import React from 'react'
import parameters from 'parameters'
import { A10Component } from '@gui-libraries/framework'
import { A10Row, A10Col, A10Icon } from '@gui-libraries/widgets'

import { A10Panel } from 'src/components/ADC/A10Panel'
import { GettingStarted } from 'src/components/shared/GettingStarted'
import { A10IconTextGroup } from 'src/components/ADC/A10IconTextGroup'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { Configs } from 'src/constants/Configs'
import EulaAfterLogin from '../EulaAfterLogin'
import EulaActivation from '../EulaActivation'
import storage from 'src/libraries/storage'

import './styles/helpsupport.scss'

export interface IHelpSupportProps {
  helpFunction: any
  showSlidingPage: boolean
  onCancel(): void
}
export interface IHelpSupportState {
  menuOpen: boolean
  showSlidingPage: boolean
  helpSupportObjs: any
  isGettingStartedVisible: boolean
  showWelcomeNotes: boolean
  showActivationNotes: boolean
}

class HelpSupport extends A10Component<IHelpSupportProps, IHelpSupportState> {
  Configs = new Configs()
  constructor(props: IHelpSupportProps) {
    super(props)
    this.state = {
      menuOpen: false,
      showSlidingPage: this.props.showSlidingPage,
      helpSupportObjs: this.Configs.HELPSUPPORT,
      isGettingStartedVisible: false,
      showWelcomeNotes: false,
      showActivationNotes: false,
    }
  }
  handleEdit = () => {}
  setSlidingPage = (isOpen: boolean) => {
    this.props.onCancel()
    this.setState({ showSlidingPage: isOpen })
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (this.state.showSlidingPage !== nextProps.showSlidingPage) {
      this.setState({ showSlidingPage: nextProps.showSlidingPage })
    }
  }
  // const HeartIcon = props => (
  //   <A10Icon component={HeartSvg} {...props} />
  // );
  showGettingStarted = () => {
    this.setState({
      isGettingStartedVisible: true,
    })
  }
  closeGettingStarted = () => {
    this.setState({
      isGettingStartedVisible: false,
    })
  }
  welcomeNotesAction = (showWelcomeNotes: boolean) => {
    this.setState({
      showWelcomeNotes,
    })
  }
  showAgreements = (showActivationNotes: boolean) => {
    this.setState({
      showActivationNotes,
    })
  }
  runFunc = (funcType: string) => {
    if (funcType === 'welcome-notes') {
      this.welcomeNotesAction(true)
    } else if (funcType === 'Agreements') {
      this.showAgreements(true)
    }
  }
   
  render() {
    const {
      showSlidingPage,
      helpSupportObjs,
      isGettingStartedVisible,
      showWelcomeNotes,
      showActivationNotes,
    } = this.state
    return (
      <div className="row no-margin">
        <FormatSlidingPage
          isOpen={showSlidingPage}
          saveText="Edit"
          disableSave={true}
          onRequestOk={this.handleEdit}
          isShowFooterButtons={false}
          isRightCancel={true}
          onRequestClose={this.setSlidingPage.bind(this, false)}
          title={'Help center'}
          description="How can we help you?"
        >
          <div className="help-panel-container">
            <A10Panel
              className="help-support-links"
              title={
                <A10IconTextGroup
                  text="Help & Support"
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, marginRight: 12 }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              }
            >
              <A10Row type="flex" gutter={16}>
                {helpSupportObjs.map((key: any, index: any) => {
                  const action = key.isFunc
                    ? this.runFunc.bind(this, key.key)
                    : this.props.helpFunction.bind(this, key.helpUrl)

                  return (
                    <A10Col
                      span={6}
                      key={index}
                      className="help-col"
                    >
                      <div className={'help-icon-container'}>
                        <div className={'help-icon-box'} onClick={action}>
                          <span className={`help-icons ${key.iconClass}`} />
                        </div>
                      </div>
                      <div className={'help-icon-text'}>
                        <span>{key.name}</span>
                      </div>
                    </A10Col>
                  )
                })}
                <A10Col
                  span={6}
                  key={helpSupportObjs.length}
                  className="help-col"
                  onClick={this.showGettingStarted}
                >
                  <div className={'help-icon-container'}>
                    <div className={'help-icon-box'}>
                      <span className="help-icons getting-started" />
                    </div>
                  </div>
                  <div className={'help-icon-text'}>
                    <span>Getting Started</span>
                  </div>
                </A10Col>
              </A10Row>
            </A10Panel>
            <A10Panel
              title={
                <A10IconTextGroup
                  text="About Harmony Controller"
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, marginRight: 12 }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              }
            >
              <A10Row style={{ marginBottom: 25 }} gutter={48}>
                <A10Col span={6}>Version</A10Col>
                <A10Col span={16}>{parameters.VERSION_NUMBER}</A10Col>
              </A10Row>
              <A10Row style={{ marginBottom: 25 }} gutter={48}>
                <A10Col span={6}>Build</A10Col>
                <A10Col span={16}>{parameters.BUILD_NUMBER}</A10Col>
              </A10Row>
              <A10Row style={{ marginBottom: 25 }} gutter={48}>
                <A10Col span={6}>Whats New</A10Col>
                <A10Col span={16}>
                  <a href={`${parameters.EDUCATION_LINK}Default.htm`} target="_blank">
                    Release Notes
                  </a>
                </A10Col>
              </A10Row>
            </A10Panel>
          </div>
        </FormatSlidingPage>
        <GettingStarted
          visible={isGettingStartedVisible}
          onCancel={this.closeGettingStarted}
        />
        {showActivationNotes ? (
          <EulaActivation
            show={true}
            setVisible={(show: boolean) => this.showAgreements(show)}
            isHelpSupport={true}
            provider={storage.get.PROVIDER || 'root'}
          />
        ) : null}
        {showWelcomeNotes ? (
          <EulaAfterLogin
            userShow={true}
            onClose={(userShow?: boolean) => {
              if (!userShow) {
                this.welcomeNotesAction(false)
              }
            }}
            wasShown={true}
          />
        ) : null}
      </div>
    )
  }
}

export default HelpSupport
