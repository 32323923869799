import React from 'react'

import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'

import { A10Message } from '@gui-libraries/widgets'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { httpClient } from 'src/libraries/httpClient'
import { getCLIfromStatusMessage } from 'src/libraries/common'
import { ITaskInfor } from 'src/containers/Controller/Dashboard/Common/PullUpWorkflow/TasksStatusList'
import WorkflowStatus from './index'

export interface ISlidingWorkflowStatusProps extends IA10ContainerDefaultProps {
  isOpen: boolean
  source?: string
  type?: string
  assocObject?: IObject
  showMessage?: boolean
  id: string
  onRequestClose: () => void
  provisionScan?: boolean
  updateWorkflowID?: (workflowData: IObject) => void
  onCompleteCallback?: () => void
}

export interface ISlidingWorkflowStatusStates {
  status: string
  statusMessage: string
  statusCLI: string
  taskList: ITaskInfor[]
}

class SlidingWorkflowStatus extends A10Container<
  ISlidingWorkflowStatusProps,
  ISlidingWorkflowStatusStates
> {
  constructor(props: ISlidingWorkflowStatusProps) {
    super(props)
    this.state = {
      status: '',
      statusMessage: '',
      statusCLI: '',
      taskList: [],
    }
  }

  componentDidUpdate(prevProps: any) {
    const { id } = this.props
    if (
      prevProps.isOpen !== this.props.isOpen &&
      this.props.isOpen === true &&
      id
    ) {
      this.refreshWorkflowDetail()
    }
  }

  refreshWorkflowDetail = () => {
    const { id, showMessage, type, provisionScan } = this.props
    if (id) {
      const promises = []
      const uri1 = `/hwcapi/v1/workflow/${id}`
      promises.push(httpClient.get(uri1, { absoluteBasePath: true }))
      if (showMessage) {
        const uri2 = `/datastore/object-workflow-map?wf_id=${id}`
        promises.push(httpClient.get(uri2, { absoluteBasePath: true }))
      }
      Promise.all(promises).then(result => {
        const taskList = result[0].data.workflow.tasks
        const taskStatus = result[0].data.workflow.status
        const workflowType = result[0].data.workflow.workflowName
        let statusMessage = ''
        let statusCLI = ''
        if (showMessage) {
          try {
            if (result[1].data.resultSet.length > 0) {
              statusMessage = Object.values(result[1].data.resultSet[0])[0]
                .status_message
              statusCLI = getCLIfromStatusMessage(statusMessage)
            }
          } catch (err) {
            console.error(err)
          }
        }
        this.setState({
          taskList,
          status: taskStatus,
          statusMessage,
          statusCLI,
        })
        if (
          provisionScan &&
          workflowType === 'PartitionTenantMapping' &&
          taskStatus === 'COMPLETED'
        ) {
          const configScanWID =
            result[0].data.workflow?.output &&
            result[0].data.workflow.output['config-scan-workflow-id']
              ? result[0].data.workflow.output['config-scan-workflow-id']
              : ''
          if (!configScanWID) {
            A10Message.destroy()
            A10Message.warning('Config scan did not get triggered', 5, close)
          } else {
            A10Message.destroy()
            A10Message.info(
              'Provisioning completed, starting config scan',
              5,
              close,
            )
            this.props.updateWorkflowID({
              workflowID: configScanWID,
              provisionScan: false,
            })
          }
        }
      })
    }
  }

  render() {
    const {
      onRequestClose,
      isOpen,
      type,
      assocObject,
      source,
      showMessage,
      onCompleteCallback,
    } = this.props
    if (
      ['upgrade', 'backup', 'restore', 'troubleshoot'].indexOf(type || '') ===
        -1 &&
      !!!assocObject
    ) {
      return null
    }
    const { taskList, status, statusMessage, statusCLI } = this.state
    let title = ''
    let keyword = 'Logical'
    if (source === 'DP') {
      keyword = 'Device'
    }
    if (type === 'deploy') {
      const vNum = assocObject['candidate-version']
        ? assocObject['candidate-version']
        : assocObject.version
      title = `Deploying ${keyword} Partition ${assocObject.name} Version ${vNum}`
    } else if (type === 'provision') {
      title = `Provisioning Cluster ${assocObject['display-name'] ||
        assocObject.name}`
    } else if (type === 'register') {
      title = `Registering Device ${assocObject.name}`
    } else if (type === 'deregister') {
      title = `Deregistering Device ${assocObject.name}`
    } else if (type === 'reboot') {
      title = `Rebooting Device ${assocObject.name}`
    } else if (type === 'partitionAdd') {
      title = `Adding Partition to Cluster ${assocObject['display-name'] ||
        assocObject.name}`
    } else if (type === 'partitionDelete') {
      title = `Deleting Partition ${assocObject.name}`
    } else if (type === 'partitionEdit') {
      title = `Editing Partition ${assocObject.name}`
    } else if (type === 'scan') {
      title = `Scanning Config of Cluster ${assocObject['display-name'] ||
        assocObject.name}`
    } else if (type === 'cluster') {
      title = `Cluster ${assocObject['display-name'] || assocObject.name}`
    } else if (type === 'upgrade') {
      title = 'Upgrading Cluster'
    } else if (type === 'backup') {
      title = 'Backing up selected Devices'
    } else if (type === 'restore') {
      title = 'Restoring selected Devices'
    } else if (type === 'troubleshoot') {
      title = 'Device Command Troubleshoot'
    }
    return (
      <FormatSlidingPage
        isOpen={isOpen}
        title={title}
        onRequestOk={onRequestClose}
        onRequestClose={onRequestClose}
        saveText="Hide"
        hideCancel={true}
      >
        <WorkflowStatus
          tasks={taskList}
          status={status}
          refresh={this.refreshWorkflowDetail}
          showMessage={showMessage}
          statusMessage={statusMessage}
          statusCLI={statusCLI}
          onCompleteCallback={onCompleteCallback}
        />
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(SlidingWorkflowStatus)
