import React from 'react'
import moment from 'moment'
import { _ } from '@gui-libraries/framework'
import {
  A10Icon,
  A10Modal,
  A10Notification,
  A10SlidingPage,
  A10Row,
  A10Col,
  A10Button,
  A10Checkbox,
  A10Select,
  A10Tooltip,
  A10Status,
} from '@gui-libraries/widgets'
import { AutoFormV15 } from '@gui-libraries/apps'

import InterfaceEthernet from './Contents/InterfaceEthernet'
import InterfaceLIF from './Contents/InterfaceLIF'
import InterfaceManagement from './Contents/InterfaceManagement'
import InterfaceTrunk from './Contents/InterfaceTrunk'
import InterfaceTunnel from './Contents/InterfaceTunnel'
import InterfaceVE from './Contents/InterfaceVE'
import {
  requestEthernet,
  requestEthernetStatus,
  requestEthernetUpdate,
  requestLIF,
  requestLIFDelete,
  requestLifStatus,
  requestManagement,
  requestTrunk,
  requestTrunkDelete,
  requestTrunkStatus,
  requestTunnel,
  requestTunnelDelete,
  requestTunnelStatus,
  requestVe,
  requestVEDelete,
  requestVEStatus,
} from '../utils/interface'
import iconRefPlay from 'src/assets/images/svg/icon-ref-play.svg'
import storage from 'src/libraries/storage'

export interface IInterfaceContentProps {
  deviceObj: IObject
  service: IObject
  selectedPartition: string
  updateSelectedPartition: any
}

const InterfaceContent: React.FC<IInterfaceContentProps> = props => {
  const {
    deviceObj,
    service,
    selectedPartition,
    updateSelectedPartition,
  } = props

  const [ethernets, setEthernets] = React.useState([])
  const [ethernetStatus, setEthernetStatus] = React.useState({})
  const [veList, setVeList] = React.useState([])
  const [veStatus, setVeStatus] = React.useState({})
  const [lifList, setLifList] = React.useState([])
  const [lifStatus, setLifStatus] = React.useState({})
  const [trunkList, setTrunkList] = React.useState([])
  const [trunkStatus, setTrunkStatus] = React.useState({})
  const [tunnelList, setTunnelList] = React.useState([])
  const [tunnelStatus, setTunnelStatus] = React.useState({})
  // const [globalStatus, setGlobalStatus] = React.useState({})
  const [managementStatus, setManagementStatus] = React.useState({})

  const [deleteConfirmFunc, setDeleteConfirmFunc] = React.useState(
    {} as IObject,
  )

  const [urlNames, setUrlNames] = React.useState([
    storage.get.PROVIDER,
    deviceObj.name,
    selectedPartition,
  ])
  const [refreshText, setRefreshText] = React.useState('')
  const [forceDelete, setForceDelete] = React.useState(false)

  const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/device/${deviceObj.name}/partition/${selectedPartition}`

  const [schemaPath, setSchemaPath] = React.useState('')
  const [autoFormParams, setAutoFormParams] = React.useState(undefined)
  const closeAutoForm = React.useCallback(() => {
    setSchemaPath('')
  }, [])

  const openAutoForm = React.useCallback(
    (currentSchemaPath: string, params?: IObject) => {
      setSchemaPath(currentSchemaPath)
      setAutoFormParams(params)
    },
    [],
  )

  const onSubmitSuccess = React.useCallback(() => {
    switch (schemaPath) {
      case 'network/interfaces/interface-global':
        // callRequestIntefaceGlobal(service, urlNames)
        break
      case 'network/interfaces/lan':
        callRequestEthernet(service, urlNames)
        break
      case 'network/interfaces/virtual-ethernet':
        callRequestVE(service, urlNames)
        break
      case 'network/interfaces/lif':
        callRequestLIF(service, urlNames)
        break
      case 'network/interfaces/trunk':
        callRequestTrunk(service, urlNames)
        break
      case 'network/interfaces/tunnel':
        callRequestTunnel(service, urlNames)
        break
      case 'network/interfaces/management':
        callRequestManagement(service, urlNames)
        break
    }
    closeAutoForm()
  }, [service, urlNames, schemaPath])

  const callRequestEthernet = React.useCallback(
    (infraService: IObject, names: string[]) => {
      requestEthernet(infraService, names).then((ethernetlist: IObject[]) => {
        requestEthernetStatus(infraService, names).then(setEthernetStatus)
        setEthernets(ethernetlist)
      })
    },
    [],
  )

  const callRequestVE = React.useCallback(
    (infraService: IObject, names: string[]) => {
      requestVe(infraService, names).then((ves: IObject[]) => {
        setVeList(ves)
        requestVEStatus(infraService, names).then(setVeStatus)
      })
    },
    [],
  )

  const callRequestLIF = React.useCallback(
    (infraService: IObject, names: string[]) => {
      requestLIF(infraService, names).then((lifs: IObject[]) => {
        setLifList(lifs)
        requestLifStatus(infraService, names).then(setLifStatus)
      })
    },
    [],
  )

  const callRequestTunnel = React.useCallback(
    (infraService: IObject, names: string[]) => {
      requestTunnel(infraService, names).then((tunnels: IObject[]) => {
        setTunnelList(tunnels)
        requestTunnelStatus(infraService, names).then(setTunnelStatus)
      })
    },
    [],
  )

  // const callRequestIntefaceGlobal = React.useCallback((infraService: IObject, names: string[]) => {
  //   requestInterfaceGlobal(infraService, names)
  //     .then((g: IObject) => {
  //       setGlobalStatus(g)
  //     })
  // }, [])

  const callRequestTrunk = React.useCallback(
    (infraService: IObject, names: string[]) => {
      requestTrunk(infraService, names).then((trunks: IObject[]) => {
        setTrunkList(trunks)
        requestTrunkStatus(infraService, names).then(setTrunkStatus)
      })
    },
    [],
  )

  const callRequestManagement = React.useCallback(
    (infraService: IObject, names: string[]) => {
      requestManagement(infraService, names).then((management: IObject) => {
        setManagementStatus(management)
      })
    },
    [],
  )

  const showSuccessNotification = (description: string) => {
    A10Notification.success({
      message: 'Success!',
      description,
      style: {
        wordWrap: 'break-word',
      },
    })
  }

  const showErrorNotification = (description: string) => {
    A10Notification.error({
      message: 'Error!',
      description,
      style: {
        wordWrap: 'break-word',
      },
    })
  }

  const updateEthernet = (ethernet: IObject) => {
    const text = ethernet.action === 'enable' ? 'Enable' : 'Disable'
    requestEthernetUpdate(service, [...urlNames, ethernet.ifnum], { ethernet })
      .then(() => {
        showSuccessNotification(
          `${text} Interface Ethernet ${ethernet.ifnum} successfully.`,
        )
        callRequestEthernet(service, urlNames)
      })
      .catch(err => {
        showErrorNotification(
          `Fail to ${text} Interface Ethernet ${ethernet.ifnum}.  ${_.get(
            err,
            ['response', 'data', 'message', 'logs', 0],
            '',
          )}`,
        )
      })
  }

  const deleteConfirm = React.useCallback(
    (callback: (force: boolean) => void) => {
      setForceDelete(false)
      setDeleteConfirmFunc({ callback })
    },
    [forceDelete],
  )

  const deleteConfirmOK = React.useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (deleteConfirmFunc.callback) {
        deleteConfirmFunc.callback(forceDelete)
      }
      setDeleteConfirmFunc({})
    },
    [deleteConfirmFunc, forceDelete],
  )

  const deleteConfirmCancel = React.useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setDeleteConfirmFunc({})
    },
    [forceDelete],
  )

  const deleteTrunk = React.useCallback(
    (ifnum: number) => {
      deleteConfirm((force: boolean) => {
        requestTrunkDelete(service, [...urlNames, ifnum], force)
          .then(() => {
            showSuccessNotification(`Delete Trunk ${ifnum} successfully.`)
            callRequestTrunk(service, urlNames)
          })
          .catch((err: any) => {
            showErrorNotification(
              `Fail to delete Trunk ${ifnum}.  ${_.get(
                err,
                ['response', 'data', 'message', 'logs', 0],
                '',
              )}`,
            )
          })
      })
    },
    [service, urlNames, forceDelete],
  )

  const deleteVE = React.useCallback(
    (ifnum: number) => {
      deleteConfirm((force: boolean) => {
        requestVEDelete(service, [...urlNames, ifnum], force)
          .then(() => {
            showSuccessNotification(`Delete VE ${ifnum} successfully.`)
            callRequestVE(service, urlNames)
          })
          .catch((err: any) => {
            showErrorNotification(
              `Fail to delete VE ${ifnum}.  ${_.get(
                err,
                ['response', 'data', 'message', 'logs', 0],
                '',
              )}`,
            )
          })
      })
    },
    [service, urlNames, forceDelete],
  )

  const deleteLif = React.useCallback(
    (ifnum: number) => {
      deleteConfirm((force: boolean) => {
        requestLIFDelete(service, [...urlNames, ifnum], force)
          .then(() => {
            showSuccessNotification(`Delete LIF ${ifnum} successfully.`)
            callRequestLIF(service, urlNames)
          })
          .catch((err: any) => {
            showErrorNotification(
              `Fail to delete LIF ${ifnum}.  ${_.get(
                err,
                ['response', 'data', 'message', 'logs', 0],
                '',
              )}`,
            )
          })
      })
    },
    [service, urlNames, forceDelete],
  )

  const deleteTunnel = React.useCallback(
    (ifnum: number) => {
      deleteConfirm((force: boolean) => {
        requestTunnelDelete(service, [...urlNames, ifnum], force)
          .then(() => {
            showSuccessNotification(`Delete Tunnel ${ifnum} successfully.`)
            callRequestTunnel(service, urlNames)
          })
          .catch((err: any) => {
            showErrorNotification(
              `Fail to delete Tunnel ${ifnum}.  ${_.get(
                err,
                ['response', 'data', 'message', 'logs', 0],
                '',
              )}`,
            )
          })
      })
    },
    [service, urlNames, forceDelete],
  )

  const handleForceDeleteChange = React.useCallback((e: IObject) => {
    try {
      const checked = e.target.checked
      setForceDelete(checked)
    } catch (err) {
      //
    }
  }, [])

  const getData = () => {
    if (service && !urlNames.includes(undefined)) {
      callRequestEthernet(service, urlNames)
      callRequestVE(service, urlNames)
      callRequestLIF(service, urlNames)
      callRequestTunnel(service, urlNames)
      // callRequestIntefaceGlobal(service, urlNames)
      callRequestManagement(service, urlNames)
      callRequestTrunk(service, urlNames)
    }
  }

  React.useEffect(() => {
    getData()
  }, [deviceObj, service, urlNames])

  React.useEffect(() => {
    setUrlNames([storage.get.PROVIDER, deviceObj.name, selectedPartition])
  }, [deviceObj, selectedPartition])

  const refreshFunc = React.useCallback(() => {
    getData()
    setRefreshText(`Refreshed at ${moment().format('MM/DD/YYYY hh:mm A')}`)
  }, [service, urlNames])

  return (
    <div className="device-detail-container device-content">
      <div className="col-md-12">
        <A10Row type="flex" align="middle" className="action-container">
          <A10Col lg={8}>
            <A10Tooltip title="Service Partitions share all physical attributes for device and networks with Shared partition. So the Service Partitions will not be shown here">
              <span className="fontWeight500 fontSize16">Device Partition</span>
            </A10Tooltip>
            <A10Select
              value={selectedPartition}
              size="default"
              onChange={updateSelectedPartition}
              className="device-partition-selection"
            >
              {(deviceObj?.['partition-list'] || [])
                .filter((pt: IObject) => !pt?.['p-type']?.includes('service'))
                .map((p: IObject) => {
                  return (
                    <A10Select.Option value={p.name} key={p.name}>
                      <span>
                        <A10Status
                          color="colorGreen"
                          title="Dp"
                          className="device-partition-selection-avatar"
                        >
                          {`${deviceObj.name} > ${p.name}`}
                        </A10Status>
                      </span>
                    </A10Select.Option>
                  )
                })}
            </A10Select>
          </A10Col>
          <A10Col lg={16}>
            <div className="table-header table-header-inner">
              <span style={{ fontWeight: 500, color: '#000000' }}>
                {refreshText}
              </span>
              <A10Button className="action-button" onClick={refreshFunc}>
                <img src={iconRefPlay} className="action-icon" />
                Refresh
              </A10Button>
            </div>
          </A10Col>
        </A10Row>
      </div>
      {selectedPartition === 'shared' ? (
        <InterfaceManagement
          managementStatus={managementStatus}
          openAutoForm={openAutoForm}
        />
      ) : null}
      <InterfaceEthernet
        ethernets={ethernets}
        ethernetStatus={ethernetStatus}
        updateEthernet={updateEthernet}
        openAutoForm={openAutoForm}
      />
      <InterfaceVE
        veList={veList}
        veStatus={veStatus}
        openAutoForm={openAutoForm}
        deleteVE={deleteVE}
      />
      <InterfaceLIF
        lifList={lifList}
        lifStatus={lifStatus}
        openAutoForm={openAutoForm}
        deleteLif={deleteLif}
      />
      <InterfaceTrunk
        trunkList={trunkList}
        trunkStatus={trunkStatus}
        deleteTrunk={deleteTrunk}
        openAutoForm={openAutoForm}
      />
      <InterfaceTunnel
        tunnelList={tunnelList}
        tunnelStatus={tunnelStatus}
        deleteTunnel={deleteTunnel}
        openAutoForm={openAutoForm}
      />
      <A10SlidingPage
        isOpen={!!schemaPath}
        modalSize="large"
        onRequestClose={closeAutoForm}
      >
        <AutoFormV15
          hccEnv={true}
          schemaPath={schemaPath}
          apiPrefix={apiPrefix}
          params={autoFormParams}
          interceptor={{
            onSubmitSuccess,
            onCancel: closeAutoForm,
          }}
          enableSOP={true}
          ENV_CONSTS={{
            IS_SHARED_PARTITION: selectedPartition === 'shared',
            PARTITION_TYPE: selectedPartition === 'shared' ? 'SHARED' : 'L3V',
          }}
        />
      </A10SlidingPage>
      <A10Modal
        title={
          <span>
            <A10Icon
              app="global"
              type="question"
              style={{ color: '#ffba47' }}
              width="20px"
              height="20px"
            />{' '}
            Confirmation
          </span>
        }
        visible={!!deleteConfirmFunc.callback}
        className="device-detail-delete-confirm"
        onOk={deleteConfirmOK}
        onCancel={deleteConfirmCancel}
      >
        <p>Do you want to delete this item?</p>
        <A10Checkbox onChange={handleForceDeleteChange} checked={forceDelete}>
          Force Delete
        </A10Checkbox>
      </A10Modal>
    </div>
  )
}

export default InterfaceContent
