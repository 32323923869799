import React from 'react'
import moment from 'moment-timezone'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Alert,
  A10Col,
  A10Icon,
  A10Message,
  A10Row,
  A10Tooltip,
} from '@gui-libraries/widgets'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import BackupForm, { IFormData } from './index'
import storage from 'src/libraries/storage'
import globalConfig from 'src/settings/config'
import { TroubleShootService, Utilities } from 'src/services/index'

export interface ISlidingBackupProps extends IA10ContainerDefaultProps {
  isScheduled?: boolean
  isOpen: boolean
  deviceName?: string
  formData?: IFormData
  isPeriodicAllowed?: boolean
  onRequestClose: () => void
}

export interface ISlidingBackupStates {
  provider: string
  formData?: IFormData
  disableSave: boolean
  isLoading: boolean
}

class SlidingBackup extends A10Container<
  ISlidingBackupProps,
  ISlidingBackupStates
> {
  Utilities = new Utilities()
  childForm: any = null
  ref = React.createRef()
  TroubleShootService = new TroubleShootService()
  constructor(props: ISlidingBackupProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER || '',
      disableSave: true,
      isLoading: false,
    }
  }

  componentWillReceiveProps(nextProps: IObject) {
    const state = { ...this.state }
    const { formData } = nextProps
    state.formData = formData
    this.setState(state)
  }

  onFormChange = (data: IFormData) => {
    const state = { ...this.state }
    state.disableSave = !(
      data['device-backup'].name &&
      data['device-backup'].deviceList.length &&
      (data['device-backup'].when === 'now' ||
        (data['device-backup'].when === 'schedule' &&
          !!data['device-backup'].starts &&
          !!data['device-backup'].timezone))
    )
    state.formData = data
    this.setState(state)
  }

  onRequestOk = () => {
    this.setState({
      disableSave: true,
    })
    const { onRequestClose, isPeriodicAllowed } = this.props
    const formData = this.state.formData as IFormData

    if (formData['device-backup'].when === 'schedule' && !isPeriodicAllowed) {
      A10Message.error(
        'You are using a license which does not allow Scheduling Backups. For questions or upgrading your license please contact A10 Networks Customer Support or Sales.',
      )
    } else {
      if (formData['device-backup'].when === 'schedule') {
        const timezoneObj = JSON.parse(formData['device-backup'].timezone)
        moment.tz.setDefault(timezoneObj.value)
        const now = moment()
        if (moment(now).isAfter(formData['device-backup'].starts, 'day')) {
          A10Message.error(
            'Scheduled time cannot be before current date of the selected timezone',
          )
          return
        } else if (
          moment(now).isSame(formData['device-backup'].starts, 'day') &&
          moment(now).isAfter(formData['device-backup'].starts, 'second')
        ) {
          A10Message.error(
            'Scheduled time cannot be before current date & time of the selected timezone',
          )
          return
        }
      }

      this.saveBackup()
        .then(() => {
          A10Message.success('Config Backup is scheduled successfully.')
          onRequestClose()
        })
        .catch(err => {
          A10Message.error(`Config Backup failed. ${err.message}`)
          onRequestClose()
        })
    }
  }

  async saveBackup() {
    const {
      EPIC_DEPENDENCIES: { httpClient },
    } = globalConfig
    const formData = this.state.formData as IFormData
    const { name, frequency, description, timezone, starts, when } = formData[
      'device-backup'
    ]
    const offset = starts ? starts.utcOffset() : ''
    // const dateTime = new Date()
    const backupTaskList = formData['device-backup'].deviceList.map(
      (device: string) => {
        const url = `/hocapi/v1/provider/${this.state.provider}/device/${device}/backup/`
        const backupPayload = {
          backup: {
            data: {
              'device-name': device,
              'backup-name': name,
              frequency: frequency,
              description,
            },
            schedule: {
              'is-repeating': false,
              timezone: timezone,
            },
          },
        }
        if (when === 'schedule') {
          const time = starts.utcOffset(offset).format('HH:mm:ss.ms')
          const date = starts.utcOffset(offset).format('YYYY-MM-DD')

          const timeString = `${date}T${time}Z`

          const schedule = {
            'start-date': timeString,
            'is-repeating': true,
            timezone: timezone,
            ...this.getScheduleFrequency(),
          }
          backupPayload.backup.schedule = schedule
        }
        return httpClient.post(url, backupPayload, { absoluteBasePath: true })
      },
    )
    return Promise.all(backupTaskList)
  }

  handleFormValidation = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm?.props)
  }

  getTitle = () => {
    return (
      <A10Row>
        <A10Col span={24}>Create Device Backup</A10Col>
        <A10Col span={24}></A10Col>
      </A10Row>
    )
  }

  render() {
    const {
      isScheduled,
      isOpen,
      onRequestClose,
      formData,
      deviceName,
    } = this.props
    const { disableSave } = this.state
    return (
      <FormatSlidingPage
        isOpen={isOpen}
        onRequestOk={this.handleFormValidation}
        onRequestClose={onRequestClose}
        title={this.getTitle()}
        disableSave={disableSave}
      >
        <A10Row>
          <A10Col span={24}>
            <A10Alert
              style={{ marginBottom: '10px' }}
              message="Information"
              description="Device Config Backup takes a backup of the CLI in startup-configuration and other file-based configurations present on the device. Please note that you may want to persist running configuration to startup-configuration before triggering device config backup by executing “write memory” directly on the device or using Harmony’s Device CLI utility."
              type="info"
              showIcon
            />
          </A10Col>
        </A10Row>
        <BackupForm
          isScheduled={isScheduled}
          deviceName={deviceName}
          onChange={this.onFormChange}
          formData={formData}
          onSubmitForm={this.onRequestOk}
          onRef={(ref: any): any => {
            this.childForm = ref
          }}
        />
      </FormatSlidingPage>
    )
  }

  private getScheduleFrequency = () => {
    const formData = this.state.formData as IFormData
    const frequency = formData['device-backup'].frequency
    const startDate = formData['device-backup'].starts
    const utcStartDate = startDate.utc()

    //isoWeekday method gets or sets the ISO day of the week with 1 being Monday and 7 being Sunday. and to schedule the backup need to minus one day from isoweekday
    const weekday = utcStartDate.isoWeekday() - 1
    const day = utcStartDate.get('date')
    const hour = utcStartDate.get('hour')
    const minute = utcStartDate.get('minute')
    const second = utcStartDate.get('second')

    const time = {
      hour,
      minute,
      second,
    }
    switch (frequency) {
      case 'monthly':
        return {
          ...time,
          day,
        }
      case 'weekly':
        return {
          ...time,
          'day-of-week': weekday,
        }
      case 'daily':
      default:
        return time
    }
  }
}

export default setupA10Container(SlidingBackup)
