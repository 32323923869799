import React, { useState } from 'react'
import { _ } from '@gui-libraries/framework'
import { A10Row, A10Notification } from '@gui-libraries/widgets'
import { AutoFormV15, setUpAutoConfig } from '@gui-libraries/apps'

import Cards, { Type } from 'src/components/shared/Cards'
import PartitionSelector from '../PartitionSelector'
import VrrpaCommon from './VrrpaCommon'
import VrrpaInterfaces from './VrrpaInterfaces'
import VridList from './VridList'
import FailoverTemplateList from './FailoverTemplateList'
import PeerGroup from './PeerGroup'
import SessionSyncPort from './SessionSyncPort'

import styles from './styles/index.module.less'

export interface IVrrpaOverviewProps {
  devices: IObject[]
  clustersUpdated: boolean
}

export const NA = 'N/A'

const VrrpaOverview: React.FC<IVrrpaOverviewProps> = props => {
  const { devices, clustersUpdated } = props
  const [device, setDevice] = useState<string | undefined>()
  const [partition, setPartition] = useState<string | undefined>()
  const [type, setType] = useState<string | undefined>()
  const [shouldTemplateUpdate, setShouldTemplateUpdate] = useState(false)

  const handleSelectDP = ({
    device,
    partition,
    type,
  }: {
    device: string
    partition: string
    type: string
  }) => {
    setDevice(device)
    setPartition(partition)
    setType(type)
  }

  const renderForm = ({
    schemaPath,
    apiPrefix,
    params,
    successCallback,
    errorCallback,
    cancelCallback,
  }: {
    schemaPath: string
    apiPrefix: string
    params: string
    successCallback: () => void
    errorCallback: () => void
    cancelCallback: () => void
  }) => {
    const interceptor: IObject = {
      onSubmitSuccess: (
        sformUtils: IObject,
        response: IObject[] = [],
        names: string[],
        formData: Map<string, any>,
        description: string,
        SOPEnable: boolean,
      ) => {
        if (_.isFunction(successCallback)) successCallback()
      },
      onSubmitError: (sformUtils: IObject, error: IObject, name: string[]) => {
        if (_.isFunction(errorCallback)) errorCallback()
      },
      onCancel: () => {
        if (_.isFunction(cancelCallback)) cancelCallback()
      },
    }
    const isSharedPartition = type === 'shared'

    setUpAutoConfig({
      ENV_CONSTS: {
        IS_SHARED_PARTITION: isSharedPartition,
        PARTITION_TYPE: isSharedPartition ? 'SP' : 'L3V',
      },
    })

    return (
      <AutoFormV15
        schemaPath={schemaPath}
        hccEnv={true}
        apiPrefix={apiPrefix}
        params={params}
        enableSOP={true}
        interceptor={interceptor}
      />
    )
  }

  const openNotification = (
    type: string,
    title: string,
    description: string,
  ) => {
    A10Notification[type]({
      duration: 5,
      message: title,
      description,
    })
  }

  const data = [
    ...(type === 'shared' && device && partition
      ? [
          {
            name: 'VRRP-A Common',
            span: 9,
            type: Type.Custom,
            children: (
              <VrrpaCommon
                device={device}
                partition={partition}
                renderForm={renderForm}
                clustersUpdated={clustersUpdated}
              />
            ),
          },
          {
            name: 'Session Sync Port',
            span: 9,
            type: Type.Custom,
            children: (
              <SessionSyncPort
                device={device}
                partition={partition}
                renderForm={renderForm}
                openNotification={openNotification}
                clustersUpdated={clustersUpdated}
              />
            ),
          },
          {
            name: 'Peer Group',
            span: 6,
            type: Type.Custom,
            children: (
              <PeerGroup
                device={device}
                partition={partition}
                renderForm={renderForm}
                openNotification={openNotification}
                clustersUpdated={clustersUpdated}
              />
            ),
          },
          {
            name: 'VRRP-A Interface',
            span: 24,
            type: Type.Custom,
            children: (
              <VrrpaInterfaces
                device={device}
                partition={partition}
                renderForm={renderForm}
                openNotification={openNotification}
                clustersUpdated={clustersUpdated}
              />
            ),
          },
        ]
      : []),
    {
      name: 'VRID',
      span: 12,
      type: Type.Custom,
      children: (
        <VridList
          device={device}
          partition={partition}
          renderForm={renderForm}
          setShouldTemplateUpdate={setShouldTemplateUpdate}
          clustersUpdated={clustersUpdated}
        />
      ),
    },
    {
      name: 'Failover Template',
      span: 12,
      type: Type.Custom,
      children: (
        <FailoverTemplateList
          device={device}
          partition={partition}
          renderForm={renderForm}
          shouldTemplateUpdate={shouldTemplateUpdate}
          setShouldTemplateUpdate={setShouldTemplateUpdate}
          clustersUpdated={clustersUpdated}
        />
      ),
    },
  ]

  return (
    <>
      {devices.length > 0 ? (
        <>
          <A10Row type="flex" align="middle">
            <span className={styles.title}>Device Partition</span>
            <PartitionSelector
              type="device"
              items={devices}
              onClickNode={handleSelectDP}
            />
          </A10Row>
          <Cards data={data} className={styles.vrrpaOverview} />
        </>
      ) : (
        <span>No information to show</span>
      )}
    </>
  )
}

export default VrrpaOverview
