import React from 'react'
import { A10Container } from '@gui-libraries/framework'
import {
  A10Row,
  A10Col,
  A10Input,
  A10Select,
  A10Button,
  A10Collapse,
  A10Tag,
  A10Table,
} from '@gui-libraries/widgets'
import { stringAsLines, SequenceMatcher } from 'jsdifflib'
import ReactLoading from 'react-loading'
import HealthStatus from 'src/components/ADC/HealthStatus'
import AflexCodeEditor from 'src/components/ADC/AflexCodeEditor'
import { httpClient } from 'src/libraries/httpClient'
import { formatBytes } from 'src/libraries/stringUtils'
import { Utilities } from 'src/services/index'
import './styles/index.less'

export interface IDiffComparatorProps {
  id?: string
  type: string
  candidateVersion?: number
  runningVersion?: number
  leftVersionNum?: number
  rightVersionNum?: number
  versions?: IObject[]
  leftConfig?: string
  rightConfig?: string
  leftComment?: string
  rightComment?: string
  showFileList?: boolean
  fileList?: IObject[]
  onClickSetVersion?: any
  onClickDeploy?: any
  onClickValidate?: any
  isLoadingLeft?: boolean
  isLoadingRight?: boolean
  isValidating?: boolean
}

export interface IDiffComparatorStates {
  leftVersion: IObject
  rightVersion: IObject
  leftVersionNum: number
  rightVersionNum: number
  leftConfig: string
  rightConfig: string
  isLoadingLeft: boolean
  isLoadingRight: boolean
  isValidating: boolean
}

class DiffComparator extends A10Container<
  IDiffComparatorProps,
  IDiffComparatorStates
> {
  Utilities = new Utilities()
  constructor(props: IDiffComparatorProps) {
    super(props)
    this.state = {
      leftVersion: null,
      rightVersion: null,
      leftVersionNum: this.props.leftVersionNum || 0,
      rightVersionNum: this.props.rightVersionNum || 0,
      leftConfig: this.props.leftConfig || '',
      rightConfig: this.props.rightConfig || '',
      isLoadingLeft: this.props.isLoadingLeft || false,
      isLoadingRight: this.props.isLoadingRight || false,
      isValidating: this.props.isValidating || false,
    }
  }

  componentDidUpdate(preProps: any) {
    if (this.props.versions !== preProps.versions) {
      const { type, versions, leftVersionNum, rightVersionNum } = this.props
      if (type === 'version' && versions.length > 0) {
        if (leftVersionNum) {
          this.onChangeLeftVersion(leftVersionNum)
        }
        if (rightVersionNum) {
          this.onChangeRightVersion(rightVersionNum)
        }
      }
    }
    if (this.props.rightConfig !== preProps.rightConfig) {
      this.setState({
        rightConfig: this.props.rightConfig,
        isLoadingRight: false,
      })
    }
    if (this.props.leftConfig !== preProps.leftConfig) {
      this.setState({ leftConfig: this.props.leftConfig, isLoadingLeft: false })
    }
    if (this.props.isValidating !== preProps.isValidating) {
      this.setState({ isValidating: this.props.isValidating })
    }
  }

  /* istanbul ignore next */
  syncScrollBar = (type: string) => {
    const tagLeft =
      (this.props.id ? `.${this.props.id} ` : '') +
      '.diff-left .CodeMirror-vscrollbar'
    const tagRight =
      (this.props.id ? `.${this.props.id} ` : '') +
      '.diff-right .CodeMirror-vscrollbar'
    const left = document.querySelector(tagLeft)
    const right = document.querySelector(tagRight)
    if (type === 'left') {
      right.scrollTop = left.scrollTop
    } else {
      left.scrollTop = right.scrollTop
    }
  }

  renderVersionOptions = (type: string) => {
    // control left or right
    const {
      versions = [],
      leftVersionNum,
      rightVersionNum,
      candidateVersion = 0,
      runningVersion = 0,
    } = this.props
    const defaultVersion = type === 'left' ? leftVersionNum : rightVersionNum
    const onChangeVersion =
      type === 'left'
        ? this.onChangeLeftVersion.bind(this)
        : this.onChangeRightVersion.bind(this)
    const versionNum =
      type === 'left' ? this.state.leftVersionNum : this.state.rightVersionNum
    const options = versions.map(version => {
      return (
        <A10Select.Option key={version.version} value={version.version}>
          <span>{'v ' + version.version}</span>
          {version.version === candidateVersion &&
          version.version !== runningVersion ? (
            <span className="candidate-version-box">Candidate</span>
          ) : null}
          {version.version === runningVersion ? (
            <span className="running-version-box">Running</span>
          ) : null}
        </A10Select.Option>
      )
    })
    return (
      <A10Select
        style={{ width: '80%', marginBottom: 13 }}
        defaultValue={defaultVersion}
        value={versionNum}
        onChange={onChangeVersion}
      >
        {options}
      </A10Select>
    )
  }

  onChangeLeftVersion = (versionNum: number) => {
    const { versions } = this.props
    const currVersion = versions.filter(
      version => version.version == versionNum,
    )[0]
    this.setState(
      {
        leftVersion: currVersion,
        leftVersionNum: versionNum,
        isLoadingLeft: true,
      },
      async () => {
        const uri = `/hpcapi/v3/blob/${currVersion.uuid}+${versionNum}`
        const { data: Res } = await httpClient.get(uri)
        this.setState({
          leftConfig: Res,
          isLoadingLeft: false,
        })
      },
    )
  }

  onChangeRightVersion = (versionNum: number) => {
    const { versions } = this.props
    const currVersion = versions.filter(
      version => version.version == versionNum,
    )[0]
    this.setState(
      {
        rightVersion: currVersion,
        rightVersionNum: versionNum,
        isLoadingRight: true,
      },
      async () => {
        const uri = `/hpcapi/v3/blob/${currVersion.uuid}+${versionNum}`
        const { data: Res } = await httpClient.get(uri)
        this.setState({
          rightConfig: Res,
          isLoadingRight: false,
        })
      },
    )
  }

  renderListCloset = (
    config: string,
    version: string,
    diff: any[],
    isLoading: boolean,
  ) => {
    const options = {
      mode: { name: 'text/x-aflex' },
      extraKeys: { 'Alt-F': 'findPersistent' },
      // theme: 'a10',
      lineWrapping: true,
      lineNumbers: true,
      readOnly: true,
    }
    return (
      <>
        {isLoading ? (
          <div className="loading-icon">
            <ReactLoading type="bars" color="#ddd" height={70} width={70} />
          </div>
        ) : null}
        <AflexCodeEditor
          value={config}
          options={options}
          version={version}
          diff={diff}
        />
      </>
    )
  }

  renderFileList = (files: IObject[]) => {
    const Header = () => {
      return (
        <A10Row type="flex" align="middle">
          <A10Col lg={20}>
            <div className="section-title-container">
              <span className="section-title">Files in Candidate</span>
              <HealthStatus type="number" text={files.length} />
            </div>
          </A10Col>
        </A10Row>
      )
    }
    const tableCol = [
      {
        title: 'Type',
        width: '20%',
        sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'type'),
        dataIndex: 'type',
        key: 'type',
        render: (text: string, record: any) => {
          return <A10Tag>{record.type}</A10Tag>
        },
      },
      {
        title: 'File',
        width: 200,
        className: 'lp-file-name',
        sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Size',
        width: '10%',
        sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'size'),
        align: 'right',
        dataIndex: 'size',
        key: 'size',
        render: (text: string, record: any) => {
          return <span>{formatBytes(record.size, true)}</span>
        },
      },
    ]
    return (
      <A10Collapse bordered={false}>
        <A10Collapse.Panel
          className="file-list-collapse"
          header={<Header />}
          key="1"
        >
          <div className="file-list-box">
            <A10Table
              columns={tableCol}
              dataSource={files.map((key: any, index: number) => {
                key.key = index
                return key
              })}
              size="small"
              scroll={{ y: 145 }}
              pagination={false}
            />
          </div>
        </A10Collapse.Panel>
      </A10Collapse>
    )
  }

  /* istanbul ignore next */
  syncScrollBarRight = () => {
    this.syncScrollBar('right')
  }
  /* istanbul ignore next */
  syncScrollBarLeft = () => {
    this.syncScrollBar('left')
  }

  render() {
    const {
      type,
      candidateVersion,
      showFileList = false,
      fileList = [],
    } = this.props
    let before = this.state.leftConfig
    let idx = this.state.leftConfig.indexOf('!clideploy-uuid-end')
    if (idx !== -1) {
      before = this.state.leftConfig.substring(idx + 20)
    }
    const beforelines = before ? stringAsLines(before) : []
    let after = this.state.rightConfig
    idx = this.state.rightConfig.indexOf('!clideploy-uuid-end')
    if (idx !== -1) {
      after = this.state.rightConfig.substring(idx + 20)
    }
    const afterlines = after ? stringAsLines(after) : []
    const opcodes = new SequenceMatcher(beforelines, afterlines).get_opcodes()
    const leftComment = this.props.leftComment
      ? this.props.leftComment
      : this.state.leftVersion
      ? this.state.leftVersion.comments
      : ''
    const rightComment = this.props.rightComment
      ? this.props.rightComment
      : this.state.rightVersion
      ? this.state.rightVersion.comments
      : ''
    const leftVersionNum = this.state.leftVersion
      ? this.state.leftVersion.version
      : 0
    const rightVersionNum = this.state.rightVersion
      ? this.state.rightVersion.version
      : 0
    return (
      <div className={`${this.props.id} diff-comparator-content`}>
        <A10Row>
          <A10Col span={12}>
            <div
              className="diff-comparator-content-tab diff-left"
              onMouseOver={this.syncScrollBarLeft}
            >
              {type === 'deploy' ? (
                <div>Running Version</div>
              ) : (
                this.renderVersionOptions('left')
              )}
              {this.renderListCloset(
                before,
                'before',
                opcodes,
                this.state.isLoadingLeft,
              )}
              {type === 'version' &&
              leftVersionNum !== 0 &&
              leftVersionNum === candidateVersion
                ? this.renderFileList(fileList)
                : null}
              <A10Input.TextArea
                rows={3}
                style={{ marginTop: 13, color: '#363636' }}
                value={leftComment}
                disabled={true}
              />
              {type === 'version' && leftVersionNum !== 0 ? (
                <div style={{ marginTop: 13 }}>
                  <span style={{ marginRight: 5 }}>
                    <A10Button
                      disabled={leftVersionNum === candidateVersion}
                      onClick={this.props.onClickSetVersion.bind(
                        this,
                        leftVersionNum,
                      )}
                    >
                      Make Candidate
                    </A10Button>
                  </span>
                  <span style={{ marginRight: 5 }}>
                    <A10Button
                      onClick={this.props.onClickValidate.bind(
                        this,
                        leftVersionNum,
                      )}
                      disabled={this.state.isValidating}
                    >
                      Validate
                    </A10Button>
                  </span>
                  <span>
                    <A10Button
                      onClick={this.props.onClickDeploy.bind(
                        this,
                        leftVersionNum,
                      )}
                    >
                      Deploy
                    </A10Button>
                  </span>
                </div>
              ) : null}
            </div>
          </A10Col>
          <A10Col span={12}>
            <div
              className="diff-comparator-content-tab diff-right"
              onMouseOver={this.syncScrollBarRight}
            >
              {type === 'deploy' ? (
                <div>Candidate Version</div>
              ) : (
                this.renderVersionOptions('right')
              )}
              {this.renderListCloset(
                after,
                'after',
                opcodes,
                this.state.isLoadingRight,
              )}
              {(type === 'deploy' && showFileList) ||
              (rightVersionNum !== 0 && rightVersionNum === candidateVersion)
                ? this.renderFileList(fileList)
                : null}
              <A10Input.TextArea
                rows={3}
                style={{ marginTop: 13, color: '#363636' }}
                value={rightComment}
                disabled={true}
              />
              {type === 'version' && rightVersionNum !== 0 ? (
                <div style={{ marginTop: 13 }}>
                  <span style={{ marginRight: 5 }}>
                    <A10Button
                      disabled={rightVersionNum === candidateVersion}
                      onClick={this.props.onClickSetVersion.bind(
                        this,
                        rightVersionNum,
                      )}
                    >
                      Make Candidate
                    </A10Button>
                  </span>
                  <span style={{ marginRight: 5 }}>
                    <A10Button
                      onClick={this.props.onClickValidate.bind(
                        this,
                        rightVersionNum,
                      )}
                      disabled={this.state.isValidating}
                    >
                      Validate
                    </A10Button>
                  </span>
                  <span>
                    <A10Button
                      onClick={this.props.onClickDeploy.bind(
                        this,
                        rightVersionNum,
                      )}
                    >
                      Deploy
                    </A10Button>
                  </span>
                </div>
              ) : null}
            </div>
          </A10Col>
        </A10Row>
      </div>
    )
  }
}

export default DiffComparator
