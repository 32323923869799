import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Form,
  A10Select,
  A10Alert,
  A10Row,
  A10Notification,
  A10InputNumber,
  A10Radio,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { Messages } from 'src/locale/en/Messages'
import { InfrastructureService } from 'src/services/InfrastructureService/InfrastructureService'
import { IDefaultMethods } from 'src/containers/Controller'
import { DropdownConstants } from 'src/constants/DropdownConstants/DropdownConstants'
import { DashboardService, Utilities } from 'src/services/index'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import storage from 'src/libraries/storage'
import RangeSlider from 'src/components/shared/RangeSlider/index'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { ClusterAddForm } from 'src/containers/Controller/Infrastructure/Clusters/Forms/ClusterAddForm'
import './styles/vmdeviceaddform.scss'

const RadioGroup = A10Radio.Group
export const CLUSTER_OBJ = {
  type: 'single',
  dataPlaneHA: 'none',
  clusterName: '',
  clusterDName: '',
  description: '',
  advanced: false,
  clusterSetID: '',
  floatingIP: '',
  floatingIPMask: '',
  convertToHA: false,
  clusterUserName: '',
  clusterPassword: '',
  devices: [
    {
      deviceName: '',
      thunderIPAddress: '',
      mgmtInterface: true,
      // useV6: false,
      port: '',
      zone: '',
      region: undefined,
      location: '',
      metricsExportInterval: 60,
      userName: '',
      userPassword: '',
      licenseCheck: false,
      licenseToken: '',
      licenseObj: '',
      numBandwidth: '',
      bandwidthUnit: '',
    },
  ],
}

export interface IVMDeviceAddFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  device?: any
  form: any
  formData?: any
  onRef?: any
  handleChange?: (data: any) => void
}
export interface IVMDeviceAddFormState {
  devices: {
    environment: string
    credential: any
    securePort: number
    hcClusterSelect: string
    licenseObj: string
    bandwidth: number
    bandwidthUnit: string
    product: string
  }
  clustersList: any[]
  filteredClustersList: any[]
  credentialsList: any[]
  licenseList: any[]
  updateAllotted: boolean
  isAddNewCluster: boolean
  showSlidingPage: boolean
  clusterObj: any
}

class VMDeviceAddForm extends A10Container<
  IVMDeviceAddFormProps,
  IVMDeviceAddFormState
> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  Messages = new Messages()
  InfrastructureService = new InfrastructureService()
  DropdownConstants = new DropdownConstants()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  slidingTitle = 'Add Cluster'
  slidingDesc = 'Please provide the detail to connect device'
  childForm: any = null
  adminLevel

  constructor(
    props: IVMDeviceAddFormProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: {
        get: { ADMIN_LEVEL },
      },
    } = context

    this.adminLevel = ADMIN_LEVEL

    const devices: any = props.formData
    this.state = {
      devices: devices || {
        environment: undefined,
        credential: undefined,
        securePort: 443,
        hcClusterSelect: undefined,
        licenseObj: undefined,
        bandwidth: undefined,
        bandwidthUnit: '',
        product: 'CFW',
      },
      clustersList: [],
      filteredClustersList: [],
      credentialsList: [],
      licenseList: [],
      updateAllotted: false,
      isAddNewCluster: false,
      showSlidingPage: false,
      clusterObj: JSON.parse(JSON.stringify(CLUSTER_OBJ)),
    }
  }

  componentDidMount() {
    this.props.onRef(this)
    this.loadClusters()
    this.loadVMCredentials(this.state.devices.environment)
    this.getLicenseList()
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  addCluster = () => {
    this.setSlidingPage(true, CLUSTER_OBJ, true)
  }

  setSlidingPage = (
    isOpen: boolean,
    data?: IObject,
    isAddNewCluster?: boolean,
  ) => {
    this.setState({
      showSlidingPage: isOpen,
      clusterObj: data,
      isAddNewCluster,
    })
  }

  handleSave = (e: any) => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  saveCluster = () => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context

    const {
      clusterName,
      clusterDName,
      type,
      dataPlaneHA,
      description,
      clusterSetID,
      floatingIP,
      floatingIPMask,
    } = this.state.clusterObj

    const { showMessage } = this.Utilities

    const payload = {
      cluster: {
        name: clusterName,
        'display-name': clusterDName || clusterName,
        type,
        'data-plane-ha': dataPlaneHA,
        description: description !== '' ? description : undefined,
        'floating-ip': type === 'vcs' ? floatingIP : undefined,
        'floating-ip-mask': type === 'vcs' ? floatingIPMask : undefined,
        'cluster-set-id':
          (type === 'ha' ||
            (type === 'multinode' && dataPlaneHA === 'vrrpa')) &&
          clusterSetID !== ''
            ? parseInt(clusterSetID, 10)
            : undefined,
      },
    }

    const urlInput = [provider]
    const saveCluster = this.InfrastructureService.addCluster(
      null,
      payload,
      urlInput,
    )
    saveCluster
      .then((response: any) => {
        if (response.data) {
          showMessage('SUCCESS_CLUSTER_ADD', 1, 1)
          this.setSlidingPage(false, {}, false)
          this.loadClusters(clusterName)
        }
        // to refresh the clusters
      })
      .catch((error: any) => {
        showMessage(
          'FAILURE_CLUSTER_ADD',
          0,
          1,
          error.response && error.response.data && error.response.data.response
            ? ' - ' + error.response.data.response.err.msg
            : '',
        )
        this.setSlidingPage(false, {}, false)
        // to check the error
      })
  }

  handleClusterChange = (data: any, isEdit: boolean) => {
    // @ts-ignore
    this.setState({
      clusterObj: data,
    })
  }

  handleChange = (stateName: string, e: any, index?: number) => {
    if (!this.Utilities.validateField(e)) {
      return
    }

    const deviceObj = this.state.devices
    let emptyLicense = false
    if (!deviceObj['licenseObj']) {
      emptyLicense = true
    }
    let { updateAllotted, credentialsList } = this.state
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        deviceObj[stateName] = e.target.checked
      } else {
        deviceObj[stateName] = e.target.value
      }
    } else {
      deviceObj[stateName] = e
    }

    if (stateName === 'environment' && deviceObj[stateName] !== '') {
      deviceObj['credential'] = undefined
      deviceObj['product'] = 'CFW'
      credentialsList = []
      this.props.form.setFieldsValue({ [`credential`]: undefined })
      this.loadVMCredentials(deviceObj[stateName])
    }

    if (stateName === 'licenseObj' && !!deviceObj[stateName]) {
      const licObj = deviceObj[stateName]
        ? JSON.parse(deviceObj[stateName])
        : {}
      const maxVal = !licObj
        ? 0
        : ((licObj['max-allocated-bandwidth'] > licObj['remaining-bandwidth'] ||
            licObj['max-allocated-bandwidth'] === 0) &&
            licObj['license-type'] !== 'INSTANCE_FLEXPOOL') ||
          licObj['license-type'] !== 'SUBSCRIBER_FLEXPOOL'
        ? licObj['remaining-bandwidth']
        : licObj['max-allocated-bandwidth']
      const maxBW = this.Utilities.megaToGiga(maxVal || 0)
      let minBW = { val: 0, unit: '' }
      if (maxBW.unit === 'm') {
        minBW['val'] = parseFloat(
          licObj && licObj['min-allocated-bandwidth']
            ? licObj['min-allocated-bandwidth']
            : 0,
        )
        minBW['unit'] = 'm'
      } else {
        const val =
          (licObj && licObj['min-allocated-bandwidth']
            ? licObj['min-allocated-bandwidth']
            : 0) / 1000
        minBW['val'] = parseFloat(val.toFixed(2))
        minBW['unit'] = 'g'
      }
      if (
        licObj['license-type'] === 'INSTANCE_FLEXPOOL' ||
        licObj['license-type'] === 'SUBSCRIBER_FLEXPOOL'
      ) {
        const allotedBw = this.Utilities.megaToGiga(
          licObj['license-type'] === 'SUBSCRIBER_FLEXPOOL'
            ? 2
            : licObj['default-allocated-bandwidth'] || 0,
        )
        deviceObj.bandwidth = allotedBw.val
        deviceObj.bandwidthUnit = allotedBw.unit
      } else {
        deviceObj.bandwidth = minBW.val
        deviceObj.bandwidthUnit = minBW.unit
      }
      updateAllotted = !updateAllotted

      if (emptyLicense) {
        A10Notification.warning({
          message: 'Warning',
          description:
            'Please note that the selected license will replace the existing license in case a pre-licensed Thunder image or template is used to deploy the new virtual device.',
          duration: 0,
        })
      }
    } else if (stateName === 'licenseObj') {
      deviceObj.bandwidth = 0.0
      deviceObj.bandwidthUnit = ''
    }
    if (stateName === 'hcClusterSelect' && e === 'new_cluster') {
      deviceObj[stateName] = undefined
    }

    this.props.handleChange(deviceObj)
    // @ts-ignore
    this.setState(
      {
        devices: deviceObj,
        updateAllotted,
        credentialsList,
      },
      () => {
        if (stateName === 'hcClusterSelect' && e === 'new_cluster') {
          this.props.form.setFieldsValue({ hcClusterSelect: undefined })
          this.addCluster()
        }
      },
    )
  }

  onSliderChange = (data: any) => {
    const deviceObj = this.state.devices
    deviceObj.bandwidth = data.updatedValue
    this.props.handleChange(deviceObj)
    // @ts-ignore
    this.setState({
      devices: deviceObj,
    })
  }

  loadClusters = async (clusterName?: string) => {
    const {
      get: { PROVIDER },
      set,
    } = storage

    try {
      const res = await this.InfrastructureService.getClustersSummary(
        this.adminLevel === 'provider'
          ? null
          : this.Utilities.getXAccountHeaderDetails(false),
        null,
        PROVIDER,
      )
      const clusters = res?.data?.['cluster-list'] || []
      set.PROVIDER_CLUSTERS(clusters)
      set.ALLCLUSTERS(clusters)
      const clustersListforDevice = this.Utilities.getClustersToAddDevice(
        clusters,
      )

      if (clusterName) {
        const tempCluster = this.Utilities.filterObjByProperty(
          clusters,
          'name',
          clusterName,
        )
        if (tempCluster.name) {
          this.props.form.setFieldsValue({
            hcClusterSelect: tempCluster['display-name'],
          })
          this.handleChange('hcClusterSelect', JSON.stringify(tempCluster), 0)
        }
      }

      this.setState({
        clustersList: clustersListforDevice,
        filteredClustersList: clustersListforDevice,
      })
    } catch (e) {
      console.error(e)
    }
  }

  clusterSearch = (value: any, option?: any) => {
    const filteredClustersList = this.state.clustersList.filter((obj: any) => {
      return obj.name.indexOf(value) > -1
    })

    this.setState({ filteredClustersList })
  }

  loadVMCredentials = (envType: string) => {
    if (!envType && !this.state.devices.environment) {
      return
    }
    const headers = this.Utilities.getXAccountHeaderDetails(false)
    const provider = this.context?.storage?.get?.PROVIDER

    const vmCredResponse = this.InfrastructureService.getCredentialInfraAccountType(
      headers,
      null,
      [provider, envType || this.state.devices.environment],
    )
    vmCredResponse
      .then((response: any) => {
        const list = response.data['infra-account-list']
        if (list && list.length > 0) {
          this.setState({
            credentialsList: list.filter(
              (cred: { [x: string]: string }) =>
                cred['infra-provider'] === envType,
            ),
          })
        }
      })
      .catch((error: any) => {
        console.log(error.response)
      })
  }

  getLicenseList = () => {
    const {
      get: { PROVIDER },
    } = storage

    const licenseAPI = this.DashboardService.getLicenseInfo(
      null,
      null,
      PROVIDER,
    )
    licenseAPI
      .then((resp: any) => {
        if (resp && resp.data) {
          const licenseList = this.Utilities.getFlexpoolLicenses(resp.data)
          // const licenseList = [{ 'entitlement-key': 'A1045DH34H' }, { 'entitlement-key': 'A1046DH34D' }]
          this.setState({
            licenseList: licenseList,
          })
        }
      })
      .catch((err: any) => {
        console.log('err get license')
      })
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }
    const { getFieldDecorator } = this.props.form
    const {
      showSlidingPage,
      clusterObj,
      filteredClustersList,
      devices,
      credentialsList,
    } = this.state

    const licObj = devices.licenseObj ? JSON.parse(devices.licenseObj) : {}
    const maxVal = !licObj
      ? 0
      : licObj['license-type'] === 'SUBSCRIBER_FLEXPOOL'
      ? 2
      : (licObj['max-allocated-bandwidth'] > licObj['remaining-bandwidth'] ||
          licObj['max-allocated-bandwidth'] === 0) &&
        licObj['license-type'] !== 'INSTANCE_FLEXPOOL'
      ? licObj['remaining-bandwidth']
      : licObj['max-allocated-bandwidth']

    const maxBW = this.Utilities.megaToGiga(maxVal || 0)
    let minBW = { val: 0, unit: '' }
    if (maxBW.unit === 'm') {
      minBW['val'] = parseFloat(
        licObj && licObj['min-allocated-bandwidth']
          ? licObj['min-allocated-bandwidth']
          : 0,
      )
      minBW['unit'] = 'm'
    } else {
      const val =
        (licObj && licObj['min-allocated-bandwidth']
          ? licObj['min-allocated-bandwidth']
          : 0) / 1000
      minBW['val'] = parseFloat(val.toFixed(2))
      minBW['unit'] = 'g'
    }

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <div className="margin-v-10">
          <A10Panel
            title={
              <>
                <A10IconTextGroup
                  text={
                    <>
                      Cluster Info
                      {/* <span>
                        <HelpInfo
                          customCls="custom-tooltip-head"
                          placement="topLeft"
                          title="Cluster Info"
                          helpKey="HELP_INFRA_VIRTUAL_CLUSTER_INFO"
                        />
                      </span> */}
                    </>
                  }
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, marginRight: 12 }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  {this.Messages.CLUSTER_SELECT}
                  <span style={{ marginLeft: -10 }}>
                    <HelpInfo
                      placement="topLeft"
                      title={this.Messages.CLUSTER_SELECT}
                      helpKey="HELP_CREATECLUSTER_CLUSTER_NAME"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('hcClusterSelect', {
                rules: [
                  {
                    required: true,
                    message: 'Please select Harmony Cluster',
                  },
                ],
                initialValue: filteredClustersList.length
                  ? devices.hcClusterSelect
                  : undefined,
              })(
                <A10Select
                  placeholder={'Select a Cluster'}
                  onChange={(e: any) => this.handleChange('hcClusterSelect', e)}
                  showSearch={true}
                  onSearch={this.clusterSearch}
                >
                  <A10Select.Option key="new_cluster" value="new_cluster">
                    <div className="create_new_cluster">Create New Cluster</div>
                  </A10Select.Option>
                  {filteredClustersList.map((cluster, i) => {
                    return (
                      <A10Select.Option key={i} value={JSON.stringify(cluster)}>
                        {cluster['display-name'] || cluster.name}
                      </A10Select.Option>
                    )
                  })}
                </A10Select>,
              )}
            </A10Form.Item>
          </A10Panel>

          <A10Panel
            title={
              <>
                <A10IconTextGroup
                  text={
                    <>
                      Instance Info
                      {/* <span>
                        <HelpInfo
                          customCls="custom-tooltip-head"
                          placement="topLeft"
                          title="Instance Info"
                          helpKey="HELP_INFRA_VIRTUAL_INSTANCE_INFO"
                        />
                      </span> */}
                    </>
                  }
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, marginRight: 12 }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  {this.Messages.ENVIRONMENT_TYPE}
                  <span style={{ marginLeft: -10 }}>
                    <HelpInfo
                      placement="topLeft"
                      title={this.Messages.ENVIRONMENT_TYPE}
                      helpKey="HELP_INFRA_VIRTUAL_ENVIRONMENT_TYPE"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('environment', {
                rules: [
                  {
                    required: true,
                    message: this.Messages.INFRA_ENV_SEL,
                  },
                ],
                initialValue: devices.environment,
              })(
                <A10Select
                  placeholder={'Select an Environment'}
                  onChange={(e: any) => this.handleChange('environment', e)}
                >
                  <A10Select.Option value={'vmware'}>VMware</A10Select.Option>
                  <A10Select.Option value={'aws'}>AWS</A10Select.Option>
                  <A10Select.Option value={'azure'}>Azure</A10Select.Option>
                  <A10Select.Option value={'kubernetes'}>
                    Kubernetes
                  </A10Select.Option>
                  <A10Select.Option value={'oci'}>OCI</A10Select.Option>
                </A10Select>,
              )}
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  {this.Messages.CREDENTIALS}
                  <span style={{ marginLeft: -4 }}>
                    <HelpInfo
                      placement="topLeft"
                      title="Credentials"
                      helpKey="HELP_INFRA_VIRTUAL_SELECT_CREDENTIALS"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('credential', {
                rules: [
                  { required: true, message: 'Please select Credentials' },
                ],
                initialValue: credentialsList.length
                  ? devices.credential
                  : undefined,
              })(
                <A10Select
                  placeholder={'Select a Credential'}
                  onChange={(e: any) => this.handleChange('credential', e)}
                >
                  {credentialsList.map((credential, i) => {
                    return (
                      <A10Select.Option
                        key={i}
                        value={JSON.stringify(credential)}
                      >
                        {credential.name}
                      </A10Select.Option>
                    )
                  })}
                </A10Select>,
              )}
            </A10Form.Item>
            {!!devices.environment &&
            !devices.credential &&
            this.state.credentialsList.length <= 0 ? (
              <A10Alert
                message="Warning"
                description="No Credentials found. Please define Credentials under Infrastructure > Orchestration > Credentials tab"
                type="warning"
              />
            ) : null}

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  {'Management Port'}
                  <span style={{ marginLeft: -10 }}>
                    <HelpInfo
                      placement="topLeft"
                      title={this.Messages.MANAGEMENT_SECURE_PORT}
                      helpKey="HELP_INFRA_MANAGEMENT_SECURE_PORT"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('securePort', {
                rules: [
                  {
                    required: false,
                    message: this.Messages.INFRA_MANAGEMENT_SECURE_PORT,
                    validateTrigger: 'onBlur',
                    validator: this.Utilities.validatePort.bind(
                      this,
                      'not-mandatory',
                    ),
                  },
                ],
                initialValue: devices.securePort,
                default: devices.securePort,
                placeholder: devices.securePort,
              })(
                <A10InputNumber
                  className="width-50P"
                  onChange={(e: any) => this.handleChange('securePort', e)}
                />,
              )}
            </A10Form.Item>
          </A10Panel>

          <A10Panel
            title={
              <>
                <A10IconTextGroup
                  text={
                    <>
                      License Info
                      {/* <span>
                        <HelpInfo
                          customCls="custom-tooltip-head"
                          placement="topLeft"
                          title="License Info"
                          helpKey="HELP_LICENSE_DEVICE_LICENSE"
                        />
                      </span> */}
                    </>
                  }
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, marginRight: 12 }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              </>
            }
          >
            {!!devices.environment && devices.environment === 'kubernetes' ? (
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {'Product '}
                    <span style={{ marginLeft: -10 }}>
                      <HelpInfo
                        placement="topLeft"
                        title={'Product'}
                        helpKey="HELP_KUBERNETES_PRODUCT"
                      />
                    </span>
                  </>
                }
              >
                <RadioGroup
                  onChange={(e: any) => this.handleChange('product', e)}
                  value={devices.product}
                >
                  <A10Radio value="CFW">CFW</A10Radio>
                  <A10Radio value="ADC">ADC</A10Radio>
                </RadioGroup>
              </A10Form.Item>
            ) : null}
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  {'License Token'}
                  <span style={{ marginLeft: -10 }}>
                    <HelpInfo
                      placement="topLeft"
                      title={'License Token'}
                      helpKey="HELP_LICENSE_TOKEN"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('licenseObj', {
                initialValue: devices.licenseObj,
              })(
                <A10Select
                  placeholder={this.Messages.LICENSE_REQUIRED}
                  onChange={(e: any) => this.handleChange('licenseObj', e)}
                  allowClear
                >
                  {this.state.licenseList.map(
                    (item: IObject, index: number) => {
                      return (
                        <A10Select.Option
                          key={item['entitlement-key']}
                          value={JSON.stringify(item)}
                          title={
                            item['entitlement-key'] +
                            (item['license-name']
                              ? ' - ' + item['license-name']
                              : '')
                          }
                        >
                          {devices.environment === 'kubernetes' &&
                          item['license-type'] === 'SUBSCRIBER_FLEXPOOL'
                            ? ''
                            : item['entitlement-key'] +
                              (item['license-name']
                                ? ' - ' + item['license-name']
                                : '')}
                        </A10Select.Option>
                      )
                    },
                  )}
                </A10Select>,
              )}
            </A10Form.Item>
            {!!devices.licenseObj ? (
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {'Bandwidth'}
                    <span style={{ marginLeft: -10 }}>
                      <HelpInfo
                        placement="topLeft"
                        title={'Bandwidth'}
                        helpKey="HELP_LICENSE_BANDWIDTH"
                      />
                    </span>
                  </>
                }
              >
                <A10Row>
                  <RangeSlider
                    restrict={minBW.val}
                    allotted={devices.bandwidth}
                    updateAllotted={this.state.updateAllotted}
                    max={maxBW.val}
                    min={minBW.val}
                    units={maxBW.unit === 'm' ? 'Mbps' : 'Gbps'}
                    inputFixedText={true}
                    placeholder=""
                    name="Bandwidth"
                    onChange={this.onSliderChange}
                    disabled={
                      licObj['license-type'] === 'INSTANCE_FLEXPOOL' ||
                      licObj['license-type'] === 'SUBSCRIBER_FLEXPOOL'
                    }
                  />
                </A10Row>
              </A10Form.Item>
            ) : null}
          </A10Panel>
        </div>
        <FormatSlidingPage
          isOpen={showSlidingPage}
          onRequestOk={this.handleSave}
          onRequestClose={this.setSlidingPage.bind(this, false, {}, false)}
          title={this.slidingTitle}
          description={this.slidingDesc}
        >
          <ClusterAddForm
            formData={clusterObj}
            cluster={null}
            handleChange={this.handleClusterChange}
            onSubmitForm={this.saveCluster}
            onRef={(ref: any): any => (this.childForm = ref)}
            hideDevicePanel={true}
          />
        </FormatSlidingPage>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(VMDeviceAddForm))
