import React from 'react'
import { A10Row, A10Col } from '@gui-libraries/widgets'

import {
  ContentBody,
  ContentHeader,
  ContentSection,
  ContentTitle,
} from 'src/components/shared/ContentSection'
import NodesContainer from './NodesContainer'
import WeaveStatus from './WeaveStatus'
import KubernetesCertificatesStatus from './KubernetesCertificatesStatus'
import MicroServicesStatus from './MicroServicesStatus'

import styles from './styles/index.module.less'

interface IPlatformProps {}

const Platform: React.FC<IPlatformProps> = props => {
  return (
    <ContentSection className={styles.platform}>
      <ContentHeader type="flex" align="middle" justify="space-between">
        <ContentTitle title="Platform and Infrastructure Analytics" />
      </ContentHeader>
      <ContentBody style={{ marginBottom: '30px' }}>
        <A10Row>
          <NodesContainer />
        </A10Row>
        <A10Row gutter={10}>
          <A10Col span={12}>
            <WeaveStatus />
          </A10Col>
          <A10Col span={12}>
            <KubernetesCertificatesStatus />
          </A10Col>
        </A10Row>
      </ContentBody>
      <ContentHeader type="flex" align="middle" justify="space-between">
        <ContentTitle title="Microservices Status" />
      </ContentHeader>
      <ContentBody>
        <MicroServicesStatus />
      </ContentBody>
    </ContentSection>
  )
}

export default Platform
