import React from 'react'
import { A10Component, _ } from '@gui-libraries/framework'

import TasksProcessChart from 'src/components/shared/TasksProcessChart'

import './styles/tasksstatuslist.scss'

export interface ITaskInfor {
  referenceTaskName: string
  startTime: number
  updateTime: number
  endTime?: number
  status: string
  retryCount: number
}

export interface ITasksStatusListProps {
  taskList: ITaskInfor[]
  displayOrder?: string
  visible?: boolean
}

export interface ITasksStatusListState {}

class TasksStatusList extends A10Component<
  ITasksStatusListProps,
  ITasksStatusListState
> {
  static defaultProps: ITasksStatusListProps = {
    taskList: [],
    displayOrder: 'asc',
    visible: false,
  }

  static taskNameAdapter: {
    [taskName: string]: string
  } = {
    PreWorkflow: 'Initializing',
    PostWorkflow: 'Finishing',
  }

  render() {
    const { taskList, displayOrder, visible } = this.props

    return (
      <TasksProcessChart
        taskList={taskList.map((task: ITaskInfor) => {
          const {
            referenceTaskName,
            startTime,
            updateTime,
            endTime,
            status,
            retryCount,
          } = task

          const chartInfo = {
            name: referenceTaskName,
            startTime,
            endTime: endTime || updateTime,
            status,
            retryCount,
          }

          // adapt task name
          const adapter = TasksStatusList.taskNameAdapter
          if (_.isObject(adapter) && !_.isEmpty(adapter)) {
            const adaptedName = adapter[referenceTaskName]
            if (_.isString(adaptedName) && adaptedName) {
              chartInfo.name = adaptedName
            }
          }

          return chartInfo
        })}
        displayOrder={displayOrder}
        resizeWith={visible}
      />
    )
  }
}

export default TasksStatusList
