import { getNS, IHTTPRequestOptions } from '@gui-libraries/framework'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'

const HPCAPI_PREFIX = '/hpcapi/v3'
// const getProvider = () => getItem('PROVIDER') || 'root'

export const getDomainList = (params: IObject): IHTTPRequestOptions => {
  return {
    namespace: getNS('DOMAIN_LIST_LIST'),
    request: async (epicDependence: IObject) => {
      try {
        // const provider = getProvider()
        const actionURL = `${HPCAPI_PREFIX}/provider/${storage.get.PROVIDER}/tenant/pso/logical-partition/pso/domain-list`
        const { data: res } = await httpClient.get(actionURL, { params })
        let domainLists = []
        if (res && res['domain-list-list']) {
          domainLists = res['domain-list-list']
        }
        return domainLists
      } catch (err) {
        // tslint:disable-next-line:no-console
        console.error(err)
      }
      return []
    },
  }
}

export const getDomainListCount = (searchText: string): IHTTPRequestOptions => {
  return {
    namespace: getNS('DOMAIN_LIST_COUNT'),
    request: async (epicDependence: IObject) => {
      try {
        // const provider = getProvider()
        const params: IObject = { total: true }
        if (searchText) {
          params.name = encodeURIComponent(searchText)
        }
        const actionURL = `${HPCAPI_PREFIX}/provider/${storage.get.PROVIDER}/tenant/pso/logical-partition/pso/domain-list`
        const { data: res } = await httpClient.get(actionURL, { params })
        let domainListCount = 0
        if (res && res['total-count']) {
          domainListCount = res['total-count'] || 0
        }

        return domainListCount
      } catch (err) {
        // tslint:disable-next-line:no-console
        console.error(err)
      }
      return 0
    },
  }
}
