import React from 'react'
import { A10Container, setupA10Container } from '@gui-libraries/framework'
import {
  A10Form,
  A10Input,
  A10Icon,
  A10Button,
  A10Upload,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import storage from 'src/libraries/storage'

export interface IFormData {
  name: string
  description: string
  file: IObject
}

interface ICRLFormProps {
  onChange?: (data: IFormData) => void
  onRef?: any
}

interface ICRLFormStates {
  provider: string
  tenant: string
  formData: IFormData
  fileList: string[]
}

class CRLForm extends A10Container<ICRLFormProps, ICRLFormStates> {
  constructor(props: ICRLFormProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER,
      tenant: storage.get.tenant,
      formData: {
        name: null,
        description: null,
        file: null,
      },
      fileList: [],
    }
  }

  onChangeName = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const state = { ...this.state }
    const { formData } = this.state
    formData.name = e.currentTarget.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onChangeDescription = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const state = { ...this.state }
    const { formData } = this.state
    formData.description = e.currentTarget.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onChangeFile = ({ file, fileList }) => {
    const state = { ...this.state }
    const { formData } = this.state
    formData.file = file
    state.fileList = fileList
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onChangeCallback(formData: IFormData) {
    this.props.onChange(formData)
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }
    const { getFieldDecorator } = this.props.form

    return (
      <A10Form layout="horizontal">
        <A10Panel
          title={
            <A10IconTextGroup
              text="CRL Info"
              icon={<A10Icon style={{ fontSize: 48 }} type="desktop" />}
            />
          }
        >
          <A10Form.Item {...formItemLayout} label="File Name">
            {getFieldDecorator('fileName', {
              rules: [{ required: true, message: 'Please fill in this field' }],
            })(
              <A10Input
                value={this.state.formData.name}
                onChange={this.onChangeName}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item {...formItemLayout} label="Description">
            {getFieldDecorator('description', {
              rules: [{ max: 2048, message: 'Max length is 2048' }],
            })(
              <A10Input.TextArea
                onChange={this.onChangeDescription}
                placeholder="Enter some description about this CRL"
                autosize={{ minRows: 2, maxRows: 6 }}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item {...formItemLayout} label="CRL File">
            {getFieldDecorator('crlFile', {
              rules: [{ required: true, message: 'Please upload a CRL' }],
            })(
              <A10Upload
                onChange={this.onChangeFile}
                fileList={this.state.fileList}
                beforeUpload={() => false}
              >
                <A10Button>
                  <A10Icon type="upload" /> Click to Upload
                </A10Button>
              </A10Upload>,
            )}
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(CRLForm))
