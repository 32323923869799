import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Form, A10Select, A10Alert } from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import { IDefaultMethods } from 'src/containers/Controller'
import { Messages } from 'src/locale/en/Messages'
import { DashboardService } from 'src/services/DashboardService'
import Utilities from 'src/services/Utilities/Utilities'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import storage from 'src/libraries/storage'
import './styles/launchappform.scss'

export interface ILaunchAppFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  form: any
  launchAppObj: any
  isLADCAppLaunch?: boolean
  isThunderAppLaunch?: boolean
  appLaunchWOApp?: boolean
  handleChange?: (data: any) => void
}
export interface ILaunchAppFormState {
  tenant: any
  appService: any
  appServiceList: any
  isLADCAppLaunch: boolean
  isThunderAppLaunch: boolean
  appLaunchWOApp: boolean
}

class LaunchAppForm extends A10Container<
  ILaunchAppFormProps,
  ILaunchAppFormState
> {
  Utilities = new Utilities()
  Messages = new Messages()
  DashboardService = new DashboardService()
  tenants
  drillLevel
  appsLoaded = false
  appsCount = 0

  constructor(props: ILaunchAppFormProps) {
    super(props)

    const { get } = storage

    this.tenants = get.ALLTENANTS
    this.drillLevel = get.DRILL_LEVEL

    this.state = {
      tenant: '',
      appService: '',
      appServiceList: [],
      isLADCAppLaunch: this.props.isLADCAppLaunch,
      isThunderAppLaunch: this.props.isThunderAppLaunch,
      appLaunchWOApp: this.props.appLaunchWOApp,
    }
    if (this.drillLevel !== 'provider') this.getApplications()
  }

  componentWillMount() {
    const { remove } = storage

    remove.CONTEXT_LEVEL()
    remove.DGF_SELECTED_TENANT()
    remove.DGF_ACCOUNTS()
    remove.SELECTEDCONTEXT()
    remove.AUTH()
    remove.CONTEXT()
  }

  renderTenantDropdownOpts = () => {
    let dropdownOpts = this.tenants || []
    return dropdownOpts.map((tenant: any, i: number) => {
      if (tenant.type !== 'built-in') {
        const tenantStr = JSON.stringify(tenant)
        return (
          <A10Select.Option
            key={'tenant-' + i}
            value={tenantStr}
            title={
              tenant['display-name'] ? tenant['display-name'] : tenant.name
            }
          >
            <span className="hs-text">
              <HealthStatus type={'ongoing'} text="T" hideTooltip={true} />
            </span>
            {tenant['display-name'] ? tenant['display-name'] : tenant.name}
          </A10Select.Option>
        )
      }
    })
  }

  handleChange = (stateName: string, e: any) => {
    let value = ''
    if (e.target) {
      if (e.target.type === 'checkbox') {
        value = e.target.checked
      } else {
        value = e.target.value
      }
    } else {
      value = e
    }
    this.setState({
      tenant: value,
    })
    let launchAppObj = this.props.launchAppObj
    launchAppObj.tenant = value
    this.props.handleChange(launchAppObj)
    if (!this.state.isLADCAppLaunch && !this.state.appLaunchWOApp) {
      this.getApplications(value)
    }
  }

  handleChangeAppService = (e: any) => {
    const appService: any = e && e.target ? e.target.objectvalue : e
    this.setState({
      appService: appService,
    })

    let launchAppObj = this.props.launchAppObj
    launchAppObj.appService = appService
    this.props.handleChange(launchAppObj)
  }

  getApplications = (tenant?: any) => {
    const { get } = storage

    if (typeof tenant === 'string') {
      tenant = JSON.parse(tenant)
    } else {
      tenant = get.CURRENT_TENANT
    }
    this.appsLoaded = false
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      provider: get.PROVIDER,
      tenant: tenant.name,
      Authorization: get.ENCODED_SESSION_ID,
    }

    const appservices = this.DashboardService.getApplications(headers, null, [
      get.PROVIDER,
      tenant.name,
    ])
    this.handleChangeAppService('')

    appservices
      .then((resps: any) => {
        this.appsLoaded = true
        if (resps.data) {
          let appServices = resps.data
            ? resps.data.filter((obj: any) => {
                const checkToLaunchApp =
                  obj['app_svc_type'] === 'slb' &&
                  (obj.protocol === 'http' ||
                    obj.protocol === 'https' ||
                    obj.protocol === 'http2' ||
                    obj.protocol === 'tcp')
                // return-conditions: (old UI for thunder || version apps other than thunder || thunder version apps) && active apps
                return (
                  ((this.props.launchAppObj.app.thunderApp &&
                    this.props.launchAppObj.app.thunderApp === 'slb' &&
                    obj['app_svc_type'] === 'slb') ||
                    (!this.props.launchAppObj.app.thunderApp &&
                      !(
                        this.props.launchAppObj.app.name === 'slb' ||
                        this.props.launchAppObj.app.name === 'adc'
                      ) &&
                      (obj['app_svc_type'] ===
                        this.props.launchAppObj.app.name ||
                        obj['app_svc_type'].indexOf(
                          this.props.launchAppObj.app.name,
                        ) > -1 ||
                        this.props.launchAppObj.app.name.indexOf(
                          obj['app_svc_type'],
                        ) > -1)) ||
                    ((this.props.launchAppObj.app.name === 'slb' ||
                      this.props.launchAppObj.app.name === 'adc') &&
                      checkToLaunchApp)) &&
                  obj.associationState &&
                  obj.associationState.toLowerCase() === 'activated'
                )
              })
            : []

          this.appsCount = appServices.length
          if (
            ['gifw', 'gifirewall'].indexOf(
              this.props.launchAppObj.app.name.toLowerCase(),
            ) > -1
          ) {
            let appSvcIds = ''
            appServices &&
              appServices.map((service: any) => {
                service['activeRule'] = false
                if (
                  service['app_svc_type'] === 'fw' ||
                  service['app_svc_type'] === 'gifw' ||
                  service['app_svc_type'] === 'gifirewall'
                ) {
                  appSvcIds +=
                    (appSvcIds.length > 0 ? '|' : '') + service['app_svc_id']
                }
              })
            this.appsCount = 0
            if (appSvcIds.length > 0) {
              this.setActiveRuleSets(appSvcIds, tenant)
            }
          }

          if (
            ['gtpfw', 'gtp-fw'].indexOf(
              this.props.launchAppObj.app.name.toLowerCase(),
            ) > -1
          ) {
            let appSvcIds = ''
            appServices &&
              appServices.map((service: any) => {
                service['activeRule'] = false
                if (
                  service['app_svc_type'] === 'gtpfw' ||
                  service['app_svc_type'] === 'gtp-fw'
                ) {
                  appSvcIds +=
                    (appSvcIds.length > 0 ? '|' : '') + service['app_svc_id']
                }
              })
            this.appsCount = 0
            if (appSvcIds.length > 0) {
              this.setActiveRuleSets(appSvcIds, tenant)
            }
          }

          //  (cgn apps without 'ds-lite' app-svc)
          if (
            this.props.launchAppObj.app.name.toLowerCase() === 'cgn' ||
            this.props.launchAppObj.app.name.toLowerCase() === 'cgnv6'
          ) {
            appServices = appServices.filter((obj: any) => {
              return obj['name'] !== ''
            })
          }

          this.setState({
            appServiceList: appServices,
          })
        } else {
          this.setState({
            appServiceList: [],
          })
        }
      })
      .catch((error: any) => {
        console.log('error')
      })
  }

  setActiveRuleSets = (appSvcIds: string, tenant: any) => {
    const { get } = storage

    const nowTime = new Date().getTime()
    appSvcIds = appSvcIds.trim()
    // appSvcIds = appSvcIds.substr(0, appSvcIds.length - 1)
    let payload = {}
    if (appSvcIds.length > 0) {
      payload['fw'] = {
        fields: ['o_oper_state', 'app_svc_id', 'ts'],
        rangeby: {
          start: nowTime - 2 * 60 * 1000,
          end: nowTime,
          field: 'ts',
        },
        sort: 'asc',
        filterby: {
          and: {
            app_svc_id: appSvcIds,
          },
        },
      }
    }
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      provider: get.PROVIDER,
      'x-account': tenant.uuid,
      Authorization: get.ENCODED_SESSION_ID,
    }
    const activeThunderRuleSet = this.DashboardService.activeThunderRuleSet(
      headers,
      payload,
      null,
    )
    activeThunderRuleSet
      .then((resp: any) => {
        // console.log(resp)
        const activeFw = resp && resp.data && resp.data.fw ? resp.data.fw : ''
        this.appsCount = 0
        if (activeFw) {
          const objKeys = Object.keys(activeFw)
          const appservices = this.state.appServiceList
          for (const key of objKeys) {
            appservices.map((obj: any) => {
              if (
                obj.app_svc_id === activeFw[key]['app_svc_id'] &&
                activeFw[key]['o_oper_state'] === 'active'
              ) {
                obj['activeRule'] = true
                this.appsCount++
              }
            })
          }
          this.setState({
            appServiceList: appservices,
          })
        }
      })
      .catch((error: any) => {
        // console.log(error)
      })
  }

  render() {
    const {
      // tenant,
      appServiceList,
      isLADCAppLaunch,
      appLaunchWOApp,
    } = this.state

    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }

    return (
      <A10Form layout="horizontal">
        <A10Panel title={<span className="app-launch-title">Launch App</span>}>
          {this.drillLevel === 'provider' ? (
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  {this.Messages.SELECT_TENANT}
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title="Select a Tenant"
                      helpKey="HELP_APP_SELECT_TENANT"
                    />
                  </span>
                </>
              }
            >
              <A10Select
                style={{ width: 400 }}
                showSearch
                placeholder={'Please select tenant'}
                onChange={this.handleChange.bind(this, 'tenant')}
              >
                {this.renderTenantDropdownOpts()}
              </A10Select>
            </A10Form.Item>
          ) : null}
          {!isLADCAppLaunch && !appLaunchWOApp ? (
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  {this.Messages.SELECT_APPSERVICE}
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title="Select an App Service"
                      helpKey="HELP_APP_SELECT_APP_SERVICE"
                    />
                  </span>
                </>
              }
            >
              <A10Select
                style={{ width: 400 }}
                showSearch
                placeholder={'Please select app service'}
                onChange={(e: any) => this.handleChangeAppService(e)}
              >
                {appServiceList.map((obj: any) => {
                  const appStr = JSON.stringify(obj)
                  const displayLabel =
                    (obj['display-name'] || obj.name) +
                    ' - ' +
                    (obj['lp_name'] || '')
                  return obj.activeRule === undefined || obj.activeRule ? (
                    <A10Select.Option
                      key={obj.name}
                      value={appStr}
                      title={displayLabel}
                    >
                      <span className="hs-text">
                        <HealthStatus
                          type={'ongoing'}
                          text={'A'}
                          hideTooltip={true}
                        />
                      </span>
                      {<span style={{ left: 10 }}>{displayLabel}</span>}
                    </A10Select.Option>
                  ) : null
                })}
              </A10Select>
            </A10Form.Item>
          ) : null}
          {!isLADCAppLaunch &&
          !appLaunchWOApp &&
          this.appsLoaded &&
          this.appsCount <= 0 ? (
            <A10Alert
              message="Warning"
              description={this.Messages.NO_APP_SERVICE}
              type="warning"
            />
          ) : null}
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(LaunchAppForm)
