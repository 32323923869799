import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import { RequestBuilder, SeriesDataFormatter } from '@gui-libraries/dgf'
import { Viz } from '@gui-libraries/viz'
import { A10Loader, A10Alert } from '@gui-libraries/widgets'

// import './styles/index.less'

const { LineChart } = Viz
const requestBuilder = new RequestBuilder()
const seriesDataFormatter = new SeriesDataFormatter()
const INDICATOR_MAP = {
  fan: {
    metric: 'fan_value',
    unit: 'rps',
  },
  temperature: {
    metric: 'physical_system_temperature',
    unit: '°C',
  },
}

const objToArray = (obj: any) => {
  let data: any = []
  let keys: any = Object.keys(obj)
  for (let i = 0; i < keys.length; i++) {
    data.push([Number(keys[i]), obj[keys[i]]])
  }
  return data
}

const DeviceHealth: React.FC<any> = props => {
  const refContainer = useRef()
  const {
    viz,
    onZoom,
    onClick,
    service,
    startTime,
    endTime,
    parameters,
    cluster,
    device,
    indicator,
  } = props
  const { api } = parameters
  const { metric, unit } = INDICATOR_MAP[indicator]
  const isPhysical = cluster['virtualization-type'] === 'Thunder'
  const [isLoading, setIsLoading] = useState(true)
  const [formattedData, setFormattedData] = useState([])
  const deviceUUID = device['device-uuid']
  const payload = useMemo(
    () => ({
      stats: {
        rangeby: {
          start: startTime,
          end: endTime,
          field: 'ts',
        },
        fields: [metric],
        filterby: {
          and: {
            device_uuid: deviceUUID,
          },
        },
        size: 1,
      },
    }),
    [startTime, endTime, deviceUUID, metric],
  )
  const histogramPayload = useMemo(
    () => ({
      stats: {
        rangeby: {
          start: startTime,
          end: endTime,
          field: 'ts',
        },
        fields: [],
        histogram: {
          field: 'ts',
          interval: requestBuilder.getInterval(startTime, endTime),
        },
        aggregation: 'avg',
        filterby: {
          and: {
            device_uuid: deviceUUID,
          },
        },
      },
    }),
    [startTime, endTime, deviceUUID],
  )

  const init = useCallback(async () => {
    setIsLoading(true)

    try {
      const url = `${api}/analytics/thunder-adc/system_telemetry_log_environment`
      const envResponse = await service.sendRequest(
        url,
        'POST',
        service.RPTHeaders,
        payload,
        null,
      )
      let response = await envResponse['data']['stats']
      let keys: any = Object.keys(response)
      keys = keys.length ? keys[0] : ''
      if (keys === '') {
        return
      }
      response = response[keys][metric]
      const env_items = Object.keys(response)
      histogramPayload.stats.fields = []
      for (let i = 0; i < env_items.length; i++) {
        let item = `${metric}.${env_items[i]}`
        histogramPayload.stats.fields.push(item)
      }

      let envData = await service.sendRequest(
        url,
        'POST',
        service.RPTHeaders,
        histogramPayload,
        null,
      )
      let chartData = await envData['data']['stats']
      let series: any = []
      for (let i = 0; i < env_items.length; i++) {
        let item = `${metric}.${env_items[i]}_avg`
        let itemSeries = chartData[item]
        series.push({
          name: env_items[i].replace('-value', ''),
          data: objToArray(itemSeries),
          color: seriesDataFormatter.getSeriesColor({ name: env_items[i] }, i),
        })
      }

      setFormattedData(series)
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [api, payload, histogramPayload, service, metric])

  useEffect(() => {
    if (isPhysical) {
      init()
    }
  }, [isPhysical, init])

  if (!isPhysical) {
    return (
      <A10Alert
        style={{ marginTop: '10px' }}
        message="Data Not Available. Device Environment data is published only by
        physical appliances at this time."
        type="info"
        showIcon
      />
    )
  }

  return (
    <div ref={refContainer}>
      {isLoading && <A10Loader container={refContainer} />}
      {!isLoading && (
        <LineChart
          name={''}
          data={{ series: formattedData }}
          description={''}
          unit={unit}
          height={viz.displayProperties?.height || 215}
          interval={requestBuilder.getInterval(startTime, endTime)}
          xAxis={''}
          yAxis={''}
          filterType={''}
          disableCount={true}
          onZoom={onZoom}
          OnClick={onClick}
        />
      )}
    </div>
  )
}

export default DeviceHealth
