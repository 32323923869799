export const getNameColor = (severity: string) => {
  switch (String(severity)) {
    case '0':
      return ['Emergency', '#e57373']
    case '1':
      return ['Alert', '#e57373']
    case 'critical':
    case '2':
      return ['Critical', '#e57373']
    case '3':
      return ['Error', '#e57373']
    case 'moderate':
    case '4':
      return ['Warning', '#ffb74d']
    case 'low':
    case '5':
      return ['Notification', '#81c784']
    case 'info':
    case '6':
      return ['Info', '#38a8ee']
    case 'healthy':
    case '7':
      return ['Debug', '#ffe168']
    default:
      return ['Unknown', '#ccc']
  }
}
