import React from 'react'
import './styles/Navigation.less'

import { NavApps } from './components/NavApps'
import { NavAnalytics } from './components/NavAnalytics'
import { NavUtilities } from './components/NavUtilities'
import { NavOrganization } from './components/NavOrganization'
import { NavInfrastructure } from './components/NavInfrastructure'

const Navigation = () => {
  return (
    <div id="gettingStarted-5">
      <div className="gettingStarted-content-header">Navigation</div>
      <div className="gettingStarted-nav">
        <NavApps />
        <NavAnalytics />
        <NavInfrastructure />
        <NavUtilities />
        <NavOrganization />
      </div>
    </div>
  )
}

export default Navigation
