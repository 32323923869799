import React from 'react'
import propTypes from 'prop-types'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
} from '@gui-libraries/framework'
import { A10Collapse, A10Row, A10Col } from '@gui-libraries/widgets'

import HealthStatus from 'src/components/ADC/HealthStatus'
import { CertInfo } from 'src/libraries/certpem'

export interface ICertDetailProps extends IA10ContainerDefaultProps {
  record: any
  tenant: string
  logicalPartition: string
  data: any[]
  defaultActiveAll?: boolean
}

export interface ICertDetailState {
  isLoading: boolean
}

class CertDetail extends A10Container<ICertDetailProps, ICertDetailState> {
  static childContextTypes = {
    refreshTemplateList: propTypes.func.isRequired,
  }
  static defaultProps = {
    defaultActiveAll: false,
  }
  constructor(props: ICertDetailProps) {
    super(props)
    this.state = {
      isLoading: false,
    }
  }

  // componentDidMount() {
  //   if (this.props.tenant) {
  //     this.getData()
  //   }
  // }

  // getChildContext() {
  //   return {
  //     refreshTemplateList: this.refreshTemplateList,
  //   }
  // }

  // refreshTemplateList = () => {
  //   /* istanbul ignore next */
  //   this.getData()
  // }

  // getData = () => {
  //   // Start to load data
  //   this.setState({ isLoading: true })

  //   const {
  //     A10Dispatchers: { httpRequest },
  //     record,
  //     tenant,
  //     logicalPartition,
  //   } = this.props

  //   const name = record.name
  //   const type = record.type
  //   httpRequest({
  //     namespace: getNS('DEPLOYED_OBJECT_LIST', { type, name }),
  //     request: async (epicDependence: any) => {
  //       try {
  //         const { httpClient } = epicDependence
  //         let response = {}
  //         try {
  //           let objKey = ''
  //           if (type === 'cert') {
  //             objKey = 'ssl-cert'
  //           } else if (type === 'key') {
  //             objKey = 'ssl-key'
  //           } else if (type === 'ca') {
  //             objKey = 'ssl-ca'
  //           }
  //           const { data: res } = await httpClient.get(
  //             encodeURI(`/hrcapi/v3/provider/${getItem('PROVIDER')}/tenant/${tenant}/logical-partition/${logicalPartition}/file/${objKey}/${name}`))
  //           response = res
  //         } catch (err) {
  //           /* istanbul ignore next */
  //           console.log(err)
  //         }
  //         let objectList = new Array()
  //         if (response) {
  //           objectList = response['referrer-list'] || []
  //           if (objectList) {
  //             const templateReg = /[^]*\/slb\/template\/(.*)\/(.*)[^]*/
  //             objectList.forEach((item: IObject) => {
  //               const output = item.uri.match(templateReg)
  //               item.type = output[1]
  //               item.name = output[2]
  //             })
  //           }
  //         }
  //         this.setState({ isLoading: false })
  //         return objectList
  //       } catch (err) {
  //         /* istanbul ignore next */
  //         this.setState({ isLoading: false })
  //         /* istanbul ignore next */
  //         return []
  //       }
  //     },
  //   })
  // }

  renderDetail = () => {
    const { record, tenant, data, defaultActiveAll } = this.props
    const serverSslList = data.filter(
      object => object['obj-class'] === 'slb.template.server-ssl',
    )
    const clientSslList = data.filter(
      object => object['obj-class'] === 'slb.template.client-ssl',
    )
    const certpem = new CertInfo()
    const certInfo = certpem.getCertString(record.certContent)
    const CertHeader = (props: any) => {
      return (
        <>
          <A10Row type="flex" align="middle">
            <A10Col lg={20}>
              <div className="section-title-container">
                <span className="section-title">Details</span>
              </div>
            </A10Col>
          </A10Row>
        </>
      )
    }
    const ObjectHeader = (props: any) => {
      return (
        <>
          <A10Row type="flex" align="middle">
            <A10Col lg={20}>
              <div className="section-title-container">
                <span className="section-title">Assigned {props.name}</span>
                <HealthStatus type="number" text={props.count} />
              </div>
            </A10Col>
          </A10Row>
        </>
      )
    }

    return (
      <>
        <div className="detail-section">
          {record.type === 'cert' || record.type === 'ca' ? (
            <A10Collapse bordered={false} defaultActiveKey="1">
              <A10Collapse.Panel header={<CertHeader />} key="1">
                <div className="row section-row">
                  <div className="text-block">
                    <span className="block-title">Serial Number:</span>
                    <span className="block-value">{certInfo.serialNum}</span>
                  </div>
                </div>
                <div className="row section-row">
                  <div className="text-block">
                    <span className="block-title">Signature Algorithm:</span>
                    <span className="block-value">{certInfo.sigAlgo}</span>
                  </div>
                </div>
                <div className="row section-row">
                  <div className="text-block">
                    <span className="block-title">Issuer:</span>
                  </div>
                  {certInfo.issuer
                    ? certInfo.issuer.map((text: string) => {
                        return (
                          <div className="text-block">
                            <span className="block-value">{text}</span>
                          </div>
                        )
                      })
                    : null}
                </div>
                <div className="row section-row">
                  <div className="text-block">
                    <span className="block-title">Valid from:</span>
                    <span className="block-value">{certInfo.validFrom}</span>
                  </div>
                  <div className="text-block">
                    <span className="block-title">Valid to:</span>
                    <span className="block-value">{certInfo.validTo}</span>
                  </div>
                </div>
                <div className="row section-row">
                  <div className="text-block">
                    <span className="block-title">Subject:</span>
                  </div>
                  {certInfo.subject
                    ? certInfo.subject.map((text: string) => {
                        return (
                          <div className="text-block">
                            <span className="block-value">{text}</span>
                          </div>
                        )
                      })
                    : null}
                </div>
              </A10Collapse.Panel>
            </A10Collapse>
          ) : null}

          {/* <A10Collapse bordered={false}>
            <A10Collapse bordered={false} defaultActiveKey={defaultActiveAll ? 'template-client-ssl' : null}>
              <A10Collapse.Panel
                header={
                  <ObjectHeader
                    name="Client SSL Templates"
                    count={clientSslList.length}
                  />
                }
                key="template-client-ssl"
              >
                <DeployedToTemplateList
                  name={record.name}
                  type={record.type}
                  associateType="client-ssl"
                  templateName="Client SSL"
                  tenant={tenant}
                  data={clientSslList}
                  isLoading={this.state.isLoading}
                />
              </A10Collapse.Panel>
            </A10Collapse>

            <A10Collapse bordered={false} defaultActiveKey={defaultActiveAll ? 'template-server-ssl' : null}>
              <A10Collapse.Panel
                header={
                  <ObjectHeader
                    name="Server SSL Templates"
                    count={serverSslList.length}
                  />
                }
                key="template-server-ssl"
              >
                <DeployedToTemplateList
                  name={record.name}
                  type={record.type}
                  associateType="server-ssl"
                  templateName="Server SSL"
                  tenant={tenant}
                  data={serverSslList}
                  isLoading={this.state.isLoading}
                />
              </A10Collapse.Panel>
            </A10Collapse>
          </A10Collapse> */}
        </div>
      </>
    )
  }

  render() {
    return <div className="list-detail-container">{this.renderDetail()}</div>
  }
}

function mapStateToProps(state: any, props: ICertDetailProps) {
  const { record } = props
  const type = record.type
  const name = record.name
  return {
    data: state.A10Data.getIn(
      getNS('DEPLOYED_OBJECT_LIST', { type, name }),
      [],
    ),
  }
}

export default setupA10Container(CertDetail, mapStateToProps)
