export const CLUSTER_DISK_CHART_CONFIG_PHYSICAL = {
  displayProperties: {
    name: 'Disk',
    type: 'auto',
    id: 'cluster_disk',
    width: '12',
    height: 215,
    containerHeight: 215,
    description:
      'Avg Disk utilization timeseries across the devices in the cluster for the selected time period.',
    toolTip:
      'Avg Disk utilization timeseries across the devices in the cluster for the selected time period.',
    threshold: '',
    inverse: false,
    prefix: '',
    isdevice: true,
    headers: [
      {
        name: '',
        span: 4,
      },
      {
        name: '',
        span: 8,
      },
      {
        name: '',
        span: 12,
      },
    ],
    divider: '',
    visualizationType: 'histograms',
    annotation: '',
    chartType: 'line;columnTimeSeries;histogram',
    units: '%',
    seriesArr: [
      {
        name: 'Disk',
        formula: '(disk_total - disk_free)*100/disk_total',
        units: '%',
      },
    ],
    seriesProperties: {},
    allignSeries: 'single',
  },
  queries: [
    {
      dataSource: 'RPT',
      endPoint: 'thunder-adc',
      metrics: 'system_telemetry_log_environment',
      filters: ['cluster_id'],
      fields: ['disk_free', 'disk_total'],
      aggregation: 'avg',
      groupBy: 'device_uuid',
    },
  ],
}

export const CLUSTER_DISK_CHART_CONFIG = {
  displayProperties: {
    name: 'Disk',
    type: 'custom',
    id: 'not_physical',
  },
}
