import React from 'react'
import {
  A10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Message, A10Notification } from '@gui-libraries/widgets'
import { showSOPMessage } from '@gui-libraries/apps'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import CRLForm, { IFormData } from './index'
import storage from 'src/libraries/storage'
import globalConfig from 'src/settings/config'

export interface ISlidingCRLProps extends IA10ContainerDefaultProps {
  isOpen: boolean
  onRequestClose: () => void
  tenant: string
  logicalPartition: string
}

export interface ISlidingCRLStates {
  provider: string
  formData?: IFormData
}

class SlidingCRL extends A10Container<ISlidingCRLProps, ISlidingCRLStates> {
  childForm: any = null
  constructor(props: ISlidingCRLProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER,
    }
  }

  onFormChange(data: IFormData, isEdit: boolean) {
    const state = { ...this.state }
    state.formData = data
    this.setState(state)
  }

  saveCRLSlidingPage = async () => {
    let isValid = true
    this.childForm.props.form.validateFieldsAndScroll((errors, values) => {
      if (errors) {
        isValid = false
      }
    })
    if (!isValid) {
      return
    }
    const {
      EPIC_DEPENDENCIES: { httpClient },
    } = globalConfig
    const { tenant, logicalPartition } = this.props
    const { provider } = this.state
    const newFile = this.state.formData.file
    if (!this.state.formData.name) {
      A10Message.error('CRL name is required')
      return
    }
    if (!this.state.formData.file.name) {
      A10Message.error('CRL File is required')
      return
    }
    const url = `/hpcapi/v3/provider/${provider}/tenant/${tenant}/logical-partition/${logicalPartition}/file/ssl-crl`
    const payload = {
      'ssl-crl': {
        name: this.state.formData.name,
        description: this.state.formData.description,
        'file-handle': this.state.formData.file.name,
      },
    }
    const formData = new FormData()
    formData.append('json', JSON.stringify(payload))
    formData.append('file', newFile, this.state.formData.file.name)
    try {
      const response = await httpClient.post(url, formData)
      // if (statusCode === 200) {
      //   A10Notification.success({
      //     message: 'Success!',
      //     description: `CRL ${this.state.formData.name} has been imported successfully.`,
      //   })
      const successMsg = `CRL ${this.state.formData.name} has been imported successfully.`
      showSOPMessage(response, successMsg)
      this.props.onRequestClose()
    } catch (err) {
      A10Notification.error({
        message: 'Error!',
        description: err?.response?.data?.message,
      })
    }
  }

  render() {
    const { isOpen, onRequestClose } = this.props
    const onFormChange = this.onFormChange.bind(this)
    const title = 'Import CRL'

    return (
      <FormatSlidingPage
        isOpen={isOpen}
        onRequestOk={this.saveCRLSlidingPage}
        onRequestClose={onRequestClose}
        title={title}
        description="Please provide the detail of the CRL"
      >
        <CRLForm
          onChange={onFormChange}
          onRef={(ref: any): any => (this.childForm = ref)}
        />
      </FormatSlidingPage>
    )
  }
}

export default SlidingCRL
