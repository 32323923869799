import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { A10SlidingPage, A10Loader } from '@gui-libraries/widgets'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import Descriptions from 'src/components/shared/Descriptions'
import ActionButton from 'src/components/shared/ActionButton'
import { NA } from './VrrpaOverview'

import styles from './styles/index.module.less'

export interface ISessionSyncPortProps {
  device: string
  partition: string
  renderForm: (value: IObject) => JSX.Element
  openNotification: (type: string, title: string, description: string) => void
  clustersUpdated: boolean
}

const SessionSyncPort: React.FC<ISessionSyncPortProps> = props => {
  const provider = storage.get.PROVIDER
  const isOperatorUser = storage.get.IS_OPERATOR_USER

  const openFormPage = useCallback(() => setSlidingPageOpen(true), [])
  const closeFormPage = useCallback(() => setSlidingPageOpen(false), [])

  const container = useRef(null)
  const { device, partition, renderForm, clustersUpdated } = props
  const [data, setData] = useState<IObject[]>([
    {
      'Preferred Sync Port Ethernet': [],
      'Preferred Sync Port Trunk': [],
    },
  ])
  const [slidingPageOpen, setSlidingPageOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const apiPrefix = useMemo(() => {
    return `/hpcapi/v3/provider/${provider}/device/${device}/partition/${partition}`
  }, [provider, device, partition])

  const submitCallback = () => {
    closeFormPage()
    getData()
  }

  const getData = () => {
    const interfaces = ['ethernet', 'trunk']

    Promise.all(
      interfaces.map(type =>
        httpClient.get(
          `${apiPrefix}/vrrp-a/preferred-session-sync-port/${type}`,
        ),
      ),
    )
      .then(responses => {
        const data = responses.reduce((acc, res, i) => {
          const type = interfaces[i]
          const list = res?.data?.[`${type}-list`] || []
          const title =
            type === 'ethernet'
              ? 'Preferred Sync Port Ethernet'
              : 'Preferred Sync Port Trunk'
          const field = type === 'ethernet' ? 'Eth' : 'Trunk'

          acc[title] =
            list.length > 0 ? (
              <div className={styles.collection}>
                {list.map((item: IObject, i: number) => {
                  const value = item?.[`pre-${field.toLowerCase()}`]
                  const vlan = item?.['pre-vlan']
                  const displayedText = `${field}${value}${
                    vlan ? `-Vlan${vlan}` : ''
                  }`
                  return (
                    <span className={styles.collectionValue}>
                      {displayedText}
                    </span>
                  )
                })}
              </div>
            ) : (
              NA
            )

          return acc
        }, {})
        setData([data])
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    getData()
  }, [apiPrefix, clustersUpdated])

  return (
    <>
      <div ref={container} className={styles.sectionContent}>
        {loading ? (
          <A10Loader container={container} />
        ) : (
          <>
            <Descriptions
              data={data}
              className={styles.sessionSyncPortDesc}
              direction="row"
            />
            {!isOperatorUser && (
              <ActionButton
                text="Edit"
                size="default"
                onClick={openFormPage}
                iconProps={{ app: 'global', type: 'edit' }}
                className={styles.actionButton}
              />
            )}
          </>
        )}
      </div>
      <A10SlidingPage
        isOpen={slidingPageOpen}
        modalSize="large"
        onRequestClose={closeFormPage}
      >
        {slidingPageOpen &&
          renderForm({
            schemaPath: 'system/vrrpa/settings/session-sync-port',
            apiPrefix,
            successCallback: submitCallback,
            errorCallback: submitCallback,
            cancelCallback: closeFormPage,
          })}
      </A10SlidingPage>
    </>
  )
}

export default SessionSyncPort
