export const enableReferenceFieldList = [
  'security/forward-proxy/templates/dynamic-service',
  'adc/templates/ssl/ssl-cipher',
  'adc/templates/l7-protocols/tcp-proxy',
  'adc/templates/general/logging',
  'adc/templates/l4-protocols/tcp',
  'adc/templates/l4-protocols/udp',
  'adc/templates/l7-protocols/policy',
  'config/form/file.ssl-key',
  'config/form/file.ssl-crl',
  'config/form/file.bw-list',
  'config/form/file.class-list',
  'shared-objects/domain-list',
  'cgn/fixed-nat/ip-lists',
]

export const sslTypeMapList = {
  'ssl-key': 'key',
  'ssl-cert': 'Cert',
  'ssl-ca': 'CA',
  'ssl-csr': 'CSR',
}
