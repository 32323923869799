import React from 'react'

import { A10Col, A10Row, A10TreeSelect } from '@gui-libraries/widgets'

const styles = require('./styles/index.module.less')

export interface ISelectionProps {
  content: JSX.Element
  value: IObject[]
  onChange: any
}
const A10TreeNode = A10TreeSelect.A10TreeNode
const Selection: React.FC<ISelectionProps> = props => {
  const { content, value, onChange } = props
  const [selectedObj, setSelectedObj] = React.useState({} as IObject)
  const [selectedValues, setSelectedValues] = React.useState([])
  React.useEffect(() => {
    const obj = {}
    setSelectedValues(
      value.map((selected: IObject) => {
        const name = `${selected.name}: ${selected.partition}`
        obj[name] = selected
        return name
      }),
    )
    setSelectedObj(obj)
  }, [value])
  const onChangeSelectTree = (values: any, node: any, extra: any) => {
    const { triggerValue } = extra
    if (!triggerValue) {
      return
    }
    const currentSelected = [] as IObject[]
    values.map((selected: string) => {
      if (selected) {
        const obj: IObject = selectedObj[selected]
        currentSelected.push(obj)
      }
    })
    onChange(currentSelected)
  }

  return (
    <A10Row className={styles.selection}>
      <A10Col span={8} className={styles.selectionLabel}>
        Select Cluster Partition
      </A10Col>
      <A10Col span={14}>
        <A10TreeSelect
          value={selectedValues}
          onChange={onChangeSelectTree}
          // maxTagCount={tagCount || 4}
          // onSearch={onSearchText}
          size="default"
          style={{ width: '100%' }}
          multiple={true}
          placeholder={'Search'}
          showSearch={false}
          dropdownClassName={styles.treeselect}
          // showCheckedStrategy={TreeSelect.SHOW_ALL}
          // getPopupContainer={getPopupContainer}
        >
          <A10TreeNode
            className={`${styles.dropdown} logpanel-header-search-filter-tree`}
            title={content}
          />
          <A10TreeNode style={{ display: 'none' }}>
            {selectedValues.map((selected: string) => {
              return <A10TreeNode value={selected} key={selected} />
            })}
          </A10TreeNode>
        </A10TreeSelect>
      </A10Col>
      {/* <A10Col span={6}>
        <A10Dropdown
          overlay={(
            <A10Menu>
              <A10Menu.Item>
                Cluster
              </A10Menu.Item>
            </A10Menu>
          )}
        >
          <a className={styles.dropdownLink}>
            by Cluster <A10Icon type="down" className={styles.dropdownIcon} />
          </a>
        </A10Dropdown>
      </A10Col> */}
    </A10Row>
  )
}

export default Selection
