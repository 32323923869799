import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
} from '@gui-libraries/framework'

import Settings from 'src/containers/Controller/Dashboard/Settings'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')
const initHeight = 100

export interface IPullUpProps extends IA10ContainerDefaultProps {
  size: string
  header: React.ReactNode
  minHeight?: number
  maxHeight?: number
  minPositionY?: number
  leftNavCollapsed: boolean
}

export interface IPullUpState {
  currentSize: string
  height: number
}

class PullUp extends A10Container<IPullUpProps, IPullUpState> {
  canMove = false
  ffPosition = { x: 0, y: 0 }
  prevPosition = { x: 0, y: 0 }

  constructor(props: IPullUpProps) {
    super(props)
    this.state = {
      currentSize: 'min',
      height: props.minHeight,
    }
  }

  componentDidMount() {
    const { height } = this.state
    const { size, minHeight, maxHeight } = this.props
    const avgHeight = (minHeight + maxHeight) / 2
    const lowHeight = minHeight + 10
    if (size !== this.state.currentSize) {
      if (size === 'max') {
        this.setState({ currentSize: size, height: maxHeight })
      } else if (size === 'min') {
        this.setState({ currentSize: size, height: minHeight })
      } else if (size === 'avg') {
        if (height < lowHeight) {
          this.setState({ currentSize: size, height: avgHeight })
        }
      } else {
        this.setState({ currentSize: size })
      }
    }
    if (navigator.userAgent.indexOf('Firefox') > 0) {
      document.addEventListener(
        'dragover',
        e => {
          this.ffPosition = {
            x: e.clientX || e.pageX,
            y: e.clientY || e.pageY,
          }
        },
        false,
      )
    }
  }

  componentWillReceiveProps(nextProps: IPullUpProps) {
    const { height } = this.state
    const { size, minHeight, maxHeight } = nextProps
    const avgHeight = (minHeight + maxHeight) / 2
    const lowHeight = minHeight + 10
    if (size !== this.state.currentSize) {
      if (size === 'max') {
        this.setState({ currentSize: size, height: maxHeight })
      } else if (size === 'min') {
        this.setState({ currentSize: size, height: minHeight })
      } else if (size === 'avg') {
        if (height < lowHeight) {
          this.setState({ currentSize: size, height: avgHeight })
        }
      } else {
        this.setState({ currentSize: size })
      }
    }
  }

  onDragStart = (event: IObject) => {
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    httpRequest({
      namespace: Settings.namespace.pullupViewCurrentStatus,
      request: async () => {
        return await 'medium'
      },
    })
    if (event.stopPropagation) {
      event.stopPropagation()
    }
    if (event.dataTransfer && event.dataTransfer.setData) {
      event.dataTransfer.setData('infoName', ' ')
    }

    this.prevPosition = {
      x: event.clientX,
      y: event.clientY,
    }
    this.canMove = true
    return true
  }
  onDragEnd = (event: IObject) => {
    this.onMove(event)
    this.canMove = false
  }
  onMove = (event: IObject) => {
    const { minHeight = initHeight, minPositionY } = this.props
    const clientX = event.clientX || this.ffPosition.x
    const clientY = event.clientY || this.ffPosition.y
    if (this.canMove && clientX !== 0) {
      const fixY = this.prevPosition.y - clientY
      this.prevPosition = {
        x: clientX,
        y: clientY,
      }
      this.setState((prevState: IPullUpState) => {
        const height = prevState.height || minHeight
        if (this.prevPosition.y < minPositionY) {
          return {
            height,
          }
        }
        return {
          height: height + fixY,
        }
      })
    }
  }

  onDrag = (event: IObject) => {
    this.onMove(event)
  }
  render() {
    const { header, minHeight } = this.props
    return (
      <div
        style={{
          position: 'fixed',
          bottom: '14px',
          zIndex: 998,
        }}
        // style={{ position: 'fixed', left: '0', bottom: '14px', zIndex: 998 }}
      >
        <div
          className={`${styles.pullup} ${
            this.props.leftNavCollapsed ? 'pullup-collapsed' : ''
          }`}
          style={{ height: this.state.height, minHeight, marginLeft: '-24px' }}
        >
          <div className={styles.pullupContainer}>
            <div className={styles.pullupHeader}>
              <div
                className={styles.pullupHeaderBar}
                onDragStart={this.onDragStart}
                onDragEnd={this.onDragEnd}
                onDrag={this.onDrag}
                draggable={true}
                title="Pull"
              >
                <div />
                <div />
              </div>
            </div>
            {header}
            <div style={{ height: this.state.height - 60, overflowY: 'auto' }}>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    size: state.A10Data.getIn(
      Settings.namespace.pullupViewCurrentStatus,
      'min',
    ),
    leftNavCollapsed: state.A10Data.getIn(getNS('LEFT_COLLAPSED'), false),
  }
}

export default setupA10Container(PullUp, mapStateToProps)
