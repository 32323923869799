import React from 'react'
import parameters from 'parameters'
import socketIOClient from 'socket.io-client'
import { Map } from 'immutable'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Badge, A10Icon } from '@gui-libraries/widgets'

import { Configs } from 'src/constants/Configs'
import Settings from 'src/containers/Controller/Dashboard/Settings'
import {
  getKeyNameList,
  setKeyNameList,
} from 'src/containers/Controller/Dashboard/utils/basicUtils'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'

import './styles/workflownotification.scss'

export interface IWorkflowNotificationProps extends IA10ContainerDefaultProps {
  clearPoint?: number
  onUpdateWorkflows?: (data: IObject[], endTime: number) => void
  onFocusOut?: () => void
}

export interface IWorkflowNotificationState {
  currentTenant: string
  workflowData: IObject[]
  dataEndTime: number
}

class WorkflowNotification extends A10Container<
  IWorkflowNotificationProps,
  IWorkflowNotificationState
> {
  Configs = new Configs()
  interval: number
  socket: any
  endTime: number

  constructor(props: IWorkflowNotificationProps) {
    super(props)

    this.state = {
      currentTenant: '*',
      workflowData: [],
      dataEndTime: 0,
    }

    this.initialize()
    this.getData()
  }

  initialize() {
    // initialize socket communication
    this.socket = socketIOClient(
      this.Configs.WEBSOCKET_BASE_URL + '/workflow',
      {
        path: '/socket.io/hcws',
        query: {
          base: parameters.BASE_URL,
          provider: storage.get.PROVIDER,
          token: storage.get.USER_SESSION_ID,
        },
      },
    )

    // listen on connect_error
    this.socket.on('connect_error', (error: any) => {
      console.error(error)
    })

    // listen on workflowResponseData
    this.socket.on('workflowResponseData', async (resp: any) => {
      if (this.props.clearPoint > this.endTime) {
        return
      }
      const resultSet = _.get(resp, 'resultSet', [])
      let keyNameList: IObject = getKeyNameList() || {}
      const promises = resultSet.map(async (result: IObject) => {
        const workflowMapUuid = Object.keys(result)[0]
        const workflowMapObject = result[workflowMapUuid]
        const {
          obj_id: objID,
          // workflow_id: workflowID,
          obj_type: objType,
        } = workflowMapObject

        let object = {}
        if (keyNameList[objID]) {
          object = keyNameList[objID]
        } else {
          try {
            keyNameList = getKeyNameList() || keyNameList
            if (!keyNameList[objID]) {
              const defaultInfor = {
                name: '',
                'display-name': '',
                type: '',
                device: '',
              }
              keyNameList[objID] = defaultInfor
              setKeyNameList(keyNameList)
            }
            object = keyNameList[objID]
          } catch {
            console.log("Can't get the associated object!")
          }
        }

        return {
          object,
          workflowMapObject,
        }
      })
      const workflowRecords = await Promise.all(promises)
      this.setState(
        {
          workflowData: workflowRecords,
          dataEndTime: this.endTime,
        },
        () => {
          const { onUpdateWorkflows } = this.props
          const { workflowData, dataEndTime } = this.state

          if (_.isFunction(onUpdateWorkflows)) {
            onUpdateWorkflows(workflowData, dataEndTime)
          }
        },
      )
    })
  }

  componentDidMount() {
    if (window.setInterval) {
      this.interval = window.setInterval(this.getData, 1000 * 30)
    }
  }

  componentWillUnmount() {
    if (window.clearInterval) {
      window.clearInterval(this.interval)
    }

    if (this.socket && this.socket.connected) {
      this.socket.close()
    }
  }

  shouldComponentUpdate(nextProps: IWorkflowNotificationProps) {
    const { clearPoint: nextPoint } = nextProps
    const { clearPoint } = this.props

    if (clearPoint < nextPoint) {
      this.setState({ workflowData: [] }, () => {
        const { onUpdateWorkflows } = this.props
        const { workflowData, dataEndTime } = this.state

        if (_.isFunction(onUpdateWorkflows)) {
          onUpdateWorkflows(workflowData, dataEndTime)
        }
      })

      return false
    }

    return true
  }

  componentDidUpdate() {
    // This code is using to update data after tenant changes
    // But now it didn't support the tenant filter
    // const { currentTenant } = this.state
    // const targetTenant = storage.get.CURRENT_TENANT)
    // if (targetTenant) {
    //   try {
    //     const tenantObject = JSON.parse(targetTenant)
    //     const tenantName = _.get(tenantObject, 'name')
    //     if (
    //       _.isString(tenantName) && tenantName &&
    //       currentTenant !== tenantName
    //     ) {
    //       this.getData()
    //     }
    //   } catch {}
    // } else if (currentTenant && currentTenant !== '*') {
    //   this.getData()
    // }
  }

  getData = () => {
    const timePeriod: IObject = {}
    const lastView = Number(storage.get.WORKFLOW_LAST_VIEWED_TIMESTAMP)
    if (_.isFinite(lastView) && lastView > 0 && lastView <= Date.now()) {
      timePeriod.startTime = lastView
      timePeriod.endTime = Date.now()
    }

    const filters: IObject = {}
    const provider = storage.get.PROVIDER
    if (_.isString(provider) && provider) {
      filters.provider = provider
    }

    const tenantCache = storage.get.CURRENT_TENANT
    // if (tenantCache) {
    //   try {
    //     tenantCache = JSON.parse(tenantCache)
    //   } catch {}
    // }
    const tenant = _.get(tenantCache, 'name')
    if (_.isString(tenant) && tenant) {
      // filters.tenant = tenant
    }

    if (this.socket && this.socket.connected) {
      this.socketCall(timePeriod, filters, 15)
    } else {
      setTimeout(() => {
        if (this.socket && this.socket.connected) {
          this.socketCall(timePeriod, filters, 15)
        }
      }, 10000)
    }
  }

  socketCall = (
    timePeriod: IObject = {},
    filters: IObject = {},
    upperLimit: number = 15,
  ) => {
    const { startTime, endTime } = timePeriod
    const { provider = '*', tenant = '*', uuid = '*' } = filters
    const workflowMapURL = '/datastore/object-workflow-map?'

    let queryParams = ''
    // Add time period
    if (_.isFinite(startTime) && _.isFinite(endTime)) {
      queryParams += `time_from=${startTime}&time_to=${endTime}`

      this.endTime = endTime
    } else {
      this.endTime = Date.now()
    }

    // Add filter by Provider&Tenant
    if (_.isString(provider) && provider && provider !== '*') {
      queryParams +=
        (queryParams.length > 0 ? '&' : '') + 'provider_id=' + provider
    }
    if (_.isString(tenant) && tenant && tenant !== '*') {
      queryParams += (queryParams.length > 0 ? '&' : '') + 'tenant_id=' + tenant
      this.setState({ currentTenant: tenant })
    }
    if (_.isString(uuid) && uuid && uuid !== '*') {
      queryParams += (queryParams.length > 0 ? '&' : '') + 'obj_id=' + uuid
    }

    // Pagination
    queryParams += '&start=1'
    queryParams += '&page_size=' + upperLimit

    // Sort
    queryParams += '&sort_by=created_on'
    queryParams += '&sort_order=desc'

    if (this.socket && this.socket.connected) {
      this.socket.emit('requestWorkflowData', {
        requestInfo: {
          method: 'GET',
          ip: parameters.BASE_URL,
          endpoint: workflowMapURL + queryParams,
        },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          provider: storage.get.PROVIDER,
          Authorization: storage.get.ENCODED_SESSION_ID,
        },
      })
    }
  }

  mapSourceData = (source: IObject[]) => {
    return source.map((data: IObject) => {
      const workflowMapObject: IObject = _.get(data, 'workflowMapObject', {})
      const object: IObject = _.get(data, 'object', {})

      const {
        status,
        obj_id: objId,
        obj_type: objType,
        workflow_id: workflowId,
        workflow_type: workflowType,
      } = workflowMapObject
      const { name: objName, device } = object

      return {
        status,
        objId,
        objType,
        workflowId,
        workflowType,
        objName,
        objNamePrefix: _.isString(device) && device ? device : '',
      }
    })
  }

  showWorkflowPullup = (objId?: string, workflowId?: string) => {
    const {
      A10Dispatchers: { httpRequest },
    } = this.props

    httpRequest({
      namespace: Settings.namespace.pullupViewCurrentConfig,
      request: async () => {
        return Map({
          tab: 'workflow',
          status: 'max',
          param: {
            objId,
            workflowId,
          },
        })
      },
    })
  }

  render() {
    const { workflowData } = this.state

    const count = _.isArray(workflowData) ? workflowData.length : 0

    return (
      <A10Badge count={count} style={{ color: '#fff' }}>
        <A10Icon app="harmony-controller" type="task-manager" islight={false} />
      </A10Badge>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {}
}
export default setupA10Container(WorkflowNotification, mapStateToProps)
