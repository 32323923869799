import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import { A10Row, A10Col, A10Icon, A10Alert } from '@gui-libraries/widgets'
import ReactLoading from 'react-loading'

import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { CatalogApp } from './CatalogApp'
import {
  DashboardService,
  OrganizationService,
  Utilities,
} from 'src/services/index'
import { AppUploadForm } from 'src/containers/Controller/Apps/AppCatalogs/Forms/AppUploadForm'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'

import './styles/appcatalogs.scss'
import styles from './styles/index.module.less'
import { APPLICATION_NAMES_MAP } from 'src/constants/ApplicationConfigs/ApplicationNamesMap'

export interface IAppCatalogsProps extends IA10ContainerDefaultProps {
  adminMode?: boolean
  leftNavAppsRefresh?: () => void
}
export interface IAppCatalogsState {
  appObj: any
  catalogAppsUpdated: boolean
  searchString: string
  allCatalogApps: any
  appView: string
  showSlidingPage: boolean
  editMode: boolean
  validateMode: boolean
  loadingIcon: boolean
  appUpload: boolean
}

class AppCatalogs extends A10Container<IAppCatalogsProps, IAppCatalogsState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  OrganizationService = new OrganizationService()

  state: IAppCatalogsState = {
    appObj: {
      namespace: '',
      version: '',
      comment: '',
      description: '',
      metadata: {},
      files: [],
    },
    catalogAppsUpdated: false,
    searchString: '',
    allCatalogApps: [],
    appView: 'tile',
    showSlidingPage: false,
    editMode: false,
    validateMode: false,
    loadingIcon: false,
    appUpload: false,
  }
  dataLoaded = false
  allCatalogApps: any[] = []

  onFunctions = () => {
    if (this.props.leftNavAppsRefresh) {
      this.props.leftNavAppsRefresh()
    }
    this.loadCatalogApps()
  }
  async loadCatalogApps() {
    const {
      storage: { get },
    } = this.context

    const targetUrl = get.TARGET_URL

    let entitlements: string[] = [],
      status = ''
    const adminMode = get.SUPER_ADMIN_MODE

    try {
      if (!adminMode) {
        ;({
          entitlements,
          status,
        } = await this.OrganizationService.getEntitlements(null, null, [
          get.PROVIDER,
        ]))
      }

      const catalogAppsResponse = await this.DashboardService.getHCAppCatalog(
        null,
        null,
        [targetUrl],
      )

      this.allCatalogApps = catalogAppsResponse.data
      const licensed = status === 'SUCCESS' && !entitlements.length
      this.allCatalogApps = this.allCatalogApps.map(app => {
        return {
          ...app,
          licensed:
            licensed ||
            entitlements.includes(APPLICATION_NAMES_MAP[app?.name]?.shortName),
        }
      })
      this.setState({
        allCatalogApps: this.allCatalogApps,
      })
    } catch (e) {
      console.log(e)
    }
  }
  componentWillMount() {
    const {
      storage: { remove },
    } = this.context

    remove.CONTEXT_LEVEL()
    remove.CONTEXT()
    remove.DGF_SELECTED_TENANT()
    remove.DGF_ACCOUNTS()
    remove.SELECTEDCONTEXT()
    remove.AUTH()
  }
  componentDidMount() {
    this.loadCatalogApps()
  }

  receiveUpdate = () => {
    this.dataLoaded = false
    this.setState({
      catalogAppsUpdated: true,
    })
  }

  uploadApp = () => {
    this.setState({
      appObj: {
        namespace: '',
        version: '',
        comment: '',
        description: '',
        metadata: {},
        files: [],
      },
      showSlidingPage: true,
      editMode: false,
      validateMode: true,
    })
  }

  updateApp = (app: any) => {
    this.setState({
      appObj: {
        namespace: app.name,
        version: app.version,
        comment: app.metadata.comment,
        description: app.metadata.description,
        metadata: app.metadata,
        files: [],
      },
      showSlidingPage: true,
      editMode: true,
      validateMode: true,
    })
  }

  validateApp = () => {
    const {
      namespace,
      version,
      comment,
      description,
      metadata,
      files,
    } = this.state.appObj

    if (files.length === 0) {
      this.Utilities.showMessage('APP_PACKAGE_FILE_REQUIRED', 0, 1)
      return
    } else {
      if (!/(\.zip|\.tgz)$/i.exec(files[0].name)) {
        this.Utilities.showMessage(
          'Error: Invalid file type. Upload a valid zipped file.',
          0,
          false,
        )
        this.setState({
          appObj: {
            ...this.state.appObj,
            files: [],
          },
        })
        return
      }

      const formData = new FormData()
      formData.append('file', files[0])
      const payload = {
        metadata: {
          comment: comment,
          description: description,
          status: 'Subscribed',
        },
      }
      formData.append('app', JSON.stringify(payload))
      this.setState({
        loadingIcon: true,
      })
      const validateAppResponse = this.DashboardService.validateHCApp(
        { tenant: null },
        formData,
        [namespace, version],
      )
      validateAppResponse
        .then((response: any) => {
          let metaData: any = metadata
            ? JSON.parse(JSON.stringify(metadata))
            : ''
          const keys = response.data ? Object.keys(response.data) : []
          keys.map((key: string) => {
            metaData[key] = response.data[key]
          })
          this.setState({
            appObj: {
              appPackageFile: files[0],
              files: files[0],
              short_name: response.data.short_name,
              name: response.data.name,
              app_version: response.data.app_version,
              version: response.data.app_version,
              build_number: response.data.build_number,
              app_namespace: response.data.app_namespace,
              namespace: response.data.app_namespace,
              comment: response.data.comment ? response.data.comment : comment,
              description: response.data.app_description,
              date_created: response.data.date_created,
              metadata: metaData,
            },
            showSlidingPage: true,
            // editMode: false,
            validateMode: false,
            loadingIcon: false,
          })
          this.Utilities.showMessage('SUCCESS_APP_VALIDATE', 1, 1)
        })
        .catch((error: any) => {
          console.log(error)
          let msg = ''
          if (error.response) {
            if (error.response.status == 409) {
              msg = 'An app with same name and version is already uploaded'
            } else {
              msg =
                error.response && error.response.data
                  ? error.response.data.cause
                    ? error.response.data.cause.message
                      ? error.response.data.cause.message
                      : error.response.data.cause.details &&
                        error.response.data.cause.details[0]
                      ? error.response.data.cause.details[0]
                      : ''
                    : error.response.data.message
                    ? error.response.data.message
                    : ''
                  : ''
            }
          }

          this.Utilities.showMessage('Error: ' + msg, 0, false)
          this.setState({
            loadingIcon: false,
          })
        })
    }
  }

  handleSave = () => {
    if (this.state.validateMode) {
      this.validateApp()
      return false
    }

    const {
      namespace,
      version,
      comment,
      description,
      metadata,
      files,
    } = this.state.appObj

    if (!namespace) {
      this.Utilities.showMessage('APP_NAMESPACE_REQUIRED', 0, 1)
      return false
    } else if (!version) {
      this.Utilities.showMessage('APP_VERSION_REQUIRED', 0, 1)
      return false
    } else if (files.length === 0) {
      this.Utilities.showMessage('APP_PACKAGE_FILE_REQUIRED', 0, 1)
      return false
    } else {
      const formData = new FormData()

      formData.append('file', files.length > 0 ? files[0] : files)
      const payload = {
        name: namespace,
        version: version,
        metadata: {},
      }
      if (this.state.editMode) {
        payload.metadata = metadata
        payload.metadata['comment'] = comment
        payload.metadata['description'] = description
        payload.metadata['app_description'] = description
      } else {
        payload.metadata = {
          comment: comment,
          description: description,
          app_description: description,
          status: 'Subscribed',
        }
      }
      formData.append('app', JSON.stringify(payload))
      this.setState({
        loadingIcon: true,
        appUpload: true,
      })

      const uploadAppResponse = this.DashboardService.saveHCApp(
        { tenant: null },
        formData,
        [namespace, version],
        this.state.editMode,
      )
      uploadAppResponse
        .then((response: any) => {
          this.setSlidingPage(false)
          this.Utilities.showMessage('SUCCESS_APP_UPLOAD', 1, 1)
          if (!this.state.editMode && this.props.leftNavAppsRefresh) {
            this.props.leftNavAppsRefresh()
          }
          this.loadCatalogApps()
          this.setState({
            loadingIcon: false,
          })
        })
        .catch((error: any) => {
          console.log(error)
          let msg = ''
          if (error.response) {
            if (error.response.status == 409) {
              msg = 'An app with same name and version is already uploaded'
            } else if (this.state.editMode && error.response.status == 404) {
              msg = 'App name and version does not match with the existing app'
            } else {
              msg =
                error.response && error.response.data
                  ? error.response.data.cause
                    ? error.response.data.cause.message
                      ? error.response.data.cause.message
                      : error.response.data.cause.details &&
                        error.response.data.cause.details[0]
                      ? error.response.data.cause.details[0]
                      : ''
                    : error.response.data.message
                    ? error.response.data.message
                    : ''
                  : ''
            }
          }

          msg && this.Utilities.showMessage('Error: ' + msg, 0, false)
          this.setState({
            loadingIcon: false,
          })
        })
    }
    return false
  }

  handleChange = (data: any, isEdit: boolean) => {
    // @ts-ignore
    this.setState({
      appObj: data,
    })
  }

  onDrop = (files: any) => {
    let appObj = this.state.appObj
    appObj.files = files
    // @ts-ignore
    this.setState({
      appObj: appObj,
    })
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({
      showSlidingPage: isOpen,
    })
  }

  appViewChange = (view: string) => {
    this.setState({
      appView: view,
    })
  }

  render() {
    const {
      storage: { get },
    } = this.context
    const adminMode = get.SUPER_ADMIN_MODE

    return (
      <>
        <ContentSection className="apps">
          <ContentHeader type="flex" align="middle" justify="space-between">
            <A10Col>
              <ContentTitle title="Harmony Apps" />
            </A10Col>
            <A10Col className={styles.actionsContainer}>
              {adminMode && (
                <ActionButton
                  text="Upload an App"
                  onClick={this.uploadApp}
                  iconProps={{ app: 'global', type: 'add-new' }}
                />
              )}
            </A10Col>
          </ContentHeader>
          <ContentBody>
            <A10Row gutter={[14, 14]} type="flex" align="stretch">
              {this.state.allCatalogApps.map((app: IObject) => {
                return (
                  <CatalogApp
                    key={`${app.name}-${app.version}`}
                    app={app}
                    appView={this.state.appView}
                    onAppUpdate={this.receiveUpdate}
                    updateApp={this.updateApp}
                    onFunctions={this.onFunctions}
                  />
                )
              })}
            </A10Row>
          </ContentBody>
        </ContentSection>
        <FormatSlidingPage
          isOpen={this.state.showSlidingPage}
          onRequestOk={this.handleSave}
          isRightCancel={true}
          saveText={
            this.state.validateMode
              ? 'Next'
              : this.state.editMode
              ? 'Update'
              : 'Upload'
          }
          disableSave={this.state.loadingIcon}
          onRequestClose={
            this.state.loadingIcon
              ? false
              : this.setSlidingPage.bind(this, false)
          }
          title={
            <> {this.state.editMode ? 'Update an App' : 'Upload an App'} </>
          }
          hideCancel={this.state.loadingIcon}
          isRightCancel
        >
          {this.state.loadingIcon ? (
            <div className="">
              <ReactLoading
                type="bars"
                color="#4a90e2"
                height={40}
                width={40}
              />
              <A10Alert
                message={
                  !this.state.appUpload
                    ? 'App is getting validated'
                    : 'App is getting Uploaded'
                }
                description={
                  !this.state.appUpload
                    ? 'Validation in progress'
                    : 'Uploading in progress'
                }
                type="info"
              />
            </div>
          ) : null}
          <AppUploadForm
            app={this.state.appObj}
            editMode={this.state.editMode}
            validateMode={this.state.validateMode}
            handleChange={this.handleChange}
            onDrop={this.onDrop}
          />
        </FormatSlidingPage>
      </>
    )
  }
}

export default setupA10Container(AppCatalogs)
