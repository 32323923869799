import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import {
  A10Form,
  A10Icon,
  A10Select,
  A10AflexCodeEditor,
} from '@gui-libraries/widgets'

import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import A10Panel from 'src/components/ADC/A10Panel'
import { InfrastructureService, Utilities } from 'src/services/index'
import storage from 'src/libraries/storage'
import { Messages } from 'src/locale/en/index'
import { HelpInfo } from 'src/components/shared/HelpInfo'

export interface IDeviceConfigFormProps extends IA10ContainerDefaultProps {
  device: IObject
}

export interface IDeviceConfigFormState {
  partitions: IObject[]
  partitionName: string
  runningConfig: string
  runningConfigType: string
  isLoading: boolean
}

class DeviceConfigForm extends A10Container<
  IDeviceConfigFormProps,
  IDeviceConfigFormState
> {
  Utilities = new Utilities()
  InfrastructureService = new InfrastructureService()
  Messages = new Messages()
  constructor(props: IDeviceConfigFormProps) {
    super(props)
    this.state = {
      partitions: [],
      partitionName: 'shared',
      runningConfig: '',
      runningConfigType: '',
      isLoading: false,
    }
  }

  getRunningConfig = () => {
    const { device } = this.props
    const { partitionName, runningConfigType = '' } = this.state

    return this.InfrastructureService.getRequest(
      null,
      {
        commands_source: [`show running-config ${runningConfigType}`],
        devices: [
          {
            name: device.name,
            partition: partitionName,
          },
        ],
        client: 'hc_system',
      },
      [storage.get.PROVIDER],
      'RUN_CLI_SNIPPET',
    )
  }

  getDevicePartitions = () => {
    const { device } = this.props
    const headerObj =
      storage.get.ADMIN_LEVEL === 'provider'
        ? null
        : this.Utilities.getXAccountHeaderDetails(false)

    return this.InfrastructureService.getDevicePartitions(headerObj, null, [
      storage.get.PROVIDER,
      device.name,
    ])
  }

  loadRunningConfig = async () => {
    const { device } = this.props
    const { partitionName } = this.state

    try {
      const res = await this.getRunningConfig()
      this.setState({
        runningConfig: _.get(
          res,
          [
            'data',
            'details',
            device.name,
            partitionName,
            'output',
            'result',
            'response',
          ],
          '',
        ),
      })
    } catch (e) {
      console.log(e)
    }
  }

  loadDevicePartitions = async () => {
    try {
      this.setState({ isLoading: true })
      const res = await this.getDevicePartitions()
      this.setState({
        partitions: _.get(res, ['data', 'partition-list'], []),
      })
    } catch (e) {
      console.log(e)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  componentDidMount() {
    this.loadDevicePartitions()
    this.loadRunningConfig()
  }

  handleChange = (val: string) => {
    this.setState(
      {
        partitionName: val,
      },
      () => {
        this.loadRunningConfig()
      },
    )
  }
  handleTypeChange = (val: string) => {
    this.setState(
      {
        runningConfigType: val,
      },
      () => {
        this.loadRunningConfig()
      },
    )
  }

  render() {
    const {
      device: { name, host, 'primary-sw-version': acosVersion, cluster },
    } = this.props
    const {
      partitions,
      runningConfig = '',
      partitionName,
      runningConfigType,
      isLoading,
    } = this.state
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 15 },
    }

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel
          title={
            <>
              <A10IconTextGroup
                text={'View Config'}
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            </>
          }
        >
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>{this.Messages.NAME}</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title="Name"
                    helpKey="HELP_INFRA_DEVICE_CONFIG_NAME"
                  />
                </span>
              </>
            }
          >
            {name}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>{this.Messages.HOST}</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title="Host"
                    helpKey="HELP_INFRA_DEVICE_CONFIG_HOST"
                  />
                </span>
              </>
            }
          >
            {host}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>{this.Messages.ACOS_VERSION}</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title="ACOS Version"
                    helpKey="HELP_INFRA_DEVICE_CONFIG_ACOS_VERSION"
                  />
                </span>
              </>
            }
          >
            {acosVersion}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>{this.Messages.CLUSTER}</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title="Cluster"
                    helpKey="HELP_INFRA_DEVICE_CONFIG_CLUSTER"
                  />
                </span>
              </>
            }
          >
            {cluster}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>{this.Messages.PARTITION}</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title="Partition"
                    helpKey="HELP_INFRA_DEVICE_CONFIG_PARTITION"
                  />
                </span>
              </>
            }
          >
            <A10Select
              onChange={this.handleChange}
              value={partitionName}
              loading={isLoading}
            >
              {partitions.map((p: IObject) => (
                <A10Select.Option key={p.name}>{p.name}</A10Select.Option>
              ))}
            </A10Select>
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>{this.Messages.SHOW_RUNNING_CONFIG_FOR}</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title="Show Running Config. for"
                    helpKey="HELP_INFRA_DEVICE_CONFIG_RUNNING_CONFIG_FOR"
                  />
                </span>
              </>
            }
          >
            <A10Select
              value={runningConfigType}
              onChange={this.handleTypeChange}
            >
              <A10Select.Option key="" value="">
                All
              </A10Select.Option>
              <A10Select.Option key="vcs">aVCS</A10Select.Option>
              <A10Select.Option key="scaleout">Scaleout</A10Select.Option>
              <A10Select.Option key="vrrp-a">VRRP-A</A10Select.Option>
            </A10Select>
          </A10Form.Item>
        </A10Panel>
        <A10Panel
          title={
            <>
              <A10IconTextGroup
                text={'Running Config'}
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            </>
          }
          style={{ paddingLeft: '10px' }}
        >
          <A10Form.Item
            {...{
              labelCol: { span: 0 },
              wrapperCol: { span: 23 },
            }}
          >
            <A10AflexCodeEditor
              id={''}
              value={runningConfig ? runningConfig : ''}
              options={{ lineNumbers: true, readOnly: true }}
            />
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(DeviceConfigForm))
