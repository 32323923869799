import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Alert,
  A10Icon,
  A10Tree,
  A10Switch,
  A10Form,
  A10Input,
  A10Select,
  A10Radio,
  A10Tooltip,
  A10Row,
  A10Col,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { IDefaultMethods } from 'src/containers/Controller'
import { Messages } from 'src/locale/en'
import { DashboardService } from 'src/services/DashboardService'
import { DropdownConstants } from 'src/constants/DropdownConstants/DropdownConstants'
import { Utilities } from 'src/services/Utilities'
import './styles/alertaddform.scss'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import { InfrastructureService } from 'src/services/InfrastructureService'
import storage from 'src/libraries/storage'
import { HealthStatus } from 'src/components/ADC/HealthStatus'

const { TreeNode } = A10Tree

// categories
const INFRASTRUCTURE = 'INFRASTRUCTURE'
const SYSTEM = 'SYSTEM'
const APP_SERVICE = 'APP SERVICE'

export interface IActionAddFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  onRef?: any
  form: any
  alerts: any
  formData: any
  isEditMode: boolean
  handleChange?(data: any): void
  oncancel(e?: React.MouseEvent<HTMLInputElement>): void
  onTenantUpdate(): void
}

export interface IActionPostData {
  uri: string
  extended_body: string
  headers: any
  ssc: boolean
}

export interface IActionAddFormState {
  alertName: string
  actionList: any[]
  exisitingAction: boolean
  actionSelected: any
  option: any
  numOnceForEvery: number
  numOnceEvery: number
  notificationType: string
  emails: any
  mitigationMessage: any
  postToUrl: string
  validateCertificate: boolean
  postHeaders: any
  extendedBody: string
  triggersList: any[]
  triggerTreeData: any
  triggerName: string
  selectedTrigger: any
  clustersLoaded: boolean
  treeData: any
  selectedObjects: any[]
  priority: number
  actionName: string
  triggerType: string
  smootheningFunction: any
  condition: any
  value: any
  windowSize: any
  windowSizeType: any
  latestInstalledApps: any[]
  isAppsLoaded: boolean
  isCannedTriggersLoaded: boolean
  isCustomTriggersLoaded: boolean
  triggerChartMetrics: any[]
  triggerInventory: any[]
  customTriggers: any[]
  category: string
  subCategory: string
  objectType: string
}

export interface IActionObj {
  alertName: string
}
class AlertDefinitionAddForm extends A10Container<
  IActionAddFormProps,
  IActionAddFormState
> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  Messages = new Messages()
  DashboardService = new DashboardService()
  DropdownConstants = new DropdownConstants()
  InfrastructureService = new InfrastructureService()
  Utilities = new Utilities()
  actionObj = {} as IActionObj
  delimiter = '$$::$$'
  clusters: Array<any> = []
  clustersBelongs = ['system', 'infrastructure']
  cgnv6NameMapping = {
    'Technology NAT44': 'lsn',
    'Technology NAT64': 'nat64',
    'Technology DSLite': 'ds-lite',
    'Technology FixedNAT': 'fixed-nat',
    Pool: 'pool',
    Ports: 'ports',
  }
  cgnv6ObjectMapping = {
    'Technology NAT44': ['lsn', 'global'],
    'Technology NAT64': ['nat64', 'global'],
    'Technology DSLite': ['ds-lite', 'global'],
    'Technology FixedNAT': ['fixed-nat', 'global'],
    Pool: ['nat', 'pool'],
    Ports: ['global'],
  }
  iconsNames = {
    appService: 'As',
    vip: 'vS',
    vport: 'P',
    serviceGroup: 'Sg',
    server: 'S',
    serverPorts: 'Sp',
    technology: 'T',
    http2: 'H2',
    pool: 'P',
    ports: 'p',
    rule: 'r',
    ruleSet: 'rS',
    http: 'H',
    device: 'D',
    cluster: 'C',
    partition: 'P',
  }
  filterBYAppSvcID = [
    'VIP',
    'vPort',
    'HTTP protocol',
    'Technology NAT44',
    'Technology NAT64',
    'Technology FixedNAT',
    'Technology DSLite',
    'Rule Set',
    'Rule',
  ]
  fwObjectMapping = {
    Rule: ['rule-set'],
    'Rule Set': ['rule-set'],
  }

  vportList = [
    'vPort',
    'Caching',
    'Connection Reuse',
    'Forwarding',
    'HTTP protocol',
    'L7 Sessions',
    'Load balancing',
    'NAT Pool',
  ]
  slbSelfObjects = ['HTTP2 protocol', 'L4 Protocols', 'FW Global']
  slbSelfObjUrls = {
    'HTTP2 protocol': ['slb', 'http2'],
    'L4 Protocols': ['slb', 'l4'],
    'FW Global': ['fw', 'global'],
  }

  serviceGroupList = ['Service Group']
  vipList = ['VIP']
  serverList = ['Server']
  portList = ['Server Port']
  levels = [
    'VIP',
    'VPORT',
    'Service_Group',
    'Server',
    'Port',
    'HTTP2 protocol',
    'L4 Protocols',
  ]

  thunderSlbServicesOriginal: any = []
  cgnv6ServicesOriginal: any = []
  fwServicesOriginal: any = []
  nodeCounter = 0
  constructor(props: IActionAddFormProps) {
    super(props)
    const { formData } = this.props
    this.state = {
      alertName: formData?.alertName,
      exisitingAction: false,
      actionSelected: '',
      actionList: [],
      option: 'every',
      numOnceForEvery: formData?.threshold,
      numOnceEvery: formData?.interval,
      notificationType: 'email',
      emails: formData?.actionName,
      mitigationMessage: formData?.extendedMsg,
      extendedBody: formData?.extendedBody,
      postHeaders: formData?.headers,
      postToUrl: formData?.postToUrl,
      triggerName: formData?.triggerName,
      validateCertificate: true,
      triggersList: [],
      triggerTreeData: [],
      objectType: formData?.objectType,
      category: formData?.category,
      subCategory: formData?.subCategory,
      selectedTrigger: '',
      clustersLoaded: false,
      treeData: [],
      selectedObjects: [],
      priority: formData?.priority,
      actionName: '',
      triggerType: 'fromtriggerinventory',
      smootheningFunction: 'min',
      condition: 'gt',
      value: 60,
      windowSize: 10,
      windowSizeType: 'minutes',
      latestInstalledApps: [],
      isAppsLoaded: false,
      isCannedTriggersLoaded: false,
      isCustomTriggersLoaded: false,
      triggerChartMetrics: [],
      triggerInventory: [],
      customTriggers: [],
    }
    this.loadActions()
    this.loadCannedTriggers()
    this.loadCustomTriggers()
    this.loadClusters()
    this.loadAppSLB()
    this.loadMyApps()
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  getVersion(app: any) {
    const versionArr = app.version.split('v')
    let newVersion = ''
    if (versionArr && versionArr.length === 2) {
      newVersion = versionArr.length === 2 ? versionArr[1] : ''
    }
    return newVersion
  }

  loadMyApps = async () => {
    const {
      get: { DRILL_LEVEL: drillLevel, TARGET_URL },
    } = storage
    const apps: any[] = []
    let myApps: any
    if (drillLevel === 'provider') {
      const myAppsResponse = await this.DashboardService.getProviderAppRepo(
        null,
        null,
        [TARGET_URL],
      )
      myApps = myAppsResponse?.data
    } else {
      myApps = await this.Utilities.getLicensedApps(TARGET_URL)
    }
    this.setState({ isAppsLoaded: false })
    if (myApps) {
      const installedApps = myApps
      installedApps.forEach((app: any) => {
        const index = apps.findIndex(x => x.name === app.name)
        const newVersion = this.getVersion(app)
        // For New Apps
        if (index === -1) {
          app.versionNumber = newVersion
          apps.push(app)
        } else {
          // For Exisiting Apps with different version
          const exisitingVersion = this.getVersion(apps[index])
          if (newVersion > exisitingVersion) {
            app.versionNumber = newVersion // always take latest version of the app
            apps[index] = app
          }
        }
      })
      this.setState({ latestInstalledApps: apps }, () => {
        this.loadTriggerTemplates()
      })
    } else {
      this.setState({ isAppsLoaded: true })
    }
  }

  loadAppSLB = () => {
    // AppServices From App Root
    const {
      get: { PROVIDER },
    } = storage
    let appservices: any = []
    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const tenant = this.Utilities.getCurrentDrillLevelObject()

    const appservicesPromise = this.DashboardService.getApplications(
      headers,
      null,
      [PROVIDER, tenant.name],
    )

    appservicesPromise
      .then((response: any) => {
        appservices = response.data || []
        this.thunderSlbServicesOriginal = this.filterAppServices(
          appservices,
          'slb',
        )
        this.cgnv6ServicesOriginal = this.filterAppServices(
          appservices,
          'cgnv6',
        )
        this.fwServicesOriginal = this.filterAppServices(appservices, 'fw')
      })
      .catch((error: any) => {
        console.log(error.response) // we need to create a centralised error handling
      })
  }

  filterAppServices(appServices: any[], name): any[] {
    const filteredList = appServices.filter((service: any, index: number) => {
      if (service['app_svc_type'] === name) {
        return service
      }
      return false
    })
    return filteredList
  }

  loadActions = () => {
    const {
      get: { TARGET_URL: targetUrl },
    } = storage

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const actionResponse = this.DashboardService.getActions(
      headers,
      null,
      targetUrl,
    )

    actionResponse
      .then((response: any) => {
        const list = response.data
        this.setState({
          actionList: list,
          actionName: list.length + 1,
        })
      })
      .catch((error: any) => {
        const message = this.Utilities.returnErrorMessage(error)
        this.Utilities.showMessage('Unable to load actions', 0, 0, message)
        console.log(error) // we need to create a centralised error handling
      })
  }

  loadTriggerTemplates = () => {
    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const promises: any = []
    let chartBasedTriggers: any = []
    const installedApps = this.state.latestInstalledApps
    installedApps.forEach(app => {
      const triggerTemplateResponse = this.DashboardService.getTriggerTemplates(
        headers,
        null,
        [app.name, app.versionNumber],
      )
      promises.push(triggerTemplateResponse)
    })

    Promise.all(promises)
      .then((respArr: any) => {
        respArr.forEach((resp: any) => {
          if (resp && resp.data) {
            chartBasedTriggers = chartBasedTriggers.concat(resp.data)
          }
        })
        const triggerChartMetrics = this.buildChartMetricsTreeData(
          chartBasedTriggers,
        )
        this.setState({ isAppsLoaded: true, triggerChartMetrics })
      })
      .catch((error: any) => {
        this.setState({ isAppsLoaded: true })
        this.errorResolve(error.response)
      })
  }

  loadCustomTriggers = () => {
    const {
      get: { TARGET_URL: targetUrl },
    } = storage

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    this.setState({
      isCustomTriggersLoaded: false,
    })
    const triggerResponse = this.DashboardService.getTriggers(
      headers,
      null,
      targetUrl,
    )

    triggerResponse
      .then((response: any) => {
        if (response && response.data) {
          const customTriggers = this.buildTreeData(response.data)
          this.setState({
            customTriggers,
            isCustomTriggersLoaded: true,
          })
        } else {
          this.setState({
            customTriggers: [],
            isCustomTriggersLoaded: true,
          })
        }
      })
      .catch((error: any) => {
        this.setState({
          isCustomTriggersLoaded: true,
        })
      })
  }

  loadCannedTriggers = () => {
    const {
      get: { TARGET_URL: targetUrl },
    } = storage

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    this.setState({
      isCannedTriggersLoaded: false,
    })
    const triggerResponse = this.DashboardService.getCannedTriggers(
      headers,
      null,
      targetUrl,
    )

    triggerResponse
      .then((response: any) => {
        if (response && response.data) {
          const triggerInventory = this.buildTreeData(response.data)
          this.updateTriggerTree(triggerInventory)
          this.setState({
            triggersList: response.data,
            triggerInventory,
            isCannedTriggersLoaded: true,
          })
        }
      })
      .catch((error: any) => {
        this.setState({
          isCannedTriggersLoaded: true,
        })
        console.log(error.response) // we need to create a centralised error handling
      })
  }

  updateLeafProperties(obj: any) {
    let value = ''
    obj.isLeaf = true
    if (obj.metadata && obj.metadata.Trig_display_name) {
      obj.title = obj.metadata.Trig_display_name
      value =
        obj.metadata.Trig_display_name + this.delimiter + JSON.stringify(obj)
    } else {
      obj.title = obj.def_name
      value = obj.def_name + this.delimiter + JSON.stringify(obj)
    }
    obj.key = value
    return obj
  }

  buildChartMetricsTreeData(list: any[]) {
    const {
      storage: {
        get: { DRILL_LEVEL: drillLevel },
      },
    } = this.context

    let treeData = {}
    list.forEach((obj: any) => {
      const cannedTrigger = obj.definition
      const category = cannedTrigger.category
      // App Services should be allowed only at tenant level
      // Provider will only INFRASTRUCTURE && SYSTEM alerts
      if (
        (drillLevel === 'tenant' && category === APP_SERVICE)||
        (drillLevel === 'provider' &&
          (category === INFRASTRUCTURE || category === SYSTEM))
      ) {
        const subCategory = cannedTrigger['sub-category']
        const level1 = cannedTrigger.level1
        const level2 = cannedTrigger.level2
        const level3 = cannedTrigger.level3
        if (!treeData[subCategory]) {
          // create a new category
          treeData[subCategory] = {}
        }
        if (!treeData[subCategory][level1]) {
          treeData[subCategory][level1] = {}
        }
        if (!treeData[subCategory][level1][level2]) {
          treeData[subCategory][level1][level2] = []
        }
        if (!treeData[subCategory][level1][level2][level3]) {
          treeData[subCategory][level1][level2][level3] = []
          const leafObj = this.updateLeafProperties(obj)
          treeData[subCategory][level1][level2][level3].push(leafObj)
        } else {
          const leafObj = this.updateLeafProperties(obj)
          treeData[subCategory][level1][level2][level3].push(leafObj)
        }
      }
    })
    return this.formatChartMetricsTreeData(treeData)
  }

  buildTreeData(list: any[]) {
    const {
      storage: {
        get: { DRILL_LEVEL: drillLevel, IS_PARTITION_USER: isPartitionAdmin },
      },
    } = this.context

    const treeData = {}
    list.forEach((obj: any) => {
      const cannedTrigger = obj.definition
      const category = cannedTrigger.category
      let manageable = false

      // Category INFRASTRUCTURE and SYSTEM are allowed for both tenant and provider level
      // Other Categories like APP Service (which has sub categories like CGN, SLB and FW) are only allowed for tenant
      if (
        (drillLevel === 'tenant' && category === APP_SERVICE)||
        (drillLevel === 'provider' &&
          (category === INFRASTRUCTURE || category === SYSTEM))
      ) {
        manageable = true
      }

      // Partition Admin is meant to only manage App Services
      if (isPartitionAdmin && category !== APP_SERVICE) {
        manageable = false
      }

      if (manageable) {
        const subCategory = cannedTrigger['sub-category']
        const objectType = cannedTrigger?.object_type
        if (!treeData[category]) {
          // create a new category
          treeData[category] = {}
        }
        if (!treeData[category][subCategory]) {
          treeData[category][subCategory] = {}
        }
        if (!treeData[category][subCategory][objectType]) {
          treeData[category][subCategory][objectType] = []
          const leafObj = this.updateLeafProperties(obj)
          treeData[category][subCategory][objectType].push(leafObj)
        } else {
          const leafObj = this.updateLeafProperties(obj)
          treeData[category][subCategory][objectType].push(leafObj)
        }
      }
    })
    return this.formatTreeData(treeData)
  }

  formatChartMetricsTreeData(treeData: any) {
    const finalTreeData = []
    // nodeCounter used to make node key unqiue
    this.nodeCounter = 0
    if (Object.keys(treeData).length > 0) {
      for (const [subCategoryKey, subCategoryValue] of Object.entries(
        treeData,
      )) {
        this.nodeCounter = this.nodeCounter + 1
        const subCategoryObj = {
          isRoot: true,
          title: subCategoryKey,
          value: subCategoryKey,
          disableCheckbox: true,
          disabled: true,
          children: [],
          nodeCounter: this.nodeCounter,
        }
        for (const [level1Key, level1KeyValue] of Object.entries(
          subCategoryValue,
        )) {
          this.nodeCounter = this.nodeCounter + 1
          const level1Obj = {
            isRoot: false,
            title: level1Key,
            value: level1Key,
            disableCheckbox: true,
            disabled: true,
            children: [],
            nodeCounter: this.nodeCounter,
          }
          for (const [level2Key, level2KeyValue] of Object.entries(
            level1KeyValue,
          )) {
            this.nodeCounter = this.nodeCounter + 1
            const level2Obj = {
              isRoot: false,
              title: level2Key,
              value: level2Key,
              disableCheckbox: true,
              disabled: true,
              children: [],
              nodeCounter: this.nodeCounter,
            }
            for (const [level3Key, level3KeyValue] of Object.entries(
              level2KeyValue,
            )) {
              this.nodeCounter = this.nodeCounter + 1
              const level3Obj = {
                isRoot: false,
                title: level3Key,
                value: level3Key,
                disableCheckbox: true,
                disabled: true,
                children: level3KeyValue,
                nodeCounter: this.nodeCounter,
              }
              // For Dashboard we dont need second level, all nodes should be under dashboard
              if (level2Key === 'all') {
                level1Obj.children.push(level3Obj)
              } else {
                level2Obj.children.push(level3Obj)
              }
            }
            // Only Dashboard, we dont need 2nd level(info graphic level), for rest we need info graphic level
            if (level2Key !== 'all') {
              level1Obj.children.push(level2Obj)
            }
          }

          subCategoryObj.children.push(level1Obj)
        }
        finalTreeData.push(subCategoryObj)
      }
    }
    return finalTreeData
  }

  formatTreeData(treeData: any) {
    const finalTreeData = []
    // nodeCounter used to make node key unqiue
    this.nodeCounter = 0
    if (Object.keys(treeData).length > 0) {
      for (let [categoryKey, categoryValue] of Object.entries(treeData)) {
        this.nodeCounter = this.nodeCounter + 1
        const categoryObj = {
          isRoot: true,
          title: categoryKey,
          value: categoryKey,
          disableCheckbox: true,
          disabled: true,
          children: [],
          nodeCounter: this.nodeCounter,
        }
        for (let [subCategoryKey, subCategoryValue] of Object.entries(
          categoryValue,
        )) {
          this.nodeCounter = this.nodeCounter + 1
          const subCategoryObj = {
            isRoot: false,
            title: subCategoryKey,
            value: subCategoryKey,
            disableCheckbox: true,
            disabled: true,
            children: [],
            nodeCounter: this.nodeCounter,
          }
          for (let [objectTypeKey, objectTypeValue] of Object.entries(
            subCategoryValue,
          )) {
            this.nodeCounter = this.nodeCounter + 1
            const objectTypeObj = {
              isRoot: false,
              title: objectTypeKey,
              value: objectTypeKey,
              disableCheckbox: true,
              disabled: true,
              children: objectTypeValue,
              nodeCounter: this.nodeCounter,
            }
            subCategoryObj.children.push(objectTypeObj)
          }
          categoryObj.children.push(subCategoryObj)
        }
        finalTreeData.push(categoryObj)
      }
    }
    return finalTreeData
  }

  updateTriggerTree(finalTreeData: any) {
    this.setState({
      triggerTreeData: finalTreeData,
    })
  }

  addPostObject = () => {
    const actionObj = this.state
    const postArr = actionObj.postArr
    postArr.push({
      uri: '',
      extended_body: '',
      headers: '',
      ssc: false,
    })
    this.setState({
      postArr,
    })
    actionObj.postArr = postArr
    this.onChangeCallback(actionObj)
  }

  handleChange = (stateName: string, e: any, index?: number) => {
    if (!this.Utilities.validateField(e)) {
      return
    }
    let treeObj: any
    const alertObj = this.state
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        alertObj[stateName] = e.target.checked
      } else {
        alertObj[stateName] = e.target.value
      }
    } else {
      alertObj[stateName] = e
    }
    if (stateName === 'selectedTrigger') {
      if (e[0]) {
        const valueArr = e[0].split(this.delimiter)
        if (valueArr.length === 2) {
          alertObj[stateName] = JSON.parse(valueArr[1])
          this.setState(
            {
              selectedTrigger: JSON.parse(valueArr[1]),
              selectedObjects: [],
              treeData: [],
            },
            () => {
              treeObj = this.renderTreeSelector()

              if (treeObj) {
                this.updateTreeData(treeObj)
              }
            },
          )
        }
      }
    } else if (stateName === 'triggerType') {
      this.nodeCounter = 0
      const selectedValue = alertObj[stateName]
      let triggerTree = []
      if (selectedValue === 'fromtriggerinventory') {
        triggerTree = JSON.parse(JSON.stringify(this.state.triggerInventory))
      } else if (selectedValue === 'fromcustomtriggers') {
        triggerTree = JSON.parse(JSON.stringify(this.state.customTriggers))
      } else {
        triggerTree = JSON.parse(JSON.stringify(this.state.triggerChartMetrics))
      }
      this.setState(
        {
          triggerType: selectedValue,
          triggerTreeData: triggerTree,
          selectedObjects: [],
          treeData: [],
        },
        () => {
          treeObj = this.renderTreeSelector()
          if (treeObj) {
            this.updateTreeData(treeObj)
          } else {
            const emptyTreeObj = { treeData: [] }
            this.updateTreeData(emptyTreeObj)
          }
        },
      )
    } else {
      // @ts-ignore
      this.setState({
        [stateName]: alertObj[stateName],
      })
    }
    this.onChangeCallback(alertObj)
  }

  updateTreeData(treeObj: any) {
    this.setState({
      treeData: treeObj.treeData,
    })
  }

  handleUsers = (stateName: string, value: any, index?: number) => {
    if (this.Utilities.validateEmail(value)) {
      this.handleChange(stateName, value)
    }
  }

  handleUserEmailValidation = (rule: any, value: any, cb: any) => {
    return this.Utilities.validateEmail(value) ? cb() : cb(true)
  }

  onChangeCallback(data: any) {
    this.props.handleChange(data)
  }

  validateDuplicateAlertName = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const index = this.props.alerts.findIndex((key: any) => {
      return (
        (key.def_name && key.def_name === value) ||
        (key.metadata &&
          key.metadata.def_name &&
          key.metadata.def_name === value)
      )
    })
    const userRegx = new RegExp(/^[A-Za-z]([a-zA-Z0-9_ ]){1,50}$/)
    if ('' === value) {
      rule.message = this.Messages.EMPTY_ALERT_NAME
      isValid = false
    } else if (value && value.length > 50) {
      rule.message = this.Messages.VALIDATION_ALERT_NAME_LENGTH
      isValid = false
    } else if (value && value.length <= 1) {
      rule.message = this.Messages.VALIDATION_ALERT_NAME_MIN_LENGTH
      isValid = false
    } else if (!userRegx.test(value)) {
      rule.message = this.Messages.ALERT_NAME_INVALID
      isValid = false
    } else if (index > -1) {
      rule.message = this.Messages.DUPLICATE_ALERT_NAME
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validatePost = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const postRegx = new RegExp(
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    )
    const { notificationType } = this.state
    if (
      value &&
      (notificationType === 'webhook' || notificationType === 'both') &&
      !postRegx.test(value)
    ) {
      rule.message = this.Messages.INVALID_POST_TO_URI
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateHeaders = (index: any, rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    if ('' === value) {
      isValid = true
    } else if (value && value.length > 0) {
      const valueTemp = value.trim()
      const stringValue = valueTemp.substring(1, valueTemp.length - 1)
      if (!this.Utilities.IsValidJSONString(stringValue)) {
        isValid = false
        rule.message = this.Messages.VALID_JSON
      } else {
        this.handleChange('headers', JSON.parse(stringValue), index)
      }
    }
    return isValid ? cb() : cb(true)
  }

  removePost = (index: number) => {
    const actionObj = this.state
    const postArr = actionObj.postArr
    postArr.splice(index, 1)
    this.setState({
      postArr,
    })
    actionObj.postArr = postArr
    this.onChangeCallback(actionObj)
  }

  renderTriggerTreeNodes = (data: any) =>
    data.map((item: any) => {
      if (item.children) {
        return (
          <TreeNode
            title={item.title}
            key={this.delimiter + item.value + this.delimiter}
            dataRef={item}
          >
            {this.renderTriggerTreeNodes(item.children)}
          </TreeNode>
        )
      }
      return (
        <TreeNode
          key={this.delimiter + item.def_name + this.delimiter}
          {...item}
          dataRef={item}
        />
      )
    })

  createIcon(type: string, text: string, title: string) {
    return (
      <>
        <A10Tooltip title={title}>
          <HealthStatus
            type={type}
            text={text}
            className="tree-icon"
            hideTooltip={true}
          />
          {title}
        </A10Tooltip>
      </>
    )
  }

  loadClusters = () => {
    const {
      get: { PROVIDER, DRILL_LEVEL },
    } = storage

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(
      false,
      true,
    )
    if (!headers) {
      return
    }
    const currentTenant = this.Utilities.getCurrentDrillLevelObject()
    const clusterResponse = this.InfrastructureService.getClusters(
      headers,
      null,
      PROVIDER,
    )

    clusterResponse
      .then((response: any) => {
        this.clusters = response.data['cluster-list']
        if (this.clusters && this.clusters.length > 0) {
          const drillLevel = DRILL_LEVEL
          if (drillLevel !== 'provider') {
            this.clusters = this.clusters.filter(cluster => {
              const partitionList = cluster['partition-list']
              if (partitionList && partitionList.length > 0 && currentTenant) {
                const pos = partitionList
                  .map(e => e['tenant-uuid'])
                  .indexOf(currentTenant.uuid)
                return pos > -1 ? cluster : false
              }
              return false
            })
          }
          this.loadDevices()
        } else {
          this.clusters = []
          this.setState({
            clustersLoaded: true,
          })
        }
      })
      .catch((error: any) => {
        console.log(error.response) // we need to create a centralised error handling
      })
  }

  loadDevices = () => {
    const {
      storage: {
        get: { DRILL_LEVEL, PROVIDER },
      },
    } = this.context

    const deviceResps: any[] = []
    const currentTenant = this.Utilities.getCurrentDrillLevelObject()
    if (this.clusters.length > 0) {
      this.clusters.forEach((cluster: any, i: number) => {
        cluster.title = this.createIcon(
          'ongoing',
          this.iconsNames.cluster,
          cluster.name,
        )

        cluster.value = JSON.stringify({
          cluster: cluster['cluster-uuid'],
          display_name: cluster.name,
        })
        cluster.disabled = true
        cluster.isRoot = true
        cluster.isLeaf = false
        cluster.selectable = false
        cluster.disableCheckbox = true
        const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(
          false,
          true,
        )
        if (!headers) {
          this.setState({
            clustersLoaded: false,
          })
          return
        }
        const deviceResponse = this.InfrastructureService.getClusterDevices(
          headers,
          null,
          [PROVIDER, cluster.name],
        )
        deviceResps.push(deviceResponse)
      })
    } else {
      this.setState({
        clustersLoaded: true,
      })
    }

    Promise.all(deviceResps).then((resp: any) => {
      this.clusters.forEach((cluster: any, i: number) => {
        const partitionListCLuster = cluster['partition-list'] || []
        let partitionListForSelectedTenant = []
        const drillLevel = DRILL_LEVEL
        if (drillLevel !== 'provider') {
          partitionListForSelectedTenant =
            partitionListCLuster &&
            partitionListCLuster.filter(obj => {
              return obj['tenant-uuid'] === currentTenant.uuid
            })
        } else {
          partitionListForSelectedTenant = JSON.parse(
            JSON.stringify(partitionListCLuster),
          )
        }

        let deviceList = resp[i].data['device-list'] || []
        if (deviceList.length > 0) {
          deviceList = deviceList.filter(obj => obj.cluster === cluster.name)
          deviceList.forEach((device: any) => {
            device.title = this.createIcon(
              'ongoing',
              this.iconsNames.device,
              device.name,
            )
            device.value = JSON.stringify({
              cluster: cluster['cluster-uuid'],
              device: device['device-uuid'],
              partition: undefined,
              device_cluster_name: cluster.name,
              device_name: device.name,
              partition_name: undefined,
              display_name: cluster.name,
            })
            device.disabled = false
            device.selectable = false
            const partitionListDevice = device['partition-list']
            const partitionList =
              partitionListDevice &&
              partitionListDevice.filter(obj => {
                const pos = partitionListForSelectedTenant
                  .map(e => e.id)
                  .indexOf(obj.id)
                return pos > -1 ? obj : false
              })
            if (partitionList && partitionList.length > 0) {
              partitionList.forEach((partition: any) => {
                partition.title = this.createIcon(
                  'ongoing',
                  this.iconsNames.partition,
                  partition.name,
                )
                partition.isLeaf = true
                partition.checkable = true
                partition.value = JSON.stringify({
                  cluster: cluster['cluster-uuid'],
                  device: device['device-uuid'],
                  partition: partition.id,
                  device_cluster_name: cluster.name,
                  device_name: device.name,
                  partition_name: partition.name,
                  display_name: cluster.name,
                })
                partition.selectable = false
                partition.hierarchy = JSON.stringify({
                  device_cluster_name: cluster.name,
                  device_name: device.name,
                  partition_name: partition.name,
                })
              })
              device.children = partitionList
              device.backUpChildren = [...device.children]
            }
          })
        }
        cluster.children = deviceList
      })
      this.setState({
        clustersLoaded: true,
      })
    })
  }

  generateTreeDataCgnv6(selectedTrigger: any, isFilterBYAppSvcID: boolean) {
    let tempServiceList = []
    if (
      this.cgnv6NameMapping[selectedTrigger?.definition?.object_type] ===
      'ports'
    ) {
      tempServiceList = JSON.parse(JSON.stringify(this.cgnv6ServicesOriginal))
    } else if (
      this.cgnv6NameMapping[selectedTrigger?.definition?.object_type] === 'pool'
    ) {
      tempServiceList = JSON.parse(JSON.stringify(this.cgnv6ServicesOriginal))
      tempServiceList = tempServiceList.filter((app: any) => {
        return (
          app.name.toLowerCase() === 'lsn' ||
          app.name.toLowerCase() === 'ds-lite' ||
          app.name.toLowerCase() === 'nat64'
        )
      })
    } else {
      tempServiceList = JSON.parse(JSON.stringify(this.cgnv6ServicesOriginal))
      tempServiceList = tempServiceList.filter((app: any) => {
        return (
          app.name.toLowerCase() ===
          this.cgnv6NameMapping[selectedTrigger?.definition?.object_type]
        )
      })
    }
    tempServiceList = tempServiceList.filter((obj: any) => {
      if (obj.associationState === 'Activated') {
        return obj
      }
    })
    tempServiceList.forEach((svc: any, i: number) => {
      svc.title = this.createIcon(
        'ongoing',
        this.iconsNames['appService'],
        `${svc.name} (${svc.lp_name})`,
      )
      let partition_uuid = svc.partition_uuid
      if (svc.partition_uuid) {
        partition_uuid = this.getCPuuid(svc.partition_uuid)
      }
      svc.value = JSON.stringify({
        app_svc_id: isFilterBYAppSvcID ? svc.app_svc_id : undefined,
        lp_name: svc.lp_name,
        cluster_id: svc.device_cluster_id,
        partition_uuid: partition_uuid || svc.partition_uuid,
        device_cluster_id: svc.device_cluster_id,
        name: svc.name,
        display_name: svc.displayName,
      })
      svc.hierarchy = JSON.stringify({
        app_svc_name: svc.title,
      })
      svc.disabled = true
      svc.isRoot = true
      svc.isLeaf = false
      svc.disableCheckbox = true
      svc.selectable = false
    })
    return tempServiceList
  }

  generateTreeDataFW(isFilterBYAppSvcID: boolean) {
    let tempServiceList = JSON.parse(JSON.stringify(this.fwServicesOriginal))
    tempServiceList = tempServiceList.filter((obj: any) => {
      if (obj.associationState === 'Activated') {
        return obj
      }
    })
    tempServiceList.forEach((svc: any, i: number) => {
      svc.title = this.createIcon(
        'ongoing',
        this.iconsNames.appService,
        `${svc.name} (${svc.lp_name})`,
      )
      let partition_uuid = svc.partition_uuid
      if (svc.partition_uuid) {
        partition_uuid = this.getCPuuid(svc.partition_uuid)
      }
      svc.value = JSON.stringify({
        app_svc_id: isFilterBYAppSvcID ? svc.app_svc_id : undefined,
        lp_name: svc.lp_name,
        cluster_id: svc.device_cluster_id,
        partition_uuid: partition_uuid || svc.partition_uuid,
        device_cluster_id: svc.device_cluster_id,
        name: svc.name,
        display_name: svc.displayName,
      })
      svc.hierarchy = JSON.stringify({
        app_svc_name: svc.title,
      })
      svc.disabled = true
      svc.isRoot = true
      svc.isLeaf = false
      svc.disableCheckbox = true
      svc.selectable = false
    })
    return tempServiceList
  }

  generateTreeDataSlb(isFilterBYAppSvcID: boolean) {
    let tempServiceList = JSON.parse(
      JSON.stringify(this.thunderSlbServicesOriginal),
    )
    const objectType = this.state.selectedTrigger?.definition?.['object_type']
    let considerAppSvcID = false

    tempServiceList = tempServiceList.filter((obj: any) => {
      if (obj.associationState === 'Activated') {
        return obj
      }
    })
    tempServiceList.forEach((svc: any, i: number) => {
      svc.title = this.createIcon(
        'ongoing',
        this.iconsNames.appService,
        `${svc.name} (${svc.lp_name})`,
      )
      let appSvcID
      if (isFilterBYAppSvcID) {
        appSvcID = svc.app_svc_id
      } else if (considerAppSvcID && svc.protocol === 'http') {
        appSvcID = svc.app_svc_id
      }
      let partition_uuid = svc.partition_uuid
      if (svc.partition_uuid) {
        partition_uuid = this.getCPuuid(svc.partition_uuid)
      }
      svc.value = JSON.stringify({
        app_svc_id: appSvcID,
        lp_name: svc.lp_name,
        cluster_id: svc.device_cluster_id,
        partition_uuid: partition_uuid || svc.partition_uuid,
        device_cluster_id: svc.device_cluster_id,
        name: svc.name,
        display_name: svc.displayName,
      })
      svc.hierarchy = JSON.stringify({
        app_svc_name: svc.title,
      })
      svc.disabled = true
      svc.isRoot = true
      svc.isLeaf = false
      svc.disableCheckbox = true
      svc.selectable = false
    })
    if (objectType === 'HTTP protocol' || objectType === 'HTTP2 protocol') {
      tempServiceList = tempServiceList.filter((obj: any) => {
        if (obj.protocol === 'http' || obj.protocol === 'https') {
          return obj
        }
      })
    }

    if (objectType === 'L4 Protocols') {
      tempServiceList = tempServiceList.filter((obj: any) => {
        if (obj.protocol !== 'http' && obj.protocol !== 'https') {
          return obj
        }
      })
    }

    return tempServiceList
  }

  generateTreeDataInfra(subCategory: string, objectType: string) {
    this.clusters.forEach((cluster: any, i: number) => {
      cluster.disabled = true
      cluster.isRoot = true
      cluster.isLeaf = false
      cluster.disableCheckbox = true
      cluster.selectable = false
      if (cluster.children && cluster.children.length > 0) {
        cluster.children.forEach((device: any) => {
          // Show only devices when sub Category is Cluster Device
          const isClusterDevice =
            subCategory === 'Cluster Device' &&
            (objectType === 'Resource' || objectType === 'Traffic')
          const isClusterPartition =
            subCategory === 'Partition Logical Cluster' &&
            (objectType === 'Resource' || objectType === 'Traffic')

          device.disabled = !isClusterDevice
          device.isLeaf = isClusterDevice
          device.disableCheckbox = !isClusterDevice
          device.selectable = isClusterDevice
          if (isClusterDevice) {
            device.children = null
          } else {
            if (device.backUpChildren && device.backUpChildren.length > 0) {
              device.children = [...device.backUpChildren]
            } else {
              device.children = []
            }
          }
          if (device.children && device.children.length > 0) {
            device.children.forEach((partition: any) => {
              partition.isLeaf = true
              partition.disableCheckbox = false
              partition.selectable = true
              if (isClusterPartition) {
                const value = JSON.parse(partition.value)
                if (value) {
                  value.device = undefined
                  partition.value = JSON.stringify(value)
                }
              } else {
                const value = JSON.parse(partition.value)
                if (value) {
                  value.device = device['device-uuid']
                  partition.value = JSON.stringify(value)
                }
              }
            })
          }
        })
      }
    })
    return this.clusters
  }

  getCPuuid = (cpUuid: string) => {
    let partition_uuid = ''
    this.clusters.some((cluster: any, i: number) => {
      return (
        cluster['partition-list'] &&
        cluster['partition-list'].some((partition: any) => {
          if (partition['partition-uuid'] === cpUuid) {
            partition_uuid = partition.uuid
            return true
          }
          return false
        })
      )
    })
    return partition_uuid
  }

  renderTreeSelector = (): any => {
    let treeData = []
    // Return if selectedTrigger not selected
    if (
      !(
        this.state.selectedTrigger &&
        this.state.selectedTrigger?.definition?.['object_type']
      )
    ) {
      return
    }
    let isFilterBYAppSvcID = false
    if (
      this.filterBYAppSvcID.indexOf(
        this.state.selectedTrigger?.definition?.['object_type'],
      ) > -1
    ) {
      isFilterBYAppSvcID = true
    }
    if (
      this.state.selectedTrigger &&
      this.clustersBelongs.indexOf(
        this.state.selectedTrigger.definition.category.toLowerCase(),
      ) > -1
    ) {
      treeData = this.generateTreeDataInfra(
        this.state.selectedTrigger?.definition?.['sub-category'],
        this.state.selectedTrigger?.definition?.['object_type'],
      )
      return {
        treeData,
      }
    } else if (
      this.state.selectedTrigger &&
      this.state.selectedTrigger.definition['sub-category'] === 'SLB'
    ) {
      treeData = this.generateTreeDataSlb(isFilterBYAppSvcID)
      return {
        treeData,
      }
    } else if (
      this.state.selectedTrigger &&
      this.state.selectedTrigger.definition['sub-category'] === 'CGNv6'
    ) {
      treeData = this.generateTreeDataCgnv6(
        this.state.selectedTrigger,
        isFilterBYAppSvcID,
      )
      return {
        treeData,
      }
    } else if (
      this.state.selectedTrigger &&
      this.state.selectedTrigger.definition['sub-category'] === 'FW'
    ) {
      treeData = this.generateTreeDataFW(isFilterBYAppSvcID)
      return {
        treeData,
      }
    }
  }

  updateListTitleValueCgnv6 = (
    list: any,
    parentNodeValue: any,
    hierarchy: any,
    fieldName: string,
    iconName: string,
    attribute?: string,
    staticName?: string,
  ) => {
    if (Array.isArray(list)) {
      list.forEach((sg: any, i: number) => {
        sg.title = this.createIcon(
          'ongoing',
          iconName,
          attribute ? sg[attribute] : sg.name,
        )
        sg.isLeaf = true
        sg.isJSONValue = true
        parentNodeValue[fieldName] = sg.uuid
        hierarchy[fieldName] = sg.title
        sg.value = JSON.stringify(parentNodeValue)
        sg.hierarchy = JSON.stringify(hierarchy)
      })
    } else {
      parentNodeValue[fieldName] = list.uuid
      hierarchy[fieldName] = staticName
      const nodeValue = JSON.stringify(parentNodeValue)
      list = [
        {
          title: this.createIcon('ongoing', iconName, staticName),
          value: nodeValue,
          isLeaf: true,
          isJSONValue: true,
          hierarchy: JSON.stringify(hierarchy),
        },
      ]
    }

    return list
  }

  updateChildSameAsParent = (
    name: any,
    parentNodeValue: any,
    hierarchy: any,
    iconName: string,
  ) => {
    parentNodeValue['only_app_svc_id'] = true
    const nodeValue = JSON.stringify(parentNodeValue)
    hierarchy[name] = name
    const list = [
      {
        title: this.createIcon('ongoing', iconName, name),
        value: nodeValue,
        isLeaf: true,
        isJSONValue: true,
        hierarchy: JSON.stringify(hierarchy),
      },
    ]
    return list
  }

  updateListTitleValue = (
    list: any,
    parentNodeValue: any,
    hierarchy: any,
    fieldName: string,
    iconName: string,
    level: string,
    isLeaf: boolean,
    disabled: boolean,
    attribute?: string,
    onlyAppSvcID?: boolean,
  ) => {
    list.forEach((sg: any, i: number) => {
      sg.title = this.createIcon(
        'ongoing',
        iconName,
        attribute ? sg[attribute] : sg.name,
      )
      sg.level = level
      sg.isLeaf = isLeaf
      sg.isJSONValue = true
      sg.disabled = disabled
      if (onlyAppSvcID) {
        parentNodeValue['only_app_svc_id'] = true
      }
      parentNodeValue[fieldName] = sg.uuid
      hierarchy[fieldName] = sg.title
      sg.hierarchy = JSON.stringify(hierarchy)
      sg.value = JSON.stringify(parentNodeValue)
    })
    return list
  }

  getSelfObjects = (selectedObj: any, paramsArr: any) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const tenant = this.Utilities.getCurrentDrillLevelObject()
    const serviceGroupPromise = this.DashboardService.getSelfObjects(
      headers,
      null,
      [PROVIDER, tenant.name, selectedObj, paramsArr[0], paramsArr[1]],
    )
    return serviceGroupPromise
  }

  getCgnv6Tech = (selectedObj: any, paramsArr: any) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const tenant = this.Utilities.getCurrentDrillLevelObject()
    const serviceGroupPromise = this.DashboardService.getCgnv6Tech(
      headers,
      null,
      [PROVIDER, tenant.name, selectedObj, paramsArr[0], paramsArr[1]],
    )
    return serviceGroupPromise
  }
  getTreeNodes = (selectedObj: any, paramsArr: any) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const tenant = this.Utilities.getCurrentDrillLevelObject()
    const serviceGroupPromise = this.DashboardService.getTreeNodes(
      headers,
      null,
      [PROVIDER, tenant.name, selectedObj, paramsArr[0]],
    )
    return serviceGroupPromise
  }

  getTreeNodesFW = (selectedObj: any, paramsArr: any) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const tenant = this.Utilities.getCurrentDrillLevelObject()
    const serviceGroupPromise = this.DashboardService.getTreeNodesFW(
      headers,
      null,
      [PROVIDER, tenant.name, selectedObj, paramsArr[0]],
    )
    return serviceGroupPromise
  }

  getServiceGroup = (selectedObj: any) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const tenant = this.Utilities.getCurrentDrillLevelObject()
    const serviceGroupPromise = this.DashboardService.getServiceGroup(
      headers,
      null,
      [PROVIDER, tenant.name, selectedObj.vport],
    )
    return serviceGroupPromise
  }

  getSLBVirtualServer = (lpName: any, name: any) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const tenant = this.Utilities.getCurrentDrillLevelObject()
    const serviceGroupPromise = this.DashboardService.getSLBVirtualServer(
      headers,
      null,
      [PROVIDER, tenant.name, lpName, name],
    )
    return serviceGroupPromise
  }

  getSLBServiceGroup = (lpName: any, name: any) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const tenant = this.Utilities.getCurrentDrillLevelObject()
    const serviceGroupPromise = this.DashboardService.getSLBServiceGroup(
      headers,
      null,
      [PROVIDER, tenant.name, lpName, name],
    )
    return serviceGroupPromise
  }

  errorResolve(error: any, resolve?: any) {
    const { showMessage } = this.Utilities
    const data = error && error.data
    if (data && data.response && data.response.err) {
      showMessage('FAILED_LOAD', 0, 1, data.response.err.msg)
    }
    if (resolve) {
      resolve()
    }
  }

  lazyLoadFWNodes(treeNode: any, resolve: any) {
    if (treeNode.props.children || treeNode.props.isLeaf) {
      resolve()
      return
    }
    const objectType = this.state.selectedTrigger?.definition?.['object_type']
    const finalNodeValue = JSON.parse(treeNode.props.dataRef.value)
    const hierarchy = JSON.parse(treeNode.props.dataRef.hierarchy)
    const paramsArr = this.fwObjectMapping[objectType]
    if (objectType === 'Rule') {
      this.getTreeNodesFW(treeNode.props.lp_name, paramsArr)
        .then((response: any) => {
          let childList = []
          if (response.data['rule-set-list']) {
            childList = response.data['rule-set-list']
          }
          let list = childList.filter((child: any) => {
            return (
              child['rule-list'] !== undefined &&
              child.name === treeNode.props.name
            )
          })
          childList = list
          const isLeaf = objectType === 'Rule' ? true : false
          treeNode.props.dataRef.children = this.conslidateParentChildList(
            childList,
            finalNodeValue,
            hierarchy,
            'rule_set_uuid',
            this.iconsNames.ruleSet,
            !isLeaf,
            'rule-list',
            'rule_uuid',
            'name',
            'Rule',
            false,
          )
          this.setState(
            {
              treeData: [...this.state.treeData],
            },
            () => {
              resolve()
            },
          )
        })
        .catch((error: any) => {
          console.log(error.response)
          this.errorResolve(error.response, resolve)
        })
    } else if (objectType === 'Rule Set') {
      treeNode.props.dataRef.children = this.updateChildSameAsParent(
        treeNode.props.dataRef.name,
        finalNodeValue,
        hierarchy,
        this.iconsNames.ruleSet,
      )
      this.setState(
        {
          treeData: [...this.state.treeData],
        },
        () => {
          resolve()
        },
      )
    } else if (objectType === 'FW Global') {
      const paramsArrI = this.slbSelfObjUrls[objectType]
      this.getSelfObjects(finalNodeValue['lp_name'], paramsArrI)
        .then((response: any) => {
          let childList
          if (response.data[paramsArrI[1]]) {
            childList = response.data[paramsArrI[1]]
          }
          treeNode.props.dataRef.children = this.updateListTitleValueCgnv6(
            childList,
            finalNodeValue,
            hierarchy,
            'o_uuid',
            this.iconsNames[paramsArrI[1]],
            null,
            paramsArrI[1],
          )
          this.setState(
            {
              treeData: [...this.state.treeData],
            },
            () => {
              resolve()
            },
          )
        })
        .catch((error: any) => {
          console.log(error.response)
          this.errorResolve(error.response, resolve)
        })
    } else {
      resolve()
    }
  }

  lazyLoadCgnv6Nodes(treeNode: any, resolve: any) {
    if (treeNode.props.children || treeNode.props.isLeaf) {
      resolve()
      return
    }
    const objectType = this.state.selectedTrigger?.definition?.['object_type']
    const finalNodeValue = JSON.parse(treeNode.props.dataRef.value)
    const hierarchy = JSON.parse(treeNode.props.dataRef.hierarchy)
    const paramsArr = this.cgnv6ObjectMapping[objectType]
    if (objectType === 'Pool') {
      this.getCgnv6Tech(treeNode.props.lp_name, paramsArr)
        .then((response: any) => {
          let childList = []
          let attribute = 'name'
          if (response.data['global']) {
            childList = response.data['global']
          } else if (response.data['pool-list']) {
            childList = response.data['pool-list']
            attribute = 'pool-name'
          }
          treeNode.props.dataRef.children = this.updateListTitleValueCgnv6(
            childList,
            finalNodeValue,
            hierarchy,
            'o_uuid',
            this.iconsNames.pool,
            attribute,
            this.cgnv6NameMapping[objectType],
          )
          this.setState(
            {
              treeData: [...this.state.treeData],
            },
            () => {
              resolve()
            },
          )
        })
        .catch((error: any) => {
          console.log(error.response)
          this.errorResolve(error.response, resolve)
        })
    } else if (objectType === 'Ports') {
      this.getTreeNodes(treeNode.props.lp_name, paramsArr)
        .then((response: any) => {
          let childList = []
          if (response.data['global']) {
            childList = response.data['global']
          }
          treeNode.props.dataRef.children = this.updateListTitleValueCgnv6(
            childList,
            finalNodeValue,
            hierarchy,
            'o_uuid',
            this.iconsNames.ports,
            '',
            this.cgnv6NameMapping[objectType],
          )
          this.setState(
            {
              treeData: [...this.state.treeData],
            },
            () => {
              resolve()
            },
          )
        })
        .catch((error: any) => {
          console.log(error.response)
          this.errorResolve(error.response, resolve)
        })
    } else {
      treeNode.props.dataRef.children = this.updateChildSameAsParent(
        treeNode.props.dataRef.name,
        finalNodeValue,
        hierarchy,
        this.iconsNames.technology,
      )
      this.setState(
        {
          treeData: [...this.state.treeData],
        },
        () => {
          resolve()
        },
      )
    }
  }

  lazyLoadSLBNodes(treeNode: any, resolve: any) {
    if (treeNode.props.children || treeNode.props.isLeaf) {
      resolve()
      return
    }
    const objectType = this.state.selectedTrigger?.definition?.['object_type']
    const finalNodeValue = JSON.parse(treeNode.props.dataRef.value)
    const hierarchy = JSON.parse(treeNode.props.dataRef.hierarchy)
    const currentNode = treeNode.props.dataRef
    if (treeNode.props.isRoot) {
      const nameArr = treeNode.props.name.split('_')
      const arrLength = nameArr.length
      let name = ''
      let portNumber = ''
      if (arrLength > 0) {
        // Minus -2 for port and protocol
        name = nameArr.splice(0, arrLength - 2).join('_')
        portNumber = nameArr[0]
      }
      const isVIP = this.vipList.indexOf(objectType) > -1 ? true : false
      this.getSLBVirtualServer(treeNode.props.lp_name, name)
        .then((response: any) => {
          let virtualObj = response.data['virtual-server']
          let filterVirtualObj = { ...virtualObj }
          filterVirtualObj['port-list'] = filterVirtualObj['port-list'].filter(
            (obj: any) => {
              if (obj['port-number'] == portNumber) {
                return obj
              }
            },
          )
          if (filterVirtualObj['port-list']) {
            if (
              objectType === 'HTTP protocol' ||
              objectType === 'HTTP2 protocol'
            ) {
              filterVirtualObj['port-list'] = filterVirtualObj[
                'port-list'
              ].filter((obj: any) => {
                if (obj.protocol === 'http' || obj.protocol === 'https') {
                  return obj
                }
              })
            }

            if (objectType === 'L4 Protocols') {
              filterVirtualObj['port-list'] = filterVirtualObj[
                'port-list'
              ].filter((obj: any) => {
                if (obj.protocol !== 'http' && obj.protocol !== 'https') {
                  return obj
                }
              })
            }
          }
          virtualObj = filterVirtualObj
          treeNode.props.dataRef.children = this.updateListTitleValue(
            [virtualObj],
            finalNodeValue,
            hierarchy,
            'vip',
            this.iconsNames.vip,
            'VIP',
            isVIP,
            true,
            undefined,
            isVIP,
          )
          this.setState(
            {
              treeData: [...this.state.treeData],
            },
            () => {
              resolve()
            },
          )
        })
        .catch((error: any) => {
          console.log(error.response)
          this.errorResolve(error.response, resolve)
        })
    }
    // Level is used to Differenitate VPORT, Service Group, Server and Port
    if (treeNode.props.isJSONValue) {
      let isVport = this.vportList.indexOf(objectType) > -1 ? true : false
      const levelIndex = this.levels.indexOf(treeNode.props.level)
      let level = 'VPORT'
      if (levelIndex === 0) {
        if (!isVport && this.slbSelfObjects.indexOf(objectType) > -1) {
          level = objectType
          isVport = false
        }
        const vportList = treeNode.props.dataRef['port-list']
        treeNode.props.dataRef.children = this.updateListTitleValue(
          vportList,
          finalNodeValue,
          hierarchy,
          'vport',
          this.iconsNames.vport,
          level,
          isVport,
          !isVport,
          'port-number',
          isVport,
        )
        this.setState(
          {
            treeData: [...this.state.treeData],
          },
          () => {
            resolve()
          },
        )
      } else if (levelIndex === 1) {
        const isServiceGroup =
          this.serviceGroupList.indexOf(objectType) > -1 ? true : false
        this.getSLBServiceGroup(
          finalNodeValue['lp_name'],
          currentNode['service-group'],
        )
          .then((response: any) => {
            const serviceGroup = response.data['service-group'] || []
            treeNode.props.dataRef.children = this.updateListTitleValue(
              [serviceGroup],
              finalNodeValue,
              hierarchy,
              'service_group',
              this.iconsNames.serviceGroup,
              'Service_Group',
              isServiceGroup,
              !isServiceGroup,
            )
            this.setState(
              {
                treeData: [...this.state.treeData],
              },
              () => {
                resolve()
              },
            )
          })
          .catch((error: any) => {
            console.log(error.response)
            this.errorResolve(error.response, resolve)
          })
      } else if (levelIndex === 2) {
        const isLeaf = this.serverList.indexOf(objectType) > -1 ? true : false
        const serverPortList: any[] = []
        let memberList = treeNode.props.dataRef['member-list']
        memberList = memberList.filter((server: any) => {
          if (server && server.name) {
            if (
              Array.isArray(serverPortList[server.name.uuid]) &&
              serverPortList[server.name.uuid].length
            ) {
              serverPortList[server.name.uuid].push(server.port)
              return false
            } else {
              serverPortList[server.name.uuid] = []
              serverPortList[server.name.uuid].push(server.port)
              return true
            }
          } else {
            return false
          }
        })
        treeNode.props.dataRef.children = this.conslidateParentChildPortList(
          memberList,
          finalNodeValue,
          hierarchy,
          'server',
          this.iconsNames.server,
          isLeaf,
          'port-list',
          'port',
          'port-number',
          'Port',
          serverPortList,
        )
        this.setState(
          {
            treeData: [...this.state.treeData],
          },
          () => {
            resolve()
          },
        )
      } else if (levelIndex > 4) {
        const paramsArr = this.slbSelfObjUrls[objectType]
        this.getSelfObjects(finalNodeValue['lp_name'], paramsArr)
          .then((response: any) => {
            let childList
            if (response.data[paramsArr[1]]) {
              childList = response.data[paramsArr[1]]
            }
            treeNode.props.dataRef.children = this.updateListTitleValueCgnv6(
              childList,
              finalNodeValue,
              hierarchy,
              'o_uuid',
              this.iconsNames[paramsArr[1]],
              null,
              paramsArr[1],
            )
            this.setState(
              {
                treeData: [...this.state.treeData],
              },
              () => {
                resolve()
              },
            )
          })
          .catch((error: any) => {
            console.log(error.response)
            this.errorResolve(error.response, resolve)
          })
      }
    }
  }

  conslidateParentChildPortList(
    list: any,
    parentNodeValue: any,
    hierarchy: any,
    fieldName: string,
    iconName: string,
    isLeaf: boolean,
    listName: string,
    valueAttributeName: string,
    titleName: string,
    levelName: string,
    serverPortList: any[],
  ) {
    list.forEach((parent: any, i: number) => {
      const details = parent.name
      parent.title = details.name
      parent.title = this.createIcon('ongoing', iconName, details.name)
      parent.isLeaf = isLeaf
      parent.disabled = !isLeaf
      parentNodeValue[fieldName] = details.uuid
      hierarchy[fieldName] = parent.title
      parent.value = JSON.stringify(parentNodeValue)
      parent.hierarchy = JSON.stringify(hierarchy)
      if (
        !isLeaf &&
        details[listName] !== undefined &&
        serverPortList[details.uuid]
      ) {
        const filteredChildList = details[listName].filter((child: any) => {
          if (serverPortList[details.uuid].indexOf(child['port-number']) > -1) {
            const value = JSON.parse(parent.value)
            value[valueAttributeName] = child.uuid
            child.title = this.createIcon(
              'ongoing',
              this.iconsNames.serverPorts,
              child[titleName],
            )
            child.value = JSON.stringify(value)
            child.level = levelName
            child.isLeaf = true
            const nameObj = JSON.parse(parent.hierarchy)
            nameObj[valueAttributeName] = child.title
            child.hierarchy = JSON.stringify(nameObj)
            return child
          }
        })
        parent.children = filteredChildList
      }
    })
    return list
  }

  conslidateParentChildList(
    list: any,
    parentNodeValue: any,
    hierarchy: any,
    fieldName: string,
    iconName: string,
    isLeaf: boolean,
    listName: string,
    valueAttributeName: string,
    titleName: string,
    levelName: string,
    isInnerLevel: boolean,
  ) {
    list.forEach((parent: any, i: number) => {
      const details = isInnerLevel ? parent.name : parent
      parent.title = details.name
      parent.title = this.createIcon('ongoing', iconName, details.name)
      parent.isLeaf = isLeaf
      parent.disabled = !isLeaf
      parentNodeValue[fieldName] = details.uuid
      hierarchy[fieldName] = parent.title
      parent.value = JSON.stringify(parentNodeValue)
      parent.hierarchy = JSON.stringify(hierarchy)
      if (!isLeaf && details[listName] !== undefined) {
        details[listName].forEach((child: any) => {
          const value = JSON.parse(parent.value)
          value[valueAttributeName] = child.uuid
          child.title = this.createIcon(
            'ongoing',
            iconName === this.iconsNames.server
              ? this.iconsNames.serverPorts
              : this.iconsNames.rule,
            child[titleName],
          )
          child.value = JSON.stringify(value)
          child.level = levelName
          child.isLeaf = true
          const nameObj = JSON.parse(parent.hierarchy)
          nameObj[valueAttributeName] = child.title
          child.hierarchy = JSON.stringify(nameObj)
        })
        parent.children = details[listName]
      }
    })
    return list
  }

  onLoadData = (treeNode: any) =>
    new Promise(resolve => {
      if (this.state.selectedTrigger.definition['sub-category'] === 'CGNv6') {
        // for Cngv6
        this.lazyLoadCgnv6Nodes(treeNode, resolve)
      } else if (
        this.state.selectedTrigger.definition['sub-category'] === 'SLB'
      ) {
        this.lazyLoadSLBNodes(treeNode, resolve)
      } else if (
        this.state.selectedTrigger.definition['sub-category'] === 'FW'
      ) {
        this.lazyLoadFWNodes(treeNode, resolve)
      } else {
        resolve()
      }
    })

  validateTrigger = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const alertObj = this.state
    if (!alertObj.selectedTrigger) {
      rule.message = this.Messages.VALIDATION_TRIGGER
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateObjectType = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const alertObj = this.state
    if (alertObj.selectedObjects && alertObj.selectedObjects.length === 0) {
      rule.message = this.Messages.REQ_SEL_OBJ_INS
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  updateParent(key: string, value: any) {
    const alertObj: any = this.state
    alertObj[key] = value
    this.onChangeCallback(alertObj)
  }

  onCheck(checkedData: any, event: any) {
    let selectedObjects = []
    if (checkedData.checked.length > 0) {
      selectedObjects = checkedData.checked
    } else {
      selectedObjects = []
    }
    this.setState({
      selectedObjects,
    })
    this.updateParent('selectedObjects', selectedObjects)
  }

  renderTreeNodes = (data: any, triggerKey: any) =>
    data.map((item: any) => {
      if (item.children) {
        return (
          <TreeNode
            title={item.title}
            key={
              triggerKey +
              this.delimiter +
              item.value +
              this.delimiter +
              item.hierarchy
            }
            disabled={item.disabled}
            dataRef={item}
          >
            {this.renderTreeNodes(item.children, triggerKey)}
          </TreeNode>
        )
      }
      return (
        <TreeNode
          key={
            triggerKey +
            this.delimiter +
            item.value +
            this.delimiter +
            item.hierarchy
          }
          {...item}
          dataRef={item}
        />
      )
    })

  convertObjToString(headers: any) {
    return this.Utilities.convertObjToString(headers)
  }

  constructTooltip(action: any) {
    let emails = []
    let webhooks = []
    if (
      action.definition &&
      action.definition.email &&
      action.definition.email.length > 0
    ) {
      emails = action.definition.email
    }

    if (
      action.definition &&
      action.definition.webhook &&
      action.definition.webhook.length > 0
    ) {
      webhooks = action.definition.webhook
    }
    return (
      <div>
        {emails &&
          emails.map((email: any, i: number) => {
            if (
              email.email_ids &&
              email.email_ids &&
              email.email_ids.length > 0
            ) {
              return (
                <div>
                  <div className="action-title">{this.Messages.EMAIL}</div>
                  <A10Row key={'emailto' + i}>
                    <A10Col span={4}>Email to:</A10Col>
                    <A10Col span={18}>
                      {email.email_ids && email.email_ids.join()}
                    </A10Col>
                  </A10Row>
                  <A10Row key={'subject' + i}>
                    <A10Col span={4}>Subject:</A10Col>
                    <A10Col span={18}>{email.extended_subject}</A10Col>
                  </A10Row>
                  <A10Row key={'message' + i}>
                    <A10Col span={4}>Message:</A10Col>
                    <A10Col span={18}>{email.extended_message}</A10Col>
                  </A10Row>
                </div>
              )
            } else {
              return null
            }
          })}

        {webhooks &&
          webhooks.map((webhook: any, i: number) => {
            return (
              <div className="weebhook-container" key={'webhook' + i}>
                <div className="action-title">
                  {this.Messages.WEBHOOK} {i + 1}
                </div>
                <A10Row key={'emailto' + i}>
                  <A10Col span={4}>URI:</A10Col>
                  <A10Col span={18}>{webhook.uri}</A10Col>
                </A10Row>

                <A10Row key={'certificate' + i}>
                  <A10Col span={4}>Validate Certificate:</A10Col>
                  <A10Col span={18}>{webhook.ssc ? 'True' : 'False'}</A10Col>
                </A10Row>

                <A10Row key={'subject' + i}>
                  <A10Col span={4}>Headers:</A10Col>
                  <A10Col span={18}>
                    {this.convertObjToString(webhook.headers)}
                  </A10Col>
                </A10Row>
                <A10Row key={'message' + i}>
                  <A10Col span={4}>Message:</A10Col>
                  <A10Col span={18}>{webhook.extended_body}</A10Col>
                </A10Row>
              </div>
            )
          })}
      </div>
    )
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 16 },
    }
    const postFormItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 16 },
    }
    const { getFieldDecorator } = this.props.form
    const { renderDropdownOpts } = this.Utilities
    const {
      alertName,
      exisitingAction,
      actionSelected,
      option,
      numOnceEvery,
      numOnceForEvery,
      notificationType,
      emails,
      mitigationMessage,
      extendedBody,
      postHeaders,
      postToUrl,
      validateCertificate,
      objectType,
      triggerTreeData,
      selectedTrigger,
      clustersLoaded,
      treeData,
      priority,
      triggerType,
      smootheningFunction,
      condition,
      value,
      windowSize,
      windowSizeType,
      isAppsLoaded,
      isCannedTriggersLoaded,
      isCustomTriggersLoaded,
      category,
      subCategory,
      triggerName,
    } = this.state

    const keysArray = []
    const categoryKey = category
      ? this.delimiter + category + this.delimiter
      : ''
    const subKey = subCategory
      ? this.delimiter + subCategory + this.delimiter
      : ''
    const objectKey = objectType
      ? this.delimiter + objectType + this.delimiter
      : ''
    const triggerNameKey = triggerName
      ? this.delimiter + triggerName + this.delimiter
      : ''

    keysArray.push(categoryKey, subKey, objectKey)

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel
          title={
            <>
              <A10IconTextGroup
                text="Alert Details"
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            </>
          }
        >
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="mandatoryField">
                  {this.Messages.ALERT_NAME}
                </span>
                <span>
                  <HelpInfo
                    customCls="custom-tooltip-input"
                    placement="left"
                    title="Action Name"
                    helpKey="HELP_MONITOR_ALERT_DEFINITION_ALERT_NAME"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('alertName', {
              rules: [
                {
                  required: true,
                  validator: this.validateDuplicateAlertName.bind(this),
                },
              ],
              initialValue: alertName,
            })(
              <A10Input
                type="text"
                placeholder={this.Messages.ENTER_ALERT_NAME}
                onChange={(e: any) => this.handleChange('alertName', e)}
              />,
            )}
          </A10Form.Item>

          {/* priority */}
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>{this.Messages.PRIORITY_SEVERITY}</span>
                <span>
                  <HelpInfo
                    customCls="custom-tooltip-input"
                    placement="left"
                    title={this.Messages.PRIORITY_SEVERITY}
                    helpKey="HELP_MONITOR_ALERT_PRIORITY_SEVERITY"
                  />
                </span>
              </>
            }
          >
            <A10Select
              placeholder="Please select"
              defaultValue={priority}
              onChange={this.handleChange.bind(this, 'priority')}
            >
              {this.Utilities.renderDropdownOpts(
                'priority',
                'ALERTS',
                null,
                (e: any): any => {
                  return (
                    <>
                      <HealthStatus type={e.type} hideTooltip={true} />
                      <span
                        style={{
                          display: 'inline-block',
                          padding: '2px 5px',
                        }}
                      >
                        {e.label}
                      </span>
                    </>
                  )
                },
              )}
            </A10Select>
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>{this.Messages.TRIGGERS}</span>
                <span>
                  <HelpInfo
                    customCls="custom-tooltip-input"
                    placement="left"
                    title="Trigger"
                    helpKey="HELP_MONITOR_ALERT_DEFINITION_TRIGGERS"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('triggerType', {
              rules: [],
              initialValue: triggerType,
            })(
              <A10Radio.Group
                onChange={this.handleChange.bind(this, 'triggerType')}
                className="trigger-options-container"
                disabled={
                  !(
                    isCannedTriggersLoaded &&
                    isAppsLoaded &&
                    isCustomTriggersLoaded
                  )
                }
              >
                <A10Radio value={'fromtriggerinventory'}>Canned</A10Radio>
                <A10Radio value={'fromcustomtriggers'}>Custom</A10Radio>
                <A10Radio value={'fromchartmetrics'}>Charts Metrics</A10Radio>
              </A10Radio.Group>,
            )}
          </A10Form.Item>

          {/* TRIGGER Selection */}
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="mandatoryField">Trigger Category</span>
                <span>
                  <HelpInfo
                    customCls="custom-tooltip-input"
                    placement="left"
                    title={this.Messages.TRIGGER}
                    helpKey="HELP_MONITOR_ALERT_SEL_OBJ_INS"
                  />
                </span>
              </>
            }
          >
            {isCannedTriggersLoaded &&
            isAppsLoaded &&
            isCustomTriggersLoaded ? (
              getFieldDecorator('selectedTrigger', {
                rules: [
                  {
                    required: true,
                    validator: this.validateTrigger.bind(this),
                  },
                ],
                initialValue: selectedTrigger,
              })(
                <div className="object-tree">
                  <div className="object-tree-selection tree-advance">
                    {this.state.triggerTreeData &&
                    this.state.triggerTreeData.length ? (
                      <A10Tree
                        checkable={false}
                        checkStrictly={true}
                        defaultExpandedKeys={keysArray}
                        defaultSelectedKeys={[triggerNameKey]}
                        onSelect={this.handleChange.bind(
                          this,
                          'selectedTrigger',
                        )}
                      >
                        {this.renderTriggerTreeNodes(triggerTreeData)}
                      </A10Tree>
                    ) : (
                      <A10Alert
                        message="No Triggers Available"
                        description=""
                        type="error"
                      />
                    )}
                  </div>
                </div>,
              )
            ) : (
              <A10Alert message="Loading" description="" type="warning" />
            )}
          </A10Form.Item>

          {triggerType === 'fromchartmetrics' &&
          this.state.triggerTreeData &&
          this.state.triggerTreeData.length > 0 ? (
            <>
              <A10Form.Item {...formItemLayout} label={<></>}>
                <>
                  <div className="custom-padding">
                    <span>is</span>
                    {getFieldDecorator('smootheningFunction', {
                      rules: [
                        {
                          required: true,
                          message: this.Messages.VALIDATION_WINDOW_SIZE,
                        },
                      ],
                      initialValue: smootheningFunction,
                    })(
                      <A10Select
                        placeholder="Please select"
                        className="col-sm-3"
                        onChange={this.handleChange.bind(
                          this,
                          'smootheningFunction',
                        )}
                      >
                        {this.Utilities.renderDropdownOpts(
                          'metricChartAgg',
                          'TRIGGERS',
                          null,
                          null,
                          null,
                          null,
                          true,
                        )}
                      </A10Select>,
                    )}
                    {getFieldDecorator('condition', {
                      rules: [
                        {
                          required: true,
                          message: this.Messages.VALIDATION_WINDOW_SIZE,
                        },
                      ],
                      initialValue: condition,
                    })(
                      <A10Select
                        className="col-sm-5"
                        onChange={this.handleChange.bind(this, 'condition')}
                      >
                        {this.Utilities.renderDropdownOpts(
                          'conditionOperator',
                          'TRIGGERS',
                        )}
                      </A10Select>,
                    )}

                    <A10Input
                      type="number"
                      min="1"
                      value={value}
                      className="col-sm-3"
                      onChange={this.handleChange.bind(this, 'value')}
                    />
                  </div>
                  <div className="custom-padding">
                    <span> for</span>
                    {getFieldDecorator('windowSize', {
                      rules: [
                        {
                          required: true,
                          message: this.Messages.VALIDATION_WINDOW_SIZE,
                        },
                      ],
                      initialValue: windowSize,
                    })(
                      <A10Input
                        type="number"
                        min="1"
                        className="col-sm-3 m-l-10"
                        onChange={this.handleChange.bind(this, 'windowSize')}
                      />,
                    )}
                    <A10Select
                      defaultValue={windowSizeType}
                      value={windowSizeType}
                      className="col-sm-5"
                      onChange={this.handleChange.bind(this, 'windowSizeType')}
                    >
                      <A10Select.Option value="minutes">
                        Minutes
                      </A10Select.Option>
                    </A10Select>
                  </div>
                </>
              </A10Form.Item>
            </>
          ) : null}

          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="mandatoryField">
                  {this.Messages.RESOURCE_INSTANCE}
                </span>
                <span>
                  <HelpInfo
                    customCls="custom-tooltip-input"
                    placement="left"
                    title={this.Messages.RESOURCE_INSTANCE}
                    helpKey="HELP_MONITOR_ALERT_RESOURCE_INSTANCE"
                  />
                </span>
              </>
            }
          >
            {clustersLoaded ? (
              getFieldDecorator('objectType', {
                rules: [
                  {
                    required: true,
                    validator: this.validateObjectType.bind(this),
                  },
                ],
                initialValue: objectType,
              })(
                <div className="object-tree">
                  <div className="object-tree-selection tree-advance">
                    {this.state.treeData.length ? (
                      <A10Tree
                        loadData={this.onLoadData}
                        checkable={true}
                        checkStrictly={true}
                        onCheck={(checkedData, event) =>
                          this.onCheck(checkedData, event)
                        }
                        onSelect={this.handleChange.bind(this, 'objectType')}
                      >
                        {this.renderTreeNodes(
                          treeData,
                          selectedTrigger.def_name,
                        )}
                      </A10Tree>
                    ) : selectedTrigger ? (
                      <A10Alert
                        message="No Services Available"
                        description=""
                        type="error"
                      />
                    ) : null}
                  </div>
                </div>,
              )
            ) : (
              <A10Alert message="Loading" description="" type="warning" />
            )}
          </A10Form.Item>

          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>{this.Messages.EXISTING_ACTION}</span>
                <span>
                  <HelpInfo
                    customCls="custom-tooltip-input"
                    placement="left"
                    title="Action Name"
                    helpKey="HELP_MONITOR_ALERT_DEFINITION_EXISITNG_ACTION"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('exisitingAction', {
              rules: [
                {
                  required: false,
                  message: this.Messages.VALIDATION_WINDOW_SIZE,
                },
              ],
              initialValue: exisitingAction,
            })(
              <A10Switch
                defaultChecked={exisitingAction}
                size="default"
                onChange={(e: any) => this.handleChange('exisitingAction', e)}
              />,
            )}
          </A10Form.Item>

          {exisitingAction ? (
            <>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    <span>{this.Messages.SELECT_ACTION}</span>
                    <span>
                      <HelpInfo
                        customCls="custom-tooltip-input"
                        placement="left"
                        title="Select Action"
                        helpKey="HELP_MONITOR_ALERT_DEFINITION_SELECT_ACTION"
                      />
                    </span>
                  </>
                }
              >
                {getFieldDecorator('actionSelected', {
                  rules: [
                    {
                      required: false,
                      message: this.Messages.SELECT_ACTION,
                    },
                  ],

                  initialValue: actionSelected,
                })(
                  <A10Select
                    placeholder="Please select"
                    onChange={this.handleChange.bind(this, 'actionSelected')}
                  >
                    {this.state.actionList.map((option: any, i: number) => {
                      return (
                        <A10Select.Option
                          key={option.def_name}
                          value={option.def_id}
                        >
                          <A10Tooltip
                            overlayClassName="custom-tooltip-container"
                            title={this.constructTooltip(option)}
                            placement="leftTop"
                          >
                            {option.def_name}
                          </A10Tooltip>
                        </A10Select.Option>
                      )
                    })}
                  </A10Select>,
                )}
              </A10Form.Item>
            </>
          ) : (
            <>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    <span>{this.Messages.NOTIFICATION_TYPE}</span>
                    <span>
                      <HelpInfo
                        customCls="custom-tooltip-input"
                        placement="left"
                        title="Notification Type"
                        helpKey="HELP_MONITOR_ALERT_DEFINITION_NOTIFICATION_TYPE"
                      />
                    </span>
                  </>
                }
              >
                {getFieldDecorator('notificationType', {
                  rules: [],
                  initialValue: notificationType,
                })(
                  <A10Radio.Group
                    onChange={this.handleChange.bind(this, 'notificationType')}
                  >
                    <A10Radio value={'email'}>Email</A10Radio>
                    <A10Radio value={'webhook'}>Webhook</A10Radio>
                    <A10Radio value={'both'}>Both</A10Radio>
                  </A10Radio.Group>,
                )}
              </A10Form.Item>

              {notificationType === 'email' || notificationType === 'both' ? (
                <>
                  <A10Form.Item
                    {...formItemLayout}
                    label={
                      <>
                        <span>{this.Messages.SPECIFY_USERS_MAILS}</span>
                        <span>
                          <HelpInfo
                            customCls="custom-tooltip-input"
                            placement="leftTop"
                            title={this.Messages.SPECIFY_USERS_MAILS}
                            helpKey="HELP_MONITOR_ALERT_DEFINITION_ACTION_SPECIFY_USERS_MAILS"
                          />
                        </span>
                      </>
                    }
                  >
                    {getFieldDecorator('emails', {
                      rules: [
                        {
                          validator: this.handleUserEmailValidation,
                          message: this.Messages.INVALID_EMAIL,
                        },
                      ],
                      initialValue: emails,
                    })(
                      <A10Select
                        mode="tags"
                        notFoundContent=""
                        onChange={(e: any) => this.handleUsers('emails', e)}
                      >
                        {renderDropdownOpts('users', 'ACTIONS')}
                      </A10Select>,
                    )}
                  </A10Form.Item>

                  <A10Form.Item
                    {...formItemLayout}
                    label={
                      <>
                        {this.Messages.MITIGATION_MESSAGE}
                        <span>
                          <HelpInfo
                            customCls="custom-tooltip-input"
                            placement="left"
                            title="Mitigation Message"
                            helpKey="HELP_MONITOR_ALERT_DEFINITION_MITIGATION_MESSAGE"
                          />
                        </span>
                      </>
                    }
                  >
                    <A10Input
                      type="text"
                      defaultValue={mitigationMessage}
                      onChange={(e: any) =>
                        this.handleChange('mitigationMessage', e)
                      }
                    />
                  </A10Form.Item>
                </>
              ) : null}

              {notificationType === 'webhook' || notificationType === 'both' ? (
                <>
                  <A10Form.Item
                    {...postFormItemLayout}
                    label={
                      <>
                        <span>{this.Messages.POST_TO_URI}</span>
                        <span>
                          <HelpInfo
                            customCls="custom-tooltip-input"
                            placement="left"
                            title="Post to URI"
                            helpKey="HELP_MONITOR_ALERT_DEFINITION_POST_TO_URI"
                          />
                        </span>
                      </>
                    }
                  >
                    {getFieldDecorator('postToUrl', {
                      rules: [{ validator: this.validatePost.bind(this) }],
                      initialValue: postToUrl,
                    })(
                      <A10Input
                        type="url"
                        size="large"
                        onBlur={(e: any) => this.handleChange('postToUrl', e)}
                      />,
                    )}
                  </A10Form.Item>
                  <A10Form.Item
                    {...postFormItemLayout}
                    label={
                      <>
                        {this.Messages.VALIDATE_CERTIFICATE}
                        <span>
                          <HelpInfo
                            customCls="custom-tooltip-input"
                            placement="left"
                            title="VALIDATE_CERTIFICATE"
                            helpKey="HELP_MONITOR_ALERT_DEFINITION_VALIDATE_CERTIFICATE"
                          />
                        </span>
                      </>
                    }
                  >
                    <A10Switch
                      defaultChecked={validateCertificate}
                      size="default"
                      onChange={(e: any) =>
                        this.handleChange('validateCertificate', e)
                      }
                    />
                  </A10Form.Item>

                  <A10Form.Item
                    {...postFormItemLayout}
                    label={
                      <>
                        {this.Messages.HEADERS}
                        <span>
                          <HelpInfo
                            customCls="custom-tooltip-input"
                            placement="left"
                            title="Headers"
                            helpKey="HELP_MONITOR_ALERT_DEFINITION_HEADERS"
                          />
                        </span>
                      </>
                    }
                  >
                    {getFieldDecorator('postHeaders', {
                      initialValue: postHeaders,
                    })(
                      <A10Input.TextArea
                        type="text"
                        size="large"
                        placeholder={this.Messages.HEADERS_PLACEHOLDER}
                        onChange={(e: any) =>
                          this.handleChange('postHeaders', e)
                        }
                      />,
                    )}
                  </A10Form.Item>

                  <A10Form.Item
                    {...postFormItemLayout}
                    label={
                      <>
                        {this.Messages.EXTENDED_BODY}
                        <span>
                          <HelpInfo
                            customCls="custom-tooltip-input"
                            placement="left"
                            title="Extended Body"
                            helpKey="HELP_MONITOR_ALERT_DEFINITION_EXTENDED_BODY"
                          />
                        </span>
                      </>
                    }
                  >
                    <A10Input
                      type="text"
                      size="large"
                      placeholder={this.Messages.EXTENDED_BODY_PLACEHOLDER}
                      value={extendedBody}
                      onChange={(e: any) =>
                        this.handleChange('extendedBody', e)
                      }
                    />
                  </A10Form.Item>
                </>
              ) : null}
            </>
          )}
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>{this.Messages.NOTIFICATION_FREQUENCY}</span>
                <span>
                  <HelpInfo
                    customCls="custom-tooltip-input"
                    placement="left"
                    title="Notification Frequency"
                    helpKey="HELP_MONITOR_ALERT_DEFINITION_NOTIFICATION_FREQUENCY"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('notificationFrequency', {
              rules: [],
              initialValue: option,
            })(
              <A10Radio.Group
                onChange={this.handleChange.bind(this, 'notificationFrequency')}
              >
                <div className="notificationFrequency_options">
                  <div className="n_option">
                    <A10Radio value={'every'}>Every time it Triggers</A10Radio>
                  </div>
                  <div className="n_option">
                    <A10Radio value={'onceForEvery'}>
                      <>
                        Once for every
                        <A10Input
                          className="special_input col-sm-4"
                          type="number"
                          min="1"
                          value={numOnceForEvery}
                          onChange={this.handleChange.bind(
                            this,
                            'numOnceForEvery',
                          )}
                        />
                        time it occurs
                      </>
                    </A10Radio>
                  </div>
                  <div className="n_option">
                    <A10Radio value={'onceEvery'}>
                      <>
                        Once every
                        <A10Input
                          className="special_input col-sm-4"
                          type="number"
                          min="1"
                          value={numOnceEvery}
                          onChange={this.handleChange.bind(
                            this,
                            'numOnceEvery',
                          )}
                        />
                        minutes
                      </>
                    </A10Radio>
                  </div>
                </div>
              </A10Radio.Group>,
            )}
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    )
  }
}
export default setupA10Container(A10Form.create()(AlertDefinitionAddForm))
