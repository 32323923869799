import React from 'react'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10Modal,
  A10DropdownMenu,
  A10Col,
  A10Button,
  A10Input,
  A10Tooltip,
} from '@gui-libraries/widgets'
import { DISPLAY_UNIT } from '@gui-libraries/viz'

import { IDefaultMethods } from 'src/containers/Controller'
import { Messages } from 'src/locale/en'
import { DashboardService } from 'src/services/DashboardService'
import { TriggerDetails } from 'src/containers/Controller/Monitor/Triggers/TriggerDetails'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { TriggerAddForm } from 'src/containers/Controller/Monitor/Triggers/Forms/TriggerAddForm'
import { Condition } from 'src/components/shared/Condition'
import Utilities from 'src/services/Utilities/Utilities'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'

import '../styles/monitor.scss'

export interface ITriggersProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  tenantToggled: boolean
}
export interface ITriggersState {
  data: any
  triggerObj: any
  triggersUpdated: boolean
  searchString: string
  selectedIndex: number
  allData: any
  indexes: any
  deleteModalState: boolean
  selectedTrigger: any
  showSlidingPage: boolean
  formData: IObject
  editMode: boolean
  tenantToggled: boolean
  isDisableSave: boolean
}

export const TRIGGER_OBJ: any = {
  triggerName: '',
  categoryType: '',
  subcategoryType: '',
  objectType: '',
  metricType: '',
  windowType: 'step',
  triggerOn: 'int',
  windowSize: 10,
  windowSizeType: 'samples',
  maxSamples: false,
  windowSample: undefined,
  windowTime: 1,
  windowTimeParam: '',
  triggerMessage: '',
  metadata: {
    Trig_display_name: '',
    Units: [],
  },
  conditions: [
    {
      condition: '',
      metricAgg: '',
      operator: '',
      metrics: [],
      params: [],
      operation: '',
      metricsType: '',
      value: 60,
      percent: false,
      metricAggPercent: '',
      paramsPercent: [],
    },
  ],
}
class Triggers extends A10Container<ITriggersProps, ITriggersState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  DashboardService = new DashboardService()
  Messages = new Messages()
  Utilities = new Utilities()
  pageLength = 5
  dataLoaded = false
  state: ITriggersState = {
    data: [],
    triggerObj: TRIGGER_OBJ,
    triggersUpdated: false,
    searchString: '',
    selectedIndex: 0,
    allData: [],
    indexes: [],
    deleteModalState: false,
    selectedTrigger: {},
    showSlidingPage: false,
    formData: null,
    editMode: false,
    tenantToggled: this.props.tenantToggled,
    isDisableSave: false,
  }
  successObj: any = { count: 0, success: 0, failure: 0 }
  triggerResponse: any = []
  DEFAULT_HEADERS = {
    account_id: 'string',
    cluster_id: 'string',
    device_uuid: 'string',
    p_id: 'double',
    h_ver: 'string',
    p_ver: 'string',
    o_uuid: 'string',
    app_svc_type: 'string',
    app_svc_id: 'string',
    o_oper_state: 'string',
    o_ha_state: 'string',
  }
  DEFAULT_STRING_HEADERS = {
    account_id: 'string',
    cluster_id: 'string',
    device_uuid: 'string',
    p_id: 'double',
    h_ver: 'string',
    p_ver: 'string',
    o_uuid: 'string',
    app_svc_type: 'string',
    app_svc_id: 'string',
    o_oper_state: 'string',
    o_ha_state: 'string',
    msg: 'string',
    module: 'string',
  }
  STREAMS = 'Spike_Anomaly_Detector'
  CannedTriggerResponse: any = []
  childForm: any
  isOperatorUser
  triggerColumns = [
    {
      title: 'Trigger Name',
      dataIndex: 'def_name',
      className: 'td-truncate',
      key: 'def_name',
      sorter: (a: any, b: any, key: string) =>
        this.Utilities.sortString(a, b, 'def_name'),
      render: (text: any, record: any) => {
        let title = text
        if (record?.metadata?.Trig_display_name) {
          title = record.metadata.Trig_display_name
        }
        return (
          <A10Tooltip title={title} placement="topLeft">
            {title}
          </A10Tooltip>
        )
      },
    },
    {
      title: 'Condition',
      dataIndex: '',
      key: 'conditions',
      render: this.renderCondition,
      sorter: (a: any, b: any, key: any) =>
        this.Utilities.sortString(a, b, 'trigger-condition'),
    },
    {
      title: 'Category',
      dataIndex: 'definition',
      key: 'category',
      render: (key: any, dataIndex: any) => {
        return <>{key.category}</>
      },
      sorter: (a: any, b: any, key: any) =>
        this.Utilities.sortStringMulKeys(a, b, 'definition', 'category'),
    },
    {
      title: 'Sub Category',
      dataIndex: 'definition',
      key: 'sub-category',
      render: (key: any, dataIndex: any) => {
        return <>{key['sub-category']}</>
      },
      sorter: (a: any, b: any, key: any) =>
        this.Utilities.sortStringMulKeys(a, b, 'definition', 'sub-category'),
    },
    {
      title: 'Object Type',
      dataIndex: 'definition',
      key: 'object_type',
      render: (key: any, dataIndex: any) => {
        return <>{key.object_type}</>
      },
      sorter: (a: any, b: any, key: any) =>
        this.Utilities.sortStringMulKeys(a, b, 'definition', 'object_type'),
    },
    {
      title: 'Created By',
      dataIndex: '',
      key: '',
      render: (key: any, dataIndex: any) => {
        return (
          <>{key && key.createdBy ? key.createdBy : this.Messages.HARMONY}</>
        )
      },
      sorter: (a: any, b: any, key: any) =>
        this.Utilities.sortStringMulKeys(a, b, 'definition', 'object_type'),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (key: any) => {
        let isSystemCreatedTrigger = false
        isSystemCreatedTrigger = key && key.createdBy ? true : false
        const clickAction = (component: JSX.Element, index: number) => {
          if (component.key === 'duplicate') {
            this.duplicateTrigger(key)
          }

          if (component.key === 'delete') {
            if (isSystemCreatedTrigger) {
              return
            }
            this.setState({
              deleteModalState: true,
              selectedTrigger: key,
            })
          } else if (component.key === 'appServices') {
            //   this.viewAppServices(key)
          }
        }
        return this.isOperatorUser ? null : (
          <div className="text-right">
            <i>
              <A10DropdownMenu
                menu={[
                  <div key="duplicate">Clone</div>,
                  <div
                    key="delete"
                    className={isSystemCreatedTrigger ? 'disabled' : ''}
                  >
                    Delete
                  </div>,
                ]}
                title=""
                style={{ color: '#757575', marginBottom: '-15px' }}
                onClick={clickAction}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            </i>
          </div>
        )
      },
    },
  ]

  constructor(
    props: ITriggersProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: {
        get: { IS_OPERATOR_USER },
      },
    } = context

    this.isOperatorUser = IS_OPERATOR_USER
  }

  componentDidMount() {
    if (
      !this.dataLoaded ||
      this.state.tenantToggled !== this.props.tenantToggled
    ) {
      this.loadTriggers()
      this.loadCannedTriggers()
    }
  }
  componentDidUpdate() {
    if (this.state.tenantToggled !== this.props.tenantToggled) {
      this.loadTriggers()
      this.loadCannedTriggers()
    }
  }

  constructTriggerCondText = (trigger: any) => {
    let content: any = ''
    const { getVal } = this.Utilities
    const type =
      trigger.definition.conditions && trigger.definition.conditions.and
        ? 'AND'
        : 'OR'
    const triggerCond =
      trigger.definition.conditions && trigger.definition.conditions.and
        ? trigger.definition.conditions.and
        : trigger.definition.conditions.or
        ? trigger.definition.conditions.or
        : []

    triggerCond.forEach((operObj: IObject, i: number) => {
      content +=
        (i !== 0 ? ' ' + type + ' ' : '') +
        ' ' +
        getVal(operObj.aggregation, 'TRIGGERS', 'metricAgg').label +
        ' ' +
        (operObj.params.length > 1
          ? getVal(operObj.operation, 'TRIGGERS', 'operation').label +
            ' of (' +
            operObj.params.join(', ') +
            ')'
          : operObj.params.join(', ')) +
        ' is ' +
        getVal(operObj.operator, 'TRIGGERS', 'operator').label +
        ' ' +
        operObj.value +
        (operObj.percent
          ? ' % of ' +
            ' ' +
            getVal(operObj.metricAggPercent, 'TRIGGERS', 'metricAgg').label +
            ' ' +
            getVal(operObj.metricPercent, 'TRIGGERS', 'metric').label
          : '')
    })
    trigger['trigger-condition'] = content
  }

  renderCondition(trigger: any): any {
    return (
      <div className="width-400px">
        <Condition
          condition={trigger.definition.conditions}
          metadata={trigger.metadata}
          header={true}
        />
      </div>
    )
  }

  loadCannedTriggers = () => {
    const {
      storage: {
        get: { TARGET_URL: targetUrl = '' },
      },
    } = this.context
    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const triggerResponse = this.DashboardService.getCannedTriggers(
      headers,
      null,
      targetUrl,
    )

    triggerResponse
      .then((response: any) => {
        this.successObj.count++
        this.successObj.success++
        if (response && response.data) {
          this.CannedTriggerResponse = response.data
          this.CannedTriggerResponse.forEach((obj: IObject) => {
            obj.createdBy = this.Messages.SYSTEM
          })
        }

        if (2 === this.successObj.count) {
          this.triggerSuccessHandler()
        }
      })
      .catch((error: any) => {
        this.successObj.count++
        this.successObj.failure++
        if (2 === this.successObj.count) {
          this.triggerSuccessHandler()
        }
        console.log(error.response) // we need to create a centralised error handling
        this.dataLoaded = true
      })
  }

  loadTriggers = () => {
    const {
      storage: {
        get: { TARGET_URL: targetUrl = '' },
      },
    } = this.context

    try{
      const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
      if (!headers) {
        this.triggerResponse = []
        return
      }
      const triggerResponse = this.DashboardService.getTriggers(
        headers,
        null,
        targetUrl,
      )
  
      triggerResponse
        .then((response: any) => {
          this.successObj.count++
          this.successObj.success++
          if (response && response.data) {
            this.triggerResponse = response.data
          }
          if (2 === this.successObj.count) {
            this.triggerSuccessHandler()
          }
        })
        .catch((error: any) => {
          this.successObj.count++
          this.successObj.failure++
          if (2 === this.successObj.count) {
            this.triggerSuccessHandler()
          }
          console.log(error.response) // we need to create a centralised error handling
          this.dataLoaded = true
        })
    } catch(e){
      console.log(e);
    }
  }
  triggerSuccessHandler = () => {
    const {
      storage: { set },
    } = this.context

    this.dataLoaded = true
    if (this.successObj.failure === 2) {
      console.log('Get Trigger API Failed')
    }
    this.triggerResponse = this.triggerResponse.concat(
      this.CannedTriggerResponse,
    )
    set.ALL_DATA(this.triggerResponse)
    const [data, indexes] = this.DashboardService.getPaginatedItems(
      this.triggerResponse,
      this.pageLength,
    )
    this.setState(
      {
        allData: this.triggerResponse,
        data,
        indexes,
        tenantToggled: this.props.tenantToggled,
      },
      () => {
        this.successObj = { count: 0, success: 0, failure: 0 }
        this.triggerResponse = []
        this.CannedTriggerResponse = []
      },
    )
  }

  receiveUpdate = () => {
    this.dataLoaded = false
    this.setState({
      triggersUpdated: true,
    })
  }

  Search = (e: any) => {
    const searchStr = e.target.value
    this.Utilities.search(this, searchStr, 'metadata.Trig_display_name')
  }

  renderTriggerDetails = (record: any, index: number) => {
    return <TriggerDetails trigger={record} isBasic={false} />
  }

  deleteTrigger = (callbackEdit: any) => {
    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return false
    }
    const { showMessage } = this.Utilities
    const deleteTrigger = this.DashboardService.deleteTrigger(headers, null, [
      this.state.selectedTrigger.def_name,
    ])
    deleteTrigger
      .then((resp: IObject) => {
        if (callbackEdit) {
          callbackEdit()
        } else {
          showMessage('Trigger deleted successfully', 1, 0)
          this.afterDelete(true)
        }
      })
      .catch((error: IObject) => {
        if (callbackEdit) {
          let message = 'Unable to edit trigger. '
          message =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : ''
          showMessage(message, 0, 0)
          this.afterDelete(false)
          return false
        } else {
          let message = 'Unable to delete trigger. '
          message =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : ''
          showMessage(message, 0, 0)
          this.afterDelete(false)
          return false
        }
      })
    return true
  }

  afterDelete = (loadTriggers: boolean) => {
    this.setState({
      deleteModalState: false,
      selectedTrigger: {},
    })
    if (loadTriggers) {
      this.dataLoaded = false
      this.loadTriggers()
      this.loadCannedTriggers()
    }
  }

  handleOk = (callbackEdit: any) => {
    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return false
    }
    const { showMessage } = this.Utilities
    const checkDeleteDependencyTriggerAction = this.DashboardService.checkDeleteDependencyTriggerAction(
      headers,
      null,
      [this.state.selectedTrigger.def_id],
    )
    checkDeleteDependencyTriggerAction
      .then((resp: any) => {
        if (resp.data.dependency_exists === false) {
          this.deleteTrigger(callbackEdit)
          return
        }
        if (callbackEdit) {
          showMessage('Cannot edit trigger: Associated with an alert', 0, 0)
        } else {
          showMessage('Cannot delete trigger: Associated with an alert', 0, 0)
        }

        this.afterDelete(false)
      })
      .catch((error: any) => {
        if (callbackEdit) {
          const messsge = 'Unable to edit trigger. ' + error.message
          showMessage(messsge, 0, 0)
        } else {
          const messsge = 'Unable to delete trigger. ' + error.message
          showMessage(messsge, 0, 0)
        }
        this.afterDelete(false)
        return false
      })
    return true
  }

  handleCancel = () => {
    this.setState({
      deleteModalState: false,
      selectedTrigger: {},
    })
  }

  setSlidingPage = (isOpen: boolean, data?: IObject, editMode?: boolean) => {
    let formDataObj = {}
    if (editMode) {
      const streams =
        data?.metadata?.Trig_type === this.STREAMS
          ? data.definition.streams
          : undefined
      const defWindow = data.definition.window
      const windowSample =
        defWindow && defWindow.params && defWindow.params.length > 1
          ? defWindow.params[1]
          : undefined
      const windowSizeType =
        defWindow && defWindow.type.indexOf('time') > -1 ? 'minutes' : 'samples'
      const windowSize =
        defWindow && defWindow.params
          ? windowSizeType === 'minutes'
            ? defWindow.params[0].split(' ')[0]
            : defWindow.params[0]
          : ''
      data.metadata.Trig_display_name = data.def_name
      formDataObj = {
        triggerName: data.def_name,
        categoryType: data.definition.category,
        subcategoryType: data.definition['sub-category'],
        objectType: data.definition.object_type,
        windowType:
          defWindow && defWindow.type.indexOf('Batch') > -1
            ? 'step'
            : 'sliding',
        windowSize,
        windowSizeType,
        maxSamples: defWindow && 'timeLength' === defWindow.type,
        metricType: data.definition.metric_type,
        triggerOn:
          data.definition.metric_data_type === 'string' ? 'string' : 'int',
        windowSample,
        definition: { conditions: data.definition.conditions, streams },
        triggerMessage: data.definition.trigger_message,
        formMode: editMode,
        metadata: data?.metadata,
      }
    } else {
      formDataObj = data
      // formDataObj['formMode'] = editMode
    }
    this.setState({
      showSlidingPage: isOpen,
      triggerObj: data,
      formData: formDataObj,
      editMode,
      selectedTrigger: data,
    })
  }

  generateCondition = (
    conditions: any[],
    isEdit: boolean,
    expression: boolean,
  ) => {
    const { showMessage } = this.Utilities
    let conditionObj: any = {}
    const condType = conditions[0]
    if (
      expression &&
      (condType.expressions !== '' || condType.expression_condition !== '')
    ) {
      const params = condType.params
      if (!params || params.length === 0) {
        showMessage('VALIDATION_METRIC_PARAMS', 0, 1)
        conditionObj = false
      } else {
        conditionObj = {
          expressions: JSON.parse(condType.expressions),
          expression_condition: condType.expression_condition,
          params,
        }
      }
      return conditionObj
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < conditions.length; i++) {
      const condition = conditions[i]
      const params = condition.params
      if (!params || params.length === 0) {
        showMessage('VALIDATION_METRIC_PARAMS', 0, 1)
        conditionObj = false
        break
      }
      // if (!isEdit) {
      //   params = params.map((param: string) => {
      //     return JSON.parse(param).name
      //   })
      // }
      const weights: any = []
      if (condition.metricAgg === 'weightedAvg') {
        if (condition.weight && condition.weight.indexOf(',') > -1) {
          const weightsArr = condition.weight.split(',')
          weightsArr.forEach((weight: any) => {
            weights.push(parseFloat(weight))
          })
        } else if (condition.weight) {
          weights.push(parseFloat(condition.weight))
        }
      }

      if (!conditionObj[condType.condition]) {
        conditionObj[condType.condition] = []
      }
      const {
        value,
        operator,
        metricAgg,
        operation,
        percent,
        paramsPercent,
        metricAggPercent,
        weight,
        sample_count,
      } = condition
      // GUI-4383 some default alerts have no Units under metadata
      const {
        triggerObj: { metadata, triggerOn },
      } = this.state
      const Measured_unit =
        metadata?.Units?.[i]?.Measured_unit || metadata.Measured_unit
      const Display_unit = metadata?.Units?.[i]?.Display_unit || ''
      const displayUnitArray = DISPLAY_UNIT.get(Measured_unit) || [
        { formula: [], displayUnit: '' },
      ]
      const index = displayUnitArray.findIndex(
        (v: IObject) => v.displayUnit === Display_unit,
      )
      const { formula = [] } = displayUnitArray[index > -1 ? index : 0]
      const display_value = !formula.length
        ? value
        : formula[0] === 'divide'
        ? value / formula?.[1]
        : value * formula?.[1]
      const triggerOnIsINT = triggerOn === 'int'
      conditionObj[condType.condition].push({
        operator: triggerOnIsINT ? operator : undefined,
        aggregation: metricAgg,
        value,
        display_value: triggerOnIsINT ? display_value : undefined,
        params,
        operation: params && params.length > 1 ? operation : undefined,
        percent: percent || undefined,
        paramsPercent: percent ? paramsPercent : undefined,
        metricAggPercent: percent ? metricAggPercent : undefined,
        weight: metricAgg === 'exponentialAvg' ? parseFloat(weight) : undefined,
        sample_count: metricAgg === 'exponentialAvg' ? sample_count : undefined,
        weights: metricAgg === 'weightedAvg' ? weights : undefined,
      })
    }
    return conditionObj
  }

  addTrigger = () => {
    this.setSlidingPage(true, TRIGGER_OBJ)
  }

  duplicateTrigger = (trigger: any) => {
    this.setSlidingPage(true, trigger, true)
  }

  handleAdd = (isEdit: boolean) => {
    const {
      storage: {
        get: { TARGET_URL },
      },
    } = this.context

    const {
      triggerName,
      metricsType,
      categoryType,
      subcategoryType,
      objectType,
      windowType,
      windowSize,
      windowSample,
      metric,
      conditions,
      triggerMessage,
      windowSizeType,
      maxSamples,
      triggerOn,
      expression,
      streams,
      metadata,
    } = this.state.triggerObj
    const { showMessage } = this.Utilities

    const tenant = this.Utilities.getCurrentTenantObject()

    const triggerPayload: any = {
      def_name: triggerName,
      account_id: tenant.uuid,
      type: 'add',
      metadata,
    }
    const conditionObj = this.generateCondition(conditions, isEdit, expression)
    if (!conditionObj) {
      return false
    }
    const windowParams = []
    let wType = ''
    if (windowType === 'sliding') {
      if (windowSizeType === 'minutes' && maxSamples) {
        wType = 'timeLength'
        windowParams.push(windowSize + ' min')
        windowParams.push(windowSample.toString())
      } else if (windowSizeType === 'minutes') {
        wType = 'time'
        windowParams.push(windowSize + ' min')
      } else {
        wType = 'length'
        windowParams.push(windowSize.toString())
      }
    } else {
      if (windowSizeType === 'minutes') {
        wType = 'timeBatch'
        windowParams.push(windowSize + ' min')
      } else {
        wType = 'lengthBatch'
        windowParams.push(windowSize.toString())
      }
    }

    triggerPayload.definition = {
      metric,
      metric_type: metricsType,
      metric_data_type: triggerOn === 'int' ? 'integer' : 'string',
      object_type: objectType,
      window:
        windowType &&
        triggerOn === 'int' &&
        !(
          conditions[0] &&
          (conditions[0].metricAgg === 'exponentialAvg' ||
            conditions[0].metricAgg === 'weightedAvg')
        )
          ? {
              type: wType,
              params: windowParams,
            }
          : undefined,
      conditions: conditionObj,
      trigger_message: triggerMessage,
      default_headers:
        triggerOn === 'string'
          ? this.DEFAULT_STRING_HEADERS
          : this.DEFAULT_HEADERS,
      category: categoryType,
      'sub-category': subcategoryType,
      streams,
    }

    triggerPayload.filter = {
      account_id: tenant.uuid,
    }

    // While duplicating using the same headers as original trigger
    if (
      this.state.editMode &&
      this.state.selectedTrigger &&
      this.state.selectedTrigger.definition &&
      this.state.selectedTrigger.definition.default_headers
    ) {
      triggerPayload.definition.default_headers = this.state.selectedTrigger.definition.default_headers
    }

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return false
    }

    const triggerAdd = this.DashboardService.addTrigger(
      headers,
      triggerPayload,
      TARGET_URL,
    )
    triggerAdd
      .then((response: any) => {
        showMessage('TRIGGER_ADD_SUCCESS', 1, 1)
        this.setSlidingPage(false)
        this.dataLoaded = false
        this.loadTriggers()
        this.loadCannedTriggers()
      })
      .catch((error: any) => {
        let message = ''
        message =
          error.response && error.response.data && error.response.data.args[0]
        showMessage('TRIGGER_ADD_FAILED', 0, 1, message)
      })
    return false
  }

  handleUpdate = () => {
    this.handleAdd(true)
  }

  handleSave = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  onCreateTrigger = () => {
    this.setSlidingPage(false, null)
  }

  onCloseSlidingPage = () => {
    this.setSlidingPage(false, null)
  }

  handleChange = (data: any, isSaveBtn: boolean) => {
    // @ts-ignore
    if (isSaveBtn) {
      this.setState({
        isDisableSave: data,
      })
    } else {
      this.setState({
        triggerObj: data,
      })
    }
  }

  render() {
    const { showSlidingPage, allData } = this.state
    const currentTenantName = this.Utilities.getCurrentTenantName()
    return (
      <>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col>
            <ContentTitle
              title="Triggers"
              count={allData.length}
            />
          </A10Col>
          <A10Col style={{ display: 'flex' }}>
            <A10Input.Search
              type="text"
              onChange={this.Search}
              name="searchBox"
              value={this.state.searchString}
              placeholder="Search"
              style={{ marginRight: '6px' }}
            />
            {this.isOperatorUser ? null : (
              <ActionButton
                text="Create Trigger"
                onClick={this.addTrigger}
                iconProps={{ app: 'global', type: 'add-new' }}
              />
            )}
          </A10Col>
        </ContentHeader>
        <ContentBody>
          <A10Table
            columns={this.triggerColumns}
            expandedRowRender={this.renderTriggerDetails}
            dataSource={this.state.allData.map((key: any, index: number) => {
              key.key = index
              if (!!key.definition.conditions) {
                this.constructTriggerCondText(key)
                return key
              } else {
                return key
              }
            })}
            scroll={{ x: true }}
            size="small"
            loading={
              !this.dataLoaded
                ? {
                    indicator: (
                      <div>
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
          />
        </ContentBody>

        <A10Modal
          title="Delete Trigger"
          visible={this.state.deleteModalState}
          onOk={this.handleOk.bind(this, false)}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk.bind(this, false)}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            Do you want to delete{' '}
            {this.state.selectedTrigger && this.state.selectedTrigger.def_name
              ? this.state.selectedTrigger.def_name
              : ''}{' '}
            from {currentTenantName}?
          </p>
        </A10Modal>
        <FormatSlidingPage
          isOpen={showSlidingPage}
          onRequestOk={this.handleSave}
          disableSave={this.state.isDisableSave}
          onRequestClose={this.setSlidingPage.bind(this, false)}
          title={'Create Trigger'}
          description=""
        >
          {this.state.editMode ? (
            <TriggerAddForm
              handleChange={this.handleChange}
              defaultMethods={this.props.defaultMethods}
              trigger={this.state.formData}
              triggers={this.state.allData}
              onSubmitForm={this.handleUpdate}
              onRef={(ref: any): any => (this.childForm = ref)}
            />
          ) : (
            <TriggerAddForm
              triggers={this.state.allData}
              trigger={this.state.formData}
              handleChange={this.handleChange}
              defaultMethods={this.props.defaultMethods}
              onSubmitForm={this.handleAdd}
              onRef={(ref: any): any => (this.childForm = ref)}
            />
          )}
        </FormatSlidingPage>
      </>
    )
  }
}
export default setupA10Container(Triggers)
