import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
} from '@gui-libraries/framework'

import NTPServer from './NTPServer'
import AuthKey from './AuthKey'
import TrustedKey from './TrustedKey'

export interface INTPProps extends IA10ContainerDefaultProps {
  data?: any[]
}
export interface INTPState {
  isLoading: boolean
  isOpenForm: boolean
}

class NTP extends A10Container<INTPProps, INTPState> {
  constructor(props: INTPProps) {
    super(props)
    this.state = {
      isOpenForm: false,
      isLoading: false,
    }
  }

  render() {
    return (
      <>
        <NTPServer />
        <AuthKey />
        <TrustedKey />
      </>
    )
  }
}

export default setupA10Container(NTP)
