import React from 'react'
import { A10Component } from '@gui-libraries/framework'
import { A10SlidingPage } from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'

import FormatForm from '../FormatForm'

export interface IA10SlidingPageProps {
  isOpen: boolean
  modalSize?: string
  title?: string
  description?: string
  children: React.ReactElement<any>
  showBack?: boolean
  saveText?: string
  disableSave?: boolean
  hideCancel?: boolean
  isRightCancel?: boolean
  isShowFooterButtons?: boolean
  customizedButtons?: React.ReactElement<any>
  onRequestOk?: (isOpen: boolean) => void
  onRequestClose?: (isOpen: boolean) => void
}

class FormatSlidingPage extends A10Component<IA10SlidingPageProps> {
  static defaultProps = {
    onRequestOk: (isOpen: boolean) => {
      return
    },
    onRequestClose: (isOpen: boolean) => {
      return
    },
  }
  toggleSlide = (isOpen: any) => {
    const { get, set, remove } = storage

    const domObj = document.getElementsByClassName('maincontainer')
    let titleText = ''
    if ('object' === typeof this.props.title) {
      this.props.title.props &&
        this.props.title.props.children.forEach((obj: any) => {
          titleText += obj.props ? obj.props.children : obj
        })
      this.props.title[0] &&
        this.props.title[0].props.children.forEach((obj: any) => {
          titleText += obj.props ? obj.props.children : obj
        })
    } else {
      titleText = this.props.title
    }
    if (isOpen) {
      set.activeSlide(titleText)
      if (
        domObj &&
        domObj.length &&
        !domObj[0].classList.contains('restrictBackScroll')
      ) {
        domObj[0].classList.add('restrictBackScroll')
      }
    } else {
      // fix the issue: the css class restrictBackScroll can't be removed after save the configuration in GOE window
      if (get.activeSlide === titleText || titleText === 'Explorer') {
        remove.activeSlide()
        if (domObj && domObj.length) {
          domObj[0].classList.remove('restrictBackScroll')
        }
      }
    }
  }
  render() {
    const {
      isOpen,
      modalSize = '',
      onRequestClose,
      onRequestOk,
      children,
      title,
      saveText,
      description,
      customizedButtons,
      disableSave,
      hideCancel,
      isRightCancel,
      isShowFooterButtons,
      showBack = false,
    } = this.props
    return (
      <A10SlidingPage
        onAfterOpen={this.toggleSlide(isOpen)}
        isOpen={isOpen}
        modalSize={modalSize}
        onRequestClose={onRequestClose}
      >
        <FormatForm
          {...{
            title,
            description,
            children,
            showBack: showBack ? showBack : false,
            saveText,
            disableSave,
            hideCancel,
            isRightCancel,
            onRequestOk,
            onRequestClose,
            isShowFooterButtons,
            customizedButtons,
          }}
        />
      </A10SlidingPage>
    )
  }
}
export default FormatSlidingPage
