import React from 'react'
import ReactLoading from 'react-loading'
import moment from 'moment-timezone'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import {
  A10Col,
  A10Input,
  A10Table,
  A10DropdownMenu,
  A10Modal,
  A10Button,
  A10Popover,
} from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import { TroubleShootService, Utilities } from 'src/services/index'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import ActionButton from 'src/components/shared/ActionButton'
import SlidingUpgrade from '../UpgradeForm/SlidingUpgrade'

// tslint:disable-next-line:no-var-requires
const styles = require('./style/index.module.less')

export interface IScheduledUpgradesProps extends IA10ContainerDefaultProps {}

export interface IScheduledUpgradesState {
  imageCount: number
  isLoading: boolean
  search: string
  data: any[]
  scheduledUpgrades: any[]
  deleteModalState: boolean
  enableSave: boolean
  selectedUpgrade: any
  showSlidingPage: boolean
  deviceLevelUpgrade: boolean
}

class ScheduledUpgrades extends A10Container<
  IScheduledUpgradesProps,
  IScheduledUpgradesState
> {
  defaultImageColumns: any[]
  TroubleShootService = new TroubleShootService()
  Utilities = new Utilities()

  delayedSearchText = _.debounce(searchString => {
    this.searchData(searchString)
  }, 800)

  constructor(props: IScheduledUpgradesProps) {
    super(props)
    if (!storage.get.PROVIDER) {
      storage.set.PROVIDER('root')
    }
    this.state = {
      imageCount: 0,
      isLoading: false,
      search: '',
      data: [],
      scheduledUpgrades: [],
      deleteModalState: false,
      enableSave: false,
      selectedUpgrade: {},
      showSlidingPage: false,
      deviceLevelUpgrade: false
    }

    this.defaultImageColumns = [
      {
        title: 'Target Object',
        dataIndex: 'object-type',
        key: 'object-type',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'object-type'),
      },
      {
        title: 'Target Name',
        dataIndex: 'object-name',
        key: 'object-name',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'object-name'),
      },
      {
        title: 'Image',
        dataIndex: 'imageName',
        key: 'imageName',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'imageName'),
      },
      {
        title: 'Start time',
        dataIndex: 'startTime',
        key: 'startTime',
        sorter: (a: any, b: any) => this.Utilities.sortDate(a, b, 'startTime'),
      },
      {
        title: 'Time Zone',
        dataIndex: 'timeZoneOffset',
        key: 'timeZoneOffset',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'timeZoneOffset'),
      },
      {
        title: 'Save to startup',
        dataIndex: 'saveConfig',
        key: 'saveConfig',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'saveConfig'),
      },
      {
        title: 'Reboot',
        dataIndex: 'imageReboot',
        key: 'imageReboot',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'imageReboot'),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'description'),
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (selectedUpgrade: any) => {
          const clickAction = (component: JSX.Element, index: number) => {
            if (component.key === 'delete') {
              this.setState({
                deleteModalState: true,
                enableSave: true,
                selectedUpgrade,
              })
            }
          }
          return (
            <div className="text-right">
              <i>
                <A10DropdownMenu
                  menu={[<div key="delete">Delete </div>]}
                  title=""
                  style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={clickAction}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </i>
            </div>
          )
        },
      },
    ]
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    this.setState({ isLoading: true })
    const promises = []
    promises.push(
      this.TroubleShootService.getDeviceMgmtScheduledJobsForState(null, null, [
        storage.get.PROVIDER,
        'ClusterUpgrade',
        'scheduled',
      ]),
    )
    promises.push(
      this.TroubleShootService.getDeviceMgmtScheduledJobsForState(null, null, [
        storage.get.PROVIDER,
        'DeviceUpgrade',
        'scheduled',
      ]),
    )

    Promise.all(promises)
      .then((respArr: any) => {
        const imageList: any = []
        respArr.map((resp: any, index: number) => {
          const images = resp?.data['device-mgmt-scheduled-job-list'] || []
          images.map((image: any) => {
            const upgradeInfo =
              (['input-json'] && image['input-json'].upgrade) || {}
            const startTime =
              (image?.schedule && image?.schedule['start-date']) || ''
            const timezoneDetails = image?.timezone
              ? _.isObject(image.timezone)
                ? image.timezone
                : image.timezone.indexOf('{') > -1
                ? JSON.parse(image.timezone)
                : null
              : null
            image.imageName = upgradeInfo['image-name']
            image.imageReboot = upgradeInfo['reboot-after-upgrade']
              ? 'Yes'
              : 'No'
            image.saveConfig = upgradeInfo['save-config-before-upgrade']
              ? 'Yes'
              : 'No'
            image.startTime = startTime
              ? moment(startTime).format('MMM DD, YYYY hh:mm A')
              : ''
            image.timeZoneOffset = timezoneDetails
              ? timezoneDetails.offset
              : image.startTime
              ? 'UTC+00:00'
              : ''
            if (timezoneDetails && startTime) {
              const timeoffset = timezoneDetails.value
              image.startTime = moment
                .utc(startTime, 'YYYY-MM-DD HH:mm:ss')
                .tz(timeoffset)
                .format('MMM DD, YYYY hh:mm A')
            }

            imageList.push(image)
          })
        })

        this.setState({
          isLoading: false,
          data: imageList,
          scheduledUpgrades: imageList,
          imageCount: imageList.length,
        })
      })
      .catch((error: any) => {
        if (error && error.response) {
          const msg =
            error?.response?.data && typeof error.response.data == 'string'
              ? error.response.data
              : ''
          this.Utilities.showMessage(
            'Unable to load scheduled upgrades',
            0,
            0,
            msg,
          )
        }
        this.setState({ isLoading: false })
      })
  }

  searchData = (search: string = '') => {
    const scheduledUpgrades: any[] = this.state.data
      ? this.state.data.filter((scheduledUpgrade: any) => {
          return (
            (scheduledUpgrade['object-name'] &&
              scheduledUpgrade['object-name'].indexOf(search) > -1) ||
            (scheduledUpgrade['object-type'] &&
              scheduledUpgrade['object-type'].indexOf(search) > -1) ||
            (scheduledUpgrade['imageName'] &&
              scheduledUpgrade['imageName'].indexOf(search) > -1) ||
            (scheduledUpgrade['description'] &&
              scheduledUpgrade['description'].indexOf(search) > -1)
          )
        })
      : []
    this.setState({ scheduledUpgrades })
  }

  onSearchText = (e: any) => {
    const searchString = e.target.value
    this.setState({ search: searchString }, () => {
      this.delayedSearchText(searchString)
    })
  }

  deleteScheduledJob = () => {
    if (this.state.selectedUpgrade && this.state.selectedUpgrade['job-id']) {
      this.TroubleShootService.deleteScheduledJobs(null, null, [
        this.state.selectedUpgrade['job-id'],
      ])
        .then((resp: any) => {
          this.getData()
          this.Utilities.showMessage(
            'Successfully deleted scheduled upgrade',
            1,
            0,
          )
          this.handleCancel()
        })
        .catch((error: any) => {
          if (error?.response) {
            const msg =
              error?.response?.data &&
              typeof error.response.data == 'string' &&
              error.response.status !== 404
                ? error.response.data
                : ''
            this.Utilities.showMessage(
              'Unable to delete scheduled upgrade',
              0,
              0,
              msg,
            )
          }
          this.handleCancel()
        })
    }
  }

  handleCancel = () => {
    this.setState({
      deleteModalState: false,
      enableSave: false,
      selectedUpgrade: {},
    })
  }

  showImageSlidingPage = (key: string) => {
    if (key === 'clusterUpgrade') {
      this.setState({
        showSlidingPage: true,
        deviceLevelUpgrade: false,
      })
    } else if (key === 'deviceUpgrade') {
      this.setState({
        showSlidingPage: true,
        deviceLevelUpgrade: true,
      })
    }
  }

  closeUpgradeSlidingPage = async (
    idList?: string[],
    objectList?: string[],
  ) => {
    this.setState({ showSlidingPage: false })
  }

  getContextMenu = () => {
    return (
      <ul
        className="a10-dropdown-menu-popover-list"
        style={{
          width: 150,
        }}
      >
        <li onClick={this.showImageSlidingPage.bind(this, 'clusterUpgrade')}>
          Cluster Upgrade
        </li>
        <li onClick={this.showImageSlidingPage.bind(this, 'deviceUpgrade')}>
          Device Upgrade
        </li>
      </ul>
    )
  }

  render() {
    const {
      isLoading,
      scheduledUpgrades,
      deleteModalState,
      enableSave,
      selectedUpgrade,
    } = this.state

    return (
      <>
        <ContentHeader type="flex" align="middle" justify="space-between" className={styles.sectionHeader}>
          <A10Col className={styles.actionsContainer}>
            <A10Input.Search
              type="text"
              onChange={this.onSearchText}
              name="searchBox"
              value={this.state.search}
              placeholder="Search"
            />
            {storage.get.ADMIN_LEVEL === 'provider' &&
              !storage.get.IS_OPERATOR_USER && (
                <A10Popover
                  content={this.getContextMenu()}
                  placement="bottom"
                  trigger="hover"
                  arrowPointAtCenter={true}
                  key={'scheduled_upgrades'}
                >
                  <ActionButton
                    text="Schedule"
                    onClick={this.showImageSlidingPage}
                    iconProps={{ app: 'global', type: 'add-new' }}
                  />
                </A10Popover>
              )}
          </A10Col>
        </ContentHeader>
        <ContentBody>
          <A10Table
            className={styles.suList}
            columns={this.defaultImageColumns}
            dataSource={scheduledUpgrades.map(
              (item: IObject, index: number) => {
                item.key = index
                return item
              },
            )}
            size="small"
            loading={
              isLoading
                ? {
                    indicator: (
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    ),
                  }
                : false
            }
          />
        </ContentBody>
        <A10Modal
          title="Delete Scheduled Upgrade Task"
          visible={deleteModalState}
          onOk={this.deleteScheduledJob}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={!enableSave}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.deleteScheduledJob}
              className="yesbtn"
              disabled={!enableSave}
            >
              Yes
            </A10Button>,
          ]}
        >
          {`Do you want to delete upgrade scheduled for ${selectedUpgrade['object-type']} ${selectedUpgrade['object-name']}`}
        </A10Modal>
        <SlidingUpgrade
          isOpen={this.state.showSlidingPage}
          deviceLevelUpgrade={this.state.deviceLevelUpgrade}
          onRequestClose={this.closeUpgradeSlidingPage}
        />
        
      </>
    )
  }
}

export default setupA10Container(ScheduledUpgrades)
