import React from 'react'
import moment from 'moment'
import ReactLoading from 'react-loading'
import {
  A10Col,
  A10Modal,
  A10Button,
  A10Table,
  A10DropdownMenu,
  A10Loader,
  A10Notification,
} from '@gui-libraries/widgets'
import {
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import storage from 'src/libraries/storage'
import { ProviderFormActions } from './ProviderForm/index'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import {
  DashboardService,
  InfrastructureService,
  Utilities,
} from 'src/services/index'
import { PasswordResetFormActions } from './PasswordResetForm/index'
import TabsData from './TabsData'
import styles from './styles/index.module.less'

export interface IProviderListProps extends IA10ContainerDefaultProps {}
export interface IProviderListState {
  data: any
  provider?: any
  providers: any
  editProvider: {}
  providerNames: any[]
  editMode?: boolean
  isLoading: boolean
  deleteProvider: any
  slidingDesc?: string
  slidingTitle?: string
  loadingModal: boolean
  showDeleteModal: boolean
  showProviderForm: boolean
  showPasswordResetForm: boolean
  resetPasswordProviderName: string
  logCapacityObj: any
}

class ProviderList extends React.Component<
  IProviderListProps,
  IProviderListState
> {
  utilities = new Utilities()
  dashboardService = new DashboardService()
  InfrastructureService = new InfrastructureService()
  columns: any[] = []
  ref = React.createRef()

  constructor(props: IProviderListProps) {
    super(props)
    this.state = {
      data: null,
      providers: [],
      editMode: false,
      isLoading: true,
      editProvider: {},
      providerNames: [],
      deleteProvider: {},
      loadingModal: false,
      showDeleteModal: false,
      showProviderForm: false,
      showPasswordResetForm: false,
      resetPasswordProviderName: '',
      logCapacityObj: {
        remaining_hc_capacity: 0,
        shared_capacity: 0,
        default_capacity: 0,
        capacity: 0,
      },
    }
    this.loadData()
  }

  getProviders = () => {
    const providerCall = this.dashboardService.getProviders(null, null)
    providerCall
      .then((response: any) => {
        let providers =
          response.data && response.data['provider-list']
            ? response.data['provider-list']
            : []

        if (!storage.get.IS_SUPER_USER) {
          providers = response.data['provider-list'].filter((provider: any) => {
            return provider.name.toLowerCase() !== 'root'
          })
        }

        const providerNames: [] = providers.map((provider: any) => {
          if (
            !!this.state.logCapacityObj[provider.uuid] ||
            this.state.logCapacityObj[provider.uuid] == 0
          ) {
            const valString = this.utilities.truncateVal(
              this.state.logCapacityObj[provider.uuid],
              'quantity',
              2,
            )
            provider['log-rate'] = `${valString + ' /sec' + ' (Allocated)'}`
          } else {
            const valString = this.utilities.truncateVal(
              this.state.logCapacityObj['shared_capacity'],
              'quantity',
              2,
            )
            provider['log-rate'] = `${valString + ' /sec' + ' (Shared)'}`
          }
          return provider.name
        })
        this.setState({ providers, providerNames, isLoading: false })
        this.setupTable()
      })
      .catch((error: any) => {
        console.log(error.response)
        this.setState({ isLoading: false })
      })
  }

  getLogCapacity = () => {
    const logProcessingRate = this.dashboardService.getLogProcessingCapacity(
      null,
      null,
      null,
    )
    logProcessingRate
      .then((resp: any) => {
        const logResp =
          resp && resp.data
            ? resp.data
            : {
                remaining_hc_capacity: 0,
                shared_capacity: 0,
                default_capacity: 0,
                capacity: 0,
              }

        this.setState({ logCapacityObj: logResp })
        this.getProviders()
      })
      .catch((error: any) => {
        this.getProviders()
      })
  }

  loadData = () => {
    this.getLogCapacity()
  }

  checkProviderDevices = async (provider: string) => {
    try {
      const res = await this.InfrastructureService.getDevices(
        null,
        null,
        provider,
      )
      return !!res?.data?.['device-list']?.length
    } catch (e) {
      return false
    }
  }

  setupTable() {
    this.columns = [
      {
        title: '',
        dataIndex: 'terms-accepted',
        key: 'terms-accepted',
        width: 30,
        render: (key: any, record: any) => {
          const status =
            record['provider-status'] === 'verified' ? 'ongoing' : 'warning'
          const toolTip =
            record['provider-status'] === 'verified'
              ? 'Verified'
              : 'Not Verified'
          return <HealthStatus type={status} tooltip={toolTip} />
        },
      },
      {
        title: <>Name</>,
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => this.utilities.sortString(a, b, 'name'),
      },
      {
        title: <>Organization</>,
        dataIndex: 'organization-name',
        key: 'organization-name',
        sorter: (a: any, b: any) =>
          this.utilities.sortString(a, b, 'organization-name'),
      },
      {
        title: <>Allocated Log Rate</>,
        dataIndex: 'log-rate',
        key: 'log-rate',
        sorter: (a: any, b: any) => this.utilities.sortString(a, b, 'log-rate'),
      },
      {
        title: <>Authentication</>,
        dataIndex: 'authentication-provider',
        key: 'authentication-provider',
        sorter: (a: any, b: any) =>
          this.utilities.sortString(a, b, 'authentication-provider'),
        render: (key: any) =>
          typeof key !== 'undefined'
            ? key.type.toLowerCase() == 'default'
              ? 'LOCAL'
              : key.type.toUpperCase()
            : '',
      },
      {
        title: <>Authorization</>,
        dataIndex: '',
        key: 'remote-authorization',
        sorter: (a: any, b: any) =>
          this.utilities.sortStringObj(
            a,
            'authentication-provider',
            'remote-authorization',
          ),
        render: (item: any) => {
          const strVal = item['authentication-provider']?.[
            'remote-authorization'
          ]
            ? item['authentication-provider'].type
            : 'local'
          return strVal.toUpperCase()
        },
      },
      {
        title: <>MFA</>,
        dataIndex: '',
        key: 'mfa-status',
        sorter: (a: any, b: any) =>
          this.utilities.sortStringObj(
            a,
            'authentication-provider',
            'mfa-enabled',
          ),
        render: (item: any) => {
          let strVal = ' - '
          if ('mfa-enabled' in item['authentication-provider']) {
            strVal =
              item['authentication-provider']?.['mfa-enabled'] === true
                ? 'ENABLED'
                : 'DISABLED'
          }

          return strVal
        },
      },
      {
        title: <>Created On</>,
        dataIndex: 'created-at',
        key: 'created-at',
        sorter: (a: any, b: any) => this.utilities.sortDate(a, b, 'created-at'),
        render: (key: any) => {
          return key !== '' && !isNaN(key)
            ? moment(parseInt(key, 10)).format('MM-DD-YYYY h:mm A')
            : ''
        },
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (key: any, record: any) => {
          const clickAction = async (component: JSX.Element, index: number) => {
            if (component.key === 'edit') {
              this.editProviderForm(key)
            } else if (component.key === 'reset-password') {
              this.openPasswordResetForm(key)
            } else if (component.key === 'delete') {
              const deviceFlag = await this.checkProviderDevices(key?.name)
              if (deviceFlag) {
                A10Notification.warning({
                  message: 'Warning',
                  description: `There are devices registered with ${key?.name} provider. Please deregister devices before deleting this provider.`,
                  duration: 15,
                })
                return
              }
              this.setState({
                deleteProvider: key,
                showDeleteModal: true,
              })
            }
          }
          const dropdownOptions = [<div key="edit">Edit</div>]

          if (key.name.toLowerCase() !== 'root') {
            if (record['provider-status'] === 'verified') {
              dropdownOptions.push(
                <div key="reset-password">Reset Password</div>,
              )
            }
            dropdownOptions.push(<div key="delete">Delete</div>)
          }

          return (
            <div className="table-dropdown">
              <A10DropdownMenu
                title=""
                menu={dropdownOptions}
                onClick={clickAction}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            </div>
          )
        },
      },
    ]

    const data = this.state.providers.map((provider: any, index: number) => {
      provider.key = provider.uuid
      return provider
    })
    this.setState({ data })
  }

  render() {
    return (
      <>
        <ContentSection className={styles.providerTable}>
          <ContentHeader type="flex" align="middle" justify="space-between">
            <A10Col>
              <ContentTitle
                title="Provider"
                count={this.state.providers.length}
              />
            </A10Col>
            <A10Col>
              <ActionButton
                text="Create"
                onClick={this.openNewProviderForm}
                iconProps={{ app: 'global', type: 'add-new' }}
              />
            </A10Col>
          </ContentHeader>
          <ContentBody ref={this.ref}>{this.showProviders()}</ContentBody>
        </ContentSection>
        <ProviderFormActions
          show={this.state.showProviderForm}
          title={this.state.slidingTitle}
          provider={this.state.editProvider}
          editMode={this.state.editMode}
          onClose={this.closeForms}
          description={this.state.slidingDesc}
          providerNames={this.state.providerNames}
          onActionCompleted={this.loadData}
          logCapacityObj={this.state.logCapacityObj}
        />
        <PasswordResetFormActions
          show={this.state.showPasswordResetForm}
          title={this.state.slidingTitle}
          onClose={this.closeForms}
          description={this.state.slidingDesc}
          providerName={this.state.resetPasswordProviderName}
          onActionCompleted={this.loadData}
        />
        {this.deleteProviderModal()}
      </>
    )
  }

  showProviders = () => {
    return (
      <A10Table
        columns={this.columns}
        dataSource={this.state.data}
        expandedRowRender={this.renderProviderDetails}
        loading={
          !this.state.data && {
            indicator: <A10Loader container={this.ref} />,
          }
        }
      />
    )
  }

  renderProviderDetails = (record: any) => {
    const tabProps = {
      providerName: record.name,
      providerId: record.uuid,
      activeTab: 'license',
      record: record
    }

    return (
      <div>
        <TabsData {...tabProps} />
      </div>
    )
  }

  openNewProviderForm = () => {
    this.setState({
      editMode: false,
      editProvider: {
        name: '',
        email: '',
        description: '',
        'contact-last-name': '',
        'organization-name': '',
        'contact-first-name': '',
        'organization-phone-number': '',
      },
      showProviderForm: true,
      slidingTitle: 'Add Provider',
      slidingDesc: "Please enter Provider's details",
    })
  }

  editProviderForm = async (provider: any) => {
    const providerRes = await this.dashboardService.getProvider(null, null, [
      provider.name,
    ])

    const users = providerRes?.data?.provider?.['user-list'] ?? []

    const editProvider = {
      name: provider.name,
      email: provider.email,
      description: provider.description,
      'terms-accepted': provider['terms-accepted'],
      'contact-last-name': provider['contact-last-name'],
      'organization-name': provider['organization-name'],
      'contact-first-name': provider['contact-first-name'],
      'organization-phone-number': provider?.[
        'organization-phone-number'
      ]?.toString(),
      'user-list': users,
      uuid: provider.uuid,
    }
    this.setState({
      editProvider,
      editMode: true,
      showProviderForm: true,
      slidingTitle: 'Edit Provider',
      slidingDesc: "Please update Service Provider's Information!",
    })
  }

  openPasswordResetForm = (provider: any) => {
    this.setState({
      showPasswordResetForm: true,
      slidingDesc: '',
      resetPasswordProviderName: provider.name,
      slidingTitle: 'Reset Password for Provider - ' + provider.name,
    })
  }

  closeForms = () => {
    this.setState({
      editMode: false,
      showProviderForm: false,
      showPasswordResetForm: false,
    })
  }

  deleteProviderModal = () => {
    return (
      <A10Modal
        width={700}
        title="Delete Provider?"
        visible={this.state.showDeleteModal}
        onOk={this.handleDelete}
        onCancel={this.handleCancelDelete}
        footer={[
          <A10Button
            key="no"
            type="primary"
            onClick={this.handleCancelDelete}
            className="nobtn"
            disabled={this.state.loadingModal}
          >
            No
          </A10Button>,
          <A10Button
            key="yes"
            type="default"
            onClick={this.handleDelete}
            className="yesbtn"
            disabled={this.state.loadingModal}
          >
            Yes
          </A10Button>,
        ]}
      >
        <p>Deleting the provider is an irreversible action.</p>
        <p>
          This provider will be permanently deleted along with the users created
          in it.
        </p>
        <p>
          <strong>
            Do you want to delete provider with name:{' '}
            {this.state.deleteProvider.name}?
          </strong>
        </p>
        {this.state.loadingModal ? (
          <div className="">
            <ReactLoading type="bars" color="#4a90e2" height={40} width={40} />
          </div>
        ) : null}
      </A10Modal>
    )
  }

  handleDelete = () => {
    const provider = { ...this.state.deleteProvider }
    if (
      this.state.logCapacityObj &&
      !!this.state.logCapacityObj[provider.uuid]
    ) {
      this.dashboardService
        .deleteProviderLogRate(null, null, [provider.uuid])
        .then(response => {
          // this.Utilities.showMessage('SUCCESS_PROVIDER_DELETE', 1, 1)
        })
        .catch((error: any) => {
          // this.Utilities.showMessage('PROVIDER_DELETE_FAILED', 0, 1, error.message)
        })
    }
    this.dashboardService
      .deleteProvider(null, null, [provider.name])
      .then(response => {
        this.utilities.showMessage('SUCCESS_PROVIDER_DELETE', 1, 1)
        this.setState({
          showDeleteModal: false,
          loadingModal: false,
        })
        this.loadData()
        this.deleteProviderChartTemplates(provider)
      })
      .catch((error: any) => {
        this.setState({ loadingModal: false })
        this.utilities.showMessage(
          'PROVIDER_DELETE_FAILED',
          0,
          1,
          error.message,
        )
      })
  }

  deleteProviderChartTemplates(provider: any) {
    this.dashboardService
      .deleteProviderChartTemplates(null, null, [provider.uuid])
      .then(response => {})
      .catch((error: any) => {
        this.utilities.showMessage(
          'PROVIDER_DELETE_TRIGGER_TEMPLATES_FAILED',
          0,
          1,
          error.message,
        )
      })
  }

  handleCancelDelete = () => {
    this.setState({ showDeleteModal: false })
  }
}

export default setupA10Container(ProviderList)
