import React from 'react'
import { A10Component } from '@gui-libraries/framework'
import { A10TreeSelect, A10Tooltip, A10Avatar } from '@gui-libraries/widgets'

import { AppRoot } from 'src/settings/appRoot'
import storage from 'src/libraries/storage'

export interface IClusterDeviceMenuProps {
  clusterDeviceType: string
  selectedClusterDevice: any
  refreshClusterDevices?: boolean
  clusterDeviceChanged(type: string, selectedObj: any): void
}

export interface IClusterDeviceMenuState {
  clusters: any
  devices: any
  selectedClusterDevice: any
  searchString: string
  refreshClusterDevices: boolean
}

const A10TreeNode = A10TreeSelect.A10TreeNode

class ClusterDeviceMenu extends A10Component<
  IClusterDeviceMenuProps,
  IClusterDeviceMenuState
> {
  static getDerivedStateFromProps(
    nextProps: IClusterDeviceMenuProps,
    prevState: IClusterDeviceMenuState,
  ) {
    let refreshClusterDevices = prevState.refreshClusterDevices
    if (prevState.refreshClusterDevices !== nextProps.refreshClusterDevices) {
      refreshClusterDevices = nextProps.refreshClusterDevices
    }
    return {
      clusters: storage.get.ALLCLUSTERS,
      devices: storage.get.ALLDEVICES,
      selectedClusterDevice: prevState.selectedClusterDevice,
      searchString: prevState.searchString,
      refreshClusterDevices,
    }
  }
  AppRoot = new AppRoot()

  constructor(props: IClusterDeviceMenuProps) {
    super(props)
    this.state = {
      selectedClusterDevice: this.props.selectedClusterDevice || null,
      clusters: storage.get.ALLCLUSTERS,
      devices: storage.get.ALLDEVICES,
      searchString: '',
      refreshClusterDevices: false,
    }
  }

  changeClusterDevice = (clusterDevice: any) => {
    if (clusterDevice === `all#${this.props.clusterDeviceType}#obj`) {
      clusterDevice = ''
    }
    try {
      clusterDevice = JSON.parse(clusterDevice)
      if (!clusterDevice.name) {
        clusterDevice = {
          name: clusterDevice,
        }
      }
    } catch (e) {
      if (clusterDevice !== undefined || !clusterDevice.name) {
        clusterDevice = {
          name: clusterDevice,
        }
      }
    }
    if (clusterDevice) {
      this.setState({ selectedClusterDevice: clusterDevice })
      this.props.clusterDeviceChanged(
        this.props.clusterDeviceType,
        clusterDevice,
      )
    }
  }

  searchCluster = (searchString: any) => {
    const clusters = storage.get.ALLCLUSTERS
    let filteredClusters = clusters
    if (searchString && searchString !== '') {
      filteredClusters = clusters.filter((cluster: any) => {
        return (
          cluster.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
          (cluster['display-name'] &&
            cluster['display-name']
              .toLowerCase()
              .indexOf(searchString.toLowerCase()) > -1)
        )
      })
    }
    this.setState({
      searchString,
      clusters: filteredClusters,
    })
  }

  searchDevice = (searchString: any) => {
    const devices = storage.get.ALLDEVICES
    let filteredDevices = devices
    if (searchString && searchString !== '') {
      filteredDevices = devices.filter((device: any) => {
        return (
          device.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
        )
      })
    }
    this.setState({
      searchString,
      devices: filteredDevices,
    })
  }

  componentDidMount() {
    if (this.props.selectedClusterDevice) {
      if (
        this.props.selectedClusterDevice !==
        JSON.stringify(this.state.selectedClusterDevice)
      ) {
        this.setState({
          selectedClusterDevice: this.props.selectedClusterDevice || undefined,
          clusters: storage.get.ALLCLUSTERS,
          devices: storage.get.ALLDEVICES,
        })
      }
    } else {
      if (
        this.props.selectedClusterDevice !== this.state.selectedClusterDevice
      ) {
        this.setState({
          selectedClusterDevice: undefined,
          clusters: storage.get.ALLCLUSTERS,
          devices: storage.get.ALLDEVICES,
        })
      }
    }
  }
  componentDidUpdate() {
    if (this.props.selectedClusterDevice) {
      if (this.props.selectedClusterDevice === 'undefined') {
        return
      }
      if (
        this.props.selectedClusterDevice?.name !==
        this.state.selectedClusterDevice?.name
      ) {
        this.setState({
          selectedClusterDevice: this.props.selectedClusterDevice || undefined,
          clusters: storage.get.ALLCLUSTERS,
          devices: storage.get.ALLDEVICES,
        })
      }
    } else {
      if (
        (!!this.props.selectedClusterDevice ||
          !!this.state.selectedClusterDevice) &&
        this.props.selectedClusterDevice?.name !==
          this.state.selectedClusterDevice?.name
      ) {
        this.setState({
          selectedClusterDevice: undefined,
          clusters: storage.get.ALLCLUSTERS,
          devices: storage.get.ALLDEVICES,
        })
      }
    }
  }

  renderDisplayText = (displayName: string) => {
    const label =
      displayName === ''
        ? this.props.clusterDeviceType === 'cluster'
          ? 'All Clusters'
          : 'All Devices'
        : displayName
    const symbol = this.props.clusterDeviceType === 'cluster' ? 'C' : 'D'
    return (
      <>
        <A10Tooltip title={label}>
          <A10Avatar
            style={{
              width: '18px',
              height: '18px',
              lineHeight: '18px',
              fontSize: '12px',
              backgroundColor: '#81c784',
            }}
          >
            {symbol}
          </A10Avatar>
        </A10Tooltip>

        <span className="option-label" title={label}>
          {label}
        </span>
      </>
    )
  }

  render() {
    const clusterDeviceObjs =
      this.props.clusterDeviceType === 'cluster'
        ? this.state.clusters
        : this.state.devices
    return (
      <div className="pt-drop-container">
        <A10TreeSelect
          searchValue={this.state.searchString}
          onSearch={
            this.props.clusterDeviceType === 'cluster'
              ? this.searchCluster
              : this.searchDevice
          }
          showSearch
          value={
            this.state.selectedClusterDevice.name
              ? this.state.selectedClusterDevice.name
              : undefined
          }
          dropdownStyle={{ maxHeight: 400, width: 300, overflow: 'auto' }}
          dropdownClassName="provider-tenant-select-dropdown"
          className="provider-tenant-menu"
          placeholder={this.renderDisplayText('')}
          treeDefaultExpandAll
          onChange={this.changeClusterDevice}
          size="large"
        >
          <A10TreeNode
            value={`all#${this.props.clusterDeviceType}#obj`}
            title={this.renderDisplayText('')}
            key={`all#${this.props.clusterDeviceType}#obj`}
          >
            {clusterDeviceObjs &&
              clusterDeviceObjs.map((obj: any, index: number) => {
                return (
                  <A10TreeNode
                    value={obj.name}
                    title={this.renderDisplayText(
                      obj['display-name'] || obj.name,
                    )}
                    key={obj.name}
                  />
                )
              })}
          </A10TreeNode>
        </A10TreeSelect>
      </div>
    )
  }
}

export default ClusterDeviceMenu
