import React from 'react'
import ReactLoading from 'react-loading'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
  A10Context,
  getItem,
  setItem,
} from '@gui-libraries/framework'

import {
  A10Alert,
  A10Button,
  A10Col,
  A10DropdownMenu,
  A10Icon,
  A10Input,
  A10Loader,
  A10Message,
  A10Modal,
  A10Popover,
  A10Table,
  A10Tooltip,
} from '@gui-libraries/widgets'
import {
  AuthenticationService,
  DashboardService,
  OrganizationService,
  Utilities,
  TroubleShootService
} from 'src/services/index'
import { PasswordResetForm } from 'src/containers/Providers/PasswordResetForm'
import {
  ContentBody,
  ContentHeader,
  ContentSection,
  ContentTitle,
} from 'src/components/shared/ContentSection'

import { UpgradeModal } from 'src/components/shared/UpgradeModal'
import { ActionButton } from 'src/components/shared/ActionButton'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { IDefaultMethods } from 'src/containers/Controller'
import { Messages } from 'src/locale/en'

// import { PasswordResetForm } from 'src/containers/Controller/MultiProvider/ProviderList/PasswordResetForm'
import { QR_CODE_GENERATED } from 'src/constants/MFAStatus/MFAConstants'

import { RoundNumber } from 'src/components/shared/RoundNumber'
import { UserForm } from 'src/containers/Controller/Organization/RBACUsers/Forms/UserForm'
import UserRole from 'src/constants/Data/UserRole'
import storage from 'src/libraries/storage'
import ActivateLicense from 'src/components/shared/ActivateLicense'

const styles = require('./styles/rbacusers.scss')

export interface IRBACUsersProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  isObjectExplorerMode?: boolean
  callbackObjectExplorerMode?: any
}
export interface IRBACUsersState {
  searchString: string
  allUsers: any
  deleteModalState: boolean
  selectedUser: any
  formUser: any
  editMode: boolean
  showSlidingPage: boolean
  showResetPasswordForm: boolean
  slidingTitle: any
  slidingDesc: any
  addUserObj: any
  loadingIcon: boolean
  loadingIconModal: boolean
  passwordReset: any
  maxUsers: number
  remainingUsers: number
  showUpgrade: boolean
  activeLicense: string
  showAddLicense: boolean
}

class RBACUsers extends A10Container<IRBACUsersProps, IRBACUsersState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  AuthenticationService = new AuthenticationService()
  DashboardService = new DashboardService()
  OrganizationService = new OrganizationService()
  TroubleShootService = new TroubleShootService()
  Utilities = new Utilities()
  UserRole = new UserRole()
  Messages = new Messages()
  pageLength = 5
  processedUserList = {}
  adminLevel
  infraLevel
  certLevel
  tenants
  isOperatorUser
  isRemote: boolean
  roleList: any = []
  roleClassList: any = []
  state = {
    searchString: '',
    allUsers: [],
    deleteModalState: false,
    selectedUser: { id: '' },
    editMode: false,
    showSlidingPage: false,
    showResetPasswordForm: false,
    slidingTitle: '',
    slidingDesc: '',
    formUser: null,
    addUserObj: {
      firstName: '',
      lastName: '',
      userId: '',
      emailId: '',
      rbacRoles: [],
      rbacRole: '',
      mfaStatus: '',
      showRbacRole: true,
      resetPassword: false,
    },
    loadingIcon: false,
    loadingIconModal: false,
    passwordReset: {},
    maxUsers: -1,
    remainingUsers: 0,
    showUpgrade: false,
    activeLicense: '',
    showAddLicense: false,
  }
  ref = React.createRef()

  constructor(
    props: IRBACUsersProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: {
        get: {
          ADMIN_LEVEL,
          IS_INFRA_USER,
          IS_CERT_USER,
          ALLTENANTS,
          IS_OPERATOR_USER,
        },
      },
    } = context

    this.adminLevel = ADMIN_LEVEL
    this.infraLevel = IS_INFRA_USER
    this.certLevel = IS_CERT_USER
    this.tenants = ALLTENANTS
    this.isOperatorUser = IS_OPERATOR_USER
    this.getRoles()
    this.getAuthType()
    this.getRemainingUsers()
  }

  defaultUsers = ['super-admin', 'provider-admin']
  childForm: any = null
  dataLoaded = false
  userColumns = [
    {
      title: <HealthStatus type="Status" hideTooltip={true} />,
      dataIndex: 'state',
      key: 'state',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'status'),
      render: (text: string, record: any, index: number) => {
        const statusText = text.toLowerCase()
        record.status = 'stopped'
        if (statusText === 'unverified' || statusText === 'reset_password') {
          record.status = 'warning'
        } else if (statusText === 'active') {
          record.status = 'ongoing'
        }
        return <HealthStatus type={record.status} text="U" tooltip={text} />
      },
    },
    {
      title: <>Name</>,
      dataIndex: 'first-name',
      key: 'first-name',
      className: 'td-truncate',
      render: (text: string, record: any, index: number) => {
        return (
          <A10Tooltip
            title={
              record['first-name']
                ? record['first-name'] + ' ' + (record['last-name'] || '')
                : ''
            }
            placement="topLeft"
          >
            {record['first-name']
              ? record['first-name'] + ' ' + (record['last-name'] || '')
              : ''}
          </A10Tooltip>
        )
      },
      sorter: this.Utilities.sortFullName,
    },
    {
      title: <>Email</>,
      dataIndex: 'email-id',
      key: 'email-id',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'email-id'),
      className: 'td-truncate',
      render: (text: string, record: any, index: number) => {
        return (
          <A10Tooltip title={text} placement="topLeft">
            {text}
          </A10Tooltip>
        )
      },
    },
    {
      title: <>User ID</>,
      dataIndex: 'user-id',
      key: 'user-id',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'user-id'),
      className: 'td-truncate',
      render: (text: string, record: any, index: number) => {
        return (
          <A10Tooltip title={text} placement="topLeft">
            {text}
          </A10Tooltip>
        )
      },
    },
    {
      title: <>MFA</>,
      dataIndex: 'mfa-status',
      key: 'mfa-status',
      sorter: (a: string, b: string) =>
        this.Utilities.sortString(a, b, 'mfa-status'),
      className: 'td-truncate ',
      render: (text: string, record: any, index: number) => {
        const displayText = text === QR_CODE_GENERATED ? 'PENDING' : text
        return (
          <A10Tooltip title={displayText} placement="topLeft">
            {displayText}
          </A10Tooltip>
        )
      },
    },
    {
      title: 'Access Group',
      key: 'rbacRoles',
      dataIndex: 'rbacRoles',
      sorter: (a: any, b: any) =>
        this.Utilities.sortArrayString(a, b, 'rbacRoles', 0),
      className: 'td-truncate',
      render: (text: string, record: any, index: number) => {
        const rbacRoles = record['rbacRoles']
          ? record['rbacRoles'].join(', ')
          : ''
        return (
          <>
            {rbacRoles ? (
              <A10Popover
                overlayClassName="popoverUserTenants"
                placement="right"
                content={this.renderPopoverRoles(record['rbacRoles'])}
              >
                {record['rbacRoles'] && record['rbacRoles'].length > 1
                  ? 'Multiple Access Group'
                  : rbacRoles}
                <RoundNumber
                  number={record['rbacRoles'] ? record['rbacRoles'].length : 0}
                />
              </A10Popover>
            ) : (
              'Not Assigned'
            )}
          </>
        )
      },
    },
    {
      title: 'Role',
      key: 'rbacRoleClass',
      dataIndex: 'rbacRoleClass',
      sorter: (a: any, b: any) =>
        this.Utilities.sortArrayString(a, b, 'rbacRoleClass', 0),
      className: 'td-truncate',
      render: (text: string, record: any, index: number) => {
        const rbacRoleClass = record['rbacRoleClass']
          ? record['rbacRoleClass'].join(', ')
          : ''
        return (
          <>
            {rbacRoleClass ? (
              <A10Popover
                overlayClassName="popoverUserTenants"
                placement="right"
                content={this.renderPopoverRoles(record['rbacRoleClass'])}
              >
                {record['rbacRoleClass'] && record['rbacRoleClass'].length > 1
                  ? 'Multiple Roles'
                  : rbacRoleClass}
                <RoundNumber
                  number={
                    record['rbacRoleClass'] ? record['rbacRoleClass'].length : 0
                  }
                />
              </A10Popover>
            ) : (
              'Not Assigned'
            )}
          </>
        )
      },
    },
    {
      title: <>Created On</>,
      dataIndex: 'created-at',
      key: 'created-at',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'created-at'),
      render: (text: string, record: any, index: number) => {
        const fromDateFormat = moment
          .utc(record['created-at'])
          .local()
          .format('MMM DD, YYYY hh:mm A')
        return <>{fromDateFormat}</>
      },
    },
  ]

  filterColumn = (isMFAEnabled: boolean) => {
    if (!isMFAEnabled) {
      this.userColumns = this.userColumns.filter(item => {
        return item.key != 'mfa-status'
      })
    }
  }

  addActionsColumn = () => {
    const {
      get: { USER_ID },
    } = storage

    if (
      this.adminLevel === 'provider' &&
      !(this.infraLevel || this.certLevel)
    ) {
      const actionsCol = {
        title: '',
        dataIndex: '',
        key: 'action-item-menu',
        render: (key: any) => {
          const clickAction = (component: JSX.Element, index: number) => {
            if (component.key === 'edit' && !this.isOperatorUser) {
              this.editUser(key)
              this.setState({
                searchString: '',
              })
            } else if (
              this.adminLevel === 'provider' &&
              component.key === 'delete' &&
              !this.isOperatorUser
            ) {
              this.setState({
                deleteModalState: true,
                selectedUser: key,
                searchString: '',
              })
            }
          }
          const dropdownOptions = []
          const adminUser = this.defaultUsers.indexOf(key['user-id']) >= 0
          let superAdmin = false
          key['roles'] &&
            key['roles'].map((roleObj: any) => {
              const roles = roleObj ? Object.keys(roleObj) : []
              if (roles[1] === 'superadmin-root' || roles[1] === 'superadmin') {
                superAdmin = true
              }
            })
          if (!this.isOperatorUser) {
            dropdownOptions.push(<div key="edit">Edit </div>)
            if (!adminUser) {
              dropdownOptions.push(
                <div
                  key="delete"
                  className={this.adminLevel === 'provider' ? '' : 'disabled'}
                >
                  Delete{' '}
                </div>,
              )
            }
          }
          if (key['user-id'] === USER_ID || superAdmin) {
            return (
              <div className="text-right">
                <A10Icon
                  type="user"
                  className="action-icon"
                  title={
                    key['user-id'] === USER_ID
                      ? 'Current User'
                      : 'Super admin user'
                  }
                />
              </div>
            )
          }

          return dropdownOptions.length ? (
            <div className="table-dropdown">
              <A10DropdownMenu
                menu={dropdownOptions}
                title=""
                onClick={clickAction}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            </div>
          ) : null
        },
      }
      this.userColumns.push(actionsCol)
    }
  }

  renderPopoverRoles = (popoverRoles: any) => {
    if (popoverRoles && popoverRoles.length > 0) {
      return (
        <div className="listTenant">
          <ul>
            {popoverRoles.map((role: string) => {
              return (
                <li key={role}>
                  <span className="popoverTenantName">{role}</span>
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
  }

  getRoles = async () => {
    const {
      get: { PROVIDER },
    } = storage

    const roleResponse = this.OrganizationService.getRbacAccessGroups(
      null,
      null,
      [PROVIDER],
    )
    await roleResponse
      .then((resp: any) => {
        const roleListResp = resp && resp.data ? resp.data : []
        this.roleList = roleListResp
      })
      .catch((error: any) => {})
    this.getRoleClasses()
  }

  getRoleClasses = async () => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const roleClassResponse = this.OrganizationService.getRbacRoleList(
      null,
      null,
      [PROVIDER],
    )
    await roleClassResponse
      .then((resp: any) => {
        const roleClassListResp = resp && resp.data ? resp.data : []
        this.roleClassList = roleClassListResp
      })
      .catch((error: any) => {})
    this.loadUsers()
    const addNew = getItem('ADD_NEW')
    if (addNew) {
      setItem('ADD_NEW', '')
      if (this.canAddNewUsers()) {
        this.addUser()
      }
    }
  }

  loadUsers = () => {
    const {
      storage: {
        get: { PROVIDER: provider },
        set,
      },
    } = this.context

    let promises = []
    if (this.adminLevel === 'provider' || true) {
      const userResponse = this.OrganizationService.getUsers(
        null,
        null,
        provider,
      )
      promises.push(userResponse)
    }
    Promise.all(promises)
      .then((respArr: any) => {
        let userList: any = []
        respArr.map((resp: any, index: number) => {
          if (index === 0) {
            userList = resp.data
          } else {
            resp.data.map((obj: any) => {
              const existingUser = userList.filter((user: any) => {
                return user['user-id'] === obj['user-id']
              })
              if (existingUser.length === 0) {
                userList.push(obj)
              }
            })
          }
        })
        userList.map((user: any) => {
          const rbacAGs: string[] = []
          const rbacRoles: string[] = []
          const rbacRoleClass: string[] = []
          if (user.roles && user.roles.length > 0) {
            user.roles.map((roleObj: any) => {
              const roles = Object.keys(roleObj)
              roles[0] && rbacAGs.push(roles[0])
              roles[0] && rbacRoles.push(this.getRoleDisplayName(roles[0]))
              roles[0] &&
                rbacRoleClass.push(
                  roleObj[roles[0]]
                    ? this.getRoleClassDisplayName(roleObj[roles[0]])
                    : roles[0] === 'superadmin'
                    ? 'Super Admin'
                    : '',
                )
            })
          }
          user.rbacAGs = rbacAGs
          user.rbacRoles = rbacRoles
          user.rbacRoleClass = rbacRoleClass
          user.rbacRoleExists = !!user.roles ? true : false
        })
        this.dataLoaded = true
        this.setState({
          allUsers: userList,
        })
        set.ALL_USERS(userList)
      })
      .catch((error: any) => {
        if (error && error.response) {
          this.dataLoaded = true
          const msg =
            error && typeof error?.response?.data == 'string'
              ? error.response.data
              : ''
          this.Utilities.showMessage('Unable to load users', 0, 0, msg)
          this.setState({
            loadingIcon: false,
          })
        }
      })
  }
  getRoleDisplayName = (roleName: string) => {
    const roleObj = this.roleList.filter((role: any) => {
      return role.name === roleName
    })
    return roleObj.length > 0
      ? roleObj[0]['displayName'] || roleObj[0]['name']
      : roleName
  }
  getRoleClassDisplayName = (roleClassName: string) => {
    const roleClassObj = this.roleClassList.filter((roleClass: any) => {
      return roleClass.name === roleClassName
    })
    return roleClassObj.length > 0
      ? roleClassObj[0]['displayName'] || roleClassObj[0]['name']
      : roleClassName
  }
  getUsersRbacRoles = () => {
    const {
      storage: {
        get: { PROVIDER },
        set,
      },
    } = this.context

    const getRbacRoles = this.OrganizationService.getUsersRbacRoles(
      null,
      null,
      [PROVIDER],
    )
    getRbacRoles
      .then((resp: any) => {
        const userRbacRoles = resp && resp.data ? resp.data : []
        const userList = this.state.allUsers
        userRbacRoles.map((rbacRole: any) => {
          userList.map((user: any) => {
            if (
              user['user-id'] === rbacRole.userId
                ? rbacRole.userId.toLowerCase()
                : rbacRole.userId
            ) {
              user.rbacRoles = rbacRole.roles
              user.rbacRoleExists = true
            }
          })
        })

        this.setState({
          allUsers: userList,
        })

        set.ALL_USERS(userList)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  handleAddFormChange = (data: any, isEdit: boolean) => {
    // @ts-ignore
    this.setState(
      {
        addUserObj: data,
      },
      () => {
        if (data.resetPassword) {
          this.setResetPasswordPage(true)
        }
      },
    )
  }

  SearchUsers = (e: any) => {
    const searchString = e.target.value
    this.Utilities.searchUser(
      this,
      searchString,
      'first-name',
      'last-name',
      'user-id',
      {
        storageName: 'ALL_USERS',
        stateName: 'allUsers',
      },
    )
  }

  handleSave = async () => {
    const {
      storage: {
        get: { ENCODED_SESSION_ID, PROVIDER },
      },
    } = this.context

    const {
      firstName,
      lastName,
      userId,
      emailId,
      rbacRoles,
    } = this.state.addUserObj
    const editMode = this.state.editMode

    const adminObj = {
      'email-id': emailId,
      'user-id': userId ? userId.toLowerCase() : userId,
      'first-name': firstName,
      'last-name': lastName,
      'roles-list': rbacRoles,
    }

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      charset: 'utf-8',
      Authorization: ENCODED_SESSION_ID,
      removeProvider: editMode ? false : true,
    }

    if (editMode) {
      delete adminObj['user-id']
      delete headers['Content-Type']
      delete headers.Accept
      delete headers.removeProvider
    }

    this.setState({
      loadingIcon: true,
    })

    const apiResponse = editMode
      ? this.OrganizationService.updateUser(headers, adminObj, [
          userId ? userId.toLowerCase() : userId,
        ])
      : this.OrganizationService.addUser(null, adminObj, PROVIDER)
    apiResponse
      .then((response: any) => {
        this.Utilities.showMessage(
          editMode ? 'SUCCESS_USER_UPDATE' : 'SUCCESS_USER_CREATE',
          1,
          true,
        )
        this.loadUsers()
        this.getRemainingUsers()
        this.setSlidingPage(false)
        this.setState({
          loadingIcon: false,
        })
      })
      .catch((error: any) => {
        this.setSlidingPage(false)
        let msg = ''
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.status === 409
        ) {
          msg = this.Messages.DUPLICATE_USER_NAME
        }
        this.Utilities.showMessage(
          editMode ? 'FAIL_USER_UPDATE' : 'FAIL_USER_CREATE',
          0,
          true,
          msg,
        )
        this.setState({
          loadingIcon: false,
        })
      })
    return false
  }

  checkForUserRole = (rbacAGs: any) => {
    if (!this.state.formUser) {
      return true
    } else {
      const curRbacAGs = this.state.formUser.rbacAGs
        ? this.state.formUser.rbacAGs
        : []
      if (curRbacAGs.length !== rbacAGs.length) {
        return true
      } else {
        let sameAGs: any = []
        curRbacAGs.map((outerObj: any) => {
          rbacAGs.map((innerObj: any) => {
            if (innerObj === outerObj) {
              sameAGs.push(innerObj)
            }
          })
        })
        return curRbacAGs.length !== sameAGs.length
      }
    }
  }

  updateUserRbacRoles = async (userId: any, roles: any) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    try {
      const payload = {
        providerId: PROVIDER,
        userId: userId ? userId.toLowerCase() : userId,
        roles: roles,
      }
      if (this.state.formUser.rbacRoleExists) {
        await this.OrganizationService.userRbacRoleUpdate(null, payload, [
          PROVIDER,
          userId ? userId.toLowerCase() : userId,
        ])
      } else {
        await this.OrganizationService.userRbacRoleCreate(null, payload, [
          PROVIDER,
        ])
      }
    } catch (err) {
      const msg =
        err?.response?.data && typeof err.response.data == 'string'
          ? err.response.data
          : ''
      this.Utilities.showMessage('Error in access group assignment', 0, 0, msg)
    }
  }

  createUserRbacRoles = async (userId: any, roles: any) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const payload = {
      providerId: PROVIDER,
      userId: userId ? userId.toLowerCase() : userId,
      roles: roles,
    }

    const createRole = this.OrganizationService.userRbacRoleUpdate(
      null,
      payload,
      [PROVIDER, userId ? userId.toLowerCase() : userId],
    )
    createRole
      .then((resp: any) => {
        this.loadUsers()
        this.setSlidingPage(false)
        this.setState({
          loadingIcon: false,
        })
      })
      .catch((error: any) => {
        const msg =
          error && typeof error?.response?.data == 'string'
            ? error.response.data
            : ''
        this.Utilities.showMessage(
          'Error in access group assignment',
          0,
          0,
          msg,
        )
        this.loadUsers()
        this.setSlidingPage(false)
        this.setState({
          loadingIcon: false,
        })
      })
  }

  handleOk = () => {
    this.deleteUser()
  }

  deleteUser = () => {
    const deleteUser = this.OrganizationService.deleteUser(null, null, [
      this.state.selectedUser['user-id'],
    ])
    this.setState({
      loadingIconModal: true,
    })
    deleteUser
      .then((resp: any) => {
        A10Message.success('User deleted successfully', 10, close)
        this.dataLoaded = false
        this.loadUsers()
        this.getRemainingUsers()
        this.setState({
          loadingIconModal: false,
          deleteModalState: false,
          selectedUser: { 'user-id': '' },
          searchString: null,
        })
      })
      .catch((error: any) => {
        const msg =
          error && typeof error?.response?.data == 'string'
            ? error.response.data
            : ''
        const messsge = 'Unable to delete user. ' + msg
        A10Message.error(messsge, 10, close)
        this.setState({
          loadingIconModal: false,
          deleteModalState: false,
          selectedUser: { 'user-id': '' },
          searchString: null,
        })
        return false
      })
    return true
  }

  handleCancel = () => {
    if (this.state.loadingIconModal) {
      return
    }
    this.setState({
      deleteModalState: false,
      selectedUser: { name: '' },
    })
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({ showSlidingPage: isOpen })
  }

  setResetPasswordPage = (isOpen: boolean) => {
    let { addUserObj } = this.state
    if (isOpen) {
      this.childForm = null
      this.setState({
        showSlidingPage: false,
        showResetPasswordForm: true,
        slidingTitle:
          'Reset Password for ' +
          addUserObj.firstName +
          (addUserObj.lastName ? ' ' + addUserObj.lastName : ''),
        slidingDesc: 'Email: ' + (addUserObj.emailId ? addUserObj.emailId : ''),
      })
    } else {
      addUserObj.resetPassword = false
      this.setState({ showResetPasswordForm: false, addUserObj })
    }
  }

  closeResetPasswordForm = () => {
    this.dataLoaded = true
    this.setState({ showResetPasswordForm: false, passwordReset: {} })
  }

  handleFormChange = (data: any) => {
    this.setState({
      passwordReset: data,
    })
  }

  resetPassword = () => {
    const payload = { ...this.state.passwordReset }

    if (payload['reset-type'] === 'set') {
      this.changePassword(payload)
      return
    }
    this.emailResetPasswordLink()
  }

  changePassword = (payload: any) => {
    const {
      storage: {
        get: { PROVIDER, ENCODED_SESSION_ID },
      },
    } = this.context

    if (payload.password !== payload['confirm-password']) {
      this.Utilities.showMessage(
        "Password & Confirm Password doesn't match!",
        0,
        0,
      )
      return
    }

    var userPass: any = {
      password: payload.password,
    }
    var headersPass = {
      'Content-Type': 'application/x-www-form-urlencoded',
      charset: 'utf-8',
      provider: PROVIDER,
      Authorization: ENCODED_SESSION_ID,
    }

    userPass = this.DashboardService.serializeData(userPass)
    var updateUser = this.AuthenticationService.updatePassword(
      headersPass,
      userPass,
      this.state.addUserObj['userId'],
    )
    updateUser
      .then((response: any) => {
        this.Utilities.showMessage('CHANGE_PASSWORD_SUCCESS', 1, true)
        this.closeResetPasswordForm()
      })
      .catch((error: any) => {
        this.Utilities.showMessage('CHANGE_PASSWORD_FAIL', 0, true)
        this.closeResetPasswordForm()
      })
  }

  emailResetPasswordLink = () => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      charset: 'utf-8',
      provider: PROVIDER,
    }
    const resetProviderPassword = this.AuthenticationService.resetProviderPassword(
      headers,
      null,
      this.state.addUserObj['userId'],
    )
    resetProviderPassword
      .then((response: any) => {
        let message: string = this.Messages.SUCCESS_PASSWORD_REQUEST
        message =
          typeof response?.data?.message !== 'undefined'
            ? response.data.message
            : message
        this.Utilities.showMessage(message, 1, false)
        this.closeResetPasswordForm()
      })
      .catch((error: any) => {
        const msg =
          error && typeof error?.response?.data == 'string'
            ? error.response.data
            : ''
        const message: string =
          'Failed to send password reset instructions. ' + msg
        this.Utilities.showMessage(message, 0, false)
        this.closeResetPasswordForm()
      })
  }

  editUser = (user: any) => {
    let userObj = this.state.addUserObj
    userObj.firstName = user['first-name'] ? user['first-name'] : ''
    userObj.lastName = user['last-name'] ? user['last-name'] : ''
    userObj.emailId = user['email-id'] ? user['email-id'] : ''
    userObj.userId = user['user-id']
      ? user['user-id'].toLowerCase()
      : user['user-id']
    userObj.mfaStatus = user['mfa-status'] ? user['mfa-status'] : ''
    userObj.rbacRoles = _.cloneDeep(user.rbacAGs)
    // start - changes for ticket - HARMONY-21354
    userObj.rbacRole = user.rbacAGs.length > 0 ? user.rbacAGs[0] : ''
    // start - changes for ticket - HARMONY-21354
    userObj.showRbacRole = false
    userObj.resetPassword = false
    this.setState({
      formUser: user,
      addUserObj: userObj,
      editMode: true,
      showSlidingPage: true,
      showResetPasswordForm: false,
      slidingTitle: 'Edit User',
      slidingDesc: '',
    })
  }
  addUser = () => {
    const { maxUsers, remainingUsers, activeLicense } = this.state

    if (activeLicense !== 'No Active License') {
      if(maxUsers === -1 || remainingUsers > 0){
        let user = {
          firstName: '',
          lastName: '',
          emailId: '',
          userId: '',
          rbacRoles: [],
          rbacRole: '',
          showRbacRole: true,
          resetPassword: false,
        }
        this.setState({
          formUser: null,
          editMode: false,
          addUserObj: user,
          showSlidingPage: true,
          showResetPasswordForm: false,
          searchString: '',
          slidingTitle: 'Add new User',
          slidingDesc: 'Please provide the user’s information',
        })
      } else{
        this.setState({ showUpgrade: true })
      }
    } else{
      this.setState({ showAddLicense: true})
    } 
  }

  handleFormValidation = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  getAuthType = () => {
    const provider = storage.get.PROVIDER || 'root'

    this.AuthenticationService.getAuthProvider({ provider }, null, [provider])
      .then((response: any) => {
        const authProvider = _.get(response, [
          'data',
          'authentication-provider',
        ])
        if (authProvider) {
          this.isRemote = authProvider['remote-authorization']
          if (!this.isRemote) {
            this.addActionsColumn()
          }
        }
        this.filterColumn(authProvider['mfa-enabled'])
      })
      .catch(error => {
        this.isRemote = false
      })
  }

  canAddNewUsers = () => {
    const {
      storage: {
        get: { ADMIN_LEVEL },
      },
    } = this.context

    return (
      ADMIN_LEVEL === 'provider' &&
      !(this.infraLevel || this.certLevel) &&
      !this.isOperatorUser &&
      !this.isRemote
    )
  }

  isUsingLocalAuth = () => {
    const {
      storage: {
        get: { USER_SESSION_AUTHTYPE },
      },
    } = this.context

    return (
      USER_SESSION_AUTHTYPE.toLocaleLowerCase() === 'default' ||
      USER_SESSION_AUTHTYPE.toLocaleLowerCase() === 'local'
    )
  }

  exportUser = () => {
    let userCSVData = 'Name,Email,User ID,MFA,Access Group\n'
    const { allUsers } = this.state
    if (allUsers?.length > 0) {
      allUsers.forEach(item => {
        const name = `${item['first-name']} ${item['last-name']}`
        const accessGroup =
          item['rbacRoles'] && item['rbacRoles'].length > 0
            ? item['rbacRoles']
            : 'Not Assigned'
        const cellData = [
          name,
          item['email-id'],
          item['user-id'],
          item['mfa-status'],
          accessGroup,
        ]
        userCSVData += `${cellData.join(',')}\n`
      })
    }

    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(userCSVData)
    hiddenElement.target = '_blank'

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = 'User_Data.csv'
    hiddenElement.click()
  }

  getRemainingUsers = () => {
    this.TroubleShootService.getBackups(null, null, [
      storage.get.PROVIDER,
      'remaining-users',
    ])
      .then((resp: IObject) => {
        this.setState({
          maxUsers: resp?.data?.max_users,
          remainingUsers: resp?.data?.remaining_users,
        })
      })
      .catch((err: IObject) => {
        this.setState({
          activeLicense: err?.response?.data
        })
      })
  }

  render() {
    const {
      storage: {
        get: { ADMIN_LEVEL, PROVIDER, CURRENT_TENANT },
      },
    } = this.context

    const {
      showSlidingPage,
      showResetPasswordForm,
      allUsers,
      maxUsers,
      remainingUsers,
      showUpgrade,
      showAddLicense,
    } = this.state

    return (
      <>
        <ContentSection>
          {this.isUsingLocalAuth() ? null : (
            <A10Alert
              type="info"
              style={{ margin: '0 0 20px' }}
              message="Users are being managed by External Authentication System."
              showIcon={true}
            />
          )}
          <ContentHeader type="flex" align="middle" justify="space-between">
            <A10Col>
              <ContentTitle
                title="Users"
                count={maxUsers === -1 ? allUsers.length : undefined}
                remaining={`${remainingUsers}`}
                total={maxUsers}
              />
            </A10Col>
            <A10Col style={{ display: 'flex' }}>
              <A10Input.Search
                type="text"
                onChange={this.SearchUsers}
                name="searchBox"
                value={this.state.searchString}
                placeholder="Search Users"
                style={{ marginRight: '6px' }}
              />
              <ActionButton
                text="Refresh"
                onClick={this.loadUsers}
                iconProps={{ app: 'global', type: 'refresh' }}
              />
              {this.canAddNewUsers() && (
                <ActionButton
                  text="Add a User"
                  onClick={this.addUser}
                  iconProps={{ app: 'global', type: 'add-new' }}
                />
              )}
              <ActionButton
                text="Export Local Users"
                onClick={this.exportUser}
                iconProps={{ app: 'global', type: 'export' }}
              />
            </A10Col>
          </ContentHeader>
          <ContentBody ref={this.ref}>
            <A10Table
              className={`${styles.userlist}`}
              columns={this.userColumns}
              dataSource={this.state.allUsers.map((key: any, index: number) => {
                key.key = index
                if (key.bandwidth === undefined || key.requests === undefined) {
                  return key
                } else {
                  return key
                }
              })}
              loading={
                !this.dataLoaded && {
                  indicator: <A10Loader container={this.ref} />,
                }
              }
              pagination={{ hideOnSinglePage: true, pageSize: 10 }}
              scroll={{ x: true }}
            />
          </ContentBody>
        </ContentSection>
        <A10Modal
          title="Delete User"
          visible={this.state.deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={this.state.loadingIconModal}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
              disabled={this.state.loadingIconModal}
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            Do you want to delete {this.state.selectedUser['user-id']} from{' '}
            {ADMIN_LEVEL === 'provider' ? PROVIDER : CURRENT_TENANT.name}?
          </p>
          {this.state.loadingIconModal ? (
            <div className="">
              <ReactLoading
                type="bars"
                color="#4a90e2"
                height={40}
                width={40}
              />
            </div>
          ) : null}
        </A10Modal>
        <FormatSlidingPage
          isOpen={showSlidingPage}
          onRequestOk={this.handleFormValidation}
          onRequestClose={this.setSlidingPage.bind(this, false)}
          title={this.state.slidingTitle}
          description={this.state.slidingDesc}
          disableSave={this.state.loadingIcon}
        >
          <>
            <UserForm
              defaultMethods={this.props.defaultMethods}
              addUserObj={this.state.addUserObj}
              userInfo={this.state.formUser}
              handleChange={this.handleAddFormChange}
              allUsers={this.state.allUsers}
              onSubmitForm={this.handleSave}
              setSlidingPage={this.setSlidingPage}
              loadUsers={this.loadUsers}
              onRef={(ref: any): any => (this.childForm = ref)}
            />
            {this.state.loadingIcon ? (
              <div className="">
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </div>
            ) : null}
          </>
        </FormatSlidingPage>

        <FormatSlidingPage
          isOpen={showResetPasswordForm}
          onRequestOk={this.handleFormValidation}
          onRequestClose={this.closeResetPasswordForm}
          title={this.state.slidingTitle}
          description={this.state.slidingDesc}
          saveText="Reset"
          disableSave={this.state.loadingIcon}
        >
          <PasswordResetForm
            handleChange={this.handleFormChange}
            onSubmitForm={this.resetPassword}
            onRef={(ref: any): any => (this.childForm = ref)}
          />
        </FormatSlidingPage>

        <UpgradeModal
          content={`You are using a license which only allows ${maxUsers} Users. For questions or upgrading your license please contact A10 Networks Customer Support or Sales.`}
          visible={showUpgrade}
          onClose={() => {
            this.setState({ showUpgrade: false })
          }}
        />
        <ActivateLicense
          content={'Please add a valid license to your account'}
          visible={showAddLicense}
          onClose={() => {
            this.setState({ showAddLicense: false })
          }}
        />
      </>
    )
  }
}
export default setupA10Container(RBACUsers)
