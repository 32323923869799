import storage from 'src/libraries/storage'

export class UserRole {
  SUPER_ADMIN_ROLE = [
    {
      role: 'superadmin',
      scope: {
        providers: {
          ids: ['*'],
        },
        users: {
          ids: ['*'],
        },
        roles: {
          ids: ['*'],
        },
        helptexts: {
          ids: ['*'],
        },
        apps: {
          ids: ['*'],
        },
        deviceclusters: {
          ids: ['*'],
        },
        licenses: {
          ids: ['*'],
        },
        subscriber: {
          ids: ['*'],
        },
        uuid: {
          ids: ['*'],
        },
        'hcms-version': {
          ids: ['*'],
        },
        blob: {
          ids: ['*'],
        },
        'validate-config': {
          ids: ['*'],
        },
      },
    },
  ]
  PROVIDER_ADMIN_ROLE = {
    role: 'provideradmin',
    scope: {
      ids: [],
      users: {
        ids: ['*'],
      },
      providers: {
        ids: [storage.get.PROVIDER_PATH],
        tenants: {
          ids: ['*'],
        },
        products: {
          ids: ['*'],
        },
      },
      licenses: {
        ids: ['*'],
      },
      deviceclusters: {
        ids: ['*'],
      },
      apps: {
        ids: ['*'],
      },
      subscriber: {
        ids: ['*'],
      },
      uuid: {
        ids: ['*'],
      },
      'hcms-version': {
        ids: ['*'],
      },
      blob: {
        ids: ['*'],
      },
      'validate-config': {
        ids: ['*'],
      },
    },
  }

  TENANT_ADMIN_ROLE = {
    role: 'tenantadmin',
    scope: {
      providers: {
        ids: [storage.get.PROVIDER_PATH],
        tenants: {
          ids: [],
        },
        products: {
          ids: ['*'],
        },
        cluster: {
          ids: ['*'],
        },
        device: {
          ids: ['*'],
        },
        'virtual-device': {
          ids: ['*'],
        },
        'device-backup': {
          ids: ['*'],
        },
        'device-image': {
          ids: ['*'],
        },
        'infra-account': {
          ids: ['*'],
        },
      },
      users: {
        ids: ['*'],
      },
      apps: {
        ids: ['*'],
      },
      deviceclusters: {
        ids: ['*'],
      },
      uuid: {
        ids: ['*'],
      },
      'hcms-version': {
        ids: ['*'],
      },
      blob: {
        ids: ['*'],
      },
      'validate-config': {
        ids: ['*'],
      },
    },
  }

  APP_ADMIN_ROLE = {
    role: 'appadmin',
    scope: {
      providers: {
        ids: [storage.get.PROVIDER_PATH],
        tenants: {
          ids: [],
          applications: {
            ids: ['*'],
          },
          cloudaccounts: {
            ids: ['*'],
          },
          dnsaccounts: {
            ids: ['*'],
          },
          metrics: {
            ids: ['*'],
          },
          pods: {
            ids: ['*'],
          },
          peps: {
            ids: ['*'],
          },
          agents: {
            ids: ['*'],
          },
          acaiapps: {
            ids: ['*'],
          },
          cspcluster: {
            ids: ['*'],
          },
          infracredentials: {
            ids: ['*'],
          },
          system: {
            ids: ['regions'],
          },
          analytics: {
            ids: ['*'],
          },
        },
        products: {
          ids: ['*'],
        },
      },
    },
  }

  RBAC_ROLE_TYPES = {
    provideradmin: [
      'hc_provider_admin',
      'hc_provider_operator',
      'hc_infra_admin',
      'hc_infra_operator',
      'hc_certificate_admin',
      'hc_certificate_operator',
    ],
    tenantadmin: [
      'hc_tenant_admin',
      'hc_tenant_operator',
      'hc_partition_admin',
      'hc_partition_operator',
    ],
    partitionadmin: ['hc_partition_admin', 'hc_partition_operator'],
    infraadmin: ['hc_infra_admin', 'hc_infra_operator'],
    certadmin: ['hc_certificate_admin', 'hc_certificate_operator'],
    operators: [
      'hc_provider_operator',
      'hc_infra_operator',
      'hc_certificate_operator',
      'hc_tenant_operator',
      'hc_partition_operator',
    ],
  }
}

export default UserRole
