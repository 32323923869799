import React from 'react'
import { _ } from '@gui-libraries/framework'
import {
  A10Collapse,
  A10DropdownMenu,
  A10Row,
  A10Table,
} from '@gui-libraries/widgets'
import { normalizeValue } from '@gui-libraries/viz'

import HealthStatus from 'src/components/ADC/HealthStatus'
import RoundNumber from 'src/components/shared/RoundNumber'
import storage from 'src/libraries/storage'

export interface IInterfaceEthernetProps {
  ethernets: IObject[]
  ethernetStatus: IObject
  openAutoForm: any
  updateEthernet: any
}

const InterfaceEthernet: React.FC<IInterfaceEthernetProps> = props => {
  const { ethernets, ethernetStatus, openAutoForm, updateEthernet } = props
  const [etheStatus, setEtheStatus] = React.useState({} as IObject)
  const [datasources, setDatasources] = React.useState([])
  const [counter, setCounter] = React.useState({} as IObject)

  const editEthernet = (ifnum: number) => {
    if (openAutoForm) {
      const params = ifnum ? { ifnum } : undefined
      openAutoForm('network/interfaces/lan', params)
    }
  }

  const enableEthernet = (ethernet: IObject) => {
    ethernet.action = ethernet.action === 'enable' ? 'disable' : 'enable'
    if (updateEthernet) {
      updateEthernet(ethernet)
    }
  }

  React.useEffect(() => {
    const status = { up: 0, down: 0, disable: 0 } as IObject
    const ds = [] as IObject[]
    const count = {} as IObject
    ;(ethernets || []).map((ethernet: IObject) => {
      const datasource = {} as IObject
      const { oper = {}, stats = {} } =
        (ethernetStatus || {})[ethernet.ifnum] || {}
      datasource.ifnum = ethernet.ifnum
      datasource.key = `${ethernet.ifnum}---${oper.state}`
      status[oper.state] = (status[oper.state] || 0) + 1
      datasource.status = oper.state
      datasource.action = ethernet.action
      datasource.mac = oper.mac || 'N/A'
      datasource.speed =
        oper.config_speed && oper.actual_speed
          ? `${oper.config_speed}/${oper.actual_speed}`
          : 'N/A'
      datasource.duplexity =
        oper.config_duplexity && oper.actual_duplexity
          ? `${oper.config_duplexity}/${oper.actual_duplexity}`
          : 'N/A'
      datasource.control = oper.is_blocked ? 'All Enabled' : 'All Blocked'

      let addrs = [] as string[]
      addrs = addrs.concat(
        (_.get(ethernet, ['ip', 'address-list'], []) || []).map(
          (ip: IObject) => ip['ipv4-address'],
        ),
      )
      addrs = addrs.concat(
        (_.get(ethernet, ['ipv6', 'address-list'], []) || []).map(
          (ip: IObject) => ip['ipv6-addr'],
        ),
      )
      datasource.ipaddress = addrs.join(', ')

      const packetIn = normalizeValue('number', {
        value: oper.rate_pkt_sent || 0,
        formatType: '0.0b',
        unit: 'B',
      })
      const packetOut = normalizeValue('number', {
        value: oper.rate_pkt_rcvd || 0,
        formatType: '0.0b',
        unit: 'B',
      })

      datasource.packets = `${packetIn}/ ${packetOut}`

      const byteIn = normalizeValue('number', {
        value: oper.rate_byte_sent || 0,
        formatType: '0.00a',
      })
      const byteOut = normalizeValue('number', {
        value: oper.rate_byte_rcvd || 0,
        formatType: '0.00a',
      })

      datasource.bytes = `${byteIn}/ ${byteOut}`
      datasource.ethernet = ethernet
      count.byteIn = (count.byteIn || 0) + (oper.rate_byte_sent || 0)
      count.byteOut = (count.byteOut || 0) + (oper.rate_byte_rcvd || 0)

      const errorIn = normalizeValue('number', {
        value: stats.input_errors || 0,
        formatType: '0.0b',
        unit: 'B',
      })
      const errorOut = normalizeValue('number', {
        value: stats.output_errors || 0,
        formatType: '0.0b',
        unit: 'B',
      })
      datasource.errors = `${errorIn}/ ${errorOut}`
      ds.push(datasource)
    })
    setCounter(count)
    setDatasources(ds)
    setEtheStatus(status)
  }, [ethernets, ethernetStatus])
  const columns = React.useMemo(
    () => [
      {
        dataIndex: 'status',
        sorter: (a: IObject, b: IObject) => {
          return a.status > b.status ? 1 : -1
        },
        render: (text: any, record: any) => {
          return (
            <HealthStatus
              type={record.status}
              tooltip={record.status || 'Unknown'}
              toolTipArrowPosition="topLeft"
            />
          )
        },
      },
      {
        title: 'Interface',
        dataIndex: 'ifnum',
        sorter: (a: IObject, b: IObject) => {
          return a.ifnum > b.ifnum ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'IP Address',
        width: 140,
        dataIndex: 'ipaddress',
        // sorter: (a: any, b: any) => a - b,
        render: (text: any, record: IObject) => {
          return (
            <div
              style={{
                textOverflow: 'ellipsis',
                width: 140,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              title={text}
            >
              {text}
            </div>
          )
        },
      },
      {
        title: 'MAC',
        dataIndex: 'mac',
        sorter: (a: IObject, b: IObject) => {
          return a.mac > b.mac ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text || 'N/A'
        },
      },

      {
        title: 'Speed',
        dataIndex: 'speed',
        sorter: (a: IObject, b: IObject) => {
          return a.speed > b.speed ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Duplexity',
        dataIndex: 'duplexity',
        sorter: (a: IObject, b: IObject) => {
          return a.duplexity > b.duplexity ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'MTU',
        dataIndex: 'mtu',
        sorter: (a: IObject, b: IObject) => {
          return a.mtu > b.mtu ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Packets In/Out',
        dataIndex: 'packets',
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Bytes In/Out',
        dataIndex: 'bytes',
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Errors In/Out',
        dataIndex: 'errors',
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        dataIndex: 'actions',
        width: 30,
        render: (text: any, record: any) => {
          return (
            !storage.get.IS_OPERATOR_USER && (
              <A10DropdownMenu
                title=""
                menu={[
                  <div
                    key="enable"
                    onClick={enableEthernet.bind(this, record.ethernet)}
                  >
                    {record.action === 'enable' ? 'Disable' : 'Enable'}
                  </div>,
                  <div
                    key="edit"
                    onClick={editEthernet.bind(this, record.ifnum)}
                  >
                    Edit
                  </div>,
                ]}
                style={{ color: '#757575', marginBottom: '-15px' }}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            )
          )
        },
      },
    ],
    [updateEthernet],
  )

  return (
    <div className="col-md-12 marginBottom10">
      <A10Collapse>
        <A10Collapse.Panel
          key="interface-ethernet"
          header={
            <>
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 width180">
                  Ethernet <RoundNumber number={ethernets.length} />
                </span>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Up</div>
                  <div className="pad-s-15 fontWeight300">
                    {etheStatus.up || 0}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Down</div>
                  <div className="pad-s-15 fontWeight300">
                    {etheStatus.down || 0}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Disabled</div>
                  <div className="pad-s-15 fontWeight300">
                    {etheStatus.disabled || 0}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Bytes In/Out</div>
                  <div className="pad-s-15 fontWeight300">
                    {normalizeValue('number', {
                      value: counter.byteIn || 0,
                      formatType: '0.00a',
                    })}
                    /{' '}
                    {normalizeValue('number', {
                      value: counter.byteOut || 0,
                      formatType: '0.00a',
                    })}
                  </div>
                </div>
              </A10Row>
            </>
          }
          className="collapsePanel"
        >
          <A10Table
            columns={columns}
            dataSource={datasources}
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
            bordered={false}
          />
        </A10Collapse.Panel>
      </A10Collapse>
    </div>
  )
}

export default InterfaceEthernet
