import React from 'react'

import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Form,
  A10Input,
  A10Select,
  A10Radio,
  A10Tooltip,
  A10Button,
} from '@gui-libraries/widgets'
const RadioGroup = A10Radio.Group
import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { IDefaultMethods } from 'src/containers/Controller'
import { Messages } from 'src/locale/en'
import { DashboardService } from 'src/services/DashboardService'
import { DropdownConstants } from 'src/constants/DropdownConstants/DropdownConstants'
import { Utilities } from 'src/services/Utilities'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import './styles/credentialaddform.scss'

export interface ICredentialAddFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  credentials: any
  form: any
  formData?: any
  editMode: boolean
  onRef?: any
  handleChange?: (data: any) => void
}

export interface ICredentialAddFormState {
  infraEnvironment: string
  accountName: string
  vCenterIPAddress: string
  userName: string
  userPassword: string
  awsType: string
  accessKey: string
  secretAccess: string
  roleARN: string
  externalID: string
  index: any
  subscriptionID: string
  tenantID: string
  clientID: string
  clientSecret: string
  host: string
  port: string
  bearerToken: string
  userID: string
  tenancyID: string
  regionIdentifier: string
  sshKeyContent: string
  sshKeyPassphrase: string
  sshKeyFingerprint: string
  editedSigningKey: string
  showUpdateSigningKeyButton: boolean
  editedBearerToken: string
  showUpdateBearerTokenButton: boolean
}

class CredentialAddForm extends A10Container<
  ICredentialAddFormProps,
  ICredentialAddFormState
> {
  Messages = new Messages()
  DashboardService = new DashboardService()
  DropdownConstants = new DropdownConstants()
  Utilities = new Utilities()

  constructor(props: ICredentialAddFormProps) {
    super(props)
    this.state = {
      infraEnvironment: this.props.formData.infraEnvironment,
      accountName: this.props.formData.accountName,
      vCenterIPAddress: this.props.formData.vCenterIPAddress,
      userName: this.props.formData.userName,
      userPassword: this.props.formData.userPassword,
      awsType: this.props.formData.awsType,
      accessKey: this.props.formData.accessKey,
      secretAccess: this.props.formData.secretAccess,
      roleARN: this.props.formData.roleARN,
      externalID: this.props.formData.externalID,
      index: this.props.formData.index,
      subscriptionID: this.props.formData.subscriptionID,
      tenantID: this.props.formData.tenantID,
      clientID: this.props.formData.clientID,
      clientSecret: this.props.formData.clientSecret,
      host: this.props.formData.host,
      port: this.props.formData.port,
      bearerToken: this.props.formData.bearerToken,
      userID: this.props.formData.userID,
      tenancyID: this.props.formData.tenancyID,
      regionIdentifier: this.props.formData.regionIdentifier,
      sshKeyContent: this.props.formData.sshKeyContent,
      sshKeyPassphrase: this.props.formData.sshKeyPassphrase,
      sshKeyFingerprint: this.props.formData.sshKeyFingerprint,
      editedSigningKey: this.props.formData.sshKeyContent,
      showUpdateSigningKeyButton: true,
      editedBearerToken: this.props.formData.bearerToken,
      showUpdateBearerTokenButton: true,
    }
  }
  userName = this.props.formData.userName
  vCenterIPAddress = this.props.formData.vCenterIPAddress

  validateDuplicateAccountName = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    let index = -1
    const userRegx = new RegExp(/^([a-zA-Z0-9._-]){1,50}$/)
    if (!value || '' === value) {
      rule.message = this.Messages.ACCOUNT_NAME_REQ
      isValid = false
    } else if (!userRegx.test(value)) {
      rule.message = this.Messages.INVALID_ACCOUNT_NAME
      isValid = false
    } else if (!this.props.editMode) {
      index = this.props.credentials.findIndex((key: any) => {
        return key.name === value
      })
      if (index > -1) {
        rule.message = this.Messages.ACCOUNT_NAME_DUP
        isValid = false
      }
    }
    return isValid ? cb() : cb(true)
  }

  validateDuplicateUserName = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    if (!value || '' === value) {
      rule.message = this.Messages.USERNAME_REQUIRED
      isValid = false
    }
    const index = this.props.credentials.findIndex((key: any) => {
      return (
        key['vmware-account'] &&
        key['vmware-account'].username.toLowerCase() === value.toLowerCase() &&
        (!this.props.editMode ||
          this.userName.toLowerCase() !== value.toLowerCase())
      )
    })
    if (index > -1) {
      let vcIPIndex = -1
      if (this.state.vCenterIPAddress) {
        vcIPIndex = this.checkUserNameVCIP(value, this.state.vCenterIPAddress)
      }

      if (vcIPIndex > -1) {
        rule.message = this.Messages.VCENTER_IP_USERNAME_DUP
        isValid = false
      }
    }

    return isValid ? cb() : cb(true)
  }

  checkUserNameVCIP = (userName: string, vcIP: string) => {
    let dupIndex = -1
    const checkObj = this.props.credentials.map((key: any) => {
      return (
        key['vmware-account'] &&
        key['vmware-account']['vcenter-ip'] === vcIP &&
        key['vmware-account'].username.toLowerCase() === userName.toLowerCase()
      )
    })
    if (!this.props.editMode) {
      dupIndex =
        checkObj &&
        checkObj.findIndex((key: any) => {
          return key === true
        })
    } else {
      dupIndex =
        checkObj &&
        checkObj.findIndex((key: any, i: number) => {
          return key === true && i !== this.state.index
        })
    }
    return dupIndex
  }

  validateDuplicateVCIP = (rule: any, value: any, cb: any) => {
    const IPV4_REGEX = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
    const IPV6_REGEX = /^([\da-fA-F]{1,4}:){1,7}([\da-fA-F]{1,4})$/
    const ipv4Regex = IPV4_REGEX
    const ipv6Regex = IPV6_REGEX
    const ipvalue = value || ''
    let isValid: boolean = true
    if (!value || '' === value) {
      rule.message = this.Messages.VCENTER_IP_ADDRESS_REQ
      isValid = false
    }
    let ip = null
    if (ipvalue.indexOf('/') !== -1) {
      ip = ipvalue.substring(0, ipvalue.indexOf('/'))
      if (!ipv4Regex.test(ip) && !ipv6Regex.test(ip)) {
        rule.message = this.Messages.VCENTER_IP_ADDRESS_INVALID
        isValid = false
      }
    } else {
      if (!ipvalue) {
        rule.message = this.Messages.VCENTER_IP_ADDRESS_REQ
        isValid = false
      } else if (!ipv4Regex.test(ipvalue) && !ipv6Regex.test(ipvalue)) {
        rule.message = this.Messages.VCENTER_IP_ADDRESS_INVALID
        isValid = false
      }
    }
    const index = this.props.credentials.findIndex((key: any) => {
      return (
        key['vmware-account'] &&
        key['vmware-account']['vcenter-ip'] === value &&
        (!this.props.editMode || this.vCenterIPAddress !== value)
      )
    })
    if (index > -1) {
      let userNameIndex = -1
      if (this.state.userName) {
        userNameIndex = this.checkUserNameVCIP(this.state.userName, value)
      }
      if (userNameIndex > -1) {
        rule.message = this.Messages.VCENTER_IP_USERNAME_DUP
        isValid = false
      }
    }

    return isValid ? cb() : cb(true)
  }

  handleChange = (stateName: string, e: any) => {
    let credentialObj = this.state
    if (e.target) {
      if (e.target.type === 'checkbox') {
        credentialObj[stateName] = e.target.checked
      } else {
        credentialObj[stateName] = e.target.value
      }
    } else {
      credentialObj[stateName] = e
    }
    if (stateName === 'infraEnvironment') {
      credentialObj['accountName'] = this.props.editMode
        ? credentialObj['accountName']
        : ''
      credentialObj['vCenterIPAddress'] = ''
      credentialObj['userName'] = ''
      credentialObj['userPassword'] = ''
      credentialObj['accessKey'] = ''
      credentialObj['secretAccess'] = ''
      credentialObj['roleARN'] = ''
      credentialObj['externalID'] = ''
      credentialObj['subscriptionID'] = ''
      credentialObj['tenantID'] = ''
      credentialObj['clientID'] = ''
      credentialObj['clientSecret'] = ''
      credentialObj['host'] = ''
      credentialObj['port'] = ''
      credentialObj['bearerToken'] = ''
      credentialObj['userID'] = ''
      credentialObj['tenancyID'] = ''
      credentialObj['regionIdentifier'] = ''
      credentialObj['sshKeyContent'] = ''
      credentialObj['sshKeyPassphrase'] = ''
      credentialObj['sshKeyFingerprint'] = ''
      credentialObj['sshKeyFingerprint'] = ''
      credentialObj['editedBearerToken'] = ''
      credentialObj['editedSigningKey'] = ''

      this.props.handleChange(credentialObj)
      this.setState({
        [stateName]: credentialObj[stateName],
        accountName: this.props.editMode ? credentialObj['accountName'] : '',
        vCenterIPAddress: '',
        userName: '',
        userPassword: '',
        accessKey: '',
        secretAccess: '',
        roleARN: '',
        externalID: '',
      })
    } else {
      this.props.handleChange(credentialObj)
      this.setState({
        [stateName]: credentialObj[stateName],
      })
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }
    const { getFieldDecorator } = this.props.form
    const {
      infraEnvironment,
      accountName,
      vCenterIPAddress,
      userName,
      userPassword,
      awsType,
      accessKey,
      secretAccess,
      roleARN,
      externalID,
      subscriptionID,
      tenantID,
      clientID,
      clientSecret,
      host,
      port,
      bearerToken,
      userID,
      tenancyID,
      regionIdentifier,
      sshKeyContent,
      sshKeyPassphrase,
      sshKeyFingerprint,
      editedBearerToken,
      editedSigningKey,
    } = this.state
    const accessKeyPattern = /^((?!\s).)*$/

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel
          title={
            <>
              <A10IconTextGroup
                text={
                  <>
                    <span className="titleAcc">
                      {
                        <>
                          Select an infrastructure environment
                        </>
                      }
                    </span>
                  </>
                }
                icon={
                  <A10Icon
                    style={{
                      width: 19,
                      height: 23,
                      marginLeft: 6,
                    }}
                    className="add-action"
                    app="harmony-controller"
                    type="license-manager"
                  />
                }
              />
            </>
          }
        >
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="mandatoryField">
                  {this.Messages.INFRA_ENVIRONMENT}
                </span>
                <span>
                  <HelpInfo
                    placement="leftTop"
                    title={this.Messages.INFRA_ENVIRONMENT}
                    helpKey="HELP_CREDENTIAL_ENVIRONMENT"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('infraEnvironment', {
              rules: [{ required: true, message: this.Messages.INFRA_ENV_SEL }],
              initialValue: !infraEnvironment ? undefined : infraEnvironment,
            })(
              <A10Select
                placeholder={this.Messages.INFRA_ENV_SEL}
                disabled={this.props.editMode}
                onChange={(e: any) => this.handleChange('infraEnvironment', e)}
              >
                <A10Select.Option key={'vmware'} value={'vmware'}>
                  VMware
                </A10Select.Option>
                <A10Select.Option key={'aws'} value={'aws'}>
                  AWS
                </A10Select.Option>
                <A10Select.Option key={'azure'} value={'azure'}>
                  Azure
                </A10Select.Option>
                <A10Select.Option key={'kubernetes'} value={'kubernetes'}>
                  Kubernetes
                </A10Select.Option>
                <A10Select.Option key={'oci'} value={'oci'}>
                  OCI
                </A10Select.Option>
              </A10Select>,
            )}
          </A10Form.Item>
        </A10Panel>
        {infraEnvironment === 'vmware' ? (
          <A10Panel
            title={
              <>
                <A10IconTextGroup
                  text={
                    <>
                      <span className="titleAcc">
                        {
                          <>
                            Account Details (VMWare)
                          </>
                        }
                      </span>
                    </>
                  }
                  icon={
                    <A10Icon
                      style={{
                        width: 19,
                        height: 23,
                        marginLeft: 6,
                      }}
                      className="add-action"
                      app="harmony-controller"
                      type="license-manager"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.ACCOUNT_NAME}
                  </span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.ACCOUNT_NAME}
                      helpKey="HELP_CREDENTIAL_ACCOUNT_NAME"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('accountName', {
                rules: [
                  { validator: this.validateDuplicateAccountName.bind(this) },
                ],
                initialValue: accountName,
              })(
                <A10Input
                  type="text"
                  placeholder={this.Messages.ACCOUNT_NAME}
                  disabled={this.props.editMode}
                  onChange={(e: any) => this.handleChange('accountName', e)}
                />,
              )}
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.VCENTER_IP_ADDRESS}
                  </span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.VCENTER_IP_ADDRESS}
                      helpKey="HELP_CREDENTIAL_VCENTER_IP_ADDRESS"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('vCenterIPAddress', {
                validateTrigger: 'onBlur',
                rules: [{ validator: this.validateDuplicateVCIP.bind(this) }],
                initialValue: vCenterIPAddress,
              })(
                <A10Input
                  type="text"
                  placeholder={this.Messages.VCENTER_IP_ADDRESS}
                  onChange={(e: any) =>
                    this.handleChange('vCenterIPAddress', e)
                  }
                />,
              )}
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.USER_NAME}
                  </span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.USER_NAME}
                      helpKey="HELP_CREDENTIAL_USER_NAME"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('userName', {
                validateTrigger: 'onBlur',
                rules: [
                  { validator: this.validateDuplicateUserName.bind(this) },
                ],
                initialValue: userName,
              })(
                <A10Input
                  type="text"
                  placeholder={this.Messages.USER_NAME}
                  onChange={(e: any) => this.handleChange('userName', e)}
                />,
              )}
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.PASSWORD}
                  </span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.PASSWORD}
                      helpKey="HELP_CREDENTIAL_PASSWORD"
                    />
                  </span>
                </>
              }
            >
              <div className="row">
                <div className="col-md-9">
                  {getFieldDecorator('userPassword', {
                    rules: [
                      {
                        required: true,
                        message: this.Messages.USER_PASSWORD_REQUIRED,
                      },
                    ],
                    initialValue: userPassword,
                  })(
                    <A10Input
                      type="password"
                      placeholder={this.Messages.PASSWORD}
                      onChange={(e: any) =>
                        this.handleChange('userPassword', e)
                      }
                    />,
                  )}
                </div>
              </div>
            </A10Form.Item>
          </A10Panel>
        ) : infraEnvironment === 'aws' ? (
          <A10Panel
            title={
              <>
                <A10IconTextGroup
                  text={
                    <>
                      <span className="titleAcc">
                        {
                          <>
                            Account Details (AWS)
                          </>
                        }
                      </span>
                    </>
                  }
                  icon={
                    <A10Icon
                      style={{
                        width: 19,
                        height: 23,
                        marginLeft: 6,
                      }}
                      className="add-action"
                      app="harmony-controller"
                      type="license-manager"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.ACCOUNT_NAME}
                  </span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.ACCOUNT_NAME}
                      helpKey="HELP_CREDENTIAL_ACCOUNT_NAME"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('accountName', {
                rules: [
                  { validator: this.validateDuplicateAccountName.bind(this) },
                ],
                initialValue: accountName,
              })(
                <A10Input
                  type="text"
                  placeholder={this.Messages.ACCOUNT_NAME}
                  disabled={this.props.editMode}
                  onChange={(e: any) => this.handleChange('accountName', e)}
                />,
              )}
            </A10Form.Item>

            {awsType === 'accessKey' ? (
              <>
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span className="mandatoryField">
                        {this.Messages.ACCESS_KEY}
                      </span>
                      <span>
                        <HelpInfo
                          placement="leftTop"
                          title={this.Messages.ACCESS_KEY}
                          helpKey="HELP_CREDENTIAL_AWS_ACCESS_KEY"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('accessKey', {
                    rules: [
                      {
                        required: true,
                        message: this.Messages.ACCESS_KEY_REQUIRED,
                      },
                      {
                        pattern: accessKeyPattern,
                        message: this.Messages.NO_SPACE,
                      },
                    ],
                    initialValue: accessKey,
                  })(
                    <A10Input
                      type="text"
                      placeholder={this.Messages.ACCESS_KEY}
                      onChange={(e: any) => this.handleChange('accessKey', e)}
                    />,
                  )}
                </A10Form.Item>

                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span className="mandatoryField">
                        {this.Messages.SECRET_ACCESS_KEY}
                      </span>
                      <span>
                        <HelpInfo
                          placement="leftTop"
                          title={this.Messages.SECRET_ACCESS_KEY}
                          helpKey="HELP_CREDENTIAL_AWS_SECRET_ACCESS_KEY"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('secretAccess', {
                    rules: [
                      {
                        required: true,
                        message: this.Messages.SECRET_ACCESS_KEY_REQUIRED,
                      },
                      {
                        pattern: accessKeyPattern,
                        message: this.Messages.NO_SPACE,
                      },
                    ],
                    initialValue: secretAccess,
                  })(
                    <A10Input
                      type="password"
                      placeholder={this.Messages.SECRET_ACCESS_KEY}
                      onChange={(e: any) =>
                        this.handleChange('secretAccess', e)
                      }
                    />,
                  )}
                </A10Form.Item>
              </>
            ) : (
              <>
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span className="mandatoryField">
                        {this.Messages.ROLE_ARN}
                      </span>
                      <span>
                        <HelpInfo
                          placement="leftTop"
                          title={this.Messages.ROLE_ARN}
                          helpKey="HELP_CREDENTIAL_AWS_ROLE_ARN"
                        />
                      </span>
                    </>
                  }
                >
                  <div className="row">
                    <div className="col-md-9">
                      {getFieldDecorator('roleARN', {
                        rules: [
                          {
                            required: true,
                            message: this.Messages.ROLE_ARN_REQUIRED,
                          },
                        ],
                        initialValue: roleARN,
                      })(
                        <A10Input
                          type="password"
                          placeholder={this.Messages.ROLE_ARN}
                          onChange={(e: any) => this.handleChange('roleARN', e)}
                        />,
                      )}
                    </div>
                  </div>
                </A10Form.Item>

                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span className="mandatoryField">
                        {this.Messages.EXTERNAL_ID}
                      </span>
                      <span>
                        <HelpInfo
                          placement="leftTop"
                          title={this.Messages.EXTERNAL_ID}
                          helpKey="HELP_CREDENTIAL_AWS_EXTERNAL_ID"
                        />
                      </span>
                    </>
                  }
                >
                  <div className="row">
                    <div className="col-md-9">
                      {getFieldDecorator('externalID', {
                        rules: [
                          {
                            required: true,
                            message: this.Messages.EXTERNAL_ID_REQUIRED,
                          },
                        ],
                        initialValue: externalID,
                      })(
                        <A10Input
                          type="password"
                          placeholder={this.Messages.EXTERNAL_ID}
                          onChange={(e: any) =>
                            this.handleChange('externalID', e)
                          }
                        />,
                      )}
                    </div>
                  </div>
                </A10Form.Item>
              </>
            )}
          </A10Panel>
        ) : infraEnvironment === 'azure' ? (
          <A10Panel
            title={
              <>
                <A10IconTextGroup
                  text={
                    <>
                      <span className="titleAcc">
                        {
                          <>
                            Account Details (Azure)
                          </>
                        }
                      </span>
                    </>
                  }
                  icon={
                    <A10Icon
                      style={{
                        width: 19,
                        height: 23,
                        marginLeft: 6,
                      }}
                      className="add-action"
                      app="harmony-controller"
                      type="license-manager"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.ACCOUNT_NAME}
                  </span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.ACCOUNT_NAME}
                      helpKey="HELP_CREDENTIAL_ACCOUNT_NAME"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('accountName', {
                rules: [
                  { validator: this.validateDuplicateAccountName.bind(this) },
                ],
                initialValue: accountName,
              })(
                <A10Input
                  type="text"
                  placeholder={this.Messages.ACCOUNT_NAME}
                  disabled={this.props.editMode}
                  onChange={(e: any) => this.handleChange('accountName', e)}
                />,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.AZURE_SUBSCRIPTION_ID}
                  </span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.AZURE_SUBSCRIPTION_ID}
                      helpKey="HELP_CREDENTIAL_AZURE_SUBSCRIPTION_ID"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('subscriptionID', {
                initialValue: subscriptionID,
              })(
                <A10Input
                  type="text"
                  placeholder={this.Messages.AZURE_SUBSCRIPTION_ID}
                  onChange={(e: any) => this.handleChange('subscriptionID', e)}
                />,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.AZURE_TENANT_ID}
                  </span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.AZURE_TENANT_ID}
                      helpKey="HELP_CREDENTIAL_AZURE_TENANT_ID"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('tenantID', {
                initialValue: tenantID,
              })(
                <A10Input
                  type="text"
                  placeholder={this.Messages.AZURE_TENANT_ID}
                  onChange={(e: any) => this.handleChange('tenantID', e)}
                />,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.AZURE_APP_ID}
                  </span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.AZURE_APP_ID}
                      helpKey="HELP_CREDENTIAL_AZURE_CLIENT_ID"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('clientID', {
                initialValue: clientID,
              })(
                <A10Input
                  type="text"
                  placeholder={this.Messages.AZURE_APP_ID}
                  onChange={(e: any) => this.handleChange('clientID', e)}
                />,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.AZURE_CLIENT_SECRET}
                  </span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.AZURE_CLIENT_SECRET}
                      helpKey="HELP_CREDENTIAL_AZURE_CLIENT_SECRET"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('clientSecret', {
                message: this.Messages.USER_PASSWORD_REQUIRED,
                initialValue: clientSecret,
              })(
                <A10Input
                  type="password"
                  placeholder={this.Messages.AZURE_CLIENT_SECRET}
                  onChange={(e: any) => this.handleChange('clientSecret', e)}
                />,
              )}
            </A10Form.Item>
          </A10Panel>
        ) : infraEnvironment === 'kubernetes' ? (
          <A10Panel
            title={
              <>
                <A10IconTextGroup
                  text={
                    <>
                      <span className="titleAcc">
                        {
                          <>
                            Account Details (Kubernetes)
                          </>
                        }
                      </span>
                    </>
                  }
                  icon={
                    <A10Icon
                      style={{
                        width: 19,
                        height: 23,
                        marginLeft: 6,
                      }}
                      className="add-action"
                      app="harmony-controller"
                      type="license-manager"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.ACCOUNT_NAME}
                  </span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.ACCOUNT_NAME}
                      helpKey="HELP_CREDENTIAL_ACCOUNT_NAME"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('accountName', {
                rules: [
                  { validator: this.validateDuplicateAccountName.bind(this) },
                ],
                initialValue: accountName,
              })(
                <A10Input
                  type="text"
                  placeholder={this.Messages.ACCOUNT_NAME}
                  disabled={this.props.editMode}
                  onChange={(e: any) => this.handleChange('accountName', e)}
                />,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">API Server Host</span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title="API Server Host"
                      helpKey="HELP_CREDENTIAL_KUBERNETES_API_SERVER_HOST"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('host', {
                initialValue: host,
              })(
                <A10Input
                  type="text"
                  placeholder="API Server Host IP OR Name"
                  onChange={(e: any) => this.handleChange('host', e)}
                />,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span>API Server Port</span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title="API Server Port"
                      helpKey="HELP_CREDENTIAL_KUBERNETES_API_SERVER_PORT"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('port', {
                initialValue: port,
                rules: [
                  {
                    required: false,
                    validateTrigger: 'onBlur',
                    validator: this.Utilities.validatePort.bind(
                      this,
                      'not-mandatory',
                    ),
                  },
                ],
              })(
                <A10Input
                  type="text"
                  placeholder="6443"
                  onChange={(e: any) => this.handleChange('port', e)}
                />,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">Bearer Token</span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title="Bearer Token"
                      helpKey="HELP_CREDENTIAL_KUBERNETES_BEARER_TOKEN"
                    />
                  </span>
                </>
              }
            >
              {this.props.editMode ? (
                <>
                  <A10Button
                    className="pull-left no-border"
                    onClick={() => {
                      let credentialObj = this.state
                      credentialObj['editedBearerToken'] = undefined
                      this.props.handleChange(credentialObj)
                      this.setState({
                        showUpdateBearerTokenButton: false,
                      })
                    }}
                    style={{
                      padding: '0px',
                      color: '#4A90E2',
                      textDecorationLine: 'underline !important',
                    }}
                    hidden={!this.state.showUpdateBearerTokenButton}
                  >
                    Update
                  </A10Button>
                  {getFieldDecorator('editedBearerToken', {
                    rules: [
                      {
                        required: this.state.showUpdateBearerTokenButton
                          ? false
                          : true,
                        message: 'Please enter Bearer Token',
                      },
                    ],
                  })(
                    <A10Input.TextArea
                      autosize={{ minRows: 3, maxRows: 8 }}
                      type="text"
                      placeholder="Bearer Token"
                      onChange={(e: any) =>
                        this.handleChange('editedBearerToken', e)
                      }
                      hidden={this.state.showUpdateBearerTokenButton}
                    />,
                  )}
                  <A10Button
                    className="pull-right no-border"
                    onClick={() => {
                      this.props.form.setFieldsValue({
                        [`editedBearerToken`]: undefined,
                      })
                      let credentialObj = this.state
                      credentialObj['editedBearerToken'] = bearerToken
                      this.props.handleChange(credentialObj)
                      this.setState({
                        showUpdateBearerTokenButton: true,
                      })
                    }}
                    style={{
                      padding: '0px',
                      color: '#4A90E2',
                      textDecorationLine: 'underline !important',
                    }}
                    hidden={this.state.showUpdateBearerTokenButton}
                  >
                    Cancel
                  </A10Button>
                </>
              ) : (
                <>
                  {getFieldDecorator('bearerToken', {
                    initialValue: bearerToken,
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  })(
                    <A10Input.TextArea
                      autosize={{ minRows: 3, maxRows: 8 }}
                      type="text"
                      placeholder="Bearer Token"
                      onChange={(e: any) => this.handleChange('bearerToken', e)}
                    />,
                  )}
                  <A10Tooltip
                    title="Bearer token can be fetched by describing the secret associated with the account. For exact commands please refer to Kubernetes or product documentation."
                    placement="topLeft"
                    className="k8sBearerToken"
                  >
                    <A10Icon app="global" type="information" />
                  </A10Tooltip>
                </>
              )}
            </A10Form.Item>
          </A10Panel>
        ) : infraEnvironment === 'oci' ? (
          <A10Panel
            title={
              <>
                <A10IconTextGroup
                  text={
                    <>
                      <span className="titleAcc">
                        {
                          <>
                            Account Details (OCI)
                          </>
                        }
                      </span>
                    </>
                  }
                  icon={
                    <A10Icon
                      style={{
                        width: 19,
                        height: 23,
                        marginLeft: 6,
                      }}
                      className="add-action"
                      app="harmony-controller"
                      type="license-manager"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.ACCOUNT_NAME}
                  </span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.ACCOUNT_NAME}
                      helpKey="HELP_CREDENTIAL_ACCOUNT_NAME"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('accountName', {
                rules: [
                  { validator: this.validateDuplicateAccountName.bind(this) },
                ],
                initialValue: accountName,
              })(
                <A10Input
                  type="text"
                  placeholder={this.Messages.ACCOUNT_NAME}
                  disabled={this.props.editMode}
                  onChange={(e: any) => this.handleChange('accountName', e)}
                />,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">User OCID</span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title="User OCID"
                      helpKey="HELP_CREDENTIAL_OCI_USER_ID"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('userID', {
                initialValue: userID,
                rules: [
                  {
                    required: true,
                  },
                ],
              })(
                <A10Input
                  type="text"
                  placeholder="Please Enter User OCID"
                  onChange={(e: any) => this.handleChange('userID', e)}
                />,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">Tenancy OCID</span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title="Tenancy OCID"
                      helpKey="HELP_CREDENTIAL_OCI_TENANCY_ID"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('tenancyID', {
                initialValue: tenancyID,
                rules: [
                  {
                    required: true,
                  },
                ],
              })(
                <A10Input
                  type="text"
                  placeholder="Please Enter Tenancy ID"
                  onChange={(e: any) => this.handleChange('tenancyID', e)}
                />,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">Region Identifier</span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title="Region Identifier"
                      helpKey="HELP_CREDENTIAL_OCI_REGION_IDENTIFIER"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('regionIdentifier', {
                initialValue: regionIdentifier,
                rules: [
                  {
                    required: true,
                  },
                ],
              })(
                <A10Input
                  type="text"
                  placeholder="Region Identifier"
                  onChange={(e: any) =>
                    this.handleChange('regionIdentifier', e)
                  }
                />,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    API Signing Key Content (Private)
                  </span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title="API Signing Key Content (Private)"
                      helpKey="HELP_CREDENTIAL_OCI_API_SIGNING_KEY_CONTENT"
                    />
                  </span>
                </>
              }
            >
              {this.props.editMode ? (
                <>
                  <A10Button
                    className="pull-left no-border"
                    onClick={() => {
                      let credentialObj = this.state
                      credentialObj['editedSigningKey'] = undefined
                      this.props.handleChange(credentialObj)
                      this.setState({
                        showUpdateSigningKeyButton: false,
                      })
                    }}
                    style={{
                      paddingLeft: '0px',
                      color: '#4A90E2',
                      paddingTop: '10px',
                    }}
                    hidden={!this.state.showUpdateSigningKeyButton}
                  >
                    Update
                  </A10Button>

                  {getFieldDecorator('editedSigningKey', {
                    rules: [
                      {
                        required: this.state.showUpdateSigningKeyButton
                          ? false
                          : true,
                        message: 'Please enter API Signing Key Content',
                      },
                    ],
                  })(
                    <A10Input.TextArea
                      autosize={{ minRows: 3, maxRows: 8 }}
                      placeholder="API Signing Key Content"
                      onChange={(e: any) =>
                        this.handleChange('editedSigningKey', e)
                      }
                      hidden={this.state.showUpdateSigningKeyButton}
                    />,
                  )}

                  <A10Button
                    className="pull-right no-border"
                    onClick={() => {
                      this.props.form.setFieldsValue({
                        [`editedSigningKey`]: undefined,
                      })
                      let credentialObj = this.state
                      credentialObj['editedSigningKey'] = sshKeyContent
                      this.props.handleChange(credentialObj)
                      this.setState({
                        showUpdateSigningKeyButton: true,
                      })
                    }}
                    style={{
                      padding: '0px',
                      color: '#4A90E2',
                      textDecorationLine: 'underline !important',
                    }}
                    hidden={this.state.showUpdateSigningKeyButton}
                  >
                    Cancel
                  </A10Button>
                </>
              ) : (
                <>
                  {getFieldDecorator('sshKeyContent', {
                    initialValue: sshKeyContent,
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  })(
                    <A10Input.TextArea
                      autosize={{ minRows: 3, maxRows: 8 }}
                      placeholder="API Signing Key Content"
                      onChange={(e: any) =>
                        this.handleChange('sshKeyContent', e)
                      }
                    />,
                  )}
                </>
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span>API Signing Key Passphrase</span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title="API Signing Key Passphrase"
                      helpKey="HELP_CREDENTIAL_OCI_API_SIGNING_KEY_PASSPHRASE"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('sshKeyPassphrase', {
                initialValue: sshKeyPassphrase,
              })(
                <A10Input
                  type="password"
                  placeholder="API Signing Key Passphrase"
                  onChange={(e: any) =>
                    this.handleChange('sshKeyPassphrase', e)
                  }
                />,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    API Signing Key Fingerprint
                  </span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title="API Signing Key Fingerprint"
                      helpKey="HELP_CREDENTIAL_OCI_API_SIGNING_KEY_FINGERPRINT"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('sshKeyFingerprint', {
                initialValue: sshKeyFingerprint,
                rules: [
                  {
                    required: true,
                  },
                ],
              })(
                <A10Input
                  type="text"
                  placeholder="API Signing Key Fingerprint"
                  onChange={(e: any) =>
                    this.handleChange('sshKeyFingerprint', e)
                  }
                />,
              )}
            </A10Form.Item>
          </A10Panel>
        ) : null}
      </A10Form>
    )
  }
}
export default setupA10Container(A10Form.create()(CredentialAddForm))
