import './styles/index.less'

import {
  A10Col,
  A10Collapse,
  A10Row,
  A10DropdownMenu,
  A10Message,
} from '@gui-libraries/widgets'
import React, { useEffect, useState } from 'react'
import { SystemService, Utilities } from 'src/services/index'

import { EmailTemplateEditForm } from './EmailTemplateEditForm/index'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import Messages from 'src/locale/en/Messages'
import ReactLoading from 'react-loading'

const messages = new Messages()
const utilities = new Utilities()
const systemService = new SystemService()
interface ITemplateConfigProps {
  value: string
  key: string
  lastModifiedAt?: string
}

const editTitle = {
  welcome: 'Edit User Welcome Email',
  activation: 'Edit User Activation Email',
  resetPassword: 'Edit Reset Password Email',
}

const handleEmailTemplatePost = async (
  payload: ITemplateConfigProps[],
  successMessage: string,
  failureMessage: string,
) => {
  let apiResponse: boolean = false

  try {
    const response = await systemService.updateEmailTemplate(
      null,
      payload,
      null,
    )
    if (response) {
      utilities.showMessage(successMessage, 1, 1)
      apiResponse = true
    }
  } catch {
    utilities.showMessage(failureMessage, 0, 1)
  }

  return apiResponse
}

export const getTemplateData = async () => {
  let apiResponse: any = []
  try {
    const { data: response } = await systemService.getEmailTemplateData(
      null,
      null,
      null,
    )
    apiResponse = response
  } catch (error) {
    apiResponse = []
  }

  return apiResponse
}

export const getRequestedValue = (key: string, arrayToFind: any) => {
  const emailObject = arrayToFind?.find(
    (detailsObject: ITemplateConfigProps) => detailsObject.key === key,
  )
  return emailObject && emailObject.value
}

export const getEmailBody = (key: string, arrayToFind: any) => {
  let emailBody = ''
  switch (key) {
    case 'welcome':
      emailBody = getRequestedValue('welcomeEmail', arrayToFind)
      break
    case 'activation':
      emailBody = getRequestedValue('activationEmail', arrayToFind)
      break
    case 'resetPassword':
      emailBody = getRequestedValue('resetEmail', arrayToFind)
      break
    default:
      break
  }

  return emailBody
}

export const editClick = async (
  activeTemplate,
  subject: string,
  body: string,
) => {
  let payload: ITemplateConfigProps[] = []
  switch (activeTemplate) {
    case 'welcome':
      payload = [
        {
          key: 'welcomeEmailSubject',
          value: subject,
        },
        {
          key: 'welcomeEmail',
          value: body,
        },
      ]
      break

    case 'activation':
      payload = [
        {
          key: 'activationEmailSubject',
          value: subject,
        },
        {
          key: 'activationEmail',
          value: body,
        },
      ]
      break

    case 'resetPassword':
      payload = [
        {
          key: 'resetEmailSubject',
          value: subject,
        },
        {
          key: 'resetEmail',
          value: body,
        },
      ]
      break

    default:
      break
  }
  const response = await handleEmailTemplatePost(
    payload,
    'SUCCESS_TEMPLATE_UPDATE',
    'FAILED_TEMPLATE_UPDATE',
  )
  return response
}

export const getEmailSubject = (key: string, arrayToFind: any) => {
  let emailSubject = ''
  switch (key) {
    case 'welcome':
      emailSubject = getRequestedValue('welcomeEmailSubject', arrayToFind)
      break
    case 'activation':
      emailSubject = getRequestedValue('activationEmailSubject', arrayToFind)
      break
    case 'resetPassword':
      emailSubject = getRequestedValue('resetEmailSubject', arrayToFind)
      break
    default:
      break
  }
  return emailSubject
}

export const getResetKeys = (key: string) => {
  let resetKey = ''
  switch (key) {
    case 'welcome':
      resetKey = 'welcomeEmail,welcomeEmailSubject'
      break
    case 'activation':
      resetKey = 'activationEmail,activationEmailSubject'
      break
    case 'resetPassword':
      resetKey = 'resetEmail,resetEmailSubject'
      break
    default:
      break
  }

  return resetKey
}

const EmailTemplate: React.FC<IObject> = props => {
  const [isLoading, setLoading] = useState(false)
  const [showEmailTemplateForm, setShowEmailTemplateForm] = useState(false)
  const [emailTemplateDetails, setEmailTemplateDetails] = useState(null)
  const [activeTemplate, setActiveTemplate] = useState('')
  const [isExpand, setIsExpand] = useState(false)

  useEffect(() => {
    fetchEmailTemplateData()
  }, [])

  const fetchEmailTemplateData = async () => {
    setLoading(true)
    const data: IObject = await getTemplateData()
    setEmailTemplateDetails(data)
    setLoading(false)
  }

  const resetEmailServer = async (key: string) => {
    const urlQuery = getResetKeys(key)
    try {
      const response = await systemService.resetEmailTemplate(null, null, [urlQuery])
      if (response?.status === 204) {
        const message = `${messages.RESET_EMAIL_TEMPLATE_SUCCESS}`
        A10Message.success(message, 5, close)
        fetchEmailTemplateData()
      }
    } catch {
      const message = `${messages.RESET_EMAIL_TEMPLATE_FAILED}`
      A10Message.error(message, 5, close)
    }
  }

  const onEditOkClick = async (subject: string, body: string) => {
    setLoading(true)
    const editResponse = await editClick(activeTemplate, subject, body)
    if (editResponse) {
      fetchEmailTemplateData()
    }
    setShowEmailTemplateForm(!!false)
    setLoading(false)
    setActiveTemplate('')
  }

  const dropdownOptions = [
    <div key="edit">Edit</div>,
    <div key="reset">Reset Config</div>,
  ]

  const clickAction = (component: JSX.Element, keyString: string) => {
    if (component.key === 'edit') {
      setActiveTemplate(keyString)
      setShowEmailTemplateForm(!!true)
    } else if (component.key === 'reset') {
      resetEmailServer(keyString)
    }
  }

  const welcomeSubject = getRequestedValue('welcomeEmailSubject', emailTemplateDetails)
  const welcomeBody = getRequestedValue('welcomeEmail', emailTemplateDetails)
  const activationSubject = getRequestedValue('activationEmailSubject', emailTemplateDetails)
  const activationBody = getRequestedValue('activationEmail', emailTemplateDetails)
  const passwordSubject = getRequestedValue('resetEmailSubject', emailTemplateDetails)
  const passwordBody = getRequestedValue('resetEmail', emailTemplateDetails)

  return (
    <>
      <A10Row>
        <A10Collapse className="email-template-content-body-div" onChange={() => { setIsExpand(!isExpand) }}>
          <A10Collapse.Panel
            header={
              <A10Row>
                <A10Row>
                  <A10Col span={24}>
                    <span className="email-section-title">
                      {messages.EMAIL_TEMPLATE}
                    </span>
                    <span style={{ marginLeft: '-4px' }}>
                      <HelpInfo
                        placement="right"
                        title="EMAIL TEMPLATE"
                        helpKey="HELP_SYSTEM_EMAIL_TEMPLATE"
                      />
                    </span>
                  </A10Col>
                </A10Row>
                {isExpand ? (
                  <A10Row className="template-row-wrapper">
                    <A10Col span={5}>
                      <A10Col className="column-heading">
                        {messages.USER_WELCOME_EMAIL}
                      </A10Col>
                    </A10Col>
                    <A10Col span={5}>
                      <A10Col className="column-heading">
                        {messages.SUBJECT}
                      </A10Col>
                      <A10Col className="subject-content">
                        {emailTemplateDetails && welcomeSubject}
                      </A10Col>
                    </A10Col>
                    <A10Col span={14}>
                      <A10Row>
                        <A10Col span={23} className="column-heading">
                          {messages.EMAIL_BODY}
                        </A10Col>
                        <A10Col span={1} style={{ paddingLeft: '8px' }}>
                          <A10DropdownMenu
                            menu={dropdownOptions}
                            onClick={component =>
                              clickAction(component, 'welcome')
                            }
                            trigger="hover"
                            placement="bottomRight"
                            arrowPointAtCenter={true}
                          />
                        </A10Col>
                      </A10Row>
                      <A10Col className='body-content'>
                        {emailTemplateDetails && welcomeBody}
                      </A10Col>
                    </A10Col>
                  </A10Row>
                ) : (
                  <A10Row className='header-names'>
                    <A10Col span={8}>
                      <A10Col span={24} style={{ marginBottom: '8px' }}>
                        <span className="sub-heading">
                          {messages.USER_WELCOME_EMAIL}
                        </span>
                      </A10Col>
                      <A10Col span={24}>
                        {welcomeSubject && welcomeBody ? 'Enabled' : 'Not configured'}
                      </A10Col>
                    </A10Col>
                    <A10Col span={8}>
                      <A10Col span={24} style={{ marginBottom: '8px' }}>
                        <span className="sub-heading">
                          {messages.USER_ACTIVATION_EMAIL}
                        </span>
                      </A10Col>
                      <A10Col span={24}>
                        {activationSubject && activationBody ? 'Enabled' : 'Not configured'}
                      </A10Col>
                    </A10Col>
                    <A10Col span={8}>
                      <A10Col span={24} style={{ marginBottom: '8px' }}>
                        <span className="sub-heading">
                          {messages.RESET_PASSWORD_EMAIL}
                        </span>
                      </A10Col>
                      <A10Col span={24}>
                        {passwordSubject && passwordBody ? 'Enabled' : 'Not configured'}
                      </A10Col>
                    </A10Col>
                  </A10Row>
                )}

              </A10Row>
            }
            key="emailTemplate"
          >
            <A10Row className="template-row-wrapper">
              <A10Col span={5}>
                <A10Col className="column-heading">
                  {messages.USER_ACTIVATION_EMAIL}
                </A10Col>
              </A10Col>
              <A10Col span={5}>
                <A10Col className="column-heading">{messages.SUBJECT}</A10Col>
                <A10Col className="subject-content">
                  {emailTemplateDetails && activationSubject}
                </A10Col>
              </A10Col>
              <A10Col span={14}>
                <A10Row>
                  <A10Col span={23} className="column-heading">
                    {messages.EMAIL_BODY}
                  </A10Col>
                  <A10Col span={1} style={{ paddingLeft: '3px' }}>
                    <A10DropdownMenu
                      menu={dropdownOptions}
                      onClick={component =>
                        clickAction(component, 'activation')
                      }
                      trigger="hover"
                      placement="bottomRight"
                      arrowPointAtCenter={true}
                    />
                  </A10Col>
                </A10Row>
                <A10Col className='body-content'>
                  {emailTemplateDetails && activationBody}
                </A10Col>
              </A10Col>
            </A10Row>
            <A10Row className="template-row-wrapper">
              <A10Col span={5}>
                <A10Col className="column-heading">
                  {messages.RESET_PASSWORD_EMAIL}
                </A10Col>
              </A10Col>
              <A10Col span={5}>
                <A10Col className="column-heading">{messages.SUBJECT}</A10Col>
                <A10Col className="subject-content">
                  {emailTemplateDetails && passwordSubject}
                </A10Col>
              </A10Col>
              <A10Col span={14}>
                <A10Row>
                  <A10Col span={23} className="column-heading">
                    {messages.EMAIL_BODY}
                  </A10Col>
                  <A10Col span={1} style={{ paddingLeft: '3px' }}>
                    <A10DropdownMenu
                      menu={dropdownOptions}
                      onClick={component =>
                        clickAction(component, 'resetPassword')
                      }
                      trigger="hover"
                      placement="bottomRight"
                      arrowPointAtCenter={true}
                    />
                  </A10Col>
                </A10Row>
                <A10Col className='body-content'>
                  {emailTemplateDetails && passwordBody}
                </A10Col>
              </A10Col>
            </A10Row>
          </A10Collapse.Panel>
        </A10Collapse>
      </A10Row>
      <EmailTemplateEditForm
        show={showEmailTemplateForm}
        title={editTitle[activeTemplate]}
        emailSubject={getEmailSubject(
          activeTemplate,
          emailTemplateDetails || [],
        )}
        emailBody={getEmailBody(activeTemplate, emailTemplateDetails || [])}
        onClose={() => {
          setShowEmailTemplateForm(!!false)
        }}
        onActionCompleted={onEditOkClick}
      />
      {isLoading && (
        <div>
          <ReactLoading type="bars" color="#4a90e2" height={40} width={40} />
        </div>
      )}
    </>
  )
}

export default EmailTemplate
