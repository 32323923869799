export const DEVICE_DISK_CHART_CONFIG_PHYSICAL = {
  displayProperties: {
    name: 'Disk',
    type: 'auto',
    id: 'provider_device_disk',
    height: 215,
    containerHeight: 215,
    toolTip:
      'Avg Disk utilization timeseries  across the device for the selected time period.',
    visualizationType: 'histograms',
    units: '%',
    chartType: 'line;columnTimeSeries;histogram',
    seriesArr: [
      {
        name: 'Disk Utilization',
        formula: '(disk_total - disk_free)*100/disk_total',
        units: '',
      },
    ],
    allignSeries: 'single',
  },
  queries: [
    {
      dataSource: 'RPT',
      endPoint: 'thunder-adc',
      metrics: 'system_telemetry_log_environment',
      filters: ['device_uuid'],
      fields: ['disk_free', 'disk_total'],
      aggregation: 'avg',
      groupBy: '',
    },
  ],
}

export const DEVICE_DISK_CHART_CONFIG = {
  displayProperties: {
    name: 'Disk',
    type: 'custom',
    id: 'not_physical',
  },
}
