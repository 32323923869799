import React from 'react'
import {
  A10Container,
  _,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Notification } from '@gui-libraries/widgets'

import { occurArray } from 'src/libraries/arrayUtils'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import AssociateVport from './Associate'

export interface IMemberWrapperProps extends IA10ContainerDefaultProps {
  isShow: boolean
  tenant: string
  name: string
  type: string
  existVPorts: IObject[]
  onTriggerSwitch: (
    isOpen: boolean,
    record?: any,
    needRefresh?: boolean,
  ) => void
}

export interface IMemberWrapperState {
  isShow: boolean
  portNames: string[]
  currentSelectedNames: string[]
}

class VportWrapper extends A10Container<
  IMemberWrapperProps,
  IMemberWrapperState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      isShow: false,
      portNames: [],
      currentSelectedNames: [],
    }
  }

  componentWillMount() {
    this.init(this.props)
  }

  componentWillReceiveProps(nextProps: any) {
    this.init(nextProps)
  }

  init = (props: any) => {
    const { existVPorts = [] } = props
    const portNames = existVPorts.map((item: IObject) => {
      return item.uuid
    })
    this.setState({ portNames })
  }

  getHttpClient = () => {
    const {
      GLOBAL_CONFIG: {
        EPIC_DEPENDENCIES: { httpClient },
      },
    } = this.props
    return httpClient
  }

  getTemplatePrefixName = () => {
    const { type } = this.props
    if (!type) {
      return ''
    }
    if (type === 'aflex') {
      return type
    }
    const splitTypes = type.split('.')
    const name = splitTypes[splitTypes.length - 1]
    if (
      name === 'ssl-sid' ||
      name === 'destination-ip' ||
      name === 'source-ip' ||
      name === 'cookie'
    ) {
      return `persist-${name}`
    }
    return name
  }

  getTemplateName = () => {
    return `template-${this.getTemplatePrefixName()}`
  }

  closeSlidingPage = () => {
    const { onTriggerSwitch = _.noop } = this.props
    onTriggerSwitch(false)
  }

  okSlidingPage = async () => {
    const { onTriggerSwitch = _.noop, name } = this.props
    const { currentSelectedNames, portNames } = this.state
    const occur = occurArray(currentSelectedNames, portNames)
    if (occur.removeList && occur.removeList.length > 0) {
      for (const sgUuid of occur.removeList) {
        await this.bindUnbindTemplate(sgUuid, false)
      }
    }
    if (occur.newList && occur.newList.length > 0) {
      for (const sgUuid of occur.newList) {
        await this.bindUnbindTemplate(sgUuid, true)
      }
    }

    A10Notification.success({
      message: 'Success!',
      description: `Assign ${name} to vPort.`,
    })
    onTriggerSwitch(false, null, true)
  }

  bindUnbindTemplate = async (uuid: string, bind: boolean) => {
    const { name } = this.props
    const templateType = this.getTemplateName()
    const updateApi = `/hpcapi/v3/uuid/${uuid}`
    const httpClient = this.getHttpClient()
    try {
      const {
        data: { port: port },
      } = await httpClient.get(updateApi)
      let newPort = port
      if (newPort) {
        if (bind) {
          newPort[templateType] = name
        } else {
          newPort = _.omit(port, templateType)
        }
        await httpClient.put(updateApi, { port: newPort })
      }
    } catch (err) {
      console.log(err)
    }
  }

  onChangeSelectedValue = (selectedValues: string[]) => {
    this.setState({ currentSelectedNames: selectedValues })
  }

  render() {
    const { isShow, tenant, name } = this.props
    return (
      <FormatSlidingPage
        isOpen={isShow}
        onRequestClose={this.closeSlidingPage}
        onRequestOk={this.okSlidingPage}
        saveText="Assign"
        title={`${name} > Associate a vPort`}
        description="Please select a vPort to associate."
      >
        <AssociateVport
          tenant={tenant}
          templateName={this.getTemplatePrefixName()}
          existPortNames={this.state.portNames}
          onChangeSelectedValue={this.onChangeSelectedValue}
        />
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(VportWrapper)
