import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { useRequest, _, useAsyncEffect } from '@gui-libraries/framework'
import {
  A10Checkbox,
  A10Collapse,
  A10Empty,
  A10Input,
  A10Loader,
  A10Notification,
  A10Tree,
} from '@gui-libraries/widgets'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import storage from 'src/libraries/storage'
import HealthStatus from 'src/components/ADC/HealthStatus'
import { InfraStructureStatsService } from 'src/services'

import styles from './styles/index.module.less'

export interface IClusterDeviceTreeProps {
  onChange: (selectedDeviceKeyList: string[]) => void
  disableBladeDevice?: boolean
  treeHeight?: number
}

export interface ITreeNode {
  key: string
  title: string
  disabled?: boolean
  children?: ITreeNode[]
}

const infraStructureStatsService = new InfraStructureStatsService()

const ClusterDeviceTree: React.FC<IClusterDeviceTreeProps> = props => {
  const { onChange, disableBladeDevice = false, treeHeight = 400 } = props
  const provider = storage.get.PROVIDER
  const providerId = storage.get.PROVIDER_ID
  const container = useRef(null)
  const [vcsStates, setVcsStates] = useState({})
  const [searchString, setSearchString] = useState('')
  const [isSelectAll, setIsSelectAll] = useState(false)

  // Tree state
  const [filteredClusterDeviceTree, setFilteredClusterDeviceTree] = useState<
    ITreeNode[]
  >([])
  const [autoExpandParent, setAutoExpandParent] = useState(false)
  const [expandedKeyList, setExpandedKeyList] = useState<string[]>([])
  const [selectedDeviceKeyList, setSelectedDeviceKeyList] = useState<string[]>(
    [],
  )
  const [filteredDeviceKeyList, setFilteredDeviceKeyList] = useState<string[]>(
    [],
  )

  useAsyncEffect(async () => {
    if (disableBladeDevice) {
      const header = {
        'x-providerids': providerId,
      }
      const payload = {
        deviceStats: {
          rangeby: {
            start: Date.now() - 3 * 60 * 1000,
            end: Date.now(),
            field: 'ts',
          },
          size: 10000,
        },
      }
      const res = await infraStructureStatsService.getClusterStats(
        header,
        payload,
        null,
      )
      const deviceStats = res?.data?.deviceStats || {}
      const vcsStates = Object.values(deviceStats).reduce(
        (acc: IObject, deviceStat: IObject) => {
          const { cluster_vcs_state, device_uuid } = deviceStat

          if (cluster_vcs_state === 'master' || cluster_vcs_state === 'blade')
            acc[device_uuid] = cluster_vcs_state

          return acc
        },
        {},
      )

      setVcsStates(vcsStates)
    }
  }, [])

  // Cluster API
  const [clusterData, { loading, error }] = useRequest<ITreeNode[]>(
    `/hpcapi/v3/provider/${provider}/cluster/`,
  )

  const clusterDeviceTree = useMemo(() => {
    const clusterList: IObject[] = clusterData?.['cluster-list'] || []
    const expandedKeys: string[] = []
    const filteredDeviceKeys: string[] = []

    const tree = clusterList.reduce((acc, cluster) => {
      const deviceList = cluster?.['referrer-list'] || []

      if (deviceList.length) {
        const clusterKey = `cluster:${cluster.name}`
        expandedKeys.push(clusterKey)

        acc.push({
          key: clusterKey,
          title: cluster.name,
          children: deviceList.map((device: IObject) => {
            const vcsState = vcsStates?.[device['device-uuid']]
            filteredDeviceKeys.push(device.name)
            const disabled = vcsState && vcsState === 'blade'
            return {
              key: device.name,
              title: device.name,
              isLeaf: true,
              disabled,
            }
          }),
        })
      }

      return acc
    }, []) as ITreeNode[]

    setFilteredClusterDeviceTree(tree)
    setExpandedKeyList(expandedKeys)
    setFilteredDeviceKeyList(filteredDeviceKeys)

    return tree
  }, [vcsStates, clusterData])

  useEffect(() => {
    if (error) {
      A10Notification.error({
        message: 'Error!',
        description: error.response.data.msg,
      })
    }
  }, [error])

  const onSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      const expandedKeys: string[] = []
      const filteredClusterNodes: ITreeNode[] = []
      const filteredDeviceKeys: string[] = []

      clusterDeviceTree.forEach(cluster => {
        if (cluster.title.toLowerCase().includes(value.toLowerCase())) {
          expandedKeys.push(cluster.key)
          filteredClusterNodes.push(cluster)
        } else if (cluster.children) {
          const filteredDeviceNodes: ITreeNode[] = []

          cluster.children.forEach(device => {
            if (device.title.toLowerCase().includes(value.toLowerCase())) {
              expandedKeys.push(cluster.key)
              filteredDeviceNodes.push(device)
            }
          })

          if (filteredDeviceNodes.length > 0) {
            const clusterObj = _.cloneDeep(cluster)
            clusterObj.children = filteredDeviceNodes
            filteredClusterNodes.push(clusterObj)
          }
        }
      })

      filteredClusterNodes.forEach(cluster => {
        cluster.children?.forEach(device => {
          filteredDeviceKeys.push(device.key)
        })
      })

      setExpandedKeyList(expandedKeys)
      setFilteredClusterDeviceTree(filteredClusterNodes)
      setSearchString(value)
      setAutoExpandParent(true)
      setFilteredDeviceKeyList(filteredDeviceKeys)
      setIsSelectAll(
        filteredDeviceKeys.every(
          filteredDevice =>
            selectedDeviceKeyList.length &&
            selectedDeviceKeyList.includes(filteredDevice),
        ),
      )
    },
    [clusterDeviceTree, selectedDeviceKeyList],
  )

  const onCheck = useCallback(
    (checkedKeys: string[]) => {
      let newSelection: string[]
      const checkedDevices = checkedKeys.filter(
        key => !key.startsWith('cluster:'),
      )

      if (
        checkedDevices.some(
          checkedDevice => !selectedDeviceKeyList.includes(checkedDevice),
        )
      ) {
        const devicesToAdd = checkedDevices.filter(
          checkedDevice => !selectedDeviceKeyList.includes(checkedDevice),
        )
        newSelection = selectedDeviceKeyList.concat(devicesToAdd)
      } else {
        const uniqueDevices = selectedDeviceKeyList.filter(
          selectedDevice => !checkedDevices.includes(selectedDevice),
        )
        const devicesToRemove = filteredDeviceKeyList.filter(filteredDevice =>
          uniqueDevices.includes(filteredDevice),
        )
        newSelection = selectedDeviceKeyList.filter(
          device => !devicesToRemove.includes(device),
        )
      }

      onChange(newSelection)
      setSelectedDeviceKeyList(newSelection)
      setIsSelectAll(
        filteredDeviceKeyList.every(
          filteredDevice =>
            newSelection.length && newSelection.includes(filteredDevice),
        ),
      )
    },
    [selectedDeviceKeyList, filteredDeviceKeyList],
  )

  const onExpand = useCallback((expandedKeys: string[]) => {
    const expandedClusters = expandedKeys.filter(key =>
      key.startsWith('cluster:'),
    )
    setExpandedKeyList(expandedClusters)
    setAutoExpandParent(false)
  }, [])

  const onSelectAll = useCallback(
    (e: CheckboxChangeEvent) => {
      const isSelectAllChecked = e.target.checked
      const devices: string[] = []
      let newSelection: string[]

      filteredClusterDeviceTree.forEach(cluster => {
        cluster.children?.forEach(device => {
          if(!device?.disabled){
            devices.push(device.key)
          }
        })
      })

      if (isSelectAllChecked) {
        const uniqueSelection = devices.filter(
          device => !selectedDeviceKeyList.includes(device),
        )
        newSelection = selectedDeviceKeyList.concat(uniqueSelection)
      } else {
        newSelection = selectedDeviceKeyList.filter(
          selectedDevice => !devices.includes(selectedDevice),
        )
      }

      onChange(newSelection)
      setSelectedDeviceKeyList(newSelection)
      setIsSelectAll(isSelectAllChecked)
    },
    [filteredClusterDeviceTree, selectedDeviceKeyList],
  )

  const renderTreeNodes = useCallback(
    (tree: ITreeNode[]) =>
      tree.map((node: ITreeNode) => {
        const { key, title, disabled, children } = node
        const _title =
          searchString &&
          title.toLowerCase().includes(searchString.toLowerCase()) ? (
            <b>{title}</b>
          ) : (
            title
          )
        if (children) {
          return (
            <A10Tree.TreeNode
              icon={<HealthStatus type="ongoing" text="C" />}
              title={_title}
              key={key}
            >
              {renderTreeNodes(children)}
            </A10Tree.TreeNode>
          )
        }
        return (
          <A10Tree.TreeNode
            icon={<HealthStatus type="ongoing" text="D" />}
            title={_title}
            key={key}
            disableCheckbox={disabled}
          />
        )
      }),
    [searchString],
  )

  return (
    <A10Collapse defaultActiveKey={['1']} className={styles.cdtPanel}>
      <A10Collapse.Panel
        key="1"
        showArrow={false}
        disabled
        className={styles.panelDiv}
        header={
          <div className={styles.panelHeader}>
            <div>
              <A10Input.Search
                style={{ marginBottom: 8 }}
                placeholder="Search"
                onChange={onSearch}
                disabled={loading || clusterDeviceTree.length === 0}
              />
            </div>
            <div>
              <A10Checkbox
                onChange={onSelectAll}
                checked={isSelectAll}
                disabled={filteredClusterDeviceTree.length === 0}
              >
                Select All
              </A10Checkbox>
            </div>
          </div>
        }
      >
        {loading ? (
          <div ref={container} style={{ minHeight: treeHeight }}>
            <A10Loader container={container} />
          </div>
        ) : filteredClusterDeviceTree.length ? (
          <A10Tree
            className={styles.panelContent}
            selectable={false}
            checkable={true}
            showIcon={true}
            expandedKeys={expandedKeyList}
            onExpand={onExpand}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={selectedDeviceKeyList}
            style={{ height: treeHeight }}
          >
            {renderTreeNodes(filteredClusterDeviceTree)}
          </A10Tree>
        ) : (
          <A10Empty image={A10Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </A10Collapse.Panel>
    </A10Collapse>
  )
}

ClusterDeviceTree.defaultProps = {
  onChange: _.noop,
}

export default ClusterDeviceTree
