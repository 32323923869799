import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Collapse,
  A10Tooltip,
  A10Icon,
  A10Tag,
  A10Table,
  A10DropdownMenu,
  A10Row,
} from '@gui-libraries/widgets'
import { SecondaryIPManagementForm } from 'src/containers/Controller/Infrastructure/VMDevices/Forms/SecondaryIPManagementForm'
import { Utilities } from 'src/services/index'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
const styles = require('./../../../Clusters/Cluster/Devices/DeviceDetails/styles/DeviceDetails.scss')
const moment = require('moment')

export interface IVMDeviceInfoProps extends IA10ContainerDefaultProps {
  vmDevice: any
  powerStateCheck: any
  deployStateCheck: any
  loadVMDevice: any
}
export interface IVMDeviceInfoState {
  vmDevice: any
  toggleState: boolean
  selectedInterface: any
  secondaryIPState: boolean
}

class VMDeviceInfo extends A10Container<
  IVMDeviceInfoProps,
  IVMDeviceInfoState
> {
  static getDerivedStateFromProps(
    nextProps: IVMDeviceInfoProps,
    prevState: IVMDeviceInfoState,
  ) {
    return {
      vmDevice: nextProps.vmDevice,
      toggleState: !prevState.toggleState,
    }
  }
  Utilities = new Utilities()
  state = {
    vmDevice: this.props.vmDevice,
    toggleState: true,
    selectedInterface: { network: '' },
    secondaryIPState: false,
  }

  vmPlacementColumns = [
    {
      title: 'DataCenter',
      key: 'datacenter',
      dataIndex: 'datacenter',
    },
    {
      title: 'Cluster',
      key: 'cluster',
      dataIndex: 'cluster',
    },
    {
      title: 'Folder',
      key: 'folder',
      dataIndex: 'folder',
    },
    {
      title: 'Resource Pool',
      key: 'resource-pool',
      dataIndex: 'resource-pool',
    },
    {
      title: 'Host',
      key: 'host',
      dataIndex: 'host',
    },
    {
      title: 'Datastore',
      key: 'datastore',
      dataIndex: 'datastore',
    },
  ]

  awsPlacementColumns = [
    {
      title: 'Region',
      key: 'region',
      dataIndex: 'region',
    },
    {
      title: 'Availability Zone',
      key: 'zone',
      dataIndex: 'zone',
    },
    {
      title: 'Virtual Private Cloud (VPC)',
      key: 'vpc',
      dataIndex: 'vpc',
      render: (text: string, record: any) => {
        return record['vpc'] || record['vpc-id']
      },
    },
  ]

  azurePlacementColumns = [
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'region',
    },
    {
      title: 'Resource Group',
      key: 'resourceGroup',
      dataIndex: 'resource-group',
    },
    {
      title: 'Virtual Network (VNET)',
      key: 'vnet',
      dataIndex: 'vpc',
      render: (text: string, record: any) => {
        return record['vpc'] || record['vpc-id']
      },
    },
  ]

  k8sPlacementColumns = [
    {
      title: 'Namespace',
      key: 'namespace',
      dataIndex: 'namespace',
    },
    {
      title: 'Host',
      key: 'host',
      dataIndex: 'host',
    },
  ]

  ociPlacementColumns = [
    {
      title: 'Region',
      key: 'region',
      dataIndex: 'region',
    },
    {
      title: 'Availability Domain',
      key: 'zone',
      dataIndex: 'zone',
    },
    {
      title: 'Compartment',
      key: 'compartment',
      dataIndex: 'compartment',
      render: (text: string, record: any) => {
        return record['compartment'] || record['compartment-id']
      },
    },
    {
      title: 'Virtual Cloud Network (VCN)',
      key: 'vcn',
      dataIndex: 'vcn',
      render: (text: string, record: any) => {
        return record['vpc'] || record['vpc-id']
      },
    },
  ]

  vnicColumns = [
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: (text: string, record: any) => {
        return this.virtualNetwork(record['interface-number'])
      },
    },
    {
      title:
        this.props.vmDevice['infra-provider'] === 'vmware' ||
        this.props.vmDevice['infra-provider'] === 'kubernetes'
          ? 'Network'
          : 'Subnet',
      key: 'network-name',
      dataIndex: 'network-name',
      render: (text: string, record: any) => {
        if (
          this.props.vmDevice['infra-provider'] === 'kubernetes' &&
          !record['network-name'] &&
          !record['network']
        ) {
          return 'Pod Network'
        }

        return record['network-name'] || record['network']
      },
    },
    {
      title: 'IP Address',
      key: 'ip-address',
      dataIndex: 'ip-address',
      render: (text: string) => {
        return <>{text ? <A10Tag>{text}</A10Tag> : null}</>
      },
    },
    {
      title:
        this.props.vmDevice['infra-provider'] === 'vmware'
          ? 'Default Gateway'
          : 'Security Group',
      key: 'default-gateway',
      dataIndex: 'default-gateway',
      render: (text: string, record: any) => {
        return this.props.vmDevice['infra-provider'] === 'vmware' ? (
          <>{text ? <A10Tag>{text}</A10Tag> : null}</>
        ) : (
          record['security-group'] || record['security-group-id']
        )
      },
    },
    {
      title: 'Connectivity Type',
      key: 'connectivity-type',
      dataIndex: 'connectivity-type',
      render: (text: string, record: any) => {
        return this.connectivityType(record['interface-number'])
      },
    },
    {
      title: 'IP Allocation Type',
      key: 'ip-allocation-type',
      dataIndex: 'ip-allocation-type',
      render: (text: string, record: any) => {
        return record['ip-allocation-type'] || 'Static'
      },
    },
    {
      title: 'MAC Address',
      key: 'mac-address',
      dataIndex: 'mac-address',
    },
    {
      title: 'Service Type',
      key: 'service-type',
      dataIndex: 'service-type',
      render: (text: string, record: any) => {
        return this.getServiceType(record)
      },
    },
    {
      title: 'Ports',
      key: 'ports',
      dataIndex: 'ports',
      render: (text: string, record: any) => {
        return this.getServicePorts(record)
      },
    },
  ]

  constructor(props: IVMDeviceInfoProps) {
    super(props)
  }

  addPublicIpColumn = () => {
    if (this.vnicColumns[3].key === 'default-gateway') {
      const publicIPColumn = {
        title: 'Public IP Address',
        key: 'public-ip-address',
        dataIndex: 'public-ip-address',
        render: (text: string) => {
          return <>{text ? <A10Tag>{text}</A10Tag> : null}</>
        },
      }
      this.vnicColumns.splice(3, 0, publicIPColumn)
    }
  }

  addSecondaryIPCountColumn = () => {
    if (this.vnicColumns[9].key !== 'secondary-ip-address') {
      const secondaryIPColumn = {
        title: 'Secondary IP Address Count',
        key: 'secondary-ip-address',
        dataIndex: 'secondary-ip-address',
        render: (text: string, record: any) => {
          return record['secondary-ip-address-count'] || 0
        },
      }
      this.vnicColumns.splice(8, 1, secondaryIPColumn)
    }
  }

  getServiceType = (nic: any) => {
    let serviceType = ''
    if (nic['interface-number'] === 'eth0') {
      if ('external-service-list' in nic) {
        serviceType = nic['external-service-list'][0]['type']
      }
    }
    return serviceType
  }

  getServicePorts = (nic: any) => {
    let servicePorts = ''
    if (nic['interface-number'] === 'eth0') {
      if ('external-service-list' in nic) {
        let portList = nic['external-service-list'][0]['port-list']
        servicePorts = ''
        for (const [, port] of portList.entries()) {
          servicePorts =
            servicePorts +
            port['port-number'] +
            (port['mapped-port-number']
              ? ':' + port['mapped-port-number']
              : '') +
            ', '
        }
      }
    }
    return servicePorts.slice(0, -2)
  }

  getResourceSize = (resourceType: string) => {
    const vmDevice = this.props.vmDevice
    if ('resource-list' in vmDevice) {
      for (const [, resource] of vmDevice['resource-list'].entries()) {
        if (resource['resource-type'] === resourceType) {
          return resource['resource-size']
        }
      }
    }
    return ''
  }

  getResource = (resourceType: string) => {
    const vmDevice = this.props.vmDevice
    if ('resource-list' in vmDevice) {
      for (const [, resource] of vmDevice['resource-list'].entries()) {
        if (resource['resource-type'] === resourceType) {
          return resource['resource']
        }
      }
    }
    return ''
  }

  closeSecondaryIPPage = () => {
    this.setState({ secondaryIPState: false })
    this.props.loadVMDevice(this.state.vmDevice)
  }

  addSecondaryIPAddressAction = () => {
    this.vnicColumns.splice(9, 1, {
      title: '',
      dataIndex: '',
      key: '',
      render: (key: any, record?: any) => {
        const clickAction = (component: JSX.Element) => {
          if (component.key === 'secondaryIp') {
            if (this.state.vmDevice.status === 'deployed') {
              this.setState({
                selectedInterface: record,
                secondaryIPState: true,
              })
            }
          }
        }
        const menuItem = []
        menuItem.push(
          <div
            key="secondaryIp"
            className={
              this.state.vmDevice.status === 'deployed' ? '' : 'disabled'
            }
          >
            Secondary IP Address
          </div>,
        )

        return (
          <div className="text-right">
            <i>
              <A10DropdownMenu
                menu={menuItem}
                title=""
                style={{ color: '#757575', marginBottom: '-15px' }}
                onClick={clickAction}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            </i>
          </div>
        )
      },
    })
  }

  virtualNetwork = (interfaceNum: string) => {
    if (interfaceNum === 'eth0') {
      return 'Management/eth0'
    } else if (interfaceNum) {
      return 'Ethernet' + interfaceNum.split('eth')[1] + '/' + interfaceNum
    } else {
      return ''
    }
  }
  connectivityType = (interfaceNum: string) => {
    if (interfaceNum === 'eth0') {
      return 'Management'
    } else {
      return 'Data'
    }
  }
  render() {
    const { vmDevice } = this.props
    const placement = vmDevice.placement
    const vnics = vmDevice['virtual-nic-list']
    const bandwidth = this.Utilities.megaToGiga(vmDevice['bandwidth'])
    if (
      vmDevice['infra-provider'] === 'aws' ||
      vmDevice['infra-provider'] === 'azure' ||
      vmDevice['infra-provider'] === 'oci'
    ) {
      this.addPublicIpColumn()
      this.addSecondaryIPAddressAction()
      this.addSecondaryIPCountColumn()
    }

    if (vmDevice['infra-provider'] === 'kubernetes') {
      this.addPublicIpColumn()
      // Remove security group and MAC-address column
      for (let i = 0; i < this.vnicColumns.length; i++) {
        if (
          this.vnicColumns[i].key === 'default-gateway' ||
          this.vnicColumns[i].key === 'mac-address'
        ) {
          this.vnicColumns.splice(i, 1)
        }
      }
    }

    if (vmDevice['infra-provider'] !== 'kubernetes') {
      for (let i = 0; i < this.vnicColumns.length; i++) {
        if (
          this.vnicColumns[i].key === 'service-type' ||
          this.vnicColumns[i].key === 'ports'
        ) {
          this.vnicColumns.splice(i, 1)
        }
      }
    }

    return (
      <div className="row rowDetails">
        <div className="device-detail-container">
          <A10Collapse bordered={false} defaultActiveKey={['1']}>
            <A10Collapse.Panel header="Device Detail" key="1">
              <A10Row type="flex" className="no-margin font-14 device-details">
                {vmDevice['infra-provider'] === 'aws' ||
                vmDevice['infra-provider'] === 'vmware' ? (
                  <div className="margin-h-15 pad-v-15 dev-content">
                    <div className="device-title">{'Cloud Object Id'}</div>
                    <div
                      className="pad-s-15"
                      title={vmDevice['infra-object-id']}
                    >
                      {vmDevice['infra-object-id']}
                    </div>
                  </div>
                ) : (
                  <div className="margin-h-15 pad-v-15 dev-content">
                    <div className="device-title">{'Cloud Object Id'}</div>
                    <div
                      className="pad-s-15"
                      title={vmDevice['infra-object-id']}
                    >
                      {vmDevice['infra-object-id']
                        ? '...' +
                          vmDevice['infra-object-id'].substring(
                            vmDevice['infra-object-id'].length - 15,
                          )
                        : null}
                    </div>
                  </div>
                )}
                <div className="margin-h-15 pad-v-15 dev-content">
                  <div className="device-title">{'Compute Type'}</div>
                  <div className="pad-s-15" title={vmDevice['compute-type']}>
                    {vmDevice['compute-type']}
                  </div>
                </div>
                <div className="margin-h-15 pad-v-15 dev-content">
                  <div className="device-title">{'Name'}</div>
                  <div className="pad-s-15" title={vmDevice.name}>
                    {vmDevice.name}
                  </div>
                </div>
                <div className="margin-h-15 pad-v-15 dev-content">
                  <div className="device-title">{'CPU Count'}</div>
                  <div className="pad-s-15">{vmDevice['cpu-count']}</div>
                </div>

                {vmDevice['infra-provider'] === 'aws' ||
                vmDevice['infra-provider'] === 'vmware' ? (
                  <div className="margin-h-15 pad-v-15 dev-content">
                    <div className="device-title">{'CPU Cores Per Socket'}</div>
                    <div
                      className="pad-s-15"
                      title={vmDevice['cpu-cores-per-socket']}
                    >
                      {vmDevice['cpu-cores-per-socket']}
                    </div>
                  </div>
                ) : null}
                {vmDevice['infra-provider'] === 'aws' ? null : (
                  <>
                    <div className="margin-h-15 pad-v-15 dev-content">
                      <div className="device-title">{'Memory Size'}</div>
                      <div className="pad-s-15">
                        {vmDevice['memory-size']
                          ? vmDevice['memory-size'] + 'MB'
                          : ''}
                      </div>
                    </div>
                    {vmDevice['infra-provider'] === 'kubernetes' ? (
                      <>
                        <div className="margin-h-15 pad-v-15 dev-content">
                          <div className="device-title">{'HugePages Type'}</div>
                          <div className="pad-s-15">
                            {this.getResource('hugepages')
                              ? this.getResource('hugepages')
                              : 'N/A'}
                          </div>
                        </div>
                        <div className="margin-h-15 pad-v-15 dev-content">
                          <div className="device-title">{'HugePages Size'}</div>
                          <div className="pad-s-15">
                            {this.getResource('hugepages')
                              ? this.getResourceSize('hugepages') + ' GB'
                              : 'N/A'}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="margin-h-15 pad-v-15 dev-content">
                        <div className="device-title">{'Disk Size'}</div>
                        <div className="pad-s-15">
                          {vmDevice['disk-size']
                            ? vmDevice['disk-size'] + 'MB'
                            : ''}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {vmDevice['infra-provider'] !== 'kubernetes' ? (
                  <div className="margin-h-15 pad-v-15 dev-content">
                    <div className="device-title">{'Power State'}</div>
                    <div className="pad-s-15">
                      <span>{vmDevice['power-state']}</span>
                      {vmDevice['power-op-status'] === 'error' ? (
                        <A10Tooltip
                          placement="bottom"
                          title={vmDevice['power-op-msg']}
                          arrowPointAtCenter
                          overlayStyle={{ width: '150px' }}
                        >
                          <A10Icon
                            style={{ marginLeft: '10px' }}
                            app="global"
                            type="warning-color"
                          />
                        </A10Tooltip>
                      ) : null}
                      {this.props.powerStateCheck ? (
                        <A10Icon
                          style={{ marginLeft: '10px' }}
                          type="sync"
                          spin
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {vmDevice['status'] === 'deployed' ? (
                  <div className="margin-h-15 pad-v-15 dev-content">
                    <div className="device-title">{'Launched on'}</div>
                    <div className="pad-s-15">
                      {moment(
                        parseInt(vmDevice['creation-date-time'], 10),
                      ).format('lll')}
                    </div>
                  </div>
                ) : null}

                <div className="margin-h-15 pad-v-15 dev-content">
                  <div className="device-title">{'Owner Account'}</div>
                  <div className="pad-s-15">
                    {vmDevice['owner-account'] || vmDevice['owner-account-id']}
                  </div>
                </div>
                <div className="margin-h-15 pad-v-15 dev-content">
                  <div className="device-title">{'Status'}</div>
                  <div className="pad-s-15">
                    <span>{vmDevice['status']}</span>
                    {vmDevice['status'] === 'error' ? (
                      <A10Tooltip
                        placement="bottom"
                        title={vmDevice['status-detail']}
                        arrowPointAtCenter
                        overlayStyle={{ width: '150px' }}
                      >
                        <A10Icon
                          style={{ marginLeft: '10px' }}
                          app="global"
                          type="warning-color"
                        />
                      </A10Tooltip>
                    ) : null}
                    {this.props.deployStateCheck ? (
                      <A10Icon
                        style={{ marginLeft: '10px' }}
                        type="sync"
                        spin
                      />
                    ) : null}
                  </div>
                </div>
                {vmDevice['infra-provider'] === 'aws' ||
                vmDevice['infra-provider'] === 'azure' ||
                vmDevice['infra-provider'] === 'oci' ? (
                  <div className="margin-h-15 pad-v-15 dev-content">
                    <div className="device-title">
                      {vmDevice['infra-provider'] === 'oci'
                        ? 'Shape'
                        : 'Instance Type'}
                    </div>
                    <div
                      className="pad-s-15"
                      title={vmDevice['infra-instance-type']}
                    >
                      {vmDevice['infra-instance-type']
                        ? vmDevice['infra-instance-type'].replace(/_/g, ' ')
                        : null}
                    </div>
                  </div>
                ) : null}

                <div className="margin-h-15 pad-v-15 dev-content">
                  <div className="device-title">{'Associated Cluster'}</div>
                  <div className="pad-s-15">{vmDevice['cluster-name']}</div>
                </div>

                <div className="margin-h-15 pad-v-15 dev-content">
                  <div className="device-title">{'Cluster Type'}</div>
                  <div className="pad-s-15">
                    {vmDevice['cluster-type'] === 'single'
                      ? 'Single'
                      : vmDevice['cluster-type'] === 'vcs'
                      ? vmDevice['cluster-data-plane-ha'] === 'scaleout'
                        ? 'VCS/Scaleout'
                        : 'VCS/VRRP-A'
                      : vmDevice['cluster-type'] === 'multinode'
                      ? vmDevice['cluster-data-plane-ha'] === 'scaleout'
                        ? 'Multinode/Scaleout'
                        : 'Multinode/VRRP-A'
                      : vmDevice['cluster-type']
                      ? vmDevice['cluster-type'].toUpperCase()
                      : ''}
                  </div>
                </div>

                {vmDevice['infra-provider'] === 'kubernetes' ? (
                  <div className="margin-h-15 pad-v-15 dev-content">
                    <div className="device-title">{'Product'}</div>
                    <div className="pad-s-15">
                      {this.getResource('product')
                        ? this.getResource('product')
                        : 'CFW'}
                    </div>
                  </div>
                ) : null}
                {vmDevice['license'] ? (
                  <>
                    <div className="margin-h-15 pad-v-15 dev-content">
                      <div className="device-title">{'Device License'}</div>
                      <div className="pad-s-15">{vmDevice['license']}</div>
                    </div>
                    <div className="margin-h-15 pad-v-15 dev-content">
                      <div className="device-title">{'Capacity'}</div>
                      <div className="pad-s-15">
                        {bandwidth.val + (bandwidth.unit.toUpperCase() + 'bps')}
                      </div>
                    </div>
                  </>
                ) : null}
              </A10Row>

              <div className="row pad-v-15">
                <div className="col-md-2">
                  <span className="title">Placement</span>
                </div>
                <div className="col-md-12 pad-v-5">
                  <A10Table
                    columns={
                      vmDevice['infra-provider'] === 'vmware'
                        ? this.vmPlacementColumns
                        : vmDevice['infra-provider'] === 'azure'
                        ? this.azurePlacementColumns
                        : vmDevice['infra-provider'] === 'aws'
                        ? this.awsPlacementColumns
                        : vmDevice['infra-provider'] === 'kubernetes'
                        ? this.k8sPlacementColumns
                        : vmDevice['infra-provider'] === 'oci'
                        ? this.ociPlacementColumns
                        : []
                    }
                    dataSource={
                      placement &&
                      [placement].map((key: any, index: number) => {
                        key.key = index
                        return key
                      })
                    }
                    size="small"
                    loading={false}
                    pagination={{ hideOnSinglePage: true, pageSize: 5 }}
                  />
                </div>
              </div>

              <div className="row pad-v-15">
                <div className="col-md-2">
                  <span className="title">vNic</span>
                </div>
                <div className="col-md-12 pad-v-5">
                  <A10Table
                    columns={this.vnicColumns}
                    dataSource={
                      vnics &&
                      vnics.map((key: any, index: number) => {
                        key.key = index
                        return key
                      })
                    }
                    size="small"
                    loading={false}
                    pagination={{ hideOnSinglePage: true, pageSize: 5 }}
                  />
                </div>
              </div>
            </A10Collapse.Panel>
          </A10Collapse>
        </div>

        <FormatSlidingPage
          isOpen={this.state.secondaryIPState}
          onRequestOk={this.closeSecondaryIPPage}
          title="Manage Secondary IP Address"
          saveText="Close"
          hideCancel={true}
        >
          <SecondaryIPManagementForm
            device={this.state.vmDevice}
            interface={this.state.selectedInterface}
          />
        </FormatSlidingPage>
      </div>
    )
  }
}

export default setupA10Container(VMDeviceInfo)
