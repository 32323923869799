import React from 'react'
import propTypes from 'prop-types'
import { A10Container, A10Context } from '@gui-libraries/framework'
import { A10Table, A10Button, A10Icon, A10Row } from '@gui-libraries/widgets'
import ReactLoading from 'react-loading'

import AssignHealthMonitorToServer from '../AssignHealthMonitorToServer/index'
import AssociateToTemplate from '../AssociateToTemplate/index'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

export interface IDeployedServerListProps {
  name: string
  type: string
  associateType: string
  templateName: string
  data: any[]
  tenant: string
  onClickDeploy?: any
  onClickAssign?: any
  onClickCancel?: any
  isLoading?: boolean
  source?: string
  hideAssign?: boolean
}

export interface IDeployedServerListState {
  showDeployServerSlidingPage: boolean
  showAssociateDeploySlidingPage: boolean
}

const defaultServerColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name', // TODO: change this key
  },
  {
    title: 'Working Version',
    dataIndex: 'working_version',
    key: 'working_version',
  },
  {
    title: 'Running Version',
    dataIndex: 'running_version',
    key: 'running_version',
  },
]

class DeployedClientSSLTemplateList extends A10Container<
  IDeployedServerListProps,
  IDeployedServerListState
> {
  static contextType = {
    refreshTemplateList: propTypes.func.isRequired,
    ...A10Context,
  }
  // context: React.ContextType<typeof HCContext & {refreshTemplateList: typeof propTypes.func.isRequired}>
  constructor(props: any) {
    super(props)
    this.state = {
      showDeployServerSlidingPage: false,
      showAssociateDeploySlidingPage: false,
    }
  }

  refresh = () => {
    this.context.refreshTemplateList()
  }

  onTriggerDeployServer = (
    isOpen: boolean,
    record?: IObject,
    needRefresh: boolean = false,
  ) => {
    this.setState({ showDeployServerSlidingPage: isOpen })
    if (typeof this.props.onClickAssign === 'function') {
      this.props.onClickAssign()
    }
    if (needRefresh) {
      this.refresh()
    }
  }

  onTriggerAssociateDeploy = (isOpen: boolean, record: any) => {
    this.setState({ showAssociateDeploySlidingPage: isOpen })
  }

  getAssignRenderBySource = () => {
    const {
      data = [],
      tenant,
      name,
      type,
      source,
      associateType,
      templateName,
    } = this.props

    if (!source) {
      return (
        <AssociateToTemplate
          isShow={this.state.showDeployServerSlidingPage}
          tenant={tenant}
          name={name}
          type={type}
          associateType={associateType}
          templateName={templateName}
          existList={data}
          onTriggerSwitch={this.onTriggerDeployServer}
        />
      )
    } else if (source === 'health-monitor') {
      return (
        <AssignHealthMonitorToServer
          isShow={this.state.showDeployServerSlidingPage}
          tenant={tenant}
          name={name}
          type={type}
          onTriggerSwitch={this.onTriggerDeployServer}
        />
      )
    }
    return <div />
  }

  render() {
    const {
      storage: {
        get: { IS_OPERATOR_USER },
      },
    } = this.context

    const { data = [], hideAssign = false } = this.props
    return (
      <React.Fragment>
        {!hideAssign && !IS_OPERATOR_USER ? (
          <A10Row className={styles.actionContainer}>
            <div className={`table-header ${styles.actionWrap}`}>
              <A10Button
                className="action-button"
                onClick={this.onTriggerDeployServer.bind(this, true)}
              >
                <A10Icon app="global" type="add-new" className="action-icon" />
                Assign
              </A10Button>
            </div>
          </A10Row>
        ) : null}
        <div className="inner-table">
          <A10Table
            className={styles.hcList}
            columns={defaultServerColumns}
            dataSource={data.map((item: IObject, index: number) => {
              item.key = index
              return item
            })}
            size="small"
            loading={
              this.props.isLoading
                ? {
                    indicator: (
                      <div className={styles.loadingIcon}>
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
          />
        </div>
        {this.getAssignRenderBySource()}
      </React.Fragment>
    )
  }
}

export default DeployedClientSSLTemplateList
