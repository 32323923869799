import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10DropdownMenu,
  A10Button,
  A10Modal,
  A10Loader,
} from '@gui-libraries/widgets'

import SlidingAccessGroup from '../AccessGroupForm/SlidingAccessGroup'
import AccessGroupDetail from './AccessGroupDetail'
import { OrganizationService, Utilities } from 'src/services'

import './styles/index.module.less'

export interface IAccessGroupListProps extends IA10ContainerDefaultProps {
  data: any[]
  search: string
  updateList: any
  isLoading?: boolean,
  authResponse?: IObject[]
}

export interface IAccessGroupListState {
  showSlidingPage: boolean
  name: string
  apiPrefix: string
  deleteModalState: boolean
  editObject: IObject
  editRole: string
  deleteObject: IObject
  needRefresh: boolean
  roleList: IObject[]
  rolesInUse: Set<string>
  accessroleList: IObject[]
}

class AccessGroupList extends A10Container<
  IAccessGroupListProps,
  IAccessGroupListState
> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  defaultAccessGroupColumns: any[]
  Utilities = new Utilities()
  OrganizationService = new OrganizationService()
  isOperatorUser
  authResponse: IObject
  ref = React.createRef<HTMLDivElement>()

  constructor(
    props: IAccessGroupListProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)
    const {
      storage: {
        get: { IS_OPERATOR_USER, PROVIDER },
      },
    } = context

    this.isOperatorUser = IS_OPERATOR_USER
    this.state = {
      showSlidingPage: false,
      name: '',
      apiPrefix: '',
      deleteModalState: false,
      editObject: {},
      editRole: '',
      deleteObject: {},
      needRefresh: false,
      roleList: [],
      rolesInUse: new Set(),
      accessroleList: [],
    }
    this.defaultAccessGroupColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) =>
          this.Utilities.sortStringKeys(a, b, 'name', 'name'),
        render: (text: string, record: any, index: number) => {
          return (
            <div>
              <span>{record['displayName'] || record.name}</span>
            </div>
          )
        },
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        sorter: (a: any, b: any) =>
          this.Utilities.sortStringKeys(a, b, 'role', 'role'),
        render: (text: string, record: any, index: number) => {
          const role = this.state.roleList.find(role => {
            return role.name === record.role
          })
          return (
            <div>
              <span>{(role && role.displayName) || record['role']}</span>
            </div>
          )
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'LDAP Group',
        dataIndex: 'ldapGroupDn',
        key: 'ldapGroupDn',
        sorter: (a: any, b: any) =>
          this.Utilities.sortStringKeys(a, b, 'ldapGroupDn', 'ldapGroupDn'),
        render: (text: string, record: any, index: number) => {
          return (
            <div>
              <span>{record['ldapGroupDn']}</span>
            </div>
          )
        },
      },
    ]
    // add actions column based on role
    if (!this.isOperatorUser) {
      this.defaultAccessGroupColumns.push({
        width: '30px',
        title: '',
        dataIndex: '',
        key: '',
        render: (record: any) => {
          const menuItems: any = []
          menuItems.push(<div key="edit">Edit</div>)
          if (
            this.state.rolesInUse.has(record.name) ||
            record.name === `hc_provider_admin-${PROVIDER}`
          ) {
            menuItems.push(
              <div key="delete" style={{ cursor: 'not-allowed', opacity: 0.6 }}>
                Delete
              </div>,
            )
          } else {
            menuItems.push(<div key="delete">Delete</div>)
          }
          return (
            <div className="table-dropdown">
              <A10DropdownMenu
                title=""
                menu={menuItems}
                onClick={this.onClickAction.bind(this, record)}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            </div>
          )
        },
      })
    }
  }

  async componentDidMount() {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const getRoleListReq = this.OrganizationService.getRbacRoleList(
      null,
      null,
      [PROVIDER],
    )
    getRoleListReq
      .then((resp: any) => {
        const roleList = resp && resp.data ? resp.data : []
        const roleSet: Set<string> = new Set()
        const accessRoleList = []
        for (const role of roleList) {
          roleSet.add(role.name)
          if (role.name !== 'hc_app_admin' && role.name !== 'hc_app_operator') {
            accessRoleList.push(role)
          }
        }
        this.setState({
          roleList,
          rolesInUse: roleSet,
          accessroleList: accessRoleList,
        })
      })
      .catch((error: any) => {
        // Error in get role API
      })
  }

  componentDidUpdate() {
    if (this.state.needRefresh) {
      this.setState({ needRefresh: false })
    }
  }

  onClickAction = (record: any, component: JSX.Element) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    if (component.key === 'edit') {
      this.setSlidingPage(true, record)
    } else if (component.key === 'delete') {
      if (
        this.state.rolesInUse.has(record.name) ||
        record.name === `hc_provider_admin-${PROVIDER}`
      ) {
        return
      } else {
        this.setState({ deleteModalState: true, deleteObject: record })
      }
    }
  }

  setSlidingPage = (isOpen: boolean, record: IObject) => {
    this.setState({
      showSlidingPage: isOpen,
      editObject: record,
      editRole: record?.role || '',
    })
  }

  onCloseSlidingPage = () => {
    this.setSlidingPage(false, {})
  }

  onSuccessUpdateAccessGroup = () => {
    const { updateList, search } = this.props
    updateList(search)
    this.setSlidingPage(false, {})
    this.setState({ needRefresh: true })
  }

  handleOk = async (e: React.MouseEvent<any>) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const { updateList, search } = this.props
    const { deleteObject } = this.state
    const deleteAccessGroup = this.OrganizationService.deleteAccessGroup(
      null,
      null,
      [PROVIDER, deleteObject.name],
    )
    await deleteAccessGroup
      .then((resp: any) => {
        this.Utilities.showMessage(
          `Deleted Access group ${deleteObject.displayName ||
          deleteObject.name} successfully.`,
          1,
          0,
        )
        updateList(search)
        this.setState({ deleteModalState: false })
      })
      .catch((error: any) => {
        const message = _.get(error, ['response', 'data', 'message'], '')
        this.Utilities.showMessage(
          'Error: Failed to delete the access group',
          0,
          0,
          message,
        )
      })
  }

  handleCancel = (e: React.MouseEvent<any>) => {
    this.setState({ deleteModalState: false })
  }

  renderAccessGroupDetail = (
    record: any,
    index: number,
    indent: number,
    expanded: boolean,
  ) => {
    return expanded ? (
      <AccessGroupDetail data={record} refresh={this.props.updateList} />
    ) : null
  }

  getRowKey = (record: IObject) => {
    return record.uuid
  }

  filterColumn = (authType: string, remoteAuth: boolean) => {
    let filteredCol: IObject[] = []
    if (authType === "ldap" && remoteAuth) {
      filteredCol = this.defaultAccessGroupColumns
    } else {
      filteredCol = this.defaultAccessGroupColumns.filter(item => {
        return item.key != 'ldapGroupDn'
      })
    }
    return filteredCol
  }

  render() {
    const { showSlidingPage, editObject, editRole, accessroleList } = this.state
    const formData = { accessGroup: editObject }

    const { data = [], authResponse } = this.props
    const accessGroupColumns = this.filterColumn(authResponse?.['type'], authResponse?.['remote-authorization'])

    return (
      <div ref={this.ref}>
        <A10Table
          rowKey={this.getRowKey}
          columns={accessGroupColumns}
          expandedRowRender={this.renderAccessGroupDetail}
          dataSource={data.map((item: IObject, index: number) => {
            item.key = index
            return item
          })}
          loading={
            this.props.isLoading && {
              indicator: <A10Loader container={this.ref} />,
            }
          }
        />
        <SlidingAccessGroup
          isOpen={showSlidingPage}
          isEdit={true}
          formData={formData}
          role={editRole}
          onRequestClose={this.onSuccessUpdateAccessGroup}
          accessroleList={accessroleList}
          authResponse={authResponse}
        />
        <A10Modal
          title="Confirmation"
          visible={this.state.deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>Do you want to delete this Item?</p>
        </A10Modal>
      </div>
    )
  }
}

export default setupA10Container(AccessGroupList)
