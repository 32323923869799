import { A10Col, A10Row } from '@gui-libraries/widgets'
import React from 'react'
import { ContentSection } from 'src/components/shared/ContentSection'
import { DeviceCommunication } from './DeviceCommunication/index'
import { EmailTemplate } from './EmailTemplate/index'
import { NTPDetails } from './NTPDetails/index'
import { ServiceLogging } from './ServiceLogging/index'
import SSLCertificate from './SSLCertificate'
import EmailServer from './EmailServer'

interface ISystemProps {}

const System: React.FC<ISystemProps> = props => {

  return (
    <ContentSection>
      <NTPDetails />
      <A10Row>
        <SSLCertificate />
      </A10Row>
        <EmailServer />
      <A10Row>
        <EmailTemplate />
      </A10Row>
      <A10Row>
        <A10Col span={12}>
          <ServiceLogging />
        </A10Col>
        <A10Col span={12}>
          <DeviceCommunication />
        </A10Col>
      </A10Row>
    </ContentSection>
  )
}

export default System
