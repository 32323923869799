import { A10Collapse, A10Icon, A10Row } from '@gui-libraries/widgets'
import React from 'react'
import RoundNumber from 'src/components/shared/RoundNumber'

const TunnelVxLan: React.FC = () => {
  return (
    <div className="col-md-12 marginBottom10">
      <A10Collapse>
        <A10Collapse.Panel
          header={
            <>
              <span className="edit-content">
                <A10Icon app="global" type="add-new" /> Add New
              </span>
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 fontWeight300 width250">
                  VxLAN <RoundNumber number={1} />
                </span>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Up</div>
                  <div className="pad-s-15 fontWeight300">2</div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Down</div>
                  <div className="pad-s-15 fontWeight300">1</div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Disabled</div>
                  <div className="pad-s-15 fontWeight300">1</div>
                </div>
              </A10Row>
            </>
          }
          className="collapsePanel"
        />
      </A10Collapse>
    </div>
  )
}

export default TunnelVxLan
