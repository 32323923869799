import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import { A10Collapse } from '@gui-libraries/widgets'
import { Messages } from 'src/locale/en'

export interface ICredentialInfoProps extends IA10ContainerDefaultProps {
  credential: any
}
export interface ICredentialInfoState {}

class CredentialInfo extends A10Container<
  ICredentialInfoProps,
  ICredentialInfoState
> {
  Messages = new Messages()

  constructor(props: ICredentialInfoProps) {
    super(props)
  }

  render() {
    const credential = this.props.credential
    return (
      <div className="row rowDetails marginLft">
        <div className="col-md-12">
          <A10Collapse bordered={false} defaultActiveKey={['1']}>
            {credential['infra-provider'] === 'vmware' ? (
              <A10Collapse.Panel
                header="VMWare Credential Information"
                key="1"
                className="no-border"
              >
                <div className="row pdLftBtm">
                  <div className="col-md-2">
                    <span className="title">
                      {this.Messages.VCENTER_IP_ADDRESS}
                    </span>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-3">
                        <span
                          title={credential['vmware-account']['vcenter-ip']}
                        >
                          {credential['vmware-account']['vcenter-ip']}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pdLftBtm">
                  <div className="col-md-2">
                    <span className="title">{this.Messages.USER_NAME}</span>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-3">
                        <span title={credential['vmware-account'].username}>
                          {credential['vmware-account'].username}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </A10Collapse.Panel>
            ) : credential['infra-provider'] === 'aws' ? (
              <A10Collapse.Panel
                header="AWS Credential Information"
                key="1"
                className="no-border"
              >
                {!!credential['aws-account']['access-key'] ? (
                  <div className="row pdLftBtm">
                    <div className="col-md-2">
                      <span className="title">{this.Messages.ACCESS_KEY}</span>
                    </div>
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-3">
                          <span title={credential['aws-account']['access-key']}>
                            {credential['aws-account']['access-key']}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {!!credential['aws-account']['role-arn'] ? (
                  <>
                    <div className="row pdLftBtm">
                      <div className="col-md-2">
                        <span className="title">
                          {this.Messages.ACCESS_KEY}
                        </span>
                      </div>
                      <div className="col-md-10">
                        <div className="row">
                          <div className="col-md-3">
                            <span title={credential['aws-account']['role-arn']}>
                              {credential['aws-account']['role-arn']}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pdLftBtm">
                      <div className="col-md-2">
                        <span className="title">{this.Messages.ROLE_ARN}</span>
                      </div>
                      <div className="col-md-10">
                        <div className="row">
                          <div className="col-md-3">
                            <span
                              title={credential['aws-account']['external-id']}
                            >
                              {credential['aws-account']['external-id']}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </A10Collapse.Panel>
            ) : credential['infra-provider'] === 'azure' ? (
              <A10Collapse.Panel
                header="Azure Credential Information"
                key="1"
                className="no-border"
              >
                {!!credential['azure-account']['subscription-id'] ? (
                  <div className="row pdLftBtm">
                    <div className="col-md-2">
                      <span className="title">
                        {this.Messages.AZURE_SUBSCRIPTION_ID}
                      </span>
                    </div>
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-6">
                          <span
                            title={
                              credential['azure-account']['subscription-id']
                            }
                          >
                            {credential['azure-account']['subscription-id']}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {!!credential['azure-account']['tenant-id'] ? (
                  <div className="row pdLftBtm">
                    <div className="col-md-2">
                      <span className="title">
                        {this.Messages.AZURE_TENANT_ID}
                      </span>
                    </div>
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-6">
                          <span
                            title={credential['azure-account']['tenant-id']}
                          >
                            {credential['azure-account']['tenant-id']}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {!!credential['azure-account']['client-id'] ? (
                  <div className="row pdLftBtm">
                    <div className="col-md-2">
                      <span className="title">
                        {this.Messages.AZURE_APP_ID}
                      </span>
                    </div>
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-6">
                          <span
                            title={credential['azure-account']['client-id']}
                          >
                            {credential['azure-account']['client-id']}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </A10Collapse.Panel>
            ) : credential['infra-provider'] === 'kubernetes' ? (
              <A10Collapse.Panel
                header="Kubernetes Credential Information"
                key="1"
                className="no-border"
              >
                {!!credential['kubernetes-account']['host'] ? (
                  <div className="row pdLftBtm">
                    <div className="col-md-2">
                      <span className="title">API Server Host</span>
                    </div>
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-3">
                          <span
                            title={credential['kubernetes-account']['host']}
                          >
                            {credential['kubernetes-account']['host']}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {!!credential['kubernetes-account']['port'] ? (
                  <div className="row pdLftBtm">
                    <div className="col-md-2">
                      <span className="title">API Server Port</span>
                    </div>
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-3">
                          <span
                            title={credential['kubernetes-account']['port']}
                          >
                            {credential['kubernetes-account']['port']}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </A10Collapse.Panel>
            ) : credential['infra-provider'] === 'oci' ? (
              <A10Collapse.Panel
                header="OCI Credential Information"
                key="1"
                className="no-border"
              >
                {!!credential['oci-account']['user-id'] ? (
                  <div className="row pdLftBtm">
                    <div className="col-md-2">
                      <span className="title">{this.Messages.OCI_USER_ID}</span>
                    </div>
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-10">
                          <span title={credential['oci-account']['user-id']}>
                            {credential['oci-account']['user-id']}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {!!credential['oci-account']['tenancy-id'] ? (
                  <div className="row pdLftBtm">
                    <div className="col-md-2">
                      <span className="title">
                        {this.Messages.OCI_TENANCY_ID}
                      </span>
                    </div>
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-10">
                          <span title={credential['oci-account']['tenancy-id']}>
                            {credential['oci-account']['tenancy-id']}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {!!credential['oci-account']['region'] ? (
                  <div className="row pdLftBtm">
                    <div className="col-md-2">
                      <span className="title">{this.Messages.OCI_REGION}</span>
                    </div>
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-10">
                          <span title={credential['oci-account']['region']}>
                            {credential['oci-account']['region']}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {!!credential['oci-account']['fingerprint'] ? (
                  <div className="row pdLftBtm">
                    <div className="col-md-2">
                      <span className="title">
                        {this.Messages.OCI_FINGERPRINT}
                      </span>
                    </div>
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-10">
                          <span
                            title={credential['oci-account']['fingerprint']}
                          >
                            {credential['oci-account']['fingerprint']}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </A10Collapse.Panel>
            ) : null}
          </A10Collapse>
        </div>
      </div>
    )
  }
}

export default setupA10Container(CredentialInfo)
