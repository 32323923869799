import React from 'react'
import ReactDOM from 'react-dom'

// styles of GUI widgets and libraries
import './styles/index.less'
import 'bootstrap/dist/css/bootstrap.min.css'

import HCProvider from './HCProvider'
import { Root } from './containers/Root'

// overwritten styles
import './styles/index.scss'
import './styles/main.less'

ReactDOM.render(
  <HCProvider>
    <Root />
  </HCProvider>,
  document.getElementById('root') as HTMLElement,
)
