import React, { useMemo, useState, useRef } from 'react'
import { useAsyncEffect } from '@gui-libraries/framework'
import { A10Row, A10Loader } from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import { InfrastructureService, Utilities } from 'src/services'
import { ClusterPartitions } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/clusterPartitions'
import { Devices } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/Devices'
import {
  VrrpaOverview,
  ScaleoutOverview,
} from 'src/containers/Controller/Infrastructure/Clusters/Cluster/Overview'
import { SystemServices } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/SystemServices'
import { ProtocolSettings } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/ProtocolSettings'
import { MonitorSettings } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/MonitorSettings'
import { PartitionSelector } from './PartitionSelector'
import styles from './styles/index.module.less'

export interface IClusterProps {
  tab: string
  clusterName: IObject
  clustersUpdated: boolean
  refreshClusterData: any
  refreshClusterSession: (onlyClusterSesSet: boolean) => void
  defaultMethods: any
  addPartition: any
}

export interface IClusterPartition {
  cluster: string
  partition: string
  type: string
}

const infrastructureService = new InfrastructureService()
const utilities = new Utilities()

const Cluster: React.FC<IClusterProps> = props => {
  const container = useRef(null)
  const {
    tab,
    clusterName,
    clustersUpdated,
    refreshClusterData,
    defaultMethods,
    addPartition,
    refreshClusterSession,
  } = props
  const shouldRenderCPSelector = useMemo(
    () =>
      tab === 'systemServices' ||
      tab === 'protocolSettings' ||
      tab === 'monitorSettings',
    [tab],
  )
  const [clusterPartition, setClusterPartition] = useState<IClusterPartition>({
    cluster: '',
    partition: '',
    type: 'shared',
  })
  const [loading, setLoading] = useState(false)
  const [cluster, setCluster] = useState<IObject>({})
  const [clusters, setClusters] = useState<IObject[]>([])

  useAsyncEffect(async () => {
    setLoading(true)

    try {
      const res = await infrastructureService.getCluster(
        storage.get.ADMIN_LEVEL === 'provider'
          ? null
          : utilities.getXAccountHeaderDetails(false),
        null,
        [storage.get.PROVIDER, clusterName],
      )
      const _cluster = res?.data?.cluster

      if (_cluster) {
        setCluster(_cluster)
        setClusters([_cluster])
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [clusterName, clustersUpdated])

  const renderTab = () => {
    const devices = cluster?.['referrer-list'] || []

    switch (tab) {
      case 'devices': {
        const now = new Date().getTime()
        const rangeByTime = {
          end: now,
          start: now - 2 * 60 * 1000,
        }
        return (
          <Devices
            cluster={cluster}
            clusterConfigMgmt={cluster.type}
            fromClustersPage={true}
            defaultMethods={defaultMethods}
            clustersUpdated={clustersUpdated}
            refreshClusterData={refreshClusterData}
            rangeByTime={rangeByTime}
            refreshClusterSession={refreshClusterSession}
          />
        )
      }
      case 'vrrpa': {
        return (
          <VrrpaOverview clustersUpdated={clustersUpdated} devices={devices} />
        )
      }
      case 'scaleout': {
        return (
          <ScaleoutOverview
            clustersUpdated={clustersUpdated}
            devices={devices}
          />
        )
      }
      case 'systemServices': {
        return (
          <SystemServices
            cluster={cluster}
            clusterPartition={clusterPartition}
          />
        )
      }
      case 'protocolSettings': {
        return (
          <ProtocolSettings
            cluster={cluster}
            clusterPartition={clusterPartition}
          />
        )
      }
      case 'monitorSettings': {
        return (
          <MonitorSettings
            cluster={cluster}
            clusterPartition={clusterPartition}
          />
        )
      }
      case 'clusterPartitions':
      default: {
        return (
          <ClusterPartitions
            cluster={cluster}
            clustersUpdated={clustersUpdated}
            refreshClusterData={refreshClusterData}
            addPartition={addPartition}
          />
        )
      }
    }
  }

  const renderClusterPartitionSelector = () => {
    return (
      <A10Row type="flex" align="middle">
        <span className={styles.title}>Cluster Partition</span>
        <PartitionSelector
          type="cluster"
          items={clusters}
          onClickNode={handleSelectCP}
        />
      </A10Row>
    )
  }

  const handleSelectCP = ({
    cluster,
    partition,
    type,
  }: {
    cluster: string
    partition: string
    type: string
  }) => {
    setClusterPartition({ cluster, partition, type })
  }

  return (
    <div ref={container} className={styles.container}>
      <A10Row>
        {loading ? (
          <A10Loader container={container} />
        ) : (
          <>
            {shouldRenderCPSelector && renderClusterPartitionSelector()}
            {renderTab()}
          </>
        )}
      </A10Row>
    </div>
  )
}

export default Cluster
