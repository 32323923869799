import React from 'react'
import { A10Chart } from '@gui-libraries/widgets'
import {
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import Settings from 'src/containers/Controller/Dashboard/Settings'
import { Map } from 'immutable'
import ChartWrapper from '../ChartWrapper'
import {
  mergeErrorCount,
  mergeErrorArray,
} from 'src/containers/Controller/Dashboard/utils/index'

export interface IPullUpAlertProps extends IA10ContainerDefaultProps {
  data: IObject
  selectedTimePeriod: IObject
}

const columnConfigSingle = {
  chart: {
    type: 'column',
    animation: false,
  },
  time: {
    timezoneOffset: new Date().getTimezoneOffset(),
  },
  title: {
    text: 'Stacked column chart',
  },
  xAxis: {
    title: {
      enabled: false,
    },
    type: 'datetime',
    labels: {
      format: '{value:%H:%M}',
    },
    // min: moment()
    //   .subtract(6, 'hours')
    //   .valueOf(),
    // max: moment().valueOf(),
  },
  yAxis: {
    title: {
      enabled: false,
    },
  },
  tooltip: {
    headerFormat: '<b>{point.x:%Y-%m-%d %H:%M:%S}</b><br/>',
    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: false,
      },
      pointPadding: 0,
      groupPadding: 0,
    },
  },
  series: new Array(),
}

class PullUpAlertChart extends React.Component<IPullUpAlertProps> {
  shouldComponentUpdate(nextProps: IPullUpAlertProps) {
    return nextProps.data !== this.props.data
  }

  setData = (data: IObject, timeRange: IObject) => {
    let alertCount = { total: 0, filters: {} }
    if (data.alertSeriesObj) {
      columnConfigSingle.series = data.alertSeriesObj.series
      alertCount = data.alertSeriesObj.count
    }
    columnConfigSingle.series = mergeErrorArray(columnConfigSingle.series)
    alertCount = mergeErrorCount(alertCount)

    columnConfigSingle.xAxis.min = timeRange.startTime
    columnConfigSingle.xAxis.max = timeRange.endTime
    if (timeRange.endTime - timeRange.startTime > 6 * 24 * 3600 * 1000) {
      columnConfigSingle.xAxis.labels = {
        format: '{value:%Y-%m-%d %H:%M:%S}',
      }
    } else {
      columnConfigSingle.xAxis.labels = {
        format: '{value:%H:%M}',
      }
    }

    return [columnConfigSingle, alertCount]
  }
  onClickText = () => {
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    // this.setState({ selectedTimeRange: selectTime })
    httpRequest({
      namespace: Settings.namespace.pullupViewCurrentStatus,
      request: async () => {
        return await 'min'
      },
    })
  }
  // getChartCounters = (chartConfigCount: IObject) => {
  //   if (chartConfigCount.filters) {
  //     const {
  //       Emergency = 0,
  //       Alert = 0,
  //       Notification = 0,
  //       Error: error = 0,
  //     } = chartConfigCount.filters
  //     const allSeverityCounter = Emergency + Alert + Notification + error

  //     chartConfigCount.filters['Top 4 Severity'] = allSeverityCounter
  //   }
  //   return chartConfigCount as { total: number; filters: IObject }
  // }
  render() {
    const { data, selectedTimePeriod } = this.props
    const [chartConfig, chartConfigCount] = this.setData(
      data,
      selectedTimePeriod,
    )
    return (
      <ChartWrapper onClick={this.onClickText} text="Hide">
        <A10Chart.Detail
          useSimpleHeader={true}
          mainTitle="Log Collection Stats"
          title=""
          maxFilterNumber={10}
          height={300}
          chartConfig={chartConfig}
          counters={chartConfigCount}
          type="column"
          defaultExpandCollapsed={true}
        />
      </ChartWrapper>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    data: state.A10Data.getIn(Settings.namespace.stackChartData, Map({})),
    selectedTimePeriod: state.A10Data.getIn(
      Settings.namespace.selectedDashboardTimeline,
      Map(Settings.rangePeriod),
    ).toJS(),
  }
}
export default setupA10Container(PullUpAlertChart, mapStateToProps)
