import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Col, A10Loader } from '@gui-libraries/widgets'
import { setItem, removeItem } from '@gui-libraries/framework'
import { DGF } from '@gui-libraries/dgf'

import providerClusters from './constants/providerClusters.json'
import providerCluster from './constants/providerCluster.json'
import {
  CLUSTER_DISK_CHART_CONFIG,
  CLUSTER_DISK_CHART_CONFIG_PHYSICAL,
} from './constants/providerClusterDisk'
import storage from 'src/libraries/storage'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import {
  CustomSlices,
  ICustomSlicesParams,
} from 'src/components/dashboard/CustomSlices'
import { InfrastructureService } from 'src/services/InfrastructureService'

import styles from './styles/index.module.less'

export interface IClusterAnalyticsProps extends IA10ContainerDefaultProps {
  clusterDeviceToggled: boolean
  mode: string
}
export interface IClusterAnalyticsState {
  isLoading: boolean
  clusters: IObject[]
  cluster: IObject
  clusterDashboard: IObject
}

class ClusterAnalytics extends A10Container<
  IClusterAnalyticsProps,
  IClusterAnalyticsState
> {
  InfrastructureService = new InfrastructureService()
  refContainer = React.createRef<HTMLDivElement>()

  constructor(props) {
    super(props)

    removeItem('DGF_PROVIDER_CAPACITY')
    removeItem('DGF_PROVIDER_CAPACITY_ALLDATA')

    const cluster = this.getSelectedCluster()

    if (cluster) {
      setItem('SELECTED_CONTEXT', cluster, true)
    } else {
      removeItem('SELECTED_CONTEXT')
    }

    this.state = {
      isLoading: true,
      clusters: storage.get.ALLCLUSTERS || [],
      cluster,
      clusterDashboard: this.formatClusterDashboard(cluster),
    }
  }

  componentDidMount() {
    if (storage.get.ALLCLUSTERS?.length) this.setState({ isLoading: false })
    else this.getClusters()
  }

  componentDidUpdate(prevProps: IClusterAnalyticsProps) {
    if (this.props.clusterDeviceToggled !== prevProps.clusterDeviceToggled) {
      const cluster = this.getSelectedCluster()

      if (cluster) {
        setItem('SELECTED_CONTEXT', cluster, true)
      } else {
        removeItem('SELECTED_CONTEXT')
      }

      this.setState({
        cluster,
        clusterDashboard: this.formatClusterDashboard(cluster),
      })
    }
  }

  renderCustomSlices = (argObj: ICustomSlicesParams) => {
    return <CustomSlices {...argObj} />
  }

  getSelectedCluster = () => {
    const clusters = storage.get.ALLCLUSTERS
    const currentCluster = storage.get.CURRENT_CLUSTER
    return clusters.find(cluster => {
      return cluster.name === currentCluster?.name
    })
  }

  formatClusterDashboard = (cluster?: IObject) => {
    if (!cluster) {
      return null
    }

    const _providerCluster = { ...providerCluster }
    const isScaleoutCluster = cluster['data-plane-ha'] === 'scaleout'
    const isPhysical = cluster['virtualization-type'] === 'Thunder'
    const { rows } = _providerCluster.dashboard

    rows.forEach((row: IObject) => {
      if (row.name === 'Scale Out') {
        row.isDisabled = !isScaleoutCluster
        row.collapsed = !isScaleoutCluster
        row.description = isScaleoutCluster
          ? ''
          : 'Cluster is not of type Scaleout'
      }

      if (row.name === 'Utilizations') {
        const { cols } = row
        cols.forEach((col: IObject) => {
          if (col.name === 'Disk') {
            col.vizualizations = isPhysical
              ? [CLUSTER_DISK_CHART_CONFIG_PHYSICAL]
              : [CLUSTER_DISK_CHART_CONFIG]
          }
        })
      }
    })

    return _providerCluster
  }

  getClusters = async () => {
    const allClusters = this.InfrastructureService.getClusters(null, null, [
      storage.get.PROVIDER,
    ])
    await allClusters
      .then((response: any) => {
        const clusters = response?.data?.['cluster-list'] || []
        storage.set.ALLCLUSTERS(clusters)
        this.setState({ clusters })
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { mode } = this.props
    const { clusters, cluster, clusterDashboard, isLoading } = this.state
    const tenants = storage.get.ALLTENANTS

    return (
      <div className={styles.clusterAnalyticsContainer} ref={this.refContainer}>
        {isLoading ? (
          <A10Loader container={this.refContainer} />
        ) : clusters.length === 0 || tenants.length === 0 ? (
          <ContentSection>
            <ContentHeader type="flex" align="middle" justify="space-between">
              <A10Col>
                <ContentTitle title="Cluster Analytics" />
              </A10Col>
            </ContentHeader>
            <ContentBody>
              <div className="empty-data-warn">
                <div className="empty-image-block">
                  <i className="empty-image-icon empty-no-device" />
                </div>
                <span>
                  There are no Infrastructure components to show analytics for.
                  Please add clusters, devices and tenants before any data can
                  be shown.
                </span>
              </div>
            </ContentBody>
          </ContentSection>
        ) : (
          <>
            {cluster ? (
              <div className={styles.dashboardContainerIndCluster}>
                <DGF
                  key={cluster.name}
                  mode={mode}
                  dashboard={clusterDashboard.dashboard}
                  renderCustomSlices={this.renderCustomSlices}
                />
              </div>
            ) : (
              <div className={styles.dashboardContainerAllClusters}>
                <DGF
                  mode={mode}
                  dashboard={providerClusters.dashboard}
                  renderCustomSlices={this.renderCustomSlices}
                />
              </div>
            )}
          </>
        )}
      </div>
    )
  }
}

export default setupA10Container(ClusterAnalytics)
