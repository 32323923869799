import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10Tooltip,
  A10Row,
  A10Col,
  A10Alert,
} from '@gui-libraries/widgets'
import StatusBar from 'src/components/ADC/StatusBar'
import { AlertFlow } from 'src/containers/Controller/Dashboard/Common/AlertFlow'
import * as analysisUtils from 'src/containers/Controller/Dashboard/utils'
import { httpClient } from 'src/libraries/httpClient'
import { Map } from 'immutable'
import Settings from 'src/containers/Controller/Dashboard/Settings'
import moment from 'moment'
import FilterList from '../FilterList'

import PullUpAlertChart from './PullUpAlertChart'

import './styles/index.scss'
// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

const getSeverity = (severity: number) => {
  switch (String(severity)) {
    case 'critical':
    case '0':
    case '1':
    case '2':
    case '3':
      return (
        <StatusBar
          color="#e57373"
          tip="Top 4 Severity levels are: Emergency, Alert, Critical, and Error"
        />
      )
    case 'moderate':
    case '4':
      return <StatusBar color="#ffb74d" tip="Warning" />
    case '5':
      return <StatusBar color="#81c784" tip="Notification" />
    case 'info':
    case '6':
      return <StatusBar color="#38a8ee" tip="Info" />
    case 'healthy':
    case 'low':
    case '7':
      return <StatusBar color="#ffe168" tip="Debug" />
    default:
      return <StatusBar tip="Unknown" />
  }
}

export interface IPullUpAlertState {
  // chartConfig: IObject
  // chartConfigCount: IObject
  apiFilters: IObject
}
export interface IPullUpAlertProps extends IA10ContainerDefaultProps {
  alert: IObject
  data: IObject
  selectedTimePeriod: IObject
  searchValue: string
  onChangeFilter: (
    filters: IObject[],
    filterStr: string,
    apiFilters?: IObject,
  ) => void
}

class PullUpAlert extends A10Container<IPullUpAlertProps, IPullUpAlertState> {
  static defaultProps = {
    onChangeFilter: _.noop,
  }

  defaultColumns = [
    {
      title: '',
      dataIndex: 'icon',
      width: 30,
      key: 'icon',
      render: (text: string, record: any, index: number) => {
        return getSeverity(Number(record.severity))
      },
    },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
      sorter: (a: any, b: any) => {
        return a.ts - b.ts
      },
      render: (text: any, record: IObject) => {
        return moment(new Date(record.ts)).format('MMM DD, YYYY hh:mm A')
      },
    },
    {
      title: 'Alert Name',
      dataIndex: 'alertName',
      key: 'alertName',
      render: (text: IObject, record: IObject) => {
        const { alert: alert = {} } = record
        return (
          <A10Tooltip title={alert.def_name}>
            <div className={styles.ellipsisTextOverflow}>{alert.def_name}</div>
          </A10Tooltip>
        )
      },
    },
    {
      title: 'Trigger',
      dataIndex: 'trigger',
      key: 'trigger',
      render: (text: IObject, record: IObject) => {
        const { trigger: trigger = {} } = record
        return (
          <A10Tooltip title={trigger.def_name}>
            <div className={styles.ellipsisTextOverflow}>
              {trigger.def_name}
            </div>
          </A10Tooltip>
        )
      },
    },
    {
      title: 'Object',
      dataIndex: 'object',
      key: 'object',
      render: (text: IObject, record: IObject) => {
        const [objStr, objests] = this.getObject(record)
        return (
          <A10Tooltip title={objStr}>
            <div className={styles.ellipsisTextOverflow}>{objests}</div>
          </A10Tooltip>
        )
      },
    },
    {
      title: 'Condition',
      dataIndex: 'condition',
      key: 'condition',
      render: (text: string, record: any, index: number) => {
        const { condition_text } = record
        return (
          <A10Tooltip title={condition_text}>
            <div className={styles.ellipsisTextOverflow}>{condition_text}</div>
          </A10Tooltip>
        )
      },
    },
    {
      title: 'Recorded Value',
      dataIndex: 'recordedValue',
      width: 100,
      key: 'recordedValue',
      render: (t: IObject, record: IObject = {}) => {
        // return `${(record.avg_control_cpu_usage_avg || 0).toFixed(2) || 0} %`

        const text = record.measured_text
        let newText =
          _.isString(text) && _.endsWith(_.trim(text), ',')
            ? text.substring(0, text.length - 1)
            : text
        const displayUnit = _.isArray(record?.Display_unit)
          ? record.Display_unit?.[0]
          : record.Display_unit
        const unit =
          displayUnit === 'percentage'
            ? '%'
            : displayUnit === 'ratio'
            ? ''
            : displayUnit

        const splitNewTextArray = newText.split('=')
        if (splitNewTextArray.length > 1) {
          const tmpText = parseFloat(
            parseFloat(splitNewTextArray.pop()).toFixed(2),
          )
          splitNewTextArray.push(tmpText)
          newText = splitNewTextArray.join('=')
        }
        return (
          <A10Tooltip title={newText}>
            <div
              className={styles.ellipsisTextOverflow}
            >{`${newText}${unit}`}</div>
          </A10Tooltip>
        )
      },
    },
  ]
  constructor(props: any) {
    super(props)
    this.state = {
      // chartConfig: columnConfigSingle,
      // chartConfigCount: {},
      apiFilters: {},
    }
  }
  onChangeFilter = (
    filters: IObject[],
    filterStr: string,
    apiFilters?: IObject,
  ) => {
    const { onChangeFilter } = this.props
    onChangeFilter(filters, filterStr, apiFilters)
    this.setState({ apiFilters })
  }
  onChangeTable = (pager: any = {}, b: any, filters: any = {}) => {
    let sort = 'desc'
    const { order, columnKey } = filters
    if (columnKey === 'timestamp') {
      sort = order === 'ascend' ? 'asc' : 'desc'
    }
    const { current, pageSize } = pager
    this.onChangePagination(current, pageSize, sort)
  }
  /* istanbul ignore next */
  onChangePagination = (page: number, pageSize: number, sort: string) => {
    const { selectedTimePeriod } = this.props
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    httpRequest({
      namespace: Settings.namespace.dashboardAlertPullupInfo,
      request: async () => {
        const alertData = await analysisUtils.analysisAlerts(
          httpClient,
          selectedTimePeriod,
          {
            page,
            pageSize,
            sort,
          },
          this.state.apiFilters,
        )
        return Map(alertData)
      },
    })
  }
  returnWindowType(value: any) {
    if (value === 'timeLength' || value === 'time' || value === 'length') {
      return 'Sliding'
    } else if (value === 'timeBatch' || value === 'lengthBatch') {
      return 'Step'
    } else {
      return 'None'
    }
  }
  renderPullUpDetail = (record: IObject) => {
    const { alert: alertContainer = {} } = this.props
    const { filterMapping = {} } = alertContainer
    const { app_svc_id: appServices = {} } = filterMapping
    const {
      trigger: trigger = {},
      alert: alert = {},
      tragger_info = [],
    } = record
    const { definition: definition = {}, condition = ' ', metadata } = trigger
    const msg = _.get(tragger_info, '[0].msg') || ''
    const {
      object_type: objectType = '',
      trigger_message: triggerMessage = '',
      window = {},
    } = definition
    const { type: windowType = '', params: windowParams = [] } = window
    // const prefixNameList = record.prefixNameList || []
    const [
      prefixName,
      objects,
      clusterName,
      deviceName,
      partitionName,
    ] = this.getObject(record)
    const actions = record.actions || []
    record['metadata'] = metadata
    return (
      <>
        <div className="pull-up-expand">
          <div style={{ margin: '20px auto', width: '80%' }}>
            <AlertFlow
              record={record}
              severity={record.severity}
              objectType={objectType}
              objectInstance={record.prefixName}
              condition={condition}
              recordValue={(record.avg_control_cpu_usage_avg || 0).toFixed(2)}
              action={actions.length > 0 ? actions[0] : {}}
              hasEmail={true}
              hasPost={true}
              clusterName={clusterName}
              deviceName={deviceName}
              partitionName={partitionName}
            />
          </div>
          {msg && _.toLower(objectType) === 'system' && (
            <div
              className="row pull-up-expand-row"
              style={{ padding: '16px 0' }}
            >
              <A10Alert
                message={`{System Message: ${msg}}`}
                type="error"
                showIcon={true}
              />
            </div>
          )}
          <div className="row pull-up-expand-row">
            <div className={'col-md-2 ' + styles.pullUpExpandTitle}>
              Alert Name
            </div>
            <div className="col-md-2">
              <A10Tooltip title={alert.def_name}>
                <div
                  className={styles.pullUpTextOverflow}
                  style={{ maxWidth: 200 }}
                >
                  {alert.def_name}
                </div>
              </A10Tooltip>
            </div>
            <div className={'col-md-2 ' + styles.pullUpExpandTitle}>
              Description
            </div>
            <div className="col-md-6">
              <A10Tooltip title={alert.description}>
                <div
                  className={styles.pullUpTextOverflow}
                  style={{ maxWidth: 300 }}
                >
                  {alert.description}
                </div>
              </A10Tooltip>
            </div>
          </div>
          <hr style={{ marginTop: 0 }} />
          <div className="row pull-up-expand-row">
            <div className={'col-md-2 ' + styles.pullUpExpandTitle}>
              Trigger Name
            </div>
            <div className="col-md-2">
              <A10Tooltip title={trigger.def_name}>
                <div
                  className={styles.pullUpTextOverflow}
                  style={{ maxWidth: 200 }}
                >
                  {trigger.def_name}
                </div>
              </A10Tooltip>
            </div>
            <div className={'col-md-2 ' + styles.pullUpExpandTitle}>
              Object Type
            </div>
            <div className="col-md-2">{objectType}</div>
            <div className={'col-md-2 ' + styles.pullUpExpandTitle}>
              Object Instance
            </div>
            <div className="col-md-2">
              <A10Tooltip title={prefixName}>
                <div className={styles.ellipsisTextOverflow}>{objects}</div>
              </A10Tooltip>
            </div>
          </div>
          <div className="row pull-up-expand-row">
            <div className={'col-md-2 ' + styles.pullUpExpandTitle}>
              App Service Type
            </div>
            <div className="col-md-2">{record.app_svc_type}</div>
            <div className={'col-md-2 ' + styles.pullUpExpandTitle}>
              App Service Name
            </div>
            <div className="col-md-2">
              <A10Tooltip title={appServices[record.app_svc_id]}>
                <div className={styles.ellipsisTextOverflow}>
                  {appServices[record.app_svc_id]}
                </div>
              </A10Tooltip>
            </div>
          </div>
          <div className="row pull-up-expand-row">
            <div className={'col-md-2 ' + styles.pullUpExpandTitle}>
              Window Type
            </div>
            <div className="col-md-2">{this.returnWindowType(windowType)}</div>
            <div className={'col-md-2 col-sm-1 ' + styles.pullUpExpandTitle}>
              Window Size
            </div>
            <div className="col-md-2">{windowParams.join(', ')}</div>
            <div className={'col-md-2 ' + styles.pullUpExpandTitle}>
              Condition
            </div>
            <div className="col-md-2">
              <A10Tooltip title={record.condition_text}>
                <div className={styles.ellipsisTextOverflow}>
                  {record.condition_text}
                </div>
              </A10Tooltip>
            </div>
          </div>
          <div className="row pull-up-expand-row">
            <div className={'col-md-2 ' + styles.pullUpExpandTitle}>
              Trigger Message
            </div>
            <div className="col-md-10">{triggerMessage || 'None'}</div>
          </div>
          <hr style={{ marginTop: 0 }} />
        </div>
        {actions.map((action: IObject, index: number) => {
          const { definition: actionDefinition = {} } = action
          const {
            email: emails = [],
            webhook: webhooks = [],
          } = actionDefinition
          return (
            <div key={index} style={{ marginBottom: '4px' }}>
              {/* <A10Collapse defaultActiveKey={['action1']}>
                <A10Collapse.Panel
                  key={action.def_name}
                  header={
                    <div>
                      <span className={styles.pullUpExpandTitle}>
                        Action Token &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                      <span className="action-item">
                        {action.def_name}
                      </span>
                    </div>
                  }
                > */}
              <div className="row pull-up-expand-row">
                <div className={'col-md-12 ' + styles.pullUpExpandTitle}>
                  <span>Action Taken &nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <span className="action-item" style={{ fontWeight: 300 }}>
                    {action.def_name}
                    {/* Email to Support */}
                  </span>
                </div>
              </div>
              {emails.map((email: IObject) => {
                const { email_ids: emailIds = [] } = email
                return (
                  <>
                    <div className="row pull-up-expand-row">
                      <div className={'col-md-2 ' + styles.pullUpExpandTitle}>
                        <StatusBar isRect={false} color="#7ed321" />
                        Email Alert To
                      </div>
                      <div className="col-md-4">
                        <A10Tooltip title={emailIds.join(', ')}>
                          <div className={styles.ellipsisTextOverflow}>
                            {emailIds.join(', ')}
                          </div>
                        </A10Tooltip>
                      </div>
                      <div className={'col-md-1 ' + styles.pullUpExpandTitle}>
                        Subject
                      </div>
                      <div className="col-md-2">
                        {email.extended_subject || ''}
                      </div>
                      <div className={'col-md-1 ' + styles.pullUpExpandTitle}>
                        Message
                      </div>
                      <div className="col-md-2">
                        {email.extended_message || ''}
                      </div>
                    </div>
                  </>
                )
              })}
              {webhooks.map((webhook: IObject) => {
                return (
                  <>
                    <div className="row pull-up-expand-row">
                      <div className={'col-md-2 ' + styles.pullUpExpandTitle}>
                        <StatusBar isRect={false} color="#7ed321" />
                        POST URI
                      </div>
                      <div className="col-md-4">{webhook.uri}</div>
                      <div className={'col-md-1 ' + styles.pullUpExpandTitle}>
                        BODY
                      </div>
                      <div className="col-md-5">{webhook.extended_body}</div>
                    </div>
                  </>
                )
              })}
              {/*</A10Collapse.Panel>
                </A10Collapse>*/}
            </div>
          )
        })}
      </>
    )
  }

  getObject = (record: IObject) => {
    const { alert: { objMapping = {} } = {} } = this.props
    const device = objMapping[record.device_uuid] || {}
    const deviceName = device.name
    const clusterName = device.cluster
    const partitions = device['partition-list']
    const partitionName =
      record.p_id === 0
        ? 'shared'
        : (
            _.first(
              _.filter(partitions, (partition: IObject) => {
                return partition.id === record.p_id
              }),
            ) || {}
          ).name
    const objectNames = [clusterName, deviceName, partitionName]
    const objects = (
      <span>
        <StatusBar isRect={false} text="C" type="up" />
        {clusterName}
        >>
        <StatusBar isRect={false} text="D" type="up" />
        {deviceName}
        >>
        <StatusBar isRect={false} text="P" type="up" />
        {partitionName}
      </span>
    )
    return [
      objectNames.join(' >> '),
      objects,
      clusterName,
      deviceName,
      partitionName,
    ]
  }

  render() {
    const {
      alert: {
        total = 0,
        alerts = [],
        pager = {},
        filters = {},
        filterMapping,
      },
      searchValue,
    } = this.props
    return (
      <div className="pull-up">
        <A10Row style={{ padding: alert.length > 0 ? '' : '0 8px 8px 8px' }}>
          <PullUpAlertChart />
        </A10Row>
        <A10Row>
          <A10Col span={6}>
            <FilterList
              type="alert"
              filterStr={searchValue}
              data={filters}
              filterMapping={filterMapping}
              onChangeFilter={this.onChangeFilter}
            />
          </A10Col>
          <A10Col span={18}>
            <A10Table
              columns={this.defaultColumns}
              expandRowByClick={true}
              dataSource={alerts.map((alert: IObject, index: number) => {
                alert.key = index
                alert.key = `${alert.timestamp}-${index}`
                return alert
              })}
              scroll={{ x: true }}
              expandedRowRender={this.renderPullUpDetail}
              // expandedRowKeys={['a1']}
              size="small"
              pagination={{
                hideOnSinglePage: true,
                defaultPageSize: pager.pageSize || 20,
                current: pager.page || 1,
                // onChange: this.onChangePagination,
                total,
              }}
              onChange={this.onChangeTable}
              loading={false}
            />
          </A10Col>
        </A10Row>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    alert: state.A10Data.getIn(
      Settings.namespace.dashboardAlertPullupInfo,
      Map({}),
    ).toJS(),
    data: state.A10Data.getIn(Settings.namespace.stackChartData, Map({})),
    selectedTimePeriod: state.A10Data.getIn(
      Settings.namespace.selectedDashboardTimeline,
      Map(Settings.rangePeriod),
    ).toJS(),
  }
}
export default setupA10Container(PullUpAlert, mapStateToProps)
