import React, { MouseEvent } from 'react'
import { _ } from '@gui-libraries/framework'
import {
  A10DropdownMenu,
  A10Col,
  A10Icon,
  A10Status,
} from '@gui-libraries/widgets'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { AboutAppInfoForm } from 'src/containers/Controller/Apps/AppCatalogs/Forms/AboutAppInfoForm'

import styles from './styles/index.module.less'
import { APPLICATION_NAMES_MAP } from 'src/constants/ApplicationConfigs/ApplicationNamesMap'
import parameters from 'parameters'

export interface IAppProps {
  app: IObject
  menuItems?: JSX.Element[]
  actionButton?: JSX.Element
  isLoading?: boolean
  span?: number
  showLicense?: boolean
  onMouseEnter?: (e: MouseEvent<HTMLDivElement>) => void
  onMouseLeave?: (e: MouseEvent<HTMLDivElement>) => void
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
  onClickDropdownMenu?: (component: JSX.Element) => void
}
export interface IAppState {
  showDropdown: boolean
  showSlidingPage: boolean
}

class App extends React.Component<IAppProps, IAppState> {
  state = {
    showDropdown: false,
    showSlidingPage: false,
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({
      showSlidingPage: isOpen,
    })
  }

  handleMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
    const { onMouseEnter } = this.props

    this.setState({
      showDropdown: true,
    })

    if (_.isFunction(onMouseEnter)) {
      onMouseEnter(e)
    }
  }

  handleMouseLeave = (e: MouseEvent<HTMLDivElement>) => {
    const { onMouseLeave } = this.props

    this.setState({
      showDropdown: false,
    })

    if (_.isFunction(onMouseLeave)) {
      onMouseLeave(e)
    }
  }

  handleClickDropdownMenu = (component: JSX.Element, index: number) => {
    const { onClickDropdownMenu } = this.props

    if (_.isFunction(onClickDropdownMenu)) {
      onClickDropdownMenu(component, index)
    }

    if (component.key === 'about') {
      this.setSlidingPage(true)
    }
  }

  renderIcon = (app: string) => {
    let type = ''

    switch (app) {
      case 'adc':
      case 'slb': {
        type = 'adc-icon-active'
        break
      }
      case 'lightning': {
        type = 'lightning-adc-icon-active'
        break
      }
      case 'cgn': {
        type = 'cgn-icon-active'
        break
      }
      case 'gifw': {
        type = 'gi-firewall-icon-active'
        break
      }
      case 'gtp':
      case 'gtp-fw': {
        type = 'gtp-icon-active'
        break
      }
      case 'ssli': {
        type = 'ss-li-icon-active'
        break
      }
      default: {
        type = 'adc-icon-active'
        break
      }
    }

    return <A10Icon app="app-icons" type={type} />
  }

  render() {
    const {
      onClick,
      app = {},
      menuItems = [],
      span = 8,
      showLicense = false,
    } = this.props
    const { showDropdown, showSlidingPage } = this.state
    const { name, version } = app
    const displayName = APPLICATION_NAMES_MAP[name]?.displayName || app.name
    const displayedVersion = version ? version.split('-')[1] : ''

    const _menuItems = [...menuItems]
    if (_menuItems.length > 0) {
      _menuItems.push(<hr />)
    }
    _menuItems.push(<div key="about">About</div>)

    return (
      <>
        <A10Col span={span}>
          <div
            className={`${styles.container}`}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            {showLicense && app.licensed && (
              <div className={styles.appRibbon}>
                <div className={styles.appRibbonTop}></div>
                <span className={styles.appRibbonText}>
                  <A10Status color="colorGreen" />
                  Licensed
                </span>
              </div>
            )}

            <div className={styles.iconContainer}>{this.renderIcon(name)}</div>
            <div className={styles.detailsContainer}>
              <div className={styles.appTitle}>
                {showDropdown && (
                  <A10DropdownMenu
                    menu={_menuItems}
                    title=""
                    onClick={this.handleClickDropdownMenu}
                    trigger="hover"
                    style={{ marginBottom: '45px' }}
                    placement="bottomRight"
                    arrowPointAtCenter={true}
                  />
                )}
                <span
                  className={`${styles.appTitle} ${
                    onClick ? styles.pointerCursor : ''
                  }`}
                  onClick={onClick}
                >
                  {displayName}
                </span>
                <span className={styles.appVersion}>{displayedVersion}</span>
              </div>
              <div className={styles.appDesc}>
                <p>{APPLICATION_NAMES_MAP[name]?.shortDesc}</p>
              </div>
              {showLicense && !app.licensed && (
                <div className={styles.learnMore}>
                  <a
                    className="link"
                    href={`${parameters.A10_URL}/${APPLICATION_NAMES_MAP[name]?.learnMore}`}
                    target="_blank"
                  >
                    Learn More
                    <span>
                      <A10Icon
                        app="global"
                        type="breadcrumb"
                        style={{
                          color: '#268aff',
                          fontSize: '12px',
                          strokeWidth: 5,
                          borderRadius: '5px',
                        }}
                      ></A10Icon>
                    </span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </A10Col>
        <FormatSlidingPage
          isOpen={showSlidingPage}
          title={
            <div className={styles.slidingPageTitleContainer}>
              {this.renderIcon(name)}
              <span>{`${APPLICATION_NAMES_MAP[name]?.displayName ||
                name.toUpperCase()}`}</span>
              <span className={styles.appVersion}>{`${displayedVersion}`}</span>
            </div>
          }
          onRequestClose={this.setSlidingPage.bind(this, false)}
          onRequestOk={this.setSlidingPage.bind(this, false)}
          saveText="Done"
          isShowFooterButtons={false}
          disableSave={true}
          isRightCancel={true}
        >
          <AboutAppInfoForm app={{ ...app, displayName, name }} />
        </FormatSlidingPage>
      </>
    )
  }
}

export default App
