import React from 'react'
import parameters from 'parameters'
import { setupA10Container } from '@gui-libraries/framework'
import {
  A10Table,
  A10DropdownMenu,
  A10Collapse,
  A10Tooltip,
  A10Button,
  A10Badge,
  A10Row,
  A10Col,
  A10Icon,
} from '@gui-libraries/widgets'
import { AppServiceGroupForm } from '@gui-libraries/apps'

import { DashboardService } from 'src/services/DashboardService'
import { IDefaultMethods } from 'src/containers/Controller'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { Utilities } from 'src/services/Utilities/Utilities'
import storage from 'src/libraries/storage'
import { LaunchAppForm } from 'src/containers/Controller/Apps/InstalledApps/Forms/LaunchAppForm'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import {
  AppServiceGroupAssignment,
  IAppServiceGroupAssignmentProps,
  IAppServiceGroupAssignmentState,
} from '../AppServiceGroupAssignment'

import '../styles/appservices.scss'
import styles from '../styles/index.module.less'

export interface ISSLiServicesProps extends IAppServiceGroupAssignmentProps {
  defaultMethods: IDefaultMethods
  device: any
  appServices: any
  hcApps: any
}

export interface ISSLiServicesState extends IAppServiceGroupAssignmentState {
  appServicesReloaded: boolean
  showTable: boolean
  appServices: any
  indexes: any
  selectedIndex: number
  showSlidingPage: boolean
  appData: any
  appServicesLength: number
}

class SSLiServices extends AppServiceGroupAssignment<
  ISSLiServicesProps,
  ISSLiServicesState
> {
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  state = {
    datasourceToAppServiceGroup: [] as string[],
    appServicesReloaded: false,
    showTable: true,
    appServices: [[]],
    indexes: [],
    selectedIndex: 0,
    showSlidingPage: false,
    appData: {
      tenant: '',
      appService: '',
      app: {},
    },
    appServicesLength: 0,
    selectedAppService: '',
  } as ISSLiServicesState
  showTable = true
  pageLength = 5
  isOperatorUser = storage.get.IS_OPERATOR_USER
  appServicesColumns = [
    {
      title: <>Policy</>,
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any, index: number) => {
        const associationState =
          record && record.associationState
            ? record.associationState.toLowerCase()
            : ''
        const color =
          record.licenseErrorType == 'WARNING'
            ? 'warning'
            : record.licenseErrorType == 'ERROR'
            ? 'stopped'
            : 'info'
        return (
          <span>
            <HealthStatus
              type={associationState === 'activated' ? color : 'undefined'}
              text="A"
              hideTooltip={associationState === 'activated' && color === 'info'}
              tooltip={
                associationState === 'activated'
                  ? color !== 'info'
                    ? record.licenseDescription
                    : record.displayName || text
                  : 'Inactive'
              }
            />
            &nbsp;
            {record.name}
          </span>
        )
      },
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
    },
    {
      title: <>Logical Partition</>,
      dataIndex: 'lp_name',
      key: 'lp_name',
      className: 'td-truncate',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'lp_name'),
      render: (text: any, record: any) => {
        return <A10Tooltip title={text}>{text}</A10Tooltip>
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text: IObject, record: IObject, index: number) => {
        const ssliApps = this.props.hcApps.filter((app: any) => {
          return app.name === 'ssli'
        })
        let menuItems
        if (ssliApps.length) {
          menuItems = ssliApps.map((obj: any) => {
            // allowing app launch irrespective of active or inactive app svc --- HARMONY-18996
            return (
              <div onClick={() => this.checkAppLicenseAndLaunch(obj, text)}>
                {'Launch In App(' + obj.version + ')'}
              </div>
            )
          })
        } else {
          menuItems = [<div className="disabled">No apps available</div>]
        }
        return (
          <div className="table-dropdown">
            <A10DropdownMenu
              menu={menuItems}
              title=""
              trigger="hover"
              placement="bottomRight"
              arrowPointAtCenter={true}
            />
          </div>
        )
      },
    },
  ]
  format = (appService: any) => {
    if (!appService.cspClusterNames || appService.cspClusterNames[0] === null) {
      appService.clusterName = 'No Cluster Associted'
    } else {
      appService.clusterName = appService.cspClusterNames[0]
    }
    if (appService.associationState === 'NotAssociated') {
      appService.associationState = 'No Cluster Associted'
    }
  }

  componentWillReceiveProps(props: any) {
    let receivedProps = [...props.appServices]
    let [appServices, indexes] = this.DashboardService.getPaginatedItems(
      receivedProps,
      this.pageLength,
    )
    this.setState({
      appServicesReloaded: true,
      appServices: appServices,
      showTable: true,
      indexes: indexes,
      selectedIndex: 0,
    })
  }
  setData = () => {
    let receivedProps = [...this.props.appServices]
    let [appServices, indexes] = this.DashboardService.getPaginatedItems(
      receivedProps,
      this.pageLength,
    )
    this.setState({
      appServicesReloaded: true,
      appServices: appServices,
      showTable: true,
      indexes: indexes,
    })
  }
  toggleServicesTable = () => {
    let showTable = this.state.showTable
    this.setState({
      showTable: !showTable,
    })
  }
  updateSelectedIndex = (index: any) => {
    this.setState({
      selectedIndex: index,
    })
  }

  componentDidMount() {
    if (!this.state.appServicesReloaded) {
      this.setData()
    }
  }
  componentDidUpdate() {
    if (!this.state.appServicesReloaded) {
      this.setData()
    }

    const { appServices = [] } = this.props

    if (appServices && appServices.length !== this.state.appServicesLength) {
      this.setState({ appServicesLength: appServices.length })
    }
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({
      showSlidingPage: isOpen,
    })
  }

  handleChange = (data: any) => {
    // @ts-ignore
    this.setState({
      appData: data,
    })
  }

  checkAppLicenseAndLaunch = (app: any, appSvc: any) => {

    if (!app) {
      this.Utilities.launchApp(appSvc)
    } else {
      this.Utilities.launchAppWithSvc(app, appSvc)
    }
  }

  appServiceNameListToLaunch = (app: any) => {
    const appData = {
      tenant: '',
      appService: '',
      app,
    }
    this.setState({
      showSlidingPage: false,
      appData: appData,
    })
    this.launchAppURLPage(appData)
  }

  launchAppURLPage = async (appObjData: any) => {
    const {
      get: { PROVIDER, CURRENT_TENANT, SESSION_TIMEOUT_INITIAL, USER_ID },
    } = storage

    const providerName = PROVIDER
    let tenantObj = appObjData.tenant
      ? JSON.parse(appObjData.tenant)
      : CURRENT_TENANT
    let appSvcObj = appObjData.appService
      ? JSON.parse(appObjData.appService)
      : ''

    const sessionTimeout = SESSION_TIMEOUT_INITIAL
    const currTimestamp = new Date().getTime()
    const ipv6Url = this.Utilities.checkIPv6BaseURL()
    const baseURL = ipv6Url
      ? parameters.BASE_URL.replace(':8443/api/v2', '') + encodeURI('/hcapps')
      : encodeURI(parameters.BASE_URL.replace(':8443/api/v2', '') + '/hcapps')
    let sessionId = await this.Utilities.getSessionId()
    const queryString =
      '?user_id=' +
      USER_ID +
      '&api_ep=' +
      encodeURI(parameters.BASE_URL) +
      '&provider=' +
      providerName +
      '&token=' +
      sessionId +
      '&timeout=' +
      (sessionTimeout ? sessionTimeout : '1') +
      (tenantObj.name ? '&tenant=' + tenantObj.name : '') +
      (tenantObj.uuid ? '&tenantUUID=' + tenantObj.uuid : '') +
      (appSvcObj.app_svc_id
        ? '&app_svc=' +
          appSvcObj.name +
          '&app_service_id=' +
          appSvcObj.app_svc_id +
          '&app_svc_type=' +
          appSvcObj['app_svc_type'] +
          '&action=config'
        : '')

    const href = `${baseURL}/${appObjData.app.name}_${appObjData.app.version}${queryString}&ts=${currTimestamp}`

    window.open(href, '_blank')

    this.setState({
      showSlidingPage: false,
    })
  }

  handleOk = (ssliApps: any) => {
    if (!this.state.appData.appService) {
      this.Utilities.showMessage('SELECT_APPSERVICE', 0, 1)
      return false
    }

    this.launchAppURLPage(this.state.appData)
    this.setState({
      showSlidingPage: false,
    })
  }

  render() {
    const ssliApps = this.props.hcApps.filter((app: any) => {
      return app.name === 'ssli'
    })

    ssliApps.sort((a: any, b: any) => {
      return this.Utilities.sortAppVersionNumber(
        a?.version?.split('-')[0]?.split('.') || [0],
        b?.version?.split('-')[0]?.split('.') || [0],
      )
    })
    ssliApps.sort((a: any, b: any) => {
      return this.Utilities.sortAppVersionNumber(
        a?.version?.split('v')[1]?.split('.') || [0],
        b?.version?.split('v')[1]?.split('.') || [0],
      )
    })

    const {
      appServiceGroupList,
      appServicesMap,
      usableAppServiceOptions,
    } = this.props

    const { appServiceGroupToBeUpdated, appServicesLength } = this.state

    return (
      <>
        <A10Collapse
          bordered={true}
          defaultActiveKey={this.props.appServices?.length? ['1'] : [null]}
          className="marginTop20 marginBottom15"
        >
          <A10Collapse.Panel
            header={
              <div onClick={this.toggleServicesTable}>
                <A10Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className={styles.appServicesTitleContainer}
                >
                  <A10Col>
                    <A10Icon app="app-icons" type="ss-li-icon-active" />
                    <span className={styles.appServicesTitle}>SSLi</span>
                    <A10Tooltip
                      title="SSLi App Svcs are listed and managed by the SSLi App."
                      placement="right"
                    >
                      <A10Icon
                        app="global"
                        type="information"
                        style={{ width: '14px', height: '14px' }}
                      />
                    </A10Tooltip>
                    <A10Badge count={appServicesLength} overflowCount={9999} className={styles.badgeHeight} />
                  </A10Col>
                  <A10Col>
                    <A10Button
                      type="link"
                      onClick={() =>
                        this.appServiceNameListToLaunch(ssliApps[0])
                      }
                      disabled={
                        ssliApps.length === 0 ||
                        this.props.appServices.length === 0
                      }
                    >
                      Launch App
                    </A10Button>
                  </A10Col>
                </A10Row>
              </div>
            }
            key="1"
            className={styles.collapse}
          >
            <A10Table
              className={`${styles.appserviceslist}`}
              columns={this.appServicesColumns}
              dataSource={this.props.appServices.map(
                (key: any, index: number) => {
                  key.key = index
                  if (!key.clusterName) {
                    this.format(key)
                    return key
                  } else {
                    return key
                  }
                },
              )}
              size="small"
              pagination={{ hideOnSinglePage: true, pageSize: 20 }}
            />
          </A10Collapse.Panel>
        </A10Collapse>

        <FormatSlidingPage
          isOpen={this.state.showSlidingPage}
          title={'List of SSLi App Services'}
          description={'Please select an App service'}
          onRequestClose={this.setSlidingPage.bind(this, false)}
          saveText="Proceed"
          onRequestOk={() => this.handleOk(ssliApps)}
        >
          <LaunchAppForm
            launchAppObj={this.state.appData}
            handleChange={this.handleChange}
            ssliAppLaunch={true}
          />
        </FormatSlidingPage>
        <FormatSlidingPage
          isOpen={this.state.isFormOpen}
          title={'Assign to an App Service Group'}
          description={`Assign "${this.state.selectedAppService}" to an App Service Label`}
          onRequestClose={() => {
            this.setState({
              isFormOpen: false,
              appServiceGroupToBeUpdated: {},
            })
          }}
          saveText="Save"
          onRequestOk={this.onAssignAppServiceGroupRequestOk}
        >
          <AppServiceGroupForm
            appServiceGroupList={appServiceGroupList}
            appServiceGroup={appServiceGroupToBeUpdated}
            onSetAppServiceGroup={(appServiceGroup: IObject) => {
              this.setState({
                appServiceGroupToBeUpdated: {
                  ...appServiceGroup,
                  appServices: [...appServiceGroup.appServices],
                },
              })
            }}
            appServices={usableAppServiceOptions}
            appServicesMap={appServicesMap}
          />
        </FormatSlidingPage>
      </>
    )
  }
}

export default setupA10Container(SSLiServices)
