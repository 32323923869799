import { UrlService, AjaxService } from 'src/services'

export class TroubleShootService {
  UrlService = new UrlService()
  AjaxService = new AjaxService()

  getRunCommandHistoryCounts = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_CMD_HISTORY_COUNTS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  listCmdSnippetHistory = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('LIST_CMD_SNIPPET_HISTORY')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  listCliSnippets = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_CLI_SNIPPETS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  saveCliSnippet = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('POST_CLI_SNIPPET')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  updateCliSnippet = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('PUT_CLI_SNIPPET')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  deleteCliSnippet = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_CLI_SNIPPET')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  runCliSnippet = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('RUN_CLI_SNIPPET')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  listScheduleTasks = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_SCHEDULE_TASKS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  createScheduleTasks = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('POST_SCHEDULE_TASKS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  updateScheduleTasks = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('PUT_SCHEDULE_TASKS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  deleteScheduleTasks = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_SCHEDULE_TASKS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  listScheduleJobs = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_SCHEDULE_JOBS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getScheduleJobInfo = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_SCHEDULE_JOB_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  devicePartitionAxApiGetCall = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DEVICE_PARTITION_DEPLOY_AXAPI')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getDeviceMgmtScheduledJobsForState = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get(
      'GET_DEVICE_MGMT_SCHEDULED_JOBS_FOR_STATE',
    )
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getExecutedUpgrades = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_EXECUTED_UPGRADES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  deleteScheduledJobs = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_SCHEDULED_JOBS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  loopParseJson = (data: any) => {
    const objKeys = Object.keys(data)
    let stringArr: string[] = []
    objKeys.map((objKey: string) => {
      if (Array.isArray(data[objKey])) {
        // stringArr.push(`${objKey}::`)
        stringArr = stringArr.concat(data[objKey])
      } else if (typeof data[objKey] === 'object') {
        // stringArr.push(`${objKey}::`)
        stringArr = stringArr.concat(this.loopParseJson(data[objKey]))
      } else {
        const lineTitle = objKey[0].toUpperCase() + objKey.slice(1)
        if (objKey === 'execution-time') {
          stringArr.push(`Took: ${parseFloat(data[objKey]) / 1000}sec`)
        } else {
          stringArr.push(`${lineTitle}: ${data[objKey]}`)
        }
      }
    })
    return stringArr
  }

  parseTroubleShootOutput = (devCmdOtput: any) => {
    const cmdOutputArr = this.loopParseJson(devCmdOtput)

    return cmdOutputArr
  }

  padZero = (num: number, size: number) => {
    var s = num + ''
    if (s.length < size) s = '0' + s
    return s
  }

  convertToLocalDateTime = (dateTime: string) => {
    const dateTimeArr = dateTime.split('-')
    const timeArr = dateTimeArr[3].split(':')
    const utcDateTime = new Date()
    utcDateTime.setUTCFullYear(parseInt(dateTimeArr[0]))
    utcDateTime.setUTCMonth(parseInt(dateTimeArr[1]) - 1)
    utcDateTime.setUTCDate(parseInt(dateTimeArr[2]))

    utcDateTime.setUTCHours(parseInt(timeArr[0]))
    utcDateTime.setUTCMinutes(parseInt(timeArr[1]))
    utcDateTime.setUTCSeconds(parseInt(timeArr[2]))

    const convertedDateTime =
      utcDateTime.getFullYear() +
      '-' +
      this.padZero(utcDateTime.getMonth() + 1, 2) +
      '-' +
      this.padZero(utcDateTime.getDate(), 2) +
      '-' +
      this.padZero(utcDateTime.getHours(), 2) +
      ':' +
      this.padZero(utcDateTime.getMinutes(), 2) +
      ':' +
      this.padZero(utcDateTime.getSeconds(), 2)

    return convertedDateTime
  }

  convertDateTime = (dateTime: string) => {
    const dateTimeArr = dateTime.split('-')
    const timeArr = dateTimeArr[3].split(':')
    return (
      dateTimeArr[0] +
      '/' +
      dateTimeArr[1] +
      '/' +
      dateTimeArr[2] +
      ' ' +
      timeArr[0] +
      ':' +
      timeArr[1] +
      ':' +
      timeArr[2]
    )
  }
  getMicroServiceLogsData = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('GET_MICRO_SERVICE_LOGS')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  postMicroServiceLogsDownload = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('POST_MICRO_SERVICE_LOGS_DOWNLOAD')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getMicroServiceLogsDownload = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('GET_MICRO_SERVICE_LOGS_DOWNLOAD')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getRunTargets = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('GET_RUN_TARGETS')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getRunCommands = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('GET_RUN_COMMANDS')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  postRunCommands = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('POST_RUN_COMMAND')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getRunCommandStatus = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('GET_RUN_COMMAND_STATUS')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getBackups = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('GET_REMAINING_BACKUPS')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getLicenseEntitlements = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('GET_LICENSE_ENTITLEMENTS')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
}

export default TroubleShootService
