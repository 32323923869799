import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Row, A10Col } from '@gui-libraries/widgets'
import { LMLicenseUsageCharts } from '../LMLicenseUsageCharts'
import { ApplicationConfigs } from 'src/constants/ApplicationConfigs/index'
import { DashboardService, Utilities } from 'src/services'
const styles = require('./styles/index.module.less')
const moment = require('moment')

export interface ILMLicenseActivationDetailsProps
  extends IA10ContainerDefaultProps {
  isUpdateActivations: boolean
  activation: IObject
  licenseData: IObject
}
export interface ILMLicenseActivationDetailsState {
  activationData: IObject
  activationUsage: any[]
}

class LMLicenseActivationDetails extends A10Container<
  ILMLicenseActivationDetailsProps,
  ILMLicenseActivationDetailsState
> {
  ApplicationConfigs = new ApplicationConfigs()
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  constructor(props: ILMLicenseActivationDetailsProps) {
    super(props)
    this.state = {
      activationData: {},
      activationUsage: [],
    }
  }

  getLicenseActivationInfo = async () => {
    await this.DashboardService.getLLMLicenseActivationInfo(null, null, [
      this.props.activation['license_id'],
      this.props.activation.id,
    ])
      .then((resp: any) => {
        const activationData = resp?.data ? resp.data : {}
        this.setState({ activationData })
      })
      .catch((err: any) => {
        console.log('error in getting license activations')
      })
  }

  getActivationUsage = async () => {
    await this.DashboardService.getLLMLicenseActivationUsage(null, null, [
      this.props.activation.id,
    ])
      .then((resp: any) => {
        const activationUsage = resp?.data || []
        this.setState({ activationUsage })
      })
      .catch((err: any) => {
        console.log('error in getting activation usage')
      })
  }

  componentDidMount() {
    this.getLicenseActivationInfo()
    this.getActivationUsage()
  }

  shouldComponentUpdate = (nextProps: any, nextState: any) => {
    if (nextProps.isUpdateActivations !== this.props.isUpdateActivations) {
      this.getLicenseActivationInfo()
      this.getActivationUsage()
    }
    return true
  }

  render() {
    const { activationData, activationUsage } = this.state
    const { licenseData } = this.props

    const allotedBW =
      licenseData['license-type'].indexOf('INSTANCE_FLEXPOOL') > -1
        ? activationData['bandwidth']
        : activationData['allocated_bandwidth']
    const allotedBandwidth = this.Utilities.megaToGiga(
      allotedBW ? Math.abs(allotedBW) : 0,
    )
    const allotedValue =
      (allotedBW < 0 ? '-' : '') +
      (allotedBandwidth.val + (allotedBandwidth.unit === 'm' ? 'Mbps' : 'Gbps'))
    const createDate = activationData['created_at']
      ? moment
          .utc(activationData['created_at'])
          .local()
          .format('MMM DD, YYYY hh:mm A')
      : ''
    const lastUpdate = activationData['updated_at']
      ? moment
          .utc(activationData['updated_at'])
          .local()
          .format('MMM DD, YYYY hh:mm A')
      : ''
    const lastContact = activationData['last_contacted_at']
      ? moment
          .utc(activationData['last_contacted_at'])
          .local()
          .format('MMM DD, YYYY hh:mm A')
      : ''

    return (
      <A10Row type="flex" className={`no-margin font-14 ${styles.licDetails}`}>
        <div className={`margin-h-15 pad-v-15 ${styles.licContent}`}>
          <div className={styles.licTitle}>
            {licenseData['license-type'].indexOf('FLEXPOOL') > -1
              ? 'Device'
              : 'Provider'}
          </div>
          <div className="pad-s-15">{activationData.appliance_name}</div>
        </div>
        <div className={`margin-h-15 pad-v-15 ${styles.licContent}`}>
          <div className={styles.licTitle}>UUID</div>
          <div className="pad-s-15">{activationData.appliance_uuid}</div>
        </div>
        {licenseData['license-type'].indexOf('FLEXPOOL') > -1 ? (
          <>
            <div className={`margin-h-15 pad-v-15 ${styles.licContent}`}>
              <div className={styles.licTitle}>MAC</div>
              <div className="pad-s-15">{activationData.mac}</div>
            </div>
            <div className={`margin-h-15 pad-v-15 ${styles.licContent}`}>
              <div className={styles.licTitle}>Version</div>
              <div className="pad-s-15">{activationData.build_version}</div>
            </div>
            <div className={`margin-h-15 pad-v-15 ${styles.licContent}`}>
              <div className={styles.licTitle}>Virtualization Platform</div>
              <div className="pad-s-15">{activationData.platform}</div>
            </div>
            <div className={`margin-h-15 pad-v-15 ${styles.licContent}`}>
              <div className={styles.licTitle}>Hostname</div>
              <div className="pad-s-15">{activationData.hostname}</div>
            </div>
          </>
        ) : null}
        <div className={`margin-h-15 pad-v-15 ${styles.licContent}`}>
          <div className={styles.licTitle}>Bandwidth</div>
          <div className="pad-s-15">{allotedValue}</div>
        </div>
        <div className={`margin-h-15 pad-v-15 ${styles.licContent}`}>
          <div className={styles.licTitle}>Activated On</div>
          <div className="pad-s-15">{createDate}</div>
        </div>
        <div className={`margin-h-15 pad-v-15 ${styles.licContent}`}>
          <div className={styles.licTitle}>Last Updated On</div>
          <div className="pad-s-15">{lastUpdate}</div>
        </div>
        <div className={`margin-h-15 pad-v-15 ${styles.licContent}`}>
          <div className={styles.licTitle}>Last Synced On</div>
          <div className="pad-s-15">{lastContact}</div>
        </div>
        {licenseData['license-type'].indexOf('FLEXPOOL') > -1 ? (
          <A10Col span={24} className={styles.licUsageDiv}>
            <LMLicenseUsageCharts licenseUsage={activationUsage} />
          </A10Col>
        ) : null}
      </A10Row>
    )
  }
}

export default setupA10Container(LMLicenseActivationDetails)
