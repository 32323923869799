import React, { useState, useEffect } from 'react'

import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { A10Input, A10Form, A10Icon } from '@gui-libraries/widgets'
import {
  IA10ContainerDefaultProps,
  setupA10Container,
} from '@gui-libraries/framework'
import A10Panel from 'src/components/ADC/A10Panel'
import Messages from 'src/locale/en/Messages'
import '../styles/index.less'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import _ from 'lodash'

const messages = new Messages()

interface IAPIConfigProps extends IA10ContainerDefaultProps {
  show: boolean
  title: string
  timeout: string
  onClose: () => void
  onActionCompleted: (value: string) => void
  onRef?: (ref: any) => void
  form: any
}

const APIConfigurationEditForm: React.FC<IAPIConfigProps> = ({
  timeout,
  show,
  title,
  form,
  onRef,
  onClose,
  onActionCompleted,
}) => {
  const [apiInactivityTimeout, setApiInactivityTimeout] = useState(timeout)

  const { getFieldDecorator } = form

  const onHandleSave = () => {
    form?.validateFieldsAndScroll({ force: true }, (err: any) => {
      if (!err) {
        onActionCompleted(apiInactivityTimeout)
      }
    })
  }

  useEffect(() => {
    if (_.isFunction(onRef)) {
      onRef(this)
    }
    setApiInactivityTimeout(timeout)
  }, [timeout])

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  return (
    <FormatSlidingPage
      isOpen={show}
      onRequestOk={onHandleSave}
      onRequestClose={onClose}
      isRightCancel
      title={title}
    >
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel showHeader={false}>
          <A10Form.Item
            className="mb-0"
            {...formItemLayout}
            label={
              <>
                <span style={{ fontWeight: 400 }} className="mandatoryField">
                  API Inactivity Timeout
                </span>
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="left"
                    title={messages.INACTIVITY_TIMEOUT}
                    helpKey="HELP_MS_API_SESSTINGS"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('api.session.timeout.secs', {
              rules: [
                { required: true, message: messages.EMPTY_INACTIVITY_TIMEOUT },
              ],

              initialValue: apiInactivityTimeout,
            })(
              <A10Input
                addonAfter="Sec"
                type="text"
                className="input-component"
                onChange={event => {
                  setApiInactivityTimeout(event?.target?.value)
                }}
              />,
            )}
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    </FormatSlidingPage>
  )
}

export default setupA10Container(A10Form.create()(APIConfigurationEditForm))
