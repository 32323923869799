import React from 'react'
import { A10Component } from '@gui-libraries/framework'
import { A10Tooltip } from '@gui-libraries/widgets'

import './styles/statusicon.scss'

export interface IStatusInfo {
  className?: string
  background?: string
  borderWidth?: number
  borderColor?: string
  tooltip?: string
}

export interface IStatusIconProps {
  status?: string
  innerText?: string
  size?: number
  statusMap?: { [key: string]: IStatusInfo }
}

export interface IStatusIconStates {}

class StatusIcon extends A10Component<IStatusIconProps, IStatusIconStates> {
  static defaultProps = {
    status: 'DEFAULT',
    innerText: '',
    size: 16,
    statusMap: {
      COMPLETED: {
        background: '#81c784',
      },
      SUCCESS: {
        background: '#81c784',
      },
      FAILED: {
        background: '#e57373',
      },
      NEW: {
        className: 'in-normal-progress',
      },
      INPROGRESS: {
        className: 'in-normal-progress',
      },
      RUNNING: {
        className: 'in-normal-progress',
      },
      PAUSED: {
        className: 'in-abnormal-progress',
      },
      TERMINATED: {
        background: '#f5a623',
      },
      TIMED_OUT: {
        background: '#ffb74d',
      },
      DEFAULT: {
        background: '#777777',
      },
      // for header
      ERROR: {
        background: '#e57373',
        borderWidth: 1,
        borderColor: '#cdcdcd',
      },
      INFO: {
        background: '#64b5f6',
      },
      WARNING: {
        background: '#ffb74d',
        borderWidth: 1,
        borderColor: '#cdcdcd',
      },
    },
  }
  constructor(props: IStatusIconProps) {
    super(props)
    this.state = {}
  }

  render() {
    const { status, innerText, size, statusMap } = this.props
    const { className, background, borderWidth, borderColor, tooltip } =
      statusMap[status] || statusMap.DEFAULT
    const tooltipProps = {
      title: tooltip,
      arrowPointAtCenter: true,
      placement: 'top',
      trigger: 'hover',
    }
    const InnerText = () => {
      return innerText ? <span className="inner-text">{innerText}</span> : null
    }

    return (
      <span
        className={`status-icon status-icon-${size}${
          className ? ` ${className}` : ''
        }`}
        style={{
          background,
          borderWidth: borderWidth + 'px',
          borderColor,
        }}
      >
        {tooltip ? (
          <A10Tooltip {...tooltipProps}>
            <InnerText />
          </A10Tooltip>
        ) : (
          <InnerText />
        )}
      </span>
    )
  }
}

export default StatusIcon
