import React from 'react'

import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import {
  A10Row,
  A10Col,
  A10Icon,
  A10Collapse,
  A10Switch,
  A10Form,
  A10Input,
  A10Select,
} from '@gui-libraries/widgets'
import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { IDefaultMethods } from 'src/containers/Controller'
import { Messages } from 'src/locale/en'
import { DashboardService } from 'src/services/DashboardService'
import { DropdownConstants } from 'src/constants/DropdownConstants/DropdownConstants'
import { Utilities } from 'src/services/Utilities'
import './styles/actionaddform.scss'
import { HelpInfo } from 'src/components/shared/HelpInfo'

export interface IActionAddFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  onRef?: any
  form: any
  actions: any
  formData: any
  isEditMode: boolean
  handleChange?(data: any): void
  oncancel(e?: React.MouseEvent<HTMLInputElement>): void
  onTenantUpdate(): void
}

export interface IActionPostData {
  uri: string
  extended_body: string
  headers: any
  ssc: boolean
}

export interface IActionAddFormState {
  actionName: string
  email: boolean
  emailToProviderAdmins: boolean
  emailToTenantAdmins: boolean
  users: any
  extendedSubject: string
  extendedMsg: string
  post: boolean
  postArr: IActionPostData[]
}

export interface IActionObj {
  actionName: string
}
class ActionAddForm extends A10Container<
  IActionAddFormProps,
  IActionAddFormState
> {
  Messages = new Messages()
  DashboardService = new DashboardService()
  DropdownConstants = new DropdownConstants()
  Utilities = new Utilities()
  actionObj = {} as IActionObj
  MAX_POSTDATA = 5
  constructor(props: IActionAddFormProps) {
    super(props)
    this.state = {
      actionName: this.props.formData.actionName,
      email: this.props.formData.email,
      emailToProviderAdmins: this.props.formData.emailToProviderAdmins,
      emailToTenantAdmins: this.props.formData.emailToTenantAdmins,
      users: this.props.formData.users,
      extendedSubject: this.props.formData.extendedSubject,
      extendedMsg: this.props.formData.extendedMsg,
      post: this.props.formData.post,
      postArr: this.props.formData.postArr,
    }
  }

  addPostObject = () => {
    const actionObj = this.state
    const postArr = actionObj.postArr
    postArr.push({
      uri: '',
      extended_body: '',
      headers: '',
      ssc: false,
    })
    this.setState({
      postArr,
    })
    actionObj.postArr = postArr
    this.onChangeCallback(actionObj)
  }

  handleChange = (stateName: string, e: any, index?: number) => {
    // if (!this.Utilities.validateField(e)) {
    //   return
    // }
    const actionObj = this.state
    if (index !== undefined && index > -1) {
      const valArr = this.state.postArr
      valArr[index][stateName] = e.target ? e.target.value : e
      // @ts-ignore
      this.setState({
        postArr: valArr,
      })
      this.onChangeCallback(actionObj)
      return
    }

    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        actionObj[stateName] = e.target.checked
      } else {
        actionObj[stateName] = e.target.value
      }
    } else {
      actionObj[stateName] = e
    }
    this.onChangeCallback(actionObj)
    // @ts-ignore
    this.setState({
      [stateName]: actionObj[stateName],
    })
  }

  handleUsers = (stateName: string, value: any, index?: number) => {
    if (this.Utilities.validateEmail(value)) {
      this.handleChange(stateName, value)
    }
  }

  handleUserEmailValidation = (rule: any, value: any, cb: any) => {
    return this.Utilities.validateEmail(value) ? cb() : cb(true)
  }

  onChangeCallback(data: any) {
    this.props.handleChange(data)
  }

  process = () => {
    console.log('handle process')
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  validateDuplicateActionName = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const index = this.props.actions.findIndex((key: any) => {
      return key.def_name === value
    })
    const userRegx = new RegExp(/^[A-Za-z]([a-zA-Z0-9_ ]){1,50}$/)
    if ('' === value) {
      rule.message = this.Messages.EMPTY_ACTION_NAME
      isValid = false
    } else if (value && value.length > 50) {
      rule.message = this.Messages.VALIDATION_ACTION_NAME_LENGTH
      isValid = false
    } else if (value && value.length <= 1) {
      rule.message = this.Messages.VALIDATION_ACTION_NAME_MIN_LENGTH
      isValid = false
    } else if (!userRegx.test(value)) {
      rule.message = this.Messages.VALIDATION_ACTION_NAME
      isValid = false
    } else if (index > -1) {
      // if ((this.props.isEditMode && value != this.props.formData.actionName)
      //   || !this.props.isEditMode) {
      //   rule.message = this.Messages.DUPLICATE_ACTION_NAME
      //   isValid = false
      // }
      rule.message = this.Messages.DUPLICATE_ACTION_NAME
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateUsers = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const { email, post, postArr } = this.state
    if (email && post) {
      let postCount = []
      postCount = postArr.filter(obj => obj.uri)
      if (value.length === 0 && postCount.length === 0) {
        rule.message = this.Messages.EMPTY_EMAIL
        isValid = false
      } else if (value.length === 0) {
        rule.message = this.Messages.VALIDATION_EMAIL_CONFIG
        isValid = false
      }
    } else if (email && value.length === 0) {
      rule.message = this.Messages.VALIDATION_EMAIL_CONFIG
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validatePost = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const postRegx = new RegExp(
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    )
    const { email, post, postArr, users } = this.state
    if (email && post) {
      if (users.length === 0 && !value) {
        rule.message = this.Messages.EMPTY_POST_TO_URI
        isValid = false
      } else if (postArr && !postRegx.test(value)) {
        rule.message = this.Messages.INVALID_POST_TO_URI
        isValid = false
      }
    } else if (post && !value) {
      rule.message = this.Messages.EMPTY_POST_TO_URI
      isValid = false
    } else if (post && !postRegx.test(value)) {
      rule.message = this.Messages.INVALID_POST_TO_URI
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateHeaders = (index: any, rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    if ('' === value) {
      isValid = true
    } else if (value && value.length > 0) {
      const valueTemp = value.trim()
      const stringValue = valueTemp.substring(1, valueTemp.length - 1)
      if (!this.Utilities.IsValidJSONString(stringValue)) {
        isValid = false
        rule.message = this.Messages.VALID_JSON
      } else {
        this.handleChange('headers', JSON.parse(stringValue), index)
      }
      // const splitArr = value.split(',')
      // const finalHeaderObj = {}
      // let count = 0
      // splitArr.map((obj: any) => {
      //   const eachPair = obj.split('=')
      //   if (eachPair.length === 2) {
      //     count = count + 1
      //     finalHeaderObj[eachPair[0].trim()] = eachPair[1].trim()
      //   }
      // })
      // if (splitArr.length !== count) {
      //   isValid = false
      //   rule.message = this.Messages.PROPER_HEADERS
      // } else {
      //   this.handleChange('headers', finalHeaderObj, index)
      // }
    }
    return isValid ? cb() : cb(true)
  }

  removePost = (index: number) => {
    const actionObj = this.state
    const postArr = actionObj.postArr
    postArr.splice(index, 1)
    this.setState({
      postArr,
    })
    actionObj.postArr = postArr
    this.onChangeCallback(actionObj)
  }

  render() {
    const { oncancel } = this.props
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }
    const postFormItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 15 },
    }
    const { getFieldDecorator } = this.props.form
    const { renderDropdownOpts } = this.Utilities

    const {
      actionName,
      email,
      extendedSubject,
      extendedMsg,
      post,
      postArr,
      users,
    } = this.state

    const isEmailOpen = actionName != '' ? email : true
    const isPostOpen = actionName != '' ? post : true

    const customPanelStyle = {
      borderRadius: 4,
      marginBottom: '-1.6%',
      border: 0,
      overflow: 'hidden',
    }
    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel
          title={
            <>
              <A10IconTextGroup
                text="Action"
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            </>
          }
        >
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="mandatoryField">
                  {this.Messages.ACTION_NAME}
                </span>
                <span>
                  <HelpInfo
                    customCls="custom-tooltip-input"
                    placement="left"
                    title="Action Name"
                    helpKey="HELP_MONITOR_ACTION_NAME"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('actionName', {
              rules: [
                { validator: this.validateDuplicateActionName.bind(this) },
              ],
              initialValue: actionName,
            })(
              <A10Input
                type="text"
                placeholder={this.Messages.ACTION_NAME}
                onChange={(e: any) => this.handleChange('actionName', e)}
              />,
            )}
          </A10Form.Item>

          <A10Collapse
            bordered={false}
            defaultActiveKey={[isEmailOpen ? '1' : 0]}
          >
            <A10Collapse.Panel
              header={
                <>
                  <A10Switch
                    defaultChecked={email}
                    size="default"
                    id="email"
                    onChange={(e: any) => this.handleChange('email', e)}
                    style={{ left: '0.9%' }}
                  />
                  <label
                    htmlFor="email"
                    className="custom-action-label"
                    style={customPanelStyle}
                  >
                    {
                      <>
                        {this.Messages.EMAIL}
                        <span>
                          <HelpInfo
                            customCls="custom-tooltip-input-indent"
                            placement="right"
                            title={this.Messages.EMAIL}
                            helpKey="HELP_MONITOR_ACTION_EMAIL"
                          />
                        </span>
                      </>
                    }
                  </label>
                </>
              }
              key="1"
              className="no-border"
              style={{ left: '-14%' }}
            >
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    <span className="mandatoryField">
                      {this.Messages.SPECIFY_USERS_MAILS}
                    </span>
                    <span>
                      <HelpInfo
                        customCls="custom-tooltip-input"
                        placement="leftTop"
                        title={this.Messages.SPECIFY_USERS_MAILS}
                        helpKey="HELP_MONITOR_ACTION_SPECIFY_USERS_MAILS"
                      />
                    </span>
                  </>
                }
              >
                {getFieldDecorator('users', {
                  rules: [
                    { validator: this.validateUsers.bind(this) },
                    {
                      validator: this.handleUserEmailValidation,
                      message: this.Messages.INVALID_EMAIL,
                    },
                  ],
                  initialValue: users,
                })(
                  <A10Select
                    mode="tags"
                    notFoundContent=""
                    onChange={(e: any) => this.handleUsers('users', e)}
                  >
                    {renderDropdownOpts('users', 'ACTIONS')}
                  </A10Select>,
                )}
              </A10Form.Item>

              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.EXTENDED_SUBJECT}
                    <span>
                      <HelpInfo
                        customCls="custom-tooltip-input"
                        placement="left"
                        title={this.Messages.EXTENDED_SUBJECT}
                        helpKey="HELP_MONITOR_ACTION_EXTENDED_SUBJECT"
                      />
                    </span>
                  </>
                }
              >
                <A10Input
                  type="text"
                  defaultValue={extendedSubject}
                  onChange={(e: any) => this.handleChange('extendedSubject', e)}
                />
              </A10Form.Item>

              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.EXTENDED_MESSAGE}
                    <span>
                      <HelpInfo
                        customCls="custom-tooltip-input"
                        placement="left"
                        title="Extended Message"
                        helpKey="HELP_MONITOR_ACTION_EXTENDED_MESSAGE"
                      />
                    </span>
                  </>
                }
              >
                <A10Input.TextArea
                  type="text"
                  defaultValue={extendedMsg}
                  onChange={(e: any) => this.handleChange('extendedMsg', e)}
                />
              </A10Form.Item>
            </A10Collapse.Panel>
          </A10Collapse>
          <A10Collapse
            bordered={false}
            defaultActiveKey={[isPostOpen ? '1' : 0]}
          >
            <A10Collapse.Panel
              header={
                <>
                  <A10Switch
                    defaultChecked={post}
                    size="default"
                    id="post"
                    onChange={(e: any) => this.handleChange('post', e)}
                    style={{ left: '0.9%' }}
                  />
                  <label
                    htmlFor="post"
                    className="custom-action-label"
                    style={customPanelStyle}
                  >
                    {<>{this.Messages.POST}</>}
                  </label>
                </>
              }
              key="1"
              className="no-border"
            >
              {postArr.map((postData: IActionPostData, index: number) => {
                return (
                  <div className="post-url-container" key={'post-' + index}>
                    <A10Panel
                      key={'post_panel'}
                      title={
                        <A10IconTextGroup
                          text={
                            <>
                              {'POST to server ' + (index + 1)}
                              <span>
                                <HelpInfo
                                  customCls="custom-tooltip-head-indent"
                                  placement="right"
                                  title={'Server ' + (index + 1)}
                                  helpKey="HELP_MONITOR_ACTION_SERVER"
                                />
                              </span>
                            </>
                          }
                          icon={
                            <A10Icon
                              style={{
                                width: 22,
                                height: 22,
                                marginRight: 12,
                              }}
                              app="global"
                              type="form-section"
                              className="sliding-panel-icon"
                            />
                          }
                        />
                      }
                      menu={
                        index > 0 ? (
                          <div
                            className="cursor-pointer"
                            onClick={this.removePost.bind(this, index)}
                          >
                            <span className="a10-icon remove" />
                          </div>
                        ) : (
                          ''
                        )
                      }
                    >
                      <A10Form.Item
                        {...postFormItemLayout}
                        label={
                          <>
                            <span className="mandatoryField">
                              {this.Messages.URI}
                            </span>
                            <span>
                              <HelpInfo
                                customCls="custom-tooltip-input"
                                placement="left"
                                title="URI"
                                helpKey="HELP_MONITOR_ACTION_POST_TO_URI"
                              />
                            </span>
                          </>
                        }
                      >
                        {getFieldDecorator(`uri-${index}`, {
                          rules: [{ validator: this.validatePost.bind(this) }],
                          initialValue: postData.uri,
                        })(
                          <A10Input
                            type="url"
                            size="large"
                            onBlur={(e: any) =>
                              this.handleChange('uri', e, index)
                            }
                          />,
                        )}
                      </A10Form.Item>
                      <A10Form.Item
                        {...postFormItemLayout}
                        label={
                          <>
                            {this.Messages.VALIDATE_CERTIFICATE}
                            <span>
                              <HelpInfo
                                customCls="custom-tooltip-input"
                                placement="left"
                                title="VALIDATE_CERTIFICATE"
                                helpKey="HELP_MONITOR_ACTION_VALIDATE_CERTIFICATE"
                              />
                            </span>
                          </>
                        }
                      >
                        <A10Switch
                          defaultChecked={postData.ssc}
                          size="default"
                          onChange={(e: any) =>
                            this.handleChange('ssc', e, index)
                          }
                        />
                      </A10Form.Item>

                      <A10Form.Item
                        {...postFormItemLayout}
                        label={
                          <>
                            {this.Messages.HEADERS}
                            <span>
                              <HelpInfo
                                customCls="custom-tooltip-input"
                                placement="left"
                                title="Headers"
                                helpKey="HELP_MONITOR_ACTION_HEADERS"
                              />
                            </span>
                          </>
                        }
                      >
                        {getFieldDecorator(`headers-${index}`, {
                          rules: [
                            {
                              validator: this.validateHeaders.bind(this, index),
                            },
                          ],
                          initialValue: postData.headers,
                        })(
                          <A10Input.TextArea
                            type="text"
                            size="large"
                            placeholder={this.Messages.HEADERS_PLACEHOLDER}
                          />,
                        )}
                      </A10Form.Item>

                      <A10Form.Item
                        {...postFormItemLayout}
                        label={
                          <>
                            {this.Messages.EXTENDED_BODY}
                            <span>
                              <HelpInfo
                                customCls="custom-tooltip-input"
                                placement="left"
                                title="Extended Body"
                                helpKey="HELP_MONITOR_ACTION_EXTENDED_BODY"
                              />
                            </span>
                          </>
                        }
                      >
                        <A10Input
                          type="text"
                          size="large"
                          placeholder={this.Messages.EXTENDED_BODY_PLACEHOLDER}
                          value={postData.extended_body}
                          onChange={(e: any) =>
                            this.handleChange('extended_body', e, index)
                          }
                        />
                      </A10Form.Item>
                    </A10Panel>
                  </div>
                )
              })}
              {postArr && postArr.length < this.MAX_POSTDATA ? (
                <A10Row>
                  <A10Col span={9} />
                  <A10Col span={13}>
                    <div
                      className="add-another-link no-margin"
                      onClick={this.addPostObject}
                    >
                      <span className="a10-icon add-another" />
                      <span className="label">
                        Add another POST destination
                      </span>
                    </div>
                  </A10Col>
                </A10Row>
              ) : null}
            </A10Collapse.Panel>
          </A10Collapse>
        </A10Panel>
      </A10Form>
    )
  }
}
export default setupA10Container(A10Form.create()(ActionAddForm))
