import React from 'react'
import './styles/index.less'
import parameters from 'parameters'

interface IGrafanaProps {}

const Grafana: React.FC<IGrafanaProps> = props => {
  return (
    <div style={{ height: '92vh' }}>
      <iframe
        src={`${parameters.BASE_URL}/grafana`}
        id="invisible"
        name="invisible"
        className="grafanaIframe"
      ></iframe>
    </div>
  )
}

export default Grafana
