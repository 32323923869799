import React from 'react'
import { A10Tooltip } from '@gui-libraries/widgets'
import styles from './styles/index.module.less'

const DoubleMetrics: React.FC<any> = props => {
  const { metrics, tooltipPosition } = props
  return (
    <>
      <div className={styles.container}>
        {metrics.map((item: any, index: number) => {
          return (
            <>
              <div
                data-testid="double-metrics-container"
                className={styles.item}
              >
                {item?.title ? (
                  <div className={styles.title}>
                    <A10Tooltip
                      title={item?.tooltip}
                      placement={tooltipPosition}
                    >
                      <span>{item?.title}</span>
                    </A10Tooltip>
                  </div>
                ) : null}
                <div className={styles.metricContainer}>
                  <div
                    data-testid="double-metrics-subTitle1"
                    className={styles.subTitle}
                  >
                    {item?.firstSubTitle}
                  </div>
                  <div>
                    <span
                      data-testid="double-metrics-metric1"
                      className={styles.metric}
                    >
                      {item?.metric1}
                    </span>
                    {item?.unit ? (
                      <span className={styles.unit}>{item.unit}</span>
                    ) : null}
                  </div>
                </div>
                <div className={styles.metricContainer}>
                  <div
                    data-testid="double-metrics-subTitle2"
                    className={styles.subTitle}
                  >
                    {item?.secondSubTitle}
                  </div>
                  <div>
                    <span
                      data-testid="double-metrics-subTitle2"
                      className={styles.metric}
                    >
                      {item?.metric2}
                    </span>
                    {item?.unit ? (
                      <span className={styles.unit}>{item.unit}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )
        })}
      </div>
    </>
  )
}

export default DoubleMetrics
