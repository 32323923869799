import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import { A10Row, A10Col } from '@gui-libraries/widgets'

import { RoundNumber } from 'src/components/shared/RoundNumber'
import storage from 'src/libraries/storage'
import { httpClient } from 'src/libraries/httpClient'
import DevicePartitionList from './DevicePartitionList'
// tslint:disable-next-line:no-var-requires

export interface ILogicalPartitionDetailProps
  extends IA10ContainerDefaultProps {
  data: IObject
  index: number
  isLoading?: boolean
  refreshIndex?: number
  updateList?: (search?: string, editName?: string) => void
}

export interface ILogicalPartitionDetailState {
  clusterPartition: string
  partitionList: IObject[]
}

class LogicalPartitionDetail extends A10Container<
  ILogicalPartitionDetailProps,
  ILogicalPartitionDetailState
> {
  constructor(props: ILogicalPartitionDetailProps) {
    super(props)
    this.state = {
      clusterPartition: '',
      partitionList: [],
    }
  }

  componentDidMount() {
    this.refreshData()
  }

  componentDidUpdate(preProps: ILogicalPartitionDetailProps) {
    if (
      preProps.refreshIndex !== this.props.refreshIndex &&
      this.props.refreshIndex === this.props.index
    ) {
      this.refreshData()
    }
  }

  refreshData = (updateLP: boolean = false) => {
    const { data, updateList } = this.props
    const cName = data.cluster
    const pName = data.partition
    const headerValue = cName + ': ' + pName
    const dUri = `/hpcapi/v3/provider/${
      storage.get.PROVIDER
    }/device?cluster=${encodeURIComponent(cName)}`
    if (pName) {
      httpClient.get(dUri).then(result => {
        const partitionList = (result.data['device-list'] || []).reduce(
          (acc: IObject[], device: IObject) => {
            const copiedDevice = _.cloneDeep(device)
            const partition = device['partition-list'].find(
              (item: IObject) => item.name === pName,
            )
            if (partition) {
              partition['device-name'] = device?.name
              partition.parent = copiedDevice
              acc.push(partition)
            }
            return acc
          },
          [],
        )
        this.setState({
          clusterPartition: headerValue,
          partitionList,
        })
        if (updateLP) {
          updateList('', data.name)
        }
      })
    }
  }

  renderDetail = () => {
    const {
      clusterPartition = 'No cluster partition is associated',
      partitionList = [],
    } = this.state
    const ClusterPartitionHeader = () => {
      return (
        <>
          <A10Row type="flex" align="middle">
            <A10Col lg={20}>
              <div className="table-header-inner pull-left">
                <span className="page-header">
                  {clusterPartition === ''
                    ? 'No Associated Cluster Partition'
                    : clusterPartition}
                </span>
                {partitionList.length > 0 ? (
                  <RoundNumber number={partitionList.length} />
                ) : null}
              </div>
            </A10Col>
          </A10Row>
        </>
      )
    }

    return (
      <div className="detail-section">
        <ClusterPartitionHeader />
        <DevicePartitionList
          data={partitionList}
          lpUUID={this.props.data.uuid}
          onRefresh={this.refreshData}
        />
      </div>
    )
  }

  render() {
    return <div className="list-detail-container">{this.renderDetail()}</div>
  }
}
export default setupA10Container(LogicalPartitionDetail)
