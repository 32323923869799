import { httpClient } from 'src/libraries/httpClient'

const getStats = async (accounts: string[], payload: IObject) => {
  const systemLogApi =
    '/analytics/thunder-adc/system_telemetry_log_device_status/'
  if (accounts && accounts.length > 0) {
    const headers = { 'x-account': accounts.join(',') }
    const { data: data } = await httpClient.post(systemLogApi, payload, {
      absoluteBasePath: true,
      headers,
    })
    return data
  } else {
    return {}
  }
}

const getRequestsCapacity = async () => {
  const capacityApi = '/analytics/configuration/requests/capacity/'
  const defualtCapacity = 5000
  try {
    const {
      data: { capacity: capacity = defualtCapacity },
    } = await httpClient.get(capacityApi, {
      absoluteBasePath: true,
    })
    return capacity
  } catch {
    /* istanbul ignore next */
    return defualtCapacity
  }
}
/* istanbul ignore next */
const computeAvgUsage = (
  stats: IObject,
  timeInterval: number,
  capacity: number,
) => {
  if (stats) {
    return (
      ((stats.pr_sent_sum +
        stats.pc_sent_sum +
        stats.pr_drop_sum +
        stats.pc_drop_sum) *
        100) /
      (timeInterval * capacity)
    )
  }
  return 0
}

const getStatsByAccountId = async (
  selectedTimePeriod: IObject,
  accounts: string[],
) => {
  const payload = {
    thunderStats: {
      fields: ['ts', 'pc_sent', 'pr_sent', 'pc_drop', 'pr_drop'],
      aggregation: 'sum',
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      groupby: 'account_id',
    },
  }
  const timeInterval = selectedTimePeriod.endTime - selectedTimePeriod.startTime
  const requestCapacity = await getRequestsCapacity()
  const data = await getStats(accounts, payload)
  const thunderStats = data.thunderStats || {}
  const stats = {}
  accounts.forEach((account: string) => {
    stats[account] = {
      avg: computeAvgUsage(
        thunderStats[account],
        timeInterval,
        requestCapacity,
      ),
    }
  })
  return stats
}

export default getStatsByAccountId
