import {
  A10Col,
  A10Collapse,
  A10Message,
  A10Row,
  A10DropdownMenu
} from '@gui-libraries/widgets'
import React, { useEffect, useState } from 'react'

import { CertificateFormAction } from './CertificateFormAction'
import { Messages } from 'src/locale/en'
import ReactLoading from 'react-loading'
import { SystemService } from 'src/services/index'
import './styles/index.less'

export interface ISSLConfigState {
  fqdn: string,
  serverCert: string,
  serverKey: string,
  name: string,
  verify: boolean,
  combinedCert: string,
  caCert: string,
}

const systemService = new SystemService()
const messages = new Messages()
let childForm: React.ReactElement = null

export const getIngressCertificate = async (names: string) => {
  let apiResponse: any = []
  const payload = {
    names: names
  }
  try {
    const { data: response } = await systemService.getCertData(
      null,
      payload,
      null
    )
    apiResponse = response
  } catch (error) {
    apiResponse = []
  }
  return apiResponse
}

export   const splitCertpara = (data:string) => {
  const regex = /([\s\S]*?)-----END CERTIFICATE-----/
  const str = data?.split('-----BEGIN CERTIFICATE-----')
  const serverCertValue = str && str[1]?.match(regex)
  const CACertValue = str && str[2]?.match(regex)
  return {
    serverCertValue,
    CACertValue
  }
}

const SSLCertificate: React.FC<IObject> = (props) => {
  const [isLoading, setLoading] = useState(false)
  const [slidingTitle, setSlidingTitle] = useState('')
  const [showSlidingPage, setSlidingPage] = useState(false)
  const [formData, setformData] = useState<ISSLConfigState[]>([])

  useEffect(() => {
    fetchCertData()
  }, [])

  const fetchCertData = async () => {
    const data = await getIngressCertificate('ingress')
    setformData(data)
  }

  const onEditClick = (isOpen: boolean) => {
    setSlidingPage(isOpen)
    setLoading(false)
    setSlidingTitle('Edit Certificates')
  }

  const handleSave = async (payload: ISSLConfigState[]) => {
    try {
      const response = await systemService.updateCertificateData(
        null,
        payload,
        null
      )
      if (response) {
        const message = `${(messages.CERTIFICATE_SUCCESS)}`
        A10Message.success(message, 10, close)
        if (response?.data) {
          const data = await getIngressCertificate('ingress')
          setformData(data)
        }
        setSlidingPage(!!false)
      }
    } catch (error) {
      const message = error?.response?.data?.message || messages.CERTIFICATE_FAILURE
      A10Message.error(message, 10, close)
    }
  }

  const resetCertificate = async () => {
    try {
      const response = await systemService.resetCertificateData(null, null, null)
      if (response) {
        const message = `${messages.RESET_SSL_CERTIFICATE_SUCCESS}`
        A10Message.success(message, 5, close)
        fetchCertData()
      }
    } catch {
      const message = `${messages.RESET_SSL_CERTIFICATE_FAIL}`
      A10Message.error(message, 5, close)
    }
  }

  const dropdownOptions = [
    <div key="edit">Edit</div>,
    <div key="reset">Reset as Default</div>,
  ]

  const clickAction = (component: JSX.Element) => {
    if (component.key === 'edit') {
      onEditClick(true)
    } else if (component.key === 'reset') {
      resetCertificate()
    }
  }

  return (
    <>
      <A10Row>
        <A10Collapse className='SSL-content-section'>
          <A10Collapse.Panel key='sslCertificate'
            header={
              <A10Row>
                <A10Row type="flex" align="middle" justify="space-between">
                  <A10Col>
                    <span className='SSL-heading'>
                      {messages.SSL_CERTIFICATE}
                    </span>
                  </A10Col>
                  <A10Col style={{ paddingRight: '8px' }}>
                    <A10DropdownMenu
                      menu={dropdownOptions}
                      onClick={clickAction}
                      trigger="hover"
                      placement="bottomRight"
                      arrowPointAtCenter={true}
                    />
                  </A10Col>
                </A10Row>
                <A10Row className='sub-heading'>
                  <A10Col span={12} >
                    <A10Col className='column-heading '>
                      {messages.FQDN}
                    </A10Col>
                    <A10Col>
                      <p style={{ fontWeight: 'normal' }}>
                        {formData[0]?.fqdn}
                      </p>
                    </A10Col>
                  </A10Col>
                  <A10Col span={12} >
                    <A10Col className='column-heading '>
                      {messages.PRIVATE_KEY}
                    </A10Col>
                    <A10Col>
                      <p style={{ fontWeight: 'normal' }}>
                        {formData[0]?.serverKey &&
                          'Private Key is available'
                        }
                      </p>
                    </A10Col>
                  </A10Col>
                </A10Row>
              </A10Row>
            }
          >
            <A10Row style={{ marginLeft: '-5px' }}>
              <A10Row style={{ marginBottom: '40px' }}>
                <A10Row style={{ marginBottom: '15px' }}>
                  <span className='expanded-row-heading '>
                    Server Certificate
                  </span>
                </A10Row>
                <A10Row >
                  -----BEGIN NEW CERTIFICATE REQUEST-----
                  <p className='paragraph-section'>
                    {splitCertpara(formData[0]?.serverCert).serverCertValue?.[1]}
                  </p>
                  -----END NEW CERTIFICATE REQUEST-----
                </A10Row>
              </A10Row>
              <A10Row style={{ marginBottom: '40px' }}>
                <A10Row style={{ marginBottom: '15px' }}>
                  <span className='expanded-row-heading'>
                    CA Certificate Chain
                  </span>
                </A10Row>
                <A10Row >
                  -----BEGIN NEW CERTIFICATE REQUEST-----
                  <p className='paragraph-section'>
                    {splitCertpara(formData[0]?.serverCert).CACertValue?.[1]}
                  </p>
                  -----END NEW CERTIFICATE REQUEST-----
                </A10Row>
              </A10Row>
            </A10Row>
          </A10Collapse.Panel>
        </A10Collapse>
      </A10Row>
      <CertificateFormAction
        show={showSlidingPage}
        title={slidingTitle}
        formData={formData}
        onSubmitForm={handleSave}
        onClose={() => setSlidingPage(!!false)}
        onRef={(ref: any) => {
          childForm = ref
        }}
      />
      {isLoading && (
        <div>
          <ReactLoading
            type="bars"
            color="#4a90e2"
            height={40}
            width={40}
          />
        </div>
      )}
    </>
  )
}

export default SSLCertificate
