import React from 'react'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import { Messages } from 'src/locale/en/'
import {
  InfrastructureService,
  InfraStructureStatsService,
  Utilities,
} from 'src/services'
import storage from 'src/libraries/storage'
import { InterfaceContent } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/Devices/DeviceDetails/InterfaceContent'
import { NetworkContent } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/Devices/DeviceDetails/NetworkContent'
import { TunnelContent } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/Devices/DeviceDetails/TunnelContent'
import { RoutingProtocolContent } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/Devices/DeviceDetails/RoutingContent'
import DeviceOverview from './DeviceOverview'

import './styles/DeviceDetails.scss'

export interface IDeviceDetailsProps extends IA10ContainerDefaultProps {
  deviceObj: IObject
  cluster: any
  fromClustersPage: boolean
  onPartitionUpdate?: any
  deviceUpdated: boolean
  refreshDevices?: any
  updateDeviceLicense?: any
  rangeByTime?: any
  contentType: string
  updateDeviceData: IObject
}
export interface IDeviceDetailsState {
  dataLoaded: boolean
  ethernetInterfaces: IObject
  devLiceCheckProgress: boolean
  selectedPartition: string
  deviceSN: string
  clusterObj: IObject
  deviceDetail: IObject
  refreshClicked: boolean
}

const statColumns = [
  {
    name: 'Health',
    data: 'NA',
    unit: '',
    level: 'warn',
  },
  {
    name: 'Device License',
    data: '--',
    unit: '',
    level: 'warn',
  },
  {
    name: 'Harmony License',
    data: 'UNLICENSED',
    unit: '',
    level: 'warn',
  },
  {
    name: 'Throughput',
    data: 'NA',
    unit: 'bps',
    level: 'warn',
  },
  {
    name: 'Connection Rate',
    data: '0',
    unit: '/s',
    level: 'warn',
  },
  {
    name: 'CPU',
    data: '0',
    unit: '%',
    level: 'warn',
  },
  {
    name: 'Memory',
    data: '0',
    unit: '%',
    level: 'warn',
  },
]

class DeviceDetails extends A10Container<
  IDeviceDetailsProps,
  IDeviceDetailsState
> {
  InfrastructureService = new InfrastructureService()
  Messages = new Messages()
  Utilities = new Utilities()
  InfraStructureStatsService = new InfraStructureStatsService()

  constructor(props: IDeviceDetailsProps) {
    super(props)
    this.state = {
      dataLoaded: false,
      ethernetInterfaces: [],
      devLiceCheckProgress: false,
      selectedPartition: 'shared',
      deviceSN: '',
      clusterObj: {},
      deviceDetail: {},
      refreshClicked: false,
    }
  }

  getDeviceSN = () => {
    const provider = storage.get.PROVIDER
    const {
      deviceObj: { name: deviceName },
    } = this.props
    const { selectedPartition } = this.state
    const payload = {
      'object-url': `/hpcapi/v3/provider/provider/root/device/${deviceName}/partition/${selectedPartition}/version/oper`,
      method: 'GET',
      'object-payload': {},
    }
    const req = this.InfrastructureService.devicePartitionAxApiGetCall(
      null,
      payload,
      [provider, deviceName, selectedPartition],
    )
    req
      .then(res => {
        const data = res?.data?.[0]

        if (data) {
          const deviceUUID = Object.keys(data)[0]
          const serialNumber =
            data?.[deviceUUID]?.version?.oper?.['serial-number']

          this.setState({
            deviceSN: serialNumber,
          })
        }
      })
      .catch(err => {
        console.error(err)
        this.setState({
          deviceSN: 'NA',
        })
      })
  }

  getInterfaceDetails = () => {
    const { selectedPartition, deviceDetail } = this.state
    if (deviceDetail?.['partition-list']?.length > 0) {
      const interfaceReq = this.InfrastructureService.getDeviceInterfaces(
        null,
        null,
        [storage.get.PROVIDER, deviceDetail.name, selectedPartition],
      )
      interfaceReq
        .then((resp: any) => {
          const ethernetInterfaces = resp?.data?.['ethernet-list'] || []

          this.setState({ ethernetInterfaces })
        })
        .catch((err: any) => {
          // console.log(err)
        })
    }
  }

  // On-demand get cluster information
  getClusterObj = async () => {
    const { deviceObj } = this.props
    const cluster = this.InfrastructureService.getClusterSummary(null, null, [
      storage.get.PROVIDER,
      deviceObj?.cluster ?? '',
    ])
    await cluster
      .then((response: any) => {
        this.setState({ clusterObj: response?.data?.['cluster-list'] || {} })
      })
      .catch((error: any) => {})
  }

  // On-demand get device detail information
  getDeviceDetailObj = async () => {
    const { deviceObj: deviceSummaryObj } = this.props
    const res = await this.InfrastructureService.getDevice(null, null, [
      storage.get.PROVIDER,
      deviceSummaryObj?.name,
    ])

    let deviceObj = res?.data?.device || {}

    // Set device object property used for Device Detail card
    // Moved from Devices.tsx
    deviceObj.memory = 'NA'
    deviceObj.maxmem = 'NA'
    deviceObj.avgmem = 'NA'
    deviceObj.session = 'NA'
    deviceObj.dataCpu = 'NA / NA'
    deviceObj.controlCpu = 'NA / NA'
    deviceObj.overallCpu = 'NA / NA'
    deviceObj.maxcpu = 'NA'
    deviceObj.avgcpu = 'NA'
    deviceObj.avgdatacpu = 'NA'
    deviceObj.avgcontrolcpu = 'NA'
    deviceObj.hcLicense = 'UNLICENSED'
    deviceObj.hcLicenseClassName = 'warning-lic-class'
    deviceObj.deviceLicense = '--'
    deviceObj.deviceLicenseClassName = 'warning-lic-class'

    deviceObj['statColumns'] = JSON.parse(JSON.stringify(statColumns))
    deviceObj.statColumns[3].data = '0'
    deviceObj.statColumns[5].data = '0'
    deviceObj.statColumns[6].data = '0'

    deviceObj.statColumns[3].level = 'warn'
    deviceObj.statColumns[5].level = 'warn'
    deviceObj.statColumns[6].level = 'warn'

    if (deviceObj['licenseinfo-list']) {
      deviceObj = this.setLicenseDetails(deviceObj)
    } else {
      deviceObj.statColumns[1].data = '--'
      deviceObj.statColumns[1].tooltip = 'Check device for license'
      deviceObj.statColumns[1].unit = ''
      deviceObj.statColumns[1].level = ''
      deviceObj.statColumns[2].data = 'UNLICENSED'
      deviceObj.statColumns[2].unit = ''
      deviceObj.statColumns[2].level = 'warn'
    }

    const xAccount = this.Utilities.getAllXAccountIDs()
    const header = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      provider: storage.get.PROVIDER,
      Authorization: storage.get.ENCODED_SESSION_ID,
      'X-Account': xAccount,
    }

    const to = Date.now()
    const from = Date.now() - 2 * 60 * 1000
    const payload = {
      deviceStats: {
        rangeby: {
          start: from,
          end: to,
          field: 'ts',
        },
        size: 10000,
      },
      deviceThroughput: {
        fields: ['total_bytes_in', 'total_bytes_out'],
        aggregation: 'sum',
        sort: 'asc',
        rangeby: {
          start: Date.now() - 6 * 60 * 60 * 1000,
          end: Date.now(),
          field: 'ts',
        },
        histogram: {
          field: 'ts',
          interval: 120000,
        },
        filterby: {
          and: {
            device_uuid: deviceObj?.uuid,
          },
        },
        groupby: 'device_uuid',
      },
      avgCPUUtil: {
        fields: ['avg_data_cpu_usage'],
        aggregation: 'avg',
        sort: 'asc',
        rangeby: {
          start: Date.now() - 6 * 60 * 60 * 1000,
          end: Date.now(),
          field: 'ts',
        },
        histogram: {
          field: 'ts',
          interval: 120000,
        },
        filterby: {
          and: {
            device_uuid: deviceObj?.uuid,
          },
        },
        groupby: 'device_uuid',
      },
    }

    if (this.props.cluster) {
      payload.deviceStats['filterby'] = {
        and: {
          cluster_id: this.props.cluster['cluster-uuid'],
        },
      }
      payload.deviceThroughput['filterby'].and.cluster_id = this.props.cluster[
        'cluster-uuid'
      ]
      payload.avgCPUUtil['filterby'].and.cluster_id = this.props.cluster[
        'cluster-uuid'
      ]
    }

    deviceObj.deviceCpuStats = []

    const deviceStatsReq = this.InfraStructureStatsService.getClusterStats(
      header,
      payload,
      null,
    )
    deviceStatsReq.then((response: any) => {
      const deviceThroughputData =
        response.data && response.data.deviceThroughput
          ? response.data.deviceThroughput
          : {}
      const avgCPUUtilData =
        response.data && response.data.avgCPUUtil
          ? response.data.avgCPUUtil
          : {}

      if (deviceThroughputData[deviceObj['device-uuid']]) {
        const chartData = this.Utilities.parseBPSChartData(
          deviceThroughputData[deviceObj['device-uuid']],
          payload.deviceThroughput,
        )
        deviceObj.bpsChart = chartData
      }
      if (avgCPUUtilData[deviceObj['device-uuid']]) {
        const chartData = this.Utilities.parseCPUUtilChartData(
          avgCPUUtilData[deviceObj['device-uuid']],
        )
        deviceObj.cpuUtilChart = chartData
      }

      const deviceStat = Object.keys(response.data.deviceStats)
        .filter(
          key =>
            response.data.deviceStats[key]['device_uuid'] ==
            deviceObj['device-uuid'],
        )
        .map((key, index) => {
          return response.data.deviceStats[key]
        })

      let deviceUsageChart = {
        series: [
          {
            name: '',
            data: [0],
          },
        ],
        controlChart: [
          {
            name: '',
            data: [0],
          },
        ],
        dataChart: [
          {
            name: '',
            data: [0],
          },
        ],
      }

      const cpuCount = {},
        deviceStatKeyLength = deviceStat.length

      let cpuCounts = 0,
        controlCpuCounts = 0,
        dataCpuCounts = 0,
        cpu_usage_sum = 0,
        controlCpuSum = 0,
        dataCpuSum = 0,
        cpu_usage_avg = -1,
        cpu_usage_max = -1,
        avg_control_cpu_usage = 0,
        max_control_cpu_usage = 0,
        avg_data_cpu_usage = 0,
        max_data_cpu_usage = 0,
        avg_vthunder_cpu_usage = 0,
        max_vthunder_cpu_usage = 0,
        free_memory_ratio = 0,
        free_memory_ratio_max = 1,
        free_session_ratio = 0,
        total_bytes_in = 0,
        total_bytes_out = 0

      deviceObj['statColumns'] = JSON.parse(JSON.stringify(statColumns))
      deviceObj.hcLicense = 'UNLICENSED'
      deviceObj.hcLicenseClassName = 'warning-lic-class'
      deviceObj.deviceLicense = '--'
      deviceObj.deviceLicenseClassName = 'warning-lic-class'

      if (deviceObj['licenseinfo-list']) {
        deviceObj = this.setLicenseDetails(deviceObj)
      } else {
        deviceObj.statColumns[1].data = '--'
        deviceObj.statColumns[1].tooltip = 'Check device for license'
        deviceObj.statColumns[1].unit = ''
        deviceObj.statColumns[1].level = ''
        deviceObj.statColumns[2].data = 'UNLICENSED'
        deviceObj.statColumns[2].unit = ''
        deviceObj.statColumns[2].level = 'warn'
      }
      if (deviceStatKeyLength > 0) {
        if (deviceStat[0]['cluster_vcs_state'] === 'vcs_disabled') {
          deviceObj.vcsState = 'Disabled'
        }
        if (deviceStat[0]['cluster_vcs_state'] === 'master') {
          deviceObj.vcsState = 'Master'
        }
        if (deviceStat[0]['cluster_vcs_state'] === 'blade') {
          deviceObj.vcsState = 'Blade'
        }

        const cpuKeys = Object.keys(deviceStat[0].cpu_usage_overall)
        for (let i = 0; i < deviceStatKeyLength; i++) {
          for (let k = 0; k < cpuKeys.length; k++) {
            let cpuCountVal = deviceStat[i].cpu_usage_overall[cpuKeys[k]]
            if (!cpuCount[cpuKeys[k]]) {
              cpuCount[cpuKeys[k]] = 0
            }
            cpuCount[cpuKeys[k]] += cpuCountVal

            cpu_usage_sum += cpuCountVal
            if (cpuCountVal > cpu_usage_max) {
              cpu_usage_max = cpuCountVal
            }
            cpuCounts++

            if (cpuKeys[k].indexOf('Data') > -1) {
              dataCpuSum += cpuCountVal
              dataCpuCounts++
            }
            if (cpuKeys[k].indexOf('Control') > -1) {
              controlCpuSum += cpuCountVal
              controlCpuCounts++
            }
          }

          avg_control_cpu_usage += deviceStat[i].avg_control_cpu_usage
          max_control_cpu_usage += deviceStat[i].max_control_cpu_usage
          avg_data_cpu_usage += deviceStat[i].avg_data_cpu_usage
          max_data_cpu_usage += deviceStat[i].max_data_cpu_usage
          avg_vthunder_cpu_usage += deviceStat[i].avg_vthunder_cpu_usage
          max_vthunder_cpu_usage += deviceStat[i].max_vthunder_cpu_usage
          free_memory_ratio += deviceStat[i].free_memory_ratio
          free_session_ratio += deviceStat[i].free_session_ratio
          total_bytes_in += deviceStat[i].total_bytes_in
          total_bytes_out += deviceStat[i].total_bytes_out

          if (deviceStat[i].free_memory_ratio < free_memory_ratio_max) {
            free_memory_ratio_max = deviceStat[i].free_memory_ratio
          }
        }

        avg_control_cpu_usage /= deviceStatKeyLength
        max_control_cpu_usage /= deviceStatKeyLength
        avg_data_cpu_usage /= deviceStatKeyLength
        max_data_cpu_usage /= deviceStatKeyLength
        avg_vthunder_cpu_usage /= deviceStatKeyLength
        max_vthunder_cpu_usage /= deviceStatKeyLength
        free_memory_ratio /= deviceStatKeyLength
        free_session_ratio /= deviceStatKeyLength
        // total_bytes_in /= deviceStatKeyLength
        // total_bytes_out /= deviceStatKeyLength

        let memHealth = 0,
          cpuHealth = 0,
          deviceHealth = 0
        if (1 - free_memory_ratio < 0.7) {
          memHealth = 1
        } else if (1 - free_memory_ratio > 0.9) {
          memHealth = 0
        } else {
          memHealth = 1 - (1 - free_memory_ratio - 0.7) / 0.2
        }
        const cpuMax = Math.max(avg_control_cpu_usage, avg_data_cpu_usage)
        if (cpuMax < 40) {
          cpuHealth = 1
        } else if (cpuMax > 70) {
          cpuHealth = 0
        } else {
          cpuHealth = 1 - (cpuMax - 40) / 30
        }
        deviceHealth = Math.min(memHealth, cpuHealth)
        deviceObj.deviceHealth = deviceHealth
        deviceObj.statColumns[0].data =
          deviceHealth === 0 ? 'Bad' : deviceHealth >= 0.8 ? 'Good' : 'Average'
        deviceObj.statColumns[0].level =
          deviceHealth === 0
            ? 'error'
            : deviceHealth >= 0.8
            ? 'success'
            : 'warn'

        if (cpuCounts > 0) {
          cpu_usage_avg = cpu_usage_sum / cpuCounts
        }

        const cpuNames = Object.keys(cpuCount)
        const parsedChartData = [],
          controlChart = [],
          dataChart = []
        for (let l = 0; l < Object.keys(cpuCount).length; l++) {
          parsedChartData.push({
            name: cpuNames[l],
            data: [cpuCount[cpuNames[l]]],
          })
          if (cpuNames[l].toLowerCase().indexOf('control') > -1) {
            controlChart.push({
              name: cpuNames[l],
              data: [cpuCount[cpuNames[l]]],
            })
          }
          if (cpuNames[l].toLowerCase().indexOf('data') > -1) {
            dataChart.push({
              name: cpuNames[l],
              data: [cpuCount[cpuNames[l]]],
            })
          }
          deviceUsageChart.series = parsedChartData
        }
        deviceUsageChart.controlChart = controlChart
        deviceUsageChart.dataChart = dataChart

        const memory = Math.round(100 - 100 * free_memory_ratio)
        const maxmem = Math.round(100 - 100 * free_memory_ratio_max)
        const avgmem = Math.round(100 - 100 * free_memory_ratio)
        const avgCPU =
          avg_vthunder_cpu_usage >= 0
            ? Math.round(avg_vthunder_cpu_usage)
            : Math.round(cpu_usage_avg) >= 0
            ? Math.round(cpu_usage_avg)
            : 0

        deviceObj.statColumns[3].data = this.Utilities.truncateVal(
          ((total_bytes_in + total_bytes_out) * 8) / 60,
          'bytes',
        )
        deviceObj.statColumns[5].data = avgCPU >= 0 ? avgCPU : 0
        deviceObj.statColumns[5].level =
          avgCPU > 74 ? 'warn' : avgCPU > 89 ? 'error' : 'success'

        if (!isNaN(memory)) {
          deviceObj.memory = memory + '%'
          deviceObj.maxmem = maxmem + '%'
          deviceObj.avgmem = avgmem + '%'
          deviceObj.statColumns[6].data = memory
          deviceObj.statColumns[6].level =
            memory > 74 ? 'warn' : memory > 89 ? 'error' : 'success'
        } else {
          deviceObj.memory = 'NA'
          deviceObj.maxmem = 'NA'
          deviceObj.avgmem = 'NA'
          deviceObj.statColumns[6].data = '0'
          deviceObj.statColumns[6].level = 'success'
        }

        deviceObj.session = !isNaN(Math.round(100 - 100 * free_session_ratio))
          ? Math.round(100 - 100 * free_session_ratio) + '%'
          : 'NA'

        deviceObj.dataCpu =
          (max_data_cpu_usage >= 0
            ? Math.round(max_data_cpu_usage) + '%'
            : 'NA') +
          ' / ' +
          (avg_data_cpu_usage >= 0
            ? Math.round(avg_data_cpu_usage) + '%'
            : 'NA')

        deviceObj.controlCpu =
          (max_control_cpu_usage >= 0
            ? Math.round(max_control_cpu_usage) + '%'
            : 'NA') +
          ' / ' +
          (avg_control_cpu_usage >= 0
            ? Math.round(avg_control_cpu_usage) + '%'
            : 'NA')

        deviceObj.overallCpu =
          (max_vthunder_cpu_usage >= 0
            ? Math.round(max_vthunder_cpu_usage) + '%'
            : Math.round(cpu_usage_max) >= 0
            ? Math.round(cpu_usage_max) + '%'
            : 'NA') +
          ' / ' +
          (avgCPU >= 0 ? avgCPU + '%' : 'NA')
        deviceObj.maxcpu =
          max_vthunder_cpu_usage >= 0
            ? Math.round(max_vthunder_cpu_usage) + '%'
            : Math.round(cpu_usage_max) >= 0
            ? Math.round(cpu_usage_max) + '%'
            : 'NA'
        deviceObj.avgcpu = avgCPU >= 0 ? avgCPU + '%' : 'NA'
        if (dataCpuCounts > 0) {
          deviceObj.avgdatacpu = Math.round(dataCpuSum / dataCpuCounts) + '%'
        } else {
          deviceObj.avgdatacpu = 'NA'
        }
        if (controlCpuCounts > 0) {
          deviceObj.avgcontrolcpu =
            Math.round(controlCpuSum / controlCpuCounts) + '%'
        } else {
          deviceObj.avgcontrolcpu = 'NA'
        }
      } else {
        deviceObj.memory = 'NA'
        deviceObj.maxmem = 'NA'
        deviceObj.avgmem = 'NA'
        deviceObj.session = 'NA'
        deviceObj.dataCpu = 'NA / NA'
        deviceObj.controlCpu = 'NA / NA'
        deviceObj.overallCpu = 'NA / NA'
        deviceObj.maxcpu = 'NA'
        deviceObj.avgcpu = 'NA'
        deviceObj.avgdatacpu = 'NA'
        deviceObj.avgcontrolcpu = 'NA'

        deviceObj.statColumns[3].data = '0'
        deviceObj.statColumns[5].data = '0'
        deviceObj.statColumns[6].data = '0'

        deviceObj.statColumns[3].level = 'warn'
        deviceObj.statColumns[5].level = 'warn'
        deviceObj.statColumns[6].level = 'warn'
      }

      if (deviceUsageChart.series && deviceStatKeyLength > 0) {
        for (let i = 0; i < deviceUsageChart.series.length; i++) {
          deviceUsageChart.series[i].data[0] = Math.round(
            deviceUsageChart.series[i].data[0] / deviceStatKeyLength,
          )
        }
        for (
          let i = 0;
          deviceUsageChart.controlChart &&
          i < deviceUsageChart.controlChart.length;
          i++
        ) {
          deviceUsageChart.controlChart[i].data[0] = Math.round(
            deviceUsageChart.controlChart[i].data[0] / deviceStatKeyLength,
          )
        }
        for (let i = 0; i < deviceUsageChart.dataChart.length; i++) {
          deviceUsageChart.dataChart[i].data[0] = Math.round(
            deviceUsageChart.dataChart[i].data[0] / deviceStatKeyLength,
          )
        }

        deviceObj.deviceCpuStats = deviceUsageChart.series
        deviceObj['controlChart'] = deviceUsageChart.controlChart
        deviceObj['dataChart'] = deviceUsageChart.dataChart
      } else {
        deviceObj.deviceCpuStats = []
        deviceObj['controlChart'] = []
        deviceObj['dataChart'] = []
      }
    })
    this.setState(
      {
        deviceDetail: this.setLicenseDetails(deviceObj),
      },
      this.getInterfaceDetails, // Need device detail to fetch interface data
    )
  }

  // Set license property
  setLicenseDetails = (deviceObj: IObject, newLicDevices?: IObject[]) => {
    const oldLicDevices = deviceObj?.['licenseinfo-list']
    const licDevices = newLicDevices || oldLicDevices

    licDevices?.map((licDevice: any) => {
      if (
        licDevice['license-type'] &&
        licDevice['license-type'] === 'HC_LICENSE'
      ) {
        const bandwidth = this.Utilities.megaToGiga(
          licDevice['device-bandwidth'],
        )
        if (
          licDevice['license-error-type'] === 'WARNING' ||
          licDevice['license-error-type'] === 'ERROR'
        ) {
          deviceObj.statColumns[2].tooltip = licDevice['notes']
        }
        if (licDevice['license-state'] === 'HC_LICENSE_AVAILABLE') {
          deviceObj.hcLicense = licDevice['device-bandwidth']
            ? bandwidth.val + (bandwidth.unit === 'm' ? 'Mbps' : 'Gbps')
            : '0Mbps'

          deviceObj.statColumns[2].data = licDevice['device-bandwidth']
            ? bandwidth.val + (bandwidth.unit === 'm' ? 'M' : 'G')
            : '0M'
          deviceObj.statColumns[2].unit = 'bps'
          deviceObj.statColumns[2].level = licDevice['device-bandwidth']
            ? licDevice['license-error-type'] === 'INFO'
              ? 'success'
              : licDevice['license-error-type'] === 'WARNING'
              ? 'warn'
              : 'error'
            : 'success'
        } else {
          deviceObj.hcLicense = 'UNLICENSED'

          deviceObj.statColumns[2].data = 'UNLICENSED'
          deviceObj.statColumns[2].unit = ''
          deviceObj.statColumns[2].level =
            licDevice['license-error-type'] === 'WARNING' ? 'warn' : 'error'
        }
        deviceObj.hcLicenseClassName =
          licDevice['license-error-type'] === 'INFO'
            ? ''
            : licDevice['license-error-type'] === 'WARNING'
            ? 'warning-lic-class'
            : 'danger-lic-class'
      } else {
        const bandwidth = this.Utilities.megaToGiga(
          licDevice['device-bandwidth'],
        )
        if (licDevice['license-type'] === 'EXTERNAL_DEVICE_LICENSE') {
          deviceObj.statColumns[1].tooltip = 'External Device License'
        } else if (licDevice['license-type'] === 'PERPETUAL_LICENSE') {
          deviceObj.statColumns[1].tooltip =
            'Perpetual License' +
            (licDevice['lic-key'] ? ' - ' + licDevice['lic-key'] : '')
        }
        if (licDevice['license-state'] === 'FAILED') {
          deviceObj.statColumns[1].tooltip = licDevice['notes']
        }

        if (licDevice['license-state'] === 'ACTIVATED') {
          deviceObj.deviceLicense =
            licDevice['device-bandwidth'] && bandwidth.val > 0
              ? bandwidth.val + (bandwidth.unit === 'm' ? 'Mbps' : 'Gbps')
              : 'LICENSED'

          deviceObj.statColumns[1].data =
            licDevice['device-bandwidth'] && bandwidth.val > 0
              ? bandwidth.val + (bandwidth.unit === 'm' ? 'M' : 'G')
              : 'LICENSED'
          deviceObj.statColumns[1].unit =
            deviceObj.statColumns[1].data === 'LICENSED' ? '' : 'bps'
          deviceObj.statColumns[1].level = 'success'
          if (
            licDevice['license-type'] === 'PERPETUAL_LICENSE' &&
            licDevice['lic-key'].toLowerCase() !== 'vthunder' &&
            licDevice['lic-key'].toLowerCase() !== 'cthunder'
          ) {
            deviceObj.deviceLicense = licDevice['lic-key']
            deviceObj.statColumns[1].data = licDevice['lic-key']
          }
        } else {
          deviceObj.deviceLicense = 'UNLICENSED'

          deviceObj.statColumns[1].data = 'UNLICENSED'
          deviceObj.statColumns[1].unit = ''
          deviceObj.statColumns[1].level =
            licDevice['license-state'] === 'PENDING' ? 'warn' : 'error'
        }
        deviceObj.deviceLicenseClassName =
          licDevice['license-state'] === 'ACTIVATED'
            ? ''
            : licDevice['license-state'] === 'PENDING'
            ? 'warning-lic-class'
            : 'danger-lic-class'
      }

      if (deviceObj.statColumns[1].data === '--') {
        deviceObj.statColumns[1].tooltip = 'Check device for license'
        deviceObj.statColumns[1].level = ''
      }
    })
    return deviceObj
  }

  componentDidMount() {
    this.getDeviceSN()
    this.getClusterObj()
    this.getDeviceDetailObj()
  }

  devLicStateCheck = () => {
    this.setState({ refreshClicked: true })
    const { deviceDetail } = this.state
    this.setState({
      devLiceCheckProgress: true,
    })
    const provider = storage.get.PROVIDER
    const devLicStateCheck = this.InfrastructureService.checkDeviceLicenseState(
      null,
      null,
      [provider, deviceDetail['device-uuid']],
    )
    devLicStateCheck
      .then((resp: IObject) => {
        const that = this
        setTimeout(function() {
          that.setState({
            devLiceCheckProgress: false,
          })
          that.getDeviceDetailObj()
        }, 5000)
      })
      .catch((err: any) => {
        this.setState({
          devLiceCheckProgress: true,
        })
        const msg = err?.response?.data?.message
          ? err.response.data.message
          : ''
        this.Utilities.showMessage(
          'Error while check of device license',
          0,
          0,
          msg,
        )
      })
  }

  updateSelectedPartition = (partition: string) => {
    this.setState({ selectedPartition: partition })
  }

  render() {
    const { deviceObj, contentType, updateDeviceData } = this.props
    const {
      clusterObj,
      ethernetInterfaces,
      devLiceCheckProgress,
      deviceDetail,
      selectedPartition,
    } = this.state
    let status =
      deviceObj.deviceHealth !== undefined ? deviceObj.deviceHealth : 'NA'
    let tooltip = ''
    if (status === 'NA') {
      status = 'undefined'
      tooltip = 'NA'
    } else if (status === 0) {
      status = 'stopped'
      tooltip = 'Bad'
    } else if (status >= 0.8) {
      status = 'ongoing'
      tooltip = 'Good'
    } else {
      status = 'warning'
      tooltip = 'Average'
    }

    return (
      <div className="col-md-12 margintop10 sidepadding0">
        {contentType === 'overview' && (
          <DeviceOverview
            deviceObj={deviceDetail}
            clusterObj={clusterObj}
            ethernetInterfaces={ethernetInterfaces}
            tooltip={tooltip}
            devLicStateCheck={this.devLicStateCheck}
            devLiceCheckProgress={devLiceCheckProgress}
            deviceSN={this.state.deviceSN}
          />
        )}
        {contentType === 'interfaces' && (
          <InterfaceContent
            deviceObj={deviceDetail}
            service={this.InfrastructureService}
            selectedPartition={selectedPartition}
            updateSelectedPartition={this.updateSelectedPartition}
          />
        )}
        {contentType === 'networks' && (
          <NetworkContent
            deviceObj={deviceDetail}
            service={this.InfrastructureService}
            selectedPartition={selectedPartition}
            updateSelectedPartition={this.updateSelectedPartition}
          />
        )}
        {contentType === 'tunnels' && <TunnelContent />}{' '}
        {contentType === 'routing' && (
          <RoutingProtocolContent
            deviceObj={deviceDetail}
            service={this.InfrastructureService}
          />
        )}
      </div>
    )
  }
}

export default setupA10Container(DeviceDetails)
