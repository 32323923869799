import React from 'react'

import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Row,
  A10Col,
  A10Icon,
  A10Form,
  A10Input,
  A10Radio,
  A10Tooltip,
} from '@gui-libraries/widgets'
import { Viz } from '@gui-libraries/viz'

import { Utilities } from 'src/services'
import { Messages } from 'src/locale/en/Messages'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import { HealthStatus } from 'src/components/ADC/HealthStatus'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'

import './styles/providerform.scss'

const { PieChart } = Viz

export interface IProviderFormProps extends IA10ContainerDefaultProps {
  form: any
  onRef?: any
  provider: any
  editMode?: boolean
  activationLink: string
  handleChange?: (data: any) => void
  handleLogCapacityChange?: (data: any) => void
  logCapacityObj: any
  logProcessingObj: any
}

export interface IProviderFormState {
  provider: any
  logProcessingObj: any
  isLoading: boolean
  activationLink: string
  pieData: IObject[]
}

const chartConfig = {
  tooltip: {
    formatter: function(this: IObject): string {
      return `<span style="color:${this.color}">\u25CF</span>\n<span>${this.key}:\n</span><span>${this.y}%</span>`
    },
  },
}

class ProviderForm extends A10Container<
  IProviderFormProps,
  IProviderFormState
> {
  static getDerivedStateFromProps(
    nextProps: IProviderFormProps,
    prevState: IProviderFormState,
  ) {
    if (nextProps.activationLink !== prevState.activationLink) {
      return { activationLink: nextProps.activationLink }
    }
    return null
  }

  Messages = new Messages()
  Utilities = new Utilities()
  maxLogValue = {
    value: 0,
    unit: '',
  }

  constructor(props: IProviderFormProps) {
    super(props)

    this.state = {
      isLoading: false,
      provider: this.props.provider || {},
      logProcessingObj: this.props.logProcessingObj || {
        logProcessingType: '',
        dedicatedLogCapacity: '',
      },
      activationLink: this.props.activationLink,
      pieData: [
        {
          name: 'Reserved Pool',
          color: '#80cbc4',
        },
        {
          name: 'Remaining Dedicated Pool',
          color: '#9fa8da',
        },
        {
          name: 'Total Dedicated Pool',
          color: '#76a2d7',
        },
        {
          name: 'Shared Pool',
          color: '#b39ddb',
        },
      ],
    }
    const total = this.props.logCapacityObj['default_capacity'] || 0
    const allocated = this.props.logCapacityObj['capacity'] || 0
    const remaining =
      this.props.logCapacityObj['remaining_hc_capacity'] === undefined
        ? allocated
        : this.props.logCapacityObj['remaining_hc_capacity']
    const maxLogValue =
      remaining + (this.state.logProcessingObj.dedicatedLogCapacity || 0)
    // this.maxLogValue = this.Utilities.kiloToMillionToBillion(maxLogValue)
    this.maxLogValue.value = maxLogValue
  }

  chartTooltipFormatter = (d: IObject) => {
    const point = d.chart.hoverPoint
    const value: any = point.y || 0
    const valString = this.Utilities.truncateVal(value, 'quantity', 2)
    return (
      (point.name == 'Reserved'
        ? 'Reserved for future use, cannot be assigned to any provider<br/>'
        : '') +
      point.name +
      ' <b>' +
      valString +
      ' /sec</b>'
    )
  }

  componentDidMount() {
    this.props.onRef(this)
    this.getPieData()
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  componentDidUpdate(prevProps: IProviderFormProps) {
    if (this.props.logCapacityObj !== prevProps.logCapacityObj) {
      this.getPieData()
    }
  }

  getPieData = () => {
    const { logCapacityObj } = this.props
    const { pieData } = this.state
    const {
      shared_capacity: shared = 0,
      default_capacity: total = 0,
      capacity: maxUsable = 0,
      remaining_hc_capacity,
    } = logCapacityObj

    const totalDedicatedPool = (maxUsable - shared) || 0
    const reservedPool = (total - maxUsable) || 0
    const remainingDedicatedPool = remaining_hc_capacity || 0

    const _pieData = [...pieData]

    // _pieData[0].y = reserved
    _pieData[0].y = reservedPool > 0 ? Math.round((reservedPool * 100) / total) : 0
    // _pieData[1].y = remaining dedicated
    _pieData[1].y = remainingDedicatedPool > 0 ? Math.round((remainingDedicatedPool * 100) / total) : 0
    //_pieData[2].y = total dedicated
    _pieData[2].y = totalDedicatedPool > 0 ? Math.round((totalDedicatedPool * 100) / total) : 0
    // _pieData[3].y = shared
    _pieData[3].y = shared > 0 ? Math.round((shared * 100) / total) : 0
    

    this.setState({
      pieData: _pieData,
    })
  }

  handleInputChange = (name: string, e: Event | any) => {
    const { provider, logProcessingObj } = this.state
    let changedValue = ''

    if (e.target) {
      if (e.target.type === 'checkbox') {
        changedValue = e.target.checked
      } else {
        changedValue = e.target.value
      }
    } else {
      changedValue = e
    }

    if (name === 'logProcessingType' || name === 'dedicatedLogCapacity') {
      logProcessingObj[name] = changedValue
      this.props.handleLogCapacityChange(logProcessingObj)
      this.setState({ logProcessingObj })
    } else {
      provider[name] = changedValue
      this.props.handleChange(provider)
      this.setState({ provider })
    }
  }

  validateDedicatedLogCapacity = (rule: any, value: any, cb: any) => {
    const integerRegex = this.Utilities.INTEGER_PATTERN
    let isValid: boolean = true
    if (!value) {
      rule.message = this.Messages.ENTER_CAPACITY
      isValid = false
    } else if (value > this.maxLogValue.value) {
      rule.message =
        'Cannot exceed allowed capacity value ' +
        this.maxLogValue.value +
        ' ' +
        this.maxLogValue.unit +
        ' / Sec'
      isValid = false
    } else if (value < 0) {
      rule.message = 'Negative capacity value not allowed'
      isValid = false
    } else if (!value.match(integerRegex)) {
      rule.message = 'Only integer value allowed'
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateProviderName = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    if (!value) {
      rule.message = this.Messages.EMPTY_PROVIDER_NAME
      isValid = false
    } else if (!this.props.editMode && !this.Utilities.validateName(value)) {
      rule.message = this.Messages.INVALID_PROVIDER_NAME
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateOrgName = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    if (!value) {
      rule.message = this.Messages.CANNOT_BE_EMPTY
      isValid = false
    } else if (!this.Utilities.validateName50WithSpace(value)) {
      rule.message = this.Messages.INVALID_NAME_SPACE_50
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  providerStatus = () => {
    if (!this.props.editMode) {
      return null
    }
    const formItemLayout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
    }
    // const status = parseInt(this.state.provider['terms-accepted'], 10) === 1 ? 'ongoing' : 'warning'
    const users = this.state.provider['user-list']
    let admin =
      users &&
      users.filter((user: any) => {
        return user['user-id'] === 'provider-admin'
      })[0]
    const status = admin && admin.state === 'ACTIVE' ? 'ongoing' : 'warning'
    const statusText = status === 'ongoing' ? 'Verified' : 'Not Verified'

    return (
      <A10Form.Item {...formItemLayout}
        label={
          <span className='provider-label'>
            {this.Messages.PROVIDER_STATUS}
          </span>
        }
      >
        <div className="input-box">
          <HealthStatus type={status} hideTooltip={true} />
          <div className="input-box-content">{statusText}</div>
        </div>
      </A10Form.Item>
    )
  }

  activationLink = () => {
    const users = this.state.provider['user-list']
    let admin =
      users &&
      users.filter((user: any) => {
        return user['user-id'] === 'provider-admin'
      })[0]
    if (
      !this.props.editMode ||
      (admin && !admin['activation-token']) ||
      (admin && admin.state === 'ACTIVE')
    ) {
      return false
    }
    const formItemLayout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
    }
    const activationLink =
      typeof admin === 'undefined'
        ? ''
        : window.location.protocol +
          '//' +
          window.location.host +
          '/confirm/provider-admin/' +
          admin['activation-token'] +
          '?provider=' +
          this.state.provider.name

    return (
      <A10Form.Item
        {...formItemLayout}
        label={
          <span className='provider-label'>
            {this.Messages.ADMIN_ACTIVATION_LINK}
          </span>
        }
      >
        <div>
          <a href={activationLink} target="_blank">
            {activationLink}
          </a>
        </div>
      </A10Form.Item>
    )
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
    }

    const { getFieldDecorator } = this.props.form
    const { provider, logProcessingObj, pieData } = this.state
    const editMode = this.props.editMode
    const disabledAttr = editMode ? { disabled: true } : {}
    const totalCapacityString =
      this.Utilities.truncateVal(
        this.props.logCapacityObj['default_capacity'] || 0,
        'quantity',
        2,
      ) + ' logs/sec'

    return (
      <>
        <A10Form hideRequiredMark={true} layout="horizontal">
          <A10Panel
            className='provider-panel'
            title={
              <>
                <A10IconTextGroup
                  text={<>Administrative Info</>}
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, marginRight: 12 }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="provider-label mandatoryField">
                    {this.Messages.PROVIDER_NEW_NAME}
                  </span>
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.PROVIDER_NEW_NAME}
                      helpKey="HELP_MP_PROVIDER_NAME"
                    />
                  </span>
                </>
              }
            >
              <div>
                {getFieldDecorator('name', {
                  rules: [
                    {
                      validator: this.validateProviderName.bind(this),
                    },
                  ],
                  initialValue: provider.name,
                })(
                  <A10Input
                    {...disabledAttr}
                    type="text"
                    placeholder={this.Messages.PROVIDER_NEW_NAME}
                    onChange={this.handleInputChange.bind(this, 'name')}
                  />,
                )}
              </div>
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className='provider-label'>
                    {this.Messages.DEFAULT_ADMIN_NAME}
                  </span>
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.DEFAULT_ADMIN_NAME}
                      helpKey="HELP_MP_DEFAULT_ADMIN_NAME"
                    />
                  </span>
                </>
              }
            >
              <div>
                <A10Input
                  disabled
                  type="text"
                  placeholder={this.Messages.DEFAULT_ADMIN_NAME}
                  value={
                    provider.name == 'root' ? 'super-admin' : 'provider-admin'
                  }
                />
              </div>
            </A10Form.Item>

            {provider.name == 'root' ? null : (
              <>
                {this.providerStatus()}
                {this.activationLink()}
              </>
            )}
          </A10Panel>

          <A10Panel
            className='provider-panel'
            title={
              <>
                <A10IconTextGroup
                  text={<>Organization Info</>}
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, marginRight: 12 }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="provider-label mandatoryField">
                    {
                      <>
                        {this.Messages.ORG_NAME}
                        <span>
                          <HelpInfo
                            customCls="custom-tooltip-input"
                            placement="left"
                            title={this.Messages.ORG_NAME}
                            helpKey="HELP_MP_ORGANIZATION_NAME"
                          />
                        </span>
                      </>
                    }
                  </span>
                </>
              }
            >
              <div>
                {getFieldDecorator('organization-name', {
                  rules: [
                    {
                      validator: this.validateOrgName.bind(this),
                    },
                  ],
                  initialValue: provider['organization-name'],
                })(
                  <A10Input
                    type="text"
                    placeholder={this.Messages.ORG_NAME}
                    onChange={this.handleInputChange.bind(
                      this,
                      'organization-name',
                    )}
                  />,
                )}
              </div>
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <span className="provider-label">
                  {this.Messages.DESCRIPTION}
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.DESCRIPTION}
                      helpKey="HELP_MP_DESCRIPTION"
                    />
                  </span>
                </span>
              }
            >
              <div>
                {getFieldDecorator('description', {
                  initialValue: provider.description,
                })(
                  <A10Input.TextArea
                    placeholder={this.Messages.ORG_DESCRIPTION_PLACEHOLDER}
                    onChange={this.handleInputChange.bind(this, 'description')}
                  />,
                )}
              </div>
            </A10Form.Item>
          </A10Panel>

          <A10Panel
            className='provider-panel'
            title={
              <>
                <A10IconTextGroup
                  text={<>Primary Contact Info </>}
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, marginRight: 12 }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className='provider-label'>
                    {this.Messages.NAME}
                  </span>
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.NAME}
                      helpKey="HELP_MP_PRIMARY_CONTACT_NAME"
                    />
                  </span>
                </>
              }
            >
              <div>
                <A10Row>
                  <A10Col span={12}>
                    <A10Input
                      type="text"
                      defaultValue={provider['contact-first-name']}
                      placeholder={this.Messages.USER_FIRSTNAME}
                      onChange={this.handleInputChange.bind(
                        this,
                        'contact-first-name',
                      )}
                    />
                  </A10Col>
                  <A10Col span={12} className="left-pad">
                    <A10Input
                      type="text"
                      defaultValue={provider['contact-last-name']}
                      placeholder={this.Messages.USER_LASTNAME}
                      onChange={this.handleInputChange.bind(
                        this,
                        'contact-last-name',
                      )}
                    />
                  </A10Col>
                </A10Row>
              </div>
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className='provider-label'>
                    {this.Messages.EMAIL}
                  </span>
                  <span className="mandatoryField"></span>
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.EMAIL}
                      helpKey="HELP_MP_PRIMARY_CONTACT_EMAIL"
                    />
                  </span>
                </>
              }
            >
              <div>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      validator: this.Utilities.validateMandatoryEmail,
                    },
                  ],
                  initialValue: provider.email,
                })(
                  <A10Input
                    type="text"
                    placeholder={this.Messages.PROVIDER_EMAIL_PLACEHOLDER}
                    onChange={this.handleInputChange.bind(this, 'email')}
                  />,
                )}
              </div>
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className='provider-label'>
                    {this.Messages.CONTACT_NUMBER}
                  </span>
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.CONTACT_NUMBER}
                      helpKey="HELP_MP_PRIMARY_CONTACT_NUMBER"
                    />
                  </span>
                </>
              }
            >
              <div>
                {getFieldDecorator('organization-phone-number', {
                  rules: [
                    {
                      validator: this.Utilities.validateContactNumber,
                      message: this.Messages.INVALID_CONTACT_NUMBER,
                    },
                  ],
                  initialValue: provider['organization-phone-number'],
                })(
                  <A10Input
                    type="text"
                    placeholder={this.Messages.CONTACT_NUMBER}
                    onChange={this.handleInputChange.bind(
                      this,
                      'organization-phone-number',
                    )}
                  />,
                )}
              </div>
            </A10Form.Item>
          </A10Panel>

          <A10Panel
            title={
              <>
                <A10IconTextGroup
                  text={this.Messages.LOG_P_C}
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, marginRight: 12 }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className='provider-label'>
                    {this.Messages.LOG_P_C}
                  </span>
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.LOG_P_C}
                      helpKey="HELP_MP_PROVIDER_LOG_PROCESSING_TYPE"
                    />
                  </span>
                </>
              }
            >
              <div className="row">
                <div className="col-md-10">
                  <A10Radio.Group
                    name="type"
                    defaultValue={logProcessingObj['logProcessingType']}
                    value={logProcessingObj['logProcessingType']}
                    onChange={this.handleInputChange.bind(
                      this,
                      'logProcessingType',
                    )}
                  >
                    <A10Radio value="dedicated">
                      <A10Tooltip
                        placement="top"
                        title="Dedicated Capacity is the sum total of all capacities allocated to Providers of type 'Dedicated.'"
                        trigger="hover"
                        overlayClassName="popoverinfo"
                      >
                        Dedicated
                      </A10Tooltip>
                    </A10Radio>
                    <A10Radio value="shared">
                      <A10Tooltip
                        placement="top"
                        title="Shared capacity is all the leftover usable capacity not allocated to 'Dedicated' Providers. This capacity is shared fairly between all Providers based on their instantaneous traffic levels."
                        trigger="hover"
                        overlayClassName="popoverinfo"
                      >
                        Shared
                      </A10Tooltip>
                    </A10Radio>
                  </A10Radio.Group>
                </div>
              </div>
            </A10Form.Item>

            {logProcessingObj['logProcessingType'] === 'dedicated' ? (
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    <span className='provider-label'>
                      {this.Messages.DEDICATED_CAPACITY}
                    </span>
                    <span>
                      <HelpInfo
                        customCls="custom-tooltip-input"
                        placement="left"
                        title={this.Messages.DEDICATED_CAPACITY}
                        helpKey="HELP_MP_PROVIDER_LOG_PROCESSING_CAPACITY"
                      />
                    </span>
                  </>
                }
              >
                <div className="row">
                  <div className="col-md-8">
                    {getFieldDecorator('dedicatedLogCapacity', {
                      rules: [
                        {
                          validator: this.validateDedicatedLogCapacity.bind(
                            this,
                          ),
                        },
                      ],
                      initialValue: logProcessingObj['dedicatedLogCapacity'] || '0',
                    })(
                      <A10Input
                        type="number"
                        max={this.maxLogValue.value}
                        min={0}
                        step={1}
                        placeholder={this.Messages.ENTER_CAPACITY}
                        onChange={this.handleInputChange.bind(
                          this,
                          'dedicatedLogCapacity',
                        )}
                        addonAfter="/ Sec."
                      />,
                    )}
                  </div>
                </div>
              </A10Form.Item>
            ) : null}

            <div className="divider-line" />

            <A10Row style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '450px' }}>
                <PieChart
                  name=""
                  description=""
                  data={{
                    series: [
                      {
                        name: '',
                        data: pieData,
                      },
                    ],
                  }}
                  unit="%"
                  legend={true}
                  chartConfig={chartConfig}
                />
                <div className="total-cap-text">
                  <label>
                    Total Capacity{' '}
                    <span className="total-cap-val">{totalCapacityString}</span>
                  </label>
                </div>
              </div>
            </A10Row>
          </A10Panel>
        </A10Form>
      </>
    )
  }
}

export default setupA10Container(A10Form.create()(ProviderForm))
