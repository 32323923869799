import {
  A10AflexCodeEditor,
  A10Button,
  A10Col,
  A10Icon,
  A10Input,
  A10Row,
  A10Tree,
  A10Popover,
} from '@gui-libraries/widgets'
import React, { useState, useEffect } from 'react'
import { ContentSection } from 'src/components/shared/ContentSection'
import { TroubleShootService, Utilities } from 'src/services/index'
import Card from 'src/components/shared/Card'
import ReactLoading from 'react-loading'
import styles from './styles/index.module.less'
import './styles/index.less'

interface IRunCommandsProps {}
const troubleshootService = new TroubleShootService()
const utilities = new Utilities()

export const getTargetsData = async () => {
  let apiResponse: IObject[] = []
  try {
    const { data: response } = await troubleshootService.getRunTargets(
      null,
      null,
      null,
    )
    apiResponse = response
  } catch (error) {
    apiResponse = []
  }

  return apiResponse
}

export const getCommandList = async (targets: string[]) => {
  let apiResponse: IObject[] = []
  try {
    const { data: response } = await troubleshootService.getRunCommands(
      null,
      null,
      targets,
    )
    apiResponse = response
  } catch (error) {
    apiResponse = []
  }

  return apiResponse
}

export const handleRunCommandPost = async (payload: IObject) => {
  let apiResponse: IObject = null
  try {
    const response = await troubleshootService.postRunCommands(
      null,
      payload,
      null,
    )
    if (response) {
      utilities.showMessage('SUCCESS_RUN_COMMAND', 1, 1)
      apiResponse = response
    }
  } catch {
    utilities.showMessage('FAILED_RUN_COMMAND', 0, 1)
  }

  return apiResponse
}

export const buildCommandsArray = (inputArray: IObject[]) => {
  const commandsArray: IObject[] = []
  inputArray.forEach((input: IObject) => {
    const { command, params, separator } = input
    const commandParams = params.join(separator)
    const commandObj = {
      key: `${command} ${commandParams}`.trim(),
      title: `${command} ${commandParams}`.trim(),
      ...input,
    }
    commandsArray.push(commandObj)
  })

  return commandsArray
}

const prepareTreeArray = (targetArray: IObject[]) => {
  const targetsArray = targetArray.map((target: IObject) => {
    let children: IObject[] = []
    if (target.subCategories.length > 0) {
      const subCategoryArray = target.subCategories.map(
        (subCategory: IObject) => {
          return {
            key: subCategory,
            title: subCategory,
          }
        },
      )
      children = subCategoryArray
    }
    return {
      key: target.category,
      title: target.category,
      children: children,
    }
  })
  return targetsArray
}

const RunCommands: React.FC<IRunCommandsProps> = props => {
  const [isLoading, setLoading] = useState(false)
  const [isTargetLoading, setTargetLoading] = useState(false)
  const [inputText, setInputText] = useState('')
  const [targets, setTargets] = useState(null)
  const [commandList, setCommandList] = useState([])
  const [output, setOutput] = useState('')
  const [sarchText, setSearchText] = useState('')
  const [selectedCommand, setSelectedCommand] = useState(null)
  const [showPopover, setShowPopover] = useState(false)
  const [targetedValue, setTargetedValue] = useState([])

  useEffect(() => {
    async function fetchTargetsData() {
      setTargetLoading(true)
      const data: IObject[] = await getTargetsData()
      const targets = prepareTreeArray(data)
      setTargets(targets)
      setTargetLoading(false)
    }

    fetchTargetsData()
  }, [])

  const handleInputChange = (event: string) => {
    setInputText(event)
  }

  const onTargetSelection = async (selectedTargets: string[]) => {
    const payload: string[] = []
    setTargetedValue(selectedTargets)

    selectedTargets.forEach((target: string) => {
      payload.push(`category=${target}`)
    })
    const commands: IObject[] = await getCommandList([payload.join('&')])
    const commandArray = buildCommandsArray(commands)
    if (selectedTargets.length > 0) {
      setCommandList(commandArray)
    } else {
      setCommandList([])
    }
  }

  const handleRunCommand = async () => {
    const postCommandObject = { ...selectedCommand }
    postCommandObject.userInput = inputText
    delete postCommandObject?.key
    delete postCommandObject?.title
    setLoading(true)

    const response = await handleRunCommandPost(postCommandObject)
    const logID = response?.data
    if (logID) {
      let status: string
      let newStatus: string = 'RUNNING'
      let outputInfo = null
      const timeout = (postCommandObject.timeOutInSeconds * 1000) / 10
      do {
        const {
          data: outputData,
        } = await troubleshootService.getRunCommandStatus(null, null, logID)

        newStatus = outputData?.status
        if (status !== newStatus) {
          status = newStatus
        }
        await new Promise(r => setTimeout(r, timeout))
        outputInfo = outputData
      } while (newStatus === 'PENDING')

      setOutput(outputInfo.output)
      setInputText('')
    }
    setLoading(false)
    setSelectedCommand(null)
  }

  const getCommands = () =>
    commandList &&
    commandList.filter((command: IObject) => {
      return command?.key?.toLowerCase()?.includes(sarchText?.toLowerCase())
    })

  const renderDrodownList = () => {
    const filteredCommandList = getCommands()

    return (
      <A10Row>
        <A10Input.Search
          style={{ width: '340px', marginTop: '8px', marginLeft: '10px' }}
          type="text"
          Name="Command"
          placeholder="Command"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchText(e?.target?.value)
          }
          value={sarchText}
        />
        <A10Row className={styles.commandListSearch}>
          {filteredCommandList &&
            filteredCommandList.map((data: IObject) => {
              return (
                <A10Col
                  className={styles.commandList}
                  onClick={() => {
                    setInputText(data.key)
                    setSelectedCommand(data)
                    setShowPopover(false)
                    setOutput('')
                  }}
                  key={data.key}
                  span={18}
                >
                  <A10Icon
                    type="function"
                    app="global"
                    className={styles.listIcon}
                  />
                  <li key={data.key} value={data.key}>
                    {data.title}
                  </li>
                </A10Col>
              )
            })}
        </A10Row>
      </A10Row>
    )
  }

  return (
    <ContentSection>
      <A10Row style={{ marginBottom: '30px' }}>
        <A10Col span={9}>
          <A10Row className={styles.headingRow}>
            <span>Select Target</span>
          </A10Row>
          <A10Row className={styles.selectTargetRow}>
            <A10Row className={styles.selectTarget}>
              {isTargetLoading ? (
                <A10Col>
                  <ReactLoading
                    type="bars"
                    color="#4a90e2"
                    height={40}
                    width={40}
                  />
                </A10Col>
              ) : (
                <A10Tree
                  checkable
                  multiple={true}
                  showIcon={true}
                  testID="a10-tree"
                  disabled={isLoading}
                  onCheck={(checkedKeys: string[], info) => {
                    onTargetSelection(checkedKeys)
                  }}
                  treeData={targets}
                  checkedKeys={targetedValue}
                ></A10Tree>
              )}
            </A10Row>
          </A10Row>
        </A10Col>
        <A10Col
          span={15}
          className={styles.codeEditorDiv}
          style={{ paddingLeft: '10px' }}
        >
          <A10Row className={styles.headingRow}>
            <span>Run Command</span>
          </A10Row>
          <A10Row style={{ display: 'flex' }}>
            <A10Col span={8}>
              <A10Popover
                content={renderDrodownList()}
                trigger="click"
                visible={showPopover}
                placement="bottomLeft"
                onVisibleChange={() => setShowPopover(!showPopover)}
                overlayClassName={'run-command-command-list'}
              >
                <A10Row style={{ marginBottom: '4px' }}>
                  <A10Col style={{ paddingTop: '10px' }}>
                    <span className={styles.dropdownIcon}>
                      <A10Icon type="list-view" app="global" />
                      Command List
                      <A10Icon type="down" />
                    </span>
                  </A10Col>
                </A10Row>
              </A10Popover>
            </A10Col>
            <A10Col offset={13} span={3}>
              <A10Button
                type="primary"
                className={styles.runButton}
                testID="run-command-button"
                onClick={handleRunCommand}
                disabled={!inputText}
              >
                Run
              </A10Button>
            </A10Col>
          </A10Row>
          <A10AflexCodeEditor
            id="run-command"
            value={inputText}
            clearWhenEmpty={true}
            testID="aflex-code-editor"
            options={{ lineNumbers: true, readOnly: false }}
            onAFlexChange={(e: any) => handleInputChange(e)}
          />
        </A10Col>
      </A10Row>
      <A10Row className={styles.codeEditorDiv}>
        <A10Row className={styles.outputField}>
          <span>Output :</span>
        </A10Row>
        <Card className={styles.statusCard} title="" showTitleDashLine={true}>
          {isLoading ? (
            <A10Col>
              <ReactLoading
                type="bars"
                color="#4a90e2"
                height={40}
                width={40}
              />
            </A10Col>
          ) : (
            <div className={styles.statusBody}>{output}</div>
          )}
        </Card>
      </A10Row>
    </ContentSection>
  )
}

export default RunCommands
