import axios from 'axios'
import parameters from 'parameters'

import * as basicUtils from '../../containers/Controller/Dashboard/utils/basicUtils'
import storage from 'src/libraries/storage'

export class SessionManagementUtil {
  redirectToHC = () => {
    const provider = storage.get.PROVIDER
    window.location.href = `/login/${provider || 'root'}`
  }

  destroySession = (sessionId: any) => {
    const headers = {
      Authorization: storage.get.ENCODED_SESSION_ID,
      provider: storage.get.PROVIDER,
      'x-account': basicUtils.getTenantUuids(),
      tenant: storage.get.TENANT,
    }
    const baseURL = parameters.BASE_URL
    axios({
      method: 'DELETE',
      url: `${baseURL}/sessions/${sessionId}`,
      headers,
    })
      .then(res => {
        console.log('Successfully destroyed old session: ', res)
      })
      .catch(err => {
        console.error('Error in destroying old session: ', err)
      })
  }

  handleLogout = () => {
    const currSession = storage.get.ENCODED_SESSION_ID || undefined
    const currSessnId = (currSession && currSession.split(' ')[1]) || undefined
    this.destroySession(currSessnId)
    this.redirectToHC()
  }

  isUserIdle = (currentTime: number) => {
    const noActivityTimeout = 55 * 60 * 1000 // If user is inactive for 1 hour, check if we can logout the user
    // const noActivityTimeout = 20 * 1000
    const LAST_TIME_CLICKED = JSON.parse(storage.get.LAST_CLICKED_TIMESTAMP)
    return currentTime - LAST_TIME_CLICKED > noActivityTimeout
  }
}

export default SessionManagementUtil
