import React from 'react'

import { AlertNotification } from 'src/components/shared/AlertNotification'
import { WorkflowNotification } from 'src/components/shared/WorkflowNotification'
import { NotificationSlidingPage } from 'src/components/shared/NotificationSlidingPage'
import { WebSocketAlert } from 'src/components/shared/Header/WebSocketAlert'

import styles from './styles/index.module.less'

export interface INotificationProps {}

export interface INotificationState {
  alertData: IObject[]
  alertDataEndTime: number
  alertClearPoint: number
  workflowData: IObject[]
  workflowDataEndTime: number
  workflowClearPoint: number
  showNotificationPage: boolean
  notificationPageType: string
}

export default class Notification extends React.Component<
  INotificationProps,
  INotificationState
> {
  state: INotificationState = {
    alertData: [],
    alertDataEndTime: 0,
    alertClearPoint: Date.now(),
    workflowData: [],
    workflowDataEndTime: 0,
    workflowClearPoint: Date.now(),
    showNotificationPage: false,
    notificationPageType: 'alerts',
  }

  updateAlerts = (data: IObject[], endTime: number) => {
    this.setState({ alertData: data, alertDataEndTime: endTime })
  }

  updateWorkflows = (data: IObject[], endTime: number) => {
    this.setState({ workflowData: data, workflowDataEndTime: endTime })
  }

  handleCloseSlidingPage = (type: string) => {
    const { alertClearPoint, workflowClearPoint } = this.state
    const current = Date.now()

    this.setState({
      showNotificationPage: false,
      alertClearPoint: type === 'alerts' ? current : alertClearPoint,
      workflowClearPoint: type === 'workflows' ? current : workflowClearPoint,
    })
  }

  render() {
    const { showNotificationPage, notificationPageType } = this.state

    return (
      <>
        <div className={styles.notification}>
          <div
            onClick={() => {
              this.setState({
                showNotificationPage: true,
                notificationPageType: 'alerts',
              })
            }}
          >
            <AlertNotification
              clearPoint={this.state.alertClearPoint}
              onUpdateAlerts={this.updateAlerts}
            />
          </div>
          <div
            onClick={() => {
              this.setState({
                showNotificationPage: true,
                notificationPageType: 'workflows',
              })
            }}
          >
            <WorkflowNotification
              clearPoint={this.state.workflowClearPoint}
              onUpdateWorkflows={this.updateWorkflows}
            />
          </div>
        </div>
        <NotificationSlidingPage
          isOpen={showNotificationPage}
          activeTab={notificationPageType}
          tabs={{
            alert: {
              data: this.state.alertData,
              dataEndTime: this.state.alertDataEndTime,
            },
            workflow: {
              data: this.state.workflowData,
              dataEndTime: this.state.workflowDataEndTime,
            },
          }}
          onRequestClose={this.handleCloseSlidingPage}
        />
        <WebSocketAlert />
      </>
    )
  }
}
