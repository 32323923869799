import React from 'react'
import parameters from 'parameters'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  setItem,
  getItem,
  _,
} from '@gui-libraries/framework'
import { DGF, DGFProvider } from '@gui-libraries/dgf'

import {
  ContentSection,
  ContentBody,
} from 'src/components/shared/ContentSection'
import storage from 'src/libraries/storage'
import LogContent from '../LogContent'

export interface IEventProps extends IA10ContainerDefaultProps {
  selectedTenant?: string
}
export interface IEventState {
  dgfParameter: GuiDGF.HCParameters
  dashboard: IObject
}

const logConfig = [
  {
    name: 'HC Events',
    viz: [
      {
        app: 'adc',
        dataSource: 'RPT',
        endPoint: 'thunder-adc',
        metrics: 'system_logs_to_hc',
        name: 'Events',
      },
    ],
  },
]

class Event extends A10Container<IEventProps, IEventState> {
  constructor(props) {
    super(props)

    const tenant = storage.get.CURRENT_TENANT

    if (props.selectedTenant && !getItem('SELECTED_CONTEXT')) {
      setItem('SELECTED_CONTEXT', getItem('CURRENT_TENANT'))
    }

    if (!props.selectedTenant && !getItem('SELECTED_CONTEXT')) {
      const providerId = getItem('PROVIDER_ID')
      setItem('SELECTED_CONTEXT', {
        id: providerId,
        name: getItem('PROVIDER'),
        uuid: providerId,
      })
    }

    //get all clusters and tenants and modify the KEY-NAME-LIST in session storage for uuid/name mapping which is used from log panel
    this.setTenantClusterNamesMapping()

    this.state = {
      dgfParameter: {
        auth: storage.get.ENCODED_SESSION_ID,
        api: parameters.BASE_URL,
        provider: storage.get.PROVIDER,
        username: storage.get.USER_ID,
        tenant: tenant.name,
        tenantUUID: tenant.uuid,
        providerId: storage.get.PROVIDER_ID,
      },
      dashboard: {
        name: 'Event',
        description: '',
        context: props.selectedTenant ? 'tenant-custom' : 'provider-custom',
        isDrillDown: false,
      },
    }
  }

  //save the keys for tenant name/uuid mapping and cluster name/uuid mapping
  setTenantClusterNamesMapping() {
    const existingKeyMapping = sessionStorage.getItem('KEY-NAME-LIST')
    const keyList = existingKeyMapping ? JSON.parse(existingKeyMapping) : {}
    const clusters = storage.get.ALLCLUSTERS
    const tenants = storage.get.ALLTENANTS
    if (clusters?.length) {
      // get cluster
      for (const cluster of clusters) {
        const storageKey = `cluster.${cluster.uuid}`
        if (!keyList[storageKey]) {
          keyList[storageKey] = {
            name: cluster.name,
            'display-name': cluster['display-name'] || cluster.name,
            type: 'cluster',
          }
        }

        if (cluster['cluster-uuid']) {
          const storageKey = `cluster.${cluster['cluster-uuid']}`
          if (!keyList[storageKey]) {
            keyList[storageKey] = {
              name: cluster.name,
              'display-name': cluster['display-name'] || cluster.name,
              type: 'cluster',
            }
          }
        }
      }
    }
    if (tenants?.length) {
      // get tenant
      for (const tenant of tenants) {
        const storageKey = `tenant.${tenant.uuid}`
        if (!keyList[storageKey] || _.isEmpty(keyList[storageKey])) {
          keyList[storageKey] = {
            name: tenant.name,
            'display-name': tenant['display-name'] || tenant.name,
            type: 'tenant',
          }
        }
      }
    }
    sessionStorage.setItem('KEY-NAME-LIST', JSON.stringify(keyList))
  }

  componentDidUpdate = (prevProps: IObject) => {
    if (prevProps.selectedTenant !== this.props.selectedTenant) {
      this.setState(prevState => ({
        dgfParameter: {
          ...prevState.dgfParameter,
          tenant: storage.get.CURRENT_TENANT.name,
          tenantUUID: storage.get.CURRENT_TENANT.uuid,
          providerId: storage.get.PROVIDER_ID,
        },
        dashboard: {
          ...prevState.dashboard,
          context: this.props.selectedTenant
            ? 'tenant-custom'
            : 'provider-custom',
        },
      }))
    }
  }

  renderDashboard = (Dashboard: React.ReactNode) => {
    const { selectedTenant } = this.props
    return (
      <LogContent
        logConfig={logConfig}
        selectedTenant={selectedTenant}
        title={'Event'}
      />
    )
  }

  render() {
    const { dgfParameter, dashboard } = this.state
    return (
      <ContentSection>
        <ContentBody>
          <DGFProvider platform="hc" parameters={dgfParameter}>
            <DGF
              mode={'PROD'}
              dashboard={dashboard}
              renderDashboard={this.renderDashboard}
            />
          </DGFProvider>
        </ContentBody>
      </ContentSection>
    )
  }
}
export default setupA10Container(Event)
