import React from 'react'
import { Redirect } from 'react-router-dom'
import parameters from 'parameters'
import { A10Icon, A10Menu } from '@gui-libraries/widgets'
import { _ } from '@gui-libraries/framework'

import storage from 'src/libraries/storage'
import {
  DashboardService,
  OrganizationService,
  Utilities,
} from 'src/services/index'
import { AppRoot } from 'src/settings/appRoot'
import { AppConfigs } from 'src/constants/ApplicationConfigs/AppConfigs'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { LaunchAppForm } from 'src/containers/Controller/Apps/InstalledApps/Forms/LaunchAppForm'

import styles from './styles/index.module.less'
import { APPLICATION_NAMES_MAP } from 'src/constants/ApplicationConfigs/ApplicationNamesMap'

export interface ILeftNavAppsProps {
  options?: string[]
  defaultOption?: string
  selectedTenant?: any
  refreshHCApps?: boolean
  leftNavcollapsed?: boolean
  inlineIndent?: number
  level?: number
  allApps: IObject[]
}

export interface IOption {
  key: string
  text: string
  versions?: boolean
  separator?: boolean
}

export interface ILeftNavAppsState {
  optionList: IOption[]
  currentIndex: number
  showDropMenu: boolean
  appsLoaded: boolean
  appsAPICall: boolean
  selectedTenant: any
  refreshHCApps: boolean
  showSlidingPage: boolean
  showThunderLadcSlidingPage: boolean
  appObjData: any
  redirectUrl: string
  selectedKeys: any
  appToLaunch: string
}

class LeftNavApps extends React.Component<
  ILeftNavAppsProps,
  ILeftNavAppsState
> {
  DashboardService = new DashboardService()
  OrganizationService = new OrganizationService()
  Utilities = new Utilities()
  AppRoot = new AppRoot()
  AppConfigs = new AppConfigs()

  drillLevel = storage.get.DRILL_LEVEL
  isSuperUser = storage.get.IS_SUPER_USER

  constructor(props: ILeftNavAppsProps) {
    super(props)
    const options = props.options || [
      'adc',
      'lightning',
      'cgn',
      'ssli',
      'gtp',
      'gifw',
      'object-explorer',
    ]
    const optionList = this.AppConfigs.APPS.filter((item: any) => {
      return options.some(option => {
        return option === item.key
      })
    })
    let defaultIndex = 0
    optionList.forEach((item: any, index: number) => {
      if (item.key === props.defaultOption) {
        defaultIndex = index
      }
    })
    this.state = {
      optionList,
      currentIndex: defaultIndex,
      showDropMenu: false,
      appsLoaded: false,
      appsAPICall: false,
      selectedTenant: this.props.selectedTenant,
      refreshHCApps: this.props.refreshHCApps,
      showSlidingPage: false,
      showThunderLadcSlidingPage: false,
      appObjData: {
        tenant: {},
        appService: '',
        app: {},
      },
      redirectUrl: '',
      selectedKeys: [],
      appToLaunch: null
    }
  }

  setSlidingPage = (isOpen: boolean, stateName: string) => {
    // @ts-ignore
    this.setState({
      [stateName]: isOpen,
    })
  }

  handleChange = (data: any) => {
    // @ts-ignore
    this.setState({
      appObjData: {
        ...data,
        tenant: JSON.parse(data.tenant),
      },
    })
  }

  loadMyApps = async () => {
    if (!storage.get.CURRENT_TENANT.uuid) {
      const tenants = this.AppRoot.getRootScopeElement('allTenants')
      storage.set.CURRENT_TENANT(tenants[0])
      this.AppRoot.setRootScopeElement('currentTenant', tenants[0])
    }

    this.setState({
      appsAPICall: true,
      selectedTenant: this.props.selectedTenant,
      refreshHCApps: this.props.refreshHCApps,
    })
  }

  loadAppServices = async () => {
    const currentTenant = storage.get.CURRENT_TENANT
    const tenant = currentTenant?.name || this.state.appObjData.tenant.name
    const headers = {
      Authorization: storage.get.ENCODED_SESSION_ID,
      provider: storage.get.PROVIDER,
      'Content-Type': 'application/json',
      tenant,
    }

    return this.DashboardService.getApplications(headers, null, null)
  }

  loadActiveThunderRuleSet = async (
    appServices: any[],
    appServiceType: 'gifw' | 'gtp',
  ) => {
    const nowTime = new Date().getTime()
    let appSvcIds = ''
    appServices.forEach(appSvc => {
      const { app_svc_type: appSvcType } = appSvc
      if (
        (appServiceType === 'gifw' &&
          (appSvcType === 'gifw' ||
            appSvcType === 'gifirewall' ||
            appSvcType === 'fw')) ||
        (appServiceType === 'gtp' &&
          (appSvcType === 'gtpfw' || appSvcType === 'gtp-fw'))
      ) {
        appSvcIds += (appSvcIds.length > 0 ? '|' : '') + appSvc.app_svc_id
      }
    })
    appSvcIds = appSvcIds.trim()

    const currentTenant = storage.get.CURRENT_TENANT
    const tenantUuid = currentTenant?.uuid || this.state.appObjData.tenant.uuid
    const headers = {
      'x-account': tenantUuid,
    }
    const payload = {
      state: {
        fields: ['o_oper_state', 'app_svc_id', 'ts'],
        rangeby: {
          start: nowTime - 5 * 60 * 1000,
          end: nowTime,
          field: 'ts',
        },
        sort: 'asc',
        size: appServices.length,
        filterby: {
          and: {
            app_svc_id: appSvcIds,
          },
        },
      },
    }

    return this.DashboardService.activeThunderRuleSet(headers, payload, null)
  }

  componentDidUpdate(prevProps: any) {
    const {
      appObjData,
      appToLaunch
    } = this.state
      if (
          (appToLaunch === 'gifw') ||
          (appToLaunch === 'gtp')
        ) { 
      appObjData.tenant = appObjData.tenant || storage.get.CURRENT_TENANT
      this.launchAppURLPage(appObjData)
    }
  }

  changeApp = (value: string) => {
    switch (value) {
      case 'thunder':
        this.selectLADCThunderAppService(
          'Thunder ADC',
          'TADCManageApp',
          'TADCApp',
        )
        break
      case 'object-explorer':
        this.setState({ redirectUrl: '/launchers/object-explorer' })
        break
      case 'lightning':
        this.selectLADCThunderAppService(
          'Lightning ADC',
          'LADCAppList',
          'LADCApp',
        )
        break
      case 'goe':
        this.setState({ redirectUrl: '/launchers/goe' })
        break
      case 'controller':
        this.setState({ redirectUrl: '/controller/infrastructure' })
        break
    }
  }

  onClickOption = (key: string, index: number) => (event: any) => {
    if ('lightning' === key || 'thunder' === key) {
      this.changeApp(key)
    } else {
      this.setState({ currentIndex: index }, () => {
        if (key !== this.props.defaultOption) {
          this.changeApp(key)
        }
      })
    }
  }
  onControlDropMenu = (show: boolean) => (event: any) => {
    return this.setState({ showDropMenu: show })
  }

  handleAppClick = (app: any, key: string) => {
    // gifw and gtp app launch are handled separately in componentDidUpdate
    if (!storage.get.CURRENT_TENANT.uuid || (key !== 'gifw' && key !== 'gtp')) {
      this.prepareAppLaunch(app, key)
    } else {
      this.setState({
        appToLaunch: key,
        appObjData: {
          tenant: {},
          appService: '',
          app,
        },
      })
    }
  }

  prepareAppLaunch = async (app: any, key: string) => {
    const appObjData = {
      tenant: {},
      appService: '',
      app,
    }

    if (!!storage.get.CURRENT_TENANT.uuid) {
      this.launchAppURLPage(appObjData)
      appObjData.tenant = storage.get.CURRENT_TENANT
      return
    }

    this.setState({
      showSlidingPage: true,
      showThunderLadcSlidingPage: false,
      appObjData,
    })
  }

  launchAppURLPage = async (appObjData: any) => {
    const providerName = storage.get.PROVIDER
    const tenantObj = appObjData.tenant?.uuid
      ? appObjData.tenant
      : storage.get.CURRENT_TENANT
    const appSvcObj = appObjData.appService
      ? JSON.parse(appObjData.appService)
      : ''

    const sessionTimeout = storage.get.SESSION_TIMEOUT_INITIAL
    const currTimestamp = new Date().getTime()
    const ipv6Url = this.Utilities.checkIPv6BaseURL()
    const baseURL = ipv6Url
      ? parameters.BASE_URL.replace(':8443/api/v2', '') + encodeURI('/hcapps')
      : encodeURI(parameters.BASE_URL.replace(':8443/api/v2', '') + '/hcapps')
    const sessionId = await this.Utilities.getSessionId()
    const queryString =
      '?user_id=' +
      storage.get.USER_ID +
      '&api_ep=' +
      encodeURI(parameters.BASE_URL) +
      '&provider=' +
      providerName +
      '&token=' +
      sessionId +
      '&timeout=' +
      (sessionTimeout ? sessionTimeout : '1') +
      (tenantObj.name ? '&tenant=' + tenantObj.name : '') +
      (tenantObj.uuid ? '&tenantUUID=' + tenantObj.uuid : '') +
      (appSvcObj.app_svc_id
        ? '&app_svc=' +
          appSvcObj.name +
          '&app_service_id=' +
          appSvcObj.app_svc_id +
          '&app_svc_type=' +
          appSvcObj.app_svc_type +
          '&action=config'
        : '')

    const href = `${baseURL}/${appObjData.app.name}_${appObjData.app.version}${queryString}&ts=${currTimestamp}`

    window.open(href, '_blank')

    this.setState({
      appToLaunch: null,
      showSlidingPage: false,
      showThunderLadcSlidingPage: false,
    })
  }

  handleOk = async () => {
    const { appObjData } = this.state
    const {
      app: { name: appName },
      tenant,
    } = appObjData
    if (!tenant?.uuid) {
      this.Utilities.showMessage('Please select tenant', 0, 0)
      return
    }
    const appLicense: any = true
    // this.state.appObjData.app.name !== 'ssli'
    //   ? this.Utilities.checkAppLicenseAndLaunch(
    //       JSON.parse(this.state.appObjData.appService),
    //     )
    //   : true
    if (appLicense) {
      setTimeout(
        () => {
          if (appName !== 'gifw' && appName !== 'gtp') {
            this.launchAppURLPage(appObjData)
            this.setState({
              showSlidingPage: false,
              showThunderLadcSlidingPage: false,
            })
          } else {
            // tenant can change, clear activeRuleSet to invoke getActiveRuleSet
            this.setState({
              appToLaunch: appName,
              showSlidingPage: false,
              showThunderLadcSlidingPage: false,
            })
          }
        },
        appLicense === 'warning' ? 5000 : 0,
      )
    }
  }

  handleLADCThunderOk = () => {
    if (!this.state.appObjData.tenant?.uuid) {
      this.Utilities.showMessage('Please select tenant', 0, 0)
      return
    }
    if (
      this.state.appObjData.app.launchModeValue === 'TADCManageApp' &&
      !this.state.appObjData.appService
    ) {
      this.Utilities.showMessage('NO_APP_SERVICE', 0, 1)
      return
    }

    const that = this
    that.launchLADCThunderApp(
      that.state.appObjData.app.launchModeValue === 'TADCManageApp'
        ? 'TADCManageApp'
        : 'LADCAppList',
      that.state.appObjData.tenant,
      that.state.appObjData.app.appValue,
    )
    that.setState({
      showSlidingPage: false,
      showThunderLadcSlidingPage: false,
    })
  }

  launchLADCThunderApp = async (
    launchModeValue: string,
    tenantValue: any,
    appValue: string,
  ) => {
    const currentTenant = tenantValue
    const sessionTimeout = storage.get.SESSION_TIMEOUT_INITIAL
    let params: any = {
      ACCOUNT_NAME: currentTenant.name,
      ENCODED_SESSION_ID: storage.get.ENCODED_SESSION_ID,
      LAST_CLICKED_TIMESTAMP: new Date().getTime(),
      LOCAL_USER: storage.get.LOCAL_USER,
      PROVIDER: storage.get.PROVIDER,
      PROVIDER_ID: storage.get.PROVIDER_ID,
      PROVIDER_PATH: storage.get.PROVIDER_PATH,
      SAAS: true,
      TARGET_URL: storage.get.TARGET_URL,
      CURRENT_STATE: storage.get.CURRENT_STATE,
      USER_ID: storage.get.USER_ID,
      APPMODEPAGE: launchModeValue,
      APPMODEVALUE: appValue,
      CURRENT_APPLICATION_TYPE: '',
      CURRENT_APPLICATION: '',
      APPLICATION_SVC_ID: '',
      TIMEOUT: sessionTimeout ? sessionTimeout : '1',
    }
    if ('TADCManageApp' === launchModeValue) {
      const appService = JSON.parse(this.state.appObjData.appService)
      params.APPLICATION_SVC_ID = appService.app_svc_id
      params.CURRENT_APPLICATION_TYPE = appService.app_svc_type
      params.CURRENT_APPLICATION = appService.name
      params.CURRENT_APPLICATION_PROTOCOL = appService.protocol
      params.DEVICE_CLUSTER_ID = appService.device_cluster_id
    }
    params = JSON.stringify(params)
    params = params.replace(/\//g, 'S@REP@L')
    const url =
      'https:' +
      '//' +
      window.location.hostname +
      ':7443/#/ladcApp?value=' +
      params
    window.open(url, '_blank')
  }
  selectLADCThunderAppService = (
    appName: any,
    launchModeValue: string,
    appValue: string,
  ) => {
    if (storage.get.CURRENT_TENANT.uuid && appValue === 'LADCApp') {
      this.launchLADCThunderApp(
        launchModeValue,
        storage.get.CURRENT_TENANT,
        appValue,
      )
      return
    }
    this.setState({
      showSlidingPage: false,
      showThunderLadcSlidingPage: true,
      appObjData: {
        tenant: storage.get.CURRENT_TENANT.uuid
          ? storage.get.CURRENT_TENANT
          : '',
        appService: '',
        app: {
          name: appName,
          thunderApp: appValue === 'TADCApp' ? 'slb' : '',
          appValue,
          launchModeValue,
        },
      },
    })
  }

  onSubMenuClick = (selectedKey: any) => {
    this.setState({
      selectedKeys: [selectedKey.key],
    })
  }

  renderRedirect = (url: string) => {
    return <Redirect to={url} />
  }

  renderApps = () => {
    const {
      children,
      leftNavcollapsed,
      selectedTenant,
      allApps,
      ...restProps
    } = this.props
    const { optionList } = this.state

    let filterdOptionList = optionList
    const adminlevel = storage.get.ADMIN_LEVEL
    if (adminlevel !== 'provider') {
      filterdOptionList = optionList.filter(
        app =>
          allApps.findIndex(
            item => app.key === APPLICATION_NAMES_MAP[item?.name]?.key,
          ) > -1,
      )
    }

    return filterdOptionList.map((appObj: any, index: number) => {
      allApps.sort((a: any, b: any) => {
        return this.Utilities.sortAppVersionNumber(
          a?.version?.split('-')[0]?.split('.') || [0],
          b?.version?.split('-')[0]?.split('.') || [0],
        )
      })
      allApps.sort((a: any, b: any) => {
        return this.Utilities.sortAppVersionNumber(
          a?.version?.split('v')[1]?.split('.') || [0],
          b?.version?.split('v')[1]?.split('.') || [0],
        )
      })
      const filteredApps = allApps.filter((app: any) => {
        let appExists = false
        if (appObj.appName && appObj.appName.length > 0) {
          appExists = appObj.appName.some((appName: string) => {
            if (appName === app.name) {
              return true
            }
            return false
          })
        }
        return appObj.key === app.name || appExists
      })

      filteredApps.sort((a: any, b: any) => {
        return this.Utilities.sortAppVersionNumber(
          a?.version?.split('-')[0]?.split('.') || [0],
          b?.version?.split('-')[0]?.split('.') || [0],
        )
      })
      filteredApps.sort((a: any, b: any) => {
        return this.Utilities.sortAppVersionNumber(
          a?.version?.split('v')[1]?.split('.') || [0],
          b?.version?.split('v')[1]?.split('.') || [0],
        )
      })

      return appObj.versions ? (
        filteredApps && filteredApps.length > 0 ? (
          <A10Menu.Item
            {...restProps}
            key={appObj.key + '-' + filteredApps[0].version}
            eventKey={appObj.key + '-' + filteredApps[0].version}
            onClick={() => {
              this.handleAppClick(filteredApps[0], appObj.key)
            }}
            className="menu-item-container"
          >
            <A10Icon
              className={styles.appIconContainer}
              app="app-icons"
              type={appObj.icon}
              islight={true}
              withoutHover={true}
            />
            <span className="text-span">{appObj.text}</span>
          </A10Menu.Item>
        ) : null
      ) : (
        <A10Menu.Item
          {...restProps}
          key={appObj.key}
          eventKey={appObj.key}
          onClick={
            !appObj.versions
              ? this.onClickOption(appObj.key, index) // onClickOption.bind(this, item.key, index)
              : undefined
          }
          className={`menu-item-container ${
            appObj.key == 'object-explorer' ? 'separator' : ''
          }`}
        >
          <A10Icon
            className={styles.appIconContainer}
            app="app-icons"
            type={appObj.icon}
            islight={true}
            withoutHover={true}
          />
          <span>{appObj.text}</span>
        </A10Menu.Item>
      )
    })
  }

  render() {
    const { redirectUrl } = this.state
    if (redirectUrl) {
      return <Redirect to={redirectUrl} />
    }
    const appLaunchAppsvc = this.Utilities.checkAppForLaunchWOAppsvc(
      this.state.appObjData.app,
      'NOR',
    )

    return (
      <>
        {this.renderApps()}
        <FormatSlidingPage
          isOpen={this.state.showSlidingPage}
          title={
            this.state.appObjData.app.name +
            ' ' +
            this.state.appObjData.app.version
          }
          description={
            'Please select ' +
            (this.drillLevel === 'provider' ? 'Tenant' : '') +
            (appLaunchAppsvc
              ? this.drillLevel === 'provider'
                ? ' & App service'
                : 'an App service'
              : '')
          }
          onRequestClose={this.setSlidingPage.bind(
            this,
            false,
            'showSlidingPage',
          )}
          saveText="Proceed"
          onRequestOk={() => this.handleOk()}
        >
          <LaunchAppForm
            launchAppObj={this.state.appObjData}
            handleChange={this.handleChange}
            appLaunchWOApp={true}
          />
        </FormatSlidingPage>
        <FormatSlidingPage
          isOpen={this.state.showThunderLadcSlidingPage}
          title={this.state.appObjData?.app?.name}
          description={
            this.drillLevel === 'provider' &&
            this.state.appObjData?.app?.launchModeValue === 'TADCManageApp'
              ? 'Please select Tenant & App service'
              : this.drillLevel !== 'provider' &&
                this.state.appObjData?.app?.launchModeValue === 'TADCManageApp'
              ? 'Please select an App service'
              : 'Please select Tenant'
          }
          onRequestClose={this.setSlidingPage.bind(
            this,
            false,
            'showThunderLadcSlidingPage',
          )}
          saveText="Proceed"
          onRequestOk={() => this.handleLADCThunderOk()}
        >
          <LaunchAppForm
            isLADCAppLaunch={
              this.state.appObjData?.app?.launchModeValue === 'TADCManageApp'
                ? false
                : true
            }
            isThunderAppLaunch={
              this.state.appObjData?.app?.launchModeValue === 'TADCManageApp'
                ? true
                : false
            }
            launchAppObj={this.state.appObjData}
            handleChange={this.handleChange}
          />
        </FormatSlidingPage>
      </>
    )
  }
}

export default LeftNavApps
