import React from 'react'
import {
  A10Container,
  _,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Notification } from '@gui-libraries/widgets'

import { occurArray } from 'src/libraries/arrayUtils'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import Associate from './Associate'
import { getItem } from '../../../../../libraries/storage'

export interface IWrapperProps extends IA10ContainerDefaultProps {
  isShow: boolean
  tenant: string
  logicalPartition: string
  name: string
  type: string
  existVIPs: IObject[]
  onTriggerSwitch: (
    isOpen: boolean,
    record?: any,
    needRefresh?: boolean,
  ) => void
}

export interface IWrapperState {
  isShow: boolean
  currentSelectedNames: string[]
  vipNames: string[]
}

class Wrapper extends A10Container<IWrapperProps, IWrapperState> {
  constructor(props: any) {
    super(props)
    this.state = {
      isShow: false,
      currentSelectedNames: [],
      vipNames: [],
    }
  }

  componentWillMount() {
    this.init(this.props)
  }

  componentWillReceiveProps(nextProps: any) {
    this.init(nextProps)
  }

  init = (props: any) => {
    const { existVIPs = [] } = props
    const vipNames = existVIPs.map((item: IObject) => {
      return item.name
    })
    this.setState({ vipNames })
  }

  getHttpClient = () => {
    const {
      GLOBAL_CONFIG: {
        EPIC_DEPENDENCIES: { httpClient },
      },
    } = this.props
    return httpClient
  }

  getTemplateName = () => {
    const { type } = this.props
    const splitTypes = type.split('.')
    const templateType = splitTypes[splitTypes.length - 1]
    return `template-${templateType}`
  }

  closeSlidingPage = () => {
    const { onTriggerSwitch = _.noop } = this.props
    onTriggerSwitch(false)
  }

  okSlidingPage = async () => {
    const { onTriggerSwitch = _.noop, name } = this.props
    const { currentSelectedNames, vipNames } = this.state

    const occur = occurArray(currentSelectedNames, vipNames)
    if (occur.removeList && occur.removeList.length > 0) {
      for (const vipName of occur.removeList) {
        await this.bindUnbindPolicy(vipName, false)
      }
    }
    if (occur.newList && occur.newList.length > 0) {
      for (const vipName of occur.newList) {
        await this.bindUnbindPolicy(vipName, true)
      }
    }
    A10Notification.success({
      message: 'Success!',
      description: `Assign ${name} to VIP(s).`,
    })
    onTriggerSwitch(false, null, true)
  }

  bindUnbindPolicy = async (vipName: string, bind: boolean) => {
    const { tenant, logicalPartition, name } = this.props
    const templateType = this.getTemplateName()
    console.log(templateType)
    const vsApi = `/hpcapi/v3/provider/${getItem(
      'PROVIDER',
    )}/tenant/${tenant}/logical-partition/${logicalPartition}/slb/virtual-server`
    const httpClient = this.getHttpClient()
    const {
      data: { 'virtual-server': vs },
    } = await httpClient.get(`${vsApi}/${vipName}`)
    const vipUuid = vs.uuid
    let newVs = vs
    if (bind) {
      newVs[templateType] = name
    } else {
      newVs = _.omit(vs, templateType)
    }
    const updateApi = `/hpcapi/v3/uuid/${vipUuid}`
    await httpClient.put(updateApi, { 'virtual-server': newVs })
  }

  onChangeSelectedValue = (selectedValues: string[]) => {
    this.setState({ currentSelectedNames: selectedValues })
  }

  render() {
    const { isShow, tenant, name } = this.props
    return (
      <FormatSlidingPage
        isOpen={isShow}
        onRequestClose={this.closeSlidingPage}
        onRequestOk={this.okSlidingPage}
        saveText="Assign"
        title={`${name} > Associate a VIP`}
        description="Please select a VIP to associate."
      >
        <Associate
          tenant={tenant}
          existVIPs={this.state.vipNames}
          onChangeSelectedValue={this.onChangeSelectedValue}
        />
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(Wrapper)
