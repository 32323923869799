import React from 'react'
import { Map } from 'immutable'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import { A10Row, A10Col, A10Icon } from '@gui-libraries/widgets'
import { Viz, VizContext } from '@gui-libraries/viz'
import moment from 'moment'

import * as analysisUtils from 'src/containers/Controller/Dashboard/utils'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import Settings from 'src/containers/Controller/Dashboard/Settings'
import { WorkflowList } from './WorkflowList'
// import { RoundNumber } from 'src/components/shared/RoundNumber'
import { FilterList } from 'src/components/shared/FilterList'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

const { Timeline } = Viz
export interface IWorkflowProps extends IA10ContainerDefaultProps {
  workflowInfo: IObject
  tenantToggled: boolean
}

export interface IUpWorkflowState {
  isLoading: boolean
  checkedFilter: string
  startTime: number
  endTime: number
}

class Workflow extends A10Container<IWorkflowProps, IUpWorkflowState> {
  state: IUpWorkflowState = {
    isLoading: false,
    checkedFilter: null,
    startTime: Date.now() - 6 * 3600 * 1000,
    endTime: Date.now(),
  }
  filterConfig = [
    {
      key: 'CLUSTER',
      label: 'Cluster',
      icon: <A10Icon app="harmony-controller" type="logical-cluster" />,
    },
    {
      key: 'DEVICE',
      label: 'Device',
      icon: <A10Icon app="harmony-controller" type="devices" />,
    },
    {
      key: 'LOGICAL_PARTITION',
      label: 'Logical Partition',
      icon: <A10Icon app="harmony-controller" type="tenant" />,
    },
    {
      key: 'DEVICE_PARTITION',
      label: 'Device Partition',
      icon: <A10Icon app="harmony-controller" type="app-service" />,
    },
  ]

  componentDidMount() {
    this.getWorkflowData({})
  }

  componentDidUpdate(prevProps: IWorkflowProps) {
    if (prevProps.tenantToggled !== this.props.tenantToggled) {
      this.getWorkflowData({})
    }
  }

  onPageChange = (start: number, size: number) => {
    this.getWorkflowData({
      pager: { pageStart: start, pageSize: size },
    })
  }

  onOrderChange = (key: string, order: string) => {
    this.getWorkflowData({ sorters: { sortBy: key, order } })
  }

  onFilterChange = (filters: IObject) => {
    const { checkedFilter } = this.state

    let changedKey: string = null
    Object.keys(filters).some((key: string) => {
      if (
        (key !== checkedFilter && filters[key]) ||
        (key === checkedFilter && !filters[key])
      ) {
        changedKey = key
        return true
      }
      return false
    })

    if (changedKey === checkedFilter) {
      this.setState({ checkedFilter: null })
      this.getWorkflowData({ filters: {} })
    } else {
      this.setState({ checkedFilter: changedKey })
      this.getWorkflowData({ filters: { [changedKey]: true } })
    }
  }

  getWorkflowData = (currentConfig: IObject = {}) => {
    const {
      A10Dispatchers: { httpRequest },
      workflowInfo,
    } = this.props
    const { startTime, endTime } = this.state
    const {
      pager: prevPager = { pageStart: 0, pageSize: 10 },
      filters: prevFilters = {},
      sorters: prevSorters = { sortBy: 'createTime', order: 'desc' },
    } = workflowInfo

    const {
      pager = prevPager,
      filters = prevFilters,
      sorters = prevSorters,
    } = currentConfig

    const filterParam = {
      ...filters,
      provider: storage.get.PROVIDER,
    }
    const currentTenant = _.get(storage.get.CURRENT_TENANT, 'name')
    if (_.isString(currentTenant) && currentTenant) {
      const { LOGICAL_PARTITION } = filters
      if (LOGICAL_PARTITION === true) {
        filterParam.tenant = currentTenant
      }
    }

    this.setState({ isLoading: true })
    httpRequest({
      namespace: Settings.namespace.commonWorkflowInfo,
      request: async () => {
        const workflow = await analysisUtils.getWorkflow(
          httpClient,
          { startTime, endTime },
          pager,
          filterParam,
          sorters,
        )
        return Map(workflow)
      },
    }).finally(() => {
      this.setState({ isLoading: false })
    })
  }

  receiveAction = (action: any) => {
    const { type } = action

    switch (type) {
      case 'VIZ.TIMELINE.ONCHANGE': {
        const { startTime, endTime } = action
        const newStart =
          startTime < 1000000000000 ? startTime * 1000 : startTime
        const newEnd = endTime < 1000000000000 ? endTime * 1000 : endTime
        this.setState({ startTime: newStart, endTime: newEnd }, () => {
          this.getWorkflowData()
        })
      }
    }
  }

  customRangeDisabledDate = (date: moment.Moment) => {
    const isDateBeforeOneYearAgo =
      date <
      moment()
        .subtract(1, 'years')
        .endOf('day')
    const isDateAfterToday = date > moment().endOf('day')
    return isDateBeforeOneYearAgo || isDateAfterToday
  }

  render() {
    const {
      workflowInfo: { total = 0, pageData = [], pager = {}, sorters = {} },
    } = this.props
    const { isLoading, checkedFilter } = this.state
    const { pageStart = 0, pageSize = 10 } = pager
    const { sortBy = 'createTime', order = 'desc' } = sorters

    const context = {
      receive: this.receiveAction,
    }

    return (
      <ContentSection>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col lg={8}>
            <ContentTitle title="Workflows" count={total} />
          </A10Col>
          <A10Col lg={16}>
            <div className={styles.timelineContainer}>
              <VizContext.Provider value={context}>
                <Timeline
                  filters={{
                    timePeriod: [this.state.startTime, this.state.endTime],
                  }}
                  customRangeDisabledDate={this.customRangeDisabledDate}
                />
              </VizContext.Provider>
            </div>
          </A10Col>
        </ContentHeader>
        <ContentBody>
          <div className="pull-up-workflow-container">
            <A10Row>
              <A10Col span={5} className={styles.filterContainer}>
                <FilterList
                  filterConfig={this.filterConfig}
                  filterData={{ [checkedFilter]: true }}
                  onFilterChange={this.onFilterChange}
                  disabled={isLoading}
                />
              </A10Col>
              <A10Col span={19}>
                <WorkflowList
                  pageStart={pageStart}
                  pageSize={pageSize}
                  total={total}
                  pageData={pageData}
                  isLoading={isLoading}
                  sortKey={sortBy}
                  sortOrder={order}
                  onRefreshPage={this.getWorkflowData}
                  onPageChange={this.onPageChange}
                  onOrderChange={this.onOrderChange}
                />
              </A10Col>
            </A10Row>
          </div>
        </ContentBody>
      </ContentSection>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    workflowInfo: state.A10Data.getIn(
      Settings.namespace.commonWorkflowInfo,
      Map({}),
    ).toJS(),
  }
}
export default setupA10Container(Workflow, mapStateToProps)
