import React from 'react'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10Modal,
  A10DropdownMenu,
  A10Col,
  A10Button,
  A10Input,
} from '@gui-libraries/widgets'

import { IDefaultMethods } from 'src/containers/Controller'
// import { RoundNumber } from 'src/components/shared/RoundNumber'
import { DashboardService } from 'src/services/DashboardService'
import { ActionDetails } from 'src/containers/Controller/Monitor/Actions/ActionDetails'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { ActionAddForm } from 'src/containers/Controller/Monitor/Actions/Forms/ActionAddForm'
import Utilities from 'src/services/Utilities/Utilities'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import storage from 'src/libraries/storage'

import '../styles/monitor.scss'
import './styles/actions.scss'

export interface IActionsProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  tenantToggled: boolean
}
export interface IActionsState {
  data: any
  actionObj: any
  actionsUpdated: boolean
  searchString: string
  selectedIndex: number
  allData: any
  indexes: any
  deleteModalState: boolean
  selectedAction: any
  showSlidingPage: boolean
  formData: IObject
  editMode: boolean
  tenantToggled: boolean
  showAlertLog: boolean
  chartData: any
  chartReq: any
  disableSave: boolean
}

export const ACTION_OBJ: any = {
  actionName: '',
  email: true,
  emailToProviderAdmins: true,
  emailToTenantAdmins: true,
  users: [],
  extendedSubject: '',
  extendedMsg: '',
  post: true,
  postArr: [
    {
      uri: '',
      extended_body: '',
      headers: {},
    },
  ],
}

export const WIDGET_CONFIG_ACTION: any = {
  fields: [
    {
      key: 'rule_assoc_id',
      label: 'Actions',
    },
  ],
  aggregation: 'count',
  histogramField: 'ts',
  rangebyField: 'ts',
  // filterBy: 'delete',
  filterBy: {
    and: {
      // account_id: '',
      rule_assoc_id: '',
    },
  },
}

export const SPARKLINE_CHART_CONFIG = {
  chart: {
    height: '60px',
    width: 200,
    type: 'column',
    backgroundColor: 'transparent',
  },
  xAxis: {
    gridLineWidth: '0px',
    labels: { enabled: false },
    tickPosition: 'inside',
    type: 'datetime',
  },
  tooltip: {
    enabled: true,
  },
  colors: ['#808080'],
}

class Actions extends A10Container<IActionsProps, IActionsState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  pageLength = 5
  dataLoaded = false
  chartReq: any[] = []
  state: IActionsState = {
    data: [],
    actionObj: ACTION_OBJ,
    actionsUpdated: false,
    searchString: '',
    selectedIndex: 0,
    allData: [],
    indexes: [],
    deleteModalState: false,
    selectedAction: {},
    showSlidingPage: false,
    formData: null,
    editMode: false,
    tenantToggled: this.props.tenantToggled,
    showAlertLog: true,
    chartData: null,
    disableSave: false,
  }
  childForm: any = null
  isOperatorUser = storage.get.IS_OPERATOR_USER
  actionColumns = [
    {
      title: 'Action Name',
      //  (
      //   <div className="header-column sort-container">
      //     <div className="action-title"> Action Name</div>
      //     <i className="sort-icon" />
      //   </div>
      // ),
      dataIndex: 'def_name',
      key: 'def_name',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'def_name'),
    },
    {
      title: 'Options Enabled',
      dataIndex: 'definition',
      key: 'definition',
      render: (key: any, dataIndex: any) => {
        let email_ids = []
        if (key.email && Object.keys(key.email[0]).length > 0) {
          email_ids = key.email[0].email_ids
        }
        const uri = key.webhook

        const optionsEnabled = ['Email', 'Email, POST', 'POST', 'null']
        if (email_ids && email_ids.length > 0 && uri && uri.length > 0) {
          return <>{optionsEnabled[1]}</>
        } else if (uri && uri.length > 0) {
          return <>{optionsEnabled[2]}</>
        } else if (email_ids && email_ids.length > 0) {
          return <>{optionsEnabled[0]}</>
        } else {
          return <>{optionsEnabled[3]}</>
        }
      },
      // sorter: (a: any, key: string, otherKey: string) => {
      //   this.Utilities.sortStringObj(a, 'Email', 'POST')
      // },
    },
    // {
    //   title: (
    //     <>
    //       Occurred
    //       <div style={{ fontSize: '12px', fontWeight: 400 }}>Last 6 hours</div>
    //     </>
    //   ),
    //   dataIndex: 'occurred',
    //   key: 'occurred',
    //   render: (text: string, record: any, index: number) => {
    //     // console.log('Actions', record)
    //     // if (!record.occurred) {
    //     //   return
    //     // }

    //     const chartData = record.occurred
    //     let count = 0
    //     // if (chartData.length > 0) {
    //     //   count = chartData[0].data.reduce((total: any, currentVal: any) => {
    //     //     return total + currentVal[1]
    //     //   }, 0)
    //     // }

    //     // if (chartData.length > 0) {
    //     //   if (chartData[0].color) {
    //     //     chartData[0].color = null
    //     //   }
    //     // }

    //     return (
    //       <div key={'occurred-' + index} className="inline">
    //         <div className="col-sm-2 text-center" style={{ padding: '10px 0' }}>
    //           <A10Tag id="countTag">{count}</A10Tag>
    //         </div>
    //         <div className="col-sm-10 no-padding">
    //           <SparcLine
    //             seriesData={chartData}
    //             chartConfig={SPARKLINE_CHART_CONFIG}
    //           />
    //         </div>
    //       </div>
    //     )
    //   },
    // },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (key: any) => {
        const clickAction = (component: JSX.Element, index: number) => {
          if (component.key === 'duplicate') {
            this.editAction(key)
          }
          if (component.key === 'delete') {
            this.setState({
              deleteModalState: true,
              selectedAction: key,
            })
          } else if (component.key === 'appServices') {
            //   this.viewAppServices(key)
          }
        }
        return this.isOperatorUser ? null : (
          <div className="text-right">
            <i>
              <A10DropdownMenu
                menu={[
                  <div key="duplicate">Duplicate</div>,
                  <div key="delete">Delete </div>,
                ]}
                style={{ color: '#757575', marginBottom: '-15px' }}
                onClick={clickAction}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            </i>
          </div>
        )
      },
    },
  ]

  componentDidMount() {
    if (
      !this.dataLoaded ||
      this.state.tenantToggled !== this.props.tenantToggled
    ) {
      this.loadActions()
    }
  }
  componentDidUpdate() {
    if (this.state.tenantToggled !== this.props.tenantToggled) {
      this.loadActions()
    }
  }

  renderCondition(action: any): any {
    let conditions = 'Sessions Greater Than 0.90 50 OR % of AVG. D...'
    // if (action) {
    //   conditions = action['event-definition'].conditions;
    // }
    return <div>{conditions}</div>
  }

  loadActions = () => {
    const {
      storage: {
        get: { TARGET_URL: targetUrl = '' },
        set,
      },
    } = this.context

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const actionResponse = this.DashboardService.getActions(
      headers,
      null,
      targetUrl,
    )

    actionResponse
      .then((response: any) => {
        this.dataLoaded = true
        set.ALL_DATA(response.data)
        const [data, indexes] = this.DashboardService.getPaginatedItems(
          response.data,
          this.pageLength,
        )

        this.setState({
          allData: response.data,
          data,
          indexes,
          tenantToggled: this.props.tenantToggled,
        })
      })
      .catch((error: any) => {
        this.dataLoaded = true
        this.setState({
          allData: [],
        })
      })
  }

  receiveUpdate = () => {
    this.dataLoaded = false
    this.setState({
      actionsUpdated: true,
    })
  }

  addAction = () => {
    this.setSlidingPage(true, ACTION_OBJ)
  }
  editAction = (trigger: any) => {
    this.setSlidingPage(true, trigger, true)
  }

  setSlidingPage = (isOpen: boolean, data?: IObject, editMode?: boolean) => {
    let formDataObj = {}
    if (editMode) {
      const emailObj = data.definition.email
      const isEmailObj = emailObj.length > 0 && emailObj[0].email_ids
      const dataClone = JSON.parse(JSON.stringify(data))
      let postObj = dataClone.definition.webhook
      postObj.forEach((obj: any, index: number) => {
        const keys = Object.keys(obj.headers)
        if (keys.length > 0) {
          const headerArr: any = []
          keys.forEach((key: string, index: number) => {
            headerArr.push('"' + key + '"' + ':' + '"' + obj.headers[key] + '"')
          })
          obj.headers = headerArr.join(',')
          obj.headers = '"{' + obj.headers + '}"'
        } else {
          obj.headers = ''
        }
      })
      formDataObj = {
        actionName: data.def_name,
        email: !!isEmailObj,
        emailToProviderAdmins: true,
        emailToTenantAdmins: true,
        users: isEmailObj ? emailObj[0].email_ids : [],
        extendedSubject: isEmailObj ? emailObj[0].extended_subject : '',
        extendedMsg: isEmailObj ? emailObj[0].extended_message : '',
        post: postObj.length > 0,
        postArr:
          postObj.length > 0
            ? postObj
            : [
                {
                  uri: '',
                  extended_body: '',
                  headers: '',
                },
              ],
      }
    } else {
      formDataObj = {
        actionName: '',
        email: true,
        emailToProviderAdmins: true,
        emailToTenantAdmins: true,
        users: [],
        extendedSubject: '',
        extendedMsg: '',
        post: true,
        postArr: [
          {
            uri: '',
            extended_body: '',
            headers: '',
          },
        ],
      }
    }

    this.setState({
      showSlidingPage: isOpen,
      actionObj: data,
      formData: formDataObj,
      editMode: editMode,
      selectedAction: data,
    })
  }

  Search = (e: any) => {
    const searchStr = e.target.value
    this.Utilities.search(this, searchStr, 'def_name')
  }

  renderActionDetails = (record: any, index: number) => {
    return <ActionDetails action={record} isBasic={false} />
  }

  deleteAction = (isEditCallback: any) => {
    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const { showMessage } = this.Utilities
    const deleteAction = this.DashboardService.deleteAction(headers, null, [
      this.state.selectedAction.def_name,
    ])
    deleteAction
      .then((resp: any) => {
        if (isEditCallback) {
          isEditCallback()
        } else {
          showMessage('Action deleted successfully', 1, 0)
          this.afterDelete(true)
        }
      })
      .catch((error: any) => {
        if (isEditCallback) {
          const messsge =
            'Unable to update action. ' + error.response.data.message
          showMessage(messsge, 0, 0)
        } else {
          const messsge =
            'Unable to delete action. ' + error.response.data.message
          showMessage(messsge, 0, 0)
        }
        this.afterDelete(false)
        return false
      })
  }

  afterDelete = (loadActions: boolean) => {
    this.setState({
      deleteModalState: false,
      selectedAction: {},
    })
    if (loadActions) {
      this.dataLoaded = false
      this.loadActions()
    }
  }

  handleOk = (isEditCallback: any) => {
    const { showMessage } = this.Utilities
    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const checkDeleteDependencyAction = this.DashboardService.checkDeleteDependencyTriggerAction(
      headers,
      null,
      [this.state.selectedAction.def_id],
    )
    checkDeleteDependencyAction
      .then((resp: any) => {
        if (resp.data.dependency_exists === false) {
          this.deleteAction(isEditCallback)
          return
        }
        if (isEditCallback) {
          showMessage('Cannot edit action: Associated with an alert', 0, 0)
        } else {
          showMessage('Cannot delete action: Associated with an alert', 0, 0)
        }
        this.afterDelete(false)
      })
      .catch((error: any) => {
        if (isEditCallback) {
          const messsge =
            'Unable to edit action. ' + error.response.data.message
          showMessage(messsge, 0, 0)
        } else {
          const messsge =
            'Unable to delete action. ' + error.response.data.message
          showMessage(messsge, 0, 0)
        }
        this.afterDelete(false)
        return false
      })
    return true
  }

  handleCancel = () => {
    this.setState({
      deleteModalState: false,
      selectedAction: {},
    })
  }

  handleAdd = (isEdit: boolean) => {
    const {
      actionName,
      users,
      extendedSubject,
      extendedMsg,
      postArr,
      email,
      post,
    } = this.state.actionObj

    const { showMessage } = this.Utilities
    const tenant = this.Utilities.getCurrentTenantObject()

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const emailObj = email
      ? {
          email_ids: users,
          extended_subject: extendedSubject,
          extended_message: extendedMsg,
        }
      : {}
    postArr.forEach((element: any) => {
      if (element.headers === '') {
        element.headers = {}
      }
    })
    const actionPayload: any = {
      def_name: actionName,
      account_id: tenant.name,
      definition: {
        email: [emailObj],
        webhook: postArr.length && post > 0 ? postArr : [],
      },
      type: 'add',
    }

    actionPayload.filter = {
      account_id: tenant.name,
    }

    const actionAdd = this.DashboardService.addAction(
      headers,
      actionPayload,
      storage.get.TARGET_URL,
    )
    actionAdd
      .then((response: any) => {
        // if (isEdit) {
        //   showMessage('ACTION_UPDATE_SUCCESS', 1, 1)
        // } else {
        //   showMessage('ACTION_ADD_SUCCESS', 1, 1)
        // }
        showMessage('ACTION_ADD_SUCCESS', 1, 1)
        this.setSlidingPage(false)
        this.dataLoaded = false
        this.loadActions()
      })
      .catch((error: any) => {
        // if (isEdit) {
        //   showMessage('ACTION_UPDATE_FAILED', 1, 1)
        // } else {
        //   showMessage('ACTION_ADD_FAILED', 0, 1, error.message)
        // }
        showMessage('ACTION_ADD_FAILED', 0, 1, error.message)
      })
    return false
  }

  handleUpdate = () => {
    this.handleAdd(true)
  }

  handleSave = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  handleChange = (data: any, isEdit: boolean) => {
    // @ts-ignore
    let disableSave = false
    if (!(data.email || data.post)) {
      disableSave = true
    }
    this.setState({
      actionObj: data,
      disableSave,
    })
  }

  // chart graph for occured

  // getOccurredChartData = (key: any, timePeriod?: moment.Moment[]) => {
  //   if (this.chartReq.indexOf(key.def_id) > -1) {
  //     return
  //   }
  //   this.chartReq.push(key.def_id)

  //   let now: any = moment().valueOf()
  //   let startTime = moment()
  //     .subtract(30, 'minutes')
  //     .valueOf() // 30 minutes
  //   if (timePeriod && timePeriod.length > 1) {
  //     now = timePeriod[1].valueOf()
  //     startTime = timePeriod[0].valueOf()
  //   }
  //   let query = this.Utilities.buildQuery(WIDGET_CONFIG_ACTION, startTime, now)
  //   query.filterby.and.rule_assoc_id = key.def_id
  //   // query.filterby.and.account_id = this.tenant.uuid
  //   query.type = 'event'
  //   const eventQuery = {
  //     event: query,
  //   }

  //   const eventsResp = this.DashboardService.getHCEvents(null, eventQuery, null)

  //   eventsResp.then((resp: any) => {
  //     const chartData = this.Utilities.parseChartData(
  //       resp.data['event'],
  //       WIDGET_CONFIG_ACTION,
  //     )

  //     key.occurred = chartData
  //     key.timePeriod = [startTime, now]
  //     this.setState({
  //       chartData,
  //     })
  //   })
  // }

  loadHCEvents = () => {
    let now = new Date().getTime()
    let payload: any = {
      events: {
        fields: ['rule_def_id'],
        aggregation: 'count',
        rangeby: {
          start: now - 6 * 60 * 60 * 1000,
          end: now,
          field: 'timestamp',
        },
        filterby: {
          and: {
            // account_id: tenant.uuid,
          },
        },
        histogram: {
          field: 'timestamp',
          interval: 60000,
        },
      },
    }
    // const hcEventsRes = this.DashboardService.getHCEvents(null, payload, null)
    // hcEventsRes
    //   .then((resp: any) => {
    //     console.log(resp.data);
    //     this.setState({
    //       hcEvents: resp.data
    //     });
    //   })
    //   .catch((error: any) => {

    //   })
  }

  render() {
    const { showSlidingPage, allData } = this.state
    const currentTenantName = this.Utilities.getCurrentTenantName()

    return (
      <>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col>
            <ContentTitle
              title="Actions"
              count={allData.length}
            />
          </A10Col>
          <A10Col style={{ display: 'flex' }}>
            <A10Input.Search
              type="text"
              onChange={this.Search}
              name="searchBox"
              value={this.state.searchString}
              placeholder="Search"
              style={{ marginRight: '6px' }}
            />
            {this.isOperatorUser ? null : (
              <ActionButton
                text="Create Action"
                onClick={this.addAction.bind(this)}
                iconProps={{ app: 'global', type: 'add-new' }}
              />
            )}
          </A10Col>
        </ContentHeader>
        <ContentBody>
          <A10Table
            columns={this.actionColumns}
            expandedRowRender={this.renderActionDetails}
            dataSource={this.state.allData.map((key: any, index: number) => {
              key.key = index
              if (key.bandwidth === undefined || key.requests === undefined) {
                // this.getStats(key)
                return key
              } else {
                return key
              }
            })}
            scroll={{ x: true }}
            size="small"
            loading={
              !this.dataLoaded
                ? {
                    indicator: (
                      <div>
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
          />
        </ContentBody>
        <A10Modal
          title="Delete Action"
          visible={this.state.deleteModalState}
          onOk={this.handleOk.bind(this, false)}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk.bind(this, false)}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            Do you want to delete '
            {this.state.selectedAction && this.state.selectedAction.def_name
              ? this.state.selectedAction.def_name
              : ''}
            ' from {currentTenantName}?
          </p>
        </A10Modal>
        <FormatSlidingPage
          isOpen={showSlidingPage}
          onRequestOk={this.handleSave}
          disableSave={this.state.disableSave}
          onRequestClose={this.setSlidingPage.bind(this, false)}
          title={'Create Action'}
          description=""
        >
          {this.state.editMode ? (
            <ActionAddForm
              handleChange={this.handleChange}
              defaultMethods={this.props.defaultMethods}
              onSubmitForm={this.handleUpdate}
              actions={this.state.allData}
              isEditMode={this.state.editMode}
              formData={this.state.formData}
              onRef={(ref: any): any => (this.childForm = ref)}
            />
          ) : (
            <ActionAddForm
              handleChange={this.handleChange}
              defaultMethods={this.props.defaultMethods}
              onSubmitForm={this.handleAdd}
              actions={this.state.allData}
              isEditMode={this.state.editMode}
              formData={this.state.formData}
              onRef={(ref: any): any => (this.childForm = ref)}
            />
          )}
        </FormatSlidingPage>
      </>
    )
  }
}
export default setupA10Container(Actions)
