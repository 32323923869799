import React from 'react'
import { A10Col } from '@gui-libraries/widgets'

import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import styles from './styles/index.module.less'

interface IStatusProps {}

const Status: React.FC<IStatusProps> = props => {
  const onClick = () => {}

  return (
    <>
      <ContentHeader type="flex" align="middle" justify="space-between">
        <A10Col>
          <ContentTitle title="Base" />
        </A10Col>
        <A10Col>
          <ActionButton
            text="Refresh"
            onClick={onClick}
            iconProps={{ app: 'global', type: 'refresh' }}
          />
        </A10Col>
      </ContentHeader>
      <ContentBody className={styles.contentBody}>Base</ContentBody>

      <ContentHeader type="flex" align="middle" justify="space-between">
        <ContentTitle title="Analytics" />
      </ContentHeader>
      <ContentBody className={styles.contentBody}>Analytics</ContentBody>

      <ContentHeader type="flex" align="middle" justify="space-between">
        <ContentTitle title="Ceph" />
      </ContentHeader>
      <ContentBody>Ceph</ContentBody>
    </>
  )
}

export default Status
