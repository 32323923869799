import React from 'react'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Table,
  A10DropdownMenu,
  A10Tooltip,
} from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import httpClient from 'src/libraries/httpClient'
import WorkflowTooltip from 'src/components/shared/WorkflowTooltip'
import SlidingLPDeploy from 'src/components/shared/LogicalPartitionDeploy/slidingForm'
import SlidingVersionCompare from 'src/components/shared/VersionComparison/slidingForm'
import SlidingWorkflowStatus from 'src/components/shared/WorkflowStatus/slidingForm'
import SlidingPartitionComment from 'src/components/shared/PartitionComment/slidingForm'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { ObjectExplorer } from 'src/components/shared/ObjectExplorer'
// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

export interface IDevicePartitionProps extends IA10ContainerDefaultProps {
  data: any
  lpUUID: string
  onRefresh: (update?: boolean) => void
  isLoading?: boolean
}

export interface IDevicePartitionState {
  uuid: string
  partitionObj: IObject
  showEditObject: boolean
  showAssociateDeploySlidingPage: boolean
  showVersionCompareSlidingPage: boolean
  showWorkflowStatusSlidingPage: boolean
  showSaveCommentSlidingPage: boolean
  workflowID: string
}

class DevicePartitionList extends A10Container<
  IDevicePartitionProps,
  IDevicePartitionState
> {
  defaultDevicePartitionColumns: any[]

  constructor(props: IDevicePartitionProps) {
    super(props)
    this.state = {
      uuid: '',
      partitionObj: {},
      showEditObject: false,
      showAssociateDeploySlidingPage: false,
      showVersionCompareSlidingPage: false,
      showWorkflowStatusSlidingPage: false,
      showSaveCommentSlidingPage: false,
      workflowID: '',
    }
    this.defaultDevicePartitionColumns = [
      {
        title: 'Device',
        dataIndex: 'device-name',
        key: 'device-name', // TODO: change this key
        render: (text: any, record: any) => {
          return (
            <WorkflowTooltip
              objUUID={record.uuid}
              workflowType="DEPLOY"
              textDisplayIntact={true}
              title={text}
              size={1}
              parent={record}
              viewWorkflow={this.onViewWorkflowStatus}
            />
          )
        },
      },
      // {
      //   title: (
      //     <span title="The version of configuration eligible to be pushed to a device">
      //       Candidate Version
      //     </span>
      //   ),
      //   render: (text: any, record: any) => {
      //     if (record['candidate-version']) {
      //       const vNum = record['candidate-version']
      //       return (
      //         <>
      //           <span>{'v ' + vNum}</span>
      //           {record.dirty ? (
      //             <span
      //               className={styles.warningIcon}
      //               title="config has been modified"
      //             />
      //           ) : null}
      //         </>
      //       )
      //     } else if (record.version) {
      //       const vNum = record.version
      //       return (
      //         <>
      //           <span>{'v ' + vNum}</span>
      //           {record.dirty ? (
      //             <span
      //               className={styles.warningIcon}
      //               title="config has been modified"
      //             />
      //           ) : null}
      //         </>
      //       )
      //     }
      //     return (
      //       <>
      //         <span className={styles.emptyDisplay}>None</span>
      //         {record.dirty ? (
      //           <span
      //             className={styles.warningIcon}
      //             title="config has been modified"
      //           />
      //         ) : null}
      //       </>
      //     )
      //   },
      // },
      // {
      //   title: (
      //     <span title="The version of configuration currently running in the device">
      //       Running Version
      //     </span>
      //   ),
      //   render: (text: any, record: any) => {
      //     if (record['running-version']) {
      //       return 'v ' + record['running-version']
      //     }
      //     return <span className={styles.emptyDisplay}>None</span>
      //   },
      // },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (record: any) => {
          const menuItems = [<div key="explorer">Explore Config</div>]
          // if (record.dirty) {
          //   menuItems = [
          //     <div key="explorer">Explore Config</div>,
          // <div key="save">Save</div>,
          // <div key="compare">Compare</div>,
          // <div key="deploy" style={{ cursor: 'not-allowed', opacity: 0.6 }}>
          //   Deploy
          // </div>,
          //   ]
          // } else {
          //   menuItems = [
          //     <div key="explorer">Explore Config</div>,
          // <div key="save" style={{ cursor: 'not-allowed', opacity: 0.6 }}>
          //   Save{' '}
          // </div>,
          // <div key="compare">Compare</div>,
          // <div key="deploy">Deploy</div>,
          //   ]
          // }
          return (
            <div className={styles.editColumn}>
              {storage.get.ADMIN_LEVEL === 'provider' &&
              !storage.get.IS_OPERATOR_USER ? (
                <i>
                  <A10DropdownMenu
                    menu={menuItems}
                    style={{ color: '#757575', marginBottom: '-15px' }}
                    onClick={this.onClickAction.bind(this, record)}
                    trigger="hover"
                    placement="bottomRight"
                    arrowPointAtCenter={true}
                  />
                </i>
              ) : null}
            </div>
          )
        },
      },
    ]
  }

  onViewWorkflowStatus = (id: string, partition: IObject) => {
    this.setState({
      partitionObj: partition,
      workflowID: id,
      showWorkflowStatusSlidingPage: true,
    })
  }

  onClickAction = (record: any, component: JSX.Element) => {
    if (component.key === 'explorer') {
      storage.set['object.explorer.device.text'](record?.['device-name'] || '')
      storage.set['object.explorer.partition.name'](record?.name || '')
      this.setState({
        showEditObject: true,
        partitionObj: record,
      })
    } else if (component.key === 'save') {
      this.setState({
        showSaveCommentSlidingPage: true,
        partitionObj: record,
      })
    } else if (component.key === 'compare') {
      this.setState({
        showVersionCompareSlidingPage: true,
        partitionObj: record,
      })
    } else if (component.key === 'deploy') {
      this.setState({
        showAssociateDeploySlidingPage: true,
        partitionObj: record,
      })
    }
  }

  onCloseDeployWindow = (
    clickDeploy: boolean = false,
    hasDP: boolean = true,
    workflowID?: string,
  ) => {
    if (clickDeploy && workflowID) {
      this.setState({ showAssociateDeploySlidingPage: false }, () => {
        setTimeout(() => {
          this.setState({ showWorkflowStatusSlidingPage: true, workflowID })
        }, 1000)
      })
    } else {
      this.setState({ showAssociateDeploySlidingPage: false })
      this.props.onRefresh()
    }
  }

  onCloseVersionCompareWindow = (
    clickDeploy: boolean = false,
    workflowID?: string,
  ) => {
    if (clickDeploy && workflowID) {
      this.setState({ showVersionCompareSlidingPage: false }, () => {
        setTimeout(() => {
          this.setState({ showWorkflowStatusSlidingPage: true, workflowID })
        }, 1000)
      })
    } else {
      this.setState({ showVersionCompareSlidingPage: false })
      setTimeout(() => {
        this.props.onRefresh()
      }, 1000)
    }
  }

  onCloseWorkflowStatusWindow = () => {
    this.setState({ showWorkflowStatusSlidingPage: false }, () => {
      this.props.onRefresh()
    })
  }

  onCloseSaveCommentWindow = async (continueDeploy: boolean = false) => {
    const { partitionObj } = this.state
    if (continueDeploy) {
      const {
        data: { partition: record },
      } = await httpClient.get(partitionObj['a10-url'])
      record['device-name'] = partitionObj['device-name']
      record.parent = partitionObj.parent
      this.setState(
        { partitionObj: record, showSaveCommentSlidingPage: false },
        () => {
          setTimeout(() => {
            this.setState({ showAssociateDeploySlidingPage: true })
          }, 1000)
        },
      )
    } else {
      this.setState({ showSaveCommentSlidingPage: false })
      this.props.onRefresh(true)
    }
  }

  setEditObject = (isOpen: boolean) => {
    this.setState({ showEditObject: isOpen })
    this.props.onRefresh(true)
  }

  render() {
    const { data } = this.props
    const { partitionObj = {} } = this.state
    const parentPartitionObj = partitionObj?.parent ?? {}
    return (
      <>
        <A10Table
          className="hc-list"
          columns={this.defaultDevicePartitionColumns}
          dataSource={data.map((item: IObject, index: number) => {
            item.key = index
            return item
          })}
          size="small"
          loading={
            this.props.isLoading
              ? {
                  indicator: (
                    <div className="loading-icon">
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    </div>
                  ),
                }
              : false
          }
        />
        {!!partitionObj ? (
          <FormatSlidingPage
            isOpen={this.state.showEditObject}
            isShowFooterButtons={false}
            isRightCancel={true}
            onRequestOk={this.setEditObject.bind(this, false)}
            onRequestClose={this.setEditObject.bind(this, false)}
            title="Explorer"
            description={
              <>
                <div style={{ color: '#999999' }}>
                  Cluster
                  <A10Tooltip
                    title={parentPartitionObj.name}
                    placement="topLeft"
                  >
                    <span className="titlePadFntWgt">
                      {parentPartitionObj.cluster
                        ? parentPartitionObj.cluster.length > 20
                          ? parentPartitionObj.cluster.slice(0, 20) + '...'
                          : parentPartitionObj.cluster
                        : ''}
                    </span>
                  </A10Tooltip>
                  <A10Icon
                    type="right"
                    className="iconPad open-explorer-breadcrumb-separator"
                  />
                  Device
                  <A10Tooltip
                    title={parentPartitionObj.name}
                    placement="topLeft"
                  >
                    <span className="titlePadFntWgt">
                      {parentPartitionObj.name
                        ? parentPartitionObj.name.length > 20
                          ? parentPartitionObj.name.slice(0, 20) + '...'
                          : parentPartitionObj.name
                        : ''}
                    </span>
                  </A10Tooltip>
                  <A10Icon
                    type="right"
                    className="iconPad open-explorer-breadcrumb-separator"
                  />
                  Partition
                  <span className="titlePadFntWgt">{partitionObj.name}</span>
                </div>
              </>
            }
          >
            <ObjectExplorer
              singleDevice={parentPartitionObj}
              singleDeviceSelectedPartition={partitionObj}
            />
          </FormatSlidingPage>
        ) : null}

        <SlidingLPDeploy
          type="device"
          isOpen={this.state.showAssociateDeploySlidingPage}
          onRequestClose={this.onCloseDeployWindow}
          partition={partitionObj}
          additionalID={this.props.lpUUID}
        />
        <SlidingVersionCompare
          isOpen={this.state.showVersionCompareSlidingPage}
          type="device"
          onRequestClose={this.onCloseVersionCompareWindow}
          partition={this.state.partitionObj}
          additionalID={this.props.lpUUID}
        />
        <SlidingWorkflowStatus
          isOpen={
            this.state.showWorkflowStatusSlidingPage &&
            this.state.workflowID !== '' &&
            !!partitionObj
          }
          onRequestClose={this.onCloseWorkflowStatusWindow}
          source="DP"
          type="deploy"
          id={this.state.workflowID}
          assocObject={partitionObj}
          showMessage={true}
        />
        <SlidingPartitionComment
          isOpen={this.state.showSaveCommentSlidingPage}
          onRequestClose={this.onCloseSaveCommentWindow}
          type="device"
          partition={partitionObj}
        />
      </>
    )
  }
}

export default setupA10Container(DevicePartitionList)
