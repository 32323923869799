import {
  A10Collapse,
  A10DropdownMenu,
  A10Icon,
  A10Row,
  A10Table,
} from '@gui-libraries/widgets'
import React from 'react'
import * as _ from 'lodash'
import RoundNumber from 'src/components/shared/RoundNumber'
export interface IRoutingOSPFProps {
  config: IObject[]
}
const RoutingOSPF: React.FC<IRoutingOSPFProps> = props => {
  const { config = [] } = props

  const editOspf = () => {}

  const clickDeleteOspf = () => {}
  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return `OSPF ${record['process-id']}`
        },
      },
      {
        title: 'Router ID',
        dataIndex: 'router_id',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return _.get(record, 'router-id.value') || 'N/A'
        },
      },
      {
        title: 'Ref Bandwidth',
        dataIndex: 'ref_bandwidth',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return _.get(record, 'auto-cost-reference-bandwidth') || 'N/A'
        },
      },
      {
        title: 'BFD all I/F',
        dataIndex: 'bfd_all',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return _.get(record, 'bfd-all-interfaces') ? 'True' : 'False'
        },
      },
      {
        title: 'Default Metric',
        dataIndex: 'address',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return _.get(record, 'default-metric') || 'N/A'
        },
      },
      {
        title: 'Distance Value',
        dataIndex: 'address',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return _.get(record, 'distance.distance-value') || 'N/A'
        },
      },
      {
        title: 'Networks',
        dataIndex: 'networks',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return _.get(record, 'network-list', []).length
        },
      },

      {
        title: 'Partition',
        dataIndex: 'address',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return _.get(record, 'router-id.value') || 'N/A'
        },
      },
      {
        dataIndex: 'actions',
        width: 30,
        render: (text: any, record: any) => {
          return (
            <A10DropdownMenu
              title=""
              menu={[
                <div key="edit" onClick={editOspf.bind(this, record.ifnum)}>
                  Edit
                </div>,
                <div
                  key="delete"
                  onClick={clickDeleteOspf.bind(this, record.ifnum)}
                >
                  Delete
                </div>,
              ]}
              style={{ color: '#757575', marginBottom: '-15px' }}
              trigger="hover"
              placement="bottomRight"
              arrowPointAtCenter={true}
            />
          )
        },
      },
    ],
    [],
  )

  return (
    <div className="col-md-12 marginBottom10">
      <A10Collapse>
        <A10Collapse.Panel
          key="routing-ospf"
          header={
            <>
              <span className="edit-content">
                <A10Icon app="global" type="edit" /> Edit
              </span>
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 fontWeight300 width180">
                  OSPF <RoundNumber number={(config || []).length} />
                </span>
              </A10Row>
            </>
          }
          className="collapsePanel"
        >
          <A10Table
            columns={columns}
            dataSource={config}
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
          />
        </A10Collapse.Panel>
      </A10Collapse>
    </div>
  )
}

export default RoutingOSPF
