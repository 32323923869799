import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'

import { ApplicationConfigs } from 'src/constants/ApplicationConfigs'
import { AppRoot } from 'src/settings/appRoot'
import { IDefaultMethods } from 'src/containers/Controller'
import { MyApps } from './MyApps'
import { AppCatalogs } from './AppCatalogs'
import { Utilities } from 'src/services/Utilities'

import 'src/styles/base.scss'
import './styles/apps.scss'

export interface IAppsProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  tenantToggled: boolean
  selectedApp: string
  leftNavAppsRefresh: () => void
  onSelectApp: (selectedApp: string) => void
}
export interface IAppsState {
  application: any
}

class Apps extends A10Container<IAppsProps, IAppsState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>

  state: IAppsState = {
    application: {
      subApps: [],
      name: '',
    },
  }

  ApplicationConfigs = new ApplicationConfigs()
  AppRoot = new AppRoot()
  Utilities = new Utilities()

  componentDidMount() {
    const {
      storage: { remove },
    } = this.context

    remove.CONTEXT_LEVEL()
    remove.CONTEXT()
    remove.DGF_SELECTED_TENANT()
    remove.DGF_ACCOUNTS()
    remove.SELECTEDCONTEXT()
    remove.AUTH()

    this.setAppsMenu()
  }

  componentDidUpdate(prevProps: IAppsProps, prevState: IAppsState) {
    const { tenantToggled } = this.props
    if (prevProps.tenantToggled !== tenantToggled) {
      this.setAppsMenu()
    }
  }

  setAppsMenu() {
    const { selectedApp, onSelectApp } = this.props
    const {
      storage: { get, set, remove },
    } = this.context

    const applications = this.ApplicationConfigs.getAvailableApps()
    const applicationIndex = applications.findIndex((key: any) => {
      return key.name.toLowerCase() === 'apps'
    })
    let selectedAppDetail = applications[applicationIndex].subApps.find(
      (subApp: any) => {
        return subApp.name.toLowerCase() === selectedApp.toLowerCase()
      },
    )

    if (!selectedAppDetail) {
      const drilllevel = get.DRILL_LEVEL
      selectedAppDetail = applications[applicationIndex].subApps[0]
      if (drilllevel === 'provider') {
        selectedAppDetail = applications[applicationIndex].subApps[2]
      }
    }

    const allowedRoles = this.ApplicationConfigs.getAllowedRolesSubApps(
      'Apps',
      selectedAppDetail.name,
    )

    if (allowedRoles) {
      const adminLevel = get.ADMIN_LEVEL
      const allowedIndex = allowedRoles.findIndex((key: any) => {
        return key === adminLevel
      })

      if (allowedIndex >= 0) {
        this.setState({
          application: applications[applicationIndex],
        })

        remove.SUPER_ADMIN_MODE()
        set.CURRENT_STATE('apps')
        this.AppRoot.setRootScopeElement('currentState', 'apps')

        if (selectedAppDetail.name !== selectedApp) {
          if (onSelectApp instanceof Function) {
            onSelectApp(selectedAppDetail.name)
          }
        }

        return
      }
    }
    this.Utilities.showMessage('INSUFFICENT_PRIVILEGE', 0, 1)
  }

  render() {
    const { tenantToggled, defaultMethods, leftNavAppsRefresh } = this.props
    const {
      storage: { get },
    } = this.context

    const selectedApp = this.props.selectedApp.toLowerCase()
    if (get.IS_INFRA_USER) {
      const uri = '/controller/Home'
      return <Redirect to={uri} />
    }

    return (
      <div>
        {selectedApp === 'myapps' ? (
          <MyApps
            tenantToggled={tenantToggled}
            defaultMethods={defaultMethods}
            leftNavAppsRefresh={leftNavAppsRefresh}
          />
        ) : selectedApp === 'providersappcatalog' ? (
          <AppCatalogs leftNavAppsRefresh={leftNavAppsRefresh} />
        ) : null}
      </div>
    )
  }
}

export default setupA10Container(Apps)
