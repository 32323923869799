import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import { A10Loader } from '@gui-libraries/widgets'
import { Viz } from '@gui-libraries/viz'
import { isValidNumber, getCountryByISOCountryCode } from '@gui-libraries/dgf'

import './styles/index.less'

interface IProps {
  viz: IObject
  parameters: IObject
  service: IObject
  startTime: number
  endTime: number
  clusters: IObject[]
  devices: IObject[]
}

const { DestinationCountries } = Viz

const TrafficLocation: React.FC<IProps> = props => {
  const refContainer = useRef()
  const {
    viz,
    parameters,
    service,
    startTime,
    endTime,
    clusters,
    devices,
  } = props
  const { api } = parameters
  const [isLoading, setIsLoading] = useState(true)
  const [formattedData, setFormattedData] = useState([])
  const timeInterval = (endTime - startTime) / 1000
  const cluster_id = clusters
    .map(cluster => cluster['cluster-uuid'] || cluster.uuid)
    .join('|')
  const payload = useMemo(
    () => ({
      result: {
        rangeby: {
          start: startTime,
          end: endTime,
          field: 'ts',
        },
        fields: ['total_bytes_in', 'total_bytes_out'],
        filterby: {
          and: {
            cluster_id,
          },
        },
        groupby: 'cluster_id',
        aggregation: 'avg',
      },
    }),
    [startTime, endTime, cluster_id],
  )

  const formatData = useCallback(
    data => {
      const typesMap = {}
      const clusterTputMap = {}

      Object.entries(data).forEach(series => {
        const [key, value] = series
        const clusterObj = clusters.find((cluster: any) => {
          return cluster['cluster-uuid'] === key
        })

        if (clusterObj?.name) {
          const bytesIn = isValidNumber(value?.['total_bytes_in_avg'])
            ? value['total_bytes_in_avg']
            : 0
          const bytesOut = isValidNumber(value?.['total_bytes_out_avg'])
            ? value['total_bytes_out_avg']
            : 0
          const throughput = ((bytesIn + bytesOut) * 8) / timeInterval

          if (clusterTputMap[clusterObj.name])
            clusterTputMap[clusterObj.name] += throughput
          else clusterTputMap[clusterObj.name] = throughput
        }
      })

      for (let i = 0; i < devices.length; i++) {
        const device = devices[i]
        const region = device.region || 'Unknown'
        const { name: type } = getCountryByISOCountryCode(region)
        const val = clusterTputMap?.[device.cluster] || 0
        if (typesMap[type]) {
          typesMap[type]['value'] += val
        } else {
          typesMap[type] = {
            value: val,
            code: devices[i]['region'] ? devices[i]['region'] : 'Unknown',
          }

          if (clusterTputMap?.[device.cluster])
            clusterTputMap[device.cluster] = 0
        }
      }

      const keys = Object.keys(typesMap)
      const _formatedData = []
      for (let i = 0; i < keys.length; i++) {
        _formatedData.push({
          code: typesMap[keys[i]]?.['code']?.toString()?.toLowerCase(),
          name: keys[i],
          value: typesMap[keys[i]]['value'],
        })
      }

      setFormattedData(_formatedData)
    },
    [clusters, devices, timeInterval],
  )

  const init = useCallback(async () => {
    setIsLoading(true)

    try {
      const url = `${api}/analytics/thunder-adc/system_telemetry_log_device_status`
      const res = await service.sendRequest(
        url,
        'POST',
        service.RPTHeaders,
        payload,
        null,
      )
      const data = res?.data?.result || {}

      formatData(data)
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [api, payload, service, formatData])

  useEffect(() => {
    init()
  }, [init])

  return (
    <div ref={refContainer}>
      {isLoading && <A10Loader container={refContainer} />}
      {!isLoading && (
        <DestinationCountries
          name=""
          data={{
            series: [
              {
                name: 'Location',
                data: formattedData,
              },
            ],
          }}
          unit=""
          height={viz.displayProperties?.height || 300}
          barTitleWidth={viz.displayProperties?.legendWidth}
        />
      )}
    </div>
  )
}

export default TrafficLocation
