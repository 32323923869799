import parameters from 'parameters'

export class Configs {
  EVENTS = [
    {
      name: 'System Operations',
      key: 'Sys_operations',

      columns: [
        {
          title: 'System Operations',
          main: true,
          dataIndex: 'defintion_name',
          logo: 'global',
          key: 'System_Operations',
        },
        {
          title: 'Action name',
          dataIndex: 'action_name',
          main: false,
          key: 'Action_Name',
        },
        {
          title: 'Occured',
          dataIndex: 'occured',
          main: false,
          subText: 'Last 6 hours',
          key: 'occured',
        },
      ],
      contents: [
        {
          name: 'System',
          items: [
            {
              name: 'info',
              contents: [
                {
                  name: 'info',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'notice',
              contents: [
                {
                  name: 'notice',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'warning',
              contents: [
                {
                  name: 'warning',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'error',
              contents: [
                {
                  name: 'error',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
          ],
        },
        {
          name: 'Thunder ACOS',
          items: [
            {
              name: 'info',
              contents: [
                {
                  name: 'info',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'notice',
              contents: [
                {
                  name: 'notice',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'warning',
              contents: [
                {
                  name: 'warning',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'error',
              contents: [
                {
                  name: 'error',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
          ],
        },
        {
          name: 'VCS Controller',
          items: [
            {
              name: 'info',
              contents: [
                {
                  name: 'info',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'notice',
              contents: [
                {
                  name: 'notice',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'warning',
              contents: [
                {
                  name: 'warning',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'error',
              contents: [
                {
                  name: 'error',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'App Services',
      key: 'app_services',
      columns: [
        {
          title: 'App Services',
          main: true,
          dataIndex: 'defintion_name',
          logo: 'global',
          key: 'app_services',
        },
        {
          title: 'Action name',
          dataIndex: 'action_name',
          main: false,
          key: 'Action_Name',
        },
        {
          title: 'Occured',
          dataIndex: 'occured',
          main: false,
          subText: 'Last 6 hours',
          key: 'occured',
        },
      ],
      contents: [
        {
          name: 'Configuration Manager',
          items: [
            {
              name: 'info',
              contents: [
                {
                  name: 'info',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'notice',
              contents: [
                {
                  name: 'notice',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'warning',
              contents: [
                {
                  name: 'warning',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'error',
              contents: [
                {
                  name: 'error',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
          ],
        },
        {
          name: 'Compression',
          items: [
            {
              name: 'info',
              contents: [
                {
                  name: 'info',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'notice',
              contents: [
                {
                  name: 'notice',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'warning',
              contents: [
                {
                  name: 'warning',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'error',
              contents: [
                {
                  name: 'error',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
          ],
        },
        {
          name: 'GSLB Service',
          items: [
            {
              name: 'info',
              contents: [
                {
                  name: 'info',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'notice',
              contents: [
                {
                  name: 'notice',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'warning',
              contents: [
                {
                  name: 'warning',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'error',
              contents: [
                {
                  name: 'error',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
          ],
        },
        {
          name: 'WAF Service',
          items: [
            {
              name: 'info',
              contents: [
                {
                  name: 'info',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'notice',
              contents: [
                {
                  name: 'notice',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'warning',
              contents: [
                {
                  name: 'warning',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'error',
              contents: [
                {
                  name: 'error',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'Health Monitor',
      key: 'health_monitor',
      columns: [
        {
          title: 'Health Monitor',
          main: true,
          dataIndex: 'defintion_name',
          logo: 'global',
          key: 'health_monitor',
        },
        {
          title: 'Action name',
          dataIndex: 'action_name',
          main: false,
          key: 'Action_Name',
        },
        {
          title: 'Occured',
          dataIndex: 'occured',
          main: false,
          subText: 'Last 6 hours',
          key: 'occured',
        },
      ],
      contents: [
        {
          name: 'Monitoring Service',
          items: [
            {
              name: 'info',
              contents: [
                {
                  name: 'info',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'notice',
              contents: [
                {
                  name: 'notice',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'warning',
              contents: [
                {
                  name: 'warning',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'error',
              contents: [
                {
                  name: 'error',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'Networking',
      key: 'networking',
      columns: [
        {
          title: 'Networking',
          main: true,
          dataIndex: 'defintion_name',
          logo: 'global',
          key: 'networking',
        },
        {
          title: 'Action name',
          dataIndex: 'action_name',
          main: false,
          key: 'Action_Name',
        },
        {
          title: 'Occured',
          dataIndex: 'occured',
          main: false,
          subText: 'Last 6 hours',
          key: 'occured',
        },
      ],
      contents: [
        {
          name: 'IPV6 Service',
          items: [
            {
              name: 'info',
              contents: [
                {
                  name: 'info',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'notice',
              contents: [
                {
                  name: 'notice',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'warning',
              contents: [
                {
                  name: 'warning',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'error',
              contents: [
                {
                  name: 'error',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
          ],
        },
        {
          name: 'L3 Service',
          items: [
            {
              name: 'info',
              contents: [
                {
                  name: 'info',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'notice',
              contents: [
                {
                  name: 'notice',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'warning',
              contents: [
                {
                  name: 'warning',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'error',
              contents: [
                {
                  name: 'error',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
          ],
        },
        {
          name: 'Router',
          items: [
            {
              name: 'info',
              contents: [
                {
                  name: 'info',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'notice',
              contents: [
                {
                  name: 'notice',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'warning',
              contents: [
                {
                  name: 'warning',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'error',
              contents: [
                {
                  name: 'error',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'Others',
      key: 'others',
      columns: [
        {
          title: 'Others',
          main: true,
          dataIndex: 'defintion_name',
          logo: 'global',
          key: 'others',
        },
        {
          title: 'Action name',
          dataIndex: 'action_name',
          main: false,
          key: 'Action_Name',
        },
        {
          title: 'Occured',
          dataIndex: 'occured',
          main: false,
          subText: 'Last 6 hours',
          key: 'occured',
        },
      ],
      contents: [
        {
          name: 'Auth Manager',
          items: [
            {
              name: 'info',
              contents: [
                {
                  name: 'info',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'notice',
              contents: [
                {
                  name: 'notice',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'warning',
              contents: [
                {
                  name: 'warning',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'error',
              contents: [
                {
                  name: 'error',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
          ],
        },
        {
          name: 'Failsafe Service',
          items: [
            {
              name: 'info',
              contents: [
                {
                  name: 'info',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'notice',
              contents: [
                {
                  name: 'notice',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'warning',
              contents: [
                {
                  name: 'warning',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
            {
              name: 'error',
              contents: [
                {
                  name: 'error',
                  type: 'switch',
                },
                {
                  name: '',
                  type: 'dropDown',
                  options: [
                    {
                      name: 'Default',
                      value: 'default',
                    },
                    {
                      name: 'Send Email to Support',
                      value: 'email',
                    },
                  ],
                },
                {
                  name: '',
                  type: 'histogram',
                },
              ],
            },
          ],
        },
      ],
    },
  ]
  HELPSUPPORT = [
    {
      name: 'Product Documentation',
      logo: 'book',
      func: 'prdDocumentation',
      iconClass: 'product-document',
      helpUrl: `${parameters.EDUCATION_LINK}Default.htm`,
    },
    {
      name: 'Product Demos',
      logo: 'desktop',
      func: 'prdDemo',
      iconClass: 'product-demo',
      helpUrl:
        'https://www.a10networks.com/resources/product-demos/',
    },
    {
      name: 'Solution Briefs',
      logo: 'file-text',
      func: 'techArticles',
      iconClass: 'technical-articles',
      helpUrl:
        'https://www.a10networks.com/search-result/?ss_search=&asset_types=solution-briefs',
    },
    {
      name: 'Video Tutorials',
      logo: 'video-camera',
      func: 'vidTutorial',
      iconClass: 'video-tutorials',
      helpUrl: 'https://www.a10networks.com/light-talk-video-library/',
    },
    {
      name: 'White Papers',
      logo: 'copy',
      func: 'whitePaper',
      iconClass: 'white-papers',
      helpUrl:
        'https://www.a10networks.com/search-result/?ss_search=&asset_types=white-papers',
    },
    {
      name: 'Create Ticket',
      logo: 'file-add',
      func: 'createTicket',
      iconClass: 'create-ticket',
      helpUrl: 'https://www.a10networks.com/support/',
    },
    {
      name: 'Privacy Policy',
      logo: 'message',
      func: 'chatSupport',
      iconClass: 'privacy-policy',
      helpUrl: 'https://www.a10networks.com/company/legal/privacy-policy/',
    },
    {
      name: 'End of Sale',
      logo: 'message',
      func: 'endOfSale',
      iconClass: 'end-of-sale',
      helpUrl: 'https://www.a10networks.com/support/end-sales',
    },
    {
      name: 'Agreements',
      iconClass: 'privacy-policy',
      // helpUrl: 'https://www.a10networks.com/company/legal-notices',
      isFunc: true,
      key: 'Agreements',
    },
    {
      name: 'Welcome Notes',
      iconClass: 'white-papers',
      isFunc: true,
      key: 'welcome-notes',
    },
  ]
  VMDeviceObj = {
    datacenter: '',
    vmName: '',
    hcCluster: '',
    vmCluster: '',
    vmHost: '',
    vmResourcePool: '',
    vmStorage: '',
    vmFolder: '',
    vmDiskImage: '',
    vmNetworks: [
      {
        name: 'eth0',
        network: '',
        ipAddr: '',
        netMask: '',
        gateway: '',
      },
      {
        name: 'eth1',
        network: '',
        ipAddr: '',
        netMask: '',
        gateway: '',
      },
      {
        name: 'eth2',
        network: '',
        ipAddr: '',
        netMask: '',
        gateway: '',
      },
    ],
  }
  AWSDeviceObj = {
    vmName: '',
    region: '',
    zone: '',
    vpc: '',
    imageName: '',
    image: '',
    instanceType: '',
    vmNetworks: [
      {
        name: 'eth0',
        subnet: '',
        ipAddr: '',
        autoAssign: false,
        publicIP: '',
        autoAllocate: false,
        securityGroup: '',
      },
      {
        name: 'eth1',
        subnet: '',
        ipAddr: '',
        autoAssign: false,
        publicIP: '',
        autoAllocate: false,
        securityGroup: '',
      },
    ],
  }
  // {
  //   name: 'eth2',
  //   subnet: '',
  //   ipAddr: '',
  //   autoAssign: false,
  //   publicIP: '',
  //   autoAllocate: false,
  //   securityGroup: '',
  // },
  AWSInstanceTypeList = [
    { name: 'm4.xlarge', maxNics: 4 },
    { name: 'm4.2xlarge', maxNics: 4 },
    { name: 'm4.4xlarge', maxNics: 8 },
    { name: 'm4.10xlarge', maxNics: 8 },
    { name: 'm4.16xlarge', maxNics: 8 },
    { name: 'm5.xlarge', maxNics: 4 },
    { name: 'm5.2xlarge', maxNics: 8 },
    { name: 'm5.4xlarge', maxNics: 16 },
    { name: 'm5d.xlarge', maxNics: 4 },
    { name: 'm5d.2xlarge', maxNics: 8 },
    { name: 'm5d.4xlarge', maxNics: 16 }
  ]

  AzureInstanceTypeList = [
    { name: 'Standard_A4_v2', displayName: 'Standard A4 v2', maxNics: 4 },
    { name: 'Standard_A4m_v2', displayName: 'Standard A4m v2', maxNics: 4 },
    { name: 'Standard_A3', displayName: 'Standard A3', maxNics: 2 },
    { name: 'Basic_A3', displayName: 'Basic A3', maxNics: 2 },
    { name: 'Standard_A4', displayName: 'Standard A4', maxNics: 4 },
    { name: 'Basic_A4', displayName: 'Basic A4', maxNics: 2 },
    { name: 'Standard_A8_v2', displayName: 'Standard A8 v2', maxNics: 8 },
    { name: 'Standard_B2ms', displayName: 'Standard B2ms', maxNics: 3 },
    { name: 'Standard_B4ms', displayName: 'Standard B4ms', maxNics: 4 },
    { name: 'Standard_D2s_v3', displayName: 'Standard D2s v3', maxNics: 2 },
    { name: 'Standard_D4_v3', displayName: 'Standard D4 v3', maxNics: 2 },
    { name: 'Standard_D4s_v3', displayName: 'Standard D4s v3', maxNics: 2 },
    { name: 'Standard_D3_v2', displayName: 'Standard D3 v2', maxNics: 4 },
    { name: 'Standard_Ds3_v2', displayName: 'Standard Ds3 v2', maxNics: 4 },
    { name: 'Standard_D5_v2', displayName: 'Standard D5 v2', maxNics: 8 },
    { name: 'Standard_F4s', displayName: 'Standard F4s', maxNics: 4 },
    { name: 'Standard_F8s', displayName: 'Standard F8s', maxNics: 8 },
    { name: 'Standard_F16s', displayName: 'Standard F16s', maxNics: 8 },
  ]

  AzureDeviceObj = {
    vmName: '',
    location: '',
    resourceGroup: '',
    autoCreate: '',
    vnet: '',
    imageName: '',
    image: '',
    instanceType: '',
    vmNetworks: [
      {
        name: 'eth0',
        subnet: '',
        ipAddr: '',
        autoAssign: false,
        publicIP: '',
        autoAllocate: false,
        securityGroup: '',
      },
      {
        name: 'eth1',
        subnet: '',
        ipAddr: '',
        autoAssign: false,
        publicIP: '',
        autoAllocate: false,
        securityGroup: '',
      },
    ],
  }

  K8sDeploymentObj = {
    vmName: '',
    node: '',
    namespace: '',
    autoCreate: false,
    imageName: '',
    image: '',
    cpu: '',
    memory: '',
    hugePagesType: '',
    hugePagesSize: '',
    sriovDriver: '',
    vmNetworks: [
      {
        name: 'eth0',
        isService: true,
        serviceType: 'NodePort',
        interfaceDeviceType: '',
        ports: ['22', '80', '443'],
      },
    ],
  }

  OCIDeviceObj = {
    vmName: '',
    region: '',
    availabilityDomain: '',
    compartment: '',
    vcn: '',
    imageName: '',
    image: '',
    instanceType: '',
    vmNetworks: [
      {
        name: 'eth0',
        subnet: '',
        ipAddr: '',
        autoAssign: false,
        publicIP: '',
        autoAllocate: false,
        securityGroup: '',
        subnetCompartment: '',
        publicIpCompartment: '',
        nsgCompartment: '',
        subnetList: [] as any[],
        publicIPList: [] as any[],
        securityGroupList: [] as any[],
      },
      {
        name: 'eth1',
        subnet: '',
        ipAddr: '',
        autoAssign: false,
        publicIP: '',
        autoAllocate: false,
        securityGroup: '',
        subnetCompartment: '',
        publicIpCompartment: '',
        nsgCompartment: '',
        subnetList: [] as any[],
        publicIPList: [] as any[],
        securityGroupList: [] as any[],
      },
    ],
    vcnCompartment: '',
    imageCompartment: '',
    // subnetCompartment: '',
    // publicIpCompartment: '',
    // nsgCompartment: '',
  }

  OCIInstanceTypeList = [
    { name: 'VM.Standard1.1', maxNics: 2 },
    { name: 'VM.Standard1.2', maxNics: 2 },
    { name: 'VM.Standard1.4', maxNics: 4 },
    { name: 'VM.Standard1.8', maxNics: 8 },
    { name: 'VM.Standard1.16', maxNics: 16 },
    { name: 'VM.Standard2.1', maxNics: 2 },
    { name: 'VM.Standard2.2', maxNics: 2 },
    { name: 'VM.Standard2.4', maxNics: 4 },
    { name: 'VM.Standard2.8', maxNics: 8 },
    { name: 'VM.Standard2.16', maxNics: 16 },
    { name: 'VM.Standard2.24', maxNics: 24 },
  ]

  WEBSOCKET_BASE_URL = parameters.BASE_URL.replace('/api/v2', '') + ':443/hcws'
}
export default Configs
