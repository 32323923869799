import React from 'react'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import { A10Table, A10Icon, A10Tag } from '@gui-libraries/widgets'

import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import A10Panel from 'src/components/ADC/A10Panel'
import storage from 'src/libraries/storage'

import './styles/index.less'

export interface IAssociateVIPProps extends IA10ContainerDefaultProps {
  tenant: string
  existVIPs: string[]
  onChangeSelectedValue: (selectedValue: string[]) => void
}

export interface IAssociateVIPState {
  vipList: IObject[]
  isLoading: boolean
  selectedRowKeys: IObject[]
  currentSelectedNames: string[]
}

class AssociateVIP extends A10Container<
  IAssociateVIPProps,
  IAssociateVIPState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      vipList: [],
      isLoading: false,
      selectedRowKeys: [],
      currentSelectedNames: [],
    }
  }

  componentWillMount() {
    this.init(this.props)
  }

  componentWillReceiveProps(nextProps: any) {
    this.init(nextProps)
  }

  init = (props: any) => {
    const { existVIPs } = props
    const currentSelectedNames = existVIPs.map((vip: string) => {
      return vip
    })
    this.setState({ currentSelectedNames })
  }

  componentDidMount() {
    this.getVIPs()
  }

  getHttpClient = () => {
    const {
      GLOBAL_CONFIG: {
        EPIC_DEPENDENCIES: { httpClient },
      },
    } = this.props
    return httpClient
  }

  getVIPs = async () => {
    // Start to load data
    this.setState({ isLoading: true })

    const { tenant, logicalPartition, existVIPs = [] } = this.props
    const httpClient = this.getHttpClient()
    const vipApi = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${logicalPartition}/slb/virtual-server?detail=true`
    try {
      const {
        data: { 'virtual-server-list': vipList = [] },
      } = await httpClient.get(vipApi)
      const initSelectedRowKeys = new Array()
      const newVipList = vipList.map((item: IObject, index: number) => {
        item.key = index
        if (existVIPs.indexOf(item.name) !== -1) {
          initSelectedRowKeys.push(index)
        }
        return item
      })
      this.setState({
        vipList: newVipList,
        selectedRowKeys: initSelectedRowKeys,
        isLoading: false,
      })
    } catch {
      this.setState({ isLoading: false })
    }
  }

  getColumns = () => {
    return [
      {
        title: 'VIP',
        dataIndex: 'vip',
        render: (text: IObject, record: IObject) => {
          return record.name
        },
      },
      {
        title: 'IP Address',
        dataIndex: 'ipAddress',
        render: (text: IObject, record: IObject) => {
          return <A10Tag>{record['ip-address']}</A10Tag>
        },
      },
      {
        title: '#App Service',
        dataIndex: 'appService',
        render: (text: IObject, record: IObject) => {
          return 0
        },
      },
      {
        title: '#vPort',
        dataIndex: 'vport',
        render: (text: IObject, record: IObject) => {
          const { 'port-list': ports = [] } = record
          return ports.length
        },
      },
    ]
  }

  getRowSelection = () => {
    return {
      selectedRowKeys: this.state.selectedRowKeys,
      onSelect: (
        record: IObject,
        selected?: IObject,
        selectedRows?: IObject[],
      ) => {
        const { currentSelectedNames } = this.state
        let actionRows = currentSelectedNames
        if (selected) {
          if (actionRows.indexOf(record.name) === -1) {
            actionRows.push(record.name)
          }
        } else {
          actionRows = _.remove(actionRows, (name: string) => {
            return name !== record.name
          })
        }
        this.setState({ currentSelectedNames: actionRows }, () => {
          this.onChangeValue()
        })
      },
      onSelectAll: (
        selected: IObject,
        selectedRows: IObject[],
        changeRows: IObject[],
      ) => {
        const { currentSelectedNames } = this.state
        let actionRows = currentSelectedNames
        if (selected) {
          changeRows.map((item: IObject) => {
            if (actionRows.indexOf(item.name) === -1) {
              actionRows.push(item.name)
            }
          })
        } else {
          const changeRowNames = changeRows.map((item: IObject) => {
            return item.name
          })
          actionRows = _.remove(actionRows, (name: string) => {
            return changeRowNames.indexOf(name) === -1
          })
        }
        this.setState({ currentSelectedNames: actionRows }, () => {
          this.onChangeValue()
        })
      },
      onChange: (selectedRowKeys: IObject[]) => {
        this.setState({ selectedRowKeys })
      },
    }
  }

  onChangeValue = () => {
    const { currentSelectedNames } = this.state
    const { onChangeSelectedValue } = this.props
    onChangeSelectedValue(currentSelectedNames)
  }

  render() {
    const { vipList } = this.state
    return (
      <A10Panel
        title={
          <A10IconTextGroup
            text="VIPs"
            icon={<A10Icon style={{ fontSize: 48 }} type="desktop" />}
          />
        }
        isFormContent={false}
      >
        <A10Table
          columns={this.getColumns()}
          dataSource={vipList}
          rowSelection={this.getRowSelection()}
          pagination={{
            hideOnSinglePage: true,
          }}
          loading={
            this.state.isLoading
              ? {
                  indicator: (
                    <div className="loading-icon">
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    </div>
                  ),
                }
              : false
          }
        />
      </A10Panel>
    )
  }
}

export default setupA10Container(AssociateVIP)
