import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10DropdownMenu,
  A10Icon,
  A10Tooltip,
} from '@gui-libraries/widgets'
import ReactLoading from 'react-loading'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { DashboardService, Utilities } from 'src/services'
import { LMLicenseDetails } from '../LMLicenseDetails'
import SlidingLMLicenseUpdate from '../LMLicenseUpdateForm/SlidingLMLicenseUpdate'
import { LicenseRevokeForm } from '../LicenseRevokeForm'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')
// tslint:disable-next-line:no-var-requires
const moment = require('moment')

export interface ILMLicenseListProps extends IA10ContainerDefaultProps {
  isLoading?: boolean
  updateTableList?: boolean
  licenseList: any
  updateList: any
}
export interface ILMLicenseListState {
  selectedLicense: any
  showEditSlidingPage: boolean
  showRevokeSlidingPage: boolean
  licenseList: any
  loadingIcon: boolean
}

class LMLicenseList extends A10Container<
  ILMLicenseListProps,
  ILMLicenseListState
> {
  defaultLicenseColumns: any[]
  updateActivations: boolean
  menu: any[]
  DashboardService = new DashboardService()
  Utilities = new Utilities()

  constructor(props: ILMLicenseListProps) {
    super(props)

    this.state = {
      selectedLicense: { 'license-id': '', 'license-name': '' },
      showEditSlidingPage: false,
      showRevokeSlidingPage: false,
      licenseList: this.props.licenseList || [],
      loadingIcon: false,
    }
    this.updateActivations = false
    this.defaultLicenseColumns = [
      {
        title: <></>,
        dataIndex: 'activation-status',
        key: 'activation-status',
        render: (text: string, record: any, index: number) => {
          const status = record['activation-status']
          const statusObj = this.Utilities.getVal(
            status,
            'LICENSE',
            'activation-status',
          )
          return (
            <div className="icons-wrap">
              <HealthStatus
                type={statusObj.type}
                tooltip={this.capitalize('License ' + statusObj.label)}
              />
            </div>
          )
        }
      }
      ,
      {
        title: <>Token</>,
        dataIndex: 'entitlement-key',
        className: 'table-header-color',
        key: 'entitlement-key',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'entitlement-key'),
      },
      {
        title: <>Name</>,
        dataIndex: 'license-name',
        key: 'license-name',
        className: 'td-truncate table-header-color',
        render: (text: string, record: any, index: number) => {
          return (
            <span className="td-truncate" title={text}>
              {text}
            </span>
          )
        },
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'license-name'),
      },
      {
        title: <>Platform</>,
        dataIndex: 'license-platform',
        key: 'license-platform',
        className: 'td-truncate table-header-color',
        render: (description: string, record: any, index: number) => {
          return (
            <span className="td-truncate" title={description}>
              {description}
            </span>
          )
        },
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'license-platform'),
      }
      ,
      {
        title: <>Product</>,
        dataIndex: 'description',
        key: 'description',
        className: 'td-truncate table-header-color',
        render: (description: string, record: any, index: number) => {
          return (
            <span className="td-truncate" title={description}>
              {description}
            </span>
          )
        },
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'license-type'),
      },
      {
        title: <>Bandwidth</>,
        dataIndex: 'alloted-bandwidth',
        key: 'bandwidth',
        className: 'table-header-color',
        children: [
          {
            title: <>Used / Capacity</>,
            dataIndex: 'used-bandwidth',
            className: 'table-header-color',
            key: 'used-bandwidth',
            render: (text: string, record: any, index: number) => {
              const usedBW = record['used-bandwidth']
              const status = record['activation-status']
              const allotedBW = record['alloted-bandwidth']
              const usedBandwidth = this.Utilities.megaToGiga(
                usedBW ? Math.abs(usedBW) : 0,
              )
              const allotedBandwidth = this.Utilities.megaToGiga(
                allotedBW ? Math.abs(allotedBW) : 0,
              )
              const usedValue =
                usedBW === 0
                  ? usedBW
                  : (usedBW < 0 ? '-' : '') +
                    (usedBandwidth.val +
                      (usedBandwidth.unit === 'm' ? 'Mbps' : 'Gbps'))
              const allotedValue =
                allotedBW === 0
                  ? 'Unlimited'
                  : (allotedBW < 0 ? '-' : '') +
                    (allotedBandwidth.val +
                      (allotedBandwidth.unit === 'm' ? 'Mbps' : 'Gbps'))
              return (
                <>
                  {status === 'PENDING' ? (
                    <div>
                      <A10Tooltip
                        placement="bottom"
                        title="Capacity change request pending"
                        arrowPointAtCenter
                        overlayStyle={{ width: '150px' }}
                      >
                        <A10Icon
                          style={{ marginRight: '10px' }}
                          app="global"
                          type="warning-color"
                        />
                      </A10Tooltip>
                      <span>{usedValue + ' / ' + allotedValue}</span>
                    </div>
                  ) : (
                    <div
                      className={`${
                        allotedBW > 0 && usedBW === allotedBW
                          ? styles.expired
                          : ''
                      }`}
                    >
                      {usedValue + ' / ' + allotedValue}
                    </div>
                  )}
                </>
              )
            },
            sorter: (a: any, b: any) =>
              this.Utilities.sortString(a, b, 'used-bandwidth'),
          },
        ],
      },
      {
        title: <>Activations</>,
        dataIndex: 'alloted-devices',
        key: 'devices',
        className: 'table-header-color',
        children: [
          {
            title: <>Used / Capacity</>,
            className: 'table-header-color',
            dataIndex: 'used-devices',
            key: 'used-devices',
            render: (text: string, record: any, index: number) => {
              const usedDev = record['used-devices']
              const allotedDev = record['alloted-devices']
              return (
                usedDev + ' / ' + (allotedDev === 0 ? 'Unlimited' : allotedDev)
              )
            },
            sorter: (a: any, b: any) =>
              this.Utilities.sortString(a, b, 'used-devices'),
          },
        ],
      },
      {
        title: <>Expires on</>,
        dataIndex: 'expires-at',
        className:'table-header-color',
        key: 'expires-at',
        sorter: (a: any, b: any) => this.Utilities.sortDate(a, b, 'expires-at'),
        render: (text: string, record: any, index: number) => {
          const status = record['activation-status']
          let fromDateFormat = !record['expires-at']
            ? ''
            : moment.utc(record['expires-at']).format('MMM DD, YYYY hh:mm A')
          if (
            record['expires-at'] &&
            record['expires-at'] === '2050-12-31 00:00:00'
          ) {
            fromDateFormat = 'Never'
            return fromDateFormat
          }

          return (
            <div
              className={
                status === 'PENDING'
                  ? styles.pending
                  : status === 'EXPIRED'
                  ? styles.expired
                  : ''
              }
            >
              {fromDateFormat}
            </div>
          )
        },
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (record: any) => {
          const todayDate = new Date()
          const date =
            todayDate.getFullYear() +
            '-' +
            (todayDate.getMonth() + 1) +
            '-' +
            todayDate.getDate()

          const expDate = record['expires-at'] || ''
          const keyExpires = expDate.substr(0, expDate.indexOf(' '))
          const currentDate = new Date(date)
          const expiresDate = new Date(keyExpires)

          const clickAction = (component: JSX.Element, index: number) => {
            if (
              component.key === 'edit' &&
              record['activation-status'] !== 'REVOKED'
            ) {
              this.setState({
                showEditSlidingPage: true,
                selectedLicense: record,
              })
            } else if (
              component.key === 'revoke' &&
              record['activation-status'] === 'ACTIVATED'
            ) {
              this.setState({
                showRevokeSlidingPage: true,
                selectedLicense: record,
              })
            }
          }
          const menu = [
            <div
              key="revoke"
              className={
                record['activation-status'] !== 'ACTIVATED' ? 'disabled' : ''
              }
            >
              Revoke{' '}
            </div>,
          ]
          if (
            !(
              record['license-type'] === 'Trial' ||
              record['license-type'] === 'TEMP'
            )
          ) {
            menu.unshift(
              <div
                key="edit"
                className={
                  record['activation-status'] === 'REVOKED' ||
                  record['activation-status'] === 'EXPIRED'
                    ? 'disabled'
                    : ''
                }
              >
                Edit
              </div>,
            )
          }
          return (record['license-type'] === 'Trial' &&
            expiresDate > currentDate) ||
            record['license-type'] === 'TEMP' ? null : (
            <div className="text-right">
              <i>
                <A10DropdownMenu
                  menu={menu}
                  title=""
                  style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={clickAction}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </i>
            </div>
          )
        },
      },
    ]
  }

  capitalize = (s: string) => {
    if (typeof s !== 'string') {
      return ''
    }
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  closeSlidingPage = () => {
    this.setState({
      showEditSlidingPage: false,
      showRevokeSlidingPage: false,
    })
  }

  renderLicenseDetails = (
    record: any,
    index: number,
    indent: number,
    expanded: boolean,
  ) => {
    this.updateActivations = expanded
      ? !this.updateActivations
      : this.updateActivations
    return (
      <LMLicenseDetails
        licenseData={record}
        isUpdateActivations={this.updateActivations}
        refreshData={this.props.updateList}
      />
    )
  }

  render() {
    const { licenseList = [], updateList } = this.props
    const {
      selectedLicense,
      showEditSlidingPage,
      showRevokeSlidingPage,
    } = this.state
    return (
      <div>
        <A10Table
          columns={this.defaultLicenseColumns}
          expandedRowRender={this.renderLicenseDetails}
          dataSource={licenseList.map((item: IObject, index: number) => {
            item.key = index
            return item
          })}
          size="small"
          scroll={{ x: '100%' }}
          loading={
            this.props.isLoading
              ? {
                  indicator: (
                    <div>
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    </div>
                  ),
                }
              : false
          }
          pagination={{ hideOnSinglePage: true, pageSize: 10 }}
        />

        <SlidingLMLicenseUpdate
          isOpen={showEditSlidingPage}
          licenseObj={selectedLicense}
          onRequestClose={this.closeSlidingPage}
          updateList={updateList}
        />
        <LicenseRevokeForm
          isOpen={showRevokeSlidingPage}
          licenseObj={selectedLicense}
          onRequestClose={this.closeSlidingPage}
          updateList={updateList}
        />
      </div>
    )
  }
}

export default setupA10Container(LMLicenseList)
