import React from 'react'
import { A10Button, A10Icon } from '@gui-libraries/widgets'

import styles from './styles/index.module.less'

export interface IActionButtonProps {
  text: string
  onClick: () => void
  iconProps?: GuiWidgets.A10Icon.Props
  className?: string
}

const ActionButton: React.FC<IActionButtonProps> = props => {
  const { className, text, iconProps, onClick, ...restProps } = props

  return (
    <A10Button
      type="link"
      size="large"
      onClick={onClick}
      {...restProps}
      className={`${styles.actionButton} ${className || ''}`}
    >
      {iconProps && <A10Icon {...iconProps} />}
      {text}
    </A10Button>
  )
}

export default ActionButton
