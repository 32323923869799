import React from 'react'
import ReactLoading from 'react-loading'
import { Redirect } from 'react-router-dom'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10DropdownMenu,
  A10Tooltip,
  A10Modal,
  A10Button,
  A10Input,
  A10Icon,
} from '@gui-libraries/widgets'

import { IDefaultMethods } from 'src/containers/Controller'
import { InfraConfigs } from 'src/constants/InfraConfigs/InfraConfigs'
import { AppRoot } from 'src/settings/appRoot'
import storage from 'src/libraries/storage'
import {
  InfrastructureService,
  DashboardService,
  InfraStructureStatsService,
  Utilities,
} from 'src/services/index'
import SlidingWorkflowStatus from 'src/components/shared/WorkflowStatus/slidingForm'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { PartitionAddForm } from 'src/containers/Controller/Infrastructure/Clusters/Forms/PartitionAddForm'
import { ObjectExplorer } from 'src/components/shared/ObjectExplorer'
import Card from 'src/components/shared/Card'
import ActionButton from 'src/components/shared/ActionButton'

import styles from './styles/index.module.less'

export interface IClusterPartitionsProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  cluster: any
  refreshClusterData: any
  addPartition: any
}
export interface IClusterPartitionsState {
  partitions: IObject[]
  filteredPartitions: IObject[]
  deleteModalState: boolean
  selectedPartition: any
  loadingIcon: boolean
  searchString: string
  workflowID: string
  workflowSliderState: boolean
  showSlidingForm: boolean
  partitionformdata: any
  partitionFormName: boolean
  selectedPartitionDetail: any
  workflowType: string
  workflowMappedObj: any
  workflowSliderTitle: string
  workFlowType: string
  redirectPath: string
  showOE: boolean
  slidingFormObject: React.Component
}

export const PARTITION_OBJ = {
  partitionId: '',
  partitionName: '',
  device: '',
  tenant: '',
  description: '',
}

class ClusterPartitions extends A10Container<
  IClusterPartitionsProps,
  IClusterPartitionsState
> {
  pageLength = 5
  DashboardService = new DashboardService()
  InfraStructureStatsService = new InfraStructureStatsService()
  InfrastructureService = new InfrastructureService()
  Utilities = new Utilities()
  InfraConfigs = new InfraConfigs()
  AppRoot = new AppRoot()
  adminLevel = storage.get.ADMIN_LEVEL
  isOperatorUser = storage.get.IS_OPERATOR_USER

  childForm: any

  state = {
    partitions: [],
    filteredPartitions: [],
    selectedPartition: { name: '' },
    deleteModalState: false,
    loadingIcon: false,
    searchString: '',
    workflowID: '',
    workflowSliderState: false,
    showSlidingForm: false,
    partitionformdata: PARTITION_OBJ,
    partitionFormName: false,
    selectedPartitionDetail: {
      name: '',
      id: '',
      tenantuuid: '',
      lpuuid: '',
      existingTenant: { name: '', uuid: '' },
      existingPuuid: {},
      existingLPuuid: '',
      existingLPname: '',
      tenant: '',
      uuid: '',
    },
    workflowType: '',
    workflowMappedObj: null,
    workflowSliderTitle: '',
    workFlowType: '',
    redirectPath: '',
    showOE: false,
    slidingFormObject: <div />,
  }
  partitionsColumns = [
    {
      title: 'Partition',
      dataIndex: 'name',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
      className: 'td-truncate',
      render: (text: any, record: any) => {
        return (
          <A10Tooltip
            title={this.rowToolTip(record)}
            placement="topLeft"
            overlayClassName="toolTipWidth"
          >
            {text}
          </A10Tooltip>
        )
      },
    },
    // {
    //   title: 'Resource Template',
    //   dataIndex: 'resourceTemplate',
    // },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'id'),
      render: (id: string, record: any) => {
        return <span>{id}</span>
      },
    },
    {
      title: (
        <A10Tooltip
          title={
            'Service Partitions are shown as of type "Service". Note that these partitions are a logical construct for RBAC and share all physical attributes like network and Interfaces with the Shared Partition'
          }
        >
          <span className={styles.helpTextProvided}>Partition Type</span>
        </A10Tooltip>
      ),
      dataIndex: 'id',
      key: 'partitionType',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'id'),
      render: (id: number, record: any) => {
        return id === 0
          ? 'Shared'
          : record['p-type']?.includes('service')
          ? 'Service'
          : 'L3V'
      },
    },
    // {
    //   title: 'App Type',
    //   dataIndex: 'appType',
    // },
    {
      title: 'Tenant',
      dataIndex: 'tenant-name',
      key: 'tenant-name',
      sorter: (a: any, b: any) =>
        this.Utilities.sortStringKeys(a, b, 'display-name', 'tenant-name'),
      className: 'td-truncate',
      render: (tenant: any, record: any) => {
        return <span>{record['display-name'] || record['tenant-name']}</span>
      },
    },
    {
      title: 'CPU',
      children: [
        {
          title: 'Max.',
          dataIndex: 'max',
          key: 'max',
          sorter: (a: any, b: any) => this.Utilities.sortNumber(a, b, 'max'),
          render: (max: any, record: any) => {
            return <span>{max}</span>
          },
        },
        {
          title: 'Avg.',
          dataIndex: 'avg',
          key: 'avg',
          sorter: (a: any, b: any) => this.Utilities.sortNumber(a, b, 'avg'),
          render: (avg: any, record: any) => {
            return <span>{avg}</span>
          },
        },
      ],
    },
    {
      title: '',
      dataIndex: '',
      key: 'menuAction',
      render: (record: any, key: any) => {
        const { cluster } = this.props
        const clusterType = cluster['data-plane-ha']
        const clickAction = (component: JSX.Element, index: number) => {
          if (component.key === 'delete') {
            const payload = { features: ['partition_remove'] }
            this.featureCheck({
              key,
              payload,
              type: 'partition_remove',
              record,
            })
          } else if (component.key === 'showTrafficMap') {
            storage.set.CURRENT_CLUSTER({ name: cluster.name })
            this.setState({
              redirectPath: '/controller/Utilities/ScaleoutTraffic',
            })
          } else if (component.key === 'exploreConfig') {
            const cp = {
              ...key,
              clusterName: cluster?.name,
              partitionType: key.name === 'shared' ? 'shared' : 'l3v',
            }
            this.setState({
              selectedPartition: cp,
              showOE: true,
              showSlidingForm: true,
            })
          }
        }
        return storage.get.ADMIN_LEVEL === 'provider' &&
          !this.isOperatorUser ? (
          <div className="text-right">
            <i>
              <A10DropdownMenu
                menu={[
                  <div key="exploreConfig">Explore Config</div>,
                  ...(clusterType === 'scaleout'
                    ? [<div key="showTrafficMap">Show Traffic Map</div>]
                    : []),
                  <div
                    key="delete"
                    className={record.id === 0 ? 'disabled' : ''}
                  >
                    Delete
                  </div>,
                ]}
                title=""
                style={{ color: '#757575', marginBottom: '-15px' }}
                onClick={clickAction}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            </i>
          </div>
        ) : null
      },
    },
  ]

  rowToolTip = (record: any) => {
    return (
      <div className="tableOverToolTip">
        <table>
          <tr>
            <th>Partition Name</th>
            <td>{record.name}</td>
          </tr>
          <tr>
            <th>Avg CPU</th>
            <td>{record.avg}</td>
          </tr>
        </table>
      </div>
    )
  }

  featureCheck = ({
    key,
    payload,
    type,
    record,
  }: {
    key?: any
    payload: any
    type: any
    record?: any
  }) => {
    const cluster = this.props.cluster
    const { showMessage } = this.Utilities
    const provider = storage.get.PROVIDER
    const featureCheck = this.InfrastructureService.featureCheck(
      null,
      payload,
      [provider, 'cluster', cluster.name],
    )
    featureCheck
      .then((resp: any) => {
        const feature = resp.data[payload.features[0]]
        if (feature) {
          if (type === 'partition_remove' && record.id !== 0) {
            this.setState({
              deleteModalState: true,
              selectedPartition: key,
            })
          } else if (type === 'edit') {
            this.editPartition(record)
          } else if (type === 'partition_add') {
            this.props.addPartition(cluster)
          } else {
            this.Utilities.checkFeatureMsg('cluster', cluster.name)
          }
        } else {
          this.Utilities.checkFeatureMsg('cluster', cluster.name)
        }
      })
      .catch((error: any) => {
        const message = error?.response?.data?.message
          ? error.response.data.message
          : ''
        if (!this.Utilities.isRBACAccessDenied(error)) {
          showMessage('', 0, 0, message, 10)
        }
      })
  }

  addClusterPartition = () => {
    const type = 'partition_add'
    const payload = {
      features: [type],
    }
    this.featureCheck({ payload, type })
  }

  getLPname(lpuuid: any, tenant: any) {
    let lp =
      tenant && tenant['logical-partition-list']
        ? tenant['logical-partition-list'].find((item: any, i: any) => {
            if (item.uuid === lpuuid) {
              return item.name
            }
          })
        : { name: 'NA' }
    if (!lp) {
      lp = { name: 'NA' }
    }
    return lp.name
  }

  getNewLpName = async (lpname: any, tenant: any, partitionDetail: any) => {
    if (tenant['logical-partition-list']) {
      const lpExists = tenant['logical-partition-list'].find(
        (item: any, i: any) => {
          // if (item.name === lpname) {
          //   return true
          // }
          return item.name === lpname
        },
      )
      if (!lpExists || lpExists === 'undefined' || lpExists === undefined) {
        return lpname
      } else {
        const partitionInfo = {
          id: partitionDetail.id,
          'partition-uuid': partitionDetail['partition-uuid'],
          existingTenant: tenant,
          existingLPuuid: lpExists.uuid,
          existingLPname: lpExists.name,
        }

        await this.InfrastructureService.unmapTenantPartitonFunctions(
          partitionInfo,
          true,
        )

        return lpname
        // return this.getNewLpName(lpname + '_new', tenant, partitionDetail)
      }
    } else {
      return lpname
    }
  }

  editPartition = async (partition: any) => {
    const tenantObj = await this.Utilities.getDetailedTenantObjectByUUID(
      partition['tenant-uuid'],
    )
    const partitionformdata = this.state.partitionformdata
    partitionformdata.tenant = tenantObj ? JSON.stringify(tenantObj) : ''
    this.setState({
      showSlidingForm: true,
      partitionFormName: true,
      selectedPartitionDetail: {
        name: partition.name,
        id: partition.id,
        lpuuid: partition['lp-uuid'],
        'partition-uuid': partition['partition-uuid'],
        tenantuuid: partition['tenant-uuid'],
        existingPuuid: partition.uuid,
        existingTenant: tenantObj,
        existingLPuuid: partition['lp-uuid'],
        existingLPname: partition['lp-uuid']
          ? this.getLPname(partition['lp-uuid'], tenantObj)
          : '',
        uuid: partition.uuid,
        tenant: partition['display-name'] ? (
          <>
            <span className="statusIcon">
              <span className="statusIconTenantText">T</span>
            </span>{' '}
            {partition['display-name']}
          </>
        ) : partition['tenant-name'] ? (
          <>
            <span className="statusIcon">
              <span className="statusIconTenantText">T</span>
            </span>{' '}
            {partition['tenant-name']}
          </>
        ) : (
          'Please select'
        ),
      },
      partitionformdata,
    })
  }

  setSlidingPage = (isOpen: boolean, data?: IObject, editMode?: boolean) => {
    this.setState({
      showSlidingForm: isOpen,
    })
  }

  handleFormChange = (data: any, isEdit: boolean) => {
    this.setState({
      partitionformdata: data,
    })
  }

  handleSave = (e: any) => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  handleSaveEditedPartition = async () => {
    const { selectedPartitionDetail, partitionformdata } = this.state
    const tenantObj = partitionformdata.tenant
      ? JSON.parse(partitionformdata.tenant)
      : {}
    const uuid = tenantObj.uuid || ''
    const { showMessage } = this.Utilities
    if (selectedPartitionDetail.tenantuuid === uuid) {
      showMessage('No change to update the partition details', 2, 0)
      return
    }
    const provider = storage.get.PROVIDER

    if (uuid) {
      const logicalPartition = uuid
        ? await this.getNewLpName(
            this.props.cluster.name + '-P' + selectedPartitionDetail.id,
            tenantObj,
            selectedPartitionDetail,
          )
        : ''
      const lpPayload = {
        'logical-partition-list': [
          {
            type:
              selectedPartitionDetail.id === '0' ||
              selectedPartitionDetail.id === 0
                ? 'shared'
                : 'private-partition',
            name: logicalPartition,
            cluster: this.props.cluster.name,
            partition: selectedPartitionDetail.name,
          },
        ],
      }
      const lpResponse = this.InfrastructureService.createLogicalPartition(
        null,
        lpPayload,
        [provider, tenantObj.name],
      )
      lpResponse
        .then((response: any) => {
          // const lpSubsPayload = {
          //   subscriber: {
          //     uuid: response.data['logical-partition'].uuid,
          //     'receiver-list': [{ uuid: selectedPartitionDetail.uuid }],
          //   },
          // }
          // this.InfrastructureService.createLPSubscriber(
          //   null,
          //   lpSubsPayload,
          //   null,
          // ).then((resp: any) => {
          if (
            selectedPartitionDetail.existingTenant &&
            selectedPartitionDetail.existingTenant.uuid &&
            selectedPartitionDetail.existingPuuid
          ) {
            this.InfrastructureService.unmapTenantPartitonFunctions(
              selectedPartitionDetail,
            )
          }
          //   const subscriberLabel = `LogicalParition_${
          //     response.data['logical-partition'].uuid
          //   }`
          //   const cpVal = `${this.props.cluster.name}$$&&${
          //     selectedPartitionDetail.name
          //   }`
          //   setItem(subscriberLabel, cpVal)
          const lpUuid =
            response?.data && response.data['logical-partition-list'].length > 0
              ? response.data['logical-partition-list'][0].uuid
              : ''
          if (lpUuid) {
            this.editTenantPartitionAPICall(
              selectedPartitionDetail,
              lpUuid,
              uuid,
            )
          }
          // }).catch((error: any) => {
          //   if (!this.Utilities.isRBACAccessDenied(error)) {
          //     const msg = error?.response?.data?.message ? error.response.data.message : ''
          //     this.Utilities.showMessage('FAILURE_PARTITION_EDITED', 0, 1, msg)
          //   }
          // })
        })
        .catch((error: any) => {
          if (!this.Utilities.isRBACAccessDenied(error)) {
            const msg = error?.response?.data?.message
              ? error.response.data.message
              : ''
            this.Utilities.showMessage('FAILURE_PARTITION_EDITED', 0, 1, msg)
          }
        })
    } else {
      if (
        selectedPartitionDetail.existingTenant &&
        selectedPartitionDetail.existingTenant.uuid &&
        selectedPartitionDetail.existingPuuid
      ) {
        this.InfrastructureService.unmapTenantPartitonFunctions(
          selectedPartitionDetail,
        )
      }
      this.editTenantPartitionAPICall(selectedPartitionDetail, '', '')
    }
  }

  editTenantPartitionAPICall = (
    selectedPartitionDetail: any,
    lpUUID: string,
    tenantUUID: string,
  ) => {
    const initialPayload = {
      partition: {
        name: selectedPartitionDetail.name,
        id: parseInt(selectedPartitionDetail.id),
        'lp-uuid': lpUUID,
        'tenant-uuid': tenantUUID,
      },
    }

    const editedPartition = this.InfrastructureService.editPartitionToCluster(
      null,
      initialPayload,
      [
        storage.get.PROVIDER,
        this.props.cluster.name,
        selectedPartitionDetail.name,
      ],
    )

    editedPartition
      .then((resp: any) => {
        const payload = {
          provision: {
            'config-scan': false,
          },
        }
        this.InfrastructureService.createReProvision(null, payload, [
          storage.get.PROVIDER,
          this.props.cluster.name,
        ])
          .then((response: any) => {
            this.setSlidingPage(false)
            this.setState({
              workflowType: 'provision',
              workflowID: response.data.workflow['workflow-id'],
              workflowSliderState: true,
              workflowMappedObj: selectedPartitionDetail,
              workflowSliderTitle: 'Cluster partition edit Workflow Status',
              workFlowType: 'partitionEdit',
            })
            this.Utilities.refreshTenants()
            this.props.refreshClusterData()
          })
          .catch((error: any) => {
            if (!this.Utilities.isRBACAccessDenied(error)) {
              const msg = error?.response?.data?.message
                ? error.response.data.message
                : ''
              this.Utilities.showMessage('FAILURE_PARTITION_EDITED', 0, 1, msg)
            }
          })
        // const message = 'SUCCESS_PARTITION_EDITED'
        // this.Utilities.showMessage(message, 1, 1)
        // this.setSlidingPage(false)
        // this.Utilities.refreshTenants()
        // this.props.refreshClusterData()
      })
      .catch((error: any) => {
        if (!this.Utilities.isRBACAccessDenied(error)) {
          const messageKey = 'FAILURE_PARTITION_EDITED'
          this.Utilities.showMessage(messageKey, 0, 1)
        }
        this.setSlidingPage(false)
      })
  }

  goToTenants = (tenant: any) => {
    const tenantObj = this.Utilities.getTenantObject(tenant)
    if (tenantObj) {
      storage.set.DRILL_LEVEL('tenant')
      storage.set.CURRENT_TENANT(tenantObj)
      this.AppRoot.setRootScopeElement('currentDrillLevel', 'tenant')
      this.AppRoot.setRootScopeElement('currentTenant', tenantObj)
    }
  }

  formatPartitions = () => {
    const { cluster } = this.props
    const { searchString } = this.state
    const partitions = (cluster?.['partition-list'] || []).map(
      (partition: IObject) => {
        if (partition['tenant-uuid']) {
          const tenant = this.Utilities.getTenantObjectByUUID(
            partition['tenant-uuid'],
          )
          if (tenant) {
            const tenantName = tenant?.name || ''
            const tenantDisplayName = tenant?.['display-name'] || tenantName
            partition['tenant-name'] = tenantName
            partition['display-name'] = tenantDisplayName
          }
        } else {
          partition['tenant-uuid'] = ''
          partition['tenant-name'] = ''
          partition['display-name'] = ''
        }

        partition.key = partition.uuid

        return partition
      },
    )

    this.setState({
      partitions: partitions,
      filteredPartitions: this.getFilteredPartitions(partitions, searchString),
    })
    if (partitions.length > 0) {
      this.getAllPartitionStats()
    }
  }

  getAllPartitionStats = () => {
    const { cluster } = this.props
    const { searchString } = this.state
    const partitions = cluster?.['partition-list'] || []
    const xAccount = partitions
      .reduce((acc: string[], partition: IObject) => {
        const tenantUuid = partition['tenant-uuid']
        if (tenantUuid && !acc.includes(tenantUuid)) {
          acc.push(tenantUuid)
        }
        return acc
      }, [])
      .join(',')

    if (xAccount === '' || !xAccount) {
      const _partitions = partitions.map((partition: any) => {
        partition.max = 'NA'
        partition.avg = 'NA'
        return partition
      })

      this.setState({
        partitions: _partitions,
        filteredPartitions: this.getFilteredPartitions(
          _partitions,
          searchString,
        ),
        loadingIcon: false,
      })
      return
    } else {
      const header = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        provider: storage.get.PROVIDER,
        Authorization: storage.get.ENCODED_SESSION_ID,
        'X-Account': xAccount,
      }

      const to = Date.now()
      const from = Date.now() - 2 * 60 * 1000
      const payload = {
        dataCPUStats: {
          fields: ['avg_data_cpu_usage', 'max_data_cpu_usage'],
          aggregation: 'avg',
          sort: 'asc',
          rangeby: {
            start: from,
            end: to,
            field: 'ts',
          },
          filterby: {
            and: {
              cluster_id: this.props.cluster['cluster-uuid'],
            },
          },
          size: partitions.length,
          groupby: 'p_id',
        },
      }

      const dataCPUReq = this.InfraStructureStatsService.getPartitionStats(
        header,
        payload,
        null,
      )
      dataCPUReq
        .then((response: any) => {
          const dataCPUStats = response.data.dataCPUStats
          const _partitions = partitions.map((partition: any) => {
            const { id } = partition
            if (dataCPUStats[id]) {
              let maxData = dataCPUStats[id].max_data_cpu_usage_avg
              let avgData = dataCPUStats[id].avg_data_cpu_usage_avg
              if (partition['p-type']?.includes('service')) {
                maxData = -1
                avgData = -1
              }
              partition.max = maxData >= 0 ? Math.round(maxData) + '%' : 'NA'
              partition.avg = avgData >= 0 ? Math.round(avgData) + '%' : 'NA'
            } else {
              partition.max = 'NA'
              partition.avg = 'NA'
            }
            return partition
          })
          this.setState({
            partitions: _partitions,
            filteredPartitions: this.getFilteredPartitions(
              _partitions,
              searchString,
            ),
            loadingIcon: false,
          })
        })
        .catch((error: any) => {
          console.log(error)
        })
    }
  }

  handleOk = () => {
    const { showMessage } = this.Utilities
    const provider = storage.get.PROVIDER
    const payload = {
      'delete-partitions': {
        'write-mem': true,
        'partition-list': [this.state.selectedPartition.name],
      },
    }
    const deletePartition = this.InfrastructureService.deleteClusterPartition(
      null,
      payload,
      [provider, this.props.cluster.name],
    )
    this.setState({
      loadingIcon: true,
    })
    deletePartition
      .then((resp: any) => {
        // showMessage('SUCCESS_PARTITION_DELETE', 1, 1)
        this.setState({
          loadingIcon: false,
          deleteModalState: false,
          workflowID: resp.data.workflow.uuid,
          workflowSliderState: true,
          workflowMappedObj: this.state.selectedPartition,
          workflowSliderTitle: 'Cluster partition delete Workflow Status',
          workFlowType: 'partitionDelete',
        })
      })
      .catch((error: any) => {
        this.setState({
          loadingIcon: false,
        })
        if (!this.Utilities.isRBACAccessDenied(error)) {
          showMessage('FAILURE_PARTITION_DELETE', 0, 1)
        }
      })
  }

  handleCancel = () => {
    if (this.state.loadingIcon) {
      return false
    }
    this.setState({
      deleteModalState: false,
    })
  }

  handleSlidingWorkflowOnCancel = () => {
    this.setState({
      workflowSliderState: false,
    })
    this.props.refreshClusterData()
  }

  onSearch = (e: any) => {
    this.setState({
      searchString: (e?.target?.value || '').toLowerCase(),
    })
  }

  getFilteredPartitions = (partitions: IObject[], searchString: string) => {
    return partitions.filter(partition => {
      return partition.name?.toLowerCase().includes(searchString)
    })
  }

  componentDidMount() {
    this.formatPartitions()
  }

  componentDidUpdate(
    prevProps: IClusterPartitionsProps,
    prevState: IClusterPartitionsState,
  ) {
    const { searchString, partitions } = this.state

    if (this.props.cluster !== prevProps.cluster) {
      this.formatPartitions()
    }

    if (prevState.searchString !== searchString) {
      this.setState({
        filteredPartitions: this.getFilteredPartitions(
          partitions,
          searchString,
        ),
      })
    }
  }

  render() {
    const { cluster, defaultMethods } = this.props
    const {
      filteredPartitions,
      redirectPath,
      showSlidingForm,
      showOE,
    } = this.state
    const slidingFormTitle = showOE ? 'Explorer' : 'Edit Cluster Partition'
    const slidingFormDescription = showOE
      ? ''
      : 'Please provide the detail of the partition'

    if (redirectPath) {
      return <Redirect to={redirectPath} />
    }

    return (
      <>
        <Card
          title="Cluster Partition List"
          toolbar={
            <>
              <div className={styles.searchInputContainer}>
                <A10Input.Search
                  type="text"
                  onChange={this.onSearch}
                  name="searchBox"
                  value={this.state.searchString}
                  placeholder="Search"
                />
              </div>
              {!this.isOperatorUser && (
                <ActionButton
                  text="Add"
                  size="default"
                  onClick={this.addClusterPartition}
                  iconProps={{ app: 'global', type: 'add-new' }}
                />
              )}
            </>
          }
        >
          <A10Table
            columns={this.partitionsColumns}
            bordered={false}
            dataSource={filteredPartitions}
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
          />
        </Card>
        <A10Modal
          title="Delete Partition"
          visible={this.state.deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={this.state.loadingIcon}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
              disabled={this.state.loadingIcon}
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            Do you want to delete {this.state.selectedPartition.name} from{' '}
            {storage.get.PROVIDER}?
          </p>
          {this.state.loadingIcon ? (
            <div className="">
              <ReactLoading
                type="bars"
                color="#4a90e2"
                height={40}
                width={40}
              />
            </div>
          ) : null}
        </A10Modal>

        <FormatSlidingPage
          isOpen={showSlidingForm}
          onRequestOk={this.handleSave}
          onRequestClose={this.setSlidingPage.bind(this, false)}
          title={slidingFormTitle}
          description={slidingFormDescription}
          isShowFooterButtons={!showOE}
          isRightCancel={showOE}
        >
          {showOE ? (
            <ObjectExplorer
              singleClusterPartition={this.state.selectedPartition}
            />
          ) : (
            <PartitionAddForm
              defaultMethods={defaultMethods}
              formData={this.state.partitionformdata}
              handleChange={this.handleFormChange}
              clusterLevelEditPartitionEnable={this.state.partitionFormName}
              onSubmitForm={this.handleSaveEditedPartition}
              onRef={(ref: any): any => (this.childForm = ref)}
              clusterData={cluster}
              selectedPartitionData={this.state.selectedPartitionDetail}
            />
          )}
        </FormatSlidingPage>

        <SlidingWorkflowStatus
          isOpen={
            this.state.workflowSliderState &&
            this.state.workflowID !== '' &&
            !!filteredPartitions
          }
          title={this.state.workflowSliderTitle}
          onRequestClose={this.handleSlidingWorkflowOnCancel}
          type={this.state.workFlowType}
          id={this.state.workflowID}
          assocObject={this.state.workflowMappedObj}
          showMessage={true}
        />
      </>
    )
  }
}
export default setupA10Container(ClusterPartitions)
