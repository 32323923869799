import { _ } from '@gui-libraries/framework'
import { getKeyNameList, setKeyNameList } from './basicUtils'

export const getWorkflow = async (
  httpClient: IObject,
  selectedTimePeriod: IObject = {},
  pager: IObject = {},
  filters: IObject = {},
  sorters: IObject = {},
) => {
  const { startTime, endTime } = selectedTimePeriod
  const { pageStart = 0, pageSize = 10 } = pager
  const {
    objId = '*',
    workflowId = '*',
    provider = '*',
    tenant = '*',
    ...typeFilters
  } = filters
  const { sortBy = 'createTime', order = 'desc' } = sorters

  const workflowMapURL = '/datastore/object-workflow-map'
  const searchParams = new URLSearchParams()
  // Time
  if (_.isFinite(startTime) && _.isFinite(endTime)) {
    searchParams.append('time_from', `${startTime}`)
    searchParams.append('time_to', `${endTime}`)
  }

  // Pagination
  if (_.isFinite(pageStart) && _.isFinite(pageSize)) {
    searchParams.append('start', `${pageStart + 1}`)
    searchParams.append('page_size', `${pageSize}`)
  }

  // Filter by ID
  if (_.isString(objId) && objId && objId !== '*') {
    searchParams.append('obj_id', objId)
  }
  if (_.isString(workflowId) && workflowId && workflowId !== '*') {
    searchParams.append('wf_id', workflowId)
  }
  if (_.isString(provider) && provider && provider !== '*') {
    searchParams.append('provider_id', provider)
  }
  if (_.isString(tenant) && tenant && tenant !== '*') {
    searchParams.append('tenant_id', tenant)
  }

  // Filter by Object type (API only support one type)
  Object.keys(typeFilters).some((filterKey: string) => {
    if (typeFilters[filterKey]) {
      searchParams.append('obj_type', filterKey)
      return true
    }
    return false
  })

  // Sort
  if (sortBy === 'createTime') {
    searchParams.append('sort_by', 'created_on')
    if (order === 'desc') {
      searchParams.append('sort_order', 'desc')
    } else {
      searchParams.append('sort_order', 'asc')
    }
  } else if (sortBy === 'workflowType') {
    searchParams.append('sort_by', 'wf_type')
    if (order === 'desc') {
      searchParams.append('sort_order', 'desc')
    } else {
      searchParams.append('sort_order', 'asc')
    }
  }

  try {
    const {
      data: { resultSet, resultSetCount, totalRecordsCount },
    } = await httpClient.get(workflowMapURL, {
      absoluteBasePath: true,
      params: searchParams,
    })

    let keyNameList: IObject = getKeyNameList() || {}
    const promises = resultSet.map(async (mapResult: IObject) => {
      const result: IObject = {}

      result.workflowObjectMap = mapResult[Object.keys(mapResult)[0]]
      const {
        obj_id: objID,
        workflow_id: workflowID,
      } = result.workflowObjectMap

      if (keyNameList[objID]) {
        result.object = keyNameList[objID]
      } else {
        try {
          try {
            const objectURL = `/hpcapi/v3/uuid/${objID}`
            await httpClient.get(objectURL, {
              absoluteBasePath: true,
              saveUUIDDef: true,
            })
          } catch (e) {
            console.log(e)
          }
          keyNameList = getKeyNameList() || keyNameList
          if (!keyNameList[objID]) {
            const defaultInfor = {
              name: '',
              'display-name': '',
              type: '',
              device: '',
            }
            keyNameList[objID] = defaultInfor
            setKeyNameList(keyNameList)
          }
          result.object = keyNameList[objID]
        } catch {
          console.log("Can't get the associated object!")
        }
      }

      try {
        const workflowURL = `/hwcapi/v1/workflow/${workflowID}`
        // const { data: { workflow } } = await httpClient.get(workflowURL, { absoluteBasePath: true })
        // result.workflow = workflow

        // To avoid waiting long time, put the Promise as the data
        result.workflow = httpClient.get(workflowURL, {
          absoluteBasePath: true,
        })
      } catch (e) {
        console.log(e)
      }

      return result
    })
    const promiseResults = await Promise.all(promises)

    return {
      total: totalRecordsCount,
      pageData: promiseResults,
      pager: { pageStart, pageSize },
      filters: { objId, workflowId, provider, tenant, ...typeFilters },
      sorters: { sortBy, order },
    }
  } catch {
    return {
      total: 0,
      pageData: [],
      pager: { pageStart, pageSize },
      filters: { objId, workflowId, provider, tenant, ...typeFilters },
      sorters: { sortBy, order },
    } as IObject
  }
}

export const getLatestWorkflow = async (
  httpClient: IObject,
  selectedTimePeriod: IObject = {},
  filters: IObject = {},
  upperLimit: number = 15,
) => {
  const { startTime, endTime } = selectedTimePeriod
  const { provider = '*', tenant = '*', uuid = '*' } = filters

  const workflowMapURL = '/datastore/object-workflow-map'
  const searchParams = new URLSearchParams()
  // Time
  if (_.isFinite(startTime) && _.isFinite(endTime)) {
    searchParams.append('time_from', `${startTime}`)
    searchParams.append('time_to', `${endTime}`)
  }

  // Filter by Provider&Tenant
  if (_.isString(provider) && provider && provider !== '*') {
    searchParams.append('provider_id', provider)
  }
  if (_.isString(tenant) && tenant && tenant !== '*') {
    searchParams.append('tenant_id', tenant)
  }
  if (_.isString(uuid) && uuid && uuid !== '*') {
    searchParams.append('obj_id', uuid)
  }

  // Pagination
  searchParams.append('start', '1')
  searchParams.append('page_size', `${upperLimit}`)

  // Sort
  searchParams.append('sort_by', 'created_on')
  searchParams.append('sort_order', 'desc')

  try {
    const {
      data: { resultSet, resultSetCount, totalRecordsCount },
    } = await httpClient.get(workflowMapURL, {
      absoluteBasePath: true,
      params: searchParams,
    })

    let keyNameList: IObject = getKeyNameList() || {}
    const promises = resultSet
      .slice(0, upperLimit)
      .map(async (mapResult: IObject) => {
        const result: IObject = {}

        result.workflowObjectMap = mapResult[Object.keys(mapResult)[0]]
        const {
          obj_id: objID,
          workflow_id: workflowID,
        } = result.workflowObjectMap

        if (keyNameList[objID]) {
          result.object = keyNameList[objID]
        } else {
          try {
            try {
              const objectURL = `/hpcapi/v3/uuid/${objID}`
              await httpClient.get(objectURL, {
                absoluteBasePath: true,
                saveUUIDDef: true,
              })
            } catch (e) {
              console.log(e)
            }
            keyNameList = getKeyNameList() || keyNameList
            if (!keyNameList[objID]) {
              const defaultInfor = {
                name: '',
                'display-name': '',
                type: '',
                device: '',
              }
              keyNameList[objID] = defaultInfor
              setKeyNameList(keyNameList)
            }
            result.object = keyNameList[objID]
          } catch {
            console.log("Can't get the associated object!")
          }
        }

        // No use data for right now
        // try {
        //   const workflowURL = `/hwcapi/v1/workflow/${workflowID}`
        //   const { data: { workflow } } = await httpClient.get(workflowURL, { absoluteBasePath: true })
        //   result.workflow = workflow
        // } catch(e) {
        //   console.log(e)
        // }

        return result
      })
    const promiseResults = await Promise.all(promises)

    return {
      count: promiseResults.length,
      latestData: promiseResults,
    }
  } catch {
    return {
      count: 0,
      latestData: [],
    } as IObject
  }
}

export const getMapDetailInfo = async (
  httpClient: IObject,
  objectId: string,
  workflowId: string,
) => {
  const workflowMapURL = '/datastore/object-workflow-map'
  const searchParams = new URLSearchParams()
  searchParams.append('obj_id', objectId)
  searchParams.append('wf_id', workflowId)

  try {
    const {
      data: { resultSet },
    } = await httpClient.get(workflowMapURL, {
      absoluteBasePath: true,
      params: searchParams,
    })

    if (resultSet.length === 1) {
      return resultSet[0][Object.keys(resultSet[0])[0]]
    } else {
      return false
    }
  } catch {
    return false
  }
}

export const getWFDetailInfo = async (
  httpClient: IObject,
  workflowId: string,
) => {
  try {
    const workflowURL = `/hwcapi/v1/workflow/${workflowId}`
    const {
      data: { workflow },
    } = await httpClient.get(workflowURL, { absoluteBasePath: true })
    return workflow
  } catch (e) {
    console.log(e)
  }
  return false
}

export const restartWorkflow = async (
  httpClient: IObject,
  workflowId: string,
) => {
  const restartURL = `/hwcapi/v1/workflow/${workflowId}/_restart`
  try {
    const { data } = await httpClient.post(
      restartURL,
      {},
      { absoluteBasePath: true },
    )
    return data
  } catch {
    return false
  }
}

export const terminateWorkflow = async (
  httpClient: IObject,
  workflowId: string,
) => {
  const deleteURL = `/hwcapi/v1/workflow/${workflowId}`
  try {
    const { data } = await httpClient.delete(deleteURL, {
      absoluteBasePath: true,
    })
    return data
  } catch {
    return false
  }
}
