import React, { useState } from 'react'
import { Viz } from '@gui-libraries/viz'
import { A10Row, A10Col } from '@gui-libraries/widgets'

import Card from 'src/components/shared/Card'

import styles from './styles/index.module.less'

interface INodeProps {
  title: string
  cpu: number
  memory: number
  disk: number
  cpuTimeline: number[][]
  interval: number
  memoryTimeline: number[][]
  diskTimeline: number[][]
  instance: string
  onTimeRangeChange: (value: string, instance: string) => void
}

const { GaugeChart, SparkLine } = Viz
const GAUGE_HEIGHT = 170

const Node: React.FC<INodeProps> = props => {
  const {
    title,
    cpu,
    memory,
    disk,
    cpuTimeline,
    interval,
    memoryTimeline,
    diskTimeline,
    instance,
    onTimeRangeChange,
  } = props
  const [timeInterval, setTimeInterval] = useState('10m')

  const onTabChange = (value: string) => {
    setTimeInterval(value)
    onTimeRangeChange(value, instance)
  }

  return (
    <Card
      className={styles.nodeCard}
      title={title}
      titleStyle={{ fontSize: '16px' }}
      showTitleDashLine={true}
    >
      <div className={styles.node}>
        <div className={styles.gaugesContainer}>
          <div className={styles.gaugesColumn}>
            <div className={styles.chartTitle}>CPU</div>
            <div>
              <GaugeChart
                name=""
                gaugeTitle=""
                percentage={cpu}
                height={GAUGE_HEIGHT}
              />
            </div>
          </div>
          <div className={styles.gaugesColumn}>
            <div className={styles.chartTitle}>Memory</div>
            <div>
              <GaugeChart
                name=""
                gaugeTitle=""
                percentage={memory}
                height={GAUGE_HEIGHT}
              />
            </div>
          </div>
          <div className={styles.gaugesColumn}>
            <div className={styles.chartTitle}>Disk</div>
            <div>
              <GaugeChart
                name=""
                gaugeTitle=""
                percentage={disk}
                height={GAUGE_HEIGHT}
              />
            </div>
          </div>
        </div>
        <div className={styles.timeSeriesContainer}>
          <A10Row className={styles.timeRangeWrapper}>
            <A10Col
              className={
                timeInterval === '10m'
                  ? styles.activeTimeRange
                  : styles.timeRange
              }
              testID="10m-tab"
              onClick={() => onTabChange('10m')}
            >
              10 Min
            </A10Col>
            <A10Col
              className={
                timeInterval === '1h'
                  ? styles.activeTimeRange
                  : styles.timeRange
              }
              testID="1h-tab"
              onClick={() => onTabChange('1h')}
            >
              1 Hrs
            </A10Col>
            <A10Col
              className={
                timeInterval === '5h'
                  ? styles.activeTimeRange
                  : styles.timeRange
              }
              testID="5h-tab"
              onClick={() => onTabChange('5h')}
            >
              5 Hrs
            </A10Col>
          </A10Row>
          <div className={styles.timeSeriesRow}>
            <div className={styles.rowTitle}>CPU</div>
            <div>
              <SparkLine
                seriesData={cpuTimeline}
                interval={interval}
                showTooltip={true}
                enableLinker={false}
                unit={'%'}
              />
            </div>
          </div>
          <div className={styles.timeSeriesRow}>
            <div className={styles.rowTitle}>Memory</div>
            <div>
              <SparkLine
                seriesData={memoryTimeline}
                interval={interval}
                showTooltip={true}
                enableLinker={false}
                isBytes={true}
              />
            </div>
          </div>
          <div className={styles.timeSeriesRow}>
            <div className={styles.rowTitle}>Disk</div>
            <div>
              <SparkLine
                seriesData={diskTimeline}
                interval={interval}
                showTooltip={true}
                enableLinker={false}
                isBytes={true}
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default Node
