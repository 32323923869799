import React from 'react'
import { A10Icon, A10Tag, A10Row, A10Col } from '@gui-libraries/widgets'
import { getNameColor } from './status'
import StatusBar from 'src/components/ADC/StatusBar'

import serverIcon from './images/server.svg'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

const height = '122px'

export interface IAlertFlowProps {
  data: IObject
  clusterName: string
  deviceName: string
  partitionName: string
}

class EventFlow extends React.Component<IAlertFlowProps> {
  renderPartition = () => {
    const { data, clusterName, deviceName, partitionName } = this.props
    return (
      <div
        className={`${styles.flowBwrapper} ${styles.flowLeft}`}
        style={{ height, width: '265px' }}
      >
        <div style={{ textAlign: 'center', paddingBottom: '12px' }}>
          <img src={serverIcon} />
        </div>

        <A10Row style={{ textAlign: 'left' }}>
          <A10Col
            span={12}
            style={{ borderRight: '1px solid #ccc', minHeight: '70px' }}
          >
            <div className={styles.flowEventInner}>
              <div
                className={styles.ellipsisTextOverflowShort}
                style={{ width: '110px' }}
              >
                {clusterName || deviceName || partitionName ? '' : 'All'}
                {clusterName ? (
                  <span title={clusterName}>
                    <StatusBar isRect={false} text="C" type="up" />
                    {clusterName}
                  </span>
                ) : null}
                <br />
                {deviceName ? (
                  <span title={deviceName}>
                    <StatusBar isRect={false} text="D" type="up" />
                    {deviceName}
                  </span>
                ) : null}
                <br />
                {partitionName ? (
                  <span title={partitionName}>
                    <StatusBar isRect={false} text="P" type="up" />
                    {partitionName}
                  </span>
                ) : null}
              </div>
            </div>
          </A10Col>
          <A10Col span={12} className={styles.flowEventInner}>
            <div className={styles.flowEventInner}>
              <div>Module</div>
              <div>{data.module}</div>
            </div>
          </A10Col>
        </A10Row>
      </div>
    )
  }

  renderPriority = () => {
    const { data } = this.props
    const { severity } = data
    const style = getNameColor(severity)
    // style.length = 2
    return (
      <div className={styles.flowAbspan} style={{ backgroundColor: style[1] }}>
        {style[0]}
      </div>
    )
  }

  renderAction = () => {
    const { data } = this.props
    const label = getNameColor(data.severity)
    return (
      <div
        className={`${styles.flowBwrapper} ${styles.flowRight}`}
        style={{
          height,
          width: '344px',
          left: '500px',
          textAlign: 'left',
        }}
      >
        <div className={styles.flowEventInner}>
          <A10Tag
            style={{ backgroundColor: label[1], color: '#fff', border: '0px' }}
          >
            {label[0]}
          </A10Tag>
        </div>
        <div className={styles.flowEventInner}>
          <div className={styles.pullUpTextOverflow} title={data.msg}>
            {data.msg}
          </div>
          <div>{`#{${data.msg_id}} / {${data.msg_lineage}}`}</div>
        </div>
      </div>
    )
  }

  render() {
    const { data } = this.props
    const { severity } = data
    const style = getNameColor(severity)
    return (
      <div className={styles.flow} style={{ height }}>
        <div
          className={styles.flowLine}
          style={{
            left: '267px',
            width: '228px',
            top: '65px',
            border: `1px dashed ${style[1]}`,
          }}
        />
        <div
          className={styles.flowIcon}
          style={{ left: '489px', color: style[1], top: '53px' }}
        >
          <A10Icon type="right" />
        </div>
        {/* <div className={styles.flowEventUpLebal}>
          <span>App Svc Type:</span>
          {data.app_svc_type ? data.app_svc_type : '--'}
        </div>
        <div className={styles.flowEventDownLebal}>
          <span>App Svc Id:</span>
          {data.app_svc_id ? data.app_svc_id : '--'}
        </div> */}
        {this.renderPartition()}
        {this.renderAction()}
      </div>
    )
  }
}

export default EventFlow
