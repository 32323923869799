import React from 'react'
import { _ } from '@gui-libraries/framework'
import { A10Icon, A10Tooltip } from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import Cards, { Type } from 'src/components/shared/Cards'

export interface IInterfaceManagementProps {
  managementStatus: IObject
  openAutoForm: any
}

const NA = 'N/A'

const InterfaceManagement: React.FC<IInterfaceManagementProps> = props => {
  const { managementStatus = {}, openAutoForm } = props

  const ip = _.get(managementStatus, 'ip.ipv4-address')
  const defaultGateway = _.get(managementStatus, 'ip.default-gateway')
  const ipv6s = _.get(managementStatus, 'ipv6') || []
  const ipv6List = [] as string[]
  const ipv6Gateway = [] as string[]
  ipv6s.map((v6: IObject) => {
    const address = _.get(v6, 'ipv6-addr')
    const gateway = _.get(v6, 'default-ipv6-gateway')
    if (address) {
      ipv6List.push(address)
    }
    if (gateway) {
      ipv6Gateway.push(gateway)
    }
  })

  const getAddress = (ipv4: string, ipv6: string[]) => {
    if (ipv4) {
      return ipv4
    }
    if (Array.isArray(ipv6) && ipv6.length > 0) {
      return ipv6[0]
    }
    return 'N/A'
  }

  const clickEdit = () => {
    if (openAutoForm) {
      openAutoForm('network/interfaces/management')
    }
  }

  const cardContent = [
    {
      name: 'Management',
      type: Type.Description,
      span: 24,
      minWidth: 700,
      direction: 'row',
      data: [
        {
          'IP Address': (
            <A10Tooltip
              overlay={
                <div>
                  {ip && <div>{ip}</div>}
                  {(ipv6List || []).map((address: string) => {
                    return <div key={address}>{address}</div>
                  })}
                </div>
              }
            >
              <div>{getAddress(ip, ipv6List)}</div>
            </A10Tooltip>
          ),
          Netmask: _.get(managementStatus, 'ip.ipv4-netmask') || NA,
        },
        {
          Gateway: (
            <A10Tooltip
              overlay={
                <div>
                  {defaultGateway && <div>{defaultGateway}</div>}
                  {(ipv6Gateway || []).map((gateway: string) => {
                    return <div key={gateway}>{gateway}</div>
                  })}
                </div>
              }
            >
              <div>{getAddress(defaultGateway, ipv6Gateway)}</div>
            </A10Tooltip>
          ),
        },
      ],
      more: !storage.get.IS_OPERATOR_USER ? (
        <span className="edit-content" onClick={clickEdit}>
          <A10Icon app="global" type="edit" /> Edit
        </span>
      ) : null,
    },
  ]

  return (
    <div className="col-md-12 marginBottom10">
      <Cards data={cardContent} className="device-card" />
    </div>
  )
}

export default InterfaceManagement
