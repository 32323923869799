import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
  _,
} from '@gui-libraries/framework'
import { A10Col, A10Input } from '@gui-libraries/widgets'

import { httpClient } from 'src/libraries/httpClient'
import { TroubleShootService } from 'src/services/index'
import BackupList from '../BackupList'
import SlidingBackup from '../BackupForm/slidingForm'
import storage from 'src/libraries/storage'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import { UpgradeModal } from 'src/components/shared/UpgradeModal'
import SlidingRestore from '../RestoreForm/slidingForm'
import ActivateLicense from 'src/components/shared/ActivateLicense'

// tslint:disable-next-line:no-var-requires
const styles = require('./style/index.module.less')

export interface IDeviceBackupsProps extends IA10ContainerDefaultProps {}

export interface IDeviceBackupsState {
  showSlidingPage: boolean
  showRestoreSlidingPage: boolean
  backupCount: number
  isLoading: boolean
  search: string
  maxBackups: number
  remainingBackups: number
  showUpgrade: boolean
  isPeriodicAllowed: boolean
  activeLicense: string
  showAddLicense: boolean
}

class DeviceBackups extends A10Container<
  IDeviceBackupsProps,
  IDeviceBackupsState
> {
  TroubleShootService = new TroubleShootService()
  delayedSearchText = _.debounce(searchString => {
    this.getData(searchString)
  }, 800)
  constructor(props: IDeviceBackupsProps) {
    super(props)
    if (!storage.get.PROVIDER) {
      storage.set.PROVIDER('root')
    }
    this.state = {
      showSlidingPage: false,
      showRestoreSlidingPage: false,
      backupCount: 0,
      isLoading: false,
      search: '',
      maxBackups: -1,
      remainingBackups: 0,
      showUpgrade: false,
      isPeriodicAllowed: false,
      activeLicense: '',
      showAddLicense: false,
    }
  }

  setSlidingPage = (isOpen: boolean) => {
    const { remainingBackups, maxBackups, activeLicense } = this.state
    if (activeLicense !== 'No Active License') {
      if (remainingBackups > 0 || maxBackups === -1) {
        this.setState({ showSlidingPage: isOpen })
      } else {
        this.setState({ showUpgrade: true })
      }
    } else {
      this.setState({ showAddLicense: true })
    }
  }

  setRestoreSlidingPage = (isOpen: boolean) => {
    this.setState({ showRestoreSlidingPage: isOpen })
  }

  onRestoreFormSubmitted = () => {
    const { search } = this.state
    this.setRestoreSlidingPage(false)
    this.getData(search)
    this.getRemainingBackups()
    this.getEntitlements()
  }

  onFormSubmitted = () => {
    this.setSlidingPage(false)
    this.getData()
    this.getRemainingBackups()
    this.getEntitlements()
  }

  componentDidMount() {
    this.getData()
    this.getRemainingBackups()
    this.getEntitlements()
  }

  getData = (search: string = '') => {
    // Start to load data
    this.setState({ isLoading: true })
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    const searchString = search.toLowerCase()

    httpRequest({
      namespace: getNS('BACKUP_LIST'),
      request: async (epicDependence: IObject) => {
        try {
          const actionURL = `/hpcapi/v3/provider/${storage.get.PROVIDER}/device-backup`
          const { data: res } = await httpClient.get(actionURL)
          const backupList = res['device-backup-list'] || []
          const filteredBackupList = backupList.filter(
            (deviceBackup: IObject) => {
              const {
                name,
                host,
                'backup-info-list': backupInfoList,
              } = deviceBackup
              return (
                name?.toLowerCase().includes(searchString) ||
                host?.toLowerCase().includes(searchString) ||
                backupInfoList?.some((backupInstance: IObject) => {
                  const { uuid, description } = backupInstance
                  return (
                    uuid?.toLowerCase().includes(searchString) ||
                    description?.toLowerCase().includes(searchString)
                  )
                })
              )
            },
          )
          this.setState({ isLoading: false, backupCount: backupList.length })
          return filteredBackupList
        } catch (err) {
          // tslint:disable-next-line:no-console
          console.error(err)
        }
        this.setState({ isLoading: false })
        return []
      },
    })
  }

  getRemainingBackups = () => {
    this.setState({ isLoading: true })
    this.TroubleShootService.getBackups(null, null, [
      storage.get.PROVIDER,
      'remaining-backups',
    ])
      .then((resp: IObject) => {
        this.setState({
          maxBackups: resp?.data?.max_backups,
          remainingBackups: resp?.data?.remaining_backups || 0,
        })
      })
      .catch((err: IObject) => {
        this.setState({
          isLoading: false,
          activeLicense: err?.response?.data,
        })
      })
    this.setState({ isLoading: false })
  }

  getEntitlements = () => {
    this.TroubleShootService.getLicenseEntitlements(null, null, [
      storage.get.PROVIDER,
    ])
      .then((resp: IObject) => {
        const { data, status } = resp
        const deviceManagement = data?.['device-management']

        let isAllowed = false
        if (!deviceManagement?.backup && status === 200) {
          isAllowed = true
        } else {
          isAllowed = deviceManagement?.backup?.periodic
        }
        this.setState({ isPeriodicAllowed: isAllowed })
      })
      .catch((err: IObject) => {})
  }

  onSearchText = (e: any) => {
    const searchString = e.target.value
    this.setState({ search: searchString }, () => {
      this.delayedSearchText(searchString)
    })
  }

  render() {
    const {
      showSlidingPage,
      showRestoreSlidingPage,
      backupCount,
      isLoading,
      search,
      maxBackups,
      remainingBackups,
      showUpgrade,
      isPeriodicAllowed,
      showAddLicense,
    } = this.state

    return (
      <>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col>
            <ContentTitle
              title="Device Backups"
              count={maxBackups === -1 ? backupCount : undefined}
              remaining={`${remainingBackups}`}
              total={maxBackups}
            />
          </A10Col>
          <A10Col className={styles.actionsContainer}>
            <A10Input.Search
              type="text"
              onChange={this.onSearchText}
              name="searchBox"
              value={this.state.search}
              placeholder="Search"
              className={styles.searchInput}
            />
            {storage.get.ADMIN_LEVEL === 'provider' &&
              !storage.get.IS_OPERATOR_USER && (
                <>
                  <ActionButton
                    text="Create"
                    onClick={this.setSlidingPage.bind(this, true)}
                    iconProps={{ app: 'global', type: 'add-new' }}
                  />
                  <ActionButton
                    text="Restore"
                    onClick={this.setRestoreSlidingPage.bind(this, true)}
                    iconProps={{ app: 'global', type: 'restore' }}
                  />
                </>
              )}
          </A10Col>
        </ContentHeader>
        <ContentBody>
          <BackupList
            updateList={this.getData}
            getRemainingBackups={this.getRemainingBackups}
            isLoading={isLoading}
            search={search}
          />
        </ContentBody>
        <SlidingBackup
          isOpen={showSlidingPage}
          isPeriodicAllowed={isPeriodicAllowed}
          onRequestClose={this.onFormSubmitted}
        />
        <SlidingRestore
          isOpen={showRestoreSlidingPage}
          onRequestClose={this.onRestoreFormSubmitted}
        />
        <UpgradeModal
          content={`You are using a license which only allows ${maxBackups} Device Backups. For questions or upgrading your license please contact A10 Networks Customer Support or Sales.`}
          visible={showUpgrade}
          onClose={() => {
            this.setState({ showUpgrade: false })
          }}
        />
        <ActivateLicense
          content={'Please add a valid license to your account'}
          visible={showAddLicense}
          onClose={() => {
            this.setState({ showAddLicense: false })
          }}
        />
      </>
    )
  }
}

function mapStateToProps(state: any) {
  return {}
}

export default setupA10Container(DeviceBackups, mapStateToProps)
