import React from 'react'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Row,
  A10Col,
  A10Icon,
  A10Button,
  A10Tooltip,
  A10Table,
  A10Tag,
  A10Modal,
  A10DropdownMenu,
  A10Input,
} from '@gui-libraries/widgets'

import { UrlService, DashboardService } from 'src/services'
import { IDefaultMethods } from 'src/containers/Controller'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
// import { RoundNumber } from 'src/components/shared/RoundNumber'
import Utilities from 'src/services/Utilities/Utilities'
import ReportFormActions from 'src/containers/Controller/Monitor/Reports/ReportSchedule/Forms/ReportFormActions'
import DropdownConstants from 'src/constants/DropdownConstants/DropdownConstants'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import './styles/reportschedule.scss'

export interface IReportScheduleProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  tenantToggled: boolean
}
export interface IReportScheduleState {
  when: string
  showSlidingPage: boolean
  searchString: string
  allData: any
  formData: IObject
  editMode: boolean
  scheduleFormObj: any
  tenantToggled: boolean
  deleteModalState: boolean
  selectedReport: any
  reportAddStateCheck: boolean
}

class ReportSchedule extends A10Container<
  IReportScheduleProps,
  IReportScheduleState
> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  Utilities = new Utilities()
  UrlService = new UrlService()
  DashboardService = new DashboardService()
  DropdownConstants = new DropdownConstants()
  dataLoaded = false
  childForm: any = null
  actionsReq: any[] = []
  durationDropdownOptions = this.DropdownConstants['REPORTS']['duration']
  durationDropdownOld = this.DropdownConstants['REPORTS']['durationOld']

  state: IReportScheduleState = {
    when: 'schedule',
    searchString: '',
    allData: [],
    showSlidingPage: false,
    formData: null,
    editMode: false,
    scheduleFormObj: {},
    tenantToggled: this.props.tenantToggled,
    deleteModalState: false,
    selectedReport: {},
    reportAddStateCheck: false,
  }
  isOperatorUser
  reportColumns = [
    {
      title: 'Report Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
    },
    {
      title: 'Template',
      dataIndex: 'type',
      key: 'type',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'type'),
    },
    {
      title: 'Tag',
      dataIndex: 'tags',
      key: 'tags',
      render: (tags: any) => {
        const tagsArr = []
        tags &&
          tags.forEach((tag: any) => {
            tagsArr.push(
              <A10Tag color="blue" size="small">
                {tag}
              </A10Tag>,
            )
          })
        return tagsArr
      },
    },
    {
      title: 'Scope',
      dataIndex: 'tenantid',
      key: 'tenantid',
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render: (range: string) => {
        const options = this.durationDropdownOptions.concat(
          this.durationDropdownOld,
        )
        const durationArr = options && options.filter(obj => obj.key === range)
        return durationArr.length === 1 ? durationArr[0].label : range
      },
    },
    {
      title: 'Frequency',
      dataIndex: 'occurance',
      key: 'occurance',
    },
    {
      title: <span>Actions</span>,
      dataIndex: 'action',
      key: 'action',
      className: 'td-truncate',
      render: (text: any, record: any) => {
        return (
          <A10Tooltip
            title={this.Utilities.rowToolTip(record, text)}
            placement="topLeft"
            overlayClassName="toolTipWidth"
          >
            {text ? text.join(', ') : ''}
          </A10Tooltip>
        )
      },
      sorter: (a: any, b: any, key: any) =>
        this.Utilities.sortArrayString(a, b, 'action', 0),
    },
    {
      title: 'Start Generating From',
      dataIndex: 'start_generating_from',
      key: 'start_generating_from',
      sorter: (a: IObject, b: IObject) =>
        this.Utilities.sortDate(a, b, 'start_generating_from'),
      render: (key: any, report: IObject) => {
        const date = key !== undefined && key !== null ? key.split('.')[0] : ''
        const timezoneDetails = report?.timezone
          ? JSON.parse(report.timezone)
          : null
        return this.Utilities.convertUTCDateToTimezone(
          date,
          timezoneDetails?.value,
        )
      },
    },
    {
      title: 'Time Zone',
      dataIndex: 'timezone',
      key: 'timezone',
      render: (timezone: string) => {
        // if array
        const timezoneDetails = timezone ? JSON.parse(timezone) : null
        return timezoneDetails ? timezoneDetails.offset : '-'
      },
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (createdBy: string) => {
        return createdBy ? createdBy : '-'
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (key: any) => {
        const clickAction = (component: JSX.Element, index: number) => {
          if (component.key === 'edit') {
            // edit code in case if that is there in future
          } else if (component.key === 'delete') {
            this.setState({
              deleteModalState: true,
              selectedReport: key,
            })
          }
        }
        const dropdownOptions = [<div key="delete">Delete</div>]
        return this.isOperatorUser ? null : (
          <div className="text-right">
            <i>
              <A10DropdownMenu
                title=""
                menu={dropdownOptions}
                style={{ color: '#757575', marginBottom: '-15px' }}
                onClick={clickAction}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            </i>
          </div>
        )
      },
    },
  ]

  expandedColumns = [
    {
      title: '',
      dataIndex: 'path',
      key: 'path',
      width: 30,
      render: (path: any) => {
        const status = path === null ? 'stopped' : 'ongoing'
        return <HealthStatus type={status} hideTooltip={true} />
      },
    },
    // {
    //   title: 'Type',
    //   dataIndex: 'type',
    //   key: 'type',
    //   sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'type'),
    //   render: (type: string) => type === 'userTriggered' ? 'One Time Report' : type
    // },
    {
      title: 'Instance Timestamp',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a: IObject, b: IObject) =>
        this.Utilities.sortDate(a, b, 'created_at'),
      render: (key: any, reportInstance: IObject) => {
        const date = key !== undefined && key !== null ? key.split('.')[0] : ''
        const timezoneDetails = reportInstance?.timezone
          ? JSON.parse(reportInstance.timezone)
          : null
        return this.Utilities.convertUTCDateToTimezone(
          date,
          timezoneDetails?.value,
        )
      },
    },
    {
      title: 'Tags',
      dataIndex: '',
      key: '',
      render: (report: any) => {
        let text: any = '-'
        if (report.path !== null) {
          const segments = report.path.split('/')
          const first = segments.pop()
          const second = segments.pop()
          text = (
            <>
              <A10Tag color="blue" size="small">
                {second}
              </A10Tag>
              <A10Tag color="blue" size="small">
                {first}
              </A10Tag>
            </>
          )
        }
        return text
      },
    },
    {
      title: 'Date Time Range',
      dataIndex: 'occurance',
      key: 'occurance',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (key: any) => {
        return (
          <div className="text-right">
            <A10Icon
              app="global"
              type="download"
              title="Download PDF"
              className="pointer"
              onClick={() => {
                this.downloadReport(key)
              }}
            />
          </div>
        )
      },
    },
  ]

  constructor(
    props: IReportScheduleProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: {
        get: { IS_OPERATOR_USER },
      },
    } = context

    this.isOperatorUser = IS_OPERATOR_USER
  }

  scheduleReport = () => {
    this.setSlidingPage(true)
  }

  getActions = () => {
    const promises: any = []
    this.actionsReq = []
    this.state.allData.forEach((reportObj: any) => {
      if (reportObj.actions && reportObj.actions.length > 0) {
        reportObj.actions.map((actionId: any) => {
          if (this.actionsReq.indexOf(actionId) === -1) {
            this.actionsReq.push(actionId)
            const actionRes = this.DashboardService.getAction(null, null, [
              actionId,
            ])
            promises.push(actionRes)
          }
        })
      }
    })

    Promise.all(promises)
      .then((respArr: any) => {
        const allData = this.state.allData
        allData.map((reportObj: any) => {
          if (reportObj.actions && reportObj.actions.length > 0) {
            const action: any = []
            reportObj.actions.map((actionId: any) => {
              respArr.map((resp: any, index: number) => {
                if (resp.data && resp.data.def_id === actionId) {
                  if (resp.data) {
                    // if required, we can add other details of actions here
                    action.push(resp.data.def_name)
                  }
                }
              })
            })
            reportObj.action = action
          }
        })
        this.setState({
          allData,
        })
      })
      .catch((error: any) => {
        console.log('error in gettinhg action data', error)
        const message = this.Utilities.returnErrorMessage(error)
        this.Utilities.showMessage('Unable to load actions', 0, 0, message)
      })
    return
  }

  componentDidMount() {
    if (
      !this.dataLoaded ||
      this.state.tenantToggled !== this.props.tenantToggled
    ) {
      this.loadScheduleReports()
    }
  }
  componentDidUpdate() {
    if (this.state.tenantToggled !== this.props.tenantToggled) {
      this.loadScheduleReports()
    }
  }

  loadScheduleReports = () => {
    const {
      storage: {
        get: { PROVIDER: provider, CURRENT_TENANT: tenant },
        set,
      },
    } = this.context

    const headers = this.Utilities.getXAccountHeaderDetails(false)
    if (!headers) {
      return
    }
    const payload = {
      tenantid: tenant.name,
      providerid: provider,
    }
    const scheduleReportResponse = this.DashboardService.getScheduleReports(
      headers,
      payload,
      [provider, tenant.name],
    )
    scheduleReportResponse
      .then((response: any) => {
        if (response.data && response.data.success) {
          this.dataLoaded = true
          let reports = response.data.reports.resultSet

          reports = this.processReportsData(reports)
          this.actionsReq = []
          set.ALL_SCHEDULE_REPORT_DATA(reports)
          this.setState({
            allData: reports,
            tenantToggled: this.props.tenantToggled,
            reportAddStateCheck: false,
            searchString: '',
          })
          this.getActions()
        }
      })
      .catch((error: any) => {
        this.dataLoaded = true
        console.log(error.response, this.dataLoaded)
        const message = this.Utilities.returnErrorMessage(error)
        this.Utilities.showMessage('Unable to load reports', 0, 0, message)
        this.setState({
          reportAddStateCheck: false,
        })
      })
  }

  processReportsData = (result: any[]) => {
    const {
      storage: {
        get: { ALL_REPORT_DATA: reports },
      },
    } = this.context

    for (const key in result) {
      if (result.hasOwnProperty(key)) {
        result[key].instances = reports.filter(
          (report: any) => report.reportid === result[key].name,
        )
      }
    }
    return result
  }

  setSlidingPage = (isOpen: boolean, data?: IObject, editMode?: boolean) => {
    this.setState({
      showSlidingPage: isOpen,
      formData: data,
    })
  }

  search = (e: any) => {
    const searchStr = e.target.value
    this.Utilities.search(
      this,
      searchStr,
      {
        firstKey: 'name',
        secondKey: 'type',
      },
      {
        stateName: 'allData',
        storageName: 'ALL_SCHEDULE_REPORT_DATA',
      },
    )
  }

  handleOk = (isEditCallback: any) => {
    const {
      storage: {
        get: { PROVIDER: provider, CURRENT_TENANT: tenant },
      },
    } = this.context

    const headers = this.Utilities.getXAccountHeaderDetails(false)
    if (!headers) {
      return
    }
    const { showMessage } = this.Utilities
    const payload = {
      provider: provider,
      id: this.state.selectedReport.id,
      name: this.state.selectedReport.name,
      type: this.state.selectedReport.type,
      workflowid: this.state.selectedReport.workflowid,
    }
    const deleteScheduledReport = this.DashboardService.deleteScheduledReport(
      headers,
      payload,
      [provider, tenant.name],
    )
    deleteScheduledReport
      .then((resp: any) => {
        showMessage('Schedule Report deleted successfully', 1, 0)
        this.dataLoaded = false
        this.setState({
          deleteModalState: false,
          selectedReport: {},
        })
        this.loadScheduleReports()
      })
      .catch((error: any) => {
        const message = this.Utilities.returnErrorMessage(error)
        showMessage('Unable to delete schedule report.', 0, 0, message)
        this.setState({
          deleteModalState: false,
          selectedReport: {},
        })
        return false
      })
    return true
  }

  handleCancel = () => {
    this.setState({
      deleteModalState: false,
      selectedReport: {},
    })
  }

  addAlert = () => {
    this.setSlidingPage(true, {})
  }

  renderInstanceDetails = (report: any) => {
    return (
      <div className="table-row-padding">
        <A10Row className="desc-container">
          <A10Col className="title" span={2}>
            Description
          </A10Col>
          <A10Col span={18}>
            <A10Tooltip placement="topLeft" title={report.description}>
              <div className="content">{report.description}</div>
            </A10Tooltip>
          </A10Col>
        </A10Row>
        <A10Table
          columns={this.expandedColumns}
          dataSource={report.instances}
          size="small"
        />
      </div>
    )
  }

  loadDelayedReports = () => {
    this.setState({ reportAddStateCheck: true, searchString: '' })
    this.loadScheduleReports()
  }

  downloadReport = (reportInstance: any) => {
    const {
      storage: {
        get: { PROVIDER: provider, CURRENT_TENANT: tenant },
      },
    } = this.context

    const path = reportInstance.path
    const cephid = reportInstance.ceph_id
    const reportid = reportInstance.reportid
    const { showMessage } = this.Utilities
    const pdfId = cephid ? cephid : path
    const payload = {
      path: pdfId,
      name: reportInstance.name,
      type: reportInstance.type,
      tenantId: reportInstance.tenantid,
    }
    let name: any = path.split('/')
    if (name.length > 0) {
      name = name[name.length - 2] + '_' + name[name.length - 1]
      if (reportid) {
        name = reportid + '_' + name
      }
    }
    const headers: any = this.Utilities.getXAccountHeaderDetails(false)
    const downloadReport = this.DashboardService.downloadReport(
      headers,
      payload,
      [provider, tenant.name],
    )
    downloadReport
      .then((resp: any) => {
        showMessage('Report downloaded', 1, 0)
        const blob = new Blob([resp.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = name
        link.click()
      })
      .catch((error: any) => {
        const message =
          error && error.response
            ? error.response.status && error.response.status === 403
              ? 'Access denied'
              : error.response.data && error.response.data.message
              ? error.response.data.message
              : ''
            : error.message
            ? error.message
            : ''
        showMessage('Unable to download report.', 0, 0, message)
      })
  }

  render() {
    const {
      storage: {
        get: { CURRENT_TENANT: tenant },
      },
    } = this.context

    const { showSlidingPage, allData, when } = this.state
    return (
      <>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col style={{ display: 'flex', alignItems: 'center' }}>
            <ContentTitle
              title="Scheduled Reports"
              count={this.state.allData ? this.state.allData.length : 0}
            />
            {this.state.reportAddStateCheck && (
              <A10Tooltip
                title={'Scheduled report generation in progress.'}
                placement="right"
              >
                <A10Icon
                  style={{ marginLeft: '10px', color: '#0b608d' }}
                  type="sync"
                  spin
                />
              </A10Tooltip>
            )}
          </A10Col>
          <A10Col style={{ display: 'flex' }}>
            <A10Input.Search
              type="text"
              onChange={this.search}
              name="searchBox"
              value={this.state.searchString}
              placeholder="Search"
              style={{ marginRight: '6px' }}
            />

            <ActionButton
              text="Refresh"
              onClick={() => {
                this.loadScheduleReports()
              }}
              iconProps={{ app: 'global', type: 'refresh' }}
            />
            {this.isOperatorUser ? null : (
              <ActionButton
                text="Schedule Report"
                onClick={() => {
                  this.scheduleReport()
                }}
                iconProps={{ app: 'global', type: 'add-new' }}
              />
            )}
          </A10Col>
        </ContentHeader>
        <ContentBody>
          <A10Table
            columns={this.reportColumns}
            dataSource={this.state.allData}
            expandedRowRender={this.renderInstanceDetails}
            pageSize={20}
            size="small"
            loading={
              !this.dataLoaded
                ? {
                    indicator: (
                      <div>
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
          />
        </ContentBody>
        <A10Modal
          title="Delete Scheduled Report"
          visible={this.state.deleteModalState}
          onOk={this.handleOk.bind(this, false)}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk.bind(this, false)}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            Do you want to delete this report -{' '}
            {this.state.selectedReport ? this.state.selectedReport.name : ''}{' '}
            from {tenant && tenant['display-name']}?
          </p>
        </A10Modal>
        {showSlidingPage && (
          <ReportFormActions
            show={showSlidingPage}
            when={when}
            onClose={this.setSlidingPage.bind(this, false)}
            loadReports={this.loadDelayedReports}
            scheduleData={allData}
            currentTab='schedule'
          />
        )}
      </>
    )
  }
}

export default setupA10Container(ReportSchedule)
