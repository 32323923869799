import React from 'react'
import classnames from 'classnames'

import styles from './styles/index.module.less'

interface ICardProps {
  className?: string
  title: string
  toolbar?: React.ReactNode
  children?: React.ReactNode
  style?: IObject
  titleStyle?: IObject
  showTitleDashLine?: boolean
}

const Card: React.FC<ICardProps> = props => {
  const {
    className,
    title,
    toolbar,
    children,
    style,
    titleStyle,
    showTitleDashLine,
  } = props

  return (
    <div className={classnames(className, styles.container)} style={style}>
      {title && (
        <div className={styles.header}>
          <span
            className={classnames(styles.title, {
              [styles.titleWithDashLine]: showTitleDashLine,
            })}
            style={titleStyle}
          >
            {title}
          </span>
          {toolbar && <div className={styles.toolbar}>{toolbar}</div>}
        </div>
      )}
      <div className={styles.body}>{children}</div>
    </div>
  )
}

export default Card
