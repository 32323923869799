import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
} from '@gui-libraries/framework'
import {
  A10Checkbox,
  A10Table,
  A10DropdownMenu,
  A10Message,
  A10Tag,
  A10Modal,
  A10Icon,
  A10Button,
  A10Loader,
} from '@gui-libraries/widgets'

import CertDetail from '../CertDetail'
import { httpClient } from 'src/libraries/httpClient'
import { getLPObject } from 'src/redux/httpServices/ADC/common/current_logical_partition'
import storage from 'src/libraries/storage'
import deployWrapper from '../../DeployWrapper'
// tslint:disable-next-line:no-var-requires
const moment = require('moment')
// tslint:disable-next-line:no-var-requires
const styles = require('./style/index.module.less')
// tslint:disable-next-line:no-var-requires
const deployStyles = require('../../styles/index.module.less')

export interface ICertListProps extends IA10ContainerDefaultProps {
  data: any[]
  tenant: string
  logicalPartition: string
  isLoading?: boolean
  updateList: any
  search: string
  scope: string
  handleDeploy: any
}

export interface ICertListState {
  name: string
  type: string
  id?: string
  showSlidingPage: boolean
  deleteModalState: boolean
  uuid: string
  dirtyList: IObject[]
  forceDelete: boolean
}

class CertList extends A10Container<ICertListProps, ICertListState> {
  ref = React.createRef()

  defaultCertColumns: any[]
  constructor(props: ICertListProps) {
    super(props)
    this.state = {
      name: null,
      type: null,
      id: null,
      showSlidingPage: false,
      deleteModalState: false,
      uuid: null,
      dirtyList: [],
      forceDelete: false,
    }
    this.defaultCertColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: string, record: any, index: number) => {
          return (
            <div className="text-with-avatar">
              <span>{text}</span>
            </div>
          )
        },
      },
      {
        title: 'Type',
        render: (record: any) => {
          switch (record.type) {
            case 'cert':
              return 'SSL Certificate'
            case 'key':
              return 'Key'
            case 'ca':
              return 'CA'
            default:
              return ''
          }
        },
      },
      {
        title: 'Expiring',
        dataIndex: '',
        key: '',
        render: (record: any) => {
          if (record.type === 'cert') {
            let expirationDate = ''
            let showExpired = false
            let showExpireSoon = false
            let dateColor = '#363636'
            try {
              expirationDate = moment(
                record.certContent.notAfter.value.toString(),
              )
              const now = moment()
              const dayDiff = expirationDate.diff(now, 'day', true)
              showExpireSoon = dayDiff < 7 && dayDiff > 0
              showExpired = dayDiff < 0
              if (showExpireSoon) {
                dateColor = '#f5a623'
              }
              if (showExpired) {
                dateColor = '#d0021b'
              }
            } catch (err) {
              console.log(err)
            }
            const dateStyle = {
              marginRight: '5px',
              color: dateColor,
            }
            if (expirationDate) {
              return (
                <div>
                  <span style={dateStyle}>{expirationDate.format('L')}</span>
                  {showExpireSoon ? <A10Tag>Expiring Soon</A10Tag> : null}
                  {showExpired ? <A10Tag>Expired</A10Tag> : null}
                </div>
              )
            } else {
              return null
            }
          } else {
            return null
          }
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
    ]
    if (
      this.props.scope !== 'provider' ||
      (storage.get.ADMIN_LEVEL === 'provider' && !storage.get.IS_OPERATOR_USER)
    ) {
      this.defaultCertColumns.push({
        title: '',
        dataIndex: '',
        key: '',
        render: (record: any) => {
          return (
            <div className={styles.editColumn}>
              <i>
                <A10DropdownMenu
                  menu={[
                    <div key="delete">Delete</div>,
                    <div key="deploy">Deploy</div>,
                  ]}
                  style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={this.onClickAction.bind(this, record)}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </i>
            </div>
          )
        },
      })
    }
  }

  getRowClassName = (record: IObject, index: number) => {
    const { type } = record
    const rowColor = index % 2 ? 'even' : 'odd'
    if (type === 'key') {
      return `${rowColor} hide-expand-icon`
    }
    return rowColor
  }

  onClickAction = (record: any, component: JSX.Element) => {
    if (component.key === 'delete') {
      this.setState({
        deleteModalState: true,
        uuid: record.uuid,
        name: record.name,
        type: record.type,
        forceDelete: false,
      })
    } else if (component.key === 'deploy') {
      if (this.props.handleDeploy) {
        this.props.handleDeploy(record)
      }
    }
  }

  renderCertDetail = (record: any) => {
    const { tenant } = this.props
    return <CertDetail record={record} tenant={tenant} />
  }

  handleOk = async (e: React.MouseEvent<any>) => {
    const {
      updateList,
      tenant,
      logicalPartition,
      search,
      A10Dispatchers: { httpRequest },
    } = this.props
    const { name, type, forceDelete } = this.state
    try {
      const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${logicalPartition}/`
      const fileType = 'ssl-' + type
      let actionUri = apiPrefix + `file/${fileType}/${name}`
      if (forceDelete) {
        actionUri += '?force=true'
      }
      await httpClient.delete(actionUri)
      updateList(search)
      httpRequest(getLPObject(tenant, logicalPartition))
      this.setState({ deleteModalState: false, forceDelete: false })
      A10Message.success('Success: Certificate has been deleted')
    } catch (e) {
      // do nothing
      console.error(e)
      this.setState({ deleteModalState: false, forceDelete: false })
      A10Message.error('Error: Failed to delete the Certificate')
    }
  }

  handleCancel = (e: React.MouseEvent<any>) => {
    this.setState({ deleteModalState: false })
  }

  onCheckForceDelete = (e: any) => {
    this.setState({ forceDelete: e.target.checked })
  }

  getRowKey = (record: IObject) => {
    return record.uuid
  }

  render() {
    const { data = [] } = this.props

    return (
      <div ref={this.ref}>
        <A10Table
          className={`${styles.certList} hc-list`}
          rowClassName={this.getRowClassName}
          rowKey={this.getRowKey}
          columns={this.defaultCertColumns}
          dataSource={data.map((item: IObject, index: number) => {
            item.key = index
            return item
          })}
          size="small"
          expandedRowRender={this.renderCertDetail}
          loading={
            this.props.isLoading && {
              indicator: <A10Loader container={this.ref} />,
            }
          }
        />
        <A10Modal
          title={
            <span>
              <A10Icon
                app="global"
                type="question"
                style={{ color: '#ffba47' }}
                width="20px"
                height="20px"
              />{' '}
              Confirmation
            </span>
          }
          className={deployStyles.deployConfrim}
          visible={this.state.deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>Do you want to delete this Item?</p>
          <A10Checkbox
            checked={this.state.forceDelete}
            onChange={this.onCheckForceDelete}
          >
            Force Delete
          </A10Checkbox>
        </A10Modal>
      </div>
    )
  }
}

function mapStateToProps(state: any, props: ICertListProps) {
  return {
    data: state.A10Data.getIn(getNS('CERT_LIST'), []),
  }
}

export default setupA10Container(deployWrapper(CertList), mapStateToProps)
