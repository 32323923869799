import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import { A10Modal, A10Button } from '@gui-libraries/widgets'
import parameters from 'parameters'
import ReactLoading from 'react-loading'

import { IDefaultMethods } from 'src/containers/Controller'
import { DashboardService, Utilities } from 'src/services/index'
import { LaunchAppForm } from 'src/containers/Controller/Apps/InstalledApps/Forms/LaunchAppForm'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { App } from '../../App'

export interface IMyAppProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  onUninstall?(): void
  app: IObject
  appView: string
}
export interface IMyAppState {
  deleteModalState: boolean
  showSlidingPage: boolean
  launchApp: boolean
  launchAppMainCtnr: boolean
  launchAppObj: any
  loadingIcon: boolean
}

class MyApp extends A10Container<IMyAppProps, IMyAppState> {
  static defaultProps = {
    app: {},
    appView: 'tile',
    defaultMethods: () => {},
  }
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  currTimestamp = new Date().getTime()

  state = {
    deleteModalState: false,
    launchApp: false,
    showSlidingPage: false,
    launchAppMainCtnr: false,
    launchAppObj: {
      tenant: '',
      appService: '',
      app: {},
    },
    loadingIcon: false,
  }
  currentTenant
  isOperatorUser

  constructor(
    props: IMyAppProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: { get },
    } = context

    this.currentTenant = get.CURRENT_TENANT
    this.isOperatorUser = get.IS_OPERATOR_USER
  }

  componentDidUpdate() {
    const {
      storage: { get },
    } = this.context

    this.currentTenant = get.CURRENT_TENANT
  }

  handleChange = (data: any) => {
    // @ts-ignore
    this.setState({
      launchAppObj: data,
    })
  }

  gotoApp = (app: any) => {
    if (this.Utilities.checkAppForLaunchWOAppsvc(app, 'OR')) {
      this.launchAppURLPage(app)
    } else {
      const launchAppObj = {
        tenant: '',
        appService: '',
        app: app,
      }
      this.setState({
        launchAppObj: launchAppObj,
        launchApp: true,
        showSlidingPage: true,
        onAbout: false,
      })
    }
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({
      showSlidingPage: isOpen,
    })
  }

  uninstallApp = () => {
    this.setState({
      deleteModalState: true,
    })
  }

  onLaunchAppMainCtnr = (app: any) => {
    if (this.Utilities.checkAppForLaunchWOAppsvc(app, 'OR')) {
      this.launchAppURLPage(app)
    } else {
      const launchAppObj = {
        tenant: '',
        appService: '',
        app,
      }
      this.setState({
        showSlidingPage: true,
        launchAppMainCtnr: true,
        onAbout: false,
        launchAppObj,
      })
    }
  }

  handleOk = () => {
    this.deleteApp(this.props.app)
  }

  handleCancel = () => {
    if (this.state.loadingIcon) {
      return false
    }
    this.setState({
      deleteModalState: false,
    })
  }

  deleteApp = (app: any) => {
    const {
      storage: { get },
    } = this.context

    let targetUrl = get.TARGET_URL
    var deleteAppResponse = this.DashboardService.disableHCTenantApp(
      null,
      null,
      [targetUrl, this.currentTenant.name, app.name, app.version],
    )
    this.setState({
      loadingIcon: true,
    })
    deleteAppResponse
      .then((response: any) => {
        this.handleCancel()
        this.Utilities.showMessage('SUCCESS_APP_DISABLE', 1, true)
        this.props.onUninstall()
        this.setState({
          loadingIcon: false,
          deleteModalState: false,
        })
      })
      .catch((error: any) => {
        const message =
          error && error.response && error.response.data
            ? error.response.data.message
            : (error && error.message) || ''
        this.Utilities.showMessage('FAILURE_APP_DISABLE', 0, true, message)
        this.handleCancel()
        this.setState({
          loadingIcon: false,
          deleteModalState: false,
        })
      })
  }

  launchAppURLPage = (app: any) => {
    const {
      storage: { get },
    } = this.context

    const providerName = get.PROVIDER
    const tenantObj = get.CURRENT_TENANT
    let appSvcObj = JSON.parse(this.state.launchAppObj.appService || '{}')
    const sessionTimeout = get.SESSION_TIMEOUT_INITIAL

    const queryString =
      '?user_id=' +
      get.USER_ID +
      '&api_ep=' +
      encodeURI(parameters.BASE_URL) +
      '&provider=' +
      providerName +
      '&token=' +
      get.USER_SESSION_ID +
      '&timeout=' +
      (sessionTimeout ? sessionTimeout : '1') +
      (tenantObj.name ? '&tenant=' + tenantObj.name : '') +
      (tenantObj.uuid ? '&tenantUUID=' + tenantObj.uuid : '') +
      (appSvcObj.app_svc_id
        ? '&app_svc=' +
          appSvcObj.name +
          '&app_service_id=' +
          appSvcObj.app_svc_id +
          '&app_svc_type=' +
          appSvcObj['app_svc_type'] +
          '&action=config'
        : '')
    const ipv6Url = this.Utilities.checkIPv6BaseURL()
    const baseURL = ipv6Url
      ? parameters.BASE_URL.replace(':8443/api/v2', '') + encodeURI('/hcapps')
      : encodeURI(parameters.BASE_URL.replace(':8443/api/v2', '') + '/hcapps')
    const href = `${baseURL}/${this.props.app.name}_${this.props.app.version}${queryString}&ts=${this.currTimestamp}`

    window.open(href, '_blank')

    this.setState({
      showSlidingPage: false,
    })
  }

  onClickDropdownMenu = (component: JSX.Element) => {
    const { app } = this.props
    const action = component.key

    if (action === 'Disable') {
      this.uninstallApp()
    } else if (action === 'Go_to_App') {
      this.gotoApp(app)
    }
  }

  render() {
    const { app } = this.props
    const {
      deleteModalState,
      showSlidingPage,
      launchApp,
      launchAppMainCtnr,
      launchAppObj,
      loadingIcon,
    } = this.state

    const displayedVersion = app?.version ? app.version.split('-')[1] : ''
    return (
      <>
        <App
          app={app}
          onClick={() => this.onLaunchAppMainCtnr(app)}
          onClickDropdownMenu={this.onClickDropdownMenu}
        />
        <A10Modal
          title="Disable App"
          visible={deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={loadingIcon}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
              disabled={loadingIcon}
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            {`Are you sure to Disable the app '${
              app.name
            } ${displayedVersion}' from the tenant '${this.currentTenant[
              'display-name'
            ] || this.currentTenant.name}'?`}
          </p>
          {loadingIcon ? (
            <div className="">
              <ReactLoading
                type="bars"
                color="#4a90e2"
                height={40}
                width={40}
              />
            </div>
          ) : null}
        </A10Modal>

        {launchApp || launchAppMainCtnr ? (
          <FormatSlidingPage
            isOpen={showSlidingPage}
            title={`${app.name} ${displayedVersion}`}
            description="Please select App service"
            onRequestClose={this.setSlidingPage.bind(this, false)}
            saveText="Proceed"
            disableSave={
              launchAppObj.appService.length <= 0 &&
              this.Utilities.checkAppForLaunchWOAppsvc(app, 'NOR')
            }
            onRequestOk={() => this.launchAppURLPage(app)}
          >
            <LaunchAppForm
              launchAppObj={launchAppObj}
              handleChange={this.handleChange}
              appLaunchWOApp={this.Utilities.checkAppForLaunchWOAppsvc(
                app,
                'OR',
              )}
            />
          </FormatSlidingPage>
        ) : null}
      </>
    )
  }
}

export default setupA10Container(MyApp)
