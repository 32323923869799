import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { A10SlidingPage, A10Loader } from '@gui-libraries/widgets'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import Descriptions from 'src/components/shared/Descriptions'
import ActionButton from 'src/components/shared/ActionButton'
import { NA } from './VrrpaOverview'

import styles from './styles/index.module.less'

export interface IPeerGroupProps {
  device: string
  partition: string
  renderForm: (value: IObject) => JSX.Element
  openNotification: (type: string, title: string, description: string) => void
  clustersUpdated: boolean
}

const PeerGroup: React.FC<IPeerGroupProps> = props => {
  const provider = storage.get.PROVIDER
  const isOperatorUser = storage.get.IS_OPERATOR_USER

  const openFormPage = useCallback(() => setSlidingPageOpen(true), [])
  const closeFormPage = useCallback(() => setSlidingPageOpen(false), [])

  const container = useRef(null)
  const { device, partition, renderForm, clustersUpdated } = props
  const [data, setData] = useState<IObject[]>([
    {
      'IP Address': [],
    },
  ])
  const [slidingPageOpen, setSlidingPageOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const apiPrefix = useMemo(() => {
    return `/hpcapi/v3/provider/${provider}/device/${device}/partition/${partition}`
  }, [provider, device, partition])

  const submitCallback = () => {
    closeFormPage()
    getData()
  }

  const getData = () => {
    httpClient
      .get(`${apiPrefix}/vrrp-a/peer-group`)
      .then(res => {
        const ips =
          res?.data?.['peer-group']?.peer?.['ip-peer-address-cfg'] || []
        setData([
          {
            'IP Address':
              ips.length > 0 ? (
                <div className={styles.collection}>
                  {ips.map((ip: string) => {
                    const _ip = ip['ip-peer-address']
                    return _ip ? (
                      <span className={styles.collectionValue}>{_ip}</span>
                    ) : null
                  })}
                </div>
              ) : (
                NA
              ),
          },
        ])
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    getData()
  }, [apiPrefix, clustersUpdated])

  return (
    <>
      <div ref={container} className={styles.sectionContent}>
        {loading ? (
          <A10Loader container={container} />
        ) : (
          <>
            <Descriptions data={data} direction="row" />
            {!isOperatorUser && (
              <ActionButton
                text="Edit"
                size="default"
                onClick={openFormPage}
                iconProps={{ app: 'global', type: 'edit' }}
                className={styles.actionButton}
              />
            )}
          </>
        )}
      </div>
      <A10SlidingPage
        isOpen={slidingPageOpen}
        modalSize="large"
        onRequestClose={closeFormPage}
      >
        {slidingPageOpen &&
          renderForm({
            schemaPath: 'system/vrrpa/settings/peer-group',
            apiPrefix,
            successCallback: submitCallback,
            errorCallback: submitCallback,
            cancelCallback: closeFormPage,
          })}
      </A10SlidingPage>
    </>
  )
}

export default PeerGroup
