import React from 'react'
import { A10Icon } from '@gui-libraries/widgets'

const setupIconStyle = { fontSize: 34 }

const SetupInfrastructure = () => {
  return (
    <div id="gettingStarted-3">
      <div className="gettingStarted-content-header">
        Set up Infrastructure & Harmony Apps
      </div>
      <div className="gettingStarted-content-horizontal">
        <div className="horizontal-item">
          <div className="horizontal-title-items">
            <div className="horizontal-icon">
              <A10Icon
                app="harmony-controller"
                type="devices"
                style={setupIconStyle}
              />
            </div>
            <div className="horizontal-title">
              Add Cluster and Device (Required)
            </div>
          </div>
          <div className="horizontal-rectangle" id="clusterDeviceImage" />
          <div className="horizontal-text">
            Create clusters and register Thunder devices. A Thunder device can
            only be registered under a cluster. A cluster may contain a single
            device or an HA pair or multiple devices (VCS cluster).
          </div>
        </div>
        <div className="horizontal-item">
          <div className="horizontal-title-items">
            <div className="horizontal-icon">
              <A10Icon
                app="harmony-controller"
                type="partition-config"
                style={setupIconStyle}
              />
            </div>
            <div className="horizontal-title">
              Assign Partition to Tenant (Required)
            </div>
          </div>
          <div className="horizontal-rectangle" id="provisionImage" />
          <div className="horizontal-text">
            A Thunder device supports multiple layer 3 virtualized partitions
            (L3V). Provision cluster partitions to tenants.
          </div>
        </div>
        <div className="horizontal-item">
          <div className="horizontal-title-items">
            <div className="horizontal-icon">
              <A10Icon
                app="harmony-controller"
                type="apps"
                style={setupIconStyle}
              />
            </div>
            <div className="horizontal-title">App Catalog</div>
          </div>
          <div className="horizontal-rectangle" id="appsImage" />
          <div className="horizontal-text">
            Various service management applications which you have licensed are
            available via app catalog.
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetupInfrastructure
