import React from 'react'
import moment from 'moment-timezone'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Message } from '@gui-libraries/widgets'

import globalConfig from 'src/settings/config'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import UpgradeForm, { IFormData } from './index'
import storage from 'src/libraries/storage'

export interface ISlidingUpgradeProps extends IA10ContainerDefaultProps {
  isOpen: boolean
  isEdit?: boolean
  clusterName?: string
  deviceLevelUpgrade?: boolean
  deviceName?: string
  imageName?: string
  formData?: IFormData
  onRequestClose: (idList: string[], clusterList?: string[]) => void
}

export interface ISlidingUpgradeStates {
  provider: string
  formData?: IFormData
  disableSave: boolean
}

class SlidingUpgrade extends A10Container<
  ISlidingUpgradeProps,
  ISlidingUpgradeStates
> {
  constructor(props: ISlidingUpgradeProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER || '',
      disableSave: true,
    }
  }

  componentWillReceiveProps(nextProps: IObject) {
    const state = { ...this.state }
    const { formData } = nextProps
    state.formData = formData
    this.setState(state)
  }

  onFormChange = (data: IFormData) => {
    const state = { ...this.state }
    state.disableSave = !(
      (
        data['device-upgrade'].image &&
        ((this.props.deviceLevelUpgrade &&
          data['device-upgrade'].deviceList.length) ||
          (!this.props.deviceLevelUpgrade &&
            data['device-upgrade'].clusterList.length)) &&
        (data['device-upgrade'].when === 'now' ||
          (data['device-upgrade'].when === 'schedule' &&
            !!data['device-upgrade'].starts &&
            !!data['device-upgrade'].timezone))
      )
      // !!data['device-upgrade'].date &&
      // !!data['device-upgrade'].time
    )
    state.formData = data
    this.setState(state)
  }

  onRequestOk = () => {
    const { onRequestClose } = this.props
    const formData = this.state.formData as IFormData
    if (formData['device-upgrade'].when === 'schedule') {
      const timezoneObj = JSON.parse(formData['device-upgrade'].timezone)
      moment.tz.setDefault(timezoneObj.value)
      const now = moment()
      if (moment(now).isAfter(formData['device-upgrade'].starts, 'day')) {
        A10Message.error(
          'Scheduled time cannot be before current date of the selected timezone',
          5,
        )
        return
      } else if (
        moment(now).isSame(formData['device-upgrade'].starts, 'day') &&
        moment(now).isAfter(formData['device-upgrade'].starts, 'second')
      ) {
        A10Message.error(
          'Scheduled time cannot be before current date & time of the selected timezone',
          5,
        )
        return
      }
    }

    this.execUpgrade().then(
      res => {
        if (formData['device-upgrade'].when === 'now') {
          if (this.props.deviceLevelUpgrade) {
            const deviceUpgradeResp = res[0].data
            let jobIdList: string[] = [],
              deviceList: string[] = [],
              deviceFailed = ''
            deviceUpgradeResp.map((deviceResp: any) => {
              if (deviceResp['schedule-status'] == 'SUCCESS') {
                jobIdList.push(deviceResp.id)
                deviceList.push(deviceResp['device-name'])
              } else {
                deviceFailed +=
                  (deviceFailed.length > 0 ? ', ' : '') +
                  deviceResp['device-name']
              }
            })
            if (deviceList.length > 0) {
              onRequestClose(jobIdList, deviceList)
            } else {
              A10Message.error('Upgrade failed for devices ' + deviceFailed, 5)
              onRequestClose(null)
            }
          } else {
            const jobIdList = res.map(item => item.data.job.id)
            const clusterList = formData['device-upgrade'].clusterList
            onRequestClose(jobIdList, clusterList)
          }
        } else {
          A10Message.success('Upgrade is scheduled successfully.', 5)
          onRequestClose(null)
        }
      },
      err => {
        if (
          err.response &&
          err.response.status === 400 &&
          err.response.data &&
          err.response.data.message
        ) {
          A10Message.error(err.response.data.message, 5)
        }
        console.log(err)
      },
    )
  }

  async execUpgrade() {
    const {
      EPIC_DEPENDENCIES: { httpClient },
    } = globalConfig
    const formData = this.state.formData as IFormData
    let upgradeSchedule: any,
      upgradeTaskList: any = []
    if (formData['device-upgrade'].when === 'schedule') {
      const startDate = formData['device-upgrade'].starts
      // const startTime = formData['device-upgrade'].time
      const utcStartDate = startDate.utc()
      // const utcStartTime = startTime.utc()
      const year = utcStartDate.get('year')
      const month = utcStartDate.get('month')
      const day = utcStartDate.get('date')
      const hour = startDate.get('hour')
      const minute = startDate.get('minute')
      const second = startDate.get('second')
      const scheduleTime = moment()
        .utc()
        .year(year)
        .month(month)
        .date(day)
        .hour(hour)
        .minute(minute)
        .second(second)
      const timeString = scheduleTime.toISOString()
      upgradeSchedule = {
        'start-date': timeString,
        'is-repeating': false,
        timezone: formData['device-upgrade'].timezone,
      }
    }

    const imageObj = formData['device-upgrade'].image

    if (this.props.deviceLevelUpgrade) {
      const url = `/hocapi/v1/provider/${this.state.provider}/_upgrade_devices`
      const upgradePayload = {
        upgrade: {
          data: {
            'image-name': imageObj.name,
            'image-id': imageObj['file-key'],
            'upgrade-drive': formData['device-upgrade'].drive,
            'upgrade-disk': formData['device-upgrade'].disk,
            'save-config-before-upgrade': formData['device-upgrade'].save,
            'reboot-after-upgrade': formData['device-upgrade'].reboot,
            description: formData['device-upgrade'].description,
            'device-list': [],
          },
          schedule: {
            'is-repeating': false,
          },
        },
      }
      formData['device-upgrade'].deviceList.map((device: string) => {
        upgradePayload.upgrade.data['device-list'].push(device)
      })

      if (formData['device-upgrade'].when === 'schedule') {
        upgradePayload.upgrade.schedule = upgradeSchedule
      }
      upgradeTaskList.push(
        httpClient.post(url, upgradePayload, { absoluteBasePath: true }),
      )
    } else {
      upgradeTaskList = formData['device-upgrade'].clusterList.map(
        (cluster: string) => {
          const url = `/hocapi/v1/provider/${this.state.provider}/cluster/${cluster}/_upgrade/`
          const upgradePayload = {
            upgrade: {
              data: {
                'image-name': imageObj.name,
                'image-id': imageObj['file-key'],
                cluster,
                'upgrade-drive': formData['device-upgrade'].drive,
                'upgrade-disk': formData['device-upgrade'].disk,
                'save-config-before-upgrade': formData['device-upgrade'].save,
                'reboot-after-upgrade': formData['device-upgrade'].reboot,
                description: formData['device-upgrade']?.description,
              },
              schedule: {
                'is-repeating': false,
              },
            },
          }

          if (formData['device-upgrade'].when === 'schedule') {
            upgradePayload.upgrade.schedule = upgradeSchedule
          }
          return httpClient.post(url, upgradePayload, {
            absoluteBasePath: true,
          })
        },
      )
    }

    return Promise.all(upgradeTaskList)
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      formData,
      clusterName,
      deviceLevelUpgrade,
      deviceName,
      imageName,
    } = this.props
    const { disableSave } = this.state
    const title = deviceLevelUpgrade ? 'Device Upgrade' : 'Cluster Upgrade'
    return (
      <FormatSlidingPage
        isOpen={isOpen}
        onRequestOk={this.onRequestOk}
        onRequestClose={onRequestClose}
        title={title}
        description=""
        disableSave={disableSave}
        saveText="Upgrade"
      >
        <UpgradeForm
          clusterName={clusterName}
          deviceLevelUpgrade={deviceLevelUpgrade}
          deviceName={deviceName}
          imageName={imageName}
          onChange={this.onFormChange}
          formData={formData}
        />
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(SlidingUpgrade)
