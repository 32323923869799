import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import { A10Row, A10Col, A10Chart, A10Tag } from '@gui-libraries/widgets'

// import { RequestLicenseForm } from 'src/containers/Controller/License/Information/RequestLicenseForm'
import SlidingLicenseEdit from '../../Forms/LicenseEditForm/SlidingLicenseEdit'
import { ApplicationConfigs } from 'src/constants/ApplicationConfigs/index'
import { DashboardService, Utilities } from 'src/services'
import globalConfig from 'src/settings/config'

import './styles/licensecharts.scss'
export interface ILicenseChartsProps extends IA10ContainerDefaultProps {
  licenseData: IObject
  refreshData: () => void
}
export interface ILicenseChartsState {
  showSlidingPage: boolean
  selectedEditLicense: any
  selectedLicenseInfo: any
}

class LicenseCharts extends A10Container<
  ILicenseChartsProps,
  ILicenseChartsState
> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  selectedEditLicense: any
  ApplicationConfigs = new ApplicationConfigs()
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  isOperatorUser
  constructor(
    props: ILicenseChartsProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: {
        get: { IS_OPERATOR_USER },
      },
    } = context

    this.isOperatorUser = IS_OPERATOR_USER
    this.state = {
      showSlidingPage: false,
      selectedEditLicense: {
        entitleToken: this.props.licenseData['entitlement-key'],
        licenseType: this.props.licenseData['license-type'],
        numBandwidth: this.props.licenseData['alloted-bandwidth'],
        numDevices: this.props.licenseData['alloted-devices'],
        numProcessingUnits: null,
        expiry: this.props.licenseData['expires-at'],
        maxAllocatedBandwidth: this.props.licenseData['remaining-bandwidth'],
        billingSerials: this.props.licenseData['billing-serials'],
        usedDevices: this.props.licenseData['used-devices'],
        usedBandwidth: this.props.licenseData['used-bandwidth'],
        maxAllocatedDevices: this.props.licenseData['remaining-devices'],
      },
      selectedLicenseInfo: this.props.licenseData,
    }
  }
  closeImageSlidingPage() {
    this.setState({ showSlidingPage: false })
    this.props.refreshData()
  }

  editLicense() {
    if (this.props.licenseData['license-type'].indexOf('FLEXPOOL') > -1) {
      this.reSyncLicense(this.props.licenseData)
      return
    }
    const maxbandwidthObj = this.Utilities.megaToGiga(
      this.props.licenseData['remaining-bandwidth'] || 0,
    )
    let usedBW = this.props.licenseData['used-bandwidth'],
      numBW = this.props.licenseData['alloted-bandwidth']
    if (maxbandwidthObj && maxbandwidthObj.unit === 'g') {
      usedBW = parseFloat(
        (this.props.licenseData['used-bandwidth'] / 1000).toFixed(2),
      )
      numBW = parseFloat(
        (this.props.licenseData['alloted-bandwidth'] / 1000).toFixed(2),
      )
    }
    const selectedEditLicense = {
      entitleToken: this.props.licenseData['entitlement-key'],
      licenseType: this.props.licenseData['license-type'],
      numBandwidth: numBW,
      numDevices: this.props.licenseData['alloted-devices'],
      numProcessingUnits: null,
      expiry: this.props.licenseData['expires-at'],
      maxAllocatedBandwidth: this.props.licenseData['remaining-bandwidth'],
      billingSerials: this.props.licenseData['billing-serials'],
      usedDevices: this.props.licenseData['used-devices'],
      usedBandwidth: usedBW,
      maxAllocatedDevices: this.props.licenseData['remaining-devices'],
      offlineMode:
        this.props.licenseData['lm-mode'] &&
        this.props.licenseData['lm-mode'] === 'OFFLINE',
      activationUUID:
        this.props.licenseData['lm-mode'] &&
        this.props.licenseData['lm-mode'] === 'OFFLINE'
          ? this.props.licenseData['activation-uuid']
          : '',
    }
    this.setState({ showSlidingPage: true, selectedEditLicense })
  }

  reSyncLicense = async (license: any) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const payload = {
      'entitlement-key': license['entitlement-key'],
      'license-type': license['license-type'],
      'alloted-bandwidth': license['alloted-bandwidth'],
      'alloted-devices': license['alloted-bandwidth'],
      'expires-at': license['expires-at'],
      'billing-serials': license['billing-serials'],
      'glm-lic-type': license['glm-lic-type'],
      'license-name': license['license-name'],
      'license-id': license['license-id'],
      'license-json': license['license-json'],
      'glm-created-at': license['glm-created-at'],
      'glm-updated-at': license['glm-updated-at'],
      'created-at': license['created-at'],
      'last-modified-at': license['last-modified-at'],
      description: license['description'],
      notes: license['notes'],
      'check-in-frequency': license['check-in-frequency'],
      'default-allocated-bandwidth': license['default-allocated-bandwidth'],
      'max-allocated-bandwidth': license['max-allocated-bandwidth'],
      'min-allocated-bandwidth': license['min-allocated-bandwidth'],
      'container-device-return-to-pool-time':
        license['container-device-return-to-pool-time'],
      'remaining-bandwidth': license['remaining-bandwidth'],
      'remaining-devices': license['remaining-devices'],
      'used-bandwidth': license['used-bandwidth'],
      'used-devices': license['used-devices'],
      'used-activations': license['used-activations'],
      'active-appliances': license['active-appliances'],
      'current-revision-id': license['current-revision-id'],
      'licensed-device-list': license['licensed-device-list'],
      'license-platform': license['license-platform'],
      'license-product': license['license-product'],
      'organization-name': license['organization-name'],
      'start-date': license['start-date'],
    }

    const {
      EPIC_DEPENDENCIES: { httpClient },
    } = globalConfig

    const url = `/hls/licenses/providers/${PROVIDER}/entitlementkey/${license['entitlement-key']}`
    try {
      const { data } = await httpClient.put(url, payload, {
        absoluteBasePath: true,
      })
      if (data) {
        this.Utilities.showMessage('Resync call sent successfully', 1, 0)
        this.closeImageSlidingPage()
      }
    } catch (error) {
      this.Utilities.showMessage(error.response.data.cause.message, 0, 0)
    }
  }

  render() {
    const { licenseData } = this.props

    let mbuChartConfig = this.ApplicationConfigs.getLicenseGaugeChartConfig()
    let mduChartConfig = this.ApplicationConfigs.getLicenseGaugeChartConfig()
    let mpuChartConfig = this.ApplicationConfigs.getLicenseGaugeChartConfig()

    let usedBW = { val: 0, unit: 'm' }
    const freeBW = this.Utilities.megaToGiga(
      licenseData['alloted-bandwidth'] || 0,
    )
    if (licenseData['alloted-bandwidth'] > 0 && freeBW.unit === 'm') {
      usedBW = { val: licenseData['used-bandwidth'], unit: 'm' }
    } else {
      if (licenseData['alloted-bandwidth'] === 0) {
        usedBW = this.Utilities.megaToGiga(licenseData['used-bandwidth'] || 0)
      } else {
        const val = licenseData['used-bandwidth'] / 1000
        usedBW = { val: parseFloat(val.toFixed(2)), unit: 'g' }
      }
    }
    let callbackForA10Chart = (chart: IObject) => true
    const fontColor = '#494949'
    const fontSize = '16px'
    if (
      licenseData['license-type'] === 'MBU' ||
      licenseData['license-type'].indexOf('FLEXPOOL') > -1 ||
      licenseData['license-type'] === 'MDU'
    ) {
      const usedBValue = usedBW.val
      const freeBValue = freeBW.val
      mbuChartConfig.title.text = `Allocated:<br>${usedBValue +
        (usedBW.unit === 'm' ? 'Mbps' : 'Gbps')}`
      mbuChartConfig.title.style.color = fontColor
      mbuChartConfig.title.align = 'left'
      mbuChartConfig.title.verticalAlign = 'top'
      delete mbuChartConfig.tooltip.pointFormat
      delete mbuChartConfig.series[0].name
      mbuChartConfig.tooltip['pointFormatter'] = function() {
        const self: IObject = this
        const tooltipText = `${self.y}${
          freeBW.unit === 'm' ? 'Mbps' : 'Gbps'
        }</b>`
        return self.name === 'Available' ? tooltipText : `${tooltipText} used`
      }
      mbuChartConfig.series[0].data[0].y = usedBValue
      mbuChartConfig.series[0].data[0].color =
        usedBValue / freeBValue > 0.75 ? 'green' : '#fcb160'
      mbuChartConfig.series[0].data[1].y = freeBValue - usedBValue
      mbuChartConfig.series[0].data[1].name = 'Available'
      callbackForA10Chart = (chart: IObject) => {
        const attrX =
          chart.chartWidth > 300
            ? `3${Math.round(chart.chartWidth / 100)}%`
            : '22%'
        chart.renderer
          .text('0')
          .css({
            color: fontColor,
            fontSize,
            position: 'relative',
          })
          .attr({
            zIndex: 5,
            x: attrX,
            y: '75%',
          })
          .add()
        chart.renderer
          .text(`${freeBW.val}<br>Gbps`)
          .css({
            color: fontColor,
            fontSize,
            position: 'relative',
          })
          .attr({
            zIndex: 5,
            x: '67%',
            y: '75%',
          })
          .add()
        return true
      }

      let usedDValue = licenseData['used-devices']
      let freeDValue = licenseData['alloted-devices']
      mduChartConfig.title.text = `${usedDValue}`
      mduChartConfig.tooltip.pointFormat = '{series.name}: <b>{point.y}</b>'
      mduChartConfig.series[0].data[0].y = usedDValue
      mduChartConfig.series[0].data[0].color =
        usedDValue / freeDValue > 0.75 ? 'green' : '#fcb160'
      mduChartConfig.series[0].data[1].y = freeDValue - usedDValue
    } else if (licenseData['license-type'] === 'MPU') {
      let usedPValue = licenseData['used-process']
      let freePValue = licenseData['alloted-process']
      mpuChartConfig.title.text = `${usedPValue}`
      mpuChartConfig.tooltip.pointFormat = '{series.name}: <b>{point.y}</b>'
      mpuChartConfig.series[0].data[0].y = usedPValue
      mpuChartConfig.series[0].data[0].color =
        usedPValue / freePValue > 0.75 ? 'green' : '#fcb160'
      mpuChartConfig.series[0].data[1].y = freePValue - usedPValue
    }

    return (
      <div className="license-guage-bar">
        {licenseData['license-type'] === 'MBU' ||
        licenseData['license-type'].indexOf('FLEXPOOL') > -1 ||
        licenseData['license-type'] === 'MDU' ||
        licenseData['license-type'] === 'Trial' ? (
          <A10Row type="flex">
            <A10Col xs={24} sm={24} md={24} lg={12} className="panel">
              {licenseData['alloted-bandwidth'] > 0 ? (
                <A10Chart.Summary
                  classNmae="customized-a10chart-summary"
                  title="Managed Bandwidth Units"
                  hideLegend={true}
                  chartConfig={mbuChartConfig}
                  callback={callbackForA10Chart}
                />
              ) : (
                <div className="statsNumberSection">
                  <div className="statsFontHeader">Managed Bandwidth Units</div>
                  <div>
                    <div className="statsFontLarge">
                      {licenseData['license-type'] === "SUBSCRIBER_FLEXPOOL"?'NA':usedBW.val + (usedBW.unit === 'm' ? ' Mbps' : ' Gbps')}
                    </div>
                  </div>
                  <hr style={{ width: '50%', margin: '20px auto' }} />
                  <div>
                    <div className="statsFontLarge">
                      {licenseData['license-type'] === "SUBSCRIBER_FLEXPOOL"?'NA':licenseData['alloted-bandwidth'] === 0
                        ? 'Unlimited'
                        : freeBW.val +
                          (freeBW.unit === 'm' ? ' Mbps' : ' Gbps')}
                    </div>
                  </div>
                </div>
              )}
            </A10Col>
            <A10Col xs={24} sm={24} md={24} lg={12} className="panel">
              {licenseData['alloted-devices'] > 0 ? (
                <A10Chart.Summary
                  title="Managed Devices"
                  hideLegend={true}
                  chartConfig={mduChartConfig}
                />
              ) : (
                <div className="statsNumberSection">
                  <div className="statsFontHeader">Managed Devices</div>
                  <div>
                    <div className="statsFontLarge">
                      {licenseData['used-devices']}
                    </div>
                  </div>
                  <hr style={{ width: '50%', margin: '20px auto' }} />
                  <div>
                    <div className="statsFontLarge">
                      {licenseData['alloted-devices'] === 0
                        ? 'Unlimited'
                        : licenseData['alloted-devices']}
                    </div>
                    {licenseData['license-type'] === 'Trial' ? (
                      <A10Tag color="#e57373" className="trial-tag">
                        Trial
                      </A10Tag>
                    ) : null}
                  </div>
                </div>
              )}
            </A10Col>
          </A10Row>
        ) : licenseData['license-type'] === 'MPU' ? (
          <A10Row type="flex">
            <A10Col xs={24} sm={24} md={24} lg={24} className="panel">
              {licenseData['alloted-process'] > 0 ? (
                <A10Chart.Summary
                  title="Managed Processing Units"
                  hideLegend={true}
                  chartConfig={mpuChartConfig}
                />
              ) : (
                <div className="statsNumberSection">
                  <div className="statsFontHeader">
                    Managed Processing Units
                  </div>
                  <div>
                    <div className="statsFontLarge">
                      {licenseData['used-process']}
                    </div>
                  </div>
                  <hr style={{ width: '50%', margin: '20px auto' }} />
                  <div>
                    <div className="statsFontLarge">
                      {licenseData['alloted-process'] === 0
                        ? 'Unlimited'
                        : licenseData['alloted-process']}
                    </div>
                  </div>
                </div>
              )}
            </A10Col>
          </A10Row>
        ) : null}

        {this.isOperatorUser ||
        licenseData['license-type'] === 'Trial' ||
        licenseData['license-type'] === 'TEMP' ||
        licenseData['activation-status'] === 'REVOKED' ||
        licenseData['activation-status'] === 'EXPIRED' ? null : (
          <>
            <A10Row type="flex">
              <A10Col xs={24} sm={24} md={24} lg={24} className="panel">
                <div className={'change-capacity-div'}>
                  <label
                    className={licenseData['activation-status'] === 'GRACE-PERIOD'?'pad-v-10 no-margin cursor-pointer disabled' : 'pad-v-10 no-margin cursor-pointer'}

                    onClick={() => {
                      this.editLicense()
                    }}
                  >
                    {licenseData['license-type'].indexOf('FLEXPOOL') > -1
                      ? 'Resync License'
                      : 'Request Capacity Update'}
                  </label>
                </div>
              </A10Col>
            </A10Row>
            <SlidingLicenseEdit
              isOpen={this.state.showSlidingPage}
              onRequestClose={this.closeImageSlidingPage.bind(this)}
              formData={this.state.selectedEditLicense}
              licenseInfo={this.state.selectedLicenseInfo}
            />
          </>
        )}
      </div>
    )
  }
}

export default setupA10Container(LicenseCharts)
