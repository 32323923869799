import './styles/index.less'

import {
  A10Col,
  A10Message,
  A10Row,
} from '@gui-libraries/widgets'
import React, { useEffect, useState } from 'react'

import { ActionButton } from 'src/components/shared/ActionButton'
import ReactLoading from 'react-loading'
import { SystemService } from 'src/services/index'
import { Utilities } from 'src/services/index'
import ServiceLoggingEditForm from './ServiceLoggingEditForm'
import { Messages } from 'src/locale/en/Messages'
import ListPanel from 'src/components/ADC/ListPanel'

const utilities = new Utilities()
const systemService = new SystemService()
const messages = new Messages()

const handleServiceLogPost = async (
  payload: IObject,
  successMessage: string,
  failureMessage: string,
) => {
  let apiResponse: boolean = false
  try {
    const response = systemService.changeLogLevel(null, payload, null)
    if (response) {
      utilities.showMessage(successMessage, 1, 1)
      apiResponse = true
    }
  } catch {
    utilities.showMessage(failureMessage, 0, true)
  }
  return apiResponse
}

export const getServiceLogData = async () => {
  let apiResponse: any = []
  try {
    const { data: response } = await systemService.getServiceLogDetails(
      null,
      null,
      null,
    )
    apiResponse = response
  } catch (error) {
    apiResponse = []
  }

  return apiResponse
}

const ServiceLogging: React.FC<IObject> = props => {
  const [isLoading, setLoading] = useState(false)
  const [showModalForm, setShowModalForm] = useState(false)
  const [displayLogLevel, setDiaplayLogLevel] = useState(null)

  useEffect(() => {
    async function fetchServiceLogData() {
      setLoading(true)
      const data: IObject = await getServiceLogData()
      const standardLevelVal = data?.level?.standardLevel
      setDiaplayLogLevel(standardLevelVal)
      setLoading(false)
    }
    fetchServiceLogData()
  }, [])

  const onEditOkClick = async (logLevel: string) => {
    setLoading(true)
    const payload: IObject = {
      command: 'SET_LOG_LEVEL',
      log_level: logLevel,
    }
    try {
      const response = await handleServiceLogPost(
        payload,
        'SUCCESS_SERVICE_LOG_UPDATE',
        'FAILED_SERVICE_LOG_UPDATE',
      )
      if (response) {
        setDiaplayLogLevel(logLevel)
      }
      setShowModalForm(false)
      setLoading(false)
    } catch (error) {
      console.log(error);
      const message = `${(messages.LOGGING_FAILURE)}`
      A10Message.error(message, 10, close)
    }
  }

  const setFormModal = () => {
    setShowModalForm(!showModalForm)
  }

  return (
    <>
      <A10Row className="logging-content-body">
        <A10Col className="headerTitle">
          <ListPanel
            title={messages.SERVICE_LOGGING}
            rightContent={
              <ActionButton
                style={{marginTop: '6px'}}
                text="Edit"
                testID="edit-button"
                onClick={setFormModal}
                iconProps={{ app: 'global', type: 'edit' }}
              />
            }
          >
            <A10Row
              type="flex"
              className="no-margin font-14"
              style={{ flexDirection: 'column' }}
            >
              <A10Col className="service-section-title">
                {messages.LOG_LEVEL}
              </A10Col>
              {isLoading && displayLogLevel == null ? (
                <div className="">
                  <ReactLoading
                    type="bars"
                    color="#4a90e2"
                    height={40}
                    width={40}
                  />
                </div>
              ) : (
                <A10Col className='service-section-value' label="Log level">
                {displayLogLevel}
              </A10Col>
              )}
            </A10Row>
          </ListPanel>
        </A10Col>
      </A10Row>
      <ServiceLoggingEditForm
        show={showModalForm}
        title={'Edit Logging Configuration'}
        logLevelValue={displayLogLevel}
        onSubmitForm={onEditOkClick}
        onClose={() => setShowModalForm(!!false)}
      />
      {isLoading && (
        <div>
          <ReactLoading type="bars" color="#4a90e2" height={40} width={40} />
        </div>
      )}
    </>
  )
}

export default ServiceLogging
