import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Table, A10Tooltip, A10Loader, A10Icon } from '@gui-libraries/widgets'

import { OrganizationService, Utilities } from 'src/services'
import storage from 'src/libraries/storage'

import styles from './styles/index.module.less'

export interface IRbacRoleListProps extends IA10ContainerDefaultProps {
  data: any[]
  search: string
  updateList: any
  isLoading?: boolean
}

export interface IRbacRoleListState {
  needRefresh: boolean
  accessGroupList: IObject[]
}

class RbacRoleList extends A10Container<
  IRbacRoleListProps,
  IRbacRoleListState
> {
  defaultRbacRoleColumns: any[]
  permissionArray: IObject[]
  Utilities = new Utilities()
  OrganizationService = new OrganizationService()
  ref = React.createRef<HTMLDivElement>()

  constructor(props: IRbacRoleListProps) {
    super(props)
    this.state = {
      needRefresh: false,
      accessGroupList: [],
    }

    this.permissionArray = [
      {
        key: "Read-Write access for management of SSL certificates and keys within the provider.",
        value: "SSL certificates and keys"
      },
      {
        key: "Read-Write access to device and service registration and deregistration API",
        value: "Device and service registration and deregistration API"
      },
      {
        key: "Read-Write access for managing infrastructure (devices, clusters) within the provider.",
        value: "Cluster and Devices"
      },
      {
        key: "Read-Only access for monitoring infrastructure (devices, clusters) within the provider.",
        value: "Cluster and Devices"
      },
      {
        key: "Read-Write access to configuration and analytics of app services in the assigned logical partitions.",
        value: "App services in the assigned partitions"
      },
      {
        key: "Read-Only access to configuration and analytics of app services in the assigned logical partitions.",
        value: "App services in the assigned partitions"
      },
      {
        key: "Read-Write access to organization, infrastructure, configuration, analytics, and reporting of app services for all tenants within the provider.",
        value: "All"
      },
      {
        key: "Read-Only access to organization, infrastructure, configuration, analytics, and reporting of app services for all tenants within the provider.",
        value: "All"
      },
      {
        key: "Read-Write access to configuration, analytics, and reporting of app services in all partitions assigned to the tenant.",
        value: "App services in the assigned partitions"
      },
      {
        key: "Read-Only access to configuration, analytics, and reporting of app services in all partitions assigned to the tenant.",
        value: "App services in the assigned partitions"
      },
    ]

    this.defaultRbacRoleColumns = [
      {
        title: <>Name</>,
        className: 'td-truncate',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        sorter: (a: any, b: any) =>
          this.Utilities.sortStringKeys(a, b, 'name', 'name'),
        render: (text: string, record: any, index: number) => {
          return (
            <div>
              <span>{record['displayName'] || record.name}</span>
            </div>
          )
        },
      },
      {
        title: <>Read</>,
        className: 'td-truncate',
        dataIndex: '',
        key: '',
        width: '15%',
        render: (text: string, record: IObject, index: number) => {
          return (
            <div>
              {record?.['description'].match('Read') && (
                <A10Icon app="global" type="check-green" style={{ 'fontSize': '17px' }} />
              )}
            </div>
          )
        },
      },
      {
        title: <>Write</>,
        className: 'td-truncate',
        dataIndex: '',
        key: '',
        width: '15%',
        render: (text: string, record: IObject, index: number) => {
          return (
            <div>
              {record?.['description'].match('Read-Write') && (
                <A10Icon app="global" type="check-green" style={{ 'fontSize': '17px' }} />
              )}
            </div>
          )
        },
      },
      {
        title: <>Resources</>,
        className: 'td-truncate',
        dataIndex: 'description',
        key: 'description',
        render: (text: string, record: IObject) => {
          const resourceObj = this.filterResource(record?.['description'])
          return (
            <div
              className={styles.descriptionWrapper}
              title={resourceObj?.value}
            >
              <span>{resourceObj?.value}</span>
            </div>
          )
        },
      },
      {
        title: <>#Access Groups</>,
        dataIndex: 'accessGroups',
        key: 'accessGroups',
        width: '15%',
        render: (text: string, record: any) => {
          const roles = this.getRoles(record.name)
          return roles && roles.length > 0 ? (
            <A10Tooltip
              placement="right"
              title={
                <ul>
                  {roles.map((role: IObject) => (
                    <li key={role.name} className={styles.roleItem}>
                      <div className={styles.roundText}>R</div>
                      {role.displayName || role.name}
                    </li>
                  ))}
                </ul>
              }
            >
              <span style={{ color: '#4a90e2' }}>{roles.length}</span>
            </A10Tooltip>
          ) : (
            <span style={{ color: '#4a90e2' }}>{roles ? roles.length : 0}</span>
          )
        },
      },
    ]
  }

  getRoles = (name: string): IObject[] => {
    const { accessGroupList } = this.state
    const findObj = accessGroupList.filter(
      accessGroup => accessGroup.role === name,
    )
    return findObj ? findObj : []
  }

  async componentDidMount() {
    const getAGListReq = this.OrganizationService.getRbacAccessGroups(
      null,
      null,
      [storage.get.PROVIDER],
    )
    getAGListReq
      .then((resp: any) => {
        const accessGroupList = resp && resp.data ? resp.data : []
        this.setState({ accessGroupList })
      })
      .catch((error: any) => {
        // Error in get access group API
      })
  }

  componentDidUpdate() {
    if (this.state.needRefresh) {
      this.setState({ needRefresh: false })
    }
  }

  getRowKey = (record: IObject) => {
    return record.uuid
  }

  filterResource = (resource: string) => {
    const permissionValue = this.permissionArray.find((data) => {
      if (data.key === resource) {
        return data?.value
      }
    })
    return permissionValue
  }

  render() {
    const { data = [] } = this.props
    const rbacRoleColumns = [...this.defaultRbacRoleColumns]

    return (
      <div ref={this.ref}>
        <A10Table
          className={`hc-list ${styles.vipList}`}
          rowKey={this.getRowKey}
          columns={rbacRoleColumns}
          dataSource={data.map((item: IObject, index: number) => {
            item.key = index
            return item
          })}
          loading={
            this.props.isLoading && {
              indicator: <A10Loader container={this.ref} />,
            }
          }
        />
      </div>
    )
  }
}

export default setupA10Container(RbacRoleList)
