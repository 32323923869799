import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Button,
  A10Icon,
  A10Switch,
  A10Form,
  A10Input,
  A10Select,
  hcKeys as countries,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import storage from 'src/libraries/storage'
import { Messages } from 'src/locale/en/Messages'
import { ClusterPanel } from 'src/containers/Controller/Infrastructure/Clusters/Forms/ClusterPanel'
import { InfrastructureService, DashboardService } from 'src/services/'
import { IDefaultMethods } from 'src/containers/Controller'
import { DropdownConstants } from 'src/constants/DropdownConstants/DropdownConstants'
import Utilities from 'src/services/Utilities/Utilities'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import CitySelect from 'src/components/shared/CitySelect'

import styles from './styles/index.module.less'

export interface IClusterAddFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  cluster: any
  form: any
  formData?: any
  onRef?: any
  onSubmitForm?: (data: any) => void
  handleChange?: (data: any) => void
  oncancel: any
  hideDevicePanel?: boolean
}
export interface IClusterAddFormState {}

const DEVICE_OBJ: IObject = {
  deviceName: '',
  thunderIPAddress: '',
  mgmtInterface: true,
  // useV6: false,
  port: '443',
  region: undefined,
  zone: undefined,
  location: '',
  userName: '',
  userPassword: '',
  metricsExportInterval: '60',
  licenseCheck: false,
  licenseToken: '',
  licenseObj: '',
  numBandwidth: '',
}

class ClusterAddForm extends A10Container<
  IClusterAddFormProps,
  IClusterAddFormState
> {
  Messages = new Messages()
  InfrastructureService = new InfrastructureService()
  DashboardService = new DashboardService()
  DropdownConstants = new DropdownConstants()
  Countries = countries.sort((a: any, b: any) => {
    return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
  })
  Utilities = new Utilities()
  hcHostname = window.location.hostname

  constructor(props: any) {
    super(props)
    if (this.Countries[0].name !== '') {
      this.Countries.unshift({
        alias: 'null',
        key: 'null',
        name: '',
      })
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  handleChange = (field: string, e: any) => {
    const { formData } = this.props
    const _formData = { ...formData }
    let fieldValue
    if (e.target) {
      if (e.target.type === 'checkbox') {
        fieldValue = e.target.checked
      } else {
        fieldValue = e.target.value
      }
    } else {
      fieldValue = e
    }

    // Set onchange field
    _formData[field] = fieldValue

    // Set the default value of type (Config Mgmt.) while changing dataPlaneHA
    if (field === 'dataPlaneHA') {
      _formData.type = fieldValue === 'none' ? 'single' : 'multinode'
    }

    // Set the default value of convertToHA while changing type
    if (field === 'dataPlaneHA' || field === 'type') {
      _formData.convertToHA =
        fieldValue === 'vcs' && _formData.dataPlaneHA === 'vrrpa'
    }

    /*
      Set correct number of add device panel
      - dataPlaneHA = none, type = single: 1
      - dataPlaneHA = vrrpa, type = multinode: 2
      - dataPlaneHA = vrrpa, type = vcs
        - convertToHA (disable vcs) = true: 0
        - convertToHA = false: 1
      - dataPlaneHA = scaleout, type = multinode: 8
      - dataPlaneHA = scaleout, type = vcs: 1
    */
    if (
      field === 'dataPlaneHA' ||
      field === 'type' ||
      field === 'convertToHA'
    ) {
      let deviceCount = 0
      if (field === 'dataPlaneHA') {
        deviceCount = fieldValue === 'none' ? 1 : 2
      }
      if (field === 'type') {
        if (fieldValue === 'multinode') {
          deviceCount = 2
        } else if (fieldValue === 'vcs') {
          _formData['convertToHA'] = false
          deviceCount = 1
        }
      }
      if (field === 'convertToHA') {
        deviceCount = fieldValue ? 0 : 1
      }

      const currentDevicesCount = _formData.devices.length
      if (deviceCount < currentDevicesCount) {
        _formData.devices = _formData.devices.slice(0, deviceCount)
      } else if (deviceCount > currentDevicesCount) {
        const toAddNum = deviceCount - currentDevicesCount
        for (let i = 0; i < toAddNum; i++) {
          _formData.devices.push({ ...DEVICE_OBJ })
        }
      }
    }

    if (field === 'type' && fieldValue !== 'single') _formData.advanced = true

    this.props.handleChange(_formData)
  }

  validateDeviceIPs = (
    deviceIndex: number,
    rule: any,
    deviceIp: any,
    cb: any,
  ) => {
    const {
      formData: { devices },
    } = this.props
    if (devices.length > 1 && deviceIp !== '') {
      let isValid = true
      devices.forEach((dev: any, index: number) => {
        if (
          devices[index].thunderIPAddress === deviceIp &&
          index !== deviceIndex
        ) {
          isValid = false
        }
      })
      if (!isValid) {
        rule.message = this.Messages.HOSTNAME_IP_ADDRESS_DUP
        return cb(true)
      }
    }
    this.Utilities.validateThunderIPAndHost('not-mandatory', rule, deviceIp, cb)
  }

  validateDeviceHostPort = (
    fieldName: string,
    index: number,
    rule: any,
    value: any,
    cb: any,
  ) => {
    const allDevices = storage.get.ALLDEVICES
    const {
      form,
      formData: { devices },
    } = this.props
    if (allDevices && allDevices.length > 0) {
      let ipPortExists = false
      allDevices.forEach((device: any) => {
        if (
          device.host == devices[index].thunderIPAddress &&
          device['mgmt-port-secure'] == devices[index].port
        ) {
          ipPortExists = true
        }
      })
      if (ipPortExists) {
        rule.message = this.Messages.HOSTNAME_IP_PORT_DUP
        return cb(true)
      }
    }
    if (fieldName === 'thunderIPAddress') {
      const portErr = form.getFieldError([`port[${index}]`])
      if (portErr?.rt && portErr.rt.length > 0 && portErr.rt[0]?.length > 0) {
        portErr.rt[0].forEach((errMsg: string) => {
          if (errMsg === this.Messages.HOSTNAME_IP_PORT_DUP) {
            form.validateFields([`port[${index}]`])
          }
        })
      }

      this.validateDeviceIPs(index, rule, value, cb)
    } else if (fieldName === 'port') {
      const ipErr = form.getFieldError([`thunderIPAddress[${index}]`])
      if (
        ipErr?.underIPAddress &&
        ipErr.underIPAddress.length > 0 &&
        ipErr.underIPAddress[0]?.length > 0
      ) {
        ipErr.underIPAddress[0].map((errMsg: string) => {
          if (errMsg === this.Messages.HOSTNAME_IP_PORT_DUP) {
            form.validateFields([`thunderIPAddress[${index}]`])
          }
        })
      }

      this.Utilities.validatePort('not-mandatory', rule, value, cb)
    }
  }

  validateDuplicateDeviceName = (
    deviceIndex: number,
    rule: any,
    deviceName: any,
    cb: any,
  ) => {
    const {
      formData: { devices },
    } = this.props
    const allDevices = storage.get.ALLDEVICES
    let isValid: boolean = true
    const isDuplicate = allDevices.some(device => device.name === deviceName)
    const userRegx = new RegExp(/^([a-zA-Z0-9._-]){1,50}$/)
    if ('' === deviceName) {
      // rule.message = this.Messages.DEVICE_NAME_REQUIRED
      // isValid = false
    } else if (!userRegx.test(deviceName)) {
      rule.message = this.Messages.INVALID_DEVICE_NAME
      isValid = false
    } else if (isDuplicate) {
      rule.message = this.Messages.DUPLICATE_DEVICE_NAME
      isValid = false
    } else if (devices.length > 1) {
      devices.forEach((dev: any, index: number) => {
        if (devices[index].deviceName === deviceName && index !== deviceIndex) {
          rule.message = this.Messages.DUPLICATE_DEVICE_NAME_O
          isValid = false
        }
      })
    }
    return isValid ? cb() : cb(true)
  }

  handleDeviceChange = (field: string, index: number, e: any) => {
    const {
      form: { setFieldsValue },
      formData,
    } = this.props
    const _formData = { ...formData }
    if (e === null) {
      return
    }
    let fieldValue
    if (e.target) {
      if (e.target.type === 'checkbox') {
        fieldValue = e.target.checked
      } else {
        fieldValue = e.target.checked = e.target.value
      }
    } else {
      fieldValue = e
    }

    // Set onchange field
    _formData.devices[index][field] = fieldValue

    if (
      field === 'thunderIPAddress' ||
      field === 'userName' ||
      field === 'userPassword'
    ) {
      _formData.devices[index]['licenseCheck'] = false
      _formData.devices[index]['licenseToken'] = ''
      _formData.devices[index]['licenseObj'] = ''
      _formData.devices[index]['numBandwidth'] = ''
    }
    if (field === 'licenseObj') {
      _formData.devices[index]['numBandwidth'] = ''
    }
    if (field === 'region') {
      _formData.devices[index]['zone'] = ''
      setFieldsValue({ [`zone[${index}]`]: undefined })
    }
    this.props.handleChange(_formData)
  }

  addDevice = () => {
    const _formData = { ...this.props.formData }
    _formData.devices.push({ ...DEVICE_OBJ })
    this.props.handleChange(_formData)
  }

  removeDevice = (index: any) => {
    const {
      formData,
      form: { setFieldsValue },
    } = this.props
    const _formData = { ...formData }
    if (_formData.devices.length > 1) {
      _formData.devices.splice(index, 1)
    }
    _formData.devices.forEach((device: IObject, i: number) => {
      setFieldsValue({
        [`deviceName[${i}]`]: device.deviceName,
        [`thunderIPAddress[${i}]`]: device.thunderIPAddress,
        [`mgmtInterface[${i}]`]: device.mgmtInterface,
        // [`useV6[${i}]`]: device.useV6,
        [`port[${i}]`]: device.port,
        [`zone[${i}]`]: device.zone,
        [`region[${i}]`]: device.region,
        [`location[${i}]`]: device.location,
        [`userName[${i}]`]: device.userName,
        [`userPassword[${i}]`]: device.userPassword,
      })
    })

    this.props.handleChange(_formData)
  }

  displayRemove = (devices: IObject[], index: number) => {
    return (
      devices.length > 1 && (
        <span
          className="pull-right add-another-link"
          onClick={() => {
            this.removeDevice(index)
          }}
        >
          <span className="a10-icon remove" />
          <span className="label">Remove</span>
        </span>
      )
    )
  }

  render() {
    const { cluster, form, formData, onRef, hideDevicePanel } = this.props
    const { getFieldDecorator } = form
    const { type, dataPlaneHA, convertToHA, devices } = formData

    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 15 },
    }

    const { renderDropdownOpts } = this.Utilities
    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <ClusterPanel
          cluster={cluster}
          form={form}
          formData={formData}
          onRef={onRef}
          handleChange={this.handleChange}
        />
        <>
          {!cluster &&
            (!convertToHA || (type === 'vcs' && dataPlaneHA === 'scaleout')) &&
            !hideDevicePanel &&
            devices.map((device: IObject, index: number) => {
              return (
                <A10Panel
                  title={
                    <>
                      <A10IconTextGroup
                        text={
                          type === 'single'
                            ? 'Cluster Device Info'
                            : type === 'vcs' &&
                              (!convertToHA || dataPlaneHA === 'scaleout')
                            ? 'VCS Master Device Info'
                            : 'Device ' + (index + 1) + ' Info'
                        }
                        icon={
                          <A10Icon
                            style={{ width: 22, height: 22, marginRight: 12 }}
                            app="global"
                            type="form-section"
                            className="sliding-panel-icon"
                          />
                        }
                      />
                    </>
                  }
                  menu={this.displayRemove(devices, index)}
                  key={index}
                  style={{ padding: '30px 12px 30px 70px' }}
                >
                  <A10Form.Item
                    {...formItemLayout}
                    label={
                      <>
                        {this.Messages.DEVICE_NAME}
                        <span style={{ marginLeft: '-21px' }}>
                          <HelpInfo
                            placement="left"
                            title={this.Messages.DEVICE_NAME}
                            helpKey="HELP_DEVICE_NAME"
                          />
                        </span>
                      </>
                    }
                  >
                    <div className="row">
                      <div className="col-md-11">
                        {getFieldDecorator(`deviceName[${index}]`, {
                          rules: [
                            {
                              required: false,
                              message: this.Messages.DEVICE_NAME_REQUIRED,
                            },
                            {
                              validator: this.validateDuplicateDeviceName.bind(
                                this,
                                index,
                              ),
                            },
                          ],
                          initialValue: device.deviceName,
                        })(
                          <A10Input
                            type="text"
                            placeholder={this.Messages.DEVICE_NAME}
                            onChange={this.handleDeviceChange.bind(
                              this,
                              'deviceName',
                              index,
                            )}
                          />,
                        )}
                      </div>
                    </div>
                  </A10Form.Item>
                  <A10Form.Item
                    {...formItemLayout}
                    key={'thunderIPAddress-item-' + index}
                    name={`thunderIPAddress[${index}]`}
                    label={
                      <>
                        {this.Messages.THUNDER_IP_ADDR}
                        <span style={{ marginLeft: '-8px' }}>
                          <HelpInfo
                            placement="left"
                            title={this.Messages.THUNDER_IP_ADDR}
                            helpKey="HELP_INFRA_THUNDER_IP_ADDRESS"
                          />
                        </span>
                      </>
                    }
                  >
                    <div className="row">
                      <div className="col-md-11">
                        {getFieldDecorator(`thunderIPAddress[${index}]`, {
                          validateTrigger: 'onBlur',
                          rules: [
                            {
                              required: !!device.deviceName ? true : false,
                              message: this.Messages.THUNDER_IP_REQUIRED,
                            },
                            {
                              validator: this.validateDeviceHostPort.bind(
                                this,
                                'thunderIPAddress',
                                index,
                              ),
                            },
                          ],
                          initialValue: device.thunderIPAddress,
                        })(
                          <A10Input
                            type="text"
                            placeholder={this.Messages.THUNDER_IP_ADDR}
                            onChange={this.handleDeviceChange.bind(
                              this,
                              'thunderIPAddress',
                              index,
                            )}
                          />,
                        )}
                      </div>
                    </div>
                  </A10Form.Item>

                  <A10Form.Item
                    {...formItemLayout}
                    key={'mgmtInterface-item-' + index}
                    name={`mgmtInterface[${index}]`}
                    label={
                      <>
                        {this.Messages.MANAGEMENT_INTERFACE}
                        <span style={{ marginLeft: '-8px' }}>
                          <HelpInfo
                            placement="left"
                            title="Management Interface"
                            helpKey="HELP_INFRA_MANAGEMENT_INTERFACE"
                          />
                        </span>
                      </>
                    }
                  >
                    <div className="row">
                      <div className="col-md-6">
                        {getFieldDecorator(`mgmtInterface[${index}]`, {
                          initialValue: device.mgmtInterface,
                          valuePropName: 'checked',
                        })(
                          <A10Switch
                            className="mgntInSwitch"
                            id={'mgmtInterface-' + index}
                            onChange={this.handleDeviceChange.bind(
                              this,
                              'mgmtInterface',
                              index,
                            )}
                          />,
                        )}
                      </div>
                    </div>
                  </A10Form.Item>

                  <A10Form.Item
                    {...formItemLayout}
                    label={
                      <>
                        {this.Messages.PORT}
                        <span style={{ marginLeft: '-12px' }}>
                          <HelpInfo
                            placement="leftTop"
                            title="Port"
                            helpKey="HELP_INFRA_PORT"
                          />
                        </span>
                      </>
                    }
                    name={`port[${index}]`}
                    key={'port-item-' + index}
                  >
                    <div className="row">
                      <div className="col-md-4">
                        {getFieldDecorator(`port[${index}]`, {
                          validateTrigger: 'onBlur',
                          rules: [
                            {
                              required: !!device.deviceName ? true : false,
                              message: this.Messages.PORT_REQUIRED,
                            },
                            {
                              validator: this.validateDeviceHostPort.bind(
                                this,
                                'port',
                                index,
                              ),
                            },
                          ],
                          initialValue: device.port,
                        })(
                          <A10Input
                            type="text"
                            placeholder={this.Messages.PORT}
                            onChange={this.handleDeviceChange.bind(
                              this,
                              'port',
                              index,
                            )}
                          />,
                        )}
                      </div>
                    </div>
                  </A10Form.Item>

                  <A10Form.Item
                    {...formItemLayout}
                    label={
                      <>
                        {this.Messages.ADMIN_USERNAME}
                        <span style={{ marginLeft: '-25px' }}>
                          <HelpInfo
                            placement="left"
                            title={this.Messages.ADMIN_USERNAME}
                            helpKey="HELP_INFRA_ADMIN_USER_NAME"
                          />
                        </span>
                      </>
                    }
                    key={'userName-item-' + index}
                  >
                    <div className="row">
                      <div className="col-md-11">
                        {getFieldDecorator(`userName[${index}]`, {
                          rules: [
                            {
                              required: !!device.deviceName ? true : false,
                              message: this.Messages.USERNAME_REQUIRED,
                            },
                          ],
                          initialValue: device.userName,
                        })(
                          <A10Input
                            type="text"
                            placeholder={this.Messages.ADMIN_USERNAME}
                            onChange={this.handleDeviceChange.bind(
                              this,
                              'userName',
                              index,
                            )}
                          />,
                        )}
                      </div>
                    </div>
                  </A10Form.Item>

                  <A10Form.Item
                    {...formItemLayout}
                    label={
                      <>
                        {this.Messages.ADMIN_PASSWORD}
                        <span style={{ marginLeft: '-24px' }}>
                          <HelpInfo
                            placement="left"
                            title={this.Messages.ADMIN_PASSWORD}
                            helpKey="HELP_INFRA_ADMIN_USER_PASSWORD"
                          />
                        </span>
                      </>
                    }
                    key={'password-item-' + index}
                  >
                    <div className="row">
                      <div className="col-md-11">
                        {getFieldDecorator(`userPassword[${index}]`, {
                          rules: [
                            {
                              required: !!device.deviceName ? true : false,
                              message: this.Messages.USER_PASSWORD_REQUIRED,
                            },
                          ],
                          initialValue: device.userPassword,
                        })(
                          <A10Input
                            type="password"
                            placeholder={this.Messages.ADMIN_PASSWORD}
                            onChange={this.handleDeviceChange.bind(
                              this,
                              'userPassword',
                              index,
                            )}
                          />,
                        )}
                      </div>
                    </div>
                  </A10Form.Item>

                  <A10Form.Item
                    {...formItemLayout}
                    label={
                      <>
                        {this.Messages.REGION}
                        <span style={{ marginLeft: '-5px' }}>
                          <HelpInfo
                            placement="left"
                            title={this.Messages.REGION}
                            helpKey="HELP_INFRA_REGION"
                          />
                        </span>
                      </>
                    }
                    key={'region-item-' + index}
                  >
                    <div className="row">
                      <div className="col-md-11">
                        {getFieldDecorator(`region[${index}]`, {
                          initialValue: device.region,
                        })(
                          <A10Select
                            showSearch={true}
                            placeholder="Please select"
                            onChange={this.handleDeviceChange.bind(
                              this,
                              'region',
                              index,
                            )}
                          >
                            {renderDropdownOpts(this.Countries, null, 'name')}
                          </A10Select>,
                        )}
                      </div>
                    </div>
                  </A10Form.Item>

                  <A10Form.Item
                    {...formItemLayout}
                    label={
                      <>
                        {this.Messages.ZONE}
                        <span style={{ marginLeft: '-4px' }}>
                          <HelpInfo
                            placement="left"
                            title={this.Messages.ZONE}
                            helpKey="HELP_INFRA_ZONE"
                          />
                        </span>
                      </>
                    }
                    key={'zone-item-' + index}
                  >
                    <div className="row">
                      <div className="col-md-11">
                        {getFieldDecorator(`zone[${index}]`, {
                          rules: [
                            {
                              required:
                                device.region &&
                                JSON.parse(device.region)?.name,
                              message: this.Messages.ZONE_REQUIRED,
                            },
                          ],
                          initialValue: device.zone,
                        })(
                          <CitySelect
                            region={device.region}
                            initialValue={device.zone}
                            key={device.region}
                            onChange={this.handleDeviceChange.bind(
                              this,
                              'zone',
                              index,
                            )}
                          />,
                        )}
                      </div>
                    </div>
                  </A10Form.Item>

                  <A10Form.Item
                    {...formItemLayout}
                    label={
                      <>
                        {this.Messages.LOCATION}
                        <span style={{ marginLeft: '-12px' }}>
                          <HelpInfo
                            placement="left"
                            title={this.Messages.LOCATION}
                            helpKey="HELP_INFRA_LOCATION"
                          />
                        </span>
                      </>
                    }
                    key={'location-item-' + index}
                  >
                    <div className="row">
                      <div className="col-md-11">
                        {getFieldDecorator(`location[${index}]`, {
                          initialValue: device.location,
                        })(
                          <A10Input
                            type="text"
                            placeholder={this.Messages.LOCATION}
                            onChange={this.handleDeviceChange.bind(
                              this,
                              'location',
                              index,
                            )}
                          />,
                        )}
                      </div>
                    </div>
                  </A10Form.Item>
                </A10Panel>
              )
            })}

          {!cluster &&
            !hideDevicePanel &&
            ((type === 'multinode' &&
              dataPlaneHA === 'vrrpa' &&
              devices.length < 2) ||
              (type === 'multinode' &&
                dataPlaneHA === 'scaleout' &&
                devices.length < 8)) && (
              <A10Button
                type="secondary"
                className={styles.addAnotherBtn}
                onClick={this.addDevice}
              >
                <A10Icon app="global" type="add-new" />
                Add Another Device
              </A10Button>
            )}
        </>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(ClusterAddForm))
