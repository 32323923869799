import React from 'react'
import { A10Col } from '@gui-libraries/widgets'

import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import styles from './styles/index.module.less'

interface ICannedRolesProps {}

const CannedRoles: React.FC<ICannedRolesProps> = props => {
  const onClick = () => {}

  return (
    <ContentSection>
      <ContentHeader type="flex" align="middle" justify="space-between">
        <A10Col>
          <ContentTitle title="Canned Roles" />
        </A10Col>
        <A10Col>
          <ActionButton
            text="Add Canned Role"
            onClick={onClick}
            iconProps={{ app: 'global', type: 'upload' }}
          />
        </A10Col>
      </ContentHeader>
      <ContentBody>Canned Roles</ContentBody>
    </ContentSection>
  )
}

export default CannedRoles
