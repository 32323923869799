import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Form,
  A10Input,
  A10Collapse,
  A10Tree,
  A10Checkbox,
  A10Button,
  A10Notification,
} from '@gui-libraries/widgets'

import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import A10Panel from 'src/components/ADC/A10Panel'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import storage from 'src/libraries/storage'
import { Messages } from 'src/locale/en/'
import Utilities from 'src/services/Utilities/Utilities'
import {
  InfrastructureService,
  InfraStructureStatsService,
} from 'src/services/'
import { HelpInfo } from 'src/components/shared/HelpInfo'

import './styles/bulkupdateform.scss'

const moment = require('moment')
const { Search } = A10Input
const { Panel } = A10Collapse
const { TreeNode } = A10Tree
export interface IBulkUpdateFormProps extends IA10ContainerDefaultProps {
  onChange?: (data: any) => void
  devicesList: any
  onRef: any
  licenseInfo: any
  glmLicTypeText: (glmLicType: string, licType: string) => string
}
export interface IBulkUpdateFormState {
  clusterTreeData: any
  devices: string[]
  isSelectAll: boolean
  autoExpandParent: boolean
  expandedKeys: string[]
  searchString: string
}

class BulkUpdateForm extends A10Container<
  IBulkUpdateFormProps,
  IBulkUpdateFormState
> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  Messages = new Messages()
  Utilities = new Utilities()
  InfrastructureService = new InfrastructureService()
  InfraStructureStatsService = new InfraStructureStatsService()
  constructor(props: IBulkUpdateFormProps) {
    super(props)
    this.state = {
      clusterTreeData: [],
      devices: [],
      isSelectAll: false,
      autoExpandParent: false,
      expandedKeys: [],
      searchString: '',
    }

    this.loadClusters()
  }

  adminLevel = storage.get.ADMIN_LEVEL
  clusterData: any = []
  bulkDevices: string[] = []
  deviceCount = 0

  formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  getPayloadHeader = () => {
    const to = Date.now()
    const from = Date.now() - 2 * 60 * 1000
    const payload = {
      deviceStats: {
        fields: ['cluster_type', 'cluster_vcs_state', 'device_uuid'],
        rangeby: {
          start: from,
          end: to,
          field: 'ts',
        },
        size: 10000,
      },
    }
    let xAccount = this.Utilities.getAllXAccountIDs()
    const header = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      provider: storage.get.PROVIDER,
      Authorization: storage.get.ENCODED_SESSION_ID,
      'X-Account': xAccount,
    }

    return { payload, header }
  }

  setTreeData = (clusters: any, deviceStats: any) => {
    const clustersWithRegDevices: any = [],
      expandedKeys: string[] = []
    clusters.map((cluster: any, i: number) => {
      if (cluster['referrer-list'] && cluster['referrer-list'].length > 0) {
        const devices: any = []
        cluster['referrer-list'].map((clusterDevice: any) => {
          const deviceObj = this.props.devicesList.filter((device: any) => {
            return device.uuid === clusterDevice.uuid
          })
          let vcsState = ''
          if (!!deviceStats) {
            const deviceStat = Object.keys(deviceStats)
              .filter(
                key =>
                  deviceStats[key]['device_uuid'] ==
                  deviceObj[0]['device-uuid'],
              )
              .map((key, index) => {
                return deviceStats[key]
              })

            if (deviceStat.length > 0) {
              if (deviceStat[0]['cluster_vcs_state'] === 'master') {
                vcsState = 'vMaster'
              } else if (deviceStat[0]['cluster_vcs_state'] === 'blade') {
                vcsState = 'vBlade'
              }
            }
          }

          const licenseObjs = deviceObj[0]['licenseinfo-list']
          let licType = ''
          if (this.props.licenseInfo['license-type'].indexOf('FLEXPOOL') > -1) {
            licenseObjs &&
              licenseObjs.map((licObj: any) => {
                switch (licObj['license-type']) {
                  case 'DEVICE_LICENSE':
                    licType =
                      licObj['license-state'] === 'ACTIVATED' ? 'Licensed' : ''
                    break
                  case 'EXTERNAL_DEVICE_LICENSE':
                    licType =
                      'External' +
                      (licObj['license-state'] === 'ACTIVATED'
                        ? ' - Licensed'
                        : ' - Unlicensed')
                    break
                  case 'PERPETUAL_LICENSE':
                    if (
                      licObj['device-bandwidth'] &&
                      licObj['device-bandwidth'] > 0
                    ) {
                      licType =
                        'Perpetual' +
                        (licObj['license-state'] === 'ACTIVATED'
                          ? ' - Licensed'
                          : ' - Unlicensed')
                    } else {
                      licType =
                        'Perpetual' +
                        (licObj['license-state'] === 'ACTIVATED'
                          ? ' - Licensed'
                          : ' - Unlicensed')
                    }
                    break
                  default:
                    break
                }
              })
          }
          
          
          if (
            deviceObj &&
            deviceObj.length > 0 &&
            deviceObj[0]['registration-state'] === 'REGISTERED'
          ) {
            devices.push({
              title: deviceObj[0]['name'],
              licType: licType,
              vcsState: vcsState,
              type: 'device',
              clusterType: cluster.type,
              key: deviceObj[0]['device-uuid'],
              isLeaf: true,
              model: deviceObj[0]['model']?deviceObj[0]['model']:""
            })
            this.deviceCount++
          }
        })
        if (devices.length > 0) {
          clustersWithRegDevices.push({
            title: cluster['display-name'] || cluster['name'],
            type: 'cluster',
            key: 'cluster:' + cluster['cluster-uuid'],
            children: devices,
            clusterType: cluster.type,
          })
          expandedKeys.push('cluster:' + cluster['cluster-uuid'])
        }
      }
    })

    if (clustersWithRegDevices.length > 0) {
      this.props.licenseInfo &&
        this.props.licenseInfo['licensed-device-list'] &&
        this.props.licenseInfo['licensed-device-list'].map((device: any) => {
          if (!!device['device-id']) {
            this.bulkDevices.push(device['device-id'])
          }
        })
    }

    this.setState({
      clusterTreeData: clustersWithRegDevices,
      devices: this.bulkDevices,
      isSelectAll: this.deviceCount === this.bulkDevices.length,
      expandedKeys,
      autoExpandParent: true,
    })
  }

  loadClusters = () => {
    const {
      get: { PROVIDER },
      set,
    } = storage

    const clusterResponse = this.InfrastructureService.getClusters(
      this.adminLevel === 'provider'
        ? null
        : this.Utilities.getXAccountHeaderDetails(false),
      null,
      PROVIDER,
    )

    clusterResponse
      .then((response: any) => {
        const clusters = response.data ? response.data['cluster-list'] : []
        set.PROVIDER_CLUSTERS(clusters)
        this.clusterData = clusters

        const payloadHeader = this.getPayloadHeader()
        const deviceStatsReq = this.InfraStructureStatsService.getClusterStats(
          payloadHeader.header,
          payloadHeader.payload,
          null,
        )
        deviceStatsReq
          .then((response: any) => {
            this.setTreeData(clusters, response?.data?.deviceStats)
          })
          .catch((error: any) => {
            console.log(error)
            this.setTreeData(clusters, null)
          })
      })
      .catch((error: any) => {
        console.error(error.response)
      })
  }

  onCheck = (checkedKeys: any, event: any) => {
    const devices: string[] = [],
      existingDevices = this.state.devices
    checkedKeys.map((keys: string) => {
      if (keys.indexOf('cluster:') === -1) {
        devices.push(keys)
      }
    })

    event.checkedNodes &&
      event.checkedNodes.map((node: any, index: number) => {
        if (node.props.dataRef.key.indexOf('cluster:') === -1) {
          if (
            existingDevices.indexOf(node.props.dataRef.key) === -1 &&
            node.props.dataRef.clusterType === 'vcs'
          ) {
            A10Notification.destroy()
            A10Notification.info({
              message: '',
              description:
                'Same amount of bandwidth will be used for each vBlade in this VCS cluster.',
            })
          }
        }
      })
    this.props.onChange(devices)
    this.setState({ devices, isSelectAll: this.deviceCount === devices.length })
  }

  onExpand = (expandedKeys: any, event: any) => {
    const clusters: string[] = []
    expandedKeys.map((keys: string) => {
      if (keys.indexOf('cluster:') > -1) {
        clusters.push(keys)
      }
    })
    this.setState({ expandedKeys: clusters, autoExpandParent: false })
  }

  onClearAll = () => {
    this.props.onChange(this.bulkDevices)
    this.setState({ devices: this.bulkDevices, isSelectAll: false })
  }

  onSelectAll(event: any) {
    const selectedIDs: any[] = []
    const isSelectAll = event.target.checked
    if (isSelectAll) {
      const { clusterTreeData } = this.state
      clusterTreeData.forEach((node: any) => {
        if (node.children) {
          node.children.forEach((device: any) => {
            if ( ['vThunder','cThunder'].includes(device.model)) {
              selectedIDs.push(device['key'])
            }
          })
        }
      })
    }

    this.props.onChange(selectedIDs.length > 0 ? selectedIDs : this.bulkDevices)
    this.setState({
      devices: selectedIDs.length > 0 ? selectedIDs : this.bulkDevices,
      isSelectAll,
    })
  }

  onSearch = (e: any) => {
    const value = e.target.value
    const expandedKeys: string[] = []
    this.state.clusterTreeData.map((item: any) => {
      if (item.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
        expandedKeys.push(item.key)
      } else if (item.children) {
        item.children.forEach((device: any) => {
          if (device.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
            expandedKeys.push(item.key)
          }
        })
      }
    })
    this.setState({
      expandedKeys,
      searchString: value,
      autoExpandParent: true,
    })
  }

  renderTreeNodes = (data: any) =>
    data.map((item: any) => {

      const titleNode =
        this.state.searchString &&
        item.title
          .toLowerCase()
          .indexOf(this.state.searchString.toLowerCase()) > -1 ? (
          <b>
            {item.title +
              (!!item.licType ? ' - (' + item.licType + ')' : '') +
              (!item.vcsState ? '' : ' - (' + item.vcsState + ')')}
          </b>
        ) : (
          item.title +
          (!!item.licType ? ' - (' + item.licType + ')' : '') +
          (!item.vcsState ? '' : ' - (' + item.vcsState + ')')
        )
      if (item.children) {
        return (
          <TreeNode
            icon={<HealthStatus type="ongoing" text="C" hideTooltip={true} />}
            title={titleNode}
            key={item.key}
            dataRef={item}
          >
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        )
      }
      let existingDevice = false
      this.bulkDevices &&
        this.bulkDevices.map((obj: string) => {
          if (obj === item.key) {
            existingDevice = true
          }
        })
      return (
        <TreeNode
          icon={<HealthStatus type="ongoing" text="D" hideTooltip={true} />}
          disableCheckbox={ 
            item.model?item.model==='cThunder' && this.props.licenseInfo['license-type'] === 'SUBSCRIBER_FLEXPOOL'?true:
            existingDevice || item.licType.indexOf('Hardware') > -1 || item.vcsState == 'vBlade' || (item.licType.indexOf('Perpetual') > -1 &&  !['vThunder','cThunder'].includes(item.model))
            :existingDevice || item.licType.indexOf('Hardware') > -1 || item.licType.indexOf('Perpetual') > -1 || item.vcsState == 'vBlade'
          }
          title={titleNode}
          key={item.key}
          dataRef={item}
        />
      )
    })

  render() {
    const licenseInfo = this.props.licenseInfo
    const expiresAt = moment
      .utc(licenseInfo['expires-at'])
      .format('MMM DD, YYYY hh:mm A')
    return (
      <div>
        <A10Form layout="horizontal">
          <A10Panel
            title={
              <A10IconTextGroup
                text={
                  <>
                    License Information
                    {/* <span>
                      <HelpInfo
                        customCls="custom-tooltip-head"
                        placement="topLeft"
                        title="License Information"
                        helpKey="HELP_LICENSE_INFORMATION"
                      />
                    </span> */}
                  </>
                }
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            }
          >
            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  {this.Messages.ENTITLEMENT_TOKEN}
                  <HelpInfo
                    placement="right"
                    title={this.Messages.ENTITLEMENT_TOKEN}
                    helpKey="HELP_LICENSE_TOKEN"
                  />
                </>
              }
              className="no-margin font-14"
            >
              <label className="pdlr-5 disabled">
                {licenseInfo['entitlement-key']}
              </label>
            </A10Form.Item>

            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  {'Name'}
                  <HelpInfo
                    placement="right"
                    title="Name"
                    helpKey="HELP_LICENSE_NAME"
                  />
                </>
              }
              className="no-margin font-14"
            >
              <label className="pdlr-5 disabled">
                {licenseInfo['license-name']}
              </label>
            </A10Form.Item>
            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  {this.Messages.LICENSE_TYPE}
                  <HelpInfo
                    placement="right"
                    title={this.Messages.LICENSE_TYPE}
                    helpKey="HELP_LICENSE_TYPE_VALUE"
                  />
                </>
              }
              className="no-margin font-14"
            >
              <label className="pdlr-5 disabled">
                {this.props.glmLicTypeText(licenseInfo['glm-lic-type'],licenseInfo['license-type'])}
              </label>
            </A10Form.Item>

            {licenseInfo['organization-name'] ? (
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    {'Organization Name'}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title="Organization Name"
                        helpKey="HELP_LICENSE_ORGANIZATION_NAME"
                      />
                    </span>
                  </>
                }
                className="no-margin font-14"
              >
                {
                  <div className="disabled">
                    {licenseInfo['organization-name']}
                  </div>
                }
              </A10Form.Item>
            ) : null}

{licenseInfo['license-platform'] ? (
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    {'Platform'}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title="License Platform"
                        helpKey="HELP_LICENSE_PLATFORM"
                      />
                    </span>
                  </>
                }
                className="no-margin font-14"
              >
                {
                  <div className="disabled">
                    {licenseInfo['license-platform']}
                  </div>
                }
              </A10Form.Item>
            ) : null}

        {licenseInfo['license-product'] ? (
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    {'Product'}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title="License Product"
                        helpKey="HELP_LICENSE_PRODUCT"
                      />
                    </span>
                  </>
                }
                className="no-margin font-14"
              >
                {
                  <div className="disabled">
                    {licenseInfo['license-product']}
                  </div>
                }
              </A10Form.Item>
            ) : null}

            {licenseInfo['used-activations'] ? (
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    {'Used Activations'}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title="Used Activations"
                        helpKey="HELP_LICENSE_USED_ACTIVATIONS"
                      />
                    </span>
                  </>
                }
                className="no-margin font-14"
              >
                {
                  <div className="disabled">
                    {licenseInfo['used-activations']}
                  </div>
                }
              </A10Form.Item>
            ) : null}

            {licenseInfo['start-date'] ? (
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    {'Start Date'}
                    <HelpInfo
                      placement="right"
                      title="Start Date"
                      helpKey="HELP_LICENSE_START_DATE"
                    />
                  </>
                }
                className="no-margin font-14"
              >
                <label className="pdlr-5 disabled">
                  {licenseInfo['start-date']}
                </label>
              </A10Form.Item>
            ) : null}

            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  {this.Messages.EXPIRES_ON}
                  <HelpInfo
                    placement="right"
                    title="Expires On"
                    helpKey="HELP_LICENSE_EXPIRES_ON"
                  />
                </>
              }
              className="no-margin font-14"
            >
              <label className="pdlr-5 disabled">
                {licenseInfo['expires-at']
                  ? licenseInfo['expires-at'] === '2050-12-31 00:00:00'
                    ? 'Never'
                    : expiresAt
                  : ''}
              </label>
            </A10Form.Item>

            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  {'Notes'}
                  <HelpInfo
                    placement="right"
                    title="Notes"
                    helpKey="HELP_LICENSE_NOTES"
                  />
                </>
              }
              className="no-margin font-14"
            >
              <label className="pdlr-5 disabled">{licenseInfo['notes']}</label>
            </A10Form.Item>
          </A10Panel>

          <A10Panel
            className="panel-body-pad"
            title={
              <A10IconTextGroup
                text={
                  <>
                    Select Devices
                    {/* <span>
                      <HelpInfo
                        customCls="custom-tooltip-head"
                        placement="topLeft"
                        title="Select Devices"
                        helpKey="HELP_LICENSE_BULK_DEVICE_UPDATE"
                      />
                    </span> */}
                  </>
                }
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            }
          >
            <A10Collapse defaultActiveKey={['1']}>
              <Panel
                key="1"
                showArrow={false}
                disabled
                header={
                  <div className="row">
                    <div className="col-md-3">
                      <A10Checkbox
                        onChange={(e: any) => {
                          this.onSelectAll(e)
                        }}
                        checked={this.state.isSelectAll}
                        disabled={this.state.clusterTreeData.length === 0}
                      >
                        Select All
                      </A10Checkbox>

                    </div>
                    <div className="col-md-6">
                      <span>
                        <label className="sel-count">
                          {this.state.devices.length + ' Selected'}
                        </label>
                      </span>
                      <span>
                        <A10Button
                          className="action-button blue-text"
                          disabled={this.state.devices.length === 0}
                          onClick={this.onClearAll}
                        >
                          Clear Select
                        </A10Button>
                      </span>
                    </div>
                    <div className="col-md-3">
                      <Search
                        style={{ marginBottom: 8 }}
                        placeholder="Search"
                        onChange={this.onSearch}
                      />
                    </div>
                  </div>
                }
              >

                <A10Tree
                  checkable={true}
                  showIcon={true}
                  defaultExpandAll={true}
                  expandedKeys={this.state.expandedKeys}
                  onExpand={this.onExpand}
                  autoExpandParent={this.state.autoExpandParent}
                  onCheck={(checkedData, event) =>
                    this.onCheck(checkedData, event)
                  }
                  checkedKeys={this.state.devices}
                >
                  {this.renderTreeNodes(this.state.clusterTreeData)}
                </A10Tree>
              </Panel>
            </A10Collapse>
            <div></div>
          </A10Panel>
        </A10Form>
      </div>
    )
  }
}

export default setupA10Container(BulkUpdateForm)
