import React from 'react'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Collapse,
  A10Checkbox,
  A10Tree,
  A10Button,
  A10Input,
  A10Icon,
} from '@gui-libraries/widgets'
import { InfrastructureService } from 'src/services/InfrastructureService'
import { Utilities } from 'src/services/Utilities'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import storage from 'src/libraries/storage'
import Selection from './Selection'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
const styles = require('./styles/index.module.less')

const { Panel } = A10Collapse
const { TreeNode } = A10Tree
const { Search } = A10Input

export interface IClusterDevicePartitionProps
  extends IA10ContainerDefaultProps {
  onChange?: (data: any) => void
  height?: number
  devicePartitions?: any[]
  radioCheckbox?: boolean
  onlySearch?: boolean
  isDropdown?: boolean
}
export interface IClusterDevicePartitionState {
  height: number
  clusterTreeData: any[]
  filteredClusterTreeData: any[]
  devicePartitions: any[]
  partitions: string[]
  searchString: string
  isSelectAll: boolean
  autoExpandParent: boolean
  expandedKeys: string[]
}

class ClusterDevicePartition extends A10Container<
  IClusterDevicePartitionProps,
  IClusterDevicePartitionState
> {
  devicePartitionCount: number = 0
  InfrastructureService = new InfrastructureService()
  Utilities = new Utilities()
  constructor(props: any) {
    super(props)
    this.state = {
      height: this.props.height || 400,
      clusterTreeData: [],
      filteredClusterTreeData: [],
      devicePartitions: this.props.devicePartitions || [],
      partitions: [],
      searchString: '',
      isSelectAll: false,
      autoExpandParent: false,
      expandedKeys: [],
    }
  }

  componentDidMount() {
    this.prepareClusterTreeData()
  }

  prepareClusterTreeData = async () => {
    const {
      get: { PROVIDER },
    } = storage
    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(
      false,
      true,
    )
    if (!headers) {
      return
    }

    const clusterListResponse = await this.InfrastructureService.getClusters(
      headers,
      null,
      PROVIDER,
    )
    const clusterDevPartitionTreeData: IObject[] = []
    const expandedKeys: string[] = []
    const checkedPartition: string[] = []

    const clusterList: IObject[] =
      clusterListResponse?.data?.['cluster-list'] ?? []
    clusterList.forEach((cluster: IObject) => {
      const partitions: IObject[] = []
      if (cluster['partition-list'] && cluster['partition-list'].length > 0) {
        cluster['partition-list'].forEach((partition: any) => {
          partitions.push({
            title: partition.name,
            type: 'partition',
            cluster: cluster.name,
            lpUuid: partition['lp-uuid'],
            key: partition.uuid,
            isLeaf: true,
          })
          this.devicePartitionCount++
        })
      }
      if (partitions.length > 0) {
        clusterDevPartitionTreeData.push({
          title: cluster['display-name'] || cluster.name,
          type: 'cluster',
          key: 'cluster:' + cluster['cluster-uuid'],
          children: partitions,
        })
        expandedKeys.push('cluster:' + cluster['cluster-uuid'])
      }
    })
    this.setState({
      clusterTreeData: clusterDevPartitionTreeData,
      filteredClusterTreeData: clusterDevPartitionTreeData,
      expandedKeys,
      partitions: checkedPartition,
      autoExpandParent: true,
    })
  }

  onSearch = (e: any) => {
    const value = e.target.value
    const expandedKeys: string[] = [],
      filteredClusterTreeData: any[] = []
    const { partitions } = this.state
    let partitonCount = 0
    this.state.clusterTreeData.map((cluster: any) => {
      if (cluster.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
        expandedKeys.push(cluster.key)
        filteredClusterTreeData.push(cluster)
      } else if (cluster.children) {
        const filteredDeviceTreeData: any[] = []
        cluster.children.forEach((device: any) => {
          if (device.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
            expandedKeys.push(device.key)
            expandedKeys.push(cluster.key)
            filteredDeviceTreeData.push(device)
          } else if (device.children) {
            const filteredPartitionTreeData: any[] = []
            device.children.forEach((partition: any) => {
              if (
                partition.title.toLowerCase().indexOf(value.toLowerCase()) > -1
              ) {
                expandedKeys.push(device.key)
                expandedKeys.push(cluster.key)
                filteredPartitionTreeData.push(partition)
              }
            })

            if (filteredPartitionTreeData.length > 0) {
              const deviceObj = _.cloneDeep(device)
              deviceObj.children = filteredPartitionTreeData
              filteredDeviceTreeData.push(deviceObj)
            }
          }
        })

        if (filteredDeviceTreeData.length > 0) {
          const clusterObj = _.cloneDeep(cluster)
          clusterObj.children = filteredDeviceTreeData
          filteredClusterTreeData.push(clusterObj)
        }
      }
    })

    filteredClusterTreeData.map((cluster: any) => {
      cluster.children &&
        cluster.children.forEach((device: any) => {
          partitonCount += device.children.length || 0
        })
    })

    this.setState({
      expandedKeys,
      filteredClusterTreeData,
      searchString: value,
      autoExpandParent: true,
      isSelectAll: partitonCount === partitions.length,
    })
  }

  onCheck = (checkedKeys: any, event: any) => {
    const { partitions } = this.state
    let currentCheckedPartition = '',
      currentCheckedDevice = ''
    const partitionLists: string[] = [],
      devicePartitions: any[] = []

    event.checkedNodes &&
      event.checkedNodes.map((node: any, index: number) => {
        if (
          node.props.dataRef.key.indexOf('cluster:') === -1 &&
          node.props.dataRef.key.indexOf('device:') === -1
        ) {
          if (partitions.indexOf(node.props.dataRef.key) === -1) {
            currentCheckedPartition = node.props.dataRef.key
            currentCheckedDevice = node.props.dataRef.device
          }
        }
      })

    event.checkedNodes &&
      event.checkedNodes.map((node: any, index: number) => {
        if (
          node.props.dataRef.key.indexOf('cluster:') === -1 &&
          node.props.dataRef.key.indexOf('device:') === -1
        ) {
          if (
            !this.props.radioCheckbox ||
            (this.props.radioCheckbox &&
              (currentCheckedDevice !== node.props.dataRef.device ||
                currentCheckedPartition === node.props.dataRef.key))
          ) {
            devicePartitions.push({
              name: node.props.dataRef.cluster,
              partition: node.props.dataRef.title,
              partitionUuid: node.props.dataRef.key,
            })
            partitionLists.push(node.props.dataRef.key)
          }
        }
      })

    this.props.onChange(devicePartitions)
    this.setState({
      devicePartitions,
      partitions: partitionLists,
      isSelectAll: this.devicePartitionCount === partitionLists.length,
    })
  }

  onChangeSelection = (selected: IObject[]) => {
    const partitionLists = selected.map((value: IObject) => {
      return value.partitionUuid
    })

    this.props.onChange(selected)
    this.setState({
      devicePartitions: selected,
      partitions: partitionLists,
      isSelectAll: this.devicePartitionCount === partitionLists.length,
    })
  }

  onExpand = (expandedKeys: any, event: any) => {
    const clusterDevices: string[] = []
    expandedKeys.map((keys: string) => {
      if (keys.indexOf('cluster:') > -1 || keys.indexOf('device:') > -1) {
        clusterDevices.push(keys)
      }
    })
    this.setState({ expandedKeys: clusterDevices, autoExpandParent: false })
  }

  onSelectAll(event: any) {
    const selectedPartitions: any[] = [],
      devicePartitions: any[] = []
    const isSelectAll = event.target.checked,
      { partitions, clusterTreeData } = this.state

    if (isSelectAll) {
      const { filteredClusterTreeData } = this.state
      filteredClusterTreeData.forEach((node: any) => {
        if (node.children) {
          node.children.forEach((device: any) => {
            if (device.children) {
              device.children.forEach((partition: any) => {
                selectedPartitions.push(partition['key'])
                devicePartitions.push({
                  name: partition.cluster,
                  partition: partition.title,
                  partitionUuid: partition.key,
                })
              })
            }
          })
        }
      })
    }

    clusterTreeData.forEach((node: any) => {
      if (node.children) {
        node.children.forEach((device: any) => {
          if (device.children) {
            device.children.forEach((partition: any) => {
              if (
                partitions.indexOf(partition['key']) > -1 &&
                selectedPartitions.indexOf(partition['key']) === -1
              ) {
                selectedPartitions.push(partition['key'])
                devicePartitions.push({
                  name: partition.device,
                  partition: partition.title,
                  partitionUuid: partition.key,
                })
              }
            })
          }
        })
      }
    })

    this.props.onChange(devicePartitions)
    this.setState({
      partitions: selectedPartitions,
      devicePartitions,
      isSelectAll,
    })
  }

  onClearAll = () => {
    this.props.onChange([])
    this.setState({
      devicePartitions: [],
      partitions: [],
      isSelectAll: false,
    })
  }

  renderTreeNodes = (data: any[]) =>
    data.map((item: any) => {
      const titleNode =
        this.state.searchString &&
        item.title
          .toLowerCase()
          .indexOf(this.state.searchString.toLowerCase()) > -1 ? (
          <b>{item.title}</b>
        ) : (
          item.title
        )
      if (item.children) {
        return (
          <TreeNode
            icon={
              <HealthStatus
                type="ongoing"
                text={item.type === 'cluster' ? 'C' : 'D'}
                hideTooltip={true}
              />
            }
            // checkable={item.type === 'cluster' ? false : true}
            title={titleNode}
            key={item.key}
            dataRef={item}
          >
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        )
      }

      return (
        <TreeNode
          disabled={item.disabled}
          icon={<HealthStatus type="ongoing" text="P" hideTooltip={true} />}
          title={titleNode}
          key={item.key}
          dataRef={item}
        />
      )
    })

  render() {
    const { isDropdown } = this.props
    const {
      height,
      autoExpandParent,
      expandedKeys,
      filteredClusterTreeData,
      devicePartitions,
      partitions,
    } = this.state

    const tree = (
      <A10Tree
        className={styles.panelContent}
        checkable={true}
        showIcon={true}
        // defaultExpandAll={true}
        expandedKeys={expandedKeys}
        onExpand={this.onExpand}
        autoExpandParent={autoExpandParent}
        onCheck={(checkedData, event) => this.onCheck(checkedData, event)}
        checkedKeys={partitions}
        style={{ height }}
      >
        {this.renderTreeNodes(filteredClusterTreeData)}
      </A10Tree>
    )
    return (
      <A10Collapse defaultActiveKey={['1']} className={styles.cdpPanel}>
        {isDropdown ? (
          <Panel
            key="1"
            showArrow={false}
            disabled
            header={
              <A10IconTextGroup
                text={
                  <span className={styles.title}>
                    Select Cluster Partition to Deploy to
                  </span>
                }
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            }
          >
            <div className={styles.selectionPanel}>
              <Selection
                value={devicePartitions}
                onChange={this.onChangeSelection}
                content={tree}
              />
            </div>
          </Panel>
        ) : (
          <Panel
            key="1"
            showArrow={false}
            disabled
            className={styles.panelDiv}
            header={
              <div className={`row ${styles.panelHeader}`}>
                {this.props.onlySearch ? (
                  <Search
                    style={{ marginBottom: 8 }}
                    placeholder="Search"
                    onChange={this.onSearch}
                  />
                ) : (
                  <>
                    <div className="col-md-3">
                      <A10Checkbox
                        onChange={(e: any) => {
                          this.onSelectAll(e)
                        }}
                        checked={this.state.isSelectAll}
                        disabled={
                          this.props.radioCheckbox ||
                          (!this.props.radioCheckbox &&
                            this.state.filteredClusterTreeData.length === 0)
                        }
                      >
                        Select All
                      </A10Checkbox>
                    </div>
                    <div className="col-md-6">
                      <span>
                        <label className="selCount">
                          {devicePartitions.length + ' Selected'}
                        </label>
                      </span>
                      <span>
                        <A10Button
                          className="action-button blue-text"
                          disabled={devicePartitions.length === 0}
                          onClick={this.onClearAll}
                        >
                          Clear Select
                        </A10Button>
                      </span>
                    </div>
                    <div className="col-md-3">
                      <Search
                        style={{ marginBottom: 8 }}
                        placeholder="Search"
                        onChange={this.onSearch}
                      />
                    </div>
                  </>
                )}
              </div>
            }
          >
            {tree}
          </Panel>
        )}
      </A10Collapse>
    )
  }
}

export default setupA10Container(ClusterDevicePartition)
