import {
  A10Icon,
  A10Loader,
  A10Radio,
  A10Table,
  A10Tooltip,
  A10Card,
  A10Descriptions,
  A10Row,
  A10Col,
} from '@gui-libraries/widgets'
import { DashboardService, Utilities } from 'src/services'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { _ } from '@gui-libraries/framework'
import storage from 'src/libraries/storage'

import styles from '../styles/index.module.less'

const objUtilities = new Utilities()
const objDashboardService = new DashboardService()
const moment = require('moment')
const ref = React.createRef()

export enum Expire_Status {
  PENDING = 'pending',
  EXPIRED = 'expired',
}

export const rowToolTip = (record: any) => {
  const status = record.deviceHealth !== undefined ? record.deviceHealth : 'NA'
  let healthText = ''
  if (status === 'NA') {
    healthText = 'NA'
  } else if (status === 0) {
    healthText = 'Bad'
  } else if (status >= 0.8) {
    healthText = 'Good'
  } else {
    healthText = 'Average'
  }

  return (
    <div className="tableOverToolTip maxWidth">
      <table>
        <tbody>
          <tr>
            <th>Device Name</th>
            <td>{record.name}</td>
          </tr>
          <tr>
            <th>Cluster Name</th>
            <td>{record.cluster}</td>
          </tr>
          <tr>
            <th>Health</th>
            <td>{healthText}</td>
          </tr>
          <tr>
            <th>Location</th>
            <td> {record.location}</td>
          </tr>
          <tr>
            <th># Partitions</th>
            <td>{Math.floor(record['partition-list']?.length)}</td>
          </tr>
          <tr>
            <th>ACOS Version</th>
            <td>{record['primary-sw-version']}</td>
          </tr>
          <tr>
            <th>Model</th>
            <td>{record.model}</td>
          </tr>
          <tr>
            <th>Avg CPU</th>
            <td>{`${record.avgcpu || 'NA'} (Data: ${record.avgdatacpu ||
              'NA'} Control: ${record.avgcontrolcpu || 'NA'})`}</td>
          </tr>
          <tr>
            <th>Avg Memory</th>
            <td>{record.avgmem}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export const getFormattedDate = (record: IObject) => {
  let fromDateFormat = !record['expires-at']
    ? ''
    : moment.utc(record['expires-at']).format('MM-DD-YYYY')
  if (record['expires-at'] && record['expires-at'] === '2050-12-31 00:00:00') {
    fromDateFormat = 'Never'
  }

  return fromDateFormat
}

export const getDestructuredItems = (record: IObject) => {
  const usedBW = record['used-bandwidth']
  const status = record['activation-status']
  const allotedBW = record['alloted-bandwidth']
  const allotedbandwidth = objUtilities.megaToGiga(allotedBW || 0)
  const bandwidth = objUtilities.megaToGiga(usedBW || 0)

  return {
    usedBW,
    status,
    allotedBW,
    allotedbandwidth,
    bandwidth,
  }
}

export const getDeviceDetails = (record: IObject) => {
  const { 'used-devices': usedDev, 'alloted-devices': allotedDev } = record
  return `${usedDev} / ${allotedDev === 0 ? 'Unlimited' : allotedDev}`
}

export const handleTabChange = (
  activeTab: String,
  handleTableState: React.Dispatch<React.SetStateAction<IObject>>,
  handleTableData: React.Dispatch<React.SetStateAction<IObject>>,
  handleActiveTab: React.Dispatch<React.SetStateAction<String>>,
) => {
  handleTableState([])
  handleTableData([])
  handleActiveTab(activeTab)
}

export const getTooltipComponent = (acosVersion: string, record: IObject) => {
  return (
    <A10Tooltip
      title={rowToolTip(record)}
      placement="topLeft"
      overlayClassName="toolTipWidth"
    >
      {!!acosVersion && acosVersion.split(' ')[0]}
    </A10Tooltip>
  )
}

export const getBandwidthText = (val: String, unit: String) => {
  let strUnit = `${val} Gbps`
  if (unit === 'm') {
    strUnit = `${val} Mbps`
  }

  return strUnit
}

export const getAllotedBandwidthText = (
  licenseType: String,
  allotedBW: number,
  bandWidth: IObject,
) => {
  if (allotedBW === 0) {
    if (licenseType === 'SUBSCRIBER_FLEXPOOL') {
      return 'na'
    } else {
      return 'Unlimited'
    }
  }

  const returnVal = getBandwidthText(bandWidth.val, bandWidth.unit)
  return returnVal
}

export const getUsedBandwidthText = (
  licenseType: String,
  usedBW: number,
  bandWidth: IObject,
) => {
  if (licenseType === 'SUBSCRIBER_FLEXPOOL') {
    return 'na'
  }

  if (usedBW === 0) {
    return usedBW
  }

  const returnVal = getBandwidthText(bandWidth.val, bandWidth.unit)
  return returnVal
}

export const combineClusterAndPartitionDataAndMapWithDevices = (
  deviceData: IObject[],
  partitionData: IObject,
  clusterData: IObject,
) => {
  deviceData.map((deviceItem: IObject) => {
    if (_.isEmpty(partitionData) && _.isEmpty(clusterData)) {
      return deviceData
    }

    const objPartition = partitionData[deviceItem['device-uuid']]
    const {
      pc_sent_sum: partition_pc_sent_sum,
      pc_drop_sum: partition_pc_drop_sum,
      // total_bytes_in_sum: partition_total_bytes_in_sum,
      // total_bytes_out_sum: partition_total_bytes_out_sum
    } = objPartition

    let pcSentSum,
      pcDropSum = 0
    const objCluster = clusterData[deviceItem['device-uuid']]
    if (objCluster) {
      const {
        pc_sent_sum: cluster_pc_sent_sum,
        pc_drop_sum: cluster_pc_drop_sum,
        total_bytes_in_sum: cluster_total_bytes_in_sum,
        total_bytes_out_sum: cluster_total_bytes_out_sum,
      } = objCluster

      const throughput =
        ((Number.parseFloat(cluster_total_bytes_in_sum) / 100 +
          Number.parseFloat(cluster_total_bytes_out_sum) / 100) *
          8) /
        1000000
      deviceItem['throughput'] = `${throughput.toFixed(1)}Mbps`

      pcSentSum =
        (Number.parseFloat(partition_pc_sent_sum) +
          Number.parseFloat(cluster_pc_sent_sum)) /
        100
      pcDropSum =
        (Number.parseFloat(partition_pc_drop_sum) +
          Number.parseFloat(cluster_pc_drop_sum)) /
        100
    } else {
      pcSentSum = Number.parseFloat(partition_pc_sent_sum) / 100
      pcDropSum = Number.parseFloat(partition_pc_drop_sum) / 100
    }

    const cps = (pcSentSum + pcDropSum) / 60
    deviceItem['cps'] = cps.toFixed(1)
    return deviceItem
  })

  return deviceData
}

export const getRequestPaylad = (p_ver: string) => {
  /**
   * 1 hour = 60 min, 1 min = 60 sec, 1 sec = 1000 milisecond
   * Range should be for 6 hrs
   * (60 * 60 * 1000) * 6
   */

  const miliSecond = 60 * 60 * 1000 * 6
  const dtNow = new Date()
  const endTimeInMiliSecond = dtNow.getTime()
  const startTimeInMiliSecond = endTimeInMiliSecond - miliSecond
  const payload = {
    stats: {
      rangeby: {
        start: startTimeInMiliSecond, //1651807938341,
        end: endTimeInMiliSecond, //1651829538341,
        field: 'ts',
      },
      fields: [
        'pc_sent',
        'pc_drop',
      ],
      aggregation: 'sum',
      groupby: 'device_uuid',
      filterby: {
        and: {
          p_ver: p_ver,
        },
      },
    },
  }

  return payload
}

const TabsData = (props: IObject) => {
  const { activeTab: defaultTab, providerName, providerId, record } = props
  const [activeTab, setActiveTab] = useState<String>(defaultTab)
  const [tableColumn, setTableColumn] = useState<IObject[]>([])
  const [tableData, setTableData] = useState<IObject>([])
  const [activationLink, setActivationLink] = useState<any>(null)
  const {
    get: { PROVIDER: strProvider, ENCODED_SESSION_ID: strENCODED_SESSION_ID },
  } = storage

  const firstName = record && (record['contact-first-name'] || '')
  const lastName = record && (record['contact-last-name'] || '')

  const licenseColumns = useMemo(
    () => [
      {
        title: <></>,
        dataIndex: 'activation-status',
        key: 'activation-status',
        render: (_text: string, record: any, _index: number) => {
          const { type, label } = objUtilities.getVal(
            record?.['activation-status'],
            'LICENSE',
            'activation-status',
          )
          return (
            <div className="icons-wrap">
              <HealthStatus type={type} tooltip={'License ' + label} />
            </div>
          )
        },
      },
      {
        title: 'Token',
        dataIndex: 'entitlement-key',
        key: 'entitlement-key',
        sorter: (a: IObject, b: IObject) =>
          objUtilities.sortString(a, b, 'entitlement-key'),
      },
      {
        title: 'Name',
        dataIndex: 'license-name',
        key: 'license-name',
        sorter: (a: IObject, b: IObject) =>
          objUtilities.sortString(a, b, 'license-name'),
      },
      {
        title: 'Platform',
        dataIndex: 'license-platform',
        sorter: (a: IObject, b: IObject) =>
          objUtilities.sortString(a, b, 'license-platform'),
      },
      {
        title: <>Product</>,
        dataIndex: 'license-type',
        key: 'license-type',
        className: 'td-truncate',
        render: (text: string, record: any, index: number) => {
          const licType = record['license-type']
          const glmLicType = record['glm-lic-type']
          let licTypeValue = ''
          if (glmLicType) {
            licTypeValue = glmLicTypeText(glmLicType, licType)
          }
          return (
            <span
              className="td-truncate"
              title={licType.indexOf('FLEXPOOL') > -1 ? licTypeValue : licType}
            >
              {licType.indexOf('FLEXPOOL') > -1 ? licTypeValue : licType}
            </span>
          )
        },
        sorter: (a: any, b: any) =>
          objUtilities.sortString(a, b, 'license-type'),
      },
      {
        title: <>Bandwidth</>,
        dataIndex: 'alloted-bandwidth',
        key: 'bandwidth',
        children: [
          {
            title: <>Used / Capacity</>,
            dataIndex: 'used-bandwidth',
            key: 'used-bandwidth',
            render: (_text: string, record: IObject, _index: number) => {
              const {
                usedBW,
                status,
                allotedBW,
                allotedbandwidth,
                bandwidth,
              } = getDestructuredItems(record)
              return (
                <>
                  {status === 'PENDING' ? (
                    <div>
                      <A10Tooltip
                        placement="bottom"
                        title="Capacity change request pending"
                        arrowPointAtCenter
                        overlayStyle={{ width: '150px' }}
                      >
                        <A10Icon
                          style={{ marginRight: '10px' }}
                          app="global"
                          type="warning-color"
                        />
                      </A10Tooltip>
                      <span>
                        {usedBW === 0
                          ? usedBW
                          : bandwidth.val +
                            ' ' +
                            (bandwidth.unit === 'm' ? 'Mbps' : 'Gbps')}
                        {' / '}
                        {allotedBW === 0
                          ? 'Unlimited'
                          : allotedbandwidth.val +
                            ' ' +
                            (allotedbandwidth.unit === 'm' ? 'Mbps' : 'Gbps')}
                      </span>
                    </div>
                  ) : (
                    <div
                      className={`${allotedBW > 0 &&
                        usedBW === allotedBW &&
                        'expired'}`}
                    >
                      {getUsedBandwidthText(
                        record['license-type'],
                        usedBW,
                        bandwidth,
                      )}
                      {' / '}
                      {getAllotedBandwidthText(
                        record['license-type'],
                        allotedBW,
                        allotedbandwidth,
                      )}
                    </div>
                  )}
                </>
              )
            },
            sorter: (a: IObject, b: IObject) =>
              objUtilities.sortString(a, b, 'used-bandwidth'),
          },
        ],
      },
      {
        title: <>Devices</>,
        dataIndex: 'alloted-devices',
        key: 'devices',
        children: [
          {
            title: <>Used / Capacity</>,
            dataIndex: 'used-devices',
            key: 'used-devices',
            render: (_text: string, record: IObject, _index: number) => {
              return getDeviceDetails(record)
            },
            sorter: (a: IObject, b: IObject) =>
              objUtilities.sortString(a, b, 'used-devices'),
          },
        ],
      },
      {
        title: <>Expires on</>,
        dataIndex: 'expires-at',
        key: 'expires-at',
        sorter: (a: IObject, b: IObject) =>
          objUtilities.sortDate(a, b, 'expires-at'),
        render: (_text: string, record: IObject, _index: number) => {
          let status = record['activation-status']
          const fromDateFormat = getFormattedDate(record)
          return (
            <div
              className={Expire_Status[status]}
              title={moment
                .utc(record?.['expires-at'])
                .format('MMM DD, YYYY hh:mm A')}
            >
              {fromDateFormat}
            </div>
          )
        },
      },
    ],
    [],
  )

  const deviceColumns = useMemo(
    () => [
      {
        title: 'Device Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => objUtilities.sortString(a, b, 'name'),
      },
      {
        title: 'Device Model',
        dataIndex: 'model',
        key: 'model',
        sorter: (a: any, b: any) => objUtilities.sortString(a, b, 'model'),
      },
      {
        title: 'ACOS Version',
        dataIndex: 'primary-sw-version',
        key: 'primary-sw-version',
        className: 'td-truncate-min',
        sorter: (a: IObject, b: IObject) =>
          objUtilities.sortString(a, b, 'primary-sw-version'),
        render: (acosVersion: any, record: any) =>
          getTooltipComponent(acosVersion, record),
      },
      {
        title: 'Serial Number',
        dataIndex: 'serial_number',
        key: 'device-uuid',
        className: 'td-truncate-min',
        sorter: (a: IObject, b: IObject) =>
          objUtilities.sortString(a, b, 'device-uuid'),
        render: (_serialNumber: any, record: any) => {
          return (
            <A10Tooltip
              title={record['device-uuid']}
              placement="topLeft"
              overlayClassName="toolTipWidth"
            >
              {!!record['device-uuid']
                ? `${record['device-uuid'].substring(0, 20)}...`
                : ''}
            </A10Tooltip>
          )
        },
      },
      {
        title: 'Site',
        dataIndex: 'site',
        key: 'site',
        sorter: (a: IObject, b: IObject) => (a.site > b.site ? 1 : -1),
      },
      {
        title: 'Throughput',
        dataIndex: 'throughput',
        sorter: (a: IObject, b: IObject) =>
          a.throughput > b.throughput ? 1 : -1,
      },
      {
        title: 'CPS',
        dataIndex: 'cps',
        sorter: (a: IObject, b: IObject) => (a.cps > b.cps ? 1 : -1),
      },
    ],
    [],
  )

  useEffect(() => {
    async function fetchData() {
      const headers = {
        Authorization: strENCODED_SESSION_ID,
        provider: strProvider,
      }


      if(record['provider-status'] !== 'verified'){
        const providerRes = await objDashboardService.getProvider(headers, null, [
          providerName,
        ])
  
        const users = providerRes?.data?.provider?.['user-list'] ?? []
        const adminUser = users.find((user: IObject) => user['user-id'] === 'provider-admin') ?? {}    
  
        setActivationLink(getActivationLink(record, adminUser))
      }

      if (activeTab === 'license') {
        setTableColumn(licenseColumns)
        const { data } = await objDashboardService.getProviderLicense(
          headers,
          null,
          [providerName],
        )
        setTableData(data)
      } else {
        setTableColumn(deviceColumns)
        const objPayload = getRequestPaylad(providerId)

        /**
         * code to get device list by provider
         */
        const { data } = await objDashboardService.getProviderDevices(
          headers,
          null,
          [providerName],
        )

        headers['X-providerids'] = providerId

        /**
         * code to get device partition statistics
         */
        let objPartitionStats = null
        try {
          const {
            data,
          } = await objDashboardService.getDevicePartitionStatistics(
            headers,
            objPayload,
          )
          objPartitionStats = data
        } catch (error) {
          objUtilities.showMessage('WARN_DEVICE_PARTITION_STATS', 2, 1)
        }

        /**
         * code to get device cluster statistics
         */
        let objClusterStats = null
        try {
          objPayload.stats.fields = [
            ...objPayload.stats.fields,
            'total_bytes_in',
            'total_bytes_out',
          ]
          const { data } = await objDashboardService.getDeviceClusterStatistics(
            headers,
            objPayload,
          )
          objClusterStats = data
        } catch (error) {
          objUtilities.showMessage('WARN_DEVICE_CLUSTER_STATS', 2, 1)
        }

        /**
         * code to combine cluster and partition data and map to devices
         */
        const objDeviceData = combineClusterAndPartitionDataAndMapWithDevices(
          data['device-list'],
          objPartitionStats?.stats,
          objClusterStats?.stats,
        )
        setTableData(objDeviceData)
      }
    }

    fetchData()
  }, [activeTab, providerName, providerId])

  const onHandleTabChange = useCallback(
    event => {
      handleTabChange(
        event?.target?.value,
        setTableColumn,
        setTableData,
        setActiveTab,
      )
    },
    [setTableColumn, setTableData, setActiveTab],
  )

  const glmLicTypeText = (glmLicType: string, licType: string) => {
    switch (glmLicType) {
      case 'adc_cap_sub':
      case 'adc_cap_burst_sub':
        return 'ADC Subscription (Capacity)'

      case 'cgn_cap_sub':
      case 'cgn_cap_burst_sub':
        return 'CGN Subscription (Capacity)'

      case 'cfw_cap_sub':
      case 'cfw_cap_burst_sub':
        return 'CFW Subscription (Capacity)'

      case 'adc_inst_sub':
      case 'adc_inst_burst_sub':
        return 'ADC Subscription (Instance)'

      case 'cgn_inst_sub':
      case 'cgn_inst_burst_sub':
        return 'CGN Subscription (Instance)'

      case 'cfw_inst_sub':
      case 'cfw_inst_burst_sub':
        return 'CFW Subscription (Instance)'

      case 'adc_inst_perpet':
        return 'ADC Perpetual (Instance)'

      case 'cgn_inst_perpet':
        return 'CGN Perpetual (Instance)'

      case 'cfw_inst_perpet':
        return 'CFW Perpetual (Instance)'

      case 'cgn_subscriber_sub':
        return 'CGN Subscriber'

      case 'cgn_subscriber_perpet':
        return 'CGN Subscriber'

      default:
        return licType
    }
  }

  const getActivationLink = (provider: any, adminUser: any) => {
    const url =
      typeof adminUser === 'undefined'
        ? ''
        : window.location.protocol +
          '//' +
          window.location.host +
          '/confirm/provider-admin/' +
          adminUser['activation-token'] +
          '?provider=' +
          provider.name
    return (
      <>
        {adminUser &&
        adminUser.state !== 'ACTIVE' &&
        typeof adminUser['activation-token'] !== 'undefined' ? (
          <A10Row gutter={[16, 16]}>
            <A10Col span={4}>
              <span className={styles.detailTitle}>Admin Activation Link</span>
            </A10Col>
            <A10Col span={20}>
              {url !== '' ? (
                <a href={url} target="_blank" className={styles.blueText}>
                  {url}
                </a>
              ) : (
                ''
              )}
            </A10Col>
          </A10Row>
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <>
      <A10Row>
        <A10Card>
          <A10Descriptions
            title="PROVIDER DETAILS"
            layout="vertical"
            size="small"
            column={{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 2, xs: 1 }}
          >
            <A10Descriptions.Item label="Provider Status">
              {record['provider-status'] === 'verified'
                ? 'Verified'
                : 'Not Verified'}
            </A10Descriptions.Item>
            <A10Descriptions.Item label="Primary Contact">
              {`${firstName} ${lastName}`}
            </A10Descriptions.Item>
            <A10Descriptions.Item label="Contact Number">
              {record && record['organization-phone-number']
                ? record['organization-phone-number']
                : ''}
            </A10Descriptions.Item>
            <A10Descriptions.Item label="Contact Email">
              {record && record['email'] ? record['email'] : ''}
            </A10Descriptions.Item>
          </A10Descriptions>
        </A10Card>
      </A10Row>
      <A10Row className="auth-card">
        <A10Card style={{ width: '50%' }}>
          <A10Descriptions
            layout="vertical"
            size="small"
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          >
            <A10Descriptions.Item label="Description">
              {record && record.description ? record.description : ''}
            </A10Descriptions.Item>
          </A10Descriptions>
        </A10Card>
        <A10Card style={{ width: '50%', paddingLeft: '20px' }}>
          <A10Descriptions
            layout="vertical"
            size="small"
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          >
            <A10Descriptions.Item label="Admin Activation Link">
              {activationLink}
            </A10Descriptions.Item>
          </A10Descriptions>
        </A10Card>
      </A10Row>
      <A10Row className={styles.mainTabsRow}>
        <A10Radio.Group
          onChange={onHandleTabChange}
          defaultValue={activeTab}
          buttonStyle="tab"
        >
          <A10Radio.Button value="license" data-testid="typeLicense">
            License
          </A10Radio.Button>
          <A10Radio.Button value="device" data-testid="typeDevice">
            Device
          </A10Radio.Button>
        </A10Radio.Group>
        <A10Table
          columns={tableColumn}
          dataSource={tableData.map((key: any, index: number) => {
            key.key = index
            return key
          })}
          scroll={{ x: '100%' }}
          style={{ marginTop: '20px' }}
          loading={
            props.isLoading && {
              indicator: <A10Loader container={ref} />,
            }
          }
          pagination={{ hideOnSinglePage: true, pageSize: 10 }}
        />
      </A10Row>
    </>
  )
}

export default TabsData
