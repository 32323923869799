import React from 'react'
import moment from 'moment'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10DropdownMenu,
  A10Icon,
  A10Button,
  A10Tooltip,
  A10Col,
  A10Table,
  A10Modal,
  A10Message,
  A10Loader,
} from '@gui-libraries/widgets'

import { IDefaultMethods } from 'src/containers/Controller'
import {
  DashboardService,
  OrganizationService,
  Utilities,
} from 'src/services/index'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import storage from 'src/libraries/storage'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { AdminUserForm } from './Forms/AdminUserForm'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import { Messages } from 'src/locale/en'
import UserRole from 'src/constants/Data/UserRole'

export interface IAdminUserProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
}
export interface IAdminUserState {
  allData: any
  showSlidingPage: boolean
  addUserObj: any
  editMode: boolean
  slidingTitle: string
  slidingDesc: string
  loadingIcon: boolean
  loadingIconModal: boolean
  deleteModalState: boolean
  selectedUser: any
  formUser: any
}

class AdminUser extends A10Container<IAdminUserProps, IAdminUserState> {
  DashboardService = new DashboardService()
  OrganizationService = new OrganizationService()
  Utilities = new Utilities()
  Messages = new Messages()
  UserRole = new UserRole()
  dataLoaded = false
  childForm: any = null
  rolesList = JSON.parse(JSON.stringify(this.UserRole?.SUPER_ADMIN_ROLE))
  ref = React.createRef()

  state = {
    showSlidingPage: false,
    addUserObj: {
      firstName: '',
      lastName: '',
      userId: '',
      emailId: '',
      contactNumber: '',
    },
    allData: [],
    formUser: null,
    slidingTitle: 'Add Harmony Admin',
    editMode: false,
    loadingIcon: false,
    selectedUser: { id: '' },
    loadingIconModal: false,
    deleteModalState: false,
  }

  adminColumns = [
    {
      title: <HealthStatus type="Status" hideTooltip={true} />,
      dataIndex: 'state',
      key: 'state', // TODO: change this key
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'status'),
      render: (text: string, record: any, index: number) => {
        const statusText = text.toLowerCase()
        record.status = 'stopped'
        if (statusText === 'unverified' || statusText === 'reset_password') {
          record.status = 'warning'
        } else if (statusText === 'active') {
          record.status = 'ongoing'
        }
        return <HealthStatus type={record.status} text="U" tooltip={text} />
      },
    },
    {
      title: <>Name</>,
      dataIndex: 'first-name',
      key: 'first-name',
      className: 'td-truncate',
      render: (text: string, record: any, index: number) => {
        return (
          <A10Tooltip
            title={
              record['first-name']
                ? record['first-name'] + ' ' + record['last-name']
                : ''
            }
            placement="topLeft"
          >
            {record['first-name']
              ? record['first-name'] + ' ' + record['last-name']
              : ''}
          </A10Tooltip>
        )
      },
      sorter: this.Utilities.sortFullName,
    },
    {
      title: <>User ID</>,
      dataIndex: 'user-id',
      key: 'user-id',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'user-id'),
      className: 'td-truncate',
      render: (text: string, record: any, index: number) => {
        return (
          <A10Tooltip title={text} placement="topLeft">
            {text}
          </A10Tooltip>
        )
      },
    },
    {
      title: <>Email</>,
      dataIndex: 'email-id',
      key: 'email-id',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'email-id'),
      className: 'td-truncate',
      render: (text: string, record: any, index: number) => {
        return (
          <A10Tooltip title={text} placement="topLeft">
            {text}
          </A10Tooltip>
        )
      },
    },
    {
      title: <>Created On</>,
      dataIndex: 'created-at',
      key: 'created-at',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'created-at'),
      render: (text: string, record: any, index: number) => {
        const fromDateFormat = moment(record['created-at'])
          .utc()
          .format('MMM DD, YYYY hh:mm A')
        return <>{fromDateFormat}</>
      },
    },
    {
      title: <>Created By</>,
      dataIndex: 'created-by',
      key: 'created-by',
      sorter: (a: IObject, b: IObject) =>
        this.Utilities.sortString(a, b, 'created-by'),
      render: (text: string, record: IObject, index: number) => {
        return text || 'N/A'
      },
    },
    {
      title: <>Role</>,
      dataIndex: 'role',
      key: 'role',
      sorter: (a: IObject, b: IObject) => this.Utilities.sortString(a, b, ''),
      render: () => {
        return 'Super Admin'
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (key: any) => {
        const clickAction = (component: JSX.Element, index: number) => {
          if (component.key === 'edit') {
            this.editUser(key)
          } else if (component.key === 'delete') {
            this.setState({
              deleteModalState: true,
              selectedUser: key,
            })
          }
        }
        return key['user-id'] !== storage.get.USER_ID &&
          key['user-id'] !== 'super-admin' ? (
          <div className="table-dropdown">
            <A10DropdownMenu
              menu={[
                <div key="edit">Edit </div>,
                <div key="delete">Delete </div>,
              ]}
              title=""
              onClick={clickAction}
              trigger="hover"
              placement="bottomRight"
              arrowPointAtCenter={true}
            />
          </div>
        ) : (
          <div className="text-right">
            <div>
              {key['user-id'] === storage.get.USER_ID ? (
                <A10Icon
                  type="user"
                  className="action-icon"
                  title="Current user"
                />
              ) : (
                'This user cannot be edited or removed.'
              )}
            </div>
          </div>
        )
      },
    },
  ]

  componentDidMount() {
    this.loadUsers()
  }

  handleFormValidation = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  handleOk = () => {
    const deleteUser = this.DashboardService.deleteUser(null, null, [
      this.state.selectedUser['user-id'],
    ])
    this.setState({
      loadingIconModal: true,
    })
    deleteUser
      .then((resp: any) => {
        A10Message.success('Super Admin Deleted successfully', 10, close)
        this.loadUsers()
        this.dataLoaded = false
        this.setState({
          loadingIconModal: false,
          deleteModalState: false,
          selectedUser: { 'user-id': '' },
        })
      })
      .catch((error: any) => {
        const messsge =
          'Unable to delete super admin. ' + error?.response?.data?.message
        A10Message.error(messsge, 10, close)
        this.setState({
          loadingIconModal: false,
          deleteModalState: false,
          selectedUser: { 'user-id': '' },
        })
        return false
      })
    return true
  }

  handleCancel = () => {
    if (this.state.loadingIconModal) {
      return false
    }
    this.setState({
      deleteModalState: false,
      selectedUser: { name: '' },
    })
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({ showSlidingPage: isOpen })
  }

  handleAddFormChange = (data: any, isEdit: boolean) => {
    // @ts-ignore
    this.setState({
      addUserObj: data,
    })
  }

  refreshUsers = () => {
    this.loadUsers()
    this.setSlidingPage(false)
    this.setState({
      loadingIcon: false,
    })
  }

  handleSave = () => {
    const {
      firstName,
      lastName,
      userId,
      emailId,
      contactNumber,
    } = this.state.addUserObj
    const editMode = this.state.editMode

    const adminObj = {
      'email-id': emailId,
      'user-id': userId.toLocaleLowerCase(),
      'first-name': firstName,
      'last-name': lastName,
      'roles-list': ['superadmin'],
      'contact-number': contactNumber,
    }

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      charset: 'utf-8',
      Authorization: storage.get.ENCODED_SESSION_ID,
      removeProvider: editMode ? false : true,
    }

    if (editMode) {
      delete adminObj['roles-list']
      delete headers['Content-Type']
      delete headers.Accept
      delete headers.removeProvider
    }

    this.setState({
      loadingIcon: true,
    })

    const addUser = editMode
      ? this.DashboardService.updateUser(headers, adminObj, [userId])
      : this.DashboardService.addUser(null, adminObj, storage.get.PROVIDER)
    addUser
      .then((response: any) => {
        this.Utilities.showMessage(
          editMode
            ? 'SUCCESS_SUPER_ADMIN_UPDATE'
            : 'SUCCESS_SUPER_ADMIN_CREATE',
          1,
          true,
        )
        this.refreshUsers()
      })
      .catch((error: any) => {
        this.setSlidingPage(false)
        let msg = ''
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.status === 409
        ) {
          msg = this.Messages.DUPLICATE_USER_NAME
        }
        this.Utilities.showMessage(
          editMode ? 'FAIL_SUPER_ADMIN_UPDATE' : 'FAIL_SUPER_ADMIN_CREATE',
          0,
          true,
          msg,
        )
        this.setState({
          loadingIcon: false,
        })
      })
    return false
  }

  createUserRbacRoles = async (userId: string) => {
    const userid = userId.toLowerCase()
    const payload = {
      providerId: storage.get.PROVIDER,
      userid,
      roles: ['superadmin'],
    }

    const createRole = this.OrganizationService.userRbacRoleUpdate(
      null,
      payload,
      [storage.get.PROVIDER, userId],
    )
    createRole
      .then((resp: any) => {
        this.refreshUsers()
      })
      .catch((error: any) => {
        const msg = ''
        this.Utilities.showMessage(
          'Error in rbac role assignment of superadmin',
          0,
          0,
          msg,
        )
        this.refreshUsers()
      })
  }

  editUser = (user: any) => {
    const userObj = this.state.addUserObj
    userObj.firstName = user['first-name'] ? user['first-name'] : ''
    userObj.lastName = user['last-name'] ? user['last-name'] : ''
    userObj.emailId = user['email-id'] ? user['email-id'] : ''
    userObj.userId = user['user-id']
    userObj.contactNumber = user['contact-number'] ? user['contact-number'] : ''

    this.setState({
      addUserObj: userObj,
      formUser: user,
      editMode: true,
      showSlidingPage: true,
      slidingTitle: 'Edit Super Admin',
    })
  }
  addUser = () => {
    const user = {
      firstName: '',
      lastName: '',
      emailId: '',
      userId: '',
      contactNumber: '',
    }
    this.setState({
      editMode: false,
      formUser: null,
      addUserObj: user,
      showSlidingPage: true,
      slidingTitle: 'Add Harmony Admin',
    })
  }

  loadUsers() {
    const provider = storage.get.PROVIDER
    const usersResponse = this.DashboardService.getUsers(null, null, provider)

    usersResponse
      .then((response: any) => {
        this.dataLoaded = true
        const users = response.data
        const superAdmins: any = []
        users.forEach((user: any) => {
          superAdmins.push(user)
        })
        this.setState({
          allData: superAdmins,
        })
      })
      .catch((error: any) => {
        console.log(error, error && error.response) // we need to create a centralised error handling
        this.dataLoaded = true
      })
  }

  render() {
    const { showSlidingPage } = this.state

    return (
      <>
        <ContentSection>
          <ContentHeader type="flex" align="middle" justify="space-between">
            <A10Col>
              <ContentTitle
                title="Harmony Admins"
                count={this.state.allData.length}
              />
            </A10Col>
            <A10Col>
              <ActionButton
                text="Add"
                onClick={this.addUser}
                iconProps={{ app: 'global', type: 'add-new' }}
              />
            </A10Col>
          </ContentHeader>
          <ContentBody ref={this.ref}>
            <A10Table
              columns={this.adminColumns}
              dataSource={this.state.allData.map((key: any, index: number) => {
                key.key = index
                if (key.bandwidth === undefined || key.requests === undefined) {
                  return key
                } else {
                  return key
                }
              })}
              pagination={{ hideOnSinglePage: true, pageSize: 10 }}
              loading={
                !this.dataLoaded && {
                  indicator: <A10Loader container={this.ref} />,
                }
              }
            />
          </ContentBody>
        </ContentSection>
        <A10Modal
          title="Delete SuperAdmin"
          visible={this.state.deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={this.state.loadingIconModal}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
              disabled={this.state.loadingIconModal}
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            Do you want to delete {this.state.selectedUser['user-id']} from{' '}
            {storage.get.ADMIN_LEVEL === 'provider'
              ? storage.get.PROVIDER
              : storage.get.CURRENT_TENANT.name}
            ?
          </p>
          {this.state.loadingIconModal ? (
            <div className="">
              <ReactLoading
                type="bars"
                color="#4a90e2"
                height={40}
                width={40}
              />
            </div>
          ) : null}
        </A10Modal>
        <FormatSlidingPage
          isOpen={showSlidingPage}
          onRequestOk={this.handleFormValidation}
          onRequestClose={this.setSlidingPage.bind(this, false)}
          title={this.state.slidingTitle}
          description={this.state.slidingDesc}
          disableSave={this.state.loadingIcon}
        >
          <>
            <AdminUserForm
              defaultMethods={this.props.defaultMethods}
              onSubmitForm={this.handleSave}
              handleChange={this.handleAddFormChange}
              addUserObj={this.state.addUserObj}
              userInfo={this.state.formUser}
              allUsers={this.state.allData}
              refreshUsers={this.refreshUsers}
              onRef={(ref: any): any => (this.childForm = ref)}
            />
            {this.state.loadingIcon ? (
              <div className="">
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </div>
            ) : null}
          </>
        </FormatSlidingPage>
      </>
    )
  }
}

export default setupA10Container(AdminUser)
