export const occurArray = (
  currentList: string[] = [],
  oldList: string[] = [],
) => {
  const removeList = new Array()
  const newList = new Array()
  currentList.map((name: string) => {
    if (name && oldList.indexOf(name) === -1 && newList.indexOf(name) === -1) {
      newList.push(name)
    }
  })

  oldList.map((name: string) => {
    if (
      name &&
      currentList.indexOf(name) === -1 &&
      removeList.indexOf(name) === -1
    ) {
      removeList.push(name)
    }
  })
  return { currentList, removeList, newList }
}

export const flattenFileArray = (object: IObject) => {
  let result: IObject[] = []
  if (object.file) {
    if ('aflex-list' in object.file) {
      result = result.concat(object.file['aflex-list'])
    }
    if ('bw-list-list' in object.file) {
      const bwList = object.file['bw-list-list'].map((item: IObject) => {
        item.type = 'bw'
        return item
      })
      result = result.concat(bwList)
    }
    if ('ssl-key-list' in object.file) {
      const keyList = object.file['ssl-key-list'].map((item: IObject) => {
        item.type = 'key'
        return item
      })
      result = result.concat(keyList)
    }
    if ('ssl-cert-list' in object.file) {
      const certList = object.file['ssl-cert-list'].map((item: IObject) => {
        item.type = 'cert'
        return item
      })
      result = result.concat(certList)
    }
    if ('ssl-ca-list' in object.file) {
      const caList = object.file['ssl-ca-list'].map((item: IObject) => {
        item.type = 'ca'
        return item
      })
      result = result.concat(caList)
    }
    if ('waf-policy-list' in object.file) {
      const wafList = object.file['waf-policy-list'].map((item: IObject) => {
        item.type = 'waf'
        return item
      })
      result = result.concat(wafList)
    }
  }
  return result
}
