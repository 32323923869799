import React from 'react'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import { A10Table, A10Icon } from '@gui-libraries/widgets'

import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import A10Panel from 'src/components/ADC/A10Panel'
import storage from 'src/libraries/storage'

export interface IAssociateServiceGroupProps extends IA10ContainerDefaultProps {
  tenant: string
  logicalPartition: string
  associateType?: string
  templateName?: string
  existSgNames: string[]
  onChangeSelectedValue: (selectedValue: string[]) => void
}

export interface IAssociateServiceGroupState {
  serverList: IObject[]
  isLoading: boolean
  selectedRowKeys: IObject[]
  currentSelectedNames: string[]
}

class AssociateServiceGroup extends A10Container<
  IAssociateServiceGroupProps,
  IAssociateServiceGroupState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      serverList: [],
      isLoading: false,
      selectedRowKeys: [],
      currentSelectedNames: [],
    }
  }

  componentWillMount() {
    this.init(this.props)
  }

  componentWillReceiveProps(nextProps: any) {
    this.init(nextProps)
  }

  componentDidMount() {
    this.getServers()
  }

  init = (props: any) => {
    const { existSgNames } = props
    const currentSelectedNames = existSgNames.map((sgName: string) => {
      return sgName
    })
    this.setState({ currentSelectedNames })
  }

  getHttpClient = () => {
    const {
      GLOBAL_CONFIG: {
        EPIC_DEPENDENCIES: { httpClient },
      },
    } = this.props
    return httpClient
  }

  getServers = async () => {
    const { associateType, logicalPartition } = this.props
    // Start to load data
    this.setState({ isLoading: true })

    const { tenant, existSgNames = [] } = this.props
    const httpClient = this.getHttpClient()
    const sharedObjApi = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${logicalPartition}`
    try {
      const sgApi = `${sharedObjApi}/slb/template/${associateType}?detail=true`
      const templatePrefixList = `${associateType}-list`
      const {
        data: { [templatePrefixList]: serverList = [] },
      } = await httpClient.get(sgApi)
      const initSelectedRowKeys = new Array()
      serverList.map((item: IObject, index: number) => {
        item.key = index
        if (existSgNames.indexOf(item.uuid) !== -1) {
          initSelectedRowKeys.push(index)
        }
        return item
      })
      this.setState({
        serverList,
        selectedRowKeys: initSelectedRowKeys,
        isLoading: false,
      })
    } catch {
      this.setState({ isLoading: false })
    }
  }

  getColumns = () => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        render: (text: IObject, record: IObject) => {
          return record.name
        },
      },
      {
        title: '# Shared Obj.',
        dataIndex: 'sharedObj',
        render: (text: IObject, record: IObject) => {
          const { 'referrer-list': referrerList = [] } = record
          return referrerList.length
        },
      },
    ]
  }

  getRowSelection = () => {
    return {
      selectedRowKeys: this.state.selectedRowKeys,
      onSelect: (
        record: IObject,
        selected?: IObject,
        selectedRows?: IObject[],
      ) => {
        const { currentSelectedNames } = this.state
        let actionRows = currentSelectedNames
        if (selected) {
          if (actionRows.indexOf(record.uuid) === -1) {
            actionRows.push(record.uuid)
          }
        } else {
          actionRows = _.remove(actionRows, (name: string) => {
            return name !== record.uuid
          })
        }
        this.setState({ currentSelectedNames: actionRows }, () => {
          this.onChangeValue()
        })
      },
      onSelectAll: (
        selected: IObject,
        selectedRows: IObject[],
        changeRows: IObject[],
      ) => {
        const { currentSelectedNames } = this.state
        let actionRows = currentSelectedNames
        if (selected) {
          changeRows.map((item: IObject) => {
            if (actionRows.indexOf(item.uuid) === -1) {
              actionRows.push(item.uuid)
            }
          })
        } else {
          const changeRowNames = changeRows.map((item: IObject) => {
            return item.uuid
          })
          actionRows = _.remove(actionRows, (name: string) => {
            return changeRowNames.indexOf(name) === -1
          })
        }
        this.setState({ currentSelectedNames: actionRows }, () => {
          this.onChangeValue()
        })
      },
      onChange: (selectedRowKeys: IObject[]) => {
        this.setState({ selectedRowKeys })
      },
    }
  }

  onChangeValue = () => {
    const { currentSelectedNames } = this.state
    const { onChangeSelectedValue } = this.props
    onChangeSelectedValue(currentSelectedNames)
  }

  render() {
    const { templateName } = this.props
    return (
      <A10Panel
        title={
          <A10IconTextGroup
            text={templateName}
            icon={<A10Icon style={{ fontSize: 48 }} type="desktop" />}
          />
        }
        isFormContent={false}
      >
        <A10Table
          columns={this.getColumns()}
          dataSource={this.state.serverList}
          rowSelection={this.getRowSelection()}
          pagination={{
            hideOnSinglePage: true,
          }}
          loading={
            this.state.isLoading
              ? {
                  indicator: (
                    <div className="loading-icon">
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    </div>
                  ),
                }
              : false
          }
        />
      </A10Panel>
    )
  }
}

export default setupA10Container(AssociateServiceGroup)
