import React from 'react'
import ReactLoading from 'react-loading'
import moment from 'moment-timezone'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Input,
  A10DropdownMenu,
  A10Tooltip,
  A10Col,
  A10Table,
  A10Button,
  A10Modal,
} from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import { Utilities, TroubleShootService } from 'src/services'
import { Messages } from 'src/locale/en/Messages'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import { UpgradeModal } from 'src/components/shared/UpgradeModal'
import { ScheduleTaskDetails } from '../ScheduleTaskDetails'
import { SlidingDeviceCmdForms } from '../SlidingDeviceCmdForms'
import ActivateLicense from 'src/components/shared/ActivateLicense'

const styles = require('./styles/index.module.less')

export interface IScheduledTasksProps extends IA10ContainerDefaultProps {
  tenantName: string
  maxSnippets: number
  remainingSnippets: number
  getRemainingSnippets: () => void
  devicesDetail: IObject[]
  tenantDetail: IObject
}
export interface IScheduledTasksState {
  cmdSnippets: any[]
  runCmdScheduleTasks: any[]
  enableSave: boolean
  deleteModalState: boolean
  editMode: boolean
  selectedTask: any
  showRunSlidingPage: boolean
  showSlidingPage: boolean
  devicePartitions: any[]
  workflowID: string
  workflowSliderState: boolean
  searchRunTasks: string
  refreshScheduleLogs: boolean
  showUpgrade: boolean
  isPeriodicAllowed: boolean
  activeLicense: string
  showAddLicense: boolean
}

class ScheduledTasks extends A10Container<
  IScheduledTasksProps,
  IScheduledTasksState
> {
  Messages = new Messages()
  Utilities = new Utilities()
  TroubleShootService = new TroubleShootService()
  provider = storage.get.PROVIDER
  headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    provider: storage.get.PROVIDER,
    Authorization: storage.get.ENCODED_SESSION_ID,
    user: storage.get.USER_ID,
  }
  childForm: any = null
  cmdSnippets: any[] = []
  runCmdScheduleTasks: any[] = []

  constructor(props: any) {
    super(props)
    this.state = {
      cmdSnippets: [],
      runCmdScheduleTasks: [],
      enableSave: false,
      deleteModalState: false,
      editMode: false,
      selectedTask: {},
      showRunSlidingPage: false,
      showSlidingPage: false,
      devicePartitions: [],
      workflowID: '',
      workflowSliderState: false,
      searchRunTasks: '',
      refreshScheduleLogs: false,
      showUpgrade: false,
      isPeriodicAllowed: false,
      activeLicense: '',
      showAddLicense: false,
    }

    this.getCliSnippets()
    this.getEntitlements()
  }

  runTasksColumns = [
    {
      title: 'Snippet',
      dataIndex: 'snippet',
      key: 'snippet',
      className: 'td-truncate',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'snippet'),
      render: (snippet: string, record: any) => {
        return (
          <A10Tooltip placement="right" title={record.snippet || ''}>
            {record.snippet || ''}
          </A10Tooltip>
        )
      },
    },
    {
      title: 'Task Name',
      dataIndex: 'name',
      key: 'name',
      className: 'td-truncate',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
    },
    {
      title: this.Messages.FREQUENCY,
      dataIndex: 'frequency',
      key: 'frequency',
      className: 'td-truncate',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'frequency'),
    },
    {
      title: this.Messages.STARTS_ON,
      dataIndex: 'startDate',
      key: 'startDate',
      className: 'td-truncate',
      sorter: (a: any, b: any) => this.Utilities.sortDate(a, b, 'startDate'),
    },
    {
      title: 'Time Zone',
      dataIndex: 'timeZoneOffset',
      key: 'timeZoneOffset',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'timeZoneOffset'),
    },
    {
      title: 'Device : Partition',
      dataIndex: 'device-partition',
      key: 'device-partition',
      className: 'td-truncate',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'device-partition'),
      render: (devices: any, record: any) => {
        const deviceName =
          devices && devices.length > 0 ? devices.join(', ') : ''
        const deviceTooltip =
          devices &&
          devices.map((device: string) => {
            return (
              <>
                {device}
                <br />
              </>
            )
          })
        return (
          <A10Tooltip placement="right" title={deviceTooltip || ''}>
            {deviceName || ''}
          </A10Tooltip>
        )
      },
    },
    {
      title: 'User',
      dataIndex: 'created_by',
      key: 'created_by',
      className: 'td-truncate',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'created_by'),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (runTask: any) => {
        const clickAction = (component: JSX.Element, index: number) => {
          if (component.key === 'edit') {
            const input_parameters = JSON.parse(runTask.input_parameters || {})
            const cmdSnippet = !input_parameters.commands_source
              ? ''
              : this.cmdSnippets.find((cmdSnippet: any) => {
                  return cmdSnippet.id === input_parameters.commands_source
                })
            this.setState({
              editMode: true,
              enableSave: true,
              showSlidingPage: true,
              selectedTask: {
                id: runTask.id,
                name: runTask.name,
                devicePartitions: input_parameters && input_parameters.devices,
                cliSnippet: JSON.stringify(cmdSnippet),
                when: 'schedule',
                frequency: input_parameters?.scheduler
                  ? input_parameters.scheduler.frequency
                  : '',
                startsOn: input_parameters?.scheduler
                  ? input_parameters.scheduler.startsOn
                  : '',
                timezone: input_parameters?.scheduler
                  ? input_parameters.scheduler.timezone
                  : '',
                timeZoneOffset: runTask.timeZoneOffset,
              },
            })
          } else if (component.key === 'delete') {
            this.setState({
              deleteModalState: true,
              enableSave: true,
              selectedTask: runTask,
            })
          }
        }
        return (
          <div className="text-right">
            <i>
              <A10DropdownMenu
                menu={[
                  <div key="edit">Edit </div>,
                  <div key="delete">Delete </div>,
                ]}
                title=""
                style={{ color: '#757575', marginBottom: '-15px' }}
                onClick={clickAction}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            </i>
          </div>
        )
      },
    },
  ]
  dataLoaded = false

  componentDidUpdate = (prevProps: IScheduledTasksProps) => {
    if (prevProps.tenantDetail !== this.props.tenantDetail) {
      this.getScheduledTasks()
    }
  }

  getEntitlements = () => {
    this.TroubleShootService.getLicenseEntitlements(null, null, [
      storage.get.PROVIDER,
    ])
      .then((resp: IObject) => {
        const { data, status } = resp
        const deviceManagement = data?.['device-management']

        let isAllowed = false
        if (!deviceManagement?.backup && status === 200) {
          isAllowed = true
        } else {
          isAllowed = deviceManagement?.['cli-snippets']?.periodic
        }
        this.setState({ isPeriodicAllowed: isAllowed })
      })
      .catch((err: IObject) => {
        this.setState({
          activeLicense: err?.response?.data
        })
      })
      .catch((err: IObject) => {})
  }

  getCliSnippets = () => {
    const cliSnippets = this.TroubleShootService.listCliSnippets(
      this.headers,
      null,
      [this.provider],
    )
    cliSnippets
      .then((response: any) => {
        const cmdSnippets =
          response?.data && Array.isArray(response.data) ? response.data : []
        this.cmdSnippets = cmdSnippets
        this.setState({ cmdSnippets })
        this.getScheduledTasks()
      })
      .catch((error: any) => {
        this.setState({ cmdSnippets: [] })
        this.getScheduledTasks()
      })
  }

  getScheduledTasks = () => {
    const { tenantName, devicesDetail, tenantDetail } = this.props

    const scheduleTasks = this.TroubleShootService.listScheduleTasks(
      this.headers,
      null,
      [this.provider],
    )

    const isPartitionInTenantLPList = (
      partitionUuid: string,
      tenantLPList: IObject[],
    ): boolean => {
      return tenantLPList.some(tenantLP => {
        return tenantLP.deploy_target.target['device-list'].some(
          (device: IObject) => {
            return device['partition-list'].some(
              (partition: IObject) => partition.uuid === partitionUuid,
            )
          },
        )
      })
    }

    scheduleTasks
      .then((response: any) => {
        const runCmdScheduleTasks =
          response?.data && Array.isArray(response.data)
            ? response.data.reduce((prev: IObject[], cur: IObject) => {
                const inputParams = JSON.parse(cur.input_parameters),
                  devices: string[] = []
                const frequency = inputParams?.scheduler?.frequency
                  ? inputParams.scheduler.frequency
                  : ''
                const timezoneDetails = inputParams?.scheduler?.timezone
                  ? _.isObject(inputParams.scheduler.timezone)
                    ? inputParams.scheduler.timezone
                    : inputParams.scheduler.timezone.indexOf('{') > -1
                    ? JSON.parse(inputParams.scheduler.timezone)
                    : null
                  : null
                cur.timeZoneOffset = timezoneDetails
                  ? timezoneDetails.offset
                  : '-'
                let startDate = ''
                if (inputParams?.scheduler?.startsOn) {
                  if (!timezoneDetails) {
                    cur.timeZoneOffset =
                      'UTC' + inputParams.scheduler.startsOn.substr(19)
                    startDate = moment
                      .utc(
                        inputParams.scheduler.startsOn,
                        'YYYY-MM-DD HH:mm:ss',
                      )
                      .format('MMM DD, YYYY hh:mm A')
                  } else {
                    const timeoffset = timezoneDetails.value
                    startDate = moment
                      .utc(
                        inputParams.scheduler.startsOn,
                        'YYYY-MM-DD HH:mm:ss',
                      )
                      .tz(timeoffset)
                      .format('MMM DD, YYYY hh:mm A')
                  }
                }

                const devicesWithPartition = devicesDetail?.filter(
                  (deviceObj: any) => {
                    return inputParams.devices.some(
                      (device: IObject) =>
                        deviceObj.name === device.name &&
                        deviceObj['partition-list'].some(
                          (partition: IObject) =>
                            device.partition === partition.name,
                        ),
                    )
                  },
                )
                const partitionIds = inputParams.devices.reduce(
                  (prevP: IObject, curP: IObject) => {
                    devicesWithPartition.forEach(deviceP => {
                      if (deviceP.name === curP.name) {
                        prevP.push(
                          deviceP['partition-list'].find(
                            (partition: IObject) =>
                              curP.partition === partition.name,
                          )?.uuid,
                        )
                      }
                    })

                    return prevP
                  },
                  [],
                )
                if (
                  !tenantName ||
                  partitionIds.some((id: string) =>
                    isPartitionInTenantLPList(
                      id,
                      tenantDetail?.['logical-partition-list'],
                    ),
                  )
                ) {
                  inputParams.devices.map((device: any) => {
                    if (devices.indexOf(device.name) === -1) {
                      devices.push(device.name + ':' + device.partition)
                    }
                  })
                  cur['device-partition'] = devices
                  cur['frequency'] = frequency
                  cur['startDate'] = startDate
                  if (this.cmdSnippets.length > 0) {
                    const cmdSnippet = this.cmdSnippets.find((cmd: any) => {
                      return cmd.id === cur.snippet_id
                    })
                    if (cmdSnippet) {
                      cur['commands'] = cmdSnippet.content.split(',').join(', ')
                      cur['snippet'] = cmdSnippet.name
                    }
                  }
                  prev.push(cur)
                }
                return prev
              }, [])
            : []

        this.dataLoaded = true
        this.runCmdScheduleTasks = runCmdScheduleTasks
        this.setState({
          runCmdScheduleTasks,
          refreshScheduleLogs: !this.state.refreshScheduleLogs,
        })
      })
      .catch((error: any) => {
        this.dataLoaded = true
        this.runCmdScheduleTasks = []
        this.setState({ runCmdScheduleTasks: [] })
      })
  }

  handleCancel = () => {
    this.setState({
      deleteModalState: false,
      enableSave: false,
      selectedTask: {},
      showRunSlidingPage: false,
      showSlidingPage: false,
      workflowSliderState: false,
      workflowID: '',
    })
  }

  deleteRunTask = () => {
    const { showMessage } = this.Utilities
    this.setState({ enableSave: false })
    const saveCliSnippet = this.TroubleShootService.deleteScheduleTasks(
      this.headers,
      null,
      [this.provider, this.state.selectedTask.id],
    )
    saveCliSnippet
      .then((response: any) => {
        showMessage(`Deleted scheduled run task successfully`, 1, 0)
        this.handleCancel()
        this.getScheduledTasks()
        this.props.getRemainingSnippets()
      })
      .catch((error: any) => {
        showMessage(`Error in delete of scheduled run task`, 0, 0)
        this.handleCancel()
      })
  }

  newRunCmdSchedule = () => {
    const { activeLicense } = this.state
    if (activeLicense !== 'No Active License') {
      this.setState({
        showSlidingPage: true,
        editMode: false,
        selectedTask: {
          name: '',
          devicePartitions: [],
          cliSnippet: '',
          when: 'schedule',
          frequency: '',
          startsOn: '',
          timezone: '',
        },
      })
    } else {
      this.setState({ showAddLicense: true })
    }
  }

  onViewWorkflowStatus = (data: IObject) => {
    const details = data?.details
    const devices = Object.keys(details)
    if (devices && devices.length > 0) {
      this.setState({
        workflowID: details[devices[0]],
        workflowSliderState: true,
      })
    }
  }

  getComaSeparatedCmds = (cmdContent: string) => {
    const cmdContentArray = cmdContent.split('\n')
    let cmds = ''
    cmdContentArray.map((cmd: string) => {
      if (cmd.trim() !== '') {
        cmds += (cmds.length > 0 ? ',' : '') + cmd.trim()
      }
    })
    return cmds
  }

  updateState = (stateName: string, value: any) => {
    // @ts-ignore
    this.setState({ [stateName]: value })
  }

  searchRunTasks = (e: any) => {
    const searchRunTasks =
      e && e.target && (e.target.value || e.target.value === '')
        ? e.target.value
        : this.state.searchRunTasks
    this.Utilities.search(
      this,
      searchRunTasks,
      'name',
      {
        storeData: this.runCmdScheduleTasks,
        stateName: 'runCmdScheduleTasks',
      },
      'searchRunTasks',
    )
  }

  refreshRunTasks = () => {
    this.getScheduledTasks()
    this.setState({ searchRunTasks: '' })
  }

  onRefreshScheduleTasks = () => {
    this.getScheduledTasks()
    this.props.getRemainingSnippets()
  }

  renderScheduleLogs = (record: any) => {
    return (
      <ScheduleTaskDetails
        scheduledTask={record}
        cmdSnippets={this.state.cmdSnippets}
        refreshLogs={this.state.refreshScheduleLogs}
      />
    )
  }

  render() {
    const {
      cmdSnippets,
      runCmdScheduleTasks,
      deleteModalState,
      selectedTask,
      enableSave,
      showSlidingPage,
      editMode,
      showUpgrade,
      isPeriodicAllowed,
      showAddLicense,
    } = this.state

    const { maxSnippets, devicesDetail, tenantDetail } = this.props

    return (
      <>
        <A10Col className={styles.searchContainer}>
          <A10Input.Search
            type="text"
            onChange={this.searchRunTasks}
            name="searchBox"
            value={this.state.searchRunTasks}
            placeholder="Search"
            className={styles.searchInput}
          />
          <ActionButton
            text="Refresh"
            onClick={this.refreshRunTasks}
            iconProps={{ app: 'global', type: 'refresh' }}
          />
        </A10Col>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col className={styles.titleContainer}>
            <ContentTitle
              title="Scheduled Snippets"
              count={runCmdScheduleTasks.length}
            />
          </A10Col>
          <A10Col className={styles.actionsContainer}>
            <ActionButton
              text="Create"
              onClick={this.newRunCmdSchedule}
              iconProps={{ app: 'global', type: 'add-new' }}
            />
          </A10Col>
        </ContentHeader>
        <ContentBody className={styles.scheduledTaskContent}>
          <A10Table
            columns={this.runTasksColumns}
            dataSource={runCmdScheduleTasks.map((key: any, index: number) => {
              key.key = index
              return key
            })}
            expandedRowRender={this.renderScheduleLogs}
            size="small"
            loading={
              !this.dataLoaded
                ? {
                    indicator: (
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    ),
                  }
                : false
            }
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
          />
          {/* <Table name="Troubleshoot" data={cmdHistory} /> */}
        </ContentBody>
        <SlidingDeviceCmdForms
          showSlidingPage={showSlidingPage}
          updateState={this.updateState}
          cmdSnippets={cmdSnippets}
          runCmdScheduleTasks={runCmdScheduleTasks}
          runCmdSchedule={selectedTask}
          editTask={editMode}
          isPeriodicAllowed={isPeriodicAllowed}
          onViewWorkflowStatus={this.onViewWorkflowStatus}
          refreshScheduleTasks={this.onRefreshScheduleTasks}
          devicesDetail={devicesDetail}
          tenantDetail={tenantDetail}
        />
        <A10Modal
          title="Delete Scheduled Run Task"
          visible={deleteModalState}
          onOk={this.deleteRunTask}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={!enableSave}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.deleteRunTask}
              className="yesbtn"
              disabled={!enableSave}
            >
              Yes
            </A10Button>,
          ]}
        >
          {`Do you want to delete '${selectedTask.name}' `}
        </A10Modal>
        <UpgradeModal
          content={`You are using a license which only allows ${maxSnippets} CLI Snippets. For questions or upgrading your license please contact A10 Networks Customer Support or Sales.`}
          visible={showUpgrade}
          onClose={() => {
            this.setState({ showUpgrade: false })
          }}
        />
        <ActivateLicense
          content={'Please add a valid license to your account'}
          visible={showAddLicense}
          onClose={() => {
            this.setState({ showAddLicense: false })
          }}
        />
      </>
    )
  }
}

export default setupA10Container(ScheduledTasks)
