import React, { useEffect, useState } from 'react'
import { A10Message } from '@gui-libraries/widgets'

import Node from './Node'
import { OperatorConsoleService } from 'src/services'
import moment from 'moment'

interface INodesContainerProps {}
const operatorConsoleService = new OperatorConsoleService()

const getFromDate = (
  value: number,
  unit: string,
  currentDateTime: IObject,
  millisecond: number,
) => {
  const fromDate = moment(currentDateTime)
    .utc()
    .subtract(value, unit)
    .format('YYYY-MM-DD HH:mm:ss')
  return `${fromDate.split(' ').join('T')}.${millisecond}Z`
}

const getToDate = (currentDateTime: IObject, millisecond: number) => {
  const toDate = moment(currentDateTime)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss')
  return `${toDate.split(' ').join('T')}.${millisecond}Z`
}

export const getSteps = (input: string) => {
  let steps = '1m'
  switch (input) {
    case '1h':
      steps = '5m'
      break
    case '5h':
      steps = '30m'
      break
    default:
      steps = '1m'
      break
  }
  return steps
}

export const getQueries = (timeRange: string, instance: string) => {
  const millisecond = moment().get('millisecond')
  const currentDateTime = new Date()
  const timeArray = getTimeArray(timeRange)
  const toTimeString = getToDate(currentDateTime, millisecond)
  const fromTimeString: string = getFromDate(
    timeArray[0],
    timeArray[1],
    currentDateTime,
    millisecond,
  )

  const singleInstanceCPU = `(avg(irate(node_cpu_seconds_total%7Bmode!="idle",instance="${instance}"%7D[1m])) by (instance))*100&start=${fromTimeString}&end=${toTimeString}&step=${getSteps(
    timeRange,
  )}`

  const singleInstanceMemory = `node_memory_MemAvailable_bytes%7Bjob="node-exporter",instance="${instance}"%7D&start=${fromTimeString}&end=${toTimeString}&step=${getSteps(
    timeRange,
  )}`

  const singleInstanceDisk = `(node_filesystem_avail_bytes%7Bdevice=~"/dev/[a-z]d[a-z][0-9]*",instance="${instance}"%7D)&start=${fromTimeString}&end=${toTimeString}&step=${getSteps(
    timeRange,
  )}`
  return { singleInstanceCPU, singleInstanceMemory, singleInstanceDisk }
}

export const getTimeArray = (input: string) => {
  const result = []
  switch (input) {
    case '1h':
      result.push(1, 'hours')
      break
    case '5h':
      result.push(5, 'hours')
      break
    case '10m':
      result.push(10, 'minutes')
      break
    default:
      result.push(10, 'minutes')
      break
  }
  return result
}

export const getArrayIndex = (inputArray: IObject[], instance: string) => {
  return inputArray.findIndex(
    (dataObject: IObject) => dataObject.instance === instance,
  )
}

export const convertStringToNumberArray = (inputArray: IObject[]) => {
  return inputArray
    ? inputArray.map((value: IObject[]) => {
        return [Number(value[0]) * 1000, Number(value[1])]
      })
    : []
}

export const setTimeSeriesData = (
  cpuTimeSeries: IObject,
  memoryTimeSeries: IObject,
  diskTimeSeries: IObject,
  finalData: IObject[],
  instance: string,
) => {
  const cpuUsageObject = cpuTimeSeries?.data?.data?.result[0]
  const cpuArrayIndex = getArrayIndex(finalData, instance)
  finalData[cpuArrayIndex].cpuTimeline = convertStringToNumberArray(
    cpuUsageObject?.values,
  )

  const memoryUsageObject = memoryTimeSeries?.data?.data?.result[0]
  const memoryArrayIndex = getArrayIndex(finalData, instance)
  finalData[memoryArrayIndex].memoryTimeline = convertStringToNumberArray(
    memoryUsageObject?.values,
  )

  const diskUsageObject = diskTimeSeries?.data?.data?.result[0]
  const diskArrayIndex = getArrayIndex(finalData, instance)
  finalData[diskArrayIndex].diskTimeline = convertStringToNumberArray(
    diskUsageObject?.values,
  )
  return finalData
}

export const setMetricsData = (
  memoryUsage: IObject,
  diskUsage: IObject,
  cpuTimeSeries: IObject,
  memoryTimeSeries: IObject,
  diskTimeSeries: IObject,
  finalData: IObject[],
) => {
  memoryUsage?.data?.data?.result?.map((memory: IObject) => {
    const arrayIndex = getArrayIndex(finalData, memory?.metric?.instance)
    finalData[arrayIndex].memory = Math.round(Number(memory?.value[1]))
  })

  diskUsage?.data?.data?.result?.map((disk: IObject) => {
    const arrayIndex = getArrayIndex(finalData, disk?.metric?.instance)
    finalData[arrayIndex].disk = Math.round(Number(disk?.value[1]))
  })

  cpuTimeSeries?.data?.data?.result?.forEach((cpuUsage: IObject) => {
    const arrayIndex = getArrayIndex(finalData, cpuUsage?.metric?.instance)
    finalData[arrayIndex].cpuTimeline = convertStringToNumberArray(
      cpuUsage?.values,
    )
  })

  memoryTimeSeries?.data?.data?.result?.forEach((diskUsage: IObject) => {
    const arrayIndex = getArrayIndex(finalData, diskUsage?.metric?.instance)
    finalData[arrayIndex].memoryTimeline = convertStringToNumberArray(
      diskUsage?.values,
    )
  })

  diskTimeSeries?.data?.data?.result?.forEach((memoryUsage: IObject) => {
    const arrayIndex = getArrayIndex(finalData, memoryUsage?.metric?.instance)
    finalData[arrayIndex].diskTimeline = convertStringToNumberArray(
      memoryUsage?.values,
    )
  })
  return finalData
}

const NodesContainer: React.FC<INodesContainerProps> = props => {
  const [nodes, setNodes] = useState([])

  useEffect(() => {
    async function fetchData() {
      const finalData: IObject[] = []

      const cpuExpression =
        '(avg(irate(node_cpu_seconds_total%7Bmode!="idle"%7D[30m])) by (instance))*100'
      const memoryExpression =
        '100-(((node_memory_MemAvailable_bytes%7Bjob="node-exporter"%7D)/(node_memory_MemTotal_bytes%7Bjob="node-exporter"%7D )) *100)'
      const diskExpression =
        '(100-(node_filesystem_avail_bytes%7Bdevice=~"/dev/[a-z]d[a-z][0-9]*"%7D/node_filesystem_size_bytes%7Bdevice=~"/dev/[a-z]d[a-z][0-9]*"%7D * 100))'

      const currentDateTime = new Date()
      const millisecond = moment(currentDateTime).get('millisecond')

      const toTimeString = getToDate(currentDateTime, millisecond)
      const fromTimeString: string = getFromDate(
        10,
        'minutes',
        currentDateTime,
        millisecond,
      )

      const cpuTimeSeriesExpression = `(avg(irate(node_cpu_seconds_total%7Bmode!="idle"%7D[1m])) by (instance))*100&start=${fromTimeString}&end=${toTimeString}&step=${getSteps(
        '10m',
      )}`

      const diskTimeSeriesExpression = `node_filesystem_avail_bytes%7Bdevice=~"/dev/[a-z]d[a-z][0-9]*"%7D&start=${fromTimeString}&end=${toTimeString}&step=${getSteps(
        '10m',
      )}`

      const memoryTimeSeriesExpression = `node_memory_MemAvailable_bytes%7Bjob="node-exporter"%7D&start=${fromTimeString}&end=${toTimeString}&step=${getSteps(
        '10m',
      )}`

      Promise.all([
        operatorConsoleService.getNodeUsage(null, null, [cpuExpression]),
        operatorConsoleService.getNodeUsage(null, null, [memoryExpression]),
        operatorConsoleService.getNodeUsage(null, null, [diskExpression]),
        operatorConsoleService.getTimeSeriesUsage(null, null, [
          cpuTimeSeriesExpression,
        ]),
        operatorConsoleService.getTimeSeriesUsage(null, null, [
          memoryTimeSeriesExpression,
        ]),
        operatorConsoleService.getTimeSeriesUsage(null, null, [
          diskTimeSeriesExpression,
        ]),
      ])
        .then(
          ([
            cpuUsage,
            memoryUsage,
            diskUsage,
            cpuTimeSeries,
            memoryTimeSeries,
            diskTimeSeries,
          ]) => {
            cpuUsage?.data?.data?.result?.map(
              (usage: IObject, index: number) => {
                const usageObject: IObject = {
                  name: `Node ${index + 1}: ${usage?.metric?.instance}`,
                  cpu: Math.round(Number(usage?.value[1])),
                  memory: 0,
                  disk: 0,
                  instance: usage?.metric?.instance,
                  cpuTimeline: [],
                  memoryTimeline: [],
                  diskTimeline: [],
                  interval: 400,
                }
                finalData.push(usageObject)
              },
            )
            setNodes(
              setMetricsData(
                memoryUsage,
                diskUsage,
                cpuTimeSeries,
                memoryTimeSeries,
                diskTimeSeries,
                finalData,
              ),
            )
          },
        )
        .catch(e => {
          const message = `Fail to get analytics data.`
          A10Message.error(message, 10, close)
        })
    }
    fetchData()
  }, [])

  const onTimeRangeFilterChange = (timeRange: string, instance: string) => {
    const finalData = [...nodes]
    Promise.all([
      operatorConsoleService.getTimeSeriesUsage(null, null, [
        getQueries(timeRange, instance)?.singleInstanceCPU,
      ]),
      operatorConsoleService.getTimeSeriesUsage(null, null, [
        getQueries(timeRange, instance)?.singleInstanceMemory,
      ]),
      operatorConsoleService.getTimeSeriesUsage(null, null, [
        getQueries(timeRange, instance)?.singleInstanceDisk,
      ]),
    ])
      .then(([cpuTimeSeries, memoryTimeSeries, diskTimeSeries]) => {
        setNodes(
          setTimeSeriesData(
            cpuTimeSeries,
            memoryTimeSeries,
            diskTimeSeries,
            finalData,
            instance,
          ),
        )
      })
      .catch(e => {
        const message = `Fail to get analytics data.`
        A10Message.error(message, 10, close)
      })
  }

  return (
    <>
      {nodes.map(node => {
        const {
          name,
          cpu,
          memory,
          disk,
          cpuTimeline,
          interval,
          memoryTimeline,
          diskTimeline,
          instance,
        } = node
        return (
          <Node
            key={name}
            title={name}
            cpu={cpu}
            memory={memory}
            disk={disk}
            cpuTimeline={cpuTimeline}
            memoryTimeline={memoryTimeline}
            diskTimeline={diskTimeline}
            interval={interval}
            instance={instance}
            onTimeRangeChange={onTimeRangeFilterChange}
            nodes={nodes}
          />
        )
      })}
    </>
  )
}

export default NodesContainer
