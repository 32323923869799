import React from 'react'
import { A10Container, _ } from '@gui-libraries/framework'
import { A10Icon, A10Tabs, A10Row, A10Col } from '@gui-libraries/widgets'

import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import A10Panel from 'src/components/ADC/A10Panel'
import { SortButton } from 'src/components/shared/SortButton'
import {
  TasksStatusList,
  ITaskInfor,
} from 'src/containers/Controller/Dashboard/Common/PullUpWorkflow/TasksStatusList'
import { workflowStatusMapping } from 'src/libraries/storage'
import StatusMessage from './StatusMessage'

export interface IWorkflowStatusProps {
  tasks: ITaskInfor[]
  status: string
  refresh: any
  multi?: boolean
  index?: number
  title?: string
  showMessage?: boolean
  statusMessage?: string
  statusCLI?: string
  onCompleteCallback?: () => void
}

type SortOrder = 'asc' | 'desc'
export interface IWorkflowStatusState {
  displayOrder: SortOrder
}

class WorkflowStatus extends A10Container<
  IWorkflowStatusProps,
  IWorkflowStatusState
> {
  state: IWorkflowStatusState = {
    displayOrder: 'asc',
  }
  interval: NodeJS.Timeout
  constructor(props: IWorkflowStatusProps) {
    super(props)
    this.interval = null
  }

  componentDidMount() {
    const { status, refresh, multi = false, index } = this.props
    if (status === 'IN_PROGRESS' || status === 'RUNNING') {
      if (multi) {
        if (index !== undefined) {
          this.interval = setInterval(() => refresh(index), 5000)
        }
      } else {
        this.interval = setInterval(() => refresh(), 5000)
      }
    }
  }

  componentDidUpdate(prevProps: IWorkflowStatusProps) {
    const {
      status,
      refresh,
      multi = false,
      index,
      onCompleteCallback,
    } = this.props
    if (
      !!!this.interval &&
      prevProps.status !== status &&
      (status === 'IN_PROGRESS' || status === 'RUNNING')
    ) {
      if (multi) {
        if (index !== undefined) {
          this.interval = setInterval(() => refresh(index), 5000)
        }
      } else {
        this.interval = setInterval(() => refresh(), 5000)
      }
    }
    if (
      (prevProps.status === status &&
        prevProps.status === 'COMPLETED' &&
        status === 'COMPLETED') ||
      (prevProps.status !== status &&
        [
          'COMPLETED',
          'COMPLETED_WITH_ERRORS',
          'FAILED',
          'FAILED_WITH_TERMINAL_ERROR',
        ].indexOf(status) >= 0)
    ) {
      if (this.interval) {
        clearInterval(this.interval)
      }
    }

    if (prevProps.status !== 'COMPLETED' && status === 'COMPLETED') {
      if (_.isFunction(onCompleteCallback)) {
        onCompleteCallback()
      }
    }

    // HARMONY-19694 - To call workflow status GET call for one more time when the status is COMPLETED
    if (
      prevProps.status !== status &&
      prevProps.status !== 'COMPLETED' &&
      status === 'COMPLETED'
    ) {
      if (multi) {
        if (index !== undefined) {
          refresh(index)
        }
      } else {
        refresh()
      }
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  getFormatDurationText = () => {
    const { tasks, status = 'IN_PROGRESS' } = this.props

    let minStartTime: number
    let maxEndTime: number
    tasks.forEach((taskItem: ITaskInfor) => {
      const { startTime, endTime } = taskItem
      if (_.isFinite(startTime) && startTime !== 0) {
        if (!_.isFinite(minStartTime)) {
          minStartTime = startTime
        } else if (startTime < minStartTime) {
          minStartTime = startTime
        }
      }

      if (_.isFinite(endTime) && endTime !== 0) {
        if (!_.isFinite(maxEndTime)) {
          maxEndTime = endTime
        } else if (endTime > maxEndTime) {
          maxEndTime = endTime
        }
      }
    })

    let timeRange: number = 0
    if (
      _.isFinite(minStartTime) &&
      _.isFinite(maxEndTime) &&
      maxEndTime > minStartTime
    ) {
      timeRange = maxEndTime - minStartTime
    }
    const second = Math.floor(timeRange / 1000) % 60
    const minute = Math.floor(timeRange / 1000 / 60) % 60
    const hour = Math.floor(timeRange / 1000 / 60 / 60) % 24
    const day = Math.floor(timeRange / 1000 / 60 / 60 / 24)

    let text = ''
    text += day > 0 ? `${day}d ` : ''
    text += hour > 0 ? `${hour}h ` : ''
    text += minute > 0 ? `${minute}m ` : ''
    text += `${second}s`

    if (status === 'IN_PROGRESS') {
      text = text + ' (still running)'
    }

    return text
  }

  onChangeOrder = (order: SortOrder) => {
    this.setState({ displayOrder: order })
  }

  render() {
    const {
      tasks,
      status = 'IN_PROGRESS',
      title,
      statusMessage = '',
      statusCLI = '',
      showMessage = false,
      multi,
    } = this.props
    const { displayOrder } = this.state

    let titleDisplayed = ''
    if (title) {
      if (!!!multi) {
        const desc = title.split('$$&&')
        if (desc.length === 2) {
          titleDisplayed = `Logical Partition ${desc[0]} Version ${desc[1]}`
        } else if (desc.length === 3) {
          titleDisplayed = `Device Partition ${desc[0]} : ${desc[1]} Version ${desc[2]}`
        }
      } else {
        titleDisplayed = title
      }
    }
    const WindowTitle = () => {
      return (
        <>
          <span style={{ marginLeft: 5, marginRight: 5 }}>
            {workflowStatusMapping[status]}
          </span>
          <span
            style={{
              marginLeft: 10,
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 300,
            }}
          >
            {titleDisplayed}
          </span>
        </>
      )
    }

    const StatusIcon = () => {
      if (status === 'COMPLETED') {
        return <A10Icon app="global" type="success-green" />
      } else if (status === 'COMPLETED_WITH_ERRORS') {
        return <A10Icon app="global" type="success" />
      } else if (
        status === 'FAILED' ||
        status === 'FAILED_WITH_TERMINAL_ERROR'
      ) {
        return <A10Icon app="global" type="error-color" />
      } else if (status === 'IN_PROGRESS' || status === 'RUNNING') {
        return (
          <span className="a10-widget-appicon">
            <img
              src={require('./images/Spinner-1s-32px.svg')}
              style={{ width: 20, height: 20 }}
            />
          </span>
        )
      } else {
        return <A10Icon app="global" type="form-section" />
      }
    }

    return multi ? (
      <>
        <A10Panel
          title={
            <A10IconTextGroup text={<WindowTitle />} icon={<StatusIcon />} />
          }
          menu={
            <span>
              Duration:
              <span
                style={{ fontSize: '14px', fontWeight: 300, margin: '0 10px' }}
              >
                {this.getFormatDurationText()}
              </span>
              <SortButton
                order={displayOrder}
                onOrderChange={this.onChangeOrder}
              />
            </span>
          }
          isFormContent={false}
        >
          <A10Tabs defaultActiveKey="1" style={{ overflow: 'visible' }}>
            <A10Tabs.TabPane tab="Tasks" key="1">
              <TasksStatusList
                taskList={tasks}
                displayOrder={displayOrder}
                visible={true}
              />
            </A10Tabs.TabPane>
            <A10Tabs.TabPane tab="Status" key="2">
              <div className="workflow-pullup-detail">
                <A10Row className="detail-row">
                  <A10Col span={3}>
                    <span className="infor-label">Status Message</span>
                  </A10Col>
                  <A10Col span={21}>
                    <p className="infor-text message">
                      {statusMessage || 'No Data'}
                    </p>
                  </A10Col>
                </A10Row>
                {statusCLI ? (
                  <A10Row className="detail-row">
                    <A10Col span={3}>
                      <span className="infor-label">Status CLI</span>
                    </A10Col>
                    <A10Col span={21}>
                      <p className="infor-text cli">{statusCLI || 'No Data'}</p>
                    </A10Col>
                  </A10Row>
                ) : null}
              </div>
            </A10Tabs.TabPane>
          </A10Tabs>
        </A10Panel>
      </>
    ) : (
      <>
        <A10Panel
          title={
            <A10IconTextGroup text={<WindowTitle />} icon={<StatusIcon />} />
          }
          menu={
            <span>
              Duration:
              <span
                style={{ fontSize: '14px', fontWeight: 300, margin: '0 10px' }}
              >
                {this.getFormatDurationText()}
              </span>
              <SortButton
                order={displayOrder}
                onOrderChange={this.onChangeOrder}
              />
            </span>
          }
          isFormContent={false}
        >
          <TasksStatusList
            taskList={tasks}
            displayOrder={displayOrder}
            visible={true}
          />
        </A10Panel>
        {showMessage ? (
          <StatusMessage message={statusMessage} statusCLI={statusCLI} />
        ) : null}
      </>
    )
  }
}

export default WorkflowStatus
