import React from 'react'
import moment from 'moment-timezone'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import storage from 'src/libraries/storage'
import Utilities from 'src/services/Utilities/Utilities'
import { UrlService, DashboardService } from 'src/services'
import { IDefaultMethods } from 'src/containers/Controller'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { ScheduleReportForm } from 'src/containers/Controller/Monitor/Reports/ReportSchedule/Forms/ScheduleReportForm'

export interface IAction {
  id: string
  details: any
  detailsString: string
  open: boolean
}

export interface IScheduleReport {
  template: string
  duration: string
  description: string
  tags: any[]
  when: string
  occurance: string
  starts: any
  time: any
  advance: boolean
  actions: IAction[]
  timezone: string
  exisitingAction: boolean
  actionSelected: any
  emails: any
  scope: string
}

export let SCHEDULE_REPORT_OBJ: IScheduleReport = {
  template: '',
  duration: '1day',
  description: '',
  tags: [],
  when: 'schedule',
  occurance: 'Daily',
  starts: null,
  advance: false,
  timezone: '',
  actions: [
    {
      id: '',
      details: {},
      open: false,
      detailsString: '',
    },
  ],
  exisitingAction: false,
  actionSelected: '',
  emails: [],
  scope: '',
}

export interface IReportFormActionsProps extends IA10ContainerDefaultProps {
  onClose: any
  when: string
  show: boolean
  loadReports: any
  tenantToggled: boolean
  oneTimeReport?: boolean
  defaultMethods: IDefaultMethods
  scheduleData?: IObject[]
  currentTab?: string
}
export interface IReportFormActionsState {
  show: boolean
  searchString: string
  allData: any
  generateData: IObject[]
  formData: IObject
  editMode: boolean
  scheduleFormObj: any
  tenantToggled: boolean
  deleteModalState: boolean
  selectedReport: any
}

class ReportFormActions extends A10Container<
  IReportFormActionsProps,
  IReportFormActionsState
> {
  static getDerivedStateFromProps(
    nextProps: IReportFormActionsProps,
    prevState: IReportFormActionsState,
  ) {
    if (nextProps.show !== prevState.show) {
      return {
        show: nextProps.show,
      }
    }
    return null
  }
  Utilities = new Utilities()
  UrlService = new UrlService()
  DashboardService = new DashboardService()
  childForm: any = null

  constructor(props: IReportFormActionsProps) {
    super(props)

    if (this.props.when) {
      SCHEDULE_REPORT_OBJ.when = this.props.when
    }
    this.state = {
      searchString: '',
      allData: [],
      generateData: [],
      show: false,
      formData: JSON.parse(JSON.stringify(SCHEDULE_REPORT_OBJ)),
      editMode: false,
      scheduleFormObj: {},
      tenantToggled: this.props.tenantToggled,
      deleteModalState: false,
      selectedReport: {},
    }
  }

  componentDidMount() {
    this.loadReports()
  }

  loadReports = () => {
    const provider =  storage.get.PROVIDER
    const tenant = storage.get.CURRENT_TENANT

    const headers = this.Utilities.getXAccountHeaderDetails(false)
    if (!headers) {
      return
    }
    const payload = {
      tenantid: tenant.name,
      providerid: provider,
    }

    headers.tenant = tenant.name
    const functionTobeCalled = this.props.currentTab === 'generated' ? 'getScheduleReports' : 'getReports'
    const reportResponse = this.DashboardService[functionTobeCalled](headers, payload, [
      provider,
      tenant.name,
    ])

    reportResponse
      .then((response: IObject) => {
        if (response?.data && response?.data?.success) {
          const reports = response.data.reports?.resultSet
          this.setState({
            generateData: reports
          })
        }
      })
      .catch((error: IObject) => {
        console.log(error)
      })
  }

  setSlidingPage = (isOpen: boolean, data?: IObject, editMode?: boolean) => {
    this.setState({
      show: isOpen,
      formData: data,
    })
  }

  handleSave = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  handleChange = (data: any, isEdit: boolean) => {
    // @ts-ignore
    this.setState({
      scheduleFormObj: data,
    })
  }

  closeReportForm = () => {
    this.setSlidingPage(false, JSON.parse(JSON.stringify(SCHEDULE_REPORT_OBJ)))
    this.props.onClose()
  }

  getTimestamp() {
    return moment().unix()
  }

  extractNameFromEmail(email: string) {
    return email.split('@').shift()
  }

  handleAddAction = () => {
    const { emails, when } = this.state.scheduleFormObj
    const { showMessage } = this.Utilities
    const tenant = this.Utilities.getCurrentDrillLevelObject()
    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const emailObj = [
      {
        email_ids: emails,
        extended_subject: '',
        extended_message: '',
      },
    ]

    let actionNameNew = `email`

    if (emails && emails.length > 0) {
      emails.forEach(ele => {
        actionNameNew = `${actionNameNew}_${this.extractNameFromEmail(ele)}`
      })
    }
    actionNameNew = `${actionNameNew}_${this.getTimestamp()}`
    const actionPayload: any = {
      def_name: actionNameNew,
      account_id: tenant.name,
      definition: {
        email: emailObj,
        webhook: [],
      },
      type: 'add',
    }
    actionPayload.filter = {
      account_id: tenant.name,
    }
    const actionAdd = this.DashboardService.addAction(
      headers,
      actionPayload,
      storage.get.TARGET_URL,
    )
    actionAdd
      .then((response: any) => {
        const actionDefNames = []
        actionDefNames.push(response.data.def_id)
        if (when === 'schedule') {
          this.createScheduledReport(headers, actionDefNames)
        } else {
          this.createAdhocReport(headers, actionDefNames)
        }
      })
      .catch((error: any) => {
        showMessage('ACTION_ADD_FAILED', 0, 1, error.message)
      })
    return false
  }

  handleAdd = () => {
    const {
      when,
      exisitingAction,
      emails,
      actionSelected,
    } = this.state.scheduleFormObj

    const headers = this.Utilities.getXAccountHeaderDetails(false)
    if (!headers) {
      return
    }
    const actionDefNames = []
    if (actionSelected) {
      actionDefNames.push(actionSelected)
    }
    if (!exisitingAction && emails && emails.length > 0) {
      this.handleAddAction()
    } else {
      if (when === 'schedule') {
        this.createScheduledReport(headers, actionDefNames)
      } else {
        this.createAdhocReport(headers, actionDefNames)
      }
    }
  }

  createAdhocReport = (headers: any, actions: any) => {
    const {
      name,
      type,
      duration,
      timezone,
      tags,
      starts,
    } = this.state.scheduleFormObj
    const tenant = storage.get.CURRENT_TENANT
    const provider = storage.get.PROVIDER
    const userName = storage.get.USER_NAME
    const payload: any = {
      name,
      type,
      provider,
      range: duration,
      tenant: tenant.name,
      instanceType: 'adhoc',
      tenantUUID: tenant.uuid,
      trigger: 'userTriggered',
      actions,
      tags,
      path: 'http://127.0.0.1:5080',
      timezone,
      starts: moment(starts).format('YYYY-MM-DD HH:mm:ss'),
      created_by: userName,
      isUserTriggeredReport: true,
    }

    const createReport = this.DashboardService.createAdhocReport(
      headers,
      payload,
      [provider, tenant.name],
    )
    createReport
      .then((response: any) => {
        this.Utilities.showMessage(response.data.message, 1, 0)
        this.setSlidingPage(false)
        this.props.loadReports()
        this.closeReportForm()
      })
      .catch((error: any) => {
        const message =
          error && error.response
            ? error.response.status && error.response.status === 403
              ? 'Access denied'
              : error.response.data && error.response.data.message
              ? error.response.data.message
              : ''
            : error.message
            ? error.message
            : ''
        this.Utilities.showMessage('REPORT_ADD_FAILED', 0, 1, message)
      })
  }

  createScheduledReport = (headers: any, actions: any) => {
    const {
      name,
      type,
      occurance,
      description,
      duration,
      tags,
      timezone,
      starts,
    } = this.state.scheduleFormObj
    const tenant = storage.get.CURRENT_TENANT
    const provider = storage.get.PROVIDER
    const userName = storage.get.USER_NAME
    const reportPayload: any = {
      type,
      name,
      actions,
      occurance,
      description,
      workFlowId: '',
      providerId: provider,
      tenantId: tenant.name,
      tags,
      timezone,
      start_generating_from: moment(starts.utc()).format('YYYY-MM-DD HH:mm:ss'),
      created_by: userName,
      last_modified_by: userName,
      duration,
      tenant_uuid: tenant.uuid,
    }

    const createReport = this.DashboardService.createScheduledReport(
      headers,
      reportPayload,
      [provider, tenant.name],
    )
    createReport
      .then((response: any) => {
        this.Utilities.showMessage('REPORT_SCHEDULE_SUCCESS', 1, 1)
        this.props.loadReports()
        this.closeReportForm()
      })
      .catch((error: any) => {
        const message =
          error && error.response
            ? error.response.status && error.response.status === 403
              ? 'Access denied'
              : error.response.data && error.response.data.message
              ? error.response.data.message
              : ''
            : error.message
            ? error.message
            : ''
        this.Utilities.showMessage('REPORT_ADD_FAILED', 0, 1, message)
        this.closeReportForm()
      })
  }

  render() {
    const { show, generateData } = this.state
    const { oneTimeReport, defaultMethods, scheduleData } = this.props

    return (
      <FormatSlidingPage
        isOpen={show}
        onRequestOk={this.handleSave}
        onRequestClose={this.closeReportForm}
        title={'Create Report'}
        description="Please provide the detail about this report"
      >
        <ScheduleReportForm
          oneTimeReport={oneTimeReport}
          defaultMethods={defaultMethods}
          formData={this.state.formData}
          handleChange={this.handleChange}
          onSubmitForm={this.handleAdd}
          onRef={(ref: any): any => (this.childForm = ref)}
          generateReport={generateData}
          scheduleReport={scheduleData}
        />
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(ReportFormActions)
