import React from 'react'
import { A10Container, _ } from '@gui-libraries/framework'
import { A10Icon, A10Row, A10Col } from '@gui-libraries/widgets'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import A10Panel from 'src/components/ADC/A10Panel'
export interface IStatusMessageProps {
  message: string
  statusCLI?: string
}

export interface IStatusMessageState {}

class StatusMessage extends A10Container<
  IStatusMessageProps,
  IStatusMessageState
> {
  render() {
    return (
      <A10Panel
        title={<A10IconTextGroup text="Status" />}
        isFormContent={false}
      >
        <div className="workflow-pullup-detail">
          <A10Row className="detail-row">
            <A10Col span={3}>
              <span className="infor-label">Status Message</span>
            </A10Col>
            <A10Col span={21}>
              <p className="infor-text message">
                {this.props.message || 'No Data'}
              </p>
            </A10Col>
          </A10Row>
          {this.props.statusCLI ? (
            <A10Row className="detail-row">
              <A10Col span={3}>
                <span className="infor-label">Status CLI</span>
              </A10Col>
              <A10Col span={21}>
                <p className="infor-text cli">
                  {this.props.statusCLI || 'No Data'}
                </p>
              </A10Col>
            </A10Row>
          ) : null}
        </div>
      </A10Panel>
    )
  }
}

export default StatusMessage
