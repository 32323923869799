import React from 'react'

import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { LicenseImportForm } from './index'
import { Utilities, DashboardService } from 'src/services'

export interface ISlidingLicenseImportProps extends IA10ContainerDefaultProps {
  isOpen: boolean
  llmUUID: string
  onRequestClose: () => void
  updateList: () => void
}

export interface ISlidingLicenseImportStates {
  llmUUID: any
  exportLicString: string
  disableSave: boolean
}

class SlidingLicenseImport extends A10Container<
  ISlidingLicenseImportProps,
  ISlidingLicenseImportStates
> {
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  childForm: any = null

  constructor(props: ISlidingLicenseImportProps) {
    super(props)
    this.state = {
      llmUUID: this.props.llmUUID,
      exportLicString: '',
      disableSave: true,
    }
  }

  componentWillReceiveProps(nextProps: IObject) {
    const state = { ...this.state }
    const { llmUUID } = nextProps
    state.llmUUID = llmUUID
    if (this.props.isOpen !== nextProps.isOpen && nextProps.isOpen) {
      state.exportLicString = ''
      state.disableSave = true
    }
    this.setState(state)
  }

  onLMFormChange = (exportLicString: string) => {
    const state = { ...this.state }
    if (exportLicString === '') {
      state.disableSave = true
    } else {
      state.disableSave = false
    }
    state.exportLicString = exportLicString

    this.setState(state)
  }

  onClose = () => {
    this.props.updateList()
    this.props.onRequestClose()
  }

  importLicense = () => {
    const { exportLicString } = this.state

    const importLicense = this.DashboardService.importLLMLicense(
      null,
      exportLicString,
      null,
    )
    importLicense
      .then((resp: any) => {
        if (resp) {
          this.Utilities.showMessage(
            'License import request submitted successfully, please check the status in activity log for the license',
            1,
            0,
          )
          this.onClose()
        }
      })
      .catch((err: any) => {
        const msg =
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : ''
        this.Utilities.showMessage('Error while import of license', 0, 0, msg)
        this.onClose()
      })
  }

  onRequestOk = () => {
    const { exportLicString } = this.state
    if (exportLicString === '') {
      this.Utilities.showMessage(
        'Please enter the exported license string',
        0,
        0,
      )
      return
    }
    this.importLicense()
  }

  render() {
    const { isOpen, onRequestClose, llmUUID } = this.props
    const { disableSave, exportLicString } = this.state
    return (
      <FormatSlidingPage
        isOpen={isOpen}
        onRequestOk={this.onRequestOk}
        onRequestClose={onRequestClose}
        title="Import License"
        disableSave={disableSave}
        saveText="Import"
      >
        <LicenseImportForm
          onChange={this.onLMFormChange}
          llmUUID={llmUUID}
          exportLicString={exportLicString}
          onRef={(ref: any): any => (this.childForm = ref)}
        />
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(SlidingLicenseImport)
