import React from 'react'
import {
  useDashboardProviderContext,
  getCountryByISOCountryCode,
  SeriesDataFormatter,
} from '@gui-libraries/dgf'
import { Viz } from '@gui-libraries/viz'
import { A10Alert } from '@gui-libraries/widgets'

import Utilization from '../Utilization'
import DeviceCPUs from '../DeviceCPUs'
import DeviceHealth from '../DeviceHealth'
import DeviceEnvHealth from '../DeviceEnvHealth'
import TrafficLocation from '../TrafficLocation'
import styles from './styles/index.module.less'
import AppsList from '../AppsList'

import DoubleMetrics from 'src/components/shared/DoubleMetrics'
import Help from 'src/components/shared/Help'
import PARComponent from '../PARComponent'
import DashboardMetrics from '../DashboardMetrics'
import DashboardAlerts from '../DashboardAlerts'
import TopIndicators from '../TopIndicators'

const seriesDataFormatter = new SeriesDataFormatter()
const { DestinationCountries, PieChart } = Viz

export type ICustomSlicesParams = Parameters<
  GuiDGF.Components.DGF.RenderCustomSlices
>[0]

type IProps = ICustomSlicesParams & {}

const CustomSlices: React.FC<IProps> = props => {
  const {
    service,
    startTime,
    endTime,
    clusters,
    devices,
    selectedContext,
    parameters,
    tenants,
    licenses,
  } = useDashboardProviderContext()
  const {
    viz,
    overlayData,
    yAxisType,
    onClick,
    onClickChartTooltip,
    onZoom,
  } = props
  const id = viz.displayProperties.id

  switch (id) {
    case 'logical_partition_count': {
      return (
        <TopIndicators
          key={'logical_partition_count'}
          viz={viz as GuiDGF.TopIndicatorViz}
          service={service}
          parameters={parameters}
          selectedContext={selectedContext}
        />
      )
    }
    case 'active_app_svcs': {
      return (
        <TopIndicators
          key={'active_app_svcs'}
          viz={viz as GuiDGF.TopIndicatorViz}
          service={service}
          parameters={parameters}
          selectedContext={selectedContext}
        />
      )
    }
    case 'appsList': {
      return (
        <AppsList
          height={viz.displayProperties?.height || 215}
          service={service}
          startTime={startTime}
          endTime={endTime}
          parameters={parameters}
        />
      )
    }
    case 'utilization': {
      return (
        <Utilization
          height={viz.displayProperties?.height || 215}
          service={service}
          startTime={startTime}
          endTime={endTime}
          licenses={licenses}
        />
      )
    }
    case 'device_location': {
      const series = {
        name: 'Location',
        data: [],
      }
      const data = devices.reduce((acc, device) => {
        const region = device.region || 'Unknown'
        const { code, name } = getCountryByISOCountryCode(region)

        if (!acc[code]) {
          acc[code] = {
            code,
            name,
            value: 1,
          }
        } else {
          acc[code].value++
        }
        return acc
      }, {})

      series.data = Object.values(data)

      return (
        <DestinationCountries
          name=""
          data={{ series: [series] }}
          unit=""
          height={viz.displayProperties?.height || 300}
          barTitleWidth={viz.displayProperties?.legendWidth}
        />
      )
    }
    case 'traffic_location': {
      return (
        <TrafficLocation
          viz={viz}
          service={service}
          startTime={startTime}
          endTime={endTime}
          parameters={parameters}
          clusters={clusters}
          devices={devices}
        />
      )
    }
    case 'cluster_types': {
      const series = {
        name: 'Types Of Clusters',
        data: [],
      }
      const data = clusters.reduce((acc, cluster, i) => {
        const type = cluster?.type?.toUpperCase()

        if (type) {
          if (!acc[type]) {
            acc[type] = {
              name: type,
              y: 1,
              color: seriesDataFormatter.getSeriesColor({ name: type }, i),
            }
          } else {
            acc[type].y++
          }
        }
        return acc
      }, {})

      series.data = Object.values(data)

      return (
        <PieChart
          name={''}
          vizType="donut"
          data={{ series: [series] }}
          description={viz.displayProperties?.description}
          unit={viz.displayProperties?.units || ''}
          legend={true}
          height={viz.displayProperties?.height || 215}
          legendWidth={viz.displayProperties?.legendWidth}
          fontSize={12}
          isBytes={viz.displayProperties?.isBytes}
          chartSize="110%"
        />
      )
    }
    case 'cluster_device_locations': {
      const series = {
        name: 'Location',
        data: [],
      }
      const data = devices.reduce((acc, device, i) => {
        const region = device.region || 'Unknown'
        const { name } = getCountryByISOCountryCode(region)

        if (!acc[name]) {
          acc[name] = {
            name: name,
            y: 1,
            color: seriesDataFormatter.getSeriesColor({ name }, i),
          }
        } else {
          acc[name].y++
        }
        return acc
      }, {})

      series.data = Object.values(data)

      return (
        <PieChart
          name={''}
          vizType="pie"
          data={{ series: [series] }}
          description={viz.displayProperties?.description}
          unit={viz.displayProperties?.units || ''}
          legend={true}
          height={viz.displayProperties?.height || 215}
          legendWidth={viz.displayProperties?.legendWidth}
          fontSize={12}
          isBytes={viz.displayProperties?.isBytes}
          chartSize="110%"
        />
      )
    }
    case 'acos_version': {
      const series = {
        name: 'ACOS VERSIONS',
        data: [],
      }
      const data = devices.reduce((acc, device, i) => {
        const version = device['primary-sw-version']
          ? device['primary-sw-version'].split(' ')[0].toUpperCase()
          : 'N/A'

        if (!acc[version]) {
          acc[version] = {
            name: version,
            y: 1,
            color: seriesDataFormatter.getSeriesColor({ name: version }, i),
          }
        } else {
          acc[version].y++
        }

        return acc
      }, {})

      series.data = Object.values(data)

      return (
        <PieChart
          name={''}
          vizType="pie"
          data={{ series: [series] }}
          description={viz.displayProperties?.description}
          unit={viz.displayProperties?.units || ''}
          legend={true}
          height={viz.displayProperties?.height || 215}
          legendWidth={viz.displayProperties?.legendWidth}
          fontSize={12}
          isBytes={viz.displayProperties?.isBytes}
          chartSize="110%"
        />
      )
    }
    case 'virtualization_type': {
      const series = {
        name: 'Virtualization Type',
        data: [],
      }
      const data = clusters.reduce((acc, cluster, i) => {
        const type = cluster['virtualization-type']?.toUpperCase() || 'Unknown'

        if (!acc[type]) {
          acc[type] = {
            name: type,
            y: 1,
            color: seriesDataFormatter.getSeriesColor({ name: type }, i),
          }
        } else {
          acc[type].y++
        }

        return acc
      }, {})

      series.data = Object.values(data)

      return (
        <PieChart
          name={''}
          vizType="donut"
          data={{ series: [series] }}
          description={viz.displayProperties?.description}
          unit={viz.displayProperties?.units || ''}
          legend={true}
          height={viz.displayProperties?.height || 215}
          legendWidth={viz.displayProperties?.legendWidth}
          fontSize={12}
          isBytes={viz.displayProperties?.isBytes}
          chartSize="110%"
        />
      )
    }
    case 'device_cpus': {
      return (
        <DeviceCPUs
          service={service}
          startTime={startTime}
          endTime={endTime}
          device={selectedContext}
          parameters={parameters}
          height={viz.displayProperties?.height || 215}
        />
      )
    }
    case 'device_fan_speed':
    case 'device_temp': {
      let indicator = null

      switch (id) {
        case 'device_fan_speed': {
          indicator = 'fan'
          break
        }
        case 'device_temp': {
          indicator = 'temperature'
          break
        }
      }

      if (indicator) {
        return (
          <DeviceHealth
            viz={viz}
            onZoom={onZoom}
            onClick={onClick}
            service={service}
            parameters={parameters}
            startTime={startTime}
            endTime={endTime}
            device={selectedContext}
            cluster={clusters[0]}
            indicator={indicator}
          />
        )
      }
      return null
    }
    case 'device_environment_health': {
      return (
        <DeviceEnvHealth
          service={service}
          parameters={parameters}
          startTime={startTime}
          endTime={endTime}
          device={selectedContext}
          cluster={clusters[0]}
        />
      )
    }
    case 'not_physical': {
      return (
        <A10Alert
          style={{ marginTop: '10px' }}
          message="Data Not Available. Device Environment data is published only by
      physical appliances at this time."
          type="info"
          showIcon
        />
      )
    }
    case 'no_device_cluster': {
      return (
        <div className={styles.noClusterDevice}>
          There are no Infrastructure components to show analytics for, Please
          add clusters, devices & tenants before any data can be shown.
        </div>
      )
    }
    case 'homeHelp': {
      return <Help />
    }
    case 'cluster_partition_devices': {
      const metrics = [
        {
          title: 'Devices',
          firstSubTitle: 'Total',
          metric1: devices?.length || 0,
          secondSubTitle: 'Registered',
          metric2:
            devices?.filter(
              device =>
                device['license-state'] === 'LICENSED' &&
                device['registration-state'] === 'REGISTERED',
            ).length || 0,
          tooltip: 'Displays cluster devices',
        }
      ]

      return (
        <DoubleMetrics
          metrics={metrics}
          tooltipPosition="right"
          height={viz.displayProperties?.height || 215}
        />
      )
    }
    case 'cluster_par': {
      return (
        <PARComponent
          type="cluster_par"
          height={viz.displayProperties?.height || 215}
          selectedContext={selectedContext}
          parameters={parameters}
          startTime={startTime}
          endTime={endTime}
          service={service}
        />
      )
    }
    case 'dashboard_infrastructure': {
      return (
        <DashboardMetrics
          type="infrastructure"
          height={viz.displayProperties?.height || 215}
          clusters={clusters}
          devices={devices}
        />
      )
    }
    case 'dashboard_organization': {
      return (
        <DashboardMetrics
          type="organization"
          height={viz.displayProperties?.height || 215}
          tenants={tenants}
          service={service}
        />
      )
    }
    case 'dashboard_licenses': {
      return (
        <DashboardMetrics
          type="licenses"
          height={viz.displayProperties?.height || 215}
          data={[]}
          licenses={licenses}
        />
      )
    }

    case 'dashboard_alerts': {
      return (
        <DashboardAlerts
          height={viz.displayProperties?.height || 215}
          data={[]}
          service={service}
          OnClick={onClick}
          endTime={endTime}
          parameters={parameters}
        />
      )
    }
    default: {
      return null
    }
  }
}

export default CustomSlices
