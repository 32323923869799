import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { _ } from '@gui-libraries/framework'
import { getCities, A10Icon } from '@gui-libraries/widgets'
import { Utilities } from 'src/services'
import styles from './styles/index.module.less'

export interface ICitySelectProps {
  region: string
  initialValue?: string
  onChange: any
  disabled?: boolean
}

export interface ICitySelectState {
  cities: string[]
  filteredCities: string[]
  displayCities: string[]
  selectedCity: string
  visible: boolean
  inputValue: string
  page: number
}

const COUNT = 20

class CitySelect extends React.Component<ICitySelectProps, ICitySelectState> {
  ref = React.createRef()
  inputRef = React.createRef()
  Utilities = new Utilities()

  constructor(props: ICitySelectProps) {
    super(props)

    this.state = {
      cities: [] as string[],
      filteredCities: [] as string[],
      displayCities: [] as string[],
      selectedCity: '',
      inputValue: '',
      visible: false,
      page: 1,
    }
  }

  componentDidMount() {
    this.getCities(this.props.region, this.props.initialValue)

    document.addEventListener('mousedown', this.onClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside)
  }

  componentDidUpdate(prevProps: ICitySelectProps, prevState: ICitySelectState) {
    if (prevProps.region !== this.props.region) {
      this.getCities(this.props.region)
    }
  }

  getCities = async (region: string, selectedCity: string = '') => {
    try {
      const country = JSON.parse(region)
      const cities = await getCities(country.name)
      // TODO: temp fix for HC-5775, will be handled in object form in HC-5509
      const cityNames = _.map(cities, 'name')
      if (cityNames[0] !== '') {
        cityNames.unshift('')
      }
      this.setState({
        cities: cityNames,
        filteredCities: cityNames,
        displayCities: [],
        selectedCity: selectedCity,
        inputValue: '',
        page: 1,
      })
    } catch (err) {
      this.setState({
        cities: [],
        filteredCities: [],
        displayCities: [],
        selectedCity: '',
        inputValue: '',
        page: 1,
      })
    }
  }

  openDropdown = () => {
    this.setState(
      {
        visible: true,
        inputValue: '',
        filteredCities: this.state.cities,
      },
      () => this.inputRef.current?.focus(),
    )
  }

  onClickOption = (city: string) => {
    const { onChange } = this.props
    const modifiedCity = this.Utilities.replaceAccentedCharacters(city)
    this.setState({
      selectedCity: modifiedCity,
      inputValue: '',
      visible: false,
    })
    onChange && onChange(modifiedCity)
  }

  onClickOutside = (e: React.MouseEvent) => {
    const { cities, filteredCities, selectedCity, visible } = this.state
    if (visible && this.ref.current && !this.ref.current.contains(e.target)) {
      this.setState({
        visible: false,
        inputValue: '',
        selectedCity:
          this.inputRef.current?.value && filteredCities.length
            ? filteredCities[0]
            : selectedCity,
        filteredCities: cities,
        displayCities: [],
        page: 1,
      })
    }
  }

  onChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement

    this.setState({
      inputValue: target.value,
      filteredCities: this.state.cities.filter(city =>
        target.value ? new RegExp(target.value, 'i').test(city) : true,
      ),
      displayCities: [],
      page: 1,
    })
  }

  loadMore = () => {
    const { filteredCities, displayCities, page } = this.state
    const totalPages = Math.ceil(filteredCities.length / COUNT)

    if (page <= totalPages) {
      const begin = COUNT * (page - 1)
      const end = begin + COUNT

      this.setState({
        displayCities: [...displayCities, ...filteredCities.slice(begin, end)],
        page: page + 1,
      })
    }
  }

  render() {
    const { disabled, region } = this.props
    const { visible, selectedCity, inputValue, displayCities } = this.state
    const _disabled =
      disabled || !region || (region && !JSON.parse(region).name)

    return (
      <div
        ref={this.ref}
        className={`${styles.container} ${_disabled ? styles.disabled : ''} ${
          visible ? styles.visible : ''
        }`}
      >
        <input
          ref={this.inputRef}
          readOnly={!visible}
          value={visible ? inputValue : selectedCity}
          placeholder={(visible && selectedCity) || 'Please select'}
          onClick={this.openDropdown}
          onChange={this.onChangeInput}
        />
        <A10Icon app="global" type="dropdown" />
        {visible && (
          <div className={`${styles.dropdownContainer}`}>
            <ul>
              <InfiniteScroll
                loadMore={this.loadMore}
                hasMore={true}
                useWindow={false}
              >
                {displayCities.map((city: string) => {
                  return (
                    <li
                      className={`${styles.dropdownOption} ${
                        this.Utilities.replaceAccentedCharacters(city) ===
                        selectedCity
                          ? styles.selectedOption
                          : ''
                      }`}
                      key={city}
                      onClick={() => this.onClickOption(city)}
                    >
                      {city}
                    </li>
                  )
                })}
              </InfiniteScroll>
            </ul>
          </div>
        )}
      </div>
    )
  }
}

export default CitySelect
