import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import { A10Row, A10Tabs } from '@gui-libraries/widgets'

import { ContentSection } from 'src/components/shared/ContentSection'
import DeviceBackups from './DeviceBackups'
import ScheduledBackups from './ScheduledBackups'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

const { TabPane } = A10Tabs

export interface IBackupProps extends IA10ContainerDefaultProps {}

export interface IBackupState {
  tabValue: string
}

class Backup extends A10Container<IBackupProps, IBackupState> {
  constructor(props: IBackupProps) {
    super(props)
    this.state = {
      tabValue: 'scheduledBackups',
    }
  }
  dataLoaded = false

  tabChange = (event: any) => {
    let { tabValue } = this.state
    if (event.target) {
      tabValue = event.target.value
    } else {
      tabValue = event
    }

    this.setState({ tabValue })
  }

  render() {
    const { tabValue } = this.state

    return (
      <>
        <ContentSection>
          <A10Row className={styles.radioGroupContainer}>
            <A10Tabs
              onChange={this.tabChange}
              type="card"
              className="backup-tab"
            >
              <TabPane key={'scheduledBackups'} tab={'Scheduled Backups'} />
              <TabPane key={'deviceBackups'} tab={'Device Backups'} />
            </A10Tabs>
          </A10Row>
          {tabValue === 'scheduledBackups' ? (
            <ScheduledBackups />
          ) : (
            <DeviceBackups />
          )}
        </ContentSection>
      </>
    )
  }
}

export default setupA10Container(Backup)
