import React from 'react'
import ReactLoading from 'react-loading'
import { _, A10Component, A10Context } from '@gui-libraries/framework'
import {
  A10Icon,
  A10Form,
  A10Input,
  A10Radio,
  A10Select,
  A10Switch,
  A10Alert,
  A10DatePicker,
  A10Row,
} from '@gui-libraries/widgets'
import { ActionButton } from 'src/components/shared/ActionButton'

import { Messages } from 'src/locale/en/Messages'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'

import {
  validateHosts,
  validatePort,
  validateRetries,
  validateTimeout,
  validateSharedSecret,
  validateAdminDN,
  validateAdminPwd,
  checkPatternList,
  checkAttributeList,
  validateDate,
} from './authConfigurationValidate'
import { AuthenticationService, OrganizationService } from 'src/services'
import moment from 'moment'
import storage from 'src/libraries/storage'

export interface IAuthConfigProps {
  form: any
  visible: boolean
  isLoading: boolean
  authProvider: any
  handleChange?: (data: any) => void
  handleSubmit?: () => void
  onValidate?: () => void
  onClose?: () => void
  onRef?: (ref: any) => void
}

export interface IAuthConfigState {
  pageTitle: string
  dataCache: IObject
  initialRemoteAuthorization: boolean
  allUsers: IObject[]
  mfaEnabled: boolean
  mfaEnforceData: string
  showWarningForDisableMFA: boolean
  initialMFA: boolean
  groupMembershipType: string
  isSSO: boolean
}

class AuthConfigurationEditForm extends A10Component<
  IAuthConfigProps,
  IAuthConfigState
> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  AuthenticationService = new AuthenticationService()
  OrganizationService = new OrganizationService()
  Messages = new Messages()
  formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 13 },
  }
  adminLevel
  dateFormat: string = 'MM/DD/YYYY'

  constructor(
    props: IAuthConfigProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: {
        get: { ADMIN_LEVEL },
      },
    } = context

    this.adminLevel = ADMIN_LEVEL
    this.state = {
      pageTitle: 'Edit Authentication & Authorization',
      dataCache: {},
      allUsers: [],
      initialRemoteAuthorization: false,
      initialMFA: false,
      mfaEnabled: false,
      showWarningForDisableMFA: null,
      mfaEnforceData: '',
      groupMembershipType: '',
      isSSO: true
    }

    this.initialize(props)
  }

  componentDidMount() {
    const { onRef } = this.props
    if (_.isFunction(onRef)) {
      onRef(this)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: IAuthConfigProps) {
    const { visible: nextVisible } = nextProps
    const { visible } = this.props
    if (nextVisible === true && nextVisible !== visible) {
      this.getUserEntitlement()
    }
  }

  componentWillUnmount() {
    const { onRef } = this.props
    if (_.isFunction(onRef)) {
      onRef(undefined)
    }
  }

  shouldComponentUpdate(nextProps: IAuthConfigProps) {
    const { visible: nextVisible, authProvider: nextAuthProvider } = nextProps
    const { visible, authProvider } = this.props

    const shouldInitialize =
      authProvider !== nextAuthProvider || (!visible && nextVisible)
    if (shouldInitialize) {
      this.initialize(nextProps)
      return false
    }

    return true
  }

  initialize = (props: IAuthConfigProps) => {
    const { authProvider, handleChange } = props
    const initialData = this.parseAuthProvider(authProvider)
    this.setState({
      dataCache: initialData,
      initialRemoteAuthorization: initialData['remote-authorization'] || false,
      initialMFA: initialData['mfaEnabled'] || false,
    })

    if (_.isFunction(handleChange)) {
      const newPayload = this.generatePayload(initialData)
      handleChange(newPayload)
    }
  }

  parseAuthProvider(authProvider: IObject) {
    if (!_.isNil(authProvider)) {
      const { type } = authProvider

      if (type === 'ldap') {
        const {
          inheritable,
          urls,
          scheme,
          userdnpattern,
          searchdn,
          'login-attribute': loginAttr,
          admindn,
        } = authProvider
        const ldapHosts = _.isArray(urls) ? urls.join(',') : ''

        return {
          type,
          inheritable,
          ldapHosts,
          scheme: scheme || 'userDN',
          userdnpattern:
            _.isArray(userdnpattern) && userdnpattern.length
              ? _.cloneDeep(userdnpattern)
              : [''],
          searchdn:
            _.isArray(searchdn) && searchdn.length
              ? _.cloneDeep(searchdn)
              : [''],
          loginAttr:
            _.isArray(loginAttr) && loginAttr.length
              ? _.cloneDeep(loginAttr)
              : [''],
          admindn,
          adminpwd: '',
          'remote-authorization': authProvider['remote-authorization'] || false,
          'group-membership-of-user': authProvider?.['group-membership-of-user'] || 'user-entry',
          'user-first-name-attribute': authProvider?.['user-first-name-attribute'],
          'user-last-name-attribute': authProvider?.['user-last-name-attribute'],
          'group-objectclass': authProvider?.['group-objectclass'],
          'group-base-dn': authProvider?.['group-base-dn'],
          'group-membership-attribute': authProvider?.['group-membership-attribute'],
          'group-member-attribute': authProvider?.['group-member-attribute'],
          'group-id-attribute': authProvider?.['group-id-attribute'],
          'user-attribute-in-group-attribute': authProvider?.['user-attribute-in-group-attribute'],
        }
      } 
      else if (type === 'radius') {
        const {
          inheritable,
          'authentication-method': authMethod,
          'end-point-hosts': hosts,
          retries,
          'shared-secret': sharedSecret,
        } = authProvider

        let radiusHosts = ''
        let radiusPort = ''
        if (_.isArray(hosts) && hosts.length) {
          hosts.forEach((item: any) => {
            if (_.isObject(item)) {
              const { host, port } = item as any
              if (_.isString(host) && host) {
                radiusHosts += (radiusHosts ? ',' : '') + host
              }
              if (_.isFinite(port) || _.isString(port)) {
                if (!radiusPort) {
                  radiusPort = `${port}`
                }
              }
            }
          })
        }

        return {
          type,
          inheritable,
          authMethod,
          radiusHosts,
          radiusPort,
          retries: _.isFinite(retries) ? `${retries}` : '',
          sharedSecret: '',
          'remote-authorization': authProvider['remote-authorization'] || false,
        }
      } else if (type === 'tacacsplus') {
        const {
          inheritable,
          'authentication-method': authMethod,
          'end-point-hosts': hosts,
          // 'remote-address': remoteAddress,
          retries,
          timeout,
          'shared-secret': sharedSecret,
        } = authProvider

        let tacacsHosts = ''
        let tacacsPort = ''
        if (_.isArray(hosts) && hosts.length) {
          hosts.forEach((item: any) => {
            if (_.isObject(item)) {
              const { host, port } = item as any
              if (_.isString(host) && host) {
                tacacsHosts += (tacacsHosts ? ',' : '') + host
              }
              if (_.isNumber(port) || _.isString(port)) {
                if (!tacacsPort) {
                  tacacsPort = `${port}`
                }
              }
            }
          })
        }

        return {
          type,
          inheritable,
          authMethod,
          tacacsHosts,
          tacacsPort,
          // remoteAddress,
          retries: _.isFinite(retries) ? `${retries}` : '',
          timeout: _.isFinite(timeout) ? `${timeout}` : '',
          sharedSecret: '',
          'remote-authorization': authProvider['remote-authorization'] || false,
        }
      } else if (type === 'default' || type === 'local') {
        const { inheritable } = authProvider
        return {
          type,
          inheritable,
          mfaEnabled: authProvider['mfa-enabled'] || false,
          mfaEnforceData: authProvider['enforcement-date'],
        }
      }
    }

    return {
      type: 'local',
      inheritable: 'optional',
    }
  }

  generatePayload(data: IObject) {
    if (!_.isNil(data)) {
      const { type } = data

      if (type === 'ldap') {
        const {
          inheritable,
          ldapHosts,
          scheme,
          userdnpattern,
          searchdn,
          loginAttr,
          admindn,
          adminpwd,
          ['remote-authorization']: remoteAuth,
        } = data

        const urls: string[] = []
        if (_.isString(ldapHosts) && ldapHosts.trim()) {
          ldapHosts.split(',').forEach(item => {
            const host = item.trim()
            if (host) {
              urls.push(host)
            }
          })
        }

        if (scheme === 'userDN') {
          if (remoteAuth) {
            return {
              type,
              inheritable: inheritable || 'optional',
              urls,
              scheme,
              userdnpattern,
              'remote-authorization': data?.['remote-authorization'] || false,
              'user-first-name-attribute': data?.['user-first-name-attribute'],
              'user-last-name-attribute': data?.['user-last-name-attribute'],
              'group-objectclass': data?.['group-objectclass'],
              'group-base-dn': data?.['group-base-dn'],
              'group-membership-of-user': data?.['group-membership-of-user'],
              'group-membership-attribute': data?.['group-membership-attribute'],
              'group-member-attribute': data?.['group-member-attribute'],
              'group-id-attribute': data?.['group-id-attribute'],
              'user-attribute-in-group-attribute': data?.['user-attribute-in-group-attribute'],
            }
          }
          return {
            type,
            inheritable: inheritable || 'optional',
            urls,
            scheme,
            userdnpattern,
            'remote-authorization': data?.['remote-authorization'] || false,
            'user-first-name-attribute': data?.['user-first-name-attribute'],
            'user-last-name-attribute': data?.['user-last-name-attribute'],
          }
        } else if (scheme === 'searchDN') {
          if (remoteAuth) {
            return {
              type,
              inheritable: inheritable || 'optional',
              urls,
              scheme,
              searchdn,
              'login-attribute': loginAttr,
              admindn,
              adminpwd: new Buffer(adminpwd).toString('base64'),
              'remote-authorization': data?.['remote-authorization'] || false,
              'user-first-name-attribute': data?.['user-first-name-attribute'],
              'user-last-name-attribute': data?.['user-last-name-attribute'],
              'group-objectclass': data?.['group-objectclass'],
              'group-base-dn': data?.['group-base-dn'],
              'group-membership-of-user': data?.['group-membership-of-user'],
              'group-membership-attribute': data?.['group-membership-attribute'],
              'group-member-attribute': data?.['group-member-attribute'],
              'group-id-attribute': data?.['group-id-attribute'],
              'user-attribute-in-group-attribute': data?.['user-attribute-in-group-attribute'],
            }
          }
          return {
            type,
            inheritable: inheritable || 'optional',
            urls,
            scheme,
            searchdn,
            'login-attribute': loginAttr,
            admindn,
            'remote-authorization': data?.['remote-authorization'] || false,
            adminpwd: new Buffer(adminpwd).toString('base64'),
            'user-first-name-attribute': data?.['user-first-name-attribute'],
            'user-last-name-attribute': data?.['user-last-name-attribute'],
          }
        }
        return {
          type,
          userdnpattern,
          inheritable: inheritable || 'optional',
          urls,
          scheme: 'userDN',
          'remote-authorization': data?.['remote-authorization'] || false,
          'group-membership-of-user': data?.['group-membership-of-user'],
          'user-first-name-attribute': data?.['user-first-name-attribute'],
          'user-last-name-attribute': data?.['user-last-name-attribute'],
          'group-objectclass': data?.['group-objectclass'],
          'group-base-dn': data?.['group-base-dn'],
          'group-membership-attribute': data?.['group-membership-attribute'],
          'group-member-attribute': data?.['group-member-attribute'],
          'group-id-attribute': data?.['group-id-attribute'],
          'user-attribute-in-group-attribute': data?.['user-attribute-in-group-attribute'],
        }
      } else if (type === 'radius') {
        const {
          inheritable,
          authMethod,
          radiusHosts,
          radiusPort,
          retries,
          sharedSecret,
        } = data

        const hosts: any[] = []
        if (_.isString(radiusHosts) && radiusHosts.trim()) {
          radiusHosts.split(',').forEach(item => {
            const host = item.trim()
            if (host) {
              hosts.push({
                host,
                port: parseInt(radiusPort, 10),
              })
            }
          })
        }

        return {
          type,
          inheritable: inheritable || 'optional',
          'authentication-method': authMethod || 'PAP',
          'end-point-hosts': hosts,
          retries: parseInt(retries, 10) || 0,
          'shared-secret': sharedSecret,
          'remote-authorization': data['remote-authorization'] || false,
        }
      } else if (type === 'tacacsplus') {
        const {
          inheritable,
          authMethod,
          tacacsHosts,
          tacacsPort,
          // remoteAddress,
          retries,
          timeout,
          sharedSecret,
        } = data

        const hosts: any[] = []
        if (_.isString(tacacsHosts) && tacacsHosts.trim()) {
          tacacsHosts.split(',').forEach(item => {
            const host = item.trim()
            if (host) {
              hosts.push({
                host,
                port: parseInt(tacacsPort, 10),
              })
            }
          })
        }

        return {
          type,
          inheritable: inheritable || 'optional',
          'authentication-method': authMethod || 'PAP',
          'end-point-hosts': hosts,
          // 'remote-address': remoteAddress,
          retries: parseInt(retries, 10) || 0,
          timeout: parseInt(timeout, 10) || 0,
          'shared-secret': sharedSecret,
          'remote-authorization': data['remote-authorization'] || false,
        }
      } else if (type === 'default' || type === 'local') {
        const { inheritable } = data
        return {
          type: 'local',
          inheritable: inheritable || 'optional',
          'mfa-enabled': data.mfaEnabled || false,
          'enforcement-date': data.mfaEnabled ? data.mfaEnforceData : '',
        }
      }
    }

    return {
      type: 'local',
      inheritable: 'optional',
    }
  }

  checkState() {
    const { dataCache } = this.state

    const authType = dataCache.type
    if (authType === 'ldap') {
      const {
        ldapHosts,
        scheme,
        userdnpattern,
        searchdn,
        loginAttr,
        admindn,
        adminpwd,
      } = dataCache

      // validate hosts
      if (!validateHosts(authType, ldapHosts, true)) {
        return false
      }

      if (scheme === 'userDN') {
        // validate user DN patterns
        if (!checkPatternList('User DN', userdnpattern, true)) {
          return false
        }
      } else if (scheme === 'searchDN') {
        // validate search DN patterns and login attributes
        if (
          !checkPatternList('Search DN', searchdn, true) ||
          !checkAttributeList('Login Attribute', loginAttr, true)
        ) {
          return false
        }

        // validate adminDN
        if (!validateAdminDN(authType, admindn, true)) {
          return false
        }

        //validate adminPassword
        if (!validateAdminPwd(authType, adminpwd, true)) {
          return false
        }
      }
    } else if (authType === 'radius') {
      const { radiusHosts, radiusPort, retries, sharedSecret } = dataCache

      // validate hosts
      if (!validateHosts(authType, radiusHosts, true)) {
        return false
      }

      // validate port
      if (!validatePort(authType, radiusPort, true)) {
        return false
      }

      // validate shared secret
      if (!validateSharedSecret(authType, sharedSecret, true)) {
        return false
      }

      // validate retries
      if (!validateRetries(authType, retries, true)) {
        return false
      }
    } else if (authType === 'tacacsplus') {
      const {
        tacacsHosts,
        tacacsPort,
        // remoteAddress,
        retries,
        timeout,
        sharedSecret,
      } = dataCache

      // validate hosts
      if (!validateHosts(authType, tacacsHosts, true)) {
        return false
      }

      // validate port
      if (!validatePort(authType, tacacsPort, true)) {
        return false
      }

      // validate shared secret
      if (!validateSharedSecret(authType, sharedSecret, true)) {
        return false
      }

      // validate retries
      if (!validateRetries(authType, retries, true)) {
        return false
      }

      // validate timeout
      if (!validateTimeout(authType, timeout, true)) {
        return false
      }

      // validate remote address
      // if (!validateRemote(authType, remoteAddress, true)) {
      //   return false
      // }
    }

    return true
  }

  getUserEntitlement = () => {
    this.OrganizationService.getUserEntitlements(null, null, [
      storage.get.PROVIDER,
    ])
      .then((resp: IObject) => {
        if (resp?.status === 200 && resp?.data?.user) {
          const sso = resp?.data?.user?.SSO
          this.setState({ isSSO: sso })
        }
      })
      .catch((err: any) => {})
  }

  renderAuthTypes = (): any => {
    const types: IObject = {
      local: 'Local Authentication',
      ldap: 'LDAP',
      radius: 'RADIUS',
      tacacsplus: 'TACACS Plus',
    }

    const options = []
    for (const type in types) {
      if (types.hasOwnProperty(type)) {
        const isDisable = type !== 'local' && this.state.isSSO === false
        options.push(
          <A10Select.Option
            key={'type-' + type}
            value={type}
            title={types[type]}
            disabled={isDisable}
          >
            {types[type]}
          </A10Select.Option>,
        )
      }
    }
    return options
  }

  renderDefaultAuthForm = () => {
    const { getFieldDecorator } = this.props.form
    const { type, mfaEnforceData } = this.state.dataCache
    return (
      <>
        <A10Form.Item
          {...this.formItemLayout}
          key="mfa-configuration"
          label={
            <span>
              Multi-factor Authentication (MFA)
              <span>
                <HelpInfo
                  customCls="custom-tooltip-input"
                  placement="left"
                  title="User MFA"
                  helpKey="HELP_ORG_AUTHENTICATION_MFA"
                />
              </span>
            </span>
          }
        >
          <A10Switch
            color="green"
            style={{ marginRight: '15px' }}
            checked={this.state.dataCache['mfaEnabled']}
            onChange={this.handleInputChange.bind(this, 'mfaEnabled')}
          />
          {this.state.dataCache['mfaEnabled'] ? 'Enabled' : 'Disabled'}
        </A10Form.Item>
        {this.state.dataCache['mfaEnabled'] && (
          <A10Form.Item
            {...this.formItemLayout}
            key="date-picker"
            label={
              <div className="enforcedDate">
                <span className="enforced Text mandatoryField">
                  Enforcement Date
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title="User MFA Enforcement Date"
                      helpKey="HELP_ORG_AUTHENTICATION_MFA_ENFORCE_DATE"
                    />
                  </span>
                </span>
              </div>
            }
          >
            {getFieldDecorator('date-picker', {
              rules: [
                {
                  validator: (rule: any, value: string, cb: any) => {
                    return validateDate(mfaEnforceData)
                  },
                  message: this.Messages.EMPTY_ENFORCEMENT_DATE,
                },
              ],
              initialValue: mfaEnforceData,
            })(
              <div className="datePicker">
                <A10DatePicker
                  defaultValue={
                    this.state.dataCache['mfaEnforceData'] &&
                    moment(
                      this.state.dataCache['mfaEnforceData'],
                      this.dateFormat,
                    )
                  }
                  format={this.dateFormat}
                  className="datepicker"
                  onChange={this.handleInputChange.bind(
                    this,
                    'mfaEnforceData',
                  )}
                  placeholder="Select enforcement date"
                  disabledDate={current => {
                    return current && current.valueOf() < Date.now()
                  }}
                />
              </div>,
            )}
          </A10Form.Item>
        )}
      </>
    )
  }

  renderLdapAuthForm = () => {
    const { getFieldDecorator } = this.props.form
    const { dataCache } = this.state
    const { type, ldapHosts, scheme, admindn, adminpwd} = dataCache

    const hostsField: JSX.Element = (
      <A10Form.Item
        {...this.formItemLayout}
        key="ldap-hosts"
        label={
          <span className="mandatoryField">
            Hosts
            <span>
              <HelpInfo
                customCls="custom-tooltip-input"
                placement="left"
                title={this.Messages.HOST}
                helpKey="HELP_ORG_AUTHENTICATION_LDAP_HOSTS"
              />
            </span>
          </span>
        }
      >
        <div>
          {getFieldDecorator('ldap-hosts', {
            rules: [
              {
                validator: (rule: any, value: string, cb: any) => {
                  return validateHosts(type, value, false)
                },
                message:
                  'All LDAP servers must follow this format "ldap://LdapServerName:LdapPort"(Separated by commas)',
              },
            ],
            initialValue: ldapHosts,
          })(
            <A10Input
              type="text"
              placeholder={this.Messages.HOST}
              onChange={this.handleInputChange.bind(this, 'ldapHosts')}
            />,
          )}
        </div>
      </A10Form.Item>
    )

    const schemeField: JSX.Element = (
      <A10Form.Item
        {...this.formItemLayout}
        key="ldap-scheme"
        label={
          <span>
            Authorization Scheme
            <span>
              <HelpInfo
                customCls="custom-tooltip-input"
                placement="left"
                title="Authorization Scheme"
                helpKey="HELP_ORG_AUTHENTICATION_LDAP_AUTH_SCHEME"
              />
            </span>
          </span>
        }
      >
        <A10Radio.Group
          name="scheme"
          className="radio-component"
          defaultValue={scheme}
          onChange={this.handleInputChange.bind(this, 'scheme')}
        >
          <A10Radio className="radio-label" value="userDN">
            Use User DN
          </A10Radio>
          <A10Radio className="radio-label" value="searchDN">
            Use Search DN
          </A10Radio>
        </A10Radio.Group>
      </A10Form.Item>
    )

    const userDnField: JSX.Element =
      scheme === 'userDN' ? (
        <A10Form.Item
          {...this.formItemLayout}
          key="ldap-user-dn"
          label={
            <span className="mandatoryField">
              DN Pattern
              <span>
                <HelpInfo
                  customCls="custom-tooltip-input"
                  placement="left"
                  title="User DN Pattern"
                  helpKey="HELP_ORG_AUTHENTICATION_LDAP_USER_DN"
                />
              </span>
            </span>
          }
        >
          {this.renderList('userdnpattern', this.Messages.USER_DN)}
        </A10Form.Item>
      ) : null

    const searchDnField: JSX.Element =
      scheme === 'searchDN' ? (
        <A10Form.Item
          {...this.formItemLayout}
          key="ldap-search-dn"
          label={
            <span className="mandatoryField">
              DN Pattern
              <span>
                <HelpInfo
                  customCls="custom-tooltip-input"
                  placement="left"
                  title="Search DN Pattern"
                  helpKey="HELP_ORG_AUTHENTICATION_LDAP_SEARCH_DN"
                />
              </span>
            </span>
          }
        >
          {this.renderList('searchdn', this.Messages.SEARCH_DN)}
        </A10Form.Item>
      ) : null

    const loginAttrField: JSX.Element =
      scheme === 'searchDN' ? (
        <A10Form.Item
          {...this.formItemLayout}
          key="ldap-login-attr"
          label={
            <span className="mandatoryField">
              {this.Messages.LOGIN_ATTR}
              <span>
                <HelpInfo
                  customCls="custom-tooltip-input"
                  placement="left"
                  title={this.Messages.LOGIN_ATTR}
                  helpKey="HELP_ORG_AUTHENTICATION_LDAP_LOGIN_ATTR"
                />
              </span>
            </span>
          }
        >
          {this.renderList('loginAttr', this.Messages.LOGIN_ATTR)}
        </A10Form.Item>
      ) : null

    const adminDnField: JSX.Element =
      scheme === 'searchDN' ? (
        <A10Form.Item
          {...this.formItemLayout}
          key="ldap-admin-dn"
          label={
            <span className="mandatoryField">
              {this.Messages.ADMIN_DN}
              <span>
                <HelpInfo
                  customCls="custom-tooltip-input"
                  placement="left"
                  title={this.Messages.ADMIN_DN}
                  helpKey="HELP_ORG_AUTHENTICATION_LDAP_ADMIN_DN"
                />
              </span>
            </span>
          }
        >
          {getFieldDecorator('ldap-admin-dn', {
            rules: [
              {
                validator: (rule: any, value: string, cb: any) => {
                  return validateAdminDN(type, value, false)
                },
                message:
                  'Admin DN pattern should consist of several equations "Key=Value"(Separated by commas)!',
              },
            ],
            initialValue: admindn,
          })(
            <A10Input
              type="text"
              className="input-component"
              placeholder={this.Messages.ADMIN_DN}
              onChange={this.handleInputChange.bind(this, 'admindn')}
            />,
          )}
        </A10Form.Item>
      ) : null

    const adminPwdField: JSX.Element =
      scheme === 'searchDN' ? (
        <A10Form.Item
          {...this.formItemLayout}
          key="ldap-admin-pwd"
          label={
            <span className="mandatoryField">
              {this.Messages.ADMIN_PASSWORD}
              <span>
                <HelpInfo
                  customCls="custom-tooltip-input"
                  placement="left"
                  title={this.Messages.ADMIN_PASSWORD}
                  helpKey="HELP_ORG_AUTHENTICATION_LDAP_ADMIN_PASSWORD"
                />
              </span>
            </span>
          }
        >
          {getFieldDecorator('ldap-admin-pwd', {
            rules: [
              {
                validator: (rule: any, value: string, cb: any) => {
                  return validateAdminPwd(type, value, false)
                },
                message: 'Please enter a valid Admin Password!',
              },
            ],
            initialValue: adminpwd,
          })(
            <A10Input
              type="password"
              className="input-component"
              placeholder={this.Messages.ADMIN_PASSWORD}
              onChange={this.handleInputChange.bind(this, 'adminpwd')}
            />,
          )}
        </A10Form.Item>
      ) : null

    const validateButton: JSX.Element = (
      <A10Form.Item {...this.formItemLayout} key="validate-btn" label=" ">
        <a
          style={{ color: '#4a90e2', fontSize: 17 }}
          onClick={this.onClickValidate}
        >
          Validate
        </a>
      </A10Form.Item>
    )

    const remoteAuthorization: JSX.Element = (
      <A10Form.Item
        {...this.formItemLayout}
        key="ldap-remote-authorization"
        label={
          <span>
            Remote Authorization
            <span>
              <HelpInfo
                customCls="custom-tooltip-input"
                placement="left"
                title="User Remote Authorization"
                helpKey="HELP_ORG_AUTHENTICATION_REMOTE_AUTHORIZATION"
              />
            </span>
          </span>
        }
      >
        <A10Switch
          color="green"
          style={{ marginRight: '10px' }}
          checked={this.state.dataCache['remote-authorization']}
          onChange={this.handleInputChange.bind(this, 'remote-authorization')}
        />
        {this.state.dataCache['remote-authorization'] ? 'Enabled' : 'Disabled'}
      </A10Form.Item>
    )

    return [
      hostsField,
      schemeField,
      userDnField,
      searchDnField,
      loginAttrField,
      adminDnField,
      adminPwdField,
      validateButton,
      remoteAuthorization,
    ]
  }

  renderList = (stateName: string, placeholder?: string) => {
    const { dataCache } = this.state
    const stateData = dataCache[stateName]
    if (_.isArray(stateData)) {
      const list = stateData.map((item: string, index: number) => {
        
        return (
          <A10Form.Item
            key={`${stateName}-${index}`}
            className="multiple-input-field"
          >
            <A10Input
            className={`input-component ${stateData.length > 1 ? 'col-sm-10' : 'col-sm-12'
              }`}
            type="text"
            placeholder={placeholder}
            value={item}
            onChange={(e: any) => this.handleInputChange(stateName, e, index)}
          />
            {stateData.length > 1 ? (
              <span
                className="col-sm-2 delete-btn"
                onClick={() => this.deleteOne(stateName, index)}
                style={{
                  display: 'inline-block',
                  color: 'red',
                  cursor: 'pointer',
                }}
              >
                <A10Icon type="delete" />
              </span>
            ) : null}
          </A10Form.Item>
        )
      })

      list.push(
        <div
          className="add-btn"
          onClick={() => this.addOne(stateName)}
          style={{
            fontSize: 16,
            marginTop: 10,
            color: '#4a90e2',
            cursor: 'pointer',
          }}
        >
          <A10Icon
            type="plus"
            style={{ verticalAlign: 'text-top', marginRight: 5 }}
          />
          Add Another
        </div>,
      )

      return list
    }

    return null
  }

  addOne = (stateName: string) => {
    const { dataCache } = this.state
    if (_.isArray(dataCache[stateName])) {
      dataCache[stateName].push('')
      this.setState({
        dataCache,
      })
    }
  }

  deleteOne = (stateName: string, index: number) => {
    const { dataCache } = this.state
    if (_.isArray(dataCache[stateName])) {
      dataCache[stateName].splice(index, 1)
      this.setState({
        dataCache,
      })
    }
  }

  renderRadiusAuthForm = () => {
    const { getFieldDecorator } = this.props.form
    const {
      type,
      radiusHosts,
      radiusPort,
      retries,
      sharedSecret,
    } = this.state.dataCache

    const hostsField: JSX.Element = (
      <A10Form.Item
        {...this.formItemLayout}
        key="radius-hosts"
        label={
          <span className="mandatoryField">
            Hosts
            <span>
              <HelpInfo
                customCls="custom-tooltip-input"
                placement="left"
                title={this.Messages.HOST}
                helpKey="HELP_ORG_AUTHENTICATION_RADIUS_HOST"
              />
            </span>
          </span>
        }
      >
        <div>
          {getFieldDecorator('radius-hosts', {
            rules: [
              {
                validator: (rule: any, value: string, cb: any) => {
                  return validateHosts(type, value, false)
                },
                message: this.Messages.INVALID_HOST,
              },
            ],
            initialValue: radiusHosts,
          })(
            <A10Input
              type="text"
              placeholder={this.Messages.HOST}
              onChange={this.handleInputChange.bind(this, 'radiusHosts')}
            />,
          )}
        </div>
      </A10Form.Item>
    )

    const portField: JSX.Element = (
      <A10Form.Item
        {...this.formItemLayout}
        key="radius-port"
        label={
          <span className="mandatoryField">
            {this.Messages.PORT}
            <span>
              <HelpInfo
                customCls="custom-tooltip-input"
                placement="left"
                title={this.Messages.PORT}
                helpKey="HELP_ORG_AUTHENTICATION_RADIUS_PORT"
              />
            </span>
          </span>
        }
      >
        <div>
          {getFieldDecorator('radius-port', {
            rules: [
              {
                validator: (rule: any, value: string, cb: any) => {
                  return validatePort(type, value, false)
                },
                message: this.Messages.INVALID_PORT_NUMBER,
              },
            ],
            initialValue: radiusPort,
          })(
            <A10Input
              type="text"
              placeholder={this.Messages.PORT}
              onChange={this.handleInputChange.bind(this, 'radiusPort')}
            />,
          )}
        </div>
      </A10Form.Item>
    )

    const retriesField: JSX.Element = (
      <A10Form.Item
        {...this.formItemLayout}
        key="radius-retries"
        label={
          <span>
            {this.Messages.RETRIES}
            <span>
              <HelpInfo
                customCls="custom-tooltip-input"
                placement="left"
                title={this.Messages.RETRIES}
                helpKey="HELP_ORG_AUTHENTICATION_RADIUS_RETRIES"
              />
            </span>
          </span>
        }
      >
        <div>
          {getFieldDecorator('radius-retries', {
            rules: [
              {
                validator: (rule: any, value: string, cb: any) => {
                  return validateRetries(type, value, false)
                },
                message: this.Messages.INVALID_RETRIES,
              },
            ],
            initialValue: retries ? retries : '0',
          })(
            <A10Input
              type="text"
              placeholder={this.Messages.RETRIES + ' (optional)'}
              onChange={this.handleInputChange.bind(this, 'retries')}
            />,
          )}
        </div>
      </A10Form.Item>
    )

    const sharedSecretField: JSX.Element = (
      <A10Form.Item
        {...this.formItemLayout}
        key="radius-shared-secret"
        label={
          <span className="mandatoryField">
            {this.Messages.SHARED_SECRET}
            <span>
              <HelpInfo
                customCls="custom-tooltip-input"
                placement="left"
                title={this.Messages.SHARED_SECRET}
                helpKey="HELP_ORG_AUTHENTICATION_RADIUS_SHARED_SECRET"
              />
            </span>
          </span>
        }
      >
        <div>
          {getFieldDecorator('radius-shared-secret', {
            rules: [
              {
                validator: (rule: any, value: string, cb: any) => {
                  return validateSharedSecret(type, value, false)
                },
                message: this.Messages.INVALID_SHARED_SECRET,
              },
            ],
            initialValue: '',
          })(
            <A10Input
              type="password"
              placeholder={this.Messages.SHARED_SECRET}
              onChange={this.handleInputChange.bind(this, 'sharedSecret')}
            />,
          )}
        </div>
      </A10Form.Item>
    )

    const validateButton: JSX.Element = (
      <A10Form.Item {...this.formItemLayout} key="validate-btn" label=" ">
        <a
          style={{ color: '#4a90e2', fontSize: 17 }}
          onClick={this.onClickValidate}
        >
          Validate
        </a>
      </A10Form.Item>
    )

    const remoteAuthorization: JSX.Element = (
      <A10Form.Item
        {...this.formItemLayout}
        key="radius-remote-authorization"
        label={
          <span>
            Remote Authorization
            <span>
              <HelpInfo
                customCls="custom-tooltip-input"
                placement="left"
                title="User Remote Authorization"
                helpKey="HELP_ORG_AUTHENTICATION_REMOTE_AUTHORIZATION"
              />
            </span>
          </span>
        }
      >
        <A10Switch
          color="green"
          style={{ marginRight: '10px' }}
          checked={this.state.dataCache['remote-authorization']}
          onChange={this.handleInputChange.bind(this, 'remote-authorization')}
        />
        {this.state.dataCache['remote-authorization'] ? 'Enabled' : 'Disabled'}
      </A10Form.Item>
    )

    return [
      hostsField,
      portField,
      retriesField,
      sharedSecretField,
      validateButton,
      remoteAuthorization,
    ]
  }

  renderTacacsplusAuthForm = () => {
    const { getFieldDecorator } = this.props.form
    const {
      type,
      tacacsHosts,
      tacacsPort,
      // remoteAddress,
      retries,
      timeout,
      sharedSecret,
    } = this.state.dataCache

    const hostsField: JSX.Element = (
      <A10Form.Item
        {...this.formItemLayout}
        key="tacacs-hosts"
        label={
          <span className="mandatoryField">
            Hosts
            <span>
              <HelpInfo
                customCls="custom-tooltip-input"
                placement="left"
                title={this.Messages.HOST}
                helpKey="HELP_ORG_AUTHENTICATION_TACACS_HOST"
              />
            </span>
          </span>
        }
      >
        <div>
          {getFieldDecorator('tacacs-hosts', {
            rules: [
              {
                validator: (rule: any, value: string, cb: any) => {
                  return validateHosts(type, value, false)
                },
                message: this.Messages.INVALID_HOST,
              },
            ],
            initialValue: tacacsHosts,
          })(
            <A10Input
              type="text"
              placeholder={this.Messages.HOST}
              onChange={this.handleInputChange.bind(this, 'tacacsHosts')}
            />,
          )}
        </div>
      </A10Form.Item>
    )

    const portField: JSX.Element = (
      <A10Form.Item
        {...this.formItemLayout}
        key="tacacs-port"
        label={
          <span className="mandatoryField">
            {this.Messages.PORT}
            <span>
              <HelpInfo
                customCls="custom-tooltip-input"
                placement="left"
                title={this.Messages.PORT}
                helpKey="HELP_ORG_AUTHENTICATION_TACACS_PORT"
              />
            </span>
          </span>
        }
      >
        <div>
          {getFieldDecorator('tacacs-port', {
            rules: [
              {
                validator: (rule: any, value: string, cb: any) => {
                  return validatePort(type, value, false)
                },
                message: this.Messages.INVALID_PORT_NUMBER,
              },
            ],
            initialValue: tacacsPort,
          })(
            <A10Input
              type="text"
              placeholder={this.Messages.PORT}
              onChange={this.handleInputChange.bind(this, 'tacacsPort')}
            />,
          )}
        </div>
      </A10Form.Item>
    )

    const retriesField: JSX.Element = (
      <A10Form.Item
        {...this.formItemLayout}
        key="tacacs-retries"
        label={
          <span>
            {this.Messages.RETRIES}
            <span>
              <HelpInfo
                customCls="custom-tooltip-input"
                placement="left"
                title={this.Messages.RETRIES}
                helpKey="HELP_ORG_AUTHENTICATION_TACACS_RETRIES"
              />
            </span>
          </span>
        }
      >
        <div>
          {getFieldDecorator('tacacs-retries', {
            rules: [
              {
                validator: (rule: any, value: string, cb: any) => {
                  return validateRetries(type, value, false)
                },
                message: this.Messages.INVALID_RETRIES,
              },
            ],
            initialValue: retries ? retries : '0',
          })(
            <A10Input
              type="text"
              placeholder={this.Messages.RETRIES + ' (optional)'}
              onChange={this.handleInputChange.bind(this, 'retries')}
            />,
          )}
        </div>
      </A10Form.Item>
    )

    const timeoutField: JSX.Element = (
      <A10Form.Item
        {...this.formItemLayout}
        key="tacacs-timeout"
        label={
          <span>
            {this.Messages.TIMEOUT}
            <span>
              <HelpInfo
                customCls="custom-tooltip-input"
                placement="left"
                title={this.Messages.TIMEOUT}
                helpKey="HELP_ORG_AUTHENTICATION_TACACS_TIMEOUT"
              />
            </span>
          </span>
        }
      >
        <div>
          {getFieldDecorator('tacacs-timeout', {
            rules: [
              {
                validator: (rule: any, value: string, cb: any) => {
                  return validateTimeout(type, value, false)
                },
                message: this.Messages.INVALID_TIMEOUT,
              },
            ],
            initialValue: timeout ? timeout : '0',
          })(
            <A10Input
              type="text"
              placeholder={this.Messages.TIMEOUT + ' (optional)'}
              onChange={this.handleInputChange.bind(this, 'timeout')}
            />,
          )}
        </div>
      </A10Form.Item>
    )

    const sharedSecretField: JSX.Element = (
      <A10Form.Item
        {...this.formItemLayout}
        key="tacacs-shared-secret"
        label={
          <span className="mandatoryField">
            {this.Messages.SHARED_SECRET}
            <span>
              <HelpInfo
                customCls="custom-tooltip-input"
                placement="left"
                title={this.Messages.SHARED_SECRET}
                helpKey="HELP_ORG_AUTHENTICATION_TACACS_SHARED_SECRET"
              />
            </span>
          </span>
        }
      >
        <div>
          {getFieldDecorator('tacacs-shared-secret', {
            rules: [
              {
                validator: (rule: any, value: string, cb: any) => {
                  return validateSharedSecret(type, value, false)
                },
                message: this.Messages.INVALID_SHARED_SECRET,
              },
            ],
            initialValue: '',
          })(
            <A10Input
              type="password"
              placeholder={this.Messages.SHARED_SECRET}
              onChange={this.handleInputChange.bind(this, 'sharedSecret')}
            />,
          )}
        </div>
      </A10Form.Item>
    )

    const validateButton: JSX.Element = (
      <A10Form.Item {...this.formItemLayout} key="validate-btn" label=" ">
        <a
          style={{ color: '#4a90e2', fontSize: 17 }}
          onClick={this.onClickValidate}
        >
          Validate
        </a>
      </A10Form.Item>
    )

    const remoteAuthorization: JSX.Element = (
      <A10Form.Item
        {...this.formItemLayout}
        key="tacaus-remote-authorization"
        label={
          <span>
            Remote Authorization
            <span>
              <HelpInfo
                customCls="custom-tooltip-input"
                placement="left"
                title="User Remote Authorization"
                helpKey="HELP_ORG_AUTHENTICATION_REMOTE_AUTHORIZATION"
              />
            </span>
          </span>
        }
      >
        <A10Switch
          defaultChecked
          color="green"
          checked={this.state.dataCache['remote-authorization']}
          onChange={this.handleInputChange.bind(this, 'remote-authorization')}
          style={{ marginRight: '10px' }}
        />
        {this.state.dataCache['remote-authorization'] ? 'Enabled' : 'Disabled'}
      </A10Form.Item>
    )

    return [
      hostsField,
      portField,
      // remoteField,
      retriesField,
      timeoutField,
      sharedSecretField,
      validateButton,
      remoteAuthorization,
    ]
  }

  renderAuthForm(type: string) {
    let content: JSX.Element | JSX.Element[]
    switch (type) {
      case 'local':
        content = this.renderDefaultAuthForm()
        break
      case 'default':
        content = this.renderDefaultAuthForm()
        break
      case 'ldap':
        content = this.renderLdapAuthForm()
        break
      case 'radius':
        content = this.renderRadiusAuthForm()
        break
      case 'tacacsplus':
        content = this.renderTacacsplusAuthForm()
        break
      default:
        content = this.renderDefaultAuthForm()
        break
    }
    return content
  }

  handleAuthTypeChange = (type: string) => {
    const { dataCache } = this.state
    const { handleChange } = this.props

    if (dataCache.type !== type) {
      const newCache = this.parseAuthProvider({ type })
      const newAuthProvider = this.generatePayload(newCache)

      this.setState({
        dataCache: newCache,
      })
      if (_.isFunction(handleChange)) {
        handleChange(newAuthProvider)
      }
    }
  }

  handleInputChange = (
    key: string,
    e: React.ChangeEvent<any> | any,
    index?: number,
  ) => {
    const { dataCache } = this.state
    if (key === 'mfaEnabled') {
      this.setState({
        showWarningForDisableMFA: e
      })
    }
    const { handleChange } = this.props
    if (_.isFinite(index)) {
      dataCache[key][index] = e.target.value
    } else if (_.has(e, 'target')) {
      if (e.target.type === 'checkbox') {
        dataCache[key] = e.target.checked
      } else {
        dataCache[key] = e.target.value
      }
    } else if (key === 'mfaEnforceData') {
      const dateOnly = moment(e).format(this.dateFormat)
      dataCache['mfaEnforceData'] = dateOnly
    } else {
      dataCache[key] = e
    }
    this.setState({ dataCache })
    if (_.isFunction(handleChange)) {
      handleChange(this.generatePayload(dataCache))
    }
  }

  onClickValidate = () => {
    const { onValidate } = this.props

    if (!this.checkState()) {
      return
    }

    if (_.isFunction(onValidate)) {
      onValidate()
    }
  }

  exportUser = () => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context

    if (this.adminLevel === 'provider' || true) {
      this.OrganizationService.getUsers(null, null, provider).then(response => {
        let userCSVData = 'Name,Email,User ID,Access Group\n'
        let allUsers = response.data || []
        const rbacRoles: string[] = []
        allUsers.map((user: any) => {
          if (user.roles && user.roles.length > 0) {
            user.roles.map((roleObj: any) => {
              const roles = Object.keys(roleObj)
              rbacRoles.push(roles[0])
            })
          }
        })

        if (allUsers?.length > 0) {
          allUsers.forEach((item: IObject, index: number) => {
            const name = `${item['first-name']} ${item['last-name']}`
            const accessGroup =
              rbacRoles[index] && rbacRoles.length > 0
                ? rbacRoles[index]
                : 'Not Assigned'
            const cellData = [
              name,
              item['email-id'],
              item['user-id'],
              accessGroup,
            ]
            userCSVData += `${cellData.join(',')}\n`
          })
        }

        const hiddenElement = document.createElement('a')
        hiddenElement.href =
          'data:text/csv;charset=utf-8,' + encodeURI(userCSVData)
        hiddenElement.target = '_blank'

        //provide the name for the CSV file to be downloaded
        hiddenElement.download = 'User_Data.csv'
        hiddenElement.click()
      })
    }
  }

  handleClose = () => {
    this.setState({
      showWarningForDisableMFA: null,
    })

    this.props.onClose()
  }

  handleSave = () => {
    this.setState({
      showWarningForDisableMFA: null,
    })
    if (!this.checkState()) {
      return
    }
    this.props.handleSubmit()
  }

  render() {
    const {
      pageTitle,
      dataCache,
      initialRemoteAuthorization,
      showWarningForDisableMFA,
      initialMFA,
    } = this.state
    const { getFieldDecorator } = this.props.form
    const { visible, isLoading } = this.props
    const type = _.get(dataCache, 'type')
    const authType = dataCache.type
    
    return (
      <FormatSlidingPage
        isOpen={visible}
        title={pageTitle}
        disableSave={isLoading}
        onRequestOk={this.handleSave}
        onRequestClose={this.handleClose}
      >
        <A10Form hideRequiredMark={true} layout="horizontal">
          {authType !== 'local' && !initialRemoteAuthorization ? (
            <A10Alert
              message="Changing to remote authentication would make your existing local user accounts inactive."
              type="info"
              style={{ margin: '20px 0px' }}
              showIcon
              closable
            />
          ) : (
            ''
          )}
          {initialMFA && showWarningForDisableMFA === false && (
            <A10Alert
              message="WARNING! You are about to disable Multi-Factor Authentication (MFA) for your account."
              type="warning"
              style={{ margin: '20px 0px' }}
              showIcon
              closable
            />
          )}

          <A10Panel
            style={{ marginTop: '35px' }}
            className="editPanel"
            title={
              <div>
                <A10IconTextGroup
                  text="Authentication &amp; Authorization"
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, margin: '0px 12px 0px 17px' }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
                {!initialRemoteAuthorization && (
                  <ActionButton
                    style={{ float: 'right', marginTop: '8px' }}
                    onClick={this.exportUser}
                    text="Export Local Users"
                  />
                )}
              </div>
            }
          >
            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.AUTH_TYPE}
                    <span>
                      <HelpInfo
                        customCls="custom-tooltip-input"
                        placement="left"
                        title="Authentication Type"
                        helpKey="HELP_ORG_AUTHENTICATION_TYPE"
                      />
                    </span>
                  </span>
                </>
              }
            >
              <div>
                <A10Select
                  name="type"
                  defaultValue={type}
                  title={
                    type === 'local' || type === 'default' ? 'Local' : type
                  }
                  key="auth-type"
                  onChange={this.handleAuthTypeChange}
                  placeholder="Please select Authentication type"
                >
                  {this.renderAuthTypes()}
                </A10Select>
              </div>
            </A10Form.Item>

            {this.renderAuthForm(type)}
          </A10Panel>

          {type === 'ldap' && (
            <A10Panel
              style={{ marginTop: '35px' }}
              className="editPanel"
              title={
                <A10IconTextGroup
                  text={
                    <>
                      <span>Advance Configuration</span>
                    </>
                  }
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, margin: '0px 12px 0px 17px' }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              }
            >
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    <span>{this.Messages.FIRSTNAME_ATTRIBUTE}</span>
                    <span>
                      <HelpInfo
                        customCls="custom-tooltip-input"
                        placement="left"
                        title='Enter firstname attribute'
                        helpKey="HELP_ORG_USER_FIRSTNAME"
                      />
                    </span>
                  </>
                }
              >
                <A10Input
                  type="text"
                  defaultValue={dataCache?.['user-first-name-attribute']}
                  placeholder='Enter firstname attribute'
                  onChange={this.handleInputChange.bind(this, 'user-first-name-attribute')}
                />
              </A10Form.Item>
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    <span>{this.Messages.LASTNAME_ATTRIBUTE}</span>
                    <span>
                      <HelpInfo
                        customCls="custom-tooltip-input"
                        placement="left"
                        title='Enter lastname attribute '
                        helpKey="HELP_ORG_USER_LASTNAME"
                      />
                    </span>
                  </>
                }
              >
                <A10Input
                  type="text"
                  defaultValue={dataCache?.['user-last-name-attribute']}
                  placeholder='Enter lastname attribute'
                  onChange={this.handleInputChange.bind(this, 'user-last-name-attribute')}
                />
              </A10Form.Item>
              {dataCache?.['remote-authorization'] && (
                <>
                  <A10Form.Item
                    {...this.formItemLayout}
                    label={
                      <>
                        <span>{this.Messages.OBJECT_CLASS}</span>
                        <span style={{ marginLeft: '-10px' }}>
                          <HelpInfo
                            placement="left"
                            title="Select group object class"
                            helpKey="HELP_ORG_GROUP_OBJECT_CLASS"
                          />
                        </span>
                      </>
                    }
                  >
                    <A10Input
                      type="text"
                      defaultValue={dataCache?.['group-objectclass']}
                      placeholder='Enter group object class'
                      onChange={this.handleInputChange.bind(this, 'group-objectclass')}
                    />
                  </A10Form.Item>
                  <A10Form.Item
                    {...this.formItemLayout}
                    label={
                      <>
                        <span className="mandatoryField">
                          {this.Messages.BASE_DN}
                        </span>
                        <span style={{ marginLeft: '-10px' }}>
                          <HelpInfo
                            placement="left"
                            title="Select group base dn"
                            helpKey="HELP_ORG_GROUP_BASE_DN"
                          />
                        </span>
                      </>
                    }
                  >
                    {getFieldDecorator('group-base-dn', {
                      rules: [
                        { required: true, message: this.Messages.EMPTY_GROUP_BASE_DN },
                      ],
                      initialValue: dataCache?.['group-base-dn'],
                    })(
                      <A10Input
                        type="text"
                        placeholder='Enter group base dn'
                        onChange={this.handleInputChange.bind(this, 'group-base-dn')}
                      />,
                    )}

                  </A10Form.Item>
                  <A10Form.Item
                    {...this.formItemLayout}
                    label={
                      <>
                        <span className="mandatoryField">
                          {this.Messages.GROUP_MEMBERSHIP_USER}
                        </span>
                        <span>
                          <HelpInfo
                            customCls="custom-tooltip-input"
                            placement="left"
                            title="group Membership of user"
                            helpKey="HELP_MONITOR_GROUP_MEMBERSHIP_OF_USER"
                          />
                        </span>
                      </>
                    }
                  >
                    <A10Radio.Group
                      defaultValue={dataCache?.['group-membership-of-user']}
                      onChange={this.handleInputChange.bind(this, 'group-membership-of-user')}
                    >
                      <A10Radio value={'user-entry'}>
                        User Entry
                        <span>
                          <HelpInfo
                            customCls="custom-tooltip-input"
                            placement="bottom"
                            title="groupMembership"
                            helpKey="HELP_MONITOR_USER_ENTRY"
                          />
                        </span>
                      </A10Radio>
                      <A10Radio value={'group-entry'}>
                        Group Entry
                        <HelpInfo
                          customCls="custom-tooltip-input"
                          placement="bottom"
                          title="groupMembership"
                          helpKey="HELP_MONITOR_GROUP_ENTRY"
                        />
                      </A10Radio>
                      <A10Radio value={'both'}>
                        Both
                        <HelpInfo
                          customCls="custom-tooltip-input"
                          placement="bottom"
                          title="groupMembership"
                          helpKey="HELP_MONITOR_BOTH_ENTRIES"
                        />
                      </A10Radio>
                    </A10Radio.Group>
                  </A10Form.Item>

                  {dataCache?.['group-membership-of-user'] === 'user-entry' && (
                    <A10Form.Item
                      {...this.formItemLayout}
                      label={
                        <>
                          <span className="mandatoryField">
                            {this.Messages.GROUP_MEMBERSHIP_ATTRIBUTE}
                          </span>
                          <span style={{ marginLeft: '-10px' }}>
                            <HelpInfo
                              placement="left"
                              title="Select group membership attribute"
                              helpKey="HELP_ORG_GROUP_MEMBERSHIP_ATTR"
                            />
                          </span>
                        </>
                      }
                    >
                      {getFieldDecorator('group-membership-attribute', {
                        rules: [
                          { required: true, message: this.Messages.EMPTY_GROUP_MEMBERSHIP_ATTRIBUTE },
                        ],
                        initialValue: dataCache?.['group-membership-attribute'],
                      })(
                        <A10Input
                          type="text"
                          placeholder='Enter group membership attribute'
                          onChange={this.handleInputChange.bind(this, 'group-membership-attribute')}
                        />
                      )}
                    </A10Form.Item>
                  )}

                  {dataCache?.['group-membership-of-user'] === 'group-entry' && (
                    <>
                      <A10Form.Item
                        {...this.formItemLayout}
                        label={
                          <>
                            <span className="mandatoryField">
                              {this.Messages.GROUP_MEMBER_ATTR}
                            </span>
                            <span style={{ marginLeft: '-10px' }}>
                              <HelpInfo
                                placement="left"
                                title="Enter group member attribute"
                                helpKey="HELP_ORG_GROUP_MEMBER_ATTR_"
                              />
                            </span>
                          </>
                        }
                      >
                        {getFieldDecorator('group-member-attribute', {
                          rules: [
                            { required: true, message: this.Messages.EMPTY_GROUP_MEMBER_ATTR },
                          ],
                          initialValue: dataCache?.['group-member-attribute'],
                        })(
                          <A10Input
                            type="text"
                            placeholder='Enter group member attribute'
                            onChange={this.handleInputChange.bind(this, 'group-member-attribute')}
                          />
                        )}
                      </A10Form.Item>
                      <A10Form.Item
                        {...this.formItemLayout}
                        label={
                          <A10Row className='userAttrHeading' >
                            <span className="mandatoryField" >
                              {this.Messages.USER_ATTRIBUTE}
                            </span>
                            <span style={{ marginLeft: '-10px' }}>
                              <HelpInfo
                                placement="left"
                                title="Select user attribute"
                                helpKey="HELP_ORG_USER_ATTR"
                              />
                            </span>
                          </A10Row>
                        }
                      >
                        {getFieldDecorator('user-attribute-in-group-attribute', {
                          rules: [
                            { required: true, message: this.Messages.EMPTY_USER_ATTRIBUTE },
                          ],
                          initialValue: dataCache?.['user-attribute-in-group-attribute'],
                        })(
                          <A10Input
                            type="text"
                            placeholder='Enter user attribute'
                            onChange={this.handleInputChange.bind(this, 'user-attribute-in-group-attribute')}
                          />
                        )}
                      </A10Form.Item>
                    </>
                  )}

                  {dataCache?.['group-membership-of-user'] === 'both' && (
                    <>
                      <A10Form.Item
                        {...this.formItemLayout}
                        label={
                          <>
                            <span className="mandatoryField">
                              {this.Messages.GROUP_MEMBERSHIP_ATTRIBUTE}
                            </span>
                            <span style={{ marginLeft: '-10px' }}>
                              <HelpInfo
                                placement="left"
                                title="Select group membership attribute"
                                helpKey="HELP_ORG_GROUP_MEMBERSHIP_ATTR_BOTH"
                              />
                            </span>
                          </>
                        }
                      >
                        {getFieldDecorator('group-membership-attribute', {
                          rules: [
                            { required: true, message: this.Messages.EMPTY_GROUP_MEMBERSHIP_ATTRIBUTE },
                          ],
                          initialValue: dataCache?.['group-membership-attribute'],
                        })(
                          <A10Input
                            type="text"
                            placeholder='Enter group membership attribute'
                            onChange={this.handleInputChange.bind(this, 'group-membership-attribute')}
                          />
                        )}
                      </A10Form.Item>
                      <A10Form.Item
                        {...this.formItemLayout}
                        label={
                          <>
                            <span className="mandatoryField">
                              {this.Messages.GROUP_ID_ATTRIBUTE}
                            </span>
                            <span style={{ marginLeft: '-10px' }}>
                              <HelpInfo
                                placement="left"
                                title="enter group id attribute"
                                helpKey="HELP_ORG_GROUP_ID_ATTRIBUTE"
                              />
                            </span>
                          </>
                        }
                      >

                        {getFieldDecorator('group-id-attribute', {
                          rules: [
                            { required: true, message: this.Messages.EMPTY_GROUP_ID_ATTRIBUTE },
                          ],
                          initialValue: dataCache?.['group-id-attribute'],
                        })(
                          <A10Input
                            type="text"
                            placeholder='Enter group id attribute'
                            onChange={this.handleInputChange.bind(this, 'group-id-attribute')}
                          />
                        )}
                      </A10Form.Item>
                    </>
                  )}
                </>
              )}
            </A10Panel>
          )}
          {isLoading ? (
            <div className="col-md-12" style={{ height: 50 }}>
              <div className="loading-icon">
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </div>
            </div>
          ) : null}
        </A10Form>
      </FormatSlidingPage>
    )
  }
}

export default A10Form.create()(AuthConfigurationEditForm)


