import React from 'react'
import { _ } from '@gui-libraries/framework'
import {
  A10Collapse,
  A10DropdownMenu,
  A10Icon,
  A10Row,
  A10Table,
} from '@gui-libraries/widgets'

import HealthStatus from 'src/components/ADC/HealthStatus'
import RoundNumber from 'src/components/shared/RoundNumber'
import { normalizeValue } from '@gui-libraries/viz'
import storage from 'src/libraries/storage'
export interface IInterfaceLifProps {
  lifList: IObject[]
  openAutoForm: any
  deleteLif: any
  lifStatus: IObject
}

const InterfaceLIF: React.FC<IInterfaceLifProps> = props => {
  const { lifList, lifStatus, openAutoForm, deleteLif } = props
  const [datasource, setDatasource] = React.useState([] as IObject[])
  const [status, setStatus] = React.useState({} as IObject)
  const [counter, setCounter] = React.useState({} as IObject)

  const clickAddNew = (event: React.MouseEvent<HTMLElement>) => {
    if (openAutoForm) {
      openAutoForm('network/interfaces/lif')
    }
    event.stopPropagation()
  }

  const editLIF = (ifname: number) => {
    if (openAutoForm) {
      const params = ifname ? { ifname } : undefined
      openAutoForm('network/interfaces/lif', params)
    }
  }

  const clickDelete = (ifname: number) => {
    if (deleteLif) {
      deleteLif(ifname)
    }
  }
  React.useEffect(() => {
    const ds = [] as IObject[]
    const count = {} as IObject
    const currentStatus = { up: 0, down: 0, disable: 0 } as IObject
    ;(lifList || []).map((lif: IObject) => {
      const { oper = {}, stats = {} } = (lifStatus || {})[lif.ifname] || {}
      currentStatus[oper.state] = (currentStatus[oper.state] || 0) + 1
      const packetIn = normalizeValue('number', {
        value: stats.num_pkts - stats.num_tx_pkts || 0,
        formatType: '0.0b',
        unit: 'B',
      })
      const packetOut = normalizeValue('number', {
        value: stats.num_tx_pkts || 0,
        formatType: '0.0b',
        unit: 'B',
      })
      const byteIn = normalizeValue('number', {
        value: stats.num_total_bytes - stats.num_total_tx_bytes || 0,
        formatType: '0.00a',
      })
      const byteOut = normalizeValue('number', {
        value: stats.num_total_tx_bytes || 0,
        formatType: '0.00a',
      })
      const errorIn = normalizeValue('number', {
        value: stats.dropped_rx_pkts || 0,
        formatType: '0.0b',
        unit: 'B',
      })
      const errorOut = normalizeValue('number', {
        value: stats.num_total_tx_bytes || 0,
        formatType: '0.0b',
        unit: 'B',
      })
      const source = {
        key: lif.ifname,
        status: oper.state,
        ifname: lif.ifname,
        mtu: lif.mtu,
        action: lif.action,
        mac: oper.mac,
        ipaddress: _.get(lif, 'ip.address-list[0].ipv4-address', 'N/A'),
        packets: `${packetIn}/ ${packetOut}`,
        bytes: `${byteIn}/ ${byteOut}`,
        pktDrop: `${errorIn}/ ${errorOut}`,
      }
      ds.push(source)
      count.packetIn =
        (count.packets || 0) + (stats.num_pkts - stats.num_tx_pkts || 0)
      count.packetOut = (count.byteOut || 0) + (stats.num_tx_pkts || 0)
      count.byteIn =
        (count.byteIn || 0) +
        (stats.num_total_bytes - stats.num_total_tx_bytes || 0)
      count.byteOut = (count.byteOut || 0) + (stats.num_total_tx_bytes || 0)
    })
    setStatus(currentStatus)
    setDatasource(ds)
    setCounter(count)
  }, [lifList, lifStatus])

  const columns = React.useMemo(
    () => [
      {
        dataIndex: 'status',
        sorter: (a: IObject, b: IObject) => {
          return a.status > b.status ? 1 : -1
        },
        render: (text: any, record: any) => {
          return (
            <HealthStatus
              type={record.status}
              tooltip={record.status || 'Unknown'}
              toolTipArrowPosition="topLeft"
            />
          )
        },
      },
      {
        title: 'Interface Name',
        dataIndex: 'ifname',
        sorter: (a: IObject, b: IObject) => {
          return a.ifname > b.ifname ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      // {
      //   title: 'Partition ID',
      //   dataIndex: 'partitionid',
      //   sorter: (a: any, b: any) => a - b,
      //   render: (text: any, record: any) => {
      //     return text || 'N/A'
      //   },
      // },
      {
        title: 'IP Address',
        dataIndex: 'ipaddress',
        sorter: (a: IObject, b: IObject) => {
          return a.ipaddress > b.ipaddress ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text || 'N/A'
        },
      },
      {
        title: 'MAC',
        dataIndex: 'mac',
        sorter: (a: IObject, b: IObject) => {
          return a.mac > b.mac ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text || 'N/A'
        },
      },
      // {
      //   title: 'Speed',
      //   dataIndex: 'speed',
      //   sorter: (a: any, b: any) => a - b,
      //   render: (text: any, record: any) => {
      //     return <s>N/A</s>
      //   },
      // },
      {
        title: 'MTU',
        dataIndex: 'mtu',
        sorter: (a: IObject, b: IObject) => {
          return a.mtu > b.mtu ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text || 'N/A'
        },
      },
      {
        title: 'Packets In/Out',
        dataIndex: 'packets',
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Bytes In/Out',
        dataIndex: 'bytes',
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Pkt Drop In/Out',
        dataIndex: 'pktDrop',
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        dataIndex: 'actions',
        width: 30,
        render: (text: any, record: any) => {
          return (
            !storage.get.IS_OPERATOR_USER && (
              <A10DropdownMenu
                title=""
                menu={[
                  <div key="edit" onClick={editLIF.bind(this, record.ifname)}>
                    Edit
                  </div>,
                  <div
                    key="delete"
                    onClick={clickDelete.bind(this, record.ifname)}
                  >
                    Delete
                  </div>,
                ]}
                style={{ color: '#757575', marginBottom: '-15px' }}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            )
          )
        },
      },
    ],
    [deleteLif],
  )
  return (
    <div className="col-md-12 marginBottom10">
      <A10Collapse>
        <A10Collapse.Panel
          key="interface-lif"
          header={
            <>
              {!storage.get.IS_OPERATOR_USER && (
                <span className="edit-content" onClick={clickAddNew}>
                  <A10Icon app="global" type="add-new" /> Add
                </span>
              )}
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 width180">
                  LIF <RoundNumber number={(lifList || []).length} />
                </span>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Up</div>
                  <div className="pad-s-15 fontWeight300">{status.up || 0}</div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Down</div>
                  <div className="pad-s-15 fontWeight300">
                    {status.down || 0}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Disabled</div>
                  <div className="pad-s-15 fontWeight300">
                    {status.disabled || 0}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Packets In/Out</div>
                  <div className="pad-s-15 fontWeight300">
                    {normalizeValue('number', {
                      value: counter.packetIn || 0,
                      formatType: '0.0b',
                      unit: 'B',
                    })}
                    /
                    {normalizeValue('number', {
                      value: counter.packetOut || 0,
                      formatType: '0.0b',
                      unit: 'B',
                    })}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Bytes In/Out</div>
                  <div className="pad-s-15 fontWeight300">
                    {normalizeValue('number', {
                      value: counter.byteIn || 0,
                      formatType: '0.00a',
                    })}
                    /
                    {normalizeValue('number', {
                      value: counter.byteOut || 0,
                      formatType: '0.00a',
                    })}
                  </div>
                </div>
              </A10Row>
            </>
          }
          className="collapsePanel"
        >
          <A10Table
            columns={columns}
            dataSource={datasource}
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
            bordered={false}
          />
        </A10Collapse.Panel>
      </A10Collapse>
    </div>
  )
}

export default InterfaceLIF
