import { getNS, IHTTPRequestOptions } from '@gui-libraries/framework'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'

const HPCAPI_PREFIX = '/hpcapi/v3'
const getProvider = () => storage.get.PROVIDER

export const getDeviceClusterMap = (): IHTTPRequestOptions => {
  return {
    namespace: getNS('DEVICE_CLUSTER_MAP'),
    request: async () => {
      try {
        const provider = getProvider()
        const actionURL = `${HPCAPI_PREFIX}/provider/${provider}/device`
        const { data: deviceData } = await httpClient.get(actionURL)
        const deviceList = deviceData['device-list'] || []
        const deviceMap = new Map()
        for (const device of deviceList) {
          deviceMap.set(device.name, device.cluster)
        }
        return deviceMap
      } catch (err) {
        console.error(err)
      }
      return []
    },
  }
}
