import { _ } from '@gui-libraries/framework'

import storage, { priorityMap } from 'src/libraries/storage'
import Utilities from 'src/services/Utilities/Utilities'
import { getFilterQuery } from '../Common/FilterList'
import {
  getAlert,
  getAction,
  getTrigger,
  fetchClusterAndDevice,
  fetchAppService,
} from './basicApiUtils'
import * as basicUtils from './basicUtils'

const getConditionDetail = (conditionsObj: any[], type: any) => {
  return (
    conditionsObj &&
    conditionsObj.map((operObj: any, i: number) => {
      const { getVal } = new Utilities()
      return `${i !== 0 ? ` ${type} ` : ''} ${
        getVal(operObj.aggregation, 'TRIGGERS', 'metricAgg').label
      } of ${
        operObj.params.length > 1
          ? getVal(operObj.operation, 'TRIGGERS', 'operation').label +
            ' of (' +
            operObj.params.join(', ') +
            ')'
          : operObj.params.join(', ')
      }${' is '} ${getVal(operObj.operator, 'TRIGGERS', 'operator').label} ${
        operObj.value
      } ${
        operObj.percent
          ? `${' % of '}${
              getVal(operObj.metricAggPercent, 'TRIGGERS', 'metricAgg').label
            } ${getVal(operObj.metricPercent, 'TRIGGERS', 'metric').label}`
          : ''
      }`
    })
  )
}

const getTriggerCondition = (trigger: IObject) => {
  const { definition: definition = {} } = trigger
  const { conditions: conditionInfo = {} } = definition
  const and = getConditionDetail(conditionInfo.and, 'AND')
  const or = getConditionDetail(conditionInfo.or, 'OR')
  return `${and ? and : ''} ${or ? or : ''}`
}

const getPrefixName = (alert: IObject) => {
  const currentTenant = storage.get.CURRENT_TENANT
  const { definition: definition = {} } = alert
  const { filter: filterObj = {} } = definition
  // const filters = new Array()
  // if (!currentTenant && alert.tenant) {
  //   filters.push(alert.tenant)
  // }
  // if (filterObj.cluster_id) {
  //   filters.push(filterObj.cluster_id)
  // }
  // if (filterObj.device_id) {
  //   filters.push(filterObj.device_uuid)
  // }
  // if (filterObj.p_id || filterObj.p_id === 0) {
  //   filters.push(filterObj.p_id)
  // }
  // let preName = filters.join(':')
  // preName = preName ? `${preName}: ` : ''
  return filterObj
}

/* istanbul ignore next */
export const fetchAlertAndTrigger = async (
  httpClient: IObject,
  records: IObject,
) => {
  const fetchAlerts = {}
  const fetchTrigger = {}
  const fetchActions = {}
  const alertRecords = new Array()
  for (const recordKey in records) {
    if (records.hasOwnProperty(recordKey)) {
      const record = records[recordKey]
      if (record) {
        const {
          rule_assoc_id: alertDefId,
          rule_def_id: triggerDefId,
          account_id: tenantUuid,
        } = record
        if (fetchAlerts[alertDefId]) {
          record.alert = fetchAlerts[alertDefId]
        } else {
          const alert =
            (await getAlert(httpClient, tenantUuid, alertDefId)) || {}
          const { definition: definition = {} } = alert
          const {
            priority: priority = 'Unknow',
            severity,
            action_def_id: actionDefIds = [],
          } = definition
          alert.severity = severity
          alert.priority = priority
          // alert.prefixNameList = getPrefixName(alert)
          // alert.prefixName = alert.prefixNameList.join(':')
          alert.prefixName = getPrefixName(alert)
          const actions = new Array()
          for (const actionDefId of actionDefIds) {
            if (fetchActions[actionDefId]) {
              actions.push(fetchActions[actionDefId])
            } else {
              const actionInstance = await getAction(
                httpClient,
                tenantUuid,
                actionDefId,
              )
              fetchActions[actionDefId] = actionInstance
              actions.push(actionInstance)
            }
          }
          record.actions = actions
          record.alert = alert
          fetchAlerts[alertDefId] = alert
        }

        if (fetchTrigger[triggerDefId]) {
          record.trigger = fetchTrigger[triggerDefId]
        } else {
          const trigger = await getTrigger(httpClient, tenantUuid, triggerDefId)
          trigger.condition = getTriggerCondition(trigger)
          record.trigger = trigger
          fetchTrigger[triggerDefId] = trigger
        }
        alertRecords.push(record)
      }
    }
  }
  return alertRecords
}

const fetchAlertApi = async (
  httpClient: IObject,
  xAccount: string,
  payload: IObject,
) => {
  const headers: IObject = {}
  if (xAccount) {
    headers['X-Account'] = xAccount
  }
  const { data: data } = await httpClient.post(
    '/analytics/event/hc_event?dummy=alertcount',
    payload,
    {
      absoluteBasePath: true,
      headers,
    },
  )
  /* istanbul ignore next */
  return data
}

/* istanbul ignore next */
export const getAlertTraffic = async (
  httpClient: IObject,
  selectedTimePeriod: IObject = {},
) => {
  let slotInterval = Math.floor(
    (selectedTimePeriod.endTime - selectedTimePeriod.startTime) / 100,
  )
  if (slotInterval < 60000) {
    slotInterval = 60000
  }
  const tenantUuids = basicUtils.getTenantUuids('|')
  const payload = {
    event: {
      fields: ['ts'],
      aggregation: 'count',
      sort: 'asc',
      rangeby: {
        start: Number(selectedTimePeriod.startTime),
        end: Number(selectedTimePeriod.endTime),
        field: 'ts',
      },
      groupby: 'severity',
      filterby: {
        and: {
          account_id: tenantUuids,
        },
      },
      field: 'ts',
      histogram: {
        interval: slotInterval,
        field: 'ts',
      },
      type: 'event',
    },
  }
  const stackChartArray = new Array()
  const severityCount = { total: 0, filters: {} }
  const tenants = basicUtils.getTenantUuids()
  try {
    const { event: eventData = {} } = await fetchAlertApi(
      httpClient,
      tenants,
      payload,
    )

    for (const severity in eventData) {
      if (eventData.hasOwnProperty(severity)) {
        const stackData = new Array()
        const severityDef = priorityMap[severity].definition
        for (const key in eventData[severity].ts_count) {
          if (eventData[severity].ts_count.hasOwnProperty(key)) {
            stackData.push([Number(key), eventData[severity].ts_count[key]])
            severityCount.filters[severityDef]
              ? (severityCount.filters[severityDef] +=
                  eventData[severity].ts_count[key])
              : (severityCount.filters[severityDef] =
                  eventData[severity].ts_count[key])
          }
        }
        const severityObj = {
          data: stackData,
          name: severityDef,
          color: priorityMap[severity].color,
        }
        stackChartArray.push(severityObj)
      }
    }
  } catch {
    // return default value
  }
  return { stackChartArray, severityCount }
}

export const getIndividualTenantAlert = async (
  httpClient: IObject,
  selectedTimePeriod: IObject = {},
  tenantList = [] as string[],
) => {
  let slotInterval = Math.floor(
    (selectedTimePeriod.endTime - selectedTimePeriod.startTime) / 100,
  )
  if (slotInterval < 60000) {
    slotInterval = 60000
  }
  // const tenantUuids = basicUtils.getTenantUuids('|')
  // const tenantList = (tenantUuids && tenantUuids.split('|')) || []
  // const promiseAll: any = []
  const data = {}
  const promiseAll = tenantList.map(async (val: string) => {
    const payload = {
      event: {
        fields: ['ts'],
        aggregation: 'count',
        sort: 'asc',
        rangeby: {
          start: Number(selectedTimePeriod.startTime),
          end: Number(selectedTimePeriod.endTime),
          field: 'ts',
        },
        groupby: 'severity',
        filterby: {
          and: {
            account_id: val || '',
          },
        },
        field: 'ts',
        histogram: {
          interval: slotInterval,
          field: 'ts',
        },
        type: 'event',
      },
    }
    const resp = await fetchAlertApi(httpClient, val, payload)
    const stackChartArray: IObject[] = []
    const severityCount = { total: 0, filters: {} }
    const { event: eventData = {} } = resp
    _.forIn(eventData, (severity, severityKey) => {
      if (severity) {
        const stackData: any = []
        const severityDef = (priorityMap[severityKey] || {}).definition
        _.forIn(severity.ts_count, (count, key) => {
          if (key) {
            stackData.push([Number(key), count])
            severityCount.filters[severityDef]
              ? (severityCount.filters[severityDef] += count)
              : (severityCount.filters[severityDef] = count)
          }
        })
        const severityObj = {
          data: stackData,
          name: severityDef,
          color: priorityMap[severityKey].color,
          severityCount,
        }
        stackChartArray.push(severityObj)
      }
    })
    data[val] = stackChartArray
    return data
  })
  await Promise.all(promiseAll)
  return data
}

export const alertEventsAPIs = (
  selectedTimePeriod: IObject,
  pager: IObject = { page: 1, pageSize: 20 },
  apiFilters: IObject = {},
) => {
  const hcEventApi = '/analytics/event/hc_event'
  const tenantUuids = basicUtils.getTenantUuids('|')
  const isTenantDashboard = basicUtils.isTenantDashboard()
  const isClusterDashboard = basicUtils.isClusterDashboard()
  const currentTenant = basicUtils.getCurrentTenant()
  const currentTenantId = currentTenant ? currentTenant.uuid : ''
  const currentClusterId = basicUtils.getCurrentClusterId()
  const clusterFilter = isClusterDashboard
    ? { cluster_id: currentClusterId }
    : {}
  const tenantFilter = isTenantDashboard ? { account_id: currentTenantId } : {}

  const filterQueries = getFilterQuery('alert')(
    selectedTimePeriod.startTime,
    selectedTimePeriod.endTime,
    { ...clusterFilter },
    { ...tenantFilter },
  )
  const page = pager.page || 1
  const pageSize = pager.pageSize || 20
  const sort = pager.sort || 'desc'
  const query = {
    logs: {
      size: pageSize,
      from: (page - 1) * pageSize,
      sort,
      filterby: {
        and: {
          account_id: tenantUuids,
          ...clusterFilter,
          ...tenantFilter,
          ...apiFilters,
        },
      },
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      type: 'event',
    },
    total: {
      filterby: {
        and: {
          account_id: tenantUuids,
          ...clusterFilter,
          ...apiFilters,
        },
      },
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: ['ts'],
      aggregation: 'count',
      type: 'event',
    },
    ...filterQueries,
  }

  return { hcEventApi, query, tenantUuids }
}

const analysisAlerts = async (
  httpClient: IObject,
  selectedTimePeriod: IObject,
  pager: IObject = { page: 1, pageSize: 20 },
  apiFilters: IObject = {},
) => {
  const hcEventApi = '/analytics/event/hc_event'
  const tenantUuids = basicUtils.getTenantUuids('|')
  const isTenantDashboard = basicUtils.isTenantDashboard()
  const isClusterDashboard = basicUtils.isClusterDashboard()
  const currentTenant = basicUtils.getCurrentTenant()
  const currentTenantId = currentTenant ? currentTenant.uuid : ''
  const currentClusterId = basicUtils.getCurrentClusterId()
  const clusterFilter = isClusterDashboard
    ? { cluster_id: currentClusterId }
    : {}
  const tenantFilter = isTenantDashboard ? { account_id: currentTenantId } : {}

  const filterQueries = getFilterQuery('alert')(
    selectedTimePeriod.startTime,
    selectedTimePeriod.endTime,
    { ...clusterFilter },
    { ...tenantFilter },
  )
  const page = pager.page || 1
  const pageSize = pager.pageSize || 20
  const sort = pager.sort || 'desc'
  const query = {
    logs: {
      size: pageSize,
      from: (page - 1) * pageSize,
      sort,
      filterby: {
        and: {
          account_id: tenantUuids,
          ...clusterFilter,
          ...tenantFilter,
          ...apiFilters,
        },
      },
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      type: 'event',
    },
    total: {
      filterby: {
        and: {
          account_id: tenantUuids,
          ...clusterFilter,
          ...apiFilters,
        },
      },
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: ['ts'],
      aggregation: 'count',
      type: 'event',
    },
    ...filterQueries,
  }
  const headers: IObject = {}
  if (tenantUuids) {
    headers['X-Account'] = tenantUuids
    const { data: events } = await httpClient.post(hcEventApi, query, {
      absoluteBasePath: true,
      headers,
    })

    const [logs, total, filterData, severity] = basicUtils.analysisData(events)
    const [objMapping, filterMapping] = await fetchClusterAndDevice(
      httpClient,
      logs,
      filterData,
    )
    const [appServiceFilterMapping] = fetchAppService(filterData)
    const alertRecords = await fetchAlertAndTrigger(httpClient, logs)
    return {
      // alerts: Object.values(events.logs),
      alerts: alertRecords,
      dataDisplay: alertRecords,
      // alerts: alertRecords.map((record: IObject) => {
      //   const {
      //     alert: { priority: priority, prefixName: prefixName },
      //   } = record

      //   record.priority = priority
      //   record.prefixName = prefixName
      //   return record
      // }),
      total,
      pager,
      filters: filterData,
      severity,
      objMapping,
      filterMapping: { ...filterMapping, ...appServiceFilterMapping },
    }
  } else {
    return {}
  }
}

export default analysisAlerts
