import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'

import { ApplicationConfigs } from 'src/constants/ApplicationConfigs'
import { AppRoot } from 'src/settings/appRoot'
import { Utilities } from 'src/services'
import { DeviceCLI } from 'src/containers/Controller/TroubleShoot/DeviceCLI'
import { ScaleoutTraffic } from 'src/containers/Controller/TroubleShoot/ScaleoutTraffic'
import { Backup } from 'src/containers/Controller/TroubleShoot/Backup'
import { ImageUpgrade } from 'src/containers/Controller/TroubleShoot/ImageUpgrade'
import { ObjectExplorer } from 'src/components/shared/ObjectExplorer'

export interface ITroubleShootProps extends IA10ContainerDefaultProps {
  clusterDeviceToggled: boolean
  tenantToggled: boolean
  selectedApp: string
  onSelectApp: (selectedApp: string) => void
  propsForLeftNavProviderTenantMenu?: IObject
}
export interface ITroubleShootState {
  application: IObject
  selectedTenant: IObject
}

class TroubleShoot extends A10Container<
  ITroubleShootProps,
  ITroubleShootState
> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  ApplicationConfigs = new ApplicationConfigs()
  AppRoot = new AppRoot()
  Utilities = new Utilities()

  state: ITroubleShootState = {
    application: {
      subApps: [],
      name: '',
    },
    selectedTenant: {},
  }

  componentDidMount() {
    const {
      storage: { remove },
    } = this.context

    remove.CONTEXT_LEVEL()
    remove.DGF_SELECTED_TENANT()
    remove.DGF_ACCOUNTS()
    remove.SELECTEDCONTEXT()
    remove.AUTH()
    remove.CONTEXT()

    this.updateState()
  }

  componentDidUpdate(prevProps: ITroubleShootProps) {
    const { tenantToggled, clusterDeviceToggled } = this.props
    if (
      prevProps.tenantToggled !== tenantToggled ||
      prevProps.clusterDeviceToggled !== clusterDeviceToggled
    ) {
      this.updateState()
    }
  }

  updateState() {
    const {
      storage: {
        get: { ADMIN_LEVEL, CURRENT_TENANT },
        remove,
        set,
      },
    } = this.context

    const { selectedApp, onSelectApp } = this.props
    const applications = this.ApplicationConfigs.getAvailableApps()
    const applicationIndex = applications.findIndex(key => {
      return key.name.toLowerCase() === 'utilities'
    })
    let selectedAppDetail = applications[applicationIndex].subApps.find(
      (subApp: any) => {
        return subApp.name.toLowerCase() === selectedApp.toLowerCase()
      },
    )

    if (!selectedAppDetail) {
      selectedAppDetail = applications[applicationIndex].subApps[0]
    }

    const allowedRoles = this.ApplicationConfigs.getAllowedRolesSubApps(
      'Utilities',
      selectedAppDetail.name,
    )
    if (allowedRoles) {
      const adminLevel = ADMIN_LEVEL
      const allowedIndex = allowedRoles.findIndex((key: any) => {
        return key === adminLevel
      })
      if (allowedIndex >= 0) {
        this.setState({
          application: applications[applicationIndex],
          selectedTenant: CURRENT_TENANT,
        })

        if (ADMIN_LEVEL === 'provider' && !selectedAppDetail.tenantContext) {
          remove.TENANT()
          remove.CURRENT_TENANT()
          set.DRILL_LEVEL('provider')
          this.AppRoot.setRootScopeElement('currentDrillLevel', 'provider')
        } else {
          set.DRILL_LEVEL('tenant')
          this.AppRoot.setRootScopeElement('currentDrillLevel', 'tenant')
        }

        remove.SUPER_ADMIN_MODE()
        set.CURRENT_STATE('utilities')
        this.AppRoot.setRootScopeElement('currentState', 'utilities')

        if (selectedAppDetail.name !== selectedApp) {
          if (onSelectApp instanceof Function) {
            onSelectApp(selectedAppDetail.name)
          }
        }

        return
      }
    }
    this.Utilities.showMessage('INSUFFICENT_PRIVILEGE', 0, 1)
  }

  render() {
    const {
      clusterDeviceToggled,
      propsForLeftNavProviderTenantMenu,
    } = this.props
    const { selectedTenant } = this.state
    const tenantName = selectedTenant?.name || ''
    const selectedApp = this.props.selectedApp.toLowerCase()

    switch (selectedApp) {
      case 'devicecli': {
        return <DeviceCLI tenantName={tenantName} />
      }
      case 'scaleouttraffic': {
        return <ScaleoutTraffic clusterDeviceToggled={clusterDeviceToggled} />
      }
      case 'devicebackup': {
        return <Backup />
      }
      case 'imageupgrade': {
        return <ImageUpgrade />
      }
      case 'objectexplorer': {
        return (
          <ObjectExplorer
            propsForLeftNavProviderTenantMenu={
              propsForLeftNavProviderTenantMenu
            }
          />
        )
      }
      default: {
        return null
      }
    }
  }
}

export default setupA10Container(TroubleShoot)
