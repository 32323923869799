import { A10Collapse, A10Icon, A10Row } from '@gui-libraries/widgets'
import React from 'react'
import * as _ from 'lodash'

export interface IRoutingBFDProps {
  configured: IObject
  bfd: IObject
}
const RoutingBFD: React.FC<IRoutingBFDProps> = props => {
  const { bfd, configured } = props

  return (
    <div className="col-md-12 marginBottom10">
      <A10Collapse>
        <A10Collapse.Panel
          key="roting-bdf"
          showArrow={false}
          disabled={true}
          header={
            <>
              <span className="edit-content">
                <A10Icon app="global" type="edit" /> Edit
              </span>
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 fontWeight300 width180">BFD</span>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Echo</div>
                  <div className="pad-s-15 fontWeight300">
                    {bfd.echo ? 'Enable' : 'Disable'}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Global Timers</div>
                  <div className="pad-s-15 fontWeight300">
                    {_.get(bfd, 'interval-cfg.interval', 0)} ms
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">BGP</div>
                  <div className="pad-s-15 fontWeight300">
                    {configured.bgp ? 'Configured' : 'N/A'}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">OSPF</div>
                  <div className="pad-s-15 fontWeight300">
                    {configured.ospf ? 'Configured' : 'N/A'}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">IS-IS</div>
                  <div className="pad-s-15 fontWeight300">
                    {configured.isis ? 'Configured' : 'N/A'}
                  </div>
                </div>
              </A10Row>
            </>
          }
          className="collapsePanel"
        />
      </A10Collapse>
    </div>
  )
}

export default RoutingBFD
