import React, { useCallback, useEffect, useState } from 'react'
import { getItem } from '@gui-libraries/framework'

import {  Utilities } from 'src/services'
import parameters from 'parameters'
import AppConfigs from 'src/constants/ApplicationConfigs/AppConfigs'
import './styles/index.less'
import { A10Row } from '@gui-libraries/widgets'

const AppsList: React.FC<any> = props => {
  // const { startTime, endTime, service } = props
  const [allApps, setAllApps] = useState([])
  const [launchApp, setLaunchApp] = useState(false)
  const [launchAppObj, setLaunchAppObj] = useState({
    showSlidingPage: false,
    tenant: {},
    appService: {},
    app: {},
  })

  
  const appConfigs = new AppConfigs()
  const options = [
    'adc',
    'lightning',
    'cgn',
    'ssli',
    'gtp',
    'gifw',
    'object-explorer',
  ]
  const utilities = new Utilities()

  const init = useCallback(async () => {
    try {
      const targetUrl = getItem('TARGET_URL')
      const allAppsData = await utilities.getLicensedApps(targetUrl)
      setAllApps(allAppsData)
    } catch (err) {}
  }, [])

  useEffect(() => {
    init()
  }, [init])

  const openApp = app => {
    let launchAppObj1 = launchAppObj
    launchAppObj1.app = app

    launchAppObj1.showSlidingPage = true
    setLaunchAppObj(launchAppObj1)
    setLaunchApp(true)
    console.log(launchAppObj)
  }



  const setSlidingPage = (isOpen: boolean) => {
    let launchAppObj1 = launchAppObj
    launchAppObj1.showSlidingPage = isOpen
    setLaunchAppObj(launchAppObj1)
    setLaunchApp(isOpen)
  }

  const handleChange = (data: any) => {
    console.log('handleChange')
    console.log(data)

    let launchAppObj1 = launchAppObj
    launchAppObj1.app = data.app
    launchAppObj1.appService = data.appService
    launchAppObj1.tenant = data.tenant
    setLaunchAppObj(launchAppObj1)
  }

  const launchAppURLPage = async (appObjData: any) => {
    const providerName = getItem('PROVIDER')

    appObjData.tenant =
      typeof appObjData.tenant == 'string' && appObjData.tenant
        ? JSON.parse(appObjData.tenant)
        : appObjData.tenant
    const tenantObj = appObjData.tenant?.uuid
      ? appObjData.tenant
      : getItem('CURRENT_TENANT')
    const appSvcObj =
      typeof appObjData.appService == 'string' && appObjData.appService
        ? JSON.parse(appObjData.appService)
        : appObjData.appService

    const sessionTimeout = getItem('SESSION_TIMEOUT_INITIAL')
    const currTimestamp = new Date().getTime()
    const ipv6Url = utilities.checkIPv6BaseURL()
    const baseURL = ipv6Url
      ? parameters.BASE_URL.replace(':8443/api/v2', '') + encodeURI('/hcapps')
      : encodeURI(parameters.BASE_URL.replace(':8443/api/v2', '') + '/hcapps')
    const sessionId = await utilities.getSessionId()
    const queryString =
      '?user_id=' +
      getItem('USER_ID') +
      '&api_ep=' +
      encodeURI(parameters.BASE_URL) +
      '&provider=' +
      providerName +
      '&token=' +
      sessionId +
      '&timeout=' +
      (sessionTimeout ? sessionTimeout : '1') +
      (tenantObj.name ? '&tenant=' + tenantObj.name : '') +
      (tenantObj.uuid ? '&tenantUUID=' + tenantObj.uuid : '') +
      (appSvcObj.app_svc_id
        ? '&app_svc=' +
          appSvcObj.name +
          '&app_service_id=' +
          appSvcObj.app_svc_id +
          '&app_svc_type=' +
          appSvcObj.app_svc_type +
          '&action=config'
        : '')

    const href = `${baseURL}/${appObjData.app.name}_${appObjData.app.version}${queryString}&ts=${currTimestamp}`

    window.open(href, '_blank')

    setLaunchApp(false)
  }

  const optionList = appConfigs.APPS.filter((item: any) => {
    return options?.some(option => {
      return (
        option === item.key &&
        allApps?.some(app => {
          return item?.appName?.includes(app.name)
        })
      )
    }) 
  })

  return (
    <>
      <div className="app-list">
        {optionList?.length > 0 ? optionList.map((appObj: any, index: number) => {
          const filteredApps = allApps.filter((app: any) => {
            let appExists = false
            if (appObj.appName && appObj.appName.length > 0) {
              appExists = appObj.appName.some((appName: string) => {
                if (appName === app.name) {
                  return true
                }
                return false
              })
            }
            return appObj.key === app.name || appExists
          })

          filteredApps.sort((a: any, b: any) => {
            return utilities.sortAppVersionNumber(
              a?.version?.split('-')[0]?.split('.') || [0],
              b?.version?.split('-')[0]?.split('.') || [0],
            )
          })
          filteredApps.sort((a: any, b: any) => {
            return utilities.sortAppVersionNumber(
              a?.version?.split('v')[1]?.split('.') || [0],
              b?.version?.split('v')[1]?.split('.') || [0],
            )
          })

          const app = filteredApps?.[0] || {}

          return (
            <div className="app-name" onClick={() => openApp(app)}>
              {utilities.renderAppIcon(app?.name)}
              <span style={{ marginLeft: '5px' }}>
                {app?.metadata?.short_name}
              </span>
            </div>
          )
        }) : <A10Row>No licensed apps are available</A10Row>}
      </div>
      {launchApp
        ? utilities.openSlidingApp(
            launchAppObj?.showSlidingPage,
            launchAppObj.app,
            launchAppObj.app?.version || '',
            setSlidingPage,
            launchAppObj,
            launchAppURLPage,
            handleChange,
          )
        : null}
    </>
  )
}

export default AppsList
