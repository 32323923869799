import React from 'react'
import {
  A10Component,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Chart, A10Tag, A10Collapse } from '@gui-libraries/widgets'
import { Messages } from 'src/locale/en'
import { Condition } from 'src/components/shared/Condition'
import { Utilities } from 'src/services/Utilities'
import { DashboardService } from 'src/services/DashboardService'
import { RoundNumber } from 'src/components/shared/RoundNumber'
import moment from 'moment'
import './styles/alertdetails.scss'
import storage from 'src/libraries/storage'
import { InfrastructureService } from 'src/services/InfrastructureService'

export const CHART_CONFIG: any = {
  chart: {
    type: 'column',
  },
  xAxis: {
    type: 'datetime',
    min: moment()
      .subtract(30, 'minutes')
      .valueOf(),
    max: moment().valueOf(),
  },
  series: [
    {
      name: 'Actions',
      data: [],
      color: '#ff9090',
    },
  ],
}

export const WIDGET_CONFIG: any = {
  fields: [
    {
      key: 'rule_assoc_id',
      label: 'Alerts',
    },
  ],
  aggregation: 'count',
  histogramField: 'ts',
  rangebyField: 'ts',
  filterBy: {
    and: {
      rule_assoc_id: '',
    },
  },
}

export interface IAlertDetailsObj {
  defId: string
  alertName: string
  alertDesc: string
  priority: string
  tags: any[]
  objectType: string
  objInstance: any
  trigger: any
  triggerName: string
  condition: any
  triggerMessage: string
  actions: any
  actionName: string
  emailAlert: string
  subject: string
  actionMessage: string
  hierarchyTree: any
  treeLength: number
  hcEventDetails: any
}
export interface IAlertDetailsProps extends IA10ContainerDefaultProps {
  alert: any
}
export interface IAlertDetailsState {
  chartConfig: any
  alert: any
  partitionName: string
  alertAction: any
}

const WIDGET_CONFIG_HC_EVENT_ALL_DETAILS: any = {
  sort: 'desc',
  size: 1,
  rangebyField: 'ts',
  filterBy: {
    and: {
      rule_assoc_id: '',
    },
  },
}

const colorMapping = {
  0: { color: '#e57373', definition: 'Emergency' },
  1: { color: '#e57373', definition: 'Alert' },
  2: { color: '#e57373', definition: 'Critical' },
  3: { color: '#e57373', definition: 'Error' },
  4: { color: '#ffb74d', definition: 'Warning' },
  5: { color: '#81c784', definition: 'Notification' },
  6: { color: '#64b5f6', definition: 'Info' },
  7: { color: '#ffe168', definition: 'Debug' },
}

class AlertDetails extends A10Component<
  IAlertDetailsProps,
  IAlertDetailsState
> {
  static getDerivedStateFromProps(
    nextProps: IAlertDetailsProps,
    prevState: IAlertDetailsState,
  ) {
    const alert = nextProps.alert
    const trigger = alert.triggerObj

    return {
      alert: {
        defId: alert.def_id,
        alertName: alert.def_name,
        alertDesc: alert.description,
        createdBy: alert.account_id,
        dateCreatedBy: alert.created_on,
        priority: alert.definition.severity,
        tags: alert.definition.tags || [],
        objectType:
          trigger && trigger.definition ? trigger.definition.object_type : '',
        objInstance:
          alert.definition &&
          alert.definition.filter &&
          alert.definition.filter.cluster_id
            ? {
                cluster_id: alert.definition.filter.cluster_id,
                device_id: alert.definition.filter.device_uuid,
                partition_id: alert.definition.filter.p_id,
              }
            : null,
        trigger: alert.triggerObj,
        triggerName: trigger ? trigger.def_name : '',
        category:
          trigger && trigger.definition ? trigger.definition.category : null,
        subCategory:
          trigger && trigger.definition
            ? trigger.definition['sub-category']
            : null,
        window:
          trigger && trigger.definition ? trigger.definition.window : null,
        condition:
          trigger && trigger.definition ? trigger.definition.conditions : null,
        triggerMessage:
          trigger && trigger.definition
            ? trigger.definition.trigger_message
            : '',
        hierarchyTree: alert.definition && alert.definition.hierarchyTree,
        actionFrequency: alert.definition && alert.definition.action_frequency,
        hcEventDetails: alert.hcEventDetails,
        treeLength:
          alert.definition && alert.definition.hierarchyTree
            ? Object.keys(alert.definition.hierarchyTree).length
            : 0,
      },
    }
  }

  Messages = new Messages()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  InfrastructureService = new InfrastructureService()
  clusters: Array<any> = []
  partitionName: IObject
  alert: IAlertDetailsObj = {} as IAlertDetailsObj
  dynamicTriggerMapping = {
    '<pool_name>': 'pool_name',
    '<device_cluster>': 'device_cluster',
    '<app_svc>': 'app_svc',
  }
  state: IAlertDetailsState = {
    chartConfig: CHART_CONFIG,
    alert: {},
    partitionName: '',
    alertAction: {},
  }

  constructor(props: any) {
    super(props)
  }

  getChartData(alert: any) {
    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(
      false,
      true,
    )
    if (!headers) {
      return
    }

    const now: any = moment().valueOf()
    const startTime = moment()
      .subtract(1, 'days')
      .valueOf()
    const query = this.Utilities.buildQuery(WIDGET_CONFIG, startTime, now)
    const hcEventDetails = this.Utilities.buildQuery(
      WIDGET_CONFIG_HC_EVENT_ALL_DETAILS,
      startTime,
      now,
    )
    const tenant = this.Utilities.getCurrentDrillLevelObject()
    hcEventDetails.filterby.and.rule_assoc_id = alert.defId
    hcEventDetails.filterby.and.account_id = tenant.uuid
    hcEventDetails.type = 'event'
    if (hcEventDetails.aggregation) {
      // For this query we dont need aggregation
      hcEventDetails.aggregation = undefined
    }
    query.filterby.and.rule_assoc_id = alert.defId
    query.filterby.and.account_id = tenant.uuid
    query.type = 'event'
    const eventQuery = {
      event: query,
      hcEventDetails,
    }

    const eventsResp = this.DashboardService.getHCEvents(
      headers,
      eventQuery,
      null,
    )

    eventsResp
      .then((resp: any) => {
        const chartData = this.Utilities.parseChartData(
          resp.data['event'],
          WIDGET_CONFIG,
        )
        if (resp.data['hcEventDetails']) {
          const keys = Object.keys(resp.data['hcEventDetails'])
          if (keys.length === 1) {
            alert.hcEventDetails = resp.data['hcEventDetails'][keys[0]]
          }
        }

        const timePeriod = [startTime, now]

        const chartConfig = JSON.parse(JSON.stringify(CHART_CONFIG))

        if (chartData && chartData.length > 0 && timePeriod) {
          if (alert.priority >= 0) {
            chartData[0].color = colorMapping[alert.priority].color
          }

          chartConfig.series = chartData
          chartConfig.xAxis.min = timePeriod[0]
          chartConfig.xAxis.max = timePeriod[1]

          this.setState({
            chartConfig,
          })
        } else {
          this.setState({
            chartConfig: JSON.parse(JSON.stringify(CHART_CONFIG)),
          })
        }
      })
      .catch((error: any) => {})
  }

  componentDidMount() {
    this.getChartData(this.state.alert)
    this.getAction(this.props.alert)
    this.getPartionName()
  }

  getAction = (alertObj: any) => {
    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }

    let promises: any = []
    // this.state.allData.forEach((alertObj: any) => {
    alertObj.definition.action_def_id.map((actionId: any) => {
      const actionRes = this.DashboardService.getAction(headers, null, [
        actionId,
      ])
      promises.push(actionRes)
    })

    Promise.all(promises)
      .then((respArr: any) => {
        let actionsObj: any = []
        alertObj.definition.action_def_id.map((actionId: any) => {
          respArr.map((resp: any, index: number) => {
            if (resp.data && resp.data.def_id === actionId) {
              if (resp.data) {
                actionsObj.push(resp.data)
              }
            }
          })
        })

        const alertAction = {
          actions: actionsObj || [],
        }

        this.setState({
          alertAction: alertAction,
        })
      })
      .catch((error: any) => {
        console.log('error in gettinhg action data', error)
      })
  }

  onClickMenu = (chart: any) => {
    const chartConfig = this.state.chartConfig
    chartConfig.chart.type = chart.type
    this.setState({
      chartConfig,
    })
  }

  renderPriority(priority: string): any {
    const priorityObj = this.Utilities.getVal(priority, 'ALERTS', 'priority')
    return priorityObj.label
  }

  renderDateCreated(date: any) {
    const dateObj = this.Utilities.formatDate(date, 'MM/DD/YYYY')
    return dateObj
  }

  renderTriggerMessage(message: string) {
    const hcEventDetails = this.state.alert.hcEventDetails
    if (message && hcEventDetails && Object.keys(hcEventDetails).length > 0) {
      const keys = Object.keys(this.dynamicTriggerMapping)
      keys.forEach(key => {
        if (message.indexOf(key) > -1) {
          message = message.replace(
            key,
            hcEventDetails[this.dynamicTriggerMapping[key]],
          )
        }
      })
      return message
    } else {
      return this.Utilities.returnValue(message)
    }
  }

  renderCreatedBy(uuid: any) {
    const tenantObj = this.Utilities.getCurrentObjectByUUID(uuid)
    return tenantObj['display-name']
  }

  convertObjToString(headers: any) {
    return this.Utilities.convertObjToString(headers)
  }

  getPartionName = async () => {
    if (this.state.alert?.objInstance) {
      const [clusterId] = this.state.alert?.objInstance?.cluster_id;
      const clusterList = storage.get.ALLCLUSTERS

      const clusterName =
        clusterList?.find(
          item => item.uuid === clusterId,
        )?.name ?? ''

      if (clusterName) {
        const res = await this.InfrastructureService.getCluster(
          storage.get.ADMIN_LEVEL === 'provider'
            ? null
            : this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false, true),
          null,
          [storage.get.PROVIDER, clusterName],
        )
        const _cluster = res?.data?.cluster || {}

        const {
          'partition-list': partitionList
        } = _cluster

        const partition = partitionList?.find((record: IObject) => {
          return this.state.alert.objInstance.partition_id?.[0] === record.id
        }) ?? {}
        this.setState({
          partitionName: partition?.name ?? "",
        })
      }
    }
  }

  render() {
    const { alert, partitionName, alertAction } = this.state
    return (
      <div className="row rowDetails">
        <div className="col-md-12">
          <A10Collapse
            bordered={false}
            defaultActiveKey={['1']}
            destroyInactivePanel={true}
          >
            <A10Collapse.Panel header="Occured" key="1" className="no-border">
              {this.state.chartConfig ? (
                <A10Chart.Detail
                  chartConfig={this.state.chartConfig}
                  series={this.state.chartConfig.series}
                  showSettingIcon={false}
                  showChartHeaderMenu={false}
                  showChartIcons={false}
                  type="column"
                  types={['column']}
                  counters={{
                    total: 0,
                    filters: {},
                  }}
                  onClickMenu={this.onClickMenu}
                  title=""
                  height={200}
                  disableFilter={true}
                  noDataDetails={this.Messages.NO_DATA_FOR_LAST_24_HOURS}
                />
              ) : null}
            </A10Collapse.Panel>
          </A10Collapse>

          <A10Collapse bordered={false} defaultActiveKey={['1']}>
            <A10Collapse.Panel
              header="Alert Details"
              key="1"
              className="no-border"
            >
              <div className="row section block-sec">
                <div className="block">
                  <span className="title">{this.Messages.ALERT_NAME}</span>
                  <span className="monitor-value">{alert.alertName}</span>
                </div>
                <div className="block">
                  <span className="title">{this.Messages.DESCRIPTION}</span>
                  <span className="monitor-value">{alert.alertDesc}</span>
                </div>
                <div className="block">
                  <span className="title">Partition Name</span>
                  <span className="monitor-value">{partitionName}</span>
                </div>
                <div className="block">
                  <span className="title">{this.Messages.PRIORITY}</span>
                  <span className="monitor-value">
                    {this.renderPriority(alert.priority)}
                  </span>
                </div>
                <div className="block">
                  <span className="title">{this.Messages.TAGS}</span>
                  <span className="monitor-value">
                    {alert.tags.map((tag: any, i: number) => {
                      return <A10Tag key={'tag' + i}>{tag}</A10Tag>
                    })}
                  </span>
                </div>
                <div className="block">
                  <span className="title">
                    {this.Messages.APP_DATE_CREATED}
                  </span>
                  <span className="monitor-value">
                    {this.renderDateCreated(alert.dateCreatedBy)}
                  </span>
                </div>
                <div className="block">
                  <span className="title">
                    {this.Messages.ALERT_CREATED_BY}
                  </span>
                  <span className="monitor-value">
                    {this.renderCreatedBy(alert.createdBy)}
                  </span>
                </div>
              </div>
              {alert.hierarchyTree &&
              Object.keys(alert.hierarchyTree).length > 0 ? (
                <div className="row section block-sec">
                  <div className="block">
                    <span className="title">{this.Messages.OBJECT_TYPE}</span>
                    <span className="monitor-value">{alert.objectType}</span>
                  </div>
                  <div className="block">
                    <span className="title">{this.Messages.OBJ_INS}</span>
                    <span
                      className="monitor-value"
                      style={{ fontStyle: 'italic' }}
                    >
                      {Object.keys(alert.hierarchyTree).map(
                        (tag: any, i: number) => {
                          return (
                            <span key={'hierarchy' + i}>
                              {alert.hierarchyTree[tag]}{' '}
                              {alert.treeLength - 1 !== i ? ' > ' : null}
                            </span>
                          )
                        },
                      )}
                    </span>
                  </div>
                </div>
              ) : null}
              {/* <h1>Line 259</h1> */}
              <div className="row section">
                <div className="block">
                  <span className="title">
                    {this.Messages.NOTIFICATION_FREQUENCY}
                  </span>
                  <span className="monitor-value">
                    {alert.actionFrequency?.count_threshold
                      ? `Once for every ${alert.actionFrequency.count_threshold} time it occurs`
                      : alert.actionFrequency?.interval
                      ? `Once every ${alert.actionFrequency.interval} minutes`
                      : this.Messages.NOTIFICATION_EVERY_TIME}
                  </span>
                </div>
              </div>
              <div className="row section">
                <div className="block">
                  <span className="title">{this.Messages.TRIGGER_NAME}</span>
                  <span className="monitor-value">
                    {this.Utilities.returnValue(alert.triggerName)}
                  </span>
                </div>
                <div className="block">
                  <span className="title">{this.Messages.CATEGORY_TYPE}</span>
                  <span className="monitor-value">
                    {this.Utilities.returnValue(alert.category)}
                  </span>
                </div>
                <div className="block">
                  <span className="title">
                    {this.Messages.SUBCATEGORY_TYPE_DOT}
                  </span>
                  <span className="monitor-value">
                    {this.Utilities.returnValue(alert.subCategory)}
                  </span>
                </div>
                <div className="block">
                  <span className="title">{this.Messages.OBJECT_TYPE}</span>
                  <span className="monitor-value">
                    {this.Utilities.returnValue(alert.objectType)}
                  </span>
                </div>
              </div>
              <div className="row section">
                {alert.window && alert.window.type ? (
                  <>
                    <div className="block">
                      <span className="title">{this.Messages.WINDOW_TYPE}</span>
                      <span className="monitor-value">
                        {alert.window &&
                          this.Utilities.returnWindowType(alert.window.type)}
                      </span>
                    </div>
                    <div className="block">
                      <span className="title">{this.Messages.WINDOW_SIZE}</span>
                      <span className="monitor-value">
                        {alert.window &&
                          this.Utilities.returnValue(alert.window.params[0])}
                      </span>
                    </div>
                  </>
                ) : null}

                {alert.window && alert.window.type === 'timeLength' ? (
                  <div className="block">
                    <span className="title">{this.Messages.MAX_SAMPLES}</span>
                    <span className="monitor-value">
                      {this.Utilities.returnValue(alert.window.params[1])}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="row section block-sec">
                <div className="block">
                  <span className="title">{this.Messages.CONDITION}</span>
                  <span className="monitor-value">
                    <Condition
                      condition={alert.condition}
                      metadata={alert?.trigger?.metadata}
                      header={false}
                    />
                  </span>
                </div>
              </div>

              <div className="row section block-sec">
                <div className="block">
                  <span className="title">{this.Messages.TRIGGER_MESSAGE}</span>
                  <span className="monitor-value">
                    {this.renderTriggerMessage(alert.triggerMessage)}
                  </span>
                </div>
              </div>

              <div className="row section no-border">
                <div className="block">
                  <span className="title">
                    {
                      <>
                        Actions
                        <RoundNumber number={alertAction?.actions?.length} />
                      </>
                    }
                  </span>
                </div>
              </div>
              {alertAction?.actions?.length > 0
                ? alertAction?.actions?.map((action: any, index: number) => {
                    const defData = action.definition.email[0] || ''
                    return (
                      <div className="section no-border" key={'action' + index}>
                        <div className="row section padLft">
                          <span className="title">
                            {this.Messages.ACTION} {index + 1}
                          </span>
                          <span className="padLft">
                            <>{action.def_name && action.def_name?.split('A10')?.[0]}</>
                          </span>
                        </div>
                        <div className="row section no-border">
                          <div className="padding0">
                            <div className="inline-block">
                              <span className="title">
                                {this.Messages.EMAIL_ALERT_TO}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-10 flex-wrap">
                            <div className="inline-block">
                              <span
                                className="monitor-value"
                                title={this.Utilities.returnValue(
                                  defData.email_ids
                                    ? defData.email_ids.length > 1
                                      ? defData.email_ids.join(', ')
                                      : defData.email_ids
                                    : '',
                                )}
                              >
                                {this.Utilities.returnValue(
                                  defData.email_ids
                                    ? defData.email_ids.length > 1
                                      ? defData.email_ids.join(', ')
                                      : defData.email_ids
                                    : '',
                                )}
                              </span>
                            </div>
                            <div className="inline-block">
                              <span className="title">
                                {this.Messages.SUBJECT}
                              </span>
                              <span
                                className="monitor-value"
                                title={this.Utilities.returnValue(
                                  defData.extended_subject,
                                )}
                              >
                                {this.Utilities.returnValue(
                                  defData.extended_subject,
                                )}
                              </span>
                            </div>
                            <div className="inline-block">
                              <span className="title">
                                {this.Messages.MESSAGE}
                              </span>
                              <span
                                className="monitor-value"
                                title={this.Utilities.returnValue(
                                  defData.extended_message,
                                )}
                              >
                                {this.Utilities.returnValue(
                                  defData.extended_message,
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        {alertAction?.actions[index].definition.webhook.length >
                        0 ? (
                          alertAction?.actions[index].definition.webhook.map(
                            (webhook: any, index: number) => {
                              return (
                                <div
                                  className="section no-border"
                                  key={'webhook' + index}
                                >
                                  <span className="title padRgt">
                                    {this.Messages.WEBHOOK} {index + 1}
                                  </span>
                                  <div className="inline-block">
                                    <span className="title">
                                      {this.Messages.POST_TO_URI}
                                    </span>
                                    <span
                                      className="monitor-value"
                                      title={this.Utilities.returnValue(
                                        webhook.uri,
                                      )}
                                    >
                                      {this.Utilities.returnValue(webhook.uri)}
                                    </span>
                                  </div>
                                  <div className="inline-block">
                                    <span className="title">
                                      {this.Messages.HEADERS}
                                    </span>
                                    <span
                                      className="monitor-value"
                                      title={this.Utilities.returnValue(
                                        webhook.headers,
                                      )}
                                    >
                                      {this.Utilities.returnValue(
                                        this.convertObjToString(
                                          webhook.headers,
                                        ),
                                      )}
                                    </span>
                                  </div>
                                  <div className="inline-block">
                                    <span className="title">
                                      {this.Messages.EXTENDED_BODY}
                                    </span>
                                    <span
                                      className="monitor-value"
                                      title={this.Utilities.returnValue(
                                        webhook.extended_body,
                                      )}
                                    >
                                      {this.Utilities.returnValue(
                                        webhook.extended_body,
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )
                            },
                          )
                        ) : (
                          <div
                            className="row section no-border"
                            key={'webhook'}
                          >
                            <div className="col-md-1 padding0">
                              <div className="inline-block">
                                <span className="title">
                                  {this.Messages.WEBHOOK}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-11 flex-wrap">
                              <div className="inline-block">
                                <span className="title">
                                  {this.Messages.POST_TO_URI}
                                </span>
                                <span className="monitor-value">
                                  {this.Messages.NONE}
                                </span>
                              </div>
                              <div className="inline-block">
                                <span className="title">
                                  {this.Messages.HEADERS}
                                </span>
                                <span className="monitor-value">
                                  {this.Messages.NONE}
                                </span>
                              </div>
                              <div className="inline-block">
                                <span className="title">
                                  {this.Messages.EXTENDED_BODY}
                                </span>
                                <span className="monitor-value">
                                  {this.Messages.NONE}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  })
                : null}
            </A10Collapse.Panel>
          </A10Collapse>
        </div>
      </div>
    )
  }
}
export default AlertDetails
