import React, { useCallback, useState } from 'react'
import { A10Icon, A10Loader, A10Tooltip } from '@gui-libraries/widgets'
import { useAsyncEffect, _ } from '@gui-libraries/framework'

import storage from 'src/libraries/storage'
import { InfrastructureService } from 'src/services'
import './styles/index.less'

const prefixCls = 'dgf-top-indicators'

const infrastructureService = new InfrastructureService()
const TopIndicators: React.FC<any> = props => {
  const {
    viz: { displayProperties },
    thresholdArr,
    selectedContext,
  } = props
  const {
    name,
    toolTip,
    hideName,
    threshold: defaultThreshold,
    inverse: isInverse,
    subLabel,
    id,
  } = displayProperties
  const showSeriesName = !hideName

  const [series, setSeries] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const refContainer = React.createRef<HTMLDivElement>()

  useAsyncEffect(async () => {
    await fetchData()
  }, [id])

  const fetchData = useCallback(async () => {
    switch (id) {
      case 'active_app_svcs': {
        try {
          setIsLoading(true)
          const res = await infrastructureService.getActiveAppServiceCount(
            {
              provider: storage.get.PROVIDER,
              'x-account': storage.get.CURRENT_TENANT?.uuid ?? '',
            },
            [],
          )

          const formattedSeries = [
            {
              name: 'Active App Services',
              hideName: false,
              data: [res?.data || 0],
              units: '',
            },
          ]

          setSeries(formattedSeries)
        } catch (e) {
          setSeries([{
            name: 'Active App Services',
            hideName: false,
            data: null,
            units: '',
          }])
        } finally {
          setIsLoading(false)
        }

        break
      }
      case 'logical_partition_count': {
        try {
          setIsLoading(true)
          const res = await infrastructureService.getLogicalPartitionCount(
            {
              provider: storage.get.PROVIDER,
            },
            [storage.get.PROVIDER, selectedContext?.name],
          )

          const formattedSeries = [
            {
              name: 'Logical Partition',
              hideName: false,
              data: [res?.data?.['total-count'] || 0],
              units: '',
            },
          ]

          setSeries(formattedSeries)
        } catch (e) {
        } finally {
          setIsLoading(false)
        }

        break
      }
      default: {
        break
      }
    }
  }, [])

  const getSuffixClass = useCallback(
    (value: number, threshold = [1000000, 2000000], isInverse = false) => {
      if (
        (isInverse && value < threshold?.[0]) ||
        (!isInverse && value > threshold?.[1])
      ) {
        return 'danger'
      }
      if (value > threshold?.[0] && value < threshold?.[1]) {
        return 'warning'
      }
      return 'success'
    },
    [],
  )

  const getThreshold = useCallback(
    (index: number): number[] => {
      return _.get(thresholdArr, `[${index}].threshold`, defaultThreshold)
        .split(',')
        .map(Number)
    },
    [thresholdArr, defaultThreshold],
  )

  return (
    <span ref={refContainer}>
      {isLoading && <A10Loader container={refContainer} iconOnly />}
      {!isLoading && (
        <A10Tooltip title={toolTip} placement="bottom">
          <div className={prefixCls}>
            <div className={`${prefixCls}-data`}>
              {series.map((s, index) => {
                const threshold = getThreshold(index)
                const suffixCls = getSuffixClass(
                  s?.data[0],
                  threshold,
                  isInverse,
                )
                // const suffixCls = 'success'
                const [value, unit] = s?.data?.length
                  ? [s.data[0], '']
                  : [0, '']
                const displayNum = Number(value)
                const displayText = Number.isInteger(displayNum)
                  ? displayNum
                  : displayNum.toFixed(1)

                return (
                  <>
                    <span className={`${prefixCls}-number-${suffixCls}`}>
                      {s.icon ? (
                        <A10Icon
                          className={`${prefixCls}-icon`}
                          type={s.icon.toLowerCase()}
                        />
                      ) : null}
                      {displayText}{' '}
                    </span>
                    <span className={`${prefixCls}-unit-${suffixCls}`}>
                      {unit}
                      {s.units}{' '}
                    </span>
                    {series.length > 1 && index < series.length - 1 ? (
                      <span className={`${prefixCls}-d-divider`}>|</span>
                    ) : (
                      ''
                    )}
                  </>
                )
              })}
            </div>
            <div className={`${prefixCls}-label`}>
              {showSeriesName
                ? series.map((s, index) => {
                    return (
                      <>
                        {!s.hideName ? (
                          <>
                            <span>{s.name}</span>
                            {series.length > 1 && index < series.length - 1 ? (
                              <span className={`${prefixCls}-s-divider`}>
                                |
                              </span>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    )
                  })
                : `${name}${subLabel || ''}`}
            </div>
          </div>
        </A10Tooltip>
      )}
    </span>
  )
}
export default TopIndicators
