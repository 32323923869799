import React from 'react'
import {
  A10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Message } from '@gui-libraries/widgets'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import UploadForm, { IFormData } from './index'
import storage from 'src/libraries/storage'
import globalConfig from 'src/settings/config'

export interface ISlidingUploadProps extends IA10ContainerDefaultProps {
  isOpen: boolean
  onRequestClose: () => void
  onRequestOk: (payload:IObject, file:IObject) => void
}

export interface ISlidingUploadStates {
  provider: string
  formData?: IFormData
  isUploading: boolean
}

class SlidingUpload extends A10Container<
  ISlidingUploadProps,
  ISlidingUploadStates
> {
  childForm: any = null
  cancelTokenSrc: any = null
  constructor(props: ISlidingUploadProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER,
      isUploading: false,
    }
  }

  onFormChange(data: IFormData, isEdit: boolean) {
    const state = { ...this.state }
    state.formData = data
    this.setState(state)
  }

  saveUploadSlidingPage = async () => {
    let isValid = true
    this.childForm.props.form.validateFieldsAndScroll((errors, values) => {
      if (errors) {
        isValid = false
      }
    })
    if (!isValid) {
      return
    }
    const {
      EPIC_DEPENDENCIES: { httpClient },
    } = globalConfig
    const { provider } = this.state
    if (
      this.state.formData.file.name.indexOf('.tgz') === -1 &&
      this.state.formData.file.name.indexOf('.upg') === -1
    ) {
      A10Message.error('Please upload a valid image')
      return
    }
  
    const payload = {
      name: this.state.formData.file.name,
      version: this.state.formData.version,
      description: this.state.formData.description,
      'file-handle': this.state.formData.file.name,
    }
    
    try {
      this.setState({ isUploading: true })
      const currentDate = Math.floor(Date.now() / 1000)

      A10Message.loading(`Updating image file data ${this.state.formData.file.name}`, 0, ()=>{})
        const hpcUrl = storage.get.SUPER_ADMIN_MODE ?  `/hpcapi/v3/image-catalog/` : `/hpcapi/v3/provider/${provider}/device-image`
        const payloadKey = storage.get.SUPER_ADMIN_MODE ?  'device_image_catalog' : 'device-image'
        const imagePayload = {};
        imagePayload[payloadKey] = {
            name: this.state.formData.file.name,
            version: this.state.formData.version,
            description: this.state.formData.description,
            size: this.state.formData.file.size,
            "created-on": currentDate,
            "last-modified-on": currentDate
          };
        
        const res = await httpClient.post(
          hpcUrl,
          imagePayload,
        )
        this.setState({ isUploading: false })

        this.props.onRequestOk(payload, this.state.formData.file)
        A10Message.destroy()
      
    } catch (error) {
      A10Message.destroy()
      this.setState({ isUploading: false })
      const dataObj = error?.response?.data?.message?.[this.state.formData.file.name]
      const msg = dataObj && typeof dataObj === 'string'
        ? dataObj
        : ''
      A10Message.error('Image upload failed: ' + msg)
      this.props.onRequestClose()
    }
  }

  onClickCancel = () => {
    this.setState({ isUploading: false }, () => {
      if (this.cancelTokenSrc !== null) {
        this.cancelTokenSrc.cancel('Cancel Upload')
        this.cancelTokenSrc = null
      }
      this.props.onRequestClose()
    })
  }

  render() {
    const { isOpen } = this.props
    const onFormChange = this.onFormChange.bind(this)
    const title = 'Device Image Upload'

    return (
      <FormatSlidingPage
        isOpen={isOpen}
        onRequestOk={this.saveUploadSlidingPage}
        onRequestClose={this.onClickCancel}
        title={title}
        disableSave={this.state.isUploading}
        saveText="Upload"
        description=""
      >
        <UploadForm
          onChange={onFormChange}
          onRef={(ref: any): any => (this.childForm = ref)}
        />
      </FormatSlidingPage>
    )
  }
}

export default SlidingUpload
