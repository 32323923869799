import React, { useState, useEffect, useCallback } from 'react'
import { _ } from '@gui-libraries/framework'
import {
  A10Form,
  A10Icon,
  A10Select,
  A10Checkbox,
  A10Row,
  A10Col,
} from '@gui-libraries/widgets'

import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import A10Panel from 'src/components/ADC/A10Panel'
import { InfrastructureService } from 'src/services/index'
import storage from 'src/libraries/storage'

const AccessControlForm: React.FC = (props: IObject) => {
  const infrastructureService = new InfrastructureService()
  const [interfaces, setInterfaces] = useState([] as IObject[])
  const [pingSerivces, setPingServices] = useState([] as string[])
  const [sshSerivces, setSSHServices] = useState([] as string[])
  const [telnetSerivces, setTelnetServices] = useState([] as string[])
  const [httpSerivces, setHTTPServices] = useState([] as string[])
  const [httpsSerivces, setHTTPSServices] = useState([] as string[])
  const [snmpSerivces, setSNMPServices] = useState([] as string[])
  const provider = storage.get.PROVIDER
  const name = props.device.name
  const svcMapping = {
    ping: {
      list: pingSerivces,
      func: setPingServices,
    },
    ssh: {
      list: sshSerivces,
      func: setSSHServices,
    },
    telnet: {
      list: telnetSerivces,
      func: setTelnetServices,
    },
    http: {
      list: httpSerivces,
      func: setHTTPServices,
    },
    https: {
      list: httpsSerivces,
      func: setHTTPSServices,
    },
    snmp: {
      list: snmpSerivces,
      func: setSNMPServices,
    },
  }
  useEffect(() => {
    Promise.all([
      infrastructureService.getRequest(
        null,
        null,
        [provider, name, 'shared'],
        'DEVICE_ETHERNET_INTERFACE',
      ),
      infrastructureService.getRequest(
        null,
        null,
        [provider, name, 'shared'],
        'DEVICE_INTERFACE_VE',
      ),
      infrastructureService.getRequest(
        null,
        null,
        [provider, name, 'shared'],
        'DEVICE_INTERFACE_TUNNEL',
      ),
    ]).then((responses: IObject[]) => {
      const objs = [
        { bKey: 'ethernet-list', prefix: 'Eth' },
        { bKey: 've-list', prefix: 'Ve' },
        { bKey: 'tunnel-list', prefix: 'Tunnel' },
      ]
      let inList = [] as IObject[]
      responses.forEach((res: IObject, i: number) => {
        inList = inList.concat(
          _.get(res, `data.${objs[i].bKey}`, []).map((ifObj: IObject) => {
            return {
              id: `${objs[i].prefix}${ifObj.ifnum}`,
              services: [] as string[],
              aclv4: '',
              aclv6: '',
            }
          }),
        )
      })
      inList.unshift({
        id: 'Mgmt',
        services: [] as string[],
        'acl-ipv4': '',
        'acl-ipv6': '',
      })
      setInterfaces(inList)
    })
  }, [name])

  useEffect(() => {
    if (interfaces.length === 0) {
      return
    }
    infrastructureService
      .operRequest([provider, name, 'shared'], 'GET_DEVICE_ACCESS_CONTROL')
      .then((response: IObject) => {
        const enableMgmt = _.get(
          response,
          `data.0.${_.keys(response.data[0])[0]}.enable-management`,
        )
        _.keys(enableMgmt).forEach((key: string) => {
          const svc = [] as string[]
          _.get(enableMgmt[key], 'oper.port-list', []).forEach(
            (port: IObject) => {
              if (port.action === 'on') {
                if (port.management) {
                  svc.push('Mgmt')
                }
                if (port.ethernet) {
                  svc.push(`Eth${port.ethernet}`)
                }
                if (port.tunnel) {
                  svc.push(`Tunnel${port.tunnel}`)
                }
                if (port.ve) {
                  svc.push(`Ve${port.ve}`)
                }
              }
            },
          )
          if (svcMapping[key]) {
            svcMapping[key].func(svc)
          }
        })
      })
  }, [interfaces])
  const options = ['Ping', 'SSH', 'Telnet', 'HTTP', 'HTTPS', 'SNMP'].map(
    protocol => {
      return {
        label: protocol,
        value: protocol.toLowerCase(),
      }
    },
  )

  const handleChange = useCallback(
    (obj: string, e: any) => {
      let svc = svcMapping[e.target.value].list
      if (e.target.checked) {
        svc.push(obj)
        svcMapping[e.target.value].func(svc)
      } else {
        svc = _.remove(svc, (opt: string) => opt === obj)
        svcMapping[e.target.value].func(svc)
      }
    },
    [
      pingSerivces,
      sshSerivces,
      telnetSerivces,
      httpSerivces,
      httpsSerivces,
      snmpSerivces,
    ],
  )

  const getServics = useCallback(
    (obj: string, protocol: string) => svcMapping[protocol].list.includes(obj),
    [
      pingSerivces,
      sshSerivces,
      telnetSerivces,
      httpSerivces,
      httpsSerivces,
      snmpSerivces,
    ],
  )

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 19 },
  }

  return (
    <A10Form hideRequiredMark={true} layout="horizontal">
      <A10Panel
        title={
          <>
            <A10IconTextGroup
              text={'Access Control'}
              icon={
                <A10Icon
                  style={{ width: 22, height: 22, marginRight: 12 }}
                  app="global"
                  type="form-section"
                  className="sliding-panel-icon"
                />
              }
            />
          </>
        }
      >
        {interfaces.map((eth: IObject) => (
          <A10Form.Item {...formItemLayout} label={eth.id} key={eth.id}>
            <A10Row>
              {options.map((opt: IObject) => (
                <A10Col span={4} key={opt.value}>
                  <A10Checkbox
                    value={opt.value}
                    checked={getServics(eth.id, opt.value)}
                    onChange={handleChange.bind(null, eth.id)}
                  >
                    {opt.label}
                  </A10Checkbox>
                </A10Col>
              ))}
            </A10Row>
            <A10Row style={{ marginTop: 20 }}>
              <A10Col span={3}>ACLv4</A10Col>
              <A10Col span={9}>
                <A10Select>
                  <A10Select.Option key={1}>1</A10Select.Option>
                  <A10Select.Option key={2}>2</A10Select.Option>
                </A10Select>
              </A10Col>
              <A10Col span={3} style={{ paddingLeft: 10 }}>
                ACLv6
              </A10Col>
              <A10Col span={9}>
                <A10Select>
                  <A10Select.Option key={1}>1</A10Select.Option>
                  <A10Select.Option key={2}>2</A10Select.Option>
                </A10Select>
              </A10Col>
            </A10Row>
          </A10Form.Item>
        ))}
      </A10Panel>
    </A10Form>
  )
}

export default AccessControlForm
