import { A10Card, A10Form, A10Input } from '@gui-libraries/widgets'
import {
  IA10ContainerDefaultProps,
  setupA10Container,
} from '@gui-libraries/framework'
import React, { useEffect, useState } from 'react'
import { Utilities } from 'src/services'
import '../../styles/index.less'
import { Messages } from 'src/locale/en/Messages'
const utilities = new Utilities()
const messages = new Messages()
export interface INTPFormProps extends IA10ContainerDefaultProps {
  form: IObject
  onRef?: IObject
  handleChange?: (data: IObject) => void
  ntpServerData: any
  setDisableSave: (disableSave: boolean) => void
}

export interface INTPFormState {
  isLoading: boolean
  inputCount: number
  NTPDetailsList: any
}

const NTPForm = (props: INTPFormProps) => {
  const {
    getFieldDecorator,
    getFieldsValue,
    setFieldsValue,
    validateFields,
  } = props.form
  const { ntpServerData } = props
  const [NTPDetailsList, setNTPDetailsList] = useState(ntpServerData)
  useEffect(() => {
    setNTPDetailsList(ntpServerData)
  }, [ntpServerData])

  const handleInputChange = (index: number, e: Event | any) => {
    if (e.target) {
      const ntpDataList: any = [...NTPDetailsList]
      ntpDataList[index] = e.target.value
      setNTPDetailsList(ntpDataList)
      setFieldsValue({ servers: ntpDataList }, validateFields)
      const hasDuplicates = ntpDataList.length !== new Set(ntpDataList).size
      if (!hasDuplicates) {
        props.handleChange(ntpDataList)
      }
    }
  }
  const removeInput = (index: number) => {
    let ntpList = [...NTPDetailsList]
    ntpList.splice(index, 1)
    setNTPDetailsList(ntpList)
    checkValidation(ntpList)
    setFieldsValue({ servers: ntpList }, validateFields)
  }

  const checkValidation = (ntpList: IObject) => {
    const hasDuplicates = ntpList.length !== new Set(ntpList).size
    props.setDisableSave(hasDuplicates)

    if (!hasDuplicates) {
      props.handleChange(ntpList)
    }
  }

  const addInput = () => {
    if (NTPDetailsList[NTPDetailsList.length - 1] !== '') {
      let ntpAddInputsList: any = NTPDetailsList
      ntpAddInputsList.push('')
      setNTPDetailsList(ntpAddInputsList)
      props.setDisableSave(ntpAddInputsList.includes(''))
      setFieldsValue({ servers: ntpAddInputsList }, validateFields)
    }
  }

  const handleValidate = (rule: IObject, data: string, cb: any) => {
    let isValidIPAndHost = false
    let isValidDuplicate = false
    let isNotEmpty = true
    let isValid = false
    const ntpDataList: string[] = getFieldsValue().servers
    const count = ntpDataList.reduce(
      (acc, cur) => (cur === data ? acc + 1 : acc),
      0,
    )
    const ip = utilities.VALIDATE_IPADDRESSES
    const host = utilities.VALIDATE_HOSTNAME

    //if IPv4 and IPv6 matches regex pattern and show error
    isValidIPAndHost =
      ip.test(data) || host.test(data)
        ? true
        : ((rule.message = messages.INVALID_NTP_INPUT), false)

    //check if input is empty , and show error
    isNotEmpty =
      data === '' ? ((rule.message = messages.NTP_EMPTY_INPUT), false) : true

    //if input has duplicate values and show error
    isValidDuplicate =
      count > 1 ? ((rule.message = messages.NTP_DUPLICATE_NAME), false) : true

    isValid = isValidIPAndHost && isValidDuplicate && isNotEmpty ? true : false
    const getFieldErrors = props.form.getFieldsError().servers
    const errorObjects = Object.values(getFieldErrors)
    const hasNoError = errorObjects.every((element: IObject) => {
      return element === undefined
    })

    //disable save if validation fails
    props.setDisableSave(!(isValid && hasNoError) || ntpDataList.includes(''))
    return isValid ? cb() : cb(true)
  }

  return (
    <>
      <A10Form>
        <A10Card className="ntp-form">
          {NTPDetailsList &&
            NTPDetailsList.map((item: string, index: number) => {
              return (
                <div className="ntp-form-input">
                  <div className="ntp-server-heading">
                    <span>{`Server ${index + 1}`}</span>
                  </div>
                  <div className="ntp-form-input-content">
                    <A10Form.Item>
                      {getFieldDecorator(`servers[${index}]`, {
                        rules: [
                          {
                            validator: handleValidate,
                          },
                        ],
                        initialValue: item,
                      })(
                        <A10Input
                          key={index}
                          type="text"
                          placeholder={'Server'}
                          onChange={e => handleInputChange(index, e)}
                          value={item}
                          className="ntp-input"
                        ></A10Input>,
                      )}

                      {index == NTPDetailsList.length - 1  && (
                        <div
                          className="a10-icon add-another margin8"
                          testID="add-icon"
                          onClick={() => addInput()}
                        ></div>
                      )}

                      {NTPDetailsList.length !== index && NTPDetailsList.length !== 1 && (
                        <div
                          className="a10-icon remove margin8"
                          testID="remove-icon"
                          onClick={() => removeInput(index)}
                        ></div>
                      )}
                    </A10Form.Item>
                  </div>
                </div>
              )
            })}
        </A10Card>
      </A10Form>
    </>
  )
}
export default setupA10Container(A10Form.create()(NTPForm))
