import React from 'react'
import { Redirect } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import {
  A10Container,
  IA10ContainerDefaultProps,
  setupA10Container,
} from '@gui-libraries/framework'
import { A10Menu, A10Icon } from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import { DashboardService, Utilities } from 'src/services/index'
import { AppRoot } from 'src/settings/appRoot'
import { ApplicationConfigs } from 'src/constants/ApplicationConfigs/ApplicationConfigs'
import { LeftNavApps } from '../LeftNavApps'
import { ConfirmTenantSelection } from 'src/components/shared/ConfirmTenantSelection'

import './styles/index.less'

const SubMenu = A10Menu.SubMenu

export interface ILeftNavMenuProps extends IA10ContainerDefaultProps {
  leftNavCollapsed?: boolean
  selectedTenant?: any
  tenantToggled?: boolean
  refreshHCApps: boolean
  applicationName: string
  subApplicationName: string
  tenantChange(tenant: any, isProviderMode: boolean, isApp?: boolean): void
}

export interface ILeftNavMenuState {
  applications: any
  confirmTenantState: boolean
  selectedApplication: any
  selectedSubApplication: any
  tenantToggled: boolean
  openKeys: any
  allApps: IObject[]
}

class LeftNavMenu extends A10Container<ILeftNavMenuProps, ILeftNavMenuState> {
  static getDerivedStateFromProps(
    nextProps: ILeftNavMenuProps,
    prevState: ILeftNavMenuState,
  ) {
    return {
      confirmTenantState: prevState.confirmTenantState,
      selectedApplication: prevState.selectedApplication,
      selectedSubApplication: prevState.selectedSubApplication,
      tenantToggled: prevState.tenantToggled,
      openKeys: prevState.openKeys,
    }
  }
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  AppRoot = new AppRoot()
  ApplicationConfigs = new ApplicationConfigs()

  drillLevel = storage.get.DRILL_LEVEL
  isSuperUser = storage.get.IS_SUPER_USER
  allApps: Array<any> = []
  rootSubmenuKeys: Array<any> = []

  constructor(props: ILeftNavMenuProps) {
    super(props)
    this.state = {
      applications: this.ApplicationConfigs.APPLICATIONS,
      confirmTenantState: false,
      tenantToggled: this.props.tenantToggled,
      selectedApplication: {},
      selectedSubApplication: {},
      openKeys: ['sub1'],
      allApps: [],
    }

    this.ApplicationConfigs.APPLICATIONS.map((app: any) => {
      if (!app.noSubmenu && app.subApps && app.subApps.length > 0) {
        this.rootSubmenuKeys.push(`menu${app.name}`)
      }
    })
  }

  componentDidMount() {
    this.loadApps()
  }
  componentDidUpdate(prevProps: any) {
    if (prevProps.refreshHCApps !== this.props.refreshHCApps) {
      this.loadApps()
    }
  }

  renderRedirect = (url: string) => {
    return <Redirect to={url} />
  }

  showHideConfirmTenant = (confirmState: boolean) => {
    this.setState({ confirmTenantState: confirmState })
  }

  handleApplicationClick = (application: any, subApp?: any) => {
    if (!!application) {
      const current_tenant = storage.get.CURRENT_TENANT
      if (!current_tenant.uuid && !!subApp && subApp.tenantContext) {
        this.setState({
          confirmTenantState: true,
          selectedApplication: application,
          selectedSubApplication: subApp,
        })
      } else {
        this.setState({
          confirmTenantState: false,
          selectedApplication: application,
          selectedSubApplication: subApp,
        })
        storage.set.CURRENT_STATE(application.name.toLowerCase())
        if (subApp && subApp.name) {
          storage.set.CURRENT_SUB_STATE(subApp.name.toLowerCase())
        }
        const tenant = {
          name: 'superAdmin',
          superAdminRole: true,
        }
        let providerMode = application.providerMode
        if (!!subApp && subApp.tenantContext) {
          providerMode = false
        }
        this.props.tenantChange(
          application.superUserMode ? tenant : null,
          storage.get.ADMIN_LEVEL === 'tenant' ||
            ((application?.name?.toLowerCase() === 'apps' ||
              application?.name?.toLowerCase() === 'home') &&
              storage.get.DRILL_LEVEL === 'tenant')
            ? false
            : providerMode,
          application?.name?.toLowerCase() === 'home' ? true : false,
        )
      }
    }
  }

  onMainMenuClick = (application: any, event: any) => {
    const current_tenant = storage.get.CURRENT_TENANT
    storage.remove.CURRENT_SUB_STATE()
    if (
      !current_tenant.uuid &&
      ('Services' === application.name || 'Monitor' === application.name)
    ) {
      event.preventDefault()
      this.handleApplicationClick(application)
    } else {
      this.handleApplicationClick(application)
    }
  }

  onSubAppClick = (application: any, subApp: any, event: any) => {
    if (subApp.state !== 'disabled') {
      const current_tenant = storage.get.CURRENT_TENANT
      if (!current_tenant.uuid && subApp.tenantContext) {
        event.preventDefault()
        this.handleApplicationClick(application, subApp)
      } else {
        storage.remove.CURRENT_SUB_STATE()
        this.handleApplicationClick(application, subApp)
      }
    }
  }

  onOpenChange = (openKeys: any) => {
    const latestOpenKey = openKeys.find(
      (key: any) => this.state.openKeys.indexOf(key) === -1,
    )
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys })
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      })
    }
  }

  parseIconConfig = (iconConfig: string | string[]) => {
    const config = Array.isArray(iconConfig) ? iconConfig : [iconConfig]
    let app = 'navigation-icons'
    let type = 'provider'

    if (config.length > 1) {
      app = config[0]
      type = config[1]
    } else {
      type = config[0]
    }

    return { app, type } as { app: GuiWidgets.A10Icon.IconApps; type: string }
  }

  renderSubApps = (application: IObject) => {
    const { leftNavCollapsed } = this.props
    const adminLevel = storage.get.ADMIN_LEVEL
    const drillLevel = storage.get.DRILL_LEVEL
    const infraAdminMode = storage.get.IS_INFRA_USER
    const certAdminMode = storage.get.IS_CERT_USER
    const partitionAdminMode = storage.get.IS_PARTITION_USER
    const operatorMode = storage.get.IS_OPERATOR_USER

    return application.subApps.map((subApp: IObject, index: number) => {
      let showByRole = false

      if (subApp.allowedRoles.indexOf(adminLevel) > -1) {
        showByRole = true
      }
      if (subApp.allowedLevels) {
        showByRole = false
        if (subApp.allowedLevels.indexOf(drillLevel) > -1) {
          showByRole = true
        }
      }
      // For hc_infra_admin & hc_certificate_admin role class users
      if (
        (certAdminMode || infraAdminMode) &&
        subApp.pseudoProviderMode === 'no'
      ) {
        showByRole = false
      }
      // For hc_certificate_admin role class users
      if (certAdminMode && subApp.certUserMode === 'no') {
        showByRole = false
      }
      // For hc_partition_admin role class users
      if (partitionAdminMode && subApp.partitionAdminMode === 'no') {
        showByRole = false
      }
      // For hc_*****_operator role class users
      if (operatorMode && subApp.operatorUserMode === 'no') {
        showByRole = false
      }

      if (!subApp.noShow && showByRole) {
        const subUri = `/controller/${application.name}/${subApp.name}`
        const key = `${application?.name?.toLowerCase()}-${subApp?.name?.toLowerCase()}`

        return (
          <A10Menu.Item
            key={key}
            className={`menu-item-container ${
              subApp.state === 'disabled' ? 'disabled' : ''
            }`}
            title={leftNavCollapsed ? '' : subApp.displayName || subApp.name}
          >
            <NavLink
              to={subUri}
              disabled={subApp.state === 'disabled'}
              onClick={this.onSubAppClick.bind(this, application, subApp)}
            >
              {subApp.icon && (
                <A10Icon
                  {...this.parseIconConfig(subApp.icon)}
                  islight={true}
                  withoutHover={true}
                />
              )}
              <span>{subApp.displayName || subApp.name}</span>
            </NavLink>
          </A10Menu.Item>
        )
      } else {
        return null
      }
    })
  }

  loadApps = async () => {
    let allApps = []
    try {
      const targetUrl = storage.get.TARGET_URL
      allApps = await this.Utilities.getLicensedApps(targetUrl)
    } catch (e) {
      console.log(e.response)
    }
    this.setState({ allApps: allApps })
  }

  render() {
    const { leftNavCollapsed, applicationName, subApplicationName } = this.props
    const { applications } = this.state
    const adminLevel = storage.get.ADMIN_LEVEL
    const superAdminMode = storage.get.SUPER_ADMIN_MODE
    const infraAdminMode = storage.get.IS_INFRA_USER
    const certAdminMode = storage.get.IS_CERT_USER
    const selectedKey = subApplicationName
      ? `${applicationName.toLowerCase()}-${subApplicationName.toLowerCase()}`
      : `${applicationName.toLowerCase()}`
    const userRole = storage.get.USER_ROLES

    return (
      <>
        <A10Menu
          theme="light"
          mode="inline"
          inlineCollapsed={leftNavCollapsed}
          className="left-nav-menu"
          defaultOpenKeys={
            leftNavCollapsed ? [] : [`menu-${applicationName.toLowerCase()}`]
          }
          selectedKeys={[selectedKey]}
          expandIcon={(props: IObject) => {
            const style = {
              transition: 'transform 0.3s',
              transform: 'rotate(0deg)',
            }
            if (props.isOpen) {
              style.transform = 'rotate(90deg)'
            }

            return (
              <A10Icon
                app="global"
                type="breadcrumb"
                islight={true}
                style={style}
              />
            )
          }}
          // openKeys={this.state.openKeys}
          // onOpenChange={this.onOpenChange}
        >
          {applications.map((application: IObject) => {
            if (
              application.visible &&
              application.allowedRoles.indexOf(adminLevel) > -1 &&
              ((!superAdminMode && !application.superUserMode) ||
                (superAdminMode && application.superUserMode)) &&
              (!infraAdminMode ||
                (infraAdminMode && application.infraUserMode !== 'no')) && // For hc_infra_admin role class users
              (!certAdminMode ||
                (certAdminMode && application.certUserMode !== 'no')) && // For hc_certificate_admin role class users
              (userRole !== 'hc_tenant_operator' ||
                application.operatorUserMode !== 'no') &&
              (userRole !== 'hc_partition_operator' ||
                application.operatorUserMode !== 'no')
            ) {
              const uri = `/controller/${application.name}`

              if (
                !application.noSubmenu &&
                application.subApps &&
                application.subApps.length > 0
              ) {
                return (
                  <SubMenu
                    key={`menu-${application.name.toLowerCase()}`}
                    popupClassName="left-nav-submenu"
                    title={
                      <span
                        title={application.displayName || application.name}
                        className={`menu-item-container ${
                          applicationName.toLowerCase() ===
                          application.name.toLowerCase()
                            ? 'active'
                            : ''
                        }`}
                      >
                        <A10Icon
                          {...this.parseIconConfig(application.icon)}
                          islight={true}
                          withoutHover={true}
                        />
                        <span>
                          {application.displayName || application.name}
                        </span>
                      </span>
                    }
                  >
                    {leftNavCollapsed && (
                      <A10Menu.Item
                        key={`${application.name}-header`}
                        disabled={true}
                      >
                        <span>
                          {application.displayName || application.name}
                        </span>
                      </A10Menu.Item>
                    )}
                    {application.name === 'Apps' && (
                      <LeftNavApps
                        leftNavcollapsed={leftNavCollapsed}
                        selectedTenant={this.props.selectedTenant}
                        refreshHCApps={this.props.refreshHCApps}
                        allApps={this.state.allApps}
                      />
                    )}
                    {application.name === 'Apps' &&
                      this.state.allApps?.length && <A10Menu.Divider />}
                    {this.renderSubApps(application)}
                  </SubMenu>
                )
              } else {
                return (
                  <A10Menu.Item
                    key={application.name.toLowerCase()}
                    className={'menu-item-container'}
                    title={
                      leftNavCollapsed
                        ? ''
                        : application.displayName || application.name
                    }
                  >
                    <NavLink
                      to={uri}
                      onClick={this.onMainMenuClick.bind(this, application)}
                    >
                      <A10Icon
                        {...this.parseIconConfig(application.icon)}
                        islight={true}
                        withoutHover={true}
                      />
                      <span>{application.displayName || application.name}</span>
                    </NavLink>
                  </A10Menu.Item>
                )
              }
            } else {
              return null
            }
          })}
        </A10Menu>

        <ConfirmTenantSelection
          selectedApp={this.state.selectedApplication}
          selectedSubApp={this.state.selectedSubApplication}
          onCancel={this.showHideConfirmTenant.bind(this, false)}
          showSlidingPage={this.state.confirmTenantState}
          tenantChange={this.props.tenantChange}
        />
      </>
    )
  }
}

export default setupA10Container(LeftNavMenu)
