import React from 'react'
import {
  A10Container,
  IA10ContainerDefaultProps,
  setupA10Container,
} from '@gui-libraries/framework'
import { A10Message } from '@gui-libraries/widgets'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { IFormData } from './index'
import storage from 'src/libraries/storage'
// import globalConfig from 'src/settings/config'
import { Utilities } from 'src/services'
import { DashboardService } from 'src/services/DashboardService'
import { LicenseAddForm } from 'src/containers/Controller/Organization/License/Forms/LicenseAddForm/index'

export interface ISlidingLicenseProps extends IA10ContainerDefaultProps {
  isOpen: boolean
  onRequestClose: () => void
  glmLicTypeText: (glmLicType: string, licType: string) => string
  leftNavAppsRefresh: () => void
}

export interface ISlidingLicenseStates {
  provider: string
  formData?: IFormData
  isValidToken: boolean
  licenseInfo: any
  offlineLicense: any
}

class SlidingLicense extends A10Container<
  ISlidingLicenseProps,
  ISlidingLicenseStates
> {
  Utilities = new Utilities()
  DashboardService = new DashboardService()

  childForm: any = null
  constructor(props: ISlidingLicenseProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER,
      formData: {
        entitleToken: null,
        licenseType: null,
        numBandwidth: null,
        numDevices: null,
        numProcessingUnits: null,
      },
      isValidToken: false,
      licenseInfo: {},
      offlineLicense: {
        enabled: false,
        isValidActivationUUID: false,
        activationUUID: '',
        activationKey: '',
      },
    }
  }

  setDefaultValues() {
    const formDataTemp: IFormData = {
      entitleToken: null,
      licenseType: null,
      numBandwidth: null,
      numDevices: null,
      numProcessingUnits: null,
    }
    const state = { ...this.state }
    state.formData = formDataTemp
    state.isValidToken = false
    state.licenseInfo = {}
    state.offlineLicense = {
      enabled: false,
      isValidActivationUUID: false,
      activationUUID: '',
      activationKey: '',
    }
    this.setState(state)
  }

  onFormChange(data: IFormData, isEdit: boolean) {
    const state = { ...this.state }
    state.formData = data
    this.setState(state)
  }

  onChangeOffline = (data: any) => {
    const state = { ...this.state }
    state.offlineLicense = data
    this.setState(state)
  }

  checkToken = () => {
    const checkLicense = this.DashboardService.validateLicense(null, null, [
      storage.get.PROVIDER,
      this.state.formData.entitleToken,
    ])
    checkLicense
      .then((resp: any) => {
        const licenseInfo = resp && resp.data ? resp.data : {}
        this.setState({
          isValidToken: true,
          licenseInfo,
        })
      })
      .catch((err: any) => {
        this.setState({
          isValidToken: false,
          licenseInfo: {},
        })
        if (!this.Utilities.isRBACAccessDenied(err)) {
          const msg = err?.response?.data?.message
            ? err.response.data.message
            : ''
          A10Message.error(
            'Error: ' + (msg ? msg : 'License details not found.'),
          )
        }
      })
  }

  getActivationUUID = () => {
    const checkLicenseActivation = this.DashboardService.getOfflineLicenseActivationUUID(
      null,
      null,
      [storage.get.PROVIDER, this.state.formData.entitleToken],
    )
    checkLicenseActivation
      .then((resp: any) => {
        const activationUUID =
          resp && resp.data && resp.data.ActivationUUID
            ? resp.data.ActivationUUID
            : ''
        const { offlineLicense } = this.state
        if (!!activationUUID) {
          offlineLicense.activationUUID = activationUUID
          offlineLicense.isValidActivationUUID = true
        }

        this.setState({ offlineLicense })
      })
      .catch((err: any) => {
        const { offlineLicense } = this.state
        offlineLicense.isValidActivationUUID = false
        this.setState({ offlineLicense })

        if (!this.Utilities.isRBACAccessDenied(err)) {
          const msg = err?.response?.data?.message
            ? err.response.data.message
            : ''
          A10Message.error(
            'Error: ' + (msg ? msg : 'Activation UUID not found.'),
          )
        }
      })
  }

  importLicense = () => {
    const importLicense = this.DashboardService.importOfflineLicense(
      null,
      this.state.offlineLicense.activationKey,
      [storage.get.PROVIDER, this.state.formData.entitleToken],
    )
    importLicense
      .then((resp: any) => {
        if (resp) {
          this.Utilities.showMessage(
            'Import request submitted successfully, please check the status in activity log for the license',
            1,
            0,
          )
          this.props.leftNavAppsRefresh()
          this.onClose()
        }
      })
      .catch((err: any) => {
        if (!this.Utilities.isRBACAccessDenied(err)) {
          const msg = err?.response?.data?.message
            ? err.response.data.message
            : ''
          this.Utilities.showMessage('Error while importing license', 0, 0, msg)
        }
        this.onClose()
      })
  }

  onAddLicenseSubmit() {
    if (this.state.offlineLicense.enabled) {
      if (this.state.offlineLicense.isValidActivationUUID) {
        this.importLicense()
      } else {
        this.getActivationUUID()
      }
      return
    }

    if (this.state.formData.entitleToken && !this.state.isValidToken) {
      this.checkToken()
    } else {
      this.saveUploadSlidingPage()
    }
  }

  saveUploadSlidingPage = async () => {
    const {
      entitleToken,
      licenseType,
      numBandwidth,
      numDevices,
      // numProcessingUnits,
    } = this.state.formData
    const maxbandwidthObj = this.Utilities.megaToGiga(
      this.state.licenseInfo['remaining-bandwidth'] || 0,
    )
    const numBW =
      maxbandwidthObj && maxbandwidthObj.unit === 'g'
        ? parseFloat(numBandwidth) * 1000
        : numBandwidth

    const payload = {
      'entitlement-key': entitleToken,
      'license-type': this.state.licenseInfo['license-type'],
      'alloted-bandwidth': numBW,
      'alloted-devices': numDevices,
      'expires-at': this.state.licenseInfo['expires-at'],
      // 'license-bandwidth': this.state.licenseInfo['license-bandwidth'],
      // 'license-devices': this.state.licenseInfo['license-devices'],
      'billing-serials': this.state.licenseInfo['billing-serials'],
      'glm-lic-type': this.state.licenseInfo['glm-lic-type'],
      'license-name': this.state.licenseInfo['license-name'],
      'license-id': this.state.licenseInfo['license-id'],
      'license-json': this.state.licenseInfo['license-json'],
      'glm-created-at': this.state.licenseInfo['glm-created-at'],
      'glm-updated-at': this.state.licenseInfo['glm-updated-at'],
      'created-at': this.state.licenseInfo['created-at'],
      'last-modified-at': this.state.licenseInfo['last-modified-at'],
      description: this.state.licenseInfo['description'],
      notes: this.state.licenseInfo['notes'],
      'check-in-frequency': this.state.licenseInfo['check-in-frequency'],
      'default-allocated-bandwidth': this.state.licenseInfo[
        'default-allocated-bandwidth'
      ],
      'max-allocated-bandwidth': this.state.licenseInfo[
        'max-allocated-bandwidth'
      ],
      'min-allocated-bandwidth': this.state.licenseInfo[
        'min-allocated-bandwidth'
      ],
      'container-device-return-to-pool-time': this.state.licenseInfo[
        'container-device-return-to-pool-time'
      ],
      'remaining-bandwidth': this.state.licenseInfo['remaining-bandwidth'],
      'remaining-devices': this.state.licenseInfo['remaining-devices'],
      'used-bandwidth': this.state.licenseInfo['used-bandwidth'],
      'used-devices': this.state.licenseInfo['used-devices'],
      'used-activations': this.state.licenseInfo['used-activations'],
      'active-appliances': this.state.licenseInfo['active-appliances'],
      'current-revision-id': this.state.licenseInfo['current-revision-id'],
      'licensed-device-list': this.state.licenseInfo['licensed-device-list'],
      'license-platform': this.state.licenseInfo['license-platform'],
      'license-product': this.state.licenseInfo['license-product'],
      'organization-name': this.state.licenseInfo['organization-name'],
      'start-date': this.state.licenseInfo['start-date'],
      'subscriber-count': this.state.licenseInfo['subscriber-count'],
      entitlements: this.state.licenseInfo['entitlements']
        ? this.state.licenseInfo['entitlements']
        : [],
    }

    if (
      this.state.licenseInfo['license-type'] === 'MDU' ||
      licenseType === 'MDU'
    ) {
      payload['alloted-bandwidth'] = '0'
    }

    if (
      this.state.licenseInfo['license-type'].indexOf('FLEXPOOL') > -1 ||
      licenseType.indexOf('FLEXPOOL') > -1
    ) {
      payload['alloted-bandwidth'] = this.state.licenseInfo['alloted-bandwidth']
      payload['alloted-devices'] = this.state.licenseInfo['alloted-devices']
    }

    const { provider } = this.state
    const addLicense = this.DashboardService.updateLicense(null, payload, [
      provider,
    ])
    addLicense
      .then((resp: any) => {
        if (resp) {
          this.Utilities.showMessage(
            'Creation request submitted successfully, please check the status in activity log for the license',
            1,
            0,
          )
          this.props.leftNavAppsRefresh()
          this.onClose()
        }
      })
      .catch((error: any) => {
        if (!this.Utilities.isRBACAccessDenied(error)) {
          const msg = error?.response?.data?.message
            ? error.response.data.message
            : ''
          this.Utilities.showMessage(
            'Error while creating license - Please consult with License Administrator of your organization about the capacity you are trying to allocate',
            0,
            0,
            msg,
          )
        }

        this.onClose()
      })
  }

  onClose = () => {
    this.setDefaultValues()
    this.props.onRequestClose()
  }

  handleFormValidation = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  render() {
    const { isOpen } = this.props
    const onFormChange = this.onFormChange.bind(this)
    const title = 'Add License'
    let saveText = 'Check Token'
    if (this.state.isValidToken) {
      saveText = 'Request'
    } else if (this.state.offlineLicense.enabled) {
      saveText = 'Get Activation UUID'
      if (this.state.offlineLicense.isValidActivationUUID) {
        saveText = 'Import License'
      }
    }
    return (
      <FormatSlidingPage
        isOpen={isOpen}
        onRequestOk={this.handleFormValidation}
        onRequestClose={this.onClose}
        title={title}
        saveText={saveText}
        description="Enter a license key to request capacity"
      >
        <LicenseAddForm
          onChange={onFormChange}
          isValidToken={this.state.isValidToken}
          onSubmitForm={() => {
            this.onAddLicenseSubmit()
          }}
          licenseInfo={this.state.licenseInfo}
          offlineLicense={this.state.offlineLicense}
          onChangeOffline={this.onChangeOffline}
          onRef={(ref: any): any => (this.childForm = ref)}
          glmLicTypeText={this.props.glmLicTypeText}
        />
      </FormatSlidingPage>
    )
  }
}

// export default SlidingLicense
export default setupA10Container(SlidingLicense)
