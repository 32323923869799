import React from 'react'
import parameters from 'parameters'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
  getItem,
  setItem,
} from '@gui-libraries/framework'
import { A10Row, A10Radio } from '@gui-libraries/widgets'
import { DGF, DGFProvider } from '@gui-libraries/dgf'

import { IDefaultMethods } from 'src/containers/Controller'
import { AlertDefinition } from 'src/containers/Controller/Monitor/AlertDefinition'
import { Triggers } from 'src/containers/Controller/Monitor/Triggers'
import { Actions } from 'src/containers/Controller/Monitor/Actions'
import {
  ContentSection,
  ContentBody,
} from 'src/components/shared/ContentSection'
import storage from 'src/libraries/storage'
import { TroubleShootService } from 'src/services'

import styles from './styles/index.module.less'
import LogContent from '../LogContent'

export interface IAlertProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  tenantToggled: boolean
  tenantChange: any
  selectedTenant?: string
}
export interface IAlertState {
  app: string
  dgfParameter: GuiDGF.HCParameters
  dashboard: IObject
  maxAlerts: number
  remainingAlerts: number
  activeLicense: string
}

const logConfig = [
  {
    name: 'Alerts',
    viz: [
      {
        app: 'adc',
        dataSource: 'RPT',
        endPoint: 'thunder-adc',
        metrics: 'hc_event',
        name: 'Alerts',
      },
    ],
  },
]

class Alert extends A10Container<IAlertProps, IAlertState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  TroubleShootService = new TroubleShootService()
  app: string
  constructor(
    props: IAlertProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: { set },
    } = context

    set.API(process.env.NODE_ENV === 'production' ? parameters.BASE_URL : '')
    this.app = 'generated_alerts'

    const tenant = storage.get.CURRENT_TENANT

    this.state = {
      app: this.app,
      dgfParameter: {
        auth: storage.get.ENCODED_SESSION_ID,
        api: parameters.BASE_URL,
        provider: storage.get.PROVIDER,
        username: storage.get.USER_ID,
        tenant: tenant.name,
        tenantUUID: tenant.uuid,
        providerId: storage.get.PROVIDER_ID,
      },
      dashboard: {
        name: 'Alerts',
        description: '',
        context: props.selectedTenant ? 'tenant-custom' : 'provider-custom',
        isDrillDown: false,
      },
      maxAlerts: -1,
      remainingAlerts: 0,
      activeLicense: '',
    }

    if (props.selectedTenant && !getItem('SELECTED_CONTEXT')) {
      setItem('SELECTED_CONTEXT', getItem('CURRENT_TENANT'))
    }

    if(!props.selectedTenant && !getItem('SELECTED_CONTEXT')){
      const providerId = getItem('PROVIDER_ID')
      setItem('SELECTED_CONTEXT', {id: providerId, name:getItem('PROVIDER'), uuid: providerId})
    }
    
    this.getRemainingAlerts()
  }

  componentDidUpdate(prevProps: IAlertProps) {
    if (prevProps.tenantToggled !== this.props.tenantToggled) {
      const {
        storage: {
          get: { CURRENT_TENANT: tenant },
        },
      } = this.context

      if (tenant?.uuid) {
        this.setState({
          app: this.app,
        })
      } else {
        this.setState({
          app: 'generated_alerts',
        })
      }
    }

    if (prevProps.selectedTenant !== this.props.selectedTenant) {
      this.setState(prevState => ({
        dgfParameter: {
          ...prevState.dgfParameter,
          tenant: storage.get.CURRENT_TENANT.name,
          tenantUUID: storage.get.CURRENT_TENANT.uuid,
        },
        dashboard: {
          ...prevState.dashboard,
          context: this.props.selectedTenant
            ? 'tenant-custom'
            : 'provider-custom',
        },
      }))
    }
  }

  onChange = (e: any) => {
    this.app = e?.target?.value
    this.setState({
      app: this.app
    })
  }

  getRemainingAlerts = () => {
    const headers = {
      'X-Account': storage.get.CURRENT_TENANT.uuid,
    }
    this.TroubleShootService.getBackups(headers, null, [
      storage.get.PROVIDER,
      'remaining-alerts',
    ])
      .then((resp: IObject) => {
        this.setState({
          maxAlerts: resp?.data?.max_alerts,
          remainingAlerts: resp?.data?.remaining_alerts,
        })
      })
      .catch((err: IObject) => {
        this.setState({
          activeLicense: err?.response?.data
        })
      })
  }


  renderDashboard = (Dashboard: React.ReactNode) => {
    const { selectedTenant } = this.props
    return (
      <LogContent
        logConfig={logConfig}
        selectedTenant={selectedTenant}
        title={'Generated Alerts'}
      />
    )
  }

  renderGeneratedAlerts = () => {
    const { dgfParameter, dashboard } = this.state
    return (
      <ContentBody>
        <DGFProvider platform="hc" parameters={dgfParameter}>
          <DGF
            mode={'PROD'}
            dashboard={dashboard}
            renderDashboard={this.renderDashboard}
          />
        </DGFProvider>
      </ContentBody>
    )
  }

  renderApp = () => {
    const { tenantToggled, defaultMethods } = this.props
    const { app, remainingAlerts, maxAlerts, activeLicense } = this.state
    switch (app) {
      case 'generated_alerts': {
        return this.renderGeneratedAlerts()
      }
      case 'alert_config': {
        return (
          <AlertDefinition
            tenantToggled={tenantToggled}
            defaultMethods={defaultMethods}
            maxAlerts={maxAlerts}
            remainingAlerts={remainingAlerts}
            getRemainingAlerts={() => this.getRemainingAlerts()}
            activeLicense={activeLicense}
          />
        )
      }
      case 'triggers': {
        return (
          <Triggers
            tenantToggled={tenantToggled}
            defaultMethods={defaultMethods}
            maxAlerts={maxAlerts}
            remainingAlerts={remainingAlerts}
            getRemainingAlerts={() => this.getRemainingAlerts()}
          />
        )
      }
      case 'actions': {
        return (
          <Actions
            tenantToggled={tenantToggled}
            defaultMethods={defaultMethods}
            maxAlerts={maxAlerts}
            remainingAlerts={remainingAlerts}
            getRemainingAlerts={() => this.getRemainingAlerts()}
          />
        )
      }
      default: {
        return this.renderGeneratedAlerts()
      }
    }
  }

  render() {
    const { app } = this.state
    return (
      <>
        <ContentSection>
          <A10Row className={styles.radioGroupContainer}>
            <A10Radio.Group
              defaultValue="generated_alerts"
              buttonStyle="tab"
              onChange={this.onChange}
              value={app}
            >
              <A10Radio.Button value="generated_alerts">
                Generated Alerts
              </A10Radio.Button>
              <A10Radio.Button value="alert_config">
                Alert Configuration
              </A10Radio.Button>
              <A10Radio.Button value="triggers">Triggers</A10Radio.Button>
              <A10Radio.Button value="actions">Actions</A10Radio.Button>
            </A10Radio.Group>
          </A10Row>
          {this.renderApp()}
        </ContentSection>
      </>
    )
  }
}
export default setupA10Container(Alert)
