import React from 'react'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Card, A10Col, A10Descriptions } from '@gui-libraries/widgets'

import { AuthenticationService, Utilities } from 'src/services'
import storage from 'src/libraries/storage'
import AuthConfigurationEditForm from './Forms/AuthConfigurationEditForm'
import AuthValidateForm from './Forms/AuthValidateForm'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'

import './styles/authentication.scss'

const contentList = {
  ldap: {
    layout: [['Host', 'Port', 'CN', 'DN String']],
    fieldMap: {
      Host: 'urls',
      Port: 'urls',
      CN: '',
      'DN String': '',
    },
    formater: {
      Host: {
        format: (urls: any) => {
          if (_.isArray(urls) && urls.length) {
            const hosts: string[] = []
            const regexp = /\/\/(.+)\:(\d+)/
            urls.forEach(item => {
              if (_.isString(item) && item) {
                const result = item.match(regexp)
                if (result) {
                  hosts.push(result[1])
                }
              }
            })
            return hosts.join(', ') || 'N/A'
          }
          return 'N/A'
        },
      },
      Port: {
        format: (urls: any) => {
          if (_.isArray(urls) && urls.length) {
            const ports: string[] = []
            const regexp = /\/\/(.+)\:(\d+)/
            urls.forEach(item => {
              if (_.isString(item) && item) {
                const result = item.match(regexp)
                if (result) {
                  ports.push(result[2])
                }
              }
            })
            return ports[0] || 'N/A'
          }
          return 'N/A'
        },
      },
      CN: {
        format: (value: any, data: any) => {
          if (_.isObject(data)) {
            const { scheme, admindn, userdnpattern } = data as any
            if (scheme === 'searchDN') {
              return parseCN(admindn) || 'N/A'
            } else if (scheme === 'userDN') {
              if (_.isArray(userdnpattern) && userdnpattern.length) {
                const caches = userdnpattern
                  .map(pattern => parseCN(pattern))
                  .filter(item => !!item)
                return _.uniq(caches).join(' | ') || 'N/A'
              }
            }
          }
          return 'N/A'
        },
      },
      'DN String': {
        format: (value: any, data: any) => {
          if (_.isObject(data)) {
            const { scheme, admindn, searchdn, userdnpattern } = data as any
            const separator = ' | '
            if (scheme === 'searchDN') {
              return searchdn.join(separator) || 'N/A'
            } else if (scheme === 'userDN') {
              return userdnpattern.join(separator) || 'N/A'
            }
          }
          return 'N/A'
        },
      },
    },
    defaultMap: {},
  },
  radius: {
    layout: [['Host', 'Port', 'Retries', 'Authentication Mode']],
    fieldMap: {
      Host: 'end-point-hosts',
      Port: 'end-point-hosts',
      Retries: 'retries',
      'Authentication Mode': 'authentication-method',
    },
    formater: {
      Host: {
        format: (hostList: any) => {
          if (_.isArray(hostList) && hostList.length) {
            const hosts: string[] = []
            hostList.forEach(item => {
              const host = _.get(item, 'host')
              if (_.isString(host) && host) {
                hosts.push(host)
              }
            })
            return hosts.join(', ') || 'N/A'
          }
          return 'N/A'
        },
      },
      Port: {
        format: (hostList: any) => {
          if (_.isArray(hostList) && hostList.length) {
            const ports: string[] = []
            hostList.forEach(item => {
              const port = _.get(item, 'port')
              if (_.isString(port) || _.isNumber(port)) {
                ports.push(`${port}`)
              }
            })
            return ports[0] || 'N/A'
          }
          return 'N/A'
        },
      },
    },
    defaultMap: {},
  },
  tacacsplus: {
    layout: [
      [
        'Host',
        'Port',
        'Retries',
        // 'Remote Address',
        'Time Out',
        'Authentication Mode',
      ],
    ],
    fieldMap: {
      Host: 'end-point-hosts',
      Port: 'end-point-hosts',
      Retries: 'retries',
      'Authentication Mode': 'authentication-method',
      'Remote Address': 'remote-address',
      'Time Out': 'timeout',
    },
    formater: {
      Host: {
        format: (hostList: any) => {
          if (_.isArray(hostList) && hostList.length) {
            const hosts: string[] = []
            hostList.forEach(item => {
              const host = _.get(item, 'host')
              if (_.isString(host) && host) {
                hosts.push(host)
              }
            })
            return hosts.join(', ') || 'N/A'
          }
          return 'N/A'
        },
      },
      Port: {
        format: (hostList: any) => {
          if (_.isArray(hostList) && hostList.length) {
            const ports: string[] = []
            hostList.forEach(item => {
              const port = _.get(item, 'port')
              if (_.isString(port) || _.isNumber(port)) {
                ports.push(`${port}`)
              }
            })
            return ports[0] || 'N/A'
          }
          return 'N/A'
        },
      },
    },
    defaultMap: {},
  },
} as {
  [authType: string]: IContentConfig
}

function parseCN(pattern: string) {
  const list: Array<{ [key: string]: string }> = []
  pattern.split(',').forEach(item => {
    const equation = item.trim()
    const [key, value] = equation.split('=', 2).map(part => part.trim())
    if (_.isString(key) && key) {
      list.push({ [key]: value })
    }
  })

  let cnValue: string = ''
  list.some(item => {
    const key = Object.keys(item)[0]
    if (_.lowerCase(key) === 'cn') {
      cnValue = item[key]
      return true
    }
    return false
  })

  return cnValue
}

export interface IContentConfig {
  layout: string[][]
  fieldMap: {
    [key: string]: string
  }
  formater: {
    [key: string]: {
      format: (value: any, data: any, ...args: any[]) => any
      options?: any[]
    }
  }
  defaultMap: {
    [key: string]: any
  }
}

export interface IEndPointHost {
  host: string
  port: number
}

export interface ILdapAuthProvider {
  type: string
  inheritable: string
  urls: string[]
  scheme: 'userDN' | 'searchDN'
  userdnpattern?: string[]
  searchdn?: string[]
  'login-attribute'?: string[]
  admindn?: string
  adminpwd?: string
  'remote-authorization': boolean
  'mfa-enabled': boolean
  [otherKey: string]: any
}
export interface IRadiusAuthProvider {
  type: string
  inheritable: string
  'authentication-method': string
  'end-point-hosts': IEndPointHost[]
  retries?: number
  'shared-secret': string
  'remote-authorization': boolean
  'mfa-enabled': boolean
  [otherKey: string]: any
}
export interface ITacacsAuthProvider {
  type: string
  inheritable: string
  'authentication-method': string
  'end-point-hosts': IEndPointHost[]
  'remote-address'?: string
  retries?: number
  timeout?: number
  'remote-authorization': boolean
  'mfa-enabled': boolean
  'shared-secret': string
  [otherKey: string]: any
}
export type IAuthProviderObject = ILdapAuthProvider &
  IRadiusAuthProvider &
  ITacacsAuthProvider

export interface IAuthenticationProps extends IA10ContainerDefaultProps {}

export interface IAuthenticationState {
  authProvider?: IAuthProviderObject
  cachePayload?: IAuthProviderObject
  isLoading: boolean
  showConfigPage: boolean
  showValidatePage: boolean
}

class Authentication extends A10Container<
  IAuthenticationProps,
  IAuthenticationState
> {
  isOperatorUser = storage.get.IS_OPERATOR_USER
  private authenticationService = new AuthenticationService()
  private utilities = new Utilities()
  private childForm: React.ReactElement = null
  constructor(props: IAuthenticationProps) {
    super(props)

    this.state = {
      isLoading: true,
      showConfigPage: false,
      showValidatePage: false,
    }

    this.getAuthConfig()
  }

  getAuthConfig = () => {
    const provider = storage.get.PROVIDER || 'root'

    this.authenticationService
      .getAuthProvider({ provider }, null, [provider])
      .then((response: any) => {
        const authProvider = _.get(response, [
          'data',
          'authentication-provider',
        ])
        if (_.isObject(authProvider)) {
          const { type } = authProvider as any

          if (storage.get.USER_SESSION_AUTHTYPE !== type) {
            storage.set.USER_SESSION_AUTHTYPE(type)
          }

          this.setState({ authProvider: authProvider as IAuthProviderObject })
        }
      })
      .catch(error => {
        if (!this.utilities.isRBACAccessDenied(error)) {
          const message = _.get(error, ['response', 'data', 'message'], '')
          if (_.isString(message) && message) {
            this.utilities.showMessage(
              'Unable to get Authentication details',
              0,
              0,
              message,
            )
          } else {
            this.utilities.showMessage(
              'Unable to get Authentication details',
              0,
              0,
            )
          }
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  setConfigPage = (isOpen: boolean) => {
    this.setState({ showConfigPage: isOpen })
  }

  setValidatePage = (isOpen: boolean) => {
    this.setState({ showValidatePage: isOpen })
  }

  handleFormChange = (data: any) => {
    this.setState({
      cachePayload: data,
    })
  }

  handleSaveAuthConfig = () => {
    const { cachePayload } = this.state
    const provider = storage.get.PROVIDER || 'root'

    this.setState({ isLoading: true })
    this.authenticationService
      .updateAuthProvider({ provider }, cachePayload, [provider])
      .then((resp: any) => {
        this.utilities.showMessage(
          'Successfully updated Authentication Provider!',
          1,
          0,
        )
      })
      .catch(error => {
        if (!this.utilities.isRBACAccessDenied(error)) {
          const message = _.get(
            error,
            ['response', 'data', 'message'],
            'Failed to save Authentication Provider!',
          )
          if (message) {
            this.utilities.showMessage(message, 0, 0)
          }
        }
      })
      .finally(() => {
        this.getAuthConfig()

        this.setState({
          showConfigPage: false,
          showValidatePage: false,
        })
      })
  }

  handleValidateAuthConfig = (
    payload: any,
    userId: string,
    password: string,
  ) => {
    const provider = storage.get.PROVIDER || 'root'
    const validateHeader: any = {
      'Content-Type': 'application/json',
      provider,
    }

    const authType = payload.type
    if (
      authType === 'radius' ||
      authType === 'ldap' ||
      authType === 'tacacsplus'
    ) {
      validateHeader.user = userId
      validateHeader.password = new Buffer(password).toString('base64')
    }

    return this.authenticationService
      .validateProviderAuth(validateHeader, payload, [provider])
      .then((resp: any) => {
        return {
          status: true,
          message: _.get(resp, 'data', ''),
        }
      })
      .catch((error: any) => {
        return {
          status: false,
          message: _.get(error, 'response.data', ''),
        }
      })
  }

  handleFormValidation = () => {
    const { validateAndSubmitForm } = this.utilities
    if (this.childForm) {
      validateAndSubmitForm(this.childForm.props)
    }
  }

  renderContent = () => {
    const { authProvider } = this.state
    const authType = authProvider?.type || 'default'
    const mfaEnabled = authProvider && authProvider['mfa-enabled']
    const enforcedDate = authProvider && authProvider['enforcement-date']

    return (
      <div className="auth-content">
        {authType === 'default' || authType === 'local' ? (
          <div className="auth-card">
            <A10Card style={mfaEnabled ? { width: '80%' } : { width: '50%' }}>
              <A10Descriptions
                title="Authentication"
                layout="vertical"
                size="small"
                column={{ xxl: 7, xl: 7, lg: 7, md: 3, sm: 2, xs: 1 }}
              >
                {(authType === 'default' || authType === 'local') && (
                  <>
                    <A10Descriptions.Item label="Type">
                      Local
                    </A10Descriptions.Item>

                    {mfaEnabled && (
                      <>
                        <A10Descriptions.Item label="Multi-factor Authentication (MFA)">
                          Enabled
                        </A10Descriptions.Item>

                        <A10Descriptions.Item
                          label={
                            <div style={{ marginLeft: '156px' }}>
                              Enforcement Date
                            </div>
                          }
                        >
                          <div style={{ marginLeft: '156px' }}>
                            {enforcedDate}
                          </div>
                        </A10Descriptions.Item>
                      </>
                    )}
                  </>
                )}
              </A10Descriptions>
            </A10Card>
            <A10Card
              style={
                mfaEnabled && !storage.get.IS_SUPER_USER
                  ? { width: '20%' }
                  : { width: '50%' }
              }
            >
              <A10Descriptions
                title="Authorization"
                layout="vertical"
                size="small"
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                <A10Descriptions.Item
                  label="Type"
                  labelStyle={{ fontWeight: 'bold' }}
                >
                  Local
                </A10Descriptions.Item>
              </A10Descriptions>
            </A10Card>
          </div>
        ) : (
          this.renderConfig(authProvider)
        )}
      </div>
    )
  }

  renderConfig = (authProvider: IAuthProviderObject) => {
    const authType = authProvider.type
    const remoteAuthorization = authProvider['remote-authorization']
    const config = contentList[authType]

    if (config) {
      const { layout, fieldMap, formater, defaultMap } = config
      const restRows = _.isArray(layout)
        ? layout.map((rowConfig: string[]) => {
            if (_.isArray(rowConfig) && rowConfig.length) {
              return (
                <div className="auth-card">
                  <A10Card style={{ width: '80%' }}>
                    <A10Descriptions
                      className='auth-description'
                      title="Authentication"
                      layout="vertical"
                      size="small"
                      column={{ xxl: 6, xl: 6, lg: 6, md: 3, sm: 2, xs: 1 }}
                    >
                      <A10Descriptions.Item
                        label="Type"
                        labelStyle={{ fontWeight: 'bold' }}
                      >
                        {authType.toUpperCase()}
                      </A10Descriptions.Item>

                      {rowConfig.map((item: string, itemIndex: number) => {
                        if (_.isString(item) && item) {
                          const key = _.get(fieldMap, item, item)
                          const value = _.get(authProvider, key)

                          const formatConfig = _.get(formater, item)
                          if (!_.isNil(formatConfig)) {
                            const { format, options = [] } = formatConfig
                            let formatValue = value
                            if (_.isFunction(format)) {
                              formatValue = format(
                                value,
                                authProvider,
                                ...options,
                              )
                            }
                            return (
                              <A10Descriptions.Item
                                label={item}
                                labelStyle={{ fontWeight: 'bold' }}
                              >
                                {formatValue}
                              </A10Descriptions.Item>
                            )
                          } else if (
                            (_.isString(value) && value) ||
                            _.isNumber(value) ||
                            _.isArray(value)
                          ) {
                            let renderText = ''
                            if (_.isString(value) || _.isNumber(value)) {
                              renderText = `${value}`
                            } else if (_.isArray(value)) {
                              value.forEach((val, index) => {
                                if (_.isString(val) || _.isNumber(val)) {
                                  if (index !== value.length - 1) {
                                    renderText += `${val}, `
                                  } else {
                                    renderText += `${val}`
                                  }
                                }
                              })
                            }
                            return (
                              <A10Descriptions.Item
                                label={item}
                                labelStyle={{ fontWeight: 'bold' }}
                              >
                                {renderText}
                              </A10Descriptions.Item>
                            )
                          } else {
                            const defaultRender = _.get(defaultMap, item, 'N/A')
                            return (
                              <A10Descriptions.Item
                                label={item}
                                labelStyle={{ fontWeight: 'bold' }}
                              >
                                {defaultRender}
                              </A10Descriptions.Item>
                            )
                          }
                        }
                        return null
                      })}
                    </A10Descriptions>
                  </A10Card>
                  <A10Card style={{ width: '20%' }}>
                    <A10Descriptions
                      title="Authorization"
                      layout="vertical"
                      size="small"
                      column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                    >
                      <A10Descriptions.Item
                        label="Type"
                        labelStyle={{ fontWeight: 'bold' }}
                      >
                        {remoteAuthorization ? authType.toUpperCase() : 'LOCAL'}
                      </A10Descriptions.Item>
                    </A10Descriptions>
                  </A10Card>
                </div>
              )
            }
            return null
          })
        : []
      return restRows
    }
    return null
  }

  render() {
    const {
      isLoading,
      showConfigPage,
      showValidatePage,
      authProvider,
      cachePayload,
    } = this.state
    
    const enableEditConfig =
      !isLoading &&
      !this.isOperatorUser &&
      (storage.get.IS_SUPER_USER || storage.get.ADMIN_LEVEL === 'provider')

    return (
      <>
        <ContentSection>
          <ContentHeader type="flex" align="middle" justify="space-between">
            <A10Col>
              <ContentTitle title="User Authentication &amp; Authorization" />
            </A10Col>
            <A10Col>
              {enableEditConfig && (
                <ActionButton
                  text="Edit"
                  onClick={this.setConfigPage.bind(this, true)}
                  iconProps={{ app: 'global', type: 'edit' }}
                />
              )}
            </A10Col>
          </ContentHeader>
          <ContentBody isLoading={isLoading}>
            {this.renderContent()}
          </ContentBody>
        </ContentSection>
          <AuthConfigurationEditForm
            visible={showConfigPage}
            isLoading={isLoading}
            authProvider={authProvider}
            handleChange={this.handleFormChange}
            handleSubmit={this.handleFormValidation}
            onSubmitForm={this.handleSaveAuthConfig}
            onValidate={this.setValidatePage.bind(this, true)}
            onClose={this.setConfigPage.bind(this, false)}
            onRef={(ref: any) => {
              this.childForm = ref
            }}
          />
        <AuthValidateForm
          visible={showValidatePage}
          payload={cachePayload}
          validateAuth={this.handleValidateAuthConfig}
          onClose={this.setValidatePage.bind(this, false)}
        />
      </>
    )
  }
}

export default setupA10Container(Authentication)
