import {
  A10Row,
  A10Col,
  A10Form,
  A10Input,
  A10Table,
} from '@gui-libraries/widgets'
import {
  IA10ContainerDefaultProps,
  setupA10Container,
} from '@gui-libraries/framework'
import React, { useEffect, useState } from 'react'

import A10Panel from 'src/components/ADC/A10Panel'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import Messages from 'src/locale/en/Messages'
import '../styles/index.less'

const messages = new Messages()

interface IEmailTemplateProps extends IA10ContainerDefaultProps {
  show: boolean
  title: string
  emailSubject: string
  emailBody: string
  onClose: () => void
  form: any
  onActionCompleted: (value1: string, value2: string) => void
}

const EmailTemplateEditForm: React.FC<IEmailTemplateProps> = ({
  show,
  title,
  emailSubject,
  emailBody,
  form,
  onClose,
  onActionCompleted,
}) => {
  const [subject, setSubject] = useState(emailSubject)
  const [body, setBody] = useState(emailBody)
  const { getFieldDecorator } = form
  useEffect(() => {
    setSubject(emailSubject)
    setBody(emailBody)
  }, [emailSubject, emailBody])

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const columns = [
    {
      title: <>Variable</>,
      dataIndex: 'variable',
      key: 'variable',
    },
    {
      title: <>Description</>,
      dataIndex: 'description',
      key: 'description',
    },
  ]

  const tableData = [
    {
      variable: '${firstname}',
      description:
        'First Name of the user. This is applicable for all types of email configuration.',
    },
    {
      variable: '${uiserver}',
      description:
        'Domain name of the lightning controller. This is applicable for user activation email and welcome email content.',
    },
    {
      variable: '${user}',
      description:
        'Provider user id. This is applicable for user activation email and welcome email content.',
    },
    {
      variable: '${token}',
      description:
        'Authentication token. This is applicable for user activation email content.',
    },
    {
      variable: '${provider}',
      description:
        'Provider name. This is applicable for user activation email and welcome email content.',
    },
    {
      variable: '${tenant}',
      description:
        'Tenant name. This is applicable for user activation email and welcome email content.',
    },
    {
      variable: '${newPassword}',
      description:
        'New Password. This is applicable for password reset email content.',
    },
  ]

  const note =
    '<h3> Hi ${firstName} </h3> <br> <p> Harmony Controller account is waiting for you </p>'

  return (
    <FormatSlidingPage
      isOpen={show}
      onRequestOk={() => {
        form?.validateFieldsAndScroll({ force: true }, (err: any) => {
          if (!err) {
            onActionCompleted(subject, body)
          }
        })
      }}
      onRequestClose={onClose}
      title={title}
    >
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel showHeader={false} className='template-panel'>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="template-label mandatoryField">
                  {messages.SUBJECT}
                </span>
              </>
            }
          >
            {getFieldDecorator('subject', {
              rules: [{ required: true, message: messages.EMPTY_SUBJECT }],
              initialValue: subject,
            })(
              <A10Input
                testID="email-subject-input"
                text="text"
                onChange={event => {
                  setSubject(event?.target?.value)
                }}
                value={subject}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="template-label mandatoryField">
                  {messages.EMAIL_BODY}
                </span>
              </>
            }
          >
            {getFieldDecorator('emailBody', {
              rules: [{ required: true, message: messages.EMPTY_EMAIL_BODY }],
              initialValue: emailBody,
            })(
              <A10Input.TextArea
                className="input-component"
                testID="email-body-input"
                type="text"
                size="large"
                onChange={event => {
                  setBody(event?.target?.value)
                }}
              />,
            )}
          </A10Form.Item>
        </A10Panel>
        <A10Panel showHeader={false}>
          <A10Row className="template-sample-row">
            <A10Col>
              <span style={{ fontWeight: 'bold' }}>Note : </span> HTML tags are
              also supported.
            </A10Col>
            <A10Col>e.g. - {note} </A10Col>
          </A10Row>
          <A10Row>
            <A10Col className="template-sample-table-title">
              Following variables can be used while configuring the email
              content. These variable can be used for the description of the
              variable and will be replaced by the actual values by the system.
            </A10Col>
            <A10Table columns={columns} dataSource={tableData} />
          </A10Row>
        </A10Panel>
      </A10Form>
    </FormatSlidingPage>
  )
}

export default setupA10Container(A10Form.create()(EmailTemplateEditForm))
