import React, { useEffect, useState } from 'react'
import { A10Loader, A10Tabs, A10Modal } from '@gui-libraries/widgets'

import { httpClient } from 'src/libraries/httpClient'
import { removeQuotations } from 'src/containers/Controller/Organization/Eula/Eula'

import styles from './styles/index.module.less'

export interface IEulaActivationProps {
  show: boolean
  provider: string
  token?: string
  user?: string
  setVisible?: (show: boolean) => void
  isHelpSupport?: boolean
}

const EulaActivation = (props: IEulaActivationProps) => {
  // pass in user, token, and provider bc activation page will be outside of hc
  const { user, token, show, isHelpSupport, provider, setVisible } = props
  const EULA_BASE_URL = `/hpcapi/v3/provider/${provider}/eulainfo`
  const httpParams: IObject = {
    absoluteBasePath: true,
    headers: { 'user-id': user, token },
  }
  const [activationEulas, setActivationEulas] = useState([])
  const [isLoading, setLoading] = useState(true)
  const getEulas = async () => await httpClient.get(EULA_BASE_URL, httpParams)

  const getActivationEulas = async () => {
    const {
      data: { 'eulainfo-list': eulaList },
    } = await getEulas()
    const filteredEulas = eulaList.filter(
      (eula: IObject) =>
        eula.location === 'Activation Page' && eula.state === 'ACTIVE',
    )
    setActivationEulas(filteredEulas)
    setLoading(false)
  }
  const loading = (
    <div className={styles.loader}>
      <A10Loader iconOnly />
    </div>
  )
  const eulaContent = !isLoading ? (
    <section className={styles.activationContainers}>
      <div className={styles.title}>Agreements</div>
      <A10Tabs>
        {activationEulas.map((eula: IObject) => (
          <A10Tabs.TabPane tab={eula.title} key={eula['eula-key']}>
            <div className={styles.content}>
              {removeQuotations(eula.document)}
            </div>
          </A10Tabs.TabPane>
        ))}
      </A10Tabs>
    </section>
  ) : (
    loading
  )

  const noContent = !isLoading ? (
    <div className={styles.noNotes}>No Agreements Set</div>
  ) : (
    loading
  )

  const renderModal = (eulaContent: JSX.Element) => (
    <A10Modal
      visible={show}
      footer={[null, null]}
      destroyOnClose={true}
      onCancel={() => setVisible(false)}
      wrapClassName={styles.eulaActivationPage}
    >
      {eulaContent}
    </A10Modal>
  )
  useEffect(() => {
    // activation page needs user/token
    // help support does not need user/token
    if ((user && token) || (isHelpSupport && !user && !token)) {
      getActivationEulas()
    }
  }, [])

  // show for all users
  return activationEulas.length > 0
    ? renderModal(eulaContent)
    : renderModal(noContent)
}

export default EulaActivation
