import React from 'react'
import {
  A10Component,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
} from '@gui-libraries/framework'
import { A10Icon, A10Form, A10Select, A10Alert } from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import { Messages } from 'src/locale/en/'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import storage from 'src/libraries/storage'
import { Utilities } from 'src/services/index'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import RangeSlider from 'src/components/shared/RangeSlider/index'

export interface IChangeDeviceFormProps extends IA10ContainerDefaultProps {
  data: any[]
  form: any
  hcLicenseKey: string
  deviceLicenseKey: string
  allotedBandwidth: any
  activeLicenses: boolean[]
  onRef?: any
  onChange?: (data: any) => void
  onSubmitForm?: (data: any) => void
  deviceLicenseUnit: string
  selectedDevice: IObject
}
export interface IChangeDeviceFormState {
  formData: any
  avaialableHCLicenses: any
  avaialableDeviceLicenses: any
  updateAllotted: boolean
}

class ChangeDeviceForm extends A10Component<
  IChangeDeviceFormProps,
  IChangeDeviceFormState
> {
  formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  }
  Messages = new Messages()
  Utilities = new Utilities()
  licenseList: any = []

  constructor(props: IChangeDeviceFormProps) {
    super(props)
    const bandwidthObj = this.Utilities.megaToGiga(
      this.props.allotedBandwidth || 0,
    )

    this.state = {
      formData: {
        hcLicenseNewKey: undefined,
        deviceLicenseNewKey:
          this.props.deviceLicenseKey &&
          this.props.deviceLicenseKey.indexOf('-EXTERNAL-') === -1
            ? this.props.deviceLicenseKey
            : undefined,
        newBandwidth: this.props.deviceLicenseKey ? bandwidthObj.val : '',
        newBandwidthUnit: this.props.deviceLicenseKey ? bandwidthObj.unit : '',
        numBandwidth: this.props.deviceLicenseKey ? bandwidthObj.val : '',
        numBandwidthUnit: this.props.deviceLicenseKey ? bandwidthObj.unit : '',
      },
      avaialableHCLicenses: [],
      avaialableDeviceLicenses: [],
      updateAllotted: false,
    }
    this.getData()
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  setAvaialableLicenses(licenseList: any) {
    const avaialableHCLicenses = this.Utilities.getAvailableLicenseForReassign(
      licenseList,
      this.props.activeLicenses[0] ? this.props.hcLicenseKey : '',
      'HC_LICENSE',
      this.props.selectedDevice.model,
    )
    const avaialableDeviceLicenses = this.Utilities.getAvailableLicenseForReassign(
      licenseList,
      '',
      'DEVICE_LICENSE',
      this.props.selectedDevice.model,
    )

    let { formData, updateAllotted } = this.state
    if (formData.deviceLicenseNewKey) {
      const newLicObj = this.Utilities.filterObjByProperty(
        this.licenseList,
        'entitlement-key',
        formData.deviceLicenseNewKey,
      )
      const usedBandwidth = this.props.allotedBandwidth || 0
      if (!!newLicObj) {
        let remaining = parseFloat(newLicObj['remaining-bandwidth'])
        if (this.props.deviceLicenseKey === formData.deviceLicenseNewKey) {
          remaining += parseFloat(usedBandwidth)
        }
        const maxVal = parseFloat(
          (newLicObj['max-allocated-bandwidth'] > remaining ||
            newLicObj['max-allocated-bandwidth'] === 0) &&
            (newLicObj['license-type'] !== 'INSTANCE_FLEXPOOL' ||
              newLicObj['license-type'] !== 'SUBSCRIBER_FLEXPOOL')
            ? remaining
            : newLicObj['license-type'] === 'INSTANCE_FLEXPOOL' ||
              newLicObj['license-type'] !== 'SUBSCRIBER_FLEXPOOL'
            ? newLicObj['default-allocated-bandwidth']
            : newLicObj['max-allocated-bandwidth'],
        )
        const newbandwidthObj =
          this.props.deviceLicenseKey === formData.deviceLicenseNewKey
            ? this.Utilities.megaToGiga(usedBandwidth || 0)
            : this.Utilities.megaToGiga(maxVal || 0)
        if (newbandwidthObj.unit !== formData.newBandwidthUnit) {
          formData.newBandwidthUnit = newbandwidthObj.unit
          formData.numBandwidthUnit = newbandwidthObj.unit
          if (newbandwidthObj.unit === 'g') {
            formData.newBandwidth = parseFloat(
              (formData.newBandwidth / 1000).toFixed(2),
            )
          } else {
            formData.newBandwidth = formData.newBandwidth * 1000
          }
          formData.numBandwidth = formData.newBandwidth
          updateAllotted = !updateAllotted
        }
      }
    }

    this.setState({
      formData,
      updateAllotted,
      avaialableHCLicenses,
      avaialableDeviceLicenses,
    })
  }

  getData = () => {
    // Start to load data
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    httpRequest({
      namespace: getNS('LICENSE_LIST'),
      request: async (epicDependence: IObject) => {
        try {
          const { httpClient } = epicDependence
          const { data: res } = await httpClient.get(
            `/hls/licenses/providers/${storage.get.PROVIDER}`,
            {
              absoluteBasePath: true,
            },
          )
          const licenseList = res || []
          this.licenseList = licenseList
          this.setAvaialableLicenses(licenseList)
          return licenseList
        } catch (err) {
          // tslint:disable-next-line:no-console
          console.error(err)
        }
        return []
      },
    })
  }

  setNewCapacity = (e: any) => {
    const { formData } = this.state
    let capVal: any = 0
    this.state.avaialableDeviceLicenses.map((item: IObject, index: number) => {
      if (item['entitlement-key'] === e) {
        capVal = item
      }
    })
    if (capVal['license-type'] != 'INSTANCE_FLEXPOOL') {
      formData.newBandwidth = capVal
        ? capVal['min-allocated-bandwidth'] == 0 &&
          capVal['license-type'] == 'SUBSCRIBER_FLEXPOOL'
          ? 2
          : capVal['min-allocated-bandwidth'] / 1000
        : 0
    } else {
      formData.newBandwidth = capVal
        ? capVal['default-allocated-bandwidth'] / 1000
        : 0
    }
    this.setState({ formData })
  }
  handleChange = (stateName: string, e: any) => {
    let { formData, updateAllotted } = this.state
    formData[stateName] = e && e.target ? e.target.value : e

    if (stateName === 'deviceLicenseNewKey') {
      const licObj = this.Utilities.filterObjByProperty(
        this.licenseList,
        'entitlement-key',
        formData[stateName],
      )
      // const bandwidth = this.Utilities.megaToGiga(licObj['remaining-bandwidth'])
      const maxVal = !licObj
        ? 0
        : parseFloat(
            (licObj['max-allocated-bandwidth'] >
              licObj['remaining-bandwidth'] ||
              licObj['max-allocated-bandwidth'] === 0) &&
              licObj['license-type'] !== 'INSTANCE_FLEXPOOL'
              ? licObj['remaining-bandwidth']
              : licObj['license-type'] === 'INSTANCE_FLEXPOOL'
              ? licObj['default-allocated-bandwidth']
              : licObj['max-allocated-bandwidth'],
          )
      const bandwidth = this.Utilities.megaToGiga(maxVal || 0)
      let minBW = { val: 0, unit: '' }
      if (bandwidth.unit === 'm') {
        minBW['val'] = parseFloat(
          licObj && licObj['min-allocated-bandwidth']
            ? licObj['min-allocated-bandwidth']
            : 0,
        )
        minBW['unit'] = 'm'
      } else {
        const val =
          (licObj && licObj['min-allocated-bandwidth']
            ? licObj['min-allocated-bandwidth']
            : 0) / 1000
        minBW['val'] = parseFloat(val.toFixed(2))
        minBW['unit'] = 'g'
      }

      formData['newBandwidthUnit'] = bandwidth.unit
      if (!!this.props.deviceLicenseKey) {
        if (
          licObj['license-type'] !== 'INSTANCE_FLEXPOOL' &&
          licObj['license-type'] !== 'SUBSCRIBER_FLEXPOOL'
        ) {
          formData['newBandwidth'] = this.state.formData.numBandwidth
          formData['newBandwidthUnit'] = this.state.formData.numBandwidthUnit
        } else {
          formData['newBandwidth'] = bandwidth.val
          formData['newBandwidthUnit'] = this.state.formData.numBandwidthUnit
          updateAllotted = !updateAllotted
        }
      } else {
        formData['newBandwidth'] = minBW.val
      }
    }
    this.props.onChange(formData)
    // @ts-ignore
    this.setState({
      formData,
      updateAllotted,
    })
  }

  onSliderChange(data: any) {
    const { formData } = this.state
    if (data.name === 'Bandwidth') {
      formData['newBandwidth'] = data.updatedValue
    }

    this.props.onChange(formData)
    // @ts-ignore
    this.setState({
      formData,
    })
  }

  render() {
    const {
      hcLicenseNewKey,
      deviceLicenseNewKey,
      newBandwidth,
      newBandwidthUnit,
      numBandwidth,
      numBandwidthUnit,
    } = this.state.formData
    const { selectedDevice } =  this.props
    const licObj = selectedDevice?.['licenseinfo-list']?.find((item)=> item['license-type'] !== 'HC_LICENSE')
    const licenseType = licObj?.['license-type']
    const usedBandwidth = this.props.allotedBandwidth || 0

    const newLicObj = deviceLicenseNewKey
      ? this.Utilities.filterObjByProperty(
          this.licenseList,
          'entitlement-key',
          deviceLicenseNewKey,
        )
      : {}
    let maxVal = 0
    if (!!newLicObj) {
      let remaining = parseFloat(newLicObj['remaining-bandwidth'])
      if (this.props.deviceLicenseKey === deviceLicenseNewKey) {
        remaining += parseFloat(usedBandwidth)
      }
      maxVal = parseFloat(
        (newLicObj['max-allocated-bandwidth'] > remaining ||
          newLicObj['max-allocated-bandwidth'] === 0) &&
          newLicObj['license-type'] !== 'INSTANCE_FLEXPOOL'
          ? remaining
          : newLicObj['license-type'] === 'INSTANCE_FLEXPOOL'
          ? newLicObj['default-allocated-bandwidth']
          : newLicObj['max-allocated-bandwidth'],
      )
    }

    const newbandwidthObj = this.Utilities.megaToGiga(maxVal || 0)
    let minBW = { val: 0, unit: '' }
    let usedBW = { val: 0, unit: '' }
    if (newbandwidthObj.unit === 'm') {
      minBW['val'] = parseFloat(
        newLicObj && newLicObj['min-allocated-bandwidth']
          ? newLicObj['min-allocated-bandwidth']
          : 0,
      )
      minBW['unit'] = 'm'
      usedBW['val'] = parseFloat(
        newLicObj && newLicObj['used-bandwidth']
          ? newLicObj['used-bandwidth']
          : 0,
      )
      usedBW['unit'] = 'm'
    } else {
      const val =
        (newLicObj && newLicObj['min-allocated-bandwidth']
          ? newLicObj['min-allocated-bandwidth']
          : 0) / 1000
      minBW['val'] = parseFloat(val.toFixed(2))
      minBW['unit'] = 'g'
      const usedVal =
        (newLicObj && newLicObj['used-bandwidth']
          ? newLicObj['used-bandwidth']
          : 0) / 1000
      usedBW['val'] = parseFloat(usedVal.toFixed(2))
      usedBW['unit'] = 'g'
    }
    const currentDeviceLicense =
      this.props?.deviceLicenseKey?.replace('-EXTERNAL-', '') || ''

    const { getFieldDecorator } = this.props.form
    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        {this.props.hcLicenseKey != null ? (
          <A10Panel
            title={
              <A10IconTextGroup
                text={
                  <>
                    Harmony License Information
                    {/* <HelpInfo
                      customCls="custom-tooltip-head"
                      placement="right"
                      title="Harmony License Information"
                      helpKey="HELP_LICENSE_HARMONY_LICENSE"
                    /> */}
                  </>
                }
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            }
          >
            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  <span>{this.Messages.CURRENT_TOKEN}</span>
                  <span style={{ marginLeft: '-15px' }}>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.CURRENT_TOKEN}
                      helpKey="HELP_LICENSE_CURRENT_TOKEN"
                    />
                  </span>
                </>
              }
            >
              <span>{this.props.hcLicenseKey}</span>
            </A10Form.Item>
            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  <span>{this.Messages.SELECT_NEW_TOKEN}</span>
                  <span style={{ marginLeft: '-15px' }}>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.SELECT_NEW_TOKEN}
                      helpKey="HELP_LICENSE_NEW_TOKEN"
                    />
                  </span>
                </>
              }
            >
              <div className="row">
                <div className="col-md-8">
                  {getFieldDecorator('hcLicenseNewKey', {
                    rules: [
                      {
                        required: !deviceLicenseNewKey ? true : false,
                        message: this.Messages.LICENSE_REQUIRED,
                      },
                    ],
                    initialValue: hcLicenseNewKey,
                  })(
                    <A10Select
                      placeholder={this.Messages.LICENSE_REQUIRED}
                      onChange={(e: any) =>
                        this.handleChange('hcLicenseNewKey', e)
                      }
                    >
                      {this.state.avaialableHCLicenses.map(
                        (item: IObject, index: number) => {
                          return (
                            <A10Select.Option
                              key={item['entitlement-key']}
                              value={item['entitlement-key']}
                            >
                              {item['entitlement-key'] +
                                (item['license-name']
                                  ? ' - ' + item['license-name']
                                  : '')}
                            </A10Select.Option>
                          )
                        },
                      )}
                    </A10Select>,
                  )}
                </div>
              </div>
            </A10Form.Item>
            {this.state.avaialableHCLicenses &&
            this.state.avaialableHCLicenses.length === 0 ? (
              <A10Alert
                description="No other available license to change"
                type="warning"
                message=""
              />
            ) : null}
          </A10Panel>
        ) : null}

        {this.props.deviceLicenseKey !== null ? (
          <A10Panel
            title={
              <A10IconTextGroup
                text={
                  <>
                    Device License Information
                    {/* <HelpInfo
                      customCls="custom-tooltip-head"
                      placement="right"
                      title="Device License Information"
                      helpKey="HELP_LICENSE_DEVICE_LICENSE"
                    /> */}
                  </>
                }
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            }
          >
            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  <span>{this.Messages.CURRENT_TOKEN}</span>
                  <span style={{ marginLeft: '-15px' }}>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.CURRENT_TOKEN}
                      helpKey="HELP_LICENSE_CURRENT_TOKEN"
                    />
                  </span>
                </>
              }
            >
              <div className="row">
                <div className="col-md-8">
                  <span>{currentDeviceLicense || 'No License available'}</span>
                </div>
              </div>
            </A10Form.Item>
            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  <span>{this.Messages.CURRENT_LIC_CAPACITY}</span>
                  <span style={{ marginLeft: '-15px' }}>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.CURRENT_LIC_CAPACITY}
                      helpKey="HELP_LICENSE_CURRENT_CAPACITY"
                    />
                  </span>
                </>
              }
            >
              <div className="row">
                <div className="col-md-8">
                  <span>
                    {numBandwidth}{' '}
                    {numBandwidth
                      ? numBandwidthUnit === 'm'
                        ? 'Mbps'
                        : 'Gbps'
                      : ''}
                  </span>
                </div>
              </div>
            </A10Form.Item>

            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  <span>{this.Messages.SELECT_NEW_TOKEN}</span>
                  <span style={{ marginLeft: '-15px' }}>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.SELECT_NEW_TOKEN}
                      helpKey="HELP_LICENSE_NEW_TOKEN"
                    />
                  </span>
                </>
              }
            >
              <div className="row">
                <div className="col-md-8">
                  {getFieldDecorator('deviceLicenseNewKey', {
                    rules: [
                      {
                        required: !hcLicenseNewKey ? true : false,
                        message: this.Messages.LICENSE_REQUIRED,
                      },
                    ],
                    initialValue: deviceLicenseNewKey,
                  })(
                    <A10Select
                      placeholder={this.Messages.LICENSE_REQUIRED}
                      onChange={e => {
                        this.handleChange('deviceLicenseNewKey', e)
                      }}
                      onSelect={this.setNewCapacity}
                    >
                      {this.state.avaialableDeviceLicenses.map(
                        (item: IObject, index: number) => {
                          if (
                            !(
                              this.props.selectedDevice.model === 'cThunder' &&
                              item['license-type'] === 'SUBSCRIBER_FLEXPOOL'
                            )
                          ) {
                            return (
                              <A10Select.Option
                                key={item['entitlement-key']}
                                value={item['entitlement-key']}
                                title={
                                  item['entitlement-key'] +
                                  (item['license-name']
                                    ? ' - ' + item['license-name']
                                    : '')
                                }
                              >
                                {item['entitlement-key'] +
                                  (item['license-name']
                                    ? ' - ' + item['license-name']
                                    : '')}
                              </A10Select.Option>
                            )
                          }
                        },
                      )}
                    </A10Select>,
                  )}
                </div>
              </div>
            </A10Form.Item>

            {!!deviceLicenseNewKey ? (
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    <span>{this.Messages.NEW_CAPACITY}</span>
                    <span style={{ marginLeft: '-15px' }}>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.NEW_CAPACITY}
                        helpKey="HELP_LICENSE_NEW_CAPACITY"
                      />
                    </span>
                  </>
                }
              >
                <div className="row">
                  <div className="col-md-12">
                    <RangeSlider
                      deviceLicenseUnit={this.props.deviceLicenseUnit}
                      restrict={minBW.val || 0}
                      used={usedBW.val || 0}
                      allotted={parseFloat(
                        newLicObj !== undefined
                          ? newLicObj['license-type'] === 'INSTANCE_FLEXPOOL' ||
                            newLicObj['license-type'] === 'SUBSCRIBER_FLEXPOOL'
                            ? newBandwidth
                            : numBandwidth || 0
                          : numBandwidth || 0,
                      )}
                      updateAllotted={this.state.updateAllotted}
                      max={
                        newbandwidthObj && newbandwidthObj.unit === 'g'
                          ? newbandwidthObj.val
                          : maxVal || 99999999
                      }
                      min={minBW.val}
                      units={newbandwidthObj.unit === 'm' ? 'Mbps' : 'Gbps'}
                      inputFixedText={true}
                      placeholder=""
                      name="Bandwidth"
                      onChange={this.onSliderChange.bind(this)}
                      disabled={
                        newLicObj !== undefined
                          ? newLicObj['license-type'] === 'INSTANCE_FLEXPOOL' ||
                            newLicObj['license-type'] === 'SUBSCRIBER_FLEXPOOL'
                            ? true
                            : false
                          : (selectedDevice.model === 'vThunder' || selectedDevice.model === 'cThunder') &&
                            licenseType === 'PERPETUAL_LICENSE'
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
              </A10Form.Item>
            ) : null}

            {this.state.avaialableDeviceLicenses &&
            this.state.avaialableDeviceLicenses.length === 0 ? (
              <A10Alert
                description="No other available license to change"
                type="warning"
                message=""
              />
            ) : null}
          </A10Panel>
        ) : null}
      </A10Form>
    )
  }
}

function mapStateToProps(state: any, props: IChangeDeviceFormProps) {
  return {
    data: state.A10Data.getIn(getNS('LICENSE_LIST'), []),
  }
}

export default setupA10Container(
  A10Form.create()(ChangeDeviceForm),
  mapStateToProps,
)
