import React from 'react'
import PropTypes from 'prop-types'
import { RouteComponentProps } from 'react-router-dom'
import { List } from 'immutable'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
  _,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Layout,
  A10SlidingPage,
  A10Breadcrumb,
  A10DropdownMenu,
  A10Icon,
} from '@gui-libraries/widgets'
import { setAutoConfigDefaultParams } from '@gui-libraries/apps'

import { default as SlidingFormPartitionComment } from 'src/components/shared/PartitionComment/slidingForm'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { LeftNavProviderTenantMenu } from 'src/components/shared/LeftNavProviderTenantMenu'
import { ObjectTree } from './Common/ObjectTree'
import { DevicePartitionSelector } from './Common/DevicePartitionSelector'

import './styles/index.less'

export interface IObjectExplorerState {
  formatSlidingComponent: JSX.Element | null
  formComponent: JSX.Element | null
  listComponent: JSX.Element | null
  modalSize?: string
  tenant: string
  expandedKeys: List<string>
  eventKey: string
  isLoading: boolean
  titleOfFormSliding?: string
  routes: IObject[]
  isDevicePartition: boolean
  isLogicalPartition: boolean
  breadcrumbItems: string[]
  selectedPartition: IObject
  appType: string
  version: number
  dirty: number
}

export interface IObjectExplorerProps
  extends IA10ContainerDefaultProps,
    RouteComponentProps<{}> {
  tenantList: IObject[]
  singleDevice?: IObject
  singleDeviceSelectedPartition?: IObject
  singleClusterPartition?: IObject
  singleLP?: IObject
  propsForLeftNavProviderTenantMenu?: IObject
}

const { Header, Sider } = A10Layout

const BREADCRUMB_PREFIX = ['Object Explorer']
const DEFAULT_PARTITION_TYPE = 'shared'
const EVENT_KEY_PREFIX = 'global-obj-tree'

class ObjectExplorer extends A10Container<
  IObjectExplorerProps,
  IObjectExplorerState
> {
  static childContextTypes = {
    openFormatSlidingPage: PropTypes.func.isRequired,
    closeFormatSlidingPage: PropTypes.func.isRequired,
    openFormPage: PropTypes.func.isRequired,
    closeFormPage: PropTypes.func.isRequired,
    openListPage: PropTypes.func.isRequired,
    closeListPage: PropTypes.func.isRequired,
    setModalSize: PropTypes.func,
  }
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>

  constructor(props: IObjectExplorerProps) {
    super(props)

    // const currentTenant = getItem(CURRENT_TENANT, true) || DEFAULT_TENANT
    const { singleDevice } = props
    const sharedPartition = _.get(singleDevice, 'partition-list', []).find(
      (partition: IObject) => partition.type === DEFAULT_PARTITION_TYPE,
    )

    this.state = {
      formatSlidingComponent: null,
      formComponent: null,
      listComponent: null,
      tenant: '',
      modalSize: '',
      expandedKeys: List<string>(),
      eventKey: EVENT_KEY_PREFIX,
      isLoading: true,
      routes: [
        {
          breadcrumbName: 'Provider',
        },
      ],
      isDevicePartition: singleDevice ? true : false,
      isLogicalPartition: false,
      breadcrumbItems: [...BREADCRUMB_PREFIX],
      selectedPartition:
        this.props.singleDeviceSelectedPartition ||
        this.props.singleClusterPartition ||
        sharedPartition ||
        null,
      appType: '',
      version: 0,
      dirty: 0,
    }
  }

  getChildContext() {
    return {
      openFormatSlidingPage: this.openFormatSlidingPage,
      closeFormatSlidingPage: this.closeFormatSlidingPage,
      openFormPage: this.openFormPage,
      closeFormPage: this.closeFormPage,
      openListPage: this.openListPage,
      closeListPage: this.closeListPage,
      setModalSize: this.setModalSize,
    }
  }

  openFormatSlidingPage = (
    formatSlidingComponent: JSX.Element,
    titleOfFormSliding?: string,
    modalSize?: string,
  ) => {
    this.setState({
      formatSlidingComponent,
      titleOfFormSliding,
      modalSize,
    })
  }

  openFormPage = (formComponent: JSX.Element, modalSize?: string) => {
    this.setState({
      formComponent,
      modalSize,
    })
  }

  openListPage = (
    listComponent: JSX.Element,
    modalSize?: string,
    breadcrumbName?: string,
    updateBreadCrumb: boolean = true,
  ) => {
    this.setState((prevState: IObjectExplorerState) => {
      return {
        listComponent,
        modalSize,
        routes: updateBreadCrumb
          ? [...prevState.routes, { breadcrumbName }]
          : [...prevState.routes],
      }
    })
  }

  setModalSize = (modalSize: string) => {
    this.setState({ modalSize })
  }

  closeFormatSlidingPage = () => {
    this.setState({
      formatSlidingComponent: null,
      modalSize: '',
    })
  }

  closeFormPage = () => {
    this.setState({
      formComponent: null,
      modalSize: '',
    })
  }

  closeListPage = () => {
    const { routes } = this.state
    routes.pop()
    this.setState({ listComponent: null, modalSize: '', routes })
  }

  getHttpClient = () => {
    const {
      GLOBAL_CONFIG: {
        EPIC_DEPENDENCIES: { httpClient },
      },
    } = this.props
    return httpClient
  }

  updateAutoFormDefaultProps() {
    const {
      storage: { get },
    } = this.context
    const { tenant } = this.state

    const defaultParams = {
      provider: get.PROVIDER || 'root',
      tenant,
    }
    setAutoConfigDefaultParams(defaultParams)
  }

  onChangeAppType = (appType: string = '') => {
    const {
      storage: { get, set },
    } = this.context

    const lpDetail = get['object.explorer.lp.detail']
    lpDetail['app-type'] = appType
    set['object.explorer.lp.detail'](lpDetail)
    this.setState({
      appType,
    })
  }

  onSelectTree = (menu: IObject[], keys: string[], treeNodeEvent: IObject) => {
    if (keys.length > 0) {
      const [key] = keys
      if (key && key.includes(EVENT_KEY_PREFIX)) {
        const breadcrumbItems = [...BREADCRUMB_PREFIX]
        const sequence = key.split('-')
        // remove global-obj-tree
        sequence.splice(0, 3)
        let nextMenu = menu
        sequence.forEach(element => {
          const subMenu = nextMenu[element]
          const { title, menus } = subMenu
          breadcrumbItems.push(title)
          nextMenu = menus
        })

        this.setState({
          breadcrumbItems,
        })
      }
    }

    const { node } = treeNodeEvent
    this.expandNode(node)
  }

  onExpandTree = (expandedKeys: List<string>, treeNodeEvent: IObject) => {
    const { node } = treeNodeEvent
    this.expandNode(node)
  }

  expandNode = (node: IObject) => {
    const { expandedKeys } = this.state
    const { isLeaf, expanded, eventKey } = node.props

    if (isLeaf) {
      return
    }

    let newExpandedKeys = expandedKeys
    const index = expandedKeys.indexOf(eventKey)

    if (expanded) {
      if (index === 0) {
        newExpandedKeys = List<string>()
      } else {
        newExpandedKeys = newExpandedKeys.delete(index)
      }
    } else {
      if (newExpandedKeys.size === 0) {
        newExpandedKeys = List<string>([eventKey])
      } else {
        newExpandedKeys = newExpandedKeys.push(eventKey)
      }
    }
    this.setState((prevState: IObjectExplorerState) => {
      return {
        ...prevState,
        expandedKeys: newExpandedKeys,
        eventKey,
      }
    })
  }

  changeTenant = (tenant: string, isProviderMode: boolean) => {
    if (!isProviderMode) {
      const {
        storage: { set },
      } = this.context

      set.tenant(tenant)
      set['object.explorer.tenant.text'](tenant)
      this.setState(
        {
          tenant,
          expandedKeys: List<string>(),
          listComponent: null,
          isLoading: true,
        },
        () => {
          this.updateAutoFormDefaultProps()
          this.onObjectChange()
        },
      )
    } else {
      this.setState(
        {
          tenant: '',
          expandedKeys: List<string>(),
          listComponent: null,
          isLoading: true,
        },
        this.onObjectChange,
      )
    }
  }

  onChangePartition = (
    selectedPartition: IObject,
    isDevicePartition: boolean,
    isLogicalPartition: boolean,
    appType: string = '',
  ) => {
    let shouldChangeMode = false
    const {
      storage: { get, set },
    } = this.context

    if (selectedPartition && isDevicePartition) {
      shouldChangeMode =
        selectedPartition.name === get['object.explorer.partition.name']
      set['object.explorer.partition.name'](selectedPartition.name)
      set['object.explorer.partition.detail'](selectedPartition)
      set['object.explorer.device.text'](selectedPartition['device-name'])
    } else if (selectedPartition && isLogicalPartition) {
      shouldChangeMode =
        selectedPartition.name === get['object.explorer.lp.name']
      set['object.explorer.lp.name'](selectedPartition.name)
      set['object.explorer.lp.detail'](selectedPartition)
    }
    this.setState((prevState: IObjectExplorerState) => {
      shouldChangeMode =
        prevState.isLogicalPartition === isLogicalPartition
          ? shouldChangeMode
          : false
      return {
        ...prevState,
        expandedKeys: shouldChangeMode
          ? prevState.expandedKeys
          : List<string>(),
        listComponent: shouldChangeMode ? prevState.listComponent : null,
        isLoading: false,
        selectedPartition,
        isDevicePartition: !!selectedPartition && isDevicePartition,
        isLogicalPartition: !!selectedPartition && isLogicalPartition,
        appType,
      }
    }, this.onObjectChange)
  }

  onTenantChange = (newTenant: IObject, isProviderMode: boolean) => {
    const { propsForLeftNavProviderTenantMenu = {} } = this.props
    const { tenantChange = _.noop } = propsForLeftNavProviderTenantMenu
    const { name = '' } = newTenant

    tenantChange(newTenant, isProviderMode)
    this.changeTenant(name, isProviderMode)
  }
  updateDirtyAndVersion: any = async () => {
    const { selectedPartition, isDevicePartition } = this.state
    if (!!selectedPartition) {
      const httpClient = this.getHttpClient()
      const {
        data: {
          [isDevicePartition ? 'partition' : 'logical-partition']: tmpPartition,
        },
      } = await httpClient.get(selectedPartition['a10-url'])
      const version = tmpPartition.version || 0
      const dirty = tmpPartition.dirty || 0
      return { version, dirty }
    }
    return { version: 0, dirty: 0 }
  }

  delay = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  onCloseSaveCommentWindow = async (continueDeploy: boolean = false) => {
    this.closeFormatSlidingPage()
    const { version, dirty } = await this.updateDirtyAndVersion()
    this.setState({
      version,
      dirty,
    })
  }

  onSave = (type: string, detail: IObject) =>
    this.openFormatSlidingPage(
      <SlidingFormPartitionComment
        type={type}
        partition={detail}
        onRequestClose={this.onCloseSaveCommentWindow}
        isOpen={true}
      />,
    )
  onObjectChange = async () => {
    // const { version, dirty } = await this.updateDirtyAndVersion()
    // this.setState({
    //   dirty,
    //   version,
    // })
  }

  componentDidMount() {
    const { singleLP, singleDevice, singleClusterPartition } = this.props
    const { selectedPartition } = this.state
    const {
      storage: { get, set },
    } = this.context

    const currentTenant = get.CURRENT_TENANT
    set['object.explorer.tenant.text'](currentTenant.name)
    let version = selectedPartition?.version || 0
    let dirty = selectedPartition?.dirty || 0

    if (singleLP) {
      set['object.explorer.lp.name'](singleLP.name)
      set['object.explorer.lp.detail'](singleLP)
      version = singleLP.version || 0
      dirty = singleLP.dirty || 0
    } else if (singleDevice && selectedPartition) {
      set['object.explorer.partition.name'](selectedPartition.name)
      set['object.explorer.partition.detail'](selectedPartition)
      set['object.explorer.device.text'](singleDevice.name)
    } else if (singleDevice) {
      set['object.explorer.device.text'](singleDevice.name)
    }
    this.setState((prevState: IObjectExplorerState) => {
      return {
        ...prevState,
        version,
        dirty,
        selectedPartition: singleLP || prevState.selectedPartition,
        isLoading: false,
        isLogicalPartition: !singleClusterPartition
          ? singleLP
            ? true
            : prevState.isLogicalPartition
          : false,
        isDevicePartition: !singleClusterPartition
          ? singleDevice && !!selectedPartition
            ? true
            : prevState.isDevicePartition
          : false,
        appType: singleLP ? singleLP?.['app-type'] || '' : '',
      }
    })
    this.updateAutoFormDefaultProps()
  }

  componentDidUpdate() {
    const { propsForLeftNavProviderTenantMenu = {} } = this.props
    const { selectedTenant } = propsForLeftNavProviderTenantMenu
    if (selectedTenant) {
      // let parseSelectedTenant = { name: '' }
      // try {
      //   parseSelectedTenant = JSON.parse(selectedTenant)
      // } catch (ignore) {
      //   // ignore parse error
      // }

      const tenant = this.state?.tenant || ''
      const name = selectedTenant?.name || ''
      if (name !== tenant) {
        this.changeTenant(name, false)
      }
    }
  }

  render() {
    const {
      propsForLeftNavProviderTenantMenu = {},
      singleDevice,
      singleLP,
      singleClusterPartition,
    } = this.props

    const {
      formatSlidingComponent,
      formComponent,
      tenant,
      listComponent,
      modalSize,
      expandedKeys,
      eventKey,
      titleOfFormSliding,
      isLoading,
      isDevicePartition,
      isLogicalPartition,
      breadcrumbItems,
      appType,
      version,
      dirty,
      selectedPartition,
    } = this.state

    const {
      storage: { get },
    } = this.context

    const isSingleMode =
      !!singleDevice || !!singleLP || !!singleClusterPartition
    const detail = isLogicalPartition
      ? get['object.explorer.lp.detail']
      : isDevicePartition
      ? get['object.explorer.partition.detail']
      : {}
    const partitionType = isLogicalPartition
      ? detail && detail?.type === 'private-partition'
        ? 'l3v'
        : 'shared'
      : isDevicePartition
      ? detail && detail?.name === 'shared'
        ? 'shared'
        : 'l3v'
      : selectedPartition?.partitionType ?? 'shared'
    const type = isLogicalPartition
      ? 'logical'
      : isDevicePartition
      ? 'device'
      : ''
    const menu = [
      dirty === 1 && !!selectedPartition ? (
        <div key="save">Save</div>
      ) : (
        <div
          key="save"
          style={{
            pointerEvents: 'none',
            opacity: '0.4',
            cursor: 'not-allowed',
          }}
        >
          Save
        </div>
      ),
      // <div key="discard">Discard</div>,
      // <div key="deploy">Deploy</div>,
      // <div key="compare">Compare</div>,
    ]
    const onDropdownMenuClick = (element: IObject) => {
      const { key } = element
      switch (key) {
        case 'save':
          if (dirty === 1 && !!selectedPartition) {
            this.onSave.call(this, type, detail)
          }
          break
        // case 'discard':
        //   this.onDiscard(detail)
        //   break
        // case 'deploy':
        //   this.openFormatSlidingPage(
        //     <SlidingFormVersionComparison
        //       type={type}
        //       partition={detail}
        //       onRequestClose={this.onCloseCompareWindow}
        //       isOpen={true}
        //       onClickSave={this.onSave.bind(this, type, detail)}
        //     />,
        //   )
        //   break
        // case 'compare':
        //   this.openFormatSlidingPage(
        //     <SlidingFormVersionComparison
        //       type={type}
        //       partition={detail}
        //       onRequestClose={this.onCloseCompareWindow}
        //       isOpen={true}
        //       onClickSave={this.onSave.bind(this, type, detail)}
        //     />,
        //   )
        //   break
      }
    }

    const {
      tenantChange,
      tenants,
      selectedTenant,
      refreshTenants,
      leftNavCollapsed,
    } = propsForLeftNavProviderTenantMenu

    return (
      <div
        className={`object-explorer-container ${
          tenantChange ? 'goe-tree-based' : 'goe-tree-based singleMode'
        }
        ${leftNavCollapsed ? ' left-nav-collapsed' : ''}`}
      >
        <A10Layout>
          {!isSingleMode ? (
            <Header>
              <A10Breadcrumb className="breadcrumb">
                <LeftNavProviderTenantMenu
                  tenantChange={this.onTenantChange}
                  tenants={tenants}
                  selectedTenant={selectedTenant}
                  refreshTenants={refreshTenants}
                  leftNavCollapsed={leftNavCollapsed}
                />

                <DevicePartitionSelector
                  onChange={this.onChangePartition}
                  tenant={tenant}
                  appType={appType}
                />
                {/* <A10DropdownMenu
                  trigger="hover"
                  arrowPointAtCenter={true}
                  title={dirty ? 'Candidate' : `v${version}`}
                  menu={menu}
                  onClick={onDropdownMenuClick}
                /> */}

                <A10Icon app="global" type="separator-16" />

                {breadcrumbItems.map((element, index) => {
                  if (index !== breadcrumbItems.length - 1) {
                    return <A10Breadcrumb.Item>{element}</A10Breadcrumb.Item>
                  } else {
                    return (
                      <A10Breadcrumb.Item className="sub-menu">
                        {element}
                      </A10Breadcrumb.Item>
                    )
                  }
                })}
              </A10Breadcrumb>
            </Header>
          ) : null}

          <Sider width="600px">
            <A10Layout>
              <A10Layout>
                <Sider className="goe-sider goe-menu" width="50%">
                  {tenantChange && (
                    <span className="sider-header">Object Explorer</span>
                  )}

                  {!this.props.singleDeviceSelectedPartition &&
                    !singleClusterPartition &&
                    !this.props.singleLP &&
                    !tenantChange && (
                      <div className="single-device">
                        <DevicePartitionSelector
                          onChange={this.onChangePartition}
                          tenant={tenant}
                          appType={appType}
                          defaultDevice={this.props.singleDevice}
                          defaultLP={this.props.singleLP}
                          selectedPartition={this.state.selectedPartition}
                        />
                        {this.state.selectedPartition ? (
                          <A10DropdownMenu
                            trigger="hover"
                            arrowPointAtCenter={true}
                            title={dirty ? 'Candidate' : `v${version}`}
                            menu={menu}
                            onClick={onDropdownMenuClick}
                          />
                        ) : null}
                      </div>
                    )}
                  <ObjectTree
                    eventKey={eventKey}
                    baseKey="global-obj-tree"
                    expandedKeys={expandedKeys.toArray()}
                    onExpandTree={this.onExpandTree}
                    onSelectTree={this.onSelectTree}
                    isLogicalPartition={isLogicalPartition}
                    isDevicePartition={isDevicePartition}
                    isClusterPartition={
                      !isDevicePartition && !isLogicalPartition
                    }
                    partitionType={partitionType}
                    isRenderCustomizedTree={!!listComponent}
                    isLoading={isLoading}
                    appType={appType}
                    onChangeAppType={this.onChangeAppType}
                    onObjectChange={this.onObjectChange}
                    selectedPartition={this.state.selectedPartition}
                  />
                </Sider>
                {listComponent ? (
                  <Sider className="goe-sider" width="50%">
                    <listComponent.type {...listComponent.props} />
                  </Sider>
                ) : null}
              </A10Layout>
            </A10Layout>
          </Sider>
        </A10Layout>

        <A10SlidingPage
          isOpen={!!formComponent}
          modalSize={modalSize}
          onRequestClose={this.closeFormPage}
        >
          {formComponent ? (
            <formComponent.type {...formComponent.props} />
          ) : null}
        </A10SlidingPage>

        <FormatSlidingPage
          isOpen={!!formatSlidingComponent}
          modalSize={modalSize}
          title={titleOfFormSliding}
          onRequestClose={this.closeFormatSlidingPage}
          isShowFooterButtons={false}
          isRightCancel={true}
        >
          {formatSlidingComponent ? (
            <formatSlidingComponent.type {...formatSlidingComponent.props} />
          ) : null}
        </FormatSlidingPage>
      </div>
    )
  }
}

function mapStateToProps(state: any) {
  let nameSpace: string[] = []
  try {
    nameSpace = getNS('TENANT_LIST')
  } catch (ignore) {
    // ignore exception
  }

  return {
    tenantList: state.A10Data.getIn(nameSpace, []),
  }
}

export default setupA10Container(ObjectExplorer, {
  mapStateToProps,
  isWithRouter: true,
})
