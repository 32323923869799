import React from 'react'
import ReactLoading from 'react-loading'
import {
  _,
  getNS,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10Button,
  A10Menu,
  A10Row,
  A10Col,
  A10Icon,
  A10Input,
  A10Tooltip,
  A10SlidingPage,
  A10Dropdown,
  A10DropdownMenu,
  A10Modal,
  A10Message,
} from '@gui-libraries/widgets'
import { AutoFormV15 } from '@gui-libraries/apps'

import { RoundNumber } from 'src/components/shared/RoundNumber'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import {
  getObjectGroupList,
  getObjectGroupCount,
} from 'src/redux/httpServices/SharedObj/ObjectGroup'

const pageSize = 20

type ObjectGroupType = 'Application' | 'Network' | 'Service'

interface IObjectGroupInfo {
  name: string
  mode: string
  type: ObjectGroupType
  count: number
  objects: string[]
  uuid: string
}

export interface IObjectGroupProps extends IA10ContainerDefaultProps {
  data: IObjectGroupInfo[]
  total?: number
  scope: string
}

export interface IObjectGroupState {
  type: ObjectGroupType
  isLoading: boolean
  isOpenForm: boolean
  pageNumber: number
  searchText: string
  selectedObjectName: string
  selectedObjectUuid: string
  deleteModalVisible: boolean
  viewMode: boolean
}

class ObjectGroup extends A10Container<IObjectGroupProps, IObjectGroupState> {
  delayedSearchText = _.debounce(searchString => {
    this.getData(searchString)
  }, 800)
  defaultColumns: any[]
  constructor(props: IObjectGroupProps) {
    super(props)
    this.state = {
      type: null,
      isOpenForm: false,
      isLoading: false,
      pageNumber: 1,
      searchText: '',
      selectedObjectName: '',
      selectedObjectUuid: '',
      deleteModalVisible: false,
      viewMode: false,
    }
  }

  componentDidMount() {
    const { scope } = this.props
    this.getData()
    this.setState({
      viewMode: !(
        scope !== 'provider' ||
        (storage.get.ADMIN_LEVEL === 'provider' &&
          !storage.get.IS_OPERATOR_USER)
      ),
    })
  }

  getData = (searchText = '') => {
    if (this.state.isLoading) {
      return
    }
    this.setState({ isLoading: true })
    const { pageNumber } = this.state
    const param: IObject = {
      start: (pageNumber - 1) * pageSize,
      count: pageSize,
    }
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    if (searchText) {
      param.name = encodeURIComponent(searchText)
    }
    try {
      httpRequest(getObjectGroupCount(searchText))
      httpRequest(getObjectGroupList(param)).finally(() => {
        this.setState({
          isLoading: false,
        })
      })
    } catch (e) {
      this.setState({
        isLoading: false,
      })
      console.log(e)
    }
  }

  onClickCreateMenu = (param: any) => {
    this.setState({
      isOpenForm: true,
      type: param.key,
      selectedObjectName: null,
    })
  }

  renderCreateMenu = () => {
    return (
      <A10Menu onClick={this.onClickCreateMenu}>
        <A10Menu.Item key="Application">Application</A10Menu.Item>
        <A10Menu.Item key="Network">Network</A10Menu.Item>
        <A10Menu.Item key="Service">Service</A10Menu.Item>
      </A10Menu>
    )
  }

  renderSlidingPage = () => {
    const { isOpenForm, selectedObjectName, type, viewMode } = this.state
    const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/pso/logical-partition/pso/`
    const param: IObject = {}
    if (selectedObjectName !== null) {
      if (type === 'Application') {
        param['app-name'] = selectedObjectName
      } else if (type === 'Network') {
        param['net-name'] = selectedObjectName
      } else if (type === 'Service') {
        param['svc-name'] = selectedObjectName
      }
    }
    const schemaPath =
      type === 'Application'
        ? 'shared-objects/object-group/application'
        : type === 'Network'
        ? 'shared-objects/object-group/network'
        : type === 'Service'
        ? 'shared-objects/object-group/service'
        : null
    return (
      <A10SlidingPage isOpen={isOpenForm} onRequestClose={this.handleClose}>
        {isOpenForm && (
          <AutoFormV15
            hccEnv={true}
            schemaPath={schemaPath}
            apiPrefix={apiPrefix}
            params={param}
            viewMode={viewMode}
            interceptor={{
              onSubmitSuccess: this.handleAddOK,
              onCancel: this.handleClose,
            }}
          />
        )}
      </A10SlidingPage>
    )
  }

  renderDeleteModal = () => {
    const { deleteModalVisible } = this.state

    return (
      <A10Modal
        title="Confirmation"
        visible={deleteModalVisible}
        onOk={this.handleOk}
        onCancel={this.handleDeleteCancel}
        footer={[
          <A10Button
            key="no"
            className="nobtn"
            type="primary"
            onClick={this.handleDeleteCancel}
          >
            No
          </A10Button>,
          <A10Button
            key="yes"
            className="yesbtn"
            type="default"
            onClick={this.handleOk}
          >
            Yes
          </A10Button>,
        ]}
      >
        <p>Do you want to delete this Item?</p>
      </A10Modal>
    )
  }
  handleAdd = () => {
    this.setState({
      selectedObjectName: '',
      selectedObjectUuid: '',
      isOpenForm: true,
    })
  }

  handleOk = async () => {
    const {
      selectedObjectUuid,
      selectedObjectName,
      searchText,
      pageNumber,
      type,
    } = this.state
    const { total } = this.props
    try {
      await httpClient.delete(`/hpcapi/v3/uuid/${selectedObjectUuid}`)
      if (Math.ceil((total - 1) / pageSize) < pageNumber) {
        this.setState({ pageNumber: pageNumber - 1 }, () => {
          this.getData(searchText)
        })
      } else {
        this.getData(searchText)
      }
      this.setState({ deleteModalVisible: false })
      A10Message.success(
        `Success: ${type} object group ${selectedObjectName} has been deleted!`,
      )
    } catch (e) {
      // do nothing
      A10Message.error(
        `Error: Failed to delete ${type} object group ${selectedObjectName}!`,
      )
    }
  }

  handleDeleteCancel = () => {
    this.setState({
      deleteModalVisible: false,
    })
  }

  handleAddOK = () => {
    const { searchText } = this.state
    this.handleClose()
    this.getData(searchText)
  }

  handleClose = () => {
    this.setState({
      isOpenForm: false,
      selectedObjectName: '',
      selectedObjectUuid: '',
    })
  }

  onClickAction = (record: IObject, component: JSX.Element) => {
    if (component.key === 'edit' || component.key === 'view') {
      this.setState({
        type: record.type,
        selectedObjectUuid: record.uuid,
        selectedObjectName: record.name,
        isOpenForm: true,
      })
    } else if (component.key === 'delete') {
      this.setState({
        deleteModalVisible: true,
        selectedObjectName: record.name,
        selectedObjectUuid: record.uuid,
        type: record.type,
      })
    }
  }

  handleSearch = (e: any) => {
    const searchString = e.target.value
    this.setState(
      {
        searchText: searchString,
        pageNumber: 1,
      },
      () => {
        this.delayedSearchText(searchString)
      },
    )
  }

  handlePageChange = (page: number) => {
    const { searchText } = this.state
    this.setState({ pageNumber: page }, () => {
      this.getData(searchText)
    })
  }
  render() {
    const { isLoading, pageNumber, viewMode } = this.state
    const { total = 0, data = [] } = this.props
    const defaultColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        className: 'td-truncate',
        width: '30%',
        render: (text: any, record: any) => {
          return (
            <A10Tooltip
              placement="topLeft"
              title={record.name}
              overlayClassName="toolTipWidth"
            >
              {record.name || text}
            </A10Tooltip>
          )
        },
      },
      {
        title: 'Mode',
        dataIndex: 'mode',
        key: 'mode',
        className: 'td-truncate',
        width: '10%',
        render: (text: any, record: any) => {
          return (
            <A10Tooltip
              placement="topLeft"
              title={record.mode}
              overlayClassName="toolTipWidth"
            >
              {record.mode || text}
            </A10Tooltip>
          )
        },
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        className: 'td-truncate',
        width: '10%',
        render: (text: any, record: any) => {
          return (
            <A10Tooltip
              placement="topLeft"
              title={record.type}
              overlayClassName="toolTipWidth"
            >
              {record.type || text}
            </A10Tooltip>
          )
        },
      },
      {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        className: 'td-truncate',
        width: '10%',
        render: (text: any, record: any) => {
          return (
            <A10Tooltip
              placement="topLeft"
              title={record.count}
              overlayClassName="toolTipWidth"
            >
              {record.count || text}
            </A10Tooltip>
          )
        },
      },
      {
        title: 'Objects',
        dataIndex: 'objects',
        key: 'objects',
        className: 'td-truncate',
        render: (text: any, record: any) => {
          return (
            <A10Tooltip
              placement="topLeft"
              title={record.objects.join(', ')}
              overlayClassName="toolTipWidth"
            >
              {record.objects.join(', ') || text}
            </A10Tooltip>
          )
        },
      },
      {
        key: 'actions',
        width: 36,
        render: (record: IObjectGroupInfo) => {
          return (
            <div className="actions-column">
              <i>
                <A10DropdownMenu
                  title={null}
                  menu={
                    !viewMode
                      ? [
                          <div key="edit">Edit</div>,
                          <div key="delete">Delete</div>,
                        ]
                      : [<div key="view">View</div>]
                  }
                  overlayClassName="object-group-menu"
                  style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={this.onClickAction.bind(this, record)}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </i>
            </div>
          )
        },
      },
    ]

    return (
      <>
        <A10Row type="flex" align="middle" className="action-container">
          <A10Col lg={8}>
            <div className="table-header-inner pull-left">
              <span className="page-header">Object Group</span>
              <RoundNumber number={total} />
            </div>
          </A10Col>
          <A10Col lg={16}>
            <div className="table-header table-header-inner">
              {!viewMode ? (
                <>
                  <A10Dropdown overlay={this.renderCreateMenu()}>
                    <A10Button className="ant-dropdown-link action-button">
                      <A10Icon
                        app="global"
                        type="add-new"
                        className="action-icon"
                      />{' '}
                      Add <A10Icon type="down" className="action-icon" />
                    </A10Button>
                  </A10Dropdown>
                  <span
                    className="vertical-split-divider"
                    style={{ margin: '18px' }}
                  />
                </>
              ) : null}

              <div className="searchbar-container">
                <A10Input.Search
                  onChange={this.handleSearch}
                  type="text"
                  name="searchBox"
                  placeholder="Search"
                />
              </div>
            </div>
          </A10Col>
        </A10Row>
        <A10Table
          columns={defaultColumns}
          dataSource={data}
          size="small"
          loading={
            isLoading
              ? {
                  indicator: (
                    <div>
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    </div>
                  ),
                }
              : false
          }
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            current: pageNumber,
            total,
            onChange: this.handlePageChange,
          }}
        />
        {this.renderSlidingPage()}
        {this.renderDeleteModal()}
      </>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    data: state.A10Data.getIn(getNS('OBJECT_GROUP_LIST'), []),
    total: state.A10Data.getIn(getNS('OBJECT_GROUP_COUNT'), 0),
  }
}

export default setupA10Container(ObjectGroup, mapStateToProps)
