import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Radio, A10Row, A10Tabs } from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import {
  TroubleShootService,
  InfrastructureService,
  OrganizationService,
  Utilities,
} from 'src/services'

import { CliSnippets } from 'src/containers/Controller/TroubleShoot/Forms/CliSnippets'
import { ScheduledTasks } from 'src/containers/Controller/TroubleShoot/Forms/ScheduledTasks'
import { RunCommands } from 'src/containers/Controller/TroubleShoot/RunCommands'
import { ContentSection } from 'src/components/shared/ContentSection'

const styles = require('./styles/index.module.less')

const { TabPane } = A10Tabs

export interface IDeviceCLIProps extends IA10ContainerDefaultProps {
  tenantName: string
}

export interface IDeviceCLIState {
  tabValue: string
  maxSnippets: number
  remainingSnippets: number
  activeLicense: string
  devicesDetail: IObject[]
  tenantDetail: IObject
}

class DeviceCLI extends A10Container<IDeviceCLIProps, IDeviceCLIState> {
  TroubleShootService = new TroubleShootService()
  InfrastructureService = new InfrastructureService()
  OrganizationService = new OrganizationService()
  Utilities = new Utilities()

  constructor(props: any) {
    super(props)
    this.state = {
      tabValue: 'runCommand',
      maxSnippets: -1,
      remainingSnippets: 0,
      activeLicense: '',
      devicesDetail: [],
      tenantDetail: {},
    }
  }
  dataLoaded = false

  componentDidMount() {
    this.getRemainingSnippets()
    this.getDevicesDetail()
    this.getCurrentTenantDetail()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tenantName !== this.props.tenantName) {
      this.getCurrentTenantDetail()
    }
  }

  tabChange = (event: any) => {
    let { tabValue } = this.state
    if (event.target) {
      if (event.target.type === 'checkbox') {
        tabValue = event.target.checked
      } else {
        tabValue = event.target.value
      }
    } else {
      tabValue = event
    }

    this.setState({ tabValue })
  }

  getRemainingSnippets = () => {
    this.TroubleShootService.getBackups(null, null, [
      storage.get.PROVIDER,
      'remaining-cli-snippets',
    ])
      .then((resp: IObject) => {
        this.setState({
          maxSnippets: resp?.data?.max_snippets,
          remainingSnippets: resp?.data?.remaining_snippets,
        })
      })
      .catch((err: IObject) => {
        this.setState({
          activeLicense: err?.response?.data
        })
      })
  }

  // On-demand get devices detail
  getDevicesDetail = async () => {
    const deviceResp = await this.InfrastructureService.getDevices(null, null, [
      storage.get.PROVIDER,
    ])

    this.setState({ devicesDetail: deviceResp?.data?.['device-list'] || [] })
  }

  // On-demand get current tenant detail
  getCurrentTenantDetail = async () => {
    const { tenantName } = this.props

    if (tenantName) {
      const tenantResp = await this.OrganizationService.getTenantObj(
        null,
        null,
        [storage.get.PROVIDER, tenantName],
      )
      this.setState({ tenantDetail: tenantResp?.data?.tenant || {} })
    } else {
      this.setState({ tenantDetail: {} })
    }
  }

  render() {
    const { tenantName } = this.props
    const {
      tabValue,
      maxSnippets,
      remainingSnippets,
      activeLicense,
      devicesDetail,
      tenantDetail,
    } = this.state

    return (
      <>
        <ContentSection>
          <A10Row className={styles.radioGroupContainer}>
            <A10Tabs
              onChange={this.tabChange}
              type="card"
              className="cli-snippets-tab"
            >
              <TabPane key={'runCommand'} tab={'Run Command'} />
              <TabPane key={'cliSnippets'} tab={'CLI Snippets'} />
              <TabPane key={'scheduledTask'} tab={'Scheduled Tasks'} />
            </A10Tabs>
          </A10Row>
          {tabValue === 'runCommand' ? (
            <RunCommands
              tenantName={tenantName}
              maxSnippets={maxSnippets}
              remainingSnippets={remainingSnippets}
              getRemainingSnippets={this.getRemainingSnippets}
              devicesDetail={devicesDetail}
              tenantDetail={tenantDetail}
              activeLicense={activeLicense}
            />
          ) : tabValue === 'cliSnippets' ? (
            <CliSnippets
              maxSnippets={maxSnippets}
              remainingSnippets={remainingSnippets}
              getRemainingSnippets={this.getRemainingSnippets}
              activeLicense={activeLicense}
              devicesDetail={devicesDetail}
              tenantDetail={tenantDetail}
            />
          ) : tabValue === 'scheduledTask' ? (
            <ScheduledTasks
              tenantName={tenantName}
              maxSnippets={maxSnippets}
              remainingSnippets={remainingSnippets}
              getRemainingSnippets={this.getRemainingSnippets}
              devicesDetail={devicesDetail}
              tenantDetail={tenantDetail}
            />
          ) : null}
        </ContentSection>
      </>
    )
  }
}

export default setupA10Container(DeviceCLI)
