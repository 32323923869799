import React from 'react'
import propTypes from 'prop-types'
import { A10Container, setupA10Container } from '@gui-libraries/framework'
import { A10Table, A10Button, A10Icon, A10Row } from '@gui-libraries/widgets'
import ReactLoading from 'react-loading'

import AssociateVPort from '../AssociateVPort'
import AppServiceAssociateDeploy from '../AppServiceAssociateDeploy'
// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

export interface IDeployedVPortListProps {
  name: string
  type: string
  data: any[]
  tenant: string
  isLoading?: boolean
}

export interface IDeployedVPortListState {
  showDeployVPortSlidingPage: boolean
  showAssociateDeploySlidingPage: boolean
}

const defaultVPortColumns = [
  {
    title: 'vPort',
    dataIndex: 'name',
    key: 'name', // TODO: change this key
  },
  {
    title: 'Protocol',
    dataIndex: 'protocol',
    key: 'protocol',
  },
  {
    title: 'Port',
    dataIndex: 'port-number',
    key: 'port-number',
  },
  {
    title: 'VIP',
    dataIndex: 'vip',
    key: 'vip',
  },
]

class DeployedVPortList extends A10Container<
  IDeployedVPortListProps,
  IDeployedVPortListState
> {
  static contextTypes = {
    openSlidingPage: propTypes.func.isRequired,
    refreshTemplateList: propTypes.func.isRequired,
  }

  constructor(props: any) {
    super(props)
    this.state = {
      showDeployVPortSlidingPage: false,
      showAssociateDeploySlidingPage: false,
    }
  }

  onTriggerDeployVPort = (
    isOpen: boolean,
    record?: IObject,
    needRefresh?: boolean,
  ) => {
    const { tenant, name, type, data } = this.props
    this.context.openSlidingPage(
      isOpen ? (
        <AssociateVPort
          name={name}
          type={type}
          isShow={isOpen}
          tenant={tenant}
          existVPorts={data}
          onTriggerSwitch={this.onTriggerDeployVPort}
        />
      ) : null,
      'middle',
    )

    if (needRefresh) {
      this.context.refreshTemplateList()
    }
  }

  onTriggerAssociateDeploy = (isOpen: boolean, record: any) => {
    this.setState({ showAssociateDeploySlidingPage: isOpen })
  }

  render() {
    const { data = [] } = this.props
    return (
      <React.Fragment>
        <A10Row className="action-container">
          <div className="table-header action-wrap">
            <A10Button
              className="action-button"
              onClick={this.onTriggerDeployVPort.bind(this, true)}
            >
              <A10Icon app="global" type="add-new" className="action-icon" />
              Assign
            </A10Button>
          </div>
        </A10Row>
        <div className="inner-table">
          <A10Table
            className="hc-list"
            columns={defaultVPortColumns}
            dataSource={data.map((item: IObject, index: number) => {
              item.key = index
              return item
            })}
            size="small"
            loading={
              this.props.isLoading
                ? {
                    indicator: (
                      <div className="loading-icon">
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
          />
        </div>

        <AppServiceAssociateDeploy
          isShow={this.state.showAssociateDeploySlidingPage}
          onTriggerSwitch={this.onTriggerAssociateDeploy}
        />
      </React.Fragment>
    )
  }
}

export default setupA10Container(DeployedVPortList)
