import React, { useEffect, useState } from 'react'
import { A10Col, A10Descriptions, A10Row } from '@gui-libraries/widgets'
import ReactLoading from 'react-loading'
import {
  ContentBody,
  ContentHeader,
  ContentSection,
  ContentTitle,
} from 'src/components/shared/ContentSection'
import ListPanel from 'src/components/ADC/ListPanel'
import { ActionButton } from 'src/components/shared/ActionButton'

import { Utilities, PlatformService } from 'src/services/index'

import { APIConfigurationEditForm } from './APIConfigurationEditForm'
import { AnalyticsConfigEditForm } from './AnalyticsConfigEditForm'
import { LogProcessing } from '../../Dashboard/Resource/LogProcessing/index'

import './styles/index.less'

const utilities = new Utilities()
const platformService = new PlatformService()
let childForm: React.ReactElement = null

export interface IConfigObjectProps {
  property_name: string
  property_value: string
  property_display_name: string
}

export interface ILogRateProps {
  capacity: number
  default_capacity: number
  remaining_hc_capacity: number
  shared_capacity: number
}

export interface ILogRateState {
  capacity: number
  shared: number
  dedicated: number
  reserved: number
}

interface ILogRateConfigProps {
  name: string
  key: string
}

const logRateConfigs: ILogRateConfigProps[] = [
  {
    name: 'Capacity',
    key: 'capacity',
  },
  {
    name: 'Reserved Pool',
    key: 'reserved',
  },
  {
    name: 'Shared Pool',
    key: 'shared',
  },
  {
    name: 'Dedicated Pool',
    key: 'dedicated',
  },
]

const handleMicroServicePost = async (
  component: string,
  action: string,
  properties: IConfigObjectProps[],
  successMessage: string,
  failureMessage: string,
) => {
  let apiResponse: any = null
  const requestPayload = {
    component: component,
    action: action,
    properties: properties,
  }
  try {
    const response = await platformService.postConfigDetails(
      null,
      requestPayload,
      null,
    )
    if (response) {
      utilities.showMessage(successMessage, 1, 1)
      apiResponse = response?.data
    }
  } catch {
    utilities.showMessage(failureMessage, 0, 1)
  }
  return apiResponse
}

export const getAnalyticsConfigDetails = async () => {
  let apiResponse: any = []
  try {
    const { data: response } = await platformService.getAnalyticsConfigDetails(
      null,
      null,
      null,
    )
    apiResponse = response
  } catch (error) {
    apiResponse = []
  }
  return apiResponse
}

export const getAPIServerDetails = async () => {
  let apiResponse: any = []
  try {
    const { data: response } = await platformService.getAPIServerDetails(
      null,
      null,
      null,
    )
    apiResponse = response
  } catch (error) {
    apiResponse = []
  }
  return apiResponse
}

export const getLogRate = async () => {
  let apiResponse: any = []
  try {
    const { data: response } = await platformService.getLogRateDetails(
      null,
      null,
      null,
    )
    apiResponse = response
  } catch (error) {
    apiResponse = []
  }

  return apiResponse
}

const MicroServiceConfig: React.FC<IObject> = props => {
  const [showAPIConfigForm, setShowAPIConfigForm] = useState(false)
  const [showAnalyticsConfigForm, setShowAnalyticsConfigForm] = useState(false)
  const [showChangeLogRateForm, setShowChangeLogRateForm] = useState(false)
  const [apiInactivityTimeout, setApiInactivityTimeout] = useState<
    IConfigObjectProps
  >(null)
  const [analyticsConfig, setAnalyticsConfig] = useState<IConfigObjectProps[]>(
    [],
  )
  const [logRateDetails, setLogRateDetails] = useState<ILogRateState>(null)
  const [isAPIInactivityLoading, setAPIInactivityLoading] = useState(false)
  const [isAnalyticsConfigLoading, setAnalyticsConfigLoading] = useState(false)
  const [isLogRateLoading, setLogRateLoading] = useState(false)

  useEffect(() => {
    async function fetchAPIServerData() {
      setAPIInactivityLoading(true)
      const data: IObject = await getAPIServerDetails()
      setApiInactivityTimeout(data[0])
      setAPIInactivityLoading(false)
    }
    async function fetchAnalyticsConfigData() {
      setAnalyticsConfigLoading(true)
      const data: IConfigObjectProps[] = await getAnalyticsConfigDetails()
      setAnalyticsConfig(data)
      setAnalyticsConfigLoading(false)
    }

    fetchAPIServerData()
    fetchAnalyticsConfigData()
    fetchLogData()
  }, [])

  const onEditAPIConfigSaveClick = async (timeout: string) => {
    const properties = [{ ...apiInactivityTimeout, property_value: timeout }]
    const response = await handleMicroServicePost(
      'API Server',
      'EXECUTE',
      properties,
      'SUCCESS_API_SERVER_UPDATE',
      'FAILED_API_SERVER_UPDATE',
    )
    if (response) {
      setApiInactivityTimeout(response.properties[0])
    }
    setShowAPIConfigForm(!!false)
  }

  const onEditAnalyticsConfigOkClick = async (
    requestPayload: IConfigObjectProps[],
  ) => {
    const response = await handleMicroServicePost(
      'Analytics Metrics Processing Engine',
      'EXECUTE',
      requestPayload,
      'SUCCESS_ANALYTICS_CONFIG_UPDATE',
      'FAILED_ANALYTICS_CONFIG_UPDATE',
    )
    if (response) {
      const data = await getAnalyticsConfigDetails()
      setAnalyticsConfig(data)
    }
    setShowAnalyticsConfigForm(!!false)
  }

  const getAnalyticsPropValue = (analyticsKey: string) => {
    const analyticsObject = analyticsConfig.find(
      config => config.property_display_name === analyticsKey,
    )
    return analyticsObject
  }

  const fetchLogData = async () => {
    setLogRateLoading(true)
    const data: ILogRateProps = await getLogRate()
    const {
      shared_capacity: shared = 0,
      default_capacity: total = 0,
      capacity: maxUsable = 0,
    } = data

    const totalDedicatedPool = (maxUsable - shared) || 0
    const reservedPool = (total - maxUsable) || 0
    const logData = {
      capacity: total,
      reserved: reservedPool,
      shared: shared,
      dedicated: totalDedicatedPool
    }
    setLogRateDetails(logData)
    setLogRateLoading(false)
  }

  const logRetaintionPeriodObj = getAnalyticsPropValue('Logs Retention Period')
  const automaticLogRateObj = getAnalyticsPropValue('Automatic log-rate')
  const matricsRetaintionPeriodObj = getAnalyticsPropValue(
    'metric-aggregation-proc.agg.retention.period.months',
  )
  const alertRetaintionPeriodObj = getAnalyticsPropValue(
    'Alerts Retention Period',
  )

  return (
    <>
      <ContentSection>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <ContentTitle title="Microservice Config" />
        </ContentHeader>
        <ContentBody className="content-body">
          <A10Row>
            <A10Col
              span={6}
              style={{ paddingRight: '10px' }}
              className="heading"
            >
              <ListPanel
                title="API Settings"
                rightContent={
                  <ActionButton
                    className="edit-button"
                    text="Edit"
                    onClick={() => setShowAPIConfigForm(!!true)}
                    iconProps={{ app: 'global', type: 'edit' }}
                  />
                }
              >
                {isAPIInactivityLoading ? (
                  <A10Col>
                    <ReactLoading
                      type="bars"
                      color="#4a90e2"
                      height={40}
                      width={40}
                    />
                  </A10Col>
                ) : (
                  <A10Row
                    type="flex"
                    className="no-margin font-14 config-details"
                    key="api-row"
                  >
                    <A10Col className="config-title">
                      API Inactivity Timeout
                    </A10Col>
                    <A10Col style={{ paddingBottom: '5px' }}>
                      {apiInactivityTimeout?.property_value} Sec
                    </A10Col>
                  </A10Row>
                )}
              </ListPanel>
            </A10Col>
            <A10Col span={18} className="heading">
              <ListPanel
                title="Analytics Config"
                rightContent={
                  <ActionButton
                    text="Edit"
                    onClick={() => setShowAnalyticsConfigForm(!!true)}
                    iconProps={{ app: 'global', type: 'edit' }}
                  />
                }
              >
                {isAnalyticsConfigLoading ? (
                  <A10Col>
                    <ReactLoading
                      type="bars"
                      color="#4a90e2"
                      height={40}
                      width={40}
                    />
                  </A10Col>
                ) : (
                  <A10Row
                    className="no-margin font-14 config-details"
                    key="analytics-row"
                  >
                    <A10Descriptions
                      title=""
                      layout="vertical"
                      size="small"
                      column={{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }}
                    >
                      <A10Descriptions.Item label="Log Retention Period" className='config-menu'>
                        {`${logRetaintionPeriodObj?.property_value || ''} ${
                          parseInt(logRetaintionPeriodObj?.property_value) === 1
                            ? 'hour'
                            : 'hours'
                        }`}
                      </A10Descriptions.Item>
                      <A10Descriptions.Item label="Automatic Log Rate" className='config-menu'>
                        {automaticLogRateObj?.property_value || ''}
                      </A10Descriptions.Item>
                      <A10Descriptions.Item label="Metrics Retention Period" className='config-menu'>
                        {`${matricsRetaintionPeriodObj?.property_value ||
                          ''} Months`}
                      </A10Descriptions.Item>
                      <A10Descriptions.Item label="Alert Retention Period" className='config-menu'>
                        {`${alertRetaintionPeriodObj?.property_value || ''} ${
                          parseInt(analyticsConfig[3]?.property_value) <= 1
                            ? 'Month'
                            : 'Months'
                        }`}
                      </A10Descriptions.Item>
                    </A10Descriptions>
                  </A10Row>
                )}
              </ListPanel>
            </A10Col>
          </A10Row>
        </ContentBody>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col>
            <ContentTitle title="Harmony Log Rate Processing Capacity Config" />
          </A10Col>
          <A10Col style={{ marginRight: '32px' }}>
            <ActionButton
              text="Edit"
              onClick={() => setShowChangeLogRateForm(!!true)}
              iconProps={{ app: 'global', type: 'edit' }}
            />
          </A10Col>
        </ContentHeader>
        <ContentBody className="content-body harmony-log-rate">
          <ListPanel title="">
            {isLogRateLoading ? (
              <A10Col>
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </A10Col>
            ) : (
              <A10Row className="row rowPadding" key="log-rate-row">
                {logRateConfigs.map((capacityConfig: ILogRateConfigProps) => {
                  return (
                    <A10Col span={6} className="config-details-wrapper">
                      <A10Row
                        type="flex"
                        className="no-margin font-14 config-details"
                        style={{ flexDirection: 'column' }}
                      >
                        <A10Col className="config-title">
                          {capacityConfig.name}
                        </A10Col>
                        <A10Col>
                          {logRateDetails &&
                            logRateDetails[capacityConfig.key] / 1000}{' '}
                          K/s
                        </A10Col>
                      </A10Row>
                    </A10Col>
                  )
                })}
              </A10Row>
            )}
          </ListPanel>
        </ContentBody>
      </ContentSection>
      <APIConfigurationEditForm
        show={showAPIConfigForm}
        title="Edit API Configuration"
        timeout={apiInactivityTimeout?.property_value}
        onActionCompleted={onEditAPIConfigSaveClick}
        onClose={() => {
          setShowAPIConfigForm(!!false)
        }}
        onRef={(ref: any) => {
          childForm = ref
        }}
      />

      <AnalyticsConfigEditForm
        show={showAnalyticsConfigForm}
        title="Edit Analytics Configuration"
        logRetaintionPeriod={logRetaintionPeriodObj}
        automaticLogRate={automaticLogRateObj}
        alertRetaintionPeriod={alertRetaintionPeriodObj}
        onActionCompleted={onEditAnalyticsConfigOkClick}
        onClose={() => {
          setShowAnalyticsConfigForm(!!false)
        }}
        onRef={(ref: any) => {
          childForm = ref
        }}
      />
      {showChangeLogRateForm && (
        <LogProcessing
          show={showChangeLogRateForm}
          isOConsole
          onClose={() => setShowChangeLogRateForm(false)}
          getLogRateData={fetchLogData}
        />
      )}
    </>
  )
}

export default MicroServiceConfig
