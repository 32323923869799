import React from 'react'

import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import { PasswordResetForm } from './index'
import {
  Utilities,
  DashboardService,
  AuthenticationService,
} from 'src/services'
import { Messages } from 'src/locale/en/Messages'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'

export interface IPasswordResetFormActionsProps
  extends IA10ContainerDefaultProps {
  onRef?: any
  show: boolean
  title: string
  description: string
  providerName: string
  onActionCompleted: any
  onClose: () => void
}

export interface IPasswordResetFormActionsState {
  show: boolean
  title: string
  isLoading: boolean
  passwordReset: any
  description: string
}

class PasswordResetFormActions extends A10Container<
  IPasswordResetFormActionsProps,
  IPasswordResetFormActionsState
> {
  static getDerivedStateFromProps(
    nextProps: IPasswordResetFormActionsProps,
    prevState: IPasswordResetFormActionsState,
  ) {
    if (nextProps.show !== prevState.show) {
      return {
        show: nextProps.show,
        title: nextProps.title,
        description: nextProps.description,
      }
    }
    return null
  }

  Messages = new Messages()
  Utilities = new Utilities()
  dashboardService = new DashboardService()
  authenticationService = new AuthenticationService()

  private childForm: any = null

  constructor(props: IPasswordResetFormActionsProps) {
    super(props)

    this.state = {
      isLoading: false,
      passwordReset: {},
      show: this.props.show,
      title: this.props.title,
      description: this.props.description,
    }
  }

  closeResetPasswordForm = () => {
    this.setState({ show: false, passwordReset: {} })
    this.props.onClose()
  }

  handleFormChange = (data: any) => {
    this.setState({
      passwordReset: data,
    })
  }

  handleFormValidation = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  resetPassword = () => {
    const payload = { ...this.state.passwordReset }

    if (payload['reset-type'] === 'set') {
      this.changePassword(payload)
      return
    }
    this.emailResetPasswordLink()
  }

  changePassword = (payload: any) => {
    if (payload.password !== payload['confirm-password']) {
      this.Utilities.showMessage(
        "Password & Confirm Password doesn't match!",
        0,
        0,
      )
      return
    }

    const data: any = this.dashboardService.serializeData({
      password: payload.password,
    })
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      charset: 'utf-8',
      provider: 'root',
      np: this.props.providerName,
    }
    // userPass = this.dashboardService.serializeData(userPass)
    const updateUser = this.authenticationService.updateAdminPassword(
      headers,
      data,
      'provider-admin',
    )
    updateUser
      .then((response: any) => {
        this.Utilities.showMessage('CHANGE_PASSWORD_SUCCESS', 1, true)
        this.closeResetPasswordForm()
      })
      .catch((error: any) => {
        this.Utilities.showMessage('CHANGE_PASSWORD_FAIL', 0, true)
      })
  }

  emailResetPasswordLink = () => {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      charset: 'utf-8',
      provider: this.props.providerName,
    }
    // userPass = this.dashboardService.serializeData(userPass)
    const resetProviderPassword = this.authenticationService.resetProviderPassword(
      headers,
      null,
      'provider-admin',
    )
    resetProviderPassword
      .then((response: any) => {
        let message: string = this.Messages.SUCCESS_PASSWORD_REQUEST
        message =
          typeof response.data.message !== 'undefined'
            ? response.data.message
            : message
        this.Utilities.showMessage(message, 1, false)
        this.closeResetPasswordForm()
      })
      .catch((error: any) => {
        let message: string = 'Failed to send password reset instructions'
        message =
          typeof error.response.data.message !== 'undefined'
            ? error.response.data.message
            : message
        this.Utilities.showMessage(message, 0, false)
      })
  }

  render() {
    return (
      <FormatSlidingPage
        isOpen={this.state.show}
        onRequestOk={this.handleFormValidation}
        onRequestClose={this.closeResetPasswordForm}
        title={this.state.title}
        description={this.state.description}
        disableSave={this.state.isLoading}
      >
        <PasswordResetForm
          handleChange={this.handleFormChange}
          onSubmitForm={this.resetPassword}
          onRef={(ref: any): any => (this.childForm = ref)}
        />
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(PasswordResetFormActions)
