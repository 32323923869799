import React, { useState, useEffect } from 'react'
import {
  A10Form,
  A10Input,
  A10Switch,
  A10Radio,
  A10Upload,
  A10Icon,
  A10Button,
} from '@gui-libraries/widgets'

import { removeQuotations } from '../../Eula'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { A10Panel } from 'src/components/ADC/A10Panel'
import { Utilities } from 'src/services/index'
import { HelpInfo } from 'src/components/shared/HelpInfo'

import styles from './styles/EulaSlidingPage.module.less'

export interface IEulaSlidingPageProps {
  eulas: IObject
  open: boolean
  isEditing: boolean
  close: () => void
  addEula: (eula: IObject) => Promise<any>
  editEula: (eula: IObject) => Promise<any>
  updateTable: () => void
  editingEula?: IObject
}

const FormValidationType = '' || 'success' || 'error'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
}

const DEFAULT_VALUES = {
  title: '',
  comment: '',
  document: '',
  'where-to-show': 'Activation Page',
  enabled: true,
}
const EulaSlidingPage = (props: IEulaSlidingPageProps) => {
  const utilities = new Utilities()
  const {
    open,
    eulas,
    isEditing,
    editingEula,
    addEula,
    editEula,
    updateTable,
    close,
  } = props
  // changeable values
  const [titleValue, setTitleValue] = useState(DEFAULT_VALUES.title)
  const [commentValue, setCommentValue] = useState(DEFAULT_VALUES.comment)
  const [document, setDocument] = useState(DEFAULT_VALUES.document)
  const [activeRadio, setActiveRadio] = useState(
    DEFAULT_VALUES['where-to-show'],
  )
  const [enabled, setEnabled] = useState(DEFAULT_VALUES.enabled)
  const setFormValues = (eula: IObject) => {
    const {
      title,
      comment,
      document: newDoc,
      'where-to-show': activeType,
      state,
    } = eula
    const active = typeof state === 'undefined' || state === 'ACTIVE'
    setTitleValue(title)
    setCommentValue(comment)
    setDocument(newDoc)
    setActiveRadio(activeType)
    setEnabled(active)

    // reset form notifications
    setValidateTitleStatus('' as typeof FormValidationType)
    setValidateDocumentStatus('' as typeof FormValidationType)
  }
  const getNewEula = () =>
    isEditing && editingEula ? editingEula : DEFAULT_VALUES
  useEffect(() => {
    const newEula = getNewEula()
    setFormValues(newEula)
  }, [isEditing, eulas])
  // validation
  const [validateTitle, setValidateTitleStatus] = useState(
    '' as typeof FormValidationType,
  )
  const [validateDocument, setValidateDocumentStatus] = useState(
    '' as typeof FormValidationType,
  )
  const onRadioChange = (e: any) => {
    if (e.target.value) {
      setActiveRadio(e.target.value)
    }
  }
  const onTitleChange = (e: any) => {
    setTitleValue(e.target.value)
  }
  const onEnable = (checked: boolean) => {
    setEnabled(checked)
  }
  const onCommentChange = (e: any) => {
    if (e.target.value) {
      setCommentValue(e.target.value)
    }
  }

  const doesTitleExist = () =>
    !isEditing && eulas.some((eula: IObject) => eula.title === titleValue)
  const isTitleValid = () => titleValue.length > 0 && titleValue.length <= 32
  const isDocumentValid = () => document.length > 0

  const areInputsValid = () => {
    return isTitleValid() && isDocumentValid() && !doesTitleExist()
  }
  const getEulaTypeCount = () => {
    let afterCount = 0
    let activateCount = 0

    eulas.forEach((eula: IObject) =>
      eula['where-to-show'] === 'After Login' ? afterCount++ : activateCount++,
    )
    return [afterCount, activateCount]
  }
  const handleSave = () => {
    const eula = { titleValue, activeRadio, enabled, commentValue, document }
    const [afterCount, activateCount] = getEulaTypeCount()
    // check if we can add; must have less than five pre
    if (activeRadio === 'After Login' && afterCount >= 5) {
      utilities.showMessage('Max number of Welcome Notes created.', 0, false)
    } else if (activeRadio === 'Activation Page' && activateCount >= 5) {
      utilities.showMessage('Max number of Agreements created.', 0, false)
    } else {
      // validate form
      if (areInputsValid()) {
        if (isEditing) {
          editEula(eula)
            .then(() => {
              updateTable()
              setFormValues(DEFAULT_VALUES)
              close()
            })
            .catch((err: any) => {
              console.error(err)
            })
        } else {
          addEula(eula)
            .then(() => {
              updateTable()
              setFormValues(DEFAULT_VALUES)
              close()
            })
            .catch((err: any) => {
              console.error(err)
            })
        }
      } else {
        if (doesTitleExist && !isEditing) {
          utilities.showMessage(
            'Title already exists, please pick another title',
            0,
            false,
          )
        }
        const invalidTitle = !isTitleValid()
        const invalidDocument = !isDocumentValid()
        if (invalidTitle && invalidDocument) {
          setValidateTitleStatus('error')
          setValidateDocumentStatus('error')
          utilities.showMessage(
            'Please keep title between 1 to 32 characters and provide a document',
            0,
            false,
          )
        } else if (invalidTitle) {
          setValidateTitleStatus('error')
          utilities.showMessage(
            'Please keep title between 1 to 32 characters',
            0,
            false,
          )
        } else if (invalidDocument) {
          setValidateDocumentStatus('error')
          utilities.showMessage('Please provide a document.', 0, false)
        }
      }
    }
  }
  const titleHelp = {
    help: validateTitle === 'error' ? 'Title required.' : null,
  }
  const documentHelp = {
    help: validateDocument === 'error' ? 'Document required.' : null,
  }

  const closeRequest = () => {
    setValidateDocumentStatus('' as typeof FormValidationType)
    setValidateTitleStatus('' as typeof FormValidationType)
    setFormValues(getNewEula())
    close()
  }
  return (
    <FormatSlidingPage
      title="Add / Edit an Agreement"
      description="Please provide detail of the document"
      isOpen={open}
      onRequestClose={closeRequest}
      onRequestOk={handleSave}
    >
      <A10Panel
        title={
          <>
            <A10Icon
              app="global"
              type="form-section"
              className={styles.labelIcon}
            />
            User Agreement
          </>
        }
      >
        <A10Form layout="horizontal">
          <A10Form.Item
            rules={[{ required: true, message: 'Please enter a title' }]}
            label={
              <>
                <span>Title</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title="Title"
                    helpKey="HELP_ORG_AGREEMENTS_DOC_TITTLE"
                  />
                </span>
              </>
            }
            validateStatus={validateTitle}
            {...titleHelp}
            {...formItemLayout}
          >
            <A10Input
              placeholder="Enter a Title ( 32 characters max )"
              maxLength={32}
              onChange={onTitleChange}
              value={titleValue}
              disabled={isEditing}
            />
          </A10Form.Item>
          <A10Form.Item
            label={
              <>
                <span>Enable</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title="Enable"
                    helpKey="HELP_ORG_AGREEMENTS_DOC_ENABLE"
                  />
                </span>
              </>
            }
            {...formItemLayout}
          >
            <A10Switch checked={enabled} onChange={onEnable} />
            <span className={styles.enabled}>Active</span>
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>Where To Show</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title="Where To Show"
                    helpKey="HELP_ORG_AGREEMENTS_DOC_WHERE_TO_SHOW"
                  />
                </span>
              </>
            }
          >
            <A10Radio.Group onChange={onRadioChange} value={activeRadio}>
              <A10Radio value="Activation Page">
                Activation Page (Agreement)
              </A10Radio>
              <A10Radio value="After Login">
                After Login (Welcome Note)
              </A10Radio>
            </A10Radio.Group>
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>Comment</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title="Comment"
                    helpKey="HELP_ORG_AGREEMENTS_DOC_COMMENT"
                  />
                </span>
              </>
            }
          >
            <A10Input
              onChange={onCommentChange}
              value={commentValue}
              placeholder="Comment or note about this"
            />
          </A10Form.Item>
          <A10Form.Item
            rules={[{ required: true, message: 'Please upload a document' }]}
            label={
              <>
                <span>Upload Document</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title="Upload Document"
                    helpKey="HELP_ORG_AGREEMENTS_DOC_UPLOAD_DOC"
                  />
                </span>
              </>
            }
            validateStatus={validateDocument}
            {...documentHelp}
            {...formItemLayout}
          >
            <A10Upload
              accept=".txt"
              showUploadList={false}
              beforeUpload={(file: any, fileList: any) => {
                if (file.size > 300000) {
                  utilities.showMessage('File size too large.', 0, false)
                  return false
                }
                if (file.type != 'text/plain') {
                  utilities.showMessage(
                    'File should be plain text type',
                    0,
                    false,
                  )
                  return false
                }
                const reader = new FileReader()
                reader.onload = e => {
                  let document = e.target?.result
                  setDocument(document as string)
                }
                reader.readAsText(file)

                // Prevent upload
                return false
              }}
            >
              <A10Button>
                <A10Icon
                  className={styles.uploadIcon}
                  app="global"
                  type="upload"
                />
                Upload txt file
              </A10Button>
            </A10Upload>
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>Document Preview</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title="Document Preview"
                    helpKey="HELP_ORG_AGREEMENTS_DOC_PREVIEW_DOC"
                  />
                </span>
              </>
            }
          >
            <A10Input.TextArea
              rows={4}
              readOnly={true}
              value={removeQuotations(document)}
            />
          </A10Form.Item>
        </A10Form>
      </A10Panel>
    </FormatSlidingPage>
  )
}

export default EulaSlidingPage
