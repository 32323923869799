import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { A10Table, A10Status, A10Tooltip } from '@gui-libraries/widgets'

import { Utilities, OperatorConsoleService } from 'src/services'

import styles from './styles/index.module.less'

const utilities = new Utilities()
const operatorConsoleService = new OperatorConsoleService()
const UNKNOWN = 'UNKNOWN'
const columns = [
  {
    title: '',
    dataIndex: 'status',
    key: 'status',
    sorter: (a: IObject, b: IObject) => utilities.sortString(a, b, 'status'),
    render: (status: string) => (
      <A10Tooltip title={status} arrowPointAtCenter={true}>
        <div>
          <A10Status color={Status?.[status] || Status.UNKNOWN} />
        </div>
      </A10Tooltip>
    ),
  },
  {
    title: 'Microservice',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: IObject, b: IObject) => utilities.sortString(a, b, 'name'),
  },
  {
    title: 'Version',
    dataIndex: 'version',
    key: 'version',
    sorter: (a: IObject, b: IObject) => utilities.sortString(a, b, 'version'),
  },
  {
    title: 'Pod Status',
    dataIndex: 'podStatus',
    key: 'podStatus',
    sorter: (a: IObject, b: IObject) => utilities.sortString(a, b, 'status'),
  },
  {
    title: 'IP Address',
    dataIndex: 'podIP',
    key: 'podIP',
    sorter: (a: IObject, b: IObject) => utilities.sortString(a, b, 'podIP'),
  },
  {
    title: 'Port',
    dataIndex: 'ports',
    key: 'ports',
    render: (ports: IObject, record: Data) => {
      return (
        <div className={styles.portsContainer}>
          {Object.entries(ports).map(([key, value]) => (
            <span>
              {key}: {value.join(', ')}
            </span>
          ))}
        </div>
      )
    },
  },
  {
    title: 'Up Since',
    dataIndex: 'startedAt',
    key: 'startedAt',
    sorter: (a: IObject, b: IObject) => utilities.sortString(a, b, 'startedAt'),
  },
  {
    title: '# Restarts',
    dataIndex: 'restartCount',
    key: 'restartCount',
    sorter: (a: IObject, b: IObject) =>
      utilities.sortNumber(a, b, 'restartCount'),
  },
]

enum Status {
  UP = 'colorGreen',
  DOWN = 'colorOrange',
  UNKNOWN = 'colorGray',
}

interface IMicroServicesStatusProps {}

interface Data {
  key: string
  status: string
  name: string
  version: string
  podStatus: string
  podIP: string
  ports: IObject
  startedAt: string
  restartCount: number
}

interface Port {
  protocol: string
  hostPort: number
  containerPort: number
}

interface PodData {
  spec: {
    hostname: string
    containers: {
      ports: Port[]
    }[]
  }
  status: {
    phase: string
    podIP: string
    containerStatuses: {
      name: string
      image: string
      restartCount: number
      state: {
        running: {
          startedAt: string
        }
      }
    }[]
  }
}

interface ContainerState {
  state: string
  name: string
}

const portsFormatter = (ports: Port[]) => {
  return ports.reduce((acc, port) => {
    const { protocol, containerPort } = port

    if (!acc[protocol]) {
      acc[protocol] = []
    }
    acc[protocol].push(containerPort)

    return acc
  }, {})
}

const containerStateFormatter = (components: ContainerState[]) => {
  return components.reduce((acc, component) => {
    const { name, state } = component

    if (!acc[name]) {
      acc[name] = state
    }

    return acc
  }, {})
}

export const formatData = (
  podsData: PodData[],
  componentsData: ContainerState[],
) => {
  const containerStateMap = containerStateFormatter(componentsData)

  const formattedData: Data[] = podsData.reduce((acc, record) => {
    const hostname = record?.spec?.hostname

    if (hostname) {
      const containers = record.status?.containerStatuses || []

      containers.forEach((container, index) => {
        const name = container.name
        const displayedName = containers.length > 1 ? name : hostname
        const startedAt = moment(container.state.running.startedAt).format(
          'YYYY-MM-DD HH:mm:ss',
        )
        const ports = portsFormatter(record.spec.containers[index].ports)

        if (hostname) {
          acc.push({
            key: name,
            status: containerStateMap[displayedName] || UNKNOWN,
            name: displayedName,
            version: container.image,
            podStatus: record.status.phase,
            podIP: record.status.podIP,
            ports,
            startedAt,
            restartCount: record.status.containerStatuses[0].restartCount,
          })
        }
      })
    }

    return acc
  }, [])
  return formattedData
}

const MicroServicesStatus: React.FC<IMicroServicesStatusProps> = props => {
  const [data, setData] = useState<Data[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)

    Promise.all([
      operatorConsoleService.getPods(null, null, null),
      operatorConsoleService.getComponents(null, null, null),
    ])
      .then(([pods, components]) => {
        const tableData = formatData(pods?.data || [], components?.data || [])
        setData(tableData)
      })
      .catch(e => {
        console.error(`Fail to get pods. ${e}`)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <A10Table
      testID="statusTable"
      loading={isLoading}
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  )
}

export default MicroServicesStatus
