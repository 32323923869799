import React, { useState, useMemo, useEffect, useCallback } from 'react'
import {
  A10Explorer,
  A10Popover,
  A10Icon,
  A10Avatar,
} from '@gui-libraries/widgets'

import styles from './styles/index.module.less'

export interface IPartitionSelectorProps {
  type: 'cluster' | 'device'
  items: IObject[]
  onClickNode: (value: {
    cluster?: string
    device?: string
    partition: string
    type: string
  }) => void
}

const CLUSTER = 'cluster'
const DEVICE = 'device'
const INITIAL_PARTITION_TYPE = 'shared'

const PartitionSelector: React.FC<IPartitionSelectorProps> = props => {
  const { type, items = [], onClickNode } = props
  const [selectedItem, setSelectedItem] = useState()
  const [selectedPartition, setSelectedPartition] = useState()
  const [visible, setVisible] = useState(false)
  const displayName = useMemo(() => {
    const selectedItemObj = items.find(item => item.name === selectedItem)

    if (selectedItemObj) {
      return selectedItemObj['display-name'] || selectedItemObj.name
    }
  }, [items, selectedItem])

  useEffect(() => {
    // initial setup. Use shared partition as default
    const [firstItem] = items
    if (firstItem) {
      switch (type) {
        case CLUSTER: {
          const name = firstItem.name
          const partition = firstItem['partition-list']?.find(
            (p: IObject) => p.id === 0,
          )?.name

          if (name && partition) {
            setSelectedItem(name)
            setSelectedPartition(partition)
            onClickNode({
              cluster: name,
              partition,
              type: INITIAL_PARTITION_TYPE,
            })
          }
          break
        }
        case DEVICE: {
          const name = firstItem.name
          const partition = firstItem['partition-list']?.find(
            (p: IObject) => p['p-type'] === INITIAL_PARTITION_TYPE,
          )?.name

          if (name && partition) {
            setSelectedItem(name)
            setSelectedPartition(partition)
            onClickNode({
              device: name,
              partition,
              type: INITIAL_PARTITION_TYPE,
            })
          }
          break
        }
      }
    }
  }, [items])

  const explorerData = useMemo(
    () =>
      items.map((item: IObject, i: number) => {
        const partitions = item['partition-list'] || []
        const itemName = item.name
        const children = partitions.map((partition: IObject) => {
          const { name } = partition
          let partitionType = INITIAL_PARTITION_TYPE

          switch (type) {
            case CLUSTER: {
              partitionType =
                partition.id === 0
                  ? INITIAL_PARTITION_TYPE
                  : item['p-type']?.includes('service')
                  ? 'service'
                  : 'l3v'
              break
            }
            case DEVICE: {
              partitionType = partition['p-type']
              break
            }
          }
          const isActive =
            itemName === selectedItem && selectedPartition === name

          return {
            name: (
              <span className={styles.treeNodeContent}>
                <A10Avatar className={styles.treeNodeAvatar} size={24}>
                  P
                </A10Avatar>
                {name}
              </span>
            ),
            item: itemName,
            type: partitionType,
            partition: name,
            // icon: ['harmony-controller', 'partition-config'],
            useAvatar: false,
            active: isActive,
          }
        })
        const isActive = itemName === selectedItem

        return {
          name: (
            <span>
              <A10Avatar className={styles.treeNodeAvatar} size={24}>
                {type === CLUSTER ? 'C' : 'D'}
              </A10Avatar>
              {itemName}
            </span>
          ),
          // icon: ['harmony-controller', 'devices'],
          avatar: children.length.toString(),
          active: isActive,
          children,
          ...(i === 0 ? { toggled: true } : {}),
        }
      }),
    [items, selectedItem, selectedPartition],
  )

  const content = (
    <A10Explorer
      data={explorerData}
      onClickNode={e => {
        const { item, partition, type: partitionType } = e
        setSelectedItem(item)
        setSelectedPartition(partition)
        onClickNode({ [type]: item, partition, type: partitionType })
      }}
    />
  )

  const onVisibleChange = useCallback((visible: boolean) => {
    setVisible(visible)
  }, [])

  return (
    <A10Popover
      placement="leftTop"
      content={content}
      overlayClassName={styles.partitionSelectorPopoverOverlay}
      align={{ offset: [-10, 0] }}
      trigger="click"
      onVisibleChange={onVisibleChange}
    >
      <div className={styles.partitionSelector}>
        <A10Avatar className={styles.selectAvatar} size={18}>
          {type === CLUSTER ? 'CP' : 'DP'}
        </A10Avatar>
        <span className={styles.partitionSelectorDisplayedValue}>
          {selectedItem && selectedPartition
            ? `${displayName} > ${selectedPartition}`
            : `Please Select a ${
                type === CLUSTER ? 'Cluster' : 'Device'
              } Partition`}
        </span>
        <A10Icon
          className={visible && styles.rotated}
          style={{ fontSize: '8px', transition: 'transform .3s ease-in-out' }}
          app="global"
          type="dropdown"
        />
      </div>
    </A10Popover>
  )
}

export default PartitionSelector
