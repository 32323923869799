import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Form,
  A10Input,
  A10Radio,
  A10Select,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import storage from 'src/libraries/storage'
import { Messages } from 'src/locale/en/Messages'
import { Utilities } from 'src/services/Utilities/Utilities'
import { HelpInfo } from 'src/components/shared/HelpInfo'

import styles from './styles/index.module.less'

export interface IClusterPanelProps extends IA10ContainerDefaultProps {
  cluster: any
  form: any
  formData?: any
  onRef?: any
  handleChange?: () => void
  device?: any
  devices?: any
  deviceAddForm?: boolean
  clusterSearch?: () => void
  filteredClustersList?: any
}
export interface IClusterPanelState {}

class ClusterPanel extends A10Container<
  IClusterPanelProps,
  IClusterPanelState
> {
  Messages = new Messages()
  Utilities = new Utilities()
  allOptions = [
    {
      key: 'none',
      label: 'Single',
    },
    {
      key: 'vrrpa',
      label: 'VRRP-A',
    },
    {
      key: 'scaleout',
      label: 'Scaleout',
    },
  ]

  constructor(props: any) {
    super(props)
  }

  validateDuplicateClusterName = (rule: any, clusterName: any, cb: any) => {
    const allCluster = storage.get.PROVIDER_CLUSTERS
    let isValid: boolean = true
    let index = -1
    if (allCluster) {
      index = allCluster.findIndex((key: any) => {
        return (
          key.name === clusterName ||
          (key['display-name'] && key['display-name'] === clusterName)
        )
      })
    }
    const nameRegx = new RegExp(/^([a-zA-Z0-9._-]){1,50}$/)
    if ('' === clusterName) {
      rule.message = this.Messages.ENTER_CLUSTER_NAME
      isValid = false
    } else if (!nameRegx.test(clusterName) || !isNaN(clusterName)) {
      rule.message = this.Messages.INVALID_CLUSTER_NAME
      isValid = false
    } else if (!this.props.cluster && index > -1) {
      rule.message = this.Messages.DUPLICATE_CLUSTER_NAME
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateDuplicateClusterDisplayName = (
    rule: any,
    displayName: any,
    cb: any,
  ) => {
    const allCluster = storage.get.PROVIDER_CLUSTERS
    let isValid: boolean = true
    let index = -1
    if (allCluster) {
      index = allCluster.findIndex((cluster: any) => {
        if (!!this.props.cluster) {
          if (cluster.name === this.props.formData.clusterName) {
            return false
          }
        }
        return (
          cluster.name === displayName ||
          (cluster['display-name'] && cluster['display-name'] === displayName)
        )
      })
    }
    const nameRegx = this.Utilities.DISPLAYNAME_PATTERN
    if ('' === displayName) {
      // rule.message = this.Messages.ENTER_DISPLAY_NAME
      // isValid = false
    } else if (!nameRegx.test(displayName)) {
      rule.message = this.Messages.INVALID_DISPLAY_NAME
      isValid = false
    } else if (index > -1) {
      rule.message = this.Messages.DUPLICATE_CLUSTER_NAME
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateDuplicateFloatingIP = (
    type: any,
    clusterName: any,
    rule: any,
    value: any,
    cb: any,
  ) => {
    const allCluster = storage.get.PROVIDER_CLUSTERS
    let isValid: boolean = true
    let index = -1
    if (allCluster) {
      index = allCluster.findIndex((key: any) => {
        return (
          key.name !== clusterName &&
          key.type === 'vcs' &&
          key['floating-ip'] === value
        )
      })
    }

    const ipv4Regex = this.Utilities.IPV4_REGEX
    const ipv6Regex = this.Utilities.IPV6_REGEX
    if (type === 'vcs' && '' === value) {
      rule.message = this.Messages.ENTER_FLOATING_IP
      isValid = false
    } else if (
      type === 'vcs' &&
      !(ipv4Regex.test(value) || ipv6Regex.test(value))
    ) {
      rule.message = this.Messages.INVALID_FLOATING_IP
      isValid = false
    } else if (type === 'vcs' && index > -1) {
      rule.message = this.Messages.DUPLICATE_FLOATING_IP
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  returnClusterTypeOptions = (cluster: string, dataPlaneHA: string) => {
    let options: any[] = []
    if (dataPlaneHA !== 'none' && cluster) {
      options = this.allOptions.filter(option => option.key !== 'none')
    } else {
      options = [...this.allOptions]
    }
    return options.map((option: any, i: number) => {
      return (
        <A10Select.Option key={option.key + i} value={option.key}>
          {option.label}
        </A10Select.Option>
      )
    })
  }

  render() {
    const {
      clusterName,
      clusterDName,
      type,
      dataPlaneHA,
      description,
      floatingIP,
      floatingPort,
      convertToHA,
      clusterUserName,
      clusterPassword,
    } = this.props.formData

    const { cluster, device, devices, deviceAddForm } = this.props

    const { renderDropdownOpts } = this.Utilities

    const formItemLayoutHeader = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    }

    const { getFieldDecorator } = this.props.form
    return (
      <A10Panel
        title={
          <>
            <A10IconTextGroup
              text={
                <>
                  Cluster Info
                </>
              }
              icon={
                <A10Icon
                  style={{ width: 22, height: 22, marginRight: 12 }}
                  app="global"
                  type="form-section"
                  className="sliding-panel-icon"
                />
              }
            />
          </>
        }
      >
        {!!deviceAddForm ? (
          <A10Form.Item
            {...formItemLayoutHeader}
            label={
              !!cluster || !!device ? (
                <>
                  <span className="mandatoryField">
                    {this.Messages.CLUSTER_NAME}
                  </span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title="Cluster Name * "
                      helpKey="HELP_CREATECLUSTER_CLUSTER_NAME"
                    />
                  </span>
                </>
              ) : (
                <>
                  <span className="mandatoryField">
                    {this.Messages.CLUSTER_SELECT}
                  </span>
                  <HelpInfo
                    placement="leftTop"
                    title="Select a Cluster * "
                    helpKey="HELP_CREATECLUSTER_CLUSTER_NAME"
                  />
                </>
              )
            }
          >
            {getFieldDecorator('cluster', {
              rules: [
                { required: true, message: this.Messages.CLUSTER_SELECT },
              ],
              initialValue: cluster
                ? cluster['display-name'] || cluster.name
                : devices[0].cluster
                ? devices[0].cluster
                : undefined,
            })(
              <A10Select
                disabled={!!cluster || !!device}
                placeholder={'Please select cluster'}
                onChange={this.props.handleChange.bind(this, 'cluster')}
                showSearch={true}
                onSearch={this.props.clusterSearch}
              >
                <A10Select.Option key="new_cluster" value="new_cluster">
                  <div className="create_new_cluster">Create New Cluster</div>
                </A10Select.Option>
                {renderDropdownOpts(
                  this.props.filteredClustersList,
                  null,
                  'name',
                  null,
                  null,
                  'display-name',
                )}
              </A10Select>,
            )}
          </A10Form.Item>
        ) : null}

        {!device ? (
          <>
            <A10Form.Item
              {...formItemLayoutHeader}
              label={
                <>
                  {this.Messages.CLUSTER_TYPE}
                  <span style={{ marginLeft: '-18px' }}>
                    <HelpInfo
                      placement="left"
                      title={this.Messages.CLUSTER_TYPE}
                      helpKey="HELP_CLUSTER_TYPE"
                    />
                  </span>
                </>
              }
            >
              <div className="row">
                <div className="col-md-12">
                  <A10Select
                    value={dataPlaneHA}
                    disabled={!!cluster || !!deviceAddForm}
                    onChange={this.props.handleChange.bind(this, 'dataPlaneHA')}
                  >
                    {this.returnClusterTypeOptions(cluster, dataPlaneHA)}
                  </A10Select>
                </div>
              </div>
            </A10Form.Item>

            {(dataPlaneHA === 'vrrpa' || dataPlaneHA === 'scaleout') && (
              <A10Form.Item
                {...formItemLayoutHeader}
                label={
                  <>
                    {this.Messages.CONFIG_MANAGEMENT}
                    <span style={{ marginLeft: '-18px' }}>
                      <HelpInfo
                        placement="left"
                        title={this.Messages.CONFIG_MANAGEMENT}
                        helpKey="HELP_CLUSTER_CONFIG_MANAGEMENT"
                      />
                    </span>
                  </>
                }
              >
                <div className="row">
                  <div className="col-md-12">
                    <A10Radio.Group
                      name="type"
                      value={type}
                      defaultValue={type}
                      disabled={!!cluster || !!deviceAddForm}
                      onChange={this.props.handleChange.bind(this, 'type')}
                    >
                      <A10Radio value="multinode">Harmony</A10Radio>
                      <A10Radio value="vcs">aVCS</A10Radio>
                    </A10Radio.Group>
                  </div>
                  <div className="col-md-12">
                    <span className={styles.helpText}>
                      {type === 'vcs'
                        ? 'aVCS master manages configuration sync to devices in the cluster'
                        : 'Harmony manages configuration sync to devices in the cluster'}
                    </span>
                  </div>
                </div>
              </A10Form.Item>
            )}

            {!!deviceAddForm ? null : (
              <>
                <A10Form.Item
                  {...formItemLayoutHeader}
                  label={
                    <>
                      <span className="mandatoryField">
                        {this.Messages.CLUSTER_NAME}
                      </span>{' '}
                      <span style={{ marginLeft: '-18px' }}>
                        <HelpInfo
                          placement="left"
                          title={this.Messages.CLUSTER_NAME + ' *'}
                          helpKey="HELP_CREATECLUSTER_CLUSTER_NAME"
                        />
                      </span>
                    </>
                  }
                >
                  <div className="row">
                    <div className="col-md-12">
                      {getFieldDecorator('clusterName', {
                        rules: [
                          {
                            validator: !!cluster
                              ? null
                              : this.validateDuplicateClusterName.bind(this),
                          },
                        ],
                        initialValue: clusterName,
                      })(
                        <A10Input
                          type="text"
                          placeholder={this.Messages.CLUSTER_NAME}
                          onChange={this.props.handleChange.bind(
                            this,
                            'clusterName',
                          )}
                          disabled={!!cluster}
                        />,
                      )}
                    </div>
                  </div>
                </A10Form.Item>

                <A10Form.Item
                  {...formItemLayoutHeader}
                  label={
                    <>
                      <span className="">{this.Messages.DISPLAY_NAME}</span>{' '}
                      <span style={{ marginLeft: '-18px' }}>
                        <HelpInfo
                          placement="left"
                          title={this.Messages.DISPLAY_NAME + ' *'}
                          helpKey="HELP_CREATECLUSTER_CLUSTER_DISPLAY_NAME"
                        />
                      </span>
                    </>
                  }
                >
                  <div className="row">
                    <div className="col-md-12">
                      {getFieldDecorator('clusterDName', {
                        rules: [
                          {
                            validator: this.validateDuplicateClusterDisplayName.bind(
                              this,
                            ),
                          },
                        ],
                        initialValue: clusterDName,
                      })(
                        <A10Input
                          type="text"
                          placeholder={
                            clusterName || this.Messages.DISPLAY_NAME
                          }
                          onChange={this.props.handleChange.bind(
                            this,
                            'clusterDName',
                          )}
                        />,
                      )}
                    </div>
                  </div>
                </A10Form.Item>
              </>
            )}

            <A10Form.Item
              {...formItemLayoutHeader}
              label={
                <>
                  {this.Messages.DESCRIPTION}
                  <span style={{ marginLeft: '-12px' }}>
                    <HelpInfo
                      placement="left"
                      title={this.Messages.DESCRIPTION}
                      helpKey="HELP_INFRA_CLUSTER_DESCRIPTION"
                    />
                  </span>
                </>
              }
            >
              <div className="row">
                <div className="col-md-12">
                  <A10Input.TextArea
                    placeholder={
                      deviceAddForm ? '' : this.Messages.CLUSTER_DESC
                    }
                    defaultValue={description}
                    value={description}
                    rows={3}
                    id="description"
                    disabled={!!deviceAddForm}
                    onChange={this.props.handleChange.bind(this, 'description')}
                  />
                </div>
              </div>
            </A10Form.Item>

            <div className="col-md-12 no-padding">
              {type === 'vcs' ? (
                <>
                  <A10Form.Item
                    {...formItemLayoutHeader}
                    label={
                      <>
                        <span className="mandatoryField">
                          {this.Messages.FLOATING_IP}
                        </span>
                        <span style={{ marginLeft: '-4px' }}>
                          <HelpInfo
                            placement="left"
                            title={this.Messages.FLOATING_IP + ' *'}
                            helpKey="HELP_INFRA_CLUSTER_FLOATING_IP"
                          />
                        </span>
                      </>
                    }
                  >
                    <div className="row">
                      <div className="col-md-6">
                        {getFieldDecorator('floatingIP', {
                          validateTrigger: 'onBlur',
                          rules: [
                            {
                              validator: deviceAddForm
                                ? true
                                : this.validateDuplicateFloatingIP.bind(
                                    this,
                                    type,
                                    clusterName,
                                  ),
                            },
                          ],
                          initialValue: floatingIP,
                        })(
                          <A10Input
                            type="text"
                            placeholder={this.Messages.FLOATING_IP}
                            disabled={!!deviceAddForm}
                            onChange={this.props.handleChange.bind(
                              this,
                              'floatingIP',
                            )}
                          />,
                        )}
                      </div>
                    </div>
                  </A10Form.Item>

                  {convertToHA && dataPlaneHA === 'vrrpa' ? (
                    <A10Form.Item
                      {...formItemLayoutHeader}
                      label={
                        <>
                          <span className="mandatoryField">
                            {this.Messages.PORT}
                          </span>
                          <span style={{ marginLeft: '-4px' }}>
                            <HelpInfo
                              placement="left"
                              title={this.Messages.PORT + ' *'}
                              helpKey="HELP_INFRA_CLUSTER_FLOATING_PORT"
                            />
                          </span>
                        </>
                      }
                    >
                      <div className="row">
                        <div className="col-md-4">
                          {getFieldDecorator('floatingPort', {
                            rules: [
                              {
                                validator: this.Utilities.validatePort.bind(
                                  this,
                                  '',
                                ),
                              },
                            ],
                            initialValue: floatingPort,
                          })(
                            <A10Input
                              type="text"
                              placeholder={this.Messages.PORT}
                              disabled={!!deviceAddForm}
                              onChange={this.props.handleChange.bind(
                                this,
                                'floatingPort',
                              )}
                            />,
                          )}
                        </div>
                      </div>
                    </A10Form.Item>
                  ) : null}

                  {convertToHA && !devices ? (
                    <>
                      <A10Form.Item
                        {...formItemLayoutHeader}
                        label={
                          <>
                            <span className="mandatoryField">
                              {this.Messages.CLUSTER_USERNAME}
                            </span>
                            <span style={{ marginLeft: '-25px' }}>
                              <HelpInfo
                                placement="left"
                                title={this.Messages.CLUSTER_USERNAME}
                                helpKey="HELP_INFRA_ADMIN_USER_NAME"
                              />
                            </span>
                          </>
                        }
                        key={'clusterUserName'}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            {getFieldDecorator('clusterUserName', {
                              rules: [
                                {
                                  required: true,
                                  message: this.Messages.USERNAME_REQUIRED,
                                },
                              ],
                              initialValue: clusterUserName,
                            })(
                              <A10Input
                                type="text"
                                placeholder={this.Messages.CLUSTER_USERNAME}
                                onChange={this.props.handleChange.bind(
                                  this,
                                  'clusterUserName',
                                )}
                              />,
                            )}
                          </div>
                        </div>
                      </A10Form.Item>

                      <A10Form.Item
                        {...formItemLayoutHeader}
                        label={
                          <>
                            <span className="mandatoryField">
                              {this.Messages.CLUSTER_PASSWORD}
                            </span>
                            <span style={{ marginLeft: '-24px' }}>
                              <HelpInfo
                                placement="left"
                                title={this.Messages.CLUSTER_PASSWORD}
                                helpKey="HELP_INFRA_ADMIN_USER_PASSWORD"
                              />
                            </span>
                          </>
                        }
                        key={'clusterPassword'}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            {getFieldDecorator('clusterPassword', {
                              rules: [
                                {
                                  required: true,
                                  message: this.Messages.USER_PASSWORD_REQUIRED,
                                },
                              ],
                              initialValue: clusterPassword,
                            })(
                              <A10Input
                                type="password"
                                placeholder={this.Messages.CLUSTER_PASSWORD}
                                onChange={this.props.handleChange.bind(
                                  this,
                                  'clusterPassword',
                                )}
                              />,
                            )}
                          </div>
                        </div>
                      </A10Form.Item>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </>
        ) : null}
      </A10Panel>
    )
  }
}

export default setupA10Container(A10Form.create()(ClusterPanel))
