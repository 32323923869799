import React, { useCallback, useEffect, useRef, useState } from 'react'
import { A10Avatar, A10Icon, A10Loader } from '@gui-libraries/widgets'
import { formatDatetime, Viz } from '@gui-libraries/viz'
import { RequestBuilder } from '@gui-libraries/dgf'
import useDashboardAlerts from './useDashboardAlerts'
import './styles/index.less'

const DashboardAlerts: React.FC<any> = props => {
  const { service, height, OnClick, endTime, parameters } = props
  const startTime = endTime - 24 * 60 * 60 * 1000
  const { api } = parameters

  const { formattedData, isLoading, chartData } = useDashboardAlerts(
    startTime,
    endTime,
    service,
    api,
  )

  const refContainer = useRef()

  const requestBuilder = new RequestBuilder()
  const interval = requestBuilder.getInterval(startTime, endTime)

  const { SparkLine } = Viz

  const init = useCallback(async () => {}, [])

  useEffect(() => {
    init()
  }, [init])

  const getColorForSeverity = (severity: number) => {
    switch (String(severity)) {
      case 'critical':
      case '0':
      case '1':
      case '2':
      case '3':
        return 'critical'
      case 'moderate':
      case '4':
        return 'warning'
      case '5':
        return 'notification'
      case 'info':
      case '6':
        return 'info'
      case 'healthy':
      case 'low':
      case '7':
        return 'debug'
      default:
        return ''
    }
  }

  const chartConfig = {
    lang: {
      noData: 'No Data',
    },
  }

  return (
    <>
      <div className="interval-text">Last 24 hrs</div>
      <div className="alerts-container" style={{ maxHeight: height }}>
        {isLoading && <A10Loader container={refContainer} />}
        {!isLoading && (
          <>
            <div
              className="show-more"
              onClick={(data: any) => OnClick({ endPoint: 'hc_event' })}
            >
              <span>
                <A10Icon app="global" type="min-max"></A10Icon>
              </span>
              <span>Show More</span>
            </div>
            <div className="chart-container">
              <div className="chart-item">
                <div>Critical Alerts</div>
                <div className="chart">
                  <span>{chartData.criticalAlertCount}</span>
                  <SparkLine
                    seriesData={chartData.criticalAlerts}
                    interval={interval}
                    color="#887cc6"
                    showTooltip={true}
                    chartConfig={chartConfig}
                  />
                </div>
              </div>
              <div className="vertical-divider"></div>
              <div className="chart-item">
                <div>Warnings</div>
                <div className="chart">
                  <span>{chartData.warningCount}</span>
                  <SparkLine
                    seriesData={chartData.warnings}
                    interval={interval}
                    color="#887cc6"
                    showTooltip={true}
                    chartConfig={chartConfig}
                  />
                </div>
              </div>
            </div>
            <div className="hrDivider"></div>
            <div className="alert-item-container">
              {!formattedData.length && (
                <div className="empty-message">
                  <p>No Data</p>
                </div>
              )}
              {formattedData.map(item => (
                <div className="alert-item">
                  <div className="alert-item-name">
                    <A10Avatar
                      className={`dashboard-alert-severity-${getColorForSeverity(
                        item.severity,
                      )}`}
                      size={14}
                    ></A10Avatar>
                    <span>{`${item?.def_name}  ${item?.condition_text}`}</span>
                  </div>
                  <div className="timestampCol">
                    {formatDatetime(parseInt(item?.ts), 'MM/DD h:mm:ss a')}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default DashboardAlerts
