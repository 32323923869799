import _ from 'lodash'

import { Messages } from 'src/locale/en/Messages'
import { UrlService, Utilities } from 'src/services'

const messages = new Messages()
const urlService = new UrlService()
const utilities = new Utilities()

const hostWithIPv4 = /\w+\:\/\/([1]?[1-9]?[0-9]\.|2[0-4][0-9]\.|25[0-5]\.){3}([1]?[1-9]?[0-9]|2[0-4][0-9]|25[0-5])\:\d+/
const hostWithIPv6 = /^\w+\:\/\/\[((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\]\:\d+$/
const hostWithDomain = /\w+\:\/\/[\w\.\-\_]+\:\d+/

export function validateHosts(
  type: string,
  hosts: string,
  showMessage?: boolean,
) {
  if (
    !hosts ||
    hosts.trim() === '' ||
    hosts.split(',').every((item: string) => !item || item.trim() === '')
  ) {
    if (type === 'ldap') {
      showMessage &&
        utilities.showMessage(
          'Please enter LDAP servers in this format "ldap://LdapServerName:LdapPort"(Separated by commas)',
          0,
          false,
        )
    } else if (type === 'radius') {
      showMessage &&
        utilities.showMessage(
          'Please enter RADIUS server names/IP addresses separated by commas',
          0,
          false,
        )
    } else if (type === 'tacacsplus') {
      showMessage &&
        utilities.showMessage(
          'Please enter TACACS+ server names/IP addresses separated by commas',
          0,
          false,
        )
    }
    return false
  } else {
    let cacheLdapProtocol: string = null

    const isValid = hosts.split(',').every((item: string) => {
      const host = item.trim()

      if (host) {
        if (type === 'ldap') {
          if (
            !hostWithIPv4.test(host) &&
            !hostWithIPv6.test(host) &&
            !hostWithDomain.test(host)
          ) {
            showMessage &&
              utilities.showMessage(
                'Detected invalid host! All LDAP servers must follow this format "ldap://LdapServerName:LdapPort"(Separated by commas)',
                0,
                false,
              )
            return false
          }

          const protocol = urlService.getProtocol(host)
          if (cacheLdapProtocol === null) {
            cacheLdapProtocol = protocol
          } else if (cacheLdapProtocol !== protocol) {
            showMessage &&
              utilities.showMessage(
                'All LDAP servers must have same protocol',
                0,
                false,
              )
            return false
          }
        } else if (type === 'radius') {
          if (!utilities.validateHost(host)) {
            showMessage &&
              utilities.showMessage(
                'Detected invalid host! ' +
                messages.HOSTNAME_IP_ADDRESS_INVALID,
                0,
                false,
              )
            return false
          }
        } else if (type === 'tacacsplus') {
          if (!utilities.validateHost(host)) {
            showMessage &&
              utilities.showMessage(
                'Detected invalid host! ' +
                messages.HOSTNAME_IP_ADDRESS_INVALID,
                0,
                false,
              )
            return false
          }
        }
      }

      return true
    })
    if (!isValid) {
      return false
    }
  }

  return true
}

export function validatePort(
  type: string,
  port: string | number,
  showMessage?: boolean,
) {
  if (
    (_.isString(port) && (!port || !utilities.validatePort('', port))) ||
    (_.isInteger(port) && (port < 0 || port > 65535))
  ) {
    showMessage && utilities.showMessage(messages.INVALID_PORT_NUMBER, 0, false)
    return false
  }

  return true
}

export function validateRetries(
  type: string,
  retries: string | number,
  showMessage?: boolean,
) {
  if (
    (_.isString(retries) && retries && !utilities.validateRetries(retries)) ||
    (_.isInteger(retries) && (retries < 0 || retries > 10))
  ) {
    showMessage && utilities.showMessage(messages.INVALID_RETRIES, 0, false)
    return false
  }

  return true
}

export function validateTimeout(
  type: string,
  timeout: string | number,
  showMessage?: boolean,
) {
  if (
    (_.isString(timeout) && timeout && !utilities.validateTimeout(timeout)) ||
    (_.isInteger(timeout) && (timeout < 0 || timeout > 10))
  ) {
    showMessage && utilities.showMessage(messages.INVALID_TIMEOUT, 0, false)
    return false
  }

  return true
}

export function validateSharedSecret(
  type: string,
  secret: string,
  showMessage?: boolean,
) {
  if (!utilities.validateSharedSecret(secret)) {
    showMessage &&
      utilities.showMessage(messages.INVALID_SHARED_SECRET, 0, false)
    return false
  }

  return true
}

export function validateRemote(
  type: string,
  remoteAddress: string,
  showMessage?: boolean,
) {
  if (remoteAddress !== '' && !utilities.validateHost(remoteAddress)) {
    showMessage &&
      utilities.showMessage(messages.INVALID_REMOTE_ADDRESS, 0, false)
    return false
  }

  return true
}

export function validateAdminDN(
  type: string,
  admindn: string,
  showMessage?: boolean,
) {
  if (!admindn || admindn.trim() === '') {
    showMessage &&
      utilities.showMessage('Please enter a valid Admin DN', 0, false)
    return false
  } else if (!checkPattern(admindn)) {
    showMessage &&
      utilities.showMessage(
        `Admin DN pattern should consist of several equations "Key=Value"(Separated by commas)`,
        0,
        false,
      )
    return false
  }

  return true
}

export function validateAdminPwd(
  type: string,
  adminpwd: string,
  showMessage?: boolean,
) {
  if (!adminpwd || adminpwd.trim() === '') {
    showMessage &&
      utilities.showMessage('Please enter a valid Admin Password', 0, false)
    return false
  }

  return true
}

export function checkPatternList(
  type: string,
  list: string[],
  showMessage?: boolean,
) {
  const isEmpty =
    list.length === 0 ||
    list.every((element: string) => {
      return !element || element.trim() === ''
    })
  if (isEmpty) {
    showMessage &&
      utilities.showMessage(
        'Please enter a valid ' + type + ' pattern',
        0,
        false,
      )
    return false
  }

  return list.every((element: string, index: number, arr: string[]) => {
    const patternString = element.trim()

    if (patternString) {
      if (!checkPattern(patternString)) {
        showMessage &&
          utilities.showMessage(
            `${type} patterns should consist of several equations "Key=Value"(Separated by commas)`,
            0,
            false,
          )
        return false
      }

      const patternObject = transPattern(patternString)
      const isDuplicated = arr.some((item: string, i: number) => {
        if (i !== index) {
          const pattern = transPattern(item)
          if (_.isEqual(patternObject, pattern)) {
            return true
          }
        }
        return false
      })
      if (isDuplicated) {
        showMessage &&
          utilities.showMessage(
            'Duplicated ' + type + ' pattern detected',
            0,
            false,
          )
        return false
      }
    }

    return true
  })
}

export function checkPattern(pattern: string) {
  return pattern.split(',').every(item => {
    const equation = item.trim()

    if (equation === '') {
      return false
    } else {
      const [key, value] = equation.split('=', 2).map(part => part.trim())
      if (!key || !value) {
        return false
      }
      // Todo: check key/value format
    }
    return true
  })
}

export function transPattern(pattern: string) {
  const list: any[] = []

  pattern.split(',').forEach(item => {
    const equation = item.trim()
    const [key, value] = equation.split('=', 2).map(part => part.trim())
    if (_.isString(key) && key) {
      list.push({ [key]: value })
    }
  })

  return list
}

export function checkAttributeList(
  type: string,
  list: string[],
  showMessage?: boolean,
) {
  const isEmpty =
    list.length === 0 ||
    list.every((element: string) => {
      return !element || element.trim() === ''
    })
  if (isEmpty) {
    showMessage &&
      utilities.showMessage('Please enter a valid ' + type, 0, false)
    return false
  }

  return list.every((element: string, index: number, arr: string[]) => {
    const attribute = element.trim()

    if (attribute) {
      const isDuplicated = arr.some((item: string, i: number) => {
        if (i !== index && item.trim() === attribute) {
          return true
        }
        return false
      })
      if (isDuplicated) {
        showMessage &&
          utilities.showMessage(`Duplicated ${type} detected`, 0, false)
        return false
      }
    }

    return true
  })
}

export function validateDate(date: string ) {
  if (date === undefined ||date === ''|| date === 'Invalid date') {
    return false
  }
  return true
}

export function validateBaseDN(
  type: string,
  baseDN: string,
  showMessage?: boolean,
) {
  if (!baseDN || baseDN?.trim() === '') {
    showMessage &&
      utilities.showMessage(messages.VALID_GROUP_BASE_DN, 0, false)
    return false
  } else if (!checkPattern(baseDN)) {
    showMessage &&
      utilities.showMessage(messages.GROUP_BASE_DN_PATTERN, 0, false,)
    return false
  }
  return true
}

