import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Row, A10Col, A10Tabs } from '@gui-libraries/widgets'
import DomainList from './DomainList'
import DNS from './DNS'
import SNMPServer from './SNMPServer'
import Interface from './Interface'
import VLAN from './VLAN'

import { AccessList } from './AccessList'
import { LLDP } from './LLDP'
import Trunk from './Trunk'
import ARP from './ARP'
import Routes from './Routes'
const TabPane = A10Tabs.TabPane

export interface INetworkProps extends IA10ContainerDefaultProps {
  tenant: string
  logicalPartition: string
  scope: string
}

export interface INetworkState {
  activeTab: string
}

class Network extends A10Container<INetworkProps, INetworkState> {
  state: INetworkState = {
    activeTab: '8',
  }

  onTabChange = (activeKey: string) => {
    this.setState({ activeTab: activeKey })
  }

  render() {
    const { activeTab } = this.state
    const { tenant, logicalPartition, scope } = this.props

    return (
      <A10Row style={{ marginLeft: 42, marginRight: 38 }}>
        <A10Col lg={24}>
          <A10Tabs defaultActiveKey={activeTab} onChange={this.onTabChange}>
            {/* <TabPane tab="Interface" key="1">
              <Interface actived={activeTab === '1'} />
            </TabPane>
            <TabPane tab="VLAN" key="2">
              <VLAN actived={activeTab === '2'} />
            </TabPane>
            <TabPane tab="Trunk" key="3">
              <Trunk actived={activeTab === '3'} />
            </TabPane>
            <TabPane tab="ARP" key="4">
              <ARP actived={activeTab === '4'} />
            </TabPane>
            <TabPane tab="Route" key="5">
              <Routes actived={activeTab === '5'} />
            </TabPane> */}
            {/* <TabPane tab="Access List" key="6">
              <AccessList actived={activeTab === '6'} />
            </TabPane> */}
            {/* <TabPane tab="SNMP Server" key="7">
              <SNMPServer actived={activeTab === '7'} />
            </TabPane> */}
            {/* <TabPane tab="DNS" key="8">
              <DNS
                actived={activeTab === '8'}
                tenant={tenant}
                logicalPartition={logicalPartition}
                scope={scope}
              />
            </TabPane> */}
            <TabPane tab="Domain List" key="9">
              <DomainList
                actived={activeTab === '9'}
                tenant={tenant}
                logicalPartition={logicalPartition}
                scope={scope}
              />
            </TabPane>
            {/* <TabPane tab="LLDP" key="10">
              <LLDP actived={activeTab === '10'} />
            </TabPane> */}
          </A10Tabs>
        </A10Col>
      </A10Row>
    )
  }
}

export default setupA10Container(Network)
