import React from 'react'
import { A10Status } from '@gui-libraries/widgets'

interface IWorkflowStatusProps {
  title?: string
  status?: string
}

const GREEN = 'colorGreen'
const RED = 'colorRed'
const ORANGE = 'colorOrange'
const GRAY = 'colorGray'
const BLUE = 'colorBlue'
const BUSY_GREEN = 'busyGreen'
const BUSY_RED = 'busyRed'

const statusMap = {
  COMPLETE: GREEN,
  SUCCESS: GREEN,
  FAILED: RED,
  NEW: BUSY_GREEN,
  INPROGRESS: BUSY_GREEN,
  RUNNING: BUSY_GREEN,
  PAUSED: BUSY_RED,
  TERMINATED: ORANGE,
  TIMED_OUT: ORANGE,
  DEFAULT: GRAY,
  ERROR: RED,
  INFO: BLUE,
  WARNING: ORANGE,
}

const WorkflowStatus: React.FC<IWorkflowStatusProps> = props => {
  const { title, status = 'DEFAULT' } = props

  return <A10Status color={statusMap[status]} title={title} />
}

export default WorkflowStatus
