import React, { useState, useEffect } from 'react'
import { A10Icon, A10SlidingPage } from '@gui-libraries/widgets'
import { AutoFormV15 } from '@gui-libraries/apps'

import { InfrastructureService } from 'src/services/index'
import storage from 'src/libraries/storage'
import Cards, { Type } from 'src/components/shared/Cards'

const NetworkVlanGlobal: React.FC = (props: IObject) => {
  const [macAgeTime, setMacAgeTime] = useState(300)
  const [l2Forward, setL2Forward] = useState(false)
  const [l3Forward, setL3Forward] = useState(false)
  const [showSlidingPage, setShowSlidingPage] = useState(false)
  const { selectedPartition = 'shared' } = props
  const infrastructureService = new InfrastructureService()
  const getData = () => {
    const provider = storage.get.PROVIDER
    infrastructureService
      .getRequest(
        null,
        null,
        [provider, props.deviceObj.name, selectedPartition],
        'GET_DEVICE_NETWORK_MAC_AGE_TIME',
      )
      .then((res: IObject) => {
        try {
          setMacAgeTime(res.data['mac-age-time']['aging-time'])
        } catch (e) {
          console.log(e)
        }
      })

    infrastructureService
      .getRequest(
        null,
        null,
        [provider, props.deviceObj.name, selectedPartition],
        'GET_DEVICE_VLAN_GLOBAL',
      )
      .then((res: IObject) => {
        try {
          setL2Forward(
            !!res.data['vlan-global']['enable-def-vlan-l2-forwarding'],
          )
          setL3Forward(!!res.data['vlan-global']['l3-vlan-fwd-disable'])
        } catch (e) {
          console.log(e)
        }
      })
  }
  useEffect(() => {
    getData()
  }, [props.deviceObj.name, props.refreshTimer, selectedPartition])

  const toggleSlidingPage = (isOpen: boolean) => {
    setShowSlidingPage(isOpen)
  }
  const handleSuccess = () => {
    getData()
    toggleSlidingPage(false)
  }
  const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/device/${props.deviceObj.name}/partition/${selectedPartition}`

  const cardContent = [
    {
      name: 'VLAN Global',
      type: Type.Description,
      span: 24,
      minWidth: 800,
      direction: 'row',
      data: [
        {
          'L2 Forwarding on Default VLAN': !!l2Forward ? 'Enabled' : 'Disabled',
          'L3 Forwarding between VLANs': !!l3Forward ? 'Disabled' : 'Enabled',
        },
        {
          'MAC Aging Time': macAgeTime,
        },
      ],
      more: !storage.get.IS_OPERATOR_USER ? (
        <span
          className="edit-content"
          onClick={toggleSlidingPage.bind(this, true)}
        >
          <A10Icon app="global" type="edit" /> Edit
        </span>
      ) : null,
    },
  ]

  return (
    <div className="col-md-12 marginBottom10">
      <Cards data={cardContent} className="device-card" />
      <A10SlidingPage
        isOpen={showSlidingPage}
        modalSize="large"
        onRequestClose={toggleSlidingPage.bind(this, false)}
      >
        <AutoFormV15
          hccEnv={true}
          schemaPath="network/vlan/global"
          apiPrefix={apiPrefix}
          interceptor={{
            onSubmitSuccess: handleSuccess,
            onCancel: toggleSlidingPage.bind(this, false),
          }}
          enableSOP={true}
          ENV_CONSTS={{
            IS_SHARED_PARTITION: selectedPartition.toLowerCase() === 'shared',
            PARTITION_TYPE:
              selectedPartition.toLowerCase() === 'shared' ? 'SHARED' : 'L3V',
          }}
        />
      </A10SlidingPage>
    </div>
  )
}

export default NetworkVlanGlobal
