import React from 'react'
import { A10Component } from '@gui-libraries/framework'
import { Highcharts } from '@gui-libraries/widgets'

import './styles/taskprocesschart.scss'

export interface ITaskInfo {
  name: string
  startTime: number
  endTime: number
  status: string
  retryCount: number
}

export interface IStatusInfo {
  stroke?: string
  color: string
  stripe?: string
}

export interface IChartPadding {
  'padding-top': number
  'padding-right': number
  'padding-bottom': number
  'padding-left': number
}

export interface IChartInfor {
  X: number
  Y: number
  width: number
  height: number
  labelSpan: number
  endSpan: number
  lineHeight: number
  timeAxis: ITimeAxisInfor
  taskItems: ITaskProcessInfo[]
  displayOrder: string
}

export interface IScaleInfor {
  text: string
  detail: string
}

export interface ITimeAxisInfor {
  scales: IScaleInfor[]
  axisStart: number
  axisEnd: number
}

export interface ITaskProcessInfo {
  axisStart: number
  axisEnd: number
  label: string
  taskStart: number
  taskEnd: number
  status: string
  stroke: string
  color: string
  stripe: string
  count: number
}

export interface IItemRect {
  X: number
  Y: number
  width: number
  height: number
  labelSpan?: number
  endSpan?: number
}

export interface ITasksProcessChartProps {
  taskList: ITaskInfo[]
  displayOrder?: string
  statusList?: { [status: string]: IStatusInfo }
  labelSpan?: number
  endSpan?: number
  lineHeight?: number
  padding?: IChartPadding
  resizeWith?: boolean
  timeLevelMax?: number
  showLevelRange?: number[]
}

export interface ITasksProcessChartState {
  domElement: HTMLDivElement
  chartRenderer: Highcharts.RendererObject
  width: number
  height: number
}

class TasksProcessChart extends A10Component<
  ITasksProcessChartProps,
  ITasksProcessChartState
> {
  static defaultProps: ITasksProcessChartProps = {
    taskList: [],
    displayOrder: 'asc',
    statusList: {
      COMPLETED: {
        color: '#7ed321',
      },
      COMPLETED_WITH_ERRORS: {
        stroke: '#ff8c00',
        color: '#7ed321',
      },
      IN_PROGRESS: {
        stroke: '#bbbbbb',
        color: '#dedede',
        stripe: '#acacac',
      },
      SCHEDULED: {
        color: '#afd7ff',
      },
      FAILED: {
        color: '#ff2e48',
      },
      FAILED_WITH_TERMINAL_ERROR: {
        stroke: '#ff8c00',
        color: '#ff2e48',
      },
      CANCELLED: {
        color: '#a80115',
      },
      CANCELED: {
        color: '#a80115',
      },
      TIMED_OUT: {
        color: '#ffb74d',
      },
      SKIPPED: {
        color: '#cccccc',
      },
    },
    labelSpan: 250,
    endSpan: 100,
    lineHeight: 30,
    padding: {
      'padding-top': 20,
      'padding-right': 20,
      'padding-bottom': 20,
      'padding-left': 20,
    },
    resizeWith: true,
    timeLevelMax: 5,
    showLevelRange: [0, 4],
  }

  chartContainer: React.RefObject<HTMLDivElement>
  elementGroup: Highcharts.ElementObject
  tooltipGroup: Highcharts.ElementObject
  cacheGroup: Highcharts.ElementObject
  designMode: boolean

  constructor(props: ITasksProcessChartProps) {
    super(props)

    this.state = {
      domElement: null,
      chartRenderer: null,
      width: null,
      height: null,
    }
    ;(this.chartContainer = React.createRef()), (this.elementGroup = null)
    this.tooltipGroup = null
    this.cacheGroup = null
    this.designMode = false
  }

  componentDidMount() {
    this.prepareRenderer()
    if (window.addEventListener) {
      window.addEventListener('resize', this.onResize)
    } else {
      console.log('Task Process Chart can not resize with window.')
    }
  }

  componentWillUnmount() {
    if (window.removeEventListener) {
      window.removeEventListener('resize', this.onResize)
    }
  }

  componentDidUpdate(
    prevProps: ITasksProcessChartProps,
    prevState: ITasksProcessChartState,
  ) {
    const { resizeWith: prevResizeWith, taskList: prevTaskList } = prevProps
    const { chartRenderer: prevRenderer } = prevState

    const { resizeWith, taskList } = this.props
    const { chartRenderer } = this.state

    if (this.checkRenderer()) {
      if (chartRenderer !== prevRenderer) {
        this.refreshChart()
      } else if (resizeWith && !prevResizeWith) {
        this.resizeChart()
      } else if (taskList !== prevTaskList) {
        this.resizeChart()
      }
    } else {
      this.prepareRenderer()
    }
  }

  checkRenderer = () => {
    const { domElement, chartRenderer } = this.state
    const { current } = this.chartContainer

    if (current !== null) {
      if (
        domElement === null ||
        chartRenderer === null ||
        domElement !== current
      ) {
        return false
      } else {
        return true
      }
    }
    return false
  }

  prepareRenderer = () => {
    const { current: domElement } = this.chartContainer
    if (domElement) {
      const width = this.getChartWidth()
      const height = this.getChartHeight()
      const chartRenderer = new Highcharts.Renderer(domElement, width, height)
      this.setState({ domElement, chartRenderer, width, height })
    }
  }

  onResize = () => {
    const { resizeWith } = this.props
    const { current } = this.chartContainer
    if (this.checkRenderer() && resizeWith && current.clientWidth !== 0) {
      this.resizeChart()
    }
  }

  resizeChart = () => {
    const { chartRenderer, width: prevWidth, height: prevHeight } = this.state

    const nextWidth = this.getChartWidth()
    const nextHeight = this.getChartHeight()

    if (nextWidth !== prevWidth || nextHeight !== prevHeight) {
      ;(chartRenderer as any).setSize(nextWidth, nextHeight)
      this.setState({ width: nextWidth, height: nextHeight }, () => {
        this.refreshChart()
      })
    } else {
      this.refreshChart()
    }
  }

  refreshChart = () => {
    const { chartRenderer, height } = this.state
    const { current } = this.chartContainer
    current.style.height = height + 'px'

    if (this.elementGroup) {
      this.elementGroup.destroy()
    }
    if (this.tooltipGroup) {
      this.tooltipGroup.destroy()
    }
    if (this.cacheGroup) {
      this.cacheGroup.destroy()
    }

    this.elementGroup = chartRenderer.g('elementGroup').add()
    this.tooltipGroup = chartRenderer.g('tooltipGroup').add()
    this.cacheGroup = chartRenderer.g('cacheGroup').add()

    try {
      this.drawChart(chartRenderer, this.getChartInfor(this.props, this.state))
    } catch (e) {
      console.error(e)
      console.error('Above error happens on drawing chart in TasksProcessChart')
    }
  }

  getChartHeight = () => {
    const { taskList, lineHeight, padding } = this.props
    return (
      ((taskList && (taskList.length + 1) * lineHeight) || 0) +
      padding['padding-top'] +
      padding['padding-bottom']
    )
  }

  getChartWidth = () => {
    const { current } = this.chartContainer
    return (current && current.clientWidth) || 0
  }

  getChartInfor = (
    props: ITasksProcessChartProps,
    state: ITasksProcessChartState,
  ) => {
    const {
      taskList,
      displayOrder,
      statusList,
      labelSpan,
      endSpan,
      lineHeight,
      padding,
    } = props
    const { width, height } = state

    const timeAxis = {
      start: NaN,
      end: NaN,
    }
    taskList.forEach((task: ITaskInfo) => {
      if (isNaN(timeAxis.start)) {
        timeAxis.start = task.startTime
      }
      if (isNaN(timeAxis.end)) {
        timeAxis.end = task.endTime
      }
      if (task.startTime < timeAxis.start) {
        timeAxis.start = task.startTime
      }
      if (task.endTime > timeAxis.end) {
        timeAxis.end = task.endTime
      }
    })

    const scaleSpan = this.getScaleSpan(timeAxis.end - timeAxis.start)

    timeAxis.start = Math.floor(timeAxis.start / scaleSpan) * scaleSpan
    timeAxis.end = Math.ceil(timeAxis.end / scaleSpan) * scaleSpan

    const scales: IScaleInfor[] = []
    for (
      let scale = timeAxis.start;
      scale <= timeAxis.end;
      scale = scale + scaleSpan
    ) {
      scales.push(this.getScaleText(scale, scaleSpan))
    }

    return {
      X: padding['padding-left'],
      Y: padding['padding-top'],
      width: width - padding['padding-left'] - padding['padding-right'],
      height: height - padding['padding-top'] - padding['padding-bottom'],
      labelSpan,
      endSpan,
      lineHeight,
      timeAxis: {
        scales,
        axisStart: timeAxis.start,
        axisEnd: timeAxis.end,
      },
      taskItems: taskList.map((task: ITaskInfo) => {
        const statusInfo = statusList[task.status]
        return {
          axisStart: timeAxis.start,
          axisEnd: timeAxis.end,
          label: this.getSpaceCase(task.name),
          taskStart: task.startTime,
          taskEnd: task.endTime,
          status: task.status,
          stroke: statusInfo ? statusInfo.stroke : undefined,
          color: statusInfo ? statusInfo.color : '#000000',
          stripe: statusInfo ? statusInfo.stripe : undefined,
          count: task.retryCount,
        }
      }),
      displayOrder,
    }
  }

  getScaleSpan = (duration: number) => {
    switch (true) {
      case duration < 1000 * 3:
        return 1000
      case duration < 1000 * 6:
        return 1000 * 2
      case duration < 1000 * 12:
        return 1000 * 4
      case duration < 1000 * 24:
        return 1000 * 8
      case duration < 1000 * 30:
        return 1000 * 10
      case duration < 1000 * 60:
        return 1000 * 20
      case duration < 1000 * 60 * 2:
        return 1000 * 40
      case duration < 1000 * 60 * 3:
        return 1000 * 60
      case duration < 1000 * 60 * 6:
        return 1000 * 60 * 2
      case duration < 1000 * 60 * 12:
        return 1000 * 60 * 4
      case duration < 1000 * 60 * 24:
        return 1000 * 60 * 8
      case duration < 1000 * 60 * 30:
        return 1000 * 60 * 10
      case duration < 1000 * 60 * 60:
        return 1000 * 60 * 20
      case duration < 1000 * 60 * 60 * 2:
        return 1000 * 60 * 40
      case duration < 1000 * 60 * 60 * 3:
        return 1000 * 60 * 60
      case duration < 1000 * 60 * 60 * 6:
        return 1000 * 60 * 60 * 2
      case duration < 1000 * 60 * 60 * 12:
        return 1000 * 60 * 60 * 4
      case duration < 1000 * 60 * 60 * 24:
        return 1000 * 60 * 60 * 8
      case duration < 1000 * 60 * 60 * 24 * 2:
        return 1000 * 60 * 60 * 16
      case duration < 1000 * 60 * 60 * 24 * 3:
        return 1000 * 60 * 60 * 24
      case duration < 1000 * 60 * 60 * 24 * 6:
        return 1000 * 60 * 60 * 24 * 2
      case duration < 1000 * 60 * 60 * 24 * 12:
        return 1000 * 60 * 60 * 24 * 4
      case duration < 1000 * 60 * 60 * 24 * 24:
        return 1000 * 60 * 60 * 24 * 8
      case duration < 1000 * 60 * 60 * 24 * 48:
        return 1000 * 60 * 60 * 24 * 16
      case duration < 1000 * 60 * 60 * 24 * 96:
        return 1000 * 60 * 60 * 24 * 32
      case duration < 1000 * 60 * 60 * 24 * 192:
        return 1000 * 60 * 60 * 24 * 64
      case duration < 1000 * 60 * 60 * 24 * 366:
        return 1000 * 60 * 60 * 24 * 122
      case duration < 1000 * 60 * 60 * 24 * 366 * 2:
        return 1000 * 60 * 60 * 24 * 244
      case duration < 1000 * 60 * 60 * 24 * 366 * 3:
        return 1000 * 60 * 60 * 24 * 366
      case duration < 1000 * 60 * 60 * 24 * 366 * 6:
        return 1000 * 60 * 60 * 24 * 366 * 2
      case duration < 1000 * 60 * 60 * 24 * 366 * 12:
        return 1000 * 60 * 60 * 24 * 366 * 4
      case duration < 1000 * 60 * 60 * 24 * 366 * 24:
        return 1000 * 60 * 60 * 24 * 366 * 8
      case duration < 1000 * 60 * 60 * 24 * 366 * 48:
        return 1000 * 60 * 60 * 24 * 366 * 16
      default:
        return 1000 * 60 * 60 * 24 * 366 * 32
    }
  }

  getScaleText = (scale: number, scaleSpan: number) => {
    const { timeLevelMax, showLevelRange } = this.props

    const time = new Date(scale)
    const year = time.getFullYear()
    const month = time.getMonth()
    const date = time.getDate()
    const hour = time.getHours()
    const minute = time.getMinutes()
    const second = time.getSeconds()

    const monthText = month + 1 < 10 ? '0' + (month + 1) : '' + (month + 1)
    const dateText = date < 10 ? '0' + date : '' + date
    const hourText = hour < 10 ? '0' + hour : '' + hour
    const minuteText = minute < 10 ? '0' + minute : '' + minute
    const secondText = second < 10 ? '0' + second : '' + second

    let monthAbbr: string = null
    switch (month + 1) {
      case 1:
        monthAbbr = 'Jan.'
        break
      case 2:
        monthAbbr = 'Feb.'
        break
      case 3:
        monthAbbr = 'Mar.'
        break
      case 4:
        monthAbbr = 'Apr.'
        break
      case 5:
        monthAbbr = 'May'
        break
      case 6:
        monthAbbr = 'Jun.'
        break
      case 7:
        monthAbbr = 'Jul.'
        break
      case 8:
        monthAbbr = 'Aug.'
        break
      case 9:
        monthAbbr = 'Sep.'
        break
      case 10:
        monthAbbr = 'Oct.'
        break
      case 11:
        monthAbbr = 'Nov.'
        break
      case 12:
        monthAbbr = 'Dec.'
        break
    }

    let scaleText: string = null
    switch (true) {
      // Time Level 0:
      case scaleSpan < 1000 * 60 || timeLevelMax < 1:
        if (0 < showLevelRange[0] || 0 > showLevelRange[1]) {
          scaleText = ''
          break
        }
        if (hour > 0 && hour < 13) {
          scaleText = `${hourText}:${minuteText}:${secondText}AM`
        } else if (hour === 0) {
          scaleText = `12:${minuteText}:${secondText}PM`
        } else {
          scaleText = `${
            hour - 12 < 10 ? '0' + (hour - 12) : '' + (hour - 12)
          }:${minuteText}:${secondText}PM`
        }
        break
      // Time Level 1:
      case scaleSpan < 1000 * 60 * 60 || timeLevelMax < 2:
        if (1 < showLevelRange[0] || 1 > showLevelRange[1]) {
          scaleText = ''
          break
        }
        if (hour > 0 && hour < 13) {
          scaleText = `${hourText}:${minuteText}AM`
        } else if (hour === 0) {
          scaleText = `12:${minuteText}PM`
        } else {
          scaleText = `${
            hour - 12 < 10 ? '0' + (hour - 12) : '' + (hour - 12)
          }:${minuteText}PM`
        }
        break
      // Time Level 2:
      case scaleSpan < 1000 * 60 * 60 * 24 || timeLevelMax < 3:
        if (2 < showLevelRange[0] || 2 > showLevelRange[1]) {
          scaleText = ''
          break
        }
        if (hour > 0 && hour < 13) {
          scaleText = `${monthAbbr} ${date}th ${hour}AM`
        } else if (hour === 0) {
          scaleText = `${monthAbbr} ${date}th 12PM`
        } else {
          scaleText = `${monthAbbr} ${date}th ${hour - 12}PM`
        }
        break
      // Time Level 3:
      case scaleSpan < 1000 * 60 * 60 * 24 * 366 || timeLevelMax < 4:
        if (3 < showLevelRange[0] || 3 > showLevelRange[1]) {
          scaleText = ''
          break
        }
        scaleText = `${year} ${monthAbbr} ${date}th`
        break
      // Time Level 4:
      case scaleSpan < 1000 * 60 * 60 * 24 * (365 * 10 + 3) || timeLevelMax < 5:
        if (4 < showLevelRange[0] || 4 > showLevelRange[1]) {
          scaleText = ''
          break
        }
        scaleText = `${year} ${monthAbbr}`
        break
      // Time Level 5:
      default:
        if (5 < showLevelRange[0] || 5 > showLevelRange[1]) {
          scaleText = ''
          break
        }
        scaleText = `${year}`
        break
    }

    const ymd = `${year}-${monthText}-${dateText}`
    let hms: string = null
    if (hour > 0 && hour < 13) {
      hms = `${hourText}:${minuteText}:${secondText}AM`
    } else if (hour === 0) {
      hms = `12:${minuteText}:${secondText}PM`
    } else {
      hms = `${
        hour - 12 < 10 ? '0' + (hour - 12) : '' + (hour - 12)
      }:${minuteText}:${secondText}PM`
    }
    const scaleDetail = `${ymd} ${hms}`

    return {
      text: scaleText,
      detail: scaleDetail,
    }
  }

  getSpaceCase = (text: string) => {
    // Format 'IAmAGoodMan' to 'I Am A Good Man'
    const blocks = text.split(' ')
    return blocks
      .map((block: string) => {
        const matchSingleWord = /[A-Z][a-z]*/g
        const slices = block.match(matchSingleWord)

        const matchMultiLetterWord = /[A-Z][a-z]+/
        const { wordList: wordSlices } = slices.reduce(
          (results: IObject, slice: string) => {
            const { wordList, lastWordCanMerge } = results as {
              wordList: string[]
              lastWordCanMerge: boolean
            }
            const currentWordCanMerge = !matchMultiLetterWord.test(slice)
            if (currentWordCanMerge && lastWordCanMerge) {
              const lastWord = wordList.pop()
              wordList.push(lastWord + slice)
              return { wordList, lastWordCanMerge: true }
            } else if (currentWordCanMerge && !lastWordCanMerge) {
              wordList.push(slice)
              return { wordList, lastWordCanMerge: true }
            } else {
              wordList.push(slice)
              return { wordList, lastWordCanMerge: false }
            }
          },
          { wordList: [], lastWordCanMerge: false },
        )

        return wordSlices.join(' ')
      })
      .join(' ')
  }

  drawChart = (renderer: Highcharts.RendererObject, infor: IChartInfor) => {
    const {
      X,
      Y,
      width,
      height,
      labelSpan,
      endSpan,
      lineHeight,
      timeAxis,
      taskItems,
      displayOrder,
    } = infor
    if (taskItems && taskItems.length === 0) {
      renderer
        .label(
          'No Data',
          width / 2 - 35,
          height / 2 + 10,
          'rect',
          0,
          0,
          false,
          false,
        )
        .attr({
          zIndex: 2,
        })
        .css({
          fontFamily: 'Roboto',
          fontSize: '16px',
          color: '#00000073',
        })
        .add(this.elementGroup)
    } else {
      if (this.designMode) {
        renderer
          .rect(X, Y, width, height, 0)
          .attr({
            stroke: '#000000',
            'stroke-width': 1,
            fill: 'red',
          })
          .add(this.elementGroup)
        renderer
          .rect(
            X + 2,
            Y + height - lineHeight + 1,
            width - 4,
            lineHeight - 3,
            0,
          )
          .attr({
            fill: 'green',
          })
          .add(this.elementGroup)
      }

      this.drawTimeAxisItem(
        renderer,
        {
          X: X + 2,
          Y: Y + height - lineHeight + 1,
          width: width - 4,
          height: lineHeight - 3,
          labelSpan,
          endSpan,
        },
        timeAxis,
      )
      taskItems.forEach((item: ITaskProcessInfo, index: number) => {
        let yOffset: number = lineHeight * index + 1
        if (displayOrder === 'desc') {
          yOffset = height - lineHeight * (index + 2) + 1
        } else {
          yOffset = lineHeight * index + 1
        }

        if (this.designMode) {
          renderer
            .rect(X + 2, Y + yOffset, width - 4, lineHeight - 2, 0)
            .attr({
              fill: 'blue',
            })
            .add(this.elementGroup)
        }

        this.drawTaskProcessItem(
          renderer,
          {
            X: X + 2,
            Y: Y + yOffset,
            width: width - 4,
            height: lineHeight - 2,
            labelSpan,
            endSpan,
          },
          item,
        )
      })
    }
    return
  }

  drawTimeAxisItem = (
    renderer: Highcharts.RendererObject,
    rect: IItemRect,
    itemInfor: ITimeAxisInfor,
  ) => {
    const { X, Y, width, height, labelSpan, endSpan } = rect
    const startX = X + labelSpan
    const startY = Y + (height - 16) / 2
    const scaleWidth =
      (width - labelSpan - endSpan) / (itemInfor.scales.length - 1)
    itemInfor.scales.forEach((scale: IScaleInfor, index: number) => {
      if (this.designMode) {
        renderer
          .rect(startX + scaleWidth * index, startY, scaleWidth, 16, 0)
          .attr({
            fill: 'yellow',
          })
          .add(this.elementGroup)
      }

      let showScale = scale.text
      let textWidth = 0
      for (let i = 0; i < scale.text.length; i++) {
        const cacheText = renderer
          .text(showScale, 0, 0)
          .css({
            fontFamily: 'Roboto',
            fontSize: '16px',
            lineHeight: '16px',
            color: '#2c2c2c',
          })
          .add(this.cacheGroup)
        try {
          if ((cacheText as any).element.getBoundingClientRect instanceof Function) {
            textWidth = (cacheText as any).element.getBoundingClientRect().width
          } else if ((cacheText as any).element.getBBox instanceof Function) {
            textWidth = (cacheText as any).element.getBBox().width
          }
        } catch {
          console.warn(
            `Can't get the size of SVG element '${showScale}', whose style 'display' may be none.`,
          )
        }
        if (textWidth > scaleWidth) {
          showScale = scale.text.slice(0, -i - 1) + '...'
          continue
        } else {
          break
        }
      }
      this.cacheGroup.destroy()
      this.cacheGroup = renderer.g('cacheGroup').add()

      renderer
        .label(showScale, startX + scaleWidth * index, startY - 3)
        .css({
          fontFamily: 'Roboto',
          fontSize: '16px',
          lineHeight: '16px',
          color: '#2c2c2c',
        })
        .add(this.elementGroup)
        .on('mouseover', () => {
          this.drawHelp(
            renderer,
            {
              X: startX + scaleWidth * index,
              Y: startY,
              width: textWidth,
              height: 16,
            },
            scale.detail,
          )
        })
        .on('mouseleave', () => {
          this.closeTooltips()
        })
    })
  }

  drawTaskProcessItem = (
    renderer: Highcharts.RendererObject,
    rect: IItemRect,
    itemInfor: ITaskProcessInfo,
  ) => {
    const { X, Y, width, height, labelSpan, endSpan } = rect

    const labelX = X
    const labelY = Y + (height - 16) / 2
    const labelWidth = labelSpan
    const labelHeight = 16

    const processBarX = X + labelWidth
    const processBarY = labelY
    const processBarWidth = width - labelSpan - endSpan
    const processBarHeight = 16

    this.drawLabel(
      renderer,
      {
        X: labelX,
        Y: labelY,
        width: labelWidth,
        height: labelHeight,
        labelSpan,
        endSpan,
      },
      itemInfor,
    )
    this.drawProcessBar(
      renderer,
      {
        X: processBarX,
        Y: processBarY,
        width: processBarWidth,
        height: processBarHeight,
        labelSpan,
        endSpan,
      },
      itemInfor,
    )
  }

  drawLabel = (
    renderer: Highcharts.RendererObject,
    rect: IItemRect,
    itemInfor: ITaskProcessInfo,
  ) => {
    const { X, Y, width, height } = rect

    if (this.designMode) {
      renderer
        .rect(X, Y, width, height, 0)
        .attr({
          fill: 'orange',
        })
        .add(this.elementGroup)
    }

    // Draw Label
    const wholeLabel = itemInfor.label || 'Unknown'
    let shownLabel = wholeLabel
    let labelWidth = 0
    for (let i = 0; i < wholeLabel.length; i++) {
      const cacheText = renderer
        .text(shownLabel, 0, 0)
        .css({
          fontFamily: 'Roboto',
          fontSize: '16px',
          lineHeight: '16px',
          color: '#2c2c2c',
        })
        .add(this.cacheGroup)
      try {
        if ((cacheText as any).element.getBoundingClientRect instanceof Function) {
          labelWidth = (cacheText as any).element.getBoundingClientRect().width
        } else if ((cacheText as any).element.getBBox instanceof Function) {
          labelWidth = (cacheText as any).element.getBBox().width
        }
      } catch {
        console.warn(
          `Can't get the size of SVG element '${shownLabel}', whose style 'display' may be none.`,
        )
      }
      if (labelWidth > width - 20) {
        shownLabel = wholeLabel.slice(0, -i - 1) + '...'
        continue
      } else {
        break
      }
    }
    this.cacheGroup.destroy()
    this.cacheGroup = renderer.g('cacheGroup').add()

    const text = renderer.text(shownLabel, X, Y + 13)
    text
      .css({
        fontFamily: 'Roboto',
        fontSize: '16px',
        lineHeight: '16px',
        color: '#2c2c2c',
      })
      .add(this.elementGroup)
      .attr({
        // translateX: width - labelWidth - 20,     // Text align: right
        translateX: 0, // Text align: left
      })
      .on('mouseover', () => {
        if (shownLabel.includes('...')) {
          this.drawHelp(
            renderer,
            {
              X: X + width - labelWidth - 20,
              Y,
              width: labelWidth,
              height,
            },
            wholeLabel,
          )
        }
      })
      .on('mouseleave', () => {
        this.closeTooltips()
      })

    // Draw retry icon
    if (itemInfor.count) {
      renderer
        .image(
          '../../../assets/images/retry@2x.png',
          X + width - 20,
          Y - 3,
          20,
          20,
        )
        .add(this.elementGroup)
      renderer
        .text(`${itemInfor.count}`, X + width - 19, Y + 12)
        .css({
          fontFamily: 'Roboto',
          fontSize: '12px',
          fontWeight: '700',
          lineHeight: '12px',
          color: '#999999',
        })
        .add(this.elementGroup)
        .attr({
          translateX: 6,
        })
    }
  }

  drawProcessBar = (
    renderer: Highcharts.RendererObject,
    rect: IItemRect,
    itemInfor: ITaskProcessInfo,
  ) => {
    const { X, Y, width, height } = rect
    const {
      axisStart,
      axisEnd,
      taskStart,
      taskEnd,
      stroke,
      color,
      stripe,
      count,
    } = itemInfor

    const retryX = X + (width * (taskStart - axisStart)) / (axisEnd - axisStart)
    const retrySpan = 3
    const taskX = retryX + count * (height + retrySpan)
    const taskWidth =
      (width * (taskEnd - taskStart)) / (axisEnd - axisStart) -
      count * (height + retrySpan)

    // Draw retry point
    for (let i = 0; i < count; i++) {
      renderer
        .rect(retryX + i * (height + retrySpan), Y, height, height, height / 2)
        .attr({
          fill: '#fc324d',
        })
        .add(this.elementGroup)
    }

    // Draw process bar
    renderer
      .rect(
        taskX,
        Y,
        taskWidth > height ? taskWidth : height,
        height,
        height / 2,
      )
      .attr({
        stroke,
        'stroke-width': 2,
        fill: color,
      })
      .on('mouseover', () => {
        this.drawTooltip(
          renderer,
          {
            X: taskX,
            Y,
            width: taskWidth > height ? taskWidth : height,
            height,
          },
          itemInfor,
        )
      })
      .on('mouseleave', () => {
        this.closeTooltips()
      })
      .add(this.elementGroup)

    // Draw stripe of process bar
    if (stripe) {
      for (let i = 0; i < Math.floor((taskWidth - height) / 12); i++) {
        renderer
          .path([
            'M',
            taskX + height / 2 + 12 * i,
            Y + height,
            'L',
            taskX + height / 2 + 12 * (i + 1),
            Y,
          ])
          .attr({
            'stroke-width': 2,
            stroke: stripe,
          })
          .add(this.elementGroup)
      }
    }
  }

  drawHelp = (
    renderer: Highcharts.RendererObject,
    rect: IItemRect,
    helpText: string,
  ) => {
    const { X, Y, width, height } = rect

    const tooltipBoxX = X + width / 2
    const tooltipBoxY = Y + height + 20

    const helpTextX = tooltipBoxX + 20
    const helpTextY = tooltipBoxY + 20

    const cacheText = renderer.text(helpText, 0, 0).add(this.cacheGroup)
    cacheText.css({
      fontFamily: 'Roboto',
      fontSize: '16px',
    })
    let textWidth = 0
    try {
      if ((cacheText as any).element.getBoundingClientRect instanceof Function) {
        textWidth = (cacheText as any).element.getBoundingClientRect().width
      } else if ((cacheText as any).element.getBBox instanceof Function) {
        textWidth = (cacheText as any).element.getBBox().width
      }
    } catch {
      console.warn(
        `Can't get the size of SVG element '${helpText}', whose style 'display' may be none.`,
      )
    }
    const tooltipBoxWidth = textWidth + 40
    const tooltipBoxHeight = 30
    this.cacheGroup.destroy()
    this.cacheGroup = renderer.g('cacheGroup').add()

    // Draw tooltip box and arrow
    renderer
      .rect(tooltipBoxX, tooltipBoxY, tooltipBoxWidth, tooltipBoxHeight, 5)
      .attr({
        fill: '#404040',
      })
      .add(this.elementGroup)
      .add(this.tooltipGroup)
    renderer
      .path([
        'M',
        tooltipBoxX + (tooltipBoxWidth - 20) / 2,
        tooltipBoxY,
        'L',
        tooltipBoxX + (tooltipBoxWidth - 20) / 2 + 20,
        tooltipBoxY,
        'L',
        tooltipBoxX + (tooltipBoxWidth - 20) / 2 + 10,
        tooltipBoxY - 10,
        'Z',
      ])
      .attr({
        fill: '#404040',
        translateY: 1,
      })
      .add(this.elementGroup)
      .add(this.tooltipGroup)

    // Draw help text
    renderer
      .text(helpText, helpTextX, helpTextY)
      .css({
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '16px',
        color: '#ffffff',
      })
      .add(this.elementGroup)
      .add(this.tooltipGroup)

    this.tooltipGroup.attr({
      translateX: -tooltipBoxWidth / 2,
    })
  }

  drawTooltip = (
    renderer: Highcharts.RendererObject,
    rect: IItemRect,
    itemInfor: ITaskProcessInfo,
  ) => {
    const { X, Y, width, height } = rect
    const { taskStart, taskEnd, label, status, color, count } = itemInfor

    const tooltipBoxX = X + width / 2
    const tooltipBoxY = Y + height + 20

    const durationText = `${this.getDurationText(
      taskStart,
    )} to ${this.getDurationText(taskEnd)}`
    const durationTextX = tooltipBoxX + 20
    const durationTextY = tooltipBoxY + 30

    const statusText = `${label} ${status}`
    const statusIconX = durationTextX
    const statusIconY = durationTextY + 15
    const statusTextX = statusIconX + 15
    const statusTextY = statusIconY + 12

    const retryText = `Retried ${count} times`
    const retryTextX = statusTextX
    const retryTextY = statusTextY + 25

    const cacheDuration = renderer.text(durationText, 0, 0).add(this.cacheGroup)
    cacheDuration.css({
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: '400',
    })
    const cacheStatus = renderer.text(statusText, 0, 0).add(this.cacheGroup)
    cacheStatus.css({
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '400',
    })
    const cacheRetry = renderer.text(retryText, 0, 0).add(this.cacheGroup)
    cacheRetry.css({
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '400',
    })

    let durationWidth = 0
    let statusWidth = 0
    let retryWidth = 0
    try {
      if ((cacheDuration as any).element.getBoundingClientRect instanceof Function) {
        durationWidth = (cacheDuration as any).element.getBoundingClientRect().width
        statusWidth = (cacheStatus as any).element.getBoundingClientRect().width
        retryWidth = (cacheRetry as any).element.getBoundingClientRect().width
      } else if ((cacheDuration as any).element.getBBox instanceof Function) {
        durationWidth = (cacheDuration as any).element.getBBox().width
        statusWidth = (cacheStatus as any).element.getBBox().width
        retryWidth = (cacheRetry as any).element.getBBox().width
      }
    } catch {
      console.warn(
        `Can't get the size of SVG element '${durationText}', whose style 'display' may be none.`,
      )
      console.warn(
        `Can't get the size of SVG element '${statusText}', whose style 'display' may be none.`,
      )
      console.warn(
        `Can't get the size of SVG element '${retryText}', whose style 'display' may be none.`,
      )
    }
    const tooltipBoxWidth =
      Math.max(durationWidth, statusWidth + 15, retryWidth) + 40
    const tooltipBoxHeight = count > 0 ? 100 : 80
    this.cacheGroup.destroy()
    this.cacheGroup = renderer.g('cacheGroup').add()

    // Draw tooltip box and arrow
    renderer
      .rect(tooltipBoxX, tooltipBoxY, tooltipBoxWidth, tooltipBoxHeight, 5)
      .attr({
        fill: '#404040',
      })
      .add(this.elementGroup)
      .add(this.tooltipGroup)
    renderer
      .path([
        'M',
        tooltipBoxX + (tooltipBoxWidth - 20) / 2,
        tooltipBoxY,
        'L',
        tooltipBoxX + (tooltipBoxWidth - 20) / 2 + 20,
        tooltipBoxY,
        'L',
        tooltipBoxX + (tooltipBoxWidth - 20) / 2 + 10,
        tooltipBoxY - 10,
        'Z',
      ])
      .attr({
        fill: '#404040',
        translateY: 1,
      })
      .add(this.elementGroup)
      .add(this.tooltipGroup)

    // Draw duration text
    renderer
      .text(durationText, durationTextX, durationTextY)
      .css({
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '16px',
        color: '#ffffff',
      })
      .add(this.elementGroup)
      .add(this.tooltipGroup)

    // Draw status icon and text
    renderer
      .rect(statusIconX, statusIconY, 12, 12, 6)
      .attr({
        fill: color,
      })
      .add(this.elementGroup)
      .add(this.tooltipGroup)
    renderer
      .text(statusText, statusTextX, statusTextY)
      .css({
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '14px',
        color: '#ffffff',
      })
      .add(this.elementGroup)
      .add(this.tooltipGroup)

    // Draw retry text
    if (count > 0) {
      renderer
        .text(retryText, retryTextX, retryTextY)
        .css({
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '14px',
          color: '#ffffff',
        })
        .add(this.elementGroup)
        .add(this.tooltipGroup)
    }

    this.tooltipGroup.attr({
      translateX: -tooltipBoxWidth / 2,
    })
  }

  closeTooltips = () => {
    const { chartRenderer } = this.state
    if (this.tooltipGroup) {
      this.tooltipGroup.destroy()
    }

    this.tooltipGroup = chartRenderer.g('tooltipGroup').add()
  }

  getDurationText = (scale: number): string => {
    const time = new Date(scale)
    const hour = time.getHours()
    const minute = time.getMinutes()
    const minuteText = minute < 10 ? `0${minute}` : `${minute}`
    const second = time.getSeconds()
    const secondText = second < 10 ? `0${second}` : `${second}`
    if (hour > 0 && hour < 13) {
      const hourText = hour < 10 ? `0${hour}` : `${hour}`
      return `${hourText}:${minuteText}:${secondText} AM`
    } else if (hour === 0) {
      return `${12}:${minuteText}:${secondText} PM`
    } else {
      const hourText = hour - 12 < 10 ? `0${hour - 12}` : `${hour - 12}`
      return `${hourText}:${minuteText}:${secondText} PM`
    }
  }

  render() {
    return <div className="process-chart-container" ref={this.chartContainer} />
  }
}
export default TasksProcessChart
