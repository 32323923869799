import {
  A10Card,
  A10Col,
  A10Descriptions,
  A10Row,
} from '@gui-libraries/widgets'
import {
  ContentHeader,
  ContentSection,
} from 'src/components/shared/ContentSection'
import React, { useEffect, useState } from 'react'
import { _, setupA10Container } from '@gui-libraries/framework'

import { ActionButton } from 'src/components/shared/ActionButton'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { NTPForm } from './NTPForm/index'
import ReactLoading from 'react-loading'
import { SystemService } from 'src/services/index'
import { Utilities } from 'src/services'
import '../styles/index.less'

const systemService = new SystemService()

export const getNTPStatusDetails = async () => {
  let apiResponse: any = []
  try {
    const { data: response } = await systemService.getNTPStatus(
      null,
      null,
      null,
    )
    apiResponse = response
  } catch (error) {
    apiResponse = []
  }

  return apiResponse
}

export const getNTPDetails = async () => {
  let apiResponse: any = []
  try {
    const { data: response } = await systemService.getNTPDetails(
      null,
      null,
      null,
    )
    apiResponse = response
  } catch (error) {
    apiResponse = []
  }

  return apiResponse
}

interface INTPServerState {
  node0: any
  slidingTitle?: string
  ntpServerDetails: any
  showNTPServerForm: boolean
  ntpStatusData: any
}

const NTPDetails = () => {
  const utilities = new Utilities()
  const [showNTPServerForm, setshowNTPServerForm] = useState(false)
  const [ntpStatusData, setntpStatusData] = useState<INTPServerState>()
  const [slidingTitle, setslidingTitle] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [ntpServerDetails, setNTPServerDetails] = useState([])
  const [serverUpdateDetails, setserverUpdateDetails] = useState([])
  const [disableSave, setDisableSave] = useState(true)

  const fetchNTPDetails = async () => {
    setLoading(true)
    const ntpDetails = await getNTPDetails()
    handleGetStatusData()
    setNTPServerDetails(ntpDetails.servers)
    setLoading(false)
  }

  useEffect(() => {
    fetchNTPDetails()
  }, [])

  const handleGetStatusData = async () => {
    const response = await getNTPStatusDetails()
    const logID = response?.id
    const nodeDetail = response?.status?.node0
    if (logID && !nodeDetail) {
      let status: string
      let newStatus: string = 'RUNNING'
      let outputInfo: IObject = null
      const timeout = 2000
      do {
        const { data: outputData } = await systemService.checkNTPStatus(
          null,
          null,
          logID,
        )

        newStatus = outputData?.status
        if (status !== newStatus) {
          status = newStatus
        }
        await new Promise(r => setTimeout(r, timeout))
        outputInfo = outputData
      } while (newStatus === 'PENDING')

      setntpStatusData(outputInfo.status)
    } else if (nodeDetail) {
      setntpStatusData(response?.status)
    }
    setLoading(false)
  }

  const closeNTPForm = () => {
    setDisableSave(true)
    setLoading(false)
    closeForms()
  }
  const handleFormChange = (data: any) => {
    if (data) {
      setDisableSave(false)
      setserverUpdateDetails(data)
    } else {
      setDisableSave(true)
    }
  }
  const setConfigPage = (isOpen: boolean) => {
    setshowNTPServerForm(isOpen)
    setslidingTitle('Edit NTP Server Configuration')
  }
  const closeForms = () => {
    setshowNTPServerForm(false)
  }
  const saveNTP = async () => {
    setLoading(true)
    const saveNTPdetails = systemService.saveNTP(
      null,
      { servers: serverUpdateDetails },
      null,
    )

    saveNTPdetails
      .then((response: any) => {
        if (response.status == 201) {
          utilities.showMessage('SUCCESS_NTP_UPDATE', 1, 1)
          fetchNTPDetails()
        } else {
          setLoading(false)
          utilities.showMessage('FAILED_NTP_UPDATE', 0, 1)
        }
      })
      .catch((error: any) => {
        utilities.showMessage('FAILED_NTP_UPDATE', 0, true)
      })
    closeNTPForm()
  }

  return (
    <>
      <ContentSection className="system-content-section">
        <div style={{ display: 'flex' }}>
          <A10Card className="ntp-details">
            <ContentHeader
              type="flex"
              align="middle"
              justify="space-between"
              className="test"
            >
              <A10Col>
                <span className="system-page-heading">NTP SERVERS</span>
              </A10Col>
              <A10Col>
                <ActionButton
                  text="Edit"
                  testID="edit-ntp-servers"
                  onClick={() => setConfigPage(true)}
                  iconProps={{ app: 'global', type: 'edit' }}
                />
              </A10Col>
            </ContentHeader>
            <A10Row>
              <A10Col span={24}>
                <A10Col className="ntp-col-heading">Server/URL</A10Col>
              </A10Col>
            </A10Row>
            {isLoading ? (
              <A10Col>
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </A10Col>
            ) : (
              <div className="ntp-servers-content">
                {ntpServerDetails &&
                  ntpServerDetails.map((item, index) => {
                    return (
                      <>
                        <span>{item} </span>
                        {index < ntpServerDetails.length - 1 && (
                          <label>
                            <span>,</span>&nbsp;
                          </label>
                        )}
                      </>
                    )
                  })}
              </div>
            )}
          </A10Card>
          <A10Card className="status-container">
            <ContentHeader type="flex" align="middle" justify="left">
              <A10Col>
                <span className="system-page-heading">NTP STATUS</span>
              </A10Col>
            </ContentHeader>
            {isLoading && !ntpStatusData ? (
              <A10Col>
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </A10Col>
            ) : (
              ntpStatusData && (
                <A10Descriptions
                  title=""
                  layout="vertical"
                  size="small"
                  column={{ xxl: 5, xl: 5, lg: 5, md: 3, sm: 2, xs: 1 }}
                >
                  <>
                    <A10Descriptions.Item label="Node">
                      {Object.keys(ntpStatusData)[0]}
                    </A10Descriptions.Item>
                    <A10Descriptions.Item label="Node IP">
                      {ntpStatusData[Object.keys(ntpStatusData)[0]].node_ip}
                    </A10Descriptions.Item>
                    <A10Descriptions.Item label="NTP">
                      {ntpStatusData[Object.keys(ntpStatusData)[0]].ntp}
                    </A10Descriptions.Item>
                    <A10Descriptions.Item label="offset">
                      {ntpStatusData[Object.keys(ntpStatusData)[0]].offset}
                    </A10Descriptions.Item>
                    <A10Descriptions.Item label="Status">
                      {ntpStatusData[Object.keys(ntpStatusData)[0]].status}
                    </A10Descriptions.Item>
                  </>
                </A10Descriptions>
              )
            )}
          </A10Card>
        </div>
      </ContentSection>
      <FormatSlidingPage
        isOpen={showNTPServerForm}
        onRequestClose={closeNTPForm}
        title={slidingTitle}
        disableSave={disableSave}
        onRequestOk={saveNTP}
      >
        <NTPForm
          ntpServerData={ntpServerDetails}
          handleChange={(data: IObject) => handleFormChange(data)}
          setDisableSave={(disableSave: boolean) => setDisableSave(disableSave)}
        ></NTPForm>
      </FormatSlidingPage>
    </>
  )
}

export default setupA10Container(NTPDetails)
