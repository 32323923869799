export default {
  'aam.aaa-policy': ['name'],
  'aam.aaa-policy.aaa-rule': ['name', 'index'],
  'aam.authentication.account.kerberos-spn': ['name'],
  'aam.authentication.global': null,
  'aam.authentication.jwt': ['name'],
  'aam.authentication.log': null,
  'aam.authentication.logon.form-based': ['name'],
  'aam.authentication.logon.http-authenticate.instance': ['name'],
  'aam.authentication.portal.change-password': ['name'],
  'aam.authentication.portal.logon': ['name'],
  'aam.authentication.portal.logon-fail': ['name'],
  'aam.authentication.portal.notify-change-password': ['name'],
  'aam.authentication.portal': ['name'],
  'aam.authentication.relay.form-based.instance': ['name'],
  'aam.authentication.relay.form-based.instance.request-uri': [
    'name',
    '{match-type}+{uri}',
  ],
  'aam.authentication.relay.http-basic.instance': ['name'],
  'aam.authentication.relay.kerberos.instance': ['name'],
  'aam.authentication.relay.ntlm': ['name'],
  'aam.authentication.relay.ws-federation': ['name'],
  'aam.authentication.saml.identity-provider': ['name'],
  'aam.authentication.saml.metadata-monitor': null,
  'aam.authentication.saml.service-provider': ['name'],
  'aam.authentication.server.ldap.instance': ['name'],
  'aam.authentication.server.ocsp.instance': ['name'],
  'aam.authentication.server.radius.instance': ['name'],
  'aam.authentication.server.windows.instance': ['name'],
  'aam.authentication.service-group.member': ['name', '{name}+{port}'],
  'aam.authentication.service-group': ['name'],
  'aam.authentication.template': ['name'],
  'aam.authorization.policy.attribute': ['name', 'attr-num'],
  'aam.authorization.policy.jwt-claim-map': ['name', 'attr-num'],
  'aam.authorization.policy': ['name'],
  'aam.jwt-authorization': ['name'],
  'access-list': null,
  'access-list.extended': ['extd'],
  'access-list.standard': ['std'],
  accounting: null,
  'accounting.exec': null,
  'acos-events.active-template': null,
  'acos-events.collector-group': ['name'],
  'acos-events.collector-group.log-server': ['name', '{name}+{port}'],
  'acos-events.log-server': ['name'],
  'acos-events.log-server.port': ['name', '{port-number}+{protocol}'],
  'acos-events.logdb': null,
  'acos-events.message-id': ['log-msg'],
  'acos-events.message-id.property.severity': ['log-msg'],
  'acos-events.message-selector': ['name'],
  'acos-events.message-selector.rule': ['name', 'index'],
  'acos-events.template': ['name'],
  'active-partition': null,
  'admin.access': ['user'],
  admin: ['user'],
  'admin.aws-accesskey': ['user'],
  'admin.password': ['user'],
  'admin.ssh-pubkey': ['user'],
  'admin-lockout': null,
  'admin-session': null,
  'aflex-scripts': null,
  'allow-slb-cfg': null,
  'application-type': null,
  audit: null,
  authentication: null,
  'authentication.console': null,
  authorization: null,
  'automatic-update': null,
  'automatic-update.check-now': null,
  'automatic-update.config': ['feature-name'],
  'automatic-update.proxy-server': null,
  'automatic-update.reset': null,
  'automatic-update.revert': null,
  axdebug: null,
  'axdebug.capture': null,
  'axdebug.filter-config': ['number'],
  backup: null,
  'backup.log': null,
  'backup.store': null,
  'backup.system': null,
  'backup-periodic': null,
  banner: null,
  bgp: null,
  'bios-prog': null,
  'boot-block-fix': null,
  bootimage: null,
  'cgnv6.ddos-protection': null,
  'cgnv6.dhcpv6.map.translation.common': null,
  'cgnv6.dhcpv6.map.translation.domain': ['domain-name'],
  'cgnv6.dns64-virtualserver': ['name'],
  'cgnv6.dns64-virtualserver.port': ['name', '{port-number}+{protocol}'],
  'cgnv6.ds-lite.alg.ftp': null,
  'cgnv6.ds-lite.alg.h323': null,
  'cgnv6.ds-lite.alg.mgcp': null,
  'cgnv6.ds-lite.alg.pptp': null,
  'cgnv6.ds-lite.alg.rtsp': null,
  'cgnv6.ds-lite.alg.sip': null,
  'cgnv6.ds-lite.alg.tftp': null,
  'cgnv6.ds-lite.fragmentation.inbound': null,
  'cgnv6.ds-lite.fragmentation.outbound': null,
  'cgnv6.ds-lite.global': null,
  'cgnv6.ds-lite.port-reservation': [
    '{inside}+{tunnel-dest-address}+{inside-addr}+{inside-start-port}+{inside-end-port}+{nat}+{nat-start-port}+{nat-end-port}',
  ],
  'cgnv6.ecmp': null,
  'cgnv6.fixed-nat.global': null,
  'cgnv6.fixed-nat.inside.iplist': ['{inside-ip-list}+{partition}'],
  'cgnv6.fixed-nat.inside.ipv4address': [
    '{inside-start-address}+{inside-end-address}+{inside-netmask}+{partition}',
  ],
  'cgnv6.fixed-nat.inside.ipv6address': [
    '{inside-start-address}+{inside-end-address}+{inside-netmask}+{partition}',
  ],
  'cgnv6.global': null,
  'cgnv6.logging.nat-quota-exceeded': null,
  'cgnv6.logging.nat-resource-exhausted': null,
  'cgnv6.lsn.alg.esp': null,
  'cgnv6.lsn.alg.ftp': null,
  'cgnv6.lsn.alg.h323': null,
  'cgnv6.lsn.alg.mgcp': null,
  'cgnv6.lsn.alg.pptp': null,
  'cgnv6.lsn.alg.rtp': null,
  'cgnv6.lsn.alg.rtsp': null,
  'cgnv6.lsn.alg.sip': null,
  'cgnv6.lsn.alg.tftp': null,
  'cgnv6.lsn.endpoint-independent-filtering.tcp': null,
  'cgnv6.lsn.endpoint-independent-filtering.udp': null,
  'cgnv6.lsn.endpoint-independent-mapping.tcp': null,
  'cgnv6.lsn.endpoint-independent-mapping.udp': null,
  'cgnv6.lsn.global': null,
  'cgnv6.lsn.health-check-gateway': null,
  'cgnv6.lsn.inside.source': null,
  'cgnv6.lsn.port-overloading.global': null,
  'cgnv6.lsn.port-overloading.tcp': null,
  'cgnv6.lsn.port-overloading.udp': null,
  'cgnv6.lsn.port-reservation': [
    '{inside}+{inside-port-start}+{inside-port-end}+{nat}+{nat-port-start}+{nat-port-end}',
  ],
  'cgnv6.lsn.radius.server': null,
  'cgnv6.lsn.stun-timeout.tcp': ['{port-start}+{port-end}'],
  'cgnv6.lsn.stun-timeout.udp': ['{port-start}+{port-end}'],
  'cgnv6.lsn.tcp.mss-clamp': null,
  'cgnv6.lsn.tcp.reset-on-error': null,
  'cgnv6.lsn-lid': ['lid-number'],
  'cgnv6.lsn-radius-profile': ['lid-profile-index'],
  'cgnv6.lsn-rule-list.default': ['name'],
  'cgnv6.lsn-rule-list.domain-list-name': ['name', 'name-domain-list'],
  'cgnv6.lsn-rule-list.domain-name': ['name', 'name-domain'],
  'cgnv6.lsn-rule-list.ip': ['name'],
  'cgnv6.lsn-rule-list': ['name'],
  'cgnv6.lw-4o6.binding-table': ['name'],
  'cgnv6.lw-4o6.binding-table.tunnel-address.nat-address': ['name'],
  'cgnv6.lw-4o6.binding-table.tunnel-address.nat-address.port-range': [
    'name',
    '{port-start}+{port-end}',
  ],
  'cgnv6.lw-4o6.binding-table.tunnel-address': ['name'],
  'cgnv6.lw-4o6.binding-table-validate': null,
  'cgnv6.lw-4o6.fragmentation.inbound': null,
  'cgnv6.lw-4o6.fragmentation.outbound': null,
  'cgnv6.lw-4o6.global': null,
  'cgnv6.lw-4o6.health-check-gateway': null,
  'cgnv6.map.encapsulation.domain.basic-mapping-rule': ['name'],
  'cgnv6.map.encapsulation.domain.basic-mapping-rule.prefix-rule': [
    'name',
    'name',
  ],
  'cgnv6.map.encapsulation.domain': ['name'],
  'cgnv6.map.encapsulation.domain.health-check-gateway': ['name'],
  'cgnv6.map.encapsulation.fragmentation.inbound': null,
  'cgnv6.map.encapsulation.fragmentation.outbound': null,
  'cgnv6.map.translation.default-domain': null,
  'cgnv6.map.translation.domain.basic-mapping-rule': ['name'],
  'cgnv6.map.translation.domain.basic-mapping-rule.prefix-rule': [
    'name',
    'name',
  ],
  'cgnv6.map.translation.domain.default-mapping-rule': ['name'],
  'cgnv6.map.translation.domain': ['name'],
  'cgnv6.map.translation.domain.health-check-gateway': ['name'],
  'cgnv6.map.translation.fragmentation.inbound': null,
  'cgnv6.map.translation.fragmentation.outbound': null,
  'cgnv6.nat.exclude-port.tcp': null,
  'cgnv6.nat.exclude-port.udp': null,
  'cgnv6.nat.icmp': null,
  'cgnv6.nat.icmpv6': null,
  'cgnv6.nat.inside.source.static': ['{src-address}+{partition}'],
  // 'cgnv6.nat.inside.source.static': ['src-address'],
  'cgnv6.nat.pool': ['pool-name'],
  'cgnv6.nat.pool-group.member': ['pool-group-name', 'pool-name'],
  'cgnv6.nat.pool-group': ['pool-group-name'],
  'cgnv6.nat.range-list': ['{name}+{partition}'],
  // 'cgnv6.nat.range-list': ['name'],
  'cgnv6.nat46-stateless.fragmentation.inbound': null,
  'cgnv6.nat46-stateless.fragmentation.outbound': null,
  'cgnv6.nat46-stateless.partition-prefix': ['partition'],
  'cgnv6.nat46-stateless.prefix': null,
  'cgnv6.nat46-stateless.static-dest-mapping': ['{v4-address}+{v6-address}'],
  'cgnv6.nat64.alg.esp': null,
  'cgnv6.nat64.alg.ftp': null,
  'cgnv6.nat64.alg.h323': null,
  'cgnv6.nat64.alg.mgcp': null,
  'cgnv6.nat64.alg.pptp': null,
  'cgnv6.nat64.alg.rtsp': null,
  'cgnv6.nat64.alg.sip': null,
  'cgnv6.nat64.alg.tftp': null,
  'cgnv6.nat64.fragmentation.df-bit-transparency': null,
  'cgnv6.nat64.fragmentation.inbound': null,
  'cgnv6.nat64.fragmentation.outbound': null,
  'cgnv6.nat64.global': null,
  'cgnv6.nat64.prefix': ['prefix-val'],
  'cgnv6.nptv6.common': null,
  'cgnv6.nptv6.domain': ['name'],
  'cgnv6.one-to-one.global': null,
  'cgnv6.one-to-one.pool': ['pool-name'],
  'cgnv6.one-to-one.pool-group.member': ['pool-group-name', 'pool-name'],
  'cgnv6.one-to-one.pool-group': ['pool-group-name'],
  'cgnv6.pcp': null,
  'cgnv6.port-batch-v1': null,
  'cgnv6.port-list': ['name'],
  'cgnv6.resource-usage': null,
  'cgnv6.resource-usage.stateless-entries': null,
  'cgnv6.scaleout.nat-ip-hashing-scheme': null,
  'cgnv6.sctp.global': null,
  'cgnv6.sctp.permit-payload-protocol.protocol-id': ['id'],
  'cgnv6.sctp.permit-payload-protocol.protocol-name': ['protocol'],
  'cgnv6.sctp.rate-limit.destination': ['ip'],
  'cgnv6.sctp.rate-limit.source': ['ip'],
  'cgnv6.server.port': ['name', '{port-number}+{protocol}'],
  'cgnv6.server': ['name'],
  'cgnv6.service-group.member': ['name', '{name}+{port}'],
  'cgnv6.service-group': ['name'],
  'cgnv6.sixrd.domain': ['name'],
  'cgnv6.sixrd.fragmentation.inbound': null,
  'cgnv6.sixrd.fragmentation.outbound': null,
  'cgnv6.stateful-firewall.alg.ftp': null,
  'cgnv6.stateful-firewall.alg.pptp': null,
  'cgnv6.stateful-firewall.alg.rtp': null,
  'cgnv6.stateful-firewall.alg.rtsp': null,
  'cgnv6.stateful-firewall.alg.sip': null,
  'cgnv6.stateful-firewall.alg.tftp': null,
  'cgnv6.stateful-firewall.endpoint-independent-filtering.tcp': null,
  'cgnv6.stateful-firewall.endpoint-independent-filtering.udp': null,
  'cgnv6.stateful-firewall.global': null,
  'cgnv6.stateful-firewall.stun-timeout': ['{port}+{port-end}'],
  'cgnv6.stateful-firewall.tcp.idle-timeout': ['{port}+{port-end}'],
  'cgnv6.stateful-firewall.tcp.stun-timeout': ['{port}+{port-end}'],
  'cgnv6.stateful-firewall.tcp.syn-timeout': null,
  'cgnv6.stateful-firewall.udp.idle-timeout': ['{port}+{port-end}'],
  'cgnv6.stateful-firewall.udp.stun-timeout': ['{port}+{port-end}'],
  'cgnv6.stateful-firewall.vrid': null,
  'cgnv6.template.dns.class-list': ['name'],
  'cgnv6.template.dns.class-list.lid': ['name', 'lidnum'],
  'cgnv6.template.dns': ['name'],
  'cgnv6.template.dns.dns64': ['name'],
  'cgnv6.template.http-alg': ['name'],
  'cgnv6.template.logging.disable-log-by-destination': ['name'],
  'cgnv6.template.logging': ['name'],
  'cgnv6.template.logging.source-address': ['name'],
  'cgnv6.template.pcp': ['name'],
  'cgnv6.template.policy.class-list': ['name'],
  'cgnv6.template.policy.class-list.lid': ['name', 'lidnum'],
  'cgnv6.template.policy': ['name'],
  'cgnv6.translation.service-timeout': ['{service-type}+{port}'],
  'cgnv6.translation': null,
  'chassis-info': null,
  'chassis-infra': null,
  'class-list': ['name'],
  'classification.class': ['class-name'],
  'classification.ip-list.ip': ['listname'],
  'classification.ip-list': ['listname'],
  'classification.ip-list.ipv6': ['listname'],
  'classification.port-list.port': ['listname', 'start-port'],
  'classification.port-list': ['listname'],
  'clock.set': null,
  'cloud-services.meta-data': null,
  'cm-ut.encrypted-password': null,
  'cm-ut.list-entry-1': null,
  'cm-ut.list-entry-2': null,
  'cm-ut.list-enum-1': null,
  'cm-ut.obj-key-1': null,
  'cm-ut.obj-key-2': null,
  'cm-ut.post-modify-parent.post-modify-child': null,
  'cm-ut.post-modify-parent': null,
  'cm-ut.tc-array-field-01': null,
  'cm-ut.tc-display-always': null,
  'cm-ut.tc-flag-mute-01': null,
  'cm-ut.tc-reference-to': null,
  'cm-ut.tc-string-rlx': null,
  cmcov: null,
  'cmxmpp.chat-group': ['group-name'],
  cmxmpp: null,
  'configure.sync': null,
  copy: null,
  'counter.port-diameter': null,
  'ddos.dst-ip.default': null,
  'ddos.dst-ip.default.ip-proto': ['port-num'],
  'ddos.dst-ip.default.l4-type': ['protocol'],
  'ddos.dst-ip.default.port': ['{port-num}+{protocol}'],
  'ddos.dst-ip.host': ['ip-addr'],
  'ddos.dst-ip.host.ip-proto': ['ip-addr', 'port-num'],
  'ddos.dst-ip.host.l4-type': ['ip-addr', 'protocol'],
  'ddos.dst-ip.host.port': ['ip-addr', '{port-num}+{protocol}'],
  'ddos.dst-ip.host.src-dst-pair.app-type-src-dst': [
    'ip-addr',
    'src',
    'protocol',
  ],
  'ddos.dst-ip.host.src-dst-pair.l4-type-src-dst': [
    'ip-addr',
    'src',
    'protocol',
  ],
  'ddos.dst-ip.host.src-dst-pair': ['ip-addr', 'src'],
  'ddos.dst-ip.subnet.ip-proto': ['ip-addr', 'port-num'],
  'ddos.dst-ip.subnet.l4-type': ['ip-addr', 'protocol'],
  'ddos.dst-ip.subnet.port': ['ip-addr', '{port-num}+{protocol}'],
  'ddos.dst-ip.subnet.src-dst-pair.app-type-src-dst': [
    'ip-addr',
    'src',
    'protocol',
  ],
  'ddos.dst-ip.subnet.src-dst-pair.l4-type-src-dst': [
    'ip-addr',
    'src',
    'protocol',
  ],
  'ddos.dst-ip.subnet.src-dst-pair': ['ip-addr', 'src'],
  'ddos.dst-ip.subnet': ['ip-addr'],
  'ddos.dst-ipv6.default': null,
  'ddos.dst-ipv6.default.ip-proto': ['port-num'],
  'ddos.dst-ipv6.default.l4-type': ['protocol'],
  'ddos.dst-ipv6.default.port': ['{port-num}+{protocol}'],
  'ddos.dst-ipv6.host': null,
  'ddos.dst-ipv6.host.ip-proto': ['port-num'],
  'ddos.dst-ipv6.host.l4-type': ['protocol'],
  'ddos.dst-ipv6.host.port': ['{port-num}+{protocol}'],
  'ddos.dst-ipv6.host.src-dst-pair.app-type-src-dst': ['src', 'protocol'],
  'ddos.dst-ipv6.host.src-dst-pair.l4-type-src-dst': ['src', 'protocol'],
  'ddos.dst-ipv6.host.src-dst-pair': ['src'],
  'ddos.dst-ipv6.subnet.ip-proto': ['port-num'],
  'ddos.dst-ipv6.subnet.l4-type': ['protocol'],
  'ddos.dst-ipv6.subnet.port': ['{port-num}+{protocol}'],
  'ddos.dst-ipv6.subnet.src-dst-pair.app-type-src-dst': ['src', 'protocol'],
  'ddos.dst-ipv6.subnet.src-dst-pair.l4-type-src-dst': ['src', 'protocol'],
  'ddos.dst-ipv6.subnet.src-dst-pair': ['src'],
  'ddos.dst-ipv6.subnet': null,
  'ddos.geo-location.delete': null,
  'ddos.geo-location.load': ['load-file-name'],
  'ddos.ip': ['ip-addr'],
  'ddos.ipv6': null,
  'ddos.protection': null,
  'ddos.src-ip.class-list.app-type': ['clist-name', 'protocol'],
  'ddos.src-ip.class-list': ['clist-name'],
  'ddos.src-ip.class-list.l4-type': ['clist-name', 'protocol'],
  'ddos.src-ip.default.app-type': ['protocol'],
  'ddos.src-ip.default': null,
  'ddos.src-ip.default.l4-type': ['protocol'],
  'ddos.src-ip.geo-location.app-type': ['geolocation-name', 'protocol'],
  'ddos.src-ip.geo-location': ['geolocation-name'],
  'ddos.src-ip.geo-location.l4-type': ['geolocation-name', 'protocol'],
  'ddos.src-ip.host.app-type': ['ip-addr', 'protocol'],
  'ddos.src-ip.host': ['ip-addr'],
  'ddos.src-ip.host.l4-type': ['ip-addr', 'protocol'],
  'ddos.src-ip.subnet.app-type': ['ip-addr', 'protocol'],
  'ddos.src-ip.subnet.l4-type': ['ip-addr', 'protocol'],
  'ddos.src-ip.subnet': ['ip-addr'],
  'ddos.src-ipv6.class-list.app-type': ['clist-name', 'protocol'],
  'ddos.src-ipv6.class-list': ['clist-name'],
  'ddos.src-ipv6.class-list.l4-type': ['clist-name', 'protocol'],
  'ddos.src-ipv6.default.app-type': ['protocol'],
  'ddos.src-ipv6.default': null,
  'ddos.src-ipv6.default.l4-type': ['protocol'],
  'ddos.src-ipv6.host.app-type': ['protocol'],
  'ddos.src-ipv6.host': null,
  'ddos.src-ipv6.host.l4-type': ['protocol'],
  'ddos.src-ipv6.subnet.app-type': ['protocol'],
  'ddos.src-ipv6.subnet.l4-type': ['protocol'],
  'ddos.src-ipv6.subnet': null,
  'ddos.sync.peer-ip': ['peer-ip'],
  'ddos.sync': null,
  'ddos.tap': null,
  'ddos.template.dns': ['name'],
  'ddos.template.http': ['http-tmpl-name'],
  'ddos.template.ssl-l4': null,
  'ddos.template.tcp': ['name'],
  'ddos.template.udp': ['name'],
  'ddos.use-default-route': null,
  'debug.a10support': null,
  'debug.aflex': null,
  'debug.aflow': null,
  'debug.all': null,
  'debug.appcls': null,
  'debug.auth': null,
  'debug.backtrace': null,
  'debug.bgp': null,
  'debug.bridge-vlan-group': null,
  'debug.cache': null,
  'debug.cfgmgr': null,
  'debug.cgn': null,
  'debug.cli': null,
  'debug.conn-reuse': null,
  'debug.cpu': null,
  'debug.diameter': null,
  'debug.dns-cache': null,
  'debug.drop': null,
  'debug.dumpthread': null,
  'debug.es': null,
  'debug.fix': null,
  'debug.ftp': null,
  'debug.ftp-proxy': null,
  'debug.fw': null,
  'debug.generic-proxy': null,
  'debug.gslb': null,
  'debug.h323': null,
  'debug.ha': null,
  'debug.hc': null,
  'debug.hm': null,
  'debug.http-proxy': null,
  'debug.http2': null,
  'debug.hw-compression': null,
  'debug.imap-proxy': null,
  'debug.imish': null,
  'debug.imish-profile': null,
  'debug.ip': null,
  'debug.ipv6.ospf.all': null,
  'debug.ipv6.ospf.bfd': null,
  'debug.ipv6.ospf.events': null,
  'debug.ipv6.ospf.ifsm': null,
  'debug.ipv6.ospf.lsa': null,
  'debug.ipv6.ospf.nfsm': null,
  'debug.ipv6.ospf.nsm': null,
  'debug.ipv6.ospf.packet': null,
  'debug.ipv6.ospf.route': null,
  'debug.ipv6.rip': null,
  'debug.isis': null,
  'debug.l2-redirect': null,
  'debug.l4': null,
  'debug.l7session': null,
  'debug.lacp': null,
  'debug.layer2': null,
  'debug.lb': null,
  'debug.list_all_cli': null,
  'debug.local-log': null,
  'debug.logging': null,
  'debug.lsn': null,
  'debug.management': null,
  'debug.mgcp': null,
  'debug.migration': null,
  'debug.mlb': null,
  'debug.monitor': null,
  'debug.msg-proxy': null,
  'debug.ospf.all': null,
  'debug.ospf.bfd': null,
  'debug.ospf.events': null,
  'debug.ospf.ifsm': null,
  'debug.ospf.lsa': null,
  'debug.ospf.nfsm': null,
  'debug.ospf.nsm': null,
  'debug.ospf.packet': null,
  'debug.ospf.route': null,
  'debug.packet': null,
  'debug.polltech-enable': null,
  'debug.pop3-proxy': null,
  'debug.rip': null,
  'debug.rt': null,
  'debug.scaleout': null,
  'debug.sctp': null,
  'debug.sess': null,
  'debug.sip': null,
  'debug.smpp': null,
  'debug.smtp': null,
  'debug.snmp': null,
  'debug.ssl': null,
  'debug.ssli': null,
  'debug.system': null,
  'debug.tcp': null,
  'debug.threat-intel': null,
  'debug.tunnel': null,
  'debug.vector': null,
  'debug.visibility': null,
  'debug.vpn': null,
  'debug.vtep-error': null,
  'debug.vtep-event': null,
  'debug.vtep-packet': null,
  'debug.waf': null,
  'debug.web-category': null,
  'delete.auth-jwks': null,
  'delete.auth-portal': null,
  'delete.auth-portal-image': null,
  'delete.auth-saml-idp': null,
  'delete.bw-list': null,
  'delete.cgnv6': null,
  'delete.debug-monitor': null,
  'delete.geo-location': null,
  'delete.glm-license': null,
  'delete.guest-file': null,
  'delete.health-external': null,
  'delete.health-postfile': null,
  'delete.local-uri-file': null,
  'delete.partition': null,
  'delete.service-partition': null,
  'delete.startup-config': null,
  'delete.threat-intel': null,
  'delete.web-category': null,
  'device-context': null,
  'disable-management.service.http': null,
  'disable-management.service.https': null,
  'disable-management.service.ping': null,
  'disable-management.service.snmp': null,
  'disable-management.service.ssh': null,
  'dnssec.dnskey': null,
  dnssec: null,
  'dnssec.ds': null,
  'dnssec.key-rollover': ['dnssec-key-type'],
  'dnssec.sign-zone-now': null,
  'dnssec.template': ['dnssec-temp-name'],
  'domain-list': ['name'],
  'enable-core': null,
  'enable-management.service.acl-v4': ['acl-id'],
  'enable-management.service.acl-v6': ['acl-name'],
  'enable-management.service.http.acl-v4': ['acl-id'],
  'enable-management.service.http.acl-v6': ['acl-name'],
  'enable-management.service.http': null,
  'enable-management.service.https.acl-v4': ['acl-id'],
  'enable-management.service.https.acl-v6': ['acl-name'],
  'enable-management.service.https': null,
  'enable-management.service.ping.acl-v4': ['acl-id'],
  'enable-management.service.ping.acl-v6': ['acl-name'],
  'enable-management.service.ping': null,
  'enable-management.service.snmp.acl-v4': ['acl-id'],
  'enable-management.service.snmp.acl-v6': ['acl-name'],
  'enable-management.service.snmp': null,
  'enable-management.service.ssh.acl-v4': ['acl-id'],
  'enable-management.service.ssh.acl-v6': ['acl-name'],
  'enable-management.service.ssh': null,
  'enable-management.service.telnet.acl-v4': ['acl-id'],
  'enable-management.service.telnet.acl-v6': ['acl-name'],
  'enable-management.service.telnet': null,
  'enable-password': null,
  environment: null,
  erase: null,
  'event.partition': ['vnp-events'],
  'event-notification.kafka.server': null,
  export: null,
  'export.store': null,
  'fail-safe.disable-failsafe': null,
  'fail-safe': null,
  'fan-speed': null,
  'file.aflex': null,
  'file.auth-jwks': null,
  'file.auth-portal': null,
  'file.auth-portal-image': null,
  'file.auth-saml-idp': null,
  'file.aws-accesskey': null,
  'file.axdebug': null,
  'file.bw-list': null,
  'file.ca-cert': null,
  'file.class-list': null,
  'file.csr': null,
  'file.debug-monitor': null,
  'file.dnssec-dnskey': null,
  'file.dnssec-ds': null,
  'file.file-inspection-bw-list': null,
  file: null,
  'file.geo-location': null,
  'file.glm-cert': null,
  'file.glm-license': null,
  'file.hc-pt-mapping': null,
  'file.health-external': null,
  'file.health-postfile': null,
  'file.license': null,
  'file.local-uri-file': null,
  'file.log-backup': null,
  'file.policy': null,
  'file.ssh-pubkey': null,
  'file.ssl-cert': null,
  'file.ssl-cert-key': null,
  'file.ssl-cert-poc': null,
  'file.ssl-crl': null,
  'file.ssl-key': null,
  'file.startup-config': null,
  'file.syslog': null,
  'file.system-backup': null,
  'file.techsupport': null,
  'file.template': null,
  'file.web-category-license': null,
  'file.web-service-cert-key': null,
  'file.wsdl': null,
  'file.xml-schema': null,
  'file-inspection.data-file': null,
  'file-inspection.global': null,
  'file-inspection.global-stat': null,
  'file-inspection.service': null,
  'file-inspection.template': ['name'],
  'fw.active-rule-set': null,
  'fw.alg': null,
  'fw.alg.dns': null,
  'fw.alg.ftp': null,
  'fw.alg.icmp': null,
  'fw.alg.pptp': null,
  'fw.alg.rtsp': null,
  'fw.alg.sip': null,
  'fw.alg.tftp': null,
  'fw.apply-changes': null,
  'fw.clear-session-filter': null,
  fw: null,
  'fw.global': null,
  'fw.gtp': null,
  'fw.gtp-in-gtp-filtering': null,
  'fw.gtp-v0': null,
  'fw.helper-sessions': null,
  'fw.local-log': null,
  'fw.logging': null,
  'fw.radius.server': null,
  'fw.server.port': ['name', '{port-number}+{protocol}'],
  'fw.server': ['name'],
  'fw.service-group.member': ['name', '{name}+{port}'],
  'fw.service-group': ['name'],
  'fw.session-aging': ['name'],
  'fw.session-aging.tcp': ['name'],
  'fw.session-aging.udp': ['name'],
  'fw.tap-monitor': null,
  'fw.tcp.mss-clamp': null,
  'fw.tcp.reset-on-error': null,
  'fw.tcp-rst-close-immediate': null,
  'fw.tcp-window-check': null,
  'fw.template.logging': ['name'],
  'fw.template.logging.source-address': ['name'],
  'fw.urpf': null,
  'fw.vrid': null,
  glid: ['num'],
  glm: null,
  'glm.proxy-server': null,
  'glm.send': null,
  'gslb.active-rdt': null,
  'gslb.dns': null,
  'gslb.geo-location': ['geo-locn-obj-name'],
  'gslb.group': ['name'],
  'gslb.ip-list': ['gslb-ip-list-obj-name'],
  'gslb.policy.active-rdt': ['name'],
  'gslb.policy.auto-map': ['name'],
  'gslb.policy.capacity': ['name'],
  'gslb.policy.connection-load': ['name'],
  'gslb.policy.dns': ['name'],
  'gslb.policy.edns': ['name'],
  'gslb.policy.geo-location': ['name', 'name'],
  'gslb.policy.geo-location-match': ['name'],
  'gslb.policy': ['name'],
  'gslb.protocol.enable': ['type'],
  'gslb.protocol.limit': null,
  'gslb.protocol': null,
  'gslb.service-group': ['service-group-name'],
  'gslb.service-ip.port': ['node-name', '{port-num}+{port-proto}'],
  'gslb.service-ip': ['node-name'],
  'gslb.site.active-rdt': ['site-name'],
  'gslb.site.easy-rdt': ['site-name'],
  'gslb.site.ip-server': ['site-name', 'ip-server-name'],
  'gslb.site': ['site-name'],
  'gslb.site.slb-dev': ['site-name', 'device-name'],
  'gslb.site.slb-dev.vip-server.vip-server-name': [
    'site-name',
    'device-name',
    'vip-name',
  ],
  'gslb.site.slb-dev.vip-server.vip-server-v4': ['site-name', 'device-name'],
  'gslb.site.slb-dev.vip-server.vip-server-v6': ['site-name', 'device-name'],
  'gslb.system': null,
  'gslb.template.csv': ['csv-name'],
  'gslb.template.snmp': ['snmp-name'],
  'gslb.zone.dns-mx-record': ['name', 'mx-name'],
  'gslb.zone.dns-ns-record': ['name', 'ns-name'],
  'gslb.zone.service.dns-a-record.dns-a-record-ipv4': [
    'name',
    '{service-port}+{service-name}',
    'dns-a-record-ip',
  ],
  'gslb.zone.service.dns-a-record.dns-a-record-ipv6': [
    'name',
    '{service-port}+{service-name}',
  ],
  'gslb.zone.service.dns-a-record.dns-a-record-srv': [
    'name',
    '{service-port}+{service-name}',
    'svrname',
  ],
  'gslb.zone.service.dns-cname-record': [
    'name',
    '{service-port}+{service-name}',
    'alias-name',
  ],
  'gslb.zone.service.dns-mx-record': [
    'name',
    '{service-port}+{service-name}',
    'mx-name',
  ],
  'gslb.zone.service.dns-naptr-record': [
    'name',
    '{service-port}+{service-name}',
    '{naptr-target}+{service-proto}+{flag}',
  ],
  'gslb.zone.service.dns-ns-record': [
    'name',
    '{service-port}+{service-name}',
    'ns-name',
  ],
  'gslb.zone.service.dns-ptr-record': [
    'name',
    '{service-port}+{service-name}',
    'ptr-name',
  ],
  'gslb.zone.service.dns-record': [
    'name',
    '{service-port}+{service-name}',
    'type',
  ],
  'gslb.zone.service.dns-srv-record': [
    'name',
    '{service-port}+{service-name}',
    '{srv-name}+{port}',
  ],
  'gslb.zone.service.dns-txt-record': [
    'name',
    '{service-port}+{service-name}',
    'record-name',
  ],
  'gslb.zone.service.geo-location': [
    'name',
    '{service-port}+{service-name}',
    'geo-name',
  ],
  'gslb.zone.service': ['name', '{service-port}+{service-name}'],
  'gslb.zone': ['name'],
  'harmony-controller.profile': null,
  'harmony-controller.profile.thunder-mgmt-ip': null,
  'harmony-controller.telemetry': null,
  'hd-monitor': null,
  'health.external.copy': null,
  'health.external.create': null,
  'health.external.delete': null,
  'health.external.edit': null,
  'health.external.rename': null,
  'health.global': null,
  'health.monitor.method.compound': ['name'],
  'health.monitor.method.database': ['name'],
  'health.monitor.method.dns': ['name'],
  'health.monitor.method.external': ['name'],
  'health.monitor.method.ftp': ['name'],
  'health.monitor.method.http': ['name'],
  'health.monitor.method.https': ['name'],
  'health.monitor.method.icmp': ['name'],
  'health.monitor.method.imap': ['name'],
  'health.monitor.method.kerberos-kdc': ['name'],
  'health.monitor.method.ldap': ['name'],
  'health.monitor.method.ntp': ['name'],
  'health.monitor.method.pop3': ['name'],
  'health.monitor.method.radius': ['name'],
  'health.monitor.method.rtsp': ['name'],
  'health.monitor.method.sip': ['name'],
  'health.monitor.method.smtp': ['name'],
  'health.monitor.method.snmp': ['name'],
  'health.monitor.method.tacplus': ['name'],
  'health.monitor.method.tcp': ['name'],
  'health.monitor.method.udp': ['name'],
  'health.monitor': ['name'],
  hostname: null,
  hsm: null,
  'hsm.template': ['template-name'],
  'import.auth-saml-idp': null,
  'import.health-external': null,
  'import.health-postfile': null,
  import: null,
  'import.store': null,
  'import.to-device': null,
  'import-periodic.aflex': ['aflex'],
  'import-periodic.auth-jwks': ['auth-jwks'],
  'import-periodic.auth-portal': ['auth-portal'],
  'import-periodic.bw-list': ['bw-list'],
  'import-periodic.ca-cert': ['ca-cert'],
  'import-periodic.class-list': ['class-list'],
  'import-periodic.class-list-convert': ['class-list-convert'],
  'import-periodic.dnssec-dnskey': ['dnssec-dnskey'],
  'import-periodic.dnssec-ds': ['dnssec-ds'],
  'import-periodic.file-inspection-bw-list': ['file-inspection-bw-list'],
  'import-periodic.geo-location': ['geo-location'],
  'import-periodic.local-uri-file': ['local-uri-file'],
  'import-periodic.policy': ['policy'],
  'import-periodic.ssl-cert': ['ssl-cert'],
  'import-periodic.ssl-cert-key': ['ssl-cert-key'],
  'import-periodic.ssl-crl': ['ssl-crl'],
  'import-periodic.ssl-key': ['ssl-key'],
  'import-periodic.thales-kmdata': ['thales-kmdata'],
  'import-periodic.thales-secworld': ['thales-secworld'],
  'import-periodic.wsdl': ['wsdl'],
  'import-periodic.xml-schema': ['xml-schema'],
  'interface.ethernet.bfd': ['ifnum'],
  'interface.ethernet.ddos': ['ifnum'],
  'interface.ethernet.dhcpv6.map-t': ['ifnum'],
  'interface.ethernet': ['ifnum'],
  'interface.ethernet.ip': ['ifnum'],
  'interface.ethernet.ip.ospf.ospf-global': ['ifnum'],
  'interface.ethernet.ip.ospf.ospf-ip': ['ifnum', 'ip-addr'],
  'interface.ethernet.ip.rip': ['ifnum'],
  'interface.ethernet.ip.router.isis': ['ifnum'],
  'interface.ethernet.ip.stateful-firewall': ['ifnum'],
  'interface.ethernet.ipv6': ['ifnum'],
  'interface.ethernet.ipv6.ospf': ['ifnum'],
  'interface.ethernet.ipv6.rip': ['ifnum'],
  'interface.ethernet.ipv6.router.isis': ['ifnum'],
  'interface.ethernet.ipv6.router.ospf': ['ifnum'],
  'interface.ethernet.ipv6.router.ripng': ['ifnum'],
  'interface.ethernet.ipv6.stateful-firewall': ['ifnum'],
  'interface.ethernet.isis': ['ifnum'],
  'interface.ethernet.lldp': ['ifnum'],
  'interface.ethernet.lw-4o6': ['ifnum'],
  'interface.ethernet.map': ['ifnum'],
  'interface.ethernet.nptv6.domain': ['ifnum', '{domain-name}+{bind-type}'],
  'interface.ethernet.trunk-group': ['ifnum', 'trunk-number'],
  'interface.trunk-group-new': ['trunk-number'],
  interface: null,
  'interface.lif.bfd': ['ifnum'],
  'interface.lif.ip': ['ifnum'],
  'interface.lif.ip.ospf.ospf-global': ['ifnum'],
  'interface.lif.ip.ospf.ospf-ip': ['ifnum', 'ip-addr'],
  'interface.lif.ip.rip': ['ifnum'],
  'interface.lif.ip.router.isis': ['ifnum'],
  'interface.lif.isis': ['ifnum'],
  'interface.lif': ['ifnum'],
  'interface.loopback.ip': ['ifnum'],
  'interface.loopback.ip.ospf.ospf-global': ['ifnum'],
  'interface.loopback.ip.ospf.ospf-ip': ['ifnum', 'ip-addr'],
  'interface.loopback.ip.rip': ['ifnum'],
  'interface.loopback.ip.router.isis': ['ifnum'],
  'interface.loopback.ipv6': ['ifnum'],
  'interface.loopback.ipv6.ospf': ['ifnum'],
  'interface.loopback.ipv6.rip': ['ifnum'],
  'interface.loopback.ipv6.router.isis': ['ifnum'],
  'interface.loopback.ipv6.router.ospf': ['ifnum'],
  'interface.loopback.ipv6.router.ripng': ['ifnum'],
  'interface.loopback.isis': ['ifnum'],
  'interface.loopback': ['ifnum'],
  'interface.management.lldp': null,
  'interface.management': null,
  'interface.trunk.bfd': ['ifnum'],
  'interface.trunk.ddos': ['ifnum'],
  'interface.trunk.dhcpv6.map-t': ['ifnum'],
  'interface.trunk.ip': ['ifnum'],
  'interface.trunk.ip.ospf.ospf-global': ['ifnum'],
  'interface.trunk.ip.ospf.ospf-ip': ['ifnum', 'ip-addr'],
  'interface.trunk.ip.rip': ['ifnum'],
  'interface.trunk.ip.router.isis': ['ifnum'],
  'interface.trunk.ip.stateful-firewall': ['ifnum'],
  'interface.trunk.ipv6': ['ifnum'],
  'interface.trunk.ipv6.ospf': ['ifnum'],
  'interface.trunk.ipv6.rip': ['ifnum'],
  'interface.trunk.ipv6.router.isis': ['ifnum'],
  'interface.trunk.ipv6.router.ospf': ['ifnum'],
  'interface.trunk.ipv6.router.ripng': ['ifnum'],
  'interface.trunk.ipv6.stateful-firewall': ['ifnum'],
  'interface.trunk.isis': ['ifnum'],
  'interface.trunk.lw-4o6': ['ifnum'],
  'interface.trunk.map': ['ifnum'],
  'interface.trunk.nptv6.domain': ['ifnum', '{domain-name}+{bind-type}'],
  'interface.trunk': ['ifnum'],
  'interface.tunnel.ip': ['ifnum'],
  'interface.tunnel.ip.ospf.ospf-global': ['ifnum'],
  'interface.tunnel.ip.ospf.ospf-ip': ['ifnum', 'ip-addr'],
  'interface.tunnel.ip.rip': ['ifnum'],
  'interface.tunnel.ipv6': ['ifnum'],
  'interface.tunnel.ipv6.ospf': ['ifnum'],
  'interface.tunnel.ipv6.router.ospf': ['ifnum'],
  'interface.tunnel.ipv6.router.ripng': ['ifnum'],
  'interface.tunnel': ['ifnum'],
  'interface.ve.bfd': ['ifnum'],
  'interface.ve.ddos': ['ifnum'],
  'interface.ve.dhcpv6.map-t': ['ifnum'],
  'interface.ve.ip': ['ifnum'],
  'interface.ve.ip.ospf.ospf-global': ['ifnum'],
  'interface.ve.ip.ospf.ospf-ip': ['ifnum', 'ip-addr'],
  'interface.ve.ip.rip': ['ifnum'],
  'interface.ve.ip.router.isis': ['ifnum'],
  'interface.ve.ip.stateful-firewall': ['ifnum'],
  'interface.ve.ipv6': ['ifnum'],
  'interface.ve.ipv6.ospf': ['ifnum'],
  'interface.ve.ipv6.rip': ['ifnum'],
  'interface.ve.ipv6.router.isis': ['ifnum'],
  'interface.ve.ipv6.router.ospf': ['ifnum'],
  'interface.ve.ipv6.router.ripng': ['ifnum'],
  'interface.ve.ipv6.stateful-firewall': ['ifnum'],
  'interface.ve.isis': ['ifnum'],
  'interface.ve.lw-4o6': ['ifnum'],
  'interface.ve.map': ['ifnum'],
  'interface.ve.nptv6.domain': ['ifnum', '{domain-name}+{bind-type}'],
  'interface.ve': ['ifnum'],
  'ip.access-list': ['name'],
  'ip.address': ['{ip-addr}+{ip-mask}'],
  'ip.anomaly-drop': null,
  'ip.as-path': ['{access-list}+{action}+{value}'],
  'ip.community-list.expanded': ['expanded'],
  'ip.community-list.expanded-num': ['ext-list-num'],
  'ip.community-list.standard': ['standard'],
  'ip.community-list.standard-num': ['std-list-num'],
  'ip.default-gateway': null,
  'ip.dns.primary': null,
  'ip.dns.secondary': null,
  'ip.dns.suffix': null,
  'ip.extcommunity-list.expanded': ['expanded'],
  'ip.extcommunity-list.expanded-num': ['ext-list-num'],
  'ip.extcommunity-list.standard': ['standard'],
  'ip.extcommunity-list.standard-num': ['std-list-num'],
  'ip.flow.agent-list': ['name'],
  'ip.flow.agent-list.agent-v4': ['name'],
  'ip.flow.agent-list.agent-v6': ['name'],
  'ip.frag': null,
  'ip.icmp': null,
  'ip.map-list': ['name'],
  'ip.mgmt-traffic': ['traffic-type'],
  'ip.nat.alg.pptp': null,
  'ip.nat.icmp': null,
  'ip.nat.inside.source.class-list': null,
  'ip.nat.inside.source.list.acl-id-list': ['acl-id'],
  'ip.nat.inside.source.list.acl-name-list': ['name'],
  'ip.nat.inside.source.static': ['{src-address}+{nat-address}'],
  'ip.nat.pool': ['pool-name'],
  'ip.nat.pool-group.member-list': ['pool-group-name'],
  'ip.nat.pool-group': ['pool-group-name'],
  'ip.nat.range-list': ['name'],
  'ip.nat.template.logging': ['name'],
  'ip.nat.translation.service-timeout': ['{service-type}+{port}'],
  'ip.nat.translation': null,
  'ip.nat-global': null,
  'ip.prefix-list': ['name'],
  'ip.reroute': null,
  'ip.reroute.suppress-protocols': null,
  'ip.route.rib': ['{ip-dest-addr}+{ip-mask}'],
  'ip.route.static.bfd': ['{local-ip}+{nexthop-ip}'],
  'ip.tcp': null,
  'ip-list': ['name'],
  'ipv6.access-list': ['name'],
  'ipv6.address': null,
  'ipv6.default-gateway': null,
  'ipv6.frag': null,
  'ipv6.icmpv6': null,
  ipv6: null,
  'ipv6.nat.icmpv6': null,
  'ipv6.nat.inside.source.list': ['list-name'],
  'ipv6.nat.pool': ['pool-name'],
  'ipv6.nat.pool-group.member-list': ['pool-group-name'],
  'ipv6.nat.pool-group': ['pool-group-name'],
  'ipv6.neighbor': null,
  'ipv6.neighbor.static': ['{ipv6-addr}+{vlan}'],
  'ipv6.ospf': null,
  'ipv6.prefix-list': ['name'],
  'ipv6.reroute': null,
  'ipv6.reroute.suppress-protocols': null,
  'ipv6.route.rib': ['ipv6-address'],
  'ipv6.route.static.bfd.bfd-ipv6': ['{local-ipv6}+{nexthop-ipv6}'],
  'ipv6.route.static.bfd.ethernet': ['{eth-num}+{nexthop-ipv6-ll}'],
  'ipv6.route.static.bfd.trunk': ['{trunk-num}+{nexthop-ipv6-ll}'],
  'ipv6.route.static.bfd.ve': ['{ve-num}+{nexthop-ipv6-ll}'],
  'key.key': ['{key-chain-flag}+{key-chain-name}', 'key-number'],
  key: ['{key-chain-flag}+{key-chain-name}'],
  'ldap-server.host.ipv4': ['ipv4-addr'],
  'ldap-server.host.ipv6': ['ipv6-addr'],
  'ldap-server.host.ldap-hostname': ['hostname'],
  'ldap-server': null,
  'license-manager.connect': null,
  'license-manager.host': null,
  'license-manager': null,
  'license-manager.overage': null,
  'license-manager.reminder': ['reminder-value'],
  'link.startup-config': null,
  locale: null,
  'locale.test': null,
  'logging.auditlog': null,
  'logging.buffered': null,
  'logging.console': null,
  'logging.disable-partition-name': null,
  'logging.email.buffer': null,
  'logging.email.filter': ['filter-id'],
  'logging.email.level': null,
  'logging.email-address': null,
  'logging.export': null,
  'logging.facility': null,
  'logging.host.ipv4addr-list': null,
  'logging.host.ipv6addr-list': null,
  'logging.host.partition': null,
  'logging.lsn.log-suppression': null,
  'logging.lsn.quota-exceeded': null,
  'logging.monitor': null,
  'logging.single-priority-list': ['levelname'],
  'logging.syslog': null,
  'logging.trap': null,
  'maximum-paths': null,
  'merge-mode-add.slb': null,
  'mirror-port': ['mirror-index'],
  monitor: null,
  'multi-config': null,
  'multi-ctrl-cpu': null,
  'netflow.common': null,
  'netflow.monitor.custom-record': ['name'],
  'netflow.monitor.destination': ['name'],
  'netflow.monitor.disable-log-by-destination': ['name'],
  'netflow.monitor': ['name'],
  'netflow.monitor.record': ['name'],
  'netflow.monitor.resend-template': ['name'],
  'netflow.monitor.sample.ethernet-list': ['name', 'ifindex'],
  'netflow.monitor.sample.nat-pool-list': ['name', 'pool-name'],
  'netflow.monitor.sample.ve-list': ['name', 've-num'],
  'netflow.monitor.source-address': ['name'],
  'netflow.template': ['name'],
  'network.arp': null,
  'network.arp.static': ['{ip-addr}+{vlan}'],
  'network.arp-timeout': null,
  'network.bfd': null,
  'network.bpdu-fwd-group': ['bpdu-fwd-group-number'],
  'network.bridge-vlan-group': ['bridge-vlan-group-number'],
  'network.icmp-rate-limit': null,
  'network.icmpv6-rate-limit': null,
  'network.lacp': null,
  'network.lacp-passthrough': ['{peer-from}+{peer-to}'],
  'network.lldp': null,
  'network.lldp.management-address.dns': ['dns'],
  'network.lldp.management-address.ipv4-addr': null,
  'network.lldp.management-address.ipv6-addr': null,
  'network.mac-address.static': ['{mac}+{vlan}'],
  'network.mac-age-time': null,
  'network.trunk': null,
  'network.ve-stats': null,
  'network.vlan': ['vlan-num'],
  'network.vlan-global': null,
  'ntp.auth-key': ['key'],
  'ntp.ntp-global': null,
  ntp: null,
  'ntp.server.hostname': ['host-servername'],
  'ntp.trusted-key': ['key'],
  'object.network': ['net-name'],
  'object-group.application': ['app-name'],
  'object-group.network': ['net-name'],
  'object-group.service': ['svc-name'],
  'overlay-mgmt-info': ['appstring'],
  'overlay-tunnel.options': null,
  'overlay-tunnel.vtep.destination-ip-address': ['id', 'ip-address'],
  'overlay-tunnel.vtep.destination-ip-address.vni': [
    'id',
    'ip-address',
    'segment',
  ],
  'overlay-tunnel.vtep.host': [
    'id',
    '{ip-addr}+{overlay-mac-addr}+{vni}+{destination-vtep}',
  ],
  'overlay-tunnel.vtep.source-ip-address': ['id'],
  'overlay-tunnel.vtep.source-ip-address.vni': ['id', 'segment'],
  'overlay-tunnel.vtep': ['id'],
  partition: ['partition-name'],
  'partition.shared-vlan': ['partition-name'],
  'partition.template': ['partition-name'],
  'partition-group': ['partition-group-name'],
  'pki.copy-cert': null,
  'pki.copy-key': null,
  'pki.create-oper': null,
  'pki.delete': null,
  'pki.delete-oper': null,
  pki: null,
  'pki.scep-cert': ['name'],
  'pki.ssli.generate.crl': null,
  'pki.ssli.revoke': null,
  'plat-buff-stats': null,
  poap: null,
  'qos.bandwidth': null,
  'qos.interface': ['name'],
  'qos.policy.class': ['name', 'name'],
  'qos.policy': ['name'],
  'radius-server.host.ipv4': ['ipv4-addr'],
  'radius-server.host.ipv6': ['ipv6-addr'],
  'radius-server.host.name': ['hostname'],
  'radius-server': null,
  'rate-limit': null,
  'rba.group': ['name'],
  'rba.group.partition': ['name', 'partition-name'],
  rba: null,
  'rba.role': ['name'],
  'rba.user.partition': ['name', 'partition-name'],
  'rba.user': ['name'],
  reboot: null,
  reload: null,
  'remove-upgrade-lock': null,
  rename: null,
  'report.debug': null,
  'resource-track': ['name'],
  restore: null,
  'route-map.match': ['{tag}+{action}+{sequence}'],
  'route-map': ['{tag}+{action}+{sequence}'],
  'route-map.set': ['{tag}+{action}+{sequence}'],
  'router.bgp.address-family.ipv6': ['as-number'],
  'router.bgp.address-family.ipv6.neighbor.ipv4-neighbor': ['as-number'],
  'router.bgp.address-family.ipv6.neighbor.ipv6-neighbor': ['as-number'],
  'router.bgp.address-family.ipv6.neighbor.peer-group-neighbor': [
    'as-number',
    'peer-group',
  ],
  'router.bgp.address-family.ipv6.network.ipv6-network': ['as-number'],
  'router.bgp.address-family.ipv6.network.synchronization': ['as-number'],
  'router.bgp.address-family.ipv6.redistribute': ['as-number'],
  'router.bgp': ['as-number'],
  'router.bgp.neighbor.ipv4-neighbor': ['as-number'],
  'router.bgp.neighbor.ipv6-neighbor': ['as-number'],
  'router.bgp.neighbor.peer-group-neighbor': ['as-number', 'peer-group'],
  'router.bgp.network.ip-cidr': ['as-number'],
  'router.bgp.network.synchronization': ['as-number'],
  'router.bgp.redistribute': ['as-number'],
  'router.ipv6.ospf.area': ['process-id', 'area-num'],
  'router.ipv6.ospf.default-information': ['process-id'],
  'router.ipv6.ospf': ['process-id'],
  'router.ipv6.ospf.redistribute': ['process-id'],
  'router.ipv6.rip.distribute-list': null,
  'router.ipv6.rip.distribute-list.prefix': null,
  'router.ipv6.rip.offset-list': null,
  'router.ipv6.rip.redistribute': null,
  'router.ipv6.rip': null,
  'router.ipv6.rip.route-map': null,
  'router.isis.address-family.ipv6': ['tag'],
  'router.isis.address-family.ipv6.redistribute': ['tag'],
  'router.isis': ['tag'],
  'router.isis.redistribute': ['tag'],
  'router.lacp': null,
  'router.log.file': null,
  'router.log': null,
  'router.ospf.area': ['process-id', 'area-num'],
  'router.ospf.default-information': ['process-id'],
  'router.ospf': ['process-id'],
  'router.ospf.redistribute': ['process-id'],
  'router.rip.distribute-list': null,
  'router.rip.distribute-list.prefix': null,
  'router.rip.offset-list': null,
  'router.rip.redistribute': null,
  'router.rip': null,
  router: null,
  'rrd.slb-server': null,
  'rule-set.rule.move-rule': ['name', 'name'],
  'rule-set.rule': ['name', 'name'],
  'rule-set': ['name'],
  'running-config': null,
  'scaleout.apps': null,
  'scaleout.cluster.cluster-devices': ['cluster-id'],
  'scaleout.cluster.cluster-devices.cluster-discovery-timeout': ['cluster-id'],
  'scaleout.cluster.cluster-devices.device-id': ['cluster-id', 'device-id'],
  'scaleout.cluster': ['cluster-id'],
  'scaleout.cluster.device-groups.device-group': ['cluster-id', 'device-group'],
  'scaleout.cluster.device-groups': ['cluster-id'],
  'scaleout.cluster.local-device.l2-redirect': ['cluster-id'],
  'scaleout.cluster.local-device': ['cluster-id'],
  'scaleout.cluster.local-device.session-sync-interface': ['cluster-id'],
  'scaleout.cluster.service-config': ['cluster-id'],
  'scaleout.cluster.service-config.template': ['cluster-id', 'name'],
  'scaleout.cluster.tracking-template.template': ['cluster-id', 'template'],
  'scaleout-cgn': null,
  'service-partition': ['partition-name'],
  'session-filter': ['name'],
  'set-product-id': null,
  'sflow.agent.address': null,
  'sflow.collector.ip': ['{addr}+{port}'],
  'sflow.collector.ipv6': ['{addr}+{port}'],
  'sflow.polling': null,
  'sflow.sampling': null,
  'sflow.setting': null,
  'sflow.source-address': null,
  shutdown: null,
  'single-board-mode': null,
  'slb.common': null,
  'slb.common.conn-rate-limit.src-ip': ['protocol'],
  'slb.common.dns-response-rate-limiting': null,
  'slb.ftp-proxy': null,
  'slb.generic-proxy': null,
  'slb.http-proxy': null,
  'slb.hw-compress': null,
  'slb.icap': null,
  'slb.icap_http': null,
  'slb.imap-proxy': null,
  'slb.ipv6-class-list': ['name'],
  'slb.l4': null,
  'slb.mlb': null,
  'slb.mssql': null,
  'slb.mysql': null,
  'slb.player-id-global': null,
  'slb.player-id-list': null,
  'slb.pop3-proxy': null,
  'slb.resource-usage': null,
  'slb.server.port': ['name', '{port-number}+{protocol}'],
  'slb.server': ['name'],
  'slb.service-group.member': ['name', '{name}+{port}'],
  'slb.service-group.reset': ['name'],
  'slb.service-group': ['name'],
  'slb.spdy-proxy': null,
  'slb.ssl.export-hc-metrics': null,
  'slb.ssl.sni-automap-attributes': null,
  'slb.ssl-expire-check.exception': null,
  'slb.ssl-expire-check': null,
  'slb.ssl-forward-proxy': null,
  'slb.svm-source-nat': null,
  'slb.switch': null,
  'slb.template.cache': ['name'],
  'slb.template.cipher': ['name'],
  'slb.template.client-ssh': ['name'],
  'slb.template.client-ssl': ['name'],
  'slb.template.connection-reuse': ['name'],
  'slb.template.dblb.calc-sha1': ['name'],
  'slb.template.dblb': ['name'],
  'slb.template.diameter': ['name'],
  'slb.template.diameter.origin-host': ['name'],
  'slb.template.dns.class-list': ['name'],
  'slb.template.dns.class-list.lid-list': ['name', 'lidnum'],
  'slb.template.dns': ['name'],
  'slb.template.dns.response-rate-limiting': ['name'],
  'slb.template.dynamic-service': ['name'],
  'slb.template.external-service': ['name'],
  'slb.template.fix': ['name'],
  'slb.template.ftp': ['name'],
  'slb.template.http': ['name'],
  'slb.template.http-policy': ['name'],
  'slb.template.imap-pop3': ['name'],
  'slb.template.logging': ['name'],
  'slb.template.monitor': ['id'],
  'slb.template.persist.cookie': ['name'],
  'slb.template.persist.destination-ip': ['name'],
  'slb.template.persist.source-ip': ['name'],
  'slb.template.persist.ssl-sid': ['name'],
  'slb.template.policy.class-list': ['name'],
  'slb.template.policy.class-list.lid': ['name', 'lidnum'],
  'slb.template.policy.forward-policy.action-list': ['name'],
  'slb.template.policy.forward-policy': ['name'],
  'slb.template.policy.forward-policy.source.destination.any': ['name'],
  'slb.template.policy.forward-policy.source.destination.class-list-list': [
    'name',
    'dest-class-list',
  ],
  'slb.template.policy.forward-policy.source.destination.web-category-list-list': [
    'name',
    'web-category-list',
  ],
  'slb.template.policy.forward-policy.source-list': ['name'],
  'slb.template.policy': ['name'],
  'slb.template.port': ['name'],
  'slb.template.reqmod-icap': ['name'],
  'slb.template.respmod-icap': ['name'],
  'slb.template.server': ['name'],
  'slb.template.server-ssh': ['name'],
  'slb.template.server-ssl': ['name'],
  'slb.template.sip': ['name'],
  'slb.template.smpp': ['name'],
  'slb.template.smtp': ['name'],
  'slb.template.ssli': ['name'],
  'slb.template.tcp': ['name'],
  'slb.template.tcp-proxy': ['name'],
  'slb.template.udp': ['name'],
  'slb.template.virtual-port': ['name'],
  'slb.template.virtual-server': ['name'],
  'slb.transparent-acl-template': null,
  'slb.transparent-tcp-template': null,
  'slb.virtual-server.migrate-vip': ['name'],
  'slb.virtual-server.port': ['name', '{port-number}+{protocol}'],
  'slb.virtual-server': ['name'],
  smtp: null,
  'snmp-server.SNMPv1-v2c.user.oid': ['user', 'oid-val'],
  'snmp-server.SNMPv1-v2c.user': ['user'],
  'snmp-server.SNMPv3.user': ['username'],
  'snmp-server.snmpv1-v2c.user.oid': ['user', 'oid-val'],
  'snmp-server.snmpv1-v2c.user': ['user'],
  'snmp-server.snmpv3.user': ['username'],
  'snmp-server.community.read.oid': ['user', 'oid-val'],
  'snmp-server.community.read': ['user'],
  'snmp-server.contact': null,
  'snmp-server.disable.traps': null,
  'snmp-server.enable': null,
  'snmp-server.enable.traps.gslb': null,
  'snmp-server.enable.traps.lsn': null,
  'snmp-server.enable.traps.network': null,
  'snmp-server.enable.traps.routing.bgp': null,
  'snmp-server.enable.traps.routing.isis': null,
  'snmp-server.enable.traps.routing.ospf': null,
  'snmp-server.enable.traps.slb': null,
  'snmp-server.enable.traps.slb-change': null,
  'snmp-server.enable.traps.snmp': null,
  'snmp-server.enable.traps.ssl': null,
  'snmp-server.enable.traps.system': null,
  'snmp-server.enable.traps': null,
  'snmp-server.enable.traps.vcs': null,
  'snmp-server.enable.traps.vrrp-a': null,
  'snmp-server.engineID': null,
  'snmp-server.group': ['groupname'],
  'snmp-server.host.host-name': ['{hostname}+{version}'],
  'snmp-server.host.ipv4-host': ['version'],
  'snmp-server.host.ipv6-host': ['version'],
  'snmp-server.location': null,
  'snmp-server.management-index': null,
  'snmp-server.slb-data-cache-timeout': null,
  'snmp-server': null,
  'snmp-server.user': ['username'],
  'snmp-server.view': ['{viewname}+{oid}'],
  'ssh-login-grace-time': null,
  sshd: null,
  'ssl.certificate': ['name'],
  'ssl.key': ['name'],
  'syn-cookie': null,
  'sys-ut.common': null,
  'sys-ut.event.action': ['event-number', 'direction'],
  'sys-ut.event.action.ignore-validation': ['event-number', 'direction'],
  'sys-ut.event.action.l1': ['event-number', 'direction'],
  'sys-ut.event.action.l2': ['event-number', 'direction'],
  'sys-ut.event.action.l2.mac': ['event-number', 'direction', 'src-dst'],
  'sys-ut.event.action.l3.ip': ['event-number', 'direction', 'src-dst'],
  'sys-ut.event.action.l3': ['event-number', 'direction'],
  'sys-ut.event.action.tcp.flags': ['event-number', 'direction'],
  'sys-ut.event.action.tcp.options': ['event-number', 'direction'],
  'sys-ut.event.action.tcp': ['event-number', 'direction'],
  'sys-ut.event.action.udp': ['event-number', 'direction'],
  'sys-ut.event': ['event-number'],
  'sys-ut.run-test': null,
  'sys-ut.state.next-state.case.action': [
    'name',
    'name',
    'case-number',
    'direction',
  ],
  'sys-ut.state.next-state.case.action.l1': [
    'name',
    'name',
    'case-number',
    'direction',
  ],
  'sys-ut.state.next-state.case.action.l2': [
    'name',
    'name',
    'case-number',
    'direction',
  ],
  'sys-ut.state.next-state.case.action.l2.mac': [
    'name',
    'name',
    'case-number',
    'direction',
    'src-dst',
  ],
  'sys-ut.state.next-state.case.action.l3.ip': [
    'name',
    'name',
    'case-number',
    'direction',
    'src-dst',
  ],
  'sys-ut.state.next-state.case.action.l3': [
    'name',
    'name',
    'case-number',
    'direction',
  ],
  'sys-ut.state.next-state.case.action.tcp.flags': [
    'name',
    'name',
    'case-number',
    'direction',
  ],
  'sys-ut.state.next-state.case.action.tcp.options': [
    'name',
    'name',
    'case-number',
    'direction',
  ],
  'sys-ut.state.next-state.case.action.tcp': [
    'name',
    'name',
    'case-number',
    'direction',
  ],
  'sys-ut.state.next-state.case.action.udp': [
    'name',
    'name',
    'case-number',
    'direction',
  ],
  'sys-ut.state.next-state.case': ['name', 'name', 'case-number'],
  'sys-ut.state.next-state': ['name', 'name'],
  'sys-ut.state': ['name'],
  'sys-ut': null,
  'sys-ut.template.ignore-validation': ['name'],
  'sys-ut.template.l1': ['name'],
  'sys-ut.template.l2': ['name'],
  'sys-ut.template.l2.mac': ['name', 'src-dst'],
  'sys-ut.template.l3.ip': ['name', 'src-dst'],
  'sys-ut.template.l3': ['name'],
  'sys-ut.template.tcp.flags': ['name'],
  'sys-ut.template.tcp.options': ['name'],
  'sys-ut.template.tcp': ['name'],
  'sys-ut.template': ['name'],
  'sys-ut.template.udp': ['name'],
  'system.add-cpu-core': null,
  'system.add-port': null,
  'system.all-vlan-limit': null,
  'system.apps-global': null,
  'system.cm-update-file-name-ref': null,
  'system.cpu-hyper-thread': null,
  'system.cpu-load-sharing': null,
  'system.deep-hrxq': null,
  'system.del-port': null,
  'system.delete-cpu-core': null,
  'system.fips': null,
  'system.fw': null,
  'system.gen-logdb': null,
  'system.gen-logdb.performance': null,
  'system.geo-location.entry': ['geo-locn-obj-name'],
  'system.geo-location': null,
  'system.geoloc-list': ['name'],
  'system.icmp': null,
  'system.icmp6': null,
  'system.io-cpu': null,
  'system.ipmi.ip': null,
  'system.ipmi': null,
  'system.ipmi.ipsrc': null,
  'system.ipmi.tool': null,
  'system.ipmi.user': null,
  'system.ipmi-service': null,
  'system.ipsec.fpga-decrypt': null,
  'system.ipsec': null,
  'system.link-capability': null,
  'system.memory': null,
  'system.mgmt-port': null,
  'system.modify-port': null,
  'system.mon-template.monitor': ['id'],
  'system.multi-queue-support': null,
  'system.password-policy': null,
  'system.per-vlan-limit': null,
  'system.ports': null,
  'system.queuing-buffer': null,
  'system.radius.server': null,
  'system.resource-accounting.template.app-resources': ['name'],
  'system.resource-accounting.template.network-resources': ['name'],
  'system.resource-accounting.template.system-resources': ['name'],
  'system.resource-accounting.template': ['name'],
  'system.resource-usage': null,
  'system.resource-usage.visibility': null,
  'system.session-reclaim-limit': null,
  'system.shell-privileges': null,
  'system.spe-profile': null,
  system: null,
  'system.template': null,
  'system.template-bind.monitor': ['template-monitor'],
  'system.trunk.load-balance': null,
  'system.trunk-hw-hash': null,
  'system.trunk-xaui-hw-hash': null,
  'system.ve-mac-scheme': null,
  'system-2x40g-mode': null,
  'system-4x10g-mode': null,
  'system-big-buff-pool': null,
  'system-buff-debug': null,
  'system-jumbo-global': null,
  'system-reset': null,
  'tacacs-server.host.ipv4': ['ipv4-addr'],
  'tacacs-server.host.ipv6': ['ipv6-addr'],
  'tacacs-server.host.tacacs-hostname': ['hostname'],
  'tacacs-server': null,
  'techreport.interval': null,
  'techreport.priority-partition': ['part-name'],
  techreport: null,
  'template.csv': ['csv-name'],
  'template.gtp': ['name'],
  'template.gtp-filter-list': ['name'],
  'template.lid': ['lid-number'],
  'template.sctp': ['name'],
  terminal: null,
  tftp: null,
  'threat-intel.threat-feed': ['type'],
  'threat-intel.threat-list': ['name'],
  timezone: null,
  'upgrade.cf': null,
  'upgrade.gui': null,
  'upgrade.hd': null,
  upgrade: null,
  'vcs.action': null,
  'vcs.admin-session-connect': null,
  'vcs.debug': null,
  'vcs.device': ['device'],
  'vcs.reload': null,
  'vcs.vMaster-maintenance': null,
  'vcs.vcs-para': null,
  'vcs.vmaster-take-over': null,
  'vcs-chassis.admin-session-connect': null,
  'vcs-chassis.debug': null,
  'vcs-vblades.stat': ['vblade-id'],
  'visibility.anomaly-detection': null,
  'visibility.file.metrics': null,
  'visibility.monitor.agent': ['agent-name'],
  'visibility.monitor.debug': ['{debug-ip-addr}+{debug-port}+{debug-protocol}'],
  'visibility.monitor.delete-debug-file': ['debug-ip-addr'],
  'visibility.monitor': null,
  'visibility.monitor.netflow': null,
  'visibility.monitor.replay-debug-file': ['debug-ip-addr'],
  'visibility.monitor.secondary-monitor.debug': [
    '{debug-ip-addr}+{debug-port}+{debug-protocol}',
  ],
  'visibility.monitor.secondary-monitor.delete-debug-file': ['debug-ip-addr'],
  'visibility.monitor.secondary-monitor.replay-debug-file': ['debug-ip-addr'],
  'visibility.monitor.secondary-monitor': null,
  'visibility.monitor.sflow': null,
  'visibility.reporting': null,
  'visibility.reporting.telemetry-export-interval': null,
  'visibility.reporting.template.notification.template-name.authentication': [
    'name',
  ],
  'visibility.reporting.template.notification.template-name': ['name'],
  visibility: null,
  'vpn.ike-gateway': ['name'],
  'vpn.ipsec.bind-tunnel': ['name'],
  'vpn.ipsec': ['name'],
  'vpn.revocation': ['name'],
  vpn: null,
  'vrrp-a.common': null,
  'vrrp-a.fail-over-policy-template': ['name'],
  'vrrp-a.force-self-standby': null,
  'vrrp-a.force-self-standby-persistent': ['vrid'],
  'vrrp-a.interface.ethernet': ['ethernet-val'],
  'vrrp-a.interface.trunk': ['trunk-val'],
  'vrrp-a.l2-inline-peer-ip': ['ip-address'],
  'vrrp-a.l3-inline-mode-flag': null,
  'vrrp-a.ospf-inline': ['vlan'],
  'vrrp-a.peer-group': null,
  'vrrp-a.preferred-session-sync-port.ethernet': ['pre-eth'],
  'vrrp-a.preferred-session-sync-port.trunk': ['pre-trunk'],
  'vrrp-a.restart-port-list': null,
  'vrrp-a.restart-port-list.vrid': ['vrid-val'],
  'vrrp-a.session-sync': null,
  'vrrp-a.vrid.blade-parameters': ['vrid-val'],
  'vrrp-a.vrid.blade-parameters.tracking-options.gateway.ipv4-gateway': [
    'vrid-val',
    'ip-address',
  ],
  'vrrp-a.vrid.blade-parameters.tracking-options.gateway.ipv6-gateway': [
    'vrid-val',
  ],
  'vrrp-a.vrid.blade-parameters.tracking-options': ['vrid-val'],
  'vrrp-a.vrid': ['vrid-val'],
  'vrrp-a.vrid-lead': ['vrid-lead-str'],
  'vrrp-a': null,
  'waf.policy': null,
  'waf.template': ['name'],
  'waf.wsdl': null,
  'waf.xml-schema': null,
  'web-category.category-list': ['name'],
  'web-category.proxy-server': null,
  'web-category': null,
  'web-service.secure.certificate': null,
  'web-service.secure.generate': null,
  'web-service.secure.private-key': null,
  'web-service.secure.regenerate': null,
  'web-service.secure': null,
  'web-service': null,
  'write.memory': null,
  'zone.interface': ['name'],
  'zone.local-zone-cfg': ['name'],
  'zone.vlan': ['name'],
  zone: ['name'],
}
