import React from 'react'
import './styles/SuperNavBar.less'

const SuperNavSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="930"
    height="556"
    viewBox="0 0 688 556"
  >
    <defs>
      <filter id="019mdl0xba" color-interpolation-filters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.164706 0 0 0 0 0.239216 0 0 0 0 0.337255 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g
            filter="url(#019mdl0xba)"
            transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000)"
          >
            <path fill="#EDF1F9" d="M0 0.5H240V551.5H0z" />
            <g stroke-linecap="square">
              <path
                stroke="#C5D3DA"
                d="M0 3.346L553.691 3.346"
                transform="translate(241.422826, 277.345652) rotate(-270.000000) translate(-241.422826, -277.345652) translate(-35.422826, 275.422826)"
              />
              <path
                stroke="#D9E2ED"
                stroke-opacity=".502"
                d="M0 2.346L553.691 2.346"
                transform="translate(241.422826, 277.345652) rotate(-270.000000) translate(-241.422826, -277.345652) translate(-35.422826, 275.422826)"
              />
            </g>
          </g>
          <g>
            <g stroke="#FFF" stroke-linecap="round">
              <path
                d="M10.77 23.268H1.023c-.289 0-.523-.253-.523-.564v-7.146h10.27v7.71zM23.5 8.209H13.23V.5h9.747c.289 0 .523.254.523.564v7.145zM22.977 23.5H13.23V10.833H23.5v12.103c0 .312-.234.564-.523.564zM10.77 13.169H.5V1.064C.5.753.734.5 1.023.5h9.747V13.17z"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 59.500000)"
              />
            </g>
            <text
              fill="#FFF"
              font-family="Rubik-Regular, Rubik"
              font-size="16"
              transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 59.500000)"
            >
              <tspan x="34" y="18">
                Dashboard
              </tspan>
            </text>
          </g>
          <g>
            <g stroke="#FFF" stroke-linecap="round">
              <path
                d="M21.638 19.618c1.027 0 1.862.836 1.862 1.862 0 1.027-.835 1.862-1.862 1.862-1.027 0-1.862-.835-1.862-1.862 0-1.026.835-1.862 1.862-1.862zm-9.647 0c1.027 0 1.862.836 1.862 1.862 0 1.027-.835 1.862-1.862 1.862-1.027 0-1.862-.835-1.862-1.862 0-1.026.835-1.862 1.862-1.862zm-9.63 0c1.028 0 1.863.836 1.863 1.862 0 1.027-.835 1.862-1.862 1.862C1.335 23.342.5 22.507.5 21.48c0-1.026.835-1.862 1.862-1.862zm-.012-.002l.015-5.083c0-.8.655-1.456 1.456-1.456h16.344c.8 0 1.455.656 1.455 1.456l.036 5.083M11.95 10.6v9.082M11.939 10.573c-2.777 0-5.037-2.26-5.037-5.035C6.902 2.76 9.162.5 11.94.5c2.776 0 5.036 2.26 5.036 5.038 0 2.775-2.26 5.035-5.036 5.035zm0 0c-1.878 0-2.862-2.533-2.862-5.035C9.077 3.034 10.06.5 11.939.5 13.817.5 14.8 3.034 14.8 5.538c0 2.502-.984 5.035-2.862 5.035zm-4.181-7.84c.014.004 1.671.424 4.18.424 2.512 0 4.168-.42 4.182-.424m0 5.605c-.014-.004-1.596-.425-4.106-.425s-4.242.425-4.257.429"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 102.500000)"
              />
            </g>
            <text
              fill="#FFF"
              font-family="Rubik-Regular, Rubik"
              font-size="16"
              transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 102.500000)"
            >
              <tspan x="34" y="18">
                Providers
              </tspan>
            </text>
          </g>
          <g>
            <g stroke="#FFF" stroke-linecap="round">
              <path
                d="M20.458 10.3V6.262L10.471.5.501 6.261v11.523l9.77 5.716 1.815-1.018M.5 6.492l9.771 5.869 10.187-5.87m-10.186 5.87V23.5"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 147.500000) translate(0.000000, 0.000000)"
              />
              <path
                d="M17.75 19.5c-.965 0-1.75-.785-1.75-1.75S16.785 16 17.75 16c.966 0 1.75.785 1.75 1.75s-.784 1.75-1.75 1.75zm.732 3.595h-1.32a.196.196 0 0 1-.196-.196v-1.182a4.104 4.104 0 0 1-1.406-.578l-.832.833a.197.197 0 0 1-.279 0l-.932-.933a.196.196 0 0 1 0-.278l.832-.832c-.28-.429-.474-.901-.58-1.408h-1.181a.196.196 0 0 1-.196-.197v-1.32c0-.107.087-.195.196-.195h1.18c.106-.508.3-.98.58-1.408l-.832-.833a.195.195 0 0 1 0-.277l.932-.934a.198.198 0 0 1 .278 0l.832.833c.428-.28.9-.473 1.408-.58v-1.18c0-.108.088-.197.196-.197h1.32c.108 0 .195.089.195.196v1.18c.508.108.98.301 1.409.583l.833-.835a.198.198 0 0 1 .278 0l.932.934a.195.195 0 0 1 0 .277l-.832.835c.278.428.472.9.577 1.406h1.183c.108 0 .197.088.197.196v1.32a.197.197 0 0 1-.197.196h-1.183c-.105.505-.3.977-.58 1.406l.835.834a.196.196 0 0 1 0 .278l-.933.933a.204.204 0 0 1-.279 0l-.833-.835c-.427.28-.9.473-1.407.58V22.9a.195.195 0 0 1-.195.196z"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 147.500000) translate(0.000000, 0.000000)"
              />
            </g>
            <text
              fill="#FFF"
              font-family="Rubik-Regular, Rubik"
              font-size="16"
              transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 147.500000)"
            >
              <tspan x="34" y="18">
                Platform
              </tspan>
            </text>
          </g>
          <g>
            <g stroke="#FFF" stroke-linecap="round">
              <path
                d="M12.181 24.607c-4.23 0-6.421-5.941-6.421-11.804C5.76 6.941 7.988 1 12.181 1c4.23 0 6.421 5.941 6.421 11.803 0 5.902-2.19 11.804-6.421 11.804zM.85 12.803c0-4.406 5.703-6.688 11.331-6.688 5.628 0 11.331 2.321 11.331 6.688 0 4.407-5.703 6.689-11.331 6.689C6.515 19.492.85 17.21.85 12.803z"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 192.500000) translate(0.000000, 0.000000)"
              />
            </g>
            <text
              fill="#FFF"
              font-family="Rubik-Regular, Rubik"
              font-size="16"
              transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 192.500000)"
            >
              <tspan x="34" y="18">
                System
              </tspan>
            </text>
          </g>
          <g>
            <g stroke="#FFF" stroke-linecap="round">
              <path
                d="M12.649 21H1.292a.792.792 0 0 1-.792-.792V2.792C.5 2.354.854 2 1.292 2h17.386c.454 0 .822.367.822.586v2.762m-11.824.023h8.296m-12.229.723l-.725-.725m.725.725L5.2 4.638m2.476 6.48h6.607m-10.54.813l-.725-.725m.725.725L5.2 10.475m2.476 6.119h3.06m-6.993.867l-.725-.725m.725.725L5.2 16.004"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 236.500000) translate(0.000000, 0.000000)"
              />
              <path
                d="M17.297 15.047a2.28 2.28 0 0 0 3.491.008 2.96 2.96 0 0 1 2.712 2.94v2.3a.515.515 0 0 1-.513.514h-7.973a.515.515 0 0 1-.514-.514v-2.3a2.962 2.962 0 0 1 2.797-2.948zM19 8.5c1.262 0 2.357 1.133 2.284 2.284a19.975 19.975 0 0 1-.318 2.363 2.03 2.03 0 0 1-3.932 0s-.246-1.21-.319-2.363C16.643 9.633 17.74 8.5 19 8.5z"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 236.500000) translate(0.000000, 0.000000)"
              />
            </g>
            <text
              fill="#FFF"
              font-family="Rubik-Regular, Rubik"
              font-size="16"
              transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 236.500000)"
            >
              <tspan x="34" y="18">
                Admin User
              </tspan>
            </text>
          </g>
          <g>
            <g stroke="#FFF" stroke-linecap="round">
              <path
                d="M23.5 17.848H.5V4.615C.5 3.17 1.67 2 3.115 2h17.77C22.33 2 23.5 3.17 23.5 4.615v13.233zm-5.31 4.097H5.8m6.22 0v-4.007"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 280.500000) translate(0.000000, -0.000000)"
              />
              <path
                d="M18.21 8.606l-.158-1.495-1.61 1.171c-.117.086-.264.12-.407.097-.144-.024-.272-.104-.356-.222l-.784-1.046c-.085-.117-.12-.264-.096-.407.023-.144.103-.272.222-.356l1.61-1.17-1.38-.607-2.342 1.673.157 1.14-.094.062-7.442 5.373c-.443.321-.68.854-.624 1.398.057.544.4 1.016.9 1.238.499.223 1.079.162 1.522-.159l7.4-5.383c.033-.019.061-.044.084-.073l1.045.47 2.352-1.704z"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 280.500000) translate(0.000000, -0.000000)"
              />
            </g>
            <text
              fill="#FFF"
              font-family="Rubik-Regular, Rubik"
              font-size="16"
              transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 280.500000)"
            >
              <tspan x="34" y="18">
                Troubleshooting
              </tspan>
            </text>
          </g>
          <g>
            <g stroke="#FFF" stroke-linecap="round">
              <path
                d="M8.974 12.308a3.159 3.159 0 0 1 3.156-3.154 3.156 3.156 0 0 1 3.154 3.154M.5 15.13h14.705m4.901 0H23.5m-4.525 0a1.132 1.132 0 1 1-2.263 0 1.132 1.132 0 0 1 2.263 0zm4.525 3.324H8.795m-4.901 0H.5m4.525 0a1.132 1.132 0 1 1 2.263 0 1.132 1.132 0 0 1-2.263 0zM23.5 21.471h-9.426m-4.901 0H.5m10.18 0a1.132 1.132 0 1 1 2.264 0 1.132 1.132 0 0 1-2.263 0z"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 325.500000) translate(0.000000, -0.000000)"
              />
              <path
                d="M.819 12.308v-1.37a.41.41 0 0 1 .41-.41h2.459a8.476 8.476 0 0 1 1.205-2.93L3.16 5.863a.406.406 0 0 1 0-.578l1.942-1.944a.412.412 0 0 1 .58 0l1.732 1.736a8.51 8.51 0 0 1 2.933-1.21v-2.46c0-.223.182-.407.408-.407h2.748a.41.41 0 0 1 .409.407v2.46a8.459 8.459 0 0 1 2.932 1.213l1.737-1.739a.411.411 0 0 1 .578 0l1.942 1.944c.16.16.16.42 0 .578L19.366 7.6c.58.892.985 1.873 1.203 2.928h2.464c.224 0 .41.184.41.409v1.372"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 325.500000) translate(0.000000, -0.000000)"
              />
            </g>
            <text
              fill="#FFF"
              font-family="Rubik-Regular, Rubik"
              font-size="16"
              transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(27.000000, 325.500000)"
            >
              <tspan x="34" y="18">
                Monitoring
              </tspan>
            </text>
          </g>
          <g>
            <text
              fill="#333"
              font-family="Roboto-Regular, Roboto"
              font-size="20"
              transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(158.177091, 61.500000)"
            >
              <tspan x="167.823" y="19">
                Platform and resource dashboard
              </tspan>
            </text>
            <g stroke="#ADA6A6">
              <path
                d="M6.177.677L5.697 159m5.657-5.677L5.676 159 0 153.323"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(158.177091, 61.500000) translate(79.500000, 12.000000) rotate(90.000000) translate(-79.500000, -12.000000) translate(73.822909, -67.500000)"
              />
            </g>
          </g>
          <g>
            <text
              fill="#333"
              font-family="Roboto-Regular, Roboto"
              font-size="20"
              transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(167.177091, 104.500000)"
            >
              <tspan x="167.823" y="19">
                Providers Management
              </tspan>
            </text>
            <g stroke="#ADA6A6">
              <path
                d="M6.177.677L5.697 159m5.657-5.677L5.676 159 0 153.323"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(167.177091, 104.500000) translate(79.500000, 12.000000) rotate(90.000000) translate(-79.500000, -12.000000) translate(73.822909, -67.500000)"
              />
            </g>
          </g>
          <g>
            <text
              fill="#333"
              font-family="Roboto-Regular, Roboto"
              font-size="20"
              transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(193.677091, 150.500000)"
            >
              <tspan x="110.323" y="19">
                License Management, App Catalog and Microservice Config 
              </tspan>
            </text>
            <g stroke="#BBB">
              <path
                d="M48-41.646l-.48 94.823m5.657-5.677L47.5 53.177 41.823 47.5"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(193.677091, 150.500000) translate(0.000000, 6.322909) translate(47.500000, 5.677091) rotate(90.000000) translate(-47.500000, -5.677091)"
              />
            </g>
          </g>
          <g>
            <text
              fill="#333"
              font-family="Roboto-Regular, Roboto"
              font-size="20"
              transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(178.500000, 192.500000)"
            >
              <tspan x="257.5" y="19">
                System
              </tspan>
            </text>
            <g stroke="#BBB">
              <path
                d="M122.94-117.338l-.423 244.838m4.983-5.21l-5.001 5.21-4.999-5.21"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(178.500000, 192.500000) translate(122.500000, 13.500000) scale(1, -1) translate(-122.500000, -13.500000) translate(0.000000, 8.500000) translate(122.500000, 5.000000) rotate(90.000000) translate(-122.500000, -5.000000)"
              />
            </g>
          </g>
          <g>
            <text
              fill="#333"
              font-family="Roboto-Regular, Roboto"
              font-size="20"
              transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(180.677091, 236.500000)"
            >
              <tspan x="159.323" y="19">
                Super admin management
              </tspan>
            </text>
            <g stroke="#BBB">
              <path
                d="M72.5-66.146l-.48 143.823M77.677 72L72 77.677 66.323 72"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(180.677091, 236.500000) translate(72.000000, 13.000000) scale(1, -1) translate(-72.000000, -13.000000) translate(0.000000, 7.322909) translate(72.000000, 5.677091) rotate(90.000000) translate(-72.000000, -5.677091)"
              />
            </g>
          </g>
          <g>
            <text
              fill="#333"
              font-family="Roboto-Regular, Roboto"
              font-size="20"
              transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(196.677091, 282.500000)"
            >
              <tspan x="159.323" y="19">
                Microservice Logs and Run Command
              </tspan>
            </text>
            <g stroke="#BBB">
              <path
                d="M72.5-66.146l-.48 143.823M77.677 72L72 77.677 66.323 72"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(196.677091, 282.500000) translate(72.000000, 13.000000) scale(1, -1) translate(-72.000000, -13.000000) translate(0.000000, 7.322909) translate(72.000000, 5.677091) rotate(90.000000) translate(-72.000000, -5.677091)"
              />
            </g>
          </g>
          <g>
            <text
              fill="#333"
              font-family="Roboto-Regular, Roboto"
              font-size="20"
              transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(182.677091, 327.500000)"
            >
              <tspan x="159.323" y="19">
                Grafana and Prometheus
              </tspan>
            </text>
            <g stroke="#BBB">
              <path
                d="M72.5-66.146l-.48 143.823M77.677 72L72 77.677 66.323 72"
                transform="translate(-270.000000, -195.000000) translate(270.000000, 195.500000) translate(182.677091, 327.500000) translate(72.000000, 13.000000) scale(1, -1) translate(-72.000000, -13.000000) translate(0.000000, 7.322909) translate(72.000000, 5.677091) rotate(90.000000) translate(-72.000000, -5.677091)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const SuperNavigation = () => {
  return (
    <div id="gettingStarted-5" className="superNavBar">
      <div className="gettingStarted-content-header">
        Super-Admin Navigation
      </div>
      <div className="super-admin-nav-container">
        {/* <div className="super-admin-nav-img" /> */}
        <SuperNavSvg />
      </div>
    </div>
  )
}

export default SuperNavigation
