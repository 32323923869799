export class URLConstants {
  URLS = {
    EDGE_API_URL: {
      URL: '',
    },
    CREATE_NEW_SESSION: {
      URL: '/sessions',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GENERATE_QR_CODE: {
      URL: '/mfa/qrscan/generate',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    VALIDATE_OTP: {
      URL: '/mfa/totp/validate',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    RECOVER_MFA: {
      URL: '/mfa/recovery',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    DELETE_SESSION: {
      URL: '/sessions/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_ROLES: {
      URL: '/roles',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_USER_DETAILS: {
      URL: '/users/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_HELP_TEXTS: {
      URL: '/helptexts/AppcitoHelpTexts',
      METHOD: 'GET',
    },
    CREATE_HELP_TEXT_REPO: {
      URL: '/helptexts',
      METHOD: 'POST',
    },
    UPDATE_HELP_TEXTS: {
      URL: '/helptexts/AppcitoHelpTexts',
      METHOD: 'PUT',
    },
    LIST_TENANT: {
      URL: '~/tenants/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    LIST_TENANTS_O: {
      URL: '/providers/~/tenants',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    LIST_TENANTS: {
      URL: '/hpcapi/v3/provider/~/tenant',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    LIST_TENANTS_DETAILED: {
      URL: '/hpcapi/v3/provider/~/tenant?detail=true',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    LIST_TENANTS_SUMMARY: {
      URL: '/hpcapi/v3/provider/~/tenant?summary=true',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_TENANTS_LIST: {
      URL:
        '/applications/provider/~/app-services/count?associationstate=active',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    LIST_SELECTED_TENANTS_O: {
      URL: '/providers/~/tenants/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    LIST_SELECTED_TENANTS: {
      URL: '/hpcapi/v3/provider/~/tenant?name=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_APPLICATIONS_DETAILS: {
      URL: '/applications/~?options=loadDetails',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_APPLICATIONS: {
      URL: '/hpcapi/v3/provider/~/tenant/~/app-svc',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_APPLICATIONS_BARISTA: {
      URL: '/applications',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_ACTIVE_APPLICATIONS_BARISTA: {
      URL: '/applications?associationstate=active',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_INACTIVE_APPLICATIONS_BARISTA: {
      URL: '/applications?associationstate=inactive',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_CLUSTERS_O: {
      URL: '/providers/~/clusters',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_CLUSTERS: {
      URL: '/hpcapi/v3/provider/~/cluster?detail=true',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_CLUSTERS_SUMMARY: {
      URL: '/hpcapi/v3/provider/~/cluster?summary=true',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_CLUSTER: {
      URL: '/hpcapi/v3/provider/~/cluster/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_CLUSTER_SUMMARY: {
      URL: '/hpcapi/v3/provider/~/cluster/~?summary=true',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICES: {
      URL: '/hpcapi/v3/provider/~/device?cluster=~&detail=true',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICES_SUMMARY: {
      URL: '/hpcapi/v3/provider/~/device?summary=true',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    REBOOT_DEVICE: {
      URL: '/hocapi/v1/provider/~/device/~/_reboot',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICES_O: {
      URL: '/providers/~/clusters/~/devices',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_PARTITIONS: {
      URL: '/hpcapi/v3/provider/~/device/~/partition',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_INDEPENDENT_DEVICES: {
      URL: '/hpcapi/v3/provider/~/device',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_CLUSTER_STATS: {
      URL: '/analytics/thunder-adc/system_telemetry_log_device_status',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GET_PARTITION_STATS: {
      URL: '/analytics/thunder-adc/system_telemetry_log_partition_metrics',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GET_LADC_CLUSTERS: {
      URL: '/hpcapi/v3/provider/~/cluster',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_UUID_DETAILS: {
      URL: '/hpcapi/v3/uuid/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    CREATE_CLUSTER: {
      URL: '~//reg/dc/~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_CLUSTER_NAME: {
      URL: '~//reg/dc/~',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    GET_JWT_TOKEN: {
      URL: '/sessions/jwt/generate',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_PROVIDERS: {
      URL: '/hpcapi/v3/provider?summary=true',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_PROVIDER: {
      URL: '/hpcapi/v3/provider/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_PROVIDER_ACTIVATION_URL: {
      URL: '~/providers/~/_activationurl',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_PROVIDER: {
      URL: '/hpcapi/v3/provider/~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_TENANTS: {
      URL: '/hpcapi/v3/provider/~/tenant',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_TENANT: {
      URL: '/hpcapi/v3/provider/~/tenant/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_TENANT_BANDWIDTH_STATS: {
      URL: '/analytics/thunder-adc/slb_virtual_server_port',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_TENANT_REQUESTS_STATS: {
      URL: '/analytics/thunder-adc/system_telemetry_log_partition_metrics',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_TENANT_REQUESTS_STATS_L7: {
      URL: '/analytics/thunder-adc/slb_virtual_server_port',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_TENANT: {
      URL: '/hpcapi/v3/provider/~/tenant/~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_TENANT_CONNECTIONS_STATS: {
      URL: '/analytics/thunder-adc/system_telemetry_log_partition_metrics',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_USERS: {
      // URL: '/hpcapi/v3/provider/~/user',
      URL: '/users',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_USERS_COUNT: {
      URL: '/users/count',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_TENANT_ADMIN: {
      URL: '/users/_listusers',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_USER_INFO: {
      // URL: '/hpcapi/v3/provider/~/user/~',
      URL: '/users/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_USER_ACTIVATION_LINK: {
      URL: '/users/~/_getactivationmail',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_ADMIN_ACTIVATION_LINK: {
      URL: '/users/~/_get_admin_activationmail',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    ADD_USER: {
      // URL: '/hpcapi/v3/provider/~/user',
      URL: '/users',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    ADD_SUPER_ADMIN_USER: {
      URL: '/users/superadmin',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_SUPERADMIN_USERS: {
      URL: '/users/superadmin',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_USER: {
      // URL: '/hpcapi/v3/provider/~/user/~',
      URL: '/users/~/_updateinfo',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    ADD_PROVIDER: {
      URL: '/hpcapi/v3/provider',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    ADD_SUB_PROVIDER_USER: {
      URL: '/users',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    ADD_TENANT: {
      URL: '/hpcapi/v3/provider/~/tenant',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    ASSIGN_USER: {
      //URL: '/hpcapi/v3/provider/~/user/~/roles',
      URL: '/users/~/_addrole',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    ASSIGN_TENANT_USER: {
      URL:
        '/hpcapi/v3/provider/~/user/~/roles/tenantadmin/scopes/providers/tenants',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_LADC_CREDENTIALS: {
      URL: '/infracredentials',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_HC_APP_CATALOG: {
      URL: '/apps',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    VALIDATE_HC_APP: {
      URL: '/apps/validate',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    UPLOAD_HC_APP: {
      URL: '/apps',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    UPDATE_HC_APP: {
      URL: '/apps/~/version/~',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    GET_HC_APP_INFO: {
      URL: '/apps/~/version/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_HC_APP: {
      URL: '/apps/~/version/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    SUB_HC_PROVIDER_APP: {
      URL: '~/apps/~/version/~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    UNSUB_HC_PROVIDER_APP: {
      URL: '~/apps/~/version/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    FORCE_UNSUB_HC_PROVIDER_APP: {
      URL: '~/apps/~/version/~?force=true',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_HC_PROVIDER_APPS: {
      URL: '~/apps',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_HC_TENANT_APP_CATALOG: {
      URL: '~/tenants/~/_appcatalogue',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_HC_TENANT_APPS: {
      URL: '~/tenants/~/apps',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_HC_TENANT_APPS_APP_ADMIN: {
      URL: '~/tenants/~/_listapps',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    SUB_HC_TENANT_APP: {
      URL: '~/tenants/~/apps/~/version/~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    UNSUB_HC_TENANT_APP: {
      URL: '~/tenants/~/apps/~/version/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_PASSWORD: {
      URL: '/users/~/_updatepassword',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_ADMIN_PASSWORD: {
      URL: '/users/~/_update_admin_password',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    ACTIVATE_DOMAIN_USER: {
      URL: '/users/~/activate',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    RESET_PASSWORD: {
      URL: '/users/~/_resetpassword',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_TERMS_ACCEPTED: {
      URL: '/users/~/terms',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    SET_TERMS_ACCEPTED: {
      URL: '/users/~/terms',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_INHERITANCE: {
      URL: '/users/~/inheritable',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_INHERITANCE_TYPE: {
      URL: '/users/~/inheritableauthtype',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_AUTH_TYPE: {
      URL: '/users/~/authtype',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    SET_AUTH: {
      URL: '/users/~/authtype',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    VALIDATE_USER_AUTH: {
      URL: '/users/~/authtype/validate',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_AUTH_PROVIDER: {
      URL: '/hpcapi/v3/provider/~/authentication-provider',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_AUTH: {
      URL: '/providers/~/authtype',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    VALIDATE_PROVIDER_AUTH: {
      URL: '/providers/~/authtype/validate',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_TENANT: {
      URL: '/hpcapi/v3/provider/~/tenant/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_USER: {
      // URL: '/hpcapi/v3/provider/~/user/~',
      URL: '/users/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_PROVIDER: {
      URL: '/hpcapi/v3/provider/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_PROVIDER_CHART_TEMPLATES: {
      URL: '/providers/~/chart_templates',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_OBJECT_TYPE: {
      URL: '/analytics/configuration/metricscategory',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_SCHEMA_REGISTRY: {
      URL: '/schema-registry/subjects/~/versions/1',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_LADC_SCHEMA_REGISTRY: {
      URL: '/analytics/configuration/schema/subject/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_METRIC_TYPE: {
      URL: '/analytics/configuration/metricscategory/~/~/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_TRIGGERS: {
      URL: '/analytics/configuration/ruledef',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_CANNED_TRIGGERS: {
      URL: '/analytics/configuration/ruledef/hc_canned_rules',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_TRIGGER_TEMPLATES: {
      URL: '/analytics/configuration/ruledef/chart_templates/~/version/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_TRIGGER: {
      URL: '/analytics/configuration/ruledef/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    ADD_TRIGGER: {
      URL: '/analytics/configuration/ruledef',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_TRIGGER: {
      URL: '/analytics/configuration/ruledef',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_TRIGGER: {
      URL: '/analytics/configuration/ruledef/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_ALERTS: {
      URL: '/analytics/configuration/ruleassociation',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    ADD_ALERT: {
      URL: '/analytics/configuration/ruleassociation',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    UPDATE_ALERT: {
      URL: '/analytics/configuration/ruleassociation',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: false,
    },
    DELETE_ALERT: {
      URL: '/analytics/configuration/ruleassociation/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_SERVICE_GROUP: {
      URL:
        '/hpcapi/v3/provider/~/tenant/~/app-svc/~/slb/service-group?detail=true',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_SLB_VIRTUAL_SERVER: {
      URL:
        '/hpcapi/v3/provider/~/tenant/~/logical-partition/~/slb/virtual-server/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_SLB_SERVICE_GROUP: {
      URL:
        '/hpcapi/v3/provider/~/tenant/~/logical-partition/~/slb/service-group/~?detail=true',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_SELF_OBJECTS: {
      URL: '/hpcapi/v3/provider/~/tenant/~/logical-partition/~/~/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_CGNV6_TECH: {
      URL: '/hpcapi/v3/provider/~/tenant/~/logical-partition/~/cgnv6/~/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_TREE_NODES: {
      URL: '/hpcapi/v3/provider/~/tenant/~/logical-partition/~/cgnv6/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_TREE_NODES_FW: {
      URL: '/hpcapi/v3/provider/~/tenant/~/logical-partition/~/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_ACTIONS: {
      URL: '/analytics/configuration/actiondef',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_ACTION: {
      URL: '/analytics/configuration/actiondef/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    ADD_ACTION: {
      URL: '/analytics/configuration/actiondef',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    UPDATE_ACTION: {
      URL: '/analytics/configuration/actiondef',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: false,
    },
    DELETE_ACTION: {
      URL: '/analytics/configuration/actiondef/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    CHECK_DELETE_ACTION: {
      URL: '/analytics/configuration/ruleassociation/can_purge/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_ALL_TIMEZONES: {
      URL: '/hcuisapi/timezones',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    CREATE_ADHOC_REPORT: {
      URL: '/hcuisapi/provider/~/tenant/~/report/_generate',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    CREATE_SCHEDULED_REPORT: {
      URL: '/hcuisapi/provider/~/tenant/~/reports',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    DOWNLOAD_REPORT: {
      URL: '/hcuisapi/provider/~/tenant/~/report/_download',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_REPORTS: {
      // URL: '/analytics/configuration/reportdef',
      URL: '/hcuisapi/provider/~/tenant/~/report/_retrieve ',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_REPORTS_TEMPLATES: {
      // URL: '/analytics/configuration/reportassociation/_system',
      URL: '/hcuisapi/provider/~/tenant/~/report/reportTypes',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    ADD_REPORTS_ASSOCIATION: {
      URL: '/analytics/configuration/reportassociation',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GET_REPORT_SCHEDULES: {
      // URL: '/analytics/configuration/reportassociation',
      URL: '/hcuisapi/provider/~/tenant/~/reports/_retrieve',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_SCHEDULED_REPORT: {
      URL: '/hcuisapi/provider/~/tenant/~/reports/_delete',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_ADHOC_REPORT: {
      URL: '/hcuisapi/provider/~/tenant/~/report/_delete',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    CREATE_REPORT_ONDEMAND_ASSOCIATION: {
      URL: '/analytics/configuration/reportschedule/generate',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    CREATE_REPORT_SCHEDULE_ASSOCIATION: {
      URL: '/analytics/configuration/reportschedule',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    DELETE_REPORT_SCHEDULE: {
      URL: '/analytics/configuration/reportassociation/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_PROVIDER_ADMIN_DETAILS: {
      URL: '/users/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_CLIENT_ID: {
      URL: '/providers/~/clientid',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_PARENT_CLIENT_ID: {
      URL: '/providers/~/inheritclientid',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_HC_EVENTS: {
      URL: '/analytics/event/hc_event',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    ADD_CLUSTER: {
      URL: '/hpcapi/v3/provider/~/cluster',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    VCS_HA_ONBOARD: {
      URL: '/hocapi/v1/provider/~/vcs-cluster/~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_CLUSTER: {
      URL: '/hpcapi/v3/provider/~/cluster/~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_CLUSTER: {
      URL: '/hpcapi/v3/provider/~/cluster/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    SCAN_CLUSTER: {
      URL: '/hocapi/v1/provider/~/cluster/~/_scan',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    FEATURE_CHECK: {
      URL: '/hocapi/v1/provider/~/~/~/_check_feature_support',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    CHECK_FEATURE_SUPPORT: {
      URL: '/hocapi/v1/_check_feature_support',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    VALIDATE_DEVICE: {
      URL: '/hocapi/v1/provider/~/device/~/_validate/',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    ADD_DEVICE: {
      URL: '/hpcapi/v3/provider/~/device',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    REGISTER_DEVICE: {
      URL: '/hocapi/v1/provider/~/device/~/',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    DEREGISTER_DEVICE: {
      URL: '/hocapi/v1/provider/~/device/~/_deregister/',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_DEVICE: {
      URL: '/hpcapi/v3/provider/~/device/~',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE: {
      URL: '/hpcapi/v3/provider/~/device/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_QUERY: {
      URL: '/hpcapi/v3/provider/~/device?~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_DEVICE: {
      URL: '/hpcapi/v3/provider/~/device/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_PARTITION: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    ADD_PARTITION: {
      URL: '/hpcapi/v3/provider/~/device/~/partition',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    ADD_PARTITION_CLUSTER: {
      URL: '/hocapi/v1/provider/~/cluster/~/partition/',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    EDIT_PARTITION_CLUSTER: {
      URL: '/hpcapi/v3/provider/~/cluster/~/partition/~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    ADD_PARTITION_CLUSTER_INITIAL_REQUEST: {
      URL: '/hpcapi/v3/provider/~/cluster/~/partition/',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_PARTITION: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_PARTITION: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_CLUSTER_PARTITION: {
      URL: '/hocapi/v1/provider/~/cluster/~/partition/_delete_partitions',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    ADD_CLUSTER_DEVICE: {
      URL: '/hpcapi/v3/provider/~/device?cluster=~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    //hls
    VALIDATE_LICENSE: {
      URL: '/hls/licenses/providers/~/licenseinfo/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    VALIDATE_OFFLINE_LICENSE: {
      URL: '/hls/licenses/providers/~/entitlementkey/~/offline',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    IMPORT_OFFLINE_LICENSE: {
      URL: '/hls/licenses/providers/~/entitlementkey/~/import',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    IMPORT_OFFLINE_LICENSE_UPDATE: {
      URL: '/hls/licenses/providers/~/entitlementkey/~/import',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    GET_LICENSE_INFO: {
      URL: '/hls/licenses/providers/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    ADD_LICENSE_INFO: {
      URL: '/hls/licenses/providers/~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    DEL_LICENSE_INFO: {
      URL: '/hls/licenses/providers/~/entitlementkey/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    REMOVE_USER: {
      URL: '/users/~/_removerole',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_WORKFLOWS: {
      URL: '/hwcapi/v1/workflow',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_WORKFLOW_DETAIL: {
      URL: '/hwcapi/v1/workflow/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_VIRTUAL_DEVICES: {
      URL: '/hpcapi/v3/provider/~/virtual-device',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_VIRTUAL_DEVICE_INFO: {
      URL: '/hpcapi/v3/provider/~/virtual-device/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_CREDENTIALS_INFRA_ACCOUNT: {
      URL: '/hioapi/v1/provider/~/infra-account',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_CREDENTIALS_INFRA_ACCOUNT_ENV: {
      URL: '/hioapi/v1/provider/~/infra-account?infra-provider=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    VALIDATE_CREDENTIALS_INFRA_ACCOUNT: {
      URL: '/hioapi/v1/provider/~/infra-account/validate',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    ADD_CREDENTIALS_INFRA_ACCOUNT: {
      URL: '/hioapi/v1/provider/~/infra-account',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_CREDENTIALS_INFRA_ACCOUNT: {
      URL: '/hioapi/v1/provider/~/infra-account/~',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_CREDENTIALS_INFRA_ACCOUNT: {
      URL: '/hpcapi/v3/provider/~/infra-account/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_VMS: {
      URL: '/hioapi/v1/vmware/~/virtual-machine',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_VM_DATA_CENTERS: {
      URL: '/hioapi/v1/vmware/~/datacenter',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_VM_CLUSTERS: {
      URL: '/hioapi/v1/vmware/~/cluster~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_VM_FOLDERS: {
      URL: '/hioapi/v1/vmware/~/folder~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_VM_CLUSTER_RESOURCE_POOL: {
      URL: '/hioapi/v1/vmware/~/resource-pool~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_VM_CLUSTER_HOST: {
      URL: '/hioapi/v1/vmware/~/host~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_VM_CLUSTER_DATASTORE: {
      URL: '/hioapi/v1/vmware/~/datastore~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_VM_DISK_IMAGE: {
      URL: '/hioapi/v1/vmware/~/library-item',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_VM_DC_NETWORK: {
      URL: '/hioapi/v1/vmware/~/network~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    POST_VIRTUAL_DEVICES: {
      URL: '/hioapi/v1/provider/~/virtual-device',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    DEL_VIRTUAL_DEVICES: {
      URL: '/hioapi/v1/provider/~/virtual-device/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    DEPLOY_VIRTUAL_DEVICES: {
      URL: '/hioapi/v1/provider/~/deploy/virtual-device/~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    POWER_ON_OFF_VIRTUAL_DEVICES: {
      URL: '/hioapi/v1/provider/~/virtual-device/~/power/~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    CREATE_LOGICAL_PARTITION: {
      URL: '/hpcapi/v3/provider/~/tenant/~/logical-partition',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_LOGICAL_PARTITION: {
      URL: '/hpcapi/v3/provider/~/tenant/~/logical-partition/~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_LOGICAL_PARTITION: {
      URL: '/hpcapi/v3/provider/~/tenant/~/logical-partition/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_LOGICAL_PARTITION_COUNT: {
      URL: '/hpcapi/v3/provider/~/tenant/~/logical-partition/?total=true',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_ACTIVE_APP_SERVICE_COUNT: {
      URL: '/applications/count?associationstate=active',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    CREATE_LP_SUBSCRIBER: {
      URL: '/hpcapi/v3/subscriber',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    DELETE_LP_SUBSCRIBER: {
      URL: '/hpcapi/v3/subscriber/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_CLUSTER_PARTITION: {
      URL: '/hpcapi/v3/provider/~/cluster/~/partition',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    CREATE_RE_PROVISION: {
      URL: '/hocapi/v1/provider/~/cluster/~/_provision/',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_LOGICAL_PARTITION: {
      URL: '/hpcapi/v3/uuid/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_THUNDER_APP_NAME: {
      URL: '/reg/o/~',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    REMOVE_TENANT_PARTITION_ASSOCIATION: {
      URL: '/reg/o/partition/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    ACTIVE_RULE_SET: {
      URL: '/analytics/thunder-adc/rule_set',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_LICENSE_MANAGER_INFO: {
      URL: '/hls/licenses/providers/lmconfig',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    CHECK_DEVICE_LICENSE: {
      URL: '/hocapi/v1/provider/~/device/~/_get_license',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_LICENSE: {
      URL: '/hpcapi/v3/provider/~/device/~/licenseinfo',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    ASSIGN_DEVICE_LICENSE: {
      URL: '/hpcapi/v3/provider/~/device/~/licenseinfo',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    CREATE_PROVIDER_ADMIN: {
      URL: '/users/provideradmin',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    CHECK_AZURE_PRIVATE_IP_AVAILABILITY: {
      URL: '/hioapi/v1/azure/~/private-ip-address/check-availability',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    CHECK_AWS_PRIVATE_IP_AVAILABILITY: {
      URL: '/hioapi/v1/aws/~/private-ip-address/check-availability?region=~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_AWS_REGIONS: {
      URL: '/hioapi/v1/aws/~/region',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_AWS_ZONES: {
      URL: '/hioapi/v1/aws/~/availability-zone?region=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_AWS_VPCS: {
      URL: '/hioapi/v1/aws/~/vpc?region=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_AWS_IMAGES: {
      URL: '/hioapi/v1/aws/~/image?region=~&name=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_AWS_ELASTIC_IPS: {
      URL: '/hioapi/v1/aws/~/elastic-ip-address?region=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_AWS_SECURITY_GROUPS: {
      URL: '/hioapi/v1/aws/~/security-group?region=~&vpc=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_AWS_SUBNETS: {
      URL: '/hioapi/v1/aws/~/subnet?region=~&vpc=~&zone=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_RBAC_ROLE_CLASSES: {
      URL: '/hpcapi/v3/hcms-rba/role-class',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_RBAC_ACCESS_GROUPS: {
      URL: '/providers/~/access-groups',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_RBAC_ROLES: {
      // URL: '/hpcapi/v3/hcms-rba/role',
      URL: '/hpcapi/v3/provider/~/role',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_USERS_RBAC_ROLES: {
      URL: '/providers/~/rbac',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_USER_RBAC_ROLES: {
      URL: '/providers/~/rbac/user/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    CREATE_USER_RBAC_ROLE: {
      URL: '/providers/~/rbac',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_USER_RBAC_ROLE: {
      URL: '/providers/~/rbac/user/~',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_USER_RBAC_ROLE: {
      URL: '/providers/~/rbac/user/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_AZURE_LOCATIONS: {
      URL: '/hioapi/v1/azure/~/location',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_AZURE_RESOURCE_GROUPS: {
      URL: '/hioapi/v1/azure/~/resource-group?location=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_AZURE_VNETS: {
      URL: '/hioapi/v1/azure/~/virtual-network?location=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_AZURE_PRIVATE_IMAGES: {
      URL: '/hioapi/v1/azure/~/private-image?location=~&name=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_AZURE_MARKETPLACE_IMAGES: {
      URL: '/hioapi/v1/azure/~/marketplace-image?location=~&name=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_AZURE_PUBLIC_IPS: {
      URL: '/hioapi/v1/azure/~/public-ip-address?location=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_AZURE_NETWORK_SECURITY_GROUPS: {
      URL: '/hioapi/v1/azure/~/network-security-group?location=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_AZURE_SUBNETS: {
      URL: '/hioapi/v1/azure/~/subnet?vnet=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    CHECK_AZURE_MARKETPLACE_IMAGES_TERMS: {
      URL: '/hioapi/v1/azure/~/marketplace-image/check-terms',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_K8S_NODES: {
      URL: '/hioapi/v1/k8s/~/node',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_K8S_NAMESPACES: {
      URL: '/hioapi/v1/k8s/~/namespace',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_K8S_IMAGES: {
      URL: '/hioapi/v1/k8s/~/image?node=~&image=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_K8S_DEPLOYMENTS: {
      URL: '/hioapi/v1/k8s/~/deployment?namespace=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_K8S_NETWORK_ATTACHMENT_DEFS: {
      URL: '/hioapi/v1/k8s/~/network-attachment-definition?namespace=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    CREATE_RBAC_ROLE: {
      // URL: '/hpcapi/v3/hcms-rba/role',
      URL: '/hpcapi/v3/provider/~/role',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_LOG_CAPACITY: {
      URL: '/analytics/configuration/_provider/lograte',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    UPDATE_PROVIDER_LOG_CAPACITY: {
      URL: '/analytics/configuration/_provider/lograte',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    DELETE_PROVIDER_LOG_CAPACITY: {
      URL: '/analytics/configuration/_provider/lograte/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_LOG_CAPACITY: {
      URL: '/analytics/configuration/requests/capacity',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GET_LOG_RATE_ANALYTICS: {
      URL: '/analytics/thunder-adc/system_telemetry_log_partition_metrics/',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    RUN_CLI_SNIPPET: {
      URL: '/hocapi/v1/provider/~/cli_snippet/_run',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_INTERFACE_SYSTEM: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/system',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_ETHERNET_INTERFACE: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/interface/ethernet',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_ETHERNET_INTERFACE_UPDATE: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/interface/ethernet/~?action=savedeploy',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_INTERFACE_VESTATS: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/network/ve-stats',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_INTERFACE_IP_TCP: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/ip/tcp',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_INTERFACE_SYSTEM_JUMBO: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/system-jumbo-global',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_INTERFACE_VE: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/interface/ve',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_INTERFACE_VE_DELETE: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/interface/ve/~/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_INTERFACE_LIF: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/interface/lif',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_INTERFACE_LIF_DELETE: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/interface/lif/~/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_INTERFACE_TUNNEL: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/interface/tunnel',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_INTERFACE_TUNNEL_DELETE: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/interface/tunnel/~/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_INTERFACE_TRUNK: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/interface/trunk',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_NETWORK_TRUNK: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/network/trunk',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_INTERFACE_TRUNK_DELETE: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/interface/trunk/~/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_INTERFACE_MANAGEMENT: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/interface/management',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_ROUTING_PROTOCOL: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/router',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_ROUTING_PROTOCOL_BFD: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/network/bfd',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_SECONDARY_IPS: {
      URL:
        '/hioapi/v1/provider/~/virtual-device/~/virtual-nic/~/secondary-ip-address',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    CREATE_SECONDARY_IP: {
      URL:
        '/hioapi/v1/provider/~/virtual-device/~/virtual-nic/~/secondary-ip-address',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_SECONDARY_IP: {
      URL:
        '/hioapi/v1/provider/~/virtual-device/~/virtual-nic/~/secondary-ip-address',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_SECONDARY_IP: {
      URL:
        '/hioapi/v1/provider/~/virtual-device/~/virtual-nic/~/secondary-ip-address/~?force_delete=~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },

    GET_CMD_HISTORY_COUNTS: {
      URL: '/hocapi/v1/provider/~/cli_snippet/_history',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    LIST_CMD_SNIPPET_HISTORY: {
      URL: '/hocapi/v1/provider/~/cli_snippet/_history?limit=~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_CLI_SNIPPETS: {
      URL: '/hocapi/v1/provider/~/cli_snippet/',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    POST_CLI_SNIPPET: {
      URL: '/hocapi/v1/provider/~/cli_snippet/',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    PUT_CLI_SNIPPET: {
      URL: '/hocapi/v1/provider/~/cli_snippet/',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_CLI_SNIPPET: {
      URL: '/hocapi/v1/provider/~/cli_snippet/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_SCHEDULE_TASKS: {
      URL: '/hocapi/v1/provider/~/cli_snippet/_schedule',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    POST_SCHEDULE_TASKS: {
      URL: '/hocapi/v1/provider/~/cli_snippet/_schedule',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    PUT_SCHEDULE_TASKS: {
      URL: '/hocapi/v1/provider/~/cli_snippet/_schedule',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_SCHEDULE_TASKS: {
      URL: '/hocapi/v1/provider/~/cli_snippet/_schedule/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_SCHEDULE_JOBS: {
      URL: '/hocapi/v1/provider/~/cli_snippet/_jobs',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_SCHEDULE_JOB_INFO: {
      URL: '/hocapi/v1/provider/~/cli_snippet/_jobs/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_PARTITION_DEPLOY_AXAPI: {
      URL: '/hocapi/v1/provider/~/device/~/partition/~/_deploy/',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    DEVICE_BOOT_IMAGE: {
      URL: '/hocapi/v1/provider/~/device/~/_boot_image/',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_VLANS: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/network/vlan~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_DEVICE_VLAN: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/network/vlan/~?action=deploy~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_ARP_GLOBAL: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/network/arp-timeout',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_VLAN_GLOBAL: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/network/vlan-global',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_NETWORK_MAC_AGE_TIME: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/network/mac-age-time',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_NETWORK_LLDP: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/network/lldp',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_DEVICE_IP_ARP: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/network/arp/static~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_IP_ARP: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/network/arp/static~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_DEVICE_IP_ARP: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/network/arp/static/~?action=deploy~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_IP_DYNAMIC_ARP: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/network/arp/dynamic/oper~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_IPV6_ARP: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/ipv6/neighbor/static~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_DEVICE_IPV6_ARP: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/ipv6/neighbor/static~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_DEVICE_IPV6_ARP: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/ipv6/neighbor/static/~?action=deploy~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_IPV6_DYNAMIC_ARP: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/ipv6/neighbor/dynamic/oper~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_IPV4_RIB: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/ip/route/rib~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_DEVICE_IPV4_RIB: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/ip/route/rib/~?action=deploy~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_IPV6_RIB: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/ipv6/route/rib~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_DEVICE_IPV6_RIB: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/ipv6/route/rib/~?action=deploy~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_IPV4_BFD: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/ip/route/static/bfd~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_DEVICE_IPV4_BFD: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/ip/route/static/bfd/~?action=deploy~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_BFD_IPV6: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/ipv6/route/static/bfd/bfd-ipv6~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_DEVICE_BFD_IPV6: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/ipv6/route/static/bfd/bfd-ipv6/~?action=deploy~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_BFD_ETHERNET: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/ipv6/route/static/bfd/ethernet/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_DEVICE_BFD_ETHERNET: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/ipv6/route/static/bfd/ethernet/~?action=deploy~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_BFD_TRUNK: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/ipv6/route/static/bfd/trunk~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_DEVICE_BFD_TRUNK: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/ipv6/route/static/bfd/trunk/~?action=deploy~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_BFD_VE: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/ipv6/route/static/bfd/ve~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_DEVICE_BFD_VE: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/ipv6/route/static/bfd/ve/~?action=deploy~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_BFDU_GROUP: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/network/bpdu-fwd-group~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_DEVICE_BFDU_GROUP: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/network/bpdu-fwd-group/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_ACCESS_CONTROL: {
      URL: '/hpcapi/v3/provider/~/device/~/partition/~/enable-management',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_MAC_FORWARDING: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/network/mac-address/dynamic/oper~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_MAC_FORWARDING_STATS: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/network/mac-address/dynamic',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },

    GET_LICENSE_MANAGER_DETAILS: {
      URL: '/hls/llm/lmconfig',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_LLM_UUID: {
      URL: '/hls/llm/uuid',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    ACTIVATE_LLM_UUID: {
      URL: '/hls/llm/activate',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GET_LLM_STATUS: {
      URL: '/hls/llm/status',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    UPDATE_LICENSE_MANAGER_INFO: {
      URL: '/hls/llm/lmconfig',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: false,
    },
    CHECK_LICENSE_MANAGER_INFO: {
      URL: '/hls/llm/lmconfig/check',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GET_LM_LICENSES: {
      URL: '/hls/llm/licenses',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    UPDATE_LLM_LICENSE: {
      URL: '/hls/llm/licenses/~',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    GET_LM_REVOKED_LICENSES: {
      URL: '/hls/llm/licenses/revoked',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    IMPORT_LLM_LICENSE: {
      URL: '/hls/llm/licenses/import',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GET_LLM_LICENSE_USAGE: {
      URL: '/hls/llm/licenses/~/usages',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_LLM_LICENSE_ACTIVATIONS: {
      URL: '/hls/llm/licenses/~/activations',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_LLM_LICENSE_ACTIVATION_INFO: {
      URL: '/hls/llm/licenses/~/activations/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_LLM_ACTIVATION_USAGE: {
      URL: '/hls/llm/activations/~/usages',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_LLM_LICENSE_REVOKED_ACTIVATIONS: {
      URL: '/hls/llm/licenses/~/activations/revoked',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    REVOKE_LLM_LICENSE: {
      URL: '/hls/llm/licenses/~/revoke',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    REVOKE_LLM_ACTIVATION: {
      URL: '/hls/llm/activations/~/revoke',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    UNREVOKE_LLM_ACTIVATION: {
      URL: '/hls/llm/activations/~/unrevoke',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    POST_ROLE: {
      URL: '/providers/~/roles',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    LIST_ROLE: {
      URL: '/providers/~/roles',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    POST_ACCESSGROUP_ROLE: {
      URL: '/providers/~/access-groups',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    PUT_ACCESSGROUP_ROLE: {
      URL: '/providers/~/access-groups/~',
      METHOD: 'PUT',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_ACCESSGROUP_ROLE: {
      URL: '/providers/~/access-groups/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_DEVICE_MAC_FORWARDING: {
      URL:
        '/hpcapi/v3/provider/~/device/~/partition/~/network/mac-address/static/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_OCI_REGIONS: {
      URL: '/hioapi/v1/oci/~/region',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_OCI_AVAILABILITY_DOMAINS: {
      URL: '/hioapi/v1/oci/~/availability-domain?tenancy-id=~&region=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_OCI_COMPARTMENTS: {
      URL: '/hioapi/v1/oci/~/compartment?tenancy-id=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_OCI_VCNS: {
      URL: '/hioapi/v1/oci/~/virtual-cloud-network?compartment-id=~&region=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_OCI_IMAGES: {
      URL: '/hioapi/v1/oci/~/image?compartment-id=~&region=~&name=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_OCI_SHAPES: {
      URL: '/hioapi/v1/oci/~/shape?compartment-id=~&region=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_OCI_SUBNETS: {
      URL: '/hioapi/v1/oci/~/subnet?compartment-id=~&vcn-id=~&region=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_OCI_PUBLIC_IPS: {
      URL: '/hioapi/v1/oci/~/public-ip-address?compartment-id=~&region=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_OCI_NETWORK_SECURITY_GROUPS: {
      URL:
        '/hioapi/v1/oci/~/network-security-group?compartment-id=~&vcn-id=~&region=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    CHECK_OCI_PRIVATE_IP_AVAILABILITY: {
      URL: '/hioapi/v1/oci/~/private-ip-address/check-availability?region=~',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_MGMT_SCHEDULED_JOBS_FOR_STATE: {
      URL:
        '/hpcapi/v3/provider/~/device-mgmt-scheduled-job?operation=~&job-state=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_EXECUTED_UPGRADES: {
      URL: '/hpcapi/v3/provider/~/device-image',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    DELETE_SCHEDULED_JOBS: {
      URL: '/scheduler/v1/job/~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    PROVIDER_APP_SVC_LIST: {
      URL: '/applications/provider/~/app-services',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    CHECK_DEVICE_LICENSE_STATE: {
      URL: '/hls/licenses/providers/~/device/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    USER_RESET_MFA: {
      URL: '/mfa/reset',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GET_PODS: {
      URL: '/oc/v1/cluster/pods',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_COMPONENTS: {
      URL: '/oc/v1/components',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_OVERALL_USAGE: {
      URL: '/prometheus/api/v1/query?query=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_TIME_USAGE: {
      URL: '/prometheus/api/v1/query_range?query=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_DEVICE_COMMUNICATION: {
      URL: '/oc/v1/config/kv/jwt?key=jwt_exp_duration',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    UPDATE_DEVICE_CONFIG: {
      URL: '/oc/v1/config/kv/jwt',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GET_EMAIL_TEMPLATE: {
      URL:
        '/oc/v1/config/list?keys=activationEmail,activationEmailSubject,welcomeEmail,welcomeEmailSubject,resetEmail,resetEmailSubject',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    UPDATE_EMAIL_TEMPLATE: {
      URL: '/oc/v1/config/list',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GET_API_SERVER: {
      URL: '/oc/v1/hc/properties/components/API%20Server',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_ANALYTICS_CONFIG: {
      URL:
        '/oc/v1/hc/properties/components/Analytics%20Metrics%20Processing%20Engine',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_SERVICE_LOG_URL: {
      URL: '/oc/v1/components/loglevel',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    POST_SERVICE_LOG_URL: {
      URL: '/oc/v1/command',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GET_LOG_RATE: {
      URL: '/analytics/configuration/_provider/lograte',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    POST_CONFIG: {
      URL: '/oc/v1/hc/properties',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GET_NTP_DETAILS: {
      URL: '/oc/v1/config/ntpd',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_NTP_STATUS: {
      URL: '/oc/v1/config/ntpd/status',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    CHECK_NTP_STATUS: {
      URL: '/oc/v1/config/ntpd/status?id=~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    POST_NTP_DETAILS: {
      URL: '/oc/v1/config/ntpd',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GET_CERT_DATA: {
      URL: '/oc/v1/config/ingress_certificate?names=ingress',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    UPDATE_CERT_DATA: {
      URL: '/oc/v1/config/ingress_certificate',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    RESET_CERT_DATA: {
      URL: '/oc/v1/config/ingress_certificate',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: false,
    },

    GET_EMAIL_SERVER: {
      URL:
        '/oc/v1/config/mail?keys=mailsmtphost,mailsmtpport,mailfromaddress,mailfrompersonal,mailserverusername,mailserverpassword,mailtoaddress',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    UPDATE_EMAIL_SERVER: {
      URL: '/oc/v1/config/mail',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    SEND_EMAIL: {
      URL: '/oc/v1/config/mail/sendtestemail',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    RESET_EMAIL_SERVER: {
      URL: '/oc/v1/config/mail',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: false,
    },
    GET_MICRO_SERVICE_LOGS: {
      URL: '/oc/v1/hclogs/groups',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    POST_MICRO_SERVICE_LOGS_DOWNLOAD: {
      URL: '/oc/v1/hclogs',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GET_MICRO_SERVICE_LOGS_DOWNLOAD: {
      URL: '/oc/v1/hclogs/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_RUN_TARGETS: {
      URL: '/oc/v1/runcommand?targets=true',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_RUN_COMMANDS: {
      URL: '/oc/v1/runcommand?~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    POST_RUN_COMMAND: {
      URL: '/oc/v1/runcommand',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: false,
    },
    GET_RUN_COMMAND_STATUS: {
      URL: '/oc/v1/runcommand/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    LOAD_LDAP_GROUP: {
      URL: '/ldap/ldapgroups',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: false,
    },
    GET_ENTITLEMENTS: {
      URL: '/hls/licenses/providers/~/entitlements?type=services',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_REMAINING_BACKUPS: {
      URL: '/hls/licenses/providers/~/entitlements/~',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_USER_ENTITLEMENTS: {
      URL: '/hls/licenses/providers/~/entitlements?type=user',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    RESET_EMAIL_TEMPLATE: {
      URL: '/oc/v1/config/list?keys=~',
      METHOD: 'DELETE',
      NEEDED_QUERYSTRING: true,
    },
    GET_LICENSE_ENTITLEMENTS: {
      URL: '/hls/licenses/providers/~/entitlements',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    GET_USER_PREF_URL: {
      URL: '/hpcapi/v3/provider/~/user/~/preferences',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
    POST_USER_PREF_URL: {
      URL: '/hpcapi/v3/provider/~/user/~/preferences',
      METHOD: 'POST',
      NEEDED_QUERYSTRING: true,
    },
    GET_LOGICAL_PARTITIONS_SUMMARY: {
      URL: '/hpcapi/v3/provider/~/tenant/~/logical-partition?summary=true',
      METHOD: 'GET',
      NEEDED_QUERYSTRING: true,
    },
  }

  getURLS = (url: string) => {
    return this.URLS[url]
  }
}

export default URLConstants
