import React from 'react'
import { Map } from 'immutable'
import propTypes from 'prop-types'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10Modal,
  A10DropdownMenu,
  A10Message,
  A10Tooltip,
  A10Icon,
  A10Button,
  A10Loader,
} from '@gui-libraries/widgets'
import { AutoForm } from '@gui-libraries/apps'

import LogicalPartitionDetail from './LogicalPartitionDetail'
import WorkflowTooltip from 'src/components/shared/WorkflowTooltip'
import storage from 'src/libraries/storage'
import httpClient from 'src/libraries/httpClient'
import SlidingLPDeploy from 'src/components/shared/LogicalPartitionDeploy/slidingForm'
import SlidingVersionCompare from 'src/components/shared/VersionComparison/slidingForm'
import SlidingWorkflowStatus from 'src/components/shared/WorkflowStatus/slidingForm'
import SlidingWorkflowStatusList from 'src/components/shared/WorkflowStatus/slidingWorkflowListForm'
import SlidingPartitionComment from 'src/components/shared/PartitionComment/slidingForm'
import SlidingPartitionDeployAll from 'src/components/shared/PartitionDeployAll/slidingForm'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { ObjectExplorer } from 'src/components/shared/ObjectExplorer'
import { Utilities } from 'src/services/Utilities'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

export interface IPartitionListProps extends IA10ContainerDefaultProps {
  showStats: boolean
  tenant: string
  data: IObject
  isLoading?: boolean
  updateList: any
  filteredData: IObject[]
  pagination?: (page: number) => {}
}

export interface IPartitionListState {
  showAssociateDeploySlidingPage: boolean
  showDeployAllSlidingPage: boolean
  showLogicalPartitionSlidingPage: boolean
  showVersionCompareSlidingPage: boolean
  showWorkflowStatusSlidingPage: boolean
  showWorkflowStatusListSlidingPage: boolean
  showSaveCommentSlidingPage: boolean
  showGlobalObjectExplorerSlidingPage: boolean
  logicalPartition: any
  mouseOverRowId: string
  deleteModalState: boolean
  clusterName: string
  lpName: string | null
  dirtyList: IObject[]
  workflowID: string | IObject[]
  refreshIndex: number
  hcAppsLoaded: boolean
}

class PartitionList extends A10Container<
  IPartitionListProps,
  IPartitionListState
> {
  static contextTypes = {
    openSlidingPage: propTypes.func.isRequired,
    refreshLogicalPartition: propTypes.func.isRequired,
  }
  defaultPartitionColumns: any[]

  appTypeMap = {
    adc: 'ADC',
    cgnv6: 'CGNv6',
    none: 'None',
  }

  Utilities = new Utilities()
  allApps: any = []
  isOperatorUser = storage.get.IS_OPERATOR_USER
  ref = React.createRef()

  constructor(props: IPartitionListProps) {
    super(props)
    this.state = {
      showAssociateDeploySlidingPage: false,
      showDeployAllSlidingPage: false,
      showLogicalPartitionSlidingPage: false,
      showVersionCompareSlidingPage: false,
      showWorkflowStatusSlidingPage: false,
      showWorkflowStatusListSlidingPage: false,
      showSaveCommentSlidingPage: false,
      showGlobalObjectExplorerSlidingPage: false,
      logicalPartition: undefined,
      mouseOverRowId: '',
      deleteModalState: false,
      clusterName: '',
      lpName: '',
      dirtyList: [],
      workflowID: '',
      refreshIndex: -1,
      hcAppsLoaded: false,
    }
    this.defaultPartitionColumns = [
      {
        title: 'Logical Partition',
        dataIndex: 'name',
        key: 'name',
        render: (text: any, record: any) => {
          return (
            <WorkflowTooltip
              objUUID={record.uuid}
              workflowType="DEPLOY"
              title={text}
              size={1}
              parent={record}
              viewWorkflow={this.onViewWorkflowStatus}
            />
          )
        },
      },
      {
        title: 'Cluster: Partition',
        render: (text: any, record: any) => {
          return record.cluster && record.partition ? (
            record.cluster + ' : ' + record.partition
          ) : (
            <span className={styles.emptyDisplay}>Not Associated</span>
          )
        },
      },
      {
        title: 'Application Type',
        render: (text: any, record: any) => {
          return record['app-type'] && record['app-type'] !== 'none' ? (
            this.appTypeMap[record['app-type']]
          ) : (
            <span className={styles.emptyDisplay}>None</span>
          )
        },
      },
      {
        title: '',
        render: (text: any, record: any) => {
          let menuItems: any = []
          if (!this.isOperatorUser) {
            menuItems.push(<div key="openExplorer">Explore Config</div>)
          }
          if (!this.isOperatorUser) {
            menuItems.push(<hr key="hr" />)
            // menuItems.push(<div key="edit">Edit</div>) // HARMONY-22008 - Hiding edit LP
            if (record.partition) {
              menuItems.push(
                <div
                  key="delete"
                  style={{ cursor: 'not-allowed', opacity: 0.6 }}
                >
                  Delete
                </div>,
              )
            } else {
              menuItems.push(<div key="delete">Delete</div>)
            }
          }
          const adcApps = this.allApps.filter((app: any) => {
            return app.name === 'adc' || app.name === 'slb'
          })
          if (record['app-type'] !== 'cgnv6') {
            adcApps.map((obj: any, index: number) => {
              menuItems.push(
                <div
                  onClick={() =>
                    this.Utilities.launchAppWithLogicalPartition(
                      obj,
                      record.name,
                    )
                  }
                >
                  {'Open ' + obj.name + ' ' + obj.version}
                </div>,
              )
            })
          }

          return menuItems.length > 0 ? (
            <div className="table-dropdown">
              <A10DropdownMenu
                title=""
                menu={menuItems}
                onClick={this.onClickAction.bind(this, record)}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            </div>
          ) : null
        },
      },
    ]
  }

  componentDidMount() {
    this.loadMyApps()
  }

  componentDidUpdate() {
    if (this.state.refreshIndex > -1) {
      this.setState({ refreshIndex: -1 })
    }
  }

  loadMyApps = async () => {
    const {
      get: { TARGET_URL: targetUrl },
    } = storage

    let myAppsResponse = this.Utilities.getLicensedApps(targetUrl)
    myAppsResponse
      .then((response: any) => {
        this.allApps = response
        this.setState({ hcAppsLoaded: true })
      })
      .catch((error: any) => {
        console.log(error.response)
      })
  }

  enableOpenExplorer(data: any) {
    const {
      get: { CURRENT_LP_SVC: logicalPartitionSvc, CURRENT_TENANT: tenant },
      set,
      remove,
    } = storage

    if (data.length > 0 && !!logicalPartitionSvc) {
      remove.CURRENT_LP_SVC()
      let record: any = data.find((obj: any) => {
        if (obj.uuid === logicalPartitionSvc.lp_uuid) {
          return obj
        }
      })
      if (!record) {
        return false
      }
      record.tenant = tenant
      set['object.explorer.tenant.text'](tenant.name)
      this.setState({
        showGlobalObjectExplorerSlidingPage: true,
        logicalPartition: record,
      })
    }
  }

  onClickAction = (record: IObject, component: JSX.Element) => {
    const {
      get: { CURRENT_TENANT: tenant },
      set,
    } = storage

    // HARMONY-22008 - Hiding edit LP
    // if (component.key === 'edit') {
    //   this.onTriggerPartitionForm(true, record)
    // } else
    if (component.key === 'save' && record.dirty) {
      this.setState({
        showSaveCommentSlidingPage: true,
        logicalPartition: record,
      })
    } else if (
      component.key === 'deployAll' &&
      record.dirty !== 1 &&
      record.partition
    ) {
      this.setState({
        showDeployAllSlidingPage: true,
        logicalPartition: record,
      })
    } else if (component.key === 'version') {
      this.setState({
        showVersionCompareSlidingPage: true,
        logicalPartition: record,
      })
    } else if (!record.partition && component.key === 'delete') {
      this.setState({ deleteModalState: true, lpName: record.name })
    } else if (component.key === 'openExplorer') {
      record.tenant = tenant
      set['object.explorer.tenant.text'](tenant.name)
      this.setState({
        showGlobalObjectExplorerSlidingPage: true,
        logicalPartition: record,
      })
    }
  }

  handleOk = async (e: IObject) => {
    const { updateList, tenant } = this.props
    const { lpName } = this.state
    const headers = { tenant }
    try {
      await httpClient.delete(
        `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${lpName}`,
        { headers },
      )
      updateList()
      this.setState({ deleteModalState: false })
    } catch (e) {
      // do nothing
      A10Message.error('Error: Failed to delete the logical Partition')
    }
  }

  handleCancel = (e: IObject) => {
    this.setState({ deleteModalState: false })
  }

  onCloseDeployWindow = (
    clickDeploy: boolean = false,
    hasDP: boolean = true,
    workflowID?: string,
  ) => {
    const { updateList } = this.props
    const { logicalPartition } = this.state
    let slidingPageState = {
      showDeployAllSlidingPage: false,
    }
    if (!hasDP) {
      slidingPageState = {
        showAssociateDeploySlidingPage: false,
      }
    }
    if (clickDeploy && workflowID) {
      this.setState(slidingPageState, () => {
        setTimeout(() => {
          this.setState({ showWorkflowStatusSlidingPage: true, workflowID })
        }, 1000)
      })
    } else {
      if (hasDP) {
        this.setState({ showDeployAllSlidingPage: false })
      } else {
        this.setState({ showAssociateDeploySlidingPage: false })
      }
      updateList( logicalPartition.name)
    }
  }

  onCloseDeployAllWindow = (
    clickDeploy: boolean = false,
    workflowID?: string,
  ) => {
    const { updateList } = this.props
    const { logicalPartition } = this.state
    if (clickDeploy && workflowID) {
      this.setState({ showDeployAllSlidingPage: false }, () => {
        setTimeout(() => {
          this.setState({
            showWorkflowStatusListSlidingPage: true,
            workflowID,
          })
        }, 1000)
      })
    } else {
      this.setState({ showDeployAllSlidingPage: false })
      updateList(logicalPartition.name)
    }
  }

  onViewWorkflowStatus = (id: string, partition: IObject) => {
    this.setState({
      logicalPartition: partition,
      workflowID: id,
      showWorkflowStatusSlidingPage: true,
    })
  }

  onCloseWorkflowStatusWindow = () => {
    this.setState({ showWorkflowStatusSlidingPage: false })
  }

  onCloseWorkflowStatusListWindow = () => {
    const { logicalPartition } = this.state
    this.setState({
      showWorkflowStatusListSlidingPage: false,
      refreshIndex: logicalPartition.key,
    })
  }

  onCloseVersionCompareWindow = (
    clickDeploy: boolean = false,
    workflowID?: string,
  ) => {
    const {updateList } = this.props
    if (clickDeploy && workflowID) {
      this.setState({ showVersionCompareSlidingPage: false }, () => {
        setTimeout(() => {
          this.setState({ showWorkflowStatusSlidingPage: true, workflowID })
        }, 1000)
      })
    } else {
      this.setState({ showVersionCompareSlidingPage: false })
      updateList('')
    }
  }

  onClickSaveInVersionCompareWindow = () => {
    this.setState({
      showVersionCompareSlidingPage: false,
      showSaveCommentSlidingPage: true,
    })
  }

  onCloseSaveCommentWindow = async (continueDeploy: boolean = false) => {
    const {  updateList } = this.props
    const { logicalPartition } = this.state
    if (continueDeploy) {
      const {
        data: { 'logical-partition': record },
      } = await httpClient.get(logicalPartition['a10-url'])
      this.setState(
        { logicalPartition: record, showSaveCommentSlidingPage: false },
        () => {
          setTimeout(() => {
            this.setState({ showAssociateDeploySlidingPage: true })
          }, 1000)
        },
      )
    } else {
      this.setState({ showSaveCommentSlidingPage: false })
      updateList('')
    }
  }

  onCloseExplorerWindow = () => {
    const { updateList } = this.props
    this.setState({ showGlobalObjectExplorerSlidingPage: false }, () => {
      updateList('')
    })
  }

  onTriggerPartitionForm = (
    isOpen: boolean,
    record?: IObject,
    needRefresh?: boolean,
  ) => {
    const { tenant } = this.props
    const {
      get: { PROVIDER },
    } = storage
    const defaultParams: IObject = {
      provider: PROVIDER,
      tenant,
    }
    if (record) {
      defaultParams.name = record.name
      defaultParams.from = record.from
      defaultParams.uuid = record.uuid
      defaultParams.cluster = record.cluster
      defaultParams.partition = record.partition
    }
    const apiPrefix = `/hpcapi/v3/provider/${PROVIDER}/tenant/${tenant}/`
    this.context.openSlidingPage(
      isOpen ? (
        <AutoForm
          hccEnv={true}
          schemaPath="config/form/provider.tenant.logical-partition"
          apiPrefix={apiPrefix}
          params={defaultParams}
          interceptor={{
            onSubmitSuccess: async (
              sformUtils: any,
              response: any,
              name: any,
              formData: Map<string, any>,
            ) => {
              this.context.openSlidingPage(null)
              this.context.refreshLogicalPartition(record.name)
            },
            onCancel: () => {
              this.context.openSlidingPage(null)
            },
          }}
        />
      ) : null,
    )
  }

  renderDetail = (record: any, index: number) => {
    return (
      <LogicalPartitionDetail
        data={record}
        refreshIndex={this.state.refreshIndex}
        index={index}
        updateList={this.props.updateList}
      />
    )
  }

  onTableRow = (record: any) => {
    /* istanbul ignore next */
    return {
      onMouseEnter: () => {
        this.setState({ mouseOverRowId: record.key })
      },
    }
  }

  getRowKey = (record: IObject) => {
    return record.uuid
  }

  render() {
    const { filteredData = [] } = this.props
    const PartitionColumns = [...this.defaultPartitionColumns]
    if (filteredData.length > 0 && !this.state.showGlobalObjectExplorerSlidingPage) {
      this.enableOpenExplorer(filteredData)
    }
    return (
      <div ref={this.ref}>
        <A10Table
          className="hc-list"
          expandedRowRender={this.renderDetail}
          rowKey={this.getRowKey}
          columns={PartitionColumns}
          dataSource={filteredData.map((item: IObject, index: number) => {
            item.key = index
            return item
          })}
          loading={
            this.props.isLoading && {
              indicator: <A10Loader container={this.ref} />,
            }
          }
          pagination={{ hideOnSinglePage: true, pageSize: 15 }}
        />
        <A10Modal
          title="Confirmation"
          visible={this.state.deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>Do you want to delete this Item?</p>
        </A10Modal>
        <FormatSlidingPage
          isOpen={this.state.showGlobalObjectExplorerSlidingPage}
          title="Explorer"
          description={
            <>
              <div style={{ color: '#999999' }}>
                Tenant
                <A10Tooltip
                  title={
                    this.state.logicalPartition &&
                    this.state.logicalPartition.tenant
                      ? this.state.logicalPartition.tenant.name
                      : ''
                  }
                  placement="topLeft"
                >
                  <span className="titlePadFntWgt">
                    {this.state.logicalPartition &&
                    this.state.logicalPartition.tenant
                      ? this.state.logicalPartition.tenant.name.length > 20
                        ? this.state.logicalPartition.tenant.name.slice(0, 20) +
                          '...'
                        : this.state.logicalPartition.tenant.name
                      : ''}
                  </span>
                </A10Tooltip>
                <A10Icon
                  type="right"
                  className="iconPad open-explorer-breadcrumb-separator"
                />
                Logical Partition
                <span className="titlePadFntWgt">
                  {this.state.logicalPartition
                    ? this.state.logicalPartition.name
                    : ''}
                </span>
              </div>
            </>
          }
          onRequestClose={this.onCloseExplorerWindow}
          isShowFooterButtons={false}
          isRightCancel={true}
        >
          <ObjectExplorer
            singleLP={this.state.logicalPartition}
            baseWidthOfA10Drawer={95}
            offset={10}
          />
        </FormatSlidingPage>
        <SlidingLPDeploy
          type="logical"
          isOpen={this.state.showAssociateDeploySlidingPage}
          onRequestClose={this.onCloseDeployWindow}
          partition={this.state.logicalPartition}
        />
        <SlidingPartitionDeployAll
          isOpen={this.state.showDeployAllSlidingPage}
          onRequestClose={this.onCloseDeployWindow}
          partition={this.state.logicalPartition}
        />
        <SlidingVersionCompare
          isOpen={this.state.showVersionCompareSlidingPage}
          type="logical"
          onRequestClose={this.onCloseVersionCompareWindow}
          onClickSave={this.onClickSaveInVersionCompareWindow}
          partition={this.state.logicalPartition}
        />
        <SlidingWorkflowStatus
          isOpen={
            this.state.showWorkflowStatusSlidingPage &&
            this.state.workflowID !== '' &&
            !!this.state.logicalPartition
          }
          onRequestClose={this.onCloseWorkflowStatusWindow}
          source="LP"
          type="deploy"
          id={this.state.workflowID}
          showMessage={true}
          assocObject={this.state.logicalPartition}
        />
        <SlidingWorkflowStatusList
          isOpen={
            this.state.showWorkflowStatusListSlidingPage &&
            this.state.workflowID !== [] &&
            !!this.state.logicalPartition
          }
          onRequestClose={this.onCloseWorkflowStatusListWindow}
          idList={this.state.workflowID}
        />
        <SlidingPartitionComment
          isOpen={this.state.showSaveCommentSlidingPage}
          onRequestClose={this.onCloseSaveCommentWindow}
          type="logical"
          partition={this.state.logicalPartition}
        />
      </div>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    total: state.A10Data.getIn(getNS('LOGICAL_PARTITION_COUNT'), 0),
    data: state.A10Data.getIn(getNS('LOGICAL_PARTITION_LIST'), []),
  }
}

export default setupA10Container(PartitionList, mapStateToProps)
