import React from 'react'
import { A10Component } from '@gui-libraries/framework'

import { TasksStatusList, ITaskInfor } from '../TasksStatusList'
import { WorkflowDetailsInfor } from '../WorkflowDetailsInfor'

import './styles/workflowdetail.scss'

export interface IWorkflowDetailProps {
  startTime: number
  endTime: number
  message: string
  inputObj: IObject
  tasks: ITaskInfor[]
}

export interface IWorkflowDetailState {}

class WorkflowDetail extends A10Component<
  IWorkflowDetailProps,
  IWorkflowDetailState
> {
  static defaultProps: IWorkflowDetailProps = {
    startTime: null,
    endTime: null,
    message: null,
    inputObj: null,
    tasks: [],
  }
  constructor(props: IWorkflowDetailProps) {
    super(props)

    this.state = {}
  }

  render() {
    const { startTime, endTime, message, inputObj, tasks } = this.props

    return (
      <div className="list-detail-container">
        <div className="workflow-details-container">
          <WorkflowDetailsInfor
            startTime={startTime}
            endTime={endTime}
            message={message}
            inputObj={inputObj}
          />
        </div>
        <div className="tasks-status-container">
          <div className="tasks-status-header">
            <span className="tasks-status-title">Tasks Status</span>
          </div>
          <TasksStatusList taskList={tasks} visible={true} />
        </div>
      </div>
    )
  }
}

export default WorkflowDetail
export { ITaskInfor }
