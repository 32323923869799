import {
  A10Alert,
  A10Checkbox,
  A10Col,
  A10Dropdown,
  A10Form,
  A10Icon,
  A10Input,
  A10Row,
  A10Select,
  A10Table,
  A10Tree,
  A10Message,
} from '@gui-libraries/widgets'
import {
  A10Container,
  A10Context,
  IA10ContainerDefaultProps,
  _,
  setupA10Container,
} from '@gui-libraries/framework'
import {
  AuthenticationService,
  InfrastructureService,
  OrganizationService,
  Utilities,
} from 'src/services'

import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import A10Panel from 'src/components/ADC/A10Panel'
import { AppRoot } from 'src/settings/appRoot'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { Map } from 'immutable'
import React from 'react'
import { UserRole } from 'src/constants/Data/UserRole'
import globalConfig from 'src/settings/config'

import { HelpInfo } from 'src/components/shared/HelpInfo'
import { Messages } from 'src/locale/en/Messages'
import ActionButton from 'src/components/shared/ActionButton'
// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')
import {
  validateAdminDN,
  validateAdminPwd,
} from '../../Authentication/Forms/authConfigurationValidate'
import storage from 'src/libraries/storage'

export interface IAccessGroupFormProps extends IA10ContainerDefaultProps {
  isEdit: boolean
  formData: IFormData
  authResponse: IObject
  form: IObject
  onChange: (data: IFormData, isValid: boolean) => void
  accessroleList: IObject
}

export interface IAccessGroupFormStates {
  provider: string
  formData: IFormData
  roleList: IObjName[]
  permissionsObj: IObject
  selectAllObj: IObject
  isCheckAll: IObject
  checkedObjs: IObject
  tenantList: IObjName[]
  clusterList: IObjName[]
  deviceList: IObjName[]
  lpList: IObjName[]
  tenantChange: boolean
  tenantByName: Map<string, IObject>
  tenantLPAppSvcTree: ITreeNode[]
  clusterChange: boolean
  clusterByName: Map<string, IObject>
  clusterDeviceTree: ITreeNode[]
  expandedOrgRows: string[]
  expandedInfraRows: string[]
  expandedServRows: string[]
  admindn: string
  password: string
  ldapGroupData: IObject[]
  authResponseData: IObject
  isLoading: boolean
}

export interface IObjName {
  name: string
}

export interface ITreeNode {
  key: string
  title: string
  children?: ITreeNode[]
}

export interface IFormData {
  accessGroup: {
    name: string
    displayName: string
    description: string
    role: string
    resourceList: IObject
    ldapGroupDn: string
  }
}

class AccessGroupForm extends A10Container<
  IAccessGroupFormProps,
  IAccessGroupFormStates
> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  Utilities = new Utilities()
  authenticationService = new AuthenticationService()
  OrganizationService = new OrganizationService()
  InfrastructureService = new InfrastructureService()
  AppRoot = new AppRoot()
  UserRole = new UserRole()
  Messages = new Messages()
  defaultScopeColumns: any[]
  defaultObjectScopeColumns: any[]
  permissionMap: any
  accessroleList: IObject
  authResponse: IObject
  constructor(
    props: IAccessGroupFormProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)
    this.accessroleList = this.props?.accessroleList
    const {
      storage: {
        get: { PROVIDER },
      },
    } = context

    this.state = {
      provider: PROVIDER,
      formData: props.isEdit
        ? props.formData
        : {
          accessGroup: {
            name: '',
            displayName: '',
            description: '',
            role: '',
            resourceList: [],
            ldapGroupDn: '',
          },
        },
      roleList: [],
      tenantList: [],
      clusterList: [],
      deviceList: [],
      lpList: [],
      tenantChange: false,
      tenantByName: Map<string, IObject>(),
      tenantLPAppSvcTree: [],
      clusterChange: false,
      clusterByName: Map<string, IObject>(),
      clusterDeviceTree: [],
      expandedOrgRows: [],
      expandedInfraRows: [],
      expandedServRows: [],
      permissionsObj: {
        provider: '-',
        tenant: '-',
        cluster: '-',
        device: '-',
        lp: '-',
        appService: '-',
      },
      selectAllObj: {
        tenant: false,
        cluster: false,
        device: false,
        appService: false,
      },
      checkedObjs: {
        tenant: [],
        cluster: [],
        device: [],
        appService: [],
        lp: [],
      },
      isCheckAll: {
        tenant: false,
        cluster: false,
        device: false,
        appService: false,
      },
      admindn: '',
      password: '',
      ldapGroupData: [],
      authResponseData: [],
      isLoading: false,
    }
    this.permissionMap = {
      RO: 'Read Only',
      RW: 'Read & Write',
      NA: 'No Access',
    }
    this.defaultObjectScopeColumns = [
      {
        title: 'Object Scope',
        key: 'name',
        dataIndex: 'name',
        render: (text: string, record: any) => {
          return <div> {text} </div>
        },
      },
    ]

    this.defaultScopeColumns = [
      {
        title: 'Scope',
        key: 'name',
        sorter: (a: any, b: any) =>
          this.Utilities.sortStringKeys(a, b, 'name', 'name'),
        dataIndex: 'name',
        render: (text: string, record: any) => {
          return (
            <div>
              {text}
              {record.type === 'tenantInstance' &&
              !this.state.selectAllObj.tenant &&
              this.state.checkedObjs.tenant.length === 0 ? (
                <div
                  className={styles.warningIcon}
                  title="Select at least one tenant"
                />
              ) : record.type === 'appServiceInstance' &&
                !this.state.selectAllObj.appService &&
                this.state.checkedObjs.appService.length === 0 ? (
                <div
                  className={styles.warningIcon}
                  title="Select at least one app service"
                />
              ) : record.type === 'clusterInstance' &&
                !this.state.selectAllObj.cluster &&
                this.state.checkedObjs.cluster.length === 0 ? (
                <div
                  className={styles.warningIcon}
                  title="Select at least one cluster"
                />
              ) : null}
            </div>
          )
        },
      },
      {
        title: 'Instance',
        key: 'instance',
        dataIndex: 'instance',
        render: (text: string, record: any) => {
          if (text === 'All') {
            return 'All'
          } else {
            return this.renderInstanceSelection(record)
          }
        },
      },
      {
        title: 'Permission',
        key: 'permission',
        dataIndex: 'permission',
        render: (text: string, record: any) => {
          return this.permissionMap[text] || 'No Access'
        },
      },
    ] 
    this.getAuthType()
  }

  async componentDidMount() {
    const{ authResponse } = this.props
    if(authResponse?.scheme === 'searchDN' && authResponse?.['remote-authorization']){
      this.loadLdapGroup()
    }
    let tenantList: any[] = []
    await this.setClusterDevice()
    await this.getTenant().then(result => {
      let { tenantByName } = this.state
      tenantList = result.data['tenant-list'] || []
      tenantList.forEach((tenant: IObject) => {
        tenantByName = tenantByName.set(tenant.name, tenant)
      })
      this.setState({ tenantList, tenantByName })
    })

    if (this.props.isEdit) {
      const accessGroup = this.state.formData.accessGroup,
        deviceList = this.state.deviceList
      const checkedObjs: any = {
        tenant: [],
        cluster: [],
        device: [],
        lp: [],
        appService: [],
      }
      let permissionsObj = {
        provider: '-',
        tenant: '-',
        cluster: '-',
        device: '-',
        lp: '-',
        appService: '-',
      }
      const isCheckAll = {
        tenant: false,
        cluster: false,
        device: false,
        appService: false,
      }
      const { clusterDeviceTree } = this.state
      if (accessGroup?.resourceList) {
        const state = { ...this.state }
        let { selectAllObj } = state
        let isRoleChange = false
        const resourceObj = this.getRoleResources(
          accessGroup.resourceList,
          isRoleChange,
        )
        permissionsObj.provider = resourceObj.provider?.permission || '-'
        permissionsObj.cluster =
          resourceObj.cluster?.permission || permissionsObj.provider
        permissionsObj.device =
          resourceObj.device?.permission || permissionsObj.provider
        permissionsObj.tenant =
          resourceObj.tenant?.permission || permissionsObj.provider
        permissionsObj.lp = resourceObj.lp?.permission || permissionsObj.tenant
        permissionsObj.appService =
          resourceObj.appService?.permission || permissionsObj.tenant

        selectAllObj = {
          tenant: false,
          cluster: false,
          device: false,
          lp: false,
          appService: false,
        }
        let hasTenantSelectPattern = false,
          hasClusterSelectPattern = false,
          hasDeviceSelectPattern = false,
          hasAppServiceSelectPattern = false

        if (resourceObj.cluster) {
          hasClusterSelectPattern = true
        }
        if (resourceObj.device) {
          hasClusterSelectPattern = true
          hasDeviceSelectPattern = true
        }
        if (resourceObj.tenant) {
          hasTenantSelectPattern = true
        }
        if (resourceObj.appService) {
          hasTenantSelectPattern = true
          hasAppServiceSelectPattern = true
        }

        if (!state.selectAllObj.tenant && !hasTenantSelectPattern) {
          selectAllObj.tenant = true
          state.expandedOrgRows = []
        }
        if (!state.selectAllObj.cluster && !hasClusterSelectPattern) {
          selectAllObj.cluster = true
          state.expandedInfraRows = []
        }
        if (!state.selectAllObj.device && !hasDeviceSelectPattern) {
          selectAllObj.device = true
          state.expandedInfraRows = []
        }
        if (!resourceObj.lp) {
          if (!state.selectAllObj.appService && !hasAppServiceSelectPattern) {
            selectAllObj.appService = true
            state.expandedServRows = []
          }
        }
        if (resourceObj.cluster) {
          if (resourceObj.cluster?.length > 0) {
            if (resourceObj.cluster[0].name === '*') {
              isCheckAll.cluster = true
              clusterDeviceTree.map((cluster: IObject) => {
                checkedObjs.cluster.push(cluster.key)
                cluster.children &&
                  cluster.children.map((device: IObject) => {
                    checkedObjs.cluster.push(device.key)
                  })
              })
            } else {
              resourceObj.cluster.map((cluster: any) => {
                checkedObjs.cluster.push(cluster.name)
              })
            }
          }
        }
        if (resourceObj.device) {
          if (resourceObj.device?.length > 0) {
            if (resourceObj.device[0].name === '*') {
              isCheckAll.device = true
            } else {
              resourceObj.device.map((device: any) => {
                const deviceObj: any = deviceList.find((obj: any) => {
                  return obj.name === device.name
                })
                if (deviceObj) {
                  checkedObjs.cluster.push(
                    deviceObj.cluster + '|-d-|' + device.name,
                  )
                  checkedObjs.device.push(device.name)
                }
              })
            }
          }
        }
        if (resourceObj.tenant) {
          if (resourceObj.tenant?.length > 0) {
            if (resourceObj.tenant[0].name === '*') {
              isCheckAll.tenant = true
              tenantList.map((tenant: IObject) => {
                checkedObjs.tenant.push(tenant.name)
              })
            } else {
              resourceObj.tenant.map((tenant: any) => {
                checkedObjs.tenant.push(tenant.name)
              })
            }
          }
        }
        if (resourceObj.appService?.length > 0) {
          resourceObj.appService.map((appService: any) => {
            const tenant = appService.parentHierarchy[1].tenant
            const lp = appService.parentHierarchy[2]['logical-partition']
            const tenantObj = tenantList.find((obj: any) => {
              return obj.name === tenant
            })
            if (tenantObj) {
              const appSvcList = tenantObj['app-svc-list'] || []

              if (appService.name === '*') {
                if (checkedObjs.appService.indexOf(tenant) === -1) {
                  checkedObjs.appService.push(tenant)
                }
                if (
                  checkedObjs.appService.indexOf(tenant + '|-lp-|' + lp) === -1
                ) {
                  checkedObjs.appService.push(tenant + '|-lp-|' + lp)
                  appSvcList.map((appSvcObj: any) => {
                    if (appSvcObj['lp-name'] === lp) {
                      checkedObjs.appService.push(
                        tenant +
                          '|-lp-|' +
                          lp +
                          '|-as-|' +
                          (appSvcObj['display-name'] || appSvcObj.name),
                      )
                    }
                  })
                }
              } else {
                const appSvcObj = appSvcList.find((obj: any) => {
                  return (
                    (obj['display-name'] === appService.name ||
                      obj.name === appService.name) &&
                    obj['lp-name'] === lp
                  )
                })
                if (appSvcObj) {
                  checkedObjs.appService.push(
                    tenant + '|-lp-|' + lp + '|-as-|' + appService.name,
                  )
                }
              }
            }
          })
        } else if (resourceObj.lp?.length > 0) {
          resourceObj.lp.map((lp: any, index: any) => {
            const tenant = lp.parentHierarchy[1]?.tenant
            const tenantObj = tenantList.find((obj: any) => {
              return obj.name === tenant
            })
            if (tenantObj) {
              if (lp.name === '*') {
                if (checkedObjs.appService.indexOf(tenant) === -1) {
                  isCheckAll.appService = true
                  checkedObjs.appService.push(tenant)
                }
              } else {
                const lpKey = tenant + '|-lp-|' + lp.name
                if (checkedObjs.appService.indexOf(lpKey) === -1) {
                  checkedObjs.appService.push(lpKey)
                }
                if (checkedObjs.lp.indexOf(lpKey) === -1) {
                  checkedObjs.lp.push(lpKey)
                }
              }
            }
          })
        }

        this.setState({
          checkedObjs,
          permissionsObj,
          isCheckAll,
          selectAllObj,
        })
        const role = this.accessroleList.filter(
          (item: IObject) => item.name === accessGroup?.role,
        )[0]
        const updatedState = this.updatePermission(role)
        this.setState(updatedState, () => {
          this.onChangeCallback(updatedState.formData)
        })
      }
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.checkedObjs.tenant !== this.state.checkedObjs.tenant ||
      prevState.tenantChange !== this.state.tenantChange ||
      prevState.tenantByName !== this.state.tenantByName ||
      prevState.tenantList !== this.state.tenantList
    ) {
      this.getTenantLPAppSvcMapping()
    }
    if (
      prevState.checkedObjs.cluster !== this.state.checkedObjs.cluster ||
      prevState.clusterChange !== this.state.clusterChange ||
      prevState.clusterByName !== this.state.clusterByName ||
      prevState.clusterList !== this.state.clusterList ||
      prevState.selectAllObj.cluster !== this.state.selectAllObj.cluster
    ) {
      this.getClusterDeviceMapping()
    }
  }
  lp_partition_map: any = {}

  get_partition_id = (lp_name: any, tenant_name: any) => {

    const { clusterList } = this.state
    const tenants = this.state.tenantList;
    if (this.lp_partition_map[lp_name + '--' + tenant_name]) {
      return this.lp_partition_map[lp_name + '--' + tenant_name]
    }
    let cluster_partitions: any = []
    for (let i = 0; i < clusterList.length; i++) {
      cluster_partitions = [
        ...cluster_partitions,
        ...clusterList[i]['partition-list'],
      ]
    }
    if (tenants.length && clusterList.length) {
      let tenant: any = tenants.filter((t: any) => {
        return t.name === tenant_name
      })
      if (tenant.length) {
        tenant = tenant[0]
        if (tenant['logical-partition-list'].length) {
          let lp: any = tenant['logical-partition-list'].filter((l: any) => {
            return l.name === lp_name
          })
          if (lp.length) {
            lp = lp[0]
            const partition = cluster_partitions.filter((p: any) => {
              return p['lp-uuid'] === lp['uuid']
            })
            if (partition.length) {
              this.lp_partition_map[lp_name + '--' + tenant_name] =
                partition[0].id
              return parseInt(partition[0].id, 0)
            }
          }
        }
      }
    }
    return 0
  }

  renderInstanceSelection = (instanceObj: any) => {
    const { isCheckAll, checkedObjs } = this.state
    let role_name = this.state.formData
      ? this.state.formData.accessGroup
        ? this.state.formData.accessGroup.role
          ? this.state.formData.accessGroup.role
          : null
        : null
      : null

    switch (instanceObj.type) {
      case 'tenantInstance':
        let textTenant = isCheckAll.tenant ? 'All Tenants' : 'Select Instance'
        if (!isCheckAll.tenant && checkedObjs.tenant.length > 0) {
          textTenant = `Selected (${checkedObjs.tenant.filter((tenantName: string) => tenantName !== 'pso' && tenantName !== 'tenant').length})`
        }
        return (
          <A10Dropdown
            overlay={this.renderTenantsScope()}
            overlayClassName={styles.overlayDiv}
            trigger={['click']}
          >
            <a
              className={styles.dropdownLink}
              onClick={e => e.preventDefault()}
            >
              {textTenant} <A10Icon type="down" />
            </a>
          </A10Dropdown>
        )
      case 'clusterInstance':
        let textCluster = isCheckAll.cluster
          ? 'All Clusters'
          : 'Select Instance'
        if (!isCheckAll.cluster && checkedObjs.cluster.length > 0) {
          const clusterObjs = checkedObjs.cluster.filter((cluster: string) => {
            return cluster.indexOf('|-d-|') === -1
          })
          if (clusterObjs.length > 0) {
            textCluster = `Selected (${clusterObjs.filter((clusterName: string) => clusterName !== 'cluster').length})`
          }
        }
        return (
          <A10Dropdown
            overlay={this.renderClusterDeviceTree()}
            overlayClassName={styles.overlayDiv}
            trigger={['click']}
          >
            <a
              className={styles.dropdownLink}
              onClick={e => e.preventDefault()}
            >
              {textCluster} <A10Icon type="down" />
            </a>
          </A10Dropdown>
        )
      case 'appServiceInstance':
        let textAppSvc = isCheckAll.appService
          ? 'All App Services'
          : 'Select Instance'
        if (
          role_name === 'hc_partition_admin' ||
          role_name === 'hc_partition_operator'
        ) {
          textAppSvc = isCheckAll.appService
            ? 'All Logical Partitions'
            : 'Select Instance'
          if (!isCheckAll.appService && checkedObjs.appService.length > 0) {
            const appSvcObjs = checkedObjs.appService.filter(
              (appSvc: string) => {
                return (
                  appSvc.indexOf('|-lp-|') > -1 &&
                  appSvc.indexOf('|-as-|') === -1
                )
              },
            )
            if (appSvcObjs.length > 0) {
              textAppSvc = `Selected (${appSvcObjs.filter((lPName: string) => lPName !== "test|-lp-|logical-partition").length})`
            }
          }
        } else {
          if (!isCheckAll.appService && checkedObjs.appService.length > 0) {
            const appSvcObjs = checkedObjs.appService.filter(
              (appSvc: string) => {
                return appSvc.indexOf('|-as-|') > -1
              },
            )
            if (appSvcObjs.length > 0) {
              textAppSvc = `Selected (${appSvcObjs.length})`
            }
          }
        }

        return (
          <A10Dropdown
            overlay={this.renderTenantLPAppSrcTree()}
            overlayClassName={styles.overlayDiv}
            trigger={['click']}
            placement="topLeft"
          >
            <a
              className={styles.dropdownLink}
              onClick={e => e.preventDefault()}
            >
              {textAppSvc} <A10Icon type="down" />
            </a>
          </A10Dropdown>
        )
      default:
        return 'All'
    }
  }

  setClusterDevice = async () => {
    const {
      storage: {
        get: { PROVIDER },
        set,
      },
    } = this.context

    let { clusterByName } = this.state

    const clusterResponse = this.InfrastructureService.getClusters(
      null,
      null,
      PROVIDER,
    )
    await clusterResponse.then((response: any) => {
      const clusterList =
        response?.data && response.data['cluster-list']
          ? response.data['cluster-list']
          : []
      set.ALLCLUSTERS(clusterList)
      this.AppRoot.setRootScopeElement('allClusters', clusterList)
      clusterByName.clear()
      const clusterWithDevice: any[] = []
      clusterList.forEach((cluster: IObject) => {
        if (cluster['referrer-list'] && cluster['referrer-list'].length > 0) {
          clusterWithDevice.push(cluster)
          clusterByName = clusterByName.set(cluster.name, cluster)
        }
      })
      this.setState({ clusterList: clusterWithDevice, clusterByName })
    })

    const deviceResponse = this.InfrastructureService.getDevices(
      null,
      null,
      PROVIDER,
    )
    await deviceResponse.then((response: any) => {
      const deviceList =
        response?.data && response.data['device-list']
          ? response.data['device-list']
          : []
      set.ALLDEVICES(deviceList)
      this.setState({ deviceList })
    })
    this.getClusterDeviceMapping()
  }

  setSelectAll = () => {
    const {
      isCheckAll,
      checkedObjs,
      tenantList,
      clusterList,
      clusterDeviceTree,
    } = this.state
    if (isCheckAll.tenant) {
      checkedObjs.tenant = []
      tenantList.map((tenant: any) => {
        checkedObjs.tenant.push(tenant.name)
      })
    }
    if (isCheckAll.cluster) {
      checkedObjs.cluster = []
      clusterList.map((cluster: any) => {
        checkedObjs.cluster.push(cluster.name)
      })
    }
    if (checkedObjs.cluster.length > 0) {
      clusterDeviceTree.map((cluster: any) => {
        if (checkedObjs.cluster.indexOf(cluster.key) > -1) {
          cluster.children.map((device: any) => {
            checkedObjs.cluster.push(device.key)
            checkedObjs.device.push(device.key)
          })
        }
      })
    }
    const extraLPs: string[] = [],
      extraAppSvcs: string[] = []
    checkedObjs.appService.map((tenant: string) => {
      if (tenant.indexOf('|-lp-|') === -1) {
        const tenantObj = tenantList.find((tenantObj: any) => {
          return tenantObj.name === tenant
        })
        if (tenantObj) {
          const lpList = tenantObj['logical-partition-list'] || []
          const appSvcList = tenantObj['app-svc-list'] || []
          lpList.map((lp: any) => {
            if (
              checkedObjs.appService.indexOf(tenant + '|-lp-|' + lp.name) === -1
            ) {
              extraLPs.push(tenant + '|-lp-|' + lp.name)
            }
            appSvcList.map((appSvc: any) => {
              if (
                appSvc['lp-name'] === lp.name &&
                checkedObjs.appService.indexOf(
                  tenant +
                    '|-lp-|' +
                    lp.name +
                    '|-as-|' +
                    (appSvc['display-name'] || appSvc.name),
                ) === -1
              ) {
                extraAppSvcs.push(
                  tenant +
                    '|-lp-|' +
                    lp.name +
                    '|-as-|' +
                    (appSvc['display-name'] || appSvc.name),
                )
              }
            })
          })
        }
      }
    })
    extraLPs.map((lp: string) => {
      checkedObjs.appService.push(lp)
    })
    extraAppSvcs.map((appSvc: string) => {
      checkedObjs.appService.push(appSvc)
    })
    this.setState({ checkedObjs }, () => {
      this.getTenantLPAppSvcMapping()
      this.onRoleChange(this.state.formData.accessGroup.role)
      this.onLdapChange(this.state.formData.accessGroup.ldapGroupDn)
    })
  }

  onChangeCallback(formData: IFormData) {
    const isValid = this.validateSelectTreeNode()
    this.props.onChange(formData, isValid)
  }

  onNameChange = (e: any) => {
    const state = { ...this.state }
    state.formData.accessGroup.name = e.target.value
    this.setState(state, () => {
      this.onChangeCallback(state.formData)
    })
  }

  onDisplayNameChange = (e: any) => {
    const state = { ...this.state }
    state.formData.accessGroup.displayName = e.target.value
    this.setState(state, () => {
      this.onChangeCallback(state.formData)
    })
  }

  onDescriptionChange = (e: any) => {
    const state = { ...this.state }
    state.formData.accessGroup.description = e.target.value
    this.setState(state, () => {
      this.onChangeCallback(state.formData)
    })
  }
  getScopeObj(selectAllObj: IObject) {
    const role_name = this.state?.formData?.accessGroup?.role || null
    const scopeObject = [
      {
        name: 'Tenants',
        key: 'tenant',
        type: 'tenantInstance',
        instance: selectAllObj.tenant ? 'All' : 'Select',
        permission: this.state.permissionsObj.tenant,
      },
      {
        name: 'Clusters',
        key: 'cluster',
        type: 'clusterInstance',
        instance: selectAllObj.cluster ? 'All' : 'Select',
        permission: this.state.permissionsObj.cluster,
      },
      role_name === 'hc_partition_admin' ||
      role_name === 'hc_partition_operator'
        ? {
            name: 'Logical Partition',
            key: 'logicalPartition',
            type: 'logicalPartitionInstance',
            instance: selectAllObj.lp ? 'All' : 'Select',
            permission: this.state.permissionsObj.lp,
          }
        : {
            name: 'App Service',
            key: 'appService',
            type: 'appServiceInstance',
            instance: selectAllObj.appService ? 'All' : 'Select',
            permission: this.state.permissionsObj.appService,
          },
    ]
    return scopeObject
  }

  getRoleList() {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    return this.OrganizationService.getRbacRoleList(null, null, [PROVIDER])
  }

  getRoleResources = (resourceList: IObject, isRoleChange: boolean) => {
    const { isEdit, formData } = this.props
    let provider, cluster, device, tenant, lp, appService
    if (isEdit && !isRoleChange) {
      resourceList = formData?.accessGroup?.resourceList
      provider =
        resourceList.hasOwnProperty('provider') && resourceList.provider
      cluster = resourceList.hasOwnProperty('cluster') && resourceList.cluster
      device = resourceList.hasOwnProperty('device') && resourceList.device
      tenant = resourceList.hasOwnProperty('tenant') && resourceList.tenant
      lp =
        resourceList.hasOwnProperty('logical-partition') &&
        resourceList['logical-partition']
      appService =
        resourceList.hasOwnProperty('appService') && resourceList.appService
    } else {
      provider = resourceList.find((resource: any) => {
        return resource.name === 'provider'
      })
      cluster = resourceList.find((resource: any) => {
        return resource.name === 'cluster'
      })
      device = resourceList.find((resource: any) => {
        return resource.name === 'device'
      })
      tenant = resourceList.find((resource: any) => {
        return resource.name === 'tenant'
      })
      lp = resourceList.find((resource: any) => {
        return resource.name === 'logical-partition'
      })
      appService = resourceList.find((resource: any) => {
        return resource.name === 'app-service'
      })
    }

    return {
      provider,
      cluster,
      device,
      tenant,
      lp,
      appService,
    }
  }

  addInstanceInfoObjects = (
    checkedObjs: string[],
    parentHierarchy: any,
    permission: string,
    textCheck?: string,
  ) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const instanceInfoList: any[] = []
    checkedObjs.map((obj: string) => {
      if (!textCheck || (textCheck && obj.indexOf(textCheck) > -1)) {
        parentHierarchy[0].provider = PROVIDER
        let name = obj
        if (obj.indexOf('|-lp-|') > -1 && parentHierarchy.length > 1) {
        }

        if (obj.indexOf('|-lp-|') > -1) {
          const names = obj.split('|-lp-|')
          name = names[1]
          if (obj.indexOf('|-as-|') > -1) {
            const appName = obj.split('|-as-|')
            name = appName[1]
            if (parentHierarchy.length > 2) {
              // this case is for App Service
              const lpKeys = Object.keys(parentHierarchy[2])
              parentHierarchy[2][lpKeys[0]] = appName[0].replace(
                names[0] + '|-lp-|',
                '',
              )
            }
          }

          // this case is for LP & App Service
          const keys = Object.keys(parentHierarchy[1])
          parentHierarchy[1][keys[0]] = names[0]
        }
        // to avoid device key add in cluster instance
        if (obj.indexOf('|-d-|') === -1) {
          instanceInfoList.push({
            name,
            permission,
            parentHierarchy,
          })
        }
      }
    })
    return instanceInfoList
  }

  addAllInstance = (
    parentHierarchy: any,
    permission: string,
    secondParent?: string[],
    thirdParent?: string[],
  ) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const instanceInfoList: any[] = []

    parentHierarchy[0].provider = PROVIDER
    if (secondParent) {
      const keys = Object.keys(parentHierarchy[1])
      secondParent.map((parent: string) => {
        if (thirdParent && parentHierarchy.length > 2) {
          const keys = Object.keys(parentHierarchy[2])
          thirdParent.map((inParent: string) => {
            if (inParent.indexOf(parent + '|-lp-|') > -1) {
              const ph = _.cloneDeep(parentHierarchy)
              parentHierarchy[1][keys[0]] = parent
              parentHierarchy[2][keys[0]] = inParent.replace(
                parent + '|-lp-|',
                '',
              )
              instanceInfoList.push({
                name: '*',
                permission,
                parentHierarchy: ph,
              })
            }
          })
        } else {
          const ph = _.cloneDeep(parentHierarchy)
          parentHierarchy[1][keys[0]] = parent
          instanceInfoList.push({
            name: '*',
            permission,
            parentHierarchy: ph,
          })
        }
      })
    } else {
      instanceInfoList.push({
        name: '*',
        permission,
        parentHierarchy,
      })
    }

    return instanceInfoList
  }

  updatePermission(role: IObject) {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const state = { ...this.state }
    if (role?.resourceList) {
      const resourceList = _.cloneDeep(role.resourceList)
      let isRoleChange = true
      const resourceObj = this.getRoleResources(resourceList, isRoleChange)
      state.permissionsObj.provider = resourceObj.provider?.permission || '-'
      state.permissionsObj.cluster =
        resourceObj.cluster?.permission || state.permissionsObj.provider
      state.permissionsObj.device =
        resourceObj.device?.permission || state.permissionsObj.provider
      state.permissionsObj.tenant =
        resourceObj.tenant?.permission || state.permissionsObj.provider
      state.permissionsObj.lp =
        resourceObj.lp?.permission || state.permissionsObj.tenant
      state.permissionsObj.appService =
        resourceObj.appService?.permission || state.permissionsObj.tenant

      state.selectAllObj = {
        tenant: false,
        cluster: false,
        device: false,
        lp: false,
        appService: false,
      }
      let hasTenantSelectPattern = false,
        hasClusterSelectPattern = false,
        hasDeviceSelectPattern = false,
        hasAppServiceSelectPattern = false

      if (resourceObj.cluster) {
        hasClusterSelectPattern = true
      }
      if (resourceObj.device) {
        hasClusterSelectPattern = true
        hasDeviceSelectPattern = true
      }
      if (resourceObj.tenant) {
        hasTenantSelectPattern = true
      }
      if (resourceObj.appService) {
        hasTenantSelectPattern = true
        hasAppServiceSelectPattern = true
      }

      if (!state.selectAllObj.tenant && !hasTenantSelectPattern) {
        state.selectAllObj.tenant = true
        state.expandedOrgRows = []
      }
      if (!state.selectAllObj.cluster && !hasClusterSelectPattern) {
        state.selectAllObj.cluster = true
        state.expandedInfraRows = []
      }
      if (!state.selectAllObj.device && !hasDeviceSelectPattern) {
        state.selectAllObj.device = true
        state.expandedInfraRows = []
      }
      if (!state.selectAllObj.appService && !hasAppServiceSelectPattern) {
        state.selectAllObj.appService = true
        state.expandedServRows = []
      }
      const scopeObj = this.getScopeObj(state.selectAllObj)
      const tenantObj = scopeObj?.find(tenant => tenant.key === 'tenant')
      const clusterObj = scopeObj?.find(cluster => cluster.key === 'cluster')
      const appServiceObj = scopeObj?.find(
        appService => appService.key === 'appService',
      )
      const lpObj = scopeObj?.find(lp => lp.key === 'logicalPartition')
      const modifiedResourceList: any = {}
      resourceList.map((resource: any) => {
        if (resource.name === 'provider') {
          resource.instanceInfoList = [
            {
              name: PROVIDER,
              permission: resource.permission,
              parentHierarchy: [],
            },
          ]
        }
        if (
          resource.name === 'cluster' &&
          !!this.state.checkedObjs.cluster &&
          clusterObj?.instance === 'Select'
        ) {
          modifiedResourceList.cluster = state.isCheckAll.cluster
            ? this.addAllInstance(resource.parentHierarchy, resource.permission)
            : this.addInstanceInfoObjects(
                this.state.checkedObjs.cluster,
                resource.parentHierarchy,
                resource.permission,
              )
          modifiedResourceList?.cluster?.push(resource)
        }
        if (
          resource.name === 'tenant' &&
          !!this.state.checkedObjs.tenant &&
          tenantObj?.instance === 'Select'
        ) {
          modifiedResourceList.tenant = state.isCheckAll.tenant
            ? this.addAllInstance(resource.parentHierarchy, resource.permission)
            : this.addInstanceInfoObjects(
                this.state.checkedObjs.tenant,
                resource.parentHierarchy,
                resource.permission,
              )
          modifiedResourceList?.tenant?.push(resource)
        }
        if (
          resource.name === 'logical-partition' &&
          !!this.state.checkedObjs.lp &&
          lpObj?.instance === 'Select'
        ) {
          modifiedResourceList['logical-partition'] = state.isCheckAll
            .appService
            ? this.addAllInstance(
                resource.parentHierarchy,
                resource.permission,
                this.state.checkedObjs.tenant,
              )
            : this.addInstanceInfoObjects(
                this.state.checkedObjs.lp,
                resource.parentHierarchy,
                resource.permission,
              )
          modifiedResourceList?.['logical-partition']?.push(resource)
        }
        if (
          resource.name === 'app-service' &&
          !!this.state.checkedObjs.appService &&
          appServiceObj?.instance === 'Select'
        ) {
          modifiedResourceList.appService = state.isCheckAll.appService
            ? this.addAllInstance(
              resource.parentHierarchy,
              resource.permission,
              this.state.checkedObjs.tenant,
              this.state.checkedObjs.lp,
            )
            : this.addInstanceInfoObjects(
                this.state.checkedObjs.appService,
                resource.parentHierarchy,
                resource.permission,
                '|-as-|',
              )
          modifiedResourceList?.appService?.push(resource)
        }
      })
      state.formData.accessGroup.resourceList = modifiedResourceList
    }
    return state
  }

  renderRoleOptions() {
    const { formData } = this.state
    const defaultRoleName = formData?.accessGroup?.role || undefined
    let options: any = null
    options = this.accessroleList?.map((role: IObject) => {
      return (
        <A10Select.Option
          value={role.name}
          key={role.name}
          title={role.displayName || role.name}
          placeholder={'Please select role'}
        >
          <span>{role.displayName || role.name}</span>
        </A10Select.Option>
      )
    })
    return (
      <A10Select onChange={this.onRoleChange} value={defaultRoleName}>
        {options}
      </A10Select>
    )
  }

  validateSelectTreeNode = () => {
    const state = { ...this.state }
    if (
      (!state.selectAllObj.tenant &&
        (state.tenantList.length === 0 ||
          (state.tenantList.length > 0 &&
            state.checkedObjs.tenant.length === 0))) ||
      (!state.selectAllObj.cluster &&
        (state.clusterList.length === 0 ||
          (state.clusterList.length > 0 &&
            state.checkedObjs.cluster.length === 0))) ||
      (!state.selectAllObj.appService &&
        state.checkedObjs.appService.length === 0)
    ) {
      return false
    }
    return true
  }

  onRoleChange = (name: string) => {
    const state = { ...this.state }
    state.formData.accessGroup.role = name || null
    if (name) {
      const role = this.accessroleList.filter(
        (item: IObject) => item.name === name,
      )[0]
      const updatedState = this.updatePermission(role)
      this.setState(updatedState, () => {
        this.onChangeCallback(updatedState.formData)
      })
    } else {
      this.setState(state, () => {
        this.onChangeCallback(state.formData)
      })
    }
  }

  onLdapChange = (value:string) =>{
    const state = {...this.state}
    state.formData.accessGroup.ldapGroupDn = value || null
    this.setState(state, () => {
      this.onChangeCallback(state.formData)
    })    
  }

  onExpandRow = (expanded: boolean, key: string, type: string) => {
    let expandedRows = null
    if (type === 'org') {
      expandedRows = this.state.expandedOrgRows
    } else if (type === 'infra') {
      expandedRows = this.state.expandedInfraRows
    } else if (type === 'serv') {
      expandedRows = this.state.expandedServRows
    }
    if (expanded) {
      expandedRows.push(key)
    } else {
      expandedRows = expandedRows.filter((item: string) => {
        return item !== key
      })
    }
    if (type === 'org') {
      this.setState({ expandedOrgRows: expandedRows })
    } else if (type === 'infra') {
      this.setState({ expandedInfraRows: expandedRows })
    } else if (type === 'serv') {
      this.setState({ expandedServRows: expandedRows })
    }
  }

  getTenant() {
    const {
      EPIC_DEPENDENCIES: { httpClient },
    } = globalConfig
    const state = { ...this.state }
    const { provider } = state
    return httpClient.get(
      `/hpcapi/v3/provider/${provider}/tenant?detail=true&type=user-defined`,
    )
  }

  getTenantLPAppSvcMapping = async () => {
    const { tenantByName, checkedObjs } = this.state
    const tenantList: IObject[] = []
    for (const name of checkedObjs.tenant) {
      if (tenantByName.get(name)) {
        tenantList.push(tenantByName.get(name))
      }
    }
    const tenantLPAppSvcTree: any[] = await Promise.all(
      tenantList.map(async (tenant: IObject) => {
        const lpList = tenant['logical-partition-list'] || []
        const appSvcList = tenant['app-svc-list'] || []
        const updatedLPList = await Promise.all(
          lpList.map(async (lp: IObject) => {
            const updatedAppSvcList: any[] = []
            appSvcList.map((appSvc: any) => {
              if (appSvc['lp-name'] === lp.name) {
                updatedAppSvcList.push({
                  title: appSvc['display-name'] || appSvc.name,
                  checkable: true,
                  text: 'AS',
                  type: 'appService',
                  key:
                    tenant.name +
                    '|-lp-|' +
                    lp.name +
                    '|-as-|' +
                    (appSvc['display-name'] || appSvc.name),
                })
              }
            })
            return {
              title: lp.name,
              checkable: true,
              text: 'LP',
              type: 'lp',
              key: tenant.name + '|-lp-|' + lp.name,
              children: updatedAppSvcList,
            }
          }),
        )
        return {
          title: tenant['display-name'],
          checkable: true,
          text: 'T',
          type: 'tenant',
          key: tenant.name,
          children: updatedLPList,
        }
      }),
    )
    this.setState({ tenantLPAppSvcTree })
  }

  onSelectTenants = (checkedValues: string[]) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const state = { ...this.state }
    const { formData } = this.state,
      checkedAppService: string[] = []

    if (!formData.accessGroup.role) {
      this.Utilities.showMessage('please select role', 0, 0)
      return
    }

    if (formData?.accessGroup?.resourceList) {
      const tenantResource = formData?.accessGroup?.resourceList?.tenant?.[0]
      if (tenantResource) {
        formData.accessGroup.resourceList.tenant = []
        checkedValues.forEach(tenant => {
          const parentHierarchy = _.cloneDeep(tenantResource.parentHierarchy)
          parentHierarchy[0].provider = PROVIDER
          formData?.accessGroup?.resourceList?.tenant.push({
            name: tenant,
            parentHierarchy,
          })
        })
      }
      state.isCheckAll.tenant = false

      checkedValues.forEach(tenant => {
        state.checkedObjs.appService.map((appSvc: string) => {
          if (appSvc.indexOf(tenant + '|-lp-|') > -1 || appSvc === tenant) {
            checkedAppService.push(appSvc)
          }
        })
      })
    }

    state.formData.accessGroup = formData.accessGroup
    state.checkedObjs.tenant = checkedValues
    state.checkedObjs.appService = checkedAppService
    state.tenantChange = !state.tenantChange

    this.setState(state, () => {
      this.onChangeCallback(state.formData)
    })
  }

  onCheckAllTenants = (event: any) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const isSelectAll = event.target.checked
    const state = { ...this.state },
      checkedAppService: string[] = []
    const { tenantList, isCheckAll, formData, checkedObjs } = state

    if (!formData.accessGroup.role) {
      this.Utilities.showMessage('please select role', 0, 0)
      return
    }
    checkedObjs.tenant = []
    if (isSelectAll) {
      tenantList.map((tenant: any) => {
        checkedObjs.tenant.push(tenant.name)
      })
      if (formData?.accessGroup?.resourceList) {
        const tenantResource = formData.accessGroup.resourceList.tenant?.[0]
        if (tenantResource) {
          formData.accessGroup.resourceList.tenant = []
          const parentHierarchy = _.cloneDeep(tenantResource.parentHierarchy)
          parentHierarchy[0].provider = PROVIDER
          formData?.accessGroup?.resourceList?.tenant.push({
            name: '*',
            parentHierarchy,
          })
        }
      }
    }
    checkedObjs.tenant.forEach((tenant: string) => {
      checkedObjs.appService.map((appSvc: string) => {
        if (appSvc.indexOf(tenant + '|-lp-|') > -1 || appSvc === tenant) {
          checkedAppService.push(appSvc)
        }
      })
    })
    checkedObjs.appService = checkedAppService

    isCheckAll.tenant = isSelectAll
    state.checkedObjs = checkedObjs
    state.formData.accessGroup = formData.accessGroup
    state.tenantChange = !state.tenantChange
    this.setState(state, () => {
      this.onChangeCallback(state.formData)
    })
  }

  onSelectAppSvcs = (checkedKeys: string[]) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const role_name = this.state.formData
      ? this.state.formData.accessGroup
        ? this.state.formData.accessGroup.role
          ? this.state.formData.accessGroup.role
          : null
        : null
      : null

    const state = { ...this.state }
    const { formData, tenantLPAppSvcTree } = state,
      checkedLPs: string[] = []

    if (formData?.accessGroup?.resourceList) {
      const lpResource =
        formData.accessGroup.resourceList['logical-partition']?.[0]
      const appServiceResource =
        formData.accessGroup.resourceList.appService?.[0]

      if (lpResource) {
        formData.accessGroup.resourceList['logical-partition'] = []
      }
      if (appServiceResource) {
        formData.accessGroup.resourceList.appService = []
      }
      const allLPsOfTenant: string[] = [],
        allAppSvcsOfTenant: string[] = []
      if (
        role_name === 'hc_partition_admin' ||
        role_name === 'hc_partition_operator'
      ) {
        checkedKeys &&
          checkedKeys.forEach((key: string) => {
            if (lpResource && key.indexOf('|-lp-|') > -1) {
              const names = key.split('|-lp-|')
              if (lpResource) {
                const id = this.get_partition_id(names[1], names[0])
                const parentHierarchy = _.cloneDeep(lpResource.parentHierarchy)
                parentHierarchy[0].provider = PROVIDER
                parentHierarchy[1].tenant = names[0]
                formData.accessGroup.resourceList['logical-partition'].push({
                  name: names[1],
                  id: id,
                  parentHierarchy,
                })
                checkedLPs.push(key)
              }
            }
          })
      } else {
        checkedKeys &&
          checkedKeys.forEach((tenant: string) => {
            if (
              tenant.indexOf('|-lp-|') === -1 &&
              tenant.indexOf('|-as-|') === -1
            ) {
              allLPsOfTenant.push(tenant)
              allAppSvcsOfTenant.push(tenant)
              if (lpResource) {
                const parentHierarchy = _.cloneDeep(lpResource.parentHierarchy)
                parentHierarchy[0].provider = PROVIDER
                parentHierarchy[1].tenant = tenant
                formData.accessGroup.resourceList['logical-partition'].push({
                  name: '*',
                  parentHierarchy,
                })
              }
              if (appServiceResource) {
                const tenantTree = tenantLPAppSvcTree.find((obj: any) => {
                  return obj.key === tenant
                })
                tenantTree.children &&
                  tenantTree.children.map((lp: any) => {
                    const parentHierarchy = _.cloneDeep(
                      appServiceResource.parentHierarchy,
                    )
                    parentHierarchy[0].provider = PROVIDER
                    parentHierarchy[1].tenant = tenant
                    parentHierarchy[2]['logical-partition'] = lp.title
                    formData?.accessGroup?.resourceList?.appService?.push({
                      name: '*',
                      parentHierarchy,
                    })
                  })
              }
            }
          })

        checkedKeys &&
          checkedKeys.forEach((key: string) => {
            if (
              lpResource &&
              key.indexOf('|-lp-|') > -1 &&
              key.indexOf('|-as-|') === -1
            ) {
              const names = key.split('|-lp-|')
              if (lpResource && allLPsOfTenant.indexOf(names[0]) === -1) {
                const parentHierarchy = _.cloneDeep(lpResource.parentHierarchy)
                parentHierarchy[0].provider = PROVIDER
                parentHierarchy[1].tenant = names[0]
                formData?.accessGroup?.resourceList['logical-partition']?.push({
                  name: names[1],
                  parentHierarchy,
                })
                checkedLPs.push(key)
              }
            } else if (appServiceResource && key.indexOf('|-as-|') > -1) {
              const names = key.split('|-lp-|')
              const appName = names[1].split('|-as-|')

              if (allLPsOfTenant.indexOf(names[0]) === -1) {
                const parentHierarchy = _.cloneDeep(
                  appServiceResource.parentHierarchy,
                )
                parentHierarchy[0].provider = PROVIDER
                parentHierarchy[1].tenant = names[0]
                parentHierarchy[2]['logical-partition'] = appName[0]
                formData?.accessGroup?.resourceList?.appService?.push({
                  name: appName[1],
                  parentHierarchy,
                })
              }
            }
          })
      }
    }

    state.isCheckAll.appService = false
    state.formData.accessGroup = formData.accessGroup
    state.checkedObjs.lp = checkedLPs || []
    state.checkedObjs.appService = checkedKeys || []
    this.setState(state, () => {
      this.onChangeCallback(state.formData)
    })
  }

  onCheckAllAppServices = (event: any) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const isSelectAll = event.target.checked,
      state = { ...this.state },
      checkedTenants: string[] = [],
      checkedLPs: string[] = []
    const { isCheckAll, formData, checkedObjs, tenantLPAppSvcTree } = state

    if (!formData.accessGroup.role) {
      this.Utilities.showMessage('please select role', 0, 0)
      return
    }

    checkedObjs.lp = []
    checkedObjs.appService = []

    if (isSelectAll) {
      tenantLPAppSvcTree.map((tenant: any) => {
        checkedObjs.appService.push(tenant.key)
        checkedTenants.push(tenant.key)
        tenant.children &&
          tenant.children.map((lp: any) => {
            checkedObjs.lp.push(lp.key)
            checkedLPs.push(lp.key)
            checkedObjs.appService.push(lp.key)
            lp.children &&
              lp.children.map((appSvc: any) => {
                checkedObjs.appService.push(appSvc.key)
              })
          })
      })

      if (formData?.accessGroup?.resourceList) {
        const lpResource =
          formData.accessGroup.resourceList['logical-partition']?.[0]
        const appServiceResource =
          formData.accessGroup.resourceList.appService?.[0]

        if (lpResource) {
          formData.accessGroup.resourceList['logical-partition'] = []
        }
        if (appServiceResource) {
          formData.accessGroup.resourceList.appService = []
        }
        if (lpResource) {
          checkedTenants.map((tenant: string) => {
            const parentHierarchy = _.cloneDeep(lpResource.parentHierarchy)
            parentHierarchy[0].provider = PROVIDER
            const keys = Object.keys(parentHierarchy[1])
            parentHierarchy[1][keys[0]] = tenant
            formData.accessGroup.resourceList['logical-partition'].push({
              name: '*',
              parentHierarchy,
            })
          })
        }
        if (appServiceResource) {
          checkedTenants.map((tenant: string) => {
            checkedLPs.map((lp: string) => {
              if (lp.indexOf(tenant + '|-lp-|') > -1) {
                const parentHierarchy = _.cloneDeep(
                  appServiceResource.parentHierarchy,
                )
                parentHierarchy[0].provider = PROVIDER
                const keys = Object.keys(parentHierarchy[1])
                const lpKeys = Object.keys(parentHierarchy[2])
                parentHierarchy[1][keys[0]] = tenant
                parentHierarchy[2][lpKeys[0]] = lp.replace(
                  tenant + '|-lp-|',
                  '',
                )
                formData.accessGroup.resourceList.appService.push({
                  name: '*',
                  parentHierarchy,
                })
              }
            })
          })
        }
      }
    }
    state.formData.accessGroup = formData.accessGroup
    state.checkedObjs = checkedObjs
    isCheckAll.appService = isSelectAll
    this.setState(state, () => {
      this.onChangeCallback(state.formData)
    })
  }

  renderTenantLPAppSrcTree() {
    const role_name = this.state.formData
      ? this.state.formData.accessGroup
        ? this.state.formData.accessGroup.role
          ? this.state.formData.accessGroup.role
          : null
        : null
      : null
    const { tenantLPAppSvcTree, checkedObjs } = this.state
    if (checkedObjs.tenant.length === 0) {
      return (
        <div className={styles.infoBox}>
          <div className={styles.warningIcon} />
          Please select some tenants to start
        </div>
      )
    }

    const loop = (data: IObject) =>
      data.map((item: IObject) => {
        const text = item.text
        const checkable = item.checkable
        let title = item.title
        let icon = text ? (
          <HealthStatus type="ongoing" text={text} hideTooltip={true} />
        ) : null
        if (item.children) {
          if (item.children.length === 0) {
            title +=
              item.key.indexOf('|-lp-|') === -1
                ? ' - No Logical Partitions'
                : ' - No App Services'
          }
          const isLogicalPartition = title?.includes('No Logical Partitions')
          return (
            <A10Tree.TreeNode
              icon={icon}
              checkable={checkable}
              disabled={isLogicalPartition}
              selectable={false}
              title={title}
              key={item.key}
            >
              {((role_name === 'hc_partition_admin' ||
                role_name === 'hc_partition_operator') &&
                item.type !== 'lp') ||
              (role_name !== 'hc_partition_admin' &&
                role_name !== 'hc_partition_operator')
                ? loop(item.children)
                : null}
            </A10Tree.TreeNode>
          )
        }
        return (
          <A10Tree.TreeNode
            icon={icon}
            checkable={checkable}
            selectable={false}
            title={item.title}
            key={item.key}
          />
        )
      })
    return (
      <>
        {tenantLPAppSvcTree.length > 0 ? (
          <div className={styles.allCheckbox}>
            <A10Checkbox
              onChange={(e: any) => {
                this.onCheckAllAppServices(e)
              }}
              checked={this.state.isCheckAll.appService}
            >
              All
            </A10Checkbox>
          </div>
        ) : null}
        <A10Tree
          checkable={true}
          checkedKeys={this.state.checkedObjs.appService}
          showIcon={true}
          selectable={false}
          onCheck={this.onSelectAppSvcs}
        >
          {loop(tenantLPAppSvcTree)}
        </A10Tree>
      </>
    )
  }

  renderTenantsScope = () => {
    const CheckboxGroup = A10Checkbox.Group
    const { tenantList } = this.state
    const optionList = tenantList.map((tenant: IObject) => {
      return {
        label: tenant['display-name'] || tenant.name,
        value: tenant.name,
      }
    })
    return (
      <>
        {optionList.length > 0 ? (
          <div className={styles.allCheckbox}>
            <A10Checkbox
              onChange={(e: any) => {
                this.onCheckAllTenants(e)
              }}
              checked={this.state.isCheckAll.tenant}
            >
              All
            </A10Checkbox>
          </div>
        ) : null}
        <CheckboxGroup
          className={styles.scopeCheckbox}
          value={this.state.checkedObjs.tenant}
          options={optionList}
          onChange={this.onSelectTenants}
        />
      </>
    )
  }

  onCheckAllClusters = (event: any) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const isSelectAll = event.target.checked,
      state = { ...this.state }
    const { isCheckAll, formData, checkedObjs, clusterDeviceTree } = state

    if (!formData.accessGroup.role) {
      this.Utilities.showMessage('please select role', 0, 0)
      return
    }

    checkedObjs.cluster = []
    checkedObjs.device = []

    if (isSelectAll) {
      clusterDeviceTree.map((cluster: any) => {
        checkedObjs.cluster.push(cluster.key)
        cluster.children &&
          cluster.children.map((device: any) => {
            checkedObjs.cluster.push(device.key)
            checkedObjs.device.push(device.key)
          })
      })

      if (formData?.accessGroup?.resourceList) {
        const clusterResource = formData.accessGroup.resourceList?.cluster?.[0]
        const deviceResource = formData.accessGroup.resourceList?.device?.[0]

        if (clusterResource) {
          formData.accessGroup.resourceList.cluster = []
        }
        if (deviceResource) {
          formData.accessGroup.resourceList.device = []
        }
        if (deviceResource) {
          const parentHierarchy = _.cloneDeep(deviceResource.parentHierarchy)
          parentHierarchy[0].provider = PROVIDER
          formData?.accessGroup?.resourceList?.device?.push({
            name: '*',
            parentHierarchy,
          })
        }
        if (clusterResource) {
          const parentHierarchy = _.cloneDeep(clusterResource.parentHierarchy)
          parentHierarchy[0].provider = PROVIDER
          formData?.accessGroup?.resourceList?.cluster?.push({
            name: '*',
            parentHierarchy,
          })
        }
      }
    }
    state.formData.accessGroup = formData.accessGroup
    state.checkedObjs = checkedObjs
    isCheckAll.cluster = isSelectAll
    isCheckAll.device = isSelectAll
    this.setState(state, () => {
      this.onChangeCallback(state.formData)
    })
  }

  getClusterDeviceMapping = () => {
    const { clusterList, deviceList } = this.state
    const selClusterList: IObject[] = [],
      clusterDeviceTree: any[] = []

    clusterList.map((cluster: any) => {
      selClusterList.push(cluster)
    })

    selClusterList &&
      selClusterList.map((cluster: IObject) => {
        const deviceNodes: any[] = []
        deviceList.map((device: any) => {
          if (device.cluster === cluster.name) {
            deviceNodes.push({
              title: device.name,
              checkable: true,
              disabled: true,
              text: 'D',
              type: 'device',
              key: cluster.name + '|-d-|' + device.name,
            })
          }
        })

        if (deviceNodes.length > 0) {
          clusterDeviceTree.push({
            title: cluster['display-name'] || cluster.name,
            checkable: true,
            text: 'C',
            type: 'cluster',
            key: cluster.name,
            children: deviceNodes,
          })
        }
      })

    this.setState({ clusterDeviceTree })
  }

  onSelectClusters = (checkedKeys: string[]) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const state = { ...this.state },
      checkedClusterKeys: string[] = [],
      checkedDeviceKeys: string[] = [],
      newCheckedKeys: string[] = []
    const { formData, clusterList, clusterDeviceTree } = state

    if (!formData.accessGroup.role) {
      this.Utilities.showMessage('please select role', 0, 0)
      return
    }

    if (formData?.accessGroup?.resourceList) {
      const clusterResource = formData.accessGroup.resourceList.cluster?.[0]
      const deviceResource = formData.accessGroup.resourceList.device?.[0]

      if (clusterResource) {
        formData.accessGroup.resourceList.cluster = []
      }
      if (deviceResource) {
        formData.accessGroup.resourceList.device = []
      }
      if (deviceResource) {
        formData.accessGroup.resourceList.device = []
      }
      checkedKeys &&
        checkedKeys.forEach((clusterDevice: string) => {
          if (clusterDevice.indexOf('|-d-|') === -1) {
            if (clusterResource) {
              const parentHierarchy = _.cloneDeep(
                clusterResource.parentHierarchy,
              )
              parentHierarchy[0].provider = PROVIDER
              formData.accessGroup.resourceList.cluster.push({
                name: clusterDevice,
                parentHierarchy,
              })
            }
            checkedClusterKeys.push(clusterDevice)
            newCheckedKeys.push(clusterDevice)

            clusterDeviceTree.map((cluster: any) => {
              if (cluster.key === clusterDevice) {
                cluster.children.map((device: any) => {
                  checkedDeviceKeys.push(device.key)
                  newCheckedKeys.push(device.key)

                  if (deviceResource) {
                    const parentHierarchy = _.cloneDeep(
                      deviceResource.parentHierarchy,
                    )
                    parentHierarchy[0].provider = PROVIDER
                    formData.accessGroup.resourceList.device.push({
                      name: device.key.split('|-d-|')[1],
                      parentHierarchy,
                    })
                  }
                })
              }
            })
          }
        })
    }

    state.isCheckAll.cluster = false
    state.formData.accessGroup = formData.accessGroup
    state.checkedObjs.cluster = newCheckedKeys
    state.checkedObjs.device = checkedDeviceKeys
    this.setState(state, () => {
      this.onChangeCallback(state.formData)
    })
  }

  renderClusterDeviceTree = () => {
    const { clusterDeviceTree, selectAllObj } = this.state
    if (!selectAllObj.cluster && clusterDeviceTree.length === 0) {
      return (
        <div className={styles.infoBox}>
          <div className={styles.warningIcon} />
          No cluster(s) & device(s) to select
        </div>
      )
    }

    const loop = (data: IObject) =>
      data.map((item: IObject) => {
        const text = item.text
        const checkable = item.checkable
        let title = item.title
        let icon = text ? (
          <HealthStatus type="ongoing" text={text} hideTooltip={true} />
        ) : null
        if (item.children) {
          if (item.children.length === 0) {
            title += ' - No Device(s)'
          }
          return (
            <A10Tree.TreeNode
              icon={icon}
              checkable={checkable}
              selectable={false}
              title={title}
              key={item.key}
            >
              {loop(item.children)}
            </A10Tree.TreeNode>
          )
        }
        return (
          <A10Tree.TreeNode
            icon={icon}
            checkable={checkable}
            disabled={item.disabled}
            selectable={false}
            title={item.title}
            key={item.key}
          />
        )
      })
    return (
      <>
        {clusterDeviceTree.length > 0 ? (
          <div className={styles.allCheckbox}>
            <A10Checkbox
              onChange={(e: any) => {
                this.onCheckAllClusters(e)
              }}
              checked={this.state.isCheckAll.cluster}
            >
              All
            </A10Checkbox>
          </div>
        ) : null}
        <A10Tree
          checkable={true}
          checkedKeys={this.state.checkedObjs.cluster}
          showIcon={true}
          selectable={false}
          onCheck={this.onSelectClusters}
        >
          {loop(clusterDeviceTree)}
        </A10Tree>
      </>
    )
  }

  getRowClassName = (record: IObject, index: number) => {
    const { type } = record
    const rowColor = index % 2 ? 'even' : 'odd'
    if (
      !(
        (type === 'tenantInstance' && !this.state.selectAllObj.tenant) ||
        (type === 'lpInstance' && !this.state.selectAllObj.lp) ||
        (type === 'clusterInstance' && !this.state.selectAllObj.cluster) ||
        (type === 'deviceInstance' && !this.state.selectAllObj.device)
      )
    ) {
      return `${rowColor} hide-expand-icon`
    }
    return rowColor
  }

  getRowKey = (record: IObject) => {
    return record.key
  }

  renderObjectScope = () => {
    const role_name = this.state.formData
      ? this.state.formData.accessGroup
        ? this.state.formData.accessGroup.role
          ? this.state.formData.accessGroup.role
          : null
        : null
      : null
    return (
      <A10Table
        className={`hc-list ${styles.permissionList}`}
        rowClassName={this.getRowClassName}
        rowKey={this.getRowKey}
        size="small"
        columns={this.defaultScopeColumns}
        onExpand={(expanded: boolean, object: any) => {
          this.onExpandRow(expanded, object.key, 'org')
        }}
        expandedRowKeys={this.state.expandedOrgRows}
        dataSource={[
          {
            name: 'Tenants',
            key: 'tenant',
            type: 'tenantInstance',
            instance: this.state.selectAllObj.tenant ? 'All' : 'Select',
            permission: this.state.permissionsObj.tenant,
          },
          {
            name: 'Clusters',
            key: 'cluster',
            type: 'clusterInstance',
            instance: this.state.selectAllObj.cluster ? 'All' : 'Select',
            permission: this.state.permissionsObj.cluster,
          },
          {
            name:
              role_name === 'hc_partition_admin' ||
              role_name === 'hc_partition_operator'
                ? 'Logical Partition'
                : 'App Service',
            key: 'appService',
            type: 'appServiceInstance',
            instance: this.state.selectAllObj.appService ? 'All' : 'Select',
            permission: this.state.permissionsObj.appService,
          },
        ]}
      />
    )
  }

  handleChange = (stateName: string, e: IObject) => {
    const groupAttributeObj = this.state
    if (e?.target) {
      if (e.target.type === 'checkbox') {
        groupAttributeObj[stateName] = e.target.checked
      } else {
        groupAttributeObj[stateName] = e.target.value
      }
    } else {
      groupAttributeObj[stateName] = e
    }
  }

  loadLdapGroup = async () => {
    const { admindn, password } = this.state
    const { scheme } = this.state.authResponseData
    let headers = {}

    try {
      if (scheme === 'userDN') {
        headers = {
          admindn,
          password: new Buffer(password).toString('base64'),
        }
      } else {
        headers = {}
      }
      const response = await this.InfrastructureService.getLdapGroup(
        headers,
        null,
        null,
      )
      if (response?.data) {
        this.setState({
          ldapGroupData: response?.data,
          admindn,
          password,
        })
      }
    } catch (error) {
      const message = `${this.Messages.LOAD_LDAPGROUP_FAILED}`
      A10Message.error(message, 10, close)
    }
  }

  getAuthType = () => {
    const provider = storage.get.PROVIDER || 'root'

    this.authenticationService
      .getAuthProvider({ provider }, null, [provider])
      .then((response: any) => {        
        const authProvider = _.get(response, [
          'data',
          'authentication-provider',
        ])
        if (authProvider) {          
          this.setState({
            authResponseData: authProvider,
          })
        }
      })      
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }
    const role_name = this.state.formData
      ? this.state.formData.accessGroup
        ? this.state.formData.accessGroup.role
          ? this.state.formData.accessGroup.role
          : null
        : null
      : null
    const { formData, admindn, password, ldapGroupData, authResponseData } = this.state
    const ScopeHeader = () => {
      return (
        <A10Row type="flex" justify="space-around" align="middle">
          <A10Col span={14}>
            <A10IconTextGroup
              text='Scope'
              icon={
                <A10Icon
                  style={{ width: 22, height: 22, margin: '0px 12px 0px 17px' }}
                  app="global"
                  type="form-section"
                  className="sliding-panel-icon"
                />
              }
            />
          </A10Col>
          <A10Col span="3">Select Role</A10Col>
          <A10Col span="6" onClick={e => e.stopPropagation()}>
            {this.renderRoleOptions()}
          </A10Col>
          <A10Col span={1}>
            {formData?.accessGroup?.role ? null : (
              <div
                className={styles.warningIcon}
                title="Must select one role"
              />
            )}
          </A10Col>
        </A10Row>
      )
    }

    const { getFieldDecorator } = this.props.form
    
    
    return (
      <A10Form layout="horizontal">
        <A10Panel
          style={{marginTop: '35px'}}
          title={
            <A10IconTextGroup
              text="Access Group's Definition"
              icon={
                <A10Icon
                  style={{ width: 22, height: 22, margin: '0px 12px 0px 17px' }}
                  app="global"
                  type="form-section"
                />
              }
            />
          }
        >
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="mandatoryField">Access Group Name</span>
              </>
            }
          >
            {getFieldDecorator('name', {
              rules: [
                { max: 128, message: 'Max length is 128' },
                {
                  pattern: '^[a-zA-Z0-9_-]*$',
                  message: 'Space and special characters are not allowed',
                },
              ],
              initialValue: formData?.accessGroup?.name || '',
            })(
              <A10Input
                placeholder="Up to 128 characters. No space or special characters allowed."
                maxLength={128}
                onChange={this.onNameChange}
                disabled={this.props.isEdit}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>Display Name</span>
              </>
            }
          >
            <A10Input
              value={formData?.accessGroup?.displayName}
              placeholder={
                formData?.accessGroup?.name || 'Up to 128 characters'
              }
              maxLength={128}
              onChange={this.onDisplayNameChange}
            />
          </A10Form.Item>
          <A10Form.Item {...formItemLayout} label="Description">
            <A10Input.TextArea
              value={formData?.accessGroup?.description}
              placeholder="Enter some description about this Access Group"
              autosize={{ minRows: 2, maxRows: 6 }}
              onChange={this.onDescriptionChange}
            />
          </A10Form.Item>
        </A10Panel>

        {authResponseData?.type === 'ldap' && authResponseData?.['remote-authorization'] && (          
          <A10Panel
            style={{ marginTop: '35px' }}
            title={
              <A10IconTextGroup
                text='LDAP Group Mapping'
                icon={
                  <A10Icon
                    app="global"
                    type="form-section"
                    style={{ width: 22, height: 22, margin: '0px 12px 0px 17px' }}
                  />
                }
              />
            }
          >
            {authResponseData?.scheme === 'userDN' && (
              <>
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span className="mandatoryField">
                        {this.Messages.ADMIN_DN}
                      </span>
                      <span style={{ marginLeft: '-10px' }}>
                        <HelpInfo
                          placement="left"
                          title="Admin DN"
                          helpKey="HELP_ORG_ADMIN_DN"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('admindn', {
                    rules: [
                      { messages: this.Messages.EMPTY_ADMIN_PASSWORD },
                      {
                        validator: (rule: any, value: string, cb: any) => {
                          return validateAdminDN(authResponseData?.type, value, false)
                        },
                        message:
                          'Admin DN pattern should consist of several equations "Key=Value"(Separated by commas)!',
                      },
                    ],
                    initialValue: admindn,
                  })(
                    <A10Input
                      type="text"
                      placeholder={this.Messages.ADMIN_DN}
                      onChange={this.handleChange.bind(this, 'admindn')}
                    />
                  )}
                </A10Form.Item>
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span className="mandatoryField">
                        {this.Messages.ADMIN_PASSWORD}
                      </span>
                      <span style={{ marginLeft: '-10px' }}>
                        <HelpInfo
                          placement="left"
                          title="Admin Password"
                          helpKey="HELP_ORG_ADMIN_PASSWORD"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        validator: (rule: any, value: string, cb: any) => {
                          return validateAdminPwd(authResponseData?.type, value, false)
                        },
                        message: this.Messages.EMPTY_ADMIN_PASSWORD,
                      },
                    ],
                    initialValue: password,
                  })(
                    <A10Input
                      type="password"
                      placeholder={this.Messages.ADMIN_PASSWORD}
                      onChange={this.handleChange.bind(this, 'password')}
                    />
                  )}
                </A10Form.Item>
                <A10Form.Item
                  {...formItemLayout}
                  label=' '
                >
                  <ActionButton
                    onClick={this.loadLdapGroup}
                    text="Load LDAP Group"
                  />
                </A10Form.Item>
              </>
            )}
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  Select LDAP Group
                  <span style={{ marginLeft: '-10px' }}>
                    <HelpInfo
                      placement="left"
                      title="Select LDAP Group"
                      helpKey="HELP_ORG_LDAP_GROUP"
                    />
                  </span>
                </>
              }
            >
              <A10Select
                title=''
                key={'ldapGroupDn'}
                onChange={this.onLdapChange}
                placeholder="Please select ldap group"
                allowClear={true}
                value={formData?.accessGroup?.ldapGroupDn}
              >
                {ldapGroupData?.map((data: any, id: number) => {                  
                  return (
                    <A10Select.Option
                      key={id}
                      value={data}
                    >
                      {data}
                    </A10Select.Option>
                  )
                })}
              </A10Select>
            </A10Form.Item>
          </A10Panel>
        )}

        {formData.accessGroup?.role ? null : (
          <A10Row style={{ padding: '10px 0px' }}>
            <A10Alert
              showIcon={true}
              style={{ fontWeight: 600 }}
              message=""
              description="Choose Role."
              type="warning"
            />
          </A10Row>
        )}

        {formData?.accessGroup?.role &&
        ((!this.state.selectAllObj.tenant &&
          this.state.checkedObjs.tenant.length === 0) ||
          (!this.state.selectAllObj.cluster &&
            this.state.checkedObjs.cluster.length === 0) ||
          (!this.state.selectAllObj.appService &&
            this.state.checkedObjs.appService.length === 0)) ? (
          <A10Row style={{ padding: '10px 0px' }}>
            <A10Alert
              showIcon={true}
              style={{ fontWeight: 600 }}
              message=""
              description="Choose instances."
              type="warning"
            />
          </A10Row>
        ) : null}
        <A10Panel
          style={{marginTop: '35px'}}
          title={
            <A10IconTextGroup
              text='Scope'
              icon={
                <A10Icon
                  style={{ width: 22, height: 22, margin: '0px 12px 0px 17px' }}
                  app="global"
                  type="form-section"
                  className="sliding-panel-icon"
                />
              }
            />
          }
          menu={
            <A10Row style={{paddingTop: '6px', display: 'flex'}}>
               <A10Col style={{ display: 'inline-block' }}>
                {formData.accessGroup?.role ? null : (
                  <div
                    className={styles.warningIcon}
                    title="Must select one role"
                  />
                )}
              </A10Col>
              <A10Col>
              <div className={styles.selectRole}>Select Role</div>
              <div
                className={styles.roleSelection}
                onClick={e => e.stopPropagation()}
              >
                {this.renderRoleOptions()}
              </div>
              </A10Col>
            </A10Row>
          }
        >
          {this.renderObjectScope()}
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(AccessGroupForm))
