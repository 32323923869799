export class AppConfigs {
  APPS = [
    {
      key: 'adc',
      text: 'Application Delivery',
      versions: true,
      appName: ['adc', 'slb'],
      icon: 'adc',
    },
    // {
    //   key: 'lightning',
    //   text: 'Lightning ADC',
    //   icon: 'lightning',
    // },
    {
      key: 'cgn',
      text: 'Carrier Grade NAT',
      versions: true,
      appName: ['cgn', 'cgnv6'],
      icon: 'cgn',
    },
    {
      key: 'gifw',
      text: 'Gi/SGi Firewall',
      versions: true,
      appName: ['gifw', 'gifirewall'],
      icon: 'gi-fw',
    },
    {
      key: 'gtp',
      text: 'GTP Firewall',
      versions: true,
      appName: ['gtpfw', 'gtp-fw'],
      icon: 'gtp',
    },
    {
      key: 'ssli',
      text: 'SSL Insight',
      versions: true,
      appName: ['ssli'],
      icon: 'ss-li',
    },

    // {
    //   key: 'object-explorer',
    //   text: 'Object Explorer',
    //   separator: true
    // }
  ]
}

export default AppConfigs
