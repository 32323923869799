import React from 'react'
import parameters from 'parameters'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getItem,
  setItem,
} from '@gui-libraries/framework'
import { DGF, DGFProvider } from '@gui-libraries/dgf'

import {
  ContentSection,
  ContentBody,
} from 'src/components/shared/ContentSection'
import storage from 'src/libraries/storage'
import LogContent from '../LogContent'

export interface IAuditProps extends IA10ContainerDefaultProps {
  selectedTenant?: string
}
export interface IAuditState {
  dgfParameter: GuiDGF.HCParameters
  dashboard: IObject
}

const logConfig = [
  {
    name: 'HC Audit',
    viz: [
      {
        app: 'hc',
        name: 'Audit',
        subViz: [
          {
            dataSource: 'RPT',
            endPoint: 'hc',
            metrics: 'hc_audit',
            name: 'Controller',
            alias: 'Controller Logs',
          },
          {
            dataSource: 'RPT',
            endPoint: 'thunder',
            metrics: 'system_audit_logs_to_hc',
            name: 'Thunder',
            alias: 'Thunder Logs',
          },
          {
            dataSource: 'RPT',
            endPoint: 'thunder-adc',
            metrics: 'auditlogs',
            name: 'License',
            alias: 'License Logs',
          },
        ],
      },
    ],
  },
]

class Audit extends A10Container<IAuditProps, IAuditState> {
  constructor(props) {
    super(props)

    const tenant = storage.get.CURRENT_TENANT

    this.state = {
      dgfParameter: {
        auth: storage.get.ENCODED_SESSION_ID,
        api: parameters.BASE_URL,
        provider: storage.get.PROVIDER,
        username: storage.get.USER_ID,
        tenant: tenant.name,
        tenantUUID: tenant.uuid,
        providerId: storage.get.PROVIDER_ID,
      },
      dashboard: {
        name: 'Audit',
        description: '',
        context: props.selectedTenant ? 'tenant-custom' : 'provider-custom',
        isDrillDown: false,
      },
    }

    if (props.selectedTenant && !getItem('SELECTED_CONTEXT')) {
      setItem('SELECTED_CONTEXT', getItem('CURRENT_TENANT'))
    }

    if(!props.selectedTenant && !getItem('SELECTED_CONTEXT')){
      const providerId = getItem('PROVIDER_ID')
      setItem('SELECTED_CONTEXT', {id: providerId, name:getItem('PROVIDER'), uuid: providerId})
    }
  }

  componentDidUpdate = (prevProps: IObject) => {
    if (prevProps.selectedTenant !== this.props.selectedTenant) {
      this.setState(prevState => ({
        dgfParameter: {
          ...prevState.dgfParameter,
          tenant: storage.get.CURRENT_TENANT.name,
          tenantUUID: storage.get.CURRENT_TENANT.uuid,
        },
        dashboard: {
          ...prevState.dashboard,
          context: this.props.selectedTenant
            ? 'tenant-custom'
            : 'provider-custom',
        },
      }))
    }
  }

  renderDashboard = (Dashboard: React.ReactNode) => {
    const { selectedTenant } = this.props
    return <LogContent logConfig={logConfig} selectedTenant={selectedTenant} />
  }

  render() {
    const { dgfParameter, dashboard } = this.state
    return (
      <ContentSection>
        <ContentBody>
          <DGFProvider platform="hc" parameters={dgfParameter}>
            <DGF
              mode={'PROD'}
              dashboard={dashboard}
              renderDashboard={this.renderDashboard}
            />
          </DGFProvider>
        </ContentBody>
      </ContentSection>
    )
  }
}
export default setupA10Container(Audit)
