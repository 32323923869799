import React from 'react'
import './styles/index.less'
import parameters from 'parameters'

interface IPrometheusProps {}

const Prometheus: React.FC<IPrometheusProps> = props => {
  return (
    <div style={{ height: '92vh' }}>
      <iframe
        name="prometheus"
        src={`${parameters.BASE_URL}/prometheus/graph`}
        className="prometheusIframe"
      ></iframe>
    </div>
  )
}

export default Prometheus
