import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Message, A10Button } from '@gui-libraries/widgets'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import storage from 'src/libraries/storage'
import { httpClient } from 'src/libraries/httpClient'
import { IPartitionVersion } from './PartitionDeployAll'
import PartitionDeployAll from './index'

export interface ISlidingPartitionDeployAllProps
  extends IA10ContainerDefaultProps {
  isOpen: boolean
  onRequestClose: (clickDeploy: boolean, hasDP: boolean, id?: string) => void
  partition: IObject
}

export interface ISlidingPartitionDeployAllStates {
  provider: string
  // devicePartitionMap: IObject
  devicePartitionVersions: IPartitionVersion[]
  showValidationAlert: boolean
  validationError: string
  isValidating: boolean
}

class SlidingPartitionDeployAll extends A10Container<
  ISlidingPartitionDeployAllProps,
  ISlidingPartitionDeployAllStates
> {
  constructor(props: ISlidingPartitionDeployAllProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER,
      // devicePartitionMap: {},
      devicePartitionVersions: [],
      showValidationAlert: false,
      validationError: '',
      isValidating: false,
    }
  }

  onRequestOk = async () => {
    const { onRequestClose, partition } = this.props
    const { devicePartitionVersions } = this.state
    const pNames: string[] = []
    const label = `LogicalPartition_${partition.uuid}`
    const clusterPartition = storage.getItem(label)
    const cp = clusterPartition.split('$$&&')
    const clustername = cp[0]
    const pName = cp[1]
    let vNum = 0
    if (partition['candidate-version']) {
      vNum = partition['candidate-version']
    } else if (partition.version) {
      vNum = partition.version
    } else {
      A10Message.error(
        `Logical Partition ${partition.name} cannot be deployed because no version is found.`,
      )
      return
    }
    let headers = {}
    const tenant = storage.get.CURRENT_TENANT
    headers = { tenant: tenant.name }
    const lpLabel = partition.name + '$$&&' + vNum
    pNames.push(lpLabel)
    const deployUri = '/hocapi/v1/deploy/'
    const deployPayload = {
      deploy: {
        'obj-id': partition.uuid,
        version: vNum,
      },
    }
    const overrideList: IObject = []
    for (const dpVersion of devicePartitionVersions) {
      overrideList.push({
        'override-uuid': dpVersion.uuid,
        'override-version': dpVersion.rightVersion,
      })
    }
    if (overrideList.length > 0) {
      deployPayload.deploy['override-list'] = overrideList
    }
    try {
      const { data: Res } = await httpClient.post(deployUri, deployPayload, {
        absoluteBasePath: true,
        headers,
      })
      const workflowID = Res.workflow.uuid
      A10Message.success(
        `Deployment of partition configs to ${clustername} : ${pName} is scheduled`,
      )
      onRequestClose(true, true, workflowID)
    } catch (err) {
      if (
        err.response &&
        err.response.status === 400 &&
        err.response.data &&
        err.response.data.message
      ) {
        A10Message.error(err.response.data.message)
      } else {
        A10Message.error('Deployment Failed')
      }
    }
  }

  validateCLI = async () => {
    this.setState({ isValidating: true })
    const { partition } = this.props
    const { devicePartitionVersions } = this.state
    const payload = {
      'validate-config': {
        'config-list': [],
      },
    }
    let vNum = 0
    if (partition['candidate-version']) {
      vNum = partition['candidate-version']
    } else if (partition.version) {
      vNum = partition.version
    } else {
      A10Message.error(
        `Logical Partition ${partition.name} cannot be validated because no version is found.`,
      )
      this.setState({ isValidating: false })
      return
    }
    payload['validate-config']['config-list'].push({
      uuid: partition.uuid,
      version: vNum,
    })
    for (const dpVersion of devicePartitionVersions) {
      if (dpVersion.rightVersion) {
        payload['validate-config']['config-list'].push({
          uuid: dpVersion.uuid,
          version: dpVersion.rightVersion,
        })
      } else {
        const deployLPFailedMessage =
          'Validation cannot proceed. Not all associated device partitions have saved versions'
        A10Message.error(deployLPFailedMessage)
        this.setState({ isValidating: false })
        return
      }
    }
    const validateUri = '/hpcapi/v3/validate-config'
    try {
      A10Message.loading('Validation in progress', 0)
      const { data: res, statusText: text } = await httpClient.post(
        validateUri,
        payload,
        { absoluteBasePath: true },
      )
      A10Message.destroy()
      A10Message.success('Validation Successful')
      this.setState({ isValidating: false })
    } catch (err) {
      A10Message.destroy()
      this.setState({
        validationError: JSON.stringify(err.response.data),
        showValidationAlert: true,
        isValidating: false,
      })
      console.error(err)
    }
  }

  onCloseValidationAlert = () => {
    this.setState({ showValidationAlert: false, validationError: '' })
  }

  // onFormChange = (checked: boolean, data: IObject) => {
  //   const { devicePartitionMap } = this.state
  //   if (checked) {
  //     devicePartitionMap[data.uuid] = data
  //   } else {
  //     if (devicePartitionMap[data.uuid]) {
  //       delete devicePartitionMap[data.uuid]
  //     }
  //   }
  //   this.setState({ devicePartitionMap })
  // }
  onVersionChange = (devicePartitionVersions: any) => {
    this.setState({ devicePartitionVersions })
  }

  render() {
    const { isOpen, onRequestClose, partition } = this.props
    if (!partition) {
      return null
    }
    const label = `LogicalPartition_${partition.uuid}`
    const clusterPartition = storage.getItem(label)
    if (!!!clusterPartition) {
      return null
    }
    const cp = clusterPartition.split('$$&&')
    const clusterName = cp[0]
    const partitionName = cp[1]
    const Desc = () => {
      return (
        <>
          <HealthStatus type="ongoing" text="C" />
          <span style={{ marginLeft: 7, marginRight: 7 }}>{clusterName}</span>
          <HealthStatus type="ongoing" text="P" />
          <span style={{ marginLeft: 7 }}>{partitionName}</span>
        </>
      )
    }

    const title = 'Deploying to Cluster Partition'
    const saveState =
      (partition['candidate-version'] === undefined && !!!partition.version) ||
      this.state.devicePartitionVersions.some(
        (version: IObject) =>
          version === null || (version && version.rightVersion == 0),
      )
    return (
      <FormatSlidingPage
        isOpen={isOpen}
        modalSize="large"
        title={title}
        description={<Desc />}
        onRequestOk={this.onRequestOk}
        onRequestClose={() => onRequestClose(false, true)}
        saveText="Deploy"
        disableSave={saveState}
        customizedButtons={
          <A10Button
            className="validate-button"
            onClick={this.validateCLI}
            disabled={this.state.isValidating}
          >
            Validate
          </A10Button>
        }
      >
        <PartitionDeployAll
          partition={partition}
          // onFormChange={this.onFormChange}
          onVersionChange={this.onVersionChange}
          showValidationAlert={this.state.showValidationAlert}
          validationError={this.state.validationError}
          closeValidationAlert={this.onCloseValidationAlert}
        />
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(SlidingPartitionDeployAll)
