import React from 'react'

import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'

interface IPlanProps {}

const Plan: React.FC<IPlanProps> = props => {
  return (
    <>
      <ContentHeader type="flex" align="middle" justify="space-between">
        <ContentTitle title="HC Small Plan" />
      </ContentHeader>
      <ContentBody>HC Small Plan</ContentBody>
    </>
  )
}

export default Plan
