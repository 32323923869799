import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
  _,
} from '@gui-libraries/framework'
import {
  A10Col,
  A10Button,
  A10SlidingPage,
  A10Input,
  A10DropdownMenu,
  A10Modal,
  A10Message,
  A10Table,
  A10Loader,
  A10Checkbox,
} from '@gui-libraries/widgets'
import { FileForm } from '@gui-libraries/apps'

import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import { Utilities } from 'src/services'
import { setFormModalSize } from '../../../Common/GeneralObjectBrowser'
import storage from 'src/libraries/storage'
import { httpClient } from 'src/libraries/httpClient'
import {
  getClassList,
  getClassListCount,
} from 'src/redux/httpServices/SharedObj/ClassList'
import deployWrapper from '../../../SharedObjects/DeployWrapper'

import styles from './styles/index.module.less'

const pageSize = 20

export interface IClassListProps extends IA10ContainerDefaultProps {
  data?: IObject[]
  tenant: string
  logicalPartition: string
  scope: string
  total?: number
  handleDeploy: any
}
export interface IClassListState {
  isOpenImportForm: boolean
  deleteModalState: boolean
  classList: IObject
  isLoading: boolean
  search: string
  roundNumber: number
  name: string
  uuid: string
  viewOnly: boolean
  pageNumber: number
  searchText: string
  forceDelete: boolean
}

class ClassList extends A10Container<IClassListProps, IClassListState> {
  delayedSearchText = _.debounce(searchString => {
    this.getData(searchString)
  }, 800)
  Utilities = new Utilities()
  ref = React.createRef()

  constructor(props: IClassListProps) {
    super(props)
    this.state = {
      isOpenImportForm: false,
      deleteModalState: false,
      classList: [],
      isLoading: false,
      search: '',
      roundNumber: 0,
      name: '',
      uuid: '',
      viewOnly: false,
      pageNumber: 1,
      searchText: '',
      forceDelete: false,
    }
  }

  componentDidMount() {
    this.getData()
  }
  onImport = () => {
    this.setState({ name: '', isOpenImportForm: true })
  }

  onImportClose = () => {
    this.setState({
      isOpenImportForm: false,
    })
  }

  onImportSubmit = () => {
    this.onImportClose()
    this.getData()
  }

  onCheckForceDelete = (e: any) => {
    this.setState({ forceDelete: e.target.checked })
  }

  getData = (searchText: string = '') => {
    this.setState({ isLoading: true })
    const {
      A10Dispatchers: { httpRequest },
    } = this.props

    const { pageNumber } = this.state
    const param: IObject = {
      start: (pageNumber - 1) * pageSize,
      count: pageSize,
    }
    if (searchText) {
      param.name = encodeURIComponent(searchText)
    }
    try {
      httpRequest(getClassListCount(searchText))
      httpRequest(getClassList(param)).finally(() => {
        this.setState({
          isLoading: false,
        })
      })
    } catch (e) {
      this.setState({
        isLoading: false,
      })
      console.log(e)
    }
  }
  getColumns = () => {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '80%',
        render: (record: any) => {
          return <span title={record}>{record}</span>
        },
      },
    ]
    if (
      this.props.scope !== 'provider' ||
      (storage.get.ADMIN_LEVEL === 'provider' && !storage.get.IS_OPERATOR_USER)
    ) {
      columns.push({
        title: '',
        dataIndex: '',
        width: '10%',
        render: (record: any) => {
          return (
            <div className={styles.editColumn}>
              <i>
                <A10DropdownMenu
                  menu={[
                    <div key="edit">Edit</div>,
                    <div key="delete">Delete</div>,
                    <div key="deploy">Deploy</div>,
                  ]}
                  style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={this.clickAction.bind(this, record)}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </i>
            </div>
          )
        },
      })
    } else {
      columns.push({
        title: '',
        dataIndex: '',
        width: '20%',
        render: (record: any) => {
          return (
            <div className={styles.editColumn}>
              <i>
                <A10DropdownMenu
                  menu={[<div key="view">View</div>]}
                  style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={this.clickAction.bind(this, record)}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </i>
            </div>
          )
        },
      })
    }
    return columns
  }
  clickAction = (record: IObject, component: JSX.Element, index: number) => {
    if (record.name) {
      this.setState({
        name: record.name,
      })
    }

    const fileSchemaPath =
      'shared-objects/class-list/config/form/file.class-list'
    if (component.key === 'delete') {
      this.setState({
        forceDelete: false,
        deleteModalState: true,
        uuid: record.uuid,
      })
    } else if (component.key === 'deploy') {
      if (this.props.handleDeploy) {
        this.props.handleDeploy(record)
      }
    } else {
      this.setSlidingPage(
        true,
        fileSchemaPath,
        component.key === 'edit' ? false : true,
      )
    }
  }
  setSlidingPage = (isOpen: boolean, schemaPath = '', viewOnly = false) => {
    setFormModalSize(schemaPath)
    this.setState({
      isOpenImportForm: isOpen,
      viewOnly,
    })
  }

  handleOk = async (e: React.MouseEvent<any>) => {
    const { tenant, logicalPartition } = this.props
    const { name, forceDelete } = this.state
    try {
      const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${logicalPartition}/`
      let actionUri = apiPrefix + `file/class-list/${name}`
      if (forceDelete) {
        actionUri += '?force=true'
      }
      await httpClient.delete(actionUri)
      this.getData()
      this.setState({ deleteModalState: false })
      A10Message.success(`${name} was deleted successfully`)
    } catch (e) {
      A10Message.error(`Error: Failed to delete ${name}`)
    }
  }

  handleCancel = (e: React.MouseEvent<any>) => {
    this.setState({ deleteModalState: false })
  }
  getRowKey = (record: IObject) => {
    return record.uuid
  }
  handlePageChange = (page: number) => {
    const { searchText } = this.state
    this.setState({ pageNumber: page }, () => {
      this.getData(searchText)
    })
  }

  handleSearch = (e: any) => {
    const searchString = e.target.value
    this.setState(
      {
        searchText: searchString,
        pageNumber: 1,
      },
      () => {
        this.delayedSearchText(searchString)
      },
    )
  }

  onImportFile = () =>{
    A10Message.loading('uploading class list file')
  }

  render() {
    const {
      isOpenImportForm,
      isLoading,
      name,
      viewOnly,
      pageNumber,
    } = this.state
    const { data, tenant, logicalPartition, total, scope } = this.props
    const params: IObject = { name: name ? name : null }
    const apiPrefix = `hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${logicalPartition}/`
    return (
      <>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col>
            <ContentTitle title="Class List" count={total} />
          </A10Col>
          <A10Col style={{ display: 'flex' }}>
            <A10Input.Search
              type="text"
              name="searchBox"
              placeholder="Search"
              onChange={this.handleSearch}
              className={styles.searchInput}
            />
            {scope !== 'provider' ||
              (storage.get.ADMIN_LEVEL === 'provider' &&
                !storage.get.IS_OPERATOR_USER && (
                  <ActionButton
                    text="Add"
                    onClick={this.onImport}
                    iconProps={{ app: 'global', type: 'add-new' }}
                  />
                ))}
          </A10Col>
        </ContentHeader>
        <ContentBody ref={this.ref}>
          <A10Table
            className={styles.classList}
            columns={this.getColumns()}
            rowKey={this.getRowKey}
            dataSource={data}
            size="small"
            loading={
              isLoading && {
                indicator: <A10Loader container={this.ref} />,
              }
            }
            pagination={{
              hideOnSinglePage: true,
              pageSize: 20,
              current: pageNumber,
              total,
              onChange: this.handlePageChange,
            }}
          />
        </ContentBody>
        <A10Modal
          title="Confirmation"
          visible={this.state.deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>Do you want to delete this Item?</p>
          <A10Checkbox
            checked={this.state.forceDelete}
            onChange={this.onCheckForceDelete}
          >
            Force Delete
          </A10Checkbox>
        </A10Modal>
        <A10SlidingPage isOpen={isOpenImportForm}>
          <FileForm
            title={'Class List'}
            hccEnv={true}
            onClose={this.onImportClose}
            fileType={'class-list'}
            apiprefix={apiPrefix}
            types={['local', 'definition']}
            viewMode={viewOnly}
            params={params}
            onSubmit={this.onImportSubmit}
            onBeforeSubmit={this.onImportFile}
          />
        </A10SlidingPage>
      </>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    data: state.A10Data.getIn(getNS('CLASS_LIST'), []),
    total: state.A10Data.getIn(getNS('CLASS_LIST_COUNT'), 0),
  }
}

export default setupA10Container(deployWrapper(ClassList), mapStateToProps)
