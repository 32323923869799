import React from 'react'
import propTypes from 'prop-types'
import { A10Container, setupA10Container } from '@gui-libraries/framework'
import { A10Table, A10Button, A10Icon, A10Row } from '@gui-libraries/widgets'
import ReactLoading from 'react-loading'

// import AssociateVPort from '../AssociateVPort'
import AssignTemplateToServiceGroup from '../AssignTemplateToServiceGroup'
import AssignHealthMonitorToServiceGroup from '../AssignHealthMonitorToServiceGroup'
import AppServiceAssociateDeploy from '../AppServiceAssociateDeploy'
// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

export interface IDeployedServiceGroupListProps {
  name: string
  type: string
  data: any[]
  tenant: string
  onClickDeploy?: any
  onClickAssign?: any
  onClickCancel?: any
  isLoading?: boolean
  source?: string
}

export interface IDeployedServiceGroupListState {
  showDeployVIPSlidingPage: boolean
  // showAssociateDeploySlidingPage: boolean
}

const defaultVIPColumns = [
  {
    title: 'Service Group',
    dataIndex: 'name',
    key: 'name', // TODO: change this key
  },
  {
    title: 'Working Version',
    dataIndex: 'working_version',
    key: 'working_version',
  },
  {
    title: 'Running Version',
    dataIndex: 'running_version',
    key: 'running_version',
  },
]

class DeployedServiceGroupList extends A10Container<
  IDeployedServiceGroupListProps,
  IDeployedServiceGroupListState
> {
  static contextTypes = {
    refreshTemplateList: propTypes.func.isRequired,
  }
  constructor(props: any) {
    super(props)
    this.state = {
      showDeployVIPSlidingPage: false,
      // showAssociateDeploySlidingPage: false,
    }
  }

  refresh = () => {
    this.context.refreshTemplateList()
  }

  onTriggerDeployVIP = (
    isOpen: boolean,
    record?: IObject,
    needRefresh?: boolean,
  ) => {
    this.setState({ showDeployVIPSlidingPage: isOpen })
    if (needRefresh) {
      this.refresh()
    }
  }

  getAssignRenderBySource = () => {
    const { source, tenant, name, type } = this.props

    if (!source) {
      return (
        <AssignTemplateToServiceGroup
          isShow={this.state.showDeployVIPSlidingPage}
          tenant={tenant}
          name={name}
          type={type}
          onTriggerSwitch={this.onTriggerDeployVIP}
        />
      )
    } else if (source === 'health-monitor') {
      return (
        <AssignHealthMonitorToServiceGroup
          isShow={this.state.showDeployVIPSlidingPage}
          tenant={tenant}
          name={name}
          type={type}
          onTriggerSwitch={this.onTriggerDeployVIP}
        />
      )
    }
    return <div />
  }

  // onTriggerAssociateDeploy = (isOpen: boolean, record: any) => {
  //   this.setState({ showAssociateDeploySlidingPage: isOpen })
  // }

  render() {
    const { data = [], tenant, name, type } = this.props
    return (
      <React.Fragment>
        <A10Row className="action-container">
          <div className="table-header action-wrap">
            <A10Button
              className="action-button"
              onClick={this.onTriggerDeployVIP.bind(this, true)}
            >
              <A10Icon app="global" type="add-new" className="action-icon" />
              Assign
            </A10Button>
          </div>
        </A10Row>
        <div className="inner-table">
          <A10Table
            className="hc-list"
            columns={defaultVIPColumns}
            dataSource={data.map((item: IObject, index: number) => {
              item.key = index
              return item
            })}
            size="small"
            loading={
              this.props.isLoading
                ? {
                    indicator: (
                      <div className="loading-icon">
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
          />
        </div>
        {this.getAssignRenderBySource()}
        {/* <AssignTemplateToServiceGroup
          isShow={this.state.showDeployVIPSlidingPage}
          tenant={tenant}
          name={name}
          type={type}
          existSgList={data}
          onTriggerSwitch={this.onTriggerDeployVIP}
        /> */}
        {/* <AppServiceAssociateDeploy
          isShow={this.state.showAssociateDeploySlidingPage}
          onTriggerSwitch={this.onTriggerAssociateDeploy}
        /> */}
      </React.Fragment>
    )
  }
}

export default setupA10Container(DeployedServiceGroupList)
