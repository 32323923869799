import React, { useState, useCallback } from 'react'
import { _ } from '@gui-libraries/framework'
import {
  A10Button,
  A10Row,
  A10Col,
  A10Select,
  A10Tooltip,
  A10Status,
} from '@gui-libraries/widgets'
import moment from 'moment'
import {
  NetworkARP,
  NetworkLLDP,
  NetworkStaticRoutesBFDIPv4,
  NetworkStaticRoutesBFDIPv6,
  NetworkStaticRoutesRIBIPv4,
  NetworkStaticRoutesRIBIPv6,
  NetworkVlan,
  NetworkVlanGlobal,
} from './Contents'
import iconRefPlay from 'src/assets/images/svg/icon-ref-play.svg'

const NetworkContent: React.FC = (props: IObject) => {
  const {
    deviceObj,
    selectedPartition,
    updateSelectedPartition,
    service,
  } = props

  const [refreshText, setRefreshText] = useState('')
  const [refreshTimer, setRefreshTimer] = useState(0)

  const refreshFunc = useCallback(() => {
    setRefreshText(`Refreshed at ${moment().format('MM/DD/YYYY hh:mm A')}`)
    setRefreshTimer(Date.now())
  }, [deviceObj])

  return (
    <div className="device-detail-container device-content">
      <div className="col-md-12">
        <A10Row type="flex" align="middle" className="action-container">
          <A10Col lg={8}>
            <A10Tooltip title="Service Partitions share all physical attributes for device and networks with Shared partition. So the Service Partitions will not be shown here">
              <span className="fontWeight500 fontSize16">Device Partition</span>
            </A10Tooltip>
            <A10Select
              size="default"
              value={selectedPartition}
              onChange={updateSelectedPartition}
              className="device-partition-selection"
            >
              {(deviceObj?.['partition-list'] || [])
                .filter((pt: IObject) => !pt?.['p-type']?.includes('service'))
                .map((p: IObject) => {
                  return (
                    <A10Select.Option value={p.name} key={p.name}>
                      <span>
                        <A10Status
                          color="colorGreen"
                          title="Dp"
                          className="device-partition-selection-avatar"
                        >
                          {`${deviceObj.name} > ${p.name}`}
                        </A10Status>
                      </span>
                    </A10Select.Option>
                  )
                })}
            </A10Select>
          </A10Col>
          <A10Col lg={16}>
            <div className="table-header table-header-inner">
              <span style={{ fontWeight: 500, color: '#000000' }}>
                {refreshText}
              </span>
              <A10Button className="action-button" onClick={refreshFunc}>
                <img src={iconRefPlay} className="action-icon" />
                Refresh
              </A10Button>
            </div>
          </A10Col>
        </A10Row>
      </div>
      <NetworkVlanGlobal
        deviceObj={deviceObj}
        refreshTimer={refreshTimer}
        selectedPartition={selectedPartition}
      />
      <NetworkVlan
        deviceObj={deviceObj}
        service={service}
        refreshTimer={refreshTimer}
        selectedPartition={selectedPartition}
      />
      <NetworkARP
        deviceObj={deviceObj}
        service={service}
        refreshTimer={refreshTimer}
        selectedPartition={selectedPartition}
      />
      <NetworkStaticRoutesRIBIPv4
        deviceObj={deviceObj}
        service={service}
        refreshTimer={refreshTimer}
        selectedPartition={selectedPartition}
      />
      <NetworkStaticRoutesRIBIPv6
        deviceObj={deviceObj}
        service={service}
        refreshTimer={refreshTimer}
        selectedPartition={selectedPartition}
      />
      <NetworkStaticRoutesBFDIPv4
        deviceObj={deviceObj}
        service={service}
        refreshTimer={refreshTimer}
        selectedPartition={selectedPartition}
      />
      <NetworkStaticRoutesBFDIPv6
        deviceObj={deviceObj}
        service={service}
        refreshTimer={refreshTimer}
        selectedPartition={selectedPartition}
      />
      {selectedPartition.toUpperCase() === 'SHARED' ? (
        <NetworkLLDP deviceObj={deviceObj} refreshTimer={refreshTimer} />
      ) : null}
    </div>
  )
}

export default NetworkContent
