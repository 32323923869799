import moment from 'moment'
const loadTime = moment()
export default {
  namespace: {
    selectedDashboardTimeline: ['HC', 'dashboard', 'timeline'],
    stackChartData: ['dashboard', 'stackedData'],
    tenantStackChartData: ['dashboard', 'provider', 'tenant', 'stackedData'],
    dashboardAlertInfo: ['dashboard', 'alert', 'info'],
    dashboardEventInfo: ['dashboard', 'event', 'info'],
    dashboardAlertPullupInfo: ['dashboard', 'alert', 'pullup', 'info'],
    dashboardAlertPullupInfoNotification: [
      'dashboard',
      'alert',
      'pullup',
      'info',
      'notify',
    ],
    dashboardEventPullupInfo: ['dashboard', 'event', 'pullup', 'info'],
    dashboardAuditPullupInfo: ['dashboard', 'audit', 'pullup', 'info'],
    pullupViewCurrentConfig: ['pullup', 'current'],
    pullupViewCurrentTab: ['pullup', 'current', 'tab'],
    pullupViewCurrentStatus: ['pullup', 'current', 'status'],
    alertPullUpViewStatus: ['pullup', 'current', 'alert_status'],
    pullupViewCurrentParam: ['pullup', 'current', 'param'],
    latestWorkflowInfo: ['workflow', 'latest', 'info'],
    commonWorkflowInfo: ['workflow', 'common', 'info'],
    latestAlertInfo: ['alert', 'latest', 'info'],
  },
  rangePeriod: {
    startTime: Number(
      loadTime
        .clone()
        .subtract(6, 'hours')
        .format('x'),
    ),
    endTime: Number(loadTime.format('x')),
  },
  getDynamicPeriod: () => {
    const currentTime = moment()
    return {
      startTime: Number(
        currentTime
          .clone()
          .subtract(6, 'hours')
          .format('x'),
      ),
      endTime: Number(currentTime.format('x')),
    }
  },
}
