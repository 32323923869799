import React, { useEffect } from 'react'

import storage from 'src/libraries/storage'
import { AppRoot } from 'src/settings/appRoot'
import {
  Platform,
  Resource,
  Providers as ProvidersDashboard,
} from 'src/containers/Dashboard'
import { Providers } from 'src/containers/Providers'
import {
  CannedRoles,
  CannedTriggers,
  LicenseManager,
  MicroServiceConfig,
  StorageManagement,
} from 'src/containers/Platform'
import { AppCatalogs } from 'src/containers/Controller/Apps/AppCatalogs'
import { System } from 'src/containers/System'
import { AdminUser } from 'src/containers/AdminUser'
import { MicroServiceLogs, RunCommands } from 'src/containers/TroubleShooting'
import { Logs, Grafana, Prometheus } from 'src/containers/Monitoring'
import { ProviderSettings } from 'src/containers/Controller/ProviderSettings'
import PullUpAlertViewer from '../Dashboard/Common/PullUpViewer/PullUpAlertViewer'

import styles from './styles/index.module.less'
import SuperAdminImages from '../TroubleShoot/ImageUpgrade/SuperAdminImages'

export interface IAdministrationProps {
  application: IObject
  subApp: IObject
  isAlertPage: boolean
}

const appRoot = new AppRoot()

const Administration: React.FC<IAdministrationProps> = props => {
  const { application, subApp } = props
  const applicationName = application.name.toLowerCase()
  const subAppName = subApp?.name?.toLowerCase() || ''

  useEffect(() => {
    if (storage.get.IS_SUPER_USER) {
      storage.remove.TENANT()
      storage.remove.CURRENT_TENANT()
      storage.set.DRILL_LEVEL('provider')
      storage.set.SUPER_ADMIN_MODE(true)
      storage.set.CURRENT_STATE(applicationName)
      appRoot.setRootScopeElement('currentDrillLevel', 'provider')
      appRoot.setRootScopeElement('currentState', applicationName)
    }
  }, [])

  const renderAnalytics = () => {
    switch (subAppName) {
      case 'platform': {
        return <Platform />
      }
      case 'resource': {
        return <Resource />
      }
      case 'providers': {
        return <ProvidersDashboard />
      }
      default: {
        return null
      }
    }
  }

  const renderPlatform = () => {
    switch (subAppName) {
      case 'licensemanagement': {
        return <LicenseManager />
      }
      case 'appcatalog': {
        return <AppCatalogs />
      }
      case 'microserviceconfig': {
        return <MicroServiceConfig />
      }
      case 'storagemanagement': {
        return <StorageManagement />
      }
      case 'cannedroles': {
        return <CannedRoles />
      }
      case 'cannedtriggers': {
        return <CannedTriggers />
      }
      case 'imageupgrade': {
        return (
          <div className={styles.imagesContainer}>
            <SuperAdminImages />
          </div>
        )
      }
      default: {
        return null
      }
    }
  }

  const renderTroubleShooting = () => {
    switch (subAppName) {
      case 'microservicelogs': {
        return <MicroServiceLogs />
      }
      case 'runcommands': {
        return <RunCommands />
      }
      default: {
        return null
      }
    }
  }

  const renderMonitoring = () => {
    switch (subAppName) {
      case 'logs': {
        return <Logs />
      }
      case 'grafana': {
        return <Grafana />
      }
      case 'prometheus': {
        return <Prometheus />
      }
      default: {
        return null
      }
    }
  }

  switch (applicationName) {
    case 'superadmindashboard': {
      return renderAnalytics()
    }
    case 'providers': {
      return <Providers />
    }
    case 'platform': {
      return renderPlatform()
    }
    case 'system': {
      return <System />
    }
    case 'superadmin': {
      return <AdminUser />
    }
    case 'troubleshooting': {
      return renderTroubleShooting()
    }
    case 'monitoring': {
      return renderMonitoring()
    }
    case 'settings': {
      return <ProviderSettings applicationName={applicationName} />
    }
    case 'notification': {
      return <PullUpAlertViewer isAlertPage={true}/>
    }
    default: {
      return <div>{applicationName}</div>
    }
  }
}

export default Administration
