import React from 'react'
import moment from 'moment'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import { A10Col, A10Form, A10Icon, A10Row } from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { Messages } from 'src/locale/en/Messages'
import { DashboardService } from 'src/services/DashboardService'
import Utilities from 'src/services/Utilities/Utilities'

import './styles/aboutappinfoform.scss'
import parameters from 'parameters'
import { APPLICATION_NAMES_MAP } from 'src/constants/ApplicationConfigs/ApplicationNamesMap'

export interface IAboutAppInfoFormProps extends IA10ContainerDefaultProps {
  app: any
}
export interface IAboutAppInfoFormState {}

class AboutAppInfoForm extends A10Container<
  IAboutAppInfoFormProps,
  IAboutAppInfoFormState
> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  Utilities = new Utilities()
  Messages = new Messages()
  DashboardService = new DashboardService()
  tenants
  drillLevel

  constructor(
    props: IAboutAppInfoFormProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: { get },
    } = context

    this.tenants = get.ALLTENANTS
    this.drillLevel = get.DRILL_LEVEL
  }

  render() {
    let app = this.props.app.metadata
    const { displayName, name } = this.props.app
    const appDisplayVersion = app.app_version
      ? app.app_version.split('-')[1]
      : ''

    // date formatting fails in firefox and safari browser for this format  (2022-03-25 20:30:05 -0700) so removing numbers like -7000 at the end as,
    //date formating works fine when the string has Z at the end.
    const appDateCreated = app.date_created ?? ''
    const dateCreated = appDateCreated.toUpperCase().endsWith('Z')
      ? appDateCreated
      : appDateCreated?.slice(0, appDateCreated?.lastIndexOf(' ') || 0)

    return (
      <div className="about-panel-container">
        <A10Form layout="horizontal">
          <A10Panel
            title={
              <A10IconTextGroup
                text={<>App Info</>}
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            }
            className="formScroll"
          >
            <A10Row className="form-row">
              <A10Col span={6}>{this.Messages.APP_NAME}</A10Col>
              <A10Col span={18}>
                <div>{displayName}</div>
              </A10Col>
            </A10Row>
            <A10Row className="form-row">
              <A10Col span={6}>{this.Messages.APP_VERSION}</A10Col>
              <A10Col span={18}>
                <div className="form-value">{appDisplayVersion}</div>
              </A10Col>
            </A10Row>
            <A10Row className="form-row">
              <A10Col span={6}>{this.Messages.APP_BUILD_NUMBER}</A10Col>
              <A10Col span={18}>
                <div className="form-value">{app.build_number}</div>
              </A10Col>
            </A10Row>
            <A10Row className="form-row">
              <A10Col span={6}>{this.Messages.USER_GUIDE}</A10Col>
              <A10Col span={18}>
                <div className="form-value">
                  <a
                    href={`${parameters.EDUCATION_BASE_URL}#${APPLICATION_NAMES_MAP[name]?.documentationTag}-App`}
                    target="_blank"
                  >
                    Click here
                  </a>
                </div>
              </A10Col>
            </A10Row>
            <A10Row className="form-row">
              <A10Col span={6}>{this.Messages.APP_DATE_CREATED}</A10Col>
              <A10Col span={18}>
                {
                  <div className="form-value">
                    {moment
                      .utc(dateCreated)
                      .local()
                      .format('MMM DD, YYYY hh:mm A')}
                  </div>
                }
              </A10Col>
            </A10Row>
          </A10Panel>
          <A10Panel
            title={
              <A10IconTextGroup
                text={<>App Description</>}
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            }
            className="formScroll"
          >
            {
              <div className="form-desc">
                {APPLICATION_NAMES_MAP[name]?.description}
              </div>
            }
          </A10Panel>
          <A10Panel
            title={
              <A10IconTextGroup
                text={<>What's New</>}
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            }
            className="formScroll"
          >
            {
              <div className="form-value">
                <a
                  href={`${
                    parameters.EDUCATION_BASE_URL
                  }HarmonyController/${APPLICATION_NAMES_MAP[
                    name
                  ]?.shortName?.toLowerCase()}app/${appDisplayVersion?.slice(
                    1,
                  ) || ''}/Release_Notes/Default.htm`}
                  target="_blank"
                >
                  Release Notes
                </a>
              </div>
            }
          </A10Panel>
        </A10Form>
      </div>
    )
  }
}
//ssliapp/4.4
export default setupA10Container(AboutAppInfoForm)
