import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Row, A10Radio } from '@gui-libraries/widgets'

import { IDefaultMethods } from 'src/containers/Controller'
import { ReportsList } from 'src/containers/Controller/Monitor/Reports/ReportsList'
import { ReportSchedule } from 'src/containers/Controller/Monitor/Reports/ReportSchedule'
import { ContentSection } from 'src/components/shared/ContentSection'

const styles = require('./styles/index.module.less')

export interface IReportProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  tenantToggled: boolean
}
export interface IReportState {
  app: string
}
class Report extends A10Container<IReportProps, IReportState> {
  state = {
    app: 'generated_reports',
  }

  onChange = (e: any) => {
    this.setState({
      app: e?.target?.value,
    })
  }

  renderApp = () => {
    const { tenantToggled, defaultMethods } = this.props
    const { app } = this.state
    switch (app) {
      case 'generated_reports': {
        return (
          <ReportsList
            tenantToggled={tenantToggled}
            defaultMethods={defaultMethods}
          />
        )
      }
      case 'scheduled_reports': {
        return (
          <ReportSchedule
            tenantToggled={tenantToggled}
            defaultMethods={defaultMethods}
          />
        )
      }
      default: {
        return (
          <ReportsList
            tenantToggled={tenantToggled}
            defaultMethods={defaultMethods}
          />
        )
      }
    }
  }

  render() {
    return (
      <>
        <ContentSection>
          <A10Row className={styles.radioGroupContainer}>
            <A10Radio.Group
              defaultValue="generated_reports"
              buttonStyle="tab"
              onChange={this.onChange}
            >
              <A10Radio.Button value="generated_reports">
                Generated Reports
              </A10Radio.Button>
              <A10Radio.Button value="scheduled_reports">
                Scheduled Reports
              </A10Radio.Button>
            </A10Radio.Group>
          </A10Row>
          {this.renderApp()}
        </ContentSection>
      </>
    )
  }
}
export default setupA10Container(Report)
