import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10Button,
  A10Row,
  A10Col,
  A10Icon,
  A10Input,
  A10Tooltip,
  A10SlidingPage,
} from '@gui-libraries/widgets'
import { AutoForm } from '@gui-libraries/apps'
import ReactLoading from 'react-loading'
import { RoundNumber } from 'src/components/shared/RoundNumber'

export interface ITrustedKeyProps extends IA10ContainerDefaultProps {
  data?: any[]
}
export interface ITrustedKeyState {
  isLoading: boolean
  isOpenForm: boolean
}

class TrustedKey extends A10Container<ITrustedKeyProps, ITrustedKeyState> {
  constructor(props: ITrustedKeyProps) {
    super(props)
    this.state = {
      isOpenForm: false,
      isLoading: false,
    }
  }

  handleAdd = () => {
    this.setState({
      isOpenForm: true,
    })
  }

  handleClose = () => {
    this.setState({
      isOpenForm: false,
    })
  }

  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'name',
        key: 'name',
        className: 'td-truncate',
        render: (text: any, record: any) => {
          return (
            <A10Tooltip placement="topLeft" overlayClassName="toolTipWidth">
              {record.name || text}
            </A10Tooltip>
          )
        },
      },
    ]

    const { isOpenForm } = this.state
    return (
      <>
        <A10Row
          type="flex"
          align="middle"
          className="action-container"
          style={{ marginTop: 20 }}
        >
          <A10Col lg={8}>
            <div className="table-header-inner pull-left">
              <span className="page-header">Trusted Key</span>
              <RoundNumber number={0} />
            </div>
          </A10Col>
          <A10Col lg={16}>
            <div className="table-header table-header-inner">
              <A10Button className="action-button">
                <A10Icon app="global" type="refresh" className="action-icon" />
                Refresh
              </A10Button>

              <A10Button className="action-button" onClick={this.handleAdd}>
                <A10Icon app="global" type="add-new" className="action-icon" />
                Add
              </A10Button>
              <span
                className="vertical-split-divider"
                style={{ margin: '18px' }}
              />

              <div className="searchbar-container">
                <A10Input.Search
                  type="text"
                  name="searchBox"
                  placeholder="Search"
                />
              </div>
            </div>
          </A10Col>
        </A10Row>
        <A10Row type="flex" align="middle">
          <A10Col lg={24}>
            <A10Table
              columns={columns}
              dataSource={[]}
              size="small"
              // loading={
              //   !this.dataLoaded
              //     ? {
              //       indicator: (
              //         <div className={styles.loadingIcon}>
              //           <ReactLoading
              //             type="bars"
              //             color="#4a90e2"
              //             height={40}
              //             width={40}
              //           />
              //         </div>
              //       ),
              //     }
              //     : false
              // }
              pagination={{ hideOnSinglePage: true, pageSize: 10 }}
            />
            <A10SlidingPage
              isOpen={!!isOpenForm}
              onRequestClose={this.handleClose}
            >
              <AutoForm
                hccEnv={true}
                interceptor={{
                  onCancel: this.handleClose,
                }}
                schemaPath="config/form/ntp.trusted-key"
              />
            </A10SlidingPage>
          </A10Col>
        </A10Row>
      </>
    )
  }
}

export default setupA10Container(TrustedKey)
