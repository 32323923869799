import React from 'react'
import { _ } from '@gui-libraries/framework'
import {
  A10Form,
  A10Modal,
  A10Icon,
  A10Notification,
} from '@gui-libraries/widgets'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import ClusterLogicalPartitions from 'src/components/shared/ClusterLogicalPartitions/ClusterLogicalPartitions'
import httpClient from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

export interface IDeployWrapperState {
  record: undefined | IObject
  deployComfirm: boolean
  deployModalState: boolean
  selectedDeployInfo: undefined | IObject[]
  selectedDeployContent: string
}
const deployWrapper = (Component: any) => {
  return class Wrapper extends React.Component<any, IDeployWrapperState> {
    constructor(props: any) {
      super(props)
      this.state = {
        record: undefined,
        deployComfirm: false,
        deployModalState: false,
        selectedDeployInfo: undefined,
        selectedDeployContent: '',
      }
    }

    handleDeploy = (record: IObject) => {
      this.setState({ deployModalState: true, record })
    }

    handleDeployComfirmOk = () => {
      this.handleDeploySubmit2()
    }

    handleDeployComfirmCancel = () => {
      this.setState({ deployComfirm: false })
    }

    handleDeploySubmit = () => {
      this.setState({ deployComfirm: true })
    }

    handleDeploySubmit2 = async () => {
      const provider = storage.get.PROVIDER
      const { record, selectedDeployInfo } = this.state
      if (record && selectedDeployInfo) {
        const payload = {}
        selectedDeployInfo.map(({ name, partition }: IObject) => {
          const cluster = payload[name] || []
          cluster.push(partition)
          payload[name] = cluster
        })
        const {
          'a10-url': objectUrl,
          // type,
          name: instanceName,
        } = record

        // const instanceUrl = type.replace(/\./g, '/')
        const deployUrl = `/hocapi/v1/provider/${provider}/_deploy/`
        const deployPayload = {
          // 'object-url': `/hpcapi/v3/provider/${provider}/tenant/pso/logical-partition/pso/${instanceUrl}/${instanceName}`,
          'object-url': objectUrl,
          'deploy-target': payload,
        }
        let message = A10Notification.success
        let deploySuccess = true
        let preType =
          ['ca', 'cert', 'key'].indexOf(record.type) >= 0
            ? 'Certificate'
            : `${record.displayCategory || ''} ${record.displayType || ''}`
        preType = record.type === 'ssl' ? 'CRL' : preType
        let description:
          | string
          | JSX.Element = `Successfully pushed ${preType} ${instanceName}`
        try {
          await httpClient.post(deployUrl, deployPayload)
        } catch (err) {
          deploySuccess = false
          const resMessageStr = _.get(err, 'response.data.message', '[]')
          let resList = []
          try {
            const resMessage = resMessageStr
              .replace(/\"/g, '\\"')
              .replace(/u\'/g, "'")
              .replace(/\'/g, '"')
            resList = _.get(JSON.parse(resMessage) || {}, 'logs', [])
          } catch (err) {
            //
          }
          if (resList.length === 0) {
            resList.push(`Failed to push ${preType} ${instanceName}`)
          }
          let successCount = 0
          description = (
            <span>
              {resList.map((msg: string, index: number) => {
                successCount += _.includes(msg, 'Successfully') ? 1 : 0
                return (
                  <span key={index}>
                    {msg}
                    <br />
                  </span>
                )
              })}
            </span>
          )

          message =
            successCount === 0 ? A10Notification.error : A10Notification.warning
        }
        message({ message: 'Deploy Shared Object', description })
        this.handleDeployCancel(false)
      }
    }

    handleDeployCancel = (isOpen: boolean) => {
      this.setState({ deployModalState: isOpen })
      if (!isOpen) {
        this.setState({
          record: undefined,
          selectedDeployInfo: undefined,
          deployComfirm: false,
          selectedDeployContent: '',
        })
      }
    }

    devicePartitionChange = (selected: IObject[]) => {
      this.setState({ selectedDeployInfo: selected })
      if (Array.isArray(selected) && selected.length > 0) {
        const payload = {} as IObject
        const clusterPartitions = [] as string[]
        selected.map(({ name, partition }: IObject) => {
          const cluster = payload[name] || []
          cluster.push(partition)
          payload[name] = cluster
          clusterPartitions.push(`${name}:${partition}`)
        })
        this.setState({ selectedDeployContent: clusterPartitions.join(', ') })
      } else {
        this.setState({
          selectedDeployInfo: undefined,
          selectedDeployContent: '',
        })
      }
    }
    render() {
      const { deployModalState, selectedDeployInfo, record = {} } = this.state
      let preType =
        ['ca', 'cert', 'key'].indexOf(record.type) >= 0
          ? 'Certificate'
          : `${record.displayCategory || ''} ${record.displayType || ''}`
      preType = record.type === 'ssl' ? 'CRL' : preType
      return (
        <>
          <Component {...this.props} handleDeploy={this.handleDeploy} />
          <FormatSlidingPage
            isOpen={deployModalState}
            onRequestOk={this.handleDeploySubmit}
            onRequestClose={this.handleDeployCancel}
            title="Deploy Shared Object"
            description={`Deploy this shared object: ${preType ||
              ''} ${record.name || ''}`}
            disableSave={!selectedDeployInfo}
            saveText="Deploy"
          >
            <div>
              <A10Form layout="horizontal">
                <ClusterLogicalPartitions
                  // radioCheckbox={true}
                  isDropdown={true}
                  onChange={this.devicePartitionChange}
                />
              </A10Form>
            </div>
          </FormatSlidingPage>
          <A10Modal
            title={
              <span>
                <A10Icon
                  app="global"
                  type="question"
                  style={{ color: '#ffba47' }}
                  width="20px"
                  height="20px"
                />{' '}
                Confirmation
              </span>
            }
            visible={this.state.deployComfirm}
            className={styles.deployConfrim}
            onOk={this.handleDeployComfirmOk}
            onCancel={this.handleDeployComfirmCancel}
          >
            <p>{`Do you want to deploy the ${preType || ''} ${record.name ||
              ''} to the following cluster partitions: ${this.state
              .selectedDeployContent || ''}?`}</p>
          </A10Modal>
        </>
      )
    }
  }
}

export default deployWrapper
