import React from 'react'
import moment from 'moment-timezone'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Form,
  A10Radio,
  A10Input,
  A10DatePicker,
  A10Select,
  A10Switch,
  A10Alert,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { DashboardService } from 'src/services/DashboardService'
import { IDefaultMethods } from 'src/containers/Controller'
import { Messages } from 'src/locale/en'
import Utilities from 'src/services/Utilities/Utilities'
import storage from 'src/libraries/storage'

import './styles/schedulereportform.scss'

export interface IScheduleReportFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  onRef?: any
  form: any
  formData: any
  oncancel(e?: React.MouseEvent<HTMLInputElement>): void
  onTenantUpdate(): void
  handleChange?(data: any): void
  generateReport?: IObject[]
  scheduleReport?: IObject[]
}

export interface IScheduleReportFormState {
  templateList: any
  actionList: any
  scheduleReportFormObj: any
  timezones: any
  frequencyValues: any
}

class ScheduleReportForm extends A10Container<
  IScheduleReportFormProps,
  IScheduleReportFormState
> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  frequencyOptions = {
    today: ['Once'],
    '1day': ['Daily'],
    '7days': ['Weekly', 'Monthly'],
    lastWeek: ['Weekly', 'Monthly'],
    last30Days: ['Weekly', 'Monthly'],
    lastmonth: ['Monthly'],
    last3months: ['Monthly'],
    last12months: ['Monthly', 'Yearly'],
  }
  Messages = new Messages()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  customPanelStyle = {
    marginLeft: '10px',
    marginRight: '10px',
  }

  dropDownStyle = {
    width: '200px',
  }

  datePicker = {
    width: '75%',
  }
  START_TIME_EXTRA_MINUTES = 1
  durationOptions: any = []
  reports: any = []
  DATE_STRING_FORMAT = 'YYYY-MM-DD HH:mm'
  constructor(props: IScheduleReportFormProps) {
    super(props)
    this.state = {
      scheduleReportFormObj: this.props.formData,
      templateList: ['ADC', 'CGN'],
      actionList: [],
      timezones: [],
      frequencyValues: ['Daily'],
    }
    const scheduleReportFormObj = this.state.scheduleReportFormObj
    const currentTenant = this.Utilities.getCurrentDrillLevelObject()
    scheduleReportFormObj['scope'] = currentTenant.uuid
    this.setState({
      scheduleReportFormObj,
    })
    this.loadActions()
    this.loadTimezones()
  }

  getDurationOptions = () => {
    const durationOptions = this.Utilities.renderDropdownOpts(
      'duration',
      'REPORTS',
    )
    this.durationOptions = durationOptions
  }

  onActionExpand = (action: any, index: number) => {
    if (!action.detailsString) {
      return true
    }
    const scheduleReportFormObj = this.state.scheduleReportFormObj
    const actions = scheduleReportFormObj.actions
    actions[index].open = !actions[index].open
    scheduleReportFormObj.actions = actions
    this.setState({
      scheduleReportFormObj,
    })
  }

  addAnotherAction() {
    const scheduleReportFormObj = this.state.scheduleReportFormObj
    const actions = scheduleReportFormObj.actions
    actions.push({
      id: '',
      details: {},
      open: false,
      detailsString: '',
    })
    scheduleReportFormObj.actions = actions
    this.setState({
      scheduleReportFormObj,
    })
    this.onChangeCallback(scheduleReportFormObj)
  }

  removeAction(k: number) {
    const scheduleReportFormObj = this.state.scheduleReportFormObj
    const actions = scheduleReportFormObj.actions
    actions.splice(k, 1)
    scheduleReportFormObj.actions = actions
    this.setState({
      scheduleReportFormObj,
    })
    this.onChangeCallback(scheduleReportFormObj)
  }

  loadTimezones() {
    const {
      get: { TARGET_URL: targetUrl = '' },
    } = storage

    const headers = this.Utilities.getXAccountHeaderDetails(false)
    if (!headers) {
      return
    }
    const timezoneResponse = this.DashboardService.getTimezones(
      headers,
      null,
      targetUrl,
    )

    timezoneResponse
      .then((response: any) => {
        if (response?.data?.timezones && response.data.timezones.length > 0) {
          const timezoneStr = this.DashboardService.getDefaultBrowserTimeZone(
            response.data.timezones,
          )
          const { scheduleReportFormObj } = this.state
          scheduleReportFormObj.timezone = timezoneStr
          // this.state.scheduleReportFormObj
          this.setState({
            timezones: response.data.timezones,
            scheduleReportFormObj,
          })
        }
      })
      .catch((error: any) => {
        const message = this.Utilities.returnErrorMessage(error)
        this.Utilities.showMessage('Unable to load timezones', 0, 0, message)
        console.log(error) // we need to create a centralised error handling
      })
  }

  loadActions = () => {
    const {
      get: { TARGET_URL: targetUrl = '' },
    } = storage

    const headers = this.Utilities.getXAccountHeaderDetails(false)
    if (!headers) {
      return
    }
    const actionResponse = this.DashboardService.getActions(
      headers,
      null,
      targetUrl,
    )

    actionResponse
      .then((response: any) => {
        const list = response.data
        if (list && list.length > 0) {
          const filterList = list.filter((actionObj: any) => {
            if (
              actionObj.definition &&
              actionObj.definition.email[0].email_ids &&
              actionObj.definition.email[0].email_ids.length > 0
            ) {
              return true
            }
          })
          this.setState({
            actionList: filterList,
          })
        }
      })
      .catch((error: any) => {
        const message = this.Utilities.returnErrorMessage(error)
        this.Utilities.showMessage('Unable to load actions', 0, 0, message)
        console.log(error) // we need to create a centralised error handling
      })
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  onChangeCallback(data: any) {
    this.props.handleChange(data)
  }

  actionChange = (e: any, index: number) => {
    const scheduleReportFormObj = this.state.scheduleReportFormObj
    const actions = scheduleReportFormObj.actions
    if (this.Utilities.isJson(e)) {
      actions[index] = {
        id: JSON.parse(e).def_id,
        details: JSON.parse(e),
        open: false,
        detailsString: e,
      }
    } else {
      return
    }
    scheduleReportFormObj.actions = actions
    this.setState({
      scheduleReportFormObj,
    })
    this.onChangeCallback(scheduleReportFormObj)
  }

  setDateAndTime(startDate: any, timeValue: any) {
    this.props.form.setFields({ 'start-date': startDate })
    this.props.form.setFields({ 'start-time': timeValue })
  }

  handleChange = (stateName: string, e: any) => {
    if (!this.Utilities.validateField(e)) {
      return
    }

    const formObj = this.state.scheduleReportFormObj
    let newVal = ''
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        newVal = e.target.checked
      } else {
        newVal = e.target.value
      }
    } else {
      newVal = e
    }
    if (stateName === 'timezone') {
      const timezoneObj = JSON.parse(newVal)
      moment.tz.setDefault(timezoneObj.value)
      const startTime = moment().add(this.START_TIME_EXTRA_MINUTES, 'minutes')
      formObj.starts = startTime
      formObj.time = startTime
      this.setDateAndTime(formObj.starts, formObj.time)
    }
    if (stateName === 'starts') {
      formObj.starts = e
      this.props.form.setFields({ 'start-date': formObj.starts })
    }
    if (stateName === 'time') {
      formObj.time = e
      this.props.form.setFields({ 'start-time': formObj.time })
    }
    formObj[stateName] = newVal
    if (stateName === 'type') {
      formObj.tags = [newVal]
    }
    if (stateName === 'duration') {
      if (
        this.frequencyOptions[newVal] &&
        this.frequencyOptions[newVal].length > 0
      ) {
        formObj.occurance = this.frequencyOptions[newVal][0]
      }
      this.setState({
        frequencyValues: this.frequencyOptions[newVal],
      })
    }
    this.onChangeCallback(formObj)

    this.setState({
      scheduleReportFormObj: formObj,
    })
  }

  handleTimeRangeChange = (timeRange: moment.Moment[]) => {
    const timestamps = timeRange.map(time => {
      return time.unix() * 1000
    })
    this.handleChange('timeRange', timestamps)
  }

  validateStartDate = (rule: any, value: any, cb: any) => {
    let isValid = true
    const dateString = moment(value).format(this.DATE_STRING_FORMAT)
    const dateObject = moment(dateString, this.DATE_STRING_FORMAT)
    if (!value) {
      rule.message = this.Messages.VALIDATION_START_DATE_AND_TIME_REQUIRED
      isValid = false
      return isValid ? cb() : cb(true)
    } else if (dateObject < moment()) {
      rule.message = this.Messages.VALIDATION_START_DATE_AND_TIME
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateReportName = (rule: IObject, value: IObject, cb: IObject) => {
    const { generateReport = [], scheduleReport = [] } = this.props
    let isValid: boolean = true
    let index = [...generateReport, ...scheduleReport]?.findIndex((report: IObject) => {
      return report.name === value
    })

    const userRegx = new RegExp(/^([.a-zA-Z0-9_-]){1,50}$/)
    if (!value) {
      rule.message = this.Messages.VALIDATION_REPORT_NAME
      isValid = false
    } else if (value && value.length > 50) {
      rule.message = this.Messages.VALIDATION_REPORT_NAME_LENGTH
      isValid = false
    } else if (value && value.length <= 1) {
      rule.message = this.Messages.VALIDATION_REPORT_NAME_MIN_LENGTH
      isValid = false
    } else if (!userRegx.test(value)) {
      rule.message = this.Messages.VALIDATION_REPORT_NAME_SPECIAL
      isValid = false
    } else if (index > -1) {
      rule.message = this.Messages.DUPLICATE_REPORT_NAME
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  disabledDate(current: any) {
    return current && moment() >= current
  }

  handleUserEmailValidation = (rule: any, value: any, cb: any) => {
    return this.Utilities.validateEmail(value) ? cb() : cb(true)
  }

  handleUsers = (stateName: string, value: any, index?: number) => {
    if (this.Utilities.validateEmail(value)) {
      this.handleChange(stateName, value)
    }
  }

  render() {
    const {
      name,
      type,
      duration,
      description,
      when,
      occurance,
      tags,
      starts,
      // actions,
      timezone,
      exisitingAction,
      actionSelected,
      emails,
      scope,
      time,
    } = this.state.scheduleReportFormObj
    const { frequencyValues } = this.state

    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 13 },
    }

    const { getFieldDecorator } = this.props.form
    const { renderDropdownOpts } = this.Utilities
    this.getDurationOptions()
    const currentTenant = this.Utilities.getCurrentDrillLevelObject()
    
    return (
      <div className="ScheduleReportForm">
        <A10Alert
          className="report_expiry_msg"
          message=""
          description={this.Messages.REPORT_EXPIRY_MESSAGE}
          type="info"
          showIcon
        />
        <A10Form hideRequiredMark={true} layout="horizontal">
          <A10Panel
            title={
              <div className="s-flex">
                <div>
                  <A10IconTextGroup
                    text="Report"
                    icon={<A10Icon style={{ fontSize: 48 }} type="desktop" />}
                  />
                </div>
              </div>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  {this.Messages.REPORT_NAME}
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.REPORT_NAME}
                      helpKey="HELP_SR_REPORT_NAME"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    validator: this.validateReportName.bind(this),
                  },
                ],
                initialValue: name,
              })(
                <A10Input
                  type="text"
                  placeholder={this.Messages.REPORT_NAME}
                  onChange={this.handleChange.bind(this, 'name')}
                />,
              )}
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  {this.Messages.SCOPE}
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.SCOPE}
                      helpKey="HELP_SR_REPORT_SCOPE"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('scope', {
                initialValue: scope,
              })(
                <A10Select
                  placeholder="Please select a scope"
                  onChange={this.handleChange.bind(this, 'scope')}
                >
                  <A10Select.Option
                    key={currentTenant.uuid}
                    value={currentTenant.uuid}
                  >
                    {currentTenant['display-name']}
                  </A10Select.Option>
                </A10Select>,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  {this.Messages.REPORT_TYPE}
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.REPORT_TYPE}
                      helpKey="HELP_SR_REPORT_TYPE"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('type', {
                rules: [
                  {
                    required: true,
                    message: this.Messages.VALIDATION_REPORT_TYPE,
                  },
                ],
                initialValue: type,
              })(
                <A10Select
                  style={this.dropDownStyle}
                  placeholder="Please select type"
                  onChange={(e: any) => this.handleChange('type', e)}
                >
                  {renderDropdownOpts('type', 'REPORTS')}
                </A10Select>,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="">{this.Messages.TAGS}</span>
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="leftTop"
                      title={this.Messages.TAGS}
                      helpKey="HELP_SR_REPORT_TAGS"
                    />
                  </span>
                </>
              }
            >
              <A10Select
                mode="tags"
                value={tags}
                notFoundContent=""
                onChange={(e: any) => this.handleChange('tags', e)}
              >
                {renderDropdownOpts('tags', 'REPORTS')}
              </A10Select>
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  {this.Messages.DESCRIPTION}
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.DESCRIPTION}
                      helpKey="HELP_SR_DESCRIPTION"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('description', {
                rules: [],
                initialValue: description,
              })(
                <A10Input.TextArea
                  autosize={{ minRows: 3, maxRows: 4 }}
                  placeholder={this.Messages.EMPTY}
                  onChange={this.handleChange.bind(this, 'description')}
                />,
              )}
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  {this.Messages.WHEN}
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.WHEN}
                      helpKey="HELP_SR_WHEN"
                    />
                  </span>
                </>
              }
            >
              <A10Radio.Group
                name="when"
                defaultValue={when}
                onChange={this.handleChange.bind(this, 'when')}
              >
                <A10Radio value="onDemand">Now</A10Radio>
                <A10Radio value="schedule">Schedule</A10Radio>
              </A10Radio.Group>
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  {this.Messages.DURATION}
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.DURATION}
                      helpKey="HELP_SR_DURATION"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('duration', {
                rules: [],
                initialValue: duration,
              })(
                <A10Select
                  style={this.dropDownStyle}
                  onChange={this.handleChange.bind(this, 'duration')}
                >
                  {this.durationOptions}
                </A10Select>,
              )}
            </A10Form.Item>
            {when === 'schedule' ? (
              <>
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      {this.Messages.FREQUENCY}
                      <span>
                        <HelpInfo
                          customCls="custom-tooltip-input"
                          placement="left"
                          title={this.Messages.FREQUENCY}
                          helpKey="HELP_SR_FREQUENCY"
                        />
                      </span>
                    </>
                  }
                >
                  <A10Select
                    placeholder="Please select"
                    style={this.dropDownStyle}
                    value={occurance}
                    onChange={(e: any) => this.handleChange('occurance', e)}
                  >
                    {frequencyValues.map((value: any, index: number) => {
                      return (
                        <A10Select.Option key={value} value={value}>
                          {value}
                        </A10Select.Option>
                      )
                    })}
                  </A10Select>
                </A10Form.Item>
              </>
            ) : null}

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  {this.Messages.TIMEZONE}
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.TIMEZONE}
                      helpKey="HELP_SR_REPORT_TIMEZONE"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('timezone', {
                rules: [
                  {
                    required: true,
                    message: this.Messages.VALIDATION_REPORT_TIMEZONE,
                  },
                ],
                initialValue: timezone,
              })(
                <A10Select
                  placeholder="Please select a timezone"
                  onChange={this.handleChange.bind(this, 'timezone')}
                >
                  {this.state.timezones.map((option: any, i: number) => {
                    return (
                      <A10Select.Option
                        key={option.label}
                        value={JSON.stringify(option)}
                      >
                        {option.label}
                      </A10Select.Option>
                    )
                  })}
                </A10Select>,
              )}
            </A10Form.Item>

            {when === 'schedule' ? (
              <>
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      {this.Messages.START_GENERATTING_FROM}
                      <span>
                        <HelpInfo
                          customCls="custom-tooltip-input"
                          placement="left"
                          title={this.Messages.START_GENERATTING_FROM}
                          helpKey="HELP_SR_REPORT_START_GENERATTING_FROM"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('start-date', {
                    rules: [
                      {
                        required: true,
                        validator: this.validateStartDate,
                        type: 'any',
                      },
                    ],
                    initialValue: starts || undefined,
                  })(
                    <A10DatePicker
                      format={this.DATE_STRING_FORMAT}
                      showTime={{
                        className: 'time-container',
                        onChange: (e: any) => this.handleChange('time', e),
                      }}
                      onChange={(e: any) => this.handleChange('starts', e)}
                    />,
                  )}
                </A10Form.Item>
              </>
            ) : null}

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span>{this.Messages.EXISTING_ACTION}</span>
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title="Action Name"
                      helpKey="HELP_MONITOR_ALERT_DEFINITION_EXISITNG_ACTION"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('exisitingAction', {
                rules: [
                  {
                    required: false,
                    message: this.Messages.VALIDATION_WINDOW_SIZE,
                  },
                ],
                initialValue: exisitingAction,
              })(
                <A10Switch
                  defaultChecked={exisitingAction}
                  size="default"
                  onChange={(e: any) => this.handleChange('exisitingAction', e)}
                />,
              )}
            </A10Form.Item>

            {exisitingAction ? (
              <>
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span>{this.Messages.SELECT_ACTION}</span>
                      <span>
                        <HelpInfo
                          customCls="custom-tooltip-input"
                          placement="left"
                          title="Select Action"
                          helpKey="HELP_MONITOR_ALERT_DEFINITION_SELECT_ACTION"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('actionSelected', {
                    rules: [
                      {
                        required: false,
                        message: this.Messages.SELECT_ACTION,
                      },
                    ],

                    initialValue: actionSelected,
                  })(
                    <A10Select
                      placeholder="Please select"
                      onChange={this.handleChange.bind(this, 'actionSelected')}
                    >
                      {this.state.actionList.map((option: any, i: number) => {
                        return (
                          <A10Select.Option
                            key={option.def_name}
                            value={option.def_id}
                          >
                            {option.def_name}
                          </A10Select.Option>
                        )
                      })}
                    </A10Select>,
                  )}
                </A10Form.Item>
              </>
            ) : (
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    <span>{this.Messages.SPECIFY_USERS_MAILS}</span>
                    <span>
                      <HelpInfo
                        customCls="custom-tooltip-input"
                        placement="leftTop"
                        title={this.Messages.SPECIFY_USERS_MAILS}
                        helpKey="HELP_MONITOR_ALERT_DEFINITION_ACTION_SPECIFY_USERS_MAILS"
                      />
                    </span>
                  </>
                }
              >
                {getFieldDecorator('emails', {
                  rules: [
                    {
                      validator: this.handleUserEmailValidation,
                      message: this.Messages.INVALID_EMAIL,
                    },
                  ],
                  initialValue: emails,
                })(
                  <A10Select
                    mode="tags"
                    notFoundContent=""
                    onChange={(e: any) => this.handleUsers('emails', e)}
                  >
                    {renderDropdownOpts('users', 'ACTIONS')}
                  </A10Select>,
                )}
              </A10Form.Item>
            )}
          </A10Panel>
        </A10Form>
      </div>
    )
  }
}
export default setupA10Container(A10Form.create()(ScheduleReportForm))
