import React from 'react'
import parameters from 'parameters'
import { setupA10Container, removeItem } from '@gui-libraries/framework'
import { DGFProvider, DGF } from '@gui-libraries/dgf'

import { DashboardService, Utilities } from 'src/services'
import providerDashboard from './constants/providerDashboard.json'
import providerInfraDashboard from './constants/providerInfraDashboard.json'
import tenantDashboard from './constants/tenantDashboard.json'
import partitionAdminDashboard from './constants/partitionAdminDashboard.json'
import { IDefaultMethods } from 'src/containers/Controller'
import storage from 'src/libraries/storage'
import Settings from './Settings'
import {
  CustomSlices,
  ICustomSlicesParams,
} from '../../../components/dashboard/CustomSlices'

import styles from './styles/index.module.less'
import './styles/index.less'
import { ApplicationConfigs } from 'src/constants/ApplicationConfigs'
import AppRoot from 'src/settings/appRoot'

export interface IDashboardProps {
  defaultMethods: IDefaultMethods
  tenantToggled: any
  currentTab: string
  currentStatus: string
  alertStatus: number
  leftNavCollapsed: boolean
}

export interface IDashboardState {
  tenantDashboardParams?: GuiDGF.HCParameters
  tenant: IObject
}

const MODE = 'PROD'

class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
  DashboardService = new DashboardService()
  providerDashboardRef = React.createRef()
  ApplicationConfigs = new ApplicationConfigs()
  AppRoot = new AppRoot()
  Utilities = new Utilities()

  constructor(props) {
    super(props)

    removeItem('DGF_PROVIDER_CAPACITY')
    removeItem('DGF_PROVIDER_CAPACITY_ALLDATA')

    const tenant = storage.get.CURRENT_TENANT

    this.state = {
      tenant,
      tenantDashboardParams: {
        auth: storage.get.ENCODED_SESSION_ID,
        api: parameters.BASE_URL,
        provider: storage.get.PROVIDER,
        username: storage.get.USER_ID,
        tenant: tenant.name,
        tenantUUID: tenant.uuid,
      },
    }
  }

  componentDidMount = () => {
    this.updateState()
    this.setLogPanelPosition(this.props.leftNavCollapsed)

    const {
      tenant: { name, uuid },
      tenantDashboardParams,
    } = this.state

    if (name && !uuid) {
      this.getTenantObj(name).then(tenant => {
        if (tenant) {
          this.setState({
            tenant,
            tenantDashboardParams: {
              ...tenantDashboardParams,
              tenantUUID: tenant.uuid,
            },
          })
        }
      })
    }
  }

  componentWillReceiveProps(nextProps: IDashboardProps) {
    const { currentTab, currentStatus, alertStatus } = nextProps
    if (
      currentTab === 'alert' &&
      currentStatus === 'max' &&
      alertStatus > 0 &&
      alertStatus !== this.props.alertStatus
    ) {
      this.openVizLogPanel()
    }
  }

  componentDidUpdate = async (
    prevProps: IDashboardProps,
    prevState: IDashboardState,
  ) => {
    const { leftNavCollapsed } = this.props

    if (prevProps.leftNavCollapsed !== leftNavCollapsed) {
      this.setLogPanelPosition(leftNavCollapsed)
    }

    if (prevProps.tenantToggled !== this.props.tenantToggled) {
      let tenant = storage.get.CURRENT_TENANT

      if (tenant.name && !tenant.uuid) {
        tenant = await this.getTenantObj(tenant.name)
      }

      this.setState({
        tenant,
        tenantDashboardParams: {
          ...prevState.tenantDashboardParams,
          tenant: tenant.name,
          tenantUUID: tenant.uuid,
        },
      })
    }
  }

  updateState() {
    const applications = this.ApplicationConfigs.getAvailableApps()
    const applicationIndex = applications.findIndex(key => {
      return key.name.toLowerCase() === 'home'
    })

    const allowedRoles = applications[applicationIndex].allowedRoles

    if (allowedRoles) {
      const adminLevel = storage.get.ADMIN_LEVEL
      const drillLevel = storage.get.DRILL_LEVEL
      const allowedIndex = allowedRoles.findIndex((key: any) => {
        return key === adminLevel
      })

      if (allowedIndex >= 0) {
        if (adminLevel === 'provider' && drillLevel === 'provider') {
          storage.remove.TENANT()
          storage.remove.CURRENT_TENANT()
          storage.set.DRILL_LEVEL('provider')
          this.AppRoot.setRootScopeElement('currentDrillLevel', 'provider')
        }
        storage.remove.SUPER_ADMIN_MODE()
        storage.set.CURRENT_STATE('home')
        this.AppRoot.setRootScopeElement('currentState', 'home')

        return
      }
    }
    this.Utilities.showMessage('INSUFFICENT_PRIVILEGE', 0, 1)
  }

  getTenantObj = async (name: string) => {
    const provider = storage.get.PROVIDER
    const res = await this.DashboardService.getTenantObj(null, null, [
      provider,
      name,
    ])
    return res?.data?.tenant
  }

  openVizLogPanel = () => {
    try {
      const dashboardRef = this.providerDashboardRef.current.dfgRef.current
        .dashboardsRef.current.dashboardRef.current
      dashboardRef.OnClick({
        endPoint: 'hc_event',
      })
    } catch (err) {}
  }

  renderCustomSlices = (argObj: ICustomSlicesParams) => {
    return <CustomSlices {...argObj} />
  }

  renderTenantViewDashboard = () => {
    const {
      tenant: { name, uuid },
      tenantDashboardParams,
    } = this.state

    if (name && uuid) {
      const isPartitionAdminMode = storage.get.IS_PARTITION_USER

      return (
        <DGFProvider platform="hc" parameters={tenantDashboardParams}>
          <DGF
            key={uuid}
            mode={MODE}
            dashboard={
              isPartitionAdminMode
                ? partitionAdminDashboard.dashboard
                : tenantDashboard.dashboard
            }
            renderCustomSlices={this.renderCustomSlices}
          />
        </DGFProvider>
      )
    }
    return null
  }

  setLogPanelPosition = (leftNavCollapsed: boolean) => {
    const logPanelEl = document.getElementsByClassName('logpanel-main')[0]
    if (logPanelEl) {
      if (leftNavCollapsed) {
        logPanelEl.classList.add('left-nav-collapsed')
      } else {
        logPanelEl.classList.remove('left-nav-collapsed')
      }
    }
  }

  render() {
    const { tenant } = this.state
    const infraUser = storage.get.IS_INFRA_USER
    const certAdmin = storage.get.IS_CERT_USER

    const showTenantView = !!tenant.name

    return (
      <div className={`${showTenantView ? `` : styles.dashboardContainer}`}>
        {showTenantView ? (
          this.renderTenantViewDashboard()
        ) : infraUser || certAdmin ? (
          <DGF
            ref={this.providerDashboardRef}
            mode={MODE}
            dashboard={providerInfraDashboard.dashboard}
            renderCustomSlices={this.renderCustomSlices}
          />
        ) : (
          <DGF
            ref={this.providerDashboardRef}
            mode={MODE}
            dashboard={providerDashboard.dashboard}
            renderCustomSlices={this.renderCustomSlices}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentStatus: state.A10Data.getIn(
      Settings.namespace.pullupViewCurrentStatus,
      null,
    ),
    currentTab: state.A10Data.getIn(
      Settings.namespace.pullupViewCurrentTab,
      null,
    ),
    alertStatus: state.A10Data.getIn(
      Settings.namespace.alertPullUpViewStatus,
      '',
    ),
  }
}

export default setupA10Container(Dashboard, mapStateToProps)
