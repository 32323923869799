import React from 'react'
import socketIOClient from 'socket.io-client'
import parameters from 'parameters'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Notification } from '@gui-libraries/widgets'

import { Configs } from 'src/constants/Configs'
import storage from 'src/libraries/storage'
import Utilities from 'src/services/Utilities/Utilities'
import DropdownConstants from 'src/constants/DropdownConstants/DropdownConstants'

import './styles/WebSocketAlert.scss'

export interface IWebSocketAlertProps extends IA10ContainerDefaultProps {}
export interface IWebSocketAlertState {}

class WebSocketAlert extends A10Container<
  IWebSocketAlertProps,
  IWebSocketAlertState
> {
  Configs = new Configs()
  socket: any
  Utilities = new Utilities()
  DropdownConstants = new DropdownConstants()

  componentWillMount() {
    const baseUrl = parameters.BASE_URL
    this.socket = socketIOClient(
      this.Configs.WEBSOCKET_BASE_URL + '/kafkaTopic',
      {
        path: '/socket.io/hcws',
        query: {
          token: storage.get.USER_SESSION_ID,
          provider: storage.get.PROVIDER,
          base: baseUrl,
        },
      },
    )

    this.socket.on('alertKafkaResponseData', (data: any) => {
      if (data.socketError) {
        // error in kafka request sent, missing the kafka topic to listen on or the provider to filterby
      }
      if (data?.def_name) {
        const severity = this.DropdownConstants['ALERTS']['priority'].find(
          (severity: any) => {
            return severity.value === data.severity
          },
        )
        A10Notification.warning({
          message: severity.label + ': ' + data.def_name,
          description: (
            <span>
              Tenant: {data.tenant} <br />
              {data.condition_text}
            </span>
          ),
          duration: 10,
        })
      }
    })
  }

  sendSocketCall() {
    if (this.socket && this.socket.connected) {
      const drillLevel = storage.get.DRILL_LEVEL
      const filterBy: any = {
        drillLevel,
        provider: storage.get.PROVIDER,
        tenants: null,
      }
      if (drillLevel === 'provider') {
        delete filterBy.tenants
      } else {
        const allTenants = storage.get.ALLTENANTS
        const tenantArr = allTenants.map((tenant: any) => {
          return tenant.name
        })
        filterBy.tenants = tenantArr
      }
      if (drillLevel !== 'provider' && !filterBy.tenants) {
        // return without socket call
        return
      } else {
        this.socket.emit('requestAlertFromKafka', {
          kafkaTopic: 'HC_EVENTS',
          filterBy,
          interval: 60,
        })
      }
    }
  }

  componentDidMount() {
    if (this.socket && this.socket.connected) {
      this.sendSocketCall()
    } else {
      const that = this
      setTimeout(function() {
        that.sendSocketCall()
      }, 10000)
    }
  }

  componentWillUnmount() {
    if (this.socket && this.socket.connected) {
      this.socket.close()
    }
  }

  render() {
    return <></>
  }
}

export default setupA10Container(WebSocketAlert)
