import React from 'react'

import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Icon, A10Form, A10Input } from '@gui-libraries/widgets'
import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { IDefaultMethods } from 'src/containers/Controller'
import { Messages } from 'src/locale/en'
import Utilities from 'src/services/Utilities/Utilities'
import { DashboardService } from 'src/services/DashboardService'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import './styles/tenanteditform.scss'

export interface ITenantEditFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  tenant: any
  tenants: any
  form: any
  onRef?: any
  handleChange(key: string, value: any): void
}
export interface ITenantEditFormState {
  tenantName: string
  tenantDName: string
  tenantUpdated: boolean
}

class TenantEditForm extends A10Container<
  ITenantEditFormProps,
  ITenantEditFormState
> {
  Messages = new Messages()
  Utilities = new Utilities()
  DashboardService = new DashboardService()

  constructor(props: ITenantEditFormProps) {
    super(props)
    const { tenantName, tenantDName } = this.props.tenant
    this.state = {
      tenantName: tenantName,
      tenantDName: tenantDName,
      tenantUpdated: false,
    }
  }

  validateDuplicateTenant = (rule: any, tenantDisplayName: any, cb: any) => {
    let isValid: boolean = true
    const index = this.props.tenants.findIndex((key: any) => {
      return (
        key.name === tenantDisplayName ||
        (key['display-name'] && key['display-name'] === tenantDisplayName)
      )
    })
    if (!tenantDisplayName) {
    } else if (
      !this.Utilities.validateDisplayName(tenantDisplayName) ||
      !isNaN(tenantDisplayName)) {
      rule.message = this.Messages.INVALID_TENANT_NAME
      isValid = false
    } else if (
      index > -1 &&
      (tenantDisplayName !== this.props.tenant.tenantName ||
        tenantDisplayName !== this.props.tenant.tenantDName)
    ) {
      rule.message = this.Messages.DUPLICATE_TENANT_NAME
      isValid = false
    } else if (tenantDisplayName.toLowerCase() === 'pso') {
      isValid = false
      rule.message =
        tenantDisplayName +
        ' is a reserverd keyword, Cannot use for tenant name/display name'
    }
    return isValid ? cb() : cb(true)
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  render() {
    const { handleChange } = this.props

    const { tenantName, tenantDName, description } = this.props.tenant

    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }
    const { getFieldDecorator } = this.props.form
    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel
          title={
            <A10IconTextGroup
              text={
                <>
                  <span className="tenantTitle">Tenant Info</span>
                  {/* <span>
                  <HelpInfo customCls="custom-tooltip-head tenantTitle" placement="right" title="Tenant Info" helpKey="HELP_ORG_TENANT_INFORMATION" />
                </span> */}
                </>
              }
              icon={
                <A10Icon
                  style={{ width: 22, height: 22, marginRight: 12 }}
                  app="global"
                  type="form-section"
                  className="sliding-panel-icon"
                />
              }
            />
          }
        >
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                {this.Messages.TENANT_NEW_NAME}
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title={this.Messages.TENANT_NEW_NAME}
                    helpKey="HELP_ORG_TENANT_NEW_NAME"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('tenantName', {
              rules: [
                {
                  required: true,
                  message: this.Messages.EMPTY_TENANT_NAME,
                },
              ],
              initialValue: tenantName,
            })(
              <A10Input
                type="text"
                placeholder={this.Messages.TENANT_NEW_NAME}
                onChange={handleChange.bind(this, 'name')}
                disabled={true}
              />,
            )}
          </A10Form.Item>

          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                {this.Messages.DISPLAY_NAME}
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title={this.Messages.DISPLAY_NAME}
                    helpKey="HELP_ORG_TENANT_DISPLAY_NAME"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('tenantDName', {
              rules: [
                {
                  validator: this.validateDuplicateTenant.bind(this),
                },
              ],
              initialValue: tenantDName,
            })(
              <A10Input
                type="text"
                placeholder={tenantName || this.Messages.DISPLAY_NAME}
                onChange={handleChange.bind(this, 'tenantDName')}
              />,
            )}
          </A10Form.Item>

          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                {this.Messages.DESCRIPTION}
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title={this.Messages.DESCRIPTION}
                    helpKey="HELP_ORG_TENANT_DESCRIPTION"
                  />
                </span>
              </>
            }
          >
            <A10Input.TextArea
              type="text"
              placeholder={this.Messages.DESCRIPTION}
              defaultValue={description}
              onChange={handleChange.bind(this, 'description')}
            />
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    )
  }
}
export default setupA10Container(A10Form.create()(TenantEditForm))
