import * as _ from 'lodash'

const request = (
  service: IObject,
  urlNames: string[],
  name: string,
  func: any,
) => {
  return new Promise((resolve: any, reject: any) => {
    const response = service.getRequest(null, null, urlNames, name)
    response
      .then(async (res: any) => {
        resolve(func(res))
      })
      .catch(reject)
  })
}

export const requestEthernet = (service: IObject, urlNames: string[]) => {
  return new Promise((resolve: any) => {
    const response = service.getRequest(
      null,
      null,
      urlNames,
      'DEVICE_ETHERNET_INTERFACE',
    )
    response.then(async (ethernetRes: any) => {
      const ethernetlist = _.get(ethernetRes, 'data.ethernet-list', [])
      resolve(ethernetlist)
    })
  })
}

export const requestEthernetUpdate = (
  service: IObject,
  urlNames: string[],
  payload: IObject,
) => {
  return service.getRequest(
    null,
    payload,
    urlNames,
    'DEVICE_ETHERNET_INTERFACE_UPDATE',
  )
}

export const requestEthernetStatus = async (
  service: IObject,
  urlNames: string[],
) => {
  const statusObj = {}

  try {
    const statsRes = await service.statsRequest(
      urlNames,
      'DEVICE_ETHERNET_INTERFACE',
    )
    const stats =
      _.get(_.first(_.values(_.get(statsRes, 'data[0]'))), 'ethernet-list') ||
      []

    stats.map((stat: IObject) => {
      const status = statusObj[stat.ifnum] || {}
      status.stats = stat.stats || {}
      statusObj[stat.ifnum] = status
    })
  } catch (err) {
    //
  }
  try {
    const operRes = await service.operRequest(
      urlNames,
      'DEVICE_ETHERNET_INTERFACE',
    )
    const opers =
      _.get(_.first(_.values(_.get(operRes, 'data[0]'))), 'ethernet-list') || []
    opers.map((oper: IObject) => {
      const status = statusObj[oper.ifnum] || {}
      status.oper = oper.oper || {}
      statusObj[oper.ifnum] = status
    })
  } catch (err) {
    //
  }
  return statusObj
}

export const requestVe = (service: IObject, urlNames: string[]) => {
  return request(service, urlNames, 'DEVICE_INTERFACE_VE', (res: any) => {
    return _.get(res, 'data.ve-list', [])
  })
}

export const requestVEDelete = (
  service: IObject,
  urlNames: string[],
  forceDelete: boolean = false,
) => {
  return request(
    service,
    [...urlNames, `?action=deploy${forceDelete ? '&force=true' : ''}`],
    'DEVICE_INTERFACE_VE_DELETE',
    () => {
      return
    },
  )
}

export const requestVEStatus = async (service: IObject, urlNames: string[]) => {
  const statusObj = {} as IObject
  try {
    const statsRes = await service.statsRequest(urlNames, 'DEVICE_INTERFACE_VE')
    const stats =
      _.get(_.first(_.values(_.get(statsRes, 'data[0]'))), 've-list') || []

    stats.map((stat: IObject) => {
      const status = statusObj[stat.ifnum] || {}
      status.stats = stat.stats || {}
      statusObj[stat.ifnum] = status
    })
  } catch (err) {
    //
  }
  try {
    const operRes = await service.operRequest(urlNames, 'DEVICE_INTERFACE_VE')
    const opers =
      _.get(_.first(_.values(_.get(operRes, 'data[0]'))), 've-list') || []
    opers.map((oper: IObject) => {
      const status = statusObj[oper.ifnum] || {}
      status.oper = oper.oper || {}
      statusObj[oper.ifnum] = status
    })
  } catch (err) {
    //
  }

  return statusObj
}

export const requestLIF = (service: IObject, urlNames: string[]) => {
  return request(service, urlNames, 'DEVICE_INTERFACE_LIF', (res: any) => {
    return _.get(res, 'data.lif-list', [])
  })
}

export const requestLIFDelete = (
  service: IObject,
  urlNames: string[],
  forceDelete: boolean = false,
) => {
  return request(
    service,
    [...urlNames, `?action=deploy${forceDelete ? '&force=true' : ''}`],
    'DEVICE_INTERFACE_LIF_DELETE',
    (res: any) => {
      return
    },
  )
}

export const requestLifStatus = async (
  service: IObject,
  urlNames: string[],
) => {
  const statusObj = {} as IObject
  try {
    const statsRes = await service.statsRequest(
      urlNames,
      'DEVICE_INTERFACE_LIF',
    )
    const stats =
      _.get(_.first(_.values(_.get(statsRes, 'data[0]'))), 'lif-list') || []

    stats.map((stat: IObject) => {
      const status = statusObj[stat.ifnum] || {}
      status.stats = stat.stats || {}
      statusObj[stat.ifnum] = status
    })
  } catch (err) {
    //
  }
  try {
    const operRes = await service.operRequest(urlNames, 'DEVICE_INTERFACE_LIF')
    const opers =
      _.get(_.first(_.values(_.get(operRes, 'data[0]'))), 'lif-list') || []
    opers.map((oper: IObject) => {
      const status = statusObj[oper.ifnum] || {}
      status.oper = oper.oper || {}
      statusObj[oper.ifnum] = status
    })
  } catch (err) {
    //
  }
  return statusObj
}

export const requestTrunk = (service: IObject, urlNames: string[]) => {
  return request(service, urlNames, 'DEVICE_INTERFACE_TRUNK', (res: any) => {
    return _.get(res, 'data.trunk-list', [])
  })
}

export const requestTrunkDelete = (
  service: IObject,
  urlNames: string[],
  forceDelete: boolean = false,
) => {
  return request(
    service,
    [...urlNames, `?action=deploy${forceDelete ? '&force=true' : ''}`],
    'DEVICE_INTERFACE_TRUNK_DELETE',
    (res: any) => {
      return
    },
  )
}

export const requestTrunkStatus = async (
  service: IObject,
  urlNames: string[],
) => {
  const statusObj = {} as IObject
  try {
    const statsRes = await service.statsRequest(
      urlNames,
      'DEVICE_INTERFACE_TRUNK',
    )
    const stats =
      _.get(_.first(_.values(_.get(statsRes, 'data[0]'))), 'trunk-list') || []

    stats.map((stat: IObject) => {
      const status = statusObj[stat.ifnum] || {}
      status.stats = stat.stats || {}
      statusObj[stat.ifnum] = status
    })
  } catch (err) {
    //
  }

  try {
    const operRes = await service.operRequest(
      urlNames,
      'DEVICE_INTERFACE_TRUNK',
    )
    const opers =
      _.get(_.first(_.values(_.get(operRes, 'data[0]'))), 'trunk-list') || []
    opers.map((oper: IObject) => {
      const status = statusObj[oper.ifnum] || {}
      status.oper = oper.oper || {}
      statusObj[oper.ifnum] = status
    })
  } catch (err) {
    //
  }

  try {
    const netOperRes = await service.operRequest(
      urlNames,
      'DEVICE_NETWORK_TRUNK',
    )
    const opers =
      _.get(
        _.first(_.values(_.get(netOperRes, 'data[0]'))),
        'trunk.oper.trunk',
      ) || []
    opers.map((oper: IObject) => {
      const status = statusObj[oper.trunk_id] || {}
      status.netOper = oper || {}
      statusObj[oper.trunk_id] = status
    })
  } catch (err) {
    //
  }

  return statusObj
}

export const requestTunnel = (service: IObject, urlNames: string[]) => {
  return request(service, urlNames, 'DEVICE_INTERFACE_TUNNEL', (res: any) => {
    return _.get(res, 'data.tunnel-list', [])
  })
}

export const requestTunnelDelete = (
  service: IObject,
  urlNames: string[],
  forceDelete: boolean = false,
) => {
  return request(
    service,
    [...urlNames, `?action=deploy${forceDelete ? '&force=true' : ''}`],
    'DEVICE_INTERFACE_TUNNEL_DELETE',
    (res: any) => {
      return
    },
  )
}

export const requestTunnelStatus = async (
  service: IObject,
  urlNames: string[],
) => {
  const statusObj = {} as IObject
  try {
    const statsRes = await service.statsRequest(
      urlNames,
      'DEVICE_INTERFACE_TUNNEL',
    )
    const stats =
      _.get(_.first(_.values(_.get(statsRes, 'data[0]'))), 'tunnel-list') || []

    stats.map((stat: IObject) => {
      const status = statusObj[stat.ifnum] || {}
      status.stats = stat.stats || {}
      statusObj[stat.ifnum] = status
    })
  } catch (err) {
    //
  }
  try {
    const operRes = await service.operRequest(
      urlNames,
      'DEVICE_INTERFACE_TUNNEL',
    )
    const opers =
      _.get(_.first(_.values(_.get(operRes, 'data[0]'))), 'tunnel-list') || []
    opers.map((oper: IObject) => {
      const status = statusObj[oper.ifnum] || {}
      status.oper = oper.oper || {}
      statusObj[oper.ifnum] = status
    })
  } catch (err) {
    //
  }
  return statusObj
}

export const requestInterfaceGlobal = async (
  service: IObject,
  urlNames: string[],
) => {
  let srcIpHashEnable = false
  let veStats = false
  let synCookiethreshold = 0
  let jambo = 0
  try {
    srcIpHashEnable = (await request(
      service,
      urlNames,
      'DEVICE_INTERFACE_SYSTEM',
      (res: any) => {
        const num = _.get(res, 'data.system.src-ip-hash-enable')
        return num === 1
      },
    )) as boolean
  } catch (err) {
    //
  }

  try {
    veStats = (await request(
      service,
      urlNames,
      'DEVICE_INTERFACE_VESTATS',
      (res: any) => {
        const num = _.get(res, 'data.ve-stats.enable')
        return num === 1
      },
    )) as boolean
  } catch (err) {
    //
  }

  try {
    synCookiethreshold = (await request(
      service,
      urlNames,
      'DEVICE_INTERFACE_IP_TCP',
      (res: any) => {
        const num = _.get(res, 'data.tcp.syn-cookie.threshold')
        return num || 0
      },
    )) as number
  } catch (err) {
    //
  }

  try {
    jambo = (await request(
      service,
      urlNames,
      'DEVICE_INTERFACE_SYSTEM_JUMBO',
      (res: any) => {
        console.log('DEVICE_INTERFACE_SYSTEM_JUMBO', res)
        return 0
      },
    )) as number
  } catch (err) {
    //
  }

  return {
    jambo,
    synCookiethreshold,
    veStats,
    srcIpHashEnable,
  }
}

export const requestManagement = async (
  service: IObject,
  urlNames: string[],
) => {
  return await request(
    service,
    urlNames,
    'DEVICE_INTERFACE_MANAGEMENT',
    (res: any) => {
      return _.get(res, 'data.management', {})
    },
  )
}
