import React from 'react'

export const NavOrganization = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="214"
    height="536"
    viewBox="0 0 214 536"
  >
    <defs>
      <filter
        id="bgjd1mc98a"
        width="115.5%"
        height="141.7%"
        x="-7.7%"
        y="-20.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="31.136" />
      </filter>
      <filter
        id="5b54temqyb"
        width="101%"
        height="101.7%"
        x="-.5%"
        y="-.9%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
      </filter>
      <rect id="w7w4ymm7nc" width="1200" height="694" x="0" y="0" rx="4" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <path
          fill="#FAFBFF"
          d="M0 0H1280V860H0z"
          transform="translate(-941.000000, -185.000000)"
        />
        <path
          fill="#D8D8D8"
          fill-opacity=".5"
          d="M0 0H1281V859H0z"
          transform="translate(-941.000000, -185.000000)"
        />
        <g>
          <path
            fill="#000"
            fill-opacity=".2"
            d="M157.07 290h887.77c3.344 0 6.335 2.08 7.5 5.216l148.862 400.953c1.538 4.142-.573 8.747-4.715 10.284-.67.25-1.37.407-2.083.47C958.9 727.64 776.697 738 647.794 738c-129.263 0-342.623-10.417-640.078-31.251-4.407-.31-7.73-4.132-7.421-8.54.053-.76.215-1.51.48-2.225L149.57 295.216c1.164-3.136 4.155-5.216 7.5-5.216z"
            filter="url(#bgjd1mc98a)"
            transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000)"
          />
          <g transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000)">
            <use
              fill="#000"
              filter="url(#5b54temqyb)"
              xlinkHref="#w7w4ymm7nc"
            />
            <use fill="#FFF" xlinkHref="#w7w4ymm7nc" />
          </g>
          <path
            fill="#2A3D56"
            d="M898 96H1112V633H898z"
            transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000)"
          />
          <g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 347.000000) translate(9.000000, 34.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Tenants
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 347.000000) translate(9.000000, 70.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Users
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 347.000000) translate(9.000000, 106.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Access Groups
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 347.000000) translate(9.000000, 142.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Roles
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 347.000000) translate(9.000000, 178.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Licenses
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 347.000000) translate(9.000000, 214.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Authentication
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 347.000000) translate(9.000000, 250.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Agreements
                  </tspan>
                </text>
              </g>
            </g>
            <g>
              <g stroke="#FFF" stroke-linecap="round">
                <path
                  d="M17.324 17.458h5.78c.22 0 .396.178.396.396v4.467c0 .219-.177.397-.395.397h-5.78c-.22 0-.396-.178-.396-.397v-4.467c0-.218.177-.396.395-.396zm-16.429 0h5.78c.22 0 .396.178.396.396v4.467c0 .219-.177.397-.395.397H.896c-.22 0-.396-.178-.396-.397v-4.467c0-.218.177-.396.395-.396zm8.215 0h5.78c.218 0 .396.178.396.396v4.467c0 .219-.178.397-.397.397H9.11c-.218 0-.396-.178-.396-.397v-4.467c0-.218.178-.396.396-.396zm11.236-.062V11.59H3.554v6.068M12 17.3V6.577M8.984 1h6.031c.228 0 .414.184.414.412v4.663c0 .228-.186.412-.414.412h-6.03c-.229 0-.414-.184-.414-.412V1.412c0-.228.185-.412.413-.412z"
                  transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 347.000000)"
                />
              </g>
              <text
                fill="#FFF"
                font-family="Rubik-Regular, Rubik"
                font-size="16"
                transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 347.000000)"
              >
                <tspan x="34" y="18">
                  Organization
                </tspan>
              </text>
            </g>
          </g>
          <g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 126.000000) translate(9.000000, 34.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Alerts
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 126.000000) translate(9.000000, 70.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Reports
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 126.000000) translate(9.000000, 106.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Workflows
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 126.000000) translate(9.000000, 142.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Events
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 126.000000) translate(9.000000, 178.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Audits
                  </tspan>
                </text>
              </g>
            </g>
            <g>
              <g stroke="#FFF" stroke-linecap="round">
                <path
                  d="M8.974 12.308c0-1.74 1.416-3.154 3.156-3.154 1.74 0 3.154 1.415 3.154 3.154M.5 15.13h14.705m4.901 0H23.5m-4.525 0c0 .624-.506 1.132-1.131 1.132-.624 0-1.13-.508-1.13-1.132 0-.624.506-1.131 1.13-1.131.625 0 1.131.507 1.131 1.131zm4.525 3.324H8.795m-4.901 0H.5m4.525 0c0-.624.506-1.131 1.131-1.131s1.131.507 1.131 1.131c0 .625-.506 1.132-1.13 1.132-.626 0-1.132-.507-1.132-1.132zM23.5 21.471h-9.426m-4.901 0H.5m10.18 0c0-.625.507-1.132 1.132-1.132.625 0 1.13.507 1.13 1.132 0 .623-.505 1.131-1.13 1.131-.625 0-1.131-.508-1.131-1.131z"
                  transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 126.000000)"
                />
                <path
                  d="M.819 12.308v-1.37c0-.225.183-.41.41-.41h2.459c.219-1.055.623-2.04 1.205-2.93L3.16 5.863c-.16-.159-.16-.417 0-.578l1.942-1.944c.16-.158.419-.158.58 0l1.732 1.736c.891-.584 1.876-.988 2.933-1.21v-2.46c0-.223.182-.407.408-.407h2.748c.226 0 .409.184.409.407v2.46c1.056.222 2.04.626 2.932 1.213l1.737-1.739c.16-.158.419-.158.578 0l1.942 1.944c.16.16.16.42 0 .578L19.366 7.6c.58.892.985 1.873 1.203 2.928h2.464c.224 0 .41.184.41.409v1.372"
                  transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 126.000000)"
                />
              </g>
              <text
                fill="#FFF"
                font-family="Rubik-Regular, Rubik"
                font-size="16"
                transform="translate(-941.000000, -185.000000) translate(43.000000, 89.000000) translate(914.000000, 126.000000)"
              >
                <tspan x="34" y="18">
                  Monitor
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
