import React from 'react'

import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { httpClient } from 'src/libraries/httpClient'
import { getCLIfromStatusMessage } from 'src/libraries/common'
import { ITaskInfor } from 'src/containers/Controller/Dashboard/Common/PullUpWorkflow/TasksStatusList'
import WorkflowStatus from './index'

export interface ISlidingWorkflowStatusListProps
  extends IA10ContainerDefaultProps {
  isOpen: boolean
  assocObject?: IObject
  idList: string[]
  objectList?: string[]
  clusterName?: string
  deviceName?: string
  title: string
  onRequestClose: () => void
}

export interface ISlidingWorkflowStatusListStates {
  workflowList: IWorkflowStatus[]
}

export interface IWorkflowStatus {
  status: string
  statusMessage: string
  statusCLI: string
  taskList: ITaskInfor[]
}

class SlidingWorkflowStatusList extends A10Container<
  ISlidingWorkflowStatusListProps,
  ISlidingWorkflowStatusListStates
> {
  constructor(props: ISlidingWorkflowStatusListProps) {
    super(props)
    this.state = {
      workflowList: [],
    }
  }

  componentDidUpdate(prevProps: any) {
    const { idList = [] } = this.props
    if (
      prevProps.isOpen !== this.props.isOpen &&
      this.props.isOpen === true &&
      idList.length > 0
    ) {
      this.refreshWorkflowDetail()
    }
  }

  refreshWorkflowDetail = async (index?: number) => {
    const { idList } = this.props
    const output: IWorkflowStatus[] = []
    if (index === undefined) {
      await Promise.all(
        idList.map(async id => {
          const uri = `/hwcapi/v1/workflow/${id}`
          const { data: result } = await httpClient.get(uri, {
            absoluteBasePath: true,
          })
          const taskList = result.workflow.tasks
          const taskStatus = result.workflow.status
          const uri2 = `/datastore/object-workflow-map?wf_id=${id}`
          const { data: result2 } = await httpClient.get(uri2, {
            absoluteBasePath: true,
          })
          let statusMessage = ''
          let statusCLI = ''
          if (result2.resultSet.length > 0) {
            statusMessage = Object.values(result2.resultSet[0])[0]
              .status_message
            statusCLI = getCLIfromStatusMessage(statusMessage)
          }
          output.push({
            taskList,
            status: taskStatus,
            statusMessage,
            statusCLI,
          })
        }),
      )
      this.setState({ workflowList: output })
    } else {
      const { workflowList } = this.state
      const id = idList[index]
      const uri = `/hwcapi/v1/workflow/${id}`
      const { data: result } = await httpClient.get(uri, {
        absoluteBasePath: true,
      })
      const taskList = result.workflow.tasks
      const taskStatus = result.workflow.status
      const uri2 = `/datastore/object-workflow-map?wf_id=${id}`
      const { data: result2 } = await httpClient.get(uri2, {
        absoluteBasePath: true,
      })
      let statusMessage = ''
      let statusCLI = ''
      if (result2.resultSet.length > 0) {
        statusMessage = Object.values(result2.resultSet[0])[0].status_message
        statusCLI = getCLIfromStatusMessage(statusMessage)
      }
      workflowList[index].taskList = taskList
      workflowList[index].status = taskStatus
      workflowList[index].statusCLI = statusCLI
      workflowList[index].statusMessage = statusMessage
      this.setState({ workflowList })
    }
  }

  renderWorkflowList = () => {
    const { objectList } = this.props
    const { workflowList = [] } = this.state
    return workflowList.map((item, index) => {
      return (
        <WorkflowStatus
          key={index}
          index={index}
          multi={true}
          title={
            (objectList && objectList[index]) ||
            this.props.clusterName ||
            this.props.deviceName ||
            ''
          }
          showMessage={true}
          tasks={item.taskList}
          status={item.status}
          statusMessage={item.statusMessage}
          statusCLI={item.statusCLI}
          refresh={this.refreshWorkflowDetail}
        />
      )
    })
  }

  render() {
    const { onRequestClose, isOpen, idList = [] } = this.props
    if (idList.length === 0) {
      return null
    }

    return (
      <FormatSlidingPage
        isOpen={isOpen}
        title={this.props.title || ''}
        onRequestOk={onRequestClose}
        onRequestClose={onRequestClose}
        saveText="Hide"
        hideCancel={true}
      >
        {this.renderWorkflowList()}
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(SlidingWorkflowStatusList)
