import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Radio, A10Row } from '@gui-libraries/widgets'

import { ContentSection } from 'src/components/shared/ContentSection'
import { Credential } from 'src/containers/Controller/Infrastructure/Credential'
import { VMDevices } from 'src/containers/Controller/Infrastructure/VMDevices'

const styles = require('./styles/index.module.less')

export interface IOrchestrationProps extends IA10ContainerDefaultProps {}
export interface IOrchestrationState {
  tabValue: string
}

class Orchestration extends A10Container<
  IOrchestrationProps,
  IOrchestrationState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      tabValue: 'virtualdevices',
    }
  }

  tabChange = (event: any) => {
    let { tabValue } = this.state
    if (event.target) {
      if (event.target.type === 'checkbox') {
        tabValue = event.target.checked
      } else {
        tabValue = event.target.value
      }
    } else {
      tabValue = event
    }

    this.setState({ tabValue })
  }

  render() {
    const { tabValue } = this.state
    return (
      <ContentSection>
        <A10Row className={styles.mainTabsRow}>
          <A10Radio.Group
            value={tabValue}
            buttonStyle="tab"
            onChange={this.tabChange.bind(this)}
          >
            <A10Radio.Button value="virtualdevices">
              Virtual Devices
            </A10Radio.Button>
            <A10Radio.Button value="credential">Credential</A10Radio.Button>
          </A10Radio.Group>
        </A10Row>
        {tabValue === 'virtualdevices' ? (
          <VMDevices />
        ) : tabValue === 'credential' ? (
          <Credential />
        ) : null}
      </ContentSection>
    )
  }
}

export default setupA10Container(Orchestration)
