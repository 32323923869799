import {
  A10Container,
  IA10ContainerDefaultProps,
  setupA10Container,
} from '@gui-libraries/framework'
import {
  A10Button,
  A10Col,
  A10Form,
  A10Input,
  A10Row,
  A10Select,
} from '@gui-libraries/widgets'
import React from 'react'
import A10Panel from 'src/components/ADC/A10Panel'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import { IDefaultMethods } from 'src/containers/Controller'
import storage from 'src/libraries/storage'
import { Messages } from 'src/locale/en/Messages'
import { Utilities } from 'src/services'
import { DashboardService } from 'src/services/DashboardService'
import { AuthenticationService } from 'src/services/index'
import './styles/index.less'

export interface IAdminUserFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  addUserObj: any
  form: any
  onRef?: any
  allUsers: any
  userInfo: any
  refreshUsers: () => void
  handleChange?: (data: any) => void
}

export interface IAdminUserFormState {
  addUserObj: any
  userStatus: any
  activationLink: string
}

class AdminUserForm extends A10Container<
  IAdminUserFormProps,
  IAdminUserFormState
> {
  DashboardService = new DashboardService()
  AuthenticationService = new AuthenticationService()
  Messages = new Messages()
  Utilities = new Utilities()
  editMode = false

  constructor(props: IAdminUserFormProps) {
    super(props)
    if (props.userInfo) {
      this.getActivationLink()
    }
    if (props.addUserObj.userId !== '') {
      this.editMode = true
    }
    this.state = {
      addUserObj: this.props.addUserObj,
      userStatus: this.props.userInfo,
      activationLink: '',
    }
  }
  onChangeCallback(data: any) {
    this.props.handleChange(data)
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }
  handleChange = (stateName: string, e: any) => {
    const userObj = this.state.addUserObj
    if (e?.target) {
      if (e.target.type === 'checkbox') {
        userObj[stateName] = e.target.checked
      } else {
        userObj[stateName] = e.target.value
      }
    } else {
      userObj[stateName] = e
    }
    this.onChangeCallback(userObj)
    this.setState({
      addUserObj: userObj,
    },
      () => {
        if (stateName === 'lastName') {
          const { form } = this.props
          form.validateFields(
            ['firstName', 'lastName'],
            () => { },
          )
        }
      },
    )
  }

  getActivationLink = () => {
    const activationLinkRequest = this.DashboardService.getUserActivationMail(
      null,
      null,
      this.props.userInfo['user-id'],
    )
    activationLinkRequest.then((response: any) => {
      if (response.data.link === 'Not an unverified user') {
        const userStatus = this.state.userStatus
        userStatus.state = 'Active'
        this.setState({
          activationLink: '',
          userStatus,
        })
      } else {
        this.setState({
          activationLink: response.data.link,
        })
      }
      return response.data.link
    })
  }

  validateName = (rule: IObject, value: string, cb: string) => {
    const { userInfo } = this.props
    const { firstName, lastName } = this.state?.addUserObj
    let isValid: boolean = true
    const userRegx = new RegExp(/^([.a-zA-Z0-9_-]){1,32}$/)
    const name = firstName + lastName
    if ('' === name) {
      rule.message = this.Messages.EMPTY_USER_NAME
      isValid = false
    } else if (firstName === '') {
      rule.message = this.Messages.EMPTY_FIRST_NAME
      isValid = false
    } else if (firstName !== userInfo?.['first-name'] && !userRegx.test(firstName)) {
      rule.message = this.Messages.INVALID_FIRST_NAME
      isValid = false
    } else if (firstName !== '' && lastName === '') {
      rule.message = this.Messages.EMPTY_LAST_NAME
      isValid = false
    } else if (lastName !== userInfo?.['last-name'] && !userRegx.test(lastName)) {
      rule.message = this.Messages.INVALID_LAST_NAME
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  handleValidateUserID = (rule: any, userID: any, cb: any) => {
    let index = -1
    let isValid: boolean = true
    const userIDPattern = new RegExp(this.Utilities.USERID_PATTERN)
    if (!this.editMode) {
      if ('' === userID) {
        rule.message = this.Messages.EMPTY_USER_ID
        isValid = false
      } else if (!userIDPattern.test(userID)) {
        rule.message =
          this.Messages.INVALID_USER_ID +
          ', it cannot exceed 64 letters and no special characters (except ,.()@-_=)'
        isValid = false
      } else {
        index = this.props.allUsers.findIndex((key: any) => {
          return key['user-id'] === userID
        })
        if (index > -1) {
          rule.message = this.Messages.DUPLICATE_USER_NAME
          isValid = false
        }
      }
    }

    return isValid ? cb() : cb(true)
  }

  resetPassword = () => {
    const user = {
      user: this.state.addUserObj.userId,
    }
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      charset: 'utf-8',
      Authorization: storage.get.ENCODED_SESSION_ID,
      provider: 'root',
    }
    const promise = this.AuthenticationService.resetPassword(
      headers,
      this.state.addUserObj.userId,
      user,
    )
    promise
      .then((resp: any) => {
        if (resp.data.message) {
          const message =
            'Your password change request has been received,' +
            ' ' +
            resp.data.message
          this.Utilities.showMessage(message, 1, false)
        } else {
          const message = 'Password has been sent to your email account'
          this.Utilities.showMessage(message, 1, false)
        }
        this.props.refreshUsers()
      })
      .catch((error: any) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.status === 404
        ) {
          this.Utilities.showMessage('NO_RECORDS_FOUND', 0, true)
        } else if (
          error &&
          error.response &&
          error.response.status &&
          error.response.status === 500
        ) {
          this.Utilities.showMessage('INTERNAL_SERVER_ERROR', 0, true)
        } else {
          const msg =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : ''
          this.Utilities.showMessage('Password reset error', 0, 0, msg)
        }
        this.props.refreshUsers()
      })
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 13 },
    }

    const {
      firstName,
      lastName,
      userId,
      emailId,
      contactNumber,
      role,
    } = this.state.addUserObj
    const { getFieldDecorator } = this.props.form

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel showHeader={false} className='admin-user-panel'>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="admin-label mandatoryField">
                  {this.Messages.NAME}
                </span>
                <HelpInfo
                  customCls="custom-tooltip-input"
                  placement="left"
                  title={this.Messages.NAME}
                  helpKey=""
                />
              </>
            }
          >
            <>
              <A10Row>
                <A10Col span={12}>
                  {getFieldDecorator('firstName', {
                    rules: [
                      {
                        validator: this.validateName.bind(this),
                      },
                    ],
                    initialValue: firstName,
                  })(
                    <A10Input
                      type="text"
                      placeholder={this.Messages.USER_FIRSTNAME}
                      onChange={this.handleChange.bind(this, 'firstName')}
                    />,
                  )}
                </A10Col>
                <A10Col span={12} className="left-pad">
                  {getFieldDecorator('lastName', {
                    rules: [
                      {
                        validator: this.validateName.bind(this),
                      },
                    ],
                    initialValue: lastName,
                  })(
                    <A10Input
                      type="text"
                      placeholder={this.Messages.USER_LASTNAME}
                      onChange={this.handleChange.bind(this, 'lastName')}
                    />,
                  )}
                </A10Col>
              </A10Row>
            </>
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="admin-label mandatoryField">
                  {this.Messages.EMAIL}
                </span>
                <HelpInfo
                  placement="left"
                  title={` ${this.Messages.EMAIL} *`}
                  helpKey="HELP_ORG_USER_EMAIL_ID"
                />
              </>
            }
          >
            {getFieldDecorator('emailId', {
              rules: [
                { required: true, message: this.Messages.EMPTY_EMAIL },
                {
                  pattern: this.Utilities.EMAIL_PATTERN,
                  message: this.Messages.INVALID_EMAIL,
                },
              ],
              initialValue: emailId,
            })(
              <A10Input
                type="text"
                placeholder={this.Messages.EMAIL}
                onChange={this.handleChange.bind(this, 'emailId')}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="admin-label mandatoryField">
                  {this.Messages.USER_ID}
                </span>
                <HelpInfo
                  placement="left"
                  title={`${this.Messages.USER_ID} *`}
                  helpKey="HELP_ORG_USER_ID"
                />
              </>
            }
          >
            {getFieldDecorator('userId', {
              rules: [
                {
                  validator: this.handleValidateUserID,
                },
              ],
              initialValue: userId,
            })(
              <A10Input
                type="text"
                placeholder={this.Messages.USER_ID}
                disabled={this.editMode}
                maxLength={300}
                onChange={this.handleChange.bind(this, 'userId')}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className='admin-label'>
                  {this.Messages.CONTACT_NUMBER}
                </span>
                <HelpInfo
                  placement="left"
                  title={this.Messages.CONTACT_NUMBER}
                  helpKey="HELP_MP_PRIMARY_CONTACT_NUMBER"
                />
              </>
            }
          >
            {getFieldDecorator('contactNumber', {
              rules: [
                {
                  validator: this.Utilities.validateContactNumber,
                  message: this.Messages.INVALID_CONTACT_NUMBER,
                },
              ],
              initialValue: contactNumber,
            })(
              <A10Input
                type="text"
                placeholder={this.Messages.CONTACT_NUMBER}
                onChange={this.handleChange.bind(this, 'contactNumber')}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className='admin-label'>{this.Messages.ROLE}</span>
                <HelpInfo
                  placement="left"
                  title={this.Messages.ROLE}
                  helpKey="HELP_ORG_USER_ROLE"
                />
              </>
            }
          >
            <A10Select
              title={role}
              key={'role'}
              placeholder="Super Admin"
              allowClear={true}
              onChange={this.handleChange.bind(this, 'role')}
              disabled={true}
            />
          </A10Form.Item>
          {this.state.userStatus?.state && (
            <>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    <span className='admin-label'>
                      {this.Messages.USER_ACTIVATIONLINK}
                    </span>
                    <HelpInfo
                      placement="left"
                      title={this.Messages.USER_ACTIVATIONLINK}
                      helpKey="HELP_ORG_USER_ACTIVATION_LINK"
                    />
                  </>
                }
              >
                <span className="activation-link">
                  {this.state.userStatus?.state === 'UNVERIFIED' ||
                  this.state.userStatus?.state === 'RESET_PASSWORD' ? (
                    <a href={this.state.activationLink} target="_blank">
                      {this.state.activationLink}
                    </a>
                  ) : (
                    <label
                      className="control-label"
                      title={this.state.userStatus.state}
                    >
                      {this.state.userStatus.state}
                    </label>
                  )}
                </span>
              </A10Form.Item>
              {this.state.userStatus.state === 'ACTIVE' ||
                (this.state.userStatus.state === 'Active' && (
                  <A10Form.Item
                    {...formItemLayout}
                    label={
                      <>
                        <span className='admin-label'>
                          {this.Messages.RESET_PASSWORD}
                        </span>
                        <HelpInfo
                          placement="left"
                          title={this.Messages.USER_ACTIVATIONLINK}
                          helpKey=""
                        />
                      </>
                    }
                  >
                    <A10Button
                      style={{ width: '100%' }}
                      onClick={() => {
                        this.resetPassword()
                      }}
                    >
                      Click Here To Reset
                    </A10Button>
                  </A10Form.Item>
                ))}
            </>
          )}
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(AdminUserForm))
