import React from 'react'
import { A10Component } from '@gui-libraries/framework'
import { A10Icon, A10Popover, A10Menu } from '@gui-libraries/widgets'

export interface IListPanelProps {
  title: string
  titleLink?: string
  settingName?: string
  isValidate: any
  confirmTenant: any
  settingMenu?: React.ReactNode
  statusBars?: React.ReactNode
  rightContent?: React.ReactNode | string
}

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

class ListPanel extends A10Component<IListPanelProps> {
  render() {
    const {
      title = '',
      settingName = '',
      settingMenu,
      statusBars,
      rightContent,
      titleLink,
      isValidate,
      confirmTenant,
    } = this.props
    return (
      <div className={styles.listPanel}>
        <div className={styles.listPanelHeader}>
          <div className={styles.listPanelHeaderTitle}>
            <span className={styles.listPanelHeaderTitleSpan}>
              {!isValidate ? (
                titleLink ? (
                  <a href={titleLink}>{title.toUpperCase()}</a>
                ) : (
                  title.toUpperCase()
                )
              ) : titleLink ? (
                <a onClick={confirmTenant}>{title.toUpperCase()}</a>
              ) : (
                title.toUpperCase()
              )}
            </span>
            {statusBars}
          </div>
          <div className={styles.listPanelHeaderSetting}>
            {settingName}
            {settingMenu ? (
              <A10Popover
                placement="bottomRight"
                arrowPointAtCenter={true}
                content={settingMenu}
              >
                <A10Icon type="down" />
              </A10Popover>
            ) : null}
            {rightContent}
          </div>
        </div>
        <div className={styles.listPanelContent}>{this.props.children}</div>
      </div>
    )
  }
}

export default ListPanel
