import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Form,
  A10Select,
  A10Table,
  A10Col,
  A10Checkbox,
  A10Input,
  A10Tooltip,
  A10Tag,
  A10Popover,
  A10Button,
  A10TreeSelect,
  A10Alert,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { Messages } from 'src/locale/en/Messages'
import { InfrastructureService } from 'src/services/InfrastructureService/InfrastructureService'
import { DashboardService } from 'src/services/DashboardService/DashboardService'
import { IDefaultMethods } from 'src/containers/Controller'
import { Utilities } from 'src/services/index'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import storage from 'src/libraries/storage'

import './styles/secondaryipmanagementform.scss'

export interface ISecondaryIPManagementFormProps
  extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  device?: any
  interface?: any
  form: any
  formData?: any
  onRef?: any
  handleChange?: (data: any) => void
}
export interface ISecondaryIPManagementFormState {
  device: any
  publicIPList: any[]
  secondaryIPList: any[]
  compartmentList: any[]
  selectedCompartmentName: any
  selectedCompartmentId: any
  networkInterface: any
  virtualDeviceName: any
  vnicUUID: any
  inputRowCount: {}
  operationInProgress: {}
  createLoading: {}
  deleteLoading: {}
  deletePopoverVisible: {}
  showPublicIPCompartmentTree: {}
}

class SecondaryIPManagementForm extends A10Container<
  ISecondaryIPManagementFormProps,
  ISecondaryIPManagementFormState
> {
  Messages = new Messages()
  InfrastructureService = new InfrastructureService()
  DashboardService = new DashboardService()
  Utilities = new Utilities()

  constructor(props: ISecondaryIPManagementFormProps) {
    super(props)
    const vmDeviceObj = props.device
    const nicObj = props.interface
    let initializeOperationInProgress = {}
    let initializeInputRowCount = {}
    let initializeCreateLoading = {}
    let initializeDeleteLoading = {}
    let initDeletePopoverVisible = {}
    let initPublicIPCompartmentPopoverVisible = {}
    for (let i = 0; i < vmDeviceObj['virtual-nic-list'].length; i++) {
      initializeOperationInProgress[
        vmDeviceObj['virtual-nic-list'][i]['interface-number']
      ] = false
      initializeInputRowCount[
        vmDeviceObj['virtual-nic-list'][i]['interface-number']
      ] = 0
      initializeCreateLoading[
        vmDeviceObj['virtual-nic-list'][i]['interface-number']
      ] = false
      initializeDeleteLoading[
        vmDeviceObj['virtual-nic-list'][i]['interface-number']
      ] = {}
      initDeletePopoverVisible[
        vmDeviceObj['virtual-nic-list'][i]['interface-number']
      ] = {}
      initPublicIPCompartmentPopoverVisible[
        vmDeviceObj['virtual-nic-list'][i]['interface-number']
      ] = false
    }

    this.state = {
      device: vmDeviceObj,
      publicIPList: [],
      secondaryIPList: [],
      compartmentList: [],
      selectedCompartmentName:
        vmDeviceObj['infra-provider'] === 'oci'
          ? vmDeviceObj['placement']['compartment']
          : undefined,
      selectedCompartmentId:
        vmDeviceObj['infra-provider'] === 'oci'
          ? vmDeviceObj['placement']['compartment-id']
          : undefined,
      networkInterface: nicObj ? JSON.stringify(nicObj) : '',
      virtualDeviceName: vmDeviceObj['name'],
      vnicUUID: nicObj ? nicObj['uuid'] : undefined,
      inputRowCount: initializeInputRowCount,
      operationInProgress: initializeOperationInProgress,
      createLoading: initializeCreateLoading,
      deleteLoading: initializeDeleteLoading,
      deletePopoverVisible: initDeletePopoverVisible,
      showPublicIPCompartmentTree: initPublicIPCompartmentPopoverVisible,
    }

    if (nicObj) {
      this.loadSecondaryIPs(this.state.virtualDeviceName, this.state.vnicUUID)
    }

    this.loadPublicIPs(
      vmDeviceObj['infra-provider'],
      vmDeviceObj['owner-account-id'],
      vmDeviceObj['placement']['region'],
      this.state.selectedCompartmentId,
    )

    if (vmDeviceObj['infra-provider'] === 'oci') {
      this.loadOCICompartmentLists()
    }
  }

  addSecondaryIP = () => {
    let vmDeviceObj = this.state.device
    const secondaryIPs = this.state.secondaryIPList
    let network_interface = JSON.parse(this.state.networkInterface)
    let count = this.state.inputRowCount
    count[network_interface['interface-number']] =
      count[network_interface['interface-number']] + 1
    secondaryIPs.push({
      ipAddr: '',
      autoAssign: false,
      publicIP: undefined,
      autoAllocate: false,
      forceDelete: false,
      type: 'NewRecord',
    })
    this.loadPublicIPs(
      vmDeviceObj['infra-provider'],
      vmDeviceObj['owner-account-id'],
      vmDeviceObj['placement']['region'],
      this.state.selectedCompartmentId,
    )

    // @ts-ignore
    this.setState({ secondaryIPList: secondaryIPs, inputRowCount: count })
    this.props.form.setFieldsValue({
      [`ipAddr[${this.state.secondaryIPList.length - 1}]`]: '',
    })
    this.props.form.setFieldsValue({
      [`publicIP[${this.state.secondaryIPList.length - 1}]`]: undefined,
    })
  }

  editSecondaryIP = index => {
    const vmDeviceObj = this.state.device
    const secondaryIPs = this.state.secondaryIPList

    secondaryIPs[index]['type'] = 'UpdateRecord'

    this.loadPublicIPs(
      vmDeviceObj['infra-provider'],
      vmDeviceObj['owner-account-id'],
      vmDeviceObj['placement']['region'],
      this.state.selectedCompartmentId,
    )

    // @ts-ignore
    this.setState({ secondaryIPList: secondaryIPs })

    this.props.form.setFieldsValue({
      [`publicIP[${index}]`]: undefined,
    })
  }

  cancelSecondaryIPUpdate = (index: number) => {
    const secondaryIPs = this.state.secondaryIPList
    secondaryIPs[index].type = ''
    this.setState({ secondaryIPList: secondaryIPs })
  }

  removeSecondaryIP = (index: number) => {
    let vmDeviceObj = this.state.device
    const secondaryIPs = this.state.secondaryIPList
    let network_interface = JSON.parse(this.state.networkInterface)
    let count = this.state.inputRowCount
    count[network_interface['interface-number']] =
      count[network_interface['interface-number']] - 1
    if (secondaryIPs.length > 0) {
      secondaryIPs.splice(index, 1)
    }
    this.props.form.setFieldsValue({ [`ipAddr[${index}]`]: '' })
    this.props.form.setFieldsValue({ [`publicIP[${index}]`]: undefined })
    this.loadPublicIPs(
      vmDeviceObj['infra-provider'],
      vmDeviceObj['owner-account-id'],
      vmDeviceObj['placement']['region'],
      this.state.selectedCompartmentId,
    )

    // @ts-ignore
    this.setState({ secondaryIPList: secondaryIPs, inputRowCount: count })
  }

  // getIPnumber = (ip_address: any) => {
  //   let ip = ip_address.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/);
  //   if (ip) {
  //     return (+ip[1] << 24) + (+ip[2] << 16) + (+ip[3] << 8) + (+ip[4]);
  //   }
  // }

  componentDidMount() {
    //this.props.onRef(this)
  }
  componentWillUnmount() {
    //this.props.onRef(undefined)
  }

  getChangedValue = (e: any) => {
    let changedValue = ''
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        changedValue = e.target.checked
      } else {
        changedValue = e.target.value
      }
    } else {
      changedValue = e
    }
    return changedValue
  }

  handleChange = (stateName: string, e: any, index?: number) => {
    if (!this.Utilities.validateField(e)) {
      return
    }

    const deviceObj = this.state.device
    const secondaryIPObj = this.state.secondaryIPList
    let dataInt = this.state.networkInterface
    let vnic_uuid = this.state.vnicUUID
    let compartmentName = this.state.selectedCompartmentName
    let compartmentId = this.state.selectedCompartmentId
    let { publicIPList } = this.state

    const changedValue = this.getChangedValue(e)

    if (index !== undefined) {
      if (stateName === 'autoAssign' && changedValue) {
        secondaryIPObj[index]['ipAddr'] = ''
        this.props.form.setFieldsValue({ [`ipAddr[${index}]`]: '' })
      }
      if (stateName === 'autoAllocate' && changedValue) {
        secondaryIPObj[index]['publicIP'] = ''
        this.props.form.setFieldsValue({ [`publicIP[${index}]`]: undefined })
      }
      secondaryIPObj[index][stateName] = changedValue
    } else {
      deviceObj[stateName] = changedValue
    }

    if (stateName === 'dataInterface' && changedValue) {
      dataInt = changedValue
      vnic_uuid = JSON.parse(dataInt)['uuid']
      this.loadPublicIPs(
        deviceObj['infra-provider'],
        deviceObj['owner-account-id'],
        deviceObj['placement']['region'],
        this.state.selectedCompartmentId,
      )
      this.loadSecondaryIPs(this.state.virtualDeviceName, vnic_uuid)
      let initializeDeleteLoading = {}
      let initializeInputRowCount = {}
      let initializeDeletePopoverVisible = {}
      for (let i = 0; i < deviceObj['virtual-nic-list'].length; i++) {
        initializeDeleteLoading[
          deviceObj['virtual-nic-list'][i]['interface-number']
        ] = {}
        initializeInputRowCount[
          deviceObj['virtual-nic-list'][i]['interface-number']
        ] = 0
        initializeDeletePopoverVisible[
          deviceObj['virtual-nic-list'][i]['interface-number']
        ] = {}
      }
      this.setState({
        deleteLoading: initializeDeleteLoading,
        deletePopoverVisible: initializeDeletePopoverVisible,
        inputRowCount: initializeInputRowCount,
        publicIPList,
      })
    }
    if (stateName === 'publicIpCompartment' && changedValue) {
      compartmentName = JSON.parse(changedValue).name
      compartmentId = JSON.parse(changedValue).id
      this.loadPublicIPs(
        deviceObj['infra-provider'],
        deviceObj['owner-account-id'],
        deviceObj['placement']['region'],
        compartmentId,
      )
      secondaryIPObj[index]['publicIP'] = ''
      this.props.form.setFieldsValue({ [`publicIP[${index}]`]: undefined })
    }
    // @ts-ignore
    this.setState({
      device: deviceObj,
      secondaryIPList: secondaryIPObj,
      networkInterface: dataInt,
      vnicUUID: vnic_uuid,
      publicIPList,
      selectedCompartmentName: compartmentName,
      selectedCompartmentId: compartmentId,
    })
  }

  loadPublicIPs = (
    environment: any,
    credential: any,
    location: any,
    compartmentId: any = undefined,
  ) => {
    let publicIPsResponse

    if (environment === 'azure') {
      publicIPsResponse = this.InfrastructureService.getAzurePublicIP(
        null,
        null,
        [credential, location],
      )
    } else if (environment === 'aws') {
      publicIPsResponse = this.InfrastructureService.getAWSElasticIP(
        null,
        null,
        [credential, location],
      )
    } else if (environment === 'oci') {
      publicIPsResponse = this.InfrastructureService.getOCIPublicIPs(
        null,
        null,
        [credential, compartmentId, location],
      )
    }
    publicIPsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          this.setState({
            publicIPList: list,
          })
        }
      })
      .catch((error: any) => {
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get public IP details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  getCompartmentChildren = (element: any) => {
    let children: {}[] = []
    element.forEach((item: any) => {
      let obj = {}
      obj['label'] = obj['title'] = item.name
      obj['key'] = item.id
      if (item.hasOwnProperty('children')) {
        obj['children'] = this.getCompartmentChildren(item.children)
      }
      //item['children'] = obj['children']
      obj['value'] = JSON.stringify(item)
      obj['selectable'] = true
      children.push(obj)
    })
    return children
  }

  loadOCICompartmentLists = () => {
    const credentialResponse = this.DashboardService.getUUIDDetails(
      null,
      null,
      [this.state.device['owner-account-id']],
    )

    credentialResponse
      .then((resp: any) => {
        const cred = resp.data
        const credential = cred['infra-account']
        const ociResponse = this.InfrastructureService.getOCICompartments(
          null,
          null,
          [credential['uuid'], credential['oci-account']['tenancy-id']],
        )
        ociResponse
          .then((response: any) => {
            let list = []
            if (response.data && response.data.status === 'Success') {
              list = response.data.load
              let comp_list: {}[] = []
              list.forEach((element: { name: any; children: any[] }) => {
                let comp = {}
                comp['label'] = comp['title'] = element.name
                if (element.children) {
                  comp['children'] = this.getCompartmentChildren(
                    element.children,
                  )
                }
                comp['key'] = element.id
                comp['value'] = JSON.stringify(element)
                comp['selectable'] = true
                comp_list.push(comp)
              })
              if (list) {
                this.setState({
                  compartmentList: comp_list,
                })
              }
            }
          })
          .catch((error: any) => {
            // console.log(error.response)
            const { showMessage } = this.Utilities
            const responseCode =
              error &&
              error.response &&
              error.response.data &&
              error.response.data.response_code
                ? error.response.data.response_code
                : error.response
                ? error.response.status
                : ''
            let msg =
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : ''
            let message = 'Unable to get OCI Compartments'
            if (responseCode === 403) {
              message += ', please check the credentials'
              msg = ''
            }
            showMessage(message, 0, 0, msg)
          })
      })
      .catch((error: any) => {
        const { showMessage } = this.Utilities
        showMessage(
          'Unable to get OCI infra-account details',
          0,
          0,
          error.message,
        )
      })
  }

  loadSecondaryIPs = (vd_name: any, vnic_uuid: any) => {
    const provider = storage.get.PROVIDER
    const secondaryIPsResponse = this.InfrastructureService.getSecondaryIPs(
      null,
      null,
      [provider, vd_name, vnic_uuid],
    )
    secondaryIPsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          this.setState({
            secondaryIPList: list,
          })
        }
      })
      .catch((error: any) => {
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get secondary IP details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  createSecondaryIP = (newRecord: any) => {
    const { showMessage } = this.Utilities
    const provider = storage.get.PROVIDER
    let network_interface = JSON.parse(this.state.networkInterface)

    if (!newRecord.autoAssign) {
      if (newRecord.ipAddr === '') {
        showMessage('Please enter IP address or select auto-assign', 0, 0)
        return
      }
    }
    const secondaryIpPayload = {
      'secondary-ip': {
        'ip-address': newRecord.autoAssign ? undefined : newRecord.ipAddr,
        'ip-allocation-type': newRecord.autoAssign
          ? 'dhcp'
          : !!newRecord.ipAddr
          ? 'static'
          : undefined,
        'public-ip-address': newRecord.autoAllocate
          ? undefined
          : !!newRecord.publicIP
          ? JSON.parse(newRecord.publicIP).PublicIp
            ? JSON.parse(newRecord.publicIP).PublicIp
            : JSON.parse(newRecord.publicIP).name
            ? JSON.parse(newRecord.publicIP).name
            : JSON.parse(newRecord.publicIP).public_ip
          : undefined,
        'public-ip-allocation-type': newRecord.autoAllocate
          ? 'dhcp'
          : !!newRecord.publicIP
          ? 'static'
          : undefined,
      },
    }
    const SecondaryIPAddResponse = this.InfrastructureService.addSecondaryIP(
      null,
      secondaryIpPayload,
      [provider, this.state.virtualDeviceName, this.state.vnicUUID],
    )

    let opList = this.state.operationInProgress
    let createOprList = this.state.createLoading
    createOprList[network_interface['interface-number']] = true
    opList[network_interface['interface-number']] = true
    this.setState({ operationInProgress: opList, createLoading: createOprList })

    SecondaryIPAddResponse.then((response: any) => {
      this.loadSecondaryIPs(this.state.virtualDeviceName, this.state.vnicUUID)
      let count = this.state.inputRowCount
      count[network_interface['interface-number']] = 0
      opList[network_interface['interface-number']] = false
      createOprList[network_interface['interface-number']] = false
      this.setState({
        operationInProgress: opList,
        inputRowCount: count,
        createLoading: createOprList,
      })
    }).catch((error: any) => {
      const responseCode =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.response_code
          ? error.response.data.response_code
          : error.response
          ? error.response.status
          : ''
      let msg =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : ''
      let message = 'Unable to add secondary IP address'
      if (responseCode === 403) {
        message += ', please check the credentials'
        msg = ''
      }
      opList[network_interface['interface-number']] = false
      createOprList[network_interface['interface-number']] = false
      this.setState({
        operationInProgress: opList,
        createLoading: createOprList,
      })
      showMessage(message, 0, 0, msg)
    })
  }

  updateSecondaryIP = (updateRecord: any, index: number, action: any) => {
    const { showMessage } = this.Utilities
    const provider = storage.get.PROVIDER
    if (
      action === 'add' &&
      updateRecord.publicIP === undefined &&
      updateRecord.name === undefined &&
      updateRecord.public_ip === null &&
      updateRecord.autoAllocate === undefined
    ) {
      showMessage(
        'Please input atleast one of Auto-allocate or a Static IP',
        0,
        0,
        '',
      )
      return
    }

    const networkInterface = JSON.parse(this.state.networkInterface)
    const opList = this.state.operationInProgress
    const loadingRecord = this.state.deleteLoading
    loadingRecord[networkInterface['interface-number']][index] = true
    const createLoadingRecord = this.state.createLoading
    createLoadingRecord[networkInterface['interface-number']] = true
    opList[networkInterface['interface-number']] = true
    this.setState({
      operationInProgress: opList,
      createLoading: createLoadingRecord,
      deleteLoading: loadingRecord,
    })

    const secondaryIpPayload = {
      'secondary-ip': {
        'ip-address': updateRecord.private_ip,
        'public-ip-address': updateRecord.autoAllocate
          ? undefined
          : !!updateRecord.publicIP
          ? JSON.parse(updateRecord.publicIP).PublicIp
            ? JSON.parse(updateRecord.publicIP).PublicIp
            : JSON.parse(updateRecord.publicIP).name
            ? JSON.parse(updateRecord.publicIP).name
            : JSON.parse(updateRecord.publicIP).public_ip
          : undefined,
        'public-ip-allocation-type': updateRecord.autoAllocate
          ? 'dhcp'
          : !!updateRecord.publicIP
          ? 'static'
          : undefined,
      },
    }
    const SecondaryIPUpdateResponse = this.InfrastructureService.updateSecondaryIP(
      null,
      secondaryIpPayload,
      [provider, this.state.virtualDeviceName, this.state.vnicUUID],
    )

    SecondaryIPUpdateResponse.then((response: any) => {
      this.loadSecondaryIPs(this.state.virtualDeviceName, this.state.vnicUUID)
      opList[networkInterface['interface-number']] = false
      loadingRecord[networkInterface['interface-number']][index] = false
      createLoadingRecord[networkInterface['interface-number']] = false
      this.setState({
        operationInProgress: opList,
        createLoading: createLoadingRecord,
        deleteLoading: loadingRecord,
      })
    }).catch((error: any) => {
      const responseCode =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.response_code
          ? error.response.data.response_code
          : error.response
          ? error.response.status
          : ''
      let msg =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : ''
      let message = 'Unable to update ' + updateRecord.private_ip + '. '
      if (responseCode === 403) {
        message += 'Please check the credentials.'
        msg = ''
      }
      opList[networkInterface['interface-number']] = false
      loadingRecord[networkInterface['interface-number']][index] = false
      createLoadingRecord[networkInterface['interface-number']] = false
      this.setState({
        operationInProgress: opList,
        createLoading: createLoadingRecord,
        deleteLoading: loadingRecord,
      })
      showMessage(message + msg, 0, 0)
    })
  }

  deleteSecondaryIP = (recordToBeDeleted: any, index: any) => {
    const provider = storage.get.PROVIDER
    let opList = this.state.operationInProgress
    let network_interface = JSON.parse(this.state.networkInterface)
    opList[network_interface['interface-number']] = true
    let loadingRecord = this.state.deleteLoading
    loadingRecord[network_interface['interface-number']][index] = true
    this.setState({ operationInProgress: opList, deleteLoading: loadingRecord })

    const SecondaryIPdeleteResponse = this.InfrastructureService.deleteSecondaryIP(
      null,
      null,
      [
        provider,
        this.state.virtualDeviceName,
        this.state.vnicUUID,
        recordToBeDeleted['private_ip'],
        recordToBeDeleted['forceDelete']
          ? recordToBeDeleted['forceDelete']
          : false,
      ],
    )

    SecondaryIPdeleteResponse.then((response: any) => {
      this.loadSecondaryIPs(this.state.virtualDeviceName, this.state.vnicUUID)
      let count = this.state.inputRowCount
      count[network_interface['interface-number']] = 0
      loadingRecord[network_interface['interface-number']][index] = false
      opList[network_interface['interface-number']] = false
      this.setState({
        deleteLoading: loadingRecord,
        inputRowCount: count,
        operationInProgress: opList,
      })
      this.props.form.setFieldsValue({ [`ipAddr[${index}]`]: '' })
      this.props.form.setFieldsValue({ [`publicIP[${index}]`]: undefined })
    }).catch((error: any) => {
      const { showMessage } = this.Utilities
      const responseCode =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.response_code
          ? error.response.data.response_code
          : error.response
          ? error.response.status
          : ''
      let msg =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : ''
      let message = 'Unable to delete ' + recordToBeDeleted['private_ip'] + '. '
      if (responseCode === 403) {
        message += 'Please check the credentials.'
        msg = ''
      }
      opList[network_interface['interface-number']] = false
      loadingRecord[network_interface['interface-number']][index] = false
      this.setState({
        operationInProgress: opList,
        deleteLoading: loadingRecord,
      })
      showMessage(message + msg, 0, 0)
    })
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }
    const { getFieldDecorator } = this.props.form
    let showDropDown = true
    if (this.props.interface) {
      showDropDown = false
    }
    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <div className="margin-v-10">
          <A10Panel
            title={
              <>
                <A10IconTextGroup
                  text={<>{this.state.device.name}</>}
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, marginRight: 12 }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                    Network Interface
                  </span>
                  <span>
                    <HelpInfo
                      placement="topLeft"
                      title="Network Interfaces"
                      helpKey="HELP_INFRA_VIRTUAL_SECONDARY_IP_NETWORK_INTERFACE"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('dataInterface', {
                rules: [
                  {
                    required: true,
                    message: 'Please select network interface',
                  },
                ],
              })(
                showDropDown ? (
                  <A10Select
                    placeholder={'Select a network interface'}
                    onChange={(e: any) => this.handleChange('dataInterface', e)}
                  >
                    {this.state.device['virtual-nic-list'].map(
                      (obj: any, i: number) => {
                        return (
                          <A10Select.Option key={i} value={JSON.stringify(obj)}>
                            {obj['interface-number'] === 'eth0'
                              ? 'Management/eth0'
                              : 'Ethernet' +
                                obj['interface-number'].split('eth')[1] +
                                '/' +
                                obj['interface-number']}
                          </A10Select.Option>
                        )
                      },
                    )}
                  </A10Select>
                ) : (
                  <A10Tag color="#f5f5f5" className="interface-name">
                    {this.props.interface['interface-number'] === 'eth0'
                      ? 'Management/eth0'
                      : 'Ethernet' +
                        this.props.interface['interface-number'].split(
                          'eth',
                        )[1] +
                        '/' +
                        this.props.interface['interface-number']}
                  </A10Tag>
                ),
              )}
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                    Secondary IP Addresses
                  </span>
                  <span>
                    <HelpInfo
                      placement="topLeft"
                      title="Secondary IP Addresses"
                      helpKey="HELP_INFRA_VIRTUAL_SECONDARY_IP_ADDRESSES"
                    />
                  </span>
                  {this.state.networkInterface ? (
                    <span>
                      {' '}
                      <A10Button shape="circle" size="small" type="tertiary">
                        {this.state.secondaryIPList.length}
                      </A10Button>
                    </span>
                  ) : null}
                </>
              }
              style={{ marginBlockEnd: '0px' }}
            />
            <A10Table
              dataSource={this.state.secondaryIPList}
              scroll={{ x: '100%' }}
              className="secondaryIPTable"
              rowClassName={(record: any) => {
                if (record.type === 'NewRecord') {
                  return 'scip-table-row-alignment'
                }
                return ''
              }}
            >
              <A10Col
                title="IP Address"
                dataIndex="ipAddr"
                key="ipAddr"
                render={(text: string, record: any, index: number) => {
                  if (record.type === 'NewRecord') {
                    return (
                      <>
                        <A10Form.Item className="no-margin">
                          <>
                            {getFieldDecorator(`ipAddr[${index}]`, {
                              validateTrigger: 'onBlur',
                              rules: [
                                {
                                  validator: record.autoAssign
                                    ? null
                                    : this.Utilities.validateVMDeviceIPs.bind(
                                        this,
                                        'mandatory',
                                      ),
                                },
                              ],
                            })(
                              <A10Input
                                type="text"
                                placeholder={'IP Address'}
                                size="small"
                                className="scip-input"
                                onChange={(e: any) =>
                                  this.handleChange('ipAddr', e, index)
                                }
                                disabled={record.autoAssign}
                              />,
                            )}
                          </>
                        </A10Form.Item>
                        <div
                          className="col-md-12"
                          style={
                            this.state.device['infra-provider'] === 'oci'
                              ? { padding: '21px 0px' }
                              : { padding: '5px 0px' }
                          }
                        >
                          <A10Checkbox
                            onChange={(e: any) =>
                              this.handleChange('autoAssign', e, index)
                            }
                            style={{ fontSize: '12px' }}
                          >
                            Auto-assign
                          </A10Checkbox>
                        </div>
                      </>
                    )
                  } else {
                    return (
                      <>
                        <span>{record['private_ip']}</span>
                      </>
                    )
                  }
                }}
              />
              <A10Col
                title={
                  this.state.device['infra-provider'] == 'aws'
                    ? 'Elastic IP Address'
                    : 'Public IP Address'
                }
                key="publicIP"
                render={(text: string, record: any, index: number) => {
                  if (
                    record.type === 'NewRecord' ||
                    record.type === 'UpdateRecord'
                  ) {
                    return (
                      <>
                        <A10Form.Item
                          className="no-margin"
                          style={{ width: '170px' }}
                        >
                          {getFieldDecorator(
                            `publicIP[${index}]`,
                            {},
                          )(
                            <A10Select
                              placeholder={'Please select'}
                              className="sip-network-dropdown"
                              dropdownClassName="sip-network-dropdown"
                              size="small"
                              onChange={(e: any) =>
                                this.handleChange('publicIP', e, index)
                              }
                              disabled={record.autoAllocate}
                            >
                              {this.state.publicIPList.map(
                                (obj: any, i: number) => {
                                  return (
                                    <A10Select.Option
                                      key={i}
                                      value={JSON.stringify(obj)}
                                      className="scip-inputs"
                                    >
                                      <A10Tooltip
                                        placement="top"
                                        title={
                                          obj['ip_address']
                                            ? obj['ip_address']
                                            : obj['name']
                                            ? obj['name']
                                            : obj['public_ip']
                                        }
                                      >
                                        <span>
                                          {obj['ip_address']
                                            ? obj['ip_address']
                                            : obj['PublicIp']
                                            ? obj['PublicIp']
                                            : obj['public_ip']}
                                        </span>
                                      </A10Tooltip>
                                    </A10Select.Option>
                                  )
                                },
                              )}
                            </A10Select>,
                          )}
                          {this.state.device['infra-provider'] === 'oci' ? (
                            <A10Popover
                              placement="right"
                              overlayClassName="oci-publicip-compartment-dropdown"
                              content={
                                <A10TreeSelect
                                  style={{ width: '100%' }}
                                  dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                  }}
                                  placeholder="Please select"
                                  treeData={this.state.compartmentList}
                                  treeDefaultExpandAll
                                  onChange={(e: any) =>
                                    this.handleChange(
                                      'publicIpCompartment',
                                      e,
                                      index,
                                    )
                                  }
                                  onSelect={() => {
                                    let showPublicIPPopOver = this.state
                                      .showPublicIPCompartmentTree
                                    showPublicIPPopOver[index] = false
                                    this.setState({
                                      showPublicIPCompartmentTree: showPublicIPPopOver,
                                    })
                                  }}
                                />
                              }
                              title="Public IP Compartment"
                              trigger="click"
                              visible={
                                this.state.showPublicIPCompartmentTree[index] ||
                                false
                              }
                              onVisibleChange={(visible: any) => {
                                let showPublicIPPopOver = this.state
                                  .showPublicIPCompartmentTree
                                showPublicIPPopOver[index] = visible
                                this.setState({
                                  showPublicIPCompartmentTree: showPublicIPPopOver,
                                })
                              }}
                              getPopupContainer={() =>
                                document.getElementById(`publicIP[${index}]`)
                              }
                            >
                              <span className="col-md-12 scip-compartment pull-right">
                                <A10Icon
                                  // @ts-ignore
                                  app="goe"
                                  type="groups"
                                />{' '}
                                {this.state.selectedCompartmentName}
                              </span>
                            </A10Popover>
                          ) : null}
                        </A10Form.Item>

                        <div
                          className="col-md-12"
                          style={{ padding: '5px 0px' }}
                        >
                          <A10Checkbox
                            onChange={(e: any) =>
                              this.handleChange('autoAllocate', e, index)
                            }
                            style={{ fontSize: '12px' }}
                            checked={record.autoAllocate}
                          >
                            Auto-allocate
                          </A10Checkbox>
                        </div>
                      </>
                    )
                  } else {
                    return <span>{record['public_ip']}</span>
                  }
                }}
              />
              <A10Col
                title="Action"
                key="action"
                render={(text: string, record: any, index: number) => {
                  if (
                    record.type === 'NewRecord' ||
                    record.type === 'UpdateRecord'
                  ) {
                    return (
                      <>
                        {this.state.createLoading[
                          JSON.parse(this.state.networkInterface)[
                            'interface-number'
                          ]
                        ] ? (
                          <A10Tooltip
                            title="In Progress"
                            placement="topLeft"
                            overlayClassName="scip-tooltip"
                          >
                            <A10Icon spin type="sync" />
                          </A10Tooltip>
                        ) : null}
                        <A10Form.Item className="no-margin">
                          <>
                            <span
                              className="pull-left add-another-link"
                              onClick={() => {
                                if (record.type == 'NewRecord') {
                                  this.createSecondaryIP(record)
                                } else {
                                  this.updateSecondaryIP(record, index, 'add')
                                }
                              }}
                              hidden={
                                this.state.operationInProgress[
                                  JSON.parse(this.state.networkInterface)[
                                    'interface-number'
                                  ]
                                ]
                              }
                            >
                              <A10Tooltip
                                title="Submit"
                                placement="topRight"
                                overlayClassName="scip-tooltip"
                              >
                                <A10Icon
                                  app="global"
                                  type="success"
                                  className="a10-icon scip-icon"
                                />
                              </A10Tooltip>
                            </span>
                            <span
                              className="add-another-link"
                              onClick={() => {
                                if (record.type === 'UpdateRecord') {
                                  this.cancelSecondaryIPUpdate(index)
                                } else {
                                  this.removeSecondaryIP(index)
                                }
                              }}
                              hidden={
                                this.state.operationInProgress[
                                  JSON.parse(this.state.networkInterface)[
                                    'interface-number'
                                  ]
                                ]
                              }
                            >
                              <A10Tooltip
                                title="Cancel"
                                placement="topLeft"
                                overlayClassName="scip-tooltip"
                              >
                                <A10Icon
                                  app="global"
                                  type="error"
                                  className="a10-icon scip-icon"
                                />
                              </A10Tooltip>
                            </span>
                          </>
                        </A10Form.Item>
                      </>
                    )
                  } else {
                    return (
                      <A10Form.Item className="no-margin">
                        {this.state.deleteLoading[
                          JSON.parse(this.state.networkInterface)[
                            'interface-number'
                          ]
                        ][index] ? (
                          <A10Tooltip
                            title="In Progress"
                            placement="topLeft"
                            overlayClassName="scip-tooltip"
                          >
                            <A10Icon spin type="sync" />
                          </A10Tooltip>
                        ) : null}
                        <span
                          className="pull-left add-another-link"
                          hidden={
                            this.state.operationInProgress[
                              JSON.parse(this.state.networkInterface)[
                                'interface-number'
                              ]
                            ]
                          }
                        >
                          <A10Tooltip
                            title="Delete"
                            placement="topLeft"
                            overlayClassName="scip-tooltip"
                          >
                            <A10Popover
                              title="Are you sure you want to delete this IP Address?"
                              visible={
                                this.state.deletePopoverVisible[
                                  JSON.parse(this.state.networkInterface)[
                                    'interface-number'
                                  ]
                                ][index]
                                  ? this.state.deletePopoverVisible[
                                      JSON.parse(this.state.networkInterface)[
                                        'interface-number'
                                      ]
                                    ][index]
                                  : false
                              }
                              onVisibleChange={visible => {
                                const popovervisible = this.state
                                  .deletePopoverVisible
                                popovervisible[
                                  JSON.parse(this.state.networkInterface)[
                                    'interface-number'
                                  ]
                                ][index] = visible
                                this.setState({
                                  deletePopoverVisible: popovervisible,
                                })
                              }}
                              content={
                                <div>
                                  <div style={{ marginBottom: '5px' }}>
                                    <A10Alert
                                      message="Delete the IP address even if in use."
                                      type="info"
                                      showIcon
                                    />
                                  </div>
                                  <div style={{ marginBottom: '10px' }}>
                                    <A10Checkbox
                                      onChange={(e: any) =>
                                        this.handleChange(
                                          'forceDelete',
                                          e,
                                          index,
                                        )
                                      }
                                    />
                                    <span
                                      style={{
                                        color: 'Red',
                                        paddingLeft: '10px',
                                      }}
                                    >
                                      Force Delete
                                    </span>
                                  </div>
                                  <div>
                                    <A10Button
                                      size="default"
                                      type="primary"
                                      onClick={() => {
                                        const popovervisible = this.state
                                          .deletePopoverVisible
                                        popovervisible[
                                          JSON.parse(
                                            this.state.networkInterface,
                                          )['interface-number']
                                        ][index] = false
                                        this.setState({
                                          deletePopoverVisible: popovervisible,
                                        })
                                        this.deleteSecondaryIP(record, index)
                                      }}
                                    >
                                      Yes
                                    </A10Button>
                                    <A10Button
                                      size="default"
                                      type="primary"
                                      style={{ marginLeft: '10px' }}
                                      onClick={() => {
                                        const popovervisible = this.state
                                          .deletePopoverVisible
                                        popovervisible[
                                          JSON.parse(
                                            this.state.networkInterface,
                                          )['interface-number']
                                        ][index] = false
                                        this.setState({
                                          deletePopoverVisible: popovervisible,
                                        })
                                      }}
                                    >
                                      No
                                    </A10Button>
                                  </div>
                                </div>
                              }
                              trigger="click"
                            >
                              <A10Icon
                                app="global"
                                type="delete"
                                className="a10-icon scip-icon"
                              />
                            </A10Popover>
                          </A10Tooltip>
                        </span>
                        <span
                          className="add-another-link"
                          hidden={
                            this.state.operationInProgress[
                              JSON.parse(this.state.networkInterface)[
                                'interface-number'
                              ]
                            ]
                          }
                        >
                          {' '}
                          {this.state.secondaryIPList[index].public_ip ? (
                            <A10Tooltip
                              title="Remove Public/Elastic IP"
                              placement="topLeft"
                              overlayClassName="scip-tooltip"
                            >
                              <A10Icon
                                app="global"
                                type="delete-another"
                                className="a10-icon scip-icon"
                                onClick={() => {
                                  this.updateSecondaryIP(
                                    record,
                                    index,
                                    'remove',
                                  )
                                }}
                              />
                            </A10Tooltip>
                          ) : (
                            <A10Tooltip
                              title="Add Public/Elastic IP"
                              placement="topLeft"
                              overlayClassName="scip-tooltip"
                            >
                              <A10Icon
                                app="global"
                                type="add-another"
                                className="a10-icon scip-icon"
                                onClick={() => {
                                  this.editSecondaryIP(index)
                                }}
                              />
                            </A10Tooltip>
                          )}
                        </span>
                      </A10Form.Item>
                    )
                  }
                }}
              />
            </A10Table>

            {this.state.vnicUUID &&
            this.state.inputRowCount[
              JSON.parse(this.state.networkInterface)['interface-number']
            ] < 1 ? (
              <div className="row margin-v-10">
                <div className="col-sm-12">
                  <A10IconTextGroup
                    text={
                      <div className="addEth2Title">
                        Add New Secondary IP Address
                      </div>
                    }
                    icon={
                      <A10Icon
                        style={{ fontSize: 24, height: '20px' }}
                        app="global"
                        type="add-another"
                        onClick={this.addSecondaryIP.bind(this)}
                      />
                    }
                  />
                </div>
              </div>
            ) : null}
          </A10Panel>
        </div>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(SecondaryIPManagementForm))
