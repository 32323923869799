import { _ } from '@gui-libraries/framework'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import * as basicUtils from './basicUtils'
import * as basicApiUtils from './basicApiUtils'
import * as constants from './appServiceConstants'

/**
 * =============================
 * For the formalus of SLB/CGN/GiFW,
 * please open the below link, to see the comments by Rui Zhang
 * Those formalus have been discussed with Alak.
 * https://a10networks.atlassian.net/browse/GUI-1257
 * =============================
 */

export const getAppServiceTotal = async (tenant: IObject) => {
  const {
    get: { PROVIDER: provider },
  } = storage

  const api = `/hpcapi/v3/provider/${provider}/tenant/${tenant.name}/app-svc?total=true`
  try {
    const {
      data: { 'total-count': count = 0 },
    } = await httpClient.get(api, { absoluteBasePath: true } as IObject)
    return count
  } catch {
    return 0
  }
}

const httpRequest = async (apiPrefix: string, payload: IObject) => {
  const provider = basicUtils.getProvider()
  const tenant = basicUtils.getCurrentTenant() || {}
  const header = {
    absoluteBasePath: true,
    headers: { provider, tenant: tenant.name, 'x-account': tenant.uuid },
  }
  const api = `/analytics/thunder-adc/${apiPrefix}`
  return await httpClient.post(api, payload, header)
}

const httpLadcRequest = async (apiPrefix: string, payload: IObject) => {
  const provider = basicUtils.getProvider()
  const tenant = basicUtils.getCurrentTenant() || {}
  const header = {
    absoluteBasePath: true,
    headers: { provider, tenant: tenant.name, 'x-account': tenant.uuid },
  }
  const api = `/analytics/ladc/${apiPrefix}`
  return await httpClient.post(api, payload, header)
}

const getDataBySlbVirtualServerPort = async (payload: IObject) => {
  return await httpRequest('slb_virtual_server_port', payload)
}

const getHostLogs = async (payload: IObject) => {
  return await httpRequest('host', payload)
}

const getFlowLogs = async (payload: IObject) => {
  return await httpRequest('flow', payload)
}

const getPrLogs = async (payload: IObject) => {
  return await httpRequest('pr', payload)
}

const getLadcHealth = async (payload: IObject) => {
  return await httpLadcRequest('app-server-health', payload)
}

const getLadcAlert = async (payload: IObject) => {
  return await httpLadcRequest('event', payload)
}

const getDataByAccesslog = async (payload: IObject) => {
  if (_.keys(payload).length === 0) {
    return {} as IObject
  }
  return await httpRequest('accesslog', payload)
}

const getDataByPc = async (payload: IObject) => {
  if (_.keys(payload).length === 0) {
    return {} as IObject
  }
  return await httpRequest('pc', payload)
}

const getAlertData = async (payload: IObject) => {
  const provider = basicUtils.getProvider()
  const tenant = basicUtils.getCurrentTenant() || {}
  const header = {
    absoluteBasePath: true,
    headers: { provider, 'x-account': tenant.uuid },
  }
  const api = '/analytics/thunder-adc/hc_event'
  return await httpClient.post(api, payload, header)
}

const operStatsMapping = {
  unkn: 0.5,
  unknown: 0.5,
  'all up': 1,
  up: 1,
  'functional up': 0.9,
  'functionally up': 0.9,
  'partial up': 0.7,
  'partially up': 0.7,
  disb: 0.1,
  disabled: 0.1,
  down: 0,
}

const sumCounter = (data: IObject, fields: string[], prefix: string = '') => {
  let counter = 0
  fields.map((field: string) => {
    const newFiled = `${field}${prefix}`
    const fieldCounter = +data[newFiled] || 0
    if (fieldCounter === Infinity || fieldCounter === -Infinity) {
      counter += 0
    } else {
      counter += fieldCounter
    }
  })
  return counter
}

const preSecondCount = (num: number, selectedTimePeriod: IObject) => {
  return (
    num /
      ((selectedTimePeriod.endTime - selectedTimePeriod.startTime) / 1000) || 0
  )
}

const getPerCount = (
  minRecord: IObject,
  maxRecord: IObject,
  fields: string[],
) => {
  const timeRange = (maxRecord.ts - minRecord.ts) / 1000
  const startCount = sumCounter(minRecord, fields)
  const endCount = sumCounter(maxRecord, fields)
  return (endCount - startCount) / timeRange
}

const getClusterIdAndPid = async (appService: IObject = {}) => {
  const clusterId = appService['device-cluster-id']
  const partitionUuid = appService['partition-id']
  const cluster = await basicApiUtils.getCluster(httpClient, '', clusterId)
  const partitionList = cluster['partition-list'] || []
  const selectedPartition: IObject = _.first(
    partitionList.filter((partition: IObject) => {
      return partitionUuid === partition.uuid
    }),
  )
  const pid = selectedPartition ? selectedPartition.app_svc_id : 0
  return { cluster_id: clusterId, p_id: pid }
}

// Use Cluster id and Partion id for the query filter
// Because of the app_svc_id is emtry in sometimes.
const getClusterIdAndPidMapping = async (appServices: IObject[]) => {
  const {
    get: { PROVIDER_CLUSTERS },
  } = storage

  if (!appServices || appServices.length === 0) {
    return {}
  }
  const apps = {} as IObject
  for (const app of appServices) {
    // const clusterPartition = await getClusterIdAndPid(app)
    const clusters = PROVIDER_CLUSTERS
    const selectedCluster: IObject =
      _.first(
        clusters.filter((cluster: any) => {
          return cluster['cluster-uuid'] === app.device_cluster_id
        }),
      ) || {}
    // selectedCluster = selectedCluster.length ? selectedCluster[0] : []
    const partitions = selectedCluster['partition-list']
      ? selectedCluster['partition-list']
      : []
    const selectedPartition: IObject =
      _.first(
        partitions.filter((partition: any) => {
          return partition['partition-uuid'] === app.partition_uuid
        }),
      ) || {}
    // selectedPartition = selectedPartition.length ? selectedPartition[0] : []
    // console.log("I AM HERE ", clusterPartition, app, )
    apps[app.app_svc_id] = {
      cluster_id: app.device_cluster_id,
      p_id: selectedPartition.id,
    }
    apps[`${app.app_svc_id}_name`] = {
      clusterName: selectedCluster.name,
      partitionName: selectedPartition.name,
    }
  }
  return apps
}

const calculateHealthChart = {
  slb: (data: IObject) => {
    const timestampMap = {}
    const timeStampCount = {}
    _.forEach(data, (values: IObject, objectKey: string) => {
      const validData = _.first(_.values(values)) || {}
      const prefix = operStatsMapping[_.toLower(objectKey)] || 0
      _.forEach(validData, (v: number, k: string) => {
        timestampMap[k] = (timestampMap[k] || 0) + v * prefix
        timeStampCount[k] = (timeStampCount[k] || 0) + v
      })
    })
    const counters = [] as IObject[]
    _.forEach(timeStampCount, (v: number, k: string) => {
      counters.push([Number(k), v > 0 ? (timestampMap[k] || 0) / v : 0])
    })
    return counters
  },
  cgn: (
    data: IObject,
    dataSessionGenerated: number,
    unRevert: boolean = false,
  ) => {
    /**
     * sum([
     *   'fullcone_failure',
     *   'fullcone_self_hairpinning_drop',
     *   'eif_limit_exceeded',
     *   'nat_mismatch_drop',
     *   'ha_nat_pool_unusable',
     *   'ha_nat_pool_batch_type_mismatch',
     *   'nat_pool_unusable',
     *   'total_udp_overloaded',
     *   'total_tcp_overloaded',
     *   'no_radius_profile_match',
     *   // 'no_class_list_match',
     *   'lid_drop',
     *   'user_quota_unusable',
     *   'user_quota_unusable_drop',
     * ]) / (data_session_created_max - data_session_created_min)
     */
    const timestampDatas = _.values(data) || []
    const timestampMap = {}
    timestampDatas.map((item: IObject) => {
      _.forEach(item, (v: number, k: string) => {
        //
        const count = timestampMap[k] || 0
        timestampMap[k] = count + v
      })
    })
    const counters = [] as IObject[]
    _.forEach(timestampMap, (v: number, k: string) => {
      const count = dataSessionGenerated > 0 ? v / dataSessionGenerated : 0
      counters.push([Number(k), unRevert ? count : 1 - count])
    })
    return [counters]
  },
  normal: (data: IObject = {}) => {
    const counters = [] as [number, number][]
    _.forEach(data, (values: IObject = {}, objectKey: string) => {
      _.forEach(values, (num: any, timestamp: any) => {
        counters.push([
          Number(timestamp),
          Number((Number(num) || 0).toFixed(2)),
        ])
      })
    })
    return counters
  },
}

const getLadcTrafficData = (
  appServices: IObject[] = [],
  hostData: IObject = {},
  flowData: IObject = {},
  prData: IObject = {},
  healthData: IObject = {},
  alertData: IObject = {},
  selectedTimePeriod: IObject,
) => {
  const allCounters = {}
  const newStats = {} as IObject
  appServices.map((service: IObject) => {
    const { [service.name]: serviceHostData } = hostData
    const { [service.name]: serviceFlowData } = flowData
    const { [service.name]: totalData } = prData
    const { [service.name]: serviceHealthData } = healthData
    const { [service.name]: serviceAlertData } = alertData

    const logsProcessed = _.first(_.values(totalData)) || 0
    const alert = _.first(_.values(serviceAlertData)) || 0

    const cps = Math.abs(
      preSecondCount(
        sumCounter(serviceHostData, ['totalClientConnections_sum']),
        selectedTimePeriod,
      ),
    )

    const mbps =
      preSecondCount(
        sumCounter(serviceFlowData, ['bytesReceived_sum', 'bytesSent_sum']),
        selectedTimePeriod,
      ) * 8

    const logsGenerated = sumCounter(serviceFlowData, ['requests_sum'])

    const counters = calculateHealthChart.normal(serviceHealthData)
    allCounters[service.app_svc_id] = counters
    // for app_svc_id is set in AppServices.tsx, use tokenId
    newStats[service.app_svc_id] = {
      cps,
      mbps,
      logsGenerated,
      logsProcessed,
      alert,
    }
  })
  return { stats: newStats, counters: allCounters }
}

// Analysis SLB App Service data
const getTrafficData = (
  appServiceIds: string[],
  data: IObject = {},
  logsProcessedData: IObject = {},
  selectedTimePeriod: IObject,
) => {
  const allCounters = {}
  const { stats = {} } = data
  const newStats = {} as IObject
  appServiceIds.map((id: string) => {
    const { [id]: appServiceData = {} } = data
    const { [id]: appServiceStats = {} } = stats
    const { [id]: totalData } = logsProcessedData
    const logsProcessed = _.first(_.values(totalData)) || 0
    // Use total_req to instead of total_conn in https://a10networks.atlassian.net/browse/GUI-2348
    // const cps = Math.abs(
    //   preSecondCount(
    //     sumCounter(appServiceStats, ['total_conn_sum']),
    //     selectedTimePeriod,
    //   ),
    // )
    const cps = Math.abs(
      preSecondCount(
        sumCounter(appServiceStats, ['total_l7_conn_sum']),
        selectedTimePeriod,
      ),
    )
    const mbps =
      preSecondCount(
        sumCounter(appServiceStats, [
          'total_fwd_bytes_sum',
          'total_rev_bytes_sum',
        ]),
        selectedTimePeriod,
      ) * 8

    // Use total_req to instead of total_conn in https://a10networks.atlassian.net/browse/GUI-2348
    // const logsGenerated = sumCounter(appServiceStats, ['total_conn_sum'])
    const logsGenerated = sumCounter(appServiceStats, ['total_req_sum'])

    newStats[id] = { cps, mbps, logsGenerated, logsProcessed }

    // const appServiceDataValues = _.values(appServiceData)
    // const counters = appServiceDataValues.map((values: IObject) => {
    //   return [values.ts, operStatsMapping[_.toLower(values.o_oper_state)] || 0]
    // })
    const counters = calculateHealthChart.slb(appServiceData)
    allCounters[id] = counters
  })
  return { stats: newStats, counters: allCounters }
}

const getQueryId = (apps: IObject, appId: string) => {
  const clusterPid = apps[appId] || {}
  return `${appId}--${clusterPid.p_id}`
}

const getTrafficDataForCgnNormal = (
  appServiceIds: string[],
  apps: IObject,
  data: IObject = {},
  logsProcessedData: IObject = {},
  selectedTimePeriod: IObject,
  isFw: boolean,
) => {
  const queryKeys = [] as string[]
  const allCounters = {}
  const newStats = {} as IObject
  appServiceIds.map((id: string) => {
    const clusterPartitionNames = apps[`${id}_name`] || {}
    const queryId = getQueryId(apps, id)
    if (_.includes(queryKeys, queryId)) {
      return
    }
    queryKeys.push(queryId)
    const {
      [`${queryId}-chart`]: appServiceChart = {},
      [`${queryId}-stats`]: appServiceStats = {},
      [`${queryId}-max`]: appServiceMax = {},
      [`${queryId}-min`]: appServiceMin = {},
      [`${queryId}-start`]: appServiceStart = {},
      [`${queryId}-end`]: appServiceEnd = {},
    } = data
    const { [queryId]: logsProcessedTotal } = logsProcessedData
    // Calculate Logs Processed
    const logsProcessed = _.first(_.values(logsProcessedTotal)) || 0

    // Calculate Logs Generated
    const dataSessionFreedStart =
      (_.first(_.values(appServiceStart)) || {}).data_session_freed || 0
    const dataSessionFreedEnd =
      (_.first(_.values(appServiceEnd)) || {}).data_session_freed || 0
    const logsGenerated = dataSessionFreedEnd - dataSessionFreedStart
    // Calculate BPS
    const mbps =
      preSecondCount(
        sumCounter(appServiceStats, constants.cgnBpsFields, '_sum'),
        selectedTimePeriod,
      ) * 8
    // let maxRecord = undefined as IObject
    // let minRecord = undefined as IObject
    // const appServiceDataValues = _.values(appServiceData)
    // const counters = appServiceDataValues.map((values: IObject) => {
    //   const c = sumCounter(values, constants.cgnDropFields)
    //   const sessionCreated = sumCounter(values, constants.cgnCpsFields)
    //   if (!maxRecord || values.ts > maxRecord.ts) {
    //     maxRecord = values
    //   }
    //   if (!minRecord || values.ts < minRecord.ts) {
    //     minRecord = values
    //   }
    //   if (sessionCreated === 0) {
    //     return [values.ts, 0]
    //   }
    //   return [values.ts, 1 - c / sessionCreated]
    // })

    // Calculate CPS
    const maxSessionData = sumCounter(
      appServiceMax,
      constants.cgnCpsFields,
      '_max',
    )
    const minSessionData = sumCounter(
      appServiceMin,
      constants.cgnCpsFields,
      '_min',
    )
    const [counters] = calculateHealthChart.cgn(
      appServiceChart,
      maxSessionData - minSessionData,
      isFw,
    )
    const cps =
      ((maxSessionData - minSessionData) * 1000) /
      (selectedTimePeriod.endTime - selectedTimePeriod.startTime)
    newStats[queryId] = {
      cps,
      mbps,
      logsProcessed,
      logsGenerated,
      ...clusterPartitionNames,
    }
    allCounters[queryId] = counters
  })
  return { stats: newStats, counters: allCounters }
}

const getTrafficDataForFwNormal = (
  appServiceIds: string[],
  apps: IObject,
  data: IObject = {},
  logsProcessedData: IObject = {},
  logsProcessedDataByFw: IObject = {},
  selectedTimePeriod: IObject,
  isFw: boolean,
) => {
  const queryKeys = [] as string[]
  const allCounters = {}
  const newStats = {} as IObject
  appServiceIds.map((id: string) => {
    const clusterPartitionNames = apps[`${id}_name`] || {}
    const queryId = getQueryId(apps, id)
    if (_.includes(queryKeys, queryId)) {
      return
    }
    queryKeys.push(queryId)
    const {
      [`${queryId}-chart`]: appServiceChart = {},
      // [queryId]: appServiceData = {},
      [`${queryId}-stats`]: appServiceStats = {},
      [`${queryId}-sum`]: appServiceSum = {},
      // [`${queryId}-count`]: appServiceCount = {},
      [`${queryId}-max`]: appServiceMax = {},
      [`${queryId}-min`]: appServiceMin = {},
      [`${queryId}-start`]: appServiceStart = {},
      [`${queryId}-end`]: appServiceEnd = {},
    } = data
    const { [queryId]: logsProcessedTotal } = logsProcessedData
    const { [queryId]: logsProcessedTotalFw } = logsProcessedDataByFw
    // Calculate Logs Processed
    const logsProcessedCgn = _.first(_.values(logsProcessedTotal)) || 0
    const logsProcessedFw = _.first(_.values(logsProcessedTotalFw)) || 0
    const logsProcessed = logsProcessedCgn + logsProcessedFw

    // Calculate Logs Generated
    const dataSessionFreedStart =
      (_.first(_.values(appServiceStart)) || {}).data_session_freed || 0
    const dataSessionFreedEnd =
      (_.first(_.values(appServiceEnd)) || {}).data_session_freed || 0
    const logsGenerated = dataSessionFreedEnd - dataSessionFreedStart
    const mbps =
      preSecondCount(
        sumCounter(appServiceStats, constants.fwBpsFields, '_sum'),
        selectedTimePeriod,
      ) * 8
    // let maxRecord = undefined as IObject
    // let minRecord = undefined as IObject
    // const appServiceDataValues = _.values(appServiceData)
    // const counters = appServiceDataValues.map((values: IObject) => {
    //   const c = sumCounter(values, constants.fwDropFields)
    //   const sessionCreated = sumCounter(values, constants.fwCpsFields)
    //   if (!maxRecord || values.ts > maxRecord.ts) {
    //     maxRecord = values
    //   }
    //   if (!minRecord || values.ts < minRecord.ts) {
    //     minRecord = values
    //   }
    //   if (sessionCreated === 0) {
    //     return [values.ts, 0]
    //   }
    //   return [values.ts, 1 - c / sessionCreated]
    // })

    const maxSessionData = sumCounter(
      appServiceMax,
      ['data_session_created'],
      '_max',
    )
    const minSessionData = sumCounter(
      appServiceMin,
      ['data_session_created'],
      '_min',
    )

    const [counters] = calculateHealthChart.cgn(
      appServiceChart,
      maxSessionData - minSessionData,
      isFw,
    )

    const sessionData = sumCounter(
      appServiceSum,
      constants.cgnCpsFields,
      '_sum',
    )
    const cps =
      (sessionData * 1000) /
      (selectedTimePeriod.endTime - selectedTimePeriod.startTime)
    newStats[queryId] = {
      cps,
      mbps,
      logsProcessed,
      logsGenerated,
      ...clusterPartitionNames,
    }
    allCounters[queryId] = counters
  })
  return { stats: newStats, counters: allCounters }
}

const getTrafficDataForCgnFixedNat = (
  appServiceIds: string[],
  apps: IObject,
  data: IObject = {},
  logsProcessedData: IObject = {},
  selectedTimePeriod: IObject,
  isFw: boolean,
) => {
  const queryKeys = [] as string[]
  const allCounters = {}
  const newStats = {} as IObject
  // let maxRecord = undefined as IObject
  // let minRecord = undefined as IObject
  appServiceIds.map((id: string) => {
    const clusterPartitionNames = apps[`${id}_name`] || {}
    const queryId = getQueryId(apps, id)
    if (_.includes(queryKeys, queryId)) {
      return
    }
    queryKeys.push(queryId)
    const {
      [`${queryId}-chart`]: appServiceChart = {},
      [`${queryId}-stats`]: appServiceStats = {},
      [`${queryId}-max`]: appServiceMax = {},
      [`${queryId}-min`]: appServiceMin = {},
      [`${queryId}-start`]: appServiceStart = {},
      [`${queryId}-end`]: appServiceEnd = {},
    } = data
    const { [queryId]: logsProcessedTotal } = logsProcessedData
    // Calculate Logs Processed
    const logsProcessed = _.first(_.values(logsProcessedTotal)) || 0

    // Calculate Logs Generated
    const dataSessionFreedMapStart = _.first(_.values(appServiceStart)) || {}
    const dataSessionFreedMapEnd = _.first(_.values(appServiceEnd)) || {}
    const dataSessionFreedStart = sumCounter(
      dataSessionFreedMapStart,
      constants.cgnFixednatDataSessionFreed,
    )
    const dataSessionFreedEnd = sumCounter(
      dataSessionFreedMapEnd,
      constants.cgnFixednatDataSessionFreed,
    )
    const logsGenerated = dataSessionFreedEnd - dataSessionFreedStart

    const mbps =
      preSecondCount(
        sumCounter(appServiceStats, constants.cgnFixednatBpsFields, '_sum'),
        selectedTimePeriod,
      ) * 8
    // const appServiceDataValues = _.values(appServiceData)
    // const counters = appServiceDataValues.map((values: IObject) => {
    //   const c = sumCounter(values, constants.cgnFixednatDropFields)
    //   const sessionCreated = sumCounter(values, constants.cgnFixednatCpsFields)
    //   if (!maxRecord || values.ts > maxRecord.ts) {
    //     maxRecord = values
    //   }
    //   if (!minRecord || values.ts < minRecord.ts) {
    //     minRecord = values
    //   }
    //   if (sessionCreated === 0) {
    //     return [values.ts, 0]
    //   }
    //   return [values.ts, 1 - c / sessionCreated]
    // })
    const maxSessionData = sumCounter(
      appServiceMax,
      constants.cgnFixednatCpsFields,
      '_max',
    )
    const minSessionData = sumCounter(
      appServiceMin,
      constants.cgnFixednatCpsFields,
      '_min',
    )
    const [counters] = calculateHealthChart.cgn(
      appServiceChart,
      maxSessionData - minSessionData,
      isFw,
    )
    const cps =
      ((maxSessionData - minSessionData) * 1000) /
      (selectedTimePeriod.endTime - selectedTimePeriod.startTime)
    newStats[queryId] = {
      cps,
      mbps,
      logsProcessed,
      logsGenerated,
      ...clusterPartitionNames,
    }
    allCounters[queryId] = counters
  })
  return { stats: newStats, counters: allCounters }
}

// Use for SLB App Service
export const getCpsTraffic = async (
  appServiceIds: string[],
  appServices: IObject[],
  selectedTimePeriod: IObject,
) => {
  const payload = {
    stats: {
      fields: [
        'total_req',
        'total_conn',
        'total_fwd_bytes',
        'total_rev_bytes',
        'total_l7_conn',
      ],
      aggregation: 'sum',
      groupby: 'app_svc_id',
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
    },
  }
  const logProcessedPayloadAccesslog = {}
  const logProcessedPayloadPc = {}
  appServices.map((appService: IObject) => {
    const id = appService.app_svc_id
    payload[id] = {
      field: 'ts',
      fields: ['ts'],
      groupby: 'o_oper_state',
      aggregation: 'count',
      histogram: { field: 'ts', interval: 216000 },
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      filterby: { and: { app_svc_id: id } },
    }
    if (basicUtils.isOSILayer4(appService.protocol)) {
      logProcessedPayloadPc[id] = {
        aggregation: 'count',
        fields: ['ts'],
        rangeby: {
          start: selectedTimePeriod.startTime,
          end: selectedTimePeriod.endTime,
          field: 'ts',
        },
        filterby: {
          and: { app_svc_id: id },
        },
        sort: 'desc',
      }
    } else {
      logProcessedPayloadAccesslog[id] = {
        aggregation: 'count',
        fields: ['timestamp'],
        rangeby: {
          start: selectedTimePeriod.startTime,
          end: selectedTimePeriod.endTime,
          field: 'timestamp',
        },
        filterby: {
          and: { flowId: id },
        },
        sort: 'desc',
      }
    }
  })
  try {
    const { data } = await getDataBySlbVirtualServerPort(payload)
    const { data: logsProcessedData = {} } = await getDataByAccesslog(
      logProcessedPayloadAccesslog,
    )
    const { data: logsProcessedDataPc = {} } = await getDataByPc(
      logProcessedPayloadPc,
    )
    const { stats, counters } = getTrafficData(
      appServiceIds,
      data,
      _.assign(logsProcessedData, logsProcessedDataPc),
      selectedTimePeriod,
    )
    return { stats, counters }
  } catch {
    return { stats: {}, counters: {} }
  }
}

export const getLadcTraffic = async (
  appServiceIds: string[],
  appServices: IObject[],
  selectedTimePeriod: IObject,
) => {
  const hostPayload = {}
  const flowPayload = {}
  const prPayload = {}
  const healthPayload = {}
  const alertPayload = {}
  appServices.map((service: IObject) => {
    hostPayload[service.name] = {
      fields: ['totalClientConnections'],
      aggregation: 'sum',
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'timestamp',
      },
      filterby: {
        and: { applicationId: service.name },
      },
    }
    prPayload[service.name] = {
      fields: ['timestamp'],
      aggregation: 'count',
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'timestamp',
      },
      filterby: {
        and: { applicationId: service.name },
      },
    }
    flowPayload[service.name] = {
      fields: ['bytesSent', 'bytesReceived', 'requests'],
      aggregation: 'sum',
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'timestamp',
      },
      filterby: {
        and: { applicationId: service.name },
      },
    }

    healthPayload[service.name] = {
      fields: ['healthIndex'],
      aggregation: 'avg',
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'timestamp',
      },
      filterby: {
        and: { applicationId: service.name },
      },
      histogram: { field: 'timestamp', interval: 216000 },
    }
    alertPayload[service.name] = {
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'timestamp',
      },
      fields: ['timestamp'],
      aggregation: 'count',
    }
  })
  try {
    const { data: hostData } = await getHostLogs(hostPayload)
    const { data: flowData } = await getFlowLogs(flowPayload)
    const { data: prData } = await getPrLogs(prPayload)
    const { data: healthData } = await getLadcHealth(healthPayload)
    const { data: alertData } = await getLadcAlert(alertPayload)
    const { stats, counters } = getLadcTrafficData(
      appServices,
      hostData,
      flowData,
      prData,
      healthData,
      alertData,
      selectedTimePeriod,
    )
    return { stats, counters }
  } catch {
    return { stats: {}, counters: {} }
  }
}

export const getAlertCounter = async (
  appServiceIds: string[],
  selectedTimePeriod: IObject,
) => {
  const payload = {}
  appServiceIds.map((id: string) => {
    payload[id] = {
      aggregation: 'count',
      fields: ['severity'],
      filterby: { and: { app_svc_id: id } },
      groupby: 'severity',
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      type: 'event',
    }
  })
  const { data = {} } = await getAlertData(payload)
  const alertCounters = {}
  appServiceIds.map((id: string) => {
    const counterData = data[id]
    const counts: IObject = {
      critical: 0,
      moderate: 0,
      normal: 0,
      info: 0,
      debug: 0,
    }
    _.forEach(counterData, (value: IObject, key: string) => {
      if (Number(key) < 4) {
        counts.critical += _.findLast(value)
      } else if (Number(key) === 4) {
        counts.moderate += _.findLast(value)
      } else if (Number(key) === 5) {
        counts.normal += _.findLast(value)
      } else if (Number(key) === 6) {
        counts.info += _.findLast(value)
      } else if (Number(key) === 7) {
        counts.debug += _.findLast(value)
      }
    })
    alertCounters[id] = counts
  })
  return alertCounters
}
const revertData = (apps: IObject, data: IObject) => {
  const newData = {}
  _.keys(apps).map((id: string) => {
    const queryId = getQueryId(apps, id)
    const appData = data[queryId]
    newData[id] = appData
  })
  return newData
}

// For CGN request
const getCpsTrafficNoraml = async (
  api: string,
  appServiceIds: string[],
  appServices: IObject[],
  selectedTimePeriod: IObject,
  existApps: IObject = undefined,
  isFw: boolean,
) => {
  const apps = existApps
    ? existApps
    : await getClusterIdAndPidMapping(appServices)
  const payload = {}
  const logsProcessedPayload = {}
  appServiceIds.map((id: string) => {
    const clusterPid = apps[id] || {}
    const queryId = getQueryId(apps, id)
    logsProcessedPayload[queryId] = {
      fields: ['ts'],
      aggregation: 'count',
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      type: 'event',
      filterby: {
        and: {
          app_svc_id: id,
        },
      },
    }

    payload[`${queryId}-chart`] = {
      fields: constants.cgnDropFields,
      histogram: { field: 'ts', interval: 216000 },
      aggregation: 'sum',
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      filterby: { and: { ...clusterPid } },
    }

    payload[`${queryId}-stats`] = {
      fields: constants.cgnBpsFields,
      aggregation: 'sum',
      // groupby: 'app_svc_id',
      useAppSvc: true,
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      filterby: { and: { ...clusterPid } },
    }
    payload[`${queryId}-max`] = {
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: ['data_session_created'],
      aggregation: 'max',
      filterby: { and: { ...clusterPid } },
    }
    payload[`${queryId}-min`] = {
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: ['data_session_created'],
      aggregation: 'min',
      filterby: { and: { ...clusterPid } },
    }
    payload[`${queryId}-start`] = {
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: ['data_session_freed'],
      field: 'ts',
      sort: 'asc',
      size: 1,
      filterby: { and: { ...clusterPid } },
    }
    payload[`${queryId}-end`] = {
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: ['data_session_freed'],
      field: 'ts',
      sort: 'desc',
      size: 1,
      filterby: { and: { ...clusterPid } },
    }
  })
  try {
    const { data } = await httpRequest(api, payload)
    const { data: logsProcessedData } = await httpRequest(
      'cgn_l4_pc',
      logsProcessedPayload,
    )
    const { stats, counters } = getTrafficDataForCgnNormal(
      appServiceIds,
      apps,
      data,
      logsProcessedData,
      selectedTimePeriod,
      isFw,
    )
    return {
      stats: revertData(apps, stats),
      counters: revertData(apps, counters),
    } as IObject
  } catch {
    return { stats: {}, counters: {} } as IObject
  }
}

const getFwTrafficNoraml = async (
  api: string,
  appServiceIds: string[],
  appServices: IObject[],
  selectedTimePeriod: IObject,
  existApps: IObject = undefined,
  isFw: boolean,
) => {
  const apps = existApps
    ? existApps
    : await getClusterIdAndPidMapping(appServices)
  const payload = {}
  const logsProcessedPayload = {}
  const logsProcessedPayloadFwL4Pc = {}
  const queryKeys = [] as string[]
  appServices.map((appService: IObject) => {
    const id = appService.app_svc_id
    const clusterPid = apps[id] || {}
    const queryId = getQueryId(apps, id)
    if (_.includes(queryKeys, queryId)) {
      return
    }
    queryKeys.push(queryId)
    logsProcessedPayload[queryId] = {
      fields: ['ts'],
      aggregation: 'count',
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      type: 'event',
      filterby: {
        and: {
          rule_set: appService.name,
          ...clusterPid,
        },
      },
    }
    logsProcessedPayloadFwL4Pc[queryId] = {
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: ['ts'],
      aggregation: 'count',
      type: 'event',
      filterby: {
        and: {
          app_svc_id: id,
        },
      },
    }
    payload[`${queryId}-chart`] = {
      fields: constants.fwDropFields,
      histogram: { field: 'ts', interval: 216000 },
      aggregation: 'sum',
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      filterby: { and: { ...clusterPid } },
    }
    // payload[`${queryId}-count`] = {
    //   rangeby: {
    //     start: selectedTimePeriod.startTime,
    //     end: selectedTimePeriod.endTime,
    //     field: 'ts',
    //   },
    //   fields: ['ts'],
    //   aggregation: 'count',
    //   filterby: { and: { ...clusterPid } },
    // }
    payload[`${queryId}-stats`] = {
      fields: constants.fwBpsFields,
      aggregation: 'sum',
      // groupby: 'app_svc_id',
      useAppSvc: true,
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      filterby: { and: { ...clusterPid } },
    }
    payload[`${queryId}-sum`] = {
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: ['data_session_created'],
      aggregation: 'sum',
      filterby: { and: { ...clusterPid } },
    }
    payload[`${queryId}-max`] = {
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: ['data_session_created'],
      aggregation: 'max',
      filterby: { and: { ...clusterPid } },
    }
    payload[`${queryId}-min`] = {
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: ['data_session_created'],
      aggregation: 'min',
      filterby: { and: { ...clusterPid } },
    }
    payload[`${queryId}-start`] = {
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: ['data_session_freed'],
      field: 'ts',
      sort: 'asc',
      size: 1,
      filterby: { and: { ...clusterPid } },
    }
    payload[`${queryId}-end`] = {
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: ['data_session_freed'],
      field: 'ts',
      sort: 'desc',
      size: 1,
      filterby: { and: { ...clusterPid } },
    }
  })
  try {
    const { data } = await httpRequest(api, payload)
    const { data: logsProcessedData } = await httpRequest(
      'cgn_l4_pc',
      logsProcessedPayload,
    )
    const { data: logsProcessedDataFw } = await httpRequest(
      'fw_l4_pc',
      logsProcessedPayloadFwL4Pc,
    )
    const { stats, counters } = getTrafficDataForFwNormal(
      appServiceIds,
      apps,
      data,
      logsProcessedData,
      logsProcessedDataFw,
      selectedTimePeriod,
      isFw,
    )
    return {
      stats: revertData(apps, stats),
      counters: revertData(apps, counters),
    } as IObject
  } catch {
    return { stats: {}, counters: {} } as IObject
  }
}
const getCpsTrafficForCgnFixednat = async (
  appServiceIds: string[],
  appServices: IObject[],
  selectedTimePeriod: IObject,
  existApps: IObject = undefined,
  isFw: boolean = false,
) => {
  const api = 'cgnv6_fixed_nat_global'
  const apps = existApps
    ? existApps
    : await getClusterIdAndPidMapping(appServices)
  const payload = {}
  const logsProcessedPayload = {}
  appServiceIds.map((id: string) => {
    const clusterPid = apps[id] || {}
    const queryId = getQueryId(apps, id)
    payload[`${queryId}-stats`] = {
      fields: constants.cgnFixednatBpsFields,
      aggregation: 'sum',
      // groupby: 'app_svc_id',
      useAppSvc: true,
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      filterby: { and: { ...clusterPid } },
    }
    logsProcessedPayload[queryId] = {
      fields: ['ts'],
      aggregation: 'count',
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      type: 'event',
      filterby: {
        and: {
          app_svc_id: id,
        },
      },
    }
    payload[`${queryId}-chart`] = {
      fields: constants.cgnFixednatDropFields,
      histogram: { field: 'ts', interval: 216000 },
      aggregation: 'sum',
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      filterby: { and: { ...clusterPid } },
    }
    payload[`${queryId}-max`] = {
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: constants.cgnFixednatCpsFields,
      aggregation: 'max',
      filterby: { and: { ...clusterPid } },
    }
    payload[`${queryId}-min`] = {
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: constants.cgnFixednatCpsFields,
      aggregation: 'min',
      filterby: { and: { ...clusterPid } },
    }
    payload[`${queryId}-start`] = {
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: constants.cgnFixednatDataSessionFreed,
      field: 'ts',
      sort: 'asc',
      size: 1,
      filterby: { and: { ...clusterPid } },
    }

    payload[`${queryId}-end`] = {
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: constants.cgnFixednatDataSessionFreed,
      field: 'ts',
      sort: 'desc',
      size: 1,
      filterby: { and: { ...clusterPid } },
    }
  })
  try {
    const { data } = await httpRequest(api, payload)
    const { data: logsProcessedData } = await httpRequest(
      'cgn_l4_pc',
      logsProcessedPayload,
    )
    const { stats, counters } = getTrafficDataForCgnFixedNat(
      appServiceIds,
      apps,
      data,
      logsProcessedData,
      selectedTimePeriod,
      isFw,
    )
    return {
      stats: revertData(apps, stats),
      counters: revertData(apps, counters),
    } as IObject
  } catch {
    return { stats: {}, counters: {} } as IObject
  }
}

const getCpsTrafficForCgnLsn = async (
  appServiceIds: string[],
  appServices: IObject[],
  selectedTimePeriod: IObject,
  apps: IObject = undefined,
  isFw: boolean = false,
) => {
  const result = await getCpsTrafficNoraml(
    'cgnv6_lsn_global',
    appServiceIds,
    appServices,
    selectedTimePeriod,
    apps,
    isFw,
  )
  return result
}

const getCpsTrafficForCgnNat64 = async (
  appServiceIds: string[],
  appServices: IObject[],
  selectedTimePeriod: IObject,
  apps: IObject = undefined,
  isFw: boolean = false,
) => {
  const result = await getCpsTrafficNoraml(
    'cgnv6_nat64_global',
    appServiceIds,
    appServices,
    selectedTimePeriod,
    apps,
    isFw,
  )
  return result
}

const getCpsTrafficForCgnDslite = async (
  appServiceIds: string[],
  appServices: IObject[],
  selectedTimePeriod: IObject,
  apps: IObject = undefined,
  isFw: boolean = false,
) => {
  const result = await getCpsTrafficNoraml(
    'cgnv6_ds_lite_global',
    appServiceIds,
    appServices,
    selectedTimePeriod,
    apps,
    isFw,
  )
  return result
}

export const getCpsTrafficForCgn = async (
  appServiceIds: string[],
  appServices: IObject[],
  selectedTimePeriod: IObject,
) => {
  const lsnAppSvcIds = [] as string[]
  const nat64AppsvcIds = [] as string[]
  const dsliteAppsvcIds = [] as string[]
  const fixednatAppsvcIds = [] as string[]
  const lsnAppSvcs = [] as IObject[]
  const nat64Appsvcs = [] as IObject[]
  const dsliteAppsvcs = [] as IObject[]
  const fixednatAppsvcs = [] as IObject[]
  appServices.map((app: IObject) => {
    if (_.startsWith(app.name, 'lsn')) {
      lsnAppSvcIds.push(app.app_svc_id)
      lsnAppSvcs.push(app)
    } else if (_.startsWith(app.name, 'nat')) {
      nat64AppsvcIds.push(app.app_svc_id)
      nat64Appsvcs.push(app)
    } else if (
      _.startsWith(app.name, 'dslite') ||
      _.startsWith(app.name, 'ds-lite')
    ) {
      dsliteAppsvcIds.push(app.app_svc_id)
      dsliteAppsvcs.push(app)
    } else if (_.startsWith(app.name, 'fixed')) {
      fixednatAppsvcIds.push(app.app_svc_id)
      fixednatAppsvcs.push(app)
    }
  })
  let stats = {}
  let counters = {}
  if (lsnAppSvcIds.length > 0) {
    const lsn = await getCpsTrafficForCgnLsn(
      lsnAppSvcIds,
      lsnAppSvcs,
      selectedTimePeriod,
    )
    stats = { ...stats, ...lsn.stats }
    counters = { ...counters, ...lsn.counters }
  }
  if (nat64AppsvcIds.length > 0) {
    const nat64 = await getCpsTrafficForCgnNat64(
      nat64AppsvcIds,
      nat64Appsvcs,
      selectedTimePeriod,
    )
    stats = { ...stats, ...nat64.stats }
    counters = { ...counters, ...nat64.counters }
  }
  if (dsliteAppsvcIds.length > 0) {
    const dslist = await getCpsTrafficForCgnDslite(
      dsliteAppsvcIds,
      dsliteAppsvcs,
      selectedTimePeriod,
    )
    stats = { ...stats, ...dslist.stats }
    counters = { ...counters, ...dslist.counters }
  }
  if (fixednatAppsvcIds.length > 0) {
    const fixednat = await getCpsTrafficForCgnFixednat(
      fixednatAppsvcIds,
      fixednatAppsvcs,
      selectedTimePeriod,
    )
    stats = { ...stats, ...fixednat.stats }
    counters = { ...counters, ...fixednat.counters }
  }

  return {
    stats,
    counters,
  }
}
const getRuleSetStatus = async (
  appServiceIds: string[],
  selectedTimePeriod: IObject,
) => {
  const payload = {
    gifw: {
      fields: ['o_oper_state', 'app_svc_id', 'ts'],
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      sort: 'asc',
      filterby: {
        and: {
          app_svc_id: appServiceIds.join('|'),
        },
      },
    },
  }
  const { data: { gifw: data } = { gifw: {} } } =
    (await httpRequest('rule_set', payload)) || ({} as IObject)
  const values = _.values(data)
  const status = {}
  values.map((value: IObject) => {
    status[value.app_svc_id] = value.o_oper_state
  })
  return status
}

const reduceStats = (stats: IObject = []) => {
  return stats.reduce((total: IObject, stat: IObject) => {
    _.keys(stat).map((id: string) => {
      const existTotal = total[id] || {}
      const currentStat = stat[id] || {}
      const currentCps = existTotal.cps || 0
      const currentLogsGenerated = existTotal.logsGenerated || 0
      existTotal.cps = currentCps + (currentStat.cps || 0)
      existTotal.logsGenerated =
        currentLogsGenerated + currentStat.logsGenerated
      total[id] = existTotal
    })
    return total
  }, {})
}

const reduceCounters = (counters: IObject[] = []) => {
  const data = counters.reduce(
    (dataMapping: IObject, collections: IObject = {}) => {
      _.forEach(collections, (collection: IObject[] = [], id: string) => {
        const appServiceData = dataMapping[id] || {}
        collection.map((counter: number[]) => {
          const [timestamp, v = 0] = counter
          appServiceData[timestamp] = (appServiceData[timestamp] || 1) - v
        })
        dataMapping[id] = appServiceData
      })
      return dataMapping
    },
    {},
  )
  const newCounters = {}
  _.forEach(data, (dataMapping: IObject = {}, id: string) => {
    const newTraffic = [] as IObject[]
    _.forEach(dataMapping, (value: number, timestamp: string) => {
      newTraffic.push([Number(timestamp), value])
    })
    newCounters[id] = newTraffic
  })
  return newCounters
}

export const getCpsTrafficForFw = async (
  appServiceIds: string[],
  appServices: IObject[],
  selectedTimePeriod: IObject,
) => {
  const status = await getRuleSetStatus(appServiceIds, selectedTimePeriod)
  const apps = await getClusterIdAndPidMapping(appServices)
  const { stats, counters } = await getFwTrafficNoraml(
    'fw_global',
    appServiceIds,
    appServices,
    selectedTimePeriod,
    apps,
    true,
  )

  const lsn = await getCpsTrafficForCgnLsn(
    appServiceIds,
    appServices,
    selectedTimePeriod,
    apps,
    true,
  )
  const nat64 = await getCpsTrafficForCgnNat64(
    appServiceIds,
    appServices,
    selectedTimePeriod,
    apps,
    true,
  )
  const dslite = await getCpsTrafficForCgnDslite(
    appServiceIds,
    appServices,
    selectedTimePeriod,
    apps,
    true,
  )
  const fixednat = await getCpsTrafficForCgnFixednat(
    appServiceIds,
    appServices,
    selectedTimePeriod,
    apps,
    true,
  )

  const newStats = reduceStats([
    stats,
    lsn.stats,
    nat64.stats,
    dslite.stats,
    fixednat.stats,
  ])

  const newCounters = reduceCounters([
    counters,
    lsn.counters,
    nat64.counters,
    dslite.counters,
    fixednat.counters,
  ])
  _.keys(stats).map((id: string) => {
    const stat = stats[id] || {}
    stat.cps = (newStats[id] || {}).cps
    stat.logsGenerated = (newStats[id] || {}).logsGenerated
    stats[id] = stat
  })
  appServiceIds.map((id: string) => {
    const activeStatus = _.toLower(status[id] || 'active') === 'active'
    if (!activeStatus) {
      stats[id] = { status: 'inactive' }
      counters[id] = []
    }
  })
  return { stats, counters: newCounters }
}

/**
 * =============================
 * For the formalus of SLB/CGN/GiFW,
 * please open the below link, to see the comments by Rui Zhang
 * Those formalus have been discussed with Alak.
 * https://a10networks.atlassian.net/browse/GUI-1257
 * =============================
 */
