import { A10Form, A10Select } from '@gui-libraries/widgets'
import React, { useEffect, useState } from 'react'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { IA10ContainerDefaultProps, setupA10Container } from '@gui-libraries/framework'
import { Messages } from 'src/locale/en/Messages'
import A10Panel from 'src/components/ADC/A10Panel'
import _ from 'lodash'
import '../styles/index.less'

const messages = new Messages()

interface ILoggingProps extends IA10ContainerDefaultProps {
  show: boolean
  title: string
  form: any
  logLevelValue: string
  onClose: () => void
  onRef?: (ref: any) => void
  onSubmitForm: (data: string) => void
}

const mapOptions = [
  {
    value: 'INFO',
  },
  {
    value: 'DEBUG',
  },
  {
    value: 'ERROR',
  },
]


const ServiceLoggingEditForm: React.FC<ILoggingProps> = (props) => {
  const { show, title, form,logLevelValue, onClose, onSubmitForm, onRef } = props
  const [logLevel, setLogLevel] = useState(logLevelValue)

  const { getFieldDecorator } = form

  const handleSaveForm = () => {
    form?.validateFieldsAndScroll({ force: true }, (err: any) => {
      if (!err) {
        onSubmitForm(logLevel)
      }
    })
    onClose()
  }

  useEffect(() => {
    if (_.isFunction(onRef)) {
      onRef(this)
    }
    setLogLevel(logLevelValue)
  }, [logLevelValue])

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }
  
  return (
    <FormatSlidingPage
      isOpen={show}
      title={title}
      onRequestClose={onClose}
      onRequestOk={handleSaveForm}
    >
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel showHeader={false} className='logging-panel'>
          <A10Form.Item
            {...formItemLayout}
            label={
              <span className="logging-label mandatoryField">
                {messages.LOG_LEVEL}
              </span>
            }
          >
            {getFieldDecorator('logLevel', {
              rules: [
                { required: true, message: messages.EMPTY_LOG_LEVEL }
              ],
               initialValue: logLevel,
            })(
              <A10Select
                testID="log-level"
                style={{ width: '325px' }}
                title={'logLevel'}
                key={'logLevel'}
                onChange={value => setLogLevel(value)}
                placeholder="Please select log level"
                allowClear={true}
              >
                {mapOptions.map(option => {
                  return (
                    <A10Select.Option
                      value={option.value}
                      key={option.value}
                    >
                      {option.value}
                    </A10Select.Option>
                  )
                })}
              </A10Select>
            )}
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    </FormatSlidingPage>
  )
}

export default setupA10Container(A10Form.create()(ServiceLoggingEditForm))

