import React from 'react'
import { A10Component } from '@gui-libraries/framework'
import { A10Tooltip } from '@gui-libraries/widgets'
import { TooltipPlacement } from '@gui-libraries/widgets/node_modules/antd/lib/tooltip/'

import AppRoot from 'src/settings/appRoot'
import storage from 'src/libraries/storage'
import './styles/helpinfo.scss'

import { AuthenticationService } from 'src/services/index'

export interface IHelpInfoProps {
  title: string
  placement: TooltipPlacement
  helpKey: string
  customCls?: string
}

export interface IHelpInfoState {
  visible: boolean
  helpTextMap: typeof storage.get.HELP_TEXTS
  content: string
  enableHelpTextToEdit: boolean
  updateTextString: string
  enableEditBtn: boolean
}

class HelpInfo extends A10Component<IHelpInfoProps, IHelpInfoState> {
  AuthenticationService = new AuthenticationService()
  AppRoot = new AppRoot()
  constructor(props: IHelpInfoProps) {
    super(props)
    this.state = {
      visible: false,
      helpTextMap: storage.get.HELP_TEXTS,
      content: '',
      enableHelpTextToEdit: false,
      updateTextString: '',
      enableEditBtn: true,
    }
  }

  handleVisibleChange = (visible: boolean) => {
    this.setState({ visible })
  }

  render() {
    const {
      title,
      placement,
      customCls = 'custom-tooltip-input',
      helpKey,
    } = this.props

    const { helpTextMap, visible } = this.state

    return (
      <>
        <A10Tooltip
          placement={placement}
          title={
            <>
              <span>
                {helpTextMap && helpKey
                  ? helpTextMap[helpKey]
                    ? helpTextMap[helpKey]
                    : 'Help Information yet to be provided'
                  : 'Help text not available'}
              </span>
            </>
          }
          trigger="hover"
          visible={visible}
          onVisibleChange={this.handleVisibleChange}
          overlayClassName="popoverinfo"
        >
          <span className={customCls}>
            {
              <>
                  <span style={{ opacity: 0 }}>{title}</span>
              </>
            }
          </span>
        </A10Tooltip>
      </>
    )
  }
}

export default HelpInfo
