import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import { ApplicationConfigs } from 'src/constants/ApplicationConfigs'
import { Clusters } from './Clusters'
import { DeviceList } from './DeviceList'
import { Orchestration } from './Orchestration'
import { AppRoot } from 'src/settings/appRoot'
import { IDefaultMethods } from 'src/containers/Controller'
import { Utilities } from 'src/services'
import storage from 'src/libraries/storage'
import Network from 'src/containers/Launchers/ADC/ProviderSharedObjects/Network'
import System from 'src/containers/Launchers/ADC/ProviderSharedObjects/System'

import 'src/styles/clusters.css'
export interface IInfrastructureProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  tenantToggled: boolean
  selectedApp: string
  onSelectApp: (selectedApp: string) => void
  refreshDeviceSession: (onlyDeviceSesSet: boolean) => void
  refreshClusterSession: (onlyClusterSesSet: boolean) => void
}
export interface IInfrastructureState {
  application: IObject
}

class Infrastructure extends A10Container<
  IInfrastructureProps,
  IInfrastructureState
> {
  state: IInfrastructureState = {
    application: {
      subApps: [],
      name: '',
    },
  }
  ApplicationConfigs = new ApplicationConfigs()
  AppRoot = new AppRoot()
  Utilities = new Utilities()

  componentDidMount() {
    this.updateState()
  }

  componentDidUpdate(prevProps: IInfrastructureProps) {
    const { tenantToggled } = this.props
    if (prevProps.tenantToggled !== tenantToggled) {
      this.updateState()
    }
  }

  updateState() {
    const { selectedApp, onSelectApp } = this.props
    const applications = this.ApplicationConfigs.getAvailableApps()
    const applicationIndex = applications.findIndex(key => {
      return key.name.toLowerCase() === 'infrastructure'
    })
    let selectedAppDetail = applications[applicationIndex].subApps.find(
      (subApp: any) => {
        return subApp.name.toLowerCase() === selectedApp.toLowerCase()
      },
    )

    if (!selectedAppDetail) {
      selectedAppDetail = applications[applicationIndex].subApps[0]
    }

    const allowedRoles = this.ApplicationConfigs.getAllowedRolesSubApps(
      'Infrastructure',
      selectedAppDetail.name,
    )
    if (allowedRoles) {
      const adminLevel = storage.get.ADMIN_LEVEL
      const allowedIndex = allowedRoles.findIndex((key: any) => {
        return key === adminLevel
      })
      if (allowedIndex >= 0) {
        this.setState({
          application: applications[applicationIndex],
        })

        if (storage.get.ADMIN_LEVEL === 'provider') {
          storage.remove.TENANT()
          storage.remove.CURRENT_TENANT()
          storage.set.DRILL_LEVEL('provider')
          this.AppRoot.setRootScopeElement('currentDrillLevel', 'provider')
        }
        storage.remove.SUPER_ADMIN_MODE()
        storage.set.CURRENT_STATE('infrastructure')
        this.AppRoot.setRootScopeElement('currentState', 'infrastructure')

        if (selectedAppDetail.name !== selectedApp) {
          if (onSelectApp instanceof Function) {
            onSelectApp(selectedAppDetail.name)
          }
        }

        return
      }
    }
    this.Utilities.showMessage('INSUFFICENT_PRIVILEGE', 0, true)
  }

  render() {
    const {
      defaultMethods,
      // refreshDeviceSession,
      refreshClusterSession,
    } = this.props
    const selectedApp = this.props.selectedApp.toLowerCase()

    switch (selectedApp) {
      case 'clusters': {
        return <Clusters refreshClusterSession={refreshClusterSession} />
      }
      case 'devices': {
        return <DeviceList refreshClusterSession={refreshClusterSession} />
      }
      case 'orchestration': {
        return <Orchestration />
      }
      case 'network': {
        return <Network tenant="pso" logicalPartition="pso" scope="provider" />
      }
      case 'sharedresources': {
        return <System tenant="pso" logicalPartition="pso" scope="provider" />
      }
      default: {
        return <Clusters defaultMethods={defaultMethods} />
      }
    }
  }
}

export default setupA10Container(Infrastructure)
