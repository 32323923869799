import React from 'react'
import moment, { Moment } from 'moment-timezone'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Form,
  A10Input,
  A10Select,
  A10Icon,
  A10DatePicker,
  A10Row,
  A10Col,
  A10Tag,
  A10Radio,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import storage from 'src/libraries/storage'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import { DashboardService, TroubleShootService, Utilities } from 'src/services/index'
import { Messages } from 'src/locale/en'
import ClusterDeviceTree from 'src/components/shared/ClusterDeviceTree'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

export interface IBackupFormProps extends IA10ContainerDefaultProps {
  isScheduled: boolean
  deviceName: string
  formData: IFormData
  onRef?: any
  form?: IObject
  onChange: (data: IFormData) => void
}

export interface ICluster {
  name: string
}

export interface ITreeNode {
  key: string
  title: string
  children?: ITreeNode[]
}

export interface IBackupFormStates {
  provider: string
  formData: IFormData
  clusterList: ICluster[]
  clusterDeviceTree: ITreeNode[]
  checkedDevices: string[]
  timezones: IObject[]
  backups: IObject[]
  isLoading: boolean
}

export interface IFormData {
  'device-backup': {
    name: string
    description: string
    deviceList: string[]
    when: string
    frequency: string
    timezone: string
    starts: Moment
  }
}

class BackupForm extends A10Container<IBackupFormProps, IBackupFormStates> {
  DashboardService = new DashboardService()
  TroubleShootService = new TroubleShootService()
  Utilities = new Utilities()
  Messages = new Messages()
  DATE_STRING_FORMAT = 'MMM DD, YYYY hh:mm A'
  constructor(props: IBackupFormProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER || '',
      formData: {
        'device-backup': {
          name: '',
          description: '',
          when: props.isScheduled ? 'schedule' : 'now',
          frequency: 'daily',
          deviceList: props.deviceName ? [props.deviceName] : [],
          timezone: undefined,
          starts: null,
        },
      },
      clusterList: [],
      clusterDeviceTree: [],
      checkedDevices: props.deviceName ? [props.deviceName] : [],
      timezones: [],
      backups: [],
      isLoading: false,
    }
  }

  componentDidMount() {
    this.getData()
    this.loadTimezones()
    this.props.onRef(this)
  }

  onChangeCallback(formData: IFormData) {
    this.props.onChange(formData)
  }

  getData = () => {
    this.setState({
      isLoading: true
    })
    this.TroubleShootService.getDeviceMgmtScheduledJobsForState(null, null, [
      storage.get.PROVIDER,
      'DeviceConfigurationBackup',
      'scheduled'
    ])
      .then((resp: IObject) => {
        const backupList = resp?.data['device-mgmt-scheduled-job-list'] || []
        //Remove records that are not sheduled
        const filteredBackupList = backupList.filter((backup: IObject) => {
          return backup?.schedule?.['is-repeating'] === true
        })
        this.setState({
          isLoading: false,
          backups: filteredBackupList,
        })
      })
      .catch(() => {
        this.setState({
          isLoading: false
        })
      })
  }

  onNameChange = (e: any) => {
    const state = { ...this.state }
    state.formData['device-backup'].name = e.target.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onDescriptionChange = (e: any) => {
    const state = { ...this.state }
    state.formData['device-backup'].description = e.target.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onWhenChange = (e: any) => {
    const state = { ...this.state }
    state.formData['device-backup'].when = e.target.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onFrequencyChange = (frequency: any) => {
    const state = { ...this.state }
    state.formData['device-backup'].frequency = frequency
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onStartChange = (starts: any) => {
    const state = { ...this.state }
    if (moment(starts) < moment()) {
      starts = ''
    }
    state.formData['device-backup'].starts = starts
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onTimezoneChange = (timezone: any) => {
    const state = { ...this.state }
    state.formData['device-backup'].timezone = timezone
    const timezoneObj = JSON.parse(timezone)
    moment.tz.setDefault(timezoneObj.value)

    state.formData['device-backup'].starts = moment()
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onSelectDevice = (selectedDevices: string[]) => {
    const state = { ...this.state }
    state.formData['device-backup'].deviceList = selectedDevices
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  loadTimezones() {
    const timezoneResponse = this.DashboardService.getTimezones(
      null,
      null,
      null,
    )
    timezoneResponse
      .then((response: any) => {
        if (response?.data?.timezones && response.data.timezones.length > 0) {
          const timezoneStr = this.DashboardService.getDefaultBrowserTimeZone(
            response.data.timezones,
          )
          const { formData } = this.state
          formData['device-backup'].timezone = timezoneStr
          this.setState({ timezones: response.data.timezones, formData })
        }
      })
      .catch((error: any) => {
        const message = this.Utilities.returnErrorMessage(error)
        this.Utilities.showMessage('Unable to load timezones', 0, 0, message)
      })
  }

  renderDeviceDetail(device: IObject, index: number) {
    return (
      <A10Row type="flex" align="bottom">
        <div className={styles.deviceName}>
          <span>{device.name}</span>
        </div>
        <A10Col className={styles.host}>
          <A10Tag>{device.host}</A10Tag>
        </A10Col>
      </A10Row>
    )
  }

  validateReportName = (rule: IObject, value: IObject, cb: IObject) => {
    let isValid: boolean = true
    const { backups } = this.state
    const index = backups?.findIndex((key: IObject) => {
      return key.name === value
    })
    if (!value) {
      rule.message = this.Messages.VALIDATION_BACKUP_NAME
      isValid = false
    } else if (index > -1) {
      rule.message = this.Messages.DUPLICATE_BACKUP_NAME
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateStartDate = (rule: IObject, value: IObject, cb: IObject) => {
    let isValid = true
    const dateString = moment(value).format(this.DATE_STRING_FORMAT)
    const dateObject = moment(dateString, this.DATE_STRING_FORMAT)
    if (!value) {
      rule.message = this.Messages.VALIDATION_START_DATE_AND_TIME_REQUIRED
      isValid = false
      return isValid ? cb() : cb(true)
    } else if (dateObject < moment()) {
      rule.message = this.Messages.VALIDATION_START_DATE_AND_TIME
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }
    const { getFieldDecorator } = this.props.form
    const { name, starts, timezone } = this.state?.formData?.['device-backup']
    return (
      <A10Form layout="horizontal" hideRequiredMark={true}>
        <A10Panel
          title={
            <A10IconTextGroup
              text="Backup"
              icon={
                <A10Icon
                  style={{ marginRight: 10 }}
                  app="global"
                  type="form-section"
                />
              }
            />
          }
        >
          <A10Form.Item
            {...formItemLayout}
            label={
              <span className="mandatoryField">
                {this.Messages.BACKUP_NAME}
              </span>
            }
          >
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  validator: this.validateReportName.bind(this),
                },
              ],
              initialValue: name,
            })(
              <A10Input
                placeholder="Enter a Backup Name"
                maxLength={128}
                onChange={this.onNameChange}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="mandatoryField">
                  {this.Messages.SELECT_DEVICE}
                </span>
                <span>
                  <HelpInfo
                    placement="leftTop"
                    title="Select Device"
                    helpKey="HELP_BACKUP_SELECT_DEVICE"
                  />
                </span>
              </>
            }
          >
            <ClusterDeviceTree
              onChange={this.onSelectDevice}
              treeHeight={200}
            />
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>When</span>
                <span>
                  <HelpInfo
                    placement="leftTop"
                    title="When"
                    helpKey="HELP_BACKUP_WHEN"
                  />
                </span>
              </>
            }
          >
            <A10Radio.Group
              defaultValue="schedule"
              value={this.state.formData['device-backup'].when}
              onChange={this.onWhenChange}
            >
              <A10Radio value="now">Now</A10Radio>
              <A10Radio value="schedule">Schedule</A10Radio>
            </A10Radio.Group>
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="mandatoryField">{this.Messages.TIMEZONE}</span>
                <span>
                  <HelpInfo
                    placement="leftTop"
                    title={this.Messages.TIMEZONE}
                    helpKey="HELP_BACKUP_TIMEZONE"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('timezone', {
              rules: [
                {
                  required: true,
                },
              ],
              initialValue: timezone,
            })(
              <A10Select
                placeholder="Please select a timezone"
                onChange={this.onTimezoneChange}
              >
                {this.state.timezones.map((option: any, i: number) => {
                  return (
                    <A10Select.Option
                      key={option.label}
                      value={JSON.stringify(option)}
                    >
                      {option.label}
                    </A10Select.Option>
                  )
                })}
              </A10Select>,
            )}
          </A10Form.Item>
          {this.state.formData['device-backup'].when === 'schedule' ? (
            <>
              <A10Form.Item
                {...formItemLayout}
                label={<span className="mandatoryField">Frequency</span>}
              >
                <A10Select
                  defaultValue="daily"
                  onChange={this.onFrequencyChange}
                >
                  <A10Select.Option value="daily">Daily</A10Select.Option>
                  <A10Select.Option value="weekly">Weekly</A10Select.Option>
                  <A10Select.Option value="monthly">Monthly</A10Select.Option>
                </A10Select>
              </A10Form.Item>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    <span className="mandatoryField">Start Backup From</span>
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title="Start Backup From"
                        helpKey="HELP_BACKUP_STARTS_ON"
                      />
                    </span>
                  </>
                }
              >
                {getFieldDecorator('starts', {
                  rules: [
                    {
                      required: true,
                      validator: this.validateStartDate,
                      type: 'any',
                    },
                  ],
                  initialValue: starts || undefined,
                })(
                  <A10DatePicker
                    format={this.DATE_STRING_FORMAT}
                    showTime={{ format: 'hh:mm A' }}
                    onChange={this.onStartChange}
                  />,
                )}
              </A10Form.Item>
            </>
          ) : null}
          <A10Form.Item {...formItemLayout} label="Description">
            <A10Input.TextArea
              placeholder="Enter some description about this backup"
              value={this.state.formData['device-backup'].description}
              onChange={this.onDescriptionChange}
              rows={4}
            />
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(BackupForm))
