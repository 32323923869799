import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import { Devices } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/Devices'

export interface IDeviceListProps extends IA10ContainerDefaultProps {
  refreshClusterSession: (onlyClusterSesSet: boolean) => void
}
export interface IDeviceListState {}

class DeviceList extends A10Container<IDeviceListProps, IDeviceListState> {
  render() {
    return <Devices refreshClusterSession={this.props.refreshClusterSession} />
  }
}
export default setupA10Container(DeviceList)
