import * as _ from 'lodash'

export const requestRouter = (service: IObject, urlNames: string[]) => {
  return new Promise((resolve: any) => {
    try {
      const response = service.getRequest(
        null,
        null,
        urlNames,
        'DEVICE_ROUTING_PROTOCOL',
      )
      response.then(async (res: any) => {
        resolve(_.get(res, 'data.router', []))
      })
    } catch (err) {
      resolve({})
    }
  })
}

export const requestRouterBFD = (service: IObject, urlNames: string[]) => {
  return new Promise((resolve: any) => {
    try {
      const response = service.getRequest(
        null,
        null,
        urlNames,
        'DEVICE_ROUTING_PROTOCOL_BFD',
      )
      response.then(async (res: any) => {
        resolve(_.get(res, 'data.bfd', []))
      })
    } catch (err) {
      resolve({})
    }
  })
}
