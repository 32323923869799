import {
  A10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Notification } from '@gui-libraries/widgets'

import { validateAppServiceGroup } from 'src/libraries/common'

export interface IAppServiceGroupAssignmentProps
  extends IA10ContainerDefaultProps {
  appServiceGroupList?: IObject[]
  appServicesMap?: IObject
  appServiceOptions?: IObject[]
  usableAppServiceOptions?: IObject[]
  updateAppServiceGroup?: (
    appServiceGroupList: IObject[],
    appServiceGroupToBeUpdated: IObject,
  ) => void
}

export interface IAppServiceGroupAssignmentState {
  selectItem?: number
  datasourceToAppServiceGroup?: string[]
  appServiceGroupToBeUpdated?: IObject
  isFormOpen?: boolean
  selectedAppService?: string
}

export class AppServiceGroupAssignment<
  P extends IAppServiceGroupAssignmentProps,
  S extends IAppServiceGroupAssignmentState
> extends A10Container<P, S> {
  constructor(props: P) {
    super(props)
    this.state = {
      datasourceToAppServiceGroup: [] as string[],
      appServiceGroupToBeUpdated: {},
      selectItem: -1,
      isFormOpen: false,
    } as S
  }

  onAssignClick = (index: number) => {
    return () => {
      const { appServiceGroupList } = this.props
      const { datasourceToAppServiceGroup } = this.state
      const gropName = datasourceToAppServiceGroup[index]

      const group = gropName
        ? appServiceGroupList.find((element: IObject) => {
            return element.display_name === gropName
          })
        : {
            appServices: [],
          }

      this.setState({
        appServiceGroupToBeUpdated: {
          ...group,
          appServices: [...group.appServices],
        },
        isFormOpen: true,
        selectItem: index,
      })
    }
  }

  onAssignAppServiceGroupRequestOk = async () => {
    const { appServiceGroupList, updateAppServiceGroup } = this.props
    const {
      selectItem,
      datasourceToAppServiceGroup,
      appServiceGroupToBeUpdated,
    } = this.state

    if (validateAppServiceGroup(appServiceGroupToBeUpdated)) {
      const newDatasourceToAppServiceGroup = [...datasourceToAppServiceGroup]
      newDatasourceToAppServiceGroup[selectItem] =
        appServiceGroupToBeUpdated.display_name

      await updateAppServiceGroup(
        appServiceGroupList,
        appServiceGroupToBeUpdated,
      )

      this.setState({
        isFormOpen: false,
        datasourceToAppServiceGroup: newDatasourceToAppServiceGroup,
        appServiceGroupToBeUpdated: {},
        selectItem: -1,
      })
    } else {
      A10Notification.error({
        message: 'Please fill in all App Service fields.',
      })
    }
  }
}
