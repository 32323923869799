import { getNS, IHTTPRequestOptions } from '@gui-libraries/framework'
import { _ } from '@gui-libraries/framework'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'

const HPCAPI_PREFIX = '/hpcapi/v3'
const actionURL = `${HPCAPI_PREFIX}/provider/${storage.get.PROVIDER}/tenant/pso/logical-partition/pso/object-group`

export const getObjectGroupList = (params: IObject): IHTTPRequestOptions => {
  return {
    namespace: getNS('OBJECT_GROUP_LIST'),
    request: async (epicDependence: IObject) => {
      try {
        let objectGroupList: IObject[] = []

        let netGotCount = 0
        let svcGotCount = 0
        let netList: IObject[] = []
        let svcList: IObject[] = []
        let appList: IObject[] = []
        const paramName = params.name
        let svcNeedCount = 0
        let appNeedCount = 0
        delete params.name

        // network object-group
        if (params.start === 0) {
          params['net-name'] = paramName
          const { data: netRes } = await httpClient.get(
            `${actionURL}/network`,
            { params },
          )
          netList = netRes['network-list'] || []
        } else {
          const { data: netCountRes } = await httpClient.get(
            `${actionURL}/network`,
            {
              params: { total: true, 'net-name': paramName },
            },
          )
          if (netCountRes && netCountRes['total-count']) {
            if (netCountRes['total-count'] - 1 >= params.start) {
              params['net-name'] = paramName
              const {
                data: netRes,
              } = await httpClient.get(`${actionURL}/network`, { params })
              netList = netRes['network-list'] || []
              params.start = 0
            } else {
              params.start = params.start - netCountRes['total-count']
            }
          }
        }
        netGotCount = netList.length
        svcNeedCount =
          netGotCount < params.count ? params.count - netGotCount : 0
        params.count = svcNeedCount

        // service object-group
        if (svcNeedCount > 0) {
          delete params['net-name']
          params['svc-name'] = paramName
          if (params.start === 0) {
            const {
              data: svcRes,
            } = await httpClient.get(`${actionURL}/service`, { params })
            svcList = svcRes['service-list'] || []
          } else {
            const { data: svcCountRes } = await httpClient.get(
              `${actionURL}/service`,
              {
                params: { total: true, 'svc-name': paramName },
              },
            )
            if (svcCountRes && svcCountRes['total-count']) {
              if (svcCountRes['total-count'] - 1 >= params.start) {
                const {
                  data: svcRes,
                } = await httpClient.get(`${actionURL}/service`, { params })
                svcList = svcRes['service-list'] || []
                params.start = 0
              } else {
                params.start = params.start - svcCountRes['total-count']
              }
            }
          }
        }
        svcGotCount = svcList.length
        appNeedCount =
          svcGotCount < params.count ? params.count - svcGotCount : 0
        params.count = appNeedCount

        // application object-group
        if (appNeedCount > 0) {
          delete params['svc-name']
          params['app-name'] = paramName
          if (params.start === 0) {
            const {
              data: appRes,
            } = await httpClient.get(`${actionURL}/application`, { params })
            appList = appRes['application-list'] || []
          } else {
            const { data: appCountRes } = await httpClient.get(
              `${actionURL}/application`,
              {
                params: { total: true, 'app-name': paramName },
              },
            )
            if (appCountRes && appCountRes['total-count']) {
              if (appCountRes['total-count'] - 1 >= params.start) {
                const {
                  data: appRes,
                } = await httpClient.get(`${actionURL}/application`, { params })
                appList = appRes['application-list'] || []
              }
            }
          }
        }

        // network object-group
        for (const net of netList) {
          net.name = net['net-name']
          net.type = 'Network'
          net.mode = net.usage.toUpperCase()
          net.count = 0
          net.objects = []
          if (net.rules) {
            net.count = net.rules.length
            for (const rule of net.rules) {
              if (rule.any) {
                net.objects.push('any')
              }
              if (rule.subnet && rule['rev-subnet-mask']) {
                net.objects.push(rule.subnet + ' ' + rule['rev-subnet-mask'])
              }
              if (rule['ipv6-subnet']) {
                net.objects.push(rule['ipv6-subnet'])
              }
              if (rule['host-v4']) {
                net.objects.push(rule['host-v4'])
              }
              if (rule['host-v6']) {
                net.objects.push(rule['host-v6'])
              }
              if (rule['ip-range-start'] && rule['ip-range-end']) {
                net.objects.push(
                  rule['ip-range-start'] + ' to ' + rule['ip-range-end'],
                )
              }
              if (rule['obj-network']) {
                net.objects.push(rule['obj-network'])
              }
              if (rule['fw-ipv4-address']) {
                net.objects.push(rule['fw-ipv4-address'])
              }
              if (rule['slb-server']) {
                net.objects.push(rule['slb-server'])
              }
              if (rule['slb-vserver']) {
                net.objects.push(rule['slb-vserver'])
              }
              if (rule['ipv6-range-start'] && rule['ipv6-range-end']) {
                net.objects.push(
                  rule['ipv6-range-start'] + ' to ' + rule['ipv6-range-end'],
                )
              }
              if (rule['fw-ipv6-subnet']) {
                net.objects.push(rule['fw-ipv6-subnet'])
              }
            }
          }
        }
        objectGroupList = objectGroupList.concat(netList)

        // service object-group
        for (const svc of svcList) {
          svc.name = svc['svc-name']
          svc.type = 'Service'
          svc.mode = 'ACL/FW'
          svc.count = 0
          svc.objects = []
          if (svc.rules) {
            svc.count = svc.rules.length
            for (const rule of svc.rules) {
              if (rule.icmp) {
                let icmpStr = 'icmp'
                icmpStr = rule['any-type']
                  ? icmpStr + ' type any-type'
                  : icmpStr
                icmpStr = rule['special-type']
                  ? icmpStr + ' type ' + rule['special-type']
                  : icmpStr
                icmpStr = rule['icmp-type']
                  ? icmpStr + ' type ' + rule['icmp-type']
                  : icmpStr
                icmpStr = rule['any-code']
                  ? icmpStr + ' code any-code'
                  : icmpStr
                icmpStr = rule['special-code']
                  ? icmpStr + ' code ' + rule['special-code']
                  : icmpStr
                icmpStr = rule['icmp-code']
                  ? icmpStr + ' code ' + rule['icmp-code']
                  : icmpStr
                svc.objects.push(icmpStr)
              }
              if (rule.icmpv6) {
                let icmpv6Str = 'icmpv6'
                icmpv6Str = rule['v6-any-type']
                  ? icmpv6Str + ' type any-type'
                  : icmpv6Str
                icmpv6Str = rule['special-v6-type']
                  ? icmpv6Str + ' type ' + rule['special-v6-type']
                  : icmpv6Str
                icmpv6Str = rule['icmpv6-type']
                  ? icmpv6Str + ' type ' + rule['icmpv6-type']
                  : icmpv6Str
                icmpv6Str = rule['v6-any-code']
                  ? icmpv6Str + ' code any-code'
                  : icmpv6Str
                icmpv6Str = rule['special-v6-code']
                  ? icmpv6Str + ' code ' + rule['special-v6-code']
                  : icmpv6Str
                icmpv6Str = rule['icmpv6-code']
                  ? icmpv6Str + ' code ' + rule['icmpv6-code']
                  : icmpv6Str
                svc.objects.push(icmpv6Str)
              }
              if (rule['tcp-udp']) {
                let tcpUdpStr = rule['tcp-udp']
                tcpUdpStr = rule.source ? tcpUdpStr + ' src' : tcpUdpStr
                tcpUdpStr = rule['eq-src']
                  ? tcpUdpStr + ' eq ' + rule['eq-src']
                  : tcpUdpStr
                tcpUdpStr = rule['gt-src']
                  ? tcpUdpStr + ' gt ' + rule['gt-src']
                  : tcpUdpStr
                tcpUdpStr = rule['lt-src']
                  ? tcpUdpStr + ' lt ' + rule['lt-src']
                  : tcpUdpStr
                tcpUdpStr =
                  rule['range-src'] && rule['port-num-end-src']
                    ? tcpUdpStr +
                      ' range ' +
                      rule['range-src'] +
                      ' - ' +
                      rule['port-num-end-src']
                    : tcpUdpStr
                tcpUdpStr =
                  rule['eq-dst'] ||
                  rule['gt-dst'] ||
                  rule['lt-dst'] ||
                  rule['range-dst']
                    ? tcpUdpStr + ' dst'
                    : tcpUdpStr
                tcpUdpStr = rule['eq-dst']
                  ? tcpUdpStr + ' eq ' + rule['eq-dst']
                  : tcpUdpStr
                tcpUdpStr = rule['gt-dst']
                  ? tcpUdpStr + ' gt ' + rule['gt-dst']
                  : tcpUdpStr
                tcpUdpStr = rule['lt-dst']
                  ? tcpUdpStr + ' lt ' + rule['lt-dst']
                  : tcpUdpStr
                tcpUdpStr =
                  rule['range-dst'] && rule['port-num-end-dst']
                    ? tcpUdpStr +
                      ' range ' +
                      rule['range-dst'] +
                      ' - ' +
                      rule['port-num-end-dst']
                    : tcpUdpStr
                tcpUdpStr = rule.alg
                  ? tcpUdpStr + ' alg ' + rule.alg
                  : tcpUdpStr
                svc.objects.push(tcpUdpStr)
              }
              if (rule['protocol-id']) {
                svc.objects.push('protocol id ' + rule['protocol-id'])
              }
            }
          }
        }
        objectGroupList = objectGroupList.concat(svcList)

        // application object-group
        for (const app of appList) {
          app.name = app['app-name']
          app.type = 'Application'
          app.mode = 'FW'
          app.count = 0
          app.objects = []
          if (app['app-list']) {
            app.count = app['app-list'].length
            for (const appItem of app['app-list']) {
              if (appItem.protocol) {
                app.objects.push(appItem.protocol)
              }
              if (appItem['protocol-tag']) {
                app.objects.push(appItem['protocol-tag'])
              }
            }
          }
        }
        objectGroupList = objectGroupList.concat(appList)

        objectGroupList = _.sortBy(objectGroupList, ['name'])
        return objectGroupList
      } catch (err) {
        console.error(err)
      }
      return []
    },
  }
}

export const getObjectGroupCount = (
  searchText: string,
): IHTTPRequestOptions => {
  return {
    namespace: getNS('OBJECT_GROUP_COUNT'),
    request: async (epicDependence: IObject) => {
      try {
        const params: IObject = { total: true }
        let objectGroupCount = 0

        // network object-group
        if (searchText) {
          params['net-name'] = encodeURIComponent(searchText)
        }
        const { data: netRes } = await httpClient.get(`${actionURL}/network`, {
          params,
        })
        if (netRes && netRes['total-count']) {
          objectGroupCount = objectGroupCount + netRes['total-count'] || 0
        }

        // service object-group
        if (searchText) {
          delete params['net-name']
          params['svc-name'] = encodeURIComponent(searchText)
        }
        const { data: svcRes } = await httpClient.get(`${actionURL}/service`, {
          params,
        })
        if (svcRes && svcRes['total-count']) {
          objectGroupCount = objectGroupCount + svcRes['total-count'] || 0
        }

        // application object-group
        if (searchText) {
          delete params['svc-name']
          params['app-name'] = encodeURIComponent(searchText)
        }
        const {
          data: appRes,
        } = await httpClient.get(`${actionURL}/application`, { params })
        if (appRes && appRes['total-count']) {
          objectGroupCount = objectGroupCount + appRes['total-count'] || 0
        }

        return objectGroupCount
      } catch (err) {
        console.error(err)
      }
      return 0
    },
  }
}
