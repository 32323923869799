import React, { useState, useEffect, useCallback } from 'react'
import ReactLoading from 'react-loading'
import { _ } from '@gui-libraries/framework'
import {
  A10Collapse,
  A10Icon,
  A10Row,
  A10Table,
  A10DropdownMenu,
  A10SlidingPage,
  A10Modal,
  A10Button,
  A10Notification,
  A10Checkbox,
} from '@gui-libraries/widgets'
import { AutoFormV15 } from '@gui-libraries/apps'

import RoundNumber from 'src/components/shared/RoundNumber'
import { InfrastructureService } from 'src/services/index'
import storage from 'src/libraries/storage'

const NetworkStaticRoutesRIBIPv4: React.FC = (props: IObject) => {
  const [showSlidingPage, setShowSlidingPage] = useState(false)
  const [dest, setDest] = useState('')
  const [mask, setMask] = useState('')
  const infrastructureService = new InfrastructureService()
  const [ribList, setRibList] = useState([] as IObject[])
  const [start, setStart] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [destAddrs, setDestAddrs] = useState(0)
  const [forceDelete, setForceDelete] = useState(false)
  const [ribTotal, setRibTotal] = useState(0)
  const { selectedPartition = 'shared' } = props
  // const getData = () => {
  //   setIsLoading(true)
  //   const provider = storage.get.PROVIDER')
  //   infrastructureService.getRequest(null, null, [
  //     provider,
  //     props.deviceObj.name,
  //     'shared',
  //     `?start=${start}&count=10`
  //   ],
  //     'GET_DEVICE_IPV4_RIB')
  //     .then((res: IObject) => {
  //       try {
  //         const ribs = res.data['rib-list']
  //         console.log('ribs:', ribs)
  //         setRibList(ribs)
  //         setIsLoading(false)
  //       } catch (e) {
  //         setIsLoading(false)
  //       }
  //     })
  // }

  const updateTotal = () => {
    const { deviceObj, service } = props
    const provider = deviceObj['provider-name']
    const deviceName = deviceObj['name']

    Promise.all([
      service.getRequest(
        null,
        null,
        [provider, deviceName, selectedPartition, '?total=true'],
        'GET_DEVICE_IPV4_RIB',
      ),
    ])
      .then((response: IObject[]) => {
        try {
          setRibTotal(_.get(response[0], 'data.total-count', 0))
        } catch (e) {
          console.log(e)
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const getData = () => {
    setIsLoading(true)
    const provider = storage.get.PROVIDER
    infrastructureService
      .getRequest(
        null,
        null,
        [provider, props.deviceObj.name, selectedPartition, ''],
        'GET_DEVICE_IPV4_RIB',
      )
      .then((res: IObject) => {
        try {
          const ribs = [] as IObject[]
          const oriRibs = res.data['rib-list']
          setDestAddrs(oriRibs.length)
          oriRibs.forEach((obj: IObject) => {
            if ('ip-nexthop-ipv4' in obj) {
              _.get(obj, 'ip-nexthop-ipv4', []).forEach((nexthop: IObject) => {
                ribs.push({
                  'ip-dest-addr': _.get(obj, 'ip-dest-addr', ''),
                  'ip-mask': _.get(obj, 'ip-mask', ''),
                  nexthopRouteType: 'IPv4',
                  nexthopAddr: _.get(nexthop, 'ip-next-hop', ''),
                  description: _.get(nexthop, 'description-nexthop-ip', ''),
                })
              })
            }
            if ('ip-nexthop-lif' in obj) {
              _.get(obj, 'ip-nexthop-lif', []).forEach((nexthop: IObject) => {
                ribs.push({
                  'ip-dest-addr': _.get(obj, 'ip-dest-addr', ''),
                  'ip-mask': _.get(obj, 'ip-mask', ''),
                  nexthopRouteType: 'Lif',
                  nexthopAddr: _.get(nexthop, 'lif', '')
                    ? `Lif${_.get(nexthop, 'lif', '')}`
                    : '',
                  description: _.get(nexthop, 'description-nexthop-lif', ''),
                })
              })
            }
            if ('ip-nexthop-tunnel' in obj) {
              _.get(obj, 'ip-nexthop-tunnel', []).forEach(
                (nexthop: IObject) => {
                  ribs.push({
                    'ip-dest-addr': _.get(obj, 'ip-dest-addr', ''),
                    'ip-mask': _.get(obj, 'ip-mask', ''),
                    nexthopRouteType: 'Tunnel',
                    nexthopAddr: _.get(nexthop, 'tunnel', '')
                      ? `Tunnel${_.get(nexthop, 'tunnel', '')}`
                      : '',
                    description: _.get(
                      nexthop,
                      'description-nexthop-tunnel',
                      '',
                    ),
                  })
                },
              )
            }
            if ('ip-nexthop-partition' in obj) {
              _.get(obj, 'ip-nexthop-partition', []).forEach(
                (nexthop: IObject) => {
                  ribs.push({
                    'ip-dest-addr': _.get(obj, 'ip-dest-addr', ''),
                    'ip-mask': _.get(obj, 'ip-mask', ''),
                    nexthopRouteType: 'Partition',
                    nexthopAddr: _.get(nexthop, 'partition-name', ''),
                    description: _.get(
                      nexthop,
                      'description-nexthop-partition',
                      '',
                    ),
                  })
                },
              )
            }
          })
          setRibList(ribs)
          setIsLoading(false)
        } catch (e) {
          setIsLoading(false)
        }
      })
  }

  useEffect(() => {
    updateTotal()
    getData()
  }, [start, props.deviceObj.name, props.refreshTimer, selectedPartition])

  const columns = React.useMemo(
    () => [
      {
        title: 'Dest Address',
        dataIndex: 'ip-dest-addr',
        sorter: (a: IObject, b: IObject) => {
          return a['ip-dest-addr'] > b['ip-dest-addr'] ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Mask',
        dataIndex: 'ip-mask',
        sorter: (a: IObject, b: IObject) => {
          return a['ip-mask'] > b['ip-mask'] ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Nexthop Route Type',
        dataIndex: 'nexthopRouteType',
        sorter: (a: IObject, b: IObject) => {
          return a.nexthopRouteType > b.nexthopRouteType ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Nexthop Address',
        dataIndex: 'nexthopAddr',
        sorter: (a: IObject, b: IObject) => {
          return a.nexthopAddr > b.nexthopAddr ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        sorter: (a: IObject, b: IObject) => {
          return a.description > b.description ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        dataIndex: 'actions',
        width: 30,
        render: (text: any, record: any) => {
          return (
            !storage.get.IS_OPERATOR_USER && (
              <A10DropdownMenu
                title=""
                menu={[
                  <div
                    key="edit"
                    onClick={handleEdit.bind(
                      this,
                      record['ip-dest-addr'],
                      record['ip-mask'],
                    )}
                  >
                    Edit
                  </div>,
                  <div
                    key="delete"
                    onClick={handleDelete.bind(
                      this,
                      record['ip-dest-addr'],
                      record['ip-mask'],
                    )}
                  >
                    Delete
                  </div>,
                ]}
                style={{ color: '#757575', marginBottom: '-15px' }}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            )
          )
        },
      },
    ],
    [],
  )

  const toggleSlidingPage = (isOpen: boolean) => {
    setShowSlidingPage(isOpen)
  }

  const handleAdd = (event: React.MouseEvent<HTMLElement>) => {
    toggleSlidingPage(true)
    setDest('')
    setMask('')
    event.stopPropagation()
  }

  const handleEdit = (ipDest: string, ipMask: string) => {
    setDest(ipDest)
    setMask(ipMask)
    setShowSlidingPage(true)
  }

  const handleDelete = (ipDest: string, ipMask: string) => {
    setForceDelete(false)
    setDest(ipDest)
    setMask(ipMask)
    toggleModal(true)
  }

  const handleSuccess = useCallback(() => {
    if (!dest) {
      updateTotal()
    } else {
      getData()
    }
    toggleSlidingPage(false)
  }, [dest])

  const handlePageChange = (page: number, pageSize: number) => {
    setStart((page - 1) * pageSize)
  }

  const toggleModal = (isOpen: boolean) => {
    setShowModal(isOpen)
  }

  const handleOk = useCallback(() => {
    const provider = storage.get.PROVIDER
    infrastructureService
      .getRequest(
        null,
        null,
        [
          provider,
          props.deviceObj.name,
          selectedPartition,
          `${dest}${encodeURIComponent('+' + mask)}`,
          forceDelete ? '&force=true' : '',
        ],
        'DELETE_DEVICE_IPV4_RIB',
      )
      .then((res: IObject) => {
        updateTotal()
        toggleModal(false)
        A10Notification.success({
          message: 'Success!',
          description: `Delete IPv4 rib static route ${dest} successfully.`,
          style: {
            wordWrap: 'break-word',
          },
        })
      })
      .catch((err: IObject) => {
        toggleModal(false)
        A10Notification.error({
          message: 'Error!',
          description: `Fail to delete IPv4 RIB static route ${dest}. ${_.get(
            err,
            ['response', 'data', 'message', 'logs', 0],
            '',
          )}`,
          style: {
            wordWrap: 'break-word',
          },
        })
      })
  }, [props.deviceObj.name, dest, mask, forceDelete])

  const handleForceDeleteChange = (e: any) => {
    setForceDelete(e.target.checked)
  }
  const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/device/${props.deviceObj.name}/partition/${selectedPartition}`

  return (
    <div className="col-md-12 marginBottom10">
      <A10Modal
        visible={showModal}
        title="Confirmation"
        onOk={handleOk}
        onCancel={toggleModal.bind(this, false)}
        footer={[
          <A10Button
            key="no"
            type="primary"
            onClick={toggleModal.bind(this, false)}
            className="nobtn"
          >
            No
          </A10Button>,
          <A10Button
            key="yes"
            type="default"
            onClick={handleOk}
            className="yesbtn"
          >
            Yes
          </A10Button>,
        ]}
      >
        <p>Are you sure you want to delete the selected item?</p>
        <A10Checkbox onChange={handleForceDeleteChange} checked={forceDelete}>
          Force Delete
        </A10Checkbox>
      </A10Modal>
      <A10SlidingPage
        isOpen={showSlidingPage}
        modalSize="large"
        onRequestClose={toggleSlidingPage.bind(this, false)}
      >
        <AutoFormV15
          hccEnv={true}
          params={{
            'ip-dest-addr': dest,
            'ip-mask': mask,
            provider: storage.get.PROVIDER,
          }}
          schemaPath="network/routes/ipv4-static-routes/ipv4-rib"
          apiPrefix={apiPrefix}
          enableSOP={true}
          interceptor={{
            onSubmitSuccess: handleSuccess,
            onCancel: toggleSlidingPage.bind(this, false),
          }}
          ENV_CONSTS={{
            IS_SHARED_PARTITION: selectedPartition.toLowerCase() === 'shared',
            PARTITION_TYPE:
              selectedPartition.toLowerCase() === 'shared' ? 'SHARED' : 'L3V',
          }}
        />
      </A10SlidingPage>
      <A10Collapse>
        <A10Collapse.Panel
          key={0}
          header={
            <>
              {!storage.get.IS_OPERATOR_USER && (
                <span className="edit-content" onClick={handleAdd}>
                  <A10Icon app="global" type="add-new" /> Add
                </span>
              )}
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 fontWeight300 width260">
                  Static Routes RIB-IPv4 <RoundNumber number={ribTotal} />
                </span>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Dest Address</div>
                  <div className="pad-s-15 fontWeight300">{destAddrs}</div>
                </div>
              </A10Row>
            </>
          }
          className="collapsePanel"
        >
          <A10Table
            columns={columns}
            dataSource={(ribList || []).slice(start, start + 10)}
            size="small"
            pagination={{
              hideOnSinglePage: true,
              pageSize: 10,
              total: ribTotal,
              onChange: handlePageChange,
            }}
            bordered={false}
            loading={
              isLoading
                ? {
                    indicator: (
                      <div className="loading-icon">
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
          />
        </A10Collapse.Panel>
      </A10Collapse>
    </div>
  )
}

export default NetworkStaticRoutesRIBIPv4
