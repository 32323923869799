import { getItem } from '@gui-libraries/widgets/dist/a10/SlidingPage/SlidingPage'
import React from 'react'
import * as _ from 'lodash'
import { requestRouter, requestRouterBFD } from '../utils/router'
import { RoutingBFD, RoutingBGP, RoutingOSPF } from './Contents'

export interface IRoutingProtocolContentProps {
  deviceObj: IObject
  service: IObject
}

const RoutingProtocolContent: React.FC<IRoutingProtocolContentProps> = props => {
  const { deviceObj, service } = props
  const [urlNames] = React.useState([
    getItem('PROVIDER'),
    deviceObj.name,
    'shared',
  ])
  const [configured, setConfigured] = React.useState({})
  const [bgpConfig, setBgpConfig] = React.useState([])
  const [ospfConfig, setOspfConfig] = React.useState([])
  const [bfdConfig, setBfdConfig] = React.useState({})

  React.useEffect(() => {
    requestRouter(service, urlNames).then(router => {
      console.log(router)
      setConfigured({
        bgp: !!_.get(router, 'bgp-list'),
        ospf: !!_.get(router, 'ospf-list'),
        isis: !!_.get(router, 'isis-list'),
      })
      setBgpConfig(_.get(router, 'bgp-list', []))
      setOspfConfig(_.get(router, 'ospf-list', []))
    })

    requestRouterBFD(service, urlNames).then(router => {
      setBfdConfig(router)
    })
  }, [urlNames, service])
  return (
    <div className="device-detail-container device-content">
      <RoutingBFD bfd={bfdConfig} configured={configured} />
      <RoutingOSPF config={ospfConfig} />
      <RoutingBGP config={bgpConfig} />
    </div>
  )
}

export default RoutingProtocolContent
