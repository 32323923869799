import React from 'react'
import { A10Component } from '@gui-libraries/framework'
import { A10Tooltip } from '@gui-libraries/widgets'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

export interface IStatusBarProps {
  text?: string
  type?: string
  color?: string
  isRect?: boolean
  isBig?: boolean
  tip?: React.ReactNode
  borderRadius?: string
  clickable?: boolean
  onClick?: any
}

class StatusBar extends A10Component<IStatusBarProps> {
  static defaultProps = {
    clickable: false,
  }
  renderRectangle = (
    text: string,
    backgroundColor: string,
    padding: string,
  ) => {
    const { isBig = false, borderRadius = '4px', clickable } = this.props
    const cls = isBig ? styles.avatarRectangleBig : styles.avatarRectangleSmall
    return (
      <span
        className={`${styles.avatar} ${cls}`}
        style={{
          backgroundColor,
          padding,
          borderRadius,
          border: 'solid 1px #cdcdcd',
          cursor: clickable ? 'pointer' : 'context-menu',
        }}
        onClick={this.props.onClick}
      >
        {text}
      </span>
    )
  }

  render() {
    const {
      type,
      text = '',
      color,
      isRect = false,
      tip,
      borderRadius = '10px',
    } = this.props
    let backgroundColor = ''
    let padding = ''

    if (color) {
      backgroundColor = color
    }

    if (text.length > 2) {
      padding = '0 6px'
    }

    switch (type) {
      case 'up':
        backgroundColor = '#81c784' // '#7ed321'
        break
      case 'down':
        backgroundColor = '#e57373' // '#ff2e48'
        break
      case 'partial':
        backgroundColor = '#f5a623' // '#a6a6a6'
        break
      case 'disable':
        backgroundColor = '#a6a6a6' // '#f1f8ff'
        break
      case 'none':
        backgroundColor = '#64b5f6' // '#4d9fff'
        break
      case 'unknow':
        backgroundColor = '#ffba47'
        break
    }

    let content

    if (isRect) {
      content = this.renderRectangle(text, backgroundColor, padding)
    } else {
      content = (
        <span
          className={styles.avatar}
          style={{
            backgroundColor,
            padding,
            borderRadius,
            border: 'solid 1px #cdcdcd',
          }}
        >
          {text}
        </span>
      )
    }
    if (tip) {
      return (
        <A10Tooltip overlay={tip} arrowPointAtCenter={true}>
          {content}
        </A10Tooltip>
      )
    }
    return content
  }
}

export default StatusBar
