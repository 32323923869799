export const NS: IObject = {
  LEFT_COLLAPSED: ['leftCollapsed'],
  TENANT: ['tenant'],
  CURRENT_LOGICAL_PARTITION: ['tenant', 'currentLogicalPartition'],
  TENANT_LIST: ['tenant', 'tenantList'],
  ROLE_CLASS_LIST: ['roleClassList'],
  ROLE_CLASS_LIST_COUNT: ['roleClassCount'],
  VIP_COUNT: ['tenant', 'logicalPartition', 'vipCount'],
  VIP_LIST: ['tenant', 'logicalPartition', 'vipList'],
  APP_SERVICE_GROUP_LIST: ['tenant', 'appServiceGroupList'],
  SERVICE_GROUP_COUNT: ['tenant', 'logicalPartition', 'serviceGroupCount'],
  SERVICE_GROUP_LIST: ['tenant', 'logicalPartition', 'globalServiceGroupList'],
  SERVER_COUNT: ['tenant', 'logicalPartition', 'serverCount'],
  GLOBAL_SERVER_LIST: ['tenant', 'logicalPartition', 'globalServerList'],
  GLOBAL_VPORT_LIST: ['tenant', 'logicalPartition', 'globalVPortList'],
  CLUSTER_LIST: ['tenant', 'clusterList'],
  LOGICAL_PARTITION_LIST: ['tenant', 'logicalPartitionList'],
  LOGICAL_PARTITION_COUNT: ['tenant', 'logicalPartitionCount'],
  PHYSICAL_CLUSTER_LIST: ['tenant', '${clusterName}', 'clusterList'],
  VPORT_LIST: ['tenant', 'logicalPartition', 'vip', '${vipName}', 'portList'],
  SERVER_LIST: ['tenant', 'vip', '${sgName}', 'server'],
  APP_SERVICE_LIST: [
    'tenant',
    'appservicegroup',
    '${asgName}',
    'appServiceList',
  ],
  LC_APP_SERVICE_LIST: [
    'tenant',
    'logicalcluster',
    '${logicalCluster}',
    'appServiceList',
  ],
  SHARED_OBJECT_LIST: ['tenant', 'logicalPartition', 'serviceObjectList'],
  DEPLOYED_OBJECT_LIST: [
    'tenant',
    'sharedObjectType',
    '${type}',
    '${name}',
    'objectList',
  ],
  ASSOCIATED_SERVICE_GROUP: [
    'tenant',
    'logicalPartition',
    '${portId}',
    'serviceGroup',
  ],
  AFLEX_LIST: ['tenant', 'logicalPartition', 'aflexList'],
  AFLEX_DETAIL: ['tenant', 'logicalPartition', 'aflexDetail', '${name}'],
  CERT_LIST: ['tenant', 'logicalPartition', 'certList'],
  CRL_LIST: ['tenant', 'logicalPartition', 'crlList'],
  HEALTH_MONITOR_LIST: ['tenant', 'logicalPartition', 'healthMonitorList'],
  IPNAT_LIST: ['tenant', 'logicalPartition', 'ipNatList'],
  VRID_LIST: ['tenant', 'logicalPartition', 'vridList'],
  DIRTY_LIST: ['tenant', 'dirtyList', '${type}', '${name}'],
  HEALTH_STATUS: ['operState', '${uuid}'],
  HEALTH_SERIES: ['health', '${uuid}'],
  DEVICE_LIST: ['deviceList', '${clusterName}'],
  DEVICE_CLUSTER_MAP: ['deviceMap'],
  ACCESS_GROUP_USER_MAP: ['accessGroupUserMap'],
  BACKUP_COUNT: ['backupCount'],
  BACKUP_LIST: ['backupList'],
  PARTITION_CONFIG_LIST: ['partitionConfigList'],
  IMAGE_LIST: ['imageList'],
  ROLE_LIST: ['roleList'],
  WORKFLOW_LIST: ['workflowList'],
  LICENSE_LIST: ['licenseList'],
  LICENSE_DETAILS: ['licenseDetails'],
  ACCESS_LIST_LIST: ['accessList'],
  ACCESS_LIST_COUNT: ['accessListCount'],
  OBJECT_GROUP_LIST: ['objectGroupList'],
  OBJECT_GROUP_COUNT: ['objectGroupListCount'],
  BLACK_WHITE_LIST: ['blackWhiteList'],
  CLASS_LIST: ['classList'],
  CLASS_LIST_COUNT: ['classListCount'],
  BLACK_WHITE_COUNT: ['blackWhiteListCount'],
  IP_LIST_LIST: ['ipList'],
  IP_LIST_COUNT: ['ipListCount'],
  DOMAIN_LIST_LIST: ['domainList'],
  DOMAIN_LIST_COUNT: ['domainListCount'],
  GEO_LIST_LIST: ['geoList'],
  GEO_LIST_COUNT: ['geoListCount'],
}

export default NS
