import React from 'react'
import {
  A10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Message, A10Notification } from '@gui-libraries/widgets'
import { showSOPMessage } from '@gui-libraries/apps'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import CertForm, { IFormData } from './index'
import storage from 'src/libraries/storage'
import globalConfig from 'src/settings/config'

export interface ISlidingCertProps extends IA10ContainerDefaultProps {
  isOpen: boolean
  onRequestClose: () => void
  tenant: string
  logicalPartition: string
}

export interface ISlidingCertStates {
  provider: string
  formData?: IFormData
}

class SlidingCert extends A10Container<ISlidingCertProps, ISlidingCertStates> {
  childForm: any = null
  constructor(props: ISlidingCertProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER,
    }
  }

  onFormChange(data: IFormData) {
    const state = { ...this.state }
    state.formData = data
    this.setState(state)
  }

  saveCertSlidingPage = async () => {
    let isValid = true
    this.childForm.props.form.validateFieldsAndScroll((errors, values) => {
      if (errors) {
        isValid = false
      }
    })
    if (!isValid) {
      return
    }
    const {
      EPIC_DEPENDENCIES: { httpClient },
    } = globalConfig
    const { provider } = this.state
    const { tenant, logicalPartition } = this.props
    const newFile = this.state.formData.file
    const importType = this.state.formData.importType
    const certType = this.state.formData.certType
    if (!this.state.formData.name) {
      A10Message.error('Certificate name is required')
      return
    }
    if (!this.state.formData.file.name) {
      A10Message.error('Certificate File is required')
      return
    }
    let url = ''
    let certKey = ''
    switch (importType) {
      case 'certificate':
        if (certType === 'ssl') {
          certKey = 'ssl-cert'
          url = `/hpcapi/v3/provider/${provider}/tenant/${tenant}/logical-partition/${logicalPartition}/file/ssl-cert`
        } else {
          certKey = 'ssl-ca'
          url = `/hpcapi/v3/provider/${provider}/tenant/${tenant}/logical-partition/${logicalPartition}/file/ssl-ca`
        }
        break
      case 'key':
        certKey = 'ssl-key'
        url = `/hpcapi/v3/provider/${provider}/tenant/${tenant}/logical-partition/${logicalPartition}/file/ssl-key`
        break
      default:
    }
    const payload = {}
    payload[certKey] = {
      name: this.state.formData.name,
      description: this.state.formData.description,
      'file-handle': this.state.formData.file.name,
    }
    const formData = new FormData()
    formData.append('json', JSON.stringify(payload))
    formData.append('file', newFile, this.state.formData.file.name)
    try {
      const response = await httpClient.post(url, formData)
      const successMsg = `Certificate ${this.state.formData.name} has been imported successfully.`
      showSOPMessage(response, successMsg)
      this.props.onRequestClose()
    } catch (err) {
      console.error(err)
      A10Notification.error({
        message: 'Error!',
        description: err?.response?.data?.message,
      })
    }
  }

  render() {
    const { isOpen, onRequestClose } = this.props
    const onFormChange = this.onFormChange.bind(this)
    const title = 'Import Certificate'

    return (
      <FormatSlidingPage
        isOpen={isOpen}
        onRequestOk={this.saveCertSlidingPage}
        onRequestClose={onRequestClose}
        title={title}
        description="Please provide the detail of the Cert"
      >
        <CertForm
          onChange={onFormChange}
          onRef={(ref: any): any => (this.childForm = ref)}
        />
      </FormatSlidingPage>
    )
  }
}

export default SlidingCert
