import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import { A10Row, A10Radio, A10Tabs } from '@gui-libraries/widgets'

import { ContentSection } from 'src/components/shared/ContentSection'
import DeviceImages from './DeviceImages'
import ScheduledUpgrades from './ScheduledUpgrades'
import ExecutedUpgrades from './ExecutedUpgrades'

// tslint:disable-next-line:no-var-requires
const styles = require('./style/index.module.less')

const { TabPane } = A10Tabs

export interface IImageProps extends IA10ContainerDefaultProps {}

export interface IImageState {
  tabValue: string
}

class Image extends A10Container<IImageProps, IImageState> {
  constructor(props: IImageProps) {
    super(props)
    this.state = {
      tabValue: 'deviceImages',
    }
  }
  dataLoaded = false

  tabChange = (event: any) => {
    let { tabValue } = this.state
    if (event.target) {
      tabValue = event.target.value
    } else {
      tabValue = event
    }

    this.setState({ tabValue })
  }

  render() {
    const { tabValue } = this.state
    return (
      <>
        <ContentSection>
          <A10Row className={styles.radioGroupContainer}>
            <A10Tabs
              onChange={this.tabChange}
              type="card"
              className="image-upgrade-tab"
            >
              <TabPane key={'deviceImages'} tab={'Images'} />
              <TabPane key={'scheduledUpgrades'} tab={'Scheduled Upgrades'} />
              <TabPane key={'executedUpgrades'} tab={'Executed Upgrades'} />
            </A10Tabs>
          </A10Row>
          {tabValue === 'deviceImages' ? (
            <DeviceImages />
          ) : tabValue === 'scheduledUpgrades' ? (
            <ScheduledUpgrades />
          ) : tabValue === 'executedUpgrades' ? (
            <ExecutedUpgrades />
          ) : null}
        </ContentSection>
      </>
    )
  }
}

export default setupA10Container(Image)
