import React, { useState, useEffect, useCallback } from 'react'
import ReactLoading from 'react-loading'
import { _ } from '@gui-libraries/framework'
import {
  A10Collapse,
  A10Icon,
  A10Row,
  A10Table,
  A10DropdownMenu,
  A10SlidingPage,
  A10Modal,
  A10Button,
  A10Notification,
  A10Checkbox,
} from '@gui-libraries/widgets'
import { AutoFormV15 } from '@gui-libraries/apps'

import RoundNumber from 'src/components/shared/RoundNumber'
import { InfrastructureService } from 'src/services/index'
import storage from 'src/libraries/storage'
// import TrustedKey from 'src/containers/Launchers/ADC/ProviderSharedObjects/System/NTP/TrustedKey'

const NetworkVlan: React.FC = (props: IObject) => {
  const [vlanList, setVlanList] = useState([] as IObject[])
  const [start, setStart] = useState(0)
  const [vlanNum, setVlanNum] = useState(null as number)
  const [showSlidingPage, setShowSlidingPage] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const infrastructureService = new InfrastructureService()
  const [isLoading, setIsLoading] = useState(false)
  const [taggedEthNumber, setTaggedEthNumber] = useState(0)
  const [untaggedEthNumber, setUntaggedEthNumber] = useState(0)
  const [taggedTrunkNumber, setTaggedTrunkNumber] = useState(0)
  const [untaggedTrunkNumber, setUntaggedTrunkNumber] = useState(0)
  const [forceDelete, setForceDelete] = useState(false)
  const [vlanTotal, setVlanTotal] = useState(0)
  const { selectedPartition = 'shared' } = props

  // const getData = () => {
  //   setIsLoading(true)
  //   const provider = storage.get.PROVIDER')
  //   infrastructureService.getRequest(null, null, [
  //     provider,
  //     props.deviceObj.name,
  //     'shared',
  //     `?start=${start}&count=10`
  //   ],
  //     'GET_DEVICE_VLANS')
  //     .then((res: IObject) => {
  //       try {
  //         const vlans = res.data['vlan-list']
  //         setVlanList(vlans)
  //         setIsLoading(false)
  //       } catch (e) {
  //         console.log(e)
  //         setIsLoading(false)
  //       }
  //     })
  // }

  const getData = () => {
    setIsLoading(true)
    const provider = storage.get.PROVIDER
    infrastructureService
      .getRequest(
        null,
        null,
        [provider, props.deviceObj.name, selectedPartition, ''],
        'GET_DEVICE_VLANS',
      )
      .then((res: IObject) => {
        try {
          const vlans = res.data['vlan-list']
          setVlanList(vlans)
          setIsLoading(false)
        } catch (e) {
          console.log(e)
          setIsLoading(false)
        }
      })
  }

  const updateTotal = () => {
    const { deviceObj, service } = props
    const provider = deviceObj['provider-name']
    const deviceName = deviceObj['name']

    Promise.all([
      service.getRequest(
        null,
        null,
        [provider, deviceName, selectedPartition, '?total=true'],
        'GET_DEVICE_VLANS',
      ),
    ])
      .then((response: IObject[]) => {
        try {
          setVlanTotal(_.get(response[0], 'data.total-count', 0))
        } catch (e) {
          console.log(e)
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  useEffect(() => {
    const taggedEths = [] as number[]
    const untaggedEths = [] as number[]
    const taggedTrunks = [] as number[]
    const untaggedTrunks = [] as number[]

    if (vlanList?.length) {
      vlanList.forEach((vlan: IObject) => {
        const taggedEthlist = _.get(vlan, 'tagged-eth-list', []) || []
        const untaggedEthList = _.get(vlan, 'untagged-eth-list', []) || []
        const taggedTrunkList = _.get(vlan, 'tagged-trunk-list', []) || []
        const untaggedTrunkList = _.get(vlan, 'untagged-trunk-list', []) || []
        taggedEthlist.forEach((eth: IObject) => {
          const {
            'tagged-ethernet-start': ethStart,
            'tagged-ethernet-end': ethEnd,
          } = eth
          for (let i = ethStart; i <= ethEnd; i++) {
            taggedEths.push(i)
          }
        })
        untaggedEthList.forEach((eth: IObject) => {
          const {
            'untagged-ethernet-start': ethStart,
            'untagged-ethernet-end': ethEnd,
          } = eth
          for (let i = ethStart; i <= ethEnd; i++) {
            untaggedEths.push(i)
          }
        })
        taggedTrunkList.forEach((trunk: IObject) => {
          const {
            'tagged-trunk-start': trunkStart,
            'tagged-trunk-end': trunkEnd,
          } = trunk
          for (let i = trunkStart; i <= trunkEnd; i++) {
            taggedTrunks.push(i)
          }
        })
        untaggedTrunkList.forEach((trunk: IObject) => {
          const {
            'untagged-trunk-start': trunkStart,
            'untagged-trunk-end': trunkEnd,
          } = trunk
          for (let i = trunkStart; i <= trunkEnd; i++) {
            untaggedTrunks.push(i)
          }
        })
      })
    }
    setTaggedEthNumber(taggedEths.length)
    setUntaggedEthNumber(untaggedEths.length)
    setTaggedTrunkNumber(taggedTrunks.length)
    setUntaggedTrunkNumber(untaggedTrunks.length)
  }, [vlanList])

  useEffect(() => {
    getData()
    updateTotal()
  }, [start, props.deviceObj.name, props.refreshTimer, selectedPartition])

  const renderEthList = (record: IObject) => {
    const untaggedList = _.get(record, 'untagged-eth-list', []) || []
    const taggedList = _.get(record, 'tagged-eth-list', []) || []
    let untaggedText = ''
    let taggedText = ''

    untaggedList.forEach((eth: IObject) => {
      if (eth['untagged-ethernet-start'] === eth['untagged-ethernet-end']) {
        untaggedText =
          untaggedText + `Ethernet ${eth['untagged-ethernet-start']},`
      } else {
        untaggedText =
          untaggedText +
          `${eth['untagged-ethernet-start']} to ${eth['untagged-ethernet-end']},`
      }
    })
    if (!!untaggedText) {
      untaggedText = _.trimEnd(untaggedText, ',')
      untaggedText += ' /Untagged  '
    }
    taggedList.forEach((eth: IObject) => {
      if (eth['tagged-ethernet-start'] === eth['tagged-ethernet-end']) {
        taggedText = taggedText + `Ethernet ${eth['tagged-ethernet-start']},`
      } else {
        taggedText =
          taggedText +
          `Ethernet ${eth['tagged-ethernet-start']} to ${eth['tagged-ethernet-end']},`
      }
    })
    if (taggedList.length > 0) {
      taggedText = _.trimEnd(taggedText, ',')
      taggedText += ' /Tagged'
    }
    return (
      <>
        {untaggedText} {untaggedText && taggedText ? <br /> : null}
        {taggedText}
      </>
    )
  }

  const renderTrunkList = (record: IObject) => {
    const untaggedList = _.get(record, 'untagged-trunk-list', []) || []
    const taggedList = _.get(record, 'tagged-trunk-list', []) || []
    let untaggedText = ''
    let taggedText = ''
    untaggedList.forEach((eth: IObject) => {
      if (eth['untagged-trunk-start'] === eth['untagged-trunk-end']) {
        untaggedText = untaggedText + `Trunk ${eth['untagged-trunk-start']},`
      } else {
        untaggedText =
          untaggedText +
          `Trunk ${eth['untagged-trunk-start']} to ${eth['untagged-trunk-end']},`
      }
    })
    if (!!untaggedText) {
      untaggedText = _.trimEnd(untaggedText, ',')
      untaggedText += ' /Untagged  '
    }
    taggedList.forEach((eth: IObject) => {
      if (eth['tagged-trunk-start'] === eth['tagged-trunk-end']) {
        taggedText = taggedText + `Trunk ${eth['tagged-trunk-start']},`
      } else {
        taggedText =
          taggedText +
          `${eth['tagged-trunk-start']} to ${eth['tagged-trunk-end']},`
      }
    })
    if (taggedList.length > 0) {
      taggedText = _.trimEnd(taggedText, ',')
      taggedText += ' /Tagged'
    }
    return (
      <>
        {untaggedText} {untaggedText && taggedText ? <br /> : null}
        {taggedText}
      </>
    )
  }

  const handleEdit = (num: number) => {
    setVlanNum(num)
    setShowSlidingPage(true)
  }

  const handleDelete = (num: number) => {
    setForceDelete(false)
    setVlanNum(num)
    toggleModal(true)
  }

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a: IObject, b: IObject) => {
          return a.name > b.name ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'VLAN',
        dataIndex: 'vlan-num',
        sorter: (a: IObject, b: IObject) => {
          return a['vlan-num'] > b['vlan-num'] ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'VE',
        dataIndex: 've',
        sorter: (a: IObject, b: IObject) => {
          return a.ve > b.ve ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Ethernet List Untagged / Tagged',
        dataIndex: 'ethernetList',
        sorter: (a: IObject, b: IObject) => {
          return a.ethernetList > b.ethernetList ? 1 : -1
        },
        render: (text: any, record: any) => {
          return renderEthList(record)
        },
      },
      {
        title: 'Trunk List Untagged / Tagged',
        dataIndex: 'trunkList',
        sorter: (a: IObject, b: IObject) => {
          return a.trunkList > b.trunkList ? 1 : -1
        },
        render: (text: any, record: any) => {
          return renderTrunkList(record)
        },
      },
      {
        dataIndex: 'actions',
        width: 30,
        render: (text: any, record: any) => {
          return (
            !storage.get.IS_OPERATOR_USER && (
              <A10DropdownMenu
                title=""
                menu={[
                  // <div key="enable">Set MAC</div>,
                  <div
                    key="edit"
                    onClick={handleEdit.bind(this, record['vlan-num'])}
                  >
                    Edit
                  </div>,
                  <div
                    key="delete"
                    onClick={handleDelete.bind(this, record['vlan-num'])}
                  >
                    Delete
                  </div>,
                ]}
                style={{ color: '#757575', marginBottom: '-15px' }}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            )
          )
        },
      },
    ],
    [],
  )

  const toggleSlidingPage = (isOpen: boolean) => {
    setShowSlidingPage(isOpen)
  }

  const handleAdd = (event: React.MouseEvent<HTMLElement>) => {
    toggleSlidingPage(true)
    setVlanNum(null)
    event.stopPropagation()
  }

  const handleSuccess = useCallback(() => {
    if (!vlanNum) {
      updateTotal()
    } else {
      getData()
    }
    toggleSlidingPage(false)
  }, [props.deviceObj.name, vlanNum])

  const handlePageChange = (page: number, pageSize: number) => {
    setStart((page - 1) * pageSize)
  }

  const toggleModal = (isOpen: boolean) => {
    setShowModal(isOpen)
  }

  const handleOk = useCallback(() => {
    const provider = storage.get.PROVIDER
    infrastructureService
      .getRequest(
        null,
        null,
        [
          provider,
          props.deviceObj.name,
          selectedPartition,
          vlanNum,
          forceDelete ? '&force=true' : '',
        ],
        'DELETE_DEVICE_VLAN',
      )
      .then((res: IObject) => {
        updateTotal()
        toggleModal(false)
        A10Notification.success({
          message: 'Success!',
          description: `Delete VLAN ${vlanNum} successfully.`,
          style: {
            wordWrap: 'break-word',
          },
        })
      })
      .catch((err: any) => {
        toggleModal(false)
        A10Notification.error({
          message: 'Error!',
          description: `Fail to delete VLAN ${vlanNum}. ${_.get(
            err,
            ['response', 'data', 'message', 'logs', 0],
            '',
          )}`,
          style: {
            wordWrap: 'break-word',
          },
        })
      })
  }, [props.deviceObj.name, vlanNum, forceDelete])

  const handleForceDeleteChange = (e: any) => {
    setForceDelete(e.target.checked)
  }
  const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/device/${props.deviceObj.name}/partition/${selectedPartition}`
  return (
    <div className="col-md-12 marginBottom10">
      <A10Modal
        visible={showModal}
        title="Confirmation"
        onOk={handleOk}
        onCancel={toggleModal.bind(this, false)}
        footer={[
          <A10Button
            key="no"
            type="primary"
            onClick={toggleModal.bind(this, false)}
            className="nobtn"
          >
            No
          </A10Button>,
          <A10Button
            key="yes"
            type="default"
            onClick={handleOk}
            className="yesbtn"
          >
            Yes
          </A10Button>,
        ]}
      >
        <p>Are you sure you want to delete the selected item?</p>
        <A10Checkbox onChange={handleForceDeleteChange} checked={forceDelete}>
          Force Delete
        </A10Checkbox>
      </A10Modal>
      <A10SlidingPage
        isOpen={showSlidingPage}
        modalSize="large"
        onRequestClose={toggleSlidingPage.bind(this, false)}
      >
        <AutoFormV15
          hccEnv={true}
          params={{ 'vlan-num': vlanNum }}
          schemaPath="network/vlan/vlan"
          apiPrefix={apiPrefix}
          enableSOP={true}
          interceptor={{
            onSubmitSuccess: handleSuccess,
            onCancel: toggleSlidingPage.bind(this, false),
          }}
          ENV_CONSTS={{
            IS_SHARED_PARTITION: selectedPartition.toLowerCase() === 'shared',
            PARTITION_TYPE:
              selectedPartition.toLowerCase() === 'shared' ? 'SHARED' : 'L3V',
          }}
        />
      </A10SlidingPage>
      <A10Collapse>
        <A10Collapse.Panel
          key={0}
          header={
            <>
              {!storage.get.IS_OPERATOR_USER && (
                <span className="edit-content" onClick={handleAdd}>
                  <A10Icon app="global" type="add-new" /> Add
                </span>
              )}
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 fontWeight300 width260">
                  VLAN <RoundNumber number={vlanTotal} />
                </span>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Tagged Ethernet</div>
                  <div className="pad-s-15 fontWeight300">
                    {taggedEthNumber}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Untagged Ethernet</div>
                  <div className="pad-s-15 fontWeight300">
                    {untaggedEthNumber}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Tagged Trunk</div>
                  <div className="pad-s-15 fontWeight300">
                    {taggedTrunkNumber}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Untagged Trunk</div>
                  <div className="pad-s-15 fontWeight300">
                    {untaggedTrunkNumber}
                  </div>
                </div>
              </A10Row>
            </>
          }
          className="collapsePanel"
        >
          <A10Table
            columns={columns}
            dataSource={(vlanList || []).slice(start, start + 10)}
            size="small"
            pagination={{
              total: vlanTotal,
              onChange: handlePageChange,
              hideOnSinglePage: true,
              pageSize: 10,
            }}
            bordered={false}
            loading={
              isLoading
                ? {
                    indicator: (
                      <div className="loading-icon">
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
          />
        </A10Collapse.Panel>
      </A10Collapse>
    </div>
  )
}

export default NetworkVlan
