import React, { useState, useEffect, useCallback } from 'react'
import ReactLoading from 'react-loading'
import { _ } from '@gui-libraries/framework'
import {
  A10Collapse,
  A10Icon,
  A10Row,
  A10Table,
  A10DropdownMenu,
  A10SlidingPage,
  A10Modal,
  A10Button,
  A10Notification,
  A10Checkbox,
} from '@gui-libraries/widgets'
import { AutoFormV15 } from '@gui-libraries/apps'

import RoundNumber from 'src/components/shared/RoundNumber'
import { InfrastructureService } from 'src/services/index'
import storage from 'src/libraries/storage'

const NetworkStaticRoutesBFDIPv6: React.FC = (props: IObject) => {
  const infrastructureService = new InfrastructureService()
  const [showSlidingPage, setShowSlidingPage] = useState(false)
  const [localIp, setLocalIp] = useState('')
  const [nextHopIp, setNextHopIp] = useState('')
  const [bfdList, setBfdList] = useState([] as IObject[])
  const [start, setStart] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [bfdNumber, setBFDNumber] = useState(null as number)
  const [bfdType, setBFDType] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [destAddresss, setDestAddresss] = useState(0)
  const [forceDelete, setForceDelete] = useState(false)
  const [bfdTotal, setBfdTotal] = useState(0)

  // const getData = async () => {
  //   setIsLoading(true)
  //   let curStart = start
  //   let count = 10
  //   const objs = [
  //     ['GET_DEVICE_BFD_IPV6', 'bfd-ipv6-list'],
  //     ['GET_DEVICE_BFD_ETHERNET', 'ethernet-list'],
  //     ['GET_DEVICE_BFD_TRUNK', 'trunk-list'],
  //     ['GET_DEVICE_BFD_VE', 've-list']
  //   ]
  //   const { ipv6BFDTotal = 0, ethernetBFDTotal = 0, trunkBFDTotal = 0, veBFDTotal = 0 } = props
  //   const totals = [ipv6BFDTotal, ethernetBFDTotal, trunkBFDTotal, veBFDTotal]
  //   const provider = storage.get.PROVIDER')
  //   let bfds = [] as IObject[]
  //   for (let i = 0; i < objs.length; i++) {
  //     const curTotal = totals[i]
  //     if (count === 0) {
  //       break
  //     }
  //     if (curStart < curTotal) {
  //       if ((curStart + count) < curTotal) {
  //         const res = await infrastructureService.getRequest(null, null, [
  //           provider,
  //           props.deviceObj.name,
  //           'shared',
  //           `?start=${curStart}&count=${count}`
  //         ],
  //           objs[i][0])
  //         try {
  //           bfds = bfds.concat(res.data[objs[i][1]])
  //         } catch (e) {
  //           console.log(e)
  //         }
  //       } else {
  //         const res = await infrastructureService.getRequest(null, null, [
  //           provider,
  //           props.deviceObj.name,
  //           'shared',
  //           `?start=${curStart}&count=${curTotal - curStart}`
  //         ],
  //           objs[i][0])
  //         try {
  //           bfds = bfds.concat(res.data[objs[i][1]])
  //         } catch (e) {
  //           console.log(e)
  //         }
  //         count = (curStart + count) - curTotal
  //       }
  //       curStart = 0
  //     } else {
  //       curStart = curStart - curTotal
  //     }
  //   }
  //   setIsLoading(false)
  //   setBfdList(bfds)
  // }

  const { deviceObj, refreshTimer, selectedPartition = 'shared' } = props

  const updateTotal = () => {
    const { deviceObj, service } = props
    const provider = deviceObj['provider-name']
    const deviceName = deviceObj['name']

    Promise.all([
      service.getRequest(
        null,
        null,
        [provider, deviceName, selectedPartition, '?total=true'],
        'GET_DEVICE_BFD_IPV6',
      ),
      service.getRequest(
        null,
        null,
        [provider, deviceName, selectedPartition, '?total=true'],
        'GET_DEVICE_BFD_ETHERNET',
      ),
      service.getRequest(
        null,
        null,
        [provider, deviceName, selectedPartition, '?total=true'],
        'GET_DEVICE_BFD_TRUNK',
      ),
      service.getRequest(
        null,
        null,
        [provider, deviceName, selectedPartition, '?total=true'],
        'GET_DEVICE_BFD_VE',
      ),
    ])
      .then((response: IObject[]) => {
        try {
          const ipv6BFDTotal = _.get(response[0], 'data.total-count', 0)
          const ethernetBFDTotal = _.get(response[1], 'data.total-count', 0)
          const trunkBFDTotal = _.get(response[2], 'data.total-count', 0)
          const veBFDTotal = _.get(response[3], 'data.total-count', 0)
          setBfdTotal(
            ipv6BFDTotal + ethernetBFDTotal + trunkBFDTotal + veBFDTotal,
          )
        } catch (e) {
          console.log(e)
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const getData = async () => {
    setIsLoading(true)
    const provider = storage.get.PROVIDER
    const urls = [
      'GET_DEVICE_BFD_IPV6',
      'GET_DEVICE_BFD_ETHERNET',
      'GET_DEVICE_BFD_TRUNK',
      'GET_DEVICE_BFD_VE',
    ]
    Promise.all(
      urls.map((url: string) =>
        infrastructureService.getRequest(
          null,
          null,
          [provider, props.deviceObj.name, selectedPartition, ''],
          url,
        ),
      ),
    )
      .then((responses: IObject) => {
        const bkeys = [
          'bfd-ipv6-list',
          'ethernet-list',
          'trunk-list',
          've-list',
        ]
        let bfds = [] as IObject[]
        try {
          responses.forEach((res: IObject, i: number) => {
            bfds = bfds.concat(_.get(res, `data.${bkeys[i]}`, []))
          })
          setBfdList(bfds)
          setDestAddresss(bfds.length)
        } catch (e) {
          console.log(e)
        } finally {
          setIsLoading(false)
        }
      })
      .catch((err: IObject) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    updateTotal()
    getData()
  }, [start, deviceObj.name, refreshTimer, selectedPartition])
  const columns = React.useMemo(
    () => [
      {
        title: 'Route Type',
        dataIndex: 'interface',
        sorter: (a: IObject, b: IObject) => {
          return a.interface > b.interface ? 1 : -1
        },
        render: (text: any, record: any) => {
          if ('eth-num' in record) {
            return 'Ethernet'
          } else if ('trunk-num' in record) {
            return 'Trunk'
          } else if ('ve-num' in record) {
            return 'Ve'
          } else {
            return 'IPv6'
          }
        },
      },
      {
        title: 'Local Address',
        dataIndex: 'local-ipv6',
        sorter: (a: IObject, b: IObject) => {
          return a['local-ipv6'] > b['local-ipv6'] ? 1 : -1
        },
        render: (text: any, record: any) => {
          if ('eth-num' in record) {
            return `Ethernet${record['eth-num']}`
          } else if ('trunk-num' in record) {
            return `Trunk${record['trunk-num']}`
          } else if ('ve-num' in record) {
            return `Ve${record['ve-num']}`
          } else if ('local-ipv6' in record) {
            return record['local-ipv6']
          }
          return text
        },
      },
      {
        title: 'Nexthop Address',
        dataIndex: 'nexthop-ipv6',
        sorter: (a: IObject, b: IObject) => {
          return a['nexthop-ipv6'] > b['nexthop-ipv6'] ? 1 : -1
        },
        render: (text: any, record: any) => {
          if ('nexthop-ipv6' in record) {
            return record['nexthop-ipv6']
          } else if ('nexthop-ipv6-ll' in record) {
            return record['nexthop-ipv6-ll']
          }
          return text
        },
      },

      {
        dataIndex: 'actions',
        width: 30,
        render: (text: any, record: any) => {
          return (
            !storage.get.IS_OPERATOR_USER && (
              <A10DropdownMenu
                title=""
                menu={[
                  <div key="delete" onClick={handleDelete.bind(this, record)}>
                    Delete
                  </div>,
                ]}
                style={{ color: '#757575', marginBottom: '-15px' }}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            )
          )
        },
      },
    ],
    [],
  )

  const toggleSlidingPage = (isOpen: boolean) => {
    setShowSlidingPage(isOpen)
  }

  const handleAdd = (event: React.MouseEvent<HTMLElement>) => {
    toggleSlidingPage(true)
    setLocalIp('')
    setNextHopIp('')
    event.stopPropagation()
  }

  const handleDelete = (record: IObject) => {
    setForceDelete(false)
    if ('local-ipv6' in record) {
      setBFDType('ipv6')
      setLocalIp(record['local-ipv6'])
      setNextHopIp(record['nexthop-ipv6'])
    }
    if ('eth-num' in record) {
      setBFDType('ethernet')
      setBFDNumber(record['eth-num'])
      setNextHopIp(record['nexthop-ipv6-ll'])
    }
    if ('trunk-num' in record) {
      setBFDType('trunk')
      setBFDNumber(record['trunk-num'])
      setNextHopIp(record['nexthop-ipv6-ll'])
    }
    if ('ve-num' in record) {
      setBFDType('ve')
      setBFDNumber(record['ve-num'])
      setNextHopIp(record['nexthop-ipv6-ll'])
    }

    toggleModal(true)
  }

  const handleOk = () => {
    const urlObj = {
      ipv6: {
        url: 'DELETE_DEVICE_BFD_IPV6',
        params: encodeURIComponent(`${localIp}+${nextHopIp}`),
      },
      ethernet: {
        url: 'DELETE_DEVICE_BFD_ETHERNET',
        params: `${bfdNumber}${encodeURIComponent('+' + nextHopIp)}`,
      },
      trunk: {
        url: 'DELETE_DEVICE_BFD_TRUNK',
        params: `${bfdNumber}${encodeURIComponent('+' + nextHopIp)}`,
      },
      ve: {
        url: 'DELETE_DEVICE_BFD_VE',
        params: `${bfdNumber}${encodeURIComponent('+' + nextHopIp)}`,
      },
    }
    const provider = storage.get.PROVIDER
    infrastructureService
      .getRequest(
        null,
        null,
        [
          provider,
          props.deviceObj.name,
          selectedPartition,
          urlObj[bfdType].params,
          forceDelete ? '&force=true' : '',
        ],
        urlObj[bfdType].url,
      )
      .then((res: IObject) => {
        updateTotal()
        toggleModal(false)
        A10Notification.success({
          message: 'Success!',
          description: `Delete IPv6 BFD static route ${decodeURIComponent(
            urlObj[bfdType].params,
          )} successfully.`,
          style: {
            wordWrap: 'break-word',
          },
        })
      })
      .catch((err: IObject) => {
        toggleModal(false)
        A10Notification.error({
          message: 'Error!',
          description: `Fail to delete IPv6 BFD static route ${localIp}+${nextHopIp}.  ${_.get(
            err,
            ['response', 'data', 'message', 'logs', 0],
            '',
          )}`,
          style: {
            wordWrap: 'break-word',
          },
        })
      })
  }

  const handleSuccess = useCallback(() => {
    updateTotal()
    toggleSlidingPage(false)
  }, [props.deviceObj.name])

  const handlePageChange = (page: number, pageSize: number) => {
    setStart((page - 1) * pageSize)
  }

  const toggleModal = (isOpen: boolean) => {
    setShowModal(isOpen)
  }

  const handleForceDeleteChange = (e: any) => {
    setForceDelete(e.target.checked)
  }

  const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/device/${props.deviceObj.name}/partition/${selectedPartition}`

  return (
    <div className="col-md-12 marginBottom10">
      <A10Modal
        visible={showModal}
        title="Confirmation"
        onOk={handleOk}
        onCancel={toggleModal.bind(this, false)}
        footer={[
          <A10Button
            key="no"
            type="primary"
            onClick={toggleModal.bind(this, false)}
            className="nobtn"
          >
            No
          </A10Button>,
          <A10Button
            key="yes"
            type="default"
            onClick={handleOk}
            className="yesbtn"
          >
            Yes
          </A10Button>,
        ]}
      >
        <p>Are you sure you want to delete the selected item?</p>
        <A10Checkbox onChange={handleForceDeleteChange} checked={forceDelete}>
          Force Delete
        </A10Checkbox>
      </A10Modal>
      <A10SlidingPage
        isOpen={showSlidingPage}
        modalSize="large"
        onRequestClose={toggleSlidingPage.bind(this, false)}
      >
        <AutoFormV15
          hccEnv={true}
          schemaPath="network/routes/ipv6-static-routes/ipv6-bfd/all"
          apiPrefix={apiPrefix}
          params={{
            'local-ipv6': localIp,
            'nexthop-ipv6': nextHopIp,
          }}
          enableSOP={true}
          interceptor={{
            onSubmitSuccess: handleSuccess,
            onCancel: toggleSlidingPage.bind(this, false),
          }}
          ENV_CONSTS={{
            IS_SHARED_PARTITION: selectedPartition.toLowerCase() === 'shared',
            PARTITION_TYPE:
              selectedPartition.toLowerCase() === 'shared' ? 'SHARED' : 'L3V',
          }}
        />
      </A10SlidingPage>
      <A10Collapse>
        <A10Collapse.Panel
          key={0}
          header={
            <>
              {!storage.get.IS_OPERATOR_USER && (
                <span className="edit-content" onClick={handleAdd}>
                  <A10Icon app="global" type="add-new" /> Add
                </span>
              )}
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 fontWeight300 width260">
                  Static Routes BFD-IPv6 <RoundNumber number={bfdTotal} />
                </span>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Dest Address</div>
                  <div className="pad-s-15 fontWeight300">{destAddresss}</div>
                </div>
              </A10Row>
            </>
          }
          className="collapsePanel"
        >
          <A10Table
            columns={columns}
            dataSource={(bfdList || []).slice(start, start + 10)}
            size="small"
            pagination={{
              hideOnSinglePage: true,
              pageSize: 10,
              onChange: handlePageChange,
              total: bfdTotal,
            }}
            bordered={false}
            loading={
              isLoading
                ? {
                    indicator: (
                      <div className="loading-icon">
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
          />
        </A10Collapse.Panel>
      </A10Collapse>
    </div>
  )
}

export default NetworkStaticRoutesBFDIPv6
