import React from 'react'

import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import { A10Modal, A10Button } from '@gui-libraries/widgets'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { LMActivationForm } from './index'
import { Utilities, DashboardService } from 'src/services'

export interface ISlidingLMActivationFormProps
  extends IA10ContainerDefaultProps {
  isOpen: boolean
  slidingForm: string
  activationObj: any
  licType: string
  licToken: string
  onRequestClose: () => void
  updateList: () => void
}

export interface ISlidingLMActivationFormStates {
  revokeUnrevokeConfirm: boolean
}

class SlidingLMActivationForm extends A10Container<
  ISlidingLMActivationFormProps,
  ISlidingLMActivationFormStates
> {
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  childForm: any = null

  constructor(props: ISlidingLMActivationFormProps) {
    super(props)
    this.state = {
      revokeUnrevokeConfirm: false,
    }
  }

  onClose = () => {
    this.props.updateList()
    this.props.onRequestClose()
  }

  handleModalCancel = () => {
    this.setState({ revokeUnrevokeConfirm: false })
  }

  confirmRevokeUnrevokeConfirm = () => {
    const { activationObj, slidingForm, licToken } = this.props
    let revokeUnrevokeActivation = null
    if (slidingForm === 'Revoke') {
      revokeUnrevokeActivation = this.DashboardService.revokeLLMActivation(
        null,
        licToken,
        [activationObj.id],
      )
    } else {
      revokeUnrevokeActivation = this.DashboardService.unrevokeLLMActivation(
        null,
        licToken,
        [activationObj.id],
      )
    }
    revokeUnrevokeActivation
      .then((resp: any) => {
        if (resp) {
          this.Utilities.showMessage(
            slidingForm + ' activation request submitted successfully',
            1,
            0,
          )
          this.handleModalCancel()
          this.onClose()
        }
      })
      .catch((err: any) => {
        const msg =
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : ''
        this.Utilities.showMessage(
          'Error while ' + slidingForm.toLowerCase() + ' of activation',
          0,
          0,
          msg,
        )
        this.handleModalCancel()
        this.onClose()
      })
  }

  onRequestOk = () => {
    this.setState({ revokeUnrevokeConfirm: true })
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      activationObj,
      slidingForm,
      licType,
    } = this.props

    return (
      <>
        <FormatSlidingPage
          isOpen={isOpen}
          onRequestOk={this.onRequestOk}
          onRequestClose={onRequestClose}
          title={slidingForm + ' Activation'}
          description=""
          saveText={slidingForm}
        >
          <LMActivationForm
            slidingForm={slidingForm}
            activationObj={activationObj}
            licType={licType}
            onRef={(ref: any): any => (this.childForm = ref)}
          />
        </FormatSlidingPage>
        <A10Modal
          title={`Confirm ${slidingForm} Activation`}
          visible={this.state.revokeUnrevokeConfirm}
          onOk={this.confirmRevokeUnrevokeConfirm}
          onCancel={this.handleModalCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleModalCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.confirmRevokeUnrevokeConfirm}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            Do you want to continue {slidingForm.toLowerCase()} of{' '}
            <b>{activationObj['appliance_name'] || activationObj.mac}</b> ?
          </p>
        </A10Modal>
      </>
    )
  }
}

export default setupA10Container(SlidingLMActivationForm)
