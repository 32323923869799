import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Form,
  A10Input,
  A10Select,
  A10Button,
  A10Table,
  A10Col,
  A10Tooltip,
  A10Checkbox,
  A10Radio,
  A10TreeSelect,
  A10Popover,
  A10Message,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { Messages } from 'src/locale/en/Messages'
import { InfrastructureService } from 'src/services/InfrastructureService/InfrastructureService'
import { IDefaultMethods } from 'src/containers/Controller'
import { DropdownConstants } from 'src/constants/DropdownConstants/DropdownConstants'
import { Configs } from 'src/constants/Configs'
import { DashboardService, Utilities } from 'src/services/index'
import storage from 'src/libraries/storage'
import { HelpInfo } from 'src/components/shared/HelpInfo'

import './styles/ocidevicecreateform.scss'

const RadioGroup = A10Radio.Group
export interface IOCIDeviceCreateFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  device?: any
  vmDevices: any
  form: any
  formData?: any
  createFormData: any
  onRef?: any
  handleChange?: (data: any) => void
}
export interface IOCIDeviceCreateFormState {
  device: any
  vmDevice: any
  regionList: any[]
  availabilityDomainList: any[]
  compartmentList: any[]
  vcnList: any[]
  ociImageList: any[]
  instanceTypeList: any[]
  searchString: string
  searched: boolean
  lists: any[]
  hcClusterSelect: string
  nicCount: number
  showVCNCompartmentTree: boolean
  showImageCompartmentTree: boolean
  showSubnetCompartmentTree: {}
  showPublicIPCompartmentTree: {}
  showNSGCompartmentTree: {}
}

class OCIDeviceCreateForm extends A10Container<
  IOCIDeviceCreateFormProps,
  IOCIDeviceCreateFormState
> {
  Messages = new Messages()
  InfrastructureService = new InfrastructureService()
  DropdownConstants = new DropdownConstants()
  Configs = new Configs()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  lists: any[] = []

  mainNetworks = [
    {
      name: 'Management/eth0',
    },
    {
      name: 'Ethernet1/eth1',
    },
  ]

  constructor(props: IOCIDeviceCreateFormProps) {
    super(props)
    const vmDevice: any = props.createFormData
    const device: any = props.formData
    // const vmDeviceObj = props.device
    let initSubnetCompartmentPopoverVisible = {}
    let initPublicIPCompartmentPopoverVisible = {}
    let initNSGCompartmentPopoverVisible = {}

    console.log(vmDevice)
    for (let i = 0; i < vmDevice.vmNetworks.length; i++) {
      initSubnetCompartmentPopoverVisible[
        vmDevice.vmNetworks[i]['name']
      ] = false
      initPublicIPCompartmentPopoverVisible[
        vmDevice.vmNetworks[i]['name']
      ] = false
      initNSGCompartmentPopoverVisible[vmDevice.vmNetworks[i]['name']] = false
    }

    this.state = {
      device,
      vmDevice,
      regionList: [],
      availabilityDomainList: [],
      compartmentList: [],
      vcnList: [],
      ociImageList: [],
      instanceTypeList: this.Configs.OCIInstanceTypeList,
      searchString: '',
      searched: false,
      lists: [[]],
      hcClusterSelect: '',
      nicCount: 2,
      showVCNCompartmentTree: false,
      showImageCompartmentTree: false,
      showSubnetCompartmentTree: initSubnetCompartmentPopoverVisible,
      showPublicIPCompartmentTree: initPublicIPCompartmentPopoverVisible,
      showNSGCompartmentTree: initNSGCompartmentPopoverVisible,
    }
    this.loadOCIRegionLists(JSON.parse(device.credential))
  }

  getChangedValue = (e: any) => {
    let changedValue = ''
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        changedValue = e.target.checked
      } else {
        changedValue = e.target.value
      }
    } else {
      changedValue = e
    }
    return changedValue
  }

  handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      this.searchDeviceImage()
      event.preventDefault()
    }
  }
  handleChange = (stateName: string, e: any, index?: number) => {
    if (!this.Utilities.validateField(e)) {
      return
    }
    const changedValue = this.getChangedValue(e)
    const vmDeviceObj = this.state.vmDevice
    let { vcnList, ociImageList, compartmentList } = this.state

    if (index !== undefined) {
      if (stateName === 'autoAssign' && changedValue) {
        vmDeviceObj.vmNetworks[index]['ipAddr'] = ''
        this.props.form.setFieldsValue({ [`ipAddr[${index}]`]: '' })
      }
      if (stateName === 'autoAllocate' && changedValue) {
        vmDeviceObj.vmNetworks[index]['autoAllocate'] = changedValue
        vmDeviceObj.vmNetworks[index]['publicIP'] = ''
        this.props.form.setFieldsValue({ [`publicIP[${index}]`]: undefined })
      }
      if (stateName === 'subnetCompartment' && changedValue) {
        vmDeviceObj.vmNetworks[index]['subnetCompartment'] = changedValue
        if (vmDeviceObj['vcn'] !== '') {
          this.loadOCISubnets(
            JSON.parse(vmDeviceObj['vcn']),
            changedValue,
            index,
          )
        }
        this.props.form.setFieldsValue({ [`subnet[${index}]`]: undefined })
      }
      if (stateName === 'nsgCompartment' && changedValue) {
        vmDeviceObj.vmNetworks[index]['nsgCompartment'] = changedValue
        if (vmDeviceObj['vcn'] !== '') {
          this.loadOCINetworkSecurityGroups(
            JSON.parse(vmDeviceObj['vcn']),
            changedValue,
            index,
          )
        }
        this.props.form.setFieldsValue({
          [`securityGroup[${index}]`]: undefined,
        })
      }
      if (stateName === 'publicIpCompartment' && changedValue) {
        vmDeviceObj.vmNetworks[index]['publicIpCompartment'] = changedValue
        this.loadOCIPublicIPs(
          JSON.parse(vmDeviceObj['region']),
          changedValue,
          index,
        )
        this.props.form.setFieldsValue({ [`publicIP[${index}]`]: undefined })
      }
      vmDeviceObj.vmNetworks[index][stateName] = changedValue
    } else {
      vmDeviceObj[stateName] = changedValue
    }

    if (stateName === 'region' && this.state.vmDevice.region !== '') {
      vmDeviceObj.availabilityDomain = ''
      vmDeviceObj.compartment = ''
      vmDeviceObj.vcn = ''
      vmDeviceObj.imageName = ''
      vmDeviceObj.image = ''
      vmDeviceObj.imageCompartment = ''
      ociImageList = []
      vcnList = []
      compartmentList = []
      this.props.form.setFieldsValue({
        [`availabilityDomain`]: undefined,
        [`vcn`]: undefined,
        [`compartment`]: undefined,
        [`imageName`]: '',
      })
      vmDeviceObj.vmNetworks.map((vnic: any, index: number) => {
        vnic.subnet = ''
        vnic.subnetList = []
        vnic.subnetCompartment = ''
        vnic.nsgCompartment = ''
        vnic.publicIpCompartment = ''
        this.props.form.setFieldsValue({ [`subnet[${index}]`]: undefined })
        vnic.ipAddr = ''
        this.props.form.setFieldsValue({ [`ipAddr[${index}]`]: '' })
        vnic.publicIP = ''
        vnic.publicIPList = []
        this.props.form.setFieldsValue({ [`publicIP[${index}]`]: undefined })
        vnic.securityGroup = ''
        vnic.securityGroupList = []
        this.props.form.setFieldsValue({
          [`securityGroup[${index}]`]: undefined,
        })
      })
      this.loadOCIADLists(JSON.parse(vmDeviceObj['region']))
      this.loadOCICompartmentLists()
    }

    if (
      stateName === 'compartment' &&
      this.state.vmDevice.compartment !== '' &&
      this.state.vmDevice.vcn == ''
    ) {
      vmDeviceObj.vcn = ''
      this.props.form.setFieldsValue({
        [`vcn`]: undefined,
      })
      vmDeviceObj['vcnCompartment'] = vmDeviceObj['compartment']
      vmDeviceObj['imageCompartment'] = vmDeviceObj['compartment']
      ociImageList = []
      this.loadOCIVCNLists(
        JSON.parse(vmDeviceObj['region']),
        vmDeviceObj['compartment'],
      )

      vmDeviceObj.vmNetworks.map((vnic: any, index: number) => {
        vnic['subnetCompartment'] = vmDeviceObj['compartment']
        vnic['nsgCompartment'] = vmDeviceObj['compartment']
        vnic['publicIpCompartment'] = vmDeviceObj['compartment']
        vnic.publicIP = ''
        this.props.form.setFieldsValue({ [`publicIP[${index}]`]: undefined })
        this.loadOCIPublicIPs(
          JSON.parse(vmDeviceObj['region']),
          vmDeviceObj['compartment'],
          index,
        )
        if (vmDeviceObj['vcn'] !== '') {
          vnic.subnet = ''
          this.props.form.setFieldsValue({ [`subnet[${index}]`]: undefined })
          vnic.securityGroup = ''
          this.props.form.setFieldsValue({
            [`securityGroup[${index}]`]: undefined,
          })
          this.loadOCISubnets(
            JSON.parse(vmDeviceObj['vcn']),
            changedValue,
            index,
          )
          this.loadOCINetworkSecurityGroups(
            JSON.parse(vmDeviceObj['vcn']),
            vnic['nsgCompartment'],
            index,
          )
        }
      })
    }

    if (stateName === 'vcnCompartment') {
      vmDeviceObj.vcn = ''
      this.props.form.setFieldsValue({
        [`vcn`]: undefined,
      })
      vcnList = []
      this.loadOCIVCNLists(
        JSON.parse(vmDeviceObj['region']),
        vmDeviceObj['vcnCompartment'],
      )
    }

    if (stateName === 'imageCompartment') {
      vmDeviceObj.image = ''
      ociImageList = []
      this.searchDeviceImage()
    }

    if (stateName === 'vcn') {
      vmDeviceObj.vmNetworks.map((vnic: any, index: number) => {
        vnic.subnet = ''
        this.props.form.setFieldsValue({ [`subnet[${index}]`]: undefined })
        vnic.securityGroup = ''
        this.props.form.setFieldsValue({
          [`securityGroup[${index}]`]: undefined,
        })
        this.loadOCISubnets(
          JSON.parse(vmDeviceObj['vcn']),
          vnic['subnetCompartment'],
          index,
        )
        this.loadOCINetworkSecurityGroups(
          JSON.parse(vmDeviceObj['vcn']),
          vnic['nsgCompartment'],
          index,
        )
      })
    }

    if (stateName === 'instanceType') {
      let nics = JSON.parse(vmDeviceObj['instanceType']).maxNics
      if (this.state.nicCount > nics) {
        for (let i = nics; i < this.state.nicCount; i++) {
          this.mainNetworks.pop()
          vmDeviceObj.vmNetworks.pop()
        }
        this.setState({ nicCount: vmDeviceObj.vmNetworks.length })
      }
    }

    this.props.handleChange(vmDeviceObj)

    // @ts-ignore
    this.setState({
      vmDevice: vmDeviceObj,
      ociImageList,
      vcnList,
      compartmentList,
    })
  }

  addEth = () => {
    const nicName =
      'Ethernet' + this.state.nicCount + '/eth' + this.state.nicCount
    this.mainNetworks.push({ name: nicName })
    const vmDeviceObj = this.state.vmDevice
    vmDeviceObj.vmNetworks.push({
      name: 'eth' + this.state.nicCount,
      subnet: '',
      ipAddr: '',
      autoAssign: false,
      publicIP: '',
      autoAllocate: false,
      securityGroup: '',
      subnetCompartment:
        vmDeviceObj.vmNetworks[vmDeviceObj.vmNetworks.length - 1]
          .subnetCompartment,
      publicIpCompartment:
        vmDeviceObj.vmNetworks[vmDeviceObj.vmNetworks.length - 1]
          .publicIpCompartment,
      nsgCompartment:
        vmDeviceObj.vmNetworks[vmDeviceObj.vmNetworks.length - 1]
          .nsgCompartment,
      subnetList:
        vmDeviceObj.vmNetworks[vmDeviceObj.vmNetworks.length - 1].subnetList,
      publicIPList:
        vmDeviceObj.vmNetworks[vmDeviceObj.vmNetworks.length - 1].publicIPList,
      securityGroupList:
        vmDeviceObj.vmNetworks[vmDeviceObj.vmNetworks.length - 1]
          .securityGroupList,
    })
    this.props.handleChange(vmDeviceObj)
    // @ts-ignore
    this.setState({ vmDevice: vmDeviceObj, nicCount: this.state.nicCount + 1 })
  }

  removeEth = () => {
    this.mainNetworks.pop()
    const vmDeviceObj = this.state.vmDevice
    vmDeviceObj.vmNetworks.pop()
    this.props.handleChange(vmDeviceObj)
    // @ts-ignore
    this.setState({ vmDevice: vmDeviceObj, nicCount: this.state.nicCount - 1 })
  }

  loadOCIRegionLists = (credential: any) => {
    const ociRegionsResponse = this.InfrastructureService.getOCIRegions(
      null,
      null,
      [credential.uuid],
    )
    ociRegionsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list) {
            this.setState({
              regionList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get OCI region details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadOCIADLists = (region: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const ociADResponse = this.InfrastructureService.getOCIAvailabilityDomains(
      null,
      null,
      [credential.uuid, credential['oci-account']['tenancy-id'], region.name],
    )
    ociADResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list) {
            this.setState({
              availabilityDomainList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get oci availability domain details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  getCompartmentChildren = (element: any) => {
    let children: {}[] = []
    element.forEach((item: any) => {
      let obj = {}
      obj['label'] = obj['title'] = item.name
      obj['key'] = item.id
      if (item.hasOwnProperty('children')) {
        obj['children'] = this.getCompartmentChildren(item.children)
      }
      //item['children'] = obj['children']
      obj['value'] = JSON.stringify(item)
      obj['selectable'] = true
      children.push(obj)
    })
    return children
  }

  loadOCICompartmentLists = () => {
    const credential = JSON.parse(this.state.device.credential)
    const ociCompartmentsResponse = this.InfrastructureService.getOCICompartments(
      null,
      null,
      [credential.uuid, credential['oci-account']['tenancy-id']],
    )
    ociCompartmentsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          let comp_list: {}[] = []
          list.forEach((element: { name: any; children: any[] }) => {
            let comp = {}
            comp['label'] = comp['title'] = element.name
            if (element.children) {
              comp['children'] = this.getCompartmentChildren(element.children)
            }
            comp['key'] = element.id
            comp['value'] = JSON.stringify(element)
            comp['selectable'] = true
            comp_list.push(comp)
          })
          if (list) {
            this.setState({
              compartmentList: comp_list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get oci compartment details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadOCIVCNLists = (region: any, compartment: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const compartment_id = JSON.parse(compartment).id
    const ociVCNsResponse = this.InfrastructureService.getOCIVCNs(null, null, [
      credential.uuid,
      compartment_id,
      region.name,
    ])
    ociVCNsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list) {
            this.setState({
              vcnList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get oci virtual cloud network details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  searchDeviceImage = () => {
    const credential = JSON.parse(this.state.device.credential)
    const compartment_id = JSON.parse(this.state.vmDevice.imageCompartment).id
    const region = JSON.parse(this.state.vmDevice.region)
    const ociImageResponse = this.InfrastructureService.getOCIImages(
      null,
      null,
      [
        credential.uuid,
        compartment_id,
        region.name,
        this.state.vmDevice.imageName,
      ],
    )
    ociImageResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load ? response.data.load : []
        }
        this.setState({
          ociImageList: list,
          searched: true,
        })
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get oci image details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
    // }
  }

  loadOCIPublicIPs = (
    region: any,
    publicIpCompartment: any,
    index?: number,
  ) => {
    const credential = JSON.parse(this.state.device.credential)
    const compartment_id = JSON.parse(publicIpCompartment).id
    const ociPublicIPsResponse = this.InfrastructureService.getOCIPublicIPs(
      null,
      null,
      [credential.uuid, compartment_id, region.name],
    )
    ociPublicIPsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list) {
            const vmDeviceObj = this.state.vmDevice
            vmDeviceObj.vmNetworks[index]['publicIPList'] = list
            this.setState({
              vmDevice: vmDeviceObj,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get oci public IP details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadOCINetworkSecurityGroups = (
    vcn: any,
    nsgCompartment: any,
    index?: number,
  ) => {
    const credential = JSON.parse(this.state.device.credential)
    const compartment_id = JSON.parse(nsgCompartment).id
    const region = JSON.parse(this.state.vmDevice.region)
    const ociSecurityGroupsResponse = this.InfrastructureService.getOCINetworkSecurityGroups(
      null,
      null,
      [credential.uuid, compartment_id, vcn.id, region.name],
    )
    ociSecurityGroupsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list) {
            const vmDeviceObj = this.state.vmDevice
            vmDeviceObj.vmNetworks[index]['securityGroupList'] = list
            this.setState({
              vmDevice: vmDeviceObj,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get oci network security group details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadOCISubnets = (vcn: any, subnetCompartment: any, index?: number) => {
    const credential = JSON.parse(this.state.device.credential)
    const compartment_id = JSON.parse(subnetCompartment).id
    const region = JSON.parse(this.state.vmDevice.region)
    if (vcn.id) {
      const { showMessage } = this.Utilities
      const ociSubnetsResponse = this.InfrastructureService.getOCISubnets(
        null,
        null,
        [credential.uuid, compartment_id, vcn.id, region.name],
      )
      ociSubnetsResponse
        .then((response: any) => {
          let list = []
          if (response && response.data && response.data.status === 'Success') {
            list = response.data.load
            if (list) {
              const vmDeviceObj = this.state.vmDevice
              vmDeviceObj.vmNetworks[index]['subnetList'] = list
              this.setState({
                vmDevice: vmDeviceObj,
              })
            }
          }
        })
        .catch((error: any) => {
          // console.log(error.response)
          const responseCode =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.response_code
              ? error.response.data.response_code
              : error.response
              ? error.response.status
              : ''
          let msg =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : ''
          let message = 'Unable to get oci subnet details'
          if (responseCode === 403) {
            message += ', please check the credentials'
            msg = ''
          }
          showMessage(message, 0, 0, msg)
        })
    }
  }

  getIPnumber = (ip_address: any) => {
    let ip = ip_address.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/)
    if (ip) {
      return (+ip[1] << 24) + (+ip[2] << 16) + (+ip[3] << 8) + +ip[4]
    }
  }

  isValidComaprtment = async (compartment: any) => {
    let authorized = true
    const credential = JSON.parse(this.state.device.credential)
    const region = JSON.parse(this.state.vmDevice.region)
    const compartment_id = JSON.parse(compartment).id
    const ociVCNsResponse = this.InfrastructureService.getOCIVCNs(null, null, [
      credential.uuid,
      compartment_id,
      region.name,
    ])
    await ociVCNsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list) {
            authorized = true
          }
        }
      })
      .catch((error: any) => {
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        if (responseCode === 404) {
          authorized = false
        }
      })
    return authorized
  }

  validateCompartment = async (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    let authorized = await this.isValidComaprtment(value)
    if (value && !authorized) {
      rule.message =
        'User does not have enough permissions to use this compartment.'
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validatePrivateIPs = (region: any) => {
    let req_payload = {}
    let ip_list = []
    let unavailableIPs: string[] = []
    const { showMessage } = this.Utilities

    for (let i = 0; i < this.state.vmDevice.vmNetworks.length; i++) {
      if (
        !this.state.vmDevice.vmNetworks[i].autoAssign &&
        this.state.vmDevice.vmNetworks[i].ipAddr
      ) {
        if (
          this.state.vmDevice.vcn == '' ||
          this.state.vmDevice.vmNetworks[i].subnet == ''
        ) {
          showMessage('Please select both VCN and Subnet', 0, 0)
          return
        } else {
          let subnet_cidr = JSON.parse(this.state.vmDevice.vmNetworks[i].subnet)
            .cidr_block
          const cidrIp = subnet_cidr.split('/')[0]
          const cidrSm = subnet_cidr.split('/')[1]
          const private_ip = this.state.vmDevice.vmNetworks[i].ipAddr
          const ipmask = -1 << (32 - cidrSm)
          let isIPinSubnet =
            (this.getIPnumber(private_ip) & ipmask) == this.getIPnumber(cidrIp)
          if (!isIPinSubnet) {
            showMessage(
              'Private IP address ' +
                private_ip +
                ' does not belong to the range of subnet ' +
                subnet_cidr,
              0,
              0,
            )
            return
          } else {
            ip_list.push({
              'ip-address': this.state.vmDevice.vmNetworks[i].ipAddr,
              'network-id': JSON.parse(this.state.vmDevice.vcn).id,
              'subnet-id': JSON.parse(this.state.vmDevice.vmNetworks[i].subnet)
                .id,
            })
          }
        }
      }
    }

    if (ip_list.length < 1) {
      A10Message.info('No IP addresses provided or marked for validation.')
      return
    }

    req_payload['ip-addresses'] = ip_list
    const credential = JSON.parse(this.state.device.credential)
    const ociPrivateIPResponse = this.InfrastructureService.checkOCIPrivateIPAvailability(
      null,
      req_payload,
      [credential.uuid, JSON.parse(region).name],
    )
    ociPrivateIPResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && Object.keys(list).length > 0) {
            for (var key in list) {
              if (!list[key]) {
                unavailableIPs.push(key)
              }
            }
          }
          if (ip_list.length > 0 && unavailableIPs.length == 0) {
            A10Message.success('All Private IP Addresses are available')
          } else if (unavailableIPs.length == 1) {
            let err_message =
              'Private IP Address [' +
              unavailableIPs[0] +
              '] is already in use.'
            showMessage(err_message, 0, 0, '')
          } else if (unavailableIPs.length > 1) {
            let unavailableIP_list = unavailableIPs[0]
            for (let i = 1; i < unavailableIPs.length; i++) {
              unavailableIP_list += ', ' + unavailableIPs[i]
            }
            let err_message =
              'Private IP Addresses [' +
              unavailableIP_list +
              '] are already in use.'
            showMessage(err_message, 0, 0, '')
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to check private IP address availability'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  validateDuplicateVMName = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const index = this.props.vmDevices.findIndex((key: any) => {
      return key.name === value
    })
    const userRegx = new RegExp(/^([a-zA-Z0-9._-]){1,50}$/)

    if (!value || '' === value) {
      rule.message = this.Messages.VM_NAME_REQ
      isValid = false
    } else if (!userRegx.test(value)) {
      rule.message = this.Messages.INVALID_VM_NAME
      isValid = false
    } else if (index > -1) {
      rule.message = this.Messages.VM_NAME_DUP
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validatePublicIP = (index: number, rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const { showMessage } = this.Utilities
    let subnet = this.state.vmDevice.vmNetworks[index].subnet
      ? JSON.parse(this.state.vmDevice.vmNetworks[index].subnet)
      : null
    if (
      rule.field == `publicIP[${index}]` &&
      value !== undefined &&
      subnet &&
      subnet.prohibit_public_ip_on_vnic
    ) {
      rule.message = 'VNIC in the private subnet can not have public IP address'
      isValid = false
    } else if (
      rule.field == `autoAllocate[${index}]` &&
      typeof value === 'boolean' &&
      value &&
      subnet &&
      subnet.prohibit_public_ip_on_vnic
    ) {
      showMessage(
        'VNIC in the private subnet ' +
          subnet.cidr_block +
          ' can not have public IP address.',
        0,
        0,
      )
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { vmDevice } = this.state
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <div className="margin-v-10">
          <A10Panel
            className="panelBodyPadding"
            title={
              <>
                <A10IconTextGroup
                  text={
                    <>
                      <span className="borderLeftData">OCI Environment</span>
                      {/* <span>
                        <HelpInfo
                          placement="topLeft"
                          title="OCI Environment"
                          helpKey="HELP_INFRA_VIRTUAL_OCI_ENVIRONMENT"
                        />
                      </span> */}
                    </>
                  }
                  icon={
                    <A10Icon
                      className="destinationIcon"
                      app="gi-firewall"
                      type="destination"
                      style={{ fontSize: '24px' }}
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                    VM (Instance) Name
                  </span>
                  <span>
                    <HelpInfo
                      placement="topLeft"
                      title="VM (Instance) Name"
                      helpKey="HELP_INFRA_VIRTUAL_VM_NAME"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('vmName', {
                rules: [{ validator: this.validateDuplicateVMName.bind(this) }],
              })(
                <A10Input
                  type="text"
                  placeholder={'Enter VM Device name'}
                  onChange={(e: any) => this.handleChange('vmName', e)}
                />,
              )}
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                    Region
                  </span>
                  <span>
                    <HelpInfo
                      placement="topLeft"
                      title="Region"
                      helpKey="HELP_INFRA_VIRTUAL_OCI_REGION"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('region', {
                rules: [
                  {
                    required: true,
                    message: 'Please select region',
                  },
                ],
                initialValue: vmDevice.region || undefined,
              })(
                <A10Select
                  placeholder={'Select a region'}
                  onChange={(e: any) => this.handleChange('region', e)}
                >
                  <A10Select.Option key="blank" value="">
                    Select Region
                  </A10Select.Option>
                  {this.state.regionList.map((obj: any, i: number) => {
                    return (
                      <A10Select.Option key={i} value={JSON.stringify(obj)}>
                        {obj.name}
                      </A10Select.Option>
                    )
                  })}
                </A10Select>,
              )}
            </A10Form.Item>

            {!!vmDevice.region ? (
              <>
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                        Availability Domain
                      </span>
                      <span>
                        <HelpInfo
                          placement="topLeft"
                          title="Availability Zone"
                          helpKey="HELP_INFRA_VIRTUAL_OCI_AVAILABILITY_DOMAIN"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('availabilityDomain', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select availability domain',
                      },
                    ],
                    // initialValue: vmDevice.zone
                  })(
                    <A10Select
                      placeholder={'Select availability domain'}
                      onChange={(e: any) =>
                        this.handleChange('availabilityDomain', e)
                      }
                    >
                      {this.state.availabilityDomainList.map(
                        (obj: any, i: number) => {
                          return (
                            <A10Select.Option
                              key={i}
                              value={JSON.stringify(obj)}
                            >
                              {obj.name}
                            </A10Select.Option>
                          )
                        },
                      )}
                    </A10Select>,
                  )}
                </A10Form.Item>

                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                        Compartment
                      </span>
                      <span>
                        <HelpInfo
                          placement="topLeft"
                          title="Availability Zone"
                          helpKey="HELP_INFRA_VIRTUAL_OCI_COMPARTMENT"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('compartment', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select compartment',
                        validateTrigger: 'onBlur',
                        validator: this.validateCompartment.bind(this),
                      },
                    ],
                    //initialValue: vmDevice.compartment
                  })(
                    <A10TreeSelect
                      placeholder={'Select compartment'}
                      onChange={(e: any) => this.handleChange('compartment', e)}
                      value={this.state.vmDevice.compartment}
                      treeData={this.state.compartmentList}
                    ></A10TreeSelect>,
                  )}
                </A10Form.Item>

                {!!vmDevice.compartment ? (
                  <A10Form.Item
                    {...formItemLayout}
                    label={
                      <>
                        <span
                          style={{ fontWeight: 'normal', fontSize: '16px' }}
                        >
                          Virtual Cloud Network (VCN)
                        </span>
                        <span>
                          <HelpInfo
                            placement="topLeft"
                            title="Virtual Cloud Network (VCN)"
                            helpKey="HELP_INFRA_VIRTUAL_OCI_VCN"
                          />
                        </span>
                      </>
                    }
                  >
                    {getFieldDecorator('vcn', {
                      rules: [
                        {
                          required: true,
                          message: 'Please select Virtual Cloud Network (VCN)',
                        },
                      ],
                      initialValue: vmDevice.vcn || undefined,
                    })(
                      <A10Select
                        placeholder={'Select Virtual Private Cloud (VPC)'}
                        onChange={(e: any) => this.handleChange('vcn', e)}
                      >
                        {this.state.vcnList.map((obj: any, i: number) => {
                          let dispName =
                            obj.name + ' (' + obj['cidr_block'] + ')'
                          return (
                            <A10Select.Option
                              key={i}
                              value={JSON.stringify(obj)}
                            >
                              {dispName}
                            </A10Select.Option>
                          )
                        })}
                      </A10Select>,
                    )}
                    <A10Popover
                      placement="right"
                      content={
                        <A10TreeSelect
                          style={{ width: '100%' }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          placeholder="Please select"
                          treeData={this.state.compartmentList}
                          treeDefaultExpandAll
                          onChange={(e: any) =>
                            this.handleChange('vcnCompartment', e)
                          }
                          onSelect={() =>
                            this.setState({ showVCNCompartmentTree: false })
                          }
                          getPopupContainer={() =>
                            document.getElementById('vcn')
                          }
                        />
                      }
                      title="VCN Compartment"
                      trigger="click"
                      visible={this.state.showVCNCompartmentTree}
                      onVisibleChange={(visible: any) => {
                        this.setState({ showVCNCompartmentTree: visible })
                      }}
                      getPopupContainer={() => document.getElementById('vcn')}
                    >
                      <div className="col-md-0 oci-compartment pull-right">
                        <A10Icon
                          // @ts-ignore
                          app="goe"
                          type="groups"
                        />{' '}
                        {JSON.parse(this.state.vmDevice.vcnCompartment).name}
                      </div>
                    </A10Popover>
                  </A10Form.Item>
                ) : null}

                {!!vmDevice.compartment ? (
                  <>
                    <div className="oci-vm-template">
                      <span className="title">VM Templates</span>
                      {/* <span>
                    <HelpInfo
                      placement="topLeft"
                      title="VM Templates"
                      helpKey="HELP_INFRA_VIRTUAL_VM_TEMPLATES"
                    />
                  </span> */}
                    </div>

                    <A10Form.Item
                      {...formItemLayout}
                      label={
                        <>
                          <span
                            style={{ fontWeight: 'normal', fontSize: '16px' }}
                          >
                            Image Name
                          </span>
                          <span>
                            <HelpInfo
                              placement="topLeft"
                              title="Image Name"
                              helpKey="HELP_INFRA_VIRTUAL_OCI_IMAGE"
                            />
                          </span>
                        </>
                      }
                    >
                      {getFieldDecorator('imageName')(
                        <div className="row">
                          <div className="col-md-8">
                            <A10Input
                              type="text"
                              placeholder={'Name of the machine image'}
                              onChange={(e: any) =>
                                this.handleChange('imageName', e)
                              }
                              onKeyPress={this.handleKeyPress}
                            />
                          </div>
                          <div className="col-md-4">
                            <A10Button
                              className="action-button"
                              onClick={() => {
                                this.searchDeviceImage()
                              }}
                            >
                              Search
                            </A10Button>
                          </div>
                        </div>,
                      )}
                      <div>
                        <A10Popover
                          placement="right"
                          content={
                            <A10TreeSelect
                              style={{ width: '100%' }}
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                              }}
                              placeholder="Please select"
                              treeData={this.state.compartmentList}
                              treeDefaultExpandAll
                              onChange={(e: any) => {
                                this.handleChange('imageCompartment', e)
                              }}
                              onSelect={() =>
                                this.setState({
                                  showImageCompartmentTree: false,
                                })
                              }
                              getPopupContainer={() =>
                                document.getElementById('imageName')
                              }
                            />
                          }
                          title="Image Compartment"
                          trigger="click"
                          visible={this.state.showImageCompartmentTree}
                          onVisibleChange={(visible: any) => {
                            this.setState({ showImageCompartmentTree: visible })
                          }}
                          getPopupContainer={() =>
                            document.getElementById('imageName')
                          }
                        >
                          <div className="col-md-8 oci-compartment pull-left">
                            <A10Icon
                              // @ts-ignore
                              app="goe"
                              type="groups"
                            />{' '}
                            {
                              JSON.parse(this.state.vmDevice.imageCompartment)
                                .name
                            }
                          </div>
                        </A10Popover>

                        <div
                          className="col-md-8 row"
                          style={{ padding: '0px 5px' }}
                        >
                          {!!this.state.ociImageList &&
                          this.state.ociImageList.length === 0 &&
                          this.state.searched ? (
                            <span className="col-md-12">
                              {'No images found.'}
                            </span>
                          ) : !!this.state.ociImageList &&
                            this.state.ociImageList.length > 0 &&
                            this.state.ociImageList.length <= 50 ? (
                            <span className="col-md-12">
                              {this.state.ociImageList.length +
                                ' image(s) found. Please select one.'}
                            </span>
                          ) : !!this.state.ociImageList &&
                            this.state.ociImageList.length > 50 ? (
                            <span className="col-md-12">
                              {
                                ' More than 50 images found. Please refine your search filter'
                              }
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </A10Form.Item>

                    {!!this.state.ociImageList &&
                    this.state.ociImageList.length > 0 ? (
                      <A10Form.Item {...formItemLayout} label="&nbsp;">
                        {getFieldDecorator('image')(
                          <div className="oci-image-div">
                            <RadioGroup
                              onChange={(e: any) =>
                                this.handleChange('image', e)
                              }
                              value={vmDevice.image}
                            >
                              {this.state.ociImageList.map(
                                (ociImage: any, index: number) => {
                                  return (
                                    <A10Radio
                                      key={index}
                                      value={JSON.stringify(ociImage)}
                                      className="oci-image-list"
                                    >
                                      {ociImage.name}
                                    </A10Radio>
                                  )
                                },
                              )}
                            </RadioGroup>
                          </div>,
                        )}
                      </A10Form.Item>
                    ) : null}

                    <A10Form.Item
                      {...formItemLayout}
                      label={
                        <>
                          <span
                            style={{ fontWeight: 'normal', fontSize: '16px' }}
                          >
                            Shape
                          </span>
                          <span>
                            <HelpInfo
                              placement="topLeft"
                              title="Shape"
                              helpKey="HELP_INFRA_VIRTUAL_OCI_SHAPE"
                            />
                          </span>
                        </>
                      }
                    >
                      {getFieldDecorator('instanceType', {
                        rules: [
                          {
                            required: true,
                            message: 'Please select shape',
                          },
                        ],
                        initialValue: vmDevice.instanceType || undefined,
                      })(
                        <A10Select
                          placeholder={'Select shape'}
                          onChange={(e: any) =>
                            this.handleChange('instanceType', e)
                          }
                        >
                          {this.state.instanceTypeList.map(
                            (obj: any, i: number) => {
                              return (
                                <A10Select.Option
                                  key={i}
                                  value={JSON.stringify(obj)}
                                >
                                  {obj.name} ({obj.maxNics} Network Interfaces)
                                </A10Select.Option>
                              )
                            },
                          )}
                        </A10Select>,
                      )}
                    </A10Form.Item>
                  </>
                ) : null}
              </>
            ) : null}
          </A10Panel>

          {!!vmDevice.region && !!vmDevice.compartment ? (
            <A10Panel
              className="panelBodyPadding"
              title={
                <>
                  <A10IconTextGroup
                    text={
                      <>
                        <span className="borderLeftData">Deployment</span>
                        {/* <span>
                          <HelpInfo
                            placement="topLeft"
                            title="Deployment"
                            helpKey="HELP_INFRA_VIRTUAL_VM_DEPLOYMENT"
                          />
                        </span> */}
                      </>
                    }
                    icon={
                      <A10Icon
                        app="harmony-controller"
                        type="provider"
                        className="destinationIcon"
                      />
                    }
                  />
                </>
              }
            >
              <A10Button
                className="action-button pull-right"
                onClick={() => {
                  this.validatePrivateIPs(this.state.vmDevice.region)
                }}
                style={{ padding: '0px !important' }}
              >
                Validate IP Addresses
              </A10Button>
              <div className="VMWareTemplate">
                {
                  <>
                    Network Mapping
                    {/* <span>
                      <HelpInfo
                        placement="topLeft"
                        title="Network Mapping"
                        helpKey="HELP_INFRA_VIRTUAL_OCI_NETWORK_MAPPING"
                      />
                    </span> */}
                  </>
                }
              </div>
              <A10Table
                dataSource={this.mainNetworks}
                pagination={false}
                scroll={{ x: '100%' }}
                rowClassName={() => 'network-mapping-table-row-alignment'}
              >
                <A10Col
                  title="Name"
                  dataIndex="name"
                  key="name"
                  width="20%"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortString(a, b, 'name')
                  }
                  render={(text: string, record: any, index: number) => {
                    return (
                      <div>
                        {index > 1 && index === this.state.nicCount - 1 ? (
                          <A10IconTextGroup
                            text={text}
                            icon={
                              <A10Icon
                                style={{ fontSize: 24 }}
                                app="global"
                                type="delete-another"
                                onClick={this.removeEth.bind(this)}
                              />
                            }
                          />
                        ) : (
                          text
                        )}
                      </div>
                    )
                  }}
                />
                <A10Col
                  title="Subnet"
                  key="subnet"
                  width="20%"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortString(a, b, 'subnet')
                  }
                  render={(text: string, record: any, index: number) => {
                    return (
                      <A10Form.Item className="no-margin">
                        {getFieldDecorator(`subnet[${index}]`, {
                          rules: [
                            {
                              required: true,
                              message: 'Select subnet',
                            },
                          ],
                        })(
                          <A10Select
                            placeholder={'Please select'}
                            className="oci-network-dropdown"
                            dropdownClassName="oci-network-dropdown"
                            allowClear
                            size="small"
                            onChange={(e: any) =>
                              this.handleChange('subnet', e, index)
                            }
                          >
                            {this.state.vmDevice.vmNetworks[
                              index
                            ].subnetList.map((obj: any, i: number) => {
                              return (
                                <A10Select.Option
                                  key={i}
                                  value={JSON.stringify(obj)}
                                >
                                  <A10Tooltip
                                    placement="top"
                                    title={obj['cidr_block']}
                                  >
                                    <span>{obj['cidr_block']}</span>
                                  </A10Tooltip>
                                </A10Select.Option>
                              )
                            })}
                          </A10Select>,
                        )}
                        <A10Popover
                          placement="left"
                          overlayClassName="oci-network-compartment-dropdown"
                          content={
                            <A10TreeSelect
                              style={{ width: '100%' }}
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                              }}
                              placeholder="Please select"
                              treeData={this.state.compartmentList}
                              treeDefaultExpandAll
                              onChange={(e: any) =>
                                this.handleChange('subnetCompartment', e, index)
                              }
                              onSelect={() => {
                                let showSubnetPopOver = this.state
                                  .showSubnetCompartmentTree
                                showSubnetPopOver[
                                  this.state.vmDevice.vmNetworks[index]['name']
                                ] = false
                                this.setState({
                                  showSubnetCompartmentTree: showSubnetPopOver,
                                })
                              }}
                              getPopupContainer={() =>
                                document.getElementById(`subnet[${index}]`)
                              }
                            />
                          }
                          title="Subnet Compartment"
                          trigger="click"
                          visible={
                            this.state.showSubnetCompartmentTree[
                              this.state.vmDevice.vmNetworks[index]['name']
                            ] || false
                          }
                          onVisibleChange={(visible: any) => {
                            let showSubnet = this.state
                              .showSubnetCompartmentTree
                            showSubnet[
                              this.state.vmDevice.vmNetworks[index]['name']
                            ] = visible
                            this.setState({
                              showSubnetCompartmentTree: showSubnet,
                            })
                          }}
                          getPopupContainer={() =>
                            document.getElementById(`subnet[${index}]`)
                          }
                        >
                          <span className="col-md-12 oci-compartment  pull-right">
                            <A10Icon app="goe" type="groups" />{' '}
                            {
                              JSON.parse(
                                this.state.vmDevice.vmNetworks[index]
                                  .subnetCompartment,
                              ).name
                            }
                          </span>
                        </A10Popover>
                      </A10Form.Item>
                    )
                  }}
                />
                <A10Col
                  title="IP Address"
                  key="ipAddr"
                  width="20%"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortNumber(a, b, 'ipAddr')
                  }
                  render={(text: string, record: any, index: number) => {
                    return (
                      <>
                        <A10Form.Item className="no-margin">
                          <>
                            {getFieldDecorator(`ipAddr[${index}]`, {
                              validateTrigger: 'onBlur',
                              rules: [
                                {
                                  validator: vmDevice.vmNetworks[index]
                                    .autoAssign
                                    ? null
                                    : this.Utilities.validateVMDeviceIPs.bind(
                                        this,
                                        'not-mandatory',
                                      ),
                                },
                              ],
                            })(
                              <A10Input
                                type="text"
                                placeholder={'IP Address'}
                                size="small"
                                style={{ height: '30px' }}
                                onChange={(e: any) =>
                                  this.handleChange('ipAddr', e, index)
                                }
                                disabled={vmDevice.vmNetworks[index].autoAssign}
                              />,
                            )}
                          </>
                        </A10Form.Item>
                        <div
                          className="col-md-12"
                          style={{ padding: '17px 0px' }}
                        >
                          <A10Checkbox
                            onChange={(e: any) =>
                              this.handleChange('autoAssign', e, index)
                            }
                            style={{ fontSize: '12px' }}
                          >
                            Auto-assign
                          </A10Checkbox>
                        </div>
                      </>
                    )
                  }}
                />
                <A10Col
                  title="Public IP"
                  key="publicIP"
                  width="20%"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortNumber(a, b, 'publicIP')
                  }
                  render={(text: string, record: any, index: number) => {
                    return (
                      <>
                        <A10Form.Item className="no-margin">
                          {getFieldDecorator(`publicIP[${index}]`, {
                            validateTrigger: 'onBlur',
                            rules: [
                              {
                                validator: vmDevice.vmNetworks[index]
                                  .autoAllocate
                                  ? null
                                  : this.validatePublicIP.bind(this, index),
                              },
                            ],
                          })(
                            <A10Select
                              placeholder={'Please select'}
                              className="oci-network-dropdown"
                              dropdownClassName="oci-network-dropdown"
                              size="small"
                              allowClear
                              onChange={(e: any) =>
                                this.handleChange('publicIP', e, index)
                              }
                              disabled={vmDevice.vmNetworks[index].autoAllocate}
                            >
                              {this.Utilities.renderDropdownOpts(
                                this.state.vmDevice.vmNetworks[index]
                                  .publicIPList,
                                null,
                                'public_ip',
                                null,
                                null,
                                null,
                                'public_ip',
                                index + '',
                              )}
                            </A10Select>,
                          )}
                          <A10Popover
                            placement="right"
                            overlayClassName="oci-network-compartment-dropdown"
                            content={
                              <A10TreeSelect
                                style={{ width: '100% !important' }}
                                dropdownStyle={{
                                  maxHeight: 400,
                                  overflow: 'auto',
                                }}
                                placeholder="Please select"
                                treeData={this.state.compartmentList}
                                treeDefaultExpandAll
                                onChange={(e: any) =>
                                  this.handleChange(
                                    'publicIpCompartment',
                                    e,
                                    index,
                                  )
                                }
                                onSelect={() => {
                                  let showPublicIPPopOver = this.state
                                    .showPublicIPCompartmentTree
                                  showPublicIPPopOver[
                                    this.state.vmDevice.vmNetworks[index][
                                      'name'
                                    ]
                                  ] = false
                                  this.setState({
                                    showPublicIPCompartmentTree: showPublicIPPopOver,
                                  })
                                }}
                                getPopupContainer={() =>
                                  document.getElementById(`publicIP[${index}]`)
                                }
                              />
                            }
                            title="PublicIP Compartment"
                            trigger="click"
                            visible={
                              this.state.showPublicIPCompartmentTree[
                                this.state.vmDevice.vmNetworks[index]['name']
                              ] || false
                            }
                            onVisibleChange={(visible: any) => {
                              let showPublicIPPopOver = this.state
                                .showPublicIPCompartmentTree
                              showPublicIPPopOver[
                                this.state.vmDevice.vmNetworks[index]['name']
                              ] = visible
                              this.setState({
                                showPublicIPCompartmentTree: showPublicIPPopOver,
                              })
                            }}
                            getPopupContainer={() =>
                              document.getElementById(`publicIP[${index}]`)
                            }
                          >
                            <span className="col-md-12 oci-compartment pull-right">
                              <A10Icon app="goe" type="groups" />{' '}
                              {
                                JSON.parse(
                                  this.state.vmDevice.vmNetworks[index]
                                    .publicIpCompartment,
                                ).name
                              }
                            </span>
                          </A10Popover>
                        </A10Form.Item>
                        <div className="col-md-12 no-padding">
                          {getFieldDecorator(`autoAllocate[${index}]`, {
                            validateTrigger: 'onBlur',
                            rules: [
                              {
                                validator: this.validatePublicIP.bind(
                                  this,
                                  index,
                                ),
                              },
                            ],
                          })(
                            <A10Checkbox
                              onChange={(e: any) =>
                                this.handleChange('autoAllocate', e, index)
                              }
                              style={{ fontSize: '12px', marginTop: '10px' }}
                            >
                              Auto-allocate
                            </A10Checkbox>,
                          )}
                        </div>
                      </>
                    )
                  }}
                />
                <A10Col
                  title="Network Security Group"
                  key="networkSecurityGroup"
                  width="20%"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortNumber(a, b, 'networkSecurityGroup')
                  }
                  render={(text: string, record: any, index: number) => {
                    return (
                      <A10Form.Item className="no-margin">
                        {getFieldDecorator(`networkSecurityGroup[${index}]`, {
                          rules: [
                            {
                              required: true,
                              message: 'Select Network Security Group',
                            },
                          ],
                        })(
                          <A10Select
                            placeholder={'Please select'}
                            className="oci-network-dropdown"
                            dropdownClassName="oci-network-dropdown"
                            size="small"
                            onChange={(e: any) =>
                              this.handleChange('securityGroup', e, index)
                            }
                          >
                            {this.Utilities.renderDropdownOpts(
                              this.state.vmDevice.vmNetworks[index]
                                .securityGroupList,
                              null,
                              'name',
                              null,
                              null,
                              null,
                              'name',
                              index + '',
                            )}
                          </A10Select>,
                        )}
                        <A10Popover
                          placement="right"
                          content={
                            <A10TreeSelect
                              style={{ width: '100%' }}
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                              }}
                              placeholder="Please select"
                              treeData={this.state.compartmentList}
                              treeDefaultExpandAll
                              onChange={(e: any) =>
                                this.handleChange('nsgCompartment', e, index)
                              }
                              onSelect={() => {
                                let showNSGPopOver = this.state
                                  .showNSGCompartmentTree
                                showNSGPopOver[
                                  this.state.vmDevice.vmNetworks[index]['name']
                                ] = false
                                this.setState({
                                  showNSGCompartmentTree: showNSGPopOver,
                                })
                              }}
                              getPopupContainer={() =>
                                document.getElementById(
                                  `networkSecurityGroup[${index}]`,
                                )
                              }
                            />
                          }
                          title="NSG Compartment"
                          overlayClassName="oci-network-compartment-dropdown"
                          trigger="click"
                          visible={
                            this.state.showNSGCompartmentTree[
                              this.state.vmDevice.vmNetworks[index]['name']
                            ] || false
                          }
                          onVisibleChange={(visible: any) => {
                            let showNSGPopOver = this.state
                              .showNSGCompartmentTree
                            showNSGPopOver[
                              this.state.vmDevice.vmNetworks[index]['name']
                            ] = visible
                            this.setState({
                              showNSGCompartmentTree: showNSGPopOver,
                            })
                          }}
                          getPopupContainer={() =>
                            document.getElementById(
                              `networkSecurityGroup[${index}]`,
                            )
                          }
                        >
                          <span className="col-md-12 oci-compartment pull-right">
                            <A10Icon app="goe" type="groups" />{' '}
                            {
                              JSON.parse(
                                this.state.vmDevice.vmNetworks[index]
                                  .nsgCompartment,
                              ).name
                            }
                          </span>
                        </A10Popover>
                      </A10Form.Item>
                    )
                  }}
                />
              </A10Table>
              {this.state.vmDevice.instanceType &&
              this.state.nicCount <
                JSON.parse(this.state.vmDevice.instanceType).maxNics ? (
                <div className="row margin-v-10">
                  <div className="col-sm-12">
                    <A10IconTextGroup
                      text={
                        <div className="addEth2Title">
                          Ethernet{this.state.nicCount}/eth{this.state.nicCount}
                        </div>
                      }
                      icon={
                        <A10Icon
                          style={{ fontSize: 24 }}
                          app="global"
                          type="add-another"
                          onClick={this.addEth.bind(this)}
                        />
                      }
                    />
                  </div>
                </div>
              ) : null}
            </A10Panel>
          ) : null}
        </div>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(OCIDeviceCreateForm))
