import parameters from 'parameters'
import { axios, _ } from '@gui-libraries/framework'
import { A10Message } from '@gui-libraries/widgets'

import { saveUUIDDefinition } from './storage'
import storage from 'src/libraries/storage'

// const BASE_URL = (window as any).BASE_URL
axios.interceptors.request.use(
  (config: any) => {
    // for testing purpose
    const BASE_URL =
      process.env.NODE_ENV === 'production' ? parameters.BASE_URL : ''
    let { url } = config
    const { headers } = config
    const currentTenant = storage.get.CURRENT_TENANT
    const allTenants = storage.get.ALLTENANTS

    if (url && !/\.json$/.test(url)) {
      if (!url.startsWith('/') && !url.startsWith('http')) {
        url = `/${url}`
      }
      if (!url.startsWith('http')) {
        config.url = `${BASE_URL}${url}`
      }
    }

    const existXProviderIds =
      !!config.headers['X-ProviderIds'] ||
      !!config.headers['x-providerids'] ||
      !!config.headers['X-PROVIDERIDS']

    if (
      config.url.indexOf('/analytics/') !== -1 &&
      !existXProviderIds &&
      config.url.indexOf('/analytics/configuration/requests/capacity') === -1 &&
      config.url.indexOf('/analytics/configuration/_provider/lograte') === -1 &&
      allTenants &&
      allTenants.length === 0
    ) {
      // #GUI-2176
      throw new axios.Cancel('Request canceled because of no tenant available')
    }

    // if (process.env.NODE_ENV === 'production') {
    //   config.url = config.url.replace(/\+/g, '%2B')
    // }

    // headers
    if (headers.removeProvider) {
      delete headers.removeProvider
      config.headers = {
        'Content-Type': 'application/json',
        Authorization: storage.get.ENCODED_SESSION_ID,
        ...headers,
      }
    } else {
      config.headers = {
        'Content-Type': 'application/json',
        provider: storage.get.PROVIDER,
        Authorization: storage.get.ENCODED_SESSION_ID,
        ...headers,
      }
    }
    if (
      storage.get.ADMIN_LEVEL === 'tenant' &&
      !config.headers.tenant &&
      (config.url.includes('/uuid/') || config.url.includes('/subscriber/'))
    ) {
      try {
        config.headers.tenant = storage.get.CURRENT_TENANT.name
      } catch {
        config.headers.tenant = ''
      }
    }
    if (headers.removeContentType) {
      delete config.headers['Content-Type']
    }

    if (config.headers.tenant) {
      if (config.url.indexOf('/analytics/') !== -1) {
        // x-account is the only supported edgeAccount.
        // tenant is unsupported
        delete config.headers.tenant
      }
      if (_.size(config.headers.tenant) === 0) {
        // remove empty tenant object, tenant: {}
        delete config.headers.tenant
      }
    }

    if (
      currentTenant?.uuid &&
      config.url.indexOf('/analytics/') !== -1 &&
      !existXProviderIds
    ) {
      const MIN_XACCOUNT_LENGTH = 4
      const currentXAccount = currentTenant.uuid
      let headerXAccount =
        config.headers['x-account'] || config.headers['X-Account'] || ''

      if (!headerXAccount) {
        // auto add x-account
        config.headers['x-account'] = currentXAccount
        headerXAccount = currentXAccount
      }

      if (headerXAccount.length < MIN_XACCOUNT_LENGTH) {
        // #GUI-2767
        throw new axios.Cancel(
          'Request canceled because of no x-account available',
        )
      }
    }

    if (config.absoluteBasePath) {
      if (parameters) {
        config.url = `${parameters.BASE_URL}${url}`
      }
    }

    if (config.upload) {
      config.headers['Content-Type'] = 'multipart/form-data'
    }

    if (config.size) {
      config.headers.size = config.size
    }

    if (config.download || config.bigFileDelete) {
      delete config.headers['Content-Type']
    }

    if (config.headers.provider === null) {
      delete config.headers.provider
    }
    // if (
    //   config.headers.tenant === null ||
    //   config.headers['x-account'] ||
    //   config.headers['X-Account'] ||
    //   config.url.indexOf('/analytics/') === -1 // FIXME: remove this line when edge issue is fixed
    // ) {
    //   delete config.headers.tenant
    // }
    if (config.headers.Authorization === null) {
      delete config.headers.Authorization
    }

    config.data = config.data || '' // workaround for delete (https://github.com/axios/axios/issues/1083)
    return config
  },
  (error: any) => {
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response: any) => {
    if (response.config.saveUUIDDef) {
      saveUUIDDefinition(response.data)
    }
    return response
  },
  (error: any) => {
    try {
      const { config, response: { status, data } = {} as IObject } = error
      if (status === 403) {
        A10Message.destroy()
        A10Message.error(
          (data
            ? data.message
              ? data.message
              : data
            : ' Access Denied'),
        )
      } else if (
        status === 400 &&
        data.response &&
        data.response.err &&
        data.response.err.from === 'RBA' &&
        data.response.err.msg === 'Access Denied'
      ) {
        A10Message.destroy()
        // A10Message.error(`Not Authorized: ${config.url}`)
        A10Message.error('Access Denied')
      }
      if (status === 401 && config.url.indexOf('/sessions') === -1) {
        if (!storage.get.LASTALERT) {
          const provider = storage.get.PROVIDER
          storage.clear()
          storage.set.LASTALERT(true)
          if (provider !== null) {
            // tslint:disable-next-line:semicolon
            ;(window as any).location = '/login/' + provider
          } else {
            // tslint:disable-next-line:semicolon
            ;(window as any).location = '/login/root'
          }
        }
      }
    } catch (err) {
      console.error(err)
    }
    return Promise.reject(error)
  },
)

export const httpClient = axios
export default httpClient
