import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'

import { IDefaultMethods } from 'src/containers/Controller'
import { ApplicationConfigs } from 'src/constants/ApplicationConfigs'
import { Tenants } from './Tenants'
import { RBACUsers } from './RBACUsers'
import { AccessGroup } from './AccessGroup'
import { RBACRoles } from './RBACRoles'
import { License } from './License'
import { Authentication } from './Authentication'
import { Eula } from './Eula'
import AppRoot from 'src/settings/appRoot'
import { Utilities } from 'src/services'

export interface IOrganizationProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  refreshTenantsFunc: () => void
  tenantToggled: boolean
  selectedApp: string
  onSelectApp: (selectedApp: string) => void
  leftNavAppsRefresh: () => void
}
export interface IOrganizationState {
  application: any
}

class Organization extends A10Container<
  IOrganizationProps,
  IOrganizationState
> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>

  state: IOrganizationState = {
    application: {
      name: '',
      subApps: [],
    },
  }
  ApplicationConfigs = new ApplicationConfigs()
  AppRoot = new AppRoot()
  Utilities = new Utilities()

  componentDidMount() {
    const {
      storage: { remove },
    } = this.context

    remove.CONTEXT_LEVEL()
    remove.DGF_SELECTED_TENANT()
    remove.DGF_ACCOUNTS()
    remove.SELECTEDCONTEXT()
    remove.AUTH()
    remove.CONTEXT()

    this.updateState()
  }

  componentDidUpdate(prevProps: IOrganizationProps) {
    const { tenantToggled } = this.props
    if (prevProps.tenantToggled !== tenantToggled) {
      this.updateState()
    }
  }

  updateState() {
    const {
      storage: {
        get: { ADMIN_LEVEL },
        set,
        remove,
      },
    } = this.context

    const { selectedApp, onSelectApp } = this.props
    const applications = this.ApplicationConfigs.getAvailableApps()
    const applicationIndex = applications.findIndex((key: any) => {
      return key.name.toLowerCase() === 'organization'
    })
    let selectedAppDetail = applications[applicationIndex].subApps.find(
      (subApp: any) => {
        return subApp.name.toLowerCase() === selectedApp.toLowerCase()
      },
    )

    if (!selectedAppDetail)
      selectedAppDetail = applications[applicationIndex].subApps[0]

    const allowedRoles = this.ApplicationConfigs.getAllowedRolesSubApps(
      'Organization',
      selectedAppDetail.name,
    )
    if (allowedRoles) {
      const adminLevel = ADMIN_LEVEL
      const allowedIndex = allowedRoles.findIndex((key: any) => {
        return key === adminLevel
      })
      if (allowedIndex >= 0) {
        this.setState({
          application: applications[applicationIndex],
        })

        if (ADMIN_LEVEL === 'provider') {
          remove.TENANT()
          remove.CURRENT_TENANT()
          set.DRILL_LEVEL('provider')
          this.AppRoot.setRootScopeElement('currentDrillLevel', 'provider')
        }
        remove.SUPER_ADMIN_MODE()
        set.CURRENT_STATE('organization')
        this.AppRoot.setRootScopeElement('currentState', 'organization')

        if (selectedAppDetail.name !== selectedApp) {
          if (onSelectApp instanceof Function) {
            onSelectApp(selectedAppDetail.name)
          }
        }

        return
      }
    }
    this.Utilities.showMessage('INSUFFICENT_PRIVILEGE', 0, 1)
  }

  render() {
    const { defaultMethods, leftNavAppsRefresh } = this.props
    const selectedApp = this.props.selectedApp.toLowerCase()

    switch (selectedApp) {
      case 'tenants': {
        return (
          <Tenants
            defaultMethods={defaultMethods}
            refreshTenantsFunc={this.props.refreshTenantsFunc}
          />
        )
      }
      case 'license': {
        return (
          <License
            defaultMethods={defaultMethods}
            leftNavAppsRefresh={leftNavAppsRefresh}
          />
        )
      }
      case 'users': {
        return <RBACUsers defaultMethods={defaultMethods} />
      }
      case 'accessgroups': {
        return <AccessGroup />
      }
      case 'authentication': {
        return <Authentication defaultMethods={defaultMethods} />
      }
      case 'roles': {
        return <RBACRoles defaultMethods={defaultMethods} />
      }
      case 'agreements': {
        return <Eula />
      }
      default: {
        return null
      }
    }
  }
}

export default setupA10Container(Organization)
