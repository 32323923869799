import React from 'react'
import ReactLoading from 'react-loading'
import parameters from 'parameters'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Col } from '@gui-libraries/widgets'
import { DGF } from '@gui-libraries/dgf'

import { Utilities, DashboardService } from 'src/services'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import { ChangeLogCapacity } from './Forms/ChangeLogCapacity'
import storage from 'src/libraries/storage'
import superAdminDashboard from './superAdminDashboard.json'
import {
  CustomSlices,
  ICustomSlicesParams,
} from '../../../../components/dashboard/CustomSlices'

import styles from './styles/index.module.less'

export interface ILogProcessingProps extends IA10ContainerDefaultProps {
  form: any
  show: boolean
  isOConsole: boolean
  onClose: () => void
  getLogRateData?: () => void
}
export interface ILogProcessingState {
  showSlidingPage: boolean
  loadingIcon: boolean
  enableSave: boolean
  logCapacityObj: IObject
  timeStamp: any
}

const MODE = 'PROD'

class LogProcessing extends A10Container<
  ILogProcessingProps,
  ILogProcessingState
> {
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  childForm: any = null

  api = process.env.NODE_ENV === 'production' ? parameters.BASE_URL : ''
  auth = storage.get.ENCODED_SESSION_ID
  provider = storage.get.PROVIDER
  mode = 'PROD'

  constructor(props: any) {
    super(props)

    this.state = {
      showSlidingPage: props.show || false,
      loadingIcon: false,
      enableSave: false,
      logCapacityObj: {
        logCapacity: 0,
        maxCapacity: 0,
        logValue: 0,
        logUnit: '',
      },
      timeStamp: new Date().getTime(),
    }
  }

  componentWillReceiveProps(
    nextProps: Readonly<ILogProcessingProps>,
    nextContext: any,
  ): void {
    this.setState({ showSlidingPage: nextProps.show })
  }

  changeCapacity = () => {
    let logCapacityObj = this.state.logCapacityObj
    // const logRateObj = this.Utilities.kiloToMillionToBillion(logCapacityObj.logCapacity || 0)
    logCapacityObj.logValue = logCapacityObj.logCapacity
    logCapacityObj.sharedLogValue = logCapacityObj.sharedCapacity
    // logCapacityObj.logUnit = logRateObj.unit
    this.setState({
      logCapacityObj,
      showSlidingPage: true,
      enableSave: false,
      loadingIcon: false,
    })
  }

  handleFormValidation = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  handleChange = (logCapacityObj: any, enableSave?: boolean) => {
    this.setState({ logCapacityObj, enableSave: !enableSave ? false : true })
  }

  handleConfirmChange = (btnAction: string) => {
    if (btnAction === 'yes') {
      this.setState({ enableSave: true })
    } else if (btnAction === 'no') {
      this.handleCancel()
    } else {
      this.setState({ enableSave: false })
    }
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({ showSlidingPage: isOpen })
  }

  handleCancel = () => {
    this.setState({
      showSlidingPage: false,
      enableSave: false,
      loadingIcon: false,
    })
    this.props.onClose()
  }

  getLogValueForUnit = (value: number, unit: string) => {
    const metric = this.Utilities.metrics.quantity
    return (value || 0) / (unit !== '' ? metric[unit] : 1)
  }

  getLogCapacity = () => {
    const logProcessingRate = this.DashboardService.getLogProcessingCapacity(
      null,
      null,
      null,
    )
    logProcessingRate
      .then((resp: any) => {
        const logResp = resp && resp.data ? resp.data : {}
        const total = logResp['default_capacity'] || 0
        const allocated = logResp['capacity'] || 0
        const remaining =
          logResp['remaining_hc_capacity'] === undefined
            ? allocated
            : logResp['remaining_hc_capacity']
        const shared =
          logResp['shared_capacity'] === undefined
            ? 0
            : logResp['shared_capacity']
        const dedicated = allocated - remaining - shared
        const reserved = total - allocated

        const logRateObj = this.Utilities.kiloToMillionToBillion(allocated)
        const sharedLogValue = this.getLogValueForUnit(shared, logRateObj.unit)

        const logCapacityObj = {
          logCapacity: this.getLogValueForUnit(allocated, logRateObj.unit),
          maxCapacity: this.getLogValueForUnit(total, logRateObj.unit),
          sharedCapacity: sharedLogValue,
          remainingCapacity: this.getLogValueForUnit(
            remaining,
            logRateObj.unit,
          ),
          dedicatedCapacity: this.getLogValueForUnit(
            dedicated,
            logRateObj.unit,
          ),
          reservedValue: this.getLogValueForUnit(reserved, logRateObj.unit),
          logValue: logRateObj.value,
          logUnit: logRateObj.unit,
          sharedLogValue: sharedLogValue,
          total: total
        }

        this.setState({ logCapacityObj })
      })
      .catch((error: any) => {
        console.log('get Log capacity failed')
      })
  }

  handleSave = async () => {
    const{getLogRateData} = this.props
    const { logCapacityObj } = this.state
    const metric = this.Utilities.metrics.quantity
    const logUnitVal =
      logCapacityObj.logUnit !== '' ? metric[logCapacityObj.logUnit] : 1
    const logValue = logCapacityObj.logValue * logUnitVal
    const sharedLogValue = logCapacityObj.sharedLogValue * logUnitVal
    const totalAllocated =
      logCapacityObj.dedicatedCapacity + logCapacityObj.sharedCapacity
    const totalDedicated =
      logCapacityObj.logValue - logCapacityObj.dedicatedCapacity
    if (logCapacityObj.logValue > logCapacityObj.maxCapacity) {
      this.Utilities.showMessage(
        'Log capacity cannot exceed allowed max capacity ' +
          logCapacityObj.maxCapacity +
          ' ' +
          logCapacityObj.logUnit +
          '/Sec',
        0,
        0,
      )
      return
    }
    if (logCapacityObj.logValue < totalAllocated) {
      this.Utilities.showMessage(
        'Log capacity cannot be lesser than sum of shared & allocated capacity to providers - ' +
          totalAllocated +
          ' ' +
          logCapacityObj.logUnit +
          '/Sec',
        0,
        0,
      )
      return
    }
    if (!logCapacityObj.logValue) {
      this.Utilities.showMessage('ENTER_CAPACITY', 0, 1)
      return
    }

    if (logCapacityObj.sharedLogValue > totalDedicated) {
      this.Utilities.showMessage(
        'Shared log capacity cannot be greater than the difference of log limit & dedicated to providers ' +
          totalDedicated +
          ' ' +
          logCapacityObj.logUnit +
          '/Sec',
        0,
        0,
      )
      return
    }
    if (logCapacityObj.sharedLogValue < 0) {
      this.Utilities.showMessage(
        'Shared log capacity cannot be lesser than 0 ' +
          logCapacityObj.logUnit +
          '/Sec',
        0,
        0,
      )
      return
    }

    if (logCapacityObj.sharedCapacity != logCapacityObj.sharedLogValue) {
      this.setState({ loadingIcon: true })

      const payload = {
        providerId: 'shared_capacity',
        lograte: sharedLogValue || 0,
      }
      await this.DashboardService.updateProviderLogRate(null, payload, null)
        .then(response => {
          if (logCapacityObj.logCapacity == logCapacityObj.logValue) {
            this.Utilities.showMessage('Updated shared log capacity', 1, 0)
            getLogRateData && getLogRateData()
          }
        })
        .catch((error: any) => {
          this.Utilities.showMessage(
            'Error: Could not update shared log capacity',
            0,
            0,
          )
        })
    }

    if (logCapacityObj.logCapacity != logCapacityObj.logValue) {
      this.setState({ loadingIcon: true })

      await this.DashboardService.updateLogProcessingCapacity(
        null,
        logValue,
        null,
      )
        .then((resp: any) => {
          const sharedLogMsg =
            logCapacityObj.sharedCapacity != logCapacityObj.sharedLogValue
              ? ' shared and '
              : ''
          this.Utilities.showMessage(
            'Successfully updated ' + sharedLogMsg + 'log processing capacity',
            1,
            0,
          )
          getLogRateData && getLogRateData()
        })
        .catch((err: any) => {
          const msg =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
              ? err.response.data.message
              : ''
          this.Utilities.showMessage(
            'Error: Could not update log processing capacity',
            0,
            0,
            msg,
          )
        })
    }

    if (
      logCapacityObj.sharedCapacity != logCapacityObj.sharedLogValue ||
      logCapacityObj.logCapacity != logCapacityObj.logValue
    ) {
      this.getLogCapacity()
      this.setState({ timeStamp: new Date().getTime() })
    } else {
      this.Utilities.showMessage('Nothing changed to update', 2, 0)
    }
    this.handleCancel()
  }
  
  componentDidMount() {
    this.getLogCapacity()
  }

  renderCustomSlices = (argObj: ICustomSlicesParams) => {
    return <CustomSlices {...argObj} />
  }

  render() {
    const {
      showSlidingPage,
      loadingIcon,
      enableSave,
      logCapacityObj,
    } = this.state
    const { isOConsole } = this.props

    return (
      <div className={styles.logProcessingPage}>
        {!isOConsole && (
          <>
            <ContentSection>
              <ContentHeader
                className={styles.header}
                type="flex"
                align="middle"
                justify="space-between"
              >
                <A10Col>
                  <ContentTitle title="Log Processing" />
                </A10Col>
                <A10Col>
                  <ActionButton
                    text="Change Capacity"
                    onClick={this.changeCapacity}
                    iconProps={{ app: 'global', type: 'edit' }}
                  />
                </A10Col>
              </ContentHeader>
            </ContentSection>
            <DGF
              mode={MODE}
              dashboard={superAdminDashboard.dashboard}
              renderCustomSlices={this.renderCustomSlices}
            />
          </>
        )}

        <FormatSlidingPage
          isOpen={showSlidingPage}
          onRequestOk={this.handleFormValidation}
          onRequestClose={this.handleCancel}
          title="Change Analytics Log Processing Capacity"
          description="Log processing is a key part of your network analytics"
          disableSave={!enableSave || loadingIcon}
        >
          <>
            <ChangeLogCapacity
              logCapacityObj={logCapacityObj}
              handleChange={this.handleChange}
              onSubmitForm={this.handleSave}
              handleConfirmChange={this.handleConfirmChange}
              onRef={(ref: any): any => (this.childForm = ref)}
            />
            {loadingIcon ? (
              <div className="">
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </div>
            ) : null}
          </>
        </FormatSlidingPage>
      </div>
    )
  }
}

export default setupA10Container(LogProcessing)
