import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import { A10Row, A10Col, A10Input } from '@gui-libraries/widgets'

import { MyApp } from './MyApp'
import {
  DashboardService,
  OrganizationService,
  Utilities,
} from 'src/services/index'
import { IDefaultMethods } from 'src/containers/Controller'
import { AppRoot } from 'src/settings/appRoot'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'

import './styles/myapps.scss'

export interface IMyAppsProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  leftNavAppsRefresh: () => void
  tenantToggled: boolean
}
export interface IMyAppsState {
  myAppsUpdated: boolean
  searchString: string
  allMyApps: any
  appView: string
  tenantToggled: boolean
}

class MyApps extends A10Container<IMyAppsProps, IMyAppsState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  AppRoot = new AppRoot()
  OrganizationService = new OrganizationService()

  state: IMyAppsState = {
    myAppsUpdated: false,
    searchString: '',
    allMyApps: [],
    appView: 'tile',
    tenantToggled: this.props.tenantToggled,
  }
  dataLoaded = false
  allMyApps: any[] = []

  componentDidMount() {
    const {
      storage: { get },
    } = this.context

    if (
      get.CURRENT_STATE === 'apps' &&
      get.DRILL_LEVEL === 'tenant' &&
      (!this.dataLoaded ||
        this.state.tenantToggled !== this.props.tenantToggled)
    ) {
      this.loadMyApps()
    }
  }

  onUninstall = () => {
    this.props.leftNavAppsRefresh()
    this.loadMyApps()
  }

  loadMyApps = async () => {
    const {
      storage: { get, set },
    } = this.context

    const targetUrl = get.TARGET_URL

    if (!get.CURRENT_TENANT?.uuid) {
      const tenants = this.AppRoot.getRootScopeElement('allTenants')
      set.CURRENT_TENANT(tenants[0])
      this.AppRoot.setRootScopeElement('currentTenant', tenants[0])
    }
    if (!get.CURRENT_TENANT?.uuid) {
      return
    }

    this.setState({
      tenantToggled: this.props.tenantToggled,
    })

    try {
      const allMyApps = await this.Utilities.getLicensedApps(targetUrl)
      this.dataLoaded = true
      this.allMyApps = allMyApps
      this.setState({
        allMyApps: allMyApps,
      })
    } catch (e) {
      console.log(e) //we need to create a centralised error handling
      this.dataLoaded = true
    }
  }
  receiveUpdate = () => {
    this.dataLoaded = false
    this.setState({
      myAppsUpdated: true,
    })
  }

  SearchApps = (e: any) => {
    const searchString = e.target.value
    let allMyApps = this.allMyApps
    if (searchString !== '') {
      allMyApps = allMyApps.filter((key: any, index: number) => {
        if (
          key.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
          key.version.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
          (key.metadata &&
            key.metadata.comment &&
            key.metadata.comment
              .toLowerCase()
              .indexOf(searchString.toLowerCase()) > -1)
        ) {
          return key
        }
      })
    }

    this.setState({
      allMyApps: allMyApps,
      searchString: searchString,
    })
  }

  appViewChange = (view: string) => {
    this.setState({
      appView: view,
    })
  }

  render() {
    return (
      <ContentSection className="apps">
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col>
            <ContentTitle title="Harmony Apps" />
          </A10Col>
          <A10Col></A10Col>
        </ContentHeader>
        <ContentBody>
          <A10Row gutter={[14, 14]} type="flex" align="stretch">
            {this.state.allMyApps.map((app: IObject) => {
              return (
                <MyApp
                  key={`${app.name}-${app.version}`}
                  app={app}
                  appView={this.state.appView}
                  defaultMethods={this.props.defaultMethods}
                  onUninstall={this.onUninstall}
                />
              )
            })}
          </A10Row>
        </ContentBody>
      </ContentSection>
    )
  }
}

export default setupA10Container(MyApps)
