import React, { useCallback, useState } from 'react'
import {
  IA10ContainerDefaultProps,
  axios,
  useAsyncEffect,
} from '@gui-libraries/framework'
import {
  A10Alert,
  A10Button,
  A10Col,
  A10DropdownMenu,
  A10Icon,
  A10Input,
  A10Message,
  A10Modal,
} from '@gui-libraries/widgets'

import ImageList from '../ImageList'
import SlidingUpload from '../UploadForm/SlidingUpload'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import {
  ContentHeader,
  ContentBody,
  ContentTitle,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import SlidingUpgrade from '../UpgradeForm/SlidingUpgrade'
import parameters from 'parameters'
import { Utilities } from 'src/services/Utilities'
import { formatBytes } from 'src/libraries/stringUtils'
import moment from 'moment'
import SlidingWorkflowStatusList from 'src/components/shared/WorkflowStatus/slidingWorkflowListForm'

// tslint:disable-next-line:no-var-requires
const styles = require('./style/index.module.less')

interface IDeviceImagesProps extends IA10ContainerDefaultProps {
  data: any[]
}

const utilities = new Utilities()

const DeviceImages: React.FC<IDeviceImagesProps> = props => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showAddOrEditSlidingPage, setShowAddOrEditSlidingPage] = useState<
    boolean
  >(false)
  const [showSlidingPage, setShowSlidingPage] = useState<boolean>(false)
  const [deviceLevelUpgrade, setDeviceLevelUpgrade] = useState<IObject>({
    deviceLevelUpgrade: false,
    fileName: '',
  })

  const [alert, setAlert] = useState<IObject>({
    showAlert: false,
    alertType: '',
  })

  const [imageState, setImageState] = useState<IObject>({
    imageCount: 0,
    search: '',
    filteredImageList: [],
    imageList: [],
  })

  const [systemImages, setSystemImages] = useState<IObject>({
    imageCount: 0,
    search: '',
    filteredImageList: [],
    imageList: [],
  })

  const [deleteFile, setDeleteFile] = useState<IObject>({
    deleteModalState: false,
    fileName: '',
  })

  const [workflow, setWorkflow] = useState<IObject>({
    workflowIDList: [],
    objectList: [],
    showWorkflowStatusSlidingPage: false,
  })

  useAsyncEffect(async () => {
    await getData()
  }, [])

  const showImageSlidingPage = useCallback(() => {
    setShowAddOrEditSlidingPage(true)
  }, [])

  const closeImageSlidingPage = useCallback(() => {
    setShowAddOrEditSlidingPage(false)
  }, [])

  const getData = useCallback(async () => {
    // Start to load data
    setIsLoading(true)

    try {
      const actionURL = `/hpcapi/v3/provider/${storage.get.PROVIDER}/device-image`
      const { data: res } = await httpClient.get(actionURL)
      let imageList = res['device-image-list'] || []

      imageList = imageList
        .sort((a, b) => utilities.sortNumber(a, b, 'created-on'))
        .reverse()
      setImageState({
        ...imageState,
        imageCount: imageList?.length,
        imageList: imageList,
        filteredImageList: imageList,
      })
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err)
    } finally {
      setIsLoading(false)
    }

    //get system uploaded images
    try {
      const baseUrl = parameters.BASE_URL
      const actionURL = `${baseUrl}/hpcapi/v3/image-catalog/?published_status=Yes`
      const { data: res } = await httpClient.get(actionURL)
      let imageList = res['device_image_catalog_list'] || []
      imageList = imageList
        .sort((a, b) => utilities.sortNumber(a, b, 'created-on'))
        .reverse()
      setSystemImages({
        ...systemImages,
        imageCount: imageList?.length,
        imageList: imageList,
        filteredImageList: imageList,
        search: '',
      })
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const onSearchText = (e: any) => {
    const searchString = e.target.value ?? ''
    const searchStr = searchString?.toString().toLowerCase()

    const filteredImageList = imageState.imageList.filter(
      (deviceImage: IObject) => {
        const { name, version, description } = deviceImage

        return (
          name?.toLowerCase().includes(searchStr) ||
          version?.toLowerCase().includes(searchStr) ||
          description?.toLowerCase().includes(searchStr)
        )
      },
    )
    setImageState({
      ...imageState,
      imageState: imageState.imageList,
      filteredImageList: filteredImageList,
      search: searchString,
    })
  }

  const onSystemSearchText = (e: any) => {
    const searchString = e.target.value ?? ''
    const searchStr = searchString?.toString().toLowerCase()
    const filteredImageList = systemImages.imageList.filter(
      (deviceImage: IObject) => {
        const { name, version, description } = deviceImage
        return (
          name?.toLowerCase().includes(searchStr) ||
          version?.toLowerCase().includes(searchStr) ||
          description?.toLowerCase().includes(searchStr)
        )
      },
    )

    setSystemImages({
      ...systemImages,
      filteredImageList: filteredImageList,
      search: searchString,
    })
  }

  const uploadImageFile = useCallback(async (payload, file) => {
    closeImageSlidingPage()

    const provider = storage.get.PROVIDER
    const payloadToBlob = new Blob([JSON.stringify(payload)], {
      type: 'application/json',
    })
    const formData = new FormData()
    formData.append('metadata', payloadToBlob)
    formData.append('file', file, file.name)
    const url = `/providers/${provider}/file`
    const cancelTokenSrc = axios.CancelToken.source()

    try {
      setAlert({
        alertType: 'info',
        showAlert: true,
      })

      //refresh images so that in-progress image status is reflected.
      setTimeout(() => {
        getData()
      }, 2000)

      const { data: response, status: statusCode } = await httpClient.post(
        url,
        formData,
        {
          absoluteBasePath: true,
          size: file.size,
          cancelToken: cancelTokenSrc.token,
        },
      )

      setAlert({
        alertType: 'success',
        showAlert: true,
      })
    } catch (err) {
      setAlert({
        alertType: 'error',
        showAlert: true,
      })
    } finally {
      getData()
    }
  }, [])

  const renderMessage = useCallback(() => {
    switch (alert.alertType) {
      case 'info': {
        return (
          <div>
            <A10Icon
              app="global"
              type="loading"
              style={{
                width: '100%',
                height: 22,
              }}
            />
            <span
              style={{
                marginLeft: '10px',
              }}
            >
              Image upload in progress...{' '}
            </span>
          </div>
        )
      }
      case 'success': {
        return <div>Successfully uploaded image</div>
      }
      case 'error': {
        return <div>Failed to upload image</div>
      }
      default: {
        return null
      }
    }
  }, [alert.alertType])

  const onCloseAlert = useCallback(() => {
    setAlert({
      alertType: 'info',
      showAlert: true,
    })
  }, [])

  const getColumns = useCallback((systemImages = false) => {
    let columns = [
      {
        title: 'ACOS Version',
        dataIndex: 'version',
        key: 'version',
        sorter: (a: any, b: any) => utilities.sortString(a, b, 'name'),
        width: '20%',
      },
      {
        title: 'ACOS Image',
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => utilities.sortString(a, b, 'name'),
        width: systemImages ? '31%' : '23%',
      },
      {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        sorter: (a: any, b: any) => utilities.sortNumber(a, b, 'size'),
        render: (record: any) => {
          return <span>{formatBytes(record)}</span>
        },
        width: '8%',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (record: any) => {
          return <span> {record}</span>
        },
        sorter: (a: any, b: any) => utilities.sortString(a, b, 'status'),
        width: '12%',
      },
      {
        title: 'Date Uploaded',
        dataIndex: 'created-on',
        key: 'created-on',
        sorter: (a: any, b: any) => utilities.sortNumber(a, b, 'created-on'),
        render: (text: string, record: any) => {
          return <span>{text ? moment.unix(text).format('lll') : ''}</span>
        },
        width: '15%',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a: any, b: any) => utilities.sortString(a, b, 'description'),
        width: systemImages ? '29%' : '22%',
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (text: string, record: any) => {
          return (
            <div className={styles.editColumn}>
              {storage.get.ADMIN_LEVEL === 'provider' &&
              !storage.get.IS_OPERATOR_USER ? (
                <i>
                  <A10DropdownMenu
                    menu={
                      systemImages
                        ? [
                            <div key="clusterUpgrade">Cluster Upgrade</div>,
                            <div key="deviceUpgrade">Device Upgrade</div>,
                          ]
                        : record['status'] === 'Uploaded'
                        ? [
                            <div key="clusterUpgrade">Cluster Upgrade</div>,
                            <div key="deviceUpgrade">Device Upgrade</div>,
                            <div key="delete">Delete</div>,
                          ]
                        : [<div key="delete">Delete</div>]
                    }
                    title=""
                    style={{
                      color: '#757575',
                      marginBottom: '-15px',
                    }}
                    onClick={onClickAction.bind(this, record)}
                    trigger="hover"
                    placement="bottomRight"
                    arrowPointAtCenter={true}
                  />
                </i>
              ) : null}
            </div>
          )
        },
      },
    ]

    if (systemImages) {
      columns.splice(3, 1)
    }
    return columns
  }, [])

  const onClickAction = useCallback((record: any, component: JSX.Element) => {
    switch (component.key) {
      case 'delete': {
        setDeleteFile({
          deleteModalState: true,
          fileName: record['name'],
        })
        break
      }
      case 'clusterUpgrade': {
        setDeviceLevelUpgrade({
          ...deviceLevelUpgrade,
          fileName: record.name,
          deviceLevelUpgrade: false,
        })
        setShowSlidingPage(true)
        break
      }
      case 'deviceUpgrade': {
        setDeviceLevelUpgrade({
          ...deviceLevelUpgrade,
          fileName: record.name,
          deviceLevelUpgrade: true,
        })
        setShowSlidingPage(true)
        break
      }
      default: {
      }
    }
  }, [])

  const handleOk = useCallback(
    async (e: React.MouseEvent<any>) => {
      try {
        await httpClient.delete(
          `/hpcapi/v3/provider/${storage.get.PROVIDER}/device-image/${deleteFile.fileName}`,
          {
            absoluteBasePath: true,
            bigFileDelete: true,
          },
        )
        getData()
        A10Message.success('Success: Image deleted successfully', 5)
      } catch (e) {
        // do nothing
        A10Message.error('Error: Failed to delete the device image', 5)
      } finally {
        setDeleteFile({
          fileName: '',
          deleteModalState: false,
        })
      }
    },
    [deleteFile],
  )

  const handleCancel = useCallback((e: React.MouseEvent<any>) => {
    setDeleteFile({
      fileName: '',
      deleteModalState: false,
    })
  }, [])

  const onCloseWorkflowStatusWindow = useCallback(() => {
    setWorkflow({
      workflowIDList: [],
      objectList: [],
      showWorkflowStatusSlidingPage: false,
    })
  }, [])

  const closeUpgradeSlidingPage = useCallback(
    async (idList?: string[], objectList?: string[]) => {
      if (Array.isArray(idList) && idList.length > 0) {
        try {
          setTimeout(async () => {
            const workflowIDList = await Promise.all(
              idList.map(async id => {
                const { data: res } = await httpClient.get(
                  `/scheduler/v1/job-result/${id}`,
                  {
                    absoluteBasePath: true,
                  },
                )
                const workflowID =
                  res.job.results[0].result.workflow['workflow-id']
                return workflowID
              }),
            )
            setWorkflow({
              workflowIDList,
              objectList,
              showWorkflowStatusSlidingPage: true,
            })
          }, 1000)
        } catch (err) {
          console.error(err)
        }
      }
      setShowSlidingPage(false)
    },
    [],
  )

  return (
    <>
      {alert.showAlert ? (
        <A10Alert
          style={{
            marginBottom: '5px',
          }}
          description={renderMessage()}
          closable={true}
          message=""
          type={alert.alertType}
          onClose={onCloseAlert}
        />
      ) : null}
      <ContentHeader
        type="flex"
        align="middle"
        justify="space-between"
        className={styles.sectionHeader}
      >
        <A10Col>
          <ContentTitle title="System Images" count={systemImages.imageCount} />
        </A10Col>
        <A10Col className={styles.actionsContainer}>
          <A10Input.Search
            type="text"
            onChange={onSystemSearchText}
            name="searchBox"
            value={systemImages.search}
            placeholder="Search"
            className={styles.searchInput}
          />
        </A10Col>
      </ContentHeader>
      <ContentBody>
        <ImageList
          isLoading={isLoading}
          updateList={getData}
          data={systemImages.filteredImageList}
          columns={getColumns(true)}
        />
      </ContentBody>
      <div className={styles.imageSection}>
        <ContentHeader
          type="flex"
          align="middle"
          justify="space-between"
          className={styles.sectionHeader}
        >
          <ContentTitle
            title="User Uploaded Images"
            count={imageState.imageCount}
          />
          <A10Col className={styles.actionsContainer}>
            <A10Input.Search
              type="text"
              onChange={onSearchText}
              name="searchBox"
              value={imageState.search}
              placeholder="Search"
              className={styles.searchInput}
            />
            {storage.get.ADMIN_LEVEL === 'provider' &&
              !storage.get.IS_OPERATOR_USER && (
                <ActionButton
                  text="Upload"
                  onClick={showImageSlidingPage}
                  iconProps={{
                    app: 'global',
                    type: 'add-new',
                  }}
                />
              )}
          </A10Col>
        </ContentHeader>
        <ContentBody>
          <ImageList
            isLoading={isLoading}
            updateList={getData}
            data={imageState.filteredImageList}
            columns={getColumns(false)}
          />
        </ContentBody>
      </div>
      <SlidingUpload
        isOpen={showAddOrEditSlidingPage}
        onRequestClose={closeImageSlidingPage}
        onRequestOk={uploadImageFile}
      />
      <SlidingUpgrade
        isOpen={showSlidingPage}
        imageName={deviceLevelUpgrade.fileName}
        deviceLevelUpgrade={deviceLevelUpgrade.deviceLevelUpgrade}
        onRequestClose={closeUpgradeSlidingPage}
      />
      <SlidingWorkflowStatusList
        isOpen={
          workflow.showWorkflowStatusSlidingPage &&
          workflow.workflowIDList.length > 0
        }
        title={'Image Upgrade'}
        onRequestClose={onCloseWorkflowStatusWindow}
        idList={workflow.workflowIDList}
        objectList={workflow.objectList}
      />
      <A10Modal
        title="Confirmation"
        visible={deleteFile.deleteModalState}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <A10Button
            key="no"
            type="primary"
            onClick={handleCancel}
            className="nobtn"
          >
            No
          </A10Button>,
          <A10Button
            key="yes"
            type="default"
            onClick={handleOk}
            className="yesbtn"
          >
            Yes
          </A10Button>,
        ]}
      >
        <p>Are you sure you want to delete this image?</p>
      </A10Modal>
    </>
  )
}

export default DeviceImages
