import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import ReactLoading from 'react-loading'
import InfiniteScroll from 'react-infinite-scroller'
import Immutable, { List } from 'immutable'
import { AxiosStatic, AxiosResponse } from 'axios'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import {
  A10Checkbox,
  A10Tree,
  A10Icon,
  A10DropdownMenu,
  A10Modal,
  A10Message,
  A10Notification,
  A10Tooltip,
  A10Spin,
  A10Input,
} from '@gui-libraries/widgets'
import {
  setUpAutoConfig,
  WafTemplateForm,
  AutoForm,
  AutoFormV15,
  FileForm,
  RuleListForm,
} from '@gui-libraries/apps'
import { WafContext } from '@gui-libraries/apps/dist/containers/Security/Waf/context'
import { CheckboxChangeEvent } from '@gui-libraries/widgets/node_modules/antd/lib/checkbox'

import StatusBar from 'src/components/ADC/StatusBar'
import * as basicUtils from 'src/containers/Controller/Dashboard/utils/basicUtils'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import OEKeyMappingJson from '../../OEKeyMappingJson'
import menuJson from '../../menuJson'
import { enableReferenceFieldList, sslTypeMapList } from '../common'

import './styles/index.less'

const { Search } = A10Input
const { TreeNode } = A10Tree
const VIRTUAL_SERVER = 'virtual-server'

export interface IObjectTreeState {
  slidingPage: IObject
  dataList: IObject
  lpVersion: number
  dpVersion: number
  count: number
  searchValue: string
  autoExpandParent: boolean
  listQuantity: IObject
  currentMenuJson: IObject
  enableForceDelete: boolean
  isUpdateMode: boolean
  apiPrefix: string
  apiPrefixForObjAsso: string
}

export interface IObjectTreeProps extends IA10ContainerDefaultProps {
  baseKey: string
  onExpandTree: (expandedKeys: List<string>, treeNodeEvent: any) => void
  onSelectTree: () => void
  expandedKeys: string[]
  eventKey: string
  isLoading: boolean
  isLogicalPartition: boolean
  isDevicePartition: boolean
  isClusterPartition: boolean
  partitionType: string
  isRenderCustomizedTree: boolean
  appType: string
  pageCount?: number
  onChangeAppType?: (appType: string) => {}
  onObjectChange: () => {}
  selectedPartition: IObject
}

class ObjectTree extends A10Container<IObjectTreeProps, IObjectTreeState> {
  static contextTypes = {
    openFormatSlidingPage: PropTypes.func.isRequired,
    closeFormatSlidingPage: PropTypes.func.isRequired,
    openFormPage: PropTypes.func.isRequired,
    closeFormPage: PropTypes.func.isRequired,
    openListPage: PropTypes.func.isRequired,
    closeListPage: PropTypes.func.isRequired,
    setModalSize: PropTypes.func,
  }

  constructor(props: IObjectTreeProps) {
    super(props)
    this.state = {
      slidingPage: {
        isOpen: false,
      },
      dataList: {},
      lpVersion: 1,
      dpVersion: 1,
      count: props.pageCount || 25,
      listQuantity: {},
      searchValue: '',
      autoExpandParent: false,
      currentMenuJson: menuJson,
      enableForceDelete: false,
      isUpdateMode: false,
      apiPrefix: '',
      apiPrefixForObjAsso: '',
    }
  }

  onCancelForm = () => {
    this.context.closeFormPage()
  }

  getImage(item: IObject) {
    try {
      return (
        <img
          style={{ width: '19px', height: '19px' }}
          src={require(`./images/${item.title
            .replace(/[\s]+/g, '-')
            .toLowerCase()}.svg`)}
        />
      )
    } catch {
      return null
    }
  }
  getApiPrefix = () => {
    const {
      isLogicalPartition,
      isDevicePartition,
      isClusterPartition,
      selectedPartition = {},
    } = this.props
    let apiPrefix = ''
    let apiPrefixForObjAsso = ''
    if (isLogicalPartition) {
      apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${storage.get['object.explorer.tenant.text']}/logical-partition/${storage.get['object.explorer.lp.name']}/`
      const lpDetail = storage.get['object.explorer.lp.detail']
      if (lpDetail.cluster && lpDetail.partition) {
        apiPrefixForObjAsso = `/hpcapi/v3/provider/${storage.get.PROVIDER}/cluster/${lpDetail.cluster}/partition/${lpDetail.partition}/`
      }
    } else if (isDevicePartition) {
      apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/device/${storage.get['object.explorer.device.text']}/partition/${storage.get['object.explorer.partition.name']}/`
    } else if (isClusterPartition && selectedPartition) {
      apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/cluster/${selectedPartition.clusterName}/partition/${selectedPartition.name}/`
    } else {
      apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${storage.get['object.explorer.tenant.text']}/shared-object/`
    }
    return { apiPrefix, apiPrefixForObjAsso }
  }

  onSubmitSuccessForm = (
    item: IObject = {},
    actionType?: string,
    isUpdateMode: boolean = false,
  ) => {
    return async () => {
      this.context.closeFormPage()
      if (
        actionType !== 'cancel' &&
        !this.state.isUpdateMode &&
        !isUpdateMode
      ) {
        await this.updateQuantity(item, 'increase')
      }

      if (item.customizedPage && item.customizedPage === 'portal') {
        await this.onOpenList({
          title: 'Portal',
          lineage: ['aam.authentication.portal'],
          uiJsonPath: 'aam/auth-clients/portal/portal',
          customizedPage: 'portal',
          menus: [
            {
              title: 'Portal Logon',
              onlyEditMode: true,
              customizedPage: 'portal',
              defaultParams: {
                name: 'default-portal',
              },
              lineage: ['aam.authentication.portal.logon'],
              uiJsonPath: 'aam/auth-clients/portal/logon',
            },
            {
              title: 'Portal Logon Fail',
              onlyEditMode: true,
              customizedPage: 'portal',
              defaultParams: {
                name: 'default-portal',
              },
              lineage: ['aam.authentication.portal.logon-fail'],
              uiJsonPath: 'aam/auth-clients/portal/logon-fail',
            },
            {
              title: 'Portal Change Password',
              onlyEditMode: true,
              customizedPage: 'portal',
              defaultParams: {
                name: 'default-portal',
              },
              lineage: ['aam.authentication.portal.change-password'],
              uiJsonPath: 'aam/auth-clients/portal/change-password',
            },
            {
              title: 'Notify Change Password',
              onlyEditMode: true,
              customizedPage: 'portal',
              defaultParams: {
                name: 'default-portal',
              },
              lineage: ['aam.authentication.portal.change-password'],
              uiJsonPath: 'aam/auth-clients/portal/notify-change-password',
            },
          ],
        })
      } else {
        await this.onOpenList(item)
      }
      this.props.onObjectChange()
      this.setState({ isUpdateMode: false })
    }
  }

  onCheckForceDelete = (e: CheckboxChangeEvent) => {
    this.setState({ enableForceDelete: e.target.checked })
  }

  getPrefix = (
    actionType: string,
    isDevicePartition: boolean,
    isLogicalPartition: boolean,
    isClusterPartition: boolean,
    schemaPath: string = '',
  ) => {
    const ADD = 'add'
    const { apiPrefix, apiPrefixForObjAsso } = this.state
    if (actionType === ADD) {
      let refApiPrefix = ''

      if (isLogicalPartition && enableReferenceFieldList.includes(schemaPath)) {
        refApiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/pso/logical-partition/pso/`
      }

      return [apiPrefix, apiPrefixForObjAsso, refApiPrefix]
    } else {
      return [apiPrefix, apiPrefixForObjAsso]
    }
  }

  onAdd = (
    name: string,
    lineage: string,
    item: IObject = { lineage: [''] },
    APIResponse: IObject = {},
    isUpdate: boolean = false,
  ) => () => {
    const {
      isLogicalPartition,
      isDevicePartition,
      isClusterPartition,
    } = this.props
    const { isUpdateMode } = this.state

    const {
      defaultParams = {},
      uiJsonPath,
      lineage: itemLineage = [],
      fileType,
      title,
      modalSize,
      viewMode,
      onlyEditMode,
    } = item

    defaultParams.provider = storage.get.PROVIDER
    defaultParams.tenant = storage.get['object.explorer.tenant.text']

    const schemaPath = uiJsonPath || `config/form/${lineage}`
    const [apiPrefix, apiPrefixForObjAsso] = this.getPrefix(
      'add',
      isDevicePartition,
      isLogicalPartition,
      isClusterPartition,
      schemaPath,
    )
    const Form = uiJsonPath ? AutoFormV15 : AutoForm

    const names = OEKeyMappingJson[lineage]
    const PLUS = '+'
    if (isUpdateMode && names && names.length > 0) {
      names.forEach((value: string) => {
        if (value.includes(PLUS)) {
          value.split(PLUS).map((subValue: string) => {
            // turn "{test}" into "test"
            const property = subValue.slice(1, subValue.length - 1)
            if (APIResponse[property] === 0 || APIResponse[property]) {
              defaultParams[property] = APIResponse[property]
            }
          })
        } else {
          if (APIResponse[value] === 0 || APIResponse[value]) {
            defaultParams[value] = APIResponse[value]
          }
        }
      })
    }

    if (itemLineage[0] === 'waf.template') {
      const wafContext = {
        apiPrefix: `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${storage.get['object.explorer.tenant.text']}/logical-partition/${storage.get['object.explorer.lp.name']}/`,
        httpClient: httpClient as AxiosStatic,
        getLPObject: () => '',
        getHelpPage: (key: string) => '',
        openSlidingPage: _.noop,
        closeSlidingPage: _.noop,
        enableSOP: true,
      }
      let nameProps = {}
      if (name) {
        nameProps = {
          name,
        }
      }
      const slidingForm = (
        <WafContext.Provider value={wafContext}>
          <WafTemplateForm
            {...nameProps}
            onRequestClose={this.onCancelForm}
            onRequestOk={(resolved: IObject) => {
              // Success Case
              this.onSubmitSuccessForm(item)()
            }}
          />
        </WafContext.Provider>
      )

      this.context.openFormPage(slidingForm, 'large')
      return
    }

    if (fileType) {
      this.context.openFormPage(
        <FileForm
          title={title}
          fileType={fileType}
          hccEnv={true}
          // refApiPrefix={refApiPrefix}
          apiprefix={apiPrefix}
          apiPrefixForObjAsso={apiPrefixForObjAsso}
          types={['local', 'definition']}
          params={{ name }}
          disableNotification={false}
          enableSOP={true}
          onSubmit={(
            response: AxiosResponse,
            formData: FormData,
            description: string,
          ) => {
            this.onSubmitSuccessForm(item)()
          }}
          onClose={this.onCancelForm}
          viewMode={APIResponse?.['obj-href']}
        />,
        modalSize,
      )
    } else {
      const interceptor: IObject = {
        onSubmitSuccess: (core: IObject, response: AxiosResponse | boolean) => {
          if (response !== false) {
            this.onSubmitSuccessForm(item, '', isUpdate)()
          }
        },
        // using onSubmitSuccessForm istead of onCancel
        // onSubmitSuccessForm includes the path that customized form
        onCancel: this.onSubmitSuccessForm(item, 'cancel'),
      }

      if (uiJsonPath === 'system/global') {
        interceptor.onGetInitData = async () => {
          const payload = {
            'object-url': `${apiPrefix}interface/management`,
            method: 'GET',
            'object-payload': {},
          }
          try {
            const response = await httpClient.post(
              `${apiPrefix}_deploy/`,
              payload,
            )
            const data = response.data[0]
            return data[Object.keys(data)[0]]
          } catch (err) {
            return Promise.reject(err)
          }
        }
      }
      const formIsViewMode =
        (viewMode && (isUpdateMode || onlyEditMode)) ||
        APIResponse?.['obj-href']

      const closeSliding = () => {
        this.context.closeFormPage()
        this.onOpenList(item)
      }

      if (uiJsonPath === 'cgn/lsn/lsn-rule-list') {
        this.context.openFormPage(
          <RuleListForm
            apiPrefix="/hpcapi/v3"
            provider={storage.get.PROVIDER}
            tenant={storage.get['object.explorer.tenant.text']}
            logicalPartition={storage.get['object.explorer.lp.name']}
            name={name ?? ''}
            enableSOP={true}
            closeSliding={closeSliding}
          />,
        )
      } else {
        this.context.openFormPage(
          <Form
            schemaPath={schemaPath}
            hccEnv={true}
            apiPrefix={apiPrefix}
            // refApiPrefix={refApiPrefix}
            apiPrefixForObjAsso={apiPrefixForObjAsso}
            params={defaultParams}
            disableNotification={false}
            enableSOP={true}
            interceptor={interceptor}
            viewMode={formIsViewMode}
          />,
          modalSize,
        )
      }
    }
  }

  onListAction = (
    name: string,
    lineage: string,
    valueJson: string,
    APIResponse: IObject,
    item: IObject = {},
    onlyDel: boolean = false,
  ) => {
    const { onlyDelete, disableDel, viewMode } = item
    const menuEdit = (
      <div
        key="edit"
        id="goe-tree-node-edit"
        onClick={() => {
          this.setState(
            { isUpdateMode: true },
            this.onAdd(name, lineage, item, APIResponse),
          )
        }}
      >
        Edit
      </div>
    )
    const menuView = (
      <div
        key="view"
        id="goe-tree-node-view"
        onClick={() => {
          this.setState(
            { isUpdateMode: true },
            this.onAdd(name, lineage, item, APIResponse),
          )
        }}
      >
        View
      </div>
    )
    const menuDelete = (
      <div
        key="delete"
        id="goe-tree-node-delete"
        onClick={this.onDelete(name, lineage, valueJson, item, APIResponse)}
      >
        Delete
      </div>
    )
    const onlyDeleteOption = onlyDel || onlyDelete
    const onlyEditOption = disableDel
    const menu = onlyDeleteOption
      ? [menuDelete]
      : onlyEditOption
      ? [menuEdit]
      : viewMode || APIResponse?.['obj-href']
      ? [menuView, menuDelete]
      : [menuEdit, menuDelete]

    return <A10DropdownMenu title="" menu={menu} trigger="hover" />
  }

  onDelete = (
    name: string,
    lineage: string,
    valueJson: string,
    item: IObject = {},
    APIResponse: IObject,
  ) => {
    return () => {
      this.setState({ enableForceDelete: false })
      A10Modal.confirm({
        title: 'Confirmation',
        content: (
          <>
            <p>'Do you want to delete the selected item?'</p>
            <A10Checkbox onChange={this.onCheckForceDelete}>
              Force Delete
            </A10Checkbox>
          </>
        ),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: this.deleteItem(name, lineage, valueJson, item, APIResponse),
      })
    }
  }

  deleteItem = (
    name: string,
    lineage: string,
    valueJson: string,
    item: IObject = {},
    APIResponse: IObject,
  ) => async () => {
    const { enableForceDelete, apiPrefix } = this.state

    if (item.fileType) {
      let jsonTitle = item.fileType
      // const payload = {}
      let deleteAPI = apiPrefix + `file/${item.fileType}`
      if (item.fileType === 'ssl') {
        jsonTitle = 'ssl-' + name.split(' :')[0].toLowerCase()
        deleteAPI = apiPrefix + `file/${jsonTitle}`
        const certName = name.split(' :')[1]
        deleteAPI += '/' + certName
      } else {
        deleteAPI += '/' + name
      }
      deleteAPI += '?action=deploy'
      if (enableForceDelete) {
        deleteAPI += '&force=true'
      }
      try {
        await httpClient.delete(deleteAPI)
        A10Message.success(`Delete ${name} successfully.`)
        this.setState({ enableForceDelete: false })
        await this.updateQuantity(item, 'decrease')
        await this.onOpenList(item)
        this.props.onObjectChange()
      } catch (error) {
        A10Notification.error({
          message: 'Failure!',
          description: _.get(error, 'response.data.message.logs', error),
          style: {
            wordWrap: 'break-word',
          },
        })
      }
    } else {
      let deleteAPI =
        APIResponse['a10-url'].replace('+', '%2B') + '?action=deploy'
      if (enableForceDelete) {
        deleteAPI += '&force=true'
      }
      try {
        await httpClient.delete(deleteAPI)
        A10Message.success(`Delete ${name} successfully.`)
        this.setState({ enableForceDelete: false })
        await this.onOpenList(item)
        this.props.onObjectChange()
      } catch (error) {
        A10Notification.error({
          message: 'Failure!',
          description: _.get(error, 'response.data.message.logs', error),
          style: {
            wordWrap: 'break-word',
          },
        })
      }
    }
  }

  getDataFormHttpReuqest = async (lineage: string, item: IObject) => {
    const { apiPrefix } = this.state
    if (item.fileType === 'ssl') {
      const dataList = {}
      const promiseAll: any = []
      dataList[`${lineage}-list`] = []
      item.lineage.forEach((fileLineage: string) => {
        const prefixFileList = fileLineage.slice(5)
        const tmp = httpClient
          .get(`${apiPrefix}${fileLineage.replace(/\./g, '/')}`)
          .then((result: IObject) => {
            if (result.data) {
              result.data[`${prefixFileList}-list`].map((file: IObject) => {
                file.name = `${sslTypeMapList[prefixFileList]} :${file.name}`
                return file
              })
              dataList[`${lineage}-list`] = _.concat(
                dataList[`${lineage}-list`],
                result.data[`${prefixFileList}-list`],
              )
            }
          })
        promiseAll.push(tmp)
      })
      return Promise.all(promiseAll).then(() => {
        return new Promise(resolve => {
          const data = {}
          const prefixLineage = lineage.slice(5)
          data[`${prefixLineage}-list`] = dataList[`${lineage}-list`]
            ? dataList[`${lineage}-list`]
            : dataList
          resolve({ data })
        })
      })
    } else {
      return httpClient.get(`${apiPrefix}${lineage.replace(/\./g, '/')}`)
    }
  }

  updateVirtualServerSeriesQuantity = (
    listQuantity: IObject,
    list: IObject[],
  ) => {
    const PATH_PREFIX = 'adc/slb/'
    listQuantity[`${PATH_PREFIX}${VIRTUAL_SERVER}s`] = list.length

    let counter = 0
    list.forEach(member => {
      const { 'port-list': portList = [] } = member
      counter += portList.length
    })
    listQuantity[`${PATH_PREFIX}virtual-services`] = counter

    return listQuantity
  }

  fetchQuantities = async (item: IObject | IObject[], keys?: string[]) => {
    const { listQuantity } = this.state
    let quantityToBeUpdated = {}

    let targetMenu: IObject[] = []
    const itemLength = item?.length
    const keysLength = keys?.length
    if (itemLength > 0 && keysLength > 0) {
      const { expandedKeys } = this.props
      const keyOfLatestOpenNode = keys.find(key => {
        return !expandedKeys.includes(key)
      })

      if (
        keyOfLatestOpenNode &&
        keyOfLatestOpenNode.includes('global-obj-tree')
      ) {
        const sequence = keyOfLatestOpenNode.split('-')
        // remove "global-obj-tree"
        sequence.splice(0, 3)
        targetMenu = item as IObject[]
        sequence.forEach(element => {
          const subMenu = targetMenu[element]
          const { menus } = subMenu
          targetMenu = menus
        })
      }
    } else {
      const { menus = [] } = item as IObject
      targetMenu = menus
    }

    for (let i = 0, maxI = targetMenu.length; i < maxI; i += 1) {
      const element = targetMenu[i]

      if (element === null) {
        continue
      }

      const isLeaf = !element.menus || element.customizedPage === 'portal'
      const isExist = this.filterNode(element)
      if (isLeaf && isExist) {
        const { partitionType, appType } = this.props
        const lineage = element.lineage ? element.lineage[0] : ''
        const disableNode =
          element?.should_disable?.partition?.includes(partitionType) ||
          (appType &&
            element?.should_disable?.partition_app_type?.includes(appType))
        const response: IObject = !disableNode
          ? await this.getDataFormHttpReuqest(lineage, element)
          : {}
        const { data = {} } = response
        let list: IObject[] = []
        _.forIn(data, value => {
          if (Array.isArray(value)) {
            list = value
          }
        })

        if (list.length > 0) {
          if (lineage !== `slb.${VIRTUAL_SERVER}`) {
            quantityToBeUpdated[lineage] = list.length
          } else {
            quantityToBeUpdated = this.updateVirtualServerSeriesQuantity(
              quantityToBeUpdated,
              list,
            )
          }
        }
      }
    }

    this.setState({
      listQuantity: {
        ...listQuantity,
        ...quantityToBeUpdated,
      },
    })
  }

  updateQuantity = async (
    item: IObject = {},
    mode: string,
    response: IObject = {},
  ) => {
    let { listQuantity } = this.state
    const lineage = item.lineage ? item.lineage[0] : ''
    const basicObjectLineage = lineage.slice(lineage.lastIndexOf('.') + 1)

    if (basicObjectLineage === VIRTUAL_SERVER) {
      response = await this.getDataFormHttpReuqest(lineage, item)
    }

    const { data = {} } = response
    let list: IObject[] = []
    _.forIn(data, value => {
      if (Array.isArray(value)) {
        list = value
      }
    })

    if (basicObjectLineage !== VIRTUAL_SERVER) {
      listQuantity[lineage] = listQuantity[lineage] || 0
      switch (mode) {
        case 'increase':
          listQuantity[lineage] += 1
          break
        case 'decrease':
          listQuantity[lineage] -= 1
          break
        default:
          const { length } = list
          if (listQuantity[lineage] !== length) {
            listQuantity[lineage] = length
          }
          break
      }
    } else {
      listQuantity = this.updateVirtualServerSeriesQuantity(listQuantity, list)
    }
    this.setState({ listQuantity })
  }

  onOpenList = async (
    item: IObject,
    initalCount: boolean = false,
    updateBreadCrumb: boolean = true,
  ) => {
    const {
      expandedKeys,
      isLogicalPartition,
      isDevicePartition,
      // isClusterPartition,
      // selectedPartition,
      partitionType,
    } = this.props
    const { currentMenuJson } = this.state
    const { dataList } = this.state
    const lineage = item.lineage ? item.lineage[0] : ''
    // single object
    const singleObject = {}
    const basicObjectLineage = lineage.slice(lineage.lastIndexOf('.') + 1)
    const singleObjectLineageKey = `${basicObjectLineage}.json`
    singleObject[singleObjectLineageKey] = lineage
    const addLabel = item.addLabel || item.title
    // || singleObjectLineageKey.slice(0, singleObjectLineageKey.indexOf('.json'))
    const nodePrivilege =
      item.should_readonly &&
      item.should_readonly.partition &&
      item.should_readonly.partition.includes(partitionType)
        ? 'readOnly'
        : 'write'
    const additionNode = (
      <A10Tooltip
        placement="top"
        title={item.fileType ? `Create ${addLabel} File` : `New ${addLabel}`}
      >
        <a
          onClick={this.onAdd('', lineage, item, {})}
          style={{
            // whiteSpace: 'break-spaces',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: '0.8em',
          }}
          // title={item.fileType ? `Create ${addLabel} File` : `New ${addLabel}`}
        >
          {item.fileType ? `Create ${addLabel} File` : `New ${addLabel}`}
        </a>
      </A10Tooltip>
    )

    const isLeaf = !item.menus || item.customizedPage === 'portal'
    if (isLeaf) {
      if (item.onlyEditMode) {
        this.context.openListPage(null, '', '', updateBreadCrumb)
      } else {
        const response: IObject = await this.getDataFormHttpReuqest(
          lineage,
          item,
        )
        const indexName = isLogicalPartition
          ? `lp.${lineage}`
          : isDevicePartition
          ? `partition.${lineage}`
          : lineage

        dataList[indexName] = response.data
        const addListNode =
          item.noAddMode || nodePrivilege === 'readOnly' ? (
            <TreeNode
              icon={
                <StatusBar
                  borderRadius="9px"
                  type="disalbe"
                  text={addLabel.slice(0, 1).toUpperCase()}
                />
              }
              title={<A10Tooltip placement="top" title={`${addLabel} List`} />}
              className={`global-object-explorer-tree-list-item global-object-explorer-tree-node provider-tree-tenants-list-services-${singleObjectLineageKey} global-object-explorer-tree-list-addon`}
              key="tree-list-addon"
              isLeaf={true}
            />
          ) : (
            <TreeNode
              title={additionNode}
              className={`global-object-explorer-tree-list-item global-object-explorer-tree-node provider-tree-tenants-list-services-${singleObjectLineageKey} global-object-explorer-tree-list-addon`}
              key="tree-list-addon"
              icon={<A10Icon type="plus" className="add-button" />}
              isLeaf={true}
            />
          )
        let tmpComponent = <div />
        const tmpDataList =
          (dataList &&
            dataList[indexName] &&
            dataList[indexName][
              `${singleObjectLineageKey.slice(
                0,
                singleObjectLineageKey.indexOf('.json'),
              )}-list`
            ]) ||
          dataList[indexName][
            `${singleObjectLineageKey.slice(
              0,
              singleObjectLineageKey.indexOf('.json'),
            )}`
          ] ||
          []
        if (tmpDataList && !_.isEmpty(tmpDataList)) {
          const arrayDataList = _.isArray(tmpDataList)
            ? tmpDataList
            : Object.keys(tmpDataList)
          const { count = 25 } = this.state
          const { pageCount = 25 } = this.props
          const events =
            arrayDataList.slice(0, initalCount ? pageCount : count) || []
          let hasMore = false
          if (events.length < arrayDataList.length) {
            hasMore = true
          }
          const api = `${this.state.apiPrefix}${lineage.replace(/\./g, '/')}/`
          const InfiniteTreeNode: React.FC<any> = props => {
            const { loadMore, onListAction } = props
            return (
              <InfiniteScroll
                loadMore={loadMore}
                hasMore={hasMore}
                useWindow={false}
                initialLoad={false}
                threshold={300}
                loader={<A10Spin />}
              >
                {events
                  .map((v: any, k: number) => {
                    const name =
                      v.type && v.type === 'ssl'
                        ? v.name
                        : v['a10-url']
                        ? decodeURIComponent(
                            v['a10-url'].slice(encodeURI(api).length),
                          )
                        : v
                    item.disableDel =
                      item.disableDelList && item.disableDelList.includes(name)
                    const dataNode = (
                      <A10Tooltip placement="top" title={name}>
                        <div
                          className="flex-container"
                          style={{
                            display: 'flex',
                            paddingTop: '.04em',
                            position: 'relative',
                          }}
                        >
                          <span
                            className="global-object-explorer-tree-node-data-title"
                            // title={name}
                            style={{
                              flex: '1',
                              paddingRight: '0.8em',
                              // whiteSpace: 'break-spaces',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {name}
                          </span>
                          <span
                            className="text-right table-header"
                            style={{ right: '0', position: 'absolute' }}
                          >
                            {nodePrivilege === 'write'
                              ? onListAction(
                                  name,
                                  lineage,
                                  singleObjectLineageKey,
                                  v,
                                  item,
                                  !item.deleteOnlyNode
                                    ? false
                                    : _.isMatch(v, item.deleteOnlyNode),
                                )
                              : null}
                          </span>
                        </div>
                      </A10Tooltip>
                    )
                    const disalbeByUserTag =
                      item.userTag && item.userTag !== v['user-tag']
                    const objHref = v?.['obj-href']
                    return disalbeByUserTag ? null : (
                      <TreeNode
                        title={dataNode}
                        icon={
                          <StatusBar
                            borderRadius="9px"
                            type={!objHref ? 'up' : 'unknow'}
                            text={singleObjectLineageKey
                              .slice(0, 1)
                              .toUpperCase()}
                          />
                        }
                        className={`global-object-explorer-tree-list-item global-object-explorer-tree-node provider-tree-tenants-list-services-${v}`}
                        key={`tree-list-${k}`}
                        isLeaf={true}
                      />
                    )
                  })
                  .filter((node: JSX.Element) => !!node)}
              </InfiniteScroll>
            )
          }
          ;(InfiniteTreeNode as any).isTreeNode = 1 // workaround for GUI-1073
          // workaround GUI-1073
          let finalTreeNode: any = <TreeNode title="" />
          if (!item.customizedPage) {
            finalTreeNode = (
              <InfiniteTreeNode
                loadMore={this.loadMore(item)}
                onListAction={this.onListAction}
              />
            )
          } else if (item.customizedPage === 'vports') {
            finalTreeNode = arrayDataList.map((v: any, k: number) => {
              const name =
                v.type && v.type === 'ssl'
                  ? v.name
                  : v['a10-url']
                  ? decodeURIComponent(
                      v['a10-url'].slice(encodeURI(api).length),
                    )
                  : v
              item.disableDel =
                item.disableDelList && item.disableDelList.includes(name)
              const portList =
                _.groupBy(events, 'name')[name][0]['port-list'] || []
              return (
                <TreeNode
                  title={
                    <A10Tooltip placement="top" title={name}>
                      {name}
                    </A10Tooltip>
                  }
                  icon={
                    <StatusBar
                      borderRadius="9px"
                      type="up"
                      text={singleObjectLineageKey.slice(0, 1).toUpperCase()}
                    />
                  }
                  className={`global-object-explorer-tree-list-item global-object-explorer-tree-node provider-tree-tenants-list-services-${v}`}
                  key={`vports-tree-list-${k}`}
                  isLeaf={false}
                >
                  {portList.map((v2: any, k2: number) => {
                    v2.name = name
                    return (
                      <TreeNode
                        title={
                          <A10Tooltip
                            placement="top"
                            title={`${v2['port-number']}+${v2.protocol}`}
                          >
                            <div
                              className="flex-container"
                              style={{
                                display: 'flex',
                                paddingTop: '.04em',
                                position: 'relative',
                              }}
                            >
                              <span
                                className="global-object-explorer-tree-node-data-title"
                                // title={`${v2['port-number']}+${v2.protocol}`}
                                style={{
                                  flex: '1',
                                  paddingRight: '0.8em',
                                  // whiteSpace: 'break-spaces',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {`${v2['port-number']}+${v2.protocol}`}
                              </span>
                              <span
                                className="text-right table-header"
                                style={{
                                  right: '0',
                                  position: 'absolute',
                                }}
                              >
                                {this.onListAction(
                                  `${v2['port-number']}+${v2.protocol}`,
                                  'slb.virtual-server.port',
                                  singleObjectLineageKey,
                                  v2,
                                  item,
                                )}
                              </span>
                            </div>
                          </A10Tooltip>
                        }
                        icon={
                          <StatusBar
                            borderRadius="9px"
                            type="up"
                            text={singleObjectLineageKey
                              .slice(0, 1)
                              .toUpperCase()}
                          />
                        }
                        className={`global-object-explorer-tree-list-item global-object-explorer-tree-node provider-tree-tenants-list-services-${v2}`}
                        key={`tree-list-${k}-${k2}`}
                        isLeaf={true}
                      />
                    )
                  })}
                </TreeNode>
              )
            })
          } else if (item.customizedPage === 'portal') {
            finalTreeNode = arrayDataList.map((v: any, k: number) => {
              const name =
                v.type && v.type === 'ssl'
                  ? v.name
                  : v['a10-url']
                  ? decodeURIComponent(
                      v['a10-url'].slice(encodeURI(api).length),
                    )
                  : v
              item.disableDel =
                item.disableDelList && item.disableDelList.includes(name)
              const dataNode = (
                <A10Tooltip placement="top" title={name}>
                  <div
                    className="flex-container"
                    style={{
                      display: 'flex',
                      paddingTop: '.04em',
                      position: 'relative',
                    }}
                  >
                    <span
                      className="global-object-explorer-tree-node-data-title"
                      // title={name}
                      style={{
                        flex: '1',
                        paddingRight: '0.8em',
                        // whiteSpace: 'break-spaces',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {name}
                    </span>
                    <span
                      className="text-right table-header"
                      style={{ right: '0', position: 'absolute' }}
                    >
                      {nodePrivilege === 'write'
                        ? this.onListAction(
                            name,
                            lineage,
                            singleObjectLineageKey,
                            v,
                            item,
                            !item.deleteOnlyNode
                              ? false
                              : _.isMatch(v, item.deleteOnlyNode),
                          )
                        : null}
                    </span>
                  </div>
                </A10Tooltip>
              )
              return (
                <TreeNode
                  title={dataNode}
                  icon={
                    <StatusBar
                      borderRadius="9px"
                      type="up"
                      text={singleObjectLineageKey.slice(0, 1).toUpperCase()}
                    />
                  }
                  className={`global-object-explorer-tree-list-item global-object-explorer-tree-node provider-tree-tenants-list-services-${v}`}
                  key={`portal-tree-list-${k}`}
                  isLeaf={false}
                >
                  {item.menus
                    .map((x: any, y: number) => {
                      return (
                        <TreeNode
                          className={`global-object-explorer-tree-list-item global-object-explorer-tree-node provider-tree-tenants-list-services-${x}`}
                          title={
                            <A10Tooltip placement="top" title={x.title}>
                              <div
                                className="flex-container"
                                style={{
                                  display: 'flex',
                                  position: 'relative',
                                }}
                              >
                                <a
                                  onClick={
                                    x.onlyEditMode
                                      ? this.onOpenOnlyEditModeList(x)
                                      : this.onOpenList.bind(this, x)
                                  }
                                  style={{
                                    flex: '1',
                                    paddingRight: '0.8em',
                                    // whiteSpace: 'break-spaces',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                  // title={x.title}
                                >
                                  <span className="global-object-explorer-tree-label">
                                    {x.title}
                                  </span>
                                </a>
                              </div>
                            </A10Tooltip>
                          }
                          key={`portal-tree-list-${k}-${y}`}
                          isLeaf={true}
                          icon={this.getImage(x)}
                          // selectable={!disableNode}
                          // disabled={disableNode}
                        />
                      )
                    })
                    .filter((node: JSX.Element) => !!node)}
                </TreeNode>
              )
            })
          } else if (item.customizedPage === 'tacacs') {
            finalTreeNode = arrayDataList.map((v: any, k: number) => {
              const name =
                v.type && v.type === 'ssl'
                  ? v.name
                  : v['a10-url']
                  ? decodeURIComponent(
                      v['a10-url'].slice(encodeURI(api).length),
                    )
                  : v
              item.disableDel =
                item.disableDelList && item.disableDelList.includes(name)
              if (v !== 'a10-url') {
                return tmpDataList[v].map((host: IObject, index: number) => {
                  const objectKey =
                    v === 'ipv4-list'
                      ? 'ipv4-addr'
                      : v === 'ipv6-list'
                      ? 'ipv6-addr'
                      : 'hostname'
                  return (
                    <TreeNode
                      title={
                        <A10Tooltip placement="top" title={host[objectKey]}>
                          <div
                            className="flex-container"
                            style={{
                              display: 'flex',
                              paddingTop: '.04em',
                              position: 'relative',
                            }}
                          >
                            <span
                              className="global-object-explorer-tree-node-data-title"
                              // title={host[objectKey]}
                              style={{
                                flex: '1',
                                paddingRight: '0.8em',
                                // whiteSpace: 'break-spaces',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {host[objectKey]}
                            </span>
                            <span
                              className="text-right table-header"
                              style={{
                                right: '0',
                                position: 'absolute',
                              }}
                            >
                              {this.onListAction(
                                host[objectKey],
                                `tacacs-server.host.${v.slice(
                                  0,
                                  v.indexOf('-list'),
                                )}`,
                                singleObjectLineageKey,
                                host,
                                item,
                              )}
                            </span>
                          </div>
                        </A10Tooltip>
                      }
                      icon={<StatusBar borderRadius="9px" type="up" text="T" />}
                      className={`global-object-explorer-tree-list-item global-object-explorer-tree-node provider-tree-tenants-list-services-${index}`}
                      key={`tacacs-host-list-${index}`}
                      isLeaf={true}
                    />
                  )
                })
              }
            })
          }
          tmpComponent = (
            <A10Tree
              expandedKeys={expandedKeys}
              className="global-object-explorer-tree classlist-wrapper"
              onSelect={this.props.onSelectTree.bind(null, currentMenuJson)}
              onExpand={this.onExpand.bind(null, currentMenuJson)}
              showIcon={true}
              switcherIcon={
                <A10Icon
                  className="table-expandIcon"
                  app="global"
                  type="accordion"
                />
              }
            >
              {addListNode}
              {finalTreeNode}
            </A10Tree>
          )
        } else {
          tmpComponent = (
            <A10Tree
              expandedKeys={expandedKeys}
              className="global-object-explorer-tree"
              onSelect={this.props.onSelectTree.bind(null, currentMenuJson)}
              onExpand={this.onExpand.bind(null, currentMenuJson)}
              showIcon={true}
              // filterTreeNode={this.filterNode}
              switcherIcon={
                <A10Icon
                  className="table-expandIcon"
                  app="global"
                  type="accordion"
                />
              }
            >
              {addListNode}
            </A10Tree>
          )
        }
        this.context.openListPage(
          tmpComponent,
          '',
          item.title,
          updateBreadCrumb,
        )
        await this.updateQuantity(item, 'sync', response)
      }
      this.setState((prevState: IObjectTreeState) => {
        return {
          ...prevState,
          dataList,
        }
      })
    } else {
      await this.fetchQuantities(item)
    }
  }

  loadMore = (item: IObject) => () => {
    const { pageCount = 25 } = this.props
    this.setState(
      { count: this.state.count + pageCount },
      async () => await this.onOpenList(item),
    )
  }

  filterNode = (node: IObject) => {
    const {
      isDevicePartition,
      isClusterPartition,
      isLogicalPartition,
    } = this.props
    const isDPNode =
      node.hasOwnProperty('specificPartition') &&
      node.specificPartition.includes('dp')
    const isCPNode =
      node.hasOwnProperty('specificPartition') &&
      node.specificPartition.includes('cp')
    const isLPNode =
      !node.hasOwnProperty('specificPartition') ||
      !node.specificPartition ||
      node.specificPartition.includes('lp')
    return (
      (isDevicePartition && isDPNode) ||
      (isClusterPartition && isCPNode) ||
      (isLogicalPartition && isLPNode) ||
      (node.hasOwnProperty('specificPartition') &&
        node.specificPartition.includes('all'))
    )
  }

  onOpenOnlyEditModeList = (
    item: IObject,
    updateBreadCrumb: boolean = true,
  ) => {
    const lineage = item.lineage ? item.lineage[0] : ''
    return this.onAdd('', lineage, item, {}, true)
  }

  renderThunder(item: IObject, key: string, index: number) {
    const { partitionType, appType } = this.props
    const { listQuantity } = this.state
    const { onlyEditMode, lineage: lineages, uiJsonPath = '' } = item
    const lineage = (lineages && lineages[0]) || ''
    const nodeIcon = this.getImage(item)
    const onClick = onlyEditMode
      ? this.onOpenOnlyEditModeList(item)
      : this.onOpenList.bind(this, item, true)
    const isLeaf = !item.menus || item.customizedPage === 'portal'
    const className = !isLeaf
      ? 'global-object-explorer-tree-node global-object-explorer-tree-menus'
      : 'global-object-explorer-tree-node'

    const amount =
      listQuantity[lineage] ||
      listQuantity[uiJsonPath] ||
      (onlyEditMode ? '' : '0')
    const objTitle = (
      <A10Tooltip placement="top" title={item.title}>
        <div
          className="flex-container"
          style={{
            display: 'flex',
            position: 'relative',
          }}
        >
          <a
            onClick={onClick}
            style={{
              flex: '1',
              paddingRight: '0.8em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            href="/"
          >
            <span className="global-object-explorer-tree-label">
              {item.title}
            </span>
            {isLeaf && amount && (
              <span className="global-object-explorer-tree-amount">
                {amount}
              </span>
            )}
          </a>
        </div>
      </A10Tooltip>
    )

    const itemPrivilege = !(
      item.should_disable &&
      item.should_disable.partition &&
      item.should_disable.partition.includes(partitionType)
    )

    return (
      <TreeNode
        className={className}
        title={objTitle}
        key={`${key}-${index}`}
        isLeaf={isLeaf}
        icon={nodeIcon}
      >
        {item.menus && itemPrivilege && item.customizedPage !== 'portal'
          ? item.menus
              .map((v: any, k: number) => {
                if (
                  v === null ||
                  (v.should_disable &&
                    ((v.should_disable.partition &&
                      v.should_disable.partition.includes(partitionType)) ||
                      (appType &&
                        v.should_disable.partition_app_type &&
                        v.should_disable.partition_app_type.includes(appType))))
                ) {
                  return null
                }
                return this.filterNode(v)
                  ? this.renderThunder(v, `${key}-${index}`, k)
                  : null
              })
              .filter((node: JSX.Element) => !!node)
          : item.title}
      </TreeNode>
    )
  }

  onChangeAppType = (value: string) => {
    const { partitionType } = this.props
    const currentTenant = basicUtils.getCurrentTenant()
    const currentTenantName =
      (currentTenant && currentTenant.name) ||
      storage.get['object.explorer.tenant.text']
    const type =
      partitionType === 'shared' ? partitionType : 'private-partition'
    httpClient
      .post(
        `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${currentTenantName}/logical-partition/${storage.get['object.explorer.lp.name']}`,
        {
          'logical-partition': {
            name: storage.get['object.explorer.lp.name'],
            type,
            'app-type': value,
          },
        },
      )
      .then(() => {
        this.props.onChangeAppType(value)
      })
      .catch(() => {
        A10Message.error(
          'Failed to change Application Type of Logical Partition.',
        )
        this.props.onObjectChange()
      })
  }

  onExpand = async (
    menu: IObject,
    expandedKeys: string[],
    treeNodeEvent: any,
  ) => {
    const { onExpandTree } = this.props
    await this.fetchQuantities(menu, expandedKeys)
    onExpandTree(Immutable.fromJS(expandedKeys), treeNodeEvent)
    this.setState({
      autoExpandParent: false,
    })
  }

  filterBySearchValue = (searchValue: string, element: IObject) => {
    const { title, menus } = element

    const selfMatch = title.toUpperCase().includes(searchValue.toUpperCase())
    if (menus) {
      element.menus = menus.filter(
        this.filterBySearchValue.bind(null, searchValue),
      )
      return selfMatch || element.menus.length > 0
    } else {
      return selfMatch
    }
  }

  onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    let filterMenuJson = JSON.parse(JSON.stringify(menuJson))
    if (value) {
      filterMenuJson = filterMenuJson.filter(
        this.filterBySearchValue.bind(null, value),
      )
    }

    this.setState({
      searchValue: value,
      autoExpandParent: true,
      currentMenuJson: filterMenuJson,
    })
  }

  filterParentNodesWithNoChildren = (node: IObject) => {
    if (node === null) {
      return node
    }

    const { menus } = node
    if (Array.isArray(menus)) {
      if (menus.length > 0) {
        node.menus = menus.map(this.filterParentNodesWithNoChildren)
        if (node.menus.every((element: IObject) => element === null)) {
          return null
        } else {
          return node
        }
      } else {
        return null
      }
    } else {
      return node
    }
  }

  logicalPartitionFilter = (node: IObject) => {
    const { partitionType, appType } = this.props

    const { menus } = node
    if (menus) {
      node.menus = menus.map(this.logicalPartitionFilter)
    }

    const partition = node?.should_disable?.partition || ''
    const partitionAppType = node?.should_disable?.partition_app_type || ''
    if (
      partition.includes(partitionType) ||
      (appType && partitionAppType.includes(appType))
    ) {
      return null
    }

    if (
      !node.hasOwnProperty('specificPartition') ||
      !node.specificPartition ||
      node.specificPartition.includes('all') ||
      node.specificPartition.includes('lp')
    ) {
      return node
    } else {
      return null
    }
  }

  partitionFilter = (node: IObject) => {
    const { partitionType, isDevicePartition, isClusterPartition } = this.props
    const { title } = node

    const partition = node?.should_disable?.partition || ''
    if (partition.includes(partitionType)) {
      return null
    }
    const mainMenu = isDevicePartition
      ? // ? ['System', 'Network', 'CGN', 'ADC', 'Security']
        // new HCMS don't support ADC, CGN, and Security for DP
        ['System', 'Network']
      : ['System']
    if (isClusterPartition && partitionType === 'shared') {
      mainMenu.push('Network')
    }

    if (mainMenu.includes(title)) {
      return node
    } else {
      return null
    }
  }

  filterNodesBeforeRender = (node: IObject, index: number) => {
    const { baseKey } = this.props
    return node === null ? node : this.renderThunder(node, baseKey, index)
  }

  componentDidMount() {
    const { partitionType } = this.props
    const IS_SHARED_PARTITION = partitionType === 'shared'
    const PARTITION_TYPE = IS_SHARED_PARTITION
      ? null
      : partitionType.toUpperCase()
    setUpAutoConfig({
      ENV_CONSTS: {
        IS_SHARED_PARTITION,
        PARTITION_TYPE: PARTITION_TYPE as any,
      },
    })
    this.setState(this.getApiPrefix())
  }

  async componentDidUpdate(prevProps: IObjectTreeProps) {
    const {
      partitionType,
      eventKey,
      expandedKeys,
      isRenderCustomizedTree,
      isClusterPartition,
      isDevicePartition,
      isLogicalPartition,
      selectedPartition = {},
    } = this.props
    if (prevProps.partitionType !== partitionType) {
      const IS_SHARED_PARTITION = partitionType === 'shared'
      const PARTITION_TYPE = IS_SHARED_PARTITION
        ? null
        : partitionType.toUpperCase()
      setUpAutoConfig({
        ENV_CONSTS: {
          IS_SHARED_PARTITION,
          PARTITION_TYPE: PARTITION_TYPE as any,
        },
      })
    }
    if (
      isRenderCustomizedTree &&
      !_.isEqual(prevProps.expandedKeys, expandedKeys)
    ) {
      if (eventKey.startsWith('vports-tree-list')) {
        await this.onOpenList({
          title: 'Virtual Services',
          lineage: ['slb.virtual-server'],
          customizedPage: 'vports',
          uiJsonPath: 'adc/slb/virtual-services',
        })
      } else if (eventKey.startsWith('portal-tree-list')) {
        await this.onOpenList({
          title: 'Portal',
          lineage: ['aam.authentication.portal'],
          uiJsonPath: 'aam/auth-clients/portal/portal',
          customizedPage: 'portal',
          menus: [
            {
              title: 'Portal Logon',
              onlyEditMode: true,
              customizedPage: 'portal',
              defaultParams: {
                name: 'default-portal',
              },
              lineage: ['aam.authentication.portal.logon'],
              uiJsonPath: 'aam/auth-clients/portal/logon',
            },
            {
              title: 'Portal Logon Fail',
              onlyEditMode: true,
              customizedPage: 'portal',
              defaultParams: {
                name: 'default-portal',
              },
              lineage: ['aam.authentication.portal.logon-fail'],
              uiJsonPath: 'aam/auth-clients/portal/logon-fail',
            },
            {
              title: 'Portal Change Password',
              onlyEditMode: true,
              customizedPage: 'portal',
              defaultParams: {
                name: 'default-portal',
              },
              lineage: ['aam.authentication.portal.change-password'],
              uiJsonPath: 'aam/auth-clients/portal/change-password',
            },
            {
              title: 'Notify Change Password',
              onlyEditMode: true,
              customizedPage: 'portal',
              defaultParams: {
                name: 'default-portal',
              },
              lineage: ['aam.authentication.portal.change-password'],
              uiJsonPath: 'aam/auth-clients/portal/notify-change-password',
            },
          ],
        })
      }
    }
    const prevPropsSelectedPartition = prevProps.selectedPartition || {}
    if (
      isClusterPartition !== prevProps.isClusterPartition ||
      isLogicalPartition !== prevProps.isLogicalPartition ||
      isDevicePartition !== prevProps.isDevicePartition ||
      (!!selectedPartition &&
        JSON.stringify(selectedPartition) !==
          JSON.stringify(prevPropsSelectedPartition))
    ) {
      this.setState(this.getApiPrefix())
    }
  }

  render() {
    const {
      isLogicalPartition,
      isDevicePartition,
      isClusterPartition,
      onSelectTree,
      expandedKeys,
      isLoading,
      // appType,
      selectedPartition,
    } = this.props

    const { autoExpandParent, currentMenuJson } = this.state
    const loading = (
      <div className="goe-loading-icon">
        <ReactLoading type="bars" color="#4a90e2" height={40} width={40} />
      </div>
    )
    // if (isLogicalPartition && (!appType || appType === 'none')) {
    //   const notificationText =
    //     'Please select an Application Type for the Logical Partition. Once selected, it cannot be changed.'
    //   return isLoading ? (
    //     loading
    //   ) : (
    //     <div id="goe-app-type-select">
    //       <div className="app-type-label">App Type </div>
    //       <A10Select
    //         className="app-type-select"
    //         onChange={this.onChangeAppType}
    //       >
    //         <A10Select.Option value="adc" key="adc" title="ADC">
    //           ADC
    //         </A10Select.Option>
    //         <A10Select.Option value="cgnv6" key="cgnv6" title="CGN">
    //           CGN
    //         </A10Select.Option>
    //       </A10Select>
    //       <div className="app-type-notification">
    //         <div className="notification-icon">
    //           <A10Icon type="exclamation-circle" />
    //         </div>
    //         <div title={notificationText}>{notificationText}</div>
    //       </div>
    //     </div>
    //   )
    // }

    let filterMenuJson = JSON.parse(JSON.stringify(currentMenuJson))
    let content
    if (isLogicalPartition) {
      filterMenuJson = filterMenuJson.map(this.logicalPartitionFilter)
      filterMenuJson = filterMenuJson.map(this.filterParentNodesWithNoChildren)
      content = filterMenuJson
        .map(this.filterNodesBeforeRender)
        .filter((node: JSX.Element) => !!node)
    } else {
      if (isDevicePartition || isClusterPartition) {
        filterMenuJson = filterMenuJson.map(this.partitionFilter)
        filterMenuJson = filterMenuJson.map(
          this.filterParentNodesWithNoChildren,
        )
        content = filterMenuJson
          .map(this.filterNodesBeforeRender)
          .filter((node: JSX.Element) => !!node)
      } else {
        content = <TreeNode title="" />
      }
    }

    return isLoading ? (
      loading
    ) : (
      <>
        <Search onChange={this.onSearch} />
        <A10Tree
          className="global-object-explorer-tree"
          showIcon={true}
          onSelect={onSelectTree.bind(null, currentMenuJson)}
          onExpand={this.onExpand.bind(null, currentMenuJson)}
          expandedKeys={expandedKeys}
          switcherIcon={
            <A10Icon
              className="table-expandIcon"
              app="global"
              type="accordion"
            />
          }
          autoExpandParent={autoExpandParent}
          defaultExpandAll={true}
        >
          {!!selectedPartition ? content : <A10Tree.TreeNode title="" />}
        </A10Tree>
      </>
    )
  }
}

export default setupA10Container(ObjectTree)
