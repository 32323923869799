import React from 'react'
import propTypes from 'prop-types'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
} from '@gui-libraries/framework'
import { A10Collapse, A10Row, A10Col } from '@gui-libraries/widgets'

import HealthStatus from 'src/components/ADC/HealthStatus'
import DeployedVPortList from '../../../Common/DeployedVPortList'
import DeployedVIPList from '../../../Common/DeployedVIPList'
import DeployedServerList from '../../../Common/DeployedServerList'
import DeployedServiceGroupList from '../../../Common/DeployedServiceGroupList'
import DeployedToTemplateList from '../../../Common/DeployedToTemplateList'
import storage from 'src/libraries/storage'

import './styles/index.module.less'

export interface ISharedObjectDetailProps extends IA10ContainerDefaultProps {
  record: any
  tenant: string
  logicalPartition: string
  data: any[]
  defaultActiveAll?: boolean
}

export interface ISharedObjectDetailState {
  isLoading: boolean
}

class SharedObjectDetail extends A10Container<
  ISharedObjectDetailProps,
  ISharedObjectDetailState
> {
  static defaultProps = {
    defaultActiveAll: false,
  }
  static childContextTypes = {
    refreshTemplateList: propTypes.func.isRequired,
  }

  constructor(props: ISharedObjectDetailProps) {
    super(props)
    this.state = {
      isLoading: false,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getChildContext() {
    return {
      refreshTemplateList: this.refreshTemplateList,
    }
  }

  refreshTemplateList = () => {
    this.getData()
  }

  getData = () => {
    // Start to load data
    this.setState({ isLoading: true })

    const {
      A10Dispatchers: { httpRequest },
      record,
      tenant,
      logicalPartition,
    } = this.props
    const longType = record.type
    let templateKey = ''
    let url = ''
    const type = record.type
    const name = record.name
    if (longType.indexOf('slb.template') > -1) {
      if (longType.indexOf('slb.template.persist') > -1) {
        templateKey = longType.substring(
          longType.indexOf('template.persist') + 17,
        )
        url = `/hrcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${logicalPartition}/slb/template/persist/${templateKey}/${name}`
      } else {
        templateKey = longType.substring(longType.indexOf('template') + 9)
        url = `/hrcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${logicalPartition}/slb/template/${templateKey}/${name}`
      }
    } else {
      templateKey = 'logging'
      url = `/hrcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${logicalPartition}/ip/nat/template/${templateKey}/${name}`
    }

    httpRequest({
      namespace: getNS('DEPLOYED_OBJECT_LIST', { type, name }),
      request: async (epicDependence: any) => {
        try {
          const { httpClient } = epicDependence
          url = encodeURI(url)
          const { data: res } = await httpClient.get(url)
          let objectList = []
          if (res) {
            objectList = res[templateKey]['referrer-list'] || []
          }
          this.setState({ isLoading: false })
          return objectList
        } catch (err) {
          this.setState({ isLoading: false })
          return []
        }
      },
    })
  }

  renderDetail = () => {
    const { record, tenant, data, defaultActiveAll } = this.props
    const vportList = data.filter(
      object => object['obj-class'] === 'slb.virtual-server.port',
    )
    const vipList = data.filter(
      object => object['obj-class'] === 'slb.virtual-server',
    )
    const serviceGroupList = data.filter(
      object => object['obj-class'] === 'slb.service-group',
    )
    const serverList = data.filter(
      object => object['obj-class'] === 'slb.server',
    )
    const serverSslList = data.filter(
      object => object['obj-class'] === 'slb.template.server-ssl',
    )
    const clientSslList = data.filter(
      object => object['obj-class'] === 'slb.template.client-ssl',
    )
    const httpList = data.filter(
      object => object['obj-class'] === 'slb.template.http',
    )
    const ObjectHeader = (props: any) => {
      return (
        <>
          <A10Row type="flex" align="middle">
            <A10Col lg={20}>
              <div className="section-title-container">
                <span className="section-title">Assigned {props.name}</span>
                <HealthStatus type="number" text={props.count} />
              </div>
            </A10Col>
          </A10Row>
        </>
      )
    }

    return (
      <>
        <div className="detail-section">
          {record.type !== 'slb.template.virtual-server' &&
          record.type !== 'slb.template.port' &&
          record.type !== 'slb.template.server' &&
          record.type !== 'slb.template.cipher' &&
          record.type !== 'slb.template.logging' &&
          record.type !== 'slb.template.monitor' &&
          record.type !== 'ip.nat.template.logging' ? (
            <A10Collapse
              bordered={false}
              defaultActiveKey={defaultActiveAll ? 'vport' : null}
            >
              <A10Collapse.Panel
                header={<ObjectHeader name="vPorts" count={vportList.length} />}
                key="vport"
              >
                <DeployedVPortList
                  name={record.name}
                  type={record.type}
                  tenant={tenant}
                  data={vportList}
                  isLoading={this.state.isLoading}
                />
              </A10Collapse.Panel>
            </A10Collapse>
          ) : null}
          {record.type === 'slb.template.virtual-server' ||
          record.type === 'ip.nat.template.logging' ||
          record.type === 'scaleout' ||
          record.type === 'slb.template.policy' ? (
            <A10Collapse
              bordered={false}
              defaultActiveKey={defaultActiveAll ? 'vip' : null}
            >
              <A10Collapse.Panel
                header={<ObjectHeader name="VIPs" count={vipList.length} />}
                key="vip"
              >
                <DeployedVIPList
                  name={record.name}
                  type={record.type}
                  tenant={tenant}
                  data={vipList}
                  isLoading={this.state.isLoading}
                />
              </A10Collapse.Panel>
            </A10Collapse>
          ) : null}
          {record.type === 'slb.template.port' ||
          record.type === 'slb.template.server' ||
          record.type === 'slb.template.policy' ? (
            <A10Collapse
              bordered={false}
              defaultActiveKey={defaultActiveAll ? 'service group' : null}
            >
              <A10Collapse.Panel
                header={
                  <ObjectHeader
                    name="Service Groups"
                    count={serviceGroupList.length}
                  />
                }
                key="service group"
              >
                <DeployedServiceGroupList
                  name={record.name}
                  type={record.type}
                  tenant={tenant}
                  data={serviceGroupList}
                  isLoading={this.state.isLoading}
                />
              </A10Collapse.Panel>
            </A10Collapse>
          ) : null}
          {record.type === 'slb.template.cipher' ? (
            <React.Fragment>
              <A10Collapse
                bordered={false}
                defaultActiveKey={
                  defaultActiveAll ? 'template-client-ssl' : null
                }
              >
                <A10Collapse.Panel
                  header={
                    <ObjectHeader
                      name="Client SSL Templates"
                      count={clientSslList.length}
                    />
                  }
                  key="template-client-ssl"
                >
                  <DeployedToTemplateList
                    name={record.name}
                    type={record.type}
                    associateType="client-ssl"
                    templateName="Client SSL"
                    tenant={tenant}
                    data={clientSslList}
                    isLoading={this.state.isLoading}
                  />
                </A10Collapse.Panel>
              </A10Collapse>

              <A10Collapse
                bordered={false}
                defaultActiveKey={
                  defaultActiveAll ? 'template-server-ssl' : null
                }
              >
                <A10Collapse.Panel
                  header={
                    <ObjectHeader
                      name="Server SSL Templates"
                      count={serverSslList.length}
                    />
                  }
                  key="template-server-ssl"
                >
                  <DeployedToTemplateList
                    name={record.name}
                    type={record.type}
                    associateType="server-ssl"
                    templateName="Server SSL"
                    tenant={tenant}
                    data={serverSslList}
                    isLoading={this.state.isLoading}
                  />
                </A10Collapse.Panel>
              </A10Collapse>
            </React.Fragment>
          ) : null}
          {record.type === 'slb.template.logging' ? (
            <A10Collapse
              bordered={false}
              defaultActiveKey={defaultActiveAll ? 'template-http' : null}
            >
              <A10Collapse.Panel
                header={
                  <ObjectHeader name="HTTP Templates" count={httpList.length} />
                }
                key="template-http"
              >
                <DeployedToTemplateList
                  name={record.name}
                  type={record.type}
                  associateType="http"
                  templateName="HTTP"
                  tenant={tenant}
                  data={httpList}
                  isLoading={this.state.isLoading}
                />
              </A10Collapse.Panel>
            </A10Collapse>
          ) : null}
          {record.type === 'slb.template.server' ? (
            <A10Collapse
              bordered={false}
              defaultActiveKey={defaultActiveAll ? 'server' : null}
            >
              <A10Collapse.Panel
                header={
                  <ObjectHeader name="Servers" count={serverList.length} />
                }
                key="server"
              >
                <DeployedServerList
                  name={record.name}
                  type={record.type}
                  tenant={tenant}
                  data={serverList}
                  isLoading={this.state.isLoading}
                />
              </A10Collapse.Panel>
            </A10Collapse>
          ) : null}
        </div>
      </>
    )
  }

  render() {
    return <div className="list-detail-container">{this.renderDetail()}</div>
  }
}

function mapStateToProps(state: any, props: ISharedObjectDetailProps) {
  const { record } = props
  const type = record.type
  const name = record.name
  return {
    data: state.A10Data.getIn(
      getNS('DEPLOYED_OBJECT_LIST', { type, name }),
      [],
    ),
  }
}

export default setupA10Container(SharedObjectDetail, mapStateToProps)
