import React from 'react'
import ReactLoading from 'react-loading'
import { _, A10Component } from '@gui-libraries/framework'
import { A10Alert, A10Form, A10Icon, A10Input } from '@gui-libraries/widgets'

import { Utilities } from 'src/services'
import { Messages } from 'src/locale/en/Messages'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { A10Panel } from 'src/components/ADC/A10Panel'
import { A10IconTextGroup } from 'src/components/ADC/A10IconTextGroup'
import { HelpInfo } from 'src/components/shared/HelpInfo'

export interface IAuthValidateProps {
  form: any // from A10Form.create()

  visible: boolean
  payload: IObject
  validateAuth(payload: any, userId: string, password: string): Promise<any>
  onClose?: (status: string) => void
}

export interface IAuthValidateState {
  validatePageTitle: string
  validatePageDesc: string
  validationStatus: 'none' | 'success' | 'failure'
  validationMessage: string
  userId: string
  password: string
  isValidating: boolean
  isDone: boolean
}

export class AuthValidateForm extends A10Component<
  IAuthValidateProps,
  IAuthValidateState
> {
  Messages = new Messages()
  Utilities = new Utilities()
  state: IAuthValidateState = {
    validatePageTitle: 'Authentication Service Validation',
    validatePageDesc:
      'Harmony doesn’t store this information other than validating service access.',
    validationStatus: 'none',
    validationMessage: null,
    userId: '',
    password: '',
    isValidating: false,
    isDone: false,
  }

  shouldComponentUpdate(nextProps: IAuthValidateProps) {
    const { visible: nextVisible } = nextProps
    const { visible } = this.props

    if (!visible && nextVisible) {
      this.onResetStatus()
      return false
    }
    return true
  }

  onResetStatus = () => {
    this.setState({
      validationStatus: 'none',
      validationMessage: null,
      userId: '',
      password: '',
      isDone: false,
    })
  }

  onValidateSuccess = (message?: string) => {
    this.setState({
      validationStatus: 'success',
      validationMessage: message || null,
      isValidating: false,
      isDone: true,
    })
  }

  onValidateFailure = (message?: string) => {
    this.setState({
      validationStatus: 'failure',
      validationMessage: message || null,
      isValidating: false,
    })
  }

  handleInputChange = (key: string, event: any) => {
    const value = event.target.value

    if (_.isString(value)) {
      this.setState({ [key]: value } as any)
    }
  }

  handleValidateUserID = (rule: any, userID: string, callback: any) => {
    let isValid = true

    const userIDPattern = new RegExp(this.Utilities.USERID_PATTERN)
    if ('' === userID) {
      rule.message = this.Messages.EMPTY_USER_ID
      isValid = false
    } else if (!userIDPattern.test(userID)) {
      rule.message =
        this.Messages.INVALID_USER_ID +
        ', it cannot exceed 64 letters and no special characters (except ,.()@-_=)'
      isValid = false
    } else if (userID.toUpperCase() === 'super-admin'.toUpperCase()) {
      rule.message = 'Please enter different user id, entered id is reserved'
      isValid = false
    }

    return isValid ? callback() : callback(true)
  }

  onCancel = () => {
    const { onClose } = this.props

    if (_.isFunction(onClose)) {
      onClose(this.state.validationStatus)
    }
  }

  onAuthenticate = () => {
    const { payload, validateAuth, onClose } = this.props
    const { userId, password, isDone } = this.state

    if (isDone) {
      if (_.isFunction(onClose)) {
        onClose(this.state.validationStatus)
      }
      return
    }

    const userIDPattern = new RegExp(this.Utilities.USERID_PATTERN)

    if (userId === '') {
      this.Utilities.showMessage(this.Messages.EMPTY_USER_ID, 0, false)
      return
    } else if (!userIDPattern.test(userId)) {
      this.Utilities.showMessage(
        this.Messages.INVALID_USER_ID +
          ', it cannot exceed 64 letters and no special characters (except ,.()@-_=)',
        0,
        false,
      )
      return
    }

    if (password === '') {
      this.Utilities.showMessage(this.Messages.USER_PASSWORD_REQUIRED, 0, false)
      return
    }

    if (_.isFunction(validateAuth)) {
      this.setState({ isValidating: true })
      const promise = validateAuth(payload, userId, password)

      promise.then((result: any) => {
        const { status, message } = result
        if (status === true) {
          this.onValidateSuccess(message)
        } else if (status === false) {
          this.onValidateFailure(message)
        } else {
          this.setState({
            validationStatus: 'none',
            validationMessage: null,
            isValidating: false,
          })
        }
      })
    }
  }

  render() {
    const {
      form: { getFieldDecorator },
      visible,
    } = this.props
    const {
      validatePageTitle,
      validatePageDesc,
      validationStatus,
      validationMessage,
      isValidating,
      isDone,
    } = this.state

    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 13 },
    }

    const showSuccessAlert = validationStatus === 'success'
    const showFailureAlert = validationStatus === 'failure'

    return (
      <FormatSlidingPage
        isOpen={visible}
        title={validatePageTitle}
        description={validatePageDesc}
        saveText={isDone ? 'Done' : 'Validate'}
        onRequestOk={this.onAuthenticate}
        onRequestClose={this.onCancel}
      >
        <A10Form hideRequiredMark={true} layout="horizontal">
          {!isValidating && showSuccessAlert ? (
            <A10Alert
              className="alert-status"
              style={{ marginBottom: '20px' }}
              showIcon={true}
              type="success"
              message="Successfully Validated"
              description={validationMessage}
            />
          ) : null}
          {!isValidating && showFailureAlert ? (
            <A10Alert
              className="alert-status"
              style={{ marginBottom: '20px' }}
              showIcon={true}
              type="error"
              message="Validation Failed"
              description={validationMessage}
            />
          ) : null}
          <A10Panel
            title={
              <A10IconTextGroup
                text="Username/Password"
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            }
          >
            <A10Form.Item
              {...formItemLayout}
              key="userId"
              label={
                <span className="mandatoryField">
                  {this.Messages.USER_ID}
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.USER_ID}
                      helpKey="HELP_ORG_AUTHENTICATION_RADIUS_USER_ID"
                    />
                  </span>
                </span>
              }
            >
              <div>
                {getFieldDecorator('user', {
                  rules: [
                    {
                      validator: this.handleValidateUserID,
                      message: this.Messages.INVALID_USER_ID,
                    },
                  ],
                  initialValue: '',
                })(
                  <A10Input
                    type="text"
                    placeholder={this.Messages.USER_ID}
                    onChange={this.handleInputChange.bind(this, 'userId')}
                  />,
                )}
              </div>
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              key="password"
              label={
                <span className="mandatoryField">
                  {this.Messages.PASSWORD}
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.PASSWORD}
                      helpKey="HELP_ORG_AUTHENTICATION_RADIUS_USER_PASSWORD"
                    />
                  </span>
                </span>
              }
            >
              <div>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      validator: this.Utilities.validateNotEmpty,
                      message: this.Messages.CANNOT_BE_EMPTY,
                    },
                  ],
                  initialValue: '',
                })(
                  <A10Input
                    type="password"
                    placeholder={this.Messages.PASSWORD}
                    onChange={this.handleInputChange.bind(this, 'password')}
                  />,
                )}
              </div>
            </A10Form.Item>
            {isValidating ? (
              <ReactLoading
                type="bars"
                color="#4a90e2"
                height={40}
                width={40}
              />
            ) : null}
          </A10Panel>
        </A10Form>
      </FormatSlidingPage>
    )
  }
}

export default A10Form.create()(AuthValidateForm)
