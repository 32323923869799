import React from 'react'
import { A10Icon } from '@gui-libraries/widgets'

import { HelpSupport } from '.'

import './styles/helpsupport.scss'

export interface IHelpSupportEntryProps {}

export interface IHelpSupportEntryState {
  showHelpModal: boolean
}

export default class HelpSupportEntry extends React.Component<
  IHelpSupportEntryProps,
  IHelpSupportEntryState
> {
  state = {
    showHelpModal: false,
  }

  toggleHelpModal = (isOpen: boolean) => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'scroll'
    }
    this.setState({ showHelpModal: isOpen })
  }

  openHelpPage = (url: string, e: any) => {
    window.open(url, '_blank')
  }

  render() {
    const { showHelpModal } = this.state
    return (
      <>
        <div
          className="help-support-entry"
          onClick={this.toggleHelpModal.bind(this, true)}
        >
          <A10Icon
            app="navigation-icons"
            type="help-support"
            withoutHover={true}
          />
        </div>
        <HelpSupport
          onCancel={this.toggleHelpModal.bind(this, false)}
          showSlidingPage={showHelpModal}
          helpFunction={this.openHelpPage}
        />
      </>
    )
  }
}
