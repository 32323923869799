import React, { useState, useEffect } from 'react'

import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { A10Select, A10Form, A10Icon } from '@gui-libraries/widgets'
import { IConfigObjectProps } from '../MicroServiceConfig'
import Messages from 'src/locale/en/Messages'
import { setupA10Container } from '@gui-libraries/framework'
import A10Panel from 'src/components/ADC/A10Panel'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import _ from 'lodash'

const messages = new Messages()

interface IAnalyticsConfigProps {
  show: boolean
  title: string
  logRetaintionPeriod: IConfigObjectProps
  automaticLogRate: IConfigObjectProps
  alertRetaintionPeriod: IConfigObjectProps
  onClose: () => void
  onActionCompleted: (value: IConfigObjectProps[]) => void
  onRef?: (ref: any) => void
  form: any
}

const AnalyticsConfigEditForm: React.FC<IAnalyticsConfigProps> = ({
  show,
  title,
  logRetaintionPeriod,
  automaticLogRate,
  alertRetaintionPeriod,
  form,
  onRef,
  onClose,
  onActionCompleted,
}) => {
  const [logRetaintionPeriodInput, setLogRetaintionPeriod] = useState(
    logRetaintionPeriod,
  )
  const [automaticLogRateInput, setAutomaticLogRate] = useState(
    automaticLogRate,
  )
  const [alertRetaintionPeriodInput, setAlertRetaintionPeriod] = useState(
    alertRetaintionPeriod,
  )

  const { getFieldDecorator } = form
  const mapOptions = [
    {
      value: '6',
      key: '6 Hours',
    },
    {
      value: '12',
      key: '12 Hours',
    },
    {
      value: '24',
      key: '24 Hours',
    },
  ]
  const alertOptions = [
    {
      value: '1',
      key: '1 Month',
    },
    {
      value: '3',
      key: '3 Months',
    },
    {
      value: '6',
      key: '6 Months',
    },
    {
      value: '12',
      key: '12 Months',
    },
  ]
  const onOkButtonClick = () => {
    const editPayload = [
      logRetaintionPeriodInput,
      automaticLogRateInput,
      alertRetaintionPeriodInput,
    ]
    onActionCompleted(
      editPayload.filter(input => input !== null && input !== undefined),
    )
  }

  const handleAutomaticLogRateChange = (value: string) => {
    setAutomaticLogRate({ ...automaticLogRateInput, property_value: value })
  }

  useEffect(() => {
    if (_.isFunction(onRef)) {
      onRef(this)
    }
    setLogRetaintionPeriod(logRetaintionPeriod)
    setAutomaticLogRate(automaticLogRate)
    setAlertRetaintionPeriod(alertRetaintionPeriod)
  }, [logRetaintionPeriod, automaticLogRate, alertRetaintionPeriod])

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  return (
    <FormatSlidingPage
      isOpen={show}
      onRequestOk={onOkButtonClick}
      onRequestClose={onClose}
      isRightCancel
      title={title}
    >
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel showHeader={false}>
          <A10Form.Item
            className="mb-10"
            {...formItemLayout}
            label={
              <>
                <span className="label">Automatic Log Rate</span>
                <span>
                  <HelpInfo
                    placement="left"
                    title={messages.INACTIVITY_TIMEOUT}
                    helpKey="HELP_MS_ANALYTICS_AUTO_LOG_RATE"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('rate.adapter.enabled', {
              rules: [
                { required: true, message: messages.EMPTY_AUTOMATIC_LOG_RATE },
              ],
              initialValue: automaticLogRateInput?.property_value,
            })(
              <A10Select
                data-testid="log-rate-input"
                style={{ width: '100%' }}
                showSearch
                placeholder="Please Select a Log Rate"
                onChange={handleAutomaticLogRateChange}
              >
                <A10Select.Option key={1} value="true">
                  True
                </A10Select.Option>
                <A10Select.Option key={2} value="false">
                  False
                </A10Select.Option>
              </A10Select>,
            )}
          </A10Form.Item>
          <A10Form.Item
            className="mb-10"
            {...formItemLayout}
            label={
              <>
                <span className="label">Log Retention Period</span>
                <span>
                  <HelpInfo
                    placement="left"
                    title={messages.INACTIVITY_TIMEOUT}
                    helpKey="HELP_MS_ANALYTICS_LOG_RP"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('metric-store-proc.log.retention.period.hrs', {
              rules: [
                {
                  required: true,
                  message: messages.EMPTY_LOG_RETAINTION_PERIOD,
                },
              ],
              initialValue: logRetaintionPeriodInput?.property_value,
            })(
              <A10Select
                data-testid="log-retaintion-input"
                title="log-retaintion-input"
                key="log-retaintion-input"
                placeholder="Please select log retaintion period"
                allowClear={false}
                onChange={value => {
                  setLogRetaintionPeriod({
                    ...logRetaintionPeriodInput,
                    property_value: value,
                  })
                }}
              >
                {mapOptions.map(option => {
                  return (
                    <A10Select.Option value={option.value} key={option.key}>
                      {option.key}
                    </A10Select.Option>
                  )
                })}
              </A10Select>,
            )}
          </A10Form.Item>
          <A10Form.Item
            className="mb-0"
            {...formItemLayout}
            label={
              <>
                <span className="label">Alert Retention Period</span>
                <span>
                  <HelpInfo
                    placement="left"
                    title={messages.INACTIVITY_TIMEOUT}
                    helpKey="HELP_MS_ANALYTICS_ARP"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator(
              'metric-aggregation-proc.event.retention.period.months',
              {
                rules: [
                  {
                    required: true,
                    message: messages.EMPTY_ALERT_RETAINTION_PERIOD,
                  },
                ],
                initialValue: alertRetaintionPeriodInput?.property_value,
              },
            )(
              <A10Select
                data-testid="alert-retaintion-input"
                title="alert-retaintion-input"
                key="alert-retaintion-input"
                placeholder="Please select alert retaintion period"
                allowClear={false}
                onChange={value => {
                  setAlertRetaintionPeriod({
                    ...alertRetaintionPeriodInput,
                    property_value: value,
                  })
                }}
              >
                {alertOptions.map(option => {
                  return (
                    <A10Select.Option value={option.value} key={option.key}>
                      {option.key}
                    </A10Select.Option>
                  )
                })}
              </A10Select>,
            )}
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    </FormatSlidingPage>
  )
}

export default setupA10Container(A10Form.create()(AnalyticsConfigEditForm))
