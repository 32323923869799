import { UrlService, AjaxService } from 'src/services'

export class PlatformService {
  // Instantiating instances of imported services
  UrlService = new UrlService()
  AjaxService = new AjaxService()

  getAPIServerDetails = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_API_SERVER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAnalyticsConfigDetails = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_ANALYTICS_CONFIG')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getLogRateDetails = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LOG_RATE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  postConfigDetails = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('POST_CONFIG')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

}
export default PlatformService
