import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'

export interface IDashboardProps extends IA10ContainerDefaultProps {
  applicationName: string
}
export interface IDashboardState {
  redirectUrl: string
}

class DashboardRouter extends A10Container<IDashboardProps, IDashboardState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>

  componentWillMount() {
    const {
      storage: { remove },
    } = this.context

    remove.CONTEXT_LEVEL()
    remove.DGF_SELECTED_TENANT()
    remove.DGF_ACCOUNTS()
    remove.SELECTEDCONTEXT()
    remove.AUTH()
    remove.CONTEXT()
    const appName = this.props.match.params.applicationName
    const itemName = this.props.match.params.itemName
    let redirectUrl
    if (appName === 'provider') {
      redirectUrl = '/controller/Home/provider'
    } else if (appName === 'tenant') {
      redirectUrl = '/controller/Home/tenant/' + itemName
    } else if (appName === 'cluster') {
      redirectUrl =
        '/controller/Analytics/cluster/' + (itemName ? itemName : '')
    }

    if (redirectUrl) {
      this.setState({
        redirectUrl,
      })
    }
  }
  renderRedirect = (url: string) => {
    return <Redirect to={url} />
  }

  render() {
    const { redirectUrl } = this.state
    return this.renderRedirect(redirectUrl)
  }
}

export default setupA10Container(DashboardRouter)
