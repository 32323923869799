import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Message } from '@gui-libraries/widgets'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import RestoreForm, { IFormData } from './index'
import storage from 'src/libraries/storage'
import globalConfig from 'src/settings/config'

export interface ISlidingRestoreProps extends IA10ContainerDefaultProps {
  isOpen: boolean
  deviceName?: string
  backup?: IObject
  onRequestClose: () => void
}

export interface ISlidingRestoreStates {
  provider: string
  formData?: IFormData
  disableSave: boolean
}

class SlidingRestore extends A10Container<
  ISlidingRestoreProps,
  ISlidingRestoreStates
> {
  constructor(props: ISlidingRestoreProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER || '',
      disableSave: true,
    }
  }

  componentWillReceiveProps(nextProps: IObject) {
    const state = { ...this.state }
    const { backup, deviceName } = nextProps
    if (backup && parent) {
      state.formData = {
        backup,
        description: null,
        'src-device-name': deviceName,
        'dst-device-name': null,
      }
      this.setState(state)
    }
  }

  onFormChange(data: IFormData) {
    const state = { ...this.state }
    state.disableSave = !(data.backup && data['dst-device-name'])
    state.formData = data
    this.setState(state)
  }

  onRequestOk() {
    const { onRequestClose } = this.props
    this.saveRestore().then(({ data }) => {
      A10Message.success('Config Restore created successfully.')
      onRequestClose()
    })
  }

  async saveRestore() {
    const {
      EPIC_DEPENDENCIES: { httpClient },
    } = globalConfig
    const formData = this.state.formData as IFormData
    const restorePayload = {
      restore: {
        'backup-id': formData.backup ? formData.backup.uuid : '',
        'device-name': formData['dst-device-name'],
      },
    }
    const url = `/hocapi/v1/provider/${this.state.provider}/device/${formData['dst-device-name']}/_restore/`

    return httpClient.post(url, restorePayload, { absoluteBasePath: true })
  }

  render() {
    const { isOpen, onRequestClose } = this.props
    const { disableSave, formData } = this.state
    const onRequestOk = this.onRequestOk.bind(this)
    const onFormChange = this.onFormChange.bind(this)
    const title = 'Restore Backup'

    return (
      <FormatSlidingPage
        isOpen={isOpen}
        onRequestOk={onRequestOk}
        onRequestClose={onRequestClose}
        title={title}
        description="Please select a backup instance and targeted device."
        disableSave={disableSave}
        saveText="Restore"
      >
        <RestoreForm onChange={onFormChange} formData={formData} />
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(SlidingRestore)
