import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import storage from 'src/libraries/storage'
import VersionComparison from './index'

export interface ISlidingVersionCompareProps extends IA10ContainerDefaultProps {
  isOpen: boolean
  type: string
  onRequestClose: (clickDeploy: boolean, id?: string) => void
  onClickSave: () => void
  partition: IObject
  additionalID?: string
}

export interface ISlidingVersionCompareStates {
  provider: string
  disableSave: boolean
}

class SlidingVersionCompare extends A10Container<
  ISlidingVersionCompareProps,
  ISlidingVersionCompareStates
> {
  constructor(props: ISlidingVersionCompareProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER,
      disableSave: true,
    }
  }

  onRequestOk = () => {
    const { onRequestClose } = this.props
    onRequestClose(false)
  }

  render() {
    const { isOpen, onRequestClose, partition, type, onClickSave } = this.props
    if (!partition) {
      return null
    }
    let keyword = 'Logical'
    if (type === 'device') {
      keyword = 'Device'
    }
    const title = `Versions of ${keyword} Partition ` + partition.name
    return (
      <FormatSlidingPage
        isOpen={isOpen}
        modalSize="large"
        title={title}
        onRequestOk={this.onRequestOk}
        onRequestClose={onRequestClose}
        isShowFooterButtons={false}
        isRightCancel={true}
      >
        <VersionComparison
          type={type}
          partition={partition}
          onClickButton={onRequestClose}
          onClickSave={onClickSave}
          additionalID={this.props.additionalID}
        />
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(SlidingVersionCompare)
