import React from 'react'
import { A10Icon } from '@gui-libraries/widgets'

const setupIconStyle = { fontSize: 34 }

const SetupOrganization = () => {
  return (
    <div id="gettingStarted-2">
      <div className="gettingStarted-content-header">Set up Organization</div>
      <div className="gettingStarted-content-horizontal">
        <div className="horizontal-item">
          <div className="horizontal-title-items">
            <div className="horizontal-icon">
              <A10Icon
                app="harmony-controller"
                type="provider"
                style={setupIconStyle}
              />
            </div>
            <div className="horizontal-title">Provider (Completed)</div>
          </div>

          <div className="horizontal-rectangle" id="providerImage" />
          <div className="horizontal-text">
            A provider account has already been created for you.
          </div>
        </div>
        <div className="horizontal-item">
          <div className="horizontal-title-items">
            <div className="horizontal-icon">
              <A10Icon
                app="harmony-controller"
                type="authentication"
                style={setupIconStyle}
              />
            </div>
            <div className="horizontal-title">
              Set up Authentication (Optional)
            </div>
          </div>

          <div className="horizontal-rectangle" id="authenticationImage" />
          <div className="horizontal-text">
            The default authentication method is local. You can change it to
            remote authentication, if needed. It can also be changed later. You
            can also set up multi factor authentication (MFA) when
            authentication method is local.
          </div>
        </div>
        <div className="horizontal-item">
          <div className="horizontal-title-items">
            <div className="horizontal-icon">
              <A10Icon
                app="harmony-controller"
                type="tenant"
                style={setupIconStyle}
              />
            </div>
            <div className="horizontal-title">Create Tenants (Required)</div>
          </div>

          <div className="horizontal-rectangle" id="createTenantsImage" />
          <div className="horizontal-text">
            Create one or more tenants based on your organizational need.
            Different Thunder partitions containing Application Services can be
            assigned to different tenants.
          </div>
        </div>
        <div className="horizontal-item">
          <div className="horizontal-title-items">
            <div className="horizontal-icon">
              <A10Icon
                app="harmony-controller"
                type="users-1"
                style={setupIconStyle}
              />
            </div>
            <div className="horizontal-title">
              Add Users & Access Group (Recommended)
            </div>
          </div>

          <div className="horizontal-rectangle" id="createUsersImage" />
          <div className="horizontal-text">
            Create users with various roles.
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetupOrganization
