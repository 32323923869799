import React, { useState } from 'react'
import { A10Row, A10Radio } from '@gui-libraries/widgets'
import { RadioChangeEvent } from '@gui-libraries/widgets/node_modules/antd/lib/radio/radioButton'

import {
  ContentSection,
  ContentBody,
} from 'src/components/shared/ContentSection'
import styles from './styles/index.module.less'

interface ILogsProps {}

const TABS = ['audit', 'license', 'storage']
const TAB_MAP = {
  audit: 'Audit',
  license: 'License',
  storage: 'Storage',
}

const Logs: React.FC<ILogsProps> = props => {
  const [tab, setTab] = useState(TABS[0])

  const renderTabContent = () => {
    switch (tab) {
      case 'audit': {
        return <div>Audit</div>
      }
      case 'license': {
        return <div>License</div>
      }
      case 'storage': {
        return <div>Storage</div>
      }
      default: {
        return null
      }
    }
  }

  const onChange = (e: RadioChangeEvent) => {
    if (e?.target?.value) {
      const tab = e.target.value
      setTab(tab)
    }
  }

  return (
    <ContentSection>
      <A10Row className={styles.tabsContainer} type="flex" justify="center">
        <A10Radio.Group buttonStyle="tab" onChange={onChange} value={tab}>
          {TABS.map(TAB => {
            return <A10Radio.Button value={TAB}>{TAB_MAP[TAB]}</A10Radio.Button>
          })}
        </A10Radio.Group>
      </A10Row>
      {renderTabContent()}
    </ContentSection>
  )
}

export default Logs
