import './styles/index.less'

import { A10Col, A10Form, A10Input, A10Row } from '@gui-libraries/widgets'
import React, { useEffect, useState } from 'react'
import { SystemService, Utilities } from 'src/services/index'

import A10Panel from 'src/components/ADC/A10Panel'
import { ActionButton } from 'src/components/shared/ActionButton'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import ListPanel from 'src/components/ADC/ListPanel'
import Messages from 'src/locale/en/Messages'
import ReactLoading from 'react-loading'
import { setupA10Container } from '@gui-libraries/framework'

const messages = new Messages()
const utilities = new Utilities()
const systemService = new SystemService()

const handleDeviceCommunicationPost = async (
  payload: IObject,
  successMessage: string,
  failureMessage: string,
) => {
  let apiResponse: boolean = false
  try {
    const response = await systemService.updateDeviceCommunicationConfig(
      null,
      payload,
      null,
    )
    if (response) {
      utilities.showMessage(successMessage, 1, 1)
      apiResponse = true
    }
  } catch {
    utilities.showMessage(failureMessage, 0, 1)
  }

  return apiResponse
}

export const getDeviceCommunicationData = async () => {
  let apiResponse: any = []
  try {
    const {
      data: response,
    } = await systemService.getDeviceCommunicationConfigData(null, null, null)
    apiResponse = response
  } catch (error) {
    apiResponse = []
  }

  return apiResponse
}

const DeviceCommunication: React.FC<IObject> = props => {
  const [isLoading, setLoading] = useState(false)
  const [showModalForm, setShowModalForm] = useState(false)
  const [maxAllowedTime, setMaxAllowedTime] = useState(null)
  const [displayMaxAllowedTime, setDisplayMaxAllowedTime] = useState(null)
  const [disableSave, setDisableSave] = useState(true)

  const { getFieldDecorator } = props.form
  useEffect(() => {
    async function fetchDeviceCommunicationData() {
      setLoading(true)
      const data: IObject = await getDeviceCommunicationData()
      setMaxAllowedTime(data)
      setDisplayMaxAllowedTime(data)
      setLoading(false)
    }

    fetchDeviceCommunicationData()
  }, [])

  const onEditOkClick = async () => {
    setLoading(true)
    const payload: IObject = {
      key: 'jwt_exp_duration',
      value: maxAllowedTime,
    }
    const response = await handleDeviceCommunicationPost(
      payload,
      'SUCCESS_DEVICE_CONFIG_UPDATE',
      'FAILED_DEVICE_CONFIG_UPDATE',
    )
    if (response) {
      const data: IObject = await getDeviceCommunicationData()
      setDisplayMaxAllowedTime(data)
      setMaxAllowedTime(data)
    }
    setShowModalForm(false)
    setLoading(false)
  }

  const onDifferenceInputChange = (event: IObject) => {
    const maxDifference = event?.target?.value
    if (maxDifference && maxDifference >= 90 && maxDifference <= 900) {
      setDisableSave(false)
    } else {
      setDisableSave(true)
    }
    setMaxAllowedTime(maxDifference)
  }
  const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12 },
  }
  return (
    <>
      <A10Row className="device-content-body">
        <A10Col className="headerTitle">
          <ListPanel
            title={messages.DEVICE_COMMUNICATION}
            rightContent={
              <ActionButton
                style={{marginTop: '6px'}}
                text="Edit"
                testID="edit-button"
                onClick={() => setShowModalForm(true)}
                iconProps={{ app: 'global', type: 'edit' }}
              />
            }
          >
            <A10Row
              type="flex"
              className="no-margin font-14"
              style={{ flexDirection: 'column' }}
            >
              <A10Col className="device-section-title">
                {messages.MAX_ALLOWED_TIME_DIFF}
              </A10Col>
              {isLoading ? (
                <A10Col>
                  <ReactLoading
                    type="bars"
                    color="#4a90e2"
                    height={40}
                    width={40}
                  />
                </A10Col>
              ) : (
                <A10Col className="device-section-value">
                  {displayMaxAllowedTime} sec
                </A10Col>
              )}
            </A10Row>
          </ListPanel>
        </A10Col>
      </A10Row>

      <FormatSlidingPage
        isOpen={showModalForm}
        onRequestClose={() => setShowModalForm(false)}
        title={messages.EDIT_DEVICE_COMMUNICATION}
        onRequestOk={onEditOkClick}
        disableSave={disableSave}
      >
        <A10Form hideRequiredMark={true} layout="horizontal">
          <A10Panel showHeader={false}>
            <A10Form.Item
            className='form-row'
              {...formItemLayout}
              label={
                <A10Row className='device-field'>
                  <span className='mandatoryField'></span>
                  <p className="label wrap-text">
                    <span>{messages.MAX_ALLOWED_TIME_DIFF}</span>
                  </p>
                </A10Row>
              }
            >
              {getFieldDecorator('time-diff', {
                rules: [
                  { required: true, message: messages.EMPTY_TIME_DIFFERENCE },
                  {
                    pattern: utilities.VALIDATE_RANGE,
                    message: messages.INVALID_TIME_DIFFERENCE,
                  },
                ],
                initialValue: maxAllowedTime,
              })(
                <A10Input
                  addonAfter="Sec"
                  type="number"
                  testID="max-allowed-time-input"
                  className="input-component"
                  onChange={event => {
                    onDifferenceInputChange(event)
                  }}
                  placeholder={messages.MAX_MIN_RANGE}
                />,
              )}
            </A10Form.Item>
          </A10Panel>
        </A10Form>
      </FormatSlidingPage>
    </>
  )
}

export default setupA10Container(A10Form.create()(DeviceCommunication))
