import React from 'react'
import {
  A10Badge,
  A10Table,
  A10Tooltip,
  A10Row,
  A10Tag,
  A10Tabs,
  A10Select,
  A10Collapse,
  A10Status,
  A10Alert,
} from '@gui-libraries/widgets'

import styles from './styles/index.module.less'

const { TabPane } = A10Tabs
const { Option } = A10Select
const { Column, ColumnGroup } = A10Table

export interface IScaleoutTrafficProps {
  data: IObject
  dataMap: IObject
}

interface IScaleoutTrafficState {
  useGroupMap: IObject
  deviceMap: IObject
  dataSet: boolean
  groupsMap: IObject
  selectedDevice: string
  deviceList: string[]
  idMap: IObject
  misMatchMap: IObject
  groupMisMatchMap: IObject
  data: IObject
}

class ScaleoutMap extends React.Component<
  IScaleoutTrafficProps,
  IScaleoutTrafficState
> {
  state = {
    userGroupMap: {},
    deviceMap: {},
    dataSet: false,
    groupsMap: {},
    selectedDevice: '',
    deviceList: [],
    idMap: {},
    misMatchMap: {},
    groupMisMatchMap: {},
    data: {},
  }

  setData = () => {
    let data: any = this.props.data
    let dataMap: any = this.props.dataMap
    let deviceKeys = Object.keys(data)
    let deviceMap: any = {}
    let usermap: any = {}
    let groupsMap: any = {}
    let calculator: any = {}
    let idMap: any = {}
    let misMatchMap = {}
    let groupMisMatchMap = {}
    if (deviceKeys.length) {
      calculator = data
      deviceKeys = Object.keys(calculator) // getting refrehsed keys with data
      let versionString: any = ''
      let response = []
      for (let i = 0; i < deviceKeys.length; i++) {
        versionString = dataMap.details
          ? dataMap.details[deviceKeys[i]]
            ? dataMap.details[deviceKeys[i]]['output']
              ? dataMap.details[deviceKeys[i]]['output']['result']
                ? dataMap.details[deviceKeys[i]]['output']['result']['response']
                  ? dataMap.details[deviceKeys[i]]['output']['result'][
                      'response'
                    ].length
                    ? dataMap.details[deviceKeys[i]]['output']['result'][
                        'response'
                      ][0]
                    : 'NA'
                  : 'NA'
                : 'NA'
              : 'NA'
            : 'NA'
          : 'NA'
        versionString =
          versionString.indexOf('config:') > -1
            ? versionString.split('config:')
            : 'NA'
        versionString =
          versionString !== 'NA' ? versionString[1].split(')') : 'NA'
        versionString = versionString.length ? Number(versionString[0]) : 'NA'

        idMap[versionString] = deviceKeys[i]
        idMap[deviceKeys[i]] = versionString !== 'NA' ? versionString : i + 1
        response = calculator[deviceKeys[i]]
        calculator[deviceKeys[i]] = response
        for (let j = 0; j < response.length; j++) {
          //   {

          //     "cur-active": 2,

          //     "cur-standby": 1,

          //     "new-active": 0,

          //     "new-standby": 0,

          //     "user-group": 255

          // }
          let line = [
            response[j]['user-group'],
            response[j]['cur-active'],
            response[j]['cur-standby'],
            response[j]['new-active'],
            response[j]['new-standby'],
          ]
          if (line[1] !== 0) {
            if (groupsMap[line[1]]) {
              if (groupsMap[line[1]].active.indexOf(line[0]) < 0) {
                groupsMap[line[1]].active.push(line[0])
              }
            } else {
              groupsMap[line[1]] = {
                active: [line[0]],
                standby: [],
                new_active: [],
                new_standby: [],
              }
            }
          }
          if (line[2] !== 0) {
            if (groupsMap[line[2]]) {
              if (groupsMap[line[2]].standby.indexOf(line[0]) < 0) {
                groupsMap[line[2]].standby.push(line[0])
              }
            } else {
              groupsMap[line[2]] = {
                active: [],
                standby: [line[0]],
                new_active: [],
                new_standby: [],
              }
            }
          }
          if (line[3] !== 0) {
            if (groupsMap[line[3]]) {
              if (groupsMap[line[3]].new_active.indexOf(line[0]) < 0) {
                groupsMap[line[3]].new_active.push(line[0])
              }
            } else {
              groupsMap[line[3]] = {
                active: [],
                standby: [],
                new_active: [line[0]],
                new_standby: [],
              }
            }
          }
          if (line[4] !== 0) {
            if (groupsMap[line[4]]) {
              if (groupsMap[line[4]].new_standby.indexOf(line[0]) < 0) {
                groupsMap[line[4]].new_standby.push(line[0])
              }
            } else {
              groupsMap[line[4]] = {
                active: [],
                standby: [],
                new_active: [],
                new_standby: [line[0]],
              }
            }
          }

          if (usermap[line[0]]) {
            let device_keys = Object.keys(usermap[line[0]])
            let mismatch = 0
            for (let k = 0; k < device_keys.length; k++) {
              let device_group = usermap[line[0]][device_keys[k]]
              if (
                device_group['active'] !== line[1] ||
                device_group['standby'] !== line[2] ||
                device_group['new_active'] !== line[3] ||
                device_group['new_standby'] !== line[4]
              ) {
                usermap[line[0]][device_keys[k]]['mismatch']++
                misMatchMap[device_keys[k]] = true
                mismatch++
                groupMisMatchMap[line[0]] = true
                if (device_group['active'] !== line[1]) {
                  groupMisMatchMap['active'] = true
                }
                if (device_group['standby'] !== line[2]) {
                  groupMisMatchMap['standby'] = true
                }
                if (device_group['new_active'] !== line[3]) {
                  groupMisMatchMap['new_active'] = true
                }
                if (device_group['new_standby'] !== line[4]) {
                  groupMisMatchMap['new_standby'] = true
                }
              }
            }
            if (mismatch) {
              misMatchMap[deviceKeys[i]] = true
            }
            usermap[line[0]][deviceKeys[i]] = {
              name: line[0],
              subscriberIp: '*.*.*.' + line[0],
              active: line[1],
              standby: line[2],
              new_active: line[3],
              new_standby: line[4],
              mismatch: mismatch,
            }
            if (deviceMap[deviceKeys[i]]) {
              deviceMap[deviceKeys[i]][line[0]] = {
                name: line[0],
                subscriberIp: '*.*.*.' + line[0],
                active: line[1],
                standby: line[2],
                new_active: line[3],
                new_standby: line[4],
              }
            } else {
              deviceMap[deviceKeys[i]] = {
                [line[0]]: {
                  name: line[0],
                  subscriberIp: '*.*.*.' + line[0],
                  active: line[1],
                  standby: line[2],
                  new_active: line[3],
                  new_standby: line[4],
                },
              }
            }
          } else {
            usermap[line[0]] = {
              [deviceKeys[i]]: {
                name: line[0],
                subscriberIp: '*.*.*.' + line[0],
                active: line[1],
                standby: line[2],
                new_active: line[3],
                new_standby: line[4],
                mismatch: 0,
              },
            }
            if (deviceMap[deviceKeys[i]]) {
              deviceMap[deviceKeys[i]][line[0]] = {
                name: line[0],
                subscriberIp: '*.*.*.' + line[0],
                active: line[1],
                standby: line[2],
                new_active: line[3],
                new_standby: line[4],
              }
            } else {
              deviceMap[deviceKeys[i]] = {
                [line[0]]: {
                  name: line[0],
                  subscriberIp: '*.*.*.' + line[0],
                  active: line[1],
                  standby: line[2],
                  new_active: line[3],
                  new_standby: line[4],
                },
              }
            }
          }
        }
      }
    } else {
      // no data found for the commands scaleout traffic map
      this.setState({
        dataSet: true,
        userGroupMap: {},
        deviceMap: {},
        groupsMap: {},
        selectedDevice: '',
      })
    }
    this.setState({
      dataSet: true,
      userGroupMap: usermap,
      deviceMap: deviceMap,
      groupsMap: groupsMap,
      selectedDevice: deviceKeys[0],
      deviceList: deviceKeys,
      idMap: idMap,
      misMatchMap: misMatchMap,
      groupMisMatchMap: groupMisMatchMap,
    })
  }
  updateDevice = (device: any) => {
    this.setState({
      selectedDevice: device,
    })
  }
  componentDidMount = () => {
    this.setData()
  }

  renderValueWithStatusUserGroup = (group: any) => {
    let mismatches = this.state.userGroupMap[group][this.state.selectedDevice][
      'mismatch'
    ]
    if (mismatches > 0) {
      return (
        <>
          <A10Tooltip title="Mismatch">
            <A10Badge status="error" />
          </A10Tooltip>
          {group}
        </>
      )
    } else {
      return (
        <>
          <A10Tooltip title="In Sync">
            <A10Badge status="success" />
          </A10Tooltip>
          {group}
        </>
      )
    }
  }

  renderValueWithStatusDevice = (device: any, group: any) => {
    if (
      device === undefined ||
      device === '' ||
      !this.state.userGroupMap[group][device]
    ) {
      return <></>
    }
    let mismatches = this.state.userGroupMap[group][device]['mismatch']
    if (mismatches > 0) {
      return (
        <>
          <A10Tooltip title="Mismatch">
            <A10Badge status="error" />
          </A10Tooltip>
          {device} ({this.state.idMap[device]})
        </>
      )
    } else {
      return (
        <>
          <A10Tooltip title="In Sync">
            <A10Badge status="success" />
          </A10Tooltip>
          {device} ({this.state.idMap[device]})
        </>
      )
    }
  }

  renderValueWithStatus = (device: any) => {
    let mismatch = this.state.misMatchMap[device]
    if (mismatch) {
      return (
        <>
          <A10Tooltip title="Mismatch">
            <A10Badge status="error" />
          </A10Tooltip>
          {device}
        </>
      )
    } else {
      return (
        <>
          <A10Tooltip title="In Sync">
            <A10Badge status="success" />
          </A10Tooltip>
          {device}
        </>
      )
    }
  }

  renderUserGroup = () => {
    let selectedDeviceGroup = this.state.deviceMap[this.state.selectedDevice]
      ? this.state.deviceMap[this.state.selectedDevice]
      : {}
    let groups = Object.keys(selectedDeviceGroup)
    let dataSource: any = []
    for (let i = 0; i < groups.length; i++) {
      let group: any = selectedDeviceGroup[groups[i]]
      dataSource.push({
        key: i,
        id: group.name,
        subscribersIp: group.subscriberIp,
        active: this.state.idMap[group.active]
          ? this.state.idMap[group.active]
          : '',
        standby: this.state.idMap[group.standby]
          ? this.state.idMap[group.standby]
          : '',
        newActive: this.state.idMap[group.new_active]
          ? this.state.idMap[group.new_active]
          : '',
        newStandby: this.state.idMap[group.new_standby]
          ? this.state.idMap[group.new_standby]
          : '',
        mismatches: this.state.userGroupMap[group.name][
          this.state.selectedDevice
        ].mismatch,
      })
    }
    // let groups = Object.keys(selectedDeviceGroup)
    return (
      <A10Table dataSource={dataSource} scroll={{ y: 600 }}>
        <ColumnGroup title="User Group">
          <Column
            title="ID"
            dataIndex="id"
            sorter={(a: any, b: any) => a.id - b.id}
            render={value => this.renderValueWithStatusUserGroup(value)}
          />
          <Column
            title="Subscribers IP"
            dataIndex="subscribersIp"
            width="14%"
            sorter={(a: any, b: any) =>
              a.subscribersIp.localeCompare(b.subscribersIp)
            }
          />
        </ColumnGroup>
        <ColumnGroup title="Device Distribution">
          <Column
            title="Active"
            dataIndex="active"
            width="14%"
            sorter={(a: any, b: any) => a.active.localeCompare(b.active)}
            render={(value, record) =>
              this.renderValueWithStatusDevice(value, record.id)
            }
          />
          <Column
            title="Standby"
            dataIndex="standby"
            width="14%"
            sorter={(a: any, b: any) => a.standby.localeCompare(b.standby)}
            render={(value, record) =>
              this.renderValueWithStatusDevice(value, record.id)
            }
          />
          <Column
            title="New Active"
            dataIndex="newActive"
            width="14%"
            sorter={(a: any, b: any) => a.newActive.localeCompare(b.newActive)}
            render={(value, record) =>
              this.renderValueWithStatusDevice(value, record.id)
            }
          />
          <Column
            title="New Standby"
            dataIndex="newStandby"
            width="14%"
            sorter={(a: any, b: any) =>
              a.newStandby.localeCompare(b.newStandby)
            }
            render={(value, record) =>
              this.renderValueWithStatusDevice(value, record.id)
            }
          />
          <Column
            title="# Mismatches"
            dataIndex="mismatches"
            width="14%"
            sorter={(a: any, b: any) => a.mismatches - b.mismatches}
          />
        </ColumnGroup>
      </A10Table>
    )
  }
  renderUserGroupsByDevice = (device: any) => {
    let map = device.map
    const renderTags = (tags: string[]) =>
      tags.map(tag => (
        <A10Tag className={this.state.groupMisMatchMap[tag] && 'error'}>
          {tag}
        </A10Tag>
      ))
    return (
      <div className={styles.userGroupsDevice}>
        <A10Row>
          <span className={styles.title}>User Groups</span>
        </A10Row>
        <A10Row>
          <div>
            <span>Active</span>
          </div>
          <div>{map.active && renderTags(map.active)}</div>
        </A10Row>
        <A10Row>
          <div>
            <span>Standby</span>
          </div>
          <div>{map.standby && renderTags(map.standby)}</div>
        </A10Row>
        <A10Row>
          <div>
            <span>New Active</span>
          </div>
          <div>{map.new_active && renderTags(map.new_active)}</div>
        </A10Row>
        <A10Row>
          <div>
            <span>New Standby</span>
          </div>
          <div>{map.new_standby && renderTags(map.new_standby)}</div>
        </A10Row>
      </div>
    )
  }
  renderValueWithStatusDummy = (
    status: string | undefined,
    value: string | number,
  ) => (
    <>
      {status === 'up' ? (
        <A10Tooltip title="In Sync">
          <A10Badge status="success" />
        </A10Tooltip>
      ) : status === 'down' ? (
        <A10Tooltip title="Mismatch">
          <A10Badge status="error" />
        </A10Tooltip>
      ) : (
        <A10Tooltip title="In Sync">
          <A10Badge status="success" />
        </A10Tooltip>
      )}
      {value}
    </>
  )
  renderDeviceGroup = () => {
    let deviceKeys = Object.keys(this.state.groupsMap)
    let data = []
    for (let i = 0; i < deviceKeys.length; i++) {
      let deviceObj = this.state.groupsMap[deviceKeys[i]]
      let name = this.state.idMap[deviceKeys[i]] + ' (' + deviceKeys[i] + ')'
      if (name !== '') {
        data.push({
          key: i,
          displayedName: name,
          standby: deviceObj.standby
            ? [
                deviceObj.standby.length,
                this.state.groupMisMatchMap['standby'] ? 'down' : 'up',
              ]
            : [0, undefined],
          active: deviceObj.active
            ? [
                deviceObj.active.length,
                this.state.groupMisMatchMap['active'] ? 'down' : 'up',
              ]
            : [0, undefined],
          newStandby: deviceObj.new_standby
            ? [
                deviceObj.new_standby.length,
                this.state.groupMisMatchMap['new_standby'] ? 'down' : 'up',
              ]
            : [0, undefined],
          newActive: deviceObj.new_active
            ? [
                deviceObj.new_active.length,
                this.state.groupMisMatchMap['new_active'] ? 'down' : 'up',
              ]
            : [0, undefined],
          status: 'up',
          map: deviceObj,
        })
      }
    }
    const genProps = (column: string) => ({
      sorter: (a: any, b: any) => a[column][0] - b[column][0],
      render: (value: [number, string]) =>
        this.renderValueWithStatusDummy(value[1], value[0]),
    })
    return (
      <A10Table
        dataSource={data}
        expandedRowRender={this.renderUserGroupsByDevice}
        scroll={{ y: 600 }}
      >
        <ColumnGroup title="Device" align="left">
          <Column
            title="Name"
            dataIndex="displayedName"
            sorter={(a: any, b: any) =>
              a.displayedName.localeCompare(b.displayedName)
            }
            render={(value, record: any) => this.renderValueWithStatus(value)}
          />
        </ColumnGroup>
        <ColumnGroup title="User Group Distribution">
          <Column
            title="# Active"
            dataIndex="active"
            width="20%"
            {...genProps('active')}
          />
          <Column
            title="# Standby"
            dataIndex="standby"
            width="20%"
            {...genProps('standby')}
          />
          <Column
            title="# New Active"
            dataIndex="newActive"
            width="20%"
            {...genProps('newActive')}
          />
          <Column
            title="# New Standby"
            dataIndex="newStandby"
            width="20%"
            {...genProps('newStandby')}
          />
        </ColumnGroup>
      </A10Table>
    )
  }
  public render() {
    return (
      <A10Collapse
        defaultActiveKey={['1']}
        className={styles.scaleoutMap}
        bordered={false}
      >
        {this.state.dataSet ? (
          <A10Collapse.Panel
            header={
              <A10Tooltip
                title="This widget represents scaleout distribution map for the selected cluster"
                placement="bottom"
                className={styles.colName}
              >
                SCALEOUT DISTRIBUTION
              </A10Tooltip>
            }
            key="1"
          >
            <A10Tabs defaultActiveKey="1">
              <TabPane tab="User Group" key="1">
                <A10Select
                  className={styles.devicesSelect}
                  onChange={data => this.updateDevice(data)}
                  value={this.state.selectedDevice}
                  size="small"
                >
                  {this.state.deviceList.map((device: any, index: number) => {
                    return (
                      <Option value={device} key={index}>
                        <A10Status title="D" color="colorGreen">
                          {device}
                        </A10Status>
                      </Option>
                    )
                  })}
                </A10Select>
                {this.renderUserGroup()}
              </TabPane>
              <TabPane tab="Device" key="2">
                {this.renderDeviceGroup()}
              </TabPane>
            </A10Tabs>
          </A10Collapse.Panel>
        ) : (
          <A10Collapse.Panel
            header={
              <>
                <A10Tooltip
                  title="This widget represents scaleout distribution map for the selected cluster"
                  placement="bottom"
                  className={styles.colName}
                >
                  SCALEOUT DISTRIBUTION
                </A10Tooltip>
                <A10Alert
                  showIcon={true}
                  closable={false}
                  className={styles.noDataAlert}
                  message={
                    'User Group Distribution Mismatch Found in the devices of the Cluster!'
                  }
                  type="error"
                />
              </>
            }
            key="0"
            disabled
          ></A10Collapse.Panel>
        )}
      </A10Collapse>
    )
  }
}

export default ScaleoutMap
