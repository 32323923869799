import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { Messages } from 'src/locale/en'
import { Utilities } from 'src/services/Utilities'
import { A10Collapse } from '@gui-libraries/widgets'
import { Condition } from 'src/components/shared/Condition'
const styles = require('./styles/triggerdetails.scss')

export interface ITriggerDetailsObj {
  def_name: string
  object_type: string
  definition: any
  object_instance: any
  condition: any
  trigger_message: string
  metadata: IObject
}

export interface ITriggerDetailsProps extends IA10ContainerDefaultProps {
  trigger: ITriggerDetailsObj
  isBasic: boolean
}

export interface ITriggerDetailsState {
  trigger: any
  isBasic: boolean
}

export interface ITriggerDetailsState {}
class TriggerDetails extends A10Container<
  ITriggerDetailsProps,
  ITriggerDetailsState
> {
  static getDerivedStateFromProps(
    nextProps: ITriggerDetailsProps,
    prevState: ITriggerDetailsState,
  ) {
    return {
      trigger: {
        triggerName: nextProps.trigger.def_name,
        objectType: nextProps.trigger.definition.object_type,
        objectInstance: {
          level1: 'Cluster 1',
          level2: 'Device 1',
          level3: 'P1',
        },
        condition: nextProps.trigger.definition.conditions,
        triggerMessage: nextProps.trigger.definition.trigger_message,
        category: nextProps.trigger.definition.category,
        subCategory: nextProps.trigger.definition['sub-category'],
        window: nextProps.trigger.definition.window,
        metadata: nextProps.trigger.metadata,
      },
      isBasic: nextProps.isBasic,
    }
  }
  Messages = new Messages()
  Utilities = new Utilities()
  constructor(props: any) {
    super(props)
    // const { trigger } = this.props
    // this.trigger = {
    //   triggerName: trigger.def_name,
    //   objectType: trigger.definition.object_type,
    //   objectInstance: { level1: 'Cluster 1', level2: 'Device 1', level3: 'P1' },
    //   condition: trigger.definition.conditions,
    //   triggerMessage: trigger.definition.trigger_message,
    //   category: trigger.definition.category,
    //   subCategory: trigger.definition.subCategory,
    //   window: trigger.definition.window,
    // }
  }
  render() {
    const {
      triggerName,
      triggerMessage,
      objectType,
      condition,
      category,
      subCategory,
      window,
      metadata,
    } = this.state.trigger
    return (
      <div className="row no-margin rowDetails">
        {!this.state.isBasic ? (
          <>
            {/* Grid for Trigger Details in Trigger */}
            <div className="col-md-11">
              <A10Collapse bordered={false} defaultActiveKey={['1']}>
                <A10Collapse.Panel
                  header="Trigger Details"
                  key="1"
                  className="no-border"
                >
                  <div className="row section">
                    <div className="block">
                      <span className="title">
                        {this.Messages.TRIGGER_NAME}
                      </span>
                      <span className="monitor-value">
                        {this.Utilities.returnValue(triggerName)}
                      </span>
                    </div>
                    <div className="block">
                      <span className="title">
                        {this.Messages.CATEGORY_TYPE}
                      </span>
                      <span className="monitor-value">
                        {this.Utilities.returnValue(category)}
                      </span>
                    </div>
                    <div className="block">
                      <span className="title">
                        {this.Messages.SUBCATEGORY_TYPE_DOT}
                      </span>
                      <span className="monitor-value">
                        {this.Utilities.returnValue(subCategory)}
                      </span>
                    </div>
                    <div className="block">
                      <span className="title">{this.Messages.OBJECT_TYPE}</span>
                      <span className="monitor-value">
                        {this.Utilities.returnValue(objectType)}
                      </span>
                    </div>
                  </div>

                  <div className="row section">
                    {window && window.type ? (
                      <>
                        <div className="block">
                          <span className="title">
                            {this.Messages.WINDOW_TYPE}
                          </span>
                          <span className="monitor-value">
                            {this.Utilities.returnWindowType(window.type)}
                          </span>
                        </div>
                        <div className="block">
                          <span className="title">
                            {this.Messages.WINDOW_SIZE}
                          </span>
                          <span className="monitor-value">
                            {this.Utilities.returnValue(window.params[0])}
                          </span>
                        </div>
                      </>
                    ) : null}

                    {window && window.type === 'timeLength' ? (
                      <div className="block">
                        <span className="title">
                          {this.Messages.MAX_SAMPLES}
                        </span>
                        <span className="monitor-value">
                          {this.Utilities.returnValue(window.params[1])}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="row section no-border">
                    <div className="block">
                      <span className="title">{this.Messages.CONDITION}</span>
                      <span className="monitor-value td-truncate">
                        <Condition
                          condition={condition}
                          metadata={metadata}
                          header={false}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="row section no-border">
                    <div className="block">
                      <span className="title">
                        {this.Messages.TRIGGER_MESSAGE}
                      </span>
                      <span
                        className="monitor-value td-truncate"
                        title={this.Utilities.returnValue(triggerMessage)}
                      >
                        {this.Utilities.returnValue(triggerMessage)}
                      </span>
                    </div>
                  </div>
                </A10Collapse.Panel>
              </A10Collapse>
            </div>
          </>
        ) : (
          <>
            {/* Grid for Trigger Details in Alert */}
            <div className="alert-trigger-details">
              <div className="row section">
                <div className="block">
                  <span className="title">{this.Messages.CATEGORY_TYPE}</span>
                  <span className="monitor-value">
                    {this.Utilities.returnValue(category)}
                  </span>
                </div>
                <div className="block">
                  <span className="title">
                    {this.Messages.SUBCATEGORY_TYPE_DOT}
                  </span>
                  <span className="monitor-value">
                    {this.Utilities.returnValue(subCategory)}
                  </span>
                </div>
                <div className="block">
                  <span className="title">{this.Messages.OBJECT_TYPE}</span>
                  <span className="monitor-value">
                    {this.Utilities.returnValue(objectType)}
                  </span>
                </div>
              </div>
              {window ? (
                <div className="row section">
                  {window && window.type ? (
                    <>
                      <div className="block">
                        <span className="title">
                          {this.Messages.WINDOW_TYPE}
                        </span>
                        <span className="monitor-value">
                          {this.Utilities.returnWindowType(window.type)}
                        </span>
                      </div>
                      <div className="block">
                        <span className="title">
                          {this.Messages.WINDOW_SIZE}
                        </span>
                        <span className="monitor-value">
                          {this.Utilities.returnValue(window.params[0])}
                        </span>
                      </div>
                    </>
                  ) : null}
                  {window && window.type === 'timeLength' ? (
                    <div className="block">
                      <span className="title">{this.Messages.MAX_SAMPLES}</span>
                      <span className="monitor-value">
                        {this.Utilities.returnValue(window.params[1])}
                      </span>
                    </div>
                  ) : null}
                </div>
              ) : null}

              <div className="row section no-border">
                <div className="block">
                  <span className="title">{this.Messages.CONDITION}</span>
                  <span className="monitor-value td-truncate">
                    <Condition
                      condition={condition}
                      metadata={metadata}
                      header={false}
                    />
                  </span>
                </div>
              </div>
              <div className="row section no-border">
                <div className="block">
                  <span className="title">{this.Messages.TRIGGER_MESSAGE}</span>
                  <span
                    className="monitor-value td-truncate"
                    title={this.Utilities.returnValue(triggerMessage)}
                  >
                    {this.Utilities.returnValue(triggerMessage)}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}
export default setupA10Container(TriggerDetails)
