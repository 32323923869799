import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Col } from '@gui-libraries/widgets'
import { setItem, removeItem } from '@gui-libraries/framework'
import { DGF } from '@gui-libraries/dgf'

import storage from 'src/libraries/storage'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import {
  CustomSlices,
  ICustomSlicesParams,
} from '../../../../components/dashboard/CustomSlices'
import providerDevices from './constants/providerDevices.json'
import providerDevice from './constants/providerDevice.json'
import {
  DEVICE_DISK_CHART_CONFIG,
  DEVICE_DISK_CHART_CONFIG_PHYSICAL,
} from './constants/providerDeviceDisk'

import styles from './styles/index.module.less'

export interface IDeviceAnalyticsProps extends IA10ContainerDefaultProps {
  clusterDeviceToggled: boolean
  mode: string
}
export interface IDeviceAnalyticsState {
  device: IObject
  deviceDashboard: IObject
}

class DeviceAnalytics extends A10Container<
  IDeviceAnalyticsProps,
  IDeviceAnalyticsState
> {
  constructor(props) {
    super(props)

    removeItem('DGF_PROVIDER_CAPACITY')
    removeItem('DGF_PROVIDER_CAPACITY_ALLDATA')

    const device = this.getSelectedDevice()

    if (device) {
      setItem('SELECTED_CONTEXT', device, true)
    } else {
      removeItem('SELECTED_CONTEXT')
    }

    this.state = {
      device,
      deviceDashboard: this.formatDeviceDashboard(device),
    }
  }

  componentDidUpdate(prevProps: IDeviceAnalyticsProps) {
    if (this.props.clusterDeviceToggled !== prevProps.clusterDeviceToggled) {
      const device = this.getSelectedDevice()

      if (device) {
        setItem('SELECTED_CONTEXT', device, true)
      } else {
        removeItem('SELECTED_CONTEXT')
      }

      this.setState({
        device,
        deviceDashboard: this.formatDeviceDashboard(device),
      })
    }
  }

  renderCustomSlices = (argObj: ICustomSlicesParams) => {
    return <CustomSlices {...argObj} />
  }

  getSelectedDevice = () => {
    const devices = storage.get.ALLDEVICES
    const currentDevice = storage.get.CURRENT_DEVICE
    return devices.find(device => {
      return device.name === currentDevice?.name
    })
  }

  formatDeviceDashboard = (device?: IObject) => {
    if (!device) {
      return null
    }

    const _providerDevice = { ...providerDevice }
    const clusters = storage.get.ALLCLUSTERS
    const cluster = clusters.find(c => c.name === device.cluster)
    const isPhysical =
      cluster['virtualization-type'] === 'Thunder' &&
      device['model'] !== 'vThunder'
    const { rows } = _providerDevice.dashboard

    rows.forEach((row: IObject) => {
      if (row.name === 'Utilizations') {
        const { cols } = row
        cols.forEach((col: IObject) => {
          if (col.name === 'Disk') {
            col.vizualizations = isPhysical
              ? [DEVICE_DISK_CHART_CONFIG_PHYSICAL]
              : [DEVICE_DISK_CHART_CONFIG]
          }
        })
      }
    })

    return _providerDevice
  }

  render() {
    const { mode } = this.props
    const { device, deviceDashboard } = this.state
    const devices = storage.get.ALLDEVICES
    const tenants = storage.get.ALLTENANTS

    if (devices.length === 0 || tenants.length === 0) {
      return (
        <ContentSection>
          <ContentHeader type="flex" align="middle" justify="space-between">
            <A10Col>
              <ContentTitle title="Device Analytics" />
            </A10Col>
          </ContentHeader>
          <ContentBody>
            <div className="empty-data-warn">
              <div className="empty-image-block">
                <i className="empty-image-icon empty-no-device" />
              </div>
              <span>
                There are no Infrastructure components to show analytics for.
                Please add clusters, devices and tenants before any data can be
                shown.
              </span>
            </div>
          </ContentBody>
        </ContentSection>
      )
    }

    return (
      <>
        {device ? (
          <div className={styles.dashboardContainerIndDevices}>
            <DGF
              key={device.name}
              mode={mode}
              dashboard={deviceDashboard.dashboard}
              renderCustomSlices={this.renderCustomSlices}
            />
          </div>
        ) : (
          <div className={styles.dashboardContainerAllDevices}>
            <DGF
              mode={mode}
              dashboard={providerDevices.dashboard}
              renderCustomSlices={this.renderCustomSlices}
            />
          </div>
        )}
      </>
    )
  }
}

export default setupA10Container(DeviceAnalytics)
