import React from 'react'
import ReactLoading from 'react-loading'
import { setupA10Container } from '@gui-libraries/framework'
import { AppServiceGroupForm } from '@gui-libraries/apps'
import {
  A10Table,
  A10DropdownMenu,
  A10Collapse,
  A10Icon,
  A10Badge,
  A10Tooltip,
  A10Button,
  A10Popover,
  A10Row,
  A10Col,
} from '@gui-libraries/widgets'

import { DashboardService, Utilities } from 'src/services/index'
// import { PageIndexer } from 'src/components/shared/PageIndexer'
// import { ThunderSlbAppService } from './ThunderSlbAppService'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { ThunderAppEdit } from './ThunderAppEdit'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
// import CONFIG from 'src/settings'
import {
  AppServiceGroupAssignment,
  IAppServiceGroupAssignmentProps,
  IAppServiceGroupAssignmentState,
} from '../AppServiceGroupAssignment'
import storage from 'src/libraries/storage'

import '../styles/appservices.scss'
import styles from '../styles/index.module.less'

// const { ADC_ROUTE_PATH } = CONFIG

export interface IThunderSlbAppServicesProps
  extends IAppServiceGroupAssignmentProps {
  appServices: any
  hcApps: any
  refreshAppServices: any
  exploreConfig: any
}
export interface IThunderSlbAppServicesState
  extends IAppServiceGroupAssignmentState {
  appServicesReloaded: boolean
  showTable: boolean
  appServices: any
  indexes: any
  selectedIndex: number
  // formatted: boolean
  selectedApp: any
  showSlidingPage: boolean
  loadingIconForm: boolean
  displayName: string
  selectItem: number
  datasourceToAppServiceGroup: string[]
  appServiceGroupToBeUpdated: IObject
  isFormOpen: boolean
  appServicesLength: number
}

class ThunderSlbAppServices extends AppServiceGroupAssignment<
  IThunderSlbAppServicesProps,
  IThunderSlbAppServicesState
> {
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  state = {
    datasourceToAppServiceGroup: [] as string[],
    appServicesReloaded: false,
    showTable: true,
    appServices: [[]],
    indexes: [],
    selectedIndex: 0,
    // formatted: false,
    selectedApp: '',
    showSlidingPage: false,
    loadingIconForm: false,
    displayName: '',
    appServicesLength: 0,
    selectedAppService: '',
  } as IThunderSlbAppServicesState
  childForm: any = null
  showTable = true
  pageLength = 5
  record = ''
  isOperatorUser = storage.get.IS_OPERATOR_USER
  appServicesColumns = [
    // {
    //   title: <HealthStatus type="header" />,
    //   dataIndex: 'status',
    //   key: 'status', // TODO: change this key
    //   render: (text: string, record: any, index: number) => {
    //     let status = 'ongoing'
    //     if (index % 3 === 0) {
    //       status = 'warning'
    //     } else if (index % 2 === 0) {
    //       status = 'stopped'
    //     }
    //     return <HealthStatus dataval={index} type={status} text="T" />
    //   },
    // },
    {
      title: <>App Service</>,
      dataIndex: 'name',
      key: 'name',
      className: 'td-truncate',
      sorter: (a: any, b: any) =>
        this.Utilities.sortStringKeys(a, b, 'displayName', 'name'),
      render: (text: string, record: any, index: number) => {
        const associationState =
          record && record.associationState
            ? record.associationState.toLowerCase()
            : ''
        const color =
          record.licenseErrorType == 'WARNING'
            ? 'warning'
            : record.licenseErrorType == 'ERROR'
            ? 'stopped'
            : 'info'
        const showDisplayName = text !== record.displayName

        return (
          <span>
            <HealthStatus
              type={associationState === 'activated' ? color : 'undefined'}
              text="A"
              hideTooltip={associationState === 'activated' && color === 'info'}
              tooltip={
                associationState === 'activated'
                  ? color !== 'info'
                    ? record.licenseDescription
                    : record.displayName || text
                  : 'Inactive'
              }
            />
            &nbsp;
            <A10Tooltip
              overlayClassName={styles.appServiceTooltip}
              title={
                <table>
                  {showDisplayName && (
                    <tr>
                      <th>Display Name</th>
                      <td>{record.displayName}</td>
                    </tr>
                  )}
                  <tr>
                    <th>Name</th>
                    <td>{text}</td>
                  </tr>
                </table>
              }
            >
              {record.displayName || text}
            </A10Tooltip>
          </span>
        )
      },
    },
    {
      title: <>Protocol</>,
      dataIndex: 'protocol',
      key: 'protocol',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'protocol'),
    },
    {
      title: <>Port</>,
      dataIndex: 'port',
      key: 'port',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'port'),
    },
    {
      title: <>Logical Partition</>,
      dataIndex: 'lp_name',
      key: 'lp_name', // to be Formatted
      className: 'td-truncate',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'lp_name'),
      render: (text: any, record: any) => {
        return <A10Tooltip title={text}>{text}</A10Tooltip>
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text: IObject, record: IObject, index: number) => {
        const clickAction = (component: JSX.Element) => {
          if (component.key === 'launchAnalytics') {
            this.checkLicenseAndLaunch('TADCManageApp', text, record)
          } else if (component.key === 'rename') {
            this.renameApp(text)
          } else if ('editLP' === component.key) {
            this.props.exploreConfig(record)
          }
        }
        let menuItems: any = []
        // record.associationState.toLowerCase() === 'activated' && -- removed inactive app condition check --- HARMONY-18996
        const checkToLaunchApp =
          record['app_svc_type'] === 'slb' &&
          (record.protocol == 'http' ||
            record.protocol == 'https' ||
            record.protocol == 'http2' ||
            record.protocol == 'tcp' ||
            record.protocol?.includes('dns'))

        if (!this.isOperatorUser) {
          menuItems.push(<div key="rename">Rename </div>)
        }
        const adcApps = this.props.hcApps.filter((app: any) => {
          return app.name === 'adc' || app.name === 'slb'
        })
        if (checkToLaunchApp && adcApps.length === 0) {
          menuItems.push(<div className="disabled">No apps available</div>)
        }
        checkToLaunchApp &&
          adcApps &&
          adcApps.map((obj: any, index: number) => {
            menuItems.push(
              <div
                className={checkToLaunchApp ? '' : 'disabled'}
                onClick={() =>
                  checkToLaunchApp
                    ? this.checkAppLicenseAndLaunch(obj, record)
                    : null
                }
              >
                {'Launch In App(' + obj.version + ')'}
              </div>,
            )
          })
        checkToLaunchApp &&
          adcApps &&
          adcApps.map((obj: any, index: number) => {
            menuItems.push(
              <div
                className={checkToLaunchApp ? '' : 'disabled'}
                onClick={() =>
                  checkToLaunchApp
                    ? this.Utilities.launchAppWithLogicalPartition(
                        obj,
                        record.lp_name,
                        record,
                      )
                    : null
                }
              >
                {'Configuration ' + ' ' + obj.version}
              </div>,
            )
          })
        return (
          <div className="table-dropdown">
            <A10DropdownMenu
              menu={menuItems}
              title=""
              onClick={clickAction}
              trigger="hover"
              placement="bottomRight"
              arrowPointAtCenter={true}
            />
          </div>
        )
      },
    },
  ]

  checkAppLicenseAndLaunch = (app: any, appSvc: any) => {
    
    if (!app) {
      this.Utilities.launchApp(appSvc)
    } else {
      this.Utilities.launchAppWithSvc(app, appSvc)
    }
    
  }
  format = (appService: any) => {
    if (appService['logical-cluster']) {
      appService.clusterName = appService['logical-cluster']
      appService.physicalClusterName = appService['logical-cluster'].split(
        '.',
      )[0]
    } else {
      appService.clusterName = 'No cluster available' //'appService.cspClusterNames[0]'
      appService.physicalClusterName = 'Associate'
    }
    //appService.vPortCount = appService['vport']['obj-refcnt']
    if (appService.associationState === 'NotAssociated') {
      appService.associationState = 'Associate'
    }
    // this.setState({
    //   formatted: !this.state.formatted,
    // })
  }
  componentWillReceiveProps(props: any) {
    let receivedProps = [...props.appServices]
    let [appServices, indexes] = this.DashboardService.getPaginatedItems(
      receivedProps,
      this.pageLength,
    )
    this.setState({
      appServicesReloaded: true,
      appServices: appServices,
      showTable: true,
      indexes: indexes,
      selectedIndex: 0,
    })
  }
  renameApp = (appObj: any) => {
    this.setState({
      selectedApp: appObj,
      displayName: appObj.displayName,
      showSlidingPage: true,
    })
  }
  handleCanel = () => {
    this.setState({
      selectedApp: '',
      displayName: '',
      showSlidingPage: false,
      loadingIconForm: false,
    })
  }
  handleSave = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }
  handleChange = (data: any) => {
    this.setState({
      displayName: data,
    })
  }
  saveThunderApp = () => {
    const { showMessage } = this.Utilities
    const payload = {
      display_name: this.state.displayName,
    }
    this.setState({ loadingIconForm: true })
    const updateDisplayName = this.DashboardService.updateThunderAppName(
      null,
      payload,
      [this.state.selectedApp['app_svc_id']],
    )
    updateDisplayName
      .then((resp: any) => {
        this.props.refreshAppServices()
        showMessage('Successfully updated the display name', 1, 0)
        this.handleCanel()
      })
      .catch((error: any) => {
        const messsge = error?.response?.data?.message
          ? error.response.data.message
          : ''
        if (!this.Utilities.isRBACAccessDenied(error)) {
          showMessage('Error in updating display name', 0, 0, messsge)
        }
        this.setState({ loadingIconForm: false })
      })
  }
  setData = () => {
    let receivedProps = [...this.props.appServices]
    let [appServices, indexes] = this.DashboardService.getPaginatedItems(
      receivedProps,
      this.pageLength,
    )
    this.setState({
      appServicesReloaded: true,
      appServices: appServices,
      showTable: true,
      indexes: indexes,
    })
  }
  toggleServicesTable = () => {
    let showTable = this.state.showTable
    this.setState({
      showTable: !showTable,
    })
  }
  updateSelectedIndex = (index: any) => {
    this.setState({
      selectedIndex: index,
    })
  }
  checkLicenseAndLaunch = (
    launchModeValue: string,
    appValue: any,
    record: any,
  ) => {
    this.launchApp(launchModeValue, appValue, record)
  }
  launchApp = (launchModeValue: string, appValue: any, record: any) => {
    const {
      get: {
        ALLCLUSTERS,
        CURRENT_TENANT,
        ALLTENANTS,
        ENCODED_SESSION_ID,
        LOCAL_USER,
        PROVIDER,
        PROVIDER_ID,
        PROVIDER_PATH,
        TARGET_URL,
        CURRENT_STATE,
        USER_ID,
      },
    } = storage

    let cluster = record['logical-cluster']
      ? record['logical-cluster'].split('.')[0]
      : null
    let focusedCluster: IObject[] = [{ uuid: 'undefiled' }]
    if (ALLCLUSTERS && cluster) {
      focusedCluster = ALLCLUSTERS.filter((c: any, index: any) => {
        return c.name === cluster
      })
    }
    if (focusedCluster.length === 0) {
      focusedCluster = [{ uuid: 'undefiled' }]
    }
    let currentTenant = CURRENT_TENANT
    let cName = currentTenant.name
    if (ALLTENANTS) {
      currentTenant = ALLTENANTS.filter((t: any, index: any) => {
        return t.name === cName
      })
      if (currentTenant.length > 0) {
        currentTenant = currentTenant[0]
      }
    }

    let params: any = {
      ACCOUNT_NAME: currentTenant.name,
      // ACCOUNT_ID: currentTenant.uuid,
      // ACCOUNT_TYPE: 'HOSTED',
      // ADMIN_LEVEL: ADMIN_LEVEL,
      // DRILL_LEVEL: 'tenant',
      ENCODED_SESSION_ID,
      LAST_CLICKED_TIMESTAMP: new Date().getTime(),
      LOCAL_USER,
      PROVIDER,
      PROVIDER_ID,
      PROVIDER_PATH,
      SAAS: true,
      TARGET_URL,
      // TENANT: currentTenant.name,
      CURRENT_STATE,
      USER_ID,
      APPMODEPAGE: launchModeValue,
      APPMODEVALUE: 'TADCApp',
      CURRENT_APPLICATION_TYPE: record['app_svc_type'],
      CURRENT_APPLICATION: record.name,
      APPLICATION_SVC_ID: appValue.app_svc_id,
    }
    if ('TADCManageApp' === launchModeValue) {
      params.CURRENT_APPLICATION_TYPE = record['app_svc_type']
      params.CURRENT_APPLICATION = record.name
      params.CURRENT_APPLICATION_PROTOCOL = appValue.protocol
      params.DEVICE_CLUSTER_ID = record.device_cluster_id
    }
    params = JSON.stringify(params)
    params = params.replace(/\//g, 'S@REP@L')

    // USER_ACC_CODE:	barada1982598091
    // USER_EMAIL_ID:	qaautomation2@appcito.net
    // USER_ID:	qaautomation2@appcito.net
    // USER_NAME:	qa A10
    // USER_SESSION_AUTHTYPE:	default
    // USER_SESSION_ID:	c296ecb3-9a37-4292-8bb4-35bb0c8da373
    //    CURRENT_APPLICATION:	TestApp_day26th
    //    CURRENT_APPLICATION_TYPE:	ladc
    //  params = encodeURI(params);
    const url =
      'https:' +
      '//' +
      window.location.hostname +
      ':7443/#/ladcApp?value=' +
      params
    //const url = 'http:' + '//' + 'localhost:9000' + '/#/ladcApp?value=' + params
    window.open(url, '_blank')
  }
  componentDidMount() {
    if (!this.state.appServicesReloaded) {
      this.setData()
    }
  }
  componentDidUpdate() {
    if (!this.state.appServicesReloaded) {
      this.setData()
    }

    const { appServices = [] } = this.props

    if (appServices && appServices.length !== this.state.appServicesLength) {
      this.setState({ appServicesLength: appServices.length })
    }
  }

  appServiceTooltip() {
    const content = (
      <div>
        <p>Content</p>
        <p>Content</p>
      </div>
    )
    return (
      <A10Popover content={content} title="Title" trigger="hover">
        <A10Button>Hover me</A10Button>
      </A10Popover>
    )
  }

  vportColumn = [
    {
      title: <>vPort</>,
      render: (text: any, record: any) => {
        if (record.name) {
          return <span>{record.name}</span>
        } else {
          return 'NA'
        }
      },
    },
    {
      title: <>Protocol</>,
      dataIndex: 'protocol',
      key: 'protocol',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'protocol'),
    },
    {
      title: <>Ports</>,
      dataIndex: 'port-number',
      key: 'port-number',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'port-number'),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (key: any) => {
        const clickAction = (component: JSX.Element, index: number) => {
          if (component.key === 'launchApp') {
            this.checkLicenseAndLaunch('TADCManageApp', key, this.record)
          }
        }
        return (
          <div className="table-dropdown">
            <A10DropdownMenu
              menu={[<div key="launchApp">Analytics </div>]}
              title=""
              onClick={clickAction}
              trigger="hover"
              placement="bottomRight"
              arrowPointAtCenter={true}
            />
          </div>
        )
      },
    },
  ]

  renderVPort = (record: any) => {
    this.record = record
    return (
      <A10Table
        className="vportList"
        columns={this.vportColumn}
        dataSource={record.vport['obj-references']}
        size="small"
      />
    )
  }

  render() {
    const {
      appServiceGroupList,
      appServicesMap,
      usableAppServiceOptions,
    } = this.props

    const { appServiceGroupToBeUpdated, appServicesLength } = this.state

    const adcApps = this.props.hcApps.filter((app: any) => {
      return app.name === 'adc' || app.name === 'slb'
    })

    adcApps.sort((a: any, b: any) => {
      return this.Utilities.sortAppVersionNumber(
        a?.version?.split('-')[0]?.split('.') || [0],
        b?.version?.split('-')[0]?.split('.') || [0],
      )
    })
    adcApps.sort((a: any, b: any) => {
      return this.Utilities.sortAppVersionNumber(
        a?.version?.split('v')[1]?.split('.') || [0],
        b?.version?.split('v')[1]?.split('.') || [0],
      )
    })

    return (
      <>
        <A10Collapse
          bordered={true}
          defaultActiveKey={this.props.appServices?.length? ['1'] : [null]}
          className="marginTop20 marginBottom15"
        >
          <A10Collapse.Panel
            header={
              <div onClick={this.toggleServicesTable}>
                <A10Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className={styles.appServicesTitleContainer}
                >
                  <A10Col>
                    <A10Icon app="app-icons" type="adc-icon-active" />
                    <span className={styles.appServicesTitle}>Thunder ADC</span>
                    <A10Badge
                      count={appServicesLength}
                      overflowCount={9999}
                      className={styles.badgeHeight}
                    />
                  </A10Col>
                  <A10Col>
                    {/* <Link
                      to={`${ADC_ROUTE_PATH}/app_service_group/list`}
                      className="appLauncher"
                    >
                      Launch App
                    </Link> */}
                    <A10Button
                      type="link"
                      onClick={() =>
                        this.Utilities.launchAppWithSvc(adcApps[0], null)
                      }
                      disabled={
                        adcApps.length === 0 ||
                        this.props.appServices.length === 0
                      }
                    >
                      Launch App
                    </A10Button>
                  </A10Col>
                </A10Row>
              </div>
            }
            key="1"
            className={styles.collapse}
          >
            <A10Table
              columns={this.appServicesColumns}
              // expandedRowRender={this.renderVPort}
              dataSource={this.props.appServices.map(
                (key: any, index: number) => {
                  key.key = index
                  if (!key.clusterName) {
                    this.format(key)
                    return key
                  } else {
                    return key
                  }
                },
              )}
              size="small"
              pagination={{ hideOnSinglePage: true, pageSize: 20 }}
            />
          </A10Collapse.Panel>
        </A10Collapse>
        <FormatSlidingPage
          isOpen={this.state.showSlidingPage}
          onRequestOk={this.handleSave}
          saveText="Update"
          disableSave={this.state.loadingIconForm}
          onRequestClose={this.handleCanel}
          title={'Edit App Service Instance'}
          description={
            'You can only give a display name to an app service instance'
          }
          hideCancel={this.state.loadingIconForm}
        >
          <>
            <ThunderAppEdit
              thunderApp={this.state.selectedApp}
              handleChange={this.handleChange}
              onSubmitForm={this.saveThunderApp}
              onRef={(ref: any): any => (this.childForm = ref)}
            />
            {this.state.loadingIconForm ? (
              <div>
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </div>
            ) : null}
          </>
        </FormatSlidingPage>
        <FormatSlidingPage
          isOpen={this.state.isFormOpen}
          title={'Assign to an App Service Group'}
          description={`Assign "${this.state.selectedAppService}" to an App Service Label`}
          onRequestClose={() => {
            this.setState({
              isFormOpen: false,
              appServiceGroupToBeUpdated: {},
            })
          }}
          saveText="Save"
          onRequestOk={this.onAssignAppServiceGroupRequestOk}
        >
          <AppServiceGroupForm
            appServiceGroupList={appServiceGroupList}
            appServiceGroup={appServiceGroupToBeUpdated}
            onSetAppServiceGroup={(appServiceGroup: IObject) => {
              this.setState({
                appServiceGroupToBeUpdated: {
                  ...appServiceGroup,
                  appServices: [...appServiceGroup.appServices],
                },
              })
            }}
            appServices={usableAppServiceOptions}
            appServicesMap={appServicesMap}
          />
        </FormatSlidingPage>
      </>
    )
  }
}

export default setupA10Container(ThunderSlbAppServices)
