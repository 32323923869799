import React from 'react'
import equal from 'fast-deep-equal'
import {
  setupA10Container,
  A10Container,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Input,
  A10Notification,
  A10Switch,
  A10Col,
  A10Tooltip,
  A10Row
} from '@gui-libraries/widgets'

import { IDefaultMethods } from 'src/containers/Controller'
import { DashboardService, InfrastructureService } from 'src/services'
import { AppRoot } from 'src/settings/appRoot'
import { ThunderSlbAppServices } from './ThunderSlbAppServices'
import { CgnAppServices } from './CgnAppServices'
import { GiFirewallServices } from './GiFirewallServices'
import { GTPFWServices } from './GTPFWServices'
import { SSLiServices } from './SSLiServices'
import { Utilities } from 'src/services/Utilities'
import {
  appServiceGroupAdapter,
  collectAppServiceGroupProps,
} from 'src/libraries/common'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'

import styles from './styles/index.module.less'
import ActionButton from 'src/components/shared/ActionButton'

export interface IAppServicesProps {
  defaultMethods: IDefaultMethods
  device: any
  tenantToggled: boolean
}
export interface IAppServicesState {
  appservices: any
  searchString: string
  tenantToggled: boolean
  appSvcCount: number
  activeAppSvcCount: number
  // hcAppsLoaded: boolean
  showInactiveApps: boolean
  appServiceGroupList: IObject[]
  appServicesMap: IObject
  usableAppServiceOptions: IObject[]
}

class AppServices extends A10Container<IAppServicesProps, IAppServicesState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  DashboardService = new DashboardService()
  InfrastructureService = new InfrastructureService()
  Utilities = new Utilities()
  AppRoot = new AppRoot()
  state = {
    appservices: [],
    searchString: '',
    tenantToggled: this.props.tenantToggled,
    appSvcCount: 0,
    activeAppSvcCount: 0,
    // hcAppsLoaded: false,
    showInactiveApps: false,
    appServiceGroupList: [],
    appServicesMap: {},
    usableAppServiceOptions: [],
  } as IAppServicesState
  // all the suppoted services types should be present here and the componnet foreach of these
  //types must be defines in Applications

  thunderSlbServices: any = []
  ladcServices: any = []
  cgnServices: any = []
  giFirewallServices: any = []
  gtpFWServices: any = []
  ssliServices: any = []
  allApps: any = []
  tenant: string = ''

  dataLoaded: boolean = false

  container = React.createRef()

  getApplications = async (showInactiveApps = false) => {
    const {
      storage: { get, set },
    } = this.context

    if (!get.CURRENT_TENANT) {
      const tenants = this.AppRoot.getRootScopeElement('allTenants')
      set.CURRENT_TENANT(tenants[0])
      this.AppRoot.setRootScopeElement('currentTenant', tenants[0])
    }
    let currentTenant = get.CURRENT_TENANT
    let tenants = get.ALLTENANTS

    const tenant = currentTenant ? currentTenant.name : tenants[0].name
    this.tenant = tenant
    let appHeaders = {
      Authorization: get.ENCODED_SESSION_ID,
      provider: get.PROVIDER,
      'Content-Type': 'application/json',
      tenant: tenant,
    }

    this.dataLoaded = false

    let appservices = {}

    if (showInactiveApps) {
      appservices = this.DashboardService.getInActiveApplications(
        appHeaders,
        null,
        null,
      )
    } else {
      appservices = this.DashboardService.getActiveApplications(
        appHeaders,
        null,
        null,
      )
    }

    Promise.all([appservices])
      .then((resps: any) => {
        if (resps && resps[0] && resps[0].data) {
          const appServices = resps[0].data || []
          this.AppRoot.setRootScopeElement('appservices', appServices)
          set.APP_SERVICES(appServices)
          this.loadApplications()
        } else {
          this.AppRoot.setRootScopeElement('appservices', [])
          set.APP_SERVICES([])
          this.loadApplications()
        }
        this.loadActiveApps(!this.state.showInactiveApps)
      })
      .catch((error: any) => {
        this.setState({
          appSvcCount: 0,
          activeAppSvcCount: 0,
        })
      })
      .finally(() => {
        this.dataLoaded = true
        this.setState({
          searchString: '',
        })
      })
  }
  loadMyApps = async () => {
    const {
      storage: {
        get: { TARGET_URL: targetUrl },
      },
    } = this.context

    try {
      this.allApps = await this.Utilities.getLicensedApps(targetUrl)
      // this.setState({ hcAppsLoaded: true })
    } catch (e) {
      console.log(e)
    }
  }

  filterAppServices(appServices: any[], searchStr?: string): any[] {
    if (searchStr) {
      appServices = appServices.filter((appService: IObject) => {
        const { name, displayName } = appService
        const _searchStr = searchStr.toLowerCase()

        return (
          name.toLowerCase().includes(_searchStr) ||
          displayName.toLowerCase().includes(_searchStr)
        )
      })
    }

    this.thunderSlbServices = appServices.filter(
      (service: any, index: number) => {
        if (
          service['app_svc_type'] === 'slb' ||
          service['app_svc_type'] === 'adc'
        ) {
          return service
        }
        return false
      },
    )

    this.ladcServices = appServices.filter((service: any, index: number) => {
      if (service['app_svc_type'] === 'ladc') {
        return service
      }
      return false
    })

    this.cgnServices = appServices.filter((service: any, index: number) => {
      if (
        service['app_svc_type'] === 'cgn' ||
        service['app_svc_type'] === 'cgnv6'
      ) {
        return service
      }
      return false
    })

    this.giFirewallServices = appServices.filter(
      (service: any, index: number) => {
        if (
          service['app_svc_type'] === 'gifw' ||
          service['app_svc_type'] === 'gifirewall' ||
          service['app_svc_type'] === 'fw'
        ) {
          return service
        }
        return false
      },
    )

    this.gtpFWServices = appServices.filter((service: any, index: number) => {
      if (
        service['app_svc_type'] === 'gtpfw' ||
        service['app_svc_type'] === 'gtp-fw'
      ) {
        return service
      }
      return false
    })

    this.ssliServices = appServices.filter((service: any, index: number) => {
      if (service['app_svc_type'] === 'ssli') {
        return service
      }
      return false
    })
    return appServices
  }

  exploreConfig = (lp: any) => {
    const {
      storage: { set },
    } = this.context
    set.CURRENT_LP_SVC(lp)
    window.location.assign('/controller/Services/logicalPartitions')
    //window.location.load("/controller/Services/logicalPartitions")
  }

  loadApplications() {
    const appservices = this.AppRoot.getRootScopeElement('appservices')
    let appSvcIds = '',
      gtpAppSvcIds = ''

    appservices &&
      appservices.map((service: any, index: number) => {
        if (
          service['app_svc_type'] === 'gifw' ||
          service['app_svc_type'] === 'gifirewall' ||
          service['app_svc_type'] === 'fw'
        ) {
          appSvcIds += (appSvcIds.length > 0 ? '|' : '') + service['app_svc_id']
        }
        if (
          service['app_svc_type'] === 'gtpfw' ||
          service['app_svc_type'] === 'gtp-fw'
        ) {
          gtpAppSvcIds +=
            (gtpAppSvcIds.length > 0 ? '|' : '') + service['app_svc_id']
        }
      })

    if (appSvcIds.length > 0 || gtpAppSvcIds.length > 0) {
      const nowTime = new Date().getTime()
      appSvcIds = appSvcIds.trim()
      // appSvcIds = appSvcIds.substr(0, appSvcIds.length - 1)
      let payload = {}
      if (appSvcIds.length > 0) {
        payload['gifw'] = {
          fields: ['o_oper_state', 'app_svc_id', 'ts'],
          rangeby: {
            start: nowTime - 5 * 60 * 1000,
            end: nowTime,
            field: 'ts',
          },
          sort: 'asc',
          size: appservices.length,
          filterby: {
            and: {
              app_svc_id: appSvcIds,
            },
          },
        }
      }
      if (gtpAppSvcIds.length > 0) {
        payload['gtpfw'] = {
          fields: ['o_oper_state', 'app_svc_id', 'ts'],
          rangeby: {
            start: nowTime - 5 * 60 * 1000,
            end: nowTime,
            field: 'ts',
          },
          sort: 'asc',
          size: appservices.length,
          filterby: {
            and: {
              app_svc_id: gtpAppSvcIds,
            },
          },
        }
      }
      const activeThunderRuleSet = this.DashboardService.activeThunderRuleSet(
        null,
        payload,
        null,
      )
      activeThunderRuleSet
        .then((resp: any) => {
          // console.log(resp)
          const activeGiFw =
            resp && resp.data && resp.data.gifw ? resp.data.gifw : ''
          const activeGtpFw =
            resp && resp.data && resp.data.gtpfw ? resp.data.gtpfw : ''
          if (activeGiFw) {
            const objKeys = Object.keys(activeGiFw)
            for (const key of objKeys) {
              appservices.map((obj: any) => {
                if (
                  obj.app_svc_id === activeGiFw[key]['app_svc_id'] &&
                  activeGiFw[key]['o_oper_state'] === 'active'
                ) {
                  obj['activeGiFw'] = true
                }
              })
            }
          }
          if (activeGtpFw) {
            const objKeys = Object.keys(activeGtpFw)
            for (const key of objKeys) {
              appservices.map((obj: any) => {
                if (
                  obj.app_svc_id === activeGtpFw[key]['app_svc_id'] &&
                  activeGtpFw[key]['o_oper_state'] === 'active'
                ) {
                  obj['activeGtpFw'] = true
                }
              })
            }
          }
          this.setAppService(appservices)
        })
        .catch((error: any) => {
          // console.log(error)
          this.setAppService(appservices)
        })
    } else {
      this.setAppService(appservices)
    }
  }

  updateAppServiceGroup = async (
    appServiceGroupList: IObject[],
    appServiceGroupToBeUpdated: IObject,
  ) => {
    const {
      storage: {
        get: { CURRENT_TENANT, ALLCLUSTERS: clusters },
      },
    } = this.context

    const { display_name } = appServiceGroupToBeUpdated
    let target: number
    const appServiceGroup = appServiceGroupList.find((group, index) => {
      if (group.display_name === display_name) {
        target = index
        return true
      } else {
        return false
      }
    })

    if (!equal(appServiceGroup, appServiceGroupToBeUpdated)) {
      const { appServices } = appServiceGroupToBeUpdated
      appServices.forEach((service: IObject, index: number) => {
        const {
          tag,
          name,
          app_svc_id,
          device_cluster_id,
          partition_uuid,
          lp_uuid,
          lp_name,
          app_svc_type,
          cluster_name,
        } = service

        // service that not created by app svr group configuration has no cluster_name
        if (!cluster_name) {
          const cluster =
            clusters.find((element: IObject) => {
              return element['cluster-uuid'] === device_cluster_id
            }) || {}
          const {
            name: clusterName = '',
            'partition-list': partitionList = [],
          } = cluster
          const partition =
            partitionList.find((element: IObject) => {
              return element['partition-uuid'] === partition_uuid
            }) || {}
          const { name: partitionName = '' } = partition

          appServices[index] = {
            tag,
            name,
            app_svc_id,
            device_cluster_id,
            partition_uuid,
            lp_uuid,
            lp_name,
            app_svc_type,
            cluster_name: clusterName,
            partition_name: partitionName,
          }
        }
      })

      appServiceGroupList[target] = appServiceGroupToBeUpdated
      const tenant = CURRENT_TENANT
      const payload: IObject = {
        key: `APP_SERVICE_GROUPS_${tenant.uuid || ''}`,
        type: 'text',
        details: {
          list: appServiceGroupList,
        },
      }

      let response
      let errorMessage
      try {
        response = await appServiceGroupAdapter('PUT', payload)
      } catch (error) {
        errorMessage = error.message
      }

      if (response) {
        const { appservices: appServiceOptions } = this.state
        const newAppServiceGroupList = response?.data?.details?.list
        const appServiceGroupProps = collectAppServiceGroupProps(
          newAppServiceGroupList,
          appServiceOptions,
        )
        if (appServiceGroupProps) {
          this.setState({
            ...appServiceGroupProps,
          })
        }
      } else {
        const messagePrefix = 'Fail to update app service group'
        return A10Notification.error({
          message: errorMessage
            ? `${messagePrefix}, message: ${errorMessage}`
            : `${messagePrefix} due to server no response`,
        })
      }
    }
  }

  setAppService = (appservices: any) => {
    if (this.state.showInactiveApps) {
      this.filterAppServices(appservices)
    } else {
      this.loadActiveApps(true)
    }

    this.dataLoaded = true
    this.setState({
      appservices,
      tenantToggled: this.props.tenantToggled,
      appSvcCount: appservices ? appservices.length : 0,
    })
  }

  searchAppServices = (e: any) => {
    const appservices = this.AppRoot.getRootScopeElement('appservices')
    const searchStr = e && e.target ? e.target.value : undefined

    let appservicesFiltered = this.filterAppServices(appservices, searchStr)

    this.setState({
      searchString: e.target.value,
      appservices: appservicesFiltered,
    })
  }

  handleChange = (checked: boolean) => {
    this.setState({ showInactiveApps: checked })
    this.getApplications(checked)
  }

  loadActiveApps = (showActiveApps: boolean) => {
    const appservices = this.AppRoot.getRootScopeElement('appservices')
    const appservicesFiltered = this.filterAppServices(
      appservices,
      this.state.searchString,
    )

    this.setState({
      appservices: appservicesFiltered,
      activeAppSvcCount: appservices.length,
    })
  }

  setupAppServiceGroup = async () => {
    const { appservices: appServiceOptions } = this.state
    const response = await appServiceGroupAdapter('GET')
    const appServiceGroupList = response?.data?.details?.list

    const appServiceGroupProps = collectAppServiceGroupProps(
      appServiceGroupList,
      appServiceOptions,
    )

    if (appServiceGroupProps) {
      this.setState({
        ...appServiceGroupProps,
      })
    }
  }

  async componentDidUpdate(prevProps: any) {
    if (prevProps.tenantToggled !== this.props.tenantToggled) {
      await this.getApplications()
      // await this.setupAppServiceGroup()
    }
  }

  async componentDidMount() {
    if (
      !this.dataLoaded ||
      this.state.tenantToggled !== this.props.tenantToggled
    ) {
      await this.loadMyApps()
      await this.getApplications()
      // await this.setupAppServiceGroup()
    }
  }

  render() {
    const appServiceCount = this.allApps.length > 0 ? this.state.activeAppSvcCount : 0
    return (
      <ContentSection>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col>
            <ContentTitle
              title="App Services Instances"
              count={appServiceCount}
            />
          </A10Col>

          <A10Col className={styles.actionsContainer}>
            <A10Input.Search
              type="text"
              onChange={this.searchAppServices}
              name="searchBox"
              value={this.state.searchString}
              placeholder="Search App Services"
              className={styles.searchInput}
            />
            <ActionButton
              text="Refresh"
              onClick={() => {
                this.getApplications(this.state.showInactiveApps)
              }}
              iconProps={{ app: 'global', type: 'refresh' }}
            />
            <div className={styles.switchContainer}>
              <A10Tooltip title="Inactive App Services’ are the App Services on Thunders which have been deregistered from HC or App Services deleted from active Thunders. HC keeps historical data on these for a long time which a user can access by viewing these inactive App Services">
                <span className={styles.switchText}>
                  Show Inactive App Services
                </span>
              </A10Tooltip>
              <A10Switch
                defaultChecked={this.state.showInactiveApps}
                id="providerAdmin"
                onChange={this.handleChange}
              />
            </div>
          </A10Col>
        </ContentHeader>
        {this.dataLoaded}
        <ContentBody isLoading={!this.dataLoaded}>
          {this.allApps.length > 0 ? (
            <>
              {this.allApps.some((app: any) => {
                return app.name === 'adc' || app.name === 'slb'
              }) ? (
                <ThunderSlbAppServices
                  appServices={this.thunderSlbServices}
                  hcApps={this.allApps}
                  defaultMethods={this.props.defaultMethods}
                  refreshAppServices={() => {
                    this.getApplications(this.state.showInactiveApps)
                  }}
                  exploreConfig={this.exploreConfig}
                  appServiceGroupList={this.state.appServiceGroupList}
                  appServicesMap={this.state.appServicesMap}
                  appServiceOptions={this.state.appservices}
                  usableAppServiceOptions={this.state.usableAppServiceOptions}
                  updateAppServiceGroup={this.updateAppServiceGroup}
                />
              ) : null}

              {this.allApps.some((app: any) => {
                return app.name === 'cgn' || app.name === 'cgnv6'
              }) ? (
                <CgnAppServices
                  appServices={this.cgnServices}
                  hcApps={this.allApps}
                  defaultMethods={this.props.defaultMethods}
                  refreshAppServices={() => {
                    this.getApplications(this.state.showInactiveApps)
                  }}
                  exploreConfig={this.exploreConfig}
                  appServiceGroupList={this.state.appServiceGroupList}
                  appServicesMap={this.state.appServicesMap}
                  appServiceOptions={this.state.appservices}
                  usableAppServiceOptions={this.state.usableAppServiceOptions}
                  updateAppServiceGroup={this.updateAppServiceGroup}
                />
              ) : null}
              {this.allApps.some((app: any) => {
                return app.name === 'gifw' || app.name === 'gifirewall'
              }) ? (
                <GiFirewallServices
                  appServices={this.giFirewallServices}
                  hcApps={this.allApps}
                  defaultMethods={this.props.defaultMethods}
                  refreshAppServices={() => {
                    this.getApplications(this.state.showInactiveApps)
                  }}
                  exploreConfig={this.exploreConfig}
                  appServiceGroupList={this.state.appServiceGroupList}
                  appServicesMap={this.state.appServicesMap}
                  appServiceOptions={this.state.appservices}
                  usableAppServiceOptions={this.state.usableAppServiceOptions}
                  updateAppServiceGroup={this.updateAppServiceGroup}
                />
              ) : null}
              {this.allApps.some((app: any) => {
                return app.name === 'gtpfw' || app.name === 'gtp-fw'
              }) ? (
                <GTPFWServices
                  appServices={this.gtpFWServices}
                  hcApps={this.allApps}
                  defaultMethods={this.props.defaultMethods}
                  refreshAppServices={() => {
                    this.getApplications(this.state.showInactiveApps)
                  }}
                  exploreConfig={this.exploreConfig}
                  appServiceGroupList={this.state.appServiceGroupList}
                  appServicesMap={this.state.appServicesMap}
                  appServiceOptions={this.state.appservices}
                  usableAppServiceOptions={this.state.usableAppServiceOptions}
                  updateAppServiceGroup={this.updateAppServiceGroup}
                />
              ) : null}
              {this.allApps.some((app: any) => {
                return app.name === 'ssli'
              }) ? (
                <SSLiServices
                  appServices={this.ssliServices}
                  hcApps={this.allApps}
                  defaultMethods={this.props.defaultMethods}
                  refreshAppServices={() => {
                    this.getApplications(this.state.showInactiveApps)
                  }}
                  exploreConfig={this.exploreConfig}
                  appServiceGroupList={this.state.appServiceGroupList}
                  appServicesMap={this.state.appServicesMap}
                  appServiceOptions={this.state.appservices}
                  usableAppServiceOptions={this.state.usableAppServiceOptions}
                  updateAppServiceGroup={this.updateAppServiceGroup}
                />
              ) : null}
            </>
          ) : (
            <A10Row>No licensed apps are available</A10Row>
          )}
        </ContentBody>
      </ContentSection>
    )
  }
}

export default setupA10Container(AppServices)
