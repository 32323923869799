export const APPLICATION_NAMES_MAP = {
  'gtp-fw': {
    displayName: 'GTP Firewall',
    description:
      'A10 Thunder® Convergent Firewall (CFW),  with an integrated GTP firewall, protects against GTP protocol vulnerabilities such as fraudulent use, confidentiality breaches, DDoS attacks by malicious peers and other threats. The GTP firewall can be inserted into multiple interfaces carrying the GTP traffic. In the primary use case, it is inserted on S5-Gn and S8-Gp (roaming) interfaces.',
    shortDesc: 'Mobile Roaming security solutions for 4G/5G networks​',
    shortName: 'gtpfw',
    learnMore: 'solutions/service-provider/mobile-roaming-security/',
    documentationTag: 'GTPFW',
    key: 'gtp',
  },
  gtpfw: {
    displayName: 'GTP Firewall',
    description:
      'A10 Thunder® Convergent Firewall (CFW),  with an integrated GTP firewall, protects against GTP protocol vulnerabilities such as fraudulent use, confidentiality breaches, DDoS attacks by malicious peers and other threats. The GTP firewall can be inserted into multiple interfaces carrying the GTP traffic. In the primary use case, it is inserted on S5-Gn and S8-Gp (roaming) interfaces.',
    shortDesc: 'Mobile Roaming security solutions for 4G/5G networks​',
    shortName: 'gtpfw',
    learnMore: 'solutions/service-provider/mobile-roaming-security/',
    documentationTag: 'GTPFW',
    key: 'gtp',
  },
  slb: {
    displayName: 'Application Delivery',
    description:
      'Offering a complete application solution, A10 Thunder® Application Delivery Controller (ADC) ensures server availability, protects vulnerable applications and accelerates content delivery. It masters multi-cloud and hybrid cloud deployments with a holistic approach that reduces complexity and cost for IT operations, providing better business outcomes.',
    shortDesc:
      'Advanced Server Load Balancing solution with centralized Analytics and Insights​',
    shortName: 'adc',
    learnMore: 'products/thunder-adc',
    documentationTag: 'ADC',
    key: 'adc',
  },
  adc: {
    displayName: 'Application Delivery',
    description:
      'Offering a complete application solution, A10 Thunder® Application Delivery Controller (ADC) ensures server availability, protects vulnerable applications and accelerates content delivery. It masters multi-cloud and hybrid cloud deployments with a holistic approach that reduces complexity and cost for IT operations, providing better business outcomes.',
    shortDesc:
      'Advanced Server Load Balancing solution with centralized Analytics and Insights​',
    shortName: 'adc',
    learnMore: 'products/thunder-adc',
    documentationTag: 'ADC',
    key: 'adc',
  },
  ssli: {
    displayName: 'SSL Insight',
    description:
      'The most comprehensive decryption solution, A10 Thunder® SSLi® (SSL Insight) decrypts traffic across all ports, enabling thirdparty security devices to analyze all enterprise traffic without compromising performance. ',
    shortDesc:
      'SSL/TLS decryption solution for advanced security and Insights​',
    shortName: 'ssli',
    learnMore: 'products/thunder-ssli',
    documentationTag: 'SSLi',
    key: 'ssli',
  },
  cgn: {
    displayName: 'Carrier Grade NAT',
    description:
      'A10 Thunder® Carrier Grade Networking (CGN), provides high performance CGNAT with protocol translation that allows service providers and enterprise to extend IPv4 investment while simultaneously transitioning to IPv6 standards.',
    shortDesc:
      'High performance NAT solution for IPv4/IPv6 with analytics and insights​',
    shortName: 'cgn',
    learnMore: 'products/thunder-cgn',
    documentationTag: 'CGNAT',
    key: 'cgn',
  },
  cgnv6: {
    displayName: 'Carrier Grade NAT',
    description:
      'A10 Thunder® Carrier Grade Networking (CGN), provides high performance CGNAT with protocol translation that allows service providers and enterprise to extend IPv4 investment while simultaneously transitioning to IPv6 standards.',
    shortDesc:
      'High performance NAT solution for IPv4/IPv6 with analytics and insights​',
    shortName: 'cgn',
    learnMore: 'products/thunder-cgn',
    documentationTag: 'CGNAT',
    key: 'cgn',
  },
  gifw: {
    displayName: 'Gi/SGi Firewall',
    description:
      'A10 Thunder® Convergent Firewall (CFW), with an integrated Gi/SGi firewall, protects infrastructure and subscribers around the Gi/SGi interface and delivers the performance that mobile carriers require. The Gi/SGi firewall solution meets both current and future traffic requirements for any service provider.',
    shortDesc:
      'Safeguarding Mobile core infrastructure against multi-vector attacks​',
    shortName: 'gifw',
    learnMore: 'solutions/service-provider/gi-firewall',
    documentationTag: 'GiFW',
    key: 'gifw',
  },
  gifirewall: {
    displayName: 'Gi/SGi Firewall',
    description:
      'A10 Thunder® Convergent Firewall (CFW), with an integrated Gi/SGi firewall, protects infrastructure and subscribers around the Gi/SGi interface and delivers the performance that mobile carriers require. The Gi/SGi firewall solution meets both current and future traffic requirements for any service provider.',
    shortDesc:
      'Safeguarding Mobile core infrastructure against multi-vector attacks​',
    shortName: 'gifw',
    learnMore: 'solutions/service-provider/gi-firewall',
    documentationTag: 'GiFW',
    key: 'gifw',
  },
}
