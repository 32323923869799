import { _ } from '@gui-libraries/framework'

import * as SchGlobal from 'public/ui-schemas/config/shared-object/global.sch.json'
import storage from 'src/libraries/storage'
import { httpClient } from 'src/libraries/httpClient'

let sharedObjectMap: IObject
export const getSharedObjectMap = () => {
  if (!sharedObjectMap) {
    sharedObjectMap = {}
    _.get(SchGlobal, 'components', []).forEach((each: IObject) => {
      const { children = [] } = each
      children.forEach((item: IObject) => {
        sharedObjectMap[item['cm-object-lineage']] = item.uiJson
      })
    })
  }
  return sharedObjectMap
}

export const getUiJsonPath = (lineage: string) => {
  const map = getSharedObjectMap()
  return _.get(map, lineage)
}

export const validateAppServiceGroup = (appServiceGroup: IObject) => {
  const REQUIRED_FIELDS = {
    inside_https: false,
    outside_inspect: false,
    outside_bypass: false,
  }
  const { appServices } = appServiceGroup
  appServices.forEach((service: IObject) => {
    const { tag } = service
    REQUIRED_FIELDS[tag] = true
  })

  const keys = Object.keys(REQUIRED_FIELDS)
  return keys.every(key => REQUIRED_FIELDS[key])
}

const getUsableAppServices = (
  appServices: IObject[] = [],
  usedAppServices: Set<string>,
) => {
  return appServices.filter((appService: IObject) => {
    const { lp_uuid = '', app_svc_id } = appService
    return lp_uuid && lp_uuid.length > 3 && !usedAppServices.has(app_svc_id)
  })
}

export const collectAppServiceGroupProps = (
  appServiceGroupList: IObject[],
  appServiceOptions: IObject[],
) => {
  const usedAppServices = new Set<string>()
  if (appServiceGroupList) {
    const appServicesMap = {}
    appServiceGroupList.forEach((instance: IObject) => {
      const { appServices: services } = instance
      services.forEach((service: IObject) => {
        const { app_svc_id } = service
        appServicesMap[app_svc_id] = service
        usedAppServices.add(app_svc_id)
      })
    })

    appServiceOptions.forEach(service => {
      const { app_svc_id } = service
      appServicesMap[app_svc_id] = service
    })

    const usableAppServiceOptions = getUsableAppServices(
      appServiceOptions,
      usedAppServices,
    )

    return {
      appServiceGroupList,
      appServicesMap,
      usableAppServiceOptions,
    }
  } else {
    return null
  }
}

export const appServiceGroupAdapter = async (
  method: 'GET' | 'PUT',
  payload?: IObject,
) => {
  const tenant = storage.get.CURRENT_TENANT
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: storage.get.ENCODED_SESSION_ID,
    provider: storage.get.PROVIDER,
    tenant: tenant.name || '', // GUI-3890
  }

  const { NODE_ENV, REACT_APP_HCC_HOST } = process.env
  const API_PREFIX =
    storage.get.API || (NODE_ENV !== 'production' && REACT_APP_HCC_HOST) || ''
  const url = `${API_PREFIX}/apps/documents/APP_SERVICE_GROUPS_${tenant.uuid}`

  if (method === 'GET') {
    return await httpClient({
      method,
      headers,
      url,
    })
  } else {
    const data = new FormData()
    data.append('config', JSON.stringify(payload))

    return await httpClient({
      method,
      url,
      headers,
      data,
    })
  }
}

export const getCLIfromStatusMessage = (message: string) => {
  const matchBeginOfCLI = /\-*a10\-axapi\-([\d]+)[\r\n]*Content\-Type\:[\s]*text\/plain[\r\n]*/

  let restString = message
  const arrayOfCLI: string[] = []
  while (_.isString(restString) && restString.length > 0) {
    const result = restString.match(matchBeginOfCLI)
    if (result) {
      const matchedString = result[0]
      const matchedNumber = result[1]
      const beginIndex = result.index

      const matchEndOfCLI = `------a10-axapi-${matchedNumber}--`

      const beginOfCLI = beginIndex + matchedString.length
      const endOfCLI = restString.lastIndexOf(matchEndOfCLI)

      arrayOfCLI.push(restString.slice(beginOfCLI, endOfCLI))
      restString = restString.slice(endOfCLI)
      continue
    } else {
      break
    }
  }
  return arrayOfCLI.join('')
}

const NA = 'N/A'

export const appendUnit = (
  value: number,
  singleUnit?: string,
  pluralUnit?: string,
) => {
  if (_.isNil(value)) return NA

  const unit =
    pluralUnit && _.toNumber(value) !== 1
      ? ` ${pluralUnit}`
      : singleUnit
      ? ` ${singleUnit}`
      : ''
  return `${_.toString(value)}${unit}`
}
