import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { _ } from '@gui-libraries/framework'
import { A10Loader, A10SlidingPage } from '@gui-libraries/widgets'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import Descriptions from 'src/components/shared/Descriptions'
import ActionButton from 'src/components/shared/ActionButton'

import styles from './styles/index.module.less'

export interface IVrrpaCommonProps {
  device: string
  partition: string
  renderForm: (value: IObject) => JSX.Element
  clustersUpdated: boolean
}

const VrrpaCommon: React.FC<IVrrpaCommonProps> = props => {
  const provider = storage.get.PROVIDER
  const isOperatorUser = storage.get.IS_OPERATOR_USER

  const openFormPage = useCallback(() => setSlidingPageOpen(true), [])
  const closeFormPage = useCallback(() => setSlidingPageOpen(false), [])

  const container = useRef(null)
  const { device, partition, renderForm, clustersUpdated } = props
  const [data, setData] = useState([
    {
      'Set ID': null,
      'Device ID': null,
    },
    {
      Action: null,
    },
  ])
  const [slidingPageOpen, setSlidingPageOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const apiPrefix = useMemo(() => {
    return `/hpcapi/v3/provider/${provider}/device/${device}/partition/${partition}`
  }, [provider, device, partition])

  const submitCallback = useCallback(() => {
    closeFormPage()
    getData()
  }, [apiPrefix])

  const getData = () => {
    httpClient
      .get(`${apiPrefix}/vrrp-a/common`)
      .then(res => {
        const common = res?.data?.common
        if (common) {
          setData([
            {
              'Set ID': common?.['set-id'],
              'Device ID': common?.['device-id'],
            },
            {
              Action: common?.action === 'enable' ? 'Enabled' : 'Disabled',
            },
          ])
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    getData()
  }, [apiPrefix, clustersUpdated])

  return (
    <>
      <div ref={container} className={styles.sectionContent}>
        {loading ? (
          <A10Loader container={container} />
        ) : (
          <>
            <Descriptions data={data} direction="row" />
            {!isOperatorUser && (
              <ActionButton
                text="Edit"
                size="default"
                onClick={openFormPage}
                iconProps={{ app: 'global', type: 'edit' }}
                className={styles.actionButton}
              />
            )}
          </>
        )}
      </div>
      <A10SlidingPage
        isOpen={slidingPageOpen}
        modalSize="large"
        onRequestClose={closeFormPage}
      >
        {slidingPageOpen &&
          renderForm({
            schemaPath: 'system/vrrpa/settings/common',
            apiPrefix,
            successCallback: submitCallback,
            errorCallback: submitCallback,
            cancelCallback: closeFormPage,
          })}
      </A10SlidingPage>
    </>
  )
}

export default VrrpaCommon
