import React from 'react'
import { A10Component } from '@gui-libraries/framework'
import { A10Tooltip } from '@gui-libraries/widgets'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

/**
 * Render tooltip for assocaite column.
 * @param objList Which list need to render, if the list length is 0, return undefined.
 * @param renderText Render the context in <li />
 * @param associateButton If show associateButton in tooltip.
 */
export interface IInfoTooltip {
  objList: IObject[]
  componentDisplay?: JSX.Element
  textDisplay?: string
  textDisplayIntact?: boolean
  renderText: (obj: IObject, index?: number) => {}
  onVisibleChange?: (visible: boolean) => {}
  actions?: IObject
}
class InfoTooltip extends A10Component<IInfoTooltip> {
  render() {
    const {
      objList = [],
      textDisplay,
      textDisplayIntact,
      componentDisplay,
      renderText = (obj: IObject) => ' ',
      onVisibleChange,
      actions,
    } = this.props
    let maxTextLen = 40
    if (textDisplayIntact) {
      maxTextLen = 150
    }

    let finalText = textDisplay
      ? textDisplay.length > maxTextLen
        ? textDisplay.substr(0, maxTextLen) + '...'
        : textDisplay
      : ''

    if ((objList && objList.length > 0) || textDisplay || componentDisplay) {
      const tooltipProps = {
        title: (
          <div className={styles.dirtyList}>
            {textDisplay ? (
              <h2 className={styles.title}>{textDisplay}</h2>
            ) : null}
            <ul className={styles.description}>
              {objList.length > 0 ? (
                objList.map((obj: IObject, index: number) => {
                  return <li key={index}>{renderText(obj, index)}</li>
                })
              ) : (
                <li>No Data</li>
              )}
              {actions ? (
                <div className={styles.action}>{actions}</div>
              ) : (
                undefined
              )}
            </ul>
          </div>
        ),
        onVisibleChange,
        placement: 'right',
      }
      return (
        <A10Tooltip {...tooltipProps} overlayClassName={styles.dirtyListWrap}>
          {componentDisplay ? (
            componentDisplay
          ) : textDisplay ? (
            <a>{finalText}</a>
          ) : (
            <a>{objList.length}</a>
          )}
        </A10Tooltip>
      )
    }
    return 0
  }
}

export default InfoTooltip
