import React from 'react'
import {
  A10Container,
  setupA10Container,
  getNS,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import { A10Avatar, A10TreeSelect } from '@gui-libraries/widgets'

import { InfrastructureService } from 'src/services'
import storage from 'src/libraries/storage'
import { getLogicalPartitionList } from 'src/redux/httpServices/Controller'

import './styles/index.less'
// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

const { A10TreeNode } = A10TreeSelect

export interface IDevicePartitionSelectorProps
  extends IA10ContainerDefaultProps {
  data: IObject[]
  tenant: string
  defaultDevice: IObject
  defaultLP?: IObject
  onChange: (
    selectedObj: IObject,
    isPartition: boolean,
    isLogicalPartition: boolean,
    appType?: string,
  ) => void
  appType: string
  selectedPartition: IObject
}

export interface IDevicePartitionSelectorState {
  devices: IObject[]
  selectedDevice: IObject
  selectedPartition: string
  selectedLogicalPartition: string
  searchString?: string
}

class DevicePartitionSelector extends A10Container<
  IDevicePartitionSelectorProps,
  IDevicePartitionSelectorState
> {
  static defaultProps = {
    data: new Array(),
  }

  InfrastructureService = new InfrastructureService()
  constructor(props: IDevicePartitionSelectorProps) {
    super(props)
    this.state = {
      devices: [],
      selectedDevice: props.defaultDevice || null,
      selectedPartition: props?.selectedPartition?.name || '',
      selectedLogicalPartition: (props.defaultLP && props.defaultLP.name) || '',
    }
  }

  handleChangePartition = (value: string) => {
    if (value) {
      const { selectedDevice } = this.state
      const { onChange } = this.props
      this.setState({ selectedPartition: value })
      const partition = _.get(selectedDevice, 'partition-list', []).find(
        (p: IObject) => p.name === value,
      )
      if (!!partition) {
        partition['device-name'] = selectedDevice.name
      }
      onChange(partition, true, false)
    }
  }

  loadDevices = (showDefault = false) => {
    const clusterResponse = this.InfrastructureService.getDevices(
      null,
      null,
      storage.get.PROVIDER,
    )

    clusterResponse
      .then((response: IObject) => {
        const devices = response?.data?.['device-list'] || []
        let selectedPartition = ''
        this.setState((preState: IDevicePartitionSelectorState) => {
          if (showDefault && !!devices && devices.length > 0) {
            const partitions = _.sortBy(
              _.get(devices[0], 'partition-list', []),
              ['id'],
              ['asc'],
            )
            selectedPartition = partitions.length > 0 ? partitions[0].name : ''
            return {
              ...preState,
              devices,
              selectedDevice: devices[0],
              selectedPartition,
            }
          } else {
            return {
              ...preState,
              devices,
            }
          }
        })
        this.handleChangePartition(selectedPartition)
      })
      .catch((error: IObject) => {
        console.error(error)
      })
  }

  getData = async (tenant: string, showDefault = false) => {
    if (!!tenant) {
      const {
        A10Dispatchers: { httpRequest },
      } = this.props
      await httpRequest(getLogicalPartitionList(tenant, '', '', null, null))
    } else {
      this.loadDevices(showDefault)
    }
  }

  handleChangeLogicalPartition = (value: string) => {
    const { onChange, data } = this.props
    let partition = storage.get['object.explorer.lp.detail']
    if (partition?.name !== value) {
      partition = data.find((element: IObject) => element.name === value)
    }
    const appType = (partition && partition['app-type']) || ''
    this.setState((prevState: IDevicePartitionSelectorState) => {
      return { ...prevState, selectedLogicalPartition: value }
    })
    onChange(partition, false, true, appType)
  }

  getLogicalPartition = (
    tenantChange: boolean,
    selectedLogicalPartition = '',
  ) => {
    const { data } = this.props
    let lp = ''
    if (!selectedLogicalPartition && !!data && data.length > 0) {
      lp = data[0].name
    } else {
      const chosenOne = _.filter(data, ['name', selectedLogicalPartition]) || []
      lp = chosenOne[0] ? chosenOne[0].name : ''
    }
    this.handleChangeLogicalPartition(lp)
  }

  onDeviceChange = (value: string) => {
    const { devices } = this.state
    const selectedDevice = devices.find(
      (device: IObject) => device.name === value,
    )
    if (!!selectedDevice) {
      const partitions = _.sortBy(
        _.get(selectedDevice, 'partition-list', []),
        ['id'],
        ['asc'],
      )
      this.setState(
        {
          selectedPartition: partitions.length > 0 ? partitions[0].name : '',
          selectedDevice,
        },
        () => {
          this.handleChangePartition(
            partitions.length > 0 ? partitions[0].name : '',
          )
        },
      )
    }
  }

  getClusters = (options: IObject[]) => {
    const clusters: string[] = []
    options.forEach(
      (device: IObject) =>
        !clusters.includes(device.cluster) && clusters.push(device.cluster),
    )
    return clusters
  }

  getDeviceNodes = (cluster: string) => {
    const { devices } = this.state
    return devices
      .filter((device: IObject) => device.cluster === cluster)
      .map((device: IObject) => (
        <A10TreeNode
          key={device.name}
          title={device.name}
          isLeaf={true}
          value={device.name}
        />
      ))
  }

  getIcon = (text: string) => (
    <div
      className={styles.roundIcon}
      style={{ backgroundColor: text === 'S' ? '#64b5f6' : '#81c784' }}
    >
      <div className={styles.roundIconText}>{text}</div>
    </div>
  )

  getTitle = (prefix: string, name: string) => (
    <div>
      <div
        className={styles.roundIcon}
        style={{ backgroundColor: prefix === 'S' ? '#64b5f6' : '#81c784' }}
      >
        <div className={styles.roundIconText}>{prefix}</div>
      </div>
      <span>{name}</span>
    </div>
  )

  componentWillReceiveProps(nextProps: IObject) {
    if (!!nextProps && this.props.tenant !== nextProps.tenant) {
      this.getData(nextProps.tenant, true)
    }
  }

  componentDidMount() {
    const { tenant } = this.props
    this.getData(tenant)
  }

  componentDidUpdate = async (prevProps: IDevicePartitionSelectorProps) => {
    const { tenant, appType } = this.props
    const tenantIsDiff = prevProps.tenant !== tenant
    const appTypeIsDiff = prevProps.appType !== appType
    if ((!!tenant && tenantIsDiff) || appTypeIsDiff) {
      await this.getData(tenant)
      tenantIsDiff && this.getLogicalPartition(true, '')
    }
  }

  render() {
    const {
      devices,
      selectedPartition,
      selectedDevice,
      selectedLogicalPartition,
    } = this.state

    const appType = this.props.appType || 'None'
    const partitions = _.sortBy(
      _.get(selectedDevice, 'partition-list', []),
      ['id'],
      ['asc'],
    )
    const { tenant, data } = this.props
    const clusters = this.getClusters(devices)
    const deviceText = !!selectedDevice
      ? selectedDevice.cluster + ' : ' + selectedDevice.name
      : ''

    const devicePartition = (
      <div className={styles.deviceContainer} id="device-container">
        {this.props.defaultDevice ? null : (
          <A10TreeSelect
            value={deviceText}
            style={{ width: 175 }}
            onChange={this.onDeviceChange}
            dropdownMatchSelectWidth={false}
            showSearch
          >
            {clusters.map((cluster: string, index: number) => (
              <A10TreeNode
                value={cluster}
                key={`${cluster}-${index}`}
                title={cluster}
                isLeaf={false}
                selectable={false}
              >
                {this.getDeviceNodes(cluster)}
              </A10TreeNode>
            ))}
          </A10TreeSelect>
        )}

        <A10TreeSelect
          value={selectedPartition}
          style={{ width: 175 }}
          onChange={this.handleChangePartition}
          dropdownMatchSelectWidth={false}
          showSearch
        >
          {partitions.map((partition: IObject) => {
            const title = (
              <>
                <A10Avatar
                  size={16}
                  style={{
                    backgroundColor:
                      partition.name === 'shared' ? '#64b5f6' : '#81c784',
                    marginRight: 5,
                  }}
                >
                  {partition.name === 'shared' ? 'S' : 'P'}
                </A10Avatar>
                {partition.name}
              </>
            )
            return (
              <A10TreeNode
                value={partition.name}
                key={partition.name}
                title={title}
              />
            )
          })}
        </A10TreeSelect>
      </div>
    )
    const logicalPartition = (
      <div className={`${styles.deviceContainer} `} id="device-container">
        <A10TreeSelect
          value={selectedLogicalPartition}
          onChange={this.handleChangeLogicalPartition}
          style={{ width: 175, height: 30 }}
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ height: 300 }}
          showSearch
        >
          {data.map((lp: IObject) => {
            const title = this.getTitle(
              lp.type === 'private-partition' ? 'P' : 'S',
              lp.name,
            )
            return <A10TreeNode value={lp.name} title={title} key={lp.name} />
          })}
        </A10TreeSelect>

        <div className={styles.appType}>
          {appType === 'cgnv6'
            ? 'CGNv6'
            : appType !== 'None'
            ? appType.toUpperCase()
            : 'None'}
        </div>
      </div>
    )
    return !tenant ? devicePartition : logicalPartition
  }
}

function mapStateToProps(state: any) {
  let nameSpace: string[] = []
  try {
    nameSpace = getNS('LOGICAL_PARTITION_LIST')
  } catch (ignore) {
    // ignore exception
  }

  return {
    data: state.A10Data.getIn(nameSpace, []),
  }
}

export default setupA10Container(DevicePartitionSelector, mapStateToProps)
