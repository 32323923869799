import React from 'react'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Row,
  A10Col,
  A10Form,
  A10Icon,
  A10Input,
  A10Select,
} from '@gui-libraries/widgets'
import { Utilities, DashboardService } from 'src/services'
import { Messages } from 'src/locale/en/Messages'
import ListPanel from 'src/components/ADC/ListPanel'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import SlidingLicenseManager from './LicenseForm/SlidingLicenseManager'
import SlidingLicenseImport from './LicenseImportForm/SlidingLicenseImport'
import { LMLicenseList } from './LMLicenseList'

import './styles/licensemanager.scss'

export interface ILicenseManagerProps extends IA10ContainerDefaultProps {
  form: any
}
export interface ILicenseManagerState {
  lmObject: any
  isLoading: boolean
  isTested: number
  testMsg: string
  saveConfirm: boolean
  showEditSlidingPage: boolean
  showImportSlidingPage: boolean
  licenseList: any[]
  searchString: string
  isTableLoading: boolean
  updateTableList: boolean
  filter: string
}

class LicenseManager extends A10Container<
  ILicenseManagerProps,
  ILicenseManagerState
> {
  Messages = new Messages()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  lmObj = {
    lmType: 'Local',
    ipDomainURL: '',
    port: '',
    userName: '',
    password: '',
    useMgmtPort: false,
    activationStatus: false,
    activationStatusText: '',
    apiSuffix: '',
    llmUUID: '',
    llmActivationString: '',
  }
  licenseList: any = []

  constructor(props: any) {
    super(props)
    this.state = {
      lmObject: {
        lmType: 'Local',
        ipDomainURL: '',
        port: '',
        userName: '',
        password: '',
        useMgmtPort: false,
        activationStatus: false,
        activationStatusText: '',
        apiSuffix: '',
        llmUUID: '',
        llmActivationString: '',
      },
      isLoading: false,
      isTested: 0,
      testMsg: 'Test License Manager settings to be able to Save.',
      saveConfirm: false,
      showEditSlidingPage: false,
      showImportSlidingPage: false,
      licenseList: [],
      searchString: '',
      isTableLoading: false,
      updateTableList: false,
      filter: 'all',
    }
  }

  getLMInfo = async () => {
    this.setState({ isLoading: true })

    const getLLMInfo = this.DashboardService.getLicenseManagerDetails(
      null,
      null,
      null,
    )
    await getLLMInfo
      .then(async (resp: any) => {
        if (resp && resp.data) {
          this.lmObj = {
            lmType: resp.data['lm-type'],
            ipDomainURL: resp.data['elm-url'],
            port: resp.data['elm-port'],
            userName: resp.data['elm-user'],
            password: resp.data['elm-password'],
            useMgmtPort: resp.data['use-mgmt-port'],
            activationStatus: resp.data['activation-status'],
            activationStatusText: '',
            apiSuffix: resp.data['elm-api-suffix'],
            llmUUID: resp.data['elm-uuid'] || '',
            llmActivationString: '',
          }

          if (resp.data['lm-type'] === 'Local') {
            const llmStatusAPI = this.DashboardService.getLLMStatus(
              null,
              null,
              null,
            )
            await llmStatusAPI
              .then((resp: any) => {
                const data = resp?.data || {}
                this.lmObj.apiSuffix =
                  data['elm-api-suffix'] || this.lmObj.apiSuffix
                this.lmObj.port = data['elm-port'] || this.lmObj.port
                this.lmObj.ipDomainURL =
                  data['elm-url'] || this.lmObj.ipDomainURL
                this.lmObj.llmUUID = data['elm-uuid'] || this.lmObj.llmUUID
                this.lmObj.activationStatus = data['activation-status'] || false
                if (data['activation-status']) {
                  this.getLMLicenses()
                }
              })
              .catch((err: any) => {
                const msg = err?.response?.data?.message
                  ? err.response.data.message
                  : ''
                this.Utilities.showMessage(
                  'Error in getting LLM status: ' + msg,
                  0,
                  0,
                )
                this.lmObj.activationStatus = false
                this.lmObj.activationStatusText = 'Unable to get LLM status'
              })
          }

          this.setState({
            lmObject: this.lmObj,
            isLoading: false,
          })
        }
      })
      .catch((err: any) => {
        const msg = err?.response?.data?.message
          ? err.response.data.message
          : ''
        this.Utilities.showMessage('Error in getting LLM details: ' + msg, 0, 0)
        this.setState({ isLoading: false })
      })
  }

  getLMLicenses = () => {
    const promises: any = []
    this.setState({ isTableLoading: true })
    const getLMLicense = () =>
      this.DashboardService.getLMLicenses(null, null, null)
    promises.push(
      getLMLicense().catch((err: any) => {
        const msg = err?.response?.data?.message
          ? err.response.data.message
          : ''
        this.Utilities.showMessage(
          'Error in getting LLM licenses: ' + msg,
          0,
          0,
        )
      }),
    )
    const getLMRevokedLicense = () =>
      this.DashboardService.getLMRevokedLicenses(null, null, null)
    promises.push(
      getLMRevokedLicense().catch((err: any) => {
        console.log('error in get LM Revoked license')
      }),
    )

    Promise.all(promises)
      .then((response: any) => {
        this.licenseList = []
        response.map((resp: any, index: number) => {
          resp?.data?.map((license: any) => {
            if (license['license-type'] === 'FLEXPOOL') {
              license['used-devices'] = 0
              this.setState({
                isTableLoading: true,
                updateTableList: !this.state.updateTableList,
              })
              const getLicenseActivations = this.DashboardService.getLLMLicenseActivations(
                null,
                null,
                [license['license-id']],
              )
              getLicenseActivations
                .then((resp: any) => {
                  this.licenseList.forEach((licenseObj: IObject) => {
                    if (licenseObj['license-id'] === license['license-id']) {
                      licenseObj['used-devices'] = resp?.data?.length || 0
                    }
                  })
                  this.setState({
                    isTableLoading: false,
                    updateTableList: !this.state.updateTableList,
                    licenseList: this.licenseList,
                  })
                })
                .catch((err: any) => {
                  console.log('error in getting license activations')
                  this.setState({ isTableLoading: false })
                })
            }
            this.licenseList.push(license)
          })
        })
        this.setState({ isTableLoading: false, licenseList: this.licenseList })
      })
      .catch((err: any) => {
        console.log(err)
        this.setState({ isTableLoading: false })
      })
  }

  componentDidMount() {
    this.getLMInfo()
  }

  searchLicenses = (e: any) => {
    const searchString = e.target.value
    const licenseList: any[] = []
    this.licenseList.map((license: any) => {
      if (
        license['license-name']
          .toLowerCase()
          .indexOf(searchString.toLowerCase()) > -1
      ) {
        licenseList.push(license)
      }
    })

    this.setState({ searchString, licenseList })
  }

  closeSlidingPage = () => {
    this.setState({
      showEditSlidingPage: false,
      showImportSlidingPage: false,
    })
  }

  editLLM = () => {
    const lmObject = _.cloneDeep(this.lmObj)
    this.setState({ lmObject, showEditSlidingPage: true })
  }

  importLicense = () => {
    this.setState({
      showImportSlidingPage: true,
    })
  }

  handleChange = (event: any) => {
    const filter = event?.target?.value ? event.target.value : event
    const licenseList: any[] = []
    this.licenseList.map((license: any) => {
      if (filter === 'all' || license['activation-status'] === filter) {
        licenseList.push(license)
      }
    })

    // @ts-ignore
    this.setState({ filter, licenseList })
  }

  render() {
    const {
      lmType,
      llmUUID,
      activationStatus,
      activationStatusText,
    } = this.lmObj
    const { isTableLoading, updateTableList, licenseList, filter } = this.state
    return (
      <>
        <ContentSection>
          <ContentHeader type="flex" align="middle" justify="space-between">
            <A10Col>
              <ContentTitle title="License Manager" />
            </A10Col>
            <A10Col>
              <ActionButton
                text="Edit"
                onClick={this.editLLM}
                iconProps={{ app: 'global', type: 'edit' }}
              />
            </A10Col>
          </ContentHeader>
          <ContentBody isLoading={this.state.isLoading}>
            <div
              className="list-panel"
              style={{ width: lmType == 'Global' ? '25%' : '100%' }}
            >
              <div className="row">
                <div className="col-md-12">
                  <A10Row
                    type="flex"
                    className="no-margin font-14 license-details"
                  >
                    <div className="margin-h-15 pad-v-15 license-content">
                      <div className="license-title">Type</div>
                      <div className="pad-s-15 license-desc">
                        {lmType === 'Local'
                          ? this.Messages.LOCAL
                          : this.Messages.GLOBAL}
                      </div>
                    </div>
                    {lmType === 'Local' ? (
                      <>
                        <div className="margin-h-15 pad-v-15 license-content">
                          <div className="license-title">UUID</div>
                          <div className="pad-s-15 license-desc">{llmUUID}</div>
                        </div>
                        <div className="margin-h-15 pad-v-15 license-content">
                          <div className="license-title">Status</div>
                          <div className="pad-s-15 license-desc">
                            {activationStatus
                              ? 'Activated'
                              : activationStatusText || 'Not Activated'}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </A10Row>
                </div>
              </div>
            </div>
          </ContentBody>
          {lmType === 'Local' && activationStatus && (
            <>
              <ContentHeader
                type="flex"
                align="middle"
                justify="space-between"
                style={{ marginTop: '40px' }}
              >
                <A10Col>
                  <ContentTitle
                    title="License"
                    count={this.state.licenseList.length}
                  />
                </A10Col>
                <A10Col>
                  <div className="action-items-div">
                    <ActionButton
                      text="Refresh"
                      onClick={this.getLMLicenses}
                      iconProps={{ app: 'global', type: 'refresh' }}
                    />
                    <div className="filter-div">
                      <A10Select
                        value={filter}
                        onChange={this.handleChange}
                        size="default"
                      >
                        <A10Select.Option key="all" value="all">
                          <A10Icon type="filter" category="font-awesome" /> All
                        </A10Select.Option>
                        <A10Select.Option key="active" value="ACTIVATED">
                          <A10Icon
                            type="filter"
                            style={{ color: '#81c784' }}
                            category="font-awesome"
                          />{' '}
                          Active
                        </A10Select.Option>
                        <A10Select.Option key="revoked" value="REVOKED">
                          <A10Icon
                            type="filter"
                            style={{ color: '#ff5b6e' }}
                            category="font-awesome"
                          />{' '}
                          Revoked
                        </A10Select.Option>
                      </A10Select>
                    </div>
                    <div className="searchbar-container">
                      <A10Input.Search
                        type="text"
                        onChange={this.searchLicenses}
                        name="searchBox"
                        value={this.state.searchString}
                        placeholder="Search"
                      />
                    </div>
                    <ActionButton
                      text="Import License"
                      onClick={this.importLicense}
                      iconProps={{ app: 'global', type: 'add-new' }}
                    />
                  </div>
                </A10Col>
              </ContentHeader>
              <ContentBody>
                <LMLicenseList
                  isLoading={isTableLoading}
                  updateTableList={updateTableList}
                  updateList={this.getLMLicenses}
                  licenseList={licenseList}
                />
              </ContentBody>
            </>
          )}
        </ContentSection>
        <SlidingLicenseManager
          isOpen={this.state.showEditSlidingPage}
          lmObject={this.state.lmObject}
          onRequestClose={this.closeSlidingPage}
          refreshLMInfo={this.getLMInfo}
        />
        <SlidingLicenseImport
          isOpen={this.state.showImportSlidingPage}
          llmUUID={this.lmObj.llmUUID}
          onRequestClose={this.closeSlidingPage}
          updateList={this.getLMLicenses}
        />
      </>
    )
  }
}

export default setupA10Container(A10Form.create()(LicenseManager))
