import { UrlService, AjaxService } from 'src/services'
export class OperatorConsoleService {
  UrlService = new UrlService()
  AjaxService = new AjaxService()

  getPods = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('GET_PODS')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getComponents = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('GET_COMPONENTS')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getNodeUsage = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('GET_OVERALL_USAGE')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getTimeSeriesUsage = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('GET_TIME_USAGE')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
}
export default OperatorConsoleService
