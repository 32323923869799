import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import { A10Radio, A10Row, A10SlidingPage } from '@gui-libraries/widgets'
import propTypes from 'prop-types'

import { ContentSection } from 'src/components/shared/ContentSection'
import NTP from './NTP'
import GeoList from './GeoList'
import ClassList from './ClassList'
import PasswordPolicy from './PasswordPolicy'
import { BWList } from './BWList'
import { IPList } from './IPList'
import { ObjectGroup } from './ObjectGroup'
import { Logging } from './Logging'
import CRL from '../../SharedObjects/CRL'
import ServiceObject from '../../SharedObjects/ServiceObject'
import Certificate from '../../SharedObjects/Certificate'

export interface ISystemProps extends IA10ContainerDefaultProps {
  tenant: string
  logicalPartition: string
  scope: string
}
export interface ISystemState {
  app: string
  formComponent: JSX.Element | null
  modalSize?: string
}

class System extends A10Container<ISystemProps, ISystemState> {
  static childContextTypes = {
    openSlidingPage: propTypes.func.isRequired,
    closeSlidingPage: propTypes.func.isRequired,
    setModalSize: propTypes.func,
  }
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  isCertUser

  constructor(
    props: ISystemProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: {
        get: { IS_CERT_USER },
      },
    } = context

    this.isCertUser = IS_CERT_USER
    this.state = {
      app: this.isCertUser ? 'certificate' : 'class_list',
      formComponent: null,
      modalSize: '',
    }
  }

  onChange = (e: any) => {
    this.setState({
      app: e?.target?.value,
    })
  }

  getChildContext() {
    return {
      openSlidingPage: this.openSlidingPage,
      closeSlidingPage: this.closeSlidingPage,
      setModalSize: this.setModalSize,
    }
  }
  openSlidingPage = (formComponent: JSX.Element, modalSize?: string) => {
    this.setState({ formComponent, modalSize })
  }
  setModalSize = (modalSize: string) => {
    this.setState({ modalSize })
  }
  closeSlidingPage = () => {
    this.setState({ formComponent: null, modalSize: '' })
  }

  renderApp = () => {
    const { tenant, logicalPartition, scope } = this.props
    const { app } = this.state
    switch (app) {
      case 'NPT': {
        return <NTP />
      }
      case 'password_policy': {
        return (
          <PasswordPolicy
            scope={scope}
            tenant={tenant}
            logicalPartition={logicalPartition}
          />
        )
      }
      case 'log_server': {
        return <Logging />
      }
      case 'class_list': {
        return (
          <ClassList
            scope={scope}
            tenant={tenant}
            logicalPartition={logicalPartition}
          />
        )
      }
      case 'geo_list': {
        return (
          <GeoList
            tenant={tenant}
            logicalPartition={logicalPartition}
            scope={scope}
          />
        )
      }
      case 'object_group': {
        return (
          <ObjectGroup tenant="pso" logicalPartition="pso" scope="provider" />
        )
      }
      case 'bw_list': {
        return <BWList tenant="pso" logicalPartition="pso" scope="provider" />
      }
      case 'ip_list': {
        return <IPList tenant="pso" logicalPartition="pso" scope="provider" />
      }
      case 'crl': {
        return <CRL tenant="pso" logicalPartition="pso" scope="provider" />
      }
      case 'templates': {
        return (
          <ServiceObject tenant="pso" logicalPartition="pso" scope="provider" />
        )
      }
      case 'certificate': {
        return (
          <Certificate tenant="pso" logicalPartition="pso" scope="provider" />
        )
      }
      default: {
        return null
      }
    }
  }

  render() {
    const { formComponent, modalSize } = this.state
    return (
      <>
        <ContentSection>
          <A10Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '20px',
            }}
          >
            <A10Radio.Group
              defaultValue="class_list"
              buttonStyle="tab"
              onChange={this.onChange}
            >
              {this.isCertUser ? null : (
                <>
                  {/* <A10Radio.Button value="npt">NTP</A10Radio.Button>
                  <A10Radio.Button value="password_policy">
                    Password Policy
                  </A10Radio.Button>
                  <A10Radio.Button value="log_server">Log Server</A10Radio.Button> */}
                  <A10Radio.Button value="class_list">
                    Class List
                  </A10Radio.Button>
                  {/* <A10Radio.Button value="geo_list">Geo List</A10Radio.Button> */}
                  {/* <A10Radio.Button value="object_group">Object Group</A10Radio.Button> */}
                  <A10Radio.Button value="bw_list">BW-List</A10Radio.Button>
                  <A10Radio.Button value="ip_list">IP List</A10Radio.Button>
                  <A10Radio.Button value="templates">Templates</A10Radio.Button>
                </>
              )}
              <A10Radio.Button value="certificate">Certificate</A10Radio.Button>
              <A10Radio.Button value="crl">CRL</A10Radio.Button>
            </A10Radio.Group>
          </A10Row>
          {this.renderApp()}
        </ContentSection>
        <A10SlidingPage
          isOpen={!!formComponent}
          modalSize={modalSize}
          onRequestClose={this.closeSlidingPage}
        >
          {formComponent ? (
            <formComponent.type {...formComponent.props} />
          ) : null}
        </A10SlidingPage>
      </>
    )
  }
}

export default setupA10Container(System)
