import { getNS, IHTTPRequestOptions } from '@gui-libraries/framework'
import { _ } from '@gui-libraries/framework'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import { CertInfo } from 'src/libraries/certpem'

const HPCAPI_PREFIX = '/hpcapi/v3'
const getProvider = () => storage.get.PROVIDER

export const getCertList = (
  tenant: string,
  search: string,
  logicalPartition: string,
): IHTTPRequestOptions => {
  return {
    namespace: getNS('CERT_LIST'),
    request: async (epicDependence: IObject) => {
      try {
        const provider = getProvider()
        let certURL = `${HPCAPI_PREFIX}/provider/${provider}/tenant/${tenant}/logical-partition/${logicalPartition}/file/ssl-cert`
        let keyURL = `${HPCAPI_PREFIX}/provider/${provider}/tenant/${tenant}/logical-partition/${logicalPartition}/file/ssl-key`
        let caURL = `${HPCAPI_PREFIX}/provider/${provider}/tenant/${tenant}/logical-partition/${logicalPartition}/file/ssl-ca`
        if (search) {
          certURL += `?name=${search}`
          keyURL += `?name=${search}`
          caURL += `?name=${search}`
        }
        let resList: IObject[] = []
        const { data: certRes } = await httpClient.get(certURL)
        const certList = certRes['ssl-cert-list'] || []
        for (const cert of certList) {
          try {
            const certName = encodeURIComponent(cert.name)
            // const { data: resp } = await httpClient.get(
            //   `/hrcapi/v3/provider/${getItem(
            //     'PROVIDER',
            //   )}/tenant/${tenant}/logical-partition/${logicalPartition}/file/ssl-cert/${certName}`,
            // )
            cert.type = 'cert'
            // const referredList = resp['referrer-list'] || []
            // cert.object_count = referredList.length
            const certContent = await httpClient.get(
              `/hpcapi/v3/provider/${provider}/tenant/${tenant}/logical-partition/${logicalPartition}/file/ssl-cert/${certName}`,
            )
            if (!certContent) {
              cert.certContent = null
              continue
            }
            const certpem = new CertInfo()
            const certBeginIndex = certContent.data.indexOf(
              '-----BEGIN CERTIFICATE-----',
            )
            let certText = certContent.data
            if (certBeginIndex > -1) {
              certText = certContent.data.substring(certBeginIndex)
            }
            cert.certContent = certpem.getCertInfo(certText)
          } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err)
            cert.certContent = null
          }
        }
        resList = resList.concat(certList)
        const { data: keyRes } = await httpClient.get(keyURL)
        const keyList = keyRes['ssl-key-list'] || []
        keyList.map((key: IObject) => {
          key.type = 'key'
        })
        resList = resList.concat(keyList)
        const { data: caRes } = await httpClient.get(caURL)
        const caList = caRes['ssl-ca-list'] || []
        for (const ca of caList) {
          try {
            const caName = encodeURIComponent(ca.name)
            ca.type = 'ca'
            const certContent = await httpClient.get(
              `/hpcapi/v3/provider/${provider}/tenant/${tenant}/logical-partition/${logicalPartition}/file/ssl-ca/${caName}`,
            )
            if (!certContent) {
              ca.certContent = null
              continue
            }
            const certpem = new CertInfo()
            const certBeginIndex = certContent.data.indexOf(
              '-----BEGIN CERTIFICATE-----',
            )
            let certText = certContent.data
            if (certBeginIndex > -1) {
              certText = certContent.data.substring(certBeginIndex)
            }
            ca.certContent = certpem.getCertInfo(certText)
          } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(err)
            ca.certContent = null
          }
        }
        resList = resList.concat(caList)
        resList = _.sortBy(resList, ['name'])
        return resList
      } catch (err) {
        // tslint:disable-next-line:no-console
        console.error(err)
      }
      return []
    },
  }
}
