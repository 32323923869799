import React from 'react'
import { A10Tabs } from '@gui-libraries/widgets'

interface ITabGroupProps {
  onTabChange: (tab: string) => void
}

const { TabPane } = A10Tabs
const TABS = [
  {
    key: 'clusterPartitions',
    name: 'Cluster Partitions',
  },
  {
    key: 'devices',
    name: 'Devices',
  },
  {
    key: 'vrrpa',
    name: 'VRRP-A',
  },
  {
    key: 'scaleout',
    name: 'Scaleout',
  },
  {
    key: 'systemServices',
    name: 'System Services',
  },
  {
    key: 'protocolSettings',
    name: 'Protocol Settings',
  },
  {
    key: 'monitorSettings',
    name: 'Monitor Settings',
  },
]

const TabGroup: React.FC<ITabGroupProps> = props => {
  const { onTabChange } = props

  const onChange = (key: string) => {
    onTabChange(key)
  }

  return (
    <A10Tabs {...props} onChange={onChange} type="card">
      {TABS.map(tab => (
        <TabPane key={tab.key} tab={tab.name} />
      ))}
    </A10Tabs>
  )
}

export default TabGroup
