import React from 'react'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Button,
  A10Form,
  A10Input,
  A10Select,
  A10Modal,
  A10Message,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { Messages } from 'src/locale/en/Messages'
import { InfrastructureService, OrganizationService } from 'src/services/'
import { Utilities } from 'src/services'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import storage from 'src/libraries/storage'
import './styles/userform.scss'
import { QR_CODE_GENERATED } from 'src/constants/MFAStatus/MFAConstants'

export interface IUserFormProps extends IA10ContainerDefaultProps {
  addUserObj: any
  form: any
  onRef?: any
  allUsers: any
  handleChange?: (data: IObject) => void
  setSlidingPage: (data: boolean) => void
  loadUsers: () => void
  userInfo: any
  oncancel: any
}
export interface IUserFormState {
  addUserObj: any
  activationLink: string
  userStatus: any
  userRoleInfo: any
  accessGroupListUpdated: boolean
  visible: boolean
  loadingIconModal: boolean
}

class UserForm extends A10Container<IUserFormProps, IUserFormState> {
  Messages = new Messages()
  OrganizationService = new OrganizationService()
  InfrastructureService = new InfrastructureService()
  Utilities = new Utilities()
  editMode = false
  index: number
  accessGroupList: any = []
  provider
  adminLevel
  authType
  userId
  isSuperUser
  isMfaEnable

  constructor(props: IUserFormProps) {
    super(props)

    const {
      get: {
        PROVIDER,
        ADMIN_LEVEL,
        USER_SESSION_AUTHTYPE,
        USER_ID,
        IS_SUPER_USER,
        IS_MFA_ENABLE,
      },
    } = storage

    this.provider = PROVIDER
    this.adminLevel = ADMIN_LEVEL
    this.authType = USER_SESSION_AUTHTYPE
    this.userId = USER_ID
    this.isSuperUser = IS_SUPER_USER
    this.isMfaEnable = IS_MFA_ENABLE

    if (
      props.userInfo &&
      (props.userInfo.state == 'UNVERIFIED' || this.adminLevel == 'provider')
    ) {
      this.getActivationLink()
    }
    if (props.addUserObj.userId != '') {
      this.editMode = true
    }
    this.state = {
      addUserObj: {...this.props.addUserObj},
      activationLink: '',
      userStatus: this.props.userInfo,
      userRoleInfo: {},
      accessGroupListUpdated: false,
      visible: false,
      loadingIconModal: false,
    }

    this.loadAccessGroups()
  }

  loadAccessGroups = () => {
    const accessGroupeResponse = this.OrganizationService.getRbacAccessGroups(
      null,
      null,
      [this.provider],
    )
    accessGroupeResponse
      .then((resp: any) => {
        this.accessGroupList = resp?.data ? resp.data : []
        this.setState({ accessGroupListUpdated: true })
      })
      .catch((error: any) => {
        console.log('Access groups load failed', error)
      })
  }

  handleChange = (stateName: string, e: any) => {
    let userObj = this.state.addUserObj
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        userObj[stateName] = e.target.checked
      } else {
        userObj[stateName] = e.target.value
      }
    } else {
      userObj[stateName] = e
    }

    // start - Added code for restricting add of multiple access group - HARMONY-21354
    if (stateName === 'rbacRole') {
      userObj.rbacRoles = []
      if (userObj[stateName]) {
        userObj.rbacRoles.push(userObj[stateName])
      }
    }
    // end - Added code for restricting add of multiple access group - HARMONY-21354

    this.onChangeCallback(userObj)
    // @ts-ignore
    this.setState(
      {
        addUserObj: userObj,
      },
      () => {
        if (stateName === 'lastName') {
          const { form } = this.props
          form.validateFields(
            ['firstName', 'lastName'],
            (err: any, values: any) => { },
          )
        }
      },
    )
  }

  onChangeCallback(data: any) {
    this.props.handleChange(data)
  }

  checkRbacRole = (rbacRole: string) => {
    const rbacRoles = this.state.addUserObj.rbacRoles
    for (let i = 0; rbacRoles && i < rbacRoles.length; i++) {
      if (rbacRoles[i] == rbacRole) {
        return false
      }
    }
    return true
  }

  renderRbacRoleDropdownOpts = () => {
    const rbacRoles = this.accessGroupList

    return rbacRoles.map((rbacRole: any, i: number) => {
      // start of commented code for adding multiple access group - HARMONY-21354
      // return this.checkRbacRole(rbacRole.name) ? (
      return (
        <A10Select.Option
          key={'user-rbac-role-' + i}
          value={rbacRole.name}
          title={
            rbacRole['display-name'] || rbacRole['displayName'] || rbacRole.name
          }
        >
          {rbacRole['display-name'] || rbacRole['displayName'] || rbacRole.name}
        </A10Select.Option>
      )
      // ) : null
      // end of commented code for adding multiple access group - HARMONY-21354
    })
  }

  getAGDisplayName = (agName: string) => {
    const roleObj = this.accessGroupList.find((role: any) => {
      return role.name === agName
    })
    return roleObj ? roleObj['displayName'] || roleObj['name'] : agName
  }

  renderRoles = () => {
    const rbacRoles = this.state.addUserObj.rbacRoles
    return rbacRoles ? (
      <div className="col-sm-12">
        {rbacRoles.map((rbacRole: any, i: number) => {
          return rbacRole ? (
            <div className="row" key={'tenantApp-' + i}>
              <div className="col-sm-8 truncate">
                <label
                  className="control-label"
                  title={this.getAGDisplayName(rbacRole)}
                >
                  {this.getAGDisplayName(rbacRole)}
                </label>
              </div>
              <div className="col-sm-1 svgInc pdlr-24">
                <A10Icon
                  app="global"
                  style={{ fontSize: 24 }}
                  type="delete-another"
                  onClick={this.deleteRbacRole.bind(this, i, rbacRoles.length)}
                />
              </div>
            </div>
          ) : null
        })}
      </div>
    ) : null
  }

  showAddRbacRole = () => {
    this.handleChange('rbacRole', '')
    this.handleChange('showRbacRole', true)
  }

  addRbacRole = (rbacRoleLength: number) => {
    const curLength = this.state.addUserObj.rbacRoles
      ? this.state.addUserObj.rbacRoles.length
      : 0
    if (rbacRoleLength === curLength) {
      if (!this.state.addUserObj.rbacRole) {
        this.Utilities.showMessage('SELECT_ROLE', 0, 1)
        return
      }

      this.pushRbacRole(this.state.addUserObj.rbacRole)
    }
  }

  pushRbacRole = (rbacRole: any) => {
    let pushRbacRole = this.state.addUserObj.rbacRoles
      ? this.state.addUserObj.rbacRoles
      : []
    pushRbacRole.push(rbacRole)
    this.handleChange('rbacRoles', pushRbacRole)
    this.handleChange('rbacRole', '')
    this.handleChange('showRbacRole', false)
  }

  popRbacRole = (index: number) => {
    let popTenant = this.state.addUserObj.rbacRoles
    popTenant.splice(index, 1)
    this.handleChange('rbacRoles', popTenant)
  }

  cancelRbacRole = () => {
    this.handleChange('rbacRole', '')
    this.handleChange('showRbacRole', false)
  }

  deleteRbacRole = (index: number, rbacRolesLength: number) => {
    if (rbacRolesLength === this.state.addUserObj.rbacRoles.length) {
      this.popRbacRole(index)
    }
  }

  resetPassword = () => {
    this.handleChange('resetPassword', true)
  }

  getActivationLink = () => {
    const activationLinkRequest = this.OrganizationService.getUserActivationMail(
      null,
      null,
      this.props.userInfo['user-id'],
    )
    activationLinkRequest.then((response: any) => {
      if (response.data.link === 'Not an unverified user') {
        this.setState({
          activationLink: '',
        })
      } else {
        this.setState({
          activationLink: response.data.link,
        })
      }
      return response.data.link
    })
  }

  handleValidateUserID = (rule: any, userID: any, cb: any) => {
    let index = -1
    let isValid: boolean = true
    const userIDPattern = new RegExp(this.Utilities.USERID_PATTERN)
    if (!this.editMode) {
      if ('' === userID) {
        rule.message = this.Messages.EMPTY_USER_ID
        isValid = false
      } else if (!userIDPattern.test(userID)) {
        rule.message =
          this.Messages.INVALID_USER_ID +
          ', it cannot exceed 64 letters and no special characters (except ,.()@-_=)'
        isValid = false
      } else if (
        userID.toUpperCase() === 'provider-admin'.toUpperCase() ||
        userID.toUpperCase() === 'super-admin'.toUpperCase() ||
        userID.toUpperCase() === 'tenant'.toUpperCase()
      ) {
        rule.message = 'Please enter different user id, entered id is reserved'
        isValid = false
      } else {
        index = this.props.allUsers.findIndex((key: any) => {
          return key['user-id'] === userID
        })
        if (index > -1) {
          rule.message = this.Messages.DUPLICATE_USER_NAME
          isValid = false
        }
      }
    }

    return isValid ? cb() : cb(true)
  }

  validateName = (rule: any, value: any, cb: any) => {
    const { userInfo } = this.props
    const { firstName, lastName } = this.state?.addUserObj
    let isValid: boolean = true
    const userRegx = new RegExp(/^[A-Za-z][a-zA-Z0-9._-]{0,31}$/)
    const name = firstName + lastName
    if ('' === name) {
      rule.message = this.Messages.EMPTY_USER_NAME
      isValid = false
    } else if (firstName === '') {
      rule.message = this.Messages.EMPTY_FIRST_NAME
      isValid = false
    } else if (firstName !== userInfo?.['first-name'] && !userRegx.test(firstName)) {
      rule.message = this.Messages.INVALID_FIRST_NAME
      isValid = false
    } else if (firstName !== '' && lastName === '') {
      rule.message = this.Messages.EMPTY_LAST_NAME
      isValid = false
    } else if (lastName !== userInfo?.['last-name'] && !userRegx.test(lastName)) {
      rule.message = this.Messages.INVALID_LAST_NAME
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    // this.props.onRef(undefined)
  }

  handleResetmfa = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = async () => {
    const headers = {
      provider: this.provider,
    }
    const payload = {
      userId: this.props.addUserObj.userId,
    }
    this.setState({
      loadingIconModal: true,
    });
    try {
      const { status: statusCode } = await this.OrganizationService.resetMFA(
        headers,
        payload,
        null,
      )
      if (statusCode === 200) {
        const message = `MFA settings reset successfully for ${(this.props.addUserObj.userId)}`
        A10Message.success(message, 10, close)
      }
    } catch {
      A10Message.error('An error occurred while resetting MFA settings', 10, close)
      this.setState({
        visible: false,
        loadingIconModal: false,
      })
    }
    this.props.setSlidingPage(false)
    this.props.loadUsers()
  }

  handleCancel = () => {
    if (this.state.loadingIconModal) {
      return
    }
    this.setState({
      visible: false,
    })
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    }
    const formTenantItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    }
    const {
      firstName,
      lastName,
      userId,
      emailId,
      rbacRoles,
      rbacRole,
      showRbacRole,
    } = this.state.addUserObj

    const { getFieldDecorator } = this.props.form,
      { userStatus } = this.state

    const strMFAStatus = (this.state?.addUserObj?.mfaStatus === QR_CODE_GENERATED) ? 'PENDING' : this.state?.addUserObj?.mfaStatus

    return (
      <>
        <A10Form hideRequiredMark={true} layout="horizontal">
          <A10Panel
            title={
              <>
                <A10IconTextGroup
                  text={
                    <>
                      User's Info
                      {/* <span><HelpInfo customCls="custom-tooltip-head" placement="right" title="User's Info" helpKey="HELP_ORG_USER_INFORMATION" /></span> */}
                    </>
                  }
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, marginRight: 12 }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">{this.Messages.NAME}</span>
                  <span style={{ marginLeft: '-10px' }}>
                    <HelpInfo
                      placement="left"
                      title={this.Messages.NAME + ' *'}
                      helpKey="HELP_ORG_USER_NAME"
                    />
                  </span>
                </>
              }
            >
              <div className="row">
                <div className="col-md-6">
                  {getFieldDecorator('firstName', {
                    rules: [
                      {
                        validator: this.validateName.bind(this),
                      },
                    ],
                    initialValue: firstName,
                  })(
                    <A10Input
                      type="text"
                      placeholder={this.Messages.USER_FIRSTNAME}
                      onChange={this.handleChange.bind(this, 'firstName')}
                    />,
                  )}
                </div>
                <div className="col-md-6">
                  {getFieldDecorator('lastName', {
                    rules: [
                      {
                        validator: this.validateName.bind(this),
                      },
                    ],
                    initialValue: lastName,
                  })(
                    <A10Input
                      type="text"
                      placeholder={this.Messages.USER_LASTNAME}
                      onChange={this.handleChange.bind(this, 'lastName')}
                    />,
                  )}
                </div>
              </div>
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">{this.Messages.EMAIL}</span>
                  <span style={{ marginLeft: '-10px' }}>
                    <HelpInfo
                      placement="left"
                      title={this.Messages.EMAIL}
                      helpKey="HELP_ORG_USER_EMAIL_ID"
                    />
                  </span>
                </>
              }
            >
              <div>
                {getFieldDecorator('emailId', {
                  rules: [
                    { required: true, message: this.Messages.EMPTY_EMAIL },
                    {
                      pattern: this.Utilities.EMAIL_PATTERN,
                      message: this.Messages.INVALID_EMAIL,
                    },
                  ],
                  initialValue: emailId,
                })(
                  <A10Input
                    type="text"
                    placeholder={this.Messages.EMAIL}
                    onChange={this.handleChange.bind(this, 'emailId')}
                  />,
                )}
              </div>
            </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.USER_ID}
                  </span>
                  <span style={{ marginLeft: '-10px' }}>
                    <HelpInfo
                      placement="left"
                      title="User Id * "
                      helpKey="HELP_ORG_USER_ID"
                    />
                  </span>
                </>
              }
            >
              <div>
                {getFieldDecorator('userId', {
                  rules: [
                    {
                      validator: this.handleValidateUserID,
                    },
                  ],
                  initialValue: userId,
                })(
                  <A10Input
                    type="text"
                    placeholder={this.Messages.USER_ID}
                    disabled={this.editMode}
                    maxLength={300}
                    onChange={this.handleChange.bind(this, 'userId')}
                  />,
                )}
              </div>
            </A10Form.Item>
            <A10Form.Item
              {...formTenantItemLayout}
              label={
                <>
                  Access Group
                  <span style={{ marginLeft: '-10px' }}>
                    <HelpInfo
                      placement="left"
                      title="Access Group"
                      helpKey="HELP_ORG_USER_ROLE"
                    />
                  </span>
                </>
              }
            >
              <div
                className={
                  'row' +
                  (this.state.addUserObj.rbacRoles &&
                    this.state.addUserObj.rbacRoles.length > 1
                    ? ''
                    : ' scrollMinHeight')
                }
              >
                <div className="col-sm-8">
                  <A10Select
                    title={rbacRole}
                    key={'rbacRole'}
                    onChange={this.handleChange.bind(this, 'rbacRole')}
                    placeholder="Please select access group"
                    allowClear={true}
                    defaultValue={rbacRole ? rbacRole : undefined}
                  >
                    {this.renderRbacRoleDropdownOpts()}
                  </A10Select>
                </div>
              </div>
            </A10Form.Item>
          </A10Panel>

          {this.editMode && (
            <A10Panel
              title={
                <>
                  <A10IconTextGroup
                    text={<>User Administration</>}
                    icon={
                      <A10Icon
                        style={{ width: 22, height: 22, marginRight: 12 }}
                        app="global"
                        type="form-section"
                        className="sliding-panel-icon"
                      />
                    }
                  />
                </>
              }
            >
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.USER_STATE}
                    <span style={{ marginLeft: '-10px' }}>
                      <HelpInfo
                        placement="left"
                        title={this.Messages.USER_STATE}
                        helpKey="HELP_ORG_USER_STATUS"
                      />
                    </span>
                  </>
                }
              >
                <label
                  className="control-label"
                  title={this.state.userStatus.state}
                >
                  <span className="hs-text">
                    <HealthStatus
                      text=""
                      hideTooltip={true}
                      type={
                        this.state.userStatus.state == 'ACTIVE'
                          ? 'ongoing'
                          : this.state.userStatus.state == 'UNVERIFIED' ||
                            this.state.userStatus.state == 'RESET_PASSWORD'
                            ? 'warning'
                            : 'stopped'
                      }
                    />
                  </span>
                  {this.state.userStatus.state}
                </label>
              </A10Form.Item>
              {this.state.userStatus.state == 'UNVERIFIED' ||
                this.state.userStatus.state == 'RESET_PASSWORD' ? (
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      {this.Messages.USER_ACTIVATIONLINK}
                      <HelpInfo
                        placement="left"
                        title={this.Messages.USER_ACTIVATIONLINK}
                        helpKey="HELP_ORG_USER_ACTIVATION_LINK"
                      />
                    </>
                  }
                >
                  <div
                    className="activation-link"
                    title={this.state.activationLink}
                  >
                    <a href={this.state.activationLink} target="_blank">
                      {this.state.activationLink}
                    </a>
                  </div>
                </A10Form.Item>
              ) : null}
              {this.state.userStatus.state == 'ACTIVE' &&
                (this.authType === 'default' || this.authType === 'local') ? (
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      {this.Messages.RESET_PASSWORD}
                      <HelpInfo
                        placement="left"
                        title={this.Messages.RESET_PASSWORD}
                        helpKey="HELP_ORG_USER_RESET_PASSWORD"
                      />
                    </>
                  }
                >
                  <A10Button className="resetBtn" onClick={this.resetPassword}>
                    Click Here to Reset
                  </A10Button>
                </A10Form.Item>
              ) : null}

              {this.state.userStatus.state == 'ACTIVE' &&
                (this.authType === 'default' || this.authType === 'local') &&
                this.isMfaEnable && (
                  <A10Form.Item
                    {...formItemLayout}
                    label={
                      <>
                        {this.Messages.USER_RESET_MFA}
                        <HelpInfo
                          placement="left"
                          title={this.Messages.USER_RESET_MFA}
                          helpKey="HELP_ORG_USER_RESET_MFA"
                        />
                      </>
                    }
                  >
                    {this.state.addUserObj.mfaStatus === 'CONFIGURED' ? (
                      <A10Button
                        className="resetBtn"
                        onClick={this.handleResetmfa}
                      >
                        Click Here to Reset
                      </A10Button>
                    ) :
                      strMFAStatus
                    }
                  </A10Form.Item>
                )}
            </A10Panel>
          )}
        </A10Form>
        <A10Modal
          title="Confirmation"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={700}
          footer={[
            <A10Button
              key="yes"
              type="primary"
              onClick={this.handleOk}
              className="yesButton"
              disabled={this.state.loadingIconModal}
            >
              Yes
            </A10Button>,
            <A10Button
              key="no"
              type="default"
              onClick={this.handleCancel}
              className="cancelButton"
              disabled={this.state.loadingIconModal}
            >
              Cancel
            </A10Button>,
          ]}
        >
          <p style={{ 'marginLeft': '10px' }}>
            You are about to reset MFA settings
            for {this.state.addUserObj.firstName} {this.state.addUserObj.lastName}  ({this.state.addUserObj.emailId}),
            User will need to reconfigure their MFA settings.
          </p>
          {this.state.loadingIconModal && (
            <div className="">
              <ReactLoading
                type="bars"
                color="#4a90e2"
                height={40}
                width={40}
              />
            </div>
          )}
        </A10Modal>
      </>
    )
  }
}

export default setupA10Container(A10Form.create()(UserForm))
