import React from 'react'
import {
  A10Container,
  IA10ContainerDefaultProps,
  setupA10Container,
} from '@gui-libraries/framework'
import parameters from 'parameters'
import './styles/footer.scss'

export interface IFooterProps extends IA10ContainerDefaultProps {
  pageType: string
  leftNavCollapsed: boolean
}
export interface IFooterState {}

class Footer extends A10Container<IFooterProps, IFooterState> {
  constructor(props: IFooterProps) {
    super(props)
  }
  render() {
    const { pageType, leftNavCollapsed } = this.props
    const isLoginFooter = pageType === 'login'
    const isNavCollapsed = leftNavCollapsed === true
    return (
      <div
        className={
          isLoginFooter
            ? 'login-footer'
            : 'main-footer' + (isNavCollapsed ? ' left-nav-collapsed' : '')
        }
      >
        {isLoginFooter && (
          <div className="product-version">{`Version ${parameters.VERSION_NUMBER}`}</div>
        )}
        <div>
          <div className="copyright">
            © Copyright {new Date().getFullYear()} A10 Networks, Inc. All Rights
            Reserved.
          </div>
          {isLoginFooter ? (
            <div className="footer-nav clearfix">
              <ul className="clearfix padding0">
                <li>
                  <a
                    href="https://www.a10networks.com/cloud-support"
                    target="_blank"
                  >
                    Help & Support
                  </a>
                </li>
                <li>•</li>
                <li>
                  <a
                    href="https://www.a10networks.com/company/legal-notices/a10-networks-website-terms-useservice"
                    target="_blank"
                  >
                    Terms of Service
                  </a>
                </li>
                <li>•</li>
                <li>
                  <a
                    href="https://www.a10networks.com/company/legal-notices/a10-networks-privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>•</li>
                <li>
                  <a
                    href="https://www.a10networks.com/company/legal-notices"
                    target="_blank"
                  >
                    Legal Notices
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default setupA10Container(Footer)
