import React from 'react'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10Tag,
  A10Modal,
  A10DropdownMenu,
  A10Col,
  A10Button,
  A10Input,
  A10Tooltip,
  A10Icon,
  A10Alert,
} from '@gui-libraries/widgets'

import {
  InfrastructureService,
  DashboardService,
  Utilities,
} from 'src/services/index'
import { AppRoot } from 'src/settings/appRoot'
import { IDefaultMethods } from 'src/containers/Controller'
import { Configs } from 'src/constants/Configs'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { VMDeviceAddForm } from 'src/containers/Controller/Infrastructure/VMDevices/Forms/VMDeviceAddForm'
import { VMDeviceCreateForm } from 'src/containers/Controller/Infrastructure/VMDevices/Forms/VMDeviceCreateForm'
import { AWSDeviceCreateForm } from 'src/containers/Controller/Infrastructure/VMDevices/Forms/AWSDeviceCreateForm'
import { AzureDeviceCreateForm } from 'src/containers/Controller/Infrastructure/VMDevices/Forms/AzureDeviceCreateForm'
import { KubernetesDeviceCreateForm } from 'src/containers/Controller/Infrastructure/VMDevices/Forms/KubernetesDeviceCreateForm'
import { SecondaryIPManagementForm } from 'src/containers/Controller/Infrastructure/VMDevices/Forms/SecondaryIPManagementForm'
import { OCIDeviceCreateForm } from 'src/containers/Controller/Infrastructure/VMDevices/Forms/OCIDeviceCreateForm'
import { VMDeviceInfo } from 'src/containers/Controller/Infrastructure/VMDevices/Forms/VMDeviceInfo'
import storage from 'src/libraries/storage'

const styles = require('./styles/vmdevices.scss')

export interface IVMDevicesProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
}
export interface IVMDevicesState {
  searchString: string
  selectedIndex: number
  allVMDevices: any[]
  modalState: boolean
  modalContent: string
  selectedDevice: any
  showSlidingPage: boolean
  createMode: boolean
  editMode: boolean
  slidingTitle: any
  slidingDesc: string
  slidingForm: string
  formData: any
  createFormData: any
  createProcess: boolean
  loadingIcon: boolean
  deleteCheck: boolean
  forceDelete: boolean
  sshModalState: boolean
  secondaryIPState: boolean
}

class VMDevices extends A10Container<IVMDevicesProps, IVMDevicesState> {
  DashboardService = new DashboardService()
  InfrastructureService = new InfrastructureService()
  Utilities = new Utilities()
  AppRoot = new AppRoot()
  Configs = new Configs()
  dataLoaded = false
  vmDevices: any[] = []
  devices: any[] = []
  childForm: any = null

  state = {
    searchString: '',
    selectedIndex: 0,
    allVMDevices: [],
    modalState: false,
    modalContent: '',
    deleteCheck: false,
    selectedDevice: { name: '' },
    showSlidingPage: false,
    createMode: false,
    createProcess: false,
    editMode: false,
    slidingTitle: '',
    slidingDesc: '',
    slidingForm: '',
    formData: {
      environment: '',
      credential: '',
      securePort: 443,
      hcClusterSelect: '',
      licenseObj: '',
      bandwidth: '',
      bandwidthUnit: '',
    },
    createFormData: {},
    loadingIcon: false,
    forceDelete: false,
    sshModalState: false,
    secondaryIPState: false,
  }

  adminLevel = storage.get.ADMIN_LEVEL
  isOperatorUser = storage.get.IS_OPERATOR_USER
  intervalId: any = []
  vmDeviceName = ''

  vmDevicesColumns = [
    {
      title: 'Device',
      dataIndex: 'name',
      key: 'name',
      className: 'td-truncate',
      render: (text: any, record: any) => {
        return (
          <A10Tooltip title={text} placement="topLeft">
            {text}
          </A10Tooltip>
        )
      },
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
    },
    {
      title: 'Cluster',
      dataIndex: 'cluster-name',
      key: 'cluster-name',
      className: 'td-truncate',
      render: (text: any, record: any) => {
        return (
          <A10Tooltip title={text} placement="topLeft">
            {text}
          </A10Tooltip>
        )
      },
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'cluster-name'),
    },
    {
      title: 'Management IP',
      dataIndex: 'host',
      key: 'host',
      render: (text: string, record: any, index: number) => {
        return (
          <div className={styles.iconsWrap}>
            {text ? <A10Tag>{text}</A10Tag> : null}
          </div>
        )
      },
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'host'),
    },
    {
      title: 'State',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: any, index: number) => {
        const status = record.status
        return (
          <div>
            {status === 'error' ? (
              <>
                <span>{text}</span>
                <A10Tooltip
                  placement="bottom"
                  title={record['status-detail']}
                  arrowPointAtCenter
                  overlayStyle={{ width: '150px' }}
                >
                  <A10Icon
                    style={{ marginLeft: '10px' }}
                    app="global"
                    type="warning-color"
                  />
                </A10Tooltip>
              </>
            ) : (
              <span>{text}</span>
            )}

            {record.deployStateCheck ? (
              <A10Icon style={{ marginLeft: '10px' }} type="sync" spin />
            ) : null}
          </div>
        )
      },
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'status'),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'location'),
      render: (text: any, record: any) => {
        if (record['infra-provider'] === 'kubernetes') {
          return record.host
        } else {
          return text
        }
      },
    },
    {
      title: 'Environment',
      dataIndex: 'infra-provider',
      key: 'infra-provider',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'infra-provider'),
    },
    {
      title: 'ACOS Version',
      dataIndex: 'acos-version',
      key: 'acos-version',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'acos-version'),
    },
    {
      title: 'Power State',
      dataIndex: 'power-state',
      key: 'power-state',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'power-state'),
      render: (text: string, record: any) => {
        return (
          <div>
            <span>{text}</span>
            {record['power-op-status'] === 'error' ? (
              <A10Tooltip
                placement="bottom"
                title={record['power-op-msg']}
                arrowPointAtCenter
                overlayStyle={{ width: '150px' }}
              >
                <A10Icon
                  style={{ marginLeft: '10px' }}
                  app="global"
                  type="warning-color"
                />
              </A10Tooltip>
            ) : null}
            {record.powerStateCheck ? (
              <A10Icon style={{ marginLeft: '10px' }} type="sync" spin />
            ) : null}
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (key: any, record?: any) => {
        const clickAction = (component: JSX.Element, index: number) => {
          if (component.key === 'redeploy') {
            if (key.status === 'error') {
              this.reDeployVM(key)
            }
          } else if (component.key === 'delete') {
            this.checkDeviceState(key)
          } else if (component.key === 'powerOnOff') {
            this.setState({
              modalState: true,
              modalContent:
                'Power ' + (key['power-state'] === 'on' ? 'Off' : 'On'),
              selectedDevice: key,
              deleteCheck: false,
            })
          } else if (component.key === 'ssh') {
            this.setState({
              sshModalState: true,
              selectedDevice: key,
            })
          } else if (component.key === 'secondaryIp') {
            if (key.status === 'deployed') {
              this.setState({
                secondaryIPState: true,
                selectedDevice: key,
              })
            }
          }
        }
        const menuItem = [
          <div
            key="redeploy"
            className={key.status === 'error' ? '' : 'disabled'}
          >
            Deploy
          </div>,
          <div key="powerOnOff">
            Power {key['power-state'] === 'on' ? 'Off' : 'On'}
          </div>,
          <div key="delete">Delete</div>,
        ]
        if (
          key['infra-provider'] === 'aws' ||
          key['infra-provider'] === 'oci' ||
          key['infra-provider'] === 'azure'
        ) {
          menuItem.push(<div key="ssh">Key Pair</div>)
        }
        if (key['infra-provider'] === 'kubernetes') {
          // Remove Power on/off operations as these dont apply on k8s
          menuItem.splice(1, 1)
        }

        if (
          key['infra-provider'] === 'aws' ||
          key['infra-provider'] === 'azure' ||
          key['infra-provider'] === 'oci'
        ) {
          menuItem.push(
            <div
              key="secondaryIp"
              className={key.status === 'deployed' ? '' : 'disabled'}
            >
              Secondary IP Address
            </div>,
          )
        }

        return this.isOperatorUser ? null : (
          <div className="text-right">
            <i>
              <A10DropdownMenu
                menu={storage.get.ADMIN_LEVEL === 'provider' ? menuItem : []}
                title=""
                style={{ color: '#757575', marginBottom: '-15px' }}
                onClick={clickAction}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            </i>
          </div>
        )
      },
    },
  ]

  loadVMDevices = (vmName?: any) => {
    const provider = storage.get.PROVIDER
    let vmDeviceResp = null
    const headerObj =
      this.adminLevel === 'provider'
        ? null
        : this.Utilities.getXAccountHeaderDetails(false)

    vmDeviceResp = this.InfrastructureService.getVMDevices(headerObj, null, [
      provider,
    ])

    vmDeviceResp
      .then((resp: any) => {
        const clusters = storage.get.ALLCLUSTERS
        let vmDevices = []
        if (resp && resp.data && resp.data['virtual-device-list']) {
          vmDevices = resp.data['virtual-device-list'] || []
        }

        vmDevices.map((obj: any) => {
          const clusterObj = clusters.find((cluster: any) => {
            return cluster.uuid === obj['cluster-id']
          })
          obj['cluster-name'] = clusterObj
            ? clusterObj['display-name'] || clusterObj.name
            : ''
          obj['cluster-type'] = clusterObj ? clusterObj.type : ''
          obj['cluster-data-plane-ha'] = clusterObj
            ? clusterObj['data-plane-ha']
            : ''

          if (obj.status === 'in-progress') {
            obj.deployStateCheck = 'in-progress'
          }
          if (obj['power-op-status'] === 'in-progress') {
            obj.powerStateCheck = 'in-progress'
          }
          if (obj['infra-provider'] === 'vmware') {
            obj.location = obj.placement.datacenter
          } else if (
            obj['infra-provider'] === 'aws' ||
            obj['infra-provider'] === 'azure' ||
            obj['infra-provider'] === 'oci'
          ) {
            obj.location = obj.placement.region
          } else {
            obj.location = ''
          }
          let host = ''
          obj['virtual-nic-list'] &&
            obj['virtual-nic-list'].map((nicObj: any) => {
              if (nicObj['interface-number'] === 'eth0') {
                host = nicObj['public-ip-address'] || nicObj['ip-address']
              }
            })
          obj.host = host
        })

        this.vmDevices = vmDevices
        this.dataLoaded = true
        this.setState({
          allVMDevices: vmDevices,
        })

        if (vmDevices?.length) {
          this.loadDevices()
        }

        if (vmName) {
          vmDevices.map((obj: any) => {
            if (obj.name === vmName) {
              this.setIntervalAPI(obj, 'deploy')
            }
          })
        }
      })
      .catch((error: any) => {
        console.log(error.response || error)
        this.dataLoaded = true
      })
  }

  loadDevices() {
    const provider = storage.get.PROVIDER
    const headerObj =
      this.adminLevel === 'provider'
        ? null
        : this.Utilities.getXAccountHeaderDetails(false)

    const deviceResp = this.InfrastructureService.getDevices(headerObj, null, [
      provider,
    ])
    deviceResp
      .then((res: any) => {
        const { allVMDevices } = this.state
        const devices = res?.data?.['device-list'] || []

        this.devices = devices

        allVMDevices &&
          allVMDevices.map((vmObj: any) => {
            this.devices &&
              this.devices.map((obj: any) => {
                if (
                  obj.uuid === vmObj['device-id'] &&
                  obj['primary-sw-version']
                ) {
                  vmObj['acos-version'] = obj['primary-sw-version']
                }
                if (
                  obj.uuid === vmObj['device-id'] &&
                  obj['licenseinfo-list']
                ) {
                  const licDevices = obj['licenseinfo-list']
                  licDevices.map((licDevice: any) => {
                    if (
                      licDevice['license-type'] &&
                      licDevice['license-type'] !== 'HC_LICENSE'
                    ) {
                      vmObj.license = licDevice['lic-key']
                      vmObj.bandwidth = licDevice['device-bandwidth']
                    }
                  })
                }
              })
          })
        this.setState({
          allVMDevices,
        })
      })
      .catch((error: any) => {
        console.log(error.response)
        this.devices = []
      })
  }

  checkDeviceState = (vmDevice: any) => {
    let deleteCheck = false
    if (vmDevice['device-id']) {
      this.devices.map((obj: any) => {
        if (
          obj.uuid === vmDevice['device-id'] &&
          (obj['registration-state'] === 'REGISTERED' ||
            obj['registration-state'].toUpperCase().indexOf('PROGRESS') > -1)
        ) {
          deleteCheck = true
        }
      })
    }

    this.setState({
      modalState: true,
      modalContent: 'Delete',
      forceDelete: false,
      selectedDevice: vmDevice,
      deleteCheck,
    })
  }

  SearchVMDevices = (e: any) => {
    const searchString =
      e && e.target && (e.target.value || e.target.value === '')
        ? e.target.value
        : this.state.searchString
    this.Utilities.search(this, searchString, 'name', {
      storeData: [...this.vmDevices],
      stateName: 'allVMDevices',
    })
  }

  onForceDeleteChange = (e: any) => {
    let forceDelete = this.state.forceDelete
    if (e.target) {
      if (e.target.type === 'checkbox') {
        forceDelete = e.target.checked
      } else {
        forceDelete = e.target.value
      }
    } else {
      forceDelete = e
    }
    this.setState({
      forceDelete,
    })
  }

  addDevice = () => {
    const deviceObj: any = {
      environment: undefined,
      credential: undefined,
      securePort: 443,
      hcClusterSelect: undefined,
      licenseObj: undefined,
      bandwidth: undefined,
      bandwidthUnit: '',
    }

    this.setState({
      formData: deviceObj,
      createMode: false,
      editMode: false,
      showSlidingPage: true,
      slidingTitle: 'Create Virtual Device',
      slidingDesc:
        'Please provide the detail of the virtual machine and cluster info',
      slidingForm: 'addVMDevice',
    })
  }

  setCeateFormTrue = () => {
    const vmDevice = JSON.parse(JSON.stringify(this.Configs.VMDeviceObj))
    const awsDevice = JSON.parse(JSON.stringify(this.Configs.AWSDeviceObj))
    const azureDevice = JSON.parse(JSON.stringify(this.Configs.AzureDeviceObj))
    const ociDevice = JSON.parse(JSON.stringify(this.Configs.OCIDeviceObj))

    const k8sDeployment = JSON.parse(
      JSON.stringify(this.Configs.K8sDeploymentObj),
    )
    const vmType =
      this.state.formData.environment === 'aws'
        ? 'AWS'
        : this.state.formData.environment === 'azure'
        ? 'Azure'
        : this.state.formData.environment === 'kubernetes'
        ? 'Kubernetes'
        : this.state.formData.environment === 'oci'
        ? 'oci'
        : 'VMware'
    this.setState({
      createMode: true,
      createFormData:
        this.state.formData.environment === 'aws'
          ? awsDevice
          : this.state.formData.environment === 'azure'
          ? azureDevice
          : this.state.formData.environment === 'kubernetes'
          ? k8sDeployment
          : this.state.formData.environment === 'oci'
          ? ociDevice
          : vmDevice,
      slidingTitle: 'Create Virtual Device',
      slidingDesc: 'Please create a virtual device in ' + vmType,
    })
  }

  slidingPageCloseFunc = () => {
    if (this.state.createMode) {
      if (this.state.createProcess) {
        return false
      } else {
        this.setState({
          createMode: false,
        })
      }
    } else {
      this.setSlidingPage(false)
    }
  }
  setCeateFormFalse = () => {
    this.setState({
      createMode: false,
    })
  }

  closeSecondaryIPPage = async () => {
    this.setState({ secondaryIPState: false })
    this.loadVMDevices(this.state.selectedDevice.name)
  }

  editDevice = (device: any) => {
    const deviceObj = device
    this.setState({
      selectedDevice: deviceObj,
      formData: deviceObj,
      editMode: true,
      showSlidingPage: true,
      slidingTitle: 'Edit Virtual Device',
      slidingDesc: '',
      slidingForm: 'editVMDevice',
    })
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({ showSlidingPage: isOpen })
  }

  handleFormChange = (data: any) => {
    // @ts-ignore
    this.setState({
      formData: data,
    })
  }

  handleCreateFormChange = (data: any) => {
    // @ts-ignore
    this.setState({
      createFormData: data,
    })
  }

  validateVMFormData = (createFormData: any) => {
    const { showMessage } = this.Utilities
    if (!createFormData.vmCluster && !createFormData.vmCluster.name) {
      showMessage('Please select VMware Cluster', 0, 0)
      return false
    }
    if (!createFormData.vmHost && !createFormData.vmHost.name) {
      showMessage('Please select Host', 0, 0)
      return false
    }
    if (!createFormData.vmResourcePool && !createFormData.vmResourcePool.name) {
      showMessage('Please select Resource Pool', 0, 0)
      return false
    }
    if (!createFormData.vmStorage && !createFormData.vmStorage.name) {
      showMessage('Please select Storage', 0, 0)
      return false
    }
    if (!createFormData.vmFolder && !createFormData.vmFolder.name) {
      showMessage('Please select Folders', 0, 0)
      return false
    }
    if (!createFormData.vmDiskImage && !createFormData.vmDiskImage.name) {
      showMessage('Please select Disk Image', 0, 0)
      return false
    }
    return true
  }

  validateK8sFormData = (createFormData: any, credential: any) => {
    const { showMessage } = this.Utilities
    const node = JSON.parse(createFormData.node)
    if (!createFormData.image) {
      showMessage('Please select Image Name', 0, 0)
      return false
    } else {
      let doCapacityValidations = true
      let isPodAvailable = true
      let validCPU = true
      let validMemory = true
      let validHugePages = true

      let isNetworkValid = true
      let isIpValid = true
      const ipSet = new Set()
      const pciAddressSet = new Set()
      let validInterfaces = true
      const sriovNetworks = new Set()
      const sriovNetworksMap = {}
      const sriovNetworkNames = new Set()
      let duplicateNetworks = false

      if (!node.available || Object.keys(node.available).length === 0) {
        doCapacityValidations = false
      }

      if (
        doCapacityValidations &&
        node.available.pods &&
        !(parseInt(node.available.pods) >= 1)
      ) {
        showMessage('Pods not available', 0, 0)
        isPodAvailable = false
      }

      if (createFormData.cpu == '') {
        showMessage('Please enter no. of CPUs ', 0, 0)
        validCPU = false
      } else {
        if (
          doCapacityValidations &&
          node.available.cpu &&
          parseInt(createFormData.cpu) > parseInt(node.available.cpu, 10)
        ) {
          showMessage(
            'CPU exceeds the capacity. Available CPUs: ' +
              parseInt(node.available.cpu, 10),
            0,
            0,
          )
          validCPU = false
        }
      }

      if (createFormData.memory == '') {
        showMessage('Please enter Memory size ', 0, 0)
        validMemory = false
      } else {
        if (
          doCapacityValidations &&
          node.available.memory &&
          parseInt(createFormData.memory) > parseInt(node.available.memory, 10)
        ) {
          showMessage(
            'Memory exceeds the capacity. Available Memory: ' +
              parseInt(node.available.memory, 10),
            0,
            0,
          )
          validMemory = false
        }
      }

      if (createFormData.vmNetworks.length > 1) {
        if (createFormData.hugePagesType === '') {
          showMessage('Please select type of HugePages', 0, 0)
          validHugePages = false
        }
        if (createFormData.hugePagesSize === '') {
          showMessage('Please enter HugePages size', 0, 0)
          validHugePages = false
        }
        if (
          doCapacityValidations &&
          node.available[createFormData.hugePagesType] &&
          createFormData.hugePagesType &&
          createFormData.hugePagesSize
        ) {
          let hg_capacity = node.available[createFormData.hugePagesType]
          if (
            parseInt(createFormData.hugePagesSize) > parseInt(hg_capacity, 10)
          ) {
            showMessage(
              'HugePages size exceeds the capacity. Available HugePages: ' +
                parseInt(hg_capacity, 10),
              0,
              0,
            )
            validHugePages = false
          }
        }
      }

      createFormData.vmNetworks.map((vmNet: any) => {
        if (vmNet.name === 'eth0') {
          return true
        }
        if (vmNet.network === '') {
          if (vmNet.interfaceDeviceType === 'pcipt') {
            showMessage('Please enter PCI Address', 0, 0)
          } else if (vmNet.interfaceDeviceType === 'sriov') {
            showMessage('Please select SRIOV Network', 0, 0)
          } else if (vmNet.interfaceDeviceType === 'vhost') {
            showMessage('Please select vHost Network', 0, 0)
          }
          isNetworkValid = false
        } else if (vmNet.interfaceDeviceType === 'pcipt') {
          const pciRegx = new RegExp(
            /^([a-fA-F0-9]{2}|[a-fA-F0-9]{4}):[a-fA-F0-9]{2}\.[a-fA-F0-9]{1,2}$/,
          )
          if (!pciRegx.test(vmNet.network)) {
            showMessage('Please enter valid PCI address', 0, 0)
            isNetworkValid = false
          } else {
            if (!pciAddressSet.has(vmNet.network)) {
              pciAddressSet.add(vmNet.network)
            } else {
              showMessage(
                "Same PCI address '" +
                  vmNet.network +
                  "' is entered for multiple interfaces. Please enter different PCI addresses.",
                0,
                0,
              )
              isNetworkValid = false
            }
          }
        }

        if (vmNet.ipAddr === '') {
          showMessage('Please enter IP Address', 0, 0)
          isIpValid = false
        } else {
          if (!this.Utilities.checkForValidIP(vmNet.ipAddr)) {
            showMessage('Please enter valid IP address', 0, 0)
            isIpValid = false
          } else {
            if (!ipSet.has(vmNet.ipAddr)) {
              ipSet.add(vmNet.ipAddr)
            } else {
              showMessage(
                'Same IP address is entered for multiple interfaces. Please enter different IP addresses.',
                0,
                0,
              )
              isIpValid = false
            }
          }
        }

        if (
          vmNet.interfaceDeviceType === 'sriov' &&
          doCapacityValidations &&
          vmNet.network
        ) {
          const network = JSON.parse(vmNet.network)
          const resourceName =
            network.annotations['k8s.v1.cni.cncf.io/resourceName']
          if (!sriovNetworks.has(resourceName)) {
            sriovNetworksMap[resourceName] = network.name
          }
          sriovNetworks.add(resourceName)
        }

        if (vmNet.interfaceDeviceType === 'sriov' && vmNet.network) {
          const network = JSON.parse(vmNet.network)
          if (!sriovNetworkNames.has(network.name)) {
            sriovNetworkNames.add(network.name)
          } else {
            showMessage(
              "Same Network '" +
                network.name +
                "' is selected for multiple interfaces. Please select different networks.",
              0,
              0,
            )
            duplicateNetworks = true
          }
        }
      })

      if (sriovNetworks.size > 0) {
        const netDict = {}
        sriovNetworks.forEach((network: any) => {
          if (!(network in netDict)) {
            netDict[network] = 0
          }
          createFormData.vmNetworks.forEach((networkInterface: any) => {
            if (
              networkInterface.name === 'eth0' ||
              networkInterface.interfaceDeviceType !== 'sriov'
            ) {
              return true
            }
            const vmNetwork = JSON.parse(networkInterface.network)
            if (
              vmNetwork.annotations['k8s.v1.cni.cncf.io/resourceName'] ===
              network
            ) {
              netDict[network] = netDict[network] + 1
            }
          })
        })
        for (const netDef in netDict) {
          if (netDict[netDef] > parseInt(node.available[netDef])) {
            showMessage(
              'Maximum no. of ' +
                sriovNetworksMap[netDef] +
                ' type of SRIOV interfaces that can be added are ' +
                node.available[netDef],
              0,
              0,
            )
            validInterfaces = false
          }
        }
      }

      if (
        !isPodAvailable ||
        !validCPU ||
        !validMemory ||
        !validHugePages ||
        !isNetworkValid ||
        !isIpValid ||
        !validInterfaces ||
        duplicateNetworks
      ) {
        return false
      }
    }
    return true
  }

  validateStaticPrivateIPAvailability = async (
    createFormData: any,
    credential: any,
    environment: string,
  ) => {
    const { showMessage } = this.Utilities
    let req_payload = {}
    let ip_list = []
    const unavailableIPs: string[] = []
    let valid = true
    for (let i = 0; i < createFormData.vmNetworks.length; i++) {
      if (
        !createFormData.vmNetworks[i].autoAssign &&
        createFormData.vmNetworks[i].ipAddr
      ) {
        if (environment == 'aws') {
          ip_list.push({
            'ip-address': createFormData.vmNetworks[i].ipAddr,
            'network-id': JSON.parse(createFormData.vpc).VpcId,
            'subnet-id': JSON.parse(createFormData.vmNetworks[i].subnet)
              .SubnetId,
          })
        } else if (environment == 'azure') {
          ip_list.push({
            'ip-address': createFormData.vmNetworks[i].ipAddr,
            'network-id': JSON.parse(createFormData.vnet).id,
            'network-name': JSON.parse(createFormData.vnet).name,
          })
        } else if (environment == 'oci') {
          ip_list.push({
            'ip-address': createFormData.vmNetworks[i].ipAddr,
            'network-id': JSON.parse(createFormData.vcn).id,
            'subnet-id': JSON.parse(createFormData.vmNetworks[i].subnet).id,
          })
        }
      }
    }
    req_payload['ip-addresses'] = ip_list
    if (ip_list.length > 0) {
      const infra_credential = JSON.parse(credential)
      let privateIPResponse: Promise<any>
      if (environment == 'aws') {
        privateIPResponse = this.InfrastructureService.checkAWSPrivateIPAvailability(
          null,
          req_payload,
          [infra_credential.uuid, JSON.parse(createFormData.region).RegionName],
        )
      } else if (environment == 'azure') {
        privateIPResponse = this.InfrastructureService.checkAzurePrivateIPAvailability(
          null,
          req_payload,
          [infra_credential.uuid],
        )
      } else if (environment == 'oci') {
        privateIPResponse = this.InfrastructureService.checkOCIPrivateIPAvailability(
          null,
          req_payload,
          [infra_credential.uuid, JSON.parse(createFormData.region).name],
        )
      }

      await privateIPResponse
        .then((response: any) => {
          let list = []
          if (response.data && response.data.status === 'Success') {
            list = response.data.load
            if (list && Object.keys(list).length > 0) {
              for (let key in list) {
                if (!list[key]) {
                  unavailableIPs.push(key)
                }
              }
            }
            if (unavailableIPs.length == 1) {
              let err_message =
                'Private IP Address ' +
                unavailableIPs[0] +
                ' is already in use.'
              showMessage(err_message, 0, 0, '')
              valid = false
            } else if (unavailableIPs.length > 1) {
              let unavailableIP_list = unavailableIPs[0]
              for (let i = 1; i < unavailableIPs.length; i++) {
                unavailableIP_list += ', ' + unavailableIPs[i]
              }
              let err_message =
                'Private IP Addresses [' +
                unavailableIP_list +
                '] are already in use.'
              showMessage(err_message, 0, 0, '')
              valid = false
            }
          }
        })
        .catch((error: any) => {
          // console.log(error.response)
          const responseCode =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.response_code
              ? error.response.data.response_code
              : error.response
              ? error.response.status
              : ''
          let msg =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : ''
          let message = 'Unable to check private IP address availability'
          if (responseCode === 403) {
            message += ', please check the credentials'
            msg = ''
          }
          showMessage(message, 0, 0, msg)
        })
    }
    return valid
  }

  validateCloudFormData = async (
    createFormData: any,
    credential: any,
    environment: string,
  ) => {
    const { showMessage } = this.Utilities
    if (!createFormData.image) {
      showMessage('Please select ' + environment.toUpperCase() + ' Image', 0, 0)
      return false
    } else {
      let ipAddrError = ''
      createFormData.vmNetworks.map((vmNet: any) => {
        if (!vmNet.autoAssign && vmNet.ipAddr === '') {
          ipAddrError += " '" + vmNet.name + "'"
        } else if (
          vmNet.ipAddr !== '' &&
          !this.Utilities.checkForValidIP(vmNet.ipAddr)
        ) {
          ipAddrError += " '" + vmNet.name + "'"
        }
      })
      if (ipAddrError !== '') {
        showMessage(
          'Please enter valid IP address for ' +
            ipAddrError +
            ' or select auto-assign',
          0,
          0,
        )
        return false
      }

      if (createFormData.vmNetworks) {
        const maxNics = JSON.parse(createFormData.instanceType).maxNics
        if (createFormData.vmNetworks.length > maxNics) {
          showMessage(
            'Maximum number of network interfaces supported by "' +
              JSON.parse(createFormData.instanceType).displayName +
              '" are ' +
              maxNics,
            0,
            0,
          )
          return false
        }
      }

      const publicIpAddrSet = new Set()
      const subnetSet = new Set()
      let duplicatePublicIps = false
      let duplicateSubnets = false
      let isIPinSubnet = true
      createFormData.vmNetworks.forEach((network: any) => {
        if (network.publicIP && !network.autoAllocate) {
          if (!publicIpAddrSet.has(network.publicIP)) {
            publicIpAddrSet.add(network.publicIP)
          } else {
            showMessage(
              'Same Public IP address is selected for multiple interfaces. Please select different IP addresses or use the auto-allocate checkbox.',
              0,
              0,
            )
            duplicatePublicIps = true
          }
        }
        if (network.subnet) {
          let subnet_cidr =
            environment === 'aws'
              ? JSON.parse(network.subnet).CidrBlock
              : environment === 'azure'
              ? JSON.parse(network.subnet).addressPrefix
              : environment === 'oci'
              ? JSON.parse(network.subnet).cidr_block
              : undefined

          if (!subnetSet.has(subnet_cidr)) {
            subnetSet.add(subnet_cidr)
          } else {
            showMessage(
              'Same subnet "' +
                subnet_cidr +
                '" is selected for multiple interfaces. Please select different subnets.',
              0,
              0,
            )
            duplicateSubnets = true
          }

          if (network.ipAddr) {
            const cidrIp = subnet_cidr.split('/')[0]
            const cidrSm = subnet_cidr.split('/')[1]
            const private_ip = network.ipAddr
            const ipmask = -1 << (32 - cidrSm)
            isIPinSubnet =
              (this.getIPnumber(private_ip) & ipmask) ==
              this.getIPnumber(cidrIp)
            if (!isIPinSubnet) {
              showMessage(
                'Private IP address ' +
                  private_ip +
                  ' does not belong to the range of subnet ' +
                  subnet_cidr,
                0,
                0,
              )
            } else if (
              environment === 'azure' &&
              this.getAzureReservedIPs(subnet_cidr).includes(private_ip)
            ) {
              showMessage(
                'Private IP address ' +
                  private_ip +
                  ' falls within the reserved range for subnet ' +
                  subnet_cidr +
                  ' in Azure. Please use an IP address outside the range.',
                0,
                0,
              )
              isIPinSubnet = false
            }
          }
        }
      })

      if (duplicatePublicIps || duplicateSubnets || !isIPinSubnet) {
        return false
      } else {
        return await this.validateStaticPrivateIPAvailability(
          createFormData,
          credential,
          environment,
        )
      }
    }

    return true
  }

  getIPnumber = (ip_address: any) => {
    const ip = ip_address.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/)
    if (ip) {
      return (+ip[1] << 24) + (+ip[2] << 16) + (+ip[3] << 8) + +ip[4]
    }
  }

  long2ip = (ip_address: number) => {
    let output = ''
    if (!isNaN(ip_address) && (ip_address >= 0 || ip_address <= 4294967295)) {
      output =
        Math.floor(ip_address / Math.pow(256, 3)) +
        '.' +
        Math.floor((ip_address % Math.pow(256, 3)) / Math.pow(256, 2)) +
        '.' +
        Math.floor(
          ((ip_address % Math.pow(256, 3)) % Math.pow(256, 2)) /
            Math.pow(256, 1),
        ) +
        '.' +
        Math.floor(
          (((ip_address % Math.pow(256, 3)) % Math.pow(256, 2)) %
            Math.pow(256, 1)) /
            Math.pow(256, 0),
        )
    }
    return output
  }

  getAzureReservedIPs = (subnet_cidr: String) => {
    const reservedIPList: String[] = []

    let range = []
    const cidr = subnet_cidr.split('/')
    const start = this.getIPnumber(cidr[0])
    range[0] = this.long2ip(start)
    range[1] = this.long2ip(Math.pow(2, 32 - parseInt(cidr[1])) + start - 1)
    reservedIPList.push(range[1])

    const s1 = range[0].substr(0, range[0].lastIndexOf('.'))
    const s2 = range[0].substr(range[0].lastIndexOf('.') + 1)
    for (let i = 0; i < 4; i++) {
      reservedIPList.push(s1 + '.' + (parseInt(s2) + i))
    }
    return reservedIPList
  }

  handleSave = async () => {
    const { formData, createFormData } = this.state
    const { showMessage } = this.Utilities
    const provider = storage.get.PROVIDER

    if (
      formData.environment === 'vmware' &&
      !this.validateVMFormData(createFormData)
    ) {
      return
    } else if (
      formData.environment === 'aws' ||
      formData.environment === 'azure' ||
      formData.environment === 'oci'
    ) {
      const validFormData = await this.validateCloudFormData(
        createFormData,
        formData.credential,
        formData.environment,
      )
      if (!validFormData) {
        return
      }
    } else if (
      formData.environment === 'kubernetes' &&
      !this.validateK8sFormData(createFormData, formData.credential)
    ) {
      return
    }

    this.setState({ createProcess: true })
    const vmPayload = this.Utilities.generateVMDeviceload(
      formData,
      createFormData,
    )

    const payload = {
      'virtual-device': vmPayload,
    }

    const vmDeviceSave = this.InfrastructureService.postVMDevices(
      null,
      payload,
      [provider],
    )
    vmDeviceSave
      .then((response: any) => {
        showMessage('SUCCESS_VMDEVICE_ADD', 1, 1)
        this.setSlidingPage(false)
        this.loadVMDevices(createFormData.vmName)
        this.setState({ createProcess: false })
      })
      .catch((error: any) => {
        const moreMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        showMessage('FAILURE_VMDEVICE_ADD', 0, 1, moreMsg)
        this.setSlidingPage(false)
        this.setState({ createProcess: false })
      })
  }

  handleDeviceAddFormValidation = () => {
    if (this.state.createMode) {
      const { validateAndSubmitForm } = this.Utilities
      validateAndSubmitForm(this.childForm.props)
    } else {
      const { validateAndSubmitForm } = this.Utilities
      if (!!this.state.formData.licenseObj && !this.state.formData.bandwidth) {
        this.Utilities.showMessage('Please enter bandwidth', 0, 0)
        return
      }
      validateAndSubmitForm(this.childForm.props)
    }
  }

  reDeployVM = (vmdevice: any) => {
    const { showMessage } = this.Utilities
    const provider = storage.get.PROVIDER
    const vmDeviceDeploy = this.InfrastructureService.redeployVMDevice(
      null,
      null,
      [provider, vmdevice.name],
    )
    vmDeviceDeploy
      .then((response: any) => {
        showMessage('SUCCESS_VMDEVICE_DEPLOY', 1, 1)
        this.setSlidingPage(false)
        this.setIntervalAPI(vmdevice, 'deploy')
      })
      .catch((error: any) => {
        const moreMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        showMessage('FAILURE_VMDEVICE_DEPLOY', 0, 1, moreMsg)
        this.setSlidingPage(false)
      })
  }

  powerOnOff = (vmdevice: any) => {
    const { showMessage } = this.Utilities
    const provider = storage.get.PROVIDER
    const powerOnOff = vmdevice['power-state'] === 'on' ? 'off' : 'on'
    this.setState({ loadingIcon: true })
    const vmDeviceDeploy = this.InfrastructureService.powerOnOffVMDevice(
      null,
      null,
      [provider, vmdevice.name, powerOnOff],
    )
    vmDeviceDeploy
      .then((response: any) => {
        this.setState({ loadingIcon: false })
        this.handleCancel()
        if (response.data && response.data.status === 'Success') {
          const message =
            'Virtual device power ' + powerOnOff + ' request initiated'
          showMessage(message, 1, 0)
          this.setIntervalAPI(vmdevice, 'power')
        } else if (response.data && response.data.status === 'Error') {
          const message =
            'Virtual device power ' + powerOnOff + ', request initiation fail'
          showMessage(message, 0, 0)
        }
      })
      .catch((error: any) => {
        this.setState({ loadingIcon: false })
        this.handleCancel()
        const message =
          'Virtual device power ' + powerOnOff + ', request initiation fail'
        const moreMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        showMessage(message, 0, 0, moreMsg)
      })
  }

  setIntervalAPI = (vmdevice: any, stateChange: string) => {
    const vmDevices = this.state.allVMDevices
    let vmName = ''
    vmDevices.map((obj: any) => {
      if (obj.uuid === vmdevice.uuid) {
        if (stateChange === 'deploy') {
          obj.status = 'in-progress'
          obj.deployStateCheck = 'in-progress' // vmdevice.status
        } else if (stateChange === 'power') {
          obj.powerStateCheck = 'in-progress' // vmdevice.status
        } else if (stateChange === 'delete') {
          obj.status = 'delete-in-progress'
          obj.deployStateCheck = 'delete-in-progress' // vmdevice.status
        }
        vmName = this.vmDeviceName = vmdevice.name
      }
    })
    this.vmDevices = vmDevices
    this.setState({ selectedDevice: vmdevice, allVMDevices: vmDevices })
    const index = this.intervalId.length
    const intervalId = setInterval(
      (i: number = index) => this.refreshVMDevice(i),
      15000,
    )
    this.intervalId.push({
      intervalId,
      vmname: vmName,
      asyncCall: stateChange,
    })
    this.refreshVMDevice(this.intervalId.length - 1)
  }

  refreshVMDevice = (index: number) => {
    const provider = storage.get.PROVIDER
    let vmDeviceResp = null
    const headerObj =
      this.adminLevel === 'provider'
        ? null
        : this.Utilities.getXAccountHeaderDetails(false)

    vmDeviceResp = this.InfrastructureService.getVMDeviceDetail(
      headerObj,
      null,
      [provider, this.intervalId[index].vmname],
    )
    vmDeviceResp
      .then((resp: any) => {
        let device: any = ''
        if (resp && resp.data && resp.data['virtual-device']) {
          device = resp.data['virtual-device'] || []
        }

        if (device) {
          const vmDevices = this.state.allVMDevices
          let stateChange = false
          vmDevices.map((obj: any) => {
            if (obj.uuid === device.uuid) {
              if (
                this.intervalId[index].asyncCall === 'power' &&
                obj.powerStateCheck !== device['power-op-status']
              ) {
                obj.powerStateCheck = ''
                stateChange = true
                if (device['power-op-status'] === 'error') {
                  obj['power-op-status'] = device['power-op-status']
                  obj['power-op-msg'] = device['power-op-msg']
                }
              } else if (
                this.intervalId[index].asyncCall === 'deploy' &&
                obj.deployStateCheck !== device.status
              ) {
                obj.deployStateCheck = ''
                stateChange = true
                if (device.status === 'error') {
                  obj['status-detail'] = device['status-detail']
                }
              }
              if (stateChange) {
                obj.status = device.status
                obj['power-state'] = device['power-state']
              }
              if (obj['infra-provider'] === 'aws') {
                obj['ssh-key-name'] = device['ssh-key-name'] || ''
                obj['ssh-key-fingerprint'] = device['ssh-key-fingerprint'] || ''
                obj['ssh-key-material'] = device['ssh-key-material'] || ''
              }
              let host = ''
              device['virtual-nic-list'].map((nicObj: any) => {
                if (nicObj['interface-number'] === 'eth0') {
                  host = nicObj['public-ip-address'] || nicObj['ip-address']
                }
              })
              obj.host = host
              obj['virtual-nic-list'] = device['virtual-nic-list']
            }
          })
          if (stateChange) {
            clearInterval(this.intervalId[index].intervalId)
            this.vmDevices = vmDevices
            this.setState({ allVMDevices: vmDevices })
          }
        }
      })
      .catch((error: any) => {
        console.log(error.response)
        if (this.intervalId[index].asyncCall === 'delete') {
          // this.deleteStateCheck = false
          this.loadVMDevices()
        }
        clearInterval(this.intervalId[index].intervalId)
        const vmDevices = this.state.allVMDevices
        vmDevices.map((obj: any) => {
          if (obj.name === this.intervalId[index].vmname) {
            obj.powerStateCheck = ''
            obj.deployStateCheck = ''
          }
        })
        this.vmDevices = vmDevices
        this.setState({ allVMDevices: vmDevices })
      })
  }

  handleDelete = (vmdevice: any) => {
    const { showMessage } = this.Utilities
    // if (vmdevice['power-state'] === 'on') {
    //   showMessage("Cannot delete the VM device in 'power on' state, please power off the device and try again", 2, 0)
    //   this.handleCancel()
    //   return
    // }

    this.setState({ loadingIcon: true })
    const provider = storage.get.PROVIDER
    const deleteVMDevice = this.InfrastructureService.deleteVMDevices(
      null,
      null,
      [provider, vmdevice.name],
    )
    deleteVMDevice
      .then(() => {
        this.setState({ loadingIcon: false })
        showMessage('SUCCESS_VMDEVICE_DELETE', 1, 1)
        this.handleCancel()
        // this.loadVMDevices()
        this.setIntervalAPI(vmdevice, 'delete')
      })
      .catch((error: any) => {
        this.setState({ loadingIcon: false })
        const moreMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        showMessage('FAILURE_VMDEVICE_DELETE', 0, 1, moreMsg)
        this.handleCancel()
        this.loadVMDevices()
      })
  }

  downloadSSH = () => {
    let element = document.createElement('a')
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' +
        encodeURIComponent(this.state.selectedDevice['ssh-key-material']),
    )
    element.setAttribute(
      'download',
      this.state.selectedDevice.name + ' SSH Key',
    )

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }

  handleOk = () => {
    if (this.state.modalContent === 'Delete') {
      this.handleDelete(this.state.selectedDevice)
    } else {
      this.powerOnOff(this.state.selectedDevice)
    }
  }

  handleCancel = () => {
    if (this.state.loadingIcon) {
      return false
    }
    this.setState({
      modalState: false,
      modalContent: '',
      forceDelete: false,
      selectedDevice: { name: '' },
      deleteCheck: false,
      sshModalState: false,
      secondaryIPState: false,
    })
  }
  refreshVMDevices = () => {
    this.loadVMDevices()
    this.setState({ searchString: '' })
  }

  componentDidMount() {
    if (!this.dataLoaded) {
      this.loadVMDevices()
    }
  }

  componentWillUnmount() {
    if (this.intervalId) {
      this.intervalId.map((obj: any) => {
        clearInterval(obj.intervalId)
      })
      this.intervalId = []
    }
  }

  renderVMDeviceInfo = (record: any) => {
    return (
      <div className="grid">
        <div className="col-md-12 margintop10" id="pdgBtmPrt">
          <VMDeviceInfo
            vmDevice={record}
            deployStateCheck={record.deployStateCheck}
            powerStateCheck={record.powerStateCheck}
            loadVMDevice={this.loadVMDevices}
          />
        </div>
      </div>
    )
  }

  render() {
    const { showSlidingPage } = this.state
    return (
      <>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col className="title-container">
            <ContentTitle
              title="Virtual Devices"
              count={this.state.allVMDevices.length}
            />
            <A10Tooltip
              title="Virtual Instances exist in the environment where they were created."
              placement="right"
            >
              <A10Icon app="global" type="information" />
            </A10Tooltip>
          </A10Col>
          <A10Col style={{ display: 'flex' }}>
            <A10Input.Search
              type="text"
              onChange={this.SearchVMDevices}
              onSearch={this.SearchVMDevices}
              name="searchBox"
              value={this.state.searchString}
              placeholder="Search"
              style={{ marginRight: '6px' }}
            />
            <ActionButton
              text="Refresh"
              onClick={this.refreshVMDevices}
              iconProps={{ app: 'global', type: 'refresh' }}
            />
            {storage.get.ADMIN_LEVEL === 'provider' && !this.isOperatorUser && (
              <ActionButton
                text="Create Virtual Device"
                onClick={this.addDevice}
                iconProps={{ app: 'global', type: 'add-new' }}
              />
            )}
          </A10Col>
        </ContentHeader>
        <ContentBody>
          <A10Table
            columns={this.vmDevicesColumns}
            expandedRowRender={this.renderVMDeviceInfo}
            dataSource={this.state.allVMDevices.map(
              (key: any, index: number) => {
                key.key = index
                return key
              },
            )}
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
          />
        </ContentBody>

        <FormatSlidingPage
          isOpen={showSlidingPage}
          onRequestOk={this.handleDeviceAddFormValidation}
          onRequestClose={this.slidingPageCloseFunc}
          title={this.state.slidingTitle}
          description={this.state.slidingDesc}
          saveText={
            this.state.createMode
              ? this.state.formData.environment === 'kubernetes'
                ? 'Create Container'
                : 'Create VM'
              : 'Next'
          }
          disableSave={
            !this.state.formData.credential || this.state.createProcess
          }
          hideCancel={this.state.createProcess || this.state.createMode}
          showBack={this.state.createMode && !this.state.createProcess}
        >
          <>
            {this.state.createMode ? (
              this.state.formData.environment === 'aws' ? (
                <AWSDeviceCreateForm
                  defaultMethods={this.props.defaultMethods}
                  formData={this.state.formData}
                  createFormData={this.state.createFormData}
                  device={
                    this.state.editMode ? this.state.selectedDevice : null
                  }
                  vmDevices={this.state.allVMDevices}
                  handleChange={this.handleCreateFormChange}
                  onSubmitForm={this.handleSave}
                  onRef={(ref: any): any => (this.childForm = ref)}
                />
              ) : this.state.formData.environment === 'azure' ? (
                <AzureDeviceCreateForm
                  defaultMethods={this.props.defaultMethods}
                  formData={this.state.formData}
                  createFormData={this.state.createFormData}
                  device={
                    this.state.editMode ? this.state.selectedDevice : null
                  }
                  vmDevices={this.state.allVMDevices}
                  handleChange={this.handleCreateFormChange}
                  onSubmitForm={this.handleSave}
                  onRef={(ref: any): any => (this.childForm = ref)}
                />
              ) : this.state.formData.environment === 'kubernetes' ? (
                <KubernetesDeviceCreateForm
                  defaultMethods={this.props.defaultMethods}
                  formData={this.state.formData}
                  createFormData={this.state.createFormData}
                  device={
                    this.state.editMode ? this.state.selectedDevice : null
                  }
                  vmDevices={this.state.allVMDevices}
                  handleChange={this.handleCreateFormChange}
                  onSubmitForm={this.handleSave}
                  onRef={(ref: any): any => (this.childForm = ref)}
                />
              ) : this.state.formData.environment === 'oci' ? (
                <OCIDeviceCreateForm
                  defaultMethods={this.props.defaultMethods}
                  formData={this.state.formData}
                  createFormData={this.state.createFormData}
                  device={
                    this.state.editMode ? this.state.selectedDevice : null
                  }
                  vmDevices={this.state.allVMDevices}
                  handleChange={this.handleCreateFormChange}
                  onSubmitForm={this.handleSave}
                  onRef={(ref: any): any => (this.childForm = ref)}
                />
              ) : (
                <VMDeviceCreateForm
                  defaultMethods={this.props.defaultMethods}
                  formData={this.state.formData}
                  createFormData={this.state.createFormData}
                  device={
                    this.state.editMode ? this.state.selectedDevice : null
                  }
                  vmDevices={this.state.allVMDevices}
                  handleChange={this.handleCreateFormChange}
                  onSubmitForm={this.handleSave}
                  onRef={(ref: any): any => (this.childForm = ref)}
                />
              )
            ) : (
              <VMDeviceAddForm
                defaultMethods={this.props.defaultMethods}
                formData={this.state.formData}
                device={this.state.editMode ? this.state.selectedDevice : null}
                handleChange={this.handleFormChange}
                onSubmitForm={this.setCeateFormTrue}
                onRef={(ref: any): any => (this.childForm = ref)}
                selectedDevice={this.props}
              />
            )}

            {this.state.createProcess ? (
              <div className="">
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </div>
            ) : null}
          </>
        </FormatSlidingPage>

        <FormatSlidingPage
          isOpen={this.state.secondaryIPState}
          onRequestOk={this.closeSecondaryIPPage}
          title="Manage Secondary IP Address"
          saveText="Close"
          hideCancel={true}
        >
          <SecondaryIPManagementForm
            defaultMethods={this.props.defaultMethods}
            device={this.state.selectedDevice}
            handleChange={this.handleFormChange}
            onRef={(ref: any): any => (this.childForm = ref)}
          />
        </FormatSlidingPage>

        <A10Modal
          title={'Key Pair - ' + this.state.selectedDevice.name}
          visible={this.state.sshModalState}
          onCancel={this.handleCancel}
          footer={[
            <A10Button key="yes" type="default" onClick={this.handleCancel}>
              Close
            </A10Button>,
          ]}
        >
          <div className="row pad-v-15">
            <label className="col-md-4">Name</label>
            <div className="col-md-12">
              <A10Input
                type="text"
                disabled={true}
                value={this.state.selectedDevice['ssh-key-name']}
              />
            </div>
          </div>
          <div className="row pad-v-15">
            <label className="col-md-4">Fingerprint</label>
            <div className="col-md-12">
              <A10Input
                type="text"
                disabled={true}
                value={this.state.selectedDevice['ssh-key-fingerprint']}
              />
            </div>
          </div>
          <div className="row pad-v-15">
            <label className="col-md-4">Material</label>
            <div className="col-md-12">
              <A10Input.TextArea
                style={{ height: '100px' }}
                disabled={true}
                value={this.state.selectedDevice['ssh-key-material']}
              />
            </div>
            {!!this.state.selectedDevice['ssh-key-material'] ? (
              <div className="col-md-12 pad-v-10">
                <A10Button
                  type="default"
                  className="pull-right"
                  onClick={this.downloadSSH}
                >
                  Download SSH Key
                </A10Button>
              </div>
            ) : null}
          </div>
        </A10Modal>

        <A10Modal
          title={this.state.modalContent + ' Virtual Device'}
          visible={this.state.modalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={this.state.loadingIcon}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
              disabled={
                this.state.loadingIcon ||
                // (this.state.modalContent === 'Delete' && this.state.selectedDevice['power-state'] === 'on') ||
                (this.state.modalContent === 'Delete' &&
                  this.state.deleteCheck &&
                  !this.state.forceDelete)
              }
            >
              Yes
            </A10Button>,
          ]}
        >
          {this.state.modalContent === 'Delete' && this.state.deleteCheck ? (
            <>
              <A10Alert
                message="Warning"
                showIcon={true}
                description={
                  this.state.deleteCheck
                    ? 'The associated device is registered or in the process of being registered. Please de-register the device before deleting it.'
                    : "Cannot delete the virtual device in 'power on' state. Please power off the virtual device before deleting."
                }
                type="warning"
              />
            </>
          ) : (
            <>
              <p>
                Are you sure you want to{' '}
                {this.state.modalContent.toLocaleLowerCase()}{' '}
                {this.state.selectedDevice.name}?
              </p>
              {this.state.loadingIcon ? (
                <div className="">
                  <ReactLoading
                    type="bars"
                    color="#4a90e2"
                    height={40}
                    width={40}
                  />
                </div>
              ) : null}
            </>
          )}
        </A10Modal>
      </>
    )
  }
}

export default setupA10Container(VMDevices)
