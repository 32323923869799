import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Message, A10Button } from '@gui-libraries/widgets'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import storage from 'src/libraries/storage'
import { httpClient } from 'src/libraries/httpClient'
import { IPartitionVersion } from './LogicalPartitionDeploy'
import LogicalPartitionDeploy from './index'

export interface ISlidingLPDeployProps extends IA10ContainerDefaultProps {
  type: string
  isOpen: boolean
  onRequestClose: (clickDeploy: boolean, hasDP: boolean, id?: string) => void
  partition: IObject
  additionalID?: string
}

export interface ISlidingLPDeployStates {
  provider: string
  subscribers: IObject[]
  clusterPartition: string
  isValidating: boolean
  showValidationAlert: boolean
  validationError: string
  assocLP?: IPartitionVersion
}

class SlidingLPDeploy extends A10Container<
  ISlidingLPDeployProps,
  ISlidingLPDeployStates
> {
  constructor(props: ISlidingLPDeployProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER || '',
      subscribers: [],
      clusterPartition: '',
      isValidating: false,
      showValidationAlert: false,
      validationError: '',
    }
  }

  onRequestOk = async () => {
    const { onRequestClose, partition, type } = this.props
    const { subscribers, clusterPartition, assocLP = null } = this.state
    const postSubscribeUri = '/hpcapi/v3/subscriber'
    const putSubscribeUri = `/hpcapi/v3/subscriber/${partition.uuid}`
    let keyword = 'Logical'
    if (type === 'device') {
      keyword = 'Device'
    }
    let vNum = 0
    if (partition['candidate-version']) {
      vNum = partition['candidate-version']
    } else if (partition.version) {
      vNum = partition.version
    } else {
      A10Message.error(
        `${keyword} Partition ${partition.name} cannot be deployed because no version is found.`,
      )
      return
    }
    const subscribePayload = {
      subscriber: {
        uuid: partition.uuid,
        'receiver-list': subscribers,
      },
    }
    const deployUri = '/hocapi/v1/deploy/'
    const deployPayload = {
      deploy: {
        'obj-id': partition.uuid,
        version: vNum,
      },
    }
    let headers = {}
    try {
      // set subscriber-list
      if (type === 'logical') {
        let subscriberChange = false
        let success = true
        const tenant = storage.get.CURRENT_TENANT
        headers = { tenant: tenant.name }
        try {
          const {
            data: {
              subscriber: { 'receiver-list': receivers },
            },
          } = await httpClient.get(putSubscribeUri)
          if (JSON.stringify(receivers) !== JSON.stringify(subscribers)) {
            subscriberChange = true
          }
          // unassociate cluster partition first
          if (subscriberChange) {
            for (const { uuid: id } of receivers) {
              const updateUri = `/hpcapi/v3/uuid/${id}`
              const updatePayload = {
                partition: {
                  // 'tenant-uuid': null,
                  // 'partition-uuid': null,
                  'lp-uuid': null,
                },
              }
              try {
                await httpClient.post(updateUri, updatePayload, { headers })
              } catch (err) {
                console.error(err)
              }
            }
          }
        } catch (err) {
          success = false
          await httpClient.post(postSubscribeUri, subscribePayload)
        }
        if (success && subscriberChange) {
          await httpClient.put(putSubscribeUri, subscribePayload)
        }
        // update cluster partition lp-uuid

        const tenantID = tenant.uuid
        for (const { uuid: id } of subscribers) {
          const updateUri = `/hpcapi/v3/uuid/${id}`
          const updatePayload = {
            partition: {
              'tenant-uuid': tenantID,
              // 'partition-uuid': '',
              'lp-uuid': partition.uuid,
            },
          }
          try {
            await httpClient.post(updateUri, updatePayload, { headers })
          } catch (err) {
            console.error(err)
          }
        }
      } else {
        if (assocLP) {
          const overrideList: IObject = []
          overrideList.push({
            'override-uuid': assocLP.uuid,
            'override-version': assocLP.rightVersion,
          })
          deployPayload.deploy['override-list'] = overrideList
        }
      }
      // TODO invoke deploy function
      const { data: Res } = await httpClient.post(deployUri, deployPayload, {
        absoluteBasePath: true,
        headers,
      })
      if (type === 'logical') {
        const subscriberLabel = `LogicalPartition_${partition.uuid}`
        storage.setItem(subscriberLabel, clusterPartition)
      }
      const workflowID = Res.workflow.uuid
      A10Message.success(
        `Deployment of ${keyword} Partition ${partition.name} Version ${vNum} is scheduled`,
      )
      onRequestClose(true, false, workflowID)
    } catch (err) {
      if (
        err.response &&
        err.response.status === 400 &&
        err.response.data &&
        err.response.data.message
      ) {
        A10Message.error(err.response.data.message)
      } else {
        A10Message.error('Deployment Failed')
      }
    }
  }

  validateCLI = async () => {
    this.setState({ isValidating: true })
    const { partition, type } = this.props
    const { assocLP = null } = this.state
    const payload = {
      'validate-config': {
        'config-list': [],
      },
    }
    let vNum = 0
    if (partition['candidate-version']) {
      vNum = partition['candidate-version']
    } else if (partition.version) {
      vNum = partition.version
    } else {
      A10Message.error(
        `Logical Partition ${partition.name} cannot be validated because no version is found.`,
      )
      this.setState({ isValidating: false })
      return
    }
    payload['validate-config']['config-list'].push({
      uuid: partition.uuid,
      version: vNum,
    })

    if (type === 'logical') {
      const subscriberLabel = `LogicalPartition_${partition.uuid}`
      const subscriberDeviceLabel = `LogicalPartition_${partition.uuid}_devices`
      if (
        storage.getItem(subscriberLabel) &&
        storage.getItem(subscriberDeviceLabel)
      ) {
        const dpName = storage.getItem(subscriberLabel).split('$$&&')[1]
        const deviceList = storage.getItem(subscriberDeviceLabel, true)
        const partitionList = deviceList.map((device: IObject) => {
          const p = device['partition-list'].filter(
            item => item.name === dpName,
          )[0]
          return p
        })
        for (const p of partitionList) {
          if (p.version) {
            payload['validate-config']['config-list'].push({
              uuid: p.uuid,
              version: p['candidate-version'] || p.version,
            })
          } else {
            const deployLPFailedMessage = `Validation cannot proceed. Device Partition ${p.name} does not have saved version`
            A10Message.error(deployLPFailedMessage)
            this.setState({ isValidating: false })
            return
          }
        }
      } else {
        A10Message.error(
          `No Device Partition is associated with Logical Partition ${partition.name}`,
        )
        this.setState({ isValidating: false })
        return
      }
    }

    if (type === 'device' && assocLP && assocLP.rightVersion) {
      payload['validate-config']['config-list'].push({
        uuid: assocLP.uuid,
        version: assocLP.rightVersion,
      })
    }

    const validateUri = '/hpcapi/v3/validate-config'
    try {
      A10Message.loading('Validation in progress', 0)
      const { data: res, statusText: text } = await httpClient.post(
        validateUri,
        payload,
        { absoluteBasePath: true },
      )
      A10Message.destroy()
      A10Message.success('Validation Successful')
      this.setState({ isValidating: false })
    } catch (err) {
      A10Message.destroy()
      this.setState({
        validationError: JSON.stringify(err.response.data),
        showValidationAlert: true,
        isValidating: false,
      })
      console.error(err)
    }
  }

  onCloseValidationAlert = () => {
    this.setState({ showValidationAlert: false, validationError: '' })
  }

  onFormChange = (data: IObject[], clusterPartition: string) => {
    this.setState({ subscribers: data, clusterPartition })
  }

  onVersionChange = (assocLP: IPartitionVersion) => {
    this.setState({ assocLP })
  }

  render() {
    const { isOpen, onRequestClose, partition, type } = this.props
    if (!partition) {
      return null
    }
    let vNum = partition.version
    if (partition['candidate-version']) {
      vNum = partition['candidate-version']
    }
    let keyword = 'Logical'
    if (type === 'device') {
      keyword = 'Device'
    }
    let title = `Deploying ${keyword} Partition ` + partition.name
    if (vNum) {
      title += ' Version ' + vNum
    }

    const saveState =
      partition.version === undefined ||
      (type === 'logical' && this.state.subscribers.length === 0)
    return (
      <FormatSlidingPage
        isOpen={isOpen}
        modalSize="large"
        title={title}
        onRequestOk={this.onRequestOk}
        onRequestClose={() => onRequestClose(false, false)}
        saveText="Deploy"
        disableSave={saveState}
        customizedButtons={
          <A10Button
            className="validate-button"
            onClick={this.validateCLI}
            disabled={this.state.isValidating}
          >
            Validate
          </A10Button>
        }
      >
        <LogicalPartitionDeploy
          type={type}
          partition={partition}
          onChange={this.onFormChange}
          onVersionChange={this.onVersionChange}
          showValidationAlert={this.state.showValidationAlert}
          validationError={this.state.validationError}
          closeValidationAlert={this.onCloseValidationAlert}
          additionalID={this.props.additionalID}
        />
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(SlidingLPDeploy)
