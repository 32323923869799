import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
  _,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10Button,
  A10Col,
  A10Input,
  A10Tooltip,
  A10SlidingPage,
  A10DropdownMenu,
  A10Modal,
  A10Message,
  A10Checkbox,
  A10Loader,
} from '@gui-libraries/widgets'
import { AutoFormV15 } from '@gui-libraries/apps'

import storage from 'src/libraries/storage'
import {
  getIPList,
  getIPListCount,
} from 'src/redux/httpServices/SharedObj/IpList'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import { httpClient } from 'src/libraries/httpClient'
import deployWrapper from 'src/containers/Launchers/ADC/SharedObjects/DeployWrapper'

const pageSize = 20

export interface IIPListProps extends IA10ContainerDefaultProps {
  data?: IObject[]
  total?: number
  scope: string
  handleDeploy: any
  tenant: string
  logicalPartition: string
}

export interface IIPListState {
  isLoading: boolean
  isOpenForm: boolean
  pageNumber: number
  searchText: string
  selectedObjectName: string
  selectedObjectUuid: string
  deleteModalVisible: boolean
  viewMode: boolean
  forceDelete: boolean
}

class IPList extends A10Container<IIPListProps, IIPListState> {
  defaultColumns: any[]
  delayedSearchText = _.debounce(searchString => {
    this.getData(searchString)
  }, 800)
  ref = React.createRef()

  constructor(props: IIPListProps) {
    super(props)
    this.state = {
      isOpenForm: false,
      isLoading: false,
      pageNumber: 1,
      searchText: '',
      selectedObjectName: '',
      selectedObjectUuid: '',
      deleteModalVisible: false,
      viewMode: false,
      forceDelete: false,
    }
  }

  componentDidMount() {
    const { scope } = this.props
    this.getData()
    this.setState({
      viewMode: !(
        scope !== 'provider' ||
        (storage.get.ADMIN_LEVEL === 'provider' &&
          !storage.get.IS_OPERATOR_USER)
      ),
    })
  }

  getData = (searchText = '') => {
    if (this.state.isLoading) {
      return
    }
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    this.setState({ isLoading: true })
    const { pageNumber } = this.state
    const param: IObject = {
      start: (pageNumber - 1) * pageSize,
      count: pageSize,
    }
    if (searchText) {
      param.name = encodeURIComponent(searchText)
    }
    try {
      httpRequest(getIPListCount(searchText))
      httpRequest(getIPList(param)).finally(() => {
        this.setState({
          isLoading: false,
        })
      })
    } catch (e) {
      this.setState({
        isLoading: false,
      })
      console.log(e)
    }
  }
  handleAdd = () => {
    this.setState({
      selectedObjectName: '',
      selectedObjectUuid: '',
      isOpenForm: true,
    })
  }

  onCheckForceDelete = (e: any) => {
    this.setState({ forceDelete: e.target.checked })
  }

  handleOk = async () => {
    const { tenant, logicalPartition, total } = this.props
    const {
      selectedObjectName,
      forceDelete,
      pageNumber,
      searchText,
    } = this.state
    try {
      const apiPrefixNext = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${logicalPartition}/`
      let actionUri = apiPrefixNext + `ip-list/${selectedObjectName}`
      if (forceDelete) {
        actionUri += '?force=true'
      }
      await httpClient.delete(actionUri)
      if (Math.ceil((total - 1) / pageSize) < pageNumber && pageNumber > 1) {
        this.setState({ pageNumber: pageNumber - 1 }, () => {
          this.getData(searchText)
        })
      } else {
        this.getData(searchText)
      }
      this.setState({ deleteModalVisible: false })
      A10Message.success(
        `Success: IP list ${selectedObjectName} has been deleted!`,
      )
    } catch (e) {
      // do nothing
      A10Message.error(
        `Error: Failed to delete the IP list ${selectedObjectName}!`,
      )
    }
  }

  handleDeleteCancel = () => {
    this.setState({
      deleteModalVisible: false,
    })
  }

  handleAddOK = () => {
    const { searchText } = this.state
    this.handleClose()
    this.getData(searchText)
  }

  handleClose = () => {
    this.setState({
      isOpenForm: false,
      selectedObjectName: '',
      selectedObjectUuid: '',
    })
  }

  onClickAction = (record: IObject, component: JSX.Element) => {
    if (component.key === 'edit' || component.key === 'view') {
      this.setState({
        selectedObjectUuid: record.uuid,
        selectedObjectName: record.name,
        isOpenForm: true,
      })
    } else if (component.key === 'delete') {
      this.setState({
        forceDelete: false,
        deleteModalVisible: true,
        selectedObjectName: record.name,
        selectedObjectUuid: record.uuid,
      })
    } else if (component.key === 'deploy') {
      if (this.props.handleDeploy) {
        this.props.handleDeploy(record)
      }
    }
  }

  handleSearch = (e: any) => {
    const searchString = e.target.value
    this.setState(
      {
        searchText: searchString,
        pageNumber: 1,
      },
      () => {
        this.delayedSearchText(searchString)
      },
    )
  }

  handlePageChange = (page: number) => {
    const { searchText } = this.state
    this.setState({ pageNumber: page }, () => {
      this.getData(searchText)
    })
  }

  renderSlidingPage = () => {
    const { isOpenForm, selectedObjectName, viewMode } = this.state
    const param: IObject = {}
    if (selectedObjectName !== '') {
      param.name = selectedObjectName
    }
    const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/pso/logical-partition/pso/`

    return (
      <A10SlidingPage isOpen={!!isOpenForm} onRequestClose={this.handleAddOK}>
        {isOpenForm && (
          <AutoFormV15
            hccEnv={true}
            schemaPath={'cgn/fixed-nat/ip-lists'}
            apiPrefix={apiPrefix}
            params={param}
            viewMode={viewMode}
            interceptor={{
              onSubmitSuccess: this.handleAddOK,
              onCancel: this.handleClose,
            }}
          />
        )}
      </A10SlidingPage>
    )
  }

  render() {
    const { isLoading, deleteModalVisible, pageNumber, viewMode } = this.state
    const { total = 0, data = [] } = this.props
    const defaultColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        className: 'td-truncate',
        width: '50%',
        render: (text: any, record: any) => {
          return (
            <A10Tooltip
              placement="topLeft"
              title={record.name}
              overlayClassName="toolTipWidth"
            >
              {record.name || text}
            </A10Tooltip>
          )
        },
      },
      {
        title: 'Type',
        render: (record: any) => {
          if (record['ipv4-config']) {
            return 'IPv4'
          } else if (record['ipv6-config'] || record['ipv6-prefix-config']) {
            return 'IPv6'
          } else {
            return ''
          }
        },
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (record: any) => {
          return (
            <div className="text-right">
              <i>
                <A10DropdownMenu
                  title={null}
                  menu={
                    !viewMode
                      ? [
                          <div key="edit">Edit</div>,
                          <div key="delete">Delete</div>,
                          <div key="deploy">Deploy</div>,
                        ]
                      : [<div key="view">View</div>]
                  }
                  style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={this.onClickAction.bind(this, record)}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </i>
            </div>
          )
        },
      },
    ]
    return (
      <>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col>
            <ContentTitle title="IP List" count={total} />
          </A10Col>
          <A10Col style={{ display: 'flex' }}>
            <A10Input.Search
              onChange={this.handleSearch}
              type="text"
              name="searchBox"
              placeholder="Search"
              style={{ marginRight: '6px' }}
            />
            {!viewMode && (
              <ActionButton
                text="Add"
                onClick={this.handleAdd}
                iconProps={{ app: 'global', type: 'add-new' }}
              />
            )}
          </A10Col>
        </ContentHeader>
        <ContentBody ref={this.ref}>
          <A10Table
            columns={defaultColumns}
            dataSource={data}
            size="small"
            loading={
              isLoading && {
                indicator: <A10Loader container={this.ref} />,
              }
            }
            pagination={{
              hideOnSinglePage: true,
              pageSize,
              current: pageNumber,
              total,
              onChange: this.handlePageChange,
            }}
          />
        </ContentBody>
        {this.renderSlidingPage()}
        <A10Modal
          title="Confirmation"
          visible={deleteModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleDeleteCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleDeleteCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>Do you want to delete this Item?</p>
          <A10Checkbox
            checked={this.state.forceDelete}
            onChange={this.onCheckForceDelete}
          >
            Force Delete
          </A10Checkbox>
        </A10Modal>
      </>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    data: state.A10Data.getIn(getNS('IP_LIST_LIST'), []),
    total: state.A10Data.getIn(getNS('IP_LIST_COUNT'), 0),
  }
}

export default setupA10Container(deployWrapper(IPList), mapStateToProps)
