import React from 'react'
import { A10Icon } from '@gui-libraries/widgets'
import serverIcon from './images/server.svg'
import StatusBar from 'src/components/ADC/StatusBar'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

const height = '128px'

const getNameColor = (severity: string) => {
  switch (String(severity)) {
    case 'critical':
    case '0':
    case '1':
    case '2':
    case '3':
      return ['Critical', '#ff5b6e']
    case 'moderate':
    case '4':
      return ['Warning', '#fcb160']
    case 'low':
    case '5':
      return ['Lowwarn', '#ffe168']
    case 'info':
    case '6':
      return ['Info', '#38a8ee']
    case 'healthy':
    case '7':
      return ['Ongoing', '#5be4a9']
    default:
      return ['Unknow', '#ccc']
  }
}

export interface IAlertFlowProps {
  data: IObject
}
const iconStyle = { width: '32px', height: '32px', lineHeight: '32px' }

class AuditFlow extends React.Component<IAlertFlowProps> {
  renderPartition = () => {
    const { data } = this.props
    return (
      <div
        className={`${styles.flowBwrapper} ${styles.flowLeft}`}
        style={{ height, width: '170px' }}
      >
        <div style={{ textAlign: 'center', paddingBottom: '12px' }}>
          <img src={serverIcon} />
        </div>
        <div>
          Object Type
          <br />
          {data['cluster-name'] || data['device-name'] ? '' : 'All'}
          {data['cluster-name'] ? (
            <div
              title={data['cluster-name']}
              className={styles.ellipsisTextOverflowShort}
            >
              <StatusBar isRect={false} text="C" type="up" />
              {data['cluster-name']}
            </div>
          ) : null}
          {data['device-name'] ? (
            <div
              title={data['device-name']}
              className={styles.ellipsisTextOverflowShort}
            >
              <StatusBar isRect={false} text="D" type="up" />
              {data['device-name']}
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  renderCondition = () => {
    const { data } = this.props
    return (
      <div
        className={`${styles.flowLwrapper} ${styles.flowCenter} ${styles.flowAudit}`}
        style={{ height, width: '300px' }}
      >
        <A10Icon app="gi-firewall" type="service" style={iconStyle} />
        <div>
          <span style={{ fontWeight: 500 }}>Entitlement Key:</span>{' '}
          <span>{data['entitlement-key']}</span>
        </div>
        <div>
          <span style={{ fontWeight: 500 }}>Controller Activity:</span>{' '}
          <span>{data.action}</span>
        </div>
        <div className={styles.ellipsisTextOverflow}>
          <span title={data['action-log']}>{data['action-log']}</span>
        </div>
      </div>
    )
  }

  renderPriority = () => {
    const { data } = this.props
    const { severity } = data
    const style = getNameColor(severity)
    // style.length = 2
    return (
      <div className={styles.flowAbspan} style={{ backgroundColor: style[1] }}>
        {style[0]}
      </div>
    )
  }

  renderAction = () => {
    const { data } = this.props
    const isTrial = data['license-type'] === 'Trial'
    return (
      <div
        className={`${styles.flowBwrapper} ${styles.flowRight}`}
        style={{ height, width: isTrial ? '215px' : '175px' }}
      >
        <A10Icon
          app="harmony-controller"
          type="license-manager"
          style={iconStyle}
        />
        <div style={{ fontWeight: 500 }}>
          {isTrial ? 'Trial License - locally generated.' : 'License Manager'}
        </div>
        <div>{isTrial ? '' : 'glm.a10networks.com:443'}</div>
      </div>
    )
  }

  render() {
    return (
      <div className={styles.flow} style={{ height }}>
        <div className={styles.flowLine} style={{ top: '60px' }} />
        <div
          className={styles.flowLine}
          style={{ left: '590px', top: '60px' }}
        />
        <div
          className={styles.flowIcon}
          style={{ left: '688px', color: '#7ed321', top: '48px' }}
        >
          <A10Icon type="right" />
        </div>
        {/* <div
          className={styles.flowIcon}
          style={{ left: '280px', color: '#7ed321', top: '48px' }}
        >
          <A10Icon type="right" />
        </div> */}
        {this.renderPartition()}
        {this.renderCondition()}
        {this.renderAction()}
      </div>
    )
  }
}

export default AuditFlow
