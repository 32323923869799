import { getNS, IHTTPRequestOptions } from '@gui-libraries/framework'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'

const HPCAPI_PREFIX = '/hpcapi/v3'
const getProvider = () => storage.get.PROVIDER

export const getLPObject = (
  tenant: string,
  logicalPartition: string,
): IHTTPRequestOptions => {
  return {
    namespace: getNS('CURRENT_LOGICAL_PARTITION'),
    request: async (epicDependence: IObject) => {
      try {
        const provider = getProvider()
        const actionURL = `${HPCAPI_PREFIX}/provider/${provider}/tenant/${tenant}/logical-partition/${logicalPartition}`
        const { data: res } = await httpClient.get(actionURL)
        const lpObject = res['logical-partition']
        return lpObject
      } catch (err) {
        // tslint:disable-next-line:no-console
        console.error(err)
      }
      return {}
    },
  }
}
