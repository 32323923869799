import React from 'react'
import {
  A10Icon,
  A10Popover,
  A10Row,
  A10Col,
  A10Tooltip,
} from '@gui-libraries/widgets'
import { _ } from '@gui-libraries/framework'
import StatusBar from 'src/components/ADC/StatusBar'
import serverIcon from './images/server.svg'
import { getNameColor } from './status'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

const height = '152px'

export interface IAlertFlowProps {
  severity: string
  objectType: string
  objectInstance: IObject
  condition: string
  recordValue: number
  action: IObject
  hasEmail: boolean
  hasPost: boolean
  record: IObject
  clusterName?: string
  deviceName?: string
  partitionName?: string
}

class AlertFlow extends React.Component<IAlertFlowProps> {
  renderPartition = () => {
    const { record, clusterName, deviceName, partitionName } = this.props
    return (
      <div
        className={`${styles.flowBwrapper} ${styles.flowLeft}`}
        style={{ height, width: '170px', paddingTop: '30px' }}
      >
        <img src={serverIcon} />
        <br />
        <div style={{ textAlign: 'left' }}>
          <div className={styles.ellipsisTextOverflowShort}>
            {clusterName || deviceName || partitionName ? '' : 'All'}
            {clusterName ? (
              <span title={clusterName}>
                <StatusBar isRect={false} text="C" type="up" />
                {clusterName}
              </span>
            ) : null}
            <br />
            {deviceName ? (
              <span title={deviceName}>
                <StatusBar isRect={false} text="D" type="up" />
                {deviceName}
              </span>
            ) : null}
            <br />
            {partitionName ? (
              <span title={partitionName}>
                <StatusBar isRect={false} text="P" type="up" />
                {partitionName}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  renderCondition = () => {
    const { recordValue, record = {} } = this.props
    const { condition_text } = record
    const text = record.measured_text
    let newText =
      _.isString(text) && _.endsWith(_.trim(text), ',')
        ? text.substring(0, text.length - 1)
        : text
    const displayUnit = _.isArray(record?.metadata?.Display_unit)
      ? record?.metadata?.Display_unit?.[0]
      : record?.metadata?.Display_unit
    const unit =
      displayUnit === 'percentage'
        ? '%'
        : displayUnit === 'ratio'
        ? ''
        : displayUnit

    const splitNewTextArray = newText.split('=')
    if (splitNewTextArray.length > 1) {
      const tmpText = parseFloat(parseFloat(splitNewTextArray.pop()).toFixed(2))
      splitNewTextArray.push(tmpText)
      newText = splitNewTextArray.join('=')
    }
    return (
      <div
        className={`${styles.flowLwrapper} ${styles.flowCenter}`}
        style={{ height, width: '300px' }}
      >
        <div
          className={styles.flowBwrapper}
          style={{ display: 'block', marginBottom: '18px' }}
        >
          Condition
          <A10Tooltip title={condition_text}>
            <div className={styles.flowText}>{condition_text}</div>
          </A10Tooltip>
        </div>
        <div className={styles.flowBwrapper} style={{ display: 'block' }}>
          Recorded Value
          <br />
          {/* <span style={{ color: '#ff2e48' }}>{`${recordValue || 0} %`}</span> */}
          <A10Tooltip title={newText}>
            <div
              className={styles.ellipsisTextOverflow}
            >{`${newText}${unit}`}</div>
          </A10Tooltip>
        </div>
      </div>
    )
  }

  renderPriority = () => {
    const { severity } = this.props
    const style = getNameColor(severity)
    // style.length = 2
    return (
      <div className={styles.flowAbspan} style={{ backgroundColor: style[1] }}>
        {style[0]}
      </div>
    )
  }

  renderAction = () => {
    const { action } = this.props
    const { definition = {} } = action
    const { email: emails = [], webhook: webhooks = [] } = definition
    const iconStyle = { width: '32px', height: '32px', lineHeight: '32px' }
    const email = emails.length > 0 ? emails[0] : undefined
    const webhook = webhooks.length > 0 ? webhooks[0] : undefined
    return (
      <div
        className={`${styles.flowBwrapper} ${styles.flowRight}`}
        style={{ height, width: '170px', paddingTop: '30px' }}
      >
        Action Taken <br />
        {email ? (
          <A10Popover
            placement="top"
            content={
              <div style={{ width: 320 }}>
                <A10Row>
                  <A10Col span={8} style={{ fontWeight: 500 }}>
                    Email
                  </A10Col>
                  <A10Col span={16} style={{ fontWeight: 300 }}>
                    {(email.email_ids || []).join('; ')}
                  </A10Col>
                </A10Row>
                <A10Row>
                  <A10Col span={8} style={{ fontWeight: 500 }}>
                    Message
                  </A10Col>
                  <A10Col span={16} style={{ fontWeight: 300 }}>
                    {email.extended_message}
                  </A10Col>
                </A10Row>
                <A10Row>
                  <A10Col span={8} style={{ fontWeight: 500 }}>
                    Subject
                  </A10Col>
                  <A10Col span={16} style={{ fontWeight: 300 }}>
                    {email.extended_subject}
                  </A10Col>
                </A10Row>
              </div>
            }
          >
            <div className={styles.flowActionIcon}>
              <A10Icon app="global" type="email" style={iconStyle} />
              <br />
              Email
            </div>
          </A10Popover>
        ) : null}
        {webhook ? (
          <A10Popover
            placement="top"
            content={
              <div style={{ width: 320 }}>
                <A10Row>
                  <A10Col span={8} style={{ fontWeight: 500 }}>
                    URI
                  </A10Col>
                  <A10Col span={16} style={{ fontWeight: 300 }}>
                    {webhook.uri}
                  </A10Col>
                </A10Row>
                <A10Row>
                  <A10Col span={8} style={{ fontWeight: 500 }}>
                    Message
                  </A10Col>
                  <A10Col span={16} style={{ fontWeight: 300 }}>
                    {webhook.extended_body}
                  </A10Col>
                </A10Row>
              </div>
            }
          >
            <div className={styles.flowActionIcon}>
              <A10Icon app="global" type="post" style={iconStyle} />
              <br />
              Post
            </div>
          </A10Popover>
        ) : null}
      </div>
    )
  }

  render() {
    return (
      <div className={styles.flow} style={{ height }}>
        <div className={styles.flowLine} />
        <div className={styles.flowLine} style={{ left: '590px' }} />
        <div
          className={styles.flowIcon}
          style={{ left: '688px', color: '#7ed321' }}
        >
          <A10Icon type="right" />
        </div>
        {this.renderPartition()}
        {this.renderCondition()}
        {this.renderAction()}
        {this.renderPriority()}
        <div className={styles.flowIcon} style={{ left: '427px' }}>
          <A10Icon
            app="global"
            type="dashed-line"
            style={{ width: '16px', height: '16px', lineHeight: '16px' }}
          />
        </div>
      </div>
    )
  }
}

export default AlertFlow
