import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import { A10Loader } from '@gui-libraries/widgets'

import './styles/index.less'

interface IProps {
  service: IObject
  startTime: number
  endTime: number
  device: IObject
  height: number
  parameters: IObject
}

const getCPUColor = value => {
  if (value > 20) {
    return '#cc311d'
  } else if (value > 10) {
    return '#ff992b'
  } else {
    return '#99d97e'
  }
}

const DeviceCPUs: React.FC<IProps> = props => {
  const refContainer = useRef()
  const { height, service, startTime, endTime, device, parameters } = props
  const { api } = parameters
  const [isLoading, setIsLoading] = useState(true)
  const [formattedData, setFormattedData] = useState({ series: [], total: 0 })
  const deviceUUID = device['device-uuid']
  const payload = useMemo(
    () => ({
      logs: {
        rangeby: {
          start: startTime,
          end: endTime,
          field: 'ts',
        },
        filterby: {
          and: {
            device_uuid: deviceUUID,
          },
        },
        sort: 'desc',
        size: 1,
      },
    }),
    [startTime, endTime, deviceUUID],
  )

  const init = useCallback(async () => {
    setIsLoading(true)

    try {
      const url = `${api}/analytics/thunder-adc/system_telemetry_log_device_status`
      const logRes = await service.sendRequest(
        url,
        'POST',
        service.RPTHeaders,
        payload,
        null,
      )
      const rawData = logRes?.data?.logs || {}
      const data = Object.values(rawData)[0]
      const types = Object.keys(data?.['cpu_usage_overall'] || {})
      const fields = types.map(type => `cpu_usage_overall.${type}`)
      const cpuDataPayload = {
        cpuData: {
          rangeby: {
            start: startTime,
            end: endTime,
            field: 'ts',
          },
          fields,
          filterby: {
            and: {
              device_uuid: deviceUUID,
            },
          },
          sort: 'desc',
          aggregation: 'avg',
        },
      }

      const cpuDataRes = await service.sendRequest(
        url,
        'POST',
        service.RPTHeaders,
        cpuDataPayload,
        null,
      )
      formatData(cpuDataRes?.data?.cpuData || {})
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [startTime, endTime, api, payload, deviceUUID, service])

  const formatData = data => {
    let total = 0
    const series = Object.entries(data).map(([key, value]) => {
      const name = key
        .replace('cpu_usage_overall', '')
        .replace('_avg', '')
        .toUpperCase()
      total++

      return {
        name,
        value: value,
        color: '',
      }
    })

    const treeData = {
      series,
      total,
    }

    setFormattedData(treeData)
  }

  useEffect(() => {
    init()
  }, [init])

  const controlCPUs = formattedData.series.filter((cpu: any) => {
    return cpu.name.toLowerCase().indexOf('control') > -1
  })
  const dataCPUs = formattedData.series.filter((cpu: any) => {
    return cpu.name.toLowerCase().indexOf('data') > -1
  })
  const IOCPUs = formattedData.series.filter((cpu: any) => {
    return cpu.name.toLowerCase().indexOf('i/o') > -1
  })

  return (
    <div
      ref={refContainer}
      className="device-cpu"
      style={{
        height,
      }}
    >
      {isLoading && <A10Loader container={refContainer} />}
      {!isLoading && (
        <>
          {controlCPUs.map((cpu: IObject) => {
            return (
              <div
                style={{ backgroundColor: getCPUColor(cpu.value) }}
                className={'cpu-container'}
              >
                <div className={'cpu-name'}> {cpu.name.replace('.', '')}</div>
                <div className={'cpu-value'}> {cpu.value.toFixed(2)} %</div>
              </div>
            )
          })}
          <hr />
          {dataCPUs.map((cpu: IObject) => {
            return (
              <div
                style={{ backgroundColor: getCPUColor(cpu.value) }}
                className={'cpu-container'}
              >
                <div className={'cpu-name'}> {cpu.name.replace('.', '')}</div>
                <div className={'cpu-value'}> {cpu.value.toFixed(2)} %</div>
              </div>
            )
          })}
          <hr />
          {IOCPUs.map((cpu: IObject) => {
            return (
              <div
                style={{ backgroundColor: getCPUColor(cpu.value) }}
                className={'cpu-container'}
              >
                <div className={'cpu-name'}> {cpu.name.replace('.', '')}</div>
                <div className={'cpu-value'}> {cpu.value.toFixed(2)} %</div>
              </div>
            )
          })}
        </>
      )}
    </div>
    // <TreeMapChart
    //   name=""
    //   data={this.state.deviceCpus[0]}
    //   unit={' %, Overall'}
    //   onClick={() => { }}
    // />
  )
}

export default DeviceCPUs
