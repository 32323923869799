import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'

import propTypes from 'prop-types'
import { AutoFormV15 } from '@gui-libraries/apps'
import GeneralObjectBrowser from '../GeneralObjectBrowser'
import { httpClient } from 'src/libraries/httpClient'
import { getLPObject } from 'src/redux/httpServices/ADC/common/current_logical_partition'
// const styles = require('./styles/index.module.less')

export interface ISharedFormsProps extends IA10ContainerDefaultProps {
  data: any[]
  tenant: string
  logicalPartition: string
  node: any
  isNew?: boolean
  defaultParams?: IObject
  previousPage: string
  getData: (search: string, start: number, count: number) => void
  start?: number
  count?: number
  search?: string
}

export interface ISharedFormsState {
  showStats: boolean
  showSlidingPage: boolean
  objectType: string
  objects: any[]
}

class SharedForms extends A10Container<ISharedFormsProps, ISharedFormsState> {
  static contextTypes = {
    openSlidingPage: propTypes.func.isRequired,
  }

  constructor(props: ISharedFormsProps) {
    super(props)
    this.state = {
      showStats: false,
      showSlidingPage: false,
      objectType: 'service',
      objects: [],
    }
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({ showSlidingPage: isOpen })
  }

  closeSlidingPage = () => {
    this.context.openSlidingPage(null)
  }
  onFormSubmitted = async (response: any) => {
    let { node } = this.props
    node = _.cloneDeep(node)
    const templateKey = node.key
    const objectType = node.association
    if (node.needBinding && node.objectPassed) {
      const oldPayload = node.objectPassed.payload
      const actionURL = node.objectPassed.actionURL
      const objectName = response[0].data[templateKey].name
      let objTemplateKey = ''
      if (
        templateKey !== 'cookie' &&
        templateKey !== 'source-ip' &&
        templateKey !== 'destination-ip' &&
        templateKey !== 'ssl-sid'
      ) {
        objTemplateKey = 'template-' + templateKey
      } else {
        objTemplateKey = 'template-persist-' + templateKey
      }
      oldPayload[objTemplateKey] = objectName
      delete oldPayload.key
      delete oldPayload.vip
      delete oldPayload.svcName

      try {
        const newPayload: IObject = {}
        newPayload[objectType] = oldPayload
        await httpClient.post(actionURL, newPayload)
      } catch (err) {
        // console.log(err)
      }
    }
    this.context.openSlidingPage(null)
  }

  onSubmitSuccess = (sform: any, response: any) => {
    const {
      getData,
      start = 0,
      count = 10,
      search = '',
      tenant,
      logicalPartition,
      A10Dispatchers: { httpRequest },
    } = this.props
    this.onFormSubmitted(response)
    getData(search, start, count)
    httpRequest(getLPObject(tenant, logicalPartition))
  }

  onCancel = () => {
    this.context.openSlidingPage(null)
  }

  onBack = () => {
    const {
      isNew = false,
      tenant,
      logicalPartition,
      previousPage = '',
      getData,
      start = 0,
      count = 10,
      search = '',
    } = this.props
    this.context.openSlidingPage(
      <GeneralObjectBrowser
        schemaPath={previousPage}
        tenant={tenant}
        logicalPartition={logicalPartition}
        isNew={isNew}
        getData={getData}
        start={start}
        count={count}
        search={search}
      />,
    )
  }

  renderSharedForm = () => {
    const { defaultParams, isNew = false, node } = this.props
    const schemaPath = node.uiJson
    return (
      <AutoFormV15
        showBackLink={isNew}
        schemaPath={schemaPath}
        apiPrefix={node.apiURL}
        params={defaultParams}
        hccEnv={true}
        interceptor={{
          onSubmitSuccess: this.onSubmitSuccess,
          onCancel: this.onCancel,
          onBack: this.onBack,
        }}
      />
    )
  }

  render() {
    return (
      <>
        <div className="slide-pane__content">{this.renderSharedForm()}</div>
      </>
    )
  }
}

export default setupA10Container(SharedForms)
