import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Row,
  A10Col,
  A10Table,
  A10DropdownMenu,
  A10Radio,
  A10Input,
  A10Button,
  A10Icon,
} from '@gui-libraries/widgets'

import { Messages } from 'src/locale/en/'
import { LMLicenseCharts } from './LMLicenseCharts'
import { LMLicenseUsageCharts } from './LMLicenseUsageCharts'
import { LMLicenseActivationDetails } from './LMLicenseActivationDetails'
import SlidingLMActivationForm from '../LMActivationForm/SlidingLMActivationForm'
import ReactLoading from 'react-loading'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { DashboardService, Utilities } from 'src/services/index'
import { RoundNumber } from 'src/components/shared/RoundNumber'
const styles = require('./styles/index.module.less')
const moment = require('moment')

export interface ILMLicenseDetailsProps extends IA10ContainerDefaultProps {
  isUpdateActivations: boolean
  licenseData: IObject
  refreshData: any
}
export interface ILMLicenseDetailsState {
  isLoading: boolean
  showSlidingPage: boolean
  showActivationSlidingPage: boolean
  selectedActivation: any
  slidingForm: string
  activationList: any
  loadingIcon: boolean
  tabValue: string
  licenseUsage: any
}

class LMLicenseDetails extends A10Container<
  ILMLicenseDetailsProps,
  ILMLicenseDetailsState
> {
  Messages = new Messages()
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  activationColumns: any[]
  updateActivationInfo: boolean
  revokeLicAuditRef: any = null

  constructor(props: ILMLicenseDetailsProps) {
    super(props)
    this.state = {
      isLoading: false,
      showSlidingPage: false,
      showActivationSlidingPage: false,
      selectedActivation: { id: '' },
      slidingForm: '',
      activationList: [],
      loadingIcon: false,
      tabValue: 'licInfo',
      licenseUsage: [],
    }

    this.activationColumns = [
      {
        title: <></>,
        dataIndex: 'state',
        key: 'state',
        render: (text: string, record: any, index: number) => {
          const status = record['state']
          const statusObj = this.Utilities.getVal(
            status,
            'LICENSE',
            'activation-status',
          )
          return (
            <div className="icons-wrap">
              <HealthStatus type={statusObj.type} tooltip={statusObj.label} />
            </div>
          )
        },
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'activation-status'),
      },
      {
        title: (
          <>
            {this.props.licenseData['license-type'].indexOf('FLEXPOOL') > -1
              ? 'Device'
              : 'Provider'}
          </>
        ),
        dataIndex: 'appliance_name',
        key: 'appliance_name',
        className: 'td-truncate',
        render: (text: string, record: any, index: number) => {
          return (
            <span className="td-truncate" title={text}>
              {record['appliance_name'] || record['mac']}
            </span>
          )
        },
        sorter: (a: any, b: any) =>
          this.Utilities.sortStringMulKeys(a, b, 'appliance_name', 'mac'),
      },
      {
        title: <>UUID</>,
        dataIndex: 'appliance_uuid',
        key: 'appliance_uuid',
        className: 'td-truncate',
        render: (text: string, record: any, index: number) => {
          return (
            <span className="td-truncate" title={text}>
              {text}
            </span>
          )
        },
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'appliance_uuid'),
      },
      {
        title: <>Bandwidth</>,
        dataIndex: 'allocated_bandwidth',
        key: 'allocated_bandwidth',
        className: 'td-truncate',
        render: (text: string, record: any, index: number) => {
          const allotedBW =
            this.props.licenseData['license-type'].indexOf(
              'INSTANCE_FLEXPOOL',
            ) > -1
              ? record['bandwidth']
              : record['allocated_bandwidth']
          const allotedBandwidth = this.Utilities.megaToGiga(
            allotedBW ? Math.abs(allotedBW) : 0,
          )
          const allotedValue =
            (allotedBW < 0 ? '-' : '') +
            (allotedBandwidth.val +
              (allotedBandwidth.unit === 'm' ? 'Mbps' : 'Gbps'))
          return (
            <span className="td-truncate" title={allotedValue}>
              {allotedValue}
            </span>
          )
        },
        sorter: (a: any, b: any) =>
          this.Utilities.sortStringKeys(
            a,
            b,
            'allocated_bandwidth',
            'bandwidth',
          ),
      },
      {
        title: <>Activated On</>,
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: (a: any, b: any) => this.Utilities.sortDate(a, b, 'created_at'),
        render: (text: string, record: any, index: number) => {
          const fromDateFormat = moment
            .utc(record['created_at'])
            .local()
            .format('MMM DD, YYYY hh:mm A')
          return <>{fromDateFormat}</>
        },
      },
      {
        title: <>Last Synced On</>,
        dataIndex: 'last_contacted_at',
        key: 'last_contacted_at',
        sorter: (a: any, b: any) =>
          this.Utilities.sortDate(a, b, 'last_contacted_at'),
        render: (text: string, record: any, index: number) => {
          const fromDateFormat = moment
            .utc(record['last_contacted_at'])
            .local()
            .format('MMM DD, YYYY hh:mm A')
          return <>{fromDateFormat}</>
        },
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (record: any) => {
          const clickAction = (component: JSX.Element, index: number) => {
            if (component.key === 'revoke' && record['state'] !== 'REVOKED') {
              this.setState({
                showActivationSlidingPage: true,
                selectedActivation: record,
                slidingForm: 'Revoke',
              })
            } else if (
              component.key === 'unrevoke' &&
              record['state'] === 'REVOKED'
            ) {
              this.setState({
                showActivationSlidingPage: true,
                selectedActivation: record,
                slidingForm: 'Unrevoke',
              })
            }
          }
          const menu = []
          if (record['state'] !== 'REVOKED') {
            menu.push(<div key="revoke">Revoke </div>)
          } else {
            menu.push(<div key="unrevoke">Unrevoke </div>)
          }
          return (
            <div className="text-right">
              <i>
                <A10DropdownMenu
                  menu={menu}
                  title=""
                  style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={clickAction}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </i>
            </div>
          )
        },
      },
    ]
  }

  componentDidMount() {
    this.getLicenseActivations()
    this.getLicenseUsage()
    if (this.props.licenseData['license-type'].indexOf('FLEXPOOL') === -1) {
      this.activationColumns.splice(2, 1)
    }
  }

  shouldComponentUpdate = (nextProps: any, nextState: any) => {
    if (nextProps.isUpdateActivations !== this.props.isUpdateActivations) {
      this.getLicenseActivations()
      this.getLicenseUsage()
    }
    return true
  }

  closeSlidingPage = () => {
    this.setState({ showActivationSlidingPage: false })
  }

  getLicenseActivations = async () => {
    this.setState({ loadingIcon: true })
    const activationList: any = []
    const getLicenseActivations = this.DashboardService.getLLMLicenseActivations(
      null,
      null,
      [this.props.licenseData['license-id']],
    )
    await getLicenseActivations
      .then((resp: any) => {
        resp?.data &&
          resp.data.map((activation: any) => {
            activation.state = 'ACTIVATED'
            activationList.push(activation)
          })
      })
      .catch((err: any) => {
        console.log('error in getting license activations')
      })

    const getLicenseRevokedActivations = this.DashboardService.getLLMLicenseRevokedActivations(
      null,
      null,
      [this.props.licenseData['license-id']],
    )
    await getLicenseRevokedActivations
      .then((resp: any) => {
        resp?.data &&
          resp.data.map((activation: any) => {
            activation.state = 'REVOKED'
            activationList.push(activation)
          })
      })
      .catch((err: any) => {
        console.log('error in getting license revoked activations')
      })

    this.setState({ loadingIcon: false, activationList })
  }

  getLicenseUsage = () => {
    this.setState({ loadingIcon: true })
    const getLicenseUsage = this.DashboardService.getLLMLicenseUsage(
      null,
      null,
      [this.props.licenseData['license-id']],
    )
    getLicenseUsage
      .then((resp: any) => {
        const licenseUsage = resp?.data || []
        this.setState({ licenseUsage })
      })
      .catch((err: any) => {
        console.log('error in getting license usage')
      })
  }

  tabChange = (event: any) => {
    let { tabValue } = this.state
    if (event.target) {
      if (event.target.type === 'checkbox') {
        tabValue = event.target.checked
      } else {
        tabValue = event.target.value
      }
    } else {
      tabValue = event
    }

    this.setState({ tabValue })
  }

  getCheckInFreqText = (checkinFreq: number) => {
    if (checkinFreq > 60 * 60) {
      const hours = checkinFreq / (60 * 60),
        minutes = checkinFreq % (60 * 60)
      return (
        'Every ' +
        hours +
        ' hour(s) ' +
        (minutes > 0 ? minutes + ' minute(s)' : '')
      )
    } else if (checkinFreq > 60) {
      const minutes = checkinFreq / 60,
        seconds = checkinFreq % 60
      return (
        'Every ' +
        minutes +
        ' minute(s) ' +
        (seconds > 0 ? seconds + ' second(s)' : '')
      )
    } else {
      return 'Every ' + checkinFreq + ' second(s)'
    }
  }

  renderLicInfo = () => {
    const { licenseData } = this.props
    const checkinFreq = this.getCheckInFreqText(
      licenseData['check-in-frequency'],
    )

    const bandwidth = this.Utilities.megaToGiga(
      licenseData['alloted-bandwidth'] || 0,
    )
    const remBandwidth = this.Utilities.megaToGiga(
      licenseData['remaining-bandwidth'] || 0,
    )
    const defaultBW = this.Utilities.megaToGiga(
      licenseData && licenseData['default-allocated-bandwidth']
        ? licenseData['default-allocated-bandwidth']
        : 0,
    )
    const minBW = this.Utilities.megaToGiga(
      licenseData && licenseData['min-allocated-bandwidth']
        ? licenseData['min-allocated-bandwidth']
        : 0,
    )
    const maxBW = this.Utilities.megaToGiga(
      licenseData && licenseData['max-allocated-bandwidth']
        ? licenseData['max-allocated-bandwidth']
        : 0,
    )
    const status = licenseData['activation-status']
    const statusObj = this.Utilities.getVal(
      status,
      'LICENSE',
      'activation-status',
    )
    const dateFormat = moment
      .utc(licenseData['expires-at'])
      .format('MMM DD, YYYY hh:mm A')
    const entitlements = licenseData?.entitlements
    const deviceManagement = entitlements?.['device-management']
    const totalLogRate = this.Utilities.getEntitlementsValue(deviceManagement?.['session-log']?.['total-log-rate'])
    const services = entitlements?.services
    const userInformation = entitlements?.user

    return (
      <A10Row type="flex" className="no-margin">
        <A10Col span={12} className={styles.licDetailDiv}>
          <div className={`row ${styles.licenseDetail} no-margin`}>
            <div className="col-md-5 title lic-info">State</div>
            <div
              className={
                licenseData['activation-status'] === 'EXPIRING'
                  ? styles.licDetailValue + ' col-md-7 pad-s-15 license-info warning'
                  : licenseData['activation-status'] === 'EXPIRED' ||
                    licenseData['activation-status'] === 'GRACE-PERIOD' ||
                    licenseData['activation-status'] === 'REVOKED'
                    ? styles.licDetailValue + ' col-md-7 pad-s-15 license-info danger'
                    : styles.licDetailValue + ' col-md-7 pad-s-15 license-info'
              }
            >
              <div>
                <HealthStatus
                  type={statusObj.type}
                  tooltip={statusObj.label}
                  className={styles.statusIconLabel}
                />
                {licenseData['activation-status']}
              </div>
            </div>
          </div>
          {licenseData['activation-status'] === 'REVOKED' &&
            !!licenseData.notes ? (
            <div className={`row ${styles.licenseDetail} no-margin`}>
              <div className="col-md-5 title lic-info">Revoked License Audit</div>
              <div
                className={`${styles.licDetailValue} col-md-7 pad-s-15 license-info`}
                title={licenseData.notes}
              >
                <div className="row">
                  <div className="col-md-8">
                    <A10Input
                      type="text"
                      placeholder="Revoked License Audit"
                      value={licenseData.notes}
                      ref={(copyText: any): any =>
                        (this.revokeLicAuditRef = copyText)
                      }
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-3 no-padding">
                    {/* Logical shortcut for only displaying the button if the copy command exists */
                      document.queryCommandSupported('copy') && (
                        <A10Button
                          className="action-button"
                          onClick={() => {
                            navigator.clipboard.writeText(licenseData.notes)
                          }}
                        >
                          <A10Icon
                            app="global"
                            type="duplicate"
                            className="action-icon"
                          />
                          Copy
                        </A10Button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className={`row ${styles.licenseDetail} no-margin`}>
            <div className="col-md-5 title lic-info">{this.Messages.ENTITLEMENT_TOKEN}</div>
            <div className={`${styles.licDetailValue} col-md-7 pad-s-15 license-info`}>
              {licenseData['entitlement-key']}
            </div>
          </div>
          <div className={`row ${styles.licenseDetail} no-margin`}>
            <div className="col-md-5 title lic-info">Name</div>
            <div className={`${styles.licDetailValue} col-md-7 pad-s-15 license-info`}>
              {licenseData['license-name']}
            </div>
          </div>
          {licenseData['license-platform'] ? (
                <div className={`row ${styles.licenseDetail} no-margin`}>
                  <div className="col-md-5 title lic-info">Platform</div>
                  <div className={`${styles.licDetailValue} col-md-7 pad-s-15 license-info`}>
                    {licenseData['license-platform']}
                  </div>
                </div>
              ) : null}
              {/* {licenseData['license-product'] ? (
                <div className={`row ${styles.licDetailRow} pad-v-10`}>
                  <div className="col-md-5 title">Product</div>
                  <div className={`${styles.licDetailValue} col-md-7 pad-s-15`}>
                    {licenseData['license-product']}
                  </div>
                </div>
              ) : null} */}
          <div className={`row ${styles.licenseDetail} no-margin`}>
            <div className="col-md-5 title lic-info">Product</div>
            <div
              className={`${styles.licDetailValue} col-md-7 pad-s-15 license-info`}
              title={licenseData.description}
            >
              {licenseData.description}
            </div>
          </div>
          <div className={`row ${styles.licenseDetail} no-margin`}>
            <div className="col-md-5 title lic-info">Check-in-Frequency</div>
            <div className={`${styles.licDetailValue} col-md-7 pad-s-15 license-info`}>
              {checkinFreq}
            </div>
          </div>
          <div className={`row ${styles.licenseDetail} no-margin`}>
            <div className="col-md-5 title lic-info">

            {licenseData['activation-status'] === 'ACTIVATED' ||
                  licenseData['activation-status'] === 'EXPIRING' ||
                  licenseData['activation-status'] === 'EXPIRED' ||
                  licenseData['activation-status'] === 'GRACE-PERIOD' ||
                  licenseData['activation-status'] === 'REVOKED'
                    ? 'Start'
                    : 'Requested'}{' '}
                  Date

            </div>
            <div className={`${styles.licDetailValue} col-md-7 pad-s-15 license-info`}>
              {licenseData['created-at']}
            </div>
          </div>
          {licenseData['activation-status'] === 'ACTIVATED' ||
            licenseData['activation-status'] === 'EXPIRING' ||
            licenseData['activation-status'] === 'EXPIRED' ||
            licenseData['activation-status'] === 'GRACE-PERIOD' ||
            licenseData['activation-status'] === 'REVOKED' ? (
            <>
              <div className={`row ${styles.licenseDetail} no-margin`}>
                <div className="col-md-5 title lic-info">
                  {licenseData['activation-status'] === 'EXPIRED'
                    ? 'Expired'
                    : 'Expires'}
                </div>
                <div
                  className={
                    licenseData['activation-status'] === 'EXPIRING'
                      ? styles.licDetailValue + ' col-md-7 pad-s-15 license-info warning'
                      : licenseData['activation-status'] === 'GRACE-PERIOD' ||
                        licenseData['activation-status'] === 'EXPIRED'
                        ? styles.licDetailValue + ' col-md-7 pad-s-15 license-info danger'
                        : styles.licDetailValue + ' col-md-7 pad-s-15 license-info'
                  }
                >
                  {licenseData['expires-at'] &&
                    licenseData['expires-at'] === '2050-12-31 00:00:00'
                    ? 'Never'
                    : dateFormat}
                </div>
              </div>
            </>
          ) : null}

          {licenseData['license-type'] === 'MBU' ||
            licenseData['license-type'] === 'MDU' ||
            licenseData['license-type'] === 'Trial' ? (
            <>
              <div className={`row ${styles.licenseDetail} pad-v-8 no-margin`}>
                <div className="col-md-5 title lic-info">Managed Bandwidth</div>
                <div className={`${styles.licDetailValue} col-md-7 pad-s-15 license-info`}>
                  {licenseData['alloted-bandwidth'] === 0
                    ? 'Unlimited'
                    : bandwidth.val +
                    (bandwidth.unit === 'm' ? ' Mbps' : ' Gbps')}
                </div>
              </div>
              <div className={`row ${styles.licenseDetail} pad-v-8 no-margin`}>
                <div className="col-md-5 title lic-info">Managed Devices</div>
                <div className={`${styles.licDetailValue} col-md-7 pad-s-15 license-info`}>
                  {licenseData['alloted-devices'] === 0
                    ? 'Unlimited'
                    : licenseData['alloted-devices']}
                </div>
              </div>
            </>
          ) : null}

          {licenseData['license-type'].indexOf('FLEXPOOL') > -1 ? (
            <>
              <div className={`row ${styles.licenseDetail} pad-v-8 no-margin`}>
                <div className="col-md-5 title lic-info">Capacity</div>
                <div className={`${styles.licDetailValue} col-md-7 pad-s-15 license-info`}> 
                  <span>
                      Bandwidth (Alloted / Remaining) :{' '}
                        {(bandwidth.val == 0) ?'na' :(bandwidth.val +
                          (bandwidth.unit === 'm' ? ' Mbps' : ' Gbps'))}{' / '}
                        {(remBandwidth.val == 0) ?'na' : remBandwidth.val +
                          (remBandwidth.unit === 'm' ? ' Mbps' : ' Gbps')}
                        &nbsp;&nbsp;
                      </span>
                      <br />
                    <span>
                      Devices (Alloted / Used) :{' '}
                        {(licenseData['alloted-devices'] == 0 ? licenseData['license-type'] === "SUBSCRIBER_FLEXPOOL"?'Unlimited': 'na' : licenseData['alloted-devices'])}{' / '}
                        {(licenseData['used-devices'] == 0 ? 'na' : licenseData['used-devices'])}{' '}
                      </span>
                        <br />
                </div>
              </div>
              <div className={`row ${styles.licenseDetail} pad-v-8 no-margin`}>
                <div className="col-md-5 title lic-info">Bandwidth Allocation</div>
                <div className={`${styles.licDetailValue} col-md-7 pad-s-15 license-info`}>
                  <span>
                    Default: {defaultBW.val}{' '}
                    {defaultBW.unit === 'm' ? 'Mbps' : 'Gbps'} &nbsp;&nbsp;
                  </span>
                  <span>
                    Min: {minBW.val} {minBW.unit === 'm' ? 'Mbps' : 'Gbps'}{' '}
                    &nbsp;&nbsp;
                  </span>
                  <span>
                    Max: {maxBW.val} {maxBW.unit === 'm' ? 'Mbps' : 'Gbps'}
                  </span>
                </div>
              </div>

            </>
          ) : null}
          {entitlements && (
            <>
              <A10Row className={`${styles.entitlementHeading} license-detail pad-v-10 title`}>
                ENTITLEMENTS:
              </A10Row>
              <A10Row className={`${styles.licenseDetail} pad-v-8 no-margin`}>
                <A10Col span={5} className='col-md-5 title lic-info'>Plan</A10Col>
                <A10Col span={7} className={`${styles.licDetailValue} pad-s-15 license-info`}>
                  {entitlements?.metadata?.plan}
                </A10Col>
              </A10Row>
              <A10Row className={`${styles.licenseDetail} no-margin`}>
                <A10Col span={5} className='col-md-5 title lic-info'>No of Users</A10Col>
                <A10Col span={7} className={`${styles.licDetailValue} pad-s-15 license-info`}>
                  {this.Utilities.getEntitlementsValue(userInformation?.count)}
                </A10Col>
              </A10Row>
              <A10Row className={`${styles.licenseDetail} no-margin`}>
                <A10Col span={5} className='col-md-5 title lic-info'>SSO</A10Col>
                <A10Col span={7} className={`${styles.licDetailValue} pad-s-15 license-info`}>
                  {`${userInformation?.SSO}`}
                </A10Col>
              </A10Row>
              <A10Row className={`${styles.licenseDetail} no-margin`}>
                <A10Col span={5} className='col-md-5 title lic-info'>No of Device Backups</A10Col>
                <A10Col span={7} className={`${styles.licDetailValue} pad-s-15 license-info`}>
                  {this.Utilities.getEntitlementsValue(deviceManagement?.backup?.number)}
                </A10Col>
              </A10Row>
              <A10Row className={`${styles.licenseDetail} no-margin`}>
                <A10Col span={5} className='col-md-5 title lic-info'>Periodic Backups</A10Col>
                <A10Col span={7} className={`${styles.licDetailValue} pad-s-15 license-info`}>
                  {deviceManagement?.backup?.periodic
                    ? 'Allowed'
                    : 'Not Allowed'}
                </A10Col>
              </A10Row>
              <A10Row className={`${styles.licenseDetail} no-margin`}>
                <A10Col span={5} className='col-md-5 title lic-info'>No of CLI Snippets</A10Col>
                <A10Col span={7} className={`${styles.licDetailValue} pad-s-15 license-info`}>
                  {deviceManagement?.['cli-snippets']?.number}
                </A10Col>
              </A10Row>
              <A10Row className={`${styles.licenseDetail} no-margin`}>
                <A10Col span={5} className='col-md-5 title lic-info'>Periodic Snippets</A10Col>
                <A10Col span={7} className={`${styles.licDetailValue} pad-s-15 license-info`}>
                  {deviceManagement?.['cli-snippets']?.periodic
                    ? 'Allowed'
                    : 'Not Allowed'}
                </A10Col>
              </A10Row>
              <A10Row className={`${styles.licenseDetail} no-margin`}>
                <A10Col span={5} className='col-md-5 title lic-info'>Total Log rate</A10Col>
                <A10Col span={7} className={`${styles.licDetailValue} pad-s-15 license-info`}>
                  {Number(totalLogRate) > 0 ? `${totalLogRate}/Sec` : totalLogRate}
                </A10Col>
              </A10Row>
              <A10Row className={`${styles.licenseDetail} no-margin`}>
                <A10Col span={5} className='col-md-5 title lic-info'>No of Alerts</A10Col>
                <A10Col span={7} className={`${styles.licDetailValue} pad-s-15 license-info`}>
                  {this.Utilities.getEntitlementsValue(deviceManagement?.alerts)}
                </A10Col>
              </A10Row>
              <A10Row className={`${styles.licenseDetail} no-margin`}>
                <A10Col span={5} className='col-md-5 title lic-info'>ADC</A10Col>
                <A10Col span={7} className={`${styles.licDetailValue} pad-s-15 license-info`}>
                  {services?.ADC?.enabled ? 'Enabled' : 'Disabled'}
                </A10Col>
              </A10Row>
              <A10Row className={`${styles.licenseDetail} no-margin`}>
                <A10Col span={5} className='col-md-5 title lic-info'>CGN</A10Col>
                <A10Col span={7} className={`${styles.licDetailValue} pad-s-15 license-info`}>
                  {services?.CGN?.enabled ? 'Enabled' : 'Disabled'}
                </A10Col>
              </A10Row>
              <A10Row className={`${styles.licenseDetail} no-margin`}>
                <A10Col span={5} className='col-md-5 title lic-info'>GTP Firewall</A10Col>
                <A10Col span={7} className={`${styles.licDetailValue} pad-s-15 license-info`}>
                  {services?.GTPFW?.enabled ? 'Enabled' : 'Disabled'}
                </A10Col>
              </A10Row>
              <A10Row className={`${styles.licenseDetail} no-margin`}>
                <A10Col span={5} className='col-md-5 title lic-info'>Gi Firewall</A10Col>
                <A10Col span={7} className={`${styles.licDetailValue} pad-s-15 license-info`}>
                  {services?.GiFW?.enabled ? 'Enabled' : 'Disabled'}
                </A10Col>
              </A10Row>
              <A10Row className={`${styles.licenseDetail} no-margin`}>
                <A10Col span={5} className='col-md-5 title lic-info'>SSLi</A10Col>
                <A10Col span={7} className={`${styles.licDetailValue} pad-s-15 license-info`}>
                  {services?.SSLi?.enabled ? 'Enabled' : 'Disabled'}
                </A10Col>
              </A10Row>
            </>
          )}

          {/* <div className={`row ${styles.licDetailRow} pad-v-10`}>
            <div className="col-md-5 title">Last Updated</div>
            <div className={`${styles.licDetailValue} col-md-7 pad-s-15`}>
              {licenseData['last-modified-at']}
            </div>
          </div> */}
        </A10Col>
        <A10Col span={12}>
          {licenseData['activation-status'] === 'ACTIVATED' ||
            licenseData['activation-status'] === 'EXPIRING' ||
            licenseData['activation-status'] === 'EXPIRED' ||
            licenseData['activation-status'] === 'GRACE-PERIOD' ||
            licenseData['activation-status'] === 'REVOKED' ? (
            <LMLicenseCharts licenseData={licenseData} />
          ) : null}
        </A10Col>

        {licenseData['license-type'].indexOf('FLEXPOOL') > -1 ? (
          <A10Col span={24} className={styles.licUsageDiv}>
            <LMLicenseUsageCharts
              licenseData={licenseData}
              licenseUsage={this.state.licenseUsage}
            />
          </A10Col>
        ) : null}
      </A10Row>
    )
  }

  renderActivationDetails = (
    record: any,
    index: number,
    indent: number,
    expanded: boolean,
  ) => {
    this.updateActivationInfo = expanded
      ? !this.updateActivationInfo
      : this.updateActivationInfo
    return (
      <LMLicenseActivationDetails
        isUpdateActivations={this.updateActivationInfo}
        activation={record}
        licenseData={this.props.licenseData}
      />
    )
  }

  renderLicActivations = () => {
    const { activationList, isLoading } = this.state
    return (
      <A10Table
        columns={this.activationColumns}
        expandedRowRender={this.renderActivationDetails}
        dataSource={activationList.map((item: IObject, index: number) => {
          item.key = index
          return item
        })}
        size="small"
        scroll={{ x: '100%' }}
        loading={
          isLoading
            ? {
              indicator: (
                <div>
                  <ReactLoading
                    type="bars"
                    color="#4a90e2"
                    height={40}
                    width={40}
                  />
                </div>
              ),
            }
            : false
        }
        pagination={{ hideOnSinglePage: true, pageSize: 5 }}
      />
    )
  }


  render() {
    const {
      tabValue,
      activationList,
      selectedActivation,
      showActivationSlidingPage,
      slidingForm,
    } = this.state

    return (
      <div className="list-detail-container">
        <A10Row className={styles.licTabsRow}>
          <A10Radio.Group
            value={tabValue}
            buttonStyle="tab"
            onChange={this.tabChange.bind(this)}
          >
            <A10Radio.Button value="licInfo">
              License Information
            </A10Radio.Button>
            {/* <A10Radio.Button value="providerAllocation">Provider Allocation</A10Radio.Button> */}
            <A10Radio.Button value="activations">
              Activations <RoundNumber number={activationList.length} />
            </A10Radio.Button>
          </A10Radio.Group>
        </A10Row>
        <A10Row className={styles.componentRow}>
          {tabValue === 'licInfo' ? (
            this.renderLicInfo()
          ) : tabValue === 'providerAllocation' ? (
            <></>
          ) : tabValue === 'activations' ? (
            this.renderLicActivations()
          ) : null}
        </A10Row>

        <SlidingLMActivationForm
          isOpen={showActivationSlidingPage}
          slidingForm={slidingForm}
          activationObj={selectedActivation}
          licType={this.props.licenseData['license-type']}
          licToken={this.props.licenseData['entitlement-key']}
          onRequestClose={this.closeSlidingPage}
          updateList={this.getLicenseActivations}
        />
      </div>
    )
  }
}

export default setupA10Container(LMLicenseDetails)
