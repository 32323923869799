import React from 'react'
import { A10Component, A10Context } from '@gui-libraries/framework'
import { A10TreeSelect, A10Tooltip, A10Avatar } from '@gui-libraries/widgets'

import { AppRoot } from 'src/settings/appRoot'
import storage from 'src/libraries/storage'

import './styles/LeftNavProviderTenantMenu.scss'

export interface ILeftNavProviderTenantMenuProps {
  tenants: any
  selectedTenant: Storage.ITenant
  refreshTenants: boolean
  leftNavCollapsed?: boolean
  tenantChange(tenant: any, isProviderMode: boolean, isDualMode?: boolean): void
}

export interface ILeftNavProviderTenantMenuState {
  tenants: any
  selectedTenant: Storage.ITenant
  searchString: string
  refreshTenants: boolean
}

const A10TreeNode = A10TreeSelect.A10TreeNode
const DEFAULT_SELECTED_TENANT = { name: '' } as Storage.ITenant
class LeftNavProviderTenantMenu extends A10Component<
  ILeftNavProviderTenantMenuProps,
  ILeftNavProviderTenantMenuState
> {
  static contextType = A10Context
  static getDerivedStateFromProps(
    nextProps: ILeftNavProviderTenantMenuProps,
    prevState: ILeftNavProviderTenantMenuState,
  ) {
    let refreshTenants = prevState.refreshTenants
    if (prevState.refreshTenants !== nextProps.refreshTenants) {
      refreshTenants = nextProps.refreshTenants
    }
    return {
      tenants: storage.get.ALLTENANTS,
      selectedTenant: prevState.selectedTenant,
      searchString: prevState.searchString,
      refreshTenants,
    }
  }
  context: React.ContextType<typeof A10Context>
  AppRoot = new AppRoot()
  state = {
    selectedTenant: this.props.selectedTenant || DEFAULT_SELECTED_TENANT,
    tenants: storage.get.ALLTENANTS,
    searchString: '',
    refreshTenants: false,
  }
  isSuperUser = storage.get.IS_SUPER_USER

  tenantChange = (tenant: any) => {
    try {
      // tenant
      tenant = JSON.parse(tenant)
      if (!tenant.name) {
        tenant = {
          name: tenant,
        }
      }
    } catch (e) {
      // provider
      if (tenant !== undefined || !tenant.name) {
        tenant = {
          name: tenant,
        }
      }
    }
    if (tenant && tenant.name === ',superAdmin,') {
      storage.remove.CURRENT_TENANT()
      storage.set.DRILL_LEVEL('provider')
      storage.set.SUPER_ADMIN_MODE(true)
      tenant.name = 'superAdmin'
      tenant.superAdminRole = true
      this.AppRoot.setRootScopeElement('currentTenant', '')
      this.AppRoot.setRootScopeElement('currentDrillLevel', 'provider')
      this.props.tenantChange(tenant, true)
    } else if (tenant && tenant.name === storage.get.PROVIDER) {
      storage.remove.SUPER_ADMIN_MODE()
      storage.remove.CURRENT_TENANT()
      storage.set.DRILL_LEVEL('provider')
      this.AppRoot.setRootScopeElement('currentTenant', '')
      this.AppRoot.setRootScopeElement('currentDrillLevel', 'provider')
      this.props.tenantChange(tenant, true)
    } else if (tenant) {
      storage.remove.SUPER_ADMIN_MODE()
      storage.set.DRILL_LEVEL('tenant')
      this.AppRoot.setRootScopeElement('currentDrillLevel', 'tenant')
      const currentState = storage.get.CURRENT_STATE
      this.props.tenantChange(
        tenant,
        false,
        currentState?.toLowerCase() === 'apps' ||
          currentState?.toLowerCase() === 'home'
          ? true
          : false,
      )
    }
  }

  searchTenant = (searchString: any) => {
    const tenants = storage.get.ALLTENANTS
    let filteredTenants = tenants
    if (searchString && searchString !== '') {
      filteredTenants = tenants.filter((tenant: any) => {
        return tenant.name.toLowerCase().indexOf(searchString) > -1
      })
    }
    this.setState({
      searchString,
      tenants: filteredTenants,
    })
  }
  componentDidMount() {
    const { selectedTenant } = this.props

    if (selectedTenant) {
      if (selectedTenant?.name !== this.state.selectedTenant.name) {
        this.setState({
          selectedTenant: this.props.selectedTenant,
          tenants: storage.get.ALLTENANTS,
        })
      }
    } else {
      if (selectedTenant !== this.state.selectedTenant) {
        this.setState({
          selectedTenant: DEFAULT_SELECTED_TENANT,
          tenants: storage.get.ALLTENANTS,
        })
      }
    }
  }
  componentDidUpdate() {
    const { selectedTenant = DEFAULT_SELECTED_TENANT } = this.props

    if (selectedTenant) {
      if (selectedTenant?.name !== this.state.selectedTenant.name) {
        this.setState({
          selectedTenant,
          tenants: storage.get.ALLTENANTS,
        })
      }
    } else {
      if (
        (!!selectedTenant || !!this.state.selectedTenant) &&
        selectedTenant?.name !== this.state.selectedTenant.name
      ) {
        this.setState({
          selectedTenant: DEFAULT_SELECTED_TENANT,
          tenants: storage.get.ALLTENANTS,
        })
      }
    }
  }

  renderDisplayText = (displayName: any, symbol: string) => {
    return (
      <>
        <A10Tooltip title={displayName}>
          <A10Avatar
            style={{
              width: '18px',
              height: '18px',
              lineHeight: '18px',
              fontSize: '12px',
              backgroundColor: '#81c784',
            }}
          >
            {symbol}
          </A10Avatar>
        </A10Tooltip>

        <span className="option-label" title={displayName}>
          {displayName}
        </span>
      </>
    )
  }

  render() {
    const {
      get: { ADMIN_LEVEL: adminLevel },
    } = storage

    return (
      <div className="pt-drop-container">
        <A10TreeSelect
          searchValue={this.state.searchString}
          onSearch={this.searchTenant}
          // filterTreeNode={false}
          showSearch
          value={
            this.state.selectedTenant.name
              ? this.state.selectedTenant.name
              : storage.get.SUPER_ADMIN_MODE
              ? ',superAdmin,'
              : undefined
          }
          dropdownStyle={{ maxHeight: 400, width: 300, overflow: 'auto' }}
          dropdownClassName="provider-tenant-select-dropdown"
          className={
            this.props.leftNavCollapsed
              ? 'provider-tenant-menu-collapsed'
              : 'provider-tenant-menu'
          }
          placeholder={this.renderDisplayText(storage.get.PROVIDER, 'P')}
          treeDefaultExpandAll
          onChange={this.tenantChange}
          // size={this.props.leftNavCollapsed ? 'small' : 'large'}
          size="large"
        >
          {this.isSuperUser ? (
            <A10TreeNode
              value=",superAdmin,"
              title={this.renderDisplayText('Controller', 'C')}
              key="superAdmin"
              // selectable={adminLevel === 'provider' ? true : false}
              // disabled={adminLevel === 'provider' ? false : true}
            />
          ) : (
            <></>
          )}
          <A10TreeNode
            value={storage.get.PROVIDER}
            title={this.renderDisplayText(storage.get.PROVIDER, 'P')}
            key="provider"
            selectable={adminLevel === 'provider' ? true : false}
            disabled={adminLevel === 'provider' ? false : true}
          >
            {this.state.tenants &&
              this.state.tenants.map((key: any, index: number) => {
                if (key.type !== 'built-in') {
                  return (
                    <A10TreeNode
                      value={key.name}
                      title={this.renderDisplayText(
                        key['display-name'] || key.name,
                        'T',
                      )}
                      key={key.name}
                    />
                  )
                } else {
                  return null
                }
              })}
          </A10TreeNode>
        </A10TreeSelect>
      </div>
    )
  }
}

export default LeftNavProviderTenantMenu
