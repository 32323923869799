import React from 'react'
import {
  A10Component,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Form,
  A10Input,
  A10Alert,
  A10InputNumber,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import { Messages } from 'src/locale/en/'
import Utilities from 'src/services/Utilities/Utilities'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import storage from 'src/libraries/storage'
import { HelpInfo } from 'src/components/shared/HelpInfo'

import './styles/licenseaddform.scss'

export interface IFormData {
  entitleToken: string
  licenseType: string
  numBandwidth: string
  numDevices: string
  numProcessingUnits: number
}

export interface ILicenseAddFormProps extends IA10ContainerDefaultProps {
  data: any[]
  form: any
  onRef?: any
  isValidToken: boolean
  licenseInfo: any
  offlineLicense: any
  onChange?: (data: IFormData) => void
  onChangeOffline?: (data: any) => void
  glmLicTypeText: (glmLicType: string, licType: string) => string
}
export interface ILicenseAddFormState {
  provider: string
  formData: IFormData
}

class LicenseAddForm extends A10Component<
  ILicenseAddFormProps,
  ILicenseAddFormState
> {
  static getDerivedStateFromProps(
    props: ILicenseAddFormProps,
    state: ILicenseAddFormState,
  ) {
    if (props.isValidToken) {
      return {
        formData: {
          entitleToken: props.licenseInfo['entitlement-key'],
          licenseType: props.licenseInfo['license-type'],
          numBandwidth: state.formData.numBandwidth,
          numDevices: state.formData.numDevices,
          numProcessingUnits: state.formData.numProcessingUnits,
        },
      }
    } else {
      return null
    }
  }

  Messages = new Messages()
  Utilities = new Utilities()
  formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  }

  constructor(props: ILicenseAddFormProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER,
      formData: {
        entitleToken: '',
        licenseType: '',
        numBandwidth: '',
        numDevices: '',
        numProcessingUnits: '',
      },
    }
  }

  validateDuplicateToken = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const regexp = new RegExp(this.Utilities.NAME_PATTERN)
    const index = this.props.data.findIndex((key: any) => {
      return key['entitlement-key'] === value
    })
    if ('' === value) {
      rule.message = this.Messages.ENTITLEMENT_TOKEN_REQUIRED
      isValid = false
    } else if (!regexp.test(value)) {
      rule.message = this.Messages.ENTITLEMENT_TOKEN_INVALID
      isValid = false
      return cb(true)
    } else if (index > -1) {
      rule.message = this.Messages.DUPLICATE_ENTITLEMENT_TOKEN
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  handleChange = (stateName: string, e: any) => {
    const licenseObj = this.state.formData
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        licenseObj[stateName] = e.target.checked
      } else {
        licenseObj[stateName] = e.target.value
      }
    } else {
      licenseObj[stateName] = e
    }
    this.props.onChange(licenseObj)
    // @ts-ignore
    this.setState({
      [stateName]: licenseObj[stateName],
    })
  }

  handleChangeOffline = (stateName: string, e: any) => {
    const offlineLicense = this.props.offlineLicense
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        offlineLicense[stateName] = e.target.checked
      } else {
        offlineLicense[stateName] = e.target.value
      }
    } else {
      offlineLicense[stateName] = e
    }
    if (stateName === 'enabled' && !offlineLicense.enabled) {
      offlineLicense.isValidActivationUUID = false
      offlineLicense.activationUUID = ''
      offlineLicense.activationKey = ''
    }
    this.props.onChangeOffline(offlineLicense)
  }

  renderUnits(state: any) {
    const { getFieldDecorator } = this.props.form
    const maxbandwidthObj = this.Utilities.megaToGiga(
      this.props.licenseInfo['remaining-bandwidth'] || 0,
    )
    const devicesPlaceholder = `Max ${this.props.licenseInfo['remaining-devices']}`
    const bandwidthPlaceholder = `Max ${maxbandwidthObj.val}`

    switch (state) {
      case 'MBU':
        return (
          <div>
            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.ENTER_BANDWIDTH}
                  </span>
                  <span style={{ marginLeft: '-15px' }}>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.ENTER_BANDWIDTH + ' * '}
                      helpKey="HELP_LICENSE_BANDWIDTH"
                    />
                  </span>
                </>
              }
            >
              <div>
                {getFieldDecorator('numBandwidth', {
                  rules: [
                    {
                      required: true,
                      message: this.Messages.THUNDER_BANDWIDTH_REQUIRED,
                    },
                  ],
                  initialValue: this.state.formData.numBandwidth,
                })(
                  <A10InputNumber
                    className="width-40P"
                    type="number"
                    min={0}
                    max={maxbandwidthObj.val}
                    onChange={this.handleChange.bind(this, 'numBandwidth')}
                    placeholder={bandwidthPlaceholder}
                    style={
                      this.state.formData.numBandwidth !== ''
                        ? {}
                        : { fontSize: '12px' }
                    }
                  />,
                )}
                <label className="pdlr-5">
                  {maxbandwidthObj && maxbandwidthObj.unit === 'm'
                    ? 'Mbps'
                    : 'Gbps'}
                </label>
              </div>
            </A10Form.Item>
            {this.props.licenseInfo['remaining-devices'] > 0 ? (
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    <span className="mandatoryField">
                      {this.Messages.ENTER_DEVICES}
                    </span>
                    <span style={{ marginLeft: '-15px' }}>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.ENTER_DEVICES + ' * '}
                        helpKey="HELP_LICENSE_DEVICES"
                      />
                    </span>
                  </>
                }
              >
                {getFieldDecorator('numDevices', {
                  rules: [
                    {
                      required: true,
                      message: this.Messages.THUNDER_DEVICE_REQUIRED,
                    },
                  ],
                  initialValue: this.state.formData.numDevices,
                })(
                  <A10InputNumber
                    className="width-40P"
                    type="number"
                    min={0}
                    max={this.props.licenseInfo['remaining-devices']}
                    onChange={this.handleChange.bind(this, 'numDevices')}
                    placeholder={devicesPlaceholder}
                    style={
                      this.state.formData.numDevices !== ''
                        ? {}
                        : { fontSize: '12px' }
                    }
                  />,
                )}
              </A10Form.Item>
            ) : null}
          </div>
        )
      case 'MPU':
        return (
          <div>
            <A10Form.Item
              {...this.formItemLayout}
              label={this.Messages.ENTER_PROCESSING_UNITS}
            >
              <div>
                {getFieldDecorator('numProcessingUnits', {
                  rules: [
                    {
                      required: true,
                      message: this.Messages.LADC_PROCESS_UNIT_REQUIRED,
                    },
                  ],
                  initialValue: this.state.formData.numProcessingUnits,
                })(
                  <A10InputNumber
                    className="width-50P"
                    type="number"
                    min={0}
                    onChange={this.handleChange.bind(
                      this,
                      'numProcessingUnits',
                    )}
                    placeholder={this.Messages.NUMBER}
                  />,
                )}
              </div>
            </A10Form.Item>
          </div>
        )
      case 'MDU':
        return (
          <div>
            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.ENTER_DEVICES}
                  </span>
                  <span style={{ marginLeft: '-15px' }}>
                    <HelpInfo
                      placement="leftTop"
                      title={this.Messages.ENTER_DEVICES + ' * '}
                      helpKey="HELP_LICENSE_DEVICES"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('numDevices', {
                rules: [
                  {
                    required: true,
                    message: this.Messages.THUNDER_DEVICE_REQUIRED,
                  },
                ],
                initialValue: this.state.formData.numDevices,
              })(
                <A10InputNumber
                  className="width-40P"
                  type="number"
                  min={0}
                  max={this.props.licenseInfo['remaining-devices']}
                  onChange={this.handleChange.bind(this, 'numDevices')}
                  placeholder={devicesPlaceholder}
                  style={
                    this.state.formData.numDevices !== ''
                      ? {}
                      : { fontSize: '12px' }
                  }
                />,
              )}
            </A10Form.Item>
          </div>
        )
      default:
        return null
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  render() {
    const { entitleToken, licenseType } = this.state.formData
    const { getFieldDecorator } = this.props.form
    let licTypeValue = ''
    if (licenseType.indexOf('FLEXPOOL') > -1) {
      licTypeValue = this.props.glmLicTypeText(
        this.props.licenseInfo['glm-lic-type'],
        licenseType,
      )
    }
    if (licenseType === 'MBU') {
      licTypeValue = 'Managed Bandwidth Units'
    }
    if (licenseType === 'MDU') {
      licTypeValue = 'Managed Devices'
    }
    const allotedBW = this.Utilities.megaToGiga(
      this.props.licenseInfo && this.props.licenseInfo['alloted-bandwidth']
        ? this.props.licenseInfo['alloted-bandwidth']
        : 0,
    )
    const remainBW = this.Utilities.megaToGiga(
      this.props && this.props.licenseInfo['remaining-bandwidth']
        ? this.props.licenseInfo['remaining-bandwidth']
        : 0,
    )
    const defaultBW = this.Utilities.megaToGiga(
      this.props.licenseInfo &&
        this.props.licenseInfo['default-allocated-bandwidth']
        ? this.props.licenseInfo['default-allocated-bandwidth']
        : 0,
    )
    const minBW = this.Utilities.megaToGiga(
      this.props.licenseInfo &&
        this.props.licenseInfo['min-allocated-bandwidth']
        ? this.props.licenseInfo['min-allocated-bandwidth']
        : 0,
    )
    const maxBW = this.Utilities.megaToGiga(
      this.props.licenseInfo &&
        this.props.licenseInfo['max-allocated-bandwidth']
        ? this.props.licenseInfo['max-allocated-bandwidth']
        : 0,
    )

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel
          title={
            <>
              <A10IconTextGroup
                text="License Information"
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            </>
          }
        >
          <A10Form.Item
            {...this.formItemLayout}
            label={
              <>
                <span className="mandatoryField">
                  {this.Messages.ENTER_ENTITLEMENT_TOKEN} 
                </span>
                <span style={{ marginLeft: '-15px' }}>
                  <HelpInfo
                    placement="leftTop"
                    title={this.Messages.ENTER_ENTITLEMENT_TOKEN + ' * '}
                    helpKey="HELP_LICENSE_TOKEN"
                  />
                </span>
              </>
            }
          >
            <div>
              {getFieldDecorator('entitleToken', {
                rules: [{ validator: this.validateDuplicateToken.bind(this) }],
                initialValue: entitleToken,
              })(
                <A10Input
                  type="text"
                  disabled={this.props.isValidToken}
                  placeholder={this.Messages.ENTITLEMENT_TOKEN_PH}
                  onChange={this.handleChange.bind(this, 'entitleToken')}
                />,
              )}
            </div>
          </A10Form.Item>

          {this.props.isValidToken ? null : (
            <>


              {this.props.offlineLicense.enabled &&
              this.props.offlineLicense.isValidActivationUUID ? (
                <>
                  <A10Form.Item
                    {...this.formItemLayout}
                    label={
                      <>
                        <span className="mandatoryField">
                          {this.Messages.ACTIVATION_UUID}
                        </span>
                        <span style={{ marginLeft: '-15px' }}>
                          <HelpInfo
                            placement="leftTop"
                            title={this.Messages.ACTIVATION_UUID + ' * '}
                            helpKey="HELP_LICENSE_ACTIVATION_UUID"
                          />
                        </span>
                      </>
                    }
                  >
                    <div>
                      {getFieldDecorator('activationUUID', {
                        initialValue: this.props.offlineLicense.activationUUID,
                      })(
                        <A10Input.TextArea
                          disabled={true}
                          style={{ resize: 'none' }}
                          onChange={this.handleChangeOffline.bind(
                            this,
                            'activationUUID',
                          )}
                        />,
                      )}
                    </div>
                  </A10Form.Item>

                  <A10Alert
                    description={
                      <ul>
                        <li>Follow below steps for activation :</li>
                        <li>1. Copy above Activation UUID.</li>
                        <li>
                          2. Go to the same license page in GLM from where you
                          picked the Entitlement Token, Go to Activations tab
                          and click on "+ Activation" button.
                        </li>
                        <li>
                          3. Enter required bandwidth and Paste UUID from step 1
                          in "Provider UUID" box.
                        </li>
                        <li>
                          4. Click Activate, it shall activate the required
                          bandwidth.
                        </li>
                        <li>5. Click on "Done" button</li>
                        <li>
                          6. Copy the generated "Activation Key" and paste in
                          below box.
                        </li>
                        <li>7. Click "Import License".</li>
                        <li>
                          8. For correct Activation Key, Activated License with
                          bandwidth will show up in License list.
                        </li>
                      </ul>
                    }
                    message={`Please make sure that you have selected correct token for offline activation, as once it is activated, the activation can not be released. Offline activation is available only for HC license activation.`}
                    type="warning"
                    style={{ marginBottom: 24 }}
                    showIcon={true}
                    closable={false}
                  />

                  <A10Form.Item
                    {...this.formItemLayout}
                    label={
                      <>
                        <span className="mandatoryField">
                          {this.Messages.ACTIVATION_KEY_LM}
                        </span>
                        <span style={{ marginLeft: '-15px' }}>
                          <HelpInfo
                            placement="leftTop"
                            title={this.Messages.ACTIVATION_KEY_LM + ' * '}
                            helpKey="HELP_LICENSE_ACTIVATION_KEY_LM"
                          />
                        </span>
                      </>
                    }
                  >
                    <div>
                      {getFieldDecorator('activationKey', {
                        rules: [
                          {
                            required: true,
                            message: this.Messages.EMPTY_ACTIVATION_KEY_LM,
                          },
                        ],
                        initialValue: this.props.offlineLicense.activationKey,
                      })(
                        <A10Input.TextArea
                          placeholder={this.Messages.ACTIVATION_KEY_LM}
                          onChange={this.handleChangeOffline.bind(
                            this,
                            'activationKey',
                          )}
                        />,
                      )}
                    </div>
                  </A10Form.Item>
                </>
              ) : null}
            </>
          )}

          {this.props.isValidToken ? (
            <>
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    {'Name'}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title="Name"
                        helpKey="HELP_LICENSE_NAME"
                      />
                    </span>
                  </>
                }
                className="no-margin font-14"
              >
                {
                  <div className="disabled">
                    {this.props.licenseInfo['license-name']}
                  </div>
                }
              </A10Form.Item>


              {this.props.licenseInfo['organization-name'] ? (
                <A10Form.Item
                  {...this.formItemLayout}
                  label={
                    <>
                      {'Organization Name'}
                      <span>
                        <HelpInfo
                          placement="leftTop"
                          title="Organization Name"
                          helpKey="HELP_LICENSE_ORGANIZATION_NAME"
                        />
                      </span>
                    </>
                  }
                  className="no-margin font-14"
                >
                  {
                    <div className="disabled">
                      {this.props.licenseInfo['organization-name']}
                    </div>
                  }
                </A10Form.Item>
              ) : null}

              {this.props.licenseInfo['license-platform'] ? (
                <A10Form.Item
                  {...this.formItemLayout}
                  label={
                    <>
                      {'License Platform'}
                      <span>
                        <HelpInfo
                          placement="leftTop"
                          title="License Platform"
                          helpKey="HELP_LICENSE_PLATFORM"
                        />
                      </span>
                    </>
                  }
                  className="no-margin font-14"
                >
                  {
                    <div className="disabled">
                      {this.props.licenseInfo['license-platform']}
                    </div>
                  }
                </A10Form.Item>
              ) : null}
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    {this.Messages.LICENSE_TYPE}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.LICENSE_TYPE}
                        helpKey="HELP_LICENSE_TYPE_VALUE"
                      />
                    </span>
                  </>
                }
                className="no-margin font-14"
              >
                {<div className="disabled">{licTypeValue}</div>}
              </A10Form.Item>
              {this.props.licenseInfo['license-product'] ? (
                <A10Form.Item
                  {...this.formItemLayout}
                  label={
                    <>
                      {'License Product'}
                      <span>
                        <HelpInfo
                          placement="leftTop"
                          title="License Product"
                          helpKey="HELP_LICENSE_PRODUCT"
                        />
                      </span>
                    </>
                  }
                  className="no-margin font-14"
                >
                  {
                    <div className="disabled">
                      {this.props.licenseInfo['license-product']}
                    </div>
                  }
                </A10Form.Item>
              ) : null}

              {this.props.licenseInfo['used-activations'] ? (
                <A10Form.Item
                  {...this.formItemLayout}
                  label={
                    <>
                      {'Used Activations'}
                      <span>
                        <HelpInfo
                          placement="leftTop"
                          title="Used Activations"
                          helpKey="HELP_LICENSE_USED_ACTIVATIONS"
                        />
                      </span>
                    </>
                  }
                  className="no-margin font-14"
                >
                  {
                    <div className="disabled">
                      {this.props.licenseInfo['used-activations']}
                    </div>
                  }
                </A10Form.Item>
              ) : null}

              {licenseType.indexOf('FLEXPOOL') > -1 ? (
                <>
                  <A10Form.Item
                    {...this.formItemLayout}
                    label={
                      <>
                        {'Capacity'}
                        <span>
                          <HelpInfo
                            placement="leftTop"
                            title="Capacity"
                            helpKey="HELP_LICENSE_BW_CAPACITY"
                          />
                        </span>
                      </>
                    }
                    className="no-margin font-14"
                  >
                    <div className="disabled">
                    <span>
                      Bandwidth (Alloted / Remaining) :{' '}
                        {(allotedBW.val == 0) ?'na' :(allotedBW.val +
                          (allotedBW.unit === 'm' ? ' Mbps' : ' Gbps'))}{' / '}
                        {(remainBW.val == 0) ?'na' : remainBW.val +
                          (remainBW.unit === 'm' ? ' Mbps' : ' Gbps')}
                        &nbsp;&nbsp;
                      </span>
                      <br />
                    <span>
                      Devices (Alloted / Used) :{' '}
                        {(this.props.licenseInfo['alloted-devices'] == 0 ? this.props.licenseInfo['license-type'] === "SUBSCRIBER_FLEXPOOL" ? 'Unlimited': 'na': this.props.licenseInfo['alloted-devices'])}{' / '}
                        {(this.props.licenseInfo['used-devices'] == 0 ? 'na' : this.props.licenseInfo['used-devices'])}{' '}
                      </span>
                        <br />
                      <span>
                      Subscribers :{(this.props.licenseInfo['subscriber-count'] && this.props.licenseInfo['subscriber-count'] != 0 )? this.props.licenseInfo['subscriber-count'] : ' '+'na'}
                      </span>
                    </div>
                  </A10Form.Item>
                  <A10Form.Item
                    {...this.formItemLayout}
                    label={
                      <>
                        {'Bandwidth Allocation'}
                        <span>
                          <HelpInfo
                            placement="leftTop"
                            title="Bandwidth Allocation"
                            helpKey="HELP_LICENSE_BW_ALLOCATION"
                          />
                        </span>
                      </>
                    }
                    className="no-margin font-14"
                  >
                    <div className="disabled">
                      <span>
                        Default: {defaultBW.val}{' '}
                        {defaultBW.unit === 'm' ? 'Mbps' : 'Gbps'} &nbsp;&nbsp;
                      </span>
                      <span>
                        Min: {minBW.val} {minBW.unit === 'm' ? 'Mbps' : 'Gbps'}{' '}
                        &nbsp;&nbsp;
                      </span>
                      <span>
                        Max: {maxBW.val} {maxBW.unit === 'm' ? 'Mbps' : 'Gbps'}
                      </span>
                    </div>
                  </A10Form.Item>
                  {this.props.licenseInfo['check-in-frequency'] ? (
                    <A10Form.Item
                      {...this.formItemLayout}
                      label={
                        <>
                          {'Check-in Frequency'}
                          <span>
                            <HelpInfo
                              placement="leftTop"
                              title="Check-in Frequency"
                              helpKey="HELP_LICENSE_CHECK_IN_FREQUENCY"
                            />
                          </span>
                        </>
                      }
                      className="no-margin font-14"
                    >
                      {
                        <div className="disabled">
                          {this.Utilities.secondsToMinutes(
                            this.props.licenseInfo['check-in-frequency'],
                          )}
                        </div>
                      }
                    </A10Form.Item>
                  ) : null}

                  {this.props.licenseInfo[
                    'container-device-return-to-pool-time'
                  ] ? (
                    <A10Form.Item
                      {...this.formItemLayout}
                      label={
                        <>
                          {'Container Thunder Return to Pool Time'}
                          <span>
                            <HelpInfo
                              placement="leftTop"
                              title="Container Thunder Return to Pool Time"
                              helpKey="HELP_LICENSE_CTRTPT"
                            />
                          </span>
                        </>
                      }
                      className="no-margin font-14"
                    >
                      {
                        <div className="disabled">
                          {this.Utilities.secondsToMinutes(
                            this.props.licenseInfo[
                              'container-device-return-to-pool-time'
                            ],
                          )}
                        </div>
                      }
                    </A10Form.Item>
                  ) : null}
                </>
              ) : null}

              {this.props.licenseInfo['start-date'] ? (
                <A10Form.Item
                  {...this.formItemLayout}
                  label={
                    <>
                      {'Start Date'}
                      <span>
                        <HelpInfo
                          placement="leftTop"
                          title="Start Date"
                          helpKey="HELP_LICENSE_START_DATE"
                        />
                      </span>
                    </>
                  }
                  className="no-margin font-14"
                >
                  {
                    <div className="disabled">
                      {this.props.licenseInfo['start-date']}
                    </div>
                  }
                </A10Form.Item>
              ) : null}

              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    {'Expiration Date'}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title="Expiration Date"
                        helpKey="HELP_LICENSE_EXPIRES_ON"
                      />
                    </span>
                  </>
                }
                className="no-margin font-14"
              >
                {
                  <div className="disabled">
                    {this.props.licenseInfo['expires-at']}
                  </div>
                }
              </A10Form.Item>
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    {'Notes'}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title="Notes"
                        helpKey="HELP_LICENSE_NOTES"
                      />
                    </span>
                  </>
                }
                className="no-margin font-14"
              >
                {
                  <div className="disabled">
                    {this.props.licenseInfo['notes']}
                  </div>
                }
              </A10Form.Item>

              {this.renderUnits(licenseType)}
            </>
          ) : null}
        </A10Panel>
      </A10Form>
    )
  }
}

function mapStateToProps(state: any, props: ILicenseAddFormProps) {
  return {
    data: state.A10Data.getIn(getNS('LICENSE_LIST'), []),
  }
}

export default setupA10Container(
  A10Form.create()(LicenseAddForm),
  mapStateToProps,
)
