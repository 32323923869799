import React from 'react'
import { A10Icon } from '@gui-libraries/widgets'
import parameters from 'parameters'
import styles from './styles/index.module.less'

const Help: React.FC<any> = props => {
  const HELPSUPPORT = [
    {
      name: 'Getting started with Harmony',
      func: 'prdDemo',
      iconApp: 'global',
      iconClass: 'product-document',
      helpUrl: `${parameters.EDUCATION_LINK}Default.htm`,
      description:
        'Learn the basics and find valuable information to get the most out of Harmony Controller',
    },
    {
      name: "What's New",
      func: 'prdDocumentation',
      iconApp: 'harmony-controller',
      iconClass: 'license-manager',
      helpUrl: `${parameters.EDUCATION_LINK}Default.htm#Product/release_notes.htm%3FTocPath%3DRelease%2520Notes%7C_____0`,
      description: 'Discover new features, enhancements and resolved issues',
    },
    {
      name: 'Create Ticket',
      func: 'createTicket',
      iconApp: 'global',
      iconClass: 'create-ticket',
      helpUrl: 'https://www.a10networks.com/support/',
      description: 'Got an issue? Raise a ticket',
    },
  ]

  const helpFunction = (func: string, e: any) => {
    window.open(func, '_blank')
  }

  return (
    <>
      {HELPSUPPORT.map((key: any, index: any) => {
        const action = helpFunction.bind(this, key.helpUrl)

        return (
          <>
            <div key={index} className={styles.helpContainer}>
              <div className={styles.helpIconContainer}>
                <A10Icon
                  app={key.iconApp}
                  type={key.iconClass}
                  style={{
                    fontSize: '50px',
                    color: '#736e6e',
                  }}
                ></A10Icon>
              </div>
              <div className={styles.helpCol}>
                <label onClick={action}>{key.name}</label>
                <p>{key.description}</p>
              </div>
            </div>
          </>
        )
      })}
    </>
  )
}
export default Help
