import { _ } from '@gui-libraries/framework'

import { httpClient } from 'src/libraries/httpClient'
import * as basicUtils from './basicUtils'

const getTop10 = (filters: IObject = {}) => {
  const filterArr = [] as IObject
  _.forEach(filters, (value: IObject, key: string) => {
    filterArr.push({ key, value })
  })
  const sort = filterArr.sort((pre: IObject, next: IObject) => {
    try {
      const {
        value: { count: preCount },
      } = pre
      const {
        value: { count: nextCount },
      } = next
      return preCount > nextCount ? -1 : 1
    } catch {
      return 0
    }
  })
  const counts = {}
  sort.slice(0, 10).map((item: IObject) => {
    counts[item.key] = item.value
  })
  return counts
}

export const getAudits = async () => {
  const providerName = basicUtils.getProvider()
  const config: IObject = {
    absoluteBasePath: true,
  }
  try {
    const { data: audits = [] as IObject[] } = await httpClient.get(
      `/hls/licenses/providers/${providerName}/auditlogs`,
      config,
    )

    const entitlementKey = {}
    const license = {}
    const devices = {}
    const logSource = {}
    audits.map((audit: IObject) => {
      const key = audit['entitlement-key']
      const count = entitlementKey[key] || { count: 0 }
      count.count++
      entitlementKey[key] = count

      const licensekey = _.capitalize(audit['activation-status'])
      const licenseCount = license[licensekey] || { count: 0 }
      licenseCount.count++
      license[licensekey] = licenseCount

      const deivcekey = audit['device-name']
      if (deivcekey) {
        const deivceCount = devices[deivcekey] || { count: 0 }
        deivceCount.count++
        devices[deivcekey] = deivceCount
      }

      const resource = audit.resource
      const resourceCount = logSource[resource] || { count: 0 }
      resourceCount.count++
      logSource[resource] = resourceCount
    })
    const filters = {
      'entitlement-key': getTop10(entitlementKey),
      license: getTop10(license),
      'device-name': getTop10(devices),
      source: getTop10(logSource),
    }
    return { audits, total: audits.length, filters }
  } catch (err) {
    return { total: 0, audits: [] as IObject[] }
  }
}
