import React from 'react'
import moment from 'moment-timezone'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import {
  A10Form,
  A10Input,
  A10Select,
  A10DatePicker,
  A10Tree,
  A10Row,
  A10Radio,
  A10Col,
  A10Switch,
  A10Tag,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import storage from 'src/libraries/storage'
import { httpClient } from 'src/libraries/httpClient'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import ClusterDeviceTree from 'src/components/shared/ClusterDeviceTree'
import { DashboardService, Utilities } from 'src/services/index'
import { Messages } from 'src/locale/en'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

export interface IUpgradeFormProps extends IA10ContainerDefaultProps {
  clusterName?: string
  deviceLevelUpgrade?: boolean
  deviceName?: string
  imageName: string
  onChange: (data: IFormData) => void
}

export interface ICluster {
  name: string
}

export interface IImage {
  name: string
}

export interface ITreeNode {
  key: string
  title: string
  children?: ITreeNode[]
}

export interface IUpgradeFormStates {
  provider: string
  formData: IFormData
  imageList: IImage[]
  clusterDeviceTree: ITreeNode[]
  checkedClusters: string[]
  checkedDevices: string[]
  timezones: IObject[]
}

export interface IFormData {
  'device-upgrade': {
    image: IObject
    description: string
    clusterList: string[]
    deviceList: string[]
    drive: string
    disk: string
    save: boolean
    reboot: boolean
    when: string
    frequency: string
    date: IObject
    time: IObject
    timezone: string
    starts: any
  }
}

class UpgradeForm extends A10Container<IUpgradeFormProps, IUpgradeFormStates> {
  Messages = new Messages()
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  constructor(props: IUpgradeFormProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER || '',
      formData: {
        'device-upgrade': {
          image: null,
          description: '',
          drive: 'hd',
          disk: 'pri',
          clusterList: this.props.clusterName ? [this.props.clusterName] : [],
          deviceList: this.props.deviceName ? [this.props.deviceName] : [],
          save: true,
          reboot: true,
          when: 'schedule',
          frequency: 'daily',
          date: null,
          time: null,
          timezone: undefined,
          starts: '',
        },
      },
      imageList: [],
      clusterDeviceTree: [],
      checkedClusters: this.props.clusterName
        ? [`cluster:${this.props.clusterName}`]
        : [],
      checkedDevices: this.props.deviceName
        ? [`device:${this.props.deviceName}`]
        : [],
      timezones: [],
    }
  }

  componentDidMount() {
    this.getClusterDeviceMapping()
    this.loadTimezones()
  }

  onChangeCallback(formData: IFormData) {
    this.props.onChange(formData)
  }

  onImageChange = (image: string) => {
    const state = { ...this.state }
    state.formData['device-upgrade'].image = JSON.parse(image)
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onDriveChange = (e: any) => {
    const state = { ...this.state }
    state.formData['device-upgrade'].drive = e.target.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onDiskChange = (e: any) => {
    const state = { ...this.state }
    state.formData['device-upgrade'].disk = e.target.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onDescriptionChange = (e: any) => {
    const state = { ...this.state }
    state.formData['device-upgrade'].description = e.target.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onRebootChange = (status: boolean) => {
    const state = { ...this.state }
    state.formData['device-upgrade'].reboot = status
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onSaveConfigChange = (status: boolean) => {
    const state = { ...this.state }
    state.formData['device-upgrade'].save = status
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onCheckDevice = (selectedDevices: string[]) => {
    const state = { ...this.state }
    const checkedKeys = selectedDevices.map(
      selectedDevice => `device:${selectedDevice}`,
    )

    state.checkedDevices = checkedKeys
    state.formData['device-upgrade'].deviceList = selectedDevices
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onCheckCluster = (checkedKeys: string[], info: IObject) => {
    const state = { ...this.state }
    const selectedClusters = checkedKeys
      .filter((key: string) => key.indexOf('cluster:') > -1)
      .map((key: string) => {
        return key.substring(8)
      })
    state.checkedClusters = checkedKeys
    state.formData['device-upgrade'].clusterList = selectedClusters
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onWhenChange = (type: string) => {
    const state = { ...this.state }
    state.formData['device-upgrade'].when = type
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onDateChange = (date: any) => {
    const state = { ...this.state }
    state.formData['device-upgrade'].date = date
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onTimeChange = (time: any) => {
    const state = { ...this.state }
    state.formData['device-upgrade'].time = time
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onStartChange = (starts: any) => {
    const state = { ...this.state }
    if (moment(starts) < moment()) {
      starts = ''
    }
    state.formData['device-upgrade'].starts = starts
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onTimezoneChange = (timezone: any) => {
    const state = { ...this.state }
    state.formData['device-upgrade'].timezone = timezone
    const timezoneObj = JSON.parse(timezone)
    moment.tz.setDefault(timezoneObj.value)

    state.formData['device-upgrade'].starts = moment()
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  disabledDate(current: any) {
    return current && moment() >= current
  }

  getImage() {
    const { provider } = this.state
    return httpClient.get(`/hpcapi/v3/provider/${provider}/device-image/`)
  }

  getSystemImages() {
    return httpClient.get(`/hpcapi/v3/image-catalog/?published_status=Yes`)
  }

  getCluster() {
    const { provider } = this.state
    return httpClient.get(`/hpcapi/v3/provider/${provider}/cluster/`)
  }

  loadTimezones() {
    const timezoneResponse = this.DashboardService.getTimezones(
      null,
      null,
      null,
    )
    timezoneResponse
      .then((response: any) => {
        if (response?.data?.timezones && response.data.timezones.length > 0) {
          const timezoneStr = this.DashboardService.getDefaultBrowserTimeZone(
            response.data.timezones,
          )
          const { formData } = this.state
          formData['device-upgrade'].timezone = timezoneStr
          this.setState({ timezones: response.data.timezones, formData })
        }
      })
      .catch((error: any) => {
        const message = this.Utilities.returnErrorMessage(error)
        this.Utilities.showMessage('Unable to load timezones', 0, 0, message)
      })
  }

  getClusterDeviceMapping() {
    const { deviceLevelUpgrade } = this.props

    Promise.all([this.getSystemImages(), this.getImage()]).then(
      ([systemImagesRes, imageRes]) => {
        const deviceImageCatalogList = _.get(
          systemImagesRes,
          'data.device_image_catalog_list',
          [],
        )
        const deviceImageList =
          _.get(imageRes, 'data.device-image-list', [])?.filter(
            image => image['status'] === 'Uploaded',
          ) ?? []
        const imageList = deviceImageCatalogList.concat(deviceImageList)

        const defaultImage =
          imageList.length > 0
            ? imageList.filter(
                (image: IObject) => image.name === this.props.imageName,
              )[0]
            : null
        const { formData } = this.state
        formData['device-upgrade'].image = defaultImage
        this.setState({ imageList, formData })
        this.getCluster().then((result: IObject) => {
          const clusterList = result?.data?.['cluster-list'] || []
          const clusterDeviceTree = clusterList.reduce(
            (acc: IObject[], cluster: IObject) => {
              const { name, type } = cluster
              const upgradeCheck = deviceLevelUpgrade
                ? true
                : type === 'vcs' ||
                  type === 'single' ||
                  (type === 'multinode' && cluster['data-plane-ha'] === 'vrrpa')
              const deviceList = cluster?.['referrer-list'] || []

              if (deviceList.length && upgradeCheck) {
                acc.push({
                  key: `cluster:${name}`,
                  title: name,
                  disabled: !!deviceLevelUpgrade || cluster.type === 'vcs',
                  children: deviceList.map((device: IObject) => {
                    const { name } = device
                    return {
                      key: deviceLevelUpgrade
                        ? `device:${name}`
                        : deviceLevelUpgrade,
                      title: this.renderDeviceDetail(device),
                      isLeaf: true,
                      disabled: deviceLevelUpgrade ? false : true,
                    }
                  }),
                })
              }

              return acc
            },
            [],
          )

          this.setState({ clusterDeviceTree })
        })
      },
    )
  }

  renderDeviceDetail(device: IObject) {
    return (
      <A10Row type="flex" align="bottom">
        <A10Col className={styles.deviceName}>
          <span title={device.name}>{device.name}</span>
        </A10Col>
        <A10Col className={styles.host}>
          <A10Tag>{device.host}</A10Tag>
        </A10Col>
      </A10Row>
    )
  }

  renderDeviceTree = () => {
    const { clusterDeviceTree, checkedDevices } = this.state
    const loop = (data: IObject) =>
      data.map((item: IObject) => {
        if (item.children) {
          return (
            <A10Tree.TreeNode
              title={item.title}
              key={item.key}
              className={`${styles.clusterTreeNode}`}
            >
              {loop(item.children)}
            </A10Tree.TreeNode>
          )
        }
        return (
          <A10Tree.TreeNode
            title={item.title}
            key={item.key}
            className={`${styles.clusterTreeNode}`}
          />
        )
      })
    return (
      <>
        <A10Tree
          checkable={true}
          selectable={false}
          defaultExpandAll={true}
          checkedKeys={checkedDevices}
          onCheck={this.onCheckDevice}
          className={styles.clusterDeviceSelect}
        >
          {loop(clusterDeviceTree)}
        </A10Tree>
      </>
    )
  }

  renderClusterTree() {
    const { clusterDeviceTree, checkedClusters } = this.state
    const loop = (data: IObject) =>
      data.map((item: IObject) => {
        if (item.children) {
          return (
            <A10Tree.TreeNode
              title={item.title}
              key={item.key}
              className={`${styles.clusterTreeNode} ${
                item.disabled ? 'disabled' : ''
              }`}
              disableCheckbox={item.disabled}
            >
              {loop(item.children)}
            </A10Tree.TreeNode>
          )
        }
        return (
          <A10Tree.TreeNode
            title={item.title}
            key={item.key}
            className={`${styles.clusterTreeNode}`}
            checkable={false}
          />
        )
      })
    return (
      <>
        <A10Tree
          checkable={true}
          selectable={false}
          checkedKeys={checkedClusters}
          onCheck={this.onCheckCluster}
          className={styles.clusterDeviceSelect}
        >
          {loop(clusterDeviceTree)}
        </A10Tree>
      </>
    )
  }

  renderImageOptions() {
    const { formData, imageList } = this.state
    const onImageChange = this.onImageChange.bind(this)
    const defaultImageName = formData['device-upgrade'].image
      ? JSON.stringify(formData['device-upgrade'].image)
      : undefined
    const options = imageList.map(image => {
      return (
        <A10Select.Option
          value={JSON.stringify(image)}
          key={JSON.stringify(image)}
        >
          <span className={styles.textContainer}>{image.name}</span>
        </A10Select.Option>
      )
    })
    return (
      <A10Select
        placeholder="Please select image"
        onChange={onImageChange}
        value={defaultImageName}
      >
        {options}
      </A10Select>
    )
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }

    const { deviceLevelUpgrade } = this.props
    const {
      formData: {
        'device-upgrade': { reboot },
      },
    } = this.state

    return (
      <A10Form layout="horizontal">
        <A10Panel showHeader={false}>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="mandatoryField">Select an Image</span>
                <span>
                  <HelpInfo
                    placement="leftTop"
                    title="Select an Image"
                    helpKey="UPGRADE_SELECT_IMAGE"
                  />
                </span>
              </>
            }
          >
            {this.renderImageOptions()}
          </A10Form.Item>
          {deviceLevelUpgrade ? (
            // <A10Form.Item {...formItemLayout} label="Device">
            //   <A10Input
            //     disabled={true}
            //     value={this.state.formData['device-upgrade'].deviceName}
            //   />
            // </A10Form.Item>
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">Target Device(s)</span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title="Target Device(s)"
                      helpKey="HELP_UPGRADE_TARGET_DEVICES"
                    />
                  </span>
                </>
              }
            >
              <ClusterDeviceTree
                onChange={this.onCheckDevice}
                disableBladeDevice={true}
                treeHeight={200}
              />
            </A10Form.Item>
          ) : (
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span className="mandatoryField">Target Cluster(s)</span>
                  <span>
                    <HelpInfo
                      placement="leftTop"
                      title="Target Cluster(s)"
                      helpKey="UPGRADE_TARGET_CLUSTERS"
                    />
                  </span>
                </>
              }
            >
              {this.renderClusterTree()}
            </A10Form.Item>
          )}

          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>Drive</span>
                <span>
                  <HelpInfo
                    placement="leftTop"
                    title="Drive"
                    helpKey="UPGRADE_DRIVE"
                  />
                </span>
              </>
            }
          >
            <A10Row gutter={8}>
              <A10Radio.Group
                defaultValue="hd"
                value={this.state.formData['device-upgrade'].drive}
                onChange={this.onDriveChange}
              >
                <A10Col lg={10}>
                  <A10Radio value="hd">HD</A10Radio>
                </A10Col>
                <A10Col lg={10}>
                  <A10Radio value="cf">CF</A10Radio>
                </A10Col>
              </A10Radio.Group>
            </A10Row>
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>Disk</span>
                <span>
                  <HelpInfo
                    placement="leftTop"
                    title="Disk"
                    helpKey="UPGRADE_DISK"
                  />
                </span>
              </>
            }
          >
            <A10Row gutter={8}>
              <A10Radio.Group
                defaultValue="pri"
                value={this.state.formData['device-upgrade'].disk}
                onChange={this.onDiskChange}
              >
                <A10Col lg={10}>
                  <A10Radio value="pri">Primary</A10Radio>
                </A10Col>
                <A10Col lg={10}>
                  <A10Radio value="sec">Secondary</A10Radio>
                </A10Col>
              </A10Radio.Group>
            </A10Row>
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>Description</span>
                <span>
                  <HelpInfo
                    placement="leftTop"
                    title="Description"
                    helpKey="HELP_UPGRADE_DESCRIPTION"
                  />
                </span>
              </>
            }
          >
            <A10Input
              placeholder="Enter some description about this backup"
              maxLength={128}
              value={this.state.formData['device-upgrade'].description}
              onChange={this.onDescriptionChange}
            />
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>Save config before upgrade</span>
                <span>
                  <HelpInfo
                    placement="leftTop"
                    title="Save config before upgrade"
                    helpKey="UPGRADE_SAVE_BEFORE_UPGRADE"
                  />
                </span>
              </>
            }
          >
            <A10Switch defaultChecked onChange={this.onSaveConfigChange} />
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>Reboot after upgrade</span>
                <span>
                  <HelpInfo
                    placement="leftTop"
                    title="Reboot after upgrade"
                    helpKey="UPGRADE_REBOOT_AFTER_UPGRADE"
                  />
                </span>
              </>
            }
          >
            <A10Switch onChange={this.onRebootChange} checked={reboot} />
          </A10Form.Item>

          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="mandatoryField">When</span>
                <span>
                  <HelpInfo
                    placement="leftTop"
                    title="When"
                    helpKey="HELP_UPGRADE_WHEN"
                  />
                </span>
              </>
            }
          >
            <A10Select defaultValue="schedule" onChange={this.onWhenChange}>
              <A10Select.Option value="schedule">Schedule</A10Select.Option>
              <A10Select.Option value="now">Now</A10Select.Option>
            </A10Select>
          </A10Form.Item>

          {this.state.formData['device-upgrade'].when === 'schedule' ? (
            <>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    <span className="mandatoryField">Starts on</span>
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title="Starts on"
                        helpKey="HELP_UPGRADE_STARTS_ON"
                      />
                    </span>
                  </>
                }
              >
                {/* <A10DatePicker onChange={this.onDateChange} /> */}
                <A10DatePicker
                  format="YYYY-MM-DD HH:mm"
                  value={this.state.formData['device-upgrade'].starts}
                  showTime={{ format: 'HH:mm' }}
                  // disabledDate={(e: any) => this.disabledDate(e)}
                  onChange={this.onStartChange}
                />
              </A10Form.Item>
              {/* <A10Form.Item {...formItemLayout} label=" ">
                <A10TimePicker onChange={this.onTimeChange} />
              </A10Form.Item> */}

              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    <span className="mandatoryField">
                      {this.Messages.TIMEZONE}
                    </span>
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.TIMEZONE}
                        helpKey="HELP_UPGRADE_TIMEZONE"
                      />
                    </span>
                  </>
                }
              >
                <A10Select
                  placeholder="Please select a timezone"
                  onChange={this.onTimezoneChange}
                  value={this.state.formData['device-upgrade'].timezone}
                >
                  {this.state.timezones.map((option: any, i: number) => {
                    return (
                      <A10Select.Option
                        key={option.label}
                        value={JSON.stringify(option)}
                      >
                        {option.label}
                      </A10Select.Option>
                    )
                  })}
                </A10Select>
              </A10Form.Item>
            </>
          ) : null}
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(UpgradeForm)
