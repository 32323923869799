import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Form,
  A10Input,
  A10Select,
  A10Collapse,
  A10Table,
  A10Col,
  A10Row,
  A10Tooltip,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { RoundNumber } from 'src/components/shared/RoundNumber'
import { Messages } from 'src/locale/en/Messages'
import { InfrastructureService } from 'src/services/InfrastructureService/InfrastructureService'
import { IDefaultMethods } from 'src/containers/Controller'
import { DropdownConstants } from 'src/constants/DropdownConstants/DropdownConstants'
import { Configs } from 'src/constants/Configs'
import { DashboardService, Utilities } from 'src/services/index'
import storage from 'src/libraries/storage'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import { VMHostResourceTable } from 'src/containers/Controller/Infrastructure/VMDevices/Forms/VMHostResourceTable'

import './styles/vmdevicecreateform.scss'

export interface IVMDeviceCreateFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  device?: any
  vmDevices: any
  form: any
  formData?: any
  createFormData: any
  onRef?: any
  handleChange?: (data: any) => void
}
export interface IVMDeviceCreateFormState {
  device: any
  vmDevice: any
  dataCenterList: any[]
  vmWareClusterList: any[]
  selectedClusterKeys: any[]
  vmWareDataStoreList: any[]
  selectedStorageKeys: any
  vmWareFoldersList: any[]
  selectedFolderKeys: any
  vmWareDiskImageList: any[]
  selectedDiskImageKeys: any
  vmWareNetworksList: any[]
  searchString: string
  lists: any[]
  hcClusterSelect: string
}
// const RadioGroup = A10Radio.Group

class VMDeviceCreateForm extends A10Container<
  IVMDeviceCreateFormProps,
  IVMDeviceCreateFormState
> {
  Messages = new Messages()
  InfrastructureService = new InfrastructureService()
  DropdownConstants = new DropdownConstants()
  Configs = new Configs()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  lists: any[] = []
  vmWareClusterColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: 'td-truncate',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
      render: (text: any, record: any) => {
        return (
          <A10Tooltip title={text} placement="topLeft">
            {text}
          </A10Tooltip>
        )
      },
    },
    {
      title: 'HA Enabled',
      dataIndex: 'ha_enabled',
      key: 'ha_enabled',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'ha_enabled'),
      render: (record: any) => {
        return !record.ha_enabled ? 'false' : 'true'
      },
    },
    {
      title: 'DRS Enabled',
      dataIndex: 'drs_enabled',
      key: 'drs_enabled',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'drs_enabled'),
      render: (record: any) => {
        return !record.drs_enabled ? 'false' : 'true'
      },
    },
  ]

  vmWareStorageColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: 'td-truncate',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
      render: (text: any, record: any) => {
        return (
          <A10Tooltip title={text} placement="topLeft">
            {text}
          </A10Tooltip>
        )
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'type'),
    },
    {
      title: 'Capacity',
      dataIndex: 'capacity',
      key: 'capacity',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'capacity'),
      render: (capacity: any) => {
        return this.Utilities.truncateVal(capacity, 'bytes', 2)
      },
    },
    {
      title: 'Free',
      dataIndex: 'free_space',
      key: 'free_space',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'free_space'),
      render: (free_space: any) => {
        return this.Utilities.truncateVal(free_space, 'bytes', 2)
      },
    },
  ]
  folderTypeMap = {
    VIRTUAL_MACHINE: 'Virtual machine',
  }
  vmWareFoldersColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'type'),
      render: (folderType: any) => {
        return this.folderTypeMap[folderType]
          ? this.folderTypeMap[folderType]
          : folderType
      },
    },
  ]

  vmWareDiskImageColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: 'td-truncate',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
      render: (text: any, record: any) => {
        return (
          <A10Tooltip title={text} placement="topLeft">
            {text}
          </A10Tooltip>
        )
      },
    },
    {
      title: 'Created',
      dataIndex: 'creation_time',
      key: 'creation_time',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'creation_time'),
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'size'),
      render: (size: any) => {
        return this.Utilities.truncateVal(size, 'bytes', 2)
      },
    },
    {
      title: 'Cached',
      dataIndex: 'cached',
      key: 'cached',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'cached'),
      render: (cached: any) => {
        return cached ? 'Yes' : 'No'
      },
    },
  ]

  mainNetworks = [
    {
      name: 'Management/eth0',
    },
    {
      name: 'Ethernet1/eth1',
    },
    {
      name: 'Ethernet2/eth2',
    },
  ]

  constructor(props: IVMDeviceCreateFormProps) {
    super(props)
    const vmDevice: any = props.createFormData
    const device: any = props.formData
    this.state = {
      device,
      vmDevice,
      dataCenterList: [],
      vmWareClusterList: [],
      selectedClusterKeys: [],
      vmWareDataStoreList: [],
      selectedStorageKeys: [],
      vmWareFoldersList: [],
      selectedFolderKeys: [],
      vmWareDiskImageList: [],
      selectedDiskImageKeys: [],
      vmWareNetworksList: [],
      lists: [[]],
      hcClusterSelect: '',
    }
    this.loadVMWDataCenters(JSON.parse(device.credential))
  }

  getChangedValue = (e: any) => {
    let changedValue = ''
    if (e.target) {
      if (e.target.type === 'checkbox') {
        changedValue = e.target.checked
      } else {
        changedValue = e.target.value
      }
    } else {
      changedValue = e
    }
    return changedValue
  }
  handleChange = (stateName: string, e: any, index?: number) => {
    if (!this.Utilities.validateField(e)) {
      return
    }
    const changedValue = this.getChangedValue(e)
    const vmDeviceObj = this.state.vmDevice

    if (index !== undefined) {
      vmDeviceObj.vmNetworks[index][stateName] = changedValue
    } else {
      vmDeviceObj[stateName] = changedValue
    }

    if (stateName === 'datacenter') {
      this.loadVMWClusters(JSON.parse(vmDeviceObj['datacenter']))
      this.loadVMWDataStore(JSON.parse(vmDeviceObj['datacenter']))
      this.loadVMWFolders(JSON.parse(vmDeviceObj['datacenter']))
      this.loadVMWDiskImage()
      this.loadVMWNetworks(JSON.parse(vmDeviceObj['datacenter']))
    }

    this.props.handleChange(vmDeviceObj)
    // @ts-ignore
    this.setState({
      vmDevice: vmDeviceObj,
    })
  }

  loadVMWDataCenters = (credential: any) => {
    const vmDataCentersResponse = this.InfrastructureService.getVMWDataCenters(
      null,
      null,
      [credential.uuid],
    )
    vmDataCentersResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              dataCenterList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get datacenter details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadVMWClusters = (datacenter: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const vmClustersResponse = this.InfrastructureService.getVMWClusters(
      null,
      null,
      [credential.uuid, '?datacenter=' + datacenter.datacenter],
    )
    vmClustersResponse
      .then((response: any) => {
        let lists = []
        if (response.data && response.data.status === 'Success') {
          lists = response.data.load
          if (lists && lists.length > 0) {
            this.lists = lists
            this.setState({
              vmWareClusterList: lists,
              lists,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get cluster details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadVMWDataStore = (datacenter: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const vmDataStoreResponse = this.InfrastructureService.getVMWClusterDataStores(
      null,
      null,
      [credential.uuid, '?datacenter=' + datacenter.datacenter],
    )
    vmDataStoreResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              vmWareDataStoreList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get datastore details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadVMWFolders = (datacenter: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const vmFoldersResponse = this.InfrastructureService.getVMWFolders(
      null,
      null,
      [credential.uuid, '?datacenter=' + datacenter.datacenter],
    )
    vmFoldersResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              vmWareFoldersList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get virtual machine folder details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadVMWDiskImage = () => {
    const credential = JSON.parse(this.state.device.credential)
    const vmDiskImageResponse = this.InfrastructureService.getVMWClusterDiskImage(
      null,
      null,
      [credential.uuid],
    )
    vmDiskImageResponse
      .then((response: any) => {
        let list = [],
          innerList: any = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            list.map((listObj: any) => {
              let imageItems = listObj.items
              imageItems.map((itemObj: any) => {
                itemObj['libraryName'] = listObj.name
                innerList.push(itemObj)
              })
            })
            this.setState({
              vmWareDiskImageList: innerList,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get VM template details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadVMWNetworks = (datacenter: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const vmNetworksResponse = this.InfrastructureService.getVMWDCNetworks(
      null,
      null,
      [credential.uuid, '?datacenter=' + datacenter.datacenter],
    )
    vmNetworksResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          
          list = list.sort((a, b) => {
            return this.Utilities.sortString(a, b, 'name')
          })
          if (list && list.length > 0) {
            this.setState({
              vmWareNetworksList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get network details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  onSelectClusterChange = (selectedClusterKeys: any) => {
    const vmDeviceObj = this.state.vmDevice,
      index = selectedClusterKeys[0]
    vmDeviceObj.vmCluster = this.state.vmWareClusterList[index]
    this.setState({
      selectedClusterKeys,
      vmDevice: vmDeviceObj,
    })
  }

  handleHostResourceChange = (name: string, data: any) => {
    const vmDeviceObj = this.state.vmDevice
    if (name === 'host') {
      vmDeviceObj.vmHost = data
    } else if (name === 'resource') {
      vmDeviceObj.vmResourcePool = data
    }
    this.setState({ vmDevice: vmDeviceObj })
  }
  onSelectStorageChange = (selectedStorageKeys: any) => {
    const vmDeviceObj = this.state.vmDevice,
      index = selectedStorageKeys[0]
    vmDeviceObj.vmStorage = this.state.vmWareDataStoreList[index]
    this.setState({
      selectedStorageKeys,
      vmDevice: vmDeviceObj,
    })
  }
  onSelectFolderChange = (selectedFolderKeys: any) => {
    const vmDeviceObj = this.state.vmDevice,
      index = selectedFolderKeys[0]
    vmDeviceObj.vmFolder = this.state.vmWareFoldersList[index]
    this.setState({
      selectedFolderKeys,
      vmDevice: vmDeviceObj,
    })
  }
  onSelectDiskImageChange = (selectedDiskImageKeys: any) => {
    const vmDeviceObj = this.state.vmDevice,
      index = selectedDiskImageKeys[0]
    vmDeviceObj.vmDiskImage = this.state.vmWareDiskImageList[index]
    this.setState({
      selectedDiskImageKeys,
      vmDevice: vmDeviceObj,
    })
  }

  renderHostResourcePool = (record: any, index: number) => {
    return (
      <VMHostResourceTable
        credential={this.props.formData.credential}
        selectedCluster={this.state.vmDevice.vmCluster}
        handleHostResourceChange={this.handleHostResourceChange}
      />
    )
  }

  onClusterExpand = (expanded: boolean, record: any) => {
    const vmDeviceObj = this.state.vmDevice
    vmDeviceObj.vmCluster = record
    if (expanded) {
      this.setState({
        selectedClusterKeys: [record.key],
        vmDevice: vmDeviceObj,
      })
    }
  }

  SearchVMWareClusters = (e: any) => {
    const searchString = e.target.value
    this.Utilities.search(this, searchString, 'name', {
      storeData: [...this.lists],
      stateName: 'vmWareClusterList',
    })
  }

  validateDuplicateVMName = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const index = this.props.vmDevices.findIndex((key: any) => {
      return key.name === value
    })
    const userRegx = new RegExp(/^([a-zA-Z0-9._-]){1,50}$/)

    if (!value || '' === value) {
      rule.message = this.Messages.VM_NAME_REQ
      isValid = false
    } else if (!userRegx.test(value)) {
      rule.message = this.Messages.INVALID_VM_NAME
      isValid = false
    } else if (index > -1) {
      rule.message = this.Messages.VM_NAME_DUP
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const {
      selectedClusterKeys,
      selectedStorageKeys,
      selectedFolderKeys,
      selectedDiskImageKeys,
    } = this.state

    const clusterRowSelection = {
      type: 'radio',
      selectedClusterKeys,
      onChange: this.onSelectClusterChange,
    }
    const storageRowSelection = {
      type: 'radio',
      selectedStorageKeys,
      onChange: this.onSelectStorageChange,
    }
    const folderRowSelection = {
      type: 'radio',
      selectedFolderKeys,
      onChange: this.onSelectFolderChange,
    }
    const diskImageRowSelection = {
      type: 'radio',
      selectedDiskImageKeys,
      onChange: this.onSelectDiskImageChange,
    }

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <div className="margin-v-10">
          <A10Panel
            className="panelBodyPadding"
            title={
              <>
                <A10IconTextGroup
                  text={
                    <>
                      <span className="borderLeftData">Datacenter</span>
                      {/* <span>
                        <HelpInfo
                          placement="topLeft"
                          title="Datacenter"
                          helpKey="HELP_INFRA_VIRTUAL_DATACENTER"
                        />
                      </span> */}
                    </>
                  }
                  icon={
                    <A10Icon
                      className="destinationIcon"
                      app="gi-firewall"
                      type="destination"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 8 }}
              label={
                <>
                  <A10Icon
                    className="add-action"
                    app="harmony-controller"
                    type="license-manager"
                    id="iconVM"
                  />{' '}
                  <span className="col-md-10 dataCenter">Datacenter</span>
                  <span style={{ marginLeft: '-140px' }}>
                    <HelpInfo
                      customCls="dataCenter"
                      placement="topLeft"
                      title="Datacenter"
                      helpKey="HELP_INFRA_VIRTUAL_DATACENTER"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('datacenter', {
                rules: [
                  {
                    required: true,
                    message: 'Please select datacenter',
                  },
                ],
                //   initialValue: devices.credential,
              })(
                <A10Select
                  placeholder={'Select Datacenter'}
                  onChange={(e: any) => this.handleChange('datacenter', e)}
                >
                  {this.state.dataCenterList.map((obj: any, i: number) => {
                    return (
                      <A10Select.Option key={i} value={JSON.stringify(obj)}>
                        {obj.name}
                      </A10Select.Option>
                    )
                  })}
                </A10Select>,
              )}
            </A10Form.Item>

            <A10Form.Item
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 9 }}
              label={
                <>
                  <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                    VM Name
                  </span>
                  <span>
                    <HelpInfo
                      placement="topLeft"
                      title="VM Name"
                      helpKey="HELP_INFRA_VIRTUAL_VM_NAME"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('vmName', {
                rules: [{ validator: this.validateDuplicateVMName.bind(this) }],
              })(
                <A10Input
                  type="text"
                  placeholder={'Enter VM Device name'}
                  onChange={(e: any) => this.handleChange('vmName', e)}
                />,
              )}
            </A10Form.Item>
            {!!this.state.vmDevice.datacenter ? (
              <>
                {/* <A10Col className="searchBarVMCluster">
                  <div className="table-header table-header-inner">
                    <span className="vertical-split-divider verticalDivider" />
                    <div className="searchbar-container">
                      <A10Input.Search
                        type="text"
                        onChange={this.SearchVMWareClusters}
                        name="searchBox"
                        value={this.state.searchString}
                        placeholder="Search"
                      />
                    </div>
                  </div>
                </A10Col> */}

                <A10Collapse
                  bordered={false}
                  defaultActiveKey={['1']}
                  className="bottomBorderRem"
                >
                  <A10Collapse.Panel
                    key="1"
                    header={
                      <>
                        <A10Row
                          type="flex"
                          align="middle"
                          className="action-container"
                        >
                          <A10Col lg={24}>
                            <div className="table-header-inner pull-left">
                              <label className="page-header">
                                {
                                  <>
                                    VMware Cluster
                                    <span>
                                      <HelpInfo
                                        placement="topLeft"
                                        title="VMware Cluster"
                                        helpKey="HELP_INFRA_VIRTUAL_VM_CLUSTER_NAME"
                                      />
                                    </span>
                                  </>
                                }
                              </label>
                              <RoundNumber
                                number={this.state.vmWareClusterList.length}
                              />
                            </div>
                          </A10Col>
                        </A10Row>
                      </>
                    }
                  >
                    <A10Table
                      columns={this.vmWareClusterColumns}
                      rowSelection={clusterRowSelection}
                      expandedRowRender={this.renderHostResourcePool}
                      onExpand={this.onClusterExpand}
                      dataSource={this.state.vmWareClusterList.map(
                        (key: any, index: number) => {
                          key.key = index
                          return key
                        },
                      )}
                      scroll={{ x: '100%' }}
                      pagination={{ hideOnSinglePage: true, pageSize: 5 }}
                    />
                  </A10Collapse.Panel>
                </A10Collapse>
                <A10Collapse
                  bordered={false}
                  defaultActiveKey={['1']}
                  className="bottomBorderRem"
                >
                  <A10Collapse.Panel
                    key="1"
                    header={
                      <>
                        <label className="page-header">
                          {
                            <>
                              Storage
                              <span>
                                <HelpInfo
                                  customCls="custom-tooltip-storage"
                                  placement="topLeft"
                                  title="Storage"
                                  helpKey="HELP_INFRA_VIRTUAL_VM_STORAGE"
                                />
                              </span>
                            </>
                          }
                        </label>
                        <RoundNumber
                          number={this.state.vmWareDataStoreList.length}
                        />
                      </>
                    }
                  >
                    <A10Table
                      columns={this.vmWareStorageColumns}
                      rowSelection={storageRowSelection}
                      dataSource={this.state.vmWareDataStoreList.map(
                        (key: any, index: number) => {
                          key.key = index
                          return key
                        },
                      )}
                      scroll={{ x: '100%' }}
                      pagination={{ hideOnSinglePage: true, pageSize: 5 }}
                    />
                  </A10Collapse.Panel>
                </A10Collapse>
                <A10Collapse
                  bordered={false}
                  defaultActiveKey={['1']}
                  className="bottomBorderRem"
                >
                  <A10Collapse.Panel
                    key="1"
                    header={
                      <>
                        <label className="page-header">
                          {
                            <>
                              Virtual Machine Folders
                              <span>
                                <HelpInfo
                                  customCls="custom-tooltip-storage"
                                  placement="topLeft"
                                  title="Folders"
                                  helpKey="HELP_INFRA_VIRTUAL_VM_FOLDERS"
                                />
                              </span>
                            </>
                          }
                        </label>
                        <RoundNumber
                          number={this.state.vmWareFoldersList.length}
                        />
                      </>
                    }
                  >
                    <A10Table
                      columns={this.vmWareFoldersColumns}
                      rowSelection={folderRowSelection}
                      dataSource={this.state.vmWareFoldersList.map(
                        (key: any, index: number) => {
                          key.key = index
                          return key
                        },
                      )}
                      pagination={{ hideOnSinglePage: true, pageSize: 5 }}
                    />
                  </A10Collapse.Panel>
                </A10Collapse>
              </>
            ) : null}
          </A10Panel>
          {!!this.state.vmDevice.datacenter ? (
            <>
              <A10Panel
                className="panelBodyPadding"
                title={
                  <>
                    <A10IconTextGroup
                      text={
                        <>
                          <span className="borderLeftData">Disk Image</span>
                          {/* <span>
                            <HelpInfo
                              placement="topLeft"
                              title="Disk Image"
                              helpKey="HELP_INFRA_VIRTUAL_VM_DISK_IMAGE"
                            />
                          </span> */}
                        </>
                      }
                      icon={
                        <A10Icon
                          className="destinationIcon"
                          app="gi-firewall"
                          type="destination"
                        />
                      }
                    />
                  </>
                }
              >
                <div className="VMWareTemplate">
                  {
                    <>
                      VMware VM Templates
                      {/* <span>
                        <HelpInfo
                          placement="topLeft"
                          title="VMware VM Templates"
                          helpKey="HELP_INFRA_VIRTUAL_VM_TEMPLATES"
                        />
                      </span> */}
                    </>
                  }
                </div>
                <A10Table
                  columns={this.vmWareDiskImageColumns}
                  rowSelection={diskImageRowSelection}
                  dataSource={this.state.vmWareDiskImageList.map(
                    (key: any, index: number) => {
                      key.key = index
                      return key
                    },
                  )}
                  scroll={{ x: '100%' }}
                  pagination={{ hideOnSinglePage: true, pageSize: 5 }}
                />
              </A10Panel>
              <A10Panel
                className="panelBodyPadding"
                title={
                  <>
                    <A10IconTextGroup
                      text={
                        <>
                          <span className="borderLeftData">Deployment</span>
                          {/* <span>
                            <HelpInfo
                              placement="topLeft"
                              title="Deployment"
                              helpKey="HELP_INFRA_VIRTUAL_VM_DEPLOYMENT"
                            />
                          </span> */}
                        </>
                      }
                      icon={
                        <A10Icon
                          app="harmony-controller"
                          type="provider"
                          className="destinationIcon"
                        />
                      }
                    />
                  </>
                }
              >
                <div className="VMWareTemplate">
                  {
                    <>
                      Network Mapping
                      {/* <span>
                        <HelpInfo
                          placement="topLeft"
                          title="Network Mapping"
                          helpKey="HELP_INFRA_VIRTUAL_VM_NETWORK_MAPPING"
                        />
                      </span> */}
                    </>
                  }
                </div>
                <A10Table
                  dataSource={this.mainNetworks}
                  pagination={false}
                  scroll={{ x: '100%' }}
                >
                  <A10Col
                    title="Name"
                    dataIndex="name"
                    key="name"
                    sorter={(a: any, b: any) =>
                      this.Utilities.sortString(a, b, 'name')
                    }
                  />
                  <A10Col
                    title="Network"
                    key="network"
                    width="220px"
                    sorter={(a: any, b: any) =>
                      this.Utilities.sortString(a, b, 'network')
                    }
                    render={(text: string, record: any, index: number) => {
                      return (
                        <A10Form.Item>
                          {getFieldDecorator(`network[${index}]`, {
                            rules: [
                              {
                                required: true,
                                message: 'Select network',
                              },
                            ],
                          })(
                            <A10Select
                              placeholder={'Please select'}
                              className="networkDropdown"
                              dropdownClassName="networkDropdown"
                              size="small"
                              onChange={(e: any) =>
                                this.handleChange('network', e, index)
                              }
                            >
                              {this.Utilities.renderDropdownOpts(
                                this.state.vmWareNetworksList,
                                null,
                                'name',
                                null,
                                null,
                              )}
                            </A10Select>,
                          )}
                        </A10Form.Item>
                      )
                    }}
                  />
                  <A10Col
                    title="IP Address"
                    key="ip_address"
                    sorter={(a: any, b: any) =>
                      this.Utilities.sortNumber(a, b, 'ip_address')
                    }
                    render={(text: string, record: any, index: number) => {
                      return (
                        <A10Form.Item>
                          {getFieldDecorator(`ipAddr[${index}]`, {
                            validateTrigger: 'onBlur',
                            rules: [
                              {
                                validator: this.Utilities.validateVMDeviceIPs.bind(
                                  this,
                                  '',
                                ),
                              },
                            ],
                          })(
                            <A10Input
                              type="text"
                              placeholder={'IP Address'}
                              size="small"
                              onChange={(e: any) =>
                                this.handleChange('ipAddr', e, index)
                              }
                            />,
                          )}
                        </A10Form.Item>
                      )
                    }}
                  />
                  <A10Col
                    title="Netmask"
                    key="netmask"
                    sorter={(a: any, b: any) =>
                      this.Utilities.sortNumber(a, b, 'netmask')
                    }
                    render={(text: string, record: any, index: number) => {
                      return (
                        <A10Form.Item>
                          {getFieldDecorator(`netMask[${index}]`, {
                            validateTrigger: 'onBlur',
                            rules: [
                              {
                                validator: this.Utilities.validateVMDeviceIPs.bind(
                                  this,
                                  '',
                                ),
                              },
                            ],
                          })(
                            <A10Input
                              type="text"
                              placeholder={'Netmask'}
                              size="small"
                              onChange={(e: any) =>
                                this.handleChange('netMask', e, index)
                              }
                            />,
                          )}
                        </A10Form.Item>
                      )
                    }}
                  />
                  <A10Col
                    title="Gateway"
                    key="gateway"
                    sorter={(a: any, b: any) =>
                      this.Utilities.sortNumber(a, b, 'gateway')
                    }
                    render={(text: string, record: any, index: number) => {
                      return (
                        <A10Form.Item>
                          {getFieldDecorator(`gateway[${index}]`, {
                            validateTrigger: 'onBlur',
                            rules: [
                              {
                                validator: this.Utilities.validateVMDeviceIPs.bind(
                                  this,
                                  '',
                                ),
                              },
                            ],
                          })(
                            <A10Input
                              type="text"
                              placeholder={'Gateway'}
                              size="small"
                              onChange={(e: any) =>
                                this.handleChange('gateway', e, index)
                              }
                            />,
                          )}
                        </A10Form.Item>
                      )
                    }}
                  />
                </A10Table>
              </A10Panel>
            </>
          ) : null}
        </div>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(VMDeviceCreateForm))
