import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
  A10Context,
  getItem,
  setItem,
} from '@gui-libraries/framework'
import { A10Col, A10Input } from '@gui-libraries/widgets'

import SlidingLicense from './Forms/LicenseAddForm/SlidingLicense'
import LicenseList from 'src/containers/Controller/Organization/License/LicenseList/index'
import { InfrastructureService, Utilities } from 'src/services/index'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'

export interface ILicenseProps extends IA10ContainerDefaultProps {
  data: any[]
  leftNavAppsRefresh: () => void
}

export interface ILicenseState {
  searchString: string
  showSlidingPage: boolean
  licenseCount: number
  licenseList: any
  isLoading: boolean
  bulkAction: boolean
}
class License extends A10Container<ILicenseProps, ILicenseState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  InfrastructureService = new InfrastructureService()
  Utilities = new Utilities()
  isOperatorUser

  constructor(
    props: ILicenseProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: {
        get: { IS_OPERATOR_USER },
      },
    } = context

    this.isOperatorUser = IS_OPERATOR_USER
    this.state = {
      searchString: '',
      showSlidingPage: false,
      licenseCount: 0,
      isLoading: false,
      bulkAction: false,
      licenseList: [],
    }
  }

  componentDidMount() {
    this.getData()

    const addNew = getItem('ADD_NEW')
    if (addNew) {
      setItem('ADD_NEW', '')
      if (!this.isOperatorUser) {
        this.addLicense()
      }
    }
  }

  // componentDidUpdate() {
  //   if (!this.dataloaded) {
  //     this.loadLicenseInfo()
  //   }
  // }

  getData = () => {
    const {
      storage: {
        get: { PROVIDER },
        set,
      },
    } = this.context

    // Start to load data
    this.setState({ isLoading: true })
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    httpRequest({
      namespace: getNS('LICENSE_LIST'),
      request: async (epicDependence: IObject) => {
        try {
          const { httpClient } = epicDependence
          const { data: res } = await httpClient.get(
            `/hls/licenses/providers/${PROVIDER}`,
            {
              absoluteBasePath: true,
            },
          )
          const licenseList = res || []
          set.ALL_LICENSE(licenseList)
          this.setState({
            isLoading: false,
            licenseList,
            licenseCount: licenseList.length,
          })
          return licenseList
        } catch (err) {
          // tslint:disable-next-line:no-console
          console.error(err)
        }
        this.setState({ isLoading: false })
        return []
      },
    })
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({
      showSlidingPage: isOpen,
    })
  }

  showImageSlidingPage = () => {
    this.setState({ showSlidingPage: true })
  }

  closeImageSlidingPage = () => {
    this.setState({ showSlidingPage: false })
    this.getData()
    // Get Data Call here
  }

  SearchLicenses = (e: any) => {
    const searchString = e.target.value
    this.Utilities.search(
      this,
      searchString,
      {
        firstKey: 'entitlement-key',
      },
      {
        storageName: 'ALL_LICENSE',
        stateName: 'licenseList',
      },
    )
  }

  addLicense = () => {
    this.setState({
      showSlidingPage: true,
    })
  }

  glmLicTypeText = (glmLicType: string, licType: string) => {
    switch (glmLicType) {
      case 'adc_cap_sub':
      case 'adc_cap_burst_sub':
        return 'ADC Subscription (Capacity)'

      case 'cgn_cap_sub':
      case 'cgn_cap_burst_sub':
        return 'CGN Subscription (Capacity)'

      case 'cfw_cap_sub':
      case 'cfw_cap_burst_sub':
        return 'CFW Subscription (Capacity)'

      case 'adc_inst_sub':
      case 'adc_inst_burst_sub':
        return 'ADC Subscription (Instance)'

      case 'cgn_inst_sub':
      case 'cgn_inst_burst_sub':
        return 'CGN Subscription (Instance)'

      case 'cfw_inst_sub':
      case 'cfw_inst_burst_sub':
        return 'CFW Subscription (Instance)'

      case 'adc_inst_perpet':
        return 'ADC Perpetual (Instance)'

      case 'cgn_inst_perpet':
        return 'CGN Perpetual (Instance)'

      case 'cfw_inst_perpet':
        return 'CFW Perpetual (Instance)'

      case 'cgn_subscriber_sub':
        return 'CGN Subscriber'

      case 'cgn_subscriber_perpet':
        return 'CGN Subscriber'

      default:
        return licType
    }
  }

  render() {
    const {
      storage: {
        get: { ADMIN_LEVEL },
      },
    } = this.context

    const { showSlidingPage, isLoading } = this.state
    return (
      <>
        <ContentSection>
          <ContentHeader type="flex" align="middle" justify="space-between">
            <A10Col>
              <ContentTitle
                title="Licenses"
                count={this.state.licenseList.length}
              />
            </A10Col>
            <A10Col style={{ display: 'flex' }}>
              <A10Input.Search
                type="text"
                onChange={this.SearchLicenses}
                name="searchBox"
                value={this.state.searchString}
                placeholder="Search"
                style={{ marginRight: '6px' }}
              />
              <ActionButton
                text="Refresh"
                onClick={this.getData}
                iconProps={{ app: 'global', type: 'refresh' }}
              />
              {this.isOperatorUser ? null : (
                <ActionButton
                  text="Add License"
                  onClick={this.addLicense}
                  iconProps={{ app: 'global', type: 'add-new' }}
                />
              )}
            </A10Col>
          </ContentHeader>
          <ContentBody>
            <LicenseList
              isLoading={isLoading}
              updateList={this.getData}
              licenseList={this.state.licenseList}
              glmLicTypeText={this.glmLicTypeText}
              leftNavAppsRefresh={this.props.leftNavAppsRefresh}
            />
          </ContentBody>
        </ContentSection>
        <SlidingLicense
          isOpen={showSlidingPage}
          onRequestClose={this.closeImageSlidingPage}
          glmLicTypeText={this.glmLicTypeText}
          leftNavAppsRefresh={this.props.leftNavAppsRefresh}
        />
      </>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    data: state.A10Data.getIn(getNS('LICENSE_LIST'), []),
  }
}

export default setupA10Container(License, mapStateToProps)
