import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import SparcLine from 'src/components/ADC/SparcLine'

// tslint:disable-next-line:no-var-requires
// const styles = require('./styles/index.module.less')

export interface ISparcLineWrapperProps extends IA10ContainerDefaultProps {
  chartDataSeries: any
  seriesName: any
  chartConfig: any
}

export interface ISparcLineWrapperState {}

class SparcLineWrapper extends A10Container<
  ISparcLineWrapperProps,
  ISparcLineWrapperState
> {
  constructor(props: ISparcLineWrapperProps) {
    super(props)
  }

  shouldComponentUpdate(
    nextProps: ISparcLineWrapperProps,
    nextState: ISparcLineWrapperState,
  ) {
    if (this.props.chartDataSeries != nextProps.chartDataSeries) {
      return true
    }
    return false
  }

  render() {
    const { chartDataSeries, seriesName, chartConfig } = this.props
    return (
      <SparcLine
        seriesData={chartDataSeries}
        seriesName={seriesName}
        chartConfig={chartConfig}
      />
    )
  }
}

export default setupA10Container(SparcLineWrapper)
