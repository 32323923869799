import React from 'react'
import propTypes from 'prop-types'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import {
  A10Col,
  A10Input,
} from '@gui-libraries/widgets'

import AccessGroupList from './AccessGroupList'
import SlidingAccessGroup from './AccessGroupForm/SlidingAccessGroup'
import { OrganizationService, AuthenticationService } from 'src/services'
import { getDeviceClusterMap } from 'src/redux/httpServices/Controller/device'
import { getAccessGroupUsersMap } from 'src/redux/httpServices/Controller/roles'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import storage from 'src/libraries/storage'

import styles from './styles/index.module.less'
import { data } from '../../TroubleShoot/ScaleoutTraffic/mockData'

export interface IAccessGroupProps extends IA10ContainerDefaultProps { }

export interface IAccessGroupState {
  accessGroupList: any[]
  showSlidingPage: boolean
  isLoading: boolean
  search: string
  isRemote: boolean
  accessroleList: any[]
  authResponse: IObject[]
}

class AccessGroup extends A10Container<IAccessGroupProps, IAccessGroupState> {
  static contextTypes = {
    openSlidingPage: propTypes.func.isRequired,
    setModalSize: propTypes.func,
  }

  private authenticationService = new AuthenticationService()
  OrganizationService = new OrganizationService()
  delayedSearchText = _.debounce(searchString => {
    this.searchData(searchString)
  }, 800)
  accessGroupList: any[] = []
  isOperatorUser = storage.get.IS_OPERATOR_USER
  CONST_ACCESS_GROUP_LABLE = 'Harmony Access Groups'

  constructor(props: IAccessGroupProps) {
    super(props)
    this.state = {
      accessGroupList: [],
      showSlidingPage: false,
      isLoading: false,
      search: '',
      isRemote: false,
      accessroleList: [],
      authResponse: [],
    }
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({ showSlidingPage: isOpen })
  }

  onFormSubmitted = () => {
    this.setSlidingPage(false)
    this.getData()
    this.getAuthType()
  }

  componentDidMount() {
    this.getData()
    this.getAuthType()
  }

  getAuthType = () => {
    const provider = storage.get.PROVIDER || 'root'

    this.authenticationService
      .getAuthProvider({ provider }, null, [provider])
      .then((response: any) => {
        const authProvider = _.get(response, [
          'data',
          'authentication-provider',
        ])
        if (authProvider) {
          const remoteAuthorization = authProvider['remote-authorization']

          this.setState({
            isRemote: remoteAuthorization,
            authResponse: authProvider,
          })
        }
      })
      .catch(error => {
        this.setState({ isLoading: false })
      })
  }


  searchData = (search: string = '') => {
    const accessGroupList = this.accessGroupList
      ? this.accessGroupList.filter((accessGroup: any) => {
        return (
          accessGroup?.name?.indexOf(search) > -1 ||
          accessGroup?.displayName?.indexOf(search) > -1
        )
      })
      : []
    this.setState({ accessGroupList })
  }

  getData = (search: string = '') => {
    // Start to load data
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    this.setState({ isLoading: true })
    const getAGListReq = this.OrganizationService.getRbacAccessGroups(
      null,
      null,
      [storage.get.PROVIDER],
    )
    getAGListReq
      .then((resp: any) => {
        const accessGroupList = resp && resp.data ? resp.data : []
        this.accessGroupList = accessGroupList
        this.setState({ accessGroupList, isLoading: false })
      })
      .catch((error: any) => {
        // Error in get access group API
        this.setState({ isLoading: false })
      })
    const getRoleListReq = this.OrganizationService.getRbacRoleList(
      null,
      null,
      [storage.get.PROVIDER],
    )
    getRoleListReq
      .then((resp: any) => {
        const roleList = resp && resp.data ? resp.data : []
        const roleSet: Set<string> = new Set()
        const accessRoleList = []
        for (const role of roleList) {
          roleSet.add(role.name)
          if (role.name !== 'hc_app_admin' && role.name !== 'hc_app_operator') {
            accessRoleList.push(role)
          }
        }
        this.setState({
          accessroleList: accessRoleList,
        })
      })
      .catch((error: any) => {
        // Error in get role API
      })
    httpRequest(getDeviceClusterMap())
    httpRequest(getAccessGroupUsersMap())
  }

  onCloseSlidingPage = () => {
    this.setSlidingPage(false)
  }

  onSearchText = (e: any) => {
    const searchString = e.target.value
    this.setState({ search: searchString }, () => {
      this.delayedSearchText(searchString)
    })
  }

  render() {
    const {
      isLoading,
      isRemote,
      search,
      showSlidingPage,
      accessGroupList,
      authResponse,
    } = this.state
    return (
      <>
        <ContentSection>
          <ContentHeader type="flex" align="middle" justify="space-between">
            <A10Col>
              <ContentTitle
                title={this.CONST_ACCESS_GROUP_LABLE}
                count={accessGroupList.length}
              />
            </A10Col>
            <A10Col className={styles.actionsContainer}>
              <A10Input.Search
                type="text"
                onChange={this.onSearchText}
                name="searchBox"
                value={this.state.search}
                placeholder="Search"
                className={styles.searchInput}
              />
              {this.isOperatorUser ? null : (
                <ActionButton
                  text="Add Access Group"
                  onClick={this.setSlidingPage.bind(this, true)}
                  iconProps={{ app: 'global', type: 'add-new' }}
                />
              )}
            </A10Col>
          </ContentHeader>
          <ContentBody>
            <AccessGroupList
              updateList={this.getData}
              isLoading={isLoading}
              data={this.state.accessGroupList}
              search={search}
              authResponse={authResponse}
            />
          </ContentBody>
        </ContentSection>
        <SlidingAccessGroup
          isOpen={showSlidingPage}
          onRequestClose={this.onFormSubmitted}
          accessroleList={this.state.accessroleList}
          data={this.state.accessGroupList}
          authResponse={authResponse}
        />
      </>
    )
  }
}

export default setupA10Container(AccessGroup)
