import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Row, A10Layout, A10Icon } from '@gui-libraries/widgets'

import { LeftNavMenu } from './LeftNavMenu'

import styles from './styles/index.module.less'

export interface ILeftNavProps extends IA10ContainerDefaultProps {
  applicationName: string
  subApplicationName: string
  selectedTenant: any
  tenantChange(): void
  refreshTenants: boolean
  refreshHCApps: boolean
  tenantToggled: boolean
  leftNavCollapsed: boolean
  toggleLeftNav(): void
  headerStyle: string
}

export interface ILeftNavState {
  applicationName: string
  subApplicationName: string
  tenantToggled: boolean
  refreshTenants: boolean
  leftNavCollapsed: boolean
}

class LeftNav extends A10Container<ILeftNavProps, ILeftNavState> {
  static getDerivedStateFromProps(
    nextProps: ILeftNavProps,
    prevState: ILeftNavState,
  ) {
    const {
      applicationName: nextApplicationName,
      subApplicationName: nextSubApplicationName,
      refreshTenants: nextRefreshTenants,
      leftNavCollapsed: nextLeftNavCollapsed,
    } = nextProps
    const {
      applicationName: prevApplicationName,
      subApplicationName: prevSubApplicationName,
      refreshTenants: prevRefreshTenants,
      leftNavCollapsed: prevLeftNavCollapsed,
      ...rest
    } = prevState

    let refreshTenants = prevRefreshTenants
    if (prevRefreshTenants !== nextRefreshTenants) {
      refreshTenants = nextRefreshTenants
    }
    let applicationName = prevApplicationName
    if (prevApplicationName !== nextApplicationName) {
      applicationName = nextApplicationName
    }
    let subApplicationName = prevSubApplicationName
    if (prevSubApplicationName !== nextSubApplicationName) {
      subApplicationName = nextSubApplicationName
    }
    let leftNavCollapsed = prevLeftNavCollapsed
    if (prevLeftNavCollapsed !== nextLeftNavCollapsed) {
      leftNavCollapsed = nextLeftNavCollapsed
    }

    return {
      applicationName,
      subApplicationName,
      refreshTenants,
      leftNavCollapsed,
      ...rest,
    }
  }

  constructor(props: ILeftNavProps) {
    super(props)

    const {
      applicationName,
      subApplicationName,
      refreshTenants,
      leftNavCollapsed,
    } = props

    this.state = {
      applicationName,
      subApplicationName,
      tenantToggled: false,
      refreshTenants,
      leftNavCollapsed,
    }
  }

  renderLeftNavHeader() {
    const { leftNavCollapsed } = this.state
    return (
      <>
        <A10Row className={styles.collapseTogglerContainer}>
          {leftNavCollapsed ? (
            <A10Icon
              app="navigation-icons"
              type="hamburger-open"
              onClick={this.props.toggleLeftNav}
            />
          ) : (
            <A10Icon
              app="navigation-icons"
              type="hamburger-close"
              onClick={this.props.toggleLeftNav}
            />
          )}
        </A10Row>
        <A10Row className={styles.brandContainer}>
          <div className={styles.a10Logo} />
          <A10Icon app="app-icons" type="harmony-controller-light" />
          <div className={styles.divider} />
          <div className={styles.productName}>Harmony Controller</div>
        </A10Row>
      </>
    )
  }

  render() {
    const { leftNavCollapsed } = this.state
    return (
      <>
        <A10Layout.Sider
          trigger={null}
          collapsible
          collapsed={leftNavCollapsed}
          className={`${styles.siderSection}${
            leftNavCollapsed ? ' sider-collpased' : ' sider-expanded'
          }`}
        >
          <div className={styles.leftNavHeaderContainer}>
            {this.renderLeftNavHeader()}
          </div>
          <div className={styles.leftNavMenuContainer}>
            <LeftNavMenu
              applicationName={this.state.applicationName}
              subApplicationName={this.state.subApplicationName}
              selectedTenant={this.props.selectedTenant}
              tenantChange={this.props.tenantChange}
              tenantToggled={this.props.tenantToggled}
              leftNavCollapsed={leftNavCollapsed}
              refreshHCApps={this.props.refreshHCApps}
            />
          </div>
        </A10Layout.Sider>
      </>
    )
  }
}

export default setupA10Container(LeftNav)
