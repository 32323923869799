import React from 'react'
import { A10Icon } from '@gui-libraries/widgets'

const successIconStyle = { fontSize: 32, marginRight: 7.5 }
const gettingStartedStyle = { width: 22.9, height: 38.3, marginRight: 5.25 }
export interface IPreCheckProps {
  isSuperAdmin: boolean
}
const PreCheck = (props: IPreCheckProps) => {
  const renderNonSuperContent = () => {
    return (
      <>
        <div>
          <A10Icon
            app="harmony-controller"
            type="organization"
            style={successIconStyle}
          />
          Super-Admin Overview
        </div>
        <div>
          <A10Icon app="global" type="asterisk" style={successIconStyle} />
          Navigation
        </div>
      </>
    )
  }
  const renderSuperContent = () => {
    return (
      <>
        <div>
          <A10Icon
            app="harmony-controller"
            type="organization"
            style={successIconStyle}
          />
          Set up Organization
        </div>
        <div>
          <A10Icon
            app="harmony-controller"
            type="network"
            style={successIconStyle}
          />
          Set up Infrastructure & App Catalog
        </div>
        <div>
          <A10Icon
            app="harmony-controller"
            type="app-service"
            style={successIconStyle}
          />
          Manage Application Services
        </div>
        <div>
          <A10Icon app="global" type="asterisk" style={successIconStyle} />
          Navigation
        </div>
      </>
    )
  }
  return (
    <div id="gettingStarted-1" className="gettingStarted-content-active">
      <div className="gettingStarted-steps">
        <div>Steps:</div>
        <div>
          <A10Icon app="global" type="success" style={successIconStyle} />
          Pre-Checks
        </div>
        {props.isSuperAdmin ? renderSuperContent() : renderNonSuperContent()}
      </div>
      <div className="gettingStarted-preChecks">
        <div className="gettingStarted-title">
          <A10Icon
            app="global"
            type="GettingStarted"
            style={gettingStartedStyle}
          />
          <div>Get Started</div>
        </div>
        <div className="gettingStarted-content">
          <div className="preCheck-title">Pre-Checks</div>
          <div className="preCheck-step">
            <i className="stepOne" />
            <div className="preCheck-step-content">
              <div className="preCheck-title-step">
                Network Connectivity (Required)
              </div>
              <div className="preCheck-title-detail">
                Check that Thunder and Controller are able to reach out to each
                other on all the ports specified in the installation documents.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreCheck
