import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Form,
  A10Input,
  A10Select,
  A10Radio,
  A10Switch,
  A10InputNumber,
  A10Alert,
  A10Row,
  A10Col,
  A10Notification,
} from '@gui-libraries/widgets'
import { DISPLAY_UNIT, MEASURED_UNIT } from '@gui-libraries/viz'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { IDefaultMethods } from 'src/containers/Controller'
import { Messages } from 'src/locale/en'
import { DashboardService } from 'src/services/DashboardService'
import { DropdownConstants } from 'src/constants/DropdownConstants/DropdownConstants'
import { Utilities } from 'src/services/Utilities'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import storage from 'src/libraries/storage'

import './styles/triggeraddform.scss'

const APP_SERVICE = 'APP SERVICE'
const INFRASTRUCTURE = 'INFRASTRUCTURE'
const SYSTEM = 'SYSTEM'

export interface ITriggerAddFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  trigger?: any
  onRef?: any
  triggers: any
  form: any
  oncancel(e?: React.MouseEvent<HTMLInputElement>): void
  onTenantUpdate(): void
  handleChange?: (data: any, isSaveBtn: boolean) => void
}

export interface ITriggerMetaData {
  Trig_display_name: string
  Units: IObject[] | IObject
  Display_unit?: string
}

export interface ITriggerCondition {
  condition: string
  metricAgg: string
  params: IObject[] | string
  operation: string
  operator: string
  metrics: any['1']
  value: any
  weight: any
  sample_count: any
  percent: boolean
  metricAggPercent: string
  paramsPercent: IObject[]
  expressions: string
  expression_condition: string
}
export interface ITriggerAddFormState {
  triggerName: string
  categoryType: string
  subcategoryType: string
  objectType: string
  windowType: string
  triggerOn: string
  windowSize: number
  windowSizeType: string
  maxSamples: boolean
  windowSample: number
  expression: boolean
  windowTimeParam: string
  metricsType: string
  metadata: ITriggerMetaData
  conditions: ITriggerCondition[]
  triggerMessage: string
  categoriesLoaded: boolean
  objectTypeLoaded: boolean
  paramsList: any[]
  metricsList: any[]
  isDataProcessed: boolean
  selectedCondition: string
  isMetricsFailed: boolean
  streams: any
}
export interface ITriggerObj {
  triggerName: string
}

class TriggerAddForm extends A10Container<
  ITriggerAddFormProps,
  ITriggerAddFormState
> {
  Messages = new Messages()
  DashboardService = new DashboardService()
  DropdownConstants = new DropdownConstants()
  Utilities = new Utilities()
  triggerObj = {} as ITriggerObj
  categories: any[] = []
  treeData: any
  excludedList = [
    'account_id',
    'cluster_id',
    'device_uuid',
    'p_id',
    'h_ver',
    'p_ver',
    'o_uuid',
    'app_svc_id',
    'msg_id',
  ]
  // slbSubStringList = {
  //   'HTTP protocol': ['REQ_', 'jsi_', 'status_', 'ws_'],
  // }

  metricsExcludedList = {
    'Partition Logical Cluster': {
      Resource: ['data_cpu_usage'],
    },
    'Cluster Device': {
      Resource: ['cpu_usage_overall'],
    },
  }
  LADCObj = {
    'APP SERVICES': {
      ADC: {
        Application: {
          HOST: 'HOST',
        },
        Host: {
          HOST: 'HOST',
        },
        Service: {
          SERVICE: 'SERVICE',
        },
        Smartflow: {
          FLOW: 'FLOW',
        },
        WAF: {
          FLOW: 'FLOW',
        },
        'Server Group': {
          SERVER: 'SERVER',
        },
        Server: {
          SERVER: 'SERVER',
        },
      },

      TCP: {
        'Service Group': {
          TCP_SERVICE: 'TCP_SERVICE',
        },
        Server: {
          TCP_SERVER: 'TCP_SERVER',
        },
      },
    },
    INFRASTRUCTURE: {
      Cluster: {
        Resource: {
          PEP: 'PEP',
        },
        Traffic: {
          PEP: 'PEP',
        },
      },
      'Lightning Device': {
        Resource: {
          PEP: 'PEP',
        },
        Traffic: {
          PEP: 'PEP',
        },
      },
    },
  }

  conditionBackup = [
    {
      condition: this.Utilities.getDropdownDefault('condition', 'TRIGGERS'),
      metricAgg: this.Utilities.getDropdownDefault('metricAgg', 'TRIGGERS'),
      params: [] as IObject[],
      metrics: [1],
      operation: this.Utilities.getDropdownDefault('operation', 'TRIGGERS'),
      operator: this.Utilities.getDropdownDefault('operator', 'TRIGGERS'),
      value: 60,
      weight: '',
      sample_count: '',
      percent: false,
      metricAggPercent: this.Utilities.getDropdownDefault(
        'metricAgg',
        'TRIGGERS',
      ),
      paramsPercent: [] as IObject[],
      expressions: '',
      expression_condition: '',
    },
  ]
  anotherMetric: any
  constructor(props: ITriggerAddFormProps) {
    super(props)
    this.state = {
      triggerName: this.props.trigger.triggerName,
      categoryType: this.props.trigger.categoryType,
      subcategoryType: this.props.trigger.subcategoryType,
      objectType: this.props.trigger.objectType,
      windowType: this.props.trigger.windowType,
      triggerOn: this.props.trigger.triggerOn,
      windowSize: this.props.trigger.windowSize,
      windowSizeType: this.props.trigger.windowSizeType,
      maxSamples: this.props.trigger.maxSamples,
      windowSample: this.props.trigger.windowSample,
      expression: false,
      windowTimeParam: 'min',
      metricsType: this.props.trigger.metricType,
      triggerMessage: this.props.trigger.triggerMessage,
      paramsList: [],
      metricsList: [],
      streams: this.props.trigger?.definition?.streams,
      metadata: {
        Trig_display_name: this.props.trigger.triggerName,
        Units: [
          {
            Measured_unit: '',
            Display_unit: '',
          },
        ],
      },
      conditions: [
        {
          condition: this.Utilities.getDropdownDefault('condition', 'TRIGGERS'),
          metricAgg: this.Utilities.getDropdownDefault('metricAgg', 'TRIGGERS'),
          metrics: ['1'],
          params: [],
          operation: this.Utilities.getDropdownDefault('operation', 'TRIGGERS'),
          operator: this.Utilities.getDropdownDefault('operator', 'TRIGGERS'),
          value: 60,
          weight: '',
          sample_count: '',
          percent: false,
          metricAggPercent: this.Utilities.getDropdownDefault(
            'metricAgg',
            'TRIGGERS',
          ),
          paramsPercent: [],
          expressions: '',
          expression_condition: '',
        },
      ],
      categoriesLoaded: false,
      objectTypeLoaded: false,
      isDataProcessed: false,
      selectedCondition: 'and',
      isMetricsFailed: false,
    }

    // Wait till slider opens up
    setTimeout(() => {
      this.prefillForm()
    }, 1000)

    this.getObjectType()
  }

  prefillForm = () => {
    const { trigger } = this.props
    if (trigger && trigger.formMode) {
      const conditions = this.parseConditions(trigger.definition.conditions)
      const metadata = trigger.metadata
      this.setState({
        conditions,
        metadata,
      })
    }
  }

  parseConditions = (condition: any) => {
    const conditions: ITriggerCondition[] = []
    if (!!condition.expressions || !!condition.expression_condition) {
      const metricArr: any = []
      const params = condition.params
      if (params) {
        params.forEach((value: any, key: number) => {
          metricArr[key] = key + 1
        })
      }
      conditions.push({
        condition: this.Utilities.getDropdownDefault('condition', 'TRIGGERS'),
        metricAgg: this.Utilities.getDropdownDefault('metricAgg', 'TRIGGERS'),
        metrics: metricArr,
        params,
        operation: this.Utilities.getDropdownDefault('operation', 'TRIGGERS'),
        operator: this.Utilities.getDropdownDefault('operator', 'TRIGGERS'),
        value: 60,
        weight: '',
        sample_count: '',
        percent: false,
        metricAggPercent: this.Utilities.getDropdownDefault(
          'metricAgg',
          'TRIGGERS',
        ),
        paramsPercent: [],
        expressions: '"' + JSON.stringify(condition.expressions) + '"',
        expression_condition: condition.expression_condition,
      })
      this.setState({ expression: true })
      // this.props.form.setFieldsValue({
      //   'expression-0': true
      // })
    } else {
      const conditionType = condition.and ? 'and' : 'or'
      this.setState({ selectedCondition: conditionType })
      const keys = Object.keys(condition[conditionType])
      keys.forEach((key: string, i: number) => {
        const conditionObj = condition[conditionType][key]
        const metricArr: any = []
        const params = conditionObj.params
        if (params) {
          params.forEach((v: any, k: number) => {
            metricArr[k] = k + 1
          })
        }
        conditions.push({
          metricAgg: conditionObj.aggregation,
          operation: conditionObj.operation,
          operator: conditionObj.operator,
          params,
          metrics: metricArr,
          condition: conditionType,
          value: conditionObj.value,
          weight: conditionObj.weight
            ? conditionObj.weight
            : conditionObj.weights && conditionObj.weights.length > 0
            ? conditionObj.weights.join(',')
            : '',
          sample_count: conditionObj.sample_count,
          percent: conditionObj.percent,
          metricAggPercent: conditionObj.metricAggPercent,
          paramsPercent: conditionObj.paramsPercent,
          expressions: '',
          expression_condition: '',
        })
      })
    }
    return conditions
  }

  getSafe(fn: any, defaultVal: any) {
    try {
      return fn()
    } catch (e) {
      return defaultVal
    }
  }

  objectTypeChange = (
    stateName: string,
    value: any,
    isChangeObjectType?: boolean,
  ) => {
    if (isChangeObjectType) {
      this.handleChange('objectType', value)
    }
    const metricObj = this.treeData[this.state.categoryType].children[
      this.state.subcategoryType
    ].children[value]
    const { categoryType, subcategoryType, objectType } = this.state
    const valueTemp = this.getSafe(
      () =>
        this.LADCObj[categoryType][subcategoryType][objectType][
          metricObj.metricsType
        ],
      'nothing',
    )
    if (valueTemp !== 'nothing') {
      // LADC Metrics Call
      this.getSchemaRegistry(value, true)
    } else {
      this.getSchemaRegistry(value)
    }
  }

  getSchemaRegistry = (objectType: any, isLADC?: boolean) => {
    this.setState({ isMetricsFailed: false })
    objectType = this.treeData[this.state.categoryType].children[
      this.state.subcategoryType
    ].children[objectType]
    const schemaRegistryResponse = this.DashboardService.getSchemaRegistry(
      null,
      null,
      [objectType.metricsType],
      isLADC ? isLADC : false,
    )
    schemaRegistryResponse
      .then((resp: any) => {
        const schema = JSON.parse(resp.data.schema)
        const objectValue = JSON.parse(objectType.value)
        let paramsList = []
        if (schema.fields.length > 0) {
          // Temporary removal of 'ts' field & 'string' & default headers datatype fields
          paramsList = schema.fields.filter((field: any) => {
            if (
              objectValue.subCategory &&
              objectValue.objectType &&
              this.metricsExcludedList[objectValue.subCategory] &&
              this.metricsExcludedList[objectValue.subCategory][
                objectValue.objectType
              ]
            ) {
              return (
                field.name !== 'ts' &&
                this.excludedList.indexOf(field.name) === -1 &&
                this.metricsExcludedList[objectValue.subCategory][
                  objectValue.objectType
                ].indexOf(field.name) === -1
              )
            } else {
              return (
                field.name !== 'ts' &&
                this.excludedList.indexOf(field.name) === -1
              )
            }
          })
        }
        const metricsList = JSON.parse(JSON.stringify(paramsList))
        // Check Trigger on value and filter metrics
        if (this.state.triggerOn === 'string') {
          paramsList = paramsList.filter((field: any) => {
            if (Array.isArray(field.type)) {
              return field.type.indexOf('string') > -1
            } else if (field.type === 'string') {
              return true
            }
            return false
          })
        } else {
          paramsList = paramsList.filter((field: any) => {
            if (Array.isArray(field.type)) {
              return field.type.indexOf('string') === -1
            } else if (field.type !== 'string') {
              return true
            }
            return false
          })
        }
        this.setState({
          paramsList,
          metricsList,
        })
        this.props.handleChange(false, true)
      })
      .catch((e: any) => {
        if (e.response.status === 404) {
          A10Notification.warning({
            message: e.message,
            description: (
              <span>
                Currently no schema of published metrics for this object is
                registered with Harmony. At least one Thunder must register with
                HC to get information on the counters available
              </span>
            ),
            duration: 10,
          })
        }

        this.setState({ isMetricsFailed: true })
        this.props.handleChange(true, true)
      })
  }

  getUpdateMetricsList = (isString: boolean) => {
    let paramsList = []
    if (isString) {
      paramsList = this.state.metricsList.filter((field: any) => {
        if (Array.isArray(field.type)) {
          return field.type.indexOf('string') > -1
        } else if (field.type === 'string') {
          return true
        }
        return false
      })
    } else {
      paramsList = this.state.metricsList.filter((field: any) => {
        if (Array.isArray(field.type)) {
          return field.type.indexOf('string') === -1
        } else if (field.type !== 'string') {
          return true
        }
        return false
      })
    }
    return paramsList
  }

  getObjectType = () => {
    const targetUrl = storage.get.TARGET_URL || ''
    const objectTypeResponse = this.DashboardService.getObjectType(
      null,
      null,
      targetUrl,
    )

    objectTypeResponse
      .then((response: any) => {
        this.categories = response.data
        this.setState({
          categoriesLoaded: true,
        })
        this.renderObjectTypeTreeSelector()
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  renderOptions = (key: string) => {
    let obj: IObject

    if (key === 'Category') {
      const isPartitionAdmin = storage.get.IS_PARTITION_USER

      if (isPartitionAdmin || storage.get.DRILL_LEVEL === 'tenant') {
        obj = this.treeData?.[APP_SERVICE]
          ? {
              [APP_SERVICE]: this.treeData[APP_SERVICE],
            }
          : {}
      } else if (storage.get.DRILL_LEVEL === 'provider'){
          obj = {}
          obj[INFRASTRUCTURE] = this.treeData?.[INFRASTRUCTURE]
          obj[SYSTEM] = this.treeData?.[SYSTEM]
      }
    } else if (key === 'SubCategory') {
      obj = this.treeData[this.state.categoryType].children
    } else if (key === 'Object') {
      obj = this.treeData[this.state.categoryType].children[
        this.state.subcategoryType
      ].children
    } else {
      return null
    }
    const keys = Object.keys(obj)
    let index = -1
    // Removing System Category for now
    if (
      this.state.categoryType === 'APP SERVICE GROUP' &&
      key === 'SubCategory'
    ) {
      index = keys.indexOf('TopK')
      keys.splice(index, 1)
      index = keys.indexOf('IPSec')
      keys.splice(index, 1)
    }
    return keys.map((name: any, i: number) => {
      return (
        <A10Select.Option key={key + '_' + i} value={name}>
          {name}
        </A10Select.Option>
      )
    })
  }

  generateTreeData = (resp: any[]): any => {
    const validated = {}
    const subCatValidate = {}
    for (const res of resp) {
      const currentObject: any = res
      if (!validated[currentObject.mainCategory]) {
        validated[currentObject.mainCategory] = {
          key: currentObject.mainCategory,
          title: currentObject.mainCategory,
          disabled: true,
          children: {
            [currentObject.subCategory]: {
              key: currentObject.subCategory,
              title: currentObject.subCategory,
              disabled: true,
              children: {
                [currentObject.objectType]: {
                  key: currentObject.objectType,
                  title: currentObject.objectType,
                  metricsType: currentObject.metricsType,
                  value: JSON.stringify(currentObject),
                },
              },
            },
          },
        }
        subCatValidate[currentObject.mainCategory] = {
          [currentObject.subCategory]: true,
        }
      } else {
        if (
          !subCatValidate[currentObject.mainCategory][currentObject.subCategory]
        ) {
          validated[currentObject.mainCategory].children[
            currentObject.subCategory
          ] = {
            key: currentObject.subCategory,
            title: currentObject.subCategory,
            disabled: true,
            children: {
              [currentObject.objectType]: {
                key: currentObject.objectType,
                title: currentObject.objectType,
                metricsType: currentObject.metricsType,
                value: JSON.stringify(currentObject),
              },
            },
          }
          subCatValidate[currentObject.mainCategory][
            currentObject.subCategory
          ] = {
            done: true,
          }
        } else {
          validated[currentObject.mainCategory].children[
            currentObject.subCategory
          ].children[currentObject.objectType] = {
            key: currentObject.objectType,
            title: currentObject.objectType,
            metricsType: currentObject.metricsType,
            value: JSON.stringify(currentObject),
          }
        }
      }
    }
    return validated
  }

  renderObjectTypeTreeSelector = (): any => {
    this.treeData = this.generateTreeData(this.categories)
    this.setState({
      isDataProcessed: true,
    })
    if (this.props.trigger && this.props.trigger.formMode) {
      // this.getSchemaRegistry(this.state.objectType)
      this.objectTypeChange('objectType', this.state.objectType, false)
    }
  }

  setFieldsValueForCondtions(triggerOn?: string) {
    const triggerOnValue = triggerOn || this.state.triggerOn
    this.props.form.resetFields(['params-0-0'])
    this.props.form.setFieldsValue({
      'agg-value-0':
        triggerOnValue === 'int' && this.conditionBackup[0]
          ? this.conditionBackup[0].value
          : '',
    })
  }

  setFieldsWeightForCondtions(triggerOn?: string) {
    const triggerOnValue = triggerOn || this.state.triggerOn
    this.props.form.resetFields(['params-0-0'])
    this.props.form.setFieldsValue({
      'agg-weight-0':
        triggerOnValue === 'int' && this.conditionBackup[0]
          ? this.conditionBackup[0].weight
          : '',
    })
  }
  setFieldsSampleCountForCondtions(triggerOn?: string) {
    const triggerOnValue = triggerOn || this.state.triggerOn
    this.props.form.resetFields(['params-0-0'])
    this.props.form.setFieldsValue({
      'exponential-sample_count-0':
        triggerOnValue === 'int' && this.conditionBackup[0]
          ? this.conditionBackup[0].sample_count
          : '',
    })
  }

  handleChange = (
    stateName: string,
    e: any,
    index?: number,
    fieldName?: string,
    metricI?: number,
  ) => {
    if (!this.Utilities.validateField(e)) {
      return
    }
    const { ...triggerObj } = this.state
    let newVal = ''
    if (e && e.target) {
      newVal = e.target.value
      if (e.target.type === 'checkbox') {
        triggerObj[stateName] = e.target.checked
      } else {
        triggerObj[stateName] = e.target.value
        if (stateName === 'triggerName') {
          triggerObj.metadata.Trig_display_name = e.target.value
        }
      }
    } else {
      newVal = e
      triggerObj[stateName] = e
    }
    if (index !== undefined && index > -1) {
      const { conditions: valArr, metadata } = this.state
      if ('params' !== stateName) {
        valArr[index][stateName] = newVal
        if (!triggerObj.expression) {
          if (stateName === 'Measured_unit') {
            if (!metadata.Units?.[index]) {
              metadata.Units[index] = {}
            }
            metadata.Units[index].Measured_unit = newVal
            metadata.Units[index].Display_unit =
              DISPLAY_UNIT.get(newVal)?.[0]?.displayUnit || ''
          } else if (stateName === 'displayUnit') {
            metadata.Units[index].Display_unit =
              DISPLAY_UNIT.get(metadata.Units[index].Measured_unit)?.[newVal]
                ?.displayUnit || ''
          }
        } else {
          if (stateName === 'Measured_unit') {
            metadata.Units['Measured_unit'] = newVal
            metadata.Units['Display_unit'] =
              DISPLAY_UNIT.get(newVal)?.[0]?.displayUnit || ''
          } else if (stateName === 'displayUnit') {
            metadata.Units['Display_unit'] =
              DISPLAY_UNIT.get(metadata.Units['Measured_unit'])?.[newVal]
                ?.displayUnit || ''
          }
        }
      } else {
        let params
        if (metricI !== undefined) {
          params = valArr[index].params as IObject[]
          params[metricI] = e.target
            ? JSON.parse(e.target.value).name
            : JSON.parse(e).name
        } else {
          params = newVal
        }
        valArr[index][stateName] = params
      }

      if (stateName === 'weight') {
        valArr[0].weight = newVal
      }

      // @ts-ignore
      this.setState({ conditions: valArr, metadata })
      if ('condition' === stateName) {
        this.setState({ selectedCondition: newVal })
      }
      this.onChangeCallback(triggerObj)
      return
    }

    if (stateName === 'expression') {
      const { conditions: valArr, metadata } = this.state
      while (valArr.length > 1) {
        valArr.pop()
      }
      metadata.Units = !triggerObj[stateName]
        ? [metadata.Units]
        : metadata.Units[0]

      this.setState({ conditions: valArr })
    }

    if (stateName === 'categoryType') {
      triggerObj.categoryType = e
      triggerObj.subcategoryType = ''
      triggerObj.objectType = ''
      triggerObj.metricsType = ''
      triggerObj.paramsList = []
      triggerObj.metricsList = []
      triggerObj.conditions = this.updateConditionBackup()
      this.onChangeCallback(triggerObj)
      this.setFieldsValueForCondtions()
      this.setFieldsWeightForCondtions()
      this.setFieldsSampleCountForCondtions()
      this.setState({
        categoryType: e,
        subcategoryType: triggerObj.subcategoryType,
        objectType: triggerObj.objectType,
        metricsType: triggerObj.metricsType,
        conditions: triggerObj.conditions,
        paramsList: triggerObj.paramsList,
        metricsList: triggerObj.metricsList,
      })
      this.props.form.setFields({
        subcategoryType: triggerObj.subcategoryType,
        objectType: triggerObj.objectType,
      })
      return
    } else if (stateName === 'subcategoryType') {
      triggerObj.subcategoryType = e
      triggerObj.objectType = ''
      triggerObj.metricsType = ''
      triggerObj.paramsList = []
      triggerObj.metricsList = []
      triggerObj.conditions = this.updateConditionBackup()
      this.onChangeCallback(triggerObj)
      this.setFieldsValueForCondtions()
      this.setFieldsWeightForCondtions()
      this.setFieldsSampleCountForCondtions()
      this.setState({
        subcategoryType: e,
        objectType: triggerObj.objectType,
        metricsType: triggerObj.metricsType,
        conditions: triggerObj.conditions,
        paramsList: triggerObj.paramsList,
        metricsList: triggerObj.metricsList,
      })
      this.props.form.setFields({
        objectType: triggerObj.objectType,
      })
      return
    } else if (stateName === 'objectType') {
      triggerObj.objectType = e
      triggerObj.metricsType = this.treeData[this.state.categoryType].children[
        this.state.subcategoryType
      ].children[e].metricsType
      triggerObj.paramsList = []
      triggerObj.metricsList = []
      triggerObj.conditions = this.updateConditionBackup()
      this.onChangeCallback(triggerObj)
      this.setFieldsValueForCondtions()
      this.setFieldsWeightForCondtions()
      this.setFieldsSampleCountForCondtions()
      this.setState({
        objectType: e,
        metricsType: triggerObj.metricsType,
        conditions: triggerObj.conditions,
        paramsList: triggerObj.paramsList,
        metricsList: triggerObj.metricsList,
      })
      return
    }

    if (stateName === 'triggerOn') {
      const triggerOnIsString = triggerObj[stateName] === 'string'
      triggerObj.conditions = this.updateConditionBackup(triggerObj[stateName])
      const paramsList = this.getUpdateMetricsList(triggerOnIsString)
      this.onChangeCallback(triggerObj)
      this.setFieldsValueForCondtions(triggerObj[stateName])
      this.setFieldsWeightForCondtions(triggerObj[stateName])
      this.setFieldsSampleCountForCondtions(triggerObj[stateName])
      if (triggerOnIsString) {
        triggerObj.expression = false
      }
      // @ts-ignore
      this.setState({
        [stateName]: triggerObj[stateName],
        conditions: triggerObj.conditions,
        paramsList,
      })
      return
    }

    this.onChangeCallback(triggerObj)
    // @ts-ignore
    this.setState({
      [stateName]: triggerObj[stateName],
    })
  }

  onChangeCallback(data: any) {
    this.props.handleChange(data, false)
  }

  process = () => {
    console.log('handle process')
  }

  updateConditionBackup = (triggerOn?: string) => {
    const conditions = JSON.parse(JSON.stringify(this.conditionBackup))
    const triggerOnValue = triggerOn || this.state.triggerOn
    if (triggerOnValue === 'int') {
      conditions[0].operator = this.Utilities.getDropdownDefault(
        'operator',
        'TRIGGERS',
      )
      conditions[0].metricAgg = this.Utilities.getDropdownDefault(
        'metricAgg',
        'TRIGGERS',
      )
      conditions[0].weight = ''
      conditions[0].sample_count = ''
      conditions[0].expressions = ''
      conditions[0].expression_condition = ''
    } else {
      conditions[0].operator = this.Utilities.getDropdownDefault(
        'strOperator',
        'TRIGGERS',
      )
      conditions[0].metricAgg = this.Utilities.getDropdownDefault(
        'strMetricAgg',
        'TRIGGERS',
      )
      conditions[0].value = ''
    }
    return conditions
  }

  addCondition = () => {
    const { ...triggerObj } = this.state
    const conditions = triggerObj.conditions
    const newConditions = this.updateConditionBackup()
    conditions.push(newConditions[0])
    this.setState({
      conditions,
    })
    triggerObj.conditions = conditions
    this.onChangeCallback(triggerObj)
  }

  removeCondition = (index: number) => {
    const { ...triggerObj } = this.state
    const conditions = triggerObj.conditions
    conditions.splice(index, 1)
    this.setState({
      conditions: this.updateConditionBackup(),
    })
    triggerObj.conditions = this.updateConditionBackup()
    setTimeout(() => {
      this.setState({
        conditions,
      })
      triggerObj.conditions = conditions
      this.onChangeCallback(triggerObj)
    })
  }

  addAnotherMetric = (condition: any, index: number) => {
    const { conditions: conditionValue } = this.state
    let metricIndex =
      conditionValue[index].metrics[conditionValue[index].metrics.length - 1]
    metricIndex++
    conditionValue[index].metrics.push(metricIndex)
    this.setState({ conditions: conditionValue })
  }

  removeMetric = (condition: any, index: number, metricIndex: number) => {
    const conditionValue = JSON.parse(JSON.stringify(this.state.conditions))
    const conditionValueTwo = JSON.parse(JSON.stringify(this.state.conditions))
    conditionValue[index].metrics = []
    conditionValue[index].params = []
    this.setState({ conditions: conditionValue })
    setTimeout(() => {
      conditionValueTwo[index].metrics.splice(metricIndex, 1)
      conditionValueTwo[index].params.splice(metricIndex, 1)
      this.setState({ conditions: conditionValueTwo })
      this.onChangeCallback(this.state)
    })
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  validateDuplicateActionName = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const index = this.props.triggers.findIndex((key: any) => {
      return key.def_name === value
    })
    const userRegx = new RegExp(/^[a-zA-Z0-9]([a-zA-Z0-9_ ]){1,50}$/)
    if ('' === value) {
      rule.message = this.Messages.EMPTY_TRIGGER_NAME
      isValid = false
    } else if (value && value.length > 50) {
      rule.message = this.Messages.VALIDATION_TRIGGER_NAME_LENGTH
      isValid = false
    } else if (value && value.length <= 1) {
      rule.message = this.Messages.VALIDATION_TRIGGER_NAME_MIN_LENGTH
      isValid = false
    } else if (!userRegx.test(value)) {
      rule.message = this.Messages.VALIDATION_TRIGGER_NAME
      isValid = false
    } else if (index > -1) {
      rule.message = this.Messages.DUPLICATE_TRIGGER_NAME
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateSampleCount = (index: number, rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    if (!value || value <= 1) {
      rule.message = this.Messages.EMPTY_EXPONENTIAL_SAMPLE
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateWeights = (index: number, rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const { conditions } = this.state
    if ('' === value) {
      rule.message = this.Messages.EMPTY_AGGREGATED_WEIGHT
      isValid = false
    } else {
      if (conditions[index].metricAgg === 'weightedAvg') {
        let weights = value
        if (typeof value === 'string') {
          weights = value.split(',')
        } else {
          weights = value.toString().split(',')
        }

        if (weights.length <= 1) {
          isValid = false
          rule.message = this.Messages.VALIDATION_AGGREGATED_WEIGHT_COMMA
        } else {
          weights.forEach((weight: string) => {
            if (isNaN(Number(weight)) || Number(weight) < 0) {
              isValid = false
              rule.message = this.Messages.VALIDATION_AGGREGATED_WEIGHT_COMMA_VALUE
            }
          })
        }
      } else if (conditions[index].metricAgg === 'exponentialAvg') {
        if (isNaN(Number(value)) || !(Number(value) > 0 && Number(value) < 1)) {
          isValid = false
          rule.message = this.Messages.VALIDATION_AGGREGATED_WEIGHT
        }
      }
    }
    return isValid ? cb() : cb(true)
  }

  validateExpressions = (index: any, rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    if ('' === value) {
      isValid = false
      rule.message = this.Messages.VALID_JSON
    } else if (value && value.length > 0) {
      const valueTemp = value.trim()
      const stringValue = valueTemp.substring(1, valueTemp.length - 1)
      if (!this.Utilities.IsValidJSONString(stringValue)) {
        isValid = false
        rule.message = this.Messages.VALID_JSON
      } else {
        this.handleChange('expressions', stringValue, index)
      }
    }
    return isValid ? cb() : cb(true)
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 16 },
    }
    const tnFormItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 12 },
    }
    const dropFormItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 8 },
    }
    const {
      triggerName,
      categoryType,
      subcategoryType,
      objectType,
      windowType,
      triggerOn,
      windowSize,
      windowSizeType,
      maxSamples,
      windowSample,
      expression,
      conditions,
      triggerMessage,
      isMetricsFailed,
      metadata,
    } = this.state
    const { value } = conditions[0]
    const metadataUnits = (
      expression: boolean,
      index: number,
      metadata: ITriggerMetaData,
    ) => {
      const units = !metadata.Units
        ? ''
        : !expression
        ? metadata.Units?.[index]
        : metadata.Units
      return MEASURED_UNIT.includes(units?.Measured_unit) ? units : ''
    }
    const units = metadataUnits(expression, 0, metadata)
    const displayUnitArray = DISPLAY_UNIT.get(units?.Measured_unit) || [
      { formula: [], displayUnit: '' },
    ]
    const index = displayUnitArray.findIndex(
      (v: IObject) => v.displayUnit === units?.Display_unit,
    )
    const { formula = [], displayUnit = '' } = displayUnitArray[
      index > -1 ? index : 0
    ]
    const calculatedValue = !formula.length
      ? value
      : formula[0] === 'divide'
      ? value / formula?.[1]
      : value * formula?.[1]
    const triggerMessagePH =
      triggerName +
      ' Avg ' +
      ' ControlCPU ' +
      ' is Less than ' +
      calculatedValue +
      (displayUnit === 'percentage'
        ? '%'
        : displayUnit === 'ratio'
        ? ''
        : displayUnit) +
      ' over last ' +
      windowSize +
      ' minutes'
    const emptyOptions = (
      <A10Select.Option key="empty" value="" style={{ minHeight: 32 }} />
    )
    const { getFieldDecorator } = this.props.form

    return (
      <div className="TriggerAddFormCls">
        <A10Form hideRequiredMark={true} layout="horizontal">
          <A10Panel
            key={'triggerDetailPanel'}
            title={
              <A10IconTextGroup
                text={
                  <>
                    Trigger
                  </>
                }
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            }
          >
            <A10Form.Item
              {...tnFormItemLayout}
              key={'trigger_name'}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.TRIGGER_NAME}
                  </span>
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.TRIGGER_NAME}
                      helpKey="HELP_MONITOR_TRIGGER_NAME"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('triggerName', {
                rules: [
                  { validator: this.validateDuplicateActionName.bind(this) },
                ],
                initialValue: triggerName,
              })(
                <A10Input
                  type="text"
                  placeholder={this.Messages.TRIGGER_NAME}
                  onChange={this.handleChange.bind(this, 'triggerName')}
                />,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...dropFormItemLayout}
              key="category_type"
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.CATEGORY_TYPE}
                  </span>
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.CATEGORY_TYPE}
                      helpKey="HELP_MONITOR_TRIGGER_CATEGORY"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('categoryType', {
                rules: [
                  {
                    required: true,
                    message: this.Messages.VALIDATION_CATEGORY_TYPE,
                  },
                ],
                initialValue: categoryType,
              })(
                <A10Select
                  onChange={this.handleChange.bind(this, 'categoryType')}
                >
                  {this.state.isDataProcessed
                    ? this.renderOptions('Category')
                    : null}
                </A10Select>,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...dropFormItemLayout}
              key={'subcategory_type'}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.SUBCATEGORY_TYPE}
                  </span>
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.SUBCATEGORY_TYPE}
                      helpKey="HELP_MONITOR_TRIGGER_SUBCATEGORY_TYPE"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('subcategoryType', {
                rules: [
                  {
                    required: true,
                    message: this.Messages.VALIDATION_SUB_CATEGORY_TYPE,
                  },
                ],
                initialValue: subcategoryType,
              })(
                <A10Select
                  onChange={this.handleChange.bind(this, 'subcategoryType')}
                >
                  {categoryType && this.treeData
                    ? this.renderOptions('SubCategory')
                    : null}
                </A10Select>,
              )}
            </A10Form.Item>
            <A10Form.Item
              {...dropFormItemLayout}
              key={'object_type'}
              label={
                <>
                  <span className="mandatoryField">
                    {this.Messages.OBJECT_TYPE}
                  </span>
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.OBJECT_TYPE}
                      helpKey="HELP_MONITOR_TRIGGER_OBJECT_TYPE"
                    />
                  </span>
                </>
              }
            >
              {this.state.categoriesLoaded ? (
                <>
                  {getFieldDecorator('objectType', {
                    rules: [
                      {
                        required: true,
                        message: this.Messages.VALIDATION_OBJECT_TYPE,
                      },
                    ],
                    initialValue: objectType,
                  })(
                    <A10Select
                      onChange={(e: any) =>
                        this.objectTypeChange('objectType', e, true)
                      }
                    >
                      {subcategoryType && this.treeData
                        ? this.renderOptions('Object')
                        : null}
                    </A10Select>,
                  )}
                </>
              ) : null}
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              key={'metric_type'}
              label={
                <>
                  {this.Messages.METRIC_TYPE}
                  <span>
                    <HelpInfo
                      customCls="custom-tooltip-input"
                      placement="left"
                      title={this.Messages.METRIC_TYPE}
                      helpKey="HELP_MONITOR_TRIGGER_METRIC_TYPE"
                    />
                  </span>
                </>
              }
            >
              <A10Radio.Group
                onChange={this.handleChange.bind(this, 'triggerOn')}
                defaultValue={triggerOn}
              >
                <A10Radio value={'int'}>Numeric</A10Radio>
                <A10Radio value={'string'}>String</A10Radio>
              </A10Radio.Group>
            </A10Form.Item>

            {triggerOn === 'int' &&
            !(
              conditions[0] &&
              (conditions[0].metricAgg === 'exponentialAvg' ||
                conditions[0].metricAgg === 'weightedAvg')
            ) ? (
              <>
                <A10Form.Item
                  {...formItemLayout}
                  key={'window_type'}
                  label={
                    <>
                      {this.Messages.WINDOW_TYPE}
                      <span>
                        <HelpInfo
                          customCls="custom-tooltip-input"
                          placement="left"
                          title={this.Messages.WINDOW_TYPE}
                          helpKey="HELP_MONITOR_TRIGGER_WINDOW_TYPE"
                        />
                      </span>
                    </>
                  }
                >
                  <A10Radio.Group
                    onChange={this.handleChange.bind(this, 'windowType')}
                    value={windowType}
                  >
                    <A10Radio value={'sliding'}>Sliding</A10Radio>
                    <A10Radio value={'step'}>Step</A10Radio>
                  </A10Radio.Group>
                </A10Form.Item>

                <A10Form.Item
                  {...formItemLayout}
                  key={'window_size'}
                  label={
                    <>
                      {this.Messages.WINDOW_SIZE}
                      <span>
                        <HelpInfo
                          customCls="custom-tooltip-input"
                          placement="left"
                          title={this.Messages.WINDOW_SIZE}
                          helpKey="HELP_MONITOR_TRIGGER_WINDOW_SIZE"
                        />
                      </span>
                    </>
                  }
                >
                  <>
                    {getFieldDecorator('windowSize', {
                      rules: [
                        {
                          required: true,
                          message: this.Messages.VALIDATION_WINDOW_SIZE,
                        },
                      ],
                      initialValue: windowSize,
                    })(
                      <A10Input
                        type="number"
                        max="10"
                        min="1"
                        className="col-sm-3"
                        onChange={this.handleChange.bind(this, 'windowSize')}
                      />,
                    )}
                    <A10Select
                      defaultValue={windowSizeType}
                      value={windowSizeType}
                      className="col-sm-5"
                      onChange={this.handleChange.bind(this, 'windowSizeType')}
                    >
                      <A10Select.Option value="minutes">
                        Minutes
                      </A10Select.Option>
                      <A10Select.Option value="samples">
                        Samples
                      </A10Select.Option>
                    </A10Select>
                  </>
                </A10Form.Item>

                <A10Form.Item
                  {...formItemLayout}
                  key={'max_samples'}
                  label={
                    <>
                      <A10Switch
                        defaultChecked={maxSamples}
                        onChange={this.handleChange.bind(this, 'maxSamples')}
                      />
                      <label
                        style={{
                          marginLeft: '10px',
                        }}
                      >
                        {this.Messages.MAX_SAMPLES}
                        <span>
                          <HelpInfo
                            customCls="custom-tooltip-input"
                            placement="left"
                            title={this.Messages.MAX_SAMPLES}
                            helpKey="HELP_MONITOR_TRIGGER_MAX_SAMPLES"
                          />
                        </span>
                      </label>
                    </>
                  }
                  style={{
                    display:
                      windowType === 'sliding' && windowSizeType === 'minutes'
                        ? 'block'
                        : 'none',
                  }}
                >
                  {maxSamples ? (
                    <>
                      {getFieldDecorator('windowSample', {
                        rules: [
                          {
                            required: maxSamples ? true : false,
                            message: this.Messages.VALIDATION_WINDOW_SAMPLE,
                          },
                        ],
                        initialValue: windowSample,
                      })(
                        <A10Input
                          type="number"
                          min="1"
                          max="10"
                          className="col-sm-4"
                          onChange={this.handleChange.bind(
                            this,
                            'windowSample',
                          )}
                        />,
                      )}
                      <label
                        style={{
                          marginLeft: '10px',
                        }}
                      >
                        {this.Messages.SAMPLES}
                      </label>
                    </>
                  ) : null}
                </A10Form.Item>
              </>
            ) : null}
          </A10Panel>

          {conditions.map((condition: ITriggerCondition, index: number) => {
            return (
              <div
                className="thresholdContainer"
                key={'threshold_container' + index}
              >
                <A10Panel
                  key={'threshold_panel'}
                  title={
                    <A10IconTextGroup
                      text={
                        <>
                          {'Threshold Logic ' + (index + 1)}
                        </>
                      }
                      icon={
                        <A10Icon
                          style={{ width: 22, height: 22, marginRight: 12 }}
                          app="global"
                          type="form-section"
                          className="sliding-panel-icon"
                        />
                      }
                    />
                  }
                  menu={
                    index > 0 ? (
                      <div
                        className="cursor-pointer"
                        onClick={this.removeCondition.bind(this, index)}
                      >
                        <span className="a10-icon a10-trigger-icon remove" />
                      </div>
                    ) : (
                      ''
                    )
                  }
                >
                  {objectType && isMetricsFailed ? (
                    <div className="row">
                      <div className="col-md-12">
                        <A10Alert
                          className="alert-info"
                          message={
                            'Metrics not available. Please select different category or object type'
                          }
                          type="info"
                          showIcon={true}
                          closable={false}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="row" key={'condition1-' + index}>
                    <div className="col-md-12">
                      {condition.metrics.map(
                        (metricValue: any, metricIndex: number) => {
                          return (
                            <A10Form.Item
                              key={'params-' + metricIndex}
                              {...formItemLayout}
                              label={
                                <>
                                  <span className="mandatoryField">
                                    {this.Messages.METRIC}
                                  </span>
                                  <span>
                                    <HelpInfo
                                      customCls="custom-tooltip-input"
                                      placement="left"
                                      title={this.Messages.METRIC}
                                      helpKey="HELP_MONITOR_TRIGGER_METRIC"
                                    />
                                  </span>
                                </>
                              }
                            >
                              {getFieldDecorator(
                                `params-${index}-${metricIndex}`,
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: this.Messages
                                        .VALIDATION_METRIC_PARAMS,
                                    },
                                  ],
                                  initialValue: condition.params[
                                    metricIndex
                                  ] ? (
                                    <span>
                                      <span className="a10-icon metric-icon" />
                                      <span>
                                        {condition.params[metricIndex]}
                                      </span>
                                    </span>
                                  ) : (
                                    undefined
                                  ),
                                },
                              )(
                                // <>
                                <A10Select
                                  showSearch={true}
                                  placeholder={
                                    <span>
                                      <span className="a10-icon metric-icon" />
                                      <span>Please Select</span>
                                    </span>
                                  }
                                  className="col-sm-12 no-padding metricDropdown"
                                  // value={condition.params[metricIndex]}
                                  onChange={(e: any) =>
                                    this.handleChange(
                                      'params',
                                      e,
                                      index,
                                      `params-${index}-${metricIndex}`,
                                      metricIndex,
                                    )
                                  }
                                >
                                  {this.Utilities.renderDropdownOpts(
                                    this.state.paramsList,
                                    null,
                                    'name',
                                    undefined,
                                    'metric-icon',
                                    '',
                                    'doc',
                                  )}
                                </A10Select>,

                                // </>
                              )}
                              {condition.metrics.length > 1 ? (
                                <div
                                  className="metricDropdownRemove cursor-pointer"
                                  onClick={this.removeMetric.bind(
                                    this,
                                    condition,
                                    index,
                                    metricIndex,
                                  )}
                                >
                                  <span className="a10-icon a10-trigger-icon remove" />
                                </div>
                              ) : (
                                ''
                              )}
                            </A10Form.Item>
                          )
                        },
                      )}
                      {triggerOn === 'int' ? (
                        <>
                          <A10Row>
                            <A10Col span={7} />
                            <A10Col span={13}>
                              <div
                                className="add-condition-button"
                                onClick={this.addAnotherMetric.bind(
                                  this,
                                  condition,
                                  index,
                                )}
                              >
                                <span className="a10-icon a10-trigger-icon add-another" />
                                <span className="link addMoreLink">
                                  Add another
                                </span>
                              </div>
                            </A10Col>
                          </A10Row>
                        </>
                      ) : null}

                      {condition?.params?.length > 1 && !expression ? (
                        <A10Form.Item
                          {...formItemLayout}
                          label={
                            <>
                              {this.Messages.VECTOR_FUNCTION}
                              <span>
                                <HelpInfo
                                  customCls="custom-tooltip-input"
                                  placement="left"
                                  title={this.Messages.VECTOR_FUNCTION}
                                  helpKey="HELP_MONITOR_TRIGGER_VECTOR_FUNCTION"
                                />
                              </span>
                            </>
                          }
                        >
                          <A10Select
                            placeholder="Please select"
                            className="col-sm-4 no-padding"
                            value={condition.operation}
                            onChange={(e: any) =>
                              this.handleChange('operation', e, index)
                            }
                          >
                            {this.Utilities.renderDropdownOpts(
                              'operation',
                              'TRIGGERS',
                            )}
                          </A10Select>
                        </A10Form.Item>
                      ) : null}
                      {triggerOn === 'int' && index === 0 ? (
                        <A10Form.Item
                          {...formItemLayout}
                          key={'threshold_heading' + index}
                          label={
                            <>
                              Threshold
                              <span>
                                <HelpInfo
                                  customCls="custom-tooltip-input"
                                  placement="left"
                                  title="Threshold"
                                  helpKey="HELP_MONITOR_TRIGGER_THRESHOLD_INFORMATION"
                                />
                              </span>
                            </>
                          }
                          className="threshold-heading"
                        >
                          <A10Switch
                            checked={expression}
                            onChange={(e: any) =>
                              this.handleChange('expression', e)
                            }
                          />
                          <label
                            style={{
                              marginLeft: '10px',
                            }}
                          >
                            {this.Messages.EXPRESSION}
                            <span>
                              <HelpInfo
                                customCls="custom-tooltip-input"
                                placement="right"
                                title={this.Messages.EXPRESSION}
                                helpKey="HELP_MONITOR_TRIGGER_EXPRESSION"
                              />
                            </span>
                          </label>
                        </A10Form.Item>
                      ) : null}
                      {/* Trigger on metrics is string */}
                      {expression ? (
                        <>
                          <A10Form.Item
                            {...formItemLayout}
                            key={'expressions-' + index}
                            label={
                              <>
                                {this.Messages.EXPRESSION}
                                <span>
                                  <HelpInfo
                                    customCls="custom-tooltip-input"
                                    placement="left"
                                    title={this.Messages.EXPRESSION}
                                    helpKey="HELP_MONITOR_TRIGGER_EXPRESSION_JSON"
                                  />
                                </span>
                              </>
                            }
                          >
                            <div className="col-sm-12 no-padding">
                              {getFieldDecorator(`expressions-${index}`, {
                                rules: [
                                  {
                                    validator: this.validateExpressions.bind(
                                      this,
                                      index,
                                    ),
                                  },
                                ],
                                initialValue: condition.expressions,
                              })(
                                <A10Input.TextArea
                                  type="text"
                                  size="large"
                                  rows={5}
                                  placeholder={
                                    this.Messages.EXPRESSIONS_JSON_PH
                                  }
                                />,
                              )}
                            </div>
                          </A10Form.Item>
                          <A10Form.Item
                            {...formItemLayout}
                            key={'expression_condition-' + index}
                            label={
                              <>
                                {this.Messages.EXPRESSION_CONDITION}
                                <span>
                                  <HelpInfo
                                    customCls="custom-tooltip-input"
                                    placement="left"
                                    title={this.Messages.EXPRESSION_CONDITION}
                                    helpKey="HELP_MONITOR_TRIGGER_EXPRESSION_CONDITION"
                                  />
                                </span>
                              </>
                            }
                          >
                            <div className="col-sm-12 no-padding">
                              {getFieldDecorator(
                                `expression_condition-${index}`,
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: this.Messages
                                        .EMPTY_EXPRESSION_CONDITION,
                                    },
                                  ],
                                  initialValue: condition.expression_condition,
                                },
                              )(
                                <A10Input.TextArea
                                  type="text"
                                  size="large"
                                  placeholder={
                                    this.Messages.EXPRESSION_CONDITION_PH
                                  }
                                  onChange={(e: any) =>
                                    this.handleChange(
                                      'expression_condition',
                                      e,
                                      index,
                                      `expression_condition-${index}`,
                                    )
                                  }
                                />,
                              )}
                            </div>
                          </A10Form.Item>
                        </>
                      ) : triggerOn === 'int' ? (
                        <>
                          <A10Form.Item
                            {...formItemLayout}
                            key={'scaler_function' + index}
                            label={
                              <>
                                {this.Messages.SCALER_FUNCTION}
                                <span>
                                  <HelpInfo
                                    customCls="custom-tooltip-input"
                                    placement="left"
                                    title={this.Messages.SCALER_FUNCTION}
                                    helpKey="HELP_MONITOR_TRIGGER_SCALER_FUNCTION"
                                  />
                                </span>
                              </>
                            }
                          >
                            <div className="row col-sm-12">
                              <div className="col-sm-4 no-padding">
                                <A10Select
                                  placeholder="Please select"
                                  value={condition.metricAgg}
                                  className="col-sm-12 no-padding"
                                  onChange={(e: any) =>
                                    this.handleChange('metricAgg', e, index)
                                  }
                                >
                                  {this.Utilities.renderDropdownOpts(
                                    'metricAgg',
                                    'TRIGGERS',
                                    null,
                                    null,
                                    null,
                                    null,
                                    // true,
                                  )}
                                </A10Select>
                              </div>
                              {condition.metricAgg === 'exponentialAvg' ||
                              condition.metricAgg === 'weightedAvg' ? (
                                <div
                                  className="col-sm-8"
                                  style={{ marginTop: '7px' }}
                                >
                                  {getFieldDecorator(`agg-weight-${index}`, {
                                    rules: [
                                      {
                                        validator: this.validateWeights.bind(
                                          this,
                                          index,
                                        ),
                                      },
                                    ],
                                    initialValue: condition.weight,
                                  })(
                                    <A10Input
                                      className="form-control col-sm-12"
                                      placeholder={
                                        'Enter Weight' +
                                        (condition.metricAgg === 'weightedAvg'
                                          ? 's comma separated'
                                          : '')
                                      }
                                      onChange={(e: any) =>
                                        this.handleChange(
                                          'weight',
                                          e,
                                          index,
                                          `agg-weight-${index}`,
                                        )
                                      }
                                      style={{
                                        margin: '0 7px 0 0',
                                        height: 'auto',
                                      }}
                                    />,
                                  )}
                                </div>
                              ) : null}
                            </div>
                          </A10Form.Item>

                          {condition.metricAgg === 'exponentialAvg' ? (
                            <A10Form.Item
                              {...formItemLayout}
                              key={'exponential_count' + index}
                              label={
                                <>
                                  {this.Messages.SAMPLES}
                                  <span>
                                    <HelpInfo
                                      customCls="custom-tooltip-input"
                                      placement="left"
                                      title={this.Messages.SAMPLES}
                                      helpKey="HELP_MONITOR_TRIGGER_EXP_AVG_SAMPLE"
                                    />
                                  </span>
                                </>
                              }
                            >
                              <div className="col-sm-8 no-padding">
                                {getFieldDecorator(
                                  `exponential-sample_count-${index}`,
                                  {
                                    rules: [
                                      {
                                        validator: this.validateSampleCount.bind(
                                          this,
                                          index,
                                        ),
                                      },
                                    ],
                                    initialValue: condition.sample_count,
                                  },
                                )(
                                  <A10InputNumber
                                    min={0}
                                    className="form-control col-sm-6"
                                    placeholder={'Enter sample count'}
                                    onChange={(e: any) =>
                                      this.handleChange(
                                        'sample_count',
                                        e,
                                        index,
                                        `exponential-sample_count-${index}`,
                                      )
                                    }
                                    style={{
                                      margin: '0 7px 0 0',
                                      height: 'auto',
                                    }}
                                  />,
                                )}
                              </div>
                            </A10Form.Item>
                          ) : null}

                          <A10Form.Item
                            {...formItemLayout}
                            key={'aggregation_condition' + index}
                            label={
                              <>
                                {this.Messages.AGGREGATION_CONDITION}
                                <span>
                                  <HelpInfo
                                    customCls="custom-tooltip-input"
                                    placement="left"
                                    title={this.Messages.AGGREGATION_CONDITION}
                                    helpKey="HELP_MONITOR_TRIGGER_AGGREGATION_CONDITION"
                                  />
                                </span>
                              </>
                            }
                          >
                            <A10Select
                              className="col-sm-10 no-padding"
                              value={condition.operator}
                              onChange={(e: any) =>
                                this.handleChange('operator', e, index)
                              }
                            >
                              {this.Utilities.renderDropdownOpts(
                                'operator',
                                'TRIGGERS',
                              )}
                            </A10Select>
                          </A10Form.Item>

                          <A10Form.Item
                            {...formItemLayout}
                            key={'aggregated_value' + index}
                            label={
                              <>
                                {this.Messages.AGGREGATED_VALUE}
                                <span>
                                  <HelpInfo
                                    customCls="custom-tooltip-input"
                                    placement="left"
                                    title={this.Messages.AGGREGATED_VALUE}
                                    helpKey="HELP_MONITOR_TRIGGER_AGGREGATED_VALUE"
                                  />
                                </span>
                              </>
                            }
                          >
                            <div className="col-sm-8 no-padding">
                              {getFieldDecorator(`agg-value-${index}`, {
                                rules: [
                                  {
                                    required: true,
                                    message: this.Messages
                                      .VALIDATION_AGGREGATED_VALUE,
                                  },
                                ],
                                initialValue: condition.value,
                              })(
                                <A10InputNumber
                                  min={0}
                                  className="form-control col-sm-6"
                                  placeholder={this.Messages.AGGREGATED_VALUE}
                                  onChange={(e: any) =>
                                    this.handleChange(
                                      'value',
                                      e,
                                      index,
                                      `agg-value-${index}`,
                                    )
                                  }
                                  style={{
                                    margin: '0 7px 0 0',
                                    height: 'auto',
                                  }}
                                />,
                              )}
                            </div>
                          </A10Form.Item>
                        </>
                      ) : (
                        <>
                          <A10Form.Item
                            {...formItemLayout}
                            key={'scaler_function' + index}
                            label={
                              <>
                                {this.Messages.AGGREGATION_CONDITION}
                                <span>
                                  <HelpInfo
                                    customCls="custom-tooltip-input"
                                    placement="left"
                                    title={this.Messages.AGGREGATION_CONDITION}
                                    helpKey="HELP_MONITOR_TRIGGER_AGGREGATION_CONDITION"
                                  />
                                </span>
                              </>
                            }
                          >
                            <A10Select
                              placeholder="Please select"
                              value={condition.metricAgg}
                              className="col-sm-10 no-padding"
                              onChange={(e: any) =>
                                this.handleChange('metricAgg', e, index)
                              }
                            >
                              {this.Utilities.renderDropdownOpts(
                                'strMetricAgg',
                                'TRIGGERS',
                              )}
                            </A10Select>
                          </A10Form.Item>

                          <A10Form.Item
                            {...formItemLayout}
                            key={'aggregated_value' + index}
                            label={
                              <>
                                {this.Messages.AGGREGATED_VALUE}
                                <span>
                                  <HelpInfo
                                    customCls="custom-tooltip-input"
                                    placement="left"
                                    title={this.Messages.AGGREGATED_VALUE}
                                    helpKey="HELP_MONITOR_TRIGGER_AGGREGATED_VALUE"
                                  />
                                </span>
                              </>
                            }
                          >
                            <div className="col-sm-10 no-padding">
                              {getFieldDecorator(`agg-value-${index}`, {
                                rules: [
                                  {
                                    required: true,
                                    message: this.Messages
                                      .VALIDATION_AGGREGATED_VALUE,
                                  },
                                ],
                                initialValue: condition.value,
                              })(
                                <A10Input
                                  className="form-control col-sm-12"
                                  placeholder={this.Messages.AGGREGATED_VALUE}
                                  onChange={(e: any) =>
                                    this.handleChange(
                                      'value',
                                      e,
                                      index,
                                      `agg-value-${index}`,
                                    )
                                  }
                                  style={{
                                    margin: '0 7px 0 0',
                                    height: 'auto',
                                  }}
                                />,
                              )}
                            </div>
                          </A10Form.Item>
                        </>
                      )}

                      <A10Form.Item
                        {...formItemLayout}
                        key={'aggregated_value_unit' + index}
                        label={
                          <>
                            {this.Messages.AGGREGATED_VALUE_UNIT}
                            <span>
                              <HelpInfo
                                customCls="custom-tooltip-input"
                                placement="left"
                                title={this.Messages.AGGREGATED_VALUE_UNIT}
                                helpKey="HELP_MONITOR_TRIGGER_AGGREGATED_VALUE_UNIT"
                              />
                            </span>
                          </>
                        }
                      >
                        <div className="col-sm-8 no-padding">
                          <A10Select
                            className="col-sm-6 no-padding"
                            placeholder={this.Messages.AGGREGATED_VALUE_UNIT}
                            onChange={(e: any) =>
                              this.handleChange(
                                'Measured_unit',
                                e,
                                index,
                                `agg-value-unit-${index}`,
                              )
                            }
                            value={
                              !metadataUnits(expression, index, metadata)
                                ? ''
                                : metadataUnits(expression, index, metadata)
                                    ?.Measured_unit
                            }
                          >
                            {emptyOptions}
                            {MEASURED_UNIT.map(
                              (unit: string, unitIndex: number) => (
                                <A10Select.Option
                                  key={`agg-value-${unit}-${unitIndex}}`}
                                  value={unit}
                                >
                                  {unit}
                                </A10Select.Option>
                              ),
                            )}
                          </A10Select>
                        </div>
                      </A10Form.Item>

                      <A10Form.Item
                        {...formItemLayout}
                        key={
                          'aggregated_value_displayUnit' +
                          index +
                          '-' +
                          metadataUnits(expression, index, metadata)
                            ?.Measured_unit
                        }
                        label={
                          <>
                            {this.Messages.AGGREGATED_VALUE_DISPLAY_UNIT}
                            <span>
                              <HelpInfo
                                customCls="custom-tooltip-input"
                                placement="left"
                                title={
                                  this.Messages.AGGREGATED_VALUE_DISPLAY_UNIT
                                }
                                helpKey="HELP_MONITOR_TRIGGER_AGGREGATED_VALUE_DISPLAY_UNIT"
                              />
                            </span>
                          </>
                        }
                      >
                        <div className="col-sm-8 no-padding">
                          <A10Select
                            className="col-sm-6 no-padding"
                            placeholder={
                              this.Messages.AGGREGATED_VALUE_DISPLAY_UNIT
                            }
                            onChange={(e: any) =>
                              this.handleChange(
                                'displayUnit',
                                e,
                                index,
                                `agg-value-display-unit-${index}`,
                              )
                            }
                            value={
                              !metadataUnits(expression, index, metadata)
                                ? ''
                                : DISPLAY_UNIT.get(
                                    metadataUnits(expression, index, metadata)
                                      ?.Measured_unit || 'msec',
                                  ).findIndex(
                                    (obj: IObject) =>
                                      obj.displayUnit ===
                                      metadataUnits(expression, index, metadata)
                                        ?.Display_unit,
                                  )
                            }
                          >
                            {metadataUnits(expression, index, metadata)
                              ? DISPLAY_UNIT.get(
                                  metadataUnits(expression, index, metadata)
                                    ?.Measured_unit || 'msec',
                                )?.map((obj: IObject, unitIndex: number) => {
                                  return (
                                    <A10Select.Option
                                      key={`agg-value-display-${obj.displayUnit}-${unitIndex}}`}
                                      value={unitIndex}
                                    >
                                      {obj.displayUnit}
                                    </A10Select.Option>
                                  )
                                })
                              : emptyOptions}
                          </A10Select>
                        </div>
                      </A10Form.Item>
                    </div>
                  </div>
                </A10Panel>
                {!!expression ||
                conditions[0].metricAgg === 'exponentialAvg' ||
                conditions[0].metricAgg === 'weightedAvg' ? (
                  <div className="row">&nbsp;</div>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="connectionLink" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 section-header">
                        {index === 0 ? (
                          <A10Select
                            className="col-sm-2 no-padding"
                            value={condition.condition}
                            onChange={(e: any) =>
                              this.handleChange('condition', e, index)
                            }
                          >
                            {this.Utilities.renderDropdownOpts(
                              'condition',
                              'TRIGGERS',
                            )}
                          </A10Select>
                        ) : (
                          <div className="selectedCondition">
                            {this.state.selectedCondition}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="connectionLink" />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )
          })}

          {!!expression ||
          conditions[0].metricAgg === 'exponentialAvg' ||
          conditions[0].metricAgg === 'weightedAvg' ? null : (
            <div className="row no-margin">
              <div className="col-md-12 addAnotherConditionContainer">
                <div
                  className="add-another-link no-margin"
                  onClick={this.addCondition}
                >
                  <span className="a10-icon a10-trigger-icon add-another" />
                  <span className="label addMoreLink">
                    Add to another Condition
                  </span>
                </div>
              </div>
            </div>
          )}

          <A10Panel
            key={'trigger_message'}
            title={
              <A10IconTextGroup
                text={
                  <>
                    Trigger Message
                    <span>
                      <HelpInfo
                        customCls="custom-tooltip-head"
                        placement="right"
                        title="Trigger Message"
                        helpKey="HELP_MONITOR_TRIGGER_MESSAGE_DESCRIPTION"
                      />
                    </span>
                  </>
                }
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            }
          >
            <div className="row no-border">
              <div className="col-md-12">
                <A10Input.TextArea
                  value={triggerMessage}
                  placeholder={triggerMessagePH}
                  onChange={this.handleChange.bind(this, 'triggerMessage')}
                />
              </div>
            </div>
          </A10Panel>
        </A10Form>
      </div>
    )
  }
}
export default setupA10Container(A10Form.create()(TriggerAddForm))
