import React from 'react'
import ReactLoading from 'react-loading'
import { Redirect } from 'react-router-dom'
import classnames from 'classnames'
import {
  _,
  axios,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
  getItem,
  setItem,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10Tag,
  A10Modal,
  A10DropdownMenu,
  A10Row,
  A10Col,
  A10Button,
  A10Icon,
  A10Input,
  A10Radio,
  A10Tooltip,
  A10Alert,
  A10Checkbox,
  A10Loader,
  hcKeys as countries,
  A10Tabs,
  A10Message,
} from '@gui-libraries/widgets'

import {
  InfrastructureService,
  DashboardService,
  Utilities,
  TroubleShootService,
} from 'src/services/index'
import { httpClient } from 'src/libraries/httpClient'
import { IDefaultMethods } from 'src/containers/Controller'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { AppRoot } from 'src/settings/appRoot'
import { InfraConfigs } from 'src/constants/InfraConfigs/InfraConfigs'
import { InfraStructureStatsService } from 'src/services/InfraStructureStatsService/InfraStructureStatsService'
import { DeviceDetails } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/Devices/DeviceDetails'
import { Partitions } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/Devices/Device/Partitions'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { DeviceLicenseForm } from 'src/containers/Controller/Infrastructure/Clusters/Forms/DeviceLicenseForm'
import { DeviceAddForm } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/Devices/Device/Forms/DeviceAddForm'
import CredentialsForm from 'src/containers/Controller/Infrastructure/Clusters/Cluster/Devices/Device/Forms/CredentialsForm'
import { ObjectExplorer } from 'src/components/shared/ObjectExplorer'
import ChangeDeviceForm from 'src/containers/Controller/Organization/License/Forms/ChangeDeviceForm'
import globalConfig from 'src/settings/config'
import SlidingWorkflowStatus from 'src/components/shared/WorkflowStatus/slidingForm'
import { LoadbalancerCharts } from 'src/components/shared/LoadbalancerCharts'
import { SummayWidgets } from 'src/components/shared/SummayWidgets'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import { Messages } from 'src/locale/en/index'
import { UpgradeModal } from 'src/components/shared/UpgradeModal'
import SlidingBackup from 'src/containers/Controller/TroubleShoot/Backup/BackupForm/slidingForm'
import SlidingUpgrade from 'src/containers/Controller/TroubleShoot/ImageUpgrade/UpgradeForm/SlidingUpgrade'
import SlidingWorkflowStatusList from 'src/components/shared/WorkflowStatus/slidingWorkflowListForm'
import SparcLineWrapper from 'src/components/shared/SparcLineWrapper'
import { DeviceConfigForm } from './Device/Forms/DeviceConfig'
import { AccessControlForm } from './Device/Forms/AccessControlForm'
import { clusterTypeMap } from '../../Clusters'
import Card from 'src/components/shared/Card'

import './styles/Devices.scss'
import styles from './styles/index.module.less'
import ActivateLicense from 'src/components/shared/ActivateLicense'

const { TabPane } = A10Tabs

export interface IDevicesProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  devices: any
  cluster: any
  fromClustersPage: boolean
  clusterConfigMgmt: string
  refreshClusterSession: (onlyClusterSesSet: boolean) => void
  /*** Props for GOE ***/
  isObjectExplorerMode?: boolean
  callbackObjectExplorerMode?: any
  openDeviceSubMenu?: any
  isSingleDeviceMode?: boolean
  singleDevice?: IObject
  clustersUpdated: boolean
  refreshClusterData: any
  rangeByTime?: any
}
export interface IDevicesState {
  devices: any[]
  columns: IObject[]
  deviceUpdated: boolean
  searchString: string
  selectedIndex: number
  allDevices: any[]
  deleteModalState: boolean
  forceDelete: boolean
  modalAction: string
  selectedDevice: any
  showSlidingPage: boolean
  showBackupSlidingPage: boolean
  showEditObject: boolean
  editMode: boolean
  licenseForm: boolean
  hcLicenseKey: string
  deviceLicenseKey: string
  allotedBandwidth: any
  activeLicenses: boolean[]
  slidingTitle: string
  slidingDesc: string
  slidingForm: string
  formData: any
  partitionsLoaded: boolean
  isLoading: boolean
  clustersUpdated: boolean
  loadingIcon: boolean
  rebootModalState: boolean
  workflowType: string
  workflowID: string
  workflowSliderState: boolean
  rebootType: string
  rebootLabel: any
  showUpgradePage: boolean
  showWorkflowStatusSlidingPage: boolean
  workflowIDList: string[]
  upgradeDeviceList: string[]
  redirectUrl: string
  popup: IPopup
  contentType: string
  isPeriodicAllowed: boolean
  maxBackups: number
  remainingBackups: number
  showUpgrade: boolean
  activeLicense: string
  showAddLicense: boolean
  disableSave: boolean
  updateDeviceData: IObject
}

export interface IDevice {
  deviceName: string
  cluster: string
  thunderIPAddress: string
  mgmtInterface: boolean
  // useV6: boolean
  port: string
  zone: string
  region: any
  location: string
  metricsExportInterval: number
  tenant: string
  newPartition: boolean
  userName: string
  userPassword: string
  licenseCheck: boolean
  licenseToken: string
  licenseObj: any
  numBandwidth: string
  bandwidthUnit: string
}

const NA = 'NA'

export const DEVICE_OBJ: IDevice = {
  deviceName: '',
  cluster: '',
  thunderIPAddress: '',
  mgmtInterface: true,
  port: '443',
  zone: undefined,
  region: undefined,
  location: '',
  metricsExportInterval: 60,
  tenant: '',
  newPartition: false,
  userName: '',
  userPassword: '',
  licenseCheck: false,
  licenseToken: '',
  licenseObj: '',
  numBandwidth: '',
  bandwidthUnit: '',
}

interface IPopup {
  visible: boolean
  record?: IObject
  rowHeight?: number
}

class Devices extends A10Container<IDevicesProps, IDevicesState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  ref = React.createRef()

  pageLength = 5
  DashboardService = new DashboardService()
  InfrastructureService = new InfrastructureService()
  TroubleShootService = new TroubleShootService()
  Utilities = new Utilities()
  InfraStructureStatsService = new InfraStructureStatsService()
  InfraConfigs = new InfraConfigs()
  AppRoot = new AppRoot()
  Messages = new Messages()
  dataLoaded = false
  Countries = countries.sort((a: any, b: any) => {
    return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
  })

  devices: any[] = []
  partitions: any[] = []
  nonLicenseDevice = 0
  warnLicenseDevice = 0
  devAuthFailed = true

  childForm: any = null
  licenseFormData = {
    hcLicenseNewKey: '',
    deviceLicenseNewKey: '',
    newBandwidth: '',
    newBandwidthUnit: '',
    numBandwidth: '',
    numBandwidthUnit: '',
  }

  adminLevel
  isOperatorUser
  // Cancel token used to cancel the http call when component gets unmounted
  CancelToken = axios.CancelToken
  source = this.CancelToken.source()

  statColumns = [
    {
      name: 'Health',
      data: NA,
      unit: '',
      level: 'warn',
    },
    {
      name: 'Device License',
      data: '--',
      unit: '',
      level: 'warn',
    },
    {
      name: 'Harmony License',
      data: 'UNLICENSED',
      unit: '',
      level: 'warn',
    },
    {
      name: 'Throughput',
      data: NA,
      unit: 'bps',
      level: 'warn',
    },
    {
      name: 'Connection Rate',
      data: '0',
      unit: '/s',
      level: 'warn',
    },
    {
      name: 'CPU',
      data: '0',
      unit: '%',
      level: 'warn',
    },
    {
      name: 'Memory',
      data: '0',
      unit: '%',
      level: 'warn',
    },
  ]

  columns: IObject
  constructor(
    props: IDevicesProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)
    const {
      storage: {
        get: { ADMIN_LEVEL, IS_OPERATOR_USER },
      },
    } = context

    this.adminLevel = ADMIN_LEVEL
    this.isOperatorUser = IS_OPERATOR_USER

    this.columns = [
      {
        title: '',
        dataIndex: 'deviceHealth',
        key: 'deviceHealth',
        width: '30px',
        className: styles.statusTitle,
        sorter: (a: any, b: any) =>
          this.Utilities.sortNumber(a, b, 'deviceHealth'),
        render: (text: string, record: any, index: number) => {
          let status = record.deviceHealth ?? NA
          let tooltip = ''
          if (status === NA) {
            status = 'undefined'
            tooltip = 'undefined'
          } else if (status === 0) {
            status = 'stopped'
            tooltip = 'Bad'
          } else if (status >= 0.8) {
            status = 'ongoing'
            tooltip = 'Good'
          } else {
            status = 'warning'
            tooltip = 'Average'
          }
          return (
            <div>
              <HealthStatus
                type={status}
                tooltip={this.rowToolTip(record)}
                toolTipClassName="toolTipWidth"
                toolTipArrowPosition="topLeft"
              />
            </div>
          )
        },
      },
      {
        title: 'Device Name',
        dataIndex: 'name',
        key: 'name',
        className: 'td-truncate-min',
        render: (text: any, record: any) => {
          const { fromClustersPage } = this.props
          const { popup } = this.state

          const clockDrift =
            (record['device-oper-status'] &&
              record['device-oper-status']['clock-drift']) ||
            ''
          const networkConnectivity =
            (record['device-oper-status'] &&
              record['device-oper-status']['network-connectivity']) ||
            ''
          let error = false,
            info = false
          if (
            clockDrift.toLowerCase().startsWith('error') ||
            networkConnectivity.toLowerCase().startsWith('error')
          ) {
            error = true
          } else if (
            clockDrift.toLowerCase().startsWith('info') ||
            networkConnectivity.toLowerCase().startsWith('info')
          ) {
            info = true
          }

          const showDropdown =
            !fromClustersPage &&
            popup.visible &&
            popup.record?.key === record.key

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <A10Tooltip
                title={
                  clockDrift || networkConnectivity ? (
                    <div style={{ width: '350px' }}>
                      {clockDrift ? clockDrift : ''}
                      {clockDrift &&
                      networkConnectivity &&
                      clockDrift !== networkConnectivity ? (
                        <>
                          <br />
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      {networkConnectivity && clockDrift !== networkConnectivity
                        ? networkConnectivity
                        : ''}
                    </div>
                  ) : (
                    this.rowToolTip(record)
                  )
                }
                placement="topLeft"
                overlayClassName="toolTipWidth"
              >
                <div className={styles.deviceName}>
                  {clockDrift !== '' || networkConnectivity !== '' ? (
                    <span>
                      <A10Icon
                        type={
                          error ? 'warning' : info ? 'info-circle' : 'warning'
                        }
                        style={{
                          color: error ? 'red' : info ? 'blue' : 'orange',
                        }}
                      />
                    </span>
                  ) : null}
                  <span
                    className={classnames({
                      [styles.title]: true,
                      healthStatus:
                        clockDrift !== '' || networkConnectivity !== '',
                    })}
                  >
                    {text}
                  </span>
                </div>
              </A10Tooltip>
              {showDropdown && this.renderActionDropdown(record)}
            </div>
          )
        },
        sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
      },
      ...(!this.props.fromClustersPage
        ? [
            {
              title: 'Cluster Name',
              dataIndex: 'clusterDisplayName',
              key: 'clusterDisplayName',
              className: 'td-truncate-min',
              render: (clusterDisplayName: string, record: IObject) => {
                return (
                  <A10Tooltip
                    title={this.rowToolTip(record)}
                    placement="topLeft"
                    overlayClassName="toolTipWidth"
                  >
                    {clusterDisplayName}
                  </A10Tooltip>
                )
              },
              sorter: (a: any, b: any) =>
                this.Utilities.sortString(a, b, 'clusterDisplayName'),
            },
          ]
        : []),
      {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
        sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'model'),
      },
      {
        title: 'VCS State',
        dataIndex: 'vcsState',
        key: 'vcsState',
        sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'vcsState'),
      },
      {
        title: 'Mgmt. IP',
        dataIndex: 'mgmt-ip',
        key: 'mgmt-ip',
        render: (text: string, record: any, index: number) => {
          return (
            <A10Tooltip
              title={this.rowToolTip(record)}
              placement="topLeft"
              overlayClassName="toolTipWidth"
            >
              <div>
                {record['mgmt-ip'] ? (
                  <A10Tag>{record['mgmt-ip']}</A10Tag>
                ) : null}
              </div>
            </A10Tooltip>
          )
        },
        sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'mgmt-ip'),
      },
      {
        title: this.Messages.ACOS_VERSION,
        dataIndex: 'primary-sw-version',
        key: 'primary-sw-version',
        className: 'td-truncate-min',
        render: (acosVersion: any, record: any) => {
          return (
            <A10Tooltip
              title={this.rowToolTip(record)}
              placement="topLeft"
              overlayClassName="toolTipWidth"
            >
              {!!acosVersion ? acosVersion.split(' ')[0] : ''}
            </A10Tooltip>
          )
        },
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'primary-sw-version'),
      },
      {
        title: 'BPS',
        className: 'text-center',
        dataIndex: 'bpsChart',
        key: 'bpsChart',
        render: (text: any, record: any, index: number) => {
          const chartData = record.bpsChart || []
          let count = 0,
            chartDataSeries: any
          const seriesName: string = record.name
          if (chartData.length > 0) {
            if (chartData[0] && chartData[0].data) {
              chartDataSeries = chartData[0].data
            }
            count = chartData[0].data.reduce((total: any, currentVal: any) => {
              return total + currentVal[1]
            }, 0)
          }
          let chartConfig = _.cloneDeep(this.Utilities.SPARCLINE_CHART_CONFIG)
          chartConfig.tooltip['formatter'] = this.Utilities.bpsTooltipFormatter
          return (
            <div key={'bpsChart-' + index++} className="inline">
              <div className="col-sm-12 no-padding">
                <SparcLineWrapper
                  chartDataSeries={chartDataSeries}
                  seriesName={seriesName}
                  chartConfig={chartConfig}
                />
              </div>
            </div>
          )
        },
      },
      {
        title: 'CPU Util.',
        className: 'text-center',
        dataIndex: 'cpuUtilChart',
        key: 'cpuUtilChart',
        render: (text: any, record: any, index: number) => {
          const chartData = record.cpuUtilChart || []
          let count = 0
          let chartDataSeries: any
          const seriesName: string = record.name
          if (chartData.length > 0) {
            if (chartData[0] && chartData[0].data) {
              chartDataSeries = chartData[0].data
            }
            count = chartData[0].data.reduce((total: any, currentVal: any) => {
              return total + currentVal[1]
            }, 0)
          }
          let chartConfig = _.cloneDeep(this.Utilities.SPARCLINE_CHART_CONFIG)
          chartConfig.tooltip[
            'formatter'
          ] = this.Utilities.cpuUtilTooltipFormatter
          return (
            <div key={'cpuUtilChart-' + index++} className="inline">
              <div className="col-sm-12 no-padding">
                <SparcLineWrapper
                  chartDataSeries={chartDataSeries}
                  seriesName={seriesName}
                  chartConfig={chartConfig}
                />
              </div>
            </div>
          )
        },
      },
    ]

    if (props.fromClustersPage) {
      this.columns.push({
        title: '',
        dataIndex: '',
        key: '',
        render: this.renderActionDropdown,
      })
    }

    this.state = {
      devices: [[]],
      columns: [],
      deviceUpdated: false,
      searchString: '',
      selectedIndex: 0,
      allDevices: [],
      deleteModalState: false,
      forceDelete: false,
      modalAction: '',
      selectedDevice: { name: '', cluster: '' },
      showSlidingPage: false,
      showBackupSlidingPage: false,
      showEditObject: false,
      editMode: false,
      licenseForm: false,
      hcLicenseKey: '',
      deviceLicenseKey: '',
      allotedBandwidth: '',
      activeLicenses: [false, false],
      slidingTitle: '',
      slidingDesc: '',
      slidingForm: '',
      formData: [JSON.parse(JSON.stringify(DEVICE_OBJ))],
      partitionsLoaded: false,
      isLoading: true,
      clustersUpdated: this.props.clustersUpdated,
      loadingIcon: false,
      rebootModalState: false,
      workflowType: '',
      workflowID: '',
      workflowSliderState: false,
      rebootType: '',
      rebootLabel: {
        default: '',
        priLabel: 'Primary',
        secLabel: 'Secondary',
      },
      showUpgradePage: false,
      showWorkflowStatusSlidingPage: false,
      workflowIDList: [],
      upgradeDeviceList: [],
      redirectUrl: '',
      popup: { visible: false } as IPopup,
      contentType: 'overview',
      isPeriodicAllowed: false,
      maxBackups: -1,
      remainingBackups: 0,
      showUpgrade: false,
      activeLicense: '',
      showAddLicense: false,
      disableSave: false,
      updateDeviceData: {},
    }
  }

  rowToolTip = (record: any) => {
    const status = record.deviceHealth ?? NA
    let healthText = ''
    if (status === NA) {
      healthText = NA
    } else if (status === 0) {
      healthText = 'Bad'
    } else if (status >= 0.8) {
      healthText = 'Good'
    } else {
      healthText = 'Average'
    }

    return (
      <div className="tableOverToolTip maxWidth">
        <table>
          <tbody>
            <tr>
              <th>Device Name</th>
              <td>{record.name}</td>
            </tr>
            <tr>
              <th>Cluster Name</th>
              <td>{record.cluster}</td>
            </tr>
            <tr>
              <th>Health</th>
              <td>{healthText}</td>
            </tr>
            <tr>
              <th>Location</th>
              <td> {record.location}</td>
            </tr>
            <tr>
              <th>ACOS Version</th>
              <td>{record['primary-sw-version']}</td>
            </tr>
            <tr>
              <th>Model</th>
              <td>{record.model}</td>
            </tr>
            <tr>
              <th>Avg CPU</th>
              <td>{`${record.avgcpu || NA} (Data: ${record.avgdatacpu ||
                NA} Control: ${record.avgcontrolcpu || NA})`}</td>
            </tr>
            <tr>
              <th>Avg Memory</th>
              <td>{record.avgmem}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  checkBootImage = (device: any) => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context

    const bootImageCheck = this.InfrastructureService.bootImageCheck(
      null,
      null,
      [provider, device.name],
    )
    bootImageCheck
      .then((resp: any) => {
        const bootImage = resp?.data?.bootimage,
          rebootLabel = {
            default: '',
            priLabel: 'Primary',
            secLabel: 'Secondary',
          }
        let rebootType = ''
        if (!!bootImage) {
          rebootType = bootImage['hd-default'] === 'hd-pri' ? 'pri' : 'sec'
          rebootLabel.default = rebootType
          rebootLabel.priLabel = `Primary ${
            bootImage['hd-pri'] ? '(' + bootImage['hd-pri'] + ')' : ''
          }`
          rebootLabel.secLabel = `Secondary ${
            bootImage['hd-sec'] ? '(' + bootImage['hd-sec'] + ')' : ''
          }`
        }
        this.setState({
          rebootType,
          rebootLabel,
          rebootModalState: true,
          selectedDevice: device,
        })
      })
      .catch((err: any) => {
        this.setState({
          rebootType: '',
          rebootLabel: {
            default: '',
            priLabel: 'Primary',
            secLabel: 'Secondary',
          },
          rebootModalState: true,
          selectedDevice: device,
        })
      })
  }

  featureCheck = (device: any, payload: any, type: string) => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context
    const { showMessage } = this.Utilities

    const featureCheck = this.InfrastructureService.featureCheck(
      null,
      payload,
      [provider, 'device', device.name],
    )
    featureCheck
      .then((resp: any) => {
        const feature = resp.data[payload.features[0]]
        if (feature && type === 'device_reboot') {
          this.checkBootImage(device)
        } else {
          this.Utilities.checkFeatureMsg('device', device.name)
        }
      })
      .catch((error: any) => {
        if (!this.Utilities.isRBACAccessDenied(error)) {
          const message = error?.response?.data?.message
            ? error.response.data.message
            : ''
          showMessage('', 0, 0, message, 10)
        }
      })
  }

  onViewWorkflowStatus = (id: string, device: IObject) => {
    this.setState({
      selectedDevice: device,
      workflowID: id,
      workflowSliderState: true,
    })
  }

  rebootDeviceConfirm = () => {
    const {
      storage: {
        get: { PROVIDER: provider, ENCODED_SESSION_ID },
      },
    } = this.context

    const { showMessage } = this.Utilities
    if (!this.state.rebootType) {
      showMessage('Please select the image to reboot from', 1, 0)
      return
    }
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      provider,
      Authorization: ENCODED_SESSION_ID,
    }
    const payload: any = {
      'device-reboot': {
        'write-mem': true,
        'boot-image': this.state.rebootType,
      },
    }

    const rebootDevice = this.InfrastructureService.rebootDevice(
      headers,
      payload,
      [provider, this.state.selectedDevice.name],
    )
    this.setState({
      loadingIcon: true,
    })
    rebootDevice
      .then((resp: any) => {
        const message =
          'Device queued for reboot, Please check workflow id ' +
          resp.data.workflow.uuid +
          ' to check status.'
        // A10Message.success(message, 4, close)
        this.setSlidingPage(false)
        this.setState({
          loadingIcon: false,
          rebootType: '',
          rebootModalState: false,
          workflowType: 'reboot',
          workflowID: resp.data.workflow.uuid,
          workflowSliderState: true,
        })
        this.loadDevices()
      })
      .catch((error: any) => {
        if (!this.Utilities.isRBACAccessDenied(error)) {
          const messageKey = 'FAILED_TO_REBOOT'
          showMessage(messageKey, 1, 1)
        }
        this.setState({
          loadingIcon: false,
          rebootType: '',
          rebootModalState: false,
          workflowSliderState: false,
        })
        this.setSlidingPage(false)
      })
  }

  addDevice = () => {
    const deviceObj = JSON.parse(JSON.stringify(DEVICE_OBJ))
    deviceObj.cluster = this.props.cluster ? this.props.cluster.name : ''
    this.setState({
      formData: [deviceObj],
      editMode: false,
      showSlidingPage: true,
      licenseForm: false,
      slidingTitle: 'Add New Device',
      slidingDesc: 'Please provide the detail to connect a device',
      slidingForm: 'addDevice',
    })
  }

  editDevice = (device: any) => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context

    const deviceResp = this.InfrastructureService.getDevice(null, null, [
      provider,
      device.name,
    ])

    deviceResp
      .then((resp: any) => {
        if (resp && resp.data && resp.data['device']) {
          const deviceObj = resp.data['device']
          const resultObj = this.Countries.filter(obj => {
            return (
              obj.key === (deviceObj?.region?.toString() || '').toLowerCase()
            )
          })
          let region = {}
          if (resultObj) {
            region = resultObj[0]
          }
          const encryptedPassword = Buffer.from(
            deviceObj?.password || '',
          ).toString('base64')
          this.setState({
            formData: [
              {
                deviceName: deviceObj.name,
                thunderIPAddress: deviceObj.host,
                mgmtInterface: deviceObj['management-interface'] ? true : false,
                // useV6: deviceObj['use-ipv6'] ? true : false,
                zone: deviceObj.zone,
                region: !!region ? JSON.stringify(region) : undefined,
                metricsExportInterval: deviceObj['metrics-export-interval'],
                location: deviceObj.location
                  ? deviceObj.location.toString()
                  : '',
                userName: deviceObj.username,
                userPassword: encryptedPassword,
                cluster: deviceObj.cluster,
                tenant: deviceObj['associate-to-tenant'],
                newPartition:
                  deviceObj['create-new-partitions'] === 'true' ? true : false,
                port: deviceObj['mgmt-port-secure'].toString(),
              },
            ],
            selectedDevice: deviceObj,
            editMode: true,
            showSlidingPage: true,
            licenseForm: false,
            slidingTitle: 'View Device',
            slidingDesc: '',
            slidingForm: 'editDevice',
          })
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  openLicenseUpdate = async (deviceSummary: any) => {
    let device = await this.getDeviceDetailObj(deviceSummary)
    let hcLicenseKey = '',
      deviceLicenseKey = '',
      allotedBandwidth = '',
      activeLicenses = [false, false]
    device['licenseinfo-list'] &&
      device['licenseinfo-list'].map((licObj: any) => {
        if (licObj['license-type'] === 'HC_LICENSE') {
          hcLicenseKey = licObj['lic-key']
          if (licObj['license-state'] === 'HC_LICENSE_AVAILABLE') {
            activeLicenses[0] = true
          }
        } else if (
          licObj['license-type'] === 'DEVICE_LICENSE' ||
          ((device['model'] === 'vThunder' || device['model'] === 'cThunder') &&
            (licObj['license-type'] === 'EXTERNAL_DEVICE_LICENSE' ||
              licObj['license-type'] === 'PERPETUAL_LICENSE'))
        ) {
          deviceLicenseKey =
            (licObj['lic-key'] || '') +
            (licObj['license-type'] === 'EXTERNAL_DEVICE_LICENSE'
              ? '-EXTERNAL-'
              : '')
          if (licObj['license-state'] === 'ACTIVATED') {
            activeLicenses[1] = true
            allotedBandwidth = licObj['device-bandwidth']
            const bandwidthObj = this.Utilities.megaToGiga(
              licObj['device-bandwidth'] || 0,
            )
            this.licenseFormData.deviceLicenseNewKey = licObj['lic-key']
            this.licenseFormData.newBandwidth = bandwidthObj.val
            this.licenseFormData.newBandwidthUnit = bandwidthObj.unit
            this.licenseFormData.numBandwidth = bandwidthObj.val
            this.licenseFormData.numBandwidthUnit = bandwidthObj.unit
          }
        } else {
          deviceLicenseKey = null
        }
      })

    this.setState({
      editMode: false,
      selectedDevice: device,
      showSlidingPage: true,
      licenseForm: true,
      slidingTitle: 'Update License',
      slidingDesc: 'Select license key',
      slidingForm: 'changeLicense',
      hcLicenseKey,
      deviceLicenseKey,
      allotedBandwidth,
      activeLicenses,
    })
  }
  onFormChange = (args: any) => {
    this.licenseFormData = args
  }
  addLicense = async () => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    this.setState({
      disableSave: true,
    })
    A10Message.loading('Updating the license', 3000)

    const {
      EPIC_DEPENDENCIES: { httpClient },
    } = globalConfig
    let nothingUpdated = false

    if (this.licenseFormData.hcLicenseNewKey) {
      const url = `/hls/licenses/providers/${PROVIDER}/change/device/${this.state.selectedDevice['device-uuid']}/HC_LICENSE/newkey/${this.licenseFormData.hcLicenseNewKey}`
      try {
        const { data } = await httpClient.post(url, null, {
          absoluteBasePath: true,
        })
        if (data || true) {
          this.Utilities.showMessage('HARMONY_LICENSE_CHANGE_SUCCESS', 1, 1)
          // this.loadDevices()
        }
      } catch (error) {
        if (!this.Utilities.isRBACAccessDenied(error)) {
          const msg = error?.response?.data?.message
            ? error.response.data.message
            : ''
          this.Utilities.showMessage('HARMONY_LICENSE_CHANGE_FAIL', 0, 1, msg)
        }
      }
    } else {
      nothingUpdated = true
    }

    const devLicKey = this.licenseFormData.deviceLicenseNewKey
      ? this.licenseFormData.deviceLicenseNewKey
      : this.state.deviceLicenseKey
    let devLicBW: any = this.licenseFormData.deviceLicenseNewKey
      ? this.licenseFormData.newBandwidth
      : this.licenseFormData.numBandwidth
    const devLicBWU = this.licenseFormData.deviceLicenseNewKey
      ? this.licenseFormData.newBandwidthUnit
      : this.licenseFormData.numBandwidthUnit
    const bandwidthObj = this.Utilities.megaToGiga(
      this.state.allotedBandwidth || 0,
    )

    if (
      devLicKey &&
      (devLicKey !== this.state.deviceLicenseKey ||
        bandwidthObj.val !== devLicBW ||
        bandwidthObj.unit !== devLicBWU)
    ) {
      if (devLicBW < 1000) {
        devLicBW = parseFloat(devLicBW) * 1000
      }
      const url = `/hls/licenses/providers/${PROVIDER}/change/device/${this.state.selectedDevice['device-uuid']}/DEVICE_LICENSE/newkey/${devLicKey}/bandwidth/${devLicBW}`
      try {
        const { data } = await httpClient.post(url, null, {
          absoluteBasePath: true,
        })
        if (data || true) {
          this.Utilities.showMessage('DEVICE_LICENSE_CHANGE_SUCCESS', 1, 1)
          this.loadDevices()
        }
      } catch (error) {
        this.loadDevices()
        if (!this.Utilities.isRBACAccessDenied(error)) {
          const msg = error?.response?.data?.message
            ? error.response.data.message
            : ''
          this.Utilities.showMessage('DEVICE_LICENSE_CHANGE_FAIL', 0, 1, msg)
        }
      }
    } else {
      if (nothingUpdated) {
        this.Utilities.showMessage('No changes done to update', 2, 0)
      } else {
        this.loadDevices()
      }
    }

    this.setState({
      disableSave: false,
    })
    this.setSlidingPage(false)
  }

  refreshDevices = () => {
    this.loadDevices()
    this.setState({ searchString: '' })
  }

  saveDeviceSessionInfo = (devices: any[]) => {
    const {
      storage: {
        set,
        get: { ALLDEVICES },
      },
    } = this.context

    if (!this.props.cluster) {
      set.ALLDEVICES(devices)
    } else {
      const allDevices = ALLDEVICES
      const sessionDevices: any[] = []
      allDevices.map((device: any) => {
        if (device.cluster === this.props.cluster.name) {
          const deviceObj = devices.find((dev: any) => {
            return dev.name === device.name
          })
          if (!!deviceObj) {
            sessionDevices.push(deviceObj)
          }
        } else {
          sessionDevices.push(device)
        }
      })

      set.ALLDEVICES(sessionDevices)
    }
  }

  loadDevices = async () => {
    try {
      const {
        storage: {
          get: { PROVIDER: provider },
          set,
        },
      } = this.context
      const { cluster } = this.props
      let devices = cluster?.['referrer-list'] || []

      if (!cluster) {
        const headerObj =
          this.adminLevel === 'provider'
            ? null
            : this.Utilities.getXAccountHeaderDetails(false)

        const res = await this.InfrastructureService.getDevicesSummary(
          headerObj,
          null,
          [provider],
        )
        devices = res?.data?.['device-list'] || []
      }

      this.saveDeviceSessionInfo(devices)
      set.DEVICES_EXISTS(devices?.length > 0)
      devices.forEach((device: any, index: any) => {
        device.clusterDisplayName = device.cluster
      })
      this.devices = devices
      this.dataLoaded = true
      this.setState({
        allDevices: devices,
        devices,
        isLoading: false,
        clustersUpdated: this.props.clustersUpdated,
        deviceUpdated: !this.state.deviceUpdated,
      })
      if (devices.length > 0) {
        this.getAllDeviceStats(devices)
        if (!this.props.fromClustersPage) {
        }
      }
    } catch (e) {
      console.log(e.response) // we need to create a centralised error handling
      this.dataLoaded = true
    }
  }

  getConnectionRate() {
    const {
      storage: {
        get: { PROVIDER, ENCODED_SESSION_ID },
      },
    } = this.context

    let xAccount = this.Utilities.getAllXAccountIDs()
    if (this.adminLevel !== 'provider') {
      const tenantObj = this.Utilities.getCurrentTenantObject()
      xAccount = tenantObj?.uuid
    }
    const deviceList = this.state.allDevices
    if (xAccount === '' || !xAccount) {
      deviceList.forEach((deviceObj: any) => {
        deviceObj.statColumns[4].data = '0'
        deviceObj.statColumns[4].level = 'warn'
      })

      this.setState({
        allDevices: deviceList,
        devices: deviceList,
        isLoading: false,
      })
      return
    }

    const deviceUUID = deviceList.reduce(
      (resObj: string[], refDevice: IObject) => {
        if (refDevice['device-uuid']) resObj.push(refDevice['device-uuid'])
        return resObj
      },
      [],
    )

    const header = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      provider: PROVIDER,
      Authorization: ENCODED_SESSION_ID,
      'X-Account': xAccount,
    }

    const to = Date.now()
    const from = Date.now() - 2 * 60 * 1000
    const payload = {
      connRate: {
        fields: ['total_l7_conn'],
        aggregation: 'sum',
        sort: 'asc',
        rangeby: {
          start: from,
          end: to,
          field: 'ts',
        },
        histogram: {
          field: 'ts',
          interval: 60000,
        },
        groupby: 'device_uuid',
        size: this.state.allDevices.length,
      },
    }

    if (this.props.cluster) {
      payload.connRate['filterby'] = {
        and: {
          device_uuid: deviceUUID.join('|'),
          cluster_id: this.props.cluster['cluster-uuid'],
        },
      }
    } else {
      payload.connRate['filterby'] = {
        and: {
          device_uuid: deviceUUID.join('|'),
        },
      }
    }
    const deviceStatsReq = this.InfraStructureStatsService.getTenantBandwidthStats(
      header,
      payload,
      null,
      this.source.token,
    )
    deviceStatsReq
      .then((response: any) => {
        payload.connRate.fields = ['pc_sent', 'pc_drop']
        const deviceStatsReq2 = this.InfraStructureStatsService.getPartitionStats(
          header,
          payload,
          null,
        )
        deviceStatsReq2
          .then((response2: any) => {
            const connRateData = response.data.connRate
            const connRateData2 = response2.data.connRate
            let deviceList = this.state.allDevices
            deviceList.map((obj: any) => {
              if (
                connRateData[obj['device-uuid']] &&
                connRateData2[obj['device-uuid']]
              ) {
                let l7 = this.Utilities.getLatestRecordFromBucket(
                  connRateData[obj['device-uuid']]['total_l7_conn_sum'],
                )
                let pcs =
                  this.Utilities.getLatestRecordFromBucket(
                    connRateData2[obj['device-uuid']]['pc_sent_sum'],
                  ) +
                  this.Utilities.getLatestRecordFromBucket(
                    connRateData2[obj['device-uuid']]['pc_drop_sum'],
                  )
                let connRate = (l7 + pcs) / 60
                obj.statColumns[4].data = Math.round(connRate)
                obj.statColumns[4].level =
                  connRate > 74 ? 'warn' : connRate > 89 ? 'error' : 'success'
              } else {
                obj.statColumns[4].data = '0'
                obj.statColumns[4].level = 'success'
              }
            })
            this.setState({
              allDevices: deviceList,
              devices: deviceList,
              isLoading: false,
            })
          })
          .catch((error: any) => {
            console.log(error)
          })
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  setLicenseDetails = (deviceObj: IObject, newLicDevices?: IObject[]) => {
    const oldLicDevices = deviceObj['licenseinfo-list']
    const licDevices = newLicDevices || oldLicDevices
    let hcLicenseCheck = false
    licDevices &&
      licDevices.map((licDevice: any) => {
        if (
          licDevice['license-type'] &&
          licDevice['license-type'] === 'HC_LICENSE'
        ) {
          hcLicenseCheck = true
          const bandwidth = this.Utilities.megaToGiga(
            licDevice['device-bandwidth'],
          )
          if (
            licDevice['license-error-type'] === 'WARNING' ||
            licDevice['license-error-type'] === 'ERROR'
          ) {
            deviceObj.statColumns[2].tooltip = licDevice['notes']
            this.warnLicenseDevice++
          }
          if (licDevice['license-state'] === 'HC_LICENSE_AVAILABLE') {
            deviceObj.hcLicense = licDevice['device-bandwidth']
              ? bandwidth.val + (bandwidth.unit === 'm' ? 'Mbps' : 'Gbps')
              : '0Mbps'

            deviceObj.statColumns[2].data = licDevice['device-bandwidth']
              ? bandwidth.val + (bandwidth.unit === 'm' ? 'M' : 'G')
              : '0M'
            deviceObj.statColumns[2].unit = 'bps'
            deviceObj.statColumns[2].level = licDevice['device-bandwidth']
              ? licDevice['license-error-type'] === 'INFO'
                ? 'success'
                : licDevice['license-error-type'] === 'WARNING'
                ? 'warn'
                : 'error'
              : 'success'
          } else {
            deviceObj.hcLicense = 'UNLICENSED'

            deviceObj.statColumns[2].data = 'UNLICENSED'
            deviceObj.statColumns[2].unit = ''
            deviceObj.statColumns[2].level =
              licDevice['license-error-type'] === 'WARNING' ? 'warn' : 'error'
          }
          deviceObj.hcLicenseClassName =
            licDevice['license-error-type'] === 'INFO'
              ? ''
              : licDevice['license-error-type'] === 'WARNING'
              ? 'warning-lic-class'
              : 'danger-lic-class'
        } else {
          const bandwidth = this.Utilities.megaToGiga(
            licDevice['device-bandwidth'],
          )
          if (licDevice['license-type'] === 'EXTERNAL_DEVICE_LICENSE') {
            deviceObj.statColumns[1].tooltip = 'External Device License'
          } else if (licDevice['license-type'] === 'PERPETUAL_LICENSE') {
            deviceObj.statColumns[1].tooltip =
              'Perpetual License' +
              (licDevice['lic-key'] ? ' - ' + licDevice['lic-key'] : '')
          }
          if (licDevice['license-state'] === 'FAILED') {
            deviceObj.statColumns[1].tooltip = licDevice['notes']
          }

          if (licDevice['license-state'] === 'ACTIVATED') {
            deviceObj.deviceLicense =
              licDevice['device-bandwidth'] && bandwidth.val > 0
                ? bandwidth.val + (bandwidth.unit === 'm' ? 'Mbps' : 'Gbps')
                : 'LICENSED'

            deviceObj.statColumns[1].data =
              licDevice['device-bandwidth'] && bandwidth.val > 0
                ? bandwidth.val + (bandwidth.unit === 'm' ? 'M' : 'G')
                : 'LICENSED'
            deviceObj.statColumns[1].unit =
              deviceObj.statColumns[1].data === 'LICENSED' ? '' : 'bps'
            deviceObj.statColumns[1].level = 'success'
            if (
              licDevice['license-type'] === 'PERPETUAL_LICENSE' &&
              licDevice['lic-key'].toLowerCase() !== 'vthunder' &&
              licDevice['lic-key'].toLowerCase() !== 'cthunder'
            ) {
              deviceObj.deviceLicense = licDevice['lic-key']
              deviceObj.statColumns[1].data = licDevice['lic-key']
            }
          } else {
            deviceObj.deviceLicense = 'UNLICENSED'

            deviceObj.statColumns[1].data = 'UNLICENSED'
            deviceObj.statColumns[1].unit = ''
            deviceObj.statColumns[1].level =
              licDevice['license-state'] === 'PENDING' ? 'warn' : 'error'
          }
          deviceObj.deviceLicenseClassName =
            licDevice['license-state'] === 'ACTIVATED'
              ? ''
              : licDevice['license-state'] === 'PENDING'
              ? 'warning-lic-class'
              : 'danger-lic-class'
        }

        if (deviceObj.statColumns[1].data === '--') {
          deviceObj.statColumns[1].tooltip = 'Check device for license'
          deviceObj.statColumns[1].level = ''
        }
      })

    if (!newLicDevices && !hcLicenseCheck) {
      this.nonLicenseDevice++
    } else if (!!newLicDevices && !!hcLicenseCheck) {
      this.nonLicenseDevice--
    }

    return deviceObj
  }

  updateDeviceLicense = (deviceObj: IObject) => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context

    const deviceLicense = this.InfrastructureService.getDeviceLicenseDetails(
      null,
      null,
      [provider, deviceObj.name],
    )
    deviceLicense
      .then((resp: IObject) => {
        let deviceList = this.state.allDevices
        const newLicDevices = resp?.data?.['licenseinfo-list'] || []
        deviceList.map((obj: any) => {
          if (obj.name === deviceObj.name && obj.uuid === deviceObj.uuid) {
            obj['licenseinfo-list'] = newLicDevices
            obj = this.setLicenseDetails(obj, newLicDevices)
            this.setState({ updateDeviceData: obj })
          }
        })
        this.setState({
          allDevices: deviceList,
          devices: deviceList,
        })
      })
      .catch((err: any) => {
        const msg = err?.response?.data?.message
          ? err.response.data.message
          : ''
        this.Utilities.showMessage(
          'Error while check of device license',
          0,
          0,
          msg,
        )
      })
  }

  // On-demand get device detail information
  getDeviceDetailObj = async (deviceSummary: IObject) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const res = await this.InfrastructureService.getDevice(null, null, [
      PROVIDER,
      deviceSummary?.name,
    ])

    return res?.data?.device || {}
  }

  getAllDeviceStats(allDevices: IObject[]) {
    const {
      storage: {
        get: { PROVIDER, PROVIDER_ID, ENCODED_SESSION_ID },
      },
    } = this.context

    let xAccount = this.Utilities.getAllXAccountIDs()
    if (this.adminLevel !== 'provider') {
      const tenantObj = this.Utilities.getCurrentTenantObject()
      xAccount = tenantObj?.uuid
    }
    const deviceList = allDevices
    if (xAccount === '' || !xAccount) {
      this.nonLicenseDevice = 0
      deviceList.forEach((deviceObj: any) => {
        deviceObj.memory = NA
        deviceObj.maxmem = NA
        deviceObj.avgmem = NA
        deviceObj.session = NA
        deviceObj.dataCpu = `${NA} / ${NA}`
        deviceObj.controlCpu = `${NA} / ${NA}`
        deviceObj.overallCpu = `${NA} / ${NA}`
        deviceObj.maxcpu = NA
        deviceObj.avgcpu = NA
        deviceObj.avgdatacpu = NA
        deviceObj.avgcontrolcpu = NA
        deviceObj.hcLicense = 'UNLICENSED'
        deviceObj.hcLicenseClassName = 'warning-lic-class'
        deviceObj.deviceLicense = '--'
        deviceObj.deviceLicenseClassName = 'warning-lic-class'

        deviceObj['statColumns'] = JSON.parse(JSON.stringify(this.statColumns))
        deviceObj.statColumns[3].data = '0'
        deviceObj.statColumns[5].data = '0'
        deviceObj.statColumns[6].data = '0'

        deviceObj.statColumns[3].level = 'warn'
        deviceObj.statColumns[5].level = 'warn'
        deviceObj.statColumns[6].level = 'warn'

        if (deviceObj['licenseinfo-list']) {
          deviceObj = this.setLicenseDetails(deviceObj)
        } else {
          deviceObj.statColumns[1].data = '--'
          deviceObj.statColumns[1].tooltip = 'Check device for license'
          deviceObj.statColumns[1].unit = ''
          deviceObj.statColumns[1].level = ''
          deviceObj.statColumns[2].data = 'UNLICENSED'
          deviceObj.statColumns[2].unit = ''
          deviceObj.statColumns[2].level = 'warn'
          this.nonLicenseDevice++
        }

        deviceObj.deviceCpuStats = []
      })

      this.setState({
        allDevices: deviceList,
        devices: deviceList,
        isLoading: false,
      })
      return
    }

    const deviceUUID = deviceList.reduce(
      (resObj: string[], refDevice: IObject) => {
        if (refDevice['device-uuid']) resObj.push(refDevice['device-uuid'])
        return resObj
      },
      [],
    )

    const header = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      provider: PROVIDER,
      Authorization: ENCODED_SESSION_ID,
      'x-providerids': PROVIDER_ID,
    }

    const to = Date.now()
    const from = Date.now() - 2 * 60 * 1000
    const payload = {
      deviceStats: {
        rangeby: {
          start: from,
          end: to,
          field: 'ts',
        },
        size: 10000,
      },
      deviceThroughput: {
        fields: ['total_bytes_in', 'total_bytes_out'],
        aggregation: 'sum',
        sort: 'asc',
        rangeby: {
          start: Date.now() - 6 * 60 * 60 * 1000,
          end: Date.now(),
          field: 'ts',
        },
        histogram: {
          field: 'ts',
          interval: 120000,
        },
        filterby: {
          and: {
            device_uuid: deviceUUID.join('|'),
            cluster_id: undefined,
          },
        },
        groupby: 'device_uuid',
        size: this.state.allDevices.length,
      },
      avgCPUUtil: {
        fields: ['avg_data_cpu_usage'],
        aggregation: 'avg',
        sort: 'asc',
        rangeby: {
          start: Date.now() - 6 * 60 * 60 * 1000,
          end: Date.now(),
          field: 'ts',
        },
        histogram: {
          field: 'ts',
          interval: 120000,
        },
        filterby: {
          and: {
            device_uuid: deviceUUID.join('|'),
            cluster_id: undefined,
          },
        },
        groupby: 'device_uuid',
        size: this.state.allDevices.length,
      },
    }

    if (this.props.cluster) {
      payload.deviceStats['filterby'] = {
        and: {
          cluster_id: this.props.cluster['cluster-uuid'],
        },
      }
      payload.deviceThroughput['filterby'].and.cluster_id = this.props.cluster[
        'cluster-uuid'
      ]
      payload.avgCPUUtil['filterby'].and.cluster_id = this.props.cluster[
        'cluster-uuid'
      ]
    }
    delete payload.avgCPUUtil.filterby
    delete payload.deviceThroughput.filterby
    delete payload.deviceStats.filterby

    const deviceStatsReq = this.InfraStructureStatsService.getClusterStats(
      header,
      payload,
      null,
    )
    deviceStatsReq
      .then((response: any) => {
        const deviceThroughputData =
          response.data && response.data.deviceThroughput
            ? response.data.deviceThroughput
            : {}
        const avgCPUUtilData =
          response.data && response.data.avgCPUUtil
            ? response.data.avgCPUUtil
            : {}
        let deviceList = this.state.allDevices
        this.nonLicenseDevice = 0
        deviceList.map((deviceObj: any) => {
          if (deviceThroughputData[deviceObj['device-uuid']]) {
            const chartData = this.Utilities.parseBPSChartData(
              deviceThroughputData[deviceObj['device-uuid']],
              payload.deviceThroughput,
            )
            deviceObj.bpsChart = chartData
          }
          if (avgCPUUtilData[deviceObj['device-uuid']]) {
            const chartData = this.Utilities.parseCPUUtilChartData(
              avgCPUUtilData[deviceObj['device-uuid']],
            )
            deviceObj.cpuUtilChart = chartData
          }

          const deviceStat = Object.keys(response.data.deviceStats)
            .filter(
              key =>
                response.data.deviceStats[key]['device_uuid'] ==
                deviceObj['device-uuid'],
            )
            .map((key, index) => {
              return response.data.deviceStats[key]
            })

          let deviceUsageChart = {
            series: [
              {
                name: '',
                data: [0],
              },
            ],
            controlChart: [
              {
                name: '',
                data: [0],
              },
            ],
            dataChart: [
              {
                name: '',
                data: [0],
              },
            ],
          }

          const cpuCount = {},
            deviceStatKeyLength = deviceStat.length

          let cpuCounts = 0,
            controlCpuCounts = 0,
            dataCpuCounts = 0,
            cpu_usage_sum = 0,
            controlCpuSum = 0,
            dataCpuSum = 0,
            cpu_usage_avg = -1,
            cpu_usage_max = -1,
            avg_control_cpu_usage = 0,
            max_control_cpu_usage = 0,
            avg_data_cpu_usage = 0,
            max_data_cpu_usage = 0,
            avg_vthunder_cpu_usage = 0,
            max_vthunder_cpu_usage = 0,
            free_memory_ratio = 0,
            free_memory_ratio_max = 1,
            free_session_ratio = 0,
            total_bytes_in = 0,
            total_bytes_out = 0

          deviceObj['statColumns'] = JSON.parse(
            JSON.stringify(this.statColumns),
          )
          deviceObj.hcLicense = 'UNLICENSED'
          deviceObj.hcLicenseClassName = 'warning-lic-class'
          deviceObj.deviceLicense = '--'
          deviceObj.deviceLicenseClassName = 'warning-lic-class'

          if (deviceObj['licenseinfo-list']) {
            deviceObj = this.setLicenseDetails(deviceObj)
          } else {
            deviceObj.statColumns[1].data = '--'
            deviceObj.statColumns[1].tooltip = 'Check device for license'
            deviceObj.statColumns[1].unit = ''
            deviceObj.statColumns[1].level = ''
            deviceObj.statColumns[2].data = 'UNLICENSED'
            deviceObj.statColumns[2].unit = ''
            deviceObj.statColumns[2].level = 'warn'
            this.nonLicenseDevice++
          }
          if (deviceStatKeyLength > 0) {
            if (deviceStat[0]['cluster_vcs_state'] === 'vcs_disabled') {
              deviceObj.vcsState = 'Disabled'
            }
            if (deviceStat[0]['cluster_vcs_state'] === 'master') {
              deviceObj.vcsState = 'Master'
            }
            if (deviceStat[0]['cluster_vcs_state'] === 'blade') {
              deviceObj.vcsState = 'Blade'
            }

            const cpuKeys = Object.keys(deviceStat[0].cpu_usage_overall)
            for (let i = 0; i < deviceStatKeyLength; i++) {
              for (let k = 0; k < cpuKeys.length; k++) {
                let cpuCountVal = deviceStat[i].cpu_usage_overall[cpuKeys[k]]
                if (!cpuCount[cpuKeys[k]]) {
                  cpuCount[cpuKeys[k]] = 0
                }
                cpuCount[cpuKeys[k]] += cpuCountVal

                cpu_usage_sum += cpuCountVal
                if (cpuCountVal > cpu_usage_max) {
                  cpu_usage_max = cpuCountVal
                }
                cpuCounts++

                if (cpuKeys[k].indexOf('Data') > -1) {
                  dataCpuSum += cpuCountVal
                  dataCpuCounts++
                }
                if (cpuKeys[k].indexOf('Control') > -1) {
                  controlCpuSum += cpuCountVal
                  controlCpuCounts++
                }
              }

              avg_control_cpu_usage += deviceStat[i].avg_control_cpu_usage
              max_control_cpu_usage += deviceStat[i].max_control_cpu_usage
              avg_data_cpu_usage += deviceStat[i].avg_data_cpu_usage
              max_data_cpu_usage += deviceStat[i].max_data_cpu_usage
              avg_vthunder_cpu_usage += deviceStat[i].avg_vthunder_cpu_usage
              max_vthunder_cpu_usage += deviceStat[i].max_vthunder_cpu_usage
              free_memory_ratio += deviceStat[i].free_memory_ratio
              free_session_ratio += deviceStat[i].free_session_ratio
              total_bytes_in += deviceStat[i].total_bytes_in
              total_bytes_out += deviceStat[i].total_bytes_out

              if (deviceStat[i].free_memory_ratio < free_memory_ratio_max) {
                free_memory_ratio_max = deviceStat[i].free_memory_ratio
              }
            }

            avg_control_cpu_usage /= deviceStatKeyLength
            max_control_cpu_usage /= deviceStatKeyLength
            avg_data_cpu_usage /= deviceStatKeyLength
            max_data_cpu_usage /= deviceStatKeyLength
            avg_vthunder_cpu_usage /= deviceStatKeyLength
            max_vthunder_cpu_usage /= deviceStatKeyLength
            free_memory_ratio /= deviceStatKeyLength
            free_session_ratio /= deviceStatKeyLength
            // total_bytes_in /= deviceStatKeyLength
            // total_bytes_out /= deviceStatKeyLength

            let memHealth = 0,
              cpuHealth = 0,
              deviceHealth = 0
            if (1 - free_memory_ratio < 0.7) {
              memHealth = 1
            } else if (1 - free_memory_ratio > 0.9) {
              memHealth = 0
            } else {
              memHealth = 1 - (1 - free_memory_ratio - 0.7) / 0.2
            }
            const cpuMax = Math.max(avg_control_cpu_usage, avg_data_cpu_usage)
            if (cpuMax < 40) {
              cpuHealth = 1
            } else if (cpuMax > 70) {
              cpuHealth = 0
            } else {
              cpuHealth = 1 - (cpuMax - 40) / 30
            }
            deviceHealth = Math.min(memHealth, cpuHealth)
            deviceObj.deviceHealth = deviceHealth
            deviceObj.statColumns[0].data =
              deviceHealth === 0
                ? 'Bad'
                : deviceHealth >= 0.8
                ? 'Good'
                : 'Average'
            deviceObj.statColumns[0].level =
              deviceHealth === 0
                ? 'error'
                : deviceHealth >= 0.8
                ? 'success'
                : 'warn'

            if (cpuCounts > 0) {
              cpu_usage_avg = cpu_usage_sum / cpuCounts
            }

            const cpuNames = Object.keys(cpuCount)
            const parsedChartData = [],
              controlChart = [],
              dataChart = []
            for (let l = 0; l < Object.keys(cpuCount).length; l++) {
              parsedChartData.push({
                name: cpuNames[l],
                data: [cpuCount[cpuNames[l]]],
              })
              if (cpuNames[l].toLowerCase().indexOf('control') > -1) {
                controlChart.push({
                  name: cpuNames[l],
                  data: [cpuCount[cpuNames[l]]],
                })
              }
              if (cpuNames[l].toLowerCase().indexOf('data') > -1) {
                dataChart.push({
                  name: cpuNames[l],
                  data: [cpuCount[cpuNames[l]]],
                })
              }
              deviceUsageChart.series = parsedChartData
            }
            deviceUsageChart.controlChart = controlChart
            deviceUsageChart.dataChart = dataChart

            const memory = Math.round(100 - 100 * free_memory_ratio)
            const maxmem = Math.round(100 - 100 * free_memory_ratio_max)
            const avgmem = Math.round(100 - 100 * free_memory_ratio)
            const avgCPU =
              avg_vthunder_cpu_usage >= 0
                ? Math.round(avg_vthunder_cpu_usage)
                : Math.round(cpu_usage_avg) >= 0
                ? Math.round(cpu_usage_avg)
                : 0

            deviceObj.statColumns[3].data = this.Utilities.truncateVal(
              ((total_bytes_in + total_bytes_out) * 8) / 60,
              'bytes',
            )
            deviceObj.statColumns[5].data = avgCPU >= 0 ? avgCPU : 0
            deviceObj.statColumns[5].level =
              avgCPU > 74 ? 'warn' : avgCPU > 89 ? 'error' : 'success'

            if (!isNaN(memory)) {
              deviceObj.memory = memory + '%'
              deviceObj.maxmem = maxmem + '%'
              deviceObj.avgmem = avgmem + '%'
              deviceObj.statColumns[6].data = memory
              deviceObj.statColumns[6].level =
                memory > 74 ? 'warn' : memory > 89 ? 'error' : 'success'
            } else {
              deviceObj.memory = NA
              deviceObj.maxmem = NA
              deviceObj.avgmem = NA
              deviceObj.statColumns[6].data = '0'
              deviceObj.statColumns[6].level = 'success'
            }

            deviceObj.session = !isNaN(
              Math.round(100 - 100 * free_session_ratio),
            )
              ? Math.round(100 - 100 * free_session_ratio) + '%'
              : NA

            deviceObj.dataCpu =
              (max_data_cpu_usage >= 0
                ? Math.round(max_data_cpu_usage) + '%'
                : NA) +
              ' / ' +
              (avg_data_cpu_usage >= 0
                ? Math.round(avg_data_cpu_usage) + '%'
                : NA)

            deviceObj.controlCpu =
              (max_control_cpu_usage >= 0
                ? Math.round(max_control_cpu_usage) + '%'
                : NA) +
              ' / ' +
              (avg_control_cpu_usage >= 0
                ? Math.round(avg_control_cpu_usage) + '%'
                : NA)

            deviceObj.overallCpu =
              (max_vthunder_cpu_usage >= 0
                ? Math.round(max_vthunder_cpu_usage) + '%'
                : Math.round(cpu_usage_max) >= 0
                ? Math.round(cpu_usage_max) + '%'
                : NA) +
              ' / ' +
              (avgCPU >= 0 ? avgCPU + '%' : NA)
            deviceObj.maxcpu =
              max_vthunder_cpu_usage >= 0
                ? Math.round(max_vthunder_cpu_usage) + '%'
                : Math.round(cpu_usage_max) >= 0
                ? Math.round(cpu_usage_max) + '%'
                : NA
            deviceObj.avgcpu = avgCPU >= 0 ? avgCPU + '%' : NA
            if (dataCpuCounts > 0) {
              deviceObj.avgdatacpu =
                Math.round(dataCpuSum / dataCpuCounts) + '%'
            } else {
              deviceObj.avgdatacpu = NA
            }
            if (controlCpuCounts > 0) {
              deviceObj.avgcontrolcpu =
                Math.round(controlCpuSum / controlCpuCounts) + '%'
            } else {
              deviceObj.avgcontrolcpu = NA
            }
          } else {
            deviceObj.memory = NA
            deviceObj.maxmem = NA
            deviceObj.avgmem = NA
            deviceObj.session = NA
            deviceObj.dataCpu = `${NA} / ${NA}`
            deviceObj.controlCpu = `${NA} / ${NA}`
            deviceObj.overallCpu = `${NA} / ${NA}`
            deviceObj.maxcpu = NA
            deviceObj.avgcpu = NA
            deviceObj.avgdatacpu = NA
            deviceObj.avgcontrolcpu = NA

            deviceObj.statColumns[3].data = '0'
            deviceObj.statColumns[5].data = '0'
            deviceObj.statColumns[6].data = '0'

            deviceObj.statColumns[3].level = 'warn'
            deviceObj.statColumns[5].level = 'warn'
            deviceObj.statColumns[6].level = 'warn'
          }

          if (deviceUsageChart.series && deviceStatKeyLength > 0) {
            for (let i = 0; i < deviceUsageChart.series.length; i++) {
              deviceUsageChart.series[i].data[0] = Math.round(
                deviceUsageChart.series[i].data[0] / deviceStatKeyLength,
              )
            }
            for (
              let i = 0;
              deviceUsageChart.controlChart &&
              i < deviceUsageChart.controlChart.length;
              i++
            ) {
              deviceUsageChart.controlChart[i].data[0] = Math.round(
                deviceUsageChart.controlChart[i].data[0] / deviceStatKeyLength,
              )
            }
            for (let i = 0; i < deviceUsageChart.dataChart.length; i++) {
              deviceUsageChart.dataChart[i].data[0] = Math.round(
                deviceUsageChart.dataChart[i].data[0] / deviceStatKeyLength,
              )
            }

            deviceObj.deviceCpuStats = deviceUsageChart.series
            deviceObj['controlChart'] = deviceUsageChart.controlChart
            deviceObj['dataChart'] = deviceUsageChart.dataChart
          } else {
            deviceObj.deviceCpuStats = []
            deviceObj['controlChart'] = []
            deviceObj['dataChart'] = []
          }
        })

        this.setState({
          allDevices: deviceList,
          devices: deviceList,
          isLoading: false,
        })
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  updateSelectedIndex = (index: number) => {
    this.setState({
      selectedIndex: index,
    })
  }

  SearchDevices = (e: any) => {
    const searchString =
      e && e.target && (e.target.value || e.target.value === '')
        ? e.target.value
        : this.state.searchString
    this.Utilities.search(this, searchString, 'name', {
      storeData: [...this.devices],
      stateName: 'allDevices',
    })
  }

  updatePartitions = (partitions: any, selectedDevice: any) => {
    const devices = this.state.allDevices
    devices.forEach((device: any) => {
      if (device.name === selectedDevice.name) {
        device['partition-list'] = partitions
      }
    })
    this.setState({
      allDevices: devices,
      isLoading: false,
    })
  }

  renderDeviceDetails = (record: any) => {
    const clusterObj = this.Utilities.getClusterObjforDevice(record.cluster)
    return (
      <div className="grid">
        <DeviceDetails
          updateDeviceData={this.state.updateDeviceData}
          deviceObj={record}
          clusterObj={clusterObj}
          cluster={this.props.cluster ? this.props.cluster : ''}
          fromClustersPage={this.props.fromClustersPage}
          onPartitionUpdate={this.updatePartitions}
          deviceUpdated={this.state.deviceUpdated}
          refreshDevices={() => this.loadDevices()}
          updateDeviceLicense={this.updateDeviceLicense}
          rangeByTime={this.props.rangeByTime ? this.props.rangeByTime : ''}
          contentType={this.state.contentType}
        />
      </div>
    )
  }

  handleFormChange = (data: any, isEdit: boolean) => {
    if (this.state.slidingForm === 'addDeviceLicense') {
      let devAuthFail = false
      data.map((dev: any) => {
        if (!dev.licenseAuth || !dev.validation) {
          devAuthFail = true
        }
      })
      this.devAuthFailed = devAuthFail
    }

    // @ts-ignore
    this.setState({
      formData: data,
    })
  }

  checkForOtherHADevice = (
    deviceName: string,
    clusterName: string,
    haPrimary: any,
  ) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    let otherHADeviceName: any
    this.state.allDevices.map((device: any, index: number) => {
      if (device.name !== deviceName && device.cluster === clusterName) {
        otherHADeviceName = device
      }
    })
    if (
      otherHADeviceName &&
      otherHADeviceName['ha-primary'] === 1 &&
      haPrimary === 1
    ) {
      const payload = {
        device: {
          name: otherHADeviceName.name,
          cluster: otherHADeviceName.cluster,
          host: otherHADeviceName.host,
          'management-interface': otherHADeviceName['management-interface'],
          'use-ipv6': otherHADeviceName['use-ipv6'],
          'metrics-export-interval':
            otherHADeviceName['metrics-export-interval'],
          zone: otherHADeviceName.zone,
          region: otherHADeviceName.region,
          location: otherHADeviceName.location,
          'ha-primary': 0,
        },
      }
      const deviceUpdate = this.InfrastructureService.updateDevice(
        null,
        payload,
        [PROVIDER, otherHADeviceName.name],
      )
      deviceUpdate
        .then((resp: any) => {
          // console.log(resp)
        })
        .catch((err: any) => {
          // console.log(err)
        })
    }
  }

  handleSave = () => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context

    if (this.state.slidingForm === 'addDevice') {
      this.setState({
        editMode: false,
        showSlidingPage: true,
        licenseForm: false,
        slidingTitle: 'Device License',
        slidingDesc: 'Please provide license information for device',
        slidingForm: 'addDeviceLicense',
      })
      return
    }
    const formData = this.state.formData
    const { showMessage } = this.Utilities
    const deviceList = this.Utilities.generateDevicePayload(
      formData.devices ? formData.devices : formData,
    )

    let payload = {
      'device-list': JSON.parse(JSON.stringify(deviceList)),
    }
    delete payload['device-list'][0].username
    delete payload['device-list'][0].password
    let deviceSave = null

    if (!this.state.editMode) {
      deviceSave = this.InfrastructureService.addDevice(null, payload, [
        provider,
      ])
    } else {
      payload = {
        device: JSON.parse(JSON.stringify(deviceList[0])),
      }
      delete payload['device'].username
      delete payload['device'].password

      deviceSave = this.InfrastructureService.updateDevice(null, payload, [
        provider,
        deviceList[0].name,
      ])
    }
    deviceSave
      .then((response: any) => {
        const messageKey = this.state.editMode
          ? 'SUCCESS_DEVICE_UPDATE'
          : 'SUCCESS_DEVICE_ADD'
        if (this.state.editMode) {
          showMessage(messageKey, 1, 1)
        }
        this.setSlidingPage(false)
        this.loadDevices()
        if (!this.state.editMode) {
          this.setState({ selectedDevice: deviceList[0] })
          this.setDeviceLicense(
            formData.devices ? formData.devices : formData,
            this.state.editMode,
          )
        } else {
          // this.checkForOtherHADevice(
          //   deviceList[0].name,
          //   deviceList[0].cluster,
          //   deviceList[0]['ha-primary'],
          // )
        }
        this.registerDevices(deviceList, this.state.editMode)
      })
      .catch((error: any) => {
        if (!this.Utilities.isRBACAccessDenied(error)) {
          const messageKey = this.state.editMode
            ? 'FAILURE_DEVICE_UPDATE'
            : 'FAILURE_DEVICE_ADD'
          showMessage(messageKey, 0, 1)
        }
        this.setSlidingPage(false)
      })
  }

  setDeviceLicense = (deviceList: any[], isEditMode: boolean) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const { showMessage } = this.Utilities
    if (!isEditMode) {
      const deviceLicReqs: any[] = []
      deviceList.forEach((device: any) => {
        const devLicense = device.licenseObj
          ? JSON.parse(device.licenseObj)
          : {}
        const payload = {
          licenseinfo: {
            'device-bandwidth':
              devLicense['entitlement-key'] || device.licenseToken
                ? device['bandwidthUnit'] === 'm'
                  ? device['numBandwidth']
                  : device['numBandwidth'] * 1000
                : '',
            'license-type':
              !!devLicense['licenseType'] &&
              devLicense['licenseType'] !== 'UNLICENSED'
                ? devLicense['licenseType']
                : 'DEVICE_LICENSE',
            'lic-key': devLicense['entitlement-key']
              ? devLicense['entitlement-key']
              : '',
            'license-state': devLicense['licenseState']
              ? devLicense['licenseState']
              : devLicense['entitlement-key']
              ? 'PENDING'
              : 'NOT_AVAILABLE',
          },
        }
        const setDeviceLicense = this.InfrastructureService.setDeviceLicense(
          null,
          payload,
          [PROVIDER, device.deviceName],
        )

        deviceLicReqs.push(setDeviceLicense)
      })

      Promise.all(deviceLicReqs)
        .then((resp: any) => {
          // showMessage('SUCCESS_DEVICE_REGISTER', 1, 1)
        })
        .catch((error: any) => {
          if (!this.Utilities.isRBACAccessDenied(error)) {
            showMessage('FAILURE_DEVICE_LICENSE_ASSIGN', 0, 1)
          }
        })
    }
  }

  registerDevices = (deviceList: any[], isEditMode: boolean) => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context

    const { showMessage } = this.Utilities
    if (!isEditMode) {
      const deviceRegReqs: any[] = []
      deviceList.forEach((device: any) => {
        // const encryptedPassword = new Buffer(device.password).toString('base64')
        const payload = {
          device: {
            username: device.username,
            password: device.password,
          },
        }
        const registerDevice = this.InfrastructureService.registerDevice(
          null,
          payload,
          [provider, device.name],
        )

        deviceRegReqs.push(registerDevice)
      })

      Promise.all(deviceRegReqs)
        .then((resp: any) => {
          // showMessage('SUCCESS_DEVICE_REGISTER', 1, 1)
          this.setState({
            loadingIcon: false,
            deleteModalState: false,
            workflowType: 'register',
            workflowID: resp[0].data.workflow.uuid,
            workflowSliderState: true,
          })
        })
        .catch((error: any) => {
          if (!this.Utilities.isRBACAccessDenied(error)) {
            const msg = error?.response?.data?.message
              ? error.response.data.message
              : ''
            showMessage('FAILURE_DEVICE_REGISTER', 0, 1, msg)
          }
          this.setState({
            workflowSliderState: false,
          })
        })
    }
  }

  handleDeviceAddFormValidation = () => {
    if (this.state.slidingForm === 'updateCredentials') {
      return this.onSubmitCredetialsForm()
    }
    const { validateAndSubmitForm } = this.Utilities
    if (this.state.slidingForm === 'viewConfig') {
      this.setSlidingPage(false)
    } else {
      validateAndSubmitForm(this.childForm.props)
    }
    if (this.state.slidingForm === 'changeLicense') {
      if (
        this.licenseFormData.deviceLicenseNewKey &&
        this.licenseFormData.newBandwidth === ''
      ) {
        this.Utilities.showMessage('BANDWIDTH_REQUIRED', 0, 1)
      }
    }
  }

  handleSlidingFormCancel = () => {
    if (this.state.slidingForm === 'addDeviceLicense') {
      this.setState({
        editMode: false,
        showSlidingPage: true,
        licenseForm: false,
        slidingTitle: 'Add New Device',
        slidingDesc: 'Please provide the detail to connect a device',
        slidingForm: 'addDevice',
      })
    } else {
      this.setSlidingPage(false)
    }
  }

  handleDeRegister = () => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context

    const { showMessage } = this.Utilities
    let payload = { 'force-delete': this.state.forceDelete }
    const deRegisterDevice = this.InfrastructureService.deRegisterDevice(
      null,
      payload,
      [provider, this.state.selectedDevice.name],
    )
    this.setState({
      loadingIcon: true,
    })
    deRegisterDevice
      .then((resp: any) => {
        this.setState({
          loadingIcon: false,
          deleteModalState: false,
          workflowType: 'deregister',
          workflowID: resp.data.workflow.uuid,
          workflowSliderState: true,
          modalAction: '',
          forceDelete: false,
        })
        // this.loadDevices()
        // if (this.props.cluster) {
        //   this.props.refreshClusterData()
        // }
      })
      .catch((error: any) => {
        this.setState({
          loadingIcon: false,
          workflowSliderState: false,
          modalAction: '',
          forceDelete: false,
        })
        if (!this.Utilities.isRBACAccessDenied(error)) {
          showMessage('FAILURE_DEVICE_DEREGISTER', 0, 1)
        }
        this.handleCancel()
      })
  }

  handleDelete = () => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context

    const { showMessage } = this.Utilities
    const deleteDevice = this.InfrastructureService.deleteDevice(null, null, [
      provider,
      this.state.selectedDevice.name,
    ])
    this.setState({
      loadingIcon: true,
    })
    deleteDevice
      .then(() => {
        showMessage('SUCCESS_DEVICE_DELETE', 1, 1)
        // this.archiveBackup()
        // refresh the session storage
        this.setState({
          loadingIcon: false,
          modalAction: '',
          forceDelete: false,
        })
        this.handleCancel()
        this.loadDevices()
        if (this.props.cluster) {
          this.props.refreshClusterData()
        }
      })
      .catch((error: any) => {
        this.setState({
          loadingIcon: false,
          modalAction: '',
          forceDelete: false,
        })
        if (!this.Utilities.isRBACAccessDenied(error)) {
          showMessage('FAILURE_DEVICE_DELETE', 0, 1)
        }
        this.handleCancel()
      })
  }

  handleOk = () => {
    if (this.state.modalAction === 'De-register and Delete') {
      this.handleDeRegister()
    } else {
      this.handleDelete()
    }
  }

  onForceDeleteChange = (e: any) => {
    let forceDelete = this.state.forceDelete
    if (e.target) {
      if (e.target.type === 'checkbox') {
        forceDelete = e.target.checked
      } else {
        forceDelete = e.target.value
      }
    } else {
      forceDelete = e
    }
    this.setState({
      forceDelete,
    })
  }

  handleRebootChange = (e: any) => {
    let rebootType = this.state.rebootType
    if (e.target) {
      if (e.target.type === 'checkbox') {
        rebootType = e.target.checked
      } else {
        rebootType = e.target.value
      }
    } else {
      rebootType = e
    }
    this.setState({
      rebootType,
    })
  }

  archiveBackup = async () => {
    // check if the device has backup
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context
    const uri = `/hpcapi/v3/provider/${provider}/device-backup/${this.state.selectedDevice.name}`
    const payload = {
      'device-backup': {
        archived: 1,
      },
    }
    try {
      await httpClient.post(uri, payload)
    } catch (err) {
      console.log('Not able to set archived flag')
    }
  }

  handleCancel = () => {
    if (this.state.loadingIcon) {
      return false
    }
    this.setState({
      deleteModalState: false,
      modalAction: '',
      forceDelete: false,
      selectedDevice: { name: '' },
      rebootModalState: false,
    })
  }

  handleSlidingWorkflowOnCancel = () => {
    this.setState({
      workflowSliderState: false,
    })
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({ showSlidingPage: isOpen })
  }

  setEditObject = (isOpen: boolean) => {
    this.setState({ showEditObject: isOpen })
  }

  setBackupSlidingPage = (isOpen: boolean, device: IObject) => {
    this.setState({ showBackupSlidingPage: isOpen, selectedDevice: device })
  }

  setUpgradeSlidingPage = (isOpen: boolean, device: IObject) => {
    this.setState({ showUpgradePage: isOpen, selectedDevice: device })
  }

  closeUpgradeSlidingPage = async (
    idList?: string[],
    deviceList?: string[],
  ) => {
    if (Array.isArray(idList) && idList.length > 0) {
      try {
        setTimeout(async () => {
          const workflowIDList = await Promise.all(
            idList.map(async id => {
              const { data: res } = await httpClient.get(
                `/scheduler/v1/job-result/${id}`,
                {
                  absoluteBasePath: true,
                },
              )
              const workflowID =
                res.job.results[0].result.workflow['workflow-id']
              return workflowID
            }),
          )
          this.setState({
            workflowIDList,
            showWorkflowStatusSlidingPage: true,
            upgradeDeviceList: deviceList,
          })
        }, 1000)
      } catch (err) {
        console.error(err)
      }
    }
    this.setState({ showUpgradePage: false })
  }

  onCloseWorkflowStatusWindow = () => {
    this.setState({ workflowIDList: [], showWorkflowStatusSlidingPage: false })
  }

  componentDidMount() {
    if (!this.dataLoaded) {
      this.loadDevices()
    }
    this.getRemainingBackups()
    const addNew = getItem('ADD_NEW')
    if (addNew) {
      setItem('ADD_NEW', '')

      const {
        storage: {
          get: { ADMIN_LEVEL },
        },
      } = this.context
      if (ADMIN_LEVEL === 'provider' && !this.isOperatorUser) {
        this.addDevice()
      }
    }
  }

  componentDidUpdate(prevProps: IDevicesProps, prevState: IDevicesState) {
    if (this.props.cluster !== prevProps.cluster) {
      this.loadDevices()
    }
  }

  getEntitlements = () => {
    const { storage } = this.context
    this.TroubleShootService.getLicenseEntitlements(null, null, [
      storage.get.PROVIDER,
    ])
      .then((resp: IObject) => {
        const { data, status } = resp
        const deviceManagement = data?.['device-management']

        let isAllowed = false
        if (!deviceManagement?.backup && status === 200) {
          isAllowed = true
        } else {
          isAllowed = deviceManagement?.backup?.periodic
        }
        this.setState({ isPeriodicAllowed: isAllowed })
      })
      .catch((err: IObject) => {})
  }

  getRemainingBackups = () => {
    const { storage } = this.context
    this.setState({ isLoading: true })
    this.TroubleShootService.getBackups(null, null, [
      storage.get.PROVIDER,
      'remaining-backups',
    ])
      .then((resp: IObject) => {
        const maxBackups = resp?.data?.max_backups
        const remainingBackups = resp?.data?.remaining_backups
        this.setState({
          maxBackups,
          remainingBackups,
          isPeriodicAllowed: remainingBackups > 0 || maxBackups === -1,
        })
      })
      .catch((err: IObject) => {
        this.setState({
          activeLicense: err?.response?.data,
        })
      })
  }

  onClickOEMenu(name: string, index: number) {
    const {
      storage: { set },
    } = this.context

    const { openDeviceSubMenu } = this.props
    set['object.explorer.device.text'](name)
    set['object.explorer.device.index'](index)
    openDeviceSubMenu()
  }

  renderActionDropdown = (device: IObject) => {
    const {
      storage: {
        get: {
          ADMIN_LEVEL,
          PROVIDER,
          ENCODED_SESSION_ID,
          ALLCLUSTERS: clusters,
        },
        set,
      },
    } = this.context

    if (device['mgmt-secure-port-disable'] === 0) {
      device.deviceURL = `https://${
        device['vcs-mgmt-floating-ip'] !== undefined
          ? device['vcs-mgmt-floating-ip']
          : device['mgmt-ip']
      }:${device['mgmt-port-secure']}/gui/auth/login/`
    } else if (device['mgmt-port-disable'] === 0) {
      device.deviceURL = `https://${
        device['vcs-mgmt-floating-ip'] !== undefined
          ? device['vcs-mgmt-floating-ip']
          : device['mgmt-ip']
      }:${device['mgmt-port-secure']}/gui/auth/login/`
    } else {
      device.deviceURL = 'disabled'
    }
    const clusterObj = clusters.find(
      (cluster: any) => cluster.name === device.cluster,
    )

    // false //this.Utilities.filterObjByProperty(clusters, 'name', device.cluster)
    const clickAction = (component: JSX.Element, index: number) => {
      if (component.key === 'edit') {
        this.editDevice(device)
      } else if (component.key === 'delete') {
        this.setState({
          deleteModalState: true,
          modalAction: 'Delete',
          forceDelete: false,
          selectedDevice: device,
        })
      } else if (component.key === 'view_analytics') {
        set.CURRENT_DEVICE(device)
        this.setState({ redirectUrl: '/controller/Analytics/device/' })
      } else if (component.key === 'view_config') {
        this.setState({
          showSlidingPage: true,
          slidingTitle: 'View Config on Device',
          slidingDesc: 'Please provide the neccessary information.',
          slidingForm: 'viewConfig',
          selectedDevice: device,
        })
      } else if (component.key === 'access_control') {
        this.setState({
          showSlidingPage: true,
          slidingTitle: 'Create Access Control Configuration',
          slidingDesc:
            'Please provide the necessary info and settings for Access Control configuration.',
          slidingForm: 'accessControl',
          selectedDevice: device,
        })
      } else if (component.key === 'updateCredentials') {
        this.setState({
          showSlidingPage: true,
          slidingTitle: 'Update Credentials',
          slidingDesc:
            'Update credentials stored in Harmony Controller for accessing device',
          slidingForm: 'updateCredentials',
          selectedDevice: device,
        })
      } else if (component.key === 'backup') {
        const { remainingBackups, maxBackups, activeLicense } = this.state
        if (activeLicense !== 'No Active License') {
          if (remainingBackups > 0 || maxBackups === -1) {
            this.setBackupSlidingPage(true, device)
          } else {
            this.setState({ showUpgrade: true })
          }
        } else {
          this.setState({ showAddLicense: true })
        }
      } else if (component.key === 'upgrade') {
        this.setUpgradeSlidingPage(true, device)
      } else if (
        component.key === 'reboot' &&
        (!clusterObj || clusterObj.type !== 'vcs') &&
        device.model !== 'cThunder'
      ) {
        const payload = { features: ['device_reboot'] }
        this.featureCheck(device, payload, 'device_reboot')
      } else if (
        component.key === 'updateLicense' &&
        device['registration-state'] === 'REGISTERED' &&
        device.vcsState !== 'Blade'
      ) {
        this.openLicenseUpdate(device)
      } else if (
        component.key === 'deregister' &&
        device.vcsState !== 'Blade'
      ) {
        this.setState({
          deleteModalState: true,
          modalAction:
            device['registration-state'] === 'REGISTERED'
              ? 'De-register and Delete'
              : 'Delete',
          forceDelete: false,
          selectedDevice: device,
        })
      } else if (component.key === 'deviceUI' && device.model !== 'cThunder') {
        if (device.deviceURL === 'disabled') {
          const { showMessage } = this.Utilities
          showMessage('DEVICE_DISABLED', 0, 1)
          return false
        }

        if (
          navigator &&
          navigator.userAgent &&
          /Edg/.test(navigator.userAgent)
        ) {
          this.Utilities.showMessage(
            <p>
              Automatic login to Thunder management console is not possible in
              Microsoft Edge browser.
              <br />
              Please{' '}
              <a target="_blank" href={device.deviceURL}>
                click here
              </a>{' '}
              to open the page and login using your Thunder credentials.
            </p>,
            0,
            0,
            '',
            10,
          )
          return false
        }

        const editID = `deviceEdit-${device.uuid}`
        const provider = PROVIDER
        const tokenHeaders = {
          Authorization: ENCODED_SESSION_ID,
          provider,
        }
        let sessionID: any = ENCODED_SESSION_ID
        sessionID = sessionID.split(' ')

        const getDeviceToken = this.DashboardService.getDeviceToken(
          tokenHeaders,
          null,
          sessionID[1],
        )
        getDeviceToken
          .then((resp: any) => {
            device.jwtToken = resp

            const jwt = document.querySelector('#deviceEdit-' + device.uuid)[0]
            jwt.value = resp.data
            setTimeout(() => {
              document.getElementById(editID).submit()
            }, 500)
          })
          .catch((err: any) => {
            console.log(err)
          })
      } else if (component.key === 'edit_object') {
        this.setState({
          selectedDevice: device,
          showEditObject: true,
        })
      }
    }

    if (ADMIN_LEVEL !== 'provider' || this.isOperatorUser) {
      return null
    }

    const dropdownClassName = this.props.fromClustersPage
      ? styles.clustersPageActionDropdown
      : styles.actionDropdown
    const dropdownStyle = this.props.fromClustersPage
      ? {}
      : {
          height: this.state.popup?.rowHeight
            ? this.state.popup.rowHeight - 18
            : 0,
        }

    return (
      <div className={dropdownClassName} style={dropdownStyle}>
        <A10DropdownMenu
          menu={
            ADMIN_LEVEL === 'provider'
              ? [
                  <div key="view_config">View Config on Device</div>,
                  <div key="view_analytics">View Analytics</div>,
                  <hr key="hr" />,
                  <div key="backup">Backup Device Config </div>,
                  <div
                    key="reboot"
                    className={
                      (clusterObj && clusterObj.type === 'vcs') ||
                      device.model === 'cThunder'
                        ? 'disabled'
                        : ''
                    }
                  >
                    Reboot Device
                  </div>,
                  <div
                    key="updateLicense"
                    className={
                      device['registration-state'] === 'REGISTERED' &&
                      device.vcsState !== 'Blade'
                        ? ''
                        : 'disabled'
                    }
                  >
                    Update License
                  </div>,
                  <div key="upgrade">Image Upgrade</div>,
                  <hr key="hr" />,
                  <div key="edit">Edit </div>,
                  <div key="updateCredentials">Update Credentials</div>,
                  <div
                    key="deregister"
                    className={device.vcsState !== 'Blade' ? '' : 'disabled'}
                  >
                    Delete{' '}
                  </div>,
                ]
              : []
          }
          title=""
          style={{ color: '#757575', marginBottom: '-15px' }}
          onClick={clickAction}
          trigger="hover"
          placement="bottomRight"
          arrowPointAtCenter={true}
        />
      </div>
    )
  }

  renderAddDevice = () => {
    const {
      storage: {
        get: { ADMIN_LEVEL },
      },
    } = this.context

    const { fromClustersPage, clusterConfigMgmt } = this.props
    const { allDevices } = this.state
    return (
      ADMIN_LEVEL === 'provider' &&
      !this.isOperatorUser &&
      (!fromClustersPage ||
        ((clusterConfigMgmt === 'single' || clusterConfigMgmt === 'vcs') &&
          allDevices.length < 1) ||
        (clusterConfigMgmt === 'ha' && allDevices.length < 2) ||
        (clusterConfigMgmt === 'multinode' && allDevices.length < 8)) && (
        <ActionButton
          text={fromClustersPage ? 'Add' : 'Create'}
          size={fromClustersPage ? 'default' : 'large'}
          onClick={this.addDevice}
          iconProps={{ app: 'global', type: 'add-new' }}
        />
      )
    )
  }

  renderSearchInput = () => {
    const { searchString } = this.state
    return (
      <A10Input.Search
        type="text"
        onChange={this.SearchDevices}
        onSearch={this.SearchDevices}
        name="searchBox"
        value={searchString}
        placeholder="Search"
        style={{ marginRight: '6px', width: '180px' }}
      />
    )
  }

  renderTable = () => {
    const { fromClustersPage } = this.props
    const { allDevices } = this.state

    return (
      <A10Table
        className={styles.devicesTable}
        columns={this.columns}
        bordered={!fromClustersPage}
        expandedRowRender={!fromClustersPage ? this.renderDeviceDetails : null}
        dataSource={allDevices.map((key: any, index: number) => {
          key.key = index
          if (key.session === undefined) {
            // this.getDeviceStat(key)
            return key
          }
          return key
        })}
        loading={
          !this.dataLoaded && {
            indicator: <A10Loader container={this.ref} />,
          }
        }
        scroll={{ x: true }}
        pagination={{ hideOnSinglePage: true, pageSize: 10 }}
        onRow={
          !fromClustersPage
            ? (record, rowIndex) => {
                return {
                  onMouseEnter: (e: React.MouseEvent<HTMLDivElement>) => {
                    e.preventDefault()

                    const currentTarget = e.currentTarget as HTMLDivElement

                    this.setState({
                      popup: {
                        record,
                        visible: true,
                        rowHeight: currentTarget.clientHeight,
                      },
                    })
                  }, // mouse enter row
                  onMouseLeave: event => {
                    this.setState({
                      popup: {
                        ...this.state.popup,
                        visible: false,
                      },
                    })
                  }, // mouse leave row
                }
              }
            : null
        }
      />
    )
  }

  changeDeviceSubPage = (tab: string) => {
    this.setState({ contentType: tab })
  }

  renderClustersPageDevices = () => {
    return (
      <Card
        title="Device List"
        toolbar={
          <>
            {this.renderSearchInput()}
            {this.renderAddDevice()}
          </>
        }
      >
        {this.renderTable()}
      </Card>
    )
  }

  onSubmitCredetialsForm: () => void
  setCredentialsFormRef = (onSubmit: (callback: () => void) => void) => {
    this.onSubmitCredetialsForm = () => {
      onSubmit(() => this.setSlidingPage(false))
    }
  }

  onCompleteCallback = () => {
    const { fromClustersPage, refreshClusterData } = this.props

    this.loadDevices()
    if (fromClustersPage) {
      refreshClusterData()
    }
  }

  render() {
    const { fromClustersPage, cluster } = this.props
    const {
      showSlidingPage,
      showBackupSlidingPage,
      showEditObject,
      selectedDevice,
      redirectUrl,
      isPeriodicAllowed,
      showUpgrade,
      showAddLicense,
      disableSave,
      slidingForm,
    } = this.state
    if (redirectUrl) {
      return <Redirect to={redirectUrl} />
    }
    return (
      <>
        {fromClustersPage ? (
          this.renderClustersPageDevices()
        ) : (
          <ContentSection>
            <ContentHeader type="flex" align="middle" justify="space-between">
              <A10Col>
                <ContentTitle
                  title="Devices"
                  count={this.state.allDevices.length}
                />
              </A10Col>
              <A10Col style={{ display: 'flex' }}>
                {this.renderSearchInput()}
                <ActionButton
                  text="Refresh"
                  onClick={this.refreshDevices}
                  iconProps={{ app: 'global', type: 'refresh' }}
                />
                {this.renderAddDevice()}
              </A10Col>
            </ContentHeader>
            <A10Row>
              <A10Tabs
                onChange={this.changeDeviceSubPage}
                type="card"
                className="devices-tab"
              >
                <TabPane key={'overview'} tab={'Overview'} />
                <TabPane key={'interfaces'} tab={'Interfaces'} />
                <TabPane key={'networks'} tab={'Networks'} />
              </A10Tabs>
            </A10Row>

            <ContentBody ref={this.ref}>
              {/* May need to add back in the future */}
              {/* {this.nonLicenseDevice > 0 && (
                <A10Alert
                  showIcon={true}
                  style={{ marginBottom: '5px' }}
                  message={`You have ${this.nonLicenseDevice} Non Licensed device(s). Please add to a license`}
                  type="warning"
                />
              )}
              {this.warnLicenseDevice > 0 && (
                <A10Alert
                  showIcon={true}
                  message="License Error: few devices are having either warning or error in license info, please check the device details"
                  type="warning"
                  style={{ marginBottom: '5px' }}
                />
              )} */}

              {this.renderTable()}
            </ContentBody>
          </ContentSection>
        )}
        <FormatSlidingPage
          isOpen={showSlidingPage}
          onRequestOk={this.handleDeviceAddFormValidation}
          onRequestClose={this.handleSlidingFormCancel}
          title={this.state.slidingTitle}
          description={this.state.slidingDesc}
          saveText={
            slidingForm === 'changeLicense'
              ? 'Update'
              : slidingForm === 'addDevice'
              ? 'Next'
              : slidingForm === 'viewConfig'
              ? 'Close'
              : 'Save'
          }
          hideCancel={
            slidingForm === 'addDeviceLicense' || slidingForm === 'viewConfig'
          }
          showBack={slidingForm === 'addDeviceLicense'}
          disableSave={
            slidingForm === 'addDeviceLicense'
              ? this.devAuthFailed
              : slidingForm === 'changeLicense'
              ? disableSave
              : false
          }
        >
          {slidingForm === 'changeLicense' ? (
            <ChangeDeviceForm
              onChange={this.onFormChange}
              hcLicenseKey={this.state.hcLicenseKey}
              selectedDevice={this.state.selectedDevice}
              deviceLicenseKey={this.state.deviceLicenseKey}
              allotedBandwidth={this.state.allotedBandwidth}
              activeLicenses={this.state.activeLicenses}
              onSubmitForm={this.addLicense}
              onRef={(ref: any): any => (this.childForm = ref)}
              deviceLicenseUnit={selectedDevice?.deviceLicense?.slice(-4)}
            />
          ) : slidingForm === 'addDevice' || slidingForm === 'editDevice' ? (
            <DeviceAddForm
              defaultMethods={this.props.defaultMethods}
              formData={this.state.formData}
              device={this.state.editMode ? this.state.selectedDevice : null}
              cluster={this.props.cluster}
              handleChange={this.handleFormChange}
              onSubmitForm={this.handleSave}
              onRef={(ref: any): any => (this.childForm = ref)}
              allDevices={this.state.allDevices}
            />
          ) : slidingForm === 'viewConfig' ? (
            <DeviceConfigForm device={selectedDevice} />
          ) : slidingForm === 'accessControl' ? (
            <AccessControlForm device={selectedDevice} />
          ) : slidingForm === 'updateCredentials' ? (
            <CredentialsForm
              deviceName={selectedDevice?.name}
              onRef={this.setCredentialsFormRef}
            />
          ) : (
            <DeviceLicenseForm
              defaultMethods={this.props.defaultMethods}
              formData={this.state.formData}
              cluster={this.props.cluster}
              handleChange={this.handleFormChange}
              onSubmitForm={this.handleSave}
              onRef={(ref: any): any => (this.childForm = ref)}
              selectedDevice={selectedDevice}
            />
          )}
        </FormatSlidingPage>

        <A10Modal
          title={this.state.modalAction + ' Device'}
          visible={this.state.deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={this.state.loadingIcon}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
              disabled={this.state.loadingIcon}
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            {this.state.modalAction === 'De-register and Delete'
              ? 'Configure Thunder to de-register and delete the device ' +
                this.state.selectedDevice.name +
                ' from Harmony configuration database?'
              : 'Do you want to ' +
                this.state.modalAction.toLowerCase() +
                ' ' +
                this.state.selectedDevice.name +
                (fromClustersPage && cluster ? ' from ' + cluster.name : '')}
          </p>
          {this.state.modalAction === 'De-register and Delete' ? (
            <>
              <A10Checkbox
                onChange={this.onForceDeleteChange.bind(this)}
                defaultChecked={this.state.forceDelete}
              />
              <span style={{ color: 'Red', paddingLeft: '10px' }}>
                Force Delete
              </span>
              <br />
              <br />
              <A10Alert
                showIcon={true}
                style={{ margin: '0px 8px 5px 8px' }}
                message="Delete device even if deregistration is not successful."
                type="warning"
              />
              {/* <p>
                Force delete will send a deregistration to the device and also independently delete the device from the Configuration database even if the deregistration fails.
              </p> */}
            </>
          ) : null}
          {this.state.loadingIcon ? (
            <div className="">
              <ReactLoading
                type="bars"
                color="#4a90e2"
                height={40}
                width={40}
              />
            </div>
          ) : null}
        </A10Modal>
        <SlidingBackup
          isOpen={showBackupSlidingPage}
          isPeriodicAllowed={isPeriodicAllowed}
          onRequestClose={this.setBackupSlidingPage.bind(this, false, {
            name: '',
          })}
          deviceName={this.state.selectedDevice.name}
        />
        <SlidingWorkflowStatus
          isOpen={
            this.state.workflowSliderState &&
            this.state.workflowID !== '' &&
            !!this.state.allDevices
          }
          title={'Reboot Device Workflow Status'}
          onRequestClose={this.handleSlidingWorkflowOnCancel}
          source="DP"
          type={this.state.workflowType}
          id={this.state.workflowID}
          assocObject={this.state.selectedDevice}
          showMessage={true}
          onCompleteCallback={this.onCompleteCallback}
        />
        <SlidingUpgrade
          isOpen={this.state.showUpgradePage}
          onRequestClose={this.closeUpgradeSlidingPage}
          deviceLevelUpgrade={true}
          deviceName={this.state.selectedDevice.name}
        />
        <SlidingWorkflowStatusList
          isOpen={
            this.state.showWorkflowStatusSlidingPage &&
            this.state.workflowIDList.length > 0
          }
          title={'Image Upgrade'}
          objectList={this.state.upgradeDeviceList}
          onRequestClose={this.onCloseWorkflowStatusWindow}
          idList={this.state.workflowIDList}
        />
        <A10Modal
          title="Reboot Device"
          visible={this.state.rebootModalState}
          onOk={this.rebootDeviceConfirm}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={this.state.loadingIcon}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.rebootDeviceConfirm}
              className="yesbtn"
              disabled={this.state.loadingIcon}
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            Do you want to reboot this device : {this.state.selectedDevice.name}
          </p>
          <p>Select the image to boot from</p>
          <A10Radio.Group
            name="type"
            defaultValue={this.state.rebootType}
            value={this.state.rebootType}
            onChange={this.handleRebootChange.bind(this)}
            className="reboot-device-labels"
          >
            <A10Radio
              value="pri"
              className={
                this.state.rebootLabel.default == 'pri'
                  ? 'default-boot-image'
                  : 'non-default-boot-image'
              }
            >
              {this.state.rebootLabel.priLabel}
            </A10Radio>
            <A10Radio
              value="sec"
              className={
                this.state.rebootLabel.default == 'sec'
                  ? 'default-boot-image'
                  : 'non-default-boot-image'
              }
            >
              {this.state.rebootLabel.secLabel}
            </A10Radio>
          </A10Radio.Group>
          {this.state.loadingIcon ? (
            <div className="">
              <ReactLoading
                type="bars"
                color="#4a90e2"
                height={40}
                width={40}
              />
            </div>
          ) : null}
        </A10Modal>

        <FormatSlidingPage
          isOpen={showEditObject}
          isShowFooterButtons={false}
          isRightCancel={true}
          onRequestOk={this.setEditObject.bind(this, false)}
          onRequestClose={this.setEditObject.bind(this, false)}
          title="Explorer"
          description={
            <>
              <div style={{ color: '#999999' }}>
                Cluster
                <A10Tooltip title={selectedDevice.cluster} placement="topLeft">
                  <span className="titlePadFntWgt">
                    {selectedDevice.cluster
                      ? selectedDevice.cluster.length > 20
                        ? selectedDevice.cluster.slice(0, 20) + '...'
                        : selectedDevice.cluster
                      : ''}
                  </span>
                </A10Tooltip>
                <A10Icon
                  type="right"
                  className="iconPad open-explorer-breadcrumb-separator"
                />
                Device
                <A10Tooltip title={selectedDevice.cluster} placement="topLeft">
                  <span className="titlePadFntWgt">
                    {selectedDevice.name
                      ? selectedDevice.name.length > 20
                        ? selectedDevice.name.slice(0, 20) + '...'
                        : selectedDevice.name
                      : ''}
                  </span>
                </A10Tooltip>
                <A10Icon
                  type="right"
                  className="iconPad open-explorer-breadcrumb-separator"
                />
                Partition
              </div>
            </>
          }
        >
          <ObjectExplorer
            singleDevice={selectedDevice}
            singleDeviceSelectedPartition={null}
          />
        </FormatSlidingPage>

        <UpgradeModal
          content="You are using a freeium plan which dose not allow automated device backup. For upgrading your plan please contact"
          visible={showUpgrade}
          onClose={() => {
            this.setState({ showUpgrade: false })
          }}
        />
        <ActivateLicense
          content={'Please add a valid license to your account'}
          visible={showAddLicense}
          onClose={() => {
            this.setState({ showAddLicense: false })
          }}
        />
      </>
    )
  }
}
export default setupA10Container(Devices)
