import React from 'react'
import { A10Component } from '@gui-libraries/framework'

import './styles/sortbutton.scss'

type SortButtonType = 'simple-order' | 'letter-order' | 'number-order'

type SortOrder = 'asc' | 'desc'

interface ISortIconProps extends React.SVGProps<SVGSVGElement> {
  buttonType: SortButtonType
  isUsed: boolean
  isChecked: boolean
}

const SvgUpIcon = (props: ISortIconProps) => {
  const { buttonType, isUsed, isChecked, width, height, ...rest } = props
  if (buttonType === 'simple-order') {
    return (
      <svg
        viewBox="0 0 120 60"
        width={width || '12px'}
        height={height || '6px'}
        {...rest}
      >
        <polygon
          points="0,60 120,60 60,0"
          fill={!isUsed || !isChecked ? '#ddeeff' : '#4a90e2'}
        />
      </svg>
    )
  } else if (buttonType === 'letter-order' || buttonType === 'number-order') {
    return (
      <>
        <svg
          viewBox="0 0 130 100"
          width={width || '13px'}
          height={height || '10px'}
          {...rest}
        >
          <polygon
            points="0,100 130,100 65,0"
            fill={!isUsed || !isChecked ? '#acacac' : '#4a90e2'}
            stroke={isUsed && !isChecked ? '#979797' : null}
            stroke-width={isUsed && !isChecked ? '10px' : null}
          />
        </svg>
      </>
    )
  }
  return null
}

const SvgDownIcon = (props: ISortIconProps) => {
  const { buttonType, isUsed, isChecked, width, height, ...rest } = props
  if (buttonType === 'simple-order') {
    return (
      <svg
        viewBox="0 0 120 60"
        width={width || '12px'}
        height={height || '6px'}
        {...rest}
      >
        <polygon
          points="0,0 120,0 60,60"
          fill={!isUsed || !isChecked ? '#ddeeff' : '#4a90e2'}
        />
      </svg>
    )
  } else if (buttonType === 'letter-order' || buttonType === 'number-order') {
    return (
      <>
        <svg
          viewBox="0 0 130 100"
          width={width || '13px'}
          height={height || '10px'}
          {...rest}
        >
          <polygon
            points="0,0 130,0 65,100"
            fill={!isUsed || !isChecked ? '#acacac' : '#4a90e2'}
            stroke={isUsed && !isChecked ? '#979797' : null}
            stroke-width={isUsed && !isChecked ? '10px' : null}
          />
        </svg>
      </>
    )
  }
  return null
}

interface ISortTextProps extends React.SVGProps<SVGSVGElement> {
  buttonType: SortButtonType
}

const SvgUpText = (props: ISortTextProps) => {
  const { buttonType, width, height, ...rest } = props
  if (buttonType === 'simple-order') {
    return null
  } else if (buttonType === 'letter-order') {
    return (
      <svg
        viewBox="0 0 80 110"
        width={width || '8px'}
        height={height || '11px'}
        {...rest}
      >
        <text x="5" y="100" font-family="ArialRoundedMT" font-size="100">
          A
        </text>
      </svg>
    )
  } else if (buttonType === 'number-order') {
    return (
      <svg
        viewBox="0 0 80 110"
        width={width || '8px'}
        height={height || '11px'}
        {...rest}
      >
        <text x="5" y="100" font-family="ArialRoundedMT" font-size="100">
          1
        </text>
      </svg>
    )
  }
  return null
}

const SvgDownText = (props: ISortTextProps) => {
  const { buttonType, width, height, ...rest } = props
  if (buttonType === 'simple-order') {
    return null
  } else if (buttonType === 'letter-order') {
    return (
      <svg
        viewBox="0 0 80 110"
        width={width || '8px'}
        height={height || '11px'}
        {...rest}
      >
        <text x="5" y="80" font-family="ArialRoundedMT" font-size="100">
          Z
        </text>
      </svg>
    )
  } else if (buttonType === 'number-order') {
    return (
      <svg
        viewBox="0 0 80 110"
        width={width || '8px'}
        height={height || '11px'}
        {...rest}
      >
        <text x="5" y="80" font-family="ArialRoundedMT" font-size="100">
          9
        </text>
      </svg>
    )
  }
  return null
}

export interface ISortButtonProps {
  type: SortButtonType
  order?: SortOrder
  defaultOrder?: SortOrder
  onOrderChange?: (order: SortOrder) => void
  className?: string
  disabled?: boolean
}

export interface ISortButtonState {
  order: SortOrder
}

class SortButton extends A10Component<ISortButtonProps, ISortButtonState> {
  static defaultProps: ISortButtonProps = {
    type: 'simple-order',
    defaultOrder: 'asc',
  }

  constructor(props: ISortButtonProps) {
    super(props)

    this.state = {
      order: props.defaultOrder || 'asc',
    }
  }

  onToggle = () => {
    const { order, onOrderChange } = this.props
    if (order) {
      if (onOrderChange instanceof Function) {
        onOrderChange(order === 'asc' ? 'desc' : 'asc')
      }
    } else {
      this.setState(
        (prevState: ISortButtonState) => {
          return {
            order: prevState.order === 'asc' ? 'desc' : 'asc',
          }
        },
        () => {
          const { order: currentOrder } = this.state
          if (onOrderChange instanceof Function) {
            onOrderChange(currentOrder)
          }
        },
      )
    }
  }

  onClickUp = (event: React.UIEvent) => {
    event.stopPropagation()
    const { order, onOrderChange } = this.props
    if (order) {
      if (onOrderChange instanceof Function) {
        onOrderChange('asc')
      }
    } else {
      this.setState({ order: 'asc' }, () => {
        if (onOrderChange instanceof Function) {
          onOrderChange('asc')
        }
      })
    }
  }

  onClickDown = (event: React.UIEvent) => {
    event.stopPropagation()
    const { order, onOrderChange } = this.props
    if (order) {
      if (onOrderChange instanceof Function) {
        onOrderChange('desc')
      }
    } else {
      this.setState({ order: 'desc' }, () => {
        if (onOrderChange instanceof Function) {
          onOrderChange('desc')
        }
      })
    }
  }

  render() {
    const { type, order: outerOrder, className, disabled } = this.props
    const { order: innerOrder } = this.state

    const order: SortOrder = outerOrder || innerOrder

    return (
      <div
        className={`sort-button${className ? ' ' + className : ''}`}
        onClick={this.onToggle}
      >
        <div className={`up-button${order === 'asc' ? ' checked' : ''}`}>
          <SvgUpIcon
            className="up-icon"
            buttonType={type}
            isUsed={!disabled}
            isChecked={order === 'asc'}
          />
          <SvgUpText className="up-text" buttonType={type} />
        </div>
        <div className={`down-button${order === 'desc' ? ' checked' : ''}`}>
          <SvgDownIcon
            className="down-icon"
            buttonType={type}
            isUsed={!disabled}
            isChecked={order === 'desc'}
          />
          <SvgDownText className="down-text" buttonType={type} />
        </div>
      </div>
    )
  }
}

export default SortButton
