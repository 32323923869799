import parameters from 'parameters'
import { axios } from '@gui-libraries/framework'

import storage from 'src/libraries/storage'

export class AjaxService {

  promisingHttpRequest = (
    url: string,
    method: string,
    headers: IObject | null,
    payload: IObject | string,
    contentType?: string,
    token?: any,
  ) => {
    const {
      get: { CURRENT_TENANT, ENCODED_SESSION_ID },
    } = storage

    const  defaultHeaders: {[index: string]: string | null} = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      provider: storage.get.PROVIDER,
      tenant: null,
      Authorization: null,
    }

    const customURL =
      url.indexOf('http') === 0 ? url : parameters.BASE_URL + url

    if (CURRENT_TENANT) {
      defaultHeaders.tenant = CURRENT_TENANT.name || ''
    }
    if (ENCODED_SESSION_ID) {
      defaultHeaders.Authorization = ENCODED_SESSION_ID
    }
    if (defaultHeaders.provider === null) {
      delete defaultHeaders.provider
    }

    const customHeaders = headers ? headers : defaultHeaders
    if (contentType && contentType === 'formdata') {
      customHeaders['Content-Type'] = 'multipart/form-data'
      customHeaders.Accept = '*/*'
    }
    let data = ''
    if (
      customHeaders &&
      (customHeaders['Content-Type'] === 'application/x-www-form-urlencoded' ||
        customHeaders['Content-Type'] === 'multipart/form-data')
    ) {
      data = payload as string
    } else {
      data = JSON.stringify(payload)
    }
    if (customHeaders.provider === null) {
      delete customHeaders.provider
    }
    if (customHeaders.tenant === null || customHeaders.tenant === '') {
      delete customHeaders.tenant
    }
    if (customHeaders.Authorization === null) {
      delete customHeaders.Authorization
    }
    if (customHeaders.responseType === 'blob') {
      //  delete customHeaders['Content-type']
      //  delete customHeaders['Accept']
    }
    // Cancel token used to cancel the http call when component gets unmounted
    const body: IObject = {
      method,
      url: customURL,
      headers: customHeaders,
      data: null,
      cancelToken: token,
    }
    if (payload) {
      body.data = data
    } else {
      delete body.data
    }

    if (customHeaders.responseType === 'arraybuffer') {
      body.responseType = 'arraybuffer'
    }

    return axios(body)
  }
}
export default AjaxService
