import React from 'react'
import ReactLoading from 'react-loading'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps
} from '@gui-libraries/framework'
import {
  A10Form,
  A10Input,
  A10Select,
  A10Icon,
  A10Table
} from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import globalConfig from 'src/settings/config'
// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')
// tslint:disable-next-line:no-var-requires
const moment = require('moment')

export interface IRestoreFormProps extends IA10ContainerDefaultProps {
  formData: IFormData
  onChange: (data: IFormData) => void
  isLoading?: boolean
}

export interface IDevice {
  name: string
}

export interface IRestoreFormStates {
  provider: string
  formData: IFormData
  deviceList: IDevice[]
  backupDict: IObject
  backupInfoList: IObject[]
}

export interface IFormData {
  backup: IObject | null
  description: string
  'src-device-name': string
  'dst-device-name': string
}

class RestoreForm extends A10Container<IRestoreFormProps, IRestoreFormStates> {
  constructor(props: IRestoreFormProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER || '',
      formData: {
        backup: {},
        description: '',
        'src-device-name': '',
        'dst-device-name': ''
      },
      deviceList: [],
      backupDict: {},
      backupInfoList: []
    }
  }

  componentWillMount() {
    Promise.all([this.getDevice(), this.getBackup()]).then(results => {
      const deviceList = results[0].data ? results[0].data['device-list'] : []
      const backupList = results[1].data
        ? results[1].data['device-backup-list']
        : []
      const backupDict: IObject = {}
      for (const backup of backupList) {
        backupDict[backup.name] = backup['backup-info-list']
          ? backup['backup-info-list'].reverse()
          : []
      }
      const state = { ...this.state }
      state.deviceList = deviceList
      state.backupDict = backupDict
      if (this.props.formData) {
        state.formData = this.props.formData
        state.backupInfoList =
          state.backupDict[state.formData['src-device-name']]
      }
      this.setState(state)
    })
  }

  getBackupColumns() {
    return [
      {
        title: 'Backup Name',
        dataIndex: 'name',
        key: 'name'
      },

      {
        title: 'Timezone',
        dataIndex: 'timezone',
        key: 'timezone',
        render: (text: string, record: IObject, index: number) => {
          const tzone = record?.['timezone']?.['label'] || 'UTC+00:00'

          return <span>{tzone}</span>
        }
      },
      {
        title: 'Backup Date/Time',
        dataIndex: 'backup-time',
        key: 'backup-time', // TODO: change this key
        render: (text: string, record: any) => {
          return <span>{record?.['backup-time']}</span>
        }
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description'
      }
    ]
  }

  onChangeCallback(formData: IFormData) {
    this.props.onChange(formData)
  }

  onDescriptionChange = (e: any) => {
    const state = { ...this.state }
    state.formData.description = e.target.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onSrcDeviceChange(device: string) {
    const state = { ...this.state }
    state.formData['src-device-name'] = device
    if (device === null) {
      state.formData.backup = null
    } else {
      state.backupInfoList = state.backupDict[device]
      state.formData.backup = state.backupInfoList
        ? state.backupInfoList[0]
        : null
    }
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onDstDeviceChange(device: string) {
    const state = { ...this.state }
    state.formData['dst-device-name'] = device
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onBackupChange = (backup: number[]) => {
    const state = { ...this.state }
    state.formData.backup = this.state.backupInfoList[backup[0]]
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  async getDevice() {
    const {
      EPIC_DEPENDENCIES: { httpClient }
    } = globalConfig
    const state = { ...this.state }
    const { provider } = state
    return httpClient.get(`/hpcapi/v3/provider/${provider}/device/`)
  }

  async getBackup() {
    const {
      EPIC_DEPENDENCIES: { httpClient }
    } = globalConfig
    const state = { ...this.state }
    const { provider } = state
    return httpClient.get(`/hpcapi/v3/provider/${provider}/device-backup/`)
  }

  renderDeviceOptions(type: string) {
    // const { deviceList, formData } = this.state
    const { backupDict, deviceList, formData } = this.state
    const onDeviceChange =
      type === 'src'
        ? this.onSrcDeviceChange.bind(this)
        : this.onDstDeviceChange.bind(this)
    const srcList =
      type === 'src'
        ? Object.keys(backupDict)
        : deviceList.map(device => device.name)
    const defaultDeviceName =
      type === 'src' ? formData['src-device-name'] : formData['dst-device-name']
    const options = srcList.map(name => {
      return (
        <A10Select.Option value={name} key={name}>
          <span>{name}</span>
        </A10Select.Option>
      )
    })
    return (
      <A10Select onChange={onDeviceChange} value={defaultDeviceName}>
        {options}
      </A10Select>
    )
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 }
    }
    const formTableLayout = {
      labelCol: { span: 0 },
      wrapperCol: { span: 22 }
    }

    const tableData = this.state.backupInfoList || []
    const formData = this.props.formData
    let backupIndex = 0
    if (formData) {
      const backup = this.state.formData.backup
      backupIndex = tableData.findIndex(element => {
        return element.uuid === backup.uuid
      })
      if (backupIndex < 0) {
        backupIndex = 0
      }
    }

    const rowSelection = {
      selectedRowKeys: [backupIndex],
      type: 'radio',
      onChange: this.onBackupChange,
      onSelection: this.onBackupChange
    }

    return (
      <A10Form layout="horizontal">
        <A10Panel
          title={
            <A10IconTextGroup
              text="Restore from backup"
              icon={
                <A10Icon
                  style={{ marginRight: 10 }}
                  app="global"
                  type="form-section"
                />
              }
            />
          }
        >
          <A10Form.Item {...formItemLayout} label="Associated Device">
            {this.renderDeviceOptions('src')}
          </A10Form.Item>
          <A10Form.Item {...formTableLayout}>
            <div className={styles.tableContainer}>
              <A10Table
                className="hc-list"
                rowSelection={rowSelection}
                columns={this.getBackupColumns()}
                dataSource={tableData.map((item: IObject, index: number) => {
                  item.key = index
                  return item
                })}
                size="small"
                loading={
                  this.props.isLoading
                    ? {
                        indicator: (
                          <div className="loading-icon">
                            <ReactLoading
                              type="bars"
                              color="#4a90e2"
                              height={40}
                              width={40}
                            />
                          </div>
                        )
                      }
                    : false
                }
              />
            </div>
          </A10Form.Item>
          <A10Form.Item {...formItemLayout} label="Restore to Device">
            {this.renderDeviceOptions('dst')}
          </A10Form.Item>
          <A10Form.Item {...formItemLayout} label="Description">
            <A10Input
              placeholder="Enter some description about this restore"
              maxLength={128}
              value={this.state.formData.description}
              onChange={this.onDescriptionChange}
            />
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(RestoreForm)
