import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import { ApplicationConfigs } from 'src/constants/ApplicationConfigs'
import { AppRoot } from 'src/settings/appRoot'
import { Utilities } from 'src/services'
import storage from 'src/libraries/storage'
import { ClusterAnalytics } from './ClusterAnalytics'
import { DeviceAnalytics } from './DeviceAnalytics'

export interface IAnalyticsProps extends IA10ContainerDefaultProps {
  tenantChange: any
  defaultMethods: IDefaultMethods
  tenantToggled: boolean
  clusterDeviceToggled: boolean
  selectedApp: string
  onSelectApp: (selectedApp: string) => void
  leftNavCollapsed: boolean
}
export interface IAnalyticsState {
  application: IObject
}

const MODE = 'PROD'

class Analytics extends A10Container<IAnalyticsProps, IAnalyticsState> {
  state: IAnalyticsState = {
    application: {
      subApps: [],
      name: '',
    },
  }

  ApplicationConfigs = new ApplicationConfigs()
  AppRoot = new AppRoot()
  Utilities = new Utilities()

  componentDidMount() {
    this.updateState()
    this.setLogPanelPosition(this.props.leftNavCollapsed)
  }

  componentDidUpdate(prevProps: IAnalyticsProps, prevState: IAnalyticsState) {
    const { tenantToggled, clusterDeviceToggled, leftNavCollapsed } = this.props

    if (
      prevProps.tenantToggled !== tenantToggled ||
      prevProps.clusterDeviceToggled !== clusterDeviceToggled
    ) {
      this.updateState()
    }

    if (prevProps.leftNavCollapsed !== leftNavCollapsed) {
      this.setLogPanelPosition(leftNavCollapsed)
    }
  }

  updateState() {
    const { selectedApp, onSelectApp } = this.props
    const applications = this.ApplicationConfigs.getAvailableApps()
    const applicationIndex = applications.findIndex(key => {
      return key.name.toLowerCase() === 'analytics'
    })
    let selectedAppDetail = applications[applicationIndex].subApps.find(
      (subApp: any) => {
        return subApp.name.toLowerCase() === selectedApp.toLowerCase()
      },
    )

    if (!selectedAppDetail) {
      selectedAppDetail = applications[applicationIndex].subApps[0]
    }

    const allowedRoles = this.ApplicationConfigs.getAllowedRolesSubApps(
      'Analytics',
      selectedAppDetail.name,
    )

    if (allowedRoles) {
      const adminLevel = storage.get.ADMIN_LEVEL
      const drillLevel = storage.get.DRILL_LEVEL
      const allowedIndex = allowedRoles.findIndex((key: any) => {
        return key === adminLevel
      })

      if (allowedIndex >= 0) {
        this.setState({
          application: applications[applicationIndex],
        })

        if (adminLevel === 'provider' && drillLevel === 'provider') {
          storage.remove.TENANT()
          storage.remove.CURRENT_TENANT()
          storage.set.DRILL_LEVEL('provider')
          this.AppRoot.setRootScopeElement('currentDrillLevel', 'provider')
        }
        storage.remove.SUPER_ADMIN_MODE()
        storage.set.CURRENT_STATE('analytics')
        this.AppRoot.setRootScopeElement('currentState', 'analytics')

        if (selectedAppDetail.name !== selectedApp) {
          if (onSelectApp instanceof Function) {
            onSelectApp(selectedAppDetail.name)
          }
        }

        return
      }
    }
    this.Utilities.showMessage('INSUFFICENT_PRIVILEGE', 0, 1)
  }

  setLogPanelPosition = (leftNavCollapsed: boolean) => {
    const logPanelEl = document.getElementsByClassName('logpanel-main')[0]
    if (logPanelEl) {
      if (leftNavCollapsed) {
        logPanelEl.classList.add('left-nav-collapsed')
      } else {
        logPanelEl.classList.remove('left-nav-collapsed')
      }
    }
  }

  render() {
    const { clusterDeviceToggled } = this.props
    const selectedApp = this.props.selectedApp.toLowerCase()

    switch (selectedApp) {
      case 'cluster': {
        return (
          <ClusterAnalytics
            clusterDeviceToggled={clusterDeviceToggled}
            mode={MODE}
          />
        )
      }
      case 'device': {
        return (
          <DeviceAnalytics
            clusterDeviceToggled={clusterDeviceToggled}
            mode={MODE}
          />
        )
      }
      default: {
        return null
      }
    }
  }
}

export default setupA10Container(Analytics)
