import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { _ } from '@gui-libraries/framework'
import {
  A10Loader,
  A10SlidingPage,
  A10Table,
  A10DropdownMenu,
} from '@gui-libraries/widgets'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import ForceDeleteDialog from './ForceDeleteDialog'
import ActionButton from 'src/components/shared/ActionButton'
import { Utilities } from 'src/services'

import styles from './styles/index.module.less'

export interface IVridListProps {
  device: string
  partition: string
  renderForm: (value: IObject) => JSX.Element
  setShouldTemplateUpdate: Dispatch<SetStateAction<boolean>>
  clustersUpdated: boolean
}

const INVALID = '-- --'
const BLADE_PARAMETERS = 'blade-parameters'
const utilities = new Utilities()

const VridList: React.FC<IVridListProps> = props => {
  const provider = storage.get.PROVIDER
  const isOperatorUser = storage.get.IS_OPERATOR_USER

  const container = useRef(null)
  const {
    device,
    partition,
    renderForm,
    setShouldTemplateUpdate,
    clustersUpdated,
  } = props
  const [formattedData, setFormattedData] = useState([])
  const [slidingPageOpen, setSlidingPageOpen] = useState(false)
  const [selectedVrid, setSelectedVrid] = useState<number | null>(null)
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const apiPrefix = useMemo(() => {
    return `/hpcapi/v3/provider/${provider}/device/${device}/partition/${partition}`
  }, [provider, device, partition])

  const openFormPage = useCallback(() => setSlidingPageOpen(true), [])
  const closeFormPage = useCallback(() => {
    setSlidingPageOpen(false)
    setSelectedVrid(null)
    setSelectedTemplate('')
  }, [])
  const closeModal = useCallback(() => {
    setModalVisible(false)
    setSelectedVrid(null)
  }, [])

  const submitCallback = () => {
    closeFormPage()
    getData()
  }

  const submitTemplateCallback = () => {
    closeFormPage()
    setShouldTemplateUpdate(true)
  }

  const moreOptions = useCallback(
    ({
      appliedTemplate,
      vridVal,
    }: {
      appliedTemplate: string
      vridVal: number
    }) => {
      return [
        <div
          key={`edit-vrid-${vridVal}`}
          onClick={() => {
            setSelectedVrid(vridVal)
            openFormPage()
          }}
        >
          Edit
        </div>,
        ...(appliedTemplate
          ? [
              <div
                key={`edit-vrid-template-${vridVal}}`}
                onClick={() => {
                  setSelectedTemplate(appliedTemplate)
                  openFormPage()
                }}
              >
                Edit Template
              </div>,
            ]
          : []),
        ...(vridVal !== 0
          ? [
              <div
                key={`delete-vrid-${vridVal}`}
                onClick={() => {
                  setSelectedVrid(vridVal)
                  setModalVisible(true)
                }}
              >
                Delete
              </div>,
            ]
          : []),
      ]
    },
    [openFormPage],
  )

  const getData = () => {
    if (!provider || !device || !partition) return

    setLoading(true)
    httpClient
      .get(`${apiPrefix}/vrrp-a/vrid`)
      .then(res => {
        const vrids = res?.data?.['vrid-list'] || []
        setFormattedData(
          vrids.map((vrid: IObject) => {
            const { 'vrid-val': vridVal } = vrid
            const template =
              vrid?.[BLADE_PARAMETERS]?.['fail-over-policy-template']
            const trackingOptions =
              vrid?.[BLADE_PARAMETERS]?.['tracking-options']
            const trackingOptionsValue =
              trackingOptions && formatTrackingOptions(trackingOptions)

            return {
              vrid: vridVal,
              priority: vrid?.[BLADE_PARAMETERS]?.priority,
              ...(template && {
                failoverTemplate: template,
              }),
              ...(trackingOptionsValue &&
                trackingOptionsValue.length > 0 && {
                  trackingOptions: trackingOptionsValue,
                }),
            }
          }),
        )
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false)
      })
  }

  const formatTrackingOptions = (trackingOptions: IObject) => {
    const types = [
      'interface',
      'gateway',
      'bgp',
      'trunk-cfg',
      'route',
      'vlan-cfg',
    ]

    const formattedTrackingOptions = types.reduce((acc, type) => {
      if (trackingOptions[type]) {
        switch (type) {
          case 'interface': {
            const items = trackingOptions[type].map((item: IObject) => (
              <span className={styles.collectionValue}>{`Eth${item.ethernet}${
                item['priority-cost']
                  ? `, Priority Cost: ${item['priority-cost']}`
                  : ''
              }`}</span>
            ))
            acc['Interface Ethernet'] = items

            break
          }
          case 'gateway': {
            const items = [
              'ipv4-gateway',
              'ipv4-gateway-list',
              'ipv6-gateway',
              'ipv6-gateway-list',
            ].reduce((gwAcc, protocol: string) => {
              const list = trackingOptions[type][protocol]
              let _gwAcc = gwAcc
              if (list) {
                _gwAcc = _gwAcc.concat(
                  list.map((item: IObject) => (
                    <span className={styles.collectionValue}>{`${
                      item[
                        `${protocol.includes('ipv4') ? 'ip' : 'ipv6'}-address`
                      ]
                    }${
                      item['priority-cost']
                        ? `, Priority Cost: ${item['priority-cost']}`
                        : ''
                    }`}</span>
                  )),
                )
              }
              return _gwAcc
            }, [])
            acc['Gateway'] = items

            break
          }
          case 'bgp': {
            const items = [
              'bgp-ipv4-address-cfg',
              'bgp-ipv6-address-cfg',
            ].reduce((bgpAcc, protocol: string) => {
              const list = trackingOptions[type][protocol]
              let _bgpAcc = bgpAcc
              if (list) {
                _bgpAcc = _bgpAcc.concat(
                  list.map((item: IObject) => (
                    <span className={styles.collectionValue}>{`${
                      item[
                        `bgp-${
                          protocol === 'bgp-ipv4-address-cfg' ? 'ipv4' : 'ipv6'
                        }-address`
                      ]
                    }${
                      item['priority-cost']
                        ? `, Priority Cost: ${item['priority-cost']}`
                        : ''
                    }`}</span>
                  )),
                )
              }
              return _bgpAcc
            }, [])
            acc['BGP'] = items

            break
          }
          case 'trunk-cfg': {
            const items = trackingOptions[type].map((item: IObject) => (
              <span className={styles.collectionValue}>{`Trunk${item.trunk}${
                item['priority-cost']
                  ? `, Priority Cost: ${item['priority-cost']}`
                  : ''
              }${
                item['per-port-pri']
                  ? `, Per Port Priority: ${item['per-port-pri']}`
                  : ''
              }`}</span>
            ))
            acc['Trunk'] = items

            break
          }
          case 'route': {
            const items = ['ip-destination-cfg', 'ipv6-destination-cfg'].reduce(
              (routeAcc, protocol: string) => {
                const list = trackingOptions[type][protocol]
                let _routeAcc = routeAcc
                if (list) {
                  _routeAcc = _routeAcc.concat(
                    list.map((item: IObject) => {
                      let ipDest,
                        mask,
                        gateway = ''
                      const priorityCost = item['priority-cost']
                        ? `, Priority Cost: ${item['priority-cost']}`
                        : ''
                      const distance = item.distance
                        ? `, Distance: ${item.distance}`
                        : ''
                      const routeProtocol = item.protocol
                        ? `, Protocol: ${item.protocol}`
                        : ''

                      if (protocol === 'ip-destination-cfg') {
                        ipDest = `IP Dest.: ${item['ip-destination']}`
                        mask = item.mask ? `, Mask: ${item.mask}` : ''
                        gateway = item.gateway
                          ? `, Gateway: ${item.gateway}`
                          : ''
                      } else {
                        ipDest = `IP Dest.: ${item['ipv6-destination']}`
                        gateway = item.gateway
                          ? `, Gateway: ${item.gatewayv6}`
                          : ''
                      }

                      return (
                        <span className={styles.collectionValue}>
                          {ipDest +
                            mask +
                            priorityCost +
                            gateway +
                            distance +
                            routeProtocol}
                        </span>
                      )
                    }),
                  )
                }
                return _routeAcc
              },
              [],
            )
            acc['Router'] = items

            break
          }
          case 'vlan-cfg': {
            const items = trackingOptions[type].map((item: IObject) => (
              <span className={styles.collectionValue}>{`VLAN${
                item.vlan
              }, Timeout: ${item.timeout}${
                item['priority-cost']
                  ? `, Priority Cost: ${item['priority-cost']}`
                  : ''
              }`}</span>
            ))
            acc['VLAN'] = items

            break
          }
        }
      }

      return acc
    }, {})

    return Object.entries(formattedTrackingOptions).map(
      (option: [string, JSX.Element[]]) => {
        const [name, items] = option

        if (items.length > 0) {
          return (
            <div className={styles.collection}>
              <span className={styles.collectionTitle}>{name}: </span>
              {items}
            </div>
          )
        }

        return null
      },
    )
  }

  useEffect(() => {
    getData()
  }, [apiPrefix, clustersUpdated])

  const columns = useMemo(() => {
    return [
      {
        title: 'VRID',
        dataIndex: 'vrid',
        key: 'vrid',
        sorter: (a: string, b: string) => utilities.sortString(a, b, 'vrid'),
      },
      {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        sorter: (a: string, b: string) =>
          utilities.sortString(a, b, 'priority'),
      },
      {
        title: 'Failover Template',
        dataIndex: 'failoverTemplate',
        key: 'failoverTemplate',
        sorter: (a: string, b: string) =>
          utilities.sortString(a, b, 'failoverTemplate'),
        render: (value: string) => value ?? INVALID,
      },
      {
        title: 'Tracking Options',
        dataIndex: 'trackingOptions',
        key: 'trackingOptions',
        render: (value: JSX.Element) =>
          value ? (
            <div className={styles.collectionsInTable}>{value}</div>
          ) : (
            INVALID
          ),
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (text: string, record: IObject) => {
          if (isOperatorUser) return null

          const { vrid, failoverTemplate } = record

          return (
            <A10DropdownMenu
              trigger="hover"
              placement="bottomRight"
              arrowPointAtCenter={true}
              menu={moreOptions({
                appliedTemplate: failoverTemplate,
                vridVal: vrid,
              })}
            />
          )
        },
      },
    ]
  }, [isOperatorUser, moreOptions])

  return (
    <>
      <div ref={container} className={styles.sectionContent}>
        {loading ? (
          <A10Loader container={container} />
        ) : (
          <>
            <A10Table
              columns={columns}
              dataSource={formattedData}
              bordered={false}
            />
            {!loading && !isOperatorUser && (
              <ActionButton
                className={styles.actionButton}
                text="Add"
                size="default"
                onClick={openFormPage}
                iconProps={{ app: 'global', type: 'add-new' }}
              />
            )}
          </>
        )}
      </div>
      <A10SlidingPage
        isOpen={slidingPageOpen}
        modalSize="large"
        onRequestClose={closeFormPage}
      >
        {!selectedTemplate
          ? slidingPageOpen &&
            renderForm({
              schemaPath: 'system/vrrpa/settings/vrid',
              apiPrefix,
              params: (selectedVrid || selectedVrid === 0) && {
                ['vrid-val']: selectedVrid,
              },
              successCallback: submitCallback,
              errorCallback: submitCallback,
              cancelCallback: closeFormPage,
            })
          : slidingPageOpen &&
            renderForm({
              schemaPath: 'system/vrrpa/failover-policy-template',
              apiPrefix,
              params: { name: selectedTemplate },
              successCallback: submitTemplateCallback,
              errorCallback: submitTemplateCallback,
              cancelCallback: closeFormPage,
            })}
      </A10SlidingPage>
      <ForceDeleteDialog
        visible={modalVisible}
        closeModal={closeModal}
        api={`${apiPrefix}/vrrp-a/vrid/${selectedVrid}`}
        name={selectedVrid}
        category="VRID"
        onDeleteComplete={getData}
      />
    </>
  )
}

export default VridList
