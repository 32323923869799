import React, { useState, useMemo, useCallback } from 'react'
import { Viz, VizContext } from '@gui-libraries/viz'
import { LogService, useDashboardProviderContext } from '@gui-libraries/dgf'
import { A10DropdownMenu } from '@gui-libraries/widgets'
import moment from 'moment'

import './styles/index.less'

const { LogPanel, Timeline } = Viz

export interface ILogContentProps {
  logConfig: IObject[]
  selectedTenant?: string
  title?: string
}
export interface ILogContentState {
  startTime: number
  endTime: number
}

const LogContent: React.FC<ILogContentProps> = props => {
  const { logConfig, selectedTenant, title } = props
  const {
    parameters,
    service,
    contexts,
    devices,
    clusters,
    selectedContext,
  } = useDashboardProviderContext()

  const [startTime, setStartTime] = useState(
    new Date().getTime() - 6 * 3600 * 1000,
  )
  const [endTime, setEndTime] = useState(new Date().getTime())

  const logService = useMemo(
    () =>
      new LogService({
        ...parameters,
        service,
        contexts,
        selectedContext,
        devices,
        clusters,
      }),
    [parameters, service, contexts, selectedContext, devices, clusters],
  )
  const logPanelRef = React.createRef()
  const logPanelLayout = {
    showChart: false,
    showFilters: false,
    showTable: true,
    showSearch: true,
    showTitle: true,
    header: { title },
  }

  const context = {
    receive: action => {
      const { type } = action
      switch (type) {
        case 'VIZ.TIMELINE.ONCHANGE': {
          const { startTime, endTime } = action
          const newStart =
            startTime < 1000000000000 ? startTime * 1000 : startTime
          const newEnd = endTime < 1000000000000 ? endTime * 1000 : endTime

          setStartTime(newStart)
          setEndTime(newEnd)
        }
      }
    },
  }

  const getLogs = useCallback(
    async (logInfo: any, options: any) => {
      try {
        const { type, endPoint, metrics } = logInfo
        const result = await logService.getLogs(
          endPoint,
          type,
          metrics,
          startTime,
          endTime,
          logInfo,
          options,
        )
        return result
      } catch (err) {
        return null
      }
    },
    [startTime, endTime, logService],
  )

  const getChart = useCallback(
    async (logInfo: any) => {
      try {
        const { endPoint, type, chartMetrics } = logInfo
        const result = await logService.getChartTraffic(
          endPoint,
          type,
          chartMetrics,
          null,
          startTime,
          endTime,
          logInfo,
        )
        return result
      } catch {
        return null
      }
    },
    [startTime, endTime, logService],
  )

  const download = useCallback(() => {
    const { onClickDownload } = logPanelRef.current as IObject
    if (onClickDownload) {
      onClickDownload()
    }
  }, [logPanelRef])

  const customRangeDisabledDate = useCallback((date: moment.Moment) => {
    const isDateBeforeOneYearAgo =
      date <
      moment()
        .subtract(1, 'years')
        .endOf('day')
    const isDateAfterToday = date > moment().endOf('day')
    return isDateBeforeOneYearAgo || isDateAfterToday
  }, [])

  return (
    <>
      <VizContext.Provider value={context}>
        <div className={`dashboard-log-content${title ? '' : ' multiple-tab'}`}>
          <span className="dashboard-log-settings-menu">
            <A10DropdownMenu
              title=""
              menu={[
                <div key="csv" onClick={download}>
                  Download CSV
                </div>,
              ]}
              style={{ color: '#757575', marginBottom: '-15px' }}
              trigger="hover"
              placement="bottomRight"
              arrowPointAtCenter={true}
            />
          </span>
          <Timeline
            filters={{
              timePeriod: [startTime, endTime],
            }}
            customRangeDisabledDate={customRangeDisabledDate}
          />
          <LogPanel
            name="LogPanel"
            showCollapsePanel={true}
            forwardedRef={logPanelRef}
            config={{
              layout: logPanelLayout,
            }}
            useScroll={true}
            logs={logConfig}
            getLogs={getLogs}
            getChart={getChart}
            getInstance={logService.getInstance}
            showSingleContext={true}
            selectedTimePeriod={[startTime, endTime]}
            updateOptions={{ selectedTenant }}
          />
        </div>
      </VizContext.Provider>
    </>
  )
}

export default LogContent
