import React, { useEffect, useState, useRef } from 'react'
import { get } from 'lodash'
import { IA10ContainerDefaultProps } from '@gui-libraries/framework'
import {
  A10Table,
  A10Col,
  A10DropdownMenu,
  A10Loader,
} from '@gui-libraries/widgets'

import { StatusIcon } from 'src/components/shared/StatusIcon'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import EulaSlidingPage from './components/EulaSlidingPage'

import styles from './styles/index.module.less'

export interface IEulaProps extends IA10ContainerDefaultProps {}
export interface IEulaObject {
  'created-at': number
  document: string
  comment: string
  'eula-key': string
  'last-modified-at': string
  location: string
  'provider-id': string
  state: string
  title: string
  uuid: string
}
const STATUS_MAP = {
  ACTIVE: {
    className: `${styles.status}`,
    background: '#81c784',
  },
  DEFAULT: {
    className: `${styles.status}`,
    background: '#cfd9e2',
  },
}

export const removeQuotations = (eula: string) => {
  if (typeof eula === 'string') {
    return eula
  } else if (typeof eula === 'object') {
    // we received an object from api, so stringify it
    let stringifedDocument = JSON.stringify(eula || '')
    return stringifedDocument
  } else {
    return 'Invalid Document Type'
  }
}

const Eula = (props: IEulaProps) => {
  const isOperatorUser = storage.get.IS_OPERATOR_USER
  const providerUser = storage.get.PROVIDER
  const EULA_BASE_URL = `/hpcapi/v3/provider/${providerUser}/eulainfo`
  const httpParams: IObject = {
    absoluteBasePath: true,
  }

  const [open, setOpen] = useState(false)
  const [eulas, setEulas] = useState([])
  const [eula, setEula] = useState()
  const [isLoading, setLoading] = useState(true)
  const [isEditing, setEditing] = useState(false)
  const ref = useRef()

  // utilities
  const onAddNew = () => {
    setOpen(!open)
  }

  const close = () => {
    setOpen(false)
    setEditing(false)
    setEula(undefined)
  }

  // api
  const getEulaList = async () => {
    if (!providerUser) {
      return []
    }

    const {
      data: { 'eulainfo-list': eulaList },
    } = await httpClient.get(EULA_BASE_URL, httpParams)

    return eulaList
  }

  const getEulaInfo = async () => {
    const eulaList = await getEulaList()
    const mappedEulas = eulaList.map((eula: IEulaObject, index: string) => ({
      state: eula.state,
      title: eula.title,
      'where-to-show': eula.location,
      comment: eula.comment,
      document: removeQuotations(eula.document),
      'eula-key': eula['eula-key'],
      key: index,
      'provider-id': eula['provider-id'],
      uuid: eula.uuid,
    }))

    setEulas(mappedEulas)
    setLoading(false)
  }

  const updateTable = () => {
    setLoading(true)
    getEulaInfo()
  }
  // delete and update table
  const deleteEula = async (eulaKey: string) => {
    const deleteUrl = `${EULA_BASE_URL}/${eulaKey}`
    httpClient.delete(deleteUrl, httpParams).then(updateTable)
  }
  // enable/disable eula activity, and update table
  const enableDisableEula = async (eulaKey: string, state: string) => {
    const newState = state === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE'
    httpClient
      .post(`${EULA_BASE_URL}/${eulaKey}`, { eulainfo: { state: newState } })
      .then(() => {
        updateTable()
      })
  }
  const editEula = async (eula: IObject) => {
    const { titleValue, activeRadio, enabled, commentValue, document } = eula
    const eulaKey = getEulaKey(titleValue)
    const modifiedTime = Date.now()
    return httpClient.post(`${EULA_BASE_URL}/${eulaKey}`, {
      eulainfo: {
        document,
        'last-modified-at': modifiedTime,
        location: activeRadio,
        state: enabled ? 'ACTIVE' : 'INACTIVE',
        title: titleValue,
        comment: commentValue,
      },
    })
  }
  const addEula = async (eula: IObject) => {
    const { titleValue, activeRadio, enabled, commentValue, document } = eula

    const modifiedTime = Date.now()

    return httpClient.post(`${EULA_BASE_URL}`, {
      eulainfo: {
        'created-at': modifiedTime,
        document,
        'eula-key': getEulaKey(titleValue),
        'last-modified-at': modifiedTime,
        location: activeRadio,
        state: enabled ? 'ACTIVE' : 'INACTIVE',
        title: titleValue,
        comment: commentValue,
        'provider-id': storage.get.PROVIDER_ID,
      },
    })
  }

  const getEulaKey = (title: string) => {
    // remove spaces and replace with underscore
    // max length 32
    return title.replace(/ /g, '_')
  }

  const columns: IObject[] = [
    {
      title: '',
      dataIndex: 'state',
      key: 'state',
      render: (data: any, record: any) => {
        const status = record.state || 'INACTIVE'
        return <StatusIcon status={status} statusMap={STATUS_MAP} />
      },
      sorter: (a: IObject, b: IObject) =>
        get(a, 'state.length', 0) - get(b, 'state.length', 0),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a: IObject, b: IObject) =>
        get(a, 'title.length', 0) - get(b, 'title.length', 0),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Where To Show',
      dataIndex: 'where-to-show',
      key: 'where-to-show',
      render: (data: any, record: any) => {
        const { 'where-to-show': where } = record
        return where
      },
      sorter: (a: IObject, b: IObject) =>
        get(a, "['where-to-show'].length", 0) -
        get(b, "['where-to-show'].length", 0),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      render: (data: any, record: any) => {
        const { comment } = record
        return <span className={styles.comment}>{comment || ''}</span>
      },
      sorter: (a: IObject, b: IObject) =>
        get(a, 'comment.length', 0) - get(b, 'comment.length', 0),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Preview',
      dataIndex: 'preview',
      key: 'preview',
      render: (data: any, record: any) => {
        const { document: preview } = record
        return <span className={styles.preview}>{preview}</span>
      },
      sorter: (a: IObject, b: IObject) =>
        get(a, 'document.length', 0) - get(b, 'document.length'),
      sortDirections: ['descend', 'ascend'],
    },
  ]

  if (!isOperatorUser) {
    columns.push({
      render: (data: any, record: any) => {
        const { 'eula-key': eulaKey, state } = record
        const isEnabled = state === 'ACTIVE'

        const onClick = async (cmp: IObject, index: any) => {
          const { key: action } = cmp
          if (action === 'delete') {
            await deleteEula(eulaKey)
          } else if (action === 'disable') {
            await enableDisableEula(eulaKey, state)
          } else if (action === 'edit') {
            setEula(record)
            // // open sliding page
            setOpen(true)
            setEditing(true)
          }
        }
        const menu = [
          <div key="edit">Edit</div>,
          <div key="disable">{isEnabled ? 'Disable' : 'Enable'}</div>,
          <div key="delete">Delete</div>,
        ]
        return (
          <div className="table-dropdown">
            <A10DropdownMenu
              trigger={'hover'}
              placement="topRight"
              arrowPointAtCenter={true}
              title={null}
              menu={menu}
              onClick={onClick}
            />
          </div>
        )
      },
      dataIndex: 'actions',
      key: 'actions',
    })
  }

  useEffect(() => {
    getEulaInfo()
  }, [])

  return (
    <>
      <ContentSection>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col>
            <ContentTitle title="Agreements" count={eulas.length || 0} />
          </A10Col>
          {!isOperatorUser && (
            <A10Col>
              <ActionButton
                text="Add New"
                onClick={onAddNew}
                iconProps={{ app: 'global', type: 'add-new' }}
              />
            </A10Col>
          )}
        </ContentHeader>
        <ContentBody ref={ref}>
          <A10Table
            columns={columns}
            dataSource={eulas}
            loading={isLoading && { indicator: <A10Loader container={ref} /> }}
          />
        </ContentBody>
      </ContentSection>

      <EulaSlidingPage
        open={open}
        isEditing={isEditing}
        eulas={eulas}
        editingEula={eula}
        addEula={addEula}
        editEula={editEula}
        updateTable={updateTable}
        close={close}
      />
    </>
  )
}

export default Eula
