import { AjaxService, UrlService } from 'src/services'

export class SystemService {
  UrlService = new UrlService()
  AjaxService = new AjaxService()

  getEmailTemplateData = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_EMAIL_TEMPLATE')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  updateEmailTemplate = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_EMAIL_TEMPLATE')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getDeviceCommunicationConfigData = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_DEVICE_COMMUNICATION')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  updateDeviceCommunicationConfig = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_DEVICE_CONFIG')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getServiceLogDetails = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_SERVICE_LOG_URL')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  changeLogLevel = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('POST_SERVICE_LOG_URL')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getNTPStatus = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_NTP_STATUS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getNTPDetails = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_NTP_DETAILS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  saveNTP = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('POST_NTP_DETAILS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getCertData = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_CERT_DATA')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  updateCertificateData = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_CERT_DATA')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  resetCertificateData = (
    headers: IObject,
    payload: IObject,
    urlInput: string[]
  ) => {
    const urlObj = this.UrlService.get('RESET_CERT_DATA')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  checkNTPStatus = (
    headers: IObject,
    payload: IObject,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('CHECK_NTP_STATUS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  resetEmailTemplate = (
    headers: IObject,
    payload: IObject,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('RESET_EMAIL_TEMPLATE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
}

export default SystemService
