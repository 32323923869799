import React from 'react'
import { A10Container, setupA10Container } from '@gui-libraries/framework'
import {
  A10Form,
  A10Input,
  A10Icon,
  A10Radio,
  A10Col,
  A10Row,
  A10Button,
  A10Upload,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import storage from 'src/libraries/storage'

export interface IFormData {
  name: string
  description: string
  importType: string
  certType: string
  file: IObject
}

interface ICertFormProps {
  onChange?: (data: IFormData) => void
  onRef?: any
}

interface ICertFormStates {
  provider: string
  tenant: string
  formData: IFormData
  fileList: string[]
}

class CertForm extends A10Container<ICertFormProps, ICertFormStates> {
  constructor(props: ICertFormProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER,
      tenant: storage.get.tenant,
      formData: {
        name: null,
        description: null,
        importType: 'certificate',
        certType: 'ssl',
        file: null,
      },
      fileList: [],
    }
  }

  onChangeName = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const state = { ...this.state }
    const { formData } = this.state
    formData.name = e.currentTarget.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onChangeDescription = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const state = { ...this.state }
    const { formData } = this.state
    formData.description = e.currentTarget.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onChangeImportType = (e: React.MouseEvent<any>) => {
    const state = { ...this.state }
    const { formData } = this.state
    formData.importType = e.target.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onChangeCertType = (e: React.MouseEvent<any>) => {
    const state = { ...this.state }
    const { formData } = this.state
    formData.certType = e.target.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onChangeFile = ({ file, fileList }) => {
    const state = { ...this.state }
    const { formData } = this.state
    formData.file = file
    state.fileList = fileList.slice(-1)
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onChangeCallback(formData: IFormData) {
    this.props.onChange(formData)
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }
    const { getFieldDecorator } = this.props.form

    return (
      <A10Form layout="horizontal">
        <A10Panel
          title={
            <A10IconTextGroup
              text="Certificate Info"
              icon={<A10Icon style={{ fontSize: 48 }} type="desktop" />}
            />
          }
        >
          <A10Form.Item {...formItemLayout} label="File Name">
            {getFieldDecorator('fileName', {
              rules: [{ required: true, message: 'Please fill in this field' }],
            })(
              <A10Input
                value={this.state.formData.name}
                onChange={this.onChangeName}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item {...formItemLayout} label="Description">
            {getFieldDecorator('description', {
              rules: [{ max: 2048, message: 'Max length is 2048' }],
            })(
              <A10Input.TextArea
                onChange={this.onChangeDescription}
                placeholder="Enter some description about this Certificate"
                autosize={{ minRows: 2, maxRows: 6 }}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item {...formItemLayout} label="Import">
            <A10Row gutter={8}>
              <A10Radio.Group
                defaultValue="certificate"
                value={this.state.formData.importType}
                onChange={this.onChangeImportType}
              >
                <A10Col lg={12}>
                  <A10Radio value="certificate">Certificate</A10Radio>
                </A10Col>
                <A10Col lg={12}>
                  <A10Radio value="key">Key</A10Radio>
                </A10Col>
              </A10Radio.Group>
            </A10Row>
          </A10Form.Item>
          {this.state.formData.importType === 'certificate' ? (
            <A10Form.Item {...formItemLayout} label="Certificate Type">
              <A10Row gutter={8}>
                <A10Radio.Group
                  defaultValue="ssl"
                  value={this.state.formData.certType}
                  onChange={this.onChangeCertType}
                >
                  <A10Col lg={12}>
                    <A10Radio value="ssl">SSL</A10Radio>
                  </A10Col>
                  <A10Col lg={12}>
                    <A10Radio value="ca">CA</A10Radio>
                  </A10Col>
                </A10Radio.Group>
              </A10Row>
            </A10Form.Item>
          ) : null}
          <A10Form.Item {...formItemLayout} label="Certificate File">
            {getFieldDecorator('certFile', {
              rules: [
                { required: true, message: 'Please upload a Certificate' },
              ],
            })(
              <A10Upload
                onChange={this.onChangeFile}
                fileList={this.state.fileList}
                multiple={false}
                beforeUpload={() => false}
                accept=".cer , .pem , .pfx, .der, .p12, .key"
              >
                <A10Button>
                  <A10Icon type="upload" /> Click to Upload
                </A10Button>
              </A10Upload>,
            )}
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(CertForm))
