export class Messages {
  LOGIN_UNAUTHORISED =
    'Either user name is not registered with us or the user name / password combination is incorrect. Please try again.'
  LOGIN_FAILED = 'Login failed, please try again later'
  SESSION_INVALID = 'Session timed out. Please login again.'
  CLUSTER_TYPE = 'Cluster Type'
  DATAPLANE_TYPE = 'Dataplane Type'
  CONFIG_MANAGEMENT = 'Config Management'
  CLUSTER_NAME = 'Cluster Name'
  CLUSTER_SELECT = 'Select a Cluster'
  CLUSTER_NEW_NAME = 'Cluster New Name'
  DESCRIPTION = 'Description'
  CLUSTER_DESC = 'Enter some description about this cluster'
  CLUSTER_SET_ID = 'Cluster Set ID'
  FLOATING_IP = 'Floating IP'
  FLOATING_IP_MASK = 'Floating IP Mask'
  ENTER_CLUSTER_NAME = 'Please enter cluster name'
  INVALID_CLUSTER_NAME =
    "Please enter a valid cluster name, it cannot exceed 50 letters and no special characters (except '-', '_', '.') and no numeric only"
  INVALID_FLOATING_IP = 'Please enter a valid Floating IP'
  DUPLICATE_FLOATING_IP = 'Floating IP already exists'
  ENTER_FLOATING_IP = 'Please enter Floating IP'
  INVALID_FLOATING_IP_MASK = 'Please enter a valid Floating IP Mask'
  ENTER_FLOATING_IP_MASK = 'Please enter Floating IP Mask'
  ENTER_CLUSTER_SET_ID = 'Please enter Cluster Set ID'
  INVALID_CLUSTER_SET_ID = 'Please enter a valid Cluster Set ID (range 1-12)'
  SUCCESS_CLUSTER_ADD = 'Cluster added successfully'
  FAILURE_CLUSTER_ADD = 'Failed to add cluster'
  LAUNCH_IN_PROGRESS = 'Launch of the Cluster is in Progress'
  LAUNCHED = 'Cluster has been Launched'
  LAUNCH_FAILED = 'Cluster launch has Failed'
  DELETED = 'Cluster successfully Deleted'
  DELETION_FAILED = 'Failed to Delete Cluster'
  NOT_STARTED = 'No Cluster members are started yet'
  WAITING_FOR_CSPS = 'Waiting for all members to start up'
  DELETING = 'In the process of Deleting Cluster'
  MANAUL_CLUSTER_NA = 'Not Available for Manual Cluster'
  INSUFFICENT_PRIVILEGE =
    'You do not have sufficient privileges to access the selected application'
  PROVIDER_NEW_NAME = 'Provider Name'
  PROVIDER_NEW_DESCRIPTION = 'Description'
  STATE = 'State'
  PROVIDER_STATUS = 'Provider Status'
  PROVIDER_UNVERIFIED = 'Unverified User or Password Reset Requested'
  PROVIDER_ACTIVATION = 'Activation URL'
  PROVIDER_EMAIL_PLACEHOLDER = 'Enter email of Org business contact'
  INVALID_PROVIDER_DESCRIPTION =
    'Description must be 1 - 60 characters in length'
  INVALID_PROVIDER_NAME =
    'Please enter a valid first name, it must start with a letter, can be up to 30 characters and have numbers and any of the three special characters ("-", "_", ".")'
  EMPTY = ''
  EMPTY_TENANT_NAME = 'Please enter tenant name'
  VALIDATION_ADD_TENANT_NAME =
    'Please enter a valid tenant name, it must start with a letter, can be up to 30 characters and have numbers and any of the three special characters ("-", "_", ".")'
  INVALID_TENANT_NAME =
    'Please enter a valid tenant name, it must start with a letter, can be upto 30 characters and any of these special characters (!@#$&-_.+,/ *=<>;:^[]?")'
  TENANT_NEW_NAME = 'Tenant'
  SUCCESS_TENANT_UPDATE = 'Tenant updated successfully'
  SUCCESS_PROVIDER_UPDATE = 'Provider updated successfully'
  SUCCESS_CLUSTER_UPDATE = 'Cluster updated successfully'
  FAILURE_CLUSTER_UPDATE = 'Failed to update cluster'
  INTERNAL_SERVER_ERROR = 'Internal Server Error'
  NO_RECORDS_FOUND = 'No record found with this User ID'
  USER_ID = 'User ID'
  USER_EMAIL = 'Email Address'
  USER_FIRSTNAME = 'First Name'
  USER_LASTNAME = 'Last Name'
  USER_DESCRIPTION = 'User description'
  USER_STATE = 'User Status'
  USER_ACTIVATIONLINK = 'Activation Link'
  USER_RESET_MFA = 'Reset MFA'
  RESET_PASSWORD = 'Reset Password'
  NEW_PASSWORD = 'New Password'
  USER_ROLES = 'Roles'
  EMPTY_EMAIL = 'Please enter email address'
  INVALID_EMAIL = 'Please enter a valid email address'
  INVALID_CONTACT_NUMBER =
    'Please enter a valid contact number. (Max 15 digits & only numbers)'
  SUCCESS_USER_UPDATE = 'User updated successfully'
  SUCCESS_SUPER_ADMIN_UPDATE = 'Super Admin updated successfully'
  EMPTY_USER_NAME = 'Please enter the name'
  INVALID_USER_NAME =
    'Please enter a valid name, it cannot exceed 30 letters and no special characters (except "-", "_", ".")'
  EMPTY_FIRST_NAME = 'Please enter the first name'
  INVALID_FIRST_NAME =
    'Please enter a valid first name, it must start with a letter, can be up to 32 characters and have numbers and any of the three special characters ("-", "_", ".")'
  EMPTY_LAST_NAME = 'Please enter the last name'
  INVALID_LAST_NAME =
    'Please enter a valid last name, it must start with a letter, can be up to 32 characters and have numbers and any of the three special characters ("-", "_", ".").'
  REQUIRED_TENANT_ROLE = 'Please select role (provider / tenant admin)'
  INVALID_DESCRIPTION = 'Please enter a valid description'
  DUPLICATE_USER_NAME = 'User with same id already exists.'
  PROVIDER_NAME = 'Provider Name'
  PROVIDER_DESC = 'Description'
  PROVIDER_DESC_PH = 'Enter some description about this provider'
  PROVIDER_ADMIN_EMAIL = "Admin's Email Address"
  PROVIDER_ADMIN_ID = "Admin's User ID"
  PROVIDER_ADMIN_FNAME = "Admin's First Name"
  PROVIDER_ADMIN_LNAME = "Admin's Last Name"
  ENTITLEMENT_TOKEN = 'Entitlement Token'
  ENTER_ENTITLEMENT_TOKEN = 'Enter Entitlement Token'
  AVAILABLE_LICENSES = 'Available Licenses'
  CURRENT_TOKEN = 'Current Entitlement Token'
  CURRENT_LIC_CAPACITY = 'Current License Capacity'
  NEW_CAPACITY = 'New Capacity'
  SELECT_NEW_TOKEN = 'Select new Entitlement Token'
  LICENSE_TYPE_TEXT = 'License type'
  ENTER_BANDWIDTH = 'Enter Bandwidth'
  ENTER_DEVICES = 'Enter Devices'
  UNLIMITED = 'Unlimited'
  ENTER_PROCESSING_UNITS = 'Enter Processing Units'
  PROVIDER_IS_EMAIL_ID = 'Keep User ID same as Email Address'
  VALIDATION_PROVIDER_ADMIN_FNAME =
    "Please enter valid admin's first name, it cannot exceed 30 letters and no special characters (except '-', '_', '.')"
  VALIDATION_PROVIDER_ADMIN_LNAME =
    "Please enter valid admin's last name, it cannot exceed 30 letters and no special characters (except '-', '_', '.')"
  EMPTY_PROVIDER_ADMIN_FNAME = "Please enter admin's first name."
  EMPTY_PROVIDER_ADMIN_LNAME = "Please enter admin's last name."
  EMPTY_PROVIDER_ADMIN_ID = "Please enter admin's user id."
  VALIDATION_PROVIDER_ADMIN_ID = "Please enter valid admin's user id."
  EMPTY_PROVIDER_NAME = 'Please enter provider name'
  DUPLICATE_PROVIDER_NAME =
    'Provider with same name already exists under your Domain.'
  EMPTY_PROVIDER_ADMIN_EMAIL = "Please enter admin's email address."
  VALIDATION_PROVIDER_ADMIN_EMAIL =
    "Please enter a valid admin's email address."
  PROVIDER_ADD_FAILED = 'Failed to add provider'
  PROVIDER_UPDATE_FAILED = 'Failed to update provider'
  SUCCESS_PROVIDER_ADD = 'Provider added successfully'
  SUCCESS_PROVIDER_DELETE = 'Provider deleted successfully'
  PROVIDER_DELETE_FAILED = 'Failed to delete Provider'
  PROVIDER_DELETE_TRIGGER_TEMPLATES_FAILED =
    'Failed to delete chart based trigger templates'
  PROVIDER_SUCCESS_USER_FAIL =
    'Provider added successfully, but admin was not added, please contact current provider admin'
  DUPLICATE_TENANT_NAME =
    'Tenant with same name/display name already exists under your Domain.'
  VALID_JSON = 'Please provide a valid JSON string'
  DUPLICATE_ACTION_NAME = 'Action with same name already exists.'
  DUPLICATE_ALERT_NAME = 'Alert with same name already exists.'
  DUPLICATE_TRIGGER_NAME = 'Trigger with same name already exists.'
  TENANT_NAME = 'Tenant Name'
  ADMIN_LDAP_ID = 'LDAP User ID'
  ADMIN_KEYSTONE_ID = 'Keystone User ID'
  TENANT_ADD_FAILED = 'Failed to add tenant'
  TENANT_ADD_SUCCESS = 'Tenant added successfully'
  SYSTEM = 'System'
  HARMONY = 'Harmony'
  ALERT_ADVANCE = 'Advance'
  FOR_MAX = 'For a max of'
  NOTIFICATION_FREQUENCY = 'Notification Frequency'
  NOTIFICATION_TYPE = 'Notification Type'
  MITIGATION_MESSAGE = 'Mitigation Message'
  NOTIFICATION_EVERY_TIME = 'Every time it Triggers'
  NOTIFICATION_SUPPRESS = 'Suppress Notification'
  TENANT_SUCCESS_USER_FAIL =
    'Tenant added successfully, but admin was not added, please contact provider admin'
  CREDENTIAL_NAME = 'Credential Name'
  CREDENTIAL_NAME_PH = 'e.g. My Credential'
  CREDENTIAL_TYPE = 'Credential Type'
  CLOUDCREDENTIAL_CLOUD = 'Cloud'
  CLOUDCREDENTIAL_CREATEDNS = 'Use same ARN for DNS (Route53) credential'
  CLOUDCREDENTIAL_DNSTYPE = 'DNS Provider'
  CLOUDCREDENTIAL_ARNROLE = 'ARN Role'
  CLOUDCREDENTIAL_GCP = 'Service Account Credential'
  AZURE_SUBSCRIPTION_ID = 'Subscription Id'
  AZURE_TENANT_ID = 'Tenant Id'
  AZURE_APP_ID = 'Client / Application Id'
  AZURE_CLIENT_SECRET = 'Client Secret'
  AZURE_AUTH_KEY = 'Auth Key'
  OCI_USER_ID = 'User OCID'
  OCI_TENANCY_ID = 'Tenancy OCID'
  OCI_REGION = 'Region Identifier'
  OCI_FINGERPRINT = 'Fingerprint'
  EXISTING_CLOUDCREDENTIAL_CHECK =
    'I want to choose from the ARNs I already provided'
  DUPLICATE_CREDENTIAL_NAME = 'Credential with same name already exists'
  CLICK_TO_VIEW = 'Click to view'
  APP_NAME = 'Name'
  APP_VERSION = 'App Version'
  APP_VERSION_PH = 'Provide App Version'
  APP_NAMESPACE = 'Namespace'
  APP_DESC_PH = 'Provide App Description'
  APP_PACKAGE_FILE = 'App Package File'
  APP_NAMESPACE_REQUIRED = 'Please select app namespace'
  APP_VERSION_REQUIRED = 'Please enter app version'
  APP_PACKAGE_FILE_REQUIRED = 'Please select app package file'
  APP_SHORT_NAME = 'Short Name'
  APP_BUILD_NUMBER = 'Build Number'
  APP_DATE_CREATED = 'Date Created'
  APP_UPLOAD_NOTES = 'Comment'
  APP_UPLOAD_NOTES_PH = 'Add a comment'
  ALERT_CREATED_BY = 'Created by'
  SUCCESS_APP_VALIDATE = 'Successfully validated the app'
  SUCCESS_APP_UPLOAD = 'Successfully uploaded the app'
  SUCCESS_APP_UPDATE = 'Successfully updated the app'
  SUCCESS_APP_INSTALL = 'Successfully installed the app'
  FAILURE_APP_INSTALL = 'Unable to install the app'
  SUCCESS_APP_ENABLE = 'Successfully enabled the app'
  FAILURE_APP_ENABLE = 'Unable to enable the app'
  SUCCESS_APP_DISABLE = 'Successfully disabled the app'
  FAILURE_APP_DISABLE = 'Unable to disable the app'
  SUCCESS_APP_DELETE = 'Successfully deleted the app'
  FAILURE_APP_DELETE = 'Unable to delete the app'
  SUCCESS_APP_UNINSTALL = 'Successfully uninstalled the app'
  FAILURE_APP_UNINSTALL = 'Unable to uninstall the app'
  THUNDER_IP_ADDR = 'Thunder Hostname'
  VCS_MASTER = 'VCS Master'
  MANAGEMENT_INTERFACE = 'Management Interface'
  HA_PRIMARY = 'HA Primary'
  DEVICE_NAME = 'Device Name'
  PORT = 'Port'
  ZONE = 'Data Center / Zone / City'
  LOCATION = 'Location'
  REGION = 'Country / Region'
  INTERVAL = 'Interval'
  SUPPRESS_NOTIFICATION_FOR = 'Suppress Notification For'
  METRICS_EXPORT_INTERVAL = 'Metrics Export Interval'
  METRCIS_EXPORT_INTERVAL_REQ = 'Please enter Metrics Export Interval'
  CREATE_NEW_PARTITION = 'Create New Partition'
  ADMIN_USERNAME = 'Admin User Name'
  ADMIN_PASSWORD = 'Admin Password'
  SUCCESS_PASSWORD_REQUEST = 'Reset password email sent successfully!'
  SUCCESS_PASSWORD_UPDATION =
    'Password set successfully, Please login using the credentials.'
  EXPIRED_ACTIVATION_LINK =
    'Unable to determine Authentication type for your domain.The link that you are using for activation is either incorrect or has already been used. Please contact support at Cloud-Support@a10networks.com'
  TRIGGER_NAME = 'Trigger Name'
  REPORT_EXPIRY_MESSAGE =
    'All generated reports with less than 4 weeks duration, will be stored for 6 weeks. Reports with more than 4 weeks duration will be stored for 6 months.'
  CATEGORY_TYPE = 'Category'
  SUBCATEGORY_TYPE = 'Sub Category'
  SUBCATEGORY_TYPE_DOT = 'Sub. Category'
  NONE = 'None'
  OBJECT_TYPE = 'Object Type'
  WINDOW_TYPE = 'Window Type'
  METRIC_TYPE = 'Trigger on (Metrics)'
  WINDOW_SIZE = 'Window Size'
  WINDOW_TIME = 'Window Time'
  TIME_RANGE = 'Time Range'
  INVALID_TIME_RANGE = 'Invalid Time Range, should not be less than a day'
  TRIGGER_MESSAGE = 'Trigger Message'
  WEBHOOK = 'Webhook'
  EMAIL_ALERT_TO = 'Email Alert To'
  METRIC = 'Metric'
  CONDITION = 'Condition'
  OPERATION = 'Operation'
  AGGREGATION_FUNCTION = 'Aggregation Function'
  VECTOR_FUNCTION = 'Vector Function'
  SCALER_FUNCTION = 'Smoothening Function'
  OPERATOR = 'Operator'
  AGGREGATION_CONDITION = 'Condition'
  AGGREGATED_VALUE = 'Value'
  AGGREGATED_VALUE_UNIT = 'Unit'
  AGGREGATED_VALUE_DISPLAY_UNIT = 'Display Unit'
  SECONDS = 'Seconds'
  MINUTES = 'Minutes'
  MINUTES_OCCURRENCE = 'Minutes After First Occurrence'
  NO_DATA_FOR_LAST_24_HOURS = 'No Data For The Last 24 hrs'
  SAMPLES = 'Samples'
  MAX_SAMPLES = 'Max Samples'
  ACTIVE = 'Active'
  ALERT_NAME = 'Alert Name'
  SELECT_ACTION = 'Select Action'
  EXISTING_ACTION = 'Existing Action'
  SCOPE = 'Scope'
  REPORT_NAME = 'Report Name'
  REPORT_TYPE = 'Report Template'
  REPORT_TEMPLATE = 'Report Template'
  DURATION = 'Duration'
  TIMEZONE = 'Time Zone'
  WHEN = 'When'
  ADVANCE = 'Advance'
  FREQUENCY = 'Frequency'
  START_GENERATTING_FROM = 'Start Generating From'
  STARTS_ON = 'Starts on'
  REPORT_FORMAR_IN_ACTION = 'Report Format in Action'
  PRIORITY = 'Priority'
  PRIORITY_SEVERITY = 'Priority/Severity'
  TAGS = 'Tags'
  TRIGGER = 'Trigger'
  RESOURCE_INSTANCE = 'Resource Instance'
  SEL_OBJ_INS = 'Select Object Instance'
  REQ_SEL_OBJ_INS = 'Please Select Object Instance'
  ACTION = 'Action'
  ACTION_NAME = 'Action Name'
  REPORT_ASSOCIATIONS = 'Report Associations'
  EMAIL = 'Email'
  EMAIL_TO_PROVIDER_ADMINS = 'Email to Provider Admins'
  EMAIL_TO_TENANT_ADMINS = 'Email to Tenant Admins'
  SPECIFY_USERS_MAILS = "Specify user's emails"
  EXTENDED_SUBJECT = 'Extended Subject'
  EXTENDED_MESSAGE = 'Extended Message'
  POST = 'POST'
  MESSAGE = 'Message'
  SUBJECT = 'Subject'
  POST_TO_URI = 'POST to URI'
  URI = 'URI'
  HEADERS_PLACEHOLDER =
    'Enter Headers. Example: "{ "prop_1": "val_1", "prop_2": "val_2" }"'
  EXTENDED_BODY_PLACEHOLDER = 'Enter String'
  EXTENDED_BODY = 'Extended Body'
  HEADERS = 'Headers'
  VALIDATE_CERTIFICATE = 'Validate Certificate'
  OBJ_INS = 'Object Instance'
  FULL_NAME = 'Full name'
  SELECT_TENANT = 'Select a Tenant'
  SELECT_TENANT_PARTITION = 'Please associate Tenant for Partition'
  SELECT_ROLE = 'Please select role'
  PROVIDER_ADMIN = 'Provider Admin'
  NAME = 'Name'
  NUMBER = 'Number'
  USER_STATUS = 'User Status'
  ACCESS_LEVEL = 'Access Level'
  SEARCH_DN = 'Search DN'
  LOGIN_ATTR = 'Login Attribute'
  ADMIN_DN = 'Admin DN'
  LDAP_USERID = 'LDAP User ID'
  LDAP_USERID_PH = 'First LDAP User ID'
  LDAP_PASSWORD = 'LDAP Password'
  LDAP_PASSWORD_PH = 'First LDAP User Password'
  LDAP_HOSTS = 'LDAP Hosts'
  LDAP_HOSTS_PH = 'Comma separated LDAP hosts'
  USER_DN = 'User DN Pattern'
  OAUTH_CLIENT_ID = 'OAuth Client ID'
  INVALID_OAUTH_CLIENT_ID = 'OAuth Client ID'
  ORG_NAME = 'Organization name'
  CMP_ADDR = 'Company address'
  CMP_ADDR_PH = 'Enter full mailing address'
  CONTACT_NUMBER = 'Contact Number'
  PARTITION_NAME = 'Partition Name'
  ACTION_ADD_SUCCESS = 'Action added successfully'
  ACTION_UPDATE_SUCCESS = 'Action updated successfully'
  ACTION_ADD_FAILED = 'Failed to add Action'
  ACTION_UPDATE_FAILED = 'Failed to update Action'
  TRIGGER_ADD_SUCCESS = 'Trigger added successfully'
  TRIGGER_UPDATE_SUCCESS = 'Trigger updated successfully'
  TRIGGER_ADD_FAILED = 'Failed to add Trigger'
  FAILED_LOAD = 'Failed to load'
  TRIGGER_UPDATE_FAILED = 'Failed to update Trigger'
  ALERT_ADD_SUCCESS = 'Alert added successfully'
  ALERT_UPDATE_SUCCESS = 'Alert updated successfully'
  ALERT_ADD_FAILED = 'Failed to add Alert'
  ALERT_UPDATE_FAILED = 'Failed to update Alert'
  ALERT_DELETE_SUCCESS = 'Alert deleted successfully'
  ALERT_DELETE_FAILED = 'Failed to delete Alert'
  REPORT_ADD_FAILED = 'Failed to add Report'
  REPORT_SCHEDULE_FAILED = 'Failed to add Report Schedule'
  REPORT_CREATE_SUCCESS = 'Report created successfully!'
  REPORT_SCHEDULE_SUCCESS = 'Report scheduled successfully!'
  VALIDATION_ALERT_NAME = 'Please enter valid Alert Name'
  EMPTY_ALERT_NAME = 'Please enter Alert Name'
  ENTER_ALERT_NAME = 'Enter Alert Name'
  INTERVAL_REQUIRED = 'Please enter interval'
  THRESHOLD_REQUIRED = 'Please enter threshold'
  VALIDATION_INTERVAL = 'Please enter value greater than 0'
  VALIDATION_THRESHOLD = 'Please enter value greater than 0'
  THRESHOLD = 'Threshold'
  TRIGGERS = 'Triggers'
  EXPRESSION = 'Expression'
  EXPRESSIONS = 'Expressions'
  EMPTY_EXPRESSIONS = 'Please enter Expressions (Valid JSON)'
  EXPRESSIONS_JSON_PH =
    'Example: "{ "pkt_count": "total_rev_pkts + total_fwd_pkts", "resp_time": "(total_ssl_conn + total_conn + peak_conn)/response_time", "byte_count": "(total_fwd_bytes + total_rev_bytes + total_rev_pkts + total_fwd_pkts)/response_time"}"'
  EXPRESSION_CONDITION = 'Expression Condition'
  EXPRESSION_CONDITION_PH =
    'Example: (pkt_count > 1000 and byte_count > 15000 ) or resp_time <= 18'
  EMPTY_EXPRESSION_CONDITION = 'Please enter Expression Condition'
  SUPPRESS_COUNT = 'Suppress Count'
  VALIDATION_TRIGGER = 'Please select Trigger'
  VALIDATION_WHOLE_NUMBER = 'Please enter whole number'
  VALIDATION_ACTION = 'Please select Action'
  DUPLICATE_REPORT_NAME = 'Report with same name already exists.'
  VALIDATION_REPORT_NAME = 'Please enter Report Name'
  VALIDATION_REPORT_NAME_LENGTH =
    'Please enter valid Report Name, it cannot exceed 50 letters'
  VALIDATION_REPORT_NAME_SPECIAL =
    'Report Name should start with alphanumeric and no special characters (except _ and -)'
  VALIDATION_REPORT_NAME_MIN_LENGTH =
    'Please enter valid Report Name, it should contain min 2 letters'
  VALIDATION_REPORT_TYPE = 'Please select Report Type'
  VALIDATION_REPORT_TIMEZONE = 'Please select Timezone'
  VALIDATION_START_DATE_AND_TIME_REQUIRED = 'Start generating from is required'
  VALIDATION_START_DATE_AND_TIME = 'Start generating from cannot be in the past'
  VALIDATION_REPORT_TEMPLATE = 'Please select Report Template'
  WAITING_FOR_REPORTS = 'Waiting for report generation!'
  EMPTY_ACTION_NAME = 'Please enter Action Name'
  VALIDATION_ACTION_NAME_LENGTH =
    'Please enter valid Action Name, it cannot exceed 50 letters'
  VALIDATION_ACTION_NAME_MIN_LENGTH =
    'Please enter valid Action Name, it should contain min 2 letters'
  VALIDATION_ACTION_NAME =
    'Action Name should start with a letter and no special characters (except _ and space)'
  EMPTY_TRIGGER_NAME = 'Please enter Trigger Name'
  VALIDATION_TRIGGER_NAME_LENGTH =
    'Please enter valid Trigger Name, it cannot exceed 50 letters'
  VALIDATION_TRIGGER_NAME_MIN_LENGTH =
    'Please enter valid Trigger Name, it should contain min 2 letters'
  VALIDATION_TRIGGER_NAME =
    'Trigger Name should start with a alphanumeric and no special characters (except _ and space)'
  VALIDATION_ALERT_NAME_LENGTH =
    'Please enter valid Alert Name, it cannot exceed 50 letters'
  VALIDATION_ALERT_NAME_MIN_LENGTH =
    'Please enter valid Alert Name, it should contain min 2 letters'
  VALIDATION_WINDOW_SIZE = 'Please enter Window Size'
  VALIDATION_WINDOW_TIME = 'Please enter Window Time'
  VALIDATION_WINDOW_SAMPLE = 'Please enter Window Sample'
  VALIDATION_OBJECT_TYPE = 'Please select Object Type'
  VALIDATION_METRIC_PARAMS = 'Please select metric param'
  VALIDATION_CATEGORY_TYPE = 'Please select category'
  VALIDATION_SUB_CATEGORY_TYPE = 'Please select sub category'
  VALIDATION_AGGREGATED_VALUE = 'Please enter Aggregated Value'
  EMPTY_AGGREGATED_WEIGHT = 'Please enter weight value'
  VALIDATION_AGGREGATED_WEIGHT =
    'Please enter valid weight value between 0 and 1 (exclusive of 0 and 1)'
  VALIDATION_AGGREGATED_WEIGHT_COMMA =
    'Please enter valid weight values (number of weights greater than 1 ) with comma separated'
  VALIDATION_AGGREGATED_WEIGHT_COMMA_VALUE =
    'Please enter valid weight values (value greater than or equal to 0 ) with comma separated'
  FEATURE_CHECK_DEVICE =
    'The device {devcie_name} does not support this feature, please upgrade device to use this feature.'
  FEATURE_CHECK_CLUSTER =
    'The cluster {devcie_cluster_name} does not support this feature, please upgrade the devices associated with this cluster to be able to do this action from the HC.'
  EMPTY_EXPONENTIAL_SAMPLE = 'Please enter sample count greater than 1.'
  VALIDATION_USER = 'Please enter atleast one emailId'
  VALIDATION_SUBJECT = 'Please enter valid Subject'
  VALIDATION_MSG = 'Please enter valid Message'
  VALIDATION_POST_URI = 'Please enter valid URI'
  VALIDATION_ACTION_CONFIG = 'Please configure Email/Post'
  VALIDATION_POST_CONFIG = 'Please configure Post'
  VALIDATION_EMAIL_CONFIG = 'Please configure Email'
  EMPTY_POST_TO_URI = 'Please enter Post to URI'
  INVALID_POST_TO_URI = 'Please enter valid Post to URI'
  ALLOCATION_DATE = 'Allocation Date'
  EXPIRY_DATE = 'Expiry Date'
  EXPIRES_ON = 'Expires On'
  DEVICE_CAPACITY = 'Device Capacity'
  MBU = 'Managed Bandwidth Unit'
  MDU = 'Managed Device Unit'
  MPU = 'Managed Processing Unit'
  NEW_VALUE = 'New Value'
  MNGD_BAND_WIDTH = 'Managed Bandwidth Units'
  MNGD_DEVICES = 'Managed Devices'
  MNGD_PROCESS_UNIT = 'Managed Processing Units'
  ENTITLEMENT_TOKEN_INVALID =
    'Invalid enter entitlement token, no space and special characters (except . - _)'
  ALERT_NAME_INVALID =
    'Alert Name should start with a letter and no special characters (except _ and space)'
  ENTITLEMENT_TOKEN_PH = 'Please enter Entitlement Token'
  LICENSE_TYPE = 'Product'
  LICENSE_TYPE_PH = 'Please select License type'
  LICENSE_REQUIRED = 'Please select the License'
  ENTITLEMENT_TOKEN_REQUIRED = 'Please enter entitlement token'
  DUPLICATE_ENTITLEMENT_TOKEN = 'Token already exists.'
  LICENSE_TYPE_REQUIRED = 'Please select license type'
  THUNDER_BANDWIDTH_REQUIRED = 'Please enter Managed Bandwidth Units'
  BANDWIDTH_REQUIRED = 'Please enter Bandwidth value'
  BANDWIDTH_UNIT_REQUIRED = 'Please select Bandwidth Unit'
  THUNDER_DEVICE_REQUIRED = 'Please enter Managed Devices'
  LADC_PROCESS_UNIT_REQUIRED = 'Please enter Managed Processing Units'
  SUCCESS_LICENSE_UPDATE = 'License updated successfully'
  DEVICE_NAME_REQUIRED = 'Please enter device name'
  INVALID_DEVICE_NAME =
    "Please enter valid device name, it cannot exceed 50 letters and no special characters (except '-', '_', '.')"
  CLUSTER_REQUIRED = 'Please select a cluster'
  THUNDER_IP_REQUIRED = 'Please enter Thunder hostname or IP address'
  PORT_REQUIRED = 'Please enter port'
  ZONE_REQUIRED = 'Please select data center / zone / city'
  REGION_REQUIRED = 'Please select region'
  LOCATION_REQUIRED = 'Please select location'
  USERNAME_REQUIRED = 'Please enter user name'
  USER_PASSWORD_REQUIRED = 'Please enter password!'
  SUCCESS_DEVICE_ADD = 'Device added successfully'
  FAILURE_DEVICE_ADD = 'Failed to add the Device'
  SUCCESS_DEVICE_REGISTER =
    'Device registration task successfully queued. Please refresh screen or go to to Workflow screen to see status.'
  FAILURE_DEVICE_REGISTER = 'Failed to register the Device'
  SUCCESS_DEVICE_DEREGISTER = 'Device de-registered successfully'
  FAILURE_DEVICE_DEREGISTER = 'Failed to de-register the Device'
  SUCCESS_DEVICE_UPDATE = 'Device updated successfully'
  FAILURE_DEVICE_UPDATE = 'Failed to update the Device'
  SUCCESS_DEVICE_DELETE = 'Device deleted successfully'
  FAILURE_DEVICE_DELETE = 'Failed to delete the Device'
  EMPTY_PARTITION_NAME = 'Please enter Partition name'
  INVALID_PARTITION_NAME = 'Please enter valid Partition name'
  TENANT_REQUIRED = 'Please select Tenant'
  SUCCESS_PARTITION_ADD = 'Partition added successfully'
  FAILURE_PARTITION_ADD = 'Failed to add the Partition'
  SUCCESS_PARTITION_EDITED = 'Partition edited successfully'
  FAILURE_PARTITION_EDITED = 'Failed to edit the Partition'
  SUCCESS_PARTITION_UPDATE = 'Partition updated successfully'
  FAILURE_PARTITION_UPDATE = 'Failed to update the Partition'
  SUCCESS_PARTITION_DELETE = 'Partition deleted successfully'
  FAILURE_PARTITION_DELETE = 'Failed to delete the Partition'
  CHANGE_PASSWORD_SUCCESS = 'Changed password successfully'
  CHANGE_PASSWORD_FAIL = 'Could not change password'
  EMPTY_USER_ID = 'Please enter user id'
  INVALID_USER_ID = 'Please enter a valid user id'
  IP_MASK_MISSING = 'Mask address is incomplete'
  IP_MASK_INVALID = 'Invalid Mask address'
  IP_ADDRESS_INVALID = 'Please enter a valid IP address'
  HOSTNAME_INVALID = 'Please enter a valid Hostname'
  HOSTNAME_IP_ADDRESS_INVALID =
    'Please enter a valid Hostname or a valid IP address'
  HOSTNAME_IP_ADDRESS_DUP = 'Duplicate Hostname or IP address'
  HOSTNAME_IP_PORT_DUP = 'Thunder hostname and port combination already exists'
  IPV4_ADDRESS_INVALID =
    'Invalid IPv4 mask address ( Expects a valid IPv4 address or a number between 1-32 )'
  IPV6_ADDRESS_INVALID =
    'Invalid IPv6 mask address ( Expects a number between 1-128 )'
  IPV4_OR_IPV6_ADDRESS_INVALID = 'Invalid network address'
  CREATE_NEW_TENANT = 'Create New Tenant'
  DEVICE_DISABLED = 'Device is disabled.'
  SUCCESS_USER_CREATE = 'User created successfully'
  SUCCESS_SUPER_ADMIN_CREATE = 'Super Admin created successfully'
  FAIL_USER_CREATE = 'Failed to create user'
  FAIL_USER_UPDATE = 'Failed to update user'
  FAIL_SUPER_ADMIN_CREATE = 'Failed to create super admin'
  FAIL_SUPER_ADMIN_UPDATE = 'Failed to update super admin'
  USER_ROLE_SUCCESS = 'User role added successfully'
  USER_ROLE_REM_SUCCESS = 'User role removed successfully'
  USER_ROLE_FAIL = 'Failed to add user role'
  USER_ROLE_REM_FAIL = 'Failed to delete user role'
  BULK_APPLY = 'Apply to all'
  LDAP = 'LDAP'
  EMPTY_LDAP = 'Please select LDAP'
  SELECT_PH = 'Please select an item'
  HOST = 'Host'
  EMPTY_HOST = 'Please enter Host'
  INVALID_HOST = 'Invalid Host!'
  HOST_PH = 'Host text with instruction'
  DN = 'DN'
  DN_PATTERN = 'DN Pattern'
  EMPTY_DN_PATTERN = 'Please enter DN Pattern'
  INVALID_DN_PATTERN = 'Invalid DN Pattern'
  PASSWORD = 'Password'
  EMPTY_LOGIN_ATTR = 'Please enter Login Attribute'
  EMPTY_ADMIN_DN = 'Please enter Admin DN'
  ADMIN_PWD = 'Admin Password'
  USER_PWD = 'User Password'
  AUTH_METHOD = 'Authentication Method'
  EMPTY_AUTH_METHOD = 'Please enter Authentication Method'
  REMOTE_ADDR = 'Remote Address'
  EMPTY_REMOTE_ADDR = 'Please enter Remote Address'
  INVALID_REMOTE_ADDRESS =
    'Invalid Remote Address! Should be less than 100 characters, special characters allowed are -, _, .'
  RETRIES = 'Retries'
  EMPTY_RETRIES = 'Please enter Retries'
  INVALID_RETRIES = 'Invalid Retries, should not be greater than 10'
  TIMEOUT = 'Timeout'
  INVALID_TIMEOUT = 'Invalid Timeout, should not be greater than 10'
  EMPTY_TIMEOUT = 'Please enter Timeout'
  SHARED_SECRET = 'Shared Secret'
  EMPTY_SHARED_SECRET = 'Please enter Shared Secret'
  INVALID_SHARED_SECRET =
    'Invalid Shared Secret, it should be 6 - 30 characters in length'
  INVALID_PASSWORD =
    'Password length: 6-40 characters. Allowed characters: A-Z, a-z, 0-9, !, @, #, %, *'
  ACCOUNTINGPORT = 'Accounting Port'
  EMPTY_ACCOUNTINGPORT = 'Please enter Accounting Port'
  AUTHPORT = 'Authentication Port'
  AUTHPORT_REQUIRED = 'Please enter Authentication Port'
  EMPTY_NAME = 'Please enter the name'
  KEY = 'Key'
  KEY_REQUIRED = 'Please enter the key'
  SECRET = 'Secret'
  EMPTY_SECRET = 'Please enter the Secret'
  CALLBACK_URL = 'Callback URL'
  EMPTY_CALLBACK_URL = 'Please enter the Callback URL'
  REJECTED_CALLBACK_URL = 'Rejected Callback URL'
  EMPTY_REJECTED_CALLBACK_URL = 'Please enter the Rejected Callback URL'
  SELECT_APPSERVICE = 'Select an App Service'
  ACOS_VERSION = 'ACOS Version'
  APP_DESCRIPTION = 'App Description'
  APPDOC_URL = 'App Documentation'
  USER_GUIDE = 'User Guide'
  BUILD_NUMBER = 'Build Number'
  DATE_CREATED = 'Date Created'
  APP_AUTHOR = 'Author / Publisher'
  HC_PLATFORM_VERSION = 'HC Platform Version'
  STATUS = 'Status'
  NO_APP_SERVICE = 'No App service (Active) to select'
  INVALID_PORT_NUMBER =
    'Please enter the valid port number (Should be less than 65536)'
  ACCOUNT_NAME = 'Account Name'
  ACCOUNT_NAME_REQ = 'Please enter account name'
  INVALID_ACCOUNT_NAME =
    'Please enter the valid account name and no special characters (except "-", "_", "."), it cannot exceed 50 letters'
  ACCOUNT_NAME_DUP = 'Account Name already exists'
  VCENTER_IP_ADDRESS = 'vCenter IP Address'
  VCENTER_IP_ADDRESS_REQ = 'Please enter vCenter IP Address'
  VCENTER_IP_ADDRESS_DUP = 'vCenter IP Address already exists'
  VCENTER_IP_ADDRESS_INVALID = 'Invalid vCenter IP Address'
  VCENTER_IP_USERNAME_DUP =
    'vCenter IP Address & User name combination already exists'
  USER_NAME = 'User Name'
  USER_NAME_DUP = 'User name already exists'
  CREDENTIAL_CREATED = 'Credential created successfully'
  CREDENTIAL_CREATE_FAIL = 'Error in Credential creation'
  CREDENTIAL_UPDATED = 'Credential updated successfully'
  CREDENTIAL_UPDATE_FAIL = 'Error in Credential updation'
  CREDENTIAL_DELETED = 'Credential deleted successfully'
  CREDENTIAL_DELETE_FAIL = 'Error in Credential deletion'
  INFRA_ENVIRONMENT = 'Environment'
  INFRA_ENV_SEL = 'Please select environment'
  INFRA_MANAGEMENT_SECURE_PORT = 'Please enter secure port number'
  EMPTY_LOGICAL_PARTITION = 'Please enter logical Partition'
  FAILED_LOGICAL_PARTITION = 'Failed to Create logical partition'
  CREATE_RE_PROVISION_SUCCESS = 'Successfully Re-provisioned'
  CREATE_RE_PROVISION_FAILED = 'Failed to Re-provision'
  VM_NAME_REQ = 'Please Enter VM Device name'
  INVALID_VM_NAME =
    'Please enter the valid VM Device name and no special characters (except "-", "_", "."), it cannot exceed 50 letters'
  K8S_INVALID_VM_NAME =
    'Please enter the valid VM Device name. It must start and end with an alphanumeric character and must not have uppercase or special characters (except "-")'
  K8S_INVALID_RESOURCE_SIZE =
    'Invalid value! Only numbers and no alphabetes/special characters'
  VM_NAME_DUP = 'Duplicate VM Device name'
  SUCCESS_VMDEVICE_ADD = 'VM Device Deployment request received'
  FAILURE_VMDEVICE_ADD = 'Failed to create VM Device'
  SUCCESS_VMDEVICE_DELETE = 'VM Device delete request initiated'
  FAILURE_VMDEVICE_DELETE = 'Failed to delete VM Device'
  HARMONY_LICENSE_CHANGE_SUCCESS =
    "Device's Harmony License change request submitted successfully, please check the status in activity log for the license"
  HARMONY_LICENSE_CHANGE_FAIL = 'Harmony license change request failed'
  DEVICE_LICENSE_CHANGE_SUCCESS =
    'Device License change request submitted successfully, please check the status in activity log for the license'
  DEVICE_LICENSE_CHANGE_FAIL = 'Device license change request failed'
  SUCCESS_VMDEVICE_DEPLOY = 'Deployment request received'
  FAILURE_VMDEVICE_DEPLOY = 'Deployment request initiation failed'
  ENVIRONMENT_TYPE = 'Environment Type'
  CREDENTIALS = 'Credentials'
  MANAGEMENT_SECURE_PORT = 'Port'
  DUPLICATE_PARTITION_NAME = 'Partition already exists'
  EMPTY_PARTITION_ID = 'Please enter Partition id'
  INVALID_PARTITION_ID =
    'Please enter valid partition id - Only Integer is allowed'
  DUPLICATE_PARTITION_ID = 'Partition Id already exists'
  DUPLICATE_CLUSTER_NAME = 'Cluster name/display name already exists'
  SUCCESSFULLY_REBOOTED = 'Successfully Rebooted'
  FAILED_TO_REBOOT = 'Failed to Reboot'
  DUPLICATE_DEVICE_NAME = 'Device with same name already exists'
  DUPLICATE_DEVICE_NAME_O = 'Duplicate Device name'
  AUTH_TYPE = 'Authentication Type'
  EMPTY_AUTH_TYPE = 'Please select Authentication Type'
  DISPLAY_NAME = 'Display Name'
  INVALID_DISPLAY_NAME =
    'Please enter a display name, it cannot exceed 50 letters and allowed special characters are (!@#$&-_.+,/ *=<>;:^[]?")'
  ENTER_DISPLAY_NAME = 'Please enter display name'
  APP_SVC_ID = 'App Service ID'
  IP_DOMAIN_URL = 'IP / Domain URL'
  GLOBAL = 'GLM'
  LOCAL = 'Local License Manager'
  IP_DOMAIN_PH = 'Enter an IP or URL'
  PORT_PH = 'e.g. 8080'
  PORT_443PH = 'e.g. 443'
  LICENSE = 'License'
  USE_MGMT_PORT = 'Use Management Port'
  FAILURE_DEVICE_LICENSE_ASSIGN = 'Failed to assign license to device'
  DEFAULT_ADMIN_NAME = 'Default Admin Name'
  ORG_DESCRIPTION_PLACEHOLDER = 'Enter some description about this organization'
  INVALID_NAME =
    'Invalid Name! it cannot exceed 50 letters and spaces are not allowed!!'
  INVALID_NAME_SPACE_50 =
    'Invalid Name! it cannot exceed 50 letters, cannot start with spaces, and should not have special characters (except . _ - and space) '
  INVALID_NAME_SPACE_30 =
    'Invalid Name! it cannot exceed 30 letters, cannot start with spaces, and should not have special characters (except . _ - and space) '
  CANNOT_BE_EMPTY = 'Cannot be empty'
  FIRST_USER_ID = 'First User ID'
  FIRST_USER_PASSWORD = 'First User Password'
  VALID_TEXT_3_SPL =
    "Only Aplhabets, digits and no special characters (except '-', '_', '.') "
  NO_SPACE = 'No space allowed '
  ACCESS_KEY = 'Access Key'
  ACCESS_KEY_REQUIRED = 'Please enter access key'
  SECRET_ACCESS_KEY = 'Secret Access Key'
  SECRET_ACCESS_KEY_REQUIRED = 'Please enter secret access key'
  ROLE_ARN = 'Role ARN'
  ROLE_ARN_REQUIRED = 'Please enter Role ARN'
  EXTERNAL_ID = 'Exteranl ID'
  EXTERNAL_ID_REQUIRED = 'Please enter Exteranl ID'
  ADMIN_ACTIVATION_LINK = 'Admin Activation Link'
  CONVERT_TO_MULTINODE = 'Convert to Harmony Managed'
  CLUSTER_USERNAME = 'Cluster User Name'
  CLUSTER_PASSWORD = 'Cluster Password'
  BULK_LICENSE_CHANGE_SUCCESS =
    'Bulk license update request submitted successfully, please check the status in activity log for the license'
  BULK_LICENSE_CHANGE_FAIL = 'Bulk license update request failed'
  OFFLINE_ACTIVATION = 'Offline Activation'
  ACTIVATION_UUID = 'Activation UUID'
  ACTIVATION_KEY_LM = 'Activation Key from License Manager'
  EMPTY_ACTIVATION_KEY_LM = 'Please enter activation key from license manager'
  CAPACITY = 'Capacity'
  SHARED_POOL = 'Shared Pool'
  DEDICATED_POOL = 'Dedicated Pool'
  CAPACITY_LIMIT = 'Capacity Limit'
  RESERVED = 'Reserved'
  LOG_P_C = 'Log Processing Capacity'
  DEDICATED_CAPACITY = 'Dedicated Capacity Allocation'
  ENTER_CAPACITY = 'Enter Log Capacity'
  CREDENTIAL_VALIDATED = 'Credential validated successfully'
  CREDENTIAL_VALIDATE_FAIL = 'Error in Credential validation'
  ANALYTIC_LOG_PC = 'Analytics Log Processing Capacity'
  LOG_PROCESSING_HISTORY = 'LOG PROCESSING HISTORY'
  LOG_VALUE_REQUIRED = 'Log capacity value required'
  LOG_UNIT_REQUIRED = 'Unit required'
  TASK_NAME_PH = 'Enter a Task Name'
  CMD_SNIPPET = 'Command Snippet'
  SNIPPET_NAME = 'Snippet Name'
  SNIPPET_NAME_PH = 'Enter a Snippet Name'
  CLUSTER = 'Cluster'
  PARTITION = 'Partition'
  SHOW_RUNNING_CONFIG_FOR = 'Show Running Config. for'
  RESET_MFA =
    "Are you sure you want to reset your MFA settings? You'll be automatically logged out and will be prompted for MFA reconfiguration after re-login."
  EMPTY_ENFORCEMENT_DATE = 'Please select enforcement date'
  MAX_ALLOWED_TIME_DIFF =
    'Maximum Allowed Time Difference between Controller and Thunders'
  MAX_MIN_RANGE = 'Allowed range of value is 90 to 900'
  EDIT_DEVICE_COMMUNICATION = 'Edit Device Communication Configuration'
  DEVICE_COMMUNICATION = 'Device Communication'
  SUCCESS_DEVICE_CONFIG_UPDATE =
    'Time difference between Controller and Thunders changed successfully'
  FAILED_DEVICE_CONFIG_UPDATE =
    'Failed to change time difference between Controller and Thunders'
  SUCCESS_TEMPLATE_UPDATE = 'Template saved successfully'
  FAILED_TEMPLATE_UPDATE = 'Failed to update Template'
  EMAIL_TEMPLATE = 'EMAIL TEMPLATE'
  USER_WELCOME_EMAIL = 'User Welcome Email'
  EMAIL_BODY = 'Email Body'
  USER_ACTIVATION_EMAIL = 'User Activation Email'
  RESET_PASSWORD_EMAIL = 'Reset Password Email'
  SUCCESS_API_SERVER_UPDATE =
    'HC Property Config of API Server updated successfully'
  FAILED_API_SERVER_UPDATE = 'Failed to update HC Property Config of API Server'
  SUCCESS_ANALYTICS_CONFIG_UPDATE =
    'HC Property Config of Analytics Metrics Processing Engine updated successfully'
  FAILED_ANALYTICS_CONFIG_UPDATE =
    'Failed to update HC Property Config of Analytics Metrics Processing Engine'
  SUCCESS_LOG_RATE_PROCESSING_UPDATE =
    'Log Processing Capacity updated successfully'
  FAILED_LOG_RATE_PROCESSING_UPDATE = 'Failed to update Log Processing Capacity'
  SUCCESS_SERVICE_LOG_UPDATE = 'Log level changed successfully'
  FAILED_SERVICE_LOG_UPDATE = 'Failed to change log level'
  SUCCESS_NTP_UPDATE = 'NTP server updated successfully'
  FAILED_NTP_UPDATE = 'Error in NTP server updation'
  SSL_CERTIFICATE = 'SSL CERTIFICATE'
  FQDN = 'FQDN'
  SERVER_CERTIFICATE = 'Server Certificate'
  CA_CERTIFICATE = 'CA Certificate Chain'
  FQDN_PLACEHOLDER = 'portal.example.com'
  PRIVATE_KEY = 'Private Key'
  SERVER_CERTIFICATE_PLACEHOLDER =
    '-----BEGIN CERTIFICATE----- \n\n\n\n\n\n\n\n -----END CERTIFICATE-----'
  PRIVATE_KEY_PLACEHOLDER =
    '-----BEGIN RSA PRIVATE KEY----- \n\n\n\n\n\n\n\n -----END RSA PRIVATE KEY-----'
  CERTIFICATE_SUCCESS =
    'Certificate details saved successfully. Please relogin by using newly configured domain.'
  CERTIFICATE_FAILURE = 'Failed to update data'
  EMPTY_FQDN = 'Please enter fqdn'
  EMPTY_SERVER_CERT = 'Please enter server certificate'
  EMPTY_CA_CERT = 'Please enter ca certificate'
  EMPTY_PRIVATE_KEY = 'Please enter private key'
  SERVER_HOST = 'Server Address'
  SERVER_PORT = 'Server Port'
  SERVER_USERNAME = 'Server User Name'
  SERVER_PASSWORD = 'Server Password'
  EMAIL_ADDRESS = 'From Email Address'
  EMAIL_NAME = 'Email Name'
  TO_EMAIL_ADDRESS = 'To Email Address'
  TEST_EMAIL_SUCCESS = 'Test email sent successfully'
  RESET_EMAIL_SUCCESS = 'Email configuration reset successfully'
  RESET_EMAIL_FAILED = 'Failed to reset email configuration'
  RESET_EMAIL_TEMPLATE_SUCCESS =
    'Email template configuration reset successfully'
  RESET_EMAIL_TEMPLATE_FAILED = 'Failed to reset email template configuration'
  EMAIL_SERVER_SUCCESS = 'Email server updated successfully'
  EMAIL_SERVER_FAILED = 'Failed to update Email server'
  EMPTY_SERVER_HOST = 'Enter server host'
  EMPTY_SERVER_PORT = 'Enter server port'
  EMPTY_SERVER_USERNAME = 'enter server username'
  EMPTY_SERVER_PASSWORD = 'Enter server password'
  EMPTY_EMAIL_ADDRESS = 'Enter email address'
  EMPTY_EMAIL_NAME = 'Enter email name'
  EMPTY_PORT = 'Please enter port'
  EMAIL_SERVER = 'EMAIL SERVER'
  EMPTY_ACTIVITY_TIMEOUT = 'Please enter activity timeout'
  INACTIVITY_TIMEOUT = 'Enter inactivity timeout'
  EMPTY_INACTIVITY_TIMEOUT = 'Please enter inactivity timeout'
  EMPTY_LOG_RETAINTION_PERIOD = 'Please enter Log Retaintion Period'
  EMPTY_AUTOMATIC_LOG_RATE = 'Please enter Automatic Log Rate'
  EMPTY_MATRICS_RETAINTION_PERIOD = 'Please enter Matrics Retaintion Period'
  EMPTY_ALERT_RETAINTION_PERIOD = 'Please enter Alert Retaintion Period'
  EMPTY_SUBJECT = 'Please enter subject'
  EMPTY_EMAIL_BODY = 'Please enter email body'
  INVALID_PRIVATE_KEY = 'Invalid private key'
  EMPTY_TIME_DIFFERENCE = 'Please enter time difference'
  INVALID_TIME_DIFFERENCE = 'Please enter valid time difference'
  INVALID_ACTIVATION_STRING = 'Activation string limit has been exceeded.'
  CONTROLLER_VERSION = 'Controller Version'
  LOG_LEVEL = 'Log Level'
  ROLE = 'Role'
  EMPTY_LOGS_DATE = 'Please enter the period'
  SUCCESS_RUN_COMMAND = 'Command executed successfully, Collecting output...'
  FAILED_RUN_COMMAND = 'Failed to execute the command'
  FIRSTNAME_ATTRIBUTE = 'User First Name Attribute'
  LASTNAME_ATTRIBUTE = 'User Last Name Attribute'
  OBJECT_CLASS = 'Group object Class'
  BASE_DN = 'Group Base DN'
  GROUP_MEMBERSHIP_USER = 'Group Membership of User'
  GROUP_ID_ATTRIBUTE = 'Group ID Attribute'
  GROUP_MEMBERSHIP_ATTRIBUTE = 'Group Membership Attribute'
  USER_ATTRIBUTE = 'User Attribute (in Group Member Attribute)'
  EMPTY_GROUP_BASE_DN = 'Please enter group base DN'
  GROUP_MEMBER_ATTR = 'Group Member Attribute'
  EMPTY_GROUP_MEMBERSHIP_ATTRIBUTE = 'Please enter group membership attribute'
  EMPTY_GROUP_MEMBER_ATTR = 'Please enter group member attribute'
  EMPTY_USER_ATTRIBUTE = 'Please enter user attribute'
  EMPTY_GROUP_ID_ATTRIBUTE = 'Please enter group ID attribute'
  VALID_GROUP_BASE_DN = 'Please enter a valid Group Base DN'
  GROUP_BASE_DN_PATTERN =
    'Group Base DN pattern should consist of several equations "Key=Value"(Separated by commas)'
  EMPTY_ADMIN_PASSWORD = 'Please enter a admin password'
  LOAD_LDAPGROUP_FAILED = 'Failed to load LDAP groups'
  WARN_DEVICE_PARTITION_STATS =
    'Device partition statistics are not available to show Throughput and CPS'
  WARN_DEVICE_CLUSTER_STATS =
    'Device cluster statistics are not available to show Throughput and CPS'
  RESET_SSL_CERTIFICATE_SUCCESS =
    'Successfully reset the SSL certificate to default'
  RESET_SSL_CERTIFICATE_FAIL = 'Fail to reset the SSL certificate to default'
  VALIDATION_BACKUP_NAME = 'Please enter Backup Name'
  DUPLICATE_BACKUP_NAME = 'Backup with same name already exists'
  BACKUP_NAME = 'Backup Name'
  SELECT_DEVICE = 'Select Device'
  EMPTY_LOG_LEVEL = 'Please select log level'
  LOGGING_FAILURE = 'Failed to update log level'
  SERVICE_LOGGING = 'SERVICE LOGGING'
  INVALID_NTP_INPUT = 'Please enter valid input'
  NTP_DUPLICATE_NAME = 'Please remove duplicate values'
  NTP_EMPTY_INPUT = 'Please enter server details'
}

export default Messages
