import React, { useRef } from 'react'
import {
  A10Layout,
  A10Badge,
  A10Row,
  A10Tooltip,
  A10Loader,
} from '@gui-libraries/widgets'

import styles from './styles/index.module.less'

export interface IContentSectionProps {
  className?: string
  children?: React.ReactNode
}

export interface IContentHeaderProps {
  className?: string
  children?: React.ReactNode
}

export interface IContentTitleProps {
  title: string
  count?: number
  countTooltip?: string
  remaining?: string
  total?: number
}
export interface IContentBodyProps {
  isLoading?: boolean
  children?: React.ReactNode
}

export interface IDividerProps {}

const ContentSection: React.FC<IContentSectionProps> = props => {
  const { className, children } = props

  return (
    <A10Layout.Content className={`${className} ${styles.contentSection}`}>
      {children}
    </A10Layout.Content>
  )
}

export default ContentSection

export const ContentHeader: React.FC<IContentHeaderProps> = props => {
  const { className, children, ...restProps } = props
  return (
    <A10Row
      {...restProps}
      className={`${styles.contentHeader} ${className || ''}`}
    >
      {props.children}
    </A10Row>
  )
}

export const ContentTitle: React.FC<IContentTitleProps> = props => {
  const { title, count, countTooltip, remaining, total } = props
  return (
    <div className={styles.contentTitle}>
      <span className={styles.title}>{title}</span>
      <A10Tooltip title={countTooltip}>
        <A10Badge count={count} overflowCount={9999} showZero />
      </A10Tooltip>
      {total !== -1 && remaining && total && (
        <span className={styles.info}>
          {remaining} Out of {total} Remaining
        </span>
      )}
    </div>
  )
}

export const ContentBody: React.FC<IContentBodyProps> = React.forwardRef(
  (
    props,
    ref: React.MutableRefObject<undefined> | React.LegacyRef<HTMLDivElement>,
  ) => {
    const refContainer = useRef()
    const { isLoading, children, ...restProps } = props
    return (
      <A10Row {...restProps}>
        <div ref={ref ?? refContainer}>
          {isLoading ? (
            <div style={{ minHeight: '70px' }}>
              <A10Loader container={ref ?? refContainer} />
            </div>
          ) : (
            children
          )}
        </div>
      </A10Row>
    )
  },
)
