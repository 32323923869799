import React, { useRef, useEffect, useState, useCallback } from 'react'
import { A10Chart } from '@gui-libraries/widgets'
import moment from 'moment'

export interface ISparcLineProps {
  chartConfig?: IObject
  seriesData?: IObject
  seriesName?: string
  healthStatusMode?: boolean
  customMessageForNoData?: string
}

export interface ISparcLineState {
  chartConfig: any
}

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

const operStatsMapping = {
  0.5: 'Unknown',
  1: 'Up',
  0.9: 'Functionally Up',
  0.7: 'Partially Up',
  0.1: 'Disabled',
  0: 'Down',
}

const SparcLine: React.FC<ISparcLineProps> = props => {
  const defaultChartConfig = useRef({
    chart: {
      animation: false,
      type: 'area',
      height: '46px',
    },
    lang: {
      noData: 'No Data To Display',
    },
    credits: { enabled: false },
    title: {
      text: '',
    },
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
    },
    xAxis: {
      gridLineWidth: '0px',
      tickPosition: 'inside',
      type: 'datetime',
      labels: {
        // format: '{value:%Y-%b-%e %H:%M}',
        enabled: false,
      },
    },
    yAxis: {
      gridLineWidth: '0px',
      labels: { enabled: false },
      title: { text: '' },
    },
    tooltip: {
      xDateFormat: '%Y-%b-%e %H:%M',
      valueDecimals: 1,
      formatter: (d: IObject) => {
        const point = d.chart.hoverPoint
        return (
          point.name +
          '<br/>' +
          moment(point.x).format('MM/DD, HH:mm:ss') +
          ' : <b>' +
          Math.round(point.y * 100) / 100 +
          '</b>'
        )
      },
      outside: true,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        animation: { duration: 0 },
        stacking: 'normal',
        lineColor: '#887cc6',
        lineWidth: 1,
        color: 'rgba(219, 217, 238, 0.46)',
      },
    },
  })
  const {
    chartConfig,
    seriesData,
    seriesName,
    healthStatusMode,
    customMessageForNoData,
  } = props
  if (customMessageForNoData) {
    defaultChartConfig.current.lang.noData = customMessageForNoData
  }
  const [config, setConfig] = useState(defaultChartConfig.current)

  const healthStatusTooltipFormatter = useCallback((d: IObject) => {
    const point = d.chart.hoverPoint
    return (
      '<br/>' +
      moment(point.x).format('YYYY-MM-DD HH:mm:ss') +
      '<br/>Health Status: ' +
      (operStatsMapping[point.y] || `${(point.y || 0).toFixed(4) * 100} %`)
    )
  }, [])

  useEffect(() => {
    const _config = {
      ...defaultChartConfig.current,
      ...chartConfig,
      series: [
        {
          data: seriesData ?? chartConfig?.series?.[0].data,
          name: seriesName ?? chartConfig?.series?.[0].name,
        },
      ],
    }
    if (healthStatusMode) {
      _config.tooltip.formatter = healthStatusTooltipFormatter
    }
    setConfig(_config)
  }, [chartConfig, seriesData, seriesName, healthStatusMode])

  return (
    <div className={styles.sparcLine}>
      <A10Chart className={styles.sparcLineChart} config={config} />
    </div>
  )
}

export default SparcLine
