import React from 'react'

import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import { A10Modal, A10Button } from '@gui-libraries/widgets'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { LicenseForm } from './index'
import { Utilities, DashboardService } from 'src/services'

export interface ISlidingLicenseManagerProps extends IA10ContainerDefaultProps {
  isOpen: boolean
  lmObject: any
  onRequestClose: () => void
  refreshLMInfo: () => void
}

export interface ISlidingLicenseManagerStates {
  lmObject: any
  disableSave: boolean
  saveConfirm: boolean
  isTested: number
  testMsg: string
}

class SlidingLicenseManager extends A10Container<
  ISlidingLicenseManagerProps,
  ISlidingLicenseManagerStates
> {
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  childForm: any = null

  constructor(props: ISlidingLicenseManagerProps) {
    super(props)
    this.state = {
      lmObject: this.props.lmObject,
      disableSave: true,
      saveConfirm: false,
      isTested: 0,
      testMsg: 'Test License Manager settings to be able to Save.',
    }
  }

  componentWillReceiveProps(nextProps: IObject) {
    const state = { ...this.state }
    const { lmObject } = nextProps
    state.lmObject = lmObject
    if (this.props.isOpen !== nextProps.isOpen && nextProps.isOpen) {
      state.isTested = 0
    }
    this.setState(state)
  }

  onLMFormChange = (lmObject: any, isTested: number) => {
    const state = { ...this.state }

    if (lmObject.lmType === 'Global') {
      state.disableSave = isTested != 1
    } else if (lmObject.lmType === 'Local') {
      state.disableSave =
        !lmObject.activationStatus &&
        (!lmObject.llmActivationString || lmObject.llmActivationString.length > 16832 || !lmObject.llmUUID)
    }

    state.lmObject = lmObject
    state.isTested = isTested
    this.setState(state)
  }

  onClose = () => {
    this.props.refreshLMInfo()
    this.props.onRequestClose()
  }

  activateLLM = () => {
    const { lmObject } = this.state
    if (!lmObject.llmUUID) {
      this.Utilities.showMessage('License Manager UUID is empty', 0, 0)
      return
    }
    if (!lmObject.llmActivationString) {
      this.Utilities.showMessage('Please enter activation string', 0, 0)
      return
    }
    const activateLLM = this.DashboardService.activateLLMUUID(
      null,
      lmObject.llmActivationString,
      null,
    )
    activateLLM
      .then((resp: any) => {
        if (resp) {
          this.Utilities.showMessage('LLM Activation is successful', 1, 0)
          lmObject.activationStatus = true
          this.setState({ lmObject })
        }
      })
      .catch((err: any) => {
        const msg =
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : ''
        this.Utilities.showMessage('Error while LLM activation', 0, 0, msg)
      })
  }

  onConfirmOrSave = (callType: string) => {
    if (callType === 'confirm') {
      this.setState({ saveConfirm: true })
      return
    }
    const {
      lmType,
      apiSuffix,
      ipDomainURL,
      port,
      userName,
      password,
      llmUUID,
      activationStatus,
      useMgmtPort,
    } = this.state.lmObject

    const payload = {
      'lm-type': lmType,
      'elm-api-suffix': lmType === 'Local' ? apiSuffix : undefined,
      'elm-url': lmType === 'Local' ? ipDomainURL : undefined,
      'elm-port': lmType === 'Local' ? port : undefined,
      'elm-user': lmType === 'Local' ? userName : undefined,
      'elm-password': lmType === 'Local' ? password : undefined,
      'elm-uuid': lmType === 'Local' ? llmUUID : undefined,
      'activation-status': lmType === 'Local' ? activationStatus : undefined,
      'use-mgmt-port': useMgmtPort,
    }

    let saveLMInfo = this.DashboardService.updateLicenseManagerInfo(
      null,
      payload,
      null,
    )
    saveLMInfo
      .then((resp: any) => {
        this.onClose()
        this.setState({ isTested: 0, saveConfirm: false })
        this.Utilities.showMessage(
          'License Manager settings saved successfully',
          1,
          0,
        )
      })
      .catch((err: any) => {
        const msg = err?.response?.data?.message
          ? err.response.data.message
          : ''
        this.Utilities.showMessage('Error: ' + msg, 0, 0)
      })
  }

  onRequestOk = () => {
    const { lmObject } = this.state
    if (lmObject.lmType === 'Global') {
      this.onConfirmOrSave('confirm')
    } else if (lmObject.lmType === 'Local') {
      if (lmObject.activationStatus) {
        this.onConfirmOrSave('save')
      } else {
        // Activate LLM
        this.activateLLM()
      }
    }
  }

  handleModalCancel = () => {
    this.setState({ saveConfirm: false })
  }

  render() {
    const { isOpen, onRequestClose, lmObject } = this.props
    const { disableSave, isTested } = this.state
    return (
      <>
        <FormatSlidingPage
          isOpen={isOpen}
          onRequestOk={this.onRequestOk}
          onRequestClose={onRequestClose}
          title="License Manager"
          disableSave={disableSave}
          saveText={
            lmObject.lmType === 'Local' && !lmObject.activationStatus
              ? 'Activate'
              : 'Save'
          }
        >
          <LicenseForm
            onChange={this.onLMFormChange}
            isTested={isTested}
            lmObject={lmObject}
            onRef={(ref: any): any => (this.childForm = ref)}
          />
        </FormatSlidingPage>

        <A10Modal
          title="Licensing manager settings"
          visible={this.state.saveConfirm}
          onOk={this.onConfirmOrSave.bind(this, 'save')}
          onCancel={this.handleModalCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleModalCancel}
              className="nobtn"
            >
              Cancel
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.onConfirmOrSave.bind(this, 'save')}
              className="yesbtn"
              style={{ width: '110px' }}
            >
              Continue
            </A10Button>,
          ]}
        >
          <p>
            Changing the license manager may result in issues with already
            activated licenses and associated devices. Are you sure you want to
            continue?
          </p>
        </A10Modal>
      </>
    )
  }
}

export default setupA10Container(SlidingLicenseManager)
