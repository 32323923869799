import React from 'react'
import { axios, getItem, setItem } from '@gui-libraries/framework'
import ReactLoading from 'react-loading'
import { Redirect } from 'react-router-dom'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10Modal,
  A10DropdownMenu,
  A10Col,
  A10Button,
  A10Message,
  A10Input,
  A10Tooltip,
  A10Alert,
  A10Loader,
} from '@gui-libraries/widgets'

import { IDefaultMethods } from 'src/containers/Controller'
import { DashboardService } from 'src/services/DashboardService'
import { OrganizationService } from 'src/services/OrganizationService'
import AppRoot from 'src/settings/appRoot'
import { InfraConfigs } from 'src/constants'
import { InfraStructureStatsService, Utilities } from 'src/services'

import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import { SlidingTenantForms } from 'src/containers/Controller/Organization/Tenants/Forms/SlidingTenantForms'

import './styles/tenants.scss'

export interface ITenantsProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  refreshTenantsFunc(): void
  isObjectExplorerMode?: boolean
  callbackObjectExplorerMode?: any
  openObjectList?: any
}
export interface ITenantsState {
  tenantObj: any
  tenantUpdated: boolean
  searchString: string
  selectedIndex: number
  allTenants: any[]
  statToggle: boolean
  deleteModalState: boolean
  selectedTenant: any
  loadAppServices: boolean
  showSlidingPage: boolean
  editMode: boolean
  isLoading: boolean
  loadingIcon: boolean
}

class Tenants extends A10Container<ITenantsProps, ITenantsState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  DashboardService = new DashboardService()
  OrganizationService = new OrganizationService()
  AppRoot = new AppRoot()
  InfraStructureStatsService = new InfraStructureStatsService()
  InfraConfigs = new InfraConfigs()
  Utilities = new Utilities()
  ref = React.createRef()

  tenantAdminObj: any = {}
  childForm: any = null
  pageLength = 5
  state: ITenantsState = {
    tenantObj: {
      tenantName: '',
      tenantDName: '',
      description: '',
      adminEmail: '',
      userId: '',
      adminId: '',
      isEmailID: true,
      fName: '',
      lName: '',
      tenants: [],
    },
    tenantUpdated: false,
    searchString: '',
    selectedIndex: 0,
    allTenants: [],
    statToggle: false,
    deleteModalState: false,
    selectedTenant: '',
    loadAppServices: false,
    showSlidingPage: false,
    editMode: false,
    isLoading: true,
    loadingIcon: false,
  }
  dataLoaded = false
  statLoaded = false
  isOperatorUser
  // Cancel token used to cancel the http call when component gets unmounted
  CancelToken = axios.CancelToken
  source = this.CancelToken.source()
  tenantsColumns

  constructor(
    props: ITenantsProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: {
        get: { IS_OPERATOR_USER, ADMIN_LEVEL },
      },
    } = context
    this.isOperatorUser = IS_OPERATOR_USER

    this.tenantsColumns = [
      {
        title: <>Name</>,
        dataIndex: 'display-name',
        key: 'display-name',
        className: 'td-truncate',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) =>
          this.Utilities.sortStringKeys(a, b, 'display-name', 'name'),
        render: (text: string, record: any) => {
          return (
            <A10Tooltip
              placement="right"
              title={
                <>
                  {/* <span>Bandwidth: {record.bandwidth}</span>
                  <br /> */}
                  <span>Requests: {record.requests}</span>
                  <br />
                  <span>Connections: {record.connections}</span>
                </>
              }
            >
              <span
                className={
                  record.name.toLowerCase() !== 'pso' ? 'tenantUl' : ''
                }
                onClick={() => this.viewAppServices(record)}
              >
                {record['display-name'] ? record['display-name'] : record.name}
              </span>
            </A10Tooltip>
          )
        },
      },
      {
        title: <># App Services</>,
        dataIndex: 'app-service-count',
        key: 'app-service-count',
        sorter: (a: any, b: any) =>
          this.Utilities.sortNumber(a, b, 'app-service-count'),
        render: (text: string, record: any) => {
          return record['app-service-count'] || 0
        },
      },
      // {
      //   title: <># Logical Partition</>,
      //   dataIndex: 'clusterCount',
      //   key: 'clusterCount',
      //   sorter: (a: any, b: any) =>
      //     this.Utilities.sortObj(a, b, 'logical-partition-list', 'length'),
      //   render: (text: string, record: any) => {
      //     return record['logical-partition-list']
      //       ? record['logical-partition-list'].length
      //       : 0
      //   },
      // },
      {
        title: <>Description</>,
        dataIndex: 'description',
        key: 'description',
        className: 'td-truncate',
        sorter: (a: any, b: any) => {
          this.Utilities.sortString(a, b, 'description')
        },
        render: (text: string, record: any) => {
          return (
            <A10Tooltip placement="right" title={record.description || ''}>
              {record.description || ''}
            </A10Tooltip>
          )
        },
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (key: any) => {
          const clickAction = (component: JSX.Element, index: number) => {
            if (
              component.key === 'edit' &&
              key.name.toLowerCase() !== 'pso' &&
              !this.isOperatorUser
            ) {
              this.editTenant(key)
              this.setState({
                searchString: '',
              })
            } else if (
              component.key === 'delete' &&
              key.name.toLowerCase() !== 'pso' &&
              !this.isOperatorUser
            ) {
              if (key.activeAppsCount > 0) {
                // if (key.activeAppsCount > 0 || (key['logical-partition-list'] && key['logical-partition-list'].length > 0)) {
                return
              } else {
                this.setState({
                  deleteModalState: true,
                  selectedTenant: key,
                  searchString: '',
                })
              }
            } else if (
              component.key === 'appServices' &&
              key.name.toLowerCase() !== 'pso'
            ) {
              this.viewAppServices(key)
            }
          }
          if (key.name.toLowerCase() !== 'pso') {
            return (
              <div className="table-dropdown">
                <A10DropdownMenu
                  menu={
                    ADMIN_LEVEL === 'provider' && !this.isOperatorUser
                      ? [
                          <div key="appServices">View App Services </div>,
                          <div
                            key="edit"
                            className={
                              key.name.toLowerCase() === 'pso' ? 'disabled' : ''
                            }
                          >
                            Edit{' '}
                          </div>,
                          <div
                            key="delete"
                            className={
                              key.name.toLowerCase() === 'pso' ||
                              key.activeAppsCount > 0
                                ? 'disabled'
                                : ''
                            }
                            //|| (key['logical-partition-list'] && key['logical-partition-list'].length > 0)
                          >
                            Delete
                          </div>,
                        ]
                      : [<div key="appServices">View App Services </div>]
                  }
                  title=""
                  // style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={clickAction}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </div>
            )
          } else {
            return <></>
          }
        },
      },
    ]
  }

  async loadTenants() {
    const {
      storage: {
        get: { PROVIDER: provider, ADMIN_LEVEL, ALLTENANTS: tenantList },
        set,
      },
    } = this.context

    if (ADMIN_LEVEL === 'provider') {
      const tenantResponse = this.DashboardService.getTenantsList(
        null,
        null,
        [provider],
        this.source.token,
      )

      tenantResponse
        .then((response: any) => {
          this.dataLoaded = true
          this.statLoaded = false
          let tenantList = response?.data || []
          tenantList = tenantList.filter((tenant: any) => {
            return tenant.type !== 'built-in'
          })
          set.ALLTENANTS(tenantList)
          this.AppRoot.setRootScopeElement('allTenants', tenantList)

          this.props.refreshTenantsFunc()

          if (this.props.isObjectExplorerMode) {
            this.setState(
              {
                allTenants: tenantList,
                isLoading: false,
              },
              this.props.callbackObjectExplorerMode,
            )
          } else {
            this.setState({
              allTenants: tenantList,
              isLoading: false,
            })
          }
        })
        .catch((error: any) => {
          this.dataLoaded = true
          this.setState({
            allTenants: [],
            isLoading: false,
          })
        })
    } else {
      // getting the list of tenants from the session storage
      this.dataLoaded = true
      this.statLoaded = false

      if (this.props.isObjectExplorerMode) {
        this.setState(
          {
            allTenants: tenantList,
            isLoading: false,
          },
          this.props.callbackObjectExplorerMode,
        )
      } else {
        this.setState({
          allTenants: tenantList,
          isLoading: false,
        })
      }
    }
  }

  loadStats = () => {
    if (this.state.allTenants.length > 0) {
      this.statLoaded = true
      this.getAllStats()
    }
  }

  getAllStats = () => {
    const {
      storage: {
        get: { PROVIDER, PROVIDER_ID, ENCODED_SESSION_ID },
      },
    } = this.context

    const xAccount = this.Utilities.getAllXAccountIDs()
    if (xAccount.indexOf('undefined') > -1) {
      const that = this
      setTimeout(function() {
        that.loadTenants()
      }, 2000)
      return
    }
    const header = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      provider: PROVIDER,
      Authorization: ENCODED_SESSION_ID,
      'x-providerids': PROVIDER_ID,
    }

    const to = new Date().getTime()
    const from = to - 300000
    this.getRequestStat(header, from, to)
  }

  getRequestStat(header: any, from: any, to: any) {
    const {
      storage: { set },
    } = this.context

    let payload = this.InfraConfigs.TENANT_REQUESTS
    payload.tenantTotalRequests.rangeby.start = from
    payload.tenantTotalRequests.rangeby.end = to
    payload.tenantTotalRequests['groupby'] = 'account_id'
    payload.tenantTotalRequests['size'] = this.state.allTenants.length

    const tenantRequestsstats = this.InfraStructureStatsService.getTenantResponseStats(
      header,
      payload,
      null,
      this.source.token,
    )
    tenantRequestsstats
      .then((res: any) => {
        let tenantListObj = this.state.allTenants
        const respData = res.data.tenantTotalRequests
        tenantListObj.map((tenantObj: any) => {
          if (respData[tenantObj.uuid]) {
            let r1 = this.Utilities.getLatestRecordFromBucket(
              respData[tenantObj.uuid].pr_sent_sum,
            )
            let r2 = this.Utilities.getLatestRecordFromBucket(
              respData[tenantObj.uuid].pr_drop_sum,
            )
            let requests = this.Utilities.truncateVal(
              (r1 + r2) / 60,
              'quantity',
            )
            requests = requests + '/s'

            tenantObj.requests = requests
          } else {
            tenantObj.requests = '0/s'
          }
        })
        set.ALLTENANTS(tenantListObj)
        this.AppRoot.setRootScopeElement('allTenants', tenantListObj)
        this.setState({
          allTenants: tenantListObj,
          isLoading: false,
        })
        this.getConnStat(header, from, to)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  getConnStat(header: any, from: any, to: any) {
    const {
      storage: { set },
    } = this.context

    let payload = this.InfraConfigs.TENANT_CONNECTIONS
    payload.tenantTotalRequests.rangeby.start = from
    payload.tenantTotalRequests.rangeby.end = to
    payload.tenantTotalRequests['groupby'] = 'account_id'
    payload.tenantTotalRequests['size'] = this.state.allTenants.length

    const tenantRequestsstats = this.InfraStructureStatsService.getTenantConnStats(
      header,
      payload,
      null,
      this.source.token,
    )
    tenantRequestsstats
      .then((res: any) => {
        let payload = this.InfraConfigs.TENANT_REQUESTS_L7
        payload.tenantTotalRequests.rangeby.start = from
        payload.tenantTotalRequests.rangeby.end = to
        payload.tenantTotalRequests['groupby'] = 'account_id'
        payload.tenantTotalRequests['size'] = this.state.allTenants.length

        const tenantRequestsstatsL7 = this.InfraStructureStatsService.getTenantResponseStatsL7(
          header,
          payload,
          null,
          this.source.token,
        )
        tenantRequestsstatsL7.then((res2: any) => {
          let tenantListObj = this.state.allTenants
          const respData = res.data.tenantTotalRequests
          const respDatal7 = res2.data.tenantTotalRequests
          tenantListObj.map((tenantObj: any) => {
            if (respData[tenantObj.uuid] && respDatal7[tenantObj.uuid]) {
              let r1 = this.Utilities.getLatestRecordFromBucket(
                respData[tenantObj.uuid].pc_drop_sum,
              )
              let r2 = this.Utilities.getLatestRecordFromBucket(
                respData[tenantObj.uuid].pc_sent_sum,
              )
              let r3 = this.Utilities.getLatestRecordFromBucket(
                respDatal7[tenantObj.uuid].total_l7_conn_sum,
              )
              let requests = this.Utilities.truncateVal(
                (r1 + r2 + r3) / 60,
                'quantity',
              )
              requests = requests + '/s'

              tenantObj.connections = requests
            } else {
              tenantObj.connections = '0/s'
            }
          })

          set.ALLTENANTS(tenantListObj)
          this.AppRoot.setRootScopeElement('allTenants', tenantListObj)
          this.setState({
            allTenants: tenantListObj,
            statToggle: !this.state.statToggle,
            isLoading: false,
          })
        })
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  reloadTenants = () => {
    this.loadTenants()
  }

  addTenant = () => {
    this.setState({
      tenantObj: {
        tenantName: '',
        tenantDName: '',
        description: '',
        adminEmail: '',
        userId: '',
        adminId: '',
        isEmailID: true,
        fName: '',
        lName: '',
        tenants: [],
      },
      editMode: false,
      showSlidingPage: true,
    })
  }

  addUser = (tenant: any) => {
    console.log(tenant)
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({
      showSlidingPage: isOpen,
    })
  }

  SearchTenants = (e: any) => {
    const searchString = e.target.value
    this.Utilities.search(
      this,
      searchString,
      {
        firstKey: 'display-name',
        secondKey: 'name',
      },
      {
        storageName: 'ALLTENANTS',
        stateName: 'allTenants',
      },
    )
  }

  componentDidMount() {
    if (!this.dataLoaded) {
      this.loadTenants()
    }

    const addNew = getItem('ADD_NEW')
    if (addNew) {
      setItem('ADD_NEW', '')
      const {
        storage: {
          get: { ADMIN_LEVEL },
        },
      } = this.context
      if (ADMIN_LEVEL === 'provider' && !this.isOperatorUser) {
        this.addTenant()
      }
    }
  }

  componentWillUnmount() {
    // Using below method we can cancel http calls which are in progress
    // We are cancelling them because component got unmount
    // the http requests in which source.token has been sent are cancelled by this below method
    // We can use one token(source.token) for a component and which can be used for all http calls within the component
    // this.source.cancel('Operation canceled by the user.')
  }
  editTenant = (tenant: any) => {
    this.setState({
      tenantObj: {
        tenantName: tenant['name'],
        tenantDName: tenant['display-name'] || tenant['name'],
        name: tenant['name'],
        description: tenant['description'],
        adminEmail: '',
        userId: '',
        adminId: '',
        isEmailID: true,
        fName: '',
        lName: '',
        tenants: [],
      },
      editMode: true,
      showSlidingPage: true,
    })
  }

  viewAppServices = (tenant: any) => {
    const {
      storage: { set },
    } = this.context

    if (tenant.name.toLowerCase() !== 'pso') {
      tenant.bandwidth = undefined
      tenant.requests = undefined
      try {
        set.CURRENT_TENANT(tenant)
      } catch {
        let tenantObj = JSON.parse(JSON.stringify(tenant))
        delete tenantObj['app-svc-list']
        delete tenantObj['logical-partition-list']
        set.CURRENT_TENANT(tenantObj)
      }
      this.AppRoot.setRootScopeElement('currentTenant', tenant)

      this.setState({
        loadAppServices: true,
      })
    }
  }

  componentDidUpdate() {
    if (!this.statLoaded) {
      this.loadStats()
    }
  }

  handleOk = () => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    if (this.state.selectedTenant.appCount > 0) {
      A10Message.error('The tenant has active App Services', 10, close)
      return false
    }
    if (this.state.selectedTenant.clusterCount > 0) {
      A10Message.error('The tenant has active Clusters associated', 10, close)
      return false
    }
    const deleteTenant = this.DashboardService.deleteTenant(null, null, [
      PROVIDER,
      this.state.selectedTenant.name,
    ])
    this.setState({
      loadingIcon: true,
    })
    deleteTenant
      .then((resp: any) => {
        A10Message.success('Tenant deleted successfully', 10, close)

        this.setState({
          loadingIcon: false,
          deleteModalState: false,
          selectedTenant: '',
        })

        this.loadTenants()
      })
      .catch((error: any) => {
        const messsge =
          'Unable to delete tenant. ' + error.response.data.message
        A10Message.error(messsge, 10, close)

        this.setState({
          loadingIcon: false,
          deleteModalState: false,
          selectedTenant: '',
        })
        return false
      })
    return true
  }

  handleCancel = () => {
    if (this.state.loadingIcon) {
      return
    }
    this.setState({
      deleteModalState: false,
      selectedTenant: '',
    })
  }

  updateState = (stateName: string, value: any) => {
    // @ts-ignore
    this.setState({ [stateName]: value })
  }

  render() {
    const {
      storage: {
        get: { ADMIN_LEVEL, PROVIDER },
      },
    } = this.context
    const {
      selectedTenant,
      loadingIcon,
      showSlidingPage,
      editMode,
      allTenants,
      tenantObj,
      deleteModalState,
    } = this.state

    return !this.state.loadAppServices ? (
      <>
        <ContentSection>
          <ContentHeader type="flex" align="middle" justify="space-between">
            <A10Col>
              <ContentTitle
                title="Tenants"
                count={this.state.allTenants.length}
              />
            </A10Col>
            <A10Col style={{ display: 'flex' }}>
              <A10Input.Search
                type="text"
                onChange={this.SearchTenants}
                name="searchBox"
                value={this.state.searchString}
                placeholder="Search"
                style={{ marginRight: '6px' }}
              />
              {ADMIN_LEVEL === 'provider' && !this.isOperatorUser && (
                <ActionButton
                  text="Add a Tenant"
                  onClick={this.addTenant}
                  iconProps={{ app: 'global', type: 'add-new' }}
                />
              )}
            </A10Col>
          </ContentHeader>
          <ContentBody ref={this.ref}>
            <A10Table
              columns={this.tenantsColumns}
              dataSource={this.state.allTenants.map(
                (key: any, index: number) => {
                  key.key = index
                  return key
                },
              )}
              loading={
                !this.dataLoaded && {
                  indicator: <A10Loader container={this.ref} />,
                }
              }
              pagination={{ hideOnSinglePage: true, pageSize: 10 }}
            />
          </ContentBody>
        </ContentSection>
        <A10Modal
          title="Delete Tenant"
          visible={deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={loadingIcon}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
              disabled={loadingIcon}
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            Are you sure you want to delete{' '}
            {selectedTenant['display-name']
              ? selectedTenant['display-name']
              : selectedTenant.name}{' '}
            from {PROVIDER}?
          </p>
          {selectedTenant['app-service-count'] &&
          selectedTenant['app-service-count'] > 0 ? (
            <A10Alert
              showIcon={true}
              style={{ margin: '0px 8px 5px 8px' }}
              message={''}
              description={
                'There is/are ' +
                selectedTenant['app-service-count'] +
                ' active App(s) attached to the tenant'
              }
              type="warning"
            />
          ): null}
          {loadingIcon ? (
            <div className="">
              <ReactLoading
                type="bars"
                color="#4a90e2"
                height={40}
                width={40}
              />
            </div>
          ) : null}
        </A10Modal>
        <SlidingTenantForms
          showSlidingPage={showSlidingPage}
          editMode={editMode}
          tenants={allTenants}
          tenantObj={tenantObj}
          loadTenants={this.reloadTenants}
          updateState={this.updateState}
        />
      </>
    ) : (
      <Redirect to="/controller/Services" />
    )
  }
}
export default setupA10Container(Tenants)
