import React from 'react'
import ReactLoading from 'react-loading'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10Button,
  A10Row,
  A10Col,
  A10Icon,
  A10Input,
  A10DropdownMenu,
  A10SlidingPage,
  A10Message,
  A10Tooltip,
  A10Modal,
} from '@gui-libraries/widgets'
import { AutoForm } from '@gui-libraries/apps'

import { Utilities } from 'src/services'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import { RoundNumber } from 'src/components/shared/RoundNumber'
import {
  getGeoList,
  getGeoListCount,
} from 'src/redux/httpServices/SharedObj/GeoList'

const pageSize = 10
const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/pso/logical-partition/pso/`

export interface IGeoListProps extends IA10ContainerDefaultProps {
  data?: IObject[]
  total?: number
  tenant: string
  logicalPartition: string
  scope: string
}
export interface IGeoListState {
  isLoading: boolean
  isOpenForm: boolean
  pageNumber: number
  searchText: string
  selectedGeoList: string
  deleteModalVisible: boolean
  viewMode: boolean
}

class GeoList extends A10Container<IGeoListProps, IGeoListState> {
  delayedSearchText = _.debounce(searchString => {
    this.getData(searchString)
  }, 800)
  Utilities = new Utilities()

  constructor(props: IGeoListProps) {
    super(props)
    this.state = {
      isOpenForm: false,
      isLoading: false,
      pageNumber: 1,
      searchText: '',
      selectedGeoList: '',
      deleteModalVisible: false,
      viewMode: false,
    }
  }

  componentDidMount() {
    const { scope } = this.props
    this.getData()
    this.setState({
      viewMode: !(
        scope !== 'provider' ||
        (storage.get.ADMIN_LEVEL === 'provider' &&
          !storage.get.IS_OPERATOR_USER)
      ),
    })
  }

  getData = (searchText = '') => {
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    this.setState({
      isLoading: true,
    })
    const { pageNumber } = this.state
    const param: IObject = {
      start: (pageNumber - 1) * pageSize,
      count: pageSize,
    }
    if (searchText) {
      param.name = encodeURIComponent(searchText)
    }
    try {
      httpRequest(getGeoListCount(searchText))
      httpRequest(getGeoList(param)).finally(() => {
        this.setState({
          isLoading: false,
        })
      })
    } catch (e) {
      this.setState({
        isLoading: false,
      })
      console.log(e)
    }
  }

  handleAdd = () => {
    this.setState({
      selectedGeoList: '',
      isOpenForm: true,
    })
  }

  handleOk = async () => {
    const { selectedGeoList, searchText, pageNumber } = this.state
    const { total } = this.props
    try {
      await httpClient.delete(
        `${apiPrefix}system/geoloc-list/${selectedGeoList}`,
      )
      if (Math.ceil((total - 1) / pageSize) < pageNumber) {
        this.setState({ pageNumber: pageNumber - 1 }, () => {
          this.getData(searchText)
        })
      } else {
        this.getData(searchText)
      }
      this.setState({ deleteModalVisible: false })
      A10Message.success(
        `Success: Geo list ${selectedGeoList} has been deleted!`,
      )
    } catch (e) {
      // do nothing
      A10Message.error(
        `Error: Failed to delete the Geo list ${selectedGeoList}!`,
      )
    }
  }

  handleDeleteCancel = () => {
    this.setState({
      deleteModalVisible: false,
    })
  }

  handleAddOK = () => {
    const { searchText } = this.state
    this.handleClose()
    this.getData(searchText)
  }

  handleClose = () => {
    this.setState({
      isOpenForm: false,
      selectedGeoList: '',
    })
  }

  onClickAction = (record: IObject, component: JSX.Element) => {
    if (component.key === 'edit' || component.key === 'view') {
      this.setState({
        selectedGeoList: record.name,
        isOpenForm: true,
      })
    } else if (component.key === 'delete') {
      this.setState({ deleteModalVisible: true, selectedGeoList: record.name })
    }
  }

  handleSearch = (e: any) => {
    const searchString = e.target.value
    this.setState(
      {
        searchText: searchString,
        pageNumber: 1,
      },
      () => {
        this.delayedSearchText(searchString)
      },
    )
  }

  handlePageChange = (page: number, pageSize: number) => {
    const { searchText } = this.state
    this.setState({ pageNumber: page }, () => {
      this.getData(searchText)
    })
  }

  render() {
    console.log('render geo list.....')
    const {
      isOpenForm,
      isLoading,
      deleteModalVisible,
      selectedGeoList,
      pageNumber,
      viewMode,
    } = this.state
    const { total = 0, data = [] } = this.props
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        className: 'td-truncate',
        render: (text: any, record: any) => {
          return (
            <A10Tooltip
              title=""
              placement="topLeft"
              overlayClassName="toolTipWidth"
            >
              {record.name || text}
            </A10Tooltip>
          )
        },
      },
      {
        title: <></>,
        dataIndex: '',
        key: '',
        render: (text: string, record: any) => {
          return (
            <div className="text-right">
              <i>
                <A10DropdownMenu
                  menu={
                    !viewMode
                      ? [
                          <div key="edit">Edit </div>,
                          <div key="delete">Delete </div>,
                        ]
                      : [<div key="view">View </div>]
                  }
                  title=""
                  style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={this.onClickAction.bind(this, record)}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </i>
            </div>
          )
        },
      },
    ]

    return (
      <>
        <A10Row type="flex" align="middle" className="action-container">
          <A10Col lg={8}>
            <div className="table-header-inner pull-left">
              <span className="page-header">Geo List</span>
              <RoundNumber number={total} />
            </div>
          </A10Col>
          <A10Col lg={16}>
            <div className="table-header table-header-inner">
              <A10Button className="action-button" onClick={this.handleAdd}>
                <A10Icon app="global" type="add-new" className="action-icon" />
                Add
              </A10Button>
              <span
                className="vertical-split-divider"
                style={{ margin: '18px' }}
              />

              <div className="searchbar-container">
                <A10Input.Search
                  onChange={this.handleSearch}
                  type="text"
                  name="searchBox"
                  placeholder="Search"
                />
              </div>
            </div>
          </A10Col>
        </A10Row>
        <A10Table
          columns={columns}
          dataSource={data}
          size="small"
          loading={
            isLoading
              ? {
                  indicator: (
                    <div>
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    </div>
                  ),
                }
              : false
          }
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            current: pageNumber,
            total,
            onChange: this.handlePageChange,
          }}
        />
        <A10SlidingPage isOpen={!!isOpenForm} onRequestClose={this.handleClose}>
          <AutoForm
            params={
              selectedGeoList
                ? {
                    name: selectedGeoList,
                  }
                : null
            }
            hccEnv={true}
            apiPrefix={apiPrefix}
            viewMode={viewMode}
            interceptor={{
              onCancel: this.handleClose,
              onSubmitSuccess: this.handleAddOK,
            }}
            schemaPath="config/form/system.geoloc-list"
          />
        </A10SlidingPage>
        <A10Modal
          title="Confirmation"
          visible={deleteModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleDeleteCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleDeleteCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>Do you want to delete this Item?</p>
        </A10Modal>
      </>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    data: state.A10Data.getIn(getNS('GEO_LIST_LIST'), []),
    total: state.A10Data.getIn(getNS('GEO_LIST_COUNT'), 0),
  }
}

export default setupA10Container(GeoList, mapStateToProps)
