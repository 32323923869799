import React from 'react'
import ReactLoading from 'react-loading'
import { A10Table } from '@gui-libraries/widgets'
// tslint:disable-next-line:no-var-requires
const styles = require('./style/index.module.less')

const ImageList: React.FC<any> = props => {
  const { data = [], isLoading, columns = [], pageSize = 10 } = props

  return (
    <div>
      <A10Table
        className={styles.hcList}
        columns={columns}
        dataSource={data?.map((item: IObject, index: number) => {
          item.key = index
          return item
        })}
        size="small"
        loading={
          isLoading
            ? {
                indicator: (
                  <div className={styles.loadingIcon}>
                    <ReactLoading
                      type="bars"
                      color="#4a90e2"
                      height={40}
                      width={40}
                    />
                  </div>
                ),
              }
            : false
        }
        pagination={{ hideOnSinglePage: true, pageSize: pageSize }}
      />
    </div>
  )
}
export default ImageList
