import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Collapse } from '@gui-libraries/widgets'
import { Messages } from 'src/locale/en'
import './styles/actiondetails.scss'
import { Utilities } from 'src/services/Utilities'

export interface IActionDetailsObj {
  actionName: string
  emailAlert: any
  subject: string
  actionMessage: string
}

export interface IActionDetailsProps extends IA10ContainerDefaultProps {
  action: IActionDetailsObj
  isBasic: boolean
}
export interface IActionDetailsState {
  action: any
  isBasic: boolean
}

class ActionDetails extends A10Container<
  IActionDetailsProps,
  IActionDetailsState
> {
  static getDerivedStateFromProps(
    nextProps: IActionDetailsProps,
    prevState: IActionDetailsState,
  ) {
    return {
      action: {
        actionName: nextProps.action.def_name,
        emailAlert:
          nextProps.action.definition.email &&
          nextProps.action.definition.email[0].email_ids
            ? nextProps.action.definition.email[0].email_ids.join(', ')
            : '',
        subject: nextProps.action.definition.email
          ? nextProps.action.definition.email[0].extended_subject
          : '',
        actionMessage: nextProps.action.definition.email
          ? nextProps.action.definition.email[0].extended_message
          : '',
        webhooks: nextProps.action.definition.webhook
          ? nextProps.action.definition.webhook
          : [],
      },
      isBasic: nextProps.isBasic,
    }
  }

  Utilities = new Utilities()
  Messages = new Messages()

  constructor(props: any) {
    super(props)
  }

  convertObjToString(headers: any) {
    if (headers === '') {
      return 'None'
    } else {
      return this.Utilities.convertObjToString(headers)
    }
  }

  render() {
    return (
      <div className="row rowDetails">
        {!this.state.isBasic ? (
          <div className="col-md-12">
            <A10Collapse bordered={false} defaultActiveKey={['1']}>
              <A10Collapse.Panel
                header={
                  <>
                    <div
                      className="row section block-sec"
                      id="actionDetailsText"
                    >
                      <div className="block">
                        <span className="title">Action Details</span>
                      </div>
                    </div>
                  </>
                }
                key="1"
                className="no-border"
              >
                <div className="row section">
                  <div className="block">
                    <span className="title">{this.Messages.ACTION_NAME}</span>
                    <span className="monitor-value">
                      {this.state.action.actionName}
                    </span>
                  </div>
                </div>

                <div className="row section no-border">
                  <div className="col-md-1 padding0">
                    <div className="inline-block">
                      <span className="title">
                        {this.Messages.EMAIL_ALERT_TO}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-11 flex-wrap">
                    <div className="inline-block">
                      <span
                        className="monitor-value"
                        title={this.Utilities.returnValue(
                          this.state.action.emailAlert,
                        )}
                      >
                        {this.Utilities.returnValue(
                          this.state.action.emailAlert,
                        )}
                      </span>
                    </div>
                    <div className="inline-block">
                      <span className="title">{this.Messages.SUBJECT}</span>
                      <span
                        className="monitor-value"
                        title={this.Utilities.returnValue(
                          this.state.action.subject,
                        )}
                      >
                        {this.Utilities.returnValue(this.state.action.subject)}
                      </span>
                    </div>
                    <div className="inline-block">
                      <span className="title">{this.Messages.MESSAGE}</span>
                      <span
                        className="monitor-value"
                        title={this.Utilities.returnValue(
                          this.state.action.actionMessage,
                        )}
                      >
                        {this.Utilities.returnValue(
                          this.state.action.actionMessage,
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                {this.state.action.webhooks.length > 0 ? (
                  this.state.action.webhooks.map(
                    (webhook: any, index: number) => {
                      return (
                        <div
                          className="row section no-border"
                          key={'webhook' + index}
                        >
                          <div className="col-md-1 padding0">
                            <div className="inline-block">
                              <span className="title">
                                {this.Messages.WEBHOOK} {index + 1}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-11 flex-wrap">
                            <div className="inline-block">
                              <span className="title">
                                {this.Messages.POST_TO_URI}
                              </span>
                              <span
                                className="monitor-value"
                                title={this.Utilities.returnValue(webhook.uri)}
                              >
                                {this.Utilities.returnValue(webhook.uri)}
                              </span>
                            </div>
                            <div className="inline-block">
                              <span className="title">
                                {this.Messages.HEADERS}
                              </span>
                              <span
                                className="monitor-value"
                                title={this.Utilities.returnValue(
                                  webhook.headers,
                                )}
                              >
                                {this.Utilities.returnValue(
                                  this.convertObjToString(webhook.headers),
                                )}
                              </span>
                            </div>
                            <div className="inline-block">
                              <span className="title">
                                {this.Messages.EXTENDED_BODY}
                              </span>
                              <span
                                className="monitor-value"
                                title={this.Utilities.returnValue(
                                  webhook.extended_body,
                                )}
                              >
                                {this.Utilities.returnValue(
                                  webhook.extended_body,
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    },
                  )
                ) : (
                  <div className="row section no-border" key={'webhook'}>
                    <div className="col-md-1 padding0">
                      <div className="inline-block">
                        <span className="title">{this.Messages.WEBHOOK}</span>
                      </div>
                    </div>
                    <div className="col-md-11 flex-wrap">
                      <div className="inline-block">
                        <span className="title">
                          {this.Messages.POST_TO_URI}
                        </span>
                        <span className="monitor-value">
                          {this.Messages.NONE}
                        </span>
                      </div>
                      <div className="inline-block">
                        <span className="title">{this.Messages.HEADERS}</span>
                        <span className="monitor-value">
                          {this.Messages.NONE}
                        </span>
                      </div>
                      <div className="inline-block">
                        <span className="title">
                          {this.Messages.EXTENDED_BODY}
                        </span>
                        <span className="monitor-value">
                          {this.Messages.NONE}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </A10Collapse.Panel>
            </A10Collapse>
          </div>
        ) : (
          <div className="alert-action-details">
            <div className="col-md-12">
              <div className="row section">
                <div className="block">
                  <span className="title">{this.Messages.EMAIL_ALERT_TO}</span>
                  <span
                    className="monitor-value"
                    title={this.Utilities.returnValue(
                      this.state.action.emailAlert,
                    )}
                  >
                    {this.Utilities.returnValue(this.state.action.emailAlert)}
                  </span>
                </div>
              </div>

              <div className="row section no-border">
                <div className="col-md-12 flex-wrap">
                  <div className="inline-block">
                    <span className="title">{this.Messages.SUBJECT}</span>
                    <span
                      className="monitor-value"
                      title={this.Utilities.returnValue(
                        this.state.action.subject,
                      )}
                    >
                      {this.Utilities.returnValue(this.state.action.subject)}
                    </span>
                  </div>
                  <div className="inline-block">
                    <span className="title">{this.Messages.MESSAGE}</span>
                    <span
                      className="monitor-value"
                      title={this.Utilities.returnValue(
                        this.state.action.actionMessage,
                      )}
                    >
                      {this.Utilities.returnValue(
                        this.state.action.actionMessage,
                      )}
                    </span>
                  </div>
                </div>
              </div>

              {this.state.action.webhooks.length > 0 ? (
                this.state.action.webhooks.map(
                  (webhook: any, index: number) => {
                    return (
                      <div
                        className="row section no-border"
                        key={'webhook' + index}
                      >
                        <div className="col-md-1">
                          <div className="inline-block">
                            <span className="title">
                              {this.Messages.WEBHOOK} {index + 1}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-10 flex-wrap m-l-15">
                          <div className="inline-block w100">
                            <span className="title">
                              {this.Messages.POST_TO_URI}
                            </span>
                            <span
                              className="monitor-value"
                              title={this.Utilities.returnValue(webhook.uri)}
                            >
                              {this.Utilities.returnValue(webhook.uri)}
                            </span>
                          </div>
                          <div className="inline-block">
                            <span className="title">
                              {this.Messages.HEADERS}
                            </span>
                            <span
                              className="monitor-value"
                              title={this.Utilities.returnValue(
                                webhook.headers,
                              )}
                            >
                              {this.Utilities.returnValue(
                                this.convertObjToString(webhook.headers),
                              )}
                            </span>
                          </div>
                          <div className="inline-block">
                            <span className="title">
                              {this.Messages.EXTENDED_BODY}
                            </span>
                            <span
                              className="monitor-value"
                              title={this.Utilities.returnValue(
                                webhook.extended_body,
                              )}
                            >
                              {this.Utilities.returnValue(
                                webhook.extended_body,
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  },
                )
              ) : (
                <div className="row section no-border" key={'webhook'}>
                  <div className="col-md-1">
                    <div className="inline-block">
                      <span className="title">{this.Messages.WEBHOOK}</span>
                    </div>
                  </div>
                  <div className="col-md-10 flex-wrap m-l-15">
                    <div className="inline-block w100">
                      <span className="title">{this.Messages.POST_TO_URI}</span>
                      <span className="monitor-value">
                        {this.Messages.NONE}
                      </span>
                    </div>
                    <div className="inline-block">
                      <span className="title">{this.Messages.HEADERS}</span>
                      <span className="monitor-value">
                        {this.Messages.NONE}
                      </span>
                    </div>
                    <div className="inline-block">
                      <span className="title">
                        {this.Messages.EXTENDED_BODY}
                      </span>
                      <span className="monitor-value">
                        {this.Messages.NONE}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}
export default setupA10Container(ActionDetails)
