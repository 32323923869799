import React, { useEffect, useState } from 'react'
import { get, set } from 'lodash'
import {
  A10Modal,
  A10Tabs,
  A10Switch,
  A10Loader,
  A10Tooltip,
} from '@gui-libraries/widgets'

import { removeQuotations } from '../../../containers/Controller/Organization/Eula/Eula'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'

import styles from './styles/index.module.less'

export interface IEulaAfterLoginProps {
  onClose: any
  wasShown: boolean
  userShow?: boolean
}

const EulaAfterLogin = (props: IEulaAfterLoginProps) => {
  const { onClose, wasShown, userShow } = props

  const providerUser = storage.get.PROVIDER
  const userId = storage.get.USER_ID
  const EULA_BASE_URL = `/hpcapi/v3/provider/${providerUser}/eulainfo`
  const USER_PREF_URL = `/hpcapi/v3/provider/${providerUser}/user/${userId}/preferences`

  const httpClientParams = { absoluteBasePath: true }

  const [visible, setVisible] = useState(true) // need to decide how to show
  const [, setCurrentEula] = useState('')
  const [eulas, setEulas] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [userApproved, setUserApproval] = useState(true)
  const [switchDisabled, setSwitchDisabled] = useState(false)

  const getEulaList = async () =>
    await httpClient.get(EULA_BASE_URL, httpClientParams as any)
  const getUserPrefs = async () =>
    await httpClient.get(USER_PREF_URL, httpClientParams as any)
  const postUserPrefs = async (prefs: IObject) =>
    await httpClient.post(USER_PREF_URL, prefs)
  const getUserApproval = async () => {
    const {
      data: { preferences: prefs },
    } = await getUserPrefs()
    // check if user enabled
    const didUserApprove =
      typeof prefs === 'string'
        ? get(JSON.parse(prefs), "EULA['show-welcome-again']", true)
        : true
    setUserApproval(didUserApprove)
  }
  const getEulas = async () => {
    if (providerUser) {
      const {
        data: { 'eulainfo-list': eulaList },
      } = await getEulaList()

      const afterLoginEulas = eulaList.filter((eula: IObject) => {
        // check if provider enabled
        const providerApproved =
          eula.location === 'After Login' && eula.state === 'ACTIVE'

        return providerApproved
      })
      if (afterLoginEulas.length > 0) {
        setEulas(afterLoginEulas)
        setCurrentEula(afterLoginEulas[0]['eula-key'])
      } else {
        onClose(userShow)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    getUserApproval().then(getEulas)
  }, [])

  const onSwitchChange = async (checked: boolean) => {
    // set in user preferences whether to show next time
    // add check to show page next time when getting api, needs to be done in component did mount too
    setSwitchDisabled(true)
    const {
      data: { preferences = '{}' },
    } = await getUserPrefs()
    if (preferences && typeof preferences === 'string') {
      // parse to see if prefs exist
      const parsedPrefs = JSON.parse(preferences)

      set(parsedPrefs, "EULA['show-welcome-again']", checked)
      postUserPrefs({ preferences: parsedPrefs })
      setUserApproval(checked)
    }
    setSwitchDisabled(false)
  }

  const renderContent = () => {
    return (
      <section className={styles.content}>
        <div className={styles.welcome}>Welcome Notes</div>
        <A10Tabs className={styles.tabContent}>
          {eulas.map((eula: IObject, index: number) => {
            return (
              <A10Tabs.TabPane tab={eula.title} key={eula['eula-key']}>
                <div className={styles.document}>
                  {removeQuotations(eula.document)}
                </div>
              </A10Tabs.TabPane>
            )
          })}
        </A10Tabs>
        <span className={styles.nextTime}>
          <A10Tooltip title="You may access Welcome Notes in Help Center after toggling off here.">
            <A10Switch
              onChange={onSwitchChange}
              disabled={switchDisabled}
              checked={userApproved}
            />
            <span className={styles.nextTimeText}>
              Hide Welcome Notes Next Time
            </span>
          </A10Tooltip>
        </span>
      </section>
    )
  }
  const onCancel = () => {
    setVisible(false)
    onClose()
  }

  const renderModal = (content: any) => (
    <A10Modal
      visible={visible}
      footer={[null, null]}
      destroyOnClose={true}
      onCancel={onCancel}
      wrapClassName={styles.eulaAfterLogin}
      zIndex={999}
    >
      {content}
    </A10Modal>
  )
  const loader = (
    <div className={styles.loader}>
      <A10Loader iconOnly />
    </div>
  )
  const modalContent = isLoading ? loader : renderContent()
  const noContent = isLoading ? (
    loader
  ) : (
    <div className={styles.noNotes}>No Welcome Notes</div>
  )
  const eulasSet = eulas.length > 0

  // only show 'no content' modal if user accesses through Help & Support center
  // do not show on user login by itself
  if (userShow && wasShown) {
    return !eulasSet ? renderModal(noContent) : renderModal(modalContent)
  }
  return userApproved && !userShow && !wasShown && eulasSet
    ? renderModal(modalContent)
    : null
}

export default EulaAfterLogin
