import React from 'react'
import {
  A10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Divider } from '@gui-libraries/widgets'
import InfoTooltip from 'src/components/ADC/WarningTooltip/InfoTooltip'
import httpClient from 'src/libraries/httpClient'
const moment = require('moment')

export interface IWorkflowTooltipProps extends IA10ContainerDefaultProps {
  objUUID: string
  workflowType?: string
  textDisplayIntact?: boolean
  title?: string
  componentDisplay?: any
  size: number
  viewWorkflow: (id: string, parent: IObject) => {}
  parent: IObject
}

export interface IWorkflowTooltipState {
  workflowList: any[]
}

class WorkflowTooltip extends A10Container<
  IWorkflowTooltipProps,
  IWorkflowTooltipState
> {
  constructor(props: IWorkflowTooltipProps) {
    super(props)

    this.state = {
      workflowList: [],
    }
  }

  getData = async () => {
    const { objUUID, workflowType, size } = this.props
    let url = `datastore/object-workflow-map?obj_id=${objUUID}&start=1&page_size=${size}&sort_by=created_on&sort_order=desc`
    if (workflowType) {
      url += `&wf_type=${workflowType}`
    }
    try {
      const { data: res } = await httpClient.get(url, {
        absoluteBasePath: true,
      })
      const workflowList = []
      for (const item of res.resultSet) {
        workflowList.push(Object.values(item)[0])
      }
      this.setState({ workflowList })
    } catch (err) {
      console.log(err)
    }
  }

  renderWorkflow = (obj: IObject = {}, index: number) => {
    const { workflowList } = this.state
    return (
      <div>
        {this.props.workflowType === 'DEPLOY' ? (
          <div style={{ 'font-weight': 'bold' }}>Last Deployment:</div>
        ) : (
          <div>{`Task: ${obj.workflow_type} `}</div>
        )}
        <div>{`Status: ${obj.status} `}</div>
        <div>Started on: {moment(obj.workflow_created_on).format('lll')}</div>
        <a
          href="javascript:;"
          onClick={this.props.viewWorkflow.bind(
            this,
            obj.workflow_id,
            this.props.parent,
          )}
        >
          More...
        </a>
        {workflowList.length !== index + 1 ? <A10Divider dashed /> : null}
      </div>
    )
  }

  render() {
    const { title, componentDisplay, textDisplayIntact } = this.props
    const infoTooltipProps = {
      textDisplay: title,
      textDisplayIntact: textDisplayIntact,
      componentDisplay,
      placement: 'right',
      renderText: this.renderWorkflow,
      objList: this.state.workflowList,
      onVisibleChange: (visible: boolean) => {
        if (visible) {
          this.getData()
        }
      },
    }
    return <InfoTooltip {...infoTooltipProps} />
  }
}

export default WorkflowTooltip
