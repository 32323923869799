import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Form,
  A10Input,
  A10Select,
  A10Tag,
  A10Button,
  A10Table,
  A10Col,
  A10Radio,
  A10Switch,
  A10Tooltip,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { Messages } from 'src/locale/en/Messages'
import { InfrastructureService } from 'src/services/InfrastructureService/InfrastructureService'
import { IDefaultMethods } from 'src/containers/Controller'
import { DropdownConstants } from 'src/constants/DropdownConstants/DropdownConstants'
import { Configs } from 'src/constants/Configs'
import { DashboardService, Utilities } from 'src/services/index'
import storage from 'src/libraries/storage'
import { HelpInfo } from 'src/components/shared/HelpInfo'

import './styles/kubernetesdevicecreateform.scss'

const RadioGroup = A10Radio.Group
export interface IKubernetesDeviceCreateFormProps
  extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  device?: any
  vmDevices: any
  form: any
  formData?: any
  createFormData: any
  onRef?: any
  handleChange?: (data: any) => void
}
export interface IKubernetesDeviceCreateFormState {
  device: any
  vmDevice: any
  nodeList: any[]
  namespaceList: any[]
  imageList: any[]
  hugePagesList: any[]
  deploymentList: any[]
  nicCount: number
  portList: any[]
  port: string
  portInputVisible: boolean
  networkList: any[]
}

class KubernetesDeviceCreateForm extends A10Container<
  IKubernetesDeviceCreateFormProps,
  IKubernetesDeviceCreateFormState
> {
  [x: string]: any
  Messages = new Messages()
  InfrastructureService = new InfrastructureService()
  DropdownConstants = new DropdownConstants()
  Configs = new Configs()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  lists: any[] = []

  mainNetworks = [
    {
      name: 'Management/eth0',
    },
  ]

  PCI_PASSTHROUGH = true
  MULTI_NIC_SUPPORT = true

  constructor(props: IKubernetesDeviceCreateFormProps) {
    super(props)
    const vmDevice: any = props.createFormData
    const device: any = props.formData
    this.state = {
      device,
      vmDevice,
      nodeList: [],
      namespaceList: [],
      imageList: [],
      hugePagesList: [],
      deploymentList: [],
      nicCount: 1,
      portList: ['22', '80', '443'],
      port: '',
      portInputVisible: false,
      networkList: [],
    }
    this.loadK8sNodeLists(JSON.parse(device.credential))
    this.loadK8sNamespaceLists()
    this.loadK8sNetworkLists()
  }

  getChangedValue = (e: any) => {
    let changedValue = ''
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        changedValue = e.target.checked
      } else {
        changedValue = e.target.value
      }
    } else {
      changedValue = e
    }
    return changedValue
  }

  handlePortClose = (removedTag: any) => {
    const vmDeviceObj = this.state.vmDevice
    const tags = this.state.portList.filter(tag => tag !== removedTag)
    vmDeviceObj.vmNetworks[0]['ports'] = tags
    this.setState({
      portList: tags,
      vmDevice: vmDeviceObj,
    })
  }

  showPortInput = () => {
    this.setState({ portInputVisible: true })
  }

  handlePortInputChange = (e: any) => {
    this.setState({ port: e.target.value })
  }

  handlePortInputConfirm = () => {
    const vmDeviceObj = this.state.vmDevice
    const inputValue = this.state.port
    let tags = this.state.portList
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue]
    }
    vmDeviceObj.vmNetworks[0]['ports'] = tags
    this.setState({
      vmDevice: vmDeviceObj,
      portList: tags,
      portInputVisible: false,
      port: '',
    })
  }

  saveInputRef = (input: any) => (this.input = input)

  handleChange = (stateName: string, e: any, index?: number) => {
    if (!this.Utilities.validateField(e)) {
      return
    }
    const changedValue = this.getChangedValue(e)
    const vmDeviceObj = this.state.vmDevice
    let {
      imageList,
      networkList,
      portList,
      deploymentList,
      namespaceList,
      port,
      portInputVisible,
    } = this.state

    if (index !== undefined) {
      vmDeviceObj.vmNetworks[index][stateName] = changedValue
    } else {
      vmDeviceObj[stateName] = changedValue
    }

    if (stateName === 'node' && this.state.vmDevice.node !== '') {
      vmDeviceObj.namspace = ''
      vmDeviceObj.imageName = ''
      vmDeviceObj.image = ''
      vmDeviceObj.cpu = ''
      vmDeviceObj.memory = ''
      vmDeviceObj.hugePagesType = ''
      vmDeviceObj.hugePagesSize = ''
      vmDeviceObj.sriovDriver = ''
      imageList = []
      portList = Array.from(
        new Set([
          '22',
          '80',
          '443',
          this.state.device['securePort'].toString(),
        ]),
      )
      port = ''
      portInputVisible = false
      this.mainNetworks = [this.mainNetworks[0]]
      vmDeviceObj.vmNetworks = [vmDeviceObj.vmNetworks[0]]
      vmDeviceObj.vmNetworks[0]['ports'] = portList
      this.setState({ nicCount: 1 })

      this.props.form.setFieldsValue({
        [`cpu`]: undefined,
        [`memory`]: undefined,
        [`namespace`]: undefined,
        [`imageName`]: '',
        [`hugePagesType`]: undefined,
        [`hugePagesSize`]: '',
        sriovDriver: '',
      })
      this.loadK8sHugePagesLists(JSON.parse(vmDeviceObj['node']))
      this.loadK8sNamespaceLists()
    }

    if (stateName === 'autoCreate') {
      vmDeviceObj.namspace = ''
      this.props.form.setFieldsValue({ [`namespace`]: undefined })
    }

    if (
      (stateName === 'namespace' && this.state.vmDevice.namespace !== '') ||
      this.state.vmDevice.autoCreate
    ) {
      deploymentList = []
      port = ''
      portInputVisible = false
      if (!this.state.vmDevice.autoCreate) {
        this.loadK8sDeploymentLists()
      }
    }
    if (stateName === 'interfaceDeviceType') {
      vmDeviceObj.vmNetworks[index].network = ''
      vmDeviceObj.vmNetworks[index].ipAddr = ''
      vmDeviceObj.vmNetworks[index].netMask = ''
      this.props.form.setFieldsValue({
        [`network[${index}]`]: undefined,
        [`ipAddr[${index}]`]: undefined,
        [`netMask[${index}]`]: undefined,
      })
    }
    this.props.handleChange(vmDeviceObj)
    // @ts-ignore
    this.setState({
      vmDevice: vmDeviceObj,
      imageList,
      namespaceList,
      deploymentList,
      networkList,
      portList,
      port,
      portInputVisible,
    })
  }

  addEth = () => {
    const nicName =
      'Ethernet' + this.state.nicCount + '/eth' + this.state.nicCount
    this.mainNetworks.push({ name: nicName })
    const vmDeviceObj = this.state.vmDevice
    vmDeviceObj.vmNetworks.push({
      name: 'eth' + this.state.nicCount,
      interfaceDeviceType: 'sriov',
      network: '',
      ipAddr: '',
      netMask: '',
    })
    this.props.handleChange(vmDeviceObj)
    // @ts-ignore
    this.setState({ vmDevice: vmDeviceObj, nicCount: this.state.nicCount + 1 })
  }

  removeEth = () => {
    this.mainNetworks.pop()
    const vmDeviceObj = this.state.vmDevice
    vmDeviceObj.vmNetworks.pop()
    this.props.handleChange(vmDeviceObj)
    // @ts-ignore
    this.setState({ vmDevice: vmDeviceObj, nicCount: this.state.nicCount - 1 })
  }

  loadK8sNodeLists = (credential: any) => {
    const k8sNodesResponse = this.InfrastructureService.getK8sNodes(
      null,
      null,
      [credential.uuid],
    )
    k8sNodesResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              nodeList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get Kubernetes Node details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  searchImage = () => {
    const credential = JSON.parse(this.state.device.credential)
    const node = JSON.parse(this.state.vmDevice.node)
    let k8sImageResponse

    k8sImageResponse = this.InfrastructureService.getK8sImages(null, null, [
      credential.uuid,
      node.name,
      this.state.vmDevice.imageName,
    ])

    k8sImageResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              imageList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get docker image details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadK8sNamespaceLists = () => {
    const credential = JSON.parse(this.state.device.credential)
    const k8sNamespaceResponse = this.InfrastructureService.getK8sNamespaces(
      null,
      null,
      [credential.uuid],
    )
    k8sNamespaceResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              namespaceList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get kubernetes namespace details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadK8sHugePagesLists = (node: any) => {
    let list = []
    for (let hg in node.allocatable) {
      if (hg.toLowerCase().includes('hugepages')) {
        if (parseInt(node.allocatable[hg]) > 0) {
          list.push({ name: hg, size: node.allocatable[hg] })
        }
      }
    }
    if (list && list.length > 0) {
      this.setState({
        hugePagesList: list,
      })
    }
  }

  loadK8sNetworkLists = () => {
    const credential = JSON.parse(this.state.device.credential)
    const k8sNetworkResponse = this.InfrastructureService.getK8sNetAttachmentDefs(
      null,
      null,
      [credential.uuid],
    )
    k8sNetworkResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              networkList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get kubernetes network details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
          showMessage(message, 0, 0, msg)
        }
      })
  }

  loadK8sDeploymentLists = async () => {
    const credential = JSON.parse(this.state.device.credential)
    const namespace = this.state.vmDevice.namespace
    const k8sDeploymentResponse = this.InfrastructureService.getK8sDeployments(
      null,
      null,
      [credential.uuid, JSON.parse(namespace).name],
    )
    k8sDeploymentResponse
      .then((response: any) => {
        let list = []
        let dep_list: any[] = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            list.forEach(function(dp: { deployment_name: any }) {
              dep_list.push(dp.deployment_name)
            })
            this.setState({
              deploymentList: dep_list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get kubernetes deployment list'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  validateDuplicateVMName = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const index = this.props.vmDevices.findIndex((key: any) => {
      return key.name === value
    })
    const userRegx = new RegExp(
      /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
    )

    if (!value || '' === value) {
      rule.message = this.Messages.VM_NAME_REQ
      isValid = false
    } else if (!userRegx.test(value)) {
      rule.message = this.Messages.K8S_INVALID_VM_NAME
      isValid = false
    } else if (index > -1) {
      rule.message = this.Messages.VM_NAME_DUP
      isValid = false
    } else if (
      this.state.deploymentList.length > 0 &&
      this.state.deploymentList.includes(value)
    ) {
      rule.message =
        'Deployment with same name already exists. Try different name'
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateDuplicateDeployment = () => {
    let isValid: boolean = true
    const { showMessage } = this.Utilities
    if (this.state.deploymentList.length > 0) {
      if (this.state.deploymentList.includes(this.state.vmDevice.vmName)) {
        showMessage('Duplicate Deployment name', 0, 0)
        isValid = false
      }
    }
    return isValid
  }

  validateResourceSize = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    let msg =
      rule.field === 'cpu'
        ? ' number of CPUs'
        : rule.field === 'memory'
        ? ' memory size'
        : ' number of HugePages'
    let minSize = rule.field === 'cpu' ? 1 : rule.field === 'memory' ? 4 : 0
    let recommendedSize =
      rule.field === 'cpu' ? 2 : rule.field === 'memory' ? 8 : 0
    const userRegx = new RegExp(/^([0-9]){1,4}$/)
    if (!value || '' === value) {
      rule.message = 'Please enter' + msg
      isValid = false
    } else if (!userRegx.test(value)) {
      rule.message = this.Messages.K8S_INVALID_RESOURCE_SIZE
      isValid = false
    } else if (parseInt(value) < minSize) {
      rule.message =
        'Minimum : ' +
        minSize.toString() +
        ', Recommended : ' +
        recommendedSize.toString()
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { vmDevice } = this.state
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <div className="margin-v-10">
          <A10Panel
            className="panelBodyPadding"
            title={
              <>
                <A10IconTextGroup
                  text={
                    <>
                      <span className="borderLeftData">
                        Kubernetes Environment
                      </span>
                    </>
                  }
                  icon={
                    <A10Icon
                      className="destinationIcon"
                      app="gi-firewall"
                      type="destination"
                      style={{ fontSize: '24px' }}
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                    Container Name
                  </span>
                  <span>
                    <HelpInfo
                      placement="topLeft"
                      title="Container Name"
                      helpKey="HELP_INFRA_VIRTUAL_KUBERNETES_CONTAINER_NAME"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('vmName', {
                rules: [{ validator: this.validateDuplicateVMName.bind(this) }],
              })(
                <A10Input
                  type="text"
                  placeholder={'Enter Container name'}
                  onChange={(e: any) => this.handleChange('vmName', e)}
                />,
              )}
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                    Node
                  </span>
                  <span>
                    <HelpInfo
                      placement="topLeft"
                      title="Node"
                      helpKey="HELP_INFRA_VIRTUAL_KUBERNETES_NODE"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('node', {
                rules: [
                  {
                    required: true,
                    message: 'Please select node',
                  },
                ],
                initialValue: vmDevice.node || undefined,
              })(
                <A10Select
                  placeholder={'Select a node'}
                  onChange={(e: any) => this.handleChange('node', e)}
                >
                  {this.state.nodeList.map((obj: any, i: number) => {
                    return (
                      <A10Select.Option key={i} value={JSON.stringify(obj)}>
                        {obj.name}
                      </A10Select.Option>
                    )
                  })}
                </A10Select>,
              )}
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                    Namespace
                  </span>
                  <span>
                    <HelpInfo
                      placement="topLeft"
                      title="Namespace"
                      helpKey="HELP_INFRA_VIRTUAL_KUBERNETES_NAMESPACE"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('namespace', {
                rules: [
                  {
                    required: !vmDevice.autoCreate,
                    message: 'Please select Namespace',
                    validator: !this.state.vmDevice.autoCreate
                      ? this.validateDuplicateDeployment()
                      : null,
                  },
                ],
                initialValue: vmDevice.namespace || undefined,
              })(
                <A10Select
                  placeholder={'Select Namespace'}
                  onChange={(e: any) => this.handleChange('namespace', e)}
                  disabled={vmDevice.autoCreate}
                  className="k8s-namespace"
                >
                  {this.state.namespaceList.map((obj: any, i: number) => {
                    return (
                      <A10Select.Option key={i} value={JSON.stringify(obj)}>
                        {obj.name}
                      </A10Select.Option>
                    )
                  })}
                </A10Select>,
              )}
              <>
                <A10Switch
                  defaultChecked={false}
                  onChange={(e: any) => this.handleChange('autoCreate', e)}
                />
                <span> auto-create</span>
              </>
            </A10Form.Item>
          </A10Panel>

          {!!vmDevice.node ? (
            <>
              <A10Panel
                className="panelBodyPadding"
                title={
                  <>
                    <A10IconTextGroup
                      text={
                        <>
                          <span className="borderLeftData">Image</span>
                        </>
                      }
                      icon={
                        <A10Icon
                          app="harmony-controller"
                          type="network"
                          className="kubernetes-icons"
                        />
                      }
                    />
                  </>
                }
              >
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                        Image Name
                      </span>
                      <span>
                        <HelpInfo
                          placement="topLeft"
                          title="Image Name"
                          helpKey="HELP_INFRA_VIRTUAL_KUBERNETES_IMAGE"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('imageName', {
                    rules: [
                      {
                        message: 'Please select image',
                      },
                    ],
                    initialValue: vmDevice.imageName,
                  })(
                    <div className="row">
                      <div className="col-md-8">
                        <A10Input
                          type="text"
                          placeholder={'Name of the machine image'}
                          onChange={(e: any) =>
                            this.handleChange('imageName', e)
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        <A10Button
                          className="action-button"
                          onClick={() => {
                            this.searchImage()
                          }}
                        >
                          Search
                        </A10Button>
                      </div>
                      {!!this.state.imageList &&
                      this.state.imageList.length === 0 ? (
                        <span className="col-md-12">{'No images found.'}</span>
                      ) : !!this.state.imageList &&
                        this.state.imageList.length > 0 &&
                        this.state.imageList.length <= 50 ? (
                        <span className="col-md-12">
                          {this.state.imageList.length +
                            ' image(s) found. Please select one.'}
                        </span>
                      ) : !!this.state.imageList &&
                        this.state.imageList.length > 50 ? (
                        <span className="col-md-12">
                          {
                            ' More than 50 images found. Please refine your search filter'
                          }
                        </span>
                      ) : null}
                    </div>,
                  )}
                </A10Form.Item>
                {!!this.state.imageList && this.state.imageList.length > 0 ? (
                  <A10Form.Item {...formItemLayout} label="&nbsp;">
                    {getFieldDecorator('image')(
                      <div className="kubernetes-image-div">
                        <RadioGroup
                          onChange={(e: any) => this.handleChange('image', e)}
                          value={vmDevice.image}
                        >
                          {this.state.imageList.map((k8sImage: any) => {
                            return (
                              <A10Radio
                                key={k8sImage.name}
                                value={JSON.stringify(k8sImage)}
                                className="kubernetes-image-list"
                              >
                                {k8sImage.name}
                              </A10Radio>
                            )
                          })}
                        </RadioGroup>
                      </div>,
                    )}
                  </A10Form.Item>
                ) : null}
              </A10Panel>

              <A10Panel
                className="panelBodyPadding"
                title={
                  <>
                    <A10IconTextGroup
                      text={
                        <>
                          <span className="borderLeftData">Resource Spec</span>
                        </>
                      }
                      icon={
                        <A10Icon
                          // @ts-ignore
                          app="goe"
                          type="resource-usage"
                          className="kubernetes-icons"
                        />
                      }
                    />
                  </>
                }
              >
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                        CPU
                      </span>
                      <span>
                        <HelpInfo
                          placement="topLeft"
                          title="CPU"
                          helpKey="HELP_INFRA_VIRTUAL_KUBERNETES_CPU"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('cpu', {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter number of CPUs',
                        validateTrigger: 'onBlur',
                        validator: this.validateResourceSize.bind(this),
                      },
                    ],
                    initialValue: vmDevice.cpu,
                  })(
                    <div className="row">
                      <div className="col-md-6">
                        <A10Input
                          type="text"
                          placeholder={'No. of CPUs'}
                          onChange={(e: any) => this.handleChange('cpu', e)}
                        />
                      </div>
                      {Object.keys(
                        JSON.parse(this.state.vmDevice.node).available,
                      ).length > 0 ? (
                        <>
                          /
                          <div className="col-md-5 kubernetes-resource-capacity">
                            {parseInt(
                              JSON.parse(this.state.vmDevice.node).available[
                                'cpu'
                              ],
                              10,
                            )}{' '}
                            <A10Tooltip
                              title="Number of CPUs available"
                              placement="topLeft"
                            >
                              <A10Icon app="global" type="information" />
                            </A10Tooltip>
                          </div>
                        </>
                      ) : null}
                    </div>,
                  )}
                </A10Form.Item>

                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                        Memory
                      </span>
                      <span>
                        <HelpInfo
                          placement="topLeft"
                          title="Memory"
                          helpKey="HELP_INFRA_VIRTUAL_KUBERNETES_MEMORY"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('memory', {
                    rules: [
                      {
                        required: true,
                        message: 'Memory Size',
                        validateTrigger: 'onBlur',
                        validator: this.validateResourceSize.bind(this),
                      },
                    ],
                    initialValue: vmDevice.memory,
                  })(
                    <div className="row">
                      <div className="col-md-6">
                        <A10Input
                          type="text"
                          placeholder={'Memory Size'}
                          onChange={(e: any) => this.handleChange('memory', e)}
                        />
                      </div>
                      {Object.keys(
                        JSON.parse(this.state.vmDevice.node).available,
                      ).length > 0 ? (
                        <>
                          /
                          <div className="col-md-5 kubernetes-resource-capacity">
                            {parseInt(
                              JSON.parse(this.state.vmDevice.node).available[
                                'memory'
                              ],
                              10,
                            )}{' '}
                            GB{' '}
                            <A10Tooltip
                              title="Amount of memory available"
                              placement="topLeft"
                            >
                              <A10Icon app="global" type="information" />
                            </A10Tooltip>
                          </div>
                        </>
                      ) : null}
                    </div>,
                  )}
                </A10Form.Item>
              </A10Panel>
            </>
          ) : null}

          {!!vmDevice.node &&
          (!!vmDevice.namespace || !!vmDevice.autoCreate) ? (
            <A10Panel
              className="panelBodyPadding"
              title={
                <>
                  <A10IconTextGroup
                    text={
                      <>
                        <span className="borderLeftData">Deployment</span>
                      </>
                    }
                    icon={
                      <A10Icon
                        app="harmony-controller"
                        type="provider"
                        className="kubernetes-icons"
                      />
                    }
                  />
                </>
              }
            >
              <div className="VMWareTemplate">{<>Network Mapping</>}</div>

              <A10Table
                dataSource={this.mainNetworks}
                pagination={false}
                scroll={{ x: '100%' }}
              >
                <A10Col
                  title="Name"
                  dataIndex="name"
                  key="name"
                  width="25%"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortString(a, b, 'name')
                  }
                  placement="topLeft"
                  className="interface-name-div"
                  render={(text: string, record: any, index: number) => {
                    return (
                      <div>
                        {index > 0 && index === this.state.nicCount - 1 ? (
                          <A10IconTextGroup
                            text={' ' + text}
                            icon={
                              <A10Icon
                                style={{ fontSize: 24 }}
                                app="global"
                                type="delete-another"
                                onClick={this.removeEth.bind(this)}
                              />
                            }
                          />
                        ) : (
                          text
                        )}
                      </div>
                    )
                  }}
                />
                <A10Col
                  title="Configuration"
                  key="configuration"
                  width="75%"
                  render={(text: string, record: any, index: number) => {
                    if (record.name === 'Management/eth0') {
                      return (
                        <>
                          <A10Form.Item
                            {...formItemLayout}
                            label={
                              <>
                                <span
                                  style={{
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                  }}
                                >
                                  Service Ports
                                </span>
                                <span>
                                  <HelpInfo
                                    placement="topLeft"
                                    title="NodePort service TargetPorts"
                                    helpKey="HELP_INFRA_VIRTUAL_KUBERNETES_SERVICE_PORTS"
                                  />
                                </span>
                              </>
                            }
                            className="mgmt-svc-conf"
                          >
                            {getFieldDecorator('ports', {
                              rules: [{}],
                            })(
                              <div
                                style={{
                                  display: 'grid',
                                  gridTemplateColumns: '8fr 2fr',
                                  gridGap: '20px',
                                }}
                              >
                                <div className="col-md-8 k8s-ports">
                                  {this.state.portList.map((tag, index) => {
                                    const isLongTag = tag.length > 20
                                    const tagElem = (
                                      <A10Tag
                                        className="k8s-port"
                                        key={tag}
                                        closable={
                                          index >
                                          (this.state.device['securePort'] ==
                                          '443'
                                            ? 2
                                            : 3)
                                        }
                                        afterClose={() =>
                                          this.handlePortClose(tag)
                                        }
                                      >
                                        {isLongTag
                                          ? `${tag.slice(0, 20)}...`
                                          : tag}
                                      </A10Tag>
                                    )
                                    return isLongTag ? (
                                      <A10Tooltip title={tag} key={tag}>
                                        {tagElem}
                                      </A10Tooltip>
                                    ) : (
                                      tagElem
                                    )
                                  })}
                                </div>

                                <div>
                                  {this.state.portInputVisible && (
                                    <A10Input
                                      ref={this.saveInputRef}
                                      type="text"
                                      fontSize="24px"
                                      style={{ width: 90 }}
                                      size="small"
                                      value={this.state.port}
                                      placeholder={'Port number'}
                                      autoFocus
                                      onChange={this.handlePortInputChange}
                                      onBlur={this.handlePortInputConfirm}
                                      onPressEnter={this.handlePortInputConfirm}
                                    />
                                  )}
                                  {!this.state.portInputVisible && (
                                    <A10Tag
                                      className="k8s-input-port"
                                      onClick={this.showPortInput}
                                    >
                                      <span>
                                        <A10Icon
                                          type="plus"
                                          className="k8s-port"
                                        />{' '}
                                        {' New Port '}
                                      </span>
                                    </A10Tag>
                                  )}
                                </div>
                              </div>,
                            )}
                          </A10Form.Item>
                        </>
                      )
                    } else {
                      return (
                        <>
                          <A10Form.Item
                            className="no-margin"
                            {...formItemLayout}
                            label={
                              <>
                                <span
                                  style={{
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                  }}
                                >
                                  Interface Device Type
                                </span>
                              </>
                            }
                          >
                            {getFieldDecorator(
                              `interfaceDeviceType[${index}]`,
                              {
                                rules: [
                                  {
                                    message: 'Please select Interface Type',
                                  },
                                ],
                                initialValue:
                                  vmDevice.vmNetworks[index]
                                    .interfaceDeviceType,
                              },
                            )(
                              <A10Radio.Group
                                size="small"
                                onChange={(e: any) =>
                                  this.handleChange(
                                    'interfaceDeviceType',
                                    e,
                                    index,
                                  )
                                }
                                buttonStyle="solid"
                              >
                                <A10Radio.Button
                                  value="sriov"
                                  style={{ width: '90px' }}
                                >
                                  SRIOV
                                </A10Radio.Button>
                                <A10Radio.Button
                                  value="pcipt"
                                  style={{ width: '135px' }}
                                >
                                  PCI Passthrough
                                </A10Radio.Button>
                                {/*<A10Radio.Button
                                  value="vhost"
                                  style={{ width: '90px' }}
                                >
                                  vHost user
                                </A10Radio.Button>
                                <A10Radio.Button value="vEth">
                                  vEth
                              </A10Radio.Button>*/}
                              </A10Radio.Group>,
                            )}
                          </A10Form.Item>

                          {// if interface-type is pci-passthrough
                          vmDevice.vmNetworks[index].interfaceDeviceType ==
                          'pcipt' ? (
                            <>
                              <A10Form.Item
                                className="no-margin"
                                {...formItemLayout}
                                label={
                                  <>
                                    <span
                                      style={{
                                        fontWeight: 'normal',
                                        fontSize: '14px',
                                      }}
                                    >
                                      {' '}
                                      PCI Address{' '}
                                    </span>
                                  </>
                                }
                              >
                                {getFieldDecorator(`network[${index}]`, {
                                  rules: [
                                    {
                                      message: 'Please enter PCI Address',
                                    },
                                  ],
                                })(
                                  <A10Input
                                    type="text"
                                    placeholder={'PCI Address'}
                                    size="small"
                                    onChange={(e: any) =>
                                      this.handleChange('network', e, index)
                                    }
                                  />,
                                )}
                              </A10Form.Item>
                            </>
                          ) : // else-if interface-type sriov
                          vmDevice.vmNetworks[index].interfaceDeviceType ==
                            'sriov' ? (
                            <>
                              <A10Form.Item
                                className="no-margin"
                                {...formItemLayout}
                                label={
                                  <>
                                    <span
                                      style={{
                                        fontWeight: 'normal',
                                        fontSize: '14px',
                                      }}
                                    >
                                      {' '}
                                      Network{' '}
                                    </span>
                                    <span>
                                      <HelpInfo
                                        placement="topLeft"
                                        title="Network"
                                        helpKey="HELP_INFRA_VIRTUAL_KUBERNETES_INTERFACE_NETWORK"
                                      />
                                    </span>
                                  </>
                                }
                              >
                                {getFieldDecorator(`network[${index}]`, {
                                  rules: [
                                    {
                                      message: 'Select network',
                                    },
                                  ],
                                })(
                                  <A10Select
                                    placeholder={'Please select network'}
                                    onChange={(e: any) =>
                                      this.handleChange('network', e, index)
                                    }
                                    size="small"
                                  >
                                    {this.state.networkList.map(
                                      (obj: any, i: number) => {
                                        if (obj['type'] == 'sriov') {
                                          return (
                                            <A10Select.Option
                                              key={i}
                                              value={JSON.stringify(obj)}
                                            >
                                              <>
                                                <span>{obj['name']}</span>
                                                {Object.keys(
                                                  JSON.parse(
                                                    this.state.vmDevice.node,
                                                  ).available,
                                                ).length > 0 &&
                                                obj['annotations'][
                                                  'k8s.v1.cni.cncf.io/resourceName'
                                                ] in
                                                  JSON.parse(
                                                    this.state.vmDevice.node,
                                                  ).available ? (
                                                  <span>
                                                    {' '}
                                                    (Available:
                                                    {
                                                      JSON.parse(
                                                        this.state.vmDevice
                                                          .node,
                                                      ).available[
                                                        obj['annotations'][
                                                          'k8s.v1.cni.cncf.io/resourceName'
                                                        ]
                                                      ]
                                                    }
                                                    )
                                                  </span>
                                                ) : null}
                                              </>
                                            </A10Select.Option>
                                          )
                                        }
                                      },
                                    )}
                                  </A10Select>,
                                )}
                              </A10Form.Item>
                            </>
                          ) : vmDevice.vmNetworks[index].interfaceDeviceType ==
                            'vhost' ? (
                            <>
                              <A10Form.Item
                                className="no-margin"
                                {...formItemLayout}
                                label={
                                  <>
                                    <span
                                      style={{
                                        fontWeight: 'normal',
                                        fontSize: '14px',
                                      }}
                                    >
                                      {' '}
                                      Network{' '}
                                    </span>
                                    <span>
                                      <HelpInfo
                                        placement="topLeft"
                                        title="Network"
                                        helpKey="HELP_INFRA_VIRTUAL_KUBERNETES_INTERFACE_NETWORK"
                                      />
                                    </span>
                                  </>
                                }
                              >
                                {getFieldDecorator(`network[${index}]`, {
                                  rules: [
                                    {
                                      message: 'Select network',
                                    },
                                  ],
                                })(
                                  <A10Select
                                    placeholder={'Please select vHost network'}
                                    onChange={(e: any) =>
                                      this.handleChange('network', e, index)
                                    }
                                    size="small"
                                  >
                                    {this.state.networkList.map(
                                      (obj: any, i: number) => {
                                        if (obj['type'] === 'userspace') {
                                          return (
                                            <A10Select.Option
                                              key={i}
                                              value={JSON.stringify(obj)}
                                            >
                                              <>
                                                <span>{obj['name']}</span>
                                              </>
                                            </A10Select.Option>
                                          )
                                        }
                                      },
                                    )}
                                  </A10Select>,
                                )}
                              </A10Form.Item>
                            </>
                          ) : null}

                          <A10Form.Item
                            className="no-margin"
                            {...formItemLayout}
                            label={
                              <>
                                <span
                                  style={{
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                  }}
                                >
                                  {' '}
                                  IP Address{' '}
                                </span>
                              </>
                            }
                          >
                            {getFieldDecorator(`ipAddr[${index}]`, {
                              validateTrigger: 'onBlur',
                              rules: [
                                {
                                  validator: this.Utilities.validateVMDeviceIPs.bind(
                                    this,
                                    'not-mandatory',
                                  ),
                                },
                              ],
                            })(
                              <A10Input
                                type="text"
                                placeholder={'IP Address'}
                                size="small"
                                onChange={(e: any) =>
                                  this.handleChange('ipAddr', e, index)
                                }
                              />,
                            )}
                          </A10Form.Item>
                          <A10Form.Item
                            className="no-margin"
                            {...formItemLayout}
                            label={
                              <>
                                <span
                                  style={{
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                  }}
                                >
                                  {' '}
                                  Netmask{' '}
                                </span>
                              </>
                            }
                          >
                            {getFieldDecorator(`netMask[${index}]`, {
                              validateTrigger: 'onBlur',
                              rules: [
                                {
                                  validator: this.Utilities.validateVMDeviceIPs.bind(
                                    this,
                                    'not-mandatory',
                                  ),
                                },
                              ],
                            })(
                              <A10Input
                                type="text"
                                placeholder={'255.255.255.0'}
                                defaultValue={'255.255.255.0'}
                                size="small"
                                onChange={(e: any) =>
                                  this.handleChange('netMask', e, index)
                                }
                              />,
                            )}
                          </A10Form.Item>
                        </> // end of one network-mapping section-div
                      ) // end of return brace
                    } // End of else management/eth0
                  }} // end of render
                />
                // end of A10Col
              </A10Table>

              {this.MULTI_NIC_SUPPORT ? (
                <div className="row margin-v-10">
                  <div className="col-sm-12">
                    <A10IconTextGroup
                      text={
                        <div className="addEth2Title">
                          Ethernet{this.state.nicCount}/eth{this.state.nicCount}
                        </div>
                      }
                      icon={
                        <A10Icon
                          style={{ fontSize: 24 }}
                          app="global"
                          type="add-another"
                          onClick={this.addEth.bind(this)}
                        />
                      }
                    />
                  </div>
                </div>
              ) : null}
            </A10Panel>
          ) : null}

          {this.state.vmDevice.vmNetworks.length > 1 ? (
            <A10Panel
              className="panelBodyPadding"
              title={
                <>
                  <A10IconTextGroup
                    text={
                      <>
                        <span className="borderLeftData">
                          Interface Device Options
                        </span>
                      </>
                    }
                    icon={
                      <A10Icon
                        // @ts-ignore
                        app="goe"
                        type="resource-usage"
                        className="kubernetes-icons"
                      />
                    }
                  />
                </>
              }
            >
              <>
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                        HugePages
                      </span>
                      <span>
                        <HelpInfo
                          placement="topLeft"
                          title="HugePages"
                          helpKey="HELP_INFRA_VIRTUAL_KUBERNETES_HUGEPAGES"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('hugepages', {
                    rules: [
                      {
                        required: false,
                        message: 'Please enter hugepages',
                      },
                    ],
                  })(
                    <div className="row">
                      <div className="col-md-6">
                        <A10Select
                          placeholder={'Select HugePages Type'}
                          onChange={(e: any) =>
                            this.handleChange('hugePagesType', e)
                          }
                        >
                          {this.state.hugePagesList.map(
                            (obj: any, i: number) => {
                              return (
                                <A10Select.Option key={i} value={obj.name}>
                                  {obj.name}
                                </A10Select.Option>
                              )
                            },
                          )}
                        </A10Select>
                      </div>
                      {getFieldDecorator('hugepages', {
                        rules: [
                          {
                            required: false,
                            message: 'Hugepages size',
                            validateTrigger: 'onBlur',
                            validator: this.validateResourceSize.bind(this),
                          },
                        ],
                      })(
                        <div className="col-md-3 kubernetes-hugepages-size">
                          <A10Input
                            type="text"
                            placeholder={'Size'}
                            onChange={(e: any) =>
                              this.handleChange('hugePagesSize', e)
                            }
                          />
                        </div>,
                      )}
                      {Object.keys(
                        JSON.parse(this.state.vmDevice.node).available,
                      ).length > 0 && !!this.state.vmDevice.hugePagesType ? (
                        <>
                          <div className="col-md-3 kubernetes-resource-capacity">
                            /{'  '}
                            {parseInt(
                              JSON.parse(this.state.vmDevice.node).available[
                                this.state.vmDevice.hugePagesType
                              ],
                              10,
                            )}{' '}
                            GB{' '}
                            <A10Tooltip
                              title="Amount of HugePages available"
                              placement="top"
                            >
                              <A10Icon app="global" type="information" />
                            </A10Tooltip>
                          </div>
                        </>
                      ) : null}
                    </div>,
                  )}
                </A10Form.Item>

                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                        Driver
                      </span>
                      <span>
                        <HelpInfo
                          placement="topLeft"
                          title="SRIOV Driver"
                          helpKey="HELP_INFRA_VIRTUAL_KUBERNETES_SRIOV_DRIVER"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('sriovDriver', {
                    rules: [
                      {
                        required: false,
                        message: 'Please enter SRIOV Driver',
                      },
                    ],
                    initialValue: vmDevice.sriovDriver,
                  })(
                    <div className="row">
                      <div className="col-md-6">
                        <A10Select
                          value={vmDevice.sriovDriver}
                          onChange={(e: any) =>
                            this.handleChange('sriovDriver', e)
                          }
                          dropdownClassName="k8s-sriov-driver-dropdown"
                        >
                          <A10Select.Option key="" value=""></A10Select.Option>
                          <A10Select.Option key="bind-vfio">
                            bind-vfio
                          </A10Select.Option>
                          <A10Select.Option key="bind-igb-uio">
                            bind-igb-uio
                          </A10Select.Option>
                        </A10Select>
                      </div>
                    </div>,
                  )}
                </A10Form.Item>
              </>
            </A10Panel>
          ) : null}
        </div>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(KubernetesDeviceCreateForm))
