import React from 'react'
import { A10Modal, A10Checkbox, A10Icon } from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import {
  PreCheck,
  SetupOrganization,
  SetupInfrastructure,
  ApplicationServices,
  Navigation,
  SuperNavBar,
  SuperAdminOverview,
} from './components'

import './styles/index.less'

export interface IGettingStartedProps {
  visible: boolean
  onCancel: () => any
  afterClose: any
}
export interface IGettingStartedState {
  nextTimeChecked: boolean
  current: number[]
}
export default class GettingStarted extends React.Component<
  IGettingStartedProps,
  IGettingStartedState
> {
  static defaultProps = {
    visible: false,
  }
  state = {
    nextTimeChecked: storage.get.PERSIST_GETTING_STARTED,
    current: [1],
  }

  gettingStartedIconStyle = {
    width: 13.4,
    height: 22.3,
    marginLeft: 13,
    marginTop: 13,
    position: 'absolute',
  }

  componentDidMount() {
    const current = 1
    const pages = document.querySelectorAll(
      '.gettingStarted-content-images > div',
    )

    pages.forEach(this.togglePageClassname(current - 1))
  }
  onSwitchChange = (nextTimeChecked: boolean) => {
    this.setState({
      nextTimeChecked,
    })
  }

  changePage = (direction: string) => {
    const { current } = this.state
    const newCurrent = direction === 'left' ? current[0] - 1 : current[0] + 1
    this.setState(
      {
        current: [newCurrent],
      },
      () => {
        const pages = document.querySelectorAll(
          '.gettingStarted-content-images > div',
        )

        pages.forEach(this.togglePageClassname(newCurrent - 1))
      },
    )
  }
  isSuperAdmin = () => {
    const SUPER_ADMIN_MODE = storage.get.SUPER_ADMIN_MODE
    return !SUPER_ADMIN_MODE
  }
  renderContent = () => {
    const { current } = this.state
    const isSuperAdmin = this.isSuperAdmin()
    const showLeft = current[0] > 1
    const showRight = current[0] < (isSuperAdmin ? 5 : 3)
    return (
      <div id="gettingStarted-content">
        {showLeft ? (
          <div
            className="showPageLeft"
            onClick={this.changePage.bind(this, 'left')}
          />
        ) : null}
        {showLeft ? (
          <A10Icon
            app="global"
            type="GettingStarted"
            className="showGettingStartedIcon"
            style={this.gettingStartedIconStyle as React.CSSProperties}
          />
        ) : null}
        <div className="gettingStarted-content-images">
          {isSuperAdmin ? (
            <>
              <PreCheck isSuperAdmin={isSuperAdmin} />
              <SetupOrganization />
              <SetupInfrastructure />
              <ApplicationServices />
              <Navigation />
            </>
          ) : (
            <>
              <PreCheck isSuperAdmin={isSuperAdmin} />
              <SuperAdminOverview />
              <SuperNavBar />
            </>
          )}
        </div>
        {showRight ? (
          <div
            className="showPageRight"
            onClick={this.changePage.bind(this, 'right')}
          />
        ) : null}
      </div>
    )
  }
  onPageChange = (checkboxValueArray: any) => {
    if (checkboxValueArray.length < 1) {
      // if clicked same item, do nothing
      return
    }
    const { current } = this.state
    const prevValue = current[0]
    const prevIndex = checkboxValueArray.indexOf(prevValue)
    checkboxValueArray.splice(prevIndex, 1)

    const pages = document.querySelectorAll(
      '.gettingStarted-content-images > div',
    )

    pages.forEach(this.togglePageClassname(checkboxValueArray[0] - 1))

    this.setState({ current: checkboxValueArray })
  }

  togglePageClassname = (cmpValue: number) => (page: any, index: number) =>
    index === cmpValue
      ? page.classList.add('gettingStarted-content-active')
      : page.classList.remove('gettingStarted-content-active')

  getOptions = () => {
    const isSuperAdmin = this.isSuperAdmin()

    const options = [] as any[]
    let totalOptions = isSuperAdmin ? 5 : 3 // three or five slides
    while (totalOptions > 0) {
      options.unshift({ label: '', value: totalOptions-- })
    }
    return options
  }
  renderPagination = () => {
    const { current } = this.state

    const options = this.getOptions()
    return (
      <A10Checkbox.Group
        onChange={this.onPageChange}
        options={options}
        value={current}
        className="gettingStarted-checkboxes"
      />
    )
  }
  // renderNextTime = () => {
  //   const { nextTimeChecked } = this.state
  //   return (
  //     <div className="showNextTime">
  //       <A10Tooltip
  //         className="showNextTime-text"
  //         title="Check, if you don’t want to see Get Started next time. You can always launch Get Started from Help & Support Center."
  //       >
  //         <span>Show this next time</span>
  //       </A10Tooltip>
  //       <A10Switch checked={nextTimeChecked} onChange={this.onSwitchChange} />
  //     </div>
  //   )
  // }
  cancel = () => {
    const { onCancel } = this.props
    this.setState(
      {
        current: [1],
      },
      () => {
        // reset default classes
        const pages = document.querySelectorAll(
          '.gettingStarted-content-images > div',
        )

        pages.forEach(this.togglePageClassname(this.state.current[0] - 1))
        onCancel()
      }, // reset to one
    )
  }
  render() {
    const { visible, afterClose } = this.props
    return (
      <A10Modal
        wrapClassName="gettingStarted"
        visible={visible}
        footer={[null, null]}
        onCancel={this.cancel}
        destroyOnClose={true}
        afterClose={afterClose}
        zIndex={1000}
      >
        {this.renderContent()}
        {this.renderPagination()}
        {/* {this.renderNextTime()} */}
      </A10Modal>
    )
  }
}
