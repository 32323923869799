import React from 'react'
import { A10Modal, A10Button } from '@gui-libraries/widgets'

const UpgradeModal: React.FC<IObject> = props => {
  const { visible, onClose, content } = props

  const onUpgradeClick = () => {
    onClose()
    window.open(
      'https://www.a10networks.com/contact-us/contact-sales',
      '_blank',
    )
  }
  return (
    <A10Modal
      title="Upgrade Your Plan"
      visible={visible}
      onCancel={onClose}
      footer={[
        <A10Button
          key="upgrade"
          type="primary"
          onClick={() => onUpgradeClick()}
        >
          Contact Sales
        </A10Button>,
        <A10Button key="cancel" type="default" onClick={onClose}>
          Cancel
        </A10Button>,
      ]}
    >
     {content}
    </A10Modal>
  )
}

export default UpgradeModal
