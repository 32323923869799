import { AppRoot } from '../settings/appRoot'
import { AuthenticationService } from 'src/services/index'
import storage from 'src/libraries/storage'

export class LoggedInUser {
  AppRoot = new AppRoot()
  AuthenticationService = new AuthenticationService()
  state: any

  constructor() {
    this.state = {}
  }

  logout = () => {
    const {
      get: { PROVIDER: provider, USER_SESSION_ID },
      clear,
    } = storage

    if (provider) {
      const logoutRequest = this.AuthenticationService.deleteSession(
        null,
        null,
        [USER_SESSION_ID],
      )
      logoutRequest
        .then((resp: any) => {
          // console.log('logout')
        })
        .catch((err: any) => {
          // console.log(err)
        })
    }
    setTimeout(() => {
      clear()
      window.location.href = `/login/${provider ? provider : 'root'}`
    }, 1000)
  }
  showProfile = () => {
    // const sliderProps = {
    //   formElement: 'TenantAdd',
    //   formHeader:
    //    get.PROVIDER +
    //     ' > Tenants > Add new Tenant',
    //   onTenantUpdate: 'this.receiveUpdate',
    //   tenants: [],
    // }
    // const setSlider = this.AppRoot.getRootScopeElement('setSlider')
    // console.log(setSlider)
    // sliderProps.tenants.push({name : 'test'})
    // return sliderProps.tenants;
    // setSlider(sliderProps);
    return 'hello'
  }
}

export default LoggedInUser
