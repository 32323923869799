import React from 'react'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'

import { ProviderForm } from './index'
import {
  Utilities,
  DashboardService,
  AuthenticationService,
} from 'src/services'
import { Messages } from 'src/locale/en/Messages'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'

export interface IProviderFormActionsProps extends IA10ContainerDefaultProps {
  onRef?: any
  show: boolean
  title: string
  provider: any
  editMode: boolean
  providerNames: any[]
  description: string
  onActionCompleted: any
  onClose: () => void
  logCapacityObj: any
}

export interface IProviderFormActionsState {
  show: boolean
  title: string
  provider: any
  providerNames: any[]
  isLoading: boolean
  description: string
  activationLink: string
  logProcessingObj: any
}

class ProviderFormActions extends A10Container<
  IProviderFormActionsProps,
  IProviderFormActionsState
> {
  static contextType = A10Context
  static getDerivedStateFromProps(
    nextProps: IProviderFormActionsProps,
    prevState: IProviderFormActionsState,
  ) {
    if (nextProps.show !== prevState.show) {
      let dedicatedLogCapacity = '',
        logProcessingType = 'shared'
      if (
        nextProps.provider.uuid &&
        (nextProps.logCapacityObj[nextProps.provider.uuid] ||
          nextProps.logCapacityObj[nextProps.provider.uuid] == 0)
      ) {
        dedicatedLogCapacity = nextProps.logCapacityObj[nextProps.provider.uuid]
        logProcessingType = 'dedicated'
      }
      if (!nextProps.editMode) {
        logProcessingType = 'dedicated'
      }
      return {
        activationLink: '',
        show: nextProps.show,
        title: nextProps.title,
        provider: nextProps.provider,
        description: nextProps.description,
        providerNames: nextProps.providerNames,
        isLoading: prevState.isLoading,
        logProcessingObj: {
          logProcessingType,
          dedicatedLogCapacity,
        },
      }
    }
    return null
  }
  context: React.ContextType<typeof A10Context>
  Messages = new Messages()
  Utilities = new Utilities()
  dashboardService = new DashboardService()
  authenticationService = new AuthenticationService()

  childForm: any = null

  constructor(props: IProviderFormActionsProps) {
    super(props)

    this.state = {
      provider: {},
      isLoading: false,
      providerNames: [],
      activationLink: '',
      show: this.props.show,
      title: this.props.title,
      description: this.props.description,
      logProcessingObj: {
        logProcessingType: '',
        dedicatedLogCapacity: '',
      },
    }
  }

  componentDidUpdate(
    prevProps: IProviderFormActionsProps,
    prevState: IProviderFormActionsState,
  ) {
    if (prevState.show !== this.props.show) {
      if (this.props.editMode) {
        // this.getActivationLink(this.state.provider.name)
      }
    }
  }

  closeProviderForm = () => {
    this.setState({
      // show: false,
      isLoading: false,
    })
    this.props.onClose()
  }

  handleFormChange = (data: any) => {
    this.setState({
      provider: data,
    })
  }

  handleLogCapacityChange = (data: any) => {
    this.setState({ logProcessingObj: data })
  }

  handleFormValidation = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  loader = (): JSX.Element => {
    return <ReactLoading type="bars" color="#ffffff" height={30} width={70} />
  }

  saveProvider = () => {
    this.setState({ isLoading: true })

    if (this.props.editMode) {
      this.updateProvider()
      return
    }
    this.createProvider()
  }

  updateProviderLogCapacity = (provider: any) => {
    const payload = {
      providerId: provider.uuid,
      lograte: this.state.logProcessingObj.dedicatedLogCapacity,
    }
    this.dashboardService
      .updateProviderLogRate(null, payload, null)
      .then(response => {
        // this.Utilities.showMessage('SUCCESS_PROVIDER_UPDATE', 1, 1)
        // this.closeProviderForm()
        // this.props.onActionCompleted()
      })
      .catch((error: any) => {
        // this.setState({ isLoading: false })
        // this.Utilities.showMessage('PROVIDER_UPDATE_FAILED', 0, 1, error.message)
      })
  }

  deleteProviderLogCapacity = (provider: any) => {
    this.dashboardService
      .deleteProviderLogRate(null, null, [provider.uuid])
      .then(response => {
        // this.Utilities.showMessage('SUCCESS_PROVIDER_UPDATE', 1, 1)
        // this.closeProviderForm()
        // this.props.onActionCompleted()
      })
      .catch((error: any) => {
        // this.setState({ isLoading: false })
        // this.Utilities.showMessage('PROVIDER_UPDATE_FAILED', 0, 1, error.message)
      })
  }

  updateProvider() {
    const payload = { ...this.state.provider },
      logProcessingObj = this.state.logProcessingObj
    delete payload['user-list']

    if (logProcessingObj.logProcessingType === 'dedicated') {
      this.updateProviderLogCapacity(payload)
    } else {
      if (
        this.props.logCapacityObj[payload.uuid] != undefined &&
        this.props.logCapacityObj[payload.uuid] >= 0
      ) {
        this.deleteProviderLogCapacity(payload)
      }
    }

    this.dashboardService
      .updateProvider(null, { provider: payload }, [payload.name])
      .then(response => {
        this.Utilities.showMessage('SUCCESS_PROVIDER_UPDATE', 1, 1)
        this.closeProviderForm()
        this.props.onActionCompleted()
      })
      .catch((error: any) => {
        this.setState({ isLoading: false })
        this.Utilities.showMessage(
          'PROVIDER_UPDATE_FAILED',
          0,
          1,
          error.message,
        )
      })
  }

  createProvider() {
    const payload = { ...this.state.provider }
    this.dashboardService
      .addProviders(null, { provider: payload }, null)
      .then(response => {
        // create provider dedicated log rate if choosen
        if (
          this.state.logProcessingObj.logProcessingType === 'dedicated' &&
          response?.data?.provider
        ) {
          this.updateProviderLogCapacity(response.data.provider)
        }
        // creates provider admin
        this.createProviderAdmin()
      })
      .catch((error: any) => {
        this.setState({ isLoading: false })
        this.Utilities.showMessage('PROVIDER_ADD_FAILED', 0, 1, error?.response?.data?.message)
      })
  }

  createProviderAdmin() {
    const {
      storage: {
        get: { PROVIDER: currentProvider = 'root' },
      },
    } = this.context

    const provider = { ...this.state.provider }
    const headers: any = {
      np: provider.name,
      provider: currentProvider,
    }
    this.dashboardService
      .createProviderAdmin(headers, null, null)
      .then(response => {
        this.Utilities.showMessage('SUCCESS_PROVIDER_ADD', 1, 1)
        this.closeProviderForm()
        this.props.onActionCompleted()
      })
      .catch((error: any) => {
        this.setState({ isLoading: false })
        // this.Utilities.showMessage('PROVIDER_ADD_FAILED', 0, 1, error.message)
      })
  }

  getActivationLink = (providerName: string) => {
    const headers = {
      provider: 'root',
      np: providerName,
    }

    this.dashboardService
      .getAdminActivationMail(headers, null, 'provider-admin')
      .then((response: any) => {
        let activationLink: string
        if (response.data.link === 'Not an unverified user') {
          activationLink = ''
        } else {
          activationLink = response.data.link
        }
        this.setState({ activationLink })
      })
  }

  providerExists = (providerName: string) => {
    const providers: string[] = [...this.state.providerNames]
    if (providers.indexOf(providerName) !== -1) {
      return true
    }
    return false
  }

  render() {
    return (
      <FormatSlidingPage
        isOpen={this.state.show}
        onRequestOk={this.handleFormValidation}
        onRequestClose={this.closeProviderForm}
        title={this.state.title}
        description={this.state.description}
        disableSave={this.state.isLoading}
      >
        <ProviderForm
          editMode={this.props.editMode}
          provider={this.state.provider}
          onSubmitForm={this.saveProvider}
          handleChange={this.handleFormChange}
          handleLogCapacityChange={this.handleLogCapacityChange}
          activationLink={this.state.activationLink}
          onRef={(ref: any): any => (this.childForm = ref)}
          logCapacityObj={this.props.logCapacityObj}
          logProcessingObj={this.state.logProcessingObj}
        />
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(ProviderFormActions)
