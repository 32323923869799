import React from 'react'
import ReactLoading from 'react-loading'
import { _, A10Component } from '@gui-libraries/framework'
import {
  A10Button,
  A10DropdownMenu,
  A10Message,
  A10Modal,
  A10Table,
} from '@gui-libraries/widgets'

import { StatusIcon } from 'src/components/shared/StatusIcon'
import { SortButton } from 'src/components/shared/SortButton'
import { WorkflowDetail, ITaskInfor } from '../WorkflowDetail'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import * as analysisUtils from 'src/containers/Controller/Dashboard/utils'

import './styles/workflowlist.scss'

export interface IWorkflowSet {
  [key: string]: IObject
}

export interface IWorkflowInfor {
  status: string
  workflowId: string
  mappedObj: IObject
  workflowType: string
  createTime: number
  duration: number
  message: string
  tasks?: ITaskInfor[]
  startTime?: number
  endTime?: number
  statusMessage?: string
  inputObj?: IObject
}

export interface IWorkflowListProps {
  pageStart: number
  pageSize: number
  total: number
  pageData: IWorkflowSet[]
  isLoading: boolean
  sortKey: string
  sortOrder: string
  trackingMapStatus?: string[]
  trackingWFStatus?: string[]
  onRefreshPage?: () => void
  onPageChange?: (start: number, size?: number) => void
  onOrderChange?: (columnKey: string, order?: string) => void
}

export interface IWorkflowListState {
  enabledSorter: string
  sorterOrder: string
  targetWorkflowID: string
  showRestartModal: boolean
  showTerminateModal: boolean
  isTracking: boolean
  trackingMapList: {
    [mapKey: string]: IObject
  }
  trackingWFList: {
    [workflowId: string]: IObject
  }
  trackingTime: number
}

const TextWrapper: React.FC = (props: IObject) => {
  const { text, title, width, maxWidth } = props

  let renderText: string = ''
  if (_.isString(text) || _.isNumber(text)) {
    renderText = `${text}`
  }
  let renderTitle: string = ''
  if (_.isString(title) || _.isNumber(title)) {
    renderTitle = `${title}`
  } else if (_.isString(text) || _.isNumber(text)) {
    renderTitle = `${text}`
  }
  const styleObj: IObject = {}
  if (_.isFinite(width)) {
    styleObj.width = width
  }
  if (_.isFinite(maxWidth)) {
    styleObj.maxWidth = maxWidth
  }
  return (
    <div className="text-wrapper" style={styleObj}>
      {props.prefix}
      <span className="text-inner" title={renderTitle}>
        {renderText}
      </span>
    </div>
  )
}

class WorkflowList extends A10Component<
  IWorkflowListProps,
  IWorkflowListState
> {
  static defaultProps: IWorkflowListProps = {
    pageStart: 0,
    pageSize: 10,
    total: 0,
    pageData: [],
    isLoading: false,
    sortKey: 'createTime',
    sortOrder: 'desc',
    trackingMapStatus: ['NEW', 'INPROGRESS'],
    trackingWFStatus: ['RUNNING'],
  }
  defaultColumnConfigs: IObject[]
  timerId: number
  refresh: () => void
  constructor(props: IWorkflowListProps) {
    super(props)

    this.state = {
      enabledSorter: props.sortKey,
      sorterOrder: props.sortOrder,
      targetWorkflowID: null,
      showRestartModal: false,
      showTerminateModal: false,
      isTracking: false,
      trackingMapList: {},
      trackingWFList: {},
      trackingTime: Date.now(),
    }

    this.initialize()

    this.refresh = _.debounce(() => {
      this.setState({})
    }, 500)
  }

  componentDidMount() {
    const { pageData, trackingMapStatus, trackingWFStatus } = this.props
    this.updateTrackList(pageData, trackingMapStatus, trackingWFStatus)

    if (window.setInterval) {
      this.timerId = window.setInterval(this.trackLatestInfor, 1000 * 3)
    }
  }

  componentWillUnmount() {
    if (window.clearInterval) {
      window.clearInterval(this.timerId)
    }
  }

  shouldComponentUpdate(
    nextProps: IWorkflowListProps,
    nextState: IWorkflowListState,
  ) {
    const { sortKey: nextSortKey, sortOrder: nextSortOrder } = nextProps
    const {
      enabledSorter: nextSorter,
      sorterOrder: nextOrder,
      isTracking: nextIsTracking,
      trackingTime: nextTrackingTime,
    } = nextState
    const { enabledSorter, sorterOrder, isTracking, trackingTime } = this.state
    if (nextSorter !== enabledSorter || nextOrder !== sorterOrder) {
      this.initialize(nextSorter, nextOrder)
    }
    if (nextSortKey !== enabledSorter || nextSortOrder !== sorterOrder) {
      this.setState({ enabledSorter: nextSortKey, sorterOrder: nextSortOrder })
      return false
    } else if (!isTracking && nextIsTracking) {
      return false
    } else if (
      isTracking &&
      !nextIsTracking &&
      trackingTime === nextTrackingTime
    ) {
      return false
    }
    return true
  }

  componentDidUpdate(
    prevProps: IWorkflowListProps,
    prevState: IWorkflowListState,
  ) {
    const { pageData: prevData } = prevProps
    const { pageData, trackingMapStatus, trackingWFStatus } = this.props
    if (prevData !== pageData) {
      this.updateTrackList(pageData, trackingMapStatus, trackingWFStatus)
    }
  }

  updateTrackList = (
    source: IWorkflowSet[],
    trackingMapStatus: string[],
    trackingWFStatus: string[],
  ) => {
    const trackingMapList = {}
    const trackingWFList = {}

    // copy the prev track lists
    _.forEach(this.state.trackingMapList, (mapObj: IObject, mapKey: string) => {
      const keep = source.some((data: IWorkflowSet) => {
        const workflowObjectMap: IObject = _.get(data, 'workflowObjectMap', {})
        const {
          obj_id: objectId,
          status: mapStatus,
          workflow_id: workflowId,
        } = workflowObjectMap
        return (
          `${objectId}+${workflowId}` === mapKey &&
          trackingMapStatus.includes(mapStatus)
        )
      })
      if (keep) {
        trackingMapList[mapKey] = mapObj
      }
    })
    _.forEach(this.state.trackingWFList, (wfObj: IObject, wfKey: string) => {
      const keep = source.some((data: IWorkflowSet) => {
        const workflowObjectMap: IObject = _.get(data, 'workflowObjectMap', {})
        const workflow: IObject = _.get(data, 'workflow', {})
        if (workflow instanceof Promise) {
          const { workflow_id: workflowId } = workflowObjectMap
          return workflowId === wfKey
        } else {
          const { workflow_id: workflowId } = workflowObjectMap
          const { status: wfStatus } = workflow
          return workflowId === wfKey && trackingWFStatus.includes(wfStatus)
        }
      })
      if (keep) {
        trackingWFList[wfKey] = wfObj
      }
    })

    source.forEach((data: IWorkflowSet) => {
      const workflowObjectMap: IObject = _.get(data, 'workflowObjectMap', {})
      const workflow: IObject = _.get(data, 'workflow', {})

      const {
        obj_id: objectId,
        status: mapStatus,
        workflow_id: workflowId,
      } = workflowObjectMap

      const shouldTrackMap = trackingMapStatus.includes(mapStatus)
      const inMapTracking =
        trackingMapList[`${objectId}+${workflowId}`] !== undefined
      if (shouldTrackMap && !inMapTracking) {
        trackingMapList[`${objectId}+${workflowId}`] = null
      } else if (!shouldTrackMap && inMapTracking) {
        delete trackingMapList[`${objectId}+${workflowId}`]
      }

      if (workflow instanceof Promise) {
        workflow.then((response: any) => {
          const wfObject = _.get(response, 'data.workflow')
          if (!_.isNil(wfObject) && _.isObject(wfObject)) {
            this.updateTrackWFList(workflowId, wfObject)
          }
        })
      } else {
        const { status: wfStatus } = workflow
        const shouldTrackWF = trackingWFStatus.includes(wfStatus)
        const inWFTracking = trackingWFList[workflowId] !== undefined
        if (shouldTrackWF && !inWFTracking) {
          trackingWFList[workflowId] = null
        } else if (!shouldTrackWF && inWFTracking) {
          delete trackingWFList[workflowId]
        }
      }
    })
    this.setState({ trackingMapList, trackingWFList })
  }
  updateTrackWFList = (id: string, workflow: IObject) => {
    const { trackingWFStatus } = this.props
    const trackingWFList = this.state.trackingWFList

    const { status: wfStatus } = workflow
    const shouldTrackWF = trackingWFStatus.includes(wfStatus)
    const inWFTracking = trackingWFList[id] !== undefined
    if (shouldTrackWF && !inWFTracking) {
      trackingWFList[id] = null
    } else if (!shouldTrackWF && inWFTracking) {
      delete trackingWFList[id]
    }

    this.setState({ trackingWFList })
  }

  trackLatestInfor = () => {
    const { trackingMapStatus, trackingWFStatus } = this.props
    const {
      isTracking,
      trackingMapList,
      trackingWFList,
      trackingTime,
    } = this.state

    if (isTracking) {
      return
    }
    this.setState({ isTracking: true })

    const associateMapKey = (mapKey: string, apiResult: IObject | boolean) => {
      return { mapKey, apiResult }
    }
    const associateWFId = (
      workflowId: string,
      apiResult: IObject | boolean,
    ) => {
      return { workflowId, apiResult }
    }

    let newTrackingTime: number = trackingTime
    const promises: Array<Promise<any>> = []
    Object.keys(trackingMapList).forEach((mapKey: string) => {
      const [objectId, workflowId] = mapKey.split('+')
      if (trackingMapList[mapKey] === null) {
        promises.push(
          analysisUtils
            .getMapDetailInfo(httpClient, objectId, workflowId)
            .then(associateMapKey.bind(this, mapKey)),
        )
      } else if (_.isObject(trackingMapList[mapKey])) {
        const status = _.get(trackingMapList[mapKey], ['status'], null)
        if (trackingMapStatus.includes(status)) {
          promises.push(
            analysisUtils
              .getMapDetailInfo(httpClient, objectId, workflowId)
              .then(associateMapKey.bind(this, mapKey)),
          )
        }
      }
    })
    Object.keys(trackingWFList).forEach((workflowId: string) => {
      if (trackingWFList[workflowId] === null) {
        promises.push(
          analysisUtils
            .getWFDetailInfo(httpClient, workflowId)
            .then(associateWFId.bind(this, workflowId)),
        )
      } else if (_.isObject(trackingWFList[workflowId])) {
        const status = _.get(trackingWFList[workflowId], ['status'], null)
        if (trackingWFStatus.includes(status)) {
          promises.push(
            analysisUtils
              .getWFDetailInfo(httpClient, workflowId)
              .then(associateWFId.bind(this, workflowId)),
          )
        }
      }
    })

    Promise.all(promises).then((resultList: IObject[]) => {
      const {
        trackingMapList: latestMapList,
        trackingWFList: latestWFList,
      } = this.state
      resultList.forEach((result: IObject) => {
        const { mapKey, workflowId, apiResult } = result || ({} as IObject)
        if (
          mapKey &&
          _.isObject(apiResult) &&
          latestMapList[mapKey] !== undefined
        ) {
          latestMapList[mapKey] = apiResult
          newTrackingTime = Date.now()
        } else if (
          workflowId &&
          _.isObject(apiResult) &&
          latestWFList[workflowId] !== undefined
        ) {
          latestWFList[workflowId] = apiResult
          newTrackingTime = Date.now()
        }
      })
      this.setState({
        isTracking: false,
        trackingMapList: latestMapList,
        trackingWFList: latestWFList,
        trackingTime: newTrackingTime,
      })
    })
  }

  initialize = (nextSorter?: string, nextOrder?: string) => {
    const { enabledSorter, sorterOrder } = this.state
    const sorter = nextSorter || enabledSorter
    const order = (nextOrder || sorterOrder) as 'asc' | 'desc'

    this.defaultColumnConfigs = [
      {
        title: (
          <span className="column-header">
            {/* <SortButton onOrderChange={this.onClickSort.bind(this, 'status')}/> */}
          </span>
        ),
        dataIndex: 'status',
        key: 'status',
        render: (data: string, record: IWorkflowInfor, index: number) => {
          return (
            <div className="icon-wrapper">
              <StatusIcon status={data} />
            </div>
          )
        },
      },
      {
        title: (
          <span className="column-header">
            <span className="column-title">Workflow ID</span>
            {/* <SortButton onOrderChange={this.onClickSort.bind(this, 'workflowId')}/> */}
          </span>
        ),
        dataIndex: 'workflowId',
        key: 'workflowId',
        render: (data: string, record: IWorkflowInfor, index: number) => {
          return <TextWrapper text={data} maxWidth={280} />
        },
      },
      {
        title: (
          <span className="column-header">
            <span className="column-title">Object</span>
            {/* <SortButton onOrderChange={this.onClickSort.bind(this, 'mappedObj')}/> */}
          </span>
        ),
        dataIndex: 'mappedObj',
        key: 'mappedObj',
        render: (data: IObject, record: IWorkflowInfor, index: number) => {
          const { name = '', type = '' } = data || {}
          let prefixIcon: JSX.Element = null
          switch (type) {
            case 'cluster':
              prefixIcon = <StatusIcon status="SUCCESS" innerText="c" />
              break
            case 'cluster-partition':
              prefixIcon = <StatusIcon status="SUCCESS" innerText="cp" />
              break
            case 'device':
              prefixIcon = <StatusIcon status="SUCCESS" innerText="d" />
              break
            case 'device-partition':
              prefixIcon = <StatusIcon status="SUCCESS" innerText="dp" />
              break
            case 'logical-partition':
              prefixIcon = <StatusIcon status="SUCCESS" innerText="lp" />
              break
          }
          return (
            <TextWrapper text={name} maxWidth={180} prefix={[prefixIcon]} />
          )
        },
      },
      {
        title: (
          <span className="column-header">
            <span className="column-title">Type</span>
            <SortButton
              order={order}
              disabled={sorter !== 'workflowType'}
              onOrderChange={this.onClickSort.bind(this, 'workflowType')}
            />
          </span>
        ),
        dataIndex: 'workflowType',
        key: 'workflowType',
        render: (data: string, record: IWorkflowInfor, index: number) => {
          return <TextWrapper text={data} maxWidth={180} />
        },
      },
      {
        title: (
          <span className="column-header">
            <span className="column-title">Created on</span>
            <SortButton
              order={order}
              disabled={sorter !== 'createTime'}
              onOrderChange={this.onClickSort.bind(this, 'createTime')}
            />
          </span>
        ),
        dataIndex: 'createTime',
        key: 'createTime',
        render: (data: number, record: IWorkflowInfor, index: number) => {
          return (
            <div className="create-on-column">
              <span className="format-time">
                {this.getFormatTimeText(record.createTime)}
              </span>
              <i className="format-date">
                {this.getFormatDateText(record.createTime)}
              </i>
            </div>
          )
        },
      },
      {
        title: (
          <span className="column-header">
            <span className="column-title">Duration</span>
            {/* <SortButton onOrderChange={this.onClickSort.bind(this, 'duration')}/> */}
          </span>
        ),
        dataIndex: 'duration',
        key: 'duration',
        render: (data: number, record: IWorkflowInfor, index: number) => {
          return (
            <div className="duration-column">
              <span className="format-duration">
                {_.isFinite(record.duration) && record.duration >= 0
                  ? this.getFormatDurationText(record.duration)
                  : _.isString(record.duration)
                  ? record.duration
                  : null}
              </span>
            </div>
          )
        },
      },
      {
        title: (
          <span className="column-header">
            <span className="column-title">Status Message</span>
            {/* <SortButton onOrderChange={this.onClickSort.bind(this, 'message')}/> */}
          </span>
        ),
        dataIndex: 'message',
        key: 'message',
        render: (data: string, record: IWorkflowInfor, index: number) => {
          let text = data
          if (_.isString(data) && data.includes('\n')) {
            const allRecord = data.split('\n')
            while (allRecord.length) {
              const lastRecord = allRecord.pop()
              if (_.isString(lastRecord) && lastRecord) {
                text = lastRecord
                break
              }
            }
          }
          return <TextWrapper text={text} title={data} maxWidth={350} />
        },
      },
      {
        key: 'actions',
        render: (data: any, record: IWorkflowInfor, index: number) => {
          const actionMenu = this.getWorkflowMenu(record)
          if (actionMenu.length === 0) {
            return null
          }
          return (
            <div className="actions-column">
              <i>
                <A10DropdownMenu
                  title={null}
                  menu={actionMenu}
                  overlayClassName="workflow-pullup-menu"
                  style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={this.onClickAction.bind(this, record)}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </i>
            </div>
          )
        },
      },
    ]
  }

  getWorkflowMenu = (record: IWorkflowInfor) => {
    const currentTenant = _.get(storage.get.CURRENT_TENANT, 'name')
    if (_.isString(currentTenant) && currentTenant) {
      return []
    }

    const { status } = record || ({} as IObject)
    const enablePause = status === 'INPROGRESS'
    const enableResume = status === 'PAUSED'
    const enableRestart = status !== 'SUCCESS' && status !== 'INPROGRESS'
    const enableTerminate = status === 'INPROGRESS'

    const menu: JSX.Element[] = []
    if (enablePause) {
      // menu.push(<div key="pause">Pause</div>)      // defer to 5.1
    }
    if (enableResume) {
      // menu.push(<div key="resume">Resume</div>)    // defer to 5.1
    }
    if (enableRestart) {
      menu.push(<div key="restart">Retry Failed</div>)
    }
    if (enableTerminate) {
      menu.push(<div key="terminate">Terminate</div>)
    }
    return menu
  }

  onClickSort = (key: string, order: string) => {
    const { onOrderChange, isLoading } = this.props
    const { enabledSorter, sorterOrder } = this.state
    if (enabledSorter !== key) {
      if (onOrderChange instanceof Function && !isLoading) {
        this.setState({ enabledSorter: key, sorterOrder: 'asc' })
        onOrderChange(key, 'asc')
      }
    } else if (sorterOrder !== order) {
      if (onOrderChange instanceof Function && !isLoading) {
        this.setState({ sorterOrder: order })
        onOrderChange(key, order)
      }
    }
  }

  renderWorkflowDetail = (record: IWorkflowInfor) => {
    const { tasks, startTime, endTime, statusMessage, inputObj } = record

    if ((record as any).loading === true) {
      return (
        <div style={{ minHeight: 100 }}>
          <div className="loading-icon">
            <ReactLoading type="bars" color="#4a90e2" height={40} width={40} />
          </div>
        </div>
      )
    }

    return (
      <WorkflowDetail
        tasks={tasks || []}
        startTime={startTime}
        endTime={endTime}
        message={statusMessage}
        inputObj={inputObj}
      />
    )
  }

  getFormatTimeText = (timestamp: number) => {
    const date = new Date(timestamp)

    const hour = date.getHours()
    const hourText = hour < 10 ? `0${hour}` : `${hour}`
    const minute = date.getMinutes()
    const minuteText = minute < 10 ? `0${minute}` : `${minute}`
    const second = date.getSeconds()
    const secondText = second < 10 ? `0${second}` : `${second}`

    if (hour === 0) {
      return `${12}:${minuteText}:${secondText} PM`
    } else if (hour > 0 && hour < 13) {
      return `${hourText}:${minuteText}:${secondText} AM`
    } else {
      return `${
        hour - 12 < 10 ? `0${hour - 12}` : `${hour - 12}`
      }:${minuteText}:${secondText} PM`
    }
  }

  getFormatDateText = (timestamp: number) => {
    const date = new Date(timestamp)

    const month = date.getMonth()
    const monthText = month + 1 < 10 ? `0${month + 1}` : `${month + 1}`
    const day = date.getDate()
    const dayText = day < 10 ? `0${day}` : `${day}`
    const year = date.getFullYear()

    return `${monthText}-${dayText}-${year}`
  }

  getFormatDurationText = (timeRange: number) => {
    const second = Math.floor(timeRange / 1000) % 60
    const minute = Math.floor(timeRange / 1000 / 60) % 60
    const hour = Math.floor(timeRange / 1000 / 60 / 60) % 24
    const day = Math.floor(timeRange / 1000 / 60 / 60 / 24)

    let text = ''
    text += day > 0 ? `${day}d ` : ''
    text += hour > 0 ? `${hour}h ` : ''
    text += minute > 0 ? `${minute}m ` : ''
    text += `${second}s`

    return text
  }

  onPageChange = (page: number, pageSize?: number) => {
    const { onPageChange, isLoading } = this.props
    if (onPageChange instanceof Function && !isLoading) {
      onPageChange((page - 1) * pageSize, pageSize)
    }
  }

  onClickAction = (record: IWorkflowInfor, component: JSX.Element) => {
    const { status, workflowId } = record || ({} as IObject)
    const enablePause = status === 'INPROGRESS'
    const enableResume = status === 'PAUSED'
    const enableRestart = status !== 'SUCCESS' && status !== 'INPROGRESS'
    const enableTerminate = status === 'INPROGRESS'

    if (component.key === 'pause' && enablePause) {
      return
    } else if (component.key === 'resume' && enableResume) {
      return
    } else if (component.key === 'restart' && enableRestart) {
      this.restartWorkflow(workflowId)
      return
    } else if (component.key === 'terminate' && enableTerminate) {
      this.terminateWorkflow(workflowId)
      return
    }
  }

  restartWorkflow = (workflowId: string) => {
    this.setState({
      targetWorkflowID: workflowId,
      showRestartModal: true,
    })
  }

  onConfirmRestart = () => {
    const { onRefreshPage } = this.props
    const { targetWorkflowID } = this.state
    // Restart workflow
    analysisUtils
      .restartWorkflow(httpClient, targetWorkflowID)
      .then((result: any) => {
        if (result) {
          if (_.isString(result)) {
            A10Message.success(result, 4, close)
          } else {
            A10Message.success('Workflow restarted successfully.', 4, close)
          }
          if (onRefreshPage instanceof Function) {
            onRefreshPage()
          }
        } else {
          A10Message.error('Failed to restart worflow', 4, close)
        }
      })
      .then(() => {
        this.setState({ targetWorkflowID: null })
      })
    this.setState({ showRestartModal: false })
  }

  terminateWorkflow = (workflowId: string) => {
    this.setState({
      targetWorkflowID: workflowId,
      showTerminateModal: true,
    })
  }

  onConfirmTerminate = () => {
    // const { onRefreshPage } = this.props
    const { targetWorkflowID } = this.state
    // Delete workflow
    analysisUtils
      .terminateWorkflow(httpClient, targetWorkflowID)
      .then((result: any) => {
        if (result) {
          if (_.isString(result)) {
            A10Message.success(result, 4, close)
          } else {
            A10Message.success('Workflow terminated successfully.', 4, close)
          }
          // if (onRefreshPage instanceof Function) {
          //   onRefreshPage()
          // }
        } else {
          A10Message.error('Failed to terminate workflow', 4, close)
        }
      })
      .then(() => {
        this.setState({ targetWorkflowID: null })
      })
    this.setState({ showTerminateModal: false })
  }

  onCancel = () => {
    this.setState({
      targetWorkflowID: null,
      showRestartModal: false,
      showTerminateModal: false,
    })
  }

  mapWorkflowInfor = (source: IWorkflowSet[]) => {
    const { trackingMapList, trackingWFList } = this.state

    return source.map((data: IWorkflowSet) => {
      const workflowObjectMap: IObject = _.get(data, 'workflowObjectMap', {})
      const object: IObject = _.get(data, 'object', {})
      const workflow: IObject = _.get(data, 'workflow', {})

      const {
        obj_id: objectId,
        workflow_id: workflowId,
        workflow_type: workflowType,
        workflow_created_on: createTime,
      } = workflowObjectMap

      const latestObjectMap: IObject = _.get(
        trackingMapList,
        [`${objectId}+${workflowId}`],
        null,
      )
      const latestWorkflow: IObject = _.get(trackingWFList, [workflowId], null)
      const { status, status_message: statusMessage } =
        latestObjectMap || workflowObjectMap

      if (workflow instanceof Promise) {
        workflow.then((response: any) => {
          const wfObject = _.get(response, 'data.workflow')
          if (!_.isNil(wfObject) && _.isObject(wfObject)) {
            data.workflow = wfObject
          } else {
            data.workflow = {
              startTime: 1,
              updateTime: 0,
              endTime: 0,
              tasks: [],
            }
          }
          this.refresh()
        })

        return {
          status,
          workflowId,
          mappedObj: object,
          workflowType,
          createTime,
          duration: 'loading...',
          message: statusMessage,
          statusMessage,
          loading: true,
        }
      } else {
        const { startTime, updateTime, endTime, input, tasks } =
          latestWorkflow || workflow

        return {
          status,
          workflowId,
          mappedObj: object,
          workflowType,
          createTime,
          duration: (endTime || updateTime) - startTime,
          message: statusMessage,
          tasks,
          startTime,
          endTime,
          statusMessage,
          inputObj: input,
        } as IWorkflowInfor
      }
    })
  }

  render() {
    const { pageStart, pageSize, total, pageData, isLoading } = this.props

    return (
      <>
        <A10Table
          className={'hc-list workflow-pullup-list'}
          columns={this.defaultColumnConfigs}
          dataSource={this.mapWorkflowInfor(pageData).map(
            (item: any, index: number) => {
              if (!item.key) {
                item.key = index
              }
              return item
            },
          )}
          expandedRowRender={this.renderWorkflowDetail}
          size="small"
          pagination={{
            total,
            pageSize,
            current: Math.floor(pageStart / pageSize) + 1,
            onChange: this.onPageChange,
            showTotal: (totalNumber: number) => {
              return `Total ${totalNumber} items`
            },
          }}
          loading={
            isLoading
              ? {
                  indicator: (
                    <div className="loading-icon">
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    </div>
                  ),
                }
              : false
          }
          scroll={{ x: true }}
        />
        <A10Modal
          title="Confirmation"
          visible={this.state.showRestartModal}
          onOk={this.onConfirmRestart}
          onCancel={this.onCancel}
          footer={[
            <A10Button
              key="no"
              className="nobtn"
              type="primary"
              onClick={this.onCancel}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              className="yesbtn"
              type="default"
              onClick={this.onConfirmRestart}
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            Do you want to restart this workflow{' '}
            <span style={{ color: '#4a90e2' }}>
              {this.state.targetWorkflowID}
            </span>{' '}
            ?
          </p>
        </A10Modal>
        <A10Modal
          title="Confirmation"
          visible={this.state.showTerminateModal}
          onOk={this.onConfirmTerminate}
          onCancel={this.onCancel}
          footer={[
            <A10Button
              key="no"
              className="nobtn"
              type="primary"
              onClick={this.onCancel}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              className="yesbtn"
              type="default"
              onClick={this.onConfirmTerminate}
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            Do you want to terminate this workflow{' '}
            <span style={{ color: 'red' }}>{this.state.targetWorkflowID}</span>{' '}
            ?
          </p>
        </A10Modal>
      </>
    )
  }
}

export default WorkflowList
