import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useRequest, A10Context } from '@gui-libraries/framework'
import {
  A10Form,
  A10Input,
  A10Icon,
  A10Notification,
} from '@gui-libraries/widgets'

import { Messages } from 'src/locale/en/index'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import storage from 'src/libraries/storage'

interface ICredentialsProps {
  deviceName: string
  form?: IObject
  onRef: (onSubmit?: (callback?: () => void) => void) => void
}

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
}
const messages = new Messages()

const CredentialsForm: React.FC<ICredentialsProps> = props => {
  const { deviceName, form, onRef } = props
  const { getFieldDecorator } = form
  const provider = useMemo(() => storage.get.PROVIDER, [])
  const { httpClient } = useContext(A10Context)
  const url = useMemo(
    () => `/hpcapi/v3/provider/${provider}/device/${deviceName}`,
    [provider, deviceName],
  )
  const [data = {}] = useRequest({
    url,
  })
  const { username } = data?.device ?? {}

  useEffect(() => {
    onRef(onSubmit)
  }, [])

  const onSubmit = useCallback(
    callback => {
      form.validateFields(
        async (
          error: IObject,
          values: { username: string; password: string },
        ) => {
          const { username, password } = values
          // const encryptedPassword = new Buffer(password).toString('base64')

          if (!error) {
            try {
              await httpClient({
                url,
                method: 'post',
                data: {
                  device: {
                    username,
                    password
                  },
                },
              })
              A10Notification.success({
                message: 'Success',
                description: 'Update credentials successfully',
                duration: 10,
              })
            } catch (errRes) {
              A10Notification.error({
                message: 'Error',
                description: 'Failed to update credentials',
                duration: 10,
              })
            } finally {
              callback()
            }
          }
        },
      )
    },
    [form],
  )
  return (
    <A10Form hideRequiredMark={true} layout="horizontal">
      <A10Panel
        title={
          <>
            <A10IconTextGroup
              text={
                <>
                  Credentials
                  <HelpInfo
                    customCls="custom-tooltip-device-info"
                    placement="right"
                    title="Credentials"
                    helpKey="HELP_INFRA_DEVICE_CREDENTIALS_INFO"
                  />
                </>
              }
              icon={
                <A10Icon
                  style={{ width: 22, height: 22, marginRight: 12 }}
                  app="global"
                  type="form-section"
                  className="sliding-panel-icon"
                />
              }
            />
          </>
        }
      >
        <A10Form.Item
          {...formItemLayout}
          label={
            <>
              <span className="mandatoryField">{messages.ADMIN_USERNAME}</span>
              <span style={{ marginLeft: '-26px' }}>
                <HelpInfo
                  placement="leftTop"
                  title="Admin User Name * "
                  helpKey="HELP_INFRA_ADMIN_USER_NAME"
                />
              </span>
            </>
          }
        >
          {getFieldDecorator('username', {
            rules: [
              {
                required: true,
                message: messages.USERNAME_REQUIRED,
              },
            ],
            initialValue: username,
          })(<A10Input type="text" placeholder={messages.ADMIN_USERNAME} />)}
        </A10Form.Item>
        <A10Form.Item
          {...formItemLayout}
          label={
            <>
              <span className="mandatoryField">{messages.ADMIN_PASSWORD}</span>
              <span style={{ marginLeft: '-24px' }}>
                <HelpInfo
                  placement="leftTop"
                  title="Admin Password * "
                  helpKey="HELP_INFRA_ADMIN_USER_PASSWORD"
                />
              </span>
            </>
          }
        >
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: messages.USER_PASSWORD_REQUIRED,
              },
            ],
          })(
            <A10Input type="password" placeholder={messages.ADMIN_PASSWORD} />,
          )}
        </A10Form.Item>
      </A10Panel>
    </A10Form>
  )
}

export default A10Form.create()(CredentialsForm)
