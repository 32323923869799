import React from 'react'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Row,
  A10Col,
  A10Input,
  A10Icon,
  A10Button,
  A10AflexCodeEditor,
  A10Collapse,
  A10Alert,
  A10Select,
  A10Dropdown,
  A10Menu,
} from '@gui-libraries/widgets'
import { Viz } from '@gui-libraries/viz'

import { AppRoot } from 'src/settings/appRoot'
import { Utilities, TroubleShootService } from 'src/services'
import { Messages } from 'src/locale/en/Messages'
import storage from 'src/libraries/storage'
import { RoundNumber } from 'src/components/shared/RoundNumber'
import { UpgradeModal } from 'src/components/shared/UpgradeModal'
import { ClusterDevicePartition } from '../Forms/ClusterDevicePartition'
import { SlidingDeviceCmdForms } from '../Forms/SlidingDeviceCmdForms'
import SlidingWorkflowStatusList from 'src/components/shared/WorkflowStatus/slidingWorkflowListForm'
import ActivateLicense from 'src/components/shared/ActivateLicense'

const styles = require('./styles/index.module.less')

const { Table } = Viz

export interface IRunCommandsProps extends IA10ContainerDefaultProps {
  tenantName: string
  maxSnippets: number
  remainingSnippets: number
  getRemainingSnippets: () => void
  activeLicense: string
  devicesDetail: IObject[]
  tenantDetail: IObject
}

export interface IRunCommandsState {
  cmdSnippets: any[]
  cmdHistory: any[]
  cmdHistoryCnt: number
  searchString: string
  searchCmd: string
  selectedCliCmd: any
  editableCmd: boolean
  editMode: boolean
  showSlidingPage: boolean
  enableRun: boolean
  runLoading: boolean
  devicePartitions: any[]
  workflowIDList: string[]
  workflowObjectList: string[]
  workflowSliderState: boolean
  runCmdScheduleTasks: any[]
  selectedObj: any
  editTask: boolean
  filteredCmdHistory: any[]
  troubleshootHistory: any[]
  troubleshootHistoryTableLoaded: boolean
  refreshScheduleLogs: boolean
  filterCmdSnippet: any
  filteredCmdSnippet: any
  troubleShootOutput: any[]
  isPeriodicAllowed: boolean
  showUpgrade: boolean
  showAddLicense: boolean
}

class RunCommands extends A10Container<IRunCommandsProps, IRunCommandsState> {
  AppRoot = new AppRoot()
  Messages = new Messages()
  Utilities = new Utilities()
  TroubleShootService = new TroubleShootService()
  cmdSnippets: any[] = []
  runCmdScheduleTasks: any[] = []

  provider = storage.get.PROVIDER
  adminLevel = storage.get.ADMIN_LEVEL
  headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    provider: storage.get.PROVIDER,
    Authorization: storage.get.ENCODED_SESSION_ID,
    user: storage.get.USER_ID,
  }
  dataLoaded = false
  historyMaxCnt = 50

  constructor(props: any) {
    super(props)

    this.state = {
      cmdSnippets: [],
      cmdHistory: [],
      cmdHistoryCnt: 0,
      searchString: '',
      searchCmd: '',
      selectedCliCmd: {
        id: '',
        name: '',
        content: '',
        contentSplit: '',
      },
      editableCmd: false,
      editMode: false,
      showSlidingPage: false,
      enableRun: false,
      runLoading: false,
      devicePartitions: [],
      workflowIDList: [],
      workflowObjectList: [],
      workflowSliderState: false,
      runCmdScheduleTasks: [],
      selectedObj: {},
      editTask: false,
      filteredCmdHistory: [],
      troubleshootHistory: [],
      troubleshootHistoryTableLoaded: false,
      refreshScheduleLogs: false,
      filterCmdSnippet: '',
      filteredCmdSnippet: [],
      troubleShootOutput: [],
      isPeriodicAllowed: false,
      showUpgrade: false,
      showAddLicense: false,
    }
    this.getCliSnippets(true, true)
    this.getEntitlements()
  }

  componentDidUpdate = (prevProps: IRunCommandsProps) => {
    if (prevProps.tenantDetail !== this.props.tenantDetail) {
      this.getCommandExecutionHistory()
    }
  }

  getEntitlements = () => {
    this.TroubleShootService.getLicenseEntitlements(null, null, [
      storage.get.PROVIDER,
    ])
      .then((resp: IObject) => {
        const { data, status } = resp
        const deviceManagement = data?.['device-management']

        let isAllowed = false
        if (!deviceManagement?.backup && status === 200) {
          isAllowed = true
        } else {
          isAllowed = deviceManagement?.['cli-snippets']?.periodic
        }
        this.setState({ isPeriodicAllowed: isAllowed })
      })
      .catch((err: IObject) => {})
  }

  getCliSnippets = (
    initialCall?: boolean,
    doNotSelect?: boolean,
    newSnippet?: any,
  ) => {
    const cliSnippets = this.TroubleShootService.listCliSnippets(
      this.headers,
      null,
      [this.provider],
    )
    cliSnippets
      .then((response: any) => {
        const cmdSnippets =
          response?.data && Array.isArray(response.data) ? response.data : []
        let filteredCmdSnippet = [{ name: 'N/A', id: 'na' }]
        filteredCmdSnippet = filteredCmdSnippet.concat(cmdSnippets)
        this.cmdSnippets = cmdSnippets
        this.setState({
          cmdSnippets,
          filteredCmdSnippet,
          filterCmdSnippet: '',
          searchString: '',
        })
        if (!doNotSelect) {
          if (this.cmdSnippets.length > 0) {
            this.selectCliCmd(this.cmdSnippets[0])
          } else {
            const selectedCliCmd = {
              id: '',
              name: '',
              content: '',
              contentSplit: '',
            }
            this.setState({
              editableCmd: false,
              editMode: false,
              workflowSliderState: false,
              workflowIDList: [],
              selectedCliCmd,
            })
          }
        } else if (!!newSnippet) {
          this.selectCliCmd(newSnippet)
        }
        if (initialCall) {
          this.newSnippet()
          this.getCommandExecutionHistory()
          this.getScheduledTasks()
        }
      })
      .catch((error: any) => {
        this.cmdSnippets = []
        this.setState({
          cmdSnippets: [],
          searchString: '',
        })
        if (initialCall) {
          this.getScheduledTasks()
        }
      })
  }

  getScheduledTasks = () => {
    const scheduleTasks = this.TroubleShootService.listScheduleTasks(
      this.headers,
      null,
      [this.provider],
    )
    scheduleTasks
      .then((response: any) => {
        const runCmdScheduleTasks =
          response?.data && Array.isArray(response.data) ? response.data : []
        runCmdScheduleTasks.map((runCmdTasks: any) => {
          const inputParams = JSON.parse(runCmdTasks.input_parameters),
            devices: string[] = []
          const frequency = inputParams?.scheduler?.frequency
            ? inputParams.scheduler.frequency
            : ''
          const startDate = inputParams?.scheduler?.startsOn
            ? this.Utilities.formatDate(
                inputParams.scheduler.startsOn,
                'YYYY/MM/DD HH:mm:ss',
              )
            : ''
          inputParams.devices.map((device: any) => {
            if (devices.indexOf(device.name) === -1) {
              devices.push(device.name + ':' + device.partition)
            }
          })
          runCmdTasks['device-partition'] = devices
          runCmdTasks['frequency'] = frequency
          runCmdTasks['startDate'] = startDate
          if (this.cmdSnippets.length > 0) {
            const cmdSnippet = this.cmdSnippets.find((cmd: any) => {
              return cmd.id === runCmdTasks.snippet_id
            })
            if (cmdSnippet) {
              runCmdTasks['commands'] = cmdSnippet.content.join(', ')
              runCmdTasks['snippet'] = cmdSnippet.name
            }
          }
        })
        this.dataLoaded = true
        this.runCmdScheduleTasks = runCmdScheduleTasks
        this.setState({
          runCmdScheduleTasks,
          refreshScheduleLogs: !this.state.refreshScheduleLogs,
        })
      })
      .catch((error: any) => {
        this.dataLoaded = true
        this.runCmdScheduleTasks = []
        this.setState({ runCmdScheduleTasks: [] })
      })
  }

  getCommandExecutionHistory = () => {
    const { tenantName, devicesDetail, tenantDetail } = this.props

    this.setState({
      troubleshootHistoryTableLoaded: false,
      filteredCmdHistory: [],
    })

    // get top [historyMaxCnt] history to display
    const cmdSnippetHistory = this.TroubleShootService.listCmdSnippetHistory(
      this.headers,
      {},
      [this.provider, this.historyMaxCnt],
    )

    // get total history count for determining whether to show the 'Top N' label
    const cmdSnippetHistoryTotalCount = this.TroubleShootService.getRunCommandHistoryCounts(
      this.headers,
      {},
      [this.provider],
    )

    const isPartitionInTenantLPList = (
      partitionUuid: string,
      tenantLPList: IObject[],
    ): boolean => {
      return tenantLPList.some(tenantLP => {
        return tenantLP.deploy_target.target['device-list'].some(
          (device: IObject) => {
            return device['partition-list'].some(
              (partition: IObject) => partition.uuid === partitionUuid,
            )
          },
        )
      })
    }

    Promise.all([cmdSnippetHistory, cmdSnippetHistoryTotalCount])
      .then((response: any) => {
        const troubleshootHistory = Array.isArray(response?.[0]?.data)
          ? response[0].data.reduce((prev: IObject[], cur: IObject) => {
              const deviceWithPartition =
                devicesDetail?.find((deviceObj: any) => {
                  return (
                    deviceObj.name === cur.device_name &&
                    deviceObj['partition-list'].some(
                      (partition: IObject) => cur.partition === partition.name,
                    )
                  )
                }) || {}
              const partitionId = deviceWithPartition?.['partition-list']?.find(
                (partition: IObject) => cur.partition === partition.name,
              )?.uuid

              if (
                !tenantName ||
                isPartitionInTenantLPList(
                  partitionId,
                  tenantDetail?.['logical-partition-list'],
                )
              ) {
                const device = devicesDetail?.find((deviceObj: any) => {
                  return cur.device_name === deviceObj.name
                })
                if (device && device.cluster) {
                  cur['cluster_name'] = device.cluster
                }
                const cmdSnippet =
                  this.state.cmdSnippets &&
                  this.state.cmdSnippets.find((snippet: any) => {
                    return cur.snippet_id === snippet.id
                  })
                if (cmdSnippet && cmdSnippet.name) {
                  cur['snippet_name'] = cmdSnippet.name
                }
                cur[
                  'created_at'
                ] = this.TroubleShootService.convertToLocalDateTime(
                  cur['created_at'],
                )
                cur[
                  'last_modified_at'
                ] = this.TroubleShootService.convertToLocalDateTime(
                  cur['last_modified_at'],
                )
                prev.push(cur)
              }
              return prev
            }, [])
          : []

        const cmdHistoryCnt = response?.[1]?.data?.total || 0

        this.setState({
          troubleshootHistory,
          filterCmdSnippet: '',
          filteredCmdHistory: troubleshootHistory,
          troubleshootHistoryTableLoaded: true,
          cmdHistoryCnt,
        })
      })
      .catch((error: any) => {
        this.setState({
          troubleshootHistory: [],
          filterCmdSnippet: '',
          filteredCmdHistory: [],
          troubleshootHistoryTableLoaded: true,
        })
      })
  }

  searchCliSnippets = (e: any) => {
    const searchString =
      e && e.target && (e.target.value || e.target.value === '')
        ? e.target.value
        : this.state.searchString
    this.Utilities.search(this, searchString, 'name', {
      storeData: this.cmdSnippets,
      stateName: 'cmdSnippets',
    })
  }

  newSnippet = () => {
    const selectedCliCmd = {
      id: '',
      name: '',
      content: '',
      contentSplit: '',
    }
    this.setState(
      {
        editableCmd: true,
        editMode: false,
        workflowSliderState: false,
        workflowIDList: [],
        selectedCliCmd,
      },
      () => {
        if (this.refs.editor?.myRef?.current) {
          var cm = this.refs.editor.getCodeMirror()
          cm.setValue('')
        }
      },
    )
  }

  newRunCmdSchedule = () => {
    this.setState({
      showSlidingPage: true,
      editTask: false,
      selectedObj: {
        name: '',
        devicePartitions: [],
        cliSnippet: JSON.stringify(this.state.selectedCliCmd),
        when: 'schedule',
        frequency: '',
        startsOn: '',
        timezone: '',
      },
    })
  }

  selectCliCmd = (cliSnippet: any) => {
    const selectedCliCmd = {
      id: cliSnippet.id,
      name: cliSnippet.name,
      content: cliSnippet.content,
      contentSplit: '',
    }
    const contentSplit: string[] = []
    try {
      cliSnippet.content = JSON.parse(cliSnippet.content)
    } catch {
      try {
        cliSnippet.content = cliSnippet.content.split(',')
      } catch {
        cliSnippet.content = cliSnippet.content
      }
    }
    cliSnippet.content.map((cmd: string) => {
      contentSplit.push(cmd.trim())
    })
    selectedCliCmd.contentSplit = contentSplit.join('\n')

    let enableRun = false
    if (
      this.state.devicePartitions.length > 0 &&
      !!selectedCliCmd.contentSplit
    ) {
      enableRun = true
    }

    this.setState({
      editableCmd: false,
      editMode: false,
      workflowSliderState: false,
      workflowIDList: [],
      selectedCliCmd,
      enableRun,
    })
  }

  editSnippet = () => {
    if (this.state.selectedCliCmd.id) {
      this.setState({
        editableCmd: true,
        editMode: true,
        workflowSliderState: false,
        workflowIDList: [],
      })
    }
  }

  cancelEditable = () => {
    this.setState({
      editableCmd: false,
      workflowSliderState: false,
      workflowIDList: [],
      editMode: false,
    })
  }

  getComaSeparatedCmds = (cmdContent: string) => {
    const cmdContentArray = cmdContent.split('\n')
    let cmds: any = []
    cmdContentArray.map((cmd: string) => {
      if (cmd.trim() !== '') {
        cmds.push(cmd.trim())
      }
    })
    return cmds
  }

  saveSnippet = () => {
    const { maxSnippets, remainingSnippets, activeLicense } = this.props
    if (activeLicense !== 'No Active License') {
      if (maxSnippets === -1 || remainingSnippets > 0) {
        const { selectedCliCmd, cmdSnippets } = this.state
        const { showMessage, validateName } = this.Utilities
        const snippetName = selectedCliCmd.name
        if (!snippetName?.trim()) {
          showMessage('Please enter the command snippet name', 0, 0)
          return
        }
        if (!selectedCliCmd.contentSplit.trim()) {
          showMessage('Please enter the command snippets', 0, 0)
          return
        }
        if (cmdSnippets.find(snippet => snippet.name === snippetName?.trim())) {
          showMessage('Snippet with same name already exists', 0, 0)
          return
        } else if (!validateName(snippetName)) {
          showMessage(
            "Please enter a valid snippet name, it cannot exceed 30 letters and no special characters (except '-', '_', '.')",
            0,
            0,
          )
          return
        }

        const cmdContent = this.getComaSeparatedCmds(
          selectedCliCmd.contentSplit,
        )
        const payload = {
          name: selectedCliCmd.name,
          content: JSON.stringify(cmdContent),
        }

        let saveCliSnippet = null

        if (this.state.editMode) {
          payload['id'] = selectedCliCmd.id
          saveCliSnippet = this.TroubleShootService.updateCliSnippet(
            this.headers,
            payload,
            [this.provider],
          )
        } else {
          saveCliSnippet = this.TroubleShootService.saveCliSnippet(
            this.headers,
            payload,
            [this.provider],
          )
        }

        saveCliSnippet
          .then((response: IObject) => {
            showMessage(
              `${
                this.state.editMode ? 'Updated ' : 'Saved '
              } command snippet successfully`,
              1,
              0,
            )
            if (this.state.editMode) {
              this.getCliSnippets(false, true)
            } else {
              this.getCliSnippets(false, true, response.data)
              this.getCommandExecutionHistory()
            }
            this.cancelEditable()
            this.props.getRemainingSnippets()
          })
          .catch((error: any) => {
            showMessage(
              `Error in ${
                this.state.editMode ? 'update ' : 'save '
              } of command snippet`,
              0,
              0,
            )
          })
      } else {
        this.setState({ showUpgrade: true })
      }
    } else {
      this.setState({ showAddLicense: true })
    }
  }

  onViewWorkflowStatus = (data: IObject, devicePartitions: any[]) => {
    const details = data?.details,
      workflowIDList: string[] = [],
      objectList: string[] = []
    details &&
      devicePartitions.map((devicePartition: any) => {
        workflowIDList.push(
          details[devicePartition.name][devicePartition.partition],
        )
        objectList.push(
          devicePartition.name + ' : ' + devicePartition.partition,
        )
      })
    const devices = Object.keys(details)
    if (devices && devices.length > 0) {
      this.setState({
        workflowIDList,
        workflowObjectList: objectList,
        workflowSliderState: true,
      })
    }
  }

  handleSlidingWorkflowOnCancel = () => {
    this.selectCliCmd(this.state.selectedCliCmd)
    this.getCommandExecutionHistory()
    this.setState({
      workflowSliderState: false,
      workflowIDList: [],
    })
  }

  handleRunCliSnippet = () => {
    const { showMessage } = this.Utilities
    const { editableCmd, selectedCliCmd, devicePartitions } = this.state
    let commandSource,
      clientType = ''
    if (editableCmd) {
      commandSource = this.getComaSeparatedCmds(selectedCliCmd.contentSplit)
      clientType = 'troubleshooting'
    } else {
      commandSource = selectedCliCmd.id
      clientType = 'runtime'
    }

    if (!selectedCliCmd.contentSplit.trim()) {
      showMessage('Please enter/select the command snippets', 0, 0)
      return
    }

    if (devicePartitions && devicePartitions.length > 0) {
      const payload = {
        commands_source: commandSource,
        devices: [],
        client: clientType,
      }
      devicePartitions.map((devicePartition: any) => {
        payload.devices.push({
          name: devicePartition.name,
          partition: devicePartition.partition,
        })
      })

      this.setState({ enableRun: false, runLoading: true })

      const runCliSnippet = this.TroubleShootService.runCliSnippet(
        this.headers,
        payload,
        [this.provider],
      )
      runCliSnippet
        .then((response: any) => {
          showMessage(
            `Submitted ${
              clientType === 'runtime' ? 'command snippet' : 'command(s)'
            } successfully to execute`,
            1,
            0,
          )
          this.handleRunCancel()
          if (clientType === 'runtime') {
            this.onViewWorkflowStatus(response.data, devicePartitions)
          } else {
            const troubleShootResult = response.data?.details,
              troubleShootOutput: any[] = []
            troubleShootResult &&
              devicePartitions.map((devicePartition: any) => {
                troubleShootOutput.push({
                  device: devicePartition.name,
                  partition: devicePartition.partition,
                  devCmdOutput:
                    troubleShootResult[devicePartition.name][
                      devicePartition.partition
                    ],
                })
              })
            this.setState({ troubleShootOutput })
            this.getCommandExecutionHistory()
          }
        })
        .catch((error: any) => {
          const msg = error?.response?.data?.message
            ? error.response.data.message
            : ''
          showMessage(
            `Error in submission of ${
              clientType === 'runtime' ? 'command snippet' : 'command(s)'
            } to execute`,
            0,
            0,
            msg,
          )
          this.setState({ enableRun: true, runLoading: false })
        })
    } else {
      showMessage(
        `Please select the device partition to submit the ${
          clientType === 'runtime' ? 'command snippet' : 'command(s)'
        } to run`,
        0,
        0,
      )
    }
  }

  updateState = (stateName: string, value: any) => {
    // @ts-ignore
    this.setState({ [stateName]: value })
  }

  handleRunCancel = () => {
    this.setState({
      runLoading: false,
      showSlidingPage: false,
      workflowSliderState: false,
      workflowIDList: [],
    })
  }

  handleChange = (fieldName: string, e: Event | any) => {
    const { selectedCliCmd, devicePartitions } = this.state
    let enableRun = false
    if (e.target) {
      if (e.target.type === 'checkbox') {
        selectedCliCmd[fieldName] = e.target.checked
      } else {
        selectedCliCmd[fieldName] = e.target.value
      }
    } else {
      selectedCliCmd[fieldName] = e
    }

    if (
      devicePartitions.length > 0 &&
      !!this.state.selectedCliCmd.contentSplit
    ) {
      enableRun = true
    }

    this.setState({ selectedCliCmd, enableRun })
  }

  devicePartitionChange = (devicePartitions: any[]) => {
    let enableRun = false
    if (
      devicePartitions.length > 0 &&
      !!this.state.selectedCliCmd.contentSplit
    ) {
      enableRun = true
    }
    this.setState({
      devicePartitions,
      enableRun,
    })
  }

  filterCmdHistory = (filterCmdSnippet: any) => {
    const { troubleshootHistory } = this.state
    let filteredCmdHistory = []
    if (!filterCmdSnippet) {
      filteredCmdHistory = troubleshootHistory
    } else {
      filterCmdSnippet = JSON.parse(filterCmdSnippet)
      troubleshootHistory &&
        troubleshootHistory.map((history: any) => {
          if (
            filterCmdSnippet.id !== 'na' &&
            history.snippet_id === filterCmdSnippet.id
          ) {
            filteredCmdHistory.push(history)
          }
          if (filterCmdSnippet.id === 'na') {
            if (history.snippet_id === 'ad-hoc') {
              filteredCmdHistory.push(history)
            } else {
              const snippetIndex = this.cmdSnippets.findIndex(
                (snippet: any) => {
                  return snippet.id === history.snippet_id
                },
              )
              if (snippetIndex === -1) {
                filteredCmdHistory.push(history)
              }
            }
          }
        })
    }

    this.setState({ filteredCmdHistory })
  }

  handleFilterChange = (e: Event | any) => {
    let { filterCmdSnippet } = this.state
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        filterCmdSnippet = e.target.checked
      } else {
        filterCmdSnippet = e.target.value
      }
    } else {
      filterCmdSnippet = e || ''
    }

    this.setState({ filterCmdSnippet })
    this.filterCmdHistory(filterCmdSnippet)
  }

  cmdSnippetFilter = (value: any, option?: any) => {
    const filteredCmdSnippet =
      this.state.cmdSnippets &&
      this.state.cmdSnippets.filter((obj: any) => {
        return obj.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      })
    filteredCmdSnippet.unshift({ name: 'N/A', id: 'na' })
    this.setState({ filteredCmdSnippet })
  }

  cliSnippetsDropdown = () => {
    const { cmdSnippets } = this.state
    return (
      <A10Menu>
        {cmdSnippets.length === 0 ? (
          <A10Menu.Item disabled>No Snippets</A10Menu.Item>
        ) : (
          cmdSnippets.map((cmdSnippet: any) => {
            return (
              <A10Menu.Item onClick={this.selectCliCmd.bind(this, cmdSnippet)}>
                {cmdSnippet.name}
              </A10Menu.Item>
            )
          })
        )}
      </A10Menu>
    )
  }

  render() {
    const { tenantName, maxSnippets, devicesDetail, tenantDetail } = this.props
    const {
      editMode,
      editableCmd,
      selectedCliCmd,
      cmdSnippets,
      showSlidingPage,
      enableRun,
      runLoading,
      runCmdScheduleTasks,
      selectedObj,
      editTask,
      troubleshootHistory,
      troubleshootHistoryTableLoaded,
      filteredCmdHistory,
      cmdHistoryCnt,
      filterCmdSnippet,
      filteredCmdSnippet,
      troubleShootOutput,
      isPeriodicAllowed,
      showUpgrade,
      showAddLicense,
    } = this.state
    const codeFlexOptions = {
      lineNumbers: true,
      readOnly: editableCmd ? '' : 'nocursor',
    }
    const selCmdSnippet =
      filterCmdSnippet &&
      filteredCmdSnippet &&
      filteredCmdSnippet.find((cmdSnippet: any, i: number) => {
        return JSON.parse(filterCmdSnippet).id === cmdSnippet.id
      })
    let consolidatedDevOutput: string[] = []

    return (
      <div className="row no-margin">
        <div className="col-md-12 no-padding">
          {/* Code  editor */}
          <A10Row type="flex" align="middle" className={styles.runCmdSections}>
            <A10Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className={styles.sectionHeader}
            >
              <A10Alert
                showIcon={true}
                className={styles.runCmdInfoText}
                message={''}
                description={
                  <>
                    Commands involving large output such as ‘show log’ or ‘show
                    techsupport’ and commands that require user interaction to
                    quit (using Ctrl-C) such as ‘repeat 1 show …’ are not
                    supported. Also, all commands are run on the Thunder in
                    config mode. Any command that cannot be executed in this
                    mode will result in an error.
                  </>
                }
                type="info"
              />
            </A10Col>

            <A10Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <A10Row>
                <A10Col className={styles.colSectionHeader}>
                  <span>Command Editor</span>
                </A10Col>
              </A10Row>

              <A10Row
                className={`${styles.cmdSnippet} ${styles.cmdSnippetForm} ${
                  editableCmd ? styles.editable : ''
                }`}
              >
                <A10Row className={styles.cmdSnippetHeader}>
                  <A10Col
                    className={styles.cmdSnippetName}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={7}
                    xl={7}
                  >
                    {editableCmd ? (
                      <A10Input
                        type="text"
                        placeholder={'Command Snippet Name'}
                        value={selectedCliCmd.name}
                        onChange={this.handleChange.bind(this, 'name')}
                      />
                    ) : (
                      <span
                        className={styles.textSpan}
                        title={selectedCliCmd.name || 'Command Name'}
                      >
                        {selectedCliCmd.name || 'Command Name'}
                      </span>
                    )}
                  </A10Col>
                  <A10Col
                    className={`pad-left10 ${styles.cmdSnippetName}`}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={7}
                    xl={7}
                  >
                    <A10Dropdown overlay={this.cliSnippetsDropdown()}>
                      <a
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        <A10Icon
                          app="global"
                          type="post"
                          className={styles.actionIcon}
                        />
                        <span className={styles.dropdownTextSpan}>
                          CLI Snippets
                        </span>
                        <A10Icon type="down" />
                      </a>
                    </A10Dropdown>
                  </A10Col>
                  <A10Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={10}
                    xl={10}
                    className={styles.cliCmdBtn}
                  >
                    <A10Row>
                      <A10Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={styles.cmdSaveSnippet}
                      >
                        {editableCmd && !editMode ? (
                          <A10Button
                            className={styles.actionButton}
                            onClick={this.saveSnippet}
                          >
                            <A10Icon
                              app="global"
                              type="check"
                              className={styles.actionIcon}
                            />
                            Save as Snippet
                          </A10Button>
                        ) : (
                          <A10Button
                            className="action-button"
                            onClick={this.newSnippet}
                          >
                            <A10Icon
                              app="global"
                              type="add-new"
                              className="action-icon"
                            />
                            Create
                          </A10Button>
                        )}
                      </A10Col>
                      <A10Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={styles.cmdScheduleSnippet}
                      >
                        <A10Button
                          className={`${styles.actionButton} ${
                            !selectedCliCmd.id ? styles.disabledButton : ''
                          }`}
                          onClick={this.newRunCmdSchedule}
                          disabled={!selectedCliCmd.id}
                        >
                          <A10Icon
                            app="global"
                            type="date-time-picker"
                            className={styles.actionIcon}
                          />
                          Schedule Snippet
                        </A10Button>
                      </A10Col>
                    </A10Row>
                  </A10Col>
                </A10Row>

                <A10Row
                  className={`${editableCmd ? '' : styles.disabled} ${
                    styles.codeEditorDiv
                  } ${
                    !selectedCliCmd.contentSplit ? styles.placeholderText : ''
                  }`}
                >
                  <A10AflexCodeEditor
                    id={''}
                    ref="editor"
                    clearWhenEmpty={true}
                    className={`${styles.cmdContent}`}
                    value={
                      selectedCliCmd && selectedCliCmd.contentSplit
                        ? selectedCliCmd.contentSplit
                        : ''
                    }
                    options={codeFlexOptions}
                    style={{ height: 250 }}
                    onAFlexChange={this.handleChange.bind(this, 'contentSplit')}
                  />
                </A10Row>
              </A10Row>
            </A10Col>

            <A10Col xs={24} sm={24} md={24} lg={24} xl={8}>
              <A10Row>
                <A10Col className={styles.colSectionHeader}>
                  <span>Select Device</span>
                </A10Col>
              </A10Row>
              <A10Row className={styles.clusterDevicePartition}>
                <ClusterDevicePartition
                  tenantName={tenantName}
                  devicesDetail={devicesDetail}
                  tenantDetail={tenantDetail}
                  onChange={this.devicePartitionChange}
                  onlySearch={true}
                  height={244}
                />
                <div className={styles.runBtnDiv}>
                  <A10Button
                    className={styles.actionButton}
                    type="primary"
                    onClick={this.handleRunCliSnippet}
                    disabled={!enableRun}
                  >
                    {runLoading ? <A10Icon type="sync" spin /> : null}
                    Run
                  </A10Button>
                </div>
              </A10Row>
            </A10Col>
          </A10Row>

          {/* Troubleshoot Response */}
          {troubleShootOutput && troubleShootOutput.length > 0 ? (
            <A10Row className={`${styles.runCmdSections}`}>
              <A10Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className={styles.cdpPanel}
              >
                <A10Row>
                  <A10Col className={styles.colSectionHeader}>
                    <span>Response</span>
                    <RoundNumber
                      number={troubleShootOutput && troubleShootOutput.length}
                    />
                  </A10Col>
                </A10Row>
                <div className={styles.colSectionHeader}>
                  {troubleShootOutput &&
                    troubleShootOutput.map((devOutput: any, index: number) => {
                      consolidatedDevOutput.push(
                        `Response from ${devOutput.device +
                          ' : ' +
                          devOutput.partition}`,
                      )
                      consolidatedDevOutput.push(
                        '----------------------------------------------------------------------',
                      )
                      const parseddevoutput = this.TroubleShootService.parseTroubleShootOutput(
                        devOutput.devCmdOutput,
                      )
                      consolidatedDevOutput = consolidatedDevOutput.concat(
                        parseddevoutput,
                      )
                      consolidatedDevOutput.push(
                        '----------------------------------------------------------------------',
                      )
                    })}
                  <div className={`${styles.disabled} ${styles.codeEditorDiv}`}>
                    <A10AflexCodeEditor
                      id={''}
                      value={consolidatedDevOutput.join('\r\n')}
                      options={{ lineNumbers: true, readOnly: 'nocursor' }}
                      style={{ height: 350 }}
                    />
                  </div>
                </div>
              </A10Col>
            </A10Row>
          ) : null}
          <hr className={styles.deviceCliSeparator} />
          {/* Run Command History */}
          <A10Row className={`${styles.runCmdSections}`}>
            <A10Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <A10Collapse
                className={styles.cdpPanel}
                defaultActiveKey="1"
                bordered={false}
              >
                <A10Collapse.Panel
                  key="1"
                  className="collapsePanel"
                  header={
                    <div className="row">
                      <div className={`col-md-6 ${styles.sectionHeader}`}>
                        <span
                          style={{
                            marginTop: '3px',
                          }}
                          className={styles.sectionHeaderText}
                        >
                          {`Run Command History${
                                                  cmdHistoryCnt > this.historyMaxCnt
                                                    ? ` (Top ${this.historyMaxCnt})`
                                                    : ''
                                                }`}
                        </span>
                        <RoundNumber
                          style={{ top: '-3px' }}
                          number={
                            filteredCmdHistory ? filteredCmdHistory.length : 0
                          }
                        />
                      </div>
                      <div className={`col-md-6 ${styles.cmdFilterSel}`}>
                        <A10Button
                          className="action-button"
                          onClick={event => {
                            this.getCommandExecutionHistory()
                            event.stopPropagation()
                          }}
                        >
                          <A10Icon
                            app="global"
                            type="refresh"
                            className="action-icon"
                          />
                          Refresh
                        </A10Button>
                        <div
                          className="pull-right"
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.stopPropagation()
                          }}
                        >
                          <A10Select
                            size="default"
                            style={{ width: '250px', marginRight: '25px' }}
                            title={selCmdSnippet && selCmdSnippet.name}
                            onChange={this.handleFilterChange.bind(this)}
                            placeholder="Filter by CLI Snippet Name"
                            allowClear={true}
                            value={
                              !!selCmdSnippet
                                ? JSON.stringify(selCmdSnippet)
                                : undefined
                            }
                             showSearch={true}
                             onSearch={this.cmdSnippetFilter}
                          >
                            {filteredCmdSnippet &&
                              filteredCmdSnippet.map(
                                (cmdSnippet: any, i: number) => {
                                  return (
                                    <A10Select.Option
                                      key={'cmdSnippet-' + i}
                                      value={JSON.stringify(cmdSnippet)}
                                      title={cmdSnippet.name}
                                    >
                                      {cmdSnippet.name}
                                    </A10Select.Option>
                                  )
                                },
                              )}
                          </A10Select>
                        </div>
                      </div>
                    </div>
                  }
                >
                  {troubleshootHistoryTableLoaded ? (
                    <div className={styles.historyTable}>
                      <Table
                        name="Troubleshoot"
                        data={filteredCmdHistory || []}
                      />
                    </div>
                  ) : (
                    <div>
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    </div>
                  )}
                </A10Collapse.Panel>
              </A10Collapse>
            </A10Col>
          </A10Row>
        </div>

        <SlidingWorkflowStatusList
          isOpen={
            this.state.workflowSliderState &&
            this.state.workflowIDList.length > 0
          }
          title={'Device Command Troubleshoot Workflow Status'}
          objectList={this.state.workflowObjectList}
          onRequestClose={this.handleSlidingWorkflowOnCancel}
          idList={this.state.workflowIDList}
        />

        <SlidingDeviceCmdForms
          showSlidingPage={showSlidingPage}
          updateState={this.updateState}
          cmdSnippets={cmdSnippets}
          runCmdScheduleTasks={runCmdScheduleTasks}
          runCmdSchedule={selectedObj}
          editTask={editTask}
          isPeriodicAllowed={isPeriodicAllowed}
          onViewWorkflowStatus={this.onViewWorkflowStatus}
          refreshScheduleTasks={this.getScheduledTasks}
          tenantDetail={tenantDetail}
          devicesDetail={devicesDetail}
        />

        <UpgradeModal
          content={`You are using a license which only allows ${maxSnippets} CLI Snippets. For questions or upgrading your license please contact A10 Networks Customer Support or Sales.`}
          visible={showUpgrade}
          onClose={() => {
            this.setState({ showUpgrade: false })
          }}
        />
        <ActivateLicense
          content={'Please add a valid license to your account'}
          visible={showAddLicense}
          onClose={() => {
            this.setState({ showAddLicense: false })
          }}
        />
      </div>
    )
  }
}

export default setupA10Container(RunCommands)
