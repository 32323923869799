import { getNS, IHTTPRequestOptions } from '@gui-libraries/framework'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'

const HPCAPI_PREFIX = '/hpcapi/v3'
const getProvider = () => storage.get.PROVIDER

export const getAflexList = (
  tenant: string,
  search: string,
  logicalPartition: string,
): IHTTPRequestOptions => {
  return {
    namespace: getNS('AFLEX_LIST'),
    request: async (epicDependence: IObject) => {
      try {
        const provider = getProvider()
        let actionURL = `${HPCAPI_PREFIX}/provider/${provider}/tenant/${tenant}/logical-partition/${logicalPartition}/file/aflex`
        if (search) {
          actionURL += `?name=${search}`
        }
        const { data: res } = await httpClient.get(actionURL)
        const aflexList = res['aflex-list'] || []
        // for (const aflex of aflexList) {
        //   try {
        //     const { data: resp } = await httpClient.get(
        //       encodeURI(
        //         `/hrcapi/v3/provider/${getItem(
        //           'PROVIDER',
        //         )}/tenant/${tenant}/logical-partition/${logicalPartition}/file/aflex/${aflex.name}`,
        //       ),
        //     )
        //     const referredList = resp['referrer-list'] || []
        //     aflex.object_count = referredList.length
        //   } catch (err) {
        //     console.log(err)
        //   }
        // }
        return aflexList
      } catch (err) {
        // tslint:disable-next-line:no-console
        console.error(err)
      }
      return []
    },
  }
}
