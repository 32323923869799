import { A10Storage } from '@gui-libraries/framework'

const storageData = {
  PROVIDER: 'root',
  USER_SESSION_AUTHTYPE: '',
  CURRENT_STATE: '',
  TENANT: '',
  USER_SESSION_ID: '',
  USER_ID: '',
  PROVIDER_ID: '',
  TARGET_URL: '',
  PROVIDER_PATH: '',
  LOCAL_USER: '',
  ENCODED_SESSION_ID: '',
  ADMIN_LEVEL: '',
  CURRENT_SUB_STATE: '',
  LAST_CLICKED_TIMESTAMP: '',
  IS_MFA_ENABLE: '',
  tenant: '',
  'object.explorer.tenant.text': '',
  'object.explorer.partition.name': '',
  'object.explorer.lp.name': '',
  AUTH: '',
  DASHBOARD_PARENT_TYPE: '',
  API: '',
  'object.explorer.cluster.name': '',
  'object.explorer.device.text': '',
  DRILL_LEVEL: '',
  SESSION_TIMEOUT_INITIAL: 0,
  SESSION_TIMEOUT: 0,
  DASHBOARD_CAPACITY: 0,
  'object.explorer.cluster.index': 0,
  'object.explorer.device.index': 0,
  LOG_RETENTION: 0,
  ALERT_LAST_VIEWED_TIMESTAMP: 0,
  WORKFLOW_LAST_VIEWED_TIMESTAMP: 0,
  IS_SUPER_USER: false,
  IS_INFRA_USER: false,
  IS_CERT_USER: false,
  EULA_AFTER_LOGIN_SHOWN: false,
  LASTALERT: false,
  IS_OPERATOR_USER: false,
  PERSIST_GETTING_STARTED: false,
  DEVICES_EXISTS: false,
  GETTING_STARTED_SHOWN: false,
  HELP_TEXTS: {} as IObject,
  CURRENT_TENANT: {
    name: '',
    uuid: '',
  } as Storage.ITenant,
  CURRENT_CLUSTER: {
    name: '',
  } as Storage.ICluster,
  'object.explorer.lp.detail': {
    name: '',
  } as IObject,
  'object.explorer.partition.detail': {} as IObject,
  DASHBOARD_CURRENT_CLUSTER: {} as IObject,
  'KEY-NAME-LIST': {} as IObject,
  CURRENT_LP_SVC: {} as IObject,
  CURRENT_LP: {} as IObject,
  ALLTENANTS: [] as Storage.ITenant[],
  AVAILABLETENANTS: [] as IObject[],
  READTENANTS: [] as IObject[],
  ALLCLUSTERS: [] as Storage.ICluster[],
  ALLDEVICES: [] as Storage.IDevice[],
  APP_SERVICES: [] as IObject[],
  LADC_CREDENTIALS: [] as IObject[],
  ALL_USERS: [] as IObject[],
  PROVIDER_CLUSTERS: [] as IObject[],
  ALL_DATA: [] as IObject[],
  ALL_REPORT_DATA: [] as IObject[],
  ALL_SCHEDULE_REPORT_DATA: [] as IObject[],
  ALL_LICENSE: [] as IObject[],

  USER_NAME: '',
  ACTIVATION_TOKEN: '',
  USER_EMAIL_ID: '',
  LOGGED_IN: false,
  IS_PARTITION_USER: false,
  JUST_LOGIN: false,
  SUPER_ADMIN_MODE: false,
  CURRENT_DEVICE: {} as Storage.IDevice,
  CONTEXT_LEVEL: '',
  CONTEXT: '',
  DGF_SELECTED_TENANT: '',
  DGF_ACCOUNTS: '',
  SELECTEDCONTEXT: '',
  DASHBOARD_CLUSTER: '',
  CURRENTTENANT: {} as IObject,

  // unreasonable
  activeSlide: '',
  modalSize: '',
  USER_ROLES: '',
}

const storage = new A10Storage<typeof storageData>(storageData)
export default storage

export const getList = () => {
  return ALL_TEMPLATES
}

export const ALL_TEMPLATES: IObject = {
  vip: {
    logging: '',
    'virtual-server': '',
    // scaleout: '',
    policy: '',
  },
  services: {
    'tcp-proxy-client': '',
    'tcp-proxy-server': '',
    'tcp-proxy': '',
    // scaleout: '',
    'file-inspection': '',
    'virtual-port': '',
    'virtual-server': '',
    ftp: '',
  },
  protocols: {
    'virtual-port': '',
    // smtp: '',
    // diameter: '',
    cache: '',
    // fix: '',
    // ssli: '',
    udp: '',
    // 'udp-shared': '',
    tcp: '',
    'tcp-proxy': '',
    // 'tcp-shared': '',
    'server-ssl': '',
    // 'server-ssl-shared': '',
    'client-ssl': '',
    // 'client-ssl-shared': '',
    dns: '',
    http: '',
    policy: '',
    'http-policy': '',
    // 'http-shared': '',
    // sip: '',
    // smpp: '',
    // dblb: '',
    // 'reqmod-icap': '',
    // 'respmod-icap': '',
    'persist.source-ip': '',
    'persist.destination-ip': '',
    'persist.ssl-sid': '',
    'persist.cookie': '',
  },
  policies: {
    'connection-reuse': '',
    policy: '',
    'http-policy': '',
    'dynamic-service': '',
    'external-service': '',
  },
  servicegroup: {
    port: '',
    server: '',
    policy: '',
  },
}

export const pageTabMapping: IObject = {
  vip: 'ObjectExplorer',
  vport: 'ObjectExplorer',
  server: 'ObjectExplorer',
  service_group: 'ObjectExplorer',
  template: 'SharedObjects',
  aflex: 'SharedObjects',
  cert: 'SharedObjects',
  crl: 'SharedObjects',
  'ip-nat': 'SharedObjects',
  'health-monitor': 'SharedObjects',
  vrid: 'SharedObjects',
}

export const vPortMapping: IObject = {
  tcp: [
    'dynamic-service',
    'persist.destination-ip',
    'persist.source-ip',
    'persist.ssl-sid',
    'policy',
    // 'scaleout',
    'tcp',
    'virtual-port',
  ],
  udp: [
    'dns',
    'dynamic-service',
    'persist.destination-ip',
    'persist.source-ip',
    'policy',
    // 'scaleout',
    'udp',
    'virtual-port',
  ],
  others: [
    'dynamic-service',
    'persist.destination-ip',
    'persist.source-ip',
    'policy',
    // 'scaleout',
    'tcp',
    'udp',
    'virtual-port',
  ],
  'dns-tcp': [
    'dns',
    'dynamic-service',
    'persist.destination-ip',
    'persist.source-ip',
    'policy',
    // 'scaleout',
    'tcp-proxy',
    'virtual-port',
  ],
  'dns-udp': [
    'dns',
    'dynamic-service',
    'persist.destination-ip',
    'persist.source-ip',
    'policy',
    // 'scaleout',
    'udp',
    'virtual-port',
  ],
  'fast-http': [
    'connection-reuse',
    'dynamic-service',
    'http',
    'persist.cookie',
    'persist.destination-ip',
    'persist.source-ip',
    'policy',
    // 'scaleout',
    'tcp',
    'virtual-port',
  ],
  http: [
    'cache',
    'client-ssl',
    'connection-reuse',
    'dynamic-service',
    'http',
    'http-policy',
    'persist.cookie',
    'persist.destination-ip',
    'persist.source-ip',
    'policy',
    // 'scaleout',
    'server-ssl',
    'tcp-proxy',
    'virtual-port',
  ],
  https: [
    'cache',
    'client-ssl',
    'connection-reuse',
    'dynamic-service',
    'http',
    'http-policy',
    'persist.cookie',
    'persist.destination-ip',
    'persist.source-ip',
    'policy',
    // 'scaleout',
    'server-ssl',
    'tcp-proxy',
    'virtual-port',
  ],
  http2: [
    'client-ssl',
    'connection-reuse',
    'dynamic-service',
    'http',
    'http-policy',
    'persist.cookie',
    'persist.destination-ip',
    'persist.source-ip',
    'policy',
    // 'scaleout',
    'server-ssl',
    'tcp',
    'tcp-proxy',
    'udp',
    'virtual-port',
  ],
  http2s: [
    'client-ssl',
    'connection-reuse',
    'dynamic-service',
    'http',
    'http-policy',
    'persist.cookie',
    'persist.destination-ip',
    'persist.source-ip',
    'policy',
    // 'scaleout',
    'server-ssl',
    'tcp',
    'tcp-proxy',
    'udp',
    'virtual-port',
  ],
  mlb: [
    'dynamic-service',
    'persist.destination-ip',
    'persist.source-ip',
    'policy',
    // 'scaleout',
    'tcp-proxy',
    'virtual-port',
  ],
  mms: [
    'dynamic-service',
    'persist.destination-ip',
    'persist.source-ip',
    'policy',
    // 'scaleout',
    'tcp',
    'virtual-port',
  ],
  mysql: [
    'client-ssl',
    'dynamic-service',
    'persist.destination-ip',
    'persist.source-ip',
    // 'scaleout',
    'server-ssl',
    'tcp-proxy',
    'virtual-port',
  ],
  mssql: [
    'client-ssl',
    'dynamic-service',
    'persist.destination-ip',
    'persist.source-ip',
    // 'scaleout',
    'server-ssl',
    'tcp-proxy',
    'virtual-port',
  ],
  pop3: [
    'client-ssl',
    'dynamic-service',
    'persist.cookie',
    'persist.destination-ip',
    'persist.source-ip',
    'policy',
    // 'scaleout',
    'tcp-proxy',
    'virtual-port',
  ],
  rtsp: [
    'dynamic-service',
    'persist.destination-ip',
    'persist.source-ip',
    'policy',
    'tcp',
    'virtual-port',
  ],
  'ssl-proxy': [
    'client-ssl',
    'dynamic-service',
    'persist.destination-ip',
    'persist.source-ip',
    'policy',
    // 'scaleout',
    'server-ssl',
    'tcp-proxy',
    'virtual-port',
  ],
  'tcp-proxy': [
    'dynamic-service',
    'persist.destination-ip',
    'persist.source-ip',
    'policy',
    // 'scaleout',
    'server-ssl',
    'tcp-proxy',
    'virtual-port',
  ],
}

export const templateDefinition: IObject = {
  cache: 'RAM caching template',
  cipher: 'SSL Cipher template',
  'client-ssl': 'Client SSL template',
  'connection-reuse': 'Connection Reuse template',
  dns: 'DNS template',
  'dynamic-service': 'Dynamic service template',
  http: 'HTTP template',
  'http-policy': 'http-policy template',
  logging: 'Logging template',
  monitor: 'Monitor template',
  'persist.cookie': 'Persist Cookie template',
  'persist.destination-ip': 'Persist Dest IP template',
  'persist.source-ip': 'Persist Src IP template',
  'persist.ssl-sid': 'Persist SSL ID template',
  policy: 'Policy template',
  port: 'Port template',
  server: 'Server template',
  'server-ssl': 'Server Side SSL template',
  tcp: 'L4 TCP switch config',
  'tcp-proxy': 'TCP Proxy',
  udp: 'L4 UDP switch config',
  'virtual-port': 'Virtual port template',
  'virtual-server': 'Virtual server template',
  scaleout: 'Scaleout template',
}

export const templateCategoryMap: IObject = {
  'slb.template.virtual-server': 'ADC',
  'slb.template.virtual-port': 'ADC',
  'slb.template.server': 'ADC',
  'slb.template.port': 'ADC',
  'slb.template.cache': 'ADC',
  'slb.template.connection-reuse': 'ADC',
  'slb.template.dynamic-service': 'ADC',
  'slb.template.persist.cookie': 'ADC',
  'slb.template.persist.destination-ip': 'ADC',
  'slb.template.persist.source-ip': 'ADC',
  'slb.template.persist.ssl-sid': 'ADC',
  'slb.template.http': 'Apps',
  'slb.template.http-policy': 'Apps',
  'slb.template.dns': 'Apps',
  'slb.template.cipher': 'SSL',
  'slb.template.client-ssl': 'SSL',
  'slb.template.server-ssl': 'SSL',
  'slb.template.tcp-proxy': 'SSLi',
  'slb.template.logging': 'System',
  'ip.nat.template.logging': 'System',
  'slb.template.monitor': 'System',
  'slb.template.tcp': 'Protocol',
  'slb.template.udp': 'Protocol',
  'slb.template.policy': 'Policy',
}

export const algoMap: IObject = {
  '1.2.840.113549.2.1': 'MD2',
  '1.2.840.113549.1.1.2': 'MD2 with RSA',
  '1.2.840.113549.2.5': 'MD5',
  '1.2.840.113549.1.1.4': 'MD5 with RSA',
  '1.3.14.3.2.26': 'SHA1',
  '1.2.840.10040.4.3': 'SHA1 with DSA',
  '1.2.840.10045.4.1': 'SHA1 with ECDSA',
  '1.2.840.113549.1.1.5': 'SHA1 with RSA',
  '2.16.840.1.101.3.4.2.4': 'SHA224',
  '1.2.840.113549.1.1.14': 'SHA224 with RSA',
  '2.16.840.1.101.3.4.2.1': 'SHA256',
  '1.2.840.113549.1.1.11': 'SHA256 with RSA',
  '2.16.840.1.101.3.4.2.2': 'SHA384',
  '1.2.840.113549.1.1.12': 'SHA384 with RSA',
  '2.16.840.1.101.3.4.2.3': 'SHA512',
  '1.2.840.113549.1.1.13': 'SHA512 with RSA',
}

export const healthStatusMap: IObject = {
  'All Up': { css: 'ongoing', display: 'Up' },
  Up: { css: 'ongoing', display: 'Up' },
  'Functional Up': { css: 'warning', display: 'Functionally Up' },
  Maintenance: { css: 'warning', display: 'In Maintenance' },
  Disabled: { css: 'stopped', display: 'Disabled' },
  Down: { css: 'stopped', display: 'Down' },
  Unkn: { css: 'undefined', display: 'Unknown' },
}

export const severityMap: IObject[] = [
  { color: '#e57373', definition: 'Emergency' }, // 0
  { color: '#e57373', definition: 'Alert' }, // 1
  { color: '#e57373', definition: 'Critical' }, // 2
  { color: '#e57373', definition: 'Error' }, // 3
  { color: '#ffb74d', definition: 'Warning' }, // 4
  { color: '#81c784', definition: 'Notification' }, // 5
  { color: '#64b5f6', definition: 'Info' }, // 6
  { color: '#ffe168', definition: 'Debug' }, // 7
]

export const priorityMap: IObject = {
  0: { color: '#e57373', definition: 'Emergency' },
  1: { color: '#e57373', definition: 'Alert' },
  2: { color: '#e57373', definition: 'Critical' },
  3: { color: '#e57373', definition: 'Error' },
  4: { color: '#ffb74d', definition: 'Warning' },
  5: { color: '#81c784', definition: 'Notification' },
  6: { color: '#64b5f6', definition: 'Info' },
  7: { color: '#ffe168', definition: 'Debug' },
}

export const workflowStatusMapping = {
  COMPLETED: 'Completed',
  COMPLETED_WITH_ERRORS: 'Completed with errors',
  IN_PROGRESS: 'In Progress',
  RUNNING: 'In Progress',
  SCHEDULED: 'Scheduled',
  FAILED: 'Failed',
  FAILED_WITH_TERMINAL_ERROR: 'Failed with terminal error',
  CANCELLED: 'Cancelled',
  CANCELED: 'Cancelled',
  TIMED_OUT: 'Timed out',
  SKIPPED: 'Skipped',
}

export const adminLevel = {
  provider: 0,
  tenant: 1,
  lp: 2,
}

export const saveUUIDDefinition = (data: IObject) => {
  // only save tenant cluster, device, partition uuid name mapping
  const keyList = storage.get['KEY-NAME-LIST']
  if (data['cluster-list']) {
    // get cluster
    for (const cluster of data['cluster-list']) {
      keyList[cluster.uuid] = {
        name: cluster.name,
        'display-name': cluster['display-name'] || cluster.name,
        type: 'cluster',
      }
      if (cluster['device-list']) {
        for (const device of cluster['device-list']) {
          keyList[device.uuid] = {
            name: device.name,
            'display-name': device['display-name'] || device.name,
            type: 'device',
          }
        }
      }
      if (cluster['partition-list']) {
        for (const partition of cluster['partition-list']) {
          keyList[partition.uuid] = {
            name: partition.name,
            'display-name': partition['display-name'] || partition.name,
            type: 'cluster-partition',
          }
        }
      }
    }
  } else if (data['device-list']) {
    // get device
    for (const device of data['device-list']) {
      keyList[device.uuid] = {
        name: device.name,
        'display-name': device['display-name'] || device.name,
        type: 'device',
      }
      if (device['partition-list']) {
        for (const partition of device['partition-list']) {
          keyList[partition.uuid] = {
            name: partition.name,
            'display-name': partition['display-name'] || partition.name,
            type: 'device-partition',
            device: device.name,
          }
        }
      }
    }
  } else if (data['tenant-list']) {
    // get tenant
    for (const tenant of data['tenant-list']) {
      keyList[tenant.uuid] = {
        name: tenant.name,
        'display-name': tenant['display-name'] || tenant.name,
        type: 'tenant',
      }
      if (tenant['logical-partition-list']) {
        for (const partition of tenant['logical-partition-list']) {
          keyList[partition.uuid] = {
            name: partition.name,
            'display-name': partition['display-name'] || partition.name,
            type: 'logical-partition',
          }
        }
      }
    }
  } else if (data['logical-partition-list']) {
    for (const partition of data['logical-partition-list']) {
      keyList[partition.uuid] = {
        name: partition.name,
        'display-name': partition['display-name'] || partition.name,
        type: 'logical-partition',
      }
    }
  } else if (data.cluster) {
    keyList[data.cluster.uuid] = {
      name: data.cluster.name,
      'display-name': data.cluster['display-name'] || data.cluster.name,
      type: 'cluster',
    }
    if (data.cluster['device-list']) {
      for (const device of data.cluster['device-list']) {
        keyList[device.uuid] = {
          name: device.name,
          'display-name': device['display-name'] || device.name,
          type: 'device',
        }
      }
    }
    if (data.cluster['partition-list']) {
      for (const partition of data.cluster['partition-list']) {
        keyList[partition.uuid] = {
          name: partition.name,
          'display-name': partition['display-name'] || partition.name,
          type: 'cluster-partition',
        }
      }
    }
  } else if (data.device) {
    keyList[data.device.uuid] = {
      name: data.device.name,
      'display-name': data.device['display-name'] || data.device.name,
      type: 'device',
    }
    if (data.device['partition-list']) {
      for (const partition of data.device['partition-list']) {
        keyList[partition.uuid] = {
          name: partition.name,
          'display-name': partition['display-name'] || partition.name,
          type: 'device-partition',
          device: data.device.name,
        }
      }
    }
  } else if (data.partition) {
    const a10URL: string = data.partition['a10-url'] || ''
    if (/^\/hpcapi\/v3\/provider\/\w*\/cluster\//.test(a10URL)) {
      keyList[data.partition.uuid] = {
        name: data.partition.name,
        'display-name': data.partition['display-name'] || data.partition.name,
        type: 'cluster-partition',
      }
    } else if (/^\/hpcapi\/v3\/provider\/\w*\/device\//.test(a10URL)) {
      keyList[data.partition.uuid] = {
        name: data.partition.name,
        'display-name': data.partition['display-name'] || data.partition.name,
        type: 'device-partition',
      }

      const matchRegExp = /device\/(.+)\/partition/
      const matchResult = a10URL.match(matchRegExp)
      if (matchResult && matchResult[1]) {
        keyList[data.partition.uuid].device = matchResult[1]
      }
    }
  } else if (data['logical-partition']) {
    keyList[data['logical-partition'].uuid] = {
      name: data['logical-partition'].name,
      'display-name':
        data['logical-partition']['display-name'] ||
        data['logical-partition'].name,
      type: 'logical-partition',
    }
  }
  storage.set['KEY-NAME-LIST'](keyList)
}

export const rbaUrlsDefinitions = {
  // provider
  '/provider/?/*': {
    providerPerm: 'authentication',
    otherPerm: 'configDoc',
    sharedObjPerm: ['aflex', 'cert', 'crl', 'key'],
  },
  '/provider/?/tenant': { providerPerm: 'tenant' },
  '/provider/?/users': { providerPerm: 'user' },
  '/provider/?/role': { providerPerm: 'role' },
  '/hcms-rba/role-class': { providerPerm: 'roleClass' },
  '/provider/?/auth': { providerPerm: 'authentication' },
  '/provider/?/authentication-provider': { providerPerm: 'authentication' },
  '/provider/?/users/*': { providerPerm: ['credential', 'user'] },
  '/hls/licenses/providers/?': { providerPerm: 'licensing' },
  '/provider/?/hwcapi/v1/workflow': { providerPerm: 'workflow' },
  '/provider/?/hwcapi/v1/workflow/*': { providerPerm: 'restartWorkflow' },
  '/hcuisapi/provider/?/*': { providerPerm: 'report' },
  '/hcuisapi/provider/?/tenant/*': { providerPerm: 'report' },
  '/hcuisapi/provider/?/tenant/?/report': { providerPerm: 'report' },

  '/provider/?/apps': { providerPerm: 'app' },
  '/providers/?/apps': { providerPerm: 'app' },
  '/provider/?/apps/*': { providerPerm: ['app', 'validateApp'] },
  '/providers/?/apps/*': { providerPerm: ['app', 'validateApp'] },
  '/provider/?/apps/validate': { providerPerm: 'validateApp' },
  '/providers/?/apps/validate': { providerPerm: 'validateApp' },
  // infra
  '/provider/?/cluster': { infraPerm: 'cluster' },
  '/provider/?/cluster/?/partition': { infraPerm: 'clusterPartition' },
  '/provider/?/cluster/*': { infraPerm: 'clusterPartition' },
  '/hocapi/v1/provider/?/cluster/*': {
    infraPerm: [
      'clusterProvision',
      'clusterScan',
      'clusterUpgrade',
      'deletePartition',
      'clusterPartition',
    ],
  },
  '/hocapi/v1/provider/?/device/*': {
    infraPerm: [
      'deviceBackup',
      'deviceRestore',
      'deviceReboot',
      'deviceRegister',
      'deviceDeregister',
    ],
  },
  '/hocapi/v1/provider/?/cluster/?/_provision': {
    infraPerm: 'clusterProvision',
  },
  '/hocapi/v1/provider/?/device/?/backup': { infraPerm: 'deviceBackup' },
  '/hocapi/v1/provider/?/device/?/_restore': { infraPerm: 'deviceRestore' },
  '/hocapi/v1/provider/?/cluster/?/_scan': { infraPerm: 'clusterScan' },
  '/hocapi/v1/provider/?/device/?/_reboot': { infraPerm: 'deviceReboot' },
  '/hocapi/v1/provider/?/cluster/?/_upgrade': { infraPerm: 'clusterUpgrade' },
  '/hocapi/v1/provider/?/cluster/?/partition/_delete_partitions': {
    infraPerm: 'deletePartition',
  },
  '/provider/?/hocapi/v1/workflow/device-registration': {
    infraPerm: 'deviceRegister',
  },
  '/hocapi/v1/provider/?/device/?/_deregister': {
    infraPerm: 'deviceDeregister',
  },
  '/provider/?/device': { infraPerm: 'device' },
  // tenant
  '/provider/?/tenant/*': {
    tenantPerm: 'logicalPartition',
    analyticPerm: ['thunderADC', 'ladc', 'lads', 'config', 'event'],
    sharedObjPerm: ['aflex', 'cert', 'crl', 'key'],
    otherPerm: 'configDoc',
    ladcPerm: ['hosts', 'cspcluster'],
  },
  '/provider/?/tenant/?/logical-partition': { tenantPerm: 'logicalPartition' },
  // shared
  '/provider/?/tenant/pso/logical-partition/pso/*': {
    sharedObjPerm: ['aflex', 'cert', 'crl', 'key'],
  },
  '/provider/?/tenant/pso/logical-partition/pso/file/aflex': {
    sharedObjPerm: 'aflex',
  },
  '/provider/?/tenant/pso/logical-partition/pso/file/ssl-cert': {
    sharedObjPerm: 'cert',
  },
  '/provider/?/tenant/pso/logical-partition/pso/file/ssl-crl': {
    sharedObjPerm: 'crl',
  },
  '/provider/?/tenant/pso/logical-partition/pso/file/ssl-key': {
    sharedObjPerm: 'key',
  },
  // analytic
  '/provider/?/tenant/?/analytics/thunder-adc/*': {
    analyticPerm: 'thunderADC',
  },
  '/provider/?/analytics/*': {
    analyticPerm: ['thunderADC', 'ladc', 'lads', 'config', 'event'],
  },
  '/provider/?/analytics/ladc/*': { analyticPerm: 'ladc' },
  '/provider/?/analytics/lads/*': { analyticPerm: 'lads' },
  '/provider/?/analytics/configuration/*': { analyticPerm: 'config' },
  '/provider/?/analytics/event/hc_event': { analyticPerm: 'event' },
  '/provider/?/hocapi/v1/workflow/*': {
    otherPerm: ['hocWorkflow', 'wfDeviceReg', 'wfDeviceDereg'],
  },
  '/provider/?/datastore/object-workflow-map': {
    otherPerm: 'objectWorkflowMap',
  },
  '/provider/?/tenant/?/configdoc': { otherPerm: 'configDoc' },
  '/provider/?/scheduler/v1/*': { otherPerm: 'scheduler' },
  '/provider/?/helptexts/AppcitoHelpTexts': { otherPerm: 'helpTexts' },
  '/provider/?/sessions': { otherPerm: 'sessions' },
  '/provider/?/sessions/jwt/generate': { otherPerm: 'generateJWT' },
  '/provider/?/schema-registry/subjects/*': { otherPerm: 'schemaReg' },
  '/provider/?/schema-registry/*': { otherPerm: 'schemaReg' },

  // ladc
  '/provider/?/tenant/?/applications/?/hosts/*': { ladcPerm: 'hosts' },
  '/provider/?/tenant/?/applications/?/cspcluster/*': {
    ladcPerm: 'cspcluster',
  },

  // '/provider/?/tenant  deploy'
}

export const cannedRoleDisplayName = {
  all_service_admin: 'All Services',
  all_service_operator: 'Services Operator',
  hc_app_admin: 'App Admin',
  hc_app_operator: 'App Operator',
  hc_certificate_admin: 'Certificate Admin',
  hc_infra_admin: 'Infrastructure Admin',
  hc_provider_admin: 'Provider Admin',
  hc_tenant_admin: 'Tenant Admin',
}

export const schemasWithPassword = new Set([
  // slb object
  'health.monitor',
  'slb.template.client-ssl',
  'slb.template.server-ssl',
  'slb.template.persist.cookie',
  // aam
  'aam.authentication.account.kerberos-spn',
  'aam.authentication.jwt',
  'aam.authentication.relay.kerberos.instance',
  'aam.authentication.server.ldap.instance',
  'aam.authentication.server.radius.instance',
  'aam.jwt-authorization',
  // admin
  'admin.password',
  'admin',
  // cgn
  'cgnv6.lsn.radius.server',
  'cgnv6.template.http-alg',
  'cgnv6.template.logging',
  // config
  'configure.sync',
  // fw
  'fw.radius.server',
  'ntp.auth-key',
  'radius-server.host.ipv4',
  'radius-server.host.ipv6',
  'radius-server.host.name',
  'smtp',
  'snmp-server.snmpv1-v2c.user',
  'snmp-server.snmpv3.user',
  'snmp-server.user',
  'system.radius.server',
  'tacacs-server.host.ipv4',
  'tacacs-server.host.ipv6',
  'tacacs-server.host.tacacs-hostname',
  'threat-intel.threat-feed',
  'vpn.ike-gateway',
  'web-category.proxy-server',
])
