export class DropdownConstants {
  TRIGGERS = {
    objectType: [
      {
        key: 'partition',
        label: 'Partition',
        value: 'partition',
        default: true,
      },
      {
        key: 'type',
        label: 'Type',
        value: 'type',
      },
    ],
    windowType: [
      {
        key: 'length',
        label: 'over last N samples',
        value: 'length',
        default: true,
      },
      {
        key: 'lengthBatch',
        label: 'over intervals N samples',
        value: 'lengthBatch',
      },
      {
        key: 'time',
        label: 'over last T mins',
        value: 'time',
      },
      {
        key: 'timeBatch',
        label: 'over every T mins intervals',
        value: 'timeBatch',
      },
      {
        key: 'timeLength',
        label: 'N samples max during last T mins',
        value: 'timeLength',
      },
    ],
    metricChartAgg: [
      {
        key: 'avg',
        label: 'Avg',
        value: 'avg',
        default: true,
      },
      {
        key: 'min',
        label: 'Always',
        value: 'min',
      },
    ],
    conditionOperator: [
      {
        key: 'gt',
        label: 'Greater than',
        value: 'gt',
        default: true,
      },
      {
        key: 'lt',
        label: 'Less than',
        value: 'lt',
      },
    ],
    metricAgg: [
      {
        key: 'avg',
        label: 'Avg',
        value: 'avg',
        default: true,
      },
      {
        key: 'sum',
        label: 'Sum',
        value: 'sum',
      },
      {
        key: 'max',
        label: 'Max',
        value: 'max',
      },
      {
        key: 'min',
        label: 'Min',
        value: 'min',
      },
      {
        key: 'exponentialAvg',
        label: 'Exponential Avg',
        value: 'exponentialAvg',
        onlyOne: true,
      },
      {
        key: 'weightedAvg',
        label: 'Weighted Avg',
        value: 'weightedAvg',
        onlyOne: true,
      },
      // {
      //   key: 'count',
      //   label: 'Count',
      //   value: 'count',
      // },
      // {
      //   key: 'distinct count',
      //   label: 'Distinct Count',
      //   value: 'distinct count',
      // },
    ],
    strMetricAgg: [
      {
        key: 'strContains',
        label: 'String Contains',
        value: 'strContains',
        default: true,
        displayLabel: 'contains',
      },
      {
        key: 'strEqualsIgnoreCase',
        label: 'String Equals (Ignore Case)',
        value: 'strEqualsIgnoreCase',
        displayLabel: 'equals',
      },
      {
        key: 'strCompare',
        label: 'String Compare',
        value: 'strCompare',
        displayLabel: 'compares',
      },
    ],
    windowTimeParam: [
      {
        key: 'min',
        label: 'Min',
        value: 'min',
        default: true,
      },
      {
        key: 'sec',
        label: 'Sec',
        value: 'sec',
      },
    ],
    params: [],
    operation: [
      {
        key: 'sum',
        label: 'Sum',
        value: 'sum',
        default: true,
      },
    ],
    operator: [
      {
        key: 'gt',
        label: 'Greater than',
        value: 'gt',
        default: true,
      },
      {
        key: 'lt',
        label: 'Less than',
        value: 'lt',
      },
      {
        key: 'eq',
        label: 'Equals',
        value: 'eq',
      },
      {
        key: 'gteq',
        label: 'Greater than or equals',
        value: 'gteq',
      },
      {
        key: 'lteq',
        label: 'Less than or equals',
        value: 'lteq',
      },
    ],
    strOperator: [
      {
        key: undefined,
        label: '',
        value: undefined,
        default: true,
      },
    ],

    condition: [
      {
        key: 'and',
        label: 'AND',
        value: 'and',
        default: true,
      },
      {
        key: 'or',
        label: 'OR',
        value: 'or',
      },
    ],
  }
  ALERTS = {
    objectType: [
      {
        key: 'partition',
        label: 'Partition',
        value: 'partition',
        default: true,
      },
      {
        key: 'type',
        label: 'Type',
        value: 'type',
      },
    ],
    metricAgg: [
      {
        key: 'average',
        label: 'AVG',
        value: 'average',
        default: true,
      },
      {
        key: 'sum',
        label: 'SUM',
        value: 'sum',
      },
    ],
    metric: [
      {
        key: 'controlCPU',
        label: 'Control CPU',
        value: 'controlCPU',
        default: true,
      },
      {
        key: 'dataCPU',
        label: 'Data CPU',
        value: 'dataCPU',
      },
    ],
    condition: [
      {
        key: 'greaterThan',
        label: 'Greater than',
        value: 'greaterThan',
        default: true,
      },
      {
        key: 'lessThan',
        label: 'Less than',
        value: 'lessThan',
      },
    ],
    priority: [
      {
        key: 'emergency',
        label: 'Emergency',
        value: 0,
        type: 'stopped',
        default: true,
      },
      {
        key: 'alert',
        label: 'Alert',
        value: 1,
        type: 'stopped',
      },
      {
        key: 'critical',
        label: 'Critical',
        value: 2,
        type: 'stopped',
      },
      {
        key: 'error',
        label: 'Error',
        value: 3,
        type: 'stopped',
      },
      {
        key: 'warning',
        label: 'Warning',
        value: 4,
        type: 'warning',
      },
      {
        key: 'notification',
        label: 'Notification',
        value: 5,
        type: 'ongoing',
      },
      {
        key: 'info',
        label: 'Informational',
        value: 6,
        type: 'info',
      },
      {
        key: 'debug',
        label: 'Debug',
        value: 7,
        type: 'debug',
      },
    ],
    existingTriggers: [],
    existingActions: [],
    tags: [],
  }
  ACTIONS = {
    users: [],
  }
  REPORTS = {
    tags: [],
    templates: [],
    actions: [],
    type: [
      {
        key: 'ADC',
        label: 'ADC',
        value: 'ADC',
      },
      {
        key: 'CGN',
        label: 'CGN',
        value: 'CGN',
      },
      {
        key: 'Inventory',
        label: 'Inventory',
        value: 'Inventory',
      },
    ],
    when: [
      {
        key: 'schedule',
        label: 'Schedule',
        value: 'schedule',
        default: true,
      },
      {
        key: 'onDemand',
        label: 'On Demand',
        value: 'onDemand',
      },
    ],
    frequency: [
      {
        key: 'hourly',
        label: 'Hourly',
        value: 'hourly',
      },
      {
        key: 'daily',
        label: 'Daily',
        value: 'daily',
        default: true,
      },
      {
        key: 'weekly',
        label: 'Weekly',
        value: 'weekly',
      },
      {
        key: 'monthly',
        label: 'Monthly',
        value: 'monthly',
      },
    ],
    // 1day, 2days, 3days, 7days
    durationOld: [
      {
        key: '2days',
        label: 'Last 2 Days',
        value: '2days',
      },
      {
        key: '3days',
        label: 'Last 3 Days',
        value: '3days',
      },
    ],
    duration: [
      {
        key: 'today',
        label: 'Today',
        value: 'today',
        default: true,
      },
      {
        key: '1day',
        label: 'Last Day',
        value: '1day',
      },
      {
        key: '7days',
        label: 'Last 7 Days',
        value: '7days',
      },
      {
        key: 'lastWeek',
        label: 'Last week (Mon to Sun)',
        value: 'lastWeek',
      },
      {
        key: 'last30Days',
        label: 'Last 30 days',
        value: 'last30Days',
      },
      {
        key: 'lastmonth',
        label: 'Last Month (1st to last day)',
        value: 'lastmonth',
      },
      {
        key: 'last3months',
        label: 'Last 3 Months',
        value: 'last3months',
      },
      {
        key: 'last12months',
        label: 'Last 12 months',
        value: 'last12months',
      },
    ],
  }
  APPS = {
    namespace: [
      {
        key: 'cgn',
        label: 'CGN',
        value: 'cgn',
        default: true,
      },
      {
        key: 'ssli',
        label: 'SSLI',
        value: 'ssli',
      },
      {
        key: 'gifw',
        label: 'GIFW',
        value: 'gifw',
      },
    ],
  }
  CLUSTERS = {
    zone: [
      {
        key: 'zone1',
        label: 'Zone 1',
        value: 'zone1',
        default: true,
      },
      {
        key: 'zone2',
        label: 'Zone 2',
        value: 'zone2',
      },
    ],
    region: [
      {
        key: 'region1',
        label: 'Region 1',
        value: 'region1',
        default: true,
      },
      {
        key: 'region2',
        label: 'Region 2',
        value: 'region2',
      },
    ],
    location: [
      {
        key: 'location1',
        label: 'Location 1',
        value: 'location1',
        default: true,
      },
      {
        key: 'location2',
        label: 'Location 2',
        value: 'location2',
      },
    ],
  }
  LICENSE = {
    'activation-status': [
      {
        key: 'ACTIVATED',
        label: 'Activated',
        value: 'ACTIVATED',
        type: 'ongoing',
        default: true,
      },
      {
        key: 'IN_PROGRESS',
        label: 'In Progress',
        value: 'IN_PROGRESS',
        type: 'lowwarn',
      },
      {
        key: 'PENDING',
        label: 'Pending',
        value: 'PENDING',
        type: 'warning',
      },
      {
        key: 'EXPIRING',
        label: 'Will expire soon',
        value: 'EXPIRING',
        type: 'warning',
      },
      {
        key: 'FAILED',
        label: 'Failed to activate',
        value: 'FAILED',
        type: 'stopped',
      },
      {
        key: 'EXPIRED',
        label: 'Expired',
        value: 'EXPIRED',
        type: 'stopped',
      },
      {
        key: 'GRACE-PERIOD',
        label: 'Grace-Period',
        value: 'GRACE-PERIOD',
        type: 'stopped',
      },
      {
        key: 'REVOKED',
        label: 'Revoked',
        value: 'REVOKED',
        type: 'stopped',
      },
    ],
  }
}

export default DropdownConstants
