import React from 'react'
import ReactLoading from 'react-loading'
import { Subject } from 'rxjs'
import moment from 'moment'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10Modal,
  A10DropdownMenu,
  A10Col,
  A10Button,
  A10Input,
  A10Tooltip,
} from '@gui-libraries/widgets'

import { IDefaultMethods } from 'src/containers/Controller'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { DashboardService } from 'src/services/DashboardService'
import { AlertDetails } from 'src/containers/Controller/Monitor/Alerts/AlertDetails'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { Condition } from 'src/components/shared/Condition'
import Utilities from 'src/services/Utilities/Utilities'
import { UpgradeModal } from 'src/components/shared/UpgradeModal'
import './styles/alerts.scss'
import AlertDefinitionAddForm from './Forms'
import { Messages } from 'src/locale/en'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import ActivateLicense from 'src/components/shared/ActivateLicense'

const styles = require('./styles/alerts.scss')

export interface IAlertsProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  tenantToggled: boolean
  maxAlerts: number
  remainingAlerts: number
  getRemainingAlerts: () => void
  activeLicense: string
}
export interface IAlertsState {
  data: any
  alertObj: any
  alertsUpdated: boolean
  searchString: string
  selectedIndex: number
  allData: any
  indexes: any
  deleteModalState: boolean
  selectedAlert: any
  hcEvents: any
  showSlidingPage: boolean
  formData: IObject
  editMode: boolean
  triggerUpdated: boolean
  actionUpdated: boolean
  chartDataToggle: any
  tenantToggled: boolean
  showAlertLog: boolean
  hierarchy: any
  clusterDetails: any
  selectedRowKeys: IObject[]
  selectedRowNames: string[]
  alertDefName: string[]
  selectedRow: string[]
  showUpgrade: boolean
  showAddLicense: boolean
}

export const WIDGET_CONFIG: any = {
  fields: [
    {
      key: 'rule_assoc_id',
      label: 'Alerts',
    },
  ],
  aggregation: 'count',
  histogramField: 'ts',
  rangebyField: 'ts',
  // filterBy: 'delete',
  filterBy: {
    and: {
      // account_id: '',
      rule_assoc_id: '',
    },
  },
}

export const WIDGET_CONFIG_HC_EVENT_ALL_DETAILS: any = {
  sort: 'desc',
  size: 1,
  rangebyField: 'ts',
  filterBy: {
    and: {
      rule_assoc_id: '',
    },
  },
}

export const SPARKLINE_CHART_CONFIG = {
  chart: {
    height: '60px',
    width: 200,
    type: 'column',
    backgroundColor: 'transparent',
  },
  xAxis: {
    gridLineWidth: '0px',
    labels: { enabled: true },
    tickPosition: 'inside',
    type: 'datetime',
  },
  tooltip: {
    enabled: true,
    outside: true,
  },
  colors: ['#808080'],
}

export const ALERT_OBJ: any = {
  notification: true,
  alertName: '',
  alertDesc: '',
  priority: 0,
  tags: [],
  triggerList: [],
  trigger: 'existing',
  selectedTrigger: {},
  triggerName: '',
  windowType: 'time',
  metricAgg: '',
  metric: '',
  condition: '',
  value: 60,
  percent: true,
  objInstance: 'existing',
  deviceList: [],
  clusterDeviceTree: [],
  actionList: [],
  action: 'existing',
  selectedAction: [],
  actionName: [],
  email: true,
  emailToProviderAdmins: true,
  emailToTenantAdmins: true,
  users: [],
  extendedSubject: '',
  extendedMsg: '',
  post: true,
  postToURI: '',
  extendedBody: '',
  category: '',
  subCategory: '',
  objectType: '',
  deviceClusterName: '',
  deviceName: '',
}

class AlertDefinition extends A10Container<IAlertsProps, IAlertsState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  DashboardService = new DashboardService()
  Messages = new Messages()
  Utilities = new Utilities()
  counter = new Subject()
  pageLength = 5
  dataLoaded = false
  actionsReq: any[] = []
  triggersReq: any[] = []
  chartReq: any[] = []
  allDataOriginal: any = []
  state: IAlertsState = {
    data: [],
    alertObj: ALERT_OBJ,
    alertsUpdated: false,
    searchString: '',
    selectedIndex: 0,
    allData: [],
    indexes: [],
    deleteModalState: false,
    selectedAlert: {},
    hcEvents: {},
    showSlidingPage: false,
    formData: null,
    editMode: false,
    triggerUpdated: false,
    actionUpdated: false,
    chartDataToggle: false,
    tenantToggled: this.props.tenantToggled,
    showAlertLog: true,
    selectedRowKeys: [],
    selectedRowNames: [],
    alertDefName: [],
    selectedRow: [],
    showUpgrade: false,
    showAddLicense: false,
  }
  childForm: any = null
  delimiter = '$$::$$'
  clustersBelongs = ['system', 'infrastructure']
  isOperatorUser

  alertColumns = [
    {
      title: <span>Name</span>,
      dataIndex: 'def_name',
      key: 'def_name',
      className: 'td-truncate',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'def_name'),
      render: (text: any, record: any) => {
        return (
          <A10Tooltip title={text} placement="topLeft">
            {text}
          </A10Tooltip>
        )
      },
    },
    {
      title: <span>Priority/Severity</span>,
      dataIndex: 'priority',
      key: 'priority',
      render: (text: string, record: any, index: number) => {
        const status = record.definition.severity
        const statusObj = this.Utilities.getVal(status, 'ALERTS', 'priority')
        return (
          <div className={styles.iconsWrap} title={statusObj.label}>
            <HealthStatus type={statusObj.type} tooltip={statusObj.label} />{' '}
            {statusObj.label}
          </div>
        )
      },
      sorter: (a: any, b: any) =>
        this.Utilities.sortStringMulKeys(a, b, 'definition', 'severity'),
    },
    {
      title: (
        <span>
          Trigger
          <br />
          Name
        </span>
      ),
      dataIndex: 'trigger',
      className: 'td-truncate',
      key: 'trigger',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'trigger'),
      render: (text: any, record: any) => {
        return (
          <A10Tooltip title={text} placement="topLeft">
            {text}
          </A10Tooltip>
        )
      },
    },
    {
      title: <span>Condition</span>,
      dataIndex: 'triggerCondition',
      key: 'triggerCondition',
      render: this.renderCondition,
      sorter: (a: any, b: any, key: any) =>
        this.Utilities.sortStringMulKeys(a, b, 'definition', 'condition_text'),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (key: any) => {
        const clickAction = (component: JSX.Element, index: number) => {
          switch (component.key) {
            case 'delete':
              this.setState({
                deleteModalState: true,
                selectedAlert: key,
              })
              break
            case 'clone':
              this.cloneAlert(key)
              break
          }
        }
        return this.isOperatorUser ? null : (
          <div className="text-right">
            <i>
              <A10DropdownMenu
                menu={[
                  <div key="clone">Clone </div>,
                  <div key="delete">Delete </div>,
                ]}
                style={{ color: '#757575', marginBottom: '-15px' }}
                onClick={clickAction}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            </i>
          </div>
        )
      },
    },
  ]

  constructor(
    props: IAlertsProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: {
        get: { IS_OPERATOR_USER },
      },
    } = context

    this.isOperatorUser = IS_OPERATOR_USER
  }

  componentDidMount() {
    if (
      !this.dataLoaded ||
      this.state.tenantToggled !== this.props.tenantToggled
    ) {
      this.loadAlerts()
    }
  }
  componentDidUpdate() {
    if (this.state.tenantToggled !== this.props.tenantToggled) {
      this.loadAlerts()
    }
  }

  showTooltip(e: any, x: any): void {
    console.log(e)
  }

  rowToolTip = (record: any, text: any) => {
    return (
      <div className="tableOverToolTip">
        <table>
          <tbody>
            {text &&
              text.map((element: any) => {
                return (
                  <tr key={element}>
                    <td key={element}>{element}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    )
  }

  constructTriggerCondText = (alert: any) => {
    let content: any = ''
    const { getVal } = this.Utilities
    const type =
      alert.triggerObj.definition.conditions &&
      alert.triggerObj.definition.conditions.and
        ? 'AND'
        : alert.triggerObj.definition.conditions.or
        ? 'OR'
        : 'Expressions'
    const triggerCond =
      alert.triggerObj.definition.conditions &&
      alert.triggerObj.definition.conditions.and
        ? alert.triggerObj.definition.conditions.and
        : alert.triggerObj.definition.conditions.or
        ? alert.triggerObj.definition.conditions.or
        : alert.triggerObj.definition.conditions.expression_condition
        ? [alert.triggerObj.definition.conditions]
        : []

    triggerCond.map((operObj: any, i: number) => {
      if (!operObj.expression_condition) {
        content +=
          (i !== 0 ? ' ' + type + ' ' : '') +
          ' ' +
          getVal(operObj.aggregation, 'TRIGGERS', 'metricAgg').label +
          ' ' +
          (operObj.params.length > 1
            ? getVal(operObj.operation, 'TRIGGERS', 'operation').label +
              ' of (' +
              operObj.params.join(', ') +
              ')'
            : operObj.params.join(', ')) +
          ' is ' +
          getVal(operObj.operator, 'TRIGGERS', 'operator').label +
          ' ' +
          operObj.value +
          (operObj.percent
            ? ' % of ' +
              ' ' +
              getVal(operObj.metricAggPercent, 'TRIGGERS', 'metricAgg').label +
              ' ' +
              getVal(operObj.metricPercent, 'TRIGGERS', 'metric').label
            : '')
      } else {
        content +=
          'Expression Condition: ' +
          operObj.expression_condition +
          '; Expressions JSON: ' +
          JSON.stringify(operObj.expressions)
      }
    })
    alert['trigger-condition'] = content
  }

  renderCondition(text: string, alert: any): any {
    return alert.triggerObj ? (
      <div className="width-200px">
        <Condition
          condition={alert.triggerObj.definition.conditions}
          metadata={alert.triggerObj.metadata}
          header={true}
        />
      </div>
    ) : (
      ''
    )
  }

  renderOccurredTitle(): any {
    return <span>Occurred (24 hrs)</span>
  }

  getTrigger = (key?: any) => {
    const {
      storage: { set },
    } = this.context

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    if (!key) {
      let promises: any = []
      this.state.allData.forEach((alertObj: any) => {
        if (this.triggersReq.indexOf(alertObj.definition.rule_def_id) === -1) {
          this.triggersReq.push(alertObj.definition.rule_def_id)
          const triggerRes = this.DashboardService.getTrigger(headers, null, [
            alertObj.definition.rule_def_id,
          ])
          promises.push(triggerRes)
        }
      })
      Promise.all(promises)
        .then((respArr: any) => {
          let alertInfo = this.state.allData
          alertInfo.map((alertObj: any) => {
            respArr.map((resp: any, index: number) => {
              if (
                resp.data &&
                resp.data.def_id === alertObj.definition.rule_def_id
              ) {
                alertObj.trigger = resp.data ? resp.data.def_name : ''
                alertObj.triggerObj = resp.data ? resp.data : ''
              }
            })
          })
          set.ALL_DATA(alertInfo)
          this.allDataOriginal = alertInfo
          this.setState({
            allData: alertInfo,
            triggerUpdated: true,
          })
        })
        .catch((error: any) => {
          console.log('error in gettinhg trigger data', error)
        })
      return
    }
  }

  refreshAlerts() {
    this.dataLoaded = false
    this.setState(
      {
        actionUpdated: false,
        triggerUpdated: false,
        searchString: '',
      },
      () => {
        this.loadAlerts()
      },
    )
  }

  loadAlerts = () => {
    const {
      storage: {
        get: { TARGET_URL: targetUrl = '' },
        set,
      },
    } = this.context

    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      this.dataLoaded = true
      return
    }

    const alertResponse = this.DashboardService.getAlerts(
      headers,
      null,
      targetUrl,
    )

    alertResponse
      .then((response: any) => {
        const alertData = response?.data
        this.dataLoaded = true
        set.ALL_DATA(response.data)
        const [data, indexes] = this.DashboardService.getPaginatedItems(
          response.data,
          this.pageLength,
        )

        const alertName = alertData?.map((alertObject: IObject) => {
          return alertObject.def_name
        })

        this.actionsReq = []
        this.triggersReq = []
        this.chartReq = []
        this.allDataOriginal = response.data
        this.setState({
          allData: alertData,
          data,
          indexes,
          tenantToggled: this.props.tenantToggled,
          alertDefName: alertName,
        })

        this.getTrigger()
      })
      .catch((error: any) => {
        this.dataLoaded = true
        this.setState({
          allData: [],
        })
      })
  }

  loadHCEvents = () => {
    const {
      storage: {
        get: { CURRENT_TENANT: tenant },
      },
    } = this.context

    let now = new Date().getTime()
    let payload: any = {
      events: {
        fields: ['rule_def_id'],
        aggregation: 'count',
        rangeby: {
          start: now - 6 * 60 * 60 * 1000,
          end: now,
          field: 'timestamp',
        },
        filterby: {
          and: {
            account_id: tenant.name,
          },
        },
        histogram: {
          field: 'timestamp',
          interval: 60000,
        },
      },
    }
  }

  receiveUpdate = () => {
    this.dataLoaded = false
    this.setState({
      alertsUpdated: true,
    })
  }

  Search = (e: any) => {
    const searchStr = e.target.value
    this.Utilities.search(this, searchStr, 'def_name', {
      storeData: [...this.allDataOriginal],
    })
  }

  renderAlertDetails = (record: any, index: number) => {
    return <AlertDetails alert={record} />
  }

  handleOk = (isEditCallback: any) => {
    const { showMessage } = this.Utilities
    const { selectedRow, selectedAlert } = this.state
    let payload = ''
    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    if (selectedRow.length) {
      payload = selectedRow.join('&')
    } else payload = selectedAlert.def_name

    const deleteAlert = this.DashboardService.deleteAlert(headers, null, [
      payload,
    ])
    deleteAlert
      .then((resp: any) => {
        if (isEditCallback) {
          isEditCallback()
        } else {
          showMessage('Alert deleted successfully', 1, 0)
          this.dataLoaded = false
          this.setState({
            deleteModalState: false,
            selectedAlert: {},
            selectedRow: [],
            selectedRowKeys: [],
          })
          this.props.getRemainingAlerts()
          this.loadAlerts()
        }
      })
      .catch((error: any) => {
        if (isEditCallback) {
          const messsge = 'Unable to edit alert. ' + error.message
          showMessage(messsge, 0, 0)
        } else {
          const messsge = 'Unable to delete alert. ' + error.message
          showMessage(messsge, 0, 0)
          this.setState({
            deleteModalState: false,
            selectedAlert: {},
          })
          return false
        }
      })
    return true
  }

  handleCancel = () => {
    this.setState({
      deleteModalState: false,
      selectedAlert: {},
      selectedRow: [],
    })
  }

  setSlidingPage = (isOpen: boolean, data?: IObject, editMode?: boolean) => {
    let formDataObj = {}
    if (editMode) {
      const emailDetails = data?.actionsObj?.[0]?.definition?.email?.[0] ?? {}
      const webhookDetails =
        data?.actionsObj?.[0]?.definition?.webhook?.[0] ?? {}
      const triggerObjDetails = data?.triggerObj?.definition
      formDataObj = {
        alertName: data?.def_name,
        actionName: emailDetails?.email_ids,
        triggerName: data?.trigger,
        alertDesc: data?.description,
        tags: data?.definition.tags,
        priority: data?.definition?.severity,
        interval: data?.definition?.action_frequency?.interval,
        threshold: data?.definition?.action_frequency?.count_threshold,
        extendedMsg: emailDetails?.extended_message,
        postToUrl: webhookDetails?.uri,
        headers: webhookDetails?.headers,
        extendedBody: webhookDetails?.extended_body,
        selectedAction: data?.action?.[0] ?? '',
        category: triggerObjDetails?.category,
        subCategory: triggerObjDetails?.['sub-category'],
        objectType: triggerObjDetails?.object_type,
      }
    } else {
      formDataObj = data
    }
    this.setState({
      showSlidingPage: isOpen,
      alertObj: data,
      formData: formDataObj,
      editMode,
      selectedAlert: data,
    })
  }

  cloneAlert = (alert: any) => {
    this.setSlidingPage(true, alert, true)
  }

  onCreateAppService = () => {
    this.setSlidingPage(false, null)
  }

  onCloseSlidingPage = () => {
    this.setSlidingPage(false, null)
  }

  handleChange = (data: any, isEdit: boolean) => {
    // @ts-ignore
    this.setState({
      alertObj: data,
    })
  }

  addAlert = () => {
    const { maxAlerts, remainingAlerts, activeLicense } = this.props

    if (activeLicense !== 'No Active License') {
      if (maxAlerts === -1 || remainingAlerts > 0) {
        this.setSlidingPage(true, ALERT_OBJ)
      } else {
        this.setState({ showUpgrade: true })
      }
    } else {
      this.setState({ showAddLicense: true })
    }
  }

  editAlert = (trigger: any) => {
    this.setSlidingPage(true, trigger, true)
  }

  getFilterIDs(list: any, alertPayload: any, headers: any) {
    const idArr: any[] = []
    const promises: any = []
    list.forEach((obj: any) => {
      const fullDetails = obj.split(this.delimiter)
      const valueObj =
        fullDetails.length === 3 ? JSON.parse(fullDetails[1]) : null
      if (valueObj && valueObj.app_svc_id) {
        idArr.push({
          app_svc_id: valueObj.app_svc_id,
          name: valueObj.display_name, // used in tags
          type: 'app_svc_id',
        })
      } else {
        idArr.push({
          cluster_id: valueObj.device_cluster_id,
          partition_id: null,
          partition_uuid: valueObj.partition_uuid,
          name: valueObj.display_name,
          type: 'cluster_id',
        })
        const uuidResponse = this.DashboardService.getUUIDDetails(
          headers,
          null,
          [valueObj.partition_uuid],
        )
        promises.push(uuidResponse)
      }
    })
    if (promises.length > 0) {
      Promise.all(promises)
        .then((respArr: any) => {
          respArr.forEach((resp: any) => {
            const partition = resp.data && resp.data.partition
            if (partition) {
              idArr.forEach(obj => {
                if (obj.partition_uuid === partition.uuid) {
                  obj.partition_id = partition.id
                }
              })
            }
          })
          this.createMultipleAlerts(alertPayload, headers, idArr, 'basic')
        })
        .catch((error: any) => {
          console.log('error in getting uuid data', error)
          this.Utilities.showMessage(
            'Failed to add Alert' + ': ' + error.message,
            0,
            0,
          )
        })
    } else {
      this.createMultipleAlerts(alertPayload, headers, idArr, 'basic')
    }
  }

  createMultipleAlerts(
    payload: any,
    headers: any,
    objList: any[],
    mode: string,
  ) {
    const {
      storage: {
        get: { TARGET_URL },
      },
    } = this.context

    const { showMessage } = this.Utilities
    if (objList.length > 0) {
      const promises: any = []
      objList.forEach((obj: any, index: number) => {
        const tempPayload = { ...payload }
        if (mode === 'basic') {
          if (obj.type === 'cluster_id') {
            tempPayload.definition.filter.cluster_id = [obj.cluster_id]
            tempPayload.definition.filter.p_id = [obj.partition_id]
          } else if (obj.type === 'only_cluster_id') {
            tempPayload.definition.filter.cluster_id = [obj.cluster_id]
          } else {
            tempPayload.definition.filter.app_svc_id = [obj.app_svc_id]
          }
        } else {
          if (obj.type === 'cluster_id') {
            tempPayload.definition.filter.cluster_id = [obj.cluster]
            tempPayload.definition.filter.device_uuid = obj.device
              ? [obj.device]
              : undefined
            tempPayload.definition.filter.p_id =
              obj.partition != null ? [obj.partition] : undefined
          } else if (obj.type === 'app_svc_id_uuid') {
            tempPayload.definition.filter.app_svc_id = [obj.app_svc_id]
            tempPayload.definition.filter.o_uuid = [obj.ouuid]
          } else if (obj.type === 'uuid') {
            tempPayload.definition.filter.o_uuid = [obj.ouuid]
          } else {
            tempPayload.definition.filter.app_svc_id = [obj.app_svc_id]
          }
        }
        tempPayload.definition.tags = [obj.name]
        tempPayload.def_name = tempPayload.def_name + '_' + (index + 1)
        tempPayload.metadata.def_name = tempPayload.def_name
        const alertAdd = this.DashboardService.addAlert(
          headers,
          tempPayload,
          TARGET_URL,
        )
        promises.push(alertAdd)
      })
      Promise.all(promises)
        .then((respArr: any) => {
          showMessage('ALERT_ADD_SUCCESS', 1, 1)
          this.setSlidingPage(false)
          this.dataLoaded = false
          this.loadAlerts()
        })
        .catch((error: any) => {
          console.log('error in creating alert data', error)
          showMessage('Failed to add Alert' + ': ' + error.message, 0, 0)
        })
      return
    }
  }

  handleAddTrigger = (actionID: any) => {
    const {
      storage: {
        get: { TARGET_URL },
      },
    } = this.context

    const { showMessage } = this.Utilities
    const tenant = this.Utilities.getCurrentDrillLevelObject()
    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const {
      selectedTrigger,
      smootheningFunction,
      condition,
      value,
      windowSize,
      windowSizeType,
    } = this.state.alertObj
    const definition = selectedTrigger.definition
    const expressions =
      definition.conditions && definition.conditions.expressions
    if (
      expressions &&
      expressions.ratio &&
      expressions.ratio.indexOf('timeSeriesInterval') > -1
    ) {
      const timeinMS = windowSize * 60 * 1000
      expressions.ratio = expressions.ratio.replace(
        'timeSeriesInterval',
        timeinMS,
      )
    }
    const expressionCondition = []
    expressionCondition.push(`${smootheningFunction}(ratio)`)
    expressionCondition.push(` ${condition === 'gt' ? ' > ' : ' < '}`)
    expressionCondition.push(value)

    selectedTrigger.definition.conditions.expression_condition = expressionCondition.join(
      '',
    )
    const definitionNew = selectedTrigger.definition
    const timestamp = new Date().getTime()

    selectedTrigger.def_name = `_${definitionNew.object_type}_${definitionNew.metric_type}_${timestamp}`

    const params = []
    if (windowSizeType === 'minutes') {
      params.push(windowSize + ' min')
    } else {
      params.push(windowSize.toString())
    }
    selectedTrigger.definition.window.type = 'time'
    selectedTrigger.definition.window.params = params
    selectedTrigger.filter = {
      account_id: tenant.uuid,
    }
    selectedTrigger.account_id = tenant.uuid
    const triggerAdd = this.DashboardService.addTrigger(
      headers,
      selectedTrigger,
      TARGET_URL,
    )
    triggerAdd
      .then((response: any) => {
        this.handleAddAlert(actionID, response.data)
      })
      .catch((error: any) => {
        let message = ''
        message =
          error.response && error.response.data && error.response.data.args[0]
        showMessage('TRIGGER_ADD_FAILED', 0, 1, message)
      })
    return false
  }

  extractNameFromEmail(email: string) {
    return email.split('@').shift()
  }

  handleAddAction = () => {
    const {
      storage: {
        get: { TARGET_URL },
      },
    } = this.context

    const {
      emails,
      postHeaders,
      postToUrl,
      validateCertificate,
      notificationType,
      mitigationMessage,
      triggerType,
      selectedTrigger,
      extendedSubject,
      extendedBody,
    } = this.state.alertObj
    const { showMessage } = this.Utilities
    const tenant = this.Utilities.getCurrentDrillLevelObject()
    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    let emailObj = [{}]
    let postArr: any[] = []
    let actionNameNew = ''
    const now = moment().unix()
    if (
      emails &&
      emails.length > 0 &&
      (notificationType === 'email' || notificationType === 'both')
    ) {
      emailObj = [
        {
          email_ids: emails,
          extended_subject: extendedSubject,
          extended_message: mitigationMessage,
        },
      ]
      actionNameNew = 'emailA10'
    }

    if (
      postToUrl &&
      (notificationType === 'webhook' || notificationType === 'both')
    ) {
      postArr = [
        {
          uri: postToUrl,
          extended_body: extendedBody,
          headers: postHeaders === '' ? {} : postHeaders,
          ssc: validateCertificate,
        },
      ]
      actionNameNew = 'webhookA10'
    }
    actionNameNew = `${actionNameNew}${now}`

    const actionPayload: any = {
      def_name: actionNameNew,
      account_id: tenant.name,
      definition: {
        email: emailObj,
        webhook: postArr,
      },
      type: 'add',
    }
    actionPayload.filter = {
      account_id: tenant.name,
    }
    const actionAdd = this.DashboardService.addAction(
      headers,
      actionPayload,
      TARGET_URL,
    )
    actionAdd
      .then((response: any) => {
        if (triggerType !== 'fromchartmetrics') {
          // exisiting trigger
          this.handleAddAlert(response.data.def_id, selectedTrigger)
        } else {
          // New Trigger
          this.handleAddTrigger(response.data.def_id)
        }
      })
      .catch((error: any) => {
        showMessage('ACTION_ADD_FAILED', 0, 1, error.message)
      })
    return false
  }

  handleAdd = (isUpdateCall: boolean) => {
    const {
      exisitingAction,
      actionSelected,
      triggerType,
      selectedTrigger,
      emails,
      postToUrl,
      notificationType,
    } = this.state.alertObj

    if (
      !exisitingAction &&
      ((emails &&
        emails.length > 0 &&
        (notificationType === 'email' || notificationType === 'both')) ||
        (postToUrl &&
          (notificationType === 'webhook' || notificationType === 'both')))
    ) {
      this.handleAddAction()
    } else if (triggerType === 'fromchartmetrics') {
      this.handleAddTrigger(actionSelected)
    } else {
      this.handleAddAlert(actionSelected, selectedTrigger)
    }
    this.props.getRemainingAlerts()
  }

  handleAddAlert(actionID: any, triggerObj: any) {
    const {
      storage: {
        get: { DRILL_LEVEL: drillLevel, PROVIDER },
      },
    } = this.context

    const {
      alertName,
      priority,
      tags,
      notificationFrequency,
      numOnceForEvery,
      numOnceEvery,
      selectedObjects,
    } = this.state.alertObj
    let interval = 0
    let threshold = 0
    // getCurrentDrillLevelObject method will give us tenant or provider level details based on context
    const tenant = this.Utilities.getCurrentDrillLevelObject()
    const headers = this.Utilities.getXAccountHeaderDetailsOnDrillLevel(false)
    if (!headers) {
      return
    }
    const alertPayload: any = {
      def_name: alertName,
      description: '',
      metadata: {
        def_name: alertName,
      },
      account_id: tenant.name,
      type: 'add',
    }

    const actionDefIds: any[] = []
    if (actionID) {
      actionDefIds.push(actionID)
    }
    const filterObj = {}

    if (drillLevel === 'provider') {
      filterObj['p_ver'] = tenant.uuid
    } else {
      filterObj['account_id'] = tenant.uuid
    }

    const account_info = {
      provider_name: PROVIDER,
      tenant_name: this.Utilities.getCurrentTenantName(),
      device_cluster_name: '',
      device_name: '',
      partition_name: '',
    }
    const triggerCond = triggerObj.definition.conditions
    const andOper = triggerCond.and
    const orOper = triggerCond.or
    const operator = andOper ? 'AND' : orOper ? 'OR' : ''
    let condition_text: any
    if (triggerCond.expression_condition) {
      condition_text =
        'Expression Condition: ' +
        triggerCond.expression_condition +
        '; Expressions JSON: ' +
        JSON.stringify(triggerCond.expressions)
    } else {
      condition_text = this.Utilities.getConiditionText(
        andOper || orOper,
        operator,
      )
    }

    if (notificationFrequency === 'onceForEvery') {
      threshold = numOnceForEvery
    } else if (notificationFrequency === 'onceEvery') {
      interval = numOnceEvery
    }

    alertPayload.definition = {
      tags,
      rule_def_id: triggerObj.def_id,
      action_def_id: actionDefIds,
      filter: filterObj,
      condition_text,
      severity: priority,
      scope: drillLevel === 'provider' ? 'provider' : undefined,
      // Send interval or count_threshold in payload
      // when > 0
      action_frequency: {
        interval: interval > 0 ? interval : undefined,
        count_threshold: threshold > 0 ? threshold : undefined,
      },
      account_info,
    }

    const selectedObjectArr: any[] = []
    selectedObjects.forEach((obj: any) => {
      const fullDetails = obj.split(this.delimiter)
      let valueObj: any = {}
      if (fullDetails.length === 3) {
        valueObj = JSON.parse(fullDetails[1])
        if (valueObj.cluster) {
          selectedObjectArr.push({
            cluster: valueObj.cluster,
            device: valueObj.device,
            partition: valueObj.partition,
            name: valueObj.display_name,
            type: 'cluster_id',
          })
        } else if (valueObj.only_app_svc_id) {
          selectedObjectArr.push({
            app_svc_id: valueObj.app_svc_id,
            name: valueObj.display_name,
            type: 'only_app_svc_id',
          })
        } else if (valueObj.app_svc_id) {
          const keys = Object.getOwnPropertyNames(valueObj)
          const ouuid = valueObj[keys[keys.length - 1]]
          selectedObjectArr.push({
            app_svc_id: valueObj.app_svc_id,
            name: valueObj.display_name,
            ouuid,
            type: 'app_svc_id_uuid',
          })
        } else {
          const keys = Object.getOwnPropertyNames(valueObj)
          const ouuid = valueObj[keys[keys.length - 1]]
          selectedObjectArr.push({
            ouuid,
            name: valueObj.display_name,
            type: 'uuid',
          })
        }
      }
    })
    this.createMultipleAlerts(
      alertPayload,
      headers,
      selectedObjectArr,
      'advance',
    )
    return false
  }

  handleUpdate = () => {
    this.handleOk(() => {
      this.handleAdd(true)
    })
  }

  handleSave = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  handleDeleteSelected = (defName: string[]) => {
    this.setState({
      deleteModalState: true,
      selectedRow: defName,
    })
  }

  handleSelectedAlerts = (
    selectedRowKeys: IObject[],
    selectedRowNames: string[],
  ) => {
    this.setState({
      selectedRowKeys,
      selectedRowNames,
    })
  }

  render() {
    const {
      showSlidingPage,
      showAlertLog,
      actionUpdated,
      triggerUpdated,
      selectedRowKeys,
      allData,
      selectedAlert,
      selectedRowNames,
      selectedRow,
      showUpgrade,
      showAddLicense,
    } = this.state
    const { remainingAlerts, maxAlerts } = this.props
    const currentTenantName = this.Utilities.getCurrentTenantName()
    const hcCAllArr = this.state.allData.filter(obj => obj.isHCCall)
    const searchEnable =
      actionUpdated && triggerUpdated && hcCAllArr.length === 0

    const AlertRowSelection = {
      type: 'checkbox',
      selectedRowKeys,
      selectedRowNames: selectedAlert?.def_name,
      onChange: this.handleSelectedAlerts,
    }

    const rowName = selectedRowNames?.map((name: string) => {
      return name?.['def_name']
    })

    return (
      <>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col>
            <ContentTitle
              title="Alert Configuration"
              count={maxAlerts === -1 ? allData.length : undefined}
              remaining={`${remainingAlerts}`}
              total={maxAlerts}
            />
          </A10Col>
          <A10Col style={{ display: 'flex' }}>
            <A10Input.Search
              type="text"
              onChange={this.Search}
              disabled={!searchEnable}
              name="searchBox"
              value={this.state.searchString}
              placeholder="Search"
              style={{ marginRight: '6px' }}
            />
            <ActionButton
              text="Refresh"
              onClick={() => {
                this.refreshAlerts()
              }}
              iconProps={{ app: 'global', type: 'refresh' }}
            />
            {!this.isOperatorUser && (
              <ActionButton
                text="Create Alert"
                onClick={this.addAlert.bind(this)}
                iconProps={{ app: 'global', type: 'add-new' }}
              />
            )}
            <A10DropdownMenu
              menu={[
                <div
                  key="deleteSelected"
                  onClick={() => this.handleDeleteSelected(rowName)}
                >
                  Delete
                </div>,
              ]}
              style={{ marginTop: '10px', marginRight: '10px' }}
              trigger="hover"
              placement="bottomRight"
              arrowPointAtCenter={true}
            />
          </A10Col>
        </ContentHeader>
        <ContentBody>
          <A10Table
            rowSelection={AlertRowSelection}
            columns={this.alertColumns}
            expandedRowRender={this.renderAlertDetails}
            dataSource={this.state.allData.map((key: any, index: number) => {
              key.key = index
              if (key.triggerObj && !!key.triggerObj.definition.conditions) {
                return key
              } else {
                return key
              }
            })}
            scroll={{ x: true }}
            size="small"
            loading={
              !this.dataLoaded
                ? {
                    indicator: (
                      <div>
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
            className="alertDefination-table"
          />
        </ContentBody>
        <A10Modal
          title="Delete Alert"
          visible={this.state.deleteModalState}
          onOk={this.handleOk.bind(this, false)}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk.bind(this, false)}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            Do you want to delete{' '}
            {selectedRow.length
              ? selectedRow.join(' , ')
              : selectedAlert?.def_name}{' '}
            from {currentTenantName}?
          </p>
        </A10Modal>
        <FormatSlidingPage
          isOpen={showSlidingPage}
          onRequestOk={this.handleSave}
          onRequestClose={this.setSlidingPage.bind(this, false)}
          title={
            this.state.editMode ? 'Edit Alert' : 'Create Alert'
          }
          description=""
        >
          <AlertDefinitionAddForm
            defaultMethods={this.props.defaultMethods}
            alerts={this.state.allData}
            formData={this.state.formData}
            formMode={this.state.editMode}
            handleChange={this.handleChange}
            onSubmitForm={this.handleAdd}
            onRef={(ref: any): any => (this.childForm = ref)}
          />
        </FormatSlidingPage>
        <UpgradeModal
          content={`You are using a license which only allows ${maxAlerts} Alerts. For questions or upgrading your license please contact A10 Networks Customer Support or Sales.`}
          visible={showUpgrade}
          onClose={() => {
            this.setState({ showUpgrade: false })
          }}
        />
        <ActivateLicense
          content={'Please add a valid license to your account'}
          visible={showAddLicense}
          onClose={() => {
            this.setState({ showAddLicense: false })
          }}
        />
      </>
    )
  }
}
export default setupA10Container(AlertDefinition)
