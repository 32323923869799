import { IA10ContainerDefaultProps, setupA10Container } from '@gui-libraries/framework'
import { A10Form, A10Input, A10Button, A10Row } from '@gui-libraries/widgets'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import A10Panel from 'src/components/ADC/A10Panel'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import { Messages } from 'src/locale/en/Messages'
import { Utilities } from 'src/services'
import { IEmailServerState } from '../EmailServer'
import '../styles/index.less'

interface IServerProps extends IA10ContainerDefaultProps {
  title: string
  show: boolean
  formData: IEmailServerState[]
  onClose: () => void
  onSubmitForm: (data: IObject) => void
  onSendTestEmail: (data: IObject) => void
  form: any
  onRef?: (ref: any) => void
}

const messages = new Messages()
const utilities = new Utilities()

const ServerFormAction: React.FC<IServerProps> = (props) => {
  const { show, title, formData, form, onClose, onSubmitForm, onSendTestEmail } = props
  const [serverData, setServerData] = useState({
    mailsmtphost: formData?.['mailsmtphost'],
    mailsmtpport: formData?.['mailsmtpport'],
    mailserverusername: formData?.['mailserverusername'],
    mailserverpassword: formData?.['mailserverpassword'],
    mailfromaddress: formData?.['mailfromaddress'],
    mailfrompersonal: formData?.['mailfrompersonal'],
    mailtoaddress: formData?.['mailtoaddress']
  })

  const { getFieldDecorator } = form

  const handleChange = (name: string, e: IObject) => {
    const serverObj = { ...serverData }
    if (e && e.target) {
      serverObj[name] = e.target.value
    }
    setServerData(serverObj)
  }

  const handleSaveForm = useCallback((isTestEmail: boolean) => {
    form?.validateFieldsAndScroll({ force: true }, (err: any) => {
      if (!err) {
        if (
          formData?.['mailserverpassword'] === serverData?.mailserverpassword
        ) {
          delete serverData?.mailserverpassword
        }
        isTestEmail ? onSendTestEmail(serverData) : onSubmitForm(serverData)
      }
    })
  }, [serverData])

  useEffect(() => {
    const { onRef } = props
    if (_.isFunction(onRef)) {
      onRef(this)
    }
    const serverObj = { ...serverData }
    serverObj.mailsmtphost = formData?.['mailsmtphost']
    serverObj.mailsmtpport = formData?.['mailsmtpport']
    serverObj.mailserverusername = formData?.['mailserverusername']
    serverObj.mailserverpassword = formData?.['mailserverpassword']
    serverObj.mailfrompersonal = formData?.['mailfrompersonal']
    serverObj.mailfromaddress = formData?.['mailfromaddress']
    serverObj.mailtoaddress = formData?.['mailtoaddress']
    setServerData(serverObj)
  }, [formData])

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  return (
    <FormatSlidingPage
      isOpen={show}
      title={title}
      onRequestOk={handleSaveForm}
      onRequestClose={onClose}
    >
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel showHeader={false} className='server-panel'>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="sub-heading mandatoryField">{messages.SERVER_HOST}</span>
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="left"
                    title={messages.SERVER_HOST}
                    helpKey="HELP_SYSTEM_EMAIL_SERVER_ADD"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('mailsmtphost', {
              rules: [{ required: true, message: messages.EMPTY_HOST }],
              initialValue: serverData?.mailsmtphost,
            })(
              <A10Input
                testID="mail-smtp-host"
                type="email"
                placeholder={messages.EMPTY_SERVER_HOST}
                onChange={event => handleChange('mailsmtphost', event)}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="sub-heading mandatoryField">{messages.SERVER_PORT}</span>
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="left"
                    title={messages.SERVER_PORT}
                    helpKey="HELP_SYSTEM_EMAIL_SERVER_PORT"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('mailsmtpport', {
              rules: [{ required: true, message: messages.EMPTY_PORT }],
              initialValue: serverData?.mailsmtpport,
            })(
              <A10Input
                testID="mail-smtp-port"
                type="text"
                placeholder={messages.EMPTY_SERVER_PORT}
                onChange={event => handleChange('mailsmtpport', event)}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="sub-heading">{messages.SERVER_USERNAME}</span>
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="left"
                    title={messages.SERVER_USERNAME}
                    helpKey="HELP_SYSTEM_EMAIL_SERVER_UN"
                  />
                </span>
              </>
            }
          >
            <A10Input
              testID="mail-server-username"
              type="text"
              placeholder={messages.EMPTY_SERVER_USERNAME}
              onChange={event => handleChange('mailserverusername', event)}
              value={serverData?.mailserverusername}
            />
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="sub-heading">{messages.SERVER_PASSWORD}</span>
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="left"
                    title={messages.SERVER_PASSWORD}
                    helpKey="HELP_SYSTEM_EMAIL_SERVER_PASS"
                  />
                </span>
              </>
            }
          >
            <A10Input
              testID="mail-server-password"
              type="password"
              placeholder={messages.EMPTY_SERVER_PASSWORD}
              onChange={event => handleChange('mailserverpassword', event)}
              value={serverData?.mailserverpassword}
            />
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="sub-heading mandatoryField">{messages.EMAIL_ADDRESS}</span>
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="left"
                    title={messages.EMAIL_ADDRESS}
                    helpKey="HELP_SYSTEM_EMAIL_ADDRESS"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('mailfromaddress', {
              rules: [
                { required: true, message: messages.EMPTY_EMAIL },
                {
                  pattern: utilities.EMAIL_PATTERN,
                  message: messages.INVALID_EMAIL,
                },
              ],
              initialValue: serverData?.mailfromaddress,
            })(
              <A10Input
                testID="mail-from-address"
                type="email"
                placeholder={messages.EMPTY_EMAIL_ADDRESS}
                onChange={event => handleChange('mailfromaddress', event)}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="sub-heading mandatoryField">{messages.EMAIL_NAME}</span>
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="left"
                    title={messages.EMAIL_NAME}
                    helpKey="HELP_SYSTEM_EMAIL_NAME"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('mailfrompersonal', {
              rules: [{ required: true, message: messages.EMPTY_NAME }],
              initialValue: serverData?.mailfrompersonal,
            })(
              <A10Input
                testID="mail-from-personal"
                type="text"
                placeholder={messages.EMPTY_EMAIL_NAME}
                onChange={event => handleChange('mailfrompersonal', event)}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className='sub-heading mandatoryField'>{messages.TO_EMAIL_ADDRESS}</span>
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="left"
                    title={messages.TO_EMAIL_ADDRESS}
                    helpKey="HELP_SYSTEM_TO_EMAIL_ADDRESS"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('mailtoaddress', {
              rules: [
                { required: true, message: messages.EMPTY_NAME },
                {
                  pattern: utilities.EMAIL_PATTERN,
                  message: messages.INVALID_EMAIL,
                },
              ],
              initialValue: serverData?.mailtoaddress,
            })(
              <A10Input
                testID='mail-to-address'
                type="text"
                placeholder={messages.EMPTY_EMAIL_NAME}
                onChange={(event) => handleChange('mailtoaddress', event)}
              />
            )}
          </A10Form.Item>
          <A10Row className='send-mail-wrapper'>
            <A10Button 
              type="primary" 
              onClick={() => handleSaveForm(true)}
            >
              Send Test Email
            </A10Button>
          </A10Row>
        </A10Panel>
      </A10Form>
    </FormatSlidingPage>
  )
}

export default setupA10Container(A10Form.create()(ServerFormAction))
