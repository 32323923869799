import React, { useState, useCallback } from 'react'
import { _ } from '@gui-libraries/framework'
import {
  A10Notification,
  A10Modal,
  A10Button,
  A10Checkbox,
} from '@gui-libraries/widgets'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { httpClient } from 'src/libraries/httpClient'

export interface IForceDeleteDialogProps {
  visible: boolean
  closeModal: () => void
  api: string
  name: string
  category?: string
  onDeleteComplete: Function
}

const ForceDeleteDialog: React.FC<IForceDeleteDialogProps> = props => {
  const { visible, closeModal, api, name, category, onDeleteComplete } = props
  const [forceDelete, setForceDelete] = useState(false)
  const [loading, setLoading] = useState(false)

  const onCloseModal = useCallback(() => {
    closeModal()
    setForceDelete(false)
  }, [])

  const onCheckForceDelete = useCallback((e: CheckboxChangeEvent) => {
    setForceDelete(e.target.checked)
  }, [])

  const openNotification = (
    type: string,
    title: string,
    description: string,
  ) => {
    A10Notification[type]({
      duration: 5,
      message: title,
      description,
    })
  }

  const deleteItem = () => {
    setLoading(true)
    const _category = category ? `${category} ` : ''
    const _categoryCap = _category.charAt(0).toUpperCase() + _category.slice(1)
    httpClient
      .delete(`${api}?action=deploy${forceDelete ? '&force=true' : ''}`)
      .then(() => {
        openNotification(
          'success',
          `Successfully Deleted ${name}`,
          `${_categoryCap}${name} was successfully deleted`,
        )
      })
      .catch(err => {
        console.error(err)
        openNotification(
          'error',
          `Failed to Delete ${name}`,
          `Failed to delete ${_category}${name}${
            err?.response?.data?.message?.logs?.length > 0
              ? `: ${err.response.data.message.logs.join('. ')})`
              : ''
          }`,
        )
      })
      .finally(() => {
        onCloseModal()
        setLoading(false)
        onDeleteComplete()
      })
  }

  return (
    <A10Modal
      title="Confirmation"
      visible={visible}
      onCancel={onCloseModal}
      footer={[
        <A10Button type="primary" onClick={onCloseModal} size="large">
          No
        </A10Button>,
        <A10Button
          type="danger"
          loading={loading}
          onClick={deleteItem}
          size="large"
        >
          Yes
        </A10Button>,
      ]}
    >
      <p>{`Are you sure you want to delete ${
        category ? `${category} ` : ''
      }${name}?`}</p>
      <A10Checkbox onChange={onCheckForceDelete} checked={forceDelete}>
        Force Delete
      </A10Checkbox>
    </A10Modal>
  )
}

export default ForceDeleteDialog
