import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Switch,
  A10Form,
  A10Input,
  A10Select,
  A10Row,
  A10Col,
  A10Alert,
} from '@gui-libraries/widgets'

import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { Messages } from 'src/locale/en/Messages'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import {
  DashboardService,
  InfrastructureService,
  Utilities,
} from 'src/services'

import './styles/provisionform.scss'
import storage from 'src/libraries/storage'
export interface IProvisionFormProps extends IA10ContainerDefaultProps {
  cluster: any
  provisionData: any
  form: any
  onRef?: any
  onSubmitForm?: (data: any) => void
  handleChange?: (data: any) => void
  oncancel: any
}
export interface IProvisionFormState {
  bulkApply: boolean
  tenantsName: any
  partitionMap: any
}
class ProvisionForm extends A10Container<
  IProvisionFormProps,
  IProvisionFormState
> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  Messages = new Messages()
  DashboardService = new DashboardService()
  InfrastructureService = new InfrastructureService()
  Utilities = new Utilities()
  tenants
  provider
  adminLevel

  constructor(
    props: IProvisionFormProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)
    const {
      storage: {
        get: { ALLTENANTS, PROVIDER, ADMIN_LEVEL },
      },
    } = context

    this.tenants = ALLTENANTS
    this.provider = PROVIDER
    this.adminLevel = ADMIN_LEVEL

    this.state = {
      bulkApply: this.props.provisionData.bulkApply,
      tenantsName: this.props.provisionData.tenantsName,
      partitionMap: this.props.provisionData.partitionMap,
    }
    this.loadDevices()
  }

  getNewLpName = (clusterName: string, lpID: string) => {
    const lpname = clusterName + '-P' + lpID
    return lpname
  }

  loadDevices = async () => {
    const partitionMap = []
    const partitionList = this.props.cluster?.['partition-list'] || []

    for (let i = 0; i < partitionList.length; i++) {
      let tenantObj
      let tenantStrObj = ''
      if (partitionList[i]['tenant-uuid']) {
        tenantObj = this.Utilities.getTenantObjectByUUID(
          partitionList[i]['tenant-uuid'],
        )
        tenantStrObj = tenantObj ? JSON.stringify(tenantObj) : ''
      }
      partitionMap.push({
        id: partitionList[i].id,
        name: partitionList[i].name,
        uuid: partitionList[i].uuid,
        'p-type': partitionList[i]['p-type'],
        'partition-uuid': partitionList[i]['partition-uuid'],
        logicalPartition: this.getNewLpName(
              this.props.cluster.name,
              partitionList[i].id,
            ),
        tenant: tenantStrObj,
        existingPuuid: partitionList[i]['uuid'],
        existingTenant: tenantObj,
        existingLPuuid: partitionList[i]['lp-uuid'],
        existingLPname: partitionList[i]['lp-uuid'] ? this.getNewLpName(
          this.props.cluster.name,
          partitionList[i].id,
        ) : '',
        scan: !!tenantStrObj,
      })
    }
    let provisionData = this.state
    provisionData.partitionMap = partitionMap
    this.props.handleChange(provisionData)
    this.setState({
      partitionMap,
    })
  }
  renderTenantDropdownOpts = (index?: any, tenantData?: any) => {
    let dropdownOpts: any = this.tenants ? [...this.tenants] : []

    if (dropdownOpts[0] && !dropdownOpts[0].noTenant) {
      dropdownOpts.unshift({ name: 'Please Select', noTenant: true })
    }

    return dropdownOpts.map((tenant: any, i: number) => {
      const tenantStr = JSON.stringify(tenant)
      if (tenant.type !== 'built-in') {
        return (
          <A10Select.Option
            key={'provision-tenant-' + i + (index ? '-' + index : '')}
            value={tenantStr}
            title={
              tenant['display-name'] ? tenant['display-name'] : tenant.name
            }
          >
            {tenant.noTenant ? null : (
              <span className="hs-text">
                <HealthStatus type={'ongoing'} text="T" hideTooltip={true} />
              </span>
            )}
            {tenant['display-name'] ? tenant['display-name'] : tenant.name}
          </A10Select.Option>
        )
      }
    })
  }
  formatLogicalPartionName = (
    provisionData: any,
    value: any,
    isBulkApply: boolean,
  ) => {
    provisionData.partitionMap.forEach(
      async (partitionObj: any, pIndex: number) => {
        let tenantObj = isBulkApply
          ? JSON.parse(value)
          : JSON.parse(partitionObj.tenant)

        let lpName = this.getNewLpName(
          this.props.cluster.name,
          partitionObj.id
        )
        partitionObj['logicalPartition'] = lpName
        partitionObj['tenant'] = tenantObj

        // let tenantName = ''
        // if (isBulkApply) {
        //   tenantName = JSON.parse(value).name
        // } else {
        //   tenantName = partitionObj.tenant ? JSON.parse(partitionObj.tenant).name : ''
        // }
        // const lpName = this.props.cluster.name.substr(0, 5) + '-' + partitionObj['name'] + '-' + tenantName.substr(0, 5)
        // partitionObj['logicalPartition'] = lpName
      },
    )
    this.setState({ partitionMap: provisionData.partitionMap })
  }
  handleChange = async (stateName: string, index: any, e: any) => {
    let provisionData = this.state

    let value: any = ''
    if (
      typeof index === 'number' &&
      provisionData.partitionMap[index]['tenant'] === ''
    ) {
      provisionData.partitionMap[index]['tenant'] = '{"name" : "Please Select"}'
    }
    let tenant =
      typeof index === 'number' &&
      JSON.parse(provisionData.partitionMap[index]['tenant'])
        ? JSON.parse(
            provisionData.partitionMap[index]['tenant'] ||
              '{"name" : "Please Select"}',
          )
        : '{"name" : "Please Select"}'

    if (e.target) {
      if (e.target.type === 'checkbox') {
        value = e.target.checked
      } else {
        value = e.target.value
      }
    } else {
      value = e
    }
    if (stateName === 'scan' && tenant.name === 'Please Select') {
      value = false
    }
    if (typeof index === 'number') {
      provisionData.partitionMap[index][stateName] = value
    } else {
      provisionData[stateName] = value
    }

    if ('bulkApply' === stateName) {
      if (value && provisionData.tenantsName !== '') {
        const changedTenant = JSON.parse(provisionData.tenantsName)
        provisionData['partitionMap'].map(async (partition: any, inx: any) => {
          let newLpName = partition['existingLPname']
          partition['changed'] = false
          if (
            JSON.stringify(partition.existingTenant) !==
            provisionData.tenantsName
          ) {
            partition['changed'] = true
            newLpName = this.getNewLpName(
              this.props.cluster.name,
              partition.id
            )
          }
          partition['logicalPartition'] = newLpName
          partition['tenant'] = provisionData.tenantsName
          partition['scan'] = !changedTenant.noTenant
            ? provisionData.partitionMap[inx]['scan']
            : false
          this.setState({
            [provisionData['partitionMap'][inx]]: partition,
            bulkApply: value,
          })
        })
      } else {
        this.setState({
          bulkApply: value,
        })
      }
      if (!value) {
        setTimeout(() => {
          this.props.form.validateFieldsAndScroll({ force: true })
        })
      }
    }
    if (stateName === 'tenant') {
      let newLpName = provisionData.partitionMap[index]['existingLPname']
      const changedTenant = JSON.parse(
        provisionData['partitionMap'][index]['tenant'],
      )
      provisionData['partitionMap'][index]['changed'] = false
      if (
        JSON.stringify(provisionData['partitionMap'][index].existingTenant) !==
        provisionData['partitionMap'][index]['tenant']
      ) {
        provisionData['partitionMap'][index]['changed'] = true
        newLpName = this.getNewLpName(
          this.props.cluster.name,
          provisionData['partitionMap'][index].id
        )
      }
      provisionData.partitionMap[index]['logicalPartition'] = newLpName
      provisionData.partitionMap[index]['scan'] = !changedTenant.noTenant
        ? true
        : false
      this.props.form.setFieldsValue({
        [`logical-partition[${index}]`]: newLpName,
      })
      this.props.form.setFieldsValue({
        [`scan[${index}]`]: provisionData.partitionMap[index]['scan'],
      })
      this.setState({
        [provisionData['partitionMap'][index]]:
          provisionData.partitionMap[index],
      })
    }
    if (stateName === 'tenantsName') {
      if (value !== '') {
        const changedTenant = JSON.parse(value)
        provisionData['partitionMap'].map(async (partition: any, inx: any) => {
          let newLpName = partition['existingLPname']
          partition['changed'] = false
          if (
            JSON.stringify(partition.existingTenant) !==
            provisionData.tenantsName
          ) {
            partition['changed'] = true
            newLpName = this.getNewLpName(
              this.props.cluster.name,
              partition.id
            )
          }
          partition['logicalPartition'] = newLpName
          partition['tenant'] = value
          partition['scan'] = !changedTenant.noTenant ? true : false
          this.props.form.setFieldsValue({ [`tenant[${inx}]`]: value })
          this.props.form.setFieldsValue({
            [`scan[${inx}]`]: partition['scan'],
          })
          this.props.form.setFieldsValue({
            [`logical-partition[${inx}]`]: newLpName,
          })
        })
        this.setState({
          partitionMap: provisionData.partitionMap,
          tenantsName: provisionData.tenantsName,
        })
      }
      // this.formatLogicalPartionName(provisionData, value, true)
    } else {
      this.setState({
        partitionMap: provisionData.partitionMap,
      })
    }
    this.props.form.validateFieldsAndScroll({ force: true })
  }

  validateTenantSelection = (rule: any, value: any, cb: any) => {
    if (this.state.bulkApply) {
      return cb()
    }
    let isValid: boolean = false
    rule.message = this.Messages.SELECT_TENANT
    const partitionMap = this.state.partitionMap
    partitionMap.forEach((partitionObj: any) => {
      if (!!partitionObj.tenant) {
        isValid = true
      }
    })
    return isValid ? cb() : cb(true)
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }
  render() {
    const { getFieldDecorator } = this.props.form
    const { bulkApply, tenantsName, partitionMap } = this.state
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 14 },
    }
    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <div className="provision-panel">
          <A10Alert
            style={{ margin: '0px 8px 5px 8px' }}
            message=""
            description="NOTE: If this partition has been mapped before with this HC, it is recommended that you redeploy the new LPs created after provisioning to the device registered to avoid clashing IDs."
            type="success"
          />
          <div className="panel-header">
            <div className="title">
              <div className="row">
                <span
                  className="col-md-6 truncate"
                  title={'Cluster - ' + this.props.cluster.name}
                >
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                  {'Cluster - ' + this.props.cluster.name}
                </span>
                <div className="col-md-3 no-padding">
                  <A10Switch
                    defaultChecked={bulkApply}
                    disabled={partitionMap.length === 0 ? true : false}
                    id={'bulk-tenant'}
                    className="marginr-5"
                    // size="small"
                    style={{ marginRight: 5 }}
                    onChange={this.handleChange.bind(
                      this,
                      'bulkApply',
                      'noindex',
                    )}
                  />
                  <label>
                    {
                      <>
                        {this.Messages.BULK_APPLY}
                        <span>
                          <HelpInfo
                            customCls="custom-bulk-apply-head"
                            placement="top"
                            title="Apply to all"
                            helpKey="HELP_INFRA_PROVISION_BULK_APPLY"
                          />
                        </span>
                      </>
                    }
                  </label>
                </div>
                <div className="col-md-3 all-tenant">
                  <A10Form.Item>
                    {getFieldDecorator('tenantsName', {
                      rules: [
                        {
                          required: bulkApply,
                          message: this.Messages.SELECT_TENANT,
                        },
                      ],
                    })(
                      <A10Select
                        title={tenantsName}
                        showSearch={true}
                        disabled={!bulkApply ? true : false}
                        placeholder="Please select"
                        onChange={this.handleChange.bind(
                          this,
                          'tenantsName',
                          'noindex',
                        )}
                      >
                        {this.renderTenantDropdownOpts()}
                      </A10Select>,
                    )}
                  </A10Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="panel-body">
            {(this.props.cluster?.['referrer-list'] || []) === 0 ? (
              <div className="row">
                No device(s) to associate the cluster partitions to tenant
              </div>
            ) : partitionMap.length === 0 ? (
              <div className="row">
                No cluster partitions to associate with tenant
              </div>
            ) : (
              <div>
                <A10Row
                  style={{ paddingBottom: '10px', fontWeight: 'bold' }}
                  justify={'center'}
                  gutter={16}
                >
                  <A10Col span={8}>
                    {
                      <>
                        Partition Name
                        <span>
                          <HelpInfo
                            customCls="custom-provision-head"
                            placement="leftTop"
                            title="Partition Name"
                            helpKey="HELP_INFRA_PROVISION_PARTITION_NAME"
                          />
                        </span>
                      </>
                    }
                  </A10Col>
                  <A10Col span={8}>
                    {
                      <>
                        Tenant Name
                        <span>
                          <HelpInfo
                            customCls="custom-provision-head"
                            placement="leftTop"
                            title="Tenant Name"
                            helpKey="HELP_INFRA_TENANT_NAME"
                          />
                        </span>
                      </>
                    }
                  </A10Col>
                  <A10Col span={4} offset={2}>
                    {
                      <>
                        ScanConfig
                        <span>
                          <HelpInfo
                            customCls="custom-provision-head"
                            placement="leftTop"
                            title="ScanConfig"
                            helpKey="HELP_INFRA_PROVISION_SCAN_CONFIG"
                          />
                        </span>
                      </>
                    }
                  </A10Col>
                </A10Row>
                {partitionMap.map((obj: any, i: number) => {
                  const pType =
                    obj.id === 0
                      ? 'Shared'
                      : obj['p-type']?.includes('service')
                      ? 'Service'
                      : 'L3V'
                  return (
                    <A10Row gutter={16} key={'row-' + i}>
                      <A10Col span={8} key={'col1-' + i}>
                        <>
                          <span className="hs-text">
                            <HealthStatus
                              type={'ongoing'}
                              text="P"
                              hideTooltip={true}
                            />
                          </span>
                          {obj.name} (<b>{pType}</b>)
                        </>
                      </A10Col>
                      <A10Col span={8} key={'col2-' + i}>
                        <A10Form.Item>
                          {getFieldDecorator(`tenant[${i}]`, {
                            rules: [
                              {
                                validator: this.validateTenantSelection.bind(
                                  this,
                                ),
                              },
                            ],
                            initialValue: obj.tenant
                              ? JSON.parse(obj?.tenant ?? null)?.name
                              : undefined,
                          })(
                            <A10Select
                              showSearch={true}
                              // disabled={bulkApply}
                              placeholder="Please select"
                              onChange={this.handleChange.bind(
                                this,
                                'tenant',
                                i,
                              )}
                            >
                              {this.renderTenantDropdownOpts(i, obj.tenant)}
                            </A10Select>,
                          )}
                        </A10Form.Item>
                      </A10Col>
                      <A10Col span={4} offset={2} key={'col4-' + i}>
                        <A10Form.Item>
                          {getFieldDecorator(`scan[${i}]`, {
                            valuePropName: 'checked',
                            initialValue: obj.scan,
                          })(
                            <A10Switch
                              // defaultChecked={obj.scan}
                              onChange={this.handleChange.bind(this, 'scan', i)}
                              disabled={
                                obj.tenant === '' ||
                                !obj.tenant ||
                                obj.tenant ===
                                  '{"name":"Please Select","noTenant":true}'
                              }
                            />,
                          )}
                        </A10Form.Item>
                      </A10Col>
                    </A10Row>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </A10Form>
    )
  }
}
export default setupA10Container(A10Form.create()(ProvisionForm))
