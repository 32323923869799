import React from 'react'
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom'
import { A10Component } from '@gui-libraries/framework'
import { A10Icon, A10Form, A10Select } from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import { A10Panel } from 'src/components/ADC/A10Panel'
import { A10IconTextGroup } from 'src/components/ADC/A10IconTextGroup'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import Utilities from 'src/services/Utilities/Utilities'
import { AppRoot } from 'src/settings/appRoot'

export interface IControllerParams {
  applicationName: string
}
export interface IConfirmTenantProps
  extends RouteComponentProps<IControllerParams> {
  showSlidingPage: boolean
  tenantChange: any
  selectedApp: any
  selectedSubApp: any
  onCancel(): void
}
export interface IConfirmTenantState {
  menuOpen: boolean
  showSlidingPage: boolean
  isSaveEnabled: boolean
  isRedirect: boolean
  selectedTenant: Storage.ITenant
  tenantsList: any[]
}

class ConfirmTenantSelection extends A10Component<
  IConfirmTenantProps,
  IConfirmTenantState
> {
  static getDerivedStateFromProps(
    nextProps: IConfirmTenantProps,
    prevState: IConfirmTenantState,
  ) {
    let showSlidingPage = prevState.showSlidingPage
    if (prevState.showSlidingPage !== nextProps.showSlidingPage) {
      showSlidingPage = nextProps.showSlidingPage
    }
    return {
      menuOpen: prevState.menuOpen,
      showSlidingPage,
      isSaveEnabled: prevState.isSaveEnabled,
      isRedirect: prevState.isRedirect,
      selectedTenant: prevState.selectedTenant,
      tenantsList: prevState.tenantsList,
    }
  }
  Utilities = new Utilities()
  AppRoot = new AppRoot()
  tenantsList: any[]
  constructor(props: IConfirmTenantProps) {
    super(props)
    this.state = {
      menuOpen: false,
      showSlidingPage: this.props.showSlidingPage,
      isSaveEnabled: false,
      isRedirect: false,
      selectedTenant: {} as Storage.ITenant,
      tenantsList: [],
    }
  }

  setSlidingPage = (isOpen: boolean) => {
    this.props.onCancel()
    this.setState({
      showSlidingPage: isOpen,
      isSaveEnabled: false,
      selectedTenant: {} as Storage.ITenant,
    })
  }

  renderRedirect = () => {
    if (this.state.isRedirect) {
      storage.set.DRILL_LEVEL('tenant')
      storage.set.CURRENT_STATE(this.props.selectedApp.name.toLowerCase())
      if (this.props.selectedSubApp && this.props.selectedSubApp.name) {
        storage.set.CURRENT_SUB_STATE(
          this.props.selectedSubApp.name.toLowerCase(),
        )
      }
      const redirectUrl =
        '/Controller/' +
        this.props.selectedApp.name +
        (this.props.selectedSubApp ? '/' + this.props.selectedSubApp.name : '')
      this.setState({ isRedirect: false })
      return (
        <Switch>
          <Redirect key="confirm-tenant-selection" from="/" to={redirectUrl} />
        </Switch>
      )
    } else {
      return null
    }
  }

  handleChange = (value: string) => {
    const tenantObj = this.Utilities.getTenantObject(value)
    this.setState({ isSaveEnabled: true, selectedTenant: tenantObj })
  }

  setTenant = () => {
    const { selectedApp, selectedSubApp } = this.props
    try {
      storage.set.CURRENT_TENANT(this.state.selectedTenant)
    } catch {
      let tenant = JSON.parse(JSON.stringify(this.state.selectedTenant))
      delete tenant['app-svc-list']
      delete tenant['logical-partition-list']
      storage.set.CURRENT_TENANT(tenant)
    }
    this.AppRoot.setRootScopeElement('currentTenant', this.state.selectedTenant)

    storage.set.CURRENT_STATE(selectedApp.name.toLowerCase())
    if (selectedSubApp) {
      storage.set.CURRENT_SUB_STATE(selectedSubApp.name.toLowerCase())
    }
    storage.set.DRILL_LEVEL('tenant')
    this.AppRoot.setRootScopeElement('currentDrillLevel', 'tenant')

    this.setState({ showSlidingPage: false, isRedirect: true })
    this.setSlidingPage(false)
    this.props.tenantChange(
      this.state.selectedTenant,
      false,
      // selectedApp.name === 'Apps' ||
      //   selectedApp.name === 'Monitor' ||
      selectedApp?.name?.toLowerCase() === 'home' ? true : false,
    )
  }

  render() {
    const { isRedirect, showSlidingPage } = this.state
    const { selectedApp, selectedSubApp } = this.props
    const slideTitle =
      (selectedApp && !!selectedApp.name ? selectedApp.name : '') +
      (selectedSubApp && !!selectedSubApp.name
        ? ' > ' + selectedSubApp.name
        : '')

    if (isRedirect) {
      return this.renderRedirect()
    }
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 16 },
    }
    const tenantsList = storage.get.ALLTENANTS
    return (
      <div className="row no-margin">
        <FormatSlidingPage
          isOpen={showSlidingPage}
          saveText="Proceed"
          disableSave={!this.state.isSaveEnabled}
          onRequestClose={this.setSlidingPage.bind(this, false)}
          onRequestOk={this.setTenant.bind(this)}
          title={slideTitle}
          isRightCancel={true}
        >
          <A10Panel
            title={
              <A10IconTextGroup
                text={
                  <>
                    Please Select a Tenant
                    {/* <HelpInfo
                      customCls="custom-tooltip-head"
                      placement="right"
                      title="Please Select a Tenant"
                      helpKey="HELP_MONITOR_APPSERVICES_SELECT_TENANT_INFORMATION"
                    /> */}
                  </>
                }
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            }
          >
            <A10Form layout="horizontal">
              <A10Form.Item
                style={{ margin: '0px 5px' }}
                {...formItemLayout}
                label={
                  <>
                    Tenant
                    <span style={{ marginLeft: -10 }}>
                      <HelpInfo
                        placement="left"
                        title="Tenant"
                        helpKey="HELP_ORG_TENANT_NAME"
                      />
                    </span>
                  </>
                }
              >
                <A10Select
                  style={{ width: 400 }}
                  showSearch
                  placeholder={'Please Select a Tenant'}
                  onChange={this.handleChange}
                >
                  {tenantsList.map(tenant => {
                    if (tenant.type !== 'built-in') {
                      return (
                        <A10Select.Option key={tenant.name} value={tenant.name}>
                          {tenant['display-name'] || tenant.name}
                        </A10Select.Option>
                      )
                    }
                  })}
                </A10Select>
              </A10Form.Item>
            </A10Form>
          </A10Panel>
        </FormatSlidingPage>
      </div>
    )
  }
}

export default ConfirmTenantSelection
