import React from 'react'
import { _ } from '@gui-libraries/framework'
import { A10Collapse, A10Switch } from '@gui-libraries/widgets'

import { SortButton } from '../SortButton'

import './styles/index.less'

export interface IFilterCategory {
  key: string
  label: string
  icon?: JSX.Element
  filterItems?: IFilterItem[]
}

export interface IFilterItem {
  key: string
  label: string
}

export interface IFilterData {
  [filterKey: string]: IFilterInfo | boolean
}

export interface IFilterInfo {
  checked: boolean
  count?: number
}

export interface IFilterOrder {
  type: string
  order: string
}

export interface IFilterListProps {
  filterConfig: IFilterCategory[]
  filterData: IFilterData
  onFilterChange?: (currentState: IFilterData) => void
  showSelectAll?: boolean
  disabled?: boolean
}

export interface IFilterListState {
  data: {
    [filterKey: string]: IFilterInfo
  }
  sortOrder: {
    [categoryKey: string]: IFilterOrder
  }
  selectAll: boolean
}

class FilterList extends React.Component<IFilterListProps, IFilterListState> {
  constructor(props: IFilterListProps) {
    super(props)

    this.state = {
      data: {},
      sortOrder: {},
      selectAll: false,
    }
  }

  componentDidMount() {
    this.prepareData()
  }

  shouldComponentUpdate(nextProps: IFilterListProps) {
    const { filterConfig: nextConfig, filterData: nextData } = nextProps
    const { filterConfig, filterData } = this.props
    if (nextData !== filterData || nextConfig !== filterConfig) {
      this.prepareData(nextProps)
      return false
    }
    return true
  }

  prepareData = (props?: IFilterListProps) => {
    const { filterConfig = [], filterData = {} } = props || this.props

    if (_.isArray(filterConfig) && filterConfig.length > 0) {
      const { data, selectAll } = filterConfig.reduce(
        (prevState: IFilterListState, category: IFilterCategory) => {
          const { key: cKey, filterItems = [] } = category
          const categoryData = _.get(filterData, cKey, {})
          const { checked: checkedCategory } = _.isBoolean(categoryData)
            ? { checked: categoryData }
            : (categoryData as IFilterInfo)

          const { checkedAllItems, total } = filterItems.reduce(
            (prevValue: IObject, item: IFilterItem) => {
              const { key: iKey } = item
              const itemData = _.get(filterData, iKey, {})
              const {
                checked: checkedItem,
                count: countItem = 0,
              } = _.isBoolean(itemData)
                ? { checked: itemData, count: 0 }
                : (itemData as IFilterInfo)

              const checkedCurrentItem =
                (_.isBoolean(checkedCategory) && checkedCategory) ||
                (_.isBoolean(checkedItem) && checkedItem)
                  ? true
                  : false
              prevState.data[iKey] = {
                checked: checkedCurrentItem,
                count: countItem,
              }

              if (!checkedCurrentItem) {
                prevValue.checkedAllItems = false
              }
              prevValue.total += countItem

              return prevValue
            },
            { checkedAllItems: true, total: 0 },
          )

          const checkedCurrentCategory =
            (filterItems.length > 0 && checkedAllItems) || checkedCategory
              ? true
              : false
          prevState.data[cKey] = {
            checked: checkedCurrentCategory,
            count: total,
          }

          if (!checkedCurrentCategory) {
            prevState.selectAll = false
          }

          return prevState
        },
        { data: {}, selectAll: true },
      )

      this.setState({ data, selectAll })
    }
  }

  onFilterChange = () => {
    const { onFilterChange } = this.props
    const { data } = this.state
    if (onFilterChange instanceof Function) {
      onFilterChange(
        Object.keys(data).reduce((prevValue: IObject, key: string) => {
          const { checked } = data[key]
          prevValue[key] = checked
          return prevValue
        }, {}),
      )
    }
  }

  onSelectAll = (selectAll: boolean) => {
    const { data } = this.state
    Object.keys(data).forEach((key: string) => {
      data[key].checked = selectAll
    })
    this.setState({ data, selectAll }, this.onFilterChange)
  }

  onCheckCategory = (key: string, checked: boolean) => {
    const { filterConfig } = this.props
    const { data } = this.state
    const { filterItems } =
      filterConfig.find((category: IFilterCategory) => category.key === key) ||
      ({} as IFilterCategory)

    data[key].checked = checked
    if (_.isArray(filterItems)) {
      filterItems.forEach((item: IFilterItem) => {
        const { key: iKey } = item
        data[iKey].checked = checked
      })
    }

    const selectAll = Object.values(data).every(
      (value: IFilterInfo) => value.checked,
    )

    this.setState({ data, selectAll }, this.onFilterChange)
  }

  onCheckItem = (key: string, checked: boolean) => {
    const { filterConfig } = this.props
    const { data } = this.state
    const { key: cKey, filterItems } = filterConfig.find(
      (category: IFilterCategory) => {
        const { filterItems: items } = category
        if (_.isArray(items)) {
          return (
            items.find((item: IFilterItem) => item.key === key) !== undefined
          )
        }
        return false
      },
    )

    data[key].checked = checked
    data[cKey].checked = filterItems.every((item: IFilterItem) => {
      const { key: iKey } = item
      return data[iKey].checked
    })

    const selectAll = Object.values(data).every(
      (value: IFilterInfo) => value.checked,
    )

    this.setState({ data, selectAll }, this.onFilterChange)
  }

  sortItems = (items: IFilterItem[], type: string, order: string) => {
    if (type === 'letter-order') {
      if (order === 'asc') {
        items.sort((itemA: IFilterItem, itemB: IFilterItem) => {
          return itemA.label > itemB.label ? 1 : -1
        })
      } else if (order === 'desc') {
        items.sort((itemA: IFilterItem, itemB: IFilterItem) => {
          return itemA.label > itemB.label ? -1 : 1
        })
      }
    } else if (type === 'number-order') {
      if (order === 'asc') {
        items.sort((itemA: IFilterItem, itemB: IFilterItem) => {
          const a: number = parseInt(itemA.label, 10) || 0
          const b: number = parseInt(itemB.label, 10) || 0
          return a - b
        })
      } else if (order === 'desc') {
        items.sort((itemA: IFilterItem, itemB: IFilterItem) => {
          const a: number = parseInt(itemA.label, 10) || 0
          const b: number = parseInt(itemB.label, 10) || 0
          return b - a
        })
      }
    }
  }

  onOrderChange = (key: string, type: string, order: string) => {
    const { sortOrder } = this.state
    const { type: preType } = sortOrder[key] || ({} as IFilterOrder)
    if (preType !== type) {
      sortOrder[key] = { type, order: 'asc' }
    } else {
      sortOrder[key] = { type, order }
    }
    this.setState({ sortOrder })
  }

  renderSelectAll = () => {
    const { showSelectAll, disabled } = this.props
    const { selectAll } = this.state

    if (showSelectAll) {
      return (
        <span
          className="a10-filterList-selectAll"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
          }}
        >
          <A10Switch
            className="a10-filterList-switchBtn"
            checked={selectAll}
            onChange={this.onSelectAll}
            disabled={disabled}
          />
          <span className="a10-filterList-switchText">Select All</span>
        </span>
      )
    }
    return null
  }

  renderSortButton = (categoryKey: string, type: string, order: string) => {
    return (
      <span
        className="a10-filterList-categoryOrder"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
        }}
      >
        <SortButton
          className="letter-order"
          type="letter-order"
          order={order !== 'desc' ? 'asc' : 'desc'}
          disabled={type !== 'letter-order'}
          onOrderChange={this.onOrderChange.bind(
            this,
            categoryKey,
            'letter-order',
          )}
        />
        <SortButton
          className="number-order"
          type="number-order"
          order={order !== 'desc' ? 'asc' : 'desc'}
          disabled={type !== 'number-order'}
          onOrderChange={this.onOrderChange.bind(
            this,
            categoryKey,
            'number-order',
          )}
        />
      </span>
    )
  }

  renderFilterCategorys = () => {
    const { filterConfig = [], disabled } = this.props
    const { data, sortOrder } = this.state
    return filterConfig.map((category: IFilterCategory) => {
      const { key: cKey, label, icon, filterItems = [] } = category
      const { checked, count } = data[cKey] || ({} as IFilterInfo)
      const { type, order } = sortOrder[cKey] || ({} as IFilterOrder)

      if (filterItems.length === 0) {
        return (
          <div className="a10-filterList-category">
            <span
              className="a10-filterList-switchBtn offset-left"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
              }}
            >
              <A10Switch
                checked={checked}
                onChange={this.onCheckCategory.bind(this, cKey)}
                disabled={disabled}
              />
            </span>
            <span className="a10-filterList-categoryIcon">{icon || null}</span>
            <span className="a10-filterList-categoryText">{label}</span>
          </div>
        )
      } else {
        this.sortItems(filterItems, type, order)

        return (
          <A10Collapse
            className="a10-filterList-category"
            bordered={false}
            key={cKey}
          >
            <A10Collapse.Panel
              header={
                <span>
                  <span
                    className="a10-filterList-switchBtn"
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation()
                    }}
                  >
                    <A10Switch
                      checked={checked}
                      onChange={this.onCheckCategory.bind(this, cKey)}
                      disabled={disabled}
                    />
                  </span>
                  <span className="a10-filterList-categoryIcon">
                    {icon || null}
                  </span>
                  <span className="a10-filterList-categoryText">{label}</span>
                  {this.renderSortButton(cKey, type, order)}
                </span>
              }
              key={label}
            >
              {this.renderFilterItems(filterItems, count)}
            </A10Collapse.Panel>
          </A10Collapse>
        )
      }
    })
  }

  renderFilterItems = (filterItems: IFilterItem[], totalCount: number) => {
    const { data } = this.state
    return (
      <ul className="a10-filterList-itemContainer">
        {filterItems.map((item: IFilterItem, index: number) => {
          const { key: iKey, label } = item
          const { checked, count } = data[iKey] || ({} as IFilterInfo)
          return (
            <li className="a10-filterList-item" key={iKey}>
              <A10Switch
                className="a10-filterList-switchBtn"
                checked={checked}
                onChange={this.onCheckItem.bind(this, iKey)}
              />
              <span className="a10-filterList-itemText">{label}</span>
              <span
                className={`a10-filterList-itemCount${
                  index === filterItems.length - 1 ? ' last-item' : ''
                }`}
              >
                <span
                  className="a10-filterList-itemBar"
                  style={{
                    width: `${(count / totalCount) * 100}%`,
                  }}
                />
              </span>
            </li>
          )
        })}
      </ul>
    )
  }

  render() {
    return (
      <div className="a10-filterList-container">
        <div className="a10-filterList-header">
          <span className="a10-filterList-title">FILTER</span>
          {this.renderSelectAll()}
        </div>
        {this.renderFilterCategorys()}
      </div>
    )
  }
}

export default FilterList
