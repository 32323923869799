import React, { useCallback, useState } from 'react'
import {
  IA10ContainerDefaultProps,
  _,
  axios,
  useAsyncEffect,
} from '@gui-libraries/framework'
import {
  A10Alert,
  A10Col,
  A10Input,
  A10Icon,
  A10DropdownMenu,
  A10Modal,
  A10Button,
  A10Message,
} from '@gui-libraries/widgets'

import ImageList from '../ImageList'
import SlidingUpload from '../UploadForm/SlidingUpload'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import parameters from 'parameters'
import { Utilities } from 'src/services/Utilities'
import moment from 'moment'
import { formatBytes } from 'src/libraries/stringUtils'

// tslint:disable-next-line:no-var-requires
const styles = require('./style/index.module.less')

interface IDeviceImagesProps extends IA10ContainerDefaultProps {
  data: any[]
}

const utilities = new Utilities()

const SuperAdminImages: React.FC<IDeviceImagesProps> = props => {
  const [showAddOrEditSlidingPage, setShowAddOrEditSlidingPage] = useState<
    boolean
  >(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [alert, setAlert] = useState<IObject>({
    showAlert: false,
    alertType: '',
  })
  const [fileDelete, setFileDelete] = useState<IObject>({
    deleteModalState: false,
    selectedImageFile: '',
  })

  const [imageState, setImageState] = useState<IObject>({
    search: '',
    filteredImageList: [],
    imageList: [],
  })

  const showImageSlidingPage = useCallback(() => {
    setShowAddOrEditSlidingPage(true)
  }, [])

  const closeImageSlidingPage = useCallback(() => {
    setShowAddOrEditSlidingPage(false)
    getData()
  }, [])

  const getData = useCallback(async () => {
    // Start to load data
    setIsLoading(true)
    try {
      const baseUrl = parameters.BASE_URL
      const actionURL = `${baseUrl}/hpcapi/v3/image-catalog/`
      const { data: res } = await httpClient.get(actionURL)
      let imageList = res['device_image_catalog_list'] || []

      imageList = imageList
        .sort((a, b) => utilities.sortNumber(a, b, 'created-on'))
        .reverse()

      setImageState({
        ...imageState,
        imageList: imageList,
        filteredImageList: imageList,
      })
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const onSearchText = (e: any) => {
    const searchString = e.target.value ?? ''
    const searchStr = searchString?.toString().toLowerCase()
    const filteredImageList = imageState.imageList.filter(
      (deviceImage: IObject) => {
        const { name, version, description } = deviceImage

        return (
          name?.toLowerCase().includes(searchStr) ||
          version?.toLowerCase().includes(searchStr) ||
          description?.toLowerCase().includes(searchStr)
        )
      },
    )
    setImageState({
      ...imageState,
      imageList: imageState.imageList,
      filteredImageList: filteredImageList,
      search: searchString,
    })
  }

  const uploadImageFile = useCallback(async (payload, file) => {
    closeImageSlidingPage()

    const provider = storage.get.PROVIDER
    const payloadToBlob = new Blob([JSON.stringify(payload)], {
      type: 'application/json',
    })
    const formData = new FormData()
    formData.append('metadata', payloadToBlob)
    formData.append('file', file, file.name)
    const url = storage.get.SUPER_ADMIN_MODE
      ? `/image_file`
      : `/providers/${provider}/file`
    const cancelTokenSrc = axios.CancelToken.source()

    try {
      setAlert({
        alertType: 'info',
        showAlert: true,
      })

      //refresh images so that in-progress image status is reflected.
      setTimeout(() => {
        getData()
      }, 2000)

      const res = await httpClient.post(url, formData, {
        absoluteBasePath: true,
        size: file.size,
        cancelTokenSrc: cancelTokenSrc,
      })

      //when image file is successfully uploaded
      setAlert({
        alertType: 'success',
        showAlert: true,
      })
    } catch (err) {
      setAlert({
        alertType: 'error',
        showAlert: true,
      })
    } finally {
      getData()
    }
  }, [])

  const renderMessage = useCallback(() => {
    switch (alert.alertType) {
      case 'info': {
        return (
          <div>
            <A10Icon
              app="global"
              type="loading"
              style={{
                width: '100%',
                height: 22,
              }}
            />
            <span
              style={{
                marginLeft: '10px',
              }}
            >
              Image upload in progress...{' '}
            </span>
          </div>
        )
      }
      case 'success': {
        return <div>Successfully uploaded image</div>
      }
      case 'error': {
        return <div>Failed to upload image</div>
      }
      default: {
        return null
      }
    }
  }, [alert])

  const onCloseAlert = useCallback(() => {
    setAlert({
      alertType: 'info',
      showAlert: true,
    })
  }, [])

  const onClickAction = useCallback(
    async (record: any, component: JSX.Element) => {
      if (component.key === 'delete') {
        setFileDelete({
          ...fileDelete,
          selectedImageFile: record['name'],
          deleteModalState: true,
        })
      } else if (component.key === 'publish') {
        await httpClient.put(`/hpcapi/v3/image-catalog/${record['name']}`, {
          device_image_catalog: {
            'published-status':
              record['published-status'] === 'Yes' ? 'No' : 'Yes',
          },
        })
        getData()
      }
    },
    [],
  )

  const handleOk = useCallback(
    async (e: React.MouseEvent<any>) => {
      try {
        await httpClient.delete(
          `/hpcapi/v3/image-catalog/${fileDelete.selectedImageFile}`,
          { absoluteBasePath: true, bigFileDelete: true },
        )
        getData()
        A10Message.success('Success: Image deleted successfully', 5)
      } catch (e) {
        // do nothing
        A10Message.error('Error: Failed to delete the device image', 5)
      } finally {
        setFileDelete({
          selectedImageFile: '',
          deleteModalState: false,
        })
      }
    },
    [fileDelete],
  )

  const handleCancel = useCallback((e: React.MouseEvent<any>) => {
    setFileDelete({
      selectedImageFile: '',
      deleteModalState: false,
    })
  }, [])

  const columns = [
    {
      title: 'ACOS Version',
      dataIndex: 'version',
      key: 'version',
      sorter: (a: any, b: any) => utilities.sortString(a, b, 'name'),
    },
    {
      title: 'ACOS Image',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => utilities.sortString(a, b, 'name'),
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      sorter: (a: any, b: any) => utilities.sortNumber(a, b, 'size'),
      render: (record: any) => {
        return <span>{formatBytes(record)}</span>
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (record: any) => {
        return <span>{record}</span>
      },
      sorter: (a: any, b: any) => utilities.sortString(a, b, 'status'),
    },
    {
      title: 'Published',
      dataIndex: 'published-status',
      key: 'published-status',
      render: (record: any) => {
        return <span>{record}</span>
      },
      sorter: (a: any, b: any) =>
        utilities.sortString(a, b, 'published-status'),
    },
    {
      title: 'Date Uploaded',
      dataIndex: 'created-on',
      key: 'created-on',
      sorter: (a: any, b: any) => utilities.sortNumber(a, b, 'created-on'),
      render: (text: string, record: any) => {
        return <span>{text ? moment.unix(text).format('lll') : ''}</span>
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a: any, b: any) => utilities.sortString(a, b, 'description'),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text: string, record: any) => {
        return (
          <div className={styles.editColumn}>
            {storage.get.ADMIN_LEVEL === 'provider' &&
            !storage.get.IS_OPERATOR_USER ? (
              <i>
                <A10DropdownMenu
                  menu={
                    record['status'] === 'Uploaded'
                      ? [
                          <div key="delete">Delete</div>,
                          <div key="publish">
                            {record['published-status'] === 'Yes'
                              ? 'Unpublish'
                              : 'Publish'}
                          </div>,
                        ]
                      : [<div key="delete">Delete</div>]
                  }
                  title=""
                  style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={onClickAction.bind(this, record)}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </i>
            ) : null}
          </div>
        )
      },
    },
  ]

  useAsyncEffect(async () => {
    await getData()
  }, [])

  return (
    <>
      {alert.showAlert ? (
        <A10Alert
          style={{ marginBottom: '5px' }}
          description={renderMessage()}
          closable={true}
          message=""
          type={alert.alertType}
          onClose={onCloseAlert}
        />
      ) : null}
      <ContentHeader type="flex" align="middle" justify="space-between">
        <A10Col>
          <ContentTitle title="Image Catalog" />
        </A10Col>
        <A10Col className={styles.actionsContainer}>
          <A10Input.Search
            type="text"
            onChange={onSearchText}
            name="searchBox"
            value={imageState.search}
            placeholder="Search"
            className={styles.searchInput}
          />
          {storage.get.ADMIN_LEVEL === 'provider' &&
            !storage.get.IS_OPERATOR_USER && (
              <ActionButton
                text="Upload"
                onClick={showImageSlidingPage}
                iconProps={{ app: 'global', type: 'add-new' }}
              />
            )}
        </A10Col>
      </ContentHeader>
      <ContentBody>
        <ImageList
          isLoading={isLoading}
          data={imageState.filteredImageList}
          columns={columns}
          pageSize={20}
        />
      </ContentBody>
      <SlidingUpload
        isOpen={showAddOrEditSlidingPage}
        onRequestClose={closeImageSlidingPage}
        onRequestOk={uploadImageFile}
      />
      <A10Modal
        title="Confirmation"
        visible={fileDelete.deleteModalState}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <A10Button
            key="no"
            type="primary"
            onClick={handleCancel}
            className="nobtn"
          >
            No
          </A10Button>,
          <A10Button
            key="yes"
            type="default"
            onClick={handleOk}
            className="yesbtn"
          >
            Yes
          </A10Button>,
        ]}
      >
        <p>Are you sure you want to delete this image?</p>
      </A10Modal>
    </>
  )
}

export default SuperAdminImages
