import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Loader, A10SlidingPage } from '@gui-libraries/widgets'
import { AutoFormV15, setUpAutoConfig } from '@gui-libraries/apps'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import ActionButton from 'src/components/shared/ActionButton'
import Cards, { Type, Record } from 'src/components/shared/Cards'
import { appendUnit } from 'src/libraries/common'
import { IClusterPartition } from '../Cluster'

import styles from './styles/index.module.less'

export interface IProtocolSettingsProps extends IA10ContainerDefaultProps {
  cluster: IObject
  clusterPartition: IClusterPartition
}

interface IProtocolSettingsState {
  prefix: string
  data: Record[]
  slidingPageOpen: boolean
  formPath: string
  modalSize: string
  loading: boolean
}

const SHARED = 'shared'

class ProtocolSettings extends A10Container<
  IProtocolSettingsProps,
  IProtocolSettingsState
> {
  isOperatorUser = storage.get.IS_OPERATOR_USER

  state = {
    prefix: '',
    data: [] as Record[],
    slidingPageOpen: false,
    formPath: '',
    modalSize: '',
    loading: false,
  }

  componentDidMount = () => {
    this.init()
  }

  componentDidUpdate = (prevProps: IProtocolSettingsProps) => {
    if (
      prevProps.cluster !== this.props.cluster ||
      prevProps.clusterPartition !== this.props.clusterPartition
    ) {
      this.init()
    }
  }

  init = () => {
    const {
      clusterPartition: { cluster, partition },
    } = this.props

    if (cluster && partition) {
      const prefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/cluster/${cluster}/partition/${partition}/`
      this.setState({ prefix, loading: true }, () => this.getData())
    }
  }

  fetchData = async (url: string) => {
    const res = await httpClient.get(url).catch((err: any) => {
      console.error(err.error)
    })
    return res
  }

  getData = async () => {
    const {
      clusterPartition: { partition },
    } = this.props
    const { prefix } = this.state
    let res = {}
    const endpoints = ['network/lacp', 'network/icmp-rate-limit']
    await Promise.all(
      endpoints.map(element =>
        partition?.length > 0 ? this.fetchData(prefix + element) : null,
      ),
    ).then(value => {
      value.forEach((element, index) => {
        res[endpoints[index]] = (element as IObject)?.data?.[
          endpoints[index].includes('/')
            ? endpoints[index].split('/').slice(-1)[0]
            : endpoints[index]
        ]
      })
    })

    const writeAccessItemsMap = {
      lacp: this.renderAction('Edit', 'network/lacp/lacp'),
      icmp: this.renderAction('Edit', 'network/global'),
    }

    const data = [
      {
        name: 'ICMP',
        type: Type.Description,
        span: 16,
        minWidth: 600,
        direction: 'row',
        data: [
          {
            'Rate Limit': appendUnit(
              res?.['network/icmp-rate-limit']?.['icmp-normal-rate-limit'],
              '/ sec',
            ),
            Lockup: res?.['network/icmp-rate-limit']?.['icmp-lockup'],
          },
          {
            'Lockup Period': appendUnit(
              res?.['network/icmp-rate-limit']?.['icmp-lockup-period'],
              'sec',
              'secs',
            ),
          },
        ],
        more: !this.isOperatorUser ? writeAccessItemsMap.icmp : null,
      },
      {
        name: 'LACP',
        type: Type.Description,
        span: 8,
        minWidth: 300,
        direction: 'row',
        data: [
          {
            Priority: res?.['network/lacp']?.['system-priority'],
          },
        ],
        more: !this.isOperatorUser ? writeAccessItemsMap.lacp : null,
      },
    ]

    this.setState({
      loading: false,
      data,
    })
  }

  renderAction = (text: string, form: string, size: string = '') => {
    return (
      <ActionButton
        text={text}
        style={{ marginRight: '-10px' }}
        size="default"
        onClick={() =>
          this.setState({
            slidingPageOpen: true,
            formPath: form,
            modalSize: size,
          })
        }
        iconProps={{ app: 'global', type: 'edit' }}
      />
    )
  }

  closeFormPage = () => {
    this.setState({ slidingPageOpen: false, formPath: '', modalSize: '' })
  }

  getHttpClient = () => {
    const {
      GLOBAL_CONFIG: {
        EPIC_DEPENDENCIES: { httpClient },
      },
    } = this.props
    return httpClient
  }

  onSubmitSuccessForm = () => {
    this.closeFormPage()
    this.getData()
  }

  onCancelForm = () => {
    this.closeFormPage()
  }

  renderForm = () => {
    const {
      clusterPartition: { partition },
    } = this.props
    const { formPath, prefix } = this.state
    const Form = AutoFormV15
    const interceptor: IObject = {
      onSubmitSuccess: (
        sformUtils: IObject,
        response: IObject[],
        names: string[],
        formData: Map<string, any>,
        description: string,
        SOPEnable: boolean,
      ) => {
        this.onSubmitSuccessForm()
      },
      onCancel: this.closeFormPage,
    }

    setUpAutoConfig({
      ENV_CONSTS: {
        IS_SHARED_PARTITION: partition === SHARED,
        PARTITION_TYPE: partition === SHARED ? 'SP' : 'L3V',
      },
    })

    return (
      <Form
        schemaPath={formPath}
        hccEnv={true}
        apiPrefix={prefix}
        refApiPrefix={''}
        params={{ provider: storage.get.PROVIDER }}
        enableSOP={true}
        interceptor={interceptor}
      />
    )
  }

  render = () => {
    const { data, slidingPageOpen, modalSize, loading } = this.state
    const refBox = React.createRef()
    return (
      <>
        <div>
          {data.length > 0 && !loading ? (
            <Cards data={data} className={styles.protocolSettings} />
          ) : (
            <div
              ref={refBox as React.RefObject<HTMLDivElement>}
              style={{
                minHeight: '60px',
              }}
            >
              <A10Loader container={refBox} />
            </div>
          )}
        </div>

        <A10SlidingPage
          isOpen={slidingPageOpen}
          modalSize={modalSize}
          onRequestClose={this.closeFormPage}
        >
          {this.renderForm()}
        </A10SlidingPage>
      </>
    )
  }
}
export default setupA10Container(ProtocolSettings)
