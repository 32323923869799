import React from 'react'
import { _ } from '@gui-libraries/framework'
import './styles/index.less'

export interface IChartWrapperProps {
  onClick: () => void
  text?: string
}

export interface IChartWrapperState {
  isShow: boolean
}

class ChartWrapper extends React.Component<
  IChartWrapperProps,
  IChartWrapperState
> {
  static defaultProps = {
    onClick: _.noop,
  }
  chartWrapper: any
  constructor(props: IChartWrapperProps) {
    super(props)
    this.chartWrapper = React.createRef()
    this.state = {
      isShow: true,
    }
  }
  onClick = () => {
    setTimeout(() => {
      try {
        const height = this.chartWrapper.current.offsetHeight
        if (height < 100) {
          this.setState({ isShow: false })
        } else {
          this.setState({ isShow: true })
        }
      } catch (e) {
        //
      }
    }, 100)
  }
  render() {
    // const { text, onClick } = this.props
    // const { isShow } = this.state
    return (
      <div
        className="hc-chart-wrapper"
        onClick={this.onClick}
        ref={this.chartWrapper}
      >
        {this.props.children}
        {/* {isShow ? (
          <div className="hc-chart-wrapper-title">
            <div className="hc-chart-wrapper-btn" onClick={onClick}>
              <span className="icon-show-logs" title="Click to toggle Logs" />
              <span className="hc-chart-wrapper-text">{text ? text : ''}</span>
            </div>
          </div>
        ) : null} */}
      </div>
    )
  }
}
export default ChartWrapper
