import React from 'react'

export const NavUtilities = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="205"
    height="536"
    viewBox="0 0 205 536"
  >
    <defs>
      <filter
        id="o47xf4om8a"
        width="115.5%"
        height="141.7%"
        x="-7.7%"
        y="-20.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="31.136" />
      </filter>
      <filter
        id="kgspr7efqb"
        width="101%"
        height="101.7%"
        x="-.5%"
        y="-.9%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
      </filter>
      <rect id="mtj7s0ljhc" width="1200" height="694" x="0" y="0" rx="4" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <path
          fill="#FAFBFF"
          d="M0 0H1280V860H0z"
          transform="translate(-720.000000, -185.000000)"
        />
        <path
          fill="#D8D8D8"
          fill-opacity=".5"
          d="M0 0H1281V859H0z"
          transform="translate(-720.000000, -185.000000)"
        />
        <g>
          <path
            fill="#000"
            fill-opacity=".2"
            d="M157.07 290h887.77c3.344 0 6.335 2.08 7.5 5.216l148.862 400.953c1.538 4.142-.573 8.747-4.715 10.284-.67.25-1.37.407-2.083.47C958.9 727.64 776.697 738 647.794 738c-129.263 0-342.623-10.417-640.078-31.251-4.407-.31-7.73-4.132-7.421-8.54.053-.76.215-1.51.48-2.225L149.57 295.216c1.164-3.136 4.155-5.216 7.5-5.216z"
            filter="url(#o47xf4om8a)"
            transform="translate(-720.000000, -185.000000) translate(43.000000, 89.000000)"
          />
          <g transform="translate(-720.000000, -185.000000) translate(43.000000, 89.000000)">
            <use
              fill="#000"
              filter="url(#kgspr7efqb)"
              xlinkHref="#mtj7s0ljhc"
            />
            <use fill="#FFF" xlinkHref="#mtj7s0ljhc" />
          </g>
          <path
            fill="#2A3D56"
            d="M677 96H882V633H677z"
            transform="translate(-720.000000, -185.000000) translate(43.000000, 89.000000)"
          />
          <g>
            <g>
              <g stroke="#FFF" stroke-linecap="round">
                <path
                  d="M23.5 17.848H.5V4.615C.5 3.17 1.67 2 3.115 2h17.77C22.33 2 23.5 3.17 23.5 4.615v13.233zm-5.31 4.097H5.8m6.22 0v-4.007"
                  transform="translate(-720.000000, -185.000000) translate(43.000000, 89.000000) translate(693.000000, 126.000000) translate(0.000000, -0.000000)"
                />
                <path
                  d="M18.21 8.606l-.158-1.495-1.61 1.171c-.117.086-.264.12-.407.097-.144-.024-.272-.104-.356-.222l-.784-1.046c-.085-.117-.12-.264-.096-.407.023-.144.103-.272.222-.356l1.61-1.17-1.38-.607-2.342 1.673.157 1.14-.094.062-7.442 5.373c-.443.321-.68.854-.624 1.398.057.544.4 1.016.9 1.238.499.223 1.079.162 1.522-.159l7.4-5.383c.033-.019.061-.044.084-.073l1.045.47 2.352-1.704z"
                  transform="translate(-720.000000, -185.000000) translate(43.000000, 89.000000) translate(693.000000, 126.000000) translate(0.000000, -0.000000)"
                />
              </g>
              <text
                fill="#FFF"
                font-family="Rubik-Regular, Rubik"
                font-size="16"
                transform="translate(-720.000000, -185.000000) translate(43.000000, 89.000000) translate(693.000000, 126.000000)"
              >
                <tspan x="34" y="18">
                  Utilities
                </tspan>
              </text>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-720.000000, -185.000000) translate(43.000000, 89.000000) translate(693.000000, 126.000000) translate(9.000000, 70.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Scaleout Traffic Map
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-720.000000, -185.000000) translate(43.000000, 89.000000) translate(693.000000, 126.000000) translate(9.000000, 104.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Device Backup
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-720.000000, -185.000000) translate(43.000000, 89.000000) translate(693.000000, 126.000000) translate(9.000000, 138.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Image Upgrade
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-720.000000, -185.000000) translate(43.000000, 89.000000) translate(693.000000, 126.000000) translate(9.000000, 34.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Device CLI
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-720.000000, -185.000000) translate(43.000000, 89.000000) translate(693.000000, 126.000000) translate(9.000000, 170.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Object Explorer
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
