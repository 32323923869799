import React from 'react'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Form, A10Input, A10Icon, A10Select } from '@gui-libraries/widgets'

import { Messages } from 'src/locale/en/Messages'
import { Utilities, DashboardService } from 'src/services'
import { HelpInfo } from 'src/components/shared/HelpInfo'
// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

export interface ILMLicenseUpdateFormProps extends IA10ContainerDefaultProps {
  form: any
  onRef?: any
  licenseFormObj: any
  onChange: (licenseFormObj: any) => void
}

export interface ILMLicenseUpdateFormStates {
  licenseFormObj: any
}

class LMLicenseUpdateForm extends A10Container<
  ILMLicenseUpdateFormProps,
  ILMLicenseUpdateFormStates
> {
  Messages = new Messages()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  llmUuidRef: any = null

  constructor(props: ILMLicenseUpdateFormProps) {
    super(props)
    this.state = {
      licenseFormObj: this.props.licenseFormObj,
    }
  }

  componentWillReceiveProps(nextProps: IObject) {
    const state = { ...this.state }
    const { licenseFormObj } = nextProps
    state.licenseFormObj = licenseFormObj
    this.setState(state)
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  handleChange = (stateName: string, event: any) => {
    const value =
      event?.target?.type === 'checkbox'
        ? event.target.checked
        : event?.target
        ? event.target.value
        : event
    const { licenseFormObj } = this.state
    licenseFormObj[stateName] = value
    this.props.onChange(licenseFormObj)

    // @ts-ignore
    this.setState({ licenseFormObj })
  }

  render() {
    const {
      name,
      type,
      minBandwidth,
      defBandwidth,
      maxBandwidth,
      checkinFreq,
    } = this.state.licenseFormObj
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 14 },
    }
    const { getFieldDecorator } = this.props.form

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel showHeader={false}>
          <A10Form.Item
            className='mb-10'
            {...formItemLayout}
            label={
              <>
                <span className="mandatoryField">Name</span>
                <span style={{ marginLeft: '-4px' }}>
                  <HelpInfo
                    placement="rightTop"
                    title="Name *"
                    helpKey="HELP_LICENSE_MANAGER_EDIT_NAME"
                  />
                </span>
              </>
            }
          >
            <div className="row">
              <div className="col-md-10">
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter license name',
                    },
                  ],
                  initialValue: name,
                })(
                  <A10Input
                    type="text"
                    placeholder="License Name"
                    value={name}
                    onChange={this.handleChange.bind(this, 'name')}
                  />,
                )}
              </div>
            </div>
          </A10Form.Item>
          {type.indexOf('FLEXPOOL') > -1 && type !== 'INSTANCE_FLEXPOOL' ? (
            <>
              <A10Form.Item
                className='mb-10'
                {...formItemLayout}
                label={
                  <>
                    <span>Minimum allocated bandwidth</span>
                    <span style={{ marginLeft: '-4px' }}>
                      <HelpInfo
                        placement="rightTop"
                        title="Minimum allocated bandwidth"
                        helpKey="HELP_LICENSE_MANAGER_EDIT_MIN_ALLOCATED_BANDWIDTH"
                      />
                    </span>
                  </>
                }
              >
                <div className="row">
                  <div className="col-md-10">
                    {getFieldDecorator('minBandwidth', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter min bandwidth',
                        },
                      ],
                      initialValue: minBandwidth,
                    })(
                      <A10Input
                        type="number"
                        min={0}
                        max={maxBandwidth}
                        placeholder="Minimum allocated bandwidth"
                        onChange={this.handleChange.bind(this, 'minBandwidth')}
                        addonAfter="Mbps"
                        disabled={type === 'INSTANCE_FLEXPOOL'}
                      />,
                    )}
                  </div>
                </div>
              </A10Form.Item>

              <A10Form.Item
                className='mb-10'
                {...formItemLayout}
                label={
                  <>
                    <span>Default allocated bandwidth</span>
                    <span style={{ marginLeft: '-4px' }}>
                      <HelpInfo
                        placement="rightTop"
                        title="Default allocated bandwidth"
                        helpKey="HELP_LICENSE_MANAGER_EDIT_DEFAULT_ALLOCATED_BANDWIDTH"
                      />
                    </span>
                  </>
                }
              >
                <div className="row">
                  <div className="col-md-10">
                    {getFieldDecorator('defBandwidth', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter deafult bandwidth',
                        },
                      ],
                      initialValue: defBandwidth,
                    })(
                      <A10Input
                        type="number"
                        min={minBandwidth}
                        max={maxBandwidth}
                        placeholder="Default allocated bandwidth"
                        onChange={this.handleChange.bind(this, 'defBandwidth')}
                        addonAfter="Mbps"
                        disabled={type === 'INSTANCE_FLEXPOOL'}
                      />,
                    )}
                  </div>
                </div>
              </A10Form.Item>

              <A10Form.Item
                className='mb-10'
                {...formItemLayout}
                label={
                  <>
                    <span>Maximum allocated bandwidth</span>
                    <span style={{ marginLeft: '-4px' }}>
                      <HelpInfo
                        placement="rightTop"
                        title="Maximum allocated bandwidth"
                        helpKey="HELP_LICENSE_MANAGER_EDIT_MAX_ALLOCATED_BANDWIDTH"
                      />
                    </span>
                  </>
                }
              >
                <div className="row">
                  <div className="col-md-10">
                    {getFieldDecorator('maxBandwidth', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter max bandwidth',
                        },
                      ],
                      initialValue: maxBandwidth,
                    })(
                      <A10Input
                        type="number"
                        min={minBandwidth}
                        placeholder="Maximum allocated bandwidth"
                        onChange={this.handleChange.bind(this, 'maxBandwidth')}
                        addonAfter="Mbps"
                        disabled={type === 'INSTANCE_FLEXPOOL'}
                      />,
                    )}
                  </div>
                </div>
              </A10Form.Item>
            </>
          ) : null}

          {type.indexOf('FLEXPOOL') > -1 ? (
            <A10Form.Item
              className='mb-0'
              {...formItemLayout}
              label={
                <>
                  <span>Check-in frequency</span>
                  <span style={{ marginLeft: '-4px' }}>
                    <HelpInfo
                      placement="rightTop"
                      title="Check-in frequency"
                      helpKey="HELP_LICENSE_MANAGER_EDIT_CHECKIN_FREQUENCY"
                    />
                  </span>
                </>
              }
            >
              <div className="row">
                <div className="col-md-10">
                  {getFieldDecorator('checkinFreq', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select check-in frequency',
                      },
                    ],
                    initialValue: checkinFreq,
                  })(
                    <A10Select
                      allowClear={true}
                      placeholder="Please select"
                      onChange={this.handleChange.bind(this, 'checkinFreq')}
                    >
                      <A10Select.Option key="60" value={60}>
                        1 Minute
                      </A10Select.Option>
                      <A10Select.Option key="300" value={300}>
                        5 Minutes
                      </A10Select.Option>
                      <A10Select.Option key="180" value={900}>
                        15 Minutes
                      </A10Select.Option>
                      <A10Select.Option key="300" value={1800}>
                        30 Minutes
                      </A10Select.Option>
                    </A10Select>,
                  )}
                </div>
              </div>
            </A10Form.Item>
          ) : null}
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(LMLicenseUpdateForm))
