import React from 'react'
import { A10Icon } from '@gui-libraries/widgets'

const setupIconStyle = { fontSize: 34 }
const UserRoleSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <g fill="none" fill-rule="evenodd" stroke="#363636" stroke-linecap="round">
      <path d="M17.92 29.749H1.83c-.62 0-1.122-.502-1.122-1.122V3.955c0-.62.502-1.122 1.122-1.122h24.63c.643 0 1.165.52 1.165.831v3.913m-16.75.032h11.752M5.303 8.634L4.275 7.606m1.028 1.028L7.366 6.57m3.509 9.18h9.359M5.303 16.903l-1.028-1.028m1.028 1.028l2.063-2.064m3.509 8.668h4.334m-9.906 1.23l-1.028-1.028m1.028 1.027l2.063-2.063" />
      <path d="M24.504 21.317a3.233 3.233 0 0 0 4.946.01 4.193 4.193 0 0 1 3.842 4.166v3.259a.73.73 0 0 1-.727.727H21.27a.73.73 0 0 1-.728-.727v-3.259a4.196 4.196 0 0 1 3.962-4.176zm2.413-9.275c1.787 0 3.339 1.605 3.236 3.236-.102 1.632-.451 3.347-.451 3.347a2.875 2.875 0 0 1-5.57 0s-.35-1.715-.452-3.347c-.102-1.631 1.45-3.236 3.237-3.236z" />
    </g>
  </svg>
)

const SuperAdminOverview = () => {
  return (
    <div id="gettingStarted-2">
      <div className="gettingStarted-content-header">Super-Admin Overview</div>
      <div className="gettingStarted-content-horizontal">
        <div className="horizontal-item">
          <div className="horizontal-title-items">
            <div className="horizontal-icon">
              <A10Icon
                app="harmony-controller"
                type="provider"
                style={setupIconStyle}
              />
            </div>
            <div className="horizontal-title">Provider Management</div>
          </div>

          <div className="horizontal-rectangle" id="superProviderImage" />
          <div className="horizontal-text">
            A provider account 'root' is created during installation of Harmony
            Controller. Additional provider accounts can be created at any time.
            Devices, users and other configuration exist in the scope of a
            provider. Further segregation within the scope of a provider can be
            achieved by creating tenants.
          </div>
        </div>
        <div className="horizontal-item">
          <div className="horizontal-title-items">
            <div className="horizontal-icon">
              {/* <A10Icon
                app="harmony-controller"
                type="authentication"
                style={setupIconStyle}
              /> */}
              <UserRoleSvg />
            </div>
            <div className="horizontal-title">Super-Admin Management</div>
          </div>

          <div className="horizontal-rectangle" id="superAdminImage" />
          <div className="horizontal-text">
            A 'super-admin' user with the privilege to create providers, set up
            license manager and configure other global system settings is
            automatically created during installation of Harmony Controller.
            Optionally, additional users with the same super-admin role can be
            created.
          </div>
        </div>
        <div className="horizontal-item">
          <div className="horizontal-title-items">
            <div className="horizontal-icon">
              <A10Icon
                // @ts-ignore
                app="goe"
                type="licensing"
                style={setupIconStyle}
              />
            </div>
            <div className="horizontal-title">License Management</div>
          </div>
          <div className="horizontal-rectangle" id="superLicenseManagement" />
          <div className="horizontal-text">
            An on-prem Local License Manager (LLM) can be installed to manage
            A10 device licenses instead of having the devices reach out to the
            A10 Global License Manager (GLM) through the internet. A
            'super-admin' can setup the LLM associated with the Harmony
            Controller.
          </div>
        </div>
        <div className="horizontal-item">
          <div className="horizontal-title-items">
            <div className="horizontal-icon">
              <A10Icon
                // @ts-ignore
                app="waf"
                type="logs"
                style={setupIconStyle}
              />
            </div>
            <div className="horizontal-title">Log Rate Management</div>
          </div>
          <div className="horizontal-rectangle" id="logRateManagement" />
          <div className="horizontal-text">
            Harmony controller installation has a max Log Processing Capacity.
            Each Provider can be assigned a fixed allocated capacity or can
            share with other providers. Shared capacity providers do not have
            any allocation defined.
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuperAdminOverview
