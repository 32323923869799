import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Form,
  A10Collapse,
  A10Table,
  A10Col,
  A10Input,
} from '@gui-libraries/widgets'

import { RoundNumber } from 'src/components/shared/RoundNumber'
import { Messages } from 'src/locale/en/Messages'
import { InfrastructureService } from 'src/services/InfrastructureService/InfrastructureService'
import { IDefaultMethods } from 'src/containers/Controller'
import { DashboardService, Utilities } from 'src/services/index'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import './styles/vmhostresourcetable.scss'

export interface IVMHostResourceTableProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  credential: any
  selectedCluster: any
  onRef?: any
  handleHostResourceChange?: (name: string, data: any) => void
}
export interface IVMHostResourceTableState {
  vmWareClusterHostList: any[]
  searchString: string
  selectedHost: any
  selectedHostKeys: any[]
  vmWareResourcePoolList: any[]
  selectedResource: any
  selectedResourceKeys: any[]
}

class VMHostResourceTable extends A10Container<
  IVMHostResourceTableProps,
  IVMHostResourceTableState
> {
  Messages = new Messages()
  InfrastructureService = new InfrastructureService()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  lists: any[] = []
  powerStateMap = {
    POWERED_ON: 'On',
  }
  vmWareHostColumns = [
    {
      title: 'IP Address',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
    },
    {
      title: 'Connection State',
      dataIndex: 'connection_state',
      key: 'connection_state',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'connection_state'),
    },
    {
      title: 'Power State',
      dataIndex: 'power_state',
      key: 'power_state',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'power_state'),
      render: (powerState: any) => {
        return this.powerStateMap[powerState]
          ? this.powerStateMap[powerState]
          : powerState
      },
    },
  ]

  vmWareResourcePoolColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
    },
    {
      key: 'extrafill_1',
    },
    {
      key: 'extrafill_2',
    },
    {
      key: 'extrafill_3',
    },
    {
      key: 'extrafill_4',
    },
    {
      key: 'extrafill_5',
    },
  ]

  constructor(props: IVMHostResourceTableProps) {
    super(props)
    this.state = {
      vmWareClusterHostList: [],
      searchString: '',
      selectedHost: '',
      selectedHostKeys: [],
      vmWareResourcePoolList: [],
      selectedResource: '',
      selectedResourceKeys: [],
    }
    this.loadVMWHost()
  }

  loadVMWHost = () => {
    const credential = JSON.parse(this.props.credential)
    const vmHostResponse = this.InfrastructureService.getVMWClusterHosts(
      null,
      null,
      [credential.uuid, '?cluster=' + this.props.selectedCluster.cluster],
    )
    vmHostResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          this.lists = list
          if (list && list.length > 0) {
            this.setState({
              vmWareClusterHostList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response) // we need to create a centralised error handling
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get host details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadVMWResourcePool = (host: any) => {
    const credential = JSON.parse(this.props.credential)
    const rpResponse = this.InfrastructureService.getVMWClusterResourcePools(
      null,
      null,
      [
        credential.uuid,
        '?cluster=' + this.props.selectedCluster.cluster,
        '&host=' + host.host,
      ],
    )
    rpResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              vmWareResourcePoolList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response) // we need to create a centralised error handling
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get resource-pool details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  onSelectHostChange = (selectedHostKeys: any) => {
    let { selectedHost } = this.state,
      index = selectedHostKeys[0]
    selectedHost = this.state.vmWareClusterHostList[index]
    this.loadVMWResourcePool(selectedHost)
    this.setState({ selectedHostKeys, selectedHost })
    this.props.handleHostResourceChange('host', selectedHost)
  }
  onSelectResourceChange = (selectedResourceKeys: any) => {
    let { selectedResource } = this.state,
      index = selectedResourceKeys[0]
    selectedResource = this.state.vmWareResourcePoolList[index]
    this.setState({ selectedResourceKeys, selectedResource })
    this.props.handleHostResourceChange('resource', selectedResource)
  }

  SearchClusterHosts = (e: any) => {
    const searchString = e.target.value
    this.Utilities.search(this, searchString, 'host', {
      storeData: [...this.lists],
      stateName: 'vmWareClusterHostList',
    })
  }

  render() {
    const { selectedHostKeys, selectedResourceKeys } = this.state

    const hostRowSelection = {
      type: 'radio',
      selectedHostKeys,
      onChange: this.onSelectHostChange,
    }
    const resourceRowSelection = {
      type: 'radio',
      selectedResourceKeys,
      onChange: this.onSelectResourceChange,
    }
    return (
      <div>
        <A10Collapse
          bordered={false}
          defaultActiveKey={['1']}
          className="bottomBorderRem"
        >
          <A10Collapse.Panel
            key="1"
            header={
              <>
                <label>
                  {
                    <>
                      Host
                      <span>
                        <HelpInfo
                          customCls="custom-tooltip-storage"
                          placement="topLeft"
                          title="host"
                          helpKey="HELP_INFRA_VIRTUAL_VM_CLUSTER_HOST"
                        />
                      </span>
                    </>
                  }
                </label>
                <RoundNumber number={this.state.vmWareClusterHostList.length} />
                <div className="table-header table-header-inner">
                  <span className="vertical-split-divider verticalDivider" />
                  <div className="searchbar-container">
                    <A10Input.Search
                      type="text"
                      onChange={this.SearchClusterHosts}
                      onClick={(event: React.MouseEvent) =>
                        event.stopPropagation()
                      }
                      name="searchBox"
                      value={this.state.searchString}
                      placeholder="Search"
                    />
                  </div>
                </div>
              </>
            }
          >
            <A10Table
              columns={this.vmWareHostColumns}
              rowSelection={hostRowSelection}
              dataSource={this.state.vmWareClusterHostList.map(
                (key: any, index: number) => {
                  key.key = index
                  return key
                },
              )}
              pagination={{ hideOnSinglePage: true, pageSize: 5 }}
            />
          </A10Collapse.Panel>
        </A10Collapse>
        <A10Collapse
          bordered={false}
          defaultActiveKey={['1']}
          className="bottomBorderRem"
        >
          <A10Collapse.Panel
            key="1"
            header={
              <>
                <label>
                  {
                    <>
                      Resource Pool
                      <span>
                        <HelpInfo
                          customCls="custom-tooltip-storage"
                          placement="topLeft"
                          title="Resource Pool"
                          helpKey="HELP_INFRA_VIRTUAL_VM_CLUSTER_RESOURCE_POOL"
                        />
                      </span>
                    </>
                  }
                </label>
                <RoundNumber
                  number={this.state.vmWareResourcePoolList.length}
                />
              </>
            }
          >
            <A10Table
              columns={this.vmWareResourcePoolColumns}
              rowSelection={resourceRowSelection}
              dataSource={this.state.vmWareResourcePoolList.map(
                (key: any, index: number) => {
                  key.key = index
                  return key
                },
              )}
              pagination={{ hideOnSinglePage: true, pageSize: 5 }}
            />
          </A10Collapse.Panel>
        </A10Collapse>
      </div>
    )
  }
}

export default setupA10Container(A10Form.create()(VMHostResourceTable))
