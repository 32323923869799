import React, { useEffect, useState } from 'react'
import { A10Form, A10Input, A10Switch } from '@gui-libraries/widgets'
import {
  _,
  IA10ContainerDefaultProps,
  setupA10Container,
} from '@gui-libraries/framework'

import A10Panel from 'src/components/ADC/A10Panel'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import { ISSLConfigState } from '../SSLCertificate'
import Messages from 'src/locale/en/Messages'
import { Utilities } from 'src/services'

import './styles/index.less'

const messages = new Messages()
const utilities = new Utilities()

interface ICertificateProps extends IA10ContainerDefaultProps {
  show: boolean
  title: string
  formData: ISSLConfigState[]
  onClose: () => void
  onSubmitForm?: (data: IObject) => void
  form: any
  onRef?: (ref: any) => void
}

// split serverKey values to serverCert and caCert
export const renderCertPara = (data: string) => {
  const str = data?.split('-\n-')
  const serverCertValue = `${str && str[0]}-`
  const CACertValue = `-${str && str[1]}`
  return {
    serverCertValue,
    CACertValue,
  }
}

const CertificateFormAction: React.FC<ICertificateProps> = props => {
  const { show, title, formData, form, onRef, onClose, onSubmitForm } = props
  const [certData, setcertData] = useState({
    fqdn: formData['fqdn'],
    serverCert: formData['serverCert'],
    caCert: '',
    serverKey: formData['serverKay'],
    verify: formData['verify'],
    combinedCert: formData['combinedCert'],
    name: 'ingress',
  })

  const { getFieldDecorator } = form

  const handleSaveForm = () => {
    form?.validateFieldsAndScroll({ force: true }, (err: any) => {
      if (!err) {
        onSubmitForm(certData)
      }
    })
    delete certData?.combinedCert
  }

  const handleChange = (name: string, e: IObject) => {
    const serverData: any = { ...certData }
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        serverData[name] = e.target.checked
      } else {
        serverData[name] = e.target.value
      }
    } else {
      serverData[name] = e
    }
    setcertData(serverData)
  }

  useEffect(() => {
    if (_.isFunction(onRef)) {
      onRef(this)
    }
    const data = { ...certData }
    data.serverCert = renderCertPara(formData[0]?.serverCert).serverCertValue
    data.caCert = renderCertPara(formData[0]?.serverCert).CACertValue
    data.serverKey = formData[0]?.serverKey
    data.verify = true
    data.fqdn = formData[0]?.fqdn
    data.combinedCert = formData[0]?.combinedCert
    setcertData(data)
  }, [formData])

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  return (
    <FormatSlidingPage
      isOpen={show}
      title={title}
      onRequestOk={handleSaveForm}
      onRequestClose={() => onClose()}
    >
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel showHeader={false} className='certificate-panel'>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="cert-label mandatoryField">{messages.FQDN}</span>
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="left"
                    title={messages.FQDN}
                    helpKey="HELP_SYSTEM_SSL_FQDN"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('fqdn', {
              rules: [{ required: true, message: messages.EMPTY_FQDN }],
              initialValue: certData?.fqdn,
            })(
              <A10Input
                testID="fqdn"
                text="text"
                placeholder={messages.FQDN_PLACEHOLDER}
                onChange={handleChange.bind(this, 'fqdn')}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className=" cert-label mandatoryField">
                  {messages.SERVER_CERTIFICATE}
                </span>
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="left"
                    title={messages.SERVER_CERTIFICATE}
                    helpKey="HELP_SSL_Certificate"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('server-certiificate', {
              rules: [{ required: true, message: messages.EMPTY_SERVER_CERT }],
              initialValue: certData.serverCert,
            })(
              <A10Input.TextArea
                testID="server-certificate"
                className="certificate-textbox"
                type="text"
                size="large"
                placeholder={messages.SERVER_CERTIFICATE_PLACEHOLDER}
                onChange={handleChange.bind(this, 'serverCert')}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="cert-label mandatoryField">
                  {messages.CA_CERTIFICATE}
                </span>
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="left"
                    title={messages.CA_CERTIFICATE}
                    helpKey="HELP_SSL_Certificate"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('ca-certificate', {
              rules: [{ required: true, message: messages.EMPTY_CA_CERT }],
              initialValue: certData.caCert,
            })(
              <A10Input.TextArea
                testID="ca-certificate"
                className="certificate-textbox"
                type="text"
                size="large"
                placeholder={messages.SERVER_CERTIFICATE_PLACEHOLDER}
                onChange={handleChange.bind(this, 'caCert')}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="cert-label mandatoryField">{messages.PRIVATE_KEY}</span>
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="left"
                    title={messages.PRIVATE_KEY}
                    helpKey="HELP_SSL_Key"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('private-key', {
              rules: [
                { required: true, message: messages.EMPTY_PRIVATE_KEY },
                {
                  pattern: utilities.VALIDATE_PRIVATE_KEY,
                  message: messages.INVALID_PRIVATE_KEY,
                },
              ],
            })(
              <A10Input.TextArea
                testID="private-key"
                className="certificate-textbox"
                type="text"
                size="large"
                placeholder={messages.PRIVATE_KEY_PLACEHOLDER}
                onChange={handleChange.bind(this, 'serverKey')}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className='cert-label'>
                  {messages.VALIDATE_CERTIFICATE}
                </span>
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="left"
                    title={messages.VALIDATE_CERTIFICATE}
                    helpKey="HELP_MONITOR_ACTION_VALIDATE_CERTIFICATE"
                  />
                </span>
              </>
            }
          >
            <A10Switch
              testID="validate-certificate"
              style={{ marginRight: '8px' }}
              size="default"
              onChange={handleChange.bind(this, 'verify')}
              checked={certData?.verify}
            />
            <span style={{ fontWeight: 500 }}>
              {certData?.verify ? 'Enable' : 'Disable'}
            </span>
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    </FormatSlidingPage>
  )
}

export default setupA10Container(A10Form.create()(CertificateFormAction))
