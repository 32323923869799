import React from 'react'
import LogProcessing from './LogProcessing'

interface IResourceProps {}

const Resource: React.FC<IResourceProps> = props => {
  return <LogProcessing />
}

export default Resource
