// tslint:disable-next-line:no-var-requires
import * as queryString from 'query-string'

import { UrlService, AjaxService } from 'src/services'
import { AppRoot } from 'src/settings/appRoot'
import { Data } from 'src/constants/Data/Data'

export class OrganizationService {
  UrlService = new UrlService()
  AjaxService = new AjaxService()
  AppRoot = new AppRoot()
  Data = new Data()

  listTenant = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('LIST_TENANT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  listTenants = (headers: any, payload: any, urlInput: any) => {
    // const urlObj = this.UrlService.get('LIST_TENANTS_O')
    const urlObj = this.UrlService.get('LIST_TENANTS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    // Return new Promise((resolve: any, rej: any) => {
    //   resolve(this.Data.tenants)
    // })
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  listSelectedTenants = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('LIST_SELECTED_TENANTS')
    // const urlObj = this.UrlService.get('LIST_SELECTED_TENANTS_O')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getTenants = (
    headers: any,
    payload: any,
    urlInput: any,
    cancelToken?: any,
  ) => {
    const urlObj = this.UrlService.get('GET_TENANTS')
    // const urlObj = this.UrlService.get('LIST_TENANTS_O')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    // return new Promise((resolve: any, rej: any) => {
    //   resolve(this.Data.tenants)
    // })
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
      cancelToken,
    )
  }
  getTenantObj = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_TENANT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  updateTenant = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_TENANT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getUsers = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_USERS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getUsersCount = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_USERS_COUNT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      { 
        ...headers, 
        Accept: 'text/plain'
      },
      payload,
      undefined,
    )
  }
  getTenantAdminUsers = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_TENANT_ADMIN')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getUserActivationMail = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_USER_ACTIVATION_LINK')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getAdminActivationMail = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_ADMIN_ACTIVATION_LINK')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  addUser = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_USER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  updateUser = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_USER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  addTenants = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_TENANT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getUser = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_USER_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  userProfile = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_PROVIDER_ADMIN_DETAILS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  userAssign = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ASSIGN_USER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }
  userTenantAssign = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ASSIGN_TENANT_USER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }

  deleteTenant = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_TENANT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  deleteUser = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_USER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  validateLicense = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('VALIDATE_LICENSE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getLicenseInfo = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LICENSE_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  updateLicense = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_LICENSE_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  deleteLicense = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DEL_LICENSE_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  userRemove = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('REMOVE_USER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }

  getRbacRoleClasses = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_RBAC_ROLE_CLASSES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getRbacAccessGroups = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_RBAC_ACCESS_GROUPS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getRbacRoles = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_RBAC_ROLES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getUsersRbacRoles = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_USERS_RBAC_ROLES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }

  getUserRbacRoles = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_USER_RBAC_ROLES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }

  userRbacRoleCreate = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('CREATE_USER_RBAC_ROLE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }

  userRbacRoleUpdate = (headers: any, payload: any, urlInput: any) => {
    let userInputInSmallCase = urlInput[1].toLowerCase()
    urlInput[1] = userInputInSmallCase
    const urlObj = this.UrlService.get('UPDATE_USER_RBAC_ROLE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }

  userRbacRoleDelete = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_USER_RBAC_ROLE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }

  addRbacRole = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('CREATE_RBAC_ROLE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }

  postRoleJSON = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('POST_ROLE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getRbacRoleList = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('LIST_ROLE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  saveAccessGroup = (
    headers: any,
    payload: any,
    urlInput: any,
    editMode?: boolean,
  ) => {
    const urlObj = this.UrlService.get(
      editMode ? 'PUT_ACCESSGROUP_ROLE' : 'POST_ACCESSGROUP_ROLE',
    )
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  deleteAccessGroup = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_ACCESSGROUP_ROLE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  resetMFA = (headers: IObject, payload: IObject, urlInput: string[]) => {
    const urlObj = this.UrlService.get('USER_RESET_MFA')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getEntitlements = async (
    headers: IObject,
    payload: IObject,
    urlInput: string[],
  ) => {
    const entitlements: string[] = []
    const urlObj = this.UrlService.get('GET_ENTITLEMENTS')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    try {
      const entitlementRes = await this.AjaxService.promisingHttpRequest(
        urlObj.URL,
        urlObj.METHOD,
        headers,
        payload,
      )

      let status = 'SUCCESS'
      const result = entitlementRes?.data?.services || {}

      for (let key in result) {
        if (result[key].enabled) {
          entitlements.push(key.toLowerCase())
        }
        status = 'MODULAR_LICENSED'
      }
      return { entitlements, status }
    } catch (e) {
      return { entitlements, status: 'ERROR' }
    }
  }

  getUserEntitlements = (
    headers: IObject,
    payload: IObject,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('GET_USER_ENTITLEMENTS')
    if (urlObj['NEEDED_QUERYSTRING']) {
      urlObj['URL'] = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
}

export default OrganizationService
