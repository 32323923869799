import React, { useState, useEffect, useCallback } from 'react'
import ReactLoading from 'react-loading'
import { _ } from '@gui-libraries/framework'
import {
  A10Collapse,
  A10Icon,
  A10Row,
  A10Table,
  A10DropdownMenu,
  A10SlidingPage,
  A10Modal,
  A10Button,
  A10Notification,
} from '@gui-libraries/widgets'
import { AutoFormV15 } from '@gui-libraries/apps'

import RoundNumber from 'src/components/shared/RoundNumber'
import { InfrastructureService } from 'src/services/index'
import storage from 'src/libraries/storage'

const NetworkBPDUForwardingGroup: React.FC = (props: IObject) => {
  const [showSlidingPage, setShowSlidingPage] = useState(false)
  const [groupNumber, setGroupNumber] = useState(null as number)
  const infrastructureService = new InfrastructureService()
  const [groups, setGroups] = useState([] as IObject[])
  const [start, setStart] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [interfaces, setInterfaces] = useState(0)
  const getData = () => {
    setIsLoading(true)
    const provider = storage.get.PROVIDER
    infrastructureService
      .getRequest(
        null,
        null,
        [provider, props.deviceObj.name, 'shared', ''],
        'GET_DEVICE_BFDU_GROUP',
      )
      .then((res: IObject) => {
        try {
          const groupList = res.data['bpdu-fwd-group-list']
          setGroups(groupList)
          setIsLoading(false)
        } catch (e) {
          console.log(e)
          setIsLoading(false)
        }
      })
      .catch((err: IObject) => {
        console.log(err)
        setIsLoading(false)
      })
  }
  useEffect(() => {
    getData()
  }, [start, props.total, props.deviceObj.name])

  useEffect(() => {
    const eths = [] as number[]
    ;(groups || []).forEach((group: IObject) => {
      _.get(group, 'ethernet-list', []).forEach((eth: IObject) => {
        const { 'ethernet-start': ethStart, 'ethernet-end': ethEnd } = eth
        for (let i = ethStart; i <= ethEnd; i++) {
          if (!eths.includes(i)) {
            eths.push(i)
          }
        }
      })
    })
    setInterfaces(eths.length)
  }, [groups])

  const columns = React.useMemo(
    () => [
      {
        title: 'BPDU Fwd Group',
        dataIndex: 'bpdu-fwd-group-number',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => text,
      },
      {
        title: 'Ethernet',
        dataIndex: 'ethernet',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          const eths = _.get(record, 'ethernet-list', [])
          let content = ''
          eths.forEach((eth: IObject) => {
            if (eth['ethernet-start'] === eth['ethernet-end']) {
              content += `${eth['ethernet-start']},`
            } else {
              content += `${eth['ethernet-start']}-${eth['ethernet-end']},`
            }
          })
          content = _.trimEnd(content, ',')
          return content
        },
      },
      {
        dataIndex: 'actions',
        width: 30,
        render: (text: any, record: any) => {
          return (
            !storage.get.IS_OPERATOR_USER && (
              <A10DropdownMenu
                title=""
                menu={[
                  <div
                    key="edit"
                    onClick={handleEdit.bind(
                      this,
                      record['bpdu-fwd-group-number'],
                    )}
                  >
                    Edit
                  </div>,
                  <div
                    key="delete"
                    onClick={handleDelete.bind(
                      this,
                      record['bpdu-fwd-group-number'],
                    )}
                  >
                    Delete
                  </div>,
                ]}
                style={{ color: '#757575', marginBottom: '-15px' }}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            )
          )
        },
      },
    ],
    [],
  )

  const toggleSlidingPage = (isOpen: boolean) => {
    setShowSlidingPage(isOpen)
  }

  const handleAdd = () => {
    toggleSlidingPage(true)
    setGroupNumber(null)
  }

  const handleEdit = (bpduGroupNumber: number) => {
    setGroupNumber(bpduGroupNumber)
    setShowSlidingPage(true)
  }

  const handleDelete = (bpduGroupNumber: number) => {
    setGroupNumber(bpduGroupNumber)
    toggleModal(true)
  }

  const handleSuccess = useCallback(() => {
    const { updateTotal } = props
    if (!groupNumber) {
      updateTotal()
    } else {
      getData()
    }
    toggleSlidingPage(false)
  }, [name, groupNumber])

  const handlePageChange = (page: number, pageSize: number) => {
    setStart((page - 1) * pageSize)
  }

  const toggleModal = (isOpen: boolean) => {
    setShowModal(isOpen)
  }

  const handleOk = useCallback(() => {
    const provider = storage.get.PROVIDER
    infrastructureService
      .getRequest(
        null,
        null,
        [provider, props.deviceObj.name, 'shared', groupNumber],
        'DELETE_DEVICE_BFDU_GROUP',
      )
      .then((res: IObject) => {
        props.updateTotal()
        setStart(0)
        toggleModal(false)
        A10Notification.success({
          message: 'Success!',
          description: `Delete ${groupNumber} successfully.`,
          style: {
            wordWrap: 'break-word',
          },
        })
      })
      .catch((err: IObject) => {
        A10Notification.error({
          message: 'Error!',
          description: `Fail to delete ${groupNumber}.`,
          style: {
            wordWrap: 'break-word',
          },
        })
      })
  }, [props.deviceObj.name, groupNumber])

  const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/device/${props.deviceObj.name}/partition/shared`

  const { total } = props
  return (
    <div className="col-md-12 marginBottom10">
      <A10Modal
        visible={showModal}
        title="Confirmation"
        onOk={handleOk}
        onCancel={toggleModal.bind(this, false)}
        footer={[
          <A10Button
            key="no"
            type="primary"
            onClick={toggleModal.bind(this, false)}
            className="nobtn"
          >
            No
          </A10Button>,
          <A10Button
            key="yes"
            type="default"
            onClick={handleOk}
            className="yesbtn"
          >
            Yes
          </A10Button>,
        ]}
      >
        <p>Are you sure you want to delete the selected item?</p>
      </A10Modal>
      <A10SlidingPage
        isOpen={showSlidingPage}
        modalSize="large"
        onRequestClose={toggleSlidingPage.bind(this, false)}
      >
        <AutoFormV15
          hccEnv={true}
          schemaPath="network/bpdu-fwd-groups"
          apiPrefix={apiPrefix}
          params={{
            'bpdu-fwd-group-number': groupNumber,
          }}
          interceptor={{
            onSubmitSuccess: handleSuccess,
            onCancel: toggleSlidingPage.bind(this, false),
          }}
          ENV_CONSTS={{
            IS_SHARED_PARTITION: true,
            PARTITION_TYPE: 'SHARED',
          }}
        />
      </A10SlidingPage>
      <A10Collapse>
        <A10Collapse.Panel
          key={0}
          header={
            <>
              {!storage.get.IS_OPERATOR_USER && (
                <span className="edit-content" onClick={handleAdd}>
                  <A10Icon app="global" type="add-new" /> Add
                </span>
              )}
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 fontWeight300 width260">
                  BPDU Forwarding Group <RoundNumber number={total} />
                </span>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Forwarding Groups</div>
                  <div className="pad-s-15 fontWeight300">
                    {(groups || []).length}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Interfaces</div>
                  <div className="pad-s-15 fontWeight300">{interfaces}</div>
                </div>
              </A10Row>
            </>
          }
          className="collapsePanel"
        >
          <A10Table
            columns={columns}
            dataSource={(groups || []).slice(start, start + 10)}
            size="small"
            pagination={{
              hideOnSinglePage: true,
              pageSize: 10,
              total,
              onChange: handlePageChange,
            }}
            bordered={false}
            loading={
              isLoading
                ? {
                    indicator: (
                      <div className="loading-icon">
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
          />
        </A10Collapse.Panel>
      </A10Collapse>
    </div>
  )
}

export default NetworkBPDUForwardingGroup
