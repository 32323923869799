import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Row, A10Col } from '@gui-libraries/widgets'
import BackupInfoList from './BackupInfoList'
// tslint:disable-next-line:no-var-requires
const styles = require('./styles/detail.module.less')

export interface IBackupDetailProps extends IA10ContainerDefaultProps {
  data: IObject
  isLoading?: boolean
  updateList: any
  getRemainingBackups: () => void
}

class BackupDetail extends A10Container<IBackupDetailProps> {
  renderDetail = () => {
    const { data, isLoading, updateList, getRemainingBackups } = this.props
    const backupList = data['backup-info-list'] || []
    backupList.reverse()
    const BackupHeader = () => {
      return (
        <>
          <A10Row type="flex" align="middle">
            <A10Col lg={20}>
              <div className="table-header-inner pull-left">
                <span className={styles.pageHeader}>BACKUPS</span>
              </div>
            </A10Col>
          </A10Row>
        </>
      )
    }

    return (
      <div className="detail-section">
        <BackupHeader />
        <BackupInfoList
          deviceName={data.name}
          data={backupList}
          isLoading={isLoading}
          updateList={updateList}
          getRemainingBackups={getRemainingBackups}
        />
      </div>
    )
  }

  render() {
    return (
      <div className={styles.listDetailContainer}>{this.renderDetail()}</div>
    )
  }
}
export default setupA10Container(BackupDetail)
