import React from 'react'
import { A10Container } from '@gui-libraries/framework'
import {
  A10Row,
  A10Col,
  A10Icon,
  A10Message,
  A10Alert,
  A10Button,
} from '@gui-libraries/widgets'

import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import A10Panel from 'src/components/ADC/A10Panel'
import { DiffComparator } from 'src/components/shared/DiffComparator'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import { flattenFileArray } from 'src/libraries/arrayUtils'
export interface IVersionComparisonProps {
  partition: IObject
  type?: string
  additionalID?: string
  onClickButton: (clickDeploy: boolean, id?: string) => void
  onClickSave: () => void
}

export interface IVersionComparisonState {
  showAlert: boolean
  versions: IObject[]
  leftVersion: number
  rightVersion: number
  fileList: IObject[]
  showValidationAlert: boolean
  validationError: string
  isValidating: boolean
}

class VersionComparison extends A10Container<
  IVersionComparisonProps,
  IVersionComparisonState
> {
  typeText: string
  constructor(props: IVersionComparisonProps) {
    super(props)
    this.state = {
      showAlert: !!this.props.partition.dirty || false,
      versions: [],
      leftVersion:
        this.props.partition.version && this.props.partition.version - 1 > 0
          ? this.props.partition.version - 1
          : 0,
      rightVersion: this.props.partition.version || 0,
      fileList: [],
      showValidationAlert: false,
      validationError: '',
      isValidating: false,
    }
    this.typeText = this.props.type === 'device' ? '' : 'Logical '
  }

  componentDidMount() {
    this.getVersionList()
      .then(result => {
        const versionList = result.data['hcms-version-list'] || []
        this.setState({ versions: versionList })
      })
      .catch(() => {
        this.setState({ versions: [] })
      })
    this.getVersionFileList().then(result => {
      this.setState({ fileList: flattenFileArray(result.data) })
    })
  }

  hideAlert = () => {
    this.setState({ showAlert: false })
  }

  hideValidationError = () => {
    this.setState({ showValidationAlert: false })
  }

  getVersionList = () => {
    const lpUuid = this.props.partition.uuid
    const uri = `/hpcapi/v3/hcms-version?uuid=${lpUuid}`
    return httpClient.get(uri)
  }

  getVersionFileList = () => {
    const fileUri = this.props.partition['a10-url'] + '/file'
    return httpClient.get(fileUri)
  }

  setWorkingVersion = async (version: number) => {
    const payload = {
      load: {
        'object-uuid': this.props.partition.uuid,
        version,
      },
    }
    const uri = this.props.partition['a10-url'] + '/load'
    try {
      await httpClient.post(uri, payload)
      const loadSuccessMessage = `${this.typeText}Partition ${this.props.partition.name} is loaded with version ${version}`
      A10Message.success(loadSuccessMessage)
      setTimeout(() => {
        this.props.onClickButton(false)
      }, 500)
    } catch (err) {
      const loadFailedMessage = `Failed to load ${this.typeText}Partition ${this.props.partition.name} with version ${version}`
      A10Message.error(loadFailedMessage)
    }
  }

  validateVersion = async (version: number) => {
    this.setState({ isValidating: true })
    const { partition, type } = this.props
    let partitionList = []
    if (type === 'logical' && partition.clusterPartition) {
      const cp = partition.clusterPartition.split(' : ')
      const pName = cp[1]
      partitionList = partition.deviceList.map(device => {
        const p = device['partition-list'].filter(
          item => item.name === pName,
        )[0]
        return p
      })
    } else if (type !== 'device') {
      const deployLPFailedMessage = `Validation cannot proceed. No Cluster Partition has been assigned to the Logical Partition ${partition.name}`
      A10Message.error(deployLPFailedMessage)
      this.setState({ isValidating: false })
      return
    }
    const payload = {
      'validate-config': {
        'config-list': [],
      },
    }
    payload['validate-config']['config-list'].push({
      uuid: partition.uuid,
      version,
    })
    if (this.props.additionalID) {
      try {
        const { data: res } = await httpClient.get(
          `/hpcapi/v3/uuid/${this.props.additionalID}`,
        )
        let lpVersion = res['logical-partition'].version
        if (res['logical-partition']['candidate-version']) {
          lpVersion = res['logical-partition']['candidate-version']
        } else if (res['logical-partition']['running-version']) {
          lpVersion = res['logical-partition']['running-version']
        }
        if (lpVersion) {
          payload['validate-config']['config-list'].push({
            uuid: this.props.additionalID,
            version: lpVersion,
          })
        }
      } catch (err) {
        console.error(`uuid ${this.props.additionalID} is invalid`)
        A10Message.error(
          `Validation cannot proceed. UUID ${this.props.additionalID} is invalid.`,
        )
        this.setState({ isValidating: false })
        return
      }
    }
    for (const dp of partitionList) {
      let dpVersion = dp.version
      if (dp['candidate-version']) {
        dpVersion = dp['candidate-version']
      } else if (dp['running-version']) {
        dpVersion = dp['running-version']
      }
      if (dpVersion) {
        payload['validate-config']['config-list'].push({
          uuid: dp.uuid,
          version: dpVersion,
        })
      } else {
        const deployLPFailedMessage = `Validation cannot proceed. Device Partition ${dp.name} does not have saved version.`
        A10Message.error(deployLPFailedMessage)
        this.setState({ isValidating: false })
        return
      }
    }

    const validateUri = '/hpcapi/v3/validate-config'
    try {
      A10Message.loading('Validation in progress', 0)
      await httpClient.post(validateUri, payload, { absoluteBasePath: true })
      A10Message.destroy()
      A10Message.success('Validation Successful')
      this.setState({ isValidating: false })
    } catch (err) {
      A10Message.destroy()
      this.setState({
        validationError: JSON.stringify(err.response.data),
        showValidationAlert: true,
        isValidating: false,
      })
      console.error(err)
    }
  }

  deployVersion = async (version: number) => {
    const { partition, type } = this.props
    const subscriberUri = `/hpcapi/v3/subscriber/${partition.uuid}`
    let hasSubscriber = true
    let headers = {}
    const deployLPFailedMessage = `Deployment cannot proceed. No Cluster Partition has been assigned to the ${this.typeText} Partition ${partition.name}`
    if (type === 'logical') {
      try {
        const tenant = storage.get.CURRENT_TENANT
        headers = { tenant: tenant.name }
        await httpClient.get(subscriberUri, { headers })
      } catch (err) {
        hasSubscriber = false
        A10Message.error(deployLPFailedMessage)
        return
      }
    }

    if (hasSubscriber || type === 'device') {
      const payload = {
        deploy: {
          'obj-id': this.props.partition.uuid,
          version,
        },
      }
      const uri = '/hocapi/v1/deploy/'
      try {
        const { data: Res } = await httpClient.post(uri, payload, {
          absoluteBasePath: true,
          headers,
        })
        const workflowID = Res.workflow.uuid
        const deploySuccessMessage = `Deployment of ${this.typeText}Partition ${this.props.partition.name} Version ${version} is scheduled`
        A10Message.success(deploySuccessMessage)
        this.props.onClickButton(true, workflowID)
      } catch (err) {
        const deployDPFailedMessage = `Failed to deploy ${this.typeText}Parititon ${this.props.partition.name} with version ${version}`
        A10Message.error(deployDPFailedMessage)
      }
    }
  }

  renderRecord = () => {
    const { partition, type } = this.props
    const runningVersion = partition['running-version'] || 0
    let candidateVersion = 0
    if (partition['candidate-version']) {
      candidateVersion = partition['candidate-version']
    }
    return (
      <A10Row>
        <A10Col style={{ paddingLeft: '20px' }} span={20}>
          <DiffComparator
            type="version"
            candidateVersion={candidateVersion}
            runningVersion={runningVersion}
            leftVersionNum={this.state.leftVersion}
            rightVersionNum={this.state.rightVersion}
            versions={this.state.versions}
            onClickSetVersion={this.setWorkingVersion}
            onClickDeploy={this.deployVersion}
            onClickValidate={this.validateVersion}
            showFileList={type === 'logical'}
            fileList={this.state.fileList}
            isValidating={this.state.isValidating}
          />
        </A10Col>
      </A10Row>
    )
  }

  render() {
    const DeployWindowTitle = () => {
      return <span style={{ marginLeft: 5 }}>Compare Versions</span>
    }
    const { partition, onClickSave } = this.props
    const { showAlert } = this.state
    return (
      <>
        <A10Alert
          showIcon={true}
          style={{ marginBottom: '5px' }}
          description="For deploy to work properly, please make sure config-replace is enabled on Thunder."
          type="info"
          closable
        />
        {partition.dirty && showAlert ? (
          <A10Alert
            showIcon={true}
            style={{ marginBottom: '5px' }}
            description={
              <div>
                <span className="alert-text">
                  You have some unsaved configuration. Do you want to save
                  first?
                </span>
                <A10Button
                  type="primary"
                  style={{ marginRight: 5 }}
                  onClick={onClickSave}
                >
                  Save
                </A10Button>
                <A10Button onClick={this.hideAlert}>Hide</A10Button>
              </div>
            }
            type="warning"
          />
        ) : null}
        {this.state.showValidationAlert ? (
          <A10Alert
            showIcon={true}
            style={{ marginBottom: '5px' }}
            description={
              <div>
                <div>Error Details:</div>
                <div className="alert-text">{this.state.validationError}</div>
              </div>
            }
            type="error"
            closable
            onClose={this.hideValidationError}
          />
        ) : null}
        <A10Panel
          title={
            <A10IconTextGroup
              text={<DeployWindowTitle />}
              icon={<A10Icon app="global" type="form-section" />}
            />
          }
          isFormContent={false}
        >
          <div className="diff-comparator">{this.renderRecord()}</div>
        </A10Panel>
      </>
    )
  }
}

export default VersionComparison
