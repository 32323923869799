import React from 'react'

export const NavInfrastructure = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="176"
    height="536"
    viewBox="0 0 176 536"
  >
    <defs>
      <filter
        id="wqon752u1a"
        width="115.5%"
        height="141.7%"
        x="-7.7%"
        y="-20.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="31.136" />
      </filter>
      <filter
        id="urvmhymcxb"
        width="101%"
        height="101.7%"
        x="-.5%"
        y="-.9%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
      </filter>
      <rect id="q9hnbfejzc" width="1200" height="694" x="0" y="0" rx="4" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <path
          fill="#FAFBFF"
          d="M0 0H1280V860H0z"
          transform="translate(-528.000000, -185.000000)"
        />
        <path
          fill="#D8D8D8"
          fill-opacity=".5"
          d="M0 0H1281V859H0z"
          transform="translate(-528.000000, -185.000000)"
        />
        <g>
          <path
            fill="#000"
            fill-opacity=".2"
            d="M157.07 290h887.77c3.344 0 6.335 2.08 7.5 5.216l148.862 400.953c1.538 4.142-.573 8.747-4.715 10.284-.67.25-1.37.407-2.083.47C958.9 727.64 776.697 738 647.794 738c-129.263 0-342.623-10.417-640.078-31.251-4.407-.31-7.73-4.132-7.421-8.54.053-.76.215-1.51.48-2.225L149.57 295.216c1.164-3.136 4.155-5.216 7.5-5.216z"
            filter="url(#wqon752u1a)"
            transform="translate(-528.000000, -185.000000) translate(43.000000, 89.000000)"
          />
          <g transform="translate(-528.000000, -185.000000) translate(43.000000, 89.000000)">
            <use
              fill="#000"
              filter="url(#urvmhymcxb)"
              xlinkHref="#q9hnbfejzc"
            />
            <use fill="#FFF" xlinkHref="#q9hnbfejzc" />
          </g>
          <path
            fill="#2A3D56"
            d="M485 96H661V633H485z"
            transform="translate(-528.000000, -185.000000) translate(43.000000, 89.000000)"
          />
          <g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-528.000000, -185.000000) translate(43.000000, 89.000000) translate(492.000000, 126.000000) translate(6.000000, 34.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Clusters
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-528.000000, -185.000000) translate(43.000000, 89.000000) translate(492.000000, 126.000000) translate(6.000000, 70.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Devices
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-528.000000, -185.000000) translate(43.000000, 89.000000) translate(492.000000, 126.000000) translate(6.000000, 108.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Orchestration
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-528.000000, -185.000000) translate(43.000000, 89.000000) translate(492.000000, 126.000000) translate(6.000000, 142.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Shared Resources
                  </tspan>
                </text>
              </g>
            </g>
            <g>
              <g stroke="#FFF" stroke-linecap="round">
                <path
                  d="M.846.5h5.058c.191 0 .346.163.346.365V4.98c0 .201-.155.364-.346.364H.846C.655 5.344.5 5.18.5 4.98V.865C.5.663.655.5.846.5zm17.25 0h5.058c.191 0 .346.163.346.365V4.98c0 .201-.155.364-.346.364h-5.058c-.191 0-.346-.163-.346-.364V.865c0-.202.155-.365.346-.365zM.846 18.656h5.058c.191 0 .346.163.346.365v4.115c0 .201-.155.364-.346.364H.846c-.191 0-.346-.163-.346-.364V19.02c0-.202.155-.365.346-.365zm17.25 0h5.058c.191 0 .346.163.346.365v4.115c0 .201-.155.364-.346.364h-5.058c-.191 0-.346-.163-.346-.364V19.02c0-.202.155-.365.346-.365zm-.934 2.616h-1.568m-2.156 0h-2.876m-2.283 0H6.711M20.674 6.005v1.65m-.049 1.95l.049 4.79m0 2.107v1.65M6.847 3.116h1.568m2.37 0h2.652m2.09 0h1.568M3.424 18.045v-1.65m0-2v-4.79m0-1.95v-1.65M12.3 8.81v-1.65m0-1.815v-1.49m0 11.763v1.65m0 1.995v1.65M7.99 11.836h-.784m-2.745 0h-.784m12.219 0h1.568m1.895 0h1.316M8.702 8.841h6.596c.25 0 .452.214.452.476v5.368c0 .262-.203.475-.452.475H8.702c-.25 0-.452-.213-.452-.475V9.317c0-.262.202-.476.452-.476z"
                  transform="translate(-528.000000, -185.000000) translate(43.000000, 89.000000) translate(492.000000, 126.000000)"
                />
              </g>
              <text
                fill="#FFF"
                font-family="Rubik-Regular, Rubik"
                font-size="16"
                transform="translate(-528.000000, -185.000000) translate(43.000000, 89.000000) translate(492.000000, 126.000000)"
              >
                <tspan x="34" y="18">
                  Infrastructure
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
