import { A10Row, A10Tooltip, A10Icon, A10Col } from '@gui-libraries/widgets'
import React from 'react'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { Messages } from 'src/locale/en/'

const NA = 'N/A'
const prefixCls = 'device-overview'

const DeviceOverview: React.FC = (props: IObject) => {
  const messages = new Messages()
  const {
    deviceObj,
    clusterObj,
    ethernetInterfaces,
    tooltip,
    devLicStateCheck,
    devLiceCheckProgress,
    deviceSN,
  } = props

  const overviewContent = [
    {
      title: messages?.NAME,
      content: deviceObj?.name,
    },
    {
      title: messages?.STATE,
      content: (
        <div className={`${prefixCls}-state`}>
          <HealthStatus
            type={
              deviceObj?.['registration-state'] === 'REGISTERED'
                ? 'up'
                : 'undefined'
            }
            tooltip={deviceObj?.['registration-state'] || NA}
            toolTipArrowPosition="topLeft"
          />
          <span className="pdl-5">{deviceObj?.['registration-state']}</span>
        </div>
      ),
      tooltip: deviceObj?.['registration-state'],
    },
    {
      title: 'Model',
      content: deviceObj?.model,
    },
    {
      title: messages?.ACOS_VERSION,
      content: deviceObj?.['primary-sw-version'],
    },
    {
      title: 'Serial #',
      content: deviceSN,
    },
    {
      title: messages?.LOCATION,
      content: deviceObj?.location,
    },
    {
      title: 'Harmony License',
      content: (
        <div className={deviceObj?.hcLicenseColor}>{deviceObj?.hcLicense}</div>
      ),
      tooltip: deviceObj?.hcLicense,
    },
    {
      title: 'Device License',
      content: (
        <>
          <A10Tooltip
            title={
              deviceObj?.deviceLicense === '--'
                ? 'Check device for license'
                : ''
            }
            placement="topLeft"
            overlayClassName="toolTipWidth"
          >
            <span
              className={
                deviceObj?.deviceLicense === '--'
                  ? ''
                  : deviceObj?.deviceLicenseColor
              }
            >
              {deviceObj?.deviceLicense || NA}
            </span>
          </A10Tooltip>
          {devLiceCheckProgress ? (
            <A10Tooltip
              placement="bottom"
              title="Device license status being checked..."
              arrowPointAtCenter
              overlayStyle={{ width: '150px' }}
            >
              <A10Icon style={{ marginLeft: '10px' }} type="sync" spin />
            </A10Tooltip>
          ) : (
            <A10Tooltip
              placement="bottom"
              title="Click to refresh the device license"
              arrowPointAtCenter
              overlayStyle={{ width: '150px' }}
            >
              <A10Icon
                style={{ marginLeft: '10px' }}
                app="global"
                type="refresh"
                onClick={devLicStateCheck}
                className="pointer"
              />
            </A10Tooltip>
          )}
        </>
      ),
      tooltip: deviceObj?.deviceLicense,
    },
    {
      title: 'Associated Cluster',
      content: clusterObj?.['display-name'] || clusterObj?.name,
    },
    {
      title: 'Cluster Type',
      content:
        clusterObj?.type === 'single'
          ? 'Single'
          : clusterObj?.type === 'vcs'
          ? clusterObj?.['data-plane-ha'] === 'scaleout'
            ? 'VCS/Scaleout'
            : 'VCS/VRRP-A'
          : clusterObj?.type === 'multinode'
          ? clusterObj?.['data-plane-ha'] === 'scaleout'
            ? 'Multinode/Scaleout'
            : 'Multinode/VRRP-A'
          : clusterObj?.type
          ? clusterObj?.type.toUpperCase()
          : '',
    },
    {
      title: 'Cluster State',
      content: clusterObj?.['provision-state'],
    },
    {
      title: 'VCS State',
      content: deviceObj?.vcsState ? deviceObj?.vcsState : 'Unknown',
    },
    {
      title: '# Partitions',
      content: deviceObj?.['partition-list']
        ? deviceObj?.['partition-list']?.length
        : 0,
    },
    {
      title: 'Mgmt. IP/Port',
      content: deviceObj?.['mgmt-ip']
        ? deviceObj?.['mgmt-ip'] + ' / ' + deviceObj?.['mgmt-port-secure']
        : '',
    },
    {
      title: '# Interfaces',
      content: ethernetInterfaces?.length || 0,
    },
    {
      title: 'Host',
      content: deviceObj?.host,
    },
  ]

  const renderCardItem = (data: IObject) => {
    return (
      <div className={`${prefixCls}-card-item`}>
        <A10Row>
          <A10Col span={12} className={`${prefixCls}-card-item-title`}>
            {data?.title}
          </A10Col>
          <A10Col span={12} className={`${prefixCls}-card-item-content`}>
            <A10Tooltip
              placement="bottomLeft"
              title={data?.tooltip || data?.content || NA}
            >
              {data?.content || NA}
            </A10Tooltip>
          </A10Col>
        </A10Row>
      </div>
    )
  }

  const renderCardColumn = (rowData: IObject[]) => {
    return <A10Col span={6}>{rowData.map(data => renderCardItem(data))}</A10Col>
  }

  const renderCard = () => {
    const itemCntInCol = Math.ceil(overviewContent?.length / 4)
    return (
      <>
        <A10Row className={`${prefixCls}-row`}>
          {[...Array(4)]?.map((_, i) =>
            renderCardColumn(
              overviewContent?.slice(i * itemCntInCol, (i + 1) * itemCntInCol),
            ),
          )}
        </A10Row>
      </>
    )
  }

  return (
    <div className="device-detail-container">
      <div className={`${prefixCls}-title`}>OVERVIEW</div>
      {renderCard()}
    </div>
  )
}

export default DeviceOverview
