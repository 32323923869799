import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import { A10Icon, A10Form, A10Input } from '@gui-libraries/widgets'

import { IDefaultMethods } from 'src/containers/Controller'
import { Messages } from 'src/locale/en'
import Utilities from 'src/services/Utilities/Utilities'
import { DashboardService } from 'src/services/DashboardService'
import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { HelpInfo } from 'src/components/shared/HelpInfo'

import './styles/tenantaddform.scss'

export interface ITenantAddFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  tenant: any
  tenants: Array<any>
  form: any
  onRef?: any
  handleChange(key: string, event: any): void
}
export interface ITenantAddFormState {
  tenantName: string
  tenantDName: string
  description: string
  isEmailID: boolean
  tenants: any[]
}

class TenantAddForm extends A10Container<
  ITenantAddFormProps,
  ITenantAddFormState
> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  Messages = new Messages()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  constructor(
    props: ITenantAddFormProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    this.state = {
      tenantName: '',
      tenantDName: '',
      description: '',
      isEmailID: true,
      tenants: this.props.tenants,
    }
  }

  validateDuplicateTenant = (rule: any, tenantName: any, cb: any) => {
    let isValid: boolean = true
    const index = this.props.tenants.findIndex((key: any) => {
      return (
        key.name === tenantName ||
        (key['display-name'] && key['display-name'] === tenantName)
      )
    })
    if ('' === tenantName) {
      rule.message = this.Messages.EMPTY_TENANT_NAME
      isValid = false
    } else if (!this.Utilities.validateName(tenantName) || !isNaN(tenantName)) {
      rule.message = this.Messages.VALIDATION_ADD_TENANT_NAME
      isValid = false
    } else if (index > -1) {
      rule.message = this.Messages.DUPLICATE_TENANT_NAME
      isValid = false
    }
    if (tenantName.toLowerCase() === 'pso') {
      isValid = false
      rule.message =
        tenantName +
        ' is a reserverd keyword, Cannot create tenant with this name'
    }
    return isValid ? cb() : cb(true)
  }

  validateDuplicateTenantDisplayName = (
    rule: any,
    tenantDisplayName: any,
    cb: any,
  ) => {
    let isValid: boolean = true
    const index = this.props.tenants.findIndex((key: any) => {
      return (
        key.name === tenantDisplayName ||
        (key['display-name'] && key['display-name'] === tenantDisplayName)
      )
    })
    if ('' === tenantDisplayName) {
      // rule.message = this.Messages.ENTER_DISPLAY_NAME
      // isValid = false
    } else if (
      !this.Utilities.validateDisplayName(tenantDisplayName) ||
      !isNaN(tenantDisplayName)
    ) {
      rule.message = this.Messages.INVALID_TENANT_NAME
      isValid = false
    } else if (index > -1) {
      rule.message = this.Messages.DUPLICATE_TENANT_NAME
      isValid = false
    }
    if (tenantDisplayName.toLowerCase() === 'pso') {
      isValid = false
      rule.message =
        tenantDisplayName +
        ' is a reserverd keyword, Cannot create tenant with this name'
    }
    return isValid ? cb() : cb(true)
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  render() {
    const {
      tenantName,
      tenantDName,
      description,
    } = this.props.tenant
    const { handleChange } = this.props

    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }
    const { getFieldDecorator } = this.props.form
    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel
          title={
            <>
              <A10IconTextGroup
                text={
                  <>
                    <span className="tenantTitle">Tenant Info</span>
                  </>
                }
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            </>
          }
        >
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="mandatoryField">
                  {this.Messages.TENANT_NAME}
                </span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="left"
                    title={this.Messages.TENANT_NAME + ' * '}
                    helpKey="HELP_ORG_TENANT_NAME"
                  />
                </span>
              </>
            }
          >
            <div>
              {getFieldDecorator('tenantName', {
                rules: [
                  {
                    validator: this.validateDuplicateTenant.bind(this),
                  },
                ],
                initialValue: tenantName,
              })(
                <A10Input
                  type="text"
                  placeholder={this.Messages.TENANT_NAME}
                  onChange={handleChange.bind(this, 'tenantName')}
                />,
              )}
            </div>
          </A10Form.Item>

          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span className="">{this.Messages.DISPLAY_NAME}</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="left"
                    title={this.Messages.DISPLAY_NAME + ' * '}
                    helpKey="HELP_ORG_TENANT_DISPLAY_NAME"
                  />
                </span>
              </>
            }
          >
            <div>
              {getFieldDecorator('tenantDName', {
                rules: [
                  {
                    validator: this.validateDuplicateTenantDisplayName.bind(
                      this,
                    ),
                  },
                ],
                initialValue: tenantDName,
              })(
                <A10Input
                  type="text"
                  placeholder={tenantName || this.Messages.DISPLAY_NAME}
                  onChange={handleChange.bind(this, 'tenantDName')}
                />,
              )}
            </div>
          </A10Form.Item>

          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                {this.Messages.DESCRIPTION}
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="left"
                    title={this.Messages.DESCRIPTION}
                    helpKey="HELP_ORG_TENANT_DESCRIPTION"
                  />
                </span>
              </>
            }
          >
            <A10Input.TextArea
              type="text"
              placeholder={this.Messages.DESCRIPTION}
              defaultValue={description}
              onChange={handleChange.bind(this, 'description')}
            />
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    )
  }
}
export default setupA10Container(A10Form.create()(TenantAddForm))
