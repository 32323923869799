import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { AutoFormV15 } from '@gui-libraries/apps'

import storage from 'src/libraries/storage'

export interface IPasswordPolicyProps extends IA10ContainerDefaultProps {
  data: any[]
  tenant: string
  logicalPartition: string
  scope: string
}
export interface IPasswordPolicyState {
  viewOnly: boolean
}
class PasswordPolicy extends A10Container<IPasswordPolicyProps, any> {
  constructor(props: IPasswordPolicyProps) {
    super(props)
    this.state = {
      viewOnly: false,
    }
  }
  componentDidMount() {
    this.viewOnly()
  }
  viewOnly = () => {
    if (
      this.props.scope !== 'provider' ||
      (storage.get.ADMIN_LEVEL === 'provider' && !storage.get.IS_OPERATOR_USER)
    ) {
      this.setState({
        viewOnly: false,
      })
    } else {
      this.setState({
        viewOnly: true,
      })
    }
  }

  render() {
    const { tenant, logicalPartition } = this.props
    const apiPrefix = `hpcapi/v3/provider/root/tenant/${tenant}/logical-partition/${logicalPartition}/`

    const { viewOnly } = this.state
    return (
      <>
        <AutoFormV15
          hccEnv={true}
          schemaPath="system/settings/policy"
          apiPrefix={apiPrefix}
          viewMode={viewOnly}
        />
      </>
    )
  }
}

export default setupA10Container(PasswordPolicy)
