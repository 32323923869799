import moment from 'moment'

import storage, { severityMap } from 'src/libraries/storage'
import * as basicUtils from './basicUtils'
import { getFilterQuery } from '../Common/FilterList'
import { fetchClusterAndDevice, fetchTenant } from './basicApiUtils'

const fetchEventApi = async (
  httpClient: IObject,
  xAccount: string,
  payload: IObject,
) => {
  const headers: IObject = {}
  if (xAccount) {
    headers['X-Account'] = xAccount
    const { data: data } = await httpClient.post(
      '/analytics/thunder-adc/system_logs_to_hc',
      payload,
      {
        absoluteBasePath: true,
        headers,
      },
    )
    return data as IObject
  } else {
    return {}
  }
}

export const getEvents = async (
  httpClient: IObject,
  pager: IObject = { page: 1, pageSize: 20, searchValue: '' },
  selectedTimePeriod: IObject = {},
  apiFilters: IObject,
) => {
  const page = pager.page || 1
  const pageSize = pager.pageSize || 20
  const sort = pager.sort || 'desc'
  const isClusterDashboard = basicUtils.isClusterDashboard()
  const currentClusterId = basicUtils.getCurrentClusterId()
  const clusterFilter = isClusterDashboard
    ? { cluster_id: currentClusterId }
    : {}

  const filterQueries = getFilterQuery('event')(
    selectedTimePeriod.startTime,
    selectedTimePeriod.endTime,
    clusterFilter,
  )
  const payload = {
    ...filterQueries,
    logs: {
      type: 'event',
      from: (page - 1) * pageSize,
      sort,
      size: pageSize,
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      filterby: { and: { ...clusterFilter, ...apiFilters } },
    },
    total: {
      type: 'event',
      rangeby: {
        start: selectedTimePeriod.startTime,
        end: selectedTimePeriod.endTime,
        field: 'ts',
      },
      fields: ['ts'],
      aggregation: 'count',
      filterby: { and: { ...clusterFilter, ...apiFilters } },
    },
  }
  const tenants = basicUtils.getTenantUuids()
  const eventData = await fetchEventApi(httpClient, tenants, payload)
  const [events, total, filterData, severity] = basicUtils.analysisData(
    eventData,
  )
  // console.log('==================== Events')
  // console.log('events', events)
  // console.log('total', total)
  // console.log('filterData', filterData)

  // console.log(_.keys(filterData.cluster_id))
  // console.log(_.keys(filterData.device_uuid))
  // const clusterUuids = _.keys(filterData.cluster_id)
  // // const deviceUuids = _.keys(filterData.device_uuid)
  // const deviceUuids = [] as string[]
  // const uuids = _.concat(clusterUuids, deviceUuids)
  // const uuidPromises = new Array()
  // uuids.map((uuid: string) => {
  //   httpClient
  //     .get(`/hpcapi/v3/uuid/${uuid}`, {
  //       absoluteBasePath: true,
  //     })
  //     .then((res: any) => {
  //       uuidPromises.push(res)
  //     })
  // })
  // const result = (await httpClient.all(uuidPromises)) || []
  // const objectNames: IObject = {}
  // result.map((item: IObject) => {
  //   const { data = {} } = item
  //   if (data.cluster) {
  //     const clusterUuid = data.cluster.uuid
  //     objectNames[clusterUuid] = data.cluster.name
  //   }
  // })
  const [objMapping, filterMapping] = await fetchClusterAndDevice(
    httpClient,
    events,
    filterData,
  )
  const [tenantMapping, tenantFiltermapping] = fetchTenant(events, filterData)
  return {
    data: events,
    total,
    pager,
    filters: filterData,
    severity,
    filterMapping: { ...filterMapping, ...tenantFiltermapping },
    objMapping: { ...objMapping, ...tenantMapping },
  }
}

export const getEventsDisplay = async (
  httpClient: IObject,
  pager: IObject = { page: 1, pageSize: 20 },
  selectedTimePeriod: IObject = {},
) => {
  let apiData: any = {}
  try {
    apiData = await getEvents(httpClient, pager, selectedTimePeriod, {})
  } catch {
    apiData.data = []
  }
  let currentTenant: IObject
  try {
    currentTenant = storage.get.CURRENT_TENANT
  } catch {
    /* istanbul ignore next */
    currentTenant = null
  }
  const tenants = basicUtils.getAllTenants()

  const dataDisplay = apiData.data.map((event: IObject) => {
    event.time = event.ts
      ? moment(new Date(event.ts)).format('MMM DD, YYYY hh:mm A')
      : ''
    /* istanbul ignore else */
    if (currentTenant) {
      event.name = ''
    } else {
      const selectedTenant = tenants.find((tenant: IObject) => {
        return tenant.uuid === event.account_id
      })
      event.name = selectedTenant ? selectedTenant.name.split('.')[0] : ''
      event.fullName = selectedTenant.name
    }

    return event
  })
  return { dataDisplay, ...apiData }
}

export const getEventTraffic = async (
  httpClient: IObject,
  selectedTimePeriod: IObject = {},
) => {
  let slotInterval = Math.floor(
    (selectedTimePeriod.endTime - selectedTimePeriod.startTime) / 100,
  )
  if (slotInterval < 60000) {
    /* istanbul ignore next */
    slotInterval = 60000
  }
  const payload = {
    event: {
      fields: ['ts'],
      aggregation: 'count',
      sort: 'asc',
      rangeby: {
        start: Number(selectedTimePeriod.startTime),
        end: Number(selectedTimePeriod.endTime),
        field: 'ts',
      },
      groupby: 'severity',
      field: 'ts',
      histogram: {
        interval: slotInterval,
        field: 'ts',
      },
      type: 'event',
    },
  }
  const stackChartArray = new Array()
  const severityCount = { total: 0, filters: {} }
  const tenants = basicUtils.getTenantUuids()
  try {
    const { event: eventData = {} } = await fetchEventApi(
      httpClient,
      tenants,
      payload,
    )
    /* istanbul ignore next */
    for (const severity in eventData) {
      if (eventData.hasOwnProperty(severity)) {
        const stackData = new Array()
        const severityDef = severityMap[Number(severity)].definition
        for (const key in eventData[severity].ts_count) {
          if (eventData[severity].ts_count.hasOwnProperty(key)) {
            stackData.push([Number(key), eventData[severity].ts_count[key]])
            // eventAlertCount += eventData[severity].ts_count[key]
            severityCount.filters[severityDef]
              ? (severityCount.filters[severityDef] +=
                  eventData[severity].ts_count[key])
              : (severityCount.filters[severityDef] =
                  eventData[severity].ts_count[key])
          }
        }
        const severityObj = {
          data: stackData,
          name: severityDef,
          color: severityMap[Number(severity)].color,
        }
        stackChartArray.push(severityObj)
      }
    }
  } catch {
    // Return default traffic data
  }
  return { stackChartArray, severityCount }
}
