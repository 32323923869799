import React, { useMemo, useRef, useState } from 'react'
import { A10Loader, A10Tooltip } from '@gui-libraries/widgets'
import { isValidNumber, truncateVal } from '@gui-libraries/dgf'
import { useAsyncEffect } from '@gui-libraries/framework'

import styles from './styles/index.module.less'

const PARComponent: React.FC<any> = props => {
  const { type, selectedContext, height, startTime, endTime, service } = props

  const refContainer = useRef()
  const [isLoading, setIsLoading] = useState(true)
  const [par, setPar] = useState(0)

  const id = type

  const throughputPayload = useMemo(() => {
    const filter = selectedContext['cluster-uuid'] !== '' && {
      filterby: {
        and: { cluster_id: selectedContext['cluster-uuid'] },
      },
    }
    return {
      [`${id}_avg`]: {
        rangeby: {
          start: startTime,
          end: endTime,
          field: 'ts',
        },
        fields: ['total_bytes_in', 'total_bytes_out'],
        aggregation: 'avg',
        ...filter,
      },
      [`${id}_peak`]: {
        rangeby: {
          start: startTime,
          end: endTime,
          field: 'ts',
        },
        fields: ['total_bytes_in', 'total_bytes_out'],
        aggregation: 'max',
        ...filter,
      },
    }
  }, [startTime, endTime])

  const formatData = (deviceStats: IObject) => {
    const {
      [`${id}_avg`]: avgData = {},
      [`${id}_peak`]: peakData = {},
    } = deviceStats
    const avgIn = isValidNumber(avgData?.total_bytes_in_avg)
      ? avgData.total_bytes_in_avg
      : 0
    const avgOut = isValidNumber(avgData?.total_bytes_out_avg)
      ? avgData.total_bytes_out_avg
      : 0
    const avgTotal = avgIn + avgOut
    const peakIn = isValidNumber(peakData?.total_bytes_in_max)
      ? peakData.total_bytes_in_max
      : 0
    const peakOut = isValidNumber(peakData?.total_bytes_out_max)
      ? peakData.total_bytes_out_max
      : 0
    const peakTotal = peakIn + peakOut

    return avgTotal > 0 ? truncateVal(peakTotal / avgTotal, 'quantity') : 0
  }
  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      const res = await service.getDeviceStats({
        payload: throughputPayload,
      })
      const data = formatData(res)
      setPar(data)
      setIsLoading(false)
    } catch (e) {
      console.error(`Fail to get device stats. ${e}`) // todo: err log
    } finally {
      setIsLoading(false)
    }
  }, [throughputPayload])

  return (
    <div
      ref={refContainer}
      style={{
        height,
      }}
    >
      {isLoading && <A10Loader container={refContainer} />}
      {!isLoading && (
        <>
          <div className={styles.container}>
            <div className={styles.metricContainer}>
              <A10Tooltip title="Peak Average Ratio">
                <div className={styles.subTitle}>PAR</div>
              </A10Tooltip>
              <div>
                <span className={styles.metric}>{par}</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default PARComponent
