import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'

import { IDefaultMethods } from 'src/containers/Controller'
import { ApplicationConfigs } from 'src/constants/ApplicationConfigs'
import { AppRoot } from 'src/settings/appRoot'
import { Alert } from './Alert'
import { Audit } from './Audit'
import { Events } from './Events'
import { Report } from './Report'
import Workflow from 'src/containers/Controller/Dashboard/Common/Workflow'
import { Utilities } from 'src/services/Utilities/Utilities'
import storage from 'src/libraries/storage'

import './styles/monitor.scss'

export interface IMonitorProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  tenantToggled: boolean
  selectedApp: string
  onSelectApp: (selectedApp: string) => void
  tenantChange: any
}
export interface IMonitorState {
  application: any
}

class Monitor extends A10Container<IMonitorProps, IMonitorState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  state: IMonitorState = {
    application: {
      name: '',
      subApps: [],
    },
  }
  ApplicationConfigs = new ApplicationConfigs()
  AppRoot = new AppRoot()
  Utilities = new Utilities()

  componentDidMount() {
    const {
      storage: { remove },
    } = this.context

    remove.CONTEXT_LEVEL()
    remove.DGF_SELECTED_TENANT()
    remove.DGF_ACCOUNTS()
    remove.SELECTEDCONTEXT()
    remove.AUTH()
    remove.CONTEXT()

    this.updateState()
  }
  componentDidUpdate(prevProps: IMonitorProps, prevState: IMonitorState) {
    const { tenantToggled } = this.props
    if (prevProps.tenantToggled !== tenantToggled) {
      this.updateState()
    }
  }

  updateState = () => {
    const { selectedApp, onSelectApp } = this.props
    const applications = this.ApplicationConfigs.getAvailableApps()
    const applicationIndex = applications.findIndex((key: any) => {
      return key.name.toLowerCase() === 'monitor'
    })
    let selectedAppDetail = applications[applicationIndex].subApps.find(
      (subApp: any) => {
        return subApp.name.toLowerCase() === selectedApp.toLowerCase()
      },
    )

    if (!selectedAppDetail) {
      selectedAppDetail = applications[applicationIndex].subApps[0]
    }

    const allowedRoles = this.ApplicationConfigs.getAllowedRolesSubApps(
      'Monitor',
      selectedAppDetail.name,
    )

    if (allowedRoles) {
      const adminLevel = storage.get.ADMIN_LEVEL
      const allowedIndex = allowedRoles.findIndex((key: any) => {
        return key === adminLevel
      })
      if (allowedIndex >= 0) {
        this.setState({
          application: applications[applicationIndex],
        })

        if (
          !selectedAppDetail.tenantContext &&
          !storage.get.CURRENT_TENANT.uuid
        ) {
          storage.set.DRILL_LEVEL('provider')
          this.AppRoot.setRootScopeElement('currentDrillLevel', 'provider')
        }
        storage.remove.SUPER_ADMIN_MODE()
        storage.set.CURRENT_STATE('monitor')
        this.AppRoot.setRootScopeElement('currentState', 'monitor')

        if (selectedAppDetail.name !== selectedApp) {
          if (onSelectApp instanceof Function) {
            onSelectApp(selectedAppDetail.name)
          }
        }

        return
      }
    }
    this.Utilities.showMessage('INSUFFICENT_PRIVILEGE', 0, 1)
  }

  getTenantName = () => {
    let tenantName = ''
    try {
      const tenant = storage.get.CURRENT_TENANT
      tenantName = tenant.name
    } catch {
      //
    }
    return tenantName
  }

  render() {
    const { defaultMethods, tenantToggled } = this.props
    const selectedApp = this.props.selectedApp.toLowerCase()
    const tenantName = this.getTenantName()
    switch (selectedApp) {
      case 'alert':
        return (
          <Alert
            tenantToggled={tenantToggled}
            tenantChange={this.props.tenantChange}
            defaultMethods={defaultMethods}
            selectedTenant={tenantName}
          />
        )
      case 'report':
        return (
          <Report
            tenantToggled={tenantToggled}
            defaultMethods={defaultMethods}
          />
        )
      case 'workflow':
        return <Workflow tenantToggled={tenantToggled} />
      case 'audit':
        return (
          <Audit tenantToggled={tenantToggled} selectedTenant={tenantName} />
        )
      case 'event':
        return <Events selectedTenant={tenantName} />
      default:
        return (
          <Alert
            tenantToggled={tenantToggled}
            defaultMethods={defaultMethods}
            selectedTenant={tenantName}
          />
        )
    }
  }
}

export default setupA10Container(Monitor)
