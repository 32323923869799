import React from 'react'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10SlidingPage, A10List, A10Badge } from '@gui-libraries/widgets'

import WorkflowStatus from './WorkflowStatus'
import AlertStatus from './AlertStatus'
import Settings from 'src/containers/Controller/Dashboard/Settings'
import storage from 'src/libraries/storage'
import { NavLink } from 'react-router-dom'

const styles = require('./styles/index.module.less')

interface INotificationTabs {
  alert: {
    data: IObject[]
    dataEndTime: number
  }
  workflow: {
    data: IObject[]
    dataEndTime: number
  }
}

export interface INotificationSlidingPageProps
  extends IA10ContainerDefaultProps {
  isOpen: boolean
  tabs: INotificationTabs
  activeTab: string
  onRequestClose?: () => void
}

export interface INotificationSlidingPageState {}

class NotificationSlidingPage extends A10Container<
  INotificationSlidingPageProps,
  INotificationSlidingPageState
> {
  constructor(props: INotificationSlidingPageProps) {
    super(props)

    this.state = {}
  }

  onClose = () => {
    const { onRequestClose, tabs, activeTab } = this.props
    if (_.isFunction(onRequestClose)) {
      onRequestClose(activeTab)
    }

    if (activeTab === 'alerts') {
      storage.set.ALERT_LAST_VIEWED_TIMESTAMP(tabs.alert.dataEndTime)
    }
    if (activeTab === 'workflows') {
      storage.set.WORKFLOW_LAST_VIEWED_TIMESTAMP(tabs.workflow.dataEndTime)
    }
  }

  renderAlerts = () => {
    const alertData = _.get(this.props, ['tabs', 'alert', 'data'], [])

    return (
      <A10List
        header={this.renderTitle('NOTIFICATIONS', alertData?.length || 0)}
        footer={
          <NavLink to="/controller/Notification">
            <div className={styles.viewAll} onClick={this.showAlertsPullup}>
              View All Notifications
            </div>
          </NavLink>
        }
        dataSource={alertData}
        renderItem={(data: IObject) => {
          const { alert, object } = data
          const { severity } = alert

          const deviceName = _.get(object, ['name'], '')
          const deviceType = _.isString(deviceName) ? deviceName[0] : ''

          return (
            <A10List.Item>
              <A10List.Item.Meta
                avatar={
                  <AlertStatus
                    title={deviceType.toLocaleUpperCase()}
                    status={severity}
                  />
                }
                title={alert.tenant}
                description={alert.def_name}
              />
            </A10List.Item>
          )
        }}
      />
    )
  }

  showAlertsPullup = () => {
    const {
      A10Dispatchers: { httpRequest },
    } = this.props

    httpRequest({
      namespace: Settings.namespace.pullupViewCurrentStatus,
      request: async () => {
        return await 'max'
      },
    })
    httpRequest({
      namespace: Settings.namespace.pullupViewCurrentTab,
      request: async () => {
        return await 'alert'
      },
    })
    httpRequest({
      namespace: Settings.namespace.alertPullUpViewStatus,
      request: async () => {
        return await Date.now()
      },
    })

    this.onClose()
  }

  renderWorkflows = () => {
    const workflowData = _.get(this.props, ['tabs', 'workflow', 'data'], [])
    return (
      <A10List
        header={this.renderTitle('WORKFLOWS', workflowData?.length || 0)}
        footer={
          <NavLink to="/controller/Monitor/Workflow">
            <div className={styles.viewAll} onClick={this.onClose}>
              View All Workflows
            </div>
          </NavLink>
        }
        dataSource={workflowData}
        renderItem={(data: IObject) => {
          const workflowMapObject: IObject = _.get(
            data,
            'workflowMapObject',
            {},
          )
          const object: IObject = _.get(data, 'object', {})

          const {
            status,
            obj_id: objId,
            obj_type: objType,
            workflow_id: workflowId,
            workflow_type: workflowType,
          } = workflowMapObject
          const { name: objName, device } = object

          let type: string = null
          switch (objType) {
            case 'DEVICE':
              type = 'D'
              break
            case 'PROVIDER':
              if (
                ['REGISTER_DEVICE', 'DEREGISTER_DEVICE'].includes(workflowType)
              ) {
                type = 'D'
              }
              break
            case 'CLUSTER':
              type = 'C'
              break
            case 'LOGICAL_PARTITION':
              type = 'LP'
              break
            case 'DEVICE_PARTITION':
              type = 'DP'
              break
          }
          const objNamePrefix = _.isString(device) && device ? device : ''
          return (
            <A10List.Item>
              <A10List.Item.Meta
                avatar={<WorkflowStatus status={status} title={type} />}
                title={
                  <>
                    {objNamePrefix && (
                      <span className="prefix-name">{`${objNamePrefix} `}</span>
                    )}
                    <span className="object-name">{`${objName || '--'} `}</span>
                  </>
                }
                description={
                  <>
                    <span className="workflow-type">{`${_.capitalize(
                      workflowType,
                    ) || '--'} `}</span>
                    <span className="status-text">{`${_.capitalize(status) ||
                      '--'}.`}</span>
                  </>
                }
              />
            </A10List.Item>
          )
        }}
      />
    )
  }

  renderTitle = (title: string, length: number) => (
    <div className={styles.title}>
      <span>{title}</span>
      <A10Badge count={length || 0} showZero />
    </div>
  )

  render() {
    const { isOpen, activeTab } = this.props

    return (
      <A10SlidingPage
        isOpen={isOpen}
        onRequestClose={this.onClose}
        overlayClassName={styles.notificationPage}
      >
        {activeTab === 'workflows'
          ? this.renderWorkflows()
          : this.renderAlerts()}
      </A10SlidingPage>
    )
  }
}

export default setupA10Container(NotificationSlidingPage)
