import {
  A10Card,
  A10Checkbox,
  A10Col,
  A10Message,
  A10Row,
} from '@gui-libraries/widgets'
import React, { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import { ActionButton } from 'src/components/shared/ActionButton'
import {
  ContentHeader,
  ContentSection,
  ContentTitle,
} from 'src/components/shared/ContentSection'
import { TroubleShootService } from 'src/services/index'
import MicroserviceDownloadForm from './MicroserviceDownloadForm'
import './styles/index.less'

const troubleshootService = new TroubleShootService()
interface ICheckedState {
  analytics: string[]
  configuration: string[]
  platform: string[]
}

interface IServiceObject {
  'display-name': string
  services: string[]
}

interface IMSResponse {
  analytics: IServiceObject
  configuration: IServiceObject
  platform: IServiceObject
}

const defaultCheckedState: ICheckedState = {
  analytics: [],
  configuration: [],
  platform: [],
}

export const getMicroServiceLogs = async () => {
  let apiResponse: IObject = null
  try {
    const {
      data: response,
    } = await troubleshootService.getMicroServiceLogsData(null, null, null)
    apiResponse = response
    console.log({ apiResponse })
  } catch (error) {
    apiResponse = null
  }
  return apiResponse
}

export const longPollSuccessCallback = (downloadData: IObject) => {
  const node = downloadData?.node
  const file = downloadData?.['log-location']
  const file_path = `${window.location.origin}/oconsole/hclogs/${node}/${file}`

  const anchorElement = document.createElement('a')
  anchorElement.href = file_path
  anchorElement.download = file_path.substr(file_path.lastIndexOf('/') + 1)
  document.body.appendChild(anchorElement)
  anchorElement.click()
  document.body.removeChild(anchorElement)

  const message = 'Logs downloaded successfully'
  A10Message.success(message, 10, close)
}

export const onSelectClick = (
  category: string,
  microServicesData: IMSResponse,
  checked: ICheckedState,
) => {
  if (microServicesData) {
    const allServices = [...microServicesData?.[category]?.services]
    let updatedList = { ...checked }

    if (checked[category].length === allServices?.length) {
      updatedList[category] = []
    } else {
      updatedList[category] = allServices
    }
    return updatedList
  }
}

export const download = (
  service: string,
  category: string,
  checked: ICheckedState,
) => {
  let updatedList = { ...checked }
  updatedList[category] = [...checked[category], service]
  return updatedList
}

const MicroServiceLogs = () => {
  const [isLoading, setLoading] = useState(false)
  const [microServicesData, setMicroServicesData] = useState(null)
  const [checked, setChecked] = useState(defaultCheckedState)
  const [slidingPage, setSlidingPage] = useState(false)

  const onDownloadClick = (service: string, category: string) => {
    setChecked(download(service, category, checked))
    setSlidingPage(true)
  }

  useEffect(() => {
    async function fetchMicroServiceLogs() {
      setLoading(true)
      const data = await getMicroServiceLogs()
      setMicroServicesData(data)
      setLoading(false)
    }
    fetchMicroServiceLogs()
  }, [])

  const isChecked = (item: string, category: string) =>
    checked[category].includes(item)

  const handleCheck = (event: IObject, category: string) => {
    let updatedList = { ...checked }

    if (event.target.checked) {
      updatedList[category] = [...checked[category], event.target.value]
    } else {
      updatedList[category].splice(
        checked[category].indexOf(event.target.value),
        1,
      )
    }
    setChecked(updatedList)
  }

  const onSelectAllClick = (category: string) => {
    setChecked(onSelectClick(category, microServicesData, checked))
  }

  const handleSave = async (days: number) => {
    const data: string[] = [
      ...checked?.analytics,
      ...checked?.configuration,
      ...checked?.platform,
    ]
    const payload = {
      services: data,
      days: days,
    }
    try {
      const {
        data: logID,
      } = await troubleshootService.postMicroServiceLogsDownload(
        null,
        payload,
        null,
      )
      if (logID) {
        const message = 'Collecting logs...'
        A10Message.info(message, 10, close)
        setSlidingPage(!!false)
        setLoading(!!true)
        let status: string
        let newStatus: string = 'RUNNING'
        let downloadInfo = null
        do {
          const {
            data: logInfo,
          } = await troubleshootService.getMicroServiceLogsDownload(
            null,
            null,
            logID,
          )

          newStatus = logInfo?.status
          if (status !== newStatus) {
            status = newStatus
          }
          await new Promise(r => setTimeout(r, 5000))
          downloadInfo = logInfo
        } while (newStatus === 'RUNNING')
        longPollSuccessCallback(downloadInfo)
        setChecked(defaultCheckedState)
      }
    } catch {
      const message = 'Failed to download logs'
      A10Message.error(message, 10, close)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ContentSection className="content-body">
      <A10Row>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col>
            <ContentTitle title="Microservices" />
          </A10Col>
          <A10Col>
            <ActionButton
              disabled={
                !(
                  checked?.analytics?.length ||
                  checked?.configuration?.length ||
                  checked?.platform?.length
                )
              }
              testID="download-selected"
              text="Download Selected"
              onClick={() => setSlidingPage(true)}
              iconProps={{ app: 'global', type: 'download' }}
            />
          </A10Col>
        </ContentHeader>
        <A10Row className="content-wrapper">
          <A10Col span={8}>
            <A10Card className="card-wrapper">
              <A10Row className="title-checkbox-wrapper">
                <A10Checkbox
                  testID="selectAll-analytics"
                  onChange={() => onSelectAllClick('analytics')}
                  checked={
                    checked?.analytics?.length ===
                    microServicesData?.analytics?.services?.length
                  }
                >
                  <span className="heading-span">ANALYTICS</span>
                </A10Checkbox>
              </A10Row>
              {!isLoading ? (
                microServicesData?.analytics?.services?.map(
                  (service: string) => {
                    return (
                      <A10Row className="checkbox-wrapper bottom-border">
                        <A10Col span={24} className="subMenu">
                          <A10Checkbox
                            testID="analytics-check"
                            onChange={(event: IObject) =>
                              handleCheck(event, 'analytics')
                            }
                            value={service}
                            checked={isChecked(service, 'analytics')}
                          >
                            {service.split('-').join(' ')}
                          </A10Checkbox>
                        </A10Col>
                        <A10Col span={9} className="button-wrapper">
                          <ActionButton
                            testID="analytics-download"
                            text="Download Log"
                            onClick={() =>
                              onDownloadClick(service, 'analytics')
                            }
                            iconProps={{ app: 'global', type: 'download' }}
                          />
                        </A10Col>
                      </A10Row>
                    )
                  },
                )
              ) : (
                <A10Col>
                  <ReactLoading
                    type="bars"
                    color="#4a90e2"
                    height={40}
                    width={40}
                  />
                </A10Col>
              )}
            </A10Card>
          </A10Col>
          <A10Col span={8}>
            <A10Card className="card-wrapper">
              <A10Row className="title-checkbox-wrapper">
                <A10Checkbox
                  testID="selectAll-configuration"
                  onChange={() => onSelectAllClick('configuration')}
                  checked={
                    checked?.configuration?.length ===
                    microServicesData?.configuration?.services?.length
                  }
                >
                  <span className="heading-span">SERVICE CONFIG</span>
                </A10Checkbox>
              </A10Row>

              {!isLoading ? (
                microServicesData?.configuration?.services?.map(
                  (service: string) => {
                    return (
                      <A10Row className="checkbox-wrapper bottom-border">
                        <A10Col span={24} className="subMenu">
                          <A10Checkbox
                            testID="configuration-check"
                            onChange={(event: IObject) =>
                              handleCheck(event, 'configuration')
                            }
                            value={service}
                            checked={isChecked(service, 'configuration')}
                          >
                            {service.split('-').join(' ')}
                          </A10Checkbox>
                        </A10Col>
                        <A10Col span={9} className="button-wrapper">
                          <ActionButton
                            text="Download Log"
                            onClick={() =>
                              onDownloadClick(service, 'configuration')
                            }
                            iconProps={{ app: 'global', type: 'download' }}
                          />
                        </A10Col>
                      </A10Row>
                    )
                  },
                )
              ) : (
                <A10Col>
                  <ReactLoading
                    type="bars"
                    color="#4a90e2"
                    height={40}
                    width={40}
                  />
                </A10Col>
              )}
            </A10Card>
          </A10Col>
          <A10Col span={8}>
            <A10Card className="card-wrapper">
              <A10Row className="title-checkbox-wrapper">
                <A10Checkbox
                  testID="selectAll-platform"
                  onChange={() => onSelectAllClick('platform')}
                  checked={
                    checked?.platform?.length ===
                    microServicesData?.platform?.services?.length
                  }
                >
                  <span className="heading-span">HC PLATFORM</span>
                </A10Checkbox>
              </A10Row>
              {!isLoading ? (
                microServicesData?.platform?.services?.map(
                  (service: string) => {
                    return (
                      <A10Row className="checkbox-wrapper bottom-border">
                        <A10Col span={24} className="subMenu">
                          <A10Checkbox
                            testID="platform-check"
                            onChange={(event: IObject) =>
                              handleCheck(event, 'platform')
                            }
                            value={service}
                            checked={isChecked(service, 'platform')}
                          >
                            {service.split('-').join(' ')}
                          </A10Checkbox>
                        </A10Col>
                        <A10Col span={9} className="button-wrapper">
                          <ActionButton
                            text="Download Log"
                            onClick={() => onDownloadClick(service, 'platform')}
                            iconProps={{ app: 'global', type: 'download' }}
                          />
                        </A10Col>
                      </A10Row>
                    )
                  },
                )
              ) : (
                <A10Col>
                  <ReactLoading
                    type="bars"
                    color="#4a90e2"
                    height={40}
                    width={40}
                  />
                </A10Col>
              )}
            </A10Card>
          </A10Col>
        </A10Row>
      </A10Row>
      <MicroserviceDownloadForm
        show={slidingPage}
        title="Download Microservice Log files"
        onSubmitForm={handleSave}
        onClose={() => setSlidingPage(!!false)}
      />
    </ContentSection>
  )
}

export default MicroServiceLogs
