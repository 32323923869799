import React from 'react'
import { A10Component } from '@gui-libraries/framework'
import { A10Badge } from '@gui-libraries/widgets'
import './styles/roundnumber.scss'

export interface IRoundNumberProps {
  number: number
}
export interface IRoundNumberState {}

class RoundNumber extends A10Component<IRoundNumberProps, IRoundNumberState> {
  render() {
    return (
      <A10Badge
        count={this.props.number}
        overflowCount={this.props.number}
        showZero={true}
        style={{
          backgroundColor: '#90A4AE',
          color: '#ffffff',
          margin: '0px 10px',
          borderRadius: '12px',
          boxShadow: 'none',
        }}
      />
    )
  }
}
export default RoundNumber
