import React from 'react'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import {
  A10Icon,
  A10Form,
  A10Input,
  A10InputNumber,
  A10Select,
  A10Alert,
  A10Button,
  A10Tooltip,
  A10Slider,
  A10Row,
  A10Col,
} from '@gui-libraries/widgets'
import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { Messages } from 'src/locale/en/Messages'
import { InfrastructureService, OrganizationService } from 'src/services/'
import { Utilities } from 'src/services'
import { HelpInfo } from 'src/components/shared/HelpInfo'
const styles = require('./styles/changelogcapacity.scss')

export interface IChangeLogCapacityProps extends IA10ContainerDefaultProps {
  logCapacityObj: any
  form: any
  onRef?: any
  handleChange?: (data: any, enableSave: boolean) => void
  handleConfirmChange?: (data: any) => void
}
export interface IChangeLogCapacityState {
  logCapacityObj: any
  valueChanged: boolean
}

class ChangeLogCapacity extends A10Container<
  IChangeLogCapacityProps,
  IChangeLogCapacityState
> {
  Messages = new Messages()
  OrganizationService = new OrganizationService()
  InfrastructureService = new InfrastructureService()
  Utilities = new Utilities()

  constructor(props: IChangeLogCapacityProps) {
    super(props)

    this.state = {
      logCapacityObj: {...this.props.logCapacityObj},
      valueChanged: false,
    }
  }

  static getDerivedStateFromProps(
    nextProps: IChangeLogCapacityProps,
    prevState: IChangeLogCapacityState,
  ) {
    if (nextProps.logCapacityObj !== prevState.logCapacityObj) {
      return {
        logCapacityObj: nextProps.logCapacityObj,
      }
    }
    return null
  }

  onChangeCallback(logCapacityObj: any, enableSave: boolean) {
    this.props.handleChange(logCapacityObj, enableSave)
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  calculatePercentage(value: number) {
    if (this.state.logCapacityObj.maxCapacity > 0) {
      return (value / this.state.logCapacityObj.maxCapacity) * 100
    }
    return 0
  }

  validateLogCapacity = (value: number) => {
    const { logCapacityObj } = this.state
    const { showMessage } = this.Utilities
    let enableSave = true
      
    if (value < logCapacityObj.sharedLogValue) {
      showMessage(
        'Log capacity cannot be lesser than shared capacity to providers - ' +
          logCapacityObj.sharedLogValue +
          ' ' +
          logCapacityObj.logUnit +
          '/Sec',
        0,
        0,
      )
      enableSave = false
    }
    if (value > logCapacityObj.maxCapacity) {
      showMessage(
        'Log capacity cannot be greater than max capacity ' +
          logCapacityObj.maxCapacity +
          ' ' +
          logCapacityObj.logUnit +
          '/Sec',
        0,
        0,
      )
      enableSave = false
    }
    return { value, enableSave }
  }

  validateSharedLogCapacity = (value: number) => {
    const { logCapacityObj } = this.state
    const { showMessage } = this.Utilities
    let enableSave = true
    const totalDedicated =
      logCapacityObj.logValue - logCapacityObj.dedicatedCapacity
    if (value < 0) {
      showMessage(
        'Shared log capacity cannot be lesser than 0 ' +
          logCapacityObj.logUnit +
          '/Sec',
        0,
        0,
      )
      enableSave = false
    }
    if (value > totalDedicated) {
      showMessage(
        'Shared log capacity cannot be greater than the difference of log limit & dedicated to providers ' +
          totalDedicated +
          ' ' +
          logCapacityObj.logUnit +
          '/Sec',
        0,
        0,
      )
      enableSave = false
    }
    return { value, enableSave }
  }

  handleChange = (stateName: string, e: any) => {
    const { logCapacityObj } = this.state
    let value = ''
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        value = e.target.checked
      } else {
        value = e.target.value
      }
    } else {
      value = e
    }
    if (stateName == 'logValue') {
      const validatedVal = this.validateLogCapacity(parseFloat(value))
      logCapacityObj.logValue = validatedVal.value
      this.onChangeCallback(logCapacityObj, validatedVal.enableSave)
      this.setState({ logCapacityObj }, () => {
        this.props.form.setFieldsValue({ logValue: logCapacityObj.logValue })
      })
    } else if (stateName == 'sharedLogValue') {
      const validatedVal = this.validateSharedLogCapacity(parseFloat(value))
      logCapacityObj.sharedLogValue = validatedVal.value
      this.onChangeCallback(logCapacityObj, validatedVal.enableSave)
      this.setState({ logCapacityObj }, () => {
        this.props.form.setFieldsValue({
          sharedLogValue: logCapacityObj.sharedLogValue,
        })
      })
    }
  }

  onSliderChange = (value: any) => {
    const { logCapacityObj } = this.state
    const validatedVal = this.validateLogCapacity(parseFloat(value))
    logCapacityObj.logValue = validatedVal.value
    this.onChangeCallback(logCapacityObj, validatedVal.enableSave)
    this.setState({ logCapacityObj }, () => {
      this.props.form.setFieldsValue({ logValue: logCapacityObj.logValue })
    })
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    }

    const {
      logCapacity,
      maxCapacity,
      sharedCapacity,
      remainingCapacity,
      dedicatedCapacity,
      logValue,
      logUnit,
      reservedValue,
      sharedLogValue,
      total,
    } = this.state.logCapacityObj

    const { getFieldDecorator } = this.props.form
    const marks = {
      // 0: '0',
      [maxCapacity]: {
        style: {
          color: '#e57373',
        },
        label: <strong>{`Max ${maxCapacity} ${logUnit}/s`}</strong>,
      },
    }
    if (dedicatedCapacity > 0 && dedicatedCapacity < maxCapacity) {
      marks[dedicatedCapacity] = {
        style: {
          color: '#64b5f6',
        },
        label: (
          <strong>{`Dedicated pool ${dedicatedCapacity} ${logUnit}/s`}</strong>
        ),
      }
    }

    if (
      sharedCapacity > 0 &&
      sharedCapacity + dedicatedCapacity < maxCapacity
    ) {
      marks[sharedCapacity + dedicatedCapacity] = {
        style: {
          color: '#81c784',
        },
        label: <strong>{`Shared pool ${sharedCapacity} ${logUnit}/s`}</strong>,
      }
    }

    if (logCapacity > 0 && logCapacity < maxCapacity) {
      marks[logCapacity] = {
        style: {
          color: '#ffb74d',
        },
        label: <strong>{`Limit ${logCapacity} ${logUnit}/s`}</strong>,
      }
    }

    const maxSystemCapacity = total / 1000
    const allocatableCapacity = logValue - sharedLogValue
    const maxUsableWidth = (logValue / maxSystemCapacity) * 100
    const sharedWidth =
      (maxUsableWidth / 100) * (sharedLogValue / logValue) * 100
    const allocatedWidth =
      (maxUsableWidth / 100) * (allocatableCapacity / logValue) * 100

    return (
      <>
        <A10Form hideRequiredMark={true} layout="horizontal">
          <A10Panel title="Capacity">
            <A10Row className="capacity-row">
              <A10Col span={6}>Max System Capacity</A10Col>
              <A10Col span={16} style={{ display: 'flex' }}>
                <A10Col className="capacity-wrapper system-capacity" />
              </A10Col>
              <A10Col span={2}>
                <span style={{ paddingLeft: '5px' }}>
                  {maxSystemCapacity} K/Sec
                </span>
              </A10Col>
            </A10Row>
            <A10Row className="capacity-row">
              <A10Col span={6}>Max Usable Capacity</A10Col>
              <A10Col span={16} style={{ display: 'flex' }}>
                <A10Col
                  className="capacity-wrapper max-capacity"
                  style={{ width: `${maxUsableWidth}%` }}
                />
              </A10Col>
              <A10Col span={2}>
                <span style={{ paddingLeft: '5px' }}>{logValue} K/Sec</span>
              </A10Col>
            </A10Row>
            <A10Row className="capacity-row">
              <A10Col span={6}>Shared Pool Capacity</A10Col>
              <A10Col span={16} style={{ display: 'flex' }}>
                <A10Col
                  className="capacity-wrapper shared-capacity"
                  style={{
                    width: `${sharedWidth}%`,
                  }}
                />
              </A10Col>
              <A10Col span={2}>
                <span style={{ paddingLeft: '5px' }}>
                  {sharedLogValue} K/Sec
                </span>
              </A10Col>
            </A10Row>
            <A10Row className="capacity-row">
              <A10Col span={6}>Allocated Capacity</A10Col>
              <A10Col span={16} style={{ display: 'flex' }}>
                <A10Col
                  className="capacity-wrapper allocated-capacity"
                  style={{
                    width: `${allocatedWidth}%`,
                    marginLeft: `${sharedWidth}%`,
                  }}
                />
              </A10Col>
              <A10Col span={2}>
                <span style={{ paddingLeft: '5px' }}>
                  {parseFloat(`${allocatableCapacity}`).toFixed(2)} K/Sec
                </span>
              </A10Col>
            </A10Row>
          </A10Panel>
          <A10Panel
            title={
              <>
                <A10IconTextGroup
                  text="Log Processing"
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, marginRight: 12 }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              className='mb-16'
              {...formItemLayout}
              label={
                <>
                  Max Usable Capacity
                  <HelpInfo
                    placement="left"
                    title="Max Usable Capacity"
                    helpKey="HELP_SETTINGS_LOG_PROCESSING_LIMIT_CAPACITY"
                  />
                </>
              }
            >
              <div className="row">
                <div className="col-md-6">
                  {getFieldDecorator('logValue', {
                    initialValue: logValue,
                  })(
                    <A10Input
                      type="number"
                      min={sharedLogValue}
                      step={maxCapacity > 10 ? 1 : 0.1}
                      max={maxCapacity}
                      placeholder="Max Usable Capacity"
                      onChange={this.handleChange.bind(this, 'logValue')}
                      addonAfter={logUnit + '/Sec.'}
                    />,
                  )}
                </div>
              </div>
            </A10Form.Item>

            <A10Form.Item
              className='mb-16'
              {...formItemLayout}
              label={
                <>
                  {this.Messages.SHARED_POOL}
                  <HelpInfo
                    placement="left"
                    title={this.Messages.SHARED_POOL}
                    helpKey="HELP_SETTINGS_LOG_PROCESSING_SHARED_CAPACITY"
                  />
                </>
              }
            >
              <div className="row">
                <div className="col-md-6">
                  {getFieldDecorator('sharedCapacity', {
                    initialValue: sharedLogValue,
                  })(
                    <A10Input
                      type="number"
                      min={dedicatedCapacity}
                      step={maxCapacity > 10 ? 1 : 0.1}
                      max={logValue}
                      onChange={this.handleChange.bind(this, 'sharedLogValue')}
                      placeholder={this.Messages.SHARED_POOL}
                      addonAfter={logUnit + '/Sec.'}
                    />,
                  )}
                </div>
              </div>
            </A10Form.Item>

            <A10Form.Item
              className='mb-0'
              {...formItemLayout}
              label={
                <>
                  {this.Messages.DEDICATED_POOL}
                  <HelpInfo
                    placement="left"
                    title={this.Messages.DEDICATED_POOL}
                    helpKey="HELP_SETTINGS_LOG_PROCESSING_DEDICATED_CAPACITY"
                  />
                </>
              }
            >
              <div className="row">
                <div className="col-md-6">
                  {getFieldDecorator('dedicatedCapacity', {
                    initialValue: parseFloat(
                      `${logValue - sharedLogValue}`,
                    ).toFixed(2),
                  })(
                    <A10Input
                      type="number"
                      disabled
                      min={0}
                      step={maxCapacity > 10 ? 1 : 0.1}
                      max={dedicatedCapacity}
                      onChange={this.handleChange.bind(
                        this,
                        'dedicatedCapacity',
                      )}
                      placeholder={this.Messages.DEDICATED_POOL}
                      addonAfter={logUnit + '/Sec.'}
                    />,
                  )}
                </div>
              </div>
            </A10Form.Item>
          </A10Panel>
        </A10Form>
      </>
    )
  }
}

export default setupA10Container(A10Form.create()(ChangeLogCapacity))
