import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Form,
  A10Input,
  A10InputNumber,
  A10Alert,
  A10Row,
} from '@gui-libraries/widgets'

import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import A10Panel from 'src/components/ADC/A10Panel'
import storage from 'src/libraries/storage'
import { Messages } from 'src/locale/en/'
import Utilities from 'src/services/Utilities/Utilities'
import RangeSlider from '../../../../../../components/shared/RangeSlider/index'
import { HelpInfo } from 'src/components/shared/HelpInfo'

const styles = require('./styles/index.module.less')
const moment = require('moment')

export interface IFormData {
  entitleToken: string
  licenseType: string
  numBandwidth: number
  numDevices: number
  numProcessingUnits: number
  expiry: string
  maxAllocatedBandwidth: number
  usedDevices: number
  usedBandwidth: number
  maxAllocatedDevices: number
  billingSerials: string
  offlineMode: boolean
  activationUUID: string
  activationKey: string
}

export interface ILicenseEditFormProps extends IA10ContainerDefaultProps {
  onChange?: (data: IFormData, updatedData: any) => void
  formData: IFormData
  onRef: any
  licenseInfo: any
}
export interface ILicenseEditFormState {
  provider: string
  formData: IFormData
}

class LicenseEditForm extends A10Container<
  ILicenseEditFormProps,
  ILicenseEditFormState
> {
  Messages = new Messages()
  Utilities = new Utilities()
  updatedNumDevices: number
  updatedNumBandwidth: number
  formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  }
  infoText =
    'Enter new total capacity numbers. It can’t be lower than used capacities.'
  constructor(props: ILicenseEditFormProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER,
      formData: this.props.formData,
    }
    this.onSliderChange = this.onSliderChange.bind(this)
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  sendUpdatedValues() {
    const updatedObj = {
      updatedNumDevices: this.updatedNumDevices,
      updatedNumBandwidth: this.updatedNumBandwidth,
    }
    this.props.onChange(this.state.formData, updatedObj)
  }

  onSliderChange(data: any) {
    if (data.name === 'Device') {
      this.updatedNumDevices = data.updatedValue
    } else if (data.name === 'Bandwidth') {
      this.updatedNumBandwidth = data.updatedValue
    } else {
      // for numProcessingUnits
    }
  }

  handleChangeOffline = (stateName: string, e: any) => {
    const { formData } = this.state
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        formData[stateName] = e.target.checked
      } else {
        formData[stateName] = e.target.value
      }
    } else {
      formData[stateName] = e
    }
    this.setState({ formData })
  }

  renderUnits(state: any) {
    const {
      usedDevices,
      usedBandwidth,
      numBandwidth,
      numDevices,
      numProcessingUnits,
      maxAllocatedBandwidth,
      maxAllocatedDevices,
    } = this.state.formData
    const maxbandwidthObj = this.Utilities.megaToGiga(
      maxAllocatedBandwidth || 0,
    )

    switch (state) {
      case 'MBU':
        return (
          <div>
            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  {this.Messages.MNGD_BAND_WIDTH}
                  <HelpInfo
                    placement="right"
                    title="Managed Bandwidth Units"
                    helpKey="HELP_LICENSE_BANDWIDTH"
                  />
                </>
              }
            >
              <A10Row>
                {maxAllocatedBandwidth !== 0 ? (
                  <RangeSlider
                    restrict={usedBandwidth}
                    allotted={numBandwidth}
                    max={
                      maxbandwidthObj && maxbandwidthObj.unit === 'g'
                        ? maxbandwidthObj.val
                        : maxAllocatedBandwidth || 99999999
                    }
                    min={1}
                    step={1}
                    units={
                      maxbandwidthObj && maxbandwidthObj.unit === 'm'
                        ? 'Mbps'
                        : 'Gbps'
                    }
                    inputFixedText={true}
                    placeholder=""
                    name="Bandwidth"
                    onChange={this.onSliderChange}
                  />
                ) : (
                  <label className="pdlr-5">{this.Messages.UNLIMITED}</label>
                )}
              </A10Row>
            </A10Form.Item>
            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  {this.Messages.DEVICE_CAPACITY}
                  <HelpInfo
                    placement="right"
                    title="Device Capacity"
                    helpKey="HELP_LICENSE_DEVICE_CAPACITY"
                  />
                </>
              }
            >
              <A10Row>
                {numDevices !== 0 ? (
                  <RangeSlider
                    restrict={usedDevices}
                    allotted={numDevices}
                    max={maxAllocatedDevices}
                    min={1}
                    step={1}
                    units="Devices"
                    name="Device"
                    inputFixedText={false}
                    placeholder="New Value"
                    onChange={this.onSliderChange}
                  />
                ) : (
                  <label className="pdlr-5">{this.Messages.UNLIMITED}</label>
                )}
              </A10Row>
            </A10Form.Item>
          </div>
        )
      case 'MPU':
        return (
          <div>
            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  {this.Messages.ENTER_PROCESSING_UNITS}
                  <HelpInfo
                    placement="right"
                    title="Enter Processing Units"
                    helpKey="HELP_LICENSE_PROCESSING_UNITS"
                  />
                </>
              }
            >
              <div>
                <A10InputNumber
                  className="width-50P"
                  type="number"
                  min={0}
                  step={1}
                  placeholder={this.Messages.NEW_VALUE}
                  defaultValue={this.state.formData.numProcessingUnits}
                />
              </div>
            </A10Form.Item>
          </div>
        )
      case 'MDU':
        return (
          <div>
            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  {this.Messages.DEVICE_CAPACITY}
                  <HelpInfo
                    placement="right"
                    title="Device Capacity"
                    helpKey="HELP_LICENSE_DEVICE_CAPACITY"
                  />
                </>
              }
            >
              <A10Row>
                {numDevices !== 0 ? (
                  <RangeSlider
                    restrict={usedDevices}
                    allotted={numDevices}
                    max={maxAllocatedDevices}
                    min={1}
                    step={1}
                    units="Devices"
                    name="Device"
                    inputFixedText={false}
                    placeholder="New Value"
                    onChange={this.onSliderChange}
                  />
                ) : (
                  <label className="pdlr-5">{this.Messages.UNLIMITED}</label>
                )}
              </A10Row>
            </A10Form.Item>
          </div>
        )
      default:
        return null
    }
  }

  render() {
    const {
      entitleToken,
      licenseType,
      expiry,
      maxAllocatedBandwidth,
      maxAllocatedDevices,
      offlineMode,
      activationUUID,
      activationKey,
    } = this.props.formData

    const dateFormat = moment.utc(expiry).format('MMM DD, YYYY hh:mm A')

    return (
      <div>
        <A10Form layout="horizontal">
          <A10Panel
            title={
              <A10IconTextGroup
                text={
                  <>
                    License Information
                    {/* <span>
                      <HelpInfo
                        customCls="custom-tooltip-head"
                        placement="topLeft"
                        title="License Information"
                        helpKey="HELP_LICENSE_INFORMATION"
                      />
                    </span> */}
                  </>
                }
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            }
          >
            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  {this.Messages.ENTITLEMENT_TOKEN}
                  <HelpInfo
                    placement="right"
                    title={this.Messages.ENTITLEMENT_TOKEN}
                    helpKey="HELP_LICENSE_TOKEN"
                  />
                </>
              }
              className="no-margin font-14"
            >
              <label className="pdlr-5 disabled">{entitleToken}</label>
            </A10Form.Item>

            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  {'Name'}
                  <HelpInfo
                    placement="right"
                    title="Name"
                    helpKey="HELP_LICENSE_NAME"
                  />
                </>
              }
              className="no-margin font-14"
            >
              <label className="pdlr-5 disabled">
                {this.props.licenseInfo['license-name']}
              </label>
            </A10Form.Item>

            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  {this.Messages.LICENSE_TYPE}
                  <HelpInfo
                    placement="right"
                    title={this.Messages.LICENSE_TYPE}
                    helpKey="HELP_LICENSE_TYPE_VALUE"
                  />
                </>
              }
              className="no-margin font-14"
            >
              <label className="pdlr-5 disabled">
                {this.Messages[licenseType]}
              </label>
            </A10Form.Item>

            <A10Form.Item
              {...this.formItemLayout}
              label={<>License State</>}
              className="no-margin font-14"
            >
              <label
                className={
                  'pdlr-5 disabled ' +
                  (this.props.licenseInfo['activation-status'] === 'EXPIRING'
                    ? ' ' + styles.licWarning
                    : this.props.licenseInfo['activation-status'] ===
                        'EXPIRED' ||
                      this.props.licenseInfo['activation-status'] === 'REVOKED'
                    ? ' ' + styles.licDanger
                    : '')
                }
              >
                {`${this.props.licenseInfo['activation-status']} ${
                  this.props.licenseInfo['lm-mode'] &&
                  this.props.licenseInfo['lm-mode'] === 'OFFLINE'
                    ? '(OFFLINE)'
                    : ''
                }`}
              </label>
            </A10Form.Item>

            {this.props.licenseInfo['organization-name'] ? (
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    {'Organization Name'}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title="Organization Name"
                        helpKey="HELP_LICENSE_ORGANIZATION_NAME"
                      />
                    </span>
                  </>
                }
                className="no-margin font-14"
              >
                {
                  <div className="disabled">
                    {this.props.licenseInfo['organization-name']}
                  </div>
                }
              </A10Form.Item>
            ) : null}

            {this.props.licenseInfo['license-platform'] ? (
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    {'License Platform'}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title="License Platform"
                        helpKey="HELP_LICENSE_PLATFORM"
                      />
                    </span>
                  </>
                }
                className="no-margin font-14"
              >
                {
                  <div className="disabled">
                    {this.props.licenseInfo['license-platform']}
                  </div>
                }
              </A10Form.Item>
            ) : null}

            {this.props.licenseInfo['license-product'] ? (
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    {'License Product'}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title="License Product"
                        helpKey="HELP_LICENSE_PRODUCT"
                      />
                    </span>
                  </>
                }
                className="no-margin font-14"
              >
                {
                  <div className="disabled">
                    {this.props.licenseInfo['license-product']}
                  </div>
                }
              </A10Form.Item>
            ) : null}

            {this.props.licenseInfo['used-activations'] ? (
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    {'Used Activations'}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title="Used Activations"
                        helpKey="HELP_LICENSE_USED_ACTIVATIONS"
                      />
                    </span>
                  </>
                }
                className="no-margin font-14"
              >
                {
                  <div className="disabled">
                    {this.props.licenseInfo['used-activations']}
                  </div>
                }
              </A10Form.Item>
            ) : null}

            {this.props.licenseInfo['start-date'] ? (
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    {'Start Date'}
                    <HelpInfo
                      placement="right"
                      title="Start Date"
                      helpKey="HELP_LICENSE_START_DATE"
                    />
                  </>
                }
                className="no-margin font-14"
              >
                <label className="pdlr-5 disabled">
                  {this.props.licenseInfo['start-date']}
                </label>
              </A10Form.Item>
            ) : null}

            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  {this.Messages.EXPIRES_ON}
                  <HelpInfo
                    placement="right"
                    title="Expires On"
                    helpKey="HELP_LICENSE_EXPIRES_ON"
                  />
                </>
              }
              className="no-margin font-14"
            >
              <label className="pdlr-5 disabled">
                {expiry
                  ? expiry === '2050-12-31 00:00:00'
                    ? 'Never'
                    : dateFormat
                  : ''}
              </label>
            </A10Form.Item>

            <A10Form.Item
              {...this.formItemLayout}
              label={
                <>
                  {'Notes'}
                  <HelpInfo
                    placement="right"
                    title="Notes"
                    helpKey="HELP_LICENSE_NOTES"
                  />
                </>
              }
              className="no-margin font-14"
            >
              <label className="pdlr-5 disabled">
                {this.props.licenseInfo['notes']}
              </label>
            </A10Form.Item>
          </A10Panel>
          {offlineMode ? (
            <A10Panel
              title={
                <A10IconTextGroup
                  text={<>Offline License Update</>}
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, marginRight: 12 }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              }
            >
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    <span className="mandatoryField">
                      {this.Messages.ACTIVATION_UUID}
                    </span>
                    <span style={{ marginLeft: '-15px' }}>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.ACTIVATION_UUID + ' * '}
                        helpKey="HELP_LICENSE_ACTIVATION_UUID"
                      />
                    </span>
                  </>
                }
              >
                <div>
                  <A10Input.TextArea
                    value={activationUUID}
                    disabled={true}
                    style={{ resize: 'none' }}
                    onChange={this.handleChangeOffline.bind(
                      this,
                      'activationUUID',
                    )}
                  />
                </div>
              </A10Form.Item>
              <A10Form.Item
                {...this.formItemLayout}
                label={
                  <>
                    <span className="mandatoryField">
                      {this.Messages.ACTIVATION_KEY_LM}
                    </span>
                    <span style={{ marginLeft: '-15px' }}>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.ACTIVATION_KEY_LM + ' * '}
                        helpKey="HELP_LICENSE_ACTIVATION_KEY_LM"
                      />
                    </span>
                  </>
                }
              >
                <div>
                  <A10Input.TextArea
                    value={activationKey}
                    placeholder={this.Messages.ACTIVATION_KEY_LM}
                    onChange={this.handleChangeOffline.bind(
                      this,
                      'activationKey',
                    )}
                  />
                </div>
              </A10Form.Item>
            </A10Panel>
          ) : maxAllocatedBandwidth !== 0 || maxAllocatedDevices !== 0 ? (
            <A10Panel
              title={
                <A10IconTextGroup
                  text={
                    <>
                      Request Change
                      {/* <HelpInfo
                        customCls="custom-tooltip-head"
                        placement="right"
                        title="Request Change"
                        helpKey="HELP_LICENSE_REQUEST_CHANGE"
                      /> */}
                    </>
                  }
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, marginRight: 12 }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              }
            >
              <A10Alert
                className={styles.alertInfo}
                message={this.infoText}
                type="info"
                showIcon={true}
                closable={false}
              />
              {this.renderUnits(licenseType)}
            </A10Panel>
          ) : null}
        </A10Form>
      </div>
    )
  }
}

export default setupA10Container(LicenseEditForm)
