import React, { useState, useEffect } from 'react'
import ReactLoading from 'react-loading'
import { _ } from '@gui-libraries/framework'
import {
  A10Collapse,
  A10Icon,
  A10Row,
  A10Table,
  A10DropdownMenu,
  A10SlidingPage,
  A10Modal,
  A10Button,
  A10Notification,
} from '@gui-libraries/widgets'
import { AutoFormV15 } from '@gui-libraries/apps'

import RoundNumber from 'src/components/shared/RoundNumber'
import { InfrastructureService } from 'src/services/index'
import storage from 'src/libraries/storage'

const NetworkMacForwarding: React.FC = (props: IObject) => {
  const [showSlidingPage, setShowSlidingPage] = useState(false)
  const [mac, setMAC] = useState('')
  const [vlan, setVLAN] = useState(null as number)
  const infrastructureService = new InfrastructureService()
  const [staticList, setStaticList] = useState([] as IObject[])
  const [start, setStart] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [age, setAge] = useState(0)
  const [totalEntries, setTotalEntries] = useState(0)
  const [staticEntries, setStaticEntrieds] = useState(0)
  const provider = storage.get.PROVIDER
  const getStats = () => {
    infrastructureService
      .operRequest(
        [provider, props.deviceObj.name, 'shared'],
        'GET_DEVICE_MAC_FORWARDING_STATS',
      )
      .then((res: IObject) => {
        try {
          const ageTime = _.get(
            res,
            `data.0.${_.keys(res.data[0])[0]}.dynamic.oper.Age-time`,
            0,
          )
          const entries = _.get(
            res,
            `data.0.${_.keys(res.data[0])[0]}.dynamic.oper.macoper`,
            [],
          )
          setTotalEntries(entries.length)
          setStaticEntrieds(
            entries.filter((obj: IObject) => obj.Type === 'Static').length,
          )
          setAge(ageTime)
        } catch (e) {
          console.log(e)
        }
      })
  }
  const getData = () => {
    infrastructureService
      .operPaginationRequest(
        [provider, props.deviceObj.name, 'shared', ''],
        'GET_DEVICE_MAC_FORWARDING',
      )
      .then((res: IObject) => {
        try {
          const macs = _.get(
            res,
            `data.0.${_.keys(res.data[0])[0]}.dynamic.oper.macoper`,
            [],
          )
          setStaticList(macs)
          setIsLoading(false)
        } catch (e) {
          console.log(e)
          setIsLoading(false)
        }
      })
  }
  useEffect(() => {
    setIsLoading(true)
    getData()
    getStats()
  }, [start, props.total])

  const columns = React.useMemo(
    () => [
      {
        title: 'Type',
        dataIndex: 'Type',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'MAC Address',
        dataIndex: 'MAC-Address',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Interface',
        dataIndex: 'Port',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'VLAN',
        dataIndex: 'Vlan',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Age (secs)',
        dataIndex: 'Age',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        dataIndex: 'actions',
        width: 30,
        render: (text: any, record: any) => {
          return (
            !storage.get.IS_OPERATOR_USER && (
              <A10DropdownMenu
                title=""
                menu={[
                  <div
                    key="edit"
                    onClick={handleEdit.bind(
                      this,
                      record['MAC-Address'],
                      record.Vlan,
                    )}
                  >
                    Edit
                  </div>,
                  <div
                    key="delete"
                    onClick={handleDelete.bind(
                      this,
                      record['MAC-Address'],
                      record.Vlan,
                    )}
                  >
                    Delete
                  </div>,
                ]}
                style={{ color: '#757575', marginBottom: '-15px' }}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            )
          )
        },
      },
    ],
    [],
  )

  const toggleSlidingPage = (isOpen: boolean) => {
    setShowSlidingPage(isOpen)
  }

  const handleAdd = () => {
    toggleSlidingPage(true)
    setMAC('')
    setVLAN(null as number)
  }

  const handleEdit = (macAddress: string, vlanNumber: number) => {
    setMAC(macAddress)
    setVLAN(vlanNumber)
    setShowSlidingPage(true)
  }

  const handleDelete = (macAddress: string, vlanNumber: number) => {
    setMAC(macAddress)
    setVLAN(vlanNumber)
    toggleModal(true)
  }

  const handleSuccess = () => {
    const { updateTotal } = props
    if (!mac) {
      updateTotal()
    } else {
      getData()
    }
    toggleSlidingPage(false)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    setStart((page - 1) * pageSize)
  }

  const toggleModal = (isOpen: boolean) => {
    setShowModal(isOpen)
  }

  const handleOk = () => {
    infrastructureService
      .getRequest(
        null,
        null,
        [
          provider,
          props.deviceObj.name,
          'shared',
          `${encodeURIComponent(mac)}+${vlan}`,
        ],
        'DELETE_DEVICE_MAC_FORWARDING',
      )
      .then((res: IObject) => {
        props.updateTotal()
        toggleModal(false)
        A10Notification.success({
          message: 'Success!',
          description: `Delete (mac)}+${vlan} successfully.`,
          style: {
            wordWrap: 'break-word',
          },
        })
      })
      .catch((err: IObject) => {
        A10Notification.error({
          message: 'Error!',
          description: `Fail to delete (mac)}+${vlan}.`,
          style: {
            wordWrap: 'break-word',
          },
        })
      })
  }

  const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/device/${props.deviceObj.name}/partition/shared`

  const { total } = props
  return (
    <div className="col-md-12 marginBottom10">
      <A10Modal
        visible={showModal}
        title="Confirmation"
        onOk={handleOk}
        onCancel={toggleModal.bind(this, false)}
        footer={[
          <A10Button
            key="no"
            type="primary"
            onClick={toggleModal.bind(this, false)}
            className="nobtn"
          >
            No
          </A10Button>,
          <A10Button
            key="yes"
            type="default"
            onClick={handleOk}
            className="yesbtn"
          >
            Yes
          </A10Button>,
        ]}
      >
        <p>Are you sure you want to delete the selected item?</p>
      </A10Modal>
      <A10SlidingPage
        isOpen={showSlidingPage}
        modalSize="large"
        onRequestClose={toggleSlidingPage.bind(this, false)}
      >
        <AutoFormV15
          hccEnv={true}
          params={{
            mac,
            vlan,
          }}
          schemaPath="network/vlan/mac"
          apiPrefix={apiPrefix}
          enableSOP={true}
          interceptor={{
            onSubmitSuccess: handleSuccess,
            onCancel: toggleSlidingPage.bind(this, false),
          }}
          ENV_CONSTS={{
            IS_SHARED_PARTITION: true,
            PARTITION_TYPE: 'SHARED',
          }}
        />
      </A10SlidingPage>
      <A10Collapse>
        <A10Collapse.Panel
          key={0}
          header={
            <>
              {!storage.get.IS_OPERATOR_USER && (
                <span className="edit-content" onClick={handleAdd}>
                  <A10Icon app="global" type="add-new" /> Add
                </span>
              )}
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 fontWeight300 width260">
                  MAC Forwarding <RoundNumber number={total} />
                </span>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Total Active Entries</div>
                  <div className="pad-s-15 fontWeight300">{totalEntries}</div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Static Entries</div>
                  <div className="pad-s-15 fontWeight300">{staticEntries}</div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Aging Timer</div>
                  <div className="pad-s-15 fontWeight300">{age}</div>
                </div>
              </A10Row>
            </>
          }
          className="collapsePanel"
        >
          <A10Table
            columns={columns}
            dataSource={(staticList || []).slice(start, start + 10)}
            size="small"
            pagination={{
              hideOnSinglePage: true,
              pageSize: 10,
              total,
              onChange: handlePageChange,
            }}
            bordered={false}
            loading={
              isLoading
                ? {
                    indicator: (
                      <div className="loading-icon">
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
          />
        </A10Collapse.Panel>
      </A10Collapse>
    </div>
  )
}

export default NetworkMacForwarding
