import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { Map } from 'immutable'
import axios from 'axios'
import parameters from 'parameters'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
  _,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Col,
  A10Spin,
  A10Modal,
  A10Alert,
  A10ErrorPage,
  A10Layout,
  A10Breadcrumb,
} from '@gui-libraries/widgets'
import { DGFProvider } from '@gui-libraries/dgf'

import { ApplicationConfigs } from 'src/constants/ApplicationConfigs'
import {
  Utilities,
  DashboardService,
  InfrastructureService,
  AuthenticationService,
} from 'src/services/index'
import { LeftNav } from 'src/components/shared/LeftNav'
import { Footer } from 'src/components/shared/Footer'
import { GettingStarted } from 'src/components/shared/GettingStarted'
import { AppRoot } from 'src/settings/appRoot'
import storage, { saveUUIDDefinition } from 'src/libraries/storage'
// Importing applications
import { Infrastructure } from 'src/containers/Controller/Infrastructure'
import { Services } from 'src/containers/Controller/Services'
import { Organization } from 'src/containers/Controller/Organization'
import { Apps } from 'src/containers/Controller/Apps'
import { Monitor } from 'src/containers/Controller/Monitor'
import { SharedObjects } from 'src/containers/Controller/SharedObjects'
import Settings from 'src/containers/Controller/Dashboard/Settings'
import { AcceptTerms } from 'src/components/shared/SetAuthentication/AcceptTerms'
import { LoggedInUser } from 'src/libraries/loggedInUser'
import { LeftNavProviderTenantMenu } from 'src/components/shared/LeftNavProviderTenantMenu'
import { TroubleShoot } from 'src/containers/Controller/TroubleShoot'
import { Analytics } from 'src/containers/Controller/Analytics'
import { ClusterDeviceMenu } from 'src/components/shared/ClusterDeviceMenu'
import { ObjectExplorer } from 'src/components/shared/ObjectExplorer'
import { ProviderSettings } from 'src/containers/Controller/ProviderSettings'
import { EulaAfterLogin } from 'src/components/shared/EulaAfterLogin'
import * as basicUtils from '../../../src/containers/Controller/Dashboard/utils/basicUtils'
import SessionManagementUtil from '../../services/Utilities/SessionManagementUtil'
import {
  TopHeader,
  Left,
  Right,
  Divider,
} from 'src/components/shared/TopHeader'
import { UserMenu } from 'src/components/shared/UserMenu'
import { Notification } from 'src/components/shared/Notification'
import { HelpSupportEntry } from 'src/components/shared/HelpSupport'
import { Administration } from './Administration'

import './styles/index.less'
import { Dashboard } from './Dashboard'
import PullUpAlertViewer from './Dashboard/Common/PullUpViewer/PullUpAlertViewer'

const { Content } = A10Layout

// End importing applications

export interface IDefaultMethods {}
export interface IControllerParams {
  applicationName: string
  selectedApp: string
}
export interface IControllerProps
  extends IA10ContainerDefaultProps,
    RouteComponentProps<IControllerParams> {}
export interface IControllerState {
  applicationName: string
  subApplicationName: string
  adminLevel: string | null
  tenants?: any
  setTenant: boolean
  tenantToggled: boolean
  clusterDeviceToggled: boolean
  updateController: boolean
  redirectUrl?: string
  changeApplicationTo: string
  refreshTenants: boolean
  refreshHCApps: boolean
  refreshClusterDevices: boolean
  isGettingStartedVisible: boolean
  leftNavCollapsed: boolean
  headerStyle: string
  selectedTenant: string
  sessionMgmtMenuDisplay: boolean
  initDataCount: number
  dashboardParams: GuiDGF.HCParameters
  isAlertPage: boolean
}
const errorPath = [
  '500Error',
  '501Error',
  '503Error',
  '506Error',
  '400Error',
  '401Error',
  '402Error',
  '403Error',
  '404Error',
  '408Error',
  '410Error',
  '411Error',
  '412Error',
  '413Error',
  '414Error',
  '415Error',
  '300Error',
]

const { confirm, warning } = A10Modal
class Controller extends A10Container<IControllerProps, IControllerState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  DashboardService = new DashboardService()
  ApplicationConfigs = new ApplicationConfigs()
  AppRoot = new AppRoot()
  Utilities = new Utilities()
  InfrastructureService = new InfrastructureService()
  AuthenticationService = new AuthenticationService()
  isTenantSet = false
  defaultMethods: IDefaultMethods
  showTermsAccepted = false
  LoggedInUser = new LoggedInUser()
  user = storage.get.USER_ID
  provider = storage.get.PROVIDER
  token = storage.get.ACTIVATION_TOKEN
  eulaAfterLoginWasShown = storage.get.EULA_AFTER_LOGIN_SHOWN

  sessionMgmt = new SessionManagementUtil()
  expectedDataCount = 0
  sessionTimeoutTimerId = undefined
  sessionTimeoutWarningTimerId = undefined
  refreshSessionTimerId = undefined
  defaultSessionTimeout = 60 * 60 * 1000 // 59 mins = 59*60*1000 = 3540000 milliseconds
  defaultSessionWarningTimeout = 55 * 60 * 1000 // 55 minutes give user warning when 2 minutes are left for timeout
  // defaultSessionTimeout = 30 * 1000
  // defaultSessionWarningTimeout = 20 * 1000
  warningModal: any = undefined
  warningTimer: any = undefined

  constructor(props: IControllerProps) {
    super(props)
    this.checkTermsAccepted()
    this.state = {
      applicationName: this.props.match
        ? this.props.match.params.applicationName
        : '',
      subApplicationName: this.props.match
        ? this.props.match.params.selectedApp
        : '',
      tenantToggled: false,
      clusterDeviceToggled: false,
      updateController: false,
      adminLevel: storage.get.ADMIN_LEVEL,
      setTenant: false,
      redirectUrl: null,
      changeApplicationTo: null,
      refreshTenants: false,
      refreshHCApps: false,
      refreshClusterDevices: false,
      isGettingStartedVisible: false,
      leftNavCollapsed: false,
      headerStyle: 'left',
      selectedTenant: '',
      sessionMgmtMenuDisplay: false,
      initDataCount: 0,
      isAlertPage: false,
      dashboardParams: {
        auth: storage.get.ENCODED_SESSION_ID,
        api: parameters.BASE_URL,
        provider: storage.get.PROVIDER,
        username: storage.get.USER_ID,
        release: 'HC',
      },
    }
  }

  toggleLeftNav = () => {
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    this.setState(
      {
        leftNavCollapsed: !this.state.leftNavCollapsed,
      },
      () => {
        httpRequest({
          namespace: getNS('LEFT_COLLAPSED'),
          request: async () => {
            return await this.state.leftNavCollapsed
          },
        })
      },
    )
  }

  refreshTenantsFunc = () => {
    this.setState({
      refreshTenants: !this.state.refreshTenants,
    })
  }
  getCurrentApplication = () => {
    return this.state.applicationName
  }

  /**
   * API that checks if EULA has been accpeted
   */
  checkTermsAccepted = () => {
    const authHeader = {
      provider: this.provider,
      token: this.token,
      'Content-Type': 'application/json',
    }
    const isTermsAccepted = this.AuthenticationService.isTermsAccepted(
      authHeader,
      null,
      [this.user],
    )
    isTermsAccepted
      .then(response => {
        this.showTermsAccepted = !response.data.terms
      })
      .catch(error => {
        console.log(error)
      })
  }

  declineTerms = () => {
    this.LoggedInUser.logout()
  }

  loadInfraStructure = () => {
    if (storage.get.CURRENT_STATE !== 'infrastructure') {
      storage.remove.CURRENT_SUB_STATE()
      const uriToRedirect = '/Controller/Infrastructure'
      this.setState({ redirectUrl: uriToRedirect })
    }
  }
  loadMultiProvider = () => {
    if (storage.get.CURRENT_STATE !== 'providers') {
      storage.remove.CURRENT_SUB_STATE()
      const uriToRedirect = '/Controller/Providers'
      this.setState({ redirectUrl: uriToRedirect })
    }
  }
  isCurrentDashboard = () => {
    return storage.get.CURRENT_STATE === 'home'
  }
  isCurrentApps = () => {
    return storage.get.CURRENT_STATE === 'apps'
  }
  isCurrentMonitor = () => {
    return storage.get.CURRENT_STATE === 'monitor'
  }
  isCurrentUtilities = () => {
    return storage.get.CURRENT_STATE === 'utilities'
  }
  isCurrentAnalytics = () => {
    return storage.get.CURRENT_STATE === 'analytics'
  }
  loadDashboard = (
    tenant: any,
    isProviderMode: boolean,
    fromSuperAdmin?: boolean,
  ) => {
    const { set, remove } = storage

    remove.DASHBOARD_CLUSTER()
    let uriToRedirect
    if (isProviderMode) {
      uriToRedirect = '/Controller/Home'
      remove.CURRENT_TENANT()
      set.DRILL_LEVEL('provider')
      this.AppRoot.setRootScopeElement('currentDrillLevel', 'provider')
      const wasInTenantDashboard =
        window.location.href.indexOf('Home/tenant') > -1
      if (wasInTenantDashboard || fromSuperAdmin) {
        this.setState({ redirectUrl: uriToRedirect })
      }
    } else {
      const tenantName = tenant.name
      uriToRedirect = '/Controller/Home/tenant/' + tenantName
      this.setState({ redirectUrl: uriToRedirect })
    }
  }
  tenantChange = async (
    tenant: any = {},
    isProviderMode: boolean,
    isDualMode?: boolean,
  ) => {
    if (
      storage.get.CURRENT_STATE === 'utilities' &&
      storage.get.CURRENT_SUB_STATE !== 'imageupgrade' &&
      storage.get.CURRENT_SUB_STATE !== 'devicecli'
    ) {
      await this.getDevices(true)
      await this.getClusters(true)
    }

    if (storage.get.SUPER_ADMIN_MODE && tenant.superAdminRole) {
      storage.remove.CURRENT_TENANT()
      storage.set.DRILL_LEVEL('provider')
      this.AppRoot.setRootScopeElement('currentTenant', '')
      this.AppRoot.setRootScopeElement('currentDrillLevel', 'provider')

      if (
        storage.get.CURRENT_STATE !== 'superadmindashboard' &&
        storage.get.CURRENT_STATE !== 'providers' &&
        storage.get.CURRENT_STATE !== 'platform' &&
        storage.get.CURRENT_STATE !== 'system' &&
        storage.get.CURRENT_STATE !== 'superadmin' &&
        storage.get.CURRENT_STATE !== 'troubleshooting' &&
        storage.get.CURRENT_STATE !== 'monitoring' &&
        storage.get.CURRENT_STATE !== 'notification'
      ) {
        this.loadMultiProvider()
      } else {
        this.setState({
          tenantToggled: !this.state.tenantToggled,
        })
      }

      return
    } else {
      storage.remove.SUPER_ADMIN_MODE()
    }

    const appInfo = this.ApplicationConfigs.getAppSubappObjects(
      storage.get.CURRENT_STATE,
      storage.get.CURRENT_SUB_STATE,
    )
    const subAppNotTenantContext: string[] = []
    appInfo.appObj.subApps &&
      appInfo.appObj.subApps.map((subApp: any) => {
        if (!subApp.tenantContext) {
          subAppNotTenantContext.push(subApp.name.toLowerCase())
        }
      })

    if (isProviderMode && storage.get.ADMIN_LEVEL === 'provider') {
      if (
        !isDualMode ||
        this.isCurrentDashboard() ||
        (storage.get.CURRENT_STATE !== 'infrastructure' &&
          storage.get.CURRENT_STATE !== 'analytics' &&
          storage.get.CURRENT_STATE !== 'utilities' &&
          storage.get.CURRENT_STATE !== 'organization' &&
          storage.get.CURRENT_STATE !== 'apps' &&
          storage.get.CURRENT_STATE !== 'monitor' &&
          storage.get.CURRENT_STATE !== 'superadmindashboard' &&
          storage.get.CURRENT_STATE !== 'providers' &&
          storage.get.CURRENT_STATE !== 'platform' &&
          storage.get.CURRENT_STATE !== 'system' &&
          storage.get.CURRENT_STATE !== 'superadmin' &&
          storage.get.CURRENT_STATE !== 'troubleshooting' &&
          storage.get.CURRENT_STATE !== 'monitoring' &&
          storage.get.CURRENT_STATE !== 'settings' &&
          storage.get.CURRENT_STATE !== 'notification')
      ) {
        storage.remove.CURRENT_TENANT()
        storage.set.DRILL_LEVEL('provider')
        this.AppRoot.setRootScopeElement('currentTenant', '')
        this.AppRoot.setRootScopeElement('currentDrillLevel', 'provider')
      }

      let redirectUrl = ''
      if (
        storage.get.CURRENT_STATE === 'superadmindashboard' ||
        storage.get.CURRENT_STATE === 'providers' ||
        storage.get.CURRENT_STATE === 'platform' ||
        storage.get.CURRENT_STATE === 'system' ||
        storage.get.CURRENT_STATE === 'superadmin' ||
        storage.get.CURRENT_STATE === 'troubleshooting' ||
        storage.get.CURRENT_STATE === 'monitoring'
      ) {
        redirectUrl = '/Controller/Home'
      }
      if (this.isCurrentDashboard()) {
        this.loadDashboard(tenant, isProviderMode)
      } else if (
        storage.get.CURRENT_STATE === 'services' ||
        (storage.get.CURRENT_STATE === 'monitor' &&
          storage.get.CURRENT_SUB_STATE !== 'alert' &&
          storage.get.CURRENT_SUB_STATE !== 'workflow')
      ) {
        this.loadInfraStructure()
      }

      if (
        this.isCurrentApps() &&
        (this.state.subApplicationName === 'MyApps' ||
          this.state.subApplicationName === '')
      ) {
        storage.set.CURRENT_SUB_STATE('providersappcatalog')
        appInfo.subAppObj.name = 'ProvidersAppCatalog'
        redirectUrl = '/controller/Apps/ProvidersAppCatalog'
      } else if (this.isCurrentMonitor()) {
        if (
          subAppNotTenantContext.indexOf(storage.get.CURRENT_SUB_STATE) === -1
        ) {
          const subAppName = appInfo?.appObj?.defaultProviderContext || 'Alert'
          storage.set.CURRENT_SUB_STATE(subAppName.toLowerCase())

          redirectUrl = '/controller/' + appInfo.appObj.name + '/' + subAppName
        }
      } else if (this.isCurrentUtilities()) {
        if (storage.get.CURRENT_SUB_STATE !== 'objectexplorer') {
          if (
            subAppNotTenantContext.indexOf(storage.get.CURRENT_SUB_STATE) === -1
          ) {
            const subAppName =
              appInfo?.appObj?.defaultProviderContext || 'Devicecli'
            storage.set.CURRENT_SUB_STATE(subAppName.toLowerCase())
            redirectUrl =
              '/controller/' + appInfo.appObj.name + '/' + subAppName
          }
        }
      }

      this.setState({
        redirectUrl,
        tenantToggled: !this.state.tenantToggled,
        applicationName: appInfo?.appObj?.name || '',
        subApplicationName: appInfo?.subAppObj?.name || '',
      })
    } else if (!isProviderMode) {
      let isRedirect = false
      if (tenant) {
        const currentState = storage.get.CURRENT_STATE
        const currentSubState = storage.get.CURRENT_SUB_STATE
        if (
          currentState === 'infrastructure' ||
          currentState === 'organization' ||
          currentState === 'superadmindashboard' ||
          currentState === 'providers' ||
          currentState === 'platform' ||
          currentState === 'system' ||
          currentState === 'superadmin' ||
          currentState === 'troubleshooting' ||
          currentState === 'monitoring' ||
          currentState === 'settings' ||
          currentState === 'notification' ||
          (currentState === 'analytics' &&
            currentSubState !== 'dashboard' &&
            currentSubState !== '')
        ) {
          isRedirect = true
        }
      } else {
        tenant = storage.get.CURRENT_TENANT
      }

      // if doesn't have uuid, call the gettenantobj and save whole obj
      const tenantWithUuid = this.AppRoot.getRootScopeElement(
        'allTenants',
      ).filter((obj: any, i: number) => {
        if (!tenant) {
          return false
        }
        return obj.uuid && obj.name === tenant.name
      })

      let objTenant = tenant
      if (tenantWithUuid.length > 0 && tenantWithUuid[0].uuid) {
        try {
          objTenant = { ...tenantWithUuid[0], ...tenant }
          storage.set.CURRENT_TENANT(objTenant)
        } catch {
          const tenantObj = JSON.parse(JSON.stringify(objTenant))
          delete tenantObj['app-svc-list']
          delete tenantObj['logical-partition-list']
          storage.set.CURRENT_TENANT(tenantObj)
        }
        this.AppRoot.setRootScopeElement('currentTenant', objTenant)
      } else {
        if (tenant?.name) {
          this.setTenantObj(tenant.name)
        }
      }

      storage.set.DRILL_LEVEL('tenant')
      this.AppRoot.setRootScopeElement('currentDrillLevel', 'tenant')

      let redirectUrl = ''
      if (isRedirect) {
        if (storage.get.IS_INFRA_USER) {
          // For hc_infra_admin role class users
          this.loadDashboard(tenant, !tenant)
        } else {
          redirectUrl = '/Controller/Services/AppServices'
        }
      } else if (
        this.isCurrentApps() &&
        (this.state.subApplicationName === 'ProvidersAppCatalog' ||
          this.state.subApplicationName === '')
      ) {
        storage.set.CURRENT_SUB_STATE('myapps')
        appInfo.subAppObj.name = 'MyApps'
        redirectUrl = '/controller/Apps/MyApps'
      } else if (
        this.state.adminLevel === 'provider' &&
        this.isCurrentUtilities()
      ) {
        if (
          subAppNotTenantContext.indexOf(storage.get.CURRENT_SUB_STATE) > -1
        ) {
          const subAppName = appInfo?.appObj?.defaultTenantContext || ''
          if (appInfo?.subAppObj?.name === 'Devicecli') {
            storage.set.CURRENT_SUB_STATE('Devicecli'.toLowerCase())
          } else if (!!subAppName) {
            storage.set.CURRENT_SUB_STATE(subAppName.toLowerCase())
            redirectUrl =
              '/controller/' + appInfo.appObj.name + '/' + subAppName
          } else {
            redirectUrl = '/Controller/Services/AppServices'
          }
        }
      }

      if (this.isCurrentDashboard()) {
        this.loadDashboard(tenant, !tenant)
      }

      this.setState({
        redirectUrl,
        tenantToggled: !this.state.tenantToggled,
        applicationName: appInfo?.appObj?.name || '',
        subApplicationName: appInfo?.subAppObj?.name || '',
      })
    }

    this.setState({ selectedTenant: JSON.stringify(tenant) || '' })
  }

  clusterDeviceChanged = (type: string, selectedObj: any) => {
    if (selectedObj.name === '') {
      storage.remove.CURRENT_CLUSTER()
      storage.remove.CURRENT_DEVICE()
    } else {
      if (type == 'cluster') {
        storage.set.CURRENT_CLUSTER(selectedObj)
      } else {
        storage.set.CURRENT_DEVICE(selectedObj)
      }
    }
    this.setState({
      clusterDeviceToggled: !this.state.clusterDeviceToggled,
      refreshClusterDevices: !this.state.refreshClusterDevices,
    })
  }

  setAllTenantObj = () => {
    const provider = storage.get.PROVIDER
    const tenantList = storage.get.ALLTENANTS
    const promises: any = []
    tenantList.map((obj: any, i: number) => {
      const getTenantObj = () =>
        this.DashboardService.getTenantObj(null, null, [provider, obj.name])
      promises.push(
        getTenantObj().catch((error: any) => {
          console.log('error in getting tenant info', error)
        }),
      )
    })

    Promise.all(promises)
      .then((resp: any) => {
        const tenantObjList: any = []
        resp.map((response: any, index: number) => {
          if (
            response &&
            response.data &&
            response.data.tenant &&
            response.data.tenant.type !== 'built-in'
          ) {
            tenantObjList.push(
              this.Utilities.minimizeAppSvcLPinfoFromTenant(
                response.data.tenant,
              ),
            )
          } else if (tenantList[index].name.toLowerCase() !== 'pso') {
            tenantObjList.push({
              name: tenantList[index].name,
            })
          }
        })

        if (tenantObjList.length > 0) {
          const currentTenant = storage.get.CURRENT_TENANT
          tenantObjList.map((obj: any) => {
            if (currentTenant.name === obj.name) {
              try {
                storage.set.CURRENT_TENANT(obj)
              } catch {
                storage.set.CURRENT_TENANT(obj)
              }
              this.AppRoot.setRootScopeElement('currentTenant', obj)
            }
          })
        }
        storage.set.ALLTENANTS(tenantObjList)
        this.AppRoot.setRootScopeElement('allTenants', tenantObjList)
      })
      .catch((error: any) => {
        console.log('error in getting tenant info', error)
      })
  }

  setTenantObj = (tenantName: string) => {
    const provider = storage.get.PROVIDER
    const getTenantObj = this.DashboardService.getTenantObj(null, null, [
      provider,
      tenantName,
    ])
    getTenantObj
      .then((response: any) => {
        const tenantObj = response.data.tenant
        const tenantList = storage.get.ALLTENANTS
        let index = -1
        tenantList.map((obj: any, i: number) => {
          if (obj.name === tenantObj.name) {
            index = i
            return
          }
        })

        if (index > -1) {
          tenantList[index] = tenantObj

          storage.set.ALLTENANTS(tenantList)
          this.AppRoot.setRootScopeElement('allTenants', tenantList)

          try {
            storage.set.CURRENT_TENANT(tenantObj)
          } catch {
            const tenant = tenantObj
            delete tenant['app-svc-list']
            delete tenant['logical-partition-list']
            storage.set.CURRENT_TENANT(tenant)
          }

          this.AppRoot.setRootScopeElement('currentTenant', tenantObj)

          this.setState({
            tenantToggled: !this.state.tenantToggled,
          })
        }
      })
      .catch((error: any) => {
        console.log('error in getting tenant info')
      })
  }

  getSelectedClusterDevice = (type: string) => {
    let clusterDevice
    if (type === 'cluster') {
      clusterDevice = storage.get.CURRENT_CLUSTER
      storage.remove.CURRENT_DEVICE()
    } else {
      clusterDevice = storage.get.CURRENT_DEVICE
      storage.remove.CURRENT_CLUSTER()
    }

    try {
      if (clusterDevice === null) {
        return undefined
      } else {
        return clusterDevice
      }
    } catch {
      return undefined
    }
  }

  getSelectedTenant = () => {
    const tenantList = storage.get.ALLTENANTS
    const itemNames = tenantList.map((tenant: IObject) => {
      return tenant.name
    })
    const uri = window.location.href.toLowerCase()
    const isDashboardMode = uri.indexOf('/controller/home/') > -1

    if (isDashboardMode) {
      const isDashboardTenantMode = uri.indexOf('/controller/home/tenant/') > -1
      if (isDashboardTenantMode) {
        const uriTenantName = window.location.href
          .split('/tenant/')[1]
          .replace('/', '')
        const isWithinTenantList = itemNames.indexOf(uriTenantName)

        if (isWithinTenantList) {
          const currentTenant = tenantList.filter((tenant: IObject) => {
            return tenant.name === uriTenantName
          })[0]
          const currentTenantStr = JSON.stringify(currentTenant)
          return currentTenantStr
        }
      }
    }
    try {
      if (!storage.get.CURRENT_TENANT.name) {
        return undefined
      } else {
        return storage.get.CURRENT_TENANT
      }
    } catch {
      return undefined
    }
  }

  // NOTE::  Need to uncomment once hpcapi is integrated
  getTenants = () => {
    const provider = storage.get.PROVIDER
    if (storage.get.ADMIN_LEVEL === 'provider') {
      const tenants = this.DashboardService.getTenants(null, null, provider)
      tenants
        .then((response: any) => {
          if (response.data) {
            const tenantListObj: any[] = response?.data?.['tenant-list'] || []
            storage.set.ALLTENANTS(tenantListObj)
            this.AppRoot.setRootScopeElement('allTenants', tenantListObj)
            this.isTenantSet = true
            this.setState({
              setTenant: true,
            })
          } else {
            storage.set.ALLTENANTS([])
            this.AppRoot.setRootScopeElement('allTenants', [])
            this.isTenantSet = true
            this.setState({
              setTenant: true,
            })
          }
        })
        .finally(() => {
          this.setState(prevState => ({
            initDataCount: prevState.initDataCount + 1,
          }))
        })
    } else {
      const availableTenants = storage.get.AVAILABLETENANTS
      const tenantList: any = []
      if (availableTenants.length) {
        availableTenants.map((tenantName: any) => {
          if (tenantName) {
            tenantList.push({
              name: tenantName,
            })
          }
        })
      }

      const readTenants = storage.get.READTENANTS
      if (readTenants.length) {
        readTenants.map((tenantName: any) => {
          if (tenantName) {
            tenantList.push({
              name: tenantName,
            })
          }
        })
      }

      storage.set.ALLTENANTS(tenantList)
      this.AppRoot.setRootScopeElement('allTenants', tenantList)
      this.isTenantSet = true
      this.setState(prevState => ({
        setTenant: true,
        initDataCount: prevState.initDataCount + 1,
      }))

      this.setAllTenantObj()
    }
  }
  returnTenant = () => {
    return this.state.tenants
  }
  onChangeSubApp = (subAppName: string) => {
    this.setState({ subApplicationName: subAppName || null })
  }
  onChange = () => {
    this.setState({
      isGettingStartedVisible: false,
    })
  }
  setGettingStarted = () => {
    const wasShown = this.showTermsAccepted ? false : true
    storage.set.GETTING_STARTED_SHOWN(wasShown)
  }
  onEulaAfterLoginClose = () => {
    this.eulaAfterLoginWasShown = true
    storage.set.EULA_AFTER_LOGIN_SHOWN(this.eulaAfterLoginWasShown)
  }
  leftNavAppsRefresh = () => {
    this.setState({ refreshHCApps: !this.state.refreshHCApps })
  }
  appTobeLoaded = () => {
    const { applicationName, subApplicationName } = this.state
    const selectedApp =
      subApplicationName ||
      (this.props.match?.params?.selectedApp
        ? this.props.match.params.selectedApp
        : '')

    const application = this.ApplicationConfigs.getApplicationByName(
      applicationName,
    )
    const subApp =
      application?.subApps?.find((subApp: IObject) => {
        return subApp.name.toLowerCase() === selectedApp.toLowerCase()
      }) || application?.subApps?.[0]

    if (application?.superUserMode) {
      const { isAlertPage } = this.state
      return (
        <Administration
          application={application}
          subApp={subApp}
          leftNavCollapsed={this.state.leftNavCollapsed}
          isAlertPage={isAlertPage}
        />
      )
    }

    if (applicationName.toLowerCase() === 'infrastructure') {
      return (
        <Infrastructure
          defaultMethods={this.defaultMethods}
          tenantToggled={this.state.tenantToggled}
          selectedApp={selectedApp}
          onSelectApp={this.onChangeSubApp}
          refreshDeviceSession={this.getDevices}
          refreshClusterSession={this.getClusters}
        />
      )
    } else if (applicationName.toLowerCase() === 'services') {
      return (
        <Services
          defaultMethods={this.defaultMethods}
          tenantToggled={this.state.tenantToggled}
          onSelectApp={this.onChangeSubApp}
          selectedApp={selectedApp}
        />
      )
    } else if (applicationName.toLowerCase() === 'organization') {
      return (
        <Organization
          defaultMethods={this.defaultMethods}
          tenantToggled={this.state.tenantToggled}
          selectedApp={selectedApp}
          onSelectApp={this.onChangeSubApp}
          refreshTenantsFunc={this.refreshTenantsFunc}
          leftNavAppsRefresh={this.leftNavAppsRefresh}
        />
      )
    } else if (applicationName.toLowerCase() === 'settings') {
      return (
        <ProviderSettings
          tenantToggled={this.state.tenantToggled}
          applicationName={applicationName}
          selectedApp={selectedApp}
          onSelectApp={this.onChangeSubApp}
        />
      )
    } else if (applicationName.toLowerCase() === 'apps') {
      return (
        <Apps
          defaultMethods={this.defaultMethods}
          tenantToggled={this.state.tenantToggled}
          selectedApp={selectedApp}
          onSelectApp={this.onChangeSubApp}
          leftNavAppsRefresh={this.leftNavAppsRefresh}
        />
      )
    } else if (applicationName.toLowerCase() === 'monitor') {
      return (
        <Monitor
          defaultMethods={this.defaultMethods}
          tenantToggled={this.state.tenantToggled}
          selectedApp={selectedApp}
          onSelectApp={this.onChangeSubApp}
          tenantChange={this.tenantChange}
        />
      )
    } else if (applicationName.toLowerCase() === 'sharedobj') {
      return (
        <SharedObjects
          defaultMethods={this.defaultMethods}
          tenantToggled={this.state.tenantToggled}
          selectedApp={selectedApp}
          onSelectApp={this.onChangeSubApp}
          refreshDeviceSession={this.getDevices}
          refreshClusterSession={this.getClusters}
        />
      )
    } else if (applicationName.toLowerCase() === 'analytics') {
      return (
        <Analytics
          defaultMethods={this.defaultMethods}
          tenantToggled={this.state.tenantToggled}
          clusterDeviceToggled={this.state.clusterDeviceToggled}
          selectedApp={selectedApp}
          leftNavCollapsed={this.state.leftNavCollapsed}
          onSelectApp={this.onChangeSubApp}
        />
      )
    } else if (applicationName.toLowerCase() === 'utilities') {
      return (
        <TroubleShoot
          tenantToggled={this.state.tenantToggled}
          clusterDeviceToggled={this.state.clusterDeviceToggled}
          selectedApp={selectedApp}
          onSelectApp={this.onChangeSubApp}
          propsForLeftNavProviderTenantMenu={{
            tenantChange: this.tenantChange,
            tenants: storage.get.ALLTENANTS,
            selectedTenant: this.getSelectedTenant(),
            refreshTenants: this.state.refreshTenants,
            leftNavCollapsed: this.state.leftNavCollapsed,
          }}
        />
      )
    } else if (applicationName.toLowerCase() === 'objectexplorer') {
      return (
        <ObjectExplorer
          propsForLeftNavProviderTenantMenu={{
            tenantChange: this.tenantChange,
            tenants: storage.get.ALLTENANTS,
            selectedTenant: this.getSelectedTenant(),
            refreshTenants: this.state.refreshTenants,
            leftNavCollapsed: this.state.leftNavCollapsed,
          }}
        />
      )
    } else if (applicationName.toLowerCase() === 'home') {
      return (
        <Dashboard
          defaultMethods={this.defaultMethods}
          tenantToggled={this.state.tenantToggled}
          selectedApp={selectedApp}
          leftNavCollapsed={this.state.leftNavCollapsed}
        />
      )
    } else if (applicationName.toLowerCase() === 'Notification') {
      return (
        <PullUpAlertViewer
          isAlertPage={true}
          leftNavCollapsed={this.state.leftNavCollapsed}
        />
      )
    } else if (applicationName === '500Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.UnexpectedErrorPage500 redirectUrl="/controller/Analytics/Dashboard" />
        </A10Col>
      )
    } else if (applicationName === '501Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.UnexpectedErrorPage500 redirectUrl="/controller/Analytics/Dashboard" />
        </A10Col>
      )
    } else if (applicationName === '503Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.UnexpectedErrorPage500 redirectUrl="/controller/Analytics/Dashboard" />
        </A10Col>
      )
    } else if (applicationName === '506Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.UnexpectedErrorPage500 redirectUrl="/controller/Analytics/Dashboard" />
        </A10Col>
      )
    } else if (applicationName === '400Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.NotFoundPage404 redirectUrl="/controller/Analytics/Dashboard" />
        </A10Col>
      )
    } else if (applicationName === '401Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.NotFoundPage404 redirectUrl="/controller/Analytics/Dashboard" />
        </A10Col>
      )
    } else if (applicationName === '402Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.NotFoundPage404 redirectUrl="/controller/Analytics/Dashboard" />
        </A10Col>
      )
    } else if (applicationName === '403Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.NotFoundPage404 redirectUrl="/controller/Analytics/Dashboard" />
        </A10Col>
      )
    } else if (applicationName === '404Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.NotFoundPage404 redirectUrl="/controller/Analytics/Dashboard" />
        </A10Col>
      )
    } else if (applicationName === '408Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.RequestTimeoutPage />
        </A10Col>
      )
    } else if (applicationName === '410Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.RequestTimeoutPage />
        </A10Col>
      )
    } else if (applicationName === '411Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.RequestTimeoutPage />
        </A10Col>
      )
    } else if (applicationName === '412Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.RequestTimeoutPage />
        </A10Col>
      )
    } else if (applicationName === '413Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.RequestTimeoutPage />
        </A10Col>
      )
    } else if (applicationName === '414Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.RequestTimeoutPage />
        </A10Col>
      )
    } else if (applicationName === '415Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.RequestTimeoutPage />
        </A10Col>
      )
    } else if (applicationName === '300Error') {
      return (
        <A10Col className="error-status-margin">
          <A10ErrorPage.RedirectPage redirectUrl="/controller/Analytics/Dashboard" />
        </A10Col>
      )
    } else {
      return <div className="col-md-12">{applicationName}</div>
    }
  }
  renderRedirect = (url: string) => {
    return <Redirect to={url} />
  }

  refreshDashboardTimeline = () => {
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    return httpRequest({
      namespace: Settings.namespace.selectedDashboardTimeline,
      request: async () => {
        return Map(Settings.getDynamicPeriod())
      },
    })
  }

  shouldComponentUpdate(
    nextProps: IControllerProps,
    nextState: IControllerState,
  ) {
    const { applicationName: nextApp } = nextState
    const { applicationName } = this.state
    // For refreshing Dashboard Time Line before GUI update, it should return false when page goes to Dashboard

    if (nextApp !== applicationName && nextApp === 'Home') {
      this.refreshDashboardTimeline().then(() => {
        // To update state
        this.setState({})
      })
      return false
    }
    return true
  }

  componentDidUpdate(prevProps: IControllerProps, prevState: IControllerState) {
    if (this.props.match) {
      if (
        this.props.match.params.applicationName !==
          prevProps.match.params.applicationName ||
        this.props.match.params.selectedApp !==
          prevProps.match.params.selectedApp
      ) {
        this.setState({
          applicationName: this.props.match.params.applicationName,
          subApplicationName: this.props.match.params.selectedApp,
          redirectUrl: '',
        })
      }
    }

    if (
      prevState.initDataCount !== this.state.initDataCount &&
      this.state.initDataCount === this.expectedDataCount
    ) {
      this.handleRedirectOnLogin()
    }
  }

  handleRedirectOnLogin = () => {
    if (storage.get.JUST_LOGIN) {
      storage.remove.JUST_LOGIN()
    }
    return
  }

  getClusters = async (onlyClusterSesSet?: boolean) => {
    try {
      const res = await this.InfrastructureService.getClustersSummary(
        null,
        null,
        [storage.get.PROVIDER],
      )
      const clusters = res?.data?.['cluster-list'] || []
      saveUUIDDefinition(res?.data)
      storage.set.ALLCLUSTERS(clusters)
      this.AppRoot.setRootScopeElement('allClusters', clusters)
    } catch (e) {
      // console.error(e)
    } finally {
      if (onlyClusterSesSet) {
        this.setState({
          refreshClusterDevices: !this.state.refreshClusterDevices,
        })
      }

      this.setState(prevState => ({
        initDataCount: prevState.initDataCount + 1,
      }))
    }
  }

  gettingStartedShown = async () => {
    let gettingStartedShown = false
    try {
      const res = await this.DashboardService.getUserPrefs(null, null, [
        storage.get.PROVIDER,
        storage.get.USER_ID,
      ])
      const userPrefs = res?.data
      const preferences = JSON.parse(userPrefs.preferences ?? null) || {}
      gettingStartedShown = preferences?.gettingStartedShown ?? false

      if (!gettingStartedShown && !this.showTermsAccepted) {
        preferences['gettingStartedShown'] = true
        userPrefs.preferences = JSON.stringify(preferences)

        await this.DashboardService.saveUserPrefs(null, userPrefs, [
          storage.get.PROVIDER,
          storage.get.USER_ID,
        ])
      }
    } catch (e) {
      gettingStartedShown = true
    }
    return gettingStartedShown
  }

  getDevices = async (onlyDeviceSesSet?: boolean) => {
    const gettingStartedShown = await this.gettingStartedShown()
    const notYetShown = !gettingStartedShown

    await this.InfrastructureService.getDevicesSummary(
      null,
      null,
      storage.get.PROVIDER,
    )
      .then(({ data }) => {
        const devices = data && data['device-list'] ? data['device-list'] : []
        storage.set.ALLDEVICES(devices)
        saveUUIDDefinition(data)

        if (onlyDeviceSesSet) {
          this.setState({
            refreshClusterDevices: !this.state.refreshClusterDevices,
          })
          return
        }

        storage.set.DEVICES_EXISTS(
          data && data['device-list'].length > 0 ? true : false,
        )
        this.setState({
          isGettingStartedVisible:
            (!data || data['device-list'].length === 0) && // no devices can be registered
            notYetShown &&
            storage.get.DRILL_LEVEL === 'provider' &&
            !this.showTermsAccepted,
        })
      })
      .catch((err: any) => {
        if (onlyDeviceSesSet) {
          this.setState({
            refreshClusterDevices: !this.state.refreshClusterDevices,
          })
          return
        }
        this.setState({
          isGettingStartedVisible:
            notYetShown &&
            storage.get.DRILL_LEVEL === 'provider' &&
            !this.showTermsAccepted,
        })
      })
      .finally(() => {
        this.setState(prevState => ({
          initDataCount: prevState.initDataCount + 1,
        }))
      })
  }

  componentDidMount() {
    const initFunc: Array<() => void> = []

    if (!this.isTenantSet) {
      initFunc.push(this.getTenants)
    }

    if (
      !(
        storage.get.CURRENT_STATE === 'infrastructure' &&
        storage.get.CURRENT_SUB_STATE === 'devices'
      )
    ) {
      // Remove cluster API on initial load for Devices page as it is not used
      initFunc.push(this.getClusters)
    }
    initFunc.push(this.getDevices)

    this.init(initFunc)

    // timer for countdown panel
    this.setSessionTimeoutWarningTimer()
    // timer for log out warning
    this.setSessionTimeoutTimer()
    // timer for session refresh
    this.setRefreshSessionTimer()
  }

  init = (funcArr: Array<() => void>) => {
    this.expectedDataCount = funcArr.length

    funcArr.forEach(func => func())
  }

  onExtendSessionFromWarning = () => {
    this.resetSessionWarning()
    this.setSessionMgmtMenuDisplay(true)
  }

  setSessionMgmtMenuDisplay = (display: boolean) => {
    this.setState({ sessionMgmtMenuDisplay: display })
  }

  resetSessionWarning = () => {
    this.handleModal()
    clearTimeout(this.sessionTimeoutWarningTimerId)
  }

  handleModal = () => {
    try {
      if (this.warningTimer) {
        clearInterval(this.warningTimer)
      }
      if (this.warningModal) {
        this.warningModal.destroy()
      }
    } catch (err) {
      console.error(`Error in destorying session manager modal: ${err}`)
    }
  }

  setSessionTimeoutWarningTimer = (
    time = this.defaultSessionWarningTimeout,
  ) => {
    this.sessionTimeoutWarningTimerId = setTimeout(this.showWarning, time)
  }

  setSessionTimeoutTimer = (time = this.defaultSessionTimeout) => {
    this.sessionTimeoutTimerId = setTimeout(this.logout, time)
  }

  setRefreshSessionTimer = (time = this.defaultSessionTimeout) => {
    this.refreshSessionTimerId = setTimeout(this.refreshSession, time)
  }

  continueSession = () => {
    const sessionTimeout = 1
    storage.set.SESSION_TIMEOUT(sessionTimeout)
    storage.set.SESSION_TIMEOUT_INITIAL(sessionTimeout)
    this.resetSessionWarning()

    clearTimeout(this.sessionTimeoutWarningTimerId)
    this.setSessionTimeoutWarningTimer()

    clearTimeout(this.sessionTimeoutTimerId)
    this.setSessionTimeoutTimer()
  }

  showWarning = () => {
    const currentTime = new Date().getTime()
    const isIdle = this.sessionMgmt.isUserIdle(currentTime)
    let sessionTimeout = storage.get.SESSION_TIMEOUT
    if (sessionTimeout === undefined || isNaN(sessionTimeout)) {
      sessionTimeout = 1
    }

    if (isIdle) {
      // If user is Idle that means we are inside this block because sessionTimeout duration was set by the user explicitly
      sessionTimeout--
      storage.set.SESSION_TIMEOUT(sessionTimeout)
    }

    if (sessionTimeout > 0) {
      const lastTimeClicked = JSON.parse(storage.get.LAST_CLICKED_TIMESTAMP)

      // calculate time from last user activation to now
      const idleTimePassed = currentTime - lastTimeClicked

      clearTimeout(this.sessionTimeoutWarningTimerId)
      this.setSessionTimeoutWarningTimer(
        idleTimePassed < this.defaultSessionWarningTimeout
          ? this.defaultSessionWarningTimeout - idleTimePassed
          : this.defaultSessionWarningTimeout,
      )

      clearTimeout(this.sessionTimeoutTimerId)
      this.setSessionTimeoutTimer(
        idleTimePassed < this.defaultSessionWarningTimeout
          ? this.defaultSessionTimeout - idleTimePassed
          : this.defaultSessionTimeout,
      )
    } else {
      let secondsToGo = 300
      // let secondsToGo = 10
      this.warningModal = confirm({
        className: 'session-expiration-modal',
        title: 'Session Expiring',
        width: 700,
        content: (
          <div className="sessn-mgr-user-warning">
            <p> Your session will be expiring in:</p>
            <h2 className="sessn-mgr-user-time-left">
              {Math.floor(secondsToGo / 60)
                .toString()
                .padStart(2, '0') +
                ':' +
                (secondsToGo % 60).toString().padStart(2, '0')}
            </h2>
            <div className="sessn-mgr-settings">
              <span
                className="sessn-settings-text"
                onClick={this.onExtendSessionFromWarning}
              >
                Session Settings
              </span>
            </div>
          </div>
        ),
        okText: 'Continue Session',
        onOk: this.continueSession,
        cancelText: 'Cancel',
        onCancel: this.resetSessionWarning,
      })

      if (this.warningTimer) {
        clearInterval(this.warningTimer)
      }

      this.warningTimer = setInterval(() => {
        secondsToGo -= 1
        this.warningModal.update({
          content: (
            <div className="sessn-mgr-user-warning">
              <p> Your session will be expiring in:</p>
              <h2 className="sessn-mgr-user-time-left">
                {Math.floor(secondsToGo / 60)
                  .toString()
                  .padStart(2, '0') +
                  ':' +
                  (secondsToGo % 60).toString().padStart(2, '0')}
              </h2>
              <div className="sessn-mgr-settings">
                <span
                  className="sessn-settings-text"
                  onClick={this.onExtendSessionFromWarning}
                >
                  Session Settings
                </span>
              </div>
            </div>
          ),
        })
      }, 1000)
    }
  }

  logout = async () => {
    // if SESSION_TIMEOUT is larger than zero but still trigger logout (e.g. reset timeout by session manager)
    // reset the timer and abort logout
    const sessionTimeout = storage.get.SESSION_TIMEOUT
    if (sessionTimeout > 0) {
      clearTimeout(this.sessionTimeoutWarningTimerId)
      this.setSessionTimeoutWarningTimer()

      clearTimeout(this.sessionTimeoutTimerId)
      this.setSessionTimeoutTimer()

      return
    }

    try {
      clearTimeout(this.sessionTimeoutWarningTimerId)
      clearTimeout(this.sessionTimeoutTimerId)
      clearTimeout(this.refreshSessionTimerId)

      const currSession = storage.get.ENCODED_SESSION_ID || undefined
      const currSessnId =
        (currSession && currSession.split(' ')[1]) || undefined

      this.handleModal()
      await this.sessionMgmt.destroySession(currSessnId)
      warning({
        title: 'Session Expired',
        width: 700,
        content: 'Redirecting to Harmony Controller. Please login to continue.',
        onOk: this.sessionMgmt.redirectToHC,
      })
    } catch (err) {
      console.error('Error in creating new session: ', err)
    }
  }

  refreshSession = () => {
    const currSession = storage.get.ENCODED_SESSION_ID || undefined
    const currSessnId = (currSession && currSession.split(' ')[1]) || undefined
    const baseURL = parameters.BASE_URL
    const headers = {
      Authorization: storage.get.ENCODED_SESSION_ID,
      provider: storage.get.PROVIDER,
      'x-account': basicUtils.getTenantUuids(),
      tenant: storage.get.TENANT,
    }
    const userId = storage.get.USER_ID
    // Create a new session
    axios({
      method: 'POST',
      url: `${baseURL}/sessions`,
      headers,
      data: { userId },
    })
      .then(res => {
        const newSession = res?.data?.id
        if (newSession) {
          storage.set.ENCODED_SESSION_ID(`Session ${newSession}`)
          this.setState(
            {
              dashboardParams: {
                ...this.state.dashboardParams,
                auth: storage.get.ENCODED_SESSION_ID,
              },
            },
            () => this.sessionMgmt.destroySession(currSessnId),
          ) // destroy old session only when new session is created
          console.log('Successfully create session: ', res)
        }
      })
      .catch(err => {
        console.error('Error in creating session: ', err)
      })

    clearTimeout(this.refreshSessionTimerId)
    this.setRefreshSessionTimer()
  }

  componentWillUnmount() {
    clearTimeout(this.sessionTimeoutTimerId)
    clearTimeout(this.sessionTimeoutWarningTimerId)
    clearTimeout(this.refreshSessionTimerId)
  }

  render() {
    const tenants = storage.get.ALLTENANTS
    const { redirectUrl } = this.state
    if (redirectUrl) {
      return this.renderRedirect(redirectUrl)
    }
    if (
      tenants &&
      tenants.length === 0 &&
      this.props.match &&
      this.props.match.params.applicationName === 'Services' &&
      this.isTenantSet &&
      storage.get.ENCODED_SESSION_ID
    ) {
      return this.returnToOriginalState()
      // Dont allow access to services page if there is no tenant
    }
    const applications = this.ApplicationConfigs.getAvailableApps()
    const appIndex = applications.findIndex((key: any) => {
      if (this.props.match) {
        return (
          key.name.toLowerCase() ===
          this.props.match.params.applicationName.toLowerCase()
        )
      } else {
        return key.name === ''
      }
    })
    if (
      appIndex < 0 &&
      !_.includes(errorPath, this.props.match.params.applicationName)
    ) {
      return this.loadDefaultApp()
    }
    const roles = this.ApplicationConfigs.getAllowedRoles(
      this.props.match.params.applicationName,
    )
    if (roles && this.state.adminLevel) {
      const roleIndex = roles.findIndex((key: string) => {
        return key === this.state.adminLevel
      })
      if (roleIndex < 0) {
        // admin level does not contain access to this application
        return this.returnToOriginalState()
      }
    }
    if (!this.isTenantSet) {
      return (
        <A10Spin>
          <A10Alert
            message="User Environment"
            description="Loading Components."
            type="info"
          />
        </A10Spin>
      )
    } else {
      return this.checkLoggedInStatus()
    }
  }
  loadDefaultApp = () => {
    if (this.state.adminLevel === 'provider') {
      const uri = '/controller/Home'
      return <Redirect to={uri} />
    } else {
      const uri = '/controller/Organization'
      return <Redirect to={uri} />
    }
  }
  returnToOriginalState = () => {
    const uri = '/controller/' + this.state.applicationName
    return <Redirect to={uri} />
  }
  checkLoggedInStatus = () => {
    const {
      applicationName,
      subApplicationName,
      dashboardParams,
      isGettingStartedVisible,
    } = this.state
    const appSubappObjects = this.ApplicationConfigs.getAppSubappObjects(
      applicationName,
      subApplicationName,
    )
    const clusterDevice = appSubappObjects.subAppObj?.clusterDeviceDropdown
      ? appSubappObjects.subAppObj.clusterDeviceDropdown
      : ''
    const selectedApp =
      subApplicationName ||
      (this.props.match?.params?.selectedApp
        ? this.props.match.params.selectedApp
        : '')
    const className = `content-layout${
      this.state.leftNavCollapsed ? ' left-nav-collapsed' : ''
    }${this.state.applicationName === 'Dashboard' ? ' dashboard-layout' : ''}`

    if (storage.get.ENCODED_SESSION_ID) {
      return (
        <A10Layout>
          <LeftNav
            defaultMethods={this.defaultMethods}
            applicationName={this.state.applicationName}
            subApplicationName={this.state.subApplicationName}
            tenantChange={this.tenantChange}
            selectedTenant={this.getSelectedTenant()}
            tenantToggled={this.state.tenantToggled}
            refreshTenants={this.state.refreshTenants}
            refreshHCApps={this.state.refreshHCApps}
            leftNavCollapsed={this.state.leftNavCollapsed}
            toggleLeftNav={this.toggleLeftNav}
            headerStyle={this.state.headerStyle}
          />
          <A10Layout
            className={
              selectedApp.toLocaleLowerCase() !== 'objectexplorer'
                ? className
                : `${className} object-explorer`
            }
          >
            <TopHeader
              className={
                this.state.leftNavCollapsed ? ' left-nav-collapsed' : ''
              }
            >
              <Left>
                {selectedApp.toLocaleLowerCase() !== 'objectexplorer' && (
                  <>
                    <LeftNavProviderTenantMenu
                      tenantChange={this.tenantChange}
                      tenants={storage.get.ALLTENANTS}
                      selectedTenant={this.getSelectedTenant()}
                      refreshTenants={this.state.refreshTenants}
                      leftNavCollapsed={this.state.leftNavCollapsed}
                    />

                    {!!clusterDevice && (
                      <ClusterDeviceMenu
                        clusterDeviceType={clusterDevice}
                        clusterDeviceChanged={this.clusterDeviceChanged}
                        selectedClusterDevice={this.getSelectedClusterDevice(
                          clusterDevice,
                        )}
                        refreshClusterDevices={this.state.refreshClusterDevices}
                      />
                    )}
                    <Divider />
                    <A10Breadcrumb className="breadcrumb">
                      <A10Breadcrumb.Item>
                        {appSubappObjects.appDName ||
                          this.state.applicationName}
                      </A10Breadcrumb.Item>
                      <A10Breadcrumb.Item className="sub-menu">
                        {appSubappObjects.subappDName ||
                          this.state.subApplicationName}
                      </A10Breadcrumb.Item>
                    </A10Breadcrumb>
                  </>
                )}
              </Left>
              <Right>
                <Notification />
                <UserMenu
                  sessionMgmtMenuDisplay={this.state.sessionMgmtMenuDisplay}
                  setSessionMgmtMenuDisplay={this.setSessionMgmtMenuDisplay}
                />
                <Divider />
                <HelpSupportEntry />
              </Right>
            </TopHeader>
            <Content className="content-section">
              <A10Modal
                wrapClassName="termsEula"
                width={'70%'}
                zIndex={9999}
                visible={this.showTermsAccepted}
                footer={[null, null]}
                onCancel={this.declineTerms}
                maskClosable={false}
                destroyOnClose={true}
              >
                <AcceptTerms
                  provider={this.provider}
                  user={this.user}
                  token={this.token}
                  loggedIn={true}
                />
              </A10Modal>
              <GettingStarted
                visible={!this.showTermsAccepted && isGettingStartedVisible}
                onCancel={this.onChange}
                maskClosable={true}
                afterClose={this.setGettingStarted}
              />
              <EulaAfterLogin
                userShown={false}
                wasShown={this.eulaAfterLoginWasShown}
                onClose={this.onEulaAfterLoginClose}
              />
              <DGFProvider platform="hc" parameters={dashboardParams}>
                {this.appTobeLoaded()}
              </DGFProvider>
            </Content>
            <Footer
              pageType="main"
              leftNavCollapsed={this.state.leftNavCollapsed}
            />
          </A10Layout>
        </A10Layout>
      )
    } else {
      return <Redirect to="/login/root" />
    }
  }
}
export default setupA10Container(Controller)
