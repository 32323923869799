import { setupA10Container } from '@gui-libraries/framework'
import {
  A10DatePicker,
  A10Form,
  A10Icon,
  A10Radio,
} from '@gui-libraries/widgets'
import _ from 'lodash'
import React, { useState } from 'react'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import A10Panel from 'src/components/ADC/A10Panel'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import './styles/index.less'

interface IMicroServiceDownloadProps {
  show: boolean
  title: string
  onClose: () => void
  onSubmitForm: (data: number) => void
}

const MicroserviceDownloadForm: React.FC<IMicroServiceDownloadProps> = props => {
  const { show, title, onClose, onSubmitForm } = props
  const [date, setDate] = useState(1)
  const [logAge, setLogAge] = useState('recentLogs')

  const handleInputChange = (dates: IObject) => {
    const noOfDays: number = dates[1]?.diff(dates[0], 'days')
    setDate(noOfDays)
  }

  const handleSaveForm = () => {
    onSubmitForm(date)
    setLogAge('recentLogs')
  }

  const handleCloseForm = () => {
    setLogAge('recentLogs')
    onClose()
  }
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  return (
    <FormatSlidingPage
      isOpen={show}
      title={title}
      onRequestOk={handleSaveForm}
      onRequestClose={handleCloseForm}
      saveText="Download"
    >
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel showHeader={false}>
          <A10Form.Item
            {...formItemLayout}
            label={<span style={{ fontWeight: 500 }}>Log Age</span>}
          >
            <A10Radio.Group
              className="radio-component"
              defaultValue="recentLogs"
              buttonStyle="solid"
              testID="log-age-radio"
              onChange={(e: IObject) => setLogAge(e.target.value)}
            >
              <A10Radio className="radio-label" value="recentLogs">
                Recent Logs
              </A10Radio>
              <A10Radio className="radio-label" value="allLogs">
                All Logs
              </A10Radio>
            </A10Radio.Group>
          </A10Form.Item>
          <A10Form.Item
            className="mb-0"
            {...formItemLayout}
            label={<span style={{ fontWeight: 500 }}>Period</span>}
          >
            <A10DatePicker.A10RangePicker
              key={logAge}
              disabled={logAge === 'recentLogs'}
              format="MM/DD/YYYY"
              style={{ width: '100%' }}
              placeholder="Select the date"
              showTime={false}
              separator="-"
              allowClear={false}
              testID="period-date-input"
              onChange={(event: IObject) => handleInputChange(event)}
              disabledDate={current => {
                return current && current.valueOf() > Date.now()
              }}
            />
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    </FormatSlidingPage>
  )
}

export default setupA10Container(A10Form.create()(MicroserviceDownloadForm))
