import React from 'react'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import { A10Table, A10Icon } from '@gui-libraries/widgets'

import { templateMappings } from './protocolFilter'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import A10Panel from 'src/components/ADC/A10Panel'
import storage from 'src/libraries/storage'

import './styles/index.less'

export interface IAssociateVportProps extends IA10ContainerDefaultProps {
  tenant: string
  templateName: string
  existPortNames: string[]
  onChangeSelectedValue: (selectedValue: string[]) => void
}

export interface IAssociateVportState {
  vPorts: IObject[]
  isLoading: boolean
  selectedRowKeys: IObject[]
  currentSelectedNames: string[]
}

class AssociateVport extends A10Container<
  IAssociateVportProps,
  IAssociateVportState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      vPorts: [],
      isLoading: false,
      selectedRowKeys: [],
      currentSelectedNames: [],
    }
  }

  componentWillMount() {
    this.init(this.props)
  }

  componentWillReceiveProps(nextProps: any) {
    this.init(nextProps)
  }

  init = (props: any) => {
    const { existPortNames } = props
    const currentSelectedNames = existPortNames.map((uuid: string) => {
      return uuid
    })
    this.setState({ currentSelectedNames })
  }

  componentDidMount() {
    this.getVPortData()
  }

  getHttpClient = () => {
    const {
      GLOBAL_CONFIG: {
        EPIC_DEPENDENCIES: { httpClient },
      },
    } = this.props
    return httpClient
  }

  getVPortData = async () => {
    // Start to load data
    this.setState({ isLoading: true })

    const { tenant, existPortNames, templateName } = this.props
    const httpClient = this.getHttpClient()
    const supportProtocols = templateMappings[templateName] || []
    const appServiceApi = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/app-svc`
    const vPorts = []
    const initSelectedRowKeys = new Array()
    try {
      const {
        data: { 'app-svc-list': appServices = [] },
      } = await httpClient.get(appServiceApi)
      for (const appService of appServices) {
        const vsApi = `${appServiceApi}/${appService.name}/slb/virtual-server`
        const {
          data: { 'virtual-server-list': vss = [] },
        } = await httpClient.get(vsApi)
        console.log('templateName:', templateName)
        for (const vs of vss) {
          const vsName = vs.name
          const { 'port-list': portList = [] } = vs
          for (const port of portList) {
            if (
              supportProtocols.indexOf(port.protocol) !== -1 ||
              templateName === 'aflex'
            ) {
              port.vip = vsName
              port.appService = appService.name
              port.key = vPorts.length
              if (existPortNames.indexOf(port.uuid) !== -1) {
                initSelectedRowKeys.push(vPorts.length)
              }
              vPorts.push(port)
            }
          }
        }
      }
    } catch {
      //
    }
    this.setState({
      vPorts,
      selectedRowKeys: initSelectedRowKeys,
      isLoading: false,
    })
  }

  getColumns = () => {
    return [
      {
        title: 'vPort',
        dataIndex: 'vport',
        render: (text: IObject, record: IObject) => {
          return record.name
        },
      },
      {
        title: 'Protocol',
        dataIndex: 'protocol',
        render: (text: IObject, record: IObject) => {
          return record.protocol
        },
      },
      {
        title: 'Port',
        dataIndex: 'port',
        render: (text: IObject, record: IObject) => {
          return record['port-number']
        },
      },
      {
        title: 'App Service',
        dataIndex: 'appService',
        render: (text: IObject, record: IObject) => {
          return record.appService
        },
      },
      {
        title: 'VIP',
        dataIndex: 'vip',
        render: (text: IObject, record: IObject) => {
          return record.vip
        },
      },
    ]
  }

  getRowSelection = () => {
    return {
      selectedRowKeys: this.state.selectedRowKeys,
      onSelect: (
        record: IObject,
        selected?: IObject,
        selectedRows?: IObject[],
      ) => {
        const { currentSelectedNames } = this.state
        let actionRows = currentSelectedNames
        if (selected) {
          if (actionRows.indexOf(record.uuid) === -1) {
            actionRows.push(record.uuid)
          }
        } else {
          actionRows = _.remove(actionRows, (name: string) => {
            return name !== record.uuid
          })
        }
        this.setState({ currentSelectedNames: actionRows }, () => {
          this.onChangeValue()
        })
      },
      onSelectAll: (
        selected: IObject,
        selectedRows: IObject[],
        changeRows: IObject[],
      ) => {
        const { currentSelectedNames } = this.state
        let actionRows = currentSelectedNames
        if (selected) {
          changeRows.map((item: IObject) => {
            if (actionRows.indexOf(item.uuid) === -1) {
              actionRows.push(item.uuid)
            }
          })
        } else {
          const changeRowNames = changeRows.map((item: IObject) => {
            return item.uuid
          })
          actionRows = _.remove(actionRows, (name: string) => {
            return changeRowNames.indexOf(name) === -1
          })
        }
        this.setState({ currentSelectedNames: actionRows }, () => {
          this.onChangeValue()
        })
      },
      onChange: (selectedRowKeys: IObject[]) => {
        this.setState({ selectedRowKeys })
      },
    }
  }

  onChangeValue = () => {
    const { currentSelectedNames } = this.state
    const { onChangeSelectedValue } = this.props
    onChangeSelectedValue(currentSelectedNames)
  }

  render() {
    return (
      <A10Panel
        title={
          <A10IconTextGroup
            text="vPorts"
            icon={<A10Icon style={{ fontSize: 48 }} type="desktop" />}
          />
        }
        isFormContent={false}
      >
        <A10Table
          columns={this.getColumns()}
          dataSource={this.state.vPorts}
          rowSelection={this.getRowSelection()}
          pagination={{
            hideOnSinglePage: true,
          }}
          loading={
            this.state.isLoading
              ? {
                  indicator: (
                    <div className="loading-icon">
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    </div>
                  ),
                }
              : false
          }
        />
      </A10Panel>
    )
  }
}

export default setupA10Container(AssociateVport)
