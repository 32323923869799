import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import { A10Modal, A10Button } from '@gui-libraries/widgets'

import { DashboardService, Utilities } from 'src/services/index'
import { App } from '../../App'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { LaunchAppForm } from '../../InstalledApps/Forms/LaunchAppForm'
import parameters from 'parameters'

// import styles from './styles/index.module.less'

export interface ICatalogAppProps extends IA10ContainerDefaultProps {
  onFunctions?(): void
  app: any
  appView: string
  onAppUpdate?(): void
  updateApp?: (data: any) => void
}
export interface ICatalogAppState {
  deleteModalState: boolean
  loadingIcon: boolean
  showSlidingPage: boolean
  launchApp: boolean
  launchAppMainCtnr: boolean
  launchAppObj: any
}

class CatalogApp extends A10Container<ICatalogAppProps, ICatalogAppState> {
  static defaultProps = {
    app: {},
    appView: 'tile',
    defaultMethods: () => {},
  }

  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  isOperatorUser

  state = {
    deleteModalState: false,
    loadingIcon: false,
    showSlidingPage: false,
    launchApp: false,
    launchAppMainCtnr: false,
    launchAppObj: {
      tenant: '',
      appService: '',
      app: {},
    },
  }

  constructor(
    props: ICatalogAppProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: { get },
    } = context

    this.isOperatorUser = get.IS_OPERATOR_USER
  }

  onDeleteApp = () => {
    this.setState({
      deleteModalState: true,
    })
  }

  handleOk = () => {
    this.deleteApp(this.props.app)
  }

  handleCancel = () => {
    this.setState({
      deleteModalState: false,
    })
  }

  enableApp = (app: any) => {
    const {
      storage: { get },
    } = this.context

    this.setState({ loadingIcon: true })
    const targetUrl = get.TARGET_URL
    let enableAppResponse = this.DashboardService.enableHCApp(null, null, [
      targetUrl,
      app.name,
      app.version,
    ])

    enableAppResponse
      .then((response: any) => {
        this.Utilities.showMessage('SUCCESS_APP_INSTALL', 1, 1)
        this.props.onFunctions()
        this.setState({ loadingIcon: false })
      })
      .catch((error: any) => {
        this.setState({ loadingIcon: false })
        const message =
          error && error.response && error.response.data
            ? error.response.data.message
            : (error && error.message) || ''
        this.Utilities.showMessage('FAILURE_APP_INSTALL', 0, 1, message)
      })
  }

  deleteApp = (app: any) => {
    const deleteAppResponse = this.DashboardService.deleteHCApp(null, null, [
      app.name,
      app.version,
    ])
    deleteAppResponse
      .then((response: any) => {
        this.handleCancel()
        this.Utilities.showMessage('SUCCESS_APP_DELETE', 1, 1)
        this.props.onFunctions()
      })
      .catch((error: any) => {
        const message =
          error && error.response && error.response.data
            ? error.response.data.message
            : (error && error.message) || ''
        this.Utilities.showMessage('FAILURE_APP_DELETE', 0, 1, message)
        this.handleCancel()
      })
  }

  onClickDropdownMenu = (component: JSX.Element) => {
    const { app, updateApp } = this.props
    const action = component.key

    if (action === 'Enable') {
      this.enableApp(app)
    } else if (action === 'Update') {
      updateApp(app)
    } else if (action === 'Delete') {
      this.onDeleteApp()
    }
  }

  onLaunchAppMainCtnr = (app: any) => {
    const launchAppObj = {
      tenant: '',
      appService: '',
      app,
    }
    this.setState({
      showSlidingPage: true,
      launchAppMainCtnr: true,
      launchAppObj,
    })
  }

  handleChange = (data: any) => {
    // @ts-ignore
    this.setState({
      launchAppObj: data,
    })
  }

  launchAppURLPage = (app: any) => {
    const {
      storage: { get },
    } = this.context
    const { launchAppObj } = this.state
    const tenantObject = JSON.parse(launchAppObj.tenant || '{}')
    const currentTimestamp = new Date().getTime()
    const providerName = get.PROVIDER
    
    const tenantObj = tenantObject?.uuid
      ? tenantObject
      : get.CURRENT_TENANT
    let appSvcObj = JSON.parse(launchAppObj.appService || '{}')
    const sessionTimeout = get.SESSION_TIMEOUT_INITIAL

    const queryString =
      '?user_id=' +
      get.USER_ID +
      '&api_ep=' +
      encodeURI(parameters.BASE_URL) +
      '&provider=' +
      providerName +
      '&token=' +
      get.USER_SESSION_ID +
      '&timeout=' +
      (sessionTimeout ? sessionTimeout : '1') +
      (tenantObj.name ? '&tenant=' + tenantObj.name : '') +
      (tenantObj.uuid ? '&tenantUUID=' + tenantObj.uuid : '') +
      (appSvcObj.app_svc_id
        ? '&app_svc=' +
          appSvcObj.name +
          '&app_service_id=' +
          appSvcObj.app_svc_id +
          '&app_svc_type=' +
          appSvcObj['app_svc_type'] +
          '&action=config'
        : '')
    const ipv6Url = this.Utilities.checkIPv6BaseURL()
    const baseURL = ipv6Url
      ? parameters.BASE_URL.replace(':8443/api/v2', '') + encodeURI('/hcapps')
      : encodeURI(parameters.BASE_URL.replace(':8443/api/v2', '') + '/hcapps')
    const href = `${baseURL}/${this.props?.app?.name}_${this.props?.app?.version}${queryString}&ts=${currentTimestamp}`

    window.open(href, '_blank')

    this.setState({
      showSlidingPage: false,
    })
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({
      showSlidingPage: isOpen,
    })
  }

  render() {
    const { app } = this.props

    const {
      deleteModalState,
      showSlidingPage,
      launchApp,
      launchAppMainCtnr,
      launchAppObj,
    } = this.state

    const {
      storage: { get },
    } = this.context

    const adminMode = get.SUPER_ADMIN_MODE
    const adminLevel = get.ADMIN_LEVEL
    const drillLevel = get.DRILL_LEVEL
    const displayedVersion = app?.version ? app.version.split('-')[1] : ''

    const menuItems = []
    if (!this.isOperatorUser && adminLevel === 'provider') {
      if (adminMode) {
        menuItems.push(<div key="Update">Update </div>)

        if (app.enabled) {
          menuItems.push(<div className="disabled">Delete</div>)
        } else {
          menuItems.push(<div key="Delete">Delete</div>)
        }
      }
    }

    return (
      <>
        <App
          app={app}
          menuItems={menuItems}
          showLicense={!adminMode}
          actionButton={null}
          onClickDropdownMenu={this.onClickDropdownMenu}
          onClick={
            drillLevel === 'provider' && app.licensed && !adminMode
              ? () => this.onLaunchAppMainCtnr(app)
              : null
          }
        />
        <A10Modal
          title="Delete App"
          visible={deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            {`Are you sure you want to delete the app ${app.name} ${displayedVersion}?`}
          </p>
          <p style={{ fontSize: '12px', fontStyle: 'italic' }}>
            Note: This app may be used by providers and tenants.
          </p>
        </A10Modal>
        {launchApp || launchAppMainCtnr ? (
          <FormatSlidingPage
            isOpen={showSlidingPage}
            title={`${app.name} ${displayedVersion}`}
            description="Please select App service"
            onRequestClose={this.setSlidingPage.bind(this, false)}
            saveText="Proceed"
            disableSave={
              launchAppObj.appService.length <= 0 &&
              this.Utilities.checkAppForLaunchWOAppsvc(app, 'NOR')
            }
            onRequestOk={() => this.launchAppURLPage(app)}
          >
            <LaunchAppForm
              launchAppObj={launchAppObj}
              handleChange={this.handleChange}
              appLaunchWOApp={this.Utilities.checkAppForLaunchWOAppsvc(
                app,
                'OR',
              )}
            />
          </FormatSlidingPage>
        ) : null}
      </>
    )
  }
}

export default setupA10Container(CatalogApp)
