import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { AutoFormV15 } from '@gui-libraries/apps'

import storage from 'src/libraries/storage'

import './style/index.less'

export interface ILoggingProps extends IA10ContainerDefaultProps {
  data?: any[]
}
export interface ILoggingState {
  isLoading: boolean
}

class Logging extends A10Container<ILoggingProps, ILoggingState> {
  constructor(props: ILoggingProps) {
    super(props)
    this.state = {
      isLoading: false,
    }
  }

  render() {
    const apiPrefix = `hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/pso/logical-partition/pso/`
    return (
      <div className="Logging-configuration">
        <AutoFormV15
          hccEnv={true}
          apiPrefix={apiPrefix}
          schemaPath="system/settings/logging"
        />
      </div>
    )
  }
}

export default setupA10Container(Logging)
