import { getNS, IHTTPRequestOptions } from '@gui-libraries/framework'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'

const HPCAPI_PREFIX = '/hpcapi/v3'

export const getIPList = (params: IObject): IHTTPRequestOptions => {
  return {
    namespace: getNS('IP_LIST_LIST'),
    request: async (epicDependence: IObject) => {
      try {
        const actionURL = `${HPCAPI_PREFIX}/provider/${storage.get.PROVIDER}/tenant/pso/logical-partition/pso/ip-list`
        const { data: res } = await httpClient.get(actionURL, { params })
        let ipLists = []
        if (res && res['ip-list-list']) {
          ipLists = res['ip-list-list']
        }
        return ipLists
      } catch (err) {
        console.error(err)
      }
      return []
    },
  }
}

export const getIPListCount = (searchText: string): IHTTPRequestOptions => {
  return {
    namespace: getNS('IP_LIST_COUNT'),
    request: async (epicDependence: IObject) => {
      try {
        const params: IObject = { total: true }
        if (searchText) {
          params.name = encodeURIComponent(searchText)
        }
        const actionURL = `${HPCAPI_PREFIX}/provider/${storage.get.PROVIDER}/tenant/pso/logical-partition/pso/ip-list`
        const { data: res } = await httpClient.get(actionURL, { params })
        let ipListCount = 0
        if (res && res['total-count']) {
          ipListCount = res['total-count'] || 0
        }

        return ipListCount
      } catch (err) {
        console.error(err)
      }
      return 0
    },
  }
}
