import React from 'react'
import propTypes from 'prop-types'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
} from '@gui-libraries/framework'
import { A10Collapse, A10Row, A10Col } from '@gui-libraries/widgets'

import HealthStatus from 'src/components/ADC/HealthStatus'
import DeployedToTemplateList from '../../../Common/DeployedToTemplateList'
import storage from 'src/libraries/storage'

export interface ICRLDetailProps extends IA10ContainerDefaultProps {
  record: any
  tenant: string
  logicalPartition: string
  data: any[]
  defaultActiveAll?: boolean
}

export interface ICRLDetailState {
  isLoading: boolean
}

class CRLDetail extends A10Container<ICRLDetailProps, ICRLDetailState> {
  static childContextTypes = {
    refreshTemplateList: propTypes.func.isRequired,
  }
  static defaultProps = {
    defaultActiveAll: false,
  }
  constructor(props: ICRLDetailProps) {
    super(props)
    this.state = {
      isLoading: false,
    }
  }

  componentDidMount() {
    if (this.props.tenant) {
      this.getData()
    }
  }

  getChildContext() {
    return {
      refreshTemplateList: this.refreshTemplateList,
    }
  }

  refreshTemplateList = () => {
    /* istanbul ignore next */
    this.getData()
  }

  getData = () => {
    // Start to load data
    this.setState({ isLoading: true })

    const {
      A10Dispatchers: { httpRequest },
      record,
      tenant,
      logicalPartition,
    } = this.props

    const name = record.name
    const type = 'crl'
    httpRequest({
      namespace: getNS('DEPLOYED_OBJECT_LIST', { type, name }),
      request: async (epicDependence: any) => {
        try {
          const { httpClient } = epicDependence
          let response = {}
          try {
            const { data: res } = await httpClient.get(
              encodeURI(
                `/hrcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${logicalPartition}/ssl-crl/${name}`,
              ),
            )
            response = res
          } catch (err) {
            /* istanbul ignore next */
            console.log(err)
          }
          let objectList = new Array()
          if (response) {
            objectList = response['referrer-list'] || []
            if (objectList) {
              const templateReg = /[^]*\/slb\/template\/(.*)\/(.*)[^]*/
              objectList.forEach((item: IObject) => {
                const output = item.uri.match(templateReg)
                item.type = output[1]
                item.name = output[2]
              })
            }
          }
          this.setState({ isLoading: false })
          return objectList
        } catch (err) {
          /* istanbul ignore next */
          this.setState({ isLoading: false })
          /* istanbul ignore next */
          return []
        }
      },
    })
  }

  renderDetail = () => {
    const { record, tenant, data, defaultActiveAll } = this.props
    const serverSslList = data.filter(
      object => object['obj-class'] === 'slb.template.server-ssl',
    )
    const clientSslList = data.filter(
      object => object['obj-class'] === 'slb.template.client-ssl',
    )

    const ObjectHeader = (props: any) => {
      return (
        <>
          <A10Row type="flex" align="middle">
            <A10Col lg={20}>
              <div className="section-title-container">
                <span className="section-title">Assigned {props.name}</span>
                <HealthStatus type="number" text={props.count} />
              </div>
            </A10Col>
          </A10Row>
        </>
      )
    }

    return (
      <>
        <div className="detail-section">
          <A10Collapse bordered={false}>
            <A10Collapse
              bordered={false}
              defaultActiveKey={defaultActiveAll ? 'template-client-ssl' : null}
            >
              <A10Collapse.Panel
                header={
                  <ObjectHeader
                    name="Client SSL Templates"
                    count={clientSslList.length}
                  />
                }
                key="template-client-ssl"
              >
                <DeployedToTemplateList
                  name={record.name}
                  type="crl"
                  associateType="client-ssl"
                  templateName="Client SSL"
                  tenant={tenant}
                  data={clientSslList}
                  isLoading={this.state.isLoading}
                />
              </A10Collapse.Panel>
            </A10Collapse>

            <A10Collapse
              bordered={false}
              defaultActiveKey={defaultActiveAll ? 'template-server-ssl' : null}
            >
              <A10Collapse.Panel
                header={
                  <ObjectHeader
                    name="Server SSL Templates"
                    count={serverSslList.length}
                  />
                }
                key="template-server-ssl"
              >
                <DeployedToTemplateList
                  name={record.name}
                  type="crl"
                  associateType="server-ssl"
                  templateName="Server SSL"
                  tenant={tenant}
                  data={serverSslList}
                  isLoading={this.state.isLoading}
                />
              </A10Collapse.Panel>
            </A10Collapse>
          </A10Collapse>
        </div>
      </>
    )
  }

  render() {
    return <div className="list-detail-container">{this.renderDetail()}</div>
  }
}

function mapStateToProps(state: any, props: ICRLDetailProps) {
  const { record } = props
  const type = 'crl'
  const name = record.name
  return {
    data: state.A10Data.getIn(
      getNS('DEPLOYED_OBJECT_LIST', { type, name }),
      [],
    ),
  }
}

export default setupA10Container(CRLDetail, mapStateToProps)
