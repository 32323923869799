import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { TenantAddForm } from 'src/containers/Controller/Organization/Tenants/Forms/TenantAddForm'
import { TenantEditForm } from 'src/containers/Controller/Organization/Tenants/Forms/TenantEditForm'
import { Messages } from 'src/locale/en'
import { Utilities, DashboardService, OrganizationService } from 'src/services'
import storage from 'src/libraries/storage'
import UserRole from 'src/constants/Data/UserRole'

import './styles/slidingtenantforms.scss'

export interface ISlidingTenantFormsProps extends IA10ContainerDefaultProps {
  showSlidingPage: boolean
  editMode: boolean
  tenants: any
  tenantObj: any
  loadTenants(): void
  updateState(key: string, value: any): void
}
export interface ISlidingTenantFormsState {
  tenantObj: any
  showSlidingPage: boolean
  editMode: boolean
}

class SlidingTenantForms extends A10Container<
  ISlidingTenantFormsProps,
  ISlidingTenantFormsState
> {
  static getDerivedStateFromProps(
    nextProps: ISlidingTenantFormsProps,
    prevState: ISlidingTenantFormsState,
  ) {
    let tenantObj = prevState.tenantObj,
      showSlidingPage = prevState.showSlidingPage,
      editMode = prevState.editMode
    if (prevState.tenantObj != nextProps.tenantObj) {
      tenantObj = nextProps.tenantObj
    }
    if (prevState.showSlidingPage != nextProps.showSlidingPage) {
      showSlidingPage = nextProps.showSlidingPage
    }
    if (prevState.editMode != nextProps.editMode) {
      editMode = nextProps.editMode
    }
    return {
      tenantObj,
      showSlidingPage,
      editMode,
    }
  }
  Messages = new Messages()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  OrganizationService = new OrganizationService()
  UserRole = new UserRole()
  childForm: any = null
  tenantAdminObj: any = {}

  constructor(props: ISlidingTenantFormsProps) {
    super(props)
    this.state = {
      tenantObj: this.props.tenantObj,
      showSlidingPage: false,
      editMode: this.props.editMode,
    }
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({
      showSlidingPage: isOpen,
    })
    this.props.updateState('showSlidingPage', isOpen)
  }

  handleChange = (stateName: string, e: any) => {
    const tenantObj = this.state.tenantObj
    tenantObj[stateName] = e.target.value
    // @ts-ignore

    this.setState({ tenantObj })
    this.props.updateState('tenantObj', tenantObj)
  }

  handleFormValidation = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  handleSave = () => {
    if (this.state.editMode) {
      this.handleUpdate()
    } else {
      this.handleAdd()
      this.props.updateState('searchString', '')
    }
  }

  handleAdd = () => {
    const { tenantName, tenantDName, description } = this.state.tenantObj

    const tenantObj = {
      tenant: {
        name: tenantName,
        'provider-id': storage.get.PROVIDER_ID,
        'display-name': tenantDName || tenantName,
        description,
      },
    }

    const tenantAdd = this.DashboardService.addTenants(
      null,
      tenantObj,
      storage.get.PROVIDER,
    )
    tenantAdd
      .then((response: any) => {
        this.Utilities.showMessage('TENANT_ADD_SUCCESS', 1, 1)
        this.setSlidingPage(false)
        this.props.loadTenants()
      })
      .catch((error: any) => {
        if (!this.Utilities.isRBACAccessDenied(error)) {
          this.Utilities.showMessage('TENANT_ADD_FAILED', 0, 1, error?.response?.data?.message)
        }
      })
    return false
  }

  handleUpdate = () => {
    const { tenantName, tenantDName, name, description } = this.state.tenantObj
    const displayName = tenantDName || tenantName
    if (!this.Utilities.validateDisplayName(displayName)) {
      this.Utilities.showMessage('INVALID_TENANT_NAME', 0, 1)
      return
    } else {
      const payload = {
        tenant: {
          'display-name': displayName,
          description,
        },
      }
      const urlInput = [storage.get.PROVIDER, name]
      const upadteTenantName = this.DashboardService.updateTenant(
        null,
        payload,
        urlInput,
      )
      upadteTenantName
        .then(() => {
          this.Utilities.showMessage('SUCCESS_TENANT_UPDATE', 1, true, '')
          this.setSlidingPage(false)
          this.props.loadTenants()
        })
        .catch((error: any) => {
          console.log('some thing to be handleded')
        })
    }
  }

  render() {
    return (
      <FormatSlidingPage
        isOpen={this.props.showSlidingPage}
        onRequestOk={this.handleFormValidation}
        onRequestClose={this.setSlidingPage.bind(this, false)}
        title={
          this.state.editMode ? (
            'Edit Tenant'
          ) : (
            <>
              <span className="blue-text" /> <span className="blue-text" /> Add
              new Tenant
            </>
          )
        }
        description={
          this.state.editMode
            ? 'Please provide the Tenant details'
            : 'Adding a new Tenant under ' + storage.get.PROVIDER
        }
      >
        {this.state.editMode ? (
          <TenantEditForm
            tenants={this.props.tenants}
            tenant={this.state.tenantObj}
            handleChange={this.handleChange}
            onSubmitForm={this.handleSave}
            onRef={(ref: any): any => (this.childForm = ref)}
          />
        ) : (
          <TenantAddForm
            tenants={this.props.tenants}
            tenant={this.state.tenantObj}
            handleChange={this.handleChange}
            onSubmitForm={this.handleSave}
            onRef={(ref: any): any => (this.childForm = ref)}
          />
        )}
      </FormatSlidingPage>
    )
  }
}
export default setupA10Container(SlidingTenantForms)
