import { _ } from '@gui-libraries/framework'

import storage, { severityMap, priorityMap } from 'src/libraries/storage'
import * as basicUtils from './basicUtils'
export {
  default as analysisAlerts,
  getAlertTraffic,
  getIndividualTenantAlert,
  alertEventsAPIs,
  fetchAlertAndTrigger,
} from './analysisAlerts'
export { getEvents, getEventTraffic, getEventsDisplay } from './analysisEvents'
export { hasChangedTimePeriod } from './basicUtils'
export { getAppServiceTotal } from './appService'
export { getAudits } from './analysisAudit'
export {
  getWorkflow,
  getLatestWorkflow,
  getMapDetailInfo,
  getWFDetailInfo,
  restartWorkflow,
  terminateWorkflow,
} from './analysisWorkflow'
export { default as analysisSystemLog } from './analysisSystemLog'

const { get, set } = storage

export const analysisUser = async (httpClient: IObject) => {
  const { data: users = [] } = await httpClient.get('/users', {
    absoluteBasePath: true,
  })

  const roleUserMapping = {}
  const userRoleMapping = {}
  const userTenantMapping = {}
  const tenantUserMapping = {}
  users.map((user: IObject) => {
    const userRoles = user['roles-list'] || []
    const roleList = userRoleMapping[user['user-id']] || []
    let tenantList = userTenantMapping[user['user-id']] || []
    userRoles.map((role: IObject) => {
      const userList = roleUserMapping[role.role] || []
      if (userList.indexOf(user['user-id']) === -1) {
        userList.push(user['user-id'])
        roleUserMapping[role.role] = userList
      }

      if (roleList.indexOf(role.role) === -1) {
        roleList.push(role.role)
      }

      const scopes = _.get(role, 'scopes', [])
      scopes.map((scope: IObject) => {
        const tenantIds = _.get(scope, 'providers.tenants.ids', [])
        tenantList = tenantList.concat(tenantIds)
        tenantIds.map((id: string) => {
          const existUserList = tenantUserMapping[id] || []
          if (existUserList.indexOf(user.name) === -1) {
            existUserList.push(user)
          }
          tenantUserMapping[id] = existUserList
        })
      })
    })
    userTenantMapping[user['user-id']] = Array.from(new Set(tenantList))
    userRoleMapping[user['user-id']] = roleList
  })
  return {
    users,
    roleUserMapping,
    userRoleMapping,
    userTenantMapping,
    tenantUserMapping,
  }
}

export const analysisDevicePartition = (devices: IObject[]) => {
  let partitions = new Array()
  devices.map((device: IObject) => {
    const { 'partition-list': partitionList } = device
    partitions = partitions.concat(partitionList)
  })

  const tenantPartitionMapping = {}
  partitions.map((partition: IObject = {}) => {
    const { 'mapped-to-tenant': tenant = '' } = partition
    const existPartitions = tenantPartitionMapping[tenant] || []
    if (existPartitions.indexOf(partition.name) === -1) {
      existPartitions.push(partition.name)
    }
    tenantPartitionMapping[tenant] = existPartitions
  })
  return {
    devices,
    partitions,
    tenantPartitionMapping,
  }
}

/* istanbul ignore next */
const mergeTimeSeries = (series1: any[], series2: any[]) => {
  let i = 0
  let j = 0
  if (series1.length === 0) {
    return series2
  } else if (series2.length === 0) {
    return series1
  }
  // const agSeries = []
  // while (i < series1.length && j < series2.length) {
  //   if (series1[i][0] < series2[j][0] || j === series2.length) {
  //     if (series1[i]) {
  //       agSeries.push(series1[i])
  //     }
  //     i += 1
  //   } else if (series1[i][0] === series2[j][0]) {
  //     const newDataPoint = [series1[i][0], series1[i][1] + series2[j][1]]
  //     if (newDataPoint[0] && newDataPoint[1]) {
  //       agSeries.push(newDataPoint)
  //     }
  //     i += 1
  //     j += 1
  //   } else {
  //     if (series1[j]) {
  //       agSeries.push(series1[j])
  //     }
  //     j += 1
  //   }
  // }
  const tsKeys = [] as number[]
  const values = {}
  series1.map((data: [number, number]) => {
    const [key, value] = data
    if (!_.includes(tsKeys, key)) {
      tsKeys.push(key)
    }
    const count = values[key] || 0
    values[key] = count + value
  })
  series2.map((data: [number, number]) => {
    const [key, value] = data
    if (!_.includes(tsKeys, key)) {
      tsKeys.push(key)
    }
    const count = values[key] || 0
    values[key] = count + value
  })
  const sortKeys = tsKeys.sort()
  // return agSeries
  return sortKeys.map((key: number) => {
    return [key, values[key] || 0]
  })
}

export const mergeErrorArray = (stackChartArray: IObject[]) => {
  const mergedArrayObj = {}
  const severeType = ['Emergency', 'Alert', 'Critical', 'Error']
  stackChartArray.map((item: IObject) => {
    if (severeType.indexOf(item.name) > -1) {
      if (!mergedArrayObj['Top 4 Severity']) {
        mergedArrayObj['Top 4 Severity'] = {
          color: '#E57373',
          id: 'Top 4 Severity',
          name: 'Top 4 Severity',
          data: item.data,
        }
      } else {
        mergedArrayObj['Top 4 Severity'].data = mergeTimeSeries(
          mergedArrayObj['Top 4 Severity'].data,
          item.data,
        )
      }
    } else {
      mergedArrayObj[item.name] = item
    }
  })
  return Object.values(mergedArrayObj)
}

export const mergeErrorCount = (severityCount: any) => {
  if (severityCount.filters) {
    const {
      Emergency = 0,
      Alert = 0,
      Critical = 0,
      Error: error = 0,
    } = severityCount.filters

    severityCount.filters['Top 4 Severity'] =
      Emergency + Alert + Critical + error
  }
  // severityCount.filters['Top 4 Severity'] = severityCount.filters.Emergency
  //   ? severityCount.filters.Emergency
  //   : 0 + severityCount.filters.Alert
  //   ? severityCount.filters.Alert
  //   : 0 + severityCount.filters.Critical
  //   ? severityCount.filters.Critical
  //   : 0 + severityCount.filters.Error
  //   ? severityCount.filters.Error
  //   : 0
  // delete severityCount.filters.Emergency
  // delete severityCount.filters.Alert
  // delete severityCount.filters.Critical
  // delete severityCount.filters.Error

  return severityCount
}

export const getLogPeriod = async (httpClient: IObject) => {
  const defaultPeriod = 6
  if (get.LOG_RETENTION) {
    return Number(get.LOG_RETENTION)
  }
  try {
    const { data: data } = await httpClient.get(
      '/analytics/configuration/analytics-metrics-processing-engine/metric-store-proc.log.retention.period.hrs',
      {
        absoluteBasePath: true,
      },
    )
    if (data && data['metric-store-proc.log.retention.period.hrs'] != null) {
      const newPeriod =
        data['metric-store-proc.log.retention.period.hrs'] || '6'
      set.LOG_RETENTION(newPeriod)
      return Number(newPeriod)
    }
  } catch (e) {
    return defaultPeriod
  }
  return defaultPeriod
}

/* istanbul ignore next */
export const getStackChartCount = async (
  httpClient: IObject,
  selectedTimePeriod: IObject,
  type: string,
  clusterId?: string,
) => {
  const { PROVIDER } = get

  let stackChartArray = new Array()
  const alertArray = new Array()
  const eventArray = new Array()
  const alertDict = {}
  const eventDict = {}
  const alertSeverityCount = { total: 0, filters: {}, filtersByLevel: {} }
  const eventSeverityCount = { total: 0, filters: {} }
  let severityCount = { total: 0, filters: {} }
  let alertCount = 0
  let eventCount = 0
  let eventAlertCount = 0
  let slotInterval = Math.floor(
    (selectedTimePeriod.endTime - selectedTimePeriod.startTime) / 100,
  )
  if (slotInterval < 60000) {
    slotInterval = 60000
  }
  const { ALLTENANTS: tenants } = get
  const tenantUuids = basicUtils.getTenantUuids('|')
  let alertbody = {
    alert: {
      fields: ['ts'],
      aggregation: 'count',
      sort: 'asc',
      rangeby: {
        start: Number(selectedTimePeriod.startTime),
        end: Number(selectedTimePeriod.endTime),
        field: 'ts',
      },
      filterby: {
        and: {
          account_id: tenantUuids,
        },
      },
      groupby: 'severity',
      field: 'ts',
      histogram: {
        interval: slotInterval,
        field: 'ts',
      },
      type: 'event',
    },
  }

  let eventbody = {
    event: {
      fields: ['ts'],
      aggregation: 'count',
      sort: 'asc',
      rangeby: {
        start: Number(selectedTimePeriod.startTime),
        end: Number(selectedTimePeriod.endTime),
        field: 'ts',
      },
      groupby: 'severity',
      field: 'ts',
      histogram: {
        interval: slotInterval,
        field: 'ts',
      },
      type: 'event',
    },
  }

  let headers = {}
  await getLogPeriod(httpClient)

  if (type === 'provider' || type === 'cluster') {
    let xAccount = ''
    tenants.map((tenant: any, index: any) => {
      xAccount = xAccount + tenant.uuid
      if (index < tenants.length - 1) {
        xAccount = xAccount + ','
      }
      return 0
    })
    headers = {
      provider: PROVIDER,
      'X-Account': xAccount,
      'Content-Type': 'application/json',
    }
    if (type === 'cluster') {
      alertbody.alert.filterby.and['cluster_id'] = clusterId
        ? clusterId
        : 'undefined'
      eventbody.event.filterby = {
        and: { cluster_id: clusterId ? clusterId : 'undefined' },
      }
    }
  } else if (type === 'tenant') {
    const currentTenant = basicUtils.getCurrentTenant()
    headers = {
      provider: PROVIDER,
      // tenant: currentTenant.name,
      'X-Account': currentTenant.uuid,
      'Content-Type': 'application/json',
    }
  }
  try {
    if (tenantUuids) {
      const {
        data: { alert: alertData = {} },
      } = await httpClient.post('/analytics/event/hc_event', alertbody, {
        headers,
        absoluteBasePath: true,
      })
      for (const priority in alertData) {
        if (alertData.hasOwnProperty(priority)) {
          const stackData = new Array()
          const priorityDef = priorityMap[priority].definition
          for (const key in alertData[priority].ts_count) {
            if (alertData[priority].ts_count.hasOwnProperty(key)) {
              stackData.push([Number(key), alertData[priority].ts_count[key]])
              alertCount += alertData[priority].ts_count[key]
              eventAlertCount += alertData[priority].ts_count[key]
              alertSeverityCount.filters[priorityDef]
                ? (alertSeverityCount.filters[priorityDef] +=
                    alertData[priority].ts_count[key])
                : (alertSeverityCount.filters[priorityDef] =
                    alertData[priority].ts_count[key])
              severityCount.filters[priorityDef]
                ? (severityCount.filters[priorityDef] +=
                    alertData[priority].ts_count[key])
                : (severityCount.filters[priorityDef] =
                    alertData[priority].ts_count[key])
            }
          }
          alertDict[priorityDef] = stackData
        }
      }
      alertSeverityCount.filtersByLevel.error = alertSeverityCount.filters
        .Emergency
        ? alertSeverityCount.filters.Emergency
        : 0 + alertSeverityCount.filters.Alert
        ? alertSeverityCount.filters.Alert
        : 0 + alertSeverityCount.filters.Critical
        ? alertSeverityCount.filters.Critical
        : 0 + alertSeverityCount.filters.Error
        ? alertSeverityCount.filters.Error
        : 0

      alertSeverityCount.filtersByLevel.warning = alertSeverityCount.filters
        .Warning
        ? alertSeverityCount.filters.Warning
        : 0

      alertSeverityCount.filtersByLevel.notification = alertSeverityCount
        .filters.Notification
        ? alertSeverityCount.filters.Notification
        : 0

      alertSeverityCount.filtersByLevel.info = alertSeverityCount.filters.Info
        ? alertSeverityCount.filters.Info
        : 0

      alertSeverityCount.filtersByLevel.debug = alertSeverityCount.filters.Debug
        ? alertSeverityCount.filters.Debug
        : 0

      alertSeverityCount.total = alertCount
      severityCount.total = eventAlertCount
    }
  } catch (err) {
    // console.error(err)
  }

  try {
    if (tenantUuids) {
      const {
        data: { event: eventData = {} },
      } = await httpClient.post(
        '/analytics/thunder-adc/system_logs_to_hc',
        eventbody,
        {
          headers,
          absoluteBasePath: true,
        },
      )

      for (const severity in eventData) {
        if (eventData.hasOwnProperty(severity)) {
          if (Number(severity) > 7) {
            continue
          }
          const stackData = new Array()
          const severityDef = severityMap[Number(severity)].definition
          for (const key in eventData[severity].ts_count) {
            if (eventData[severity].ts_count.hasOwnProperty(key)) {
              stackData.push([Number(key), eventData[severity].ts_count[key]])
              eventCount += eventData[severity].ts_count[key]
              eventAlertCount += eventData[severity].ts_count[key]
              eventSeverityCount.filters[severityDef]
                ? (eventSeverityCount.filters[severityDef] +=
                    eventData[severity].ts_count[key])
                : (eventSeverityCount.filters[severityDef] =
                    eventData[severity].ts_count[key])
              severityCount.filters[severityDef]
                ? (severityCount.filters[severityDef] +=
                    eventData[severity].ts_count[key])
                : (severityCount.filters[severityDef] =
                    eventData[severity].ts_count[key])
            }
          }
          eventDict[severityDef] = stackData
        }
      }
      eventSeverityCount.total = eventCount
      severityCount.total = eventAlertCount
    }
  } catch (err) {
    console.log('error')
    // console.error(err)
  }

  for (const severity of severityMap) {
    const severityDef = severity.definition
    const series1 = alertDict[severityDef] ? alertDict[severityDef] : []
    const series2 = eventDict[severityDef] ? eventDict[severityDef] : []
    const severityObj = {
      data: mergeTimeSeries(series1, series2),
      name: severityDef,
      color: severity.color,
    }
    const alertObj = {
      data: series1,
      name: severityDef,
      color: severity.color,
    }
    const eventObj = {
      data: series2,
      name: severityDef,
      color: severity.color,
    }
    if (series1.length > 0 || series2.length > 0) {
      stackChartArray.push(severityObj)
    }
    if (series1.length > 0) {
      alertArray.push(alertObj)
    }
    if (series2.length > 0) {
      eventArray.push(eventObj)
    }
  }

  stackChartArray = mergeErrorArray(stackChartArray)
  severityCount = mergeErrorCount(severityCount)
  const stackSeriesObj = {
    series: stackChartArray,
    count: severityCount,
  }

  const alertSeriesObj = {
    series: alertArray,
    count: alertSeverityCount,
  }

  const eventSeriesObj = {
    series: eventArray,
    count: eventSeverityCount,
  }
  return {
    stackSeriesObj,
    alertSeriesObj,
    eventSeriesObj,
  }
}
