import React from 'react'
import { A10Avatar, A10Tooltip } from '@gui-libraries/widgets'

import styles from './styles/index.module.less'

interface IAlertStatusProps {
  title?: string
  status: string | number
}

const GREEN = '#81C784'
const RED = '#E57373'
const ORANGE = '#FFBA47'
const GRAY = '#C3CBCF'
const BLUE = '#64B5F6'
const YELLOW = '#FFE082'

const top4SeverityTooltip =
  'Top 4 Severity levels are: Emergency, Alert, Critical, and Error'
const statusMap = {
  0: {
    color: RED,
    tooltip: top4SeverityTooltip,
  },
  1: { color: RED, tooltip: top4SeverityTooltip },
  2: { color: RED, tooltip: top4SeverityTooltip },
  3: { color: RED, tooltip: top4SeverityTooltip },
  4: { color: ORANGE, tooltip: 'Warning' },
  5: { color: GREEN, tooltip: 'Normal' },
  6: { color: BLUE, tooltip: 'Info' },
  7: { color: YELLOW, tooltip: 'Debug' },
  8: { color: GRAY, tooltip: 'Unknown' },
}

const AlertStatus: React.FC<IAlertStatusProps> = props => {
  const { title, status = 8 } = props
  const { color, tooltip } = statusMap[status]

  return (
    <A10Tooltip title={tooltip}>
      <A10Avatar
        className={styles.alertAvatar}
        style={{ backgroundColor: color }}
      >
        {title}
      </A10Avatar>
    </A10Tooltip>
  )
}

export default AlertStatus
