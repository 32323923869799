import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  // A10Icon,
  A10Row,
  A10Col,
  A10Table,
  A10Button,
  A10Modal,
  A10DropdownMenu,
  A10Radio,
  A10Badge,
  A10Message
} from '@gui-libraries/widgets'

import { httpClient } from 'src/libraries/httpClient'
import { Messages } from 'src/locale/en/'
import { LicenseCharts } from 'src/containers/Controller/Organization/License/LicenseDetails/LicenseCharts'
import ReactLoading from 'react-loading'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { Utilities, InfrastructureService } from 'src/services/index'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import ChangeDeviceForm from '../Forms/ChangeDeviceForm'
import BulkUpdateForm from '../Forms/BulkUpdateForm'
import globalConfig from 'src/settings/config'
import storage from 'src/libraries/storage'
import { ActionButton } from 'src/components/shared/ActionButton'

const styles = require('./styles/licensedetails.scss')
const moment = require('moment')

export interface ILicenseDetailsProps extends IA10ContainerDefaultProps {
  isUpdateAuditLog: boolean
  licenseData: IObject
  refreshData: any
  glmLicTypeText: (glmLicType: string, licType: string) => string
}
export interface ILicenseDetailsState {
  isLoading: boolean
  showSlidingPage: boolean
  slidingForm: string
  activityLog: any
  allotedBandwidth: any
  activeLicenses: boolean[]
  deleteModalState: boolean
  bulkModalState: boolean
  loadingIcon: boolean
  bulkDevices: string[]
  tab: string
  devicesList: IObject[]
  disableSave: boolean
}

class LicenseDetails extends A10Container<
  ILicenseDetailsProps,
  ILicenseDetailsState
> {
  Messages = new Messages()
  Utilities = new Utilities()
  InfrastructureService = new InfrastructureService()
  licenseActivityColumns: any[]
  thundersColumns: any[]
  editTitle = 'Update License'
  editDesc = 'Select license key'
  formData = {
    hcLicenseNewKey: '',
    deviceLicenseNewKey: '',
    newBandwidth: '',
    newBandwidthUnit: '',
    numBandwidth: '',
    numBandwidthUnit: '',
  }
  selectedDevice: any
  childForm: any = null
  oldDevices: any[] = []
  isOperatorUser = storage.get.IS_OPERATOR_USER

  constructor(props: ILicenseDetailsProps) {
    super(props)
    this.state = {
      isLoading: false,
      showSlidingPage: false,
      slidingForm: '',
      activityLog: [],
      allotedBandwidth: '',
      activeLicenses: [false, false],
      deleteModalState: false,
      bulkModalState: false,
      loadingIcon: false,
      bulkDevices: [],
      devicesList: [],
      tab: 'licenseInformation',
      disableSave: false
    }
    this.getActivityLogs()
    this.licenseActivityColumns = [
      {
        title: '',
        dataIndex: 'activation-status',
        key: 'activation-status',
        render: (text: string, record: any, index: number) => {
          const status = record['activation-status']
          const statusObj = this.Utilities.getVal(
            status,
            'LICENSE',
            'activation-status',
          )
          return (
            <div className="icons-wrap" title={statusObj.label}>
              <HealthStatus type={statusObj.type} tooltip={statusObj.label} />
            </div>
          )
        },
      },
      {
        title: 'Date/Time',
        dataIndex: 'last-modified-at',
        key: 'last-modified-at',
        defaultSortOrder: 'descend',
        sorter: (a: any, b: any) =>
          this.Utilities.sortDate(a, b, 'last-modified-at'),
        render: (text: string, record: any, index: number) => {
          return record['last-modified-at']
        },
      },
      {
        title: 'Activity',
        dataIndex: 'action-log',
        key: 'action-log',
      },
      {
        title: 'Resource',
        dataIndex: 'resource',
        key: 'resource',
        // render: (text: string, record: any, index: number) => {
        //   return this.props.licenseData.thunders ? 'Thunder' : 'GLM'
        // },
      },
      {
        title: 'License type',
        dataIndex: 'license-type',
        key: 'license-type',
        render: (text: string, record: any, index: number) => {
          const glmLicType = this.props.licenseData['glm-lic-type']
          let licTypeValue = ''
          if (glmLicType) {
            licTypeValue = this.props.glmLicTypeText(
              glmLicType,
              this.props.licenseData['license-type'],
            )
          }
          return this.props.licenseData['license-type'].indexOf('FLEXPOOL') > -1
            ? licTypeValue
            : this.props.licenseData['license-type']
        },
      },
    ]

    this.thundersColumns = [
      {
        title: 'Device',
        dataIndex: 'device-name',
        className: 'td-truncate',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'device-name'),
        render: (text: string, record: any, index: number) => {
          const name = record['device-name']
          return (
            <div>
              <HealthStatus
                type={'ongoing'}
                text={'D'}
                tooltip={name}
                className="top-7"
              />
              <span className="device-name td-truncate" title={name}>
                {name}
              </span>
            </div>
          )
        },
      },
      {
        title: 'Cluster',
        dataIndex: 'cluster-name',
        className: 'td-truncate',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'cluster-name'),
        render: (text: string, record: any, index: number) => {
          const deviceObj = this.Utilities.getDeviceObj(
            this.state.devicesList,
            record['device-id'],
          )
          const clusterObj =
            deviceObj && deviceObj.cluster
              ? this.Utilities.getClusterObjforDevice(deviceObj.cluster)
              : ''
          const clusterName = (record['cluster-name'] =
            clusterObj && clusterObj['display-name']
              ? clusterObj['display-name']
              : deviceObj
              ? deviceObj.cluster
              : '')
          return (
            <div>
              {clusterName ? (
                <HealthStatus
                  type={'ongoing'}
                  hideTooltip={!clusterName}
                  text={'C'}
                  tooltip={clusterName}
                  className="top-7"
                />
              ) : null}
              <span className="device-name td-truncate" title={clusterName}>
                {clusterName}
              </span>
            </div>
          )
        },
      },
      {
        title: 'Device IP',
        dataIndex: 'device-ip',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'device-ip'),
        render: (text: string, record: any) => {
          const deviceObj = this.Utilities.getDeviceObj(
            this.state.devicesList,
            record['device-id'],
          )
          const deviceIP = (record['device-ip'] = deviceObj
            ? deviceObj.host
            : '')
          return <>{deviceIP}</>
        },
      },
      {
        title: 'State',
        dataIndex: 'state',
        sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'state'),
        render: (text: string, record: any) => {
          const deviceObj = this.Utilities.getDeviceObj(
            this.state.devicesList,
            record['device-id'],
          )
          const state = (record['state'] = deviceObj
            ? deviceObj['registration-state']
            : '')
          return <>{state}</>
        },
      },
      {
        title: 'Location',
        dataIndex: 'location',
        sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'location'),
        render: (text: string, record: any) => {
          const deviceObj = this.Utilities.getDeviceObj(
            this.state.devicesList,
            record['device-id'],
          )
          const location = (record['location'] = deviceObj
            ? deviceObj.location
            : '')
          return <>{location}</>
        },
      },
      {
        title: 'Allocated BW',
        dataIndex: 'alloted-bandwidth',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'alloted-bandwidth'),
        key: 'alloted-bandwidth',
        render: (text: string, record: any, index: number) => {
          const bandwidth = this.Utilities.megaToGiga(
            record['alloted-bandwidth'] || 0,
          )
          return bandwidth.val + (bandwidth.unit === 'm' ? ' Mbps' : ' Gbps')
        },
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (key: any) => {
          const clickAction = (component: JSX.Element, index: number) => {
            if (component.key === 'edit') {
              this.editLicence(key)
            } else if (
              component.key === 'delete' &&
              this.props.licenseData['license-type'].indexOf('FLEXPOOL') > -1
            ) {
              this.setState({ deleteModalState: true })
              this.selectedDevice = key
            }
          }
          // const avaialableLicenses = this.Utilities.getAvailableLicenseForReassign(storage.get.ALL_LICENSE', true), this.props.licenseData['entitlement-key'])
          return this.isOperatorUser ? null : (
            <div className="table-dropdown">
              <A10DropdownMenu
                menu={
                  this.props.licenseData['license-type'].indexOf('FLEXPOOL') >
                  -1
                    ? [
                        <div key="edit">Update License</div>,
                        <div key="delete">Remove from License</div>,
                      ]
                    : [<div key="edit">Update License</div>]
                }
                onClick={clickAction}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
                title=""
              />
            </div>
          )
        },
      },
    ]
  }

  componentDidMount() {
    this.getDevices()
  }

  getDevices = () => {
    const headerObj =
      storage.get.ADMIN_LEVEL === 'provider'
        ? null
        : this.Utilities.getXAccountHeaderDetails(false)
    const deviceResp = this.InfrastructureService.getDevices(headerObj, null, [
      storage.get.PROVIDER,
    ])
    deviceResp
      .then((resp: IObject) => {
        let devices = []
        if (resp && resp.data && resp.data['device-list']) {
          devices = resp.data['device-list'] || []
        }
        this.setState({ devicesList: devices })
      })
      .catch((error: IObject) => {
        console.log(error.response)
      })
  }

  getActivityLogs = async () => {
    const {
      EPIC_DEPENDENCIES: { httpClient },
    } = globalConfig
    const url = `/hls/licenses/providers/${storage.get.PROVIDER}/entitlementkey/${this.props.licenseData['entitlement-key']}/auditlogs`
    try {
      const { data: res } = await httpClient.get(url, {
        absoluteBasePath: true,
      })
      if (res) {
        const activityLogs = res || []
        // activityLogs.map((activityLog: any) => {
        //   activityLog.lastModifiedAt = Date.parse(activityLog.lastModifiedAt)
        // })
        this.setState({ activityLog: activityLogs })
      }
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err)
    }
  }

  closeImageSlidingPage = () => {
    this.setState({ showSlidingPage: false, slidingForm: '' })
    // Get Data Call here
    // this.props.updateList()
  }
  handleFormValidation = () => {
    if (this.state.slidingForm === 'bulkUpdate') {
      this.bulkUpdateDevices()
      return
    }
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
    if (
      this.formData.deviceLicenseNewKey &&
      this.formData.newBandwidth === ''
    ) {
      this.Utilities.showMessage('BANDWIDTH_REQUIRED', 0, 1)
    }
  }

  addLicense = async () => {
    const { licenseData } = this.props
    const {
      EPIC_DEPENDENCIES: { httpClient },
    } = globalConfig
    let nothingUpdated = false

    if (this.formData.hcLicenseNewKey) {
      const url = `/hls/licenses/providers/${storage.get.PROVIDER}/change/device/${this.selectedDevice['device-id']}/HC_LICENSE/newkey/${this.formData.hcLicenseNewKey}`
      try {
        const { data } = await httpClient.post(url, null, {
          absoluteBasePath: true,
        })
        if (data || true) {
          // this.formData.hcLicenseNewKey = ''
          // this.setDefaultValues()
          // this.props.refreshData()
          this.Utilities.showMessage('HARMONY_LICENSE_CHANGE_SUCCESS', 1, 1)
        }
        // this.closeImageSlidingPage()
      } catch (err) {
        const msg =
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : ''
        this.Utilities.showMessage('HARMONY_LICENSE_CHANGE_FAIL', 0, 1, msg)
      }
    } else {
      nothingUpdated = true
    }

    const devLicKey = this.formData.deviceLicenseNewKey
      ? this.formData.deviceLicenseNewKey
      : this.props.licenseData['license-type'].indexOf('FLEXPOOL') > -1
      ? this.props.licenseData['entitlement-key']
      : ''
    let devLicBW: any = this.formData.deviceLicenseNewKey
      ? this.formData.newBandwidth
      : this.formData.numBandwidth
    const devLicBWU = this.formData.deviceLicenseNewKey
      ? this.formData.newBandwidthUnit
      : this.formData.numBandwidthUnit
    const bandwidthObj = this.Utilities.megaToGiga(
      this.state.allotedBandwidth || 0,
    )

    if (
      devLicKey &&
      (devLicKey !== this.props.licenseData['entitlement-key'] ||
        bandwidthObj.val !== devLicBW ||
        bandwidthObj.unit !== devLicBWU)
    ) {
      const url = `/hls/licenses/providers/${storage.get.PROVIDER}/change/device/${this.selectedDevice['device-id']}/DEVICE_LICENSE/newkey/${devLicKey}/bandwidth/${devLicBW}`
      try {
        const { data } = await httpClient.post(url, null, {
          absoluteBasePath: true,
        })
        if (data || true) {
          // this.formData.deviceLicenseNewKey = ''
          this.setDefaultValues()
          this.props.refreshData()
          this.Utilities.showMessage('DEVICE_LICENSE_CHANGE_SUCCESS', 1, 1)
        }
        this.closeImageSlidingPage()
      } catch (err) {
        this.setDefaultValues()
        this.props.refreshData()
        this.closeImageSlidingPage()
        const msg =
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : ''
        this.Utilities.showMessage('DEVICE_LICENSE_CHANGE_FAIL', 0, 1, msg)
      }
    } else {
      if (nothingUpdated) {
        this.Utilities.showMessage('No changes done to update', 2, 0)
      } else {
        this.props.refreshData()
      }
      this.setDefaultValues()
      this.closeImageSlidingPage()
    }
  }

  setDefaultValues() {
    this.formData = {
      hcLicenseNewKey: '',
      deviceLicenseNewKey: '',
      newBandwidth: '',
      newBandwidthUnit: '',
      numBandwidth: '',
      numBandwidthUnit: '',
    }
    this.selectedDevice = {}
  }

  onFormChange = (args: any) => {
    this.formData = args
  }

  editLicence = (device: any) => {
    this.selectedDevice = device
    const state = { ...this.state }
    state.showSlidingPage = true
    state.slidingForm = 'edit'
    state.allotedBandwidth = device['alloted-bandwidth']
    state.activeLicenses = [true, true]
    if (this.props.licenseData['license-type'].indexOf('FLEXPOOL') > -1) {
      const bandwidthObj = this.Utilities.megaToGiga(
        state.allotedBandwidth || 0,
      )
      this.formData.numBandwidth = bandwidthObj.val
      this.formData.numBandwidthUnit = bandwidthObj.unit
      this.formData.newBandwidth = bandwidthObj.val
      this.formData.newBandwidthUnit = bandwidthObj.unit
      this.formData.deviceLicenseNewKey = this.props.licenseData[
        'entitlement-key'
      ]
    }
    this.editTitle = 'Update License'
    this.editDesc = 'Select license key'

    this.setState(state)
  }

  onBulkUpdateChange = (devices: any) => {
    this.setState({ bulkDevices: devices })
  }

  bulkDeviceLicenseForm = () => {
    this.editTitle = 'Associate Devices to License'
    this.editDesc = ''
    const bulkDevices: string[] = []
    this.oldDevices = []
    const deviceList = this?.props?.licenseData?.['licensed-device-list']
    if (Array.isArray(deviceList)) {
      deviceList.map(device => {
        if (!!device['device-id']) {
          bulkDevices.push(device['device-id'])
          this.oldDevices.push(device['device-id'])
        }
      })
    }
    this.setState({
      bulkDevices,
      slidingForm: 'bulkUpdate',
      showSlidingPage: true,
    })
  }

  bulkUpdateDevices = () => {
    const { bulkDevices } = this.state,
      payloadDevice: string[] = []

    bulkDevices.map((dev: string) => {
      if (this.oldDevices.indexOf(dev) === -1) {
        payloadDevice.push(dev)
      }
    })

    if (payloadDevice.length === 0) {
      this.Utilities.showMessage(
        'Please select ' +
          (this.oldDevices.length > 0 ? 'new ' : '') +
          'devices to update',
        0,
        0,
      )
      return
    }

    this.setState({ bulkModalState: true })
  }

  handleBulkOk = async () => {
    const { bulkDevices } = this.state,
      payloadDevice: string[] = []
    const {
      EPIC_DEPENDENCIES: { httpClient },
    } = globalConfig
    this.setState({
      disableSave: true,
      bulkModalState: false,
    })
    A10Message.loading('Updating...', 2000)

    bulkDevices.map((dev: string) => {
      if (this.oldDevices.indexOf(dev) === -1) {
        payloadDevice.push(dev)
      }
    })

    const url = `/hls/licenses/providers/${storage.get.PROVIDER}/entitlementkey/${this.props.licenseData['entitlement-key']}/bulkupdate`
    try {
      const { data } = await httpClient.post(url, payloadDevice, {
        absoluteBasePath: true,
      })
      if (data || true) {
        this.Utilities.showMessage('BULK_LICENSE_CHANGE_SUCCESS', 1, 1)
      }
      this.setState({
        deleteModalState: false,
        loadingIcon: false,
      })
      this.props.refreshData()
      this.closeImageSlidingPage()
    } catch (err) {
      const msg =
        err && err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : ''
      this.Utilities.showMessage('BULK_LICENSE_CHANGE_FAIL', 0, 1, msg)
    }
    this.setState({
      disableSave: false
    })
  }

  handleOk = async (e: React.MouseEvent<any>) => {
    this.setState({
      loadingIcon: true,
    })
    try {
      const url = `/hls/licenses/providers/${storage.get.PROVIDER}/delete/device/${this.selectedDevice['device-id']}/DEVICE_LICENSE`
      const { data } = await httpClient.post(url, null, {
        absoluteBasePath: true,
      })

      this.setState({
        deleteModalState: false,
        bulkModalState: false,
        loadingIcon: false,
      })
      this.props.refreshData()
      this.selectedDevice = {}
      this.Utilities.showMessage(
        'Deletion request submitted successfully, please check the status in activity log for the license',
        1,
        0,
      )
    } catch (error) {
      // do nothing
      this.setState({
        loadingIcon: false,
      })
      const msg =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : ''
      this.Utilities.showMessage(
        'Error: Unable to remove the license - Please consult with License Administrator of your organization about the license',
        0,
        0,
        msg,
      )
    }
  }

  handleCancel = (e: React.MouseEvent<any>) => {
    if (this.state.loadingIcon) {
      return false
    }
    this.setState({ deleteModalState: false, bulkModalState: false })
    return true
  }

  shouldComponentUpdate = (nextProps: any, nextState: any) => {
    if (nextProps.isUpdateAuditLog !== this.props.isUpdateAuditLog) {
      this.getActivityLogs()
    }
    return true
  }

  onChange = (e: any) => {
    if (e?.target?.value) {
      this.setState({ tab: e.target.value })
    }
  }

  renderTab = () => {
    const { licenseData } = this.props
    const { tab, isLoading, activityLog } = this.state
    const entitlements = licenseData?.entitlements
    const deviceManagement = entitlements?.['device-management']
    const metadata = entitlements?.metadata
    const userInformation = entitlements?.user
    const services = entitlements?.services
    
    let licTypeValue = licenseData['license-type']
    if (licTypeValue.indexOf('FLEXPOOL') > -1) {
      licTypeValue = this.props.glmLicTypeText(
        licenseData['glm-lic-type'],
        licenseData['license-type'],
      )
    } else if (licTypeValue === 'MBU') {
      licTypeValue = 'Managed Bandwidth Units'
    } else if (licTypeValue === 'MDU') {
      licTypeValue = 'Managed Devices'
    }
    const bandwidth = this.Utilities.megaToGiga(
      licenseData['alloted-bandwidth'] || 0,
    )
    const remBandwidth = this.Utilities.megaToGiga(
      licenseData['remaining-bandwidth'] || 0,
    )
    const defaultBW = this.Utilities.megaToGiga(
      licenseData && licenseData['default-allocated-bandwidth']
        ? licenseData['default-allocated-bandwidth']
        : 0,
    )
    const minBW = this.Utilities.megaToGiga(
      licenseData && licenseData['min-allocated-bandwidth']
        ? licenseData['min-allocated-bandwidth']
        : 0,
    )
    const maxBW = this.Utilities.megaToGiga(
      licenseData && licenseData['max-allocated-bandwidth']
        ? licenseData['max-allocated-bandwidth']
        : 0,
    )
    const dateFormat = moment
      .utc(licenseData['expires-at'])
      .format('MMM DD, YYYY hh:mm A')
    const totalLogRate = this.Utilities.getEntitlementsValue(deviceManagement?.['session-log']?.['total-log-rate'])
    switch (tab) {
      case 'licenseInformation':
      default: {
        return (
          <A10Row type="flex" className="tab-information">
            <A10Col span={12} className="license-information">
              <div className="row lic-detail-row pad-v-5">
                <div className="col-md-5 title">
                  {this.Messages.ENTITLEMENT_TOKEN}
                </div>
                <div className="lic-detail-value pad-s-15">
                  {licenseData['entitlement-key']}
                </div>
              </div>
              {licenseData['license-type'].indexOf('FLEXPOOL') > -1 ? (
                <div>
                  {licenseData['license-platform'] ? (
                    <div className="row lic-detail-row pad-v-5">
                      <div className="col-md-5 title">Platform</div>
                      <div className="lic-detail-value pad-s-15">
                        {licenseData['license-platform']}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div className="row lic-detail-row pad-v-5">
                <div className="col-md-5 title">Product</div>
                <div className="lic-detail-value pad-s-15">{licTypeValue}</div>
              </div>
              <div className="row lic-detail-row pad-v-5">
                <div className="col-md-5 title">
                  {licenseData['activation-status'] === 'ACTIVATED' ||
                  licenseData['activation-status'] === 'EXPIRING' ||
                  licenseData['activation-status'] === 'EXPIRED' ||
                  licenseData['activation-status'] === 'GRACE-PERIOD' ||
                  licenseData['activation-status'] === 'REVOKED'
                    ? 'Start'
                    : 'Requested'}{' '}
                  Date
                </div>
                <div className="lic-detail-value pad-s-15">
                  {licenseData['created-at']}
                </div>
              </div>
              <div className="row lic-detail-row pad-v-5">
                <div className="col-md-5 title">State</div>
                <div
                  className={
                    licenseData['activation-status'] === 'EXPIRING'
                      ? 'lic-detail-value pad-s-15 lic-warning'
                      : licenseData['activation-status'] === 'EXPIRED' ||
                        licenseData['activation-status'] === 'GRACE-PERIOD' ||
                        licenseData['activation-status'] === 'REVOKED'
                      ? 'lic-detail-value pad-s-15 lic-danger'
                      : 'lic-detail-value pad-s-15'
                  }
                >
                  {`${licenseData['activation-status']} ${
                    licenseData['lm-mode'] &&
                    licenseData['lm-mode'] === 'OFFLINE'
                      ? '(OFFLINE)'
                      : ''
                  }`}
                </div>
              </div>
              {licenseData['activation-status'] === 'ACTIVATED' ||
              licenseData['activation-status'] === 'EXPIRING' ||
              licenseData['activation-status'] === 'EXPIRED' ||
              licenseData['activation-status'] === 'GRACE-PERIOD' ||
              licenseData['activation-status'] === 'REVOKED' ? (
                <>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">
                      {licenseData['activation-status'] === 'EXPIRED'
                        ? 'Expired'
                        : 'Expires'}
                    </div>
                    <div
                      className={
                        licenseData['activation-status'] === 'EXPIRING'
                          ? 'lic-detail-value pad-s-15 lic-warning'
                          : licenseData['activation-status'] === 'EXPIRED' ||
                            licenseData['activation-status'] === 'GRACE-PERIOD'
                          ? 'lic-detail-value pad-s-15 lic-danger'
                          : 'lic-detail-value pad-s-15'
                      }
                    >
                      {licenseData['expires-at'] &&
                      licenseData['expires-at'] === '2050-12-31 00:00:00'
                        ? 'Never'
                        : dateFormat}
                    </div>
                  </div>
                </>
              ) : null}

              {licenseData['activation-status'] === 'FAILED' ||
              licenseData['activation-status'] === 'EXPIRING' ||
              licenseData['activation-status'] === 'EXPIRED' ||
              licenseData['activation-status'] === 'GRACE-PERIOD' ||
              licenseData['activation-status'] === 'REVOKED' ? (
                <>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">
                      {licenseData['activation-status'] === 'FAILED'
                        ? 'Activation Error'
                        : licenseData['activation-status'] === 'EXPIRING' ||
                          licenseData['activation-status'] === 'EXPIRED' ||
                          licenseData['activation-status'] === 'GRACE-PERIOD' ||
                          licenseData['activation-status'] === 'REVOKED'
                        ? 'License Error'
                        : ''}
                    </div>
                    <div
                      className={
                        licenseData['activation-status'] === 'EXPIRING'
                          ? 'col-md-7 pad-s-15 lic-warning line-2-ellipsis'
                          : licenseData['activation-status'] === 'EXPIRED' ||
                            licenseData['activation-status'] ===
                              'GRACE-PERIOD' ||
                            licenseData['activation-status'] === 'REVOKED'
                          ? 'col-md-7 pad-s-15 lic-danger line-2-ellipsis'
                          : 'col-md-7 pad-s-15 line-2-ellipsis'
                      }
                      style={{ WebkitBoxOrient: 'vertical' }}
                      title={licenseData.description}
                    >
                      {licenseData.description}
                    </div>
                  </div>
                </>
              ) : null}

              {licenseData['license-type'] === 'MBU' ||
              licenseData['license-type'] === 'MDU' ||
              licenseData['license-type'] === 'Trial' ? (
                <>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">Managed Bandwidth</div>
                    <div className="lic-detail-value pad-s-15">
                      {licenseData['alloted-bandwidth'] === 0
                        ? 'Unlimited'
                        : bandwidth.val +
                          (bandwidth.unit === 'm' ? ' Mbps' : ' Gbps')}
                    </div>
                  </div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">Managed Devices</div>
                    <div className="lic-detail-value pad-s-15">
                      {licenseData['alloted-devices'] === 0
                        ? 'Unlimited'
                        : licenseData['alloted-devices']}
                    </div>
                  </div>
                </>
              ) : licenseData['license-type'] === 'MPU' ? (
                <div className="row lic-detail-row pad-v-5">
                  <div className="col-md-5 title">Managed Processing Unit</div>
                  <div className="lic-detail-value pad-s-15">
                    {
                      (licenseData['alloted-process'] = 0
                        ? 'Unlimited'
                        : licenseData['alloted-process'])
                    }
                  </div>
                </div>
              ) : null}

              {licenseData['license-type'].indexOf('FLEXPOOL') > -1 ? (
                <>
                  {licenseData['license-product'] ? (
                    <div className="row lic-detail-row pad-v-5">
                      <div className="col-md-5 title">Product</div>
                      <div className="lic-detail-value pad-s-15">
                        {licenseData['license-product']}
                      </div>
                    </div>
                  ) : null}

                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">Capacity</div>
                    <div className="lic-detail-value pad-s-15">
                      <span>
                        Bandwidth (Alloted / Remaining) :{' '}
                        {bandwidth.val == 0
                          ? 'na'
                          : bandwidth.val +
                            (bandwidth.unit === 'm' ? ' Mbps' : ' Gbps')}
                        {' / '}
                        {remBandwidth.val == 0
                          ? 'na'
                          : remBandwidth.val +
                            (remBandwidth.unit === 'm' ? ' Mbps' : ' Gbps')}
                        &nbsp;&nbsp;
                      </span>
                      <br />
                      <span>
                        Devices (Alloted / Used) :{' '}
                        {licenseData['alloted-devices'] == 0
                          ? licenseData['license-type'] ===
                            'SUBSCRIBER_FLEXPOOL'
                            ? 'Unlimited'
                            : 'na'
                          : licenseData['alloted-devices']}
                        {' / '}
                        {licenseData['used-devices'] == 0
                          ? 'na'
                          : licenseData['used-devices']}{' '}
                      </span>
                      <br />
                      <span>
                        Subscribers :
                        {licenseData['subscriber-count'] &&
                        licenseData['subscriber-count'] != 0
                          ? licenseData['subscriber-count']
                          : ' ' + 'na'}
                      </span>
                    </div>
                  </div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">Bandwidth Allocation</div>
                    <div className="lic-detail-value pad-s-15">
                      <span>
                        Default: {defaultBW.val}{' '}
                        {defaultBW.unit === 'm' ? 'Mbps' : 'Gbps'} &nbsp;&nbsp;
                      </span>
                      <span>
                        Min: {minBW.val} {minBW.unit === 'm' ? 'Mbps' : 'Gbps'}{' '}
                        &nbsp;&nbsp;
                      </span>
                      <span>
                        Max: {maxBW.val} {maxBW.unit === 'm' ? 'Mbps' : 'Gbps'}
                      </span>
                    </div>
                  </div>
                </>
              ) : null}

              {(licenseData['activation-status'] === 'ACTIVATED' ||
                licenseData['activation-status'] === 'EXPIRING' ||
                licenseData['activation-status'] === 'EXPIRED' ||
                licenseData['activation-status'] === 'GRACE-PERIOD' ||
                licenseData['activation-status'] === 'REVOKED') &&
              licenseData['billing-serials'] &&
              licenseData['billing-serials'].length ? (
                <>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">Billing Serial Numbers</div>
                    <div className="lic-detail-value pad-s-15">
                      {licenseData['billing-serials'].toString()}
                    </div>
                  </div>
                </>
              ) : null}
              {deviceManagement && userInformation && (
                <>
                  <div className='row lic-detail-row pad-v-10 title' style={{ paddingLeft: '14px' }}>ENTITLEMENTS:</div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">Plan</div>
                    <div className="lic-detail-value pad-s-15">
                      {metadata?.plan || ''}
                    </div>
                  </div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">No of Users</div>
                    <div className="lic-detail-value pad-s-15">
                      {this.Utilities.getEntitlementsValue(userInformation?.count)}
                    </div>
                  </div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">SSO</div>
                    <div className="lic-detail-value pad-s-15">{`${userInformation?.SSO}`}</div>
                  </div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">No of Device Backups</div>
                    <div className="lic-detail-value pad-s-15">
                      {this.Utilities.getEntitlementsValue(deviceManagement?.backup?.number)}
                    </div>
                  </div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">Periodic Backups</div>
                    <div className="lic-detail-value pad-s-15">
                      {deviceManagement?.backup?.periodic
                        ? 'Allowed'
                        : 'Not Allowed'}
                    </div>
                  </div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">No of CLI Snippets</div>
                    <div className="lic-detail-value pad-s-15">
                      {deviceManagement?.['cli-snippets']?.number}
                    </div>
                  </div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">Periodic Snippets</div>
                    <div className="lic-detail-value pad-s-15">
                      {deviceManagement?.['cli-snippets']?.periodic
                        ? 'Allowed'
                        : 'Not Allowed'}
                    </div>
                  </div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">Total Log Rate</div>
                    <div className="lic-detail-value pad-s-15">
                    { Number(totalLogRate) > 0 ? `${totalLogRate}/Sec` : totalLogRate }
                    </div>
                  </div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">No of Alerts</div>
                    <div className="lic-detail-value pad-s-15">
                      {this.Utilities.getEntitlementsValue(deviceManagement?.alerts)}
                    </div>
                  </div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">ADC</div>
                    <div className="lic-detail-value pad-s-15">
                      {services?.ADC?.enabled ? 'Enabled' : 'Disabled'}
                    </div>
                  </div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">CGN</div>
                    <div className="lic-detail-value pad-s-15">
                      {services?.CGN?.enabled ? 'Enabled' : 'Disabled'}
                    </div>
                  </div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">GTP Firewall</div>
                    <div className="lic-detail-value pad-s-15">
                      {services?.GTPFW?.enabled ? 'Enabled' : 'Disabled'}
                    </div>
                  </div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">Gi Firewall</div>
                    <div className="lic-detail-value pad-s-15">
                      {services?.GiFW?.enabled ? 'Enabled' : 'Disabled'}
                    </div>
                  </div>
                  <div className="row lic-detail-row pad-v-5">
                    <div className="col-md-5 title">SSLi</div>
                    <div className="lic-detail-value pad-s-15">
                      {services?.SSLi?.enabled ? 'Enabled' : 'Disabled'}
                    </div>
                  </div>
                </>
              )}
            </A10Col>
            <A10Col span={12}>
              {licenseData['activation-status'] === 'ACTIVATED' ||
              licenseData['activation-status'] === 'EXPIRING' ||
              licenseData['activation-status'] === 'EXPIRED' ||
              licenseData['activation-status'] === 'GRACE-PERIOD' ||
              licenseData['activation-status'] === 'REVOKED' ? (
                <LicenseCharts
                  licenseData={licenseData}
                  refreshData={this.props.refreshData}
                />
              ) : null}
            </A10Col>
          </A10Row>
        )
      }
      case 'associatedDeivce': {
        return licenseData['activation-status'] === 'ACTIVATED' ||
          licenseData['activation-status'] === 'EXPIRING' ||
          licenseData['activation-status'] === 'EXPIRED' ||
          licenseData['activation-status'] === 'GRACE-PERIOD' ||
          licenseData['activation-status'] === 'REVOKED' ? (
          <div className="col-md-12 tab-information">
            <A10Row type="flex" align="middle" className="action-container">
              <A10Col lg={18} />
              <A10Col lg={6}>
                {!this.isOperatorUser &&
                (licenseData['activation-status'] === 'ACTIVATED' ||
                  (licenseData['license-platform'] === 'Harmony Controller' &&
                    licenseData['activation-status'] === 'GRACE-PERIOD') ||
                  licenseData['activation-status'] === 'EXPIRING') ? (
                  <ActionButton
                    text="Associate Devices"
                    onClick={this.bulkDeviceLicenseForm}
                    iconProps={{ app: 'global', type: 'add-new' }}
                  />
                ) : null}
              </A10Col>
            </A10Row>
            <A10Table
              style={{ marginTop: '0.2em' }}
              columns={this.thundersColumns}
              dataSource={
                licenseData['licensed-device-list'] &&
                licenseData['licensed-device-list'].map(
                  (item: IObject, index: number) => {
                    item.key = 'Device' + index
                    return item
                  },
                )
              }
              size="small"
              loading={
                isLoading
                  ? {
                      indicator: (
                        <div>
                          <ReactLoading
                            type="bars"
                            color="#4a90e2"
                            height={40}
                            width={40}
                          />
                        </div>
                      ),
                    }
                  : false
              }
              pagination={{ hideOnSinglePage: true, pageSize: 5 }}
            />
          </div>
        ) : null
      }
      case 'activityLogs': {
        return (
          <A10Table
            className="tab-information"
            columns={this.licenseActivityColumns}
            dataSource={activityLog.map((item: IObject, index: number) => {
              item.key = 'Activity' + index
              return item
            })}
            size="small"
            loading={
              isLoading
                ? {
                    indicator: (
                      <div>
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
            pagination={{ hideOnSinglePage: true, pageSize: 5 }}
          />
        )
      }
    }
  }

  render() {
    const { licenseData } = this.props
    const { activityLog, showSlidingPage, allotedBandwidth, devicesList, disableSave } = this.state

    return (
      <div>
        <A10Row className={styles.tabsContainer}>
          <A10Radio.Group
            defaultValue="licenseInformation"
            buttonStyle="tab"
            onChange={this.onChange}
          >
            <A10Radio.Button value="licenseInformation">
              <div className="tab">License Information</div>
            </A10Radio.Button>
            <A10Radio.Button value="associatedDeivce">
              <div className="tab">
                Associated Device
                <A10Badge
                  count={licenseData?.['licensed-device-list']?.length || 0}
                />
              </div>
            </A10Radio.Button>
            <A10Radio.Button value="activityLogs">
              <div className="tab">
                Activity Logs
                <A10Badge count={activityLog?.length || 0} />
              </div>
            </A10Radio.Button>
          </A10Radio.Group>
        </A10Row>
        <A10Row>{this.renderTab()}</A10Row>

        <FormatSlidingPage
          isOpen={showSlidingPage}
          onRequestOk={this.handleFormValidation}
          onRequestClose={this.closeImageSlidingPage}
          title={this.editTitle}
          saveText="Update"
          disableSave={
            this.state.slidingForm === 'bulkUpdate' &&
            (this.state.bulkDevices.length - this.oldDevices.length === 0 || disableSave)
          }
          description={this.editDesc}
        >
          {this.state.slidingForm === 'edit' ? (
            <ChangeDeviceForm
              onChange={this.onFormChange}
              hcLicenseKey={
                licenseData['license-type'].indexOf('FLEXPOOL') > -1
                  ? null
                  : licenseData['entitlement-key']
              }
              deviceLicenseKey={
                licenseData['license-type'].indexOf('FLEXPOOL') > -1
                  ? licenseData['entitlement-key']
                  : null
              }
              allotedBandwidth={allotedBandwidth}
              activeLicenses={this.state.activeLicenses}
              onSubmitForm={() => {
                this.addLicense()
              }}
              onRef={(ref: any): any => (this.childForm = ref)}
              selectedDevice={this.selectedDevice}
            />
          ) : this.state.slidingForm === 'bulkUpdate' ? (
            <BulkUpdateForm
              onChange={this.onBulkUpdateChange}
              licenseInfo={licenseData}
              devicesList={devicesList}
              onSubmitForm={() => {
                this.bulkUpdateDevices()
              }}
              onRef={(ref: any): any => (this.childForm = ref)}
              glmLicTypeText={this.props.glmLicTypeText}
            />
          ) : null}
        </FormatSlidingPage>

        <A10Modal
          title="Remove device from license"
          visible={this.state.deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={this.state.loadingIcon}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
              disabled={this.state.loadingIcon}
            >
              Yes
            </A10Button>,
          ]}
        >
          <>
            <p>
              You are deleting License from device -{' '}
              {this.selectedDevice ? this.selectedDevice['device-name'] : ''}.
              Do you want to continue to remove the license?
            </p>
            {this.state.loadingIcon ? (
              <div className="">
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </div>
            ) : null}
          </>
        </A10Modal>

        <A10Modal
          wrapClassName="modal-on-form"
          title="Bulk device license update"
          visible={this.state.bulkModalState}
          onOk={this.handleBulkOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={this.state.loadingIcon}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleBulkOk}
              className="yesbtn"
              disabled={this.state.loadingIcon}
            >
              Yes
            </A10Button>,
          ]}
        >
          <>
            <p>
              The existing license will be removed from the device.
              <br />
              Do you want to continue to remove the license and update with new?
            </p>
            {this.state.loadingIcon ? (
              <div className="">
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </div>
            ) : null}
          </>
        </A10Modal>
      </div>
    )
  }
}

export default setupA10Container(LicenseDetails)
