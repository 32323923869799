import { _ } from '@gui-libraries/framework'

const mappings = {
  tcp: [
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'tcp',
    'virtual-port',
  ],
  udp: [
    'dns',
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    // 'persist-ssl-sid',
    'policy',
    'scaleout',
    'udp',
    'virtual-port',
  ],
  others: [
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'tcp',
    'udp',
    'virtual-port',
  ],
  diameter: [
    'diameter',
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'scaleout',
    'tcp-proxy',
    'virtual-port',
  ],
  'dns-tcp': [
    'dns',
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'tcp-proxy',
    'virtual-port',
  ],
  'dns-udp': [
    'dns',
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'udp',
    'virtual-port',
  ],
  'fast-http': [
    'connection-reuse',
    'dynamic-service',
    'http',
    'persist-cookie',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'tcp',
    'virtual-port',
  ],
  fix: ['dynamic-service', 'fix', 'scaleout', 'tcp-proxy', 'virtual-port'],
  ftp: [
    'dynamic-service',
    'ftp',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'tcp',
    'virtual-port',
  ],
  'ftp-proxy': [
    'client-ssl',
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'tcp-proxy',
    'virtual-port',
  ],
  http: [
    'cache',
    'client-ssl',
    'connection-reuse',
    'dynamic-service',
    'external-service',
    'file-inspection',
    'http',
    'http-policy',
    'persist-cookie',
    'persist-source-ip',
    'persist-destination-ip',
    // 'persist-ssl-sid',
    'policy',
    'reqmod-icap',
    'respmod-icap',
    'scaleout',
    'server-ssl',
    'tcp-proxy',
    'virtual-port',
    'waf',
  ],
  https: [
    'cache',
    'client-ssl',
    'connection-reuse',
    'dynamic-service',
    'external-service',
    'file-inspection',
    'http',
    'http-policy',
    'persist-cookie',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'reqmod-icap',
    'respmod-icap',
    'scaleout',
    'server-ssl',
    'tcp-proxy',
    'virtual-port',
    'waf',
  ],
  http2: [
    'client-ssl',
    'connection-reuse',
    'dynamic-service',
    'file-inspection',
    'http',
    'http-policy',
    'persist-cookie',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'reqmod-icap',
    'respmod-icap',
    'scaleout',
    'server-ssl',
    'tcp',
    'tcp-proxy',
    'udp',
    'virtual-port',
  ],
  http2s: [
    'client-ssl',
    'connection-reuse',
    'dynamic-service',
    'file-inspection',
    'http',
    'http-policy',
    'persist-cookie',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'server-ssl',
    'tcp',
    'tcp-proxy',
    'udp',
    'virtual-port',
  ],
  imap: [
    'client-ssl',
    'dynamic-service',
    'imap-pop3',
    'persist-cookie',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'tcp-proxy',
    'virtual-port',
  ],
  mlb: [
    'connection-reuse',
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'tcp-proxy',
    'virtual-port',
  ],
  mms: [
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'tcp',
    'virtual-port',
  ],
  mysql: [
    'client-ssl',
    'dblb',
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'scaleout',
    'server-ssl',
    'tcp-proxy',
    'virtual-port',
  ],
  mssql: [
    'client-ssl',
    'dblb',
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'scaleout',
    'server-ssl',
    'tcp-proxy',
    'virtual-port',
  ],
  pop3: [
    'client-ssl',
    'dynamic-service',
    'imap-pop3',
    'persist-cookie',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'tcp-proxy',
    'virtual-port',
  ],
  radius: [
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'udp',
    'virtual-port',
  ],
  rtsp: [
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'tcp',
    'virtual-port',
  ],
  sip: [
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'sip',
    'udp',
    'virtual-port',
  ],
  'sip-tcp': [
    'connection-reuse',
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'server-ssl',
    'sip',
    'tcp-proxy',
    'virtual-port',
  ],
  sips: [
    'client-ssl',
    'connection-reuse',
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'server-ssl',
    'sip',
    'tcp-proxy',
    'virtual-port',
  ],
  'smpp-tcp': [
    'connection-reuse',
    'dynamic-service',
    'persist-cookie',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'smpp',
    'tcp-proxy',
    'virtual-port',
  ],
  spdy: [
    'cache',
    'client-ssl',
    'connection-reuse',
    'dynamic-service',
    'external-service',
    'file-inspection',
    'http',
    'http-policy',
    'persist-cookie',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'reqmod-icap',
    'respmod-icap',
    'scaleout',
    'server-ssl',
    'tcp-proxy',
    'virtual-port',
  ],
  spdys: [
    'cache',
    'client-ssl',
    'connection-reuse',
    'dynamic-service',
    'external-service',
    'file-inspection',
    'http',
    'http-policy',
    'persist-cookie',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'reqmod-icap',
    'respmod-icap',
    'scaleout',
    'server-ssl',
    'tcp-proxy',
    'virtual-port',
  ],
  smtp: [
    'client-ssl',
    'dynamic-service',
    'file-inspection',
    'imap-pop3',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'server-ssl',
    'smtp',
    'tcp-proxy',
    'virtual-port',
  ],
  'ssl-proxy': [
    'client-ssl',
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'server-ssl',
    'tcp-proxy',
    'virtual-port',
  ],
  ssli: [
    'cache',
    'client-ssl',
    'connection-reuse',
    'dblb',
    'diameter',
    'dns',
    'dynamic-service',
    'external-service',
    'file-inspection',
    'ftp',
    'http',
    'http-policy',
    'imap-pop3',
    'persist-cookie',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'reqmod-icap',
    'respmod-icap',
    'scaleout',
    'server-ssl',
    'sip',
    'smtp',
    'ssli',
    'tcp-proxy',
    'virtual-port',
    'waf',
  ],
  ssh: [
    'client-ssh',
    'dynamic-service',
    'http-policy',
    'imap-pop3',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'tcp-proxy',
    'virtual-port',
  ],
  'tcp-proxy': [
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'policy',
    'scaleout',
    'server-ssh',
    'server-ssl',
    'tcp-proxy',
    'virtual-port',
  ],
  tftp: [
    'dynamic-service',
    'persist-source-ip',
    'persist-destination-ip',
    'persist-ssl-sid',
    'scaleout',
    'udp',
    'virtual-port',
  ],
  'fast-fix': [
    'cache',
    'client-ssl',
    'dynamic-service',
    'file-inspection',
    'fix',
    'http',
    'http-policy',
    'scaleout',
    'server-ssl',
    'tcp',
    'tcp-proxy',
    'udp',
    'virtual-port',
    'waf',
  ],
}

export const protocolMappings = mappings

const revertMappings = {}
_.forEach(mappings, (value: IObject, key: string) => {
  value.map((templateName: string) => {
    const protocols = revertMappings[templateName] || []
    protocols.push(key)
    revertMappings[templateName] = protocols
  })
})

export const templateMappings = revertMappings

export default protocolMappings
