import React from 'react'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Row, A10Col, A10Chart } from '@gui-libraries/widgets'
import { ApplicationConfigs } from 'src/constants/ApplicationConfigs/index'
import { DashboardService, Utilities } from 'src/services'
const styles = require('./styles/index.module.less')

export interface ILMLicenseUsageChartsProps extends IA10ContainerDefaultProps {
  licenseData?: IObject
  licenseUsage: IObject
}
export interface ILMLicenseUsageChartsState {
  licenseUsage: IObject
  chartConfig: IObject
  counters: any
}

class LMLicenseUsageCharts extends A10Container<
  ILMLicenseUsageChartsProps,
  ILMLicenseUsageChartsState
> {
  ApplicationConfigs = new ApplicationConfigs()
  DashboardService = new DashboardService()
  Utilities = new Utilities()

  columnConfig: IObject = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'BANDWIDTH USAGE',
    },
    xAxis: {
      title: {
        enabled: false,
      },
      type: 'datetime',
      offset: 0,
      lineWidth: 0,
      tickWidth: 0,
    },
    yAxis: {
      title: {
        enabled: false,
      },
      labels: {},
    },
    tooltip: {
      headerFormat: '<b>{point.x:%Y-%m-%d %H:%M:%S}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
        pointPadding: 0,
        groupPadding: 0,
      },
    },
    series: [
      {
        name: 'Normal',
        color: '#a5d6a7',
        data: new Array(),
      },
      {
        name: 'Overage',
        color: '#ffcc80',
        data: new Array(),
      },
    ],
  }

  timeRange = {
    start: Date.now(),
    end: Date.now(),
  }

  constructor(props: ILMLicenseUsageChartsProps) {
    super(props)
    this.state = {
      licenseUsage: this.props.licenseUsage,
      chartConfig: null,
      counters: {
        total: 0,
        filters: {},
      },
    }
    const that = this
    this.columnConfig.tooltip.pointFormatter = function() {
      const formatY = that.Utilities.truncateVal(this.y, 'bytes')
      const formatTotal = that.Utilities.truncateVal(this.stackTotal, 'bytes')
      return `${this.series.name}: ${formatY}bps<br/>Total: ${formatTotal}bps`
    }

    this.columnConfig.yAxis.labels.formatter = function() {
      const formatLabel = that.Utilities.truncateVal(this.value, 'bytes')
      return formatLabel + 'bps'
    }
  }

  onClickMenu = () => {}

  render() {
    const { licenseUsage } = this.props
    const chartConfig = _.cloneDeep(this.columnConfig)
    let normalUsage = 0,
      overage = 0
    if (licenseUsage && licenseUsage.length > 0) {
      licenseUsage.map((usage: any) => {
        normalUsage += usage.usage
        overage += usage.overage
        chartConfig.series[0].data.push([
          new Date(usage.window_start).getTime(),
          usage.usage,
        ])
        chartConfig.series[1].data.push([
          new Date(usage.window_start).getTime(),
          usage.overage,
        ])
      })
    }
    const counters = {
      total: normalUsage + overage,
      filters: {
        Noraml: normalUsage,
        Overage: overage,
      },
    }

    return (
      <div className={styles.licenseUsageChart}>
        <A10Row type="flex">
          <A10Col xs={24} sm={24} md={24} lg={24} className="panel">
            <A10Chart.Detail
              defaultExpandCollapsed={false}
              useSimpleHeader={false}
              mainTitle="BANDWIDTH USAGE"
              title="BANDWIDTH USAGE"
              description="BANDWIDTH USAGE"
              height={300}
              chartConfig={chartConfig}
              type="column"
              disableFilter={false}
              showSettingIcon={false}
              showChartIcons={false}
              counters={counters}
              types={['column']}
              maxFilterNumber={3}
              filtersize="default"
              onClickMenu={this.onClickMenu}
              settings=""
              groups={[]}
            />
          </A10Col>
        </A10Row>
      </div>
    )
  }
}

export default setupA10Container(LMLicenseUsageCharts)
