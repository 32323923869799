import { getNS, IHTTPRequestOptions } from '@gui-libraries/framework'
import { Map } from 'immutable'

import storage from 'src/libraries/storage'
import { httpClient } from 'src/libraries/httpClient'
import { OrganizationService } from 'src/services/index'
const HPCAPI_PREFIX = '/hpcapi/v3'

export const getRoleList = (search: string): IHTTPRequestOptions => {
  return {
    namespace: getNS('ROLE_LIST'),
    request: async () => {
      try {
        const queryParam: { [key: string]: string } = {}
        const api = `${HPCAPI_PREFIX}/provider/${storage.get.PROVIDER}/role`
        if (search !== '') {
          queryParam.name = encodeURIComponent(search)
        }
        const { data: res } = await httpClient.get(api, {
          params: {
            // detail: 'true',
            ...queryParam,
          },
        })
        const roleList = res['role-list'] || []
        return roleList
      } catch (err) {
        console.error(err)
      }
      return []
    },
  }
}

export const getAccessGroupUsersMap = () => {
  return {
    namespace: getNS('ACCESS_GROUP_USER_MAP'),
    request: async () => {
      const organizationService = new OrganizationService()
      const userResponse = organizationService.getUsers(
        null,
        null,
        storage.get.PROVIDER,
      )
      let map = Map<string, string[]>()
      const { data: respArr } = await userResponse
      if (respArr) {
        for (const user of respArr) {
          for (const role of user.roles) {
            const accessGroupName = Object.keys(role)[0]
            const userList = map.get(accessGroupName) || []
            userList.push(user['user-id'])
            map = map.set(accessGroupName, userList)
          }
        }
      }
      return map
    },
  }
}
