import { getNS, IHTTPRequestOptions } from '@gui-libraries/framework'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'

// const HPCAPI_PREFIX = '/hpcapi/v3'
const HOCAPI_PREFIX = '/hpcapi/v3'
const getProvider = () => storage.get.PROVIDER

export const getLogicalPartitionList = (
  tenant: string,
  search: string,
  editedObjName: string,
  page: number,
  pageSize: number,
): IHTTPRequestOptions => {
  return {
    namespace: getNS('LOGICAL_PARTITION_LIST'),
    request: async (epicDependence: IObject) => {
      // try {
      const provider = getProvider()
      const queryParam: { [key: string]: string } = {}
      const apiPrefix = `${HOCAPI_PREFIX}/provider/${provider}/tenant/${tenant}`
      const actionURL = apiPrefix + '/logical-partition'
      if (search !== '') {
        queryParam.name = encodeURIComponent(search)
      }
      const { data: Res } = await httpClient.get(actionURL, {
        params: {
          start: page,
          count: pageSize,
          ...queryParam,
        },
        saveUUIDDef: true,
      })
      const partitionList = Res['logical-partition-list'] || []
      await Promise.all(
        partitionList.map(async (item: IObject) => {
          const subscriberDeviceLabel = `LogicalPartition_${item.uuid}_devices`
          item.deviceList = []
          let isVcs = false
          if (item.deploy_target) {
            if (item.deploy_target?.target['device-list'].length > 0) {
              if (item.deploy_target.target['device-list'][0]['floating-ip']) {
                isVcs = true
              }
            }
          }
          try {
            if (isVcs) {
              const { data: res } = await httpClient.get(
                `/hpcapi/v3/provider/${provider}/device?cluster=${item.cluster}`,
              )
              item.deviceList = res['device-list'] || []
            } else {
              item.deviceList = item.deploy_target.target['device-list']
            }
          } catch (err) {
            item.deviceList = []
            console.error(err)
          }
          storage.setItem(subscriberDeviceLabel, item.deviceList, true)
        }),
      )
      return partitionList
      // } catch (err) {
      //   // tslint:disable-next-line:no-console
      //   console.error(err)
      // }
      return []
    },
  }
}

export const getLogicalPartitionCount = (
  tenant: string,
  search: string,
): IHTTPRequestOptions => {
  return {
    namespace: getNS('LOGICAL_PARTITION_COUNT'),
    request: async () => {
      try {
        const provider = getProvider()
        const queryParam: { [key: string]: string } = {}
        const apiPrefix = `${HOCAPI_PREFIX}/provider/${provider}/tenant/${tenant}`
        const actionURL = apiPrefix + '/logical-partition'
        if (search !== '') {
          queryParam.name = encodeURIComponent(search)
        }
        const { data: totalCount } = await httpClient.get(actionURL, {
          params: {
            total: 'true',
            ...queryParam,
          },
        })
        const count = totalCount['total-count'] || 0
        return count
      } catch (err) {
        console.error(err)
      }
      return 0
    },
  }
}
