import storage from 'src/libraries/storage'

export class AppRoot {
  state: any

  constructor() {
    this.state = {
      rootScope: {
        sessionAuthType: storage.get.USER_SESSION_AUTHTYPE,
        availableTenants: storage.get.AVAILABLETENANTS,
        readTenants: storage.get.READTENANTS,
        isSuperUser: storage.get.IS_SUPER_USER,
        isInfraUser: storage.get.IS_INFRA_USER,
        isCertUser: storage.get.IS_CERT_USER,
        helpTexts: storage.get.HELP_TEXTS,
        currentDrillLevel: storage.get.DRILL_LEVEL,
        allTenants: storage.get.ALLTENANTS,
        currentTenant: storage.get.CURRENT_TENANT,
        tenant: storage.get.TENANT,
        currentState: storage.get.CURRENT_STATE,
        appservices: storage.get.APP_SERVICES,
        allClusters: storage.get.ALLCLUSTERS,
        loadCredentials: storage.get.LADC_CREDENTIALS,
      },
    }
  }

  setRootScopeElement = (key: string, value: any) => {
    const rootScope = { ...this.state.rootScope }
    rootScope[key] = value
    this.state = {
      rootScope,
    }
  }
  getRootScopeElement = (key: string) => {
    return this.state.rootScope[key]
  }
  getRootScope = () => {
    return this.state.rootScope
  }
}

export default AppRoot
