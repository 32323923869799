import React from 'react'
import ReactLoading from 'react-loading'
import moment from 'moment'
import { A10Component, A10Context } from '@gui-libraries/framework'
import { A10Row, A10Col, A10Table, A10Button, A10Modal, A10Message } from '@gui-libraries/widgets'
import { OrganizationService } from 'src/services'
import { UserRole } from 'src/constants/Data/UserRole'
import { Messages } from 'src/locale/en/Messages'
import styles from './styles/index.module.less'
import { LoggedInUser } from 'src/libraries/loggedInUser'
import { NOT_APPLICABLE, QR_CODE_GENERATED, CONFIGURED } from 'src/constants/MFAStatus/MFAConstants'
export interface IUserProfileProps { }

export interface IUserProfileState {
  userProfile: IObject
  roleList: IObject[]
  accessGroupList: IObject[]
  dataSource: IObject[]
  loading: boolean
  visible: boolean
  loadingIconModal: boolean
}

const columns = [
  {
    title: 'Access Group',
    dataIndex: 'accessGroup',
    key: 'accessGroup',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'UI Type',
    dataIndex: 'type',
    key: 'type',
  },
]

class UserProfile extends A10Component<IUserProfileProps, IUserProfileState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  OrganizationService = new OrganizationService()
  UserRole = new UserRole()
  Messages = new Messages()
  LoggedInUser = new LoggedInUser()
  authType: string
  isSuperUser: boolean
  provider: string
  userId: string

  constructor(
    props: IUserProfileProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: {
        get: { USER_SESSION_AUTHTYPE, IS_SUPER_USER, PROVIDER, USER_ID },
      },
    } = context

    this.state = {
      userProfile: {},
      roleList: [],
      accessGroupList: [],
      dataSource: [],
      loading: false,
      visible: false,
      loadingIconModal: false,
    }

    this.authType = USER_SESSION_AUTHTYPE
    this.isSuperUser = IS_SUPER_USER
    this.provider = PROVIDER
    this.userId = USER_ID
  }

  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    try {
      this.setState({
        loading: true,
      })
      await this.getRoleList()
      await this.getAccessGroups()
      await this.loadProfileInfo()
    } catch (err) {
      console.error(err)
    } finally {
      this.setState({
        loading: false,
      })
    }
  }

  getRoleList = () => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const roleListResponse = this.OrganizationService.getRbacRoleList(
      null,
      null,
      [PROVIDER],
    )
    return roleListResponse
      .then((resp: IObject) => {
        const roleList = resp?.data || []
        this.setState({ roleList })
      })
      .catch(err => {
        console.log('rbac role load failed')
      })
  }

  getAccessGroups = () => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const accessGroupResponse = this.OrganizationService.getRbacAccessGroups(
      null,
      null,
      [PROVIDER],
    )
    return accessGroupResponse
      .then((resp: IObject) => {
        const accessGroupList = resp?.data || []
        this.setState({ accessGroupList })
      })
      .catch(err => {
        console.log('Access groups load failed')
      })
  }

  loadProfileInfo = () => {
    const {
      storage: {
        get: { USER_ID: userId },
      },
    } = this.context

    const profileResponse = this.OrganizationService.userProfile(
      { tenant: null },
      null,
      userId,
    )
    return profileResponse
      .then((response: IObject) => {
        const userProfile = response?.data || {}
        const { roles = [] } = userProfile

        const dataSource = roles.map((roleObj: IObject) => {
          const [accessGroup, role] = roleObj ? Object.entries(roleObj)[0] : []
          let type = ''
          if (
            accessGroup &&
            (accessGroup === 'superadmin' || accessGroup === 'superadmin-root')
          ) {
            type = 'Super Admin'
          } else if (accessGroup && role) {
            if (this.UserRole.RBAC_ROLE_TYPES.provideradmin.includes(role)) {
              type = 'Provider Admin'
            } else if (
              this.UserRole.RBAC_ROLE_TYPES.tenantadmin.includes(role)
            ) {
              type = 'Tenant Admin'
            }

            if (this.UserRole.RBAC_ROLE_TYPES.infraadmin.includes(role)) {
              type = 'Infra Admin'
            } else if (this.UserRole.RBAC_ROLE_TYPES.certadmin.includes(role)) {
              type = 'Certificate Admin'
            }
          }
          return {
            accessGroup: this.getAccessGroupDisplayName(accessGroup),
            role: this.getRoleDisplayName(role),
            type,
          }
        })

        this.setState({
          userProfile,
          dataSource,
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  // Method to get the display name of Access groups (prev. Roles)
  getAccessGroupDisplayName = (accessGroupName: string) => {
    const accessGroupList: any[] = this.state.accessGroupList
    const accessGroup =
      accessGroupList &&
      accessGroupList.find((obj: any) => {
        return obj.name === accessGroupName
      })
    return accessGroup
      ? accessGroup['display-name'] || accessGroup['displayName']
      : accessGroupName
  }

  // Method to get the display name of Role
  getRoleDisplayName = (roleName: string) => {
    const roleList: any[] = this.state.roleList
    const role =
      roleList &&
      roleList.find((obj: any) => {
        return obj.name === roleName
      })
    return role ? role['display-name'] || role['displayName'] : roleName
  }

  handleResetMfa = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async () => {
    const headers = {
      provider: this.provider
    }
    const payload = {
      userId: this.userId,
    }
    this.setState({
      loadingIconModal: true,
    })
    try {
      const { status: statusCode } = await this.OrganizationService.resetMFA(
        headers,
        payload,
        null
      )
      if (statusCode === 200) {
        const message = `MFA settings reset successfully for ${(this.userId)}`
        A10Message.success(message, 10, close)
        this.setState({
          visible: false,
        });
        this.LoggedInUser.logout();
      }
    } catch {
      A10Message.error('An error occurred while resetting MFA settings', 10, close)
      this.setState({
        visible: false,
        loadingIconModal: false,
      })
    }
  }

  handleCancel = () => {
    if (this.state.loadingIconModal) {
      return
    }
    this.setState({
      visible: false,
    });
  };

  render() {
    const { userProfile, dataSource, loading } = this.state
    const {
      'first-name': firstName = '',
      'last-name': lastName = '',
      'email-id': email = '',
      'user-id': id = '',
      'mfa-status': mfaStatus = '',
      state = '',
      'created-at': createdAt = '',
      'last-modified-at': lastModifiedAt = '',
    } = userProfile

    return (
      <div className={styles.panel}>
        <div className={styles.panelHeader}>
          <span>My Info</span>
        </div>
        <div className={styles.panelBody}>
          <A10Row gutter={[16, 0]}>
            <A10Col span={4}>{this.Messages.NAME}</A10Col>
            <A10Col span={20}>{`${firstName ? `${firstName} ` : ''
              }${lastName}`}</A10Col>
          </A10Row>
          <A10Row gutter={[16, 0]}>
            <A10Col span={4}>{this.Messages.EMAIL}</A10Col>
            <A10Col span={20}>{email}</A10Col>
          </A10Row>
          <A10Row gutter={[16, 0]}>
            <A10Col span={4}>{this.Messages.USER_ID}</A10Col>
            <A10Col span={20}>{id}</A10Col>
          </A10Row>
          <A10Row gutter={[16, 0]}>
            <A10Col span={4}>{this.Messages.USER_STATE}</A10Col>
            <A10Col span={20}>
              <div className={styles.userStatus}>
                <span
                  className={styles.firstUpperCase}
                >{`${state.toLowerCase()} since ${moment
                  .utc(createdAt)
                  .local()
                  .format('MMM DD, YYYY hh:mm A')}`}</span>
                <span
                  className={styles.firstUpperCase}
                >{`Last modified at ${moment
                  .utc(lastModifiedAt)
                  .local()
                  .format('MMM DD, YYYY hh:mm A')}`}</span>
              </div>
            </A10Col>
          </A10Row>
          <A10Row gutter={[16, 0]}>
            <A10Col span={4}>{this.Messages.ACCESS_LEVEL}</A10Col>
            <A10Col span={16}>
              <A10Table
                columns={columns}
                dataSource={dataSource}
                loading={loading}
              />
            </A10Col>
          </A10Row>
          <A10Row type="flex" justify="end">
            <A10Col span={4}>
              Password
            </A10Col>
            <A10Col span={20}>
              {(this.authType === 'default' || this.authType === 'local') && (
                <a href="/changePassword" className={styles.changePassword}>
                  Change Password
                </a>
              )}
            </A10Col>
          </A10Row>

          {!this.isSuperUser && (this.authType === 'default' || this.authType === 'local') && mfaStatus && mfaStatus !== NOT_APPLICABLE && ( // NOT_APPLICABLE = 'NA'
            <A10Row type="flex" justify="end">
              <A10Col span={4}>
                MFA
              </A10Col>
              <A10Col span={20} style={{ 'paddingTop': '0 px !important' }}>
                {mfaStatus === CONFIGURED ? ( // CONFIGURED = 'CONFIGURED'
                  <a href='#' className={styles.resetMfa} onClick={this.handleResetMfa}>
                    Reset MFA
                  </a>
                ) : <>{(mfaStatus === QR_CODE_GENERATED) ? 'PENDING' : mfaStatus}</>}
              </A10Col>
            </A10Row>
          )}
          <A10Modal
            title="Confirmation!"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            width={670}
            styles={{ 'marginLeft': '10px' }}
            footer={[
              <A10Button
                key="yes"
                onClick={this.handleOk}
                className={styles.yesButton}
                disabled={this.state.loadingIconModal}
              >
                Yes
              </A10Button>,
              <A10Button
                key="no"
                onClick={this.handleCancel}
                className={styles.cancelButton}
                disabled={this.state.loadingIconModal}
              >
                Cancel
              </A10Button>,
            ]}
          >
            <p style={{ 'marginLeft': '10px' }}>
              {this.Messages.RESET_MFA}
            </p>
            {this.state.loadingIconModal && (
              <div className="">
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </div>
            )}
          </A10Modal>
        </div>
      </div>
    )
  }
}

export default UserProfile
