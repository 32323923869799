import React from 'react'
import parameters from 'parameters'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Row,
  A10Col,
  A10Button,
  A10Spin,
  A10Collapse,
  A10Radio,
  A10Input,
  A10Alert,
  A10Select,
} from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import {
  Utilities,
  TroubleShootService,
  InfrastructureService,
} from 'src/services'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import ScaleoutMap from './ScaleoutMap'

import styles from './styles/index.module.less'
import moment from 'moment'

export interface IScaleoutTrafficProps extends IA10ContainerDefaultProps {
  clusterDeviceToggled: boolean
}
export interface IScaleoutTrafficState {
  cluster: string
  scaleoutData: IObject
  scaleoutDataMap: IObject
  loadingData: boolean
  type: string
  ipaddr: string
  searchingData: boolean
  deviceSearchData: IObject
  deviceExtraMessage: string
  searchDevice: string
  searchPartition: string
  refreshTime: number
  devicesDetail: IObject[]
}

class ScaleoutTraffic extends A10Container<
  IScaleoutTrafficProps,
  IScaleoutTrafficState
> {
  api = process.env.NODE_ENV === 'production' ? parameters.BASE_URL : ''
  auth = storage.get.ENCODED_SESSION_ID
  provider = storage.get.PROVIDER
  mode = 'PROD'
  TroubleShootService = new TroubleShootService()
  Utilities = new Utilities()
  InfrastructureService = new InfrastructureService()

  headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    provider: storage.get.PROVIDER,
    Authorization: storage.get.ENCODED_SESSION_ID,
    user: storage.get.USER_ID,
  }

  constructor(props: any) {
    super(props)
    this.state = {
      cluster: storage.get.CURRENT_CLUSTER.name,
      scaleoutData: null,
      scaleoutDataMap: null,
      loadingData: false,
      type: 'subscriber',
      ipaddr: '',
      searchingData: false,
      deviceSearchData: null,
      deviceExtraMessage: '',
      searchDevice: '',
      searchPartition: '',
      devicesDetail: [],
      refreshTime: null,
    }
  }

  // On-demand get devices detail
  getDevicesDetail = async () => {
    const deviceResp = await this.InfrastructureService.getDevices(null, null, [
      storage.get.PROVIDER,
    ])

    this.setState(
      { devicesDetail: deviceResp?.data?.['device-list'] || [] },
      this.getScaleOutIPData,
    )
  }

  getScaleOutTrafficMapData = async () => {
    this.setState({
      loadingData: true,
      deviceExtraMessage: '',
      searchDevice: '',
      searchPartition: '',
    })
    const { showMessage } = this.Utilities
    const cluster = storage.get.CURRENT_CLUSTER.name
    if (cluster) {
      const devices = this.state.devicesDetail
      const deviceNames: string[] = []
      devices.forEach((device: any) => {
        if (
          device.cluster === cluster &&
          device['partition-list'] &&
          device['partition-list'].length > 0
        ) {
          deviceNames.push(device.name)
        }
      })
      if (deviceNames.length > 0) {
        const deviceSOReqs: any[] = []
        const partitionName = 'shared'
        deviceNames.forEach((device: string) => {
          const axApiUrl =
            parameters.BASE_URL +
            '/hpcapi/v3/provider/' +
            this.provider +
            '/device/' +
            device +
            '/partition/' +
            partitionName +
            '/scaleout/traffic-map/oper'
          const payload = {
            'object-url': axApiUrl,
            method: 'GET',
            'object-payload': {},
          }
          const deviceScaleoutTrafficMapOper = this.TroubleShootService.devicePartitionAxApiGetCall(
            null,
            payload,
            [this.provider, device, partitionName],
          )
          deviceSOReqs.push(
            deviceScaleoutTrafficMapOper.catch((error: any) => {
              console.log(
                'error in getting Device scaleout traffic map data',
                error,
              )
            }),
          )
        })
        Promise.all(deviceSOReqs)
          .then((response: any) => {
            const scaleoutData: any = {}
            let dataPresent = false
            response.forEach((resp: any) => {
              if (resp?.data && resp.data.length > 0) {
                const devSOResp = resp.data[0]
                const deviceUUIDs = Object.keys(devSOResp)
                deviceUUIDs &&
                  deviceUUIDs.forEach((devUUID: string) => {
                    const devObj = devices.find((dev: any) => {
                      return dev['device-uuid'] === devUUID
                    })
                    const soMapEntriesHead = devSOResp?.[devUUID]?.[
                      'traffic-map'
                    ]?.oper?.['map-entries-list-head']
                      ? devSOResp[devUUID]['traffic-map'].oper[
                          'map-entries-list-head'
                        ]
                      : []

                    if (
                      soMapEntriesHead &&
                      soMapEntriesHead.length > 0 &&
                      soMapEntriesHead[0]['map-entries-list']
                    ) {
                      scaleoutData[devObj.name] =
                        soMapEntriesHead[0]['map-entries-list']
                      dataPresent = true
                    }
                  })
              }
            })
            const searchPartition =
              devices[0]['partition-list'] && devices[0]['partition-list'][0]
                ? devices[0]['partition-list'][0].name
                : ''
            this.setState({
              scaleoutData: dataPresent ? scaleoutData : null,
              searchDevice: devices[0].name,
              searchPartition,
              loadingData: false,
              deviceExtraMessage: dataPresent
                ? ''
                : 'Either there is an error communicating with the devices or the cluster is not of type Scaleout',
            })
          })
          .catch((error: any) => {
            showMessage(
              'Error in getting Device scaleout traffic map data',
              0,
              0,
            )
            this.setState({
              scaleoutData: null,
              loadingData: false,
              deviceExtraMessage:
                'Either there is an error communicating with the devices or the cluster is not of type Scaleout',
            })
          })
      } else {
        this.setState({
          scaleoutData: null,
          loadingData: false,
          deviceExtraMessage: 'No Device(s) in the cluster ' + cluster,
        })
      }
    } else {
      this.setState({ scaleoutData: null, loadingData: false })
    }
  }

  getDeviceNames = () => {
    const deviceNames: string[] = []
    const cluster = storage.get.CURRENT_CLUSTER.name
    if (!!cluster) {
      const devices = this.state.devicesDetail
      devices.forEach((device: any) => {
        if (device.cluster === cluster) {
          deviceNames.push(device.name)
        }
      })
    }
    return deviceNames
  }

  getPartitionDropdownName = () => {
    const partitionNames: string[] = [],
      { searchDevice } = this.state
    if (!!searchDevice) {
      const devices = this.state.devicesDetail
      devices.forEach((device: any) => {
        if (device.name === searchDevice) {
          device['partition-list'] &&
            device['partition-list'].forEach((partition: any) => {
              partitionNames.push(partition.name)
            })
        }
      })
    }
    return partitionNames
  }

  // show ip command execution
  getScaleOutIPData = () => {
    this.setState({ loadingData: true })
    const cluster = storage.get.CURRENT_CLUSTER.name
    if (cluster) {
      const deviceNames = this.getDeviceNames()

      if (deviceNames.length > 0) {
        const payload = {
          commands_source: ['show ip'],
          devices: [],
          client: 'hc_system',
        }
        deviceNames.forEach((deviceName: any) => {
          payload.devices.push({
            name: deviceName,
            partition: 'shared',
          })
        })
        const runCliSnippet = this.TroubleShootService.runCliSnippet(
          this.headers,
          payload,
          [this.provider],
        )
        runCliSnippet
          .then((response: any) => {
            const troubleShootResult = response.data
            if (troubleShootResult?.details) {
              deviceNames.forEach((deviceName: any) => {
                const cliResponse =
                  troubleShootResult.details?.[
                    deviceName
                  ]?.shared?.output?.result?.response.split('\n') || []
                if (cliResponse.length > 0) {
                  troubleShootResult.details[
                    deviceName
                  ].shared.output.result.response = cliResponse
                }
                troubleShootResult.details[deviceName] =
                  troubleShootResult.details[deviceName].shared
              })
            }

            this.setState({
              scaleoutDataMap: troubleShootResult,
            })
            this.getScaleOutTrafficMapData()
          })
          .catch((error: any) => {
            console.log('Error in submission of command to execute')
            this.setState({ loadingData: false })
            this.getScaleOutTrafficMapData()
          })
      } else {
        this.setState({ scaleoutDataMap: null })
        this.getScaleOutTrafficMapData()
      }
    } else {
      this.setState({ scaleoutDataMap: null })
      this.getScaleOutTrafficMapData()
    }
  }

  parseSearchResult = (searchResult: any, deviceName: string) => {
    if (
      searchResult !== '' &&
      searchResult[deviceName]?.shared?.output?.result?.response
    ) {
      const outputArr =
        searchResult[deviceName].shared.output.result.response.split('\n') || []
      if (outputArr.length > 3 && outputArr[1].indexOf('User-group') > -1) {
        const deviceSearchData = {
          type: 'pass',
          user: '',
          active: '',
          standby: '',
          msg: `${this.state.type == 'nat' ? 'NAT' : 'Subscriber'} IP ${
            this.state.ipaddr
          } Found!`,
        }
        const tempRow = outputArr[3].split(' '),
          devData: string[] = []
        tempRow.map((col: string) => {
          if (col != '') {
            devData.push(col)
          }
        })
        deviceSearchData.user = devData[0]
        deviceSearchData.active = devData[1]
        deviceSearchData.standby = devData[2]

        return deviceSearchData
      } else if (outputArr.length > 2 && outputArr[1].indexOf('Error:') > -1) {
        return { type: 'error', msg: outputArr[1] }
      }
      return {
        type: 'error',
        msg: `${this.state.type == 'nat' ? 'NAT' : 'Subscriber'} IP ${
          this.state.ipaddr
        } not found`,
      }
    } else {
      return { type: 'error', msg: 'No output from search command execution' }
    }
  }

  // Search for device by IP
  searchDeviceIP = () => {
    const { showMessage } = this.Utilities
    const { type, ipaddr, searchDevice, searchPartition } = this.state
    const ipRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
    if (!ipRegex.test(ipaddr)) {
      showMessage('Enter valid IP address', 0, 0)
      return
    }

    if (!searchDevice) {
      showMessage('Please select device', 0, 0)
      return
    }

    if (!searchPartition) {
      showMessage('Please select partition', 0, 0)
      return
    }

    const cluster = storage.get.CURRENT_CLUSTER.name
    if (cluster) {
      const deviceNames = this.getDeviceNames()

      if (deviceNames.length > 0) {
        this.setState({ searchingData: true })
        const command =
          'show cgnv6 scaleout address-mapping ' +
          (type == 'nat' ? ' nat-address ' : ' inside-user ') +
          ipaddr
        const payload = {
          commands_source: [command],
          devices: [
            {
              name: searchDevice,
              partition: searchPartition,
            },
          ],
          client: 'hc_system',
        }

        const runCliSnippet = this.TroubleShootService.runCliSnippet(
          this.headers,
          payload,
          [this.provider],
        )
        runCliSnippet
          .then((response: any) => {
            const searchResult = response?.data?.details || ''
            const deviceSearchData = this.parseSearchResult(
              searchResult,
              this.state.searchDevice,
            )
            this.setState({ deviceSearchData, searchingData: false })
          })
          .catch((error: any) => {
            // showMessage('Error in submission of search command', 0, 0)
            this.setState({
              deviceSearchData: {
                type: 'error',
                msg: 'Error in submission of search command',
              },
              searchingData: false,
            })
          })
      } else {
        // No device to search
      }
    } else {
      // No cluster to search
    }
  }

  handleChange = (stateName: string, e: any) => {
    let value = ''
    const state = this.state
    if (e.target) {
      if (e.target.type === 'checkbox') {
        value = e.target.checked
      } else {
        value = e.target.value
      }
    } else {
      value = e
    }
    state[stateName] = value
    if (stateName === 'searchDevice') {
      state.searchPartition = ''
    }
    // @ts-ignore
    this.setState(state)
  }

  onRefresh = () => {
    this.setState({ refreshTime: Date.now() })
    this.getScaleOutIPData()
  }

  componentDidMount() {
    this.getDevicesDetail()
  }

  componentDidUpdate(prevProps: IScaleoutTrafficProps) {
    if (this.props.clusterDeviceToggled !== prevProps.clusterDeviceToggled) {
      this.setState({
        cluster: storage.get.CURRENT_CLUSTER.name,
      })
      this.getScaleOutIPData()
    }
  }

  renderGetMapPanel = () => {
    const {
      type,
      ipaddr,
      searchingData,
      deviceSearchData,
      searchDevice,
      searchPartition,
    } = this.state
    const deviceDropdown = this.getDeviceNames()
    const partitionDropdown = this.getPartitionDropdownName()

    return (
      <div className={styles.getMapSec}>
        <A10Collapse defaultActiveKey={['1']} bordered={false}>
          <A10Collapse.Panel
            key="1"
            className="collapsePanel"
            header={
              <div className={`row`}>
                <span className={styles.getMapSecHeader}>GET MAP</span>
              </div>
            }
          >
            <A10Row className={styles.getMapSecBody}>
              <A10Col
                xs={24}
                sm={11}
                md={11}
                lg={11}
                xl={11}
                className={styles.searchSec}
              >
                <A10Row className={styles.searchSecRow}>
                  <A10Radio.Group
                    name="type"
                    value={type}
                    onChange={this.handleChange.bind(this, 'type')}
                  >
                    <A10Radio value="subscriber">Subscriber IP</A10Radio>
                    <A10Radio value="nat">NAT IP</A10Radio>
                  </A10Radio.Group>
                </A10Row>

                <A10Row
                  className={`${styles.searchSecRow} ${styles.searchSelectRow}`}
                >
                  <A10Col
                    md={12}
                    lg={11}
                    xl={11}
                    className={styles.searchSelect}
                  >
                    <span className={styles.searchTypeLabel}>Device</span>
                    <A10Select
                      size="default"
                      style={{ width: '200px' }}
                      title={searchDevice}
                      onChange={this.handleChange.bind(this, 'searchDevice')}
                      placeholder="Select Device"
                      value={!!searchDevice ? searchDevice : undefined}
                    >
                      {deviceDropdown &&
                        deviceDropdown.map((device: string, i: number) => {
                          return (
                            <A10Select.Option
                              key={'device-sotm-' + i}
                              value={device}
                              title={device}
                            >
                              {device}
                            </A10Select.Option>
                          )
                        })}
                    </A10Select>
                  </A10Col>
                  <A10Col
                    md={12}
                    lg={10}
                    xl={10}
                    className={styles.searchSelect}
                  >
                    <span className={styles.searchTypeLabel}>Partition</span>
                    <A10Select
                      size="default"
                      style={{ width: '200px' }}
                      title={searchPartition}
                      onChange={this.handleChange.bind(this, 'searchPartition')}
                      placeholder="Select Partition"
                      value={!!searchPartition ? searchPartition : undefined}
                    >
                      {partitionDropdown &&
                        partitionDropdown.map(
                          (partition: string, i: number) => {
                            return (
                              <A10Select.Option
                                key={'partition-sotm-' + i}
                                value={partition}
                                title={partition}
                              >
                                {partition}
                              </A10Select.Option>
                            )
                          },
                        )}
                    </A10Select>
                  </A10Col>
                </A10Row>

                <A10Row className={styles.searchSecRow}>
                  <A10Col
                    md={10}
                    lg={6}
                    xl={6}
                    className={styles.searchTypeLabel}
                  >
                    <span>{type == 'nat' ? 'NAT IP' : 'Subscriber IP'}</span>
                  </A10Col>
                  <A10Col
                    md={12}
                    lg={10}
                    xl={10}
                    className={styles.searchIpInput}
                  >
                    <A10Input
                      type="text"
                      placeholder="0.0.0.0"
                      value={ipaddr}
                      onChange={this.handleChange.bind(this, 'ipaddr')}
                    />
                  </A10Col>
                  <A10Col
                    md={12}
                    lg={6}
                    xl={6}
                    className={styles.searchIpButton}
                  >
                    <A10Button
                      type="primary"
                      className="submit-button"
                      onClick={this.searchDeviceIP.bind(this)}
                      disabled={
                        !type || !ipaddr || !searchDevice || !searchPartition
                      }
                    >
                      Search
                    </A10Button>
                  </A10Col>
                </A10Row>
              </A10Col>
              <A10Col
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={styles.resultSec}
              >
                {searchingData ? (
                  <A10Spin tip="Loading...">
                    <div style={{ padding: '15px', height: '100px' }}></div>
                  </A10Spin>
                ) : (
                  <>
                    {!deviceSearchData ? null : (
                      <>
                        <A10Row>
                          <A10Alert
                            showIcon={true}
                            closable={true}
                            style={{ margin: '0px 8px 5px 8px' }}
                            message={deviceSearchData.msg}
                            type={
                              deviceSearchData.type === 'pass'
                                ? 'success'
                                : 'error'
                            }
                          />
                        </A10Row>
                        {deviceSearchData.type === 'pass' ? (
                          <>
                            <A10Row className={styles.resultOutput}>
                              <A10Col
                                md={5}
                                lg={4}
                                xl={4}
                                className={styles.resultTitle}
                              >
                                User Group
                              </A10Col>
                              <A10Col
                                md={5}
                                lg={4}
                                xl={4}
                                className={styles.resultValue}
                              >
                                {deviceSearchData.user}
                              </A10Col>
                            </A10Row>

                            <A10Row className={styles.resultOutput}>
                              <A10Col
                                md={5}
                                lg={4}
                                xl={4}
                                className={styles.resultTitle}
                              >
                                Active Device
                              </A10Col>
                              <A10Col
                                md={5}
                                lg={4}
                                xl={4}
                                className={styles.resultValue}
                              >
                                {deviceSearchData.active}
                              </A10Col>
                            </A10Row>

                            <A10Row className={styles.resultOutput}>
                              <A10Col
                                md={5}
                                lg={4}
                                xl={4}
                                className={styles.resultTitle}
                              >
                                Standby Device
                              </A10Col>
                              <A10Col
                                md={5}
                                lg={4}
                                xl={4}
                                className={styles.resultValue}
                              >
                                {deviceSearchData.standby}
                              </A10Col>
                            </A10Row>
                          </>
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </A10Col>
            </A10Row>
          </A10Collapse.Panel>
        </A10Collapse>
      </div>
    )
  }

  render() {
    const {
      cluster,
      scaleoutData,
      scaleoutDataMap,
      loadingData,
      deviceExtraMessage,
      refreshTime,
    } = this.state
    const clusters = storage.get.ALLCLUSTERS

    const showActionButton = !loadingData && clusters.length > 0 && cluster
    const showScaleout =
      !loadingData &&
      clusters.length > 0 &&
      cluster &&
      !!scaleoutData &&
      !!scaleoutDataMap
    const noDataMsg =
      clusters.length === 0
        ? 'There are no Infrastructure components to show scaleout traffic map for. Please add clusters and devices before any data can be shown.'
        : !cluster
        ? 'Please select cluster to show scaleout traffic map for.'
        : `No Data to show the scaleout traffic map for ${cluster}`

    return (
      <>
        <ContentSection className={styles.scaleoutTraffic}>
          <ContentHeader type="flex" align="middle" justify="space-between">
            <A10Col>
              <ContentTitle
                title={`Scaleout Traffic Map${
                  cluster ? ` for ${cluster}` : ''
                }`}
              />
            </A10Col>
            <A10Col className={styles.refreshButton}>
              {showActionButton && (
                <>
                  {refreshTime && (
                    <div className={styles.refreshText}>
                      Refreshed at{' '}
                      {moment(refreshTime).format('MM/DD/YYYY hh:mm A')}
                    </div>
                  )}
                  <ActionButton
                    text="Refresh"
                    onClick={this.onRefresh}
                    iconProps={{ app: 'global', type: 'play-refresh' }}
                  />
                </>
              )}
            </A10Col>
          </ContentHeader>
          <ContentBody isLoading={loadingData}>
            {showScaleout ? (
              <>
                <ScaleoutMap data={scaleoutData} dataMap={scaleoutDataMap} />
                {this.renderGetMapPanel()}
              </>
            ) : (
              <div className="empty-data-warn">
                <div className="empty-image-block">
                  <i className="empty-image-icon empty-no-device" />
                </div>
                <span>{noDataMsg}</span>
                <br />
                <span>{deviceExtraMessage}</span>
              </div>
            )}
          </ContentBody>
        </ContentSection>
      </>
    )
  }
}

export default setupA10Container(ScaleoutTraffic)
