import storage from 'src/libraries/storage'
import httpClient from 'src/libraries/httpClient'
import NS from 'src/redux/httpServices/namespaces'

const CONFIG = {
  DEBUG: true,
  httpClient,
  EPIC_DEPENDENCIES: {
    httpClient,
  },
  REDUX_DATA_NS: NS,
  ADC_ROUTE_PATH: '/launchers/adc',
  storage,
}
export default CONFIG

export interface IPromise<T> {
  data: T
}
