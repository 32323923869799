import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import { A10Button, A10Modal } from '@gui-libraries/widgets'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import AccessGroupForm, { IFormData } from './index'
import { OrganizationService, Utilities } from 'src/services'
import storage from 'src/libraries/storage'

const NAME_REGEX = /^([.a-zA-Z0-9_-]){1,128}$/

export interface ISlidingAccessGroupProps extends IA10ContainerDefaultProps {
  isOpen: boolean
  isEdit?: boolean
  formData?: IFormData
  role?: string
  onRequestClose: () => void
  accessroleList: IObject
  data: IObject[]
  authResponse:IObject[]
}
export interface ISlidingAccessGroupStates {
  formData?: IFormData
  disableSave: boolean
  confirmModalState: boolean
  authResponseState: IObject
}

class SlidingAccessGroup extends A10Container<
  ISlidingAccessGroupProps,
  ISlidingAccessGroupStates
> {
  OrganizationService = new OrganizationService()
  Utilities = new Utilities()
  constructor(props: ISlidingAccessGroupProps) {
    super(props)
    this.state = {
      disableSave: !props.isEdit,
      confirmModalState: false,
    }
  }

  componentWillReceiveProps(nextProps: IObject) {
    const state = { ...this.state }
    const { formData } = nextProps
    state.formData = formData
    this.setState(state)
  }

  onFormChange = (data: IFormData, isValid: boolean) => {
    const state = { ...this.state }
    state.disableSave =
      !data.accessGroup.name.match(NAME_REGEX) ||
      !data.accessGroup.role ||
      !isValid
    state.formData = data
    this.setState(state)
  }

  onRequestOk = () => {
    const { onRequestClose, isEdit, role } = this.props
    const formData = this.state.formData as IFormData
    if (
      formData.accessGroup.resourceList &&
      formData.accessGroup.resourceList.length === 0
    ) {
      this.Utilities.showMessage('please select role', 0, 0)
      return
    }
    if (isEdit && formData.accessGroup.role !== role) {
      this.setState({ confirmModalState: true })
    } else {
      this.saveAccessGroup().then(() => {
        onRequestClose()
      })
    }
  }

  handleOk = () => {
    const { onRequestClose } = this.props
    this.handleCancel()
    this.saveAccessGroup().then(() => {
      onRequestClose()
    })
  }

  handleCancel = () => {
    this.setState({ confirmModalState: false })
  }

  async saveAccessGroup() {
    const formData = this.state.formData as IFormData
    const scopeType = formData?.accessGroup?.resourceList

    if (scopeType?.tenant || scopeType?.['logical-partition']) {
      const tenantList = scopeType?.tenant?.filter((tenantObj: IObject) => {
        return tenantObj?.name !== 'pso' && tenantObj?.name !== 'tenant'
      })
      scopeType.tenant = tenantList

      const LPList = scopeType?.['logical-partition']?.filter((lPObj: IObject) => {
        return lPObj?.name !== 'logical-partition'
      })
      scopeType['logical-partition'] = LPList
    } else {
      const clusterList = scopeType?.cluster?.filter((clusterObj: IObject) => {
        return clusterObj?.name !== 'cluster'
      })
      scopeType.cluster = clusterList
    }

    Object.keys(scopeType).forEach(
      key => scopeType[key] === undefined && delete scopeType[key]
    )
    
    const payload = {
      name: formData.accessGroup.name,
      displayName:
        formData.accessGroup.displayName || formData.accessGroup.name,
      description: formData.accessGroup.description || null,
      role: formData.accessGroup.role || null,
      resourceList: _.cloneDeep(scopeType) || {},
      ldapGroupDn: formData?.accessGroup?.ldapGroupDn || null
    }    
    const urlList = [storage.get.PROVIDER]
    if (this.props.isEdit) {
      urlList.push(formData.accessGroup.name)
    }

    const postAccessGroup = this.OrganizationService.saveAccessGroup(
      null,
      payload,
      urlList,
      this.props.isEdit,
    )
    await postAccessGroup
      .then((resp: any) => {        
        this.Utilities.showMessage(
          `Access group ${formData.accessGroup.displayName ||
            formData.accessGroup.name} has been ${
            this.props.isEdit ? 'updated' : 'created'
          } successfully.`,
          1,
          0,
        )
      })
      .catch((error: any) => {
        const message = _.get(error, ['response', 'data', 'message'], '')
        this.Utilities.showMessage(
          `Failed to ${this.props.isEdit ? 'update' : 'create'} access group.`,
          0,
          0,
          message,
        )
      })
  }
  render() {
    const { isOpen, onRequestClose, formData, isEdit, accessroleList,authResponse } = this.props
    const { disableSave, confirmModalState } = this.state
    const title = isEdit ? 'Update Access Group' : 'Add New Access Group'
    
    return (
      <>
        <FormatSlidingPage
          isOpen={isOpen}
          onRequestOk={this.onRequestOk}
          onRequestClose={onRequestClose}
          title={title}
          description="Please provide access group details"
          disableSave={disableSave}
        >
          <AccessGroupForm
            isEdit={isEdit}
            onChange={this.onFormChange}
            formData={formData}
            accessroleList={accessroleList}
            authResponse={authResponse}
          />
        </FormatSlidingPage>
        <A10Modal
          title="Confirmation"
          visible={confirmModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            You are changing the Role associated to this Access Group. This will
            change privileges for users belonging to this Access Group.
            <br />
            <br />
            Do you want to Continue?
          </p>
        </A10Modal>
      </>
    )
  }
}

export default setupA10Container(SlidingAccessGroup)
