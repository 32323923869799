import React from 'react'
import { A10Layout } from '@gui-libraries/widgets'

import styles from './styles/index.module.less'

export interface ITopHeaderProps {
  className?: string
  children?: React.ReactNode
}

export interface ILeftProps {
  children?: React.ReactNode
}

export interface IRightProps {
  children?: React.ReactNode
}

export interface IDividerProps {}

const TopHeader: React.FC<ITopHeaderProps> = props => {
  const { className, children } = props

  return (
    <A10Layout.Header className={`${className} ${styles.topHeader}`}>
      {children}
    </A10Layout.Header>
  )
}

export default TopHeader

export const Left: React.FC<ILeftProps> = props => {
  return <div className={styles.left}>{props.children}</div>
}

export const Right: React.FC<IRightProps> = props => {
  return <div className={styles.right}>{props.children}</div>
}

export const Divider: React.FC<IDividerProps> = props => {
  return <div className={styles.divider} />
}
