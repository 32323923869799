import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Icon, A10Form, A10Input, A10Message } from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import storage from 'src/libraries/storage'
import { httpClient } from 'src/libraries/httpClient'

export interface ISlidingPartitionCommentProps
  extends IA10ContainerDefaultProps {
  isOpen: boolean
  type?: string
  onRequestClose: (toDeploy: boolean) => void
  partition: IObject
}

export interface ISlidingPartitionCommentStates {
  provider: string
  comment: string
}

class SlidingPartitionComment extends A10Container<
  ISlidingPartitionCommentProps,
  ISlidingPartitionCommentStates
> {
  constructor(props: ISlidingPartitionCommentProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER,
      comment: '',
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen === false) {
      this.setState({ comment: '' })
    }
  }

  onRequestSave = async (toDeploy: boolean = false) => {
    const { onRequestClose, partition } = this.props
    const saveUrl = partition['a10-url'] + '/save'
    // const loadUrl = partition['a10-url'] + '/load'
    const savePayload = {
      save: {
        comment: this.state.comment,
      },
    }
    // const loadVersion = partition.version ? partition.version + 1 : 1
    // const loadPayload = {
    //   load: {
    //     'object-uuid': partition.uuid,
    //     version: loadVersion,
    //   },
    // }
    try {
      if (this.state.comment !== '') {
        await httpClient.post(saveUrl, savePayload)
      } else {
        await httpClient.post(saveUrl)
      }
      // try {
      //   await httpClient.post(loadUrl, loadPayload)
      // } catch (err) {
      //   A10Message.error('Failed to load the latest version')
      //   console.log(err)
      //   return
      // }
      onRequestClose(toDeploy)
    } catch (err) {
      A10Message.error('Failed to save version')
      console.log(err)
    }
  }

  onFormChange = (e: any) => {
    const comment = e.target.value
    this.setState({ comment })
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }
    const { partition, isOpen, onRequestClose, type } = this.props
    if (!partition) {
      return null
    }
    let keyword = 'Logical'
    if (type === 'device') {
      keyword = 'Device'
    }
    const title = `Save Candidate Version of ${keyword} Partition ${partition.name}`
    return (
      <FormatSlidingPage
        isOpen={isOpen}
        title={title}
        onRequestOk={this.onRequestSave}
        onRequestClose={onRequestClose.bind(this, false)}
        isRightCancel={true}
        hideCancel={true}
        saveText="Save"
        // customizedButtons={
        //   <A10Button
        //     className="cancel-button"
        //     onClick={this.onRequestSave.bind(this, true)}
        //   >
        //     {'Save & Deploy'}{' '}
        //   </A10Button>
        // }
      >
        <A10Form layout="horizontal">
          <A10Panel
            title={
              <A10IconTextGroup
                text="New Version Detail"
                icon={<A10Icon style={{ fontSize: 48 }} type="desktop" />}
              />
            }
          >
            <A10Form.Item {...formItemLayout} label="Comment">
              <A10Input
                placeholder="128 characters maximum"
                maxLength={128}
                value={this.state.comment}
                onChange={this.onFormChange}
              />
            </A10Form.Item>
          </A10Panel>
        </A10Form>
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(SlidingPartitionComment)
