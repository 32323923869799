import React from 'react'
import { A10Component } from '@gui-libraries/framework'
import { A10Tooltip } from '@gui-libraries/widgets'
import Utilities from 'src/services/Utilities/Utilities'
import { DISPLAY_UNIT } from '@gui-libraries/viz'

export interface IConditionProps {
  condition: any
  metadata: IObject
  header: boolean
}

class Condition extends A10Component<IConditionProps> {
  Utilities = new Utilities()

  renderDetails(conditionsObj: IObject[] = [], type: any) {
    const content: any = []
    const { metadata = {} } = this.props
    conditionsObj.map((operObj: any, i: number) => {
      // let operObj = andOper[operation];
      const { getVal } = this.Utilities
      const { value, display_value } = operObj
      // GUI-4383 some default alerts have no Units under metadata
      const Measured_unit =
        metadata?.Units?.[i]?.Measured_unit || metadata.Measured_unit
      const Display_unit = metadata?.Units?.[i]?.Display_unit || ''
      const displayUnitArray = DISPLAY_UNIT.get(Measured_unit) || [
        { formula: [], displayUnit: '' },
      ]
      const index = displayUnitArray.findIndex(
        (v: IObject) => v.displayUnit === Display_unit,
      )
      const { formula = [], displayUnit = '' } = displayUnitArray[
        index > -1 ? index : 0
      ]
      const unit = displayUnit ? Display_unit : Measured_unit
      const calculatedValue = !formula.length
        ? value
        : formula[0] === 'divide'
        ? value / formula?.[1]
        : value * formula?.[1]
      const metricAgg = getVal(operObj.aggregation, 'TRIGGERS', 'metricAgg')
      const strMetricAgg = getVal(
        operObj.aggregation,
        'TRIGGERS',
        'strMetricAgg',
      )
      const operation = getVal(operObj.operation, 'TRIGGERS', 'operation')
      const operator = getVal(operObj.operator, 'TRIGGERS', 'operator')
      const metricAggPercent = getVal(
        operObj.metricAggPercent,
        'TRIGGERS',
        'metricAgg',
      )
      const metricPercent = getVal(operObj.metricPercent, 'TRIGGERS', 'metric')
      const aggreateHTML = strMetricAgg ? (
        <>
          {' '}
          {operObj.params.length > 1
            ? operation?.label?.toLowerCase() +
              ' of (' +
              operObj.params.join(', ') +
              ')'
            : operObj.params.join(', ')}
          <b> {strMetricAgg?.displayLabel?.toLowerCase()} </b>
        </>
      ) : (
        <>
          {' '}
          <b> {metricAgg?.label?.toLowerCase()} </b>
          {operObj.params.length > 1
            ? operation?.label?.toLowerCase() +
              ' of (' +
              operObj.params.join(', ') +
              ')'
            : operObj.params.join(', ')}
          {' is '} {operator?.label?.toLowerCase()}{' '}
        </>
      )
      content.push(
        <div key={i}>
          <b>{i !== 0 ? ' ' + type + ' ' : ''}</b>
          {aggreateHTML}
          <span className="condition-value">
            {display_value || calculatedValue}
          </span>
          {operObj.percent ? (
            <>
              {' % of '} {metricAggPercent?.label?.toLowerCase()}{' '}
              {metricPercent?.label?.toLowerCase()}
            </>
          ) : null}
          {unit ? (
            <span>
              {unit === 'percentage' ? '%' : unit === 'ratio' ? '' : unit}
            </span>
          ) : null}
        </div>,
      )
      return content
    })
    return <A10Tooltip title={content}>{content}</A10Tooltip>
  }

  renderExpressionDetails = (expressionObj: any) => {
    const content: any = []
    let condition = ''
    const { metadata } = this.props
    const Measured_unit = metadata?.Units?.Measured_unit || ''
    if (
      expressionObj &&
      expressionObj.expressions &&
      expressionObj.expression_condition
    ) {
      condition = expressionObj.expression_condition
      const keys = Object.keys(expressionObj.expressions)
      if (keys && keys.length > 0) {
        const expressions = expressionObj.expressions
        keys.forEach(key => {
          condition = condition.replace(key, expressions[key])
        })
      }
    }
    const unit =
      Measured_unit === 'percentage'
        ? '%'
        : Measured_unit === 'ratio'
        ? ''
        : Measured_unit || ''

    content.push(
      <div
        className={this.props.header ? 'condition-text' : ''}
        key="expressionCondition"
      >
        Expression: <b>{condition + unit}</b>
      </div>,
    )
    return (
      <A10Tooltip placement="topLeft" title={content}>
        {content}
      </A10Tooltip>
    )
  }

  render() {
    const { condition } = this.props
    if (!condition) {
      return null
    }
    const andOper = condition.and
    const orOper = condition.or
    const expression = condition
    return (
      <>
        <div className="condition-container">
          {this.renderDetails(andOper, 'AND')}
        </div>
        <div className="condition-container">
          {this.renderDetails(orOper, 'OR')}
        </div>
        {expression.expressions ? (
          <div className="condition-container">
            {this.renderExpressionDetails(expression)}
          </div>
        ) : null}
      </>
    )
  }
}

export default Condition
