import React from 'react'
import {
  A10Container,
  _,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Notification } from '@gui-libraries/widgets'

import { occurArray } from 'src/libraries/arrayUtils'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import Associate from './Associate'

export interface IWrapperProps extends IA10ContainerDefaultProps {
  isShow: boolean
  tenant: string
  name: string
  type: string
  associateType: string
  templateName: string
  existList: IObject[]
  onTriggerSwitch: (
    isOpen: boolean,
    record?: any,
    needRefresh?: boolean,
  ) => void
}

export interface IWrapperState {
  isShow: boolean
  sgNames: string[]
  currentSelectedNames: string[]
}

class Wrapper extends A10Container<IWrapperProps, IWrapperState> {
  constructor(props: any) {
    super(props)
    this.state = {
      isShow: false,
      currentSelectedNames: [],
      sgNames: [],
    }
  }

  componentWillMount() {
    this.init(this.props)
  }

  componentWillReceiveProps(nextProps: any) {
    this.init(nextProps)
  }

  init = (props: any) => {
    const { existList = [] } = props
    const sgNames = existList.map((item: IObject) => {
      return item.uuid
    })
    this.setState({ sgNames })
  }

  getHttpClient = () => {
    const {
      GLOBAL_CONFIG: {
        EPIC_DEPENDENCIES: { httpClient },
      },
    } = this.props
    return httpClient
  }

  getTemplateName = () => {
    const { type, associateType } = this.props
    const splitTypes = type.split('.')
    const templateType = splitTypes[splitTypes.length - 1]
    if (associateType === 'server-ssl') {
      return `${templateType}-template`
    }
    return `template-${templateType}`
  }

  closeSlidingPage = () => {
    const { onTriggerSwitch = _.noop } = this.props
    onTriggerSwitch(false)
  }

  okSlidingPage = async () => {
    const { onTriggerSwitch = _.noop, name, templateName } = this.props
    const { currentSelectedNames, sgNames } = this.state
    const occur = occurArray(currentSelectedNames, sgNames)
    if (occur.removeList && occur.removeList.length > 0) {
      for (const sgUuid of occur.removeList) {
        await this.bindUnbindPolicy(sgUuid, false)
      }
    }
    if (occur.newList && occur.newList.length > 0) {
      for (const sgUuid of occur.newList) {
        await this.bindUnbindPolicy(sgUuid, true)
      }
    }

    A10Notification.success({
      message: 'Success!',
      description: `Assign ${name} to ${templateName} template.`,
    })
    onTriggerSwitch(false, null, true)
  }

  bindUnbindPolicy = async (uuid: string, bind: boolean) => {
    const { name, associateType, type } = this.props
    const templateType = this.getTemplateName()
    const updateApi = `/hpcapi/v3/uuid/${uuid}`
    const httpClient = this.getHttpClient()
    const {
      data: { [associateType]: sg },
    } = await httpClient.get(updateApi)
    let newSg = sg
    if (newSg) {
      if (bind) {
        if (associateType === 'http') {
          newSg.template = newSg.template || {}
          newSg.template.logging = name
        } else if (type === 'cert' && associateType === 'client-ssl') {
          newSg['cert-str'] = name
        } else if (type === 'cert' && associateType === 'server-ssl') {
          newSg.cert = name
        } else if (
          type === 'key' &&
          (associateType === 'client-ssl' || associateType === 'server-ssl')
        ) {
          newSg.key = name
        } else {
          newSg[templateType] = name
        }
      } else {
        if (associateType === 'http') {
          newSg.template = newSg.template || {}
          newSg.template = _.omit(newSg.template, 'logging')
        } else if (type === 'cert' && associateType === 'server-ssl') {
          newSg = _.omit(sg, 'cert')
        } else if (type === 'cert' && associateType === 'client-ssl') {
          newSg = _.omit(sg, 'cert-str')
        } else if (
          type === 'key' &&
          (associateType === 'client-ssl' || associateType === 'server-ssl')
        ) {
          newSg = _.omit(sg, 'key')
        } else {
          newSg = _.omit(sg, templateType)
        }
      }
      await httpClient.put(updateApi, { [associateType]: newSg })
    }
  }

  onChangeSelectedValue = (selectedValues: string[]) => {
    this.setState({ currentSelectedNames: selectedValues })
  }

  render() {
    const { isShow, tenant, name, associateType, templateName } = this.props
    return (
      <FormatSlidingPage
        isOpen={isShow}
        onRequestClose={this.closeSlidingPage}
        onRequestOk={this.okSlidingPage}
        saveText="Assign"
        title={`${name} > Associate a ${templateName} template`}
        description={`Please select a ${templateName} template to associate.`}
      >
        <Associate
          tenant={tenant}
          associateType={associateType}
          templateName={templateName}
          existSgNames={this.state.sgNames}
          onChangeSelectedValue={this.onChangeSelectedValue}
        />
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(Wrapper)
