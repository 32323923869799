import React from 'react'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Form, A10Input, A10Icon, A10Alert } from '@gui-libraries/widgets'

import { Messages } from 'src/locale/en/Messages'
import { Utilities, DashboardService } from 'src/services'
import { HelpInfo } from 'src/components/shared/HelpInfo'
// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

export interface ILMActivationFormProps extends IA10ContainerDefaultProps {
  form: any
  onRef?: any
  activationObj: any
  licType: string
  slidingForm: string
}

export interface ILMActivationFormStates {}

class LMActivationForm extends A10Container<
  ILMActivationFormProps,
  ILMActivationFormStates
> {
  Messages = new Messages()
  Utilities = new Utilities()
  DashboardService = new DashboardService()

  constructor(props: ILMActivationFormProps) {
    super(props)
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  render() {
    const { slidingForm, activationObj, licType } = this.props
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 14 },
    }

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel
          title={
            <A10IconTextGroup
              text={
                slidingForm +
                ' Activation for ' +
                (licType.indexOf('FLEXPOOL') > -1 ? 'Device' : 'Provider')
              }
              icon={
                <A10Icon
                  style={{ width: 22, height: 22, marginRight: 12 }}
                  app="global"
                  type="form-section"
                  className="sliding-panel-icon"
                />
              }
            />
          }
        >
          {slidingForm === 'Revoke' ? (
            <div className={`${styles.llmInfoDiv} "col-md-12"`}>
              <A10Alert
                showIcon={true}
                message="Warning"
                description={
                  'Revoking the activation will un-license the ' +
                  (licType.indexOf('FLEXPOOL') > -1 ? 'Device' : 'Provider')
                }
                type="warning"
              />
            </div>
          ) : null}

          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>
                  {licType.indexOf('FLEXPOOL') > -1 ? 'Device' : 'Provider'}
                </span>
                <span style={{ marginLeft: '-4px' }}>
                  <HelpInfo
                    placement="rightTop"
                    title={
                      licType.indexOf('FLEXPOOL') > -1 ? 'Device' : 'Provider'
                    }
                    helpKey={
                      licType.indexOf('FLEXPOOL') > -1
                        ? 'HELP_LICENSE_MANAGER_ACTIVATION_DEVICE'
                        : 'HELP_LICENSE_MANAGER_ACTIVATION_PROVIDER'
                    }
                  />
                </span>
              </>
            }
          >
            <div className="row">
              <div className="col-md-10">
                <A10Input
                  type="text"
                  placeholder={
                    licType.indexOf('FLEXPOOL') > -1 ? 'Device' : 'Provider'
                  }
                  value={
                    activationObj['appliance_name'] || activationObj['mac']
                  }
                  disabled={true}
                />
              </div>
            </div>
          </A10Form.Item>

          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>UUID</span>
                <span style={{ marginLeft: '-4px' }}>
                  <HelpInfo
                    placement="rightTop"
                    title="UUID"
                    helpKey="HELP_LICENSE_MANAGER_ACTIVATION_DEVICE_UUID"
                  />
                </span>
              </>
            }
          >
            <div className="row">
              <div className="col-md-10">
                <A10Input
                  type="text"
                  placeholder="UUID"
                  value={activationObj['appliance_uuid']}
                  disabled={true}
                />
              </div>
            </div>
          </A10Form.Item>

          {licType.indexOf('FLEXPOOL') > -1 ? (
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span>Build Version</span>
                  <span style={{ marginLeft: '-4px' }}>
                    <HelpInfo
                      placement="rightTop"
                      title="Build Version"
                      helpKey="HELP_LICENSE_MANAGER_ACTIVATION_DEVICE_BUILD_VERSION"
                    />
                  </span>
                </>
              }
            >
              <div className="row">
                <div className="col-md-10">
                  <A10Input
                    type="text"
                    placeholder="Build Version"
                    value={activationObj['build_version']}
                    disabled={true}
                  />
                </div>
              </div>
            </A10Form.Item>
          ) : null}
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(LMActivationForm))
