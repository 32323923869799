import React from 'react'
import {
  A10Collapse,
  A10DropdownMenu,
  A10Icon,
  A10Row,
  A10Table,
} from '@gui-libraries/widgets'
import { normalizeValue } from '@gui-libraries/viz'

import HealthStatus from 'src/components/ADC/HealthStatus'
import RoundNumber from 'src/components/shared/RoundNumber'
import storage from 'src/libraries/storage'

export interface IInterfaceTunnelProps {
  openAutoForm: any
  deleteTunnel: any
  tunnelList: IObject[]
  tunnelStatus: IObject
}

const InterfaceTunnel: React.FC<IInterfaceTunnelProps> = props => {
  const { tunnelList, tunnelStatus, openAutoForm, deleteTunnel } = props
  const [datasource, setDatasource] = React.useState([] as IObject[])
  const [status, setStatus] = React.useState({} as IObject)
  const [counter, setCounter] = React.useState({} as IObject)

  const clickAddNew = (event: React.MouseEvent<HTMLElement>) => {
    if (openAutoForm) {
      openAutoForm('network/interfaces/tunnel')
    }
    event.stopPropagation()
  }
  const editTunnel = (ifnum: number) => {
    if (openAutoForm) {
      const params = ifnum ? { ifnum } : undefined
      openAutoForm('network/interfaces/tunnel', params)
    }
  }

  const clickDelete = (ifnum: number) => {
    if (deleteTunnel) {
      deleteTunnel(ifnum)
    }
  }

  React.useEffect(() => {
    const ds = [] as IObject[]
    const count = {} as IObject
    const currentStatus = { up: 0, down: 0, disable: 0 } as IObject
    ;(tunnelList || []).map((tunnel: IObject) => {
      const { oper = {}, stats = {} } = (tunnelStatus || {})[tunnel.ifnum] || {}
      currentStatus[oper.state] = (currentStatus[oper.state] || 0) + 1
      const packetIn = normalizeValue('number', {
        value: stats.rate_pkt_sent || 0,
        formatType: '0.0b',
        unit: 'B',
      })
      const packetOut = normalizeValue('number', {
        value: stats.rate_pkt_rcvd || 0,
        formatType: '0.0b',
        unit: 'B',
      })
      const byteIn = normalizeValue('number', {
        value: stats.rate_byte_sent || 0,
        formatType: '0.00a',
      })
      const byteOut = normalizeValue('number', {
        value: stats.rate_byte_rcvd || 0,
        formatType: '0.00a',
      })
      const errorIn = normalizeValue('number', {
        value: stats['num-rx-err-pkts'] || 0,
        formatType: '0.0b',
        unit: 'B',
      })
      const errorOut = normalizeValue('number', {
        value: stats['num-tx-err-pkts'] || 0,
        formatType: '0.0b',
        unit: 'B',
      })
      ds.push({
        key: tunnel.ifnum,
        ifnum: tunnel.ifnum,
        mtu: tunnel.mtu,
        status: oper.state,
        action: tunnel.action,
        speed: tunnel.speed,
        mac: oper.mac,
        packets: `${packetIn}/ ${packetOut}`,
        bytes: `${byteIn}/ ${byteOut}`,
        errors: `${errorIn}/ ${errorOut}`,
      })
      count.byteIn = (count.byteIn || 0) + (stats.rate_byte_sent || 0)
      count.byteOut = (count.byteOut || 0) + (stats.rate_byte_rcvd || 0)
      count.packetIn = (count.packetIn || 0) + (stats.rate_pkt_sent || 0)
      count.packetOut = (count.packetOut || 0) + (stats.rate_pkt_rcvd || 0)
    })
    setStatus(currentStatus)
    setDatasource(ds)
    setCounter(count)
  }, [tunnelList, tunnelStatus])

  const columns = React.useMemo(
    () => [
      {
        dataIndex: 'status',
        render: (text: any, record: any) => {
          return (
            <HealthStatus
              type={record.status}
              tooltip={record.status || 'Unknown'}
              toolTipArrowPosition="topLeft"
            />
          )
        },
      },
      {
        title: 'Interface Number',
        dataIndex: 'ifnum',
        sorter: (a: IObject, b: IObject) => {
          return a.ifnum > b.ifnum ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      // {
      //   title: 'Partition ID',
      //   dataIndex: 'partitionid',
      //   sorter: (a: any, b: any) => a - b,
      //   render: (text: any, record: any) => {
      //     return text
      //   },
      // },
      {
        title: 'IP Address',
        dataIndex: 'ipaddress',
        sorter: (a: IObject, b: IObject) => {
          return a.ipaddress > b.ipaddress ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text || 'N/A'
        },
      },
      {
        title: 'MAC',
        dataIndex: 'mac',
        sorter: (a: IObject, b: IObject) => {
          return a.mac > b.mac ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text || 'N/A'
        },
      },
      // {
      //   title: 'Access Control',
      //   dataIndex: 'control',
      //   sorter: (a: any, b: any) => a - b,
      //   render: (text: any, record: any) => {
      //     return text
      //   },
      // },
      {
        title: 'Speed',
        dataIndex: 'speed',
        sorter: (a: IObject, b: IObject) => {
          return a.speed > b.speed ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text || 'N/A'
        },
      },
      {
        title: 'MTU',
        dataIndex: 'mtu',
        sorter: (a: IObject, b: IObject) => {
          return a.mtu > b.mtu ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text || 'N/A'
        },
      },
      {
        title: 'Packets In/Out',
        dataIndex: 'packets',
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Bytes In/Out',
        dataIndex: 'bytes',
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Errors In/Out',
        dataIndex: 'errors',
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        dataIndex: 'actions',
        width: 30,
        render: (text: any, record: any) => {
          return (
            !storage.get.IS_OPERATOR_USER && (
              <A10DropdownMenu
                title=""
                menu={[
                  <div key="edit" onClick={editTunnel.bind(this, record.ifnum)}>
                    Edit
                  </div>,
                  <div
                    key="delete"
                    onClick={clickDelete.bind(this, record.ifnum)}
                  >
                    Delete
                  </div>,
                ]}
                style={{ color: '#757575', marginBottom: '-15px' }}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            )
          )
        },
      },
    ],
    [deleteTunnel],
  )

  return (
    <div className="col-md-12 marginBottom10">
      <A10Collapse>
        <A10Collapse.Panel
          key="interface-tunnel"
          header={
            <>
              {!storage.get.IS_OPERATOR_USER && (
                <span className="edit-content" onClick={clickAddNew}>
                  <A10Icon app="global" type="add-new" /> Add
                </span>
              )}
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 width180">
                  Tunnel <RoundNumber number={(tunnelList || []).length} />
                </span>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Up</div>
                  <div className="pad-s-15 fontWeight300">{status.up || 0}</div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Down</div>
                  <div className="pad-s-15 fontWeight300">
                    {status.down || 0}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Disabled</div>
                  <div className="pad-s-15 fontWeight300">
                    {status.disabled || 0}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Packets In/Out</div>
                  <div className="pad-s-15 fontWeight300">
                    {normalizeValue('number', {
                      value: counter.packetIn || 0,
                      formatType: '0.0b',
                      unit: 'B',
                    })}
                    /
                    {normalizeValue('number', {
                      value: counter.packetOut || 0,
                      formatType: '0.0b',
                      unit: 'B',
                    })}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Bytes In/Out</div>
                  <div className="pad-s-15 fontWeight300">
                    {normalizeValue('number', {
                      value: counter.byteIn || 0,
                      formatType: '0.00a',
                    })}
                    /
                    {normalizeValue('number', {
                      value: counter.byteOut || 0,
                      formatType: '0.00a',
                    })}
                  </div>
                </div>
              </A10Row>
            </>
          }
          className="collapsePanel"
        >
          <A10Table
            columns={columns}
            dataSource={datasource}
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
            bordered={false}
          />
        </A10Collapse.Panel>
      </A10Collapse>
    </div>
  )
}

export default InterfaceTunnel
