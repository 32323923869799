import React from 'react'
import moment from 'moment-timezone'
import ReactLoading from 'react-loading'
import parameters from 'parameters'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Col,
  A10Tag,
  A10Icon,
  A10Button,
  A10Tooltip,
  A10Table,
  A10Modal,
  A10DropdownMenu,
  A10Input,
} from '@gui-libraries/widgets'

import { IDefaultMethods } from 'src/containers/Controller'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { DashboardService } from 'src/services/DashboardService'
import { UrlService } from 'src/services/UrlService'
import Utilities, { DATE_FORMAT } from 'src/services/Utilities/Utilities'
import { Configs } from 'src/constants/Configs'
import ReportFormActions from 'src/containers/Controller/Monitor/Reports/ReportSchedule/Forms/ReportFormActions'
import socketIOClient from 'socket.io-client'
import DropdownConstants from 'src/constants/DropdownConstants/DropdownConstants'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'

export interface IReportsListProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  tenantToggled: boolean
}
export interface IReportsListState {
  showSlidingPage: boolean
  searchString: string
  tenantToggled: boolean
  allData: any
  when: string
  deleteModalState: boolean
  selectedReport: any
  reportAddStateCheck: boolean
  scheduleData: IObject[]
}
export const WIDGET_CONFIG: any = {
  fields: [
    {
      key: 'rule_assoc_id',
      label: 'Alerts',
    },
  ],
  aggregation: 'count',
  histogramField: 'ts',
  rangebyField: 'ts',
  // filterBy: 'delete',
  filterBy: {
    and: {
      // account_id: '',
      rule_assoc_id: '',
    },
  },
}

class ReportsList extends A10Container<IReportsListProps, IReportsListState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  DropdownConstants = new DropdownConstants()
  UrlService = new UrlService()
  Configs = new Configs()
  dataLoaded = false
  REPORTS_TOPIC = 'HC_REPORTS_GENERATION'

  state: IReportsListState = {
    searchString: '',
    showSlidingPage: false,
    tenantToggled: this.props.tenantToggled,
    allData: [],
    when: 'onDemand',
    deleteModalState: false,
    selectedReport: {},
    reportAddStateCheck: false,
    scheduleData: [],
  }
  actionsReq: any[] = []
  reportsLength: number = 0
  socket: any = null
  durationDropdownOptions = this.DropdownConstants['REPORTS']['duration']
  durationDropdownOld = this.DropdownConstants['REPORTS']['durationOld']
  isOperatorUser
  reportColumns = [
    {
      title: '',
      dataIndex: 'path',
      key: 'path',
      width: 30,
      render: (path: any) => {
        const status =
          path === null || path === undefined || path === 'undefined'
            ? 'stopped'
            : 'ongoing'
        return <HealthStatus type={status} hideTooltip={true} />
      },
    },
    {
      title: 'Report',
      dataIndex: 'reportid',
      key: 'reportid',
      sorter: (a: any, b: any) => this.Utilities.sortDate(a, b, 'reportid'),
      render: (reportid: any, report: any) => {
        return report.name ? report.name : reportid
      },
    },
    {
      title: 'Tags',
      dataIndex: '',
      key: '',
      render: (report: any) => {
        const tags = []
        if (report.tags && report.tags.length > 0) {
          report.tags.forEach((tag: any) => {
            tags.push(
              <A10Tag color="blue" size="small">
                {tag}
              </A10Tag>,
            )
          })
        } else if (
          report.path !== null &&
          report.path !== undefined &&
          report.path !== 'undefined'
        ) {
          const segments = report.path.split('/')
          const first = segments.pop()
          const second = segments.pop()
          tags.push(
            <>
              <A10Tag color="blue" size="small">
                {second}
              </A10Tag>
            </>,
          )
        }
        return tags
      },
    },
    {
      title: 'Scope',
      dataIndex: 'tenantid',
      key: 'tenantid',
    },
    {
      title: 'Duration',
      dataIndex: 'occurance',
      key: 'occurance',
      render: (range: string) => {
        const options = this.durationDropdownOptions.concat(
          this.durationDropdownOld,
        )
        const durationArr = options && options.filter(obj => obj.key === range)
        return durationArr.length === 1 ? durationArr[0].label : range
      },
    },
    {
      title: <span>Action Taken</span>,
      dataIndex: 'action',
      key: 'action',
      className: 'td-truncate',
      render: (text: any, record: any) => {
        return (
          <A10Tooltip
            title={this.Utilities.rowToolTip(record, text)}
            placement="topLeft"
            overlayClassName="toolTipWidth"
          >
            {text ? text.join(', ') : ''}
          </A10Tooltip>
        )
      },
      sorter: (a: any, b: any, key: any) =>
        this.Utilities.sortArrayString(a, b, 'action', 0),
    },
    {
      title: 'Generated At',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a: IObject, b: IObject) =>
        this.Utilities.sortDate(a, b, 'created_at'),
      render: (key: any, reportInstance: IObject) => {
        const date = key !== undefined && key !== null ? key.split('.')[0] : ''
        const timezoneDetails = reportInstance?.timezone
          ? JSON.parse(reportInstance.timezone)
          : null
        return this.Utilities.convertUTCDateToTimezone(
          date,
          timezoneDetails?.value,
        )
      },
    },
    {
      title: 'Available for',
      dataIndex: 'expires_by',
      key: 'expires_by',
      sorter: (a: any, b: any) => this.Utilities.sortDate(a, b, 'expires_by'),
      render: (key: any, reportInstance: any) => {
        const date = key !== undefined && key !== null ? key.split('.')[0] : ''
        const timezoneDetails = reportInstance.timezone
          ? JSON.parse(reportInstance.timezone)
          : null
        if (timezoneDetails && date) {
          const timeoffset = timezoneDetails.value
          const expiryDate = moment.utc(date, DATE_FORMAT).tz(timeoffset)
          const today = moment()
            .tz(timeoffset)
            .startOf('day')
          const diffWeeks = expiryDate.diff(today, 'weeks')
          if (diffWeeks > 1 && diffWeeks <= 6) {
            return `${diffWeeks} Weeks`
          } else if (diffWeeks > 6) {
            const diffMonths = expiryDate.diff(today, 'months')
            return diffMonths > 1
              ? `${diffMonths} Months`
              : `${diffMonths} Month`
          } else if (diffWeeks <= 1) {
            const now = moment().tz(timeoffset)
            const diffDays = expiryDate.diff(now, 'days')
            if (diffDays === 0) {
              const expiryDateFullFormat = moment
                .utc(date, `${DATE_FORMAT} HH:mm:ss`)
                .tz(timeoffset)
              const diffHours = expiryDateFullFormat.diff(now, 'hours')
              if (diffHours <= 0) {
                return '0 Hour'
              }
              return diffHours > 1 ? `${diffHours} Hours` : `${diffHours} Hour`
            } else {
              if (diffDays < 0) {
                return '0 Day'
              }
              return diffDays > 1 ? `${diffDays} Days` : `${diffDays} Day`
            }
          }
        } else if (date) {
          return `${moment(date).format(`${DATE_FORMAT} hh:mm:ss A `)} UTC`
        } else {
          return '-'
        }
      },
    },
    {
      title: 'Time Zone',
      dataIndex: 'timezone',
      key: 'timezone',
      render: (timezone: string) => {
        // if array
        const timezoneDetails = timezone ? JSON.parse(timezone) : null
        return timezoneDetails ? timezoneDetails.offset : '-'
      },
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (createdBy: string) => {
        return createdBy ? createdBy : '-'
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (key: any) => {
        const clickAction = (component: JSX.Element, index: number) => {
          if (component.key === 'delete') {
            this.setState({
              deleteModalState: true,
              selectedReport: key,
            })
          }
        }
        const dropdownOptions = [<div key="delete">Delete</div>]
        return (
          <>
            <div className="text-right">
              <A10Icon
                app="global"
                type="download"
                title="Download PDF"
                className="pointer"
                onClick={() => {
                  key.path === 'undefined' ||
                  key.path === undefined ||
                  key.path == null
                    ? console.log('Nothing to do')
                    : this.downloadReport(key)
                }}
              />
              &nbsp;
              {this.isOperatorUser ? null : (
                <i>
                  <A10DropdownMenu
                    title=""
                    menu={dropdownOptions}
                    style={{ color: '#757575', marginBottom: '-15px' }}
                    onClick={clickAction}
                    trigger="hover"
                    placement="bottomRight"
                    arrowPointAtCenter={true}
                  />
                </i>
              )}
            </div>
          </>
        )
      },
    },
  ]

  constructor(
    props: IReportsListProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: {
        get: { IS_OPERATOR_USER },
      },
    } = context

    this.isOperatorUser = IS_OPERATOR_USER
  }

  generateReport = () => {
    this.setSlidingPage(true)
  }

  setSlidingPage = (isOpen: boolean, data?: IObject, editMode?: boolean) => {
    this.setState({
      showSlidingPage: isOpen,
    })
  }

  search = (e: any) => {
    const searchStr = e.target.value
    this.Utilities.search(
      this,
      searchStr,
      {
        firstKey: 'created_at',
      },
      {
        stateName: 'allData',
        storageName: 'ALL_REPORT_DATA',
      },
    )
  }

  componentWillMount() {}
  componentDidMount() {
    if (
      !this.dataLoaded ||
      this.state.tenantToggled !== this.props.tenantToggled
    ) {
      this.loadReports()
    }
  }
  componentWillUnmount() {
    if (this.socket && this.socket.connected) {
      this.socket.close()
    }
  }
  componentDidUpdate() {
    if (this.state.tenantToggled !== this.props.tenantToggled) {
      this.loadReports()
    }
  }

  getActions = () => {
    const promises: any = []
    this.actionsReq = []
    this.state.allData.forEach((reportObj: any) => {
      if (reportObj.actions && reportObj.actions.length > 0) {
        reportObj.actions.map((actionId: any) => {
          if (this.actionsReq.indexOf(actionId) === -1) {
            this.actionsReq.push(actionId)
            const actionRes = this.DashboardService.getAction(null, null, [
              actionId,
            ])
            promises.push(actionRes)
          }
        })
      }
    })

    Promise.all(promises)
      .then((respArr: any) => {
        const allData = this.state.allData
        allData.map((reportObj: any) => {
          if (reportObj.actions && reportObj.actions.length > 0) {
            const action: any = []
            reportObj.actions.map((actionId: any) => {
              respArr.map((resp: any, index: number) => {
                if (resp.data && resp.data.def_id === actionId) {
                  if (resp.data) {
                    // if required, we can add other details of actions here
                    action.push(resp.data.def_name)
                  }
                }
              })
            })
            reportObj.action = action
          }
        })
        this.setState({
          allData,
        })
      })
      .catch((error: any) => {
        console.log('error in gettinhg action data', error)
      })
    return
  }

  loadReports = (count?: string) => {
    const {
      storage: {
        get: { CURRENT_TENANT: tenant, PROVIDER: provider },
        set,
      },
    } = this.context

    const headers = this.Utilities.getXAccountHeaderDetails(false)
    if (!headers) {
      return
    }

    const payload = {
      tenantid: tenant.name,
      providerid: provider,
    }

    headers.tenant = tenant.name

    const reportResponse = this.DashboardService.getReports(headers, payload, [
      provider,
      tenant.name,
    ])

    reportResponse
      .then((response: any) => {
        if (response.data && response.data.success) {
          this.dataLoaded = true
          const reports = this.transformReports(response.data.reports.resultSet)
          this.reportsLength =
            typeof reports !== 'undefined' &&
            typeof reports.length !== 'undefined'
              ? reports.length
              : 0
          set.ALL_REPORT_DATA(reports)
          this.setState({
            allData: reports,
            tenantToggled: this.props.tenantToggled,
            reportAddStateCheck: false,
            searchString: '',
          })
          this.getActions()
        }
      })
      .catch((error: any) => {
        console.log(error)
        const message =
          error && error.response
            ? error.response.status && error.response.status === 403
              ? 'Access denied'
              : error.response.data && error.response.data.message
              ? error.response.data.message
              : ''
            : error.message
            ? error.message
            : ''
        this.Utilities.showMessage('Unable to load reports', 0, 0, message)
        this.dataLoaded = true
        this.setState({
          reportAddStateCheck: false,
        })
      })
  }

  loadScheduleReports = () => {
    const {
      storage: {
        get: { PROVIDER: provider, CURRENT_TENANT: tenant },
        set,
      },
    } = this.context

    const headers = this.Utilities.getXAccountHeaderDetails(false)
    if (!headers) {
      return
    }
    const payload = {
      tenantid: tenant.name,
      providerid: provider,
    }
    const scheduleReportResponse = this.DashboardService.getScheduleReports(
      headers,
      payload,
      [provider, tenant.name],
    )
    scheduleReportResponse
      .then((response: IObject) => {
        if (response?.data && response?.data?.success) {
          this.dataLoaded = true
          const reports = response.data.reports.resultSet
          set.ALL_SCHEDULE_REPORT_DATA(reports)
          this.setState({
            scheduleData: reports,
          })
        }
      })
      .catch((error: IObject) => {
        this.dataLoaded = true
        const message = this.Utilities.returnErrorMessage(error)
        this.Utilities.showMessage('Unable to load reports', 0, 0, message)
        this.setState({
          reportAddStateCheck: false,
        })
      })
  }

  loadDelayedReports = () => {
    const {
      storage: {
        get: { PROVIDER, USER_SESSION_ID },
      },
    } = this.context

    this.setState({ reportAddStateCheck: true, searchString: '' })
    this.Utilities.showMessage('WAITING_FOR_REPORTS', 1, 1)
    const baseUrl = parameters.BASE_URL
    this.socket = socketIOClient(
      this.Configs.WEBSOCKET_BASE_URL + '/kafkaTopic',
      {
        path: '/socket.io/hcws',
        query: {
          token: USER_SESSION_ID,
          provider: PROVIDER,
          base: baseUrl,
        },
      },
    )
    this.socket.on('reportKafkaResponseData', (data: any) => {
      if (data.socketError) {
        this.setState({ reportAddStateCheck: true, searchString: '' })
        console.log('Error Response from Websocket', data.socketError)
      }
      this.socket.close()
      this.setState({ reportAddStateCheck: true, searchString: '' })
      this.loadReports()
    })

    setTimeout(() => {
      this.sendSocketCall()
    }, 2000)
  }

  sendSocketCall() {
    const {
      storage: {
        get: { CURRENT_TENANT: tenant, DRILL_LEVEL: drillLevel, PROVIDER },
      },
    } = this.context

    if (this.socket && this.socket.connected) {
      const filterBy: any = {
        drillLevel,
        provider: PROVIDER,
        tenantUUID: tenant.uuid,
      }
      this.socket.emit('requestGenerateReportFromKafka', {
        kafkaTopic: this.REPORTS_TOPIC,
        filterBy,
        interval: 60,
      })
    } else {
      console.log('Websocket not connected')
    }
  }

  /**
   * Transforms reports to facilitate filtering
   */
  transformReports = (reports: any) => {
    if (reports && reports.length > 0) {
      reports.forEach((report: any) => {
        report.displayType =
          report.type === 'userTriggered' ? 'One Time Report' : report.type
      })
    }

    return reports.sort(
      (a: any, b: any) => new Date(b.created_at) - new Date(a.created_at),
    )
  }

  downloadReport = (reportInstance: any) => {
    const {
      storage: {
        get: { CURRENT_TENANT: tenant, PROVIDER: provider },
      },
    } = this.context

    const path = reportInstance.path
    const cephid = reportInstance.ceph_id
    const { showMessage } = this.Utilities
    const pdfId = cephid ? cephid : path
    const payload = {
      path: pdfId,
      name: reportInstance.name,
      type: reportInstance.type,
      tenantId: reportInstance.tenantid,
    }
    let name: any = path.split('/')
    if (name.length > 0) {
      name = name[name.length - 2] + '_' + name[name.length - 1]
    }
    const headers: any = this.Utilities.getXAccountHeaderDetails(false)
    const downloadReport = this.DashboardService.downloadReport(
      headers,
      payload,
      [provider, tenant.name],
    )
    downloadReport
      .then((resp: any) => {
        showMessage('Report downloaded', 1, 0)
        const blob = new Blob([resp.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = name
        link.click()
      })
      .catch((error: any) => {
        const message =
          error && error.response
            ? error.response.status && error.response.status === 403
              ? 'Access denied'
              : error.response.data && error.response.data.message
              ? error.response.data.message
              : ''
            : error.message
            ? error.message
            : ''
        showMessage('Unable to download report.', 0, 0, message)
      })
  }

  handleOk = (isEditCallback: any) => {
    const {
      storage: {
        get: { CURRENT_TENANT: tenant, PROVIDER: provider },
      },
    } = this.context

    const headers = this.Utilities.getXAccountHeaderDetails(false)
    if (!headers) {
      return
    }
    const { showMessage } = this.Utilities
    const payload = {
      provider: provider,
      id: this.state.selectedReport.id,
      ceph_id: this.state.selectedReport.ceph_id,
      path: this.state.selectedReport.path,
      type: this.state.selectedReport.type,
      name: this.state.selectedReport.name,
    }
    const deleteScheduledReport = this.DashboardService.deleteAdhocReport(
      headers,
      payload,
      [provider, tenant.name],
    )
    deleteScheduledReport
      .then((resp: any) => {
        showMessage('Report deleted successfully', 1, 0)
        this.dataLoaded = false
        this.setState({
          deleteModalState: false,
          selectedReport: {},
        })
        this.loadReports()
      })
      .catch((error: any) => {
        const message =
          error && error.response
            ? error.response.status && error.response.status === 403
              ? 'Access denied'
              : error.response.data && error.response.data.message
              ? error.response.data.message
              : ''
            : error.message
            ? error.message
            : ''
        showMessage('Unable to delete report.', 0, 0, message)
        this.setState({
          deleteModalState: false,
          selectedReport: {},
        })
        return false
      })
    return true
  }

  handleCancel = () => {
    this.setState({
      deleteModalState: false,
      selectedReport: {},
    })
  }

  render() {
    const {
      storage: {
        get: { CURRENT_TENANT: tenant },
      },
    } = this.context

    const { allData, scheduleData, showSlidingPage, when } = this.state
    return (
      <>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col style={{ display: 'flex', alignItems: 'center' }}>
            <ContentTitle
              title="Generated Reports"
              count={this.reportsLength}
            />
            {this.state.reportAddStateCheck && (
              <A10Tooltip
                title={'Report generation in progress.'}
                placement="right"
              >
                <A10Icon
                  style={{ marginLeft: '10px', color: '#0b608d' }}
                  type="sync"
                  spin
                />
              </A10Tooltip>
            )}
          </A10Col>
          <A10Col style={{ display: 'flex' }}>
            <A10Input.Search
              type="text"
              onChange={this.search}
              name="searchBox"
              value={this.state.searchString}
              placeholder="Search"
              style={{ marginRight: '6px' }}
            />
            <ActionButton
              text="Refresh"
              onClick={() => {
                this.loadReports()
              }}
              iconProps={{ app: 'global', type: 'refresh' }}
            />
            {this.isOperatorUser ? null : (
              <ActionButton
                text="Generate Report"
                onClick={() => {
                  this.generateReport()
                }}
                iconProps={{ app: 'global', type: 'add-new' }}
              />
            )}
          </A10Col>
        </ContentHeader>
        <ContentBody>
          <A10Table
            columns={this.reportColumns}
            pageSize={20}
            dataSource={this.state.allData.map((key: any, index: number) => {
              key.key = index
              if (key.bandwidth === undefined || key.requests === undefined) {
                // this.getStats(key)
                return key
              } else {
                return key
              }
            })}
            size="small"
            scroll={{ x: true }}
            loading={
              !this.dataLoaded
                ? {
                    indicator: (
                      <div>
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
          />
        </ContentBody>
        <A10Modal
          title="Delete Report"
          visible={this.state.deleteModalState}
          onOk={this.handleOk.bind(this, false)}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk.bind(this, false)}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            Do you want to delete this report from{' '}
            {tenant && tenant['display-name']}?
          </p>
        </A10Modal>
        {showSlidingPage && (
          <ReportFormActions
            show={showSlidingPage}
            loadReports={this.loadDelayedReports}
            when={when}
            oneTimeReport={true}
            onClose={this.setSlidingPage.bind(this, false)}
            generateData={allData}
            scheduleData={scheduleData}
            currentTab='generated'
          />
        )}
      </>
    )
  }
}

export default setupA10Container(ReportsList)
