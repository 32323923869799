import React from 'react'
import { Map } from 'immutable'
import moment from 'moment'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Row, A10Col, A10Icon } from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import PullUp from 'src/components/ADC/PullUp'
import PullUpAlert from '../PullUpAlert'
import Settings from 'src/containers/Controller/Dashboard/Settings'
import * as analysisUtils from 'src/containers/Controller/Dashboard/utils'
import * as basicUtils from 'src/containers/Controller/Dashboard/utils/basicUtils'
import { httpClient } from 'src/libraries/httpClient'
import { ContentHeader, ContentTitle } from 'src/components/shared/ContentSection'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

export interface IPullUpAlertViewerProps extends IA10ContainerDefaultProps {
  data: IObject
  alertContainer: IObject
  selectedTimePeriod: IObject
  selectedPullupTab: string
  currentStatus: string
  currentTab: string
  leftNavCollapsed: boolean
  alertStatus: number
  isAlertPage: boolean
}

export interface IPullUpAlertViewerState {
  currentTab: string
  searchValue: string
  apiFilters: IObject
  isLoadingWorkflow: boolean
  leftNavCollapsed: boolean
  updateTime: number
}

class PullUpAlertViewer extends A10Container<
  IPullUpAlertViewerProps,
  IPullUpAlertViewerState
> {
  state: IPullUpAlertViewerState = {
    currentTab: 'alert',
    searchValue: '',
    apiFilters: {},
    isLoadingWorkflow: false,
    leftNavCollapsed: false,
    updateTime: 0,
  }

  componentWillReceiveProps(nextProps: IPullUpAlertViewerProps) {
    const {
      selectedTimePeriod: nextPeriod,
      leftNavCollapsed,
      alertStatus: nextAlertStatus,
    } = nextProps
    const { selectedTimePeriod, alertStatus } = this.props
    if (analysisUtils.hasChangedTimePeriod(selectedTimePeriod, nextPeriod)) {
      this.getData(nextPeriod, undefined, true)
      this.getChartData(nextPeriod)
    }
    if (leftNavCollapsed !== this.state.leftNavCollapsed) {
      this.setState({ leftNavCollapsed })
    }
    // It should update when clicking by alert notification
    if (nextAlertStatus !== alertStatus) {
      this.update(nextPeriod)
    }
  }

  componentDidMount() {
    const { selectedTimePeriod } = this.props
    this.getData(selectedTimePeriod, undefined, true)
    this.getChartData(selectedTimePeriod)
  }

  componentWillUnmount() {
    this.onClose()
  }

  update = (period: IObject) => {
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    const { updateTime } = this.state
    const currentTime = new Date().getTime()
    // when alert notification page is opened, should query newest logs
    if (Math.abs(updateTime - currentTime) > 30 * 1000) {
      httpRequest({
        namespace: Settings.namespace.selectedDashboardTimeline,
        request: async () => {
          const { startTime: propStartTime } = period
          const { startTime, endTime } = Settings.getDynamicPeriod()
          this.setState({ updateTime: currentTime })
          return Map({
            startTime: propStartTime || startTime,
            endTime,
          })
        },
      })
    }
  }

  getData = (
    selectedTimePeriod: IObject,
    downloadPager: IObject = { page: 1, pageSize: 20 },
    isInit: boolean = false,
  ) => {
    const { searchValue, currentTab } = this.state
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    const promises: Array<Promise<any>> = []

    if (isInit || currentTab === 'alert') {
      const tenantUuids = basicUtils.getTenantUuids('|')
      if (tenantUuids) {
        promises.push(
          httpRequest({
            namespace: Settings.namespace.dashboardAlertPullupInfo,
            request: async () => {
              const pager: IObject = { ...downloadPager }
              // pager.searchValue = currentTab === 'alert' ? searchValue : ''
              pager.searchValue = searchValue
              const alertData = await analysisUtils.analysisAlerts(
                httpClient,
                selectedTimePeriod,
                pager,
                this.state.apiFilters,
              )
              return Map(alertData)
            },
          }),
        )
      }
    }

    return promises
  }

  /* istanbul ignore next */
  changeTab = (text: string, entry: IObject) => {
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    httpRequest({
      namespace: Settings.namespace.pullupViewCurrentTab,
      request: async () => {
        return await entry.text
      },
    })
  }

  onClose = () => {
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    httpRequest({
      namespace: Settings.namespace.pullupViewCurrentTab,
      request: async () => {
        return await 'alert'
      },
    })
    httpRequest({
      namespace: Settings.namespace.pullupViewCurrentStatus,
      request: async () => {
        return await 'min'
      },
    })
  }
  /* istanbul ignore next */
  onDownload = () => {
    const { selectedTimePeriod } = this.props
    const { currentTab } = this.state
    let csvContent = 'data:text/csv;charset=utf-8,'
    csvContent += this.getTableHeader()
    const promises = this.getData(selectedTimePeriod, {
      page: 0,
      pageSize: 2000,
    })
    if (currentTab === 'alert') {
      promises[0].then(resMap => {
        const res = resMap.toJS()
        csvContent += this.getAlertTableData(res)
        this.exportCSV(csvContent)
      })
    }
  }

  /* istanbul ignore next */
  getTableHeader = () => {
    const { currentTab } = this.state
    let csvContent = ''
    let tableHeaders = [
      [
        'Timestamp',
        'App Service Type',
        'App Service ID',
        'System Module',
        'Message',
      ],
    ]
    if (currentTab === 'alert') {
      tableHeaders = [
        ['Timestamp', 'Alert Name', 'Trigger', 'Condition', 'Recorded Value'],
      ]
    }
    tableHeaders.forEach(rowArray => {
      const row = rowArray.join(',')
      csvContent += row + '\r\n'
    })
    return csvContent
  }
  getAlertTableData = (res: IObject) => {
    const data = res.data || res.alerts
    let csvContent = ''
    if (!data) {
      return csvContent
    }
    data.forEach((item: IObject) => {
      const { alert = {}, trigger = {} } = item
      const rowArray = [
        moment(item.timestamp).format('MM/DD HH:mm:SS'),
        alert.name,
        trigger.name,
        trigger.condition,
        `${item.avg_control_cpu_usage_avg || 0} %`,
      ]
      csvContent += `${rowArray.join(',')}\r\n`
    })
    return csvContent
  }

  /* istanbul ignore next */
  getTableData = (res: IObject, tableData: string[]) => {
    const data = res.data || res.alerts
    let csvContent = ''
    if (!data) {
      return csvContent
    }
    data.forEach((item: IObject) => {
      const rowArray = tableData.map((fieldKey: string) => {
        if (fieldKey === 'ts') {
          return moment(item[fieldKey]).format('MM/DD HH:mm:SS')
        }
        return item[fieldKey]
      })
      const row = rowArray.join(',')
      csvContent += row + '\r\n'
    })
    return csvContent
  }
  /* istanbul ignore next */
  exportCSV = (csvContent: string) => {
    const blob = new Blob([csvContent])
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, 'export_data.csv')
    } else {
      const link = document.createElement('a')
      link.setAttribute('href', csvContent)
      link.setAttribute('download', 'export_data.csv')
      document.querySelector('body').appendChild(link)
      link.click()
      document.querySelector('body').removeChild(link)
    }
  }

  onChangeFilter = (
    filters: IObject[],
    filterStr: string,
    apiFilters?: IObject,
  ) => {
    const { currentTab } = this.state
    this.setState({ searchValue: filterStr, apiFilters }, () => {
      const { selectedTimePeriod } = this.props
      if (currentTab !== 'audit') {
        this.getData(selectedTimePeriod)
      }
    })
  }
  getChartData = async (selectedTimePeriod: IObject, cluster?: string) => {
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    const type = 'provider'
    let clusterId = ''
    if (cluster) {
      const clusters = storage.get.PROVIDER_CLUSTERS
      const clusterObj = clusters.filter(
        (item: IObject) => item.name === cluster,
      )
      if (clusterObj && clusterObj.length > 0) {
        clusterId = clusterObj[0].uuid
      }
    }
    httpRequest({
      namespace: Settings.namespace.stackChartData,
      request: async (epicDependence: IObject) => {
        const data = await analysisUtils.getStackChartCount(
          httpClient,
          selectedTimePeriod,
          type,
          clusterId,
        )
        return data
      },
    })
  }

  getLabels = () => {
    const { data, alertContainer } = this.props
    let alertCount = 0

    if (data.alertSeriesObj && this.state.searchValue === '') {
      alertCount = data.alertSeriesObj.count.total
    } else {
      alertCount = alertContainer.total
    }

    const labels: IObject[] = [
      { name: 'ALERTS', value: alertCount, text: 'alert', searchable: true },
    ]

    return labels
  }
  resetWorkflowTab = () => {
    const {
      A10Dispatchers: { httpRequest },
    } = this.props

    httpRequest({
      namespace: Settings.namespace.pullupViewCurrentTab,
      request: async () => {
        return 'alert'
      },
    })
    httpRequest({
      namespace: Settings.namespace.pullupViewCurrentStatus,
      request: async () => {
        return 'min'
      },
    })
  }
  getTotalAlerts = () => {
    const { alertContainer } = this.props
    return alertContainer.total || 0
  }

  isDashboard = () => {
    try {
      const isDashboardPage =
        (window.location.href || '').toLowerCase().includes('home/tenant')
      const isProviderDashboardPage =
        (window.location.href || '').toLowerCase().includes('home/provider')
      return isDashboardPage || isProviderDashboardPage
    } catch (err) {
      return false
    }
  }

  render() {
    if (this.isDashboard()) {
      return null
    }
    const { currentStatus, currentTab } = this.props
    if (currentStatus === 'min' || currentTab !== 'alert') {
      return null
    }
    const AlertHeader = (props: IObject) => {
      return (
        <div className={styles.workflowTabHeader}>
          <div className={styles.workflowTab}>
            <span className={styles.workflowLabel}>Alert</span>
            <span className={styles.workflowCount}>
              {this.getTotalAlerts() || 0}
            </span>
          </div>
          <div className={styles.searchBox} />
          <div
            className={styles.closeButton}
            onClick={props.onClickClose || _.noop}
          >
            <A10Icon className={styles.closeButtonIcon} type="close" />
          </div>
        </div>
      )
    }
    const { isAlertPage } = this.props
    return (
      <div className={styles.workflowTabContainer}>
        {isAlertPage ? (
          <ContentHeader type="flex" align="middle" justify="space-between">
            <ContentTitle
              title="Alerts"
              count={this.getTotalAlerts() || 0}
            />
          </ContentHeader>
        ) : (
          <PullUp
            minHeight={60}
            maxHeight={document.body.clientHeight - 150}
            minPositionY={68}
            header={
              <AlertHeader
                onClickClose={this.resetWorkflowTab.bind(this, false)}
              />
            }
            leftNavCollapsed={this.state.leftNavCollapsed}
          />
        )}
        <A10Row className={styles.pullUpMainPanel}>
          <A10Col span={24}>
            <div className={styles.dashboardPanel}>
              {this.state.currentTab === 'alert' && (
                <PullUpAlert
                  searchValue={this.state.searchValue}
                  onChangeFilter={this.onChangeFilter}
                />
              )}
            </div>
          </A10Col>
        </A10Row>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    data: state.A10Data.getIn(Settings.namespace.stackChartData, Map({})),
    alertContainer: state.A10Data.getIn(
      Settings.namespace.dashboardAlertPullupInfo,
      Map({}),
    ).toJS(),
    selectedTimePeriod: state.A10Data.getIn(
      Settings.namespace.selectedDashboardTimeline,
      Map(Settings.rangePeriod),
    ).toJS(),
    currentStatus: state.A10Data.getIn(
      Settings.namespace.pullupViewCurrentStatus,
      null,
    ),
    currentTab: state.A10Data.getIn(
      Settings.namespace.pullupViewCurrentTab,
      null,
    ),
    alertStatus: state.A10Data.getIn(
      Settings.namespace.alertPullUpViewStatus,
      '',
    ),
  }
}

export default setupA10Container(PullUpAlertViewer, mapStateToProps)
