import React from 'react'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Row,
  A10Col,
  A10Form,
  A10Select,
  A10InputNumber,
  A10Alert,
  A10Tooltip,
} from '@gui-libraries/widgets'

import { HealthStatus } from 'src/components/ADC/HealthStatus'
import storage from 'src/libraries/storage'
import { Messages } from 'src/locale/en/Messages'
import {
  InfrastructureService,
  DashboardService,
  InfraStructureStatsService,
} from 'src/services/'
import { IDefaultMethods } from 'src/containers/Controller'
import Utilities from 'src/services/Utilities/Utilities'

import styles from './styles/index.module.less'

const DEVICE_LICENSE = 'DEVICE_LICENSE'
const EXTERNAL_DEVICE_LICENSE = 'EXTERNAL_DEVICE_LICENSE'
const PERPETUAL_LICENSE = 'PERPETUAL_LICENSE'
const UNLICENSED = 'UNLICENSED'
const ACTIVATED = 'ACTIVATED'

export interface IDeviceLicenseFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  form: any
  formData: any[]
  cluster: any
  onRef?: any
  updateMode?: boolean
  onSubmitForm?: (data: any) => void
  handleChange?: (data: any) => void
}
export interface IDeviceLicenseFormState {
  devices: any
  licenseList: IObject[]
}

class DeviceLicenseForm extends A10Container<
  IDeviceLicenseFormProps,
  IDeviceLicenseFormState
> {
  Messages = new Messages()
  InfrastructureService = new InfrastructureService()
  InfraStructureStatsService = new InfraStructureStatsService()
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  devPassCount = 0
  devCount = 0
  defaultFormData: any
  constructor(props: any) {
    super(props)

    this.state = {
      devices: props.updateMode
        ? props.formData
        : this.initDevices(props.formData),
      licenseList: [],
    }
  }

  componentDidMount() {
    this.getLicenseList()
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  componentDidUpdate(
    prevProps: IDeviceLicenseFormProps,
    prevState: IDeviceLicenseFormState,
  ) {
    if (prevState.licenseList !== this.state.licenseList) {
      if (!this.props.updateMode) {
        this.validateDevices()
      } else {
        this.formatData()
      }
    }
  }

  initDevices = (devices: IObject[]) => {
    let _devices = [...devices]
    _devices = _devices.map((_device: any) => {
      delete _device.licenseAuth
      delete _device.validation
      _device['validationMsg'] = ''
      return _device
    })
    this.props.handleChange(_devices)
    return _devices
  }

  formatLicenseDisplayedName = (licenseObj: IObject) => {
    const { licenseList } = this.state
    const { 'entitlement-key': entitlementKey, licenseType } = licenseObj
    const currentLicenseInfo = licenseList.find((license: Object) => {
      return license['entitlement-key'] === entitlementKey
    })
    const _licenseName = currentLicenseInfo
      ? ` - ${currentLicenseInfo['license-name']}`
      : ''
    const _entitlementKey = entitlementKey ? ` - ${entitlementKey}` : ''

    switch (licenseType) {
      case DEVICE_LICENSE: {
        return currentLicenseInfo && entitlementKey
          ? `Licensed${_entitlementKey}${_licenseName}`
          : `External Licensed${_entitlementKey}${_licenseName}`
      }
      case EXTERNAL_DEVICE_LICENSE: {
        return `External Licensed${_entitlementKey}${_licenseName}`
      }
      case PERPETUAL_LICENSE: {
        return entitlementKey
          ? `Licensed - ${entitlementKey}`
          : 'Licensed - Hardware'
      }
      default: {
        return ''
      }
    }
  }

  formatData = () => {
    const { licenseList, devices } = this.state
    const _devices = devices.map((device: IObject, i: number) => {
      const licenseObj = device.licenseObj ? JSON.parse(device.licenseObj) : {}
      const { 'entitlement-key': entitlementKey, licenseType } = licenseObj
      const [[licenseInfo], restLicensesInfo] = _.partition(licenseList, {
        'entitlement-key': entitlementKey,
      })
      if (licenseInfo) {
        device.licenseObj = JSON.stringify({
          ...licenseObj,
          licenseState: ACTIVATED,
          'license-type': licenseInfo['license-type'],
          'default-allocated-bandwidth':
            licenseInfo['default-allocated-bandwidth'],
          'max-allocated-bandwidth': licenseInfo['max-allocated-bandwidth'],
          'remaining-bandwidth': licenseInfo['remaining-bandwidth'],
          'min-allocated-bandwidth': licenseInfo['min-allocated-bandwidth'],
          internal: !!licenseInfo,
        })
      }

      // used to determine if user is allowed to update license
      if (
        (!licenseType && !entitlementKey) ||
        licenseObj.licenseState === 'NOT_AVAILABLE'
      ) {
        device.licenseType = UNLICENSED
      } else {
        device.licenseType = licenseType
      }

      device.currentOption = null
      if (device.licenseType !== UNLICENSED) {
        device.currentOption = {
          licenseDisplayedName: this.formatLicenseDisplayedName(licenseObj),
          licenseObj: device.licenseObj,
        }
      }
      this.props.form.setFieldsValue({
        [`licenseObj[${i}]`]: device.currentOption?.licenseObj || undefined,
      })

      device.licenseOptions = restLicensesInfo.map((license: IObject) => {
        if (
          !(
            device.platform === 'cThunder' &&
            license['license-type'] === 'SUBSCRIBER_FLEXPOOL'
          )
        ) {
          return {
            licenseDisplayedName: `${license['entitlement-key']}${
              license['license-name'] ? ` - ${license['license-name']}` : ''
            }`,
            licenseObj: JSON.stringify({
              'entitlement-key': license['entitlement-key'],
              'license-type': license['license-type'],
              licenseType: 'DEVICE_LICENSE',
              'license-state': license['license-state'],
              'default-allocated-bandwidth':
                license['default-allocated-bandwidth'],
              'max-allocated-bandwidth': license['max-allocated-bandwidth'],
              'remaining-bandwidth': license['remaining-bandwidth'],
              'min-allocated-bandwidth': license['min-allocated-bandwidth'],
              internal: true,
            }),
          }
        }
      })
      return device
    })

    this.setState({
      devices: _devices,
    })
  }

  getLicenseList = async () => {
    try {
      const res = await this.DashboardService.getLicenseInfo(
        null,
        null,
        storage.get.PROVIDER,
      )
      if (res?.data) {
        const licenseList = res.data
        const availableLicenseList = this.Utilities.getAvailableLicenseForReassign(
          licenseList,
          '',
          'DEVICE_LICENSE',
        )
        this.setState({
          licenseList: availableLicenseList,
        })
      }
    } catch (e) {
      console.log(`Error in DeviceLicenseForm: ${e}`)
    }
  }

  validateDevices = () => {
    const validateDeviceReqs: any[] = []
    const { devices } = this.state
    const { cluster = JSON.parse(devices[0].cluster) } = this.props
    this.devCount = 0

    devices.forEach((device: IObject) => {
      if (device.deviceName !== '') {
        const payload = {
          device: {
            host: device.thunderIPAddress,
            port: device.port ? parseInt(device.port) : 443,
            username: device.userName,
            password: device.userPassword,
            type: cluster.type,
            'data-plane-ha': cluster.dataPlaneHA || cluster['data-plane-ha'],
          },
        }
        const validateDevice = this.InfrastructureService.validateDeviceForAdd(
          null,
          payload,
          [storage.get.PROVIDER, device.deviceName],
        )

        validateDeviceReqs.push(
          validateDevice.catch((error: any) => {
            console.log('error in validate device call', error)
          }),
        )
        this.devCount++
      }
    })

    Promise.all(validateDeviceReqs)
      .then((respArr: any) => {
        let _devices = [...this.state.devices]
        this.devPassCount = 0
        respArr &&
          respArr.map((resp: any) => {
            const reqDev = resp?.config?.data
              ? JSON.parse(resp.config.data || '{}')
              : {}
            const status = resp?.data?.status || false
            const msg = resp?.data?.msg || ''
            const dataPlaneHA =
              resp?.data && resp.data['data-plane-ha']
                ? resp.data['data-plane-ha']
                : ''
            _devices.map((_device: IObject) => {
              if (reqDev.device.host === _device.thunderIPAddress) {
                _device['validation'] = status
                _device['validationMsg'] = msg
                _device['data-plane-ha'] = dataPlaneHA
                if (status) {
                  this.devPassCount++
                }
              }
            })
          })
        return this.devPassCount === this.devCount
          ? this.checkDeviceLicense(_devices)
          : Promise.resolve(_devices)
      })
      .then(devices => {
        this.setState({ devices }, () => {
          this.formatData()
          this.props.handleChange(devices)
        })
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  checkDeviceLicense = async (devices: IObject[]) => {
    const { licenseList } = this.state
    let _devices = [...devices]

    for (const device of _devices) {
      if (device.deviceName !== '') {
        const payload = {
          device: {
            host: device.thunderIPAddress,
            username: new Buffer(device.userName).toString(),
            password: new Buffer(device.userPassword).toString(),
            port: device.port ? parseInt(device.port) : 443,
          },
        }
        try {
          const res = await this.InfrastructureService.checkDeviceLicense(
            null,
            payload,
            [storage.get.PROVIDER, device.deviceName],
          )
          device['licenseCheck'] = true
          device['licenseToken'] = ''
          device['numBandwidth'] = ''
          device['bandwidthUnit'] = ''
          device['deviceVersion'] = res?.data?.version || ''
          device['platform'] = res?.data?.platform || ''

          if (res?.data?.['license-type']) {
            let licenseType = res.data['license-type'],
              licenseState = ''

            switch (licenseType) {
              case DEVICE_LICENSE: {
                if (res.data.bandwidth) {
                  const bandwidth = this.Utilities.megaToGiga(
                    res.data.bandwidth || 0,
                  )
                  device['numBandwidth'] = bandwidth.val
                  device['bandwidthUnit'] = bandwidth.unit
                }
                if (res.data.token) {
                  device['licenseToken'] = res.data.token
                  const isInternal = licenseList.some((obj: any) => {
                    return obj['entitlement-key'] === res.data.token
                  })

                  if (isInternal) {
                    licenseType = DEVICE_LICENSE
                    licenseState = ACTIVATED
                  } else {
                    licenseType = EXTERNAL_DEVICE_LICENSE
                    licenseState = ACTIVATED
                  }
                } else {
                  licenseType = EXTERNAL_DEVICE_LICENSE
                  licenseState = ACTIVATED
                }
                break
              }
              case PERPETUAL_LICENSE: {
                if (res.data.bandwidth) {
                  const bandwidth = this.Utilities.megaToGiga(
                    res.data.bandwidth || 0,
                  )
                  device['numBandwidth'] = bandwidth.val
                  device['bandwidthUnit'] = bandwidth.unit
                }
                device['licenseToken'] = res.data?.token || res.data?.platform
                licenseState = ACTIVATED
                break
              }
              default: {
                break
              }
            }
            device.licenseObj = JSON.stringify({
              'entitlement-key': device['licenseToken'],
              licenseState,
              licenseType,
            })
          }

          device['licenseAuth'] = true
        } catch (err) {
          device['licenseCheck'] = true
          device['licenseAuth'] = false
          device['licenseToken'] = ''
          device['numBandwidth'] = ''
          device['bandwidthUnit'] = ''

          const msg = err?.response?.data?.message || ''
          console.log(
            'Error: ' + (msg ? msg : 'Unable to get the device license info'),
            0,
            0,
          )
        }
      }
    }
    return _devices
  }

  handleChange = (stateName: string, value: any, index: number) => {
    if (!this.Utilities.validateField(value)) {
      return
    }
    const {
      form: { setFieldsValue },
    } = this.props
    const devices = [...this.state['devices']]

    if (stateName === 'numBandwidth') {
      devices[index].numBandwidth = value
    }

    if (stateName === 'bandwidthUnit') {
      let bandwidth = devices[index].numBandwidth
      if (value === 'g') {
        if (bandwidth < 1000) {
          bandwidth = bandwidth / 1000
          bandwidth = parseFloat(bandwidth.toFixed(2))
        } else {
          bandwidth = this.Utilities.megaToGiga(bandwidth).val
        }
      } else {
        bandwidth = bandwidth * 1000
      }
      devices[index].bandwidthUnit = value
      devices[index].numBandwidth = bandwidth
      this.props.form.setFieldsValue({
        [`numBandwidth[${index}]`]: bandwidth,
      })
    }

    if (stateName === 'licenseObj') {
      let bandwidth = { val: 0, unit: undefined }
      const licObj = value ? JSON.parse(value) : {}
      if (devices[index][stateName] !== undefined) {
        bandwidth = this.Utilities.megaToGiga(
          licObj['license-type'] === 'INSTANCE_FLEXPOOL' ||
            licObj['license-type'] === 'SUBSCRIBER_FLEXPOOL'
            ? licObj['default-allocated-bandwidth'] == 0 &&
              licObj['license-type'] === 'SUBSCRIBER_FLEXPOOL'
              ? 2
              : licObj['default-allocated-bandwidth']
            : licObj['remaining-bandwidth'],
        )
      }
      setFieldsValue({
        [`numBandwidth[${index}]`]:
          (licObj && licObj['license-type'] === 'INSTANCE_FLEXPOOL') ||
          licObj['license-type'] === 'SUBSCRIBER_FLEXPOOL'
            ? bandwidth.val == 0 &&
              licObj['license-type'] === 'SUBSCRIBER_FLEXPOOL'
              ? 2
              : bandwidth.val
            : undefined,
      })
      setFieldsValue({ [`bandwidthUnit[${index}]`]: bandwidth.unit })
      devices[index].numBandwidth =
        (licObj && licObj['license-type'] === 'INSTANCE_FLEXPOOL') ||
        licObj['license-type'] === 'SUBSCRIBER_FLEXPOOL'
          ? bandwidth.val == 0 &&
            licObj['license-type'] === 'SUBSCRIBER_FLEXPOOL'
            ? 2
            : bandwidth.val
          : ''

      devices[index].bandwidthUnit = bandwidth.unit
      devices[index].licenseObj = value
    }

    this.props.handleChange(devices)
  }

  render() {
    const { updateMode } = this.props
    const { getFieldDecorator } = this.props.form
    const { devices } = this.state
    const cluster = this.props.cluster
      ? this.props.cluster
      : JSON.parse(devices[0].cluster)
    let devAuthFail = '',
      validationFail = ''
    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <div className="provision-panel">
          <div className="panel-header">
            <div className="title">
              <div className="row">
                <span
                  className="col-md-6 truncate"
                  title={
                    'Cluster - ' +
                    (cluster['display-name'] ||
                      cluster.clusterName ||
                      cluster.name)
                  }
                >
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                  {'Cluster - ' +
                    (cluster['display-name'] ||
                      cluster.clusterName ||
                      cluster.name)}
                </span>
              </div>
            </div>
          </div>
          <div className="panel-body" style={{ padding: '30px' }}>
            <A10Row
              style={{ paddingBottom: '10px', fontWeight: 'bold' }}
              justify={'center'}
              gutter={16}
            >
              <A10Col span={6}>
                <span className={styles.tableMainField}>Device</span>
              </A10Col>
              <A10Col span={4}>
                <span className={styles.tableMainField}>Device Version</span>
              </A10Col>
              <A10Col span={4}>
                <span className={styles.tableMainField}>License Key</span>
              </A10Col>
              <A10Col span={10}>
                <A10Col span={24}>
                  <span className={styles.tableMainField}>Bandwidth</span>
                </A10Col>
                <A10Col span={8}>Available</A10Col>
                <A10Col span={8}>Allocate</A10Col>
              </A10Col>
            </A10Row>
            {devices &&
              devices.map((device: any, i: number) => {
                if (device.deviceName !== '') {
                  if (device.licenseAuth === false) {
                    devAuthFail +=
                      (devAuthFail.length > 0 ? ', ' : '') + device.deviceName
                  }
                  if (device.validation === false) {
                    validationFail +=
                      (validationFail.length > 0 ? ', ' : '') +
                      device.deviceName
                  }
                  const {
                    numBandwidth,
                    bandwidthUnit,
                    enableUpdate,
                    licenseType: currentLicenseType,
                    licenseOptions,
                    currentOption,
                  } = device
                  const licenseObj = device.licenseObj
                    ? JSON.parse(device.licenseObj)
                    : undefined
                  const maxAllocated =
                    licenseObj?.['max-allocated-bandwidth'] || 0
                  const defaultAllocated =
                    licenseObj?.['default-allocated-bandwidth'] || 0
                  let remaining = licenseObj?.['remaining-bandwidth'] || 0
                  let maxVal = !licenseObj
                    ? 0
                    : ((maxAllocated > remaining || maxAllocated === 0) &&
                        licenseObj['license-type'] !== 'INSTANCE_FLEXPOOL') ||
                      licenseObj['license-type'] !== 'SUBSCRIBER_FLEXPOOL'
                    ? remaining
                    : licenseObj['license-type'] === 'INSTANCE_FLEXPOOL' ||
                      licenseObj['license-type'] === 'SUBSCRIBER_FLEXPOOL'
                    ? defaultAllocated
                    : maxAllocated
                  let minVal = licenseObj?.['min-allocated-bandwidth'] || 0

                  const bandwidthObj = this.Utilities.megaToGiga(maxVal || 0)
                  if (bandwidthUnit === 'g') {
                    // use 1000 here because megaToGiga function use 1000 as well
                    if (maxVal < 1000) {
                      maxVal = maxVal / 1000
                      maxVal = parseFloat(maxVal.toFixed(2))
                    } else {
                      maxVal = this.Utilities.megaToGiga(maxVal).val
                    }
                    if (minVal < 1000) {
                      minVal = minVal / 1000
                      minVal = parseFloat(minVal.toFixed(2))
                    } else {
                      minVal = this.Utilities.megaToGiga(minVal).val
                    }
                    if (remaining < 1000) {
                      remaining = remaining / 1000
                      remaining = parseFloat(remaining.toFixed(2))
                    } else {
                      remaining = this.Utilities.megaToGiga(remaining).val
                    }
                  }

                  const devStatus =
                    device.validation === undefined
                      ? 'undefined'
                      : device.validation
                      ? 'ongoing'
                      : 'stopped'

                  const iconType =
                    devStatus === 'ongoing' ? 'info-circle' : 'warning'

                  let disableLicenseSelect = true
                  let disableLicenseBandwidthChange = true
                  if (updateMode) {
                    disableLicenseSelect =
                      !enableUpdate || currentLicenseType === PERPETUAL_LICENSE
                    disableLicenseBandwidthChange =
                      disableLicenseSelect || !licenseObj?.internal
                  } else {
                    disableLicenseSelect =
                      validationFail.length > 0 ||
                      this.devPassCount !== this.devCount ||
                      currentLicenseType !== UNLICENSED // user is only allowed to change license of unlicensed device when in adding mode
                    disableLicenseBandwidthChange =
                      disableLicenseSelect || !licenseObj?.internal
                  }

                  return (
                    <A10Row gutter={16} key={'row-' + i}>
                      <A10Col
                        span={6}
                        key={'col1-' + i}
                        style={{ paddingTop: '10px' }}
                        className="truncate"
                        title={device.deviceName}
                      >
                        <>
                          <span className="hs-text">
                            <HealthStatus
                              type={devStatus}
                              text="D"
                              tooltip={
                                device.validation === undefined
                                  ? 'Device validation in progress'
                                  : ''
                              }
                              hideTooltip={device.validation !== undefined}
                            />
                          </span>
                          <span className="hs-text">{device.deviceName}</span>
                          {devStatus !== 'undefined' && device.validationMsg ? (
                            <A10Tooltip title={device.validationMsg}>
                              <A10Icon
                                type={iconType}
                                style={{
                                  strokeWidth: 2,
                                  marginLeft: '5px',
                                  color:
                                    iconType === 'warning' ? 'red' : '#64b5f6',
                                }}
                              ></A10Icon>
                            </A10Tooltip>
                          ) : null}
                        </>
                      </A10Col>
                      <A10Col
                        span={4}
                        key={'col2-' + i}
                        style={{ paddingTop: '10px' }}
                        className="truncate"
                        title={device.deviceVersion}
                      >
                        {device.deviceVersion}
                      </A10Col>
                      <A10Col span={4} key={'col3-' + i}>
                        <A10Form.Item>
                          {getFieldDecorator(`licenseObj[${i}]`)(
                            <A10Select
                              showSearch={true}
                              placeholder="Please select"
                              onChange={(value: any) =>
                                this.handleChange('licenseObj', value, i)
                              }
                              allowClear
                              disabled={disableLicenseSelect}
                            >
                              {currentOption && (
                                <A10Select.Option
                                  key={currentOption.licenseDisplayedName}
                                  value={currentOption.licenseObj}
                                >
                                  {currentOption.licenseDisplayedName}
                                </A10Select.Option>
                              )}
                              {licenseOptions &&
                                licenseOptions.map((option: IObject) => {
                                  if (option) {
                                    return (
                                      <A10Select.Option
                                        key={option.licenseDisplayedName}
                                        value={option.licenseObj}
                                        title={option.licenseDisplayedName}
                                      >
                                        {option.licenseDisplayedName}
                                      </A10Select.Option>
                                    )
                                  }
                                })}
                            </A10Select>,
                          )}
                        </A10Form.Item>
                      </A10Col>
                      <A10Col span={10} key={'col4-' + i}>
                        <A10Col span={8} style={{ padding: '10px 8px 0 8px' }}>
                          <span>
                            {licenseObj?.['license-type'] !==
                              'INSTANCE_FLEXPOOL' && remaining > 0
                              ? bandwidthObj.val +
                                (bandwidthObj.unit === 'm' ? 'M' : 'G') +
                                'bps'
                              : '-'}
                          </span>
                        </A10Col>
                        <A10Col span={8}>
                          <A10Form.Item>
                            {getFieldDecorator(`numBandwidth[${i}]`, {
                              rules: [
                                {
                                  required:
                                    disableLicenseBandwidthChange ||
                                    licenseObj?.['license-type'] ===
                                      'INSTANCE_FLEXPOOL' ||
                                    licenseObj?.['license-type'] ===
                                      'SUBSCRIBER_FLEXPOOL'
                                      ? false
                                      : true,
                                  message: this.Messages.BANDWIDTH_REQUIRED,
                                },
                              ],
                              initialValue: numBandwidth || minVal || 0,
                            })(
                              <A10InputNumber
                                type="number"
                                step={maxVal > 20 ? 1 : maxVal > 5 ? 0.1 : 0.01}
                                min={minVal}
                                max={maxVal}
                                onChange={(e: any) =>
                                  this.handleChange('numBandwidth', e, i)
                                }
                                style={{ fontSize: '12px', width: '90%' }}
                                disabled={
                                  disableLicenseBandwidthChange ||
                                  licenseObj?.['license-type'] ===
                                    'INSTANCE_FLEXPOOL' ||
                                  licenseObj?.['license-type'] ===
                                    'SUBSCRIBER_FLEXPOOL'
                                }
                              />,
                            )}
                          </A10Form.Item>
                        </A10Col>
                        <A10Col span={8}>
                          <A10Form.Item>
                            {getFieldDecorator(`bandwidthUnit[${i}]`, {
                              rules: [
                                {
                                  required:
                                    disableLicenseBandwidthChange ||
                                    licenseObj?.['license-type'] ===
                                      'INSTANCE_FLEXPOOL' ||
                                    licenseObj?.['license-type'] ===
                                      'SUBSCRIBER_FLEXPOOL'
                                      ? false
                                      : true,
                                  message: this.Messages
                                    .BANDWIDTH_UNIT_REQUIRED,
                                },
                              ],
                              initialValue: bandwidthUnit
                                ? bandwidthUnit
                                : undefined,
                            })(
                              <A10Select
                                onChange={(e: any) =>
                                  this.handleChange('bandwidthUnit', e, i)
                                }
                                placeholder="Unit"
                                disabled={
                                  disableLicenseBandwidthChange ||
                                  licenseObj?.['license-type'] ===
                                    'INSTANCE_FLEXPOOL' ||
                                  licenseObj?.['license-type'] ===
                                    'SUBSCRIBER_FLEXPOOL'
                                }
                              >
                                <A10Select.Option key="m" value="m">
                                  Mbps
                                </A10Select.Option>
                                <A10Select.Option key="g" value="g">
                                  Gbps
                                </A10Select.Option>
                              </A10Select>,
                            )}
                          </A10Form.Item>
                        </A10Col>
                      </A10Col>
                    </A10Row>
                  )
                } else {
                  return null
                }
              })}

            {validationFail.length > 0 ? (
              <A10Row>
                <A10Alert
                  showIcon={true}
                  style={{ margin: '0px 8px 5px 8px' }}
                  message="Validation Failed"
                  description={
                    <>
                      Device validation failed for the device(s) -{' '}
                      {validationFail}. Try again after fixing the device
                      validation errors shown on hover of icon beside device
                      name.
                    </>
                  }
                  type="error"
                />
              </A10Row>
            ) : null}

            {devAuthFail.length > 0 ? (
              <A10Row>
                <A10Alert
                  showIcon={true}
                  style={{ margin: '0px 8px 5px 8px' }}
                  message="Authentication Failed"
                  description={
                    <>
                      Device authentication failed for the device(s) -{' '}
                      {devAuthFail}. Please check the details entered and try
                      again.
                    </>
                  }
                  type="error"
                />
              </A10Row>
            ) : null}

            {cluster?.type &&
            cluster.type === 'vcs' &&
            !devices[0].licenseToken &&
            !!devices[0].licenseObj ? (
              <A10Row>
                <A10Alert
                  showIcon={true}
                  style={{ margin: '0px 8px 5px 8px' }}
                  message=""
                  description="Same amount of bandwidth will be used for each vBlade in this VCS cluster."
                  type="info"
                />
              </A10Row>
            ) : null}

            {this.devPassCount > 0 ? (
              <A10Row>
                <A10Alert
                  showIcon={true}
                  style={{ margin: '0px 8px 5px 8px' }}
                  message=""
                  description="Additional information/help about each device registration is available on hover over the info icon after device name in listing above."
                  type="info"
                />
              </A10Row>
            ) : null}

            {this.state.licenseList.length === 0 ? (
              <A10Row>
                <A10Alert
                  showIcon={true}
                  style={{ margin: '0px 8px 5px 8px' }}
                  message=""
                  description="There are no Flexpool licenses in the provider account."
                  type="info"
                />
              </A10Row>
            ) : null}
          </div>
        </div>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(DeviceLicenseForm))
