import React from 'react'
import propTypes from 'prop-types'
import { Map } from 'immutable'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import { A10Col, A10Input, A10SlidingPage } from '@gui-libraries/widgets'
import { AutoForm } from '@gui-libraries/apps'

import LogicalPartitionList from './LogicalPartitionList'
import storage from 'src/libraries/storage'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { DashboardService } from 'src/services/DashboardService'

import styles from './styles/index.module.less'


export interface IPartitionProps extends IA10ContainerDefaultProps {
  tenant: IObject
  total: number
}

export interface IPartitionState {
  isLoading: boolean
  formComponent: JSX.Element | null
  modalSize?: string
  search: string
  data: IObject[]
  filteredData: IObject[]
}

class LogicalPartition extends A10Container<IPartitionProps, IPartitionState> {
  static childContextTypes = {
    openSlidingPage: propTypes.func.isRequired,
    closeSlidingPage: propTypes.func.isRequired,
    refreshLogicalPartition: propTypes.func.isRequired,
  }

  DashboardService = new DashboardService()

  constructor(props: IPartitionProps) {
    super(props)

    const {
      get: { PROVIDER },
      set,
    } = storage
    if (!PROVIDER) {
      set.PROVIDER('root')
    }
    this.state = {
      formComponent: null,
      isLoading: false,
      modalSize: '',
      search: '',
      data: [],
      filteredData: []
    }
  }

  getChildContext() {
    return {
      openSlidingPage: this.openSlidingPage,
      closeSlidingPage: this.closeSlidingPage,
      refreshLogicalPartition: this.refreshLogicalPartition,
    }
  }

  componentDidUpdate(preProps: IPartitionProps) {
    if (preProps.tenant.name !== this.props.tenant.name) {
      this.getData()
    }
  }

  componentDidMount() {
    this.getData()
  }

  openSlidingPage = (formComponent: JSX.Element, modalSize?: string) => {
    this.setState({ formComponent, modalSize })
  }
  setModalSize = (modalSize: string) => {
    this.setState({ modalSize })
  }
  closeSlidingPage = () => {
    this.setState({ formComponent: null, modalSize: '' })
  }

  getData = async() => {
    // Start to load data
    this.setState({ isLoading: true })
    const { tenant } = this.props
    try{
      const logicalPartitionData = await this.DashboardService.getLogicalPartitionListSummary(null, null, [
        storage.get.PROVIDER,
        tenant.name,
      ])
      const lpData = logicalPartitionData?.data?.['logical-partition-list'] ?? [] ;
      this.setState({ data: lpData, filteredData: lpData})
    }catch(e){

    } finally{
      this.setState({ isLoading: false })
    }
  }

  onSearchText = (e: any) => {
    const searchString = e.target.value

    this.setState({ search: searchString }, () => {
      this.delayedSearchText(searchString)
    })
   
  }
  delayedSearchText = (searchStr : string = '') => {
    const lpData = this.state.data?.filter(item => item['name']?.toString().toLowerCase().indexOf(searchStr?.toLocaleLowerCase()) > -1);
    this.setState({ filteredData: lpData})
  }

  refreshLogicalPartition = () => {
    this.getData()
  }

  setSlidingPage = (isOpen: boolean) => {
    const {
      get: { PROVIDER },
    } = storage

    const { tenant } = this.props
    const tenantName = tenant.name || ''
    const defaultParams: IObject = {
      provider: PROVIDER,
      tenant: tenantName,
    }
    const apiPrefix = `/hpcapi/v3/provider/${PROVIDER}/tenant/${tenantName}/`
    this.openSlidingPage(
      isOpen ? (
        <AutoForm
          hccEnv={true}
          schemaPath="config/form/provider.tenant.logical-partition"
          apiPrefix={apiPrefix}
          params={defaultParams}
          interceptor={{
            onSubmitSuccess: async (
              sformUtils: any,
              response: any,
              name: any,
              formData: Map<string, any>,
            ) => {
              this.setSlidingPage(false)
              // await onSubmitSuccess(sformUtils, response, name, formData)
              this.onFormSubmitted()
            },
            onCancel: this.onCloseSlidingPage,
          }}
        />
      ) : null,
    )
  }

  onCloseSlidingPage = () => {
    this.setSlidingPage(false)
  }

  onFormSubmitted = () => {
    this.setState({ search: '' }, () => {
      this.getData()
    })
  }

  render() {
    const {
      isLoading,
      formComponent,
      modalSize,
      start,
      count,
      search,
    } = this.state
    const { tenant, total } = this.props
    const tenantName = tenant.name || ''
    return (
      <>
        <ContentSection>
          <ContentHeader type="flex" align="middle" justify="space-between">
            <A10Col>
              <ContentTitle title="Logical Partitions" count={total} />
            </A10Col>
            <A10Col className={styles.actionsContainer}>
              <A10Input.Search
                type="text"
                onChange={this.onSearchText}
                name="searchBox"
                value={this.state.search}
                placeholder="Search"
                className={styles.searchInput}
              />
              {/* HARMONY-22008 - Hiding Add LP
              {getItem('IS_OPERATOR_USER') ? null : (
                <ActionButton
                text="Add Logical Partition"
                onClick={this.setSlidingPage.bind(this, true)}
                iconProps={{ app: 'global', type: 'add-new' }}
              />
              )} */}
            </A10Col>
          </ContentHeader>
          <ContentBody>
            <LogicalPartitionList
              tenant={tenantName}
              updateList={this.getData}
              isLoading={isLoading}
              start={start}
              count={count}
              search={search}
              filteredData={this.state.filteredData}
            />
          </ContentBody>
        </ContentSection>
        <A10SlidingPage
          isOpen={!!formComponent}
          modalSize={modalSize}
          onRequestClose={this.closeSlidingPage}
        >
          {formComponent && <formComponent.type {...formComponent.props} />}
        </A10SlidingPage>
      </>
    )
  }
}

function mapStateToProps(state: any) {
}

export default setupA10Container(LogicalPartition, mapStateToProps)
