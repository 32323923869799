import React from 'react'
import {
  A10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { IFormData } from './index'
import storage from 'src/libraries/storage'
import LicenseEditForm from './LicenseEditForm'
import globalConfig from 'src/settings/config'
import { DashboardService, Utilities } from 'src/services'

export interface ISlidingLicenseEditProps extends IA10ContainerDefaultProps {
  isOpen: boolean
  onRequestClose: () => void
  formData: IFormData
  licenseInfo: any
}

export interface ISlidingLicenseEditStates {
  provider: string
  formData?: IFormData
}

class SlidingLicenseEdit extends A10Container<
  ISlidingLicenseEditProps,
  ISlidingLicenseEditStates
> {
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  child: any
  updatedValues: any
  constructor(props: ISlidingLicenseEditProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER,
      formData: this.props.formData,
    }
    this.child = React.createRef()
    this.getUpdatedValues = this.getUpdatedValues.bind(this)
  }

  onFormChange(data: IFormData, updatedData?: any) {
    const state = { ...this.state }
    state.formData = data
    if (updatedData) {
      if (updatedData.updatedNumBandwidth) {
        state.formData.numBandwidth = updatedData.updatedNumBandwidth
      }
      if (updatedData.updatedNumDevices) {
        state.formData.numDevices = updatedData.updatedNumDevices
      }
    }
    const that = this
    this.setState(state, () => {
      that.saveUploadSlidingPage()
    })
  }

  getUpdatedValues() {
    this.child.sendUpdatedValues()
  }

  importLicenseUpdate = () => {
    const importLicense = this.DashboardService.importOfflineLicense(
      null,
      this.state.formData.activationKey,
      [storage.get.PROVIDER, this.state.formData.entitleToken],
      true,
    )
    importLicense
      .then((resp: any) => {
        if (resp) {
          this.Utilities.showMessage(
            'Update request submitted successfully, please check the status in activity log for the license',
            1,
            0,
          )
          this.props.onRequestClose()
        }
      })
      .catch((err: any) => {
        const msg =
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : ''
        this.Utilities.showMessage('Error while updating license', 0, 0, msg)
        this.props.onRequestClose()
      })
  }

  saveUploadSlidingPage = async () => {
    const {
      entitleToken,
      licenseType,
      numBandwidth,
      numDevices,
      // numProcessingUnits,
      // maxAllocatedBandwidth,
      // maxAllocatedDevices,
      expiry,
      billingSerials,
      // usedDevices,
      // usedBandwidth,
      offlineMode,
      // activationUUID,
      activationKey,
    } = this.state.formData

    if (offlineMode) {
      if (!activationKey) {
        this.Utilities.showMessage('EMPTY_ACTIVATION_KEY_LM', 0, 1)
        return
      }
      this.importLicenseUpdate()
      return
    }

    const maxbandwidthObj = this.Utilities.megaToGiga(
      this.props.licenseInfo['remaining-bandwidth'] || 0,
    )
    const numBW =
      maxbandwidthObj && maxbandwidthObj.unit === 'g'
        ? numBandwidth * 1000
        : numBandwidth

    const payload = {
      'entitlement-key': entitleToken,
      'license-type': licenseType,
      'alloted-bandwidth': numBW,
      'alloted-devices': numDevices,
      // 'license-bandwidth': maxAllocatedBandwidth,
      // 'license-devices': maxAllocatedDevices,
      'expires-at': expiry,
      'billing-serials': billingSerials,
      'glm-lic-type': this.props.licenseInfo['glm-lic-type'],
      'license-name': this.props.licenseInfo['license-name'],
      'license-id': this.props.licenseInfo['license-id'],
      'license-json': this.props.licenseInfo['license-json'],
      'glm-created-at': this.props.licenseInfo['glm-created-at'],
      'glm-updated-at': this.props.licenseInfo['glm-updated-at'],
      'created-at': this.props.licenseInfo['created-at'],
      'last-modified-at': this.props.licenseInfo['last-modified-at'],
      description: this.props.licenseInfo['description'],
      notes: this.props.licenseInfo['notes'],
      'check-in-frequency': this.props.licenseInfo['check-in-frequency'],
      'default-allocated-bandwidth': this.props.licenseInfo[
        'default-allocated-bandwidth'
      ],
      'max-allocated-bandwidth': this.props.licenseInfo[
        'max-allocated-bandwidth'
      ],
      'min-allocated-bandwidth': this.props.licenseInfo[
        'min-allocated-bandwidth'
      ],
      'container-device-return-to-pool-time': this.props.licenseInfo[
        'container-device-return-to-pool-time'
      ],
      'remaining-bandwidth': this.props.licenseInfo['remaining-bandwidth'],
      'remaining-devices': this.props.licenseInfo['remaining-devices'],
      'used-bandwidth': this.props.licenseInfo['used-bandwidth'],
      'used-devices': this.props.licenseInfo['used-devices'],
      'used-activations': this.props.licenseInfo['used-activations'],
      'active-appliances': this.props.licenseInfo['active-appliances'],
      'current-revision-id': this.props.licenseInfo['current-revision-id'],
      'licensed-device-list': this.props.licenseInfo['licensed-device-list'],
      'license-platform': this.props.licenseInfo['license-platform'],
      'license-product': this.props.licenseInfo['license-product'],
      'organization-name': this.props.licenseInfo['organization-name'],
      'start-date': this.props.licenseInfo['start-date'],
    }

    if (licenseType === 'MDU') {
      payload['alloted-bandwidth'] = 0
    }
    const {
      EPIC_DEPENDENCIES: { httpClient },
    } = globalConfig
    const { provider } = this.state
    const url = `/hls/licenses/providers/${provider}/entitlementkey/${entitleToken}`
    try {
      const { data } = await httpClient.put(url, payload, {
        absoluteBasePath: true,
      })
      if (data) {
        this.Utilities.showMessage(
          'Updation request submitted successfully, please check the status in activity log for the license',
          1,
          0,
        )
        this.props.onRequestClose()
      }
    } catch (error) {
      const msg =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : ''
      this.Utilities.showMessage(
        'Error while updating license - Please consult with License Administrator of your organization about the capacity you are trying to allocate',
        0,
        0,
        msg,
      )
      // this.Utilities.showMessage(error.response.data.cause.message, 0, 0)
      this.props.onRequestClose()
    }
  }

  render() {
    const { isOpen, onRequestClose, formData } = this.props
    const onFormChange = this.onFormChange.bind(this)
    const title = 'Request Change Capacity'

    return (
      <FormatSlidingPage
        isOpen={isOpen}
        onRequestOk={this.getUpdatedValues}
        onRequestClose={onRequestClose}
        title={title}
        saveText="Request"
        description="You can request for an upgrade or downgrade"
      >
        <LicenseEditForm
          onRef={instance => {
            this.child = instance
          }}
          onChange={onFormChange}
          formData={formData}
          licenseInfo={this.props.licenseInfo}
        />
      </FormatSlidingPage>
    )
  }
}

export default SlidingLicenseEdit
