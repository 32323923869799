const nextReg = /[^]*\/slb\/service-group\/(.*)\/member[^]*/
export const getServiceGroupNameByUrl = (a10Url: string) => {
  if (!a10Url) {
    return ''
  }
  return a10Url.replace(nextReg, '$1')
}

// template
const templateReg = /[^]*\/slb\/template\/(.*)\/(.*)[^]*/
const ipnatReg = /[^]*\/ip\/nat\/pool\/(.*)[^]*/
const ipnatGroupReg = /[^]*\/ip\/nat\/pool-group\/(.*)[^]*/
const ipv6natReg = /[^]*\/ipv6\/nat\/pool\/(.*)[^]*/
const ipv6natGroupReg = /[^]*\/ipv6\/nat\/pool-group\/(.*)[^]*/
const healthMonitorReg = /[^]*\/health\/monitor\/(.*)[^]*/
const ipnatLoggingReg = /[^]*\/ip\/nat\/template\/logging\/(.*)[^]*/
const vserverReg = /[^]*\/slb\/virtual-server\/(.*)[^]*/
const vportReg = /[^]*\/slb\/virtual-server\/(.*)\/port\/(.*)[^]*/
const serverReg = /[^]*\/slb\/server\/(.*)[^]*/
const serverPortReg = /[^]*\/slb\/server\/(.*)\/port\/(.*)[^]*/
const serviceGroupReg = /[^]*\/slb\/service-group\/(.*)[^]*/
const aflexReg = /[^]*\/aflex\/(.*)[^]*/
const certReg = /[^]*\/ssl-cert\/(.*)[^]*/
const keyReg = /[^]*\/ssl-key\/(.*)[^]*/
const caReg = /[^]*\/ssl-ca\/(.*)[^]*/

export const getObjectTypeByUrl = (a10Url: string) => {
  if (!a10Url) {
    return {}
  }
  if (a10Url.indexOf('/ip/nat/template/logging') !== -1) {
    const output = a10Url.match(ipnatLoggingReg)
    return { type: 'IP NAT Logging Template', name: output[1] }
  } else if (a10Url.indexOf('/health/monitor') !== -1) {
    const output = a10Url.match(healthMonitorReg)
    return { type: 'Health Monitor', name: output[1] }
  } else if (a10Url.indexOf('/ip/nat/pool/') !== -1) {
    const output = a10Url.match(ipnatReg)
    return { type: 'IP NAT Pool', name: output[1] }
  } else if (a10Url.indexOf('/ip/nat/pool-group') !== -1) {
    const output = a10Url.match(ipnatGroupReg)
    return { type: 'IP NAT Pool Group', name: output[1] }
  } else if (a10Url.indexOf('/ipv6/nat/pool/') !== -1) {
    const output = a10Url.match(ipv6natReg)
    return { type: 'IPv6 NAT Pool', name: output[1] }
  } else if (a10Url.indexOf('/ipv6/nat/pool-group') !== -1) {
    const output = a10Url.match(ipv6natGroupReg)
    return { type: 'IPv6 NAT Pool Group', name: output[1] }
  } else if (a10Url.indexOf('/slb/template') !== -1) {
    const output = a10Url.match(templateReg)
    const objType = output[1] + ' template'
    return { type: objType, name: output[2] }
  } else if (a10Url.indexOf('/slb/virtual-server/') !== -1) {
    if (a10Url.indexOf('/port/') !== -1) {
      const output = a10Url.match(vportReg)
      return { type: 'Virtual Port', name: output[2], vserver: output[1] }
    } else {
      const output = a10Url.match(vserverReg)
      return { type: 'Virtual Server', name: output[1] }
    }
  } else if (a10Url.indexOf('/slb/service-group/') !== -1) {
    const output = a10Url.match(serviceGroupReg)
    return { type: 'Service Group', name: output[1] }
  } else if (a10Url.indexOf('/slb/server/') !== -1) {
    if (a10Url.indexOf('/port/') !== -1) {
      const output = a10Url.match(serverPortReg)
      return { type: 'Server Port', name: output[2], vserver: output[1] }
    } else {
      const output = a10Url.match(serverReg)
      return { type: 'Server', name: output[1] }
    }
  } else if (a10Url.indexOf('/aflex/') !== -1) {
    const output = a10Url.match(aflexReg)
    return { type: 'aFleX', name: output[1] }
  } else if (a10Url.indexOf('/ssl-cert/') !== -1) {
    const output = a10Url.match(certReg)
    return { type: 'Certificate', name: output[1] }
  } else if (a10Url.indexOf('/ssl-key/') !== -1) {
    const output = a10Url.match(keyReg)
    return { type: 'Key', name: output[1] }
  } else if (a10Url.indexOf('/ssl-ca/') !== -1) {
    const output = a10Url.match(caReg)
    return { type: 'CA', name: output[1] }
  } else if (a10Url.indexOf('/app-svc/') !== -1) {
    return { type: 'App Service' }
  } else {
    return {}
  }
}

// role url reg
const appReg = /^\/providers\/(.*)\/apps\/(.*)[^]*/
const clusterReg = /^\/provider\/(.*)\/cluster\/(.*)[^]*/
const deviceReg = /^\/provider\/(.*)\/device\/(.*)[^]*/
const tenantReg = /^\/provider\/(.*)\/tenant\/(.*)[^]*/
const logicalPartitionReg = /^\/provider\/(.*)\/tenant\/(.*)\/logical-partition\/(.*)[^]*/
const vipRoleReg = /^\/provider\/(.*)\/tenant\/(.*)\/logical-partition\/(.*)\/slb\/virtual-server\/(.*)[^]*/
const vportRoleReg = /^\/provider\/(.*)\/tenant\/(.*)\/logical-partition\/(.*)\/slb\/virtual-server\/(.*)\/port\/(.*)[^]*/
const aflexRoleReg = /^\/provider\/(.*)\/tenant\/(.*)\/logical-partition\/(.*)\/file\/aflex(.*)[^]*/
const certRoleReg = /^\/provider\/(.*)\/tenant\/(.*)\/logical-partition\/(.*)\/file\/ssl-cert[^]*/
const keyRoleReg = /^\/provider\/(.*)\/tenant\/(.*)\/logical-partition\/(.*)\/file\/ssl-key[^]*/
const crlRoleReg = /^\/provider\/(.*)\/tenant\/(.*)\/logical-partition\/(.*)\/file\/ssl-crl[^]*/
export const getRoleObjectByUrl = (url: string) => {
  if (url.indexOf('/apps/') > -1) {
    const output = url.match(appReg)
    return { type: 'apps', value: output[2] }
  } else if (url.indexOf('/cluster/') > -1) {
    const output = url.match(clusterReg)
    return { type: 'cluster', value: output[2] }
  } else if (url.indexOf('/device') > -1) {
    const output = url.match(deviceReg)
    return { type: 'device', value: output[2] }
  } else if (url.indexOf('/tenant/') > -1) {
    if (url.indexOf('/logical-partition/') > -1) {
      if (url.indexOf('/virtual-server/') > -1) {
        if (url.indexOf('/port/') > -1) {
          const output = url.match(vportRoleReg)
          return {
            type: 'vport',
            value: `tenant:${output[2]}:lp:${output[3]}:vip:${output[4]}:vport:${output[5]}`,
          }
        } else {
          const output = url.match(vipRoleReg)
          return {
            type: 'vip',
            value: `tenant:${output[2]}:lp:${output[3]}:vip:${output[4]}`,
          }
        }
      } else if (url.indexOf('/file/aflex') > -1) {
        const output = url.match(aflexRoleReg)
        return {
          type: 'aflex',
          value: `tenant:${output[2]}:lp:${output[3]}:file:aflex`,
        }
      } else if (url.indexOf('/file/ssl-cert') > -1) {
        const output = url.match(certRoleReg)
        return {
          type: 'cert',
          value: `tenant:${output[2]}:lp:${output[3]}:file:cert`,
        }
      } else if (url.indexOf('/file/ssl-key') > -1) {
        const output = url.match(keyRoleReg)
        return {
          type: 'key',
          value: `tenant:${output[2]}:lp:${output[3]}:file:key`,
        }
      } else if (url.indexOf('/file/ssl-crl') > -1) {
        const output = url.match(crlRoleReg)
        return {
          type: 'crl',
          value: `tenant:${output[2]}:lp:${output[3]}:file:crl`,
        }
      } else {
        const output = url.match(logicalPartitionReg)
        return {
          type: 'logicalPartition',
          value: `tenant:${output[2]}:lp:${output[3]}`,
        }
      }
    } else {
      const output = url.match(tenantReg)
      if (output) {
        return { type: 'tenant', value: output[2] }
      }
    }
  }
  return { type: '', value: '' }
}

export const formatBytes = (
  bytes: number,
  simpleMode: boolean = false,
  seperateUnit: boolean = false,
) => {
  const standardSizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const simpleSizes = ['', 'K', 'M', 'G', 'T']
  let sizes = standardSizes
  if (bytes === 0) {
    if (simpleMode) {
      if (seperateUnit) {
        return {
          count: 0,
          unit: '',
        }
      }
      return '0'
    }
    if (seperateUnit) {
      return {
        count: 0,
        unit: 'Byte',
      }
    }
    return '0 Byte'
  }
  if (simpleMode) {
    sizes = simpleSizes
  }
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10) || 0
  const transformedCount = Number((bytes / Math.pow(1024, i)).toFixed(1))
  if (seperateUnit) {
    return {
      count: transformedCount,
      unit: sizes[i],
    }
  }
  return transformedCount + ' ' + sizes[i]
}

export const formatBytesByUnit = (bytes: number, unit: string = 'G') => {
  const standardSizes = {
    B: 0,
    K: 1,
    M: 2,
    G: 3,
    T: 4,
  }
  const size = standardSizes[unit]
  const transformedCount = Math.round(bytes / Math.pow(1024, size))
  return transformedCount
}

export const cannedRoleClassNames = new Set([
  'hc_provider_admin',
  'provider_admin_read_only',
  'hc_tenant_admin',
  'hc_tenant_operator',
  'hc_app_operator',
  'hc_app_admin',
  'ladc_service_admin',
  'hc_system_admin',
  'hc_network_admin',
  'hc_network_operator',
  'hc_dp_networks_operator',
  'hc_infra_admin',
  'hc_certificate_admin',
])

export const roleClassMapping = {
  hc_provider_admin: new Set(['all_service_admin']),
  provider_admin_read_only: new Set(['partition_read_only']),
  hc_tenant_admin: new Set(['all_service_admin']),
  hc_tenant_operator: new Set(['partition_read_only']),
  hc_app_admin: new Set([
    'all_service_admin',
    'slb_service_admin',
    'slb_service_operator',
    'cgn_service_admin',
    'security_service_admin',
  ]),
  hc_app_operator: new Set(['all_service_operator']),
  ladc_service_admin: new Set(),
  hc_system_admin: new Set(),
  hc_network_admin: new Set(),
  hc_network_operator: new Set(),
  hc_dp_networks_operator: new Set(),
  hc_infra_admin: new Set(),
  hc_certificate_admin: new Set(),
}

export const defaultServiceAccess = {
  hc_provider_admin: 'Access to All',
  hc_tenant_admin: 'Access to All',
  hc_app_admin: 'Access to All',
  hc_app_operator: 'Read Only',
  hc_infra_admin: 'No Access',
  hc_certificate_admin: 'Read Only',
}
