import React from 'react'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Form,
  A10Icon,
  A10Modal,
  A10Button,
  A10Input,
  A10Alert,
  A10Row,
} from '@gui-libraries/widgets'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { HelpInfo } from 'src/components/shared/HelpInfo'

import { Messages } from 'src/locale/en/Messages'
import { Utilities, DashboardService } from 'src/services'
// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

export interface ILicenseRevokeFormProps extends IA10ContainerDefaultProps {
  isOpen: boolean
  licenseObj: any
  onRequestClose: () => void
  updateList: () => void
}

export interface ILicenseRevokeFormStates {
  licenseObj: any
  revokeConfirm: boolean
  revokedLicenseAudit: string
  showRevokeAuditForm: boolean
}

class LicenseRevokeForm extends A10Container<
  ILicenseRevokeFormProps,
  ILicenseRevokeFormStates
> {
  Messages = new Messages()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  revokeLicAuditRef: any = null

  constructor(props: ILicenseRevokeFormProps) {
    super(props)
    this.state = {
      licenseObj: this.props.licenseObj,
      revokeConfirm: false,
      revokedLicenseAudit: '',
      showRevokeAuditForm: false,
    }
  }

  componentWillReceiveProps(nextProps: IObject) {
    const state = { ...this.state }
    const { licenseObj } = nextProps
    state.licenseObj = licenseObj
    this.setState(state)
  }

  onClose = () => {
    this.setState(
      {
        revokeConfirm: false,
        revokedLicenseAudit: '',
        showRevokeAuditForm: false,
      },
      () => {
        this.props.updateList()
        this.props.onRequestClose()
      },
    )
  }

  handleModalCancel = () => {
    this.setState({
      revokeConfirm: false,
      revokedLicenseAudit: '',
      showRevokeAuditForm: false,
    })
  }

  confirmRevoke = () => {
    const { licenseObj } = this.state
    const revokeLicense = this.DashboardService.revokeLLMLicense(
      null,
      licenseObj['entitlement-key'],
      [licenseObj['license-id']],
    )
    revokeLicense
      .then((resp: any) => {
        if (resp) {
          const revokedLicenseAudit = resp?.data?.revoked_license_audit || ''

          if (revokedLicenseAudit !== '') {
            this.setState({
              revokeConfirm: false,
              revokedLicenseAudit: revokedLicenseAudit,
              showRevokeAuditForm: true,
            })
          } else {
            this.handleModalCancel()
            this.onClose()
          }
        }
      })
      .catch((err: any) => {
        const msg =
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : ''
        this.Utilities.showMessage('Error while revoke of license', 0, 0, msg)
        this.handleModalCancel()
        this.onClose()
      })
  }

  onRequestOk = () => {
    this.setState({ revokeConfirm: true })
  }

  render() {
    const { isOpen, licenseObj, onRequestClose } = this.props
    const { revokedLicenseAudit, showRevokeAuditForm } = this.state
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 14 },
    }

    return (
      <>
        <FormatSlidingPage
          className='revoke-license'
          isOpen={isOpen}
          onRequestOk={showRevokeAuditForm ? this.onClose : this.onRequestOk}
          onRequestClose={onRequestClose}
          title={
            showRevokeAuditForm ? 'Revoked License Audit' : 'Revoke License'
          }
          description={
            showRevokeAuditForm
              ? ''
              : 'Please read the document carefully before revoking.'
          }
          saveText={showRevokeAuditForm ? 'Close' : 'Revoke'}
          hideCancel={showRevokeAuditForm}
        >
          <A10Form hideRequiredMark={true} layout="horizontal">
            <A10Panel
              title={
                <A10IconTextGroup
                  text={
                    showRevokeAuditForm
                      ? 'Revoked License Audit'
                      : 'Revoke License'
                  }
                  icon={
                    <A10Icon
                      style={{ width: 22, height: 22, marginRight: 12 }}
                      app="global"
                      type="form-section"
                      className="sliding-panel-icon"
                    />
                  }
                />
              }
              className={styles.licRevokePanel}
            >
              {showRevokeAuditForm ? (
                <>
                  <A10Row className="margin-v-10">
                    <A10Alert
                      showIcon={true}
                      message="License revoked"
                      description="License revoke request submitted successfully."
                      type="info"
                    />
                  </A10Row>
                  <A10Form.Item
                    {...formItemLayout}
                    label={
                      <>
                        <span>Revoked License Audit</span>
                        <span style={{ marginLeft: '-4px' }}>
                          <HelpInfo
                            placement="rightTop"
                            title="Revoked License Audit"
                            helpKey="HELP_LICENSE_MANAGER_LLM_REVOKE_LICENSE_AUDIT"
                          />
                        </span>
                      </>
                    }
                  >
                    <div className="row">
                      <div className="col-md-10">
                        <A10Input
                          type="text"
                          placeholder="Revoked License Audit"
                          value={revokedLicenseAudit}
                          ref={(copyText: any): any =>
                            (this.revokeLicAuditRef = copyText)
                          }
                          disabled={true}
                        />
                      </div>
                      <div className="col-md-2">
                        {/* Logical shortcut for only displaying the button if the copy command exists */
                        document.queryCommandSupported('copy') && (
                          <A10Button
                            className="action-button"
                            onClick={() => {
                              navigator.clipboard.writeText(revokedLicenseAudit)
                            }}
                          >
                            <A10Icon
                              app="global"
                              type="duplicate"
                              className="action-icon"
                            />
                            Copy
                          </A10Button>
                        )}
                      </div>
                    </div>
                  </A10Form.Item>
                  <p>
                    Upload Revoked License Audit to{' '}
                    <a
                      href="https://glm.a10networks.com/elm/return_license"
                      target="_blank"
                    >
                      https://glm.a10networks.com/elm/return_license
                    </a>{' '}
                    to reclaim license resources.
                  </p>
                </>
              ) : (
                <div className={`${styles.licRevokeText} "col-md-12"`}>
                  <div className={styles.licRevokeTextDiv}>
                    To revoke the license and return resources to the A10
                    Networks GLM the license and all of its activations must be
                    revoked. Once revoked upload the revoked license audit to
                    the A10 Networks GLM. You also agree that
                  </div>
                  <div className={styles.licRevokeTextDiv}>
                    “I will uninstall license{' '}
                    <b>{licenseObj['license-name']}</b> and all instances with
                    this license applied including UUID:
                  </div>
                  <div className={styles.licRevokeTextDiv}>
                    A10 Networks is willing to grant the right to return the
                    resources for further use, provided Customer agrees as
                    follows:
                  </div>
                  <div className={styles.licRevokeTextDiv}>
                    <ul>
                      <li>
                        I shall permanently delete all copies of the software
                        product on activated appliances.
                      </li>
                      <li>
                        I shall not reuse the original license ELM activation.
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </A10Panel>
          </A10Form>
        </FormatSlidingPage>

        <A10Modal
          title="Confirm Revoke License"
          visible={this.state.revokeConfirm}
          onOk={this.confirmRevoke}
          onCancel={this.handleModalCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleModalCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.confirmRevoke}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            Do you want to continue revoke of{' '}
            <b>{licenseObj['license-name']}</b> ?
          </p>
        </A10Modal>
      </>
    )
  }
}

export default setupA10Container(A10Form.create()(LicenseRevokeForm))
