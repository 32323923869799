import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import { ApplicationConfigs } from 'src/constants/ApplicationConfigs'
import { AppRoot } from 'src/settings/appRoot'
import { Utilities } from 'src/services'
import storage from 'src/libraries/storage'
import { UserProfile } from 'src/containers/Controller/ProviderSettings/UserProfile'
import { ContentSection } from 'src/components/shared/ContentSection'

export interface IProviderSettingsProps extends IA10ContainerDefaultProps {
  tenantToggled: boolean
  applicationName: string
  selectedApp: string
  onSelectApp: (selectedApp: string) => void
}
export interface IProviderSettingsState {
  application: IObject
}

class ProviderSettings extends A10Container<
  IProviderSettingsProps,
  IProviderSettingsState
> {
  state: IProviderSettingsState = {
    application: {
      subApps: [],
      name: '',
    },
  }
  ApplicationConfigs = new ApplicationConfigs()
  AppRoot = new AppRoot()
  Utilities = new Utilities()

  componentDidMount() {
    this.updateState()
  }

  componentDidUpdate(prevProps: IProviderSettingsProps) {
    if (prevProps.tenantToggled !== this.props.tenantToggled) {
      this.updateState()
    }
  }

  updateState() {
    const applications = this.ApplicationConfigs.getAvailableApps()
    const applicationIndex = applications.findIndex(key => {
      return key.name.toLowerCase() === this.props.applicationName.toLowerCase()
    })
    this.setState({
      application: applications[applicationIndex],
    })

    if (storage.get.ADMIN_LEVEL === 'provider') {
      storage.remove.TENANT()
      storage.remove.CURRENT_TENANT()
      storage.set.DRILL_LEVEL('provider')
      this.AppRoot.setRootScopeElement('currentDrillLevel', 'provider')
    }

    // Commented out because user should be able to view provider setting even under super admin mode
    // removeItem('SUPER_ADMIN_MODE')
    storage.set.CURRENT_STATE(this.props.applicationName.toLowerCase())
    this.AppRoot.setRootScopeElement(
      'currentState',
      this.props.applicationName.toLowerCase(),
    )
  }

  render() {
    return (
      <ContentSection>
        <UserProfile />
      </ContentSection>
    )
  }
}

export default setupA10Container(ProviderSettings)
