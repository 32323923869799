import React from 'react'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Form,
  A10Input,
  A10Icon,
  A10Alert,
  A10Button,
} from '@gui-libraries/widgets'

import { Messages } from 'src/locale/en/Messages'
import { Utilities, DashboardService } from 'src/services'
import { HelpInfo } from 'src/components/shared/HelpInfo'
// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

export interface ILicenseImportFormProps extends IA10ContainerDefaultProps {
  form: any
  onRef?: any
  llmUUID: string
  exportLicString: string
  onChange: (exportLicString: string) => void
}

export interface ILicenseImportFormStates {
  llmUUID: string
  exportLicString: string
}

class LicenseImportForm extends A10Container<
  ILicenseImportFormProps,
  ILicenseImportFormStates
> {
  Messages = new Messages()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  llmUuidRef: any = null

  constructor(props: ILicenseImportFormProps) {
    super(props)
    this.state = {
      llmUUID: this.props.llmUUID,
      exportLicString: this.props.exportLicString,
    }
  }

  componentWillReceiveProps(nextProps: IObject) {
    const state = { ...this.state }
    const { llmUUID, exportLicString } = nextProps
    state.llmUUID = llmUUID
    state.exportLicString = exportLicString
    this.setState(state)
  }

  componentDidMount() {
    this.props.onRef(this)
    this.props.onChange('')
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  handleChange = (event: any) => {
    const exportLicString =
      event?.target?.type === 'checkbox'
        ? event.target.checked
        : event?.target
        ? event.target.value
        : event

    this.props.onChange(exportLicString)

    // @ts-ignore
    this.setState({ exportLicString })
  }

  render() {
    const { llmUUID, exportLicString } = this.state
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 14 },
    }
    const { getFieldDecorator } = this.props.form

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel showHeader={false}>
          <div className={`${styles.llmInfoDiv} "col-md-12" mb-16`}>
            <A10Alert
              showIcon={true}
              message="How to Import"
              description="To import a license, export the license from GLM using the License Manager UUID and paste the string of the exported license below."
              type="info"
            />
          </div>
          <A10Form.Item
            className="mb-10"
            {...formItemLayout}
            label={
              <>
                <span className="mandatoryField label-border">License Manager UUID</span>
                <span style={{ marginLeft: '-4px' }}>
                  <HelpInfo
                    placement="rightTop"
                    title="License Manager UUID *"
                    helpKey="HELP_LICENSE_MANAGER_LLM_UUID"
                  />
                </span>
              </>
            }
          >
            <div className="row">
              <div className="col-md-10">
                <A10Input
                  type="text"
                  placeholder="License Manager UUID"
                  value={llmUUID}
                  ref={(copyText: any): any => (this.llmUuidRef = copyText)}
                  onChange={this.handleChange.bind(this, 'llmUUID')}
                  disabled={true}
                />
              </div>
              <div className="col-md-2">
                {/* Logical shortcut for only displaying the button if the copy command exists */
                document.queryCommandSupported('copy') && (
                  <A10Button

                    className="action-button copy-button"
                    onClick={() => {
                      navigator.clipboard.writeText(llmUUID)
                    }}
                  >
                    <A10Icon
                      app="global"
                      type="duplicate"
                      className="action-icon"
                    />
                    Copy
                  </A10Button>
                )}
              </div>
            </div>
          </A10Form.Item>

          <A10Form.Item
            className="mb-0"
            {...formItemLayout}
            label={
              <>
                <span className="mandatoryField label-border">Exported License String</span>
                <span style={{ marginLeft: '-4px' }}>
                  <HelpInfo
                    placement="rightTop"
                    title="Exported License String *"
                    helpKey="HELP_LICENSE_MANAGER_LLM_EXP_LICENSE_STRING"
                  />
                </span>
              </>
            }
          >
            <div className="row">
              <div className="col-md-10">
                {getFieldDecorator('exportLicString', {
                  rules: [
                    {
                      required: true,
                      message:
                        'Please paste the string of the exported license',
                    },
                  ],
                  initialValue: exportLicString,
                })(
                  <A10Input.TextArea
                    className='area-height'
                    type="text"
                    placeholder="Paste the string of the exported license here"
                    onChange={this.handleChange.bind(this)}
                  />,
                )}
              </div>
            </div>
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(LicenseImportForm))
