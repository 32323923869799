import { UrlService, AjaxService } from 'src/services'
import Data from 'src/constants/Data/Data'
import storage from 'src/libraries/storage'
// import Parameters from '../constants/Parameters';

export class InfrastructureService {
  // Instantiating instances of imported services
  UrlService = new UrlService()
  AjaxService = new AjaxService()
  Data = new Data()

  getRequest = (headers: any, payload: any, urlInput: any, urlName: string) => {
    const urlObj = this.UrlService.get(urlName)
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  operRequest = (urlInput: any, urlName: string, name?: string) => {
    const urlObj = this.UrlService.get(urlName)
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    const payload = {
      'object-url': `${urlObj.URL}/${name ? `${name}/` : ''}oper`,
      method: urlObj.METHOD,
      'object-payload': {},
    }
    const url = this.UrlService.formatURL(
      urlInput,
      '/hocapi/v1/provider/~/device/~/partition/~/_deploy/',
    )
    return this.AjaxService.promisingHttpRequest(url, 'POST', null, payload)
  }

  operPaginationRequest = (urlInput: any, urlName: string) => {
    const urlObj = this.UrlService.get(urlName)
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    const payload = {
      'object-url': urlObj.URL,
      method: urlObj.METHOD,
      'object-payload': {},
    }
    const url = this.UrlService.formatURL(
      urlInput,
      '/hocapi/v1/provider/~/device/~/partition/~/_deploy/',
    )
    return this.AjaxService.promisingHttpRequest(url, 'POST', null, payload)
  }

  statsRequest = (urlInput: any, urlName: string, name?: string) => {
    const urlObj = this.UrlService.get(urlName)
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    const payload = {
      'object-url': `${urlObj.URL}/${name ? `${name}/` : ''}stats`,
      method: urlObj.METHOD,
      'object-payload': {},
    }
    const url = this.UrlService.formatURL(
      urlInput,
      '/hocapi/v1/provider/~/device/~/partition/~/_deploy/',
    )
    return this.AjaxService.promisingHttpRequest(url, 'POST', null, payload)
  }

  getClusters = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_CLUSTERS')
    // const urlObj = this.UrlService.get('GET_CLUSTERS_O')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    // return new Promise((resolve, reject) => {
    //   resolve(this.Data.clusters)
    // })

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getClustersSummary = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_CLUSTERS_SUMMARY')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getCluster = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_CLUSTER')
    // const urlObj = this.UrlService.get('GET_CLUSTERS_O')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getClusterSummary = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_CLUSTER_SUMMARY')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getClusterDevices = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_DEVICES')
    // const urlObj = this.UrlService.get('GET_DEVICES_O')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    // return new Promise((resolve, reject) => {
    //   resolve(this.Data.devices)
    // })
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getDevicePartitions = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_DEVICE_PARTITIONS')
    // const urlObj = this.UrlService.get('GET_DEVICES_O')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    // return new Promise((resolve, reject) => {
    //   resolve(this.Data.devices)
    // })
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  addCluster = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_CLUSTER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  vcsToHAOnboard = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('VCS_HA_ONBOARD')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  updateCluster = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_CLUSTER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  deleteCluster = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_CLUSTER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  featureCheck = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('FEATURE_CHECK')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  checkFeatureSupport = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('CHECK_FEATURE_SUPPORT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  scanCluster = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('SCAN_CLUSTER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getDeviceToken = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_JWT_TOKEN')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getDevicesSummary = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_DEVICES_SUMMARY')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getDevices = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_INDEPENDENT_DEVICES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  addDevice = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_DEVICE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  registerDevice = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('REGISTER_DEVICE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  deRegisterDevice = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DEREGISTER_DEVICE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  updateDevice = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_DEVICE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getDevice = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_DEVICE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getDeviceQuery = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_DEVICE_QUERY')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  deleteDevice = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_DEVICE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getPartition = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_PARTITION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  addPartition = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_PARTITION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  addPartitionToCluster = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_PARTITION_CLUSTER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  editPartitionToCluster = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('EDIT_PARTITION_CLUSTER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  rebootDevice = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('REBOOT_DEVICE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  addPartitionToClusterInitialRequest = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('ADD_PARTITION_CLUSTER_INITIAL_REQUEST')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  updatePartition = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_PARTITION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  deletePartition = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_PARTITION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  deleteClusterPartition = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_CLUSTER_PARTITION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getVMDevices = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_VIRTUAL_DEVICES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getVMDeviceDetail = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_VIRTUAL_DEVICE_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getCredentialInfraAccount = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_CREDENTIALS_INFRA_ACCOUNT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getCredentialInfraAccountType = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('GET_CREDENTIALS_INFRA_ACCOUNT_ENV')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  validateCredentialInfraAccount = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('VALIDATE_CREDENTIALS_INFRA_ACCOUNT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  addCredentialInfraAccount = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_CREDENTIALS_INFRA_ACCOUNT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  updateCredentialInfraAccount = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('UPDATE_CREDENTIALS_INFRA_ACCOUNT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  deleteCredentialInfraAccount = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('DELETE_CREDENTIALS_INFRA_ACCOUNT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getVMWMachines = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_VMS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getVMWDataCenters = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_VM_DATA_CENTERS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getVMWClusters = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_VM_CLUSTERS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getVMWFolders = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_VM_FOLDERS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getVMWClusterResourcePools = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_VM_CLUSTER_RESOURCE_POOL')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getVMWClusterHosts = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_VM_CLUSTER_HOST')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getVMWClusterDataStores = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_VM_CLUSTER_DATASTORE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getVMWClusterDiskImage = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_VM_DISK_IMAGE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  getVMWDCNetworks = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_VM_DC_NETWORK')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  postVMDevices = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('POST_VIRTUAL_DEVICES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  redeployVMDevice = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DEPLOY_VIRTUAL_DEVICES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  powerOnOffVMDevice = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('POWER_ON_OFF_VIRTUAL_DEVICES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  deleteVMDevices = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DEL_VIRTUAL_DEVICES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  createLogicalPartition = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('CREATE_LOGICAL_PARTITION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  updateLogicalPartition = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_LOGICAL_PARTITION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getLogicalPartitionCount = (headers: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LOGICAL_PARTITION_COUNT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      null,
    )
  }

  getActiveAppServiceCount = (headers: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_ACTIVE_APP_SERVICE_COUNT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      null,
    )
  }

  deleteLPFromTenant = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_LOGICAL_PARTITION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  createLPSubscriber = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('CREATE_LP_SUBSCRIBER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  deleteLPSubscriber = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_LP_SUBSCRIBER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  removeTenantPartitionAssociation = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('REMOVE_TENANT_PARTITION_ASSOCIATION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  updateClusterPartion = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_CLUSTER_PARTITION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  createReProvision = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('CREATE_RE_PROVISION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getLogicalPartition = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LOGICAL_PARTITION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  unmapTenantPartitonFunctions = async (
    partitionMapObj: any,
    deleteLP?: boolean,
  ) => {
    const {
      get: { PROVIDER, ENCODED_SESSION_ID },
    } = storage

    const header = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      provider: PROVIDER,
      Authorization: ENCODED_SESSION_ID,
      tenant: partitionMapObj.existingTenant.name,
    }
    // Remove tenant-partition association
    const removeOldAssociation = this.removeTenantPartitionAssociation(
      header,
      null,
      partitionMapObj['partition-uuid'],
    )
    await removeOldAssociation
      .then((response: any) => {
        // console.log("S Remove tenant-partition association", response)
      })
      .catch((error: any) => {
        // console.log("Remove tenant-partition association", error)
      })
    // delete LP subscription
    // const delLPSubscription = this.deleteLPSubscriber(
    //   null,
    //   null,
    //   partitionMapObj.existingLPuuid,
    // )
    // await delLPSubscription
    //   .then((response: any) => {
    //     // console.log("S delete LP subscription", response)
    //   })
    //   .catch((error: any) => {
    //     // console.log("delete LP subscription", error)
    //   })

    if (deleteLP) {
      // delete LP from Tenant
      const delLPTenant = this.deleteLPFromTenant(null, null, [
        PROVIDER,
        partitionMapObj.existingTenant.name,
        partitionMapObj.existingLPname,
      ])
      await delLPTenant
        .then((response: any) => {
          // console.log("S delete LP from Tenant", response)
        })
        .catch((error: any) => {
          // console.log("delete LP from Tenant", error)
        })
    }
  }

  validateDeviceForAdd = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('VALIDATE_DEVICE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  checkDeviceLicense = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('CHECK_DEVICE_LICENSE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  setDeviceLicense = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ASSIGN_DEVICE_LICENSE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAWSRegions = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_AWS_REGIONS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAWSZones = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_AWS_ZONES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAWSVPCs = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_AWS_VPCS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAWSImage = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_AWS_IMAGES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAWSElasticIP = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_AWS_ELASTIC_IPS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAWSSecurityGroup = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_AWS_SECURITY_GROUPS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAWSSubnet = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_AWS_SUBNETS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  checkAWSPrivateIPAvailability = async (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('CHECK_AWS_PRIVATE_IP_AVAILABILITY')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  checkAzurePrivateIPAvailability = async (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('CHECK_AZURE_PRIVATE_IP_AVAILABILITY')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAzureLocations = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_AZURE_LOCATIONS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAzureResouceGroups = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_AZURE_RESOURCE_GROUPS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAzureVNETs = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_AZURE_VNETS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAzureMarketplaceImage = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_AZURE_MARKETPLACE_IMAGES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  checkAzureMarketplaceImageTerms = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('CHECK_AZURE_MARKETPLACE_IMAGES_TERMS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAzurePrivateImage = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_AZURE_PRIVATE_IMAGES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAzurePublicIP = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_AZURE_PUBLIC_IPS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAzureNetworkSecurityGroup = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('GET_AZURE_NETWORK_SECURITY_GROUPS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getAzureSubnet = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_AZURE_SUBNETS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getK8sNodes = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_K8S_NODES')

    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getK8sNamespaces = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_K8S_NAMESPACES')

    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getK8sImages = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_K8S_IMAGES')

    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getK8sNetAttachmentDefs = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_K8S_NETWORK_ATTACHMENT_DEFS')

    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getK8sDeployments = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_K8S_DEPLOYMENTS')

    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getOCIRegions = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_OCI_REGIONS')

    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getOCIAvailabilityDomains = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_OCI_AVAILABILITY_DOMAINS')

    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getOCICompartments = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_OCI_COMPARTMENTS')

    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getOCIVCNs = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_OCI_VCNS')

    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getOCIImages = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_OCI_IMAGES')

    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getOCIShapes = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_OCI_SHAPES')

    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getOCISubnets = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_OCI_SUBNETS')

    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getOCIPublicIPs = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_OCI_PUBLIC_IPS')

    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getOCINetworkSecurityGroups = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_OCI_NETWORK_SECURITY_GROUPS')

    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  checkOCIPrivateIPAvailability = async (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('CHECK_OCI_PRIVATE_IP_AVAILABILITY')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getDeviceInterfaces = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DEVICE_ETHERNET_INTERFACE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getSecondaryIPs = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_SECONDARY_IPS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  addSecondaryIP = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('CREATE_SECONDARY_IP')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  updateSecondaryIP = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_SECONDARY_IP')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  deleteSecondaryIP = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_SECONDARY_IP')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  bootImageCheck = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DEVICE_BOOT_IMAGE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  checkDeviceLicenseState = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('CHECK_DEVICE_LICENSE_STATE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  getDeviceLicenseDetails = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_DEVICE_LICENSE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }

  devicePartitionAxApiGetCall = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DEVICE_PARTITION_DEPLOY_AXAPI')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getLdapGroup = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('LOAD_LDAP_GROUP')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
}
export default InfrastructureService
