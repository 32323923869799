import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
  _,
} from '@gui-libraries/framework'
import { A10Col, A10Input } from '@gui-libraries/widgets'

import CertList from './CertList'
import SlidingCert from './CertForm/slidingForm'
import storage from 'src/libraries/storage'
import { getCertList } from 'src/redux/httpServices/ADC/common'
import { getLPObject } from 'src/redux/httpServices/ADC/common/current_logical_partition'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'

// tslint:disable-next-line:no-var-requires
// const certpem = require('certpem')
// tslint:disable-next-line:no-var-requires
import styles from './styles/index.module.less'

export interface ICertProps extends IA10ContainerDefaultProps {
  data: any[]
  tenant: string
  logicalPartition: string
  lpObject: IObject
  scope: string
}

export interface ICertState {
  showStats: boolean
  showSlidingPage: boolean
  objects: any[]
  showAddOrEditSlidingPage: boolean
  isLoading: boolean
  search: string
}

class Cert extends A10Container<ICertProps, ICertState> {
  objectPassed = {}
  delayedSearchText = _.debounce(searchString => {
    this.getData(searchString)
  }, 800)
  constructor(props: ICertProps) {
    super(props)
    if (!storage.get.PROVIDER) {
      storage.set.PROVIDER('root')
    }
    this.state = {
      showStats: false,
      showSlidingPage: false,
      objects: [],
      showAddOrEditSlidingPage: false,
      isLoading: false,
      search: '',
    }
  }

  showCertSlidingPage = () => {
    this.setState({ showAddOrEditSlidingPage: true })
  }

  closeCertSlidingPage = () => {
    const {
      A10Dispatchers: { httpRequest },
      tenant,
      logicalPartition,
    } = this.props
    this.setState({ showAddOrEditSlidingPage: false })
    this.getData()
    httpRequest(getLPObject(tenant, logicalPartition))
  }

  changeShowStats = (isChecked: boolean) => {
    this.setState({
      showStats: isChecked,
    })
  }

  componentDidUpdate(preProps: any, preState: any) {
    if (
      preProps.tenant !== this.props.tenant ||
      preProps.logicalPartition !== this.props.logicalPartition ||
      JSON.stringify(preProps.lpObject) !==
        JSON.stringify(this.props.lpObject) ||
      preProps.scope !== this.props.scope
    ) {
      this.getData()
    }
  }

  componentDidMount() {
    if (this.props.tenant) {
      this.getData()
    }
  }

  getData = (text: string = '') => {
    // Start to load data
    this.setState({ isLoading: true })

    let { tenant, logicalPartition } = this.props
    if (this.props.scope === 'provider') {
      tenant = 'pso'
      logicalPartition = 'pso'
    }
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    httpRequest(getCertList(tenant, text, logicalPartition)).finally(() => {
      this.setState({ isLoading: false })
    })
  }

  onSearchText = (e: any) => {
    const searchString = e.target.value
    this.setState({ search: searchString }, () => {
      this.delayedSearchText(searchString)
    })
  }

  render() {
    const { showAddOrEditSlidingPage, isLoading, search } = this.state
    let { tenant, logicalPartition } = this.props
    if (this.props.scope === 'provider') {
      tenant = 'pso'
      logicalPartition = 'pso'
    }

    return (
      <>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col>
            <ContentTitle title="Certificates" count={this.props.data.length} />
          </A10Col>
          <A10Col style={{ display: 'flex' }}>
            <A10Input.Search
              type="text"
              onChange={this.onSearchText}
              name="searchBox"
              value={this.state.search}
              placeholder="Search"
              className={styles.searchInput}
            />
            {this.props.scope !== 'provider' ||
              (storage.get.ADMIN_LEVEL === 'provider' &&
                !storage.get.IS_OPERATOR_USER && (
                  <ActionButton
                    text="Import a Certificate"
                    onClick={this.showCertSlidingPage}
                    iconProps={{ app: 'global', type: 'add-new' }}
                  />
                ))}
          </A10Col>
        </ContentHeader>
        <ContentBody>
          <CertList
            isLoading={isLoading}
            tenant={tenant}
            logicalPartition={logicalPartition}
            updateList={this.getData}
            search={search}
            scope={this.props.scope}
          />
        </ContentBody>
        <SlidingCert
          tenant={tenant}
          logicalPartition={logicalPartition}
          isOpen={showAddOrEditSlidingPage}
          onRequestClose={this.closeCertSlidingPage}
        />
      </>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    data: state.A10Data.getIn(getNS('CERT_LIST'), []),
    lpObject: state.A10Data.getIn(getNS('CURRENT_LOGICAL_PARTITION'), {}),
  }
}

export default setupA10Container(Cert, mapStateToProps)
