import React from 'react'
import { A10Component, _ } from '@gui-libraries/framework'
import { A10FilterTree } from '@gui-libraries/widgets'
import getFilters, {
  getFilterFromString,
  getFilterStrFromMapping,
  getApiFiltersFromStr,
} from './getFilters'

export interface IFilterList {
  type: string
  data: IObject
  filters?: IObject[]
  filterStr?: string
  filterMapping?: IObject
  onChangeFilter: (
    filters: IObject[],
    filterStr: string,
    apiFilters?: IObject,
  ) => void
}
export interface IFilterListState {
  groups: IObject[]
  data: IObject
  filterStr: string
}

class FilterList extends A10Component<IFilterList, IFilterListState> {
  static defaultProps = {
    onChangeFilter: _.noop,
    filterMapping: {},
  }

  state = {
    groups: [] as IObject[],
    filterStr: '',
    data: {},
  }
  componentWillMount() {
    this.init()
  }

  componentDidUpdate(preProps: IFilterList) {
    if (
      this.props.data !== preProps.data ||
      this.props.filterStr !== preProps.filterStr
    ) {
      this.init()
    }
  }

  init() {
    const { data, type, filterStr, filterMapping } = this.props
    const groups = getFilters(type, data, filterMapping) || []
    const filterData = getFilterFromString(filterStr)
    this.setState({ groups, filterStr, data: filterData })
  }

  onChangeFilter = (filters: IObject[]) => {
    const { type } = this.props
    const [filterStr, apiFilters, selectedData] = getFilterStrFromMapping(
      filters,
    )
    const { onChangeFilter } = this.props
    // const newApiFilters = getApiFiltersFromStr(
    //   type,
    //   filters,
    //   filterStr as string,
    //   {},
    // )
    onChangeFilter(filters, filterStr as string, apiFilters as IObject)
    this.setState({
      filterStr: filterStr as string,
      data: selectedData as IObject,
    })
  }
  render() {
    const { type } = this.props
    if (type) {
      return (
        <A10FilterTree
          onChangeFilter={this.onChangeFilter}
          groups={this.state.groups || []}
          data={this.state.data}
        />
      )
    }
    return null
  }
}

export default FilterList
