import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {
  A10Loader,
  A10SlidingPage,
  A10Table,
  A10DropdownMenu,
} from '@gui-libraries/widgets'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import ForceDeleteDialog from './ForceDeleteDialog'
import ActionButton from 'src/components/shared/ActionButton'
import { Utilities } from 'src/services'

import styles from './styles/index.module.less'

export interface IFailoverTemplateListProps {
  device: string
  partition: string
  renderForm: (value: IObject) => JSX.Element
  shouldTemplateUpdate: boolean
  setShouldTemplateUpdate: Dispatch<SetStateAction<boolean>>
  clustersUpdated: boolean
}

const utilities = new Utilities()

const FailoverTemplateList: React.FC<IFailoverTemplateListProps> = props => {
  const provider = storage.get.PROVIDER
  const isOperatorUser = storage.get.IS_OPERATOR_USER

  const container = useRef(null)
  const {
    device,
    partition,
    renderForm,
    shouldTemplateUpdate,
    setShouldTemplateUpdate,
    clustersUpdated,
  } = props
  const [formattedData, setFormattedData] = useState([])
  const [slidingPageOpen, setSlidingPageOpen] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const apiPrefix = useMemo(() => {
    return `/hpcapi/v3/provider/${provider}/device/${device}/partition/${partition}`
  }, [provider, device, partition])

  const openFormPage = useCallback(() => setSlidingPageOpen(true), [])
  const closeFormPage = useCallback(() => {
    setSlidingPageOpen(false)
    setSelectedTemplate('')
  }, [])
  const closeModal = useCallback(() => {
    setModalVisible(false)
    setSelectedTemplate('')
  }, [])

  const submitCallback = () => {
    closeFormPage()
    getData()
  }

  const moreOptions = useCallback(
    (name: string) => {
      return [
        <div
          key={`edit-template-${name}`}
          onClick={() => {
            setSelectedTemplate(name)
            openFormPage()
          }}
        >
          <span>Edit</span>
        </div>,
        <div
          key={`delete-template-${name}`}
          onClick={() => {
            setSelectedTemplate(name)
            setModalVisible(true)
          }}
        >
          Delete
        </div>,
      ]
    },
    [openFormPage],
  )

  const getData = async () => {
    if (!provider || !device || !partition) return
    setLoading(true)
    return httpClient
      .get(`${apiPrefix}/vrrp-a/fail-over-policy-template`)
      .then(res => {
        const templates = res?.data?.['fail-over-policy-template-list'] || []
        setFormattedData(
          templates.map((template: IObject) => {
            const { name } = template
            return {
              name,
              trackingOptions: formatTrackingOptions(template),
            }
          }),
        )
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false)
      })
  }

  const formatTrackingOptions = (template: IObject) => {
    const types = [
      'interface',
      'gateway',
      'bgp',
      'trunk-cfg',
      'route',
      'vlan-cfg',
    ]

    const trackingOptions = types.reduce((acc, type) => {
      if (template[type]) {
        switch (type) {
          case 'interface': {
            const items = template[type].map((item: IObject) => (
              <span
                className={styles.collectionValue}
              >{`Eth${item.ethernet}, Weight: ${item.weight}`}</span>
            ))
            acc['Interface Ethernet'] = items

            break
          }
          case 'gateway': {
            const items = ['gw-ipv4-address-cfg', 'gw-ipv6-address-cfg'].reduce(
              (gwAcc, protocol: string) => {
                const list = template[type][protocol]
                let _gwAcc = gwAcc
                if (list) {
                  _gwAcc = _gwAcc.concat(
                    list.map((item: IObject) => (
                      <span className={styles.collectionValue}>{`${
                        item[
                          `gw-${
                            protocol === 'gw-ipv4-address-cfg' ? 'ipv4' : 'ipv6'
                          }-address`
                        ]
                      }, Weight: ${item.weight}`}</span>
                    )),
                  )
                }
                return _gwAcc
              },
              [],
            )
            acc['Gateway'] = items

            break
          }
          case 'bgp': {
            const items = [
              'bgp-ipv4-address-cfg',
              'bgp-ipv6-address-cfg',
            ].reduce((bgpAcc, protocol: string) => {
              const list = template[type][protocol]
              let _bgpAcc = bgpAcc
              if (list) {
                _bgpAcc = _bgpAcc.concat(
                  list.map((item: IObject) => (
                    <span className={styles.collectionValue}>{`${
                      item[
                        `bgp-${
                          protocol === 'bgp-ipv4-address-cfg' ? 'ipv4' : 'ipv6'
                        }-address`
                      ]
                    }, Weight: ${item.weight}`}</span>
                  )),
                )
              }
              return _bgpAcc
            }, [])
            acc['BGP'] = items

            break
          }
          case 'trunk-cfg': {
            const items = template[type].map((item: IObject) => (
              <span className={styles.collectionValue}>{`Trunk${
                item.trunk
              }, Weight: ${item.weight}${
                item['per-port-weight']
                  ? `, Per Port Weight: ${item['per-port-weight']}`
                  : ''
              }`}</span>
            ))
            acc['Trunk'] = items

            break
          }
          case 'route': {
            const items = ['ip-destination-cfg', 'ipv6-destination-cfg'].reduce(
              (routeAcc, protocol: string) => {
                const list = template[type][protocol]
                let _routeAcc = routeAcc
                if (list) {
                  _routeAcc = _routeAcc.concat(
                    list.map((item: IObject) => {
                      let ipDest,
                        mask,
                        gateway = ''
                      const weight = `, Weight: ${item.weight}`
                      const distance = item.distance
                        ? `, Distance: ${item.distance}`
                        : ''
                      const routeProtocol = item.protocol
                        ? `, Protocol: ${item.protocol}`
                        : ''

                      if (protocol === 'ip-destination-cfg') {
                        ipDest = `IP Dest.: ${item['ip-destination']}`
                        mask = item.mask ? `, Mask: ${item.mask}` : ''
                        gateway = item.gateway
                          ? `, Gateway: ${item.gateway}`
                          : ''
                      } else {
                        ipDest = `IP Dest.: ${item['ipv6-destination']}`
                        gateway = item.gateway
                          ? `, Gateway: ${item.gatewayv6}`
                          : ''
                      }

                      return (
                        <span className={styles.collectionValue}>
                          {ipDest +
                            mask +
                            weight +
                            gateway +
                            distance +
                            routeProtocol}
                        </span>
                      )
                    }),
                  )
                }
                return _routeAcc
              },
              [],
            )
            acc['Router'] = items

            break
          }
          case 'vlan-cfg': {
            const items = template[type].map((item: IObject) => (
              <span
                className={styles.collectionValue}
              >{`VLAN${item.vlan}, Timeout: ${item.timeout}, Weight: ${item.weight}`}</span>
            ))
            acc['VLAN'] = items

            break
          }
        }
      }
      return acc
    }, {})

    return Object.entries(trackingOptions).map(
      (option: [string, JSX.Element[]]) => {
        const [name, items] = option

        if (items.length > 0) {
          return (
            <div className={styles.collection}>
              <span className={styles.collectionTitle}>{name}: </span>
              {items}
            </div>
          )
        }

        return null
      },
    )
  }

  useEffect(() => {
    getData()
  }, [apiPrefix, clustersUpdated])

  useEffect(() => {
    if (shouldTemplateUpdate) {
      getData().then(() => setShouldTemplateUpdate(false))
    }
  }, [shouldTemplateUpdate])

  const columns = useMemo(() => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        sorter: (a: string, b: string) => utilities.sortString(a, b, 'name'),
      },
      {
        title: 'Tracking Options',
        dataIndex: 'trackingOptions',
        key: 'trackingOptions',
        render: (value: JSX.Element) => {
          return <div className={styles.collectionsInTable}>{value}</div>
        },
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (text: string, record: IObject) => {
          if (isOperatorUser) return null

          const { name } = record
          return (
            <A10DropdownMenu
              trigger="hover"
              placement="bottomRight"
              arrowPointAtCenter={true}
              menu={moreOptions(name)}
            />
          )
        },
      },
    ]
  }, [isOperatorUser, moreOptions])

  return (
    <>
      <div ref={container} className={styles.sectionContent}>
        {loading ? (
          <A10Loader container={container} />
        ) : (
          <>
            <A10Table
              columns={columns}
              dataSource={formattedData}
              bordered={false}
            />
            {!loading && !isOperatorUser && (
              <ActionButton
                className={styles.actionButton}
                text="Add"
                size="default"
                onClick={openFormPage}
                iconProps={{ app: 'global', type: 'add-new' }}
              />
            )}
          </>
        )}
      </div>
      <A10SlidingPage
        isOpen={slidingPageOpen}
        modalSize="large"
        onRequestClose={closeFormPage}
      >
        {slidingPageOpen &&
          renderForm({
            schemaPath: 'system/vrrpa/failover-policy-template',
            apiPrefix,
            params: selectedTemplate && { name: selectedTemplate },
            successCallback: submitCallback,
            errorCallback: submitCallback,
            cancelCallback: closeFormPage,
          })}
      </A10SlidingPage>
      <ForceDeleteDialog
        visible={modalVisible}
        closeModal={closeModal}
        api={`${apiPrefix}/vrrp-a/fail-over-policy-template/${selectedTemplate}`}
        name={selectedTemplate}
        category="failover template"
        onDeleteComplete={getData}
      />
    </>
  )
}

export default FailoverTemplateList
