import React from 'react'
import { A10Component } from '@gui-libraries/framework'
import { A10Icon, A10Button, A10Row, A10Col } from '@gui-libraries/widgets'

// tslint:disable:no-var-requires
const styles = require('./styles/index.module.less')

export interface IFormatFormProps {
  title?: string
  description?: string
  children: React.ReactElement<any> | Array<React.ReactElement<any>>
  showBack?: boolean
  saveText?: string
  disableSave?: boolean
  hideCancel?: boolean
  isRightCancel?: boolean
  isShowFooterButtons?: boolean
  customizedButtons?: React.ReactElement<any>
  onRequestOk?: (isOpen: boolean) => void
  onRequestClose?: (isOpen: boolean) => void
}

class FormatForm extends A10Component<IFormatFormProps> {
  static defaultProps = {
    showBack: false,
    disableSave: false,
    hideCancel: false,
    isRightCancel: false,
    isShowFooterButtons: true,
    onRequestOk: (isOpen: boolean) => {
      return
    },
    onRequestClose: (isOpen: boolean) => {
      return
    },
  }

  onClickSaveButton = () => {
    const { onRequestOk } = this.props
    onRequestOk(false)
  }

  onClickCancelButton = () => {
    const { onRequestClose } = this.props
    onRequestClose(false)
  }

  onClickBack = () => {
    const { onRequestClose } = this.props
    onRequestClose(false)
  }

  render() {
    const {
      title,
      description,
      children,
      showBack,
      saveText,
      disableSave,
      hideCancel,
      customizedButtons,
      isRightCancel,
      isShowFooterButtons,
    } = this.props

    return (
      <>
        <div className={styles.a10SlidingPageContent}>
          <A10Row className={styles.a10SlidingPageTitle}>
            <A10Col span={20}>
              {title}
            </A10Col>
            <A10Col span={4}>
              {isRightCancel ? (
                <div className={styles.rightCancelBtn}>
                  <a className="back-link" onClick={this.onClickBack}>
                    <A10Icon app="global" type="close" />
                  </a>
                </div>
              ) : (
                ''
              )}
            </A10Col>
          </A10Row>
          <div className={styles.a10SlidingPageDescription}>{description}</div>
          {children}
        </div>
        {isShowFooterButtons ? (
          <div className={styles.a10SlidingPageFooter}>
            {showBack ? (
              <a className="back-link" onClick={this.onClickBack}>
                <A10Icon type="double-left" /> Back
              </a>
            ) : null}
            <A10Button
              type="primary"
              className="submit-button"
              onClick={this.onClickSaveButton}
              disabled={disableSave}
            >
              {saveText ? saveText : 'Save'}
            </A10Button>
            {customizedButtons ? customizedButtons : null}
            {hideCancel ? null : (
              <A10Button
                className="cancel-button"
                onClick={this.onClickCancelButton}
              >
                Cancel
              </A10Button>
            )}
          </div>
        ) : (
          ''
        )}
      </>
    )
  }
}
export default FormatForm
