import { _ } from '@gui-libraries/framework'
import storage from 'src/libraries/storage'

export const getAllTenants = () => {
  return storage.get.ALLTENANTS
}

export const getCurrentTenant = () => {
  try {
    const currentJSON = storage.get.CURRENT_TENANT
    let dashboardCurrentTenant = {}
    if (!currentJSON.uuid) {
      dashboardCurrentTenant = getAllTenants().filter((tenant: IObject) => {
        return tenant.name === currentJSON.name
      })[0]
      return dashboardCurrentTenant
    }
    return storage.get.CURRENT_TENANT
  } catch {
    /* istanbul ignore next */
    return undefined
  }
}

export const isTenantDashboard = () => {
  return storage.get.DASHBOARD_PARENT_TYPE === 'tenant'
}

export const isClusterDashboard = () => {
  return storage.get.DASHBOARD_PARENT_TYPE === 'cluster'
}

export const getCurrentClusterId = () => {
  try {
    const currentJSON = storage.get.DASHBOARD_CURRENT_CLUSTER
    const isCluster = storage.get.DASHBOARD_PARENT_TYPE === 'cluster'
    if (currentJSON && isCluster) {
      return currentJSON['cluster-uuid']
    }
  } catch {
    /* istanbul ignore next */
    return undefined
  }
}

export const getTenants = () => {
  const currentTenant = getCurrentTenant()
  const tenants = currentTenant ? [currentTenant] : getAllTenants()
  return tenants
}

export const getTenantUuids = (splitChar: string = ',') => {
  const tenants = getTenants()
  const tenantUuids = tenants.map((tenant: IObject) => {
    return tenant.uuid
  })
  return tenantUuids.join(splitChar)
}

export const getTenantByUuid = (uuid: string) => {
  const tenants = getTenants()
  const tenantMatch = tenants.filter((tenant: IObject) => {
    return tenant.uuid === uuid
  })
  return tenantMatch[0]
}

export const hasChangedTimePeriod = (
  timePeriod: IObject = {},
  nextTimePeriod: IObject,
) => {
  const { startTime, endTime } = nextTimePeriod
  return startTime !== timePeriod.startTime || endTime !== timePeriod.endTime
}

export const getProvider = () => {
  return storage.get.PROVIDER
}

export const analysisData = (logList: IObject = {}) => {
  try {
    const { logs = {}, total = {}, severity = {}, ...filterData } =
      logList || {}
    const dataSource = _.isString(logs) ? [] : _.values(logs) || []
    const totalValue = _.isString(total) ? 0 : _.first(_.values(total)) || 0
    return [
      dataSource.map((item: IObject) => {
        if (item) {
          item.key = _.uniqueId()
        }
        return item
      }),
      totalValue,
      { severity, ...filterData },
      severity,
    ]
  } catch (err) {
    console.error(err)
  }
}

export const getKeyNameList = () => {
  let keyNameList: IObject = null
  try {
    const keyList = storage.get['KEY-NAME-LIST']
    if (_.isObject(keyList) && !_.isNil(keyList)) {
      keyNameList = keyList
    }
  } catch {
    console.log("KEY-NAME-LIST didn't follow the JSON format.")
  }
  return keyNameList
}

export const setKeyNameList = (keyList: IObject) => {
  let keyNameList: IObject = {}
  if (_.isObject(keyList) && !_.isNil(keyList)) {
    keyNameList = keyList
  }
  storage.set['KEY-NAME-LIST'](keyNameList)
}

export const findUuidInKeyList = (uuid: string) => {
  let keyNameList: IObject = {}
  try {
    const keyList = storage.get['KEY-NAME-LIST']
    if (_.isObject(keyList) && !_.isNil(keyList)) {
      keyNameList = keyList
    }
  } catch {
    console.log("KEY-NAME-LIST didn't follow the JSON format.")
  }
  return keyNameList[uuid]
}

export const setUuidObject = (uuid: string, obj: IObject) => {
  let keyNameList: IObject = {}
  try {
    const keyList = storage.get['KEY-NAME-LIST']
    if (_.isObject(keyList) && !_.isNil(keyList)) {
      keyNameList = keyList
    }
  } catch {
    console.log("KEY-NAME-LIST didn't follow the JSON format.")
  }
  keyNameList[uuid] = obj
  storage.set['KEY-NAME-LIST'](keyNameList)
}

export const isOSILayer4 = (protocol: string = '') => {
  const capitcalProtocol = protocol.toUpperCase()
  const l4List = [
    'AEP',
    'AH',
    'DCCP',
    'ESP',
    'FCP',
    'NETBIOS',
    'IL',
    'ISCSI',
    'NBF',
    'SCTP',
    'SINEC',
    'TUP',
    'SPX',
    'NBP',
    'TCP',
    'UDP',
  ]
  return l4List.indexOf(capitcalProtocol) > -1
}
