import React from 'react'
import ReactLoading from 'react-loading'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Collapse,
  A10Checkbox,
  A10Tree,
  A10Button,
  A10Input,
  A10Icon,
} from '@gui-libraries/widgets'

import { HealthStatus } from 'src/components/ADC/HealthStatus'
import storage from 'src/libraries/storage'
import Selection from './Selection'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { DashboardService } from 'src/services/DashboardService'

import styles from './styles/index.module.less'

const Panel = A10Collapse.Panel
const { TreeNode } = A10Tree
const Search = A10Input.Search
export interface IClusterDevicePartitionProps
  extends IA10ContainerDefaultProps {
  onChange?: (data: any) => void
  height?: number
  devicePartitions?: any[]
  radioCheckbox?: boolean
  onlySearch?: boolean
  isDropdown?: boolean
  tenantName?: string
  devicesDetail?: Storage.IDevice[]
  tenantDetail?: Storage.ITenant
}
export interface IClusterDevicePartitionState {
  loading: boolean
  height: number
  clusterTreeData: any[]
  filteredClusterTreeData: any[]
  devicePartitions: any[]
  partitions: string[]
  searchString: string
  isSelectAll: boolean
  autoExpandParent: boolean
  expandedKeys: string[]
}

class ClusterDevicePartition extends A10Container<
  IClusterDevicePartitionProps,
  IClusterDevicePartitionState
> {
  clusterList: Storage.ICluster[] = []
  deviceList: Storage.IDevice[] = []
  currentTenant: Storage.ITenant
  devicePartitionCount: number = 0
  DashboardService = new DashboardService()

  constructor(props: any) {
    super(props)
    this.state = {
      loading: false,
      height: this.props.height || 400,
      clusterTreeData: [],
      filteredClusterTreeData: [],
      devicePartitions: this.props.devicePartitions || [],
      partitions: [],
      searchString: '',
      isSelectAll: false,
      autoExpandParent: false,
      expandedKeys: [],
    }
  }

  componentDidUpdate(prevProps: IClusterDevicePartitionProps) {
    if (
      prevProps?.devicesDetail !== this.props?.devicesDetail ||
      prevProps?.tenantDetail !== this.props?.tenantDetail
    ) {
      this.prepareClusterTreeData()
    }
  }

  componentDidMount() {
    const that = this
    that.setState({ loading: true })
    setTimeout(() => {
      that.prepareClusterTreeData()
    }, 5000)
  }

  prepareClusterTreeData = async () => {
    const { tenantDetail } = this.props

    this.clusterList = storage.get.ALLCLUSTERS
    this.deviceList = this.props.devicesDetail || storage.get.ALLDEVICES

    //get detailed tenant object

    if (!tenantDetail) {
      const provider = storage.get.PROVIDER
      const res = await this.DashboardService.getTenantObj(null, null, [
        provider,
        storage.get.CURRENT_TENANT?.name,
      ])
      this.currentTenant = res?.data?.tenant ?? storage.get.CURRENT_TENANT
    } else this.currentTenant = tenantDetail

    const hasCurrTenant = !!this.currentTenant.uuid
    const clusterDevPartitionTreeData: any[] = []
    const expandedKeys: string[] = []
    const checkedPartition: string[] = []
    const selectedDevicePartitions: IObject[] = this.props.devicePartitions

    const isPartitionInTenantLPList = (
      partitionUuid: string,
      tenantLPList: IObject[],
    ): boolean => {
      return tenantLPList.some(tenantLP => {
        return tenantLP?.deploy_target?.target?.['device-list']?.some(
          (device: IObject) => {
            return device?.['partition-list']?.some(
              (partition: IObject) => partition.uuid === partitionUuid,
            )
          },
        )
      })
    }

    this.clusterList.forEach(cluster => {
      const devices: IObject[] = []
      this.deviceList.forEach(device => {
        const selectedDevices: IObject[] = selectedDevicePartitions?.filter(
          selectedDevicePartition =>
            selectedDevicePartition.name === device.name,
        )

        if (device.cluster === cluster.name) {
          const devicePartitionList: IObject[] = device['partition-list'] || []
          const partitions: IObject[] = []
          devicePartitionList.forEach(partition => {
            if (
              hasCurrTenant &&
              !isPartitionInTenantLPList(
                partition.uuid,
                this.currentTenant['logical-partition-list'],
              )
            ) {
              return
            }
            partitions.push({
              title: partition.name,
              type: 'partition',
              device: device.name,
              key: partition.uuid,
              isLeaf: true,
            })
            this.devicePartitionCount++
            if (selectedDevices?.length) {
              const selectedPartition = selectedDevices.find(
                selectedDevice => selectedDevice.partition === partition.name,
              )
              if (selectedPartition?.partition) {
                checkedPartition.push(partition.uuid)
              }
            }
          })
          if (partitions.length > 0) {
            devices.push({
              title: device.name,
              type: 'device',
              key: 'device:' + device['device-uuid'],
              children: partitions,
            })
          }
        }
      })
      if (devices.length > 0) {
        clusterDevPartitionTreeData.push({
          title: cluster['display-name'] || cluster.name,
          type: 'cluster',
          key: 'cluster:' + cluster['cluster-uuid'],
          children: devices,
        })
        expandedKeys.push('cluster:' + cluster['cluster-uuid'])
      }
    })

    this.setState({
      loading: false,
      clusterTreeData: clusterDevPartitionTreeData,
      filteredClusterTreeData: clusterDevPartitionTreeData,
      expandedKeys,
      partitions: checkedPartition,
      autoExpandParent: true,
    })
  }

  onSearch = (e: any) => {
    const value = e.target.value
    const expandedKeys: string[] = []
    const filteredClusterTreeData: any[] = []
    const { partitions } = this.state
    let partitionCount = 0
    this.state.clusterTreeData.map((cluster: any) => {
      if (cluster.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
        expandedKeys.push(cluster.key)
        filteredClusterTreeData.push(cluster)
      } else if (cluster.children) {
        const filteredDeviceTreeData: any[] = []
        cluster.children.forEach((device: any) => {
          if (device.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
            expandedKeys.push(device.key)
            expandedKeys.push(cluster.key)
            filteredDeviceTreeData.push(device)
          } else if (device.children) {
            const filteredPartitionTreeData: any[] = []
            device.children.forEach((partition: any) => {
              if (
                partition.title.toLowerCase().indexOf(value.toLowerCase()) > -1
              ) {
                expandedKeys.push(device.key)
                expandedKeys.push(cluster.key)
                filteredPartitionTreeData.push(partition)
              }
            })

            if (filteredPartitionTreeData.length > 0) {
              const deviceObj = _.cloneDeep(device)
              deviceObj.children = filteredPartitionTreeData
              filteredDeviceTreeData.push(deviceObj)
            }
          }
        })

        if (filteredDeviceTreeData.length > 0) {
          const clusterObj = _.cloneDeep(cluster)
          clusterObj.children = filteredDeviceTreeData
          filteredClusterTreeData.push(clusterObj)
        }
      }
    })

    filteredClusterTreeData.map((cluster: any) => {
      cluster.children?.forEach((device: any) => {
        partitionCount += device.children.length || 0
      })
    })

    this.setState({
      expandedKeys,
      filteredClusterTreeData,
      searchString: value,
      autoExpandParent: true,
      isSelectAll: partitionCount === partitions.length,
    })
  }

  onCheck = (checkedKeys: any, event: any) => {
    const { partitions } = this.state
    let currentCheckedPartition = ''
    let currentCheckedDevice = ''
    const partitionLists: string[] = []
    const devicePartitions: any[] = []

    event.checkedNodes?.map((node: any, index: number) => {
      if (
        node.props.dataRef.key.indexOf('cluster:') === -1 &&
        node.props.dataRef.key.indexOf('device:') === -1
      ) {
        if (partitions.indexOf(node.props.dataRef.key) === -1) {
          currentCheckedPartition = node.props.dataRef.key
          currentCheckedDevice = node.props.dataRef.device
        }
      }
    })

    event.checkedNodes?.map((node: any, index: number) => {
      if (
        node.props.dataRef.key.indexOf('cluster:') === -1 &&
        node.props.dataRef.key.indexOf('device:') === -1
      ) {
        if (
          !this.props.radioCheckbox ||
          (this.props.radioCheckbox &&
            (currentCheckedDevice !== node.props.dataRef.device ||
              currentCheckedPartition === node.props.dataRef.key))
        ) {
          devicePartitions.push({
            name: node.props.dataRef.device,
            partition: node.props.dataRef.title,
          })
          partitionLists.push(node.props.dataRef.key)
        }
      }
    })

    this.props.onChange(devicePartitions)
    this.setState({
      devicePartitions,
      partitions: partitionLists,
      isSelectAll: this.devicePartitionCount === partitionLists.length,
    })
  }

  onChangeSelection = (selected: IObject[]) => {
    const partitionLists = selected.map((value: IObject) => {
      return value.partition
    })
    this.props.onChange(selected)
    this.setState({
      devicePartitions: selected,
      partitions: partitionLists,
      isSelectAll: this.devicePartitionCount === partitionLists.length,
    })
  }

  onExpand = (expandedKeys: any, event: any) => {
    const clusterDevices: string[] = []
    expandedKeys.map((keys: string) => {
      if (keys.indexOf('cluster:') > -1 || keys.indexOf('device:') > -1) {
        clusterDevices.push(keys)
      }
    })
    this.setState({ expandedKeys: clusterDevices, autoExpandParent: false })
  }

  onSelectAll(event: any) {
    const selectedPartitions: any[] = []
    const devicePartitions: any[] = []
    const isSelectAll = event.target.checked
    const { partitions, clusterTreeData } = this.state
    const { onChange } = this.props

    if (isSelectAll) {
      const { filteredClusterTreeData } = this.state
      filteredClusterTreeData.map((node: any) => {
        if (node.children) {
          node.children.map((device: any) => {
            if (device.children) {
              device.children.map((partition: any) => {
                selectedPartitions.push(partition.key)
                devicePartitions.push({
                  name: partition.device,
                  partition: partition.title,
                })
              })
            }
          })
        }
      })

      clusterTreeData.map((node: any) => {
        if (node.children) {
          node.children.map((device: any) => {
            if (device.children) {
              device.children.map((partition: any) => {
                if (
                  partitions.includes(partition.key) &&
                  selectedPartitions.includes(partition.key)
                ) {
                  selectedPartitions?.push(partition.key)
                  devicePartitions.push({
                    name: partition.device,
                    partition: partition.title,
                  })
                }
              })
            }
          })
        }
      })
      onChange(devicePartitions)
      this.setState({
        partitions: selectedPartitions,
        devicePartitions,
        isSelectAll,
      })
    } else {
      onChange([])
      this.setState({
        devicePartitions: [],
        partitions: [],
        isSelectAll: false,
      })
    }
  }

  onClearAll = () => {
    this.props.onChange([])
    this.setState({
      devicePartitions: [],
      partitions: [],
      isSelectAll: false,
    })
  }

  renderTreeNodes = (data: any[]) =>
    data.map((item: any) => {
      const titleNode =
        this.state.searchString &&
        item.title
          .toLowerCase()
          .indexOf(this.state.searchString.toLowerCase()) > -1 ? (
          <b>{item.title}</b>
        ) : (
          item.title
        )
      if (item.children) {
        return (
          <TreeNode
            icon={
              <HealthStatus
                type="ongoing"
                text={item.type === 'cluster' ? 'C' : 'D'}
                hideTooltip={true}
              />
            }
            checkable={item.type === 'cluster' ? false : true}
            title={titleNode}
            key={item.key}
            dataRef={item}
          >
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        )
      }

      return (
        <TreeNode
          icon={<HealthStatus type="ongoing" text="P" hideTooltip={true} />}
          title={titleNode}
          key={item.key}
          dataRef={item}
        />
      )
    })

  render() {
    const { isDropdown } = this.props
    const {
      loading,
      height,
      autoExpandParent,
      expandedKeys,
      filteredClusterTreeData,
      devicePartitions,
      partitions,
    } = this.state

    const tree = (
      <A10Tree
        className={styles.panelContent}
        checkable={true}
        showIcon={true}
        defaultExpandAll={true}
        expandedKeys={expandedKeys}
        onExpand={this.onExpand}
        autoExpandParent={autoExpandParent}
        onCheck={(checkedData, event) => this.onCheck(checkedData, event)}
        checkedKeys={partitions}
        style={{ height }}
      >
        {this.renderTreeNodes(filteredClusterTreeData)}
      </A10Tree>
    )
    return (
      <A10Collapse defaultActiveKey={['1']} className={styles.cdpPanel}>
        {isDropdown ? (
          <Panel
            key="1"
            showArrow={false}
            disabled
            header={
              <A10IconTextGroup
                text={
                  <span className={styles.title}>
                    Select Device to Deploy to
                  </span>
                }
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            }
          >
            <div className={styles.selectionPanel}>
              <Selection
                value={devicePartitions}
                onChange={this.onChangeSelection}
                content={tree}
              />
            </div>
          </Panel>
        ) : (
          <Panel
            key="1"
            showArrow={false}
            disabled
            className={styles.panelDiv}
            header={
              <div className={`row ${styles.panelHeader}`}>
                {this.props.onlySearch ? (
                  <Search
                    style={{ marginBottom: 4, marginTop: 4 }}
                    placeholder="Search"
                    onChange={this.onSearch}
                  />
                ) : (
                  <>
                    <div className="col-md-3">
                      <A10Checkbox
                        onChange={(e: any) => {
                          this.onSelectAll(e)
                        }}
                        checked={this.state.isSelectAll}
                        disabled={
                          this.props.radioCheckbox ||
                          (!this.props.radioCheckbox &&
                            this.state.filteredClusterTreeData.length === 0)
                        }
                      >
                        Select All
                      </A10Checkbox>
                    </div>
                    <div className="col-md-6">
                      <span>
                        <label className="selCount">
                          {devicePartitions.length + ' Selected'}
                        </label>
                      </span>
                      <span>
                        <A10Button
                          className="action-button blue-text"
                          disabled={devicePartitions.length === 0}
                          onClick={this.onClearAll}
                        >
                          Clear Select
                        </A10Button>
                      </span>
                    </div>
                    <div className="col-md-3">
                      <Search
                        style={{ marginBottom: 8 }}
                        placeholder="Search"
                        onChange={this.onSearch}
                      />
                    </div>
                  </>
                )}
              </div>
            }
          >
            {loading ? (
              <div style={{ height }}>
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </div>
            ) : (
              tree
            )}
          </Panel>
        )}
      </A10Collapse>
    )
  }
}

export default setupA10Container(ClusterDevicePartition)
