import React from 'react'

export const NavAnalytics = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="195"
    height="536"
    viewBox="0 0 195 536"
  >
    <defs>
      <filter
        id="9l3vuvbq3a"
        width="115.5%"
        height="141.7%"
        x="-7.7%"
        y="-20.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="31.136" />
      </filter>
      <filter
        id="hyxd3yjxkb"
        width="101%"
        height="101.7%"
        x="-.5%"
        y="-.9%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
      </filter>
      <rect id="8gf4zi2x8c" width="1200" height="694" x="0" y="0" rx="4" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <path
          fill="#FAFBFF"
          d="M0 0H1280V860H0z"
          transform="translate(-317.000000, -185.000000)"
        />
        <path
          fill="#D8D8D8"
          fill-opacity=".5"
          d="M0 0H1281V859H0z"
          transform="translate(-317.000000, -185.000000)"
        />
        <g>
          <path
            fill="#000"
            fill-opacity=".2"
            d="M157.07 290h887.77c3.344 0 6.335 2.08 7.5 5.216l148.862 400.953c1.538 4.142-.573 8.747-4.715 10.284-.67.25-1.37.407-2.083.47C958.9 727.64 776.697 738 647.794 738c-129.263 0-342.623-10.417-640.078-31.251-4.407-.31-7.73-4.132-7.421-8.54.053-.76.215-1.51.48-2.225L149.57 295.216c1.164-3.136 4.155-5.216 7.5-5.216z"
            filter="url(#9l3vuvbq3a)"
            transform="translate(-317.000000, -185.000000) translate(43.000000, 89.000000)"
          />
          <g transform="translate(-317.000000, -185.000000) translate(43.000000, 89.000000)">
            <use
              fill="#000"
              filter="url(#hyxd3yjxkb)"
              xlinkHref="#8gf4zi2x8c"
            />
            <use fill="#FFF" xlinkHref="#8gf4zi2x8c" />
          </g>
          <path
            fill="#2A3D56"
            d="M274 96H469V633H274z"
            transform="translate(-317.000000, -185.000000) translate(43.000000, 89.000000)"
          />
          <g>
            <g>
              <g stroke="#FFF" stroke-linecap="round">
                <path
                  d="M23.5 11.668V22.54c0 .53-.43.959-.959.959H1.46c-.53 0-.959-.43-.959-.959V1.46C.5.929.928.5 1.459.5H22.54c.53 0 .959.43.959.959v1.777"
                  transform="translate(-317.000000, -185.000000) translate(43.000000, 89.000000) translate(290.000000, 126.000000) translate(-0.000000, 0.000000)"
                />
                <path
                  d="M16.977 12.712l3.886-4.207m-9.421 2.2l3.188 2.133m-9.207.482l3.337-2.486m-2.895 3.518c0 .791-.642 1.433-1.433 1.433-.79 0-1.432-.642-1.432-1.433 0-.79.641-1.432 1.432-1.432.791 0 1.433.641 1.433 1.432zm5.76-4.335c0 .79-.642 1.432-1.433 1.432-.79 0-1.431-.641-1.431-1.432 0-.791.64-1.433 1.431-1.433s1.433.642 1.433 1.433zm5.815 3.744c0 .79-.641 1.431-1.432 1.431-.79 0-1.432-.64-1.432-1.431s.641-1.432 1.432-1.432c.791 0 1.432.641 1.432 1.432zm5.823-6.305c0 .791-.641 1.433-1.432 1.433-.791 0-1.432-.642-1.432-1.433 0-.79.64-1.432 1.432-1.432.79 0 1.432.641 1.432 1.432z"
                  transform="translate(-317.000000, -185.000000) translate(43.000000, 89.000000) translate(290.000000, 126.000000) translate(-0.000000, 0.000000)"
                />
              </g>
              <text
                fill="#FFF"
                font-family="Rubik-Regular, Rubik"
                font-size="16"
                transform="translate(-317.000000, -185.000000) translate(43.000000, 89.000000) translate(290.000000, 126.000000)"
              >
                <tspan x="34" y="18">
                  Analytics
                </tspan>
              </text>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-317.000000, -215.000000) translate(43.000000, 89.000000) translate(290.000000, 126.000000) translate(7.000000, 70.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Cluster
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-317.000000, -215.000000) translate(43.000000, 89.000000) translate(290.000000, 126.000000) translate(7.000000, 106.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Device
                  </tspan>
                </text>
              </g>
            </g>
          </g>
          <g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-317.000000, -215.000000) translate(43.000000, 89.000000) translate(290.000000, 280.000000) translate(8.000000, 34.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    App Services
                  </tspan>
                </text>
              </g>
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(-317.000000, -215.000000) translate(43.000000, 89.000000) translate(290.000000, 280.000000) translate(8.000000, 66.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    Logical Partitions
                  </tspan>
                </text>
              </g>
            </g>
            <g>
              <g stroke="#FFF" stroke-linecap="round">
                <path
                  d="M20.458 10.3V6.262L10.471.5h0L.501 6.261v11.523l9.77 5.716 1.815-1.018M.5 6.492l9.771 5.869 10.187-5.87m-10.186 5.87V23.5"
                  transform="translate(-317.000000, -215.000000) translate(43.000000, 89.000000) translate(290.000000, 280.000000) translate(-0.000000, 0.000000)"
                />
                <path
                  d="M17.75 19.5c-.965 0-1.75-.785-1.75-1.75S16.785 16 17.75 16c.966 0 1.75.785 1.75 1.75s-.784 1.75-1.75 1.75zm.732 3.595h-1.32c-.108 0-.196-.088-.196-.196v-1.182c-.507-.106-.977-.3-1.406-.578l-.832.833c-.077.077-.202.077-.279 0l-.932-.933c-.077-.077-.077-.2 0-.278l.832-.832c-.28-.429-.474-.901-.58-1.408h-1.181c-.109 0-.196-.088-.196-.197v-1.32c0-.107.087-.195.196-.195h1.18c.106-.508.3-.98.58-1.408l-.832-.833c-.077-.076-.077-.2 0-.277l.932-.934c.077-.076.201-.076.278 0l.832.833c.428-.28.9-.473 1.408-.58v-1.18c0-.108.088-.197.196-.197h1.32c.108 0 .195.089.195.196v1.18c.508.108.98.301 1.409.583l.833-.835c.077-.076.201-.076.278 0l.932.934c.077.077.077.2 0 .277l-.832.835c.278.428.472.9.577 1.406h1.183c.108 0 .197.088.197.196v1.32c0 .108-.089.196-.197.196h-1.183c-.105.505-.3.977-.58 1.406l.835.834c.077.077.077.201 0 .278l-.933.933c-.074.073-.205.073-.279 0l-.833-.835c-.427.28-.9.473-1.407.58V22.9c0 .108-.087.196-.195.196z"
                  transform="translate(-317.000000, -215.000000) translate(43.000000, 89.000000) translate(290.000000, 280.000000) translate(-0.000000, 0.000000)"
                />
              </g>
              <text
                fill="#FFF"
                font-family="Rubik-Regular, Rubik"
                font-size="16"
                transform="translate(-317.000000, -215.000000) translate(43.000000, 89.000000) translate(290.000000, 280.000000)"
              >
                <tspan x="34" y="18">
                  Services
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
