import { getNS, IHTTPRequestOptions } from '@gui-libraries/framework'
import { _ } from '@gui-libraries/framework'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
const HPCAPI_PREFIX = '/hpcapi/v3'

export const getBWList = (params: IObject): IHTTPRequestOptions => {
  return {
    namespace: getNS('BLACK_WHITE_LIST'),
    request: async (epicDependence: IObject) => {
      try {
        const actionURL = `${HPCAPI_PREFIX}/provider/${storage.get.PROVIDER}/tenant/pso/logical-partition/pso/file/bw-list`
        const { data: res } = await httpClient.get(actionURL, { params })
        let bwListList = []
        if (res && res['bw-list-list']) {
          bwListList = res['bw-list-list']
        }

        bwListList = _.sortBy(bwListList, ['name'])
        return bwListList
      } catch (err) {
        console.error(err)
      }
      return []
    },
  }
}

export const getBWListCount = (searchText: string): IHTTPRequestOptions => {
  return {
    namespace: getNS('BLACK_WHITE_COUNT'),
    request: async (epicDependence: IObject) => {
      try {
        const params: IObject = { total: true }
        if (searchText) {
          params.name = encodeURIComponent(searchText)
        }
        const actionURL = `${HPCAPI_PREFIX}/provider/${storage.get.PROVIDER}/tenant/pso/logical-partition/pso/file/bw-list`
        const { data: res } = await httpClient.get(actionURL, { params })
        let bwListCount = 0
        if (res && res['total-count']) {
          bwListCount = res['total-count'] || 0
        }
        return bwListCount
      } catch (err) {
        console.error(err)
      }
      return 0
    },
  }
}
