import React from 'react'
import propTypes from 'prop-types'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10DropdownMenu,
  A10Message,
  A10Modal,
  A10Tag,
  A10Button,
} from '@gui-libraries/widgets'

import ReactLoading from 'react-loading'
import { httpClient } from 'src/libraries/httpClient'
import BackupDetail from './BackupDetail'
import storage from 'src/libraries/storage'
import RoundNumber from 'src/components/shared/RoundNumber'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')
export interface IBackupListListProps extends IA10ContainerDefaultProps {
  data: any[]
  search: string
  isLoading?: boolean
  updateList: any
  getRemainingBackups: () => void
}

export interface IBackupListListState {
  showSlidingPage: boolean
  device: string
  deleteModalState: boolean
}

class BackupList extends A10Container<
  IBackupListListProps,
  IBackupListListState
> {
  static childContextTypes = {
    refresh: propTypes.func.isRequired,
  }
  constructor(props: any) {
    super(props)
    this.state = {
      showSlidingPage: false,
      device: '',
      deleteModalState: false,
    }
  }
  getChildContext() {
    return {
      refresh: this.refresh,
    }
  }

  getColumns = () => {
    return [
      {
        title: 'Device',
        dataIndex: 'name',
        // className: 'td-truncate',
        key: 'name', // TODO: change this key
        render: (text: string, record: IObject, index: number) => {
          const backupCount = record['backup-info-list']
            ? record['backup-info-list'].length
            : 0
          return (
            <>
              <div className={styles.backupNameWrapper}>
                <span title={text}>{text}</span>
              </div>
              <div className={styles.backupCount}>
                <RoundNumber number={backupCount} />
              </div>
            </>
          )
        },
      },
      {
        title: 'Device IP',
        dataIndex: 'host',
        key: 'host',
        render: (text: string, record: any, index: number) => {
          return (
            <div className="text-with-avatar">
              <A10Tag>{text}</A10Tag>
            </div>
          )
        },
      },
      {
        title: 'Timezone',
        dataIndex: 'timezone',
        key: 'timezone',
        render: (text: string, record: any, index: number) => {
          const tzone = record?.['timezone']?.['label'] || 'UTC+00:00'
          return <span>{tzone}</span>
        },
      },
      {
        title: 'Latest backup on',
        dataIndex: 'latest_backup',
        render: (text: string, record: any, index: number) => {
          return <span>{record?.['last-modified-at']}</span>
        },
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (record: any) => {
          return (
            <div className={styles.editColumn}>
              {storage.get.ADMIN_LEVEL === 'provider' &&
              !storage.get.IS_OPERATOR_USER ? (
                <i>
                  <A10DropdownMenu
                    menu={[
                      // <div key="restore">Restore</div>,
                      <div key="delete">Delete</div>,
                    ]}
                    style={{ color: '#757575', marginBottom: '-15px' }}
                    onClick={this.onClickAction.bind(this, record)}
                    trigger="hover"
                    placement="bottomRight"
                    arrowPointAtCenter={true}
                  />
                </i>
              ) : null}
            </div>
          )
        },
      },
    ]
  }

  onClickAction = (record: any, component: JSX.Element) => {
    if (component.key === 'delete') {
      this.setState({ deleteModalState: true, device: record.name })
    }
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({ showSlidingPage: isOpen })
  }

  onCloseSlidingPage = () => {
    this.setSlidingPage(false)
  }

  handleOk = async (e: React.MouseEvent<any>) => {
    const { updateList, search, getRemainingBackups } = this.props
    const { device } = this.state
    const provider = storage.get.PROVIDER
    try {
      await httpClient.delete(
        `/hpcapi/v3/provider/${provider}/device-backup/${device}`,
      )
      updateList(search)
      getRemainingBackups()
      this.setState({ deleteModalState: false })
    } catch (e) {
      // do nothing
      A10Message.error('Error: Failed to delete the config backup')
    }
  }

  refresh = () => {
    const { updateList, search } = this.props
    updateList(search)
  }

  handleCancel = (e: React.MouseEvent<any>) => {
    this.setState({ deleteModalState: false })
  }

  renderBackupDetail = (record: any) => {
    const { getRemainingBackups } = this.props
    return (
      <BackupDetail
        data={record}
        updateList={this.refresh}
        getRemainingBackups={getRemainingBackups}
      />
    )
  }

  getRowKey = (record: IObject) => {
    return record.uuid || record.key
  }

  render() {
    const { data = [] } = this.props
    const tableData = data.filter(item => {
      return !item.archived
    })
    return (
      <>
        <A10Table
          className={styles.hcList}
          rowKey={this.getRowKey}
          columns={this.getColumns()}
          dataSource={tableData.map((item: IObject, index: number) => {
            item.key = index
            return item
          })}
          size="small"
          expandedRowRender={this.renderBackupDetail}
          loading={
            this.props.isLoading
              ? {
                  indicator: (
                    <div className="loading-icon">
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    </div>
                  ),
                }
              : false
          }
        />
        <A10Modal
          title="Confirmation"
          visible={this.state.deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>Do you want to delete this Item?</p>
        </A10Modal>
      </>
    )
  }
}

function mapStateToProps(state: any, props: IBackupListListProps) {
  return {
    data: state.A10Data.getIn(getNS('BACKUP_LIST'), []),
  }
}

export default setupA10Container(BackupList, mapStateToProps)
