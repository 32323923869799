import React from 'react'
import propTypes from 'prop-types'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10SlidingPage } from '@gui-libraries/widgets'

import { IDefaultMethods } from 'src/containers/Controller'
import { ApplicationConfigs } from 'src/constants/ApplicationConfigs'
// import { SubHeader } from 'src/components/shared/SubHeader'
import { AppRoot } from 'src/settings/appRoot'
import { Utilities } from 'src/services/index'
import storage from 'src/libraries/storage'

// import Certificate from 'src/containers/Launchers/ADC//SharedObjects/Certificate'
// import CRL from 'src/containers/Launchers/ADC//SharedObjects/CRL'
import Network from 'src/containers/Launchers/ADC/ProviderSharedObjects/Network'
import System from 'src/containers/Launchers/ADC/ProviderSharedObjects/System'

export interface ISharedObjectsProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  tenantToggled: boolean
  selectedApp: string
  onSelectApp: (selectedApp: string) => void
}
export interface ISharedObjectsState {
  formComponent: JSX.Element | null
  modalSize?: string
  application: IObject
  selectedApp: string
  tenantToggled: boolean
}

class SharedObjects extends A10Container<
  ISharedObjectsProps,
  ISharedObjectsState
> {
  static childContextTypes = {
    openSlidingPage: propTypes.func.isRequired,
    closeSlidingPage: propTypes.func.isRequired,
    setModalSize: propTypes.func,
  }

  state: ISharedObjectsState = {
    formComponent: null,
    modalSize: '',
    application: {
      subApps: [],
      name: '',
    },
    selectedApp: '',
    tenantToggled: false,
  }
  ApplicationConfigs = new ApplicationConfigs()
  AppRoot = new AppRoot()
  Utilities = new Utilities()
  dataLoaded = false

  componentDidMount() {
    if (
      !this.dataLoaded ||
      this.state.tenantToggled !== this.props.tenantToggled
    ) {
      this.updateState()
    }
  }

  componentDidUpdate(
    prevProps: ISharedObjectsProps,
    prevState: ISharedObjectsState,
  ) {
    if (prevState.selectedApp !== this.state.selectedApp) {
      const { onSelectApp } = this.props
      if (onSelectApp instanceof Function) {
        onSelectApp(this.state.selectedApp)
      }
    }
  }

  updateState() {
    const applications = this.ApplicationConfigs.getAvailableApps()
    const applicationIndex = applications.findIndex(key => {
      return key.name === 'SharedObj'
    })
    this.dataLoaded = true
    let selectedSubApp = applications[applicationIndex].subApps.find(
      (subApp: any) => {
        return subApp.name === this.props.selectedApp
      },
    )
    if (selectedSubApp === undefined) {
      selectedSubApp = applications[applicationIndex].subApps[0]
    }
    this.setState({
      application: applications[applicationIndex],
      selectedApp: selectedSubApp.name,
      tenantToggled: this.props.tenantToggled,
    })
    if (
      storage.get.ADMIN_LEVEL === 'provider' &&
      storage.get.CURRENT_SUB_STATE === selectedSubApp.name.toLowerCase()
    ) {
      storage.remove.TENANT()
      storage.remove.CURRENT_TENANT()
      storage.set.DRILL_LEVEL('provider')
      this.AppRoot.setRootScopeElement('currentDrillLevel', 'provider')
    }

    storage.set.CURRENT_STATE('sharedobjects')
    this.AppRoot.setRootScopeElement('currentState', 'sharedobjects')
  }

  updateSelectedApp = (appName: any) => {
    const subAppIndex = this.state.application.subApps.findIndex((key: any) => {
      return key.name === appName
    })
    const subApp = this.state.application.subApps[subAppIndex] || {
      isExternal: false,
    }
    const allowedRoles =
      this.ApplicationConfigs.getAllowedRolesSubApps(
        this.state.application.name,
        appName,
      ) || []
    const adminLevel = storage.get.ADMIN_LEVEL
    const allowedIndex = allowedRoles.findIndex((key: any) => {
      return key === adminLevel
    })
    if (allowedIndex >= 0) {
      if (!subApp.isExternal) {
        this.setState({
          selectedApp: appName,
        })
      } else {
        console.log(
          'something to think about dirceting this module to external place',
        )
      }
    } else {
      this.Utilities.showMessage('INSUFFICENT_PRIVILEGE', 0, 1, '')
    }
  }
  getChildContext() {
    return {
      openSlidingPage: this.openSlidingPage,
      closeSlidingPage: this.closeSlidingPage,
      setModalSize: this.setModalSize,
    }
  }
  openSlidingPage = (formComponent: JSX.Element, modalSize?: string) => {
    this.setState({ formComponent, modalSize })
  }
  setModalSize = (modalSize: string) => {
    this.setState({ modalSize })
  }
  closeSlidingPage = () => {
    this.setState({ formComponent: null, modalSize: '' })
  }

  getSelectedApp = (appName: string) => {
    let app = null
    // if (appName === 'Service') {
    //   app = (
    //     <Service />
    //   )
    // }
    // if (appName === 'Certificates') {
    //   app = (
    //     <Certificate
    //       tenant="pso"
    //       logicalPartition="pso"
    //       scope="provider"
    //     />
    //   )
    // }
    // if (appName === 'CRL') {
    //   app = <CRL tenant="pso" logicalPartition="pso" scope="provider" />
    // }
    if (appName === 'Network') {
      app = <Network tenant="pso" logicalPartition="pso" scope="provider" />
    } else if (appName === 'System') {
      app = <System tenant="pso" logicalPartition="pso" scope="provider" />
    }

    return app
  }

  render() {
    const { formComponent, modalSize } = this.state
    return (
      <div>
        {/* <SubHeader
          defaultMethods={this.props.defaultMethods}
          subApps={this.state.application.subApps}
          click={this.updateSelectedApp}
          reLoadApps={true}
        /> */}

        {this.getSelectedApp(this.props.selectedApp)}
        <A10SlidingPage
          isOpen={!!formComponent}
          modalSize={modalSize}
          onRequestClose={this.closeSlidingPage}
        >
          {formComponent ? (
            <formComponent.type {...formComponent.props} />
          ) : null}
        </A10SlidingPage>
      </div>
    )
  }
}

export default setupA10Container(SharedObjects)
