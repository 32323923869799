import React from 'react'

import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import {
  A10Icon,
  A10Form,
  A10Input,
  A10Row,
  A10AflexCodeEditor,
} from '@gui-libraries/widgets'
import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { HelpInfo } from 'src/components/shared/HelpInfo'

import { Messages } from 'src/locale/en'
import { Utilities } from 'src/services'
const styles = require('./styles/index.module.less')

export interface ISnippetFormProps extends IA10ContainerDefaultProps {
  cmdSnippets: any[]
  selectedCliCmd: any
  form: any
  onRef?: any
  editMode: boolean
  handleChange(key: string, value: any): void
}
export interface ISnippetFormState {
  id: string
  name: string
  content: string
  contentSplit: string
}

class SnippetForm extends A10Container<ISnippetFormProps, ISnippetFormState> {
  Messages = new Messages()
  Utilities = new Utilities()

  constructor(props: ISnippetFormProps) {
    super(props)
    this.state = {
      id: this.props.selectedCliCmd.id,
      name: this.props.selectedCliCmd.name,
      content: this.props.selectedCliCmd.content,
      contentSplit: this.props.selectedCliCmd.contentSplit,
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  validateDuplicateSnippetName = (rule: any, snippetName: any, cb: any) => {
    let isValid: boolean = true
    const index = this.props.cmdSnippets.findIndex((key: any) => {
      return key.name === snippetName && this.state.id !== key.id
    })
    if ('' === snippetName) {
      rule.message = 'Please enter the snippet name'
      isValid = false
    } else if (!this.Utilities.validateName(snippetName)) {
      rule.message =
        "Please enter a valid snippet name, it cannot exceed 30 letters and no special characters (except '-', '_', '.')"
      isValid = false
    } else if (index > -1) {
      rule.message = 'Snippet with same name already exists.'
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  render() {
    const { handleChange, editMode, selectedCliCmd } = this.props
    const { name } = this.state
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 10 },
    }
    const { getFieldDecorator } = this.props.form

    const codeFlexOptions = {
      lineNumbers: true,
      readOnly: '',
    }

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel
          className={styles.panelBodyPad}
          title={
            <A10IconTextGroup
              text={<>Snippet Detail</>}
              icon={
                <A10Icon
                  style={{ width: 22, height: 22, marginRight: 12 }}
                  app="global"
                  type="form-section"
                  className="sliding-panel-icon"
                />
              }
            />
          }
        >
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>{this.Messages.SNIPPET_NAME}</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="left"
                    title={this.Messages.NAME}
                    helpKey="HELP_TROUBLESHOOT_CLISNIPPET_NAME"
                  />
                </span>
              </>
            }
          >
            <div>
              {getFieldDecorator('name', {
                rules: [
                  {
                    validator: this.validateDuplicateSnippetName.bind(this),
                  },
                ],
                initialValue: name,
              })(
                <A10Input
                  type="text"
                  placeholder={this.Messages.SNIPPET_NAME_PH}
                  onChange={handleChange.bind(this, 'name')}
                  disabled={editMode}
                />,
              )}
            </div>
          </A10Form.Item>

          <A10Row
            className={`${styles.codeEditorDiv} ${
              !selectedCliCmd.contentSplit ? styles.placeholderText : ''
            }`}
          >
            <A10AflexCodeEditor
              id={''}
              ref="editor"
              clearWhenEmpty={true}
              className={`${styles.cmdContent}`}
              value={selectedCliCmd?.contentSplit || ''}
              options={codeFlexOptions}
              style={{ height: 250 }}
              onAFlexChange={handleChange.bind(this, 'contentSplit')}
            />
          </A10Row>
        </A10Panel>
      </A10Form>
    )
  }
}
export default setupA10Container(A10Form.create()(SnippetForm))
