import * as basicUtils from './basicUtils'
import { _ } from '@gui-libraries/framework'
import storage from 'src/libraries/storage'

export const getAlert = async (
  httpClient: IObject,
  tenantUuid: string,
  alertDefId: string,
) => {
  const existData = basicUtils.findUuidInKeyList(alertDefId)
  if (existData) {
    return existData
  }
  if (tenantUuid) {
    const alertApi = '/analytics/configuration/ruleassociation/'
    const headers = { 'x-account': tenantUuid }
    const { data: data } = await httpClient.get(`${alertApi}${alertDefId}`, {
      absoluteBasePath: true,
      headers,
    })
    basicUtils.setUuidObject(alertDefId, data)
    return data || {}
  } else {
    return {}
  }
}

export const getTrigger = async (
  httpClient: IObject,
  tenantUuid: string,
  triggerDefId: string,
) => {
  const existData = basicUtils.findUuidInKeyList(triggerDefId)
  if (existData) {
    return existData
  }
  if (tenantUuid) {
    const alertApi = '/analytics/configuration/ruledef/'
    const headers = { 'x-account': tenantUuid }
    const { data: data } = await httpClient.get(`${alertApi}${triggerDefId}`, {
      absoluteBasePath: true,
      headers,
    })
    basicUtils.setUuidObject(triggerDefId, data)
    return data || {}
  } else {
    return {}
  }
}

// /analytics/configuration/actiondef
export const getAction = async (
  httpClient: IObject,
  tenantUuid: string,
  actionDefId: string,
) => {
  const existData = basicUtils.findUuidInKeyList(actionDefId)
  if (existData) {
    return existData
  }
  if (tenantUuid) {
    const alertApi = '/analytics/configuration/actiondef/'
    const headers = { 'x-account': tenantUuid }
    const { data: data } = await httpClient.get(`${alertApi}${actionDefId}`, {
      absoluteBasePath: true,
      headers,
    })
    basicUtils.setUuidObject(actionDefId, data)
    return data || {}
  } else {
    return {}
  }
}

export const getUuid = async (
  httpClient: IObject,
  tenantUuid: string,
  uuid: string,
) => {
  const existData = basicUtils.findUuidInKeyList(uuid)
  if (existData) {
    return existData
  }
  const uuidApi = `/hpcapi/v3/uuid/${uuid}`
  const headers = { 'x-account': tenantUuid }
  const { data: data } = await httpClient.get(uuidApi, {
    absoluteBasePath: true,
    headers,
  })
  basicUtils.setUuidObject(uuid, data)
  return data || {}
}

export const getCluster = async (
  httpClient: IObject,
  tenantUuid: string,
  cluterId: string,
) => {
  const storageKey = `cluster.${cluterId}`
  const existData = basicUtils.findUuidInKeyList(storageKey)
  if (existData) {
    return existData
  }
  const provider = basicUtils.getProvider()
  const alertApi = `/hpcapi/v3/provider/${provider}/cluster?cluster-uuid=`
  const headers = {}
  const { data: data } = await httpClient.get(`${alertApi}${cluterId}`, {
    absoluteBasePath: true,
    headers,
  })
  const { 'cluster-list': clusterList } = data
  const cluster = _.first(clusterList)
  basicUtils.setUuidObject(storageKey, cluster)
  return cluster || {}
}

export const getDevice = async (
  httpClient: IObject,
  tenantUuid: string,
  deviceId: string,
) => {
  const storageKey = `device.${deviceId}`
  const existData = basicUtils.findUuidInKeyList(storageKey)
  if (existData) {
    return existData
  }
  const provider = basicUtils.getProvider()
  const alertApi = `/hpcapi/v3/provider/${provider}/device?device-uuid=`
  const headers = { 'x-account': tenantUuid }
  const { data: data } = await httpClient.get(`${alertApi}${deviceId}`, {
    absoluteBasePath: true,
    headers,
  })
  const { 'device-list': deviceList } = data
  const device = _.first(deviceList)
  basicUtils.setUuidObject(storageKey, device)
  return device || {}
}

export const fetchClusterAndDevice = async (
  httpClient: IObject,
  records: IObject,
  filterData: IObject,
) => {
  const { cluster_id, device_uuid } = filterData

  const clusterKeys = _.isString(cluster_id) ? [] : _.keys(cluster_id)
  const clusterIds = _.uniq(clusterKeys) as string[]

  const deviceKeys = _.isString(device_uuid) ? [] : _.keys(device_uuid)
  const deviceUuids = _.uniq(deviceKeys) as string[]
  records.map((record: IObject) => {
    if (record && record.cluster_id) {
      if (clusterIds.indexOf(record.cluster_id) === -1) {
        clusterIds.push(record.cluster_id)
      }
    }
    if (record && record.device_uuid) {
      if (deviceUuids.indexOf(record.device_uuid) === -1) {
        deviceUuids.push(record.device_uuid)
      }
    }
  })
  const tenantUuid = basicUtils.getTenantUuids()
  const objMapping = {} as IObject
  const filterMapping = {} as IObject

  const deviceIdMapping = {} as IObject
  for (const deviceUuid of deviceUuids) {
    const device = await getDevice(httpClient, tenantUuid, deviceUuid)
    objMapping[deviceUuid] = device
    deviceIdMapping[deviceUuid] = device.name
  }
  filterMapping.device_uuid = deviceIdMapping

  const clusterIdMapping = {} as IObject
  for (const clusterId of clusterIds) {
    const cluster = await getCluster(httpClient, tenantUuid, clusterId)
    clusterIdMapping[clusterId] = cluster.cluster || cluster.name
  }
  filterMapping.cluster_id = clusterIdMapping

  filterMapping.p_id = {
    0: 'Shared',
  }
  return [objMapping, filterMapping]
}

export const fetchTenant = (records: IObject, filterData: IObject) => {
  const { account_id } = filterData
  let tennatUuids = [] as string[]
  records.map((record: IObject) => {
    tennatUuids.push(record.account_id)
  })
  tennatUuids = _.uniq(tennatUuids.concat(_.keys(account_id)))
  const tenants = basicUtils.getAllTenants()
  const tenantMapping = {} as IObject
  const filterMapping = {} as IObject
  tenants.map((tenant: IObject) => {
    if (_.includes(tennatUuids, tenant.uuid)) {
      tenantMapping[tenant.uuid] = tenant
      filterMapping[tenant.uuid] = tenant.name
    }
  })
  return [tenantMapping, { account_id: filterMapping }]
}

const getAppServiceObj = () => {
  try {
    const appserviceList = storage.get.APP_SERVICES
    const appServiceObj = {}
    appserviceList.forEach((item: IObject) => {
      appServiceObj[item.app_svc_id] = item
    })
    return appServiceObj
  } catch (err) {
    return {}
  }
}

const getAppServiceName = (name: string) => {
  const splitName = name.split('.') || []
  if (splitName.length >= 3) {
    splitName.pop()
    splitName.pop()
  }
  return _.join(splitName, '.')
}

export const fetchAppService = (filterData: IObject) => {
  const { app_svc_id } = filterData
  const appSvcIds = _.keys(app_svc_id) || []
  const tenants = basicUtils.getAllTenants()
  const keyNameList = basicUtils.getKeyNameList() || {}
  const filterMapping = {} as IObject
  const unExistList = [] as string[]
  appSvcIds.map((id: string) => {
    const appService = keyNameList[`appsvcid_${id}`]
    if (appService) {
      filterMapping[id] = getAppServiceName(
        appService['display-name'] || appService.name,
      )
    } else {
      unExistList.push(id)
    }
  })
  if (unExistList.length > 0) {
    for (const tenant of tenants) {
      const appSvcList = tenant['app-svc-list'] || []
      for (const appSvc of appSvcList) {
        if (_.includes(unExistList, appSvc.id)) {
          basicUtils.setUuidObject(`appsvcid_${appSvc.id}`, appSvc)
          filterMapping[appSvc.id] = getAppServiceName(
            appSvc['display-name'] || appSvc.name,
          )
        }
      }
    }
  }
  return [{ app_svc_id: filterMapping }]
}
