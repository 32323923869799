import React, { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import { A10Message } from '@gui-libraries/widgets'

import { ActionButton } from 'src/components/shared/ActionButton'
import Card from 'src/components/shared/Card'
import { TroubleShootService } from 'src/services/index'

import styles from './styles/index.module.less'

interface IWeaveStatusProps {}
const troubleshootService = new TroubleShootService()

export const longPollingStatusCheck = async (logID: string) => {
  let status: string
  let newStatus: string = 'RUNNING'
  let outputInfo = null
  do {
    const {
      data: outputData,
    } = await troubleshootService.getRunCommandStatus(null, null, [logID])
    newStatus = outputData?.status
    if (status !== newStatus) {
      status = newStatus
    }
    await new Promise(r => setTimeout(r, 5000))
    outputInfo = outputData
  } while (newStatus === 'PENDING')
  return outputInfo && outputInfo.output
}

const WeaveStatus: React.FC<IWeaveStatusProps> = props => {
  const [weaveStatus, setWeaveStatus] = useState('')
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    const payload = {
      command: 'weave',
      params: ['status'],
      timeOutInSeconds: 10,
      category: 'Kubernetes',
      subCategories: [],
      type: 'command',
      separator: ' ',
      userInput: 'weave status',
    }

    try {
      const { data: logID } = await troubleshootService.postRunCommands(
        null,
        payload,
        null,
      )
      if (logID) {
        const output = await longPollingStatusCheck(logID)
        setWeaveStatus(output)
      }
    } catch (error) {
      const message = `Fail to get Weave Status.`
      A10Message.error(message, 10, close)
    }
    setLoading(false)
  }

  return (
    <div className={styles.statusContainer}>
      <Card
        className={styles.statusCard}
        title="Weave Status"
        toolbar={
          <ActionButton
            text="Refresh"
            testID="refresh-weave-button"
            onClick={() => fetchData()}
            iconProps={{ app: 'global', type: 'refresh' }}
          />
        }
        showTitleDashLine={true}
      >
        <div className={styles.statusBody}>
          {isLoading ? (
            <ReactLoading type="bars" color="#4a90e2" height={40} width={40} />
          ) : (
            weaveStatus
          )}
        </div>
      </Card>
    </div>
  )
}

export default WeaveStatus
