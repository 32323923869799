export default [
  {
    title: 'ADC',
    icon: 'fa-server',
    should_disable: {
      platform: ['chassis'],
    },
    menu_name: ['adc'],
    // specificPartition: ['all'],
    menus: [
      {
        title: 'SLB',
        should_disable: {
          lineage: ['slb'],
          partition_app_type: ['cgnv6'],
        },
        // specificPartition: ['all'],
        specificPartition: ['lp'],
        menus: [
          // {
          //   title: 'App Services',
          //   menus: [
          //     {
          //       title: 'App Services',
          //       lineage: ['app-svc'],
          //       custom_page: 'app-services',
          //     },
          //     {
          //       title: 'App Services Group',
          //       lineage: ['app-svc-group'],
          //       custom_page: 'app-services-group',
          //     },
          //   ],
          // },
          {
            title: 'Virtual Servers',
            modalSize: 'extralarge',
            lineage: ['slb.virtual-server'],
            uiJsonPath: 'adc/slb/virtual-servers',
          },
          {
            title: 'Virtual Services',
            lineage: ['slb.virtual-server'],
            customizedPage: 'vports',
            uiJsonPath: 'adc/slb/virtual-services',
          },
          {
            title: 'Service Groups',
            modalSize: 'extralarge',
            lineage: ['slb.service-group'],
            uiJsonPath: 'adc/slb/service-groups',
          },
          {
            title: 'Servers',
            modalSize: 'extralarge',
            lineage: ['slb.server'],
            uiJsonPath: 'adc/slb/servers',
          },
          // {
          //   title: 'Policy Limits',
          //   should_disable: {
          //     partition: ['sp'],
          //   },
          //   url_name: 'glid_index',
          //   lineage: ['glid'],
          //   uiJsonPath: 'adc/slb/policy-limits',
          // },
          // {
          //   title: 'Application',
          //   lineage: [
          //     // 'slb.virtual-server',
          //     // 'slb.service-group',
          //     // menu_conditions.adc_slb_application_menu
          //   ],
          // },
          {
            title: 'Global',
            url_name: 'slb_global_index',
            should_disable: {
              partition: ['sp'],
            },
            lineage: ['slb.common'],
            onlyEditMode: true,
            uiJsonPath: 'adc/slb/global',
          },
          // {
          //   title: 'Session Filter',
          //   specificPartition: true,
          //   url_name: 'slb_session_filter_index',
          //   lineage: ['session-filter'],
          //   uiJsonPath: 'adc/slb/session-filter',
          // },
          // {
          //   title: 'Session',
          //   menus: [
          //     {
          //       title: 'Session',
          //       url_name: 'slb_session_index',
          //       lineage: ['sessions'],
          //     },
          //     {
          //       title: 'Session Filter',
          //       url_name: 'slb_session_filter_index',
          //       lineage: ['session-filter'],
          //     },
          //   ],
          // },
          // {
          //   title: 'Resource Usage',
          //   url_name: 'adc_slb_resource_usage_index',
          //   lineage: ['slb.resource-usage.oper'],
          //   should_disable: {
          //     partition: ['l3v'],
          //   },
          // },
        ],
      },
      {
        title: 'Health Monitors',
        menus: [
          {
            title: 'Health Monitors',
            url_name: 'adc_health_monitor_index',
            related_urls: ['adc_health_monitor_update'],
            lineage: ['health.monitor'],
            uiJsonPath: 'adc/health/monitor',
          },
          {
            title: 'External Programs',
            fileType: 'health-external',
            lineage: ['file.health-external'],
            onlyDelete: true,
            modalSize: 'extralarge',
          },
          {
            title: 'HTTP Post Files',
            fileType: 'health-postfile',
            onlyDelete: true,
            lineage: ['file.health-postfile'],
            modalSize: 'extralarge',
          },
          // {
          //   title: 'Global Setting',
          //   onlyEditMode: true,
          //   lineage: ['health.global'],
          //   should_disable: {
          //     partition: ['l3v'],
          //   },
          //   uiJsonPath: 'adc/health/global',
          // },
          // {
          //   title: 'Statistics',
          //   url_name: 'adc_health_stats_index',
          //   lineage: ['slb.health-stat'],
          // },
          // {
          //   title: 'Gateway',
          //   url_name: 'adc_health_gateway_index',
          //   lineage: ['slb.health-gateway'],
          // },
        ],
      },
      {
        title: 'SLB Templates',
        should_disable: {
          partition_app_type: ['cgnv6'],
        },
        menus: [
          {
            title: 'SLB',
            menus: [
              {
                title: 'Port',
                addLabel: 'Port Templates',
                url_params: {
                  template_type: 'Port',
                  category: 'slb',
                },
                related_urls: ['template_port_update'],
                lineage: ['slb.template.port'],
                uiJsonPath: 'adc/templates/slb/port',
              },
              {
                title: 'Server',
                addLabel: 'Server Templates',
                url_name: 'template_server_create',
                url_params: {
                  template_type: 'Server',
                  category: 'slb',
                },
                related_urls: ['template_server_update'],
                lineage: ['slb.template.server'],
                uiJsonPath: 'adc/templates/slb/server',
              },
              {
                title: 'Virtual Port',
                addLabel: 'Virtual Port Templates',
                url_name: 'template_virtualport_create',
                url_params: {
                  template_type: 'Virtual Port',
                  category: 'slb',
                },
                related_urls: ['template_virtualport_update'],
                lineage: ['slb.template.virtual-port'],
                uiJsonPath: 'adc/templates/slb/virtual-port',
              },
              {
                title: 'Virtual Server',
                addLabel: 'Virtual Server Templates',
                url_name: 'template_virtualserver_create',
                url_params: {
                  template_type: 'Virtual Server',
                  category: 'slb',
                },
                related_urls: ['template_virtualserver_update'],
                lineage: ['slb.template.virtual-server'],
                uiJsonPath: 'adc/templates/slb/virtual-server',
              },
            ],
          },
          {
            title: 'Application',
            menus: [
              {
                title: 'RAM Caching',
                url_name: 'template_caching_create',
                url_params: {
                  template_type: 'RAM Caching',
                  category: 'application',
                },
                related_urls: ['template_caching_update'],
                lineage: ['slb.template.cache'],
                should_disable: {
                  oem: ['fireeye', 'rg'],
                },
                uiJsonPath: 'adc/templates/application/ram-caching',
              },
              {
                title: 'Connection Re-Use',
                url_name: 'template_connectionreuse_create',
                url_params: {
                  template_type: 'Connection Re-Use',
                  category: 'application',
                },
                related_urls: ['template_connectionreuse_update'],
                lineage: ['slb.template.connection-reuse'],
                uiJsonPath: 'adc/templates/application/connection-reuse',
              },
              {
                title: 'DBLB',
                url_name: 'template_dblb_create',
                url_params: {
                  template_type: 'DBLB',
                  category: 'application',
                },
                related_urls: ['template_dblb_update'],
                lineage: ['slb.template.dblb'],
                uiJsonPath: 'adc/templates/application/dblb',
              },
            ],
          },
          {
            title: 'Persistence',
            menus: [
              {
                title: 'Persist Cookie',
                url_name: 'template_persistcookie_create',
                url_params: {
                  template_type: 'Persist Cookie',
                  category: 'persistence',
                },
                related_urls: ['template_persistcookie_update'],
                lineage: ['slb.template.persist.cookie'],
                uiJsonPath: 'adc/templates/persistence/cookie',
              },
              {
                title: 'Persist Destination IP',
                url_name: 'template_persistdest_create',
                url_params: {
                  template_type: 'Persist Destination IP',
                  category: 'persistence',
                },
                related_urls: ['template_persistdest_update'],
                lineage: ['slb.template.persist.destination-ip'],
                uiJsonPath: 'adc/templates/persistence/destination-ip',
              },
              {
                title: 'Persist Source IP',
                url_name: 'template_persistsource_create',
                url_params: {
                  template_type: 'Persist Source IP',
                  category: 'persistence',
                },
                related_urls: ['template_persistsource_update'],
                lineage: ['slb.template.persist.source-ip'],
                uiJsonPath: 'adc/templates/persistence/source-ip',
              },
              {
                title: 'Persist SSL SID',
                url_name: 'template_persistsslsid_create',
                url_params: {
                  template_type: 'Persist SSL SID',
                  category: 'persistence',
                },
                related_urls: ['template_persistsslsid_update'],
                lineage: ['slb.template.persist.ssl-sid'],
                uiJsonPath: 'adc/templates/persistence/ssl-sid',
              },
            ],
          },
          {
            title: 'L4 Protocols',
            menus: [
              {
                title: 'TCP',
                url_name: 'template_tcp_create',
                url_params: {
                  template_type: 'TCP',
                  category: 'l4',
                },
                related_urls: ['template_tcp_update'],
                lineage: ['slb.template.tcp'],
                uiJsonPath: 'adc/templates/l4-protocols/tcp',
              },
              {
                title: 'UDP',
                url_name: 'template_udp_create',
                url_params: {
                  template_type: 'UDP',
                  category: 'l4',
                },
                related_urls: ['template_udp_update'],
                lineage: ['slb.template.udp'],
                uiJsonPath: 'adc/templates/l4-protocols/udp',
              },
            ],
          },
          {
            title: 'L7 Protocols',
            menus: [
              {
                title: 'HTTP Policy',
                url_name: 'l7_template_http_policy_create',
                url_params: {
                  template_type: 'HTTP Policy',
                  category: 'l7',
                },
                related_urls: [
                  'l7_template_http_policy_update',
                  'template_http_policy_create',
                  'template_http_policy_match_create',
                  'template_http_policy_geo_match_create',
                ],
                lineage: ['slb.template.http-policy'],
                uiJsonPath: 'adc/templates/l7-protocols/http-policy',
              },
              {
                title: 'Diameter',
                url_name: 'template_diameter_create',
                url_params: {
                  template_type: 'Diameter',
                  category: 'l7',
                },
                related_urls: ['template_diameter_update'],
                lineage: ['slb.template.diameter'],
                uiJsonPath: 'adc/templates/l7-protocols/diameter',
              },
              {
                title: 'DNS',
                lineage: ['slb.template.dns'],
                uiJsonPath: 'adc/templates/l7-protocols/dns',
              },
              // {
              //   title: 'FIX',
              //   url_name: 'template_fix_create',
              //   url_params: {
              //     template_type: 'FIX',
              //     category: 'l7',
              //   },
              //   related_urls: ['template_fix_update'],
              //   lineage: ['slb.template.fix'],
              //   uiJsonPath: 'adc/templates/l7-protocols/fix',
              // },
              // {
              //   title: 'FTP',
              //   url_name: 'template_ftp_create',
              //   url_params: {
              //     template_type: 'FTP',
              //     category: 'l7',
              //   },
              //   related_urls: ['template_ftp_update'],
              //   lineage: ['slb.template.ftp'],
              //   uiJsonPath: 'adc/templates/l7-protocols/ftp',
              // },
              {
                title: 'HTTP',
                url_name: 'template_http_create',
                url_params: {
                  template_type: 'HTTP',
                  category: 'l7',
                },
                related_urls: ['template_http_update'],
                lineage: ['slb.template.http'],
                uiJsonPath: 'adc/templates/l7-protocols/http',
              },
              // {
              //   title: 'IMAP POP3',
              //   url_name: 'template_imappop3_create',
              //   url_params: {
              //     template_type: 'IMAP POP3',
              //     category: 'l7',
              //   },
              //   related_urls: ['template_imappop3_update'],
              //   lineage: ['slb.template.imap-pop3'],
              //   uiJsonPath: 'adc/templates/l7-protocols/imap-pop3',
              // },
              {
                title: 'Policy',
                url_name: 'template_policy_create',
                url_params: {
                  template_type: 'policy',
                  category: 'l7',
                },
                related_urls: ['template_policy_update'],
                lineage: ['slb.template.policy'],
                uiJsonPath: 'adc/templates/l7-protocols/policy',
              },
              {
                title: 'SIP',
                url_name: 'template_sip_create',
                url_params: {
                  template_type: 'SIP',
                  category: 'l7',
                },
                related_urls: ['template_sip_update'],
                lineage: ['slb.template.sip'],
                uiJsonPath: 'adc/templates/l7-protocols/sip',
              },
              {
                title: 'SMPP',
                url_name: 'template_smpp_create',
                url_params: {
                  template_type: 'SMPP',
                  category: 'l7',
                },
                related_urls: ['template_smpp_update'],
                lineage: ['slb.template.smpp'],
                uiJsonPath: 'adc/templates/l7-protocols/smpp',
              },
              {
                title: 'SMTP',
                url_name: 'template_smtp_create',
                url_params: {
                  template_type: 'SMTP',
                  category: 'l7',
                },
                related_urls: ['template_smtp_update'],
                lineage: ['slb.template.smtp'],
                uiJsonPath: 'adc/templates/l7-protocols/smtp',
              },
              {
                title: 'TCP Proxy',
                url_name: 'template_tcpproxy_create',
                url_params: {
                  template_type: 'TCP Proxy',
                  category: 'l7',
                },
                related_urls: ['template_tcpproxy_update'],
                lineage: ['slb.template.tcp-proxy'],
                uiJsonPath: 'adc/templates/l7-protocols/tcp-proxy',
              },
              // {
              //   title: 'RESPMOD',
              //   url_name: 'template_respmod_create',
              //   lineage: ['slb.template.respmod-icap'],
              //   uiJsonPath: 'adc/templates/l7-protocols/resmod',
              // },
              // {
              //   title: 'REQMOD',
              //   url_name: 'template_reqmod_create',
              //   lineage: ['slb.template.reqmod-icap'],
              //   uiJsonPath: 'adc/templates/l7-protocols/reqmod',
              // },
            ],
          },
          {
            title: 'SSL',
            menus: [
              {
                title: 'Client SSL',
                url_name: 'template_clientssl_create',
                url_params: {
                  template_type: 'Client SSL',
                  category: 'ssl',
                },
                related_urls: ['template_clientssl_update'],
                lineage: ['slb.template.client-ssl'],
                uiJsonPath: 'adc/templates/ssl/client-ssl',
              },
              {
                title: 'Server SSL',
                url_name: 'template_serverssl_create',
                url_params: {
                  template_type: 'Server SSL',
                  category: 'ssl',
                },
                related_urls: ['template_serverssl_update'],
                lineage: ['slb.template.server-ssl'],
                uiJsonPath: 'adc/templates/ssl/server-ssl',
              },
              // {
              //   title: 'SSL Cipher',
              //   url_name: 'template_cipher_create',
              //   url_params: {
              //     template_type: 'SSL Cipher',
              //     category: 'ssl',
              //   },
              //   related_urls: ['template_cipher_update'],
              //   lineage: ['slb.template.cipher'],
              //   uiJsonPath: 'adc/templates/ssl/ssl-cipher',
              // },
            ],
          },
          {
            title: 'General',
            menus: [
              // {
              //   title: 'External Service',
              //   url_name: 'template_externalservice_create',
              //   url_params: {
              //     template_type: 'External Service',
              //     category: 'general',
              //   },
              //   related_urls: ['template_externalservice_update'],
              //   lineage: ['slb.template.external-service'],
              //   uiJsonPath: 'adc/templates/general/external-service',
              // },
              {
                title: 'Logging',
                url_name: 'template_logging_create',
                url_params: {
                  template_type: 'Logging',
                  category: 'general',
                },
                related_urls: ['template_logging_update'],
                lineage: ['slb.template.logging'],
                uiJsonPath: 'adc/templates/general/logging',
              },
            ],
          },
        ],
      },
      {
        title: 'SSL Management',
        should_disable: {
          partition_app_type: ['cgnv6'],
        },
        menus: [
          {
            title: 'SSL Certificates',
            fileType: 'ssl',
            lineage: [
              'file.ssl-key',
              'file.ssl-cert',
              'file.ssl-ca',
              // 'file.ssl-csr',
            ],
            onlyDelete: true,
            modalSize: 'extralarge',
          },
          {
            title: 'Cert Revocation List',
            fileType: 'ssl-crl',
            lineage: ['file.ssl-crl'],
            modalSize: 'extralarge',
          },
          // {
          //   title: 'Expiration Email',
          //   onlyEditMode: true,
          //   lineage: ['slb.ssl-expire-check' /*'slb.ssl-expire-check.oper'*/],
          //   uiJsonPath: 'adc/ssl-management/expiration-mail',
          // },
        ],
      },
      {
        title: 'aFleX',
        should_disable: {
          partition_app_type: ['cgnv6'],
        },
        fileType: 'aflex',
        lineage: ['file.aflex'],
        modalSize: 'extralarge',
      },
      {
        title: 'BW-Lists',
        fileType: 'bw-list',
        should_disable: {
          partition_app_type: ['cgnv6'],
        },
        lineage: ['file.bw-list'],
        modalSize: 'extralarge',
      },
      {
        title: 'IP Source NAT',
        // specificPartition: ['all'],
        menus: [
          {
            title: 'IPv4 Pools',
            url_name: 'pool_index',
            related_urls: ['pool_create', 'pool_update'],
            lineage: ['ip.nat.pool'],
            uiJsonPath: 'adc/ip-source-nat/ipv4-pools',
            viewMode: true,
          },
          {
            title: 'IPv6 Pools',
            url_name: 'ipv6_pool_index',
            related_urls: ['ipv6_pool_create'],
            lineage: ['ipv6.nat.pool'],
            uiJsonPath: 'adc/ip-source-nat/ipv6-pools',
            viewMode: true,
          },
          {
            title: 'Groups',
            menus: [
              {
                title: 'IPv4',
                lineage: ['ip.nat.pool-group'],
                uiJsonPath: 'adc/ip-source-nat/groups/ipv4',
              },
              {
                title: 'IPv6',
                lineage: ['ipv6.nat.pool-group'],
                uiJsonPath: 'adc/ip-source-nat/groups/ipv6',
              },
            ],
          },
          // {
          //   title: 'ACL Bind',
          //   menus: [
          //     {
          //       title: 'IPv4',
          //       menus: [
          //         {
          //           title: 'ACL Name',
          //           lineage: ['ip.nat.inside.source.list.acl-name-list'],
          //           should_disable: {
          //             lineage: [
          //               ['ip.nat.pool', 'ip.nat.pool-group'],
          //               [
          //                 'access-list.standard',
          //                 'access-list.extended',
          //                 'ip.access-list',
          //               ],
          //             ],
          //           },
          //           uiJsonPath: 'adc/ip-source-nat/acl-bind/ipv4/acl-name-list',
          //         },
          //         {
          //           title: 'ACL ID',
          //           lineage: ['ip.nat.inside.source.list.acl-id-list'],
          //           should_disable: {
          //             lineage: [
          //               ['ip.nat.pool', 'ip.nat.pool-group'],
          //               [
          //                 'access-list.standard',
          //                 'access-list.extended',
          //                 'ip.access-list',
          //               ],
          //             ],
          //           },
          //           uiJsonPath: 'adc/ip-source-nat/acl-bind/ipv4/acl-id-list',
          //         },
          //       ],
          //     },
          //     {
          //       title: 'IPv6',
          //       lineage: ['ipv6.nat.inside.source.list'],
          //       should_disable: {
          //         lineage: [
          //           'ipv6.access-list',
          //           // ['ipv6.nat.pool', 'ipv6.nat.pool-group'],
          //         ],
          //       },
          //       uiJsonPath: 'adc/ip-source-nat/acl-bind/ipv6/acl-list',
          //     },
          //   ],
          // },
          /** Combine NAT Interfaces with Network Interfaces */
          // {
          //   title: 'NAT Interfaces',
          //   'should_disable': {
          //     'partition': ['sp']
          // },
          //   menus: [
          //     {
          //       title: 'Ethernets',
          //       url_name: 'nat_interface_ethernet_index',
          //       related_urls: ['nat_interface_ethernet_update'],
          //       lineage: ['interface.ethernet'],
          //       uiJsonPath: 'adc/ip-source-nat/nat-interfaces/ethernets',
          //     },
          //     {
          //       title: 'Virtual Ethernets',
          //       url_name: 'nat_interface_virtual_ethernet_index',
          //       related_urls: ['nat_interface_virtual_ethernet_update'],
          //       lineage: ['interface.ve'],
          //       uiJsonPath:
          //         'adc/ip-source-nat/nat-interfaces/virtual-ethernets',
          //     },
          //   ],
          // },
          // {
          //   title: 'NAT Range',
          //   lineage: ['ip.nat.range-list'],
          //   uiJsonPath: 'adc/ip-source-nat/nat-range',
          //   viewMode: true,
          // },
          // {
          //   title: 'Static NAT',
          //   lineage: ['ip.nat.inside.source.static'],
          //   uiJsonPath: 'adc/ip-source-nat/static-nat',
          // },
          // {
          //   title: 'NAT Global',
          //   onlyEditMode: true,
          //   lineage: [
          //     'ip.nat-global' /*, 'ip.nat.alg.pptp',  'ip.nat.translation'*/,
          //   ],
          //   uiJsonPath: 'adc/ip-source-nat/nat-global',
          // },
          {
            title: 'Templates',
            menus: [
              {
                title: 'Logging',
                url_name: 'nat_template_logging_index',
                related_urls: ['nat_template_logging_update'],
                lineage: ['ip.nat.template.logging'],
                uiJsonPath: 'adc/ip-source-nat/logging',
              },
            ],
          },
        ],
      },
      // {
      //   title: 'Statistics',
      //   should_disable: {
      //     partition_app_type: ['cgnv6'],
      //   },
      //   menus: [
      //     // {
      //     //   title: 'L4',
      //     //   url_name: 'adc_l4_stats',
      //     //   url_params: {
      //     //     category: 'l4',
      //     //   },
      //     //   lineage: ['slb.l4.stats'],
      //     // },
      //     {
      //       title: 'L7',
      //       menus: [
      //         {
      //           title: 'HTTP Proxy',
      //           url_name: 'adc_http_proxy_stats',
      //           url_params: {
      //             category: 'l7',
      //             stats_type: 'http-proxy',
      //           },
      //           lineage: ['slb.http-proxy.stats'],
      //           should_disable: {
      //             lineage: ['slb.virtual-server'],
      //           },
      //         },
      //         {
      //           title: 'Fast HTTP',
      //           url_name: 'adc_fast_http_stats',
      //           url_params: {
      //             category: 'l7',
      //             stats_type: 'fast-http',
      //           },
      //           lineage: ['slb.fast-http-proxy.stats'],
      //         },
      //         {
      //           title: 'Generic Proxy',
      //           url_name: 'adc_generic_proxy_stats',
      //           url_params: {
      //             category: 'l7',
      //             stats_type: 'generic-proxy',
      //           },
      //           lineage: ['slb.generic-proxy.stats'],
      //         },
      //         {
      //           title: 'Diameter',
      //           url_name: 'adc_diameter_stats',
      //           url_params: {
      //             category: 'l7',
      //             stats_type: 'diameter',
      //           },
      //           lineage: ['slb.server'],
      //         },
      //         {
      //           title: 'DNS',
      //           url_name: 'adc_dns_stats',
      //           url_params: {
      //             category: 'l7',
      //             stats_type: 'dns',
      //           },
      //           lineage: ['slb.dns.stats'],
      //         },
      //         {
      //           title: 'Fix',
      //           url_name: 'adc_fix_stats',
      //           url_params: {
      //             category: 'l7',
      //             stats_type: 'fix',
      //           },
      //           lineage: ['slb.fix.stats'],
      //         },
      //         {
      //           title: 'FTP',
      //           menus: [
      //             {
      //               title: 'FTP Ctl',
      //               url_name: 'adc_ftp_ctl_stats',
      //               url_params: {
      //                 category: 'l7',
      //                 stats_type: 'ftp-ctl',
      //               },
      //               lineage: ['slb.ftp-ctl.stats'],
      //             },
      //             {
      //               title: 'FTP Data',
      //               url_name: 'adc_ftp_data_stats',
      //               url_params: {
      //                 category: 'l7',
      //                 stats_type: 'ftp-data',
      //               },
      //               lineage: ['slb.ftp-data.stats'],
      //             },
      //             {
      //               title: 'FTP Proxy',

      //               url_name: 'adc_ftp_proxy_stats',
      //               url_params: {
      //                 category: 'l7',
      //                 stats_type: 'ftp-proxy',
      //               },
      //               lineage: ['slb.ftp-proxy.stats'],
      //             },
      //           ],
      //         },
      //         {
      //           title: 'SPDY Proxy',
      //           url_name: 'adc_spdy_proxy_stats',
      //           url_params: {
      //             category: 'l7',
      //             stats_type: 'spdy-proxy',
      //           },
      //           lineage: ['slb.spdy-proxy.stats'],
      //         },
      //         {
      //           title: 'SIP',
      //           url_name: 'adc_sip_stats',
      //           url_params: {
      //             category: 'l7',
      //             stats_type: 'sip',
      //           },
      //           lineage: ['slb.sip.stats'],
      //         },
      //         {
      //           title: 'SMTP',
      //           url_name: 'adc_smtp_stats',
      //           url_params: {
      //             category: 'l7',
      //             stats_type: 'smtp',
      //           },
      //           lineage: ['slb.smtp.stats'],
      //         },
      //         {
      //           title: 'SMPP',
      //           url_name: 'adc_smpp_stats',
      //           url_params: {
      //             category: 'l7',
      //             stats_type: 'smpp',
      //           },
      //           lineage: ['slb.smpp.stats'],
      //         },
      //         {
      //           title: 'SSL',
      //           url_name: 'adc_ssl_stats',
      //           url_params: {
      //             category: 'l7',
      //             stats_type: 'ssl',
      //           },
      //         },
      //         {
      //           title: 'ICAP',
      //           url_name: 'adc_icap_stats',
      //           url_params: {
      //             category: 'l7',
      //             stats_type: 'icap',
      //           },
      //           lineage: ['slb.icap.stats', 'slb.icap_http.stats'],
      //         },
      //         {
      //           title: 'L7 Session',
      //           url_name: 'adc_l7session_stats',
      //           url_params: {
      //             category: 'l7',
      //             stats_type: 'l7session',
      //           },
      //           lineage: ['slb.l7session.stats'],
      //         },
      //       ],
      //     },
      //     {
      //       title: 'Application',
      //       menus: [
      //         {
      //           title: 'RAM Caching',
      //           url_name: 'adc_caching_stats',
      //           url_params: {
      //             category: 'application',
      //             stats_type: 'caching',
      //           },
      //           lineage: ['slb.rc-cache-global.stats'],
      //           should_disable: {
      //             oem: ['fireeye', 'rg'],
      //           },
      //         },
      //         {
      //           title: 'Compression',
      //           url_name: 'adc_compression_stats',
      //           url_params: {
      //             category: 'application',
      //             stats_type: 'compression',
      //           },
      //           lineage: ['slb.hw-compress.stats'],
      //         },
      //         {
      //           title: 'Connection Re-Use',
      //           url_name: 'adc_connection_reuse_stats',
      //           url_params: {
      //             category: 'application',
      //             stats_type: 'connection-reuse',
      //           },
      //           lineage: ['slb.connection-reuse.stats'],
      //         },
      //         {
      //           title: 'Persistence',
      //           url_name: 'adc_persistence_stats',
      //           url_params: {
      //             category: 'application',
      //             stats_type: 'persistence',
      //           },
      //           lineage: ['slb.persist.stats'],
      //         },
      //         {
      //           title: 'DBLB',
      //           menus: [
      //             {
      //               title: 'MySQL',
      //               url_name: 'adc_mysql_stats',
      //               url_params: {
      //                 category: 'application',
      //                 stats_type: 'mysql',
      //               },
      //               lineage: ['slb.mysql.stats'],
      //             },
      //             {
      //               title: 'MSSQL',
      //               url_name: 'adc_mssql_stats',
      //               url_params: {
      //                 category: 'application',
      //                 stats_type: 'mssql',
      //               },
      //               lineage: ['slb.mssql.stats'],
      //             },
      //           ],
      //         },
      //         {
      //           title: 'aFlow',
      //           url_name: 'adc_aflow_stats',
      //           url_params: {
      //             category: 'application',
      //             stats_type: 'aflow',
      //           },
      //           lineage: ['slb.aflow.stats'],
      //         },
      //       ],
      //     },
      //     {
      //       title: 'System',
      //       menus: [
      //         {
      //           title: 'Performance',
      //           url_name: 'adc_performance_stats',
      //           url_params: {
      //             category: 'system',
      //             stats_type: 'performance',
      //           },
      //           lineage: ['slb.perf.stats'],
      //         },
      //         {
      //           title: 'Switch',
      //           url_name: 'adc_switch_stats',
      //           url_params: {
      //             category: 'system',
      //             stats_type: 'switch',
      //           },
      //           lineage: ['slb.switch.stats'],
      //         },
      //         {
      //           title: 'Geo-Location',
      //           url_name: 'adc_geolocation_stats',
      //           url_params: {
      //             category: 'system',
      //             stats_type: 'geolocation',
      //           },
      //           lineage: ['slb.virtual-server'],
      //         },
      //         {
      //           title: 'Rate Limit Logging',
      //           url_name: 'adc_rate_limit_logging_stats',
      //           url_params: {
      //             category: 'system',
      //             stats_type: 'rate-limit-logging',
      //           },
      //           lineage: ['slb.rate-limit-log.stats'],
      //         },
      //         {
      //           title: 'TCP',
      //           url_name: 'adc_tcp_stats',
      //           url_params: {
      //             category: 'system',
      //             stats_type: 'tcp',
      //           },
      //           lineage: ['system.tcp.stats'],
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   title: 'GSLB',
  //   icon: 'fa-globe',
  //   should_disable: {
  //     lineage: ['gslb'],
  //     platform: ['chassis'],
  //     partition_app_type: ['cgnv6'],
  //   },
  //   menu_name: ['gslb'],
  //   menus: [
  //     {
  //       title: 'FQDNs',
  //       url_name: 'gslb_fqdn_index',
  //       lineage: ['gslb.zone'],
  //       uiJsonPath: 'gslb/fqdns',
  //       modalSize: 'extralarge',
  //     },
  //     {
  //       title: 'Sites',
  //       url_name: 'gslb_sites_index',
  //       lineage: ['gslb.site'],
  //       uiJsonPath: 'gslb/sites',
  //       modalSize: 'extralarge',
  //     },
  //     {
  //       title: 'Service IPs',
  //       uiJsonPath: 'gslb/service-ip',
  //       lineage: ['gslb.service-ip'],
  //       modalSize: 'extralarge',
  //     },
  //     {
  //       title: 'Policies',
  //       uiJsonPath: 'gslb/policy',
  //       lineage: ['gslb.policy'],
  //     },
  //     {
  //       title: 'Geo Location',
  //       should_disable: {
  //         partition: ['l3v'],
  //       },
  //       menus: [
  //         {
  //           title: 'Geo Location',
  //           lineage: ['gslb.geo-location'],
  //           uiJsonPath: 'gslb/geo-location',
  //           modalSize: 'extralarge',
  //           should_disable: {
  //             partition: ['l3v'],
  //           },
  //         },
  //         {
  //           title: 'Geo Location Files',
  //           lineage: ['file.geo-location'],
  //           fileType: 'geo-location',
  //           modalSize: 'extralarge',
  //           should_disable: {
  //             partition: ['l3v'],
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       title: 'IP Lists',
  //       url_name: 'gslb_iplist_index',
  //       related_urls: ['gslb_iplist_update'],
  //       lineage: ['gslb.ip-list'],
  //       uiJsonPath: 'gslb/ip-lists',
  //     },
  //     {
  //       title: 'Groups',
  //       url_name: 'gslb_group_index',
  //       related_urls: ['gslb_group_update'],
  //       lineage: ['gslb.group'],
  //       uiJsonPath: 'gslb/groups',
  //     },
  //     {
  //       title: 'Service Groups',
  //       url_name: 'gslb_service_group_index',
  //       related_urls: ['gslb_service_group_update'],
  //       lineage: ['gslb.service-group'],
  //       uiJsonPath: 'gslb/service-groups',
  //     },
  //     {
  //       title: 'GSLB Templates',
  //       url_name: 'gslb_templates_index',
  //       related_urls: ['gslb_templates_update'],
  //       lineage: ['gslb.template.csv', 'gslb.template.snmp'],
  //       menus: [
  //         {
  //           title: 'CSV',
  //           url_name: 'gslb_templates_csv_index',
  //           related_urls: ['gslb_templates_update'],
  //           lineage: ['gslb.template.csv'],
  //           uiJsonPath: 'gslb/templates/csv',
  //         },
  //         {
  //           title: 'SNMP',
  //           url_name: 'gslb_templates_snmp_index',
  //           related_urls: ['gslb_templates_update'],
  //           lineage: ['gslb.template.snmp'],
  //           uiJsonPath: 'gslb/templates/snmp',
  //         },
  //         {
  //           title: 'DNSSEC',
  //           lineage: ['dnssec.template'],
  //           uiJsonPath: 'gslb/templates/dnssec',
  //         },
  //         {
  //           title: 'HSM',
  //           lineage: ['hsm.template'],
  //           uiJsonPath: 'gslb/templates/hsm',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Global',
  //       url_name: 'gslb_global_index',
  //       lineage: [
  //         'gslb.system',
  //         'gslb.active-rdt',
  //         'gslb.dns',
  //         'gslb.protocol',
  //       ],
  //       should_disable: {
  //         partition: ['l3v'],
  //       },
  //       menus: [
  //         {
  //           title: 'System',
  //           url_name: 'gslb_global_index',
  //           lineage: ['gslb.system'],
  //           uiJsonPath: 'gslb/global/system',
  //           onlyEditMode: true,
  //         },
  //         {
  //           title: 'Active RDT',
  //           url_name: 'gslb_global_ardt_index',
  //           lineage: ['gslb.active-rdt'],
  //           uiJsonPath: 'gslb/global/active-rdt',
  //           onlyEditMode: true,
  //         },
  //         {
  //           title: 'DNS',
  //           url_name: 'gslb_global_dns_index',
  //           lineage: ['gslb.dns'],
  //           uiJsonPath: 'gslb/global/dns',
  //           onlyEditMode: true,
  //         },
  //         {
  //           title: 'Protocol',
  //           url_name: 'gslb_global_protocol_index',
  //           lineage: ['gslb.protocol'],
  //           uiJsonPath: 'gslb/global/protocol',
  //           onlyEditMode: true,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    title: 'Security',
    icon: 'fa-shield',
    menu_name: ['security'],
    menus: [
      {
        title: 'WAF',
        module_name: ['waf'],
        should_disable: {
          platform: ['chassis'],
          lineage: ['waf'],
          partition_app_type: ['cgnv6'],
        },
        menus: [
          //   // {
          //   //   title: 'WAF Bindings',
          //   //   url_name: 'waf_services_index',
          //   //   related_urls: ['waf_services_update'],
          //   // },
          {
            title: 'WAF Templates',
            url_name: 'waf_template_index',
            uiJsonPath: 'security/waf/template/http',
            lineage: ['waf.template'],
          },
          //   {
          //     title: 'WAF Files',
          //     url_name: 'waf_files_index',
          //     menus: [
          //       {
          //         title: 'WAF Policies',
          //         url_name: 'waf_files_policy_settings',
          //         related_urls: [
          //           'waf_files_policy_create',
          //           'waf_files_policy_update',
          //         ],
          //       },
          //       {
          //         title: 'XML Schemas',
          //         url_name: 'waf_files_xml_settings',
          //         related_urls: ['waf_files_xml_create', 'waf_files_xml_update'],
          //       },
          //       {
          //         title: 'SOAP WSDLs',
          //         url_name: 'waf_files_wsdl_settings',
          //         related_urls: [
          //           'waf_files_wsdl_create',
          //           'waf_files_wsdl_update',
          //         ],
          //       },
          //     ],
          //   },
          //   // {
          //   //   title: 'Reporting',
          //   //   url_name: 'waf_reporting_index',
          //   //   lineage: ['clock.show.oper', 'partition', 'active-partition'],
          //   // },
          //   {
          //     title: 'Global Stats',
          //     url_name: 'waf_stats_global_index',
          //     lineage: ['waf.global'],
          //   },
        ],
      },
      {
        title: 'Firewall',
        module_name: ['dcfw'],
        // specificPartition: ['all'],
        specificPartition: ['lp'],
        menus: [
          // {
          //   title: 'Dashboard',
          //   url_name: 'security_firewall_dashboard_index',
          //   lineage: ['rule-set'],
          // },
          {
            title: 'Rulesets',
            url: '/r/fw/rulesets/',
            lineage: ['rule-set'],
            uiJsonPath: 'security/firewall/rulesets/rule-set',
            modalSize: 'extralarge',
          },
          // {
          //   title: 'Zones',
          //   disableDelList: ['any'],
          //   lineage: ['zone'],
          //   uiJsonPath: 'security/firewall/zones',
          //   specificPartition: true,
          // },
          // {
          //   title: 'Statistics',
          //   url_name: 'security_firewall_statistics_index',
          //   lineage: [
          //     'fw.resource-usage',
          //     'fw.status',
          //     'fw.tcp-window-check',
          //     'rule-set',
          //     'fw.alg',
          //     'fw.logging',
          //     'fw.server',
          //     'fw.service-group',
          //     'system.session',
          //   ],
          // },
          {
            title: 'Configure',
            menus: [
              // {
              //   title: 'Settings',
              //   lineage: [
              //     'fw.apply-changes',
              //     'fw.session-aging-list',
              //     'fw.session-aging',
              //     'fw.vrid',
              //     'fw.global',
              //     'fw.urpf',
              //     'fw.active-rule-set',
              //     'fw.tcp-window-check',
              //     'fw.alg',
              //   ],
              //   uiJsonPath: 'security/firewall/configure/settings',
              //   onlyEditMode: true,
              // },
              {
                title: 'Global',
                lineage: ['fw.global'],
                uiJsonPath: 'security/firewall/configure/global',
                onlyEditMode: true,
              },
              // {
              //   title: 'Logging',
              //   menus: [
              //     {
              //       title: 'Server',
              //       lineage: ['fw.server'],
              //       uiJsonPath: 'security/firewall/configure/logging/server',
              //       modalSize: 'extralarge',
              //     },
              //     {
              //       title: 'Service Group',
              //       lineage: ['fw.service-group'],
              //       uiJsonPath:
              //         'security/firewall/configure/logging/service-group',
              //       modalSize: 'extralarge',
              //     },
              //     {
              //       title: 'Templates',
              //       addLabel: 'Logging Templates',
              //       lineage: ['fw.template.logging'],
              //       uiJsonPath:
              //         'security/firewall/configure/logging/logging-template',
              //     },
              //   ],
              // },
              // {
              //   title: 'Application Protocol Update',
              //   url_name: 'security_firewall_application_protocol_update_index',
              //   lineage: [
              //     'automatic-update.info.oper',
              //     'automatic-update.proxy-server',
              //     'automatic-update.check-now',
              //     'automatic-update.config',
              //   ],
              //   should_disable: {
              //     partition: ['l3v'],
              //     platform: ['chassis'],
              //   },
              //   should_enable: {
              //     license: ['QOSMOS'],
              //   },
              // },
              // {
              //   title: 'Template LID',
              //   lineage: ['template.lid'],
              //   uiJsonPath: 'security/firewall/configure/template-lid',
              // },
            ],
          },
          // {
          //   title: 'Session',
          //   specificPartition: ['all'],
          //   menus: [
          //     // {
          //     //   title: 'Session',
          //     //   url_name: 'security_dcfw_session_index',
          //     //   lineage: ['sessions'],
          //     // },
          //     {
          //       title: 'Session Filter',
          //       specificPartition: true,
          //       lineage: ['session-filter'],
          //       uiJsonPath: 'security/firewall/session/session-filter',
          //     },
          //     {
          //       title: 'Session Aging',
          //       lineage: ['fw.session-aging'],
          //       uiJsonPath: 'security/firewall/session/session-aging',
          //     },
          //   ],
          // },
          // {
          //   title: 'Log',
          //   should_disable: {
          //     platform: ['chassis'],
          //   },
          //   url_name: 'security_firewall_log_index',
          //   lineage: ['logging.local-log.app-fw.oper'],
          // },
        ],
      },
      // {
      //   title: 'SSLi',
      //   should_disable: {
      //     platform: ['chassis'],
      //     partition_app_type: ['cgnv6'],
      //   },
      //   module_name: ['ssli'],
      //   menus: [
      //     // {
      //     //   title: 'SSLi Dashboard',
      //     //   url_name: 'security_ssli_dashboard_index',
      //     //   lineage: [
      //     //     'slb.ssl-forward-proxy',
      //     //     'logging.local-log.file-category.top-n',
      //     //   ],
      //     // },
      //     {
      //       title: 'SSLi Services',
      //       lineage: ['slb.virtual-server'],
      //       uiJsonPath: 'security/ssli/service',
      //       modalSize: 'extralarge',
      //       userTag: 'Security',
      //     },
      //     {
      //       title: 'SSLi Service Group',
      //       url_name: 'security_ssli_service_groups_index',
      //       lineage: ['slb.service-group'],
      //       uiJsonPath: 'security/ssli/service-groups',
      //       modalSize: 'extralarge',
      //       userTag: 'Security',
      //     },
      //     {
      //       title: 'SSLi Servers',
      //       url_name: 'security_ssli_servers_index',
      //       lineage: ['slb.server'],
      //       uiJsonPath: 'security/ssli/servers',
      //       modalSize: 'extralarge',
      //       userTag: 'Security',
      //     },
      //     {
      //       title: 'SSLi Templates',
      //       url_name: 'security_ssli_templates_index',
      //       lineage: [
      //         'slb.template.http',
      //         'slb.template.tcp-proxy',
      //         'slb.template.server-ssl',
      //         'slb.template.client-ssl',
      //         'slb.template.persist.ssl-sid',
      //         'slb.template.persist.source-ip',
      //         'slb.template.persist.destination-ip',
      //         'slb.template.persist.cookie',
      //         'slb.template.respmod-icap',
      //         'slb.template.reqmod-icap',
      //       ],
      //       menus: [
      //         {
      //           title: 'Client SSL',
      //           url_name: 'template_clientssl_create',
      //           url_params: {
      //             template_type: 'Client SSL',
      //             category: 'ssl',
      //           },
      //           related_urls: ['template_clientssl_update'],
      //           lineage: ['slb.template.client-ssl'],
      //           uiJsonPath: 'security/ssli/templates/client-ssl',
      //           modalSize: 'extralarge',
      //           userTag: 'Security',
      //         },
      //         {
      //           title: 'Server SSL',
      //           url_name: 'template_serverssl_create',
      //           url_params: {
      //             template_type: 'Server SSL',
      //             category: 'ssl',
      //           },
      //           related_urls: ['template_serverssl_update'],
      //           lineage: ['slb.template.server-ssl'],
      //           uiJsonPath: 'security/ssli/templates/server-ssl',
      //           modalSize: 'extralarge',
      //           userTag: 'Security',
      //         },
      //         {
      //           title: 'HTTP',
      //           url_name: 'template_http_create',
      //           url_params: {
      //             template_type: 'HTTP',
      //             category: 'l7',
      //           },
      //           related_urls: ['template_http_update'],
      //           lineage: ['slb.template.http'],
      //           uiJsonPath: 'security/ssli/templates/http',
      //           modalSize: 'extralarge',
      //           userTag: 'Security',
      //         },
      //         {
      //           title: 'TCP Proxy',
      //           url_name: 'template_tcpproxy_create',
      //           url_params: {
      //             template_type: 'TCP Proxy',
      //             category: 'l7',
      //           },
      //           related_urls: ['template_tcpproxy_update'],
      //           lineage: ['slb.template.tcp-proxy'],
      //           uiJsonPath: 'security/ssli/templates/tcp-proxy',
      //           userTag: 'Security',
      //         },
      //         {
      //           title: 'Persist Cookie',
      //           url_name: 'template_persistcookie_create',
      //           url_params: {
      //             template_type: 'Persist Cookie',
      //             category: 'persistence',
      //           },
      //           related_urls: ['template_persistcookie_update'],
      //           lineage: ['slb.template.persist.cookie'],
      //           uiJsonPath: 'security/ssli/templates/cookie',
      //           userTag: 'Security',
      //         },
      //         {
      //           title: 'Persist Destination IP',
      //           url_name: 'template_persistdest_create',
      //           url_params: {
      //             template_type: 'Persist Destination IP',
      //             category: 'persistence',
      //           },
      //           related_urls: ['template_persistdest_update'],
      //           lineage: ['slb.template.persist.destination-ip'],
      //           uiJsonPath: 'security/ssli/templates/destination-ip',
      //           userTag: 'Security',
      //         },
      //         {
      //           title: 'Persist Source IP',
      //           url_name: 'template_persistsource_create',
      //           url_params: {
      //             template_type: 'Persist Source IP',
      //             category: 'persistence',
      //           },
      //           related_urls: ['template_persistsource_update'],
      //           lineage: ['slb.template.persist.source-ip'],
      //           uiJsonPath: 'security/ssli/templates/source-ip',
      //           userTag: 'Security',
      //         },
      //         {
      //           title: 'Persist SSL SID',
      //           url_name: 'template_persistsslsid_create',
      //           url_params: {
      //             template_type: 'Persist SSL SID',
      //             category: 'persistence',
      //           },
      //           related_urls: ['template_persistsslsid_update'],
      //           lineage: ['slb.template.persist.ssl-sid'],
      //           uiJsonPath: 'security/ssli/templates/ssl-sid',
      //           userTag: 'Security',
      //         },
      //         {
      //           title: 'RESPMOD',
      //           url_name: 'template_respmod_create',
      //           lineage: ['slb.template.respmod-icap'],
      //           uiJsonPath: 'security/ssli/templates/resmod',
      //           userTag: 'Security',
      //         },
      //         {
      //           title: 'REQMOD',
      //           url_name: 'template_reqmod_create',
      //           lineage: ['slb.template.reqmod-icap'],
      //           uiJsonPath: 'security/ssli/templates/reqmod',
      //           userTag: 'Security',
      //         },
      //         {
      //           title: 'SSLi',
      //           lineage: ['slb.template.ssli'],
      //           uiJsonPath: 'security/ssli/templates/ssli',
      //           userTag: 'Security',
      //         },
      //         // there is no file inspection menu in security module in Thunder
      //         // {
      //         //   title: 'File Inspection',
      //         //   url_name: 'template_reqmod_create',
      //         //   lineage: ['file-inspection.template'],
      //         // },
      //       ],
      //     },
      //     {
      //       title: 'SSL Certs',
      //       url_name: 'security_ssli_ssl_certs_index',
      //       fileType: 'ssl',
      //       lineage: [
      //         'file.ssl-key',
      //         'file.ssl-cert',
      //         'file.ssl-ca',
      //         'file.ssl-csr',
      //       ],
      //       modalSize: 'extralarge',
      //     },
      //     {
      //       title: 'SSLi Class Lists',
      //       menus: [
      //         {
      //           title: 'SSLi Configuration',
      //           lineage: ['class-list'],
      //           uiJsonPath: 'security/ssli/class-lists',
      //           modalSize: 'extralarge',
      //           userTag: 'Security',
      //         },
      //         {
      //           title: 'SSLi Import',
      //           addLabel: 'SSLi',
      //           fileType: 'class-list',
      //           lineage: ['file.class-list'],
      //           modalSize: 'extralarge',
      //         },
      //       ],
      //     },
      //     // {
      //     //   title: 'Reports',
      //     //   url_name: 'security_ssli_info_index',
      //     // },
      //   ],
      // },
      // {
      //   title: 'Forward Proxy',
      //   should_disable: {
      //     platform: ['chassis'],
      //     partition_app_type: ['cgnv6'],
      //   },
      //   menus: [
      //     {
      //       title: 'Services',
      //       lineage: ['slb.virtual-server'],
      //       uiJsonPath: 'security/forward-proxy/service',
      //       modalSize: 'extralarge',
      //       userTag: 'Security',
      //     },
      //     {
      //       title: 'Service Groups',
      //       lineage: ['slb.service-group'],
      //       uiJsonPath: 'security/forward-proxy/service-groups',
      //       modalSize: 'extralarge',
      //       userTag: 'Security',
      //     },
      //     {
      //       title: 'Servers',
      //       lineage: ['slb.server'],
      //       uiJsonPath: 'security/forward-proxy/servers',
      //       modalSize: 'extralarge',
      //       userTag: 'Security',
      //     },
      //     {
      //       title: 'Forward Proxy Templates',
      //       url_name: 'security_forward_proxy_templates_index',
      //       lineage: [
      //         'slb.template.policy',
      //         'slb.template.client-ssl',
      //         'slb.template.dynamic-service',
      //       ],
      //       menus: [
      //         {
      //           title: 'Policy',
      //           lineage: ['slb.template.policy'],
      //           uiJsonPath: 'security/forward-proxy/templates/policy',
      //           userTag: 'Security',
      //         },
      //         {
      //           title: 'Client SSL',
      //           lineage: ['slb.template.client-ssl'],
      //           uiJsonPath: 'security/forward-proxy/templates/client-ssl',
      //           modalSize: 'extralarge',
      //           userTag: 'Security',
      //         },
      //         {
      //           title: 'Dynamic Service',
      //           lineage: ['slb.template.dynamic-service'],
      //           uiJsonPath: 'security/forward-proxy/templates/dynamic-service',
      //           userTag: 'Security',
      //         },
      //       ],
      //     },
      //     {
      //       title: 'Class Lists',
      //       menus: [
      //         {
      //           title: 'Configuration',
      //           url_name: 'security_forward_proxy_class_list_index',
      //           lineage: ['class-list'],
      //           uiJsonPath: 'security/forward-proxy/class-lists/configuration',
      //           modalSize: 'extralarge',
      //           userTag: 'Security',
      //         },
      //         {
      //           title: 'Import',
      //           addLabel: 'Class List',
      //           fileType: 'class-list',
      //           lineage: ['file.class-list'],
      //           modalSize: 'extralarge',
      //         },
      //       ],
      //     },
      //     // {
      //     //   title: 'Statistics',
      //     //   url_name: 'security_forward_proxy_statistics_index',
      //     //   lineage: ['slb.http-proxy', 'slb.ftp-proxy', 'slb.template.policy'],
      //     // },
      //   ],
      // },
      // {
      //   title: 'IPsec VPN',
      //   should_disable: {
      //     platform: ['chassis'],
      //   },
      //   module_name: ['ipsec'],
      //   menus: [
      //     {
      //       title: 'VPN Tunnels',
      //       lineage: ['vpn.ipsec'],
      //       uiJsonPath: 'security/ipsec-vpn/vpn-tunnels',
      //     },
      //     {
      //       title: 'IKE Gateway',
      //       lineage: ['vpn.ike-gateway'],
      //       uiJsonPath: 'security/ipsec-vpn/ike-gateway',
      //     },
      //     // {
      //     //   title: 'Interface',
      //     //   should_disable: {
      //     //     partition: ['sp'],
      //     //   },
      //     //   lineage: ['interface.tunnel'],
      //     //   uiJsonPath: 'security/ipsec-vpn/interfaces',
      //     // },
      //     {
      //       title: 'Settings',
      //       url_name: 'security_ipsec_vpn_settings_index',
      //       lineage: ['vpn', 'system.ipsec'],
      //       uiJsonPath: 'security/ipsec-vpn/settings',
      //       onlyEditMode: true,
      //       modalSize: 'extralarge',
      //     },
      //     // {
      //     //   title: 'Statistics',
      //     //   url_name: 'security_ipsec_vpn_statistics_index',
      //     //   lineage: ['vpn', 'interface.tunnel'],
      //     // },
      //   ],
      // },
      // {
      //   title: 'DDoS',
      //   should_disable: {
      //     partition: ['l3v'],
      //   },
      //   lineage: ['ip.anomaly-drop'],
      //   uiJsonPath: 'security/ddos',
      //   onlyEditMode: true,
      // },
      // {
      //   title: 'Threat Intel',
      //   should_disable: {
      //     platform: ['chassis'],
      //   },
      //   module_name: ['WEBROOT_TI'],
      //   url_name: 'threat_intel_configure',
      //   lineage: ['threat-intel.threat-feed', 'threat-intel.threat-list'],
      //   uiJsonPath: 'security/threat-intel',
      //   modalSize: 'extralarge',
      //   // menus: [
      //   //   {
      //   //     title: 'Configure',
      //   //     url_name: 'threat_intel_configure',
      //   //     lineage: ['threat-intel.threat-feed', 'threat-intel.threat-list'],
      //   //   },
      //   //   // {
      //   //   //   title: 'Database',
      //   //   //   url_name: 'threat_intel_database',
      //   //   //   lineage: ['threat-intel.webroot-database.oper'],
      //   //   // },
      //   //   // {
      //   //   //   title: 'Statistics',
      //   //   //   url_name: 'threat_intel_statistics',
      //   //   //   lineage: [
      //   //   //     'threat-intel.webroot-global.stats',
      //   //   //     'threat-intel.webroot-ip-category.oper',
      //   //   //     'threat-intel.threat-list',
      //   //   //   ],
      //   //   // },
      //   // ],
      // },
      // {
      //   title: 'Web Categories',
      //   should_disable: {
      //     platform: ['chassis'],
      //   },
      //   module_name: ['urlf'],
      //   url_name: 'security_web_categories_configure_index',
      //   lineage: ['web-category'],
      //   uiJsonPath: 'security/web-category',
      //   modalSize: 'extralarge',
      //   onlyEditMode: true,
      //   // menus: [
      //   //   {
      //   //     title: 'Configure',
      //   //     url_name: 'security_web_categories_configure_index',
      //   //     lineage: ['web-category'],
      //   //   },
      //   //   // {
      //   //   //   title: 'License',
      //   //   //   url_name: 'security_web_categories_license_index',
      //   //   // },
      //   //   // {
      //   //   //   title: 'Statistics',
      //   //   //   url_name: 'security_web_categories_statistics_index',
      //   //   // },
      //   // ],
      // },
    ],
  },
  // {
  //   title: 'AAM',
  //   icon: 'fa-id-card-o',
  //   should_disable: {
  //     lineage: ['aam'],
  //     platform: ['chassis'],
  //     partition_app_type: ['cgnv6'],
  //   },
  //   menu_name: ['aam'],
  //   menus: [
  //     {
  //       title: 'Auth Clients',
  //       menus: [
  //         {
  //           title: 'Logon HTTP Auth',
  //           lineage: ['aam.authentication.logon.http-authenticate.instance'],
  //           uiJsonPath: 'aam/auth-clients/logon-http-auth',
  //         },
  //         {
  //           title: 'Logon Form Based',
  //           lineage: ['aam.authentication.logon.form-based'],
  //           uiJsonPath: 'aam/auth-clients/logon-form-based',
  //         },
  //         {
  //           title: 'Portal',
  //           lineage: ['aam.authentication.portal'],
  //           uiJsonPath: 'aam/auth-clients/portal/portal',
  //           customizedPage: 'portal',
  //           menus: [
  //             {
  //               title: 'Portal Logon',
  //               onlyEditMode: true,
  //               customizedPage: 'portal',
  //               defaultParams: {
  //                 name: 'default-portal',
  //               },
  //               lineage: ['aam.authentication.portal.logon'],
  //               uiJsonPath: 'aam/auth-clients/portal/logon',
  //             },
  //             {
  //               title: 'Portal Logon Fail',
  //               onlyEditMode: true,
  //               customizedPage: 'portal',
  //               defaultParams: {
  //                 name: 'default-portal',
  //               },
  //               lineage: ['aam.authentication.portal.logon-fail'],
  //               uiJsonPath: 'aam/auth-clients/portal/logon-fail',
  //             },
  //             {
  //               title: 'Portal Change Password',
  //               onlyEditMode: true,
  //               customizedPage: 'portal',
  //               defaultParams: {
  //                 name: 'default-portal',
  //               },
  //               lineage: ['aam.authentication.portal.change-password'],
  //               uiJsonPath: 'aam/auth-clients/portal/change-password',
  //             },
  //             {
  //               title: 'Notify Change Password',
  //               onlyEditMode: true,
  //               customizedPage: 'portal',
  //               defaultParams: {
  //                 name: 'default-portal',
  //               },
  //               lineage: ['aam.authentication.portal.change-password'],
  //               uiJsonPath: 'aam/auth-clients/portal/notify-change-password',
  //             },
  //             // {
  //             //   title: 'Portal Logo CFG',
  //             //   onlyEditMode: true,
  //             //   customizedPage: 'portal',
  //             //   defaultParams: {
  //             //     name: 'default-portal',
  //             //   },
  //             //   lineage: ['aam.authentication.portal.logo-cfg'],
  //             // },
  //           ],
  //         },
  //         {
  //           title: 'SAML Service Providers',
  //           lineage: ['aam.authentication.saml.service-provider'],
  //           uiJsonPath: 'aam/auth-clients/saml/service-provider',
  //         },
  //         {
  //           title: 'SAML Identity Providers',
  //           lineage: ['aam.authentication.saml.identity-provider'],
  //           uiJsonPath: 'aam/auth-clients/saml/identity-provider',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Auth Relays',
  //       menus: [
  //         {
  //           title: 'HTTP Basic',
  //           lineage: ['aam.authentication.relay.http-basic.instance'],
  //           uiJsonPath: 'aam/auth-relays/http-basic',
  //         },
  //         {
  //           title: 'Form Based',
  //           modalSize: 'extralarge',
  //           lineage: ['aam.authentication.relay.form-based.instance'],
  //           uiJsonPath: 'aam/auth-relays/form-based',
  //         },
  //         {
  //           title: 'NTLM',
  //           lineage: ['aam.authentication.relay.ntlm'],
  //           uiJsonPath: 'aam/auth-relays/ntlm',
  //         },
  //         {
  //           title: 'Kerberos',
  //           lineage: ['aam.authentication.relay.kerberos.instance'],
  //           uiJsonPath: 'aam/auth-relays/kerberos',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Auth JWT',
  //       lineage: ['aam.authentication.jwt'],
  //       uiJsonPath: 'aam/auth-jwt',
  //     },
  //     {
  //       title: 'Accounts',
  //       menus: [
  //         {
  //           title: 'Kerberos',
  //           lineage: ['aam.authentication.account.kerberos-spn'],
  //           uiJsonPath: 'aam/accounts/kerberos-spns',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Auth Servers',
  //       menus: [
  //         {
  //           title: 'RADIUS',
  //           lineage: ['aam.authentication.server.radius.instance'],
  //           uiJsonPath: 'aam/auth-servers/radius',
  //         },
  //         {
  //           title: 'LDAP',
  //           lineage: ['aam.authentication.server.ldap.instance'],
  //           uiJsonPath: 'aam/auth-servers/ldap',
  //         },
  //         {
  //           title: 'OCSP',
  //           lineage: ['aam.authentication.server.ocsp.instance'],
  //           uiJsonPath: 'aam/auth-servers/oscp',
  //         },
  //         {
  //           title: 'Windows',
  //           lineage: ['aam.authentication.server.windows.instance'],
  //           uiJsonPath: 'aam/auth-servers/windows',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Service Groups',
  //       modalSize: 'extralarge',
  //       lineage: ['aam.authentication.service-group'],
  //       uiJsonPath: 'aam/service-groups',
  //     },
  //     {
  //       title: 'JWT Authorization',
  //       lineage: ['aam.jwt-authorization'],
  //       uiJsonPath: 'aam/jwt-authorization',
  //     },
  //     {
  //       title: 'Policies and Templates',
  //       menus: [
  //         {
  //           title: 'AAA Policies',
  //           modalSize: 'extralarge',
  //           lineage: ['aam.aaa-policy'],
  //           uiJsonPath: 'aam/policies-and-templates/aaa-policy',
  //         },
  //         {
  //           title: 'Authorization Policies',
  //           modalSize: 'extralarge',
  //           lineage: ['aam.authorization.policy'],
  //           uiJsonPath: 'aam/policies-and-templates/authorization-policy',
  //         },
  //         {
  //           title: 'Authentication Templates',
  //           lineage: ['aam.authentication.template'],
  //           uiJsonPath: 'aam/policies-and-templates/authentication-template',
  //         },
  //         {
  //           title: 'Authentication Files',
  //           menus: [
  //             {
  //               title: 'Auth Portals',
  //               fileType: 'auth-portal',
  //               lineage: ['file.auth-portal'],
  //               modalSize: 'extralarge',
  //               onlyDelete: true,
  //             },
  //             {
  //               title: 'Auth Portal Images',
  //               fileType: 'auth-portal-image',
  //               lineage: ['file.auth-portal-image'],
  //               modalSize: 'extralarge',
  //               onlyDelete: true,
  //             },
  //             {
  //               title: 'Auth JWT Files',
  //               fileType: 'auth-jwk',
  //               lineage: ['file.auth-jwk'],
  //               modalSize: 'extralarge',
  //             },
  //             {
  //               title: 'SAML Metadata',
  //               fileType: 'auth-saml-idp',
  //               lineage: ['file.auth-saml-idp'],
  //               modalSize: 'extralarge',
  //             },
  //           ],
  //         },
  //         // {
  //         //   title: 'Global Stats',
  //         //   url_name: 'aam_stats',
  //         //   lineage: ['aam.authentication.global.stats'],
  //         // },
  //       ],
  //     },
  //     {
  //       title: 'Log Settings',
  //       onlyEditMode: true,
  //       lineage: ['aam.authentication.log'],
  //       uiJsonPath: 'aam/log-settings/log',
  //     },
  //   ],
  // },
  {
    title: 'CGN',
    icon: 'fa-inbox',
    menu_name: ['cgn'],
    should_disable: {
      lineage: ['cgnv6'],
      partition_app_type: ['adc'],
      virtual_type: ['azure', 'aws', 'aws-hvm'],
    },
    // specificPartition: ['all'],
    specificPartition: ['lp'],
    menus: [
      {
        title: 'LSN',
        // specificPartition: ['all'],
        specificPartition: ['lp'],
        menus: [
          {
            title: 'LSN LID',
            lineage: ['cgnv6.lsn-lid'],
            uiJsonPath: 'cgn/lsn/lsn-lid',
          },
          {
            title: 'LSN Pools',
            lineage: ['cgnv6.nat.pool'],
            uiJsonPath: 'cgn/lsn/lsn-pools',
          },
          {
            title: 'LSN Pool Groups',
            lineage: ['cgnv6.nat.pool-group'],
            uiJsonPath: 'cgn/lsn/lsn-pool-groups',
          },
          {
            title: 'Port-Reservations',
            lineage: ['cgnv6.lsn.port-reservation'],
            uiJsonPath: 'cgn/lsn/port-reservations',
            viewMode: false,
          },
          {
            title: 'Rule Lists',
            lineage: ['cgnv6.lsn-rule-list'],
            uiJsonPath: 'cgn/lsn/lsn-rule-list',
            modalSize: 'extralarge',
          },
          // {
          //   title: 'Interfaces',
          //   should_disable: {
          //     partition: ['sp'],
          //   },
          //   // specificPartition: true,
          //   specificPartition: ['lp'],
          //   menus: [
          //     {
          //       title: 'Ethernets',
          //       lineage: ['interface.ethernet'],
          //       uiJsonPath: 'cgn/lsn/lsn-interfaces/ethernet',
          //       modalSize: 'extralarge',
          //       // specificPartition: true,
          //     },
          //     {
          //       title: 'Virtual Ethernets',
          //       lineage: ['interface.ve'],
          //       uiJsonPath: 'cgn/lsn/lsn-interfaces/ve',
          //       modalSize: 'extralarge',
          //       // specificPartition: ['dp'],
          //     },
          //     {
          //       title: 'Trunks',
          //       lineage: ['interface.trunk'],
          //       uiJsonPath: 'cgn/lsn/lsn-interfaces/trunk',
          //       modalSize: 'extralarge',
          //       // specificPartition: true,
          //     },
          //   ],
          // },
          {
            title: 'Class Lists',
            lineage: ['class-list'],
            deleteOnlyNode: { file: 1 },
            uiJsonPath: 'cgn/lsn/class-lists',
            modalSize: 'extralarge',
          },
          {
            title: 'CGN Templates',
            menus: [
              {
                title: 'CGN Logging',
                lineage: ['cgnv6.template.logging'],
                uiJsonPath: 'cgn/lsn/templates/logging',
              },
              {
                title: 'PCP',
                lineage: ['cgnv6.template.pcp'],
                uiJsonPath: 'cgn/lsn/templates/pcp',
              },
              {
                title: 'HTTP-ALG',
                lineage: ['cgnv6.template.http-alg'],
                uiJsonPath: 'cgn/lsn/templates/http-alg',
              },
            ],
          },
          {
            title: 'Full Cone',
            lineage: [
              'cgnv6.lsn.endpoint-independent-mapping.tcp',
              'cgnv6.lsn.endpoint-independent-mapping.udp',
              'cgnv6.lsn.endpoint-independent-filtering.tcp',
              'cgnv6.lsn.endpoint-independent-filtering.udp',
            ],
            onlyEditMode: true,
            uiJsonPath: 'cgn/lsn/full-cone',
          },
          {
            title: 'ALG',
            lineage: [
              'cgnv6.lsn.alg.dns',
              'cgnv6.lsn.alg.esp',
              'cgnv6.lsn.alg.ftp',
              'cgnv6.lsn.alg.h323',
              'cgnv6.lsn.alg.mgcp',
              'cgnv6.lsn.alg.pptp',
              'cgnv6.lsn.alg.rtp',
              'cgnv6.lsn.alg.rtsp',
              'cgnv6.lsn.alg.sip',
              'cgnv6.lsn.alg.tftp',
            ],
            onlyEditMode: true,
            uiJsonPath: 'cgn/lsn/lsn-alg',
          },
          {
            title: 'Global',
            lineage: [
              'cgnv6.lsn.global',
              'cgnv6.lsn.alg',
              'cgnv6.lsn.radius.server',
              'cgnv6.lsn.stun-timeout',
              'cgnv6.lsn.tcp',
              'cgnv6.lsn.port-overloading',
              'cgnv6.lsn.health-check-gateway',
              'cgnv6.lsn.inside.source',
              'cgnv6.translation',
              'cgnv6.nat.exclude-port',
            ],
            modalSize: 'extralarge',
            onlyEditMode: true,
            uiJsonPath: 'cgn/lsn/global',
          },
          // {
          //   title: 'Stats',
          //   menus: [
          //     {
          //       title: 'Global',
          //       url_name: 'cgn_lsn_global_stats',
          //       url_params: {
          //         stats_type: 'LSN Global',
          //       },
          //       lineage: ['cgnv6.lsn.global.stats'],
          //     },
          //     {
          //       title: 'Logging',
          //       url_name: 'cgn_logging_stats',
          //       url_params: {
          //         stats_type: 'Logging',
          //       },
          //       lineage: ['cgnv6.logging.stats'],
          //     },
          //     {
          //       title: 'PCP',
          //       url_name: 'cgn_pcp_stats',
          //       url_params: {
          //         stats_type: 'PCP',
          //       },
          //       lineage: ['cgnv6.pcp.stats'],
          //     },
          //     {
          //       title: 'HTTP-ALG',
          //       url_name: 'cgn_http_alg_stats',
          //       url_params: {
          //         stats_type: 'HTTP-ALG',
          //       },
          //       lineage: ['cgnv6.http-alg.stats'],
          //     },
          //     {
          //       title: 'Radius',
          //       url_name: 'cgn_radius_server_stats',
          //       url_params: {
          //         stats_type: 'Radius-Server',
          //       },
          //       lineage: ['cgnv6.lsn.radius.server.stats'],
          //     },
          //     {
          //       title: 'ALG',
          //       url_name: 'cgn_alg_stats',
          //       url_params: {
          //         stats_type: 'ALG',
          //       },
          //       lineage: [
          //         'cgnv6.lsn.alg.ftp.stats',
          //         'cgnv6.lsn.alg.esp.stats',
          //         'cgnv6.lsn.alg.pptp.stats',
          //         'cgnv6.lsn.alg.rtsp.stats',
          //         'cgnv6.lsn.alg.sip.stats',
          //         'cgnv6.lsn.alg.tftp.stats',
          //       ],
          //     },
          //     {
          //       title: 'NAT L4',
          //       url_name: 'cgn_nat_l4_stats',
          //       url_params: {
          //         stats_type: 'NAT L4',
          //       },
          //       lineage: ['cgnv6.l4.stats'],
          //     },
          //     {
          //       title: 'NAT ICMP',
          //       url_name: 'cgn_nat_icmp_stats',
          //       url_params: {
          //         stats_type: 'NAT ICMP',
          //       },
          //       lineage: ['cgnv6.icmp.stats'],
          //     },
          //     {
          //       title: 'Port Reservation',
          //       url_name: 'cgn_port_reservation_stats',
          //       lineage: ['cgnv6.lsn.port-reservation-entries.oper'],
          //     },
          //     {
          //       title: 'Full Cone Session',
          //       url_name: 'cgn_full_cone_session_stats',
          //       lineage: ['cgnv6.lsn.full-cone-session.oper'],
          //     },
          //     {
          //       title: 'Subscriber Information',
          //       url_name: 'cgn_user_quota_session_stats',
          //       lineage: [
          //         'cgnv6.lsn.user-quota-session.oper',
          //         'cgnv6.lsn.enhanced-user-tracking.oper',
          //       ],
          //     },
          //   ],
          // },
        ],
      },
      // {
      //   title: 'NAT64',
      //   menus: [
      //     {
      //       title: 'NAT64',
      //       lineage: [
      //         'cgnv6.nat64.global',
      // 'cgnv6.nat64.alg.ftp',
      // 'cgnv6.nat64.alg.sip',
      // 'cgnv6.nat64.alg.esp',
      // 'cgnv6.nat64.alg.pptp',
      // 'cgnv6.nat64.alg.rtsp',
      // 'cgnv6.nat64.alg.tftp',
      // 'cgnv6.nat64.fragmentation.df-bit-transparency',
      // 'cgnv6.nat64.fragmentation.inbound',
      // 'cgnv6.nat64.fragmentation.outbound',
      //     'cgnv6.nat64.prefix',
      //   ],
      //   onlyEditMode: true,
      //   uiJsonPath: 'cgn/nat64',
      // },
      // {
      //   title: 'Stats',
      //   menus: [
      //     {
      //       title: 'Global',
      //       url_name: 'nat64_global_stats',
      //       url_params: {
      //         stats_type: 'NAT64 Global',
      //       },
      //       lineage: ['cgnv6.nat64.global', 'cgnv6.nat64.global.stats'],
      //     },
      //     {
      //       title: 'Full Cone Sessions',
      //       url_name: 'nat64_full_cone_stats',
      //       lineage: ['cgnv6.nat64.full-cone-session.oper'],
      //     },
      //     {
      //       title: 'Subscriber Information',
      //       url_name: 'nat64_user_quota_stats',
      //       lineage: [
      //         'cgnv6.nat64.user-quota-session.oper',
      //         'cgnv6.nat64.enhanced-user-tracking.oper',
      //       ],
      //     },
      //   ],
      // },
      //   ],
      // },
      {
        title: 'DNS64',
        menus: [
          // {
          //   title: 'Virtual Servers',
          //   modalSize: 'extralarge',
          //   lineage: ['cgnv6.dns64-virtualserver'],
          //   uiJsonPath: 'cgn/dns64/virtual-servers',
          // },
          {
            title: 'Templates',
            menus: [
              {
                title: 'DNS',
                lineage: ['cgnv6.template.dns'],
                uiJsonPath: 'cgn/dns64/templates/dns',
              },
              {
                title: 'Policy',
                lineage: ['cgnv6.template.policy'],
                uiJsonPath: 'cgn/dns64/templates/policy',
              },
            ],
          },
          // {
          //   title: 'Stats',
          //   url_name: 'dns64_stats',
          //   lineage: ['cgnv6.dns64.stats'],
          // },
        ],
      },
      {
        title: 'Fixed NAT',
        menus: [
          // {
          //   title: 'Fixed NAT',
          //   lineage: [
          //     'cgnv6.fixed-nat.inside.ipv4address',
          //     'cgnv6.fixed-nat.inside.ipv6address',
          //     'cgnv6.fixed-nat.inside.iplist',
          //   ],
          //   should_disable: {
          //     // '@condition':  menu_conditions.fixed_nat_menu,
          //   },
          //   menus: [
          //     {
          //       title: 'Inside IPv4',
          //       lineage: ['cgnv6.fixed-nat.inside.ipv4address'],
          //       uiJsonPath: 'cgn/fixed-nat/inside/ipv4-address',
          //       viewMode: true,
          //     },
          //     {
          //       title: 'Inside IPv6',
          //       lineage: ['cgnv6.fixed-nat.inside.ipv6address'],
          //       uiJsonPath: 'cgn/fixed-nat/inside/ipv6-address',
          //       viewMode: true,
          //     },
          //     {
          //       title: 'Inside IP List',
          //       lineage: ['cgnv6.fixed-nat.inside.iplist'],
          //       uiJsonPath: 'cgn/fixed-nat/inside/ip-list',
          //       viewMode: true,
          //     },
          //   ],
          // },
          // {
          //   title: 'Port Mapping Files',
          //   lineage: ['cgnv6.fixed-nat.port-mapping-files'],
          // },
          {
            title: 'Global',
            lineage: ['cgnv6.fixed-nat.global'],
            onlyEditMode: true,
            uiJsonPath: 'cgn/fixed-nat/global',
          },
          {
            title: 'CGN IP Lists',
            url_name: 'ip_list_index',
            related_urls: ['ip_list_update'],
            lineage: ['ip-list'],
            uiJsonPath: 'cgn/fixed-nat/ip-lists',
          },
          // {
          //   title: 'Stats',
          //   menus: [
          //     {
          //       title: 'Global',
          //       url_name: 'cgn_fixed_nat_global_stats',
          //       url_params: {
          //         stats_type: 'Fixed NAT Global',
          //       },
          //       lineage: ['cgnv6.fixed-nat.global.stats'],
          //     },
          //     {
          //       title: 'ALG',
          //       url_name: 'cgn_fixed_nat_alg_stats',
          //       url_params: {
          //         stats_type: 'Fixed NAT ALG',
          //       },
          //       lineage: [
          //         'cgnv6.fixed-nat.alg.ftp.stats',
          //         'cgnv6.fixed-nat.alg.esp.stats',
          //         'cgnv6.fixed-nat.alg.pptp.stats',
          //         'cgnv6.fixed-nat.alg.rtsp.stats',
          //         'cgnv6.fixed-nat.alg.sip.stats',
          //         'cgnv6.fixed-nat.alg.tftp.stats',
          //       ],
          //     },
          //     {
          //       title: 'Full Cone Session',
          //       url_name: 'cgn_fixed_nat_full_cone_session_stats',
          //       lineage: ['cgnv6.fixed-nat.full-cone-session.oper'],
          //     },
          //   ],
          // },
        ],
      },
      {
        title: 'One-to-One NAT',
        menus: [
          {
            title: 'One to One Pools',
            lineage: ['cgnv6.one-to-one.pool'],
            uiJsonPath: 'cgn/one-to-one-nat/pools',
            viewMode: false,
          },
          {
            title: 'One to One Pool Groups',
            lineage: ['cgnv6.one-to-one.pool-group'],
            uiJsonPath: 'cgn/one-to-one-nat/pool-groups',
          },
          {
            title: 'One To One Global',
            url_name: 'one_to_one_global',
            lineage: ['cgnv6.one-to-one.global'],
            onlyEditMode: true,
            uiJsonPath: 'cgn/one-to-one-nat/global',
          },
          // {
          //   title: 'Stats',
          //   url_name: 'one_to_one_stats',
          //   url_params: {
          //     stats_type: 'One Global',
          //   },
          //   lineage: ['cgnv6.one-to-one.global.stats'],
          // },
          // {
          //   title: 'Mappings',
          //   url_name: 'one_to_one_mapping',
          //   lineage: ['cgnv6.one-to-one.mapping.oper'],
          // },
        ],
      },
      // {
      //   title: 'DS-Lite',
      //   menus: [
      //     {
      //       title: 'Port Reservation',
      //       lineage: ['cgnv6.ds-lite.port-reservation'],
      //       uiJsonPath: 'cgn/ds-lite/port-reservations',
      //       viewMode: true,
      //     },
      //     {
      //       title: 'Global',
      //       lineage: [
      //         'cgnv6.ds-lite.alg.ftp',
      //         'cgnv6.ds-lite.alg.tftp',
      //         'cgnv6.ds-lite.alg.rtsp',
      //         'cgnv6.ds-lite.alg.pptp',
      //         'cgnv6.ds-lite.alg.sip',
      //         'cgnv6.ds-lite.alg.h323',
      //         'cgnv6.ds-lite.alg.mgcp',
      //         'cgnv6.ds-lite.fragmentation.inbound',
      //         'cgnv6.ds-lite.fragmentation.outbound',
      //         'cgnv6.ds-lite.global',
      //       ],
      //       onlyEditMode: true,
      //       uiJsonPath: 'cgn/ds-lite/global',
      //     },
      // {
      //   title: 'Stats',
      //   menus: [
      //     {
      //       title: 'Global',
      //       url_name: 'ds_lite_global_stats',
      //       url_params: {
      //         stats_type: 'DS Lite Global',
      //       },
      //       lineage: ['cgnv6.ds-lite.global.stats'],
      //     },
      //     {
      //       title: 'Full Cone Sessions',
      //       url_name: 'ds_lite_full_cone_stats',
      //       lineage: ['cgnv6.ds-lite.full-cone-session.oper'],
      //     },
      //     {
      //       title: 'User Quota Sessions',
      //       url_name: 'ds_lite_user_quota_stats',
      //       lineage: ['cgnv6.ds-lite.user-quota-session.oper'],
      //     },
      //     {
      //       title: 'Port Reservations',
      //       url_name: 'ds_lite_port_reservation_stats',
      //       lineage: ['cgnv6.ds-lite.port-reservation-entries.oper'],
      //     },
      //   ],
      // },
      //   ],
      // },
      // {
      //   title: 'LW-4over6',
      //   menus: [
      //     {
      //       title: 'Binding Table',
      //       lineage: ['cgnv6.lw-4o6.active-binding-table'],
      //       uiJsonPath: 'cgn/lw-4over6/binding-table',
      //       modalSize: 'extralarge',
      //     },
      //     {
      //       title: 'LW-4over6 Global',
      //       lineage: ['cgnv6.lw-4o6.global'],
      //       onlyEditMode: true,
      //       uiJsonPath: 'cgn/lw-4over6/global',
      //     },
      //     // {
      //     //   title: 'Stats',
      //     //   menus: [
      //     //     {
      //     //       title: 'Global',
      //     //       url_name: 'lw_stats',
      //     //       url_params: {
      //     //         stats_type: 'LW Global',
      //     //       },
      //     //       lineage: ['cgnv6.lw-4o6.global', 'cgnv6.lw-4o6.global.stats'],
      //     //     },
      //     //     {
      //     //       title: 'Binding Table Stats',
      //     //       url_name: 'binding_table_stats',
      //     //       url_params: {
      //     //         stats_type: 'BindingTable',
      //     //       },
      //     //       lineage: ['cgnv6.lw-4o6.active-binding-table.oper'],
      //     //     },
      //     //   ],
      //     // },
      //   ],
      // },
      // {
      //   title: 'SixRD',
      //   menus: [
      // {
      //   title: 'Domain',
      //   url_name: 'six_rd_index',
      //   lineage: ['cgnv6.sixrd.domain'],
      //   uiJsonPath: 'cgn/six-rd/domains',
      // },
      // {
      //   title: 'Fragmentation',
      //   url_name: 'six_rd_index',
      //   lineage: [
      //     'cgnv6.sixrd.fragmentation',
      //     // 'cgnv6.sixrd.fragmentation.inbound',
      //     // 'cgnv6.sixrd.fragmentation.outbound',
      //   ],
      //   uiJsonPath: 'cgn/six-rd/fragmentation',
      //   onlyEditMode: true,
      // },
      // {
      //   title: 'Stats',
      //   url_name: 'sixrd_stats_index',
      //   url_params: {
      //     template_type: 'Domain',
      //   },
      //   lineage: ['cgnv6.sixrd.domain'],
      // },
      //   ],
      // },
      // {
      //   title: 'Static NAT',
      //   menus: [
      //     {
      //       title: 'Source Static',
      //       lineage: ['cgnv6.nat.inside.source.static'],
      //       uiJsonPath: 'cgn/static-nat/static',
      //     },
      //     {
      //       title: 'Range List',
      //       lineage: ['cgnv6.nat.range-list'],
      //       uiJsonPath: 'cgn/static-nat/range-list',
      //       viewMode: true,
      //     },
      //   ],
      // },
      // {
      //   title: 'Stateless NAT46',
      //   menus: [
      //     {
      //       title: 'Static Mappings',
      //       url_name: 'static_dest_mapping_index',
      //       lineage: ['cgnv6.nat46-stateless.static-dest-mapping'],
      //       uiJsonPath: 'cgn/stateless-nat46/static-mappings',
      //       viewMode: true,
      //     },
      //     {
      //       title: 'Global',
      //       url_name: 'stateless_global_index',
      //       lineage: [
      //         'cgnv6.nat46-stateless.prefix',
      //         'cgnv6.nat46-stateless.fragmentation.outbound',
      //         'cgnv6.nat46-stateless.fragmentation.inbound',
      //       ],
      //       onlyEditMode: true,
      //       uiJsonPath: 'cgn/stateless-nat46/global',
      //     },
      //     // {
      //     //   title: 'Stats',
      //     //   url_name: 'stateless_nat46_stats',
      //     //   url_params: {
      //     //     stats_type: 'Stateless Global',
      //     //   },
      //     //   lineage: ['cgnv6.nat46-stateless.global.stats'],
      //     // },
      //   ],
      // },
      {
        title: 'Settings',
        menus: [
          //     {
          //       title: 'DDoS Protection',
          //       should_disable: {
          //         partition: ['l3v'],
          //       },
          //       lineage: ['cgnv6.ddos-protection'],
          //       onlyEditMode: true,
          //       uiJsonPath: 'cgn/ddos-protection',
          //     },
          //     {
          //       title: 'Service Groups',
          //       modalSize: 'extralarge',
          //       lineage: ['cgnv6.service-group'],
          //       uiJsonPath: 'cgn/services/service-groups',
          //     },
          {
            title: 'Servers',
            modalSize: 'extralarge',
            lineage: ['cgnv6.server'],
            uiJsonPath: 'cgn/services/servers',
          },
        ],
      },
      // {
      //   title: 'Fragmentation',
      //   menus: [
      //     {
      //       title: 'IP Frag',
      //       url_name: 'cgn_ip_frag_stats',
      //       url_params: {
      //         stats_type: 'IP Frag',
      //       },
      //       lineage: ['ip.frag.stats'],
      //     },
      //     {
      //       title: 'IPv6 Frag',
      //       url_name: 'cgn_ipv6_frag_stats',
      //       url_params: {
      //         stats_type: 'IPv6 Frag',
      //       },
      //       lineage: ['ipv6.frag.stats'],
      //     },
      //     {
      //       title: 'IPv4 in Ipv6 Frag',
      //       url_name: 'cgn_ip_ipv6_frag_stats',
      //       url_params: {
      //         stats_type: 'IPIPv6 Frag',
      //       },
      //       lineage: ['ipv4-in-ipv6.frag.stats'],
      //     },
      //     {
      //       title: 'IPv6 in IPV4 Frag',
      //       url_name: 'cgn_ipv6_ip_frag_stats',
      //       url_params: {
      //         stats_type: 'IPv6IP Frag',
      //       },
      //       lineage: ['ipv6-in-ipv4.frag.stats'],
      //     },
      //   ],
      // },
      // {
      //   title: 'Session',
      //   menus: [
      //     {
      //       title: 'Session Table',
      //       url_name: 'data_session_index',
      //       lineage: ['sessions.oper'],
      //     },
      //     {
      //       title: 'Global Settings',
      //       lineage: ['cgnv6.nat.icmp', 'cgnv6.nat.icmpv6'],
      //       onlyEditMode: true,
      //       uiJsonPath: 'cgn/session/global',
      //     },
      //   ],
      // },
    ],
  },
  {
    title: 'Network',
    icon: 'fa-cloud',
    menu_name: ['network'],
    should_disable: {
      partition: ['sp'],
    },
    specificPartition: ['cp', 'dp'],
    menus: [
      {
        title: 'Network Interfaces',
        specificPartition: ['dp'],
        menus: [
          {
            title: 'LAN',
            // noAddMode: true,
            uiJsonPath: 'network/interfaces/lan',
            lineage: ['interface.ethernet'],
            specificPartition: ['dp'],
          },
          // {
          //   title: 'Transparent',
          //   onlyEditMode: true,
          //   uiJsonPath: 'network/interfaces/transparent',
          //   lineage: [
          //     'ip.address',
          //     'ip.default-gateway',
          //     'ipv6.address',
          //     'ipv6.default-gateway',
          //   ],
          //   should_disable: {
          //     partition: ['l3v'],
          //   },
          //   specificPartition: true,
          // },
          {
            title: 'Virtual Ethernets',
            uiJsonPath: 'network/interfaces/virtual-ethernet',
            lineage: ['interface.ve'],
            specificPartition: ['dp'],
          },
          {
            title: 'Trunks',
            uiJsonPath: 'network/interfaces/trunk',
            lineage: ['interface.trunk'],
            specificPartition: ['dp'],
          },
          // {
          //   title: 'Trunk Group',
          //   uiJsonPath: 'network/interfaces/trunk-group',
          //   lineage: ['interface.trunk-group-new'],
          //   specificPartition: true,
          // },
          {
            title: 'Tunnels',
            uiJsonPath: 'network/interfaces/tunnel',
            lineage: ['interface.tunnel'],
            specificPartition: ['dp'],
          },
          // {
          //   title: 'Interface Global',
          //   uiJsonPath: 'network/interfaces/interface-global',
          //   onlyEditMode: true,
          //   lineage: [
          //     'ip.tcp',
          //     'system-4x10g-mode',
          //     'system',
          //     'system-jumbo-global',
          //     'network.ve-stats',
          //   ],
          //   should_disable: {
          //     partition: ['l3v'],
          //   },
          //   specificPartition: true,
          // },
          {
            title: 'LIFs',
            uiJsonPath: 'network/interfaces/lif',
            lineage: ['interface.lif'],
            specificPartition: ['dp'],
          },
          {
            title: 'LLDP',
            uiJsonPath: 'network/interfaces/lldp',
            lineage: ['network.lldp'],
            onlyEditMode: true,
            should_disable: {
              partition: ['l3v'],
            },
            specificPartition: ['dp'],
          },
        ],
      },
      // {
      //   title: 'Trunk',
      //   specificPartition: true,
      //   should_disable: {
      //     partition: ['l3v'],
      //   },
      //   menus: [
      //     {
      //       title: 'Trunks',
      //       url_name: 'trunk_index',
      //       specificPartition: true,
      //     },
      //   ],
      // },
      {
        title: 'LACP',
        specificPartition: 'cp',
        should_disable: {
          partition: ['l3v'],
        },
        uiJsonPath: 'network/lacp/lacp',
        lineage: ['network.lacp'],
        onlyEditMode: true,
      },
      {
        title: 'VLAN',
        specificPartition: ['dp'],
        menus: [
          {
            title: 'VLAN',
            uiJsonPath: 'network/vlan/vlan',
            lineage: ['network.vlan'],
            specificPartition: ['dp'],
          },
          // {
          //   title: 'MAC',
          //  specificPartition: ['dp'],
          //   menus: [
          //     {
          //       title: 'Configured',
          //       uiJsonPath: 'network/vlan/mac',
          //       lineage: ['network.mac-address.static'],
          //       specificPartition: ['dp'],
          //     },
          //     // {
          //     //   title: 'Discovered',
          //     //   url_name: 'mac_dynamic_index',
          //     //   related_urls: ['mac_dynamic_update'],
          //     //   lineage: ['network.mac-address.dynamic.oper'],
          //     //   specificPartition: ['dp'],
          //     // },
          //   ],
          // },
          {
            title: 'Global',
            onlyEditMode: true,
            uiJsonPath: 'network/vlan/global',
            lineage: ['network.mac-age-time', 'network.vlan-global'],
            specificPartition: ['dp'],
          },
        ],
      },
      {
        title: 'ARP',
        specificPartition: ['dp'],
        menus: [
          {
            title: 'IPv4',
            specificPartition: ['dp'],
            menus: [
              {
                title: 'IPv4 Static',
                uiJsonPath: 'network/arp/ipv4',
                lineage: ['network.arp.static'],
                should_disable: {
                  lineage: ['interface.ethernet', 'interface.trunk'],
                },
                specificPartition: ['dp'],
              },
              // {
              //   title: 'IPv4 Dynamic',
              //   url_name: 'arp_ipv4_dynamic_index',
              //   related_urls: ['arp_ipv4_dynamic_update'],
              //   lineage: ['network.arp.dynamic.oper'],
              //   should_disable: {
              //     vcs: ['blade'],
              //   },
              //   specificPartition: ['dp'],
              // },
            ],
          },
          // {
          //   title: 'IPv6',
          //   specificPartition: true,
          //   menus: [
          //     {
          //       title: 'IPv6 Static',
          //       uiJsonPath: 'network/arp/ipv6',
          //       lineage: ['ipv6.neighbor.static'],
          //       should_disable: {
          //         lineage: [['interface.ethernet', 'interface.trunk']],
          //       },
          //       specificPartition: true,
          //     },
          //     // {
          //     //   title: 'IPv6 Dynamic',
          //     //   url_name: 'neighbor_dynamic_index',
          //     //   lineage: ['ipv6.neighbor.dynamic.oper'],
          //     //   should_disable: {
          //     //     vcs: ['blade'],
          //     //   },
          //     //   specificPartition: true,
          //     // },
          //   ],
          // },
          // {
          //   title: 'Global ARP Timeout',
          //   uiJsonPath: 'network/arp/global',
          //   lineage: ['ip'],
          //   onlyEditMode: true,
          //   should_disable: {
          //     partition: ['l3v'],
          //   },
          //   specificPartition: true,
          // },
        ],
      },
      {
        title: 'Routes',
        specificPartition: ['dp'],
        menus: [
          {
            title: 'IPv4 Static Routes',
            specificPartition: ['dp'],
            menus: [
              {
                title: 'IPv4 Rib',
                uiJsonPath: 'network/routes/ipv4-static-routes/ipv4-rib',
                lineage: ['ip.route.rib'],
                specificPartition: ['dp'],
                modalSize: 'extralarge',
              },
              {
                title: 'IPv4 Bfd',
                uiJsonPath: 'network/routes/ipv4-static-routes/ipv4-bfd',
                lineage: ['ip.route.static.bfd'],
                viewMode: true,
                specificPartition: ['dp'],
              },
            ],
          },
          {
            title: 'IPv6 Static Routes',
            specificPartition: ['dp'],
            menus: [
              {
                title: 'IPv6 Rib',
                uiJsonPath: 'network/routes/ipv6-static-routes/ipv6-rib',
                lineage: ['ipv6.route.rib'],
                specificPartition: ['dp'],
                modalSize: 'extralarge',
              },
              {
                title: 'IPv6 Bfd',
                specificPartition: ['dp'],
                menus: [
                  {
                    title: 'IPv6 Bfd',
                    uiJsonPath:
                      'network/routes/ipv6-static-routes/ipv6-bfd/bfd',
                    lineage: ['ipv6.route.static.bfd.bfd-ipv6'],
                    specificPartition: ['dp'],
                    viewMode: true,
                  },
                  {
                    title: 'Ethernet',
                    uiJsonPath:
                      'network/routes/ipv6-static-routes/ipv6-bfd/ethernet',
                    lineage: ['ipv6.route.static.bfd.ethernet'],
                    specificPartition: ['dp'],
                    viewMode: true,
                  },
                  {
                    title: 'Trunk',
                    uiJsonPath:
                      'network/routes/ipv6-static-routes/ipv6-bfd/trunk',
                    lineage: ['ipv6.route.static.bfd.trunk'],
                    specificPartition: ['dp'],
                    viewMode: true,
                  },
                  {
                    title: 'Virtual Ethernet',
                    uiJsonPath: 'network/routes/ipv6-static-routes/ipv6-bfd/ve',
                    lineage: ['ipv6.route.static.bfd.ve'],
                    specificPartition: ['dp'],
                    viewMode: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      // {
      //   title: 'BPDU Fwd Groups',
      //   specificPartition: true,
      //   uiJsonPath: 'network/bpdu-fwd-groups',
      //   lineage: ['network.bpdu-fwd-group'],
      // },
      {
        title: 'MDLB',
        specificPartition: ['dp'],
        menus: [
          {
            title: 'Scaleout',
            uiJsonPath: 'network/mldb/scaleout',
            lineage: ['scaleout.cluster'],
            specificPartition: ['dp'],
          },
        ],
      },
      {
        title: 'Global',
        specificPartition: ['cp'],
        should_disable: {
          partition: ['l3v'],
        },
        lineage: ['network.icmp-rate-limit', 'network.icmpv6-rate-limit'],
        uiJsonPath: 'network/global',
        onlyEditMode: true,
      },
    ],
  },
  {
    title: 'System',
    icon: 'fa-gear',
    menu_name: ['system'],
    specificPartition: ['all'],
    menus: [
      // {
      //   title: 'Global',
      //   onlyEditMode: true,
      //   uiJsonPath: 'system/global',
      //   lineage: ['interface.management'],
      //   should_disable: {
      //     partition: ['l3v'],
      //   },
      //   specificPartition: ['dp'],
      //   viewMode: true,
      // },
      {
        title: 'Settings',
        specificPartition: ['lp', 'cp'],
        menus: [
          // {
          //   title: 'Access Control',
          //   url_name: 'system_settings_access_control',
          //   // lineage: ['enable-management', 'disable-management'],
          // },
          {
            title: 'Web',
            should_disable: {
              partition: ['l3v'],
            },
            onlyEditMode: true,
            uiJsonPath: 'system/settings/web',
            lineage: ['web-service', 'multi-config'],
            specificPartition: ['cp'],
          },
          // {
          //   title: 'Certificate',
          //   should_disable: {
          //     partition: ['l3v'],
          //   },
          //   lineage: ['file.web-service-cert-key'],
          // },
          // {
          //   title: 'Time',
          //   should_disable: {
          //     partition: ['l3v'],
          //   },
          //   lineage: [
          //     'clock.show.oper',
          //     'ntp.server.hostname',
          //     'ntp.auth-key',
          //     'timezone',
          //     'ntp-status.oper',
          //   ],
          // },
          {
            title: 'Terminal',
            onlyEditMode: true,
            uiJsonPath: 'system/settings/terminal',
            lineage: ['terminal'],
            specificPartition: ['cp'],
          },
          {
            title: 'Logging',
            onlyEditMode: true,
            uiJsonPath: 'system/settings/logging',
            lineage: ['logging'],
            specificPartition: ['cp'],
          },
          {
            title: 'SMTP',
            onlyEditMode: true,
            lineage: ['smtp'],
            uiJsonPath: 'system/settings/smtp',
            specificPartition: ['cp'],
          },
          {
            title: 'General',
            should_disable: {
              partition: ['l3v'],
            },
            onlyEditMode: true,
            uiJsonPath: 'system/settings/general',
            lineage: ['environment'],
            specificPartition: ['cp'],
          },
          // {
          //   title: 'Boot Image',
          //   should_disable: {
          //     partition: ['l3v'],
          //     lineage: ['bootimage.oper'],
          //   },
          //   lineage: ['bootimage'],
          // },
          // {
          //   title: 'Actions',
          //   should_disable: {
          //     partition: ['l3v'],
          //   },
          //   specificPartition: false,
          //   onlyEditMode: true,
          //   lineage: ['sys-misc'],
          //   uiJsonPath: 'system/settings/actions',
          // },
          // {
          //   title: 'Resource Usage',
          //   should_disable: {
          //     partition: ['l3v'],
          //   },
          //   lineage: [
          //     'slb.resource-usage',
          //     'system.resource-usage',
          //     'cgnv6.resource-usage',
          //   ],
          // },
          {
            title: 'DNS',
            lineage: ['ip.dns.primary'],
            onlyEditMode: true,
            uiJsonPath: 'system/settings/dns',
            specificPartition: ['cp'],
          },
          // {
          //   title: 'Sync Settings',
          //   should_disable: {
          //     partition: ['l3v'],
          //     vcs: ['blade'],
          //   },
          //   onlyEditMode: true,
          //   lineage: ['configure.sync'],
          // },
          // {
          //   title: 'Resource Accounting',
          //   url_name: 'system_settings_resource_accounting',
          //   lineage: [
          //     'system.resource-accounting.oper',
          //     'system.resource-accounting',
          //   ],
          // },
          // {
          //   title: 'Templates',
          //   uiJsonPath: 'system/settings/templates',
          //   lineage: ['system.resource-accounting.template'],
          //   specificPartition: false,
          //   should_disable: {
          //     partition: ['l3v'],
          //   },
          // },
          // {
          //   title: 'Configuration File',
          //   url: '/r/system/settings/configuration_file/list',
          //   // lineage: ['file.startup-config'],
          // },
          {
            title: 'Password Policy',
            url: '/r/system/settings/password_policy',
            lineage: ['system.password-policy'],
            onlyEditMode: true,
            should_disable: {
              partition: ['l3v', 'sp'],
            },
            specificPartition: ['cp'],
            uiJsonPath: 'system/settings/policy',
          },
          // {
          //   title: 'Geo Locations',
          //   specificPartition: false,
          //   should_disable: {
          //     partition: ['l3v'],
          //   },
          //   menus: [
          //     {
          //       title: 'Geo Database',
          //       lineage: [
          //         'system.geo-location' /*'file.geo-location', 'system.geo-location.entry'*/,
          //       ],
          //       onlyEditMode: true,
          //       uiJsonPath: 'system/settings/geo-location/geo-database',
          //       should_disable: {
          //         partition: ['l3v'],
          //       },
          //     },
          //     {
          //       title: 'Format Specifier',
          //       url: '/r/system/settings/geolocations/csv/list',
          //       uiJsonPath: 'system/settings/geo-location/format-specifier',
          //       lineage: ['template.csv'],
          //       should_disable: {
          //         partition: ['l3v'],
          //       },
          //     },
          //   ],
          // },
          // {
          //   title: 'File Management',
          //   should_disable: {
          //     partition: ['sp'],
          //   },
          //   fileType: 'file-inspection-bw-list',
          //   lineage: ['file.file-inspection-bw-list'],
          //   modalSize: 'extralarge',
          // },
        ],
      },
      {
        title: 'Admin',
        // specificPartition: ['cp' , 'lp'],
        specificPartition: ['cp'],
        // under CP, should be disable in l3v partition
        should_disable: {
          partition: ['l3v'],
        },
        menus: [
          // {
          //   title: 'Users',
          //   uiJsonPath: 'system/admin/users',
          //   lineage: ['admin'],
          //   should_disable: {
          //     lineage: ['admin-detail.oper'],
          //   },
          //   should_readonly: {
          //     partition: ['l3v'],
          //   },
          // },
          // {
          //   title: 'Partitions',
          //   uiJsonPath: 'system/admin/partitions',
          //   lineage: ['partition'],
          //   should_disable: {
          //     partition: ['l3v', 'sp'],
          //     lineage: ['partition-all.oper'],
          //     virtual_type: ['azure'],
          //   },
          // },
          // {
          //   title: 'Partitions Group',
          //   lineage: ['partition-group'],
          //   should_disable: {
          //     partition: ['l3v'],
          //     virtual_type: ['azure'],
          //   },
          // },
          {
            title: 'Lockout',
            specificPartition: ['cp'],
            onlyEditMode: true,
            lineage: ['admin-lockout'],
            should_disable: {
              partition: ['l3v'],
            },
          },
          // {
          //   title: 'External Authentication',
          //   menus: [
          //     {
          //       title: 'Settings',
          //       onlyEditMode: true,
          //       uiJsonPath: 'system/admin/external-authentication/settings',
          //       lineage: ['authentication'],
          //       should_disable: {
          //         partition: ['l3v'],
          //       },
          //     },
          //     {
          //       title: 'RADIUS',
          //       should_disable: {
          //         partition: ['l3v'],
          //       },
          //       menus: [
          //         {
          //           title: 'Name',
          //           lineage: ['radius-server.host.name'],
          //           uiJsonPath:
          //             'system/admin/external-authentication/radius/name',
          //         },
          //         {
          //           title: 'IPv4',
          //           lineage: ['radius-server.host.ipv4'],
          //           uiJsonPath:
          //             'system/admin/external-authentication/radius/ipv4',
          //         },
          //         {
          //           title: 'IPv6',
          //           lineage: ['radius-server.host.ipv6'],
          //           uiJsonPath:
          //             'system/admin/external-authentication/radius/ipv6',
          //         },
          //       ],
          //     },
          //     {
          //       title: 'LDAP',
          //       menus: [
          //         {
          //           title: 'Hostname',
          //           lineage: ['ldap-server.host.ldap-hostname'],
          //           uiJsonPath:
          //             'system/admin/external-authentication/ldap/hostname',
          //         },
          //         {
          //           title: 'IPv4',
          //           lineage: ['ldap-server.host.ipv4'],
          //           uiJsonPath:
          //             'system/admin/external-authentication/ldap/ipv4',
          //         },
          //         {
          //           title: 'IPv6',
          //           lineage: ['ldap-server.host.ipv6'],
          //           uiJsonPath:
          //             'system/admin/external-authentication/ldap/ipv6',
          //         },
          //       ],
          //     },
          //     {
          //       title: 'TACACS Host',
          //       // customizedPage: 'tacacs',
          //       // uiJsonPath: 'system/admin/external-authentication/tacacs-host',
          //       // lineage: [
          //       //   'tacacs-server.host',
          //       //   'tacacs-server.host.tacacs-hostname',
          //       //   'tacacs-server.host.ipv4',
          //       //   'tacacs-server.host.ipv6',
          //       // ],
          //       should_disable: {
          //         partition: ['l3v'],
          //       },
          //       menus: [
          //         {
          //           title: 'Hostname',
          //           uiJsonPath:
          //             'system/admin/external-authentication/tacacs-host/tacacs-hostname',
          //           lineage: ['tacacs-server.host.tacacs-hostname'],
          //         },
          //         {
          //           title: 'IPv4',
          //           uiJsonPath:
          //             'system/admin/external-authentication/tacacs-host/ipv4',
          //           lineage: ['tacacs-server.host.ipv4'],
          //         },
          //         {
          //           title: 'IPv6',
          //           uiJsonPath:
          //             'system/admin/external-authentication/tacacs-host/ipv6',
          //           lineage: ['tacacs-server.host.ipv6'],
          //         },
          //       ],
          //     },
          //     {
          //       title: 'TACACS Monitor',
          //       onlyEditMode: true,
          //       uiJsonPath:
          //         'system/admin/external-authentication/tacacs-monitor',
          //       lineage: ['tacacs-server'],
          //       should_disable: {
          //         partition: ['l3v'],
          //       },
          //     },
          //   ],
          // },
          // {
          //   title: 'Licensing',
          //   url_name: 'system_admin_licensing_index',
          //   lineage: ['scm.license-src-info.oper', 'glm'],
          // },
          // {
          //   title: 'Controller',
          //   url_name: 'system_admin_controller_index',
          //   lineage: ['harmony-controller.profile'],
          //   should_disable: {
          //     partition: ['l3v'],
          //   },
          // },
        ],
      },
      // {
      //   title: 'Maintenance',
      //   should_disable: {
      //     partition: ['l3v'],
      //   },
      //   specificPartition: true,
      //   menus: [
      //     {
      //       title: 'Upgrade',
      //       url_name: 'system_maintenance_upgrade_index',
      //     },
      //     {
      //       title: 'Backup',
      //       specificPartition: true,
      //       menus: [
      //         {
      //           title: 'System',
      //           specificPartition: true,
      //           onlyEditMode: true,
      //           uiJsonPath: 'system/maintenance/backup/system',
      //           lineage: ['backup.system'],
      //         },
      //         {
      //           title: 'Log',
      //           specificPartition: true,
      //           onlyEditMode: true,
      //           uiJsonPath: 'system/maintenance/backup/log',
      //           lineage: ['backup.log'],
      //         },
      //         {
      //           title: 'Periodic Backup',
      //           specificPartition: true,
      //           onlyEditMode: true,
      //           uiJsonPath: 'system/maintenance/backup/backup-periodic',
      //           lineage: ['backup-periodic'],
      //         },
      //       ],
      //     },
      //     {
      //       title: 'Restore',
      //       specificPartition: true,
      //       uiJsonPath: 'system/maintenance/backup/restore',
      //       onlyEditMode: true,
      //       lineage: ['restore'],
      //     },
      //   ],
      // },
      {
        title: 'Monitoring',
        // specificPartition: ['cp', 'lp'],
        specificPartition: ['cp'],
        menus: [
          {
            title: 'sFlow',
            should_disable: {
              partition: ['sp'],
            },
            specificPartition: ['cp'],
            menus: [
              {
                title: 'sFlow',
                uiJsonPath: 'system/monitoring/sflow',
                onlyEditMode: true,
                lineage: ['sflow.setting'],
                specificPartition: ['cp'],
              },
              // {
              //   title: 'sFlow Stats',
              //   url_name: 'sflow_stats_index',
              //   url_params: {
              //     stats_type: 'sFlow Global',
              //   },
              //   lineage: ['sflow.global.stats'],
              // },
            ],
          },
          {
            title: 'SNMP',
            // specificPartition: ['cp', 'lp'],
            specificPartition: ['cp'],
            menus: [
              // {
              //   title: 'SNMP Server',
              //   uiJsonPath: 'system/monitoring/snmp/snmp',
              //   lineage: ['snmp-server'],
              //   onlyEditMode: true,
              //   modalSize: 'extralarge',
              //   specificPartition: ['cp'],
              // },
              // {
              //   title: 'SNMP Views',
              //   lineage: ['snmp-server.view'],
              //   should_disable: {
              //     partition: ['l3v'],
              //   },
              //   uiJsonPath: 'system/monitoring/snmp/snmp-views',
              // },
              // {
              //   title: 'SNMP Groups',
              //   lineage: ['snmp-server.group'],
              //   should_disable: {
              //     partition: ['l3v'],
              //     lineage: ['snmp-server.view'],
              //   },
              //   uiJsonPath: 'system/monitoring/snmp/snmp-group',
              // },
              {
                title: 'SNMPv3 Users',
                lineage: ['snmp-server.SNMPv3.user'],
                should_disable: {
                  partition: ['l3v'],
                  lineage: ['snmp-server.group', 'snmp-server.view'],
                },
                uiJsonPath: 'system/monitoring/snmp/snmpv3-user',
                specificPartition: ['cp'],
              },
              {
                title: 'SNMPv1-v2c Users',
                url_name: 'snmp_community_index',
                uiJsonPath: 'system/monitoring/snmp/snmpv1-v2c-user',
                lineage: ['snmp-server.SNMPv1-v2c.user'],
                specificPartition: ['cp'],
              },
              // {
              //   title: 'SNMP MIB Download',
              //   url_name: 'snmp_mib_download',
              //   lineage: [],
              //   should_disable: {
              //     partition: ['l3v'],
              //   },
              // },
            ],
          },
          // {
          //   title: 'Netflow',
          //   specificPartition: true,
          //   should_disable: {
          //     partition: ['sp'],
          //   },
          //   menus: [
          //     {
          //       title: 'Monitors',
          //       specificPartition: true,
          //       menus: [
          //         {
          //           title: 'Monitors List',
          //           uiJsonPath: 'system/monitoring/netflow/monitor',
          //           lineage: ['netflow.monitor'],
          //           specificPartition: true,
          //         },
          //         {
          //           title: 'Monitors Common',
          //           onlyEditMode: true,
          //           uiJsonPath: 'system/monitoring/common',
          //           lineage: ['netflow.common'],
          //           specificPartition: true,
          //         },
          //       ],
          //     },
          //     {
          //       title: 'Monitors Custom Templates',
          //       lineage: ['netflow.template'],
          //       uiJsonPath: 'system/monitoring/netflow/template',
          //       specificPartition: true,
          //     },
          //   ],
          // },
        ],
      },
      // {
      //   title: 'aVCS',
      //   should_disable: {
      //     platform: ['chassis'],
      //     partition: ['l3v'],
      //   },
      //   specificPartition: true,
      //   menus: [
      //     {
      //       title: 'Settings',
      //       uiJsonPath: 'system/avcs/settings',
      //       onlyEditMode: true,
      //       lineage: ['vcs'],
      //       should_disable: {
      //         lineage: [['vcs.action', 'vcs.debug', 'vcs.vcs-para']],
      //       },
      //       specificPartition: true,
      //     },
      //     {
      //       title: 'Device',
      //       uiJsonPath: 'system/avcs/device',
      //       lineage: ['vcs.device'],
      //       specificPartition: true,
      //     },
      //     // {
      //     //   title: 'Statistics',
      //     //   menus: [
      //     //     {
      //     //       title: 'aVCS Images',
      //     //       url_name: 'system_vcs_global_stats',
      //     //       lineage: ['vcs.images.oper'],
      //     //     },
      //     //     {
      //     //       title: 'aVCS Detail Stats',
      //     //       url_name: 'system_vcs_detail_stats',
      //     //       lineage: ['vcs.stat.stats'],
      //     //     },
      //     //     {
      //     //       title: 'Device Statistics',
      //     //       url_name: 'system_vcs_device_stats_index',
      //     //       lineage: ['vcs.vcs-summary.oper'],
      //     //     },
      //     //   ],
      //     // },
      //     // {
      //     //   title: 'aVCS Deployment Summary',
      //     //   url_name: 'system_vcs_deployment_summary',
      //     //   lineage: ['vcs.vcs-summary.oper'],
      //     // },
      //     // {
      //     //   title: 'Actions',
      //     //   uiJsonPath: 'system/avcs/actions',
      //     //   onlyEditMode: true,
      //     //   lineage: ['vcs'],
      //     //   specificPartition: true,
      //     // },
      //   ],
      // },
      {
        title: 'VRRP-A',
        specificPartition: ['dp'],
        should_disable: {
          lineage: ['vrrp-a'],
          virtual_type: ['azure'],
          partition: ['sp'],
        },
        menus: [
          {
            title: 'Settings',
            specificPartition: ['dp'],
            menus: [
              {
                title: 'Global',
                uiJsonPath: 'system/vrrpa/settings/global',
                onlyEditMode: true,
                lineage: ['vrrp-a.common'],
                specificPartition: ['dp'],
              },
              {
                title: 'Peer Group',
                uiJsonPath: 'system/vrrpa/settings/peer-group',
                onlyEditMode: true,
                lineage: ['vrrp-a.peer-group'],
                specificPartition: ['dp'],
              },
              {
                title: 'Session Sync Port',
                uiJsonPath: 'system/vrrpa/settings/session-sync-port',
                onlyEditMode: true,
                lineage: ['vrrp-a.preferred-session-sync-port'],
                specificPartition: ['dp'],
              },
              {
                title: 'Vrid',
                uiJsonPath: 'system/vrrpa/settings/vrid',
                disableDelList: ['0'],
                lineage: ['vrrp-a.vrid'],
                specificPartition: ['dp'],
                modalSize: 'extralarge',
              },
            ],
          },
          {
            title: 'VRRP-A Interface',
            specificPartition: ['dp'],
            should_disable: {
              partition: ['l3v'],
            },
            menus: [
              {
                title: 'Ethernet',
                uiJsonPath: 'system/vrrpa/interface/interface',
                lineage: ['vrrp-a.interface.ethernet'],
                specificPartition: ['dp'],
              },
              {
                title: 'Trunk',
                specificPartition: ['dp'],
                uiJsonPath: 'system/vrrpa/interface/trunk',
                lineage: ['vrrp-a.interface.trunk'],
                should_disable: {
                  lineage: ['network.available-trunk-list'],
                },
                // noAddMode: true,
              },
            ],
          },
          {
            title: 'Failover Policy Template',
            lineage: ['vrrp-a.fail-over-policy-template'],
            specificPartition: ['dp'],
            uiJsonPath: 'system/vrrpa/failover-policy-template',
          },
          // {
          //   title: 'Statistics',
          //   menus: [
          //     {
          //       title: 'Global Statistics',
          //       url_name: 'system_vrrpa_global_stats',
          //       // lineage: ['vrrp-a.state'],
          //     },
          //     {
          //       title: 'Vrid Statistics',
          //       url_name: 'system_vrrpa_vrid_stats_index',
          //       // lineage: ['vrrp-a.vrid'],
          //     },
          //   ],
          // },
        ],
      },
      // {
      //   title: 'System Log',
      //   menus: [
      //     {
      //       title: 'System',
      //       url_name: 'system_syslog_system_index',
      //       lineage: ['syslog.oper'],
      //     },
      //     {
      //       title: 'Audit',
      //       url_name: 'system_syslog_audit_index',
      //       lineage: ['sys-audit-log.oper'],
      //     },
      //   ],
      // },
      // {
      //   title: 'Getting Started',
      //   menus: [
      //     {
      //       title: 'System',
      //       url_name: 'slb_start_wizard_detail',
      //       lineage: [
      //         'snmp-server',
      //         'interface.management',
      //         'clock.show.oper',
      //         'ntp.server.hostname',
      //       ],
      //     },
      //     {
      //       title: 'Network',
      //       url_name: 'slb_start_network_detail',
      //       lineage: ['interface.ethernet'],
      //     },
      //     {
      //       title: 'Application',
      //       url_name: 'slb_start_application_detail',
      //     },
      //   ],
      // },
      // {
      //   title: 'Update',
      //   menus: [
      //     {
      //       title: 'File Inspection',
      //       url_name: 'system_update_file_inspection_index',
      //       lineage: ['file-inspection.data-file'],
      //       should_enable: {
      //         license: ['CYLANCE'],
      //       },
      //       should_disable: {
      //         partition: ['l3v', 'sp'],
      //       },
      //     },
      //   ],
      // },
      // {
      //   title: 'APP Template',
      //   url: '/templates',
      //   lineage: [],
      // },
      // {
      //   title: 'APP Template Import',
      //   url_name: 'templates_import_index',
      //   lineage: ['file.template'],
      // },
    ],
  },
  {
    title: 'Shared Objects',
    menus: [
      {
        title: 'Class Lists',
        menus: [
          // {
          //   title: 'Configuration',
          //   addLabel: 'Class List',
          //   lineage: ['class-list'],
          //   uiJsonPath: 'shared-objects/class-list/config',
          //   modalSize: 'extralarge',
          // },
          {
            title: 'Import',
            addLabel: 'Class List',
            fileType: 'class-list',
            lineage: ['file.class-list'],
            modalSize: 'extralarge',
          },
        ],
      },
      // {
      //   title: 'Access List',
      //   menus: [
      //     {
      //       title: 'Standard',
      //       lineage: ['access-list.standard'],
      //       uiJsonPath: 'shared-objects/access-list/standard',
      //       modalSize: 'extralarge',
      //     },
      //     {
      //       title: 'Extended',
      //       lineage: ['access-list.extended'],
      //       uiJsonPath: 'shared-objects/access-list/extended',
      //       modalSize: 'extralarge',
      //     },
      //     {
      //       title: 'IPv4',
      //       lineage: ['ip.access-list'],
      //       uiJsonPath: 'shared-objects/access-list/ip',
      //       modalSize: 'extralarge',
      //     },
      //     {
      //       title: 'IPv6',
      //       lineage: ['ipv6.access-list'],
      //       uiJsonPath: 'shared-objects/access-list/ipv6',
      //       modalSize: 'extralarge',
      //     },
      //   ],
      // },
      // {
      //   title: 'Object',
      //   module_name: ['dcfw'],
      //   menus: [
      //     {
      //       title: 'Object Network',
      //       lineage: ['object.network'],
      //       uiJsonPath: 'shared-objects/object',
      //     },
      //   ],
      // },
      // {
      //   title: 'Object Group',
      //   menus: [
      //     {
      //       title: 'Object Group Application',
      //       lineage: ['object-group.application'],
      //       uiJsonPath: 'shared-objects/object-group/application',
      //     },
      //     {
      //       title: 'Object Group Network',
      //       lineage: ['object-group.network'],
      //       uiJsonPath: 'shared-objects/object-group/network',
      //       modalSize: 'extralarge',
      //     },
      //     {
      //       title: 'Object Group Service',
      //       lineage: ['object-group.service'],
      //       uiJsonPath: 'shared-objects/object-group/service',
      //       modalSize: 'extralarge',
      //     },
      //   ],
      // },
      // {
      //   title: 'Domain List',
      //   lineage: ['domain-list'],
      //   uiJsonPath: 'shared-objects/domain-list',
      // },
    ],
  },
]
