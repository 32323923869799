import React from 'react'
import ReactLoading from 'react-loading'
// import propTypes from 'prop-types'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10Modal,
  A10DropdownMenu,
  A10Message,
  A10Button,
} from '@gui-libraries/widgets'

import { Utilities } from 'src/services/index'
import { httpClient } from 'src/libraries/httpClient'
import SlidingRestore from '../RestoreForm/slidingForm'
import SlidingBackup from '../BackupForm/slidingForm'
import storage from 'src/libraries/storage'

// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')
const moment = require('moment')
export interface IBackupInfoProps extends IA10ContainerDefaultProps {
  deviceName: string
  data: any
  updateList: any
  isLoading?: boolean
  getRemainingBackups: () => void
}

export interface IBackupInfoState {
  showSlidingPage: boolean
  showRestoreSlidingPage: boolean
  deleteModalState: boolean
  backup: IObject
  uuid: string
}

class BackupInfoList extends A10Container<IBackupInfoProps, IBackupInfoState> {
  defaultBackupInfoColumns: any[]
  Utilities = new Utilities()

  constructor(props: IBackupInfoProps) {
    super(props)
    this.state = {
      showSlidingPage: false,
      showRestoreSlidingPage: false,
      deleteModalState: false,
      backup: null,
      uuid: '',
    }
    this.defaultBackupInfoColumns = [
      {
        title: 'Backup Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
      },
      {
        title: 'Timezone',
        dataIndex: 'timezone',
        key: 'timezone',
        render: (text: string, record: IObject, index: number) => {
          const timezone = record?.timezone?.label || 'UTC+00:00'
          return <span>{timezone}</span>
        },
        sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'timezone'),
      },
      {
        title: 'Backup Date/Time',
        dataIndex: 'backup-time',
        key: 'backup-time', // TODO: change this key
        render: (text: string, record: any) => {
          return <span>{record?.['backup-time']}</span>
        },
        sorter: (a: any, b: any) =>
          this.Utilities.sortDate(a, b, 'backup-time'),
      },

      {
        title: 'Restored on',
        dataIndex: 'backup-restore',
        key: 'backup-restore',
        render: (text: string, record: any) => {
          return <span>{record?.['backup-restore']}</span>
        },
        sorter: (a: any, b: any) =>
          this.Utilities.sortDate(a, b, 'backup-restore'),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'description'),
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (record: any) => {
          return (
            <div className={styles.editColumn}>
              {storage.get.ADMIN_LEVEL === 'provider' &&
              !storage.get.IS_OPERATOR_USER ? (
                <i>
                  <A10DropdownMenu
                    menu={[
                      <div key="restore">Restore</div>,
                      <div key="delete">Delete</div>,
                    ]}
                    style={{ color: '#757575', marginBottom: '-15px' }}
                    onClick={this.onClickAction.bind(this, record)}
                    trigger="hover"
                    placement="bottomRight"
                    arrowPointAtCenter={true}
                  />
                </i>
              ) : null}
            </div>
          )
        },
      },
    ]
  }

  onClickAction = (record: any, component: JSX.Element) => {
    if (component.key === 'delete') {
      this.setState({ deleteModalState: true, uuid: record.uuid })
    } else if (component.key === 'restore') {
      this.setRestoreSlidingPage(true, record)
    }
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({ showSlidingPage: isOpen })
  }

  setRestoreSlidingPage = (isOpen: boolean, data: IObject) => {
    this.setState({ showRestoreSlidingPage: isOpen, backup: data })
  }

  handleOk = async (e: React.MouseEvent<any>) => {
    const { updateList, getRemainingBackups } = this.props
    const { uuid } = this.state
    try {
      await httpClient.delete(`/hpcapi/v3/uuid/${uuid}`)
      updateList()
      getRemainingBackups()
      this.setState({ deleteModalState: false })
    } catch (e) {
      // do nothing
      A10Message.error('Error: Failed to delete the config backup')
    }
  }

  handleCancel = (e: React.MouseEvent<any>) => {
    this.setState({ deleteModalState: false })
  }

  render() {
    const { data, deviceName } = this.props
    const { showSlidingPage, showRestoreSlidingPage } = this.state
    return (
      <>
        <A10Table
          className="hc-list"
          columns={this.defaultBackupInfoColumns}
          dataSource={data.map((item: IObject, index: number) => {
            item.key = index
            return item
          })}
          size="small"
          bordered={false}
          loading={
            this.props.isLoading
              ? {
                  indicator: (
                    <div className="loading-icon">
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    </div>
                  ),
                }
              : false
          }
        />
        <A10Modal
          title="Confirmation"
          visible={this.state.deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>Do you want to delete this Item?</p>
        </A10Modal>
        <SlidingBackup
          isEdit={true}
          isOpen={showSlidingPage}
          deviceName={deviceName}
          onRequestClose={this.setSlidingPage.bind(this, false)}
        />
        <SlidingRestore
          isEdit={true}
          isOpen={showRestoreSlidingPage}
          deviceName={deviceName}
          backup={this.state.backup}
          onRequestClose={this.setRestoreSlidingPage.bind(this, false)}
        />
      </>
    )
  }
}

export default setupA10Container(BackupInfoList)
