import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
} from '@gui-libraries/framework'
import {
  A10Checkbox,
  A10Table,
  A10Tag,
  A10Modal,
  A10DropdownMenu,
  A10Message,
  A10SlidingPage,
  A10Loader,
  A10Icon,
  A10Button,
} from '@gui-libraries/widgets'
import { AutoFormV15 } from '@gui-libraries/apps'

import storage from 'src/libraries/storage'
import { httpClient } from 'src/libraries/httpClient'
import SharedObjectDetail from '../SharedObjectDetail'
import { setFormModalSize } from '../../../Common/GeneralObjectBrowser'
import { getLPObject } from 'src/redux/httpServices/ADC/common/current_logical_partition'
import deployWrapper from '../../DeployWrapper'
// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')
// tslint:disable-next-line:no-var-requires
const deployStyles = require('../../styles/index.module.less')

export interface ISharedObjectListProps extends IA10ContainerDefaultProps {
  data: any[]
  tenant: string
  logicalPartition: string
  isLoading?: boolean
  updateList: any
  defaultExpandAllRows?: boolean
  scope?: string
  search: string
  handleDeploy: any
}

export interface ISharedObjectListState {
  name: string
  id?: string
  schemaPath: string
  schema: string
  showSlidingPage: boolean
  deleteModalState: boolean
  uuid: string
  duplicate: boolean
  viewOnly: boolean
  dirtyList: IObject[]
  needRefresh: boolean
  forceDelete: boolean
}

class SharedObjectList extends A10Container<
  ISharedObjectListProps,
  ISharedObjectListState
> {
  static defaultProps = {
    defaultExpandAllRows: false,
  }

  ref = React.createRef()

  constructor(props: ISharedObjectListProps) {
    super(props)
    this.state = {
      name: '',
      id: '',
      schemaPath: '',
      schema: '',
      showSlidingPage: false,
      deleteModalState: false,
      uuid: '',
      duplicate: false,
      viewOnly: false,
      dirtyList: [],
      needRefresh: false,
      forceDelete: false,
    }
  }

  componentDidUpdate(prevProps: ISharedObjectListProps) {
    if (this.state.needRefresh) {
      this.setState({ needRefresh: false })
    }
  }

  getColumns = () => {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: string, record: any, index: number) => {
          const displayName = record.name || record.id
          return <div className="name">{displayName}</div>
        },
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        render: (text: string, record: any, index: number) => {
          return (
            <div className="text-with-avatar">
              <A10Tag>{record.displayCategory}</A10Tag>
            </div>
          )
        },
      },
      {
        title: 'Type',
        dataIndex: 'displayType',
        key: 'type', // TODO: change this key
        render: (text: string, record: any, index: number) => {
          return (
            <div className="text-with-avatar">
              <A10Tag>{text}</A10Tag>
            </div>
          )
        },
      },
    ]
    if (
      this.props.scope !== 'provider' ||
      (storage.get.ADMIN_LEVEL === 'provider' && !storage.get.IS_OPERATOR_USER)
    ) {
      columns.push({
        title: '',
        dataIndex: '',
        key: '',
        render: (record: any) => {
          return (
            <div className={styles.editColumn}>
              <i>
                <A10DropdownMenu
                  menu={[
                    <div key="edit">Edit</div>,
                    <div key="duplicate">Duplicate</div>,
                    <div key="delete">Delete</div>,
                    <div key="deploy">Deploy</div>,
                  ]}
                  style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={this.clickAction.bind(this, record)}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </i>
            </div>
          )
        },
      })
    } else {
      columns.push({
        title: '',
        dataIndex: '',
        key: '',
        render: (record: any) => {
          return (
            <div className={styles.editColumn}>
              <i>
                <A10DropdownMenu
                  menu={[<div key="view">View</div>]}
                  style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={this.clickAction.bind(this, record)}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </i>
            </div>
          )
        },
      })
    }
    return columns
  }

  clickAction = (record: IObject, component: JSX.Element, index: number) => {
    let schema = ''
    if (record.type === 'ip.nat.logging') {
      schema = 'ip.nat.template.logging'
    } else {
      schema = `${record.type}`
    }
    if (component.key === 'edit') {
      const schemaPath = record.uiJson
      const primaryKey = record.name || record.id
      this.setSlidingPage(true, primaryKey, schemaPath, false, false, schema)

      if (record.id) {
        this.setState({ id: record.id })
      }
    } else if (component.key === 'duplicate') {
      const schemaPath = record.uiJson
      const primaryKey = record.name || record.id
      this.setSlidingPage(true, primaryKey, schemaPath, true, false, schema)
      if (record.id) {
        this.setState({ id: record.id })
      }
    } else if (component.key === 'delete') {
      this.setState({
        deleteModalState: true,
        schema,
        name: record.name,
        forceDelete: false,
      })
    } else if (component.key === 'deploy') {
      if (this.props.handleDeploy) {
        this.props.handleDeploy(record)
      }
    } else if (component.key === 'view') {
      const schemaPath = record.uiJson
      const primaryKey = record.name || record.id
      this.setSlidingPage(true, primaryKey, schemaPath, false, true)
    }
  }

  renderSharedObjectDetail = (record: any) => {
    const { tenant } = this.props
    return <SharedObjectDetail record={record} tenant={tenant} />
  }

  setSlidingPage = (
    isOpen: boolean,
    name = '',
    schemaPath = '',
    duplicate = false,
    viewOnly = false,
    schema = '',
  ) => {
    setFormModalSize(schemaPath)
    this.setState({
      showSlidingPage: isOpen,
      name,
      schemaPath,
      duplicate,
      viewOnly,
      schema,
    })
  }

  onCloseSlidingPage = () => {
    this.setSlidingPage(false)
  }

  onSuccessUpdateTemplate = () => {
    const {
      updateList,
      tenant,
      logicalPartition,
      A10Dispatchers: { httpRequest },
    } = this.props
    updateList()
    httpRequest(getLPObject(tenant, logicalPartition))
    this.onCloseSlidingPage()
    this.setState({ needRefresh: true })
  }

  handleOk = async (e: React.MouseEvent<any>) => {
    const {
      updateList,
      tenant,
      logicalPartition,
      search,
      A10Dispatchers: { httpRequest },
    } = this.props
    const { schema, name, forceDelete } = this.state
    try {
      const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${logicalPartition}/`
      let deleteAPI = apiPrefix + schema.replace(/\./g, '/') + '/' + name
      if (forceDelete) {
        deleteAPI += '?force=true'
      }
      await httpClient.delete(deleteAPI)
      updateList(search)
      httpRequest(getLPObject(tenant, logicalPartition))
      this.setState({ deleteModalState: false, forceDelete: false })
      A10Message.success('Success: Template has been deleted successfully')
    } catch (e) {
      // do nothing
      console.error(e)
      this.setState({ deleteModalState: false, forceDelete: false })
      A10Message.error('Error: Failed to delete the shared object')
    }
  }

  handleCancel = (e: React.MouseEvent<any>) => {
    this.setState({ deleteModalState: false })
  }

  onCheckForceDelete = (e: any) => {
    this.setState({ forceDelete: e.target.checked })
  }

  getRowKey = (record: IObject) => {
    return record.uuid
  }

  render() {
    const { data = [], tenant, logicalPartition } = this.props
    const {
      showSlidingPage,
      schemaPath,
      name,
      id,
      duplicate,
      viewOnly,
    } = this.state
    const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${logicalPartition}`
    let params: IObject = { name }
    if (id) {
      params = { id }
    }
    return (
      <div ref={this.ref}>
        <A10Table
          className={`hc-list ${styles.soList}`}
          rowKey={this.getRowKey}
          columns={this.getColumns()}
          dataSource={data.map((item: IObject, index: number) => {
            item.key = index
            return item
          })}
          // expandedRowRender={this.renderSharedObjectDetail}
          defaultExpandAllRows={this.props.defaultExpandAllRows}
          size="small"
          loading={
            this.props.isLoading && {
              indicator: <A10Loader container={this.ref} />,
            }
          }
        />
        <A10SlidingPage
          isOpen={showSlidingPage}
          onRequestClose={this.setSlidingPage.bind(this, false)}
        >
          {showSlidingPage ? (
            <AutoFormV15
              hccEnv={true}
              schemaPath={schemaPath}
              apiPrefix={apiPrefix}
              viewMode={viewOnly}
              duplicateMode={duplicate}
              params={params}
              interceptor={{
                onSubmitSuccess: this.onSuccessUpdateTemplate,
                onCancel: this.setSlidingPage.bind(this, false),
              }}
            />
          ) : null}
        </A10SlidingPage>
        <A10Modal
          title={
            <span>
              <A10Icon
                app="global"
                type="question"
                style={{ color: '#ffba47' }}
                width="20px"
                height="20px"
              />{' '}
              Confirmation
            </span>
          }
          className={deployStyles.deployConfrim}
          visible={this.state.deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>Do you want to delete this Item?</p>
          <A10Checkbox
            checked={this.state.forceDelete}
            onChange={this.onCheckForceDelete}
          >
            Force Delete
          </A10Checkbox>
        </A10Modal>
      </div>
    )
  }
}

function mapStateToProps(state: any, props: ISharedObjectListProps) {
  const objList = state.A10Data.getIn(getNS('SHARED_OBJECT_LIST'), [])
  return {
    data: objList,
  }
}

export default setupA10Container(
  deployWrapper(SharedObjectList),
  mapStateToProps,
)
