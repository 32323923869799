import React from 'react'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10DropdownMenu,
  A10Message,
  A10Modal,
  A10Icon,
  A10Tooltip,
  A10Button,
  A10Alert,
  A10Loader,
} from '@gui-libraries/widgets'

import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { DashboardService, Utilities } from 'src/services'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import SlidingLicenseEdit from '../Forms/LicenseEditForm/SlidingLicenseEdit'
import globalConfig from 'src/settings/config'
import { LicenseDetails } from 'src/containers/Controller/Organization/License/LicenseDetails/index'

import styles from './styles/index.module.less'
// tslint:disable-next-line:no-var-requires
const moment = require('moment')

export interface ILicenseListProps extends IA10ContainerDefaultProps {
  data: any[]
  isLoading?: boolean
  updateList: any
  licenseList: any
  glmLicTypeText: (glmLicType: string, licType: string) => string
  leftNavAppsRefresh: () => void
}
export interface ILicenseListState {
  name: string
  id?: string
  selectedLicense: any
  selectedEditLicense: IFormData
  selectedLicenseInfo: any
  deleteModalState: boolean
  showSlidingPage: boolean
  licenseList: any
  loadingIcon: boolean
}

class LicenseList extends A10Container<ILicenseListProps, ILicenseListState> {
  defaultLicenseColumns: any[]
  updateAuditLog: boolean
  menu: any[]
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  isOperatorUser = storage.get.IS_OPERATOR_USER
  ref = React.createRef<HTMLDivElement>()

  constructor(props: ILicenseListProps) {
    super(props)

    this.state = {
      name: null,
      id: null,
      selectedLicense: { 'entitlement-key': '' },
      deleteModalState: false,
      showSlidingPage: false,
      licenseList: this.props.licenseList || [],
      selectedEditLicense: {
        entitleToken: null,
        licenseType: null,
        numBandwidth: null,
        numDevices: null,
        numProcessingUnits: null,
        expiry: null,
        maxAllocatedBandwidth: null,
        usedDevices: null,
        usedBandwidth: null,
        maxAllocatedDevices: null,
      },
      selectedLicenseInfo: {},
      loadingIcon: false,
    }
    this.updateAuditLog = false
    this.defaultLicenseColumns = [
      {
        title: <></>,
        dataIndex: 'activation-status',
        key: 'activation-status',
        render: (text: string, record: any, index: number) => {
          const status = record['activation-status']
          const statusObj = this.Utilities.getVal(
            status,
            'LICENSE',
            'activation-status',
          )
          return (
            <div className="icons-wrap">
              <HealthStatus
                type={statusObj.type}
                tooltip={this.capitalize('License ' + statusObj.label)}
              />
            </div>
          )
        },
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'activation-status'),
      },
      {
        title: <>Token</>,
        dataIndex: 'entitlement-key',
        key: 'entitlement-key',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'entitlement-key'),
      },
      {
        title: <>Name</>,
        dataIndex: 'license-name',
        key: 'license-name',
        className: 'td-truncate',
        render: (text: string, record: any, index: number) => {
          return (
            <span className="td-truncate" title={text}>
              {text}
            </span>
          )
        },
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'license-name'),
      },
      {
        title: <>Platform</>,
        dataIndex: 'license-platform',
        key: 'license-platform',
        className: 'td-truncate',
        render: (text: string, record: any, index: number) => {
          return (
            <span className="td-truncate" title={text}>
              {text}
            </span>
          )
        },
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'license-platform'),
      },
      {
        // title: <>License Type</>,
        title: <>Product</>,
        dataIndex: 'license-type',
        key: 'license-type',
        className: 'td-truncate',
        render: (text: string, record: any, index: number) => {
          const licType = record['license-type']
          const glmLicType = record['glm-lic-type']
          let licTypeValue = ''
          if (glmLicType) {
            licTypeValue = this.props.glmLicTypeText(glmLicType, licType)
          }
          return (
            <span
              className="td-truncate"
              title={licType.indexOf('FLEXPOOL') > -1 ? licTypeValue : licType}
            >
              {licType.indexOf('FLEXPOOL') > -1 ? licTypeValue : licType}
            </span>
          )
        },
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'license-type'),
      },

      {
        title: <>Bandwidth</>,
        dataIndex: 'alloted-bandwidth',
        key: 'bandwidth',
        children: [
          {
            title: <div className={styles.noWrap}>Used / Capacity</div>,
            dataIndex: 'used-bandwidth',
            key: 'used-bandwidth',
            render: (text: string, record: any, index: number) => {
              const usedBW = record['used-bandwidth']
              const status = record['activation-status']
              const allotedBW = record['alloted-bandwidth']
              const allotedbandwidth = this.Utilities.megaToGiga(allotedBW || 0)
              const bandwidth = this.Utilities.megaToGiga(usedBW || 0)
              return (
                <>
                  {status === 'PENDING' ? (
                    <div>
                      <A10Tooltip
                        placement="bottom"
                        title="Capacity change request pending"
                        arrowPointAtCenter
                        overlayStyle={{ width: '150px' }}
                      >
                        <A10Icon
                          style={{ marginRight: '10px' }}
                          app="global"
                          type="warning-color"
                        />
                      </A10Tooltip>
                      <span>
                        {usedBW === 0
                          ? usedBW
                          : `${bandwidth.val} ${
                              bandwidth.unit === 'm' ? 'Mbps' : 'Gbps'
                            }`}

                        {' / '}
                        {allotedBW === 0
                          ? 'Unlimited'
                          : `${allotedbandwidth.val} ${
                              allotedbandwidth.unit === 'm' ? 'Mbps' : 'Gbps'
                            }`}
                      </span>
                    </div>
                  ) : (
                    <div
                      className={`${
                        allotedBW > 0 && usedBW === allotedBW
                          ? styles.expired
                          : ''
                      }`}
                    >
                      {record['license-type'] === 'SUBSCRIBER_FLEXPOOL'
                        ? 'na'
                        : usedBW === 0
                        ? usedBW
                        : `${bandwidth.val} ${
                            bandwidth.unit === 'm' ? 'Mbps' : 'Gbps'
                          }`}
                      {' / '}
                      {allotedBW === 0
                        ? record['license-type'] === 'SUBSCRIBER_FLEXPOOL'
                          ? 'na'
                          : 'Unlimited'
                        : `${allotedbandwidth.val} ${
                            allotedbandwidth.unit === 'm' ? 'Mbps' : 'Gbps'
                          }`}
                    </div>
                  )}
                </>
              )
            },
            sorter: (a: any, b: any) =>
              this.Utilities.sortString(a, b, 'used-bandwidth'),
          },
        ],
      },

      {
        title: <>Devices</>,
        dataIndex: 'alloted-devices',
        key: 'devices',
        children: [
          {
            title: <div className={styles.noWrap}>Used / Capacity</div>,
            dataIndex: 'used-devices',
            key: 'used-devices',
            render: (text: string, record: any, index: number) => {
              const usedDev = record['used-devices']
              const allotedDev = record['alloted-devices']
              return `${usedDev} / ${
                allotedDev === 0 ? 'Unlimited' : allotedDev
              }`
            },
            sorter: (a: any, b: any) =>
              this.Utilities.sortString(a, b, 'used-devices'),
          },
        ],
      },
      {
        title: <>Subscribers</>,
        dataIndex: 'subscriber-count',
        key: 'subscriber-count',
        render: (text: string, record: any, index: number) => {
          const subscribers = record['subscriber-count']
          return subscribers === 0 ? 'NA' : subscribers
        },
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'subscriber-count'),
      },
      {
        title: <div className={styles.noWrap}>Expires on</div>,
        dataIndex: 'expires-at',
        key: 'expires-at',
        sorter: (a: any, b: any) => this.Utilities.sortDate(a, b, 'expires-at'),
        render: (text: string, record: any, index: number) => {
          const status = record['activation-status']
          let fromDateFormat = !record['expires-at']
            ? ''
            : moment.utc(record['expires-at']).format('MM-DD-YYYY')
          if (
            record['expires-at'] &&
            record['expires-at'] === '2050-12-31 00:00:00'
          ) {
            fromDateFormat = 'Never'
            return fromDateFormat
          }

          return (
            <div
              className={
                status === 'PENDING'
                  ? styles.pending
                  : status === 'EXPIRED'
                  ? styles.expired
                  : ''
              }
              title={
                !record['expires-at']
                  ? ''
                  : moment
                      .utc(record['expires-at'])
                      .format('MMM DD, YYYY hh:mm A')
              }
            >
              {fromDateFormat}
            </div>
          )
        },
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (key: any) => {
          const todayDate = new Date()
          const date =
            todayDate.getFullYear() +
            '-' +
            (todayDate.getMonth() + 1) +
            '-' +
            todayDate.getDate()

          const expDate = key['expires-at'] || ''
          const keyExpires = expDate.substr(0, expDate.indexOf(' '))
          const currentDate = new Date(date)
          const expiresDate = new Date(keyExpires)

          const clickAction = (component: JSX.Element, index: number) => {
            if (component.props.className == 'disabled') {
              return
            }
            if (
              component.key === 'edit' &&
              key['activation-status'] !== 'REVOKED'
            ) {
              if (key['license-type'].indexOf('FLEXPOOL') > -1) {
                this.reSyncLicense(key)
              } else {
                this.editLicence(key)
              }
            } else if (
              component.key === 'delete' &&
              !(key['lm-mode'] && key['lm-mode'] === 'OFFLINE')
            ) {
              this.setState({
                deleteModalState: true,
                selectedLicense: key,
              })
            }
          }
          const menu = []
          if (!this.isOperatorUser) {
            menu.push(
              <div
                key="delete"
                className={
                  key['lm-mode'] && key['lm-mode'] === 'OFFLINE'
                    ? 'disabled'
                    : ''
                }
              >
                Delete{' '}
              </div>,
            )
            if (
              !(
                key['license-type'] === 'Trial' ||
                key['license-type'] === 'TEMP'
              )
            ) {
              menu.unshift(
                <div
                  key="edit"
                  className={
                    key['activation-status'] === 'REVOKED' ||
                    key['activation-status'] === 'EXPIRED' ||
                    key['activation-status'] === 'GRACE-PERIOD'
                      ? 'disabled'
                      : ''
                  }
                >
                  {key['license-type'].indexOf('FLEXPOOL') > -1
                    ? 'Resync'
                    : 'Update Capacity'}
                </div>,
              )
            }
          }

          if (key['lm-mode'] && key['lm-mode'] === 'OFFLINE') {
            menu.unshift(
              <div key="offline" className={styles.offlineMenuDiv}>
                Offline License{' '}
              </div>,
            )
          }
          return this.isOperatorUser ||
            (key['license-type'] === 'Trial' && expiresDate > currentDate) ||
            key['license-type'] === 'TEMP' ? null : (
            <div className="text-right">
              <i>
                <A10DropdownMenu
                  menu={menu}
                  title=""
                  style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={clickAction}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </i>
            </div>
          )
        },
      },
    ]
  }

  capitalize = (s: string) => {
    if (typeof s !== 'string') {
      return ''
    }
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  closeImageSlidingPage = () => {
    this.setState({ showSlidingPage: false })
    // Get Data Call here
    this.props.updateList()
  }

  handleOk = async (e: React.MouseEvent<any>) => {
    const { updateList } = this.props
    const { selectedLicense } = this.state
    this.setState({
      loadingIcon: true,
    })
    try {
      await httpClient.delete(
        `/hls/licenses/providers/${storage.get.PROVIDER}/entitlementkey/${selectedLicense['entitlement-key']}`,
        { absoluteBasePath: true },
      )
      updateList()
      this.setState({
        deleteModalState: false,
        loadingIcon: false,
        selectedLicense: { 'entitlement-key': '' },
      })
      A10Message.success(
        'Deletion request submitted successfully, please check the status in audit log for the license',
        4,
        close,
      )
      this.props.leftNavAppsRefresh()
    } catch (e) {
      // do nothing
      this.setState({
        loadingIcon: false,
      })
      A10Message.error(
        'Error: Unable to delete the license - Please consult with License Administrator of your organization about the license',
      )
    }
  }

  handleCancel = (e: React.MouseEvent<any>) => {
    if (this.state.loadingIcon) {
      return false
    }
    this.setState({ deleteModalState: false })
  }

  renderLicenseDetails = (
    record: any,
    index: number,
    indent: number,
    expanded: boolean,
  ) => {
    this.updateAuditLog = expanded ? !this.updateAuditLog : this.updateAuditLog
    const { updateList } = this.props
    return (
      <LicenseDetails
        licenseData={record}
        isUpdateAuditLog={this.updateAuditLog}
        refreshData={updateList}
        glmLicTypeText={this.props.glmLicTypeText}
      />
    )
  }

  editLicence = (license: any) => {
    const state = { ...this.state }
    state.showSlidingPage = true
    const maxbandwidthObj = this.Utilities.megaToGiga(
      license['remaining-bandwidth'] || 0,
    )
    let usedBW = license['used-bandwidth'],
      numBW = license['alloted-bandwidth']
    if (maxbandwidthObj && maxbandwidthObj.unit === 'g') {
      usedBW = parseFloat((license['used-bandwidth'] / 1000).toFixed(2))
      numBW = parseFloat((license['alloted-bandwidth'] / 1000).toFixed(2))
    }
    state.selectedEditLicense = {
      entitleToken: license['entitlement-key'],
      licenseType: license['license-type'],
      numBandwidth: numBW,
      numDevices: license['alloted-devices'],
      numProcessingUnits: null,
      expiry: license['expires-at'],
      maxAllocatedBandwidth: license['remaining-bandwidth'],
      billingSerials: license['billing-serials'],
      usedDevices: license['used-devices'],
      usedBandwidth: usedBW,
      maxAllocatedDevices: license['remaining-devices'],
      offlineMode: license['lm-mode'] && license['lm-mode'] === 'OFFLINE',
      activationUUID:
        license['lm-mode'] && license['lm-mode'] === 'OFFLINE'
          ? license['activation-uuid']
          : '',
    }
    state.selectedLicenseInfo = license
    this.setState(state)
  }

  reSyncLicense = async (license: any) => {
    const payload = {
      'entitlement-key': license['entitlement-key'],
      'license-type': license['license-type'],
      'alloted-bandwidth': license['alloted-bandwidth'],
      'alloted-devices': license['alloted-bandwidth'],
      'expires-at': license['expires-at'],
      'billing-serials': license['billing-serials'],
      'glm-lic-type': license['glm-lic-type'],
      'license-name': license['license-name'],
      'license-id': license['license-id'],
      'license-json': license['license-json'],
      'glm-created-at': license['glm-created-at'],
      'glm-updated-at': license['glm-updated-at'],
      'created-at': license['created-at'],
      'last-modified-at': license['last-modified-at'],
      description: license['description'],
      notes: license['notes'],
      'check-in-frequency': license['check-in-frequency'],
      'default-allocated-bandwidth': license['default-allocated-bandwidth'],
      'max-allocated-bandwidth': license['max-allocated-bandwidth'],
      'min-allocated-bandwidth': license['min-allocated-bandwidth'],
      'container-device-return-to-pool-time':
        license['container-device-return-to-pool-time'],
      'remaining-bandwidth': license['remaining-bandwidth'],
      'remaining-devices': license['remaining-devices'],
      'used-bandwidth': license['used-bandwidth'],
      'used-devices': license['used-devices'],
      'used-activations': license['used-activations'],
      'active-appliances': license['active-appliances'],
      'current-revision-id': license['current-revision-id'],
      'licensed-device-list': license['licensed-device-list'],
      'license-platform': license['license-platform'],
      'license-product': license['license-product'],
      'organization-name': license['organization-name'],
      'start-date': license['start-date'],
    }

    const {
      EPIC_DEPENDENCIES: { httpClient },
    } = globalConfig

    const url = `/hls/licenses/providers/${storage.get.PROVIDER}/entitlementkey/${license['entitlement-key']}`
    try {
      const { data } = await httpClient.put(url, payload, {
        absoluteBasePath: true,
      })
      if (data) {
        this.Utilities.showMessage(
          'Resync request submitted successfully, please check the status in activity log for the license',
          1,
          0,
        )
        this.closeImageSlidingPage()
      }
    } catch (error) {
      const msg = error?.response?.data?.message || ''
      this.Utilities.showMessage(
        'Error while resync request - Please consult with License Administrator of your organization about the capacity you are trying to allocate',
        0,
        0,
        msg,
      )
    }
  }

  render() {
    const { licenseList = [] } = this.props
    const { showSlidingPage, selectedEditLicense } = this.state
    return (
      <div ref={this.ref}>
        <A10Table
          columns={this.defaultLicenseColumns}
          expandedRowRender={this.renderLicenseDetails}
          dataSource={licenseList.map((item: IObject, index: number) => {
            item.key = index
            return item
          })}
          scroll={{ x: '100%' }}
          loading={
            this.props.isLoading && {
              indicator: <A10Loader container={this.ref} />,
            }
          }
          pagination={{ hideOnSinglePage: true, pageSize: 10 }}
        />

        <A10Modal
          title="Delete License"
          visible={this.state.deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={this.state.loadingIcon}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
              disabled={
                this.state.loadingIcon ||
                (this.state.selectedLicense['licensed-device-list'] &&
                  this.state.selectedLicense['licensed-device-list'].length > 0)
              }
            >
              Yes
            </A10Button>,
          ]}
        >
          {this.state.selectedLicense['licensed-device-list'] &&
          this.state.selectedLicense['licensed-device-list'].length > 0 ? (
            <A10Alert
              message="Warning"
              description={
                'Device(s) are associated with this license, can not be deleted'
              }
              type="warning"
            />
          ) : (
            <>
              <p>
                You are deleting License -{' '}
                {this.state.selectedLicense['entitlement-key']}. This action
                can't be reverted.
                <br />
                Devices will not be able to connect in absence of a License.
                <br />
                <br />
                Do you want to continue to delete the license?
              </p>
              {this.state.loadingIcon ? (
                <div className="">
                  <ReactLoading
                    type="bars"
                    color="#4a90e2"
                    height={40}
                    width={40}
                  />
                </div>
              ) : null}
            </>
          )}
        </A10Modal>

        <SlidingLicenseEdit
          isOpen={showSlidingPage}
          onRequestClose={this.closeImageSlidingPage}
          formData={selectedEditLicense}
          licenseInfo={this.state.selectedLicenseInfo}
        />
      </div>
    )
  }
}

function mapStateToProps(state: any, props: ILicenseListProps) {
  return {
    data: state.A10Data.getIn(getNS('LICENSE_LIST'), []),
  }
}

export default setupA10Container(LicenseList, mapStateToProps)
