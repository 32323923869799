import React from 'react'
import { A10Col } from '@gui-libraries/widgets'

import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import styles from './styles/index.module.less'

interface ICannedTriggersProps {}

const CannedTriggers: React.FC<ICannedTriggersProps> = props => {
  const onClick = () => {}

  return (
    <ContentSection>
      <ContentHeader type="flex" align="middle" justify="space-between">
        <A10Col>
          <ContentTitle title="Canned Triggers" />
        </A10Col>
        <A10Col className={styles.actionButtonContainer}>
          <div>Fake Filters</div>
          <ActionButton
            text="Add Canned Trigger"
            onClick={onClick}
            iconProps={{ app: 'global', type: 'upload' }}
          />
        </A10Col>
      </ContentHeader>
      <ContentBody>Canned Triggers</ContentBody>
    </ContentSection>
  )
}

export default CannedTriggers
