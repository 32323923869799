import React from 'react'
import { Map } from 'immutable'
import { A10Container, _ } from '@gui-libraries/framework'
import {
  A10Collapse,
  A10Row,
  A10Col,
  A10Icon,
  A10Select,
  A10Alert,
} from '@gui-libraries/widgets'

import { HealthStatus } from 'src/components/ADC/HealthStatus'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import A10Panel from 'src/components/ADC/A10Panel'
import { DiffComparator } from 'src/components/shared/DiffComparator'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import { flattenFileArray } from 'src/libraries/arrayUtils'
export interface ILogicalPartitionDeployProps {
  type: string
  partition: IObject
  onChange: any
  onVersionChange: (assocLP: IPartitionVersion) => void
  showValidationAlert: boolean
  validationError: string
  closeValidationAlert: () => void
  additionalID?: string
}

export interface IPartitionVersion {
  uuid: string
  leftComment: string
  rightComment: string
  leftConfig: string
  rightConfig: string
  fileList: IObject[]
  leftVersion?: number
  rightVersion?: number
}

export interface ILogicalPartitionDeployState {
  selectedCluster: string
  selectedPartition: string
  clusterList: string[]
  partitionListByCluster: Map<string, IObject[]>
  partitionListDisplay: IObject[]
  leftComment: string
  rightComment: string
  leftConfig: string
  rightConfig: string
  fileList: IObject[]
  assocLP?: IObject
  assocLPContent?: IPartitionVersion
  isLoadingPartition: boolean
  showValidationAlert: boolean
  validationError: string
}

class LogicalPartitionDeploy extends A10Container<
  ILogicalPartitionDeployProps,
  ILogicalPartitionDeployState
> {
  constructor(props: ILogicalPartitionDeployProps) {
    super(props)
    this.state = {
      selectedCluster: '',
      selectedPartition: '',
      clusterList: [],
      partitionListByCluster: Map<string, IObject[]>(),
      partitionListDisplay: [],
      leftComment: '',
      rightComment: '',
      leftConfig: '',
      rightConfig: '',
      fileList: [],
      isLoadingPartition: false,
      showValidationAlert: false,
      validationError: '',
    }
  }

  componentDidMount() {
    const { partition, type } = this.props
    let vNum = 0
    if (partition['running-version']) {
      this.getConfigfromVersion(
        partition.uuid,
        partition['running-version'],
      ).then(result => {
        this.setState({ leftConfig: result.data })
      })
      this.getVersionDetail(partition.uuid, partition['running-version']).then(
        result => {
          this.setState({ leftComment: result.data['hcms-version'].comments })
        },
      )
    }
    if (partition.version || partition['candidate-version']) {
      const id = partition.uuid
      if (partition['candidate-version']) {
        vNum = partition['candidate-version']
      } else if (partition.version) {
        vNum = partition.version
      }
      this.getConfigfromVersion(id, vNum).then(result => {
        this.setState({ rightConfig: result.data })
      })
      this.getVersionDetail(partition.uuid, vNum).then(result => {
        this.setState({ rightComment: result.data['hcms-version'].comments })
      })
      this.getVersionFileList(partition).then(result => {
        this.setState({ fileList: flattenFileArray(result.data) })
      })
    }
    if (type === 'logical') {
      this.initClusterList().then(() => {
        if (partition.name) {
          const key = `LogicalPartition_${partition.uuid}`
          const cp = storage.getItem(key)
          if (cp) {
            const res = cp.split('$$&&')
            this.getPartitionListByCluster(res[0])
            this.setState(
              { selectedCluster: res[0], selectedPartition: res[1] },
              () => {
                this.onChangePartition(this.state.selectedPartition)
              },
            )
          }
        }
      })
    } else {
      if (this.props.additionalID) {
        this.initAssociatedLogicalPartitionConfig(this.props.additionalID)
      }
    }
  }

  componentDidUpdate(prevProps: ILogicalPartitionDeployProps) {
    if (
      prevProps.showValidationAlert !== this.props.showValidationAlert ||
      prevProps.validationError !== this.props.validationError
    ) {
      this.setState({
        showValidationAlert: this.props.showValidationAlert,
        validationError: this.props.validationError,
      })
    }
  }

  initAssociatedLogicalPartitionConfig = (uuid: string) => {
    httpClient.get(`hpcapi/v3/uuid/${uuid}`).then(res => {
      if (res.data && res.data['logical-partition']) {
        const partition = res.data['logical-partition']
        let vNum = 0
        const promises = []
        if (partition['running-version']) {
          promises.push(
            this.getConfigfromVersion(
              partition.uuid,
              partition['running-version'],
            ),
          )
          promises.push(
            this.getVersionDetail(partition.uuid, partition['running-version']),
          )
        }
        if (partition.version || partition['candidate-version']) {
          const id = partition.uuid
          if (partition['candidate-version']) {
            vNum = partition['candidate-version']
          } else if (partition.version) {
            vNum = partition.version
          }
          promises.push(this.getConfigfromVersion(id, vNum))
          promises.push(this.getVersionDetail(partition.uuid, vNum))
          promises.push(this.getVersionFileList(partition))
        }
        Promise.all(promises).then(results => {
          if (results.length > 0) {
            let leftConfig = ''
            let leftComment = ''
            let rightConfig = ''
            let rightComment = ''
            let fileList = []
            if (results.length === 5) {
              leftConfig = results[0].data
              leftComment = results[1].data['hcms-version'].comments
              rightConfig = results[2].data
              rightComment = results[3].data['hcms-version'].comments
              fileList = results[4].data
            } else if (results.length === 3) {
              rightConfig = results[0].data
              rightComment = results[1].data['hcms-version'].comments
              fileList = results[2].data
            }

            this.setState(
              {
                assocLP: res.data['logical-partition'],
                assocLPContent: {
                  uuid: partition.uuid,
                  leftComment,
                  rightComment,
                  leftConfig,
                  rightConfig,
                  fileList: flattenFileArray(fileList),
                  leftVersion: partition['running-version']
                    ? partition['running-version']
                    : null,
                  rightVersion: vNum,
                },
              },
              () => {
                this.props.onVersionChange(this.state.assocLPContent)
              },
            )
          }
        })
      }
    })
  }

  initClusterList() {
    const tenant = storage.get.CURRENT_TENANT
    const tenantID = tenant.uuid
    this.setState({ isLoadingPartition: true })
    return httpClient
      .get(`/hpcapi/v3/provider/${storage.get.PROVIDER}/cluster/`)
      .then(result => {
        if (result.data && result.data['cluster-list']) {
          let { partitionListByCluster } = this.state
          result.data['cluster-list'].forEach((item: IObject) => {
            const partitionList = item['partition-list']
              ? item['partition-list']
                  .map(
                    ({
                      name,
                      uuid,
                      'lp-uuid': lp,
                      'tenant-uuid': tenantId,
                      id,
                      'app-type': appType,
                    }: {
                      name: string
                      uuid: string
                      'lp-uuid': string
                      'tenant-uuid': string
                      id: number
                      'app-type': string
                    }) => {
                      return { name, uuid, lp, tenantId, id, appType }
                    },
                  )
                  .filter(item => {
                    return item.tenantId === tenantID
                  })
              : []
            partitionListByCluster = partitionListByCluster.set(
              item.name,
              partitionList,
            )
          })
          this.setState({
            clusterList: result.data['cluster-list'].map(
              ({ name }: { name: string }) => name,
            ),
            partitionListByCluster,
            isLoadingPartition: false,
          })
        }
      })
  }

  getPartitionListByCluster = (cluster: string) => {
    const { partitionListByCluster } = this.state
    this.setState({
      partitionListDisplay: partitionListByCluster
        .get(cluster, [])
        .map(item => {
          return {
            name: item.name,
            'lp-uuid': item.lp,
            id: item.id,
            appType: item.appType,
          }
        }),
    })
  }

  onChangeCluster = (cluster: string) => {
    this.onChangePartition('')
    this.setState({ selectedCluster: cluster })
  }

  onChangePartition = (partition: string) => {
    const filteredList = this.state.partitionListByCluster
      .get(this.state.selectedCluster, [])
      .filter(({ name }: { name: string }) => name === partition)
    const uuidList = filteredList.map(({ uuid }) => {
      return { uuid }
    })
    const label = `${this.state.selectedCluster}$$&&${partition}`
    this.props.onChange(uuidList, label)
    this.setState({ selectedPartition: partition })
  }

  onPartitionDropdownVisibleChange = (isOpen: boolean) => {
    if (isOpen) {
      this.getPartitionListByCluster(this.state.selectedCluster)
    }
  }

  renderVersionOptions = (partition: IObject) => {
    // control left or right
    let defaultVersion = partition.version
    if (partition['candidate-version']) {
      defaultVersion = partition['candidate-version']
    }
    const versionCount = partition.version
    const versions = _.range(1, versionCount + 1)
    const options = versions.map(version => {
      return (
        <A10Select.Option key={version} value={version}>
          <span>{'v ' + version}</span>
          {version == defaultVersion &&
          version != partition['running-version'] ? (
            <span className="candidate-version-box">Candidate</span>
          ) : null}
          {version == partition['running-version'] ? (
            <span className="running-version-box">Running</span>
          ) : null}
        </A10Select.Option>
      )
    })
    return (
      <A10Select
        style={{ width: '20%', minWidth: 'min-content' }}
        onChange={(version: number) =>
          this.onChangeLPVersion(partition, version)
        }
        value={
          this.state.assocLPContent
            ? parseInt(this.state.assocLPContent.rightVersion, 10)
            : defaultVersion
        }
        disabled={true}
      >
        {options}
      </A10Select>
    )
  }

  onChangeLPVersion = (partition: IObject, version: number) => {
    let { assocLPContent } = this.state
    // const key = `${partition.uuid}_v_${version}`
    // if (!getItem(key)) {
    const res = this.getVersionData(partition, version)
    Promise.all(res[0]).then(() => {
      assocLPContent = res[1]
      this.setState({ assocLPContent }, () => {
        this.props.onVersionChange(assocLPContent)
      })
      // setItem(key, res[1], true)
    })
    // } else {
    //   const partitionVersion = getItem(key, true)
    //   assocLPContent = partitionVersion
    //   this.setState({ assocLPContent }, () => {
    //     this.props.onVersionChange(assocLPContent)
    //   })
    // }
  }

  getVersionData = (partition: IObject, version?: number) => {
    const partitionVersion: IPartitionVersion = {
      uuid: partition.uuid,
      leftComment: '',
      rightComment: '',
      leftConfig: '',
      rightConfig: '',
      fileList: [],
      leftVersion: partition['running-version'] || 0,
      rightVersion: version
        ? version
        : partition.version
        ? partition.version
        : 0,
    }

    let vNum = 0
    const promises = []
    if (partition['running-version']) {
      promises.push(
        this.getConfigfromVersion(
          partition.uuid,
          partition['running-version'],
        ).then(result => {
          partitionVersion.leftConfig = result.data
        }),
      )
      promises.push(
        this.getVersionDetail(
          partition.uuid,
          partition['running-version'],
        ).then(result => {
          partitionVersion.leftComment = result.data['hcms-version'].comments
        }),
      )
    }
    if (partition.version || partition['candidate-version']) {
      const id = partition.uuid
      if (version) {
        vNum = version
      } else if (partition['candidate-version']) {
        vNum = partition['candidate-version']
      } else if (partition.version) {
        vNum = partition.version
      }
      promises.push(
        this.getConfigfromVersion(id, vNum).then(result => {
          partitionVersion.rightConfig = result.data
        }),
      )
      promises.push(
        this.getVersionDetail(id, vNum).then(result => {
          partitionVersion.rightComment = result.data['hcms-version'].comments
        }),
      )
      promises.push(
        this.getVersionFileList(partition).then(result => {
          partitionVersion.fileList = flattenFileArray(result.data)
        }),
      )
    }
    return [promises, partitionVersion]
  }

  getConfigfromVersion = async (id: string, version: number) => {
    const uri = `/hpcapi/v3/blob/${id}+${version}`
    return httpClient.get(uri)
  }

  getVersionDetail = (id: string, versionNum: number) => {
    const uri = `/hpcapi/v3/hcms-version/${id}+${versionNum}`
    return httpClient.get(uri)
  }

  getVersionFileList = (partition: IObject) => {
    const fileUri = partition['a10-url'] + '/file'
    return httpClient.get(fileUri)
  }

  renderRecord = () => {
    return (
      <A10Row>
        <A10Col style={{ paddingLeft: '20px' }} span={20}>
          <DiffComparator
            id="main_partition"
            type="deploy"
            leftConfig={this.state.leftConfig}
            rightConfig={this.state.rightConfig}
            leftComment={this.state.leftComment}
            rightComment={this.state.rightComment}
            isLoadingLeft={
              this.props.partition['running-version'] &&
              !!!this.state.leftConfig
            }
            isLoadingRight={
              this.props.partition['candidate-version'] &&
              !!!this.state.rightConfig
            }
            showFileList={this.props.type === 'logical'}
            fileList={this.state.fileList}
          />
        </A10Col>
      </A10Row>
    )
  }

  renderDeviceAssocicatedLP = () => {
    return (
      <A10Row>
        <A10Col style={{ paddingLeft: '20px' }} span={20}>
          <DiffComparator
            id="assoc_lp"
            type="deploy"
            leftConfig={this.state.assocLPContent.leftConfig}
            rightConfig={this.state.assocLPContent.rightConfig}
            leftComment={this.state.assocLPContent.leftComment}
            rightComment={this.state.assocLPContent.rightComment}
            isLoadingLeft={
              this.state.assocLPContent.leftVersion &&
              !!!this.state.assocLPContent.leftConfig
            }
            isLoadingRight={
              this.state.assocLPContent.rightVersion &&
              !!!this.state.assocLPContent.rightConfig
            }
            showFileList={true}
            fileList={this.state.assocLPContent.fileList}
          />
        </A10Col>
      </A10Row>
    )
  }

  render() {
    const DeployWindowTitle = () => {
      return <span style={{ marginLeft: 5 }}>Changes</span>
    }

    const SelectPartitionTitle = () => {
      return (
        <>
          <span style={{ marginLeft: 5 }}>Select a Cluster Partition</span>
          <span className="sform-field-required" />
        </>
      )
    }

    const { partition, type } = this.props

    const DeployLPWindowTitle = (props: IObject) => {
      return (
        <A10Row>
          <A10Col span={20}>
            <div style={{ display: 'inline', marginRight: 10 }}>
              <span
                style={{ marginLeft: 5 }}
              >{`Associated Logical Partition: ${props.name}`}</span>
            </div>
            <div
              style={{ display: 'inline' }}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              {this.renderVersionOptions(this.state.assocLP)}
            </div>
          </A10Col>
        </A10Row>
      )
    }
    return (
      <>
        {this.state.showValidationAlert ? (
          <A10Alert
            showIcon={true}
            style={{ marginBottom: '5px' }}
            description={
              <div>
                <div>Error Details:</div>
                <div className="alert-text">{this.state.validationError}</div>
              </div>
            }
            type="error"
            closable
            onClose={this.props.closeValidationAlert}
          />
        ) : null}
        <A10Alert
          showIcon={true}
          style={{ marginBottom: '5px' }}
          description="For deploy to work properly, please make sure config-replace is enabled on Thunder."
          type="info"
          closable
        />
        {type === 'logical' && !this.state.selectedPartition ? (
          <A10Alert
            showIcon={true}
            style={{ marginBottom: '5px' }}
            description="To be able to Deploy this Logical Partition it must be associated with a Cluster partition."
            type="error"
            closable
          />
        ) : null}
        {type === 'logical' ? (
          <A10Panel
            title={
              <A10IconTextGroup
                text={<SelectPartitionTitle />}
                icon={<A10Icon app="global" type="form-section" />}
              />
            }
          >
            <A10Row>
              <A10Col span={20}>
                <A10Col span={12}>
                  <A10Select
                    style={{ width: '95%', marginRight: 7 }}
                    value={this.state.selectedCluster}
                    onChange={this.onChangeCluster}
                  >
                    {this.state.clusterList.map(cluster => {
                      return (
                        <A10Select.Option
                          key={cluster}
                          value={cluster}
                          title={cluster}
                        >
                          <HealthStatus
                            type="info"
                            text="C"
                            hideTooltip={true}
                          />
                          <span style={{ marginLeft: 7 }}>{cluster}</span>
                        </A10Select.Option>
                      )
                    })}
                  </A10Select>
                </A10Col>
                <A10Col span={12}>
                  <A10Select
                    style={{ width: '95%' }}
                    value={this.state.selectedPartition}
                    disabled={!this.state.selectedCluster}
                    onChange={this.onChangePartition}
                    onDropdownVisibleChange={
                      this.onPartitionDropdownVisibleChange
                    }
                    loading={this.state.isLoadingPartition}
                  >
                    {this.state.partitionListDisplay.map(devicePartition => {
                      let showAssigned = false
                      let disableOption = false
                      let matchAppType = true
                      if (partition.type && partition.type === 'shared') {
                        disableOption = devicePartition.id !== 0
                      } else {
                        disableOption = devicePartition.id === 0
                      }
                      if (devicePartition['lp-uuid']) {
                        if (
                          !(
                            partition &&
                            partition.uuid &&
                            devicePartition['lp-uuid'] === partition.uuid
                          )
                        ) {
                          showAssigned = true
                        }
                      }

                      if (
                        !!devicePartition.appType &&
                        devicePartition.appType !== 'none' &&
                        (partition['app-type'] === 'adc' ||
                          partition['app-type'] === 'cgnv6')
                      ) {
                        matchAppType =
                          devicePartition.appType === partition['app-type']
                      }
                      return (
                        <A10Select.Option
                          key={devicePartition.name}
                          value={devicePartition.name}
                          title={devicePartition.name}
                          disabled={
                            disableOption || !matchAppType || showAssigned
                          }
                        >
                          <HealthStatus
                            type="info"
                            text="P"
                            hideTooltip={true}
                          />
                          <span style={{ marginLeft: 7 }}>
                            {devicePartition.name}
                          </span>
                          {showAssigned ? (
                            <span className="lp-assigned">Assigned</span>
                          ) : null}
                        </A10Select.Option>
                      )
                    })}
                  </A10Select>
                </A10Col>
              </A10Col>
            </A10Row>
          </A10Panel>
        ) : null}
        <A10Panel
          title={
            <A10IconTextGroup
              text={<DeployWindowTitle />}
              icon={<A10Icon app="global" type="form-section" />}
            />
          }
          isFormContent={false}
        >
          <div className="diff-comparator">{this.renderRecord()}</div>
        </A10Panel>
        {this.state.assocLP ? (
          <A10Collapse defaultActiveKey={['0']} style={{ marginTop: 6 }}>
            <A10Collapse.Panel
              header={<DeployLPWindowTitle name={this.state.assocLP.name} />}
              key="0"
            >
              <div className="diff-comparator">
                {this.renderDeviceAssocicatedLP()}
              </div>
            </A10Collapse.Panel>
          </A10Collapse>
        ) : null}
      </>
    )
  }
}

export default LogicalPartitionDeploy
