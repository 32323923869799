import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
} from '@gui-libraries/framework'
import {
  A10Form,
  A10Input,
  A10Select,
  A10Switch,
  A10Icon,
  hcKeys as countries,
  A10Button,
} from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { IDefaultMethods } from 'src/containers/Controller'
import { Messages } from 'src/locale/en/index'
import { DashboardService } from 'src/services/index'
import { DropdownConstants } from 'src/constants/DropdownConstants/DropdownConstants'
import Utilities from 'src/services/Utilities/Utilities'
import { InfrastructureService } from 'src/services/InfrastructureService'
import { IDevice } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/Devices/Devices'
import { DEVICE_OBJ } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/Devices/Devices'
import { HelpInfo } from 'src/components/shared/HelpInfo'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { ClusterAddForm } from 'src/containers/Controller/Infrastructure/Clusters/Forms/ClusterAddForm'
import { ClusterPanel } from 'src/containers/Controller/Infrastructure/Clusters/Forms/ClusterPanel'
import './styles/deviceaddform.scss'
import CitySelect from 'src/components/shared/CitySelect'

export const CLUSTER_OBJ = {
  type: 'single',
  dataPlaneHA: 'none',
  clusterName: '',
  clusterDName: '',
  description: '',
  advanced: false,
  clusterSetID: '',
  floatingIP: '',
  floatingIPMask: '',
  convertToHA: false,
  clusterUserName: '',
  clusterPassword: '',
  devices: [
    {
      deviceName: '',
      thunderIPAddress: '',
      mgmtInterface: true,
      port: '443',
      zone: undefined,
      region: undefined,
      location: '',
      metricsExportInterval: 60,
      userName: '',
      userPassword: '',
      licenseCheck: false,
      licenseToken: '',
      licenseObj: '',
      numBandwidth: '',
      bandwidthUnit: '',
    },
  ],
}

export interface IDeviceAddFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  formData: any[]
  cluster?: any
  device?: any
  form: any
  onRef?: any
  handleChange?: (data: any) => void
  allDevices: any
  hideDevicePanel?: boolean
}

export interface IDeviceAddFormState {
  devices: IDevice[]
  clustersList: any[]
  filteredClustersList: any[]
  tenantsList: any[]
  cluster?: any
  isAddNewCluster: boolean
  showSlidingPage: boolean
  clusterObj: any
}

class DeviceAddForm extends A10Container<
  IDeviceAddFormProps,
  IDeviceAddFormState
> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  Messages = new Messages()
  DashboardService = new DashboardService()
  DropdownConstants = new DropdownConstants()
  Countries = countries.sort((a: any, b: any) => {
    return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
  })
  Utilities = new Utilities()
  InfrastructureService = new InfrastructureService()
  clustersList: any = []
  tenantsList: any = []
  apiError: number
  slidingTitle = 'Add Cluster'
  slidingDesc = 'Please provide the detail to connect device'
  childForm: any = null
  hcHostname = window.location.hostname

  constructor(props: IDeviceAddFormProps) {
    super(props)
    const devices: any[] = props.formData

    if (this.Countries[0].name !== '') {
      this.Countries.unshift({
        alias: 'null',
        key: 'null',
        name: '',
      })
    }

    this.state = {
      devices: devices || [],
      clustersList: [],
      filteredClustersList: [],
      tenantsList: [],
      cluster: this.props.cluster,
      isAddNewCluster: false,
      showSlidingPage: false,
      clusterObj: JSON.parse(JSON.stringify(CLUSTER_OBJ)),
    }
    if (
      !this.props.cluster &&
      (!devices[0].cluster || devices[0].cluster.charAt(0) === '{')
    ) {
      this.loadClusters()
      if (!this.state.devices[0].deviceName) {
        this.state.devices[0].cluster = undefined
      }
    } else {
      this.apiError = 1
    }
    if (!this.props.device && !this.state.devices[0].deviceName) {
      this.state.devices[0].region = undefined
    }
  }

  // using this method to setState changes all at once place
  checkClusterTenantList() {
    if (this.clustersList.length > 0 && this.tenantsList.length > 0) {
      let tempCluster: any
      let tempTenant: any
      if (this.props.formData[0].cluster || this.props.cluster) {
        const clusterName = this.props.formData[0].cluster
          ? this.props.formData[0].cluster
          : this.props.cluster.name
        tempCluster = this.Utilities.filterObjByProperty(
          this.clustersList,
          'name',
          clusterName,
        )
      }
      if (this.props.formData[0].tenant) {
        tempTenant = this.Utilities.filterObjByProperty(
          this.tenantsList,
          'name',
          this.props.formData[0].tenant,
        )
      }

      this.setState(
        {
          clustersList: this.clustersList,
          filteredClustersList: this.clustersList,
          tenantsList: this.tenantsList,
        },
        () => {
          this.handleChange('tenant', JSON.stringify(tempTenant), 0)
          this.handleChange('cluster', JSON.stringify(tempCluster), 0)
        },
      )
    } else if (this.apiError) {
      // 1 for error in clustersList api
      // 2 for error in tenantsList api
      // 3 for both error
      if (this.apiError === 1 && this.tenantsList.length > 0) {
        let tempTenant
        if (this.props.formData[0].tenant) {
          tempTenant = this.Utilities.filterObjByProperty(
            this.tenantsList,
            'name',
            this.props.formData[0].tenant,
          )
        }
        this.setState(
          {
            tenantsList: this.tenantsList,
            tenant: tempTenant ? JSON.stringify(tempTenant) : '',
          },
          () => {
            this.handleChange('tenant', this.state.tenant)
          },
        )
      } else if (this.apiError === 2 && this.clustersList.length > 0) {
        let tempCluster
        if (this.props.formData[0].cluster) {
          tempCluster = this.Utilities.filterObjByProperty(
            this.clustersList,
            'name',
            this.props.formData[0].cluster,
          )
        }
        this.setState(
          {
            clustersList: this.clustersList,
            filteredClustersList: this.clustersList,
            cluster: tempCluster ? JSON.stringify(tempCluster) : '',
          },
          () => {
            this.handleChange('cluster', this.state.cluster)
          },
        )
      } else {
        console.log('Error from loadClusters and loadTenants')
      }
    }
  }

  loadClusters = (clusterName?: string) => {
    const {
      get: { PROVIDER },
      set,
    } = storage

    const clusterResponse = this.InfrastructureService.getClustersSummary(
      null,
      null,
      PROVIDER,
    )

    clusterResponse
      .then((response: any) => {
        if (response.data && response.data['cluster-list'].length > 0) {
          const clusters = response.data['cluster-list'].map((cluster: any) => {
            return {
              name: cluster.name,
              'display-name': cluster['display-name'],
              uuid: cluster.uuid,
              type: cluster.type,
              dataPlaneHA: cluster['data-plane-ha'],
              'data-plane-ha': cluster['data-plane-ha'],
              'referrer-list': cluster['referrer-list'],
              description: cluster.description,
              'cluster-set-id': cluster['cluster-set-id'],
              'floating-ip': cluster['floating-ip'],
              'floating-ip-mask': cluster['floating-ip-mask'],
              'device-count': cluster['device-count'],
            }
          })

          //  to show up the clusters associated to vcs and single with no device attached and ha type with less than 2 devcies attached to add devices
          this.clustersList = this.Utilities.getClustersToAddDevice(clusters)
          this.apiError = 2 // tenant not needed for now, so apiError 2
          const clustersList = response.data['cluster-list']
          set.PROVIDER_CLUSTERS(clustersList)
          set.ALLCLUSTERS(clustersList)
          // Setting Select a Cluster value with newly created cluster
          if (clusterName) {
            const tempCluster = this.Utilities.filterObjByProperty(
              this.clustersList,
              'name',
              clusterName,
            )
            if (tempCluster.name) {
              const createdCluster = {
                name: tempCluster.name,
                'display-name': tempCluster['display-name'] || tempCluster.name,
                uuid: tempCluster.uuid,
                type: tempCluster.type,
                dataPlaneHA: tempCluster['data-plane-ha'],
                description: tempCluster.description,
                'cluster-set-id': tempCluster['cluster-set-id'],
                'floating-ip': tempCluster['floating-ip'],
                'floating-ip-mask': tempCluster['floating-ip-mask'],
                'device-count': tempCluster['device-count'],
              }
              this.props.form.setFieldsValue({
                cluster: createdCluster['display-name'],
              })
              this.handleChange('cluster', JSON.stringify(createdCluster), 0)
            }
          }

          this.checkClusterTenantList()
        }
      })
      .catch((error: any) => {
        console.log(error.response) // we need to create a centralised error handling
        if (this.apiError) {
          this.apiError = 3
        } else {
          this.apiError = 1
        }
        this.checkClusterTenantList()
      })
  }

  clusterSearch = (value: any, option?: any) => {
    const filteredClustersList = this.state.clustersList.filter((obj: any) => {
      return obj.name.indexOf(value) > -1
    })

    this.setState({ filteredClustersList })
  }

  loadTenants = () => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context

    const tenantResponse = this.DashboardService.listTenants(null, null, [
      provider,
    ])
    tenantResponse
      .then((resp: any) => {
        if (resp && resp.data && resp.data['tenant-list']) {
          const tenantsList = resp.data['tenant-list'].map((tenant: any) => {
            return {
              name: tenant.name,
            }
          })
          this.tenantsList = tenantsList
          // this.apiError = null
          this.checkClusterTenantList()
        }
      })
      .catch((error: any) => {
        console.log(error.response) // we need to create a centralised error handling
        if (this.apiError) {
          this.apiError = 3
        } else {
          this.apiError = 2
        }
        this.checkClusterTenantList()
      })
  }

  handleChange = (stateName: string, e: any, index?: number) => {
    if (!this.Utilities.validateField(e)) {
      return
    }
    const deviceObj = this.state
    if (stateName === 'cluster') {
      index = 0
    }

    if (index !== undefined) {
      let valArr = this.state['devices']
      valArr[index][stateName] = e && e.target ? e.target.value : e

      if (
        stateName === 'thunderIPAddress' ||
        stateName === 'userName' ||
        stateName === 'userPassword'
      ) {
        valArr[index]['licenseCheck'] = false
        valArr[index]['licenseToken'] = ''
        valArr[index]['licenseObj'] = ''
        valArr[index]['numBandwidth'] = ''
      }
      if (stateName === 'licenseObj') {
        valArr[index]['numBandwidth'] = ''
      }
      if (stateName === 'cluster' && e === 'new_cluster') {
        valArr[index][stateName] = undefined
      }
      if (stateName === 'region') {
        valArr[index]['zone'] = undefined
      }
      // @ts-ignore
      this.setState(
        {
          ['devices']: valArr,
        },
        () => {
          if (stateName === 'cluster' && e === 'new_cluster') {
            this.props.form.setFieldsValue({ cluster: undefined })
            this.addCluster()
          }
          if (stateName === 'region') {
            this.props.form.setFieldsValue({ [`zone[${index}]`]: undefined })
          }
        },
      )
      this.props.handleChange(valArr)
      return
    }
    if (e.target) {
      if (e.target.type === 'checkbox') {
        deviceObj[stateName] = e.target.checked
      } else {
        deviceObj[stateName] = e.target.value
      }
    } else {
      deviceObj[stateName] = e
    }
    this.props.handleChange(deviceObj)
    // @ts-ignore
    this.setState({
      [stateName]: deviceObj[stateName],
    })
  }

  addAnotherDevice = () => {
    const devices = Object.assign([], this.state.devices)
    const { cluster } = this.props?.formData?.[0]
    devices.push({
      deviceName: '',
      cluster: cluster,
      thunderIPAddress: '',
      mgmtInterface: true,
      port: '443',
      zone: undefined,
      region: undefined,
      location: '',
      metricsExportInterval: 60,
      tenant: '',
      newPartition: false,
      userName: '',
      userPassword: '',
      licenseCheck: false,
      licenseToken: '',
      licenseObj: '',
      numBandwidth: '',
    })
    this.setState({
      devices,
    })

    this.props.handleChange(devices)
  }

  addCluster = () => {
    this.setSlidingPage(true, CLUSTER_OBJ, true)
  }

  setSlidingPage = (
    isOpen: boolean,
    data?: IObject,
    isAddNewCluster?: boolean,
  ) => {
    this.setState({
      showSlidingPage: isOpen,
      clusterObj: data,
      isAddNewCluster,
    })
  }

  removeDevice = (index: number) => {
    let devices = this.state.devices
    devices.splice(index, 1)
    this.setState({
      devices,
    })
    this.props.handleChange(devices)
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  validateDuplicateDeviceName = (rule: any, value: any, cb: any) => {
    const {
      storage: {
        get: { ALLDEVICES: allDevices },
      },
    } = this.context
    let isValid: boolean = true
    const isDuplicate = allDevices.some(
      (device: IObject) => device.name === value,
    )
    const userRegx = new RegExp(/^([a-zA-Z0-9._-]){1,50}$/)
    if ('' === value) {
      rule.message = this.Messages.DEVICE_NAME_REQUIRED
      isValid = false
    } else if (!userRegx.test(value)) {
      rule.message = this.Messages.INVALID_DEVICE_NAME
      isValid = false
    } else if (isDuplicate) {
      rule.message = this.Messages.DUPLICATE_DEVICE_NAME
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateDeviceHostPort = (
    fieldName: string,
    index: number,
    rule: any,
    value: any,
    cb: any,
  ) => {
    if (this.props.device) {
      return cb()
    }

    const {
      storage: {
        get: { ALLDEVICES: allDevices },
      },
    } = this.context

    const devices = this.state.devices
    if (allDevices && allDevices.length > 0) {
      let ipPortExists = false
      allDevices.map((device: any) => {
        if (
          device.host == devices[index].thunderIPAddress &&
          device['mgmt-port-secure'] == devices[index].port
        ) {
          ipPortExists = true
        }
      })
      if (ipPortExists) {
        rule.message = this.Messages.HOSTNAME_IP_PORT_DUP
        return cb(true)
      }
    }
    if (fieldName === 'thunderIPAddress') {
      const portErr = this.props.form.getFieldError([`port[${index}]`])
      if (portErr?.rt && portErr.rt.length > 0 && portErr.rt[0]?.length > 0) {
        portErr.rt[0].map((errMsg: string) => {
          if (errMsg === this.Messages.HOSTNAME_IP_PORT_DUP) {
            this.props.form.validateFields([`port[${index}]`])
          }
        })
      }

      this.Utilities.validateThunderIPAndHost('', rule, value, cb)
    } else if (fieldName === 'port') {
      const ipErr = this.props.form.getFieldError([
        `thunderIPAddress[${index}]`,
      ])
      if (
        ipErr?.underIPAddress &&
        ipErr.underIPAddress.length > 0 &&
        ipErr.underIPAddress[0]?.length > 0
      ) {
        ipErr.underIPAddress[0].map((errMsg: string) => {
          if (errMsg === this.Messages.HOSTNAME_IP_PORT_DUP) {
            this.props.form.validateFields([`thunderIPAddress[${index}]`])
          }
        })
      }

      this.Utilities.validatePort('', rule, value, cb)
    }
  }

  handleSave = (e: any) => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  saveCluster = () => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context

    const {
      clusterName,
      clusterDName,
      type,
      dataPlaneHA,
      description,
      clusterSetID,
      floatingIP,
      floatingIPMask,
    } = this.state.clusterObj

    const { showMessage } = this.Utilities

    const payload = {
      cluster: {
        name: clusterName,
        'display-name': clusterDName || clusterName,
        type,
        'data-plane-ha': dataPlaneHA,
        description: description !== '' ? description : undefined,
        'floating-ip': type === 'vcs' ? floatingIP : undefined,
        'floating-ip-mask': type === 'vcs' ? floatingIPMask : undefined,
        'cluster-set-id':
          (type === 'ha' ||
            (type === 'multinode' && dataPlaneHA === 'vrrpa')) &&
          clusterSetID !== ''
            ? parseInt(clusterSetID)
            : undefined,
      },
    }

    const urlInput = [provider]
    const saveCluster = this.InfrastructureService.addCluster(
      null,
      payload,
      urlInput,
    )
    saveCluster
      .then((response: any) => {
        if (response.data) {
          showMessage('SUCCESS_CLUSTER_ADD', 1, 1)
          this.setSlidingPage(false, {}, false)
          this.loadClusters(clusterName)
        }
        // to refresh the clusters
      })
      .catch((error: any) => {
        showMessage(
          'FAILURE_CLUSTER_ADD',
          0,
          1,
          error.response && error.response.data && error.response.data.response
            ? ' - ' + error.response.data.response.err.msg
            : '',
        )
        this.setSlidingPage(false, {}, false)
        // to check the error
      })
  }

  handleClusterChange = (data: any, isEdit: boolean) => {
    // @ts-ignore
    this.setState({
      clusterObj: data,
    })
  }

  render() {
    const that = this
    const devices = this.state.devices

    const cluster = this.state.cluster
      ? typeof this.state.cluster === 'object'
        ? this.state.cluster
        : JSON.parse(this.state.cluster)
      : undefined
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 15 },
    }

    const { renderDropdownOpts } = this.Utilities
    const { getFieldDecorator } = this.props.form

    const { showSlidingPage, clusterObj } = this.state
    const { hideDevicePanel } = this.props
    const clusterData =
      cluster ||
      (!this.props.device && devices && devices[0] && devices[0].cluster
        ? JSON.parse(devices[0].cluster)
        : '')
    const clusterFormData = {
      clusterName: clusterData ? clusterData.name : '',
      clusterDName: clusterData
        ? clusterData['display-name'] || clusterData.name
        : '',
      type: clusterData ? clusterData.type : '',
      dataPlaneHA: clusterData
        ? clusterData.dataPlaneHA || clusterData['data-plane-ha']
        : '',
      description: clusterData ? clusterData.description : '',
      clusterSetID: clusterData ? clusterData['cluster-set-id'] : '',
      floatingIP: clusterData ? clusterData['floating-ip'] : '',
      floatingIPMask: clusterData ? clusterData['floating-ip-mask'] : '',
    }

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <div className="margin-v-10">
          <ClusterPanel
            cluster={this.props.cluster}
            form={this.props.form}
            formData={clusterFormData}
            onRef={this.props.onRef}
            handleChange={this.handleChange}
            device={this.props.device}
            devices={devices}
            deviceAddForm={true}
            clusterSearch={this.clusterSearch}
            filteredClustersList={this.state.filteredClustersList}
          />
        </div>
        {devices.map((device: IDevice, i: number) => {
          const {
            deviceName,
            thunderIPAddress,
            mgmtInterface,
            port,
            zone,
            region,
            location,
            userName,
            userPassword,
          } = device

          return (
            <div className="margin-v-10" key={'device-' + i}>
              <A10Panel
                title={
                  <>
                    <A10IconTextGroup
                      text={
                        <>
                          {clusterFormData &&
                          (clusterFormData.type === 'vcs' ||
                            clusterFormData.type === 'ha' ||
                            clusterFormData.type === 'multinode')
                            ? 'Device ' + (i + 1) + ' Info'
                            : clusterFormData.type === 'vcs'
                              ? 'VCS Master Device Info'
                              : 'Device Info'
                            }
                          <HelpInfo
                            customCls="custom-tooltip-device-info"
                            placement="right"
                            title={
                              clusterFormData &&
                              (clusterFormData.type === 'vcs' ||
                                clusterFormData.type === 'ha' ||
                                clusterFormData.type === 'multinode')
                                ? 'Device ' + (i + 1) + ' Info'
                                : clusterFormData.type === 'vcs'
                                  ? 'VCS Master Device Info'
                                  : 'Device Info'
                            }
                            helpKey="HELP_INFRA_CLUSTER_DEVICE_INFO"
                          />
                        </>
                      }
                      icon={
                        <A10Icon
                          style={{ width: 22, height: 22, marginRight: 12 }}
                          app="global"
                          type="form-section"
                          className="sliding-panel-icon"
                        />
                      }
                    />
                  </>
                }
                menu={
                  <>
                    {devices?.length > 1 && (
                      <div
                        className="pull-right add-another-link no-margin"
                        onClick={this.removeDevice.bind(this, i)}
                      >
                        <span className="a10-icon remove" />
                        <span className="label">Remove</span>
                      </div>
                    )}
                  </>
                }
                key={i}
              >
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span className="mandatoryField">
                        {this.Messages.DEVICE_NAME}
                      </span>
                      <span style={{ marginLeft: '-20px' }}>
                        <HelpInfo
                          placement="leftTop"
                          title="Device Name * "
                          helpKey="HELP_INFRA_DEVICE_NAME"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator(`deviceName[${i}]`, {
                    rules: [
                      {
                        validator: !!this.props.device
                          ? true
                          : this.validateDuplicateDeviceName.bind(this),
                      },
                    ],
                    initialValue: deviceName,
                  })(
                    <A10Input
                      disabled={!!this.props.device}
                      type="text"
                      placeholder={
                        !!this.props.device ? '' : this.Messages.DEVICE_NAME
                      }
                      onChange={(e: any) =>
                        this.handleChange('deviceName', e, i)
                      }
                    />,
                  )}
                </A10Form.Item>

                <A10Form.Item
                  key={'thunderIPAddress-item-' + i}
                  name={`thunderIPAddress[${i}]`}
                  {...formItemLayout}
                  label={
                    <>
                      <span className="mandatoryField">
                        {this.Messages.THUNDER_IP_ADDR}
                      </span>{' '}
                      <span style={{ marginLeft: '-8px' }}>
                        <HelpInfo
                          placement="leftTop"
                          title="Thunder IP Address * "
                          helpKey="HELP_INFRA_THUNDER_IP_ADDRESS"
                        />
                      </span>
                    </>
                  }
                >
                  <div className="row">
                    <div className="col-md-5">
                      {getFieldDecorator(`thunderIPAddress[${i}]`, {
                        validateTrigger: 'onBlur',
                        rules: [
                          {
                            validator: this.validateDeviceHostPort.bind(
                              this,
                              'thunderIPAddress',
                              i,
                            ),
                          },
                        ],
                        initialValue: thunderIPAddress,
                      })(
                        <A10Input
                          type="text"
                          placeholder={!!this.props.device ? '' : 'Hostname'}
                          onChange={(e: any) =>
                            this.handleChange('thunderIPAddress', e, i)
                          }
                          disabled={!!this.props.device}
                        />,
                      )}
                    </div>
                    <div className="col-md-7 no-padding">
                      {
                        <>
                          <span className="mgntInFt">
                            Use Management Interface
                          </span>
                          <span>
                            {' '}
                            <HelpInfo
                              placement="top"
                              title="Use Management Interface"
                              helpKey="HELP_INFRA_MANAGEMENT_INTERFACE"
                            />
                          </span>
                        </>
                      }
                      <A10Switch
                        defaultChecked={mgmtInterface}
                        size="small"
                        onChange={(e: any) =>
                          this.handleChange('mgmtInterface', e, i)
                        }
                        className="mgntInSwitch"
                        disabled={!!this.props.device}
                      />
                    </div>
                  </div>
                </A10Form.Item>

                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      {this.Messages.PORT}
                      <span className="mandatoryField">
                        <HelpInfo
                          placement="leftTop"
                          title="Port"
                          helpKey="HELP_INFRA_PORT"
                        />
                      </span>
                    </>
                  }
                  name={`port[${i}]`}
                  key={'port-item-' + i}
                >
                  <div className="row">
                    <div className="col-md-4">
                      {getFieldDecorator(`port[${i}]`, {
                        validateTrigger: 'onBlur',
                        rules: [
                          {
                            validator: this.validateDeviceHostPort.bind(
                              this,
                              'port',
                              i,
                            ),
                          },
                        ],
                        initialValue: port,
                      })(
                        <A10Input
                          type="text"
                          placeholder={
                            !!this.props.device ? '' : this.Messages.PORT
                          }
                          onChange={(e: any) => this.handleChange('port', e, i)}
                          disabled={!!this.props.device}
                        />,
                      )}
                    </div>
                  </div>
                </A10Form.Item>

                {!!this.props.device ? null : (
                  <>
                    <A10Form.Item
                      key={'userName-item-' + i}
                      {...formItemLayout}
                      label={
                        <>
                          <span className="mandatoryField">
                            {this.Messages.ADMIN_USERNAME}
                          </span>
                          <span style={{ marginLeft: '-26px' }}>
                            <HelpInfo
                              placement="leftTop"
                              title="Admin User Name * "
                              helpKey="HELP_INFRA_ADMIN_USER_NAME"
                            />
                          </span>
                        </>
                      }
                    >
                      {getFieldDecorator(`userName[${i}]`, {
                        rules: [
                          {
                            required: true,
                            message: this.Messages.USERNAME_REQUIRED,
                          },
                        ],
                        initialValue: userName,
                      })(
                        <A10Input
                          type="text"
                          placeholder={this.Messages.ADMIN_USERNAME}
                          onChange={(e: any) =>
                            this.handleChange('userName', e, i)
                          }
                          disabled={!!this.props.device}
                        />,
                      )}
                    </A10Form.Item>

                    <A10Form.Item
                      key={'password-item-' + i}
                      {...formItemLayout}
                      label={
                        <>
                          <span className="mandatoryField">
                            {this.Messages.ADMIN_PASSWORD}
                          </span>
                          <span style={{ marginLeft: '-24px' }}>
                            <HelpInfo
                              placement="leftTop"
                              title="Admin Password * "
                              helpKey="HELP_INFRA_ADMIN_USER_PASSWORD"
                            />
                          </span>
                        </>
                      }
                    >
                      {getFieldDecorator(`userPassword[${i}]`, {
                        rules: [
                          {
                            required: true,
                            message: this.Messages.USER_PASSWORD_REQUIRED,
                          },
                        ],
                        initialValue: userPassword,
                      })(
                        <A10Input
                          type="password"
                          placeholder={this.Messages.ADMIN_PASSWORD}
                          onChange={(e: any) =>
                            this.handleChange('userPassword', e, i)
                          }
                          disabled={!!this.props.device}
                        />,
                      )}
                    </A10Form.Item>
                  </>
                )}

                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      {this.Messages.REGION}
                      <span style={{ marginLeft: '-2px' }}>
                        <HelpInfo
                          placement="leftTop"
                          title={this.Messages.REGION}
                          helpKey="HELP_INFRA_REGION"
                        />
                      </span>
                    </>
                  }
                  key={'region-item-' + i}
                >
                  {getFieldDecorator(`region[${i}]`, {
                    rules: [
                      // { required: true, message: this.Messages.REGION_REQUIRED },
                    ],
                    initialValue: region,
                  })(
                    <A10Select
                      showSearch={true}
                      placeholder={!!this.props.device ? '' : 'Please select'}
                      onChange={(e: any) => this.handleChange('region', e, i)}
                    >
                      {renderDropdownOpts(this.Countries, null, 'name')}
                    </A10Select>,
                  )}
                </A10Form.Item>

                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      {this.Messages.ZONE}
                      <span style={{ marginLeft: '-2px' }}>
                        <HelpInfo
                          placement="leftTop"
                          title={this.Messages.ZONE}
                          helpKey="HELP_INFRA_ZONE"
                        />
                      </span>
                    </>
                  }
                  key={'zone-item-' + i}
                >
                  {getFieldDecorator(`zone[${i}]`, {
                    rules: [
                      {
                        required: region && JSON.parse(region)?.name,
                        message: this.Messages.ZONE_REQUIRED,
                      },
                    ],
                    initialValue: zone,
                  })(
                    <CitySelect
                      region={region}
                      initialValue={zone}
                      onChange={(e: any) => this.handleChange('zone', e, i)}
                    />,
                  )}
                </A10Form.Item>

                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      {this.Messages.LOCATION}
                      <span style={{ marginLeft: '-12px' }}>
                        <HelpInfo
                          placement="leftTop"
                          title={this.Messages.LOCATION}
                          helpKey="HELP_INFRA_LOCATION"
                        />
                      </span>
                    </>
                  }
                  key={'location-item-' + i}
                >
                  {getFieldDecorator(`location[${i}]`, {
                    rules: [
                      // { required: true, message: this.Messages.LOCATION_REQUIRED },
                    ],
                    initialValue: location,
                  })(
                    <A10Input
                      // key={'location-' + i}
                      // id={'location-' + i}
                      type="text"
                      placeholder={
                        !!this.props.device ? '' : this.Messages.LOCATION
                      }
                      onChange={(e: any) => this.handleChange('location', e, i)}
                    />,
                  )}
                </A10Form.Item>
              </A10Panel>
            </div>
          )
        })}
        {!hideDevicePanel &&
          ((clusterFormData.type === 'multinode' &&
            clusterFormData.dataPlaneHA === 'vrrpa' &&
            devices.length < 2) ||
            (clusterFormData.type === 'multinode' &&
              clusterFormData.dataPlaneHA === 'scaleout' &&
              devices.length < 8)) && (
            <A10Button
              type="secondary"
              className='add-another-btn'
              onClick={this.addAnotherDevice}
            >
              <A10Icon app="global" type="add-new" />
              Add Another Device
            </A10Button>
          )}
        <FormatSlidingPage
          isOpen={showSlidingPage}
          onRequestOk={this.handleSave}
          onRequestClose={this.setSlidingPage.bind(this, false, {}, false)}
          title={this.slidingTitle}
          description={this.slidingDesc}
        >
          <ClusterAddForm
            formData={clusterObj}
            cluster={null}
            handleChange={this.handleClusterChange}
            onSubmitForm={this.saveCluster}
            onRef={(ref: any): any => (this.childForm = ref)}
            hideDevicePanel={true}
          />
        </FormatSlidingPage>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(DeviceAddForm))
