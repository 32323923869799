import React from 'react'
import { A10Container, _ } from '@gui-libraries/framework'
import {
  A10Alert,
  A10Row,
  A10Col,
  A10Collapse,
  A10Select,
} from '@gui-libraries/widgets'

import { DiffComparator } from 'src/components/shared/DiffComparator'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import { flattenFileArray } from 'src/libraries/arrayUtils'

import './styles/index.module.less'
export interface IPartitionDeployAllProps {
  partition: IObject
  onFormChange?: (checked: boolean, data: IObject) => void
  onVersionChange: (devicePartition: IPartitionVersion[]) => void
  showValidationAlert: boolean
  validationError: string
  closeValidationAlert: () => void
}

export interface IPartitionVersion {
  uuid: string
  leftComment: string
  rightComment: string
  leftConfig: string
  rightConfig: string
  leftVersion?: number
  rightVersion?: number
}

export interface IPartitionDeployAllState {
  LogicalPartitionVersion: IPartitionVersion
  devicePartitionVersions: IPartitionVersion[]
  partitionList: IObject[]
  fileList: IObject[]
  showValidationAlert: boolean
  validationError: string
}

class PartitionDeployAll extends A10Container<
  IPartitionDeployAllProps,
  IPartitionDeployAllState
> {
  constructor(props: IPartitionDeployAllProps) {
    super(props)
    const dpVersions = []
    const vlen = props.partition.deviceList
      ? props.partition.deviceList.length
      : 0
    for (let i = 0; i < vlen; i++) {
      dpVersions.push(null)
    }
    this.state = {
      LogicalPartitionVersion: {
        uuid: '',
        leftComment: '',
        rightComment: '',
        leftConfig: '',
        rightConfig: '',
      },
      devicePartitionVersions: dpVersions,
      partitionList: [],
      fileList: [],
      showValidationAlert: false,
      validationError: '',
    }
  }

  componentDidMount() {
    const { partition } = this.props
    const res = this.getVersionData(partition)
    Promise.all(res[0]).then(() => {
      this.setState({ LogicalPartitionVersion: res[1] })
    })
    this.getVersionFileList().then(result => {
      this.setState({ fileList: flattenFileArray(result.data) })
    })
    const keyword = `LogicalPartition_${partition.uuid}`
    const clusterPartition = storage.getItem(keyword)
    let partitionList = []
    if (clusterPartition) {
      const cp = clusterPartition.split('$$&&')
      const pName = cp[1]
      partitionList = partition.deviceList.map(device => {
        const copiedDevice = _.cloneDeep(device)
        const p = device['partition-list'].filter(
          item => item.name === pName,
        )[0]
        p['device-name'] = device.name
        p.parent = copiedDevice
        p.checked = false
        return p
      })
      this.setState({ partitionList })
    }
  }

  componentDidUpdate(prevProps: IPartitionDeployAllProps) {
    if (
      prevProps.showValidationAlert !== this.props.showValidationAlert ||
      prevProps.validationError !== this.props.validationError
    ) {
      this.setState({
        showValidationAlert: this.props.showValidationAlert,
        validationError: this.props.validationError,
      })
    }
  }

  getVersionFileList = () => {
    const fileUri = this.props.partition['a10-url'] + '/file'
    return httpClient.get(fileUri)
  }

  getVersionData = (partition: IObject, version?: number) => {
    const partitionVersion = {
      uuid: partition.uuid,
      leftComment: '',
      rightComment: '',
      leftConfig: '',
      rightConfig: '',
      leftVersion: partition['running-version'] || 0,
      rightVersion: version
        ? version
        : partition.version
        ? partition.version
        : 0,
    }

    let vNum = 0
    const promises = []
    if (partition['running-version']) {
      promises.push(
        this.getConfigfromVersion(
          partition.uuid,
          partition['running-version'],
        ).then(result => {
          partitionVersion.leftConfig = result.data
        }),
      )
      promises.push(
        this.getVersionDetail(
          partition.uuid,
          partition['running-version'],
        ).then(result => {
          partitionVersion.leftComment = result.data['hcms-version'].comments
        }),
      )
    }
    if (partition.version || partition['candidate-version']) {
      const id = partition.uuid
      if (version) {
        vNum = version
      } else if (partition['candidate-version']) {
        vNum = partition['candidate-version']
      } else if (partition.version) {
        vNum = partition.version
      }
      promises.push(
        this.getConfigfromVersion(id, vNum).then(result => {
          partitionVersion.rightConfig = result.data
        }),
      )
      promises.push(
        this.getVersionDetail(id, vNum).then(result => {
          partitionVersion.rightComment = result.data['hcms-version'].comments
        }),
      )
    }
    return [promises, partitionVersion]
  }

  getConfigfromVersion = async (id: string, version: number) => {
    const uri = `/hpcapi/v3/blob/${id}+${version}`
    return httpClient.get(uri)
  }

  getVersionDetail = (id: string, versionNum: number) => {
    const uri = `/hpcapi/v3/hcms-version/${id}+${versionNum}`
    return httpClient.get(uri)
  }

  onChangeDPVersion = (partition: IObject, index: number, version: number) => {
    const { devicePartitionVersions } = this.state
    // const key = `${partition.uuid}_v_${version}`
    // if (!getItem(key)) {
    const res = this.getVersionData(partition, version)
    Promise.all(res[0]).then(() => {
      devicePartitionVersions[index] = res[1]
      this.setState({ devicePartitionVersions }, () => {
        this.props.onVersionChange(devicePartitionVersions)
      })
      // setItem(key, res[1], true)
    })
    // } else {
    //   const partitionVersion = getItem(key, true)
    //   devicePartitionVersions[index] = partitionVersion
    //   this.setState({ devicePartitionVersions }, () => {
    //     this.props.onVersionChange(devicePartitionVersions)
    //   })
    // }
  }

  renderVersionOptions = (partition: IObject, index: number) => {
    // control left or right
    let defaultVersion = partition.version
    if (partition['candidate-version']) {
      defaultVersion = partition['candidate-version']
    }
    const versionCount = partition.version
    const versions = _.range(1, versionCount + 1)
    const options = versions.map(version => {
      return (
        <A10Select.Option key={version} value={version}>
          <span>{'v ' + version}</span>
          {version == defaultVersion &&
          version != partition['running-version'] ? (
            <span className="candidate-version-box">Candidate</span>
          ) : null}
          {version == partition['running-version'] ? (
            <span className="running-version-box">Running</span>
          ) : null}
        </A10Select.Option>
      )
    })
    return (
      <A10Select
        style={{ width: '20%', minWidth: 'min-content' }}
        onChange={(version: number) =>
          this.onChangeDPVersion(partition, index, version)
        }
        value={
          this.state.devicePartitionVersions[index]
            ? this.state.devicePartitionVersions[index].rightVersion
            : defaultVersion
        }
        disabled={true}
      >
        {options}
      </A10Select>
    )
  }

  renderLPRecord = () => {
    return (
      <A10Row>
        <A10Col style={{ paddingLeft: '20px' }} span={20}>
          <DiffComparator
            id="lp_1"
            type="deploy"
            leftConfig={this.state.LogicalPartitionVersion.leftConfig}
            rightConfig={this.state.LogicalPartitionVersion.rightConfig}
            leftComment={this.state.LogicalPartitionVersion.leftComment}
            rightComment={this.state.LogicalPartitionVersion.rightComment}
            showFileList={true}
            fileList={this.state.fileList}
            isLoadingLeft={
              this.props.partition['running-version'] &&
              !!!this.state.LogicalPartitionVersion.leftConfig
            }
            isLoadingRight={
              this.props.partition['candidate-version'] &&
              !!!this.state.LogicalPartitionVersion.rightConfig
            }
          />
        </A10Col>
      </A10Row>
    )
  }

  // togglePartition = (checked: boolean, data: IObject, index: number) => {
  //   const { partitionList } = this.state
  //   partitionList[index].checked = checked
  //   this.setState({ partitionList })
  //   this.props.onFormChange(checked, data)
  // }

  renderDPRecords = () => {
    const { partitionList } = this.state
    if (partitionList) {
      const dpRecords = partitionList.map((data: IObject, index: number) => {
        const DPHeader = () => {
          return (
            <A10Row>
              <A10Col span={20}>
                <div style={{ display: 'inline', marginRight: 10 }}>
                  <span style={{ marginRight: 5 }}>{'Device Partition:'}</span>
                  <HealthStatus type="ongoing" text="D" />
                  <span style={{ marginLeft: 5, marginRight: 5 }}>
                    {' '}
                    {`${data['device-name']} :`}
                  </span>
                  <HealthStatus type="ongoing" text="P" />
                  <span style={{ marginLeft: 5 }}>{data.name}</span>
                </div>
                <div
                  style={{ display: 'inline' }}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  {this.renderVersionOptions(data, index)}
                </div>
              </A10Col>
              {storage.get.ADMIN_LEVEL === 'provider' ? (
                <A10Col
                  span={4}
                  onClick={(e: MouseEvent) => {
                    e.stopPropagation()
                  }}
                >
                  {/* {data.dirty ? <span className={styles.warningIcon} title="Save before deployment" /> : null}
                    <span>Deploy</span>
                    <A10Switch
                      disabled={data.dirty}
                      checked={this.state.partitionList[index].checked}
                      onChange={(checked: any) => {
                        this.togglePartition(checked, data, index)
                      }}
                      style={{ marginLeft: 5 }} /> */}
                </A10Col>
              ) : null}
            </A10Row>
          )
        }
        return (
          <A10Collapse.Panel
            key={index}
            header={<DPHeader />}
            style={{ marginBottom: 6 }}
          >
            <div className="diff-comparator">
              {this.renderDPRecord(data, index)}
            </div>
          </A10Collapse.Panel>
        )
      })

      return <A10Collapse>{dpRecords}</A10Collapse>
    } else {
      return null
    }
  }

  renderDPRecord = (partition: IObject, index: number) => {
    const { devicePartitionVersions } = this.state
    try {
      httpClient.get(partition['a10-url']).then(result => {
        if (devicePartitionVersions[index] === null) {
          const res = this.getVersionData(result.data.partition)
          Promise.all(res[0]).then(() => {
            // const key = `${partition.uuid}_v_${res[1].rightVersion}`
            // setItem(key, res[1], true)
            devicePartitionVersions[index] = res[1]
            this.setState({ devicePartitionVersions })
            this.props.onVersionChange(devicePartitionVersions)
          })
        }
      })
    } catch (err) {
      console.error(err)
    }
    const isLoadingLeft =
      !!!devicePartitionVersions[index] ||
      (devicePartitionVersions[index] &&
        devicePartitionVersions[index].leftVersion &&
        !devicePartitionVersions[index].leftConfig)
        ? true
        : false
    const isLoadingRight =
      !!!devicePartitionVersions[index] ||
      (devicePartitionVersions[index] &&
        devicePartitionVersions[index].rightVersion &&
        !devicePartitionVersions[index].rightConfig)
        ? true
        : false
    return (
      <A10Row>
        <A10Col style={{ paddingLeft: '20px' }} span={20}>
          <DiffComparator
            id={`dp_${index}`}
            type="deploy"
            leftConfig={
              devicePartitionVersions[index]
                ? devicePartitionVersions[index].leftConfig
                : ''
            }
            rightConfig={
              devicePartitionVersions[index]
                ? devicePartitionVersions[index].rightConfig
                : ''
            }
            leftComment={
              devicePartitionVersions[index]
                ? devicePartitionVersions[index].leftComment
                : ''
            }
            rightComment={
              devicePartitionVersions[index]
                ? devicePartitionVersions[index].rightComment
                : ''
            }
            isLoadingLeft={isLoadingLeft}
            isLoadingRight={isLoadingRight}
            showFileList={false}
          />
        </A10Col>
      </A10Row>
    )
  }

  render() {
    const DeployLPWindowTitle = () => {
      return (
        <span
          style={{ marginLeft: 5 }}
        >{`Logical Partition: ${this.props.partition.name}`}</span>
      )
    }

    return (
      <>
        {this.state.showValidationAlert ? (
          <A10Alert
            showIcon={true}
            style={{ marginBottom: '5px' }}
            description={
              <div>
                <div>Error Details:</div>
                <div className="alert-text">{this.state.validationError}</div>
              </div>
            }
            type="error"
            closable
            onClose={this.props.closeValidationAlert}
          />
        ) : null}
        <A10Collapse defaultActiveKey={['0']} style={{ marginBottom: 6 }}>
          <A10Collapse.Panel header={<DeployLPWindowTitle />} key="0">
            <div className="diff-comparator">{this.renderLPRecord()}</div>
          </A10Collapse.Panel>
        </A10Collapse>
        {this.renderDPRecords()}
      </>
    )
  }
}

export default PartitionDeployAll
