import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import { A10Icon, A10Form, A10Input } from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { Utilities } from 'src/services/Utilities'
import { Messages } from 'src/locale/en/Messages'
const styles = require('./styles/thunderappedit.scss')

export interface IThunderAppEditProps extends IA10ContainerDefaultProps {
  thunderApp: any
  form: any
  onRef?: any
  onSubmitForm?: (data: any) => void
  handleChange?: (data: any) => void
}
export interface IThunderAppEditState {
  appName: string
  appDName: string
  thunderApp: any
}

class ThunderAppEdit extends A10Container<
  IThunderAppEditProps,
  IThunderAppEditState
> {
  Messages = new Messages()
  Utilities = new Utilities()
  constructor(props: any) {
    super(props)
    this.state = {
      appName: this.props.thunderApp.name,
      appDName: this.props.thunderApp.displayName,
      thunderApp: this.props.thunderApp,
    }
  }

  handleChange = (e: any) => {
    let displayName = ''
    if (e.target) {
      if (e.target.type === 'checkbox') {
        displayName = e.target.checked
      } else {
        displayName = e.target.value
      }
    } else {
      displayName = e
    }

    this.props.handleChange(displayName)
    this.setState({ appDName: displayName })
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  render() {
    const formItemLayoutHeader = {
      labelCol: { span: 9 },
      wrapperCol: { span: 14 },
    }
    const { getFieldDecorator } = this.props.form

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel
          title={
            <>
              <A10IconTextGroup
                text="App Service Instance"
                icon={
                  <A10Icon
                    style={{ width: 22, height: 22, marginRight: 12 }}
                    app="global"
                    type="form-section"
                    className="sliding-panel-icon"
                  />
                }
              />
            </>
          }
        >
          <A10Form.Item
            {...formItemLayoutHeader}
            label={
              <>
                <span className="mandatoryField">
                  {this.Messages.APP_SVC_ID}
                </span>{' '}
                <span style={{ marginLeft: '-18px' }}></span>
              </>
            }
          >
            <div className="row">
              <div className="col-md-12">
                {getFieldDecorator('appName', {
                  initialValue: this.state.appName,
                })(<A10Input disabled={true} type="text" />)}
              </div>
            </div>
          </A10Form.Item>
          <A10Form.Item
            {...formItemLayoutHeader}
            label={
              <>
                <span className="mandatoryField">
                  {this.Messages.DISPLAY_NAME}
                </span>{' '}
                <span style={{ marginLeft: '-18px' }}></span>
              </>
            }
          >
            <div className="row">
              <div className="col-md-12">
                {getFieldDecorator('appDName', {
                  rules: [
                    {
                      required: true,
                      message: this.Messages.ENTER_DISPLAY_NAME,
                    },
                    {
                      pattern: this.Utilities.DISPLAYNAME_PATTERN,
                      message: this.Messages.INVALID_DISPLAY_NAME,
                    },
                  ],
                  initialValue: this.state.appDName,
                })(
                  <A10Input
                    type="text"
                    placeholder={this.Messages.DISPLAY_NAME}
                    onChange={this.handleChange.bind(this)}
                  />,
                )}
              </div>
            </div>
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(ThunderAppEdit))
