import React from 'react'

import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import {
  A10Icon,
  A10Form,
  A10Input,
  A10Row,
  A10Select,
  A10DatePicker,
  A10AflexCodeEditor,
} from '@gui-libraries/widgets'
import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { HelpInfo } from 'src/components/shared/HelpInfo'

import { ClusterDevicePartition } from '../ClusterDevicePartition'
import { Messages } from 'src/locale/en'
import { DashboardService, Utilities } from 'src/services'
import moment from 'moment-timezone'
const styles = require('../../RunCommands/styles/index.module.less')

export interface IDeviceCmdRunFormProps extends IA10ContainerDefaultProps {
  runCmdScheduleTasks: any[]
  runCmdSchedule: any
  cmdSnippets: any[]
  form: any
  onRef?: any
  editTask: boolean
  handleChange(key: string, value: any): void
  devicesDetail: IObject[]
  tenantDetail: IObject
}
export interface IDeviceCmdRunFormState {
  filteredCmdSnippets: any[]
  timezones: IObject[]
}

class DeviceCmdRunForm extends A10Container<
  IDeviceCmdRunFormProps,
  IDeviceCmdRunFormState
> {
  Messages = new Messages()
  DashboardService = new DashboardService()
  Utilities = new Utilities()

  constructor(props: IDeviceCmdRunFormProps) {
    super(props)
    this.state = {
      filteredCmdSnippets: this.props.cmdSnippets,
      timezones: [],
    }
  }

  componentDidMount() {
    this.loadTimezones()
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  loadTimezones() {
    const timezoneResponse = this.DashboardService.getTimezones(
      null,
      null,
      null,
    )
    timezoneResponse
      .then((response: any) => {
        if (response?.data?.timezones && response.data.timezones.length > 0) {
          const { runCmdSchedule } = this.props
          if (!runCmdSchedule.timezone) {
            const timezoneStr = runCmdSchedule.timeZoneOffset
              ? this.DashboardService.getTimeZoneString(
                  response.data.timezones,
                  runCmdSchedule.timeZoneOffset,
                )
              : this.DashboardService.getDefaultBrowserTimeZone(
                  response.data.timezones,
                )

            const timezoneObj = JSON.parse(timezoneStr)
            moment.tz.setDefault(timezoneObj.value)
            const startsOn = runCmdSchedule?.startsOn
              ? moment(runCmdSchedule.startsOn)
              : moment()
            this.props.handleChange('startsOn', startsOn)
            this.props.handleChange('timezone', timezoneStr)
          } else {
            const timezoneObj = JSON.parse(runCmdSchedule.timezone)
            moment.tz.setDefault(timezoneObj.value)
            const startsOn = moment(runCmdSchedule.startsOn)
            this.props.handleChange('startsOn', startsOn)
          }
          this.setState({ timezones: response.data.timezones })
        }
      })
      .catch((error: any) => {
        const message = this.Utilities.returnErrorMessage(error)
        this.Utilities.showMessage('Unable to load timezones', 0, 0, message)
      })
  }

  validateDuplicateTaskName = (rule: any, taskName: any, cb: any) => {
    let isValid: boolean = true
    const index = this.props.runCmdScheduleTasks.findIndex((key: any) => {
      return key.name === taskName && this.props.editTask !== taskName
    })
    if ('' === taskName) {
      rule.message = 'Please enter the task name'
      isValid = false
    } else if (!this.Utilities.validateName(taskName)) {
      rule.message =
        "Please enter a valid task name, it cannot exceed 30 letters and no special characters (except '-', '_', '.')"
      isValid = false
    } else if (index > -1) {
      rule.message = 'Task with same name already exists.'
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  devicePartitionChange = (devicePartitions: any[]) => {
    this.props.handleChange('devicePartitions', devicePartitions)
  }

  cmdSnippetSearch = (value: any, option?: any) => {
    const filteredCmdSnippets =
      this.props.cmdSnippets &&
      this.props.cmdSnippets.filter((obj: any) => {
        return obj.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      })

    this.setState({ filteredCmdSnippets })
  }

  onDateChange(dateData: any, dateString: string) {
    // const startDate = {
    //   day: dateData && dateData.day(),
    //   date: dateData && dateData.date(),
    //   month: dateData && dateData.month(),
    //   year: dateData && dateData.year(),
    //   hour: dateData && dateData.hour(),
    //   minute: dateData && dateData.minute(),
    //   dateStr: dateString
    // }

    this.props.handleChange('startsOn', dateData)
  }

  onTimezoneChange = (timezone: any) => {
    const timezoneObj = JSON.parse(timezone)
    moment.tz.setDefault(timezoneObj.value)

    const startsOn = moment()
    this.props.handleChange('startsOn', startsOn)
    this.props.handleChange('timezone', timezone)
  }

  render() {
    const {
      handleChange,
      runCmdSchedule,
      cmdSnippets,
      editTask,
      devicesDetail,
      tenantDetail,
    } = this.props
    const {
      name,
      devicePartitions,
      cliSnippet,
      when,
      frequency,
      startsOn,
      timezone,
    } = runCmdSchedule

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    }
    const { getFieldDecorator } = this.props.form

    const selectedCmdSnippet = cliSnippet && {
      ...cmdSnippets?.find((cmdSnippet: any, i: number) => {
        return JSON.parse(cliSnippet).id === cmdSnippet.id
      }),
    }

    const selCmdSnippet =
      cliSnippet &&
      this.state.filteredCmdSnippets &&
      this.state.filteredCmdSnippets.find((cmdSnippet: any, i: number) => {
        return JSON.parse(cliSnippet).id === cmdSnippet.id
      })

    if (selectedCmdSnippet) {
      const contentSplit: string[] = []
      try {
        selectedCmdSnippet.content = JSON.parse(selectedCmdSnippet.content)
      } catch {
        selectedCmdSnippet.content = selectedCmdSnippet.content
      }
      selectedCmdSnippet.content.map((cmd: string) => {
        contentSplit.push(cmd.trim())
      })
      selectedCmdSnippet.contentSplit = contentSplit.join('\r\n')
    }

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel className={styles.panelBodyPad} showHeader={false}>
          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>{this.Messages.NAME}</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="left"
                    title={this.Messages.NAME}
                    helpKey="HELP_TROUBLESHOOT_RUNCMD_TASK_NAME"
                  />
                </span>
              </>
            }
          >
            <div>
              {getFieldDecorator('name', {
                rules: [
                  {
                    validator: this.validateDuplicateTaskName.bind(this),
                  },
                ],
                initialValue: name,
              })(
                <A10Input
                  type="text"
                  placeholder={this.Messages.TASK_NAME_PH}
                  onChange={handleChange.bind(this, 'name')}
                  disabled={editTask}
                />,
              )}
            </div>
          </A10Form.Item>

          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                <span>{this.Messages.CMD_SNIPPET}</span>
                <span style={{ marginLeft: '-20px' }}>
                  <HelpInfo
                    placement="left"
                    title={this.Messages.CMD_SNIPPET}
                    helpKey="HELP_TROUBLESHOOT_RUNCMD_CMD_SNIPPET"
                  />
                </span>
              </>
            }
          >
            {getFieldDecorator('cliSnippet', {
              rules: [
                { required: true, message: 'Please select command snippet' },
              ],
              initialValue: !!selCmdSnippet
                ? JSON.stringify(selCmdSnippet)
                : undefined,
            })(
              <A10Select
                title={selectedCmdSnippet && selectedCmdSnippet.name}
                onChange={handleChange.bind(this, 'cliSnippet')}
                placeholder="Please select command snippet"
                allowClear={true}
                // value={!!selCmdSnippet ? JSON.stringify(selCmdSnippet) : undefined}
                className={styles.cmdSnippetSelect}
                showSearch={true}
                onSearch={this.cmdSnippetSearch}
              >
                {this.state.filteredCmdSnippets &&
                  this.state.filteredCmdSnippets.map(
                    (cmdSnippet: any, i: number) => {
                      return (
                        <A10Select.Option
                          key={'cmdSnippet-' + i}
                          value={JSON.stringify(cmdSnippet)}
                          title={cmdSnippet.name}
                        >
                          {cmdSnippet.name}
                        </A10Select.Option>
                      )
                    },
                  )}
              </A10Select>,
            )}
          </A10Form.Item>

          <A10Form.Item
            {...formItemLayout}
            label={
              <>
                {'Select Device Partition'}
                <span style={{ marginLeft: '-10px' }}>
                  <HelpInfo
                    placement="left"
                    title={'Select Device Partition'}
                    helpKey="HELP_TROUBLESHOOT_RUNCMD_CLUSTER"
                  />
                </span>
              </>
            }
          >
            <ClusterDevicePartition
              height={200}
              onChange={this.devicePartitionChange}
              devicePartitions={devicePartitions}
              devicesDetail={devicesDetail}
              tenantDetail={tenantDetail}
            />
          </A10Form.Item>

          {/* <A10Form.Item
            {...formItemLayout}
            label={<>
              <span >{this.Messages.WHEN}</span>
              <span style={{ marginLeft: '-20px' }}>
                <HelpInfo placement="left" title={this.Messages.WHEN} helpKey="HELP_TROUBLESHOOT_RUNCMD_WHEN" />
              </span>
            </>}>
            <div className="row">
              <div className="col-md-3">
                {getFieldDecorator('when', {
                  initialValue: when,
                })(
                  <A10Select
                    title={when}
                    onChange={handleChange.bind(this, 'when')}
                  >
                    <A10Select.Option key='now' value='now'>Now</A10Select.Option>
                    <A10Select.Option key='schedule' value='schedule'>Schedule</A10Select.Option>
                  </A10Select>
                )}
              </div>
            </div>
          </A10Form.Item> */}

          {when === 'schedule' ? (
            <>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    <span>{this.Messages.FREQUENCY}</span>
                    <span style={{ marginLeft: '-20px' }}>
                      <HelpInfo
                        placement="left"
                        title={this.Messages.FREQUENCY}
                        helpKey="HELP_TROUBLESHOOT_RUNCMD_FREQUENCY"
                      />
                    </span>
                  </>
                }
              >
                {getFieldDecorator('frequency', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select the frequency',
                    },
                  ],
                  initialValue: frequency || undefined,
                })(
                  <A10Select
                    title={when}
                    allowClear={true}
                    placeholder="Please select a frequency"
                    onChange={handleChange.bind(this, 'frequency')}
                  >
                    <A10Select.Option key="hourly" value="hourly">
                      Hourly
                    </A10Select.Option>
                    <A10Select.Option key="daily" value="daily">
                      Daily
                    </A10Select.Option>
                    <A10Select.Option key="weekly" value="weekly">
                      Weekly
                    </A10Select.Option>
                    <A10Select.Option key="monthly" value="monthly">
                      Monthly
                    </A10Select.Option>
                  </A10Select>,
                )}
              </A10Form.Item>

              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    <span>{this.Messages.STARTS_ON}</span>
                    <span style={{ marginLeft: '-20px' }}>
                      <HelpInfo
                        placement="left"
                        title={this.Messages.STARTS_ON}
                        helpKey="HELP_TROUBLESHOOT_RUNCMD_STARTSON"
                      />
                    </span>
                  </>
                }
              >
                {getFieldDecorator('startsOn', {
                  rules: [
                    { required: true, message: 'Please select the Date' },
                  ],
                  initialValue: startsOn === '' ? '' : moment(startsOn),
                })(
                  <A10DatePicker
                    onChange={this.onDateChange.bind(this)}
                    format={'YYYY-MM-DD HH:mm'}
                    showTime
                  />,
                )}
              </A10Form.Item>

              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.TIMEZONE}
                    <span>
                      <HelpInfo
                        placement="left"
                        title={this.Messages.TIMEZONE}
                        helpKey="HELP_TROUBLESHOOT_RUNCMD_TIMEZONE"
                      />
                    </span>
                  </>
                }
              >
                <A10Select
                  placeholder="Please select a timezone"
                  onChange={this.onTimezoneChange}
                  value={timezone}
                >
                  {this.state.timezones.map((option: any, i: number) => {
                    return (
                      <A10Select.Option
                        key={option.label}
                        value={JSON.stringify(option)}
                      >
                        {option.label}
                      </A10Select.Option>
                    )
                  })}
                </A10Select>
              </A10Form.Item>
            </>
          ) : null}
        </A10Panel>

        {/* <A10Panel
          className={styles.panelBodyPad}
          title={
            <A10IconTextGroup
              text={
                <>
                  Command
                </>
              }
              icon={
                <A10Icon
                  style={{ width: 22, height: 22, marginRight: 12 }}
                  app="global"
                  type="form-section"
                  className="sliding-panel-icon"
                />
              }
            />
          }
        >
          <div className={`${styles.formCmdSnippet}`}>
            <A10Row >
              <A10Select
                title={selectedCmdSnippet && selectedCmdSnippet.name}
                onChange={handleChange.bind(this, 'cliSnippet')}
                placeholder="Please select command snippet"
                allowClear={true}
                value={!!selCmdSnippet ? JSON.stringify(selCmdSnippet) : undefined}
                className={styles.cmdSnippetSelect}
                showSearch={true}
                onSearch={this.cmdSnippetSearch}
              >
                {this.state.filteredCmdSnippets && this.state.filteredCmdSnippets.map((cmdSnippet: any, i: number) => {
                  return (
                    <A10Select.Option
                      key={'cmdSnippet-' + i}
                      value={JSON.stringify(cmdSnippet)}
                      title={cmdSnippet.name}
                    >
                      {cmdSnippet.name}
                    </A10Select.Option>
                  )
                })}
              </A10Select>
            </A10Row>

            <A10Row className={`${styles.disabled} ${styles.codeEditorDiv}`}>
              <A10AflexCodeEditor
                id={''}
                className={`${styles.cmdTextContent} ${styles.disabled}`}
                value={selectedCmdSnippet && selectedCmdSnippet.contentSplit ? selectedCmdSnippet.contentSplit : ''}
                options={{ lineNumbers: true, readOnly: 'nocursor' }}
                style={{ height: 250 }}
              />
            </A10Row>
          </div>

        </A10Panel> */}
      </A10Form>
    )
  }
}
export default setupA10Container(A10Form.create()(DeviceCmdRunForm))
