import * as basicUtils from '../../../src/containers/Controller/Dashboard/utils/basicUtils'

import {
  A10Icon,
  A10Message,
  A10Select,
  A10Tooltip,
} from '@gui-libraries/widgets'

import { AppRoot } from 'src/settings/appRoot'
import { DashboardService } from 'src/services/DashboardService'
import DropdownConstants from 'src/constants/DropdownConstants/DropdownConstants'
import { Messages } from 'src/locale/en/Messages'
import React from 'react'
import { ValidationRule } from 'antd/lib/form'
import { _ } from '@gui-libraries/framework'
import axios from 'axios'
import moment from 'moment-timezone'
import parameters from 'parameters'
import storage from 'src/libraries/storage'
import { OrganizationService, TroubleShootService } from 'src/services/index'
import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { LaunchAppForm } from 'src/containers/Controller/Apps/InstalledApps/Forms/LaunchAppForm'
import { APPLICATION_NAMES_MAP } from 'src/constants/ApplicationConfigs/ApplicationNamesMap'

export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT_12HR = 'hh:mm:ss A'
export const TIME_FORMAT_24HR = 'HH:mm:ss'

export class Utilities {
  DropdownConstants = new DropdownConstants()
  DashboardService = new DashboardService()
  TroubleshootService = new TroubleShootService()
  OrganizationService = new OrganizationService()
  Messages = new Messages()
  AppRoot = new AppRoot()

  metrics = {
    quantity: {
      G: 1000 * 1000 * 1000,
      B: 1000 * 1000 * 1000,
      M: 1000 * 1000,
      K: 1000,
    },
    bytes: {
      G: 1024 * 1024 * 1024,
      M: 1024 * 1024,
      K: 1024,
    },
  }
  EMAIL_PATTERN = /^([A-z0-9._-]+@[A-z0-9._-]+\.[A-z]{2,4})$/
  NAME_PATTERN = /^[A-Za-z][a-zA-Z0-9._-]{0,29}$/
  NAME_PATTERN_SPACE_ALLOWED_30 = /^[^\s][.a-zA-Z0-9\s_-]{1,30}$/
  NAME_PATTERN_SPACE_ALLOWED_50 = /^[^\s][.a-zA-Z0-9\s_-]{1,50}$/
  DISPLAYNAME_PATTERN = /^([a-zA-Z0-9!@#$&()-_.+,/ \"]){1,50}$/
  DISPLAYNAME_PATTERN_30 = /^[A-Za-z]([a-zA-Z0-9!@#$&()-_.+,/ \"]){0,29}$/
  USERID_PATTERN = new RegExp(/^([a-zA-Z0-9,.()@_=-]){1,64}$/)
  INTEGER_PATTERN = /^\d+$/
  NOT_EMPTY_PATTERN = /([^\s])/
  IPV4_REGEX = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  // IPV6_REGEX = /^([\da-fA-F]{1,4}:){1,7}([\da-fA-F]{1,4})$/
  IPV6_ONLY_REGEX = /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/
  IPV6_REGEX = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
  VALIDHOSTNAME_REGEX = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/
  VALIDATE_PRIVATE_KEY = /-----BEGIN PRIVATE KEY-----([\s\S]*?)-----END PRIVATE KEY-----/
  VALIDATE_RANGE = /^(900)|^[9][0-9]$|^[1-8][0-9][0-9]$/
  VALIDATE_ACTIVATION_STRING = /^(?=.{1,16832}$).*|\n/
  VALIDATE_IPADDRESSES = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*(\/(\d|1\d|2\d|3[0-2]))?$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3}(\/(\d{1,2}|1[0-1]\d|12[0-8]))?)|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))|(^\*$)/
  VALIDATE_HOSTNAME = /((?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
  megaToGiga = (val: any) => {
    if (val < 1000) {
      return { val: val, unit: 'm' }
    } else {
      val = val / 1000
      return { val: parseFloat(val.toFixed(2)), unit: 'g' }
    }
  }
  kiloToMillionToBillion = (val: any) => {
    let unit = ''
    const metric = this.metrics.quantity
    if (val > metric.G) {
      val = val / metric.G
      unit = 'B'
    } else if (val > metric.M) {
      val = val / metric.M
      unit = 'M'
    } else if (val > metric.K) {
      val = val / metric.K
      unit = 'K'
    } else {
      val = !isNaN(val) && val !== 0 && val !== 'NaN' ? val : 0
    }
    return { value: val, unit }
  }
  sortString = (a: any, b: any, key: string) => {
    const aStr = a[key] ? a[key] : ''
    const bStr = b[key] ? b[key] : ''
    return aStr > bStr ? 1 : aStr < bStr ? -1 : 0
  }

  sortStringObj = (a: any, key: string, otherKey: string) => {
    const aStr = a[key] ? a[key] : ''
    const aOthStr = a[otherKey] ? a[otherKey] : ''
    return aStr > aOthStr ? 1 : aStr < aOthStr ? -1 : 0
  }

  sortNumber = (a: any, b: any, key: string) => {
    const aStr = a[key] && !isNaN(parseFloat(a[key])) ? parseFloat(a[key]) : ''
    const bStr = b[key] && !isNaN(parseFloat(b[key])) ? parseFloat(b[key]) : ''
    return aStr > bStr ? 1 : aStr < bStr ? -1 : 0
  }

  sortStringMulKeys = (a: any, b: any, key: string, otherKey: string) => {
    const aStr = a[key][otherKey] ? a[key][otherKey] : ''
    const bStr = b[key][otherKey] ? b[key][otherKey] : ''
    return aStr > bStr ? 1 : aStr < bStr ? -1 : 0
  }

  sortStringKeys = (a: any, b: any, key: string, otherKey: string) => {
    const aStr = a[key] ? a[key] : a[otherKey]
    const bStr = b[key] ? b[key] : b[otherKey]
    return aStr > bStr ? 1 : aStr < bStr ? -1 : 0
  }
  sortFullName = (a: any, b: any, key?: string) => {
    return a['first-name'] + ' ' + a['last-name'] >
      b['first-name'] + ' ' + b['last-name']
      ? 1
      : a['first-name'] + ' ' + a['last-name'] <
        b['first-name'] + ' ' + b['last-name']
      ? -1
      : 0
  }
  sortDate = (a: any, b: any, key?: string) => {
    key = key || 'createdAt'
    return Date.parse(a[key]) - Date.parse(b[key])
  }
  sortcreated_at = (a: any, b: any, key?: string) => {
    if (key === 'ascend') {
      return Date.parse(a['created_at']) > Date.parse(b['created_at'])
    } else {
      return Date.parse(b['created_at']) > Date.parse(a['created_at'])
    }
  }
  sortObj = (a: any, b: any, key: string, valProp: string) => {
    const aVal = a[key] ? a[key][valProp] : 0
    const bVal = b[key] ? b[key][valProp] : 0
    return aVal - bVal
  }
  sortArrayString = (a: any, b: any, key: string, column: number) => {
    const aStr = a[key] && a[key].length && a[key][column] ? a[key][column] : ''
    const bStr = b[key] && b[key].length && b[key][column] ? b[key][column] : ''
    return aStr > bStr ? 1 : aStr < bStr ? -1 : 0
  }
  timestampToDate = (ts: any) => {
    if (!ts) {
      return ''
    }
    const todate = new Date(parseInt(ts)).getDate()
    const tomonth = new Date(parseInt(ts)).getMonth() + 1
    const toyear = new Date(parseInt(ts)).getFullYear()
    return tomonth + '-' + todate + '-' + toyear
  }
  formatDate = (date: any, dateFormat: string) => {
    // console.log('moment(date)', moment(date))
    return moment(date).format(dateFormat)
  }
  convertUTCDateToTimezone = (utcDate: string, timezone: string) => {
    // valid Moment.js timezones: https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a
    return moment
      .utc(utcDate, `${DATE_FORMAT} ${TIME_FORMAT_24HR}`)
      .tz(timezone ?? moment.tz.guess())
      .format(`${DATE_FORMAT} ${TIME_FORMAT_12HR}`)
  }
  secondsToMinutes = (seconds: number) => {
    const min = Math.floor((seconds || 0) / 60)
    const sec = (seconds || 0) % 60
    return (min > 0 ? min + ' minutes' : '') + (sec > 0 ? sec + ' seconds' : '')
  }
  getLatestRecordFromBucket = (buckets: any) => {
    let result = 0
    if (buckets) {
      let ts = 0
      for (const key in buckets) {
        if (parseInt(key, 10) > ts) {
          ts = parseInt(key, 10)
          result = buckets[key]
        }
      }
    }
    if (isNaN(result)) {
      return 0
    }
    return result
  }
  getIntValue = (val: any, decimalDigits: any, roundOff: any) => {
    val = typeof val === 'string' ? parseFloat(val) : val
    decimalDigits = decimalDigits && decimalDigits !== 0 ? decimalDigits : 0
    let valString: any = 0
    if (val < 10) {
      // if (val < 1 && decimalDigits > 1) {
      //   decimalDigits = 2
      // } else {
      //   decimalDigits = 1
      // }

      valString = val % 1 === 0 ? val : val.toFixed(decimalDigits || 1)
      if (valString === '0.0') {
        valString = 0
      }
    } else {
      val = roundOff ? Math.round(val) : val
      val = val % 1 === 0 ? val : val.toFixed(decimalDigits || 0)
      valString = val
    }
    return valString
  }
  truncateVal = (
    val: any,
    metricType: string,
    decimalDigits?: any,
    roundOff?: any,
  ) => {
    metricType = metricType === 'bytes' ? metricType : 'quantity'
    let suffix = ''
    const metric = this.metrics[metricType]
    if (val > metric.G) {
      val = val / metric.G
      suffix = 'G'
    } else if (val > metric.M) {
      val = val / metric.M
      suffix = 'M'
    } else if (val > metric.K) {
      val = val / metric.K
      suffix = 'K'
    } else {
      val = !isNaN(val) && val !== 0 && val !== 'NaN' ? val : 0
    }
    val = val > 0 ? val : 0
    val = this.getIntValue(val, decimalDigits, roundOff)
    val = val + suffix
    return val
  }
  search = (
    scope: any,
    searchStr: string,
    searchBy?: any,
    params?: any,
    searchState?: string,
  ) => {
    searchBy = searchBy ? searchBy : 'name'
    searchState = searchState ? searchState : 'searchString'
    const storageName =
      params && params.storageName ? params.storageName : 'ALL_DATA'
    const stateName = params && params.stateName ? params.stateName : 'allData'
    let allData = [...(storage.getItem(storageName, true) || [])]

    if (params && params.storeData) {
      allData = params.storeData
    }

    allData =
      allData &&
      allData.filter((obj: any) => {
        if (searchBy?.firstKey) {
          return (
            (obj[searchBy.firstKey] &&
              obj[searchBy.firstKey]
                .toLowerCase()
                .indexOf(searchStr.toLowerCase()) > -1) ||
            (obj[searchBy.secondKey] &&
              obj[searchBy.secondKey]
                .toLowerCase()
                .indexOf(searchStr.toLowerCase()) > -1)
          )
        } else {
          const value = _.get(obj, searchBy)
          return (
            value && value.toLowerCase().indexOf(searchStr.toLowerCase()) > -1
          )
        }
      })

    scope.setState({
      [stateName]: allData,
      [searchState]: searchStr,
    })
  }

  searchUser = (
    scope: any,
    searchStr: string,
    searchBykey: string,
    searchByOtherkey: string,
    searchByAnotherkey: string,
    params?: any,
  ) => {
    searchBykey = searchBykey ? searchBykey : 'first-name'
    searchByOtherkey = searchByOtherkey ? searchByOtherkey : 'last-name'
    searchByAnotherkey = searchByAnotherkey ? searchByAnotherkey : 'user-id'
    const storageName =
      params && params.storageName ? params.storageName : 'ALL_DATA'
    const stateName = params && params.stateName ? params.stateName : 'allData'
    let allData = [...(storage.getItem(storageName, true) || [])]

    if (params && params.storeData) {
      allData = params.storeData
    }

    allData =
      allData &&
      allData.filter((obj: any) => {
        return (
          ((obj[searchBykey] || obj[searchBykey] === '') &&
            obj[searchBykey].toLowerCase().indexOf(searchStr.toLowerCase()) >
              -1) ||
          ((obj[searchByOtherkey] || obj[searchByOtherkey] === '') &&
            obj[searchByOtherkey]
              .toLowerCase()
              .indexOf(searchStr.toLowerCase()) > -1) ||
          ((obj[searchByAnotherkey] || obj[searchByAnotherkey] === '') &&
            obj[searchByAnotherkey]
              .toLowerCase()
              .indexOf(searchStr.toLowerCase()) > -1)
        )
      })

    scope.setState({
      [stateName]: allData,
      searchString: searchStr,
    })
  }

  validateEmail = (emails: any): boolean => {
    let re = this.EMAIL_PATTERN
    let hasInvalidEmail = false
    if (emails instanceof Array) {
      emails.forEach((email: string) => {
        if (!re.test(email)) {
          hasInvalidEmail = true
        }
      })
    } else {
      hasInvalidEmail = !re.test(emails)
    }
    return !hasInvalidEmail
  }

  validateMandatoryEmail: ValidationRule['validator'] = (rule, email, cb) => {
    const regex = this.EMAIL_PATTERN
    let isValid: boolean = true

    if (!email) {
      rule.message = this.Messages.CANNOT_BE_EMPTY
      isValid = false
    } else {
      if (!regex.test(email)) {
        rule.message = this.Messages.INVALID_EMAIL
        isValid = false
      }
    }

    return isValid ? cb() : cb(true)
  }

  validateNonMandatoryEmail = (
    rule: any,
    email: string = null,
    cb: any = null,
  ): boolean => {
    const regex = this.EMAIL_PATTERN
    let isValid: boolean = true

    if (!email && !cb) {
      email = rule // first argument itself is the value
      return email === '' || email === null || regex.test(email)
    }

    if (!(email === '' || email === null || regex.test(email))) {
      rule.message = this.Messages.INVALID_EMAIL
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateProviderDescription = (
    rule: any,
    description: string = null,
    cb: any = null,
  ): boolean => {
    const maxLength = 60
    let isValid: boolean = true

    if (!description && !cb) {
      description = rule // first argument itself is the description
      return description.length >= 1 && description.length <= maxLength
    }

    if (
      !description ||
      !(description.length >= 1 && description.length <= maxLength)
    ) {
      rule.message = this.Messages.INVALID_PROVIDER_DESCRIPTION
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateReportTimeRange = (
    rule: any,
    timeRange: number[] = null,
    cb: any = null,
  ): boolean => {
    let isValid: boolean = true

    const lessthanDay =
      Math.abs(timeRange[0] - timeRange[1]) < 24 * 60 * 60 * 1000

    if (lessthanDay) {
      rule.message = this.Messages.INVALID_TIME_RANGE
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateName = (rule: any, name: string = null, cb: any = null): boolean => {
    const nameRegex = this.NAME_PATTERN
    let isValid: boolean = true

    if (!name && !cb) {
      name = rule // first argument itself is the value
      return !!name && !!name.match(nameRegex)
    }

    if (name === null || !!name.match(nameRegex) === false) {
      rule.message = this.Messages.INVALID_NAME
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateName50WithSpace = (
    rule: any,
    name: string = null,
    cb: any = null,
  ): boolean => {
    const nameRegex = this.NAME_PATTERN_SPACE_ALLOWED_50
    let isValid: boolean = true

    if (!name && !cb) {
      name = rule // first argument itself is the value
      return !!name && !!name.match(nameRegex)
    }

    if (name === null || !!name.match(nameRegex) === false) {
      rule.message = this.Messages.INVALID_NAME_SPACE_50
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateNameWithSpace = (
    rule: any,
    name: string = null,
    cb: any = null,
  ): boolean => {
    const nameRegex = this.NAME_PATTERN_SPACE_ALLOWED_30
    let isValid: boolean = true

    if (!name && !cb) {
      name = rule // first argument itself is the value
      return !!name && !!name.match(nameRegex)
    }

    if (name === null || !!name.match(nameRegex) === false) {
      rule.message = this.Messages.INVALID_NAME_SPACE_30
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateDisplayName = (
    rule: any,
    name: string = null,
    cb: any = null,
  ): boolean => {
    let isValid: boolean = true

    if (!name && !cb) {
      name = rule // first argument itself is the value
      return !!name && !!name.match(this.DISPLAYNAME_PATTERN_30)
    }

    if (name === null || !!name.match(this.DISPLAYNAME_PATTERN_30) === false) {
      rule.message = this.Messages.INVALID_NAME_SPACE_30
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateField = (e: any): boolean => {
    if (e && e.nativeEvent) {
      const event = e.nativeEvent
      if (!event.target.validity.valid) {
        this.showMessage(event.target.validationMessage, 0, 0)
        return false
      }
      return true
    }
    return true
  }
  /**
   * Validates hostname, can have 100 characters atmost
   * Allowable characters Aphanumerics, -, ., _
   */
  validateHost = (rule: any, host: string = null, cb: any = null): boolean => {
    // const regex: RegExp = /^[\w._-]{1,100}$/
    const hostnameRegex = this.VALIDHOSTNAME_REGEX
    const ipv4Regex = this.IPV4_REGEX
    const ipv6Regex = this.IPV6_REGEX
    let isValid: boolean = true

    if (!host && !cb) {
      host = rule // first argument itself is the value
      return (
        hostnameRegex.test(host) || ipv4Regex.test(host) || ipv6Regex.test(host)
      )
    }

    if (host) {
      isValid =
        hostnameRegex.test(host) || ipv4Regex.test(host) || ipv6Regex.test(host)
      rule.message = this.Messages.INVALID_HOST
    }

    return isValid ? cb() : cb(true)
  }

  validatePort = (
    field: any,
    rule: any,
    port: string = null,
    cb: any = null,
  ): boolean => {
    let isValid: boolean = true

    if (!port && !cb) {
      port = rule // first argument itself is the value
      return this.portRegexTest(port)
    }

    if (!port) {
      if (field !== 'not-mandatory') {
        rule.message = this.Messages.PORT_REQUIRED
        isValid = false
      }
    }

    if (port && !this.portRegexTest(port)) {
      rule.message = this.Messages.INVALID_PORT_NUMBER
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateNotEmpty = (
    rule: any,
    value: string = null,
    cb: any = null,
  ): boolean => {
    const regex: RegExp = this.NOT_EMPTY_PATTERN
    let isValid: boolean = true

    if (!value && !cb) {
      value = rule // first argument itself is the value
      return regex.test(value)
    }

    if (regex.test(value) === false) {
      rule.message = this.Messages.CANNOT_BE_EMPTY
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  portRegexTest = (port: string) => {
    const regex: RegExp = /^\d{1,5}$/
    return regex.test(port) === true && parseInt(port, 10) <= 65535
  }

  validateRetries = (
    rule: any,
    retries: string = null,
    cb: any = null,
  ): boolean => {
    let isValid: boolean = true

    if (!retries && !cb) {
      retries = rule // first argument itself is the value
      return this.retryTimeoutRegexTest(retries)
    }

    if (!this.retryTimeoutRegexTest(retries)) {
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  retryTimeoutRegexTest = (value: string) => {
    const regex: RegExp = /^\d{1,2}$/
    return (
      value === '' || (regex.test(value) === true && parseInt(value, 10) <= 10)
    )
  }

  validateTimeout = (
    rule: any,
    timeout: string = null,
    cb: any = null,
  ): boolean => {
    let isValid: boolean = true

    if (!timeout && !cb) {
      timeout = rule // first argument itself is the value
      return this.retryTimeoutRegexTest(timeout)
    }

    if (!this.retryTimeoutRegexTest(timeout)) {
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validatePassword: ValidationRule['validator'] = (
    rule,
    password,
    callback,
  ) => {
    const regex = new RegExp(/^[A-Za-z\d!@#%*]{6,40}$/)
    if (typeof rule === 'string') {
      return regex.test(rule)
    } else {
      try {
        return regex.test(password) ? callback() : callback(rule.message)
      } catch (err) {
        return callback(err)
      }
    }
  }

  validateSharedSecret = (
    rule: any,
    secret: string = null,
    cb: any = null,
  ): boolean => {
    let isValid: boolean = true

    if (!secret && !cb) {
      secret = rule // first argument itself is the value
      return secret.length >= 6 && secret.length <= 30
    }

    if (!(secret.length >= 6 && secret.length <= 30)) {
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateContactNumber: ValidationRule['validator'] = (
    rule,
    value,
    callback,
  ): boolean => {
    const regex = this.INTEGER_PATTERN

    try {
      return !value || (value?.toString().length <= 15 && regex.test(value))
        ? callback()
        : callback(rule.message)
    } catch (err) {
      return callback(err)
    }
  }

  getDropdownDefault = (key: string, section: string): string => {
    let defaultOpt = { key: '', value: '' }
    let dropdownOpts = this.DropdownConstants[section][key]
    dropdownOpts.map((opt: any, i: number) => {
      if (opt.default) {
        defaultOpt = opt
      }
    })
    return defaultOpt.value
  }

  getVal = (key: string, section: string, type: string): any => {
    let dropdowns = this.DropdownConstants[section][type] || []
    let val = ''
    dropdowns.forEach((element: any) => {
      if (element.value === key) {
        val = element
      }
    })
    return val
  }
  renderDropdownOpts = (
    key: any,
    type?: string,
    labelKey?: string,
    render?: any,
    dropdownIcon?: string,
    otherLabelKey?: string,
    hoverLabel?: string,
    keyIndex?: any,
  ) => {
    const style = { minHeight: '27px' }

    if (key instanceof Array) {
      return key.length > 0
        ? key.map((option: any, i: number) => {
            return (
              <A10Select.Option
                style={style}
                key={
                  (option[otherLabelKey] || option[labelKey]) +
                  '_' +
                  i +
                  (keyIndex ? '_' + keyIndex : '')
                }
                value={JSON.stringify(option)}
              >
                {/*  Dropdown with icon without hover */}
                {dropdownIcon != null && !hoverLabel ? (
                  <span>
                    <span className={'a10-icon ' + dropdownIcon} />
                    <span>{option[otherLabelKey] || option[labelKey]}</span>
                  </span>
                ) : dropdownIcon != null && hoverLabel ? ( // Dropdown with icon and hover
                  <>
                    <A10Tooltip placement="top" title={option[hoverLabel]}>
                      <span>
                        <span className={'a10-icon ' + dropdownIcon} />
                        <span>{option[otherLabelKey] || option[labelKey]}</span>
                      </span>
                    </A10Tooltip>
                  </>
                ) : hoverLabel ? ( // Dropdown hover
                  <A10Tooltip placement="top" title={option[hoverLabel]}>
                    {option[otherLabelKey] || option[labelKey]}
                  </A10Tooltip>
                ) : (
                  option[otherLabelKey] || option[labelKey]
                )}
              </A10Select.Option>
            )
          })
        : null
    } else {
      let dropdownOpts = this.DropdownConstants[type][key]
      return dropdownOpts
        ? dropdownOpts.map((key: any, i: number) => {
            if (!key.onlyOne || (key.onlyOne && keyIndex === 0)) {
              return (
                <A10Select.Option style={style} key={key.key} value={key.value}>
                  {render ? (
                    <>{render(key)}</>
                  ) : hoverLabel ? (
                    <A10Tooltip placement="top" title={key.label}>
                      {key.label}
                    </A10Tooltip>
                  ) : (
                    key.label
                  )}
                </A10Select.Option>
              )
            }
          })
        : null
    }
  }

  getSessionId = async () => {
    const { get } = storage

    const headers = {
      Authorization: get.ENCODED_SESSION_ID,
      provider: get.PROVIDER,
      'x-account': basicUtils.getTenantUuids(),
      //tenant: get.TENANT, // this approach is deprecated
      tenant: get.CURRENT_TENANT?.name,
    }
    const baseURL = parameters.BASE_URL
    const userId = get.USER_ID

    const newSession = await axios({
      method: 'POST',
      url: `${baseURL}/sessions`,
      headers: headers,
      data: { userId: userId },
    })
      .then(res => {
        return res?.data?.id
      })
      .catch(err => {
        console.error('Error in creating new session: ', err)
      })
    return newSession
  }

  launchApp = async (app: any) => {
    const { get } = storage

    const ipv6Url = this.checkIPv6BaseURL()
    const baseURL = ipv6Url
      ? parameters.BASE_URL.replace(':8443/api/v2', '') + encodeURI('/hcapps')
      : encodeURI(parameters.BASE_URL.replace(':8443/api/v2', '') + '/hcapps')
    let sessionId = await this.getSessionId()
    const sessionTimeout = get.SESSION_TIMEOUT_INITIAL
    let queryString =
      '?user_id=' +
      get.USER_ID +
      '&provider=' +
      get.PROVIDER +
      '&token=' +
      sessionId +
      '&timeout=' +
      (sessionTimeout ? sessionTimeout : '1') +
      (get.CURRENT_TENANT?.uuid
        ? '&tenant=' +
          get.CURRENT_TENANT.name +
          '&tenantUUID=' +
          get.CURRENT_TENANT.uuid +
          (get.IS_OPERATOR_USER ? '&is_operator=1' : '')
        : '')

    let url =
      baseURL +
      '/' +
      app.name +
      '_' +
      app.version +
      queryString +
      '&app_svc=' +
      app.name +
      '&app_svc_type=' +
      app.app_svc_type +
      '&action=config#/analytics'

    window.open(url, '_blank')
  }

  launchAppWithSvc = async (app: any, appSvc: any) => {
    const { get } = storage

    const ipv6Url = this.checkIPv6BaseURL()
    const baseURL = ipv6Url
      ? parameters.BASE_URL.replace(':8443/api/v2', '') + encodeURI('/hcapps')
      : encodeURI(parameters.BASE_URL.replace(':8443/api/v2', '') + '/hcapps')

    const sessionTimeout = storage.get.SESSION_TIMEOUT_INITIAL
    let sessionId = await this.getSessionId()
    let queryString =
      '?user_id=' +
      get.USER_ID +
      '&api_ep=' +
      encodeURI(parameters.BASE_URL) +
      '&provider=' +
      get.PROVIDER +
      '&token=' +
      sessionId +
      '&timeout=' +
      (sessionTimeout ? sessionTimeout : '1') +
      (get.CURRENT_TENANT?.uuid
        ? '&tenant=' +
          get.CURRENT_TENANT.name +
          '&tenantUUID=' +
          get.CURRENT_TENANT.uuid +
          (get.IS_OPERATOR_USER ? '&is_operator=1' : '')
        : '')

    let url =
      baseURL +
      '/' +
      app.name +
      '_' +
      app.version +
      queryString +
      (appSvc && appSvc.app_svc_id
        ? '&app_svc=' +
          appSvc.name +
          '&app_service_id=' +
          appSvc.app_svc_id +
          '&app_svc_type=' +
          appSvc['app_svc_type'] +
          '&action=config#/analytics'
        : '')
    window.open(url, '_blank')
  }

  launchAppWithLogicalPartition = (
    app: any,
    logicalPartition: string,
    appSvc?: any,
  ) => {
    const { get } = storage
    const that = this
    const ipv6Url = that.checkIPv6BaseURL()
    const sessionTimeout = get.SESSION_TIMEOUT_INITIAL
    const baseURL = ipv6Url
      ? parameters.BASE_URL.replace(':8443/api/v2', '') + encodeURI('/hcapps')
      : encodeURI(parameters.BASE_URL.replace(':8443/api/v2', '') + '/hcapps')

    const queryString =
      '?user_id=' +
      get.USER_ID +
      '&api_ep=' +
      encodeURI(parameters.BASE_URL) +
      '&provider=' +
      get.PROVIDER +
      '&token=' +
      get.USER_SESSION_ID +
      '&timeout=' +
      (sessionTimeout ? sessionTimeout : '1') +
      (get.CURRENT_TENANT?.uuid
        ? '&tenant=' +
          get.CURRENT_TENANT.name +
          '&tenantUUID=' +
          get.CURRENT_TENANT.uuid +
          (get.IS_OPERATOR_USER ? '&is_operator=1' : '')
        : '')

    const url =
      baseURL +
      '/' +
      app.name +
      '_' +
      app.version +
      queryString +
      `&lp=${logicalPartition}`
    window.open(url, '_blank')
  }

  parseChartData = (resp: any, widgetConfig: any): any => {
    let parsedData: Array<any> = []
    if (resp == null) {
      /* 20170913: To initialize the High chart to show 'No Data Available' if response is empty */
      return parsedData
    }

    let interval: any = widgetConfig.query.histogram
      ? widgetConfig.query.histogram.interval / 1000
      : 1
    let timeperiod: any =
      (widgetConfig.query.rangeby.end - widgetConfig.query.rangeby.start) / 1000

    widgetConfig.fields.forEach((field: any, index: number) => {
      if (!widgetConfig.histogramField) {
        let key =
          field.key +
          '_' +
          (field.aggregation ? field.aggregation : widgetConfig.aggregation)
        let val = resp[key]

        val = isNaN(val) || val === 'NaN' ? 0 : val
        val = field.divisionByPeriod ? val / timeperiod : val
        val = widgetConfig.ceil ? Math.ceil(val) : val

        if (val >= 0) {
          parsedData.push({
            name: field.label,
            data: [val],
            type: field.type,
            visible: !field.hide,
            units: field.units, // extra data
            key: field.key, // extra data,
            mergeWith: field.mergeWith, // extra data,
            showForApp: field.showForApp, // extra data,
            color: field.color,
          })
        }
      } else if (
        resp[
          field.key +
            '_' +
            (field.aggregation ? field.aggregation : widgetConfig.aggregation)
        ]
      ) {
        let val =
          resp[
            field.key +
              '_' +
              (field.aggregation ? field.aggregation : widgetConfig.aggregation)
          ]

        let valKeys = Object.keys(val)

        parsedData.push({
          name: field.label,
          data: [],
          type: field.type,
          visible: !field.hide,
          units: field.units, // extra data
          key: field.key, // extra data,
          mergeWith: field.mergeWith, // extra data,
          showForApp: field.showForApp, // extra data,
          color: field.color,
        })
        valKeys.forEach((key: any) => {
          var date = Number(key)
          var value = isNaN(val[key]) || val[key] === 'NaN' ? 0 : val[key]
          value = field.divisionByPeriod ? value / timeperiod : value
          value = field.divisionByInterval ? value / interval : value
          value = widgetConfig.ceil ? Math.ceil(value) : value
          if (
            value !== Infinity &&
            value != 'Infinity' &&
            value >= 0 &&
            val[key] !== 'NaN'
          ) {
            parsedData[index].data.push([date, value])
          }
        })
      }
    })
    return parsedData
  }
  buildQuery = (widgetConfig: any, startDt: any, endDt: any) => {
    let interval = this.getPointGranularity(startDt, endDt)
    let query: any = {}
    query = widgetConfig.query || {}
    if (widgetConfig.fields) {
      query.fields = []
      widgetConfig.fields.forEach((field: any) => {
        query.fields.push(field.key)
      })
    }

    query.aggregation = widgetConfig.aggregation || 'count'
    query.sort = widgetConfig.sort

    query.groupby = widgetConfig.groupBy
    query.size = widgetConfig.size
    query.serviceId = widgetConfig.serviceId

    query.rangeby = {
      start: startDt,
      end: endDt,
      field: widgetConfig.rangebyField || 'timestamp',
    }

    if (widgetConfig.histogramField) {
      query.histogram = {
        field: widgetConfig.histogramField,
        interval,
      }
    }

    query.filterby = widgetConfig.filterBy || {}

    if (query.filterby == 'delete') {
      delete query.filterby
    }

    if (query.filterby) {
      if (!query.filterby.and) {
        query.filterby.and = {}
      }
      // query.filterby.and.account_id = tenant.name;
    }
    widgetConfig.query = query
    return query
  }
  getPointGranularity = (fromDate: any, toDate: any) => {
    let interval: any = 60000 // default 1 min
    let timeDiff: any = 0
    // creating moment instances
    timeDiff = toDate - fromDate
    let date = new Date().getTime()
    if (timeDiff > 300000) {
      interval = parseInt(timeDiff / 100)
      interval = Math.floor(interval / 60000)
      interval = interval * 60000
    } else {
      interval = 60000
    }

    if (interval < 60000) {
      interval = 60000
    }

    // If timediff is greater than 30 days & interval is less than 10 mins
    if (timeDiff > 30 * 24 * 60 * 60 * 1000 && interval < 10 * 60 * 1000) {
      interval = 10 * 60 * 1000
    }
    if (date - toDate > 30 * 24 * 60 * 60 * 1000 && interval < 10 * 60 * 1000) {
      interval = 10 * 60 * 1000
    }
    return interval
  }
  checkFeatureMsg = (key: string, msg: string) => {
    if (key === 'cluster') {
      let message = this.Messages.FEATURE_CHECK_CLUSTER
      message = message.replace('{devcie_cluster_name}', msg)
      this.showMessage(message, 0, 0, null, 10)
      return
    }

    if (key === 'device') {
      let message = this.Messages.FEATURE_CHECK_DEVICE
      message = message.replace('{devcie_name}', msg)
      this.showMessage(message, 0, 0, null, 10)
      return
    }
  }

  showMessage = (
    key: string | any,
    type: number,
    isKey: number | boolean,
    readMore?: string,
    duration?: number,
  ) => {
    let message = !isKey || isKey === 0 ? key : this.Messages[key]

    if (typeof message === 'string' && readMore) {
      message = message + (readMore ? ' - ' + readMore : '')
    }

    A10Message.destroy()
    if (type === 0) {
      A10Message.error(message, duration ? duration : 4, close)
    } else if (type === 1) {
      A10Message.success(message, duration ? duration : 4, close)
    } else {
      A10Message.info(message, duration ? duration : 4, close)
    }
  }
  getTenantDisplayName = (tenantName: string) => {
    const allTenants = storage.get.ALLTENANTS
    const tenant = allTenants.filter((obj: any) => {
      return obj.name === tenantName
    })
    return tenant.length > 0 && tenant[0]['display-name']
      ? tenant[0]['display-name']
      : tenantName
  }
  getAllXAccountIDs = () => {
    const tenants = storage.get.ALLTENANTS
    let xAccount = ''
    tenants.forEach((tenant: any, index: any) => {
      xAccount = xAccount + tenant.uuid
      if (index < tenants.length - 1) {
        xAccount = xAccount + ','
      }
    })

    return xAccount
  }
  validateAndSubmitForm = (props: any) => {
    const { form } = props
    form.validateFieldsAndScroll({ force: true }, (err: any, values: any) => {
      if (!err) {
        props.onSubmitForm()
      }
    })
  }
  generateDevicePayload = (devices: any[]): any[] => {
    const deviceListPayload: any[] = []
    devices.forEach((device: any) => {
      const {
        deviceName,
        cluster,
        thunderIPAddress,
        mgmtInterface,
        // useV6,
        port,
        zone,
        region,
        location,
        metricsExportInterval,
        tenant,
        newPartition,
        userName,
        userPassword,
      } = device
      if (!!deviceName) {
        const regionObj = region ? JSON.parse(region) : ''
        let parsedCluster: any = ''
        if (cluster.indexOf('{') > -1) {
          parsedCluster = JSON.parse(cluster)
        } else {
          parsedCluster = {
            name: cluster,
          }
        }

        let deviceObj = {
          name: deviceName,
          cluster: parsedCluster.name,
          host: thunderIPAddress,
          'management-interface': mgmtInterface,
          // 'use-ipv6': useV6,
          'mgmt-port-secure': port,
          'metrics-export-interval': metricsExportInterval,
          username: userName,
          password: userPassword,
          region: regionObj?.key || 'null',
          zone: zone || 'null',
          location,
        }

        if (device.haPrimary !== undefined) {
          deviceObj['ha-primary'] = device.haPrimary ? 1 : 0
        }

        deviceListPayload.push(deviceObj)
      }
    })
    return deviceListPayload
  }
  generateVMDeviceload = (formData: any, createFormData: any): any => {
    const credential = JSON.parse(formData.credential)
    let hcCluster: any
    try {
      hcCluster = JSON.parse(formData.hcClusterSelect)
    } catch (error) {
      hcCluster = ''
    }
    const licObj = !!formData.licenseObj
      ? JSON.parse(formData.licenseObj)
      : undefined
    const bandwidthMbps = !licObj
      ? 0
      : formData.bandwidth * (formData.bandwidthUnit === 'g' ? 1000 : 1)

    const securePort = formData.securePort
    const vmNetwork: any[] = []
    createFormData.vmNetworks.map((obj: any, index: number) => {
      if (formData.environment === 'vmware') {
        const vmNet = {
          'interface-number': obj.name,
          'ip-address': obj.ipAddr,
          network: JSON.parse(obj.network).network,
          'net-mask': obj.netMask,
          'default-gateway': obj.gateway,
        }
        vmNetwork.push(vmNet)
      } else if (formData.environment === 'aws') {
        const vmNet = {
          'interface-number': obj.name,
          'ip-address': obj.autoAssign ? undefined : obj.ipAddr,
          'ip-allocation-type': obj.autoAssign
            ? 'dhcp'
            : !!obj.ipAddr
            ? 'static'
            : undefined,
          'public-ip-address': obj.autoAllocate
            ? undefined
            : !!obj.publicIP
            ? JSON.parse(obj.publicIP).PublicIp
            : undefined,
          'public-ip-allocation-type': obj.autoAllocate
            ? 'dhcp'
            : !!obj.publicIP
            ? 'static'
            : undefined,
          network: JSON.parse(obj.subnet).SubnetId,
          'network-name': JSON.parse(obj.subnet).CidrBlock,
          'security-group-id': JSON.parse(obj.securityGroup).GroupId,
          'security-group': JSON.parse(obj.securityGroup).GroupName,
        }
        vmNetwork.push(vmNet)
      } else if (formData.environment === 'azure') {
        const vmNet = {
          'interface-number': obj.name,
          'ip-address': obj.autoAssign ? undefined : obj.ipAddr,
          'ip-allocation-type': obj.autoAssign
            ? 'dhcp'
            : !!obj.ipAddr
            ? 'static'
            : undefined,
          'public-ip-address': obj.autoAllocate
            ? undefined
            : !!obj.publicIP
            ? JSON.parse(obj.publicIP).name
            : undefined,
          'public-ip-allocation-type': obj.autoAllocate
            ? 'dhcp'
            : !!obj.publicIP
            ? 'static'
            : undefined,
          network: JSON.parse(obj.subnet).name,
          'network-name': JSON.parse(obj.subnet).name,
          'security-group-id': JSON.parse(obj.securityGroup).name,
          'security-group': JSON.parse(obj.securityGroup).name,
        }
        vmNetwork.push(vmNet)
      } else if (formData.environment === 'kubernetes') {
        let interface_number = obj.name
        let vmNet = {}
        if (interface_number == 'eth0') {
          vmNet = {
            'interface-number': interface_number,
            'ip-allocation-type': 'dhcp',
          }
          if (obj.isService) {
            let svc = {}
            svc['name'] = createFormData.vmName
            svc['type'] = obj.serviceType
            let portList = []
            const ports = obj.ports
            for (var i = 0; i < ports.length; i++) {
              let port = {}
              port['port-number'] = ports[i]
              portList.push(port)
            }
            svc['port-list'] = portList
            vmNet['external-service-list'] = [svc]
          }
        } else {
          vmNet = {
            'interface-number': interface_number,
            'ip-address': obj.ipAddr,
            'net-mask': obj.netMask,
            'interface-device-type': obj.interfaceDeviceType,
            network:
              obj.interfaceDeviceType === 'sriov' ||
              obj.interfaceDeviceType === 'vhost'
                ? JSON.parse(obj.network).name
                : obj.network,
          }
        }
        vmNetwork.push(vmNet)
      } else if (formData.environment === 'oci') {
        const vmNet = {
          'interface-number': obj.name,
          'ip-address': obj.autoAssign ? undefined : obj.ipAddr,
          'ip-allocation-type': obj.autoAssign
            ? 'dhcp'
            : !!obj.ipAddr
            ? 'static'
            : undefined,
          'public-ip-address': obj.autoAllocate
            ? undefined
            : !!obj.publicIP
            ? JSON.parse(obj.publicIP).public_ip
            : undefined,
          'public-ip-allocation-type': obj.autoAllocate
            ? 'dhcp'
            : !!obj.publicIP
            ? 'static'
            : undefined,
          network: JSON.parse(obj.subnet).id,
          'network-name': JSON.parse(obj.subnet).cidr_block,
          'security-group-id': JSON.parse(obj.securityGroup).id,
          'security-group': JSON.parse(obj.securityGroup).name,
        }
        vmNetwork.push(vmNet)
      }
    })

    let vmPayload = {
      name: createFormData.vmName,
      'cluster-id': hcCluster.uuid || hcCluster,
      'cluster-name': hcCluster.name || hcCluster,
      'power-state': formData.environment === 'kubernetes' ? 'off' : 'on',
      'owner-account': credential['name'],
      'owner-account-id': credential['uuid'],
      'management-secure-port': securePort ? securePort : undefined,
      license:
        licObj && licObj['entitlement-key']
          ? licObj['entitlement-key']
          : undefined,
      bandwidth:
        licObj && licObj['entitlement-key'] ? bandwidthMbps : undefined,
      'virtual-nic': vmNetwork,
    }

    if (formData.environment === 'vmware') {
      const datacenter = JSON.parse(createFormData.datacenter)

      vmPayload['compute-type'] = 'vm'
      vmPayload['image-name'] = createFormData.vmDiskImage.name
      vmPayload['image-id'] = createFormData.vmDiskImage.id
      vmPayload['placement'] = {
        datacenter: datacenter.name,
        'datacenter-id': datacenter.datacenter,
        cluster: createFormData.vmCluster.name,
        'cluster-id': createFormData.vmCluster.cluster,
        folder: createFormData.vmFolder.name,
        'folder-id': createFormData.vmFolder.folder,
        host: createFormData.vmHost.name,
        'host-id': createFormData.vmHost.host,
        'resource-pool': createFormData.vmResourcePool.name,
        'resource-pool-id': createFormData.vmResourcePool.resource_pool,
        datastore: createFormData.vmStorage.name,
        'datastore-id': createFormData.vmStorage.datastore,
      }
    } else if (formData.environment === 'aws') {
      const vpcObj = JSON.parse(createFormData.vpc)
      let tag = null
      if (vpcObj.Tags && vpcObj.Tags !== null && vpcObj.Tags.length > 0) {
        tag = vpcObj.Tags.filter((tagObj: any) => {
          return tagObj.Key === 'Name'
        })
      }
      const vpcDispName =
        tag.length > 0
          ? tag[0].Value + ' (' + vpcObj.CidrBlock + ')'
          : vpcObj.CidrBlock

      vmPayload['image-id'] = JSON.parse(createFormData.image).ImageId
      vmPayload['infra-instance-type'] = JSON.parse(
        createFormData.instanceType,
      ).name
      vmPayload['placement'] = {
        region: JSON.parse(createFormData.region).RegionName,
        zone: JSON.parse(createFormData.zone).ZoneName,
        'vpc-id': vpcObj.VpcId,
        vpc: vpcDispName,
      }
    } else if (formData.environment === 'azure') {
      const vpcObj = JSON.parse(createFormData.vnet)
      vmPayload['image-name'] = JSON.parse(createFormData.image).name
      vmPayload['image-id'] = JSON.parse(createFormData.image).id
      vmPayload['infra-instance-type'] = JSON.parse(
        createFormData.instanceType,
      ).name
      vmPayload['placement'] = {
        region: JSON.parse(createFormData.location).name,
        'resource-group': createFormData.autoCreate
          ? createFormData.vmName + '-resource-group'
          : JSON.parse(createFormData.resourceGroup).name,
        'vpc-id': vpcObj.id,
        vpc: vpcObj.name,
      }
    } else if (formData.environment === 'kubernetes') {
      vmPayload['compute-type'] = 'container'
      let resources = []
      resources.push({
        resource: formData.product,
        'resource-type': 'product',
        'resource-size': 0,
      })
      if (createFormData.vmNetworks.length > 1) {
        resources.push({
          resource: createFormData.hugePagesType,
          'resource-type': 'hugepages',
          'resource-size': parseInt(createFormData.hugePagesSize),
        })
        if (createFormData.sriovDriver !== '') {
          resources.push({
            resource: createFormData.sriovDriver,
            'resource-type': 'sriov-driver',
          })
        }
      }
      vmPayload['resource'] = resources
      vmPayload['memory-size'] = parseInt(createFormData.memory) * 1024
      vmPayload['cpu-count'] = parseInt(createFormData.cpu)
      vmPayload['image-name'] = JSON.parse(createFormData.image).name
      vmPayload['placement'] = {
        namespace: createFormData.autoCreate
          ? createFormData.vmName
          : JSON.parse(createFormData.namespace).name,
        host: JSON.parse(createFormData.node).name,
      }
    } else if (formData.environment === 'oci') {
      const vcnObj = JSON.parse(createFormData.vcn)
      const vcnDispName = vcnObj.name + ' (' + vcnObj['cidr_block'] + ')'

      vmPayload['image-name'] = JSON.parse(createFormData.image).name
      vmPayload['image-id'] = JSON.parse(createFormData.image).id
      vmPayload['infra-instance-type'] = JSON.parse(
        createFormData.instanceType,
      ).name
      vmPayload['placement'] = {
        region: JSON.parse(createFormData.region).name,
        zone: JSON.parse(createFormData.availabilityDomain).name,
        'compartment-id': JSON.parse(createFormData.compartment).id,
        compartment: JSON.parse(createFormData.compartment).name,
        'vpc-id': vcnObj.id,
        vpc: vcnDispName,
      }
    }

    return vmPayload
  }
  checkForTenantAdmin = (tenantName: string): boolean => {
    let result = ''
    if (tenantName) {
      const {
        get: { READTENANTS },
      } = storage

      READTENANTS.map((tenant: any) => {
        if (tenantName === tenant) {
          result = tenant
        }
        return 0
      })
    }
    return result ? false : true
  }
  checkForValidIP = (value: any) => {
    const ipv4Regex = this.IPV4_REGEX
    const ipv6Regex = this.IPV6_REGEX
    const ipvalue = value || ''
    let ip = null
    let isValid: boolean = true

    if (ipvalue.indexOf('/') !== -1) {
      ip = ipvalue.substring(0, ipvalue.indexOf('/'))
      if (!ipv4Regex.test(ip) && !ipv6Regex.test(ip)) {
        isValid = false
      }
    } else {
      if (!ipv4Regex.test(ipvalue) && !ipv6Regex.test(ipvalue)) {
        isValid = false
      }
    }
    return isValid
  }
  validateVMDeviceIPs = (field: any, rule: any, value: any, cb: any) => {
    const ipv4Regex = this.IPV4_REGEX
    const ipv6Regex = this.IPV6_REGEX
    const ipvalue = value || ''
    let ip = null
    let isValid: boolean = true
    const fieldLabel =
      rule.field.indexOf('ipAddr[') > -1
        ? 'IP address'
        : rule.field.indexOf('netMask[') > -1
        ? 'Netmask'
        : 'Gateway'

    if (ipvalue.indexOf('/') !== -1) {
      ip = ipvalue.substring(0, ipvalue.indexOf('/'))
      if (!ipv4Regex.test(ip) && !ipv6Regex.test(ip)) {
        rule.message = 'Invalid ' + fieldLabel
        isValid = false
      }
    } else {
      if (!ipvalue) {
        if (field !== 'not-mandatory') {
          rule.message = 'Enter ' + fieldLabel
          isValid = false
        }
      } else if (!ipv4Regex.test(ipvalue) && !ipv6Regex.test(ipvalue)) {
        rule.message = 'Invalid ' + fieldLabel
        isValid = false
      }
    }
    return isValid ? cb() : cb(true)
  }
  validateThunderIP = (rule: any, value: any, cb: any) => {
    const ipv4Regex = this.IPV4_REGEX
    const ipv6Regex = this.IPV6_REGEX
    const ipvalue = value || ''
    let ip = null
    let mask = null
    let isValid: boolean = true

    if (ipvalue.indexOf('/') !== -1) {
      ip = ipvalue.substring(0, ipvalue.indexOf('/'))
      mask = ipvalue.substring(ipvalue.indexOf('/') + 1, ipvalue.length)
      if (mask === '') {
        rule.message = this.Messages.IP_MASK_MISSING
        isValid = false
      }
      if (!ipv4Regex.test(ip) && !ipv6Regex.test(ip)) {
        rule.message = this.Messages.IP_ADDRESS_INVALID
        isValid = false
      }
      const isIpV4Address = ipv4Regex.test(ip)
      if (!/[0-9\.]/.test(mask)) {
        rule.message = this.Messages.IP_MASK_INVALID
        isValid = false
      }
      if (isIpV4Address) {
        // valid masks are IPv4 address or a number between 1-32
        if (
          !ipv4Regex.test(mask) &&
          !(parseInt(mask, 10) >= 1 && parseInt(mask, 10) <= 32)
        ) {
          rule.message = this.Messages.IPV4_ADDRESS_INVALID
          isValid = false
        }
      } else {
        if (parseInt(mask, 10) < 1 || parseInt(mask, 10) > 128) {
          rule.message = this.Messages.IPV6_ADDRESS_INVALID
          isValid = false
        }
      }
    } else {
      if (!ipvalue) {
        rule.message = this.Messages.THUNDER_IP_REQUIRED
        isValid = false
      } else if (!ipv4Regex.test(ipvalue) && !ipv6Regex.test(ipvalue)) {
        rule.message = this.Messages.IPV4_OR_IPV6_ADDRESS_INVALID
        isValid = false
      }
    }
    return isValid ? cb() : cb(true)
  }
  getTenantObject = (tenantName: string) => {
    const allTenants = storage.get.ALLTENANTS
    const tenant = allTenants.filter((obj: any) => {
      return obj.name === tenantName || obj['display-name'] === tenantName
    })
    return tenant.length > 0 ? tenant[0] : null
  }
  getTenantObjectByUUID = (tenantUuid: string) => {
    const allTenants = storage.get.ALLTENANTS
    const tenant = allTenants.filter((obj: any) => {
      return obj.uuid === tenantUuid
    })
    return tenant.length > 0 ? tenant[0] : null
  }
  getDetailedTenantObjectByUUID = async (tenantUuid: string) => {
    const allTenants = storage.get.ALLTENANTS
    const tenant = allTenants.filter((obj: any) => {
      return obj.uuid === tenantUuid
    })
    const name = tenant.length > 0 ? tenant[0]?.name : ''
    let tenantObj
    if (name) {
      const res = await this.DashboardService.getTenantObj(null, null, [
        storage.get.PROVIDER,
        name,
      ])
      tenantObj = res?.data?.tenant
    }
    return tenantObj ?? null
  }
  getCurrentTenantObject() {
    const {
      get: { CURRENT_TENANT: currentTenant, ALLTENANTS: tenants },
    } = storage

    if (currentTenant.name) {
      if (currentTenant.uuid) {
        return currentTenant
      }

      const matchTenants = tenants.filter(
        tenant =>
          tenant.name === currentTenant.name ||
          tenant['display-name'] === currentTenant.name,
      )

      return matchTenants.length > 0 ? matchTenants[0] : currentTenant
    } else {
      return currentTenant
    }
  }

  getCurrentObjectByUUID(uuid: any) {
    const drillLevel = storage.get.DRILL_LEVEL
    if (drillLevel === 'provider') {
      return this.getProviderObj()
    } else {
      return this.getTenantObjectByUUID(uuid)
    }
  }

  getProviderObj() {
    const { get } = storage
    return {
      name: get.PROVIDER,
      uuid: get.PROVIDER_ID,
      'display-name': get.PROVIDER,
    }
  }

  getCurrentDrillLevelObject() {
    const drillLevel = storage.get.DRILL_LEVEL
    if (drillLevel === 'provider') {
      return this.getProviderObj()
    } else {
      return this.getCurrentTenantObject()
    }
  }

  getCurrentTenantName() {
    const currentTenant = this.getCurrentTenantObject()
    return !!currentTenant
      ? currentTenant['display-name'] || currentTenant.name
      : ''
  }

  getXAccountHeaderDetailsOnDrillLevel(isAll: boolean, isProvider?: boolean) {
    const { get } = storage

    let xaccount: any = null
    const drillLevel = get.DRILL_LEVEL
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      provider: get.PROVIDER,
      Authorization: get.ENCODED_SESSION_ID,
    }
    if (drillLevel === 'provider') {
      xaccount = get.PROVIDER_ID
      if (isProvider) {
        headers['X-ProviderIds'] = xaccount
      } else {
        headers['X-Account'] = xaccount
      }
      return headers
    } else {
      xaccount = isAll
        ? this.getAllXAccountIDs()
        : this.getCurrentTenantObject()
      if (!xaccount || (!isAll && !xaccount.uuid)) {
        return null
      }
      headers['X-Account'] = isAll ? xaccount : xaccount.uuid
      return headers
    }
  }

  getXAccountHeaderDetails(isAll: boolean) {
    const { get } = storage

    let xaccount: any = null
    xaccount = isAll ? this.getAllXAccountIDs() : this.getCurrentTenantObject()
    if (!xaccount || (!isAll && !xaccount.uuid)) {
      return null
    }
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      provider: get.PROVIDER,
      Authorization: get.ENCODED_SESSION_ID,
      'X-Account': isAll ? xaccount : xaccount.uuid,
    }
  }
  getUserObject = (userID: string) => {
    const allUsers = storage.get.ALL_USERS
    const user =
      allUsers &&
      allUsers.filter((obj: any) => {
        return obj['user-id'] === userID
      })
    return user.length > 0 ? user[0] : null
  }

  getConiditionText(conditionsObj: any[], type: any) {
    let condTemp = ''
    conditionsObj.forEach((operObj: any, i: number) => {
      const strMetricAgg = this.getVal(
        operObj.aggregation,
        'TRIGGERS',
        'strMetricAgg',
      )
      const agg = this.getVal(operObj.aggregation, 'TRIGGERS', 'metricAgg')
      const opern = this.getVal(operObj.operation, 'TRIGGERS', 'operation')
      const opert = this.getVal(operObj.operator, 'TRIGGERS', 'operator')
      const metricAgg = this.getVal(
        operObj.metricAggPercent,
        'TRIGGERS',
        'metricAgg',
      )
      const metric = this.getVal(operObj.metricPercent, 'TRIGGERS', 'metric')
      const context = strMetricAgg
        ? operObj.params.length > 1
          ? opern?.label?.toLowerCase() +
            ' of (' +
            operObj.params.join(', ') +
            ')'
          : operObj.params.join(', ') +
            ' ' +
            strMetricAgg?.displayLabel?.toLowerCase() +
            ' '
        : agg?.label?.toLowerCase() +
          ' ' +
          (operObj.params.length > 1
            ? opern?.label?.toLowerCase() +
              ' of (' +
              operObj.params.join(', ') +
              ')'
            : operObj.params.join(', ') +
              ' is ' +
              (opert?.label?.toLowerCase() + ' '))
      condTemp =
        condTemp +
        (i !== 0 ? ' ' + type + ' ' : '') +
        context +
        operObj.value +
        (operObj.percent
          ? +' % of ' +
            ' ' +
            metricAgg?.label?.toLowerCase() +
            ' ' +
            metric?.label?.toLowerCase()
          : '')
    })
    return condTemp
  }

  convertObjToString(Obj: any) {
    return JSON.stringify(Obj)?.replace(/[{}]/g, '')
  }

  returnValue(value: any) {
    return value || this.Messages.NONE
  }

  returnWindowType(value: any) {
    if (value === 'timeLength' || value === 'time' || value === 'length') {
      return 'Sliding'
    } else if (value === 'timeBatch' || value === 'lengthBatch') {
      return 'Step'
    } else {
      return this.Messages.NONE
    }
  }

  getClusterObjforDevice = (clusterName: string) => {
    const { get } = storage

    let allCluster = get.PROVIDER_CLUSTERS
    if (!allCluster || allCluster.length === 0) {
      allCluster = get.ALLCLUSTERS
    }
    const cluster =
      allCluster &&
      allCluster.filter((obj: any) => {
        return obj.name === clusterName
      })
    return cluster.length > 0 ? cluster[0] : ''
  }

  getDeviceObj = (deviceList: any, deviceUUID: string) => {
    const device =
      deviceList &&
      deviceList.filter((obj: any) => {
        return obj['device-uuid'] === deviceUUID
      })
    return device.length > 0 ? device[0] : ''
  }

  getAvailableLicenseForReassign = (
    licenseList: any,
    entitlementKey: string,
    licType?: string,
    modelType: string = '',
  ) => {
    const avaialableLicenses = []
    for (const license of licenseList) {
      if (
        license['entitlement-key'] !== entitlementKey &&
        (license['activation-status'] === 'ACTIVATED' ||
          license['activation-status'] === 'EXPIRING' ||
          license['activation-status'] === 'GRACE-PERIOD')
      ) {
        if (licType) {
          if (
            licType === 'HC_LICENSE' &&
            license['license-type'].indexOf('FLEXPOOL') === -1
          ) {
            // For HC License filtering to show other than FLEXPOOL type

            avaialableLicenses.push(license)
          } else if (
            licType !== 'HC_LICENSE' &&
            ((license['license-type'] === 'FLEXPOOL' &&
              license['remaining-bandwidth'] > 0) ||
              (license['license-type'] === 'INSTANCE_FLEXPOOL' &&
                license['remaining-devices'] > 0) ||
              license['license-type'] === 'SUBSCRIBER_FLEXPOOL')
          ) {
            if (
              modelType.toUpperCase() === 'cThunder'.toUpperCase() &&
              license['license-type'] === 'SUBSCRIBER_FLEXPOOL'
            ) {
              continue
            } else {
              avaialableLicenses.push(license)
            }
            // For Device License filtering to show only FLEXPOOL type
          }
        } else {
          avaialableLicenses.push(license)
        }
      }
    }
    return avaialableLicenses
  }

  validateThunderIPAndHost = (field: any, rule: any, value: any, cb: any) => {
    const hostnamePattern = /^(((http|https|tcp|udp)?:)\/{2})?(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?((\:([1-9]|[0-9][0-9]|[0-9][0-9][0-9]|[0-9][0-9][0-9][0-9]|[0-6][0-9][0-9][0-9][0-9]))*?\/?)?$/
    const ipAddressPattern = /^(((http|https|tcp|udp)?:)\/{2})?((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)((\:([1-9]|[0-9][0-9]|[0-9][0-9][0-9]|[0-9][0-9][0-9][0-9]|[0-6][0-9][0-9][0-9][0-9]))*?\/?)?$/
    const ipv4Regex = ipAddressPattern
    const ipv6Regex = this.IPV6_REGEX
    const ValidHostnameRegex = hostnamePattern
    const ipvalue = value || ''
    // let ip = null
    // let mask = null
    let isValid: boolean = true
    // let count = 0

    if (!ipvalue) {
      if (field !== 'not-mandatory') {
        rule.message = field
          ? 'Please enter ' + field
          : this.Messages.THUNDER_IP_REQUIRED
        isValid = false
      }
    } else {
      isValid =
        ValidHostnameRegex.test(ipvalue) ||
        ipv4Regex.test(ipvalue) ||
        ipv6Regex.test(ipvalue)
      rule.message =
        field && field !== 'not-mandatory'
          ? 'Please enter a valid ' + field
          : this.Messages.HOSTNAME_IP_ADDRESS_INVALID
    }

    // if (ipvalue.indexOf('/') !== -1) {
    //   ip = ipvalue.substring(0, ipvalue.indexOf('/'))
    //   mask = ipvalue.substring(ipvalue.indexOf('/') + 1, ipvalue.length)
    //   if (mask === '') {
    //     rule.message = this.Messages.IP_MASK_MISSING
    //     isValid = false
    //   }
    //   if (
    //     (!ipv4Regex.test(ip) && !ipv6Regex.test(ip)) ||
    //     !ValidHostnameRegex.test(ipvalue)
    //   ) {
    //     let letterCount = 0
    //     for (let pos = 0; pos < ipvalue.length; pos++) {
    //       if (ipvalue.charAt(pos) === '.') {
    //         letterCount += 1
    //       }
    //     }
    //     count = letterCount

    //     if (count >= 3 || ipvalue.indexOf(':') !== -1) {
    //       rule.message = this.Messages.IP_ADDRESS_INVALID
    //       isValid = false
    //     } else {
    //       rule.message = this.Messages.HOSTNAME_INVALID
    //       isValid = false
    //     }
    //   }

    //   const isIpV4Address = ipv4Regex.test(ip)

    //   if (!/[0-9\.]/.test(mask)) {
    //     rule.message = this.Messages.IP_MASK_INVALID
    //     isValid = false
    //   }
    //   if (isIpV4Address) {
    //     // valid masks are IPv4 address or a number between 1-32
    //     if (
    //       !ipv4Regex.test(mask) &&
    //       !(parseInt(mask, 10) >= 1 && parseInt(mask, 10) <= 32)
    //     ) {
    //       rule.message = this.Messages.IPV4_ADDRESS_INVALID
    //       isValid = false
    //     }
    //   } else {
    //     if (parseInt(mask, 10) < 1 || parseInt(mask, 10) > 128) {
    //       rule.message = this.Messages.IPV6_ADDRESS_INVALID
    //       isValid = false
    //     }
    //   }
    // } else {
    //   if (!ipvalue) {
    //     rule.message = this.Messages.THUNDER_IP_REQUIRED
    //     isValid = false
    //   } else if (
    //     !ipv6Regex.test(ipvalue) &&
    //     !ValidHostnameRegex.test(ipvalue)
    //   ) {
    //     rule.message = this.Messages.HOSTNAME_IP_ADDRESS_INVALID
    //     isValid = false
    //   } else if (
    //     !ipv4Regex.test(ipvalue) &&
    //     ValidHostnameRegex.test(ipvalue) &&
    //     ipvalue.length >= 6
    //   ) {
    //     rule.message = this.Messages.HOSTNAME_IP_ADDRESS_INVALID
    //     isValid = false
    //     let letterCount = 0
    //     for (let pos = 0; pos < ipvalue.length; pos++) {
    //       if (ipvalue.charAt(pos) === '.') {
    //         letterCount += 1
    //       }
    //     }
    //     count = letterCount
    //     if (count >= 2 && ValidHostnameRegex.test(ipvalue)) {
    //       isValid = true
    //     }
    //   }
    // }
    return isValid ? cb() : cb(true)
  }

  rowToolTip = (record: any, text: any) => {
    return (
      <div className="tableOverToolTip">
        <table>
          <tbody>
            {text &&
              text.map((element: any) => {
                return (
                  <tr key={element}>
                    <td key={element}>{element}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    )
  }

  returnErrorMessage(error: any) {
    const message =
      error && error.response
        ? error.response.status && error.response.status === 403
          ? 'Access denied'
          : error.response.data && error.response.data.message
          ? error.response.data.message
          : ''
        : error.message
        ? error.message
        : ''
    return message
  }

  refreshTenants = () => {
    const { get, set } = storage

    const provider = get.PROVIDER
    const tenantResponse = this.DashboardService.getTenants(
      null,
      null,
      provider,
    )
    tenantResponse
      .then((response: any) => {
        set.ALLTENANTS(response.data ? response.data['tenant-list'] : [])
        this.AppRoot.setRootScopeElement(
          'allTenants',
          response.data ? response.data['tenant-list'] : [],
        )
      })
      .catch((error: any) => {
        // console.log(error.response)
      })
  }

  getFlexpoolLicenses = (licenseList: any) => {
    const licenses =
      licenseList &&
      licenseList.filter((obj: any) => {
        return (
          obj['license-type'].indexOf('FLEXPOOL') > -1 &&
          (obj['license-type'] !== 'INSTANCE_FLEXPOOL' ||
            (obj['license-type'] === 'INSTANCE_FLEXPOOL' &&
              obj['remaining-devices'] > 0))
        )
      })
    return licenses ? licenses : []
  }

  isJson(str: string) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  filterObjByProperty(list: any, property: any, match: any) {
    const resultObj = list.filter((obj: any) => {
      let matchField = ''
      if (match.charAt(0) === '{') {
        matchField = JSON.parse(match)[property]
      } else {
        matchField = match
      }
      return obj[property] === matchField
    })
    if (resultObj) {
      return resultObj[0]
    }
    return null
  }

  checkAppForLaunchWOAppsvc = (app: any, type: string) => {
    if (type === 'OR') {
      // return true
      if (app.name === 'ssli' || app.name === 'adc' || app.name === 'slb') {
        return true
      } else {
        return false
      }
    } else {
      // return false
      if (app.name !== 'ssli' && app.name !== 'adc' && app.name !== 'slb') {
        return true
      } else {
        return false
      }
    }
  }

  getClustersToAddDevice = (clusters: IObject[]) => {
    return clusters.filter((cluster: IObject) =>
      this.enableAddDeviceToCluster(cluster),
    )
  }

  enableAddDeviceToCluster = (cluster: IObject) => {
    const {
      type,
      'data-plane-ha': dataPlaneHA,
      'device-count': deviceCount = 0,
    } = cluster

    if (type === 'single' || type === 'vcs') {
      return deviceCount < 1
    } else if (type === 'multinode' && dataPlaneHA === 'vrrpa') {
      return deviceCount < 2
    } else if (type === 'multinode' && dataPlaneHA === 'scaleout') {
      return deviceCount < 8
    }
    return false
  }

  IsValidJSONString = (str: string) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }

    return true
  }

  SPARCLINE_CHART_CONFIG: IObject = {
    chart: {
      height: '60px',
      width: 200,
      type: 'column',
      backgroundColor: 'transparent',
    },
    xAxis: {
      gridLineWidth: '0px',
      labels: { enabled: false },
      tickPosition: 'inside',
      type: 'datetime',
    },
    tooltip: {
      xDateFormat: '%Y-%b-%e %H:%M',
      valueDecimals: 1,
      enabled: true,
      outside: true,
    },
    colors: ['#808080'],
  }

  bpsTooltipFormatter = (d: IObject) => {
    const point = d.chart.hoverPoint
    const value: any = point.y || 0
    const valString = this.truncateVal(value, 'bytes', 2)
    return (
      '<br/>' +
      moment(point.x).format(`${DATE_FORMAT}, ${TIME_FORMAT_24HR}`) +
      '<br/><b>' +
      valString +
      'bps</b>'
    )
  }

  cpuUtilTooltipFormatter = (d: IObject) => {
    const point = d.chart.hoverPoint
    const value: any = point.y || 0
    const valString = this.truncateVal(value, 'quantity', 2)
    return (
      '<br/>' +
      moment(point.x).format(`${DATE_FORMAT}, ${TIME_FORMAT_24HR}`) +
      '<br/><b>' +
      valString +
      '</b>'
    )
  }

  parseBPSChartData = (histogramData: any, config: any) => {
    const parsedData: IObject[] = []
    if (histogramData == null) {
      return parsedData
    }

    const interval = config?.histogram?.interval
      ? config.histogram.interval / 1000
      : 1

    parsedData.push({
      name: 'BPS',
      data: [],
      // type: 'column',
    })

    const tsKeys = Object.keys(histogramData['total_bytes_in_sum'])

    tsKeys.forEach((key: any) => {
      const date = Number(key)
      const invalue =
        isNaN(histogramData['total_bytes_in_sum'][key]) ||
        histogramData['total_bytes_in_sum'][key] === 'NaN'
          ? 0
          : histogramData['total_bytes_in_sum'][key]
      const outvalue =
        isNaN(histogramData['total_bytes_out_sum'][key]) ||
        histogramData['total_bytes_out_sum'][key] === 'NaN'
          ? 0
          : histogramData['total_bytes_out_sum'][key]
      const value = ((invalue + outvalue) * 8) / interval

      if (
        value !== Infinity &&
        value !== 'Infinity' &&
        value >= 0 &&
        histogramData['total_bytes_in_sum'][key] !== 'NaN'
      ) {
        parsedData[0].data.push([date, value])
      }
    })

    return parsedData
  }

  parseCPUUtilChartData = (histogramData: any) => {
    let parsedData: Array<any> = []
    if (histogramData == null) {
      return parsedData
    }

    parsedData.push({
      name: 'CPU Utilization',
      data: [],
      // type: 'column',
    })

    const tsKeys = Object.keys(histogramData['avg_data_cpu_usage_avg'])

    tsKeys.forEach((key: any) => {
      const date = Number(key)
      const cpuUsage =
        isNaN(histogramData['avg_data_cpu_usage_avg'][key]) ||
        histogramData['avg_data_cpu_usage_avg'][key] === 'NaN'
          ? 0
          : histogramData['avg_data_cpu_usage_avg'][key]
      const value = cpuUsage
      if (
        value !== Infinity &&
        value != 'Infinity' &&
        value >= 0 &&
        histogramData['avg_data_cpu_usage_avg'][key] !== 'NaN'
      ) {
        parsedData[0].data.push([date, value])
      }
    })

    return parsedData
  }

  parseDeviceInterfaceCliSnippetOutput = (result: any) => {
    const externalInterfaces: any[] = []
    const cmdArray = result ? result.response : []
    let interfaceLines = -1,
      interfaceResp = true,
      diskSize = ''
    cmdArray.map((cmdLines: string, index: number) => {
      if (interfaceResp === true) {
        if (cmdLines.indexOf('#show interfaces brief') > -1) {
          interfaceLines++
        }
        if (
          interfaceLines > -1 &&
          cmdLines.indexOf('#show interfaces brief') == -1
        ) {
          if (cmdLines.indexOf('(config') > -1) {
            interfaceLines = -1
            interfaceResp = false
          } else {
            if (cmdLines == '') {
              interfaceLines = -1
              interfaceResp = false
            } else if (
              cmdLines.startsWith('Port') ||
              cmdLines.startsWith('-----')
            ) {
              // continue loop
            } else {
              let interfaceRow: string[] = [],
                tempRow = cmdLines.split(' ')
              tempRow.map((col: string) => {
                if (col != '') {
                  interfaceRow.push(col)
                }
              })

              externalInterfaces.push({
                port: interfaceRow[0],
                link: interfaceRow[1],
                duplexity: interfaceRow[2],
                speed: interfaceRow[3],
                mac: interfaceRow[6],
                ip: interfaceRow[7],
              })
              interfaceLines++
            }
          }
        }
      }
      if (cmdLines.indexOf('#show disk') > -1) {
        const diskSizeArr = cmdArray[index + 3].split(' ')
        let foundSize = false
        diskSizeArr.map((col: string) => {
          if (!foundSize && col != '') {
            diskSize = col + 'MB'
            foundSize = true
          }
        })
      }
    })

    return {
      externalInterfaces: externalInterfaces,
      diskSize: diskSize,
    }
  }

  minimizeAppSvcLPinfoFromTenant = (tenant: any) => {
    // delete the app-svc-list & logical-partition-list and
    // put back bare min required data of these back
    // to avoid the session storage error
    if (tenant['app-svc-list']) {
      tenant['app-svc-list'] = tenant['app-svc-list'].map(
        (appService: IObject) => {
          const {
            name,
            'app-svc-type': appSvcType,
            id,
            state,
            uuid,
          } = appService
          return {
            name,
            'app-svc-type': appSvcType,
            id,
            state,
            uuid,
          }
        },
      )
    }

    if (tenant['logical-partition-list']) {
      tenant['logical-partition-list'] = tenant['logical-partition-list'].map(
        (logicalPartition: IObject) => {
          const {
            name,
            type,
            uuid: logicalPartitionUuid,
            deploy_target,
          } = logicalPartition
          const deviceList = deploy_target?.target?.['device-list'] || []
          return {
            name,
            type,
            uuid: logicalPartitionUuid,
            deploy_target: {
              target: {
                'device-list': deviceList.map((device: IObject) => {
                  const {
                    uuid: deviceUuid,
                    'partition-list': partitionList,
                  } = device
                  return {
                    uuid: deviceUuid,
                    'partition-list': partitionList.map(
                      (partition: IObject) => {
                        return { uuid: partition.uuid }
                      },
                    ),
                  }
                }),
              },
            },
          }
        },
      )
    }

    return tenant
  }

  // Sort by the latest version number
  sortAppVersionNumber = (aVersionDigits: any[], bVersionDigits: any[]) => {
    if (
      aVersionDigits[0] &&
      bVersionDigits[0] &&
      aVersionDigits[0] - bVersionDigits[0] === 0
    ) {
      if (
        aVersionDigits[1] &&
        bVersionDigits[1] &&
        aVersionDigits[1] - bVersionDigits[1] === 0
      ) {
        return (bVersionDigits[2] || 0) - (aVersionDigits[2] || 0)
      } else {
        return (bVersionDigits[1] || 0) - (aVersionDigits[1] || 0)
      }
    } else {
      return (bVersionDigits[0] || 0) - (aVersionDigits[0] || 0)
    }
  }

  // The Access Denied message if 403 status code or 400 with messgae from RBAC
  isRBACAccessDenied = (error: any) => {
    if (
      (error?.response?.status === 400 &&
        error?.response?.data?.response?.err?.from === 'RBA') ||
      error?.response?.status === 403
    ) {
      return true
    }
    return false
  }

  checkIPv6BaseURL = () => {
    const ipv6Regex = this.IPV6_REGEX
    const paramBaseUrl = parameters.BASE_URL.replace('https://', '')
      .replace('http://', '')
      .replace('/api/v2', '')

    return (
      ipv6Regex.test(paramBaseUrl) ||
      (paramBaseUrl.indexOf('[') > -1 && paramBaseUrl.indexOf(']') > -1)
    )
  }

  renderAppIcon = (app: string) => {
    let type = ''

    switch (app) {
      case 'adc':
      case 'slb': {
        type = 'adc-icon-active'
        break
      }
      case 'lightning': {
        type = 'lightning-adc-icon-active'
        break
      }
      case 'cgn': {
        type = 'cgn-icon-active'
        break
      }
      case 'gifw': {
        type = 'gi-firewall-icon-active'
        break
      }
      case 'gtp':
      case 'gtp-fw': {
        type = 'gtp-icon-active'
        break
      }
      case 'ssli': {
        type = 'ss-li-icon-active'
        break
      }
      default: {
        type = 'adc-icon-active'
        break
      }
    }

    return <A10Icon app="app-icons" type={type} style={{ fontSize: 28 }} />
  }

  openSlidingApp = (
    showSlidingPage: boolean,
    app: IObject,
    displayedVersion: string,
    setSlidingPage: Function,
    launchAppObj: IObject,
    launchAppURLPage: Function,
    handleChange: Function,
  ) => {
    return (
      <FormatSlidingPage
        isOpen={showSlidingPage}
        title={`${app?.name} ${displayedVersion}`}
        description={`Please select Tenant`}
        onRequestClose={setSlidingPage.bind(this, false)}
        saveText="Proceed"
        onRequestOk={() => launchAppURLPage(launchAppObj)}
      >
        <LaunchAppForm
          launchAppObj={launchAppObj}
          handleChange={handleChange}
          appLaunchWOApp={true}
        />
      </FormatSlidingPage>
    )
  }

  getLicensedApps = async (targetUrl: string) => {
    const {
      entitlements,
      status,
    } = await this.OrganizationService.getEntitlements(null, null, [
      storage.get.PROVIDER,
    ])

    const myAppsResponse = await this.DashboardService.getHCAppCatalog(
      null,
      null,
      [targetUrl],
    )

    //get API response
    const allMyApps: IObject[] = myAppsResponse?.data || []

    const licensed = status === 'SUCCESS' && !entitlements?.length
    return allMyApps.reduce((apps: IObject[], app: IObject) => {
      if (
        licensed ||
        entitlements.includes(APPLICATION_NAMES_MAP[app?.name]?.shortName)
      ) {
        apps.push(app)
      }
      return apps
    }, [])
  }

  getEntitlementsValue = (inputValue: any) => {
    let outputValue = ''
    if (inputValue && inputValue === -1) {
      outputValue = 'Unlimited'
    } else if (inputValue) {
      outputValue = inputValue
    }
    return outputValue
  }

  replaceAccentedCharacters = (input: string) => {
    return input?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') || ''
  }
}

export default Utilities
