import {
  A10Card,
  A10Col,
  A10Descriptions,
  A10Message,
  A10Row,
  A10DropdownMenu,
} from '@gui-libraries/widgets'
import { AuthenticationService, DashboardService } from 'src/services/index'
import React, { useEffect, useState } from 'react'

import { ContentHeader } from 'src/components/shared/ContentSection'
import Messages from 'src/locale/en/Messages'
import ReactLoading from 'react-loading'
import ServerFormAction from './ServerFormAction'
import '../styles/index.less'

export interface IEmailServerState {
  mailsmtphost: string
  mailsmtpport: string
  mailserverusername: string
  mailserverpassword: string
  mailfromaddress: string
  mailfrompersonal: string
  mailtoaddress: string
}
const authenticationService = new AuthenticationService()
const dashboardService = new DashboardService()
const messages = new Messages()

export const getServerData = async () => {
  let apiResponse: IEmailServerState[] = []
  try {
    const { data: response } = await authenticationService.getEmailServerData(
      null,
      null,
      null,
    )
    apiResponse = response
  } catch (error) {
    apiResponse = []
  }
  return apiResponse
}

const EmailServer = () => {
  const [isLoading, setLoading] = useState(false)
  const [slidingTitle, setSlidingTitle] = useState('')
  const [showSlidingPage, setSlidingPage] = useState(false)
  const [formData, setFormData] = useState<IEmailServerState[]>([])

  useEffect(() => {
    fetchServerData()
  }, [])

  const fetchServerData = async () => {
    const data = await getServerData()
    setFormData(data)
  }

  const onEditClick = (isOpen: boolean) => {
    setSlidingPage(isOpen)
    setLoading(false)
    setSlidingTitle('Edit Email Server Configuration')
  }

  const handleSave = async (inputPayload: IEmailServerState) => {
    const payload = { ...inputPayload }
    const serverPassword = new Buffer(inputPayload?.mailserverpassword || '').toString('base64')
    payload.mailserverpassword = serverPassword

    try {
      const response = await dashboardService.updatedEmailServerData(
        null,
        payload,
        null,
      )
      if (response) {
        const message = `${messages.EMAIL_SERVER_SUCCESS}`
        A10Message.success(message, 10, close)
        const data = await getServerData()
        setFormData(data)
        setSlidingPage(!!false)
      }
    } catch {
      const message = `${messages.EMAIL_SERVER_FAILED}`
      A10Message.error(message, 10, close)
    }
  }

  const sendTestEmail = async (inputPayload: IEmailServerState) => {
    const payload = { ...inputPayload }
    const serverPassword = new Buffer(inputPayload?.mailserverpassword || '').toString('base64')
    payload.mailserverpassword = serverPassword
    try {
      const response = await dashboardService.sendEmail(null, payload, null)
      if (response?.status === 200) {
        const message = `${messages.TEST_EMAIL_SUCCESS}`
        A10Message.success(message, 5, close)
      }
    } catch(err){
      const errorMsg = err?.response?.data
      const message = `${errorMsg}`
      A10Message.error(message, 5, close)
    }
  }

  const resetEmailServer = async () => {
    try {
      const response = await dashboardService.sendEmailServer(null, null, null)
      if (response?.status === 204) {
        const message = `${messages.RESET_EMAIL_SUCCESS}`
        A10Message.success(message, 5, close)
        fetchServerData()
      }
    } catch {
      const message = `${messages.RESET_EMAIL_FAILED}`
      A10Message.error(message, 5, close)
    }
  }

  const dropdownOptions = [
    <div key="edit">Edit</div>,
    <div key="send-email">Send Test Email</div>,
    <div key="reset">Reset Config</div>,
  ]

  const clickAction = (component: JSX.Element) => {
    if (component.key === 'edit') {
      onEditClick(true)
    } else if (component.key === 'send-email') {
      const payload : IObject = {
        mailtoaddress: formData?.['mailtoaddress'],
      }
      sendTestEmail(payload)
    } else if (component.key === 'reset') {
      resetEmailServer()
    }
  }

  return (
    <>
      <A10Row className='system-content-body'>
        <A10Card className='email-card'>
          <ContentHeader type="flex" align="middle" justify="space-between">
            <A10Col>
              <span className='system-page-heading'>{messages.EMAIL_SERVER}</span>
            </A10Col>
            <A10Col>
              <A10DropdownMenu
                menu={dropdownOptions}
                onClick={clickAction}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            </A10Col>
          </ContentHeader>
          <A10Descriptions
            title=""
            layout="vertical"
            size="small"
            className='serverDescription'
            column={{ xxl: 6, xl: 6, lg: 6, md: 2, sm: 2, xs: 1 }}
          >
            <A10Descriptions.Item label="Server Address">
              {formData?.['mailsmtphost']}
            </A10Descriptions.Item>
            <A10Descriptions.Item label="Server Port">
              {formData?.['mailsmtpport']}
            </A10Descriptions.Item>
            <A10Descriptions.Item label="Server User Name">
              {formData?.['mailserverusername']}
            </A10Descriptions.Item>
            <A10Descriptions.Item label="From Email Address">
              {formData?.['mailfromaddress']}
            </A10Descriptions.Item>
            <A10Descriptions.Item label="Email Name">
              {formData?.['mailfrompersonal']}
            </A10Descriptions.Item>
            <A10Descriptions.Item label="To Email Address">
              {formData?.['mailtoaddress']}
            </A10Descriptions.Item>
          </A10Descriptions>
        </A10Card>
      </A10Row>
      <ServerFormAction
        show={showSlidingPage}
        title={slidingTitle}
        formData={formData}
        onSubmitForm={handleSave}
        onSendTestEmail={sendTestEmail}
        onClose={() => setSlidingPage(!!false)}
      />
      {isLoading && (
        <div>
          <ReactLoading type="bars" color="#4a90e2" height={40} width={40} />
        </div>
      )}
    </>
  )
}

export default EmailServer
