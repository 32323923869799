import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  A10Row,
  A10Table,
  A10SlidingPage,
  A10Loader,
} from '@gui-libraries/widgets'
import { AutoFormV15, setUpAutoConfig } from '@gui-libraries/apps'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import Card from 'src/components/shared/Card'
import { Utilities } from 'src/services'
import ActionButton from 'src/components/shared/ActionButton'

import styles from './styles/index.module.less'

export interface IScaleoutOverviewProps {
  devices: IObject[]
  clustersUpdated: boolean
}

const utilities = new Utilities()

const ScaleoutOverview: React.FC<IScaleoutOverviewProps> = props => {
  const container = useRef(null)
  const { devices = [], clustersUpdated } = props
  const [loading, setLoading] = useState(false)
  const [formattedData, setFormattedData] = useState([])
  const [slidingPageOpen, setSlidingPageOpen] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState({
    device: '',
    clusterId: null,
  })

  const provider = storage.get.PROVIDER

  const openFormPage = useCallback(() => setSlidingPageOpen(true), [])
  const closeFormPage = useCallback(() => setSlidingPageOpen(false), [])

  const submitCallback = () => {
    closeFormPage()
    getData()
  }

  const columns = [
    {
      title: 'Device Name',
      dataIndex: 'name',
      sorter: (a: string, b: string) => utilities.sortString(a, b, 'name'),
    },
    {
      title: 'Device ID',
      dataIndex: 'id',
      sorter: (a: string, b: string) => utilities.sortString(a, b, 'id'),
    },
    {
      title: 'Device IP',
      dataIndex: 'ip',
      sorter: (a: string, b: string) => utilities.sortString(a, b, 'ip'),
    },
    {
      title: 'State',
      dataIndex: 'state',
      sorter: (a: string, b: string) => utilities.sortString(a, b, 'state'),
    },
    {
      title: 'Cluster Mode',
      dataIndex: 'mode',
      sorter: (a: string, b: string) => utilities.sortString(a, b, 'mode'),
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      sorter: (a: string, b: string) => utilities.sortString(a, b, 'priority'),
    },
    {
      title: '',
      dataIndex: 'action',
      render: (text: any, record: IObject) => {
        const { name, clusterId } = record
        return (
          <ActionButton
            text="Edit"
            size="default"
            onClick={() => {
              setSelectedDevice({
                device: name,
                clusterId,
              })
              openFormPage()
            }}
            iconProps={{ app: 'global', type: 'edit' }}
          />
        )
      },
    },
  ]

  const getData = () => {
    if (!provider || devices.length === 0) return
    setLoading(true)
    Promise.all(
      devices.map(device =>
        httpClient.get(
          `/hpcapi/v3/provider/${provider}/device/${device.name}/partition/shared/scaleout/cluster`,
        ),
      ),
    )
      .then(responses => {
        const data = responses.reduce((acc, res, i) => {
          const clusters = res?.data?.['cluster-list'] || []
          const [firstCluster = {}] = clusters
          const {
            'cluster-id': clusterId,
            'local-device': localDevice,
            'cluster-devices': clusterDevices,
          } = firstCluster
          const name = devices[i].name
          const id = localDevice?.id
          const ip = clusterDevices?.['device-id-list']?.find(
            (el: IObject) => el?.['device-id'] === id,
          )?.ip
          acc.push({
            key: name,
            name: name,
            clusterId,
            id,
            ip,
            state: localDevice?.action
              ? localDevice.action === 'enable'
                ? 'Enabled'
                : 'Disabled'
              : undefined,
            mode: localDevice?.['cluster-mode'],
            priority: localDevice?.priority,
          })
          return acc
        }, [])
        setFormattedData(data)
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false)
      })
  }

  const renderForm = () => {
    const { device, clusterId } = selectedDevice
    const apiPrefix = `/hpcapi/v3/provider/${provider}/device/${device}/partition/shared/`
    const interceptor: IObject = {
      onSubmitSuccess: (
        sformUtils: IObject,
        response: IObject[] = [],
        names: string[],
        formData: Map<string, any>,
        description: string,
        SOPEnable: boolean,
      ) => {
        submitCallback()
      },
      onSubmitError: (sformUtils: IObject, error: IObject, name: string[]) => {
        submitCallback()
      },
      onCancel: closeFormPage,
    }

    setUpAutoConfig({
      ENV_CONSTS: {
        IS_SHARED_PARTITION: true,
        PARTITION_TYPE: 'SP',
      },
    })

    return (
      <AutoFormV15
        schemaPath="network/mldb/scaleout"
        hccEnv={true}
        apiPrefix={apiPrefix}
        params={{ 'cluster-id': clusterId }}
        enableSOP={true}
        interceptor={interceptor}
      />
    )
  }

  useEffect(() => {
    getData()
  }, [provider, devices, clustersUpdated])

  return (
    <Card title="Scaleout Device List">
      {devices.length > 0 ? (
        <>
          <A10Row>
            <div ref={container} className={styles.sectionContent}>
              {loading ? (
                <A10Loader container={container} />
              ) : (
                <A10Table
                  columns={columns}
                  dataSource={formattedData}
                  bordered={false}
                />
              )}
            </div>
          </A10Row>
          <A10SlidingPage
            isOpen={slidingPageOpen}
            modalSize="large"
            onRequestClose={closeFormPage}
          >
            {renderForm()}
          </A10SlidingPage>
        </>
      ) : (
        <span>No information to show</span>
      )}
    </Card>
  )
}

export default ScaleoutOverview
