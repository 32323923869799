import React from 'react'
import propTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10SlidingPage } from '@gui-libraries/widgets'

import { IDefaultMethods } from 'src/containers/Controller'
import { ApplicationConfigs } from 'src/constants/ApplicationConfigs'
import { AppRoot } from 'src/settings/appRoot'
import { AppServices } from './AppServices'
import { LogicalPartition } from './LogicalPartition'
import { Utilities } from 'src/services/index'
import storage from 'src/libraries/storage'
import { httpClient } from 'src/libraries/httpClient'
import { DashboardService } from 'src/services/DashboardService'

export interface IServicesProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  tenantToggled: boolean
  onSelectApp: (selectedApp: string) => void
  selectedApp: any
}
export interface IServicesState {
  formComponent: JSX.Element | null
  modalSize?: string
  application: any
  selectedTenant: IObject
  selectedShareObject: string
  appServices: IObject[]
  clusters: IObject[]
}

class Services extends A10Container<IServicesProps, IServicesState> {
  static childContextTypes = {
    openSlidingPage: propTypes.func.isRequired,
    closeSlidingPage: propTypes.func.isRequired,
    setModalSize: propTypes.func,
  }

  DashboardService = new DashboardService()
  state: IServicesState = {
    formComponent: null,
    modalSize: '',
    application: {
      subApps: [],
      name: '',
    },
    selectedShareObject: '',
    selectedTenant: {},
    appServices: [],
    clusters: [],
  }
  ApplicationConfigs = new ApplicationConfigs()
  AppRoot = new AppRoot()
  Utilities = new Utilities()

  async componentDidMount() {
    const {
      remove
    } = storage

    remove.CONTEXT_LEVEL()
    remove.DGF_SELECTED_TENANT()
    remove.DGF_ACCOUNTS()
    remove.SELECTEDCONTEXT()
    remove.AUTH()
    remove.CONTEXT()

    this.updateState()
    await this.getClusters()
  }

  async componentDidUpdate(prevProps: IServicesProps) {
    const { tenantToggled } = this.props
    if (prevProps.tenantToggled !== tenantToggled) {
      this.updateState()
    }
  }

  setTenant = (tenant: any) => {
    const { set } = storage
    set.CURRENT_TENANT(tenant[0])
    this.AppRoot.setRootScopeElement('currentTenant', tenant[0])
  }

  updateState = () => {
    const {
      get: { ADMIN_LEVEL, CURRENT_TENANT },
      remove,
      set,
    } = storage

    const { selectedApp, onSelectApp } = this.props
    const applications = this.ApplicationConfigs.getAvailableApps()
    const applicationIndex = applications.findIndex((key: any) => {
      return key.name.toLowerCase() === 'services'
    })
    let selectedAppDetail = applications[applicationIndex].subApps.find(
      (subApp: any) => {
        return subApp.name.toLowerCase() === selectedApp.toLowerCase()
      },
    )

    if (!selectedAppDetail) {
      selectedAppDetail = applications[applicationIndex].subApps[0]
    }

    const allowedRoles = this.ApplicationConfigs.getAllowedRolesSubApps(
      'Services',
      selectedAppDetail.name,
    )
    if (allowedRoles) {
      const adminLevel = ADMIN_LEVEL
      const allowedIndex = allowedRoles.findIndex((key: any) => {
        return key === adminLevel
      })
      if (allowedIndex >= 0) {
        this.setState({
          application: applications[applicationIndex],
          selectedTenant: CURRENT_TENANT,
        })
        remove.SUPER_ADMIN_MODE()
        if (selectedAppDetail.tenantContext) {
          set.DRILL_LEVEL('tenant')
        } else {
          set.DRILL_LEVEL('provider')
        }
        set.CURRENT_STATE('services')
        this.AppRoot.setRootScopeElement('currentDrillLevel', 'tenant')
        this.AppRoot.setRootScopeElement('currentState', 'services')

        if (selectedAppDetail.name !== selectedApp) {
          if (onSelectApp instanceof Function) {
            onSelectApp(selectedAppDetail.name)
          }
        }
        return
      }
    }
    this.Utilities.showMessage('INSUFFICENT_PRIVILEGE', 0, 1)
  }

  updateTenant = () => {
    const {
      get: { CURRENT_TENANT },
    } = storage

    this.AppRoot.setRootScopeElement('currentTenant', CURRENT_TENANT)
    this.setState({
      selectedTenant: CURRENT_TENANT,
    })
  }

  getChildContext() {
    return {
      openSlidingPage: this.openSlidingPage,
      closeSlidingPage: this.closeSlidingPage,
      setModalSize: this.setModalSize,
    }
  }
  openSlidingPage = (formComponent: JSX.Element, modalSize?: string) => {
    this.setState({ formComponent, modalSize })
  }
  setModalSize = (modalSize: string) => {
    this.setState({ modalSize })
  }
  closeSlidingPage = () => {
    this.setState({ formComponent: null, modalSize: '' })
  }

  updateSelectedShareObject = (name: string) => {
    this.setState({ selectedShareObject: name })
  }

  getSelectedApp = () => {

    const { tenantToggled, defaultMethods } = this.props
    const { selectedTenant } = this.state
    const selectedApp = this.props.selectedApp.toLowerCase()

    let app = (
      <AppServices
        tenant={selectedTenant}
        defaultMethods={defaultMethods}
        tenantToggled={tenantToggled}
      />
    )

    if (selectedApp === 'appservices') {
      app = (
        <AppServices
          tenant={selectedTenant}
          defaultMethods={defaultMethods}
          tenantToggled={tenantToggled}
        />
      )
    } else if (selectedApp === 'logicalpartitions') {
      app = <LogicalPartition tenant={selectedTenant} />
    } 

    return app
  }

  getClusters = async () => {
    const {
      get: { PROVIDER_CLUSTERS, PROVIDER },
      set,
    } = storage

    let clusters = PROVIDER_CLUSTERS
    if (clusters.length === 0) {
      const { data = {} } = await httpClient.get(
        `/hpcapi/v3/provider/${PROVIDER}/cluster?detail=true`,
      )
      clusters = data['cluster-list'] || []
      set.PROVIDER_CLUSTERS(clusters)
    }

    this.setState({ clusters })
  }

  render() {
    const {
      get: { IS_INFRA_USER },
    } = storage

    if (IS_INFRA_USER) {
      const uri = '/controller/Home'
      return <Redirect to={uri} />
    }
    const { formComponent, modalSize, selectedTenant } = this.state
    return !!selectedTenant.name ? (
      <div>
        {this.getSelectedApp()}
        <A10SlidingPage
          isOpen={!!formComponent}
          modalSize={modalSize}
          onRequestClose={this.closeSlidingPage}
        >
          {formComponent ? (
            <formComponent.type {...formComponent.props} />
          ) : null}
        </A10SlidingPage>
      </div>
    ) : null
  }
}

export default setupA10Container(Services)
