import { UrlService, AjaxService } from 'src/services'

class MonitorService {
  // Instantiating instances of imported services
  UrlService = new UrlService()
  AjaxService = new AjaxService()

  getWorkflows = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_WORKFLOWS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    const allPayload = payload instanceof Object ? payload : {}

    const {
      // total, start, size,            // To do: api need support pagination
      // workflowType, workflowStatus, // To do: api need support search
      // startTime, endTime,           // To do: api need support sort
      ...restPayload
    } = allPayload as IObject

    const queryParam: IObject[] = []
    // if (total) {                   // To do: api need support pagination
    //   queryParam.push({key: 'total', value: 'true'})
    // } else if (!isNaN(Number(start)) && !isNaN(Number(size))) {
    //   queryParam.push({key: 'start', value: start}, {key: 'size', value: size})
    // }

    // if (workflowType) {             // To do: api need support search
    //   queryParam.push({key: 'workflowType', value: workflowType})
    // } else if (workflowStatus) {
    //   queryParam.push({key: 'workflowStatus', value: workflowStatus})
    // }

    // if (startTime) {                // To do: api need support sort
    //   queryParam.push({key: 'startTime', value: startTime})
    // } else if (endTime) {
    //   queryParam.push({key: 'endTime', value: endTime})
    // }

    queryParam.forEach((param: IObject, index: number) => {
      if (index === 0) {
        urlObj.URL = urlObj.URL + `?${param.key}=${param.value}`
      } else {
        urlObj.URL = urlObj.URL + `&${param.key}=${param.value}`
      }
    })

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      restPayload,
    )
  }

  getWorkflowDetail = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_WORKFLOW_DETAIL')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
}
export default MonitorService
