import React from 'react'
import classnames from 'classnames'
import { _ } from '@gui-libraries/framework'
import {
  A10Row,
  A10Col,
  A10DropdownMenu,
  A10Table,
} from '@gui-libraries/widgets'

import Descriptions from 'src/components/shared/Descriptions'
import Card from '../Card'

import styles from './styles/index.module.less'

export enum Type {
  Description = 'description',
  Table = 'table',
  Custom = 'custom',
}

export type Record = {
  name: string
  span?: number
  data?: IObject | IObject[]
  type?: Type
  more?: JSX.Element | JSX.Element[]
  minWidth?: number
  columns?: IObject[]
  direction?: 'column' | 'row' // only used when type is description
  children?: JSX.Element | JSX.Element[] // only rendered when type is "custom"
}

interface ICardsProps {
  data: Record[]
  className?: string
}

const Cards: React.FC<ICardsProps> = props => {
  const { className, data } = props

  const renderContent = (record: Record) => {
    const { type = Type.Custom, data: recordData } = record

    if (_.isArray(recordData)) {
      switch (type) {
        case Type.Table: {
          const { columns } = record

          return (
            <A10Table
              columns={columns}
              dataSource={recordData}
              bordered={false}
            />
          )
        }
        case Type.Description: {
          const { direction } = record
          return <Descriptions data={recordData} direction={direction} />
        }
      }
    } else {
    }

    if (type === Type.Custom) {
      return record.children || null
    }

    return null
  }

  const renderToolbar = (record: Record) => {
    const { more } = record

    return (
      <>
        {_.isArray(more) && more.length > 0 ? (
          <A10DropdownMenu
            title=""
            menu={more}
            trigger="hover"
            placement="bottomRight"
            arrowPointAtCenter={true}
          />
        ) : (
          more
        )}
      </>
    )
  }

  return (
    <A10Row
      type="flex"
      gutter={[12, 12]}
      className={classnames(className, styles.cards)}
    >
      {data.map(record => {
        const { name, span = 12, minWidth = 0 } = record
        return (
          <A10Col span={span} style={{ minWidth }}>
            <Card
              className={styles.card}
              title={name}
              toolbar={renderToolbar(record)}
            >
              {renderContent(record)}
            </Card>
          </A10Col>
        )
      })}
    </A10Row>
  )
}

export default Cards
