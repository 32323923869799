import { _ } from '@gui-libraries/framework'
import * as basicUtils from 'src/containers/Controller/Dashboard/utils/basicUtils'

const defaultFilterMapping = {
  severity: {
    0: 'Emergency',
    1: 'Alert',
    2: 'Critical',
    3: 'Error',
    4: 'Warning',
    5: 'Notification',
    6: 'Info',
    7: 'Debug',
  },
}

const logMapping: IObject = {
  // PR and Accesslog
  alert: [
    { label: 'Cluster', key: 'cluster_id' },
    { label: 'Device', key: 'device_uuid' },
    // { label: 'Partition', key: 'p_id' },
    { label: 'App Svc Type', key: 'app_svc_type' },
    { label: 'App Svc Name', key: 'app_svc_id' },
    { label: 'Tenant Name', key: 'tenant' },
    { label: 'Trigger Name', key: 'rule_def_name' },
    { label: 'Alert Name', key: 'def_name' },
    { label: 'Severity', key: 'severity' },
  ],
  event: [
    { label: 'Cluster', key: 'cluster_id' },
    { label: 'Device', key: 'device_uuid' },
    // { label: 'Partition', key: 'p_id' },
    { label: 'Tenant', key: 'account_id' },
    // { label: 'App Svc Type', key: 'app_svc_type' },
    { label: 'Thunder Module', key: 'module' },
    { label: 'Message ID', key: 'msg_id' },
    // { label: 'Message Type', key: 'clientFamilyName' },
    { label: 'Severity', key: 'severity' },
  ],
  audit: [
    { label: 'License State', key: 'license' },
    { label: 'Entitlement Key', key: 'entitlement-key' },
    { label: 'Device', key: 'device-name' },
    { label: 'Log Source', key: 'source' },
  ],
}

const getSingleQuery = (
  field: string,
  startTime: number,
  endTime: number,
  logType: string,
  defaultFilter: IObject = {},
) => {
  return {
    rangeby: { start: startTime, end: endTime, field: 'ts' },
    type: logType,
    fields: [field],
    groupby: field,
    size: 10,
    filterby: { and: { ...defaultFilter } },
    aggregation: 'count',
  }
}

const getQueries = (
  list: string[],
  startTime: number,
  endTime: number,
  logType: string,
  defaultFilter: IObject = {},
) => {
  // const list = ['clientFamilyName', 'clientOSName']
  const obj = {} as IObject
  list.map((item: string) => {
    obj[item] = getSingleQuery(item, startTime, endTime, logType, defaultFilter)
  })
  return obj
}

const filterQuery: IObject = {
  event: (startTime: number, endTime: number, defaultFilter: IObject = {}) => {
    const queryList = [
      'cluster_id',
      'device_uuid',
      // 'app_svc_type',
      'account_id',
      'module',
      // 'p_id',
      // 'msg_id',
      // 'severity',
      // 'clientFamilyName',
      // 'clientFamilyName',
      // 'clientFamilyName',
      // 'clientFamilyName',
    ]
    const list = getQueries(
      queryList,
      startTime,
      endTime,
      'event',
      defaultFilter,
    )
    list.msg_id = {
      rangeby: { start: startTime, end: endTime, field: 'ts' },
      type: 'event',
      fields: ['msg_id'],
      groupby: 'msg_id',
      size: 5,
      filterby: { and: { ...defaultFilter } },
      aggregation: 'count',
    }
    list.severity = {
      rangeby: { start: startTime, end: endTime, field: 'ts' },
      type: 'event',
      fields: ['severity'],
      groupby: 'severity',
      size: 10,
      filterby: { and: { ...defaultFilter } },
      aggregation: 'count',
    }
    return list
  },
  alert: (startTime: number, endTime: number, defaultFilter: IObject = {}) => {
    const queryList = [
      // 'device_cluster',
      'cluster_id',
      'device_uuid',
      // 'p_id',
      'app_svc_type',
      'app_svc_id',
      // 'account_id',  // This is not used, remove it.
      'def_name',
      'rule_def_name',
      // 'severity',
      'tenant',
      // 'clientFamilyName',
      // 'clientFamilyName',
      // 'clientFamilyName',
      // 'clientFamilyName',
    ]
    const list = getQueries(
      queryList,
      startTime,
      endTime,
      'event',
      defaultFilter,
    )
    list.severity = {
      rangeby: { start: startTime, end: endTime, field: 'ts' },
      type: 'event',
      fields: ['severity'],
      groupby: 'severity',
      size: 10,
      filterby: { and: { ...defaultFilter } },
      aggregation: 'count',
    }
    return list
  },
}

const getSubFilterItems = (data: IObject, filterMapping: IObject) => {
  const filterItems = [] as IObject[]
  const itemMapping = {} as IObject
  if (_.isObject(data)) {
    _.forEach(data, (value: IObject, key: string) => {
      if (!key) {
        return
      }
      const fixName = filterMapping[key]
      let displayName = fixName ? fixName : key
      if (fixName) {
        itemMapping[_.toLower(fixName)] = key
        const tenant = basicUtils.getTenantByUuid(key)
        try {
          displayName = (tenant && tenant['display-name']) || displayName
        } catch {
          /* istanbul ignore next */
          console.log('Key List with display-name is undefined')
        }
      }
      filterItems.push({
        name: displayName,
        'total-count': _.first(_.values(value)),
      })
    })
  }
  return [filterItems, itemMapping]
}

const getFilterItems = (
  data: IObject,
  dataMapping: IObject[],
  filterMapping: IObject,
) => {
  const filters = dataMapping.map((item: IObject) => {
    const { key, label, type } = item
    const countData = data[key] || {}
    if (type === 'range') {
      return {
        label,
        type: 'scroll',
        min: 0,
        max: 999999,
        payload: { key, mapping: {} },
        filters: [],
      }
    }
    const [filterItems, itemMapping] = getSubFilterItems(
      countData,
      filterMapping[key] || {},
    )
    return {
      label,
      filters: filterItems,
      payload: { key, mapping: itemMapping },
    }
  })
  return filters
}

export const getFilterQuery = (type: string) => {
  const fixType = type === 'pr' ? 'accesslog' : type
  return filterQuery[fixType] || _.noop
}
export const getFilterFromString = (filterStr: string = '') => {
  const filters = filterStr.split(';')
  const filterData = {} as IObject
  filters.map((filter: string = '') => {
    const [key, value] = filter.split('=')
    if (key && value && value !== '*') {
      if (value === ',') {
        filterData[key] = ['']
      } else {
        const valueList = value.split(',')
        const correntList: string[] = []
        valueList.map((item: string) => {
          if (_.toLower(_.trim(item)) || _.toLower(_.trim(item)) === '') {
            // correntList.push(_.toLower(_.trim(item)))
            correntList.push(_.trim(item))
          }
        })
        filterData[key] = correntList
      }
    } else if (key && value === '') {
      filterData[key] = []
    }
  })
  return filterData
}

export const getFilterStrFromMapping = (filters: IObject[]) => {
  const filterStrs: string[] = []
  const apiFilters: IObject = {}
  const selectedData: IObject = {}
  _.mapKeys(filters, (value: IObject, key: string) => {
    const {
      checkedList = [],
      plains = [],
      type,
      rangeValues = [],
      payload,
    } = value
    if (type === 'scroll') {
      filterStrs.push(`${key}=${rangeValues.join(',')}`)
    } else if (checkedList.length !== 0) {
      if (plains.length === checkedList.length) {
        filterStrs.push(`${key}=*`)
      } else if (checkedList.length === 1 && checkedList[0] === '') {
        filterStrs.push(`${key}=,`)
        apiFilters[payload.key] = ''
        selectedData[key] = ['']
      } else {
        filterStrs.push(`${key}=${checkedList.join(',')}`)
        const correntList = checkedList.filter((item: string) => {
          return !!item || item === ''
        })
        const mapping = payload.mapping || {}
        const covertList = correntList.map((item: string) => {
          if (mapping[_.toLower(item)]) {
            return mapping[_.toLower(item)]
          }
          return item
        })
        apiFilters[payload.key] = covertList.join('|')
        selectedData[key] = correntList
      }
    } else if (checkedList.length === 0) {
      if (plains.length !== 0) {
        apiFilters[payload.key] = '--'
        filterStrs.push(`${key}=`)
        selectedData[key] = []
      }
    }
  })
  return [filterStrs.join(';'), apiFilters, selectedData]
}

export const getApiFilters = (groups: IObject[], data: {}) => {
  const apiFilters: IObject = {}
  groups.map((item: IObject) => {
    const mapping = item.payload.mapping || {}
    let selected = data[item.label]
    if (Array.isArray(selected)) {
      selected = selected.map((selectedStr: string) => {
        return mapping[_.toLower(selectedStr)]
          ? mapping[_.toLower(selectedStr)]
          : selectedStr
      })
      if (selected.length === 0) {
        apiFilters[item.payload.key] = '---'
      } else {
        apiFilters[item.payload.key] = selected.join('|')
      }
    }
  })
  return apiFilters
}

export const getFilters = (
  type: string,
  data: IObject,
  filterMapping: IObject,
) => {
  const newFilterMapping = Object.assign(filterMapping, defaultFilterMapping)
  const fixType = type === 'pr' ? 'accesslog' : type
  if (logMapping[fixType]) {
    return getFilterItems(data, logMapping[fixType] || [], newFilterMapping)
  }
  return null
}

export const getApiFiltersFromStr = (
  type: string,
  data: IObject,
  filterStr: string,
  filterMapping: IObject = {},
) => {
  const newFilterMapping = Object.assign(filterMapping, defaultFilterMapping)
  const filters = getFilters(type, data, newFilterMapping)
  const filterData = getFilterFromString(filterStr)
  return getApiFilters(filters, filterData)
}

export default getFilters
