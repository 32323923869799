import React from 'react'
import { TunnelGre, TunnelIPsec, TunnelVxLan } from './contents'

const TunnelContent: React.FC = () => {
  return (
    <div className="device-detail-container device-content">
      <TunnelIPsec />
      <TunnelVxLan />
      <TunnelGre />
    </div>
  )
}

export default TunnelContent
