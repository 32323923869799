import React, { useState } from 'react'
import { Drawer, Icon, Radio, Button, message } from 'antd'

import storage from 'src/libraries/storage'
import './SessionManager.css'

function SessionManager(props: any) {
  const {
    showSessionMenuDefault,
    setSessionMgrDisplay,
    setSessionMgrViaProp,
  } = props
  const [showSessionForm, setSessionFormVisibility] = useState(
    showSessionMenuDefault,
  )
  const [sessionTimeout, setSessionTimeout] = useState(
    storage.get.SESSION_TIMEOUT_INITIAL || 1,
  )

  const changeTimeout = function(e: any) {
    setSessionTimeout(e.target.value)
  }

  const handleClose = function() {
    setSessionFormVisibility(false)
    setSessionMgrDisplay(false)
    setSessionMgrViaProp(false)
  }

  const success = function() {
    handleClose()
    storage.set.SESSION_TIMEOUT(sessionTimeout)
    storage.set.SESSION_TIMEOUT_INITIAL(sessionTimeout)
    message.success({
      content: `Session timeout duration set to ${sessionTimeout} hr(s)`,
    })
  }

  return (
    <div className="wrapper sessn-mgr">
      <Drawer
        width={800}
        className="sessn-mgr-drawer"
        placement="right"
        closable={true}
        onClose={handleClose}
        visible={showSessionForm}
        bodyStyle={{ backgroundColor: '#f9f9f9' }}
      >
        <div className="sessn-mgr-title">Session Settings</div>
        <div className="sessn-mgr-body">
          <div className="sessn-mgr-body-wrapper">
            <div className="panel-header">
              <div className="title">
                <span className="title-pic">
                  <Icon type="pic-right" />
                </span>
                Extend Current Session
              </div>
            </div>
            <div className="sessn-mgr-config">
              <label className="session-duration">Session Duration</label>
              <Radio.Group
                className="session-duration-options"
                onChange={changeTimeout}
                value={sessionTimeout}
              >
                <Radio className="sessn-duration" value={1}>
                  1 Hour
                </Radio>
                <Radio className="sessn-duration" value={24}>
                  24 Hours
                </Radio>
                <Radio className="sessn-duration" value={72}>
                  72 Hours
                </Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
        <div className="sessn-mgr-footer">
          <Button
            type="primary"
            className="sessn-mgr-btn extend"
            onClick={success}
          >
            Extend Session
          </Button>
          <Button
            type="default"
            className="sessn-mgr-btn cancel"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </Drawer>
    </div>
  )
}

export default SessionManager
