import React, { useState, useEffect } from 'react'
import { _ } from '@gui-libraries/framework'
import { A10Icon, A10SlidingPage } from '@gui-libraries/widgets'
import { AutoFormV15 } from '@gui-libraries/apps'

import { InfrastructureService } from 'src/services/index'
import storage from 'src/libraries/storage'
import Cards, { Type } from 'src/components/shared/Cards'

const NetworkLLDP: React.FC = (props: IObject) => {
  const [showSlidingPage, setShowSlidingPage] = useState(false)
  const [notification, setNotificateion] = useState(false)
  const [dnsStr, setDNSStr] = useState('')
  const [ipStr, setIPStr] = useState('')
  const [ipv6Str, setIPv6Str] = useState('')

  const infrastructureService = new InfrastructureService()
  const getData = () => {
    const provider = storage.get.PROVIDER

    infrastructureService
      .getRequest(
        null,
        null,
        [provider, props.deviceObj.name, 'shared'],
        'GET_DEVICE_NETWORK_LLDP',
      )
      .then((res: IObject) => {
        try {
          setNotificateion(
            _.get(res, 'data.lldp.notification-cfg.notification', 0) === 1,
          )
          const dnsList = _.get(res, 'data.lldp.management-address.dns', [])
          setDNSStr((dnsList || []).map((dns: IObject) => dns.dns).join(';'))
          const ipList = _.get(
            res,
            'data.lldp.management-address.ipv4-addr',
            [],
          )
            .map((ipObj: IObject) => ipObj.ipv4)
            .join(';')
          setIPStr(ipList)
          const ipv6List = _.get(
            res,
            'data.lldp.management-address.ipv6-addr',
            [],
          )
            .map((ipObj: IObject) => ipObj.ipv6)
            .join(';')
          setIPv6Str(ipv6List)
        } catch (e) {
          console.log(e)
        }
      })
  }
  useEffect(() => {
    getData()
  }, [props.deviceObj.name, props.refreshTimer])

  const toggleSlidingPage = (isOpen: boolean) => {
    setShowSlidingPage(isOpen)
  }

  const handleSuccess = () => {
    getData()
    setShowSlidingPage(false)
  }

  const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/device/${props.deviceObj.name}/partition/shared`

  const cardContent = [
    {
      name: 'LLDP',
      type: Type.Description,
      span: 24,
      minWidth: 700,
      direction: 'column',
      data: [
        {
          Notification: !!notification ? 'Enabled' : 'Disabled',
          'Management DNS': dnsStr,
          'Management IPv4': ipStr,
          'Management IPv6': ipv6Str,
        },
      ],
      more: !storage.get.IS_OPERATOR_USER ? (
        <span
          className="edit-content"
          onClick={toggleSlidingPage.bind(this, true)}
        >
          <A10Icon app="global" type="edit" /> Edit
        </span>
      ) : null,
    },
  ]

  return (
    <div className="col-md-12 marginBottom10">
      <Cards data={cardContent} className="device-card" />
      <A10SlidingPage
        isOpen={showSlidingPage}
        modalSize="large"
        onRequestClose={toggleSlidingPage.bind(this, false)}
      >
        <AutoFormV15
          hccEnv={true}
          schemaPath="network/interfaces/lldp"
          apiPrefix={apiPrefix}
          interceptor={{
            onSubmitSuccess: handleSuccess,
            onCancel: toggleSlidingPage.bind(this, false),
          }}
          enableSOP={true}
          ENV_CONSTS={{
            IS_SHARED_PARTITION: true,
            PARTITION_TYPE: 'SHARED',
          }}
        />
      </A10SlidingPage>
    </div>
  )
}

export default NetworkLLDP
