import React from 'react'
import { NavLink } from 'react-router-dom'
import { A10Component, A10Context } from '@gui-libraries/framework'
import {
  A10Icon,
  A10Avatar,
  A10Tooltip,
  A10Popover,
} from '@gui-libraries/widgets'

import { OrganizationService } from 'src/services'
import { LoggedInUser } from 'src/libraries/loggedInUser'
import storage from 'src/libraries/storage'
import SessionManger from '../SessionManager'

// const styles = require('./styles/index.module.less')
import styles from './styles/index.module.less'

export interface IUserMenuProps {
  sessionMgmtMenuDisplay: any
  setSessionMgmtMenuDisplay: any
}
export interface IUserMenuState {
  username: string
  userObj: any
  roles: any
  showSlidingPage: boolean
  roleList: any
  accessGroupList: any
  menuVisible: boolean
  showSessionMgr: boolean
}

class UserMenu extends A10Component<IUserMenuProps, IUserMenuState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  LoggedInUser = new LoggedInUser()
  OrganizationService = new OrganizationService()

  authType: string

  constructor(
    props: IUserMenuProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: {
        get: { USER_NAME, USER_SESSION_AUTHTYPE },
      },
    } = context

    this.state = {
      username: USER_NAME,
      userObj: null,
      roles: [],
      showSlidingPage: false,
      roleList: [],
      accessGroupList: [],
      menuVisible: false,
      showSessionMgr: false,
    }
    this.setSessionMgrMenuDisplay = this.setSessionMgrMenuDisplay.bind(this)
    this.handleDisplay = this.handleDisplay.bind(this)
    this.getAccessGroups()
    this.getRoleList()
    this.loadProfileInfo()
  }

  setSessionMgrMenuDisplay(display: any) {
    this.setState({ showSessionMgr: display })
  }

  preventDefault(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({ showSlidingPage: isOpen })
  }

  // To get the list of Accesss groups from API
  getAccessGroups = () => {
    const {
      get: { PROVIDER },
    } = storage

    const accessGroupResponse = this.OrganizationService.getRbacAccessGroups(
      null,
      null,
      [PROVIDER],
    )
    accessGroupResponse
      .then((resp: any) => {
        const accessGroupList = resp?.data ? resp.data : []
        this.setState({ accessGroupList })
      })
      .catch((err: any) => {
        console.log('Access groups load failed')
      })
  }

  // To get the display name of the Accesss group
  getAccessGroupDisplayName = (accessGroupName: string) => {
    const accessGroupList: any[] = this.state.accessGroupList
    const accessGroup =
      accessGroupList &&
      accessGroupList.find((obj: any) => {
        return obj.name === accessGroupName
      })
    return accessGroup
      ? accessGroup['display-name'] || accessGroup['displayName']
      : accessGroupName
  }

  // To get the list of Roles
  getRoleList = async () => {
    const {
      get: { PROVIDER },
    } = storage

    const roleListResponse = this.OrganizationService.getRbacRoleList(
      null,
      null,
      [PROVIDER],
    )
    await roleListResponse
      .then((resp: any) => {
        const roleList = resp?.data ? resp.data : []
        this.setState({ roleList })
      })
      .catch((err: any) => {
        console.log('rbac role load failed')
      })
  }

  // To get the display name of the Role
  getRoleDisplayName = (roleName: string) => {
    const roleList: any[] = this.state.roleList
    const role =
      roleList &&
      roleList.find((obj: any) => {
        return obj.name === roleName
      })
    return role ? role['display-name'] || role['displayName'] : roleName
  }

  getHighestPriorityRole = (roles: any) => {
    const { roleList } = this.state
    let rolePriority = 999
    const highestRole = { ag: '', role: '' }
    roles &&
      roles.map((userRole: any) => {
        const role = roleList.find((obj: any) => {
          return obj.name === userRole.role
        })
        if (role && role.rolePriority < rolePriority) {
          rolePriority = role.rolePriority
          highestRole.ag = userRole.ag
          highestRole.role = userRole.role
        }
      })
    return highestRole
  }

  loadProfileInfo = () => {
    const {
      get: { USER_ID: userId },
    } = storage

    const profileResponse = this.OrganizationService.userProfile(
      { tenant: null },
      null,
      userId,
    )
    profileResponse
      .then((response: any) => {
        const userObj = response && response.data ? response.data : null

        const roleArray: any = []
        const roles = userObj['roles']
        roles.map((roleObj: any) => {
          const roles = roleObj ? Object.keys(roleObj) : []
          const role = {
            ag: roles[0],
            role: roleObj[roles[0]],
            type: '',
          }
          roleArray.push(role)
        })

        this.setState({ userObj, roles: roleArray })
      })
      .catch((error: any) => {
        // console.log(error)
      })
  }

  handleVisibleChange = (visible: boolean) => {
    this.setState({ menuVisible: visible })
  }

  handleDisplay = () => {
    this.setState({ showSessionMgr: true, menuVisible: false })
  }

  componentDidUpdate() {
    if (
      this.props.sessionMgmtMenuDisplay === true &&
      this.state.showSessionMgr !== true
    ) {
      this.setState({
        showSessionMgr: true,
      })
    }
  }

  render() {
    const showSessionMenu =
      this.state.showSessionMgr || this.props.sessionMgmtMenuDisplay
    const { userObj, roles, menuVisible } = this.state
    const highestRole =
      roles && roles.length === 1
        ? roles[0]
        : this.getHighestPriorityRole(roles)
    const accessGroupDisplayName = highestRole
      ? this.getAccessGroupDisplayName(highestRole.ag)
      : ''
    const roleDisplayName = highestRole
      ? this.getRoleDisplayName(highestRole.role) || highestRole.role
      : ''
    const initials =
      userObj && (
        userObj['first-name']
          ? (userObj['first-name'].charAt(0) +
            (userObj['last-name'] ? userObj['last-name'].charAt(0) : ''))
          : (userObj['email-id'] && userObj['email-id'].charAt(0).toUpperCase())
      )
    const OverlayContent = (
      <div>
        <ul>
          <li>
            <div>
              <NavLink to={'/controller/Settings'}>User Profile</NavLink>
            </div>
          </li>
          <li>
            <div
              className="item-container extend-user-session"
              onClick={this.handleDisplay}
            >
              <span>Session Settings</span>
            </div>
          </li>
          <li className={styles.logOut} onClick={this.LoggedInUser.logout}>
            <span>Log Out</span>
          </li>
          {/* <li>
            <div className="item-container user-name">
              <span>{userFullName}</span>
            </div>
          </li> */}
        </ul>
      </div>
    )
    const iconStyle = {
      transition: 'transform 0.3s',
      transform: 'rotate(-90deg)',
    }
    if (menuVisible) {
      iconStyle.transform = 'rotate(90deg)'
    }
    return (
      <>
        <A10Popover
          placement="bottomRight"
          content={OverlayContent}
          align={{ offset: [17, -14] }} // adjust position of overlay content
          overlayClassName={styles.userMenuOverlay}
          // overlayClassName={`left-nav-user-menu-overlay ${
          //   leftNavCollapsed ? 'sider-collpased' : 'sider-expanded'
          // }`}
          visible={this.state.menuVisible}
          trigger="hover"
          onVisibleChange={this.handleVisibleChange}
        >
          <div className={styles.userMenu}>
            <A10Tooltip
              title={
                <>
                  {`Access Group (Role): ${accessGroupDisplayName
                    ? accessGroupDisplayName +
                    (roleDisplayName ? ' (' + roleDisplayName + ')' : '')
                    : ''
                    }`}
                </>
              }
              align={{ offset: [10, 0] }}
              placement="right"
            >
              {initials ? (
                <A10Avatar size={24} className={styles.avatar}>
                  {initials}
                </A10Avatar>
              ) : (
                <A10Avatar icon="user" size={24} className={styles.avatar} />
              )}
            </A10Tooltip>
            <A10Icon
              className={styles.expandIcon}
              app="global"
              type="breadcrumb"
              style={iconStyle}
            />
          </div>
        </A10Popover>
        {showSessionMenu && (
          <SessionManger
            showSessionMenuDefault={showSessionMenu}
            setSessionMgrDisplay={this.setSessionMgrMenuDisplay}
            setSessionMgrViaProp={this.props.setSessionMgmtMenuDisplay}
          />
        )}
      </>
    )
  }
}

export default UserMenu
