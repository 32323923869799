import { getNS, IHTTPRequestOptions } from '@gui-libraries/framework'
import { _ } from '@gui-libraries/framework'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'

const HPCAPI_PREFIX = '/hpcapi/v3'

export const getClassList = (params: IObject): IHTTPRequestOptions => {
  return {
    namespace: getNS('CLASS_LIST'),
    request: async (epicDependence: IObject) => {
      try {
        let fileList = []
        const fileURL = `${HPCAPI_PREFIX}/provider/${storage.get.PROVIDER}/tenant/pso/logical-partition/pso/file/class-list`
        const { data: file } = await httpClient.get(fileURL, { params })

        if (file && file['class-list-list']) {
          fileList = file['class-list-list']
        }
        fileList = _.sortBy(fileList, ['name'])

        return fileList
      } catch (err) {
        console.error(err)
      }
      return []
    },
  }
}

export const getClassListCount = (searchText: string): IHTTPRequestOptions => {
  return {
    namespace: getNS('CLASS_LIST_COUNT'),
    request: async (epicDependence: IObject) => {
      try {
        const params: IObject = { total: true }
        if (searchText) {
          params.name = encodeURIComponent(searchText)
        }
        const fileURL = `${HPCAPI_PREFIX}/provider/${storage.get.PROVIDER}/tenant/pso/logical-partition/pso/file/class-list`
        const { data: file } = await httpClient.get(fileURL, { params })

        let fileListCount = 0
        if (file && file['total-count']) {
          fileListCount = file['total-count']
        }

        return fileListCount
      } catch (err) {
        console.error(err)
      }
      return 0
    },
  }
}
