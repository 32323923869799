import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import {
  A10Row,
  A10Col,
  A10Icon,
  A10Form,
  A10Input,
  A10Radio,
} from '@gui-libraries/widgets'
import { Utilities } from 'src/services'
import { Messages } from 'src/locale/en/Messages'
import { HelpInfo } from 'src/components/shared/HelpInfo'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'

import './styles/passwordresetform.scss'

export interface IPasswordResetFormProps extends IA10ContainerDefaultProps {
  form: any
  onRef?: any
  handleChange?: (data: any) => void
}
export interface IPasswordResetFormState {
  passwordReset: {}
  isLoading: boolean
}

class PasswordResetForm extends A10Container<
  IPasswordResetFormProps,
  IPasswordResetFormState
> {
  Messages = new Messages()
  Utilities = new Utilities()

  constructor(props: IPasswordResetFormProps) {
    super(props)

    this.state = {
      isLoading: false,
      passwordReset: {
        'reset-type': 'email',
      },
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    // this.props.onRef(undefined)
  }

  handleInputChange = (name: string, e: Event | any, index?: number) => {
    const passwordReset = this.state.passwordReset

    if (index !== undefined) {
      const valArr = this.state[passwordReset[name]]
      valArr[index] = e.target.value
      // @ts-ignore
      passwordReset[name] = valArr
      this.setState({ passwordReset })
      return
    }
    if (e.target) {
      if (e.target.type === 'checkbox') {
        passwordReset[name] = e.target.checked
      } else {
        passwordReset[name] = e.target.value
      }
    } else {
      passwordReset[name] = e
    }

    this.props.handleChange(passwordReset)
    this.setState({ passwordReset })
  }

  setPasswordFields() {
    if (this.state.passwordReset['reset-type'] !== 'set') {
      return
    }

    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 13 },
    }

    const { getFieldDecorator } = this.props.form

    return (
      <>
        <A10Form.Item {...formItemLayout} label={this.Messages.NEW_PASSWORD}>
          <div>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: this.Messages.USER_PASSWORD_REQUIRED,
                },
                {
                  validator: this.Utilities.validatePassword,
                  message: this.Messages.INVALID_PASSWORD,
                },
              ],
              initialValue: '',
            })(
              <A10Input
                type="password"
                placeholder={this.Messages.NEW_PASSWORD}
                onChange={this.handleInputChange.bind(this, 'password')}
              />,
            )}
          </div>
        </A10Form.Item>

        <A10Form.Item
          {...formItemLayout}
          label={'Confirm ' + this.Messages.NEW_PASSWORD}
        >
          <div>
            {getFieldDecorator('confirm-password', {
              rules: [
                {
                  required: true,
                  message: this.Messages.USER_PASSWORD_REQUIRED,
                },
                {
                  validator: this.Utilities.validatePassword,
                  message: this.Messages.INVALID_PASSWORD,
                },
              ],
              initialValue: '',
            })(
              <A10Input
                type="password"
                placeholder={'Repeat ' + this.Messages.NEW_PASSWORD}
                onChange={this.handleInputChange.bind(this, 'confirm-password')}
              />,
            )}
          </div>
        </A10Form.Item>
      </>
    )
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 13 },
    }
    return (
      <>
        <A10Form hideRequiredMark={true} layout="horizontal">
          <A10Panel
            title={
              <>
                <A10IconTextGroup
                  text={
                    <>
                      User Password
                      <span>
                        <HelpInfo
                          customCls="custom-tooltip-head"
                          placement="right"
                          title="User Password"
                          helpKey="HELP_MP_USER_PASSWORD"
                        />
                      </span>{' '}
                    </>
                  }
                  icon={
                    <A10Icon
                      style={{ width: 29, height: 29, marginRight: 12 }}
                      app="filters"
                      type="form-checks"
                      className="sliding-panel-icon"
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item {...formItemLayout}>
              <div>
                <A10Radio.Group
                  name="reset-type"
                  defaultValue={'email'}
                  onChange={this.handleInputChange.bind(this, 'reset-type')}
                >
                  <A10Radio value="email">Email Reset Instruction</A10Radio>
                  <A10Radio value="set">Set Password</A10Radio>
                </A10Radio.Group>
              </div>
            </A10Form.Item>

            {this.setPasswordFields()}
          </A10Panel>
        </A10Form>
      </>
    )
  }
}

export default setupA10Container(A10Form.create()(PasswordResetForm))
