import React from 'react'
import { _, A10Container } from '@gui-libraries/framework'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import Deploy from './Deploy'
import DeployDone from './DeployDone'

export interface IAssociateWrapperProps {
  isShow: boolean
  isSingle?: boolean
  onClickDeploy: any
  onClickCancel: any
  onUpdateDirty?: any
  dirtyList?: IObject[]
  loadDirtyList?: boolean
}

export interface IAssociateWrapperState {
  isShow: boolean
  isShowDeployDone: boolean
}
/**
 * This component is used in
 *    /ClusterList/index.tsx
 *    /AppServiceList/index.tsx
 */
class AssociateWrapper extends A10Container<
  IAssociateWrapperProps,
  IAssociateWrapperState
> {
  static defaultProps = {
    isSingle: false,
  }

  constructor(props: any) {
    super(props)
    this.state = {
      isShow: false,
      isShowDeployDone: false,
    }
  }

  closeSlidingPage = () => {
    this.props.onClickCancel()
  }

  doneAssociateDeploy = () => {
    this.props.onClickDeploy()
    this.setState({ isShowDeployDone: true })
  }

  closeDeployDoneSlidingPage = () => {
    this.props.onClickCancel()
    this.setState({ isShowDeployDone: false })
  }

  render() {
    const { isShow, isSingle, dirtyList = [], loadDirtyList } = this.props
    return (
      <React.Fragment>
        <FormatSlidingPage
          isOpen={isShow}
          onRequestClose={this.closeSlidingPage}
          onRequestOk={this.doneAssociateDeploy}
          saveText="Deploy"
        >
          <Deploy
            isSingle={isSingle}
            dirtyList={dirtyList}
            onToggleDirtyItem={this.props.onUpdateDirty}
            loadDirtyList={loadDirtyList}
          />
        </FormatSlidingPage>
        <FormatSlidingPage
          isOpen={this.state.isShowDeployDone}
          onRequestClose={this.closeDeployDoneSlidingPage}
          onRequestOk={this.closeDeployDoneSlidingPage}
          saveText="Hide"
          hideCancel={true}
        >
          <DeployDone />
        </FormatSlidingPage>
      </React.Fragment>
    )
  }
}

export default AssociateWrapper
