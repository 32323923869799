import React from 'react'
import parameters from 'parameters'
import socketIOClient from 'socket.io-client'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Badge, A10Icon } from '@gui-libraries/widgets'

import StatusBar from 'src/components/ADC/StatusBar'
import { Configs } from 'src/constants/Configs'
import Settings from 'src/containers/Controller/Dashboard/Settings'
import * as analysisUtils from 'src/containers/Controller/Dashboard/utils'
import * as basicUtils from 'src/containers/Controller/Dashboard/utils/basicUtils'
import { fetchClusterAndDevice } from 'src/containers/Controller/Dashboard/utils/basicApiUtils'
import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'

import './styles/alertnotification.scss'

export interface IAlertNotificationProps extends IA10ContainerDefaultProps {
  clearPoint?: number
  onUpdateAlerts?: (data: IObject[], endTime: number) => void
  onFocusOut?: () => void
}

export interface IAlertNotificationState {
  alertData: IObject[]
  dataEndTime: number
  objMapping: IObject
}

class AlertNotification extends A10Container<
  IAlertNotificationProps,
  IAlertNotificationState
> {
  Configs = new Configs()
  interval: number
  socket: any
  pager: {
    page: 1
    pageSize: 10000
  }
  endTime: number

  constructor(props: IAlertNotificationProps) {
    super(props)

    this.state = {
      alertData: [],
      dataEndTime: 0,
      objMapping: {},
    }

    this.initialize()
    this.getData()
  }

  initialize() {
    // initialize socket communication
    this.socket = socketIOClient(this.Configs.WEBSOCKET_BASE_URL + '/alerts', {
      path: '/socket.io/hcws',
      query: {
        base: parameters.BASE_URL,
        provider: storage.get.PROVIDER,
        token: storage.get.USER_SESSION_ID,
      },
    })

    // listen on connect_error
    this.socket.on('connect_error', (error: any) => {
      console.error(error)
    })

    // listen on alertResponseData
    this.socket.on('alertResponseData', async (resp: any = {}) => {
      if (this.props.clearPoint > this.endTime) {
        return
      }

      const [logs, , filterData] = basicUtils.analysisData(resp)
      const [objMapping] = await fetchClusterAndDevice(
        httpClient,
        logs,
        filterData,
      )
      const alertRecords = await analysisUtils.fetchAlertAndTrigger(
        httpClient,
        logs,
      )

      this.setState(
        {
          alertData: alertRecords,
          dataEndTime: this.endTime,
          objMapping,
        },
        () => {
          const { onUpdateAlerts } = this.props
          const { alertData, dataEndTime, objMapping } = this.state

          if (_.isFunction(onUpdateAlerts)) {
            onUpdateAlerts(
              alertData.map((alert: IObject) => {
                return {
                  alert,
                  object: _.get(objMapping, alert.device_uuid, {}),
                }
              }),
              dataEndTime,
            )
          }
        },
      )
    })
  }

  componentDidMount() {
    if (window.setInterval) {
      this.interval = window.setInterval(this.getData, 1000 * 30)
    }
  }

  componentWillUnmount() {
    if (window.clearInterval) {
      window.clearInterval(this.interval)
    }

    if (this.socket && this.socket.connected) {
      this.socket.close()
    }
  }

  shouldComponentUpdate(nextProps: IAlertNotificationProps) {
    const { clearPoint: nextPoint } = nextProps
    const { clearPoint } = this.props

    if (clearPoint < nextPoint) {
      this.setState({ alertData: [] }, () => {
        const { onUpdateAlerts } = this.props
        const { alertData, dataEndTime, objMapping } = this.state

        if (_.isFunction(onUpdateAlerts)) {
          onUpdateAlerts(
            alertData.map((alert: IObject) => {
              return {
                alert,
                object: _.get(objMapping, alert.device_uuid, {}),
              }
            }),
            dataEndTime,
          )
        }
      })

      return false
    }

    return true
  }

  getData = () => {
    const tenants = storage.get.ALLTENANTS
    if (_.isArray(tenants) && tenants.length > 0) {
      const timeRange = {
        startTime: Date.now() - 120000,
        endTime: Date.now(),
      }
      const lastView = storage.get.ALERT_LAST_VIEWED_TIMESTAMP
      if (lastView > timeRange.startTime) {
        timeRange.startTime = lastView
      }

      const alertsAPIInfo = analysisUtils.alertEventsAPIs(timeRange, this.pager)
      if (this.socket && this.socket.connected) {
        this.socketCall(alertsAPIInfo)
        this.endTime = timeRange.endTime
      } else {
        setTimeout(() => {
          if (this.socket && this.socket.connected) {
            this.socketCall(alertsAPIInfo)
            this.endTime = timeRange.endTime
          }
        }, 10000)
      }
    }
  }

  socketCall = (alertsAPIInfo: any) => {
    if (this.socket && this.socket.connected && alertsAPIInfo.tenantUuids) {
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        provider: storage.get.PROVIDER,
        Authorization: storage.get.ENCODED_SESSION_ID,
        'X-Account': alertsAPIInfo.tenantUuids,
      }

      this.socket.emit('requestAlertData', {
        requestInfo: {
          method: 'POST',
          ip: parameters.BASE_URL,
          endpoint: '/analytics/event/hc_event',
          payload: alertsAPIInfo.query,
        },
        headers,
      })
    }
  }

  getSeverityIcon = (record: IObject) => {
    const { objMapping } = this.state
    const { severity, device_uuid: deviceUUID } = record

    const deviceName = _.get(objMapping, [deviceUUID, 'name'], '')
    const deviceType = _.isString(deviceName) ? deviceName[0] : ''

    switch (severity) {
      case 0:
      case 1:
      case 2:
      case 3:
        return (
          <StatusBar
            tip="Top 4 Severity levels are: Emergency, Alert, Critical, and Error"
            text={deviceType}
            color="#e57373"
          />
        )
      case 4:
        return <StatusBar tip="Warning" text={deviceType} color="#ffb74d" />
      case 5:
        return <StatusBar tip="Normal" text={deviceType} color="#81c784" />
      case 6:
        return <StatusBar tip="Info" text={deviceType} color="#38a8ee" />
      case 7:
        return <StatusBar tip="Debug" text={deviceType} color="#ffe168" />
      default:
        return <StatusBar tip="Unknown" text={deviceType} />
    }
  }

  showAlertsPullup = () => {
    const {
      A10Dispatchers: { httpRequest },
      onFocusOut,
    } = this.props

    httpRequest({
      namespace: Settings.namespace.pullupViewCurrentStatus,
      request: async () => {
        return await 'max'
      },
    })
    httpRequest({
      namespace: Settings.namespace.pullupViewCurrentTab,
      request: async () => {
        return await 'alert'
      },
    })
    httpRequest({
      namespace: Settings.namespace.alertPullUpViewStatus,
      request: async () => {
        return await Date.now()
      },
    })

    if (_.isFunction(onFocusOut)) {
      onFocusOut()
    }
  }

  render = () => {
    const { alertData } = this.state

    const count = _.isArray(alertData) ? alertData.length : 0

    return (
      <A10Badge count={count} style={{ color: '#fff' }}>
        <A10Icon app="global" type="alert" islight={false} />
      </A10Badge>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {}
}
export default setupA10Container(AlertNotification, mapStateToProps)
