import React, { Suspense, lazy } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import ReactLoading from 'react-loading'
import parameters from 'parameters'
import {
  A10Container,
  A10Router,
  A10Route,
  setupA10Container,
  _,
  A10Context,
} from '@gui-libraries/framework'

import { AppContext } from '../../constants/index'
import { InfrastructureService } from 'src/services/index'
import storage from 'src/libraries/storage'

import './styles/root.scss'
import 'pace-progress/themes/yellow/pace-theme-flash.css'

//  ToDO: need fix 500Error
// import ErrorBoundary from './ErrorBoundary'
// tslint:disable-next-line
const pace = require('pace-progress')

// try to amend parameters' BASE_URL on the fly, one day we need to centralize dev configs
const { NODE_ENV, REACT_APP_HCC_HOST } = process.env
if (NODE_ENV !== 'production' && REACT_APP_HCC_HOST) {
  const apiPrefix = _.trimEnd(REACT_APP_HCC_HOST, '/')
  parameters.BASE_URL = apiPrefix
  storage.set.API(apiPrefix)
}

const Loading = () => {
  return (
    <div className="root-goe-loading-icon">
      <ReactLoading type="bars" color="#4a90e2" height={40} width={40} />
    </div>
  )
}
const Login = lazy(() => import('src/components/shared/Login'))
const ChangePassword = lazy(() =>
  import('src/components/shared/ChangePassword'),
)
const SetPassword = lazy(() => import('src/components/shared/SetPassword'))
const ResetPassword = lazy(() => import('src/components/shared/ResetPassword'))
const SetAuthentication = lazy(() =>
  import('src/components/shared/SetAuthentication'),
)
const Controller = lazy(() => import('src/containers/Controller'))
const ObjectExplorer = lazy(() =>
  import('src/components/shared/ObjectExplorer'),
)

interface IRootProps {}
interface IRootState {
  isGettingStartedVisible: boolean
}
class Root extends A10Container<IRootProps, IRootState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  AppContext = new AppContext()
  InfrastructureService = new InfrastructureService()
  constructor(props: IRootProps) {
    super(props)
    pace.start()
    this.importHighChart()
  }

  importHighChart = async () => {
    const { Highcharts } = await import('@gui-libraries/widgets')
      // tslint:disable-next-line, For E2E test
    ;(window as any).Highcharts = Highcharts
    Highcharts.setOptions({
      time: {
        useUTC: false,
      },
    })
  }

  recordActivity = () => {
    const {
      storage: { set },
    } = this.context
    const currTime = new Date().getTime()
    set.LAST_CLICKED_TIMESTAMP(currTime.toString())
  }

  autoLogout = () => {
    const {
      storage: { get, set, clear },
    } = this.context

    if (!get.USER_SESSION_ID) {
      return
    }
    // Auto logout on Session expire
    const currTime = new Date().getTime()
    const allowedDifference = this.AppContext.SESSION_IDLE_PERIOD * 60 * 1000 // min * seconds * milli seconds
    const lastClickedTimestamp = get.LAST_CLICKED_TIMESTAMP
    const diff = currTime - parseInt(lastClickedTimestamp, 10)

    if (!lastClickedTimestamp) {
      set.LAST_CLICKED_TIMESTAMP(currTime.toString())
    } else if (diff > allowedDifference) {
      const provider = get.PROVIDER
      clear()
      set.LASTALERT(true)
      if (provider !== null) {
        window.location.href = '/login/' + provider
      } else {
        window.location.href = '/login/root'
      }
    } else {
      set.LAST_CLICKED_TIMESTAMP(currTime.toString())
    }
  }

  PrivateRoute = ({ component, ...rest }: any) => {
    const {
      storage: { get },
    } = this.context

    return get.USER_SESSION_ID ? (
      <A10Route {...rest} component={component} />
    ) : (
      <Redirect
        to={{
          pathname: '/login/root',
        }}
      />
    )
  }

  onChange = () => {
    this.setState({ isGettingStartedVisible: false })
  }

  render() {
    return (
      <A10Router.Browser>
        {/* <ErrorBoundary> */}

        <div
          style={{ height: '100%' }}
          // what are these???
          onClick={this.recordActivity}
          onKeyPress={this.recordActivity}
          onMouseMove={this.recordActivity}
        >
          <Suspense fallback={<Loading />}>
            <Switch>
              <A10Route
                path="/controller/:applicationName"
                strict
                exact
                component={Controller}
              />
              <A10Route
                path="/controller/:applicationName/:selectedApp"
                component={Controller}
              />
              <A10Route path="/login/:provider" component={Login} />
              <A10Route path="/changePassword" component={ChangePassword} />
              <A10Route path="/setPassword" component={SetPassword} />
              <A10Route path="/resetPassword" component={ResetPassword} />
              <A10Route
                path="/confirm/:user/:token"
                component={SetAuthentication}
              />
              <this.PrivateRoute
                path="/launchers/object-explorer"
                component={ObjectExplorer}
              />
              <Redirect from="/" exact to="/login/root" />
              {/* <Redirect to={'/controller/404Error'} /> */}
            </Switch>
          </Suspense>
        </div>
      </A10Router.Browser>
    )
  }
}

export default setupA10Container(Root)
