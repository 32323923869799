import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Row, A10Col, A10Chart, A10Tag } from '@gui-libraries/widgets'
import './styles/lmlicensecharts.scss'
import { ApplicationConfigs } from 'src/constants/ApplicationConfigs/index'
import { DashboardService, Utilities } from 'src/services'

export interface ILMLicenseChartsProps extends IA10ContainerDefaultProps {
  licenseData: IObject
}
export interface ILMLicenseChartsState {}

class LMLicenseCharts extends A10Container<
  ILMLicenseChartsProps,
  ILMLicenseChartsState
> {
  ApplicationConfigs = new ApplicationConfigs()
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  constructor(props: ILMLicenseChartsProps) {
    super(props)
  }

  render() {
    const { licenseData } = this.props

    let mbuChartConfig = this.ApplicationConfigs.getLicenseGaugeChartConfig()
    let mduChartConfig = this.ApplicationConfigs.getLicenseGaugeChartConfig()

    let usedBW = { val: 0, unit: 'm' }
    const freeBW = this.Utilities.megaToGiga(
      licenseData['alloted-bandwidth'] || 0,
    )
    if (licenseData['alloted-bandwidth'] > 0 && freeBW.unit === 'm') {
      usedBW = { val: licenseData['used-bandwidth'], unit: 'm' }
    } else {
      if (licenseData['alloted-bandwidth'] === 0) {
        usedBW = this.Utilities.megaToGiga(licenseData['used-bandwidth'] || 0)
      } else {
        const val = licenseData['used-bandwidth'] / 1000
        usedBW = { val: parseFloat(val.toFixed(2)), unit: 'g' }
      }
    }

    if (
      licenseData['license-type'] === 'MBU' ||
      licenseData['license-type'].indexOf('FLEXPOOL') > -1 ||
      licenseData['license-type'] === 'MDU'
    ) {
      let usedBValue = usedBW.val
      let freeBValue = freeBW.val
      mbuChartConfig.title.text = `${usedBValue +
        (usedBW.unit === 'm' ? ' Mbps' : ' Gbps')}`
      mbuChartConfig.tooltip.pointFormat = `{series.name}: <b>{point.y} ${
        freeBW.unit === 'm' ? 'Mbps' : 'Gbps'
      }</b>`
      mbuChartConfig.series[0].data[0].y = usedBValue
      mbuChartConfig.series[0].data[0].color =
        usedBValue / freeBValue > 0.75 ? 'green' : '#fcb160'
      mbuChartConfig.series[0].data[1].y = freeBValue - usedBValue

      let usedDValue = licenseData['used-devices']
      let freeDValue = licenseData['alloted-devices']
      mduChartConfig.title.text = `${usedDValue}`
      mduChartConfig.tooltip.pointFormat = '{series.name}: <b>{point.y}</b>'
      mduChartConfig.series[0].data[0].y = usedDValue
      mduChartConfig.series[0].data[0].color =
        usedDValue / freeDValue > 0.75 ? 'green' : '#fcb160'
      mduChartConfig.series[0].data[1].y = freeDValue - usedDValue
    }

    return (
      <div className="license-guage-bar">
        <A10Row type="flex">
          <A10Col xs={24} sm={24} md={24} lg={12} className="panel">
            {licenseData['alloted-bandwidth'] > 0 ? (
              <A10Chart.Summary
                title="Bandwidth Usage"
                hideLegend={true}
                chartConfig={mbuChartConfig}
                className='bandwidth-text'
              />
            ) : (
              <div className="statsNumberSection">
                <div className="statsFontHeader">Bandwidth Usage</div>
                <div>
                  <div className="statsFontLarge">
                    {usedBW.val + (usedBW.unit === 'm' ? ' Mbps' : ' Gbps')}
                  </div>
                </div>
                <hr style={{ width: '50%', margin: '20px auto' }} />
                <div>
                  <div className="statsFontLarge">
                    {licenseData['alloted-bandwidth'] === 0
                      ? 'Unlimited'
                      : freeBW.val + (freeBW.unit === 'm' ? ' Mbps' : ' Gbps')}
                  </div>
                </div>
              </div>
            )}
          </A10Col>
          <A10Col xs={24} sm={24} md={24} lg={12} className="panel">
            {licenseData['alloted-devices'] > 0 ? (
              <A10Chart.Summary
                title="Activations"
                hideLegend={true}
                chartConfig={mduChartConfig}
              />
            ) : (
              <div className="statsNumberSection">
                <div className="statsFontHeader">Activations</div>
                <div>
                  <div className="statsFontLarge">
                    {licenseData['used-devices']}
                  </div>
                </div>
                <hr style={{ width: '50%', margin: '20px auto' }} />
                <div>
                  <div className="statsFontLarge">
                    {licenseData['alloted-devices'] === 0
                      ? 'Unlimited'
                      : licenseData['alloted-devices']}
                  </div>
                  {licenseData['license-type'] === 'Trial' ? (
                    <A10Tag color="#e57373" className="trial-tag">
                      Trial
                    </A10Tag>
                  ) : null}
                </div>
              </div>
            )}
          </A10Col>
        </A10Row>
      </div>
    )
  }
}

export default setupA10Container(LMLicenseCharts)
