import React from 'react'
import moment from 'moment'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10Modal,
  A10DropdownMenu,
  A10Loader,
  A10Col,
  A10Button,
  A10Input,
  A10Alert,
} from '@gui-libraries/widgets'

import { InfrastructureService } from 'src/services/InfrastructureService/InfrastructureService'
import { DashboardService } from 'src/services/DashboardService/DashboardService'
import { IDefaultMethods } from 'src/containers/Controller'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import Utilities from 'src/services/Utilities/Utilities'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { CredentialAddForm } from 'src/containers/Controller/Infrastructure/Credential/Forms/CredentialAddForm'
import { CredentialInfo } from 'src/containers/Controller/Infrastructure/Credential/Forms/CredentialInfo'
import storage from 'src/libraries/storage'
import { Messages } from 'src/locale/en/Messages'

import './styles/Credential.scss'

export interface ICredentialProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
}

export interface ICredentialState {
  formData: any
  editMode: boolean
  validateMode: boolean
  credentials: any[]
  searchString: string
  allCredentialData: any[]
  showSlidingPage: boolean
  deleteModalState: boolean
  credUsed: boolean
  selectedCredential: any
}

class Credential extends A10Container<ICredentialProps, ICredentialState> {
  Messages = new Messages()
  InfrastructureService = new InfrastructureService()
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  dataLoaded = false
  credentials: any[] = []
  ref = React.createRef()
  state = {
    formData: {
      infraEnvironment: '',
      accountName: '',
      vCenterIPAddress: '',
      userName: '',
      userPassword: '',
      awsType: 'accessKey',
      accessKey: '',
      secretAccess: '',
      roleARN: '',
      externalID: '',
      index: '',
      subscriptionID: '',
      tenantID: '',
      clientID: '',
      clientSecret: '',
      host: '',
      port: '',
      bearerToken: '',
      userID: '',
      tenancyID: '',
      regionIdentifier: '',
      sshKeyContent: '',
      sshKeyPassphrase: '',
      sshKeyFingerprint: '',
      editedBearerToken: '',
      editedSigningKey: '',
    },
    editMode: false,
    validateMode: true,
    credentials: [[]],
    searchString: '',
    allCredentialData: [],
    showSlidingPage: false,
    deleteModalState: false,
    credUsed: false,
    selectedCredential: { name: '' },
  }
  childForm: any = null
  adminLevel = storage.get.ADMIN_LEVEL
  isOperatorUser = storage.get.IS_OPERATOR_USER
  vmDevices: any = []

  credentialColumns = [
    {
      title: <HealthStatus type="header" tooltip="Status" />,
      dataIndex: 'state',
      key: 'state', // TODO: change this key
      render: (text: string, record: any, index: number) => {
        let status = 'stopped'
        if (text === 'UNVERIFIED') {
          status = 'warning'
        } else if (text === 'active') {
          status = 'ongoing'
        }
        return <HealthStatus type={status} text="C" tooltip={text} />
      },
    },
    {
      title: 'Environment',
      dataIndex: 'infra-provider',
      key: 'infra-provider',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'infra-provider'),
    },
    {
      title: 'Account Name',
      dataIndex: 'name',
      key: 'account_name',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
    },
    {
      title: 'Creation Date',
      dataIndex: 'creation-date-time',
      key: 'creation-date-time',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'creation-date-time'),
      render: (text: string, record: any, index: number) => {
        return (
          text &&
          moment(new Date(parseInt(text))).format('MMM DD, YYYY hh:mm A')
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (key: any, record?: any) => {
        const clickAction = (component: JSX.Element, index: number) => {
          if (component.key === 'edit') {
            this.editCredential(key, index)
          } else if (component.key === 'delete') {
            this.checkCredentialForDelete(key)
          } else {
            // console.log("credentials", key)
          }
        }
        return this.isOperatorUser ? null : (
          <div className="text-right">
            <i>
              <A10DropdownMenu
                menu={
                  this.adminLevel === 'provider'
                    ? [
                        <div key="edit">Edit</div>,
                        <div key="delete">Delete</div>,
                      ]
                    : []
                }
                title=""
                style={{ color: '#757575', marginBottom: '-15px' }}
                onClick={clickAction}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            </i>
          </div>
        )
      },
    },
  ]

  loadCredential = () => {
    const provider = storage.get.PROVIDER
    const credentialResponse = this.InfrastructureService.getCredentialInfraAccount(
      null,
      null,
      [provider],
    )

    credentialResponse
      .then((response: any) => {
        let credentials = []
        if (response && response.data && response.data['infra-account-list']) {
          credentials = response.data['infra-account-list'] || []
        }
        this.credentials = credentials
        this.dataLoaded = true
        this.setState({
          allCredentialData: credentials,
          credentials,
        })
      })
      .catch((error: any) => {
        console.log(error.response)
        this.credentials = []
        this.dataLoaded = true
        this.setState({
          allCredentialData: [],
        })
      })
  }

  loadVMDevices = async () => {
    const provider = storage.get.PROVIDER
    const headerObj =
      this.adminLevel === 'provider'
        ? null
        : this.Utilities.getXAccountHeaderDetails(false)

    return this.InfrastructureService.getVMDevices(headerObj, null, [provider])
  }

  checkCredentialForDelete = async (credential: any) => {
    if (!this.vmDevices?.length) {
      try {
        const resp = await this.loadVMDevices()

        let devices = []
        if (resp && resp.data && resp.data['virtual-device-list']) {
          devices = resp.data['virtual-device-list'] || []
        }
        this.vmDevices = devices
      } catch (e) {}
    }

    let credUsed = false
    this.vmDevices.map((vmDevice: any) => {
      if (vmDevice['owner-account-id'] === credential.uuid) {
        credUsed = true
      }
    })
    this.setState({
      deleteModalState: true,
      credUsed,
      selectedCredential: credential,
    })
  }

  SearchInfraCredential = (e: any) => {
    const searchString =
      e && e.target && (e.target.value || e.target.value === '')
        ? e.target.value
        : this.state.searchString
    this.Utilities.search(this, searchString, 'name', {
      storeData: [...this.credentials],
      stateName: 'allCredentialData',
    })
  }
  addCredential = () => {
    const formData = {
      infraEnvironment: '',
      accountName: '',
      vCenterIPAddress: '',
      userName: '',
      userPassword: '',
      awsType: 'accessKey',
      accessKey: '',
      secretAccess: '',
      roleARN: '',
      externalID: '',
      index: '',
      subscriptionID: '',
      tenantID: '',
      clientID: '',
      clientSecret: '',
      host: '',
      port: '',
      bearerToken: '',
      userID: '',
      tenancyID: '',
      regionIdentifier: '',
      sshKeyContent: '',
      sshKeyPassphrase: '',
      sshKeyFingerprint: '',
    }
    this.setState({
      formData,
      editMode: false,
      validateMode: true,
      showSlidingPage: true,
    })
  }
  editCredential = (credential: any, index: number) => {
    const formData = {
      infraEnvironment: credential['infra-provider'],
      accountName: credential.name,
      vCenterIPAddress: credential['vmware-account']
        ? credential['vmware-account']['vcenter-ip']
        : '',
      userName: credential['vmware-account']
        ? credential['vmware-account'].username
        : '',
      userPassword: credential['vmware-account']
        ? atob(credential['vmware-account'].password)
        : '',
      awsType: 'accessKey',
      accessKey: credential['aws-account']
        ? credential['aws-account']['access-key']
        : '',
      secretAccess: credential['aws-account']
        ? atob(credential['aws-account']['secret-access-key'])
        : '',
      roleARN: '',
      externalID: '',
      index,
      subscriptionID: credential['azure-account']
        ? credential['azure-account']['subscription-id']
        : '',
      tenantID: credential['azure-account']
        ? credential['azure-account']['tenant-id']
        : '',
      clientID: credential['azure-account']
        ? credential['azure-account']['client-id']
        : '',
      clientSecret: credential['azure-account']
        ? atob(credential['azure-account']['client-secret'])
        : '',
      host: credential['kubernetes-account']
        ? credential['kubernetes-account'].host
        : '',
      port: credential['kubernetes-account']
        ? credential['kubernetes-account'].port
          ? credential['kubernetes-account'].port
          : '6443'
        : '',
      bearerToken: credential['kubernetes-account']
        ? atob(credential['kubernetes-account']['bearer-token'])
        : '',
      editedBearerToken: credential['kubernetes-account']
        ? atob(credential['kubernetes-account']['bearer-token'])
        : '',
      userID: credential['oci-account']
        ? credential['oci-account']['user-id']
        : '',
      tenancyID: credential['oci-account']
        ? credential['oci-account']['tenancy-id']
        : '',
      regionIdentifier: credential['oci-account']
        ? credential['oci-account'].region
        : '',
      sshKeyContent: credential['oci-account']
        ? atob(credential['oci-account']['key-content'])
        : '',
      editedSigningKey: credential['oci-account']
        ? atob(credential['oci-account']['key-content'])
        : '',
      sshKeyPassphrase: credential['oci-account']
        ? atob(credential['oci-account'].passphrase)
        : '',
      sshKeyFingerprint: credential['oci-account']
        ? credential['oci-account'].fingerprint
        : '',
    }
    this.setState({
      formData,
      editMode: true,
      validateMode: true,
      showSlidingPage: true,
    })
  }
  setSlidingPage = (isOpen: boolean) => {
    this.setState({
      showSlidingPage: isOpen,
    })
  }
  handleFormChange = (data: any) => {
    // @ts-ignore
    this.setState({
      formData: data,
      validateMode: true,
    })
  }
  handleCredAddFormValidation = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }
  handleOk = () => {
    const { showMessage } = this.Utilities
    const provider = storage.get.PROVIDER
    const deleteCredential = this.InfrastructureService.deleteCredentialInfraAccount(
      null,
      null,
      [provider, this.state.selectedCredential.name],
    )
    deleteCredential
      .then(() => {
        showMessage('CREDENTIAL_DELETED', 1, 1)
        this.handleCancel()
        this.loadCredential()
      })
      .catch((error: any) => {
        showMessage('CREDENTIAL_DELETE_FAIL', 0, 1)
      })
  }

  handleCancel = () => {
    this.setState({
      deleteModalState: false,
      credUsed: false,
      selectedCredential: { name: '' },
    })
  }

  handleSave = () => {
    if (this.state.editMode) {
      this.handleUpdate()
    } else {
      const credentialPayload = this.credentialPayload()
      if (!this.validateDuplicateCredential(credentialPayload)) {
        return
      }
      this.handleAdd()
    }
  }

  credentialPayload = () => {
    const {
      infraEnvironment,
      accountName,
      vCenterIPAddress,
      userName,
      userPassword,
      awsType,
      accessKey,
      secretAccess,
      roleARN,
      externalID,
      subscriptionID,
      tenantID,
      clientID,
      clientSecret,
      host,
      port,
      bearerToken,
      userID,
      tenancyID,
      regionIdentifier,
      sshKeyContent,
      sshKeyPassphrase,
      sshKeyFingerprint,
      editedBearerToken,
      editedSigningKey,
    } = this.state.formData

    const credentialPayload: any = {
      'infra-account': {
        name: accountName,
        'infra-provider': infraEnvironment,
        'creation-date-time': this.state.editMode
          ? undefined
          : '' + new Date().getTime(),
        'modified-date-time': '' + new Date().getTime(),
      },
    }
    if (infraEnvironment === 'vmware') {
      credentialPayload['infra-account']['vmware-account'] = {
        'vcenter-ip': vCenterIPAddress,
        username: userName,
        password: btoa(userPassword),
      }
    } else if (infraEnvironment === 'aws') {
      if (awsType === 'accessKey') {
        credentialPayload['infra-account']['aws-account'] = {
          'access-key': accessKey,
          'secret-access-key': btoa(secretAccess),
        }
      } else {
        credentialPayload['infra-account']['aws-account'] = {
          'role-arn': roleARN,
          'external-id': externalID,
        }
      }
    } else if (infraEnvironment === 'azure') {
      credentialPayload['infra-account']['azure-account'] = {
        'subscription-id': subscriptionID,
        'tenant-id': tenantID,
        'client-id': clientID,
        'client-secret': btoa(clientSecret),
      }
    } else if (infraEnvironment === 'kubernetes') {
      credentialPayload['infra-account']['kubernetes-account'] = {
        host,
        port: port ? port : '6443',
        'bearer-token': this.state.editMode
          ? btoa(editedBearerToken)
          : btoa(bearerToken),
      }
    } else if (infraEnvironment === 'oci') {
      credentialPayload['infra-account']['oci-account'] = {
        'user-id': userID,
        'tenancy-id': tenancyID,
        region: regionIdentifier,
        'key-content': this.state.editMode
          ? btoa(editedSigningKey)
          : btoa(sshKeyContent),
        fingerprint: sshKeyFingerprint,
        passphrase: btoa(sshKeyPassphrase),
      }
    }
    return credentialPayload
  }

  validateDuplicateCredential = (credentialPayload: any) => {
    const { showMessage } = this.Utilities

    if (credentialPayload['infra-account']['infra-provider'] === 'aws') {
      const credsAWSAccount = credentialPayload['infra-account']['aws-account']
      const awsCreds = this.state.credentials.filter(
        creds => creds['infra-provider'] === 'aws',
      )
      for (let i = 0; i < awsCreds.length; i++) {
        if (
          credsAWSAccount['access-key'] ===
          awsCreds[i]['aws-account']['access-key']
        ) {
          showMessage('An account with this access key already exists.', 0, 0)
          return false
        }
      }
    } else if (
      credentialPayload['infra-account']['infra-provider'] === 'azure'
    ) {
      const credsAzureAccount =
        credentialPayload['infra-account']['azure-account']
      const azureCreds = this.state.credentials.filter(
        creds => creds['infra-provider'] === 'azure',
      )
      for (let i = 0; i < azureCreds.length; i++) {
        if (
          credsAzureAccount['subscription-id'] ===
            azureCreds[i]['azure-account']['subscription-id'] &&
          credsAzureAccount['tenant-id'] ===
            azureCreds[i]['azure-account']['tenant-id'] &&
          credsAzureAccount['client-id'] ===
            azureCreds[i]['azure-account']['client-id']
        ) {
          showMessage(
            'An account with this subscription Id, tenant Id and client/application Id combination already exists.',
            0,
            0,
          )
          return false
        }
      }
    } else if (
      credentialPayload['infra-account']['infra-provider'] === 'kubernetes'
    ) {
      const credsK8sAccount =
        credentialPayload['infra-account']['kubernetes-account']
      const k8sCreds = this.state.credentials.filter(
        creds => creds['infra-provider'] === 'kubernetes',
      )
      for (let i = 0; i < k8sCreds.length; i++) {
        if (
          credsK8sAccount.host === k8sCreds[i]['kubernetes-account'].host &&
          credsK8sAccount.port === k8sCreds[i]['kubernetes-account'].port &&
          credsK8sAccount['bearer-token'] ===
            k8sCreds[i]['kubernetes-account']['bearer-token']
        ) {
          showMessage(
            'An account with this host, port and bearer-token combination already exists.',
            0,
            0,
          )
          return false
        }
      }
    }
    return true
  }

  handleUpdate = () => {
    const { showMessage } = this.Utilities
    const credentialPayload = this.credentialPayload()

    const provider = storage.get.PROVIDER
    const credentialAdd = this.InfrastructureService.updateCredentialInfraAccount(
      null,
      credentialPayload,
      [provider, this.state.formData.accountName],
    )
    credentialAdd
      .then((response: any) => {
        showMessage('CREDENTIAL_UPDATED', 1, 1)
        this.setSlidingPage(false)
        this.dataLoaded = false
        this.loadCredential()
      })
      .catch((error: any) => {
        const msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        showMessage(this.Messages.CREDENTIAL_UPDATE_FAIL, 0, 0, msg)
      })
    return false
  }

  handleAdd = () => {
    const { showMessage } = this.Utilities
    const credentialPayload = this.credentialPayload()

    const provider = storage.get.PROVIDER
    const credentialAdd = this.InfrastructureService.addCredentialInfraAccount(
      null,
      credentialPayload,
      [provider],
    )
    credentialAdd
      .then((response: any) => {
        showMessage('CREDENTIAL_CREATED', 1, 1)
        this.setSlidingPage(false)
        this.dataLoaded = false
        this.loadCredential()
      })
      .catch((error: any) => {
        const msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        showMessage(this.Messages.CREDENTIAL_CREATE_FAIL, 0, 0, msg)
      })
    return false
  }

  renderCrendentialDetails = (record: any) => {
    return <CredentialInfo credential={record} />
  }

  componentDidMount() {
    if (!this.dataLoaded) {
      this.loadCredential()
    }
  }

  render() {
    const { showSlidingPage } = this.state
    return (
      <>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col className="title-container">
            <ContentTitle
              title="Cloud Account Credentials"
              count={this.state.allCredentialData.length}
            />
          </A10Col>
          <A10Col style={{ display: 'flex' }}>
            <A10Input.Search
              type="text"
              onChange={this.SearchInfraCredential}
              onSearch={this.SearchInfraCredential}
              name="searchBox"
              value={this.state.searchString}
              placeholder="Search"
              style={{ marginRight: '6px' }}
            />
            {this.adminLevel === 'provider' && !this.isOperatorUser && (
              <ActionButton
                text="Add Credential"
                onClick={this.addCredential.bind(this)}
                iconProps={{ app: 'global', type: 'add-new' }}
              />
            )}
          </A10Col>
        </ContentHeader>
        <ContentBody ref={this.ref}>
          <A10Table
            columns={this.credentialColumns}
            expandedRowRender={this.renderCrendentialDetails}
            dataSource={this.state.allCredentialData.map(
              (key: any, index: number) => {
                key.key = index
                return key
              },
            )}
            size="small"
            loading={
              !this.dataLoaded && {
                indicator: <A10Loader container={this.ref} />,
              }
            }
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
          />
        </ContentBody>

        <FormatSlidingPage
          isOpen={showSlidingPage}
          onRequestOk={this.handleCredAddFormValidation}
          onRequestClose={this.setSlidingPage.bind(this, false)}
          title={
            <div
              style={{
                color: '#4a90e2',
                display: 'inline-block',
                fontWeight: 400,
              }}
            >
              {this.state.editMode ? 'Edit' : 'Add'} Credential
            </div>
          }
          description="Please provide the account details"
        >
          <CredentialAddForm
            handleChange={this.handleFormChange}
            defaultMethods={this.props.defaultMethods}
            onSubmitForm={this.handleSave}
            formData={this.state.formData}
            editMode={this.state.editMode}
            credentials={this.state.allCredentialData}
            onRef={(ref: any): any => (this.childForm = ref)}
          />
        </FormatSlidingPage>
        <A10Modal
          title="Delete Credential"
          visible={this.state.deleteModalState}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.handleOk}
              className="yesbtn"
              disabled={this.state.credUsed}
            >
              Yes
            </A10Button>,
          ]}
        >
          {this.state.credUsed ? (
            <A10Alert
              message="Warning"
              description={
                'Cannot delete the credential - ' +
                this.state.selectedCredential.name +
                ', as it is being referred by virtual-devices'
              }
              type="warning"
            />
          ) : (
            <p>Do you want to delete {this.state.selectedCredential.name}</p>
          )}
        </A10Modal>
      </>
    )
  }
}

export default setupA10Container(Credential)
