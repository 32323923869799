import { useCallback, useState } from 'react'
import { useAsyncEffect, _ } from '@gui-libraries/framework'
import { RequestBuilder } from '@gui-libraries/dgf'

function useDashboardAlerts(
  startTime: any,
  endTime: any,
  service: any,
  api: string,
) {
  const [formattedData, setFormattedData] = useState([])
  const [chartData, setChartData] = useState({
    criticalAlertCount: 0,
    criticalAlerts: [],
    warningCount: 0,
    warnings: [],
  })

  const [isLoading, setIsLoading] = useState(true)
  const requestBuilder = new RequestBuilder()

  const fetchAlerts = useCallback(async () => {
    setIsLoading(true)
    try {
      const interval = requestBuilder.getInterval(startTime, endTime)
      const payload = {
        logs: {
          size: 10,
          sort: 'desc',
          rangeby: {
            start: startTime,
            end: endTime,
            field: 'ts',
          },
          type: 'event',
        },
        stats: {
          rangeby: {
            start: startTime,
            end: endTime,
            field: 'ts',
          },
          fields: ['ts'],
          histogram: {
            field: 'ts',
            interval: interval,
          },
          aggregation: 'count',
          groupby: 'severity',
          type: 'event',
        },
      }

      const url = `${api}/analytics/thunder-adc/hc_event`
      const res = await service.sendRequest(
        url,
        'POST',
        service.RPTHeaders,
        payload,
        null,
      )
      const logs = res?.data?.logs || {}
      const stats = res?.data?.stats || {}
      const logArray = Object.keys(logs).map(key => logs[key])
      let criticalAlerts: IObject[] = []
      let warnings: IObject[] = []
      Object.keys(stats).forEach(key => {
        switch (String(key)) {
          case 'critical':
          case '0':
          case '1':
          case '2':
          case '3': {
            criticalAlerts = [
              ...Object.keys(stats[key]?.ts_count ?? {}).map(item => [
                parseInt(item),
                stats[key]?.ts_count[item],
              ]),
            ]
            break
          }
          case 'moderate':
          case '4': {
            warnings = [
              ...Object.keys(stats[key]?.ts_count ?? {}).map(item => [
                parseInt(item),
                stats[key]?.ts_count[item],
              ]),
            ]
            break
          }
          default: {
          }
        }
      })

      criticalAlerts = _.sortBy(criticalAlerts, item => {
        return item[0]
      })
      warnings = _.sortBy(warnings, item => {
        return item[0]
      })

      const criticalAlertCount = criticalAlerts?.reduce(
        (sum, item) => sum + item?.[1],
        0,
      )
      const warningCount = warnings?.reduce((sum, item) => sum + item?.[1], 0)

      setFormattedData(logArray)
      setChartData({
        criticalAlertCount,
        criticalAlerts,
        warningCount,
        warnings,
      })
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [startTime, endTime])

  useAsyncEffect(async () => {
    await fetchAlerts()
  }, [startTime, endTime])

  return { formattedData, chartData, isLoading }
}

export default useDashboardAlerts
