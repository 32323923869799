import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  A10Context,
  getItem,
  setItem,
} from '@gui-libraries/framework'
import {
  A10Table,
  A10Modal,
  A10DropdownMenu,
  A10Loader,
  A10Col,
  A10Button,
  A10Message,
  A10Input,
  A10Tooltip,
  A10Tag,
} from '@gui-libraries/widgets'
import ReactLoading from 'react-loading'

import {
  InfrastructureService,
  DashboardService,
  InfraStructureStatsService,
  Utilities,
} from 'src/services/index'
import { IDefaultMethods } from 'src/containers/Controller'
import { AppRoot } from 'src/settings/appRoot'
import { httpClient } from 'src/libraries/httpClient'
import { InfraConfigs } from 'src/constants/InfraConfigs/InfraConfigs'
import Cluster from './Cluster'
import SlidingUpgrade from 'src/containers/Controller/TroubleShoot/ImageUpgrade/UpgradeForm/SlidingUpgrade'
import { IDevice } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/Devices/Devices'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import SlidingWorkflowStatus from 'src/components/shared/WorkflowStatus/slidingForm'
import SlidingWorkflowStatusList from 'src/components/shared/WorkflowStatus/slidingWorkflowListForm'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { ClusterAddForm } from 'src/containers/Controller/Infrastructure/Clusters/Forms/ClusterAddForm'
import { ProvisionForm } from 'src/containers/Controller/Infrastructure/Clusters/Forms/ProvisionForm'
import { DeviceAddForm } from 'src/containers/Controller/Infrastructure/Clusters/Cluster/Devices/Device/Forms/DeviceAddForm'
import { DeviceLicenseForm } from 'src/containers/Controller/Infrastructure/Clusters/Forms/DeviceLicenseForm'
import { PartitionAddForm } from 'src/containers/Controller/Infrastructure/Clusters/Forms/PartitionAddForm'
import { Messages } from 'src/locale/en/index'
import SparcLineWrapper from 'src/components/shared/SparcLineWrapper'
import { TabGroup } from './TabGroup'

import styles from './styles/index.module.less'

export interface IClustersProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  isObjectExplorerMode?: boolean
  callbackObjectExplorerMode?: any
  openDeviceList?: any
  device: any
  refreshClusterSession: (onlyClusterSesSet: boolean) => void
}
export interface IClustersState {
  isLoading: boolean
  clusters: IObject[]
  filteredClusters: IObject[]
  clusterObj: any
  formDevice: IDevice[]
  clustersUpdated: boolean
  searchString: string
  selectedIndex: number
  selectedClusterToUpgrade: string[]
  indexes: any
  clusterStat: any
  deleteModalState: boolean
  selectedCluster: any
  showSlidingPage: boolean
  showSlidingPartition: boolean
  showUpgradePage: boolean
  showWorkflowStatusSlidingPage: boolean
  editMode: boolean
  slidingTitle: string
  slidingDesc: string
  slidingForm: string
  provisionData: any
  clusterType: string
  loadingIcon: boolean
  loadingIconForm: boolean
  workflowType: string
  workflowID: string
  workflowIDList: string[]
  workflowSliderState: boolean
  workflowMappedObj: IObject
  syncClusterConfig: any
  isRerenderTable: boolean
  scanClusterState: boolean
  redirectPath: string
  provisionScan: boolean
  partitionformdata: any
  partitionFormName: boolean
  popup: IPopup
  tab: string
}

export const PARTITION_OBJ_DATA: IObject = {
  partitionId: '',
  partitionName: '',
  partitionType: 'l3v', // default type
  device: '',
  tenant: '',
  description: '',
}

export const CLUSTER_OBJ = {
  type: 'single',
  dataPlaneHA: 'none',
  clusterName: '',
  clusterDName: '',
  description: '',
  advanced: false,
  clusterSetID: '',
  floatingIP: '',
  floatingPort: '',
  floatingIPMask: '',
  convertToHA: false,
  clusterUserName: '',
  clusterPassword: '',
  devices: [
    {
      deviceName: '',
      thunderIPAddress: '',
      mgmtInterface: true,
      // useV6: false,
      port: '443',
      zone: undefined,
      region: undefined,
      location: '',
      metricsExportInterval: 60,
      userName: '',
      userPassword: '',
      licenseCheck: false,
      licenseToken: '',
      licenseObj: '',
      numBandwidth: '',
      bandwidthUnit: '',
    },
  ],
}

export const DEVICE_OBJ: IDevice = {
  deviceName: '',
  cluster: '',
  thunderIPAddress: '',
  mgmtInterface: true,
  // useV6: false,
  port: '443',
  zone: undefined,
  region: undefined,
  location: '',
  metricsExportInterval: 60,
  tenant: '',
  newPartition: false,
  userName: '',
  userPassword: '',
  licenseCheck: false,
  licenseToken: '',
  licenseObj: '',
  numBandwidth: '',
  bandwidthUnit: '',
}

export const clusterTypeMap = {
  vrrpa: 'VRRP-A',
  scaleout: 'Scaleout',
  none: 'Single',
}

const configMgmtMap = {
  single: 'N/A',
  multinode: 'Harmony',
  vcs: 'aVCS',
}

const NA = 'NA'
const ACTIONS_REQUIRE_CLUSTER_DETAIL = [
  'reprovision',
  'scanCluster',
  'addpartition',
  'manageLicense',
]

const INIT_CLUSTER_HEALTH_OBJ = {
  memory: NA,
  session: NA,
  dataCpu: `${NA} / ${NA}`,
  controlCpu: `${NA} / ${NA}`,
  overallCpu: `${NA} / ${NA}`,
  cpumax: NA,
  cpuavg: NA,
  cpucontrolavg: NA,
  cpudataavg: NA,
  memorymax: NA,
  memoryavg: NA,
}

interface IPopup {
  visible: boolean
  record?: IObject
  rowHeight?: number
}

class Clusters extends A10Container<IClustersProps, IClustersState> {
  static contextType = A10Context
  context: React.ContextType<typeof A10Context>
  InfrastructureService = new InfrastructureService()
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  pageLength = 5
  InfraStructureStatsService = new InfraStructureStatsService()
  InfraConfigs = new InfraConfigs()
  AppRoot = new AppRoot()
  Messages = new Messages()
  ref = React.createRef<HTMLDivElement>()

  childForm: any = null
  clusters: any[] = []

  nowTime = new Date().getTime()
  rangeByTime = {
    end: this.nowTime,
    start: this.nowTime - 2 * 60 * 1000,
  }
  adminLevel
  isOperatorUser
  toDeviceLicense = ''
  devAuthFailed = true
  clustersColumns = [
    {
      title: '',
      dataIndex: 'clusterHealth',
      key: 'clusterHealth', // TODO: change this key
      width: '30px',
      className: styles.statusTitle,
      sorter: (a: any, b: any) =>
        this.Utilities.sortNumber(a, b, 'clusterHealth'),
      render: (text: string, record: IObject, index: number) => {
        let status = record.clusterHealth ?? NA
        let tooltip = ''
        if (status === NA) {
          status = 'undefined'
          tooltip = 'undefined'
        } else if (status === 0) {
          status = 'stopped'
          tooltip = 'Bad'
        } else if (status >= 0.8) {
          status = 'ongoing'
          tooltip = 'Good'
        } else {
          status = 'warning'
          tooltip = 'Average'
        }
        return (
          <HealthStatus
            type={status}
            tooltip={this.rowToolTip(record)}
            toolTipClassName="toolTipWidth"
            toolTipArrowPosition="topLeft"
          />
        )
      },
    },
    {
      title: 'Cluster Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) =>
        this.Utilities.sortStringKeys(a, b, 'display-name', 'name'),
      render: (text: any, record: any) => {
        const { popup } = this.state

        const showDropdown = popup.visible && popup.record?.key === record.key

        return (
          <div className={styles.clusterName}>
            <A10Tooltip
              title={this.rowToolTip(record)}
              placement="topLeft"
              overlayClassName="toolTipWidth"
            >
              {record['display-name'] || text}
            </A10Tooltip>
            {showDropdown && this.renderActionDropdown(record)}
          </div>
        )
      },
    },
    {
      title: 'Cluster Type',
      dataIndex: 'data-plane-ha',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'data-plane-ha'),
      render: (text: string, record: any) => {
        return (
          <A10Tooltip
            title={this.rowToolTip(record)}
            placement="topLeft"
            overlayClassName="toolTipWidth"
          >
            {clusterTypeMap[text] || 'None'}
          </A10Tooltip>
        )
      },
    },
    {
      title: 'Config Mgmt.',
      dataIndex: 'type',
      key: 'type',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'type'),
      render: (text: string, record: any) => {
        return (
          <A10Tooltip
            title={this.rowToolTip(record)}
            placement="topLeft"
            overlayClassName="toolTipWidth"
          >
            {configMgmtMap[text]}
          </A10Tooltip>
        )
      },
    },
    {
      title: 'Floating IP',
      dataIndex: 'floating-ip',
      key: 'floating-ip',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'floating-ip'),
      render: (text: string, record: any) => {
        return (
          <A10Tooltip
            title={this.rowToolTip(record)}
            placement="topLeft"
            overlayClassName="toolTipWidth"
          >
            <div>
              {record['floating-ip'] ? (
                <A10Tag>{record['floating-ip']}</A10Tag>
              ) : (
                NA
              )}
            </div>
          </A10Tooltip>
        )
      },
    },
    {
      title: 'Environment',
      dataIndex: 'virtualization-type',
      key: 'virtualization-type',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'virtualization-type'),
      render: (environment: any, record: any) => {
        return (
          <A10Tooltip
            title={this.rowToolTip(record)}
            placement="topLeft"
            overlayClassName="toolTipWidth"
          >
            {environment}
          </A10Tooltip>
        )
      },
    },
    {
      title: '#Devices',
      dataIndex: 'device-count',
      key: 'device-count',
      sorter: (a: any, b: any) =>
        this.Utilities.sortNumber(a, b, 'device-count'),
      render: (deviceCount: number, record: any) => {
        return (
          <A10Tooltip
            title={this.rowToolTip(record)}
            placement="topLeft"
            overlayClassName="toolTipWidth"
          >
            {deviceCount || 0}
          </A10Tooltip>
        )
      },
    },
    {
      title: 'Log Rate',
      dataIndex: 'logRate',
      key: 'logRate',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'logRate'),
      render: (logRate: any, record: any) => {
        return (
          <A10Tooltip
            title={this.rowToolTip(record)}
            placement="topRight"
            overlayClassName="toolTipWidth"
          >
            {logRate}
          </A10Tooltip>
        )
      },
    },
    {
      title: 'Throughput (BPS)',
      className: 'text-center',
      dataIndex: 'bpsChart',
      key: 'bpsChart',
      render: (text: any, record: any, index: number) => {
        const chartData = record.bpsChart || []
        let count = 0,
          chartDataSeries: any
        const seriesName: string = record.name
        if (chartData.length > 0) {
          if (chartData[0] && chartData[0].data) {
            chartDataSeries = chartData[0].data
          }
          // count = chartData[0].data.reduce((total: any, currentVal: any) => {
          //   return total + currentVal[1]
          // }, 0)
        }
        let chartConfig = _.cloneDeep(this.Utilities.SPARCLINE_CHART_CONFIG)
        chartConfig.tooltip['formatter'] = this.Utilities.bpsTooltipFormatter
        return (
          <div key={'bpsChart-' + index++} className="inline">
            <div className="col-sm-12 no-padding">
              <SparcLineWrapper
                chartDataSeries={chartDataSeries}
                seriesName={seriesName}
                chartConfig={chartConfig}
              />
            </div>
          </div>
        )
      },
    },
  ]

  constructor(
    props: IClustersProps,
    context: React.ContextType<typeof A10Context>,
  ) {
    super(props, context)

    const {
      storage: {
        get: { ADMIN_LEVEL, IS_OPERATOR_USER },
      },
    } = context

    this.adminLevel = ADMIN_LEVEL
    this.isOperatorUser = IS_OPERATOR_USER

    this.state = {
      isLoading: false,
      clusters: [],
      filteredClusters: [],

      clusterObj: JSON.parse(JSON.stringify(CLUSTER_OBJ)) || {},
      formDevice: [JSON.parse(JSON.stringify(DEVICE_OBJ))],
      provisionData: {
        bulkApply: false,
        tenantsName: '',
        partitionMap: [],
      },
      clustersUpdated: false,
      searchString: '',
      selectedIndex: 0,
      selectedClusterToUpgrade: [],
      indexes: [],
      clusterStat: {
        dataCpu: {
          max: '',
          avg: '',
        },
        controlCpu: {
          max: '',
          avg: '',
        },
        overallCpu: {
          max: '',
          avg: '',
        },
        memory: {
          value: '',
        },
        session: {
          value: '',
        },
      },
      deleteModalState: false,
      selectedCluster: { name: '' },
      showSlidingPage: false,
      showSlidingPartition: false,
      showUpgradePage: false,
      showWorkflowStatusSlidingPage: false,
      editMode: false,
      slidingTitle: '',
      slidingDesc: '',
      slidingForm: '',
      clusterType: '',
      loadingIcon: false,
      loadingIconForm: false,
      partitionformdata: PARTITION_OBJ_DATA,
      partitionFormName: false,
      workflowType: '',
      workflowID: '',
      workflowIDList: [],
      workflowSliderState: false,
      workflowMappedObj: null,
      syncClusterConfig: {
        syncClusterConfigData: false,
      },
      isRerenderTable: true,
      scanClusterState: false,
      redirectPath: '',
      provisionScan: false,
      popup: { visible: false } as IPopup,
      tab: 'clusterPartitions',
    }
  }

  onclickActionDropdown = async (
    clusterName: string,
    component: JSX.Element,
  ) => {
    const {
      storage: { set },
    } = this.context
    const { clusters } = this.state
    const { key } = component
    let cluster = clusters.find(
      (_cluster: IObject) => _cluster.name === clusterName,
    )

    if (ACTIONS_REQUIRE_CLUSTER_DETAIL.includes(key as string)) {
      cluster = await this.getClusterDetail(clusterName)
    }

    const { 'device-count': deviceCount } = cluster

    if (component.key === 'viewAnalytics') {
      set.CURRENT_CLUSTER({ name: clusterName })
      this.setState({
        redirectPath: '/controller/Analytics/cluster',
      })
    } else if (component.key === 'edit') {
      this.editCluster(cluster)
    } else if (component.key === 'delete') {
      if (deviceCount > 0) {
        A10Message.error(
          'Deregister and Delete the device(s) before deleting the cluster',
          4,
          close,
        )
      } else {
        this.setState({
          deleteModalState: true,
          selectedCluster: cluster,
        })
      }
    } else if (component.key === 'addDevice') {
      this.addDevice(cluster)
    } else if (component.key === 'reprovision' && deviceCount > 0) {
      const payload = { features: ['provisioning'] }
      this.featureCheck(cluster, payload, 'reProvision')
    } else if (component.key === 'scanCluster') {
      const scanConfigCheck = (cluster?.['partition-list'] || []).some(
        (obj: any) => {
          return !!obj['tenant-uuid']
        },
      )
      if (scanConfigCheck) {
        this.setState({
          scanClusterState: true,
          selectedCluster: cluster,
        })
      } else {
        A10Message.error(
          'Please provision at least 1 cluster partition and bind it to a tenant',
          4,
          close,
        )
      }
    } else if (component.key === 'upgrade') {
      this.setState({ showUpgradePage: true, selectedCluster: cluster })
    } else if (component.key === 'addpartition' && deviceCount > 0) {
      const payload = { features: ['partition_add'] }
      this.featureCheck(cluster, payload, 'partition_add')
    } else if (component.key === 'manageLicense') {
      this.bulkManageDevicesLicense(cluster)
    }
  }

  renderActionDropdown = (cluster: IObject) => {
    const {
      storage: {
        get: { ADMIN_LEVEL },
      },
    } = this.context

    let upgradeCheck =
      (cluster.type === 'multinode' && cluster['data-plane-ha'] === 'vrrpa') ||
      cluster.type === 'single' ||
      (cluster.type === 'vcs' && cluster['device-count'] > 0)

    const enableAddDevice = this.Utilities.enableAddDeviceToCluster(cluster)
    const enableBulkManageDevicesLicense = cluster['device-count'] > 0
    const dropdownStyle = {
      height: this.state.popup?.rowHeight ? this.state.popup.rowHeight - 18 : 0,
    }

    upgradeCheck = cluster.type === 'vcs' ? false : upgradeCheck

    return ADMIN_LEVEL === 'provider' ? (
      <div className={styles.actionDropdown} style={dropdownStyle}>
        <A10DropdownMenu
          menu={
            ADMIN_LEVEL === 'provider' && !this.isOperatorUser
              ? [
                  <div key="viewAnalytics">View Analytics</div>,
                  <hr key="hr" />,
                  ...(enableAddDevice
                    ? [<div key="addDevice">Add Device</div>]
                    : []),
                  <div
                    key="addpartition"
                    className={cluster['device-count'] > 0 ? '' : 'disabled'}
                  >
                    Add Cluster Partition
                  </div>,
                  <div key="upgrade" className={upgradeCheck ? '' : 'disabled'}>
                    Upgrade Image
                  </div>,
                  ...(enableBulkManageDevicesLicense
                    ? [<div key="manageLicense">Manage License</div>]
                    : []),
                  <hr key="hr" />,
                  <div
                    key="reprovision"
                    className={cluster['device-count'] > 0 ? '' : 'disabled'}
                  >
                    Provision
                  </div>,
                  <div key="scanCluster">Scan Cluster Config</div>,
                  <hr key="hr" />,
                  <div key="edit">Edit </div>,
                  <div key="delete">Delete </div>,
                ]
              : [<div key="viewAnalytics">View Analytics</div>]
          }
          title=""
          style={{ color: '#757575', marginBottom: '-15px' }}
          onClick={component =>
            this.onclickActionDropdown(cluster.name, component)
          }
          trigger="hover"
          placement="bottomRight"
          arrowPointAtCenter={true}
        />
      </div>
    ) : null
  }

  componentDidMount() {
    this.loadClusters()

    const addNew = getItem('ADD_NEW')
    if (addNew) {
      setItem('ADD_NEW', '')

      const {
        storage: {
          get: { ADMIN_LEVEL },
        },
      } = this.context
      if (ADMIN_LEVEL === 'provider' && !this.isOperatorUser) {
        this.addCluster()
      }
    }
  }

  componentDidUpdate(prevProps: IClustersProps, prevState: IClustersState) {
    const { clusters, tab, searchString } = this.state

    if (prevState.tab !== tab || prevState.searchString !== searchString) {
      this.setState({
        filteredClusters: this.getFilteredClusters(clusters, tab, searchString),
      })
    }
  }

  getFilteredClusters = (
    clusters: IObject[],
    tab: string,
    searchString: string,
  ) => {
    return clusters.filter(cluster => {
      const belongsToTab =
        tab === 'vrrpa' || tab === 'scaleout'
          ? cluster['data-plane-ha'] === tab
          : true
      const meetSearchString = cluster.name
        ?.toLowerCase()
        .includes(searchString)

      return belongsToTab && meetSearchString
    })
  }

  rowToolTip = (record: any) => {
    const status =
      record.clusterHealth !== undefined ? record.clusterHealth : NA
    let healthText = ''
    if (status === NA) {
      healthText = NA
    } else if (status === 0) {
      healthText = 'Bad'
    } else if (status >= 0.8) {
      healthText = 'Good'
    } else {
      healthText = 'Average'
    }

    return (
      <div className="tableOverToolTip">
        <table>
          {record?.['display-name'] !== record.name && (
            <tr>
              <th>Cluster Display Name</th> <td>{record['display-name']}</td>
            </tr>
          )}
          <tr>
            <th>Cluster Name</th> <td>{record.name}</td>
          </tr>
          <tr>
            <th>Health</th>
            <td>{healthText}</td>
          </tr>
          <tr>
            <th>Avg CPU</th>
            <td>{`${record.cpuavg || NA} (Data: ${record.cpudataavg ||
              NA} Control: ${record.cpucontrolavg || NA})`}</td>
          </tr>
          <tr>
            <th>Avg Memory</th>
            <td>{record.memoryavg}</td>
          </tr>
        </table>
      </div>
    )
  }

  reProvision = async (cluster: IObject) => {
    this.setState({
      clusterObj: cluster,
      editMode: false,
      showSlidingPage: true,
      provisionData: {
        bulkApply: false,
        tenantsName: '',
        partitionMap: [],
      },
      slidingTitle: 'Provision',
      slidingDesc: 'Please associate Tenants for Cluster Partitions...',
      slidingForm: 'reprovision',
      syncClusterConfig: {
        syncClusterConfigData: false,
      },
    })
  }

  featureCheck = (cluster: any, payload: any, type: string) => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context

    const { showMessage } = this.Utilities
    const featureCheck = this.InfrastructureService.featureCheck(
      null,
      payload,
      [provider, 'cluster', cluster.name],
    )
    featureCheck
      .then((resp: any) => {
        const feature = resp.data[payload.features[0]]
        if (feature) {
          switch (type) {
            case 'reProvision':
              this.reProvision(cluster)
              break
            case 'partition_add':
              this.addPartition(cluster)
              break
          }
        } else {
          this.Utilities.checkFeatureMsg('cluster', cluster.name)
        }
      })
      .catch((error: any) => {
        const message = error?.response?.data?.message
          ? error.response.data.message
          : ''
        if (!this.Utilities.isRBACAccessDenied(error)) {
          showMessage('', 0, 0, message, 10)
        }
      })
  }

  scanCluster = (cluster: any) => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context

    const { showMessage } = this.Utilities
    const scanCluster = this.InfrastructureService.scanCluster(null, null, [
      provider,
      cluster.name,
    ])
    this.setState({
      loadingIcon: true,
      loadingIconForm: true,
    })
    scanCluster
      .then((resp: any) => {
        this.setState({
          loadingIcon: false,
          loadingIconForm: false,
          workflowType: 'scan',
          workflowID: resp.data.workflow.uuid,
          workflowSliderState: true,
          scanClusterState: false,
          workflowMappedObj: cluster,
          provisionScan: false,
        })
        this.refresh()
      })
      .catch((error: any) => {
        const messsge = error?.response?.data?.message
          ? error.response.data.message
          : ''
        if (!this.Utilities.isRBACAccessDenied(error)) {
          showMessage('Error in Cluster scan.', 0, 0, messsge, 10)
        }
        this.setState({
          loadingIcon: false,
          loadingIconForm: false,
          scanClusterState: false,
        })
      })
  }

  addDevice = (cluster: any) => {
    const deviceObj = JSON.parse(JSON.stringify(DEVICE_OBJ))
    deviceObj.cluster = cluster ? cluster.name : ''
    this.setState({
      formDevice: [deviceObj],
      clusterObj: cluster,
      editMode: false,
      showSlidingPage: true,
      slidingTitle: 'Add new device',
      slidingDesc: 'Please provide the detail to connect device',
      slidingForm: 'addDevice',
    })
  }

  getClusterVcsState = async (cluster: IObject) => {
    const {
      storage: {
        get: { ADMIN_LEVEL, PROVIDER, ENCODED_SESSION_ID, PROVIDER_ID },
      },
    } = this.context

    const endTime = Date.now()
    const startTime = endTime - 2 * 60 * 1000
    let xAccount = this.Utilities.getAllXAccountIDs()
    if (ADMIN_LEVEL !== 'provider') {
      const tenantObj = this.Utilities.getCurrentTenantObject()
      xAccount = tenantObj?.uuid
    }
    if (!xAccount) {
      return {}
    }
    const header = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      provider: PROVIDER,
      Authorization: ENCODED_SESSION_ID,
      'x-providerids': PROVIDER_ID,
    }
    const payload = {
      cluster_vcs_state: {
        fields: ['cluster_vcs_state', 'device_uuid'],
        size: 10000,
        rangeby: {
          start: startTime,
          end: endTime,
          field: 'ts',
        },
        filterby: {
          and: {
            cluster_id: cluster['cluster-uuid'],
          },
        },
      },
    }

    const res = await this.InfraStructureStatsService.getClusterStats(
      header,
      payload,
      null,
    )

    let clusterVcsState = {}
    if (res?.data?.['cluster_vcs_state']) {
      const data = res.data['cluster_vcs_state']
      const keys = Object.keys(data)
      clusterVcsState = keys.reduce((acc: IObject, key: string) => {
        const deviceUuid = data[key]['device_uuid']
        if (!acc[deviceUuid]) {
          acc[deviceUuid] = data[key]['cluster_vcs_state']
        }
        return acc
      }, {})
    }
    return clusterVcsState
  }

  bulkManageDevicesLicense = async (cluster: IObject) => {
    const { 'referrer-list': devices = [] } = cluster
    const clusterVcsState = await this.getClusterVcsState(cluster)

    const _devices = devices.reduce((acc: IObject[], device: IObject) => {
      const deviceUuid = device['device-uuid']
      const vcsState = clusterVcsState[deviceUuid]

      if (vcsState) {
        if (vcsState == 'master' || vcsState == 'vcs_disabled') {
          acc.push(device)
        }
      } else {
        acc.push(device)
      }
      return acc
    }, [])

    const deviceLicenseList = _devices.map((device: IObject) => {
      let licenseInfo = {}
      let licenseObj = {}

      if (device?.['licenseinfo-list'] && device?.['model']) {
        if (device['model'] === 'vThunder' || device['model'] === 'cThunder') {
          licenseInfo = device['licenseinfo-list'].find((license: IObject) => {
            const licenseType = license['license-type']
            return (
              licenseType === 'DEVICE_LICENSE' ||
              licenseType === 'EXTERNAL_DEVICE_LICENSE' ||
              licenseType === 'PERPETUAL_LICENSE'
            )
          })
          licenseObj = {
            'entitlement-key': licenseInfo?.['lic-key'] || '',
            licenseType: licenseInfo?.['license-type'] || '',
            licenseState: licenseInfo?.['license-state'] || '',
          }
        }
      }
      const deviceVersion = device['primary-sw-version']
        ? device['primary-sw-version'].split(' ')[0]
        : ''
      const bandwidthObj = this.Utilities.megaToGiga(
        licenseInfo?.['device-bandwidth'] || 0,
      )
      const deviceObj = {
        cluster: cluster?.name || '',
        deviceName: device.name,
        deviceVersion,
        enableUpdate:
          device['registration-state'] === 'REGISTERED' &&
          clusterVcsState[device['device-uuid']] !== 'blade',
        licenseCheck: true,
        licenseAuth: true,
        licenseObj: JSON.stringify(licenseObj),
        numBandwidth: bandwidthObj.val,
        bandwidthUnit: bandwidthObj.unit,
        validation: true,
      }
      return deviceObj
    })
    this.setState({
      selectedCluster: cluster,
      formDevice: deviceLicenseList,
      showSlidingPage: true,
      slidingTitle: 'Device License',
      slidingDesc: 'Please provide license information for device(s)',
      slidingForm: 'editDeviceLicense',
    })
  }

  addPartition = async (cluster: IObject) => {
    this.setState({
      partitionformdata: PARTITION_OBJ_DATA,
      showSlidingPartition: true,
      partitionFormName: true,
      selectedCluster: cluster,
    })
  }

  editCluster = (cluster: any) => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context

    this.setState({
      clusterObj: {
        type: cluster.type,
        dataPlaneHA: cluster['data-plane-ha'],
        clusterName: cluster.name,
        clusterDName: cluster['display-name'] || cluster.name,
        description: cluster.description,
        advanced: false,
        clusterSetID: cluster['cluster-set-id'],
        floatingIP: cluster['floating-ip'],
        floatingPort: cluster['port'],
        devices: [
          {
            deviceName: '',
            thunderIPAddress: '',
            mgmtInterface: true,
            // useV6: false,
            port: '',
            zone: '',
            region: '',
            location: '',
            userName: '',
            userPassword: '',
            metricsExportInterval: '',
          },
        ],
      },
      selectedCluster: cluster,
      editMode: true,
      showSlidingPage: true,
      slidingTitle: 'Edit Cluster',
      slidingDesc: '',
      slidingForm: 'editCluster',
    })
  }

  addCluster = () => {
    this.setState({
      clusterObj: JSON.parse(JSON.stringify(CLUSTER_OBJ)),
      editMode: false,
      showSlidingPage: true,
      slidingTitle: 'Add Cluster',
      slidingDesc: 'Please provide the detail to connect device',
      slidingForm: 'addCluster',
    })
  }

  setSlidingPage = (isOpen: boolean, data?: IObject, editMode?: boolean) => {
    this.setState({
      showSlidingPage: isOpen,
      showSlidingPartition: isOpen,
      clusterObj: data,
      slidingForm: '',
      editMode,
    })
  }

  closeUpgradeSlidingPage = async (
    idList?: string[],
    clusterList?: string[],
  ) => {
    if (Array.isArray(idList) && idList.length > 0) {
      try {
        setTimeout(async () => {
          const workflowIDList = await Promise.all(
            idList.map(async id => {
              const { data: res } = await httpClient.get(
                `/scheduler/v1/job-result/${id}`,
                {
                  absoluteBasePath: true,
                },
              )
              const workflowID =
                res.job.results[0].result.workflow['workflow-id']
              return workflowID
            }),
          )
          this.setState({
            workflowIDList,
            selectedClusterToUpgrade: clusterList,
            showWorkflowStatusSlidingPage: true,
          })
        }, 1000)
      } catch (err) {
        console.error(err)
      }
    }
    this.setState({ showUpgradePage: false })
  }

  onCloseWorkflowStatusWindow = () => {
    this.setState({
      workflowIDList: [],
      selectedClusterToUpgrade: [],
      showWorkflowStatusSlidingPage: false,
    })
  }

  handleChange = (data: any, isEdit: boolean) => {
    // @ts-ignore
    this.setState({
      clusterObj: data,
    })
  }
  handleDeviceChange = (data: any, isEdit: boolean) => {
    // @ts-ignore
    this.setState({
      formDevice: data,
    })
  }
  handleDeviceLicenseChange = (data: any) => {
    let devAuthFail = false
    data.map((dev: any) => {
      if (
        (!!dev.name || !!dev.deviceName) &&
        (!dev.licenseAuth || !dev.validation)
      ) {
        devAuthFail = true
      }
    })
    this.devAuthFailed = devAuthFail

    if (this.toDeviceLicense === 'addCluster') {
      let clusterObj = this.state.clusterObj
      clusterObj.devices = data
      // @ts-ignore
      this.setState({
        clusterObj: clusterObj,
      })
    } else {
      // @ts-ignore
      this.setState({
        formDevice: data,
      })
    }
  }
  handleProvisionChange = (data: any) => {
    // @ts-ignore
    this.setState({
      provisionData: data,
    })
  }

  // only called when devices data in local storage needs to be updated
  loadDevices = async () => {
    const {
      storage: {
        get: { PROVIDER: provider },
        set,
      },
    } = this.context

    try {
      const res = await this.InfrastructureService.getDevicesSummary(
        this.adminLevel === 'provider'
          ? null
          : this.Utilities.getXAccountHeaderDetails(false),
        null,
        [provider],
      )
      const devices = res?.data?.['device-list'] || []
      set.ALLDEVICES(devices)
    } catch (e) {
      console.error(e)
    }
  }

  loadClusters = async () => {
    const {
      storage: {
        get: { PROVIDER },
        set,
      },
    } = this.context
    const { tab, searchString } = this.state

    try {
      this.setState({
        isLoading: true,
      })

      const res = await this.InfrastructureService.getClustersSummary(
        this.adminLevel === 'provider'
          ? null
          : this.Utilities.getXAccountHeaderDetails(false),
        null,
        PROVIDER,
      )
      const clusters = res?.data?.['cluster-list'] || []

      set.ALLCLUSTERS(clusters)
      this.AppRoot.setRootScopeElement('allClusters', clusters)
      set.PROVIDER_CLUSTERS(clusters)

      const formattedClusters = this.formatClusters(clusters)

      this.setState({
        clusters: formattedClusters,
        filteredClusters: this.getFilteredClusters(
          formattedClusters,
          tab,
          searchString,
        ),
        clustersUpdated: !this.state.clustersUpdated,
      })
      if (clusters && clusters.length > 0) {
        this.getAllClusterStat()
      }
    } catch (e) {
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  getClusterDetail = async (clusterName: string) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context
    const { clusters } = this.state
    const cluster = clusters.find(cluster => cluster.name === clusterName)

    try {
      if (cluster.detailLoaded) {
        return cluster
      } else {
        const res = await this.InfrastructureService.getCluster(
          this.adminLevel === 'provider'
            ? null
            : this.Utilities.getXAccountHeaderDetails(false),
          null,
          [PROVIDER, clusterName],
        )
        const {
          'referrer-list': devices = [],
          'partition-list': partitions = [],
        } = res?.data?.cluster || {}
        const _clusters = clusters.map(cluster => {
          if (cluster.name === clusterName) {
            cluster.detailLoaded = true
            cluster['referrer-list'] = devices
            cluster['partition-list'] = partitions
          }
          return cluster
        })

        this.setState({
          clusters: _clusters,
        })

        return cluster
      }
    } catch (e) {
      console.error(e)
      return []
    }
  }

  formatClusters = (clusters: Storage.ICluster[]) => {
    return clusters.map(cluster => {
      return {
        ...cluster,
        ...INIT_CLUSTER_HEALTH_OBJ,
        key: cluster.uuid,
      }
    })
  }

  getAllClusterStat() {
    const {
      storage: {
        set,
        get: { PROVIDER, ENCODED_SESSION_ID, PROVIDER_ID },
      },
    } = this.context
    const { clusters, tab, searchString } = this.state
    let xAccount = this.Utilities.getAllXAccountIDs()

    if (this.adminLevel !== 'provider') {
      const tenantObj = this.Utilities.getCurrentTenantObject()
      xAccount = tenantObj?.uuid
    }

    const header = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      provider: PROVIDER,
      Authorization: ENCODED_SESSION_ID,
      'x-providerids': PROVIDER_ID,
    }
    const to = Date.now()
    const from = Date.now() - 2 * 60 * 1000
    const logRatePayload = JSON.parse(
      JSON.stringify(this.InfraConfigs.CLUSTER_STATS),
    )
    logRatePayload.log_rate.rangeby.start = from - 1 * 60 * 1000
    logRatePayload.log_rate.rangeby.end = to - 1 * 60 * 1000
    logRatePayload.log_rate.groupby = 'cluster_id'
    delete logRatePayload.all_cpu_usage
    delete logRatePayload.thunderAdcClusterCpuMemory
    delete logRatePayload.thunderAdcClusterMaxMemory
    delete logRatePayload.clusterThroughput
    delete logRatePayload.log_rate.filterby

    const payload = JSON.parse(JSON.stringify(this.InfraConfigs.CLUSTER_STATS))
    payload.thunderAdcClusterCpuMemory.rangeby.start =
      Date.now() - 2 * 60 * 1000
    payload.thunderAdcClusterCpuMemory.rangeby.end = Date.now()
    payload.thunderAdcClusterCpuMemory.groupby = 'cluster_id'
    delete payload.thunderAdcClusterCpuMemory.filterby
    payload.thunderAdcClusterMaxMemory.rangeby.start =
      Date.now() - 2 * 60 * 1000
    payload.thunderAdcClusterMaxMemory.rangeby.end = Date.now()
    payload.thunderAdcClusterMaxMemory.groupby = 'cluster_id'
    delete payload.thunderAdcClusterMaxMemory.filterby
    payload.clusterThroughput.rangeby.start = Date.now() - 6 * 60 * 60 * 1000
    payload.clusterThroughput.rangeby.end = Date.now()
    payload.clusterThroughput.groupby = 'cluster_id'
    delete payload.all_cpu_usage
    delete payload.log_rate
    delete payload.clusterThroughput.filterby

    const clusterstats = this.InfraStructureStatsService.getClusterStats(
      header,
      payload,
      null,
    )
    clusterstats
      .then((response: any) => {
        let cpuUsageAvg = -1
        let cpuUsageMax = -1
        let memHealth = 0,
          cpuHealth = 0,
          clusterHealth = 0

        const statsData = response.data.thunderAdcClusterCpuMemory
        const maxMemoryData = response.data.thunderAdcClusterMaxMemory
        const clusterThroughputData = response.data.clusterThroughput
        // const logRateData = response.data.log_rate

        const _clusters = clusters.map((cluster: any, index: number) => {
          const uuid = cluster['cluster-uuid']

          if (statsData[uuid]) {
            const clusterStat = {
              dataCpu: {
                max:
                  statsData[uuid].max_data_cpu_usage_avg >= 0
                    ? Math.round(statsData[uuid].max_data_cpu_usage_avg) + '%'
                    : NA,
                avg:
                  statsData[uuid].avg_data_cpu_usage_avg >= 0
                    ? Math.round(statsData[uuid].avg_data_cpu_usage_avg) + '%'
                    : NA,
              },
              controlCpu: {
                max:
                  statsData[uuid].max_control_cpu_usage_avg >= 0
                    ? Math.round(statsData[uuid].max_control_cpu_usage_avg) +
                      '%'
                    : NA,
                avg:
                  statsData[uuid].avg_control_cpu_usage_avg >= 0
                    ? Math.round(statsData[uuid].avg_control_cpu_usage_avg) +
                      '%'
                    : NA,
              },
              overallCpu: {
                max:
                  statsData[uuid].max_vthunder_cpu_usage_avg >= 0
                    ? Math.round(statsData[uuid].max_vthunder_cpu_usage_avg) +
                      '%'
                    : Math.round(cpuUsageMax) >= 0
                    ? Math.round(cpuUsageMax) + '%'
                    : NA,
                avg:
                  statsData[uuid].avg_vthunder_cpu_usage_avg >= 0
                    ? Math.round(statsData[uuid].avg_vthunder_cpu_usage_avg) +
                      '%'
                    : Math.round(cpuUsageAvg) >= 0
                    ? Math.round(cpuUsageAvg) + '%'
                    : NA,
              },
              memory: {
                max: !isNaN(
                  Math.round(
                    100 - 100 * maxMemoryData[uuid].free_memory_ratio_min,
                  ),
                )
                  ? Math.round(
                      100 - 100 * maxMemoryData[uuid].free_memory_ratio_min,
                    ) + '%'
                  : NA,
                avg: !isNaN(
                  Math.round(100 - 100 * statsData[uuid].free_memory_ratio_avg),
                )
                  ? Math.round(
                      100 - 100 * statsData[uuid].free_memory_ratio_avg,
                    ) + '%'
                  : NA,
              },
              session: {
                value: !isNaN(
                  Math.round(
                    100 - 100 * statsData[uuid].free_session_ratio_avg,
                  ),
                )
                  ? Math.round(
                      100 - 100 * statsData[uuid].free_session_ratio_avg,
                    ) + '%'
                  : NA,
              },
            }
            cluster.memory = clusterStat.memory.avg
            cluster.memorymax = clusterStat.memory.max
            cluster.memoryavg = clusterStat.memory.avg
            cluster.session = clusterStat.session.value
            cluster.dataCpu =
              clusterStat.dataCpu.max + ' / ' + clusterStat.dataCpu.avg
            cluster.controlCpu =
              clusterStat.controlCpu.max + ' / ' + clusterStat.controlCpu.avg
            cluster.overallCpu =
              clusterStat.overallCpu.max + ' / ' + clusterStat.overallCpu.avg
            cluster.cpumax = clusterStat.overallCpu.max
            cluster.cpuavg = clusterStat.overallCpu.avg
            cluster.cpucontrolavg = clusterStat.controlCpu.avg
            cluster.cpudataavg = clusterStat.dataCpu.avg

            if (1 - statsData[uuid].free_memory_ratio_avg < 0.7) {
              memHealth = 1
            } else if (1 - statsData[uuid].free_memory_ratio_avg > 0.9) {
              memHealth = 0
            } else {
              memHealth =
                1 - (1 - statsData[uuid].free_memory_ratio_avg - 0.7) / 0.2
            }

            const cpuMax = Math.max(
              statsData[uuid].avg_control_cpu_usage_avg,
              statsData[uuid].avg_data_cpu_usage_avg,
            )
            if (cpuMax < 40) {
              cpuHealth = 1
            } else if (cpuMax > 70) {
              cpuHealth = 0
            } else {
              cpuHealth = 1 - (cpuMax - 40) / 30
            }

            clusterHealth = Math.min(memHealth, cpuHealth)
            cluster.clusterHealth = clusterHealth
          }

          if (clusterThroughputData[uuid]) {
            const chartData = this.Utilities.parseBPSChartData(
              clusterThroughputData[uuid],
              payload.clusterThroughput,
            )
            cluster.bpsChart = chartData
          }

          return cluster
        })
        set.PROVIDER_CLUSTERS(_clusters)
        this.setState({
          clusters: _clusters,
          filteredClusters: this.getFilteredClusters(
            _clusters,
            tab,
            searchString,
          ),
        })
        this.getLogRateStats(
          header,
          logRatePayload,
          Date.now() - 3 * 60 * 1000,
          Date.now() - 1 * 60 * 1000,
        )
      })
      .catch((error: any) => {
        // console.log(error)
        this.getLogRateStats(
          header,
          logRatePayload,
          Date.now() - 3 * 60 * 1000,
          Date.now() - 1 * 60 * 1000,
        )
      })
  }

  getLogRateStats = (header: any, logratePayload: any, from: any, to: any) => {
    const {
      storage: { set },
    } = this.context
    const { clusters, tab, searchString } = this.state
    const logRateAPI = this.InfraStructureStatsService.getPartitionStats(
      header,
      logratePayload,
      null,
    )

    logRateAPI
      .then((resp: any) => {
        const logRateData = resp.data.log_rate
        const _clusters = clusters.map((cluster: any, index: number) => {
          const uuid = cluster['cluster-uuid']
          if (logRateData[uuid]) {
            const logRate =
              ((logRateData[uuid].pc_sent_sum +
                logRateData[uuid].pe_sent_sum +
                logRateData[uuid].pr_sent_sum) *
                1000) /
              (to - from)
            cluster.logRate = Math.round(logRate) + ' /s'
          } else {
            cluster.logRate = NA
          }
          return cluster
        })

        set.PROVIDER_CLUSTERS(_clusters)
        this.setState({
          clusters: _clusters,
          filteredClusters: this.getFilteredClusters(
            _clusters,
            tab,
            searchString,
          ),
        })
      })
      .catch((err: any) => {})
  }

  handleOk = () => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context
    const { selectedCluster } = this.state

    if (selectedCluster['device-count'] === 0) {
      const deleteCluster = this.InfrastructureService.deleteCluster(
        null,
        null,
        [provider, this.state.selectedCluster.name],
      )
      this.setState({
        loadingIcon: true,
      })
      deleteCluster
        .then((resp: any) => {
          A10Message.success('Cluster deleted successfully', 4, close)
          this.setState({
            loadingIcon: false,
            deleteModalState: false,
            selectedCluster: { name: '' },
          })
          this.loadClusters()
        })
        .catch((error: any) => {
          const messsge =
            'Unable to delete Cluster. ' + error.response.data.message
          if (!this.Utilities.isRBACAccessDenied(error)) {
            A10Message.error(messsge, 10, close)
          }
          this.setState({
            loadingIcon: false,
            deleteModalState: false,
            selectedCluster: { name: '' },
          })
        })
    }
  }

  handleCancel = () => {
    if (this.state.loadingIcon) {
      return false
    } else if (this.state.loadingIconForm) {
      return false
    }
    this.setState({
      deleteModalState: false,
      selectedCluster: { name: '' },
      scanClusterState: false,
    })
  }

  handleSlidingWorkflowOnCancel = () => {
    this.setState({
      workflowSliderState: false,
    })
  }

  initiateScanConfigWorkflow = (
    workflowID: string,
    cluster: any,
    provisionScan?: boolean,
  ) => {
    this.setState({
      workflowType: 'scan',
      workflowID: workflowID,
      workflowSliderState: true,
      workflowMappedObj: cluster,
      provisionScan: provisionScan || false,
    })
  }

  updateWorkflowID = (workflowData: IObject) => {
    const cluster = this.state.workflowMappedObj
    this.setState(
      {
        workflowType: '',
        workflowID: '',
        workflowSliderState: false,
        workflowMappedObj: null,
      },
      () => {
        this.initiateScanConfigWorkflow(
          workflowData.workflowID,
          cluster,
          workflowData.provisionScan,
        )
      },
    )
  }

  handleAddDevice = (devices: any, cluster?: any) => {
    // const devices = this.state.formDevice
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context
    const { showMessage } = this.Utilities

    if (cluster) {
      devices.map((obj: any) => {
        obj.cluster = cluster.name
      })
    }
    const deviceList = this.Utilities.generateDevicePayload(devices)

    let payload = {
      'device-list': JSON.parse(JSON.stringify(deviceList)),
    }
    payload['device-list'].map((obj: any) => {
      delete obj.username
      delete obj.password
    })
    const deviceAdd = this.InfrastructureService.addDevice(null, payload, [
      provider,
    ])
    this.setState({
      loadingIconForm: true,
    })
    deviceAdd
      .then((response: any) => {
        this.setSlidingPage(false)
        this.refresh()
        this.setDeviceLicense(devices, this.state.editMode)
        if (!this.state.editMode) {
          deviceList.map((obj: any) => {
            let deviceRegisterPayload = {
              device: {
                username: obj.username,
                password: obj.password,
              },
            }

            const registerDevice = this.InfrastructureService.registerDevice(
              null,
              deviceRegisterPayload,
              [provider, obj.name],
            )
            registerDevice
              .then((resp: any) => {
                // showMessage('SUCCESS_DEVICE_REGISTER', 1, 1)
                this.setState({
                  workflowType: 'register',
                  workflowID: resp.data.workflow.uuid,
                  workflowSliderState: true,
                  workflowMappedObj: obj,
                  provisionScan: false,
                })
              })
              .catch((error: any) => {
                const msg = error?.response?.data?.message
                  ? error.response.data.message
                  : ''
                if (!this.Utilities.isRBACAccessDenied(error)) {
                  showMessage('FAILURE_DEVICE_REGISTER', 0, 1, msg)
                }
                this.setState({
                  workflowSliderState: false,
                })
                this.loadClusters()
              })
          })
        }
        this.setState({
          loadingIconForm: false,
        })
      })
      .catch((error: any) => {
        this.setState({
          loadingIconForm: false,
        })
      })
  }

  setDeviceLicense = (deviceList: any[], isEditMode: boolean) => {
    // const { showMessage } = this.Utilities
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    if (!isEditMode) {
      const deviceLicReqs: any[] = []
      deviceList.forEach((device: any) => {
        const devLicense = device.licenseObj
          ? JSON.parse(device.licenseObj)
          : {}
        const payload = {
          licenseinfo: {
            'device-bandwidth':
              devLicense['entitlement-key'] || device.licenseToken
                ? device['bandwidthUnit'] === 'm'
                  ? device['numBandwidth']
                  : device['numBandwidth'] * 1000
                : '',
            'license-type':
              !!devLicense['licenseType'] &&
              devLicense['licenseType'] !== 'UNLICENSED'
                ? devLicense['licenseType']
                : 'DEVICE_LICENSE',
            'lic-key': devLicense['entitlement-key']
              ? devLicense['entitlement-key']
              : '',
            'license-state': devLicense['licenseState']
              ? devLicense['licenseState']
              : devLicense['entitlement-key']
              ? 'PENDING'
              : 'NOT_AVAILABLE',
          },
        }
        const setDeviceLicense = this.InfrastructureService.setDeviceLicense(
          null,
          payload,
          [PROVIDER, device.deviceName],
        )

        deviceLicReqs.push(setDeviceLicense)
      })

      Promise.all(deviceLicReqs)
        .then((resp: any) => {
          // showMessage('SUCCESS_DEVICE_REGISTER', 1, 1)
          // console.log(resp)
        })
        .catch((error: any) => {
          // showMessage('FAILURE_DEVICE_LICENSE_ASSIGN', 0, 1)
        })
    }
  }

  vcsToHAClusterOnboard = () => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const cluster = this.state.clusterObj
    const payload = {
      cluster: {
        port: cluster.floatingPort,
        username: cluster.clusterUserName,
        password: cluster.clusterPassword,
      },
    }
    const vcsToHA = this.InfrastructureService.vcsToHAOnboard(null, payload, [
      PROVIDER,
      cluster.clusterName,
    ])
    vcsToHA
      .then((resp: any) => {
        this.setSlidingPage(false)
        this.setState({
          workflowType: 'cluster',
          workflowID: resp.data.workflow.uuid,
          workflowSliderState: true,
          workflowMappedObj: {
            name: cluster.clusterName,
            'display-name': cluster.clusterDName,
          },
          provisionScan: false,
        })
      })
      .catch((error: any) => {
        // console.log('vcs to ha call error')
      })
  }

  handleClusterUpdate = async (deviceAdd: boolean) => {
    const {
      storage: {
        get: { PROVIDER: provider },
      },
    } = this.context

    const {
      clusterName,
      clusterDName,
      type,
      dataPlaneHA,
      description,
      clusterSetID,
      floatingIP,
      floatingPort,
      // floatingIPMask,
      convertToHA,
    } = this.state.clusterObj

    const devicesAdded = this.state.clusterObj.devices
    const { showMessage } = this.Utilities

    // if (type === 'ha' && devAdd && 'editCluster' != this.state.slidingForm) {
    //   if (!devicesAdded[0].haPrimary && !devicesAdded[1].haPrimary) {
    //     showMessage('Please select the HA primary device', 0, 0)
    //     return
    //   } else if (
    //     devicesAdded[0].haPrimary &&
    //     devicesAdded[0].deviceName === ''
    //   ) {
    //     showMessage(
    //       'Please select the device with details entered as HA primary device',
    //       0,
    //       0,
    //     )
    //     return
    //   } else if (
    //     devicesAdded[1] &&
    //     devicesAdded[1].haPrimary &&
    //     devicesAdded[1].deviceName === ''
    //   ) {
    //     showMessage(
    //       'Please select the device with details entered as HA primary device',
    //       0,
    //       0,
    //     )
    //     return
    //   }
    // }

    let payload = {
      cluster: {
        name: clusterName,
        'display-name': clusterDName || clusterName,
        type,
        'data-plane-ha': dataPlaneHA,
        description: description !== '' ? description : undefined,
        'floating-ip': type === 'vcs' ? floatingIP : undefined,
        'cluster-set-id':
          (type === 'ha' ||
            (type === 'multinode' && dataPlaneHA === 'vrrpa')) &&
          clusterSetID !== ''
            ? parseInt(clusterSetID)
            : undefined,
      },
    }

    let urlInput = [provider]
    let saveCluster = null
    this.setState({
      loadingIconForm: true,
    })
    let convertToHAFeatureCheck = true,
      deviceValidation = false

    if (convertToHA) {
      const deviceValidationpayload = {
        device: {
          host: floatingIP,
          port: floatingPort ? parseInt(floatingPort) : 443,
          username: this.state.clusterObj.clusterUserName,
          password: this.state.clusterObj.clusterPassword,
          type: type,
          'data-plane-ha': dataPlaneHA,
        },
      }
      const validateDevice = this.InfrastructureService.validateDeviceForAdd(
        null,
        deviceValidationpayload,
        [provider, clusterName],
      )
      await validateDevice
        .then((resp: any) => {
          deviceValidation = resp?.data?.status ? resp.data.status : false
          const validationMsg = resp?.data?.msg ? resp.data.msg : ''
          const respDataPlaneHA =
            resp?.data && resp.data['data-plane-ha']
              ? resp.data['data-plane-ha']
              : ''
          if (deviceValidation) {
            payload.cluster['data-plane-ha'] = respDataPlaneHA
          } else {
            showMessage(validationMsg || 'Floating IP validation failed ', 0, 0)
          }
        })
        .catch((error: any) => {
          if (!this.Utilities.isRBACAccessDenied(error)) {
            showMessage('Floating IP validation failed ', 0, 0)
          }
        })

      if (!deviceValidation) {
        this.setState({
          loadingIconForm: false,
        })
        return
      }

      convertToHAFeatureCheck = false
      const fcPayload = {
        'device-vs-feature-info': {
          host: floatingIP,
          port: floatingPort,
          username: this.state.clusterObj.clusterUserName,
          password: this.state.clusterObj.clusterPassword,
          feature: 'convert_vcs_to_ha',
        },
      }
      const featureCheck = this.InfrastructureService.checkFeatureSupport(
        null,
        fcPayload,
        null,
      )
      await featureCheck
        .then((resp: any) => {
          convertToHAFeatureCheck =
            resp && resp.data && resp.data.convert_vcs_to_ha
              ? resp.data.convert_vcs_to_ha
              : false
        })
        .catch((error: any) => {
          const message = error?.response?.data?.message
            ? error.response.data.message
            : ''
          if (!this.Utilities.isRBACAccessDenied(error)) {
            showMessage(
              message || 'Failed to connect to device ' + floatingIP,
              0,
              0,
            )
          }
          this.setState({
            loadingIconForm: false,
          })
        })
    }

    if (this.state.clusterObj.convertToHA && !convertToHAFeatureCheck) {
      this.Utilities.checkFeatureMsg('device', floatingIP)
      this.setState({
        loadingIconForm: false,
      })
    } else {
      if (!this.state.editMode) {
        saveCluster = this.InfrastructureService.addCluster(
          null,
          payload,
          urlInput,
        )
      } else {
        saveCluster = this.InfrastructureService.updateCluster(null, payload, [
          provider,
          clusterName,
        ])
      }
    }

    saveCluster &&
      saveCluster
        .then((response: any) => {
          if (response.data) {
            const messageKey = this.state.editMode
              ? 'SUCCESS_CLUSTER_UPDATE'
              : 'SUCCESS_CLUSTER_ADD'

            if (this.state.clusterObj.convertToHA) {
              this.vcsToHAClusterOnboard()
            }
            if (!this.state.editMode) {
              const cluster = response.data.cluster

              if (
                (type !== 'vcs' ||
                  (type === 'vcs' && !this.state.clusterObj.convertToHA)) &&
                deviceAdd
              ) {
                this.handleAddDevice(devicesAdded, cluster)
              } else {
                showMessage(messageKey, 1, 1)
              }
            } else {
              showMessage(messageKey, 1, 1)
            }
            this.setSlidingPage(false, null, this.state.editMode)
            this.refresh()
          }
          this.setState({
            loadingIconForm: false,
          })
          // to refresh the clusters
        })
        .catch((error: any) => {
          const messageKey = this.state.editMode
            ? 'FAILURE_CLUSTER_UPDATE'
            : 'FAILURE_CLUSTER_ADD'
          if (!this.Utilities.isRBACAccessDenied(error)) {
            showMessage(
              messageKey,
              0,
              1,
              error?.response?.data?.response?.err?.msg
                ? ' - ' + error.response.data.response.err.msg
                : '',
            )
          }
          this.setSlidingPage(false)
          this.setState({
            loadingIconForm: false,
          })
          // to check the error
        })
  }
  handleSave = (e: any) => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  // partition code starts here

  handleFormChange = (data: any, isEdit: boolean) => {
    this.setState({
      partitionformdata: data,
    })
  }

  handleSavePartition = () => {
    const {
      storage: {
        get: { PROVIDER: provider, ENCODED_SESSION_ID },
      },
    } = this.context

    const {
      partitionId,
      partitionName,
      partitionType,
      // description,
      // device,
      // tenant,
    } = this.state.partitionformdata

    // const { showMessage } = this.Utilities

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      provider,
      Authorization: ENCODED_SESSION_ID,
    }

    const initialPayload: any = {
      'partition-list': [
        {
          name: partitionName,
          id: parseInt(partitionId),
          ...(partitionType && { 'p-type': partitionType }),
        },
      ],
    }

    this.setState({
      loadingIconForm: true,
    })

    const partitionInitialRequest = this.InfrastructureService.addPartitionToClusterInitialRequest(
      headers,
      initialPayload,
      [provider, this.state.selectedCluster.name],
    )

    partitionInitialRequest
      .then((resp: any) => {
        const payload: any = {
          'create-partitions': {
            'write-mem': true,
            'partition-list': [partitionName],
          },
        }

        const partitionSave = this.InfrastructureService.addPartitionToCluster(
          headers,
          payload,
          [provider, this.state.selectedCluster.name],
        )

        partitionSave
          .then((resp: any) => {
            const message = 'SUCCESS_PARTITION_ADD'
            // showMessage(message, 1, 1)
            this.setSlidingPage(false)
            this.setState({
              workflowType: 'partitionAdd',
              workflowID: resp.data.workflow.uuid,
              workflowSliderState: true,
              workflowMappedObj: this.state.selectedCluster,
              provisionScan: false,
            })
          })
          .catch((error: any) => {
            const msg = error?.response?.data?.response?.err?.msg
              ? error.response.data.response.err.msg
              : ''
            if (!this.Utilities.isRBACAccessDenied(error)) {
              this.Utilities.showMessage(
                'Error in adding cluster partition',
                0,
                0,
                msg,
                10,
              )
            }

            this.setSlidingPage(false)
            this.setState({
              workflowSliderState: false,
            })
          })
        this.setState({
          loadingIconForm: false,
        })
        this.loadClusters()
      })
      .catch((error: any) => {
        const msg = error?.response?.data?.response?.err?.msg
          ? error.response.data.response.err.msg
          : ''
        if (!this.Utilities.isRBACAccessDenied(error)) {
          this.Utilities.showMessage(
            'Error in adding cluster partition',
            0,
            0,
            msg,
            10,
          )
        }
        this.setSlidingPage(false)
        this.setState({
          loadingIconForm: false,
          workflowSliderState: false,
        })
        // to check the error
      })
  }

  handleSlidingFormCancel = () => {
    if (this.state.slidingForm === 'addDeviceLicense') {
      this.setState({
        editMode: false,
        showSlidingPage: true,
        slidingTitle:
          this.toDeviceLicense === 'addDevice'
            ? 'Add new device'
            : 'Add Cluster',
        slidingDesc: 'Please provide the detail to connect to device',
        slidingForm: this.toDeviceLicense,
      })
    } else {
      this.setSlidingPage(false)
    }
  }

  handleChangeForSyncClusterConfig = (stateName: string, e: any) => {
    const { syncClusterConfig } = this.state
    if (e.target) {
      if (e.target.type === 'checkbox') {
        syncClusterConfig[stateName] = e.target.checked
      } else {
        syncClusterConfig[stateName] = e.target.value
      }
    } else {
      syncClusterConfig[stateName] = e
    }
  }
  // partition code ends here

  saveBulkManageDevicesLicense = () => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const { formDevice, clusters } = this.state

    formDevice.forEach(form => {
      const {
        cluster: clusterName,
        deviceName,
        bandwidthUnit,
        numBandwidth,
      } = form
      const licenseObj = JSON.parse(form.licenseObj)
      const cluster = clusters.find(cluster => cluster.name === clusterName)
      const device = (cluster?.['referrer-list'] || []).find(
        (device: IObject) => device.name === deviceName,
      )

      // if the license is external licensed, do not send the request to update license
      if (licenseObj.internal) {
        const bandwidth =
          bandwidthUnit === 'm' ? numBandwidth : +numBandwidth * 1000
        const url = `/hls/licenses/providers/${PROVIDER}/change/device/${device['device-uuid']}/DEVICE_LICENSE/newkey/${licenseObj['entitlement-key']}/bandwidth/${bandwidth}`

        httpClient
          .post(url, null, {
            absoluteBasePath: true,
          })
          .then(res => {
            if (res?.status === 200) {
              this.setSlidingPage(false)
              this.Utilities.showMessage('DEVICE_LICENSE_CHANGE_SUCCESS', 1, 1)
              this.refresh()
            }
          })
          .catch(err => {
            const msg = err?.response?.data?.message || ''
            this.Utilities.showMessage('DEVICE_LICENSE_CHANGE_FAIL', 0, 1, msg)
          })
      }
    })
  }

  saveCluster = () => {
    const slidingForm = this.state.slidingForm
    const { type, devices } = this.state.clusterObj

    let deviceAdded = false
    devices &&
      devices.map((deviceObj: any) => {
        if (deviceObj.deviceName !== '') {
          deviceAdded = true
        }
      })

    if (
      (slidingForm === 'addCluster' && deviceAdded) ||
      slidingForm === 'addDevice'
    ) {
      this.toDeviceLicense = slidingForm
      this.setState({
        editMode: false,
        showSlidingPage: true,
        slidingTitle: 'Device License',
        slidingDesc: 'Please provide license information for device(s)',
        slidingForm: 'addDeviceLicense',
      })
      return
    }
    const { callbackObjectExplorerMode } = this.props
    if (slidingForm === 'addDevice' || this.toDeviceLicense === 'addDevice') {
      this.handleAddDevice(this.state.formDevice)
    } else {
      this.handleClusterUpdate(deviceAdded)
    }

    if (callbackObjectExplorerMode) {
      callbackObjectExplorerMode()
    }
  }

  lpSuccessCount: number = 0

  callReProvisionAPI = (
    clusterObj: IObject,
    toBeScannedPartitions: IObject[],
  ) => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const payload = {
      scan: toBeScannedPartitions,
      scan_type: ['lp'],
    }
    this.InfrastructureService.createReProvision(null, payload, [
      PROVIDER,
      this.state.clusterObj.name,
    ])
      .then((response: any) => {
        // showMessage('CREATE_RE_PROVISION_SUCCESS', 1, 1)
        this.setSlidingPage(false)
        this.setState({
          workflowType: 'provision',
          workflowID: response.data.workflow['workflow-id'],
          loadingIconForm: false,
          workflowSliderState: true,
          workflowMappedObj: clusterObj,
          provisionScan: toBeScannedPartitions.length > 0,
        })
      })
      .catch((error: any) => {
        if (!this.Utilities.isRBACAccessDenied(error)) {
          this.Utilities.showMessage('Failure to provision', 0, 1)
        }
        this.setState({ loadingIconForm: false })
      })
  }

  saveProvision = async () => {
    const {
      storage: {
        get: { PROVIDER },
      },
    } = this.context

    const { showMessage } = this.Utilities
    const { provisionData, clusterObj, syncClusterConfig } = this.state
    let totalMaps = provisionData.partitionMap.length
    const partitionList: IObject[] = []
    let lpListPayloadTenantwise: any = {}
    let unmapPartitionObj: any[] = []
    let newmapPartitionObj: any[] = []
    let changedPartition = provisionData.partitionMap.filter(
      (partition: any) => {
        return partition.changed
      },
    )

    const toBeScannedPartitions = provisionData.partitionMap.reduce(
      (partition: IObject[], refPartition: IObject) => {
        if (refPartition.scan) partition.push(refPartition.name)
        return partition
      },
      [],
    )

    if (!changedPartition.length && !toBeScannedPartitions.length) {
      this.callReProvisionAPI(clusterObj, toBeScannedPartitions)
      // showMessage('No change to update the partition details', 2, 0)
      return
    }
    this.setState({ loadingIconForm: true })
    provisionData.partitionMap.map(
      async (partitionMapObj: any, index: number) => {
        const tenantObject = partitionMapObj.tenant
          ? JSON.parse(partitionMapObj.tenant)
          : ''
        // if (partitionMapObj.scan) {
        //   toBeScannedPartitions.push(partitionMapObj.name)
        // }
        this.setState({ loadingIconForm: true })
        if (partitionMapObj['changed']) {
          if (tenantObject.name !== 'Please Select') {
            if (!lpListPayloadTenantwise[tenantObject.name]) {
              lpListPayloadTenantwise[tenantObject.name] = {
                'logical-partition-list': [],
              }
            }
            lpListPayloadTenantwise[tenantObject.name][
              'logical-partition-list'
            ].push({
              type:
                partitionMapObj['p-type'] === 'service-partition'
                  ? 'service-partition'
                  : partitionMapObj.id === 0
                  ? 'shared'
                  : 'private-partition',
              name: partitionMapObj.logicalPartition,
              cluster: clusterObj.name,
              partition: partitionMapObj.name,
            })
            newmapPartitionObj.push(partitionMapObj)
            if (
              partitionMapObj.existingTenant &&
              partitionMapObj.existingTenant.uuid &&
              partitionMapObj.existingPuuid
            ) {
              unmapPartitionObj.push(partitionMapObj)
            }
          } else {
            // in case tenant selected as please select allow to save other tenant
            if (
              partitionMapObj.existingTenant &&
              partitionMapObj.existingTenant.uuid &&
              partitionMapObj.existingPuuid
            ) {
              // await this.InfrastructureService.unmapTenantPartitonFunctions(
              //   partitionMapObj,
              //   false,
              // )
              unmapPartitionObj.push(partitionMapObj)
            }
            partitionList.push({
              name: partitionMapObj.name,
              id: partitionMapObj.id,
              'partition-uuid': partitionMapObj['partition-uuid'],
              'tenant-uuid': '',
              'lp-uuid': '',
            })
            totalMaps--
          }
          // in case the cluster partition is reassigned
        } else {
          // in case the cluster partition is not reassigned
          partitionList.push({
            name: partitionMapObj.name,
            id: partitionMapObj.id,
            'partition-uuid': partitionMapObj['partition-uuid'],
            'tenant-uuid': !!tenantObject ? tenantObject.uuid : '',
            'lp-uuid': !!tenantObject
              ? this.state.clusterObj['partition-list'][index]['lp-uuid']
              : '',
          })
          totalMaps--
        }
      },
    )

    if (newmapPartitionObj.length > 0) {
      const lpTenantKeys = Object.keys(lpListPayloadTenantwise)
      // lpTenantKeys.map(async (tenant: string) =>
      for (let i = 0; i < lpTenantKeys.length; i++) {
        const lpResponse = this.InfrastructureService.createLogicalPartition(
          null,
          lpListPayloadTenantwise[lpTenantKeys[i]],
          [PROVIDER, lpTenantKeys[i]],
        )
        await lpResponse
          .then((response: any) => {
            if (
              response?.data &&
              response.data['logical-partition-list'].length > 0
            ) {
              response.data['logical-partition-list'].forEach((lp: any) => {
                const partitionMapObj = newmapPartitionObj.find((obj: any) => {
                  return obj.name === lp.partition
                })
                if (partitionMapObj) {
                  partitionList.push({
                    name: partitionMapObj.name,
                    id: partitionMapObj.id,
                    'partition-uuid': partitionMapObj['partition-uuid'],
                    'tenant-uuid': JSON.parse(partitionMapObj.tenant).uuid,
                    'lp-uuid': lp.uuid,
                  })
                }
              })
            }
          })
          .catch((error: any) => {
            if (!this.Utilities.isRBACAccessDenied(error)) {
              if (error?.response?.data?.response?.err?.msg) {
                showMessage(error.response.data.response.err.msg, 0, 0)
              }
            }
            this.setState({ loadingIconForm: false })
          })
      }
    }

    for (let i = 0; i < unmapPartitionObj.length; i++) {
      await this.InfrastructureService.unmapTenantPartitonFunctions(
        unmapPartitionObj[i],
        true,
      )
    }

    if (partitionList.length > 0) {
      this.InfrastructureService.updateClusterPartion(
        null,
        { 'partition-list': partitionList },
        [PROVIDER, this.state.clusterObj.name],
      )
        .then((response: any) => {
          let clusterPartition = response['data']
          let clusterobj = { ...this.state.clusterObj }
          clusterobj['partition-list'] = clusterPartition
          this.setState({
            clusterObj: clusterobj,
          })

          this.callReProvisionAPI(clusterObj, toBeScannedPartitions)
        })
        .catch((error: any) => {
          if (!this.Utilities.isRBACAccessDenied(error)) {
            if (error?.response?.data?.response?.err?.msg) {
              showMessage(error.response.data.response.err.msg, 0, 0)
            }
          }
          this.setState({ loadingIconForm: false })
        })
    }
  }

  refresh = () => {
    this.loadClusters()
    this.loadDevices()
  }

  onSearch = (e: any) => {
    this.setState({
      searchString: (e?.target?.value || '').toLowerCase(),
    })
  }

  updateSelectedIndex = (index: number) => {
    this.setState({
      selectedIndex: index,
    })
  }

  renderExpandedRow = (record: IObject) => {
    return (
      <Cluster
        tab={this.state.tab}
        clusterName={record.name}
        clustersUpdated={this.state.clustersUpdated}
        refreshClusterData={() => this.loadClusters()}
        defaultMethods={this.props.defaultMethods}
        addPartition={this.addPartition}
        refreshClusterSession={this.props.refreshClusterSession}
      />
    )
  }

  onTabChange = (tab: string) => {
    this.setState({
      tab,
    })
  }

  onClickOEMenu(name: string, index: number) {
    const {
      storage: { set },
    } = this.context

    const { openDeviceList } = this.props
    set['object.explorer.cluster.index'](index)
    set['object.explorer.cluster.name'](name)
    openDeviceList()
  }

  render() {
    const {
      storage: {
        get: { ADMIN_LEVEL, PROVIDER },
      },
    } = this.context

    const { redirectPath } = this.state

    if (redirectPath) {
      return <Redirect to={redirectPath} />
    }

    const {
      isLoading,
      clusters,
      filteredClusters,
      selectedCluster,
      clusterObj,
    } = this.state
    let deviceAdd = false
    clusterObj &&
      clusterObj.devices &&
      clusterObj.devices.map((dev: any) => {
        if (dev.deviceName !== '') {
          deviceAdd = true
        }
      })

    return (
      <>
        <ContentSection>
          <ContentHeader type="flex" align="middle" justify="space-between">
            <A10Col>
              <ContentTitle title="Clusters" count={clusters.length} />
            </A10Col>
            <A10Col className={styles.actionButtonContainer}>
              <div className={styles.searchInputContainer}>
                <A10Input.Search
                  type="text"
                  onChange={this.onSearch}
                  onSearch={this.onSearch}
                  name="searchBox"
                  value={this.state.searchString}
                  placeholder="Search"
                />
              </div>
              {ADMIN_LEVEL === 'provider' && !this.isOperatorUser && (
                <ActionButton
                  text="Create"
                  onClick={this.addCluster}
                  iconProps={{ app: 'global', type: 'add-new' }}
                />
              )}
              <ActionButton
                text="Refresh"
                onClick={this.refresh}
                iconProps={{ app: 'global', type: 'refresh' }}
              />
            </A10Col>
          </ContentHeader>
          <ContentBody>
            <TabGroup onTabChange={this.onTabChange} />
            <div ref={this.ref}>
              <A10Table
                columns={this.clustersColumns}
                expandedRowRender={this.renderExpandedRow}
                dataSource={filteredClusters}
                scroll={{ x: true }}
                loading={
                  isLoading && {
                    indicator: <A10Loader container={this.ref} />,
                  }
                }
                pagination={{ hideOnSinglePage: true, pageSize: 10 }}
                className={styles.clustersTable}
                onRow={(record, rowIndex) => {
                  return {
                    onMouseEnter: (e: React.MouseEvent<HTMLDivElement>) => {
                      e.preventDefault()

                      const currentTarget = e.currentTarget as HTMLDivElement

                      this.setState({
                        popup: {
                          record,
                          visible: true,
                          rowHeight: currentTarget.clientHeight,
                        },
                      })
                    }, // mouse enter row
                    onMouseLeave: () => {
                      this.setState({
                        popup: {
                          ...this.state.popup,
                          visible: false,
                        },
                      })
                    }, // mouse leave row
                  }
                }}
              />
            </div>
          </ContentBody>
        </ContentSection>

        {!this.state.workflowSliderState ? (
          this.state.selectedCluster['device-count'] > 0 ? null : (
            <A10Modal
              title="Delete Cluster"
              visible={this.state.deleteModalState}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer={[
                <A10Button
                  key="no"
                  type="primary"
                  onClick={this.handleCancel}
                  className="nobtn"
                  disabled={this.state.loadingIcon}
                >
                  No
                </A10Button>,
                <A10Button
                  key="yes"
                  type="default"
                  onClick={this.handleOk}
                  className="yesbtn"
                  disabled={this.state.loadingIcon}
                >
                  Yes
                </A10Button>,
              ]}
            >
              <p>
                Do you want to delete{' '}
                {this.state.selectedCluster['display-name'] ||
                  this.state.selectedCluster.name}{' '}
                from {PROVIDER}?
              </p>
              {this.state.loadingIcon ? (
                <div className="">
                  <ReactLoading
                    type="bars"
                    color="#4a90e2"
                    height={40}
                    width={40}
                  />
                </div>
              ) : null}
            </A10Modal>
          )
        ) : null}

        <FormatSlidingPage
          isOpen={this.state.showSlidingPage}
          onRequestOk={this.handleSave}
          onRequestClose={this.handleSlidingFormCancel}
          title={this.state.slidingTitle}
          saveText={
            this.state.slidingForm === 'reprovision'
              ? this.state.loadingIconForm
                ? 'Provisioning...'
                : 'Provision'
              : (this.state.slidingForm === 'addCluster' && deviceAdd) ||
                this.state.slidingForm === 'addDevice'
              ? 'Next'
              : 'Save'
          }
          description={this.state.slidingDesc}
          hideCancel={
            this.state.loadingIconForm ||
            this.state.slidingForm === 'addDeviceLicense'
          }
          showBack={this.state.slidingForm === 'addDeviceLicense'}
          disableSave={
            this.state.slidingForm === 'addDeviceLicense'
              ? this.devAuthFailed
              : this.state.slidingForm === 'reprovision'
              ? this.state.loadingIconForm
              : false
          }
        >
          <>
            {this.state.slidingForm === 'editCluster' ? (
              <ClusterAddForm
                formData={this.state.clusterObj}
                cluster={this.state.clusterObj}
                handleChange={this.handleChange}
                onSubmitForm={this.saveCluster}
                onRef={(ref: any): any => (this.childForm = ref)}
                editMode={this.state.editMode}
                hideDevicePanel={false}
              />
            ) : this.state.slidingForm === 'addCluster' ? (
              <ClusterAddForm
                formData={this.state.clusterObj}
                cluster={null}
                handleChange={this.handleChange}
                onSubmitForm={this.saveCluster}
                onRef={(ref: any): any => (this.childForm = ref)}
                hideDevicePanel={false}
              />
            ) : this.state.slidingForm === 'addDevice' ? (
              <DeviceAddForm
                defaultMethods={this.props.defaultMethods}
                formData={this.state.formDevice}
                cluster={this.state.clusterObj}
                handleChange={this.handleDeviceChange}
                onSubmitForm={this.saveCluster}
                onRef={(ref: any): any => (this.childForm = ref)}
                hideDevicePanel={false}
              />
            ) : this.state.slidingForm === 'addDeviceLicense' ? (
              <DeviceLicenseForm
                defaultMethods={this.props.defaultMethods}
                formData={
                  this.toDeviceLicense === 'addCluster'
                    ? this.state.clusterObj.devices
                    : this.state.formDevice
                }
                cluster={this.state.clusterObj}
                handleChange={this.handleDeviceLicenseChange}
                onSubmitForm={this.saveCluster}
                onRef={(ref: any): any => (this.childForm = ref)}
              />
            ) : this.state.slidingForm === 'editDeviceLicense' ? (
              <DeviceLicenseForm
                defaultMethods={this.props.defaultMethods}
                formData={this.state.formDevice}
                cluster={this.state.selectedCluster}
                handleChange={this.handleDeviceLicenseChange}
                onSubmitForm={this.saveBulkManageDevicesLicense}
                onRef={(ref: any): any => (this.childForm = ref)}
                updateMode={true}
              />
            ) : this.state.slidingForm === 'reprovision' ? (
              <ProvisionForm
                defaultMethods={this.props.defaultMethods}
                provisionData={this.state.provisionData}
                cluster={this.state.clusterObj}
                handleChange={this.handleProvisionChange}
                onSubmitForm={this.saveProvision}
                onRef={(ref: any): any => (this.childForm = ref)}
              />
            ) : null}

            {this.state.loadingIconForm ? (
              <div>
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </div>
            ) : null}
          </>
        </FormatSlidingPage>
        <FormatSlidingPage
          isOpen={this.state.showSlidingPartition}
          onRequestOk={this.handleSave}
          onRequestClose={this.setSlidingPage.bind(this, false)}
          title={'Add new Partition'}
          description={'Please provide the detail of the partition'}
          hideCancel={this.state.loadingIconForm}
        >
          <>
            <PartitionAddForm
              defaultMethods={this.props.defaultMethods}
              formData={this.state.partitionformdata}
              handleChange={this.handleFormChange}
              onSubmitForm={this.handleSavePartition}
              onRef={(ref: any): any => (this.childForm = ref)}
              clusterLevelPartitionEnable={this.state.partitionFormName}
              clusterData={this.state.selectedCluster}
            />
            {this.state.loadingIconForm ? (
              <div>
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </div>
            ) : null}
          </>
        </FormatSlidingPage>
        <SlidingWorkflowStatus
          isOpen={
            this.state.workflowSliderState &&
            this.state.workflowID !== '' &&
            !!clusters
          }
          onRequestClose={this.handleSlidingWorkflowOnCancel}
          type={this.state.workflowType}
          provisionScan={this.state.provisionScan}
          updateWorkflowID={this.updateWorkflowID}
          id={this.state.workflowID}
          assocObject={this.state.workflowMappedObj}
          showMessage={true}
          onCompleteCallback={this.refresh}
        />
        <SlidingUpgrade
          isOpen={this.state.showUpgradePage}
          onRequestClose={this.closeUpgradeSlidingPage}
          clusterName={this.state.selectedCluster.name}
        />
        <SlidingWorkflowStatusList
          isOpen={
            this.state.showWorkflowStatusSlidingPage &&
            this.state.workflowIDList.length > 0
          }
          title={'Image Upgrade'}
          clusterName={this.state.selectedCluster.name}
          onRequestClose={this.onCloseWorkflowStatusWindow}
          idList={this.state.workflowIDList}
          objectList={this.state.selectedClusterToUpgrade}
        />
        <A10Modal
          title="Scan Cluster Config"
          visible={this.state.scanClusterState}
          onOk={() => this.scanCluster(selectedCluster)}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={this.state.loadingIcon}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={() => this.scanCluster(selectedCluster)}
              className="yesbtn"
              disabled={this.state.loadingIcon}
            >
              Yes
            </A10Button>,
          ]}
        >
          <p>
            Do you want to proceed with Scan Config for Cluster :{' '}
            {this.state.selectedCluster['display-name'] ||
              this.state.selectedCluster.name}
          </p>
          {this.state.loadingIcon ? (
            <div className="">
              <ReactLoading
                type="bars"
                color="#4a90e2"
                height={40}
                width={40}
              />
            </div>
          ) : null}
        </A10Modal>
      </>
    )
  }
}
export default setupA10Container(Clusters)
