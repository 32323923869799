import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import { useAsyncEffect } from '@gui-libraries/framework'
import { A10Loader, A10Alert } from '@gui-libraries/widgets'

import styles from './styles/index.module.less'

const DeviceEnvHealth: React.FC<any> = props => {
  const refContainer = useRef()
  const { service, startTime, endTime, parameters, cluster, device } = props
  const { api } = parameters
  const deviceUUID = device['device-uuid']
  const [isLoading, setIsLoading] = useState(true)
  const [fanCount, setFanCount] = useState([0, 0])
  const [powerCount, setPowerCount] = useState([0, 0])
  const [voltageCount, setVoltageCount] = useState([0, 0])
  const [temperature, setTemperature] = useState(0)

  const payload = useMemo(
    () => ({
      stats: {
        rangeby: {
          start: startTime,
          end: endTime,
          field: 'ts',
        },
        filterby: {
          and: {
            device_uuid: deviceUUID,
          },
        },
        size: 1,
      },
    }),
    [startTime, endTime, deviceUUID],
  )

  useAsyncEffect(async () => {
    setIsLoading(true)

    try {
      const url = `${api}/analytics/thunder-adc/system_telemetry_log_environment`
      const res = await service.sendRequest(
        url,
        'POST',
        service.RPTHeaders,
        payload,
        null,
      )
      const data: IObject = res?.data?.stats || {}

      const {
        fan_report = {},
        system_voltage = {},
        power_unit = {},
        physical_system_temperature = {},
      } = Object.values(data)[0]

      const fans: string[] = Object.values(fan_report)
      const fanCount = fans.reduce(
        (acc: number[], fan: string) => {
          if (fan.includes('OK')) {
            acc[0]++
          }
          acc[1]++

          return acc
        },
        [0, 0],
      )

      const voltages: string[] = Object.values(system_voltage)
      const voltageCount = voltages.reduce(
        (acc: number[], voltage: string) => {
          if (voltage.includes('OK')) {
            acc[0]++
          }
          acc[1]++

          return acc
        },
        [0, 0],
      )

      const powers: string[] = Object.values(power_unit)
      const powerCount = powers.reduce(
        (acc: number[], power: string) => {
          if (!power.includes('State On')) {
            acc[0]++
          }
          acc[1]++

          return acc
        },
        [0, 0],
      )

      const temperatures: number[] = Object.values(physical_system_temperature)

      setFanCount(fanCount)
      setVoltageCount(voltageCount)
      setPowerCount(powerCount)
      setTemperature(temperatures[0])
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [api, payload, service])

  if (
    cluster['virtualization-type'] !== 'Thunder' ||
    device['model'] === 'vThunder'
  ) {
    return (
      <A10Alert
        style={{ marginTop: '10px' }}
        message="Data Not Available. Device Environment data is published only by
        physical appliances at this time."
        type="info"
        showIcon
      />
    )
  }

  return (
    <div ref={refContainer}>
      {isLoading && <A10Loader container={refContainer} />}
      {!isLoading && (
        <div className={styles.deviceEnvHealth}>
          <div>
            <div>Fan</div>
            <div>
              {fanCount[0]} / {fanCount[1]} OK
            </div>
          </div>
          <div>
            <div>System Voltage</div>
            <div>
              {voltageCount[0]} / {voltageCount[1]} OK
            </div>
          </div>
          <div>
            <div>Power Supply</div>
            <div>
              {powerCount[0]} / {powerCount[1]} OFF
            </div>
          </div>
          <div>
            <div>Temperature</div>
            <div>{temperature} °C</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DeviceEnvHealth
