import React from 'react'
import { A10Badge, A10Col, A10Collapse, A10Row } from '@gui-libraries/widgets'
import {
  A10Container,
  IA10ContainerDefaultProps,
  _,
  getNS,
  setupA10Container,
} from '@gui-libraries/framework'
import { OrganizationService, Utilities } from 'src/services/index'

import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { Map } from 'immutable'
import { Messages } from 'src/locale/en'
import ReactLoading from 'react-loading'
import { UserForm } from 'src/containers/Controller/Organization/RBACUsers/Forms/UserForm'
import storage from 'src/libraries/storage'

const styles = require('./styles/index.module.less')

export interface IAccessGroupDetailProps extends IA10ContainerDefaultProps {
  data: IObject
  device_cluster_map: IObject
  access_group_user_map: Map<string, string[]>
  refresh: () => void
}

export interface IAccessGroupDetailStates {
  tenants: IObject[]
  devices: IObject[]
  logicalPartitions: IObject[]
  users: string[]
  showSlidingPage: boolean
  showResetPasswordForm: boolean
  slidingTitle: string
  slidingDesc: string
  loadingIcon: boolean
  addUserObj: IObject
  formUser: any
  allUsers: any[]
  editMode: boolean
}

class AccessGroupDetail extends A10Container<
  IAccessGroupDetailProps,
  IAccessGroupDetailStates
> {
  Utilities: any = null
  OrganizationService: any = null
  childForm: any = null
  Messages: any = null
  dataLoaded = false
  adminLevel = storage.get.ADMIN_LEVEL
  constructor(props: IAccessGroupDetailProps) {
    super(props)
    this.Utilities = new Utilities()
    this.OrganizationService = new OrganizationService()
    this.Messages = new Messages()
    this.state = {
      tenants: [],
      devices: [],
      logicalPartitions: [],
      users: [],
      slidingTitle: 'Add new User',
      slidingDesc: 'Please provide the user’s information',
      showSlidingPage: false,
      showResetPasswordForm: false,
      loadingIcon: false,
      formUser: null,
      allUsers: [],
      addUserObj: {
        firstName: '',
        lastName: '',
        userId: '',
        emailId: '',
        rbacRoles: [],
        rbacRole: '',
        showRbacRole: true,
        resetPassword: false,
      },
      editMode: false,
    }
  }
  componentDidMount() {
    this.getPermissions(this.props.data)
  }

  getPermissions = (data: IObject) => {
    let tenants: IObject[] = []
    let devices: IObject[] = []
    let logicalPartitions: IObject[] = []
    const lpPermissionMap = {}
    const resources = data.resourceList
    for (const object in resources) {
      if (object.name === 'provider') {
        for (const instance of object.instanceInfoList) {
          if (tenants.length === 0) {
            tenants = [{ name: 'All', permission: instance.permission }]
          }
          if (devices.length === 0) {
            devices = [{ name: 'All', permission: instance.permission }]
          }
          if (logicalPartitions.length === 0) {
            if (!('*' in lpPermissionMap)) {
              lpPermissionMap['*'] = instance.permission
            }
          }
          break
        }
      }
      if (object === 'tenant') {
        tenants = []
        for (const instance of resources[object]) {
          if (instance.name === '*') {
            tenants = [{ name: 'All', permission: instance.permission }]
            lpPermissionMap['*'] = instance.permission
            break
          } else {
            tenants.push({
              name: instance.name,
              permission: instance.permission,
            })
            lpPermissionMap[instance.name] = instance.permission
            if ('*' in lpPermissionMap) {
              delete lpPermissionMap['*']
            }
          }
        }
      }

      if (object === 'lp') {
        logicalPartitions = []
        for (const instance of resources[object]) {
          if (instance.name === '*') {
            let tenantName = '*'
            for (const item of instance.parentHierarchy) {
              if (Object.keys(item)[0] === 'tenant') {
                tenantName = item.tenant
              }
            }
            logicalPartitions = [
              {
                name: 'All',
                tenant: tenantName,
                permission: instance.permission,
              },
            ]
            break
          } else {
            let tenantName = '*'
            for (const item of instance.parentHierarchy) {
              if (Object.keys(item)[0] === 'tenant') {
                tenantName = item.tenant
              }
            }
            logicalPartitions.push({
              name: instance.name,
              tenant: tenantName,
              permission: instance.permission,
            })
          }
        }
      }
      if (object === 'device' || object === 'cluster') {
        devices = []
        for (const instance of resources[object]) {
          if (instance.name === '*') {
            devices = [{ name: '*', permission: instance.permission }]
            break
          } else {
            devices.push({
              name: instance.name,
              permission: instance.permission,
            })
          }
        }
      }
    }
    if (
      logicalPartitions.length === 0 &&
      Object.keys(lpPermissionMap).length > 0
    ) {
      for (const key in lpPermissionMap) {
        if (lpPermissionMap.hasOwnProperty(key)) {
          logicalPartitions.push({
            name: '*',
            tenant: key,
            permission: lpPermissionMap[key],
          })
        }
      }
    }
    if (Object.keys(resources).length == 0) {
      if (tenants.length === 0) {
        tenants = [{ name: 'All', permission: 'RO' }]
      }
      if (devices.length === 0) {
        devices = [{ name: 'All', permission: 'RO' }]
      }
      if (logicalPartitions.length === 0) {
        logicalPartitions = [
          {
            name: 'All',
            tenant: '*',
            permission: 'RO',
          },
        ]
      }
    }
    this.setState({ tenants, devices, logicalPartitions })
  }
  renderPrivInstance = (privilege: string, text: string) => {
    return (
      <A10Col span={8}>
        <div className={styles.privObjectBlock}>
          <A10Badge
            type="info"
            count={privilege}
            style={{ backgroundColor: '#38a8ee' }}
          />
          <div className={styles.sectionText} title={text}>
            {text}
          </div>
        </div>
      </A10Col>
    )
  }

  handleFormValidation = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  handleAddFormChange = (data: any, isEdit: boolean) => {
    // @ts-ignore
    this.setState(
      {
        addUserObj: data,
      },
      () => {
        if (data.resetPassword) {
          this.setResetPasswordPage(true)
        }
      },
    )
  }

  setResetPasswordPage = (isOpen: boolean) => {
    const { addUserObj } = this.state
    if (isOpen) {
      this.childForm = null
      this.setState({
        showSlidingPage: false,
        showResetPasswordForm: true,
        slidingTitle:
          'Reset Password for ' +
          addUserObj.firstName +
          (addUserObj.lastName ? ' ' + addUserObj.lastName : ''),
        slidingDesc: 'Email: ' + (addUserObj.emailId ? addUserObj.emailId : ''),
      })
    } else {
      addUserObj.resetPassword = false
      this.setState({ showResetPasswordForm: false, addUserObj })
    }
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({ showSlidingPage: isOpen })
  }

  addUser = () => {
    const user = {
      firstName: '',
      lastName: '',
      emailId: '',
      userId: '',
      rbacRoles: [this.props.data.name],
      rbacRole: this.props.data.name,
      showRbacRole: true,
      resetPassword: false,
    }
    this.setState({
      formUser: null,
      editMode: false,
      addUserObj: user,
      showSlidingPage: true,
      showResetPasswordForm: false,
      slidingTitle: 'Add new User',
      slidingDesc: 'Please provide the user’s information',
    })
  }

  handleSave = async () => {
    const {
      firstName,
      lastName,
      userId,
      emailId,
      rbacRoles,
    } = this.state.addUserObj
    const editMode = this.state.editMode

    const adminObj = {
      'email-id': emailId,
      'user-id': userId,
      'first-name': firstName,
      'last-name': lastName,
    }

    let roleChange: boolean = false

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      charset: 'utf-8',
      Authorization: storage.get.ENCODED_SESSION_ID,
      removeProvider: editMode ? false : true,
    }

    if (editMode) {
      delete headers['Content-Type']
      delete headers.Accept
      delete headers.removeProvider

      headers.newEmail = emailId
      headers.newFirstname = firstName
      headers.newLastname = lastName
      headers.removeContentType = true

      roleChange = this.checkForUserRole(rbacRoles)
      if (roleChange) {
        await this.updateUserRbacRoles(
          userId ? userId.toLowerCase() : userId,
          rbacRoles,
        )
      }
    }

    this.setState({
      loadingIcon: true,
    })

    const addUser = editMode
      ? this.OrganizationService.updateUser(headers, null, [userId])
      : this.OrganizationService.addUser(null, adminObj, storage.get.PROVIDER)

    addUser
      .then((response: any) => {
        this.Utilities.showMessage(
          editMode ? 'SUCCESS_USER_UPDATE' : 'SUCCESS_USER_CREATE',
          1,
          true,
        )

        if (!editMode && rbacRoles.length > 0) {
          this.createUserRbacRoles(userId, rbacRoles)
        } else {
          this.props.refresh()
          this.setSlidingPage(false)
          this.setState({
            loadingIcon: false,
          })
        }
      })
      .catch((error: any) => {
        this.setSlidingPage(false)
        let msg = ''
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.status === 409
        ) {
          msg = this.Messages.DUPLICATE_USER_NAME
        }
        this.Utilities.showMessage(
          editMode ? 'FAIL_USER_UPDATE' : 'FAIL_USER_CREATE',
          0,
          true,
          msg,
        )
        this.setState({
          loadingIcon: false,
        })
      })
    return false
  }

  checkForUserRole = (rbacAGs: any) => {
    if (!this.state.formUser) {
      return true
    } else {
      const curRbacAGs = this.state.formUser.rbacAGs
        ? this.state.formUser.rbacAGs
        : []
      if (curRbacAGs.length !== rbacAGs.length) {
        return true
      } else {
        const sameAGs: any = []
        curRbacAGs.map((outerObj: any) => {
          rbacAGs.map((innerObj: any) => {
            if (innerObj === outerObj) {
              sameAGs.push(innerObj)
            }
          })
        })
        return curRbacAGs.length !== sameAGs.length
      }
    }
  }

  updateUserRbacRoles = async (userId: any, roles: any) => {
    try {
      const payload = {
        providerId: storage.get.PROVIDER,
        userId: userId ? userId.toLowerCase() : userId,
        roles,
      }
      if (this.state.formUser.rbacRoleExists) {
        await this.OrganizationService.userRbacRoleUpdate(null, payload, [
          storage.get.PROVIDER,
          userId,
        ])
      } else {
        await this.OrganizationService.userRbacRoleCreate(null, payload, [
          storage.get.PROVIDER,
        ])
      }
    } catch (err) {
      const msg =
        err?.response?.data && typeof err.response.data === 'string'
          ? err.response.data
          : ''
      this.Utilities.showMessage('Error in access group assignment', 0, 0, msg)
    }
  }

  createUserRbacRoles = async (userId: any, roles: any) => {
    const payload = {
      providerId: storage.get.PROVIDER,
      userId,
      roles,
    }

    const createRole = this.OrganizationService.userRbacRoleUpdate(
      null,
      payload,
      [storage.get.PROVIDER, userId],
    )
    createRole
      .then((resp: any) => {
        this.props.refresh()
        this.setSlidingPage(false)
        this.setState({
          loadingIcon: false,
        })
      })
      .catch((error: any) => {
        const msg =
          error &&
          error.response &&
          error.response.data &&
          typeof error.response.data === 'string'
            ? error.response.data
            : ''
        this.Utilities.showMessage(
          'Error in access group assignment',
          0,
          0,
          msg,
        )
        this.props.refresh()
        this.setSlidingPage(false)
        this.setState({
          loadingIcon: false,
        })
      })
  }

  render() {
    const { tenants, devices, logicalPartitions, showSlidingPage } = this.state
    const { data, device_cluster_map, access_group_user_map } = this.props
    const userList = access_group_user_map.get(data.name) || []
    const ControllerScopeHeader = () => {
      return (
        <>
          <A10Row type="flex" align="middle">
            <A10Col lg={20}>
              <div className="section-title-container">
                <span className={styles.sectionTitle}>Controller Scope</span>
              </div>
            </A10Col>
          </A10Row>
        </>
      )
    }

    const ServiceScopeHeader = () => {
      return (
        <>
          <A10Row type="flex" align="middle">
            <A10Col lg={20}>
              <div className="section-title-container">
                <span className={styles.sectionTitle}>Service Scope</span>
              </div>
            </A10Col>
          </A10Row>
        </>
      )
    }

    const UserHeader = () => {
      return (
        <>
          <A10Row type="flex" align="middle">
            <A10Col lg={20}>
              <div className="section-title-container">
                <span className={styles.sectionTitle}>Users</span>
              </div>
            </A10Col>
          </A10Row>
        </>
      )
    }

    return (
      <div className="list-detail-container">
        <div className="detail-section">
          <A10Collapse bordered={false}>
            <A10Collapse.Panel header={<ControllerScopeHeader />} key="1">
              <A10Row className={styles.sectionRow}>
                <A10Col className={styles.sectionTitle} span={6}>
                  Provider > Tenant
                </A10Col>
                <A10Col type="aflex" span={18}>
                  {tenants.map((item: IObject) =>
                    this.renderPrivInstance(item.permission, item.name),
                  )}
                </A10Col>
              </A10Row>
              <A10Row className={styles.sectionRow}>
                <A10Col className={styles.sectionTitle} span={6}>
                  Cluster > Device
                </A10Col>
                <A10Col type="aflex" span={18}>
                  {devices.map((item: IObject) => {
                    const cluster = device_cluster_map.get(item.name)
                    let text = ''
                    if (item.name === '*' || !!!cluster) {
                      text = 'All'
                    } else {
                      text = `${cluster} > ${item.name}`
                    }
                    return this.renderPrivInstance(item.permission, text)
                  })}
                </A10Col>
              </A10Row>
            </A10Collapse.Panel>
          </A10Collapse>
          <A10Collapse bordered={false}>
            <A10Collapse.Panel header={<ServiceScopeHeader />} key="1">
              <A10Row className={styles.sectionRow}>
                <A10Col className={styles.sectionTitle} span={6}>
                  Tenant > Logical Partitions
                </A10Col>
                <A10Col type="aflex" span={18}>
                  {logicalPartitions.map((item: IObject) => {
                    let text = ''
                    if (item.tenant === '*') {
                      text = 'All'
                    } else {
                      text = `${item.tenant} > ${
                        item.name === '*' ? 'All' : item.name
                      }`
                    }
                    return this.renderPrivInstance(item.permission, text)
                  })}
                </A10Col>
              </A10Row>
            </A10Collapse.Panel>
          </A10Collapse>
          <A10Collapse bordered={false}>
            <A10Collapse.Panel header={<UserHeader />} key="1">
              <A10Row className={styles.sectionRow} wrap={true}>
                {userList.length > 0 ? (
                  userList.map((item: string) => {
                    return (
                      <A10Col
                        key={item}
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                        span={4}
                      >
                        <div className={styles.privObjectBlock}>
                          <HealthStatus
                            type="info"
                            text={'U'}
                            hideTooltip={true}
                          />
                          <div className={styles.sectionText}>{item}</div>
                        </div>
                      </A10Col>
                    )
                  })
                ) : (
                  <span className={styles.sectionTitle}>
                    No user is associated
                  </span>
                )}
              </A10Row>
            </A10Collapse.Panel>
          </A10Collapse>
        </div>
        <FormatSlidingPage
          isOpen={showSlidingPage}
          onRequestOk={this.handleFormValidation}
          onRequestClose={this.setSlidingPage.bind(this, false)}
          title={this.state.slidingTitle}
          description={this.state.slidingDesc}
          disableSave={this.state.loadingIcon}
        >
          <>
            <UserForm
              addUserObj={this.state.addUserObj}
              userInfo={this.state.formUser}
              handleChange={this.handleAddFormChange}
              allUsers={this.state.allUsers}
              onSubmitForm={this.handleSave}
              onRef={(ref: any): any => (this.childForm = ref)}
            />
            {this.state.loadingIcon ? (
              <div className="">
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </div>
            ) : null}
          </>
        </FormatSlidingPage>
      </div>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    device_cluster_map: state.A10Data.getIn(getNS('DEVICE_CLUSTER_MAP'), {}),
    access_group_user_map: state.A10Data.getIn(
      getNS('ACCESS_GROUP_USER_MAP'),
      Map<string, string[]>(),
    ),
  }
}
export default setupA10Container(AccessGroupDetail, mapStateToProps)
