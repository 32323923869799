import React from 'react'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import { A10Table, A10Icon } from '@gui-libraries/widgets'

import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import A10Panel from 'src/components/ADC/A10Panel'
import storage from 'src/libraries/storage'

export interface IAssociateServiceGroupProps extends IA10ContainerDefaultProps {
  tenant: string
  existSgNames: string[]
  onChangeSelectedValue: (selectedValue: string[]) => void
}

export interface IAssociateServiceGroupState {
  sgList: IObject[]
  isLoading: boolean
  selectedRowKeys: IObject[]
  currentSelectedNames: string[]
}

class AssociateServiceGroup extends A10Container<
  IAssociateServiceGroupProps,
  IAssociateServiceGroupState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      sgList: [],
      isLoading: false,
      selectedRowKeys: [],
      currentSelectedNames: [],
    }
  }

  componentWillMount() {
    this.init(this.props)
  }

  componentWillReceiveProps(nextProps: any) {
    this.init(nextProps)
  }

  componentDidMount() {
    this.getServiceGroups()
  }

  init = (props: any) => {
    const { existSgNames } = props
    const currentSelectedNames = existSgNames.map((sgName: string) => {
      return sgName
    })
    this.setState({ currentSelectedNames })
  }

  getHttpClient = () => {
    const {
      GLOBAL_CONFIG: {
        EPIC_DEPENDENCIES: { httpClient },
      },
    } = this.props
    return httpClient
  }

  getServiceGroups = async () => {
    // Start to load data
    this.setState({ isLoading: true })

    const { tenant, existSgNames = [] } = this.props
    const httpClient = this.getHttpClient()
    let totalSgList = new Array()

    const sgApi = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/slb/service-group?detail=true`
    const {
      data: { 'service-group-list': sgList = [] },
    } = await httpClient.get(sgApi)
    totalSgList = totalSgList.concat(sgList)

    const initSelectedRowKeys = new Array()
    const newSgList = totalSgList.map((item: IObject, index: number) => {
      item.key = index
      if (existSgNames.indexOf(item.uuid) !== -1) {
        initSelectedRowKeys.push(index)
      }
      return item
    })
    this.setState({
      sgList: newSgList,
      selectedRowKeys: initSelectedRowKeys,
      isLoading: false,
    })
  }

  getColumns = () => {
    return [
      {
        title: 'Service Group',
        dataIndex: 'sg',
        render: (text: IObject, record: IObject) => {
          return record.name
        },
      },
      {
        title: '# Servers',
        dataIndex: 'servers',
        render: (text: IObject, record: IObject) => {
          const { 'member-list': members = [] } = record
          return members.length
        },
      },
      {
        title: '# Shared Obj.',
        dataIndex: 'sharedObj',
        render: (text: IObject, record: IObject) => {
          return 0
        },
      },
    ]
  }

  getRowSelection = () => {
    return {
      selectedRowKeys: this.state.selectedRowKeys,
      onSelect: (
        record: IObject,
        selected?: IObject,
        selectedRows?: IObject[],
      ) => {
        const { currentSelectedNames } = this.state
        let actionRows = currentSelectedNames
        if (selected) {
          if (actionRows.indexOf(record.uuid) === -1) {
            actionRows.push(record.uuid)
          }
        } else {
          actionRows = _.remove(actionRows, (name: string) => {
            return name !== record.uuid
          })
        }
        this.setState({ currentSelectedNames: actionRows }, () => {
          this.onChangeValue()
        })
      },
      onSelectAll: (
        selected: IObject,
        selectedRows: IObject[],
        changeRows: IObject[],
      ) => {
        const { currentSelectedNames } = this.state
        let actionRows = currentSelectedNames
        if (selected) {
          changeRows.map((item: IObject) => {
            if (actionRows.indexOf(item.uuid) === -1) {
              actionRows.push(item.uuid)
            }
          })
        } else {
          const changeRowNames = changeRows.map((item: IObject) => {
            return item.uuid
          })
          actionRows = _.remove(actionRows, (name: string) => {
            return changeRowNames.indexOf(name) === -1
          })
        }
        this.setState({ currentSelectedNames: actionRows }, () => {
          this.onChangeValue()
        })
      },
      onChange: (selectedRowKeys: IObject[]) => {
        this.setState({ selectedRowKeys })
      },
    }
  }

  onChangeValue = () => {
    const { currentSelectedNames } = this.state
    const { onChangeSelectedValue } = this.props
    onChangeSelectedValue(currentSelectedNames)
  }

  render() {
    return (
      <A10Panel
        title={
          <A10IconTextGroup
            text="Service Groups"
            icon={<A10Icon style={{ fontSize: 48 }} type="desktop" />}
          />
        }
        isFormContent={false}
      >
        <A10Table
          columns={this.getColumns()}
          dataSource={this.state.sgList}
          rowSelection={this.getRowSelection()}
          pagination={{
            hideOnSinglePage: true,
          }}
          loading={
            this.state.isLoading
              ? {
                  indicator: (
                    <div className="loading-icon">
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    </div>
                  ),
                }
              : false
          }
        />
      </A10Panel>
    )
  }
}

export default setupA10Container(AssociateServiceGroup)
