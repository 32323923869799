export const cgnFixednatCpsFields = [
  'nat44_data_session_created',
  'nat64_data_session_created',
  'dslite_data_session_created',
]

export const cgnFixednatDataSessionFreed = [
  'nat44_data_session_freed',
  'nat64_data_session_freed',
  'dslite_data_session_freed',
]

export const cgnFixednatBpsFields = [
  'fnat44_fwd_ingress_bytes_others',
  'fnat44_fwd_ingress_bytes_icmp',
  'fnat44_fwd_ingress_bytes_udp',
  'fnat44_fwd_ingress_bytes_tcp',
  'fnat44_rev_egress_bytes_tcp',
  'fnat44_rev_egress_bytes_udp',
  'fnat44_rev_egress_bytes_icmp',
  'fnat44_rev_egress_bytes_others',
  'fnatdslite_fwd_ingress_bytes_others',
  'fnatdslite_fwd_ingress_bytes_icmp',
  'fnatdslite_fwd_ingress_bytes_udp',
  'fnatdslite_fwd_ingress_bytes_tcp',
  'fnatdslite_rev_egress_bytes_tcp',
  'fnatdslite_rev_egress_bytes_udp',
  'fnatdslite_rev_egress_bytes_icmp',
  'fnatdslite_rev_egress_bytes_others',
  'fnat64_fwd_ingress_bytes_others',
  'fnat64_fwd_ingress_bytes_icmp',
  'fnat64_fwd_ingress_bytes_udp',
  'fnat64_fwd_ingress_bytes_tcp',
  'fnat64_rev_egress_bytes_tcp',
  'fnat64_rev_egress_bytes_udp',
  'fnat64_rev_egress_bytes_icmp',
  'fnat64_rev_egress_bytes_others',
]
export const cgnFixednatDropFields = [
  'session_user_quota_exceeded',
  'fullcone_failure',
  'nat44_eif_limit_exceeded',
  'nat64_eif_limit_exceeded',
  'dslite_eif_limit_exceeded',
  'standby_drop',
  'fixed_nat_fullcone_self_hairpinning_drop',
  'sixrd_drop',
  'dest_rlist_drop',
  'dest_rlist_snat_drop',
  'config_not_found',
  'port_overload_failed',
  'ha_session_user_quota_exceeded',
]

export const cgnCpsFields = ['data_session_created']
export const cgnBpsFields = [
  'fwd_ingress_bytes_others',
  'fwd_ingress_bytes_icmp',
  'fwd_ingress_bytes_udp',
  'fwd_ingress_bytes_tcp',
  'rev_egress_bytes_tcp',
  'rev_egress_bytes_udp',
  'rev_egress_bytes_icmp',
  'rev_egress_bytes_others',
]
export const cgnDropFields = [
  'fullcone_failure',
  'fullcone_self_hairpinning_drop',
  'eif_limit_exceeded',
  'nat_mismatch_drop',
  'ha_nat_pool_unusable',
  'ha_nat_pool_batch_type_mismatch',
  'nat_pool_unusable',
  'total_udp_overloaded',
  'total_tcp_overloaded',
  'no_radius_profile_match',
  // 'no_class_list_match',
  'lid_drop',
  'user_quota_unusable',
  'user_quota_unusable_drop',
]
export const fwCpsFields = ['data_session_created']
export const fwBpsFields = [
  'fwd_ingress_bytes_others',
  'fwd_ingress_bytes_icmp',
  'fwd_ingress_bytes_udp',
  'fwd_ingress_bytes_tcp',
  'rev_egress_bytes_tcp',
  'rev_egress_bytes_udp',
  'rev_egress_bytes_icmp',
  'rev_egress_bytes_others',
]
export const fwDropFields = [
  'fullcone_failure',
  'fullcone_self_hairpinning_drop',
  'eif_limit_exceeded',
  'no_class_list_match',
  'lid_drop',
  'user_quota_unusable',
  'user_quota_unusable_drop',
  'nat_mismatch_drop',
  'no_radius_profile_match',
  'ha_nat_pool_unusable',
  'ha_nat_pool_batch_type_mismatch',
  'nat_pool_unusable',
  'total_udp_overloaded',
  'total_tcp_overloaded',
]
