import { A10Button, A10Modal } from '@gui-libraries/widgets'
import React from 'react'
import { Link } from 'react-router-dom'

const ActivateLicense: React.FC<IObject> = (props) => {
  const { visible, content, onClose } = props

  return (
    <A10Modal
      title="No valid license"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Link to="/controller/Organization/License">
          <A10Button
            key="activate"
            type="primary"
          >
            Add license
          </A10Button>
        </Link>,
        <A10Button key="cancel" type="default" onClick={onClose}>
          Cancel
        </A10Button>,
      ]}
    >
      {content}
    </A10Modal>
  )
}

export default ActivateLicense
