import React from 'react'
import { _ } from '@gui-libraries/framework'
import {
  A10Collapse,
  A10DropdownMenu,
  A10Icon,
  A10Row,
  A10Table,
} from '@gui-libraries/widgets'
import { normalizeValue } from '@gui-libraries/viz'

import HealthStatus from 'src/components/ADC/HealthStatus'
import RoundNumber from 'src/components/shared/RoundNumber'
import storage from 'src/libraries/storage'

export interface IInterfaceVEProps {
  veList: IObject[]
  veStatus: IObject
  openAutoForm: any
  deleteVE: any
}

const InterfaceVE: React.FC<IInterfaceVEProps> = props => {
  const { veList, veStatus, openAutoForm, deleteVE } = props
  const [datasource, setDatasource] = React.useState([] as IObject[])
  const [status, setStatus] = React.useState({} as IObject)
  const [counter, setCounter] = React.useState({} as IObject)

  const clickAddNew = (event: React.MouseEvent<HTMLElement>) => {
    if (openAutoForm) {
      openAutoForm('network/interfaces/virtual-ethernet')
    }
    event.stopPropagation()
  }
  const editVE = (ifnum: number) => {
    if (openAutoForm) {
      const params = ifnum ? { ifnum } : undefined
      openAutoForm('network/interfaces/virtual-ethernet', params)
    }
  }

  const clickDeleteVE = (ifnum: number) => {
    if (deleteVE) {
      deleteVE(ifnum)
    }
  }

  React.useEffect(() => {
    const ds = [] as IObject[]
    const currentStatus = { up: 0, down: 0, disable: 0 } as IObject
    const count = {} as IObject
    ;(veList || []).map((ve: IObject) => {
      const { oper = {}, stats = {} } = (veStatus || {})[ve.ifnum] || {}
      currentStatus[oper.state] = (currentStatus[oper.state] || 0) + 1
      const ipv4List = _.get(ve, ['ip', 'address-list'], [])?.map(
        (ip: IObject) => ip['ipv4-address'] || '',
      ) || []
      const ipv6List = _.get(ve, ['ipv6', 'address-list'], [])?.map(
        (ip: IObject) => ip['ipv6-addr'] || '',
      ) || []
      const ipList = [].concat(ipv4List).concat(ipv6List)

      const source = {
        key: ve.ifnum,
        status: oper.state,
        address: ipList.join(', '),
        ifnum: ve.ifnum,
        txPackets: stats.num_tx_pkts || 0,
        rxPackets: (stats.num_pkts || 0) - (stats.num_tx_pkts || 0),
        rxBytes: (stats.num_total_bytes || 0) - (stats.num_total_tx_bytes || 0),
      }
      ds.push(source)
      count.txPackets = (count.txPackets || 0) + source.txPackets
      count.rxPackets = (count.txPackets || 0) + source.rxPackets
      count.rxBytes = (count.txPackets || 0) + source.rxBytes
    })
    setStatus(currentStatus)
    setDatasource(ds)
    setCounter(count)
  }, [veList, veStatus])

  const columns = React.useMemo(
    () => [
      {
        dataIndex: 'status',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return (
            <HealthStatus
              type={record.status}
              tooltip={record.status || 'Unknown'}
              toolTipArrowPosition="topLeft"
            />
          )
        },
      },
      {
        title: 'Interface Number',
        dataIndex: 'ifnum',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return text
        },
      },
      // {
      //   title: 'Partition ID',
      //   dataIndex: 'parititionid',
      //   sorter: (a: any, b: any) => a - b,
      //   render: (text: any, record: any) => {
      //     return text || 'N/A'
      //   },
      // },
      {
        title: 'IP Address',
        dataIndex: 'address',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return (
            <div
              style={{
                textOverflow: 'ellipsis',
                width: 600,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              title={text || 'N/A'}
            >
              {text || 'N/A'}
            </div>
          )
        },
      },
      // {
      //   title: 'Access Control',
      //   dataIndex: 'control',
      //   sorter: (a: any, b: any) => a - b,
      //   render: (text: any, record: any) => {
      //     return text || 'N/A'
      //   },
      // },
      {
        title: 'TX Packets',
        dataIndex: 'txPackets',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return normalizeValue('number', {
            value: text || 0,
            formatType: '0.0b',
            unit: 'B',
          })
        },
      },
      {
        title: 'RX Packets',
        dataIndex: 'rxPackets',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return normalizeValue('number', {
            value: text || 0,
            formatType: '0.0b',
            unit: 'B',
          })
        },
      },
      {
        title: 'RX Bytes',
        dataIndex: 'rxBytes',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return normalizeValue('number', {
            value: text || 0,
            formatType: '0.00a',
          })
        },
      },
      {
        dataIndex: 'actions',
        width: 30,
        render: (text: any, record: any) => {
          return (
            !storage.get.IS_OPERATOR_USER && (
              <A10DropdownMenu
                title=""
                menu={[
                  <div key="edit" onClick={editVE.bind(this, record.ifnum)}>
                    Edit
                  </div>,
                  <div
                    key="delete"
                    onClick={clickDeleteVE.bind(this, record.ifnum)}
                  >
                    Delete
                  </div>,
                ]}
                style={{ color: '#757575', marginBottom: '-15px' }}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            )
          )
        },
      },
    ],
    [deleteVE],
  )

  return (
    <div className="col-md-12 marginBottom10">
      <A10Collapse>
        <A10Collapse.Panel
          key="interface-ve"
          header={
            <>
              {!storage.get.IS_OPERATOR_USER && (
                <span className="edit-content" onClick={clickAddNew}>
                  <A10Icon app="global" type="add-new" /> Add
                </span>
              )}
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 width180">
                  VE <RoundNumber number={(veList || []).length} />
                </span>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Up</div>
                  <div className="pad-s-15 fontWeight300">{status.up || 0}</div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Down</div>
                  <div className="pad-s-15 fontWeight300">
                    {status.down || 0}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Disabled</div>
                  <div className="pad-s-15 fontWeight300">
                    {status.disabled || 0}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">TX Packets</div>
                  <div className="pad-s-15 fontWeight300">
                    {normalizeValue('number', {
                      value: counter.txPackets || 0,
                      formatType: '0.0b',
                      unit: 'B',
                    })}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">RX Packets</div>
                  <div className="pad-s-15 fontWeight300">
                    {normalizeValue('number', {
                      value: counter.rxPackets || 0,
                      formatType: '0.0b',
                      unit: 'B',
                    })}
                  </div>
                </div>
              </A10Row>
            </>
          }
          className="collapsePanel"
        >
          <A10Table
            columns={columns}
            dataSource={datasource}
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
            bordered={false}
          />
        </A10Collapse.Panel>
      </A10Collapse>
    </div>
  )
}

export default InterfaceVE
