import React from 'react'

import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import styles from './styles/index.module.less'

interface IMgmtConfigProps {}

const MgmtConfig: React.FC<IMgmtConfigProps> = props => {
  return (
    <>
      <ContentHeader type="flex" align="middle" justify="space-between">
        <ContentTitle title="Base" />
      </ContentHeader>
      <ContentBody className={styles.contentBody}>Base</ContentBody>

      <ContentHeader type="flex" align="middle" justify="space-between">
        <ContentTitle title="Analytics" />
      </ContentHeader>
      <ContentBody className={styles.contentBody}>Analytics</ContentBody>

      <ContentHeader type="flex" align="middle" justify="space-between">
        <ContentTitle title="Ceph" />
      </ContentHeader>
      <ContentBody>Ceph</ContentBody>
    </>
  )
}

export default MgmtConfig
