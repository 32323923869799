import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Form,
  A10Input,
  A10Select,
  A10Button,
  A10Table,
  A10Col,
  A10Checkbox,
  A10Radio,
  A10Switch,
  A10Tooltip,
  A10Modal,
  A10Message,
  A10Alert,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { Messages } from 'src/locale/en/Messages'
import { InfrastructureService } from 'src/services/InfrastructureService/InfrastructureService'
import { IDefaultMethods } from 'src/containers/Controller'
import { DropdownConstants } from 'src/constants/DropdownConstants/DropdownConstants'
import { Configs } from 'src/constants/Configs'
import { DashboardService, Utilities } from 'src/services/index'
import storage from 'src/libraries/storage'
import { HelpInfo } from 'src/components/shared/HelpInfo'

import './styles/azuredevicecreateform.scss'

const RadioGroup = A10Radio.Group
export interface IAzureDeviceCreateFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  device?: any
  vmDevices: any
  form: any
  formData?: any
  createFormData: any
  onRef?: any
  handleChange?: (data: any) => void
}
export interface IAzureDeviceCreateFormState {
  device: any
  vmDevice: any
  locationList: any[]
  resourceGroupList: any[]
  vnetList: any[]
  azureImageList: any[]
  instanceTypeList: any[]
  azuresubnetList: any[]
  publicIPList: any[]
  networkSecurityGroupList: any[]
  vmWareNetworksList: any[]
  lists: any[]
  hcClusterSelect: string
  imageCategory: string
  nicCount: number
  instanceTypeModalState: boolean
  changedInstanceType: any
  marketplaceImageTermsAccepted: boolean
}

class AzureDeviceCreateForm extends A10Container<
  IAzureDeviceCreateFormProps,
  IAzureDeviceCreateFormState
> {
  Messages = new Messages()
  InfrastructureService = new InfrastructureService()
  DropdownConstants = new DropdownConstants()
  Configs = new Configs()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  lists: any[] = []

  mainNetworks = [
    {
      name: 'Management/eth0',
    },
    {
      name: 'Ethernet1/eth1',
    },
  ]

  constructor(props: IAzureDeviceCreateFormProps) {
    super(props)
    const vmDevice: any = props.createFormData
    const device: any = props.formData
    this.state = {
      device,
      vmDevice,
      locationList: [],
      resourceGroupList: [],
      vnetList: [],
      azureImageList: [],
      instanceTypeList: this.Configs.AzureInstanceTypeList,
      azuresubnetList: [],
      publicIPList: [],
      networkSecurityGroupList: [],
      vmWareNetworksList: [],
      lists: [[]],
      hcClusterSelect: '',
      imageCategory: 'privateImages',
      nicCount: 2,
      instanceTypeModalState: false,
      changedInstanceType: {},
      marketplaceImageTermsAccepted: true,
    }
    this.loadAzureLocationLists(JSON.parse(device.credential))
  }

  getChangedValue = (e: any) => {
    let changedValue = ''
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        changedValue = e.target.checked
      } else {
        changedValue = e.target.value
      }
    } else {
      changedValue = e
    }
    return changedValue
  }

  handleOk = (e: any) => {
    const vmDeviceObj = this.state.vmDevice
    vmDeviceObj['instanceType'] = this.state.changedInstanceType
    let nics = JSON.parse(vmDeviceObj['instanceType']).maxNics
    for (let i = nics; i < this.state.nicCount; i++) {
      this.mainNetworks.pop()
      vmDeviceObj.vmNetworks.pop()
    }
    this.setState({
      nicCount: vmDeviceObj.vmNetworks.length,
      vmDevice: vmDeviceObj,
      instanceTypeModalState: false,
    })
  }

  handleCancel = () => {
    const instance_type = JSON.parse(this.state.vmDevice.instanceType)
    this.props.form.setFieldsValue({
      [`instanceType`]:
        instance_type.displayName +
        ' (' +
        instance_type.maxNics +
        '  Network Interfaces)',
    })
    this.setState({
      instanceTypeModalState: false,
    })
  }

  handleChange = (stateName: string, e: any, index?: number) => {
    if (!this.Utilities.validateField(e)) {
      return
    }
    const changedValue = this.getChangedValue(e)
    const vmDeviceObj = this.state.vmDevice
    let {
      azureImageList,
      publicIPList,
      networkSecurityGroupList,
      azuresubnetList,
    } = this.state

    if (stateName === 'instanceType') {
      let nics = JSON.parse(changedValue).maxNics
      if (this.state.nicCount > nics) {
        this.setState({
          changedInstanceType: changedValue,
          instanceTypeModalState: true,
        })
        return
      }
    }

    if (index !== undefined) {
      if (stateName === 'autoAssign' && changedValue) {
        vmDeviceObj.vmNetworks[index]['ipAddr'] = ''
        this.props.form.setFieldsValue({ [`ipAddr[${index}]`]: '' })
      }
      if (stateName === 'autoAllocate' && changedValue) {
        vmDeviceObj.vmNetworks[index]['publicIP'] = ''
        this.props.form.setFieldsValue({ [`publicIP[${index}]`]: undefined })
      }
      vmDeviceObj.vmNetworks[index][stateName] = changedValue
    } else {
      vmDeviceObj[stateName] = changedValue
    }

    if (stateName === 'location' && this.state.vmDevice.location !== '') {
      vmDeviceObj.resourceGroup = ''
      vmDeviceObj.vnet = ''
      vmDeviceObj.imageName = ''
      vmDeviceObj.image = ''
      azureImageList = []
      publicIPList = []
      networkSecurityGroupList = []
      azuresubnetList = []
      this.props.form.setFieldsValue({
        [`resourceGroup`]: undefined,
        [`vnet`]: undefined,
        [`imageName`]: '',
      })
      vmDeviceObj.vmNetworks.map((vnic: any, index: number) => {
        if (vnic.subnet !== '') {
          vnic.subnet = ''
          this.props.form.setFieldsValue({ [`subnet[${index}]`]: undefined })
        }
        vnic.ipAddr = ''
        this.props.form.setFieldsValue({ [`ipAddr[${index}]`]: '' })
        if (vnic.publicIP !== '') {
          vnic.publicIP = ''
          this.props.form.setFieldsValue({ [`publicIP[${index}]`]: undefined })
        }
        if (vnic.securityGroup !== '') {
          vnic.securityGroup = ''
          this.props.form.setFieldsValue({
            [`securityGroup[${index}]`]: undefined,
          })
        }
      })
      this.loadAzureVnetLists(JSON.parse(vmDeviceObj['location']))
      this.loadAzureResourceGroupLists(JSON.parse(vmDeviceObj['location']))
      this.loadAzurePublicIPs(JSON.parse(vmDeviceObj['location']))
      this.loadAzureNetworkSecurityGroups(JSON.parse(vmDeviceObj['location']))
    }

    if (stateName === 'vnet' && vmDeviceObj.vnet !== '') {
      azuresubnetList = []
      vmDeviceObj.vmNetworks.map((vnic: any, index: number) => {
        if (vnic.subnet !== '') {
          vnic.subnet = ''
          this.props.form.setFieldsValue({ [`subnet[${index}]`]: undefined })
        }
        vnic.ipAddr = ''
        this.props.form.setFieldsValue({ [`ipAddr[${index}]`]: '' })
      })
      this.loadAzureSubnets(JSON.parse(vmDeviceObj['vnet']))
    }

    if (stateName === 'imageCategory') {
      azureImageList = []
      this.setState({ imageCategory: e.target.value })
    }

    this.props.handleChange(vmDeviceObj)
    console.log('Bhumika logs ----------------')
    console.log(vmDeviceObj)
    console.log('Bhumika logs ---------------- end')
    // @ts-ignore
    this.setState({
      vmDevice: vmDeviceObj,
      azureImageList,
      publicIPList,
      networkSecurityGroupList,
      azuresubnetList,
    })
  }

  addEth = () => {
    const nicName =
      'Ethernet' + this.state.nicCount + '/eth' + this.state.nicCount
    this.mainNetworks.push({ name: nicName })
    const vmDeviceObj = this.state.vmDevice
    vmDeviceObj.vmNetworks.push({
      name: 'eth' + this.state.nicCount,
      subnet: '',
      ipAddr: '',
      autoAssign: false,
      publicIP: '',
      autoAllocate: false,
      securityGroup: '',
    })
    this.props.handleChange(vmDeviceObj)
    // @ts-ignore
    this.setState({ vmDevice: vmDeviceObj, nicCount: this.state.nicCount + 1 })
  }

  removeEth = () => {
    this.mainNetworks.pop()
    const vmDeviceObj = this.state.vmDevice
    vmDeviceObj.vmNetworks.pop()
    this.props.handleChange(vmDeviceObj)
    // @ts-ignore
    this.setState({ vmDevice: vmDeviceObj, nicCount: this.state.nicCount - 1 })
  }

  getIPnumber = (ip_address: any) => {
    let ip = ip_address.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/)
    if (ip) {
      return (+ip[1] << 24) + (+ip[2] << 16) + (+ip[3] << 8) + +ip[4]
    }
  }

  validatePrivateIPs = () => {
    let req_payload = {}
    let ip_list = []
    let unavailableIPs: string[] = []
    const { showMessage } = this.Utilities

    for (let i = 0; i < this.state.vmDevice.vmNetworks.length; i++) {
      if (
        !this.state.vmDevice.vmNetworks[i].autoAssign &&
        this.state.vmDevice.vmNetworks[i].ipAddr
      ) {
        if (
          this.state.vmDevice.vnet == '' ||
          this.state.vmDevice.vmNetworks[i].subnet == ''
        ) {
          showMessage('Please select both VNET and Subnet', 0, 0)
          return
        } else {
          let subnet_cidr = JSON.parse(this.state.vmDevice.vmNetworks[i].subnet)
            .addressPrefix
          const cidrIp = subnet_cidr.split('/')[0]
          const cidrSm = subnet_cidr.split('/')[1]
          const private_ip = this.state.vmDevice.vmNetworks[i].ipAddr
          const ipmask = -1 << (32 - cidrSm)
          let isIPinSubnet =
            (this.getIPnumber(private_ip) & ipmask) == this.getIPnumber(cidrIp)
          if (!isIPinSubnet) {
            showMessage(
              'Private IP address ' +
                private_ip +
                ' does not belong to the range of subnet ' +
                subnet_cidr,
              0,
              0,
            )
            return
          } else {
            ip_list.push({
              'ip-address': this.state.vmDevice.vmNetworks[i].ipAddr,
              'network-id': JSON.parse(this.state.vmDevice.vnet).id,
              'network-name': JSON.parse(this.state.vmDevice.vnet).name,
            })
          }
        }
      }
    }

    if (ip_list.length < 1) {
      A10Message.info('No IP addresses provided or marked for validation.')
      return
    }

    req_payload['ip-addresses'] = ip_list
    const credential = JSON.parse(this.state.device.credential)
    const azurePrivateIPResponse = this.InfrastructureService.checkAzurePrivateIPAvailability(
      null,
      req_payload,
      [credential.uuid],
    )
    azurePrivateIPResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && Object.keys(list).length > 0) {
            for (var key in list) {
              if (!list[key]) {
                unavailableIPs.push(key)
              }
            }
          }
          if (ip_list.length > 0 && unavailableIPs.length == 0) {
            A10Message.success('All Private IP Addresses are available')
          } else if (unavailableIPs.length == 1) {
            let err_message =
              'Private IP Address [' +
              unavailableIPs[0] +
              '] is already in use.'
            showMessage(err_message, 0, 0, '')
          } else if (unavailableIPs.length > 1) {
            let unavailableIP_list = unavailableIPs[0]
            for (let i = 1; i < unavailableIPs.length; i++) {
              unavailableIP_list += ', ' + unavailableIPs[i]
            }
            let err_message =
              'Private IP Addresses [' +
              unavailableIP_list +
              '] are already in use.'
            showMessage(err_message, 0, 0, '')
          }
        }
      })
      .catch((error: any) => {
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to check private IP address availability'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  checkImageTerms = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (this.state.imageCategory !== 'marketImages') {
      return
    }
    const credential = JSON.parse(this.state.device.credential)
    const image = JSON.parse(e.target.value).name
    let req_payload = {
      'image-name': image.split(':')[0],
      'sku-name': image.split(':')[1],
    }
    const azureImageTermsResponse = this.InfrastructureService.checkAzureMarketplaceImageTerms(
      null,
      req_payload,
      [credential.uuid],
    )
    azureImageTermsResponse
      .then((response: any) => {
        let resp = response.data.load
        if (resp) {
          this.setState({
            marketplaceImageTermsAccepted: resp['terms-accepted'],
          })
        }
      })
      .catch((error: any) => {})
  }

  loadAzureLocationLists = (credential: any) => {
    const azureLocationsResponse = this.InfrastructureService.getAzureLocations(
      null,
      null,
      [credential.uuid],
    )
    azureLocationsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              locationList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get Azure location details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadAzureResourceGroupLists = (location: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const azureResourceGroupsResponse = this.InfrastructureService.getAzureResouceGroups(
      null,
      null,
      [credential.uuid, location.name],
    )
    azureResourceGroupsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              resourceGroupList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get azure resource group details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadAzureVnetLists = (location: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const azureVNETsResponse = this.InfrastructureService.getAzureVNETs(
      null,
      null,
      [credential.uuid, location.name],
    )
    azureVNETsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              vnetList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get azure virtual network details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  searchDeviceImage = (imageCategory: string) => {
    const credential = JSON.parse(this.state.device.credential)
    const location = JSON.parse(this.state.vmDevice.location)
    let azureImageResponse
    if (imageCategory == 'marketImages') {
      azureImageResponse = this.InfrastructureService.getAzureMarketplaceImage(
        null,
        null,
        [credential.uuid, location.name, this.state.vmDevice.imageName],
      )
    } else {
      azureImageResponse = this.InfrastructureService.getAzurePrivateImage(
        null,
        null,
        [credential.uuid, location.name, this.state.vmDevice.imageName],
      )
    }
    azureImageResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              azureImageList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get azure image details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadAzurePublicIPs = (location: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const azurePublicIPsResponse = this.InfrastructureService.getAzurePublicIP(
      null,
      null,
      [credential.uuid, location.name],
    )
    azurePublicIPsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              publicIPList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get azure public IP details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadAzureNetworkSecurityGroups = (location: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const azureSecurityGroupsResponse = this.InfrastructureService.getAzureNetworkSecurityGroup(
      null,
      null,
      [credential.uuid, location.name],
    )
    azureSecurityGroupsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              networkSecurityGroupList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get azure network security group details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadAzureSubnets = (vnet: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const azureSubnetsResponse = this.InfrastructureService.getAzureSubnet(
      null,
      null,
      [credential.uuid, vnet.id],
    )
    azureSubnetsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              azuresubnetList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get azure subnet details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  validateDuplicateVMName = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const index = this.props.vmDevices.findIndex((key: any) => {
      return key.name === value
    })
    const userRegx = new RegExp(/^([a-zA-Z0-9._-]){1,50}$/)

    if (!value || '' === value) {
      rule.message = this.Messages.VM_NAME_REQ
      isValid = false
    } else if (!userRegx.test(value)) {
      rule.message = this.Messages.INVALID_VM_NAME
      isValid = false
    } else if (index > -1) {
      rule.message = this.Messages.VM_NAME_DUP
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { vmDevice } = this.state
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <div className="margin-v-10">
          <A10Panel
            className="panelBodyPadding"
            title={
              <>
                <A10IconTextGroup
                  text={
                    <>
                      <span className="borderLeftData">Azure Environment</span>
                      {/* <span>
                        <HelpInfo
                          placement="topLeft"
                          title="Azure Environment"
                          helpKey="HELP_INFRA_VIRTUAL_AZURE_ENVIRONMENT"
                        />
                      </span> */}
                    </>
                  }
                  icon={
                    <A10Icon
                      className="destinationIcon"
                      app="gi-firewall"
                      type="destination"
                      style={{ fontSize: '24px' }}
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                    VM (Instance) Name
                  </span>
                  <span>
                    <HelpInfo
                      placement="topLeft"
                      title="VM (Instance) Name"
                      helpKey="HELP_INFRA_VIRTUAL_VM_NAME"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('vmName', {
                rules: [{ validator: this.validateDuplicateVMName.bind(this) }],
              })(
                <A10Input
                  type="text"
                  placeholder={'Enter VM Device name'}
                  onChange={(e: any) => this.handleChange('vmName', e)}
                />,
              )}
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                    Location
                  </span>
                  <span>
                    <HelpInfo
                      placement="topLeft"
                      title="Location"
                      helpKey="HELP_INFRA_VIRTUAL_AZURE_LOCATION"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('location', {
                rules: [
                  {
                    required: true,
                    message: 'Please select location',
                  },
                ],
                initialValue: vmDevice.location || undefined,
              })(
                <A10Select
                  placeholder={'Select a location'}
                  onChange={(e: any) => this.handleChange('location', e)}
                >
                  {this.state.locationList.map((obj: any, i: number) => {
                    return (
                      <A10Select.Option key={i} value={JSON.stringify(obj)}>
                        {obj.display_name}
                      </A10Select.Option>
                    )
                  })}
                </A10Select>,
              )}
            </A10Form.Item>

            {!!vmDevice.location ? (
              <>
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                        Virtual Network (VNET)
                      </span>
                      <span>
                        <HelpInfo
                          placement="topLeft"
                          title="Virtual Network (VNET)"
                          helpKey="HELP_INFRA_VIRTUAL_AZURE_VNET"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('vnet', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select Virtual Network (VNET)',
                      },
                    ],
                    initialValue: vmDevice.vnet || undefined,
                  })(
                    <A10Select
                      placeholder={'Select Virtual Network (VNET)'}
                      onChange={(e: any) => this.handleChange('vnet', e)}
                    >
                      {this.state.vnetList.map((obj: any, i: number) => {
                        return (
                          <A10Select.Option key={i} value={JSON.stringify(obj)}>
                            {obj.name}
                          </A10Select.Option>
                        )
                      })}
                    </A10Select>,
                  )}
                </A10Form.Item>
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                        Resource Group
                      </span>
                      <span>
                        <HelpInfo
                          placement="topLeft"
                          title="Resource Group"
                          helpKey="HELP_INFRA_VIRTUAL_AZURE_RESOURCE_GROUP"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('resourceGroup', {
                    rules: [
                      {
                        required: !vmDevice.autoCreate,
                        message: 'Please select Resource Group',
                      },
                    ],
                    initialValue: vmDevice.resourceGroup || undefined,
                  })(
                    <A10Select
                      placeholder={'Select Resource Group'}
                      onChange={(e: any) =>
                        this.handleChange('resourceGroup', e)
                      }
                      disabled={vmDevice.autoCreate}
                      className="azure-rg"
                    >
                      {this.state.resourceGroupList.map(
                        (obj: any, i: number) => {
                          return (
                            <A10Select.Option
                              key={i}
                              value={JSON.stringify(obj)}
                            >
                              {obj.name}
                            </A10Select.Option>
                          )
                        },
                      )}
                    </A10Select>,
                  )}
                  <>
                    <A10Switch
                      defaultChecked={false}
                      onChange={(e: any) => this.handleChange('autoCreate', e)}
                    />
                    <span> auto-create</span>
                  </>
                </A10Form.Item>

                <div className="azure-vm-template">
                  <span className="title">VM Templates</span>
                  {/* <span>
                    <HelpInfo
                      placement="topLeft"
                      title="VM Templates"
                      helpKey="HELP_INFRA_VIRTUAL_VM_TEMPLATES"
                    />
                  </span> */}
                </div>

                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                        Image Name
                      </span>
                      <span>
                        <HelpInfo
                          placement="topLeft"
                          title="Image Name"
                          helpKey="HELP_INFRA_VIRTUAL_AZURE_IMAGE"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('imageName')(
                    <div className="row">
                      <div className="col-md-8">
                        <RadioGroup
                          onChange={(e: any) =>
                            this.handleChange('imageCategory', e)
                          }
                          defaultValue={'privateImages'}
                        >
                          <A10Radio value="privateImages">
                            Private Images
                          </A10Radio>
                          <A10Radio value="marketImages">
                            Marketplace Images
                          </A10Radio>
                        </RadioGroup>
                        <A10Input
                          type="text"
                          placeholder={'Name of the machine image'}
                          onChange={(e: any) =>
                            this.handleChange('imageName', e)
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        <A10Button
                          className="action-button"
                          onClick={() => {
                            this.searchDeviceImage(this.state.imageCategory)
                          }}
                        >
                          Search
                        </A10Button>
                      </div>
                      {!!this.state.azureImageList &&
                      this.state.azureImageList.length === 0 ? (
                        <span className="col-md-12">{'No images found.'}</span>
                      ) : !!this.state.azureImageList &&
                        this.state.azureImageList.length > 0 &&
                        this.state.azureImageList.length <= 50 ? (
                        <span className="col-md-12">
                          {this.state.azureImageList.length +
                            ' image(s) found. Please select one.'}
                        </span>
                      ) : !!this.state.azureImageList &&
                        this.state.azureImageList.length > 50 ? (
                        <span className="col-md-12">
                          {
                            ' More than 50 images found. Please refine your search filter'
                          }
                        </span>
                      ) : null}
                    </div>,
                  )}
                </A10Form.Item>

                {!!this.state.azureImageList &&
                this.state.azureImageList.length > 0 ? (
                  <A10Form.Item {...formItemLayout} label="&nbsp;">
                    {getFieldDecorator('image')(
                      <div className="azure-image-div">
                        <RadioGroup
                          onChange={(e: any) => this.handleChange('image', e)}
                          value={vmDevice.image}
                        >
                          {this.state.azureImageList.map((azureImage: any) => {
                            return (
                              <A10Radio
                                key={azureImage.id}
                                value={JSON.stringify(azureImage)}
                                className="azure-image-list"
                                onChange={this.checkImageTerms.bind(this)}
                                //onChange={this.state.imageCategory === 'marketImages' && this.state.vmDevice.image ? this.checkImageTerms : null}
                              >
                                {azureImage.name}
                              </A10Radio>
                            )
                          })}
                        </RadioGroup>
                      </div>,
                    )}
                    {this.state.imageCategory === 'marketImages' &&
                    this.state.vmDevice.image &&
                    !this.state.marketplaceImageTermsAccepted ? (
                      <A10Alert
                        style={{ marginTop: '15px' }}
                        showIcon={true}
                        message="Warning"
                        description={
                          <div>
                            <>
                              Azure terms of use have not been accepted for the
                              selected marketplace image. If you proceed, the
                              Azure terms of use will be automatically accepted
                              on your behalf.
                              <br></br>
                              For Azure terms of use, please refer to your Azure
                              subscription documents.
                            </>
                          </div>
                        }
                        type="warning"
                      />
                    ) : null}
                  </A10Form.Item>
                ) : null}

                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                        Instance Type
                      </span>
                      <span>
                        <HelpInfo
                          placement="topLeft"
                          title="Instance Type"
                          helpKey="HELP_INFRA_VIRTUAL_AZURE_INSTANCE_TYPE"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('instanceType', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select instance type',
                      },
                    ],
                    initialValue: vmDevice.instanceType || undefined,
                  })(
                    <A10Select
                      placeholder={'Select instance type'}
                      onChange={(e: any) =>
                        this.handleChange('instanceType', e)
                      }
                    >
                      {this.state.instanceTypeList.map(
                        (obj: any, i: number) => {
                          return (
                            <A10Select.Option
                              key={i}
                              value={JSON.stringify(obj)}
                            >
                              {obj.displayName} ({obj.maxNics} Network
                              Interfaces)
                            </A10Select.Option>
                          )
                        },
                      )}
                    </A10Select>,
                  )}
                  <A10Modal
                    title="Change Instance Type"
                    visible={this.state.instanceTypeModalState}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    zIndex={9999}
                    footer={[
                      <A10Button
                        key="no"
                        type="primary"
                        onClick={this.handleCancel}
                        className="nobtn"
                        disabled={false}
                      >
                        No
                      </A10Button>,
                      <A10Button
                        key="yes"
                        type="default"
                        onClick={this.handleOk}
                        className="yesbtn"
                        disabled={false}
                      >
                        Yes
                      </A10Button>,
                    ]}
                  >
                    <p>
                      Some data for Network Interfaces will be lost! <br />
                      Are you sure?
                    </p>
                  </A10Modal>
                </A10Form.Item>
              </>
            ) : null}
          </A10Panel>

          {!!vmDevice.location ? (
            <A10Panel
              className="panelBodyPadding"
              title={
                <>
                  <A10IconTextGroup
                    text={
                      <>
                        <span className="borderLeftData">Deployment</span>
                        {/* <span>
                          <HelpInfo
                            placement="topLeft"
                            title="Deployment"
                            helpKey="HELP_INFRA_VIRTUAL_VM_DEPLOYMENT"
                          />
                        </span> */}
                      </>
                    }
                    icon={
                      <A10Icon
                        app="harmony-controller"
                        type="provider"
                        className="destinationIcon"
                      />
                    }
                  />
                </>
              }
            >
              <A10Button
                className="action-button pull-right"
                onClick={() => {
                  this.validatePrivateIPs()
                }}
              >
                Validate IP Addresses
              </A10Button>
              <div className="VMWareTemplate">
                {
                  <>
                    Network Mapping
                    {/* <span>
                      <HelpInfo
                        placement="topLeft"
                        title="Network Mapping"
                        helpKey="HELP_INFRA_VIRTUAL_AZURE_NETWORK_MAPPING"
                      />
                    </span> */}
                  </>
                }
              </div>
              <A10Table
                dataSource={this.mainNetworks}
                pagination={false}
                scroll={{ x: '100%' }}
              >
                <A10Col
                  title="Name"
                  dataIndex="name"
                  key="name"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortString(a, b, 'name')
                  }
                  render={(text: string, record: any, index: number) => {
                    return (
                      <div>
                        {index > 1 && index === this.state.nicCount - 1 ? (
                          <A10IconTextGroup
                            text={text}
                            icon={
                              <A10Icon
                                style={{ fontSize: 24 }}
                                app="global"
                                type="delete-another"
                                onClick={this.removeEth.bind(this)}
                              />
                            }
                          />
                        ) : (
                          text
                        )}
                      </div>
                    )
                  }}
                />
                <A10Col
                  title="Subnet"
                  key="subnet"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortString(a, b, 'subnet')
                  }
                  render={(text: string, record: any, index: number) => {
                    return (
                      <A10Form.Item className="no-margin">
                        {getFieldDecorator(`subnet[${index}]`, {
                          rules: [
                            {
                              required: true,
                              message: 'Select subnet',
                            },
                          ],
                        })(
                          <A10Select
                            placeholder={'Please select'}
                            className="azure-network-dropdown"
                            dropdownClassName="azure-network-dropdown"
                            allowClear
                            size="small"
                            style={{ top: '-10px' }}
                            onChange={(e: any) =>
                              this.handleChange('subnet', e, index)
                            }
                          >
                            {this.state.azuresubnetList.map(
                              (obj: any, i: number) => {
                                return (
                                  <A10Select.Option
                                    key={i}
                                    value={JSON.stringify(obj)}
                                  >
                                    <A10Tooltip
                                      placement="top"
                                      title={
                                        'Available address count - ' +
                                        obj['available_ip_address_count']
                                      }
                                    >
                                      <span>{obj['addressPrefix']}</span>
                                    </A10Tooltip>
                                  </A10Select.Option>
                                )
                              },
                            )}
                          </A10Select>,
                        )}
                      </A10Form.Item>
                    )
                  }}
                />
                <A10Col
                  title="IP Address"
                  key="ipAddr"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortNumber(a, b, 'ipAddr')
                  }
                  render={(text: string, record: any, index: number) => {
                    return (
                      <>
                        <A10Form.Item className="no-margin">
                          <>
                            {getFieldDecorator(`ipAddr[${index}]`, {
                              validateTrigger: 'onBlur',
                              rules: [
                                {
                                  validator: vmDevice.vmNetworks[index]
                                    .autoAssign
                                    ? null
                                    : this.Utilities.validateVMDeviceIPs.bind(
                                        this,
                                        'not-mandatory',
                                      ),
                                },
                              ],
                            })(
                              <A10Input
                                type="text"
                                placeholder={'IP Address'}
                                size="small"
                                style={{ top: '-3px' }}
                                onChange={(e: any) =>
                                  this.handleChange('ipAddr', e, index)
                                }
                                disabled={vmDevice.vmNetworks[index].autoAssign}
                              />,
                            )}
                          </>
                        </A10Form.Item>
                        <div className="col-md-12 no-padding">
                          <A10Checkbox
                            onChange={(e: any) =>
                              this.handleChange('autoAssign', e, index)
                            }
                            style={{ fontSize: '12px' }}
                          >
                            Auto-assign
                          </A10Checkbox>
                        </div>
                      </>
                    )
                  }}
                />
                <A10Col
                  title="Public IP"
                  key="publicIP"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortNumber(a, b, 'publicIP')
                  }
                  render={(text: string, record: any, index: number) => {
                    return (
                      <>
                        <A10Form.Item className="no-margin">
                          {getFieldDecorator(
                            `publicIP[${index}]`,
                            {},
                          )(
                            <A10Select
                              placeholder={'Please select'}
                              className="azure-network-dropdown"
                              dropdownClassName="azure-network-dropdown"
                              size="small"
                              allowClear
                              onChange={(e: any) =>
                                this.handleChange('publicIP', e, index)
                              }
                              disabled={vmDevice.vmNetworks[index].autoAllocate}
                            >
                              {this.state.publicIPList.map(
                                (obj: any, i: number) => {
                                  return (
                                    <A10Select.Option
                                      key={i}
                                      value={JSON.stringify(obj)}
                                    >
                                      <A10Tooltip
                                        placement="top"
                                        title={
                                          obj['ip_address']
                                            ? obj['ip_address']
                                            : obj['name']
                                        }
                                      >
                                        <span>
                                          {obj['ip_address']
                                            ? obj['ip_address']
                                            : obj['name']}
                                        </span>
                                      </A10Tooltip>
                                    </A10Select.Option>
                                  )
                                },
                              )}
                            </A10Select>,
                          )}
                        </A10Form.Item>
                        <div className="col-md-12 no-padding">
                          <A10Checkbox
                            onChange={(e: any) =>
                              this.handleChange('autoAllocate', e, index)
                            }
                            style={{ fontSize: '12px' }}
                          >
                            Auto-allocate
                          </A10Checkbox>
                        </div>
                      </>
                    )
                  }}
                />
                <A10Col
                  title="Security Group"
                  key="securityGroup"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortNumber(a, b, 'securityGroup')
                  }
                  render={(text: string, record: any, index: number) => {
                    return (
                      <A10Form.Item className="no-margin">
                        {getFieldDecorator(`securityGroup[${index}]`, {
                          rules: [
                            {
                              required: true,
                              message: 'Select Security Group',
                            },
                          ],
                        })(
                          <A10Select
                            placeholder={'Please select'}
                            className="azure-network-dropdown"
                            dropdownClassName="azure-network-dropdown"
                            size="small"
                            style={{ top: '-10px' }}
                            onChange={(e: any) =>
                              this.handleChange('securityGroup', e, index)
                            }
                          >
                            {this.state.networkSecurityGroupList.map(
                              (obj: any, i: number) => {
                                return (
                                  <A10Select.Option
                                    key={i}
                                    value={JSON.stringify(obj)}
                                  >
                                    <A10Tooltip
                                      placement="top"
                                      title={obj['name']}
                                    >
                                      <span>{obj['name']}</span>
                                    </A10Tooltip>
                                  </A10Select.Option>
                                )
                              },
                            )}
                          </A10Select>,
                        )}
                      </A10Form.Item>
                    )
                  }}
                />
              </A10Table>
              {this.state.vmDevice.instanceType &&
              this.state.nicCount <
                JSON.parse(this.state.vmDevice.instanceType).maxNics ? (
                <div className="row margin-v-10">
                  <div className="col-sm-12">
                    <A10IconTextGroup
                      text={
                        <div className="addEth2Title">
                          Ethernet{this.state.nicCount}/eth{this.state.nicCount}
                        </div>
                      }
                      icon={
                        <A10Icon
                          style={{ fontSize: 24 }}
                          app="global"
                          type="add-another"
                          onClick={this.addEth.bind(this)}
                        />
                      }
                    />
                  </div>
                </div>
              ) : null}
            </A10Panel>
          ) : null}
        </div>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(AzureDeviceCreateForm))
