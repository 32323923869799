import React from 'react'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10DropdownMenu,
  A10Tooltip,
  A10Col,
  A10Table,
  A10Button,
  A10Icon,
  A10Modal,
  A10Form,
} from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import { Utilities, TroubleShootService } from 'src/services'
import { Messages } from 'src/locale/en/Messages'
import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { UpgradeModal } from 'src/components/shared/UpgradeModal'
import { ClusterDevicePartition } from '../ClusterDevicePartition'
import { SnippetForm } from '../SnippetForm'
import SlidingWorkflowStatusList from 'src/components/shared/WorkflowStatus/slidingWorkflowListForm'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import ActivateLicense from 'src/components/shared/ActivateLicense'

const styles = require('./styles/index.module.less')

export interface ICliSnippetsProps extends IA10ContainerDefaultProps {
  maxSnippets: number
  remainingSnippets: number
  getRemainingSnippets: () => void
  activeLicense: string
  devicesDetail: IObject[]
  tenantDetail: IObject
}
export interface ICliSnippetsState {
  cmdSnippets: any[]
  runCmdScheduleTasks: any[]
  tableLoaded: boolean
  enableSave: boolean
  deleteModalState: boolean
  editMode: boolean
  selectedCliCmd: any
  showRunSlidingPage: boolean
  showSlidingPage: boolean
  devicePartitions: any[]
  workflowIDList: string[]
  workflowObjectList: string[]
  workflowSliderState: boolean
  showUpgrade: boolean
  showAddLicense: boolean
}

class CliSnippets extends A10Container<ICliSnippetsProps, ICliSnippetsState> {
  Messages = new Messages()
  Utilities = new Utilities()
  TroubleShootService = new TroubleShootService()
  provider = storage.get.PROVIDER
  headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    provider: storage.get.PROVIDER,
    Authorization: storage.get.ENCODED_SESSION_ID,
    user: storage.get.USER_ID,
  }
  childForm: any = null

  constructor(props: any) {
    super(props)
    this.state = {
      cmdSnippets: [],
      runCmdScheduleTasks: [],
      tableLoaded: false,
      enableSave: false,
      deleteModalState: false,
      editMode: false,
      selectedCliCmd: {},
      showRunSlidingPage: false,
      showSlidingPage: false,
      devicePartitions: [],
      workflowIDList: [],
      workflowObjectList: [],
      workflowSliderState: false,
      showUpgrade: false,
      showAddLicense: false,
    }

    this.getCliSnippets()
    this.getScheduledTasks()
  }

  getCommands = (content: any) => {
    try {
      content = JSON.parse(content).join(', ')
    } catch {
      try {
        content = content.split(',').join(', ')
      } catch {
        content = content
      }
    }
    return content
  }
  cliSnippetsColumns = [
    {
      title: 'Snippet Name',
      dataIndex: 'name',
      key: 'name',
      className: 'td-truncate',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'name'),
      render: (name: string, record: any) => {
        return (
          <A10Tooltip placement="right" title={record.name || ''}>
            {record.name || ''}
          </A10Tooltip>
        )
      },
    },
    {
      title: 'Command',
      dataIndex: 'content',
      key: 'content',
      className: 'td-truncate',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'content'),
      render: (content: string, record: any) => {
        return (
          <A10Tooltip placement="right" title={record.content || ''}>
            {this.getCommands(record.content) || ''}
          </A10Tooltip>
        )
      },
    },
    {
      title: 'User',
      dataIndex: 'created_by',
      key: 'created_by',
      className: 'td-truncate',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'created_by'),
    },
    {
      title: 'Last Updated',
      dataIndex: 'last_modified_at',
      key: 'last_modified_at',
      className: 'td-truncate',
      sorter: (a: any, b: any) =>
        this.Utilities.sortString(a, b, 'last_modified_at'),
      render: (lastModified: string, record: any) => {
        return this.TroubleShootService.convertDateTime(
          this.TroubleShootService.convertToLocalDateTime(lastModified),
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (snippet: any) => {
        const clickAction = (component: JSX.Element, index: number) => {
          if (component.key === 'run') {
            this.runSnippet(snippet)
          } else if (component.key === 'edit') {
            this.editSnippet(snippet)
          } else if (component.key === 'delete') {
            this.deleteSnippetModal(snippet)
          }
        }
        return (
          <div className="text-right">
            <i>
              <A10DropdownMenu
                menu={[
                  <div key="run">Run </div>,
                  <div key="edit">Edit </div>,
                  <div key="delete">Delete </div>,
                ]}
                title=""
                style={{ color: '#757575', marginBottom: '-15px' }}
                onClick={clickAction}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            </i>
          </div>
        )
      },
    },
  ]

  getCliSnippets = () => {
    this.setState({ tableLoaded: false })
    const cliSnippets = this.TroubleShootService.listCliSnippets(
      this.headers,
      null,
      [this.provider],
    )
    cliSnippets
      .then((response: any) => {
        const cmdSnippets =
          response?.data && Array.isArray(response.data) ? response.data : []

        this.setState({
          cmdSnippets,
          tableLoaded: true,
        })
      })
      .catch((error: any) => {
        this.setState({
          cmdSnippets: [],
          tableLoaded: true,
        })
      })
  }

  getScheduledTasks = () => {
    const scheduleTasks = this.TroubleShootService.listScheduleTasks(
      this.headers,
      null,
      [this.provider],
    )
    scheduleTasks
      .then((response: any) => {
        const runCmdScheduleTasks =
          response?.data && Array.isArray(response.data) ? response.data : []
        this.setState({ runCmdScheduleTasks })
      })
      .catch((error: any) => {
        this.setState({ runCmdScheduleTasks: [] })
      })
  }

  handleCancel = () => {
    this.setState({
      deleteModalState: false,
      enableSave: false,
      selectedCliCmd: {},
      showRunSlidingPage: false,
      showSlidingPage: false,
      workflowSliderState: false,
      workflowIDList: [],
      workflowObjectList: [],
    })
  }

  deleteSnippet = () => {
    const { showMessage } = this.Utilities
    this.setState({ enableSave: false })
    const saveCliSnippet = this.TroubleShootService.deleteCliSnippet(
      this.headers,
      null,
      [this.provider, this.state.selectedCliCmd.id],
    )
    saveCliSnippet
      .then((response: any) => {
        showMessage(`Deleted command snippet successfully`, 1, 0)
        this.handleCancel()
        this.getCliSnippets()
        this.props.getRemainingSnippets()
      })
      .catch((error: any) => {
        showMessage(`Error in delete of command snippet`, 0, 0)
      })
  }

  deleteSnippetModal = (cmdSnippet: any) => {
    const { runCmdScheduleTasks } = this.state
    const cmdToDelete = cmdSnippet
    const { showMessage } = this.Utilities
    const scheduledTask = runCmdScheduleTasks.find((runCmdTasks: any) => {
      return runCmdTasks.snippet_id === cmdToDelete.id
    })

    if (!scheduledTask) {
      this.setState({
        deleteModalState: true,
        enableSave: true,
        selectedCliCmd: cmdToDelete,
      })
    } else {
      showMessage(
        `Cannot delete '${cmdToDelete.name}', task has been scheduled with this cli snippet`,
        0,
        0,
      )
      return
    }
  }

  newSnippet = () => {
    const { maxSnippets, remainingSnippets, activeLicense } = this.props
    if (activeLicense !== 'No Active License') {
      if (maxSnippets === -1 || remainingSnippets > 0) {
        const selectedCliCmd = {
          id: '',
          name: '',
          content: '',
          contentSplit: '',
        }
        this.setState({
          showSlidingPage: true,
          editMode: false,
          workflowSliderState: false,
          workflowIDList: [],
          workflowObjectList: [],
          selectedCliCmd,
        })
      } else {
        this.setState({ showUpgrade: true })
      }
    } else {
      this.setState({ showAddLicense: true })
    }
  }

  editSnippet = (cmdSnippet: any) => {
    const contentJSON = JSON.parse(cmdSnippet?.content || '[]')
    const selectedCliCmd = {
      id: cmdSnippet.id,
      name: cmdSnippet.name,
      content: contentJSON,
      contentSplit: '',
    }
    const contentSplit: string[] = []
    contentJSON.map((cmd: string) => {
      contentSplit.push(cmd.trim())
    })
    selectedCliCmd.contentSplit = contentSplit.join('\n')

    this.setState({
      showSlidingPage: true,
      selectedCliCmd,
      enableSave: true,
      editMode: true,
      workflowSliderState: false,
      workflowIDList: [],
      workflowObjectList: [],
    })
  }

  runSnippet = (cmdSnippet: any) => {
    this.setState({
      selectedCliCmd: cmdSnippet,
      showRunSlidingPage: true,
      showSlidingPage: false,
      enableSave: false,
      workflowSliderState: false,
      workflowIDList: [],
    })
  }

  onViewWorkflowStatus = (data: IObject, devicePartitions: any[]) => {
    const details = data?.details,
      workflowIDList: string[] = [],
      objectList: string[] = []
    details &&
      devicePartitions.map((devicePartition: any) => {
        workflowIDList.push(
          details[devicePartition.name][devicePartition.partition],
        )
        objectList.push(
          devicePartition.name + ' : ' + devicePartition.partition,
        )
      })
    const devices = Object.keys(details)
    if (devices && devices.length > 0) {
      this.setState({
        workflowIDList,
        workflowObjectList: objectList,
        workflowSliderState: true,
      })
    }
  }

  handleRunCliSnippet = () => {
    const { showMessage } = this.Utilities
    const { selectedCliCmd, devicePartitions } = this.state

    if (devicePartitions && devicePartitions.length > 0) {
      const payload = {
        commands_source: selectedCliCmd.id,
        devices: [],
        client: 'runtime',
      }
      devicePartitions.map((devicePartition: any) => {
        payload.devices.push({
          name: devicePartition.name,
          partition: devicePartition.partition,
        })
      })

      this.setState({ enableSave: false })

      const runCliSnippet = this.TroubleShootService.runCliSnippet(
        this.headers,
        payload,
        [this.provider],
      )
      runCliSnippet
        .then((response: any) => {
          showMessage('Submitted command snippet successfully to execute', 1, 0)
          this.handleCancel()
          this.onViewWorkflowStatus(response.data, devicePartitions)
        })
        .catch((error: any) => {
          const msg = error?.response?.data?.message
            ? error.response.data.message
            : ''
          showMessage(
            'Error in submission of command snippet to execute',
            0,
            0,
            msg,
          )
          this.setState({ enableSave: true })
        })
    } else {
      showMessage(
        'Please select the device partition to submit the command snippet to run',
        0,
        0,
      )
    }
  }

  devicePartitionChange = (devicePartitions: any[]) => {
    this.setState({
      devicePartitions,
      enableSave: devicePartitions.length > 0,
    })
  }

  getComaSeparatedCmds = (cmdContent: string) => {
    const cmdContentArray = cmdContent.split('\n')
    let cmds: any = []
    cmdContentArray.map((cmd: string) => {
      if (cmd.trim() !== '') {
        cmds.push(cmd)
      }
    })
    return JSON.stringify(cmds)
  }

  handleChange = (fieldName: string, e: Event | any) => {
    const { selectedCliCmd } = this.state
    if (e.target) {
      selectedCliCmd[fieldName] = e.target.value
    } else {
      selectedCliCmd[fieldName] = e
    }
    let enableSave = true

    if (
      !selectedCliCmd.name ||
      !this.getComaSeparatedCmds(selectedCliCmd.contentSplit)
    ) {
      enableSave = false
    }

    this.setState({ selectedCliCmd, enableSave })
  }

  handleFormValidation = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  handleSaveCliSnippet = () => {
    const { selectedCliCmd } = this.state
    const { showMessage } = this.Utilities
    if (!selectedCliCmd.name.trim()) {
      showMessage('Please enter the command snippet name', 0, 0)
      return
    }
    if (!selectedCliCmd.contentSplit.trim()) {
      showMessage('Please enter the command snippets', 0, 0)
      return
    }

    const cmdContent = this.getComaSeparatedCmds(selectedCliCmd.contentSplit)
    const payload = {
      name: selectedCliCmd.name,
      content: cmdContent,
    }

    let saveCliSnippet = null

    if (this.state.editMode) {
      payload['id'] = selectedCliCmd.id
      saveCliSnippet = this.TroubleShootService.updateCliSnippet(
        this.headers,
        payload,
        [this.provider],
      )
    } else {
      saveCliSnippet = this.TroubleShootService.saveCliSnippet(
        this.headers,
        payload,
        [this.provider],
      )
    }

    saveCliSnippet
      .then((response: any) => {
        showMessage(
          `${
            this.state.editMode ? 'Updated ' : 'Saved '
          } command snippet successfully`,
          1,
          0,
        )
        this.getCliSnippets()
        this.handleCancel()
        this.props.getRemainingSnippets()
      })
      .catch((error: any) => {
        showMessage(
          `Error in ${
            this.state.editMode ? 'update ' : 'save '
          } of command snippet`,
          0,
          0,
        )
      })
  }

  render() {
    const {
      cmdSnippets,
      tableLoaded,
      deleteModalState,
      selectedCliCmd,
      enableSave,
      showSlidingPage,
      showRunSlidingPage,
      editMode,
      showUpgrade,
      showAddLicense,
    } = this.state
    const {
      maxSnippets,
      remainingSnippets,
      devicesDetail,
      tenantDetail,
    } = this.props

    return (
      <>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col className={styles.titleContainer}>
            <ContentTitle
              title="CLI Snippets"
              count={maxSnippets === -1 ? cmdSnippets.length : undefined}
              remaining={`${remainingSnippets}`}
              total={maxSnippets}
            />
          </A10Col>
          <ActionButton
            text="Create"
            onClick={this.newSnippet}
            iconProps={{ app: 'global', type: 'add-new' }}
          />
        </ContentHeader>
        <ContentBody>
          <A10Table
            columns={this.cliSnippetsColumns}
            dataSource={cmdSnippets.map((key: any, index: number) => {
              key.key = index
              return key
            })}
            size="small"
            loading={
              !tableLoaded
                ? {
                    indicator: (
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    ),
                  }
                : false
            }
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
          />
        </ContentBody>

        <FormatSlidingPage
          isOpen={showSlidingPage}
          onRequestOk={this.handleFormValidation}
          onRequestClose={this.handleCancel}
          title={editMode ? 'Edit Snippet' : 'New Snippet'}
          disableSave={!enableSave}
        >
          <SnippetForm
            cmdSnippets={cmdSnippets}
            selectedCliCmd={selectedCliCmd}
            editMode={editMode}
            handleChange={this.handleChange}
            onSubmitForm={this.handleSaveCliSnippet}
            onRef={(ref: any): any => (this.childForm = ref)}
          />
        </FormatSlidingPage>

        <A10Modal
          title={'Delete Command Snippet'}
          visible={deleteModalState}
          onOk={this.deleteSnippet}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={!enableSave}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.deleteSnippet}
              className="yesbtn"
              disabled={!enableSave}
            >
              Yes
            </A10Button>,
          ]}
        >
          {`Do you want to delete '${selectedCliCmd.name}' `}
        </A10Modal>

        <FormatSlidingPage
          isOpen={showRunSlidingPage}
          onRequestOk={this.handleRunCliSnippet}
          onRequestClose={this.handleCancel}
          title="Run Command"
          description="Select Device & Partition to run"
          disableSave={!enableSave}
          saveText="Run"
        >
          <div>
            <A10Form layout="horizontal">
              <A10Panel
                className={styles.panelBodyPad}
                title={
                  <A10IconTextGroup
                    text={<>Clusters, Devices & Partitions</>}
                    icon={
                      <A10Icon
                        style={{ width: 22, height: 22, marginRight: 12 }}
                        app="global"
                        type="form-section"
                        className="sliding-panel-icon"
                      />
                    }
                  />
                }
              >
                <ClusterDevicePartition
                  onChange={this.devicePartitionChange}
                  devicesDetail={devicesDetail}
                  tenantDetail={tenantDetail}
                />
              </A10Panel>
            </A10Form>
          </div>
        </FormatSlidingPage>

        <SlidingWorkflowStatusList
          isOpen={
            this.state.workflowSliderState &&
            this.state.workflowIDList.length > 0
          }
          title={'Device Command Troubleshoot Workflow Status'}
          objectList={this.state.workflowObjectList}
          onRequestClose={this.handleCancel}
          idList={this.state.workflowIDList}
        />

        <UpgradeModal
          content={`You are using a license which only allows ${maxSnippets} CLI Snippets. For questions or upgrading your license please contact A10 Networks Customer Support or Sales.`}
          visible={showUpgrade}
          onClose={() => {
            this.setState({ showUpgrade: false })
          }}
        />
        <ActivateLicense
          content={'Please add a valid license to your account'}
          visible={showAddLicense}
          onClose={() => {
            this.setState({ showAddLicense: false })
          }}
        />
      </>
    )
  }
}

export default setupA10Container(CliSnippets)
