import React from 'react'
import ReactLoading from 'react-loading'
import moment from 'moment-timezone'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Alert } from '@gui-libraries/widgets'

import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import { DeviceCmdRunForm } from '../DeviceCmdRunForm'
import { Messages } from 'src/locale/en'
import { Utilities, TroubleShootService } from 'src/services'
import storage from 'src/libraries/storage'

import './styles/index.module.less'

export interface ISlidingDeviceCmdFormsProps extends IA10ContainerDefaultProps {
  showSlidingPage: boolean
  cmdSnippets: any[]
  runCmdScheduleTasks: any[]
  runCmdSchedule: any
  editTask: boolean
  isPeriodicAllowed: boolean
  updateState(key: string, value: any): void
  onViewWorkflowStatus(data: any): void
  refreshScheduleTasks(): void
  devicesDetail: IObject[]
  tenantDetail: IObject
}
export interface ISlidingDeviceCmdFormsState {
  runCmdSchedule: any
  showSlidingPage: boolean
  processingSave: boolean
}

class SlidingDeviceCmdForms extends A10Container<
  ISlidingDeviceCmdFormsProps,
  ISlidingDeviceCmdFormsState
> {
  static getDerivedStateFromProps(
    nextProps: ISlidingDeviceCmdFormsProps,
    prevState: ISlidingDeviceCmdFormsState,
  ) {
    let showSlidingPage = prevState.showSlidingPage,
      runCmdSchedule = prevState.runCmdSchedule

    if (prevState.showSlidingPage != nextProps.showSlidingPage) {
      showSlidingPage = nextProps.showSlidingPage
    }
    if (runCmdSchedule != nextProps.runCmdSchedule) {
      runCmdSchedule = nextProps.runCmdSchedule
    }

    return {
      runCmdSchedule,
      showSlidingPage,
      processingSave: showSlidingPage ? false : prevState.processingSave,
    }
  }
  Messages = new Messages()
  Utilities = new Utilities()
  TroubleShootService = new TroubleShootService()
  childForm: any = null
  provider = storage.get.PROVIDER
  headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    provider: storage.get.PROVIDER,
    Authorization: storage.get.ENCODED_SESSION_ID,
    user: storage.get.USER_ID,
  }

  constructor(props: ISlidingDeviceCmdFormsProps) {
    super(props)
    this.state = {
      runCmdSchedule: this.props.runCmdSchedule,
      showSlidingPage: false,
      processingSave: false,
    }
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({
      showSlidingPage: isOpen,
    })
    this.props.updateState('showSlidingPage', isOpen)
  }

  handleChange = (stateName: string, e: any) => {
    const runCmdSchedule = this.state.runCmdSchedule
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        runCmdSchedule[stateName] = e.target.checked
      } else {
        runCmdSchedule[stateName] = e.target.value
      }
    } else {
      runCmdSchedule[stateName] = e
    }

    if (stateName === 'when' && runCmdSchedule[stateName] === 'schedule') {
      runCmdSchedule.startsOn = ''
    }

    // @ts-ignore
    this.setState({ runCmdSchedule })
  }

  handleFormValidation = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }

  callRuntimeCmd = () => {
    const { showMessage } = this.Utilities
    const { devicePartitions, cliSnippet } = this.state.runCmdSchedule
    const cmdSnippet = JSON.parse(cliSnippet)

    const payload = {
      commands_source: cmdSnippet.id,
      devices: [],
      client: 'runtime',
    }
    devicePartitions.map((devicePartition: any) => {
      payload.devices.push({
        name: devicePartition.name,
        partition: devicePartition.partition,
      })
    })
    this.setState({ processingSave: true })
    const runCliSnippet = this.TroubleShootService.runCliSnippet(
      this.headers,
      payload,
      [this.provider],
    )
    runCliSnippet
      .then((response: any) => {
        showMessage('Executed runtime command successfully', 1, 0)
        this.setSlidingPage(false)

        this.props.onViewWorkflowStatus(response.data)
      })
      .catch((error: any) => {
        const msg = error?.response?.data?.message
          ? error.response.data.message
          : ''
        showMessage('Error in execution of runtime command', 0, 0, msg)
        this.setState({ processingSave: false })
      })
  }

  scheduleCmdRunTask = () => {
    const { showMessage } = this.Utilities
    const {
      devicePartitions,
      cliSnippet,
      frequency,
      startsOn,
      timezone,
      name,
      id,
    } = this.state.runCmdSchedule
    const cmdSnippet = JSON.parse(cliSnippet)

    if (!startsOn) {
      showMessage('Please select the starts on date', 1, 0)
      return
    }
    const timezoneObj = JSON.parse(timezone)
    moment.tz.setDefault(timezoneObj.value)
    const now = moment()
    const dateObj = moment(startsOn)
    if (moment(now).isAfter(dateObj, 'minute')) {
      showMessage(
        'Starts on date & time cannot be before current date & time',
        0,
        0,
      )
      return
    }
    const utcStartDate = startsOn.utc()
    const year = utcStartDate.get('year')
    const month = utcStartDate.get('month')
    const day = utcStartDate.get('date')
    const hour = utcStartDate.get('hour')
    const minute = utcStartDate.get('minute')
    const second = utcStartDate.get('second')
    const scheduleTime = moment()
      .utc()
      .year(year)
      .month(month)
      .date(day)
      .hour(hour)
      .minute(minute)
      .second(second)
    const dayOfWeek = scheduleTime.day() === 0 ? 6 : scheduleTime.day() - 1
    const timeString = scheduleTime.toISOString()
    const payload = {
      name,
      type: 'CliSnippet',
      input_parameters: {
        commands_source: cmdSnippet.id,
        devices: [],
        scheduler: {
          frequency,
          startsOn: timeString,
          timezone,
        },
      },
      snippet_id: cmdSnippet.id,
      schedule: {
        'is-repeating': true,
        minute: scheduleTime.minute(),
        hour: frequency === 'hourly' ? undefined : scheduleTime.hour(),
        'day-of-week': frequency === 'weekly' ? dayOfWeek : undefined,
        day: frequency === 'monthly' ? scheduleTime.date() : undefined,
      },
    }

    if (this.props.editTask) {
      payload.id = id
    }

    devicePartitions.map((devicePartition: any) => {
      payload.input_parameters.devices.push({
        name: devicePartition.name,
        partition: devicePartition.partition,
      })
    })

    this.setState({ processingSave: true })
    let scheduleRunTask
    if (this.props.editTask) {
      scheduleRunTask = this.TroubleShootService.updateScheduleTasks(
        this.headers,
        payload,
        [this.provider],
      )
    } else {
      scheduleRunTask = this.TroubleShootService.createScheduleTasks(
        this.headers,
        payload,
        [this.provider],
      )
    }
    scheduleRunTask
      .then((response: any) => {
        showMessage(
          `${
            this.props.editTask ? 'Updated' : 'Created'
          } schedule command task successfully`,
          1,
          0,
        )
        this.setSlidingPage(false)

        this.props.refreshScheduleTasks()
      })
      .catch((error: any) => {
        const msg = error?.response?.data?.message
          ? error.response.data.message
          : ''
        showMessage(
          `Error in ${
            this.props.editTask ? 'update' : 'create'
          } of scheduling command task`,
          0,
          0,
          msg,
        )
        this.setState({ processingSave: false })
      })
  }

  handleRun = () => {
    const { showMessage } = this.Utilities
    const { runCmdSchedule } = this.state
    const { isPeriodicAllowed } = this.props
    if (runCmdSchedule.when === 'schedule' && !isPeriodicAllowed) {
      showMessage(
        'You are using a license which does not allow Scheduling Task. For questions or upgrading your license please contact A10 Networks Customer Support or Sales.',
        0,
        0,
      )
      return
    }
    if (
      !runCmdSchedule.devicePartitions ||
      runCmdSchedule.devicePartitions.length === 0
    ) {
      showMessage('Please select the device partition', 0, 0)
      return
    }
    if (!runCmdSchedule.cliSnippet || runCmdSchedule.cliSnippet === '') {
      showMessage('Please select the command snippet', 0, 0)
      return
    }
    if (
      runCmdSchedule.when === 'schedule' &&
      (!runCmdSchedule.startsOn || runCmdSchedule.startsOn === '')
    ) {
      showMessage('Please select the starts on date', 0, 0)
      return
    }
    if (runCmdSchedule.when === 'schedule' && !runCmdSchedule.timezone) {
      showMessage('Please select the timezone', 0, 0)
      return
    }
    if (runCmdSchedule.when === 'now') {
      this.callRuntimeCmd()
    } else {
      this.scheduleCmdRunTask()
    }
  }

  render() {
    return (
      <FormatSlidingPage
        isOpen={this.props.showSlidingPage}
        onRequestOk={this.handleFormValidation}
        onRequestClose={this.setSlidingPage.bind(this, false)}
        title={`${
          this.props.editTask ? 'Edit' : 'Create'
        } Schedule Command Task`}
        description=""
        saveText={
          this.state.runCmdSchedule.when === 'now' ? 'Run Now' : 'Schedule'
        }
        disableSave={this.state.processingSave}
      >
        <>
          <DeviceCmdRunForm
            runCmdScheduleTasks={this.props.runCmdScheduleTasks}
            runCmdSchedule={this.state.runCmdSchedule}
            editTask={this.props.editTask ? this.props.runCmdSchedule.name : ''}
            cmdSnippets={this.props.cmdSnippets}
            handleChange={this.handleChange}
            onSubmitForm={this.handleRun}
            onRef={(ref: any): any => (this.childForm = ref)}
            devicesDetail={this.props.devicesDetail}
            tenantDetail={this.props.tenantDetail}
          />
          {this.state.processingSave ? (
            <div className="">
              <ReactLoading
                type="bars"
                color="#4a90e2"
                height={40}
                width={40}
              />
              <A10Alert
                message={''}
                description={
                  this.state.runCmdSchedule.when === 'now'
                    ? 'Runtime Command is in progress'
                    : 'Task scheduling in progress'
                }
                type="info"
              />
            </div>
          ) : null}
        </>
      </FormatSlidingPage>
    )
  }
}
export default setupA10Container(SlidingDeviceCmdForms)
