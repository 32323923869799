import React, { useState, useEffect } from 'react'
import {
  A10Collapse,
  A10Icon,
  A10Row,
  A10SlidingPage,
} from '@gui-libraries/widgets'
import { AutoFormV15 } from '@gui-libraries/apps'

import { InfrastructureService } from 'src/services/index'
import storage from 'src/libraries/storage'

const NetworkARPGlobal: React.FC = (props: IObject) => {
  const [timeout, setTimeout] = useState(300)
  const [showSlidingPage, setShowSlidingPage] = useState(false)
  const infrastructureService = new InfrastructureService()
  const getData = () => {
    const provider = storage.get.PROVIDER

    infrastructureService
      .getRequest(
        null,
        null,
        [provider, props.deviceObj.name, 'shared'],
        'GET_DEVICE_ARP_GLOBAL',
      )
      .then((res: IObject) => {
        try {
          setTimeout(res.data['arp-timeout'].timeout)
        } catch (e) {
          console.log(e)
        }
      })
  }
  useEffect(() => {
    getData()
  }, [props.deviceObj.name])

  const toggleSlidingPage = (isOpen: boolean) => {
    setShowSlidingPage(isOpen)
  }

  const handleSuccess = () => {
    getData()
    toggleSlidingPage(false)
  }

  const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/device/${props.deviceObj.name}/partition/shared`

  return (
    <div className="col-md-12 marginBottom10">
      <A10SlidingPage
        isOpen={showSlidingPage}
        modalSize="large"
        onRequestClose={toggleSlidingPage.bind(this, false)}
      >
        <AutoFormV15
          hccEnv={true}
          schemaPath="network/arp/global"
          apiPrefix={apiPrefix}
          interceptor={{
            onSubmitSuccess: handleSuccess,
            onCancel: toggleSlidingPage.bind(this, false),
          }}
          enableSOP={true}
          ENV_CONSTS={{
            IS_SHARED_PARTITION: true,
            PARTITION_TYPE: 'SHARED',
          }}
        />
      </A10SlidingPage>
      <A10Collapse>
        <A10Collapse.Panel
          key={0}
          showArrow={false}
          disabled={true}
          header={
            <>
              {!storage.get.IS_OPERATOR_USER && (
                <span
                  className="edit-content"
                  onClick={toggleSlidingPage.bind(this, true)}
                >
                  <A10Icon app="global" type="edit" /> Edit
                </span>
              )}
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 fontWeight300">ARP Global</span>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Timeout</div>
                  <div className="pad-s-15 fontWeight300">{timeout}</div>
                </div>
              </A10Row>
            </>
          }
          className="collapsePanel"
        />
      </A10Collapse>
    </div>
  )
}

export default NetworkARPGlobal
