import React from 'react'
import propTypes from 'prop-types'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  getNS,
  _,
} from '@gui-libraries/framework'
import { A10Col, A10Input } from '@gui-libraries/widgets'

import SharedObjectList from './SharedObjectList'
import storage, { templateCategoryMap } from 'src/libraries/storage'
import GeneralObjectBrowser from '../../Common/GeneralObjectBrowser'
import { getUiJsonPath } from 'src/libraries/common'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'

import styles from './styles/index.module.less'

export interface IServiceObjectProps extends IA10ContainerDefaultProps {
  data: any[]
  tenant: string
  logicalPartition: string
  lpObject: IObject
  scope: string
}

export interface IServiceObjectState {
  showStats: boolean
  showSlidingPage: boolean
  objectType: string
  objects: any[]
  isLoading: boolean
  search: string
}

class ServiceObject extends A10Container<
  IServiceObjectProps,
  IServiceObjectState
> {
  static contextTypes = {
    openSlidingPage: propTypes.func.isRequired,
  }
  objectPassed = {}

  delayedSearchText = _.debounce(searchString => {
    this.getData(searchString)
  }, 800)

  constructor(props: IServiceObjectProps) {
    super(props)
    if (!storage.get.PROVIDER) {
      storage.set.PROVIDER('root')
    }

    this.state = {
      showStats: false,
      showSlidingPage: false,
      objectType: 'service',
      objects: [],
      isLoading: false,
      search: '',
    }
  }

  changeShowStats = (isChecked: boolean) => {
    this.setState({
      showStats: isChecked,
    })
  }

  setSlidingPage = (isOpen: boolean) => {
    let { tenant, logicalPartition } = this.props
    const { scope } = this.props
    if (this.props.scope === 'provider') {
      tenant = 'pso'
      logicalPartition = 'pso'
    }
    this.context.openSlidingPage(
      isOpen ? (
        <GeneralObjectBrowser
          isNew={true}
          getData={this.getData}
          tenant={tenant}
          logicalPartition={logicalPartition}
          currentScope={scope}
        />
      ) : null,
    )
  }

  componentDidUpdate(preProps: any, preState: any) {
    if (
      preProps.tenant !== this.props.tenant ||
      preProps.logicalPartition !== this.props.logicalPartition ||
      JSON.stringify(preProps.lpObject) !==
        JSON.stringify(this.props.lpObject) ||
      preProps.scope !== this.props.scope
    ) {
      this.getData()
    }
  }

  componentDidMount() {
    if (this.props.tenant) {
      this.getData()
    }
  }

  getDisplayCategory = (key: string) => {
    // see global.sch.json to double check
    const displayKey = templateCategoryMap[key]
      ? templateCategoryMap[key]
      : 'N/A'
    return displayKey
  }

  getDisplayKey = (key: string) => {
    // TODO: use shared translation mapping function in @gui-libraries/apps
    const NETWORK_TERMS: IObject = {
      dns: 'DNS',
      tcp: 'TCP',
      udp: 'UDP',
      dblb: 'DBLB',
      fix: 'FIX',
      ftp: 'FTP',
      http: 'HTTP',
      icap: 'ICAP',
      ip: 'IP',
      ipv4: 'IPv4',
      ipv6: 'IPv6',
      pop3: 'POP3',
      ssli: 'SSLi',
      ssl: 'SSL',
      imap: 'IMAP',
      sid: 'SID',
      sip: 'SIP',
      smpp: 'SMPP',
      smtp: 'SMTP',
      cfg: 'CFG',
    }
    const displayKey = key
      .replace(/-/g, ' ')
      .replace(/\./g, ' ')
      .split(' ')
      .map(word => {
        if (NETWORK_TERMS[word]) {
          return NETWORK_TERMS[word]
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1)
        }
      })
      .join(' ')
    return displayKey
  }

  getData = (search: string = '') => {
    // Start to load data
    this.setState({ isLoading: true })
    let { tenant, logicalPartition } = this.props
    if (this.props.scope === 'provider') {
      tenant = 'pso'
      logicalPartition = 'pso'
    }
    const {
      A10Dispatchers: { httpRequest },
    } = this.props
    httpRequest({
      namespace: getNS('SHARED_OBJECT_LIST'),
      request: async (epicDependence: IObject) => {
        try {
          const { httpClient } = epicDependence
          const { data: res } = await httpClient.get(
            `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${logicalPartition}/slb/template`,
          )
          const serviceObjectList = res.template || []
          const { data: loggingList } = await httpClient.get(
            `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${logicalPartition}/ip/nat/template/logging`,
          )

          const displayList = new Array()

          if (serviceObjectList.persist) {
            if (serviceObjectList.persist['cookie-list']) {
              serviceObjectList['persist.cookie-list'] =
                serviceObjectList.persist['cookie-list']
            }
            if (serviceObjectList.persist['destination-ip-list']) {
              serviceObjectList['persist.destination-ip-list'] =
                serviceObjectList.persist['destination-ip-list']
            }
            if (serviceObjectList.persist['source-ip-list']) {
              serviceObjectList['persist.source-ip-list'] =
                serviceObjectList.persist['source-ip-list']
            }
            if (serviceObjectList.persist['ssl-sid-list']) {
              serviceObjectList['persist.ssl-sid-list'] =
                serviceObjectList.persist['ssl-sid-list']
            }
          }

          if (loggingList['logging-list']) {
            serviceObjectList['non-slb-template.ip.nat.template.logging'] =
              loggingList['logging-list']
          }
          Object.keys(serviceObjectList).map((key: string) => {
            let templateKey = key.replace(/-list$/, '') // become 'virtual-server', 'http', etc
            const template = serviceObjectList[key]

            if (Array.isArray(template)) {
              for (const instance of template) {
                if (key.indexOf('non-slb-template') > -1) {
                  templateKey = key.replace('non-slb-template.', '')
                  instance.type = templateKey
                } else {
                  instance.type = 'slb.template.' + templateKey
                }
                instance.uiJson = getUiJsonPath(instance.type)
                instance.displayType = this.getDisplayKey(templateKey)
                instance.displayCategory = this.getDisplayCategory(
                  instance.type,
                )
                displayList.push(instance)
              }
            }
            // console.log('displayList', displayList)
            return displayList
          })
          const filteredList = displayList.filter((item: IObject) => {
            return (
              (item.name && item.name.indexOf(search) > -1) ||
              (item.id && Number(item.id) === Number(search)) ||
              item.displayType.indexOf(search) > -1 ||
              item.displayCategory.indexOf(search) > -1
            )
          })
          this.setState({ isLoading: false })
          // console.log('filteredList', filteredList)
          return filteredList
        } catch (err) {
          // tslint:disable-next-line:no-console
          console.error(err)
        }
        this.setState({ isLoading: false })
        return []
      },
    })
  }

  onSearchText = (e: any) => {
    const searchString = e.target.value
    this.setState({ search: searchString }, () => {
      this.delayedSearchText(searchString)
    })
  }

  render() {
    const { isLoading, search } = this.state
    let { tenant, logicalPartition } = this.props
    if (this.props.scope === 'provider') {
      tenant = 'pso'
      logicalPartition = 'pso'
    }
    return (
      <>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col>
            <ContentTitle title="Templates" count={this.props.data.length} />
          </A10Col>
          <A10Col style={{ display: 'flex' }}>
            <A10Input.Search
              type="text"
              onChange={this.onSearchText}
              name="searchBox"
              value={this.state.search}
              placeholder="Search"
              className={styles.searchInput}
            />
            {this.props.scope !== 'provider' ||
              (storage.get.ADMIN_LEVEL === 'provider' &&
                !storage.get.IS_OPERATOR_USER && (
                  <ActionButton
                    text="Add Template"
                    onClick={this.setSlidingPage.bind(this, true)}
                    iconProps={{ app: 'global', type: 'add-new' }}
                  />
                ))}
          </A10Col>
        </ContentHeader>
        <ContentBody>
          <SharedObjectList
            tenant={tenant}
            logicalPartition={logicalPartition}
            search={search}
            updateList={this.getData}
            isLoading={isLoading}
            scope={this.props.scope}
          />
        </ContentBody>
      </>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    data: state.A10Data.getIn(getNS('SHARED_OBJECT_LIST'), []),
    lpObject: state.A10Data.getIn(getNS('CURRENT_LOGICAL_PARTITION'), {}),
  }
}

export default setupA10Container(ServiceObject, mapStateToProps)
