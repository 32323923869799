import React from 'react'
import { _ } from '@gui-libraries/framework'
import {
  A10Collapse,
  A10DropdownMenu,
  A10Icon,
  A10Row,
  A10Table,
} from '@gui-libraries/widgets'

import HealthStatus from 'src/components/ADC/HealthStatus'
import RoundNumber from 'src/components/shared/RoundNumber'
import storage from 'src/libraries/storage'

export interface IInterfaceTrunkProps {
  deleteTrunk: any
  openAutoForm: any
  trunkList: IObject[]
  trunkStatus: IObject
}

const InterfaceTrunk: React.FC<IInterfaceTrunkProps> = props => {
  const { trunkList, trunkStatus, deleteTrunk, openAutoForm } = props
  const [datasources, setDatasources] = React.useState([] as IObject[])
  const [status, setStatus] = React.useState({} as IObject)
  const [counter, setCounter] = React.useState({} as IObject)

  const clickAddNew = (event: React.MouseEvent<HTMLElement>) => {
    if (openAutoForm) {
      openAutoForm('network/interfaces/trunk')
    }
    event.stopPropagation()
  }
  const editTrunk = (ifnum: number) => {
    if (openAutoForm) {
      const params = ifnum ? { ifnum } : undefined
      openAutoForm('network/interfaces/trunk', params)
    }
  }

  const clickDeleteTrunk = (ifnum: number) => {
    if (deleteTrunk) {
      deleteTrunk(ifnum)
    }
  }

  React.useEffect(() => {
    const datasource = [] as IObject[]
    const currentStatus = { up: 0, down: 0, disable: 0 } as IObject
    const count = {} as IObject
    ;(trunkList || []).map((trunk: IObject) => {
      const { oper = {}, netOper = {} } = (trunkStatus || {})[trunk.ifnum] || {}
      currentStatus[oper.state] = (currentStatus[oper.state] || 0) + 1
      datasource.push({
        key: trunk.ifnum,
        ifnum: trunk.ifnum,
        status: oper.state,
        mtu: trunk.mtu,
        type: netOper.trunk_type,
        ipAddress: _.get(oper, 'ipv4_list[0].addr', 'N/A'),
        members: _.get(netOper, 'trunk-member-status[0]', {}),
        portThreshold: {
          config: trunk['ports-threshold'] || 'N/A',
          oper: netOper.ports_threshold || 'N/A',
        },
        timer: {
          config: trunk.timer || 'N/A',
          oper: netOper.timer || 'N/A',
        },
        timerRuning: netOper.timer_running,
      })

      count.portThresholdConfig =
        (count.portThresholdConfig || 0) + (trunk['ports-threshold'] || 0)
      count.portThresholdOper =
        (count.portThresholdOper || 0) + (netOper.ports_threshold || 0)
      count.timerConfig = (count.timerConfig || 0) + (trunk.timer || 0)
      count.timerOper = (count.timerOper || 0) + (netOper.timer || 0)
    })
    setStatus(currentStatus)
    setDatasources(datasource)
  }, [trunkList, trunkStatus])

  const columns = React.useMemo(
    () => [
      {
        dataIndex: 'status',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return (
            <HealthStatus
              type={record.status}
              tooltip={record.status || 'Unknown'}
              toolTipArrowPosition="topLeft"
            />
          )
        },
      },
      {
        title: 'Trunk Number',
        dataIndex: 'ifnum',
        sorter: (a: IObject, b: IObject) => {
          return a.ifnum > b.ifnum ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text || 'N/A'
        },
      },
      {
        title: 'Type',
        dataIndex: 'type',
        sorter: (a: IObject, b: IObject) => {
          return a.type > b.type ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text || 'N/A'
        },
      },
      {
        title: 'IP Address',
        dataIndex: 'ipaddress',
        sorter: (a: IObject, b: IObject) => {
          return a.ipaddress > b.ipaddress ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text || 'N/A'
        },
      },
      // {
      //   title: 'Access Control',
      //   dataIndex: 'control',
      //   sorter: (a: any, b: any) => a - b,
      //   render: (text: any, record: any) => {
      //     return <s>N/A</s>
      //   },
      // },
      {
        title: 'Members (Config/Oper Status)',
        dataIndex: 'members',
        sorter: (a: any, b: any) => a - b,
        render: (text: any = {}, record: any) => {
          return (
            <span>
              {text.members}{' '}
              <HealthStatus
                type={text.cfg_status || 'Unknow'}
                tooltip={`Config: ${text.cfg_status || 'Unknow'}`}
                toolTipArrowPosition="topLeft"
              />{' '}
              <HealthStatus
                type={text.oper_status || 'Unknow'}
                tooltip={`Oper: ${text.oper_status || 'Unknow'}`}
                toolTipArrowPosition="topLeft"
              />
            </span>
          )
        },
      },
      {
        title: 'MTU',
        dataIndex: 'mtu',
        sorter: (a: IObject, b: IObject) => {
          return a.mtu > b.mtu ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text || 'N/A'
        },
      },
      {
        title: "Port's Threshold Config/Oper",
        dataIndex: 'portThreshold',
        render: (text: any, record: any) => {
          return `${text.config || 'N/A'} / ${text.oper || 'N/A'}`
        },
      },
      {
        title: 'Timer Config/Oper',
        dataIndex: 'timer',
        render: (text: any = {}, record: any) => {
          return `${text.config || 'N/A'} / ${text.oper || 'N/A'}`
        },
      },
      {
        title: 'Timer Runing Config/Oper',
        dataIndex: 'timerRuning',
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        dataIndex: 'actions',
        width: 30,
        render: (text: any, record: any) => {
          return (
            !storage.get.IS_OPERATOR_USER && (
              <A10DropdownMenu
                title=""
                menu={[
                  <div key="edit" onClick={editTrunk.bind(this, record.ifnum)}>
                    Edit
                  </div>,
                  <div
                    key="delete"
                    onClick={clickDeleteTrunk.bind(this, record.ifnum)}
                  >
                    Delete
                  </div>,
                ]}
                style={{ color: '#757575', marginBottom: '-15px' }}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            )
          )
        },
      },
    ],
    [deleteTrunk],
  )

  return (
    <div className="col-md-12 marginBottom10">
      <A10Collapse>
        <A10Collapse.Panel
          key="interface-trunk"
          header={
            <>
              {!storage.get.IS_OPERATOR_USER && (
                <span className="edit-content" onClick={clickAddNew}>
                  <A10Icon app="global" type="add-new" /> Add
                </span>
              )}
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 width180">
                  Trunk <RoundNumber number={(trunkList || []).length} />
                </span>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Up</div>
                  <div className="pad-s-15 fontWeight300">{status.up || 0}</div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Down</div>
                  <div className="pad-s-15 fontWeight300">
                    {status.down || 0}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Disabled</div>
                  <div className="pad-s-15 fontWeight300">
                    {status.disabled || 0}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">
                    Port's Threshold Config/Oper
                  </div>
                  <div className="pad-s-15 fontWeight300">
                    {counter.portThresholdConfig || 0}/
                    {counter.portThresholdOper || 0}
                  </div>
                </div>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Timer Config/Oper</div>
                  <div className="pad-s-15 fontWeight300">
                    {counter.timerConfig || 0}/{counter.timerOper || 0}
                  </div>
                </div>
              </A10Row>
            </>
          }
          className="collapsePanel"
        >
          <A10Table
            columns={columns}
            dataSource={datasources}
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
            bordered={false}
          />
        </A10Collapse.Panel>
      </A10Collapse>
    </div>
  )
}

export default InterfaceTrunk
