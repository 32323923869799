import {
  A10Collapse,
  A10DropdownMenu,
  A10Icon,
  A10Row,
  A10Table,
} from '@gui-libraries/widgets'
import React from 'react'
import * as _ from 'lodash'
import RoundNumber from 'src/components/shared/RoundNumber'
export interface IRoutingBGPProps {
  config: IObject[]
}

const RoutingBGP: React.FC<IRoutingBGPProps> = props => {
  const { config } = props

  const editOspf = () => {}

  const clickDeleteOspf = () => {}

  console.log(config)

  const columns = React.useMemo(
    () => [
      {
        title: 'ASN',
        dataIndex: 'asn',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return _.get(record, 'router-id.value') || 'N/A'
        },
      },
      {
        title: 'Router ID',
        dataIndex: 'router_id',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return _.get(record, 'bgp.router-id') || 'N/A'
        },
      },
      {
        title: '# Neighbors',
        dataIndex: 'neighbors',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return (
            _.get(record, 'neighbor.ipv4-neighbor-list', []).length +
            _.get(record, 'neighbor.ipv6-neighbor-list', []).length +
            _.get(record, 'neighbor.peer-group-neighbor-list', []).length
          )
        },
      },
      {
        title: 'Max Paths',
        dataIndex: 'max_paths',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return _.get(record, 'maximum-paths-value') || 'false'
        },
      },
      {
        title: 'Scan Time',
        dataIndex: 'scan_time',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return _.get(record, 'bgp.scan-time') || 'N/A'
        },
      },
      {
        title: 'Keepalive Timer',
        dataIndex: 'keepalive_timer',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return _.get(record, 'timers.bgp-keepalive') || 'N/A'
        },
      },
      {
        title: 'Holdtime',
        dataIndex: 'holdtime',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return _.get(record, 'timers.bgp-holdtime') || 'N/A'
        },
      },
      {
        title: 'Partition',
        dataIndex: 'partition',
        sorter: (a: any, b: any) => a - b,
        render: (text: any, record: any) => {
          return _.get(record, 'router-id.value') || 'N/A'
        },
      },
      {
        dataIndex: 'actions',
        width: 30,
        render: (text: any, record: any) => {
          return (
            <A10DropdownMenu
              title=""
              menu={[
                <div key="edit" onClick={editOspf.bind(this, record.ifnum)}>
                  Edit
                </div>,
                <div
                  key="delete"
                  onClick={clickDeleteOspf.bind(this, record.ifnum)}
                >
                  Delete
                </div>,
              ]}
              style={{ color: '#757575', marginBottom: '-15px' }}
              trigger="hover"
              placement="bottomRight"
              arrowPointAtCenter={true}
            />
          )
        },
      },
    ],
    [],
  )

  return (
    <div className="col-md-12 marginBottom10">
      <A10Collapse>
        <A10Collapse.Panel
          key="routing-bgp"
          header={
            <>
              <span className="edit-content">
                <A10Icon app="global" type="edit" /> Edit
              </span>
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 fontWeight300 width180">
                  BGP <RoundNumber number={(config || []).length} />
                </span>
              </A10Row>
            </>
          }
          className="collapsePanel"
        >
          <A10Table
            columns={columns}
            dataSource={config}
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: 10 }}
          />
        </A10Collapse.Panel>
      </A10Collapse>
    </div>
  )
}

export default RoutingBGP
