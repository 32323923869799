import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Icon,
  A10Form,
  A10Input,
  A10Select,
  A10Button,
  A10Table,
  A10Col,
  A10Tooltip,
  A10Checkbox,
  A10Radio,
  A10Message,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { Messages } from 'src/locale/en/Messages'
import { InfrastructureService } from 'src/services/InfrastructureService/InfrastructureService'
import { IDefaultMethods } from 'src/containers/Controller'
import { DropdownConstants } from 'src/constants/DropdownConstants/DropdownConstants'
import { Configs } from 'src/constants/Configs'
import { DashboardService, Utilities } from 'src/services/index'
import storage from 'src/libraries/storage'
import { HelpInfo } from 'src/components/shared/HelpInfo'

import './styles/awsdevicecreateform.scss'

const RadioGroup = A10Radio.Group
export interface IAWSDeviceCreateFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  device?: any
  vmDevices: any
  form: any
  formData?: any
  createFormData: any
  onRef?: any
  handleChange?: (data: any) => void
}
export interface IAWSDeviceCreateFormState {
  device: any
  vmDevice: any
  regionList: any[]
  zoneList: any[]
  vpcList: any[]
  awsImageList: any[]
  instanceTypeList: any[]
  subnetList: any[]
  elasticIPList: any[]
  securityGroupList: any[]
  vmWareNetworksList: any[]
  searchString: string
  searched: boolean
  lists: any[]
  hcClusterSelect: string
  nicCount: number
}

class AWSDeviceCreateForm extends A10Container<
  IAWSDeviceCreateFormProps,
  IAWSDeviceCreateFormState
> {
  Messages = new Messages()
  InfrastructureService = new InfrastructureService()
  DropdownConstants = new DropdownConstants()
  Configs = new Configs()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  lists: any[] = []

  mainNetworks = [
    {
      name: 'Management/eth0',
    },
    {
      name: 'Ethernet1/eth1',
    },
  ]

  // {
  //   name: 'Ethernet2/eth2',
  // }

  constructor(props: IAWSDeviceCreateFormProps) {
    super(props)
    const vmDevice: any = props.createFormData
    const device: any = props.formData
    this.state = {
      device,
      vmDevice,
      regionList: [],
      zoneList: [],
      vpcList: [],
      awsImageList: [],
      instanceTypeList: this.Configs.AWSInstanceTypeList,
      subnetList: [],
      elasticIPList: [],
      securityGroupList: [],
      vmWareNetworksList: [],
      searchString: '',
      searched: false,
      lists: [[]],
      hcClusterSelect: '',
      nicCount: 2,
    }
    this.loadAWSRegionLists(JSON.parse(device.credential))
  }

  getChangedValue = (e: any) => {
    let changedValue = ''
    if (e && e.target) {
      if (e.target.type === 'checkbox') {
        changedValue = e.target.checked
      } else {
        changedValue = e.target.value
      }
    } else {
      changedValue = e
    }
    return changedValue
  }
  handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      this.searchDeviceImage()
      event.preventDefault()
    }
  }
  handleChange = (stateName: string, e: any, index?: number) => {
    if (!this.Utilities.validateField(e)) {
      return
    }
    const changedValue = this.getChangedValue(e)
    const vmDeviceObj = this.state.vmDevice
    let {
      awsImageList,
      elasticIPList,
      securityGroupList,
      subnetList,
    } = this.state

    if (index !== undefined) {
      if (stateName === 'autoAssign' && changedValue) {
        vmDeviceObj.vmNetworks[index]['ipAddr'] = ''
        this.props.form.setFieldsValue({ [`ipAddr[${index}]`]: '' })
      }
      if (stateName === 'autoAllocate' && changedValue) {
        vmDeviceObj.vmNetworks[index]['publicIP'] = ''
        this.props.form.setFieldsValue({ [`publicIP[${index}]`]: undefined })
      }
      vmDeviceObj.vmNetworks[index][stateName] = changedValue
    } else {
      vmDeviceObj[stateName] = changedValue
    }

    if (stateName === 'region' && this.state.vmDevice.region !== '') {
      vmDeviceObj.zone = ''
      vmDeviceObj.vpc = ''
      vmDeviceObj.imageName = ''
      vmDeviceObj.image = ''
      awsImageList = []
      elasticIPList = []
      securityGroupList = []
      subnetList = []
      this.props.form.setFieldsValue({
        [`zone`]: undefined,
        [`vpc`]: undefined,
        [`imageName`]: '',
      })
      vmDeviceObj.vmNetworks.map((vnic: any, index: number) => {
        if (vnic.subnet !== '') {
          vnic.subnet = ''
          this.props.form.setFieldsValue({ [`subnet[${index}]`]: undefined })
        }
        vnic.ipAddr = ''
        this.props.form.setFieldsValue({ [`ipAddr[${index}]`]: '' })
        if (vnic.publicIP !== '') {
          vnic.publicIP = ''
          this.props.form.setFieldsValue({ [`publicIP[${index}]`]: undefined })
        }
        if (vnic.securityGroup !== '') {
          vnic.securityGroup = ''
          this.props.form.setFieldsValue({
            [`securityGroup[${index}]`]: undefined,
          })
        }
      })
      this.loadAWSZoneLists(JSON.parse(vmDeviceObj['region']))
      this.loadAWSVPCLists(JSON.parse(vmDeviceObj['region']))
      this.loadAWSElasticIPs(JSON.parse(vmDeviceObj['region']))
    }

    if (stateName === 'vpc' && vmDeviceObj.zone !== '') {
      securityGroupList = []
      subnetList = []
      vmDeviceObj.vmNetworks.map((vnic: any, index: number) => {
        if (vnic.subnet !== '') {
          vnic.subnet = ''
          this.props.form.setFieldsValue({ [`subnet[${index}]`]: undefined })
        }
        vnic.ipAddr = ''
        this.props.form.setFieldsValue({ [`ipAddr[${index}]`]: '' })
        if (vnic.securityGroup !== '') {
          vnic.securityGroup = ''
          this.props.form.setFieldsValue({
            [`securityGroup[${index}]`]: undefined,
          })
        }
      })
      this.loadAWSSecurityGroups(JSON.parse(vmDeviceObj['vpc']))
      this.loadAWSSubnets(
        JSON.parse(vmDeviceObj['vpc']),
        JSON.parse(vmDeviceObj['zone']),
      )
    }

    if (stateName === 'zone' && vmDeviceObj.vpc !== '') {
      subnetList = []
      vmDeviceObj.vmNetworks.map((vnic: any, index: number) => {
        if (vnic.subnet !== '') {
          vnic.subnet = ''
          this.props.form.setFieldsValue({ [`subnet[${index}]`]: undefined })
        }
        vnic.ipAddr = ''
        this.props.form.setFieldsValue({ [`ipAddr[${index}]`]: '' })
      })
      this.loadAWSSubnets(
        JSON.parse(vmDeviceObj['vpc']),
        JSON.parse(vmDeviceObj['zone']),
      )
    }

    if (stateName === 'instanceType') {
      let nics = JSON.parse(vmDeviceObj['instanceType']).maxNics
      if (this.state.nicCount > nics) {
        for (let i = nics; i < this.state.nicCount; i++) {
          this.mainNetworks.pop()
          vmDeviceObj.vmNetworks.pop()
        }
        this.setState({ nicCount: vmDeviceObj.vmNetworks.length })
      }
    }

    this.props.handleChange(vmDeviceObj)
    // @ts-ignore
    this.setState({
      vmDevice: vmDeviceObj,
      awsImageList,
      elasticIPList,
      securityGroupList,
      subnetList,
    })
  }

  addEth = () => {
    const nicName =
      'Ethernet' + this.state.nicCount + '/eth' + this.state.nicCount
    this.mainNetworks.push({ name: nicName })
    const vmDeviceObj = this.state.vmDevice
    vmDeviceObj.vmNetworks.push({
      name: 'eth' + this.state.nicCount,
      subnet: '',
      ipAddr: '',
      autoAssign: false,
      publicIP: '',
      autoAllocate: false,
      securityGroup: '',
    })
    this.props.handleChange(vmDeviceObj)
    // @ts-ignore
    this.setState({ vmDevice: vmDeviceObj, nicCount: this.state.nicCount + 1 })
  }

  removeEth = () => {
    this.mainNetworks.pop()
    const vmDeviceObj = this.state.vmDevice
    vmDeviceObj.vmNetworks.pop()
    this.props.handleChange(vmDeviceObj)
    // @ts-ignore
    this.setState({ vmDevice: vmDeviceObj, nicCount: this.state.nicCount - 1 })
  }

  getIPnumber = (ip_address: any) => {
    let ip = ip_address.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/)
    if (ip) {
      return (+ip[1] << 24) + (+ip[2] << 16) + (+ip[3] << 8) + +ip[4]
    }
  }

  validatePrivateIPs = (region: any) => {
    let req_payload = {}
    let ip_list = []
    let unavailableIPs: string[] = []
    const { showMessage } = this.Utilities

    for (let i = 0; i < this.state.vmDevice.vmNetworks.length; i++) {
      if (
        !this.state.vmDevice.vmNetworks[i].autoAssign &&
        this.state.vmDevice.vmNetworks[i].ipAddr
      ) {
        if (
          this.state.vmDevice.vpc == '' ||
          this.state.vmDevice.vmNetworks[i].subnet == ''
        ) {
          showMessage('Please select both VPC and Subnet', 0, 0)
          return
        } else {
          let subnet_cidr = JSON.parse(this.state.vmDevice.vmNetworks[i].subnet)
            .CidrBlock
          const cidrIp = subnet_cidr.split('/')[0]
          const cidrSm = subnet_cidr.split('/')[1]
          const private_ip = this.state.vmDevice.vmNetworks[i].ipAddr
          const ipmask = -1 << (32 - cidrSm)
          let isIPinSubnet =
            (this.getIPnumber(private_ip) & ipmask) == this.getIPnumber(cidrIp)
          if (!isIPinSubnet) {
            showMessage(
              'Private IP address ' +
                private_ip +
                ' does not belong to the range of subnet ' +
                subnet_cidr,
              0,
              0,
            )
            return
          } else {
            ip_list.push({
              'ip-address': this.state.vmDevice.vmNetworks[i].ipAddr,
              'network-id': JSON.parse(this.state.vmDevice.vpc).VpcId,
              'subnet-id': JSON.parse(this.state.vmDevice.vmNetworks[i].subnet)
                .SubnetId,
            })
          }
        }
      }
    }

    if (ip_list.length < 1) {
      A10Message.info('No IP addresses provided or marked for validation.')
      return
    }

    req_payload['ip-addresses'] = ip_list
    const credential = JSON.parse(this.state.device.credential)
    const awsPrivateIPResponse = this.InfrastructureService.checkAWSPrivateIPAvailability(
      null,
      req_payload,
      [credential.uuid, JSON.parse(region).RegionName],
    )
    awsPrivateIPResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && Object.keys(list).length > 0) {
            for (var key in list) {
              if (!list[key]) {
                unavailableIPs.push(key)
              }
            }
          }
          if (ip_list.length > 0 && unavailableIPs.length == 0) {
            A10Message.success('All Private IP Addresses are available')
          } else if (unavailableIPs.length == 1) {
            let err_message =
              'Private IP Address [' +
              unavailableIPs[0] +
              '] is already in use.'
            showMessage(err_message, 0, 0, '')
          } else if (unavailableIPs.length > 1) {
            let unavailableIP_list = unavailableIPs[0]
            for (let i = 1; i < unavailableIPs.length; i++) {
              unavailableIP_list += ', ' + unavailableIPs[i]
            }
            let err_message =
              'Private IP Addresses [' +
              unavailableIP_list +
              '] are already in use.'
            showMessage(err_message, 0, 0, '')
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to check private IP address availability'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadAWSRegionLists = (credential: any) => {
    const awsRegionsResponse = this.InfrastructureService.getAWSRegions(
      null,
      null,
      [credential.uuid],
    )
    awsRegionsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              regionList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get AWS region details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadAWSZoneLists = (region: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const awsZonesResponse = this.InfrastructureService.getAWSZones(
      null,
      null,
      [credential.uuid, region.RegionName],
    )
    awsZonesResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              zoneList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get aws availability zone details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadAWSVPCLists = (region: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const awsVPCsResponse = this.InfrastructureService.getAWSVPCs(null, null, [
      credential.uuid,
      region.RegionName,
    ])
    awsVPCsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              vpcList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get aws virtual private cloud details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  searchDeviceImage = () => {
    const credential = JSON.parse(this.state.device.credential)
    const region = JSON.parse(this.state.vmDevice.region)
    const awsImageResponse = this.InfrastructureService.getAWSImage(
      null,
      null,
      [credential.uuid, region.RegionName, this.state.vmDevice.imageName],
    )
    awsImageResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load ? response.data.load : []
        }
        this.setState({
          awsImageList: list,
          searched: true,
        })
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get aws image details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
    // }
  }

  loadAWSElasticIPs = (region: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const awsElasticIPsResponse = this.InfrastructureService.getAWSElasticIP(
      null,
      null,
      [credential.uuid, region.RegionName],
    )
    awsElasticIPsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              elasticIPList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get aws public IP details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadAWSSecurityGroups = (vpc: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const region = JSON.parse(this.state.vmDevice.region)
    const awsSecurityGroupsResponse = this.InfrastructureService.getAWSSecurityGroup(
      null,
      null,
      [credential.uuid, region.RegionName, vpc.VpcId],
    )
    awsSecurityGroupsResponse
      .then((response: any) => {
        let list = []
        if (response.data && response.data.status === 'Success') {
          list = response.data.load
          if (list && list.length > 0) {
            this.setState({
              securityGroupList: list,
            })
          }
        }
      })
      .catch((error: any) => {
        // console.log(error.response)
        const { showMessage } = this.Utilities
        const responseCode =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.response_code
            ? error.response.data.response_code
            : error.response
            ? error.response.status
            : ''
        let msg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        let message = 'Unable to get aws security group details'
        if (responseCode === 403) {
          message += ', please check the credentials'
          msg = ''
        }
        showMessage(message, 0, 0, msg)
      })
  }

  loadAWSSubnets = (vpc: any, zone: any) => {
    const credential = JSON.parse(this.state.device.credential)
    const region = JSON.parse(this.state.vmDevice.region)
    if (vpc.VpcId && zone.ZoneId) {
      const { showMessage } = this.Utilities
      const awsSubnetsResponse = this.InfrastructureService.getAWSSubnet(
        null,
        null,
        [credential.uuid, region.RegionName, vpc.VpcId, zone.ZoneId],
      )
      awsSubnetsResponse
        .then((response: any) => {
          let list = []
          if (response && response.data && response.data.status === 'Success') {
            list = response.data.load
            if (list && list.length > 0) {
              this.setState({
                subnetList: list,
              })
            } else {
              showMessage(
                'There are no subnets for the selected VPC in the selected Availability Zone',
                2,
                0,
              )
            }
          }
        })
        .catch((error: any) => {
          // console.log(error.response)
          const responseCode =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.response_code
              ? error.response.data.response_code
              : error.response
              ? error.response.status
              : ''
          let msg =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : ''
          let message = 'Unable to get aws subnet details'
          if (responseCode === 403) {
            message += ', please check the credentials'
            msg = ''
          }
          showMessage(message, 0, 0, msg)
        })
    }
  }

  validateDuplicateVMName = (rule: any, value: any, cb: any) => {
    let isValid: boolean = true
    const index = this.props.vmDevices.findIndex((key: any) => {
      return key.name === value
    })
    const userRegx = new RegExp(/^([a-zA-Z0-9._-]){1,50}$/)

    if (!value || '' === value) {
      rule.message = this.Messages.VM_NAME_REQ
      isValid = false
    } else if (!userRegx.test(value)) {
      rule.message = this.Messages.INVALID_VM_NAME
      isValid = false
    } else if (index > -1) {
      rule.message = this.Messages.VM_NAME_DUP
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { vmDevice } = this.state
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <div className="margin-v-10">
          <A10Panel
            className="panelBodyPadding"
            title={
              <>
                <A10IconTextGroup
                  text={
                    <>
                      <span className="borderLeftData">AWS Environment</span>
                    </>
                  }
                  icon={
                    <A10Icon
                      className="destinationIcon"
                      app="gi-firewall"
                      type="destination"
                      style={{ fontSize: '24px' }}
                    />
                  }
                />
              </>
            }
          >
            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                    VM (Instance) Name
                  </span>
                  <span>
                    <HelpInfo
                      placement="topLeft"
                      title="VM (Instance) Name"
                      helpKey="HELP_INFRA_VIRTUAL_VM_NAME"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('vmName', {
                rules: [{ validator: this.validateDuplicateVMName.bind(this) }],
              })(
                <A10Input
                  type="text"
                  placeholder={'Enter VM Device name'}
                  onChange={(e: any) => this.handleChange('vmName', e)}
                />,
              )}
            </A10Form.Item>

            <A10Form.Item
              {...formItemLayout}
              label={
                <>
                  <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                    Region
                  </span>
                  <span>
                    <HelpInfo
                      placement="topLeft"
                      title="Region"
                      helpKey="HELP_INFRA_VIRTUAL_AWS_REGION"
                    />
                  </span>
                </>
              }
            >
              {getFieldDecorator('region', {
                rules: [
                  {
                    required: true,
                    message: 'Please select region',
                  },
                ],
                initialValue: vmDevice.region || undefined,
              })(
                <A10Select
                  placeholder={'Select a region'}
                  onChange={(e: any) => this.handleChange('region', e)}
                >
                  <A10Select.Option key="blank" value="">
                    Select Region
                  </A10Select.Option>
                  {this.state.regionList.map((obj: any, i: number) => {
                    return (
                      <A10Select.Option key={i} value={JSON.stringify(obj)}>
                        {obj.RegionName}
                      </A10Select.Option>
                    )
                  })}
                </A10Select>,
              )}
            </A10Form.Item>

            {!!vmDevice.region ? (
              <>
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                        Availability Zone
                      </span>
                      <span>
                        <HelpInfo
                          placement="topLeft"
                          title="Availability Zone"
                          helpKey="HELP_INFRA_VIRTUAL_AWS_AVAILABILITY_ZONE"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('zone', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select availability zone',
                      },
                    ],
                    // initialValue: vmDevice.zone
                  })(
                    <A10Select
                      placeholder={'Select availability zone'}
                      onChange={(e: any) => this.handleChange('zone', e)}
                    >
                      {this.state.zoneList.map((obj: any, i: number) => {
                        return (
                          <A10Select.Option key={i} value={JSON.stringify(obj)}>
                            {obj.ZoneName}
                          </A10Select.Option>
                        )
                      })}
                    </A10Select>,
                  )}
                </A10Form.Item>

                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                        Virtual Private Cloud (VPC)
                      </span>
                      <span>
                        <HelpInfo
                          placement="topLeft"
                          title="Virtual Private Cloud (VPC)"
                          helpKey="HELP_INFRA_VIRTUAL_AWS_VPC"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('vpc', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select Virtual Private Cloud (VPC)',
                      },
                    ],
                    initialValue: vmDevice.vpc || undefined,
                  })(
                    <A10Select
                      placeholder={'Select Virtual Private Cloud (VPC)'}
                      onChange={(e: any) => this.handleChange('vpc', e)}
                    >
                      {this.state.vpcList.map((obj: any, i: number) => {
                        let dispName = obj.CidrBlock
                        if (
                          obj.Tags &&
                          obj.Tags !== null &&
                          obj.Tags.length > 0
                        ) {
                          const tag = obj.Tags.filter((tagObj: any) => {
                            return tagObj.Key === 'Name'
                          })
                          dispName =
                            tag.length > 0
                              ? tag[0].Value + ' (' + obj.CidrBlock + ')'
                              : dispName
                        }
                        return (
                          <A10Select.Option key={i} value={JSON.stringify(obj)}>
                            {dispName}
                          </A10Select.Option>
                        )
                      })}
                    </A10Select>,
                  )}
                </A10Form.Item>

                <div className="aws-vm-template">
                  <span className="title">VM Templates</span>
                </div>

                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                        Image Name
                      </span>
                      <span>
                        <HelpInfo
                          placement="topLeft"
                          title="Image Name"
                          helpKey="HELP_INFRA_VIRTUAL_AWS_IMAGE"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('imageName')(
                    <div className="row">
                      <div className="col-md-8">
                        <A10Input
                          type="text"
                          placeholder={'Name of the machine image'}
                          onChange={(e: any) =>
                            this.handleChange('imageName', e)
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                      <div className="col-md-4">
                        <A10Button
                          className="action-button"
                          onClick={() => {
                            this.searchDeviceImage()
                          }}
                        >
                          Search
                        </A10Button>
                      </div>
                      {!!this.state.awsImageList &&
                      this.state.awsImageList.length === 0 &&
                      this.state.searched ? (
                        <span className="col-md-12">{'No images found.'}</span>
                      ) : !!this.state.awsImageList &&
                        this.state.awsImageList.length > 0 &&
                        this.state.awsImageList.length <= 50 ? (
                        <span className="col-md-12">
                          {this.state.awsImageList.length +
                            ' image(s) found. Please select one.'}
                        </span>
                      ) : !!this.state.awsImageList &&
                        this.state.awsImageList.length > 50 ? (
                        <span className="col-md-12">
                          {
                            ' More than 50 images found. Please refine your search filter'
                          }
                        </span>
                      ) : null}
                    </div>,
                  )}
                </A10Form.Item>

                {!!this.state.awsImageList &&
                this.state.awsImageList.length > 0 ? (
                  <A10Form.Item {...formItemLayout} label="&nbsp;">
                    {getFieldDecorator('image')(
                      <div className="aws-image-div">
                        <RadioGroup
                          onChange={(e: any) => this.handleChange('image', e)}
                          value={vmDevice.image}
                        >
                          {this.state.awsImageList.map(
                            (awsImage: any, index: number) => {
                              return (
                                <A10Radio
                                  key={index}
                                  value={JSON.stringify(awsImage)}
                                  className="aws-image-list"
                                >
                                  {awsImage.Name}
                                </A10Radio>
                              )
                            },
                          )}
                        </RadioGroup>
                      </div>,
                    )}
                  </A10Form.Item>
                ) : null}

                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      <span style={{ fontWeight: 'normal', fontSize: '16px' }}>
                        Instance Type
                      </span>
                      <span>
                        <HelpInfo
                          placement="topLeft"
                          title="Instance Type"
                          helpKey="HELP_INFRA_VIRTUAL_AWS_INSTANCE_TYPE"
                        />
                      </span>
                    </>
                  }
                >
                  {getFieldDecorator('instanceType', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select instance type',
                      },
                    ],
                    initialValue: vmDevice.instanceType || undefined,
                  })(
                    <A10Select
                      placeholder={'Select instance type'}
                      onChange={(e: any) =>
                        this.handleChange('instanceType', e)
                      }
                    >
                      {this.state.instanceTypeList.map(
                        (obj: any, i: number) => {
                          return (
                            <A10Select.Option
                              key={i}
                              value={JSON.stringify(obj)}
                            >
                              {obj.name} ({obj.maxNics} Network Interfaces)
                            </A10Select.Option>
                          )
                        },
                      )}
                    </A10Select>,
                  )}
                </A10Form.Item>
              </>
            ) : null}
          </A10Panel>

          {!!vmDevice.region ? (
            <A10Panel
              className="panelBodyPadding"
              title={
                <>
                  <A10IconTextGroup
                    text={
                      <>
                        <span className="borderLeftData">Deployment</span>
                      </>
                    }
                    icon={
                      <A10Icon
                        app="harmony-controller"
                        type="provider"
                        className="destinationIcon"
                      />
                    }
                  />
                </>
              }
            >
              <A10Button
                className="action-button pull-right"
                onClick={() => {
                  this.validatePrivateIPs(this.state.vmDevice.region)
                }}
                style={{ padding: '0px !important' }}
              >
                Validate IP Addresses
              </A10Button>
              <div className="VMWareTemplate">
                {
                  <>
                    Network Mapping
                  </>
                }
              </div>
              <A10Table
                dataSource={this.mainNetworks}
                pagination={false}
                scroll={{ x: '100%' }}
              >
                <A10Col
                  title="Name"
                  dataIndex="name"
                  key="name"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortString(a, b, 'name')
                  }
                  render={(text: string, record: any, index: number) => {
                    return (
                      <div>
                        {index > 1 && index === this.state.nicCount - 1 ? (
                          <A10IconTextGroup
                            text={text}
                            icon={
                              <A10Icon
                                style={{ fontSize: 24 }}
                                app="global"
                                type="delete-another"
                                onClick={this.removeEth.bind(this)}
                              />
                            }
                          />
                        ) : (
                          text
                        )}
                      </div>
                    )
                  }}
                />
                <A10Col
                  title="Subnet"
                  key="subnet"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortString(a, b, 'subnet')
                  }
                  render={(text: string, record: any, index: number) => {
                    return (
                      <A10Form.Item className="no-margin">
                        {getFieldDecorator(`subnet[${index}]`, {
                          rules: [
                            {
                              required: true,
                              message: 'Select subnet',
                            },
                          ],
                        })(
                          <A10Select
                            placeholder={'Please select'}
                            className="aws-network-dropdown"
                            dropdownClassName="aws-network-dropdown"
                            allowClear
                            size="small"
                            style={{ top: '-10px' }}
                            onChange={(e: any) =>
                              this.handleChange('subnet', e, index)
                            }
                          >
                            {this.state.subnetList.map(
                              (obj: any, i: number) => {
                                return (
                                  <A10Select.Option
                                    key={i}
                                    value={JSON.stringify(obj)}
                                  >
                                    <A10Tooltip
                                      placement="top"
                                      title={
                                        'Available address count - ' +
                                        obj['AvailableIpAddressCount']
                                      }
                                    >
                                      <span>{obj['CidrBlock']}</span>
                                    </A10Tooltip>
                                  </A10Select.Option>
                                )
                              },
                            )}
                          </A10Select>,
                        )}
                      </A10Form.Item>
                    )
                  }}
                />
                <A10Col
                  title="IP Address"
                  key="ipAddr"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortNumber(a, b, 'ipAddr')
                  }
                  render={(text: string, record: any, index: number) => {
                    return (
                      <>
                        <A10Form.Item className="no-margin">
                          <>
                            {getFieldDecorator(`ipAddr[${index}]`, {
                              validateTrigger: 'onBlur',
                              rules: [
                                {
                                  validator: vmDevice.vmNetworks[index]
                                    .autoAssign
                                    ? null
                                    : this.Utilities.validateVMDeviceIPs.bind(
                                        this,
                                        'not-mandatory',
                                      ),
                                },
                              ],
                            })(
                              <A10Input
                                type="text"
                                placeholder={'IP Address'}
                                size="small"
                                style={{ top: '-3px' }}
                                onChange={(e: any) =>
                                  this.handleChange('ipAddr', e, index)
                                }
                                disabled={vmDevice.vmNetworks[index].autoAssign}
                              />,
                            )}
                          </>
                        </A10Form.Item>
                        <div className="col-md-12 no-padding">
                          <A10Checkbox
                            onChange={(e: any) =>
                              this.handleChange('autoAssign', e, index)
                            }
                            style={{ fontSize: '12px' }}
                          >
                            Auto-assign
                          </A10Checkbox>
                        </div>
                      </>
                    )
                  }}
                />
                <A10Col
                  title="Public IP"
                  key="publicIP"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortNumber(a, b, 'publicIP')
                  }
                  render={(text: string, record: any, index: number) => {
                    return (
                      <>
                        <A10Form.Item className="no-margin">
                          {getFieldDecorator(
                            `publicIP[${index}]`,
                            {},
                          )(
                            <A10Select
                              placeholder={'Please select'}
                              className="aws-network-dropdown"
                              dropdownClassName="aws-network-dropdown"
                              size="small"
                              allowClear
                              onChange={(e: any) =>
                                this.handleChange('publicIP', e, index)
                              }
                              disabled={vmDevice.vmNetworks[index].autoAllocate}
                            >
                              {this.Utilities.renderDropdownOpts(
                                this.state.elasticIPList,
                                null,
                                'PublicIp',
                                null,
                                null,
                                null,
                                'PublicIp',
                                index + '',
                              )}
                            </A10Select>,
                          )}
                        </A10Form.Item>
                        <div className="col-md-12 no-padding">
                          <A10Checkbox
                            onChange={(e: any) =>
                              this.handleChange('autoAllocate', e, index)
                            }
                            style={{ fontSize: '12px' }}
                          >
                            Auto-allocate
                          </A10Checkbox>
                        </div>
                      </>
                    )
                  }}
                />
                <A10Col
                  title="Security Group"
                  key="securityGroup"
                  sorter={(a: any, b: any) =>
                    this.Utilities.sortNumber(a, b, 'securityGroup')
                  }
                  render={(text: string, record: any, index: number) => {
                    return (
                      <A10Form.Item className="no-margin">
                        {getFieldDecorator(`securityGroup[${index}]`, {
                          rules: [
                            {
                              required: true,
                              message: 'Select Security Group',
                            },
                          ],
                        })(
                          <A10Select
                            placeholder={'Please select'}
                            className="aws-network-dropdown"
                            dropdownClassName="aws-network-dropdown"
                            size="small"
                            style={{ top: '-10px' }}
                            onChange={(e: any) =>
                              this.handleChange('securityGroup', e, index)
                            }
                          >
                            {this.Utilities.renderDropdownOpts(
                              this.state.securityGroupList,
                              null,
                              'GroupName',
                              null,
                              null,
                              null,
                              'GroupName',
                              index + '',
                            )}
                          </A10Select>,
                        )}
                      </A10Form.Item>
                    )
                  }}
                />
              </A10Table>
              {this.state.vmDevice.instanceType &&
              this.state.nicCount <
                JSON.parse(this.state.vmDevice.instanceType).maxNics ? (
                <div className="row margin-v-10">
                  <div className="col-sm-12">
                    <A10IconTextGroup
                      text={
                        <div className="addEth2Title">
                          Ethernet{this.state.nicCount}/eth{this.state.nicCount}
                        </div>
                      }
                      icon={
                        <A10Icon
                          style={{ fontSize: 24 }}
                          app="global"
                          type="add-another"
                          onClick={this.addEth.bind(this)}
                        />
                      }
                    />
                  </div>
                </div>
              ) : null}
            </A10Panel>
          ) : null}
        </div>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(AWSDeviceCreateForm))
