import React from 'react'
import { A10Provider, IA10ProviderProps } from '@gui-libraries/framework'

import CONFIG from './settings/config'
import appReducers from './redux/reducers'

export interface IHCPRoviderProps {
  children: JSX.Element
  store?: IA10ProviderProps['store']
  initState?: IObject
}

const HCProvider = (props: IHCPRoviderProps) => {
  const { children, store, initState } = props
  const options = {
    initState,
    reducers: appReducers,
    middlewares: [],
    CONFIG,
    children,
  } as IA10ProviderProps

  if (store) {
    options.store = store
  }
  return <A10Provider {...options} />
}
export default HCProvider
