import React from 'react'
export const NavApps = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="205"
    height="536"
    viewBox="0 0 171 536"
  >
    <defs>
      <filter
        id="u0u4mysn2a"
        width="115.5%"
        height="141.7%"
        x="-7.7%"
        y="-20.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="31.136" />
      </filter>
      <filter
        id="mop15dh4ub"
        width="101%"
        height="101.7%"
        x="-.5%"
        y="-.9%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
      </filter>
      <rect id="ze3xba97jc" width="1200" height="694" x="0" y="0" rx="4" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <path
          fill="#FAFBFF"
          d="M0 0H1280V860H0z"
          transform="translate(-127.000000, -185.000000)"
        />
        <path
          fill="#D8D8D8"
          fill-opacity=".5"
          d="M0 0H1281V859H0z"
          transform="translate(-127.000000, -185.000000)"
        />
        <g>
          <path
            fill="#000"
            fill-opacity=".2"
            d="M157.07 290h887.77c3.344 0 6.335 2.08 7.5 5.216l148.862 400.953c1.538 4.142-.573 8.747-4.715 10.284-.67.25-1.37.407-2.083.47C958.9 727.64 776.697 738 647.794 738c-129.263 0-342.623-10.417-640.078-31.251-4.407-.31-7.73-4.132-7.421-8.54.053-.76.215-1.51.48-2.225L149.57 295.216c1.164-3.136 4.155-5.216 7.5-5.216z"
            filter="url(#u0u4mysn2a)"
            transform="translate(-127.000000, -185.000000) translate(43.000000, 89.000000)"
          />
          <g transform="translate(-127.000000, -185.000000) translate(43.000000, 89.000000)">
            <use
              fill="#000"
              filter="url(#mop15dh4ub)"
              xlinkHref="#ze3xba97jc"
            />
            <use fill="#FFF" xlinkHref="#ze3xba97jc" />
          </g>
          <path
            fill="#2A3D56"
            d="M54 96H295V633H54z"
            transform="translate(-127.000000, -185.000000) translate(43.000000, 89.000000)"
          />
          <g>
            <g stroke="#FFF" stroke-linecap="round">
              <path
                d="M22.882 10.504l-.001-.002-9.384-9.382C13.097.72 12.566.5 12 .5c-.565 0-1.097.22-1.497.62l-9.379 9.377-.009.01c-.821.826-.82 2.166.004 2.99.376.377.873.594 1.405.617.022.003.044.004.065.004h.374v6.904c0 1.366 1.112 2.478 2.479 2.478h3.67c.373 0 .675-.302.675-.674v-5.413c0-.623.507-1.13 1.13-1.13h2.166c.623 0 1.13.507 1.13 1.13v5.413c0 .372.302.674.674.674h3.671c1.367 0 2.479-1.112 2.479-2.478v-6.904h.347c.565 0 1.097-.22 1.497-.62.825-.826.825-2.168.001-2.994h0z"
                transform="translate(-127.000000, -185.000000) translate(43.000000, 89.000000) translate(98.000000, 126.000000) translate(0.000000, -0.000000)"
              />
            </g>
            <text
              fill="#FFF"
              font-family="Rubik-Regular, Rubik"
              font-size="16"
              transform="translate(-127.000000, -185.000000) translate(43.000000, 89.000000) translate(98.000000, 126.000000)"
            >
              <tspan x="34" y="18">
                Home
              </tspan>
            </text>
          </g>
          <g transform="translate(-127.000000, -190.000000) translate(43.000000, 89.000000) translate(84.000000, 180.000000)">
            <g>
              <g stroke="#FFF" stroke-linecap="round">
                <path
                  d="M16.507 11.989h-4.016c-.264 0-.48-.215-.48-.479V7.4m0-2.576V.979c0-.264.216-.479.48-.479h10.53c.264 0 .479.215.479.479V11.51c0 .262-.215.479-.479.479h-3.928m-7.107 4.224v-3.825c0-.262.215-.477.479-.477h4.11m2.307 0h4.114c.263 0 .478.215.478.477V22.92c0 .264-.215.479-.478.479H12.464c-.263 0-.478-.215-.478-.479v-3.928m6.896-7.088c.295.294.477.702.477 1.151 0 .898-.729 1.627-1.628 1.627-.898 0-1.627-.729-1.627-1.627 0-.446.18-.85.47-1.143m-9.096.044l4.032-.049c.263 0 .479.216.479.479v4.11m0 3.036v3.385c0 .263-.216.479-.48.479H.98c-.264 0-.479-.216-.479-.48v-10.53c0-.263.215-.479.479-.479h3.929m7.088 7.006c-.295.295-.702.477-1.151.477-.9 0-1.628-.729-1.628-1.628 0-.9.728-1.627 1.628-1.627.446 0 .85.18 1.143.47"
                  transform="translate(14.000000, 0.000000) translate(0.000000, 0.000000)"
                />
                <path
                  d="M12.013 7.212v4.298c0 .263-.215.479-.479.479h-4.11m-2.308 0H1.003c-.263 0-.478-.215-.478-.479V.98C.525.715.74.5 1.003.5h10.531c.264 0 .48.216.48.48v3.927m-.005 0c.295-.294.702-.477 1.151-.477.899 0 1.628.729 1.628 1.629 0 .898-.73 1.627-1.628 1.627-.448 0-.853-.181-1.147-.474m-6.897 4.783c-.294-.294-.476-.701-.476-1.151 0-.898.728-1.627 1.627-1.627s1.628.729 1.628 1.627c0 .446-.18.851-.47 1.145"
                  transform="translate(14.000000, 0.000000) translate(0.000000, 0.000000)"
                />
              </g>
              <text
                fill="#FFF"
                font-family="Rubik-Regular, Rubik"
                font-size="16"
                transform="translate(14.000000, 0.000000)"
              >
                <tspan x="34" y="18">
                  Harmony Apps
                </tspan>
              </text>
            </g>
            <g>
              <g transform="translate(19.000000, 40.000000)">
                <circle
                  cx="10"
                  cy="10"
                  r="10"
                  fill="#FFF"
                  fill-rule="nonzero"
                />
                <path
                  fill="#6D6E71"
                  fill-rule="nonzero"
                  d="M10 20C4.469 20 0 15.531 0 10S4.469 0 10 0s10 4.469 10 10-4.469 10-10 10zm0-1.25c4.844 0 8.75-3.906 8.75-8.75 0-4.844-3.906-8.75-8.75-8.75-4.844 0-8.75 3.906-8.75 8.75 0 4.844 3.906 8.75 8.75 8.75z"
                />
                <path
                  fill="#AEB2C1"
                  fill-rule="nonzero"
                  d="M16.563 9.594c0 1.687-1.375 3.062-3.063 3.062-.344 0-.625-.281-.625-.625s.281-.625.625-.625c1 0 1.813-.812 1.813-1.812 0-.781-.5-1.469-1.25-1.719l-.688-.219.313-.656c.062-.156.093-.313.093-.469 0-.562-.469-1.031-1.031-1.031-.188 0-.375.063-.531.156L11.656 6l-.312-.594c-.406-.812-1.25-1.343-2.219-1.343-1.406 0-2.563 1.156-2.563 2.562v.094l.032.406-.375.188C5.5 7.688 5.03 8.405 5.03 9.25c0 1.188.969 2.156 2.157 2.156.343 0 .625.281.625.625s-.282.625-.625.625c-1.875 0-3.407-1.531-3.407-3.406 0-1.188.594-2.25 1.563-2.875.093-1.969 1.75-3.563 3.781-3.563 1.25 0 2.375.594 3.063 1.563.187-.063.374-.094.593-.094 1.25 0 2.281 1.032 2.281 2.282 0 .125 0 .25-.03.406.937.531 1.53 1.531 1.53 2.625z"
                />
                <g fill="#AEB2C1">
                  <path
                    fill-rule="nonzero"
                    d="M3.813 1.375L4.563 1.938 1.531 6.031.781 5.469zM4.813 1.625L5.563 1.063 8.938 5.563 8.188 6.125z"
                    transform="translate(5.625000, 9.687500)"
                  />
                  <path
                    fill-rule="nonzero"
                    d="M4.281 1.719L5.219 1.719 5.219 5.688 4.281 5.688z"
                    transform="translate(5.625000, 9.687500)"
                  />
                  <path
                    d="M3.438 0H5.938000000000001V2.5H3.438zM0 4.375H2.5V6.875H0zM3.438 4.375H5.938000000000001V6.875H3.438zM6.875 4.375H9.375V6.875H6.875z"
                    transform="translate(5.625000, 9.687500)"
                  />
                </g>
              </g>
              <text
                fill="#BFC5CC"
                font-family="Rubik-Regular, Rubik"
                font-size="14"
                transform="translate(19.000000, 40.000000)"
              >
                <tspan x="30" y="15">
                  Application Delivery
                </tspan>
              </text>
            </g>
            <g>
              <g fill-rule="nonzero">
                <g transform="translate(19.000000, 75.000000)">
                  <circle cx="10" cy="10" r="10" fill="#FFF" />
                  <path
                    fill="#6D6E71"
                    d="M10 20C4.469 20 0 15.531 0 10S4.469 0 10 0s10 4.469 10 10-4.469 10-10 10zm0-1.25c4.844 0 8.75-3.906 8.75-8.75 0-4.844-3.906-8.75-8.75-8.75-4.844 0-8.75 3.906-8.75 8.75 0 4.844 3.906 8.75 8.75 8.75z"
                  />
                  <g fill="#AEB2C1">
                    <path
                      d="M2.156 6.656h2.125c.125-.031.188-.062.188-.062.031-.063.25-.469.593-1.157.344-.718.844-1.78 1.5-3.156.313-.656.875-1 1.563-1h2.5V.031l2.156 2.156-2.125 2.126v-1.5H8.094c-.063 0-.094 0-.156.124-1.313 2.844-2 4.25-2.188 4.5-.281.407-.719.657-1.281.75H2.125v1.376L0 7.469l2.156-2.157v1.344z"
                      transform="translate(3.750000, 5.312500)"
                    />
                    <path
                      d="M2.156 1.375H4.5c.563.094 1 .344 1.281.719.188.25.875 1.656 2.188 4.437.062.094.062.125.156.125h2.5V5.313l2.156 2.156-2.125 2.125V8.219H8.094c-.688 0-1.219-.344-1.563-1-.625-1.344-1.125-2.375-1.5-3.094C4.72 3.469 4.5 3.031 4.438 3c0 0-.063-.031-.188-.063H2.125v1.344L0 2.156 2.156 0v1.375z"
                      transform="translate(3.750000, 5.312500)"
                    />
                  </g>
                </g>
              </g>
              <text
                fill="#BFC5CC"
                font-family="Rubik-Regular, Rubik"
                font-size="14"
                transform="translate(19.000000, 75.000000)"
              >
                <tspan x="30" y="15">
                  Carrier Grade NAT
                </tspan>
              </text>
            </g>
            <g>
              <g fill-rule="nonzero">
                <g transform="translate(19.000000, 110.000000)">
                  <circle cx="10" cy="10" r="10" fill="#FFF" />
                  <path
                    fill="#6D6E71"
                    d="M10 20C4.469 20 0 15.531 0 10S4.469 0 10 0s10 4.469 10 10-4.469 10-10 10zm0-1.25c4.844 0 8.75-3.906 8.75-8.75 0-4.844-3.906-8.75-8.75-8.75-4.844 0-8.75 3.906-8.75 8.75 0 4.844 3.906 8.75 8.75 8.75z"
                  />
                  <g fill="#AEB2C1">
                    <path
                      d="M8.906 5c0 .438-.344.781-.781.781-.438 0-.781-.343-.781-.781V3.875c0-1.188-.907-2.156-2-2.156H5.28c-1.093 0-2 .968-2 2.156V5c0 .438-.344.781-.781.781-.438 0-.781-.343-.781-.781V3.875C1.719 1.844 3.312.156 5.28.156h.063c1.968 0 3.562 1.657 3.562 3.719V5z"
                      transform="translate(4.687500, 2.812500)"
                    />
                    <path
                      d="M10.625 10.844v.75c0 .5-.469.906-1.031.906H1.03C.47 12.5 0 12.094 0 11.594V5.906C0 5.406.469 5 1.031 5h8.563c.562 0 1.031.406 1.031.906v4.938zm-3.531-.313c.968-.969.968-2.562 0-3.531-.969-.969-2.563-.969-3.532 0-.968.969-.968 2.563 0 3.531.97.969 2.532.969 3.532 0z"
                      transform="translate(4.687500, 2.812500)"
                    />
                    <path
                      d="M5.344 9.031c.187.188.187.469 0 .656-.188.188-.469.188-.657 0l-.625-.624c-.187-.188-.187-.47 0-.657.188-.187.47-.187.657 0l.625.625z"
                      transform="translate(4.687500, 2.812500)"
                    />
                    <path
                      d="M5.344 9.719c-.188.187-.469.187-.657 0-.187-.188-.187-.469 0-.656l1.25-1.25c.188-.188.47-.188.657 0 .187.187.187.468 0 .656l-1.25 1.25z"
                      transform="translate(4.687500, 2.812500)"
                    />
                  </g>
                </g>
              </g>
              <text
                fill="#BFC5CC"
                font-family="Rubik-Regular, Rubik"
                font-size="14"
                transform="translate(19.000000, 110.000000)"
              >
                <tspan x="30" y="15">
                  SSL Insight
                </tspan>
              </text>
            </g>
            <g>
              <g fill-rule="nonzero">
                <g transform="translate(19.000000, 145.000000)">
                  <circle cx="10" cy="10" r="9.688" fill="#FFF" />
                  <path
                    fill="#6D6E71"
                    d="M10 18.75c4.844 0 8.75-3.906 8.75-8.75 0-4.844-3.906-8.75-8.75-8.75-4.844 0-8.75 3.906-8.75 8.75 0 4.844 3.906 8.75 8.75 8.75zM10 20C4.469 20 0 15.531 0 10S4.469 0 10 0s10 4.469 10 10-4.469 10-10 10z"
                  />
                  <path
                    fill="#AEB2C1"
                    d="M15.156 4.688c-4.156 0-4.875-1.5-4.875-1.532-.031-.062-.094-.125-.187-.125-.094 0-.156.063-.188.125 0 0-.719 1.531-4.875 1.531-.125 0-.218.094-.218.188v8.219c0 2.75 5 4.875 5.187 4.969h.188c.218-.094 5.187-2.188 5.187-4.97V4.876c0-.094-.094-.188-.219-.188zm-2.937 6.53l.5 2.938-2.656-1.406-2.657 1.406.5-2.937L5.75 9.125l2.969-.188L10.03 6l1.313 2.938 2.969.187-2.094 2.094z"
                  />
                </g>
              </g>
              <text
                fill="#BFC5CC"
                font-family="Rubik-Regular, Rubik"
                font-size="14"
                transform="translate(19.000000, 145.000000)"
              >
                <tspan x="30" y="15">
                  GTP Firewall
                </tspan>
              </text>
            </g>
            <circle cx="29" cy="190" r="10" fill="#FFF" />
            <g>
              <g transform="translate(19.000000, 180.000000)">
                <circle cx="10.313" cy="9.688" r="9.688" fill="#FFF" />
                <path
                  fill="#6D6E71"
                  fill-rule="nonzero"
                  d="M10 18.75c4.844 0 8.75-3.906 8.75-8.75 0-4.844-3.906-8.75-8.75-8.75-4.844 0-8.75 3.906-8.75 8.75 0 4.844 3.906 8.75 8.75 8.75zM10 20C4.469 20 0 15.531 0 10S4.469 0 10 0s10 4.469 10 10-4.469 10-10 10z"
                />
                <g fill="#AEB2C1">
                  <path
                    d="M.219 0H1.5630000000000002V1.688H.219zM5.938 0H7.282V1.688H5.938zM1.875 0H5.625V1.688H1.875zM.313 3.75H1.563V5.313H.313zM5.938 3.75H7.188V5.313H5.938zM1.875 3.75H5.625V5.313H1.875zM.313 1.875H3.7510000000000003V3.4379999999999997H.313zM4.063 1.875H7.188V3.4379999999999997H4.063z"
                    transform="translate(5.223214, 9.687500)"
                  />
                </g>
                <path
                  fill="#AEB2C1"
                  fill-rule="nonzero"
                  d="M12.813 12.563v-.938h.25c1.124 0 1.593-1.156 1.593-2.156 0-.563-.562-2-.625-2.188-.094.282-.656 1.188-1.437.875-1.563-.625.687-1.625-1.344-3-.25.969.219 3.75-1 3.75-.438 0-1-.656-1.156-1.094v-.156c-.094.375-.281.907-.5 1.375H7.375c.031-.031.031-.094.063-.125.5-1.062 1-1.531 1-2.312 0-.782-.313-1.375-.313-1.375.313 0 .875.312 1.188.812.25.438.093.969.437 1.188.688.281.625-1.469.781-2.563.125-.906 0-.875 0-.875.5.875 3.469.5 2.219 3.188.938.562 1.219-1.188 1.219-1.188s1.687 2.532 1.687 4c0 1.594-1.594 2.813-2.594 2.813h-.25v-.031z"
                />
              </g>
              <text
                fill="#BFC5CC"
                font-family="Rubik-Regular, Rubik"
                font-size="14"
                transform="translate(19.000000, 180.000000)"
              >
                <tspan x="30" y="15">
                  Gi/SGi Firewall
                </tspan>
              </text>
            </g>
            <g stroke-linecap="square">
              <path
                stroke="#C5D3DA"
                d="M0.5 0.75L143.826 0.75"
                transform="translate(14.000000, 212.000000)"
              />
              <path
                stroke="#D9E2ED"
                stroke-opacity=".502"
                d="M0.5 0.25L143.826 0.25"
                transform="translate(14.000000, 212.000000)"
              />
            </g>
            <g fill="#BFC5CC" font-family="Rubik-Regular, Rubik" font-size="14">
              <g>
                <text transform="translate(0.000000, 229.000000) translate(30.000000, 2.000000)">
                  <tspan x="0" y="13">
                    App Catalog
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
