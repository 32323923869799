import React from 'react'
import ReactLoading from 'react-loading'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Collapse } from '@gui-libraries/widgets'
import { Viz } from '@gui-libraries/viz'

import storage from 'src/libraries/storage'
import { RoundNumber } from 'src/components/shared/RoundNumber'
import { Utilities, TroubleShootService } from 'src/services'
import { Messages } from 'src/locale/en/Messages'

const styles = require('../../RunCommands/styles/index.module.less')

const { Table } = Viz
export interface IScheduleTaskDetailsProps extends IA10ContainerDefaultProps {
  scheduledTask: any
  cmdSnippets: any
  refreshLogs: boolean
}
export interface IScheduleTaskDetailsState {
  cmdHistory: any[]
  tableLoaded: boolean
  refreshLogs: boolean
}

class ScheduleTaskDetails extends A10Container<
  IScheduleTaskDetailsProps,
  IScheduleTaskDetailsState
> {
  Messages = new Messages()
  Utilities = new Utilities()
  TroubleShootService = new TroubleShootService()
  provider = storage.get.PROVIDER
  deviceList: any[] = storage.get.ALLDEVICES
  headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    provider: storage.get.PROVIDER,
    Authorization: storage.get.ENCODED_SESSION_ID,
    user: storage.get.USER_ID,
  }
  historyMaxCnt = 50

  constructor(props: any) {
    super(props)
    this.state = {
      cmdHistory: [],
      tableLoaded: false,
      refreshLogs: this.props.refreshLogs || false,
    }
  }

  getCmdSnippetHistory = () => {
    const allDevices: any[] = storage.get.ALLDEVICES
    // get top [historyMaxCnt] history to display
    const cmdSnippetHistory = this.TroubleShootService.listCmdSnippetHistory(
      this.headers,
      { schedule_name: this.props.scheduledTask.name },
      [this.provider, this.historyMaxCnt],
    )

    cmdSnippetHistory
      .then((response: any) => {
        const cmdHistory =
          response?.data && Array.isArray(response.data) ? response.data : []
        cmdHistory.map((cmd: any) => {
          const device =
            allDevices &&
            allDevices.find((deviceObj: any) => {
              return cmd.device_name === deviceObj.name
            })
          if (device && device.cluster) {
            cmd['cluster_name'] = device.cluster
          }
          const cmdSnippet =
            this.props.cmdSnippets &&
            this.props.cmdSnippets.find((snippet: any) => {
              return cmd.snippet_id === snippet.id
            })
          if (cmdSnippet && cmdSnippet.name) {
            cmd['snippet_name'] = cmdSnippet.name
          }
          cmd['created_at'] = this.TroubleShootService.convertToLocalDateTime(
            cmd['created_at'],
          )
          cmd[
            'last_modified_at'
          ] = this.TroubleShootService.convertToLocalDateTime(
            cmd['last_modified_at'],
          )
        })
        this.setState({ cmdHistory, tableLoaded: true })
      })
      .catch((error: any) => {
        this.setState({ cmdHistory: [], tableLoaded: true })
      })
  }

  componentDidMount() {
    this.getCmdSnippetHistory()
  }

  componentDidUpdate() {
    if (this.props.refreshLogs !== this.state.refreshLogs) {
      this.setState({ refreshLogs: this.props.refreshLogs })
      this.getCmdSnippetHistory()
    }
  }

  render() {
    const { cmdHistory, tableLoaded } = this.state
    const { scheduledTask } = this.props
    const input_parameters = JSON.parse(scheduledTask.input_parameters || {})
    const startDate = input_parameters?.scheduler?.startsOn
      ? this.Utilities.formatDate(
          input_parameters.scheduler.startsOn,
          'MMM D, YYYY, HH:mm a',
        )
      : ''

    return (
      <div className={styles.taskDetailContainer}>
        {/* <A10Collapse bordered={false} defaultActiveKey={['1']} className={styles.taskDetailsCollpase}>
          <A10Collapse.Panel header="Task Details" key="1">
            <A10Row type="flex" className={`no-margin font-14 ${styles.taskDetails}`}>
              <div className={`margin-h-15 ${styles.devContent}`}>
                <div className={styles.title}>Command</div>
                <div className={`${styles.value} pad-s-15`}>{scheduledTask.commands}</div>
              </div>
              <div className={`margin-h-15 ${styles.devContent}`}>
                <div className={styles.title}>{this.Messages.WHEN}</div>
                <div className={`${styles.value} pad-s-15`}>Schedule</div>
              </div>
              <div className={`margin-h-15 ${styles.devContent}`}>
                <div className={styles.title}>{this.Messages.FREQUENCY}</div>
                <div className={`${styles.value} pad-s-15`}>
                  {input_parameters && input_parameters.scheduler ? input_parameters.scheduler.frequency : ''}
                </div>
              </div>
              <div className={`margin-h-15 ${styles.devContent}`}>
                <div className={styles.title}>{this.Messages.STARTS_ON}</div>
                <div className={`${styles.value} pad-s-15`}>{startDate}</div>
              </div>
            </A10Row>
          </A10Collapse.Panel>
        </A10Collapse> */}

        <A10Collapse bordered={false} className={styles.taskDetailsCollpase}>
          <A10Collapse.Panel
            header={
              <>
                <div>
                  <span>Run Logs</span>
                  <RoundNumber number={cmdHistory ? cmdHistory.length : 0} />
                </div>
              </>
            }
            key="1"
            className="collapsePanel"
          >
            {tableLoaded ? (
              <Table name="Troubleshoot" data={cmdHistory || []} />
            ) : (
              <div>
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </div>
            )}
          </A10Collapse.Panel>
        </A10Collapse>
      </div>
    )
  }
}

export default setupA10Container(ScheduleTaskDetails)
