import React from 'react'
import { A10Icon } from '@gui-libraries/widgets'

const iconStyle = { fontSize: 34 }
const ApplicationServices = () => {
  return (
    <div id="gettingStarted-4">
      <div className="gettingStarted-content-header">
        Manage Application Services
      </div>
      <div className="gettingStarted-content-horizontal">
        <div className="horizontal-item">
          <div className="horizontal-title-items">
            <div className="horizontal-icon">
              <A10Icon
                app="harmony-controller"
                type="app-service"
                style={iconStyle}
              />
            </div>
            <div className="horizontal-title">Configure App Services</div>
          </div>
          <div className="horizontal-rectangle" id="appServiceImage" />
          <div className="horizontal-text">
            Launch a service management app such as ADC or CGN to configure and
            deploy ADC vPorts or CGN technology, firewall rules etc.
          </div>
        </div>
        <div className="horizontal-item">
          <div className="horizontal-title-items">
            <div className="horizontal-icon">
              <A10Icon app="thunder-adc" type="analytic" style={iconStyle} />
            </div>
            <div className="horizontal-title">View App Service Analytics</div>
          </div>
          <div className="horizontal-rectangle" id="viewAppServiceImage" />
          <div className="horizontal-text">
            For each application service running in Thunder, an Application
            service listing is automatically created in the tenant space. Launch
            respective app to view service analytics.
          </div>
        </div>
        <div className="horizontal-item">
          <div className="horizontal-title-items">
            <div className="horizontal-icon">
              <A10Icon
                app="harmony-controller"
                type="alert"
                style={iconStyle}
              />
            </div>
            <div className="horizontal-title">Manage Alerts</div>
          </div>
          <div className="horizontal-rectangle" id="manageAlertsImage" />
          <div className="horizontal-text">
            Define alerts using system provided triggers. You can also customize
            alerts by copying an existing alert and updating various parameters.
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplicationServices
