import React from 'react'
import propTypes from 'prop-types'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import { A10Col, A10Input } from '@gui-libraries/widgets'
import RBACRolesList from './RbacRoleList'

import storage from 'src/libraries/storage'
import { OrganizationService } from 'src/services'
import {
  ContentSection,
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'

export interface IRBACRolesProps extends IA10ContainerDefaultProps {}

export interface IRBACRolesState {
  rolesList: any[]
  isLoading: boolean
  search: string
}

class RBACRoles extends A10Container<IRBACRolesProps, IRBACRolesState> {
  static contextTypes = {
    openSlidingPage: propTypes.func.isRequired,
    setModalSize: propTypes.func,
  }

  OrganizationService = new OrganizationService()
  delayedSearchText = _.debounce(searchString => {
    this.searchData(searchString)
  }, 800)
  rolesList: any[] = []
  isOperatorUser = storage.get.IS_OPERATOR_USER

  constructor(props: IRBACRolesProps) {
    super(props)
    this.state = {
      rolesList: [],
      isLoading: false,
      search: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  searchData = (search: string = '') => {
    const rolesList = this.rolesList
      ? this.rolesList.filter((roles: any) => {
          return (
            roles?.name?.indexOf(search) > -1 ||
            roles?.displayName?.indexOf(search) > -1
          )
        })
      : []
    this.setState({ rolesList })
  }

  getData = (search: string = '') => {
    // Start to load data
    this.setState({ isLoading: true })
    const getAGListReq = this.OrganizationService.getRbacRoleList(null, null, [
      storage.get.PROVIDER,
    ])
    getAGListReq
      .then((resp: any) => {
        const roles = resp?.data || []
        // HARMONY-21759 : to remove app-admin & app-operator roles for creating access group
        const rolesList = roles.filter((role: IObject) => {
          return role.name !== 'hc_app_admin' && role.name !== 'hc_app_operator'
        })
        this.rolesList = rolesList
        this.setState({ rolesList, isLoading: false })
      })
      .catch((error: any) => {
        // Error in get access group API
        this.setState({ isLoading: false })
      })
  }

  onSearchText = (e: any) => {
    const searchString = e.target.value
    this.setState({ search: searchString }, () => {
      this.delayedSearchText(searchString)
    })
  }

  render() {
    const { isLoading, search } = this.state

    return (
      <ContentSection>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col>
            <ContentTitle title="Roles" count={this.state.rolesList.length} />
          </A10Col>
          <A10Col>
            <A10Input.Search
              type="text"
              onChange={this.onSearchText}
              name="searchBox"
              value={this.state.search}
              placeholder="Search"
            />
          </A10Col>
        </ContentHeader>
        <ContentBody>
          <RBACRolesList
            updateList={this.getData}
            isLoading={isLoading}
            data={this.state.rolesList}
            search={search}
          />
        </ContentBody>
      </ContentSection>
    )
  }
}

export default setupA10Container(RBACRoles)
