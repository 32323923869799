// tslint:disable-next-line:no-var-requires
import * as queryString from 'query-string'
import moment from 'moment-timezone'

import { UrlService, AjaxService } from 'src/services'
import { AppRoot } from 'src/settings/appRoot'
import { Data } from 'src/constants/Data/Data'

export class DashboardService {
  UrlService = new UrlService()
  AjaxService = new AjaxService()
  AppRoot = new AppRoot()
  Data = new Data()

  getDeviceToken = (headers: any, payload: any, urlInput: any): any => {
    const urlObj = this.UrlService.get('GET_JWT_TOKEN')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  listTenant = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('LIST_TENANT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  listTenants = (headers: any, payload: any, urlInput: any) => {
    // const urlObj = this.UrlService.get('LIST_TENANTS_O')
    const urlObj = this.UrlService.get('LIST_TENANTS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    // Return new Promise((resolve: any, rej: any) => {
    //   resolve(this.Data.tenants)
    // })
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  listTenantsDetailed = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('LIST_TENANTS_DETAILED')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  listSelectedTenants = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('LIST_SELECTED_TENANTS')
    // const urlObj = this.UrlService.get('LIST_SELECTED_TENANTS_O')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getApplications = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_APPLICATIONS_BARISTA')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getActiveApplications = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_ACTIVE_APPLICATIONS_BARISTA')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getInActiveApplications = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_INACTIVE_APPLICATIONS_BARISTA')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getLadcClusters = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LADC_CLUSTERS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getCredentials = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LADC_CREDENTIALS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getPaginatedItems = (itemsArray: any, pageLength: any) => {
    const indexes = [0]
    let currentIndex = 0
    const paginatedItems: any[] = []

    for (let i = 0; i < itemsArray.length; i++) {
      if (i > 0 && i % pageLength === 0) {
        currentIndex++
        indexes.push(currentIndex)
      }
      if (!paginatedItems[currentIndex]) {
        paginatedItems[currentIndex] = []
      }
      paginatedItems[currentIndex].push(itemsArray[i])
    }
    return [paginatedItems, indexes]
  }
  getProviders = (headers: any, payload: any) => {
    const urlObj = this.UrlService.get('GET_PROVIDERS')
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getProvider = (headers: any, payload: any,urlInput: any) => {
    const urlObj = this.UrlService.get('GET_PROVIDER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getProviderDevices = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_INDEPENDENT_DEVICES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getProviderLicense = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LICENSE_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getDeviceClusterStatistics = (headers: any, payload: any) => {
    // const urlObj = this.UrlService.get('GET_SYSTEM_AUDIT_LOGS_TO_HC')
    const urlObj = this.UrlService.get('GET_CLUSTER_STATS')
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getDevicePartitionStatistics = (headers: any, payload: any) => {
    const urlObj = this.UrlService.get('GET_PARTITION_STATS')
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getActivationUrl = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_PROVIDER_ACTIVATION_URL')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  updateProvider = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_PROVIDER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getTenants = (
    headers: any,
    payload: any,
    urlInput: any,
    cancelToken?: any,
  ) => {
    const urlObj = this.UrlService.get('LIST_TENANTS_SUMMARY')
    // const urlObj = this.UrlService.get('LIST_TENANTS_O')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    // return new Promise((resolve: any, rej: any) => {
    //   resolve(this.Data.tenants)
    // })
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
      cancelToken,
    )
  }
  getTenantObj = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_TENANT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getTenantsList = (
    headers: IObject,
    payload: IObject,
    urlInput: string[],
    cancelToken?: IObject,
  ) => {
    const urlObj = this.UrlService.get('GET_TENANTS_LIST')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
      cancelToken,
    )
  }
  updateTenant = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_TENANT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getUsers = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_SUPERADMIN_USERS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getTenantAdminUsers = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_TENANT_ADMIN')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getUserActivationMail = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_USER_ACTIVATION_LINK')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getAdminActivationMail = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_ADMIN_ACTIVATION_LINK')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getUUIDDetails = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_UUID_DETAILS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  addUser = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_SUPER_ADMIN_USER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  updateUser = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_USER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  addProviders = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_PROVIDER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  createProviderAdmin = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('CREATE_PROVIDER_ADMIN')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  addSubProviderUser = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_SUB_PROVIDER_USER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  addTenants = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_TENANT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getUser = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_USER_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  userProfile = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_PROVIDER_ADMIN_DETAILS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  userAssign = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ASSIGN_USER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }
  userTenantAssign = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ASSIGN_TENANT_USER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }

  // Apps APIs
  getTenantActiveApps = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_HC_TENANT_APPS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getTenantActiveAppsAppAdmin = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_HC_TENANT_APPS_APP_ADMIN')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getProviderAppRepo = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_HC_PROVIDER_APPS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getHCAppCatalog = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_HC_APP_CATALOG')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getHCAppTenantCatalog = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_HC_TENANT_APP_CATALOG')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  validateHCApp = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('VALIDATE_HC_APP')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      'formdata',
    )
  }
  saveHCApp = (headers: any, payload: any, urlInput: any, updateMode: any) => {
    const urlObj = this.UrlService.get(
      updateMode ? 'UPDATE_HC_APP' : 'UPLOAD_HC_APP',
    )
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      'formdata',
    )
  }
  deleteHCApp = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_HC_APP')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  subscribeHCApp = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('SUB_HC_PROVIDER_APP')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  unsubscribeHCApp = (
    headers: any,
    payload: any,
    urlInput: any,
    forceUninstall?: boolean,
  ) => {
    const urlObj = this.UrlService.get(
      !forceUninstall ? 'UNSUB_HC_PROVIDER_APP' : 'FORCE_UNSUB_HC_PROVIDER_APP',
    )
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  enableHCApp = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('SUB_HC_PROVIDER_APP')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  enableHCTenantApp = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('SUB_HC_TENANT_APP')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  disableHCTenantApp = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UNSUB_HC_TENANT_APP')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getHCAppInfo = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_HC_APP_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  deleteTenant = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_TENANT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  deleteUser = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_USER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  deleteProvider = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_PROVIDER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  deleteProviderChartTemplates = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('DELETE_PROVIDER_CHART_TEMPLATES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getObjectType = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_OBJECT_TYPE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getSchemaRegistry = (
    headers: any,
    payload: any,
    urlInput: any,
    isLADC?: boolean,
  ) => {
    let urlObj = this.UrlService.get('GET_SCHEMA_REGISTRY')
    if (isLADC) {
      urlObj = this.UrlService.get('GET_LADC_SCHEMA_REGISTRY')
    }
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getMetricType = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_METRIC_TYPE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getTriggers = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_TRIGGERS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getCannedTriggers = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_CANNED_TRIGGERS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getTriggerTemplates = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_TRIGGER_TEMPLATES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getTrigger = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_TRIGGER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  addTrigger = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_TRIGGER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  updateTrigger = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_TRIGGER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  deleteTrigger = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_TRIGGER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getAlerts = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_ALERTS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  addAlert = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_ALERT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  updateAlert = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_ALERT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  deleteAlert = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_ALERT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getServiceGroup = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_SERVICE_GROUP')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getSLBVirtualServer = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_SLB_VIRTUAL_SERVER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getSLBServiceGroup = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_SLB_SERVICE_GROUP')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getCgnv6Tech = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_CGNV6_TECH')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getSelfObjects = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_SELF_OBJECTS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getTreeNodes = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_TREE_NODES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getTreeNodesFW = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_TREE_NODES_FW')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getTimezones = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_ALL_TIMEZONES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getDefaultBrowserTimeZone = (timezones: any[]) => {
    const defaultZone = moment.tz.guess()
    const currentUTCOffSetString = `UTC${moment.tz(defaultZone).format('Z')}`
    const currentUTCOffSetArr = timezones.filter((offsetObj: any) => {
      return offsetObj.offset === currentUTCOffSetString
    })

    let timezoneStr = undefined
    if (currentUTCOffSetArr && currentUTCOffSetArr.length > 0) {
      timezoneStr = JSON.stringify(currentUTCOffSetArr[0])
    }

    return timezoneStr
  }

  getTimeZoneString = (timezones: any[], timeZoneOffset: string) => {
    const currentUTCOffSetArr = timezones.filter((offsetObj: any) => {
      return offsetObj.offset === timeZoneOffset
    })

    let timezoneStr = undefined
    if (currentUTCOffSetArr && currentUTCOffSetArr.length > 0) {
      timezoneStr = JSON.stringify(currentUTCOffSetArr[0])
    }

    return timezoneStr
  }

  getActions = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_ACTIONS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getAction = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_ACTION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  addAction = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_ACTION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  updateAction = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_ACTION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  deleteAction = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_ACTION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getReports = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_REPORTS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )

    // const reports = {
    //   data: {
    //     "success": "true",
    //     "message": "List of Reports",
    //     "reports": {
    //       "resultSetCount": 31,
    //       "resultSet": [
    //         {
    //           "path": "/opt/portal-generator/pdf/root/ControllerTenant/CGN/19-11-12-19-32-24.pdf",
    //           "reportid": "NewReport",
    //           "providerid": "root",
    //           "tenantid": "ControllerTenant",
    //           "name": null,
    //           "created_at": null,
    //           "id": "sqqsfoarlzpe330u6pwzh",
    //           "last_modified_by": "System",
    //           "type": "userTriggered",
    //           "last_modified_at": null,
    //           "occurance": null
    //         },
    //         {
    //           "path": null,
    //           "reportid": "adhocReport",
    //           "providerid": "root",
    //           "tenantid": "ControllerTenant",
    //           "name": null,
    //           "created_at": "1573456118000",
    //           "id": "0nocc5zg4ypl72nuqyl0aj",
    //           "last_modified_by": "System",
    //           "type": 'userTriggered',
    //           "last_modified_at": null,
    //           "occurance": null
    //         },
    //         {
    //           "path": null,
    //           "reportid": "NewReport",
    //           "providerid": "root",
    //           "tenantid": "ControllerTenant",
    //           "name": null,
    //           "created_at": "1573456118000",
    //           "id": "0nocc5zg4ypl72nuqyl0aj",
    //           "last_modified_by": "System",
    //           "type": 'System',
    //           "last_modified_at": null,
    //           "occurance": null
    //         }
    //       ],
    //       "totalRecordsCount": 0
    //     }
    //   }
    // }
    // return Promise.resolve(reports)
  }

  createAdhocReport = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('CREATE_ADHOC_REPORT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
    // const response = {
    //   data: {
    //     success: 'true',
    //     message: 'REPORT submitted for generation successfully'
    //   }
    // }
    // return Promise.resolve(response)
  }

  createScheduledReport = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('CREATE_SCHEDULED_REPORT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  downloadReport = (headers: any, payload: any, urlInput: any) => {
    headers['responseType'] = 'arraybuffer'
    const urlObj = this.UrlService.get('DOWNLOAD_REPORT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getReportTemplates = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_REPORTS_TEMPLATES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )

    // const reportTypes = {
    //   data: {
    //     "success": "true",
    //     "message": "Listing all the available report types",
    //     "reportTypes": [
    //       "ADC",
    //       "CGN"
    //     ]
    //   }
    // }
    // return Promise.resolve(reportTypes)
  }

  getScheduleReports = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_REPORT_SCHEDULES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
    // const scheduleReports = {
    //   data: {
    //     "success": "true",
    //     "message": "List of Scheduled Reports",
    //     "reports": {
    //       "resultSetCount": 2,
    //       "resultSet": [
    //         {
    //           "created_at": '1573456318000',
    //           "description": "This is an optional field",
    //           "last_modified_by": null,
    //           "type": "ADC",
    //           "created_by": null,
    //           "last_modified_at": null,
    //           "providerid": "root",
    //           "tenantid": "ControllerTenant",
    //           "name": "NewReport",
    //           "id": "vyegsxjggdqtyi6rsb00d",
    //           "actions": null,
    //           "workflowid": null,
    //           "occurance": "weekly"
    //         },
    //         {
    //           "created_at": '1573456318000',
    //           "description": "This is an optional field",
    //           "last_modified_by": null,
    //           "type": "ADC",
    //           "created_by": null,
    //           "last_modified_at": null,
    //           "providerid": "root",
    //           "tenantid": "ControllerTenant",
    //           "name": "NewReport",
    //           "id": "ynd9nwxjr1iemyo4w20atr",
    //           "actions": null,
    //           "workflowid": null,
    //           "occurance": "weekly"
    //         },
    //         {
    //           "created_at": '1573456318000',
    //           "description": "This is an optional field",
    //           "last_modified_by": null,
    //           "type": "ADC",
    //           "created_by": null,
    //           "last_modified_at": null,
    //           "providerid": "root",
    //           "tenantid": "ControllerTenant",
    //           "name": "adhocReport",
    //           "id": "ynd9nwxjr1iemyo4w20atr",
    //           "actions": null,
    //           "workflowid": null,
    //           "occurance": "weekly"
    //         },
    //         {
    //           "created_at": '1573456318000',
    //           "description": "This is an optional field",
    //           "last_modified_by": null,
    //           "type": "ADC",
    //           "created_by": null,
    //           "last_modified_at": null,
    //           "providerid": "root",
    //           "tenantid": "ControllerTenant",
    //           "name": "NewReport",
    //           "id": "ynd9nwxjr1iemyo4w20atr",
    //           "actions": null,
    //           "workflowid": null,
    //           "occurance": "weekly"
    //         },
    //         {
    //           "created_at": '1573456318000',
    //           "description": "This is an optional field",
    //           "last_modified_by": null,
    //           "type": "ADC",
    //           "created_by": null,
    //           "last_modified_at": null,
    //           "providerid": "root",
    //           "tenantid": "ControllerTenant",
    //           "name": "AnotherReport",
    //           "id": "ynd9nwxjr1iemyo4w20atr",
    //           "actions": null,
    //           "workflowid": null,
    //           "occurance": "weekly"
    //         }
    //       ],
    //       "totalRecordsCount": 0
    //     }
    //   }
    // }
    // return Promise.resolve(scheduleReports)
  }

  addReportAssociation = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_REPORTS_ASSOCIATION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  createOnDemandReport = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('CREATE_REPORT_ONDEMAND_ASSOCIATION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  createScheduleReport = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('CREATE_REPORT_SCHEDULE_ASSOCIATION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  deleteAdhocReport = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_ADHOC_REPORT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  deleteScheduledReport = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_SCHEDULED_REPORT')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  checkDeleteDependencyTriggerAction = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('CHECK_DELETE_ACTION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getHCEvents = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_HC_EVENTS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }

    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  serializeData = (data: IObject) => {
    return queryString.stringify(data)
  }
  validateLicense = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('VALIDATE_LICENSE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getOfflineLicenseActivationUUID = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('VALIDATE_OFFLINE_LICENSE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  importOfflineLicense = (
    headers: any,
    payload: any,
    urlInput: any,
    update?: boolean,
  ) => {
    const urlObj = this.UrlService.get(
      update ? 'IMPORT_OFFLINE_LICENSE_UPDATE' : 'IMPORT_OFFLINE_LICENSE',
    )
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
  getLicenseInfo = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LICENSE_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
    // const licenseObj = {
    //   allocDate: new Date().toUTCString(),
    //   expDate: new Date().toUTCString(),
    //   mbu: {
    //     totalValue: 6227702580,
    //     usedValue: 104857600,
    //   },
    //   mdu: {
    //     totalValue: 30,
    //     usedValue: 2,
    //   },
    //   mpu: {
    //     totalValue: 20,
    //     usedValue: 5,
    //   },
    // }
    // return Promise.resolve(licenseObj)
  }

  getLicenseRevokedActivations = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    // const urlObj = this.UrlService.get('GET_LICENSE_REVOKED_ACTIVATIONS')
    // if (urlObj.NEEDED_QUERYSTRING) {
    //   urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    // }
    // return this.AjaxService.promisingHttpRequest(
    //   urlObj.URL,
    //   urlObj.METHOD,
    //   headers,
    //   payload,
    //   undefined,
    // )
    const dummyRevokedActivations = {
      data: [
        {
          state: 'InActive',
          device: '00:0C:28:A8:DC:BC (4.1.3-P8, build 113 | VMware)',
          licenseInfo: '500 Mbps vThunder',
          activatedOn: 'Jul 15, 2018 10:53:58 AM UTC',
          lastContact: 'Jul 17, 2018 10:53:58 AM UTC',
        },
        {
          state: 'ACTIVE',
          device: '00:0C:29:A8:DC:BC (4.1.4-P8, build 114 | VMware)',
          licenseInfo: '500 Mbps vThunder',
          activatedOn: 'Aug 15, 2018 10:53:58 AM UTC',
          lastContact: 'Aug 17, 2018 10:53:58 AM UTC',
        },
      ],
    }
    return Promise.resolve(dummyRevokedActivations)
  }
  getLicenseUnrevokedActivations = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    // const urlObj = this.UrlService.get('GET_LICENSE_UNREVOKED_ACTIVATIONS')
    // if (urlObj.NEEDED_QUERYSTRING) {
    //   urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    // }
    // return this.AjaxService.promisingHttpRequest(
    //   urlObj.URL,
    //   urlObj.METHOD,
    //   headers,
    //   payload,
    //   undefined,
    // )
    const dummyUnrevokedActivations = {
      data: [
        {
          state: 'InActive',
          device: '00:0C:26:A8:DC:BC (4.1.1-P8, build 111 | VMware)',
          licenseInfo: '500 Mbps vThunder',
          activatedOn: 'Jul 15, 2018 10:53:58 AM UTC',
          lastContact: 'Jul 17, 2018 10:53:58 AM UTC',
        },
        {
          state: 'ACTIVE',
          device: '00:0C:27:A8:DC:BC (4.1.2-P8, build 112 | VMware)',
          licenseInfo: '500 Mbps vThunder',
          activatedOn: 'Aug 15, 2018 10:53:58 AM UTC',
          lastContact: 'Aug 17, 2018 10:53:58 AM UTC',
        },
      ],
    }
    return Promise.resolve(dummyUnrevokedActivations)
  }
  getLicenseAllocations = (headers: any, payload: any, urlInput: any) => {
    // const urlObj = this.UrlService.get('GET_LICENSE_ALLOCATIONS')
    // if (urlObj.NEEDED_QUERYSTRING) {
    //   urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    // }
    // return this.AjaxService.promisingHttpRequest(
    //   urlObj.URL,
    //   urlObj.METHOD,
    //   headers,
    //   payload,
    //   undefined,
    // )
    const dummyAllocations = {
      data: [
        {
          timestamp: 'Jun 15, 2018 10:53:58 AM UTC',
          reference: 'GLM',
          licenseType: 'MBU',
          details: 'License request made by <username> for <capacity>',
          result: 'Success',
        },
        {
          timestamp: 'Jul 15, 2018 10:53:58 AM UTC',
          reference: 'Thunder',
          licenseType: 'MBU',
          details: 'Device <name> with UUID: <UUID> consumed <capacity>',
          result: 'Success',
        },
        {
          timestamp: 'Aug 15, 2018 10:53:58 AM UTC',
          reference: 'Lightning ADC',
          licenseType: 'MPU',
          details: 'Registration denied. Reason: Capacity not available',
          result: 'Failure',
        },
      ],
    }
    return Promise.resolve(dummyAllocations)
  }
  updateLicense = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ADD_LICENSE_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  deleteLicense = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DEL_LICENSE_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
    )
  }
  userRemove = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('REMOVE_USER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }
  updateThunderAppName = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_THUNDER_APP_NAME')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }
  activeThunderRuleSet = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ACTIVE_RULE_SET')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getLicenseManagerInfo = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LICENSE_MANAGER_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }

  checkLicenseManagerInfo = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('CHECK_LICENSE_MANAGER_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }

  updateLicenseManagerInfo = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_LICENSE_MANAGER_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      null,
      payload,
      undefined,
    )
  }

  getLogProcessingCapacity = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LOG_CAPACITY')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  updateProviderLogRate = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_PROVIDER_LOG_CAPACITY')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  deleteProviderLogRate = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('DELETE_PROVIDER_LOG_CAPACITY')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  updateLogProcessingCapacity = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_LOG_CAPACITY')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getLogRateAnalytics = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LOG_RATE_ANALYTICS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getLicenseManagerDetails = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LICENSE_MANAGER_DETAILS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getLLMUUID = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LLM_UUID')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  activateLLMUUID = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('ACTIVATE_LLM_UUID')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getLLMStatus = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LLM_STATUS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getLMLicenses = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LM_LICENSES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getLMRevokedLicenses = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LM_REVOKED_LICENSES')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  importLLMLicense = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('IMPORT_LLM_LICENSE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getLLMLicenseUsage = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LLM_LICENSE_USAGE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getLLMLicenseActivations = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LLM_LICENSE_ACTIVATIONS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getLLMLicenseActivationInfo = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LLM_LICENSE_ACTIVATION_INFO')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getLLMLicenseActivationUsage = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('GET_LLM_ACTIVATION_USAGE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getLLMLicenseRevokedActivations = (
    headers: any,
    payload: any,
    urlInput: any,
  ) => {
    const urlObj = this.UrlService.get('GET_LLM_LICENSE_REVOKED_ACTIVATIONS')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  revokeLLMLicense = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('REVOKE_LLM_LICENSE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  revokeLLMActivation = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('REVOKE_LLM_ACTIVATION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  unrevokeLLMActivation = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UNREVOKE_LLM_ACTIVATION')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  updateLLMLicense = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_LLM_LICENSE')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getAllAppSvcForProvider = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('PROVIDER_APP_SVC_LIST')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  updatedEmailServerData = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('UPDATE_EMAIL_SERVER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  sendEmail = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('SEND_EMAIL')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  sendEmailServer = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('RESET_EMAIL_SERVER')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getUserPrefs = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('GET_USER_PREF_URL')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }


  saveUserPrefs = (
    headers: IObject | null,
    payload: IObject | string,
    urlInput: string[],
  ) => {
    const urlObj = this.UrlService.get('POST_USER_PREF_URL')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }

  getLogicalPartitionListSummary = (headers: any, payload: any, urlInput: any) => {
    const urlObj = this.UrlService.get('GET_LOGICAL_PARTITIONS_SUMMARY')
    if (urlObj.NEEDED_QUERYSTRING) {
      urlObj.URL = this.UrlService.formatURL(urlInput, urlObj.URL)
    }
    return this.AjaxService.promisingHttpRequest(
      urlObj.URL,
      urlObj.METHOD,
      headers,
      payload,
      undefined,
    )
  }
}

export default DashboardService
