export class ApplicationConfigs {
  APPLICATIONS = [
    {
      name: 'Notification',
      displayName: 'Alert Notification',
      icon: '',
      state: 'disabled',
      allowedRoles: ['provider', 'tenant'],
      providerMode: true,
      superUserMode: true,
      noSubmenu: true,
      visible: false,
    },
    {
      name: 'home',
      displayName: 'Home',
      icon: 'home',
      state: 'disabled',
      allowedRoles: ['provider', 'tenant', 'appadmin'],
      selected: true,
      isExternal: false,
      visible: true,
      defaultProviderContext: 'Home',
      defaultTenantContext: 'Home',
      providerMode: true,
      superUserMode: false,
    },
    {
      name: 'Apps',
      displayName: 'Harmony Apps',
      icon: 'apps',
      state: 'disabled',
      subApps: [
        {
          name: 'MyApps',
          displayName: 'App Catalog',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider', 'tenant', 'appadmin'],
          allowedLevels: ['tenant'],
          tenantContext: true,
          selected: true,
          isExternal: false,
        },
        {
          name: 'ProvidersAppCatalog',
          displayName: 'App Catalog',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          allowedLevels: ['provider'],
          selected: false,
          isExternal: true,
          separator: true,
        },
        // {
        //   name: 'InstalledApps',
        //   displayName: 'Manage Apps',
        //   icon: 'manage-apps-icon',
        //   state: 'enabled',
        //   allowedRoles: ['provider', 'tenant'],
        //   allowedLevels: ['provider', 'tenant'],
        //   selected: false,
        //   isExternal: true,
        // },
      ],
      allowedRoles: ['provider', 'tenant', 'appadmin'],
      providerMode: true,
      superUserMode: false,
      infraUserMode: 'no',
      visible: true,
      moreApps: true,
    },
    {
      name: 'Analytics',
      displayName: 'Analytics',
      icon: 'analytics',
      state: 'enabled',
      subApps: [
        {
          name: 'Cluster',
          displayName: 'Cluster',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
          clusterDeviceDropdown: 'cluster',
        },
        {
          name: 'Device',
          displayName: 'Device',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
          clusterDeviceDropdown: 'device',
        },
        // {
        //   name: 'PartitionResource',
        //   displayName: 'Partition Resource',
        //   icon: '',
        //   state: 'enabled',
        //   allowedRoles: ['provider', 'tenant', 'appadmin'],
        //   selected: true,
        //   isExternal: false,
        // },
        // {
        //   name: 'InterfaceAnalytics',
        //   displayName: 'Interface Analytics',
        //   icon: '',
        //   state: 'enabled',
        //   allowedRoles: ['provider', 'tenant', 'appadmin'],
        //   selected: true,
        //   isExternal: false,
        // },
      ],
      allowedRoles: ['provider'],
      defaultProviderContext: 'Dashboard',
      defaultTenantContext: 'Dashboard',
      providerMode: true,
      superUserMode: false,
      visible: true,
      moreApps: true,
    },
    {
      name: 'Services',
      displayName: 'Services',
      icon: 'service',
      state: 'enabled',
      subApps: [
        {
          name: 'AppServices',
          displayName: 'App Services',
          keyValue: 'appServices',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider', 'tenant', 'appadmin'],
          tenantContext: true,
          selected: true,
          isExternal: false,
        },
        // {
        //   name: 'Applications',
        //   displayName: 'Applications',
        //   icon: '',
        //   state: 'enabled',
        //   allowedRoles: ['provider', 'tenant', 'appadmin'],
        //   tenantContext: true,
        //   selected: true,
        //   isExternal: false,
        // },
        {
          name: 'LogicalPartitions',
          displayName: 'Logical Partitions',
          // keyValue: 'logicalPartition',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider', 'tenant', 'appadmin'],
          tenantContext: true,
          selected: true,
          isExternal: false,
        },
        // {
        //   name: 'SharedResources',
        //   displayName: 'Shared Resources',
        //   icon: '',
        //   state: 'enabled',
        //   allowedRoles: ['provider', 'tenant'],
        //   allowedLevels: ['provider', 'tenant'],
        //   tenantContext: true,
        //   selected: true,
        //   isExternal: false,
        // },
      ],
      allowedRoles: ['provider', 'tenant', 'appadmin'],
      providerMode: false,
      superUserMode: false,
      infraUserMode: 'no',
      visible: true,
    },
    {
      name: 'Infrastructure',
      displayName: 'Infrastructure',
      icon: 'infrastructure',
      state: 'disabled',
      subApps: [
        {
          name: 'Clusters',
          displayName: 'Clusters',
          // icon: 'cluster-icon',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
          certUserMode: 'no',
        },
        {
          name: 'Devices',
          displayName: 'Devices',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
          certUserMode: 'no',
        },
        {
          name: 'Orchestration',
          displayName: 'Orchestration',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
          certUserMode: 'no',
        },
        // GUI-4766
        // hide this menu due to not supported by new HCMS
        // {
        //   name: 'Network',
        //   displayName: 'Network',
        //   icon: '',
        //   state: 'enabled',
        //   allowedRoles: ['provider'],
        //   allowedLevels: ['provider'],
        //   selected: false,
        //   isExternal: false,
        //   certUserMode: 'no',
        // },
        {
          name: 'SharedResources',
          displayName: 'Shared Resources',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: false,
          isExternal: false,
        },
      ],
      allowedRoles: ['provider'],
      providerMode: true,
      superUserMode: false,
      visible: true,
    },
    {
      name: 'Utilities',
      displayName: 'Utilities',
      icon: 'utility',
      state: 'enabled',
      operatorUserMode: 'no',
      subApps: [
        {
          name: 'Devicecli',
          displayName: 'Device CLI',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider', 'tenant'],
          operatorUserMode: 'no',
          selected: true,
          isExternal: false,
        },
        {
          name: 'ScaleoutTraffic',
          displayName: 'Scaleout Traffic Map',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
          clusterDeviceDropdown: 'cluster',
          certUserMode: 'no',
        },
        // {
        //   name: 'VRRPAUtility',
        //   displayName: 'VRRP-A Utility',
        //   icon: '',
        //   state: 'enabled',
        //   allowedRoles: ['provider'],
        //   selected: true,
        //   isExternal: false,
        // },
        {
          name: 'DeviceBackup',
          displayName: 'Device Backup',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
          certUserMode: 'no',
        },
        {
          name: 'ImageUpgrade',
          displayName: 'Image Upgrade',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
          certUserMode: 'no',
        },
        // {
        //   name: 'PackageCapture',
        //   displayName: 'Package Capture',
        //   icon: '',
        //   state: 'enabled',
        //   allowedRoles: ['provider'],
        //   selected: true,
        //   isExternal: false,
        // },
        {
          name: 'ObjectExplorer',
          displayName: 'Object Explorer',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          operatorUserMode: 'no',
          tenantContext: true,
          selected: true,
          isExternal: false,
          certUserMode: 'no',
        },
        // {
        //   name: 'MLConfiguration',
        //   displayName: 'ML Configuration',
        //   icon: '',
        //   state: 'enabled',
        //   allowedRoles: ['provider'],
        //   selected: true,
        //   isExternal: false,
        // },
      ],
      allowedRoles: ['provider', 'tenant'],
      providerMode: true,
      defaultProviderContext: 'Devicecli',
      superUserMode: false,
      visible: true,
      moreApps: true,
    },
    {
      name: 'Monitor',
      displayName: 'Monitor',
      icon: 'monitor',
      state: 'disabled',
      subApps: [
        {
          name: 'Alert',
          displayName: 'Alerts',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider', 'tenant'],
          allowedLevels: ['provider', 'tenant'],
          selected: true,
          isExternal: false,
          certUserMode: 'no',
        },
        {
          name: 'Report',
          displayName: 'Reports',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider', 'tenant'],
          tenantContext: true,
          selected: true,
          isExternal: false,
          separator: true,
          certUserMode: 'no',
        },
        {
          name: 'Workflow',
          displayName: 'Workflows',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider', 'tenant'],
          selected: true,
          isExternal: false,
          partitionAdminMode: 'no',
        },
        {
          name: 'Event',
          displayName: 'Events',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider', 'tenant'],
          selected: true,
          isExternal: false,
          partitionAdminMode: 'no',
        },
        {
          name: 'Audit',
          displayName: 'Audits',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
          partitionAdminMode: 'no',
        },
        // {
        //   name: 'ScheduledJobs',
        //   displayName: 'Scheduled Job',
        //   icon: '',
        //   state: 'enabled',
        //   allowedRoles: ['provider', 'tenant'],
        //   tenantContext: true,
        //   selected: true,
        //   isExternal: false,
        //   overrideClass: 'wide-icon',
        // },
        // {
        //   name: 'FaultManagement',
        //   displayName: 'Fault Management',
        //   icon: '',
        //   state: 'enabled',
        //   allowedRoles: ['provider', 'tenant'],
        //   tenantContext: true,
        //   selected: true,
        //   isExternal: false,
        // },
      ],
      allowedRoles: ['provider', 'tenant', 'appadmin'],
      providerMode: false,
      // defaultProviderContext: 'Alert',
      // defaultTenantContext: 'Alert',
      superUserMode: false,
      visible: true,
      moreApps: true,
    },
    {
      name: 'Organization',
      displayName: 'Organization',
      icon: 'organization',
      state: 'disabled',
      subApps: [
        {
          name: 'Tenants',
          displayName: 'Tenants',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: false,
          isExternal: false,
        },
        {
          name: 'Users',
          displayName: 'Users',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: false,
          isExternal: false,
        },
        {
          name: 'AccessGroups',
          displayName: 'Access Groups',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: false,
          isExternal: false,
          pseudoProviderMode: 'no',
        },
        {
          name: 'Roles',
          displayName: 'Roles',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: false,
          isExternal: false,
          pseudoProviderMode: 'no',
        },
        {
          name: 'License',
          displayName: 'Licenses',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
          separator: true,
        },
        {
          name: 'Authentication',
          displayName: 'User Auth',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
          separator: true,
        },
        {
          name: 'Agreements',
          displayName: 'Agreements',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
          separator: true,
        },
      ],
      allowedRoles: ['provider'],
      providerMode: true,
      superUserMode: false,
      certUserMode: 'no',
      infraUserMode: 'no',
      visible: true,
      moreApps: true,
    },
    {
      name: 'Settings',
      displayName: 'Settings',
      icon: 'settings-icon',
      state: 'enabled',
      allowedRoles: ['provider', 'tenant'],
      providerMode: true,
      superUserMode: true,
      noSubmenu: true,
      visible: false,
    },
    //SuperAdmin menu
    {
      name: 'superAdminDashboard',
      displayName: 'Dashboard',
      icon: 'dashboard',
      state: 'disabled',
      subApps: [
        {
          name: 'Platform',
          displayName: 'Platform',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
        },
        {
          name: 'Resource',
          displayName: 'Resource',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
        },
      ],
      allowedRoles: ['provider'],
      providerMode: false,
      superUserMode: true,
      visible: true,
    },
    {
      name: 'Providers',
      displayName: 'Providers',
      icon: 'provider',
      state: 'disabled',
      subApps: [],
      allowedRoles: ['provider'],
      providerMode: false,
      superUserMode: true,
      visible: true,
    },
    {
      name: 'Platform',
      displayName: 'Platform',
      icon: 'service',
      state: 'disabled',
      subApps: [
        {
          name: 'LicenseManagement',
          displayName: 'License Management',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
        },
        {
          name: 'AppCatalog',
          displayName: 'App Catalog',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
        },
        {
          name: 'ImageUpgrade',
          displayName: 'Image Catalog',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
        },
        {
          name: 'MicroServiceConfig',
          displayName: 'Microservice Config',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
        },
      ],
      allowedRoles: ['provider'],
      providerMode: false,
      superUserMode: true,
      visible: true,
    },
    {
      name: 'System',
      displayName: 'System',
      icon: ['goe', 'system'],
      state: 'disabled',
      subApps: [],
      allowedRoles: ['provider'],
      providerMode: false,
      superUserMode: true,
      visible: true,
    },
    {
      name: 'SuperAdmin',
      displayName: 'Admin User',
      icon: 'user-role',
      state: 'disabled',
      subApps: [],
      allowedRoles: ['provider'],
      providerMode: false,
      superUserMode: true,
      visible: true,
    },
    {
      name: 'Troubleshooting',
      displayName: 'Troubleshooting',
      icon: 'utility',
      state: 'disabled',
      subApps: [
        {
          name: 'MicroserviceLogs',
          displayName: 'Microservice Logs',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
        },
        {
          name: 'RunCommands',
          displayName: 'Run Command',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
        },
      ],
      allowedRoles: ['provider'],
      providerMode: false,
      superUserMode: true,
      visible: true,
    },
    {
      name: 'Monitoring',
      displayName: 'Monitoring',
      icon: 'monitor',
      state: 'disabled',
      subApps: [
        {
          name: 'Grafana',
          displayName: 'Grafana',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
        },
        {
          name: 'Prometheus',
          displayName: 'Prometheus',
          icon: '',
          state: 'enabled',
          allowedRoles: ['provider'],
          selected: true,
          isExternal: false,
        },
      ],
      allowedRoles: ['provider'],
      providerMode: false,
      superUserMode: true,
      visible: true,
    },
    // {
    //   name: 'LicenseManager',
    //   displayName: 'License Manager',
    //   icon: 'license-manager',
    //   state: 'disabled',
    //   subApps: [],
    //   allowedRoles: ['provider'],
    //   providerMode: false,
    //   superUserMode: true,
    //   visible: true,
    // },
    // {
    //   name: 'LogProcessing',
    //   displayName: 'Log Processing',
    //   icon: 'scorecard',
    //   state: 'disabled',
    //   subApps: [],
    //   allowedRoles: ['provider'],
    //   providerMode: false,
    //   superUserMode: true,
    //   visible: true,
    // },
    // {
    //   name: 'AppCatalog',
    //   displayName: 'App Catalog',
    //   icon: 'apps-catalog',
    //   state: 'disabled',
    //   subApps: [],
    //   allowedRoles: ['provider'],
    //   providerMode: false,
    //   superUserMode: true,
    //   visible: true,
    // },
  ]

  getAllowedRoles = (appname: string) => {
    const appIndex = this.APPLICATIONS.findIndex((key: any) => {
      return key.name === appname
    })
    if (appIndex >= 0) {
      return this.APPLICATIONS[appIndex].allowedRoles
    } else {
      return null
    }
  }
  getAllowedRolesSubApps = (parentApp: any, subApp: any) => {
    const applications = this.APPLICATIONS
    const appIndex = applications.findIndex((key: any) => {
      return key.name === parentApp
    })
    const subApps: any = applications[appIndex].subApps
    const subAppIndex = subApps.findIndex((key: any) => {
      return key.name === subApp
    })
    if (subAppIndex >= 0) {
      return subApps[subAppIndex].allowedRoles
    } else {
      return null
    }
  }
  getAllowedLevelsSubApps = (parentApp: any, subApp: any) => {
    const applications = this.APPLICATIONS
    const appIndex = applications.findIndex((key: any) => {
      return key.name === parentApp
    })
    const subApps: any = applications[appIndex].subApps
    const subAppIndex = subApps.findIndex((key: any) => {
      return key.name === subApp
    })

    if (subAppIndex >= 0) {
      return subApps[subAppIndex].allowedLevels
    } else {
      return null
    }
  }
  getAvailableApps = () => {
    return this.APPLICATIONS
  }
  getApplicationByName = (name: string) => {
    return this.APPLICATIONS.find(application => {
      return application.name.toLowerCase() === name.toLowerCase()
    })
  }
  getMoreApps = () => {
    const moreApplications: any = []
    this.APPLICATIONS.map((obj: any) => {
      if (obj.moreApps) {
        obj.visible = true
        moreApplications.push(JSON.parse(JSON.stringify(obj)))
      }
    })
    return moreApplications
  }
  getAppSubappObjects = (appName: string, subappName?: string) => {
    const appObj =
      this.APPLICATIONS.find((app: any) => {
        return app.name.toLowerCase() === appName.toLowerCase()
      }) || ({} as IObject)
    let subAppObj = null
    if (!!appObj && !!subappName) {
      subAppObj =
        appObj.subApps &&
        appObj.subApps.find((subApp: any) => {
          return subApp.name.toLowerCase() === subappName.toLowerCase()
        })
    }
    return {
      appDName: appObj.displayName || appObj.name,
      subappDName:
        subAppObj && subappName
          ? subAppObj.displayName || subAppObj.name
          : subappName,
      appObj,
      subAppObj,
    }
  }
  getLicenseBarChartConfig = () => {
    const chartConfig = {
      title: {
        text: '',
        align: 'left',
        margin: 0,
      },
      chart: {
        renderTo: 'container',
        type: 'bar',
        height: 50,
      },
      credits: false,
      tooltip: false,
      legend: false,
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
        min: 0,
        max: 100,
      },
      series: [
        {
          data: [100],
          grouping: false,
          // animation: false,
          // enableMouseTracking: false,
          showInLegend: false,
          color: 'gainsboro',
          pointWidth: 25,
          borderWidth: 0,
          dataLabels: {
            format: '100',
            enabled: true,
            style: {
              color: 'black',
            },
          },
        },
        {
          data: [25],
          // animation: false,
          // enableMouseTracking: false,
          color: '#0081d6',
          borderWidth: 0,
          pointWidth: 25,
          dataLabels: {
            enabled: true,
            inside: true,
            align: 'right',
            // format: '{point.y}%',
            format: '25',
            style: {
              color: 'white',
            },
          },
        },
      ],
    }
    return chartConfig
  }
  getLicenseGaugeChartConfig = () => {
    const chartConfig = {
      yAxis: {
        min: 0,
        max: 100,
        labels: {
          format: '{value}%',
          y: -50,
        },
      },
      title: {
        text: '', // `${usedValue + ' ' + usedUnits}`,
        align: 'center',
        verticalAlign: 'middle',
        y: 20,
        style: { color: '#999999', fontSize: '16px' },
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>',
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Usage',
          innerSize: '50%',
          data: [
            {
              name: 'Used',
              y: 0,
              color: 'green',
            },
            {
              name: 'Free',
              y: 0,
              color: '#cccccc',
            },
          ],
        },
      ],
    }
    return chartConfig
  }
}
export default ApplicationConfigs
