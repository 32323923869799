import React, { useState, useEffect, useCallback } from 'react'
import ReactLoading from 'react-loading'
import { _ } from '@gui-libraries/framework'
import {
  A10Collapse,
  A10Icon,
  A10Row,
  A10Table,
  A10DropdownMenu,
  A10SlidingPage,
  A10Modal,
  A10Button,
  A10Notification,
  A10Checkbox,
} from '@gui-libraries/widgets'
import { AutoFormV15 } from '@gui-libraries/apps'

import RoundNumber from 'src/components/shared/RoundNumber'
import { InfrastructureService } from 'src/services/index'
import storage from 'src/libraries/storage'

const NetworkStaticRoutesRIBIPv6: React.FC = (props: IObject) => {
  const [showSlidingPage, setShowSlidingPage] = useState(false)
  const [address, setAddress] = useState('')
  const infrastructureService = new InfrastructureService()
  const [ribList, setRibList] = useState([] as IObject[])
  const [start, setStart] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [destAddrs, setDestAddrs] = useState(0)
  const [forceDelete, setForceDelete] = useState(false)
  const [ribTotal, setRibTotal] = useState(0)
  const { selectedPartition = 'shared' } = props

  const updateTotal = () => {
    const { deviceObj, service } = props
    const provider = deviceObj['provider-name']
    const deviceName = deviceObj['name']

    Promise.all([
      service.getRequest(
        null,
        null,
        [provider, deviceName, selectedPartition, '?total=true'],
        'GET_DEVICE_IPV6_RIB',
      ),
    ])
      .then((response: IObject[]) => {
        try {
          setRibTotal(_.get(response[0], 'data.total-count', 0))
        } catch (e) {
          console.log(e)
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const getData = () => {
    setIsLoading(true)
    const provider = storage.get.PROVIDER
    infrastructureService
      .getRequest(
        null,
        null,
        [provider, props.deviceObj.name, selectedPartition, ''],
        'GET_DEVICE_IPV6_RIB',
      )
      .then((res: IObject) => {
        try {
          const ribs = [] as IObject[]
          setDestAddrs(_.get(res, 'data.rib-list', []).length)
          _.get(res, 'data.rib-list', []).forEach((rib: IObject) => {
            _.get(rib, 'ipv6-nexthop-ipv6', []).forEach((nexthop: IObject) => {
              let inteText = ''
              if ('ethernet' in nexthop) {
                inteText = `Ethernet ${nexthop.ethernet}`
              } else if ('trunk' in nexthop) {
                inteText = `Trunk ${nexthop.trunk}`
              } else if ('ve' in nexthop) {
                inteText = `Ve ${nexthop.ve}`
              }
              ribs.push({
                'ipv6-address': rib['ipv6-address'],
                interface: inteText,
                nexthop: nexthop['ipv6-nexthop'],
                distance: nexthop.distance,
                description: nexthop.description,
              })
            })
            _.get(rib, 'ipv6-nexthop-tunnel', []).forEach(
              (nexthop: IObject) => {
                ribs.push({
                  'ipv6-address': rib['ipv6-address'],
                  interface: `Tunnel ${nexthop.tunnel}`,
                  nexthop: _.get(nexthop, 'ipv6-nexthop-tunnel-addr', ''),
                  distance: _.get(nexthop, 'distance-nexthop-tunnel', ''),
                  description: _.get(nexthop, 'description', ''),
                })
              },
            )
          })

          setRibList(ribs)
          setIsLoading(false)
        } catch (e) {
          console.log(e)
          setIsLoading(false)
        }
      })
  }
  useEffect(() => {
    updateTotal()
    getData()
  }, [start, props.deviceObj.name, props.refreshTimer, selectedPartition])
  const columns = React.useMemo(
    () => [
      {
        title: 'Address',
        dataIndex: 'ipv6-address',
        sorter: (a: IObject, b: IObject) => {
          return a['ipv6-address'] > b['ipv6-address'] ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Interface',
        dataIndex: 'interface',
        sorter: (a: IObject, b: IObject) => {
          return a.interface > b.interface ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Nexthop IP',
        dataIndex: 'nexthop',
        sorter: (a: IObject, b: IObject) => {
          return a.nexthop > b.nexthop ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Distance',
        dataIndex: 'distance',
        sorter: (a: IObject, b: IObject) => {
          return a.distance > b.distance ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        sorter: (a: IObject, b: IObject) => {
          return a.description > b.description ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        dataIndex: 'actions',
        width: 30,
        render: (text: any, record: any) => {
          return (
            !storage.get.IS_OPERATOR_USER && (
              <A10DropdownMenu
                title=""
                menu={[
                  <div
                    key="edit"
                    onClick={handleEdit.bind(this, record['ipv6-address'])}
                  >
                    Edit
                  </div>,
                  <div
                    key="edit"
                    onClick={handleDelete.bind(this, record['ipv6-address'])}
                  >
                    Delete
                  </div>,
                ]}
                style={{ color: '#757575', marginBottom: '-15px' }}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            )
          )
        },
      },
    ],
    [],
  )

  const toggleSlidingPage = (isOpen: boolean) => {
    setShowSlidingPage(isOpen)
  }

  const handleAdd = (event: React.MouseEvent<HTMLElement>) => {
    toggleSlidingPage(true)
    setAddress('')
    event.stopPropagation()
  }

  const handleEdit = (dest: string) => {
    setAddress(dest)
    setShowSlidingPage(true)
  }

  const handleDelete = (dest: string) => {
    setForceDelete(false)
    setAddress(dest)
    toggleModal(true)
  }

  const handleSuccess = useCallback(() => {
    if (!address) {
      updateTotal()
    } else {
      getData()
    }
    toggleSlidingPage(false)
  }, [address])

  const handlePageChange = (page: number, pageSize: number) => {
    setStart((page - 1) * pageSize)
  }

  const toggleModal = (isOpen: boolean) => {
    setShowModal(isOpen)
  }

  const handleOk = useCallback(() => {
    const provider = storage.get.PROVIDER
    infrastructureService
      .getRequest(
        null,
        null,
        [
          provider,
          props.deviceObj.name,
          selectedPartition,
          encodeURIComponent(address),
          forceDelete ? '&force=true' : '',
        ],
        'DELETE_DEVICE_IPV6_RIB',
      )
      .then((res: IObject) => {
        updateTotal()
        toggleModal(false)
        A10Notification.success({
          message: 'Success!',
          description: `Delete IPv6 RIB static route ${address} successfully.`,
          style: {
            wordWrap: 'break-word',
          },
        })
      })
      .catch((err: IObject) => {
        toggleModal(false)
        A10Notification.error({
          message: 'Error!',
          description: `Fail to delete IPv6 RIB static route ${address}. ${_.get(
            err,
            ['response', 'data', 'message', 'logs', 0],
            '',
          )}`,
          style: {
            wordWrap: 'break-word',
          },
        })
      })
  }, [address, props.deviceObj.name, forceDelete])

  const handleForceDeleteChange = (e: any) => {
    setForceDelete(e.target.checked)
  }

  const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/device/${props.deviceObj.name}/partition/${selectedPartition}`

  return (
    <div className="col-md-12 marginBottom10">
      <A10Modal
        visible={showModal}
        title="Confirmation"
        onOk={handleOk}
        onCancel={toggleModal.bind(this, false)}
        footer={[
          <A10Button
            key="no"
            type="primary"
            onClick={toggleModal.bind(this, false)}
            className="nobtn"
          >
            No
          </A10Button>,
          <A10Button
            key="yes"
            type="default"
            onClick={handleOk}
            className="yesbtn"
          >
            Yes
          </A10Button>,
        ]}
      >
        <p>Are you sure you want to delete the selected item?</p>
        <A10Checkbox onChange={handleForceDeleteChange} checked={forceDelete}>
          Force Delete
        </A10Checkbox>
      </A10Modal>
      <A10SlidingPage
        isOpen={showSlidingPage}
        modalSize="large"
        onRequestClose={toggleSlidingPage.bind(this, false)}
      >
        <AutoFormV15
          hccEnv={true}
          schemaPath="network/routes/ipv6-static-routes/ipv6-rib"
          apiPrefix={apiPrefix}
          params={{
            'ipv6-address': address,
          }}
          enableSOP={true}
          interceptor={{
            onSubmitSuccess: handleSuccess,
            onCancel: toggleSlidingPage.bind(this, false),
          }}
          ENV_CONSTS={{
            IS_SHARED_PARTITION: selectedPartition.toLowerCase() === 'shared',
            PARTITION_TYPE:
              selectedPartition.toLowerCase() === 'shared' ? 'SHARED' : 'L3V',
          }}
        />
      </A10SlidingPage>
      <A10Collapse>
        <A10Collapse.Panel
          key={0}
          header={
            <>
              {!storage.get.IS_OPERATOR_USER && (
                <span className="edit-content" onClick={handleAdd}>
                  <A10Icon app="global" type="add-new" /> Add
                </span>
              )}
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 fontWeight300 width260">
                  Static Routes RIB-IPv6 <RoundNumber number={ribTotal} />
                </span>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Dest Address</div>
                  <div className="pad-s-15 fontWeight300">{destAddrs}</div>
                </div>
              </A10Row>
            </>
          }
          className="collapsePanel"
        >
          <A10Table
            columns={columns}
            dataSource={(ribList || []).slice(start, start + 10)}
            size="small"
            pagination={{
              hideOnSinglePage: true,
              pageSize: 10,
              total: ribTotal,
              onChange: handlePageChange,
            }}
            bordered={false}
            loading={
              isLoading
                ? {
                    indicator: (
                      <div className="loading-icon">
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
          />
        </A10Collapse.Panel>
      </A10Collapse>
    </div>
  )
}

export default NetworkStaticRoutesRIBIPv6
