import React from 'react'
import { A10Container, setupA10Container } from '@gui-libraries/framework'
import {
  A10Form,
  A10Input,
  A10Icon,
  A10Button,
  A10Upload,
} from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import storage from 'src/libraries/storage'

export interface IFormData {
  name: string
  version: string
  type: string
  description: string
  path?: string
  host?: string
  username?: string
  password?: string
  file?: IObject
}

interface IUploadFormProps {
  onChange?: (data: IFormData) => void
  onRef?: any
}

interface IUploadFormStates {
  provider: string
  formData: IFormData
  fileList: string[]
}

class UploadForm extends A10Container<IUploadFormProps, IUploadFormStates> {
  constructor(props: IUploadFormProps) {
    super(props)
    this.state = {
      provider: storage.get.PROVIDER,
      formData: {
        name: null,
        version: null,
        type: 'local',
        description: null,
        path: null,
        host: null,
        username: null,
        password: null,
        file: null,
      },
      fileList: [],
    }
  }

  onChangeName = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const state = { ...this.state }
    const { formData } = this.state
    formData.name = e.currentTarget.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onChangeType = (e: React.MouseEvent<any>) => {
    const state = { ...this.state }
    const { formData } = this.state
    formData.type = e.target.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onChangeVersion = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const state = { ...this.state }
    const { formData } = this.state
    formData.version = e.currentTarget.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onChangeDescription = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const state = { ...this.state }
    const { formData } = this.state
    formData.description = e.currentTarget.value
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onChangeFile = ({ file, fileList }) => {
    const state = { ...this.state }
    const { formData } = this.state
    formData.file = file
    state.fileList = fileList.slice(-1)
    this.onChangeCallback(state.formData)
    this.setState(state)
  }

  onChangeCallback(formData: IFormData) {
    this.props.onChange(formData)
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }

    const { getFieldDecorator } = this.props.form

    return (
      <A10Form layout="horizontal">
        <A10Panel showHeader={false}>
          <A10Form.Item {...formItemLayout} label="ACOS Version">
            {getFieldDecorator('version', {
              rules: [{ required: true, message: 'Please fill in this field' }],
            })(
              <A10Input
                value={this.state.formData.version}
                onChange={this.onChangeVersion}
              />,
            )}
          </A10Form.Item>
          <A10Form.Item {...formItemLayout} label="ACOS Image">
            {getFieldDecorator('ImageFile', {
              rules: [
                { required: true, message: 'Please upload a Device Image' },
              ],
            })(
              <A10Upload
                onChange={this.onChangeFile}
                fileList={this.state.fileList}
                beforeUpload={() => false}
              >
                <A10Button>
                  <A10Icon type="upload" /> Click to Select
                </A10Button>
              </A10Upload>,
            )}
          </A10Form.Item>
          <A10Form.Item {...formItemLayout} label="Description">
            <A10Input
              placeholder="Enter description about this image"
              maxLength={128}
              onChange={this.onChangeDescription}
            />
          </A10Form.Item>
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(UploadForm))
