import React from 'react'
import { A10Component, _ } from '@gui-libraries/framework'
import { A10Row, A10Col } from '@gui-libraries/widgets'

// tslint:disable:no-var-requires
const CodeMirror = require('react-codemirror')
require('codemirror/mode/javascript/javascript')
require('codemirror/lib/codemirror.css')

import './styles/workflowdetailsinfor.scss'

export interface IWorkflowDetailsInforProps {
  startTime: number
  endTime: number
  message: string
  inputObj: IObject
}

export interface IWorkflowDetailsInforState {}

class WorkflowDetailsInfor extends A10Component<
  IWorkflowDetailsInforProps,
  IWorkflowDetailsInforState
> {
  static defaultProps: IWorkflowDetailsInforProps = {
    startTime: null,
    endTime: null,
    message: null,
    inputObj: null,
  }

  getFormatTimeText = (timestamp: number) => {
    const date = new Date(timestamp)

    const hour = date.getHours()
    const hourText = hour < 10 ? `0${hour}` : `${hour}`
    const minute = date.getMinutes()
    const minuteText = minute < 10 ? `0${minute}` : `${minute}`
    const second = date.getSeconds()
    const secondText = second < 10 ? `0${second}` : `${second}`

    if (hour === 0) {
      return `${12}:${minuteText}:${secondText} PM`
    } else if (hour > 0 && hour < 13) {
      return `${hourText}:${minuteText}:${secondText} AM`
    } else {
      return `${
        hour - 12 < 10 ? `0${hour - 12}` : `${hour - 12}`
      }:${minuteText}:${secondText} PM`
    }
  }

  getFormatDateText = (timestamp: number) => {
    const date = new Date(timestamp)

    const month = date.getMonth()
    const monthText = month + 1 < 10 ? `0${month + 1}` : `${month + 1}`
    const day = date.getDate()
    const dayText = day < 10 ? `0${day}` : `${day}`
    const year = date.getFullYear()

    return `${monthText}-${dayText}-${year}`
  }

  getCLIfromStatusMessage = (message: string) => {
    const matchBeginOfCLI = /\-*a10\-axapi\-([\d]+)[\r\n]*Content\-Type\:[\s]*text\/plain[\r\n]*/

    let restString = message
    const arrayOfCLI: string[] = []
    while (_.isString(restString) && restString.length > 0) {
      const result = restString.match(matchBeginOfCLI)
      if (result) {
        const matchedString = result[0]
        const matchedNumber = result[1]
        const beginIndex = result.index

        const matchEndOfCLI = `------a10-axapi-${matchedNumber}--`

        const beginOfCLI = beginIndex + matchedString.length
        const endOfCLI = restString.lastIndexOf(matchEndOfCLI)

        arrayOfCLI.push(restString.slice(beginOfCLI, endOfCLI))
        restString = restString.slice(endOfCLI)
        continue
      } else {
        break
      }
    }
    return arrayOfCLI.join('')
  }

  render() {
    const { startTime, endTime, message, inputObj } = this.props

    const startDateTime: string =
      _.isFinite(startTime) && startTime !== 0
        ? this.getFormatDateText(startTime) +
          ', ' +
          this.getFormatTimeText(startTime)
        : ''
    const endDateTime: string =
      _.isFinite(endTime) && endTime !== 0
        ? this.getFormatDateText(endTime) +
          ', ' +
          this.getFormatTimeText(endTime)
        : ''

    const statusCLI = this.getCLIfromStatusMessage(message)

    return (
      <div className="workflow-pullup-detail">
        <A10Row className="detail-row">
          <A10Col span={3}>
            <span className="infor-label">Status Message</span>
          </A10Col>
          <A10Col span={21}>
            <p className="infor-text message">{message || ''}</p>
          </A10Col>
        </A10Row>
        {statusCLI ? (
          <A10Row className="detail-row">
            <A10Col span={3}>
              <span className="infor-label">Status CLI</span>
            </A10Col>
            <A10Col span={21}>
              <p className="infor-text cli">{statusCLI || ''}</p>
            </A10Col>
          </A10Row>
        ) : null}
        <A10Row className="detail-row">
          <A10Col span={3}>
            <span className="infor-label">Start Date Time</span>
          </A10Col>
          <A10Col span={5}>
            <span className="infor-text">{startDateTime}</span>
          </A10Col>
          <A10Col span={3}>
            <span className="infor-label">End Date Time</span>
          </A10Col>
          <A10Col span={5}>
            <span className="infor-text">{endDateTime}</span>
          </A10Col>
        </A10Row>
        {!_.isNil(inputObj) ? (
          <A10Row className="detail-row">
            <A10Col span={3}>
              <span className="infor-label">Workflow Input</span>
            </A10Col>
            <A10Col span={21} className="json-code">
              <CodeMirror
                value={JSON.stringify(inputObj, null, '\t')}
                options={{
                  mode: 'javascript',
                  lineNumbers: true,
                  readOnly: true,
                }}
              />
            </A10Col>
          </A10Row>
        ) : null}
      </div>
    )
  }
}

export default WorkflowDetailsInfor
