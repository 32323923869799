import React, { useState, useEffect } from 'react'
import ReactLoading from 'react-loading'
import { _ } from '@gui-libraries/framework'
import {
  A10Collapse,
  A10Icon,
  A10Row,
  A10Table,
  A10DropdownMenu,
  A10SlidingPage,
  A10Modal,
  A10Button,
  A10Notification,
  A10Checkbox,
} from '@gui-libraries/widgets'
import { AutoFormV15 } from '@gui-libraries/apps'

import RoundNumber from 'src/components/shared/RoundNumber'
import { InfrastructureService } from 'src/services/index'
import storage from 'src/libraries/storage'

const NetworkStaticRoutesBFDIPv4: React.FC = (props: IObject) => {
  const infrastructureService = new InfrastructureService()
  const [showSlidingPage, setShowSlidingPage] = useState(false)
  const [localIp, setLocalIp] = useState('')
  const [nextHopIp, setNextHopIp] = useState('')
  const [bfdList, setBfdList] = useState([] as IObject[])
  const [start, setStart] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [forceDelete, setForceDelete] = useState(false)
  const [bfdTotal, setBfdTotal] = useState(0)
  const { selectedPartition = 'shared' } = props

  const updateTotal = () => {
    const { deviceObj, service } = props
    const provider = deviceObj['provider-name']
    const deviceName = deviceObj['name']

    Promise.all([
      service.getRequest(
        null,
        null,
        [provider, deviceName, selectedPartition, '?total=true'],
        'GET_DEVICE_IPV4_BFD',
      ),
    ])
      .then((response: IObject[]) => {
        try {
          setBfdTotal(_.get(response[0], 'data.total-count', 0))
        } catch (e) {
          console.log(e)
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const getData = () => {
    setIsLoading(true)
    const provider = storage.get.PROVIDER
    infrastructureService
      .getRequest(
        null,
        null,
        [provider, props.deviceObj.name, selectedPartition, ''],
        'GET_DEVICE_IPV4_BFD',
      )
      .then((res: IObject) => {
        try {
          const bfds = res.data['bfd-list']
          setBfdList(bfds)
          setIsLoading(false)
        } catch (e) {
          console.log(e)
          setIsLoading(false)
        }
      })
  }
  useEffect(() => {
    getData()
    updateTotal()
  }, [start, props.deviceObj.name, props.refreshTimer, selectedPartition])

  const columns = React.useMemo(
    () => [
      {
        title: 'Local Address',
        dataIndex: 'local-ip',
        sorter: (a: IObject, b: IObject) => {
          return a['local-ip'] > b['local-ip'] ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        title: 'Nexthop Address',
        dataIndex: 'nexthop-ip',
        sorter: (a: IObject, b: IObject) => {
          return a['nexthop-ip'] > b['nexthop-ip'] ? 1 : -1
        },
        render: (text: any, record: any) => {
          return text
        },
      },
      {
        dataIndex: 'actions',
        width: 30,
        render: (text: any, record: any) => {
          return (
            !storage.get.IS_OPERATOR_USER && (
              <A10DropdownMenu
                title=""
                menu={[
                  // <div key="edit" onClick={handleEdit.bind(this, record['local-ip'], record['nexthop-ip'])}>Edit</div>,
                  <div
                    key="delete"
                    onClick={handleDelete.bind(
                      this,
                      record['local-ip'],
                      record['nexthop-ip'],
                    )}
                  >
                    Delete
                  </div>,
                ]}
                style={{ color: '#757575', marginBottom: '-15px' }}
                trigger="hover"
                placement="bottomRight"
                arrowPointAtCenter={true}
              />
            )
          )
        },
      },
    ],
    [],
  )

  const toggleSlidingPage = (isOpen: boolean) => {
    setShowSlidingPage(isOpen)
  }

  const handleAdd = (event: React.MouseEvent<HTMLElement>) => {
    toggleSlidingPage(true)
    setLocalIp('')
    setNextHopIp('')
    event.stopPropagation()
  }

  // const handleEdit = (localIpAddr: string, nextIp: string) => {
  //   setLocalIp(localIpAddr)
  //   setNextHopIp(nextIp)
  //   setShowSlidingPage(true)
  // }

  const handleDelete = (localIpAddr: string, nextIp: string) => {
    setForceDelete(false)
    setLocalIp(localIpAddr)
    setNextHopIp(nextIp)
    toggleModal(true)
  }

  const handleSuccess = () => {
    if (!localIp) {
      updateTotal()
    }
    // getData()
    toggleSlidingPage(false)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    setStart((page - 1) * pageSize)
  }

  const toggleModal = (isOpen: boolean) => {
    setShowModal(isOpen)
  }

  const handleOk = () => {
    const provider = storage.get.PROVIDER
    infrastructureService
      .getRequest(
        null,
        null,
        [
          provider,
          props.deviceObj.name,
          selectedPartition,
          `${localIp}${encodeURIComponent('+')}${nextHopIp}`,
          forceDelete ? '&force=true' : '',
        ],
        'DELETE_DEVICE_IPV4_BFD',
      )
      .then((res: IObject) => {
        // getData()
        updateTotal()
        toggleModal(false)
        A10Notification.success({
          message: 'Success!',
          description: `Delete IPv4 BFD static route ${localIp}+${nextHopIp} successfully.`,
          style: {
            wordWrap: 'break-word',
          },
        })
      })
      .catch((err: IObject) => {
        toggleModal(false)
        A10Notification.error({
          message: 'Error!',
          description: `Fail to delete IPv4 BFD static route ${localIp}+${nextHopIp}.  ${_.get(
            err,
            ['response', 'data', 'message', 'logs', 0],
            '',
          )}`,
          style: {
            wordWrap: 'break-word',
          },
        })
      })
  }

  const handleForceDeleteChange = (e: any) => {
    setForceDelete(e.target.checked)
  }

  const apiPrefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/device/${props.deviceObj.name}/partition/${selectedPartition}`
  return (
    <div className="col-md-12 marginBottom10">
      <A10Modal
        visible={showModal}
        title="Confirmation"
        onOk={handleOk}
        onCancel={toggleModal.bind(this, false)}
        footer={[
          <A10Button
            key="no"
            type="primary"
            onClick={toggleModal.bind(this, false)}
            className="nobtn"
          >
            No
          </A10Button>,
          <A10Button
            key="yes"
            type="default"
            onClick={handleOk}
            className="yesbtn"
          >
            Yes
          </A10Button>,
        ]}
      >
        <p>Are you sure you want to delete the selected item?</p>
        <A10Checkbox onChange={handleForceDeleteChange} checked={forceDelete}>
          Force Delete
        </A10Checkbox>
      </A10Modal>
      <A10SlidingPage
        isOpen={showSlidingPage}
        modalSize="large"
        onRequestClose={toggleSlidingPage.bind(this, false)}
      >
        <AutoFormV15
          hccEnv={true}
          schemaPath="network/routes/ipv4-static-routes/ipv4-bfd"
          apiPrefix={apiPrefix}
          params={{
            'local-ip': localIp,
            'nexthop-ip': nextHopIp,
          }}
          enableSOP={true}
          interceptor={{
            onSubmitSuccess: handleSuccess,
            onCancel: toggleSlidingPage.bind(this, false),
          }}
          ENV_CONSTS={{
            IS_SHARED_PARTITION: selectedPartition.toLowerCase() === 'shared',
            PARTITION_TYPE:
              selectedPartition.toLowerCase() === 'shared' ? 'SHARED' : 'L3V',
          }}
        />
      </A10SlidingPage>
      <A10Collapse>
        <A10Collapse.Panel
          key={0}
          header={
            <>
              {!storage.get.IS_OPERATOR_USER && (
                <span className="edit-content" onClick={handleAdd}>
                  <A10Icon app="global" type="add-new" /> Add
                </span>
              )}
              <A10Row type="flex" className="no-margin font-14 device-details">
                <span className="pad-s-15 fontWeight300 width260">
                  Static Routes BFD-IPv4 <RoundNumber number={bfdTotal} />
                </span>
                <div className="margin-h-15 dev-content">
                  <div className="device-title">Dest Address</div>
                  <div className="pad-s-15 fontWeight300">
                    {(bfdList || []).length}
                  </div>
                </div>
              </A10Row>
            </>
          }
          className="collapsePanel"
        >
          <A10Table
            columns={columns}
            dataSource={(bfdList || []).slice(start, start + 10)}
            size="small"
            pagination={{
              hideOnSinglePage: true,
              pageSize: 10,
              total: bfdTotal,
              onChange: handlePageChange,
            }}
            bordered={false}
            loading={
              isLoading
                ? {
                    indicator: (
                      <div className="loading-icon">
                        <ReactLoading
                          type="bars"
                          color="#4a90e2"
                          height={40}
                          width={40}
                        />
                      </div>
                    ),
                  }
                : false
            }
          />
        </A10Collapse.Panel>
      </A10Collapse>
    </div>
  )
}

export default NetworkStaticRoutesBFDIPv4
