import React from 'react'
import propTypes from 'prop-types'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Explorer, A10Button, A10Message } from '@gui-libraries/widgets'

import SharedForms from '../SharedForms'
import storage from 'src/libraries/storage'
import { httpClient } from 'src/libraries/httpClient'
// tslint:disable:no-submodule-imports
import * as SchGlobal from 'public/ui-schemas/config/shared-object/global.sch.json'
import * as SchServer from 'public/ui-schemas/config/shared-object/server.sch.json'
import * as SchVServer from 'public/ui-schemas/config/shared-object/virtual-server.sch.json'
import * as SchServiceGroup from 'public/ui-schemas/config/shared-object/service-group.sch.json'
import * as SchVPortTcp from 'public/ui-schemas/config/shared-object/virtual-port.tcp.sch.json'
import * as SchVPortUdp from 'public/ui-schemas/config/shared-object/virtual-port.udp.sch.json'
import * as SchVPortDnsTcp from 'public/ui-schemas/config/shared-object/virtual-port.dns-tcp.sch.json'
import * as SchVPortDnsUdp from 'public/ui-schemas/config/shared-object/virtual-port.dns-udp.sch.json'
import * as SchVPortFastHttp from 'public/ui-schemas/config/shared-object/virtual-port.fast-http.sch.json'
import * as SchVPortHttp from 'public/ui-schemas/config/shared-object/virtual-port.http.sch.json'
import * as SchVPortHttp2 from 'public/ui-schemas/config/shared-object/virtual-port.http2.sch.json'
import * as SchVPortHttp2s from 'public/ui-schemas/config/shared-object/virtual-port.http2s.sch.json'
import * as SchVPortHttps from 'public/ui-schemas/config/shared-object/virtual-port.https.sch.json'
import * as SchVPortMlb from 'public/ui-schemas/config/shared-object/virtual-port.mlb.sch.json'
import * as SchVPortMms from 'public/ui-schemas/config/shared-object/virtual-port.mms.sch.json'
import * as SchVPortMssql from 'public/ui-schemas/config/shared-object/virtual-port.mssql.sch.json'
import * as SchVPortMysql from 'public/ui-schemas/config/shared-object/virtual-port.mysql.sch.json'
import * as SchVPortOthers from 'public/ui-schemas/config/shared-object/virtual-port.others.sch.json'
import * as SchVPortPop3 from 'public/ui-schemas/config/shared-object/virtual-port.pop3.sch.json'
import * as SchVPortRtsp from 'public/ui-schemas/config/shared-object/virtual-port.rtsp.sch.json'
import * as SchVPortSslProxy from 'public/ui-schemas/config/shared-object/virtual-port.ssl-proxy.sch.json'

import './styles/index.less'

export interface IGeneralObjectBrowserProps extends IA10ContainerDefaultProps {
  name: string
  type: string
  tenant: string
  logicalPartition: string
  objectPassed: any
  objectKey?: string
  isNew?: boolean
  schemaPath?: string
  onCancel: () => void
  onSubmit: () => void
  getData: (search: string, start: number, count: number) => void
  start?: number
  count?: number
  search?: string
  currentScope: string
}
export interface IGeneralObjectBrowserState {
  provider: string
  showNext: boolean
  node: any
  selected: boolean
  isNewActive: boolean
  objData: any
}
export interface IUiSchemaSharedObjectComponent {
  label: string
  expanded?: boolean
  children?: object[]
  lineage?: string
  apiURL?: string
  'obj-association'?: string[]
  'cm-object-lineage': string
  uiJson?: string
  scope?: string
}
export interface IUiSchemaSharedObject {
  version: string
  template: string
  type: string
  components: IUiSchemaSharedObjectComponent[]
}

export const setFormModalSize = (cmSchema: string) => {
  const formModalSizeMap: any = {
    'slb.template.dns': {
      size: 'extralarge',
    },
    'slb.template.policy': {
      size: 'extralarge',
    },
    'slb.template.http-policy': {
      size: 'extralarge',
    },
    'slb.template.client-ssl': {
      size: 'extralarge',
    },
    'slb.template.server-ssl': {
      size: 'extralarge',
    },
    'slb.template.monitor': {
      size: 'extralarge',
    },
    'slb.template.http': {
      size: 'large',
    },
    'slb.template.cache': {
      size: 'large',
    },
    'slb.template.cipher': {
      size: 'large',
    },
    'slb.template.virtual-server': {
      size: 'large',
    },
  }
  try {
    storage.set.modalSize(formModalSizeMap[cmSchema].size)
  } catch (e) {
    storage.remove.modalSize()
  }
}

class GeneralObjectBrowser extends A10Container<
  IGeneralObjectBrowserProps,
  IGeneralObjectBrowserState
> {
  static contextTypes = {
    openSlidingPage: propTypes.func.isRequired,
  }
  node: IObject = {
    name: '',
  }
  retSLB: IObject = {}
  retNAT: IObject = {}
  schemaObj: IObject = {}
  schemaMap: IObject = {
    global: SchGlobal,
    server: SchServer,
    'virtual-server': SchVServer,
    'service-group': SchServiceGroup,
    'virtual-port.tcp': SchVPortTcp,
    'virtual-port.udp': SchVPortUdp,
    'virtual-port.dns-tcp': SchVPortDnsTcp,
    'virtual-port.dns-udp': SchVPortDnsUdp,
    'virtual-port.fast-http': SchVPortFastHttp,
    'virtual-port.http': SchVPortHttp,
    'virtual-port.http2': SchVPortHttp2,
    'virtual-port.http2s': SchVPortHttp2s,
    'virtual-port.https': SchVPortHttps,
    'virtual-port.mlb': SchVPortMlb,
    'virtual-port.mms': SchVPortMms,
    'virtual-port.mssql': SchVPortMssql,
    'virtual-port.mysql': SchVPortMysql,
    'virtual-port.others': SchVPortOthers,
    'virtual-port.pop3': SchVPortPop3,
    'virtual-port.rtsp': SchVPortRtsp,
    'virtual-port.ssl-proxy': SchVPortSslProxy,
  }
  schemaObjCache: IObject = {}

  constructor(props: IGeneralObjectBrowserProps) {
    super(props)
    this.state = {
      provider: storage.set.PROVIDER || '',
      showNext: false,
      node: {},
      selected: false,
      isNewActive: !!props.isNew,
      objData: props.objectPassed, // || SlbGeneralObject,
    }
  }
  componentWillMount() {
    this.processSchema()
  }
  componentWillUnmount() {
    storage.remove.modalSize()
  }
  updateSelection = (node: any) => {
    this.node = node
    this.setState({ selected: true })
    const page = node.page

    setFormModalSize(page)
  }
  loadSharedObjectForm = () => {
    const {
      getData,
      isNew,
      start = 0,
      count = 10,
      search = '',
      tenant,
      logicalPartition,
      schemaPath = '',
    } = this.props
    if (this.state.isNewActive) {
      const { provider } = this.state
      const defaultParams = {
        provider,
        tenant,
      }
      this.context.openSlidingPage(
        <SharedForms
          tenant={tenant}
          logicalPartition={logicalPartition}
          previousPage={schemaPath}
          isNew={isNew}
          node={this.node}
          getData={getData}
          defaultParams={defaultParams}
          start={start}
          count={count}
          search={search}
        />,
      )
    } else {
      this.onInstanceBinded()
    }
  }
  cancelLoad = () => {
    this.context.openSlidingPage(null)
  }
  onInstanceBinded = async () => {
    const { getData, search = '', start = 0, count = 10 } = this.props
    const node: IObject = this.node
    const templateKey = node.key
    const objectType = node.association
    if (node.needBinding && node.objectPassed) {
      const oldPayload = node.objectPassed.payload
      const actionURL = node.objectPassed.actionURL.replace('%2B', '+')
      const objectName = node.name
      let objTemplateKey = ''
      if (
        templateKey !== 'cookie' &&
        templateKey !== 'source-ip' &&
        templateKey !== 'destination-ip' &&
        templateKey !== 'ssl-sid'
      ) {
        objTemplateKey = 'template-' + templateKey
      } else {
        objTemplateKey = 'template-persist-' + templateKey
      }
      oldPayload[objTemplateKey] = objectName
      try {
        const newPayload: IObject = {}
        newPayload[objectType] = oldPayload
        const { data: Res } = await httpClient.post(actionURL, newPayload)
        A10Message.success('Successful to bind the object')
        getData(search, start, count)
      } catch (err) {
        console.log(err)
        A10Message.error('Error: Failed to bind the object')
      }
    }
    this.context.openSlidingPage(null)
  }
  getSlbChildern = () => {
    const { tenant, logicalPartition } = this.props
    const state = { ...this.state }
    const { provider } = state
    return httpClient.get(
      `/hpcapi/v3/provider/${provider}/tenant/${tenant}/logical-partition/${logicalPartition}/slb/template/`,
    )
  }
  getIPNATChildern = () => {
    const { tenant, logicalPartition } = this.props
    const state = { ...this.state }
    const { provider } = state
    return httpClient.get(
      `/hpcapi/v3/provider/${provider}/tenant/${tenant}/logical-partition/${logicalPartition}/ip/nat/template/`,
    )
  }
  becomeNew = async (toBeNew: boolean) => {
    const state = { ...this.state }
    state.isNewActive = toBeNew

    if (toBeNew) {
      state.objData = JSON.parse(
        JSON.stringify(Object.values(this.schemaObjCache)),
      )
    } else {
      state.objData = await this.getChildren(state.objData)
    }
    this.setState(state)
    this.schemaObj = this.state.objData
  }
  getInstance = (leaf: IObject) => {
    if (leaf.page) {
      const posLastDot = leaf.page.lastIndexOf('.')
      const lineageParent = leaf.page.slice(0, posLastDot)
      const lineageSelf = leaf.page.slice(posLastDot + 1)
      let apiObj: IObject = {}
      const listKey = lineageSelf + '-list'
      if (lineageParent === 'slb.template') {
        apiObj = this.retSLB || []
      } else if (lineageParent === 'slb.template.persist') {
        apiObj = this.retSLB.persist || []
      } else if (lineageParent === 'ip.nat.template') {
        apiObj = this.retNAT || []
      }

      if (apiObj[listKey]) {
        leaf.children = apiObj[listKey].map((child: any) => {
          const newLeaf: IObject = {}
          if (leaf.objectPassed) {
            newLeaf.rawkey = leaf.rawkey
            newLeaf.key = lineageSelf
            newLeaf.needBinding = true
            newLeaf.association = leaf.association
            newLeaf.objectPassed = leaf.objectPassed
          }
          newLeaf.name = child.name
          newLeaf.page = leaf.page
          return newLeaf
        })
        leaf.count = apiObj[listKey].length
      } else {
        leaf.count = 0
        leaf.children = []
      }
      delete leaf.page
    }
    return leaf
  }
  getChildren = async (objArray: IObject[]) => {
    const [resultSLB, resultNAT] = await Promise.all([
      this.getSlbChildern(),
      this.getIPNATChildern(),
    ])
    const {
      data: { template: retSLB },
    } = resultSLB

    const {
      data: { template: retNAT },
    } = resultNAT
    this.retSLB = retSLB
    this.retNAT = retNAT
    const newObjArray = objArray.map((obj: IObject) => {
      if (obj.page) {
        obj = this.getInstance(obj)
      } else {
        obj.children = obj.children.map((leaf: IObject) => {
          return this.getInstance(leaf)
        })
      }
      return obj
    })
    return newObjArray
  }

  getFormattedSchemaComponent = (components: IObject) => {
    const {
      schemaPath,
      objectPassed,
      objectKey,
      tenant,
      logicalPartition,
      currentScope = 'lp',
    } = this.props
    components = components.filter(
      (component: IUiSchemaSharedObjectComponent) => {
        return !(component.scope === 'lp' && currentScope === 'provider')
      },
    )
    return components.map((component: IUiSchemaSharedObjectComponent) => {
      const obj: IObject = {
        name: component.label,
      }
      obj.key = component.label.toLowerCase()

      if (component.expanded) {
        obj.active = component.expanded
        obj.toggled = component.expanded
      }
      obj.rawkey = obj.key

      if (component['cm-object-lineage']) {
        // logic by weiyang
        obj.page = component['cm-object-lineage']

        if (obj.key !== 'ip nat logging') {
          obj.key =
            component.label.indexOf('Persist') !== -1
              ? component['cm-object-lineage'].substring(21)
              : component['cm-object-lineage'].substring(13)
        } else {
          // ip.nat.template.logging
          obj.key = component['cm-object-lineage'].substring(16)
        }
      }
      obj.uiJson = component.uiJson || ''

      if (component.children) {
        obj.children = this.getFormattedSchemaComponent(component.children)
      }
      /* istanbul ignore next */
      if (component['obj-association']) {
        obj['obj-association'] = component['obj-association']
      }

      if (!component.apiURL) {
        obj.apiURL = `/hpcapi/v3/provider/${storage.get.PROVIDER}/tenant/${tenant}/logical-partition/${logicalPartition}`
      }

      if (objectPassed) {
        obj.needBinding = true
        obj.association = schemaPath
        obj.objectPassed = objectPassed

        if (objectKey) {
          obj.association = objectKey
        }
      }

      return obj
    })
  }

  processSchema = () => {
    const { schemaPath } = this.props
    let objData = {}
    let schemaFile = schemaPath || 'global'

    if (schemaPath) {
      schemaFile = schemaPath
    }
    const uiSchemaJson: IUiSchemaSharedObject = this.schemaMap[schemaFile]

    objData = this.getFormattedSchemaComponent(uiSchemaJson.components)
    this.schemaObj = objData
    this.schemaObjCache = JSON.parse(JSON.stringify(objData)) // deep copy
    this.setState({ objData })
  }

  renderDetail = () => {
    return (
      <A10Explorer
        data={this.state.objData}
        onClickNode={this.updateSelection}
      />
    )
  }
  render() {
    const { schemaPath } = this.props
    const { showNext, isNewActive } = this.state

    return !showNext ? (
      <>
        <div className="slide-rectangle">
          <div className="New-Shared-Object">Select Template Type</div>
          <div className="Choose-from-a-Templa">
            Choose a type of the template
          </div>
          <div className="Rectangle-22">
            {schemaPath ? (
              <div className="ADC-Template-subtitle">
                <div className="ADC-Template-categor">
                  ADC Template categories
                </div>
                <div className="ADC-Template-new-exist">
                  <div
                    onClick={this.becomeNew.bind(this, true)}
                    className={
                      isNewActive
                        ? 'ADC-Template-new ADC-Template-active'
                        : 'ADC-Template-new'
                    }
                  >
                    New
                  </div>
                  <div
                    onClick={this.becomeNew.bind(this, false)}
                    className={
                      !isNewActive
                        ? 'ADC-Template-exist ADC-Template-active'
                        : 'ADC-Template-exist'
                    }
                  >
                    Existing
                  </div>
                </div>
              </div>
            ) : null}
            <div className="Line" />
            {this.renderDetail()}
          </div>
          <div className="footer">
            <A10Button
              className="Next"
              key="create_or_update"
              type="primary"
              size="default"
              disabled={!this.state.selected}
              style={{ marginRight: 20 }}
              onClick={this.loadSharedObjectForm}
            >
              {isNewActive ? 'Next' : 'Select'}
            </A10Button>
            <A10Button
              className="Cancel"
              key="cancel"
              type="default"
              size="default"
              style={{ marginRight: 20 }}
              onClick={this.cancelLoad}
            >
              Cancel
            </A10Button>
          </div>
        </div>
      </>
    ) : null
  }
}

export default setupA10Container(GeneralObjectBrowser)
