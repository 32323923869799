import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import {
  A10Checkbox,
  A10DropdownMenu,
  A10Icon,
  A10Loader,
  A10Message,
  A10Modal,
  A10SlidingPage,
  A10Status,
  A10Button,
} from '@gui-libraries/widgets'
import { CheckboxChangeEvent } from '@gui-libraries/widgets/node_modules/antd/lib/checkbox'
import { AutoFormV15, setUpAutoConfig } from '@gui-libraries/apps'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import ActionButton from 'src/components/shared/ActionButton'
import Cards, { Type, Record } from 'src/components/shared/Cards'
import { Utilities } from 'src/services'
import { appendUnit } from 'src/libraries/common'
import { IClusterPartition } from '../Cluster'

import styles from './styles/index.module.less'

export interface ISystemServicesProps extends IA10ContainerDefaultProps {
  cluster: IObject
  clusterPartition: IClusterPartition
}

interface ISystemServicesState {
  prefix: string
  data: Record[]
  slidingPageOpen: boolean
  listOpen: boolean
  listDataObj: IObject[]
  formPath: string
  modalSize: string
  onlyEditMode: boolean
  editData: IObject
  enableForceDelete: boolean
  showDeleteModal: boolean
  deleteItemName: string
  deleteItemUuid: string
  loading: boolean
}

const SHARED = 'shared'
const SHARED_ONLY_BLOCKS = [
  'ntp',
  'generalSettings',
  'webAxapi',
  'administrativeSettings',
]
const ENDPOINTS = [
  'terminal',
  'web-service',
  'ip/dns/suffix',
  'ip/dns/primary',
  'ip/dns/secondary',
  'environment',
  'ntp/server/hostname',
  // 'ssh-login-grace-time',
  'smtp',
  'admin-lockout',
  // 'authentication',
  'system/password-policy',
]
const SHARED_ONLY_ENDPOINTS = [
  'ntp/server/hostname',
  'environment',
  'web-service',
  'admin-lockout',
  'system/password-policy',
]

class SystemServices extends A10Container<
  ISystemServicesProps,
  ISystemServicesState
> {
  Utilities = new Utilities()
  isOperatorUser = storage.get.IS_OPERATOR_USER

  state = {
    prefix: '',
    data: [] as Record[],
    slidingPageOpen: false,
    listOpen: false,
    listDataObj: [] as IObject[],
    formPath: '',
    modalSize: '',
    onlyEditMode: false,
    editData: {},
    showDeleteModal: false,
    enableForceDelete: false,
    deleteItemName: '',
    deleteItemUuid: '',
    loading: false,
  }

  componentDidMount = () => {
    this.init()
  }

  componentDidUpdate = (prevProps: ISystemServicesProps) => {
    if (
      prevProps.cluster !== this.props.cluster ||
      prevProps.clusterPartition !== this.props.clusterPartition
    ) {
      this.init()
    }
  }

  init = () => {
    const {
      clusterPartition: { cluster, partition },
    } = this.props

    if (cluster && partition) {
      const prefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/cluster/${cluster}/partition/${partition}/`
      this.setState({ prefix, loading: true }, () => this.getData())
    }
  }

  fetchData = async (url: string) => {
    const res = await httpClient.get(url).catch((err: any) => {
      console.error(err.error)
    })
    return res
  }

  getData = async () => {
    const {
      clusterPartition: { partition },
    } = this.props
    const { prefix } = this.state
    const isShared = partition === SHARED
    let res = {}
    const endpoints = ENDPOINTS.filter(endpoint => {
      return isShared || !SHARED_ONLY_ENDPOINTS.includes(endpoint)
    })

    await Promise.all(
      endpoints.map(element =>
        partition?.length > 0 ? this.fetchData(prefix + element) : null,
      ),
    ).then(value => {
      value.forEach((element, index) => {
        res[endpoints[index]] = (element as IObject)?.data?.[
          endpoints[index] === 'ntp/server/hostname'
            ? 'hostname-list'
            : endpoints[index].includes('/')
            ? endpoints[index].split('/').slice(-1)[0]
            : endpoints[index]
        ]
      })
    })

    const writeAccessItemsMap = {
      terminal: this.renderAction(
        true,
        'Edit',
        true,
        [],
        'system/settings/terminal',
      ),
      webAxapi: this.renderAction(
        true,
        'Edit',
        true,
        [],
        'system/settings/web',
      ),
      dns: this.renderAction(true, 'Edit', true, [], 'system/settings/dns'),
      generalSettings:
        // this.renderAction(
        //   'GeoDB',
        //   true,
        //   [],
        //   'system/settings/geo-location/geo-database',
        // ),
        // this.renderAction('TFTP', true, [], 'system/settings/general'),
        // this.renderAction('SSH', true, [], 'system/settings/general'),
        this.renderAction(
          true,
          'Edit Thresholds',
          true,
          [],
          'system/settings/general',
        ),
      ntp: this.renderAction(
        true,
        'Edit',
        false,
        res?.['ntp/server/hostname'],
        'system/settings/ntp',
      ),
      smtp: this.renderAction(true, 'Edit', true, [], 'system/settings/smtp'),
      administrativeSettings: [
        this.renderAction(false, 'Lockout', true, [], 'system/admin/lockout'),
        this.renderAction(
          false,
          'Password',
          true,
          [],
          'system/settings/policy',
        ),
      ],
    }

    const dataByBlock = {
      terminal: {
        name: 'Terminal',
        type: Type.Description,
        span: 8,
        minWidth: 300,
        direction: 'row',
        data: [
          {
            History: _.isNumber(res?.['terminal']?.['history-cfg']?.['enable'])
              ? res?.['terminal']?.['history-cfg']?.['enable'] == 1
                ? 'enabled'
                : 'disabled'
              : null,
            'Max History': res?.['terminal']?.['history-cfg']?.['size'],
            'Idle Time': appendUnit(
              res?.['terminal']?.['idle-timeout'],
              'min',
              'mins',
            ),
          },
        ],
        more: !this.isOperatorUser ? writeAccessItemsMap.terminal : null,
      },
      smtp: {
        name: 'SMTP',
        type: Type.Description,
        span: 8,
        minWidth: 250,
        direction: 'row',
        data: [
          {
            Server: res?.['smtp']?.['smtp-server'],
            Port: res?.['smtp']?.['port'],
          },
        ],
        more: !this.isOperatorUser ? writeAccessItemsMap.smtp : null,
      },
      generalSettings: {
        name: 'General Settings',
        type: Type.Description,
        span: 8,
        minWidth: 400,
        direction: 'row',
        data: [
          {
            'Env Updated Interval': appendUnit(
              res?.['environment']?.['update-interval']?.['interval'],
              'min',
              'mins',
            ),
            // 'SSH Login Grace Time': {
            //   value: res?.['ssh-login-grace-time']?.['grace-time'],
            //   unit: 'sec',
            //   plural: 'secs',
            // },
            'High Temp Threshold': appendUnit(
              res?.['environment']?.['threshold-cfg']?.['high'],
              '°C',
            ),
          },
        ],
        more: !this.isOperatorUser ? writeAccessItemsMap.generalSettings : null,
      },
      webAxapi: {
        name: 'Web / aXAPI',
        type: Type.Description,
        span: 16,
        minWidth: 700,
        direction: 'row',
        data: [
          {
            'GUI Idle Time': appendUnit(
              res?.['web-service']?.['gui-idle'],
              'min',
              'mins',
            ),
            'GUI Session Limit': appendUnit(
              res?.['web-service']?.['gui-session-limit'],
            ),
          },
          {
            'Axapi Idle Time': appendUnit(
              res?.['web-service']?.['axapi-idle'],
              'min',
              'mins',
            ),
            'Axapi Session Limit': appendUnit(
              res?.['web-service']?.['axapi-session-limit'],
            ),
          },
        ],
        more: !this.isOperatorUser ? writeAccessItemsMap.webAxapi : null,
      },
      administrativeSettings: {
        name: 'Administrative Settings',
        type: Type.Description,
        span: 8,
        minWidth: 380,
        direction: 'row',
        data: [
          {
            Lockout: _.isNumber(res?.['admin-lockout']?.['enable'])
              ? res?.['admin-lockout']?.['enable'] == 1
                ? 'enabled'
                : 'disabled'
              : null,
            // Authentication: res?.['authentication']?.['type-cfg']?.['type'],
            'Password Policy': res?.['system/password-policy']?.['complexity'],
          },
        ],
        more: [
          ...(!this.isOperatorUser
            ? writeAccessItemsMap.administrativeSettings
            : []),
        ],
      },
      ntp: {
        name: 'NTP',
        span: 16,
        minWidth: 710,
        type: 'table',
        columns: [
          {
            title: 'Server',
            dataIndex: 'server',
            key: 'server',
            sorter: (a: string, b: string) =>
              this.Utilities.sortString(a, b, 'server'),
          },
          {
            title: 'Mode',
            dataIndex: 'mode',
            key: 'mode',
            sorter: (a: string, b: string) =>
              this.Utilities.sortString(a, b, 'mode'),
          },
          {
            title: 'Preferred',
            dataIndex: 'preferred',
            key: 'preferred',
            sorter: (a: string, b: string) =>
              this.Utilities.sortString(a, b, 'preferred'),
          },
          {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (text: string, record: IObject) => {
              if (this.isOperatorUser) return null

              const { server, uuid } = record
              return (
                <A10DropdownMenu
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                  menu={[
                    this.renderAction(
                      false,
                      'Edit',
                      true,
                      [],
                      'system/settings/ntp',
                      '',
                      {
                        'host-servername': server,
                      },
                    ),
                    <div key="delete" onClick={this.onDelete(server, uuid)}>
                      Delete
                    </div>,
                  ]}
                />
              )
            },
          },
        ],
        data:
          res?.['ntp/server/hostname']?.map((server: IObject) => {
            return {
              server: server?.['host-servername'],
              uuid: server?.['uuid'],
              mode: server?.['action'],
              preferred: server?.['prefer'] == 1 ? 'yes' : 'no',
            }
          }) ?? [],
        more: !this.isOperatorUser ? writeAccessItemsMap.ntp : null,
      },
      dns: {
        name: 'DNS',
        type: Type.Description,
        span: 8,
        minWidth: 380,
        direction: 'row',
        data: [
          {
            Domain: res?.['ip/dns/suffix']?.['domain-name'],
            'Primary IPv4': res?.['ip/dns/primary']?.['ip-v4-addr'],
            'Secondary IPv4': res?.['ip/dns/secondary']?.['ip-v4-addr'],
          },
        ],
        more: !this.isOperatorUser ? writeAccessItemsMap.dns : null,
      },
    }

    const data = Object.keys(dataByBlock).reduce((acc, block) => {
      if (isShared) {
        acc.push(dataByBlock[block])
      } else {
        if (!SHARED_ONLY_BLOCKS.includes(block)) {
          acc.push(dataByBlock[block])
        }
      }
      return acc
    }, [])

    this.setState({
      loading: false,
      data,
    })
  }

  renderAction = (
    isActionButton: boolean,
    text: string, // text for dropdown item
    onlyEdit: boolean, // whether the tab only opens the sliding page for form (`false` for forms that can Edit, Add, Delete, etc.)
    dataObj: [], // data for list to display under `onlyEdit` = false
    form: string, // form schema path
    size: string = '', // sliding page size
    editData: IObject = {},
  ) => {
    const onClickAction = () => {
      this.setState({
        onlyEditMode: onlyEdit,
        listOpen: !onlyEdit,
        listDataObj: dataObj ?? [],
        slidingPageOpen: onlyEdit,
        formPath: form,
        modalSize: size,
        editData: editData,
      })
    }

    if (isActionButton) {
      return (
        <ActionButton
          text={text}
          style={{ marginRight: '-10px' }}
          size="default"
          onClick={onClickAction}
          iconProps={{ app: 'global', type: 'edit' }}
        />
      )
    }

    return <div onClick={onClickAction}>{text}</div>
  }

  closeFormPage = () => {
    this.setState({
      slidingPageOpen: false,
      listOpen: false,
      onlyEditMode: false,
      formPath: '',
      modalSize: '',
      editData: {},
    })
  }

  onSubmitSuccessForm = () => {
    this.closeFormPage()
    this.getData()
  }

  onCancelForm = () => {
    this.closeFormPage()
  }

  onCheckForceDelete = (e: CheckboxChangeEvent) => {
    this.setState({ enableForceDelete: e.target.checked })
  }

  onDelete = (name: string, uuid: string) => {
    return () => {
      this.setState({
        showDeleteModal: true,
        enableForceDelete: false,
        deleteItemName: name,
        deleteItemUuid: uuid,
      })
    }
  }

  deleteItem = async () => {
    const {
      enableForceDelete,
      deleteItemName,
      deleteItemUuid,
      prefix,
    } = this.state
    const apiPrefix = prefix

    const api = `${apiPrefix}/ntp/server/hostname/${deleteItemName}?action=deploy${
      enableForceDelete ? '&force=true' : ''
    }`

    await httpClient
      .delete(api)
      .then(() => {
        A10Message.success(`Delete ${deleteItemName} successfully.`)
      })
      .catch(err => {
        console.error(err)
        A10Message.success(`Failed to delete ${deleteItemName}.`)
      })
      .finally(() => {
        this.getData()
        this.closeFormPage()
        this.setState({
          enableForceDelete: false,
          showDeleteModal: false,
          deleteItemName: '',
          deleteItemUuid: '',
        })
      })
  }

  onDeleteCancel = () => {
    this.setState({ showDeleteModal: false })
  }

  renderItems = () => {
    const { listDataObj } = this.state
    return (
      <>
        {listDataObj.map(item => (
          <div className="listItem">
            <A10Status color="colorGreen" title="S"></A10Status>
            <div className="listItemName">{item?.['host-servername']}</div>
            <div className="listItemOption">
              <A10DropdownMenu
                title={''}
                menu={[
                  <div
                    key="edit"
                    onClick={() =>
                      this.setState({
                        slidingPageOpen: true,
                        editData: {
                          'host-servername': item?.['host-servername'],
                        },
                      })
                    }
                  >
                    Edit
                  </div>,
                  <div
                    key="delete"
                    onClick={this.onDelete(
                      item?.['host-servername'],
                      item.uuid,
                    )}
                  >
                    Delete
                  </div>,
                ]}
                trigger="hover"
              />
            </div>
          </div>
        ))}
      </>
    )
  }

  renderList = () => {
    const { slidingPageOpen, modalSize } = this.state
    return (
      <>
        <div className="openList">
          <div
            className="listItem"
            onClick={() => this.setState({ slidingPageOpen: true })}
          >
            <A10Icon app="global" type="add-new" />
            <div className="listItemName">{`  New Server`}</div>
          </div>

          {this.renderItems()}
        </div>
        <A10SlidingPage
          isOpen={slidingPageOpen}
          modalSize={modalSize}
          onRequestClose={this.closeFormPage}
        >
          {this.renderForm()}
        </A10SlidingPage>
      </>
    )
  }

  renderForm = () => {
    const {
      clusterPartition: { partition },
    } = this.props
    const { editData, formPath, prefix } = this.state
    const Form = AutoFormV15
    const interceptor: IObject = {
      onSubmitSuccess: (
        sformUtils: IObject,
        response: IObject[],
        names: string[],
        formData: Map<string, any>,
        description: string,
        SOPEnable: boolean,
      ) => {
        this.onSubmitSuccessForm()
      },
      onCancel: this.closeFormPage,
    }

    setUpAutoConfig({
      ENV_CONSTS: {
        IS_SHARED_PARTITION: partition === SHARED,
        PARTITION_TYPE: partition === SHARED ? 'SP' : 'L3V',
      },
    })

    return (
      <Form
        schemaPath={formPath}
        hccEnv={true}
        apiPrefix={prefix}
        refApiPrefix={''}
        params={{
          provider: storage.get.PROVIDER,
          ...editData,
        }}
        enableSOP={true}
        interceptor={interceptor}
      />
    )
  }

  render = () => {
    const {
      data,
      listOpen,
      slidingPageOpen,
      modalSize,
      onlyEditMode,
      showDeleteModal,
      deleteItemName,
      loading,
    } = this.state
    const refBox = React.createRef()
    return (
      <>
        <div>
          {data.length > 0 && !loading ? (
            <Cards data={data} className={styles.systemServices} />
          ) : (
            <div
              ref={refBox as React.RefObject<HTMLDivElement>}
              style={{
                minHeight: '60px',
              }}
            >
              <A10Loader container={refBox} />
            </div>
          )}
        </div>

        <A10SlidingPage
          isOpen={listOpen || slidingPageOpen}
          modalSize={modalSize}
          onRequestClose={this.closeFormPage}
        >
          {onlyEditMode ? this.renderForm() : this.renderList()}
        </A10SlidingPage>

        <A10Modal
          title="Confirmation"
          visible={showDeleteModal}
          onOk={this.deleteItem}
          onCancel={this.onDeleteCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.onDeleteCancel}
              className="nobtn"
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="danger"
              onClick={this.deleteItem}
              className="yesbtn"
            >
              Yes
            </A10Button>,
          ]}
        >
          <>
            <p>Do you want to delete {deleteItemName.toString()} ?</p>
            <A10Checkbox onChange={this.onCheckForceDelete}>
              Force Delete
            </A10Checkbox>
          </>
        </A10Modal>
      </>
    )
  }
}
export default setupA10Container(SystemServices)
