import React from 'react'
import classnames from 'classnames'

import './styles/index.less'

interface IDescriptionsProps {
  data: IObject[]
  direction: 'column' | 'row'
  className?: string
}

const NA = 'N/A'

const Descriptions: React.FC<IDescriptionsProps> = props => {
  const { data: groups, direction = 'row', className } = props

  return (
    <div
      className={classnames(className, 'descriptions', {
        'descriptions-row': direction === 'row',
        'descriptions-column': direction === 'column',
      })}
    >
      {groups.map(group => {
        const records = Object.entries(group)
        return (
          <div className="description-group">
            {records.map(record => {
              const [name, value] = record
              return (
                <div key={name} className="description-record">
                  <div className="description-title">{name}</div>
                  <div className="description-value">{value ?? NA}</div>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default Descriptions
