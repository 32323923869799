import React from 'react'
import ReactLoading from 'react-loading'
import moment from 'moment-timezone'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import {
  A10Col,
  A10Input,
  A10Table,
  A10DropdownMenu,
  A10Modal,
  A10Button,
} from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import { TroubleShootService, Utilities } from 'src/services/index'
import {
  ContentHeader,
  ContentTitle,
  ContentBody,
} from 'src/components/shared/ContentSection'
import { ActionButton } from 'src/components/shared/ActionButton'
import { UpgradeModal } from 'src/components/shared/UpgradeModal'

import SlidingBackup from '../BackupForm/slidingForm'
import ActivateLicense from 'src/components/shared/ActivateLicense'

// tslint:disable-next-line:no-var-requires
const styles = require('./style/index.module.less')

export interface IScheduledBackupsProps extends IA10ContainerDefaultProps {}

export interface IScheduledBackupsState {
  showSlidingPage: boolean
  imageCount: number
  isLoading: boolean
  search: string
  data: any[]
  scheduledBackups: any[]
  deleteModalState: boolean
  enableSave: boolean
  selectedBackup: any
  maxBackups: number
  remainingBackups: number
  showUpgrade: boolean
  isPeriodicAllowed: boolean
  activeLicense: string
  showAddLicense: boolean
}

class ScheduledBackups extends A10Container<
  IScheduledBackupsProps,
  IScheduledBackupsState
> {
  defaultImageColumns: any[]
  TroubleShootService = new TroubleShootService()
  Utilities = new Utilities()

  delayedSearchText = _.debounce(searchString => {
    this.searchData(searchString)
  }, 800)

  constructor(props: IScheduledBackupsProps) {
    super(props)
    if (!storage.get.PROVIDER) {
      storage.set.PROVIDER('root')
    }
    this.state = {
      showSlidingPage: false,
      imageCount: 0,
      isLoading: false,
      search: '',
      data: [],
      scheduledBackups: [],
      deleteModalState: false,
      enableSave: false,
      selectedBackup: {},
      maxBackups: -1,
      remainingBackups: 0,
      showUpgrade: false,
      isPeriodicAllowed: false,
      activeLicense: '',
      showAddLicense: false,
    }

    this.defaultImageColumns = [
      {
        title: 'Backup Name',
        dataIndex: 'backupName',
        key: 'backupName',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'backupName'),
      },
      {
        title: 'Device',
        dataIndex: 'object-name',
        key: 'object-name',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'object-name'),
      },
      {
        title: 'Start Time',
        dataIndex: 'startTime',
        key: 'startTime',
        sorter: (a: any, b: any) => this.Utilities.sortDate(a, b, 'startTime'),
      },
      {
        title: 'Frequency',
        dataIndex: 'frequencyText',
        key: 'frequencyText',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'frequencyText'),
      },
      {
        title: 'Time Zone',
        dataIndex: 'timeZoneOffset',
        key: 'timeZoneOffset',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'timeZoneOffset'),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a: any, b: any) =>
          this.Utilities.sortString(a, b, 'description'),
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (selectedBackup: any) => {
          const clickAction = (component: JSX.Element, index: number) => {
            if (component.key === 'delete') {
              this.setState({
                deleteModalState: true,
                enableSave: true,
                selectedBackup,
              })
            }
          }
          return (
            <div className="text-right">
              <i>
                <A10DropdownMenu
                  menu={[<div key="delete">Delete </div>]}
                  title=""
                  style={{ color: '#757575', marginBottom: '-15px' }}
                  onClick={clickAction}
                  trigger="hover"
                  placement="bottomRight"
                  arrowPointAtCenter={true}
                />
              </i>
            </div>
          )
        },
      },
    ]
  }

  componentDidMount() {
    this.getData()
    this.getRemainingBackups()
    this.getEntitlements()
  }

  setSlidingPage = (isOpen: boolean) => {
    const { remainingBackups, maxBackups, activeLicense } = this.state
    if (activeLicense !== 'No Active License') {
      if (remainingBackups > 0 || maxBackups === -1) {
        this.setState({ showSlidingPage: isOpen })
      } else {
        this.setState({ showUpgrade: true })
      }
    } else {
      this.setState({ showAddLicense: true })
    }
  }

  onFormSubmitted = () => {
    this.setSlidingPage(false)
    this.getData()
    this.getRemainingBackups()
  }

  getRemainingBackups = () => {
    this.setState({ isLoading: true })
    this.TroubleShootService.getBackups(null, null, [
      storage.get.PROVIDER,
      'remaining-backups',
    ])
      .then((resp: IObject) => {
        this.setState({
          maxBackups: resp?.data?.max_backups,
          remainingBackups: resp?.data?.remaining_backups,
        })
      })
      .catch((err: IObject) => {
        this.setState({
          isLoading: false,
          activeLicense: err?.response?.data,
        })
      })
  }

  getEntitlements = () => {
    this.TroubleShootService.getLicenseEntitlements(null, null, [
      storage.get.PROVIDER,
    ])
      .then((resp: IObject) => {
        const { data, status } = resp
        const deviceManagement = data?.['device-management']

        let isAllowed = false
        if (!deviceManagement?.backup && status === 200) {
          isAllowed = true
        } else {
          isAllowed = deviceManagement?.backup?.periodic
        }
        this.setState({ isPeriodicAllowed: isAllowed })
      })
      .catch((err: IObject) => {})
  }

  getData = () => {
    this.setState({ isLoading: true })
    this.TroubleShootService.getDeviceMgmtScheduledJobsForState(null, null, [
      storage.get.PROVIDER,
      'DeviceConfigurationBackup',
      'scheduled',
    ])
      .then((resp: any) => {
        const backupList = resp?.data['device-mgmt-scheduled-job-list'] || []
        backupList.map((backup: any) => {
          const backupInfo = backup?.['input-json']?.backup
          const timezoneDetails = backup?.timezone
            ? _.isObject(backup.timezone)
              ? backup.timezone
              : backup.timezone.indexOf('{') > -1
              ? JSON.parse(backup.timezone)
              : null
            : null

          backup.timeZoneOffset = timezoneDetails
            ? timezoneDetails.label
            : backup.startTime
            ? 'UTC+00:00'
            : ''
          backup.backupName = backupInfo?.['backup-name'] || ''
          backup.frequencyText = backup['schedule']?.frequency || ''
          backup.startTime = backup['schedule']['start-time'] || ''
        })
        //Remove records that are not sheduled
        const filteredBackupList = backupList.filter((backup: IObject) => {
          return backup?.schedule?.['is-repeating'] === true
        })
        this.setState({
          isLoading: false,
          data: filteredBackupList,
          scheduledBackups: filteredBackupList,
          imageCount: filteredBackupList.length,
        })
      })
      .catch((err: any) => {
        this.setState({ isLoading: false })
      })
  }

  searchData = (search: string = '') => {
    const scheduledBackups: any[] = this.state.data
      ? this.state.data.filter((scheduledBackup: any) => {
          return (
            (scheduledBackup['object-name'] &&
              scheduledBackup['object-name'].indexOf(search) > -1) ||
            (scheduledBackup['object-type'] &&
              scheduledBackup['object-type'].indexOf(search) > -1) ||
            (scheduledBackup.imageName &&
              scheduledBackup.imageName.indexOf(search) > -1) ||
            (scheduledBackup.description &&
              scheduledBackup.description.indexOf(search) > -1) ||
            (scheduledBackup.backupName &&
              scheduledBackup.backupName.indexOf(search) > -1) ||
            (scheduledBackup.frequencyText &&
              scheduledBackup.frequencyText.indexOf(search) > -1)
          )
        })
      : []
    this.setState({ scheduledBackups })
  }

  onSearchText = (e: any) => {
    const searchString = e.target.value
    this.setState({ search: searchString }, () => {
      this.delayedSearchText(searchString)
    })
  }

  deleteScheduledJob = () => {
    if (this.state.selectedBackup && this.state.selectedBackup['job-id']) {
      this.TroubleShootService.deleteScheduledJobs(null, null, [
        this.state.selectedBackup['job-id'],
      ])
        .then((resp: any) => {
          this.getData()
          this.Utilities.showMessage(
            'Successfully deleted scheduled backup',
            1,
            0,
          )
          this.handleCancel()
        })
        .catch((error: any) => {
          if (error?.response) {
            const msg =
              error?.response?.data &&
              typeof error.response.data === 'string' &&
              error.response.status !== 404
                ? error.response.data
                : ''
            this.Utilities.showMessage(
              'Unable to delete scheduled backup',
              0,
              0,
              msg,
            )
          }
          this.handleCancel()
        })
    }
  }

  handleCancel = () => {
    this.setState({
      deleteModalState: false,
      enableSave: false,
      selectedBackup: {},
    })
  }

  render() {
    const {
      showSlidingPage,
      isLoading,
      scheduledBackups,
      deleteModalState,
      enableSave,
      selectedBackup,
      showUpgrade,
      imageCount,
      isPeriodicAllowed,
      maxBackups,
      showAddLicense,
    } = this.state

    return (
      <>
        <ContentHeader type="flex" align="middle" justify="space-between">
          <A10Col>
            <ContentTitle title="Scheduled Backups" count={imageCount} />
          </A10Col>
          <A10Col className={styles.actionsContainer}>
            <A10Input.Search
              type="text"
              onChange={this.onSearchText}
              name="searchBox"
              value={this.state.search}
              placeholder="Search"
              className={styles.searchInput}
            />
            {storage.get.ADMIN_LEVEL === 'provider' &&
              !storage.get.IS_OPERATOR_USER && (
                <ActionButton
                  text="Create"
                  onClick={this.setSlidingPage.bind(this, true)}
                  iconProps={{ app: 'global', type: 'add-new' }}
                />
              )}
          </A10Col>
        </ContentHeader>
        <ContentBody>
          <A10Table
            className={styles.suList}
            columns={this.defaultImageColumns}
            dataSource={scheduledBackups.map((item: IObject, index: number) => {
              item.key = index
              return item
            })}
            size="small"
            loading={
              isLoading
                ? {
                    indicator: (
                      <ReactLoading
                        type="bars"
                        color="#4a90e2"
                        height={40}
                        width={40}
                      />
                    ),
                  }
                : false
            }
          />
        </ContentBody>
        <SlidingBackup
          isScheduled={true}
          isOpen={showSlidingPage}
          isPeriodicAllowed={isPeriodicAllowed}
          onRequestClose={this.onFormSubmitted}
        />
        <A10Modal
          title="Delete Scheduled Backup Task"
          visible={deleteModalState}
          onOk={this.deleteScheduledJob}
          onCancel={this.handleCancel}
          footer={[
            <A10Button
              key="no"
              type="primary"
              onClick={this.handleCancel}
              className="nobtn"
              disabled={!enableSave}
            >
              No
            </A10Button>,
            <A10Button
              key="yes"
              type="default"
              onClick={this.deleteScheduledJob}
              className="yesbtn"
              disabled={!enableSave}
            >
              Yes
            </A10Button>,
          ]}
        >
          {`Do you want to delete backup scheduled for ${selectedBackup['object-type']} ${selectedBackup['object-name']}`}
        </A10Modal>
        <UpgradeModal
          content={`You are using a license which only allows ${maxBackups} Device Backups. For questions or upgrading your license please contact A10 Networks Customer Support or Sales.`}
          visible={showUpgrade}
          onClose={() => {
            this.setState({ showUpgrade: false })
          }}
        />
        <ActivateLicense
          content={'Please add a valid license to your account'}
          visible={showAddLicense}
          onClose={() => {
            this.setState({ showAddLicense: false })
          }}
        />
      </>
    )
  }
}

export default setupA10Container(ScheduledBackups)
