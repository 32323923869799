import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Form, A10Input, A10Select, A10Icon } from '@gui-libraries/widgets'

import storage from 'src/libraries/storage'
import A10Panel from 'src/components/ADC/A10Panel'
import A10IconTextGroup from 'src/components/ADC/A10IconTextGroup'
import { Messages } from 'src/locale/en/index'
import { DashboardService } from 'src/services/index'
import { DropdownConstants } from 'src/constants/DropdownConstants/DropdownConstants'
import Utilities from 'src/services/Utilities/Utilities'
import { InfrastructureService } from 'src/services/InfrastructureService'
import { HelpInfo } from 'src/components/shared/HelpInfo'

import './styles/partitionaddform.scss'

const { Option } = A10Select

export interface IPartitionAddFormProps extends IA10ContainerDefaultProps {
  formData: any
  partition: any
  device?: any
  form: any
  onRef?: any
  editMode: boolean
  handleChange?: (data: any) => void
  clusterLevelPartitionEnable: boolean
  clusterData: any
  clusterLevelEditPartitionEnable: boolean
  selectedPartitionData: any
}
export interface IPartitionAddFormState {
  tenantsList: any[]
}

class PartitionAddForm extends A10Container<
  IPartitionAddFormProps,
  IPartitionAddFormState
> {
  Messages = new Messages()
  DashboardService = new DashboardService()
  DropdownConstants = new DropdownConstants()
  Utilities = new Utilities()
  InfrastructureService = new InfrastructureService()

  constructor(props: IPartitionAddFormProps) {
    super(props)
    this.state = {
      tenantsList: [],
    }

    this.loadTenants()
  }

  loadTenants = () => {
    const provider = storage.get.PROVIDER
    const tenantResponse = this.DashboardService.listTenants(null, null, [
      provider,
    ])
    tenantResponse
      .then((resp: any) => {
        if (resp && resp.data && resp.data['tenant-list']) {
          const tenantsList = resp.data['tenant-list']

          this.setState({
            tenantsList,
          })
          if (this.props.partition) {
            this.handleChange('tenant', this.props.formData.tenant)
          }
        }
      })
      .catch((error: any) => {
        console.log(error.response) // we need to create a centralised error handling
      })
  }

  handleChange = (field: string, e: IObject) => {
    const { formData } = this.props
    const _formData = { ...formData }
    let fieldValue

    if (e.target) {
      if (e.target.type === 'checkbox') {
        fieldValue = e.target.checked
      } else {
        fieldValue = e.target.value
      }
    } else {
      fieldValue = e
    }

    _formData[field] = fieldValue

    if (field === 'partitionId') {
      if (fieldValue && parseInt(fieldValue) === 0) {
        delete _formData.partitionType
      } else if (!_formData.partitionType) {
        _formData.partitionType = 'l3v'
      }
    }

    this.props.handleChange(_formData)
  }

  renderDropdownOpts = (key: IObject[]) => {
    if (key instanceof Array) {
      if (key[0] && !key[0].noTenant) {
        key.unshift({ name: 'None', 'display-name': 'None', noTenant: true })
      }

      return key.length > 0
        ? key.map(option => {
            if (option.type !== 'built-in') {
              return (
                <Option
                  key={option['display-name'] || option.name}
                  value={JSON.stringify(option)}
                >
                  {
                    <>
                      {option.noTenant ? null : (
                        <span className="statusIcon">
                          <span className="statusIconTenantText">T</span>
                        </span>
                      )}
                      {'  '}
                      {option['display-name'] || option.name}{' '}
                    </>
                  }
                </Option>
              )
            }
            return null
          })
        : null
    } else {
      return null
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  validateDuplicatePartitionName = (rule: any, value: any, cb: any) => {
    const { clusterData } = this.props
    let isValid: boolean = true
    const isDuplicate = (clusterData?.['partition-list'] || []).some(
      (partition: IObject) => partition.name === value,
    )
    const userRegx = new RegExp(/^[A-Za-z]([.a-zA-Z0-9_-]){1,50}$/)
    if ('' === value) {
      rule.message = this.Messages.EMPTY_PARTITION_NAME
      isValid = false
    } else if (!userRegx.test(value)) {
      rule.message = this.Messages.INVALID_PARTITION_NAME
      isValid = false
    } else if (isDuplicate) {
      rule.message = this.Messages.DUPLICATE_PARTITION_NAME
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  validateDuplicatePartitionId = (rule: any, value: any, cb: any) => {
    const { clusterData } = this.props
    let isValid: boolean = true
    const isDuplicate = (clusterData?.['partition-list'] || []).some(
      (partition: IObject) => partition.id.toString() === value,
    )
    const userRegx = new RegExp(/^([0-9]){1,50}$/)
    if ('' === value) {
      rule.message = this.Messages.EMPTY_PARTITION_ID
      isValid = false
    } else if (!userRegx.test(value)) {
      rule.message = this.Messages.INVALID_PARTITION_ID
      isValid = false
    } else if (isDuplicate) {
      rule.message = this.Messages.DUPLICATE_PARTITION_ID
      isValid = false
    }
    return isValid ? cb() : cb(true)
  }

  render() {
    const {
      partitionId,
      partitionName,
      // device,
      tenant,
      description,
    } = this.props.formData

    const { selectedPartitionData, clusterData } = this.props

    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }

    const { getFieldDecorator } = this.props.form

    // const headerTxt = 'Edit Device Partition'
    // if (this.props.cluster) {
    //   headerTxt = 'Add device under cluster - ' + this.props.cluster.name
    // }

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel
          title={
            <A10IconTextGroup
              text={
                this.props.editMode ? (
                  <span className="partitionTitle">Edit Device Partition</span>
                ) : (
                  <span className="partitionTitle">
                    {' '}
                    {this.props.clusterLevelPartitionEnable ? (
                      <>
                        Add Cluster Partition
                      </>
                    ) : this.props.clusterLevelEditPartitionEnable ? (
                      <>
                        Edit Cluster Partition
                      </>
                    ) : (
                      <>
                        Add Device Partition
                      </>
                    )}
                  </span>
                )
              }
              icon={
                <A10Icon
                  style={{ width: 22, height: 22, marginRight: 12 }}
                  app="global"
                  type="form-section"
                  className="sliding-panel-icon"
                />
              }
            />
          }
        >
          {this.props.clusterLevelPartitionEnable ? (
            <>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.PARTITION_NAME}
                    <span style={{ marginLeft: -2 }}>
                      <HelpInfo
                        placement="topLeft"
                        title="Partition name"
                        helpKey="HELP_INF_PARTITION_NAME_INFORMATION"
                      />
                    </span>
                  </>
                }
              >
                {getFieldDecorator('partitionName', {
                  rules: [
                    {
                      validator: this.validateDuplicatePartitionName.bind(this),
                    },
                  ],
                  initialValue: partitionName,
                })(
                  <A10Input
                    disabled={!!this.props.partition}
                    type="text"
                    placeholder={this.Messages.PARTITION_NAME}
                    onChange={this.handleChange.bind(this, 'partitionName')}
                    maxLength={14}
                  />,
                )}
              </A10Form.Item>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    Id
                    <span style={{ marginLeft: -9 }}>
                      <HelpInfo
                        placement="topLeft"
                        title="Id"
                        helpKey="HELP_INF_PARTITION_ID_INFORMATION"
                      />
                    </span>
                  </>
                }
              >
                {getFieldDecorator('partitionId', {
                  rules: [
                    { validator: this.validateDuplicatePartitionId.bind(this) },
                  ],
                  initialValue: partitionId,
                })(
                  <A10Input
                    disabled={!!this.props.partition}
                    type="text"
                    placeholder={'Id'}
                    onChange={this.handleChange.bind(this, 'partitionId')}
                  />,
                )}
              </A10Form.Item>
              {partitionId !== 0 && partitionId !== '0' && (
                <A10Form.Item
                  {...formItemLayout}
                  label={
                    <>
                      Partition Type
                      <span style={{ marginLeft: -12 }}>
                        <HelpInfo
                          placement="topLeft"
                          title="Partition Type"
                          helpKey="HELP_INF_PARTITION_TYPE_INFORMATION"
                        />
                      </span>
                    </>
                  }
                >
                  <A10Select
                    defaultValue="l3v"
                    onChange={this.handleChange.bind(this, 'partitionType')}
                  >
                    <Option value="l3v">L3V</Option>
                    <Option value="service">Service</Option>
                  </A10Select>
                </A10Form.Item>
              )}
            </>
          ) : this.props.clusterLevelEditPartitionEnable ? (
            <>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.PARTITION_NAME}
                    <span style={{ marginLeft: -2 }}>
                      <HelpInfo
                        placement="topLeft"
                        title="Partition name"
                        helpKey="HELP_INF_PARTITION_NAME_INFORMATION"
                      />
                    </span>
                  </>
                }
              >
                {getFieldDecorator('partitionName', {
                  initialValue: selectedPartitionData.name,
                })(
                  <A10Input
                    disabled={true}
                    type="text"
                    placeholder={this.Messages.PARTITION_NAME}
                  />,
                )}
              </A10Form.Item>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    Type
                    <span style={{ marginLeft: -12 }}>
                      <HelpInfo
                        placement="topLeft"
                        title="Type"
                        helpKey="HELP_INF_PARTITION_TYPE_INFORMATION"
                      />
                    </span>
                  </>
                }
              >
                <A10Input
                  disabled={true}
                  type="text"
                  placeholder={'Type'}
                  defaultValue={
                    selectedPartitionData.id === 0 ? 'Shared' : 'L3V'
                  }
                />
              </A10Form.Item>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    Associated Cluster
                    <span>
                      <HelpInfo
                        placement="topLeft"
                        title="Associated Cluster"
                        helpKey="HELP_INF_PARTITION_ASSOCIATED_CLUSTER_INFORMATION"
                      />
                    </span>
                  </>
                }
              >
                <A10Input
                  disabled={true}
                  type="text"
                  placeholder={'Cluster Name'}
                  defaultValue={
                    clusterData['display-name']
                      ? clusterData['display-name']
                      : clusterData.name
                  }
                />
              </A10Form.Item>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    Partition Id
                    <span style={{ marginLeft: 8 }}>
                      <HelpInfo
                        placement="topLeft"
                        title="Partition Id"
                        helpKey="HELP_INF_PARTITION_ID_INFORMATION"
                      />
                    </span>
                  </>
                }
              >
                {getFieldDecorator('partitionId', {
                  rules: [
                    { validator: this.validateDuplicatePartitionId.bind(this) },
                  ],
                  initialValue: selectedPartitionData.id,
                })(<A10Input disabled={true} type="text" placeholder={'Id'} />)}
              </A10Form.Item>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    Associated Tenant
                    <span style={{ marginLeft: -12 }}>
                      <HelpInfo
                        placement="topLeft"
                        title=" Associated Tenant"
                        helpKey="HELP_INF_PARTITION_ASSOCIATED_TENANT_INFORMATION"
                      />
                    </span>
                  </>
                }
              >
                {getFieldDecorator('tenant', {
                  rules: [
                    { required: true, message: this.Messages.TENANT_REQUIRED },
                  ],
                  initialValue:
                    this.state.tenantsList.length > 0 &&
                    selectedPartitionData.tenant !== '{}'
                      ? selectedPartitionData.tenant
                      : '',
                })(
                  <A10Select
                    placeholder={'Please select'}
                    onChange={this.handleChange.bind(this, 'tenant')}
                  >
                    {this.renderDropdownOpts(this.state.tenantsList)}
                  </A10Select>,
                )}
              </A10Form.Item>
            </>
          ) : (
            <>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.PARTITION_NAME}
                    <span style={{ marginLeft: -2 }}>
                      <HelpInfo
                        placement="topLeft"
                        title="Partition name"
                        helpKey="HELP_INF_PARTITION_NAME_INFORMATION"
                      />
                    </span>
                  </>
                }
              >
                {getFieldDecorator('partitionName', {
                  rules: [
                    {
                      required: true,
                      message: this.Messages.EMPTY_PARTITION_NAME,
                    },
                    {
                      pattern: /^([a-zA-Z0-9_-]){1,50}$/,
                      message: this.Messages.INVALID_PARTITION_NAME,
                    },
                  ],
                  initialValue: partitionName,
                })(
                  <A10Input
                    disabled={!!this.props.partition}
                    type="text"
                    placeholder={this.Messages.PARTITION_NAME}
                    onChange={this.handleChange.bind(this, 'partitionName')}
                  />,
                )}
              </A10Form.Item>

              <A10Form.Item
                {...formItemLayout}
                label={this.Messages.DESCRIPTION}
              >
                <A10Input.TextArea
                  placeholder={this.Messages.DESCRIPTION}
                  defaultValue={description}
                  rows={3}
                  id="description"
                  onChange={this.handleChange.bind(this, 'description')}
                />
              </A10Form.Item>

              <A10Form.Item
                {...formItemLayout}
                label={this.Messages.TENANT_NEW_NAME}
              >
                {getFieldDecorator('tenant', {
                  rules: [
                    { required: true, message: this.Messages.TENANT_REQUIRED },
                  ],
                  initialValue:
                    this.state.tenantsList.length > 0 && tenant !== '{}'
                      ? tenant
                      : '',
                })(
                  <A10Select
                    placeholder={'Please select'}
                    onChange={this.handleChange.bind(this, 'tenant')}
                  >
                    {this.renderDropdownOpts(this.state.tenantsList)}
                  </A10Select>,
                )}
              </A10Form.Item>
            </>
          )}
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(PartitionAddForm))
