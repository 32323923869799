import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
  _,
} from '@gui-libraries/framework'
import {
  A10Checkbox,
  A10DropdownMenu,
  A10Icon,
  A10Loader,
  A10Message,
  A10Modal,
  A10SlidingPage,
  A10Status,
} from '@gui-libraries/widgets'
import { CheckboxChangeEvent } from '@gui-libraries/widgets/node_modules/antd/lib/checkbox'
import { AutoFormV15, setUpAutoConfig } from '@gui-libraries/apps'

import ActionButton from 'src/components/shared/ActionButton'
import Cards, { Type, Record } from 'src/components/shared/Cards'
import { httpClient } from 'src/libraries/httpClient'
import { appendUnit } from 'src/libraries/common'
import storage from 'src/libraries/storage'
import { formatBytes } from 'src/libraries/stringUtils'
import { IClusterPartition } from '../Cluster'

import './styles/index.less'
export interface IMonitorSettingsProps extends IA10ContainerDefaultProps {
  cluster: IObject
  clusterPartition: IClusterPartition
}
export interface IMonitorSettingsState {
  prefix: string
  data: Record[]
  slidingPageOpen: boolean
  listOpen: boolean
  listDataObj: IObject[]
  formPath: string
  modalSize: string
  onlyEditMode: boolean
  editData: IObject
  enableForceDelete: boolean
  loading: boolean
}

const SHARED = 'shared'

class MonitorSettings extends A10Container<
  IMonitorSettingsProps,
  IMonitorSettingsState
> {
  isOperatorUser = storage.get.IS_OPERATOR_USER

  state = {
    prefix: '',
    data: [] as Record[],
    slidingPageOpen: false,
    listOpen: false,
    listDataObj: [] as IObject[],
    formPath: '',
    modalSize: '',
    onlyEditMode: false,
    editData: {},
    enableForceDelete: false,
    loading: false,
  }

  componentDidMount = () => {
    this.init()
  }

  componentDidUpdate = (prevProps: IMonitorSettingsProps) => {
    if (
      prevProps.cluster !== this.props.cluster ||
      prevProps.clusterPartition !== this.props.clusterPartition
    ) {
      this.init()
    }
  }

  init = () => {
    const {
      clusterPartition: { cluster, partition },
    } = this.props

    if (cluster && partition) {
      const prefix = `/hpcapi/v3/provider/${storage.get.PROVIDER}/cluster/${cluster}/partition/${partition}/`
      this.setState({ prefix, loading: true }, () => this.getData())
    }
  }

  fetchData = async (url: string) => {
    const res = await httpClient.get(url).catch((err: any) => {
      console.error(err.error)
    })
    return res
  }

  getData = async () => {
    const {
      clusterPartition: { partition },
    } = this.props
    const { prefix } = this.state
    let res = {}
    const endpoints = [
      'logging/console',
      'logging/buffered',
      'logging/monitor',
      'logging/trap',
      'logging/syslog',
      'sflow/agent/address',
      'sflow/setting',
    ]
    await Promise.all(
      endpoints.map(element =>
        partition?.length > 0 ? this.fetchData(prefix + element) : null,
      ),
    ).then(value => {
      value.forEach((element, index) => {
        res[endpoints[index]] = (element as IObject)?.data?.[
          endpoints[index].includes('/')
            ? endpoints[index].split('/').slice(-1)[0]
            : endpoints[index]
        ]
      })
    })

    const writeAccessItemsMap = {
      logging: this.renderAction(
        true,
        'Edit',
        true,
        [],
        'system/settings/logging',
      ),

      sFlowMonitors: this.renderAction(
        true,
        'Edit',
        true,
        [],
        'system/monitoring/sflow',
      ),
    }

    const data = [
      {
        name: 'Logging',
        type: Type.Description,
        span: 12,
        minWidth: 600,
        direction: 'row',
        data: [
          {
            'Console Level': res?.['logging/console']?.['console-levelname'],
            'Buffer Size': _.isNumber(res?.['logging/buffered']?.['buffersize'])
              ? formatBytes(res?.['logging/buffered']?.['buffersize'])
              : null,
            'Monitor Level': res?.['logging/monitor']?.['monitor-levelname'],
          },
          {
            'Trap Level': res?.['logging/trap']?.['trap-levelname'],
            'Syslog Level': res?.['logging/syslog']?.['syslog-levelname'],
          },
        ],
        more: !this.isOperatorUser ? writeAccessItemsMap.logging : null,
      },
      {
        name: 'sFlow Monitors',
        type: Type.Description,
        span: 12,
        minWidth: 600,
        direction: 'row',
        data: [
          {
            'Agent IP Address': res?.['sflow/agent/address']?.['ip'],
            'Packet Samp. Rate': appendUnit(
              res?.['sflow/setting']?.['packet-sampling-rate'],
              '/ sec',
            ),
          },
          {
            'Counter Polling Interval': appendUnit(
              res?.['sflow/setting']?.['counter-polling-interval'],
              'sec',
              'secs',
            ),
            'Max Header': res?.['sflow/setting']?.['max-header']
              ? formatBytes(res?.['sflow/setting']?.['max-header'])
              : null,
          },
        ],
        more: !this.isOperatorUser ? writeAccessItemsMap.sFlowMonitors : null,
      },
    ]

    this.setState({
      loading: false,
      data,
    })
  }

  renderAction = (
    isActionButton: boolean,
    text: string, // text for dropdown item
    onlyEdit: boolean, // whether the tab only opens the sliding page for form (`false` for forms that can Edit, Add, Delete, etc.)
    dataObj: [], // data for list to display under `onlyEdit` = false
    form: string, // form schema path
    size: string = '', // sliding page size
  ) => {
    const onClickAction = () => {
      this.setState({
        onlyEditMode: onlyEdit,
        listOpen: !onlyEdit,
        listDataObj: dataObj ?? [],
        slidingPageOpen: onlyEdit,
        formPath: form,
        modalSize: size,
      })
    }

    if (isActionButton) {
      return (
        <ActionButton
          text={text}
          style={{ marginRight: '-10px' }}
          size="default"
          onClick={onClickAction}
          iconProps={{ app: 'global', type: 'edit' }}
        />
      )
    }

    return <div onClick={onClickAction}>{text}</div>
  }

  closeFormPage = () => {
    this.setState({
      slidingPageOpen: false,
      listOpen: false,
      onlyEditMode: false,
      formPath: '',
      modalSize: '',
      editData: {},
    })
  }

  getHttpClient = () => {
    const {
      GLOBAL_CONFIG: {
        EPIC_DEPENDENCIES: { httpClient },
      },
    } = this.props
    return httpClient
  }

  onSubmitSuccessForm = () => {
    this.closeFormPage()
    this.getData()
  }

  onCancelForm = () => {
    this.closeFormPage()
  }

  onCheckForceDelete = (e: CheckboxChangeEvent) => {
    this.setState({ enableForceDelete: e.target.checked })
  }

  onDelete = (name: string, uuid: string) => {
    return () => {
      this.setState({ enableForceDelete: false })
      A10Modal.confirm({
        title: 'Confirmation',
        content: (
          <>
            <p>'Do you want to delete the selected item?'</p>
            <A10Checkbox onChange={this.onCheckForceDelete}>
              Force Delete
            </A10Checkbox>
          </>
        ),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: this.deleteItem(name, uuid),
      })
    }
  }

  deleteItem = (name: string, uuid: string) => async () => {
    const { enableForceDelete, prefix } = this.state
    const apiPrefix = prefix

    const api = `/hpcapi/v3/uuid/${uuid}`

    pushSingleObject(httpClient, apiPrefix, 'DELETE', api, {})
      .then(async () => {
        await httpClient.delete(api)
        A10Message.success(`Delete ${name} successfully.`)
        this.getData()
        this.closeFormPage()
      })
      .catch(async error => {
        if (enableForceDelete) {
          await httpClient.delete(api)
          this.getData()
          this.closeFormPage()
        }
        this.setState({ enableForceDelete: false })
      })
  }

  renderItems = () => {
    const { listDataObj } = this.state
    return (
      <>
        {listDataObj.map(item => (
          <div className="listItem">
            <A10Status color="colorGreen" title="M"></A10Status>
            <div className="listItemName">{item.name}</div>
            <div className="listItemOption">
              <A10DropdownMenu
                title={''}
                menu={[
                  <div
                    key="edit"
                    onClick={() =>
                      this.setState({
                        slidingPageOpen: true,
                        editData: { name: item.name },
                      })
                    }
                  >
                    Edit
                  </div>,
                  <div
                    key="delete"
                    onClick={this.onDelete(item.name, item.uuid)}
                  >
                    Delete
                  </div>,
                ]}
                trigger="hover"
              />
            </div>
          </div>
        ))}
      </>
    )
  }

  renderList = () => {
    const { slidingPageOpen, modalSize } = this.state
    return (
      <>
        <div className="openList">
          <div
            className="listItem"
            onClick={() => this.setState({ slidingPageOpen: true })}
          >
            <A10Icon app="global" type="add-new" />
            <div className="listItemName">{`  New Monitor`}</div>
          </div>

          {this.renderItems()}
        </div>
        <A10SlidingPage
          isOpen={slidingPageOpen}
          modalSize={modalSize}
          onRequestClose={this.closeFormPage}
        >
          {this.renderForm()}
        </A10SlidingPage>
      </>
    )
  }

  renderForm = () => {
    const {
      clusterPartition: { partition },
    } = this.props
    const { editData, formPath, prefix } = this.state
    const Form = AutoFormV15
    const interceptor: IObject = {
      onSubmitSuccess: (
        sformUtils: IObject,
        response: IObject[],
        names: string[],
        formData: Map<string, any>,
        description: string,
        SOPEnable: boolean,
      ) => {
        this.onSubmitSuccessForm()
      },
      onCancel: this.closeFormPage,
    }

    setUpAutoConfig({
      ENV_CONSTS: {
        IS_SHARED_PARTITION: partition === SHARED,
        PARTITION_TYPE: partition === SHARED ? 'SP' : 'L3V',
      },
    })

    return (
      <Form
        schemaPath={formPath}
        hccEnv={true}
        apiPrefix={prefix}
        refApiPrefix={''}
        params={{ provider: storage.get.PROVIDER, ...editData }}
        enableSOP={true}
        interceptor={interceptor}
        viewMode={false}
      />
    )
  }

  render = () => {
    const {
      data,
      listOpen,
      slidingPageOpen,
      modalSize,
      onlyEditMode,
      loading,
    } = this.state
    const refBox = React.createRef()
    return (
      <>
        <div>
          {data.length > 0 && !loading ? (
            <Cards data={data} className="monitor-settings" />
          ) : (
            <div
              ref={refBox as React.RefObject<HTMLDivElement>}
              style={{
                minHeight: '60px',
              }}
            >
              <A10Loader container={refBox} />
            </div>
          )}
        </div>

        <A10SlidingPage
          isOpen={listOpen || slidingPageOpen}
          modalSize={modalSize}
          onRequestClose={this.closeFormPage}
        >
          {onlyEditMode ? this.renderForm() : this.renderList()}
        </A10SlidingPage>
      </>
    )
  }
}
export default setupA10Container(MonitorSettings)
