import React from 'react'

import A10Panel from 'src/components/ADC/A10Panel'
import {
  _,
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import {
  A10Form,
  A10Input,
  A10Icon,
  A10Radio,
  A10Checkbox,
  A10Alert,
  A10Button,
} from '@gui-libraries/widgets'

import { Messages } from 'src/locale/en/Messages'
import { Utilities, DashboardService } from 'src/services'
import { HelpInfo } from 'src/components/shared/HelpInfo'
// tslint:disable-next-line:no-var-requires
const styles = require('./styles/index.module.less')

export interface ILicenseFormProps extends IA10ContainerDefaultProps {
  form: any
  onRef?: any
  lmObject: any
  isTested: number
  onChange: (lmObject: any, isTested: number) => void
}

export interface ILicenseFormStates {
  lmObject: any
  isTested: number
  testMsg: string
}

class LicenseForm extends A10Container<ILicenseFormProps, ILicenseFormStates> {
  Messages = new Messages()
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  llmUuidRef: any = null

  constructor(props: ILicenseFormProps) {
    super(props)
    this.state = {
      lmObject: this.props.lmObject,
      isTested: this.props.isTested,
      testMsg: 'Test License Manager settings to be able to Save.',
    }
  }

  componentWillReceiveProps(nextProps: IObject) {
    const state = { ...this.state }
    const { lmObject, isTested } = nextProps
    state.lmObject = lmObject
    state.isTested = isTested
    this.setState(state)
  }

  componentDidMount() {
    this.props.onRef(this)
    this.props.onChange(this.state.lmObject, 0)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  getLlmUuidStatus = () => {
    const llmStatusAPI = this.DashboardService.getLLMStatus(null, null, null)
    llmStatusAPI
      .then((resp: any) => {
        const { lmObject } = this.state,
          data = resp?.data || {}

        lmObject.apiSuffix = data['elm-api-suffix'] || lmObject.apiSuffix
        lmObject.port = data['elm-port'] || lmObject.port
        lmObject.ipDomainURL = data['elm-url'] || lmObject.ipDomainURL
        lmObject.llmUUID = data['elm-uuid'] || ''
        lmObject.activationStatus = data['activation-status'] || false
        lmObject.activationStatusText = ''

        this.props.onChange(lmObject, 0)

        this.setState({ lmObject })
      })
      .catch((err: any) => {
        const msg = err?.response?.data?.message
          ? err.response.data.message
          : ''
        this.Utilities.showMessage('Error in getting LLM status: ' + msg, 0, 0)
        const { lmObject } = this.state
        lmObject.activationStatus = false
        lmObject.activationStatusText = 'Unable to get LLM status'

        this.props.onChange(lmObject, 0)
        this.setState({ lmObject })
      })
  }

  handleChange = (stateName: string, e: any) => {
    const lmObject = this.state.lmObject
    const value =
      e?.target?.type === 'checkbox'
        ? e.target.checked
        : e?.target
        ? e.target.value
        : e

    if (
      stateName === 'lmType' &&
      lmObject.lmType !== value &&
      value === 'Local'
    ) {
      this.getLlmUuidStatus()
    }

    lmObject[stateName] = value

    this.props.onChange(lmObject, 1)

    // @ts-ignore
    this.setState({
      lmObject,
      isTested: 1,
      testMsg: 'Test License Manager settings to be able to Save.',
    })
  }

  onTestOrSave = () => {
    const { lmType, useMgmtPort } = this.state.lmObject

    const payload = {
      'lm-type': lmType,
      'use-mgmt-port': useMgmtPort,
    }

    const saveLMInfo = this.DashboardService.checkLicenseManagerInfo(
      null,
      payload,
      null,
    )
    saveLMInfo
      .then((resp: any) => {
        this.props.onChange(this.state.lmObject, 1)
        this.setState({
          isTested: 1,
          testMsg: 'License Manager settings tested successfully',
        })
      })
      .catch((err: any) => {
        this.props.onChange(this.state.lmObject, 2)
        const msg =
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : ''
        this.setState({
          isTested: 2,
          testMsg: msg + ', Please check your settings and test again.',
        })
      })
  }

  render() {
    const {
      lmType,
      useMgmtPort,
      llmUUID,
      activationStatus,
      activationStatusText,
      llmActivationString,
    } = this.state.lmObject
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 14 },
    }
    const { getFieldDecorator } = this.props.form

    return (
      <A10Form hideRequiredMark={true} layout="horizontal">
        <A10Panel showHeader={false}>
          <A10Form.Item
            className="mb-16"
            {...formItemLayout}
            label={
              <>
                <span className="mandatoryField label-border">Type</span>
                <span style={{ marginLeft: '-4px' }}>
                  <HelpInfo
                    placement="rightTop"
                    title="Type *"
                    helpKey="HELP_LICENSE_MANAGER_LICENSE_TYPE"
                  />
                </span>
              </>
            }
          >
            <div className="row">
              <div className="col-md-12">
                <A10Radio.Group
                  className={styles.llmRadioGroup}
                  name="lmType"
                  value={lmType}
                  onChange={this.handleChange.bind(this, 'lmType')}
                >
                  <A10Radio value="Global">
                    <span className='label-border'>{this.Messages.GLOBAL}</span>
                    <span style={{ marginLeft: '-30px' }}>
                      <HelpInfo
                        placement="rightTop"
                        customCls="custom-tooltip-lm-radio"
                        title={this.Messages.GLOBAL}
                        helpKey="HELP_LICENSE_MANAGER_TYPE_GLOBAL"
                      />
                    </span>
                  </A10Radio>
                  <A10Radio value="Local" className="pad-s-15">
                    <span className='label-border'>{this.Messages.LOCAL}</span>
                    <span style={{ marginLeft: '-145px' }}>
                      <HelpInfo
                        placement="rightTop"
                        customCls="custom-tooltip-lm-radio"
                        title={this.Messages.LOCAL}
                        helpKey="HELP_LICENSE_MANAGER_TYPE_LOCAL"
                      />
                    </span>
                  </A10Radio>
                </A10Radio.Group>
              </div>
            </div>
          </A10Form.Item>
          {lmType === 'Local' ? (
            <>
              <div className={`${styles.llmInfoDiv} "col-md-12"`}>
                <A10Alert
                  showIcon={true}
                  message={
                    activationStatus
                      ? ''
                      : activationStatusText || 'How to Activate'
                  }
                  description={
                    activationStatus ? (
                      'Local License Manager is active'
                    ) : activationStatusText === '' ? (
                      <span>
                        To Activate Local License Manager visit{' '}
                        <a
                          href="https://glm.a10networks.com/elm/activate"
                          target="_blank"
                        >
                          https://glm.a10networks.com/elm/activate
                        </a>{' '}
                        and get the Activation String using the License Manager
                        UUID.
                      </span>
                    ) : (
                      'Unable to get Local License Manager status'
                    )
                  }
                  type={activationStatusText === '' ? 'info' : 'warning'}
                />
              </div>

              <A10Form.Item
                className="mb-16"
                {...formItemLayout}
                label={
                  <>
                    <span className="mandatoryField label-border">License Manager UUID</span>
                    <span style={{ marginLeft: '-4px' }}>
                      <HelpInfo
                        placement="rightTop"
                        title="License Manager UUID *"
                        helpKey="HELP_LICENSE_MANAGER_LLM_UUID"
                      />
                    </span>
                  </>
                }
              >
                <div className="row">
                  <div className="col-md-10">
                    <A10Input
                      type="text"
                      placeholder="License Manager UUID"
                      value={llmUUID}
                      ref={(copyText: any): any => (this.llmUuidRef = copyText)}
                      onChange={this.handleChange.bind(this, 'llmUUID')}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-2">
                    {/* Logical shortcut for only displaying the button if the copy command exists */
                    document.queryCommandSupported('copy') && (
                      <A10Button
                        className="action-button copy-button"
                        onClick={() => {
                          navigator.clipboard.writeText(llmUUID)
                        }}
                      >
                        <A10Icon
                          app="global"
                          type="duplicate"
                          className="action-icon"
                        />
                        Copy
                      </A10Button>
                    )}
                  </div>
                </div>
              </A10Form.Item>
              {activationStatus ? null : (
                <A10Form.Item
                  className='mb-0'
                  {...formItemLayout}
                  label={
                    <>
                      <span className="mandatoryField label-border">Activation String</span>
                      <span style={{ marginLeft: '-4px' }}>
                        <HelpInfo
                          placement="rightTop"
                          title="Activation String *"
                          helpKey="HELP_LICENSE_MANAGER_LLM_ACTIVATION_STRING"
                        />
                      </span>
                    </>
                  }
                >
                  <div className="row">
                    <div className="col-md-10">
                      {getFieldDecorator('llmActivationString', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter activation string',
                          },
                          {
                            pattern: this.Utilities.VALIDATE_ACTIVATION_STRING,
                            message: this.Messages.INVALID_ACTIVATION_STRING,
                          },
                        ],
                        initialValue: llmActivationString,
                      })(
                        <A10Input.TextArea
                          type="text"
                          placeholder="Activation String"
                          onChange={this.handleChange.bind(
                            this,
                            'llmActivationString',
                          )}
                          disabled={activationStatusText !== ''}
                        />,
                      )}
                    </div>
                  </div>
                </A10Form.Item>
              )}
            </>
          ) : null}

          {lmType === 'Global' || (lmType === 'Local' && activationStatus) ? (
            <>
              <A10Form.Item
                className='mb-0'
                {...formItemLayout}
                label={
                  <>
                    <span className='label-border'>{this.Messages.USE_MGMT_PORT}</span>
                    <span style={{ marginLeft: '-4px' }}>
                      <HelpInfo
                        placement="rightTop"
                        title={this.Messages.USE_MGMT_PORT}
                        helpKey="HELP_LICENSE_MANAGER_USE_MGMT_PORT"
                      />
                    </span>
                  </>
                }
              >
                <div className="row">
                  <div className="col-md-6">
                    <A10Checkbox
                      onChange={this.handleChange.bind(this, 'useMgmtPort')}
                      defaultChecked={useMgmtPort}
                    />
                  </div>
                </div>
              </A10Form.Item>
            </>
          ) : null}
        </A10Panel>
      </A10Form>
    )
  }
}

export default setupA10Container(A10Form.create()(LicenseForm))
