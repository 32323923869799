import React from 'react'
import ReactLoading from 'react-loading'
import parameters from 'parameters'
import { setupA10Container } from '@gui-libraries/framework'
import {
  A10Table,
  A10DropdownMenu,
  A10Collapse,
  A10Tooltip,
  A10Button,
  A10Badge,
  A10Row,
  A10Col,
  A10Icon,
} from '@gui-libraries/widgets'
import { AppServiceGroupForm } from '@gui-libraries/apps'

import { DashboardService } from 'src/services/DashboardService'
import { HealthStatus } from 'src/components/ADC/HealthStatus'
import { LaunchAppForm } from 'src/containers/Controller/Apps/InstalledApps/Forms/LaunchAppForm'
import { ThunderAppEdit } from '../ThunderSlbAppServices/ThunderAppEdit'
import { FormatSlidingPage } from 'src/components/ADC/FormatSlidingPage'
import storage from 'src/libraries/storage'
import { Utilities } from 'src/services/Utilities/Utilities'
import {
  AppServiceGroupAssignment,
  IAppServiceGroupAssignmentProps,
  IAppServiceGroupAssignmentState,
} from '../AppServiceGroupAssignment'

import '../styles/appservices.scss'
import styles from '../styles/index.module.less'

export interface IGTPFWServicesProps extends IAppServiceGroupAssignmentProps {
  appServices: any
  hcApps: any
  refreshAppServices: any
}
export interface IGTPFWServicesState extends IAppServiceGroupAssignmentState {
  appServicesReloaded: boolean
  showTable: boolean
  appServices: any
  indexes: any
  selectedIndex: number
  showSlidingPage: boolean
  appData: any
  selectedApp: any
  showEditSlidingPage: boolean
  loadingIconForm: boolean
  displayName: string
  appServicesLength: number
}

class GTPFWServices extends AppServiceGroupAssignment<
  IGTPFWServicesProps,
  IGTPFWServicesState
> {
  DashboardService = new DashboardService()
  Utilities = new Utilities()
  state = {
    datasourceToAppServiceGroup: [] as string[],
    appServicesReloaded: false,
    showTable: true,
    appServices: [[]],
    indexes: [],
    selectedIndex: 0,
    showSlidingPage: false,
    appData: {
      tenant: '',
      appService: '',
      app: {},
    },
    selectedApp: '',
    showEditSlidingPage: false,
    loadingIconForm: false,
    displayName: '',
    appServicesLength: 0,
    selectedAppService: '',
  } as IGTPFWServicesState
  showTable = true
  pageLength = 5
  childForm: any = null
  isOperatorUser = storage.get.IS_OPERATOR_USER
  appServicesColumns = [
    {
      title: <>Rule Set Name</>,
      dataIndex: 'name',
      key: 'name',
      className: 'td-truncate',
      render: (text: string, record: any, index: number) => {
        const associationState =
          record && record.associationState
            ? record.associationState.toLowerCase()
            : ''
        const color =
          record.licenseErrorType == 'WARNING'
            ? 'warning'
            : record.licenseErrorType == 'ERROR'
            ? 'stopped'
            : 'info'
        const showDisplayName = text !== record.displayName
        const prefix = record.activeGtpFw
          ? 'Active Rule Set'
          : 'Inactive Rule Set'

        return (
          <span>
            <HealthStatus
              type={associationState === 'activated' ? color : 'undefined'}
              text="A"
              hideTooltip={associationState === 'activated' && color === 'info'}
              tooltip={
                associationState === 'activated'
                  ? color !== 'info'
                    ? record.licenseDescription
                    : record.displayName || text
                  : 'Inactive'
              }
            />
            &nbsp;
            <A10Tooltip
              overlayClassName={styles.appServiceTooltip}
              title={
                <table>
                  {showDisplayName && (
                    <tr>
                      <th>{`${prefix} Display Name`}</th>
                      <td>{record.displayName}</td>
                    </tr>
                  )}
                  <tr>
                    <th>{`${prefix} Name`}</th>
                    <td>{text}</td>
                  </tr>
                </table>
              }
            >
              {record.activeGtpFw ? (
                <b>{record.displayName || text}</b>
              ) : (
                record.displayName || text
              )}
            </A10Tooltip>
          </span>
        )
      },
      sorter: (a: any, b: any) =>
        this.Utilities.sortStringKeys(a, b, 'displayName', 'name'),
    },
    {
      title: <>Logical Partition</>,
      dataIndex: 'lp_name',
      key: 'lp_name',
      className: 'td-truncate',
      sorter: (a: any, b: any) => this.Utilities.sortString(a, b, 'lp_name'),
      render: (text: any, record: any) => {
        return <A10Tooltip title={text}>{text}</A10Tooltip>
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text: IObject, record: IObject, index: number) => {
        const gtpfwApps = this.props.hcApps.filter((app: any) => {
          return app.name === 'gtpfw' || app.name === 'gtp-fw'
        })
        if (gtpfwApps.length > 0 && !this.isOperatorUser) {
          gtpfwApps.unshift({ thunderEdit: 'edit' })
        }
        let menuItems
        if (gtpfwApps.length !== 0) {
          menuItems = gtpfwApps.map((obj: any, index: number) => {
            return (
              <div
                className={
                  obj.thunderEdit || text.activeGtpFw ? '' : 'disabled'
                }
                onClick={() => {
                  obj.thunderEdit
                    ? this.editApp(text)
                    : text.activeGtpFw
                    ? this.checkAppLicenseAndLaunch(obj, text)
                    : null
                }}
              >
                {obj.thunderEdit ? 'Rename ' : 'Launch In App(' + obj.version + ')'}
              </div>
            )
          })
        } else {
          menuItems = []
          if (!this.isOperatorUser) {
            menuItems.push(
              <div key="edit" onClick={() => this.editApp(text)}>
                Rename{' '}
              </div>,
            )
          }
          menuItems.push(<div className="disabled">No apps available</div>)
        }
        return (
          <div className="table-dropdown">
            <A10DropdownMenu
              menu={menuItems}
              title=""
              trigger="hover"
              placement="bottomRight"
              arrowPointAtCenter={true}
            />
          </div>
        )
      },
    },
  ]
  format = (appService: any) => {
    if (!appService.cspClusterNames || !appService.cspClusterNames?.length) {
      appService.clusterName = 'No Cluster Associted'
    } else {
      appService.clusterName = appService.cspClusterNames[0]
    }
    if (appService.associationState === 'NotAssociated') {
      appService.associationState = 'No Cluster Associted'
    }
  }
  editApp = (appObj: any) => {
    this.setState({
      selectedApp: appObj,
      displayName: appObj.displayName,
      showEditSlidingPage: true,
    })
  }
  handleCanel = () => {
    this.setState({
      selectedApp: '',
      displayName: '',
      showEditSlidingPage: false,
      loadingIconForm: false,
    })
  }
  handleSave = () => {
    const { validateAndSubmitForm } = this.Utilities
    validateAndSubmitForm(this.childForm.props)
  }
  handleEditChange = (data: any) => {
    this.setState({
      displayName: data,
    })
  }
  saveThunderApp = () => {
    const { showMessage } = this.Utilities
    const payload = {
      display_name: this.state.displayName,
    }
    this.setState({ loadingIconForm: true })
    const updateDisplayName = this.DashboardService.updateThunderAppName(
      null,
      payload,
      [this.state.selectedApp['app_svc_id']],
    )
    updateDisplayName
      .then((resp: any) => {
        this.props.refreshAppServices()
        showMessage('Successfully updated the display name', 1, 0)
        this.handleCanel()
      })
      .catch((error: any) => {
        const messsge =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : ''
        showMessage('Error in updating display name', 0, 0, messsge)
      })
  }

  componentWillReceiveProps(props: any) {
    let receivedProps = [...props.appServices]
    let [appServices, indexes] = this.DashboardService.getPaginatedItems(
      receivedProps,
      this.pageLength,
    )
    this.setState({
      appServicesReloaded: true,
      appServices: appServices,
      showTable: true,
      indexes: indexes,
      selectedIndex: 0,
    })
  }
  setData = () => {
    let receivedProps = [...this.props.appServices]
    let [appServices, indexes] = this.DashboardService.getPaginatedItems(
      receivedProps,
      this.pageLength,
    )
    this.setState({
      appServicesReloaded: true,
      appServices: appServices,
      showTable: true,
      indexes: indexes,
    })
  }
  toggleServicesTable = () => {
    let showTable = this.state.showTable
    this.setState({
      showTable: !showTable,
    })
  }
  updateSelectedIndex = (index: any) => {
    this.setState({
      selectedIndex: index,
    })
  }
  componentDidMount() {
    if (!this.state.appServicesReloaded) {
      this.setData()
    }
  }
  componentDidUpdate() {
    if (!this.state.appServicesReloaded) {
      this.setData()
    }

    const { appServices = [] } = this.props

    if (appServices && appServices.length !== this.state.appServicesLength) {
      this.setState({ appServicesLength: appServices.length })
    }
  }

  setSlidingPage = (isOpen: boolean) => {
    this.setState({
      showSlidingPage: isOpen,
    })
  }

  handleChange = (data: any) => {
    // @ts-ignore
    this.setState({
      appData: data,
    })
  }

  checkAppLicenseAndLaunch = (app: any, appSvc: any) => {
    if (!app) {
      this.Utilities.launchApp(appSvc)
    } else {
      this.Utilities.launchAppWithSvc(app, appSvc)
    }
  }

  appServiceNameListToLaunch = (app: any) => {
    this.setState({
      showSlidingPage: true,
      appData: {
        tenant: '',
        appService: '',
        app,
      },
    })
  }

  launchAppURLPage = async (appObjData: any) => {
    const {
      get: { PROVIDER, CURRENT_TENANT, SESSION_TIMEOUT_INITIAL, USER_ID },
    } = storage

    const providerName = PROVIDER
    let tenantObj = appObjData.tenant
      ? JSON.parse(appObjData.tenant)
      : CURRENT_TENANT
    let appSvcObj = appObjData.appService
      ? JSON.parse(appObjData.appService)
      : ''

    const sessionTimeout = SESSION_TIMEOUT_INITIAL
    const currTimestamp = new Date().getTime()
    const ipv6Url = this.Utilities.checkIPv6BaseURL()
    const baseURL = ipv6Url
      ? parameters.BASE_URL.replace(':8443/api/v2', '') + encodeURI('/hcapps')
      : encodeURI(parameters.BASE_URL.replace(':8443/api/v2', '') + '/hcapps')
    let sessionId = await this.Utilities.getSessionId()
    const queryString =
      '?user_id=' +
      USER_ID +
      +'&api_ep=' +
      encodeURI(parameters.BASE_URL) +
      '&provider=' +
      providerName +
      '&token=' +
      sessionId +
      '&timeout=' +
      (sessionTimeout ? sessionTimeout : '1') +
      (tenantObj.name ? '&tenant=' + tenantObj.name : '') +
      (tenantObj.uuid ? '&tenantUUID=' + tenantObj.uuid : '') +
      (appSvcObj.app_svc_id
        ? '&app_svc=' +
          appSvcObj.name +
          '&app_service_id=' +
          appSvcObj.app_svc_id +
          '&app_svc_type=' +
          appSvcObj['app_svc_type'] +
          '&action=config'
        : '')

    const href = `${baseURL}/${appObjData.app.name}_${appObjData.app.version}${queryString}&ts=${currTimestamp}`

    window.open(href, '_blank')

    this.setState({
      showSlidingPage: false,
    })
  }

  handleOk = (gtpfwApps: any) => {
    if (!this.state.appData.appService) {
      this.Utilities.showMessage('SELECT_APPSERVICE', 0, 1)
      return false
    }

    this.launchAppURLPage(this.state.appData)
    this.setState({
      showSlidingPage: false,
    })
  }

  render() {
    const {
      appServiceGroupList,
      appServicesMap,
      usableAppServiceOptions,
    } = this.props
    const { appServiceGroupToBeUpdated, appServicesLength } = this.state

    const gtpfwApps = this.props.hcApps.filter((app: any) => {
      return app.name === 'gtpfw' || app.name === 'gtp-fw'
    })

    gtpfwApps.sort((a: any, b: any) => {
      return this.Utilities.sortAppVersionNumber(
        a?.version?.split('-')[0]?.split('.') || [0],
        b?.version?.split('-')[0]?.split('.') || [0],
      )
    })
    gtpfwApps.sort((a: any, b: any) => {
      return this.Utilities.sortAppVersionNumber(
        a?.version?.split('v')[1]?.split('.') || [0],
        b?.version?.split('v')[1]?.split('.') || [0],
      )
    })

    const activeRuleSet = this.props.appServices.filter((ruleset: IObject) => {
      return ruleset?.associationState === 'Activated' && ruleset?.activeGtpFw
    })

    return (
      <>
        <A10Collapse
          bordered={true}
          defaultActiveKey={this.props.appServices?.length? ['1'] : [null]}
          className="marginTop20 marginBottom15"
        >
          <A10Collapse.Panel
            header={
              <div onClick={this.toggleServicesTable}>
                <A10Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className={styles.appServicesTitleContainer}
                >
                  <A10Col>
                    <A10Icon app="app-icons" type="gtp-icon-active" />
                    <span className={styles.appServicesTitle}>
                      GTP Firewall
                    </span>
                    <A10Badge count={appServicesLength} overflowCount={9999} className={styles.badgeHeight} />
                  </A10Col>
                  <A10Col>
                    <A10Button
                      type="link"
                      onClick={() =>
                        this.appServiceNameListToLaunch(gtpfwApps[0])
                      }
                      disabled={
                        gtpfwApps.length === 0 || activeRuleSet.length === 0
                      }
                    >
                      Launch App
                    </A10Button>
                  </A10Col>
                </A10Row>
              </div>
            }
            key="1"
            className={styles.collapse}
          >
            <A10Table
              columns={this.appServicesColumns}
              dataSource={this.props.appServices.map(
                (key: any, index: number) => {
                  key.key = index
                  if (!key.clusterName) {
                    this.format(key)
                    return key
                  } else {
                    return key
                  }
                },
              )}
              size="small"
              pagination={{ hideOnSinglePage: true, pageSize: 20 }}
            />
          </A10Collapse.Panel>
        </A10Collapse>

        <FormatSlidingPage
          isOpen={this.state.showSlidingPage}
          title={'List of GTP Firewall App Services'}
          description={'Please select an App service'}
          onRequestClose={this.setSlidingPage.bind(this, false)}
          saveText="Proceed"
          onRequestOk={() => this.handleOk(gtpfwApps)}
        >
          <LaunchAppForm
            launchAppObj={this.state.appData}
            handleChange={this.handleChange}
          />
        </FormatSlidingPage>
        <FormatSlidingPage
          isOpen={this.state.showEditSlidingPage}
          onRequestOk={this.handleSave}
          saveText="Update"
          disableSave={this.state.loadingIconForm}
          onRequestClose={this.handleCanel}
          title={'Edit App Service Instance'}
          description={
            'You can only give a display name to an app service instance'
          }
          hideCancel={this.state.loadingIconForm}
        >
          <>
            <ThunderAppEdit
              thunderApp={this.state.selectedApp}
              handleChange={this.handleEditChange}
              onSubmitForm={this.saveThunderApp}
              onRef={(ref: any): any => (this.childForm = ref)}
            />
            {this.state.loadingIconForm ? (
              <div>
                <ReactLoading
                  type="bars"
                  color="#4a90e2"
                  height={40}
                  width={40}
                />
              </div>
            ) : null}
          </>
        </FormatSlidingPage>
        <FormatSlidingPage
          isOpen={this.state.isFormOpen}
          title={'Assign to an App Service Group'}
          description={`Assign "${this.state.selectedAppService}" to an App Service Label`}
          onRequestClose={() => {
            this.setState({
              isFormOpen: false,
              appServiceGroupToBeUpdated: {},
            })
          }}
          saveText="Save"
          onRequestOk={this.onAssignAppServiceGroupRequestOk}
        >
          <AppServiceGroupForm
            appServiceGroupList={appServiceGroupList}
            appServiceGroup={appServiceGroupToBeUpdated}
            onSetAppServiceGroup={(appServiceGroup: IObject) => {
              this.setState({
                appServiceGroupToBeUpdated: {
                  ...appServiceGroup,
                  appServices: [...appServiceGroup.appServices],
                },
              })
            }}
            appServices={usableAppServiceOptions}
            appServicesMap={appServicesMap}
          />
        </FormatSlidingPage>
      </>
    )
  }
}

export default setupA10Container(GTPFWServices)
