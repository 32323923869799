import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { getItem, setItem, _ } from '@gui-libraries/framework'
import { A10Icon, A10Loader } from '@gui-libraries/widgets'
import storage from 'src/libraries/storage'
import { OrganizationService } from 'src/services'
import styles from './styles/index.module.less'

const DashboardMetrics: React.FC<any> = props => {
  const { type, height, tenants, licenses, clusters, devices } = props
  const refContainer = useRef()
  const [formattedData, setFormattedData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [redirect, setRedirect] = useState({
    isRedirect: true,
    url: '',
  })

  const organizationService = new OrganizationService()
  const readonlyAccess =
    storage.get.IS_CERT_USER || storage.get.IS_OPERATOR_USER

  const init = useCallback(async () => {
    setIsLoading(true)
    try {
      let series = []

      if (type === 'organization') {
        const users = await organizationService.getUsersCount(
          null,
          null,
          getItem('PROVIDER'),
        )

        const data = [
          {
            name: 'Tenants',
            count: tenants?.length || 0,
            icon_app: 'harmony-controller',
            icon_type: 'tenant',
            action: 'Add',
            actionHandler: () => {
              setItem('ADD_NEW', true)
              setRedirect({
                isRedirect: true,
                url: '/controller/Organization/Tenants',
              })
            },
          },
          {
            name: 'Users',
            count: _.isNumber(users?.data) && users?.data,
            icon_app: 'harmony-controller',
            icon_type: 'users',
            action: 'Add',
            actionHandler: () => {
              setItem('ADD_NEW', true)
              setRedirect({
                isRedirect: true,
                url: '/controller/Organization/Users',
              })
            },
          },
        ]

        setFormattedData(data)
      } else if (type === 'licenses') {
        series = [
          {
            name: 'License',
            count: licenses?.length || 0,
            icon_app: 'harmony-controller',
            icon_type: 'license-manager',
            action: 'Add',
            actionHandler: () => {
              setItem('ADD_NEW', true)
              setRedirect({
                isRedirect: true,
                url: '/controller/Organization/License',
              })
            },
          },
          {
            name: 'Expiring',
            count:
              licenses?.filter(
                (lic: IObject) => lic['activation-status'] === 'EXPIRING',
              )?.length || 0,
            icon_app: 'harmony-controller',
            icon_type: 'license-manager',
            action: 'Renew',
            actionHandler: () => {
              setRedirect({
                isRedirect: true,
                url: '/controller/Organization/License',
              })
            },
          },
          {
            name: 'Expired',
            count:
              licenses?.filter(
                (lic: IObject) => lic['activation-status'] === 'EXPIRED',
              )?.length || 0,
            icon_app: 'harmony-controller',
            icon_type: 'license-manager',
            action: 'Renew',
            actionHandler: () => {
              setRedirect({
                isRedirect: true,
                url: '/controller/Organization/License',
              })
            },
          },
        ]
        setFormattedData(series)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [type])

  useEffect(() => {
    if (type === 'licenses' || type === 'organization') {
      init()
    } else {
      //infrastructure KPIs
      const data = [
        {
          name: 'Cluster',
          count: clusters?.length,
          icon_app: 'harmony-controller',
          icon_type: 'logical-cluster',
          action: 'Add',
          actionHandler: () => {
            setItem('ADD_NEW', true)
            setRedirect({
              isRedirect: true,
              url: '/controller/Infrastructure/Clusters',
            })
          },
        },
        {
          name: 'Device',
          count: devices?.length,
          icon_app: 'harmony-controller',
          icon_type: 'devices',
          action: 'Add',
          actionHandler: () => {
            setItem('ADD_NEW', true)
            setRedirect({
              isRedirect: true,
              url: '/controller/Infrastructure/Devices',
            })
          },
        },
      ]
      setFormattedData(data)
      setIsLoading(false)
    }
  }, [type, init])

  return (
    <>
      {isLoading && <A10Loader container={refContainer} />}
      {!isLoading && (
        <div className={styles.container} style={{ height: height }}>
          <div className={styles.row}>
            {formattedData?.map(item => {
              return (
                <div className={styles.col}>
                  <div className={styles.iconContainer}>
                    <A10Icon
                      app={item.icon_app}
                      type={item.icon_type}
                      className={styles.colIcon}
                    ></A10Icon>
                    <span>{item.name}</span>
                  </div>
                  <div
                    className={`${styles.metric} ${
                      item.name === `Expired` && item.count > 0
                        ? ` ${styles.redFont}`
                        : ``
                    }`}
                  >
                    {item.count}
                  </div>
                  {!readonlyAccess ? (
                    <div
                      className={styles.linkContainer}
                      onClick={item.actionHandler}
                    >
                      <A10Icon
                        app="global"
                        type={item.action === 'Renew' ? 'edit' : 'add-new'}
                        style={{ fontSize: '20px', paddingRight: '5px' }}
                      ></A10Icon>
                      <span className={styles.link}>{item.action}</span>
                    </div>
                  ) : null}
                </div>
              )
            })}
          </div>
        </div>
      )}
      {redirect?.isRedirect && redirect?.url ? (
        <Redirect to={redirect.url} />
      ) : null}
    </>
  )
}

export default DashboardMetrics
