import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import { isValidNumber, RequestBuilder, truncateVal } from '@gui-libraries/dgf'
import { A10Loader } from '@gui-libraries/widgets'
import { _, getItem, useAsyncEffect } from '@gui-libraries/framework'
import { Viz } from '@gui-libraries/viz'

import './styles/index.less'

interface IProps {
  service: IObject
  height: number
  startTime: number
  endTime: number
  licenses: IObject[]
}

const { GaugeChart } = Viz

const startTime = new Date().getTime() - 24 * 60 * 60 * 1000
const endTime = new Date().getTime()
const requestBuilder = new RequestBuilder()
const interval = requestBuilder.getInterval(startTime, endTime)

const Utilization: React.FC<IProps> = props => {
  const refContainer = useRef()
  const { height, service, licenses } = props
  const [isLoading, setIsLoading] = useState(true)

  const [logRate, setLogRate] = useState(0)
  const [avgLogRate, setAvgLogRate] = useState(0)
  const [usedBandwidthCapacity, setUsedBandwidthCapacity] = useState(0)

  const payload = useMemo(
    () => ({
      utilization: {
        rangeby: {
          start: startTime,
          end: endTime,
          field: 'ts',
        },
        fields: [
          'pc_sent',
          'pr_sent',
          'pe_sent',
          'pc_drop',
          'pr_drop',
          'pe_drop',
        ],
        aggregation: 'sum',
      },
    }),
    [startTime, endTime],
  )

  const timeSeriesInterval = (endTime - startTime) / 1000

  const formatLicenseData = (licenses: IObject) => {
    const deviceLicenses = licenses.filter((license: IObject) => {
      return (
        (license['license-platform'] === 'Capacity Flexpool' ||
          license['license-platform'] === 'Instance Flexpool') &&
        license['activation-status'] !== 'EXPIRED'
      )
    })
    const deviceLicensesData = deviceLicenses.reduce(
      (acc, license: IObject) => {
        acc.allocatedBandwidth += license['alloted-bandwidth']
        acc.usedBandwidth += license['used-bandwidth']
        return acc
      },
      {
        allocatedBandwidth: 0,
        usedBandwidth: 0,
      },
    )
    const usedBandwidthCapacityPercantage =
      deviceLicensesData.allocatedBandwidth > 0
        ? (deviceLicensesData.usedBandwidth * 100) /
          deviceLicensesData.allocatedBandwidth
        : 0

    setUsedBandwidthCapacity(_.round(usedBandwidthCapacityPercantage, 2))
  }

  const formatLogRateData = useCallback(
    data => {
      const { pe_sent_sum, pr_sent_sum, pc_sent_sum } = data
      const peSent = isValidNumber(pe_sent_sum) ? pe_sent_sum : 0
      const prSent = isValidNumber(pr_sent_sum) ? pr_sent_sum : 0
      const pcSent = isValidNumber(pc_sent_sum) ? pc_sent_sum : 0

      let capacity = Number(getItem('DGF_PROVIDER_CAPACITY'))
      capacity = isValidNumber(capacity) ? capacity : 100
      const logRate =
        ((peSent + prSent + pcSent) * 100) / (timeSeriesInterval * capacity)

      const avgLogRate = (peSent + prSent + pcSent) / timeSeriesInterval

      setLogRate(_.round(logRate, 2))
      setAvgLogRate(_.round(avgLogRate, 2))
    },
    [timeSeriesInterval],
  )

  useAsyncEffect(async () => {
    setIsLoading(true)

    try {
      const [partitionStats] = await Promise.all([
        service.getPartitionStats({ payload })
        //service.getLicenses(),
      ])
      formatLogRateData(partitionStats?.utilization || {})
      formatLicenseData(licenses)
    } catch (e) {
      console.error(`Utilization: fail to get data. ${e}`)
    } finally {
      setIsLoading(false)
    }
  }, [])

  return (
    <>
      <div className="interval-text">Last 24 hrs</div>
      <div ref={refContainer} className="utilization">
        {isLoading && <A10Loader container={refContainer} />}
        {!isLoading && (
          <>
            <div className="utilization-visualization">
              <div className="utilization-title">Log Rate Capacity</div>
              <GaugeChart
                name={''}
                gaugeTitle=""
                percentage={logRate}
                height={height}
              />
            </div>
            <div className="logs-container">
              <div>
                <div className="log-label">Log Rate Capacity</div>
                <div className="log-val">
                  {truncateVal(getItem('DGF_PROVIDER_CAPACITY'), 'quantity') +
                    ' /sec'}
                </div>
              </div>
              <div>
                <div className="log-label">Avg Log Rate</div>
                <div className="log-val">
                  {truncateVal(avgLogRate, 'quantity') + ' /sec'}
                </div>
              </div>
            </div>

            <div className="utilization-visualization">
              <div className="utilization-title">Device License</div>
              <GaugeChart
                name={''}
                gaugeTitle=""
                percentage={usedBandwidthCapacity}
                height={height}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Utilization
