import React from 'react'

import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'

import FormatSlidingPage from 'src/components/ADC/FormatSlidingPage'
import { LMLicenseUpdateForm } from './index'
import { Utilities, DashboardService } from 'src/services'

export interface ISlidingLMLicenseUpdateProps
  extends IA10ContainerDefaultProps {
  isOpen: boolean
  licenseObj: any
  onRequestClose: () => void
  updateList: () => void
}

export interface ISlidingLMLicenseUpdateStates {
  licenseObj: any
  licenseFormObj: any
  disableSave: boolean
}

class SlidingLMLicenseUpdate extends A10Container<
  ISlidingLMLicenseUpdateProps,
  ISlidingLMLicenseUpdateStates
> {
  Utilities = new Utilities()
  DashboardService = new DashboardService()
  childForm: any = null

  constructor(props: ISlidingLMLicenseUpdateProps) {
    super(props)
    this.state = {
      licenseObj: this.props.licenseObj,
      licenseFormObj: {
        name: '',
        type: '',
        minBandwidth: '',
        defBandwidth: '',
        maxBandwidth: '',
        checkinFreq: '',
      },
      disableSave: true,
    }
  }

  componentWillReceiveProps(nextProps: IObject) {
    const state = { ...this.state }
    const { licenseObj } = nextProps
    state.licenseObj = licenseObj
    state.licenseFormObj = {
      name: licenseObj['license-name'],
      type: licenseObj['license-type'],
      minBandwidth: licenseObj['min-allocated-bandwidth'],
      defBandwidth: licenseObj['default-allocated-bandwidth'],
      maxBandwidth: licenseObj['max-allocated-bandwidth'],
      checkinFreq: licenseObj['check-in-frequency'],
    }

    state.disableSave = false
    if (state.licenseFormObj.name === '') {
      state.disableSave = true
    }
    this.setState(state)
  }

  onFormChange = (licenseFormObj: any) => {
    const state = { ...this.state }

    state.licenseFormObj = licenseFormObj
    state.disableSave = false

    if (state.licenseFormObj.name === '') {
      state.disableSave = true
    }

    this.setState(state)
  }

  onClose = () => {
    this.props.updateList()
    this.props.onRequestClose()
  }

  updateLicense = () => {
    const { licenseFormObj } = this.state
    const type = licenseFormObj?.type || ''
    const payload = {
      'license-name': licenseFormObj.name,
      'min-allocated-bandwidth':
        type.indexOf('FLEXPOOL') > -1 && type !== 'INSTANCE_FLEXPOOL'
          ? licenseFormObj.minBandwidth
          : undefined,
      'default-allocated-bandwidth':
        type.indexOf('FLEXPOOL') > -1 && type !== 'INSTANCE_FLEXPOOL'
          ? licenseFormObj.defBandwidth
          : undefined,
      'max-allocated-bandwidth':
        type.indexOf('FLEXPOOL') > -1 && type !== 'INSTANCE_FLEXPOOL'
          ? licenseFormObj.maxBandwidth
          : undefined,
      'check-in-frequency': licenseFormObj.checkinFreq,
      'entitlement-key': this.props.licenseObj['entitlement-key'],
    }

    const updateLicense = this.DashboardService.updateLLMLicense(
      null,
      payload,
      [this.props.licenseObj['license-id']],
    )
    updateLicense
      .then((resp: any) => {
        if (resp) {
          this.Utilities.showMessage(
            'License update request submitted successfully',
            1,
            0,
          )
          this.onClose()
        }
      })
      .catch((err: any) => {
        const msg =
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : ''
        this.Utilities.showMessage('Error while update of license', 0, 0, msg)
        this.onClose()
      })
  }

  onRequestOk = () => {
    const { licenseFormObj } = this.state
    if (licenseFormObj.name.trim() === '') {
      this.Utilities.showMessage('Please enter the name', 0, 0)
      return
    }
    if (
      licenseFormObj.type.indexOf('FLEXPOOL') > -1 &&
      licenseFormObj.type !== 'INSTANCE_FLEXPOOL'
    ) {
      if (licenseFormObj.minBandwidth <= 0) {
        this.Utilities.showMessage(
          'Min allocated bandwidth cannot be less than equal to 0',
          0,
          0,
        )
        return
      }
      if (licenseFormObj.minBandwidth > licenseFormObj.maxBandwidth) {
        this.Utilities.showMessage(
          'Min allocated bandwidth cannot be more than max allocated bandwidth',
          0,
          0,
        )
        return
      }
      if (licenseFormObj.defBandwidth <= 0) {
        this.Utilities.showMessage(
          'Default allocated bandwidth cannot be less than equal to 0',
          0,
          0,
        )
        return
      }
      if (
        licenseFormObj.defBandwidth < licenseFormObj.minBandwidth ||
        licenseFormObj.defBandwidth > licenseFormObj.maxBandwidth
      ) {
        this.Utilities.showMessage(
          'Default allocated bandwidth should be less than max allocated bandwidth and more than min allocated bandwidth',
          0,
          0,
        )
        return
      }
    }
    this.updateLicense()
  }

  render() {
    const { isOpen, onRequestClose } = this.props
    const { disableSave, licenseFormObj } = this.state
    return (
      <FormatSlidingPage
        isOpen={isOpen}
        onRequestOk={this.onRequestOk}
        onRequestClose={onRequestClose}
        title="Edit License"
        disableSave={disableSave}
        saveText="Update"
      >
        <LMLicenseUpdateForm
          onChange={this.onFormChange}
          licenseFormObj={licenseFormObj}
          onRef={(ref: any): any => (this.childForm = ref)}
        />
      </FormatSlidingPage>
    )
  }
}

export default setupA10Container(SlidingLMLicenseUpdate)
