import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import {
  A10SlidingPage,
  A10Loader,
  A10Table,
  A10DropdownMenu,
} from '@gui-libraries/widgets'

import { httpClient } from 'src/libraries/httpClient'
import storage from 'src/libraries/storage'
import ActionButton from 'src/components/shared/ActionButton'
import ForceDeleteDialog from './ForceDeleteDialog'
import { Utilities } from 'src/services'

import styles from './styles/index.module.less'

const INVALID = '-- --'
const convertSwitchObject = (value?: number) => {
  return value ? 'True' : 'False'
}
const utilities = new Utilities()

export interface IVrrpaInterfacesProps {
  device: string
  partition: string
  renderForm: (value: IObject) => JSX.Element
  openNotification: (type: string, title: string, description: string) => void
  clustersUpdated: boolean
}

const VrrpaInterfaces: React.FC<IVrrpaInterfacesProps> = props => {
  const provider = storage.get.PROVIDER
  const isOperatorUser = storage.get.IS_OPERATOR_USER

  const container = useRef(null)
  const { device, partition, renderForm, clustersUpdated } = props
  const [formattedData, setFormattedData] = useState([])
  const [slidingPageOpen, setSlidingPageOpen] = useState(false)
  const [selectedInterface, setSelectedInterface] = useState({
    interface: '',
    val: null,
  })
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const apiPrefix = useMemo(() => {
    return `/hpcapi/v3/provider/${provider}/device/${device}/partition/${partition}`
  }, [provider, device, partition])

  const openFormPage = useCallback(() => setSlidingPageOpen(true), [])
  const closeFormPage = useCallback(() => setSlidingPageOpen(false), [])
  const closeModal = useCallback(() => {
    setModalVisible(false)
  }, [])

  const handleAddNewEth = () => {
    setSelectedInterface({
      interface: 'ethernet',
      val: null,
    })
    openFormPage()
  }

  const handleAddNewTrunk = () => {
    setSelectedInterface({
      interface: 'trunk',
      val: null,
    })
    openFormPage()
  }

  const submitCallback = () => {
    closeFormPage()
    getData()
  }

  const moreOptions = useCallback(
    ({ interface: type, val }: { interface: string; val: number | string }) => {
      return [
        <div
          key={`edit-interface-${type}-val`}
          onClick={() => {
            setSelectedInterface({
              interface: type,
              val: val,
            })
            openFormPage()
          }}
        >
          Edit
        </div>,
        <div
          key={`delete-interface-${type}-val`}
          onClick={() => {
            setSelectedInterface({
              interface: type,
              val: val,
            })
            setModalVisible(true)
          }}
        >
          Delete
        </div>,
      ]
    },
    [openFormPage],
  )

  const getData = () => {
    if (!provider || !device || !partition) return

    setLoading(true)
    const interfaces = ['ethernet', 'trunk']
    Promise.all(
      interfaces.map(type =>
        httpClient.get(`${apiPrefix}/vrrp-a/interface/${type}`),
      ),
    )
      .then(responses => {
        const data = responses.reduce((acc, res, i) => {
          const type = interfaces[i]
          const list = res?.data?.[`${type}-list`] || []
          const arr = list.map((item: IObject) => {
            const val = item?.[`${type}-val`]
            return {
              type,
              value: val,
              routerInterface: convertSwitchObject(item?.['router-interface']),
              serverInterface: convertSwitchObject(item?.['server-interface']),
              ...(item?.both && {
                routerInterface: convertSwitchObject(item.both),
                serverInterface: convertSwitchObject(item.both),
              }),
              noHeartbeat: convertSwitchObject(item?.['no-heartbeat']),
            }
          })

          acc = [...acc, ...arr]
          return acc
        }, [])
        setFormattedData(data)
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
  }, [apiPrefix, clustersUpdated])

  const columns = useMemo(() => {
    return [
      {
        title: 'Ethernet',
        dataIndex: 'ethernet',
        key: 'ethernet',
        sorter: (a: string, b: string) => utilities.sortString(a, b, 'value'),
        render: (text: string, record: IObject) =>
          record.type === 'ethernet' ? record.value : INVALID,
      },
      {
        title: 'Trunk',
        dataIndex: 'trunk',
        key: 'trunk',
        sorter: (a: string, b: string) => utilities.sortString(a, b, 'value'),
        render: (text: string, record: IObject) =>
          record.type === 'trunk' ? record.value : INVALID,
      },
      {
        title: 'Router Interface',
        dataIndex: 'routerInterface',
        key: 'routerInterface',
        sorter: (a: string, b: string) =>
          utilities.sortString(a, b, 'routerInterface'),
      },
      {
        title: 'Server Interface',
        dataIndex: 'serverInterface',
        key: 'serverInterface',
        sorter: (a: string, b: string) =>
          utilities.sortString(a, b, 'serverInterface'),
      },
      {
        title: 'No Heartbeat',
        dataIndex: 'noHeartbeat',
        key: 'noHeartbeat',
        sorter: (a: string, b: string) =>
          utilities.sortString(a, b, 'noHeartbeat'),
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (text: string, record: IObject) => {
          if (isOperatorUser) return null

          const { type, value } = record
          return (
            <A10DropdownMenu
              trigger="hover"
              placement="bottomRight"
              arrowPointAtCenter={true}
              menu={moreOptions({
                interface: type,
                val: value,
              })}
            />
          )
        },
      },
    ]
  }, [isOperatorUser, moreOptions])

  return (
    <>
      <div ref={container} className={styles.sectionContent}>
        {loading ? (
          <A10Loader container={container} />
        ) : (
          <>
            <A10Table
              columns={columns}
              dataSource={formattedData}
              bordered={false}
            />
            {!loading && !isOperatorUser && (
              <div className={styles.actionButtonContainer}>
                <ActionButton
                  text="Add Ethernet"
                  size="default"
                  onClick={handleAddNewEth}
                  iconProps={{ app: 'global', type: 'add-new' }}
                />
                <ActionButton
                  text="Add Trunk"
                  size="default"
                  onClick={handleAddNewTrunk}
                  iconProps={{ app: 'global', type: 'add-new' }}
                />
              </div>
            )}
          </>
        )}
      </div>
      <A10SlidingPage
        isOpen={slidingPageOpen}
        modalSize="large"
        onRequestClose={closeFormPage}
      >
        {slidingPageOpen &&
          renderForm({
            schemaPath: `system/vrrpa/interface/${
              selectedInterface.interface === 'ethernet' ? 'interface' : 'trunk'
            }`,
            apiPrefix,
            params: selectedInterface.val && {
              [`${selectedInterface.interface}-val`]: selectedInterface.val,
            },
            successCallback: submitCallback,
            errorCallback: submitCallback,
            cancelCallback: closeFormPage,
          })}
      </A10SlidingPage>
      <ForceDeleteDialog
        visible={modalVisible}
        closeModal={closeModal}
        api={`${apiPrefix}/vrrp-a/interface/${selectedInterface.interface}/${selectedInterface.val}`}
        name={selectedInterface.val}
        category={selectedInterface.interface}
        onDeleteComplete={getData}
      />
    </>
  )
}

export default VrrpaInterfaces
