import React from 'react'
import {
  A10Container,
  setupA10Container,
  IA10ContainerDefaultProps,
} from '@gui-libraries/framework'
import { A10Form, A10Input, A10Select, A10Icon } from '@gui-libraries/widgets'

import A10Panel from 'src/components/ADC/A10Panel'
import { IDefaultMethods } from 'src/containers/Controller'
import { DropdownConstants } from 'src/constants/DropdownConstants/DropdownConstants'
// import { Redirect } from 'react-router-dom';
import { Messages } from 'src/locale/en/Messages'
import Dropzone from 'react-dropzone'
import { DashboardService } from 'src/services/DashboardService'
import { HelpInfo } from 'src/components/shared/HelpInfo'

export interface IAppUploadFormProps extends IA10ContainerDefaultProps {
  defaultMethods: IDefaultMethods
  app: any
  editMode: boolean
  validateMode: boolean
  handleChange?: (data: any) => void
  onDrop(files: Array<any>): void
}
export interface IAppUploadFormState {
  namespace: any
  version: any
  comment: string
  description: string
  metadata: any
  files: Array<any>
}

class AppUploadForm extends A10Container<
  IAppUploadFormProps,
  IAppUploadFormState
> {
  Messages = new Messages()
  DashboardService = new DashboardService()
  DropdownConstants = new DropdownConstants()

  constructor(props: IAppUploadFormProps) {
    super(props)
    this.state = {
      namespace: this.props.app.namespace,
      version: this.props.app.version,
      comment: this.props.app.comment,
      description: this.props.app.description,
      metadata: this.props.app.metadata,
      files: [] as Array<any>,
    }
  }

  componentWillReceiveProps(nextProps: any) {
    const {
      namespace,
      version,
      comment,
      description,
      metadata,
      files,
    } = nextProps.app

    this.setState({
      namespace: namespace,
      version: version,
      comment: comment,
      description: description,
      metadata: metadata,
      files: files,
    })
  }

  handleChange = (stateName: string, e: any) => {
    let appObj = this.state
    if (e.target) {
      if (e.target.type === 'checkbox') {
        appObj[stateName] = e.target.checked
      } else {
        appObj[stateName] = e.target.value
      }
    } else {
      appObj[stateName] = e
    }
    this.props.handleChange(appObj)
    // @ts-ignore
    this.setState({
      [stateName]: appObj[stateName],
    })
  }

  renderDropdownOpts = (key: string) => {
    const dropdownOpts = this.DropdownConstants.APPS[key]
    return dropdownOpts.map((opt: any, i: number) => {
      return (
        <A10Select.Option key={opt.key} value={opt.value}>
          {opt.label}
        </A10Select.Option>
      )
    })
  }

  getDropdownDefault = (key: string): string => {
    let defaultOpt = { key: '' }
    const dropdownOpts = this.DropdownConstants.APPS[key]
    dropdownOpts.map((opt: any, i: number) => {
      if (opt.default) {
        defaultOpt = opt
      }
    })
    return defaultOpt.key
  }

  onDrop = (files: any) => {
    this.props.onDrop(files)

    this.setState({
      files,
    })
  }

  render() {
    const appObj = this.props.app
    const { editMode, validateMode } = this.props

    // console.log(appObj.files)
    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 13 },
    }

    return (
      <A10Form layout="horizontal">
        <A10Panel showHeader={false}>
          {validateMode ? (
            <>
              <A10Form.Item
                className="mb-10"
                {...formItemLayout}
                label={
                  <>
                    <span className="label-border">
                      {this.Messages.APP_PACKAGE_FILE}
                    </span>
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.APP_PACKAGE_FILE}
                        helpKey="HELP_APP_PACKAGE_FILE"
                      />
                    </span>
                  </>
                }
              >
                <Dropzone
                  onDrop={(e: any) => {
                    this.onDrop(e)
                  }}
                  className="cursor-pointer text-ellipsis bordered"
                >
                  <i className="fa fa-file-zip-o pad-s-5" />
                  {appObj.files.length > 0 ? (
                    <span title={appObj.files[0].name}>
                      {appObj.files[0].name}
                    </span>
                  ) : (
                    <span>Click to upload</span>
                  )}
                </Dropzone>
              </A10Form.Item>
              <A10Form.Item
                className="mb-0"
                {...formItemLayout}
                label={
                  <>
                    <span className="label-border">
                      {this.Messages.APP_UPLOAD_NOTES}
                    </span>
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.APP_UPLOAD_NOTES}
                        helpKey="HELP_APP_UPLOAD_NOTES"
                      />
                    </span>
                  </>
                }
              >
                <A10Input.TextArea
                  className='comment-height'
                  defaultValue={appObj.comment}
                  placeholder={this.Messages.APP_UPLOAD_NOTES_PH}
                  onChange={this.handleChange.bind(this, 'comment')}
                />
              </A10Form.Item>
            </>
          ) : !validateMode ? (
            <>
              <A10Form.Item
                className="mb-16"
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.APP_PACKAGE_FILE}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.APP_PACKAGE_FILE}
                        helpKey="HELP_APP_PACKAGE_FILE"
                      />
                    </span>
                  </>
                }
              >
                <i className="fa fa-file-zip-o pad-s-5" />
                {appObj.files && appObj.files.name ? (
                  <span title={appObj.files.name}>{appObj.files.name}</span>
                ) : (
                  <span>No file uploaded</span>
                )}
              </A10Form.Item>

              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.APP_NAME}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.APP_NAME}
                        helpKey="HELP_APP_NAME"
                      />
                    </span>
                  </>
                }
              >
                <A10Input
                  type="text"
                  placeholder={this.Messages.APP_NAME}
                  defaultValue={appObj.name}
                  disabled={true}
                />
              </A10Form.Item>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.APP_SHORT_NAME}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.APP_SHORT_NAME}
                        helpKey="HELP_APP_SHORT_NAME"
                      />
                    </span>
                  </>
                }
              >
                <A10Input
                  type="text"
                  placeholder={this.Messages.APP_SHORT_NAME}
                  defaultValue={appObj.short_name}
                  disabled={true}
                />
              </A10Form.Item>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.APP_NAMESPACE}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.APP_NAMESPACE}
                        helpKey="HELP_APP_NAMESPACE"
                      />
                    </span>
                  </>
                }
              >
                <A10Input
                  type="text"
                  placeholder={this.Messages.APP_NAMESPACE}
                  defaultValue={appObj.app_namespace}
                  disabled={true}
                />
              </A10Form.Item>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.APP_VERSION}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.APP_VERSION}
                        helpKey="HELP_APP_VERSION"
                      />
                    </span>
                  </>
                }
              >
                <A10Input
                  type="text"
                  placeholder={this.Messages.APP_VERSION}
                  defaultValue={appObj.app_version}
                  disabled={true}
                />
              </A10Form.Item>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.APP_BUILD_NUMBER}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.APP_BUILD_NUMBER}
                        helpKey="HELP_APP_BUILD_NUMBER"
                      />
                    </span>
                  </>
                }
              >
                <A10Input
                  type="text"
                  placeholder={this.Messages.APP_BUILD_NUMBER}
                  defaultValue={appObj.build_number}
                  disabled={true}
                />
              </A10Form.Item>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.APP_DESCRIPTION}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.APP_DESCRIPTION}
                        helpKey="HELP_APP_DESCRIPTION"
                      />
                    </span>
                  </>
                }
              >
                <A10Input.TextArea
                  defaultValue={appObj.description}
                  placeholder={this.Messages.APP_DESC_PH}
                  // onChange={this.handleChange.bind(this, 'description')}
                  disabled={true}
                />
              </A10Form.Item>
              <A10Form.Item
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.APP_DATE_CREATED}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.APP_DATE_CREATED}
                        helpKey="HELP_APP_DATE_CREATED"
                      />
                    </span>
                  </>
                }
              >
                <A10Input
                  type="text"
                  placeholder={this.Messages.APP_DATE_CREATED}
                  defaultValue={appObj.date_created}
                  disabled={true}
                />
              </A10Form.Item>
              <A10Form.Item
                className="mb-0"
                {...formItemLayout}
                label={
                  <>
                    {this.Messages.APP_UPLOAD_NOTES}
                    <span>
                      <HelpInfo
                        placement="leftTop"
                        title={this.Messages.APP_UPLOAD_NOTES}
                        helpKey="HELP_APP_UPLOAD_NOTES"
                      />
                    </span>
                  </>
                }
              >
                <A10Input.TextArea
                  placeholder={this.Messages.APP_UPLOAD_NOTES_PH}
                  defaultValue={appObj.comment}
                  onChange={this.handleChange.bind(this, 'comment')}
                />
              </A10Form.Item>
            </>
          ) : null}
        </A10Panel>
      </A10Form>
    )
  }
}
export default setupA10Container(AppUploadForm)
